import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Cascader as Cascaded,
  DatePicker,
} from 'antd'
import {
  getVisitingStatusName,
  visitingStatuss,
} from '../../../../models/ToothHome'
import moment from 'moment'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../../compnents/form/DateSelect'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { getAllDoctorList, getCounselor } from '../toothHomeSlice'
import {
  selectInsuranceArray,
  selectUserId,
} from '../../../../app/applicationSlice'

interface WorkStationQueryProps {
  onSubmit: (obj: any) => void
}

export const WorkStationQuery = ({ onSubmit }: WorkStationQueryProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname + '_gongzuozhan') || '{}'
  ) //数据回显

  const [form] = Form.useForm()

  const [head, tail] = getDateRange(DateRangeType.Today)

  const [doctorList, setDoctorList] = useState<any>([]) //  挂号医生数据

  const [counselorList, setCounselorList] = useState<any[]>() //咨询师数据

  const [current, setCurrent] = useState<any>()

  const userId = useSelector(selectUserId) //登录是用户数据

  const insuranceArray = useSelector(selectInsuranceArray)

  const getCounselorFn = () => {
    //获取咨询师
    dispatch(
      getCounselor({
        current: 1,
        size: 100,
        stationCategory: 4,
      })
    )
      .then(unwrapResult)
      .then(({ records }) => setCounselorList(records))
  }

  const getAllDoctor = (id?: string) => {
    //获取挂号医生数据
    dispatch(
      getAllDoctorList({
        size: 1000,
        state: 1,
        stationCategory: 1,
        outpatientDepartmentId: id ? id : undefined,
      })
    )
      .then(unwrapResult)
      .then(({ records }) => {
        setDoctorList(records.filter((v: any) => v.kssxbz != 1)) // 筛选已注销科室的医生
        if (Object.keys(storageParams).length == 0) {
          if (records?.find((v: any) => v.id == userId && v.kssxbz != 1)?.id) {
            form.setFieldsValue({
              treatmentDoctorIdWS: records?.find(
                (v: any) => v.id == userId && v.kssxbz != 1
              )?.id,
            })
          } else {
            form.setFieldsValue({
              treatmentDoctorIdWS: '全部',
            })
          }

          form.submit()
        }
      })
  }

  useEffect(() => {
    getCounselorFn()
    getAllDoctor()
    if (
      Object.keys(storageParams).length &&
      (location.pathname == '/toothHome' ||
        location.pathname == '/toothPatient')
    ) {
      form.setFieldsValue({
        ...storageParams,
        timeHead: storageParams?.startTimeWS || head,
        timeTail: storageParams?.endTimeWS || tail,
        treatmentDoctorIdWS:
          storageParams?.treatmentDoctorIdWS != null
            ? storageParams?.treatmentDoctorIdWS
              ? storageParams?.treatmentDoctorIdWS
              : doctorList?.find((v: any) => v.id == userId && v.kssxbz != 1)
                  ?.id
            : '',
        [DateRangeFieldName]: storageParams?._dateRange || DateRangeType.Today,
        current: current || storageParams?.current ? storageParams?.current : 1,
      })
    } else {
      // form.setFieldsValue({
      //   treatmentDoctorIdWS: '全部',
      // })
    }
    form.submit()
  }, [])

  return (
    <Form
      form={form}
      onFinish={(values) => {
        onSubmit({
          ...storageParams,
          ...values,
          treatmentDoctorIdWS: values?.treatmentDoctorIdWS
            ? values?.treatmentDoctorIdWS
            : null,
          endTimeWS: values.timeTail || tail,
          startTimeWS: values.timeHead || head,
          timeHead: undefined,
          timeTail: undefined,
          current: current,
        })
      }}
      style={{ padding: '0 10px', marginBottom: 10 }}
    >
      {/* 工作站查询条件 */}
      <Space wrap>
        <DateSelect
          placeholder=''
          labelPrefix=''
          namePrefix='time'
          initialValue={DateRangeType.Today}
          style={{ width: '8.25rem' }}
          allowClear={false}
          onChange={(e) => {
            if (e !== 6) {
              form.submit()
              setCurrent(1)
            }
          }}
        />
        <Form.Item noStyle name='nameWS'>
          <Input
            autoComplete='off'
            placeholder='患者姓名/助记码/手机号/病历号'
            style={{ width: '15rem' }}
            allowClear
            onChange={() => {
              setCurrent(1)
              form.submit()
            }}
          />
        </Form.Item>
        <Form.Item name='receiveTagWS' noStyle>
          <Select
            placeholder='就诊类型'
            style={{ width: '7rem' }}
            allowClear
            onChange={() => {
              setCurrent(1)
              form.submit()
            }}
          >
            <Select.Option value={0}>初诊</Select.Option>
            <Select.Option value={1}>复诊</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name='registrationStateWS' noStyle>
          <Select
            placeholder='就诊状态'
            style={{ width: '7rem' }}
            allowClear
            onChange={() => {
              setCurrent(1)
              form.submit()
            }}
          >
            {visitingStatuss.map((v: any) => {
              return (
                <Select.Option key={v} value={v}>
                  {getVisitingStatusName(v)}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item name='registrationDoctorIdWS' noStyle>
          <Select
            placeholder='挂号医生'
            style={{ width: '7rem' }}
            allowClear
            showSearch
            filterOption={(input, option: any) =>
              (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={() => {
              setCurrent(1)
              form.submit()
            }}
          >
            {doctorList?.map((v: any) => {
              return (
                <Select.Option key={v.id} value={v.id}>
                  {v.realname}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item name='treatmentDoctorIdWS' noStyle>
          <Select
            placeholder='就诊/预约医生'
            style={{ width: '9rem' }}
            allowClear={false}
            onChange={form.submit}
            showSearch
            filterOption={(input, option: any) =>
              (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option key={'全部'} value={'全部'}>
              全部
            </Select.Option>
            {doctorList?.map((v: any) => {
              return (
                <Select.Option key={v.id} value={v.id}>
                  {v.realname}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item name='consultDoctorIdWS' noStyle>
          <Select
            placeholder='咨询师'
            style={{ width: '6rem' }}
            allowClear
            onChange={() => {
              setCurrent(1)
              form.submit()
            }}
            showSearch
            filterOption={(input, option: any) =>
              (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {counselorList?.map((v: any) => {
              return (
                <Select.Option key={v.id} value={v.id}>
                  {v.realname}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item name='insuranceCode' noStyle>
          <Select
            placeholder='结算险种'
            style={{ width: '8.25rem' }}
            allowClear
            onChange={form.submit}
          >
            <Select.Option value={'ZIFEI'}>自费</Select.Option>
            {insuranceArray.map((v) => (
              <Select.Option value={v?.insuranceCode} key={v.insuranceCode}>
                {v.insuranceName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button type='primary' htmlType='submit'>
          查询
        </Button>
      </Space>
    </Form>
  )
}
