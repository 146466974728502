import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  notification,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTenantName } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  DateRangeType,
  DateSelect,
  TJFXTime,
} from '../../../compnents/form/DateSelect'
import { EditableColumnType } from '../../../compnents/list/EditableList'
import {
  BillingCategories,
  BillingCategory,
  BillingCategoryName,
} from '../../../models/billingCategory'
import { User } from '../../../models/user'
import { ThemeContext } from '../../../theme/ThemeContext'
import styles from './DoctorAudit.module.css'
import {
  getDoctorAuditEntries,
  getDoctorAuditExoprt,
  getDoctorList,
  selectGetDoctorAuditEntriesParam,
  setGetDoctorAuditEntriesParam,
} from './doctorAuditSlice'

import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'

import { DoctorSaleEntry, DoctorSalesResult } from '../../../models/doctorSales'
const TreatmentKeys = [
  'name',
  'accountUnit',
  'treatmentDoctorName',
  'totalCount',
  'totalRetail',
]

export const DoctorAudit = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const params = useSelector(selectGetDoctorAuditEntriesParam)

  const name = useSelector(selectTenantName)

  const [doctors, setDoctors] = useState<User[]>([])

  const [all, setAll] = useState<any>()

  const [entries, setEntries] = useState<Record<string, unknown>[]>([])

  const printRef = useRef<PrintFrameRef>(null)

  const [result, setResult] = useState({
    headers: [],
    entries: [],
  } as DoctorSalesResult)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  useEffect(() => {
    try {
      setPageLoading(true)
      dispatch(getDoctorAuditEntries(params))
        .then(unwrapResult)
        .then((data: any) => {
          setEntries(data?.doctorAccountingSummaries)
          setAll(data)
        })
        .finally(() => setPageLoading(false))
    } catch {
      // do nothing.
    }
  }, [params])

  useEffect(() => {
    try {
      dispatch(getDoctorList())
        .then(unwrapResult)
        .then((doctors) => setDoctors(doctors))
    } catch {
      // do nothing.
    }
    form.setFieldsValue(params)
  }, [])

  const outClued = () => {
    dispatch(getDoctorAuditExoprt(params))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '医生核算明细.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const columns: EditableColumnType<Record<string, any>>[] = [
    {
      title: `${params.drugType === 1 ? '诊疗' : '药品'}名称`,
      dataIndex: 'name',
      align: 'center',
      width: '12rem',
      ellipsis: true,
    },
    {
      title: '规格',
      dataIndex: 'packSpec',
      align: 'center',
      width: '12rem',
      ellipsis: true,
    },
    {
      title: '供应商',
      dataIndex: 'gysName',
      align: 'center',
      width: '12rem',
      ellipsis: true,
    },
    {
      title: '厂家',
      dataIndex: 'tenantSupplierName',
      align: 'center',
      width: '12rem',
      ellipsis: true,
    },
    {
      title: '批次',
      dataIndex: 'batchNo',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => (r.batchNo ? r.batchNo : '-'),
    },
    {
      title: '开票项目',
      key: 'billingCategory',
      dataIndex: 'billingCategory',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, d) =>
        BillingCategoryName(d.billingCategory as BillingCategory),
    },
    {
      title: '单位',
      dataIndex: 'accountUnit',
      align: 'center',
      width: '4rem',
      ellipsis: true,
    },
    {
      title: '开单医生',
      dataIndex: 'treatmentDoctorName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, e) => {
        return {
          props: {
            style: {
              whiteSpace: 'nowrap',
            },
          },
          children: e.treatmentDoctorName,
        }
      },
    },
    {
      title: '数量',
      dataIndex: 'totalCount',
      align: 'right',
      width: '4rem',
      ellipsis: true,
    },
    {
      title: '零售金额',
      key: 'totalRetail',
      dataIndex: 'totalRetail',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => r.totalRetail?.toFixed(2),
    },
    {
      title: '进货金额',
      dataIndex: 'totalCost',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => r.totalCost?.toFixed(2),
    },
    {
      title: '利润率',
      dataIndex: 'profitRate',
      align: 'right',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => r.profitRate?.toFixed(2),
    },
    {
      title: '利润',
      dataIndex: 'totalDiff',
      align: 'right',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => r.totalDiff?.toFixed(2),
    },
  ]
  const footingColumns: EditableColumnType<Record<string, any>>[] = [
    {
      title: `${params.drugType === 1 ? '诊疗' : '药品'}名称`,
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '规格',
      dataIndex: 'packSpec',
      align: 'center',
    },
    {
      title: '厂家',
      dataIndex: 'tenantSupplierName',
      align: 'center',
    },
    {
      title: '批次',
      dataIndex: 'batchNo',
      align: 'center',
      render: (_, r) => (r.batchNo ? r.batchNo : '-'),
    },
    {
      title: '开票项目',
      key: 'billingCategory',
      dataIndex: 'billingCategory',
      render: (_, d) =>
        BillingCategoryName(d.billingCategory as BillingCategory),
    },
    {
      title: '单位',
      dataIndex: 'accountUnit',
      align: 'center',
    },
    {
      title: '开单医生',
      dataIndex: 'treatmentDoctorName',
      align: 'center',
      render: (_, e) => {
        return {
          props: {
            style: {
              whiteSpace: 'nowrap',
            },
          },
          children: e.treatmentDoctorName,
        }
      },
    },
    {
      title: '数量',
      dataIndex: 'totalCount',
      align: 'right',
    },
    {
      title: '零售金额',
      key: 'totalRetail',
      align: 'right',
      render: (_, r) => r.totalRetail?.toFixed(2),
    },
    {
      title: '进货金额',
      dataIndex: 'totalCost',
      align: 'right',
      render: (_, r) => r.totalCost?.toFixed(2),
    },
    {
      title: '利润率',
      dataIndex: 'profitRate',
      align: 'right',
      render: (_, r) => r.profitRate?.toFixed(2),
    },
    {
      title: '利润',
      dataIndex: 'totalDiff',
      align: 'right',
      render: (_, r) => r.totalDiff?.toFixed(2),
    },
  ]
  const table = (type = false) => (
    <>
      <Table
        className={type ? styles.tables : styles.table}
        loading={pageLoading}
        pagination={false}
        // rowClassName={(e) =>
        //   e.treatmentDoctorName === "合计" ? styles.total : ""
        // }
        bordered={!type}
        dataSource={entries}
        rowKey={(_, i) => `${i}}`}
        style={{ flex: 1, overflow: 'auto' }}
        columns={columns.filter((c) =>
          params.drugType === 1
            ? TreatmentKeys.includes(c.dataIndex as string)
            : true
        )}
        summary={() =>
          entries.length ? (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell className={styles.footingTitle} index={0}>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={6} align='right'>
                  {params.drugType != 1 ? ' ' : all?.totalCount}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align='right'>
                  {params.drugType != 1 ? ' ' : all?.totalRetail?.toFixed(2)}
                </Table.Summary.Cell>
                {params.drugType != 1 && (
                  <>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  </>
                )}

                {params.drugType != 1 && (
                  <>
                    <Table.Summary.Cell index={7} align='right'>
                      {all?.totalCount}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align='right'>
                      {all?.totalRetail}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9} align='right'>
                      {all?.totalCost}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} align='right'>
                      {all?.totalProfitRate}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11} align='right'>
                      {all?.totalDiff}
                    </Table.Summary.Cell>
                  </>
                )}
              </Table.Summary.Row>
            </Table.Summary>
          ) : (
            <></>
          )
        }
      />
    </>
  )
  return (
    <Col
      style={{
        height: 'calc(100% - 30px)',
        padding: '0 15px',
        borderRadius: theme.br,
        backgroundColor: theme.pn,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Form
        form={form}
        onFinish={(values) => {
          dispatch(setGetDoctorAuditEntriesParam({ ...params, ...values }))
        }}
      >
        <Row
          style={{ marginTop: 15, marginBottom: 15 }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='doctorId' noStyle>
              <Select
                onChange={form.submit}
                style={{ width: 220 }}
                placeholder='请选择医生姓名'
                allowClear
                showSearch
                filterOption={(input, option) => {
                  return option?.className
                    ? ((option?.className as unknown) as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    : false
                }}
              >
                {doctors.map((d) => (
                  <Select.Option
                    className={d.mnemonicCode}
                    key={d.id}
                    value={d.id}
                  >
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='drugType' noStyle>
              <Select
                onChange={() => {
                  setEntries([])
                  form.submit()
                  // setTag
                }}
                style={{ width: '8.5rem' }}
                placeholder='项目名称'
                allowClear
              >
                <Select.Option value={0}>药品</Select.Option>
                <Select.Option value={1}>诊疗</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name='billingCategory' noStyle>
              <Select
                onChange={form.submit}
                style={{ width: '8.5rem' }}
                placeholder='开票项目'
                allowClear
              >
                {BillingCategories.map((c) => (
                  <Select.Option key={c} value={c}>
                    {BillingCategoryName(c)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <DateSelect
              style={{ width: '8.5rem' }}
              labelPrefix=''
              allowClear={false}
              namePrefix='time'
              options={TJFXTime}
              placeholder='日期范围-全部'
              initialValue={DateRangeType.Today}
              onChange={form.submit}
            />
          </Space>
          <Space>
            <Button type='primary' onClick={outClued}>
              导出
            </Button>
            <Button type='primary' onClick={form.submit}>
              查询
            </Button>
            <Button type='primary' onClick={() => printRef.current?.print()}>
              打印
            </Button>
          </Space>
        </Row>
      </Form>
      <div
        style={{
          textAlign: 'center',
          color: theme.tc1,
          fontWeight: 'bold',
          fontSize: '1.5rem',
          marginBottom: 10,
        }}
      >
        {name}-医生核算明细
      </div>
      {table()}
      <PrintFrame
        ref={printRef}
        title={
          JSON.parse(localStorage.getItem('credentials') as string)?.tenantName
        }
        subTitle='医生核算明细'
      >
        <Row style={{ marginBottom: '10px', lineHeight: '32px' }}></Row>
        {table(true)}
      </PrintFrame>
    </Col>
  )
}
