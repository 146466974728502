/* eslint-disable @typescript-eslint/no-empty-function */
import {
  CaretDownFilled,
  CaretRightFilled,
  CloseOutlined,
  SyncOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  EllipsisOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
const { Item } = Form
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd'
import _, { flatMap } from 'lodash'
import { serverType } from '../../../services/commodity'

import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectEdition,
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserDepartmentId,
  selectUserId,
  selectUserName,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { DateRangeType, getDateRange } from '../../../compnents/date/TimeSelect'
import {
  AppointmentIcon,
  EditOutlined,
  FemaleFilled,
  LikeedIcon,
  LikeIcon,
  MaleFilled,
  OweMoneyIcon,
} from '../../../compnents/icons/Icons'
import { DiseaseInput } from '../../../compnents/input/DiseaseInput'
import { Label } from '../../../compnents/widgets/Label'
import { LoadingState } from '../../../models/common'
import { Disease } from '../../../models/disease'
import { patientInfo } from '../../../models/intelligence'
import { PrintTemplateCategory } from '../../../models/print'
import { VisitType } from '../../../models/registration'
import { Settings } from '../../../models/setting'
import { TemplateCategory, TemplateDetail } from '../../../models/template'
import {
  getTreatmentCategoryName,
  TreatmentCategories,
  TreatmentCategoriesCQ,
  TreatmentCategoriesJS,
  TreatmentCategoriesZF,
  TreatmentCategory,
  TreatmentNotice,
} from '../../../models/treatment'
import { Gender, getGenderName, getSexName } from '../../../models/user'
import { CopyTreatmentParams } from '../../../services/treatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import { getAge, getAgeName } from '../../../utils/StringUtils'
import {
  getDiseaseCompletions,
  getDoctorsInDepartment,
  getOutpatientDepartments,
  getTenantNurse,
  selectDoctorsInDepartments,
  selectOutpatientDepartments,
  selectTenantNurses,
  setDoctorsInDepartment,
} from '../../completion/completionSlice'
import { getUserProfileList } from '../../personalManagement/personalDetail/personalDetailSlice'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import {
  searchMedicalAsync,
  selectSearchMedical,
} from '../../registration/register/registerSlice'
import { getRecipeList } from '../../retail/retailSlice'
import { selectBooleanSetting } from '../../settings/settingsSlice'
import { SaveTemplateModal } from '../../template/SaveTemplateModal'
import {
  commonListAsync,
  commonRemoveAsync,
  commonSaveAsync,
  createOrUpdateTemplateDetail,
  getTempalteDetailList,
  getTemplateCategoryList,
} from '../../template/templateSlice'
import {
  getTab,
  getTreatmentNotice,
  setRecipeEditorTreatment,
} from '../editor/recipeListEditorSlice'
import { Treatments, TreatmentsRef } from '../list/Treatments'
import {
  getMedicalRecordsShow,
  getTcmTreatmentOldList,
} from '../list/treatmentSlice'
import { FastInput } from '../tcmCases/fastInput'
import { HistoryPanel } from '../tcmCases/historyPanel'
import styles from './Diagnosis.module.css'
import { DiagnosisModal } from './DiagnosisModal'
import {
  copyTreatment,
  createTreatment,
  deleteUploadFileList,
  getIframePreScriptionAsync,
  getNatureAsync,
  getTreatment,
  getTreatments,
  remindSaveMedicalAsync,
  resetDiagnosis,
  selectRegistration,
  selectTreatment,
  selectTreatmentCreatingState,
  setNatureAsync,
  setRemindDetail,
  setTreatmentCreatingState,
  startTreatment,
} from './diagnosisSlice'
import { HistoryCaseDialog } from './HistoryCase'
import { ParientInfoRow } from './parientInfo'
import { PreferencesSetModal } from './preferencesSetModal'
import { RemindTips } from './remindTips'
import FileUpload from '../../../compnents/file/FileUpload'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import { getUrl } from '../oralCases/oralCasesSlice'
import { PlusOutlined } from '@ant-design/icons/lib'
import { UploadModal } from '../../putstorelist/Query/uploadModal'
import {
  beforeAlertAsync,
  inprocessAnalysisAsync,
} from '../../payment/confirm/paymentConfirmSlice'
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../../models/datetime'
import { getNurse } from '../../../services/user'

const { Text } = Typography
const { TextArea } = Input

export interface DiagnosisRef {
  submit: () => Promise<void>
  showTreatments: () => void
}

interface messageData {
  action: string
  params: {
    message: string
    hospitalId: string
    idcCode: string
  }
}
interface DiagnosisProps {
  readMode?: boolean
  state?: number
  saveEdit?: () => void
  registrations?: any
  owelabel?: any
  oweMoneyNum?: any
  oweMoneyShow?: any
  insuranceBean?: any
  patientInsurance?: any
  PreferencesSetChange?: () => void
}

const RealDiagnosis = (
  {
    readMode = false,
    state = undefined,
    saveEdit = () => {},
    registrations,
    owelabel,
    oweMoneyNum,
    oweMoneyShow,
    insuranceBean,
    patientInsurance,
    PreferencesSetChange,
  }: DiagnosisProps,
  ref: React.Ref<DiagnosisRef>
): ReactElement => {
  const layout = {
    labelCol: { flex: '4rem' },
    wrapperCol: { flex: 1 },
  }
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const query = useQuery()

  const { Panel } = Collapse

  const patientSystolicPressureRef = useRef<any>(null)

  const patientDiastolicPressureRef = useRef<any>(null)

  const patientPulseRef = useRef<any>(null)

  const patientHeightRef = useRef<any>(null)

  const patientWeightRef = useRef<any>(null)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const insuranceArray = useSelector(selectInsuranceArray)

  const treatmentsRef = useRef<TreatmentsRef>(null)

  const departments = useSelector(selectOutpatientDepartments)

  const creatingState = useSelector(selectTreatmentCreatingState)

  const doctors = useSelector(selectDoctorsInDepartments)

  const nurses = useSelector(selectTenantNurses)

  const registration = useSelector(selectRegistration)

  const searchMedical = useSelector(selectSearchMedical)

  const treatment = useSelector(selectTreatment)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const tenantCategory = useSelector(selectTenantCategory)

  const doNotWarnDoctorMismatch = useSelector(
    selectBooleanSetting(Settings.Treatment.DoNotWarnDoctorMismatch)
  )

  const baseData = useSelector(selectInsuranceItem)

  const [selectedDiseases, setSelectedDiseases] = useState<Disease[]>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isLookPicModalVisible, setIsLookPicModalVisible] = useState(false)

  const [isShowBeforeAlert, setIsShowBeforeAlert] = useState(false)

  const [beforeAlertMessage, setBeforeAlertMessage] = useState('')

  const [lookPic, setLookPic] = useState('')

  const [medical, setMedical] = useState<any>()

  const [diagnosisForm] = Form.useForm()

  // const [patientForm] = Form.useForm()

  const [treatmentForm] = Form.useForm()

  const [templateKeyword, setTemplateKeyword] = useState('')

  const [templates, setTemplates] = useState<TemplateCategory[]>([])

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const [isCreating, setIsCreating] = useState(false)

  const [isOpen, setIsOpen] = useState(false)

  // 辅助诊断 start
  const [messageData, setMessageData] = useState<messageData>()

  const [patientData, setpatientData] = useState<patientInfo>()

  const [checkData, setCheckData] = useState<any>([])

  const [isDrawer, setIsDrawer] = useState<boolean>(
    Boolean(sessionStorage.getItem('isDrawer')) || false
  )

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const [treatmentData, setTreatmentData] = useState<any>({})

  const [tempType, setTempType] = useState(false)

  const [SaveTemplateModalVasible, setSaveTemplateModalVasible] = useState(
    false
  ) //存为模板

  const [selectedCategoryId, setSelectedCategoryId] = useState('')

  const [recipeCollapse, setRecipeCollapse] = useState<any>([])

  const [iframeUrl, setIframeUrl] = useState<string>(
    'https://pre-aimedical.wecity.qq.com/toolbox/index.html'
  )
  const [historyDialog, setHistoryDialog] = useState(false)

  const [seeOtherDoctor, setSeeOtherDoctor] = useState(true)

  const [historyList, setHistoryList] = useState<any>([])

  const [CollapseActivekey, setCollapseActivekey] = useState('0')

  const [historyData, setHistoryData] = useState<any>({})

  const [reuseType, setReuseType] = useState(false)

  const [reuseTreatmentId, setReuseTreatmentId] = useState('')

  const [preferencesSetShow, setiPreferencesSetShow] = useState(false) //偏好设置

  const [isCopyReciptModalVisible, setIsCopyReciptModalVisible] = useState(
    false
  )

  const [isCopyReciptParam, setIsCopyReciptParam] = useState<any>()

  const [isTipsModalVisible, setIsTipsModalVisible] = useState<any>()

  const [loading, setLoading] = useState(false)

  const [mlBtnLoading, setmlBtnLoading] = useState(false)
  const [cfBtnLoading, setcfBtnLoading] = useState(false)
  const [kcBtnLoading, setkcBtnLoading] = useState(false)

  const departmentId = useSelector(selectUserDepartmentId)

  const storedepartmentId =
    localStorage.getItem('registrationDepartmentId') || departmentId

  const [isUploadVisible, setIsUploadVisible] = useState(false) //上传附件弹窗
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [url, setUrl] = useState<any>()

  useEffect(() => {
    if (!registration?.patientId) return
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode === registration?.insuranceCode
    )
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    dispatch(getMedicalRecordsShow({ patientId: registration?.patientId }))
      .then(unwrapResult)
      .then((res: any) => {
        diagnosisForm.setFieldsValue({
          ...res,
          historyOfPresentIllness:
            diagnosisForm.getFieldValue('historyOfPresentIllness') ||
            res.historyOfPresentIllness,
          pastMedicalHistory:
            diagnosisForm.getFieldValue('pastMedicalHistory') ||
            res.pastMedicalHistory,
          allergen: diagnosisForm.getFieldValue('allergen') || res.allergen,
          personalHistory:
            diagnosisForm.getFieldValue('personalHistory') ||
            res.personalHistory,
          familyHistory:
            diagnosisForm.getFieldValue('familyHistory') || res.familyHistory,
          vaccineHistory:
            diagnosisForm.getFieldValue('vaccineHistory') || res.vaccineHistory,
          menstrualHistory:
            diagnosisForm.getFieldValue('menstrualHistory') ||
            res.menstrualHistory,
          maritalHistory:
            diagnosisForm.getFieldValue('maritalHistory') || res.maritalHistory,
          personalHistoryEnable: !!res.personalHistory,
          familyHistoryEnable: !!res.familyHistory,
          vaccineHistoryEnable: !!res.vaccineHistory,
          menstrualHistoryEnable: !!res.menstrualHistory,
          maritalHistoryEnable: !!res.maritalHistory,
          coherentVideo: fileList
            .map((v) => `${v.name}@${v.uid}@`.concat(v.url || ''))
            .join(','),
        })
      })
  }, [registration])

  useEffect(() => {
    if (!registration?.patientId) return
    // 历史病例
    const [head, tail] = getDateRange(DateRangeType.RecentYear)
    const data = {
      current: 1,
      size: 50,
      medicalRecord: 1,
      treatmentTimeHead: head,
      treatmentTimeTail: tail,
      patientId: registration?.patientId,
      treatmentDoctorId: seeOtherDoctor
        ? registration?.treatmentDoctorId
        : undefined,
    }
    dispatch(getTcmTreatmentOldList(data))
      .then(unwrapResult)
      .then((res: any) => {
        setHistoryList(res.records)
        const first = res?.records[0]?.id
        setCollapseActivekey(first)
        if (first) {
          dispatch(getRecipeList(first))
            .then(unwrapResult)
            .then((res) => {
              setHistoryData({
                ...historyData,
                [first]: res,
              })
            })
        }
      })
  }, [seeOtherDoctor, registration?.patientId])

  const setPatientInfo = () => {
    const diagnosisData = diagnosisForm.getFieldsValue()
    return setTreatmentData({
      prescriptionReviewRequestType: 0,
      requestCase: {
        caseType: 0,
        chiefComplaint: diagnosisData.chiefComplaint,
        presentIllness: diagnosisData.historyOfPresentIllness,
        // patientEmrDiagnosis: "",
        patientEmrDiagnosis: selectedDiseases
          .map((d) => `${d.name}`)
          .join('；'),
        caseId: '1',
        visitId: registrationId,
        patientBaseInfo: {
          sex: getSexName(patientData?.registration.patientSex || 0),
          age: getAgeName(
            patientData?.registration?.patientAge || 0,
            patientData?.registration?.patientMonth || 0
          ),
          height: patientData?.treatment.patientHeight,
          weight: patientData?.treatment.patientWeight,
          patientId: String(patientData?.treatment.patientId),
        },
        prescriptions: [{}],
      },
    })
  }

  const arouseDrawer = () => {
    if (!isDrawer) {
      dispatch(getNatureAsync('1'))
        .then(unwrapResult)
        .then((res) => {
          if (JSON.stringify(res) === '{}') {
            setIsDrawerVisible(true)
          } else {
            setIsDrawer(true)
            sessionStorage.setItem('isDrawer', '1')
          }
        })
    } else {
      setIsDrawer(false)
      sessionStorage.removeItem('isDrawer')
    }
  }

  // 获取个人设置权限
  useEffect(() => {
    if (userId) {
      dispatch(getUserProfileList({ userId: userId }))
        .then(unwrapResult)
        .then((c: any) => {
          setCheckData(c)
        })
    }
  }, [userId])
  // 获取患者信息
  useEffect(() => {
    registrationId &&
      dispatch(
        searchMedicalAsync({
          // patientId: registration.patientId,
          registrationId: registrationId,
        })
      )
        .then(unwrapResult)
        .then((v) => {
          if (v?.length) {
            if (v?.[0]?.medicalRecord == 1 && v?.[0]?.localDisease) {
              setIsTipsModalVisible(false)
            } else {
              setIsTipsModalVisible(true)
            }
          }
        })
    if (!registration)
      dispatch(
        getTreatments({
          registrationId,
          treatmentId,
        })
      )
        .then(unwrapResult)
        .then((v) => {
          setReuseType(v.registration.receiveTag == 1) //复诊是否勾选
          setpatientData(v)
        })
  }, [registration])

  useEffect(() => {
    if (JSON.stringify(selectedDiseases) !== '{}') {
      setPatientInfo()
    }
    const disease = selectedDiseases.map((d) => `${d.name}/${d.icds}`).join(',')
    dispatch(remindSaveMedicalAsync({ disease }))
  }, [selectedDiseases])

  useEffect(() => {
    if (isDrawer && JSON.stringify(treatmentData) !== '{}') {
      dispatch(getIframePreScriptionAsync(treatmentData))
        .then(unwrapResult)
        .then((res) => {
          if (res.iframeUrl) {
            setIframeUrl(
              `${res.iframeUrl}?id=${res.prescriptionReviewId}&ADTAG=${res.hospitalId}&token=${res.token}&module=decision&iframe`
            )
          }
        })
    }
  }, [isDrawer, treatmentData])

  useEffect(() => {
    window.onmessage = (e: any) => {
      if (typeof e.data === 'string') {
        setMessageData(JSON.parse(e.data))
      }
    }
  })
  // iframe回填

  useEffect(() => {
    const diagnosisData = diagnosisForm.getFieldsValue()
    if (messageData?.action === 'input-complaint') {
      if (diagnosisData.chiefComplaint) {
        if (
          !diagnosisData.chiefComplaint
            .replace(/。/g, '，')
            .split('，')
            .find((v: string) => v === messageData?.params.message)
        ) {
          diagnosisForm.setFieldsValue({
            chiefComplaint: diagnosisData.chiefComplaint.concat(
              `${messageData?.params.message}，`
            ),
          })
        }
      } else {
        diagnosisForm.setFieldsValue({
          chiefComplaint: `${messageData?.params.message}，`,
        })
      }
    } else if (messageData?.action === 'input-physi') {
      if (diagnosisData.physicalExamination) {
        if (
          !diagnosisData.physicalExamination
            .replace(/。/g, '，')
            .split('，')
            .find((v: string) => v === messageData?.params.message)
        ) {
          diagnosisForm.setFieldsValue({
            physicalExamination: diagnosisData.physicalExamination.concat(
              `${messageData?.params.message}，`
            ),
          })
        }
      } else {
        diagnosisForm.setFieldsValue({
          physicalExamination: `${messageData?.params.message}，`,
        })
      }
    } else if (messageData?.action === 'input-inspection') {
      if (diagnosisData.inspectionCheck) {
        if (
          !diagnosisData.inspectionCheck
            .replace(/。/g, '，')
            .split('，')
            .find((v: string) => v === messageData?.params.message)
        ) {
          diagnosisForm.setFieldsValue({
            inspectionCheck: diagnosisData.inspectionCheck.concat(
              `${messageData?.params.message}，`
            ),
          })
        }
      } else {
        diagnosisForm.setFieldsValue({
          inspectionCheck: `${messageData?.params.message}，`,
        })
      }
    } else if (messageData?.action === 'input-diagnose') {
      dispatch(
        getDiseaseCompletions({
          param: messageData?.params.message,
          size: 10,
          current: 1,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          if (res.items[0]) {
            if (!selectedDiseases.find((d) => d?.icds === res.items[0]?.icds)) {
              setSelectedDiseases(selectedDiseases.concat(res.items[0]))
            }
          }
        })
    }
  }, [messageData?.params.message])

  // 辅助诊断 end

  const [templateDetails, setTemplateDetails] = useState<
    Record<string, TemplateDetail[]>
  >({})

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const registrationId = query.get('registrationId') as string

  const treatmentId = query.get('treatmentId') as string

  const diseasesChose = (v: any) => {
    switch (v.diseTypeCode) {
      case '12':
        diagnosisForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.Special,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(1)
        // }, 1000)
        break
      case '11':
        diagnosisForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.Slow,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(2)
        // }, 1000)
        break
      case '14':
        diagnosisForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.SlowAndSpecial,
        })
        // setTimeout(() => {
        //   treatmentCategoryChose(2)
        // }, 1000)
        break
      default:
        if (
          diagnosisForm.getFieldValue('treatmentCategory') ==
            TreatmentCategory.Special ||
          diagnosisForm.getFieldValue('treatmentCategory') ==
            TreatmentCategory.Slow ||
          diagnosisForm.getFieldValue('treatmentCategory') ==
            TreatmentCategory.SlowAndSpecial
        ) {
          diagnosisForm.setFieldsValue({
            treatmentCategory: TreatmentCategory.Normal,
          })
        }
        diagnosisForm.resetFields(['disease'])
        // setTimeout(() => {
        //   treatmentCategoryChose(0)
        // }, 1000)
        break
    }
  }

  const treatmentCategoryChose = (key: number) => {
    if (!searchMedical) return
    switch (key) {
      case 0:
        // setIsOpen(false)
        setSelectedDiseases((selectedDiseases) => {
          return selectedDiseases?.filter((v) => {
            if (
              searchMedical?.find((item: any) => v?.icds == item?.diseaseCode)
            ) {
              notification.warn({
                message: (
                  <span>
                    不允许同时添加普通病与特慢病
                    <br></br>
                    已为您删除特慢病{v.name}
                  </span>
                ),
                duration: 3,
              })
            }
            if (
              searchMedical?.findIndex(
                (item: any) => v.icds == item.diseaseCode
              ) < 0
            ) {
              return v
            } else {
              return
            }
          })
        })
        break
      case 1:
      case 2:
        // setIsOpen(true)
        setSelectedDiseases((selectedDiseases) => {
          return selectedDiseases?.filter((v) => {
            if (
              !searchMedical?.find((item: any) => v?.icds == item?.diseaseCode)
            ) {
              notification.warn({
                message: (
                  <span>
                    不允许同时添加普通病与特慢病
                    <br></br>
                    已为您删除普通疾病{v.name}
                  </span>
                ),
                duration: 3,
              })
            }
            return searchMedical?.find(
              (item: any) => v.icds == item.diseaseCode
            )
          })
        })

        break
      default:
        break
    }
  }

  const getFormCheck = async () => {
    if (creatingState === LoadingState.Loading) {
      return
    }
    if (selectedDiseases.length === 0) {
      setIsDiseaseEmpty(true)
    }
    const diagnosisValues = await diagnosisForm.validateFields()
    // const patientValues = await patientForm.validateFields()
    const treatmentValues = await treatmentForm.validateFields()
    const newTreatment = {
      ...diagnosisValues,
      // ...patientValues,
      ...treatmentValues,
      medicalRecord: 1,
      diseaseType: '1',
      id: treatment?.id || treatmentId,
      registrationId: registration?.id || registrationId,
      patientId: registration?.patientId,
      receiveTag: reuseType ? VisitType.Subsequence : VisitType.First,
      disease: selectedDiseases.map((d) => `${d.name}/${d.icds}`).join(','),
      treatmentDepartmentName: departments.find(
        (d) => d.id === treatmentValues.treatmentDepartmentId
      )?.name,
      treatmentDoctorName: doctors.find(
        (d) => d.id === treatmentValues.treatmentDoctorId
      )?.name,
      treatmentNurseName: nurses.find(
        (n) => n.id === treatmentValues.treatmentNurseId
      )?.name,
      infectiousDisease: treatmentValues.infectiousDisease ? 1 : 0,
      personalHistory: diagnosisValues.personalHistoryEnable
        ? diagnosisValues.personalHistory
        : '',
      familyHistory: diagnosisValues.familyHistoryEnable
        ? diagnosisValues.familyHistory
        : '',
      vaccineHistory: diagnosisValues.vaccineHistoryEnable
        ? diagnosisValues.vaccineHistory
        : '',
      menstrualHistory: diagnosisValues.menstrualHistoryEnable
        ? diagnosisValues.menstrualHistory
        : '',
      maritalHistory: diagnosisValues.maritalHistoryEnable
        ? diagnosisValues.maritalHistory
        : '',
      insuranceCode: registration?.insuranceCode,
      reuseTreatmentId: reuseTreatmentId || undefined,
      coherentVideo: fileList
        .map((v) => `${v.name}@${v.uid}@`.concat(v.url || ''))
        .join(','),
    }
    return newTreatment
  }

  const submit = async (flag?: any) => {
    try {
      const newTreatment = await getFormCheck()
      // flag点击诊断书打印标志位 如果是诊断书保存时多传一个参数
      const paramsData = flag
        ? { ...newTreatment, isNotModifySign: true }
        : { ...newTreatment }
      dispatch(setTreatmentCreatingState(LoadingState.Loading))
      await dispatch(createTreatment(paramsData))
        .then(unwrapResult)
        .then((res: any) => {
          {
            state === 1 ? null : notification.success({ message: '保存成功' })
          }
          dispatch(
            getTreatment({
              registrationId: registrationId,
              treatmentId: res,
              medicalRecord: 1,
            })
          )
        })
    } catch (e) {
      return Promise.reject(e)
    } finally {
      dispatch(setTreatmentCreatingState(LoadingState.Finished))
    }
    return Promise.resolve()
  }

  useImperativeHandle(ref, () => ({
    submit: () => submit(),
    showTreatments: () => setIsModalVisible(true),
  }))

  useEffect(() => {
    dispatch(getOutpatientDepartments())
    diagnosisForm.setFieldsValue({
      treatmentCategory: TreatmentCategory.Normal,
      disease: '',
    })
  }, [dispatch])

  useEffect(() => {
    if (registration?.patientId) {
      dispatch(getTreatmentNotice(registration.patientId))
        .then(unwrapResult)
        .then((notice) => setNotice(notice))
        .catch(() => {
          // do nothing
        })
    }
  }, [registration])

  useEffect(() => {
    dispatch(setRecipeEditorTreatment(treatment))
    treatmentForm.setFieldsValue({
      treatmentNurseId:
        Number(treatment?.treatmentNurseId) > -1
          ? treatment?.treatmentNurseId?.toString()
          : undefined,
    })
  }, [treatment])

  const [isDiseaseEditable, setIsDiseaseEditable] = useState(true)

  const [isDiseaseEmpty, setIsDiseaseEmpty] = useState(false)

  const [targetTemplate, setTargetTemplate] = useState<
    TemplateDetail | undefined
  >(undefined)

  const [isDoctorEditable, setIsDoctorEditable] = useState(!readMode)

  useEffect(() => {
    dispatch(getTreatment({ registrationId, treatmentId, medicalRecord: 1 }))
    if (!readMode) {
      dispatch(startTreatment(registrationId))
    }
  }, [dispatch, registrationId, treatmentId])

  useEffect(() => {
    if (registration) {
      if (registration.treatmentDepartmentId) {
        dispatch(getDoctorsInDepartment(registration.treatmentDepartmentId))
      }
      const doctorName = doctors.find(
        (d) =>
          d.id ===
          (registration.treatmentDoctorId || registration.registrationDoctorId)
      )?.name
      treatmentForm.setFieldsValue({
        registrationDoctorName:
          registration.treatmentDoctorName ||
          registration.registrationDoctorName,
        treatmentDepartmentId:
          registration.treatmentDepartmentId == '0'
            ? undefined
            : registration.treatmentDepartmentId || storedepartmentId,
        treatmentDoctorId:
          registration.treatmentDoctorId == '0'
            ? undefined
            : registration.treatmentDoctorId ||
              registration.registrationDoctorId,
        // receiveTag: registration.receiveTag === VisitType.Subsequence,
      })
      if (
        registration.treatmentDepartmentId == '0' &&
        registration.treatmentDoctorId == '0'
      ) {
        Modal.warn({
          title: '提示',
          content: '暂未指定医生，请先选择接诊医生再填写病历',
        })
        // messageApi.open({
        //   type: 'warning',
        //   content: '请选择医生科室跟就诊医生，再',
        //   className: 'custom-class',
        //   style: {
        //     marginTop: '35vh',
        //   },
        // })
      }
      if (!treatment) {
        diagnosisForm.setFieldsValue({
          treatmentCategory: TreatmentCategory.Normal,
        })
      }
    }
  }, [dispatch, registration?.registrationDoctorId])

  useEffect(() => {
    dispatch(getDoctorsInDepartment(storedepartmentId))
  }, [storedepartmentId])

  useEffect(() => {
    dispatch(getTenantNurse(storedepartmentId))
  }, [])

  useEffect(() => {
    if (!treatment) {
      setIsDiseaseEditable(true)
      return
    }
    if (treatment.disease) {
      if (readMode) {
        setIsDiseaseEditable(false)
      }
      setSelectedDiseases(
        treatment.disease
          .split(',')
          .map((d) => d.split('/'))
          .map((pair) => ({ name: pair[0], icds: pair[1] } as Disease))
      )
      setIsDiseaseEmpty(false)
    } else {
      setSelectedDiseases([])
    }
    diagnosisForm.setFieldsValue({
      ...treatment,
      disease: '',
      personalHistoryEnable: !!treatment.personalHistory,
      familyHistoryEnable: !!treatment.familyHistory,
      vaccineHistoryEnable: !!treatment.vaccineHistory,
      menstrualHistoryEnable: !!treatment.menstrualHistory,
      maritalHistoryEnable: !!treatment.maritalHistory,
    })
    if (treatment?.patientWeight && treatment?.patientHeight) {
      const BMI = (
        (treatment?.patientWeight /
          (treatment?.patientHeight * treatment?.patientHeight)) *
        10000
      ).toFixed(1)
      diagnosisForm.setFieldsValue({
        BMI,
      })
    }

    // patientForm.setFieldsValue({
    //   patientId: treatment?.patientId,
    //   patientHeight: patientForm.getFieldValue('patientHeight') || treatment?.patientHeight,
    //   patientName: registration?.patientName,
    //   patientPhone: registration?.patientPhone,
    //   patientPulse: treatment?.patientPulse,
    //   patientSex: registration?.patientSex,
    //   patientSystolicPressure: treatment?.patientSystolicPressure,
    //   patientDiastolicPressure: treatment?.patientDiastolicPressure,
    //   patientTemperature: treatment?.patientTemperature,
    //   patientWeight: patientForm.getFieldValue('patientWeight') || treatment?.patientWeight,
    // })
    diagnosisForm.setFieldsValue({
      treatmentCategory:
        treatment.treatmentCategory == 0 || treatment.treatmentCategory == -1
          ? TreatmentCategory.Normal
          : treatment.treatmentCategory || TreatmentCategory.Normal,
      infectiousDisease: treatment.infectiousDisease === 1,
    })
    setReuseType(reuseType || treatment.receiveTag === VisitType.Subsequence)
    setReuseTreatmentId(reuseTreatmentId || treatment?.reuseTreatmentId || '')
    // if (treatment.receiveTag !== undefined) {
    //   treatmentForm.setFieldsValue({
    //     receiveTag: treatment.receiveTag === VisitType.Subsequence,
    //   })
    // }
    if (readMode) {
      setIsDoctorEditable(
        treatment.treatmentDepartmentId === '0' ||
          treatment.treatmentDoctorId === '0'
      )
      if (!registration?.treatmentDoctorId && treatment.treatmentDoctorId) {
        dispatch(getDoctorsInDepartment(treatment.treatmentDepartmentId))
      }
      if (
        !registration?.registrationDepartmentId &&
        !registration?.treatmentDoctorId
      ) {
        const doctorName = doctors.find(
          (d) =>
            d.id ===
            (treatment.treatmentDoctorId === '0'
              ? undefined
              : treatment.treatmentDoctorId)
        )?.name
        treatmentForm.setFieldsValue({
          treatmentDepartmentId:
            treatment.treatmentDepartmentId === '0'
              ? undefined
              : treatment.treatmentDepartmentId,
          treatmentDoctorId:
            treatment.treatmentDoctorId === '0'
              ? undefined
              : treatment.treatmentDoctorId,
        })
      }
    }

    treatment.coherentVideo
      ? setFileList(
          treatment.coherentVideo.split(',').map((v) => {
            return ({
              name: v.split('@')[0],
              uid: v.split('@')[1] ? v.split('@')[1] : v.split('@')[0],
              url: v.split('@')[2] ? v.split('@')[2] : v.split('@')[0],
            } as unknown) as UploadFile
          })
        )
      : setFileList([])
  }, [treatment])

  useEffect(() => {
    if (templateKeyword) {
      if (templates?.length) {
        const ids: any = templates?.map((v) => {
          return v?.id
        })
        setRecipeCollapse(ids)
      }
    } else {
      setRecipeCollapse([])
    }
  }, [templateKeyword, templates])

  useEffect(() => {
    if (recipeCollapse?.length > 1)
      _.forEach(recipeCollapse, (id) => {
        if (!templateDetails[id]) {
          const template = templates.find((t) => t.id === id)
          setSelectedCategoryId(id)
          if (template) {
            dispatch(getTempalteDetailList(id))
              .then(unwrapResult)
              .then((details) => {
                setTemplateDetails({
                  ...templateDetails,
                  [id]: details,
                })
              })
          }
        }
      })
  }, [recipeCollapse])

  useEffect(() => {
    dispatch(
      getTemplateCategoryList({
        current: 1,
        size: 100,
        categoryName: templateKeyword,
        medicalRecord: 1,
      })
    )
      .then(unwrapResult)
      .then(async (templates) => {
        let activeId = '0'
        setTemplates([
          {
            categoryName: '收藏',
            id: '-1',
            medicalRecord: 1,
          },
          ...templates,
        ])
        // setTemplates([...templates])

        const commonList = !templateDetails['-1']
          ? await dispatch(commonListAsync('1')).then(unwrapResult)
          : templateDetails['-1']
        if (commonList.length && !templateDetails['-1']) {
          activeId = '-1'
        } else {
          activeId = templates[0].id
        }
        templates.length && setSelectedCategoryId(activeId)
        // const collArr:any = Array.from(new Set([activeId, templates[0]?.id]))
        // setRecipeCollapse(collArr)
        _.forEach([templates[0]?.id], (id) => {
          if (!templateDetails[id]) {
            const template = templates.find((t) => t.id === id)
            if (template) {
              dispatch(getTempalteDetailList(id))
                .then(unwrapResult)
                .then((details) => {
                  setTemplateDetails({
                    ...templateDetails,
                    [id]: details,
                    ['-1']: commonList,
                  })
                })
            }
          }
        })
      })
      .catch(() => {
        // do nothing.
      })
  }, [templateKeyword])

  useEffect(() => {
    fileList.map((v) => {
      return setUrl(v?.url)
    })
  }, [fileList])

  useEffect(() => {
    return () => {
      dispatch(resetDiagnosis())
      dispatch(setRemindDetail([]))
    }
  }, [])

  const getBMI = async (v: boolean) => {
    const values = await diagnosisForm.validateFields()
    let BMI = ''
    if (v && values.patientWeight && values.patientHeight) {
      BMI = (
        (values.patientWeight / (values.patientHeight * values.patientHeight)) *
        10000
      )
        .toFixed(1)
        .toString()
      diagnosisForm.setFieldsValue({
        BMI,
      })
    } else {
      BMI = ''
    }
    diagnosisForm.setFieldsValue({
      BMI,
    })
  }

  const copyRecipe = (vs: any) => {
    const params = vs as CopyTreatmentParams
    setIsModalVisible(false)
    if (params.copyRecipe) {
      setIsCopyReciptModalVisible(true)
      setIsCopyReciptParam(params)
      // Modal.confirm({
      //   title: '是否从处方复制价格？',
      //   cancelText: '使用目录价格',
      //   okText: '使用处方价格',
      //   onOk: () => {
      //     dispatch(
      //       copyTreatment({
      //         ...params,
      //         registrationId,
      //         copyWithAmount: true,
      //       })
      //     )
      //       .then(unwrapResult)
      //       .then(() => {
      //         notification.success({
      //           message: '病历和处方已',
      //         })
      //         dispatch(
      //           getTreatment({
      //             registrationId,
      //             treatmentId,
      //           })
      //         )
      //       })
      //   },
      //   onCancel: () => {
      //     dispatch(
      //       copyTreatment({
      //         ...params,
      //         registrationId,
      //         copyWithAmount: false,
      //       })
      //     )
      //       .then(unwrapResult)
      //       .then(() => {
      //         notification.success({
      //           message: '病历和处方已复制',
      //         })
      //         dispatch(
      //           getTreatment({
      //             registrationId,
      //             treatmentId,
      //           })
      //         )
      //       })
      //   },
      // })
    } else {
      dispatch(copyTreatment({ ...params, registrationId })).then(() => {
        notification.success({
          message: '病历已复制',
        })
        dispatch(
          getTreatment({
            registrationId,
            treatmentId,
          })
        )
      })
    }
  }

  const onUpdateColl = (id: string) => {
    dispatch(getTempalteDetailList(id))
      .then(unwrapResult)
      .then(async (details) => {
        const commonList = await dispatch(commonListAsync('1')).then(
          unwrapResult
        )
        setTemplateDetails({
          ...templateDetails,
          [id]: details,
          ['-1']: commonList,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const like = useCallback(
    _.debounce((d) => {
      dispatch(
        commonSaveAsync({
          itemId: d.id,
          medicalRecord: 1,
        })
      )
        .then(unwrapResult)
        .then(() => {
          onUpdateColl(d.templateId)
        })
        .catch(() => {
          setLoading(false)
        })
    }, 500),
    []
  )

  const liked = useCallback(
    _.debounce((d) => {
      // setLoading(true)
      dispatch(commonRemoveAsync(d.commonId))
        .then(unwrapResult)
        .then(() => {
          onUpdateColl(d.templateId)
        })
        .catch(() => {
          setLoading(false)
        })
    }, 500),
    []
  )

  // 事前提醒
  const beforeAlert = (serverType: serverType, responseString?: string) => {
    if (!treatment || !treatment.id) {
      return
    }
    setBeforeAlertMessage('正在查询医保事前提醒信息...')

    dispatch(
      beforeAlertAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          treatmentId: treatment?.id,
          patientId: treatment?.patientId,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                beforeAlert('response', JSON.stringify(call.netmessage))
              } else {
                setBeforeAlertMessage(
                  '查询事前提醒失败。' + JSON.stringify(call)
                )
              }
            }
          )
        } else {
          if (res && res.length) {
            setBeforeAlertMessage(res)
          } else {
            setBeforeAlertMessage('当前病例暂无事前提醒')
          }
        }
      })
      .catch((e) => {
        setBeforeAlertMessage('查询事前提醒失败：' + e?.message)
      })
  }

  useEffect(() => {
    const historyPanelCheck = localStorage.getItem('historyPanelCheck')
    setSeeOtherDoctor(historyPanelCheck == 'true' ? true : false)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        height: state === 1 ? '100% ' : 'calc(100% - 5px)',
        position: 'relative',
        flexDirection: 'column',
      }}
    >
      {state === 1 ? (
        <></>
      ) : (
        <Row
          style={{
            position: 'fixed',
            top: '102px',
            right: '20px',
            fontSize: '1rem',
            padding: '0 20px',
          }}
          align='middle'
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#027AFF',
            }}
            onClick={() => {
              setiPreferencesSetShow(true)
            }}
          >
            <SettingOutlined
              style={{
                fontSize: '20px',
                color: '#027AFF',
              }}
            />
            <a
              type='text'
              style={{
                marginLeft: '6px',
                fontSize: '16px',
                color: '#027AFF',
              }}
            >
              偏好设置
            </a>
          </div>
        </Row>
      )}
      <Row
        className={styles.container}
        style={{
          height: '100%',
          overflow: 'hidden',
          flex: 1,
          marginBottom: state === 1 ? '0' : '10px',
        }}
        wrap={false}
      >
        {!readMode && (
          <Col
            style={{
              width: 242,
              height: '100%',
              marginRight: 10,
              backgroundColor: theme.pn,
              borderRadius: theme.br,
              overflow: 'auto',
            }}
          >
            <Label label='诊断模板' style={{ marginLeft: 20 }} />
            <Divider />
            <Input
              value={templateKeyword}
              placeholder='请输入内容检索'
              onChange={(e) =>
                setTemplateKeyword((e.target.value || '').trim())
              }
              style={{
                width: 'calc(100% - 40px)',
                marginTop: 20,
                marginLeft: 20,
              }}
              allowClear
            />
            <Spin spinning={loading}>
              <Collapse
                className={styles.templates}
                bordered={false}
                // defaultActiveKey={[templates[0]?.id]}
                activeKey={recipeCollapse}
                // collapsible='header'
                expandIcon={(v) =>
                  v.isActive ? (
                    <CaretDownFilled style={{ lineHeight: '26px' }} />
                  ) : (
                    <CaretRightFilled style={{ lineHeight: '26px' }} />
                  )
                }
                style={{
                  marginTop: 30,
                  marginLeft: 20,
                  marginRight: 20,
                  backgroundColor: 'transparent',
                }}
                onChange={(ids) => {
                  setRecipeCollapse(ids)

                  _.forEach(ids, (id) => {
                    if (!templateDetails[id]) {
                      const template = templates.find((t) => t.id === id)
                      setSelectedCategoryId(id)
                      if (template) {
                        dispatch(getTempalteDetailList(id))
                          .then(unwrapResult)
                          .then((details) => {
                            setTemplateDetails({
                              ...templateDetails,
                              [id]: details,
                            })
                          })
                      }
                    }
                  })
                }}
              >
                {templates.map((t) => (
                  <Collapse.Panel
                    key={t.id}
                    header={
                      <div
                        style={{
                          width: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: templateKeyword
                            ? undefined
                            : selectedCategoryId === t.id
                            ? theme.c1
                            : undefined,
                          fontWeight: templateKeyword
                            ? undefined
                            : selectedCategoryId === t.id
                            ? 'bold'
                            : 'normal',
                        }}
                        onClick={() => {
                          setSelectedCategoryId(t.id)
                        }}
                      >
                        {t.categoryName}
                      </div>
                    }
                  >
                    {templateDetails[t.id] &&
                      templateDetails[t.id]
                        .filter(
                          (d) =>
                            !templateKeyword ||
                            d.itemName.indexOf(templateKeyword) >= 0 ||
                            d.mnemonicCode.indexOf(
                              templateKeyword.toUpperCase()
                            ) >= 0
                        )
                        .map((d, i) => (
                          <Row
                            key={d.id}
                            justify='space-between'
                            style={{
                              marginTop: 10,
                            }}
                          >
                            <Col span={20}>
                              <div
                                onClick={() => {
                                  diagnosisForm.setFields([
                                    { name: 'disease', errors: [] },
                                  ])
                                  diagnosisForm.setFieldsValue({
                                    ...d,
                                    disease: '',
                                  })
                                  const diseases = _.chain(d.disease)
                                    .split(',')
                                    .map((s) => s.split('/'))
                                    .map(
                                      ([n, c]) =>
                                        ({ name: n, icds: c } as Disease)
                                    )
                                    .value()
                                  setSelectedDiseases(diseases)
                                  setIsDiseaseEmpty(diseases.length === 0)
                                  setSelectedCategoryId(t.id)
                                }}
                                style={{
                                  color: theme.tc2,
                                  fontSize: '1.125rem',
                                  cursor: 'pointer',
                                }}
                              >
                                {i + 1}. {d.itemName}
                              </div>
                            </Col>
                            <Col
                              span={4}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {d?.commonId && d?.commonId > 0 ? (
                                <LikeedIcon
                                  style={{
                                    width: '16px',
                                    height: '16px',
                                  }}
                                  onClick={(e) => {
                                    setLoading(true)
                                    e.stopPropagation()
                                    liked(d)
                                  }}
                                />
                              ) : (
                                <LikeIcon
                                  style={{
                                    width: '16px',
                                    height: '16px',
                                  }}
                                  onClick={(e) => {
                                    setLoading(true)
                                    e.stopPropagation()
                                    like(d)
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        ))}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Spin>
          </Col>
        )}
        <Col
          flex='1'
          style={{
            height: '100%',
            overflow: 'hidden',
            borderRadius: readMode ? `0 0 0 ${theme.br}px` : `${theme.br}px`,
            backgroundColor: theme.pn,
          }}
        >
          <Form form={treatmentForm} colon={false}>
            <Row
              justify='space-between'
              style={{
                paddingRight: 30,
                width: '100%',
                padding: '10px 10px 10px 20px',
              }}
            >
              {/* <ParientInfoRow
                registrations={registrations}
                owelabel={owelabel}
                oweMoneyNum={oweMoneyNum}
                oweMoneyShow={oweMoneyShow}
                insuranceBean={insuranceBean}
                patientInsurance={patientInsurance}
              /> */}
              <Row
                style={{
                  width: 900,
                  marginLeft: '16px',
                }}
                gutter={10}
              >
                <Col
                  span={7}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '10px',
                  }}
                >
                  <Form.Item
                    label='科室'
                    name='treatmentDepartmentId'
                    style={{ width: '100%', margin: 0 }}
                  >
                    <Select
                      // disabled={!isDoctorEditable}
                      onChange={(v) => {
                        treatmentForm.setFieldsValue({
                          treatmentDoctorId: undefined,
                        })
                        dispatch(getDoctorsInDepartment(v))
                      }}
                      style={
                        {
                          // width: 100,
                          // marginLeft: '8px',
                        }
                      }
                    >
                      {departments.map((d) => (
                        <Select.Option key={d.id} value={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  span={7}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    // marginRight: '10px',
                  }}
                >
                  <Form.Item
                    label='医生'
                    name='treatmentDoctorId'
                    style={{ width: '100%', margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: '接诊医生不能为空',
                      },
                    ]}
                  >
                    <Select
                      // disabled={!isDoctorEditable}
                      style={
                        {
                          // width: 100,
                          // marginLeft: '8px',
                        }
                      }
                    >
                      {doctors.map((d) => (
                        <Select.Option key={d.id} value={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    // marginRight: '10px',
                  }}
                >
                  <Form.Item
                    label='护士'
                    name='treatmentNurseId'
                    style={{ width: '100%', margin: 0 }}
                  >
                    <Select
                      // disabled={!isDoctorEditable}
                      style={
                        {
                          // width: 100,
                          // marginLeft: '8px',
                        }
                      }
                    >
                      {nurses.map((d) => (
                        <Select.Option key={d.id} value={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  span={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* <Form.Item name='receiveTag' valuePropName='checked' style={{ width: '100%', margin: '0 0 0 10px' }}> */}
                  <Checkbox
                    checked={reuseType}
                    onChange={(e) => {
                      // if (e.target.checked) {
                      //   notification.info({
                      //     message: '请在右侧就诊历史病历中点击复诊按钮。'
                      //   })
                      // } else {
                      setReuseTreatmentId('')
                      setReuseType(e.target.checked)
                      // }
                    }}
                  >
                    复诊
                  </Checkbox>
                  {/* <div
                    style={{ fontSize: '20px', cursor: 'pointer' }}
                    onClick={() => setiPreferencesSetShow(true)}
                  >
                    <EllipsisOutlined />
                  </div> */}
                  {/* </Form.Item> */}
                </Col>
              </Row>
            </Row>
          </Form>
          {/* <Label label="疾病诊断" style={{ marginLeft: 20 }} /> */}
          <Divider />
          <Col style={{ height: 'calc(100% - 104px)', overflow: 'auto' }}>
            <Form
              form={diagnosisForm}
              colon={false}
              className={styles.info}
              autoComplete='off'
              labelCol={{
                flex: '6.5rem',
              }}
              style={{ marginRight: 30 }}
            >
              {registration?.remark ? (
                <span
                  style={{
                    marginLeft: '112px',
                    position: 'relative',
                    top: '-2px',
                    color: '#027aff',
                  }}
                >
                  备注：{registration?.remark.replace('#', ',')}
                </span>
              ) : (
                ''
              )}
              <Form.Item
                label='主诉'
                name='chiefComplaint'
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                ]}
              >
                <FastInput action='zhusu' onBlur={setPatientInfo} />
                {/* <TextArea
                placeholder='请输入内容'
                autoSize
                autoFocus
                style={{
                  height: '32px',
                }}
                // onChange={setPatientInfo}
                onBlur={setPatientInfo}
              /> */}
              </Form.Item>
              <Form.Item
                label='现病史'
                name='historyOfPresentIllness'
                rules={[
                  {
                    max: 6000,
                    message: '最长6000位!',
                  },
                ]}
              >
                <TextArea
                  placeholder='请输入内容'
                  autoSize
                  onBlur={setPatientInfo}
                />
              </Form.Item>
              <Form.Item
                label='既往史'
                name='pastMedicalHistory'
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                ]}
              >
                <FastInput action='jiwang' onBlur={setPatientInfo} />
                {/* <TextArea
                placeholder='请输入内容'
                autoSize
                onBlur={setPatientInfo}
              /> */}
              </Form.Item>
              <Form.Item
                label='过敏史'
                name='allergen'
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                ]}
              >
                <FastInput action='guoming' onBlur={setPatientInfo} />
              </Form.Item>
              {searchMedical && searchMedical?.length !== 0 && (
                <Form.Item label=' '>
                  <Text style={{ color: '#027AFF' }}>
                    该患者存在备案的特慢病信息，点击引用
                    {searchMedical?.map((v: any) => {
                      const disease = {
                        icds: v.diseaseCode,
                        id: v.id,
                        name: v.diseaseName,
                      }
                      return (
                        <span
                          style={{
                            padding: '0 10px',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                          }}
                          key={v.icds}
                          onClick={() => {
                            if (
                              !selectedDiseases.find(
                                (d) => d?.icds === disease?.icds
                              )
                            ) {
                              setSelectedDiseases(
                                selectedDiseases.concat(disease as Disease)
                              )
                              diseasesChose(v)
                              // setIsOpen(true)
                            }
                          }}
                        >
                          {`${disease.name}/${disease.icds}`}
                        </span>
                      )
                    })}
                  </Text>
                </Form.Item>
              )}
              <Form.Item
                label='疾病名称'
                name='disease'
                required
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                  () => ({
                    validator: () => {
                      if (isDiseaseEmpty) {
                        return Promise.reject('请输入主要疾病')
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Row wrap={false} style={{ height: '35px' }} gutter={10}>
                  <Col span={12}>
                    <DiseaseInput
                      autoFocus={selectedDiseases?.length ? true : false}
                      isMedical={1}
                      // disabled={!isDiseaseEditable}
                      placeholder='请输入主要疾病'
                      onChange={() => setIsDiseaseEmpty(false)}
                      onSelect={(disease) => {
                        if (
                          !selectedDiseases.find(
                            (d) => d?.icds === disease?.icds
                          )
                        ) {
                          setSelectedDiseases(selectedDiseases.concat(disease))
                          diseasesChose(disease)
                        }
                      }}
                      insuranceCode={registration?.insuranceCode}
                    />
                  </Col>
                  <Col span={6} style={{ marginLeft: '-10px' }}>
                    <Form.Item
                      // label='就诊类型'
                      name='treatmentCategory'
                      // name={['disease', 'treatmentCategory']}
                      {...layout}
                    >
                      <Select
                      // onSelect={(values: number) => {
                      //   treatmentCategoryChose(values as number)
                      // }}
                      >
                        {registration?.insuranceCode === 'CHONGQING' &&
                          TreatmentCategoriesCQ.map((c) => (
                            <Select.Option key={c} value={c}>
                              {getTreatmentCategoryName(c)}
                            </Select.Option>
                          ))}
                        {registration?.insuranceCode === 'ZHEJIANG' &&
                          TreatmentCategories.map((c) => (
                            <Select.Option key={c} value={c}>
                              {getTreatmentCategoryName(c)}
                            </Select.Option>
                          ))}
                        {registration?.insuranceCode === 'JIANGSU' &&
                          TreatmentCategoriesJS.map((c) => (
                            <Select.Option key={c} value={c}>
                              {getTreatmentCategoryName(c)}
                            </Select.Option>
                          ))}
                        {(registration?.insuranceCode === 'ZIFEI' ||
                          treatment?.insuranceCode === 'ZIFEI') &&
                          TreatmentCategoriesZF.map((c) => (
                            <Select.Option key={c} value={c}>
                              {getTreatmentCategoryName(c)}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ marginLeft: '-10px' }}>
                    <Form.Item name='infectiousDisease' valuePropName='checked'>
                      <Checkbox style={{ marginLeft: 10 }}>是否传染病</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Row
                style={{
                  display: selectedDiseases.length === 0 ? 'none' : undefined,
                }}
              >
                <Col flex='7.125rem' />
                <Col
                  flex='1'
                  style={{
                    borderRadius: 2,
                    border: `1px solid ${theme.wbd}`,
                    padding: '10px 0 0 10px',
                    marginBottom: 10,
                  }}
                >
                  {selectedDiseases.map((d) => (
                    <div key={d.icds} className={styles.disease}>
                      {`${d.name}/${d.icds}`}{' '}
                      <CloseOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedDiseases(
                            selectedDiseases.filter((i) => i?.icds !== d?.icds)
                          )
                        }}
                      />
                    </div>
                  ))}
                </Col>
              </Row>
              <Form.Item label='病史'>
                <Row
                  style={{
                    borderColor: '#d9d9d9',
                    borderRadius: 2,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    padding: '4px 10px',
                  }}
                  align='middle'
                >
                  <Form.Item
                    noStyle
                    name='personalHistoryEnable'
                    valuePropName='checked'
                  >
                    <Checkbox>个人史</Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name='familyHistoryEnable'
                    valuePropName='checked'
                  >
                    <Checkbox>家族史</Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name='vaccineHistoryEnable'
                    valuePropName='checked'
                  >
                    <Checkbox>疫苗接种史</Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name='menstrualHistoryEnable'
                    valuePropName='checked'
                  >
                    <Checkbox>月经史</Checkbox>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name='maritalHistoryEnable'
                    valuePropName='checked'
                  >
                    <Checkbox>婚育史</Checkbox>
                  </Form.Item>
                </Row>
              </Form.Item>
              <Form.Item dependencies={['personalHistoryEnable']} noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('personalHistoryEnable') && (
                    <Form.Item
                      label='个人史'
                      name='personalHistory'
                      rules={[
                        {
                          max: 200,
                          message: '最长200位!',
                        },
                      ]}
                    >
                      {/*<TextArea placeholder='请输入内容' autoSize />*/}
                      <FastInput action='geren' onBlur={setPatientInfo} />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item dependencies={['familyHistoryEnable']} noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('familyHistoryEnable') && (
                    <Form.Item
                      label='家族史'
                      name='familyHistory'
                      rules={[
                        {
                          max: 200,
                          message: '最长200位!',
                        },
                      ]}
                    >
                      <FastInput action='jiazu' onBlur={setPatientInfo} />
                      {/* <TextArea
                      placeholder='请输入内容'
                      autoSize
                      onBlur={setPatientInfo}
                    /> */}
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item dependencies={['vaccineHistoryEnable']} noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('vaccineHistoryEnable') && (
                    <Form.Item
                      label='疫苗接种史'
                      name='vaccineHistory'
                      rules={[
                        {
                          max: 200,
                          message: '最长200位!',
                        },
                      ]}
                    >
                      <TextArea
                        placeholder='请输入内容'
                        autoSize
                        onBlur={setPatientInfo}
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item dependencies={['menstrualHistoryEnable']} noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('menstrualHistoryEnable') && (
                    <Form.Item
                      label='月经史'
                      name='menstrualHistory'
                      rules={[
                        {
                          max: 200,
                          message: '最长200位!',
                        },
                      ]}
                    >
                      {/*<TextArea*/}
                      {/*  placeholder='请输入内容'*/}
                      {/*  autoSize*/}
                      {/*  onBlur={setPatientInfo}*/}
                      {/*/>*/}
                      <FastInput action='yuejin' onBlur={setPatientInfo} />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item dependencies={['maritalHistoryEnable']} noStyle>
                {({ getFieldValue }) =>
                  getFieldValue('maritalHistoryEnable') && (
                    <Form.Item
                      label='婚育史'
                      name='maritalHistory'
                      rules={[
                        {
                          max: 200,
                          message: '最长200位!',
                        },
                      ]}
                    >
                      {/*<TextArea*/}
                      {/*  placeholder='请输入内容'*/}
                      {/*  autoSize*/}
                      {/*  onBlur={setPatientInfo}*/}
                      {/*/>*/}
                      <FastInput action='hunyu' onBlur={setPatientInfo} />
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item label='体格检查'>
                {/* <TextArea placeholder='请输入内容' autoSize /> */}
                <Row
                  style={{ height: 35, position: 'relative' }}
                  className={styles.checkGroup}
                >
                  <Col span={3}>
                    <Form.Item
                      noStyle
                      name='patientTemperature'
                      rules={[
                        {
                          message: '请输入最多带一位小数的数值',
                          pattern: new RegExp(/^[0-9]+((\.)[0-9]?)?$/),
                        },
                      ]}
                    >
                      <Input
                        addonAfter='℃'
                        placeholder='体温'
                        onPressEnter={() => {
                          patientSystolicPressureRef?.current?.focus()
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <Col span={8}>
                        <Form.Item
                          name='patientSystolicPressure'
                          rules={[
                            {
                              message: '请输入整数值',
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                        >
                          <Input
                            placeholder='收缩压'
                            ref={patientSystolicPressureRef}
                            onPressEnter={() => {
                              patientDiastolicPressureRef?.current?.focus()
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={1}
                        style={{
                          paddingLeft: 9,
                          paddingTop: 5,
                        }}
                      >
                        /
                      </Col>
                      <Col span={13}>
                        <Form.Item
                          name='patientDiastolicPressure'
                          rules={[
                            {
                              message: '请输入整数值',
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}
                        >
                          <Input
                            addonAfter='mmHg'
                            placeholder='舒张压'
                            ref={patientDiastolicPressureRef}
                            onPressEnter={() => {
                              patientPulseRef?.current?.focus()
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name='patientPulse'
                      rules={[
                        {
                          message: '请输入整数值',
                          pattern: new RegExp(/^[0-9]+$/),
                        },
                      ]}
                    >
                      <Input
                        addonAfter='次/分'
                        placeholder='脉搏'
                        ref={patientPulseRef}
                        onPressEnter={() => {
                          patientHeightRef?.current?.focus()
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name='patientHeight'
                      rules={[
                        {
                          message: '请输入最多带两位小数的数值',
                          pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
                        },
                      ]}
                    >
                      <Input
                        addonAfter='cm'
                        placeholder='身高'
                        onChange={async (e) => {
                          const v = e.target.value
                          getBMI(!!v)
                        }}
                        ref={patientHeightRef}
                        onPressEnter={() => {
                          patientWeightRef?.current?.focus()
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name='patientWeight'
                      rules={[
                        {
                          message: '请输入最多带两位小数的数值',
                          pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
                        },
                      ]}
                    >
                      <Input
                        addonAfter='kg'
                        placeholder='体重'
                        onChange={async (e) => {
                          const v = e.target.value
                          getBMI(!!v)
                        }}
                        ref={patientWeightRef}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name='BMI'>
                      <Input addonBefore='BMI' disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label=' ' name='physicalExamination'>
                <TextArea placeholder='其他体格检查' autoSize />
              </Form.Item>
              <Form.Item
                label='检验检查'
                name='inspectionCheck'
                rules={[
                  {
                    max: 500,
                    message: '最长500位!',
                  },
                ]}
              >
                <TextArea
                  placeholder='请输入内容'
                  autoSize
                  onBlur={setPatientInfo}
                />
              </Form.Item>
              <Form.Item label='治疗意见' name='treatmentConclusion'>
                <TextArea
                  placeholder='请输入内容'
                  autoSize
                  onBlur={setPatientInfo}
                />
              </Form.Item>

              <Item label='相关影像'>
                <FileUpload
                  count={fileList.length || 0}
                  files={fileList}
                  onUpload={(v, i) => {
                    setFileList([
                      ...fileList,
                      {
                        uid: v.uid,
                        size: v.size,
                        name: v.name,
                        type: v.type,
                        originFileObj: {} as RcFile,
                        url: i,
                      },
                    ])
                  }}
                >
                  {/* <Button icon={<PlusOutlined />}>上传附件</Button> */}
                </FileUpload>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setIsUploadVisible(true)
                  }}
                >
                  上传附件
                </Button>
                {fileList.map((item) => (
                  <Row
                    gutter={24}
                    key={item.uid}
                    style={{
                      padding: '10px',
                      width: '300px',
                    }}
                  >
                    <Col flex={1}>
                      <span
                        style={{
                          display: 'inline-block',
                          maxWidth: '60px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          verticalAlign: 'middle',
                        }}
                      >
                        {item.name.split('.')[0]}
                      </span>
                      <span
                        style={{
                          verticalAlign: 'middle',
                        }}
                      >
                        .{item.name.split('.')[1]}
                      </span>
                    </Col>
                    <Col span={6}>
                      <a
                        onClick={() => {
                          dispatch(getUrl({ fileName: item.url }))
                            .then(unwrapResult)
                            .then((v: any) => {
                              setLookPic(v)
                              setIsLookPicModalVisible(true)
                              // window.open(
                              //   v.replace('http:', 'https:'),
                              //   '_blank'
                              // )
                            })
                        }}
                      >
                        查看
                      </a>
                    </Col>
                    <Col span={6}>
                      <CloseOutlined
                        onClick={() => {
                          //  e.stopPropagation();
                          setFileList(() =>
                            fileList.filter((ite) => ite.uid !== item.uid)
                          )
                        }}
                      />
                    </Col>
                  </Row>
                ))}
              </Item>
            </Form>
          </Col>
          <Row
            justify='space-between'
            style={{
              marginBottom: '180px',
              marginTop: hisVersion == 1 ? '16px' : '10px',
              marginRight: '10px',
            }}
          >
            <Button
              style={{ marginLeft: 30 }}
              type='primary'
              disabled={!templates.length}
              onClick={async () => {
                setTargetTemplate(await getFormCheck()) //存为模板
                setSaveTemplateModalVasible(true)
              }}
            >
              存为模板
            </Button>
            <Space>
              {/*<Button*/}
              {/*  type='primary'*/}
              {/*  onClick={() => {*/}
              {/*    setHistoryDialog(true)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  查看历史病历*/}
              {/*</Button>*/}

              {readMode ? undefined : ( // </Button> //   另存为模版 // > //   }} //     } //       // do nothing. //     } catch { //       }); //         itemName: "", //           .join(","), //           .map((d) => `${d.name}/${d.icds}`) //         disease: selectedDiseases //         ...values, //       setTargetTemplate({ //       const values = await diagnosisForm.validateFields(); //     try { //   onClick={async () => { //   type="primary" // <Button
                <Button
                  type='primary'
                  onClick={() => {
                    setTempType(false)
                    setIsModalVisible(true)
                  }}
                >
                  查看/选择 历史病历
                </Button>
              )}
              <Button
                type='primary'
                // disabled={!treatment?.id}
                onClick={async () => {
                  try {
                    await submit(true)
                    setIsPrintModalVisible(true)
                  } catch {
                    // do nothing.
                  }
                }}
              >
                打印
              </Button>
            </Space>
          </Row>
          <Modal
            width={800}
            footer={null}
            title='医保事前审核'
            visible={isShowBeforeAlert}
            onOk={(v) => {
              setIsShowBeforeAlert(false)
            }}
            onCancel={(v) => {
              setIsShowBeforeAlert(false)
            }}
          >
            {beforeAlertMessage}
          </Modal>
          <Modal
            width={800}
            footer={null}
            title=''
            visible={isLookPicModalVisible}
            onOk={(v) => {
              setIsLookPicModalVisible(false)
            }}
            onCancel={(v) => {
              setIsLookPicModalVisible(false)
            }}
          >
            <div
              style={{
                maxHeight: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '40px',
              }}
            >
              <img src={lookPic} style={{ width: '500px' }}></img>
              <a
                style={{ marginTop: '20px' }}
                onClick={(v) => {
                  window.open(lookPic.replace('http:', 'https:'), '_blank')
                }}
              >
                下载
              </a>
            </div>
          </Modal>
          <Modal
            className={styles.history}
            width={1200}
            style={{ height: 720, maxHeight: 720, overflow: 'hidden' }}
            title='历史就诊信息'
            visible={isModalVisible}
            footer={null}
            onCancel={() => {
              treatmentsRef.current?.reset()
              setIsModalVisible(false)
            }}
            destroyOnClose
          >
            <Treatments
              medicalRecord={1}
              ref={treatmentsRef}
              style={{ height: '100%' }}
              patientId={registration?.patientId}
              treatmentId={notice?.treatmentId}
              tempType={tempType}
              onCopy={copyRecipe}
            />
          </Modal>
        </Col>
        <Col
          style={{
            width: 320,
            height: '100%',
            overflow: 'auto',
            marginLeft: readMode ? 10 : 10,
            backgroundColor: theme.pn,
            position: 'relative',
            borderRadius: readMode ? `0 0 ${theme.br}px 0` : `${theme.br}px`,
          }}
        >
          <HistoryPanel
            seeOtherDoctor={seeOtherDoctor}
            historyList={historyList?.map((v: any) => {
              return {
                ...v,
                tgjc:
                  (v?.patientTemperature ? v?.patientTemperature + '℃，' : '') +
                  (v?.patientSystolicPressure
                    ? v?.patientSystolicPressure + '/'
                    : '') +
                  (v?.patientDiastolicPressure
                    ? v?.patientDiastolicPressure + 'mmHg，'
                    : '') +
                  (v?.patientPulse ? v?.patientPulse + '次/分，' : '') +
                  (v?.patientHeight ? v?.patientHeight + 'cm，' : '') +
                  (v?.patientWeight ? v?.patientWeight + 'kg，' : '') +
                  (v?.patientWeight && v?.patientHeight
                    ? 'BMI:' +
                      (
                        (v?.patientWeight /
                          (v?.patientHeight * v?.patientHeight)) *
                        10000
                      ).toFixed(1) +
                      '，'
                    : '') +
                  (v.physicalExamination ? v?.physicalExamination : ''),
              }
            })}
            historyData={historyData}
            reuseTreatmentId={reuseTreatmentId}
            onChange={(v: string) => {
              if (v && !historyData[v]) {
                dispatch(getRecipeList(v))
                  .then(unwrapResult)
                  .then((res) => {
                    setHistoryData({
                      ...historyData,
                      [v]: res,
                    })
                  })
              }
            }}
            onCopy={(type: boolean, srcTreatmentId: string) => {
              copyRecipe({
                copyRecipe: type,
                srcTreatmentId,
              })
            }}
            CollapseActivekey={CollapseActivekey}
            changeAction={(action, v: any) => {
              switch (action) {
                case 'active':
                  setCollapseActivekey(v)
                  break
                case 'check':
                  setSeeOtherDoctor(v)
                  break
                case 'again':
                  if (v) {
                    setReuseType(true)
                    setReuseTreatmentId(v)
                  } else {
                    setReuseType(false)
                    setReuseTreatmentId('')
                  }
                  break
                default:
                  break
              }
            }}
          />

          {/* <Label label='病人信息' style={{ marginLeft: 20 }} />
          <Divider />
          <Form
            form={patientForm}
            autoComplete='off'
            colon={false}
            labelCol={{ flex: '5.625rem' }}
            className={styles.patient}
            style={{ marginRight: 20, marginTop: 10 }}
          >
            <Form.Item
              label='身高'
              name='patientHeight'
              rules={[
                {
                  message: '请输入整数值',
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input suffix='cm' />
            </Form.Item>
            <Form.Item
              label='体重'
              name='patientWeight'
              rules={[
                {
                  message: '请输入最多带两位小数的数值',
                  pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
                },
              ]}
            >
              <Input suffix='kg' />
            </Form.Item>
            <Form.Item
              label='体温'
              name='patientTemperature'
              rules={[
                {
                  message: '请输入最多带一位小数的数值',
                  pattern: new RegExp(/^[0-9]+((\.)[0-9]?)?$/),
                },
              ]}
            >
              <Input suffix='℃' />
            </Form.Item>
            <Form.Item
              label='脉搏'
              name='patientPulse'
              rules={[
                {
                  message: '请输入整数值',
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input suffix='次/分' />
            </Form.Item>
            <Form.Item
              label='收缩压'
              name='patientSystolicPressure'
              rules={[
                {
                  message: '请输入整数值',
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input suffix='mmHg' />
            </Form.Item>
            <Form.Item
              label='舒张压'
              name='patientDiastolicPressure'
              rules={[
                {
                  message: '请输入整数值',
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input suffix='mmHg' />
            </Form.Item>
          </Form> */}
          {/* <Label label='就诊信息' style={{ marginTop: 0, marginLeft: 20 }} />
          <Divider style={{ marginBottom: 0, color: theme.c3 }} />
          <Form
            form={treatmentForm}
            colon={false}
            labelCol={{ flex: '6.25rem' }}
            className={styles.doctor}
            style={{ marginTop: 10, marginRight: 10 }}
          >
            <Form.Item
              label='挂号医生'
              name='registrationDoctorName'
              rules={[{ max: 40 }]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item label='医生科室' name='treatmentDepartmentId'>
              <Select
                disabled={!isDoctorEditable}
                onChange={(v) => {
                  treatmentForm.setFieldsValue({
                    treatmentDoctorId: undefined,
                  })
                  dispatch(setDoctorsInDepartment([]))
                  dispatch(getDoctorsInDepartment(v as string))
                }}
              >
                {departments.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label='接诊医生'
              name='treatmentDoctorId'
              rules={[
                {
                  required: true,
                  message: '接诊医生不能为空',
                },
              ]}
            >
              <Select disabled={!isDoctorEditable}>
                {doctors.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='就诊类型' name='treatmentCategory'>
              <Select
                // open={isOpen}
                onSelect={(values: number) => {
                  treatmentCategoryChose(values as number)
                }}
                // onClick={() => {
                //   setIsOpen(!isOpen)
                // }}
                // onBlur={() => {
                //   setIsOpen(false)
                // }}
              >
                {TreatmentCategories.map((c) => (
                  <Select.Option key={c} value={c}>
                    {getTreatmentCategoryName(c)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Row justify='center' style={{ paddingLeft: 10, marginTop: 20 }}>
              <Form.Item name='receiveTag' valuePropName='checked'>
                <Checkbox>是否复诊</Checkbox>
              </Form.Item>
              <Form.Item name='infectiousDisease' valuePropName='checked'>
                <Checkbox style={{ marginLeft: 10 }}>是否传染病</Checkbox>
              </Form.Item>
            </Row>
          </Form> */}
        </Col>
        {/* 辅助诊断 */}
        {checkData && checkData.find((v: any) => v.code === '4') && (
          <div
            className={styles.drawer}
            style={{
              right: isDrawer ? '0' : '-360px',
            }}
          >
            <div className={styles.drawerContainer}>
              <div className={styles.drawerLeft}>
                <div
                  className={styles.drawerBtn}
                  style={{ background: '#027AFF' }}
                  onClick={() => arouseDrawer()}
                >
                  辅助诊断
                  {isDrawer ? (
                    <DoubleRightOutlined className={styles.drawerBtnIcon} />
                  ) : (
                    <DoubleLeftOutlined className={styles.drawerBtnIcon} />
                  )}
                </div>
                {isDrawer && (
                  <div
                    className={styles.drawerBtn}
                    style={{ marginTop: '10px', background: '#47DF56' }}
                    onClick={setPatientInfo}
                  >
                    刷新
                    <SyncOutlined className={styles.drawerBtnIcon} />
                  </div>
                )}
              </div>
              {isDrawer && (
                <iframe
                  src={iframeUrl}
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: 0,
                  }}
                  scrolling='yes'
                  frameBorder='0'
                />
              )}
            </div>
          </div>
        )}

        <DiagnosisModal
          visible={isDrawerVisible}
          onOk={() => {
            dispatch(setNatureAsync({ code: '1', codeName: 'true' }))
              .then(unwrapResult)
              .then(() => {
                setIsDrawerVisible(false)
                setIsDrawer(true)
              })
          }}
          onCancel={() => {
            setIsDrawerVisible(false)
          }}
        />
        <PrintTemplateModal
          templateCategory={PrintTemplateCategory.Zds}
          visible={isPrintModalVisible && !!treatment?.id}
          treatmentId={treatment?.id}
          onCancel={() => setIsPrintModalVisible(false)}
          onOk={() => {
            setIsPrintModalVisible(false)
          }}
        />
        {/* <SaveTemplateModal
          categoryName=''
          detail={targetTemplate}
          visible={!!targetTemplate}
          onCancel={() => setTargetTemplate(undefined)}
          onSave={() => setTargetTemplate(undefined)}
        /> */}
        <HistoryCaseDialog
          visible={historyDialog}
          patientName={patientData?.treatment.patientName}
          patientId={patientData?.treatment.patientId}
          onCancel={() => setHistoryDialog(false)}
        />
      </Row>
      {state === 1 ? (
        <></>
      ) : (
        <Row
          style={{
            width: '100%',
            height: '3.125rem',
            backgroundColor: '#fff',
            paddingRight: '40px',
          }}
          align='middle'
        >
          {notice && notice.recentlyTreatmentTime && (
            <div
              style={{
                marginLeft: 15,
                fontSize: '1rem',
                color: theme.e,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                setTempType(true)

                setIsModalVisible(true)
              }}
            >
              最近就诊：
              {moment(notice.recentlyTreatmentTime).format('YYYY/MM/DD')}
              <Tooltip title={notice?.disease}>
                <span
                  style={{
                    marginLeft: 15,
                    maxWidth: 400,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  疾病名称：{notice?.disease}
                </span>
              </Tooltip>
            </div>
          )}
          <div style={{ flex: 1 }} />
          <Space>
            <RemindTips />
            {registration?.insuranceCode === 'CHONGQING' && (
              <Button
                type='primary'
                // disabled={!treatment?.id}
                onClick={async () => {
                  if (registration?.patientId) {
                    beforeAlert('requestString')
                    setIsShowBeforeAlert(true)
                  }
                }}
              >
                医保事前提醒
              </Button>
            )}

            <Button
              type='primary'
              onClick={async () => {
                const flag = localStorage.getItem('FIRSTNOXY')
                // 勾选过下次不再提示
                if (flag == '1') {
                  await submit()
                  saveEdit()
                } else {
                  if (registrations?.insuranceCode !== 'ZIFEI') {
                    if (
                      selectedDiseases?.length &&
                      selectedDiseases?.[0]?.diagType != 1 &&
                      selectedDiseases?.[0]?.diagType
                    ) {
                      Modal.confirm({
                        centered: true,
                        title: '',
                        content: (
                          <>
                            <div>
                              <ExclamationCircleFilled
                                style={{ color: '#FFAE55', fontSize: 18 }}
                              />
                              &nbsp;主诊断非西医诊断，是否确定保存？
                            </div>
                            <div style={{ position: 'relative', bottom: -60 }}>
                              <Checkbox
                                onChange={({ target }) => {
                                  const { checked } = target
                                  const flag = checked ? '1' : '0'
                                  localStorage.setItem('FIRSTNOXY', flag)
                                }}
                                style={{ margin: '10px 0' }}
                              >
                                下次不再提示
                              </Checkbox>
                            </div>
                          </>
                        ),
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: async () => {
                          await submit()
                          saveEdit()
                        },
                        onCancel: () => {
                          return
                        },
                      })
                    } else {
                      await submit()
                      saveEdit()
                    }
                  } else {
                    await submit()
                    saveEdit()
                  }
                }
              }}
              loading={isCreating}
            >
              保存
            </Button>
          </Space>
        </Row>
      )}

      <SaveTemplateModal
        templates={templates?.filter((v) => v?.categoryName != '收藏')}
        medicalRecord={1}
        categoryName={
          templates.find((v) => v.id == selectedCategoryId)?.categoryName
        }
        detail={targetTemplate}
        visible={SaveTemplateModalVasible}
        onCancel={() => {
          setSaveTemplateModalVasible(false)
          setTargetTemplate(undefined)
        }}
        onSave={() => {
          setSaveTemplateModalVasible(false)
          setTargetTemplate(undefined)
          dispatch(getTempalteDetailList(selectedCategoryId))
            .then(unwrapResult)
            .then((details) => {
              setTemplateDetails({
                ...templateDetails,
                [selectedCategoryId]: details,
              })
            })
        }}
      />

      <PreferencesSetModal
        tabNum={'1'}
        isModalVisible={preferencesSetShow}
        handleCancel={() => setiPreferencesSetShow(false)}
        onsubmit={() => {
          PreferencesSetChange && PreferencesSetChange()
          setiPreferencesSetShow(false)
        }}
      />
      <UploadModal
        uploadModalVisible={isUploadVisible}
        accept={'.png,.tiff,.jpg,.jpeg,bmp,.heic,.webp'}
        id={registration?.id || registrationId}
        uploadType={6}
        onCancel={() => {
          setIsUploadVisible(false)
        }}
        maxCount={10}
        onSubmit={(v, i, id) => {
          if (fileList.length + v.length > 10) {
            notification.info({
              message: '已达到最大上传数量，请先删除再上传！',
            })
            return
          }
          dispatch(deleteUploadFileList({ ids: id })).then(unwrapResult)
          setFileList([...fileList, ...v])
          setIsUploadVisible(false)
        }}
      />
      {/* 通用病历引用病历及处方 */}
      <Modal
        visible={isCopyReciptModalVisible}
        footer={null}
        destroyOnClose
        className={styles.modal}
      >
        <div
          style={{
            padding: '32px',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <InfoCircleOutlined
            style={{
              color: '#faad14',
              marginRight: '10px',
              fontSize: '22px',
            }}
          />
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            是否从处方复制价格？
          </span>
        </div>
        <CloseOutlined
          style={{
            position: 'absolute',
            color: '#8c8c8c',
            right: 10,
            top: 20,
            marginRight: '10px',
            fontSize: '22px',
          }}
          onClick={() => {
            setIsCopyReciptModalVisible(false)
            setcfBtnLoading(false)
            setmlBtnLoading(false)
            setkcBtnLoading(false)
          }}
        />
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            style={{
              marginRight: '14px',
            }}
            loading={mlBtnLoading}
            onClick={() => {
              setmlBtnLoading(true)
              dispatch(
                copyTreatment({
                  ...isCopyReciptParam,
                  registrationId,
                  copyAmountType: 0,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  setmlBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                  dispatch(
                    getTreatment({
                      registrationId,
                      treatmentId,
                    })
                  )
                })
                .catch((v)=>{
                  setmlBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                })
            }}
          >
            使用目录价格
          </Button>
          <Button
            style={{
              marginRight: '14px',
            }}
            loading={cfBtnLoading}
            type='primary'
            onClick={() => {
              setcfBtnLoading(true)
              dispatch(
                copyTreatment({
                  ...isCopyReciptParam,
                  registrationId,
                  copyAmountType: 1,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  setcfBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                  dispatch(
                    getTreatment({
                      registrationId,
                      treatmentId,
                    })
                  )
                })
                .catch((v)=>{
                  setcfBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                })
            }}
          >
            使用处方价格
          </Button>
          <Button
            style={{
              marginRight: '14px',
            }}
            loading={kcBtnLoading}
            type='primary'
            onClick={() => {
              setkcBtnLoading(true)
              dispatch(
                copyTreatment({
                  ...isCopyReciptParam,
                  registrationId,
                  copyAmountType: 2,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  setkcBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                  dispatch(
                    getTreatment({
                      registrationId,
                      treatmentId,
                    })
                  )
                })
                .catch(()=>{
                  setkcBtnLoading(false)
                  setIsCopyReciptModalVisible(false)
                })
            }}
          >
            使用库存价格
          </Button>
        </Row>
      </Modal>
      <Modal
        title='温馨提醒'
        visible={isTipsModalVisible}
        width={500}
        footer={null}
        maskClosable={false}
        closable
        onOk={() => {
          setIsTipsModalVisible(false)
        }}
        onCancel={() => {
          setIsTipsModalVisible(false)
        }}
      >
        <Text>
          该患者存在备案的特慢病信息，点击引用
          {searchMedical?.map((v: any) => {
            const disease = {
              icds: v.diseaseCode,
              id: v.id,
              name: v.diseaseName,
            }
            return (
              <span
                style={{
                  color: '#027AFF',
                  padding: '0 10px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
                key={v.icds}
                onClick={() => {
                  if (
                    !selectedDiseases.find((d) => d?.icds === disease?.icds)
                  ) {
                    setSelectedDiseases(
                      selectedDiseases.concat(disease as Disease)
                    )
                    diseasesChose(v)
                    setIsTipsModalVisible(false)
                  }
                }}
              >
                {`${disease.name}/${disease.icds}`}
              </span>
            )
          })}
        </Text>
        <Row justify='end' style={{ marginTop: '20px' }}>
          <Button
            onClick={() => setIsTipsModalVisible(false)}
            style={{ marginRight: '10px' }}
          >
            不引用
          </Button>
          <Button
            type='primary'
            onClick={() => {
              const data = searchMedical?.map((v: any) => {
                return {
                  icds: v.diseaseCode,
                  id: v.id,
                  name: v.diseaseName,
                }
              })
              
              setSelectedDiseases(data)
              diseasesChose(searchMedical?.[data?.length - 1])
              setIsTipsModalVisible(false)
            }}
          >
            全部引用
          </Button>
        </Row>
      </Modal>
    </div>
  )
}

export const Diagnosis = forwardRef(RealDiagnosis)
