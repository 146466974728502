import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { api } from "../../../../app/applicationSlice";
import { RootState, RootThunk } from "../../../../app/store";
import * as service from "../../../../services/treatmentCostModal";
import { treatmentCostParams } from "../../../../services/treatmentCost";
interface TreatCostState {
  Detail: [],
  Total: 0,
  Current: 1,


}
const initialState = {
  Detail: [],
  Total: 0,
  Current: 1,


} as TreatCostState;

const treatmentCostModalSlice = createSlice({
  name: "treatmentCostModalSlice",
  initialState,
  reducers: {
    setDetail(state, action) {
      state.Detail = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },



  },
});

export const { setDetail,
  setTotal,
  setCurrent, } = treatmentCostModalSlice.actions;

export const selectTreatmentCostData = (state: RootState) => state.treatmentModalCost.Detail

export const selectTotal = (state: RootState) => state.treatmentModalCost.Total

export const selectCurrent = (state: RootState) => state.treatmentModalCost.Current




export function getTreatmentCostDetailList(params: treatmentCostParams): RootThunk {
  return api(service.getTreatmentCostDetail(params), (data: any, dispatch) => {
    dispatch(setDetail(data.records))
    dispatch(setTotal(data.total))
  });
}

export default treatmentCostModalSlice.reducer;
