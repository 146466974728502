/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-29 14:08:18
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-05 11:25:56
 */
import { Select } from "antd";
import React from "react";
import { EditableColumnType } from "../../compnents/list/EditableList";
import "../putstorelist/notshow.css";
const { Option } = Select;

export const QingDouColumns = (): EditableColumnType<any>[] => {
  return [
    {
      title: "序号",
      dataIndex: "",
      align: "center",
      width: "5%",
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "产品信息",
      align: "center",
      width: "20%",
      ellipsis: true,
      dataIndex: "productInfo",
    },
    {
      title: "批号",
      align: "center",
      width: "5%",
      dataIndex: "batchNo",
    },
    {
      title: "库存数量",
      align: "center",
      dataIndex: "count",
    },
    {
      title: "售价",
      align: "center",
      dataIndex: "retailPrice",
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "",
      render: (_, t) => {
        if (t.targetInventoryLedgerId) {
          return "装斗";
        } else {
          return "清斗";
        }
      },
    },
    {
      title: "目标批次",
      align: "center",
      dataIndex: "targetBatchNo",
      render: (_, t) => {
        return t.targetBatchNo > 0 ? t.targetBatchNo : "";
      },
    },
    {
      title: "操作时间",
      align: "center",
      dataIndex: "createTime",
    },
  ];
};

export const ModalColumnsOne = (): EditableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "商品信息",
      dataIndex: "producInfo",
      render: function Element(_, record) {
        return (
          <div>
            <h3>{record.name}</h3>
            <div>
              {record.tenantSupplierName} / {record.spec} /{" "}
              {record.doseCategory}
            </div>
          </div>
        );
      },
    },
    {
      title: "批号",
      align: "center",
      dataIndex: "batchNo",
    },
    {
      title: "库存数量",
      align: "center",
      dataIndex: "count",
    },
    {
      title: "售价",
      align: "center",
      dataIndex: "retailPrice",
    },
    {
      title: "操作",
      align: "center",
      render: (_, t) => {
        return t.flag === 1 ? "清斗" : "装斗";
      },
    },
  ];
};
export const ModalColumnsTwo = (
  onclick: (text: string, t: any) => void
): EditableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "商品信息",
      dataIndex: "producInfo",
      render: function Element(_, record) {
        return (
          <div>
            <h3>{record.name}</h3>
            <div>
              {record.tenantSupplierName} / {record.spec} /{" "}
              {record.doseCategory}
            </div>
          </div>
        );
      },
    },
    {
      title: "批号",
      align: "center",
      dataIndex: "batchNo",
    },
    {
      title: "库存数量",
      align: "center",
      dataIndex: "count",
    },
    {
      title: "售价",
      align: "center",
      dataIndex: "retailPrice",
    },
    {
      title: "操作",
      align: "center",
      render: (_, t) => {
        return t.flag === 1 ? "清斗" : "装斗";
      },
    },
    {
      title: "台账Id",
      dataIndex: "tenantInventoryLedgerId",
      className: "notshow",
    },
    {
      title: "单据Id",
      dataIndex: "tenantInventoryReceiptsId",
      className: "notshow",
    },
    {
      title: "物资Id",
      dataIndex: "tenantMaterialsCatalogId",
      className: "notshow",
    },
    {
      title: "选择目标批次",
      align: "center",
      dataIndex: "flag",
      editable: true,
      onSave: (prev, next) => {
        onclick("edit", { prev, next });
      },
      render: function Element(_, t) {
        const optionList = t?.outInvCleanListTargetList;
        return (
          <Select
            style={{ width: "100%" }}
            placeholder="批次号|数量|单位"
            defaultValue={0}
            onSelect={(value: any) => {
              const selectItem = t?.outInvCleanListTargetList.filter(
                (v: any, i: number) => i === value
              )[0];
              const next = selectItem;
              onclick("edit", { prev: t, next });
            }}
          >
            {optionList ? (
              optionList?.map((v: any, i: number) => (
                <>
                  {/* <Option value={23} disabled>
                    批次号库存|数量|单位
                  </Option> */}
                  <Option value={i} key={i}>
                    {`${v?.targetBatchNo}|
                      ${v?.targetCount}|
                      ${v?.targetAccountUnit}`}
                  </Option>
                </>
              ))
            ) : (
              <></>
            )}
          </Select>
        );
      },
    },
  ];
};
