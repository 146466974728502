/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-15 14:11:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-31 15:02:06
 */
/* eslint-disable react/display-name */
import { Button, Popconfirm, Space, TableColumnType } from 'antd'
import React, { ReactElement } from 'react'
import { getInvoiceStatus, getPayFlagName } from '../../../models/payment'
import { getGenderName } from '../../../models/user'
import { Dimen } from '../../../models/dimen'
import { getAge } from '../../../utils/StringUtils'

export const getCardType = (cardType: string) => {
  switch (cardType) {
    case '1':
      return '电子凭证'
    case '2':
      return '身份证'
    case '3':
      return '医保卡'
    default:
      return ''
  }
}
type Action = 'result' | 'apply' | 'detail' | 'reason' | 'again'

export const Columns = (
  startIndex: number,
  onAction: (action: Action, t: any) => void
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '5rem',
      fixed: 'left',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '门诊号',
      key: 'outpatientNo',
      align: 'center',
      fixed: 'left',
      width: Dimen.OutpatientNo,
      render: (_, { registration }) => registration.outpatientNo,
    },
    {
      title: '患者姓名',
      key: 'patientName',
      align: 'center',
      fixed: 'left',
      width: '12em',
      ellipsis:true,
      render: (_, { treatment }) => {
        return (
          <div style={{ width: '100%', height: '100%' }}>
            <>{treatment.patientName}</>
          </div>
        )
      },
    },
    {
      title: '病历号',
      key: 'patientId',
      align: 'center',
      width: '8em',
      dataIndex: 'patientId',
      render: (_, { registration }) => registration.patientId,
    },
    {
      title: '结算险种',
      key: 'insuranceCode',
      width: '8em',
      align: 'center',
      render: (_, { registration }) => {
        return !registration ||
          !registration.insuranceCode ||
          registration.insurance === 'ZIFEI'
          ? '自费'
          : '智慧医保'
      },
    },
    {
      title: '聚合支付状态',
      key: 'payStatus',
      width: '10em',
      align: 'center',
      render: (_, { payStatus }) => {
        return payStatus == 0
          ? '支付中'
          : payStatus == 1
          ? '已支付'
          : payStatus == 2
          ? '支付失败'
          : payStatus == 3
          ? '已退费'
          : '撤销支付'
      },
    },
    {
      title: '读卡方式',
      key: 'cardType',
      align: 'center',
      width: Dimen.Sex,
      render: (_, { registration }) => {
        return getCardType(registration.cardType)
      },
    },
    {
      title: '收费标志',
      key: 'settlementCategory',
      align: 'center',
      width: '8em',
      render: (_, { treatment }) => {
        return getPayFlagName(Number(treatment?.settlementCategory))
      },
    },
    {
      title: '性别',
      key: 'patientSex',
      align: 'center',
      width: Dimen.Sex,
      render: (_, { treatment }) => getGenderName(treatment.patientSex),
    },
    {
      title: '年龄',
      key: 'patientAge',
      align: 'center',
      width: Dimen.Age,
      render: (_, { treatment }) =>
        getAge(treatment?.patientAge, treatment?.patientMonth),
    },
    {
      title: '诊断疾病',
      key: 'disease',
      align: 'center',
      render: (_, { treatment }) => ({
        props: {
          style: {
            maxWidth: '14rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
        children: treatment.disease,
      }),
    },
    {
      title: '接诊科室',
      key: 'treatmentDepartmentName',
      align: 'center',
      width: '8em',
      render: (_, { treatment }) => treatment.treatmentDepartmentName,
    },
    {
      title: '接诊医生',
      key: 'treatmentDoctorName',
      align: 'center',
      width: '8em',
      render: (_, { treatment }) => treatment.treatmentDoctorName,
    },
    {
      title: '报销金额',
      key: 'treatmentDoctorName',
      align: 'right',
      width: '7em',
      render: (_, { insurancePayAmount }) =>
        insurancePayAmount === undefined ? '' : insurancePayAmount?.toFixed(2),
    },
    {
      title: '现金金额',
      key: 'realPayAmount',
      align: 'right',
      width: '7em',
      render: (_, { realPayAmount }) =>
        realPayAmount === undefined ? '' : realPayAmount?.toFixed(2),
    },
    {
      title: '总金额',
      key: 'amount',
      width: '7em',
      align: 'right',
      render: (_, { amount }) =>
        amount === undefined ? '' : amount?.toFixed(2),
    },
    {
      title: '收费时间',
      key: 'chargeOrRefundTime',
      align: 'center',
      width: Dimen.Time,
      render: (_, { treatment }) => treatment.chargeOrRefundTime,
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '6rem',
      fixed: 'right',
      render: (_, t) => {
        return (
          <Space key={t.registration.patientId}>
            {t.payStatus == 1 && (
              <Popconfirm
                placement='top'
                title={'是否退费 ?'}
                onConfirm={() => onAction('apply', t)}
                okText='确认'
                cancelText='取消'
              >
                <Button size='small' type='primary'>
                  退费
                </Button>
              </Popconfirm>
            )}
            {/* <a onClick={() => onAction('apply', t)}>查看详情</a> */}
          </Space>
        )
      },
    },
  ]
}
