import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import { LoadingState } from '../../../models/common'
import * as R from '../../../models/registration'
import { Registration } from '../../../models/registration'
import {
  BeautyValue,
  HistoryMedicalValue,
  MedicalValue,
} from '../../../models/template'
import * as Tr from '../../../models/treatment'
import { Treatment } from '../../../models/treatment'
import { startTreatment as apiStartTreatment } from '../../../services/registration'
import {
  BeautyParams,
  copyTreatment as apiCopyTreatment,
  CopyTreatmentParams,
  createTreatment as apiCreateTreatment,
  DetailBeauty,
  getBeautyClinic,
  getBeautyDetail,
  getHistoryMedical,
  getMedicalDetail,
  getMedicalList,
  getMedicalRemove,
  getTreatment as apiGetTreatment,
  GetTreatmentDetailParams,
  getUrlLink,
  getVideoSaveFn,
  getVideoSaveFnParams,
  historicalRecordsCopyFn,
  historicalRecordsCopyFnParams,
  historicalRecordsListFn,
  historicalRecordsListFnParams,
  HistoryMedical, isAllZifeiParams,
  MedicalDetail,
  MedicalParams,
  MedicalRemove,
  SaveBeauty,
  SaveBeautyBody, selectIsAllZifei,
  urlLinkParams,
} from '../../../services/treatment'
import { setTreatmentRegistration } from '../treatmentSlice'

interface BeautyClinicState {
  registration?: Registration
  treatment?: Treatment
  creatingState: LoadingState
}

const initialState = {
  registration: undefined,
  creatingState: LoadingState.Idle,
} as BeautyClinicState

export const createTreatment = createAsyncThunk<void, Treatment, RootThunkApi>(
  'BeautyClinic/createTreatmentStatus',
  async (treatment, api) => {
    return sendAsync(apiCreateTreatment(treatment), api).then(() => {
      // const id = api.getState().beautyClinic.registration?.id;
      // if (id) {
      //   api.dispatch(getTreatment({ registrationId: id,medicalRecord:1 }));
      // }
    })
  }
)

const beautyClinicSlice = createSlice({
  name: 'beautyClinic',
  initialState,
  reducers: {
    setRegistration: (state, action: PayloadAction<Registration>) => {
      state.registration = action.payload
    },
    setTreatment: (state, action: PayloadAction<Treatment | undefined>) => {
      state.treatment = action.payload
    },
    setTreatmentCreatingState: (state, action: PayloadAction<LoadingState>) => {
      state.creatingState = action.payload
    },
    resetBeautyClinic: (state) => {
      state.registration = undefined
      state.treatment = undefined
      state.creatingState = LoadingState.Idle
    },
  },
})

const { setRegistration } = beautyClinicSlice.actions

export const {
  setTreatmentCreatingState,
  resetBeautyClinic,
  setTreatment,
} = beautyClinicSlice.actions

export function getTreatment(params: GetTreatmentDetailParams): RootThunk {
  return api(
    apiGetTreatment(params),
    (data: Record<string, never>, dispatch) => {
      const registration = R.fromJson(data.registration)
      dispatch(setRegistration(registration))
      dispatch(setTreatmentRegistration(registration))
      const treatment =
        JSON.stringify(data.treatment) !== '{}'
          ? Tr.fromJson(data.treatment)
          : undefined
      dispatch(setTreatment(treatment))
    }
  )
}

export const getTreatments = createAsyncThunk<
  void,
  GetTreatmentDetailParams,
  RootThunkApi<void>
>('beautyClinic/getTreatments', async (params, api) => {
  return sendAsync(apiGetTreatment(params), api)
})

export function startTreatment(registrationId: string): RootThunk {
  return api(apiStartTreatment(registrationId), () => {
    // Do nothing.
  })
}

export const copyTreatment = createAsyncThunk<
  void,
  CopyTreatmentParams,
  RootThunkApi<void>
>('beautyClinic/copyTreatmentStatus', async (params, api) => {
  return sendAsync(apiCopyTreatment(params), api).then(() => {
    // do nothing.
  })
})

export const getUrl = createAsyncThunk<void, urlLinkParams, RootThunkApi>(
  'beautyClinic/getUrl',
  async (params, api) => {
    return sendAsync(getUrlLink(params), api)
  }
)

// 医美/手术模板列表
export const getBeaytyClinicList = createAsyncThunk<
  BeautyValue[],
  BeautyParams,
  RootThunkApi
>('beautyClinic/getList', async (params, api) => {
  return sendAsync(getBeautyClinic(params), api)
})

// 医美/手术模板详情
export const getBeaytyClinicDetail = createAsyncThunk<
  string,
  DetailBeauty,
  RootThunkApi
>('beautyClinic/getList', async (params, api) => {
  return sendAsync(getBeautyDetail(params), api)
})

// 医美/手术保存
export const getBeautyClinicSaveAsync = createAsyncThunk<
  any,
  SaveBeautyBody,
  RootThunkApi
>('beautyClinic/getList', async (body, api) => {
  return sendAsync(SaveBeauty(body), api)
})
// 医美/上传影像保存
export const getVideoSave = createAsyncThunk<
  any,
  getVideoSaveFnParams,
  RootThunkApi
>('beautyClinic/getVideoSave', async (body, api) => {
  return sendAsync(getVideoSaveFn(body), api)
})

// 医美/手术模板记录列表
export const getMedicalListAsync = createAsyncThunk<
  MedicalValue[],
  MedicalParams,
  RootThunkApi
>('beautyClinic/getList', async (params, api) => {
  return sendAsync(getMedicalList(params), api)
})

// 医美/手术模板记录详情
export const getMedicalDetailAsync = createAsyncThunk<
  string,
  MedicalDetail,
  RootThunkApi
>('beautyClinic/getList', async (params, api) => {
  return sendAsync(getMedicalDetail(params), api)
})

// 医美/手术模板记录详情
export const getMedicalRemoveAsync = createAsyncThunk<
  string,
  MedicalRemove,
  RootThunkApi
>('beautyClinic/getList', async (body, api) => {
  return sendAsync(getMedicalRemove(body), api)
})

// 历史医美记录
export const getHistoryMedicalAsync = createAsyncThunk<
  HistoryMedicalValue,
  HistoryMedical,
  RootThunkApi
>('beautyClinic/getHistoryList', async (params, api) => {
  return sendAsync(getHistoryMedical(params), api)
})

// 查看/选择 历史病历列表
export const historicalRecordsList = createAsyncThunk<
  any,
  historicalRecordsListFnParams,
  RootThunkApi
>('beautyClinic/historicalRecordsList', async (params, api) => {
  return sendAsync(historicalRecordsListFn(params), api)
})

// 查看/选择 历史病历复制
export const historicalRecordsCopy = createAsyncThunk<
  any,
  historicalRecordsCopyFnParams,
  RootThunkApi
>('beautyClinic/historicalRecordsCopy', async (params, api) => {
  return sendAsync(historicalRecordsCopyFn(params), api)
})
// 查询传入的指定的recipeId 或者treatmentId，是否全是自费项目(1:是，0：否)
export const selectIsAllZifeiAsync = createAsyncThunk<
    any,
    isAllZifeiParams,
    RootThunkApi
    >('recipe/isAllZifei', async (params, api) => {
  return sendAsync(selectIsAllZifei(params), api)
})

export const selectRegistration = (
  state: RootState
): Registration | undefined => state.beautyClinic.registration

export const selectTreatmentB = (state: RootState): Treatment | undefined =>
  state.beautyClinic.treatment

export const selectTreatmentCreatingState = (state: RootState): LoadingState =>
  state.beautyClinic.creatingState

export default beautyClinicSlice.reducer
