/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
 * @Description: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-10 09:32:44
 * @LastEditors: sj
 * @LastEditTime: 2022-08-22 16:56:54
 */
import { unwrapResult } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd'
import styles from "./outRecipe.module.css"
import { selectInsuranceArray, selectTenantAddress, selectTenantAreaCd, selectTenantCategory, selectTenantName, selectUserId, selectUserName } from '../../app/applicationSlice'
import { EditableList } from '../../compnents/list/EditableList'
import { DateRangeType, DateSelect, getDateRange } from '../../compnents/form/DateSelect'
import { Columns } from './columns'
import { outRecipeListAsync } from './outRecipeSlice'
import moment from 'moment'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { serverType } from '../../services/commodity'
import { medicalSendHttp, strParse } from '../../utils/MedicalUtils'
import { CheckResultAsync, buyResultAsync, repealRecipeAsync } from './outRecipeSlice'
import { User } from '../../models/user'
import { getDoctorList } from '../sales/DoctorAccont/doctorAuditSlice'
import { stringTrim } from '../../utils/StringUtils'

function OutRecipe() {
  const dispatch = useDispatch<RootDispatch>()


  // form 表单初始化
  const [form] = Form.useForm()

  const [repealForm] = Form.useForm()

  const [head, tail] = getDateRange(DateRangeType.ThisWeek);
  // 表格参数
  const [queries, setQueries] = useState({
    current: 0,
    size: 10,

  })
  const [data, setData] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [visible, setVisible] = useState(false)

  const [message, setMessage] = useState('')

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const insuranceArray = useSelector(selectInsuranceArray)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const baseData = useSelector(selectInsuranceItem)

  const [action, setAction] = useState('')

  const [doctors, setDoctors] = useState<User[]>([]);

  const [loading, setLoading] = useState(false)

  const [treatmentId, setTreatmentId] = useState(0)

  useEffect(() => {
    dispatch(getDoctorList())
      .then(unwrapResult)
      .then((doctors) => setDoctors(doctors));
  }, [])


  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  // 7104
  const repealRecipe = (
    serverType: serverType,
    repealObj: any,
    responseString?: string
  ) => {
    dispatch(
      repealRecipeAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          ...repealObj,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '处方撤销',
            },
            (call: any, err) => {
              if (!err) {
                repealRecipe('response', repealObj, JSON.stringify(call.netmessage))
              } else {
                setLoading(false)
              }
            }
          )
        } else if (serverType === 'response') {
          setLoading(false)
          close()
          getTable()
        }
      }).finally(() => {
        setLoading(false)
      })
  }

  // 7160
  const CheckResult = (
    serverType: serverType,
    ybExternalNo: number,
    registrationId:any,
    responseString?: string

  ) => {
    dispatch(
      CheckResultAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          ybExternalNo,
          registrationId,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '处方审核结果查询',
            },
            (call: any, err) => {
              !err && CheckResult('response', ybExternalNo, registrationId,JSON.stringify(call.netmessage))
            }
          )
        } else if (serverType === 'response') {
          setMessage(res)
          setVisible(true)
        }
      })
  }

  // 7161
  const buyResult = (
    serverType: serverType,
    ybExternalNo: number,
    registrationId:number,
    responseString?: string
  ) => {
    dispatch(
      buyResultAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          ybExternalNo,
          registrationId,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '处方购药结果查询',
            },
            (call: any, err) => {
              !err && buyResult('response', ybExternalNo,registrationId, JSON.stringify(call.netmessage))
            }
          )
        } else if (serverType === 'response') {
          setMessage(res)
          setVisible(true)
        }
      })
  }

  useEffect(() => {
    form.setFieldsValue({
      registrationTimeHead: head,
      registrationTimeTail: tail
    })
    form.submit()
  }, [])

  useEffect(() => {
    if (!queries.current) return
    getTable()
  }, [queries])

  // 获取表格数据
  const getTable = () => {
    setPageLoading(true)
    dispatch(
      outRecipeListAsync({
        ...queries,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setData(res)
      })
      .finally(() => {
        setPageLoading(false)
      })
  }

  const titleList = ['审核结果查询', '购药结果查询', '确认撤销']

  const getAction = (str: string): number => {
    switch (str) {
      case 'check':
        return 0;
      case 'buy':
        return 1;
      case 'repeal':
        return 2
      default:
        return 0
    }
  }

  const rules = {
    rules: [{
      required: true
    }]
  }

  const close = () => {
    setVisible(false)
    setMessage('')
    repealForm.resetFields()
  }

  return (
    <div className={styles.wrap}>
      <Form form={form} onFinish={(values: any) => {
        setQueries({ ...queries, ...values, current: 1 })
      }}>
        <Row gutter={11} style={{ height: '42px' }}>
          <Col span={4}>
            <Form.Item name='param'>
              <Input
                size='middle'
                placeholder='搜索患者姓名'
                allowClear
                prefix={<SearchOutlined />}
                onChange={form.submit}
              />
            </Form.Item>
          </Col>
          <Col >
            <DateSelect
              placeholder='请选择时间'
              labelPrefix=''
              namePrefix='registrationTime'
              initialValue={DateRangeType.ThisWeek}
              allowClear={false}
              style={{ width: '8.5rem' }}
              onChange={form.submit}
            />
          </Col>
          <Col >
            <Button htmlType='submit' type='primary' style={{ float: 'right' }}>
              查询
            </Button>
          </Col>
        </Row>
      </Form>
      <EditableList
        loading={pageLoading}
        columns={Columns((id, action,t) => {
          setAction(action)

          if (action == 'check') {
            CheckResult('requestString', id,t.registrationId)
          } else if (action == 'buy') {
            buyResult('requestString', id,t.registrationId)
          } else if (action == 'repeal') {
            setVisible(true)
            setTreatmentId(id)
          }
        },
          (queries.size || 10) * ((queries.current || 1) - 1))}
        onChangePage={(current, size) => {
          setQueries({
            ...queries,
            current,
            size: size || 10,
          })
        }}
        page={{
          size: queries.size || 10,
          current: queries.current || 1,
          total: data?.total || 0,
          items: data?.records || [],
        }}
        rowKey={(_, i) => `${i}`}
      />
      <Modal
        title={titleList[getAction(action)]}
        visible={visible}
        width={800}
        footer={null}
        onCancel={close}
      >
        {
          getAction(action) != 2 ? (
            <div dangerouslySetInnerHTML={{ __html: message }}></div>
          ) : (
            <div>
              <Form form={repealForm}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 14 }}
                onFinish={(values: any) => {
                  const data = {
                    ...values,
                    treatmentId
                  }
                  repealRecipe('requestString', data)
                }}>

                <Form.Item name='undoRea' getValueFromEvent={stringTrim} {...rules} label='原因'>
                  <Input.TextArea
                    size='middle'
                    placeholder='请输入撤销原因'
                    allowClear
                  />
                </Form.Item>

                <Form.Item name="doctorId" label='医生' rules={[{
                  required: true,
                  message: '请选择医生'
                }]}>
                  <Select
                    style={{ width: 220 }}
                    placeholder="请选择医生"
                    allowClear
                    showSearch
                    filterOption={(input, option) => {
                      return option?.className ? (option?.className as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                    }}
                  >
                    {doctors.map((d) => (
                      <Select.Option className={d.mnemonicCode} key={d.id} value={d.id}>
                        {d.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          )
        }
        <Row justify='end'>
          <Space>
            <Button type='primary' onClick={() => {
              if (getAction(action) != 2) {
                setVisible(false)
              } else {
                setLoading(true)
                repealForm.submit()
              }
            }}
              loading={loading}>确认</Button>
          </Space>
        </Row>
      </Modal>
    </div>
  );
}


export default OutRecipe;
