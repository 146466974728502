import { Button, Checkbox, Col, notification } from 'antd'
import React, {
    forwardRef,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { setMenuManagementList, getMenuManagementList } from './urlManagementSlice'

interface MenuManagementProps {
    tabKey?: any
}

export const MenuManagement = (
    { tabKey }: MenuManagementProps,
): ReactElement => {

    const dispatch = useDispatch<RootDispatch>()

    const theme = useContext(ThemeContext)

    const [menuList, setMenuList] = useState([
        { id: '1', name: '在线预约', value: '1' },
        { id: '2', name: '预约记录', value: '2' },
        { id: '3', name: '优惠券', value: '3' },
        { id: '4', name: '就诊人管理', value: '4' },
        // { id: '5', name: '设置', value: '5' },
    ])

    const [data, setData] = useState<any>([])

    const [id, setId] = useState<any>()

    const getPage = () => {
        dispatch(getMenuManagementList())
            .then(unwrapResult)
            .then((res: any) => {
                setData(res.menuListView)
                setId(res.id)
                setMenuList(menuList.map((v: any) => {
                    if (res.menuListView.includes(v.value)) {
                        return { ...v, checked: true }

                    } else {
                        return { ...v, checked: false }
                    }
                }))
            })
    }

    useEffect(() => {
        if (tabKey == 4) {
            getPage()
        }
    }, [tabKey])

    return (
        <Col
            style={{
                height: 'calc(100% - 30px)',
                overflow: 'hidden',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Col
                style={{
                    flex: 1,
                    backgroundColor: theme.pn,
                    borderRadius: 10,
                    padding: '0px 15px 0 15px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <p>勾选需要开通的菜单权限</p>
                <Checkbox.Group onChange={(e:any) => {
                    setData(e)
                }} value={data} >
                    {menuList?.map((c: any) => {
                        return (
                            <Checkbox key={c?.id} value={c?.value} style={{ width: '120px' }} disabled={c.id != 3} >
                                {c?.name}
                            </Checkbox>
                        )

                    })}
                </Checkbox.Group>
                <Button type="primary"
                    onClick={() => {
                        dispatch(setMenuManagementList({ menuList: data.join(','), id: id }))
                            .then(unwrapResult)
                            .then(() => {
                                getPage()
                                notification.success({
                                    message: '保存成功'
                                })
                            })
                    }}
                    style={{ width: '80px', margin: '100px auto 0' }}>保存</Button>
            </Col>
        </Col>
    )
}