import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunkApi } from "../../app/store";
import { Page, pageOf, startPageOf } from "../../models/page";
import * as U from "../../models/user";
import { User } from "../../models/user";
import * as SU from "../../services/user";
import {
  CreateUserParams,
  GetUserDetailParams,
  GetUserListParams,
  ResetUserPasswordParams,
  ToggleUserStateParams,
  UploadHeadPhotoParams,
} from "../../services/user";

export interface UserState {
  page: Page<User>;
}

const initialState: UserState = {
  page: startPageOf(),
};

export const getUserList = createAsyncThunk<
  Page<User>,
  GetUserListParams,
  RootThunkApi<Page<User>>
>("user/getUserListStatus", async (params, api) => {
  return sendAsync(SU.getUserList(params), api).then(
    (data: Record<string, never>) =>
      pageOf(
        data,
        _.chain(data.records)
          .map((u) => U.fromJson(u))
          .value()
      )
  );
});

export const createOrUpdateUser = createAsyncThunk<
  void,
  CreateUserParams,
  RootThunkApi<void>
>("user/createOrUpdateUserStatus", async (params, api) => {
  return sendAsync(SU.createOrUpdateUser(params), api).then(() => {
    // do nothing.
  });
});

export const getUploadHeadPhotoAsync = createAsyncThunk<
  void,
  UploadHeadPhotoParams,
  RootThunkApi<void>
>("user/getUploadHeadPhotoAsync", async (params, api) => {
  return sendAsync(SU.UploadHeadPhotoAsync(params), api)
});

export const getUserDetail = createAsyncThunk<
  User,
  GetUserDetailParams,
  RootThunkApi<User>
>("user/getUserDetailStatus", async (params, api) => {
  return sendAsync(SU.getUserDetail(params), api).then((data: unknown) =>
    U.fromJson(data)
  );
});

export const getUserProfile = createAsyncThunk<User, void, RootThunkApi<User>>(
  "user/getUserProfile",
  async (_, api) => {
    return sendAsync(SU.getUserProfile(), api).then((data: unknown) =>
      U.fromJson(data)
    );
  }
);

export const toggleUserState = createAsyncThunk<
  void,
  ToggleUserStateParams,
  RootThunkApi<void>
>("user/toggleUserStateStatus", async (params, api) => {
  return sendAsync(SU.toggleUserState(params), api);
});

export const resetUserPassword = createAsyncThunk<
  void,
  ResetUserPasswordParams,
  RootThunkApi<void>
>("user/resetUserPasswordStatus", async (params, api) => {
  return sendAsync(SU.resetUserPassword(params), api);
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.page = action.payload;
    });
  },
});

// 菜单权限tree
export const getThirdTenantMenuTree = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
  >("user/thirdTenantMenuTree", async (params, api) => {
  return sendAsync(SU.thirdTenantMenuTreeFn(params), api);
});

// 菜单权限tree修改
export const setThirdTenantMenuTree = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
  >("user/setThirdTenantMenuTree", async (params, api) => {
  return sendAsync(SU.setThirdTenantMenuTreeFn(params), api);
});

export const selectUserPage = (state: RootState): Page<User> => state.user.page;

export default userSlice.reducer;
