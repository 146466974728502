import {
  AlipayCircleFilled,
  CaretDownFilled,
  QrcodeOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import { JSX_TYPES } from '@babel/types'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Carousel, Checkbox, Form, Input, notification, Row, Select } from 'antd'
import { AnyMxRecord } from 'dns'
import _ from 'lodash'
import Qrcode from 'qrcode.react'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  ApplicationType,
  containsCredentials,
  setCredentials,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import {
  AccountFilled,
  CloseFaceLoginLine,
  FaceLoginLine,
  PasswordFilled,
  TenantFilled,
} from '../../compnents/icons/Icons'
import { AccountType } from '../../models/accounttype'
import { ScanQueryParams } from '../../services/auth'
import { ThemeContext } from '../../theme/ThemeContext'
import { isValidMobileNumber } from '../../utils/StringUtils'
import { useInterval } from '../../utils/timehooks'
import splash from './assets/splash.jpg'
import styles from './Login.module.css'
import {
  getFaceLogin,
  getImgList,
  getScanUrl,
  getSmsCode, getTemporaryToken,
  getTenants,
  getThirdPartyLoginUrl,
  login,
  queryScanStatus,
  selectSmsCountdown,
  selectTenants,
  setSmsCountdown,
  setTenants,
} from './loglinSlice'
import crypto from "crypto";
import {Base64} from "jsencrypt/lib/lib/asn1js/base64";
import {encrypt, getHashStr, getSecretKey} from "../../models/m";

export interface LoginProps {
  qrcodeEnabled?: boolean
}

export const Login = ({ qrcodeEnabled = false }: LoginProps): ReactElement => {
  const appName = '好医点智慧门诊'

  const dispatch = useDispatch<RootDispatch>()

  const history = useHistory()

  const theme = useContext(ThemeContext)

  const [loginMode, setLoginMode] = useState<'form' | 'qrcode' | 'faceLogin'>(
    'form'
  )

  const [form] = Form.useForm()

  const videoRef = useRef(null)

  const canvasRef = useRef(null)

  const imageRef = useRef(null)

  const [video, setVideo] = useState<any>()

  const [canvas, setCanvas] = useState<any>()

  const [image, setImage] = useState<any>()

  const tenants = useSelector(selectTenants)

  const countdown = useSelector(selectSmsCountdown)

  const [popup, setPopup] = useState<null | Window>(null)

  const [qrcodeActiveInterval, setQrcodeActiveInterval] = useState(
    2 * 60 * 1000
  )

  const [scanParams, setScanParams] = useState<ScanQueryParams | undefined>()

  const [scanUrl, setScanUrl] = useState('')

  const [scanStatus, setScanStatus] = useState(0)

  const [applicationType, setApplicationType] = useState(1) // 1 机构用 0 自己用

  const [rememberMe, setRememberMe] = useState(true)

  const [faceLogin, setFaceLogin] = useState(false)

  const [faceCheck, setFaceCheck] = useState(false)

  const [temporaryToken, setTemporaryToken] = useState('')

  const [imgList, setImgList] = useState<any>([])



  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if(params.get("userName") && params.get("tenantId") && params.get("pwd") && params.get("client") == "1" ){
      if((params.get("rememberMe") == "true" || params.get("rememberMe") == "True")){
        form.setFieldsValue({
          "username":params.get("userName")
        })
        setRememberMe(true);
        dispatch(getTenants(params.get("userName")||""))
      }else if((params.get("rememberMe") == "false" || params.get("rememberMe") == "False")){
        setRememberMe(false);
      }

    }
  }, [])

  useEffect(() => {
    const tenantId = tenants?.length ? tenants[0].id : undefined
    form.setFieldsValue({
      tenantId,
    })
  }, [form, tenants])

  useEffect(() => {
    if(tenants && params.get("userName") && params.get("tenantId") && params.get("pwd") && params.get("client") == "1" ){
      if(params.get("rememberMe") == "true" || params.get("rememberMe") == "True"){
        form.setFieldsValue({
          tenantId:params.get("tenantId"),
          password:params.get("pwd")

        })
      }else if((params.get("rememberMe") == "false" || params.get("rememberMe") == "False")){
        setRememberMe(false);
      }

    }
  }, [tenants])



  const onFinish = (values: Record<string, never>) => {
    sessionStorage.clear()
    const tenantName = _.chain(tenants)
      .find((t) => t.id === values.tenantId)
      .value()?.name

    dispatch(getTemporaryToken(''))
      .then(unwrapResult)
      .then((data: any) => {
        const password = encrypt(values.password,data)
        dispatch(
          login({
            username: values.username,
            tenantId: values.tenantId,
            smsCode: values.smsCode,
            password: password,
            tenantName: tenantName,
            rememberMe,
            platform: applicationType,
            clientSource:params.get("client") || undefined,

          })
        )
      })
  }

  const { Option } = Select

  const layout = {
    labelCol: { flex: '0 0 34px' },
    wrapperCol: { flex: 1 },
  }

  const tailLayout = {
    wrapperCol: { offset: 3, span: 21 },
  }

  const wholeLayout = {
    wrapperCol: { offset: 0, span: 24 },
  }

  let mediaStreamTrack = null
  const timer = useRef<any>(null)
  useEffect(() => {
    setCanvas(canvasRef.current)
    setVideo(videoRef.current)
    setImage(imageRef.current)
    if (video && faceLogin) {
      // 开启摄像头
      openMedia()
      getPhotoUrl()
    }
    if (!faceLogin) {
      timer.current && clearInterval(timer.current)
    }
    return () => {
      timer.current && clearInterval(timer.current)
    }
  }, [videoRef, video, faceLogin, canvas, canvasRef])

  // 打开摄像头
  const openMedia = () => {
    const constraints = {
      video: { width: 500, height: 500 },
      audio: false,
    }
    //获得video摄像头
    if (
      navigator.mediaDevices === undefined ||
      navigator.mediaDevices.getUserMedia === undefined
    ) {
      notification.error({
        message: '您的电脑暂不支持该功能',
      })
      timer.current && clearInterval(timer.current)
      stopCapture()
      setFaceCheck(false)
      setFaceLogin(false)
      setLoginMode('form')
      return
    }
    const promise = navigator?.mediaDevices?.getUserMedia(constraints)
    promise
      .then((mediaStream: any) => {
        mediaStreamTrack =
          typeof mediaStream.stop === 'function'
            ? mediaStream
            : mediaStream.getTracks()[1]
        video.srcObject = mediaStream
        video.play()
      })
      .catch(() => {
        notification.error({
          message: '您的电脑暂不支持该功能，请使用其他方式登录',
        })
        timer.current && clearInterval(timer.current)
        stopCapture()
        setFaceCheck(false)
        setFaceLogin(false)
        setLoginMode('form')
      })
  }

  //停止摄像机
  const stopCapture = () => {
    // var video = document.getElementById('video');
    if (!video.srcObject) return
    const stream = video.srcObject
    const tracks = stream.getTracks()
    tracks.forEach((track: any) => {
      track.stop()
    })
  }

  // 获取头像的 base64
  const getPhotoUrl = () => {
    let num = 0
    timer.current = setInterval(() => {
      num++
      const ctx = canvas.getContext('2d')
      ctx.drawImage(video, 0, 0, 500, 500)
      // toDataURL  ---  可传入'image/png'---默认, 'image/jpeg'
      const img = canvas.toDataURL()
      // 这里的img就是得到的图片
      dispatch(getFaceLogin(img))
        .then(unwrapResult)
        .then((data: any) => {
          const token = data.accessToken as string
          dispatch(
            setCredentials({
              creds: {
                ...data,
                applicationType: ApplicationType.Application,
                id: data.userId?.toString(),
                name: data.userName,
                departmentId: data.outpatientDepartmentId?.toString(),
                tenantName: data.tenantName || '',
                shortName: data.shortName || '',
                token,
                endTime: data.endTime,
                stationCategory: data.stationCategory,
              },
              rememberMe: true,
            })
          )
          video.pause()
          stopCapture()
          clearInterval(timer.current)
        })
        .catch((error) => {

          if (num >= 3) {
            clearInterval(timer.current)
            setFaceCheck(true)
            image.src = img
            video.pause()
            stopCapture()
          }
        })
    }, 2000)
  }

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        dispatch(setSmsCountdown(countdown - 1))
      }, 1000)
    }
  }, [countdown])

  useEffect(() => {
    const timer = popup
      ? setInterval(() => {
          if (popup.closed) {
            setPopup(null)
            if (containsCredentials()) {
              history.go(0)
            }
          }
        }, 1000)
      : undefined
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [popup])

  const thirdPartyLogin = (accountType: AccountType) => {
    const location = window.location
    dispatch(
      getThirdPartyLoginUrl({
        accountType,
        isLogin: true,
        redirectUrl: `${location.protocol}//${location.host}/login/callback`,
      })
    )
      .then(unwrapResult)
      .then((url) => {
        setPopup(window.open(url))
      })
      .catch(() => {
        // do nothing.
      })
  }

  const reloadScanUrl = () => {
    if (qrcodeEnabled) {
      dispatch(getScanUrl())
        .then(unwrapResult)
        .then((data) => {
          const url = new URL(`http://${window.location.host}/api/${data}`)
          setScanParams({
            uid: url.searchParams.get('uid') || '',
            sign: url.searchParams.get('sign') || '',
          })
          setQrcodeActiveInterval(
            (Number(url.searchParams.get('timeout')) || 2) * 60000
          )
          setScanUrl(`hyd://login?url=${encodeURIComponent(url.href)}`)
        })
    }
  }

  useEffect(reloadScanUrl, [qrcodeEnabled])

  useInterval(reloadScanUrl, qrcodeEnabled ? qrcodeActiveInterval : null)

  useInterval(
    async () => {
      if (scanParams) {
        const status = await dispatch(queryScanStatus(scanParams)).then(
          unwrapResult
        )
        setScanStatus(status)
      }
    },
    loginMode === 'qrcode' && scanParams ? 2000 : null
  )

  useEffect(() => {
    dispatch(getImgList()).then(unwrapResult).then(res => setImgList(res))
  }, [])







  return (
    <div className={styles.viewport}>
      <img src={splash} className={styles.background} alt='登录页面背景图' />
      <div className={styles.container}>
        {/* 广告位轮播 */}
        {!imgList.length && <div className={styles.banner}></div>}
        {imgList.length != 0 && <div className={styles.imgWrap}>
          <Carousel autoplay dotPosition='bottom'>
            {
              imgList.length && imgList.map((item: any) => {
                return (
                  <div key={item.id} onClick={() => {
                    item.link && window.open(item.link)
                  }}>
                    <img style={{ height: 600, width: 560 }} src={item.imgUrlPath} />
                  </div>
                )
              })
            }
          </Carousel>
        </div>}
        <div className={styles.login}>
          <div
            className={styles.form}
            style={{ display: loginMode === 'form' ? 'block' : 'none' }}
          >
            <div
              className={styles.title}
              // 关闭后台入口
              // onDoubleClick={() => {
              //   if (applicationType === ApplicationType.Management) {
              //     setApplicationType(ApplicationType.Application)
              //   } else {
              //     setApplicationType(ApplicationType.Management)
              //   }
              // }}
            >
              {appName}
            </div>
            <Form
              {...layout}
              form={form}
              onFinish={onFinish}
              colon={false}
              requiredMark={false}
            >
              <Form.Item
                name='username'
                label={<AccountFilled />}
                className={styles.entry}
                style={{ fontSize: '1rem' }}
                rules={[{ required: true, message: '请输入手机号码' }]}
              >
                <Input
                  placeholder='手机号码'
                  bordered={false}
                  allowClear
                  onChange={(e) => {
                    const text = e.target.value
                    if (
                      isValidMobileNumber(text) &&
                      applicationType === ApplicationType.Application
                    ) {
                      dispatch(getTenants(text))
                    } else {
                      dispatch(setTenants([]))
                    }
                  }}
                />
              </Form.Item>
              {applicationType == ApplicationType.Application ? (
                <Form.Item
                  name='tenantId'
                  label={<TenantFilled />}
                  className={styles.entry}
                  rules={[{ required: true, message: '请选择诊所' }]}
                >
                  <Select
                    placeholder='选择诊所'
                    bordered={false}
                    disabled={tenants.length <= 1}
                    suffixIcon={
                      tenants.length > 1 && (
                        <CaretDownFilled style={{ color: theme.tc3,  }}/>
                      )
                    }
                    style={{ fontSize: '1rem', color: theme.tc1 }}
                    className={styles.tenant}
                  >
                    {tenants.map((t) => (
                      <Option key={t.id} value={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  name='smsCode'
                  label={<TenantFilled />}
                  className={styles.entry}
                >
                  <Input
                    placeholder='手机验证码'
                    autoComplete='off'
                    suffix={
                      <div
                        style={{
                          // width: "6.5rem",
                          height: '1.8rem',
                          lineHeight: '1.8rem',
                          textAlign: 'center',
                          fontSize: countdown > 0 ? '0.625rem' : '0.875rem',
                          color: countdown > 0 ? theme.tc3 : theme.tc2,
                          backgroundColor: theme.c3,
                          cursor: countdown > 0 ? 'not-allowed' : 'pointer',
                        }}
                        onClick={async (e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          if (countdown > 0) {
                            return
                          }
                          const phone = form.getFieldValue('username')
                          if (!isValidMobileNumber(phone)) {
                            notification.error({
                              message: '手机号码格式不正确',
                              duration: 3,
                            })
                            return
                          }
                          try {
                            await dispatch(getSmsCode(phone)).then(unwrapResult)
                            dispatch(setSmsCountdown(20))
                          } catch {
                            // do nothing.
                          }
                        }}
                      >
                        {countdown > 0
                          ? `获取验证码(${countdown}s)`
                          : '获取验证码'}
                      </div>
                    }
                  />
                </Form.Item>
              )}
              <Form.Item
                name='password'
                label={<PasswordFilled />}
                className={styles.password}
                rules={[
                  { required: true, message: '请输入密码' },
                  {
                    pattern: /^[^\s]*$/,
                    message: '密码不能存在空格',
                  },
                ]}
              >
                <Input.Password placeholder='密码' bordered={false} />
              </Form.Item>
              <Form.Item {...tailLayout} className={styles.remember}>
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                >
                  记住密码
                </Checkbox>
              </Form.Item>
              <Form.Item {...wholeLayout} style={{ marginBottom: 0 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  className={styles.submit}
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
            <Row
              style={{ marginTop: 40, marginBottom: 15 }}
              wrap={false}
              align='middle'
            >
              <div
                style={{
                  flex: 1,
                  borderBottom: `solid 1px ${theme.wbd}`,
                  marginRight: 10,
                }}
              ></div>
              <div style={{ fontSize: 12, color: theme.tc3 }}>其他登录方式</div>
              <div
                style={{
                  flex: 1,
                  borderBottom: `solid 1px ${theme.wbd}`,
                  marginLeft: 10,
                }}
              ></div>
            </Row>
            <Row justify='center'>
              <AlipayCircleFilled
                style={{ color: theme.tc2, fontSize: 30 }}
                onClick={() => {
                  thirdPartyLogin(AccountType.Alipay)
                }}
              />
              {/*<div*/}
              {/*  style={{*/}
              {/*    width: '30px',*/}
              {/*    height: '30px',*/}
              {/*    marginLeft: 40,*/}
              {/*    cursor: 'pointer',*/}
              {/*  }}*/}
              {/*  onClick={() => {*/}
              {/*    setLoginMode('faceLogin')*/}
              {/*    setFaceLogin(true)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <FaceLoginLine*/}
              {/*    style={{*/}
              {/*      width: '30px',*/}
              {/*      height: '30px',*/}
              {/*      fontSize: '30px',*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</div>*/}
            </Row>
          </div>
          {qrcodeEnabled && (
            <>
              <div
                className={styles.qrcode}
                style={{ display: loginMode === 'qrcode' ? 'flex' : 'none' }}
              >
                <div
                  style={{ color: theme.tc2, fontSize: 22, marginBottom: 15 }}
                >
                  扫码登录
                </div>
                <Qrcode
                  value={scanUrl}
                  color={theme.tc1}
                  size={256}
                  style={{
                    width: 256,
                    height: 256,
                  }}
                />
                {scanStatus === 0 && (
                  <div
                    style={{ color: theme.tc3, fontSize: 14, marginTop: 15 }}
                  >
                    请用好医点APP扫码登录
                  </div>
                )}
                {scanStatus === 1 && (
                  <div
                    style={{ color: theme.tc3, fontSize: 14, marginTop: 15 }}
                  >
                    请在好医点APP确认登录
                  </div>
                )}
              </div>
              {loginMode === 'form' ? (
                <QrcodeOutlined
                  style={{
                    position: 'absolute',
                    color: theme.tc2,
                    right: 10,
                    top: 10,
                    fontSize: 40,
                  }}
                />
              ) : (
                <UnlockOutlined
                  style={{
                    position: 'absolute',
                    color: theme.tc2,
                    right: 10,
                    top: 10,
                    fontSize: 40,
                  }}
                />
              )}
              <div
                className={styles.corner}
                onClick={() => {
                  if (faceLogin) {
                    timer.current && clearInterval(timer.current)
                    stopCapture()
                    setFaceCheck(false)
                    setFaceLogin(false)
                    setLoginMode('form')
                  } else {
                    setLoginMode((mode) =>
                      mode === 'form' ? 'qrcode' : 'form'
                    )
                  }
                }}
              ></div>
            </>
          )}
          {/* 人脸登录 */}
          {faceLogin && (
            <div
              className={styles.qrcode}
              style={{ display: 'flex', position: 'relative' }}
            >
              <div style={{ color: theme.tc2, fontSize: 22, marginBottom: 15 }}>
                {!faceCheck ? '人脸登录' : '认证失败，请重试'}
              </div>
              <video
                width='400px'
                height='400px'
                autoPlay
                ref={videoRef}
              ></video>
              <canvas
                ref={canvasRef}
                width='400px'
                height='400px'
                style={{ display: 'none' }}
              ></canvas>
              <img
                ref={imageRef}
                src=''
                alt='人脸识别'
                style={{ display: 'none' }}
              />
              {faceCheck ? (
                <div
                  style={{
                    position: 'absolute',
                    top: 105,
                    left: 20,
                    height: 400,
                    width: 400,
                    backgroundColor: 'rgba(0,0,0,.4)',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    type='primary'
                    style={{
                      width: 120,
                      marginBottom: 15,
                    }}
                    onClick={() => {
                      setFaceCheck(false)
                      openMedia()
                      getPhotoUrl()
                    }}
                  >
                    继续人脸识别
                  </Button>
                </div>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.internet}>浙ICP备19029717号-2</div>
    </div>
  )
}
