/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-13 17:54:03
 * @LastEditors: linxi
 * @LastEditTime: 2021-04-22 19:41:49
 */
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface StockfloorParams {
  current?: number
  size?: number
  keyword?: string
  storehouseId?: number
  billingCategory?: string
  drugCategor?: string
  flag?: any
  sortType?: any
  sortColumn?:any
  type?:any
}

export interface StockfloorBody {
    id?: number;
    inventoryAlert?: number;
}

export function stockfloorPage(params: StockfloorParams): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryledger/lowerlimit`,
        method: "GET",
        params
    }
}

export function stockfloorEditpage(params: StockfloorParams): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryledger/lowerlimitset`,
        method: "GET",
        params
    }
}

export function stockfloorlogoutlistupdate(body: StockfloorBody): Request {
    return {
        url: `${API_PREFIX}/blade-store/materialscatalog/logoutlistupdate`,
        method: "POST",
        params: body
    }
}