import { unwrapResult } from '@reduxjs/toolkit'
import {Col, notification} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectUserName,
  selectUserId,
  selectTenantAreaCd,
  selectTenantAddress,
  selectTenantName,
  selectTenantCategory,
  selectInsuranceArray,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../../models/datetime'
import { Payment, PaymentStatus } from '../../../models/payment'
import { RecipeStatus } from '../../../models/recipe'
import { serverType } from '../../../services/commodity'
import { PaymentListParams } from '../../../services/payment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils'
import { PaymentsRef } from '../../payment/list/Payments'
import {
  getInsurancePaymentListAsync,
  selectPaymentPage,
} from '../../payment/list/paymentSlice'
import { PaymentColumns, PaymentColumnsWithAmount } from './columns'
import { PaymentQuery } from './Query'
import {
  settlementListUpload,
  settlementListChange,
  settlementListSearch,
  upldElecSetlCert,
  queryElecSetlCertInfoUpldRsltAsync,
} from './settlementSlice'
import {TableRowSelection} from "antd/lib/table/interface";
import {DataType} from "../../putstorage/putstorage";
import {selectPutstorageData} from "../../putstorage/putstorageSlice";

interface PaymentProps {
  status: PaymentStatus
  sibling?: PaymentsRef | null
}

export const SettlementApply = (
  { status, sibling = null }: PaymentProps,
  ref: React.Ref<PaymentsRef>
): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const page = useSelector(selectPaymentPage(status))

  const [params, setParams] = useState<PaymentListParams>({
    current: 0,
    state: status,
  })

  const [disabled, setDisabled] = useState(false)

  const [loading, setLoading] = useState(false)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const insuranceArray = useSelector(selectInsuranceArray)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const baseData = useSelector(selectInsuranceItem)

  const [selectIds, setSelectIds] = useState<any>('')

  const columns = PaymentColumnsWithAmount




  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  // 获取数据
  const getData = () => {
    setPageLoading(true)
      dispatch(getInsurancePaymentListAsync({...params,fromPage:"settlementApply"}))
      .then(unwrapResult)
      .finally(() => {
        setPageLoading(false)
      })
  }

  const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectIds(selectedRowKeys)
        },
  }

  const settlementUpload = (
    serverType: serverType,
    treatmentid: number,
    paymentid: any,
    yidibz: string,
    responseString?: string
  ) => {
    dispatch(
      settlementListUpload({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          treatmentid,
          paymentid,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType == 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '清算申请上传',
            },
            (call: any, err) => {
              if (!err) {
                if (yidibz === '0') {
                  const { output } = call.netmessage
                  settlementChange(
                    'requestString',
                    treatmentid,
                    paymentid,
                    output.setl_list_id,
                    JSON.stringify(call.netmessage)
                  )
                } else {
                  settlementUpload(
                    'response',
                    treatmentid,
                    paymentid,
                    yidibz,
                    JSON.stringify(call.netmessage)
                  )
                }
              } else {
                if (
                  call?.netmessage?.err_msg?.indexOf('以上传记录且状态为:1') >=
                  0
                ) {
                  settlementSearch('requestString', treatmentid, paymentid)
                }
                setLoading(false)
                // setDisabled(false)
              }
            }
          )
        } else if (serverType === 'response') {
          setDisabled(false)
          setLoading(false)
          getData()
        }
      })
      .catch((e) => {
        console.log('失败：' + e.message)
        setDisabled(false)
        setLoading(false)
      })
  }

  const settlementChange = (
    serverType: serverType,
    treatmentid: number,
    paymentid: number,
    stasType: string,
    responseString?: string
  ) => {
    dispatch(
      settlementListChange({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          treatmentid,
          paymentid,
          stasType,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '清单确认',
            },
            (call: any, err) => {
              if (!err) {
                settlementChange(
                  'response',
                  treatmentid,
                  paymentid,
                  stasType,
                  responseString
                )
              } else {
                setDisabled(false)
              }
            }
          )
        } else {
          setDisabled(false)

          // uploadSettleInvoice('requestString', paymentid, res?.invoiceId)
          // getData()
        }
      })
      .catch(() => setDisabled(false))
  }

  const settlementSearch = (
    serverType: serverType,
    treatmentid: number,
    paymentid: number,
    responseString?: string
  ) => {
    dispatch(
      settlementListSearch({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          paymentid,
          treatmentid,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '清算状态查询',
            },
            (call: any, err) => {
              if (!err) {
                settlementSearch(
                  'response',
                  treatmentid,
                  paymentid,
                  JSON.stringify(call.netmessage)
                )
              } else {
                setDisabled(false)
              }
            }
          )
        } else {
          // setDisabled(false)
          uploadSettleInvoice('requestString', paymentid, res?.invoiceId)
          // getData()
        }
      })
      .catch((err) => {
        setDisabled(false)
      })
  }
  const uploadSettleInvoice = (
    serverType: serverType,
    paymentid: number,
    invoiceId: string,
    responseString?: string
  ) => {
    dispatch(
      upldElecSetlCert({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          paymentid,
          invoiceId,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '电子票据上传',
            },
            (call: any, err) => {
              if (!err) {
                uploadSettleInvoice(
                  'response',
                  paymentid,
                  invoiceId,
                  JSON.stringify(call.netmessage)
                )
              } else {
                setDisabled(false)
              }
            }
          )
        } else {
          // getData()
          searchSettleInvoice('requestString', paymentid, invoiceId)
        }
      })
      .catch(() => setDisabled(false))
  }

  const searchSettleInvoice = (
    serverType: serverType,
    paymentid: number,
    invoiceId: string,
    responseString?: string
  ) => {
    dispatch(
      queryElecSetlCertInfoUpldRsltAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          paymentid,
          invoiceId,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '电子票据状态查询',
            },
            (call: any, err) => {
              if (!err) {
                searchSettleInvoice(
                  'response',
                  paymentid,
                  invoiceId,
                  JSON.stringify(call.netmessage)
                )
              } else {
                setDisabled(false)
              }
            }
          )
        } else {
          setDisabled(false)
          getData()
        }
      })
      .catch(() => setDisabled(false))
  }

  useEffect(() => {
    if (params.current !== 0) {
      getData()
    }
  }, [params])

    const massUpload = () => {
        if (selectIds?.length === 0){
            notification.info({
                message: '请选择上传的数据',
            })
        }

        if (selectIds?.length > 10) {
            notification.info({
                message: '一次最多上传10条',
            })
        } else {
            selectIds?.map((id: any) => {
                const item = (page?.items.find((v:any)=> {return v.id===id})) as any
                console.log(item)
                settlementUpload(
                    'requestString',
                    item.registration.treatmentId,
                    item.id,
                    item.treatment.yidibz
                )
            })
        }

    }

  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 40px)',
        margin: '20px',
        overflow: 'hidden',
        padding: '10px 15px 0 15px',
      }}
    >
      <PaymentQuery
        loading={loading}
        onValueChange={(v) => {
          setParams({ ...params, ...v, current: 1 })
        }}
        massUpload={() => massUpload()}
      />
        <span style={{color:"red"}}>请注意！医保要求的电子票据上传功能已迁移至 【医保业务】--【电子票据上传】菜单中，如需上传电子票据，请移步至该菜单进行上传。</span>
      <EditableList<Payment>
        page={page}
        loading={pageLoading}
        rowKey={(m: any) => m?.id}
        rowSelection={
            {
                ...rowSelection,
            } as TableRowSelection<any>
        }
        columns={columns(
          status,
          page.size * (page.current - 1),
          (action, id, t) => {
            if (action === 'apply') {
              setDisabled(true)
              setLoading(true)
              settlementUpload(
                'requestString',
                t.registration.treatmentId,
                id,
                t.treatment.yidibz
              )
            }
          },
          disabled
        )}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size,
          })
        }
        onRow={(record) => ({})}
      />
    </Col>
  )
}
