/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-13 13:56:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-03 11:53:29
 */
import { SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Form, Input, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
  ToothDay,
} from '../../compnents/form/DateSelect'
import { examinePageParams } from '../../services/examine'
import { stringTrim } from '../../utils/StringUtils'
import { FollowUpType, getFollowUpPeopleList } from './followUpPageSlice'
const { Option } = Select
export const Query = (props: {
  status: any
  type: number
  patientId?: string
  followUpTypeList: any
  onValueChange: (queries: any) => void
  toothPatient?: boolean
}) => {
  const dispatch = useDispatch<RootDispatch>()
  const [form] = Form.useForm()
  const storageParams = JSON.parse(sessionStorage.getItem(location.pathname+"_suifang") || '{}') //数据回显

  const [head, tail] = getDateRange(
    props.type != 1 || props.toothPatient
      ? DateRangeType.ThisWeek
      : DateRangeType.Today
  )

  const [followUpPersonList, setFollowUpPersonList] = useState<any>() //随访类型数据

  useEffect(() => {
    dispatch(
      getFollowUpPeopleList({
        current: 1,
        size: 500,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setFollowUpPersonList(res.records)
      })

    if (
      Object.keys(storageParams).length &&
        (location.pathname == '/toothHome' || location.pathname == '/toothPatient' )
    ) {
      form.setFieldsValue({
        ...storageParams,
        timeHead: storageParams?.startTime || head,
        timeTail: storageParams?.endTime || tail,
        [DateRangeFieldName]: storageParams?._dateRange || (props.type != 1 || props.toothPatient
            ? DateRangeType.ThisWeek
            : DateRangeType.Today),
      })
    } else {
      form.setFieldsValue({
        [DateRangeFieldName]:
          props.type != 1 || props.toothPatient
            ? DateRangeType.ThisWeek
            : DateRangeType.Today,
        timeHead: head,
        timeTail: tail,
        stateV: props.type == 1 ? '0' : '',
      })
    }
    form.submit()
  }, [])

  const refresh = () => {
    const {
      timeHead,
      timeTail,
      _dateRange,
      stateV,
      ...tempObj
    } = form.getFieldsValue()
    props.onValueChange({
      ...tempObj,
      startTime: timeHead,
      endTime: timeTail,
      current: 1,
      _dateRange:_dateRange,
      stateV: props.status == '3' ? stateV : props.status,
    })
  }
  return (
    <div>
      <Form
        form={form}
        autoComplete='off'
        onFinish={(values) => {
          const data = props.status == storageParams.state ? storageParams : {}
          const { timeHead, timeTail, _dateRange, ...tempObj } = values
          props.onValueChange({
            ...data,
            ...tempObj,
            startTime: timeHead,
            endTime: timeTail,
            actionTimeHead: undefined,
            actionTimeTail: undefined,
            timeHead: undefined,
            timeTail: undefined,
            current: data.current || 1,
            _dateRange:_dateRange,
            state:
              location.pathname != '/toothHome' ? props.status : values.stateV,
          })
        }}
        style={{ marginTop: props.type == 1 ? '-15px' : 0 }}
      >
        <Row style={{ height: '4rem' }} align='middle' justify='space-between'>
          <Space>
            {!props.patientId && (
              <Form.Item noStyle name='name' getValueFromEvent={stringTrim}>
                <Input
                  placeholder='患者姓名/手机号码/助记码'
                  allowClear
                  prefix={<SearchOutlined />}
                  style={{ width: '19.75rem' }}
                  onChange={refresh}
                />
              </Form.Item>
            )}
            <Form.Item noStyle name='userId'>
              <Select
                style={{ width: '11.25rem' }}
                placeholder='随访人'
                allowClear
                onChange={refresh}
              >
                {followUpPersonList &&
                  followUpPersonList.map((item: any) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.realname}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='flag'>
              <Select
                style={{ width: '11.25rem' }}
                placeholder='随访类型'
                allowClear
                onChange={refresh}
              >
                {props?.followUpTypeList &&
                  props?.followUpTypeList.map((item: any) => {
                    return (
                      <Option key={item.id} value={item.name}>
                        {item.name}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
            {props.type == 1 && !props.toothPatient && (
              <Form.Item noStyle name='stateV'>
                <Select
                  style={{ width: '11.25rem' }}
                  placeholder='随访状态'
                  onChange={refresh}
                >
                  <Option value={'0'}>待随访</Option>
                  <Option value={'1'}>已完成</Option>
                </Select>
              </Form.Item>
            )}

            <DateSelect
              placeholder='随访时间'
              labelPrefix=''
              namePrefix='time'
              initialValue={DateRangeType.ThisWeek}
              allowClear={false}
              options={ToothDay}
              style={{ width: '8.5rem' }}
              onChange={(e) => {
                if (e !== 6) {
                  refresh()
                }
              }}
            />
            <Button type='primary' onClick={refresh}>
              查询
            </Button>
          </Space>
          {/* {props.type != 1 && (
            <Button type='primary' onClick={refresh}>
              查询
            </Button>
          )} */}
        </Row>
      </Form>
    </div>
  )
}
