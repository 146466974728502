/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 11:15:10
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-15 13:52:03
 */
import {
  CiCircleFilled,
  LeftOutlined,
  ReloadOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Badge,
  BadgeProps,
  Button,
  Calendar,
  DatePicker,
  Row,
  Space,
} from 'antd'
import moment from 'moment'
import { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import {
  AppointmentingIcon,
  RegistrationedIcon,
  OutpatientedIcon,
  RunOffIcon,
  CancelIcon,
  OverTimeIcon,
  AppointmentingSureIcon,
} from '../../compnents/icons/Icons'
import { dateFormat, getBgc } from '../../models/appointment'
import { setData } from '../commodity/commoditySlice'
import { AutomaticSchedulingModal } from '../composing/AutomaticSchedulingModal'
import { data } from '../composing/modal'
import styles from './appointment.module.css'
import {
  monthAppointmentAsync,
  setAppointmentTabBar,
  setDayTime,
} from './appointmentSelice'
import { INITIAL_Value } from './event-utils'

export const AppointmentMonth = (props: {
  activeKey?: any
  onlyThisAccount?: any
}) => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [monthsTime, setMonthsTime] = useState(0) //月 日期时间控制

  const [monthTime, setmonthTime] = useState('')

  const [nowTime, setNowTime] = useState(moment(new Date()).format(dateFormat))

  const [pageData, setPageData] = useState<any>([])

  useEffect(() => {
    setmonthTime(getLastWeek(monthsTime))
  }, [monthsTime])

  useEffect(() => {
    getpageData()
  }, [monthTime])

  useEffect(() => {
    if (props?.activeKey == '3') {
      if (props?.onlyThisAccount == true || props?.onlyThisAccount == false) {
        getpageData()
      }
    }
  }, [props?.onlyThisAccount, props?.activeKey])

  const getpageData = () => {
    const data: any = {
      type: 0,
      endTime: monthTime
        ? moment(monthTime).endOf('month').format('YYYY-MM-DD')
        : undefined,
      startTime: monthTime
        ? moment(monthTime).startOf('month').format('YYYY-MM-DD')
        : undefined,
      onlyDisplayThisAccount: props?.onlyThisAccount,
    }
    if (data?.startTime && data?.endTime)
      dispatch(monthAppointmentAsync(data))
        .then(unwrapResult)
        .then((res: any) => {
          setPageData(res)
        })
  }
  // 获取当前月 上月 下月时间
  const getLastWeek = (i: number) => {
    return moment().subtract(i, 'months').format('YYYY-MM')
  }

  useEffect(() => {
    if (pageData.length) {
      pageData.forEach((v: any) => {
        dateCellRender(moment(v.appointmentDate))
      })
    }
  }, [pageData])

  const getListData = (value: Moment) => {
    let listData = []

    if (!pageData.length) return
    const date = value.format(dateFormat)
    if (pageData.some((v: any) => v.appointmentDate == date)) {
      listData = pageData.find((v: any) => v.appointmentDate == date)
        .appointmentRecord
    }
    return listData
  }

  const getMonthData = (value: Moment) => {
    if (value.month() === 8) {
      return 1394
    }
  }
  const monthCellRender = (value: Moment) => {
    const num = getMonthData(value)
    return num ? (
      <div className='notes-month'>
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null
  }

  const dateCellRender = (value: Moment) => {
    const listData = getListData(value)
    return (
      <div className={styles.monthIcon}>
        {listData?.map((item: any) => (
          <p
            key={item.id}
            style={{ backgroundColor: getBgc(item?.appointmentState) }}
          >
            {item?.appointmentState == 0 && (
              <AppointmentingIcon className={styles.icon} />
            )}
            {item?.appointmentState == 4 && (
              <AppointmentingSureIcon className={styles.icon} />
            )}
            {item?.appointmentState == 1 && (
              <RegistrationedIcon className={styles.icon} />
            )}
            {item?.appointmentState == 2 && (
              <OutpatientedIcon className={styles.icon} />
            )}
            {item?.appointmentState == 3 && (
              <CancelIcon className={styles.icon} />
            )}
            {item?.appointmentState == 9 && (
              <OverTimeIcon className={styles.icon} />
            )}
            {item?.appointmentState == -1 && (
              <RunOffIcon className={styles.icon} />
            )}
            &ensp; {item.name}
            {/* <Badge status={item.type as BadgeProps['status']} text={item.content} /> */}
          </p>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.monthMain}>
      <Row justify='space-between' align='middle'>
        <Space style={{ flex: 1 }}>
          <Button
            type='primary'
            onClick={() => {
              setMonthsTime(0)
              setNowTime(moment(new Date()).format(dateFormat))
            }}
          >
            回到本月
          </Button>
          {/* <Button
            type='primary'
            onClick={() => {
              setIsAutomaticSchedulingModalVisible(true)
            }}
          >
            自动排班设置
          </Button> */}
          <div className='input-group' style={{ display: 'inline-flex' }}>
            {/* 左按钮 */}
            <Button
              icon={<LeftOutlined />}
              onClick={() => {
                setMonthsTime(monthsTime + 1)
                const data = moment(nowTime).subtract(1, 'M').format(dateFormat)
                setNowTime(data)
              }}
            />
            {/* 日期显示 */}
            <div style={{ margin: '0 10px' }}>
              <DatePicker
                picker='month'
                allowClear={false}
                // disabled
                value={moment(monthTime, 'YYYY-MM')}
                onChange={(v: any) => {
                  const value =
                    moment(v) > moment(new Date())
                      ? moment(new Date()).diff(moment(v), 'month') - 1
                      : moment(new Date()).diff(moment(v), 'month')
                  const data = moment(v).format(dateFormat)
                  setNowTime(data)
                  setMonthsTime(value)
                }}
              />
            </div>

            {/* <div className={styles.weekTimeWrap}>{monthTime}</div> */}
            {/* 右按钮 */}
            <Button
              icon={<RightOutlined />}
              onClick={() => {
                setMonthsTime(monthsTime - 1)
                const data = moment(nowTime).add(1, 'M').format(dateFormat)
                setNowTime(data)
              }}
            />
          </div>
        </Space>
        <ReloadOutlined
          style={{ fontSize: '20px', marginRight: 40 }}
          onClick={() => {
            getpageData()
          }}
        />
      </Row>
      <Calendar
        value={moment(nowTime)}
        onSelect={(v) => {
          if (v.format('M') != moment(monthTime).format('M')) {
            const num =
              Number(moment(monthTime).format('M')) - Number(v.format('M'))

            if (num > 0) {
              const data = moment(nowTime).subtract(1, 'M').format(dateFormat)
              setNowTime(data)
            } else {
              const data = moment(nowTime).add(1, 'M').format(dateFormat)
              setNowTime(data)
            }
            setMonthsTime(monthsTime + num)
          }

          if (
            pageData?.some(
              (el: any) => el.appointmentDate == v.format(dateFormat)
            )
          ) {
            dispatch(setAppointmentTabBar('1'))
            dispatch(setDayTime(v.format(dateFormat)))
          }
        }}
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
      />
      <Row justify='end'>
        <Space>
          <AppointmentingIcon className={styles.appointmentIcon} /> 待确认
          <AppointmentingSureIcon className={styles.appointmentIcon} /> 已确认
          <OverTimeIcon className={styles.appointmentIcon} /> 超时
          <RegistrationedIcon className={styles.appointmentIcon} /> 已挂号
          <OutpatientedIcon className={styles.appointmentIcon} /> 已就诊
          <RunOffIcon className={styles.appointmentIcon} /> 流失
        </Space>
      </Row>
    </div>
  )
}
