import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
} from 'antd'
import moment from 'moment'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  DateRangeChargeTypes,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { DateTimeFormat } from '../../../models/datetime'
import { InvoicePageParams, PaymentListParams } from '../../../services/payment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getAllDepartmentUser, selectDepartments } from '../list/paymentSlice'

export const Query = (props: {
  onValueChange: (params: InvoicePageParams) => void
  outExportExcel: () => void
  print: () => void
  status: number
  YBZFUploadflag?: boolean
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const inputRef = useRef<any>(null)

  const { Option } = Select

  const departments = useSelector(selectDepartments)

  const insuranceArray = useSelector(selectInsuranceArray)

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  const [allList, setAllList] = useState<any>([])

  const getAllDepartment = () => {
    dispatch(getAllDepartmentUser())
      .then(unwrapResult)
      .then((res: any) => {
        setAllList(
          res.map((v: any) => {
            return {
              label: v.name,
              value: v.id,
              children: v.children.map((i: any) => {
                return {
                  label: i.name,
                  value: i.id,
                }
              }),
            }
          })
        )
      })
  }

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
  }

  useEffect(() => {
    getAllDepartment()
    if (
      Object.keys(storageParams).length &&
      props.status == storageParams.state
    ) {
      form.setFieldsValue({
        ...storageParams,
        // param: '', // 防止缓存数据输入
      })
      if (
        storageParams.treatmentDepartmentId &&
        storageParams.treatmentDoctorId
      ) {
        form.setFieldsValue({
          allData: [
            storageParams.treatmentDepartmentId,
            storageParams.treatmentDoctorId,
          ],
        })
      } else if (storageParams.treatmentDepartmentId) {
        form.setFieldsValue({
          allData: [storageParams.treatmentDepartmentId],
        })
      }
    } else {
      const [head, tail] = getDateRange(DateRangeType.Today)
      form.setFieldsValue({
        _dateRange: 0,
        registrationTimeHead: head,
        registrationTimeTail: tail,
      })
    }
    inputRef.current!.focus({
      //获取输入框焦点
      cursor: 'start',
    })
    form.submit()
  }, [])

  const refresh = () => {
    const tempArr = form.getFieldsValue().allData
    props.onValueChange({
      ...storageParams,
      ...form.getFieldsValue(),
      treatmentDepartmentId: tempArr?.length ? tempArr[0] : undefined,
      treatmentDoctorId: tempArr?.length == 2 ? tempArr[1] : undefined,
      treatmentDepartmentName: tempArr?.length
        ? allList.filter((v: any) => v.value == tempArr[0])[0].label
        : undefined,
      treatmentDoctorName:
        tempArr?.length == 2
          ? allList
              .filter((v: any) => v.value == tempArr[0])[0]
              .children.filter((i: any) => i.value == tempArr[1])[0].label
          : undefined,
      current: 1,
      state: undefined,
      allData: undefined,
    })
  }

  return (
    <Form
      form={form}
      onFinish={(values) => {
        const data = props.status == storageParams.state ? storageParams : {}
        const tempArr = values.allData
        props.onValueChange({
          ...data,
          ...values,
          current: data.current || 1,
          state: undefined,
          allData: undefined,
        })
      }}
    >
      <Row style={{ marginTop: 10, marginBottom: 20 }} justify='space-between'>
        <Space>
          <Form.Item noStyle name='param'>
            <Input
              ref={inputRef}
              autoComplete='off'
              prefix={<SearchOutlined />}
              placeholder='患者姓名/手机号/病历号'
              style={{ width: '16rem' }}
              allowClear
              onChange={refresh}
            />
          </Form.Item>
          <Form.Item noStyle name='allData'>
            <Cascaded
              loadData={loadData}
              changeOnSelect
              // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              style={{ width: '240px' }}
              options={allList}
              onChange={refresh}
              placeholder='查看范围-科室列表'
            />
            {/* <Select
              style={{ width: '12.5rem' }}
              placeholder='查看范围-科室列表'
              allowClear
              onChange={() => form.submit()}
            >
              {departments.map((d) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))}
            </Select> */}
          </Form.Item>
          {insuranceArray &&
            insuranceArray.length !== 0 &&
            !props.YBZFUploadflag && (
              <Form.Item name='accountingStatus' noStyle>
                <Select
                  placeholder='欠费状态'
                  style={{ width: '8.25rem' }}
                  allowClear
                  // suffixIcon={<CaretDownFilled />}
                  onChange={refresh}
                >
                  <Select.Option value={'0'}>已结清</Select.Option>
                  <Select.Option value={'1'}>欠费中</Select.Option>
                </Select>
              </Form.Item>
            )}
          <DateSelect
            allowClear={false}
            style={{ width: '9.5rem' }}
            labelPrefix=''
            namePrefix='registrationTime'
            placeholder='时间'
            initialValue={DateRangeType.ThisWeek}
            options={DateRangeChargeTypes}
            onChange={refresh}
          />
          <Button type='primary' htmlType='submit' onClick={refresh}>
            查询
          </Button>
        </Space>{' '}
        <Space>
          {/* <div style={{ color: '#999999' }}>
                （最多支持导出近3个月10000条内的数据，更多请联系客服）
              </div> */}
          <Button
            type='primary'
            onClick={() => {
              props?.print()
            }}
          >
            打印
          </Button>
          <Button
            type='primary'
            onClick={() => {
              props?.outExportExcel()
            }}
          >
            导出
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
