/* * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 17:00:20
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { Dimen } from '../../../../models/dimen'
import { getGenderName } from '../../../../models/user'
import femaleHead from '../../images/female.png'
import maleHead from '../../images/male.png'

type ActionType = 'edit' | 'stateEdit'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const [checked, setChecked] = useState<any>()
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '科室头图',
      dataIndex: 'name',
      align: 'center',
      ellipsis: true,
      render: function ShowActions(_, r) {
        return r.deptLogoUrl ? (
          <img
            src={r.deptLogoUrl}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        ) : r.sex == 2 ? (
          <img
            src={femaleHead}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        ) : (
          <img
            src={maleHead}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        )
      },
    },
    {
      title: '科室名称',
      dataIndex: 'name',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '科室特色',
      dataIndex: 'deptSpecial',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.deptSpecial ? r.deptSpecial : '-'
      },
    },
    {
      title: '科室简介',
      dataIndex: 'itro',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.itro ? r.itro : '-'
      },
    },
    {
      title: '是否展示状态',
      dataIndex: 'departmentName',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: function UserSettings(_, d): ReactElement {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span style={{ width: '46px' }}>
              {d.showFlg == '0'
                ? '展示'
                : d.showFlg == '1' || d.showFlg == -1
                ? '不展示'
                : ''}
            </span>
            <Switch
              checked={
                d?.showFlg == -1 ? false : d?.showFlg == 0 ? true : false
              }
              style={{ marginLeft: '10px' }}
              onChange={(e) => {
                setChecked(e)
                onClick('stateEdit', d, e)
              }}
            />
          </div>
        )
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '6rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('edit', u)}
              style={{ marginRight: '4px' }}
            >
              编辑
            </a>
          </>
        )
      },
    },
  ]
}
