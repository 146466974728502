/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-08-02 11:46:43
 * @LastEditors: sj
 * @LastEditTime: 2022-12-12 16:20:08
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import {
  getSendMessageListFn,
  getSendMessageListFnParams,
  getSmsTemplateType,
} from '../../services/sendMessage'

//  短信推送列表
export const getSendMessageList = createAsyncThunk<
  any,
  getSendMessageListFnParams,
  RootThunkApi
>('stand/getSendMessageList', async (params, api) => {
  return sendAsync(getSendMessageListFn(params), api)
})

export const getSmsTemplateTypeAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('stand/getSendMessageList', async (_, api) => {
  return sendAsync(getSmsTemplateType(), api)
})
