/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-12-14 14:07:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-14 14:25:46
 */
/* eslint-disable react/display-name */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Typography, Tag, Tabs } from 'antd'

import React, { forwardRef, ReactElement, useState } from 'react'
import { NavBar } from '../../../compnents/nav/NavBar'
import styles from './reconciliationMessage.module.css'
import { sum } from 'lodash'
import { TabPane } from '../../../compnents/widgets/TabBar'
import { DayOrMonthReconciliations } from './dayOrMonthReconciliation'
import { DetailReconciliations } from './detailReconciliation'
const { Text } = Typography

export const ReconciliationMessage = (): ReactElement => {
  const [currentTab, setCurrentTab] = useState<any>()

  return (
    <div className={styles.content}>
      <NavBar
        where={['医保对账', '对账明细']}
        backtrace={{
          name: '医保对账',
          path: '/reconciliation',
        }}
      />
      <Col
        style={{
          height: '92%',
          padding: '10px 0px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          background: '#fff',
          marginTop: '10px',
          borderRadius:'10px'
        }}
      >
        <Tabs
          defaultActiveKey='0'
          className={styles.tabs}
          activeKey={currentTab}
          onChange={(t) => setCurrentTab(t)}
        >
          <TabPane tab='月对账' key='0'>
            <DayOrMonthReconciliations
              tabKey={currentTab}
            ></DayOrMonthReconciliations>
          </TabPane>
          <TabPane tab='日对账' key='1'>
            <DayOrMonthReconciliations
              tabKey={currentTab}
            ></DayOrMonthReconciliations>
          </TabPane>
          <TabPane tab='明细对账' key='2'>
            <DetailReconciliations
              tabKey={currentTab}
            ></DetailReconciliations>
          </TabPane>
        </Tabs>
      </Col>
    </div>
  )
}
