/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-24 19:50:35
 * @LastEditors: sj
 * @LastEditTime: 2022-11-01 18:27:14
 */
// 0内服 1外用 2易串味 3特殊管理药品 4危险品 5非药品
export enum inventoryAlert {
  NF = 0,
  WY = 1,
  YCW = 2,
  TS = 3,
  WX = 4,
  FYP = 5,
}

export const inventoryAlertCate = [
  inventoryAlert.NF,
  inventoryAlert.WY,
  inventoryAlert.YCW,
  inventoryAlert.TS,
  inventoryAlert.WX,
  inventoryAlert.FYP,
]

export function getinventoryAlertName(inventoryAlertcate: inventoryAlert) {
  switch (inventoryAlertcate) {
    case 0:
      return '内服(用)药'
    case 1:
      return '外用药'
    case 2:
      return '易串味药'
    case 3:
      return '特殊管理药品'
    case 4:
      return '危险品'
    case 5:
      return '其他'
    default:
      break
  }
}

// 0常温储存 1阴凉储存 2冷藏储存 3冷冻储存
export enum qualityControlCategory {
  CW = 0,
  YL = 1,
  LC = 2,
  LD = 3,
}

export const qualityControlCategories = [
  qualityControlCategory.CW,
  qualityControlCategory.YL,
  qualityControlCategory.LC,
  qualityControlCategory.LD,
]

export function getQualityControlCategory(cate: qualityControlCategory) {
  switch (cate) {
    case 0:
      return '常温储存'
    case 1:
      return '阴凉储存'
    case 2:
      return '冷藏储存'
    case 3:
      return '冷冻储存'
    default:
      break
  }
}

//   0药品 1 材料 2 器械 3 保健品  4 非药品
export enum MaterialsCategory {
  YP = 0,
  CL = 1,
  BJP = 2,
}

export const MaterialsCategories = [
  MaterialsCategory.YP,
  MaterialsCategory.CL,
  MaterialsCategory.BJP,
]

export function getMaterialsCategoryName(materialsCategory: MaterialsCategory) {
  switch (materialsCategory) {
    // case 0:
    //   return '药品'
    // case 1:
    //   return '材料/器械'
    // case 2:
    //   return '保健品'
    case 0:
      return '药品'
    case 1:
      return '材料'
    case 2:
      return '器械'
    case 4:
      return '其他'
  }
}

export enum Materials {
  YP = 0,
  CL = 1,
  QX = 2,
  FYP = 4,
}

export const Materiales = [
  Materials.YP,
  Materials.CL,
  Materials.QX,
  Materials.FYP,
]


export function getMaterialsName(materialsCategory: Materials) {
  switch (materialsCategory) {
    case 0:
      return '药品'
    case 1:
      return '材料'
    case 2:
      return '器械'
    case 4:
      return '其他'
  }
}

// approveFlg(匹配上传状态码表）：审批标志 0:未上传 1:审批已通过 -1:审批不通过 2：已上传待审核
export function getApproveFlgName(approveFlg: number) {
  switch (approveFlg) {
    case 0:
      return '未上传'
    case 1:
      return '审批已通过'
    case -1:
      return '审批不通过'
    case 2:
      return '已上传待审核'
    default:
      return '未匹配'
  }
}
// chrgitmLv（收费等级名称 ) 01：甲类 02：乙类03：丙类，
export function getChrgitmLvName(chrgitmLv: string) {
  switch (chrgitmLv) {
    case '01':
      return '甲类'
    case '02':
      return '乙类'
    case '03':
      return '丙类'
    default:
      return ''
  }
}

export function getIsCouldBxName(isCouldBx: string) {
  switch (isCouldBx) {
    case '1':
      return '可报销'
    default:
      return '不可报销'
  }
}

export const hilistLmtpricTypeOptions = [
  { id: '0', name: '不区分医院级别' },
  { id: '1', name: '一级及以下' },
  { id: '202', name: '职工县级' },
  { id: '3', name: '三级' },
  { id: '303', name: '职工省级' },
  { id: '305', name: '居民省级' },
  { id: '9011', name: '职工限价' },
  { id: '9014', name: '公立医院改革省级限价' },
  { id: '9021', name: '居民限价' },
  { id: '9024', name: '公立医院改革市级限价' },
  { id: '9032', name: '公立医院改革县级限价' },
  { id: '9041', name: '县级公立医院一级' },
  { id: '905', name: '诊疗项目医保最高价(一档)' },
  { id: '9052', name: '非公立省级限价' },
  { id: '9061', name: '县级公立医院三级' },
  { id: '907', name: '诊疗项目医保最高价(三档)' },
  { id: '908', name: '诊疗项目医保最高价(四档)' },
  { id: '909', name: '医保谈判价格' },
  { id: '910', name: '中选价格' },
  { id: '911', name: '医保支付上限' },
  { id: '9115', name: '三级非公立' },
  { id: '935', name: '一级及以下非公立' },
  { id: '975', name: '二级非公立' },
  { id: '999126', name: '公立医院取消耗材加成一级及以下' },
  { id: '999138', name: '公立医院取消耗材加成三级' },
  { id: '107', name: '居民乡级' },
  { id: '2', name: '二级' },
  { id: '203', name: '居民县级' },
  { id: '302', name: '职工市级' },
  { id: '304', name: '居民市级' },
  { id: '901', name: '两病门诊药品限价' },
  { id: '9012', name: '诊疗项目政府指导价格(一档)' },
  { id: '902', name: '一般药品限价' },
  { id: '9022', name: '诊疗项目政府指导价格(二档)' },
  { id: '903', name: '诊疗项目政府指导价格(三档)' },
  { id: '904', name: '诊疗项目政府指导价格(四档)' },
  { id: '9042', name: '公立医院改革乡级限价' },
  { id: '9051', name: '立医院二级' },
  { id: '906', name: '诊疗项目医保最高价(二档)' },
  { id: '9062', name: '非公立市级限价' },
  { id: '9072', name: '非公立县级限价' },
  { id: '9082', name: '非公立乡级限价' },
  { id: '9091', name: '市级县改限价' },
  { id: '9101', name: '县级标准下浮20基础上上浮15' },
  { id: '9111', name: '县级标准下浮20基础上上浮10' },
  { id: '915', name: '一级及以下公立' },
  { id: '955', name: '二级公立' },
  { id: '995', name: '三级公立' },
  { id: '999132', name: '公立医院取消耗材加成二级' },
]

export const getHilistLmtpricType = (type = '') =>{
 const item = hilistLmtpricTypeOptions.find(v=> v.id == type)
 return item ? item.name:'-'
}