/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: K
 * @LastEditTime: 2022-08-24 13:52:09
 */
import { Col, Row } from "antd";
import React, { ReactElement, useRef, useState } from "react";
import { NavBar } from "../../../compnents/nav/NavBar";
import { RoundTabBar, TabPane } from "../../../compnents/widgets/RoundTabBar";
import { Patient } from "../../../models/patient";
import { useQuery } from "../../../utils/HttpUtils";
import { AppointmentLists } from "../../appointment/list/appointmentList";
import { FollowUpTab } from "../../followUp/followUpTab";
import { MedicalSation } from "../../medicalSation/medicalSation";
import { OperationStation } from "../../operation/operation";
import { CurePlanListModal } from "../../treatment/editor/modals/curePlanListModal";
import { Charge } from "../Charge/charge";
import {
  PatientDetailPanel,
  PatientDetailPanelRef,
} from "../PatientDetailPanel";
import { ValueCard } from "../valueCard/valueCard";
import { PatientDetailBasic } from "./PatientDetailBasic";
import { PatientPayments } from "./PatientPayments";
import { PatientReports } from "./PatientReports";
import { PatientTreatmentHistory } from "./PatientTreatmentHistory";

export const PatientDetail = (): ReactElement => {
  const query = useQuery();

  const patientId = query.get("patientId") as string;

  const ref = useRef<PatientDetailPanelRef>(null);

  const [selectedTab, setSelectedTab] = useState("基本信息");

  const [patient, setPatient] = useState<Patient | undefined>();

  return (
    <Col
      style={{
        height: "calc(100% - 20px)",
        margin: "10px 20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavBar
        where={["患者管理", selectedTab]}
        backtrace={{
          name: "患者管理",
          path: "/statistics/outpatient",
        }}
      />

      <Row wrap={false} style={{ flex: 1, marginTop: 10, overflow: "hidden" }}>
        <Col
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <PatientDetailPanel
            ref={ref}
            style={{ width: 242 }}
            editable={false}
            patientId={patientId}
            onChange={(p) => setPatient(p)}
          />
        </Col>
        <RoundTabBar
          style={{ marginLeft: 20, flex: 1 }}
          activeKey={selectedTab}
          onChange={(k) => setSelectedTab(k)}
        >
          <TabPane key="基本信息" tab="基本信息">
            <PatientDetailBasic
              patient={patient}
              onSave={() => {
                ref.current?.reload();
              }}
            />
          </TabPane>
          <TabPane key="病历信息" tab="病历信息">
            <PatientTreatmentHistory patientId={patientId} />
          </TabPane>
          <TabPane key="报告管理" tab="报告管理">
            <PatientReports patientId={patientId} />
          </TabPane>
          <TabPane key="消费信息" tab="消费信息">
            <PatientPayments patientId={patientId} />
          </TabPane>
          <TabPane key="治疗计划" tab="治疗计划">
            <div style={{ backgroundColor: '#fff', height: '100%', padding: '15px', overflow: 'auto', borderRadius: 6 }}>
              <CurePlanListModal patientId={patientId} type={'0'} sourceType={0} />
            </div>
          </TabPane>
          <TabPane key="预约信息" tab="预约信息">

            <AppointmentLists patientId={patientId} />
          </TabPane>
          <TabPane key="随访信息" tab="随访信息">
            <FollowUpTab patientId={patientId} />
          </TabPane>
          <TabPane key="手术记录" tab="手术记录">
            <OperationStation patientId={patientId} />
          </TabPane>
          <TabPane key="医技站" tab="医技站">
            <MedicalSation patientId={patientId} />
          </TabPane>
          {/* <TabPane key="储值卡" tab="储值卡">
            <ValueCard />
          </TabPane>
          <TabPane key="收费管理" tab="收费管理">
            <Charge  />
          </TabPane> */}
        </RoundTabBar>
      </Row>
    </Col>
  );
};
