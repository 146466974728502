/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-10 09:21:12
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-19 14:52:25
 */
import { Col } from 'antd'
import React from 'react'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { FailureDirectory } from './failureDirectory'
import styles from './failureDirectory.module.css'

export const FailureDirectoryTabBar = () => {
  return (
    <Col className={styles.container}>
      <TabBar defaultActiveKey={'1'}>
        <TabPane key='1' tab='失效目录查询'>
          <FailureDirectory></FailureDirectory>
        </TabPane>
      </TabBar>
    </Col>
  )
}
