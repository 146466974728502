/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 13:54:51
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import { LoadingState } from '../../../models/common'
import { emptyOf, Pagination } from '../../../models/pagination'
import { inventoryRecords } from '../../../models/inventoryRecords'
import { fromJson } from '../../../models/inventoryRecords'
import * as service from '../../../services/inventoryRecords'
import {
  InventoryRecordsParams,
  getPutstorageExoprtFn,
} from '../../../services/inventoryRecords'
interface InventoryRecords {
  InventoryRecord: []
  Total: 0
  Current: 1
  pageLoading: false
}

const initialState: InventoryRecords = {
  InventoryRecord: [],
  Total: 0,
  Current: 1,
  pageLoading: false,
}

const inventoryRecordsSlice = createSlice({
  name: 'inventoryRecordsSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.InventoryRecord = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setData,
  setTotal,
  setCurrent,
  setPageLoading,
} = inventoryRecordsSlice.actions

export const selectInventoryRecord = (state: RootState) =>
  state.inventoryRecord.InventoryRecord

export const selectTotal = (state: RootState) => state.inventoryRecord.Total

export const selectCurrent = (state: RootState) => state.inventoryRecord.Current

export const selectPageLoading = (state: RootState) =>
  state.inventoryRecord.pageLoading

export function getInventoryRecordsList(
  params: InventoryRecordsParams
): RootThunk {
  return api(service.getInventoryRecords(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

// 导出
export const getInventoryRecordsListExoprt = createAsyncThunk<
  void,
  InventoryRecordsParams,
  RootThunkApi
>('doctorAudit/getInventoryRecordsListExoprt ', async (params, api) => {
  return sendAsync(getPutstorageExoprtFn(params), api)
})

export default inventoryRecordsSlice.reducer
