/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-31 14:23:29
 * @LastEditors: sj
 * @LastEditTime: 2022-06-30 15:24:11
 */
import { notification, Select, TableColumnType } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditableColumnType } from "../../../../compnents/list/EditableList";
import { DateTimeFormatSimple } from "../../../../models/datetime";
import {
  getMintenanceResultName,
  getMintenanceStepName,
  getQualityOfCommunityName,
  MintenanceResults,
  MintenanceStepS,
  QualityOfCommunities,
} from "../../../../models/maintenance";
import styles from "./additionDrug.module.css";
import { selectAddtionDrugData, setAddtionData } from "./addtionDrugSlice";
const { Option } = Select;

export const AdditionDrugsColumns = (
  onclick: (t: any) => void,
  startIndex: number,
  state: number
): // startIndex: number
EditableColumnType<any>[] => {
  const dispatch = useDispatch();

  const data = useSelector(selectAddtionDrugData);
  return [
    {
      title: "序号",
      align: "center",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    // 药品名
    // 有效期
    // 生产厂家
    // 批准文号
    // 包装规格
    {
      title: "药品信息",
      align: "left",
      dataIndex: "",
      key: "",
      render: function Element(_, record) {
        return (
          <div style={{ height: "55px" }}>
            <p
              style={{
                height: "28px",
                lineHeight: "36px",
                padding: "0",
                margin: "0",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              {record.mnemonicName}
            </p>
            <p
              style={{
                height: "28px",
                lineHeight: "24px",
                padding: "0",
                margin: "0",
                color: "#999999",
                fontSize: "14px",
              }}
            >
              {moment(record.deadline).format("yyyyMMd")} /
              {record.tenantSupplierName} /{record.approvalNumber}/
              {record.packSpec}
            </p>
          </div>
        );
      },
    },
    {
      title: "批次",
      align: "center",
      dataIndex: "batchNo",
      key: "batchNo",
    },
    {
      title: "库存数量",
      align: "center",
      dataIndex: "inventoryCount",
      key: "inventoryCount",
    },
    {
      title: "养护数量",
      align: "center",
      dataIndex: "maintainCount",
      editable: state > 0 ? false : true,
      width: "10%",
      nextDataIndex: "belowCount",
      key: "maintainCount",
      onSave: (prev, next) => {
        if (next.maintainCount <= prev.inventoryCount) {
          onclick({ prev, next });
        } else {
          notification.error({
            message: "养护数量不可大于库存数量",
            duration: 3,
          });
        }
      },
    },
    {
      title: "不合格数量",
      align: "center",
      dataIndex: "belowCount",
      editable: state > 0 ? false : true,
      width: "10%",
      key: "belowCount",
      onSave: (prev, next) => {
        if (next.belowCount <= prev.inventoryCount) {
          onclick({ prev, next });
        } else {
          notification.error({
            message: "不合格数量不可大于库存数量",
            duration: 3,
          });
        }
      },
    },
    {
      title: "养护措施",
      align: "center",
      width: "15%",
      dataIndex: "maintainMeasure",
      key: "maintainMeasure",
      render: function Element(_, t) {
        return (
          <div
            style={{
              height: "32px",
              overflow: "hidden",
              borderBottom: "1px rgb(217 217 217) solid",
            }}
          >
            <Select
              defaultValue={JSON.parse(t?.maintainMeasure)}
              style={{ width: "100%" }}
              className={styles.addtion}
              mode="multiple"
              onChange={(e) => {
                const newdata = { ...t, maintainMeasure: JSON.stringify(e) };
                dispatch(
                  setAddtionData(
                    data.map((v: any) => (v.id === t.id ? newdata : { ...v }))
                  )
                );
              }}
              onBlur={() => {
                if (!state) {
                  onclick({ prev: t, next: t });
                }
              }}
            >
              {MintenanceStepS.map((v) => (
                <Option key={v} value={v}>
                  {getMintenanceStepName(v)}
                </Option>
              ))}
            </Select>
          </div>
        );
      },
      onSave: (prev, next) => {
        onclick({ prev, next });
      },
    },
    {
      title: "质量状况",
      align: "center",
      width: "15%",
      dataIndex: "maintainQuality",
      key: "maintainQuality",
      render: function Element(_, t) {
        return (
          <Select
            defaultValue={t.maintainQuality}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            style={{ width: "100%" }}
            onSelect={(v: any) => {
              if (!state) {
                onclick({ prev: t, next: { maintainQuality: v } });
              }
            }}
          >
            {QualityOfCommunities.map((v) => (
              <Option key={v} value={v}>
                {getQualityOfCommunityName(v)}
              </Option>
            ))}
          </Select>
        );
      },
      onSave: (prev, next) => {
        onclick({ prev, next });
      },
    },
    {
      title: "养护结果",
      align: "center",
      width: "15%",
      dataIndex: "maintainResult",
      key: "maintainResult",
      render: function Element(_, t) {
        return (
          <Select
            defaultValue={t.maintainResult}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            style={{ width: "100%" }}
            onSelect={(v: any) => {
              if (!state) {
                onclick({ prev: t, next: { maintainResult: v } });
              }
            }}
          >
            {MintenanceResults.map((v) => (
              <Option key={v} value={v}>
                {getMintenanceResultName(v)}
              </Option>
            ))}
          </Select>
        );
      },
    },
  ];
};

export const PrintAdditionDrugsColumns = (
  startIndex: number
): // startIndex: number
EditableColumnType<any>[] => {
  const dispatch = useDispatch();

  const data = useSelector(selectAddtionDrugData);
  return [
    {
      title: "序号",
      align: "center",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    // 药品名
    // 有效期
    // 生产厂家
    // 批准文号
    // 包装规格
    {
      title: "药品信息",
      align: "left",
      dataIndex: "",
      key: "",
      render: function Element(_, record) {
        return (
          <div style={{ height: "55px" }}>
            <p
              style={{
                height: "28px",
                lineHeight: "36px",
                padding: "0",
                margin: "0",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              {record.mnemonicName}
            </p>
            <p
              style={{
                height: "28px",
                lineHeight: "24px",
                padding: "0",
                margin: "0",
                color: "#999999",
                fontSize: "14px",
              }}
            >
              {moment(record.deadline).format("yyyyMMd")} /
              {record.tenantSupplierName} /{record.approvalNumber}/
              {record.packSpec}
            </p>
          </div>
        );
      },
    },
    {
      title: "批次",
      align: "center",
      dataIndex: "batchNo",
      key: "batchNo",
    },
    {
      title: "库存数量",
      align: "center",
      dataIndex: "inventoryCount",
      key: "inventoryCount",
    },
    {
      title: "养护数量",
      align: "center",
      dataIndex: "maintainCount",
      nextDataIndex: "belowCount",
      key: "maintainCount",
    },
    {
      title: "不合格数量",
      align: "center",
      dataIndex: "belowCount",
      key: "belowCount",
    },
    {
      title: "养护措施",
      align: "center",
      dataIndex: "maintainMeasure",
      key: "maintainMeasure",
      render: function Element(_, t) {
        return JSON.parse(t?.maintainMeasure)
          ?.map((v: any) => {
            return getMintenanceStepName(v);
          })
          .join(",");
      },
    },
    {
      title: "质量状况",
      align: "center",
      dataIndex: "maintainQuality",
      key: "maintainQuality",
      render: function Element(_, t) {
        return getQualityOfCommunityName(t.maintainQuality);
      },
    },
    {
      title: "养护结果",
      align: "center",
      key: "maintainResult",
      dataIndex: "maintainResult",
      render: function Element(_, t) {
        return getMintenanceResultName(t.maintainResult);
      },
    },
  ];
};

export const MaintenanceDurg = (startIndex: number): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "药品信息",
      align: "left",
      dataIndex: "",
      key: "",
      render: function Element(_, record) {
        return (
          <div style={{ height: "55px" }}>
            <p
              style={{
                height: "28px",
                lineHeight: "36px",
                padding: "0",
                margin: "0",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              {record.mnemonicName}
            </p>
            <p
              style={{
                height: "28px",
                lineHeight: "24px",
                padding: "0",
                margin: "0",
                color: "#999999",
                fontSize: "14px",
              }}
            >
              {moment(record.deadline).format(DateTimeFormatSimple)} /
              {record.tenantSupplierName} /{record.approvalNumber}/
              {record.packSpec}
            </p>
          </div>
        );
      },
    },
    {
      title: "批次",
      align: "center",
      dataIndex: "batchNo",
      key: "batchNo",
    },
    {
      title: "库存数量",
      align: "center",
      dataIndex: "inventoryCount",
      key: "inventoryCount",
      render: (_, t) => {
        return `${t.inventoryCount} ${t.accountUnit}`;
      },
    },
  ];
};
