/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-16 18:06:31
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-20 11:04:35
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Modal, Popover, Row, Space } from 'antd'
import moment from 'moment'
import React, { forwardRef, ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { Label } from '../../../compnents/widgets/Label'
import { columns, DetailColumns } from './columns'
import styles from './reconciliationMessage.module.css'
import { serverType } from '../../../services/commodity'
import { useQuery } from '../../../utils/HttpUtils'
import {
  selfDayReconciliationAsync,
  selfDayReconciliationInfoAsync,
  selfDaySummaryUploadAsync,
  selfDaySummaryUploadInfoAsync,
  selfDaySummaryUploadInfoConfirmAsync,
  selfDaySummaryUploadInfoDeleteAsync,
  selfMonthReconciliationAsync,
  selfMonthReconciliationInfoAsync,
  selfMonthSummaryUploadAsync,
  selfMonthSummaryUploadInfoAsync,
  selfMonthSummaryUploadInfoConfirmAsync,
  selfMonthSummaryUploadInfoDeleteAsync,
} from '../ReconciliationSlice'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../../app/applicationSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../../models/datetime'
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils'

interface DayOrMonthReconciliationProps {
  tabKey?: any
}

const RealDayOrMonthReconciliations = ({
  tabKey,
}: DayOrMonthReconciliationProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({ current: 0, size: 10 })

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const baseData = useSelector(selectInsuranceItem)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const tenantCategory = useSelector(selectTenantCategory)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const [uploadDataLoading, setUploadDataLoading] = useState(false)

  const [uploadInfoDataLoading, setUploadInfoDataLoading] = useState(false)

  const [
    uploadInfoDeleteDataLoading,
    setUploadInfoDeleteDataLoading,
  ] = useState(false)

  const [
    uploadInfoConfirmDataLoading,
    setUploadInfoConfirmDataLoading,
  ] = useState(false)

  const [monthReconciliationLoading, setMonthReconciliationLoading] = useState(
    false
  )

  const [
    monthReconciliationInfoLoading,
    setMonthReconciliationInfoLoading,
  ] = useState(false)

  const [
    monthReconciliationInfoData,
    setMonthReconciliationInfoData,
  ] = useState<any>()

  const [
    dayReconciliationInfoData,
    setDayReconciliationInfoData,
  ] = useState<any>()

  const reconciliationInfoData =
    tabKey == 1 ? dayReconciliationInfoData : monthReconciliationInfoData
  const query = useQuery()

  const accountDate = query.get('accountDate') as any

  const [monthPage, setMonthPage] = useState<any>()

  const [dayPage, setDayPage] = useState<any>()

  const page = tabKey == 1 ? dayPage : monthPage

  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  // 月对账

  // 汇总上传
  const selfMonthSummaryUploadData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setUploadDataLoading(true)
    dispatch(
      selfMonthSummaryUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '汇总上传',
            },
            (call: any, err) => {
              !err &&
                selfMonthSummaryUploadData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setUploadDataLoading(false)
          // dispatch(getPaymentList(params))
        }
      })
      .catch(() => {
        setUploadDataLoading(false)
      })
  }

  // 上传查询
  const selfMonthSummaryUploadInfoData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setUploadInfoDataLoading(true)
    dispatch(
      selfMonthSummaryUploadInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '上传查询',
            },
            (call: any, err) => {
              !err &&
                selfMonthSummaryUploadInfoData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setUploadInfoDataLoading(false)
          setMonthPage(res)
        }
      })
      .catch(() => {
        setUploadInfoDataLoading(false)
      })
  }

  // 删除
  const selfMonthSummaryUploadInfoDeleteData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setUploadInfoDeleteDataLoading(true)
    const solidIds = page?.res?.map((v: any) => {
        return v?.solidify_id
    })
    dispatch(
      selfMonthSummaryUploadInfoDeleteAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
          solidifyIdList:solidIds
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '上传删除',
            },
            (call: any, err) => {
              !err &&
                selfMonthSummaryUploadInfoDeleteData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setUploadInfoDeleteDataLoading(false)
          setMonthPage([])
        }
      })
      .catch(() => {
        setUploadInfoDeleteDataLoading(false)
      })
  }

  // 上传确认
  const selfMonthSummaryUploadInfoConfirmData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    const solidIds = page?.res?.map((v: any) => {
        return v?.solidify_id
    })
    setUploadInfoConfirmDataLoading(true)
    dispatch(
      selfMonthSummaryUploadInfoConfirmAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
          solidifyIdList:solidIds
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '上传确认',
            },
            (call: any, err) => {
              !err &&
                selfMonthSummaryUploadInfoConfirmData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setUploadInfoConfirmDataLoading(false)
          selfMonthReconciliationData('requestString', accountDate)
        }
      })
      .catch(() => {
        setUploadInfoConfirmDataLoading(false)
      })
  }

  // 月对账
  const selfMonthReconciliationData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setMonthReconciliationLoading(true)
    dispatch(
      selfMonthReconciliationAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '对账',
            },
            (call: any, err) => {
              !err &&
                selfMonthReconciliationData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setMonthReconciliationLoading(false)
        }
      })
      .catch(() => {
        setMonthReconciliationLoading(false)
      })
  }

  // 月对账明细查询
  const selfMonthReconciliationInfoData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setMonthReconciliationInfoLoading(true)
    dispatch(
      selfMonthReconciliationInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '月对账明细查询',
            },
            (call: any, err) => {
              !err &&
                selfMonthReconciliationInfoData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setMonthReconciliationInfoLoading(false)
          setMonthReconciliationInfoData(res)
        }
      })
      .catch(() => {
        setMonthReconciliationInfoLoading(false)
      })
  }

  // 日对账

  // 汇总上传
  const selfDaySummaryUploadData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setUploadDataLoading(true)
    dispatch(
      selfDaySummaryUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '汇总上传',
            },
            (call: any, err) => {
              !err &&
                selfDaySummaryUploadData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setUploadDataLoading(false)
          // dispatch(getPaymentList(params))
        }
      })
      .catch(() => {
        setUploadDataLoading(false)
      })
  }

  // 上传查询
  const selfDaySummaryUploadInfoData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setUploadInfoDataLoading(true)
    dispatch(
      selfDaySummaryUploadInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '上传查询',
            },
            (call: any, err) => {
              !err &&
                selfDaySummaryUploadInfoData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setUploadInfoDataLoading(false)
          setDayPage(res)
        }
      })
      .catch(() => {
        setUploadInfoDataLoading(false)
      })
  }

  // 删除
  const selfDaySummaryUploadInfoDeleteData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setUploadInfoDeleteDataLoading(true)
    const solidIds = page?.res?.map((v: any) => {
        return v?.solidify_id
    })
    dispatch(
      selfDaySummaryUploadInfoDeleteAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
          solidifyIdList:solidIds
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '上传删除',
            },
            (call: any, err) => {
              !err &&
                selfDaySummaryUploadInfoDeleteData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setUploadInfoDeleteDataLoading(false)
          setDayPage([])
        }
      })
      .catch(() => {
        setUploadInfoDeleteDataLoading(false)
      })
  }

  // 上传确认
  const selfDaySummaryUploadInfoConfirmData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    const solidIds = page?.res?.map((v: any) => {
        return v?.solidify_id
    })
    setUploadInfoConfirmDataLoading(true)
    dispatch(
      selfDaySummaryUploadInfoConfirmAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
          solidifyIdList:solidIds
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '上传确认',
            },
            (call: any, err) => {
              !err &&
                selfDaySummaryUploadInfoConfirmData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setUploadInfoConfirmDataLoading(false)
          selfDayReconciliationData('requestString', accountDate)
        }
      })
      .catch(() => {
        setUploadInfoConfirmDataLoading(false)
      })
  }

  // 月对账
  const selfDayReconciliationData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setMonthReconciliationLoading(true)
    dispatch(
      selfDayReconciliationAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '对账',
            },
            (call: any, err) => {
              !err &&
                selfDayReconciliationData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setMonthReconciliationLoading(false)
          // dispatch(getPaymentList(params))
        }
      })
      .catch(() => {
        setMonthReconciliationLoading(false)
      })
  }

  // 日对账明细查询
  const selfDayReconciliationInfoData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setMonthReconciliationInfoLoading(true)
    dispatch(
      selfDayReconciliationInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '日对账明细查询',
            },
            (call: any, err) => {
              !err &&
                selfDayReconciliationInfoData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setMonthReconciliationInfoLoading(false)
          setDayReconciliationInfoData(res)
        }
      })
      .catch(() => {
        setMonthReconciliationInfoLoading(false)
      })
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <Col
        style={{
          borderRadius: 10,
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Label
            label={tabKey == 1 ? '待固化日汇总数据' : '待固化月汇总数据'}
            style={{ marginLeft: 10, fontSize: '1.125rem' }}
          />
          <Space>
            <Button
              type='primary'
              loading={uploadDataLoading}
              onClick={() => {
                if (tabKey == 1) {
                  selfDaySummaryUploadData('requestString', accountDate)
                } else {
                  selfMonthSummaryUploadData('requestString', accountDate)
                }
              }}
            >
              {tabKey == 1 ? '按日汇总上传' : '按月汇总上传'}
            </Button>
            <Button
              type='primary'
              loading={uploadInfoDataLoading}
              onClick={() => {
                if (tabKey == 1) {
                  selfDaySummaryUploadInfoData('requestString', accountDate)
                } else {
                  selfMonthSummaryUploadInfoData('requestString', accountDate)
                }
              }}
            >
              查询已上传数据
            </Button>
            <Button
              loading={uploadInfoDeleteDataLoading}
              type='primary'
              onClick={() => {
                Modal.confirm({
                  centered: false,
                  title: '是否确认清空数据？',
                  onOk: () => {
                    if (tabKey == 1) {
                      selfDaySummaryUploadInfoDeleteData(
                        'requestString',
                        accountDate
                      )
                    } else {
                      selfMonthSummaryUploadInfoDeleteData(
                        'requestString',
                        accountDate
                      )
                    }
                  },
                })
              }}
            >
              清空数据
            </Button>
            <div
              className={styles.backsButtton}
              onClick={() => {
                if (tabKey == 1) {
                  selfDaySummaryUploadInfoConfirmData(
                    'requestString',
                    accountDate
                  )
                } else {
                  selfMonthSummaryUploadInfoConfirmData(
                    'requestString',
                    accountDate
                  )
                }
              }}
            >
              <Button type='primary' loading={uploadInfoConfirmDataLoading}>
                {tabKey == 1 ? '确认并进行日对账' : '确认并进行月对账'}
              </Button>
            </div>
            <div
              className={styles.backsButtton}
              onClick={() => {
                if (tabKey == 1) {
                  selfDayReconciliationData('requestString', accountDate)
                } else {
                  selfMonthReconciliationData('requestString', accountDate)
                }
              }}
            >
              <Button type='primary' loading={monthReconciliationLoading}>
                {tabKey == 1 ? '日对账' : '月对账'}
              </Button>
            </div>
          </Space>
        </Row>
        <EditableList
          pagination={false}
          scroll={{ x: '30rem' }}
          loading={pageLoading}
          page={{
            items: page?.res,
            current: 1,
            size: 10,
            total: page?.res?.length,
          }}
          rowKey={(m: any) => m.paymentId}
          columns={columns()}
          onChangePage={(current, size) =>
            setParams({ ...params, current, size: size || 10 })
          }
          sticky
        />
      </Col>
      <Col
        style={{
          borderRadius: 10,
          marginTop: '20px',
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Label
            label={tabKey == 1 ? '日对账明细信息' : '月对账明细信息'}
            style={{ marginLeft: 10, fontSize: '1.125rem' }}
          />
          <Space>
            <Button
              type='primary'
              loading={monthReconciliationInfoLoading}
              onClick={() => {
                if (tabKey == 1) {
                  selfDayReconciliationInfoData('requestString', accountDate)
                } else {
                  selfMonthReconciliationInfoData('requestString', accountDate)
                }
              }}
            >
              查询明细
            </Button>
          </Space>
        </Row>
        <Row style={{ margin: '10px 0 15px 0' }}>
          {!reconciliationInfoData ? (
            <span>
              对账结果：{'-'} ， 备注：
              {'-'}
            </span>
          ) : (
            <>
              <Popover
                content={reconciliationInfoData?.res?.memo}
                placement='bottom'
                title={null}
              >
                <span
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  对账结果：{reconciliationInfoData?.res?.stmt_status || '-'} ，
                  备注：{reconciliationInfoData?.res?.memo || '-'}
                </span>
              </Popover>
            </>
          )}
        </Row>
        <EditableList
          pagination={false}
          scroll={{ x: '30rem' }}
          loading={pageLoading}
          page={{
            items: reconciliationInfoData?.res?.totlLastStmtRsltDetailList,
            current: 1,
            size: 10,
            total:
              reconciliationInfoData?.res?.totlLastStmtRsltDetailList?.length,
          }}
          rowKey={(m: any) => m.paymentId}
          columns={DetailColumns()}
          onChangePage={(current, size) =>
            setParams({ ...params, current, size: size || 10 })
          }
          sticky
        />
      </Col>
    </div>
  )
}

export const DayOrMonthReconciliations = forwardRef(
  RealDayOrMonthReconciliations
)
