import { unwrapResult } from '@reduxjs/toolkit'
import { Col, message, Modal, notification, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { Columns } from './columns'
import styles from '../transactionQueryTab.module.css'
import { Query } from './Query'
import { ThemeContext } from '../../../theme/ThemeContext'
import {
  getTransactionQueryExport,
  getTransactionQueryList,
} from '../transactionQuerySlice'
import { getDateRange, DateRangeType } from '../../../compnents/form/DateSelect'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import {
  getPaymentResult,
  isCancelUpload,
  selectResult,
} from '../../payment/result/paymentResultSlice'
import {
  getPaymentDetail,
  JuhePremium,
  refund as refundPayment,
} from '../../payment/list/paymentSlice'
import { getPayFlagName, Payment } from '../../../models/payment'
import {
  selectEdition,
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../../app/applicationSlice'
import { getCardType } from '../../payment/JuhePayPage/columns'
import { getTransactionQueryName } from '../../../models/TransactionQuery'
import { singleZlPrintBody } from '../../../models/transactionQueryPrint'
import { result, sum } from 'lodash'
import { traceRoute } from '../../../layouts/layoutSlice'
import { RealPayModal } from '../../payment/list/realPayModal'
import { Refund } from '../../../models/refund'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import {
  strParse,
  medicalSendHttp,
  getTradeName,
  readCardSHttp,
  readCardEHttp,
} from '../../../utils/MedicalUtils'
import { cancelSettlementAsync } from '../../payment/confirm/paymentConfirmSlice'
import { TableRowSelection } from 'antd/lib/table/interface'
import { DataType } from '../../putstorage/putstorage'
import { PatientModal } from '../../patient/modals/PatientModal'
import { getTradeCode } from '../../../models/material'
import { getPatientInfoAsync } from '../../registration/register/registerSlice'

interface TransactionQueryProps {
  selectedTab?: any
  flag?: any
}
export interface TransactionQueryRef {
  reload: () => void
  outClude: () => void
}

const TransactionQuery = (
  { selectedTab, flag }: TransactionQueryProps,
  ref: React.Ref<TransactionQueryRef>
): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [patient, setPatient] = useState<any>()

  const [registrationId, setRegistrationId] = useState<any>()

  const [treatmentId, setTreatmentId] = useState<any>()

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname + '_jiaoyichaxun') || '{}'
  )

  const [params, setParams] = useState<any>({
    current: storageParams?.current ? Number(storageParams?.current) : 1,
    size: 20,
  })

  const [selectedKeys, setSelectedKeys] = useState<any>([])

  const tenantName = useSelector(selectTenantName)

  const [page, setPage] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false)

  const [isReplaceMakeVisible, setIsReplaceMakeVisible] = useState(false)

  const [isDetailModalVisible, setIsDetailModalVisible] = useState<any>(false)

  const [refund, setRefund] = useState<Refund | undefined>()

  const result = useSelector(selectResult) //预退费详情接口

  const [buttonLoading, setButtonLoading] = useState(false)

  const [stkButtonLoading, setStkButtonLoading] = useState(false)

  const [dzpzButtonLoading, setDzpzButtonLoading] = useState(false)

  const [scrollHeight, setScrollHeight] = useState<any>()

  const [tableId, setTableId] = useState('')

  const [refundParams, setRefundParams] = useState<any>()

  const [totalData, setTotalData] = useState<any>()

  const [sureReplaceMakeVisible, setSureReplaceMakeVisible] = useState(false)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const baseData = useSelector(selectInsuranceItem)

  useEffect(() => {
    if (selectedTab == '1') {
      setPageLoading(true)
      if (params?.registrationTimeHead && params?.registrationTimeTail) {
        getPage()
      }
    }
  }, [selectedTab, params])

  useEffect(() => {
    setButtonLoading(false)
    setDzpzButtonLoading(false)
    setStkButtonLoading(false)
  }, [])

  const refondSubmit = (id: any) => {
    setButtonLoading(true)
    dispatch(refundPayment(id))
      .then(unwrapResult)
      .then(() => {
        setButtonLoading(false)
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
        setRefund(undefined)
        getPage()
      })
      .catch(() => {
        setButtonLoading(false)
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
      })
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedKeys(selectedRowKeys)
    },
  }

  useEffect(() => {
    if (!tableId) return
    result &&
      dispatch(getPaymentDetail(tableId))
        .then(unwrapResult)
        .then((refund) => {
          setRefund(refund)
        })
  }, [result])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  const cancelSettlement = (
    serverType: any,
    paymentId?: string,
    cardType?: string,
    fullCardInfo?: string,
    responseString?: string
  ) => {
    const insuranceItem = insuranceArray.find(
      (v: any) => v.insuranceCode === refund?.insuranceCode
    )
    dispatch(
      cancelSettlementAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          exp_content: '',
          treatmentId: refund?.treatmentId,
          paymentId,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              notification: '退费',
            },
            (call: any, err) => {
              if (!err) {
                cancelSettlement(
                  'response',
                  paymentId,
                  cardType,
                  fullCardInfo,
                  JSON.stringify(call.netmessage)
                )
              }
              setStkButtonLoading(false)
              setDzpzButtonLoading(false)
            }
          )
        } else {
          refondSubmit(refund?.id)
          // resolve()
          //更新医保卡数据
          if (cardType == '1') {
            //实体卡
            getPatientInfo(
              '3',
              baseData?.insuranceCode == 'JIANGSU'
                ? String(fullCardInfo)
                : JSON.stringify(fullCardInfo),
              'requestString'
            )
          } else if (cardType == '2') {
            //电子凭证
            getPatientInfo('1', String(fullCardInfo), 'requestString')
          }
          // submit(vs, undefined, res)
        }
      })
      .catch((v) => {
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
      })
  }

  const success = (str: string) => {
    const hide = message.loading(str, 0)
    setTimeout(() => {
      hide()
    }, 3000)
  }

  // 获取参保人信息
  const getPatientInfo = (
    cardType: string,
    fullCardInfo: string,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          cardType: String(cardType),
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                message.loading('正在更新人员信息')
                getPatientInfo(
                  cardType,
                  fullCardInfo,
                  'response',
                  JSON.stringify(call.netmessage)
                )
                // }
              }
            }
          )
        } else {
          message.success('人员信息已更新')
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 3,
        })
      })
  }

  const getPage = () => {
    dispatch(getTransactionQueryList({ ...params, selectedTab: undefined }))
      .then(unwrapResult)
      .then((v: any) => {
        onResize() // 初始化设置高度
        window.addEventListener('resize', onResize)
        setPage({
          items: v?.records,
          size: v?.size,
          total: v?.total,
          current: v?.current,
        })
        setPageLoading(false)
      })
  }

  useEffect(() => {
    params.current &&
      sessionStorage.setItem(
        location.pathname + '_jiaoyichaxun',
        JSON.stringify({ ...params })
      )
  }, [params])

  // 计算合计数据
  useEffect(() => {
    if (selectedKeys?.length) {
      const data = page?.items?.filter((v: any) => v?.state != 5)
      if (data?.length) {
        // 过滤出的包含勾选的所有数据
        const filterList: any = []
        selectedKeys.forEach((item: any) => {
          data.forEach((element: any) => {
            if (item == element.paymentId) {
              filterList.push(element)
            }
          })
        })
        // 计算合计
        const totalPriceAll = sum(
          filterList?.map((v: any) =>
            v?.totalPrice > -1 ? Number(v?.totalPrice) : 0
          )
        )
        const insurancePayAmountAll = sum(
          filterList?.map((v: any) =>
            v?.insurancePayAmount > -1 ? Number(v?.insurancePayAmount) : 0
          )
        )
        const cashAmountAll = sum(
          filterList?.map((v: any) =>
            v?.cashAmount > -1 ? Number(v?.cashAmount) : 0
          )
        )
        const arrearageAmountAll = sum(
          filterList?.map((v: any) =>
            v?.arrearageAmount > -1 ? Number(v?.arrearageAmount) : 0
          )
        )
        const overlmtSelfpayAll = sum(
          filterList?.map((v: any) =>
            v?.overlmtSelfpay > -1 ? Number(v?.overlmtSelfpay) : 0
          )
        )
        setTotalData({
          totalPriceAll: totalPriceAll,
          insurancePayAmountAll: insurancePayAmountAll,
          cashAmountAll: cashAmountAll,
          arrearageAmountAll: arrearageAmountAll,
          overlmtSelfpayAll: overlmtSelfpayAll,
        })
      } else {
        setTotalData({
          totalPriceAll: 0.0,
          insurancePayAmountAll: 0.0,
          cashAmountAll: 0.0,
          arrearageAmountAll: 0.0,
          overlmtSelfpayAll: 0.0,
        })
      }
    } else {
      const data = page?.items?.filter((v: any) => v?.state != 5)
      if (data?.length) {
        // 没勾选的情况下计算全部
        const totalPriceAll = sum(
          data?.map((v: any) =>
            v?.totalPrice > -1 ? Number(v?.totalPrice) : 0
          )
        )
        const insurancePayAmountAll = sum(
          data?.map((v: any) =>
            v?.insurancePayAmount > -1 ? Number(v?.insurancePayAmount) : 0
          )
        )
        const cashAmountAll = sum(
          data?.map((v: any) =>
            v?.cashAmount > -1 ? Number(v?.cashAmount) : 0
          )
        )
        const arrearageAmountAll = sum(
          data?.map((v: any) =>
            v?.arrearageAmount > -1 ? Number(v?.arrearageAmount) : 0
          )
        )
        const overlmtSelfpayAll = sum(
          data?.map((v: any) =>
            v?.overlmtSelfpay > -1 ? Number(v?.overlmtSelfpay) : 0
          )
        )

        setTotalData({
          totalPriceAll: totalPriceAll,
          insurancePayAmountAll: insurancePayAmountAll,
          cashAmountAll: cashAmountAll,
          arrearageAmountAll: arrearageAmountAll,
          overlmtSelfpayAll: overlmtSelfpayAll,
        })
      } else {
        setTotalData({
          totalPriceAll: 0.0,
          insurancePayAmountAll: 0.0,
          cashAmountAll: 0.0,
          arrearageAmountAll: 0.0,
          overlmtSelfpayAll: 0.0,
        })
      }
    }
  }, [page, selectedKeys])

  // 打印
  const dataMainList = {
    tenantName: tenantName?.split('-')?.[0],
    data: page?.items?.map((v: any) => {
      return {
        ...v,
        nameState:
          v?.state == 3
            ? '已收费'
            : v?.state == 5
            ? '已退费'
            : v?.state == 7
            ? '欠费中'
            : '',
        patientName: v?.patientName ? v?.patientName : '-',
        patientId: v?.patientId ? v?.patientId : '-',
        patientPhone: v?.patientPhone ? v?.patientPhone : '-',
        insuranceCode:
          v?.insuranceCode === 'ZIFEI' || !v?.insuranceCode
            ? '自费'
            : '智慧医保',
        totalPrice:
          v?.state == 5
            ? 0
            : Number(v?.totalPrice) > 0
            ? Number(v?.totalPrice)?.toFixed(2)
            : '0.00',
        insurancePayAmount:
          Number(v?.insurancePayAmount) > 0
            ? Number(v?.insurancePayAmount)?.toFixed(2)
            : '0.00',
        cashAmount:
          Number(v?.cashAmount) > 0
            ? Number(v?.cashAmount)?.toFixed(2)
            : '0.00',
        arrearageAmount:
          Number(v?.arrearageAmount) > 0
            ? Number(v?.arrearageAmount)?.toFixed(2)
            : '0.00',
        overlmtSelfpay:
          Number(v?.overlmtSelfpay) > 0
            ? Number(v?.overlmtSelfpay)?.toFixed(2)
            : '0.00',
        jibenylbxtcjjzc:
          Number(v?.jibenylbxtcjjzc) > 0
            ? Number(v?.jibenylbxtcjjzc)?.toFixed(2)
            : '0.00',
        discountAmount:
          Number(v?.discountAmount) > 0
            ? Number(v?.discountAmount)?.toFixed(2)
            : '0.00',
        gerenzhzc:
          Number(v?.gerenzhzc) > 0 ? Number(v?.gerenzhzc)?.toFixed(2) : '0.00',
        treatmentDoctorName: v?.treatmentDoctorName
          ? v?.treatmentDoctorName
          : '0.00',
        cardType: v?.cardType ? getCardType(v?.cardType) : '-',
        settlementCategory: v?.settlementCategory
          ? getPayFlagName(v?.settlementCategory)
          : '-',
        userName: v?.userName ? v?.userName : '-',
        chargeOrRefundTime: v?.chargeOrRefundTime
          ? moment(v?.chargeOrRefundTime)?.format(DateTimeFormatSimple)
          : '-',
        state: v?.state ? getTransactionQueryName(Number(v?.state)) : '-',
        stateNumber: v?.state,
      }
    }),
    timeHead: moment(params?.registrationTimeHead).format(
      DateTimeFormatSimpleOnly
    ),
    timeTail: moment(params?.registrationTimeTail).format(
      DateTimeFormatSimpleOnly
    ),
  }

  useImperativeHandle(ref, () => ({
    reload: () => {
      const content = singleZlPrintBody(dataMainList)
      const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
      page.document.write(content) // 写入打印页面的内容
      page.print() // 打印
      page?.close()
    },
    outClude: () => {
      outCluedExport()
    },
  }))

  const outCluedExport = () => {
    dispatch(getTransactionQueryExport({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '交易查询.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        notification.success({
          message: '导出失败',
          duration: 3,
        })
      })
  }

  // 表格双击跳转
  const tableDoubleFn = (record: any) => {
    if (record?.state == 3 || record?.state == 5 || record?.state == 7) {
      dispatch(
        traceRoute({
          name: '收费',
          path: '/payment/result',
          query: `paymentId=${record.paymentId}&status=${
            record?.state
          }&selectedTab=${selectedTab}&name=${'交易查询'}`,
          state: {
            name:
              record?.state == 3
                ? '已收费'
                : record?.state == 5
                ? '已退费'
                : record?.state == 7
                ? '欠费中'
                : '',
            path: '/transactionQuery',
            routeName: '交易查询',
          },
        })
      )
    }
  }

  const doRefund = (cardType?: string, fullCardInfo?: string) => {
    const payMethodList = refund?.payMethod?.toString()?.split(',')
    const isJuHe: any = payMethodList?.find((v: any) => {
      return v == 2
    })
    if (isJuHe) {
      //是否聚合支付退费
      dispatch(
        JuhePremium({
          treatmentId: refund?.treatmentId || '',
          paymentId: refund?.id || ''
        })
      )
        .then(unwrapResult)
        .then(() => {
          setStkButtonLoading(false)
          setDzpzButtonLoading(false)
          notification.success({ message: '聚合支付退费成功' })
          if (refund) {
            if (refund.insuranceCode !== 'ZIFEI') {
              cancelSettlement(
                'requestString',
                refund.id,
                cardType,
                fullCardInfo
              )
            } else {
              refondSubmit(refund.id)
            }
          }
        })
        .catch((v) => {
          setStkButtonLoading(false)
          setDzpzButtonLoading(false)
        })
    } else {
      if (refund) {
        if (refund.insuranceCode !== 'ZIFEI') {
          cancelSettlement('requestString', refund.id, cardType, fullCardInfo)
        } else {
          refondSubmit(refund.id)
        }
      }
    }
  }

  const validCardInfoBeforeGoRefund = (type: string) => {
    //实体卡读卡校验
    if (type == '1') {
      setStkButtonLoading(true)
      //部分强制读卡的地区，跳过校验
      const tradeType = getTradeName()
      if (tradeType === 'CQ_ZHYB') {
        doRefund()
        return
      }
      readCardSHttp(
        {
          trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
        },
        (data, err) => {
          if (!err) {
            //其他地区需要做二次读卡校验
            const Name =
              tradeType === 'JS_ZHYB'
                ? data?.netmessage
                    ?.split('|')[4]
                    ?.replace('\r', '')
                    .replace('\t', '')
                    .replace(' ', '')
                    .trim()
                : data?.netmessage?.Name
            if (Name != refund?.patientName) {
              Modal.error({
                title: '提示',
                content:
                  `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                  refund?.patientName +
                  '，卡片姓名：' +
                  data?.netmessage.Name,
                okText: '确认',
              })
              setStkButtonLoading(false)
            } else {
              doRefund(type, data?.netmessage)
            }
          }
        }
      )
      //电子凭证读卡校验
    } else if (type == '2') {
      setDzpzButtonLoading(true)
      setTimeout(() => {
        setDzpzButtonLoading(false)
      }, 3000)
      readCardEHttp(
        {
          trade_args: [
            baseData?.countryHospitalNb,
            '01301',
            '',
            userId,
            userName,
            '',
            '',
          ],
        },
        (data, err) => {
          if (!err) {
            // {"netcode":"1","netmessage":"徐春霞|330621198804253802|01|330000ecaqm4mb0mvk0b8fab0a0000236f0d3c|330182|","QrCode":"","ChnlId":""}
            const readName = data?.netmessage?.split('|')[0]
            if (readName != refund?.patientName) {
              Modal.error({
                title: '提示',
                content:
                  `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                  refund?.patientName +
                  '，电子凭证姓名：' +
                  readName,
                okText: '确认',
              })
              setDzpzButtonLoading(false)
            } else {
              doRefund(type, data?.netmessage)
            }
          }
        }
      )
      //自费直接调用退费
    } else if (type == '3') {
      doRefund()
      //自费直接调用退费
    } else if (type == '0') {
      doRefund()
    } else {
      Modal.error({
        title: '错误',
        content: `未知的退费类型` + type + `请联系客服人员处理`,
        okText: '确认',
      })
    }
  }

  const onResize = () => {
    const table = document.getElementById('tableHeights')
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 20
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }
  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)',
        marginTop: flag ? 0 : '10px',
        overflow: 'hidden',
        padding: flag ? 0 : '10px 15px 0 15px',
      }}
    >
      <Query
        onValueChange={(v: any) => {
          setSelectedKeys([])
          const paramData = {
            ...params,
            ...v,
            registrationTimeHead: v?.timeHead
              ? moment(v?.timeHead).format(DateTimeFormatSimpleOnlyOne)
              : moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
            registrationTimeTail: v?.timeTail
              ? moment(v?.timeTail).format(DateTimeFormatSimpleOnlyOne)
              : moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
            timeHead: undefined,
            timeTail: undefined,
            current: v?.current
              ? v?.current
              : storageParams?.current
              ? storageParams?.current
              : 1,
          }
          setParams({
            ...paramData,
          })
        }}
        selectedTab={selectedTab}
      />
      <div
        id='tableHeights'
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <EditableList
          // className={flag ? styles.table : ''}
          scroll={{
            y: Number(scrollHeight),
            x: (hisVersion == 1 && 1900) as any,
          }}
          style={{
            width: '100%',
            height: '89%',
            overflow: 'hidden',
          }}
          rowKey={(m) => m.paymentId}
          loading={pageLoading}
          page={page}
          columns={Columns(
            page?.size * (page?.current - 1),
            (action, id: any, t, settlementCategory) => {
              switch (action) {
                case 'return':
                  setTableId(t?.paymentId)
                  if (t?.potionRecipeStatus !== 1) {
                    dispatch(getPaymentResult(id)) //获取预退费表格信息
                  } else {
                    setIsReplaceMakeVisible(true)
                    setRefundParams(t)
                  }
                  break
              }
            },
            (text, v) => {
              if (text == 'openDetail') {
                setPatient({ ...v, id: v?.patientId })
                // setRegistrationId(v?.registrationId)
                // setTreatmentId(v?.treatmentId)
                setIsDetailModalVisible(true)
              }
            }
          )}
          rowSelection={
            {
              selectedRowKeys: selectedKeys,
              ...rowSelection,
            } as TableRowSelection<any>
          }
          onChangePage={(current, size) => {
            setSelectedKeys([])
            setParams({
              ...params,
              current,
              size,
            })
          }}
          onRow={(record) => ({
            onDoubleClick: () => tableDoubleFn(record),
          })}
          // sticky
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell
                  index={0}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={1}
                  align='center'
                  className={styles.fixed}
                >
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>

                <Table.Summary.Cell index={6} align='right'>
                  {totalData?.totalPriceAll?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align='right'>
                  {totalData?.insurancePayAmountAll?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align='right'>
                  {totalData?.cashAmountAll?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} align='right'>
                  {totalData?.arrearageAmountAll?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} align='right'>
                  {totalData?.overlmtSelfpayAll?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={12}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={15}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={16}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={17}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={18}
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </div>
      <RealPayModal
        buttonLoading={buttonLoading}
        stkButtonLoading={stkButtonLoading}
        dzpzButtonLoading={dzpzButtonLoading}
        refund={refund}
        onOK={(type) => {
          validCardInfoBeforeGoRefund(type)
        }}
        onCancel={() => setRefund(undefined)}
        resultData={result?.paymentDetailRecordVOS}
      />
      <Modal
        closable={false}
        maskClosable={false}
        cancelText='不撤销'
        okText='撤销'
        visible={isReplaceMakeVisible}
        title=''
        onOk={() => {
          setIsReplaceMakeVisible(false)
          dispatch(
            isCancelUpload({
              treatmentId: refundParams!.treatmentId,
              potionRecipeStatus: 3,
              paymentId: refundParams!.paymentId,
            })
          )
            .then(unwrapResult)
            .then(() => {
              dispatch(getPaymentDetail(refundParams!.paymentId))
                .then(unwrapResult)
                .then((refund) => {
                  // setRefund(refund)
                  dispatch(getPaymentResult(tableId)) //获取预退费表格信息
                })
            })
        }}
        onCancel={() => {
          setIsReplaceMakeVisible(false)
          setSureReplaceMakeVisible(true)
        }}
      >
        本单已传至代煎方，需要撤销代煎吗？
      </Modal>
      <Modal
        visible={sureReplaceMakeVisible}
        title=''
        onOk={() => {
          setSureReplaceMakeVisible(false)
          dispatch(getPaymentDetail(refundParams!.paymentId))
            .then(unwrapResult)
            .then((refund) => {
              // setRefund(refund)
              dispatch(getPaymentResult(tableId)) //获取预退费表格信息
            })
        }}
        onCancel={() => {
          setSureReplaceMakeVisible(false)
        }}
      >
        退费时不撤销订单可能带来损失，确认不需要撤销代煎吗？
      </Modal>
      <PatientModal
        tag={true}
        status={1}
        patient={patient}
        // registrationId={registrationId}
        // treatmentId={treatmentId}
        visible={isDetailModalVisible}
        onCancel={() => setIsDetailModalVisible(false)}
        onOk={() => setIsDetailModalVisible(false)}
      />
    </Col>
  )
}

export const TransactionQuerys = forwardRef(TransactionQuery)
