/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 11:59:44
 */
import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../app/applicationSlice'
import { RootState, RootThunk } from '../../app/store'
import { stockfloorPage, StockfloorParams } from '../../services/stockfloor'

const initialState = {
  StockfloorData: [],
  Total: 0,
  Current: 1,
  pageLoading: false,
}

export const stockfloorSlice = createSlice({
  name: 'stockfloor',
  initialState,
  reducers: {
    setData(state, action) {
      state.StockfloorData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export function pageStockfloor(params: StockfloorParams): RootThunk {
  return api(stockfloorPage(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

export const {
  setData,
  setTotal,
  setCurrent,
  setPageLoading,
} = stockfloorSlice.actions

export const selectStockfloorData = (state: RootState) =>
  state.stockfloor.StockfloorData

export const selectTotal = (state: RootState) => state.stockfloor.Total

export const selectCurrent = (state: RootState) => state.stockfloor.Current

export const selectPageLoading = (state: RootState) =>
  state.stockfloor.pageLoading

export default stockfloorSlice.reducer
