/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Form, Modal, Row, Timeline } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './Journal.module.css'
import React from 'react'
import { RootDispatch } from '../../app/store'
import { getStandDetail } from './journalSlice'
import moment from 'moment'
import { DateTimeFormat } from '../../models/datetime'
import { getTypeName, standDetailValue } from '../../models/standbook'

export const RejectDetailModal = (props: {
  isRejectModalVisible?: any
  onOk: () => void
  onCancel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [detail, setDetail] = useState<standDetailValue>({} as standDetailValue)

  const [cautionDetailList, setCautionDetailList] = useState<string[]>([])

  // const [fileList, setFileList] = useState<any>([])

  useEffect(() => {
    if (props.isRejectModalVisible.visible) {
      dispatch(getStandDetail(props.isRejectModalVisible.cautionId))
        .then(unwrapResult)
        .then((res) => {
          setDetail(res)
          setCautionDetailList(
            res.cautionDetail
              .replace(new RegExp('@_@', 'gm'), '：')
              .split('&_&')
          )
        })
    }
    return () => {
      setDetail({} as standDetailValue)
      setCautionDetailList([])
    }
  }, [props.isRejectModalVisible.visible])

  return (
    <Modal
      width={800}
      title='查看'
      okText='提交'
      footer={null}
      centered
      bodyStyle={{
        maxHeight: '700px',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
      onOk={() => {
        props.onOk()
      }}
      onCancel={() => {
        props.onCancel()
      }}
      visible={props.isRejectModalVisible.visible}
      className={styles.detail}
    >
      <Form form={form} name='basic' autoComplete='off'>
        <Row gutter={24}>
          <Col span={24}>
            <Timeline mode='left' style={{ marginLeft: '-200px' }}>
              {detail.cautionProcessModelVOS &&
                detail.cautionProcessModelVOS.map((item, index) => (
                  <Timeline.Item
                    key={index}
                    label={
                      item.timeNode
                        ? moment(item.timeNode).format(DateTimeFormat)
                        : undefined
                    }
                    color='green'
                  >
                    <p className={styles.title}>{getTypeName(item.type)}</p>
                    {item.type === 4 ? (
                      <Form.Item label='完成原因'>
                        <p className={styles.itemContent}>{item.reason}</p>
                      </Form.Item>
                    ) : item.type === 3 ? (
                      <Form.Item label='驳回理由'>
                        <p className={styles.itemContent}>{item.reason}</p>
                      </Form.Item>
                    ) : item.type === 2 ? (
                      <>
                        <Form.Item label='申诉理由'>
                          <p className={styles.itemContent}>{item.reason}</p>
                        </Form.Item>
                        {item.attachment.length !== 0 ? (
                          <p style={{ marginBottom: '10px' }}>附件查看</p>
                        ) : (
                          <></>
                        )}
                        {item.attachment.length !== 0 &&
                          item.attachment.map((v: string, i: number) => {
                            const fileInfo = v ? v.split('@') : []
                            return (
                              <Row
                                gutter={24}
                                key={i}
                                style={{
                                  // padding: "0 10px",
                                  marginBottom: '5px',
                                  width: '240px',
                                }}
                              >
                                <Col flex={1}>
                                  <span
                                    style={{
                                      display: 'inline-block',
                                      maxWidth: '60px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    {fileInfo[0].split('.')[0]}
                                  </span>
                                  <span
                                    style={{
                                      maxWidth: '40px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    .
                                    {
                                      fileInfo[0].split('.')[
                                        fileInfo.length - 1
                                      ]
                                    }
                                  </span>
                                </Col>
                                <Col span={8}>
                                  <a
                                    onClick={() => {
                                      window.open(fileInfo[1], '_blank')
                                    }}
                                  >
                                    查看
                                  </a>
                                </Col>
                              </Row>
                            )
                          })}
                        <p>申诉人：{item.person}</p>
                      </>
                    ) : (
                      <></>
                    )}
                  </Timeline.Item>
                ))}
              <Timeline.Item
                label={
                  detail.cautionTime
                    ? moment(detail.cautionTime).format(DateTimeFormat)
                    : undefined
                }
                color='green'
              >
                <p className={styles.title}>警报发现</p>
                <Row className={styles.detailMain}>
                  <Form.Item label='警报详情'>
                    <p style={{ margin: 0, marginTop: 5 }}>
                      {cautionDetailList.map((v: string, i: number) => {
                        if (v.substring(0, 1) === '0') {
                          return (
                            <span
                              style={{ marginRight: 5, whiteSpace: 'nowrap' }}
                              key={i}
                            >
                              {v.substring(1)}
                            </span>
                          )
                        } else {
                          return (
                            <p key={i} style={{ marginBottom: 0 }}>
                              {v.substring(1)}
                            </p>
                          )
                        }
                      })}
                    </p>
                  </Form.Item>
                </Row>
              </Timeline.Item>
            </Timeline>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
