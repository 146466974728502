import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { NavBar } from "../../compnents/nav/NavBar";
import { CategoryName, getflagCategory } from "../../models/category";
import { DateTimeFormat } from "../../models/datetime";
import { Department } from "../../models/department";
import { User } from "../../models/user";
import { getStorage, selectStorage } from "../durgsystem/durgsystemSlice";
import {
  DepartmentDate,
  savePutstorage,
  selectDepartData,
  selectId,
} from "../putstorage/putstorageSlice";
import {
  getUserName,
  selectstoreId,
  setSelectId,
  sleectUserData,
} from "../putstorelist/putstorelistSlice";
import { SupplierModal } from "./modal";
import { SupplierModal as SupplierSaveModal } from "../wms/modal";
import styles from "./Savebusiness.module.css";
import { setCurrent } from "../wms/supplierSlice";
import {
  selectUserDepartmentId,
  selectUserId,
} from "../../app/applicationSlice";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
export const Savebusiness = () => {
  const history = useHistory();

  const location: any = useLocation();

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const Id = useSelector(selectstoreId);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [ismodalVisible, setIsmodalVisible] = useState(false);

  const durgsystemData = useSelector(selectStorage);

  const departmentData = useSelector(selectDepartData);

  const userData = useSelector(sleectUserData);

  const userId = useSelector(selectUserId);

  const departmentId = useSelector(selectUserDepartmentId);

  useEffect(() => {
    dispatch(
      getStorage({ current: 1, flag: getflagCategory(location.state.category) })
    );
    dispatch(DepartmentDate({ current: 1, size: 1000 }));

    form.setFieldsValue({
      recepitsDate: moment(),
    });
    if (userId || departmentId) {
      dispatch(getUserName(departmentId));
      form.setFieldsValue({
        departmentId: Number(departmentId),
        userId,
      });
    }
  }, []);

  useEffect(() => {
    if (Id) {
      history.push({
        pathname: "/putlist",
        state: { category: location.state.category, selectId: Id },
      });
      dispatch(setSelectId(0));
    }
  }, [Id]);

  return (
    <div style={{ height: "100%" }}>
      <Col
        style={{
          margin: "10px 20px",
        }}
      >
        <NavBar
          where={["库房业务", CategoryName(location.state.category) as string]}
          backtrace={{
            name: "入库列表",
            path: "/putstorage",
            state: { category: location.state.category },
            routeName: "库房工作站",
          }}
          buttons={<></>}
        />
      </Col>
      <div className={styles.content}>
        <div className={styles.title}>
          <p className={styles.titletext}>
            新增{CategoryName(location.state.category)}
          </p>
        </div>
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          autoComplete="off"
          onFinish={() => {
            form.validateFields().then((values) => {
              const storehouseName =
                (durgsystemData.find(
                  (d: any) => d.storehouseId === values.storehouseId
                ) as any)?.storehouseName || "";
              const departmentName =
                (departmentData.find(
                  (d: any) => d.id === values.departmentId
                ) as any)?.name || "";
              const userName =
                (userData.find((u: any) => u.id === values.userId) as any)
                  ?.name || "";
              const date = moment(values.recepitsDate);
              dispatch(
                savePutstorage({
                  ...values,
                  storehouseName,
                  departmentName,
                  userName,
                  category: location.state.category,
                  recepitsDate: date.format(DateTimeFormat),
                })
              );
            });
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name="storehouseId"
                label="库房名称"
                rules={[
                  {
                    required: true,
                    message: "库房名称不能为空,请输入正确的值。",
                  },
                ]}
              >
                <Select placeholder="药房" allowClear>
                  {durgsystemData.map((v: any, i) => {
                    return (
                      <Option value={v.storehouseId} key={i}>
                        {v.storehouseName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="departmentId"
                label="业务部门"
                rules={[
                  {
                    required: true,
                    message: "业务部门不能为空,请输入正确的值。",
                  },
                ]}
              >
                <Select
                  placeholder="业务部门"
                  allowClear
                  onSelect={(key: any) => {
                    form.setFieldsValue({
                      userId: undefined,
                    });
                    dispatch(getUserName(key));
                  }}
                >
                  {departmentData.map((v: Department) => {
                    return (
                      <Option value={v.id} key={v.id}>
                        {v.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="userId"
                label="业务人员"
                rules={[
                  {
                    required: true,
                    message: "业务人员不能为空,请输入正确的值。",
                  },
                ]}
              >
                <Select showSearch placeholder="业务人员" allowClear>
                  {userData.map((v: User) => {
                    return (
                      <Option value={v.id} key={v.id}>
                        {v.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Form.Item style={{ display: "none" }} name="supplierId">
              <Input type="hidden" style={{ display: "none" }} />
            </Form.Item>
            <Col span={12}>
              <Form.Item
                name="supplierName"
                label="供应商"
                rules={[
                  {
                    required: true,
                    message: "供应商不能为空,请输入正确的值。",
                  },
                ]}
              >
                <Input
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                />
              </Form.Item>
            </Col>
            {location.state.category !== "2" ? (
              <Col span={12}>
                <Form.Item
                  name="recepitsNumber"
                  label="采购单号"
                  rules={[{ max: 256, message: "最大256位!" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}

            <Col span={12}>
              <Form.Item
                name="recepitsDate"
                label="业务日期"
                rules={[
                  {
                    required: true,
                    message: "业务日期不能为空,请输入正确的值。",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="remark"
                label="单据备注"
                rules={[{ max: 256, message: "最大256位!" }]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  float: "right",
                }}
              >
                保存
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType="button"
                onClick={() => {
                  history.go(-1);
                }}
                style={{
                  float: "left",
                }}
              >
                取消
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <SupplierModal
        supplierCategory={0}
        visible={isModalVisible}
        switchModal={() => {
          setIsModalVisible(false);
          setIsmodalVisible(true);
        }}
        onOk={() => {
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        onRecord={(record) => {
          form.setFieldsValue({
            supplierId: record.id,
            supplierName: record.companyName,
          });
        }}
      />
      <SupplierSaveModal
        visible={ismodalVisible}
        onOk={() => {
          setIsmodalVisible(false);
          dispatch(setCurrent(1));
          setIsModalVisible(true);
          dispatch(setSelectId(0));
        }}
        onCancel={() => {
          setIsmodalVisible(false);
          setIsModalVisible(true);
          dispatch(setSelectId(0));
        }}
      />
    </div>
  );
};
