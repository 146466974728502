/* eslint-disable react/display-name */
import { TableColumnType, Typography } from 'antd'
import React from 'react'
import {
  getChrgitmLvName,
  getHilistLmtpricType,
  getIsCouldBxName,
} from '../../../models/commodity'
const { Text } = Typography

export const InsuranceOperationColumn = (
  onClick: (text: string, id: any, item?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      fixed: 'left',
      render: (_1, _2, index) => <span>{startIndex + index + 1}</span>,
    },{
      title: '手术操作代码',
      // width: '6rem',
      dataIndex: 'oprnOprtCode',
      align: 'center',
      ellipsis: true,
    },{
      title: '手术操作名称',
      // width: '6rem',
      dataIndex: 'oprnOprtName',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '类目代码',
      // width: '6rem',
      dataIndex: 'cgyCode',
      align: 'center',
      ellipsis: true,
    },{
      title: '类目名称',
      // width: '6rem',
      dataIndex: 'cgyName',
      align: 'center',
      ellipsis: true,
    },{
      title: '临床版手术操作代码',
      // width: '6rem',
      dataIndex: 'clncOprnOprtCode',
      align: 'center',
      ellipsis: true,
    },{
      title: '临床版手术操作名称',
      // width: '6rem',
      dataIndex: 'clncOprnOprtName',
      align: 'center',
      ellipsis: true,
    },{
      title: '章',
      // width: '6rem',
      dataIndex: 'cpr',
      align: 'center',
      ellipsis: true,
    },{
      title: '章代码范围',
      // width: '6rem',
      dataIndex: 'cprCodeScp',
      align: 'center',
      ellipsis: true,
    },{
      title: '章名称',
      // width: '6rem',
      dataIndex: 'cprName',
      align: 'center',
      ellipsis: true,
    },{
      title: '细目代码',
      // width: '6rem',
      dataIndex: 'dtlsCode',
      align: 'center',
      ellipsis: true,
    },{
      title: '细目名称',
      // width: '6rem',
      dataIndex: 'dtlsName',
      align: 'center',
      ellipsis: true,
    },{
      title: '所属地区',
      // width: '6rem',
      dataIndex: 'insuranceCode',
      align: 'center',
      ellipsis: true,
    },{
      title: '创建时间',
      // width: '6rem',
      dataIndex: 'crteTime',
      align: 'center',
      ellipsis: true,
    }
  ]
}
