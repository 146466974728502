import { Col, notification, Row, Table } from 'antd'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BillingCategoryName } from '../../../models/billingCategory'
import { revenueReportParams } from '../../../services/revenueReport'
import styles from './revent.module.css'
import { RevenueReportQuery } from './Query'
import {
  getRevenueReportExoprt,
  getRevenueReportList,
  selectPageLoading,
  selectReportList,
  setPageLoading,
} from './revenueReportSlice'
import _ from 'lodash'
import { RootDispatch } from '../../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'
import { EditableList } from '../../../compnents/list/EditableList'
import moment from 'moment'
import { headerColumns, priceColumns } from './Columns'
import { TabBar, TabPane } from '../../../compnents/widgets/TabBar'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnly,
} from '../../../models/datetime'
import {selectInsuranceArray, selectTenantName} from '../../../app/applicationSlice'
import { printBody } from '../../../models/RevenueReportPrint'
export const RevenueReport = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const printRef = useRef<PrintFrameRef>(null)

  const [queries, setQueries] = useState<revenueReportParams | null>(null)

  const list: any = useSelector(selectReportList)

  const tenantName = useSelector(selectTenantName)

  const [local, setlocal] = useState<any>()

  const pageLoadingRX = useSelector(selectPageLoading)

  const insuranceArray = useSelector(selectInsuranceArray)


  useEffect(() => {
    if (queries?.timeHead) {
      const endTime = moment(queries?.timeHead).add(6, 'month').endOf('month')
      if (moment(queries?.timeTail) > endTime) {
        notification.info({
          message: '一次最多只能查询6个月的数据',
        })
      } else {
        dispatch(setPageLoading(true));
        dispatch(getRevenueReportList({ ...queries }));
        setTimeout(() => {
          dispatch(setPageLoading(false));
        }, 3000);
      }
    }
  }, [queries])

  const outClued = () => {
    dispatch(getRevenueReportExoprt({ ...queries }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '营收报表.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const titleList = list?.[0]?.incomeStatisticsSummary?.[0]?.customizePay
    ?.map((v: any) => {
      return { payMethodName: v?.payMethodName, payMethod: v?.payMethod }
    })

  const dataMainList = {
    tenantName: tenantName?.split('-')?.[0],
    data: list,
    local: {
      name: local?.name,
      time: moment().locale('zh-cn').format('YYYY/MM/DD HH:mm:ss'),
    },
    timeHead: moment(queries?.timeHead).format(DateTimeFormatSimpleOnly),
    timeTail: moment(queries?.timeTail).format(DateTimeFormatSimpleOnly),
    titleList: titleList,
  }

  const content = printBody(dataMainList)
  const print = () => {
    const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
    page.document.write(content) // 写入打印页面的内容
    page.print() // 打印
    page.close();
  }

  useEffect(() => {
    setlocal(JSON.parse(localStorage.getItem('credentials') as string))
  }, [])

  return (
    <>
      <TabBar style={{ margin: '10px 20px' }}>
        <TabPane key='0' tab='营收报表'>
          <div className={styles.main}>
            <Row className={styles.head}>
              <p>营收报表</p>
            </Row>
            <div className={styles.main_query}>
              <RevenueReportQuery
                onValueChange={(v) => {
                  setQueries({ ...queries, ...v })
                }}
                outClued={outClued}
                print={print}
              />
            </div>

            {list.map((v: any) => (
              <div key={v.id}>
                <Table
                  bordered
                  className={styles.reventReport}
                  loading={pageLoadingRX}
                  pagination={false}
                  dataSource={v.incomeStatisticsSummary}
                  columns={headerColumns(v.incomeStatisticsSummary,insuranceArray)}
                />

                <Table
                  bordered
                  className={styles.reventReport}
                  loading={pageLoadingRX}
                  pagination={false}
                  dataSource={_.chain(v.billingCategoryStatisticsSummary)
                    .chunk(2)
                    .value()}
                  columns={priceColumns()}
                />
              </div>
            ))}
          </div>
        </TabPane>
      </TabBar>
      <PrintFrame ref={printRef} title='营收报表' subTitle=''>
        <Row style={{ marginBottom: '10px', lineHeight: '32px' }}>
          <Col span={12}>
            报表时间：{moment(queries?.timeHead).format(DateTimeFormat)} ~
            {moment(queries?.timeTail).format(DateTimeFormat)}
          </Col>
          <Col
            span={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            机构名称：{tenantName?.split('-')?.[0]}
          </Col>
        </Row>
        {list.map((v: any) => (
          <div key={v.id}>
            <EditableList
              className={styles.reventReports}
              style={{ height: 'auto' }}
              columns={headerColumns(v.incomeStatisticsSummary,insuranceArray)}
              pagination={false}
              dataSource={v.incomeStatisticsSummary}
            />
            <EditableList
              className={styles.reventReports}
              style={{ height: 'auto' }}
              columns={priceColumns()}
              pagination={false}
              dataSource={_.chain(v.billingCategoryStatisticsSummary)
                .chunk(2)
                .value()}
            />
          </div>
        ))}
        <Row style={{ lineHeight: '32px', marginTop: '20px' }}>
          <Col span={8}>制单人：{local?.name}</Col>
          <Col span={8}>
            制单日期：{moment().locale('zh-cn').format('YYYY/MM/DD HH:mm:ss')}
          </Col>
        </Row>
      </PrintFrame>
    </>
  )
}
