import { Button, Col,  notification, Row, Select } from "antd";

import { useDispatch } from "react-redux";
import { RootDispatch } from "../../app/store";
import React, { useRef, useState } from "react";

import { UploadFile } from "antd/lib/upload/interface";
import { NavBar } from "../../compnents/nav/NavBar";
import { useHistory, useLocation } from "react-router-dom";
import SurgicalFrom from "../treatment/surgical/SurgicalFrom";
import { surgicalApply } from "../treatment/surgical/surgicalSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { surgicalBody } from "../../services/surgical";

export const ApplyForOperation = () => {
  const dispatch = useDispatch<RootDispatch>();

  const history = useHistory();

  const location: any = useLocation();

  const ref = useRef<any>(null);

  const tag = location?.state?.tag;

  return (
    <>
      <Col
        style={{
          height: "calc(100% - 90px)",
          overflow: "hidden",
          position: "relative",
          marginTop: 10,
          marginBottom: 10,
          padding: "0px 20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavBar
          style={{ marginBottom: "10px" }}
          where={[
            tag == 1 ? "待完成手术" : tag == 2 ? "已结束手术" : "",
            "新增手术申请",
          ]}
          backtrace={{
            name: tag == 1 ? "待完成手术" : tag == 2 ? "已结束手术" : "",
            path: "/operationStation",
            routeName: "手术",
            state: {
              selectKey: tag == 1 ? "0" : tag == 2 ? "3" : "",
            },
          }}
        />
        <Col
          style={{
            fontSize: "14px",
            flex: 1,
            borderRadius: 10,
            padding: "10px 15px 0 15px",
            display: "flex",
            flexDirection: "column",
            background: "#fff",
          }}
        >
          <SurgicalFrom
            tag="operation"
            ref={ref}
            subForm={(v: surgicalBody) => {
              dispatch(surgicalApply(v))
                .then(unwrapResult)
                .then(async () => {
                  await notification.success({
                    message: "手术申请提交成功",
                  });
                  if (tag === 1) {
                    history.push({
                      pathname: "/operationStation",
                      state: {
                        tag: 1,
                      },
                    });
                  } else if (tag === 2) {
                    history.push({
                      pathname: "/operationStation",
                      state: {
                        tag: 2
                      },
                    });
                  }
                });
            }}
          />
        </Col>
      </Col>
      <Row
        style={{
          width: "100%",
          background: "#fff",
          height: "60px",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Col span={22}>
          <Button
            htmlType="button"
            onClick={() => {
              history.go(-1);
            }}
            style={{
              float: "right",
              marginRight: "10px",
            }}
          >
            取消
          </Button>
        </Col>
        <Col span={2}>
          <Button
            type="primary"
            onClick={() => {
              ref.current?.subSurgical();
            }}
            style={{
              float: "left",
            }}
          >
            保存
          </Button>
        </Col>
      </Row>
    </>
  );
};
