/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-04 11:12:46
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-23 16:04:59
 */
import { Col } from 'antd'
import React, { ReactElement, useState } from 'react'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { ReplaceMakeDetail } from './detail/ReplaceMakeDetail'
import { ReplaceMakeOrder } from './order/ReplaceMakeOrder'
import { ReplaceMakePrice } from './price/Price'
import { ReplaceMakeRecipeDetail } from './recipeDetail/RecipeDetail'
import { ReplaceMakeAStatistics } from './statistics/statistics'

export const OReplaceNavBar = (): ReactElement => {
  const [tabSelect, setTabSelect] = useState<any>('0')
  return (
    <Col
      style={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      <TabBar
        style={{ margin: '10px 20px' }}
        onChange={(v) => {
          setTabSelect(v)
        }}
      >
        <TabPane key='0' tab='代煎订单'>
          <ReplaceMakeOrder />
        </TabPane>
        <TabPane key='1' tab='代煎目录价格表'>
          <ReplaceMakePrice />
        </TabPane>
        <TabPane key='2' tab='代煎明细查询' style={{ height: '100%' }}>
          <ReplaceMakeDetail />
        </TabPane>
        <TabPane key='4' tab='代煎处方明细' style={{ height: '100%' }}>
          <ReplaceMakeRecipeDetail />
        </TabPane>
        <TabPane key='3' tab='代煎统计' style={{ height: '100%' }}>
          <ReplaceMakeAStatistics tabSelect={tabSelect} />
        </TabPane>
      </TabBar>
    </Col>
  )
}
