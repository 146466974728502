/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-16 18:01:59
 */
import React from "react"
import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {Button, notification, Popconfirm, Row, Space} from "antd"
import {unwrapResult} from "@reduxjs/toolkit"
import {RootDispatch} from "../../app/store"
import {AddLiveModal} from "./AddLiveModal"
import styles from "../journal/Journal.module.css";
import {JournalQuery} from "../journal/JournalQuery";
import {EditableList} from "../../compnents/list/EditableList";
import {JournalColumns} from "../journal/JournalColumns";
import {getStandStateName, standListParams} from "../../models/standbook";
import {LiveColumns} from "./LiveColumns";
import {getreplaceMakeDetail, getreplaceMakeDetailAmount} from "../OReplaceMake/OReplaceMakeSlice";
import {checkAnchor, searchLives, startLive} from "./liveSlice";
import {StartLiveModal} from "./StartLiveModal";
// import Icon, { AppealReady } from "../../compnents/icons/Icons"

export const Live = () => {
    const dispatch = useDispatch<RootDispatch>()

    const [pageLoading, setPageLoading] = useState(false) //表格 loading
    const [data, setData] = useState<any>([])
    const [params, setParams] = useState<any>({
        current: 1,
        size: 10,
    })
    const [total, setTotal] = useState(0)
    const [isAddLiveVisible, setIsAddLiveVisible] = useState(false) //创建直播
    const [isStartLiveVisible, setIsStartLiveVisible] = useState(false) //开播
    const [anchorBtn, setAnchorBtn] = useState(false) //是否主播权限
    const kaiboTop = (window.screen.availHeight-30-250)/2;
    const kaiboLeft = (window.screen.availWidth-10-500)/2;
    const openLiveTop = (window.screen.availHeight-30-720)/2;
    const openLiveLeft = (window.screen.availWidth-10-1260)/2;
    const kaiboFeatures:string = "width=500,height=250,top="+kaiboTop+",left="+kaiboLeft;
    const openLiveFeatures:string = "width=1260,height=720,top="+openLiveTop+",left="+openLiveLeft;
    // 查询主播权限
    const check = () => {
        dispatch(
            checkAnchor()
        )
            .then(unwrapResult)
            .then((res: any) => {
                setAnchorBtn(res==1?true:false);
            })
    }

    // 查询
    const search = () => {
        setPageLoading(true)
        dispatch(
            searchLives({...params})
        )
            .then(unwrapResult)
            .then((res: any) => {
                setTotal(res.total)
                setData(res.records)
            })
            .finally(() => {
                setPageLoading(false)
            })
    }
    useEffect(() => {
        search();
    }, [params])
    useEffect(() => {
        check();
    }, [])


    return (
        <div style={{padding: 10, height: "100%"}}>

            <div className={styles.content}>

                <Space style={{display: "flex", justifyContent: "flex-end", marginBottom: "10px"}}>
                    <Button type='primary' onClick={() => search()}>
                        查询
                    </Button>
                    {anchorBtn&&
                        <Button
                            type='primary'
                            onClick={() => {
                                setIsAddLiveVisible(true);
                            }}>
                            新建直播
                        </Button>}
                </Space>

                <EditableList
                    // rowKey={(record: any) => (record as any).cautionId}
                    className={styles.suppliertable}
                    loading={pageLoading}
                    page={{
                        items: data,
                        current: params.current || 1,
                        size: params.size || 10,
                        total,
                    }}
                    onChangePage={(current, pageSize) => {
                        setParams({
                            ...params,
                            current: current,
                            size: pageSize || 10,
                        })
                    }}
                    columns={LiveColumns((action, id,liveUrl,liveType) => {

                        if(action === "startLive"){
                            dispatch(
                                startLive({liveId:id})
                            )
                                .then(unwrapResult)
                                .then((res: any) => {
                                    window.open(res,"_blank", kaiboFeatures);
                                    notification.success({
                                        message: '开播成功，请在钉钉直播窗口内确认',
                                    })
                                })
                                .finally(() => {
                                    setPageLoading(false)
                                })
                        }else if(action === "openLive"){
                            if(liveType === "kaibo"){
                                window.open(liveUrl,"_blank", kaiboFeatures);
                            }else if(liveType === "open"){
                                window.open(liveUrl,"_blank", openLiveFeatures);

                            }
                        }


                    }, (params.size || 10) * ((params.current || 1) - 1))}
                />
            </div>

            <AddLiveModal
                modalVisible={isAddLiveVisible}
                onOk={() => {
                    notification.success({
                        message: '保存成功'
                    })
                    search();
                    setIsAddLiveVisible(false);

                }}
                onCancel={() => {
                    setIsAddLiveVisible(false);
                }}
            />
            <StartLiveModal
                modalVisible={isStartLiveVisible}
                onOk={() => {
                    notification.success({
                        message: '已开播'
                    })
                    setIsStartLiveVisible(false);
                }}
                onCancel={() => {
                    setIsStartLiveVisible(false);
                }}
            />
        </div>

    )
}
