import { Menu, toJson } from "../models/menu";
import { ADMIN_API_PREFIX, API_PREFIX } from "./constant";
import { Request } from "./request";

export function getAvailableMenuList(tenantId?: string): Request {
  return {
    url: tenantId
      ? `${ADMIN_API_PREFIX}/tenantmenu/list`
      : `${ADMIN_API_PREFIX}/menu/list`,
    method: "GET",
    params: {
      tenantId,
      current: 1,
      size: 1000,
    },
  };
}

export function getGrantedMenuList(tenantId: string, userId?: string): Request {
  return {
    url: userId
      ? `${ADMIN_API_PREFIX}/tenant/usermenu/list`
      : `${ADMIN_API_PREFIX}/tenantmenu/list`,
    method: "GET",
    params: {
      tenantId,
      userId,
      current: 1,
      size: 1000,
    },
  };
}

export function getTenantAvailableMenuList(): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantmenu/list`,
    method: "GET",
    params: {
      current: 1,
      size: 1000,
    },
  };
}

export function getUserGrantedMenuList(userId?: string): Request {
  return {
    url: `${API_PREFIX}/blade-user/usermenu/usermenu/list`,
    method: "GET",
    params: {
      userId,
      current: 1,
      size: 1000,
    },
  };
}

export function grantMenus(
  tenantId: string,
  userId: string | undefined,
  menuIds: string[]
): Request {
  return {
    url: userId
      ? `${ADMIN_API_PREFIX}/tenant/user/menu/add`
      : `${ADMIN_API_PREFIX}/tenantmenu/add`,
    method: "POST",
    body: {
      tenantId,
      userId,
      menuIds,
    },
  };
}

export function grantUserMenus(userId: string, menuIds: string[]): Request {
  return {
    url: `${API_PREFIX}/blade-user/usermenu/add`,
    method: "POST",
    body: {
      userId,
      menuIds,
    },
  };
}

export function createMenu(menu: Menu): Request {
  return {
    url: `${ADMIN_API_PREFIX}/menu/add`,
    method: "POST",
    body: toJson(menu),
  };
}


export function updateMenu(menu: Menu): Request {
  return {
    url: `${ADMIN_API_PREFIX}/menu/update`,
    method: "POST",
    body: toJson(menu),
  };
}

export function removeMenu(menu: Menu): Request {
  return {
    url: `${ADMIN_API_PREFIX}/menu/remove`,
    method: "POST",
    params: {
      ids: menu.id,
    },
  };
}
