import { SearchOutlined } from "@ant-design/icons"
import { unwrapResult } from "@reduxjs/toolkit"
import { Button, Col, Form, Input, Pagination, Row, Space } from "antd"
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  selectUserDepartmentId,
  selectUserId,
} from "../../../app/applicationSlice"
import { RootDispatch } from "../../../app/store"
import { DateSelect } from "../../../compnents/form/DateSelect"
import { RecipeCategory } from "../../../models/recipe"
import { GetTreatmentListParams } from "../../../services/treatment"
import { getTreatmentList, getTreatmentOldList, reset } from "../list/treatmentSlice"
import { HistoryTreatmentBox } from "./historyTreatmentBox"

export interface HistoryTreatmentsRef {
  reset: () => void
}
interface TreatmentsProps {
  recipeCategory?: RecipeCategory
  exact?: boolean
  patientId?: string
  showDiagnosis?: boolean
  onConnect?: (v: any) => void
  style?: React.CSSProperties
}

const RealTreatments = (
  {
    recipeCategory,
    exact = true,
    patientId,
    onConnect,
    showDiagnosis = true,
    style = {},
  }: TreatmentsProps,
  ref: React.Ref<HistoryTreatmentsRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const userId = useSelector(selectUserId)

  const departmentId = useSelector(selectUserDepartmentId)

  const [size, setSize] = useState(10)

  const [current, setCurrent] = useState(1)

  const [page, setPage] = useState<any>()

  const [params, setParams] = useState<GetTreatmentListParams>({
    current: 1,
    // treatmentDoctorId: userId,
    patientId,
  })

  useEffect(() => {
    dispatch(getTreatmentOldList({ ...params, current, size, flag: true }))
      .then(unwrapResult)
      .then((res: any) => {
        setPage(res)
      })
  }, [params, current, size])

  useImperativeHandle(ref, () => ({
    reset: () => {
      dispatch(reset())
      setParams({ current: 1, patientId })
      form.resetFields()
    },
  }))

  return (
    <Col
      style={{
        ...style,
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
      }}
    >
      <Form
        form={form}
        autoComplete='off'
        initialValues={{
          id: `u${userId}`,
          scope: 0,
        }}
        onFinish={(values) => {
          const vs = {
            ...params,
            ...values,
            id: undefined,
            scope: undefined,
            treatmentDepartmentId: undefined,
            patientId: values.scope === 0 ? patientId : undefined,
            current: 1,
          }
          setParams(vs)
        }}
      >
        <Row style={{ marginBottom: 20 }} justify='space-between'>
          <Space>
            <Form.Item name='param' noStyle>
              <Input
                style={{ width: "14rem" }}
                placeholder='请输入患者姓名/手机号/助记码'
                prefix={<SearchOutlined />}
                onChange={form.submit}
              />
            </Form.Item>
            <DateSelect
              namePrefix='treatmentTime'
              labelPrefix=''
              placeholder='时间范围'
              style={{ width: "8.5rem" }}
              onChange={form.submit}
            />
          </Space>
          <Button type='primary' htmlType='submit'>
            查询
          </Button>
        </Row>
      </Form>
      <Col style={{ flex: 1, overflow: "auto" }}>
        <HistoryTreatmentBox
          page={page?.items}
          exact={exact}
          recipeCategory={recipeCategory}
          showDiagnosis={showDiagnosis}
          onConnect={onConnect}
        />
      </Col>
      <Pagination
        style={{ marginTop: 20 }}
        total={page?.total}
        current={current}
        pageSize={size}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `共 ${total} 条`}
        onChange={(current, size) => {
          setSize(size as number)
          setCurrent(current)
        }}
      />
    </Col>
  )
}

export const HistoryTreatment = forwardRef(RealTreatments)
