import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunkApi } from "../../app/store";
import { Page, pageOf, startPageOf } from "../../models/page";
import { fromJson, Tenant } from "../../models/tenant";
import * as ST from "../../services/tenant";
import { InvoiceSaveParams, TenantQueryParams } from '../../services/tenant'
interface TenantState {
  page: Page<Tenant>
}

const initialState: TenantState = {
  page: startPageOf(),
}

export const getTenantList = createAsyncThunk<
  Page<Tenant>,
  TenantQueryParams,
  RootThunkApi<Page<Tenant>>
>('tenant/getTenantListAsyncStatus', async (params, api) => {
  return sendAsync(ST.getTenantList(params), api).then(
    (data: Record<string, never>) =>
      pageOf(
        data,
        _.chain(data.records)
          .map((t) => fromJson(t))
          .value()
      )
  )
})

export const createOrUpdateTenant = createAsyncThunk<
  void,
  Tenant,
  RootThunkApi<void>
>('tenant/createOrUpdateTenantStatus', async (tenant, api) => {
  return sendAsync(ST.createOrUpdateTenant(tenant), api)
})

export const getTenantDetail = createAsyncThunk<
  Tenant,
  string,
  RootThunkApi<Tenant>
>('tenant/getTenantDetailStatus', async (tenantId, api) => {
  return sendAsync(ST.getTenantDetail(tenantId), api).then((data: unknown) =>
    fromJson(data)
  )
})

export const toggleTenantState = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('tenant/toggleTenantStateStatus', async (tenantId, api) => {
  return sendAsync(ST.toggleTenantState(tenantId), api)
})

// 省市区
export const getProvinceList = createAsyncThunk<
  void,
  ST.AllParams,
  RootThunkApi
>('ProvinceList/list', async (params, api) => {
  return sendAsync(ST.getProvince(params), api)
})

// 查询所有的省市区
export const getAllList = createAsyncThunk<any, void, RootThunkApi>(
  'ProvinceList/list',
  async (_, api) => {
    return sendAsync(ST.getAll(), api)
  }
)

// 导出
export const getExportExcelAsync = createAsyncThunk<
  void,
  ST.ExportExcelParams,
  RootThunkApi
>('/commodity/exportExcel', async (body, api) => {
  return sendAsync(ST.getExportExcel(body), api)
})

// 导入
export const getImportExcelAsync = createAsyncThunk<
  void,
  ST.ImportExcelParams,
  RootThunkApi
>('/commodity/importExcel', async (body, api) => {
  return sendAsync(ST.getImportExcel(body), api)
})

// 发票号回显
export const invoicenumberconfigAsync = createAsyncThunk<
  void,
  void,
  RootThunkApi
>('/commodity/invoicenumberconfigAsync', async (_, api) => {
  return sendAsync(ST.invoicenumberconfig(), api)
})

// 发票号保存
export const invoiceSaveAsync = createAsyncThunk<
  void,
  InvoiceSaveParams[],
  RootThunkApi
>('/commodity/invoicenumberconfigAsync', async (body, api) => {
  return sendAsync(ST.invoiceSave(body), api)
})

const tenantSlice = createSlice({
  name: "admin.tenant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTenantList.fulfilled, (state, action) => {
      state.page = action.payload;
    });
  },
});

export const selectTenantPage = (state: RootState): Page<Tenant> =>
  state.tenant.page;

export default tenantSlice.reducer;
