/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-08-29 17:21:08
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-21 14:18:06
 */
import {Form, Col, Input, Modal, Row, notification, Checkbox, Button} from 'antd'
import React, {
  ReactElement,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react'
import styles from './Payments.module.css'
import {unwrapResult} from "@reduxjs/toolkit";
import {saveTraceCodesAsync} from "./TraceCodeInputSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootDispatch} from "../../../app/store";
import {
  drugUploadAAsync,
  finishPutstorage,
  multifunctionalUpAccountAsync,
  rectPutstorageAsync
} from "../../putstorage/putstorageSlice";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {getInsuranceToSelfpayAsync} from "../../treatment/editor/recipeEditorSlice";
import {medicalSendHttp, strParse} from "../../../utils/MedicalUtils";
import {selectInsuranceItem, setInsurance} from "../../../layouts/insuranceSlice";
import { serverType } from '../../../services/commodity'
import {selectInsuranceArray} from "../../../app/applicationSlice";

interface TraceCodeInputModalProps {
  showCodeInputModal: boolean
  paymentResult: any
  onCancel: () => void
  onOk: () => void
  isShowUpload: boolean
}

export const TraceCodeInputModal = ({
  showCodeInputModal,
  paymentResult,
  onCancel,
  onOk,
  isShowUpload,
}: TraceCodeInputModalProps): ReactElement => {
  const [items, setItems] = useState([])
  const dispatch = useDispatch<RootDispatch>()

  const [buttonLoading, setButtonLoading] = useState(false)

  const baseData = useSelector(selectInsuranceItem)

  const inputRefs:any = useRef(items.map(() => []));
  const [form] = Form.useForm()

  useEffect(() => {
    if(items ){
      const jsons:any = {};
      items?.map((item: any, itemIndex: number) => {
        {[...Array(Math.ceil(item.drugCount))].map((_, colIndex) => {
          if(item?.traceCodeList?.[colIndex]){
            jsons["tradeCode_"+ item?.id + "_"+ (Number(colIndex) +1)] = item?.traceCodeList?.[colIndex]
          }
        })}
      })
      if(jsons){
        form.setFieldsValue({
          ...jsons
        })
      }
    }
  }, [items])

  const handlePressEnter = (itemIndex:any, colIndex:any) => {
    const nextColIndex = colIndex + 1;
    // 如果有下一个 Input，则聚焦
    if (inputRefs.current[itemIndex][nextColIndex]) {
      inputRefs.current[itemIndex][nextColIndex].focus();
    } else if (inputRefs.current[itemIndex + 1] && inputRefs.current[itemIndex + 1][0]) {
      // 否则如果有下一个 item，则聚焦该 item 的第一个 Input
      inputRefs.current[itemIndex + 1][0].focus();
    }
  };

  useEffect(() => {
    if (showCodeInputModal && paymentResult) {
      const recipeItems = paymentResult?.materials
        ?.map((v: any) => {
          return v?.itemList
        })
        ?.flat(1)
      if (recipeItems) {
        setItems(
          recipeItems?.filter((v: any) => {
            if (
              v?.state == 3 &&
              (v?.billingCategory == '01' || v?.billingCategory == '02'|| v?.billingCategory == '17')
            ) {
              return v
            }
          })
        )
      }
    } else {
      setItems([])
      inputRefs.current = items.map((item:any) => Array(item.drugCount).fill(null));
      form.resetFields();
    }
  }, [showCodeInputModal])

  const saveTraceCodes = (isUpload : boolean) => {
    const treatmentId = paymentResult.treatmentId;
    console.log(treatmentId,form.getFieldsValue());
    if(!form.getFieldsValue()){
      onCancel();
      return;
    }
    const submitParam:any = [];
    Object.entries(form.getFieldsValue()).forEach(([key, value]) => {
      const recipeItemId = key.split("_")[1];
      const oneRow = {
        treatmentId:treatmentId,
        recipeItemId:recipeItemId,
        traceCode:value
      };
      submitParam.push(oneRow);
    });

    dispatch(saveTraceCodesAsync({traceCodes:submitParam,treatmentId:treatmentId}))
      .then(unwrapResult)
      .then((v: any) => {
        if (isUpload) {
          drugUploadA('requestString',[paymentResult.id])
        } else {
          onOk();
          setButtonLoading(false)
        }
      })
  }

  // 发药单批量上传
  const drugUploadA = (
    serverType: serverType,
    paymentIds: number[] | string[],
    responseString?: string,
    recipeId?: number,
  ) => {
    dispatch(
      drugUploadAAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          paymentIds,
          shipmentState: 1,
          recipeId: recipeId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          let data = null
          try {
            data = JSON.parse(res)
          } catch (e) {
            data = strParse(res)
          }
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '发药单上传',
            },
            (call: any, err) => {
              !err &&
              drugUploadA(
                'response',
                paymentIds,
                JSON.stringify(call.netmessage),
                recipeId
              )
              if (err) {
                setButtonLoading(false)
              }
            }
          )
        } else {
          setButtonLoading(false)
          onOk()
        }
      })
      .catch(() => setButtonLoading(false))
  }

  return (
    <div>
      <Modal
        width={420}
        title='电子追溯码录入'
        destroyOnClose
        visible={showCodeInputModal}
        onCancel={() => {
          onCancel()
          form.resetFields();
          setButtonLoading(false)
        }}
        footer={
          <Row>
            <Col flex={1}>
              <Button  onClick={() => {
                onCancel()
                form.resetFields();
                setButtonLoading(false)
              }}>取消</Button>
              <Button  type={'primary'} loading={buttonLoading} onClick={() => {
                setButtonLoading(true)
                saveTraceCodes(false)
              }}>确认</Button>
              <Button type={'primary'} disabled={!isShowUpload} loading={buttonLoading} onClick={() => {
                setButtonLoading(true)
                Modal.confirm({
                  title: '',
                  content: (
                    <div>
                      <ExclamationCircleFilled style={{ color: '#FFAE55', fontSize: 18 }} />
                      &nbsp; 是否上传发药信息至医保？
                    </div>
                  ),
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    saveTraceCodes(true);
                  },
                  onCancel: () => {
                    setButtonLoading(false)
                  }
                })
              }}>确认并上传</Button>
            </Col>
          </Row>
        }
        className={styles.traceCodeModal}
      >
        <Row className={styles.tips}>
          <Col span={24}>根据医保局最新的规定与要求：</Col>
          <Col span={24}>请在下方输入框中扫入每一盒药品包装上的电子追溯码。</Col>
          <Col span={24}><span>包装</span>
            <span style={{fontWeight:"bold"}}>没有追溯码</span>
            <span>则不用扫入，系统会以</span>
            <span style={{fontWeight:"bold"}}>无码药品</span>
            <span>的方式上传。</span>
          </Col>
        </Row>

        {(!items?.length || items?.length == 0) && (
          <Row>
            <Col span={24} className={styles.errorMsg}>
              本次没有需要上传追溯码的药品。
            </Col>
          </Row>
        )}
        {items && items.length > 0 && (
          <>
            <Form
              form={form}
              autoComplete='off'
            >
              {items &&
                items?.map((item: any, itemIndex: number) => {
                  return (
                    <>
                      <Row key={itemIndex}>
                        <Col span={24} className={styles.traceNameCol}>
                          {item?.name} * {item?.drugCount}
                          {item?.splitTag == 1 ? item?.splitUnit : item?.accountUnit}
                        </Col>
                      </Row>
                      <Row>
                        {[...Array(Math.ceil(item.drugCount) )].map((_, colIndex) => (
                          <Col
                            key={colIndex}
                            span={24}
                            className={styles.traceCodeCol}
                          >
                            <Form.Item
                              noStyle
                              name={`tradeCode_` + item?.id + `_${colIndex + 1}`}

                            >
                              <Input
                                ref={(el) => {
                                  // 确保对应的数组存在
                                  if (!inputRefs.current[itemIndex]) {
                                    inputRefs.current[itemIndex] = [];
                                  }
                                  inputRefs.current[itemIndex][colIndex] = el;
                                }}
                                className={styles.traceCodeInput}
                                size='middle'
                                placeholder={
                                  item?.name + ` 追溯码` + `${colIndex + 1}`
                                }
                                allowClear
                                onPressEnter={() => handlePressEnter(itemIndex, colIndex)}
                                type={"number"}
                              />
                            </Form.Item>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )
                })}
            </Form>
          </>
        )}
      </Modal>
    </div>
  )
}
