/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-29 20:19:55
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-28 17:56:22
 */
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, notification, Row } from "antd";
import { remove } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getExportZYQDExcelAsync } from "../putstorelist/putstorelistSlice";
import { DebitOutQuery } from "../putstorelist/Query/debitOutQuery";
import { setQingdouFlag } from "./QingdouSlice";

export const QingdouQuery = (props: {
  detail: any;
  isModal: () => void;
  remove: () => void;
}) => {
  const location: any = useLocation()

  const dispatch = useDispatch()

  

  return (
    <>
      <DebitOutQuery
        category={location.state.category}
        Detail={props.detail}
       
      />
      <Row gutter={[10, 10]} style={{ marginBottom: '10px' }}>
        <Col span={3}>
          <Button
            type='primary'
            danger
            disabled={props.detail.storehouseTag !== 0 ? true : false}
            style={{ width: '100%' }}
            onClick={() => {
              props.remove()
            }}
          >
            删除选中行
          </Button>
        </Col>
        <Col span={3}>
          <Button
            type='primary'
            disabled={props.detail.storehouseTag !== 0 ? true : false}
            style={{ width: '100%' }}
            onClick={() => {
              dispatch(setQingdouFlag(1))
              props.isModal()
            }}
          >
            添加清斗药品
          </Button>
        </Col>
        <Col span={3}>
          <Button
            type='primary'
            disabled={props.detail.storehouseTag !== 0 ? true : false}
            style={{ width: '100%' }}
            onClick={() => {
              dispatch(setQingdouFlag(2))
              props.isModal()
            }}
          >
            添加装斗药品
          </Button>
        </Col>
      </Row>
    </>
  )
};
