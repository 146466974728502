/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-23 21:33:40
 */
import {Popconfirm, Space, TableColumnType} from "antd";
import React from "react";
import { getMaterialsCategoryName } from "../../models/commodity";
import { Dimen } from "../../models/dimen";
import { getSupplierCategory } from "../../models/supplierCategory";

export const Columns = (
  onclick: (id: number,type: string) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "供应商名称",
      align: "center",
      dataIndex: "companyName",
      width: Dimen.SupplierName,
      sorter: (a, b) => {
        return a.companyName.length - b.companyName.length;
      },
    },
    {
      title: "企业编号",
      align: "center",
      dataIndex: "companyCode",
      width: Dimen.Code,
    },
    {
      title: "许可证类型",
      align: "center",
      dataIndex: "licenceCategory",
      width: Dimen.Category,
      render: (licenceCategory) => {
        return getSupplierCategory(licenceCategory);
      },
    },
    {
      title: "供应商类型",
      align: "center",
      dataIndex: "supplierCategory",
      width: Dimen.Category,
      render: (_, t) => {
        return t.supplierCategory ? "生产厂商" : "供应商";
      },
    },
    {
      title: "许可证号",
      align: "center",
      dataIndex: "licenceNumber",
      width: Dimen.Coding,
    },
    {
      title: "地址",
      align: "center",
      dataIndex: "address",

    },
    {
      title: "联系电话",
      align: "center",
      dataIndex: "phone",
      width: Dimen.TelPhone,
    },
    {
      title: "供货物资类别",
      align: "center",
      dataIndex: "supplierMaterialsCategory",
      width: Dimen.Category,
      render: (_, t) => {
        try {
          const arr = JSON.parse(t.supplierMaterialsCategory as string);
          return Array.isArray(arr)
            ? arr.map((v) => getMaterialsCategoryName(Number(v))).join("，")
            : getMaterialsCategoryName(Number(arr));
        } catch {
          return "";
        }
      },
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "",
      width: "6rem",
      key: "x",
      render: function edit(_, t) {
        return (
            <div>
              <a
                onClick={(e) => {
                  onclick(t.id,"edit");
                }}
              >
                编辑
              </a>
              {(t.tenantId && t.tenantId>0) ?
                  <Space>
                    {<Popconfirm
                        title="确认进行此操作？"
                        onConfirm={() =>onclick(t.id,"delete")}
                        okText="确认"
                        cancelText="取消"
                    >
                      <a style={{marginLeft:"10px"}}
                      >
                        删除
                      </a>
                    </Popconfirm>}
                  </Space>: ''


              }
            </div>
        );
      },
    },
  ];
};

export const SupplierColumns = (): TableColumnType<any>[] => {
  return [
    {
      dataIndex: "storehouseName",
    },
  ];
};
