/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-31 14:23:29
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 11:50:49
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, sendAsync } from "../../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../../app/store";
import { getStockMaintenance, getStockMaintenanceDetail, maintenanceCompleted, oneKeyStockMaintenanceList, OneKeyStockMaintenanceParams, oneKeyStockMaintenanceSubmit, OneKeyStockMaintenanceSubmitParams, removeStockMintenance, StockMaintenanceBody, StockMaintenanceParams, submitStockMaintenance } from "../../../../services/StockMaintenance";
interface StockMaintenance {
  stockMaintenanceData: []
  stockMaintenanceTotal: 0
  stockMaintenanceCurrent: 1
  stockMaintenanceId: 0
  stockMaintenanceDetail: any
  pageLoading: false
}

const initialState: StockMaintenance = {
  stockMaintenanceData: [],
  stockMaintenanceTotal: 0,
  stockMaintenanceCurrent: 1,
  stockMaintenanceId: 0,
  stockMaintenanceDetail: {},
  pageLoading: false,
}

export const submitStockMaintenanceAsync = createAsyncThunk<
  void,
  StockMaintenanceBody,
  RootThunkApi<void>
>('maintenance/submit', async (body, api) => {
  return sendAsync(submitStockMaintenance(body), api)
})

// 一键养护列表
export const getOneKeyStockMaintenanceList = createAsyncThunk<
  void,
  OneKeyStockMaintenanceParams,
  RootThunkApi<void>
>('maintenance/getOneKeyStockMaintenanceList', async (params, api) => {
  return sendAsync(oneKeyStockMaintenanceList(params), api)
})


// 一键养护保存
export const getOneKeyStockMaintenanceSubmit = createAsyncThunk<
  void,
  OneKeyStockMaintenanceSubmitParams,
  RootThunkApi<void>
>('maintenance/getOneKeyStockMaintenanceSubmit', async (params, api) => {
  return sendAsync(oneKeyStockMaintenanceSubmit(params), api)
})

const stockMaintenanceSlice = createSlice({
  name: 'stockMaintenanceSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.stockMaintenanceData = action.payload
    },
    setTotal(state, action) {
      state.stockMaintenanceTotal = action.payload
    },
    setCurrent(state, action) {
      state.stockMaintenanceCurrent = action.payload
    },
    setId(state, action) {
      state.stockMaintenanceId = action.payload
    },
    setDetail(state, action) {
      state.stockMaintenanceDetail = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      submitStockMaintenanceAsync.fulfilled,
      (state, action: any) => {
        state.stockMaintenanceId = action.payload
      }
    )
  },
})

export const {
  setData,
  setTotal,
  setCurrent,
  setDetail,
  setId,
  setPageLoading,
} = stockMaintenanceSlice.actions

export const selectStockMaintenanceData = (state: RootState) =>
  state.stockMaintenance.stockMaintenanceData

export const selectStockMaintenanceTotal = (state: RootState) =>
  state.stockMaintenance.stockMaintenanceTotal

export const selectStockMaintenanceCurrent = (state: RootState) =>
  state.stockMaintenance.stockMaintenanceCurrent

export const selectStockMaintenanceId = (state: RootState) =>
  state.stockMaintenance.stockMaintenanceId

export const selectStockMaintenanceDetail = (state: RootState) =>
  state.stockMaintenance.stockMaintenanceDetail

export const selectPageLoading = (state: RootState) =>
  state.stockMaintenance.pageLoading



export function getStockMaintenanceList(params: StockMaintenanceParams): RootThunk {
    return api(getStockMaintenance(params), (data: any, dispatch) => {
        dispatch(setData(data.records))
        dispatch(setTotal(data.total))
        dispatch(setPageLoading(false))
    });
}

export function getStockMaintenanceItem(id: number): RootThunk {
    return api(getStockMaintenanceDetail(id), (data, dispatch) => {
        dispatch(setDetail(data))
    })
}

export const removeStockMaintenanceAsync = createAsyncThunk<
    void, number, RootThunkApi<void>
>("maimtenance/remove", async (id, api) => {
    return sendAsync(removeStockMintenance(id), api)
})

export const maintenanceCompletedAsync = createAsyncThunk<
    void, number, RootThunkApi<void>
>("maintenance/completed", async (id, api) => {
    return sendAsync(maintenanceCompleted(id), api)
})



export default stockMaintenanceSlice.reducer;
