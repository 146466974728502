/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-14 11:19:36
 * @LastEditors: sj
 * @LastEditTime: 2022-11-25 14:50:37
 */

export interface PatientLeftListItem {
  name: string
  open: boolean
  count: number
  value: number
  list: any[]
}

export enum PatientListItem {
  YYWD = 0,
  DJZ = 1,
  JZZ = 2,
  DJF = 3,
  YJZ = 4,
  CZ = 5,
  FZ = 6,
  ZJ = 7,
}

export const todayPatientListItem = [
  PatientListItem.DJZ,
  PatientListItem.JZZ,
  PatientListItem.DJF,
  PatientListItem.YJZ,
]

export const getPatientListItem = (key: PatientListItem): string => {
  switch (key) {
    case PatientListItem.YYWD:
      return '预约未到'
    case PatientListItem.DJZ:
      return '待接诊'
    case PatientListItem.JZZ:
      return '就诊中'
    case PatientListItem.DJF:
      return '待缴费'
    case PatientListItem.YJZ:
      return '已就诊'
      case PatientListItem.CZ:
      return '初诊'
      case PatientListItem.FZ:
      return '复诊'
    case PatientListItem.ZJ:
      return '最近'
    default:
      return ''
  }
}

export const patientLeftList: PatientLeftListItem[] = [
  {
    name: '预约未到',
    open: true,
    count: 0,
    value: PatientListItem.YYWD,
    list: [],
  },
  {
    name: '待接诊',
    open: true,
    count: 0,
    value: PatientListItem.DJZ,
    list: [],
  },
  {
    name: '就诊中',
    open: true,
    count: 0,
    value: PatientListItem.JZZ,
    list: [],
  },
  {
    name: '待缴费',
    open: true,
    count: 0,
    value: PatientListItem.DJF,
    list: [],
  },
  {
    name: '已就诊',
    open: true,
    count: 0,
    value: PatientListItem.YJZ,
    list: [],
  },
]

export const CZFZLeftDefaultList: PatientLeftListItem[] = [
  {
    name: '初诊',
    open: true,
    count: 0,
    value: PatientListItem.CZ,
    list: [],
  },
  {
    name: '复诊',
    open: true,
    count: 0,
    value: PatientListItem.FZ,
    list: [],
  },
]

export interface AllGroupType {
  name: string
  isEdit: boolean
  count: number
}

export const allGroupList: AllGroupType[] = [
  {
    name: '全部',
    isEdit: false,
    count: 22,
  },
  {
    name: '最近患者',
    isEdit: false,
    count: 10,
  },
  {
    name: '治疗完成',
    isEdit: true,
    count: 7,
  },
  {
    name: '洗牙',
    isEdit: true,
    count: 4,
  },
  {
    name: '哈哈',
    isEdit: true,
    count: 5,
  },
]

export const outpatientProjectOptions = [
  {
    value: '1',
    name: '治疗',
  },
  {
    value: '1',
    name: '修复',
  },
  {
    value: '1',
    name: '种植',
  },
  {
    value: '1',
    name: '正畸',
  },
  {
    value: '1',
    name: '检查',
  },
  {
    value: '1',
    name: '洗牙',
  },
  {
    value: '1',
    name: '其他',
  },
]

export const outPatientInfoOptions = [
  {
    name: '挂号',
    value: 1,
  },
  {
    name: '病历',
    value: 2,
  },
  {
    name: '处置',
    value: 3,
  },
  {
    name: '结算',
    value: 4,
  },
]

export enum PatientDataType {
  GH = 1,
  CZ = 2,
  JS = 3,
  BL = 4,
  SS = 5,
  YY = 6,
}


export const sourceOptions = [
  {
    value: '0',
    name: '未知',
  },
  {
    value: '1',
    name: '亲友推荐',
  },
  {
    value: '2',
    name: '媒体报道',
  },
  {
    value: '3',
    name: '活动推广',
  },
  {
    value: '4',
    name: '网络咨询',
  },
  {
    value: '5',
    name: '家住附近',
  }
]


export const getPatientDataTypeName = (type: PatientDataType): string => {
  switch (type) {
    case PatientDataType.GH:
      return '挂号'
    case PatientDataType.CZ:
      return '处置'
    case PatientDataType.JS:
      return '结算'
    case PatientDataType.BL:
      return '病历'
    case PatientDataType.SS:
      return '手术'
    case PatientDataType.YY:
      return '预约'
    default:
      return ''
  }
}
