/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-10-20 16:56:19
 */
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState, RootThunk } from "../../../../app/store";
import { api } from "../../../../app/applicationSlice";
import * as service from "../../../../services/formula";
import { NoFormulaParmas } from "../../../../services/formula";
import { NoFormulasDetail } from "../../../../models/ModalList";


interface NoFormulaState {
  FormulaData: any,
  Total: 0,
  Current: 1,
  departments: NoFormulasDetail[];
  pageLoading: false,
}
const initialState: NoFormulaState = {
  FormulaData: [],
  Total: 0,
  Current: 1,
  departments: [],
  pageLoading: false,
 
};
const noformulaSlice = createSlice({
  name: "formula",
  initialState,
  reducers: {
    setData(state, action) {
      state.FormulaData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setFormulaDetail: (state, action) => {
      state.departments = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
  
  },
});

export const {
  setData, 
  setTotal,
  setCurrent,
  setFormulaDetail,
  setPageLoading,
} = noformulaSlice.actions;

export function getformulaList(params: NoFormulaParmas): RootThunk {
  return api(service.getNoformulaList(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
        dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  });
}

export function getformula(params: NoFormulaParmas): RootThunk {
  return api(service.getNoformula(params), (data: any, dispatch) => {
    dispatch(setFormulaDetail([data]));
  });
}


export const selectFormulaData = (state: RootState) => state.formula.FormulaData

export const selectTotal = (state: RootState) => state.formula.Total

export const selectCurrent = (state: RootState) => state.formula.Current

export const selectPageLoading = (state: RootState) => state.formula.pageLoading

export const selectFarmulaDetail = (state: RootState) =>
  state.FormulasDetail.departments;



export default noformulaSlice.reducer;
