/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-13 14:53:45
 * @LastEditors: sj
 * @LastEditTime: 2022-09-13 14:57:12
 */
import { Col, Row, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import styles from './TcmCases.module.css'

interface HistoryTitleProps {
  t: any
  CollapseActivekey: string
}

export const HistoryTitle = ({ t, CollapseActivekey }: HistoryTitleProps) => {
  return <Row justify='space-between' style={{
    width: '100%', overflow: 'hidden',
    fontWeight: CollapseActivekey == t.id ? 600 : 400
  }}>
    <Col className={styles.historyTitle} span={10}>
      <Tooltip title={t.disease} placement="topLeft">
        <span >{t.disease}</span>
      </Tooltip>
    </Col>
    <Col className={styles.historyTitle} span={14}>
      <Tooltip title={t.treatmentDoctorName + ' ' + t.treatmentDepartmentName + ' ' + moment(t.createTime).format('MM/DD')} placement="topRight">
        <span>
          {t.treatmentDoctorName}&ensp;{t.treatmentDepartmentName}&ensp;{moment(t.createTime).format('MM/DD')}
        </span>
      </Tooltip>
    </Col>
  </Row>
}