/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-01-24 15:33:09
 * @LastEditors: linxi
 * @LastEditTime: 2024-01-24 16:51:25
 */

import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'
export interface ListUploadBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    treatmentId: number
  }
}
export function electronicMedicalRecordListUpload(bodyName: ListUploadBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/electronicMedicalRecordUpload_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

