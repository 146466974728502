/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-27 14:46:41
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-28 15:06:33
 */

import { sum } from 'lodash'
import moment from 'moment'
import { BillingCategoryName } from './billingCategory'
import { DateTimeFormatSimpleOnlyOne } from './datetime'

export function printBody(data: any) {
  const tenantName = data?.tenantName
  const userName = data?.userName
  const storehouseName = data?.storehouseName
  const totalAmountInput = data?.totalAmountInput
  const totalAmountSale = data?.totalAmountSale
  // const index = kfmc.indexOf('-')
  // kfmc = kfmc.substring(index + 1, kfmc.length)
  const datas: any = data?.dataList
  let sjDatas = ''
  let splitCount: any = 0
  const total = 0
  const retailPriceTotal = 0
  let accountUnit = ''
  let splitUnit = ''

  for (let i = 0; i < datas?.length; i++) {
    const list = datas?.[i]?.value
    const count = Number(datas?.[i]?.counts)
    const total = Number(datas?.[i]?.totals)
    const retailPriceTotal = Number(datas?.[i]?.retailPriceTotals)

    sjDatas +=
      ' <div style="font: 8px \'宋体\';display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '            <div style="padding:0px 2px;word-break:break-all;width: 10%;display: table-cell;vertical-align: middle;text-align:left;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i]?.name +
      '            </div>' +
      '           <div style="display:table-cell;word-break:break-all;width: 90%;text-align:center;box-sizing: border-box;">'

    for (let j = 0; j < list?.length; j++) {
      accountUnit = list?.[j]?.accountUnit
      splitCount = list?.[j]?.splitTag ? Number(datas?.[i]?.splitCounts) : '-'
      splitUnit =list?.[j]?.splitTag ? list?.[j]?.splitUnit :''
      sjDatas +=
        '    <div style="display:table;width:100%;clear: left;border-bottom: 1px solid #000;">' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 10%;display: table-cell;vertical-align: middle;text-align:left;box-sizing: border-box;border-right: 1px solid #000">' +
        '                    ' +
        list?.[j]?.tenantSupplierName +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 7%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                    ' +
        list?.[j]?.spec +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                    ' +
        list?.[j]?.packSpec +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                    ' +
        list?.[j]?.billingCategory +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                    ' +
        list?.[j]?.batchNo +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                    ' +
        list?.[j]?.productDate +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
        '                    ' +
        list?.[j]?.count +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
        '                   ' +
        list?.[j]?.splitCount +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
        '                   ' +
        list?.[j]?.unitPrice +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
        '                    ' +
        list?.[j]?.total +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
        '                    ' +
        list?.[j]?.retailPrice +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
        '                   ' +
        list?.[j]?.retailPriceTotal +
        '                   </div>' +
        '                   <div style="padding:0px 2px;word-break:break-all;width: 9%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;">' +
        '                   ' +
        list?.[j]?.deadline +
        '                 </div>' +
        '           </div>'
    }
    sjDatas +=
      '    <div style="display:table;width:100%;clear: left;">' +
      '               <div style="padding:0px 2px;font-weight: bold;word-break:break-all;width: 43%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                小计:' +
      '                </div>' +
      '                <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      count +
      accountUnit +
      '                </div>' +
      '                <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      splitCount +
      splitUnit +
      '                </div>' +
      '                <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      '                </div>' +
      '                <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      total?.toFixed(2) +
      '                </div>' +
      '                <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      '                </div>' +
      '                <div style="padding:0px 2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      retailPriceTotal?.toFixed(2) +
      '                </div>' +
      '                <div style="padding:0px 2px;word-break:break-all;width: 9%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;">' +
      '                ' +
      '                </div>' +
      '            </div></div></div>'
  }

  // const userBean = getUserBean()
  const content =
    '<div style="margin-left: 10px;margin-right: 10px">' +
    '    <h4 style="text-align: center;margin-bottom: 5px;">' +
    tenantName +
    '</h4>' +
    '    <div style="text-align: center;margin-top: 5px;margin-bottom: 5px;width:100%">' +
    '       <h3 style="display: inline-block;text-align: center;margin-top: 5px;margin-bottom: 5px;width: 20%;border-bottom: 1px solid #000;">' +
    '          <div style="text-align: center;width:100%;display: inline-flex;">' +
    '           <div style="width:25%;display: inline-block">库</div>' +
    '           <div style="width:25%;display: inline-block">存</div>' +
    '           <div style="width:25%;display: inline-block">台</div>' +
    '           <div style="width:25%;display: inline-block">帐</div>' +
    '          </div></h3>' +
    '    </div>' +
    '    <div style="font-size:10px;height: 35px;margin-bottom: -18px;">' +
    '        <div style="float: left;width: 40%;text-align: left;"><span style="padding-left: 10px;"><span style="font-weight: bold">库房名称:</span>' +
    storehouseName +
    '</span></div>' +
    '        <div style="float: left;width: 60%;text-align: right;"><span style="padding-right: 10px"><span style="padding-right: 10px;"><span style="font-weight: bold">操作日期:</span>' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</div>' +
    '    </div>' +
    '    <div style="font-size:8px;display:table;width:100%;clear: left;border: 1px solid #000;">' +
    '       <div style="font-weight: bold;word-break:break-all;width: 10%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            物资名称' +
    '       </div>' +
    '       <div style="display:table-cell;word-break:break-all;width: 90%;text-align:center;box-sizing: border-box;">' +
    '          <div style="display:table;width:100%;clear: left;">' +
    '               <div style="font-weight: bold;word-break:break-all;width: 10%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                生产厂家' +
    '               </div>' +
    '               <div style="font-weight: bold;word-break:break-all;width: 7%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                物资规格' +
    '               </div>' +
    '               <div style="font-weight: bold;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                单位' +
    '               </div>' +
    '               <div style="font-weight: bold;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                开票项目' +
    '               </div>' +
    '               <div style="font-weight: bold;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                批次号' +
    '               </div>' +
    '               <div style="font-weight: bold;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                生产日期' +
    '               </div>' +
    '               <div style="display: table-cell;vertical-align: middle;width: 16%;border-right: 1px solid #000;box-sizing: border-box;">' +
    '                   <div style="font-weight: bold;word-break:break-all;width: 100%;float: left;text-align:center;box-sizing: border-box;">' +
    '                   数量' +
    '                   </div>' +
    '                   <div style="display: table-cell;vertical-align: middle;width: 100%;float: left;text-align:center;box-sizing: border-box;border-top: 1px solid #000;">' +
    '                       <div style="display: table;width: 100%;clear: left;box-sizing: border-box;">' +
    '                           <div style="font-weight: bold;word-break:break-all;width:50%;display: table-cell;vertical-align: middle;box-sizing: border-box;text-align:center;border-right: 1px solid #000">整存</div>' +
    '                           <div style="font-weight: bold;word-break:break-all;width:50%;display: table-cell;vertical-align: middle;box-sizing: border-box;text-align:center;">拆零</div>' +
    '                       </div>' +
    '                   </div>' +
    '              </div>' +
    '              <div style="display: table-cell;vertical-align: middle;width: 16%;border-right: 1px solid #000;box-sizing: border-box;">' +
    '                   <div style="font-weight: bold;word-break:break-all;width: 100%;float: left;text-align:center;">' +
    '                     以进价计' +
    '                   </div>' +
    '                   <div style="display: table-cell;vertical-align: middle;width: 100%;float: left;text-align:center;box-sizing: border-box;border-top: 1px solid #000;">' +
    '                       <div style="display: table;width: 100%;clear: left;">' +
    '                           <div style="font-weight: bold;word-break:break-all;width:50%;display: table-cell;box-sizing: border-box;vertical-align: middle;text-align:center;border-right: 1px solid #000">单价(元)</div>' +
    '                           <div style="font-weight: bold;word-break:break-all;width:50%;display: table-cell;box-sizing: border-box;vertical-align: middle;text-align:center;">金额(元)</div>' +
    '                       </div>' +
    '                   </div>' +
    '             </div>' +
    '             <div style="display: table-cell;vertical-align: middle;width: 16%;border-right: 1px solid #000;box-sizing: border-box;">' +
    '                   <div style="font-weight: bold;word-break:break-all;width: 100%;float: left;text-align:center;">' +
    '                       以售价计' +
    '                   </div>' +
    '                   <div style="display: table-cell;vertical-align: middle;width: 100%;float: left;text-align:center;box-sizing: border-box;border-top: 1px solid #000;">' +
    '                       <div style="display: table;width: 100%;clear: left;">' +
    '                           <div style="font-weight: bold;word-break:break-all;width:50%;display: table-cell;box-sizing: border-box;vertical-align: middle;text-align:center;border-right: 1px solid #000">单价(元)</div>' +
    '                           <div style="font-weight: bold;word-break:break-all;width:50%;display: table-cell;box-sizing: border-box;vertical-align: middle;text-align:center;">金额(元)</div>' +
    '                       </div>' +
    '                   </div>' +
    '             </div>' +
    '             <div style="font-weight: bold;word-break:normal;width: 9%;display: table-cell;vertical-align: middle;box-sizing: border-box;text-align:center;">' +
    '               有效期' +
    '             </div>' +
    '        </div>' +
    '     </div>' +
    '    </div>' +
    sjDatas +
    '    <div style="font-size:8px;display:table;width:100%;clear: left;border-bottom: 1px solid #000;border-left: 1px solid #000;border-right: 1px solid #000;">' +
    '       <div style="word-break:break-all;width: 13%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;">' +
    '            ' +
    '       </div>' +
    '       <div style="display:table-cell;word-break:break-all;width: 87%;text-align:center;box-sizing: border-box;">' +
    '          <div style="display:table;width:100%;clear: left;">' +
    '             <div style="font-weight: bold;word-break:break-all;width: 39.7%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                合计：' +
    '             </div>' +
    '             <div style="word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    '             </div>' +
    '             <div style="word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    '             </div>' +
    '             <div style="word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    '             </div>' +
    '             <div style="word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '             ' +
    totalAmountInput +
    '             </div>' +
    '             <div style="word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '              ' +
    '             </div>' +
    '             <div style="word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '             ' +
    totalAmountSale +
    '             </div>' +
    '             <div style="word-break:break-all;width: 9%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;">' +
    '                ' +
    '              </div>' +
    '          </div>' +
    '       </div>' +
    '    </div>' +
    '    <div style="padding-top: 10px;font-size: 10px;width: 100%">' +
    '       <div style="width: 70%;float: left;text-align: left"><span style="font-weight: bold;padding-left: 10px">单位负责人：</span></div>' +
    '       <div style="width: 30%;float: left;text-align: left;"><span style="font-weight: bold;">财务：</span></div>' +
    '    </div>' +
    '    <div style="padding-top: 10px;font-size: 10px;width: 100%">' +
    '       <div style="width: 70%;float: left;text-align: left"><span style="font-weight: bold;padding-left: 10px">核查：</span></div>' +
    '       <div style="width: 30%;float: left;text-align: left;"><span style="font-weight: bold;">制单人：</span> ' +
    userName +
    '</div>' +
    '    </div>'
  return content
}
