import { DateRangeFieldName } from '../compnents/form/DateSelect'
import { DeliveryState } from '../models/delivery'
import { Material } from '../models/material'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface DeliveryListParams {
  current: number
  size?: number
  param?: string
  timeHead?: string
  timeTail?: string
  [DateRangeFieldName]?: number
  shipmentsState: DeliveryState
}

export function getDeliveryList(params: DeliveryListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/shipments/list`,
    method: 'GET',
    params,
  }
}

export interface DirectDeliveryParams {
  paymentId?: string
  treatmentId?: string
  recipeItemList?: string[]
  deliveryState: DeliveryState
}

export function directDeliver(params: DirectDeliveryParams): Request {
  return params.deliveryState === DeliveryState.Fulfilled
    ? {
        url: `${API_PREFIX}/blade-outpatient/shipments/oneKeyReturn`,
        method: 'POST',
        body: {
          ...params,
          // treatmentId: params.recipeItemList?.length
          //   ? undefined
          //   : params.treatmentId,
          // recipeItemList: params.recipeItemList,
          deliveryState: undefined,
        },
      }
    : {
        url: `${API_PREFIX}/blade-outpatient/shipments/oneKeyShipments`,
        method: 'POST',
        body: {
          ...params,
          // treatmentId: params.recipeItemList?.length
          //   ? undefined
          //   : params.treatmentId,
          // recipeItemList: params.recipeItemList,
          deliveryState: undefined,
        },
      }
}

export interface DirectDeliverItemParams {
  recipeItemId: string
  deliveryState: DeliveryState
}

export function directDeliverSingleItem(
  params: DirectDeliverItemParams
): Request {
  return params.deliveryState === DeliveryState.Fulfilled
    ? {
        url: `${API_PREFIX}/blade-outpatient/shipments/oneReturn`,
        method: 'POST',
        body: {
          ...params,
          deliveryState: undefined,
        },
      }
    : {
        url: `${API_PREFIX}/blade-outpatient/shipments/autoShipments`,
        method: 'POST',
        body: {
          ...params,
          deliveryState: undefined,
        },
      }
}

export interface DeliveryDetailParams {
  current: number
  size?: number
  treatmentId?: string
  paymentId?:any
  shipmentsState: DeliveryState
  billingCategorys?: string
}

export function getDeliveryDetail(params: DeliveryDetailParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/shipments/detail`,
    method: 'GET',
    params,
  }
}

export function getMaterialBatchInfo(material: Material): Request {
  let id = undefined
  let flag = 0
  if (material.materialsCatalogId && material.materialsCatalogId !== '-1') {
    id = material.materialsCatalogId
    flag = 2
  } else if (
    material.inventoryLedgerId &&
    material.inventoryLedgerId !== '-1'
  ) {
    id = material.inventoryLedgerId
    flag = 1
  }
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/allinvs`,
    method: 'GET',
    params: {
      id,
      flag,
    },
  }
}

export interface BatchDeliveryParams {
  recipeItemId: string
  choseBatchList: { inventoryLedgerId: string; shipmentsCount: number }[]
}

export function batchDeliver(params: BatchDeliveryParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/shipments/batchShipments`,
    method: 'POST',
    body: params,
  }
}

// 导出
export function getDeliveryListExoprtFn(
  params: DeliveryListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/shipments/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}