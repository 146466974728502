/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-17 10:08:05
 */
import { RecipeStatus } from '../models/recipe'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface GetRetailListParams {
  current: number
  size?: number
  param?: string
  payMethod: any
  operateUser?: string
  state?: RecipeStatus
  timeHead?: string
  timeTail?: string
}
export interface JuheReturnPremiumParams {
  paymentId: string
}
export function JuheReturnPremium(params: JuheReturnPremiumParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/retailReturnPremium`,
    method: 'POST',
    params,
  }
}
export function getRetailList(params: GetRetailListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/quicksales/list`,
    method: 'GET',
    params,
  }
}

// 药品零售接口优化
export function getRetailListNew(params: GetRetailListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/quicksales/retailList`,
    method: 'GET',
    params,
  }
}
// 药品零售-修改患者姓名
export interface editNameFnParams {
  name?: string
  treatmentId?: string
}
export function editNameFn(body: editNameFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/quicksales/retailPatientChange`,
    method: 'POST',
    body,
  }
}
