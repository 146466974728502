import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../../app/applicationSlice";
import { RootThunkApi } from "../../../app/store";
import {
  ExcuteDetailList,
  ExcuteDetailListParams,
  ExcuteTableList,
  ExcuteTableListParams,
  LatelyOperatorParams,
  LatelyOperatorPeople,
  ToBeCompletedTreatmentList,
  ToBeCompletedTreatmentParams,
  ToBeCompletedTreatmentProject,
  ToBeCompletedTreatmentProjectParams,
  ToBeCompletedTreatmentSubmit,
  ToBeCompletedTreatmentSubmitParams,
} from "../../../services/toBeCompletedTreatment";

// 列表
export const getToBeCompletedTreatmentList = createAsyncThunk<
  void,
  ToBeCompletedTreatmentParams,
  RootThunkApi<void>
>("medicalstation/toBeCompletedTreatmentList", async (params, api) => {
  return sendAsync(ToBeCompletedTreatmentList(params), api);
});

// 详情
export const getExcuteDetailList = createAsyncThunk<
  void,
  ExcuteDetailListParams,
  RootThunkApi<void>
>("medicalstation/excuteDetailList", async (params, api) => {
  return sendAsync(ExcuteDetailList(params), api);
});

// 项目
export const getProjectList = createAsyncThunk<
  void,
  ToBeCompletedTreatmentProjectParams,
  RootThunkApi<void>
>("medicalstation/ToBeCompletedTreatmentProject", async (params, api) => {
  return sendAsync(ToBeCompletedTreatmentProject(params), api);
});

// 执行人
export const getLatelyOperatorList = createAsyncThunk<
  void,
  LatelyOperatorParams,
  RootThunkApi<void>
>("medicalstation/LatelyOperatorList", async (params, api) => {
  return sendAsync(LatelyOperatorPeople(params), api);
});

// 单条详情列表
export const getExcuteTableList = createAsyncThunk<
  void,
  ExcuteTableListParams,
  RootThunkApi<void>
>("medicalstation/ExcuteTableList", async (params, api) => {
  return sendAsync(ExcuteTableList(params), api);
});
// 弹窗编辑确认提交
export const getToBeCompletedTreatmentSubmit = createAsyncThunk<
  void,
  ToBeCompletedTreatmentSubmitParams,
  RootThunkApi<void>
>("medicalstation/ToBeCompletedTreatment", async (params, api) => {
  return sendAsync(ToBeCompletedTreatmentSubmit(params), api);
});
