/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-19 14:33:03
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-25 14:41:25
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Dropdown, Input, InputProps, Pagination, Table } from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { Material } from '../../../models/material'
import { Page, startPageOf } from '../../../models/page'
import { RecipeCategory } from '../../../models/recipe'
import { setMealflag } from '../../../models/setmeal'
import { ThemeContext } from '../../../theme/ThemeContext'
import { sleep } from '../../../utils/SystemUtils'
import { useTimeout } from '../../../utils/timehooks'
import { pageCommodityAsync } from '../../commodity/commoditySlice'
import {
  batchCailiaoAsync,
  batchTaocanAsync,
  ItemCompletionType,
} from '../../completion/completionSlice'
import {
  RecipeItemColumnsSimple,
  RecipeItemColumnsSimpleBatchNo,
} from '../../treatment/editor/components/columns'
import styles from '../Setmeal.module.css'
import { selectDurgFlag, selectFlagText, setFlagText } from '../setmealSlice'

interface RecipeItemInputProps {
  isRestrictUser?: any
  showFlag?: any
  nameValue?: any
  isFullMatchTag?: any
  useSpecifiedBatch?: any
  flag?: number
  source?: number
  type?: ItemCompletionType
  category?: RecipeCategory
  filters?: boolean
  defaultUsingSpecifiedBatch?: boolean
  value?: string
  autoFocus?: boolean
  onChange?: (value: string) => void
  onSelect?: (material: Material, ignoreStorage: boolean) => void
  style?: React.CSSProperties
}

export const RecipeItemInput = ({
  isRestrictUser,
  showFlag,
  nameValue,
  isFullMatchTag,
  useSpecifiedBatch,
  value,
  onChange,
  onSelect,
  onBlur,
  onPressEnter,
  autoFocus = false,
  style,
}: RecipeItemInputProps &
  Pick<InputProps, 'onBlur' | 'onPressEnter'>): ReactElement => {
  const theme = useContext(ThemeContext)

  const inputRef = useRef<Input>(null)

  const dispatch = useDispatch<RootDispatch>()

  const [isIgnoringStorage, setIsIgnoringStorage] = useState(false)

  const [isVisible, setIsVisible] = useState(false)

  const flag = useSelector(selectDurgFlag)

  const flagText = useSelector(selectFlagText)

  const [page, setPage] = useState<Page<Material>>(startPageOf())

  const [isComposing, setIsComposing] = useState(false)

  const [flagVisible, setFlagVisible] = useState<any>(false)

  const [params, setParams] = useState<{ keyword: string; current: number }>({
    keyword: '',
    current: 1,
  })

  const [queryStr, setQueryStr] = useState('')

  const [selectedRowId, setSelectedRowId] = useState('')

  const complete = (keyword: string, current: number) => {
    if (keyword) {
      if (showFlag == 1) {
        dispatch(
          batchTaocanAsync({
            // state: 1,
            flag: flagText == '添加材料' ? 5 : (setMealflag(flag) as number), //如果是添加材料flag 为5
            keyword: keyword,
            current,
            size: 5,
            isFullMatch: isFullMatchTag?.toString() ? 0 : undefined,
            merge: !useSpecifiedBatch,
            isRestrictUser: isRestrictUser ? isRestrictUser : null,
          })
        )
          .then(unwrapResult)
          .then((page: any) => {
            const data = {
              total: page.total,
              items: page.records,
              current: page?.current,
              size: page?.size,
            }
            setPage(data)
            if (data?.items?.length)
              if (data.items.length > 0) {
                setSelectedRowId(data.items[0].id)
                if (!flagVisible) {
                  setIsVisible(true)
                }
              } else {
                setSelectedRowId('')
              }
          })
      } else {
        dispatch(
          pageCommodityAsync({
            state: 1,
            flag: flagText == '添加材料' ? 5 : (setMealflag(flag) as number), //如果是添加材料flag 为5
            allStr: keyword,
            current,
            size: 5,
            isFullMatch: isFullMatchTag ? 0 : undefined,
            isRestrictUser: isRestrictUser ? isRestrictUser : null,
            // showFlag: showFlag,
          })
        )
          .then(unwrapResult)
          .then((page) => {
            setPage(page)
            if (page.items.length > 0) {
              setSelectedRowId(page.items[0].id)
              if (!flagVisible) {
                setIsVisible(true)
              }
            } else {
              setSelectedRowId('')
            }
          })
      }
    } else {
      reset()
      setFlagVisible(true)
    }
  }

  useEffect(() => {
    setFlagVisible(false)
  }, [])

  useEffect(() => {
    if (!isComposing) {
      setParams({ keyword: queryStr, current: 1 })
    }
  }, [isComposing, queryStr])

  useTimeout(
    () => {
      complete(params.keyword, params.current)
    },
    50,
    [params]
  )

  const overlay = (
    <Col>
      <Table
        className={styles.table}
        columns={
          showFlag
            ? RecipeItemColumnsSimpleBatchNo(theme)
            : RecipeItemColumnsSimple(theme)
        }
        dataSource={page.items}
        size='small'
        pagination={false}
        rowKey={(m) => m.id}
        bordered
        onRow={(m) => ({
          onClick: () => {
            if (onSelect) {
              onSelect(m, isIgnoringStorage)
              setFlagVisible(true)
              reset()
              setSelectedRowId('')
            }
          },
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [selectedRowId],
          onChange: (ks) => setSelectedRowId(ks[0] as string),
        }}
      />
      <Pagination
        pageSize={page.size}
        current={page.current}
        total={page.total}
        size='small'
        pageSizeOptions={['10', '20', '50', '100', '500']}
        style={{
          margin: '10px 10px',
        }}
        onChange={(current) => {
          setParams({ keyword: value || '', current: current })
          setFlagVisible(false)
        }}
      />
    </Col>
  )
  const reset = () => {
    setPage(startPageOf())
    setSelectedRowId('')
    setIsVisible(false)
  }

  useEffect(() => {
    if (value) {
      setParams({ keyword: value || '', current: page.current })
    }
  }, [value])

  return (
    <Dropdown
      overlay={overlay}
      trigger={[]}
      visible={isVisible && page?.items?.length > 0}
      onVisibleChange={(v) => setIsVisible(v)}
    >
      <Input
        value={value}
        style={style}
        autoFocus={autoFocus}
        onClick={(v) => {
          nameValue ? setParams({ keyword: nameValue, current: 1 }) : ''
          setIsVisible(true)
        }}
        onBlur={async (e) => {
          onBlur && onBlur(e)
          await sleep(200)
          setIsVisible(false)
        }}
        onFocus={() => {
          setIsVisible(false)
        }}
        onCompositionStart={() => {
          setIsComposing(true)
        }}
        onCompositionEnd={(e) => {
          setIsComposing(false)
          setParams({ keyword: e.data, current: 1 })
        }}
        onChange={(e) => {
          setQueryStr(e.target.value)
          if (e.target.value) {
            setIsVisible(true)
          } else {
            setIsVisible(false)
          }
          if (onChange) {
            onChange(e.target.value)
          }
        }}
        onKeyDown={(event) => {
          if (event?.keyCode == 27) {
            setIsVisible(false)
          }
          event.stopPropagation()
          if (!selectedRowId) {
            return
          }
          let index = page.items.findIndex((m) => m.id === selectedRowId)
          const oneId = page.items?.[0]?.id
          const lastId = page.items?.[4]?.id
          switch (event.key) {
            case 'ArrowDown':
              // index = index + 1
              index = lastId == selectedRowId ? 0 : index + 1
              break
            case 'ArrowUp':
              index =
                oneId == selectedRowId
                  ? index + (page.items?.length - 1)
                  : index - 1
              break
            case 'ArrowRight':
              if (page.current * page.size < page.total) {
                setParams({ keyword: value || '', current: page.current + 1 })
              }
              break
            case 'ArrowLeft':
              if (page.current > 1) {
                setParams({ keyword: value || '', current: page.current - 1 })
              }
              break
          }
          if (index >= 0 && index < page.items.length) {
            setSelectedRowId(page.items[index].id)
          }
        }}
        onPressEnter={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onPressEnter && onPressEnter(e)
          if (!selectedRowId || !onSelect) {
            return
          }
          const item = page.items.find((m) => m.id === selectedRowId)

          if (item) {
            onSelect(item, isIgnoringStorage)
            reset()
            setFlagVisible(true)
          }
        }}
      />
    </Dropdown>
  )
}
