/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-16 18:06:31
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-20 20:06:14
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {Col ,message , notification} from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { Query } from './Query'
import { Columns } from './columns'
import { serverType } from '../../services/commodity'
import { DataType } from '../putstorage/putstorage'
import {
  getElectronicTicketUploadListAsync,
  getMedicalInformationUploadModalDetailAsync,
  selfMedicalInformationUploadAsync,
  selfMedicalInformationUploadElecSetlCertAsync,
  selfQueryElecSetlCertInfoUpldRsltAsync,
} from './ElectronicTicketUploadSlice'
import { MedicalInformationUploadModal } from './MedicalInformationUploadModal'
import { RetransmissionMedicalInformationUploadModal } from './RetransmissionMedicalInformationUploadModal'
import { LookDetailModal } from './lookDetail'
import { CertificateResultQueryModal } from './CertificateResultQueryModal'
import {
  selectInsuranceArray,
  selectUserName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectUserId,
  selectTenantCategory,
} from '../../app/applicationSlice'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { strParse, medicalSendHttp } from '../../utils/MedicalUtils'
import styles from "./ElectronicTicketUpload.module.css";

const RealElectronicTicketUploads = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const baseData = useSelector(selectInsuranceItem)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [buttonLoading, setButtonLoading] = useState(false)

  const [disabled, setDisabled] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({ current: 0, size: 50 })

  const [page, setPage] = useState<any>({ items: [], total: 0 })

  const [pageLoading, setPageLoading] = useState(false)

  const [selectedKeys, setSelectedKeys] = useState<any>([])

  const [submitLoading, setSubmitLoading] = useState<any>()

  const [messages, setMessages] = useState<any>()

  const [ids, setIds] = useState<any>()

  const [paymentInsurance, setPaymentInsurance] = useState<any>()

  const [showStopAction, setShowStopAction] = useState(false)

  const [paymentIds, setPaymentIds] = useState<string[]>([])

  const [totalUpload, setTotalUpload] = useState<number>(0)

  const [messageApi, contextHolder] = message.useMessage()

  const [
    isMedicalInformationUploadVisible,
    setIsMedicalInformationUploadVisible,
  ] = useState(false)

  const [
    isRetransmissionMedicalInformationUploadVisible,
    setIsRetransmissionMedicalInformationUploadVisible,
  ] = useState(false)

  const [isLookDetailVisible, setIsLookDetailVisible] = useState(false)

  const [
    isCertificateResultQueryVisible,
    setIsCertificateResultQueryVisible,
  ] = useState(false)

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedKeys(selectedRowKeys)
    },
  }

  useEffect(() => {
    if (params.current !== 0) {
      getPageList()
    }
  }, [params])

  const getPageList = () => {
    setPageLoading(true)
    dispatch(
      getElectronicTicketUploadListAsync({
        ...params,
        state: 3,
        insuranceCode: 'ZIFEI',
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    params.current &&
      sessionStorage.setItem(location.pathname, JSON.stringify({ ...params }))
  }, [params])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])


  //一键上传今日电子发票
  const oneClickUploadElecInvoice = (list:any) => {

    //递归结束 关闭遮罩 并刷新列表
    if (list.length === 0) {
      //上传成功的进行票据信息查询
      //batchQueryElecSetlCertInfoUpldRslt()
      setTotalUpload(0)
      setSubmitLoading(false)
      setShowStopAction(false)
      getPageList()
      return
    }

    const item = list.pop();
    dispatch(getMedicalInformationUploadModalDetailAsync({
      patientId: item?.registration?.patientId,
      paymentId: item?.paymentId,
      treatmentId: item?.treatment?.id,
    }))
      .then(unwrapResult)
      .then((v: any) => {
        // setDetail(v)
        if (JSON.stringify(v?.invoiceDetails) == '{}' || v.patient?.idCardNumber == '') {
          messageApi.open({
            type: 'warning',
            content: item?.treatment?.patientName+'未开具电子发票，跳过该单据。',
            className: 'custom-class',
          })
          oneClickUploadElecInvoice(list)
          return
        }

        selfMedicalInformationUploadSubmit(
          'requestString',
          v.patient?.idCardNumber,
          '',
          '',
          '',
          v?.invoiceDetails?.id,
          '2',
          v?.treatment.nwbFlag > -1
            ? v?.treatment.nwbFlag?.toString()
            : '0',
          v?.payment.ownpayType > -1
            ? v?.payment.ownpayType?.toString()
            : '3',
          item?.paymentId,
          v.treatmentCategory,
          item?.treatment?.id,
          '2',
          '',
          list
        )
      })
  }

  //批量查询票据信息
  const batchQueryElecSetlCertInfoUpldRslt = () => {

    if (paymentIds.length == 0) {
      return
    }

    paymentIds.map((pId:string) => {
      simpleQueryElecSetlCertInfoUpldRslt('requestString',pId);
    })
  }

  const selfMedicalInformationUploadSubmit = (//
    serverType: serverType,
    certNo: string,
    invoiceCheckCode: string,
    invoiceCode: string,
    invoiceNumber: string,
    invoiceId: string,
    isThirdpart: string,
    nwbFlag: string,
    ownpayType: string,
    paymentId: string,
    treatmentCategory: string,
    treatmentId: string,
    elecSetlCertType: string,
    responseString?: string,
    dataList?:any
  ) => {
    setSubmitLoading(true)
    dispatch(
      selfMedicalInformationUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          certNo: certNo,
          invoiceCheckCode: invoiceCheckCode,
          invoiceCode: invoiceCode,
          invoiceNumber: invoiceNumber,
          isThirdpart: isThirdpart,
          nwbFlag: nwbFlag,
          ownpayType: ownpayType,
          paymentId: paymentId,
          treatmentCategory: treatmentCategory,
          treatmentId: treatmentId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {

        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '自费就医信息上传',
            },
            (call: any, err) => {
              if (!err) {
                selfMedicalInformationUploadSubmit(
                  'response',
                  certNo,
                  invoiceCheckCode,
                  invoiceCode,
                  invoiceNumber,
                  invoiceId,
                  isThirdpart,
                  nwbFlag,
                  ownpayType,
                  paymentId,
                  treatmentCategory,
                  treatmentId,
                  elecSetlCertType,
                  JSON.stringify(call.netmessage),
                  dataList
                )
              } else {
                oneClickUploadElecInvoice(dataList)
              }
            }
          )
        } else {
          let countdown = 3 // 3秒

          const timerId = setInterval(() => {
            countdown--
            if (countdown <= 0) {

              clearInterval(timerId)
              selfMedicalInformationUploadElecSetlCert(
                'requestString',
                elecSetlCertType,
                'ZIFEI',
                invoiceCheckCode,
                invoiceCode,
                '',
                invoiceId,
                invoiceNumber,
                isThirdpart,
                paymentId,
                '',
                dataList
              )
            }
          }, 1000)
        }
      })
      .catch(() => {
        oneClickUploadElecInvoice(dataList)
      })
  }

  const selfMedicalInformationUploadElecSetlCert = (
    serverType: serverType,
    elecSetlCertType: string,
    insuranceCode: string,
    invoiceCheckCode: string,
    invoiceCode: string,
    invoiceFile: string,
    invoiceId: string,
    invoiceNumber: string,
    isThirdpart: string,
    paymentId: string,
    responseString?: string,
    dataList?:any
  ) => {

    dispatch(
      selfMedicalInformationUploadElecSetlCertAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          elecSetlCertType: elecSetlCertType,
          insuranceCode: insuranceCode,
          invoiceCheckCode: invoiceCheckCode,
          invoiceCode: invoiceCode,
          invoiceFile: invoiceFile,
          invoiceId: invoiceId,
          invoiceNumber: invoiceNumber,
          isThirdpart: isThirdpart,
          paymentid: paymentId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '医疗机构上传电子结算凭证信息结果查询',
            },
            (call: any, err) => {
              if (!err) {
                selfMedicalInformationUploadElecSetlCert(
                  'response',
                  elecSetlCertType,
                  insuranceCode,
                  invoiceCheckCode,
                  invoiceCode,
                  invoiceFile,
                  invoiceId,
                  invoiceNumber,
                  isThirdpart,
                  paymentId,
                  JSON.stringify(call.netmessage),
                  dataList
                )
              } else {
                oneClickUploadElecInvoice(dataList)
              }
            }
          )
        } else {

          addPaymentId(paymentId)
          simpleQueryElecSetlCertInfoUpldRslt('requestString',paymentId)
          oneClickUploadElecInvoice(dataList)
        }
      })
      .catch(() => {
        oneClickUploadElecInvoice(dataList)
      })
  }

  function addPaymentId(paymentId: string) {

    paymentIds.push(paymentId)
    const copiedArray : string[] = Array.from(paymentIds);
    setPaymentIds(copiedArray)
  }

  const simpleQueryElecSetlCertInfoUpldRslt = (
    serverType: serverType,
    paymentId: string,
    responseString?: string
  ) => {
    dispatch(
      selfQueryElecSetlCertInfoUpldRsltAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          paymentid: paymentId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: null,
            },
            (call: any, err) => {
              !err &&
              simpleQueryElecSetlCertInfoUpldRslt(
                'response',
                paymentId,
                JSON.stringify(call.netmessage)
              )
              // if (err) {
              // }
            }
          )
        }
      })
  }

  // 医疗机构上传电子结算凭证信息结果查询
  const selfQueryElecSetlCertInfoUpldRslt = (
    serverType: serverType,
    paymentId: string,
    responseString?: string
  ) => {
    setSubmitLoading(true)
    setDisabled(true)
    dispatch(
      selfQueryElecSetlCertInfoUpldRsltAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          paymentid: paymentId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '医疗机构上传电子结算凭证信息结果查询',
            },
            (call: any, err) => {
              !err &&
                selfQueryElecSetlCertInfoUpldRslt(
                  'response',
                  paymentId,
                  JSON.stringify(call.netmessage)
                )
              if (err) {
                setDisabled(false)
              }
            }
          )
        } else {
          setSubmitLoading(false)
          setDisabled(false)
          getPageList()
        }
      })
      .catch(() => {
        setSubmitLoading(false)
        setDisabled(false)
      })
  }

  return (
    <>
      {contextHolder}
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 20px)',
        margin: '10px 20px',
        overflow: 'hidden',
        padding: '10px 15px 0 15px',
      }}
    >
      <Query
        state={3}
        onValueChange={(v) => {
          setParams({
            ...params,
            ...v,
            payMethod: null,
          })
        }}
        oneClickUpload={() => {

          const list = page.items.filter((item:any) => item.paymentInsuranceCode === 'ZIFEI' && item.invoiceUploadState === -1)
          if (list.length === 0) {
            notification.info({
              message: '今日没有可上传的数据',
            })
          }
          setPaymentIds([])
          setTotalUpload(list.length)
          setShowStopAction(true)
          oneClickUploadElecInvoice(list)
        }}
        certificateResultQuery={() => {
          setIsCertificateResultQueryVisible(true)
          setMessages({
            elecSetlCertType: '',
            invoiceCheckCode: '',
            invoiceCode: '',
            invoiceNumber: '',
          })
        }}
      />
      <EditableList
        style={{ flex: 1 }}
        scroll={{ x: '30rem' }}
        loading={pageLoading}
        page={{
          items: page.items,
          current: params.current,
          size: params.size,
          total: page.total,
        }}
        // rowSelection={
        //   {
        //     selectedRowKeys: selectedKeys,
        //     ...rowSelection,
        //   } as TableRowSelection<any>
        // }
        rowKey={(m: any) => m.paymentId}
        columns={Columns(
          params.size * (params.current - 1),
          disabled,
          (action, t) => {
            switch (action) {
              case 'upload':
                setIsMedicalInformationUploadVisible(true)
                setIds({
                  patientId: t?.registration?.patientId,
                  paymentId: t?.paymentId,
                  treatmentId: t?.treatment?.id,
                })
                setPaymentInsurance(t?.paymentInsuranceCode)
                break
              case 'retransmissionUpload':
                setIsRetransmissionMedicalInformationUploadVisible(true)
                setIds({
                  patientId: t?.registration?.patientId,
                  paymentId: t?.paymentId,
                  treatmentId: t?.treatment?.id,
                })
                setPaymentInsurance(t?.paymentInsuranceCode)
                break
              case 'lookDetail':
                setIsLookDetailVisible(true)
                setIds({
                  patientId: t?.registration?.patientId,
                  paymentId: t?.paymentId,
                  treatmentId: t?.treatment?.id,
                })
                setPaymentInsurance(t?.paymentInsuranceCode)
                break
              case 'certificateResultQuery':
                setIsCertificateResultQueryVisible(true)
                setMessages({
                  elecSetlCertType: t?.elecSetlCertType,
                  invoiceCheckCode: t?.invoiceCheckCode,
                  invoiceCode: t?.invoiceCode,
                  invoiceNumber: t?.invoiceNumber,
                })

                break
              case 'uploadResultQuery':
                selfQueryElecSetlCertInfoUpldRslt('requestString', t?.paymentId)

                break
            }
          }
        )}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size: size || 10 })
        }
        sticky
      />
      {/* 就医信息上传 */}
      <MedicalInformationUploadModal
        ids={ids}
        paymentInsurance={paymentInsurance}
        visible={isMedicalInformationUploadVisible}
        onOk={() => {
          setIsMedicalInformationUploadVisible(false)
          getPageList()
        }}
        onCancel={() => {
          setIsMedicalInformationUploadVisible(false)
          getPageList()
        }}
      ></MedicalInformationUploadModal>
      {/* 电子票据重传 */}
      <RetransmissionMedicalInformationUploadModal
        ids={ids}
        paymentInsurance={paymentInsurance}
        visible={isRetransmissionMedicalInformationUploadVisible}
        onOk={() => {
          setIsRetransmissionMedicalInformationUploadVisible(false)
        }}
        onCancel={() => {
          setIsRetransmissionMedicalInformationUploadVisible(false)
        }}
      ></RetransmissionMedicalInformationUploadModal>
      {/* 查看详情 */}
      <LookDetailModal
        ids={ids}
        paymentInsurance={paymentInsurance}
        visible={isLookDetailVisible}
        onOk={() => {
          setIsLookDetailVisible(false)
        }}
        onCancel={() => {
          setIsLookDetailVisible(false)
        }}
      ></LookDetailModal>
      <CertificateResultQueryModal
        visible={isCertificateResultQueryVisible}
        message={messages}
        onOk={() => {
          setIsCertificateResultQueryVisible(false)
        }}
        onCancel={() => {
          setIsCertificateResultQueryVisible(false)
        }}
      ></CertificateResultQueryModal>
    </Col>
    {showStopAction && (
      <div className={styles.stopAction}>
        正在进行今日电子票据上传，请勿离开页面 ({paymentIds.length}/{totalUpload})
      </div>
    )}
  </>
  )
}

export const ElectronicTicketUploads = forwardRef(RealElectronicTicketUploads)
