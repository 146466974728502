import {
  Button,
  Checkbox,
  Col,
  Form,
  notification,
  Row,
  Select,
} from "antd"
import React, { ReactElement, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch } from "../../../app/store"
import { Label } from "../../../compnents/widgets/Label"
import styles from "./personalSetting.module.css"
import { Settinglable } from "../../../models/personalSetting"
import { useQuery } from "../../../utils/HttpUtils"
import { NavBar } from "../../../compnents/nav/NavBar"
import _ from "lodash"
import { selectUserId } from "../../../app/applicationSlice"
import { Tenant } from "../../../models/tenant"
import { getShopType, setShopType } from "../personalDetail/personalDetailSlice"
import { unwrapResult } from "@reduxjs/toolkit"

export const UserPersonalSetting = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const query = useQuery()

  const userId = useSelector(selectUserId)

  const id = query.get("state") as string


  const { Option } = Select

  const [form] = Form.useForm()

  const [checkData, setCheckData] = useState<any>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [data, setData] = useState<string[]>([""])

  useEffect(() => {
    setTimeout(function () {
      setIsModalVisible(false)
    }, 3000)
  }, [dispatch, isModalVisible])

  useEffect(() => {
    setData(
      checkData.map((v: any) => {
        return v.codeName === "0" ? "" : v.codeName
      })
    )
  }, [checkData])

  const layout = {
    labelCol: {
      span: 2,
    },
    wrapperCol: {
      span: 22,
    },
  }

  useEffect(() => {
    dispatch(getShopType({ userId: Number(id) }))
      .then(unwrapResult)
      .then((res) => {
        if (res[2] && res[2].codeName) {
          form.setFieldsValue({
            3: true,
          })
        }
      })
  }, [userId])

  return (
    <>
      {id ? (
        <Col
          style={{
            margin: "10px 20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NavBar
            where={["员工管理", "设置"]}
            backtrace={{
              name: "机构管理",
              path: `/admin/tenant`,
              state: {
                station: "3",
              },
            }}
          />
        </Col>
      ) : (
        <></>
      )}
      <div className={id ? styles.mains : styles.main}>
        <Label label='用户功能设置信息' style={{ marginBottom: 10 }} />
        <Form
          style={{ height: "100%", position: "relative" }}
          form={form}
          {...layout}
          onFinish={(values) => {
            const pattern = /^[0-9]*$/
            const t = _.chain(values)
              .omitBy((_, k) => pattern.test(k))
              .value() as Tenant
            const f = _.chain(values)
              .pickBy((_, k) => pattern.test(k))
              .mapValues(
                (v, k) =>
                  (({
                    code: k,
                    codeName: v?.toString(),
                  } as unknown) as Settinglable)
              )
              .values()
              .value()[0]
            dispatch(setShopType({ ...f, userId: Number(id) }))
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: "设置成功",
                })
              })
          }}
        >
          <Form.Item label='商城' valuePropName='checked' name='3'>
            <Checkbox>启用</Checkbox>
          </Form.Item>
          <Row
            gutter={20}
            // style={{ position: "absolute", bottom: "60%", right: "50%" }}
          >
            <Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Button
                className={styles.buttom_submit}
                onClick={() => {
                  form.resetFields()
                }}
                size='middle'
              >
                取消
              </Button>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Button
                className={styles.buttom_submit}
                type='primary'
                htmlType='submit'
                size='middle'
              >
                保存
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}
