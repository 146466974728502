export function getHealthCodeColorName(healthCodeColor: number): string {
  switch (healthCodeColor) {
    case 0:
      return '绿色'
    case 1:
      return '黄色'
    case 2:
      return '红色'
  }
  return '-'
}
export function getTourCodeColorName(tourCode: number): string {
  switch (tourCode) {
    case 0:
      return '绿色'
    case 1:
      return '黄色'
    case 2:
      return '橙色'
    case 3:
      return '红色'
  }
  return '-'
}
