/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-29 20:07:33
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-05 14:43:21
 */
import { API_PREFIX } from "./constant";
import { Request } from "./request";


export interface QingdouParams {
  count?: number
  storehouseId?: number
  flag?: number
  current?: number
  name?: number
  size?: number
  tenantInventoryReceiptsId?: number
  tenantInventoryLedgerId?: number
  tenantMaterialsCatalogId?: number
}

export function getQingdoulist(
    params: QingdouParams
): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryledger/cleanlist`,
        method: "GET",
        params
    }
}

export function getbatchNo(
    params: QingdouParams
): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryledger/cleantarget`,
        method: 'GET',
        params
    }
}

export function submitbatch(
    Ids: Array<any>
): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/submitbatch`,
        method: "POST",
        body: Ids
    }
}