import {
    Button,
    Col,
    Collapse,
    Form,
    Modal,
    Row,
    Space,
    Table,
    message,
    notification,
    Input,
    Upload
} from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile, UploadProps } from 'antd/es/upload';
import React, { useEffect, useState } from 'react'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CaretUpFilled } from '@ant-design/icons'
// import { getExecuteState, selectNofarmulaDetail } from './operatingManagePageSlice'
import _, { divide } from 'lodash'
import styles from '../../nurse/formula/formulas/formula.module.css'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../app/store'
import { uploadFileAsync } from './beforeOperatingManagePageSlice'
import { getProofAsync } from './beforeOperatingManagePageSlice'
import moment from 'moment'
import {
    DateTimeFormat,
    DateTimeFormatSimple,
} from '../../../models/datetime'
import { API_PREFIX } from '../../../services/request';
export const ModelList = (props: {
    detail: any,
    treatmentId: string
    visible: boolean
    onOk: () => void
    onCancel: () => void
}): ReactElement => {
    const success = () => {
        message.success('操作成功')
    }
    const { Panel } = Collapse

    const dispatch = useDispatch<RootDispatch>()

    // const nofarmulaDetail = useSelector(selectNofarmulaDetail)

    const [nofarmulaDetails, setNofarmulaDetails] = useState<any[]>([])

    const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

    const [form] = Form.useForm()

    const { TextArea } = Input;

    const treatmentId = props.treatmentId

    const detail = props.detail

    // useEffect(() => {
    //     setNofarmulaDetails(nofarmulaDetail)
    // }, [nofarmulaDetail])
    const [previewTitle, setPreviewTitle] = useState('');

    const [fileList, setFileList] = useState<UploadFile[]>([])

    const [fileUrl, setFileUrl] = useState([])

    const [imgList, setImgList] = useState<UploadFile[]>([])

    const [imgUrl, setImgUrl] = useState([])

    const [previewOpen, setPreviewOpen] = useState(false);

    const [previewImage, setPreviewImage] = useState('');

    const fileChange: UploadProps['onChange'] = ({ file, fileList: newFileList }) => {
        setFileList(newFileList);
        if (file.status !== 'uploading') {
            const arr: any = [...fileUrl]
            arr.push(file.response.data)
            setFileUrl(arr)
        }
    }
    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleChange: UploadProps['onChange'] = ({ file, fileList: newFileList }) => {
        const size: any = file?.size
        if (size / 1024 / 1024 < 10) {
            setImgList(newFileList);
            if (file.status !== 'uploading') {
                const arr: any = [...imgUrl]
                arr.push(file?.response?.data)
                setImgUrl(arr)
            }

        }


    }
    const handleCancel = () => setPreviewOpen(false);

    const beforeUpload = (file: RcFile) => {
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error('图片大小不能超过 10M');
            return false
        }
    }
    const onFinish = (values: any) => {
        if (values.msg || fileUrl?.join(',')?.length || imgUrl?.join(',')?.length) {
            form.resetFields();
            dispatch(getProofAsync({
                attachment: fileUrl.join(','),
                urls: imgUrl.join(','),
                comment: values.msg,
                handleType: 5,
                operationId: props.treatmentId
            })).then(unwrapResult)
                .then(() => {
                    props.onCancel()
                    success()
                })
        } else {
            message.error('您未填写任何信息，请至少填写一项内容提交！')
        }

    }

    const uploadButton = (
        <div>
            <PlusOutlined />
        </div>
    );

    useEffect(() => {
        setFileList([])
        setImgUrl([])
        setFileUrl([])
        setImgList([])
    }, [detail])
    return (
        <>
            <Modal
                title='查看'
                width='800px'
                visible={props.visible}
                footer={null}
                style={{ top: '20px' }}
                onOk={() => {
                    props.onOk()
                }}
                onCancel={() => {
                    props.onCancel()
                }}
            >
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '400px',
                        marginLeft: '25px'
                    }}
                >   <Form
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off">
                        <Row style={{ marginTop: '10px', marginBottom: '16px', fontWeight: 700, fontSize: "16px" }}>
                            <Col span={12}>
                                <span>申诉信息</span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                            <Col span={12}>
                                <span>手术项目：{detail?.surgicalItems}</span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                            <Col span={12}>
                                <span>消费者姓名：{detail?.name}</span>
                            </Col>
                            <Col span={12}>
                                <span>手机号码：{detail?.mobile}</span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                            <Col span={12}>
                                <span>手术时间：{moment(detail?.surgicalDate).format(DateTimeFormatSimple)}</span>
                            </Col>
                            <Col span={12}>
                                <span>申诉时间：{moment(detail?.createTime).format(DateTimeFormatSimple)}</span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                            <Col span={12}>
                                <span>状态：{detail?.certifyingStatus == '1' ? '机构举证中' : (
                                    detail?.certifyingStatus == '4' ? '申诉人举证中' : '案件立案中'
                                )}</span>
                            </Col>
                            <Col span={12}>
                                <span>主刀医生：{detail?.opdoctor}</span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                            <Col span={24}>
                                <span>申诉描述：{detail?.comment}</span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px', marginBottom: '16px', fontWeight: 700, fontSize: "16px" }}>
                            <Col span={12}>
                                <span>举证信息</span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px', marginBottom: '16px' }} >
                            <Col span={3}>
                                <span>举证内容：</span>
                            </Col>
                            <Col span={18}>
                                <Form.Item name="msg"   >
                                    <TextArea allowClear rows={4} placeholder='请输入内容' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={3}>
                                <span>文件附件：</span>
                            </Col>
                            <Col span={18}>
                                <Row>
                                    <Col>
                                        <Upload action={`${API_PREFIX}/blade-outpatient/operatingbill/sqrz/simpleUpload`}
                                            fileList={fileList}
                                            onChange={fileChange}
                                            accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,.xls">
                                            <Button
                                                type='primary'
                                                style={{
                                                    backgroundColor: "#fff",
                                                    borderColor: '#027AFF',
                                                    color: '#027AFF',
                                                    position: 'relative'
                                                }}
                                            >
                                                上传附件
                                                <span style={{
                                                    fontSize: '12px', color: '#9D9D9D',
                                                    position: 'absolute', bottom: '0', right: '-132px'
                                                }}>支持word,Excel,txt,pdf;</span>
                                            </Button>
                                        </Upload>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '18px' }}>
                            <Col span={3}>
                                <span>图片附件：</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ fontSize: '12px', color: '#9D9D9D', marginLeft: '8px', marginTop: '10px' }}>
                                <span>支持图片,图片最多5张图片,格式为:jpg、png、jpeg等,单张照片5MB以内</span>
                            </Col>
                        </Row>
                        <Row gutter={3}>
                            <Col span={24} style={{ marginLeft: '8px', marginTop: '10px' }}>
                                <Upload
                                    action={`${API_PREFIX}/blade-outpatient/operatingbill/sqrz/simpleUpload`}
                                    listType="picture-card"
                                    fileList={imgList}
                                    onPreview={handlePreview}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {imgList.length >= 5 ? null : uploadButton}
                                </Upload>
                            </Col>
                        </Row>
                        <Row gutter={20} style={{ marginTop: '30px' }}>
                            <Col span={20}>
                                <Button
                                    type='primary'
                                    htmlType="button"
                                    style={{ float: 'right' }}
                                    onClick={() => {
                                        setFileList([])
                                        setImgList([])
                                        form.resetFields();
                                        props.onCancel()
                                    }}
                                >
                                    取消
                                </Button>
                            </Col>
                            <Col span={1}>
                                <Button
                                    type='primary'
                                    htmlType="submit"
                                // onClick={() => {
                                //     form.resetFields()
                                //     props.onCancel()

                                // }}
                                >
                                    确定
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Modal>
            <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    )
}
