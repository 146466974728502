/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-08 21:43:10
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-08 16:35:23
 */
import { Button, Col, Form, Input, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { Categories, CategoryName } from "../../models/category";
import { InventoryMergeParams } from "../../services/InventChange";
const { Option } = Select;

export const InventoryMergeQuery = (props: {
  onValueChange: (queries: InventoryMergeParams) => void;
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      name="control-hooks"
      autoComplete="off"
      onFieldsChange={(_, fs: any) => {
        props.onValueChange(form.getFieldsValue());
      }}
    >
      <Row gutter={10} wrap={false}>
        <Col span={6}>
          <Form.Item name="batchNo">
            <Input
              placeholder="请输入商品批号"
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="category">
            <Select allowClear placeholder="库房业务">
              {Categories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {CategoryName(v)}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2} offset={13}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", float: "right" }}
          >
            查询
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
