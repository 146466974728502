/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-26 09:46:17
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-04 17:12:09
 */

import { sum } from 'lodash'
import moment from 'moment'
import { BillingCategoryName } from './billingCategory'
import { DateTimeFormatSimpleOnlyOne } from './datetime'

//打印单
export function medicalOverviewPrintBody(data: any) {
  const tenantName = data?.tenantName
  const local = data?.local
  const dataList = data?.data

  const length = Object.keys(dataList).length //数据长度
  const newKeys2 = Object.keys(dataList)

  let dataStr = ''
  if (length > 0) {
    for (let i = 0; i < newKeys2.length; i++) {
      const childData = dataList[newKeys2[i]]
      const dataArrary = childData['recipeItems']
      let je = 0.0
      for (let j = 0; j < dataArrary.length; j++) {
        je += dataArrary[j].amount
      }
      const firstTd =
        '<td style="text-align:left;" rowspan="' +
        '">就诊日期：' +
        dataList[i].registrationTime +
        '<br>挂号医生：' +
        dataList[i].registrationDoctorName +
        '<br>接诊医生：' +
        dataList[i].treatmentDoctorName +
        '<br>疾病名称：' +
        dataList[i].disease +
        '<br>费用金额：' +
        dataList[i].amount +
        '</td>'
        
      for (let j = 0; j < dataArrary?.length; j++) {
        dataStr += '<tr>'
        if (j == 0) {
          dataStr += firstTd
        }
        dataStr +=
          '      <td>' +
          dataArrary[j].name +
          '</td>' +
          '      <td>' +
          dataArrary[j].name +
          '</td>' +
          '      <td>' +
          dataArrary[j].name +
          '</td>' +
          '      <td>' +
          dataArrary[j].name +
          '</td>' +
          '      <td>' +
          dataArrary[j].name +
          '</td>' +
          '      <td>' +
          dataArrary[j].name +
          '</td>' +
          '      <td>' +
          dataArrary[j].name +
          '</td>' +
          '      <td>' +
          dataArrary[j].name +
          '</td>' +
          '    </tr>'
      }
    }
  } else {
    dataStr += '<tr><td colspan="9"><h4>暂无就诊信息</h4></td></tr>'
  }
  const content =
    '<div style="font-size: 15px;">' +
    '<div style="padding: 10px 0">' +
    '<h3 style="text-align: center;margin-bottom: 5px;" >就诊总览</h3>' +
    '  <div style="padding:20px 0">' +
    '    <div style="width: 50%;float: left;text-align: left;"><span style="font-weight:bold;">定点单位: </span><span style="font-weight:normal;">' +
    tenantName +
    '</span></div>' +
    '    <div style="width: 50%;float: left;text-align: left;"><span style="font-weight:bold;">姓名: </span><span style="font-weight:normal;">' +
    local?.name +
    '</span></div>' +
    '  </div>' +
    '  <table border="1" cellspacing="0" cellpadding="0" style="width: 100%;background-color: #fff;color: #333;margin: 10px 0;display: table;border-collapse: collapse;border-spacing: 0;text-align: center;border: 1px solid #333">' +
    '    <thead>' +
    '    <tr style="font-weight:bold;height:30px;">' +
    '      <td>就诊信息</td>' +
    '      <td>用药/治疗</td>' +
    '      <td>规格</td>' +
    '      <td>剂型</td>' +
    '      <td>单位</td>' +
    '      <td>数量</td>' +
    '      <td>频次</td>' +
    '      <td>用法</td>' +
    '      <td>每次用量</td>' +
    '    </tr>' +
    '    </thead>' +
    '    <tbody>' +
    dataStr +
    '    </tbody>' +
    '  </table>' +
    '  <div style="padding:20px 0">' +
    '    <div style="width: 90%;float: left;text-align: right;"><span style="font-size: 14px">制表日期: ' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</span></div>' +
    '  </div>' +
    '</div>' +
    '</div>'

  return content
}
