/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-08-06 15:09:14
 */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ExclamationCircleFilled,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import {
  logoutorEnableExamineAsync,
  selectCurrent,
  setCurrent,
  setTargetId,
  pageExamineAsync,
  examineLogoutEnableListAsync,
  examineExportExcelAsync,
  copyALMUCatalogAsync,
} from './examinetreatSlice'
import { ExamineModal } from './modal'
import { SetMenuModal } from './setMenuModal'
import { Columns } from './columns'
import { DataType } from '../putstorage/putstorage'
import { TableRowSelection } from 'antd/lib/table/interface'
import { ExamineQuery } from './query'
import { examinePageParams } from '../../services/examine'
import {
  Button,
  Col,
  message,
  notification,
  Pagination,
  Row,
  Dropdown,
  Menu,
  Popconfirm,
  Space,
  Spin,
  Modal,
} from 'antd'
import { NavBar } from '../../compnents/nav/NavBar'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import {
  delCodeMedicalListAsync,
  getServerGoodsNo,
  revokeCatalogAsync,
  saveCodeMedicalListAsync,
  updateCatalogAsync,
  searchCategory,
  addCategory,
  deleteCategory,
} from '../commodity/commoditySlice'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectUserName,
  selectUserId,
  selectTenantCategory,
} from '../../app/applicationSlice'
import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { MedicalItem } from '../../models/material'
import { medicalSendHttp, strParse } from '../../utils/MedicalUtils'
import { MedicalModal } from '../commodity/medical/MedicalModal'
import styles from './Examinetreat.module.css'
import { PurchaseModal } from '../putstorelist/purchaseModal'
import { CommodityLastEditModal } from '../commodity/CommodityLastEditModal'

export const Examinetreat = (props: {
  isHome?: boolean // 首页消息通知弹框目录更新
}) => {
  // const [size, setSize] = useState(10)

  const dispatch = useDispatch<RootDispatch>()

  const [total, setTotal] = useState(0)

  const [data, setData] = useState([])

  const [info, setInfo] = useState<any>()

  // const Current = useSelector(selectCurrent)

  const [selectIds, setSelectIds] = useState<any>('')

  const [scrollHeight, setScrollHeight] = useState<any>()

  const [queries, setQueries] = useState<any>({
    state: 1,
    current: 1,
    size: 10,
    searchType:1
  })

  const [treatmentCategory, setTreatmentCategory] = useState('')

  const [IsModalVisible, setIsModalVisible] = useState(false)

  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>(
    'checkbox'
  )

  const [medicalModalVisible, setMedicalModalVisible] = useState(false)

  const [componentItem, setComponentItem] = useState<any>()

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const [selectRow, setSelectRow] = useState<any>([])

  const insuranceArray = useSelector(selectInsuranceArray)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [isPurchaseModalVisible, setIsPurchaseModalVisible] = useState(false)

  const [selectCatMappingIds, setSelectCatMappingIds] = useState<number[]>([])

  const [menuModalVisible, setMenuModalVisible] = useState(false) //新增类别弹窗是否显示

  const [categoryList, setCategoryList] = useState<any>() //类别数据

  const [categoryListLoading, setCategoryListLoading] = useState(false) // 类别加载

  const [bgcColor, setBgcColor] = useState<any>(null) // 类别背景颜色控制

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [btnLoading, setBtnLoading] = useState(false) //表格 loading

  const [lastEditVisable, setLastEditVisable] = useState(false)

  const [lastEditId, setLastEditId] = useState<any>()

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectIds(selectedRowKeys)
      setSelectRow(selectedRows)
    },
  }

  const medicalMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          cycleCode
            ? updataCatalog('requestString')
            : notificationInfo('请先签到')
        }}
      >
        上传勾选目录
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          cycleCode
            ? updataCatalog('requestString', true)
            : notificationInfo('请先签到')
        }}
      >
        上传本页全部目录
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          cycleCode
            ? revokeCatalog('requestString')
            : notificationInfo('请先签到')
        }}
      >
        撤销勾选目录
      </Menu.Item>
    </Menu>
  )

  const success = (str: string) => {
    const hide = message.loading(str, 0)
    // Dismiss manually and asynchronously
    setTimeout(hide, 1500)
  }

  const notificationInfo = (str: string) => {
    notification.info({
      message: str,
    })
  }

  const getPageData = () => {
    setPageLoading(true)
    onResize() // 初始化设置高度
    window.addEventListener('resize', onResize)
    dispatch(
      pageExamineAsync({
        ...queries,
        current: queries.current,
        treatmentCategory,
        insuranceCode:
          queries.insuranceCode || insuranceArray[0]?.insuranceCode,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setTotal(res.total)
        setData(res.records)
      })
      .finally(() => setPageLoading(false))
  }

  const useChange = () => {
    if (selectIds.length == 0) {
      notification.info({
        message: '请先选择商品',
      })
    } else {
      dispatch(
        examineLogoutEnableListAsync({
          state: queries.state ? 0 : 1,
          ids: selectIds.join(','),
        })
      )
        .then(unwrapResult)
        .then(() => {
          setQueries({
            ...queries,
            current: 1,
          })
        })
    }
  }

  // 上传医保数据
  const updataCatalog = async (serverType: any, isAll?: boolean) => {
    const catMappingIds = (isAll ? data : selectRow)
      .map((v: any) => {
        if (v.mappingId) {
          if (v.approveFlg !== '1') {
            return v.mappingId
          } else {
            return
          }
        } else {
          return
        }
      })
      .filter((v: number) => v)
    const promiseData: any = []
    if (catMappingIds.length) {
      await catMappingIds.forEach((v: number) => {
        // updataCatalogItem(serverType, v)
        promiseData.push(
          new Promise((resolve, reject) => {
            updataCatalogItem(serverType, v, resolve, reject)
          })
        )
      })
      success('上传中')
      Promise.allSettled(promiseData).then((settled: any) => {
        const success: any = settled.filter(
          (v: any) => v.status === 'fulfilled'
        )
        const error: any = settled.filter((v: any) => v.status === 'rejected')
        const msgContent = (
          <div>
            上传完毕，本次共上传{settled.length}条，成功{success.length}条，失败
            {error.length}条，
            {error.length > 0 ? (
              <>
                <Row>详细信息如下：</Row>
                {error.map((v: any, i: number) => {
                  if (!v?.reason?.message) {
                    return <div>{v?.reason}</div>
                  } else {
                    return (
                      <div
                        key={i}
                        dangerouslySetInnerHTML={{ __html: v?.reason?.message }}
                      ></div>
                    )
                  }
                })}
              </>
            ) : (
              ''
            )}
          </div>
        )
        if (success.length == 0) {
          notification.error({
            message: msgContent,
            duration: 3,
          })
        } else if (success.length == settled.length) {
          notification.success({
            message: msgContent,
            duration: 3,
          })
        } else {
          notification.warn({
            message: msgContent,
            duration: 3,
          })
        }
        getPageData()
        setSelectIds([])
        setSelectRow([])
      })
    } else {
      notificationInfo('请选择已匹配医保代码商品，和未上传商品')
    }
  }

  const updataCatalogItem = (
    serverType: any,
    catMappingId: number,
    resolve: any,
    reject: any
  ) => {
    dispatch(
      updateCatalogAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
          },
          catMappingIds: [catMappingId],
        },
      })
    )
      .then(unwrapResult)
      .then(async (res: string) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data.data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                updataCatalogItem('response', catMappingId, resolve, reject)
              }
            },
            undefined,
            reject
          )
        } else {
          resolve()
        }
      })
      .catch((err) => {
        reject(err)
      })
  }

  // 撤销上传
  const revokeCatalog = async (serverType: any) => {
    const catMappingIds = selectRow
      .map((v: any) => {
        if (v.mappingId) {
          if (v.approveFlg === '1') {
            return v.mappingId
          } else {
            return
          }
        } else {
          return
        }
      })
      .filter((v: number) => v)
    const promiseData: any = []
    if (catMappingIds.length) {
      await catMappingIds.forEach((v: number) => {
        promiseData.push(
          new Promise((resolve, reject) => {
            revokeCatalogItem(serverType, v, resolve, reject)
          })
        )
      })
      success('撤销中')
      Promise.allSettled(promiseData).then((settled: any) => {
        const success: any = settled.filter(
          (v: any) => v.status === 'fulfilled'
        )
        const error: any = settled.filter((v: any) => v.status === 'rejected')
        const msgContent = (
          <div>
            撤销完毕，本次共撤销{settled.length}条，成功{success.length}条，失败
            {error.length}条，
            {error.length > 0 ? (
              <>
                <Row>详细信息如下：</Row>
                {error.map((v: any, i: number) => {
                  if (!v?.reason?.message) {
                    return <div>{v?.reason}</div>
                  } else {
                    return (
                      <div
                        key={i}
                        dangerouslySetInnerHTML={{ __html: v?.reason?.message }}
                      ></div>
                    )
                  }
                })}
              </>
            ) : (
              ''
            )}
          </div>
        )
        if (success.length == 0) {
          notification.error({
            message: msgContent,
            duration: 3,
          })
        } else if (success.length == settled.length) {
          notification.success({
            message: msgContent,
            duration: 3,
          })
        } else {
          notification.warn({
            message: msgContent,
            duration: 3,
          })
        }
        getPageData()
        setSelectIds([])
        setSelectRow([])
      })
    } else {
      notificationInfo('请选择已匹配医保代码商品，和已上传商品')
    }
  }

  const revokeCatalogItem = (
    serverType: any,
    catMappingId: number,
    resolve: any,
    reject: any
  ) => {
    dispatch(
      revokeCatalogAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
          },
          catMappingIds: [catMappingId],
        },
      })
    )
      .then(unwrapResult)
      .then(async (res: string) => {
        if (serverType === 'requestString') {
          const data = JSON.parse(res.replace(/\\"/g, '"'))
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data.data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                revokeCatalogItem('response', catMappingId, resolve, reject)
              }
            },
            undefined,
            reject
          )
        } else {
          resolve()
        }
      })
      .catch((err) => {
        reject(err)
      })
  }

  // 匹配医保目录
  const saveMedicalCode = (item: MedicalItem) => {
    dispatch(
      saveCodeMedicalListAsync({
        billing_category: componentItem.billingCategory,
        hilist_code: item?.hiListCode,
        insuranceCode: queries.insuranceCode || insuranceArray[0].insuranceCode,
        is_reimbursement: item?.isCouldBx,
        local_item_id: String(componentItem?.id),
        limitId: item.limitId,
        rid: item?.rid,
        proviceHilistCode: item?.proviceHilistCode,
      })
    )
      .then(unwrapResult)
      .then(() => {
        getPageData()
        notification.success({
          message: '匹配成功',
        })
      })
  }

  // 撤销匹配医保目录
  const delNedicalCode = (item: any) => {
    if (item.approveFlg !== '1') {
      dispatch(
        delCodeMedicalListAsync({
          insuranceCode:
            queries.insuranceCode || insuranceArray[0].insuranceCode,
          mapping_id: String(item?.mappingId),
        })
      )
        .then(unwrapResult)
        .then(() => {
          getPageData()
          notification.success({
            message: '撤销匹配成功',
          })
        })
    } else {
      notification.info({
        message: '不可删除已上传的匹配记录!',
      })
    }
  }

  useEffect(() => {
    if (insuranceArray.length) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  useEffect(() => {
    const insuranceItem = insuranceArray.find(
      (v) => v.insuranceCode == queries.insuranceCode
    )
    if (insuranceItem) {
      setInsuranceItem(insuranceItem)
    }
    getPageData()
  }, [queries])

  useEffect(() => {
    return () => {
      setSelectIds([])
    }
  }, [queries.state])

  useEffect(() => {
    //获取类别分类
    setCategoryListLoading(true)
    dispatch(searchCategory())
      .then(unwrapResult)
      .then((res) => {
        setCategoryList([
          {
            name: '全部',
            id: '1234567894562',
          },
          ...res,
        ])
        setCategoryListLoading(false)
      })
      .catch(() => {
        setCategoryListLoading(false)
      })
  }, [menuModalVisible])

  const outExportExcel = () => {
    setBtnLoading(true)
    dispatch(
      examineExportExcelAsync({
        ...queries,
        current: queries.current,
        treatmentCategory,
        insuranceCode:
          queries.insuranceCode || insuranceArray[0]?.insuranceCode,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '诊疗管理.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .finally(() => setBtnLoading(false))
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const onResize = () => {
    const table = document.getElementById('tableHeightExaminetreat')
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 10
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {!props.isHome && (
        <Row gutter={10} justify='space-between'>
          <Space>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              className={styles.add}
              onClick={() => {
                setIsModalVisible(true), dispatch(getServerGoodsNo())
              }}
            >
              新增诊疗
            </Button>
            <Dropdown overlay={medicalMenu} trigger={['click']}>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                className={styles.add}
              >
                医保操作<i className={styles.icon}></i>
              </Button>
            </Dropdown>
          </Space>

          <Space>
            <Button
              type='primary'
              loading={btnLoading}
              onClick={outExportExcel}
            >
              导出
            </Button>
            <Button
              type='primary'
              onClick={() => {
                setIsPurchaseModalVisible(true)
              }}
            >
              导入诊疗目录
            </Button>
            <Popconfirm
              title='确认进行此操作？'
              onConfirm={() => {
                useChange()
              }}
              okText='确认'
              cancelText='取消'
              placement='left'
            >
              <Button
                type='primary'
                // onClick={useChange}
              >
                {queries.state ? '注销' : '启用'}
              </Button>
            </Popconfirm>
          </Space>
        </Row>
      )}
      <div style={{ display: 'flex', height: '100%' }}>
        {!props.isHome && (
          <div className={styles.menuWrap}>
            <Spin spinning={categoryListLoading}>
              <div className={styles.menuTitle}>
                <span>类别</span>
                <span
                  className={styles.menuTitleIcon}
                  onClick={() => setMenuModalVisible(true)}
                >
                  <SettingOutlined />
                </span>
              </div>
              {categoryList &&
                categoryList.map((item: any, index: number) => {
                  return (
                    <div
                      key={item.id}
                      className={styles.menuContent}
                      style={{ background: index == bgcColor ? '#d3dcf3' : '' }}
                      onClick={() => {
                        setBgcColor(index)
                        setQueries({
                          ...queries,
                          current: 1,
                        })
                        item.name == '全部'
                          ? setTreatmentCategory('')
                          : setTreatmentCategory(item.name)
                      }}
                    >
                      {item.name}
                    </div>
                  )
                })}
            </Spin>
          </div>
        )}

        <div
          style={{
            flex: 1,
            width: '50%',
            height: props.isHome ? '100%' : 'calc(100% - 60px)',
            margin: '10px 0',
            padding: '20px',
            background: '#fff',
            borderRadius: '10px',
          }}
        >
          <ExamineQuery
            onValueChange={(v) => {
              // setBgcColor(null) //取消类别颜色
              dispatch(setCurrent(1))
              setQueries({ ...queries, ...v, current: 1 })
            }}
          />{' '}
          <div
            id='tableHeightExaminetreat'
            style={{ width: '100%', height: '100%', overflowY: 'hidden' }}
          >
            <EditableList
              scroll={{ y: Number(scrollHeight), x: '30rem' }}
              style={{
                width: '100%',
                overflow: 'hidden',
              }}
              className={styles.examinetreatTable}
              loading={pageLoading}
              page={{
                items: data,
                current: queries.current,
                size: queries.size,
                total,
              }}
              onChangePage={(current, size) => {
                setQueries({ ...queries, current, size })
              }}
              columns={Columns((id, text, item) => {
                if (text === 'edit') {
                  dispatch(setTargetId(id))
                  setInfo({ ...item, ...insuranceItem })
                  setIsModalVisible(true)
                } else if (text === 'loE') {
                  dispatch(logoutorEnableExamineAsync(id)).then(() => {
                    getPageData()
                  })
                } else if (text === 'insurance') {
                  if (item.approveFlg !== '1') {
                    setComponentItem(item)
                    setMedicalModalVisible(true)
                  } else {
                    notification.info({
                      message: '不可修改已上传的匹配记录!',
                    })
                  }
                  // medicalSendHttp("")
                } else if (text === 'delCode') {
                  delNedicalCode(item)
                } else if (text === 'copyCatalog') {
                  dispatch(copyALMUCatalogAsync(id))
                    .then(unwrapResult)
                    .then((res: any) => {
                      Modal.confirm({
                        centered: true,
                        title: '确认操作',
                        content: (
                          <div>
                            <ExclamationCircleFilled
                              style={{ color: '#FFAE55', fontSize: 18 }}
                            />
                            &nbsp;复制成功，是否继续编辑新的目录？
                          </div>
                        ),
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: () => {
                          dispatch(setTargetId(res?.id))
                          setInfo({
                            ...res,
                            ...insuranceItem,
                            copyId: res?.id,
                            copyFlag: true,
                          })
                          setIsModalVisible(true)
                        },
                        onCancel: () => {
                          getPageData()
                        },
                      })
                    })
                } else if (text === 'lastEditOperation') {
                  setLastEditVisable(true)
                  setLastEditId(id)
                }
              }, queries.size * (queries.current - 1))}
              bordered={false}
              rowSelection={
                {
                  type: selectionType,
                  selectedRowKeys: selectIds,
                  ...rowSelection,
                } as TableRowSelection<any>
              }
            />
          </div>
          {/* <Pagination
        total={total}
        current={Current}
        pageSize={size}
        showSizeChanger
        showQuickJumper
        showTotal={(total) => `共 ${total} 条`}
        style={{
          position: 'absolute',
          bottom: '40px',
          right: '40px',
        }}
        onChange={(current, size) => {
          setSize(size as number)
          dispatch(setCurrent(current))
        }}
      /> */}
        </div>
      </div>
      <ExamineModal
        info={info}
        categoryList={categoryList}
        visible={IsModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
          getPageData()
        }}
        onOk={() => {
          setIsModalVisible(false)
          getPageData()
        }}
      />
      <MedicalModal
        visible={medicalModalVisible}
        onOk={() => {
          setMedicalModalVisible(false)
        }}
        onCancel={() => {
          setMedicalModalVisible(false)
        }}
        onRecord={(item) => {
          setMedicalModalVisible(false)
          saveMedicalCode(item)
        }}
        componentItem={{
          ...componentItem,
          insuranceCode: queries?.insuranceCode,
        }}
      />
      <PurchaseModal
        isPurchaseModalVisible={isPurchaseModalVisible}
        state={3}
        refresh={() => {
          getPageData()
        }}
        onOk={() => {
          setIsPurchaseModalVisible(false)
        }}
        onCancel={() => {
          setIsPurchaseModalVisible(false)
        }}
      />
      <SetMenuModal
        visible={menuModalVisible}
        categoryList={categoryList}
        onCancel={() => {
          setMenuModalVisible(false)
        }}
        onOk={(data) => {
          const params = data.map((item: any, index: any) => {
            return {
              id: item.id,
              name: item.name,
              sortNo: index + 1,
            }
          })
          dispatch(addCategory(params))
            .then(unwrapResult)
            .then((res) => {
              setMenuModalVisible(false)
            })
        }}
        onDelete={(id: number) => {
          // dispatch(deleteCategory({ id })).then(unwrapResult).then(res => {
          //   setMenuModalVisible(false)
          // })
        }}
      />
      <CommodityLastEditModal
        visible={lastEditVisable}
        title={'最后一次修改'}
        id={lastEditId}
        onOk={() => {
          setLastEditVisable(false)
          setLastEditId('')
        }}
        onCancel={() => {
          setLastEditVisable(false)
          setLastEditId('')
        }}
        operateType={2}
      />
    </div>
  )
}
