import { Tabs, TabsProps } from "antd";
import React, { ReactElement, useContext } from "react";
import { ThemeContext } from "../../theme/ThemeContext";
import defaultStyles from "./RoundTabBar.Default.module.css";
import styles from "./RoundTabBar.module.css";

export const { TabPane } = Tabs;

export const RoundTabBar = ({
  className,
  ...others
}: TabsProps): ReactElement => {
  const theme = useContext(ThemeContext);
  return (
    <Tabs
      className={`${className} ${styles.tabs} ${defaultStyles.tabs}`}
      tabBarStyle={{
        borderTopLeftRadius: theme.br,
        borderTopRightRadius: theme.br,
        backgroundColor: theme.c1,
        marginBottom: 0,
      }}
      {...others}
    ></Tabs>
  );
};
