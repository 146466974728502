/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-12-13 11:17:48
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-15 15:55:30
 *
 *  */
import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'

// 对账记录列表
export interface ReconciliationListParams {
  current: number
  size: number
}
export function reconciliationList(params: ReconciliationListParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getAccountMonthlySummaryList`,
    method: 'GET',
    params,
  }
}

// 月对账
// 汇总上传
export interface SelfMonthSummaryUploadBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function selfMonthSummaryUpload(
  bodyName: SelfMonthSummaryUploadBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/monthlySummary_uploadData_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 上传查询
export interface SelfMonthSummaryUploadInfoBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function selfMonthSummaryUploadInfo(
  bodyName: SelfMonthSummaryUploadInfoBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/monthlySummaryUploadInfoQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 删除
export interface SelfMonthSummaryUploadInfoDeleteBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
    solidifyIdList: any
  }
}
export function selfMonthSummaryUploadInfoDelete(
  bodyName: SelfMonthSummaryUploadInfoDeleteBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/monthlySummaryUploadInfoDeletion_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 上传确认
export interface SelfMonthSummaryUploadInfoConfirmBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
    solidifyIdList: any

  }
}
export function selfMonthSummaryUploadInfoConfirm(
  bodyName: SelfMonthSummaryUploadInfoConfirmBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/monthlySummaryUploadInfoConfirm_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 月对账
export interface SelfMonthReconciliationBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function selfMonthReconciliation(
  bodyName: SelfMonthReconciliationBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/monthlyReconciliation_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 月对账明细查询
export interface SelfMonthReconciliationInfoBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function selfMonthReconciliationInfo(
  bodyName: SelfMonthReconciliationInfoBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/monthlyReconciliationInfoQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 日对账
// 汇总上传
export interface SelfDaySummaryUploadBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function selfDaySummaryUpload(
  bodyName: SelfDaySummaryUploadBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/dailySummaryUploadData_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 上传查询
export interface SelfDaySummaryUploadInfoBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function selfDaySummaryUploadInfo(
  bodyName: SelfDaySummaryUploadInfoBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/dailySummaryUploadInfoQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 删除
export interface SelfDaySummaryUploadInfoDeleteBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
    solidifyIdList:any
  }
}
export function selfDaySummaryUploadInfoDelete(
  bodyName: SelfDaySummaryUploadInfoDeleteBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/dailySummaryUploadInfoDeletion_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 上传确认
export interface SelfDaySummaryUploadInfoConfirmBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
    solidifyIdList:any
  }
}
export function selfDaySummaryUploadInfoConfirm(
  bodyName: SelfDaySummaryUploadInfoConfirmBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/dailySummaryUploadInfoConfirm_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 日对账
export interface SelfDayReconciliationBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function selfDayReconciliation(
  bodyName: SelfDayReconciliationBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/dailyReconciliation_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 月对账明细查询
export interface SelfDayReconciliationInfoBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function selfDayReconciliationInfo(
  bodyName: SelfDayReconciliationInfoBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/dailyReconciliationInfoQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 结算明细对账
export interface SelfDetailedReconciliationOfSettlementInfoBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function SelfDetailedReconciliationOfSettlementInfo(
  bodyName: SelfDetailedReconciliationOfSettlementInfoBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/detailedReconciliationOfSettlementInfo_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

//无卡退费
export interface NoCardRefundBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    setlId: string
    mdtrtId: string
  }
}

export function NoCardRefund(
  bodyName: NoCardRefundBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/noCardRefund_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 结算明细对账导出
export interface SelfDetailedReconciliationOfSettlementInfoExportBody {
  dataString: any
}
export function selfDetailedReconciliationOfSettlementInfoExport(
  body: SelfDetailedReconciliationOfSettlementInfoExportBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/detailedReconciliationDataExport`,
    method: 'POST',
    responseType: 'blob',
    body,
  }
}

// 申报
export interface SettlementExpenseReportBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function SettlementExpenseReport(
  bodyName: SettlementExpenseReportBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/settlementExpenseReport_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 申报撤销
export interface SettlementChargeReversalBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
    upldBtchNo: string
  }
}
export function SettlementChargeReversal(
  bodyName: SettlementChargeReversalBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/settlementChargeReversal_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 结付信息查询_
export interface PaymentInformationQueryBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    accountDate: string
  }
}
export function PaymentInformationQuery(
  bodyName: PaymentInformationQueryBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/paymentInformationQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
