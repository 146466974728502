/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-15 11:39:14
 * @LastEditors: K
 * @LastEditTime: 2022-08-24 13:51:16
 */
import React, { ReactElement, useRef } from "react";
import { useLocation } from "react-router-dom";
import { TabBar, TabPane } from "../../compnents/widgets/TabBar";
import { CompletedTreatment } from "./completedTreatment/completedTreatment";
import { ToBeCompletedTreatment } from "./toBeCompletedTreatment/toBeCompletedTreatment";

export const MedicalSation = (props: {
  patientId?: string
}): ReactElement => {
  const location: any = useLocation();

  return (
    <TabBar
      style={{ margin: props.patientId ? "0 " : "10px 20px" }}
      defaultActiveKey={location.state ? location.state.selectKey : "0"}
    >
      <TabPane key="0" tab="待完成诊疗">
        <ToBeCompletedTreatment patientId={props.patientId} />
      </TabPane>
      <TabPane key="1" tab="已完成诊疗">
        <CompletedTreatment patientId={props.patientId} />
      </TabPane>
    </TabBar>
  );
};
