/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-12-22 11:07:13
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

type SettingsKey = string

type SettingsValue = string | number | boolean

type Settings = Record<SettingsKey, SettingsValue>

interface SettingsState {
  settings: Settings
}

const LocalSettingsKey = 'local_settings_key'

const initialState = {
  settings: (function (): Settings {
    try {
      const json = localStorage.getItem(LocalSettingsKey) || ''
      return JSON.parse(json)
    } catch {
      return {}
    }
  })(),
} as SettingsState

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<Settings>) => {
      const settings = {
        ...state.settings,
        ...action.payload,
      }
      state.settings = settings
      localStorage.setItem(LocalSettingsKey, JSON.stringify(settings))
    },
  },
})

export const { setSettings } = settingsSlice.actions

export const selectStringSetting = (key: string) => (
  state: RootState
): string | undefined => state.settings.settings[key] as string

export const selectNumberSetting = (key: string) => (
  state: RootState
): number | undefined => state.settings.settings[key] as number

export const selectBooleanSetting = (key: string) => (
  state: RootState
): boolean => !!state.settings.settings[key]

export default settingsSlice.reducer
