/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-08-04 14:56:24
 * @LastEditors: sj
 * @LastEditTime: 2022-12-12 16:19:32
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface getSendMessageListFnParams {
  templateName?: string
  startTime?: string
  endTime?: string
  current?: number
  size?: number
}
export function getSendMessageListFn(
  params: getSendMessageListFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/message/smsrecord/list`,
    method: 'GET',
    params,
  }
}

export function getSmsTemplateType(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/message/getSmsTemplateType`,
    method: 'GET',
  }
}
