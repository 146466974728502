/* eslint-disable @typescript-eslint/no-empty-function */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification, Row, Select } from 'antd'
import { unwatchFile } from 'fs'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserId } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  getDoctorsInDepartment,
  getOutpatientDepartment,
  setDoctors,
  selectDepartments,
  selectDoctors,
  getChangeRegisterAppointData,
} from '../register/registerSlice'

interface RegisterNoAppointModalProps {
  remark?: any
  id?:any
  visible?: boolean
  onCancel?: () => void
  onOk?: () => void
}

export const RegisterNoAppointModal = ({
  remark,
  id,
  visible,
  onCancel = () => {},
  onOk = () => {},
}: RegisterNoAppointModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const departments = useSelector(selectDepartments)

  const doctors = useSelector(selectDoctors)

  const userId = useSelector(selectUserId)

  const required = {
    rules: [
      {
        required: true,
      },
    ],
  }

  useEffect(() => {
    if (visible) {
      dispatch(getOutpatientDepartment())
    }
  }, [dispatch, visible])

  //0.取最新的偏好设置内容
  const localData: any = JSON.parse(
    localStorage.getItem('preferencesSettingData') || '{}'
  )
  const atDoctor = localData?.atDoctor?.toString() ? localData?.atDoctor : ''

  const storedepartmentId: any = localStorage.getItem(
    'registrationDepartmentId'
  )

  useEffect(() => {
    if (visible) {
      if (departments.find((d) => d.id == storedepartmentId)) {
        if (atDoctor == 0) {
          form.setFieldsValue({
            registrationDepartmentId: undefined,
            registrationDoctorId: undefined,
          })
        } else if (atDoctor == 1) {
          dispatch(getDoctorsInDepartment(storedepartmentId))
          if (departments.find((d) => d.id == storedepartmentId)) {
            form.setFieldsValue({
              registrationDepartmentId: storedepartmentId,
            })
          }
          form.setFieldsValue({ registrationDepartmentId: storedepartmentId })
        } else if (atDoctor == 2) {
          dispatch(getDoctorsInDepartment(localData?.departmentId))
          if (departments.find((d) => d.id == localData?.departmentId)) {
            form.setFieldsValue({
              registrationDepartmentId: localData?.departmentId,
            })
          }
          form.setFieldsValue({ registrationDoctorId: localData?.doctorId })
        }
      }
    }
  }, [departments, visible])

  useEffect(() => {
    dispatch(setDoctors(doctors))
  }, [departments, doctors])

  useEffect(() => {
    if (visible) {
      if (doctors && doctors.length == 1) {
        form.setFieldsValue({
          registrationDoctorId: doctors[0].id,
        })
      } else {
        const storeuserId =
          localStorage.getItem('registrationDoctorId') || userId
        if (doctors.find((u) => u.id == storeuserId)) {
          if (atDoctor == 0) {
            return
          } else if (atDoctor == 1 || !atDoctor) {
            form.setFieldsValue({ registrationDoctorId: storeuserId })
          }
        }
      }
    }
  }, [doctors, visible])

  return (
    <>
      <Modal
        title='指定科室医生'
        width={600}
        visible={visible}
        footer={null}
        onCancel={() => {
          onCancel()
          dispatch(setDoctors([]))
        }}
        onOk={(()=>{
          onOk()
        })}
      >
        <Form
          form={form}
          autoComplete='off'
          colon={false}
          labelAlign='left'
          onFinish={(values: any) => {
            dispatch(
              getChangeRegisterAppointData({
                doctorId: values?.registrationDoctorId,
                registrationId: id,
              })
            )
              .then(unwrapResult)
              .then((v) => {
                notification.success({
                  message: '操作成功',
                })
                onOk()
              })
          }}
          style={{
            flex: 1,
            marginTop: 10,
            paddingBottom: 10,
            overflow: 'auto',
          }}
        >
          <Row style={{ margin: '0 0 20px 10px' }}>
            <Col span={24}>备注：{remark}</Col>
          </Row>
          <Row>
            <Col span={11}>
              <Form.Item
                label='医生科室'
                name='registrationDepartmentId'
                {...required}
              >
                <Select
                  placeholder='请选择'
                  onChange={(id: string) => {
                    form.setFieldsValue({
                      registrationDoctorId: undefined,
                    })
                    dispatch(setDoctors([]))
                    dispatch(getDoctorsInDepartment(id))
                  }}
                >
                  {departments.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={11} style={{ marginLeft: '20px' }}>
              <Form.Item
                label='接诊医生'
                name='registrationDoctorId'
                {...required}
              >
                <Select placeholder='请选择' showSearch>
                  {doctors.map((d) => (
                    <Select.Option
                      className={d.mnemonicCode}
                      key={d.id}
                      value={d.id}
                    >
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
              marginTop: '60px',
            }}
          >
            <Button
              style={{ marginRight: '20px', color: '#666666' }}
              onClick={() => {
                onCancel()
                dispatch(setDoctors([]))
                form.resetFields()
              }}
            >
              取消
            </Button>
            <Button
              style={{ background: '#027AFF', color: '#FFFFFF' }}
              htmlType='submit'
            >
              确定
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
