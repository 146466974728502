import { DatePicker, Row, Form, Modal, Col, notification } from "antd";
import moment from "moment";
import React, { ReactElement, useState } from "react";
import { } from "antd";
import { useDispatch } from "react-redux";
import { getStatementList, getStatementStates } from "./statementSlice";
import { DateTimeFormat, DateTimeFormatSimple } from "../../../models/datetime";
import { RootDispatch } from "../../../app/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { statementParams } from "../../../services/statement";

export const StatementModal = (props: {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;

}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm();

  const time = moment().format(DateTimeFormatSimple)
  
  const  disabledDate = (timeTail:any)=> {
    return timeTail > moment().endOf('day');
  }

  return (
    <>
      <Modal
        title="一键日结"
        width="500px"
        okText="确定"
        cancelText="取消"
        visible={props.visible}
        onOk={(e) => {
          form
            .validateFields()
            .then((values) => {
              const format = "YYYY-MM-DD";
              const startTime =
                values.startTime && values.startTime.format(format);
              const endTime = values.endTime && values.endTime.format(format);
              const timeHead = moment().format(format);
              const timeTail = moment().format(format)
              dispatch(
                getStatementStates({
                  timeHead: startTime || timeHead,
                  timeTail: endTime || timeTail,
                }
                )
              )
                .then(unwrapResult)
                .then(() => {
                  notification.success({ message: '一键日结成功', duration: 3 });
                  form.resetFields();
                  props.onOk()
                })
                .catch(() => {
                  notification.error({ message: '一键日结失败', duration: 3 });
                })
            }
            )
            .catch(() => {

              // Do nothing.
            });
        }}
        onCancel={() => {
          form.resetFields();
          props.onCancel();
        }}
      >
        <Form
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          form={form}

        >
          <Row
            style={{ marginBottom: 10, width: "100%" }}
            align="middle"
            justify="space-between"
          >
            <Col>请选择日结时期:</Col>
            <Col style={{ marginTop: "20px" }}>
              <Form.Item name="startTime">
                {/* <DatePicker placeholder="请选择开始时间" format="YYYY-MM-DD" disabledDate={disabledDate} defaultValue={moment(`${time}`, 'YYYY/MM/DD')} /> */}
                <DatePicker placeholder="请选择开始时间" format="YYYY-MM-DD" defaultValue={moment(`${time}`, 'YYYY/MM/DD')} />
              </Form.Item>
            </Col>
            <Col style={{ marginTop: "20px" }}>
              <Form.Item name="endTime">
                {/* <DatePicker placeholder="请选择结束时间" format="YYYY-MM-DD " disabledDate={disabledDate} defaultValue={moment(`${time}`, 'YYYY/MM/DD')} /> */}
                <DatePicker placeholder="请选择结束时间" format="YYYY-MM-DD " defaultValue={moment(`${time}`, 'YYYY/MM/DD')} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
