import { CaretDownFilled, SmileOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
} from 'antd'
import { lowerFirst } from 'lodash'
import moment from 'moment'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CREDENTIAL_KEY,
  selectUserDepartmentId,
} from '../../../../app/applicationSlice'
import { RootDispatch } from '../../../../app/store'
import {
  BoyIcon,
  FemaleFilled,
  GirlIcon,
  MaleFilled,
} from '../../../../compnents/icons/Icons'
import { Label } from '../../../../compnents/widgets/Label'
import {
  appointmentStatusOptions,
  getAppointTypeName,
} from '../../../../models/appointment'
import { DateTimeFormatSimple } from '../../../../models/datetime'
import { getSexData } from '../../../../models/ToothHome'
import { DoctorsTimeStepParams } from '../../../../services/user'
import { ThemeContext } from '../../../../theme/ThemeContext'
import {
  getDepartments,
  selectDepartments,
} from '../../../payment/list/paymentSlice'
import {
  addAppointmentAsync,
  getAcceptsType,
  getAppointmentPhoneAsync,
  getDoctorsTimesAsync,
  getDoctorsTimeStepAsync,
} from '../../../registration/register/registerSlice'
import styles from '../ToothHome.module.css'
import { getAllDoctorList, getRegistrationDetail } from '../toothHomeSlice'

interface SubscribeModalProps {
  visible: boolean
  patientId?: string //患者 ID
  appointmentId?: string //预约 ID
  registrationId?: string //挂号 ID
  treatmentDoctorId: string //就诊医生 ID
  onOk: () => void
  onCancel: () => void
}
export const SubscribeModal = ({
  visible,
  patientId,
  registrationId,
  appointmentId,
  treatmentDoctorId,
  onOk,
  onCancel,
}: SubscribeModalProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const { Option } = Select

  const { TextArea } = Input

  const [text, setText] = useState<any>('')

  const [useDays, setUseDays] = useState([]) //预约日期

  const [startTimeList, setStartTimeList] = useState([]) //预约时间-开始

  const [endTimeList, setEndTimeList] = useState<any>([]) //预约时间-结束

  const [timeStr, setTimeStr] = useState({
    start: '',
    end: '',
  })

  const [modalDeatail, setModalDetail] = useState<any>({}) //  弹窗详情

  const departments = useSelector(selectDepartments) // 医生科室数据

  const [doctorList, setDoctorList] = useState<any>([]) //  医生数据

  const [doctorIds, setDoctorId] = useState<any>()

  const [sendMessage, setSendMessage] = useState(false) //发送短信复选框

  const credentialsInfo = JSON.parse(
    localStorage.getItem(CREDENTIAL_KEY) || '{}'
  )

  const [modalBtnLoading, setModalBtnLoading] = useState(false) //  弹窗确定按钮 loading

  const [appointmentPhone, setAppointmentPhone] = useState('') //手机号

  const getDetail = () => {
    //获取详情信息
    dispatch(
      getRegistrationDetail({
        registrationId,
        patientId,
        appointmentId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setModalDetail(res)
        getAllDoctor(
          res.appointmentDepartmentId > 0
            ? String(res.appointmentDepartmentId)
            : res.outpatientDepartmentId > 0
            ? String(res.outpatientDepartmentId)
            : undefined
        )

        getTime(
          res.appointmentDoctorId > 0
            ? res.appointmentDoctorId
            : res.attendingDoctorId > 0
            ? res.attendingDoctorId
            : undefined
        )
      })
  }

  const getAllDoctor = (id?: string) => {
    //获取医生数据
    dispatch(
      getAllDoctorList({
        size: 1000,
        state: 1,
        stationCategory: 1,
        outpatientDepartmentId: id ? id : undefined,
      })
    )
      .then(unwrapResult)
      .then(({ records }) => {
        setDoctorList(
          records
            .filter((v: any) => v.kssxbz != 1)
            .filter((v: any) => v.state === 1)
        ) // 筛选已注销科室的医生
      })
  }

  useEffect(() => {
    if (doctorList && modalDeatail) {
      const dectorId =
        modalDeatail.appointmentDoctorId > 0
          ? modalDeatail.appointmentDoctorId
          : modalDeatail.attendingDoctorId > 0
          ? modalDeatail.attendingDoctorId
          : undefined
      const ids = doctorList
        ?.map((v: any) => v.id)
        ?.filter((s: any) => {
          return s == dectorId
        })
      setDoctorId(ids)
    }
  }, [modalDeatail, doctorList])

  useEffect(() => {
    if (modalDeatail) {
      form.setFieldsValue({
        appointmentDepartmentId:
          modalDeatail.appointmentDepartmentId > 0
            ? String(modalDeatail.appointmentDepartmentId)
            : modalDeatail.outpatientDepartmentId > 0
            ? String(modalDeatail.outpatientDepartmentId)
            : undefined,
        appointmentDoctorId: doctorIds?.length
          ? modalDeatail.appointmentDoctorId > 0
            ? modalDeatail.appointmentDoctorId
            : modalDeatail.attendingDoctorId > 0
            ? modalDeatail.attendingDoctorId
            : undefined
          : undefined,
      })
    }
  }, [modalDeatail])

  useEffect(() => {
    if (doctorIds) {
      form.setFieldsValue({
        appointmentDoctorId: doctorIds?.length
          ? modalDeatail.appointmentDoctorId > 0
            ? modalDeatail.appointmentDoctorId
            : modalDeatail.attendingDoctorId > 0
            ? modalDeatail.attendingDoctorId
            : undefined
          : undefined,
      })
    }
  }, [doctorIds])

  const getTime = (id: string) => {
    //获取预约日期
    id &&
      dispatch(getDoctorsTimesAsync(id))
        .then(unwrapResult)
        .then((res: any) => {
          setUseDays(res)
        })
  }

  const onSubmit = (values: any) => {
    //新增预约
    setModalBtnLoading(true)
    const scheduleObj: any = useDays?.find(
      (v: any) =>
        v.scheduleDate == moment(values.appointmentDate).format('YYYY-MM-DD')
    )
    const data = {
      ...values,
      workTime: undefined,
      appointmentStartTime: values.workTime.appointmentStartTime,
      appointmentEndTime: values.workTime.appointmentEndTime,
      appointmentDate: moment(values.appointmentDate).format('YYYY-MM-DD'),
      scheduleId: scheduleObj?.id,
      smsStatus: sendMessage ? 1 : 0,
      sex: modalDeatail.patientSex,
      ageYear: modalDeatail.ageYear,
      birthday: modalDeatail.birthday,
      name: modalDeatail.patientName,
      patientId,
      appointmentId,
      registrationId,
      phone: modalDeatail?.patientPhone,
    }
    dispatch(addAppointmentAsync(data))
      .then(unwrapResult)
      .then(() => {
        notification.success({ message: '新增预约成功' })
        setDoctorList([])
        onOk()
      })
      .finally(() => setModalBtnLoading(false))
  }

  const getType = () => {
    //获取就诊类型
    dispatch(
      getAcceptsType({
        type: '34',
      })
    )
      .then(unwrapResult)
      .then((res) => {
        form.setFieldsValue({
          receiveTag: res == 2 ? '1' : '0',
        })
      })
  }

  useEffect(() => {
    dispatch(getDepartments())
    if (visible) {
      getDetail()
      //获取手机信息
      dispatch(getAppointmentPhoneAsync())
        .then(unwrapResult)
        .then((res: any) => setAppointmentPhone(res.adviceTel))
      getType()
      setModalBtnLoading(false)
      if (form.getFieldValue('appointmentDoctorId')) {
        getTime(form.getFieldValue('appointmentDoctorId'))
      }
    } else {
      form.resetFields()
      setUseDays([])
      setModalDetail({})
    }
  }, [visible])

  const getWeek = (date: any) => {
    // 时间戳
    const week = moment(date).day()
    switch (week) {
      case 1:
        return '星期一'
      case 2:
        return '星期二'
      case 3:
        return '星期三'
      case 4:
        return '星期四'
      case 5:
        return '星期五'
      case 6:
        return '星期六'
      case 0:
        return '星期日'
    }
  }

  return (
    <Modal
      title='新增预约'
      width={1120}
      visible={visible}
      onCancel={() => {
        setDoctorList([])
        onCancel()
        setText('')
      }}
      confirmLoading={modalBtnLoading}
      onOk={() => {
        form.submit()
      }}
      centered
    >
      <div>
        {/* 头部信息 */}
        <div className={styles.modalHeaderWrap} style={{ height: 70 }}>
          <div className={styles.headerUserWrap}>
            <div className={styles.fl}>
              <div className={styles.icon} style={{ fill: '#fff' }}>
                {modalDeatail?.patientSex == 2 ? (
                  <GirlIcon
                    style={{
                      width: 48,
                      height: 48,
                    }}
                  />
                ) : (
                  <BoyIcon
                    style={{
                      width: 48,
                      height: 48,
                    }}
                  />
                )}
              </div>
              <div className={styles.user}>
                {modalDeatail.patientName} (
                {getSexData(Number(modalDeatail.patientSex))}/
                {modalDeatail.ageYear}岁)
              </div>
            </div>
          </div>
        </div>

        <Label label='预约信息' style={{ marginLeft: 10, marginBottom: 20 }} />

        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          onFinish={(values) => {
            onSubmit(values)
            // onOk()
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name='appointmentDepartmentId'
                label='预约科室'
                rules={[{ required: true }]}
              >
                <Select
                  placeholder='请选择'
                  allowClear
                  onChange={(e) => {
                    form.setFieldsValue({
                      //默认就诊医生和科室
                      appointmentDoctorId: '',
                    })
                    getAllDoctor(e)
                  }}
                >
                  {departments.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='appointmentDoctorId'
                label='预约医生'
                rules={[{ required: true }]}
              >
                <Select
                  placeholder='请选择'
                  allowClear
                  onChange={(e) => {
                    form.setFieldsValue({
                      appointmentDate: '',
                      workTime: {
                        appointmentStartTime: '',
                        appointmentEndTime: '',
                      },
                    })
                    setStartTimeList([])
                    setEndTimeList([])
                    getTime(e)
                  }}
                >
                  {doctorList?.map((d: any) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.realname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name='appointmentType' label='预约方式'>
                <Select
                  placeholder='请选择'
                  // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                >
                  {appointmentStatusOptions.map((d) => (
                    <Select.Option key={d} value={d}>
                      {getAppointTypeName(d)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='receiveTag'
                label='就诊类型'
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value='0'>初诊</Radio>
                  <Radio value='1'>复诊</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ height: 56 }}>
            <Col span={12}>
              <Form.Item
                label='预约日期'
                name='appointmentDate'
                rules={[{ required: true }]}
              >
                <DatePicker
                  style={{
                    width: text ? '65%' : '100%',
                    position: 'relative',
                  }}
                  allowClear={false}
                  showToday={false}
                  disabledDate={(date) => {
                    const days = moment(date).format('YYYY-MM-DD')
                    if (useDays.length) {
                      return !useDays
                        .map((v: any) => {
                          return v.scheduleDate
                        })
                        .some((v) => v == days)
                    } else {
                      return true
                    }
                  }}
                  onChange={(v) => {
                    const days = moment(v).diff(moment(), 'days') + 1
                    const weeks = getWeek(v)
                    if (
                      moment(v).format(DateTimeFormatSimple) ==
                      moment().format(DateTimeFormatSimple)
                    ) {
                      setText('今天，' + weeks)
                    } else {
                      setText(days + '天后，' + weeks)
                    }
                    if (!v) return
                    form.setFieldsValue({
                      workTime: {
                        appointmentStartTime: '',
                        appointmentEndTime: '',
                      },
                    })
                    const scheduleObj: any = useDays?.find(
                      (el: any) =>
                        el.scheduleDate == moment(v).format('YYYY-MM-DD')
                    )
                    const params: DoctorsTimeStepParams = {
                      id: scheduleObj?.id,
                      doctorId: form.getFieldValue('appointmentDoctorId'),
                    }

                    dispatch(getDoctorsTimeStepAsync(params)).then(
                      (res: any) => {
                        setEndTimeList(res?.payload?.endTimeList)
                        const startTimeList = res?.payload?.startTimeList.map(
                          (el: any) => {
                            const str =
                              el.timeScale +
                              (el.status == 1
                                ? '(已满)'
                                : `(余${el.surplusNum}人)`)
                            return {
                              label: str,
                              value: el.timeScale,
                              status: el.status,
                            }
                          }
                        )
                        setStartTimeList(startTimeList)
                      }
                    )
                  }}
                />
              </Form.Item>
              <span
                style={{
                  position: 'absolute',
                  top: 6,
                  bottom: 0,
                  left: '70%',
                  color: '#f47137',
                }}
              >
                {text ? '(' + text + ')' : ''}
              </span>
            </Col>
            <Col span={12}>
              <Form.Item
                label='预约时间'
                name='workTime'
                rules={[{ required: true, message: ' ' }]}
              >
                <Input.Group compact>
                  <Form.Item
                    name={['workTime', 'appointmentStartTime']}
                    rules={[{ required: true, message: '请选择时间' }]}
                  >
                    <Select
                      style={{ width: '125px' }}
                      onChange={(v) => {
                        setTimeStr({
                          start: v,
                          end: timeStr.end,
                        })
                      }}
                    >
                      {startTimeList?.length &&
                        startTimeList.map((v: any) => (
                          <Option
                            key={v.value}
                            value={v.value}
                            disabled={v.status == 1}
                          >
                            {v.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  &nbsp;&nbsp; ~ &nbsp;&nbsp;
                  <Form.Item
                    name={['workTime', 'appointmentEndTime']}
                    rules={[{ required: true, message: '请选择时间' }]}
                  >
                    <Select
                      style={{ width: '125px' }}
                      onChange={(v) => {
                        setTimeStr({
                          start: timeStr.start,
                          end: v,
                        })
                      }}
                    >
                      {endTimeList?.length &&
                        endTimeList.map((v: any) => (
                          <Option key={v} value={v}>
                            {v}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label='预约项目' name='appointmentItem'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 20 }}
                name='appointmentComment'
                label='预约备注'
              >
                <TextArea showCount maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingLeft: 40 }}>
            <Col span={24} style={{ color: sendMessage ? '#000' : '#999' }}>
              <Checkbox
                checked={sendMessage}
                onChange={(v) => {
                  setSendMessage(v.target.checked)
                  const flag = v.target.checked ? '1' : '0'
                  sessionStorage.setItem('SENDAPPOINTMENTMESSAGE', flag)
                }}
                style={{ marginRight: 8 }}
              />
              发送短信 {credentialsInfo?.shortName} 您已成功预约{' '}
              {form.getFieldValue('appointmentDepartmentId')
                ? departments.find(
                    (v) => v.id == form.getFieldValue('appointmentDepartmentId')
                  )?.name
                : '-'}{' '}
              请在{' '}
              {form.getFieldValue('appointmentDate')
                ? moment(form.getFieldValue('appointmentDate'))?.format(
                    'YYYY-MM-DD'
                  )
                : '-'}
              &nbsp;&nbsp;{timeStr.start}
              {timeStr.start && timeStr.end ? '~' + timeStr.end : ''}{' '}
              到挂号处登记信息。地址：{credentialsInfo?.tenantAddress} 电话：
              {appointmentPhone || '-'}
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
