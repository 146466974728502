/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-12 14:35:43
 */
import { TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
import { Dimen } from '../../models/dimen'

export const Columns = (
  startIndex: number,
  sortedInfo?: any
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '物资信息',
      align: 'left',
      dataIndex: '',
      render: function Element(_, record) {
        const deadline = moment(record.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div style={{ height: '55px' }}>
            <p
              style={{
                height: '28px',
                lineHeight: '36px',
                padding: '0',
                margin: '0',
                fontSize: '16px',
                fontWeight: 'bolder',
                color:
                  days > 180 || days > 90 || days > 30 || days > 0
                    ? '#333333'
                    : '#999999',
              }}
            >
              {record.name}
            </p>
            <p
              style={{
                height: '28px',
                lineHeight: '24px',
                padding: '0',
                margin: '0',
                color: '#999999',
                fontSize: '14px',
              }}
            >
              {record.tenantSupplierName} / {record.spec} /{' '}
              {record.doseCategory}
            </p>
          </div>
        )
      },
    },
    {
      title: '批次号',
      align: 'center',
      width: Dimen.BatchNo,
      dataIndex: 'batchNo',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div
            style={{
              color:
                days > 180 || days > 90 || days > 30 || days > 0
                  ? '#333333'
                  : '#999999',
            }}
          >
            {t.batchNo}
          </div>
        )
      },
    },
    {
      title: '有效期至',
      align: 'center',
      width: Dimen.Time,
      dataIndex: 'deadline',
      sorter: true,
      key: 'deadline',
      sortOrder: sortedInfo?.columnKey === 'deadline' && sortedInfo?.order,
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div
            onClick={() => {
              return
            }}
            style={{
              color:
                days > 180
                  ? '#000000'
                  : days > 90
                  ? '#027AFF'
                  : days > 30
                  ? '#FA8714'
                  : days > 0
                  ? '#FF5D5D'
                  : '#999999',
            }}
          >
            {moment(t.deadline).format(DateTimeFormatSimple)}
          </div>
        )
      },
    },
    {
      title: '库存数量',
      align: 'center',
      width: Dimen.Count,
      dataIndex: 'count',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div
            style={{
              color:
                days > 180 || days > 90 || days > 30 || days > 0
                  ? '#333333'
                  : '#999999',
            }}
          >
            {t.count} {t.accountUnit}
          </div>
        )
      },
    },
    {
      title: '拆零数量',
      align: 'center',
      width: Dimen.Count,
      dataIndex: 'splitCount',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div
            style={{
              color:
                days > 180 || days > 90 || days > 30 || days > 0
                  ? '#333333'
                  : '#999999',
            }}
          >
            {t.splitCount} {t.splitUnit}
          </div>
        )
      },
    },
    {
      title: '剩余天数',
      align: 'center',
      width: Dimen.Count,
      dataIndex: 'usedays',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days: any = duration.asDays()
        return (
          <div
            style={{
              color:
                days > 180 || days > 90 || days > 30 || days > 0
                  ? '#333333'
                  : '#999999',
            }}
          >
            {parseInt(days)}
          </div>
        )
      },
    },
  ]
}
