import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState, RootThunk, RootThunkApi, } from "../../../app/store";
import { api, sendAsync } from "../../../app/applicationSlice";
import * as service from "../../../services/beforeOperatingManagePage";
import { BeforeOperatingParmas } from "../../../services/beforeOperatingManagePage";
import { NoFormulasDetail } from "../../../models/ModalList";
import _ from "lodash";
import * as B from '../../../services/beforeOperatingManagePage'

const CREDENTIAL_KEY = "states";
const range: any = sessionStorage.getItem(CREDENTIAL_KEY);

// interface NoFormulaState {
//     NoformulaData: any,
//     Total: 0,
//     Detail: [],
//     Current: 1,
//     departments: NoFormulasDetail[];
//     pageLoading: false,



// 列表
export const getOperatingListAsync = createAsyncThunk<
    void,
    B.BeforeOperatingParmas,
    RootThunkApi
    >('/appeal/getOperatingListAsync', async (params, api) => {
    return sendAsync(B.getBeforeList(params), api)
})
// 详情
export const getOperatingDetailAsync = createAsyncThunk<
    void,
    string,
    RootThunkApi
    >('/appeal/getOperatingDetailAsync', async (id, api) => {
        return sendAsync(B.getOperatingDetail(id), api)
})
// 状态
export const getOperatingStatusAsync = createAsyncThunk<
    void,
    string,
    RootThunkApi
    >('/appeal/getOperatingStatusAsync', async (id, api) => {
        return sendAsync(B.getOperatingStatus(id), api)
})
// }

// const initialState: NoFormulaState = {
//     NoformulaData: [],
//     Total: 0,
//     Detail: [],
//     Current: 1,
//     departments: [],
//     pageLoading: false,

// };
// const operatingSlice = createSlice({
//     name: "noformula",
//     initialState,
//     reducers: {
//         setData(state, action) {
//             state.NoformulaData = action.payload
//         },
//         setTotal(state, action) {
//             state.Total = action.payload
//         },
//         setCurrent(state, action) {
//             state.Current = action.payload
//         },
//         setNoFormulaDetail: (state, action) => {
//             state.departments = action.payload;
//         },
//         setPageLoading: (state, action) => {
//             state.pageLoading = action.payload;
//         },

//     },
// });

// export const {
//     setData,
//     setTotal,
//     setCurrent,
//     setNoFormulaDetail,
//     setPageLoading,
// } = operatingSlice.actions;

// export function getNoformulaList(params: BeforeOperatingParmas): RootThunk {
//     return api(service.getBeforeList(params), (data: any, dispatch) => {
//         dispatch(setData(data.records))
//         dispatch(setTotal(data.total))
//         dispatch(setPageLoading(false))
//     });
// }

// export function getNoformula(params: BeforeOperatingParmas): RootThunk {
//     return api(service.getNoformula(params), (data: any, dispatch) => {
//         const count = data
//         dispatch(setNoFormulaDetail([data]));
//     });
// }

// export const getExecuteState = createAsyncThunk<
//     void,
//     service.ExcuteParams,
//     RootThunkApi
// >
//     ("setting/getSettingStatus", async (params, api) => {
//         return sendAsync(service.getExecute(params), api);
//     });



// export const selectNoformulaData = (state: RootState) => state.noformula.NoformulaData

// export const selectTotal = (state: RootState) => state.noformula.Total


// export const selectCurrent = (state: RootState) => state.noformula.Current

// export const selectPageLoading = (state: RootState) => state.noformula.pageLoading

// export const selectNofarmulaDetail = (state: RootState) =>
//     state.noFormulasDetails.departments;


// export default operatingSlice.reducer;
