import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, notification, Row, Select, Space } from 'antd';
import styles from "../ToothHome.module.css";
import { RootDispatch } from '../../../../app/store';
import { useDispatch } from 'react-redux';
import { getRegistByPatientIdGneralAsync } from '../../patient/patientSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { number2Pattern } from '../../../../utils/RegExp';

interface cutPriceModalProps {
  visible: boolean
  cutPriceDetail: any
  patientId: string
  onCancel: () => void
  onOk: (obj: any, id: string, type: number) => void
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}
export const CutPriceModal = ({
  visible,
  cutPriceDetail,
  patientId,
  onCancel,
  onOk
}: cutPriceModalProps) => {

  const { Option } = Select

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm();

  const [disposeSelectOptions, setDisposeSelectOptions] = useState<any[]>([])   //就诊信息数据

  const [saveType, setSaveType] = useState(0) // 保存状态

  const [btnloading, setBtnloading] = useState(false) //  按钮状态

  const getAllData = () => {   //获取就诊信息
    dispatch(getRegistByPatientIdGneralAsync({ patientId, size: 30 })).then(unwrapResult).then((res: any) => {
      setDisposeSelectOptions(res)
    })
  }

  useEffect(() => {
    if (visible) {
      getAllData()
      setBtnloading(false)
    } else {
      form.resetFields()
    }
    return () => {
      setBtnloading(false)
    }
  }, [visible])

  return (
    <Modal
      title='划价'
      width={900}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <div>
        <Form
          form={form}
          {...layout}
          style={{ paddingRight: 10 }}
          onFinish={(values) => {
            let result = true
            for (const key in values) {
              if (values[key] && key != 'registrationId') {
                result = number2Pattern.pattern.test(values[key]) ? false : true
              }
            }
            if (result) {
              notification.error({ message: '请至少输入一个正确的价格' })
              return
            }

            const tempArr = cutPriceDetail
              .map((v: any) => {
                //处理保存数据
                return {
                  billingCategory: v.billingCategory,
                  itemId: v.id,
                  itemName: v.name,
                  itemPrice: values[v.no],
                }
              })
              .filter((i: any) => i.itemPrice)
            setBtnloading(true)
            onOk(tempArr, values.registrationId, saveType)
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                name='registrationId'
                label='就诊信息'
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp='children'
                  allowClear
                  filterOption={(input, option: any) => {
                    return (option?.children ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }}
                >
                  {disposeSelectOptions.length != 0 &&
                    disposeSelectOptions.map((v: any) => (
                      <Option key={v.registrationId} value={v.registrationId}>
                        {`${v.registrationTime} ${v.treatmentDoctorName} ${
                          v.insuranceCode == 'ZIFEI'
                            ? '自费'
                            : v.insuranceCode == '' || v.insuranceCode == null
                            ? ' 未知险种'
                            : '医保刷卡'
                        } (${v.treatmentDepartmentName}) ${v.disease}`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {cutPriceDetail.map((v: any) => {
              return (
                <Col span={12} key={v.id}>
                  <Form.Item name={v.no} label={v.name}>
                    <Input allowClear />
                  </Form.Item>
                </Col>
              )
            })}
          </Row>
        </Form>
        <div className={styles.cutPriceModalFooter}>
          <div className={styles.footerText}>备注：只支持自费结算</div>
          <Space>
            <Button onClick={onCancel}>取消</Button>
            <Button
              type='primary'
              loading={btnloading}
              onClick={() => {
                form.submit()
                setSaveType(1)
              }}
            >
              确认
            </Button>
            <Button
              type='primary'
              loading={btnloading}
              onClick={() => {
                setSaveType(2)
                form.submit()
              }}
            >
              立即结算
            </Button>
          </Space>
        </div>
      </div>
    </Modal>
  )
};
