/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 13:35:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-08 14:25:56
 */
import { Col } from 'antd'
import React, { ReactElement, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import styles from './MedicalRecord/MedicalRecord.module.css'
import { MedicalRecords } from './MedicalRecord/MedicalRecord'

export const HealthSupervisorUploadTabbar = (): ReactElement => {

  return (
    <Col className={styles.main}>
      {/* <TabBar
        onChange={(v) => {
          setActiveId(v)
        }}
      > <TabPane key='0' tab='病历记录'>
          <MedicalRecords
            selectedTab={activeId}
          ></MedicalRecords>
        </TabPane> */}
        <MedicalRecords ></MedicalRecords>
      {/* </TabBar> */}
    </Col>
  )
}
