import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  notification,
  Table,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectTenantName,
  selectUserDepartmentId,
  selectUserId,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../compnents/form/DateSelect'
import { EditableList } from '../../compnents/list/EditableList'
import { traceRoute } from '../../layouts/layoutSlice'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnly,
} from '../../models/datetime'
import { Department } from '../../models/department'
import { Dimen } from '../../models/dimen'
import { getGenderName, User } from '../../models/user'
import { GetOutpatientAuditListParams } from '../../services/outpatient'
import { ThemeContext } from '../../theme/ThemeContext'
import { getAge } from '../../utils/StringUtils'
import { getFullDeparmentList } from '../completion/completionSlice'
import { getFullDoctorList } from '../registration/list/registrationSlice'
import styles from './OutpatientAudit.module.css'
import {
  getOutpatientAuditList,
  OutpatientAuditItem,
  selectOutpatientAuditPage,
  getOutpatientAuditExoprt,
} from './outpatientAuditSlice'
import { getSourceName } from '../../models/ToothHome'
import { setTotal } from '../commodity/commoditySlice'
import { PrintOutlined } from '../../compnents/icons/Icons'
import { printBody } from '../../models/outpatientAuditPrint'

interface OutpatientAuditProps {
  currentUserMode: boolean
  route: {
    name: string
    path: string
  }
}

export const OutpatientAudit = ({
  currentUserMode,
  route,
}: OutpatientAuditProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const tenantName = useSelector(selectTenantName)

  const [sortedInfo, setSorter] = useState<any>()

  // const page = useSelector(selectOutpatientAuditPage)

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  const departmentId = useSelector(selectUserDepartmentId)

  const [departments, setDepartments] = useState<Department[]>([])

  const [doctors, setDoctors] = useState<User[]>([])

  const userId = useSelector(selectUserId)

  const [head, tail] = getDateRange(DateRangeType.Today)

  const [page, setPage] = useState<any>({
    total: 1,
    items: [],
  })

  const [allData, setAllData] = useState<any>()

  const [outCludeLoading, setOutCludeLoading] = useState(false)

  const [params, setParams] = useState<GetOutpatientAuditListParams>({
    current: 1,
    size: 10,
    treatmentDoctorId: currentUserMode ? userId : undefined,
    treatmentTimeHead: head,
    treatmentTimeTail: tail,
  })

  const storageParams = JSON.parse(
    sessionStorage.getItem('outpatient') || '{}'
  )

  useEffect(() => {
    if (!currentUserMode) {
      dispatch(getFullDeparmentList({ current: 1, size: 1000, status: 1 }))
        .then(unwrapResult)
        .then((ds) => setDepartments(ds))
        .catch(() => {
          // do nothing
        })
      dispatch(getFullDoctorList())
        .then(unwrapResult)
        .then((ds) => setDoctors(ds))
        .catch(() => {
          // do nothing
        })
    }
  }, [])

  useEffect(() => {
    setPageLoading(true)
    dispatch(getOutpatientAuditList(params))
      .then(unwrapResult)
      .then((v: any) => {
        setPage({
          items: v?.treatmentLogVO?.records,
          total: v?.treatmentLogVO?.total,
        })
        setAllData(v)
      })
      .finally(() => setPageLoading(false))
  }, [params])

  useEffect(() => {
    if (Object.keys(storageParams).length) {
      form.setFieldsValue({
        ...storageParams,
        // param: '', // 防止缓存数据输入
      })
    } else {
      const [head, tail] = getDateRange(DateRangeType.Today)
      form.setFieldsValue({
        _dateRange: 0,
        registrationTimeHead: head,
        registrationTimeTail: tail,
      })
    }
    form.submit()
  }, [])

  useEffect(() => {
    sessionStorage.setItem('outpatient', JSON.stringify({ ...params, scope: form.getFieldValue('scope'), param: form.getFieldValue('param') }))
  }, [params])

  const dataMainList = {
    tenantName: tenantName?.split('-')?.[0],
    data: page?.items,
    local: {
      name: '门诊日志通用版',
      time: moment().locale('zh-cn').format('YYYY/MM/DD HH:mm:ss'),
    },
    timeHead: moment(params?.treatmentTimeHead).format(
      DateTimeFormatSimpleOnly
    ),
    timeTail: moment(params?.treatmentTimeTail).format(
      DateTimeFormatSimpleOnly
    ),
    // titleList: titleList,
  }

  const content = printBody(dataMainList)
  const print = () => {
    const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
    page.document.write(content) // 写入打印页面的内容
    page.print() // 打印
    page.close()
  }

  const outClued = () => {
    setOutCludeLoading(true)
    dispatch(getOutpatientAuditExoprt(params))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '门诊日志.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
        setOutCludeLoading(false)
      })
      .catch((res: any) => {
        setOutCludeLoading(false)
      })
  }

  useEffect(() => {
    let sortN = 0
    console.log(sortedInfo);
    
    if (sortedInfo?.order === 'ascend') {
      switch (sortedInfo?.columnKey) {
        case 'createTime':
          sortN = 1
          break
        case 'patientName':
          sortN = 3
          break
        case 'patientAge':
          sortN = 5
          break
        case 'patientSex':
          sortN = 7
          break
        case 'receiveTag':
          sortN = 9
          break
        case 'infectiousDisease':
          sortN = 11
          break
        case 'treatmentDepartmentName':
          sortN = 13
          break
        case 'treatmentDoctorName':
          sortN = 15
          break
        default:
          break
      }
    } else if (sortedInfo?.order === 'descend') {
      switch (sortedInfo?.columnKey) {
        case 'createTime':
          sortN = 0
          break
        case 'patientName':
          sortN = 2
          break
        case 'patientAge':
          sortN = 4
          break
        case 'patientSex':
          sortN = 6
          break
        case 'receiveTag':
          sortN = 8
          break
        case 'infectiousDisease':
          sortN = 10
          break
        case 'treatmentDepartmentName':
          sortN = 12
          break
        case 'treatmentDoctorName':
          sortN = 14
          break
        default:
          break
      }
    }

    if (sortedInfo && sortedInfo?.order == undefined) {
      setParams({
        ...params,
        sort: undefined,
      })
    } else {
      setParams({
        ...params,
        sort: sortN,
      })
    }
  }, [sortedInfo])

  return (
    <Col
      style={{
        height: currentUserMode ? 'calc(100% - 10px)' : 'calc(100% - 30px)',
        backgroundColor: theme.pn,
        borderRadius: 10,
        padding: '1px 1px 0 1px',
        margin: '10px 0',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Form
        form={form}
        autoComplete='off'
        initialValues={{
          scope: currentUserMode ? 'user' : undefined,
        }}
        onFinish={(values) => {
          setParams({
            ...params,
            ...values,
            scope: undefined,
            treatmentDoctorId:
              values.scope === 'user' ? userId : values.treatmentDoctorId,
            treatmentDepartmentId:
              values.scope === 'department'
                ? departmentId
                : values.treatmentDepartmentId,
            current: 1,
            treatmentTimeHead: values?.treatmentTimeHead
              ? values?.treatmentTimeHead
              : head,
            treatmentTimeTail: values?.treatmentTimeTail
              ? values?.treatmentTimeTail
              : tail,
          })
        }}
      >
        <Row align='middle' justify='space-between'>
          <Space>
            <Form.Item name='param' noStyle>
              <Input
                onChange={form.submit}
                style={{ width: 240 }}
                placeholder='请输入关键词'
                prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
              />
            </Form.Item>
            <Form.Item name='scope' noStyle>
              <Select
                placeholder='查看范围'
                allowClear
                disabled={currentUserMode}
                style={{ width: 180 }}
                onChange={form.submit}
                // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              >
                <Select.Option value='user'>只看本人</Select.Option>
                <Select.Option value='department'>只看本科室</Select.Option>
              </Select>
            </Form.Item>
            {!currentUserMode && (
              <>
                <Form.Item name='treatmentDepartmentId' noStyle>
                  <Select
                    allowClear
                    style={{ width: 180 }}
                    placeholder='接诊科室'
                    onChange={form.submit}
                    // suffixIcon={
                    //   <CaretDownFilled style={{ color: theme.tc3 }} />
                    // }
                  >
                    {departments.map((d) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name='treatmentDoctorId' noStyle>
                  <Select
                    allowClear
                    style={{ width: 180 }}
                    placeholder='接诊医生'
                    onChange={form.submit}
                    // suffixIcon={
                    //   <CaretDownFilled style={{ color: theme.tc3 }} />
                    // }
                  >
                    {doctors.map((d) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
            <DateSelect
              labelPrefix=''
              placeholder='就诊时间'
              namePrefix='treatmentTime'
              options={TJFXTime}
              allowClear={false}
              initialValue={DateRangeType.Today}
              style={{ width: 140 }}
              onChange={form.submit}
            />
            <Button type='primary' onClick={form.submit}>
              查询
            </Button>
          </Space>
          <Space style={{ marginRight: '10px' }}>
            <Button type='primary' loading={outCludeLoading} onClick={outClued}>
              导出
            </Button>
            <Button
              type='primary'
              // loading={printLoading}
              onClick={print}
            >
              打印
            </Button>
          </Space>
        </Row>
      </Form>
      <EditableList<OutpatientAuditItem>
        style={{ flex: 1, marginTop: 15 }}
        rowKey={(v, i) => `${i}`}
        loading={pageLoading}
        className={styles.table}
        page={{
          current: params.current as any,
          size: params.size as any,
          total: page.total,
          items: page.items,
        }}
        onChange={(a, b, sorter: any) => {
          setSorter(sorter)
        }}
        columns={[
          {
            title: '序号',
            key: 'no',
            align: 'center',
            width: Dimen.Num,
            render: (_1, _2, index) =>
              `${
                (params.size || 10) * ((params.current || 1) - 1) + index + 1
              }`,
          },
          {
            title: '就诊时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center',
            width: '7rem',
            sorter: true,
            sortOrder: sortedInfo?.columnKey === 'createTime' && sortedInfo?.order,
            render: (_, r) => moment(r.createTime).format(DateTimeFormatSimple),
            // sorter: (a, b) => moment(a.createTime).diff(moment(b.createTime)),
          },
          {
            title: '姓名',
            dataIndex: 'patientName',
            key: 'patientName',
            align: 'center',
            width: '7rem',
            sorter: true,
            sortOrder: sortedInfo?.columnKey === 'patientName' && sortedInfo?.order,
            // sorter: (a, b) => a.patientName.localeCompare(b.patientName),
          },
          {
            title: '年龄',
            dataIndex: 'patientAge',
            align: 'center',
            width: Dimen.Age,
            key: 'patientAge',
            sorter: true,
            sortOrder: sortedInfo?.columnKey === 'patientAge' && sortedInfo?.order,
            render: (_, r) => getAge(r.patientAge, r.patientMonth),
            // sorter: (a, b) => {
            //   const aMonths =
            //     (a.patientAge < 0 ? 0 : a.patientAge) * 12 +
            //     (a.patientMonth < 0 ? 0 : a.patientMonth)
            //   const bMonths =
            //     (b.patientAge < 0 ? 0 : b.patientAge) * 12 +
            //     (b.patientMonth < 0 ? 0 : b.patientMonth)
            //   return aMonths - bMonths
            // },
          },
          {
            title: '性别',
            key: 'patientSex',
            width: Dimen.Sex,
            align: 'center',
            sorter: true,
            sortOrder: sortedInfo?.columnKey === 'patientSex' && sortedInfo?.order,
            render: (_, r) => getGenderName(r.patientSex),
            // sorter: (a, b) => a.patientSex - b.patientSex,
          },
          {
            title: '身份证',
            dataIndex: 'idCardNumber',
            width: '10rem',
            align: 'center',
          },
          {
            title: '联系电话',
            dataIndex: 'patientPhone',
            width: '9rem',
            align: 'center',
          },
          {
            title: '地址',
            dataIndex: 'address',
            align: 'center',
            width: '10rem',
            ellipsis: true,
            render: (_, r) => (r.address ? r.address : '-'),
          },
          {
            title: '病人单位',
            dataIndex: 'companyName',
            align: 'center',
            width: '10rem',
            ellipsis: true,
            render: (_, r) => (r.companyName ? r.companyName : '-'),
          },

          {
            title: '应收款额',
            dataIndex: 'patientTotalMoney',
            align: 'right',
            width: Dimen.Sex,
          },
          {
            title: '收款额',
            dataIndex: 'patientSkMoney',
            align: 'right',
            width: Dimen.Sex,
          },
          {
            title: '医保报销金额',
            dataIndex: 'patientInsuranceMoney',
            align: 'right',
            width: Dimen.Status,
          },
          {
            title: '欠费金额',
            dataIndex: 'patientOweMoney',
            align: 'right',
            width: Dimen.Sex,
          },
          {
            title: '欠费补缴金额',
            dataIndex: 'patientBjMoney',
            align: 'right',
            width: Dimen.Status,
          },
          {
            title: '初复诊',
            key: 'receiveTag',
            align: 'center',
            width: '5rem',
            sorter: true,
            sortOrder: sortedInfo?.columnKey === 'receiveTag' && sortedInfo?.order,
            render: (_, r) => (r.receiveTag ? '复诊' : '初诊'),
            // sorter: (a, b) => a.receiveTag - b.receiveTag,
          },
          {
            title: '传染病',
            key: 'infectiousDisease',
            align: 'center',
            width: '5rem',
            sorter: true,
            sortOrder: sortedInfo?.columnKey === 'infectiousDisease' && sortedInfo?.order,
            render: (_, r) => (r.infectiousDisease == 1 ? '是' : '否'),
            // sorter: (a, b) => a.infectiousDisease - b.infectiousDisease,
          },
          {
            title: '疾病名称',
            dataIndex: 'disease',
            align: 'center',
            width: '13rem',
            ellipsis: true,
            render: (_, r) => (r.disease ? r.disease : '-'),

            // render: (_, r) => ({
            //     props: {
            //         style: {
            //             maxWidth: '300px',
            //             overflow: 'hidden',
            //             textOverflow: 'ellipsis',
            //         },
            //     },
            //     children: r.disease,
            // }),
          },
          {
            title: '接诊科室',
            dataIndex: 'treatmentDepartmentName',
            align: 'center',
            width: Dimen.Department,
            key: 'treatmentDepartmentName',
            sorter: true,
            sortOrder: sortedInfo?.columnKey === 'treatmentDepartmentName' && sortedInfo?.order,
            // sorter: (a, b) =>
            //   a.treatmentDepartmentName.localeCompare(
            //     b.treatmentDepartmentName
            //   ),
          },
          {
            title: '接诊医生',
            dataIndex: 'treatmentDoctorName',
            key: 'treatmentDoctorName',
            align: 'center',
            width: Dimen.Doctor,
            sorter: true,
            sortOrder: sortedInfo?.columnKey === 'treatmentDoctorName' && sortedInfo?.order,
            // sorter: (a, b) =>
            //   a.treatmentDoctorName.localeCompare(b.treatmentDoctorName),
          },
          {
            title: '患者来源',
            dataIndex: 'patientSource',
            align: 'center',
            render: (_, r) => getSourceName(Number(r.source)),
            width: Dimen.Sex,
          },
          {
            title: '标签',
            dataIndex: 'patientLabel',
            align: 'center',
            width: Dimen.Name,
          },
          {
            title: '操作',
            key: 'actions',
            align: 'center',
            width: '8rem',
            fixed: 'right',
            render: function ShowActions(_, r) {
              return (
                <a
                  onClick={() => {
                    dispatch(
                      traceRoute({
                        name: route.name || '日志详情',
                        path: '/treatment/detail',
                        query: `treatmentId=${r.id}&medicalRecord=${r?.medicalRecord}`,
                        state: {
                          where: ['门诊日志', '日志详情'],
                          backtrace: { name: '门诊日志', path: route.path, 
                            state: {
                              station: route.path === '/doctor/station' ? '2' 
                                : route.path === '/outpatientReport' ? '1' 
                                : route.path === '/statistics/outpatient' ? '3' : ''
                            } },
                        },
                      })
                    )
                  }}
                >
                  查看详情
                </a>
              )
            },
          },
        ]}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row className={styles.total}>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='center'>
                合计
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9} align='right'>
                {allData?.patientTotalMoneySUM?.toFixed(2) || '0.00'}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10} align='right'>
                {allData?.patientSkMoneySUM?.toFixed(2) || '0.00'}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9} align='right'>
                {allData?.patientInsuranceMoneySUM?.toFixed(2) || '0.00'}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10} align='right'>
                {allData?.patientOweMoneySUM?.toFixed(2) || '0.00'}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={11} align='right'>
                {allData?.patientBjMoneySUM?.toFixed(2) || '0.00'}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={12}></Table.Summary.Cell>
              <Table.Summary.Cell index={13}></Table.Summary.Cell>
              <Table.Summary.Cell index={14}></Table.Summary.Cell>
              <Table.Summary.Cell index={15}></Table.Summary.Cell>
              <Table.Summary.Cell index={16}></Table.Summary.Cell>
              <Table.Summary.Cell index={17}></Table.Summary.Cell>
              <Table.Summary.Cell index={18}></Table.Summary.Cell>
              <Table.Summary.Cell index={19}></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size })
        }
        onRow={(r) => ({
          onDoubleClick: () => {
            dispatch(
              traceRoute({
                name: route.name || '日志详情',
                path: '/treatment/detail',
                query: `treatmentId=${r.id}&medicalRecord=${r?.medicalRecord}`,
                state: {
                  where: ['门诊日志', '日志详情'],
                  backtrace: { name: '门诊日志', path: route.path,
                    state: {
                      station: route.path === '/doctor/station' ? '2' 
                        : route.path === '/outpatientReport' ? '1' 
                        : route.path === '/statistics/outpatient' ? '3' : ''
                    }
                  },
                },
              })
            )
          },
        })}
      />
    </Col>
  )
}
