/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:48:24
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-04 16:32:16
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export function wechatConfig(): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechat/tenant/getWechatConfig`,
    method: 'GET',
  }
}

export function generateAccessUrl(): Request {
  return {
    url: `${API_PREFIX}/blade-thirdparty/wechat/third/generateAccessUrl`,
    method: 'GET',
  }
}

export function payInfo(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/product/getPayCode`,
    method: 'GET',
    params
  }
}

// export interface ClinicSubmitParams {
//   intro?: string
//   address?: string
//   name?: string
//   phone?: string
//   pictureList?: any
//   labelList?: any
// }

// // 保存
// export function clinicSubmit(body: ClinicSubmitParams): Request {
//   return {
//     url: `${API_PREFIX}/blade-user/clinic/submit`,
//     method: 'POST',
//     body,
//   }
// }
