import { Button, Col, Row, Form, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect } from 'react'
import {} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../../compnents/form/DateSelect'
import { SupplierDealingParams } from '../../../../services/supplierDealing'
import {
  getDepartment,
  sleectUserData,
} from '../../../putstorelist/putstorelistSlice'
import { User } from '../../../../models/user'
import styles from './stockMaintenance.module.css'
import {
  pageDurgsystem,
  selectDurgsystemData,
} from '../../../durgsystem/durgsystemSlice'
const { Option } = Select

export const StockMaintenanceQuery = (props: {
  timeHead?: string
  timeTail?: string
  onValueChange: (queries: SupplierDealingParams) => void
}): ReactElement => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const durgData = useSelector(selectDurgsystemData)

  const userData = useSelector(sleectUserData)

  useEffect(() => {
    dispatch(pageDurgsystem(1, 100, 1))
    dispatch(getDepartment({ current: 1, size: 1000, state: 1 }))
    const [head, tail] = getDateRange(DateRangeType.ThisYear)
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
    })
  }, [])
  return (
    <>
      <Form
        form={form}
        onFinish={(values) => {
          props.onValueChange({
            ...values,
            state: values.state !== 2 ? values.state : null,
          })
        }}
      >
        <Row
          style={{ height: '42px', position: 'relative', zIndex: 999 }}
          wrap={false}
          gutter={10}
        >
          <Col span={4}>
            <Form.Item name='no'>
              <Input
                autoComplete='off'
                placeholder='养护单号'
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='storehouseId'>
              <Select
                placeholder='库房列表'
                allowClear
                dropdownClassName={styles.select}
                onChange={() => form.submit()}
              >
                {durgData.map((v: any) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='mainUserId'>
              <Select
                placeholder='养护人员列表'
                dropdownClassName={styles.select}
                allowClear
                onChange={() => form.submit()}
              >
                {userData.map((v: User) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='state'>
              <Select
                placeholder='养护状态'
                dropdownClassName={styles.select}
                allowClear
                onChange={() => form.submit()}
                defaultValue={2}
              >
                <Option value={2}>全部</Option>
                <Option value={0}>养护中</Option>
                <Option value={1}>养护完成</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1}>
            <DateSelect
              style={{ width: '8.5rem' }}
              labelPrefix=''
              namePrefix='time'
              placeholder='养护日期'
              initialValue={DateRangeType.ThisYear}
              onChange={() => form.submit()}
            />
          </Col>
          <Col span={1}>
            <Button
              type='primary'
              style={{ float: 'right' }}
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
