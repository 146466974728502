/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-02 14:19:51
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface OutPatientCostStatisticsPrams {
  current: number
  size: number
  startTime: string
  endTime: string
  param?: any
}

// 列表
export function outPatientCostStatisticsList(
  params: OutPatientCostStatisticsPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/getOutpatientAmountStatistics`,
    method: 'GET',
    params,
  }
}

// 导出
export const getExportFn = (params: OutPatientCostStatisticsPrams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/outpatientAmountStatisticsExportExcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
