/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-10 14:54:59
 * @LastEditors: linxi
 * @LastEditTime: 2022-07-06 18:09:53
 */
import { TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import { EditableColumnType } from '../../../compnents/list/EditableList'
import { DateTimeFormatSimple } from '../../../models/datetime'

export const InventoryModalCloumns = (
  startIndex: number,
  storehouseTag?: number
): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      dataIndex: '',
      align: 'center',
      render: (_1, _2, index) => {
        if (_2.id === startIndex) {
          return '合计'
        } else {
          return `${startIndex + index + 1}`
        }
      },
    },
    {
      title: '产品信息',
      dataIndex: 'productInfo',
      render: function Element(_, record) {
        return (
          <div style={{ height: '68px' }}>
            <p
              style={{
                height: '16px',
                marginBottom: '10px',
                fontSize: '16px',
                fontWeight: 'bolder',
              }}
            >
              {record.name}
            </p>
            <p
              style={{
                height: '16px',
                color: '#999999',
                fontSize: '14px',
              }}
            >
              {record.productInfo}
            </p>
          </div>
        )
      },
    },
    {
      title: '批次号',
      align: 'center',
      dataIndex: 'batchNo',
    },
    // {
    //   title: "拆零数值",
    //   align: "center",
    //   dataIndex: "realSplitCount",
    // },
    {
      title: '盘点前',
      align: 'center',
      dataIndex: 'invCount',
      children: [
        {
          title: '整存',
          align: 'center',
          dataIndex: 'beforeCount',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return t.beforeTotalCount
            } else {
              return t.beforeCount
            }
          },
        },
        {
          title: '拆零',
          align: 'center',
          dataIndex: 'beforeSplitCount',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return t.checkSplitCountBefore
            } else {
              return t.beforeSplitCount
            }
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '盘点后',
      align: 'center',
      children: [
        {
          title: '整存',
          align: 'center',
          dataIndex: 'realCount',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return t.afterTotalCount
            } else {
              return t.realCount
            }
          },
        },
        {
          title: '拆零',
          align: 'center',
          dataIndex: 'realSplitCount',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return t.checkSplitCountAfter
            } else {
              return t.realSplitCount
            }
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '盘盈盘亏数',
      align: 'center',
      children: [
        {
          title: '整存',
          align: 'center',
          dataIndex: '',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return t.checkCount
            } else {
              return t.count
            }
          },
        },
        {
          title: '拆零',
          align: 'center',
          dataIndex: '',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return t.checkSplitCount
            } else {
              return t.splitCount
                ? parseFloat((t.splitCount as unknown) as string)
                : 0
            }
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '变动金额(进价)',
      align: 'right',
      dataIndex: 'changePrice',
      render: (_, t) => {
        if (startIndex === t.id) {
          return t.checkPrice
        } else {
          return t.changePrice
        }
      },
    },
    {
      title: '操作时间',
      align: 'center',
      dataIndex: 'createTime',
      sorter: (a: any, b: any) =>
        moment(a.updateTime).unix() - moment(b.updateTime).unix(),
      render: (_, t) => {
        return t.updateTime
          ? moment(t.updateTime).format(DateTimeFormatSimple)
          : '-'
      },
    },
  ]
}
