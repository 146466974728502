/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-25 11:01:11
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-17 16:59:39
 */
import { Row, Switch } from "antd";
import React, { ReactElement, useContext } from "react";
import { ThemeContext } from "../../theme/ThemeContext";

interface StateSwitchProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  onLabel?: string;
  offLabel?: string;
}

export const StateSwitch = ({
  value,
  onChange,
  onLabel,
  offLabel,
}: StateSwitchProps): ReactElement => {
  const theme = useContext(ThemeContext);
  return (
    <Row align="middle" justify="center" wrap={false}>
      <div
        style={{
          width: "4px",
          height: "4px",
          borderRadius: 99,
          backgroundColor: value ? theme.s : theme.e,
        }}
      ></div>
      <div
        style={{
          width: "3.5rem",
          textAlign: "left",
          marginLeft: 8,
        }}
      >
        {value ? onLabel || "正常" : offLabel || "已注销"}
      </div>
      <Switch
        checked={value}
        onChange={onChange}
        size="small"
        style={{
          backgroundColor: value ? theme.s : theme.wsu,
        }}
      />
    </Row>
  );
};
