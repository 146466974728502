/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:34
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-11 15:01:28
 */
/* eslint-disable react/display-name */
import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { getXianzhonglxName } from '../../models/AnnualCapitalFund'
import { DateTimeFormat, DateTimeFormatSimpleOnly } from '../../models/datetime'
import { Dimen } from '../../models/dimen'

export const Column = (): TableColumnType<any>[] => {
  return [
    {
      title: '时间',
      key: 'no',
      align: 'center',
      width: '11em',
      ellipsis: true,
      fixed: 'left',
      render: function element(_, t) {
        return (
          <div>
            {moment(t?.startTime)?.format(DateTimeFormatSimpleOnly)} ~
            {moment(t?.endTime)?.format(DateTimeFormatSimpleOnly)}
          </div>
        )
      },
    },
    {
      title: '人头数',
      dataIndex: 'headCount',
      width: '5em',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r?.headCount ? r?.headCount : 0
      },
    },
    {
      title: '人次数',
      dataIndex: 'personTime',
      width: '5em',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r?.personTime ? r?.personTime : 0
      },
    },
    {
      title: '人次人头比',
      dataIndex: 'personHeadScale',
      width: '6em',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r?.personHeadScale ? r?.personHeadScale : 0
      },
    },
    {
      title: '医疗费总金额（元）',
      key: 'totalAmount',
      width: '8em',
      align: 'right',
      ellipsis: true,
      render: function element(_, t) {
        return (
            <div>
              {Number(t?.totalAmount) > 0
                  ? Number(t?.totalAmount)?.toFixed(2)
                  : '0.00'}
            </div>
        )
      },
    },
    {
      title: '列支金额（元）',
      key: 'includedMedicalExpenses',
      width: '8em',
      align: 'right',
      ellipsis: true,
      render: function element(_, t) {
        return (
            <div>
              {Number(t?.includedMedicalExpenses) > 0
                  ? Number(t?.includedMedicalExpenses)?.toFixed(2)
                  : '0.00'}
            </div>
        )
      },
    },
    {
      title: '自理金额（元）',
      dataIndex: 'selfpayAmt',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return Number(t?.selfpayAmt) > 0
            ? Number(t?.selfpayAmt)?.toFixed(2)
            : '0.00'
      },
    },
    {
      title: '自费金额（元）',
      dataIndex: 'cashAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return Number(t?.cashAmount) > 0
            ? Number(t?.cashAmount)?.toFixed(2)
            : '0.00'
      },
    },
    {
      title: '次均列支（元）',
      key: 'includedMedicalExpensesPer',
      width: '8em',
      align: 'right',
      ellipsis: true,
      render: function element(_, t) {
        return (
            <div>
              {Number(t?.includedMedicalExpensesPer) > 0
                  ? Number(t?.includedMedicalExpensesPer)?.toFixed(2)
                  : '0.00'}
            </div>
        )
      },
    },
    {
      title: '人均列支（元）',
      key: 'includedMedicalExpensesPerson',
      width: '8em',
      align: 'right',
      ellipsis: true,
      render: function element(_, t) {
        return (
            <div>
              {Number(t?.includedMedicalExpensesPerson) > 0
                  ? Number(t?.includedMedicalExpensesPerson)?.toFixed(2)
                  : '0.00'}
            </div>
        )
      },
    },
  ]
}


export const Columns = (v?:any): TableColumnType<any>[] => {
  return [
    {
      title: '时间',
      key: 'no',
      align: 'center',
      width: '11em',
      ellipsis: true,
      fixed: 'left',
      render: function element(_, t) {
        return (
          <div>
            {moment(v?.startTime)
              ?.subtract(1, 'year')
              ?.format(DateTimeFormatSimpleOnly)}
            ~
            {moment(v?.endTime)
              ?.subtract(1, 'year')
              ?.format(DateTimeFormatSimpleOnly)}
          </div>
        )
      },
    },
    {
      title: '人头数',
      dataIndex: 'headCount',
      width: '5em',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r?.headCount ? r?.headCount : 0
      },
    },
    {
      title: '人次数',
      dataIndex: 'personTime',
      width: '5em',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r?.personTime ? r?.personTime : 0
      },
    },
    {
      title: '人次人头比',
      dataIndex: 'personHeadScale',
      width: '6em',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r?.personHeadScale ? r?.personHeadScale : 0
      },
    },
    {
      title: '医疗费总金额（元）',
      key: 'totalAmount',
      width: '8em',
      align: 'right',
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div>
            {Number(t?.totalAmount) > 0
              ? Number(t?.totalAmount)?.toFixed(2)
              : '0.00'}
          </div>
        )
      },
    },
    {
      title: '列支金额（元）',
      key: 'includedMedicalExpenses',
      width: '8em',
      align: 'right',
      ellipsis: true,
      render: function element(_, t) {
        return (
            <div>
              {Number(t?.includedMedicalExpenses) > 0
                  ? Number(t?.includedMedicalExpenses)?.toFixed(2)
                  : '0.00'}
            </div>
        )
      },
    },
    {
      title: '自理金额（元）',
      dataIndex: 'selfpayAmt',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return Number(t?.selfpayAmt) > 0
          ? Number(t?.selfpayAmt)?.toFixed(2)
          : '0.00'
      },
    },
    {
      title: '自费金额（元）',
      dataIndex: 'cashAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return Number(t?.cashAmount) > 0
          ? Number(t?.cashAmount)?.toFixed(2)
          : '0.00'
      },
    },
    {
      title: '次均列支（元）',
      key: 'includedMedicalExpensesPer',
      width: '8em',
      align: 'right',
      ellipsis: true,
      render: function element(_, t) {
        return (
            <div>
              {Number(t?.includedMedicalExpensesPer) > 0
                  ? Number(t?.includedMedicalExpensesPer)?.toFixed(2)
                  : '0.00'}
            </div>
        )
      },
    },
    {
      title: '人均列支（元）',
      key: 'includedMedicalExpensesPerson',
      width: '8em',
      align: 'right',
      ellipsis: true,
      render: function element(_, t) {
        return (
            <div>
              {Number(t?.includedMedicalExpensesPerson) > 0
                  ? Number(t?.includedMedicalExpensesPerson)?.toFixed(2)
                  : '0.00'}
            </div>
        )
      },
    },
  ]
}