/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 16:55:05
 * @LastEditors: K
 * @LastEditTime: 2022-07-22 11:06:06
 */
import { Button, Col, notification, Row, Space, TableColumnType } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { RootDispatch } from '../../../app/store';
import { EditableList } from '../../../compnents/list/EditableList';
import { NavBar } from '../../../compnents/nav/NavBar';
import { Columns } from './columns'
import { getRankingListPage, editRankingListPage, deleteRankingList } from '../composingSlice'
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { PlusOutlined } from '@ant-design/icons';
import RankingModal from './rankingModal';
import { getAppointTypeName } from '../../../models/appointment';

export const RankingListPage = () => {

  const dispatch = useDispatch<RootDispatch>()

  const [tableLoading, setTableLoading] = useState(false);   //表格loding

  const [isModalVisible, setIsModalVisible] = useState(false);   //排班弹窗是否显示

  const [modaltitleType, setModaltitleType] = useState<number>(0);    //排班弹窗标题

  const [tableInfo, setTableInfo] = useState();    //排班弹窗标题

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const getPage = () => {   //获取班次列表
    setTableLoading(true)
    dispatch(getRankingListPage(params)).then(unwrapResult).then((res) => {
      const data = {
        total: res.total,
        items: res,
      }
      setPage(data)
      setTableLoading(false)
    })
      .catch(err => {
        setTableLoading(false)
      })
  }


  useEffect(() => {
    if (params.current !== 0) {
      getPage()
    }
  }, [])



  return (
    <Col style={{
      height: "calc(100% - 20px)",
      overflow: "hidden",
      position: "relative",
      marginTop: 10,
      marginBottom: 10,
      padding: "0px 20px",
      display: "flex",
      flexDirection: "column",
    }}>
      <NavBar
        style={{ marginBottom: "10px" }}
        where={['排班', '班次设置']}
        backtrace={{
          name: '排班',
          path: '/composing',
          routeName: '排班',
          // state: {
          //   current: location?.state?.current,
          // },
        }}
      />
      <Row
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginBottom: '20px',
        }}
      >
          <Button type="primary" icon={<PlusOutlined />} onClick={() => {
          setModaltitleType(1)
            setIsModalVisible(true)
          }}>
            新增班次
        </Button>
      </Row>
      <EditableList
        style={{ flex: 1 }}
        loading={tableLoading}
        rowKey={(record: any) => record.rid}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        pagination={false}
        columns={Columns(
          params.size * (params.current - 1),
          (action: any, t: any, btnText: string) => {

            if (btnText == '删除') {
              if (t.appointmentStatus == 1) {
                   //直接删除班次
                dispatch(
                  deleteRankingList({
                    id: t.id,
                  })
                ).then(unwrapResult).then(res => {
                  notification.success({ message: '删除成功' });
                  getPage()
                })
                return
              } else {
                setModaltitleType(3)
              }
            } else {
              setModaltitleType(2)
            }
            setTableInfo(t)
            setIsModalVisible(true)

          },
        )}


      />
      <RankingModal
        title={modaltitleType}
        visible={isModalVisible}
        tableInfo={tableInfo}
        onOk={(obj) => {
          if (obj.textType == 3) {   //删除
            //直接删除班次
            dispatch(
              deleteRankingList({
                id: obj.id,
              })
            ).then(unwrapResult).then(res => {
              setIsModalVisible(false);
              getPage()
              notification.success({ message: '删除成功' });
            })
            return
          } else {  //新增/编辑
            dispatch(editRankingListPage(obj)).then(unwrapResult).then((res) => {  //新增编辑班次
              getPage()
              notification.success({ message: obj.id == null ? '新增成功' : '编辑成功' });
            })
          }
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      />
    </Col>
  );
}

