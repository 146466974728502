/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-31 11:11:05
 * @LastEditors: linxi
 * @LastEditTime: 2021-09-05 19:45:28
 */
import React, { useEffect } from "react";
import styles from "./Tooth.module.css";

export interface teethStrProps {
  LT?: any;
  RT?: any;
  LB?: any;
  RB?: any;
  tableShow?: () => void;
}

export const ToothTable: React.FC<teethStrProps> = ({
  LT,
  RT,
  LB,
  RB,
  tableShow = () => {},
}) => {
  return (
    <table
      className={styles.teethTable}
      onClick={() => {
        tableShow();
      }}
    >
      <tr>
        <td>{LT?.[0] !== "" ? LT : <span></span>}</td>
        <td>{RT?.[0] !== "" ? RT : <span></span>}</td>
      </tr>
      <tr>
        <td>{LB?.[0] !== "" ? LB : <span></span>}</td>
        <td>{RB?.[0] !== "" ? RB : <span></span>}</td>
      </tr>
    </table>
  );
};
