/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-08-04 14:01:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-13 11:41:56
 */
import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { EditableList } from '../../../compnents/list/EditableList'
import styles from './sendMessagePage.module.css'
import { Columns } from './columns'
import { RootDispatch } from '../../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getSendMessageList,
  getSmsTemplateTypeAsync,
} from '../sendMessageSlice'
import { SendMessageModal } from '../../A-toothModule/toothHome/modal/sendMessageModal'
import { MessageSignatureModal } from '../../A-toothModule/toothHome/modal/messageSignatureModal'
import { selectEdition } from '../../../app/applicationSlice'
import { getMessageCount, selectMessageCount } from '../../A-toothModule/toothHome/toothHomeSlice'

const { Option } = Select

export const SendMessagePage = () => {
  const dispatch = useDispatch<RootDispatch>()
  const [form] = Form.useForm()

  const edition = useSelector(selectEdition)

  const [head, tail] = getDateRange(DateRangeType.Today)

  const messageCount = useSelector(selectMessageCount)

  const [params, setParams] = useState<any>({
    current: 0,
    size: 10,
    startTime: head,
    endTime: tail,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const [sendMessageModal, setSendMessageModal] = useState(false) // 发送短信弹窗

  const [messageSignatureModal, setMessageSignatureModal] = useState(false) // 短信签名弹窗

  const [typeOptions, setTypeOptions] = useState<any[]>([])

  const getPage = () => {
     dispatch(getMessageCount())
    dispatch(getSendMessageList(params))
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          total: res.total,
          items: res.records,
        })
      })
      .catch(() => {
        // do nothing.
      })
  }
  useEffect(() => {
   
    form.submit()
    dispatch(getSmsTemplateTypeAsync())
      .then(unwrapResult)
      .then((res: any) => {
        setTypeOptions(res)
      })
  }, [])

  useEffect(() => {
    if (params.current != 0) {
      getPage()
    }
  }, [params])
  const [pageLoading, setPageLoading] = useState(false)
  return (
    <div className={styles.wrap}>
      {/* 查询区域 */}
      <Form
        form={form}
        onFinish={(values) => {
          setParams({
            ...params,
            ...values,
            startTime: values.actionTimeHead
              ? values.actionTimeHead
              : params.startTime,
            endTime: values.actionTimeTail
              ? values.actionTimeTail
              : params.endTime,
            actionTimeHead: undefined,
            actionTimeTail: undefined,
            current: 1,
          })
        }}
      >
        <Row
          style={{ marginTop: 10, marginBottom: 20 }}
          justify='space-between'
        >
          <Space>
            <DateSelect
              allowClear={false}
              style={{ width: '8.5rem' }}
              labelPrefix=''
              namePrefix='actionTime'
              placeholder='时间范围-时间'
              initialValue={DateRangeType.Today}
              onChange={() => form.submit()}
            />
            <Form.Item noStyle name='templateName'>
              <Input
                autoComplete='off'
                prefix={<SearchOutlined />}
                placeholder='患者姓名/助记码/手机号/病历号'
                style={{ width: '16rem' }}
                allowClear
                onChange={form.submit}
              />
            </Form.Item>
            <Form.Item noStyle name='sendStatus'>
              <Select
                placeholder='发送状态'
                onChange={form.submit}
                allowClear
                style={{ width: '8rem' }}
              >
                <Option value='0'>发送成功</Option>
                <Option value='1'>发送失败</Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle name='smsTemplateType'>
              <Select
                placeholder='短信类型'
                onChange={form.submit}
                allowClear
                style={{ width: '8rem' }}
              >
                {typeOptions.map((v) => (
                  <Option value={v.value} key={v.value}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Button type='primary' htmlType='submit'>
              查询
            </Button>
          </Space>
          {edition == 1 ? (
            <Space>
              <div>短信剩余：{messageCount}条</div>
              <Button
                type='primary'
                onClick={() => {
                  setMessageSignatureModal(true)
                }}
              >
                短信签名设置
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  setSendMessageModal(true)
                }}
              >
                群发短信
              </Button>
            </Space>
          ) : (
            <Space>
              <div>短信剩余：{messageCount}条</div>
            </Space>
          )}
        </Row>
      </Form>

      {/* 表格区域 */}
      <EditableList
        style={{ overflow: 'hidden' }}
        scroll={{ y: '620px' }}
        loading={pageLoading}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns(params.size * (params.current - 1))}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
        onRow={() => ({
          onDoubleClick: () => {
            return
          },
        })}
      />
      <SendMessageModal
        visible={sendMessageModal}
        onCancel={() => {
          setSendMessageModal(false)
        }}
        onOk={() => {
          setSendMessageModal(false)
          getPage()
        }}
      />

      <MessageSignatureModal
        visible={messageSignatureModal}
        onCancel={() => setMessageSignatureModal(false)}
      />
    </div>
  )
}
