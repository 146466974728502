import { RecipeCategory } from '../models/recipe'
import { API_PREFIX } from './constant'
import { Request } from './request'

export function getMaterialCompletions(
  params: { keyword: string; current: number },
  recipeCategory?: RecipeCategory,
  includeAllTcm?: boolean,
  insuranceCode?: string,
  isFullMatch?: any,
  isWubi?: any,
  isRestrictUser?: any,
  storehouseCategory?: any
): Request {
  let flag = 0
  switch (recipeCategory) {
    case RecipeCategory.Dispose:
      flag = 5
      break
    case RecipeCategory.Medicine:
    case RecipeCategory.ShuZhuFang:
      flag = 1
      break
    case RecipeCategory.TcmGranules:
      flag = includeAllTcm ? 4 : 2
      break
    case RecipeCategory.Tcm:
      flag = includeAllTcm ? 4 : 3
      break
  }
  console.log(storehouseCategory, 'storehouseCategory')

  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/page`,
    method: 'GET',
    params: {
      allStr: params.keyword,
      materialsCategory: -1,
      allMaterials: -1,
      tenantSupplierId: -1,
      state: 1,
      otc: -1,
      current: params.current,
      size: 5,
      flag:
        storehouseCategory == 2
          ? 4
          : storehouseCategory == 1
          ? 1
          : recipeCategory === undefined
          ? undefined
          : flag,
      insuranceCode,
      // 审批标志,写死查询审批已通过
      approveFlg: insuranceCode && insuranceCode != 'ZIFEI' ? null : 1,
      isFullMatch: isFullMatch?.toString() ? 0 : undefined,
      isWubi: isWubi || '0',
      isRestrictUser: isRestrictUser ? isRestrictUser : null,
    },
  }
}

export function getInventoryCompletions(
  params: {
    keyword: string
    current: number
    storehouseId?: string
    merge?: boolean
    serviceShopPrice?: string
    isAllInventory?: boolean
    isFullMatch?: any
    deadlineFlag?: any
    isWubi?: any
    isRestrictUser?: any
  },
  recipeCategory?: RecipeCategory,
  includeAllTcm?: boolean,
  insuranceCode?: string
): Request {
  let flag = 0
  switch (recipeCategory) {
    case RecipeCategory.Dispose:
      flag = 5
      break
    case RecipeCategory.Medicine:
    case RecipeCategory.ShuZhuFang:
      flag = 1
      break
    case RecipeCategory.TcmGranules:
      flag = includeAllTcm ? 4 : 2
      break
    case RecipeCategory.Tcm:
      flag = includeAllTcm ? 4 : 3
      break
  }
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/page`,
    method: 'GET',
    params: {
      name: params.keyword,
      current: params.current,
      size: 5,
      state: 1,
      flag,
      storehouseId: params.storehouseId,
      mergeFlag: params.merge ? 1 : 0,
      serviceShopPrice: params.isAllInventory ? null : 1,
      insuranceCode,
      // 审批标志,写死查询审批已通过
      approveFlg: insuranceCode && insuranceCode != 'ZIFEI' ? null : 1,
      isFullMatch: params?.isFullMatch?.toString() ? 0 : undefined,
      deadlineFlag: params?.deadlineFlag ? params?.deadlineFlag : undefined,
      isWubi: params?.isWubi || '0',
      isRestrictUser: params?.isRestrictUser ? params?.isRestrictUser : null,
    },
  }
}
export interface batchCailiaoParams {
  keyword: string
  current: number
  size: number
  storehouseId?: string
  merge?: boolean
  serviceShopPrice?: string
  insuranceCode?: string
  isFullMatch?: any
  deadlineFlag?: any
  isRestrictUser?: any
}
export function batchCailiao(params: batchCailiaoParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/page`,
    method: 'GET',
    params: {
      name: params.keyword,
      current: params.current,
      size: params.size,
      state: 1,
      flag: 5,
      mergeFlag: params.merge ? 1 : 0,
      serviceShopPrice: 1,
      insuranceCode: params.insuranceCode,
      // 审批标志,写死查询审批已通过
      approveFlg:
        params.insuranceCode && params.insuranceCode != 'ZIFEI' ? null : 1,
      deadlineFlag: params.deadlineFlag ? params.deadlineFlag : undefined,
      isRestrictUser: params?.isRestrictUser ? params?.isRestrictUser : null,
    },
  }
}

export interface batchtaocanParams {
  keyword: string
  current: number
  size: number
  storehouseId?: string
  merge?: boolean
  serviceShopPrice?: string
  insuranceCode?: string
  isFullMatch?: any
  deadlineFlag?: any
  isRestrictUser?: any
  flag?: any
}
export function batchTaocan(params: batchtaocanParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/page`,
    method: 'GET',
    params: {
      name: params.keyword,
      current: params.current,
      size: params.size,
      state: 1,
      flag: params?.flag ? params?.flag : '',
      mergeFlag: params.merge ? 1 : 0,
      serviceShopPrice: 1,
      insuranceCode: params.insuranceCode,
      // 审批标志,写死查询审批已通过
      approveFlg:
        params.insuranceCode && params.insuranceCode != 'ZIFEI' ? null : 1,
      isFullMatch: params?.isFullMatch?.toString() ? 0 : undefined,
      deadlineFlag: params.deadlineFlag ? params.deadlineFlag : undefined,
      isRestrictUser: params?.isRestrictUser ? params?.isRestrictUser : null,
    },
  }
}
export function getTreatmentServiceCompletions(
  params: {
    keyword: string
    current: number
  },
  insuranceCode?: string,
  isFullMatch?: any,
  isWubi?: any
): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/page`,
    method: 'GET',
    params: {
      billingCategory: -1,
      state: 1,
      size: 5,
      current: params.current,
      name: params.keyword,
      insuranceCode,
      // 审批标志,写死查询审批已通过
      approveFlg: insuranceCode && insuranceCode != 'ZIFEI' ? null : 1,
      isFullMatch: isFullMatch?.toString() ? 0 : undefined,
      isWubi: isWubi || '0',
    },
  }
}
