/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-11 11:39:50
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-11 11:49:40
 */
/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-11 11:39:05
 */
import _ from 'lodash'
import { fromMaterialJson, Material } from './material'
import { RecipeStatus } from './recipe'

export interface PaymentResults {
  id: string
  success: boolean
  patientName: string
  totalAmount: number
  totalPayAmount: number
  realPayAmount: number
  payMethod: number
  arrearageAmount: number
  receivePayAmount?:number
  arrearageFlg: number
  clsdamount: number
  refundAmount: number
  patientId: number
  realAmount: number
  treatmentId: string
  materials: Material[]
  paymentDetailRecordVOS: any[]
  state: RecipeStatus
  createTime?: string
  operateName?: string
  machiningTypeMsg?: string
  insuranceCode?: string
  ybSaleUploadStatus?:number
  traceCodeCnt?: number
}

export function fromJsons(json: any): PaymentResults {
  const payment = json.payment
  return {
    success: true,
    id: payment?.id?.toString() || '',
    receivePayAmount: payment?.receivePayAmount,
    patientName: payment?.patientName,
    patientId: payment?.patientId || 0,
    totalAmount: payment?.shouldPayAmount || 0,
    realPayAmount: payment?.realPayAmount || 0,
    payMethod: payment?.payMethod || 0,
    arrearageAmount: payment?.arrearageAmount || 0,
    arrearageFlg: payment?.arrearageFlg || 0,
    clsdamount: payment?.clsdamount || 0,
    totalPayAmount: payment?.totalPayAmount || 0,
    refundAmount: payment?.refundAmount || 0,
    realAmount: payment?.realPayAmount || 0,
    materials: json.recipeVO,
    paymentDetailRecordVOS: _.chain(json.paymentDetailRecordVOS)
      .map((m) => fromMaterialJson(m))
      .value(),
    state: payment?.state || RecipeStatus.ToSettle,
    treatmentId: payment?.treatmentId?.toString() || '',
    createTime: payment?.createTime,
    operateName: payment?.operateName,
    insuranceCode: payment?.insuranceCode,
    ybSaleUploadStatus: payment?.ybSaleUploadStatus,
    traceCodeCnt:json?.traceCodeCnt
  }
}
