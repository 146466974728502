/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-06 13:33:19
 * @LastEditors: sj
 * @LastEditTime: 2022-09-07 13:50:33
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

// 机构预警
export function remindCheckTenant(tenant: string): Request {
  return {
    url: `${API_PREFIX}/blade-reminder/reminder/checkTenant`,
    method: 'POST',
    body: {
      tenant,
    },
  }
}

// 保存病历时
export interface SaveMedicalParams {
  disease: string
  medicalItems?: any[]
}
export function remindSaveMedical(body: SaveMedicalParams): Request {
  return {
    url: `${API_PREFIX}/blade-reminder/reminder/saveMedical`,
    method: 'POST',
    body,
  }
}

// 保存处方时
export interface SaveRecipesParams {
  recipeName: string
  recipeItems?: any[]
}
export function remindSaveRecipes(body: SaveRecipesParams): Request {
  return {
    url: `${API_PREFIX}/blade-reminder/reminder/saveRecipes`,
    method: 'POST',
    body,
  }
}
