/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:43
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-06 17:00:58
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import * as T from '../../services/AnnualCapitalFund'

export const getAnnualCapitalFundList = createAsyncThunk<
  void,
  T.AnnualCapitalFundListParams,
  RootThunkApi
>('payment/getAnnualCapitalFundList', async (params, api) => {
  return sendAsync(T.AnnualCapitalFundList(params), api)
})

export const getAnnualCapitalFundAreaList = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('payment/getAnnualCapitalFundAreaList', async (params, api) => {
  return sendAsync(T.AnnualCapitalFundAreaList(), api)
})
