/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-05 10:20:03
 * @LastEditors: sj
 * @LastEditTime: 2022-08-01 14:37:53
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface AppointmentSettingParams {
  businessEndTime: string
  businessStartTime: string
  hiddenUnscheduledFlg: number
  id?: number
  list: any
  maxAppoNum: number
  timeScale: number
  timeoutMin: number
}
// 预约设置
export function appointmentSetting(body: AppointmentSettingParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/appointmentSettingSave`,
    method: 'POST',
    body,
  }
}
// 预约设置 回显
export function appointmentSettingDetail(): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentSetting`,
    method: 'GET',
  }
}
// 预约设置医生
export function appointmentSettingDoctors(): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentSettingDoctor`,
    method: 'GET',
  }
}

// 预约列表
export interface appointmentListParams {
  current: number
  doctorId?: string
  startTime?: string
  endTime?: string
  status?: string
  keyWord?: string
  tenantId?: string
  patientId?: string
}
export function appointmentList(params:appointmentListParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/appointmentRecordPage`,
    method: 'GET',
    params
  }
}

// 取消预约
export function cancelAppointment(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/appointmentRecordCancel`,
    method: 'POST',
    params
  }
}

// 确定
export function sureAppointment(data: any): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/appointmentRecordConfirm`,
    method: 'POST',
    params: data
  }
}

sureAppointment

//  日 周 预约
export interface dayWeekParams {
  type:number
  endTime:string
  startTime:string
}
export function dayWeek(params:dayWeekParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/appointmentRecordTimeList`,
    method: 'GET',
    params
  }
}

// 月 数据
export function monthAppointment(params: dayWeekParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/appointmentRecordMonthList`,
    method: 'GET',
    params,
  }
}