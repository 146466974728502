/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-02 11:38:59
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-03 17:30:36
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

// 获取产品中心信息
export function productCenterInfo(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/product/getProduct`,
    method: 'GET',
  }
}


// 获取本机构续期价格表
export function productCenterInfoYearAsync(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/product/getRenewDetailsNew`,
    method: 'GET',
  }
}


// 续订详情
export function renewDetails(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/product/getRenewDetails`,
    method: 'GET',
  }
}

// 获取支付二维码
export interface QRCodeParams {
  discountNum?: number
  durationNum?: number
  payType?: number
  productId?: number
  email?: string
  renewalPriceId?:any
}
export function QRCode(params:QRCodeParams): Request {
  return {
    url: params.renewalPriceId ? `${API_PREFIX}/blade-outpatient/product/getRenewalPayCode` :`${API_PREFIX}/blade-outpatient/product/getPayCode`,
    method: 'GET',
    params,
  }
}


// 支付状态查询
export function payStatus(orderId:string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/product/getOrderStatus`,
    method: 'GET',
    params:{
      orderId
    }
  }
}

// 咨询
export function consult(productId: number): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/product/consult`,
    method: 'POST',
    params: {
      productId,
    },
  }
}


export function sendMessage(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/product/sendRenewMassage`,
    method: 'GET',
  }
}
