/* eslint-disable react/prop-types */
/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 1373842098@qq.com
 * @Date: 2022-03-15 10:14:03
 * @LastEditors: sj
 * @LastEditTime: 2022-03-16 14:14:31
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  ModalProps,
  Row,
  Select,
} from 'antd'
import React, { ReactElement, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { insuranceCodeValue } from '../../../models/setting'
import {
  editMedicalSetting,
  getMedicalItem,
  getSystemList,
} from './TenentSettingSlice'
import 'moment/locale/zh-cn'
import locale from 'antd/es/date-picker/locale/zh_CN'
import { DateTimeFormatSimpleOnly } from '../../../models/datetime'
import moment from 'moment'
import { getTenantDetail } from '../tenantSlice'
const { Option } = Select

export interface SettingProps {
  tenantId: string
  id?: number
}

export const TenentSettingModal: React.FC<ModalProps & SettingProps> = ({
  tenantId,
  id,
  onOk,
  onCancel,
  visible,
}): ReactElement => {
  const dispatch = useDispatch<any>()

  const [form] = Form.useForm()

  const [checkTime, setCheckTime] = useState(false)

  const [loading, setLoading] = useState(false)

  const [status, setStatus] = useState(true)

  const [insuranceCode, setInsuranceCode] = useState<insuranceCodeValue[]>([])

  const [tenant, setTenant] = useState<any>()

  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  }

  const FormatLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  const loadingbtn = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const disabledStartDate: any = (current: any) => {
    const vs = form.getFieldsValue()
    if (vs.endTime) {
      return current && current > moment(vs.endTime).endOf('day');
    }
  }

  const disabledEndDate = (current: any) => {
    const vs = form.getFieldsValue()
    if (vs.startTime) {
      return current && current < moment(vs.startTime).endOf('day');
    }
    else {
      return current && current < moment().endOf('day');
    }
  }

  useEffect(() => {
    if (visible) {
      dispatch(getSystemList(1))
        .then(unwrapResult)
        .then((res: insuranceCodeValue[]) => {
          setInsuranceCode(res)
        })
      dispatch(getTenantDetail(tenantId))
        .then(unwrapResult)
        .then((tenant: any) => {
          setTenant(tenant)
          form.setFieldsValue({
            name: tenant.name,
            shortName: tenant.shortName,
          })
        })
    }
    return () => {
      form.resetFields()
    }
  }, [visible])

  useEffect(() => {
    if (id) {
      dispatch(getMedicalItem({ id, tenantId }))
        .then(unwrapResult)
        .then((res: any) => {
          form.setFieldsValue({
            ...res,
            endTime: moment(res.endTime),
            startTime: moment(res.startTime),
          })
          setStatus(res.status)
        })
    }
  }, [id])

  useEffect(() => {
    if (checkTime) {
      form.setFieldsValue({
        endTime: moment(tenant.endTime),
        startTime: moment(tenant.startTime),
      })
    } else {
      form.setFieldsValue({
        endTime: '',
        startTime: '',
      })
    }
  }, [checkTime])

  return (
    <Modal
      title={(id ? '修改' : '开通') + '医保险种'}
      visible={visible}
      width='900px'
      onOk={() => {
        form.submit()
        loadingbtn()
      }}
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <Form
        {...layout}
        form={form}
        onFinish={async (vs) => {
          const insuranceName =
            insuranceCode.find((v) => v.insuranceCode === vs.insuranceCode)
              ?.insuranceName || ''
          await dispatch(
            editMedicalSetting({
              ...vs,
              status: Number(status),
              endTime: moment(vs.endTime).format(DateTimeFormatSimpleOnly),
              startTime: moment(vs.startTime).format(DateTimeFormatSimpleOnly),
              insuranceName,
              tenantId,
              id,
            })
          )
            .then(unwrapResult)
            .then(onOk)
        }}
      >
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item name='name' label='机构名称' required {...FormatLayout}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='shortName' label='机构略称'>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='insuranceCode' label='开通险种'>
              <Select>
                {insuranceCode.length &&
                  insuranceCode.map((v: insuranceCodeValue) => (
                    <Option value={v.insuranceCode} key={v.insuranceCode}>
                      {v.insuranceName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item name='startTime' label='功能服务起始时间'>
              <DatePicker locale={locale} format={DateTimeFormatSimpleOnly} disabledDate={disabledStartDate} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='endTime' label='功能服务结束时间'>
              <DatePicker locale={locale} format={DateTimeFormatSimpleOnly} disabledDate={disabledEndDate} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='' label='' noStyle>
              <Checkbox
                checked={checkTime}
                onChange={() => {
                  setCheckTime(!checkTime)
                }}
              >
                跟随租户服务时间设置
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name='countryHospitalNb'
              label='医保国家机构编码'
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input maxLength={32} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='cityHospitalNb' label='本地医保机构编码'>
              <Input maxLength={32} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='yidiHospitalNb' label='异地医保机构编码'>
              <Input maxLength={32} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='ybIp' label='医保指定的IP地址'>
              <Input maxLength={32} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='ybMac' label='MAC地址'>
              <Input maxLength={32} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item name='' label='是否启用'>
              <Checkbox
                checked={status}
                onChange={() => {
                  setStatus(!status)
                }}
              >
                启用
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
