export enum DeliveryState {
  Unfilled = 0,
  Fulfilled = 1,
  Returned = 9,
}

export interface DeliveryResult {
  successful: boolean;
  message?: string;
  errors: {
    name: string;
    count: number;
    message: string;
  }[];
}
