/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-30 10:04:39
 * @LastEditors: K
 * @LastEditTime: 2022-10-08 16:28:36
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import {
  getAddMembersFn,
  getAddMembersFnParams,
  getAddMembersOperationFn,
  getMembersBaseInfoFn,
  getMembersBaseInfoFnParams,
  getMembersInfoFn,
  getMembersPageFn,
  getMembersPageFnParams,
  getMembersTopUpFn,
  getMembersTopUpFnParams,
  getMembersTopUpRecordFn,
  getMembersTopUpRecordFnParams,
  getSearchMembersFn,
  getSearchMembersFnParams,
} from '../../services/members'

//  会员管理-列表
export const getMembersPage = createAsyncThunk<
  any,
  getMembersPageFnParams,
  RootThunkApi
>('members/getMembersPage', async (params, api) => {
  return sendAsync(getMembersPageFn(params), api)
})
//  会员管理-新增
export const getAddMembers = createAsyncThunk<
  any,
  getAddMembersFnParams,
  RootThunkApi
>('members/getAddMembers', async (params, api) => {
  return sendAsync(getAddMembersFn(params), api)
})
//  会员管理-新增会员-操作人
export const getAddMembersOperation = createAsyncThunk<any, void, RootThunkApi>(
  'members/getAddMembersOperation',
  async (_, api) => {
    return sendAsync(getAddMembersOperationFn(), api)
  }
)
//  会员管理-会员基础信息
export const getMembersBaseInfo = createAsyncThunk<
  any,
  getMembersBaseInfoFnParams,
  RootThunkApi
>('members/getMembersBaseInfo', async (params, api) => {
  return sendAsync(getMembersBaseInfoFn(params), api)
})
//  会员管理-充值 or 退款详情信息
export const getMembersInfo = createAsyncThunk<
  any,
  getMembersBaseInfoFnParams,
  RootThunkApi
>('members/getMembersInfo', async (params, api) => {
  return sendAsync(getMembersInfoFn(params), api)
})
//  会员管理-充值 or 退款
export const getMembersTopUp = createAsyncThunk<
  any,
  getMembersTopUpFnParams,
  RootThunkApi
>('members/getMembersTopUp', async (params, api) => {
  return sendAsync(getMembersTopUpFn(params), api)
})
//  会员管理-充值记录
export const getMembersTopUpRecord = createAsyncThunk<
  any,
  getMembersTopUpRecordFnParams,
  RootThunkApi
>('members/getMembersTopUpRecord', async (params, api) => {
  return sendAsync(getMembersTopUpRecordFn(params), api)
})
//  药品零售-搜索会员
export const getSearchMembers = createAsyncThunk<
  any,
  getSearchMembersFnParams,
  RootThunkApi
>('members/getSearchMembers', async (params, api) => {
  return sendAsync(getSearchMembersFn(params), api)
})
