/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-29 18:03:51
 * @LastEditors: sj
 * @LastEditTime: 2022-12-08 10:29:45
 */
import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Space,
  Cascader as Cascaded,
  DatePicker,
  Select,
  notification,
} from "antd";
import moment from "moment";
import styles from "../ToothHome.module.css";
import { DateTimeFormatSimple } from "../../../../models/datetime";

interface birthdayQueryProps {
  messageCount: number
  onSend: () => void
  onSubmit: (params: any) => void
}
export const BirthdayQuery = ({
  messageCount,
  onSend,
  onSubmit
}: birthdayQueryProps) => {

  const { RangePicker } = DatePicker;

  const [form] = Form.useForm();
  useEffect(() => {
    form.submit()
  }, [])

  return (
    <Form
      form={form}
      initialValues={{
        // time: [moment(), moment()]
        month: moment().month() + 1,
        day: moment().date(),
      }}
      onFinish={(values) => {
        if (values.month && !values.day) {
          notification.info({
            message: '请选择日期',
          })
          return
        }
        if (!values.month && values.day) {
          notification.info({
            message: '请选择月份',
          })
          return
        }
        onSubmit({
          startTime:
            values.month && values.day
              ? values.month + '/' + values.day
              : undefined,
          endTime:
            values.month && values.day
              ? values.month + '/' + values.day
              : undefined,
        })
      }}
      style={{ padding: '0 10px' }}
    >
      {/* 工收费查询条件 */}
      <div className={styles.queryWrap}>
        <Space wrap>
          {/* <Form.Item name="time">
            <RangePicker allowClear={false} style={{ width: "14rem" }} onChange={() => form.submit()} />
          </Form.Item> */}
          <Form.Item
            colon={false}
            label='出生日期'
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 0,
            }}
          ></Form.Item>
          <Form.Item noStyle name='month'>
            <Select
              style={{ width: '8rem' }}
              placeholder='请选择月份'
              allowClear={false}
              onChange={form.submit}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((d) => (
                <Select.Option key={d} value={d}>
                  {d}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle name='day'>
            <Select
              style={{ width: '8rem' }}
              placeholder='请选择日期'
              allowClear={false}
              onChange={form.submit}
            >
              {[
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
                29,
                30,
                31,
              ].map((d) => (
                <Select.Option key={d} value={d}>
                  {d}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            查询
          </Button>
        </Space>
        <Space wrap>
          <div>短信剩余：{messageCount}条</div>
          <Button type='primary' onClick={() => onSend()}>
            批量发送短信
          </Button>
        </Space>
      </div>
    </Form>
  )
}
