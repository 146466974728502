import { Row, Form, Modal, Col, Radio, Select, notification, Input } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import {} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { GenderOptions, getGenderName } from '../../models/user'
import {
  getSourceTypeName,
  RegistrationCategory,
  SourceTypeCategories,
} from '../../models/registration'
import {
  getDepartmentDoctorList,
  getDoctorDepartmentList,
  getDoctorList,
} from '../sales/DoctorAccont/doctorAuditSlice'
import { Department } from '../../models/department'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getDoctorsInDepartment,
  getFullDeparmentList,
} from '../completion/completionSlice'
import styles from './Retail.module.css'
import { selectEdition, selectInsuranceArray } from '../../app/applicationSlice'
import { MedicelListValue } from '../../models/setting'

export const RetailPreferencesSettingModal = (props: {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const [form] = Form.useForm()

  const { Option } = Select

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const localData: any = JSON.parse(
    localStorage.getItem('retailPreferencesSettingData') || '{}'
  )

  useEffect(() => {
    if (props?.visible) {
      return
    } else {
      localStorage.setItem(
        'retailPreferencesSettingData',
        JSON.stringify({
          ...localData,
          sex: localData?.sex ? Number(localData?.sex) : 0,
          recipeType: localData?.recipeType ? Number(localData?.recipeType) : 0,
          tips: localData?.tips ? Number(localData?.tips) : 7,
        })
      )
    }
  }, [props?.visible])

  useEffect(() => {
    if (props?.visible) {
      form.setFieldsValue({
        insuranceStatus: localData?.insuranceStatus
          ? localData?.insuranceStatus
          : 'ZIFEI',
        sex: localData?.sex ? Number(localData?.sex) : 0,
        recipeType: localData?.recipeType ? Number(localData?.recipeType) : 0,
        tips: localData?.recipeType ? Number(localData?.tips) : 7,
      })
    }
  }, [props?.visible])

  return (
    <>
      <Modal
        title={`快速开单偏好设置`}
        width='500px'
        okText='确定'
        cancelText='取消'
        visible={props.visible}
        onOk={(e) => {
          form
            .validateFields()
            .then((values) => {
              localStorage.setItem(
                'retailPreferencesSettingData',
                JSON.stringify({ ...values })
              )
            })
            .catch(() => {
              // Do nothing.
            })
          // form.resetFields()
          props.onOk()
          notification.success({
            message: '保存成功，将再下次进入系统时生效',
          })
        }}
        onCancel={() => {
          form.resetFields()
          props.onCancel()
        }}
      >
        <Form
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          form={form}
        >
          <Row
            style={{ marginBottom: 10, width: '100%' }}
            align='middle'
            justify='space-between'
          >
            <Col span={24}>
              <div className={styles.title}>挂号类型 - 选择一个默认项：</div>
              <Col span={24} className={styles.main}>
                <Form.Item
                  label=''
                  name='insuranceStatus'
                  initialValue={0}
                  noStyle
                >
                  <Radio.Group>
                    <Radio
                      value={'ZIFEI'}
                      key={RegistrationCategory.SelfPay}
                      onClick={() => {
                        return
                      }}
                    >
                      自费
                    </Radio>
                    {insuranceArray.length > 0 &&
                      insuranceArray.map((v: any) => (
                        <Radio key={JSON.stringify(v)} value={v.insuranceCode}>
                          {v.insuranceName}
                        </Radio>
                      ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Col>
            <Col span={24}>
              <div className={styles.title}>性别 - 选择一个默认项：</div>
              <Col span={24} className={styles.main}>
                <Form.Item label='' name='sex' initialValue={0} noStyle>
                  <Radio.Group>
                    {GenderOptions?.map((g) => (
                      <Radio key={g} value={g}>
                        {getGenderName(g)}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Col>
            {hisVersion == 0 ? (
              <>
                {' '}
                <Col span={24}>
                  <div className={styles.title}>默认选择处方：</div>
                  <Col span={24} className={styles.main}>
                    <Form.Item
                      label=''
                      name='recipeType'
                      initialValue={0}
                      noStyle
                    >
                      <Radio.Group>
                        <Radio key={0} value={0}>
                          西药
                        </Radio>
                        <Radio key={1} value={1}>
                          中药
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={24}>
                  <div className={styles.title}>中药方默认帖数：</div>
                  <Col span={24} className={styles.main}>
                    <Form.Item label='' name='tips' noStyle>
                      <Input style={{ width: '100px' }}></Input>
                    </Form.Item>
                    <span style={{ marginLeft: '10px' }}>帖</span>
                  </Col>
                </Col>
              </>
            ) : (
              ''
            )}
          </Row>
        </Form>
      </Modal>
    </>
  )
}
