/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-01 12:00:34
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-21 14:51:12
 */

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Popover,
  Radio,
  Row,
} from 'antd'
import _ from 'lodash'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  getSettingDetail,
  saveSettingLabel,
  selectSettings,
  selectTenant,
} from '../../user/setting/settingSlice'
import styles from './PaymentConfirm.module.css'
import morenImg from './assets/morenImg.png'
import bankCardIcon from './assets/bankCardIcon.png'
import dyIcon from './assets/dyIcon.png'
import dzdpIcon from './assets/dzdpIcon.png'
import elmIcon from './assets/elmIcon.png'
import hykfxIcon from './assets/hykfxIcon.png'
import kjlIcon from './assets/kjlIcon.png'
import mtIcon from './assets/mtIcon.png'
import mtISmallcon from './assets/mtISmallcon.png'
import tbIcon from './assets/tbIcon.png'
import upsizedIcon from './assets/upsizedIcon.png'
import xhsIcon from './assets/xhsIcon.png'
import xykIcon from './assets/xykIcon.png'
import ybIcon from './assets/ybIcon.png'
import yhqIcon from './assets/yhqIcon.png'
import yhqTwoIcon from './assets/yhqTwoIcon.png'
import {
  getPaymentWayList,
  getPaymentWayListAddOrEditSubmit,
  getPaymentWayListAllSubmit,
  getPaymentWayListDelete,
} from './paymentConfirmSlice'
import { Checks } from '../../../models/setting'
import { ChargeSetting, getIconImg } from '../../../models/payment'

interface ChargeSettingModalProps {
  membersStatus?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}

export const ChargeSettingModal = ({
  membersStatus,
  visible,
  onCancel,
  onOk,
}: ChargeSettingModalProps): ReactElement => {
  const [form] = Form.useForm()

  const layout = {
    labelCol: {
      span: 5,
    },
  }
  const inputRef = useRef<Input>(null)

  const dispatch = useDispatch<RootDispatch>()

  const JuheShow: any = useSelector(selectTenant) //是否显示聚合支付按钮/type

  const [addPayTypeFlag, setAddPayTypeFlag] = useState(false)

  const [addValue, setAddValue] = useState<any>() //添加时的值

  const [paymentWayList, setPaymentWayList] = useState<any>()

  const [iconType, setIconType] = useState<any>() //icon选择框选中哪个的type

  const [selectIcon, setSelectIcon] = useState<any>() //选中的icon的id

  const [openVisible, setOpenVisible] = useState(false) //打开icon选择框

  const [openEditName, setOpenEditName] = useState(false) //是否打开编辑支付方式的文字以及icon

  const [openEditId, setOpenEditId] = useState<any>() //编辑文字时的id

  const [addOrEditFlag, setAddOrEditFlag] = useState<any>() //添加或者保存的标志

  const [formChangeCheckBox, setFormChangeCheckBox] = useState<any>() // 记录checkbox变化后的值

  const [selectResultLists, setSelectResultLists] = useState<any>()

  const [morenPayWayListData, setMorenPayWayListData] = useState<any>() //默认支付方式根据选中的支付方式来

  const [modalSelectIconType, setModalSelectIconType] = useState<any>('')

  const settings = useSelector(selectSettings)

  const [payList, setPayList] = useState<any>()

  useEffect(() => {
    {
      setPayList([
        {
          name: '现金',
          value: '1',
        },
        {
          name: '微信',
          value: '4',
        },

        {
          name: '支付宝',
          value: '3',
        },
        JuheShow?.openHubPay == 1 ||
        JuheShow?.openHubPayJiali == 1 ||
        JuheShow?.openHubPayYinlian == 1 ||
        JuheShow?.openHubPayKanglin == 1
          ? {
              name: '聚合支付',
              value: '2',
            }
          : '',
        {
          name: '银联',
          value: '5',
        },
        membersStatus != 0
          ? {
              name: '会员',
              value: '6',
            }
          : '',
      ])
    }
  }, [JuheShow, membersStatus])

  const iconList = [
    { img: ybIcon, type: 'ybIcon', width: '44px', height: '44px', id: 1 },
    {
      img: mtISmallcon,
      type: 'mtISmallcon',
      width: '44px',
      height: '44px',
      id: 2,
    },
    { img: dyIcon, type: 'dyIcon', width: '34px', height: '34px', id: 3 },
    { img: elmIcon, type: 'elmIcon', width: '48px', height: '48px', id: 4 },
    { img: dzdpIcon, type: 'dzdpIcon', width: '44px', height: '44px', id: 5 },

    { img: xhsIcon, type: 'xhsIcon', width: '40px', height: '40px', id: 6 },
    {
      img: mtIcon,
      type: 'mtIcon',
      width: '46px',
      height: '46px',
      id: 7,
    },
    { img: xykIcon, type: 'xykIcon', width: '46px', height: '46px', id: 8 },
    { img: yhqIcon, type: 'yhqIcon', width: '41px', height: '41px', id: 9 },
    { img: tbIcon, type: 'tbIcon', width: '38px', height: '38px', id: 10 },

    {
      img: hykfxIcon,
      type: 'hykfxIcon',
      width: '45px',
      height: '45px',
      id: 11,
    },
    { img: kjlIcon, type: 'kjlIcon', width: '40px', height: '40px', id: 12 },
    {
      img: yhqTwoIcon,
      type: 'yhqTwoIcon',
      width: '46px',
      height: '46px',
      id: 13,
    },
    {
      img: bankCardIcon,
      type: 'bankCardIcon',
      width: '42px',
      height: '42px',
      id: 14,
    },
    {
      img: upsizedIcon,
      type: 'upsizedIcon',
      width: '50px',
      height: '50px',
      id: 15,
    },
  ]

  const getMethodName = (v: any) => {
    if (v == '1') {
      return '现金'
    } else if (v == '3') {
      return '支付宝'
    } else if (v == '4') {
      return '微信'
    } else if (v == '2') {
      return '聚合支付'
    } else if (v == '5') {
      return '银联'
    } else if (v == '6') {
      return '会员'
    }
  }

  useEffect(() => {
    if (!visible) {
      form.resetFields()
      // 默认只有一个添加按钮,点击后展示添加的输入框
      setAddPayTypeFlag(false)
      // 新增或添加每点击时是关闭
      setAddOrEditFlag(false)
      // 默认关闭编辑文字框
      setOpenEditName(false)
    } else {
      getPaymentWayModalList()
      dispatch(getSettingDetail())
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      if (settings) {
        form.setFieldsValue({
          [Checks.Mrzffs]: Number(settings[Checks.Mrzffs]),
          [Checks.JEML]: settings[Checks.JEML] == '1' ? true : false,
        })
      }
    }
  }, [visible])

  useEffect(() => {
    if (paymentWayList?.length) {
      // formChangeCheckBox 是一个对象要转化为数组对象，然后过滤没选中的checkbox，拿到id,跟全部的匹配
      // 选中的支付方式需要在默认支付方式展示
      const selectCheckboxObject: any = []
      for (const v in formChangeCheckBox) {
        selectCheckboxObject.push({
          id: parseInt(v),
          value: formChangeCheckBox[v],
        })
      }

      // 过滤没选中的值,拿到选中的id
      const selectId = selectCheckboxObject
        ?.filter((v: any) => v?.value == true)
        ?.map((item: any) => item.id)

      const morenPayWayList = paymentWayList.filter((i: any) =>
        selectId.includes(i.id)
      )
      setMorenPayWayListData(morenPayWayList)
    }
  }, [formChangeCheckBox, paymentWayList])

  const getPaymentWayModalList = () => {
    dispatch(getPaymentWayList())
      .then(unwrapResult)
      .then((v: any) => {
        setPaymentWayList(v?.records)
        const arr: any = v?.records?.map((item: any) => ({
          [Number(item.id)]: item.status == 1 ? true : false,
        }))
        const mergedObject: any = {}
        arr.forEach((obj: any) => {
          for (const key in obj) {
            mergedObject[key] = obj[key]
          }
        })

        setFormChangeCheckBox(mergedObject)

        form.setFieldsValue({ ...mergedObject })
      })
  }

  const addOrEditPaymentWay = (v?: any) => {
    // 转化获取到的保存的值
    const result: any = []
    const submitData = form.getFieldsValue()
    for (const v in submitData) {
      result.push({ id: parseInt(v), value: submitData[v] })
    }
    const submitStatus = result?.filter((v: any) => v?.id)
    const status = submitStatus?.find((v: any) => v?.id == openEditId)?.value
    const methodIcon = iconList?.find((v) => v?.type == iconType)?.type
    const commonParam = {
      methodName: addValue,
      methodIcon: methodIcon,
    }
    const params = openEditId
      ? {
          ...commonParam,
          id: openEditId,
          status: status ? 1 : 0,
        }
      : {
          ...commonParam,
          status: 0,
        }
    if (addValue && methodIcon) {
      dispatch(getPaymentWayListAddOrEditSubmit({ ...params }))
        .then(unwrapResult)
        .then((v) => {
          setAddPayTypeFlag(false)
          setAddOrEditFlag(false)
          setOpenEditName(false)
          setIconType('')
          notification.success({
            message: '操作成功',
            duration: 1,
            onClose: () => {
              getPaymentWayModalList()
            },
          })
        })
    } else {
      notification.info({
        message: '请填写支付方式并选择图片',
        duration: 1,
        onClose: () => {
          setAddOrEditFlag(false)
        },
      })
    }
  }

  useEffect(() => {
    if (addOrEditFlag) {
      addOrEditPaymentWay()
    }
  }, [addOrEditFlag, openEditId, addValue, iconType])

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column', width: '387px' }}>
      <div
        style={{
          display: 'flex',
          height: '30px',
          paddingBottom: '10px',
          borderBottom: '1px solid #f0f0f0',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '6px',
          position: 'relative',
        }}
      >
        <div>图标选择</div>
        <div
          style={{ position: 'absolute', right: 0, cursor: 'pointer' }}
          onClick={() => {
            setOpenVisible(false)
          }}
        >
          <CloseOutlined />
        </div>
      </div>
      <div className={styles.popverConent}>
        {iconList?.map((v: any) => {
          return (
            <>
              <div
                className={styles.popverConentImgMain}
                onClick={() => {
                  setSelectIcon(v?.img)
                  setIconType(v?.type)
                  setTimeout(() => {
                    setOpenVisible(false)
                  }, 200)
                }}
              >
                <div
                  className={
                    v?.type == iconType
                      ? styles.popverConentImgSelect
                      : styles.popverConentImg
                  }
                >
                  <img
                    src={v?.img}
                    style={{ width: v?.width, height: v?.height }}
                  ></img>
                </div>
              </div>
            </>
          )
        })}
      </div>
    </div>
  )

  useEffect(() => {
    if (openVisible) {
      setIconType(modalSelectIconType)
    }
  }, [openVisible, modalSelectIconType])

  const JEQZContent = (
    <div>
      <div className={styles.imgWrap}></div>
      <div className={styles.text}>
        系统会为您计算一个四舍五入到1位小数的金额
      </div>
    </div>
  )

  return (
    <Modal
      visible={visible}
      title='收费设置'
      width={1080}
      maskClosable={false}
      onCancel={onCancel}
      footer={null}
      className={styles.modalContent}
    >
      <Form
        form={form}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        autoComplete='off'
        onFinish={(values: any) => {
          const payListNew = payList?.filter((l: any) => l != '')
          const num = 10 - Number(payListNew?.length)

          const selectResultList = selectResultLists?.filter(
            (items: any) => items?.value?.toString() == 'true'
          )
          if (selectResultList?.length > num) {
            notification.info({
              message: '支付方式最多勾选10个！',
            })
          } else {
            const pattern = /^[0-9]*$/
            const f = _.chain(values)
              .pickBy((_, k) => pattern.test(k))
              .mapValues(
                (v, k: any) =>
                  ({
                    id:
                      k == 1 || k == 2 || k == 3 || k == 4 || k == 5 || k == 6
                        ? ''
                        : k,
                    status:
                      k == 1 || k == 2 || k == 3 || k == 4 || k == 5 || k == 6
                        ? 1
                        : v
                        ? 1
                        : 0,
                    methodName:
                      k == 1 || k == 2 || k == 3 || k == 4 || k == 5 || k == 6
                        ? getMethodName(k?.toString())
                        : paymentWayList?.find((v: any) => v?.id == k)
                            ?.methodName,
                    methodIcon:
                      k == 1 || k == 2 || k == 3 || k == 4 || k == 5 || k == 6
                        ? ''
                        : paymentWayList?.find((v: any) => v?.id == k)
                            ?.methodIcon,
                  } as ChargeSetting)
              )
              .values()
              .value()
            const params = f?.filter((v: any) => v?.type != '')

            const submitParams = params?.map((v: any) => {
              return { ...v, status: v?.status }
            })
            // 保存支付方式
            dispatch(getPaymentWayListAllSubmit(submitParams))
              .then(unwrapResult)
              .then((v) => {
                form.resetFields()
                notification.success({
                  message: '操作成功',
                  onClose: () => {
                    getPaymentWayModalList()
                    setOpenVisible(false)
                  },
                })
                onOk()
              })
              .catch(() => {
                form.resetFields()
                onCancel()
                setOpenVisible(false)
              })

            // 默认支付方式 ,金额取整提示
            const t = _.chain(values)
              .pickBy((_, k) => pattern.test(k))
              .mapValues(
                (v, k: any) =>
                  ({
                    type: k,
                    value: k == 31 ? (v ? true : false) : v,
                  } as ChargeSetting)
              )
              .values()
              .value()
            const paramsPayMoney = t?.filter(
              (v: any) => v?.type == 7 || v?.type == 31
            )
            // 保存默认支付方式以及金额取整提示
            dispatch(
              saveSettingLabel({
                functionList: paramsPayMoney,
              })
            )
              .then(unwrapResult)
              .then(() => {
                dispatch(getSettingDetail())
                form.resetFields()
              })
              .catch(() => {
                form.resetFields()
                onCancel()
              })
          }
        }}
      >
        <Row>
          <Col
            span={3}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '10px',
            }}
          >
            支付方式 :
          </Col>
          <Col span={20}>
            <Row style={{ marginTop: '-6px' }}>
              <Col style={{ width: '20%' }}>
                <Form.Item name='1'>
                  <Checkbox defaultChecked disabled>
                    现金
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col style={{ width: '20%' }}>
                <Form.Item name='4'>
                  <Checkbox defaultChecked disabled>
                    微信
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col style={{ width: '20%' }}>
                <Form.Item name='3'>
                  <Checkbox defaultChecked disabled>
                    支付宝
                  </Checkbox>
                </Form.Item>
              </Col>
              {JuheShow?.openHubPay == 1 ||
                JuheShow?.openHubPayJiali == 1 ||
                JuheShow?.openHubPayYinlian == 1 ||
                (JuheShow?.openHubPayKanglin == 1 && (
                  <Col style={{ width: '20%' }}>
                    <Form.Item name='2'>
                      <Checkbox defaultChecked disabled>
                        聚合支付
                      </Checkbox>
                    </Form.Item>
                  </Col>
                ))}
              <Col style={{ width: '20%' }}>
                <Form.Item name='5'>
                  <Checkbox defaultChecked disabled>
                    银联
                  </Checkbox>
                </Form.Item>
              </Col>
              {membersStatus != 0 && (
                <Col style={{ width: '20%' }}>
                  <Form.Item name='6'>
                    <Checkbox defaultChecked disabled>
                      会员
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              {paymentWayList?.map((v: any) => {
                return (
                  <>
                    <Col
                      style={{
                        width:
                          openEditName && v?.id == openEditId ? ' 40% ' : '20%',
                      }}
                    >
                      <Row>
                        <Col span={openEditName && v?.id == openEditId ? 2 : 4}>
                          <Form.Item name={v?.id} valuePropName='checked'>
                            <Checkbox
                              onChange={(v) => {
                                const resultList: any = []
                                const formSubmitData = form.getFieldsValue()
                                for (const item in formSubmitData) {
                                  resultList.push({
                                    id: parseInt(item),
                                    value: formSubmitData[item],
                                  })
                                }
                                const selectResultList = resultList?.filter(
                                  (items: any) =>
                                    items?.value?.toString() == 'true'
                                )
                                const payListNew = payList?.filter(
                                  (l: any) => l != ''
                                )
                                const num = 10 - Number(payListNew?.length)
                                if (selectResultList?.length > num) {
                                  notification.info({
                                    message: '支付方式最多勾选10个！',
                                  })
                                }
                                setFormChangeCheckBox(form.getFieldsValue())
                                setSelectResultLists(selectResultList)
                              }}
                            ></Checkbox>
                          </Form.Item>
                        </Col>
                        <Col
                          span={openEditName && v?.id == openEditId ? 22 : 20}
                        >
                          <Form.Item>
                            {openEditName && v?.id == openEditId ? (
                              <div style={{ display: 'flex' }}>
                                <Input
                                  onChange={(e) => {
                                    setAddValue(e?.target?.value)
                                    setIconType(v?.methodIcon)
                                  }}
                                  maxLength={4}
                                  ref={inputRef}
                                  onClick={() => {
                                    inputRef.current?.select()
                                  }}
                                  defaultValue={v?.methodName}
                                  style={{
                                    width:
                                      openEditName && v?.id == openEditId
                                        ? '140px'
                                        : '112px',
                                    position: 'relative',
                                  }}
                                  placeholder='收费方式'
                                  suffix={
                                    <Popover
                                      content={content}
                                      title=''
                                      trigger='click'
                                      visible={openVisible}
                                      onVisibleChange={(visible) => {
                                        setOpenVisible(visible)
                                      }}
                                    >
                                      <img
                                        src={
                                          openEditName && v?.id == openEditId
                                            ? selectIcon ||
                                              getIconImg(v?.methodIcon) ||
                                              morenImg
                                            : morenImg
                                        }
                                        style={{
                                          width: '100%',
                                          height: '20px',
                                        }}
                                        onClick={() => {
                                          setModalSelectIconType(v?.methodIcon)
                                        }}
                                      />
                                    </Popover>
                                  }
                                />
                                <CheckCircleOutlined
                                  style={{
                                    fontSize: 19,
                                    marginLeft: '10px',
                                    position: 'relative',
                                    top: 7,
                                    color: '#027AFF',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setAddOrEditFlag(true)
                                  }}
                                />
                                <DeleteOutlined
                                  style={{
                                    color: 'red',
                                    fontSize: 19,
                                    marginLeft: '10px',
                                    position: 'relative',
                                    top: 7,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    if (v?.isUsed == 1) {
                                      notification.info({
                                        message: '该收费方式已被引用不可删除',
                                      })
                                    } else {
                                      Modal.confirm({
                                        title: '是否确认删除?',
                                        okText: '确认',
                                        cancelText: '取消',
                                        onOk: () => {
                                          dispatch(
                                            getPaymentWayListDelete(v?.id)
                                          )
                                            .then(unwrapResult)
                                            .then(() => {
                                              notification.success({
                                                message: '删除成功',
                                                duration: 1,
                                                onClose: () => {
                                                  getPaymentWayModalList()
                                                },
                                              })
                                            })
                                        },
                                      })
                                    }
                                  }}
                                />
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setAddPayTypeFlag(false)
                                  setOpenEditId(v?.id)
                                  setIconType(v?.methodIcon)
                                  setAddValue(v?.methodName)
                                  setSelectIcon('')
                                  setOpenVisible(false)
                                  setOpenEditName(true)
                                }}
                              >
                                {v?.methodName}
                              </span>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )
              })}
              {!addPayTypeFlag && (
                <Col span={4} style={{ marginRight: '20px', height: '56px' }}>
                  <PlusCircleOutlined
                    onClick={() => {
                      if (paymentWayList?.length == 10) {
                        notification.info({
                          message: '最多可添加10个支付方式！',
                        })
                      } else {
                        setOpenEditId('')
                        setOpenEditName(false)
                        setAddPayTypeFlag(true)
                        setSelectIcon('')
                        setOpenVisible(false)
                        // 清除之前的名称跟图标
                        setAddValue('')
                        setIconType('')
                      }
                    }}
                    style={{
                      color: '#2D7BFF',
                      fontSize: '20px',
                      marginTop: '6px',
                    }}
                  />
                </Col>
              )}
              {addPayTypeFlag && (
                <Col style={{ height: '56px' }} span={8}>
                  <Input
                    style={{ width: '109px' }}
                    placeholder='收费方式'
                    maxLength={4}
                    onChange={(v) => {
                      setAddValue(v?.target?.value)
                    }}
                    suffix={
                      <Popover
                        content={content}
                        title=''
                        trigger='click'
                        visible={openVisible}
                        onVisibleChange={(visible) => {
                          setOpenVisible(visible)
                        }}
                      >
                        <img
                          src={selectIcon || morenImg}
                          style={{
                            width: '100%',
                            height: '20px',
                          }}
                        />
                      </Popover>
                    }
                  />
                  <CheckCircleOutlined
                    style={{
                      fontSize: 19,
                      marginLeft: '10px',
                      position: 'relative',
                      top: 3.5,
                      color: '#027AFF',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setOpenVisible(false)
                      setAddOrEditFlag(true)
                    }}
                  />
                  <CloseCircleOutlined
                    style={{
                      fontSize: 19,
                      marginLeft: '10px',
                      position: 'relative',
                      top: 3.5,
                      color: 'red',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setOpenVisible(false)
                      setAddPayTypeFlag(false)
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row style={{ marginLeft: '-244px' }} className={styles.radioStyle}>
          <Col span={24}>
            <Form.Item label='默认支付方式' name={Checks.Mrzffs}>
              <Radio.Group>
                <Radio key={1} value={1}>
                  现金
                </Radio>
                <Radio key={4} value={4}>
                  微信
                </Radio>
                <Radio key={3} value={3}>
                  支付宝
                </Radio>
                {JuheShow?.openHubPay == 1 ||
                  JuheShow?.openHubPayJiali == 1 ||
                  JuheShow?.openHubPayYinlian == 1 ||
                  (JuheShow?.openHubPayKanglin == 1 && (
                    <Radio key={2} value={2}>
                      聚合支付
                    </Radio>
                  ))}
                <Radio key={5} value={5}>
                  银联
                </Radio>
                {morenPayWayListData?.map((v: any) => {
                  return (
                    <>
                      <Radio key={v?.id} value={v?.id}>
                        {v?.methodName}
                      </Radio>
                    </>
                  )
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginLeft: '-18px' }}>
          <Col span={12}>
            <Popover content={JEQZContent} placement='bottom' title='抹零'>
              <Form.Item
                label='金额取整提示'
                name={Checks.JEML}
                valuePropName='checked'
              >
                <Checkbox>开启</Checkbox>
              </Form.Item>
            </Popover>
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
            marginRight: '24px',
          }}
        >
          <Button
            style={{ marginRight: '20px', color: '#666666' }}
            onClick={() => {
              onCancel()
              setOpenVisible(false)
              form.resetFields()
            }}
          >
            取消
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            // disabled={openEditName || addPayTypeFlag}
          >
            确定
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}
