/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-21 16:46:45
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-09 09:45:14
 */
import moment from 'moment'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface RegistByPatientIdParams {
  patientId: string
  size?: number
  registrationId?: string
}
export function getRegistByPatientId(params: RegistByPatientIdParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/getRegistByPatientId`,
    method: 'GET',
    params,
  }
}

export interface PatientInfoParams {
  registrationId: string
  type?: number
}

export function getPatientInfo(params: PatientInfoParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/getPatientInfo`,
    method: 'GET',
    params,
  }
}

export interface PatientLatelyParams {
  endTime: string
  startTime: string
}
export function getPatientLately(params: PatientLatelyParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/getPatientLately`,
    method: 'GET',
    params,
  }
}

export function updatePatientTime(patientId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/updatePatientTime`,
    method: 'POST',
    body: {
      patientId,
      updateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    },
  }
}

export interface detailForPatientParams {
  id?: string
  insuranceCode?:any
}

export function detailForPatient(params:detailForPatientParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/detailForPatient`,
    method: 'GET',
    params,
  }
}

export function getAllRecipeByPatientId(patientId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/getAllRecipeByPatientid`,
    method: 'GET',
    params: {
      patientId,
    },
  }
}

export function getChangetreatment(patientId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/oral/changetreatment`,
    method: 'GET',
    params: {
      patientId,
    },
  }
}

export function getAllRecipeByRegistrationId(registrationId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/getAllRecipeByRegistrationId`,
    method: 'GET',
    params: {
      registrationId,
    },
  }
}

export function getAllTreatmentByPatientid(patientId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/getAllTreatmentByPatientid`,
    method: 'GET',
    params: {
      patientId,
    },
  }
}

export interface RecipeItemByRecipeIdParams {
  recipeId: string
  tenantId: number
}
export function getRecipeItemByRecipeId(
  params: RecipeItemByRecipeIdParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/getRecipeItemByRecipeIdCode`,
    method: 'GET',
    params,
  }
}


export function getPatientGroup(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientgroup/list`,
    method: 'GET',
    params: {
      size: 100,
    },
  }
}

export interface AddPatientGroupParams {
  groupName:string
  id?:string
}
export function addPatientGroup(body:AddPatientGroupParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientgroup/submit`,
    method: 'POST',
    body
  }
}

export interface RemovePatientGroupParams {
  ids: string
}
export function removePatientGroup(params: RemovePatientGroupParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientgroup/remove`,
    method: 'POST',
    params,
  }
}

export function removePatient(params: RemovePatientGroupParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/remove`,
    method: 'POST',
    params,
  }
}

export interface PatientByGroupparams {
  key?: string
  attendingDoctorId?: string
  counselorId?: string
  treatmentItem?: string
  current?: number
  size?: number
  groupId:string
  introducer?:string
  source?:number
  orderType ?:number
}
export function getPatientByGroup(params: PatientByGroupparams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/getPatientByGroup`,
    method: 'GET',
    params,
  }
}

export interface EditGroupParams {
  groupId:number
  patientIds:string[]
}

export function addGroup(body: EditGroupParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/addGroup`,
    method: 'POST',
    body,
  }
}

export function updateGroup(body: EditGroupParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/updateGroup`,
    method: 'POST',
    body,
  }
}

export interface PatienHandoverParams {
  patientIds: string[]
  counselorId?: string
  attendingDoctorId?: string
  attendingDoctor?: string
  counselor?:string
}
export function patienHandover(body: PatienHandoverParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/patienHandover`,
    method: 'POST',
    body,
  }
}

export interface RegistByPatientIdGneralParams {
  patientId: string
  registrationId?: string
  size?: number
}
export function getRegistByPatientIdGneral(params: RegistByPatientIdGneralParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/getRegistByPatientIdGneral`,
    method: 'GET',
    params,
  }
}

export function getAllTreatmentWithRecipeByPatientid(
  params: RegistByPatientIdGneralParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/getAllTreatmentWithRecipeByPatientid`,
    method: 'GET',
    params,
  }
}


export interface PatientTodayParams {
  endTime:string
  startTime:string
  key?:string
  type:string
}
export function getPatientToday(params: PatientTodayParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/getPatientToday`,
    method: 'GET',
    params,
  }
}

export function patientrelationship(patientId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientrelationship/list`,
    method: 'GET',
    params: {
      patientId,
      size: 200,
    },
  }
}

export interface AddPatientrelationshipParams {
  patientId:string
  patientRelationships:any[]
}
export function addPatientrelationship(body: AddPatientrelationshipParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientrelationship/submit`,
    method: 'POST',
    body
  }
}

export interface ExternalprocessPrams {
  size: number
  current: number
  doctorId?: string
  processProvider?: string
  status?: string
  patientId?: string
}
// 外加工列表
export function externalprocessList(
  params: ExternalprocessPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/externalprocess/list`,
    method: 'GET',
    params,
  }
}

// 新增外加工
export interface ExternalprocessAddParams {
  addressee?: string
  addresseePhone?: string
  createUser?: number
  doctorId?: number
  doctorName?: string
  factoryPrice?: number
  id?: number
  isDeleted?: number
  logisticsOmpany?: string
  orderNo?: string
  patientId?: number
  patientPrice?: number
  processCycle?: string
  processEndTime?: string
  processItemColorNo?: string
  processItemDisease?: string
  processItemPrice?: string
  processItemTooth?: string
  processNumber?: number
  processProvider?: string
  processStartTime?: string
  recipeId?: number
  remark?: string
  state?: number
  status?: number
  tenantId?: number
  updateUser?: number
  externalProcessRecipeList?: any[]
  externalProcessItemList?: any[]
}
export function externalprocessAdd(body: ExternalprocessAddParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/externalprocess/submit`,
    method: 'POST',
    body
  }
}

// 删除外加工
export function externalprocessRemove(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/externalprocess/remove`,
    method: 'POST',
    params: {
      id,
    },
  }
}

// 外加工详情
export function externalprocessDetail(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/externalprocess/detail`,
    method: 'GET',
    params: {
      id,
    },
  }
}

// 外加工 关联处置
export interface tRecipeItemsByPatientIdParams {
  patientId: string
  externalProcessId?: string
}
export function getRecipeItemsByPatientId(
  params: tRecipeItemsByPatientIdParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/getRecipeItemsByPatientId`,
    method: 'GET',
    params: {
      ...params,
      size: 200,
    },
  }
}

// 历史加工单位
export function historyProvider(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/externalprocess/historyProvider`,
    method: 'GET',
  }
}
// 约 弹窗信息
export function getPatientLastAppointmentInfo(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getPatientLastAppointmentInfo`,
    method: 'GET',
    params
  }
}