import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Popover,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  pageDurgsystem,
  selectDurgsystemData,
} from '../durgsystem/durgsystemSlice'
import { storePageParams } from '../../services/storesearch'
import { DateSelect, RecentTimes } from '../../compnents/form/DateSelect'
import {
  BillingCategoryName,
  InvoiceBillingCategories,
} from '../../models/billingCategory'
import { setCurrent } from './inventoryInitSlice'
import styles from './InventoryInit.module.css'
import { FilterIconOutlined, PrintOutlined } from '../../compnents/icons/Icons'
import { useLocation } from 'react-router-dom'
import { stringTrim } from '../../utils/StringUtils'
import { ThemeContext } from '../../theme/ThemeContext'
const { Option } = Select

export const Storesearchquery = (props: {
  loading?:any
  onValueChange: (queries: storePageParams) => void
  onBatchInitialization: () => void
}) => {
  const [form] = Form.useForm()

  const [popoverForm] = Form.useForm()

  const dispatch = useDispatch()

  const theme = useContext(ThemeContext)

  const data: any = useSelector(selectDurgsystemData)

  const location: any = useLocation()

  const dataParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  const [open, setOpen] = useState(false)

  useEffect(() => {
    dispatch(pageDurgsystem(1, 100, 1))
    form.setFieldsValue({
      state: 1,
      showFlag: 0,
      mergeFlag: 0,
      ...dataParams,
    })
    popoverForm?.setFieldsValue({
      chrgitmLvs: dataParams?.chrgitmLvs,
      anaesthesiaTag: dataParams?.anaesthesiaTag == 1 ? true : false,
      antibioticTag: dataParams?.antibioticTag == 1 ? true : false,
      basicMedicineTag: dataParams?.basicMedicineTag == 1 ? true : false,
      natNegoDrugFlag: dataParams?.natNegoDrugFlag == 1 ? true : false,
    }),
      form.submit()
  }, [])

  const refresh = () => {
    props.onValueChange({
      ...dataParams,
      ...form.getFieldsValue(),
      ...popoverForm.getFieldsValue(),
      chrgitmLvs: popoverForm.getFieldsValue()?.chrgitmLvs
        ? popoverForm.getFieldsValue()?.chrgitmLvs?.join(',')
        : '',
      anaesthesiaTag: popoverForm.getFieldsValue()?.anaesthesiaTag
        ? Number(popoverForm.getFieldsValue()?.anaesthesiaTag)
        : null,
      antibioticTag: popoverForm.getFieldsValue()?.antibioticTag
        ? Number(popoverForm.getFieldsValue()?.antibioticTag)
        : null,
      basicMedicineTag: popoverForm.getFieldsValue()?.basicMedicineTag
        ? Number(popoverForm.getFieldsValue()?.basicMedicineTag)
        : null,
      natNegoDrugFlag: popoverForm.getFieldsValue()?.natNegoDrugFlag
        ? Number(popoverForm.getFieldsValue()?.natNegoDrugFlag)
        : null,
      current: 1,
    })
  }

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(values: any) => {
        const current = dataParams.current
        props.onValueChange({
          ...dataParams,
          ...values,
          current: current || 1,
        })
      }}
    >
      <Row gutter={10} wrap={false} style={{ height: '42px' }}>
        <Col span={2}>
          <Form.Item name='name' getValueFromEvent={stringTrim}>
            <Input
              size='middle'
              placeholder='关键词'
              allowClear
              prefix={<SearchOutlined />}
              onChange={refresh}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='storehouseId'>
            <Select
              allowClear
              placeholder='库房列表'
              dropdownClassName={styles.select}
              showSearch
              onChange={refresh}
              // suffixIcon={<CaretDownFilled />}
              optionFilterProp='children'
            >
              {data.map((v: any, i: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='billingCategory'>
            <Select
              placeholder='开票项目'
              allowClear
              onChange={refresh}
              dropdownClassName={styles.select}
              // suffixIcon={<CaretDownFilled />}
            >
              {InvoiceBillingCategories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {BillingCategoryName(v)}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='state'>
            <Select
              placeholder='是否停用'
              onChange={refresh}
              // suffixIcon={<CaretDownFilled />}
              dropdownClassName={styles.select}
            >
              <Option value={1}>是否停用-否</Option>
              <Option value={0}>是否停用-是</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='showFlag'>
            <Select
              placeholder='零库存'
              allowClear
              onChange={refresh}
              // suffixIcon={<CaretDownFilled />}
              dropdownClassName={styles.select}
            >
              <Option value={0}>零库存-否</Option>
              <Option value={1}>零库存-是</Option>
            </Select>
          </Form.Item>
        </Col>
        {/*<Col span={2}>*/}
        {/*  <Form.Item name='mergeFlag'>*/}
        {/*    <Select*/}
        {/*      placeholder='合并'*/}
        {/*      allowClear*/}
        {/*      onChange={refresh}*/}
        {/*      dropdownClassName={styles.select}*/}
        {/*      // suffixIcon={<CaretDownFilled />}*/}
        {/*    >*/}
        {/*      <Option value={0}>合并-否</Option>*/}
        {/*      <Option value={1}>合并-是</Option>*/}
        {/*    </Select>*/}
        {/*  </Form.Item>*/}
        {/*</Col>*/}
        <Col>
          <DateSelect
            placeholder='有效期至'
            allowClear={false}
            labelPrefix=''
            options={RecentTimes}
            namePrefix='registrationTime'
            onChange={refresh}
          />
        </Col>{' '}
        <Col flex={1}>
          <Popover
            visible={open}
            overlayClassName={styles.formQueryPopover}
            content={
              <>
                <Form
                  form={popoverForm}
                  onFinish={(val) => {
                    refresh()
                    setOpen(false)
                  }}
                >
                  <Col span={24} style={{ marginLeft: '24px' }}>
                    <Form.Item label='等级' name='chrgitmLvs'>
                      <Checkbox.Group>
                        <Checkbox value={'01'}>甲</Checkbox>
                        <Checkbox value={'02'}>乙</Checkbox>
                        <Checkbox value={'03'}>丙</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                  <Row>
                    <Col style={{ margin: '4px 0 0 0 ' }}>药品范围：</Col>
                    <Col span={5}>
                      <Form.Item
                        label=''
                        name='natNegoDrugFlag'
                        valuePropName='checked'
                      >
                        <Checkbox>国谈药</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label=''
                        name='antibioticTag'
                        valuePropName='checked'
                      >
                        <Checkbox>抗生素</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label=''
                        name='basicMedicineTag'
                        valuePropName='checked'
                      >
                        <Checkbox>基药</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label=''
                        name='anaesthesiaTag'
                        valuePropName='checked'
                      >
                        <Checkbox>毒麻类</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      height: '1px',
                      background: '#f0f0f0',
                      marginTop: '10px',
                    }}
                  ></Row>
                  <Space
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginTop: '10px',
                    }}
                  >
                    <Button
                      size='middle'
                      type={'ghost'}
                      onClick={() => {
                        setOpen(false)
                      }}
                    >
                      取消
                    </Button>
                    <Button size='middle' type='primary' htmlType='submit'>
                      确定
                    </Button>
                  </Space>
                </Form>
              </>
            }
            title=''
            trigger='click'
          >
            <Form.Item name='' initialValue={'筛选'}>
              <Button
                style={{
                  color: theme.tc2,
                  width: '78px',
                  textAlign: 'left',
                  // overflow: 'hidden',
                  // textOverflow: 'ellipsis',
                }}
                icon={
                  <FilterIconOutlined
                    style={{
                      width: '14px',
                      height: '14px',
                      position: 'relative',
                      top: 2,
                      left: -2,
                      color: '#666',
                    }}
                  />
                }
                onClick={() => {
                  setOpen(true)
                }}
              >
                筛选
              </Button>
            </Form.Item>
          </Popover>
        </Col>
        <Col span={4} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            loading ={props.loading}
            type='primary'
            style={{ marginRight: '10px' }}
            onClick={() => {
              refresh()
            }}
          >
            查询
          </Button>
          <Button
            loading ={props.loading}
            type='primary'
            onClick={() => {
              props.onBatchInitialization()
            }}
          >
            一键初始化
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
