/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-17 09:56:05
 * @LastEditors: sj
 * @LastEditTime: 2022-11-30 17:31:31
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 16:16:11
 * @LastEditors: sj
 * @LastEditTime: 2022-08-05 11:57:56
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Form, Row, Select, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../../app/store'
import { getOutsideStatus, outsideStatusOptions } from '../../../../../models/toothOutside'
import { StationCategory } from '../../../../../models/user'
import { getUsersInDepartmentAsync } from '../../../../completion/completionSlice'
import { selectHistoryProvider } from '../../patientSlice'

export const Query = (props: {
  onValueChange: (params: any) => void
}): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const { Option } = Select

  const [doctorList, setDoctorList] = useState<any>([])

  const historyProviderOptions = useSelector(selectHistoryProvider)

  useEffect(() => {
    dispatch(
      getUsersInDepartmentAsync({
        departmentId: "",
        stationCategory: StationCategory.DoctorOrPharmacist,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setDoctorList(res)
      })

  }, [dispatch])

  useEffect(() => {
    form.submit()
  }, [])

  return (
    <Form
      form={form}
      onFinish={(values) => {
        props.onValueChange({
          ...values,
          current: 1,
        })
      }}
    >
      <Row style={{ marginTop: 10, marginBottom: 20 }} justify='space-between'>
        <Space>
          <Form.Item noStyle name='processProvider'>
            <Select
              style={{ width: '10rem' }}
              placeholder='加工单位'
              allowClear
              onChange={form.submit}
            >
              {historyProviderOptions.map((d) => (
                <Select.Option key={d} value={d}>
                  {d}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='doctorId' noStyle>
            <Select
              placeholder='医生列表'
              allowClear
              onChange={form.submit}
              showSearch
              style={{ width: '10rem' }}
              filterOption={(input, option) => {
                return option?.className
                  ? ((option?.className as unknown) as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                  : false
              }}
            >
              {doctorList.map((v: any) => (
                <Option className={v.mnemonicCode} key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='status' noStyle>
            <Select
              placeholder='状态'
              allowClear
              onChange={form.submit}
              style={{ width: '10rem' }}
            >
              {outsideStatusOptions.map((v) => (
                <Option key={v} value={v}>
                  {getOutsideStatus(v)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            type='primary'
            htmlType='submit'
          >
            查询
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
