import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from "antd"
import { SearchOutlined } from "@ant-design/icons"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import {} from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from "../../../../compnents/form/DateSelect"
import { MaintenanceDrugParams } from "../../../../services/maintenanceDrug"
import { TimeRangeFieldName } from "../../../../compnents/date/TimeSelect"
export const MaintenanceDrugsQuery = (props: {
  timeHead?: string
  timeTail?: string
  onValueChange: (queries: MaintenanceDrugParams) => void
}): ReactElement => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const { Option } = Select
  useEffect(() => {
    form.setFieldsValue({
      [DateRangeFieldName]: DateRangeType.ThisYear,
      [TimeRangeFieldName]: DateRangeType.ThisYear,
    })
    form.submit()
  }, [])
  return (
    <>
      <Form
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        form={form}
        onFinish={(values) => props.onValueChange(values)}
      >
        <Row
          style={{ marginBottom: 10, width: "100%" }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='' noStyle>
              <Input
                autoComplete='off'
                placeholder='药品名称或助记码'
                style={{ width: 200 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item name='' noStyle>
              <Select
                placeholder='药品列表'
                allowClear
                style={{ width: "200px" }}
                onChange={() => form.submit()}
              >
                <Option value='1'>11</Option>
              </Select>
            </Form.Item>
          </Space>
          <Button
            type='primary'
            onClick={() => props.onValueChange(form.getFieldsValue())}
          >
            查询
          </Button>
        </Row>
      </Form>
    </>
  )
}
