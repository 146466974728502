import {TableColumnType} from "antd";
import React from "react";
import LimitPriceChange from "./limitPriceChange";
import moment from "moment/moment";
import {DateTimeFormatSimpleOnly} from "../../../models/datetime";

export const LimitPriceChangeColumn = (
  onClick: (text: string, id: any, item?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      fixed: 'left',
      // eslint-disable-next-line react/display-name
      render: (_1, _2, index) => <span>{startIndex + index + 1}</span>,
    },{
      title: '医保目录编码',
      // width: '6rem',
      dataIndex: 'hilistCode',
      align: 'center',
      ellipsis: true,
    },{
      title: '医保目录名称',
      // width: '6rem',
      dataIndex: 'hilistName',
      align: 'center',
      ellipsis: true,
    },{
      title: '目录限价变动',
      // width: '6rem',
      dataIndex: '',
      align: 'center',
      ellipsis: true,
      render: (_, t) => {
        return `${parseFloat(t.oldHilistPrice).toFixed(4)} => ${parseFloat(
          t.hilistPrice
        ).toFixed(4)}`
      },
    }
    ,{
      title: '目录限价变动日期',
      // width: '6rem',
      dataIndex: 'changeDate',
      align: 'center',
      ellipsis: true,
      render: (_, t) => {
        return moment(t.changeDate).format(DateTimeFormatSimpleOnly)
      },
    }
  ]
}