/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-07 14:36:54
 * @LastEditors: sj
 * @LastEditTime: 2022-06-07 17:14:04
 */
import { TableColumnType } from "antd";

// 4207
export const CostInfoColumus = (startIndex: number,): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "定点医药机构名称",
      align: "center",
      dataIndex: "fixmedinsName",
    },

    {
      title: "商品名",
      align: "center",
      dataIndex: "prodname",
    },
    {
      title: "单价",
      align: "center",
      dataIndex: "pric",
    },
    {
      title: "数量",
      align: "center",
      dataIndex: "cnt",
    },
    {
      title: "明细项目费用总额",
      align: "center",
      dataIndex: "detItemFeeSumamt",
    },
    {
      title: "开单科室名称",
      align: "center",
      dataIndex: "bilgDeptName",
    },
    {
      title: "开单医师姓名",
      align: "center",
      dataIndex: "bilgDrName",
    },
    {
      title: "经办人姓名",
      align: "center",
      dataIndex: "opterName",
    },
    {
      title: "费用发生时间",
      align: "center",
      dataIndex: "feeOcurTime",
    },
    {
      title: "创建时间",
      align: "center",
      dataIndex: "crteTime",
    },

  ]

}

// 4208
export const ClinicInfoColumus = (startIndex: number,): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "定点医药机构名称",
      align: "center",
      dataIndex: "fixmedinsName",
    },

    {
      title: "经办机构",
      align: "center",
      dataIndex: "optinsNo",
    },
    {
      title: "经办人姓名",
      align: "center",
      dataIndex: "opterName",
    },
    {
      title: "经办时间",
      align: "center",
      dataIndex: "optTime",
    },
    {
      title: "人员姓名",
      align: "center",
      dataIndex: "psnName",
    },
    {
      title: "创建人姓名",
      align: "center",
      dataIndex: "crterName",
    },
    {
      title: "创建时间",
      align: "center",
      dataIndex: "crteTime",
    },
  ]
}

// 4209
export const DiagnoseInfoColumus = (startIndex: number,): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "医药机构就诊ID",
      align: "center",
      dataIndex: "fixmedinsMdtrtId",
    },
    {
      title: "诊断科室",
      align: "center",
      dataIndex: "diagDept",
    },
    {
      title: "诊断名称",
      align: "center",
      dataIndex: "diagName",
    },
    {
      title: "诊断医师姓名",
      align: "center",
      dataIndex: "diagDrName",
    },
    {
      title: "诊断时间",
      align: "center",
      dataIndex: "diagTime",
    },
    {
      title: " 创建人",
      align: "center",
      dataIndex: "crterName",
    },
    {
      title: "创建时间",
      align: "center",
      dataIndex: "crteTime",
    },
  ]

}