import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import {} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from "../../../compnents/form/DateSelect";
import { InventoryRecordsParams } from "../../../services/inventoryRecords";
import { setCategory } from "../../putstorage/putstorageSlice";
import { getFullStorageHouseList as getFullStoragehouseList } from "../../completion/completionSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../../app/store";
import styles from "./invent.module.css";
import {
  BillingCategoryName,
  InvoiceBillingCategories,
} from "../../../models/billingCategory";
export const InventoryRecordsQuery = (props: {
  onValueChange: (queries: InventoryRecordsParams) => void;
  outClued: () => void;
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [head, tail] = getDateRange(DateRangeType.ThisMonth);
  const [storehouses, setStorehouses] = useState<any[]>([]);

  const [params, setParams] = useState<InventoryRecordsParams>({
    current: 1,
    timeHead: head,
    timeTail: tail,
  });

  useEffect(() => {
    dispatch(getFullStoragehouseList())
      .then(unwrapResult)
      .then((ss: any) => {
        setStorehouses(ss);
        if (ss.length > 0) {
          setParams({ ...params, storehouseId: ss[0].id });
          form.setFieldsValue({
            ...params,
            storehouseId: ss[0].id,
            timeHead: head,
            timeTail: tail,
          });
          form.setFieldsValue({
            timeHead: head,
            timeTail: tail,
            // storehouseId:storeHouse
          });
          form.submit();
        }
      })
      .catch(() => {
        //
      });
  }, []);

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(values) => props.onValueChange(values)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='name' noStyle>
              <Input
                autoComplete='off'
                placeholder='请输入药品名称/助记码/条形码/物资编号'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item name='storehouseId' noStyle>
              <Select
                style={{ width: 100 }}
                placeholder='库房名称'
                allowClear
                showSearch
                dropdownClassName={styles.select}
                optionFilterProp='chidren'
                onChange={() => form.submit()}
                onSelect={(key: any) => {
                  dispatch(setCategory(key))
                }}
              >
                {storehouses.map((v: any) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name='billingCategory' label='开票项目' noStyle>
              <Select
                placeholder='开票项目'
                allowClear
                style={{ width: 100 }}
                onChange={() => form.submit()}
                dropdownClassName={styles.select}
              >
                {InvoiceBillingCategories.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {BillingCategoryName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name='materialsCategory' noStyle>
              <Select
                placeholder='物资类别'
                allowClear
                style={{ width: 100 }}
                onChange={() => form.submit()}
                dropdownClassName={styles.select}
              >
                <Option value='0'>药品</Option>
                <Option value='1'>材料</Option>
                <Option value='2'>器械</Option>
                <Option value='3'>保健品</Option>
                <Option value='4'>其他</Option>
              </Select>
            </Form.Item>
            <DateSelect
              style={{ width: '8.5rem' }}
              labelPrefix=''
              namePrefix='time'
              placeholder='盘点时间-今天'
              initialValue={DateRangeType.ThisMonth}
              onChange={() => form.submit()}
              allowClear={false}
            />
          </Space>
          <Col>
            <Button
                type='primary'
                style={{ marginRight: '10px' }}
                onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
            <Button type='primary' onClick={() => props.outClued()}>
              导出
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
};
