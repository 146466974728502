/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-17 10:58:18
 * @LastEditors: linxi
 * @LastEditTime: 2021-11-17 11:00:50
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import _ from "lodash"
import { sendAsync } from "../../app/applicationSlice"
import { RootThunkApi } from "../../app/store"
import {
  appealParams,
  standCountValue,
  standDetailValue,
  standListParams,
  standListValue,
} from "../../models/standbook"
import * as S from "../../services/journal"

// 警报数量
export const getStandCount = createAsyncThunk<
  standCountValue[],
  void,
  RootThunkApi
>("stand/getCount", async (_, api) => {
  return sendAsync(S.getStandCount(), api)
})

// 警报列表
export const getStandList = createAsyncThunk<
  standListValue,
  standListParams,
  RootThunkApi
>("stand/getStandList", async (params, api) => {
  return sendAsync(S.getStandList(params), api)
})

// 警报详情
export const getStandDetail = createAsyncThunk<
  standDetailValue,
  string,
  RootThunkApi
>("stand/getStandDetail", async (id, api) => {
  return sendAsync(S.getStandDetail(id), api)
})

// 警报申诉
export const appealSave = createAsyncThunk<void, appealParams, RootThunkApi>(
  "stand/appealSave",
  async (params, api) => {
    return sendAsync(S.appealSave(params), api)
  }
)
