/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 19:32:02
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-18 15:45:49
 */
import { Form, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./composing.module.css";
import { ExclamationOutlined } from "@ant-design/icons";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const data = [
  {
    name: "休息",
    startTime: "-",
    endTime: "-",
    id:0
  },
  {
    name: "早班",
    startTime: "9:00",
    endTime: "16:00",
    id:1
  },
  {
    name: "晚班",
    startTime: "16:00",
    endTime: "22:00",
    id:2
  },
  {
    name: "夜班",
    startTime: "22:00",
    endTime: "6:00",
    id:3
  },
];

export const ComposingModal = (props: {
  title: any;
  visible: boolean;
  onOk: (title: string) => void;
  onCancel: () => void;
}) => {
  const [form] = Form.useForm();

  return (
    <div>
      <Modal
        title={props.title}
        visible={props.visible}
        onOk={() => {
          props.onOk(props.title)
          form.resetFields()
        }}
        onCancel={() => {
          form.resetFields()
          props.onCancel()
        }}
        width={550}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={styles.modalIcon}>
            <ExclamationOutlined />
          </div>
          <div className={styles.modalTitle}>
            {props.title == '重置排班'
              ? '确认重置排班?'
              : '当前如果已有排班的医生, 复制时将不覆盖已有排班'}
          </div>
        </div>
      </Modal>
    </div>
  )
};
