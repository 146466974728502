/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-02 13:56:07
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface OutPatientRegistrationReportPrams {
  current: number
  size: number
  startTime: string
  endTime: string
  insuranceCode?:any
}

// 列表
export function outPatientRegistrationReportList(
  params: OutPatientRegistrationReportPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/getRegistionStatistics`,
    method: 'GET',
    params,
  }
}

// 导出
export const getExportFn = (params: OutPatientRegistrationReportPrams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/registionStatisticsExportExcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
