/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-15 14:11:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-01 13:33:57
 */
/* eslint-disable react/display-name */
import { TableColumnType, Tooltip } from 'antd'
import React from 'react'





export const Columns = (
  startIndex: number,
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '5rem',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    // {
    //   title: '短信模板名称',
    //   dataIndex: 'templateName',
    //   key: 'templateName',
    //   align: 'center',
    // },
    {
      title: '接收人',
      dataIndex: 'reviceName',
      key: 'reviceName',
      align: 'center',
      width: '6rem',
    },
    {
      title: '发送时间',
      dataIndex: 'sendTime',
      align: 'center',
      width: '8rem',
    },

    //   title: '失败/成功信息',
    //   dataIndex: 'failMsg',
    //   align: 'center',
    //   width: '10rem',
    // },
    {
      title: '短信类型',
      dataIndex: 'smsTemplateStr',
      align: 'center',
      width: '8rem',
    },

    {
      title: '短信内容',
      dataIndex: 'content',
      width: '24rem',
      align: 'center',
      ellipsis: true,
      render: (_, t) => (t?.content ? t?.content : '-'),
      // render: function content(_, t) {
      //   return t?.content ? (
      //     <div
      //       // style={{
      //       //   whiteSpace: 'break-spaces',
      //       //   width: '100%',
      //       //   textAlign: 'left',
      //       // }}
      //     >
      //       {t.content}
      //     </div>
      //   ) : (
      //     '-'
      //   )
      // },
    },
    {
      title: '发送状态',
      dataIndex: 'sendStatus',
      align: 'center',
      render: (t) =>
        t == 0 ? '发送成功' : t == 2 ? '发送中' : t == 1 ? '发送失败' : '未知',
      width: '7rem',
    },
    {
      title: '失败/成功信息',
      dataIndex: 'failMsg',
      align: 'center',
      // render: (t) =>
      //   t == 0 ? '发送成功' : t == 2 ? '发送中' : t == 1 ? '发送失败' : '未知',
      width: '8rem',
      ellipsis:true
    },
  ]
}