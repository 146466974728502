/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-01 12:00:34
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-17 09:55:34
 */

import { Button, Col, Form, Input, Modal, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import styles from './PaymentConfirm.module.css'

interface JuheIptShowModalProps {
  juheText?: any
  visible: boolean
  JHZFMoney?: any
  onCancel: () => void
  onOk: () => void
  JHZFInputChange: (v?: any) => void
  cancelPayMoney: () => void
  searchPayState: () => void
  JuheIptDisabled?: any
  cancelPayBtn?: any
  loading?: any
  payCode?: any
}
export const JuheIptShowModal = ({
  juheText,
  visible,
  JHZFMoney,
  onCancel,
  onOk,
  JHZFInputChange,
  cancelPayMoney,
  searchPayState,
  JuheIptDisabled,
  cancelPayBtn,
  // loading,
  payCode,
}: JuheIptShowModalProps): ReactElement => {
  const [form] = Form.useForm()

  const inputRef = useRef<any>(null)

  const dispatch = useDispatch<RootDispatch>()

  return (
    <Modal
      title='聚合支付结算'
      width={600}
      visible={visible}
      onCancel={() => {
        onCancel()
      }}
      onOk={() => {
        onOk()
      }}
      destroyOnClose
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        name='basic'
        form={form}
        onFinish={(values) => {
          return
        }}
      >
        <Row align='middle' style={{ marginBottom: '20px' }}>
          <Col flex='7rem' style={{ textAlign: 'right' }}>
            {'聚合支付：'}
          </Col>
          <Col style={{ position: 'relative' }}>
            <Input
              disabled
              style={{
                width: '18rem',
                marginLeft: 10,
                fontSize: '16px',
              }}
              value={JHZFMoney || 0}
            />
          </Col>
        </Row>
        <Row align='middle'>
          <Col flex='7rem' style={{ textAlign: 'right' }}>
            {'扫描收款码：'}
          </Col>
          <Col style={{ position: 'relative' }}>
            <Input
              autoFocus
              style={{
                width: '18rem',
                marginLeft: 10,
                fontSize: '16px',
                color: 'green',
              }}
              disabled={JuheIptDisabled}
              inputMode='decimal'
              maxLength={18}
              value={payCode}
              onChange={(e) => {
                const { value } = e.target
                const reg = /^[0-9]+$/
                if (reg.test(value) || value === '') {
                  JHZFInputChange && JHZFInputChange(value)
                }
              }}
            />
          </Col>
          <Col>
            {cancelPayBtn && (
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  cancelPayMoney && cancelPayMoney()
                }}
              >
                撤销收款
              </Button>
            )}
            {!cancelPayBtn && (
              <Button
                // loading={loading}
                // disabled={loading}
                type='primary'
                ghost
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  searchPayState()
                }}
              >
                查询付款状态
              </Button>
            )}
          </Col>
          <Col span={24}>
            <div
              style={{
                color: 'green',
                marginLeft: '122px',
                marginTop: '16px',
                fontStyle: 'italic',
              }}
            >
              {juheText}
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
