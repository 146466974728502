import {
  Modal,
  message,
  Form,
} from "antd";
import React, {  useEffect, useState } from "react";
import { ReactElement } from "react";
import _, { divide } from "lodash";
import { EditableList,  } from "../../../../compnents/list/EditableList";
import { TreatCostDetailQuery } from "./Query";
import { useDispatch, useSelector } from "react-redux";
import { treatmentCostParams } from "../../../../services/treatmentCost";
import { TreatCostDetailColumns } from "./columns";
import { DateTimeFormat, DateTimeFormatSimple } from "../../../../models/datetime";
import moment from "moment";
import styles from "../treatmentCost.module.css"
import { getTreatmentCostDetailList, selectCurrent, selectTotal, selectTreatmentCostData, setCurrent } from "./modalSlice";

export const TreatmentCostModel = (props: {
  timeHead?: any;
  timeTail?: any;
  drugName?:string;
  id?:number;
  isModalVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
}): ReactElement => {

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectTreatmentCostData);
  
  const [size, setSize] = useState(10);

  const [queries, setQueries] = useState<treatmentCostParams>();
  
  useEffect(() => {
    if(queries?.timeHead){
       dispatch(getTreatmentCostDetailList({ 
      timeHead:(queries.timeHead).format(DateTimeFormat),
      timeTail:(queries.timeTail).format(DateTimeFormat),
      drugName:props.drugName,
      current: Current,
      size
    }))
    }else{
      dispatch(getTreatmentCostDetailList({ 
        timeHead:props.timeHead,
        timeTail:props.timeTail,
        drugName:props.drugName,
        current: Current,
        size
      })) 
    }
   

  }, [queries, size, Current,props.drugName])
  
  return (
    <div  >
    <Modal
   className={styles.modal}   
      title="诊疗费用统计"
      width="1020px"
      footer={null}
      visible={props.isModalVisible}
      onOk={() => {
        props.onOk();
        form.resetFields(); 
      }}
      onCancel={() => {
        props.onCancel();
        
      }}
    >
      <TreatCostDetailQuery
          timeHead ={
            props.timeHead ? props.timeHead  : moment().startOf("month").format(DateTimeFormat)
          }
          timeTail={
            props.timeTail ? props.timeTail  : moment().endOf("month").format(DateTimeFormat)
          }
          onValueChange={(v) => {
            setQueries({ ...queries, ...v });
            dispatch(setCurrent(1));
           
          }}          

        />
      <EditableList
       className={styles.treatmentCostModal}
       page={{
        items: data,
        current: Current,
        size: size,
        total: total,
      }}
        rowKey={(t: any) => t.id}
        columns={TreatCostDetailColumns(size * (Current - 1))}
        onChangePage={(current, pageSize) => {
          setSize(pageSize as number);
          dispatch(setCurrent(current));
        }}
      />

    </Modal>
  </div>
  );
};
