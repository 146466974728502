/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-13 11:48:42
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-31 11:23:25
 */
import { TableColumnType } from "antd";
import moment from "moment";
import React, { ReactElement } from "react";
import { Dimen } from "../../models/dimen";
import { StateSwitch } from "../../compnents/widgets/StateSwitch";

export const OperatingRoomColumns = (
  toggleStatus: (v: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "手术室名称",
      align: "center",
      width: Dimen.Num,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "状态",
      key: "actions",
      align: "center",
      width: Dimen.Actions,
      render: function DepartmentStatus(_, d): ReactElement {
        return (
          <StateSwitch
            value={d.state === 1}
            onChange={() => toggleStatus(d)}
          />
        );
      },
    },
  ];
};
