/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {nanoid, unwrapResult} from '@reduxjs/toolkit'
import {Button, Col, DatePicker, Form, Input, Modal, notification, Row, Timeline, Upload,Space} from 'antd'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styles from './Live.module.css'
import React from 'react'
import {RootDispatch} from '../../app/store'
import moment from 'moment'
import {DateTimeFormat} from '../../models/datetime'
import {getTypeName, standDetailValue} from '../../models/standbook'
import {getBase64, stringTrim} from "../../utils/StringUtils";
import {dateFormat} from "../../models/appointment";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {selectUserId, selectUserName} from "../../app/applicationSlice";
import {JuhePremium} from "../retail/retailSlice";
import {createLive} from "../../services/live";
import {saveLive} from "./liveSlice";
import {OssParams} from "../../models/ossparams";
import {getFileUploadToken, uploadFileCloud} from "../../compnents/file/fileSlice";
import {getUrl} from "../treatment/diagnosis/diagnosisSlice";

export const StartLiveModal = (props: {
    modalVisible?: boolean
    onOk: () => void
    onCancel: () => void
}) => {
    const dispatch = useDispatch<RootDispatch>()
    const [form] = Form.useForm()
    return (
        <Modal
            width={530}
            title='开播设置'
            footer={null}
            centered
            bodyStyle={{
                maxHeight: '700px',
                overflowX: 'hidden',
            }}
            onCancel={() => {
                props.onCancel()
            }}
            visible={props.modalVisible}
            className={styles.detail}
        >
            <Form
                form={form}
                labelCol={{span: 6}}
                wrapperCol={{span: 15}}
                >
                请在钉钉的直播窗口中，点击分享按钮，复制分享链接到下方输入框中，并点击确认开播。
                <Form.Item
                    label="分享链接"
                    name="title"
                >
                    <TextArea
                        showCount
                        maxLength={200}
                        rows={6}
                        placeholder='多行输入'
                    />
                </Form.Item>

                <Space style={{display:"flex",justifyContent:"flex-end"}}>
                    <Button
                        type='primary'
                        htmlType='submit'
                    >
                        确认开播
                    </Button>
                    <Button
                        onClick={() => {
                            props.onCancel();
                            form.resetFields()
                        }}
                    >
                        取消
                    </Button>
                </Space>

            </Form>
        </Modal>
    )
}
