/*
 * @Descripttion: 
 * @version: 
 * @Author: suiyue
 * @email: 1373842098@qq.com
 * @Date: 2022-01-12 13:49:58
 * @LastEditors: sj
 * @LastEditTime: 2022-03-16 15:04:18
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, sendAsync } from "../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../app/store";
import { insuranceCodeValue, MedicelListValue } from "../../../models/setting";
import * as service from "../../../services/tenentSetting";

interface tenentSettingState {
  settings: any;
}

const initialState: tenentSettingState = {
  settings: [],
};

const tenentSettingSlice = createSlice({
  name: "tenentSettingSlice",
  initialState,
  reducers: {
    setDetail: (state, action) => {
      state.settings = _.reduce(
        action.payload,
        (acc, { type, value }) => ({
          ...acc,
          [type]: value === "true" ? true : value === "false" ? false : value,
        }),
        {}

      );
    },
  },
});

export const {
  setDetail,
} = tenentSettingSlice.actions;


export const selectTenentSettings = (state: RootState): any =>
  state.tenentSetting.settings;

export const saveTenentSettingLabel = createAsyncThunk<
  void,
  service.TenentSettingParams,
  RootThunkApi
>("completion/getDiseaseCompletionsStatus", async (body, api) => {
  return sendAsync(service.getTenentSettingSave(body), api);
});


export function getSettingDetails(tenentId?: any): RootThunk {
  return api(service.getSettingDetail(tenentId), (data, dispatch) => {
    dispatch(setDetail(data));
  });
}

export const getMedicalSettingList = createAsyncThunk<
  MedicelListValue[],
  number,
  RootThunkApi
>("completion/getMedicalSetting", async (params, api) => {
  return sendAsync(service.getMedicalSettingList(params), api)
})

export const editMedicalSetting = createAsyncThunk<
  void,
  service.MedicalSettingParams,
  RootThunkApi
>("completion/editMedicalSetting", async (body, api) => {
  return sendAsync(service.editMedicalSetting(body), api)
})

export const getSystemList = createAsyncThunk<
  insuranceCodeValue[],
  number,
  RootThunkApi
>("completion/removeMediclaSetting", async (status, api) => {
  return sendAsync(service.getSystemList(status), api)
})

export const getMedicalItem = createAsyncThunk<
  insuranceCodeValue,
  service.MedicalItemParams,
  RootThunkApi
>("completion/getMedicalItem", async (params, api) => {
  return sendAsync(service.getMedicalItem(params), api)
})


export default tenentSettingSlice.reducer;