/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-11 16:34:29
 */
import { Button, Popover, Row, Spin, Tabs } from 'antd'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectEdition,
  selectQueryLoadingTag,
} from '../../app/applicationSlice'
import Qrcode from 'qrcode.react'
import { TabBar } from '../../compnents/widgets/TabBar'
import styles from './newTransferFormTab.module.css'
import { RootDispatch } from '../../app/store'
import { getWXCode } from '../../layouts/DefaultLayout'
import { RemoteVerificationForms } from './RemoteVerificationForm/RemoteVerificationForm'
import { LocalVerificationForms } from './LocalVerificationForm/LocalVerificationForm'

const { TabPane } = Tabs

export const NewTransferFormTab = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [selectKey, setSelectKey] = useState('0')

  const location: any = useLocation()

  return (
    <>
      <TabBar
        className={styles.tabs}
        onChange={(v) => {
          setSelectKey(v)
        }}
      >
        <TabPane tab='本地核拨表' key='0'>
          <LocalVerificationForms selectedTab={selectKey} />
        </TabPane>
        <TabPane tab='异地核拨表' key='1'>
          <RemoteVerificationForms selectedTab={selectKey} />
        </TabPane>
      </TabBar>
      <></>
    </>
  )
}
