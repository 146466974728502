/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-15 10:59:12
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-22 15:12:26
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { stringTrim } from '../../../../utils/StringUtils'
import { setSendIDs } from '../../toothHome/toothHomeSlice'
import {
  addGroupAsync,
  addPatientGroupAsync,
  getPatientGroupAsync,
  patienHandoverAsync,
  selectAttendingDoctorList,
  selectCounselorList,
  selectGroupList,
  setGroupList,
  updateGroupAsync,
} from '../patientSlice'

interface PatientGroupProps {
  type: number
  visible: boolean
  patientIds?: string[]
  editGroupId?: string
  onCancel?: () => void
  onOk?: () => void
  isASync?: boolean
}

export const PatientGroupModal = ({
  type,
  visible,
  onCancel,
  patientIds = [],
  onOk,
  editGroupId,
  isASync = false,
}: PatientGroupProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()
  const [form] = Form.useForm()

  const groupList = useSelector(selectGroupList)

  const attendingDoctorList = useSelector(selectAttendingDoctorList)

  const counselorList = useSelector(selectCounselorList)

  useEffect(() => {
    if (visible) {
      dispatch(getPatientGroupAsync())
        .then(unwrapResult)
        .then((res: any) => {
          dispatch(setGroupList(res.records))
        })
    }
  }, [visible])

  useEffect(() => {
    if (editGroupId) {
      const groupName = groupList.find((v: any) => v.id == editGroupId)
        ?.groupName
      form.setFieldsValue({
        groupName,
      })
    }
  }, [editGroupId])

  const getTitle = useMemo(() => {
    switch (type) {
      case 1:
        return '新增分组'
      case 2:
        return '分组名称修改'
      case 3:
        return '加入分组'
      case 4:
        return '患者交接'
      case 5:
        return '更改分组'
      default:
        return '新增分组'
    }
  }, [type])

  const close = useCallback(() => {
    form.resetFields()
    onCancel && onCancel()
  }, [])

  const submit = useCallback(() => {
    notification.success({
      message: '操作成功',
    })
    form.resetFields()
    dispatch(setSendIDs([]))
    onOk && onOk()
  }, [isASync])

  const rules = [
    {
      required: true,
    },
  ]
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }
  return (
    <Modal
      title={getTitle}
      visible={visible}
      centered
      onCancel={close}
      footer={null}
    >
      <Form
        form={form}
        colon={false}
        {...layout}
        onFinish={(vs) => {
          if (groupList.some((v) => v.groupName == vs.groupName)) {
            notification.error({
              message: '分组名称不允许重复',
            })
            return
          }
          if (type == 4 && !vs.counselorId && !vs.attendingDoctorId) {
            notification.info({
              message: '请至少选择一个交接对象',
            })
            return
          }
          if (type == 1 || type == 2) {
            dispatch(
              addPatientGroupAsync({
                groupName: vs.groupName,
                id: editGroupId || undefined,
              })
            )
              .then(unwrapResult)
              .then(() => {
                submit()
              })
          }
          if (type == 3) {
            dispatch(
              addGroupAsync({
                groupId: vs.groupId,
                patientIds,
              })
            )
              .then(unwrapResult)
              .then(() => {
                dispatch(getPatientGroupAsync())
                  .then(unwrapResult)
                  .then((res: any) => {
                    dispatch(setGroupList(res.records))
                    submit()
                  })
              })
          }
          if (type == 4) {
            const counselor: any = counselorList
              ?.filter((v) => v.id == vs.counselorId)
              ?.map((v) => {
                return v.realname
              })
              ?.toString()
            const attendingDoctor: any = attendingDoctorList
              ?.filter((v) => v.id == vs.attendingDoctorId)
              ?.map((v) => {
                return v.realname
              })
              ?.toString()
            dispatch(
              patienHandoverAsync({
                counselor: counselor,
                attendingDoctor: attendingDoctor,
                counselorId: vs.counselorId,
                attendingDoctorId: vs.attendingDoctorId,
                patientIds,
              })
            )
              .then(unwrapResult)
              .then(() => {
                submit()
              })
          }
          if (type == 5) {
            dispatch(
              updateGroupAsync({
                groupId: vs.changeGroupId,
                patientIds,
              })
            )
              .then(unwrapResult)
              .then(() => {
                if (!isASync) {
                  dispatch(getPatientGroupAsync())
                    .then(unwrapResult)
                    .then((res: any) => {
                      dispatch(setGroupList(res.records))
                      submit()
                    })
                } else {
                  submit()
                }
              })
          }
        }}
      >
        {(type == 1 || type == 2) && (
          <Form.Item
            label='分组名称'
            name='groupName'
            rules={rules}
            getValueFromEvent={stringTrim}
          >
            <Input autoComplete='off' allowClear maxLength={8} showCount />
          </Form.Item>
        )}
        {type == 3 && (
          <>
            <Form.Item label='加入分组' name='groupId' rules={rules}>
              <Select placeholder='请选择要加入的分组' allowClear>
                {groupList
                  .filter((v: any) => v.id != 0)
                  .map((d: any) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.groupName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label=' ' name=''>
              <div style={{ color: '#999' }}>
                所选患者将加入新分组同时保留原分组
              </div>
            </Form.Item>
          </>
        )}
        {type == 4 && (
          <>
            <Form.Item label='咨询师' name='counselorId'>
              <Select
                placeholder='请选择要交接对象'
                allowClear
                showSearch
                filterOption={(input, option: any) =>
                  (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {counselorList.map((d: any) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.realname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='主治医生' name='attendingDoctorId'>
              <Select
                placeholder='请选择要交接对象'
                allowClear
                showSearch
                filterOption={(input, option: any) =>
                  (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {attendingDoctorList.map((d: any) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.realname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
        {type == 5 && (
          <>
            <Form.Item label='转移分组' name='changeGroupId' rules={rules}>
              <Select placeholder='请选择要转入的分组' allowClear>
                {groupList
                  .filter((v: any) => v.id != 0)
                  .map((d: any) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.groupName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label=' ' name=''>
              <div style={{ color: '#999' }}>
                所选患者将移出原分组并移入新分组
              </div>
            </Form.Item>
          </>
        )}
      </Form>
      <Row justify='space-between'>
        <div>
          {type != 1 && type != 2 && patientIds.length != 0 && (
            <>
              已选择 <span style={{ color: '#F00' }}>{patientIds.length}</span>{' '}
              位患者
            </>
          )}
        </div>
        <Space>
          <Button onClick={close}>取消</Button>
          <Button type='primary' onClick={form.submit}>
            确认
          </Button>
        </Space>
      </Row>
    </Modal>
  )
}
