/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 16:15:53
 * @LastEditors: sj
 * @LastEditTime: 2022-08-05 09:58:29
 */
import { Button, Popconfirm, Space, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { getAppointTypeType } from '../../../models/appointment'
import { getInvoiceStatus } from '../../../models/payment'
import { getIllnessDiagType, getIllnessName, getIllnessSource } from '../../../models/peopleSales'
import { getGenderName } from '../../../models/user'
import { setPatientPageId } from "../../registration/register/registerSlice";
import { traceRoute } from "../../../layouts/layoutSlice";

type Action = 'forbidden' | 'edit' | 'setting'

export const Columns = (
  startIndex: number,
  onAction: (action: Action, t: any) => void
): TableColumnType<any>[] => {
  // const [a, sets] = useState(false)
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '4rem',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '疾病编码',
      dataIndex: 'code',
      width: '10rem',
      align: 'center',
    },
    {
      title: '疾病名称',
      dataIndex: 'name',
      width: '10rem',
      align: 'center',
    },
    {
      title: '疾病来源',
      dataIndex: 'source',
      width: '10rem',
      align: 'center',
      render: (_, t) => getIllnessSource(t.source)
    },
    {
      title: '疾病类型',
      dataIndex: 'category',
      width: '10rem',
      align: 'center',
      render: (_, t) => getIllnessName(t.category)
    },
    {
      title: '疾病类别',
      dataIndex: 'diagType',
      width: '10rem',
      align: 'center',
      render: (_, t) => getIllnessDiagType(t.diagType)
    },
    {
      title: '目录类别',
      dataIndex: 'listType',
      width: '10rem',
      align: 'center',
      render: (_, t) => t.listType == 0 ? '医保目录' : '自费目录',
    },
    {
      title: '数据来源',
      dataIndex: 'customFlag',
      align: 'center',
      width: '10rem',
      render: (_, t) => t.customFlag == 0 ? '系统录入' : '手工录入',
    },
    // {
    //   title: '标签',
    //   dataIndex: '',
    //   align: 'center',
    //   width: '10rem',
    // },
    {
      title: '使用科室',
      dataIndex: 'departmentList',
      align: 'center',
      width: '15rem',
      render: (_, t) => t.departmentList || '-'
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      render: function action(_, t) {
        return (
            <Space>
              {
                <a
                  onClick={() => onAction('setting', t)}
                >
                  权限设置
                </a>
              }
              {
              t.customFlag == 1 ? (
                  <div>
                    <Popconfirm
                      title="确认进行此操作？"
                      onConfirm={() => onAction('forbidden', t)}
                      okText="确认"
                      cancelText="取消"
                    >
                      <a href='javascript:;'>{t.status == 0 ? '禁用' : '启用'}</a>
                    </Popconfirm>
                    <a
                      onClick={() => onAction('edit', t)}
                      style={{ marginLeft: '10px' }}
                    >
                      修改
                    </a>
                  </div>
                ) : ''
              }
            </Space>
        )
      },
    },
  ]
}