
import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space, Modal, Col, Radio, notification } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import {
    DateRangeType,
    DateSelect,
    getDateRange,
} from '../../compnents/form/DateSelect'
import { EditableList } from '../../compnents/list/EditableList'
import styles from './coupons.module.css'
import { CouponsRecodeColumns } from './columns'
import { RootDispatch } from '../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { CouponsRecordQuery } from './query'
import { CheckOutlined } from '@ant-design/icons'
import { TableRowSelection } from 'antd/lib/table/interface';
import { getActiveSettingList, getCouponsRecordList, getCouponsIssueSubmit, getCouponsSettingList } from './coupons'
import { CouponsItems } from './couponsItems'
import { getPatientList } from '../patient/list/patientListSlice'

const { Option } = Select

export const CouponsRecord = (props: { selectKey: string }) => {
    const dispatch = useDispatch<RootDispatch>()

    const [params, setParams] = useState<any>({
        current: 1,
        size: 10,
    })

    const [page, setPage] = useState<any>({
        total: 0,
        items: [],
    })

    const [pageLoading, setPageLoading] = useState(false)


    const getPage = () => {
        setPageLoading(true)
        dispatch(getCouponsRecordList(params))
            .then(unwrapResult)
            .then((res: any) => {
                setPage({
                    items: res.records,
                    total: res.total,
                })
            })
            .finally(() => {
                setPageLoading(false)
            })

    }

    useEffect(() => {
        getPage()
    }, [params])

    return (
        <div className={styles.wrap}>
            {/* 查询区域 */}
            <CouponsRecordQuery
                selectKey={props.selectKey}
                onValueChange={(v) => {
                    setParams({
                        ...params,
                        ...v,
                        current: 1,
                    })
                }} />

            {/* 表格区域 */}
            <EditableList
                style={{ overflow: 'hidden' }}
                scroll={{ y: '620px' }}
                loading={pageLoading}
                page={{
                    current: params.current,
                    size: params.size,
                    total: page.total,
                    items: page.items,
                }}
                columns={CouponsRecodeColumns(() => {
                    getPage()
                },params.size * (params.current - 1))}
                onChangePage={(current, size) =>
                    setParams({
                        ...params,
                        current,
                        size: size || 10,
                    })
                }
                onRow={() => ({
                    onDoubleClick: () => {
                        return
                    },
                })}
            />
        </div>
    )
}
