import { unwrapResult } from "@reduxjs/toolkit";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  ModalProps,
  Row,
  Select,
} from "antd";
import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../app/store";
import {
  getTenantCategoryName,
  getTenantLevelName,
  HospitalLevels,
  TenantCategory,
  TenantLevel,
  TenantLevels,
} from "../../models/tenant";
import {
  createOrUpdateTenant,
  getProvinceList,
  getTenantDetail,
  getTenantList,
} from "./tenantSlice";

interface TenantModalProps {
  tenantId?: string;
}

export const TenantModal = ({
  tenantId,
  onCancel,
  onOk,
  ...others
}: TenantModalProps & ModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const rowProps = {
    gutter: 4,
  };

  const colProps = { span: 8 };

  const firstColumnLayout = {
    labelCol: { flex: "6.5rem" },
    wrapperCol: { span: 16 },
  };

  const middleColumnLayout = {
    labelCol: { flex: "8rem" },
    wrapperCol: { span: 14 },
  };

  const wideColumnLayout = {
    labelCol: { flex: "6.5rem" },
    wrapperCol: { flex: 1 },
  };

  const required = {
    rules: [
      {
        required: true,
      },
    ],
  };

  const [provinceList, setProvinceList] = useState<any[]>();

  const [cityList, setCityList] = useState<any[]>();

  const [AreaList, setAreaList] = useState<any[]>();

  const [provinceState, setProvinceState] = useState<any>();

  const [cityState, setCityState] = useState<any>();

  const [showData, setShowData] = useState<any>(provinceState);


  // 省
  useEffect(() => {
    if (others?.visible) {
      dispatch(getProvinceList({ regionLevel: 1, size: 1000 }))
        .then(unwrapResult)
        .then((t: any) => setProvinceList(t?.records));
    }
  }, [others.visible]);

  // 市
  useEffect(() => {
    if (showData?.provinceName || provinceState) {
      dispatch(
        getProvinceList({
          regionLevel: 2,
          parentRegionCode: provinceState || showData?.provinceCode,
          size: 1000,
        })
      )
        .then(unwrapResult)
        .then((t: any) => setCityList(t?.records));
    }
  }, [provinceState, showData?.provinceName]);

  // 区
  useEffect(() => {
    if (showData?.provinceName || (provinceState && cityState)) {
      dispatch(
        getProvinceList({
          regionLevel: 3,
          parentRegionCode: cityState || showData?.cityCode,
          size: 1000,
        })
      )
        .then(unwrapResult)
        .then((t: any) => setAreaList(t?.records));
    }
  }, [provinceState, cityState, showData?.provinceName]);

  useEffect(() => {
    if (tenantId) {
      dispatch(getTenantDetail(tenantId))
        .then(unwrapResult)
        .then((tenant) => {
          setShowData(tenant);
          form.setFieldsValue({
            ...tenant,
            startTime: moment(tenant.startTime),
            endTime: moment(tenant.endTime),
          });
        });
    } else {
      form.resetFields();
    }
  }, [tenantId]);

  return (
    <Modal
      {...others}
      title={`${tenantId ? "更新" : "新建"}机构`}
      width={900}
      confirmLoading={isLoading}
      cancelText="取消"
      okText="确定"
      onCancel={(e) => {
        if (tenantId) {
          form.resetFields();
        }
        onCancel && onCancel(e);
        setCityList([]);
        setAreaList([]);
        setProvinceState("");
        setCityState("");
        setShowData("");
      }}
      onOk={(e) => {
        form
          .validateFields()
          .then((values) => {
            const reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
            const format = "YYYY/MM/DD 23:59:59";
            const startTime =
              values.startTime && values.startTime.format(format);
            const endTime = values.endTime && values.endTime.format(format);
            setIsLoading(true);

            dispatch(
              createOrUpdateTenant({
                ...values,
                id: tenantId,
                startTime,
                endTime,
                provinceName: undefined,
                cityName: undefined,
                countyName: undefined,
                provinceCode:
                  reg.test(values.provinceName) == false
                    ? values.provinceName
                    : reg.test(showData.provinceCode) == false
                    ? showData.provinceCode
                    : null,
                cityCode:
                  reg.test(values.cityName) == false
                    ? values.cityName
                    : reg.test(showData.cityCode) == false
                    ? showData.cityCode
                    : null,
                countyCode:
                  reg.test(values.countyName) == false
                    ? values.countyName
                    : reg.test(showData.countyCode) == false
                    ? showData.countyCode
                    : null,
              })
            )
              .then(() => {
                setIsLoading(false);
                form.resetFields();
                onOk && onOk(e);
                dispatch(getTenantList({ current: 1, size: 10 }));
              })
              .catch(() => {
                setIsLoading(false);
              });
          })
          .catch(() => {
            // Do nothing.
          });
      }}
    >
      <Form
        form={form}
        colon={false}
        autoComplete="off"
        initialValues={{
          organLevel: TenantLevel.Level1,
          tenantCategory: TenantCategory.Pharmacy,
        }}
      >
        <Row {...rowProps}>
          <Col {...colProps} span={16}>
            <Form.Item
              {...wideColumnLayout}
              label="机构名称"
              name="name"
              {...required}
              rules={[
                { required: true, message: "机构名称不能为空" },
                { max: 500, message: "最长500位" },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="机构略称"
              name="shortName"
              {...required}
              rules={[
                { required: true, message: "机构略称不能为空" },
                { max: 120, message: "最长120位" },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item
              {...firstColumnLayout}
              label="机构VIP等级"
              name="organLevel"
            >
              <Select>
                {TenantLevels.map((l) => (
                  <Select.Option key={l} value={l}>
                    {getTenantLevelName(l)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              required
              {...middleColumnLayout}
              label="机构类别"
              name="tenantCategory"
            >
              <Select defaultValue={TenantCategory.Clinic}>
                {[
                  TenantCategory.hospital,
                  TenantCategory.maternityHealthCenter,
                  TenantCategory.communityHealthServiceCenter,
                  TenantCategory.surgery,
                  TenantCategory.nursingHome,
                  TenantCategory.outpatientDepartment,
                  TenantCategory.Clinic,
                  TenantCategory.villageHealthClinic,
                  TenantCategory.emergencyCenter,
                  TenantCategory.clinicalLaboratory,
                  TenantCategory.specializedDiseasePreventionAndTreatmentInstitution,
                  TenantCategory.nursingStation,
                  TenantCategory.center,
                  TenantCategory.Pharmacy,
                  TenantCategory.other
                ].map((c) => (
                  <Select.Option key={c} value={c}>
                    {getTenantCategoryName(c)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="医疗机构编号"
              name="medicalOrganCode"
              rules={[{ max: 120, message: "最长120位!" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item
              {...firstColumnLayout}
              label="医院编号"
              name="hospitalCode"
              rules={[{ max: 120, message: "最长120位" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="服务起始时间"
              name="startTime"
              dependencies={["endTime"]}
              {...required}
              rules={[
                { required: true, message: "服务起始时间不能为空" },
                ({ getFieldValue }) => ({
                  validator: (_, start) => {
                    if (start) {
                      const end = getFieldValue("endTime");
                      if (
                        end &&
                        moment.duration(end.diff(start)).asMinutes() < 0
                      ) {
                        return Promise.reject("起始时间必须早于终止时间");
                      }
                      return Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker format="YYYY/MM/DD" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="服务终止时间"
              name="endTime"
              {...required}
              rules={[{ required: true, message: "服务终止时间不能为空" }]}
            >
              <DatePicker format="YYYY/MM/DD" />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item
              {...firstColumnLayout}
              label="医疗机构等级"
              name="medicalOrganLevel"
              {...required}
              rules={[{ required: true, message: "医疗机构等级不能为空" }]}
            >
              <Select>
                {HospitalLevels.map((l) => (
                  <Select.Option key={l} value={l}>
                    {getTenantLevelName(l)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="机构电话"
              name="tenantPhone"
              {...required}
              rules={[
                { required: true, message: "机构电话不能为空" },
                {
                  pattern: /^[-+0-9]+$/,
                  message: "请输入正确的电话",
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              {...required}
              label="全国唯一识别码"
              name="nationUniqueCode"
              rules={[
                { required: true, message: "全国唯一识别码不能为空" },
                { max: 120, message: "最长120位!" },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item
              {...firstColumnLayout}
              label="省"
              name="provinceName"
              {...required}
              rules={[{ required: true, message: "省不能为空" }]}
            >
              <Select
                placeholder="请选择省级"
                allowClear
                onSelect={(c: any) => {
                  setProvinceState(c);
                }}
                onChange={() => {
                  form.setFieldsValue({
                    cityName: "",
                    countyName: "",
                  });
                }}
              >
                {provinceList?.map((d) => (
                  <Select.Option key={d.regionCode} value={d.regionCode}>
                    {d.fullPath}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="市"
              name="cityName"
              {...required}
              rules={[{ required: true, message: "市不能为空" }]}
            >
              <Select
                allowClear
                placeholder="请先选择省级"
                onSelect={(c: any) => {
                  setCityState(c);
                }}
                onChange={() => {
                  form.setFieldsValue({
                    countyName: "",
                  });
                }}
              >
                {cityList?.map((d) => (
                  <Select.Option key={d.regionCode} value={d.regionCode}>
                    {d.regionName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              {...required}
              label="区"
              name="countyName"
              rules={[{ required: true, message: "区不能为空" }]}
            >
              <Select allowClear placeholder="请先选择省市级">
                {AreaList?.map((d) => (
                  <Select.Option key={d.regionCode} value={d.regionCode}>
                    {d.regionName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps} span={24}>
            <Form.Item
              {...wideColumnLayout}
              label="地址"
              name="address"
              {...required}
              rules={[
                { required: true, message: "地址不能为空" },
                { max: 500, message: "最长500位" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item
              {...firstColumnLayout}
              label="联系人"
              name="contactName"
              {...required}
              rules={[
                { required: true, message: "联系人不能为空" },
                { max: 60, message: "最长60位" },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="联系电话"
              name="phone"
              {...required}
              rules={[
                { required: true, message: "联系电话不能为空" },
                { max: 40, message: "最长40位" },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="邮编"
              name="postcode"
              {...required}
              rules={[{ max: 40, message: "最长40位" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item {...firstColumnLayout} label="机构代码" name="organCode">
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="主管单位"
              name="managerCompany"
              rules={[{ max: 40, message: "最长40位" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="法人代表"
              name="legalPerson"
              rules={[{ max: 40, message: "最长40位" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...colProps}>
            <Form.Item
              {...firstColumnLayout}
              label="许可证类型"
              name="licenceCategory"
              rules={[{ max: 60, message: "最长60位" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="许可证号码"
              name="licenceNumber"
              rules={[{ max: 120, message: "最长120位" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              {...middleColumnLayout}
              label="许可证期限"
              name="licenceDeadline"
              rules={[{ max: 40, message: "最长40位" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
