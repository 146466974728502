/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-18 17:44:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-20 14:17:44
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import { medicalOverviewPage, medicalOverviewParams } from '../../services/medicalOverview'

// 开票记录列表
export const medicalOverviewAsync = createAsyncThunk<
  void,
  medicalOverviewParams,
  RootThunkApi
>('confirm/medicalOverviewAsync', async (params, api) => {
  return sendAsync(medicalOverviewPage(params), api)
})
