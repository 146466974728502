
import { RecipeCategory } from "../models/recipe";
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface SupplierDealingParams {
  id?: number;
  current?: number;
  size?: number;
  total?: number;
  category?: RecipeCategory;
  name?: string;
  timeHead?: string;
  timeTail?: string;
  pages?: number;
  hitCount?: boolean;
  goodsParam?: string;
  recepitsNumber?: string;
  supplierParam?: string;
  count?:number;
  createTime ?:string,	
  totalPrice?:number,	
  unitPrice?:number,
  searchCount?:boolean,
  spec ?:string,
  doseCategory?:string

  
}
export function getSupplierDealing(params: SupplierDealingParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/contact`,
    method: "GET",
    params,
  };
}

export function exportListExoprt(params :any): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/contact/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params
  }
}
