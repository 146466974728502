/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-25 10:33:05
 * @LastEditors: sj
 * @LastEditTime: 2022-01-25 17:42:46
 */
export function getUploadStatus(i: number): string {
  switch (i) {
    case 0:
      return '未上传'
    case 1:
      return '已上传'
    case 2:
      return '上传失败'
    case 3:
      return '已取消'
    case 4:
      return '已取消'
    default:
      return '-'
  }
}
