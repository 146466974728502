import {
  CaretLeftFilled,
  CaretRightFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Popover, Row } from "antd";
import _ from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "../../compnents/widgets/Label";
import { Menu } from "../../models/menu";
import styles from "./Menus.module.css";
import { getMenuList, moveMenu, removeMenu, selectMenus } from "./menuSlice";
import { MenuModal } from "./Modal";

const SpanCount = 3;

export const WrappedMenu = (props: {
  menu: Menu;
  parent?: Menu;
  onEdit: (menuId: string, parentId?: string) => void;
}): ReactElement => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  return (
    <Col span={SpanCount} style={{ marginTop: 10 }}>
      <Popover
        visible={isVisible}
        onVisibleChange={(v) => setIsVisible(v)}
        content={
          <>
            <Button
              icon={<EditOutlined />}
              style={{ border: "none", backgroundColor: "transparent" }}
              onClick={() => {
                setIsVisible(false);
                props.onEdit(
                  props.menu.id,
                  props.parent ? props.parent.id : undefined
                );
              }}
            />
            <Button
              icon={<CaretLeftFilled />}
              style={{ border: "none", backgroundColor: "transparent" }}
              onClick={() => {
                setIsVisible(false);
                // Move left.
                dispatch(moveMenu(props.menu, props.parent, false));
              }}
            />
            <Button
              icon={<CaretRightFilled />}
              style={{ border: "none", backgroundColor: "transparent" }}
              onClick={() => {
                setIsVisible(false);
                // Move right.
                dispatch(moveMenu(props.menu, props.parent, true));
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              style={{ border: "none", backgroundColor: "transparent" }}
              danger
              onClick={() => {
                setIsVisible(false);
                Modal.confirm({
                  title: "警告",
                  icon: <ExclamationCircleOutlined />,
                  content: `确认删除菜单“${props.menu.name}”`,
                  onOk: () => {
                    dispatch(removeMenu(props.menu));
                  },
                });
              }}
            />
          </>
        }
      >
        <span style={{ fontSize: "1.125rem" }}>{props.menu.name}</span>
      </Popover>
    </Col>
  );
};

export const Menus = (): ReactElement => {
  const dispatch = useDispatch();

  const menus = useSelector(selectMenus);

  const [menuId, setMenuId] = useState("");

  const [parentId, setParentId] = useState<string | undefined>(undefined);

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(getMenuList());
  }, [dispatch]);

  const class1Menus = _.chain(menus).value();

  const flatten = (menu: Menu): Menu[] => {
    const children = _.chain(menu.subMenus)
      .map((m) => flatten(m))
      .flatten()
      .value();
    return [menu].concat(children);
  };

  const flattenMenus = _.chain(menus)
    .map((m) => flatten(m))
    .flatten()
    .value();

  const edit = (menuId: string, parentId?: string) => {
    setMenuId(menuId);
    setParentId(parentId);
    setIsModalVisible(true);
  };

  return (
    <div className={styles.container}>
      <Label label="分配给机构的初始权限" />
      <Row className={styles.class1}>
        {class1Menus.map((m) => (
          <WrappedMenu key={m.id} menu={m} onEdit={edit} />
        ))}
        <Col span={SpanCount} style={{ marginTop: 15 }}>
          <Button
            icon={<PlusOutlined />}
            size="small"
            onClick={() => edit("", undefined)}
          />
        </Col>
      </Row>
      {flattenMenus.map((p) => (
        <div key={p.id} className={styles.class2}>
          <div className={styles.name}>{`${p.name}(${p.level}级)`}</div>
          <Row className={styles.sub}>
            {p.subMenus.map((m) => (
              <WrappedMenu key={m.id} menu={m} parent={p} onEdit={edit} />
            ))}
            <Col span={SpanCount} style={{ marginTop: 15 }}>
              <Button
                icon={<PlusOutlined />}
                size="small"
                onClick={() => edit("", p.id)}
              />
            </Col>
          </Row>
        </div>
      ))}
      <MenuModal
        visible={isModalVisible}
        menuId={menuId}
        parentId={parentId}
        onHide={() => setIsModalVisible(false)}
      />
    </div>
  );
};
