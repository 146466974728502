import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import * as M from '../../models/material'
import { Material } from '../../models/material'
import * as R from '../../models/recipe'
import { Recipe } from '../../models/recipe'
import * as T from '../../models/treatment'
import { Treatment } from '../../models/treatment'
import * as SR from '../../services/recipe'
import * as ST from '../../services/treatment'

interface RecipeListState {
  treatment: any
  recipes: Recipe[]
  materials: Material[]
  pageLoading?: boolean
}

const initialState = {
  treatment: {},
  recipes: [],
  materials: [],
  pageLoading: false,
} as RecipeListState

export const getTreatment = createAsyncThunk<Treatment, string, RootThunkApi>(
  'recipeList/getTreatmentStatus',
  async (treatmentId, api) => {
    return sendAsync(ST.getTreatment({ treatmentId }), api).then((data) => {
      return {
        ...T.fromJson(data.treatment),
        registrationCategory: data.registration.registrationCategory,
        insuranceCode: data.registration.insuranceCode,
        insuranceBean: data.insuranceBean,
        yidibz: data.registration.yidibz,
      }
    })
  }
)

export const getRecipeList = createAsyncThunk<
  Recipe[],
  string,
  RootThunkApi<Recipe[]>
>('recipeList/getRecipeListStatus', async (treatmentId, api) => {
  return sendAsync(SR.getRecipeList(treatmentId), api).then((data) =>
    _.chain(data.records)
      .map((r) => R.fromJson(r))
      .value()
  )
})

export const getWebPrintDataByTreatmentId = createAsyncThunk<
  void,
  SR.webPrintDataByTreatmentIdParams,
  RootThunkApi
>('recipeList/getWebPrintData', async (params, api) => {
  return sendAsync(SR.webPrintDataByTreatmentId(params), api)
})

export const getWebPrintDataByRecipeId = createAsyncThunk<
  void,
  SR.webPrintDataByRecipeIdParams,
  RootThunkApi
>('recipeList/getWebPrintData', async (params, api) => {
  return sendAsync(SR.webPrintDataByRecipeId(params), api)
})

export const getWebPrintDataByPaymentId = createAsyncThunk<
  void,
  SR.WebPrintDataByPaymentIdParams,
  RootThunkApi
>('recipeList/webPrintDatas', async (params, api) => {
  return sendAsync(SR.webPrintDataByPaymentId(params), api)
})

export const getMaterialList = createAsyncThunk<
  Material[],
  string,
  RootThunkApi<Material[]>
>('recipeList/getMaterialListStatus', async (recipeId, api) => {
  return sendAsync(SR.getRecipeDetail(recipeId), api).then((data) => {
    return (
      _.chain(data.records?.[0]?.itemList)
        .map((m) => M.fromMaterialJson(m))
        .sort((a, b) => {
          if (a.groupNumber < b.groupNumber) {
            return -1
          }
          if (a.groupNumber > b.groupNumber) {
            return 1
          }
          return a.sort - b.sort
        })
        .value() || []
    )
  })
})

export const removeMaterials = createAsyncThunk<void, string[], RootThunkApi>(
  'recipeList/removeMaterialsStatus',
  async (ids, api) => {
    return sendAsync(SR.removeMaterials(ids), api)
  }
)

const recipeListSlice = createSlice({
  name: 'recipeList',
  initialState,
  reducers: {
    setMaterials: (state, action) => {
      state.materials = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecipeList.fulfilled, (state, action) => {
      state.recipes = action.payload
    })
    builder.addCase(getMaterialList.fulfilled, (state, action) => {
      state.materials = action.payload
      state.pageLoading = false
    })
    builder.addCase(getTreatment.fulfilled, (state, action) => {
      state.treatment = action.payload
    })
  },
})

export const { setMaterials, setPageLoading } = recipeListSlice.actions

export const selectRecipeList = (state: RootState): Recipe[] =>
  state.recipeList.recipes

export const selectMaterialList = (state: RootState): Material[] =>
  state.recipeList.materials

export const selectTreatment = (state: RootState): any =>
  state.recipeList.treatment

export const selectPageLoading = (state: RootState): any =>
  state.recipeList.pageLoading
export default recipeListSlice.reducer
