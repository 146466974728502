/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-14 11:43:22
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-24 14:02:51
 */
import {
  CheckCircleOutlined,
  ManOutlined,
  PhoneFilled,
  PhoneOutlined,
  WomanOutlined,
} from '@ant-design/icons'
import { Button, Modal, Rate, Row, Space, Tag, Tooltip } from 'antd'
import { number } from 'echarts'
import moment from 'moment'
import { type } from 'os'
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import {
  AccomplishIcon,
  AppointIcon,
  AppointmentedIcon,
  AppointmentIcon,
  AppointmentingIcon,
  BoyOutfillIcon,
  GirlOutfillIcon,
  GroupIcon,
  MembersIcon,
  MessageIcon,
  OweMoneyIcon,
  RegistrationIcon,
  TransferTreatmentIcon,
} from '../../../../compnents/icons/Icons'
import {
  PatientListItem,
  todayPatientListItem,
} from '../../../../models/toothPatient'
import { SendMessageModal } from '../../toothHome/modal/sendMessageModal'
import styles from './listItem.module.css'
import { getGenderName } from '../../../../models/user'
import { getAge } from '../../../../utils/StringUtils'
import { patientLastAppointmentInfo } from '../patientSlice'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'

type action =
  | 'updateTime'
  | 'message'
  | 'qrCode'
  | 'registration'
  | 'appointment'
  | 'transfer'
  | 'group'
  | 'success'
interface ListItemProps {
  selectId: string
  data?: any
  type: PatientListItem
  listItemActive?: string
  index?: string
  onClick: (action: action, id: string) => void
}

export const ListItem = ({
  data = {},
  selectId,
  type,
  onClick,
  listItemActive,
  index,
}: ListItemProps): ReactElement => {
  const [patientInfo, setPatientInfo] = useState<any>({})

  const modalRef = useRef<any>()

  const dispatch = useDispatch<RootDispatch>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [patientData, setPatientData] = useState<any>({});

  const [positionXY, setPositionXY] = useState({top: 0, left: 0})

  useEffect(() => {
    data.patient && setPatientInfo(data.patient)
  }, [data])

  const getBackground = () => {
    if (type == PatientListItem.ZJ) {
      return selectId == data?.patient?.id ? '#F0F6FF' : ''
    } else {
      return listItemActive == index ? '#F0F6FF' : ''
    }
  }

  const getAppointmentState = (state: any) => {
    //0:待确认,1已挂号,2已就诊,3已取消,4已确认，9超时,-1已流失(预约)
    if (state == null) {
      return '未知'
    } else if (state == 0) {
      return '预约中'
    } else if (state == 4) {
      return '已确认'
    } else if (state == 1) {
      return '已挂号'
    } else if (state == 2) {
      return '已就诊'
    } else if (state == 3) {
      return '已取消'
    } else if (state == 9) {
      return '超时'
    } else if (state == -1) {
      return '未接诊（流失）'
    } else {
      return '未知' + state
    }
  }
  const getPatientDoctorName = (data: any) => {
    if (data?.registration?.treatmentDoctorName) {
      return {
        doctorName: data?.registration?.treatmentDoctorName,
        title: '就诊医生：' + data?.registration?.treatmentDoctorName,
      }
    } else if (data?.appointmentDoctorName) {
      return {
        doctorName: data?.appointmentDoctorName,
        title: '预约医生：' + data?.appointmentDoctorName,
      }
    } else {
      return { doctorName: '', title: '' }
    }
  }

  const handleEventClick = (e: any) => {
    const x = e.pageX
    const y = e.pageY
    const left = x + 300
    let positionX = 0
    let positionY = 0
    // 若为右侧，则框弹出位置在左侧
    if (left > document.documentElement.clientWidth - 300) {
      positionX = x - 350 - 3
    } else {
      positionX = x + 20
    }
    if (
      y + 307 >
      document.documentElement.clientHeight
    ) {
      positionY =
        document.documentElement.clientHeight - 307
    } else {
      positionY = y - 150
    }

    console.log(positionY, positionX);
    setPositionXY({
      top: positionY,
      left: positionX
    }) 
  }

  return (
    <>
      <div
        className={styles.listItem}
        style={{
          backgroundColor: getBackground(),
          // backgroundColor: selectId == data?.patient?.id ? '#F0F6FF' : '',
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClick("updateTime", patientInfo.id);
        }}
      >
        <div className={styles.cell}>
          <div className={styles.left}>
            {patientInfo.sex == 1 && (
              <BoyOutfillIcon
                style={{
                  color: "#77A1FF",
                  width: 18,
                  height: 18,
                }}
              />
            )}
            {patientInfo.sex == 2 && (
              <GirlOutfillIcon
                style={{
                  color: "#FF8181",
                  width: 18,
                  height: 18,
                }}
              />
            )}
            &nbsp;
            <Tooltip
              placement="topLeft"
              title={patientInfo.name}
              arrowPointAtCenter
            >
              <div className={styles.name}>{patientInfo.name}</div>
            </Tooltip>
          </div>
          <div className={styles.right}>
            <Tooltip
              placement="topLeft"
              title={getPatientDoctorName(data).title}
              arrowPointAtCenter
            >
              <div className={styles.name} style={{ fontSize: "14px" }}>
                {getPatientDoctorName(data).doctorName}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={styles.detail}>
          {patientInfo.star && (
            <div
              className={styles.cellLeft}
              style={{
                marginBottom: 3,
              }}
            >
              <Rate disabled value={patientInfo.star} />
            </div>
          )}
          <div
            className={styles.cellLeft}
            style={{
              minHeight: 18,
            }}
          >
            <Space>
              {data.isMeber == 1 && (
                <Tooltip
                  placement="topLeft"
                  title={"会员用户"}
                  arrowPointAtCenter
                >
                  <span className={styles.icon}>
                    <MembersIcon
                      style={{
                        fontSize: 18,
                        width: 18,
                        height: 18,
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {Number(data.arrearageAmountSum) > 0 && (
                <Tooltip
                  placement="topLeft"
                  title={"当前欠费：" + Number(data.arrearageAmountSum) + "元"}
                  arrowPointAtCenter
                >
                  <span className={styles.icon}>
                    <OweMoneyIcon
                      style={{
                        fontSize: 18,
                        width: 18,
                        height: 18,
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {Number(data.latelyAppointmentId) > 0 && (
                // <Tooltip
                //   placement='topLeft'
                //   title={
                //     <div>
                //       <div>最近一次预约：{data.latelyAppointmentDate}</div>
                //       <div>预约医生：{data.latelyAppointmentDoctorName}</div>
                //       <div>
                //         预约状态：
                //         {getAppointmentState(data.latelyAppointmentState)}
                //       </div>
                //       {data.latelyAppointmentComment &&
                //       data.latelyAppointmentComment.length > 0 ? (
                //         <div>预约备注：{data.latelyAppointmentComment}</div>
                //       ) : (
                //         ''
                //       )}
                //       {data.latelyAppointmentItem &&
                //       data.latelyAppointmentItem.length > 0 ? (
                //         <div>预约项目：{data.latelyAppointmentItem}</div>
                //       ) : (
                //         ''
                //       )}
                //     </div>
                //   }
                //   arrowPointAtCenter
                // >
                <span
                  className={styles.icon}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEventClick(e);
                    if (Number(data.latelyAppointmentId) > 0) {
                      dispatch(
                        patientLastAppointmentInfo({
                          patientId: data?.patient?.id,
                        })
                      )
                        .then(unwrapResult)
                        .then((res) => {
                          setPatientData(res);
                          setIsModalVisible(true);
                        });
                    } else {
                      setIsModalVisible(false);
                    }
                  }}
                >
                  <AppointmentedIcon
                    style={{
                      fontSize: 18,
                      width: 18,
                      height: 18,
                    }}
                  />
                </span>
                // </Tooltip>
              )}

              {(patientInfo.pastMedicalHistory ||
                patientInfo.allergyHistory) && (
                <Tooltip
                  placement="topLeft"
                  title={
                    <div>
                      <div>过敏史：{data.patient.allergyHistory}</div>
                      <div>既往史：{data.patient.pastMedicalHistory}</div>
                    </div>
                  }
                >
                  <span
                    className={styles.iconlabel}
                    style={{ backgroundColor: "#FF7373", color: "#FFF" }}
                  >
                    史
                  </span>
                </Tooltip>
              )}
              {data?.labels &&
                data.labels?.length != 0 &&
                data.labels.map((v: any, i: number) => (
                  <span
                    key={i}
                    className={styles.iconlabel}
                    style={{
                      backgroundColor: v.backgroundColor,
                      color: v.fontColor,
                    }}
                  >
                    {v.headName}
                  </span>
                ))}
            </Space>
          </div>
          <div className={styles.cell}>
            <div className={styles.phone} style={{ width: "200px" }}>
              {patientInfo.phone && (
                <PhoneFilled
                  style={{
                    color: "#ccc",
                  }}
                />
              )}
              {patientInfo.phone}
            </div>
            <div className={styles.right}>
              {data.registrationTime
                ? moment(data.registrationTime).format("YYYY/MM/DD")
                : "-"}
            </div>
          </div>
        </div>
        <div className={styles.action}>
          <div className={styles.actionContainer}>
            <Space>
              <div
                className={styles.actionItem}
                onClick={(e) => {
                  onClick("message", patientInfo.id);
                }}
              >
                <MessageIcon
                  style={{
                    fontSize: 18,
                    width: 18,
                    height: 18,
                    fill: "#666",
                  }}
                />
                短信
              </div>
              {(type == PatientListItem.YYWD || type == PatientListItem.ZJ) && (
                <div
                  className={styles.actionItem}
                  onClick={(e) => {
                    onClick("registration", patientInfo.id);
                  }}
                >
                  <RegistrationIcon
                    style={{
                      fontSize: 18,
                      width: 18,
                      height: 18,
                      fill: "#666",
                    }}
                  />
                  挂号
                </div>
              )}
              {todayPatientListItem.includes(type) && (
                <div
                  className={styles.actionItem}
                  onClick={(e) => {
                    onClick("transfer", patientInfo.id);
                  }}
                >
                  <TransferTreatmentIcon
                    style={{
                      fontSize: 18,
                      width: 18,
                      height: 18,
                      fill: "#666",
                    }}
                  />
                  转诊
                </div>
              )}
              <div
                className={styles.actionItem}
                onClick={(e) => {
                  onClick("appointment", patientInfo.id);
                }}
              >
                <AppointIcon
                  style={{
                    fontSize: 18,
                    width: 18,
                    height: 18,
                    fill: "#666",
                  }}
                />
                预约
              </div>
              {type == PatientListItem.DJF ||
                (type == PatientListItem.JZZ && (
                  <div
                    className={styles.actionItem}
                    onClick={(e) => {
                      onClick("success", patientInfo.id);
                    }}
                  >
                    <AccomplishIcon
                      style={{
                        fontSize: 18,
                        width: 18,
                        height: 18,
                        fill: "#666",
                      }}
                    />
                    完成
                  </div>
                ))}
              {type == PatientListItem.ZJ && (
                <div
                  className={styles.actionItem}
                  onClick={() => {
                    onClick("group", patientInfo.id);
                  }}
                >
                  <GroupIcon
                    style={{
                      fontSize: 18,
                      width: 18,
                      height: 18,
                      fill: "#666",
                    }}
                  />
                  分组
                </div>
              )}
            </Space>
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        centered
        style={{ position: "absolute", ...positionXY }}
        closable={false}
        width={350}
        destroyOnClose
        footer={null}
        mask={false}
        maskClosable={true}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
      >
        <div>
          <h2>
            <a>{data?.patient?.name} </a>
            <Tag color="#FFB453">
              {data?.registration?.receiveTag == 1 ? "复诊" : "初诊"}
            </Tag>
          </h2>
          <section>性别：{getGenderName(data?.patient?.sex)}</section>
          <section>
            年龄：
            {getAge(data?.patient?.ageYear, data?.patient?.ageMonth)}
          </section>
          <section>手机号：{data?.patient?.phone}</section>
          <section>
            预约时间：{patientData?.appointmentDate}{'  '}
            {patientData?.appointmentStartTime}-
            {patientData?.appointmentEndTime}
          </section>
          <section>预约科室：{patientData?.appointmentDepartmentName}</section>
          <section>预约医生：{patientData?.appointmentDoctorName}</section>
          <section>预约项目：{patientData?.appointmentItem || "-"}</section>
          <section>预约备注：{patientData?.appointmentComment}</section>
          <Row justify="center" style={{ marginTop: 15 }}>
            {/* {(cardData?.appointmentState == 1 ||
                    cardData?.appointmentState == 2) && ( */}
            <Space>
              <Button
                size="small"
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalVisible(false);
                  onClick("updateTime", patientInfo.id);
                }}
              >
                就诊
              </Button>
            </Space>
            {/* )} */}
          </Row>
        </div>
      </Modal>
    </>
  );
}
