import styles from "../directory.module.css";
import {Button, Col, Form, Input, Row, Select, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {EditableList} from "../../../compnents/list/EditableList";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootDispatch} from "../../../app/store";
import {PriceChangBody} from "../../../services/commodity";
import {DateSelect} from "../../../compnents/form/DateSelect";
import {Option} from "antd/es/mentions";
import {getLimitPriceChangeListAsync} from "../../commodity/commoditySlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {LimitPriceChangeColumn} from "./limitPriceChangeColumn";
import {DateRangeType} from "../../../compnents/date/TimeSelect";
import moment from "moment/moment";
import {selectInsuranceArray} from "../../../app/applicationSlice";
import {selectInsuranceItem} from "../../../layouts/insuranceSlice";


function LimitPriceChange  () {

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const baseData = useSelector(selectInsuranceItem)

  const [pageLoading, setPageLoading] = useState(false)

  const [data, setData] = useState<any>()

  const [queries, setQueries] = useState<PriceChangBody>({
    current: 1,
    size: 10,
    type : 0,
    insuranceCode: baseData?.insuranceCode,
  })

  useEffect(() => {
    getTable()
  }, [queries])
  // 获取表格数据
  const getTable = () => {
    setPageLoading(true)

    const head = form.getFieldValue("timeHead");
    const tail = form.getFieldValue("timeTail");

    if (head && tail) {
      dispatch(
        getLimitPriceChangeListAsync({
          ...queries,
          insuranceCode: baseData?.insuranceCode,
          timeHead: moment(head).format("yyyy-MM-DD 00:00:00"),
          timeTail: moment(tail).format("yyyy-MM-DD 23:59:59"),
        })
      )
        .then(unwrapResult)
        .then((res: any) => setData(res))
        .finally(() => setPageLoading(false))
    } else {
      dispatch(
        getLimitPriceChangeListAsync({
          ...queries,
          insuranceCode: baseData?.insuranceCode,
        })
      )
        .then(unwrapResult)
        .then((res: any) => setData(res))
        .finally(() => setPageLoading(false))
    }

  }

  return (
    <div className={styles.wrap}>
      <Form
        form={form}
        initialValues={{ billingCategory: '01' }}
        onFinish={(values: any) => {
          setQueries({ ...queries, ...values, current: 1 })
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '42px',
          }}
        >
          <Col>
            <Space>
              <Form.Item name='searchStr'>
                <Input
                  size='middle'
                  placeholder='目录名称/目录编码'
                  allowClear
                  prefix={<SearchOutlined />}
                  onChange={() => {
                    form.submit()
                  }}
                />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item name='type'>
                <Select defaultValue={'0'} placeholder='全部' style={{marginLeft:'10px',width:'7rem'}}
                  onChange={() => {
                    form.submit()
                  }}
                >
                  <Option value={'0'}>全部</Option>
                  <Option value={'1'}>本机构相关</Option>
                </Select>
              </Form.Item>
            </Space>
            <Space>
              <DateSelect
                allowClear={false}
                style={{ width: '7rem',marginLeft: '10px' }}
                labelPrefix=''
                namePrefix='time'
                placeholder='变动日期'
                onChange={getTable}
              />
            </Space>
          </Col>
          <Col>
            <Space>
              <Button htmlType='submit' type='primary'>
                查询
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <EditableList
        loading={pageLoading}
        columns={LimitPriceChangeColumn(() => {
          return
        }, (queries.size || 10) * ((queries.current || 1) - 1))}
        onChangePage={(current, size) => {
          setQueries({
            ...queries,
            current,
            size,
          })
        }}
        page={{
          size: queries.size || 10,
          current: queries.current || 1,
          total: data?.total || 0,
          items: data?.records || [],
        }}
        rowKey={(_, i) => `${i}`}
      />
    </div>
  )
}

export default LimitPriceChange