/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-22 20:44:24
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-13 15:29:10
 */
import React from "react";
import styles from "../WarehouseBusinessLibrary/library.module.css";
import { selectThreeMenus, traceRoute } from "../../../layouts/layoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch } from "../../../app/store";

export const SupplierLibrary = (props: { parentId: string }) => {
  const dispatch = useDispatch<RootDispatch>();
  const threeMenus = useSelector(selectThreeMenus)

  const getStatus = (name: string) => {
    const menus = threeMenus?.filter((v: any) => v.parentId == props?.parentId)
    return menus?.some((v: any) => v.name == name)
  }
  
  return (
    <div className={styles.content}>
      {getStatus('供应商设置') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/supplier",
            })
          );
        }}
      >
        供应商设置
      </div>) : ''}
      {getStatus('收付款') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/receiving",
            })
          );
        }}
      >
        收付款
      </div>) : ''}
      {getStatus('药品往来') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/supplierDealing",
            })
          );
        }}
      >
        药品往来
      </div>) : ''}
      {getStatus('进货管理') ? (<div
          className={styles.card}
          onClick={() => {
              dispatch(
                  traceRoute({
                      name: "库房工作站",
                      path: "/purchaseControl",
                  })
              );
          }}
      >
          进货管理
      </div>) : ''}
    </div>
  );
};
