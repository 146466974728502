/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-28 14:30:32
 */
import { API_PREFIX } from "./constant";
import { ContentType, MultiPartFormData, Request } from "./request";
import { SupplierCategory } from "../models/enums"

export interface supplierParams {
    current?: number,
    size?: number,
    supplierCategory?: SupplierCategory,
    name?: string,
    flag?: number,
}
export interface supplierDeleteBody {
    id?: number
}

export interface supplierSubmitBody {
    address: string,
    companyName: string,
    licenceCategory: string,
    licenceDeadline: string,
    licenceNumber: string,
    mnemonicCode: string,
    phone: string,
    supplierMaterialsCategory: string,
    supplierCategory: number
    companyCode?: string,
    fax?: string,
    postCode?: string,
    id?: number
}

export function supplierPage(
    params: supplierParams
): Request {
    return {
        url: `${API_PREFIX}/blade-store/supplier/page`,
        method: "GET",
        params
    }
}

export function supplierDetail(
    id: number
): Request {
    return {
        url: `${API_PREFIX}/blade-store/supplier/detail`,
        method: "GET",
        params: {
            id
        }
    }
}

export function supplierSubmit(
    body: supplierSubmitBody
): Request {
    return {
        url: `${API_PREFIX}/blade-store/supplier/submit`,
        method: "POST",
        body
    }
}

export function supplierDelete(
    body: supplierDeleteBody
): Request {
    return {
        url: `${API_PREFIX}/blade-store/supplier/remove`,
        method: "POST",
        body
    }
}


// 导入
export interface ImportExcelParams {
  file?: any
}
// 导入
export function getSuppierImportExcel(params: ImportExcelParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/supplier/importexcel`,
    headers: {
      [ContentType]: MultiPartFormData,
    },
    method: 'POST',
    params,
  }
}
