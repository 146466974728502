import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, sendAsync } from "../../../app/applicationSlice";
import {  RootThunkApi } from "../../../app/store";
import * as S from "../../../services/inspect";

export const getInspectDataList = createAsyncThunk<
  void,
  S.InspectParams,
  RootThunkApi
>("InspectData/list", async (params, api) => {
  return sendAsync(S.getInspectData(params), api)
});

export const getExportExcel = createAsyncThunk<
  void,
  S.ExportExcelParams,
  RootThunkApi
>("/commodity/exportExcel", async (params, api) => {
  return sendAsync(S.getExportExcel(params), api);
});

