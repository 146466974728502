import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunkApi } from "../../app/store";
import * as S from "../../services/treamentLogManage";



export const updataTreatmentApi = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>("treament/updataTreatment", async (params, api) => {
  return sendAsync(S.updataTreatment(params), api)
});

export const getPaymentDetailRecord = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>("registration/paymentDetailRecord", async (params, api) => {
  return sendAsync(S.paymentDetailRecord(params), api)
});


export const getChangePayTimeAndMethod = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>("registration/changePayTimeAndMethod", async (params, api) => {
  return sendAsync(S.getChangePayTimeAndMethod(params), api)
});

export const allRegistiongAndTreatmentOperatioEditLogPage = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>("registration/allRegistiongAndTreatmentOperatioEditLogPage", async (params, api) => {
  return sendAsync(S.getAllRegistiongAndTreatmentOperatioEditLogPage(params), api)
});

export const allPaymentOperatioEditLogPage = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>("registration/allPaymentOperatioEditLogPage", async (params, api) => {
  return sendAsync(S.getAllPaymentOperatioEditLogPage(params), api)
});

