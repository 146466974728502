/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-18 16:02:17
 */
import { method } from 'lodash'
import { api } from '../app/applicationSlice'
import { BillingCategory } from '../models/billingCategory'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface examinePageParams {
  current?: number
  size?: number
  name?: string
  state: number
  billingCategory?: string
  insuranceCode?: string
  treatmentCategory?: string
  startTime?: string
  endTime?: string
  isFullMatch?: any
  chrgitmLv?:number
}

export interface examineSubmitbody {
  treatmentServiceCatalog: {
    billingCategory: BillingCategory
    mnemonicCode: string
    name: string
    retailPrice: number
    accountUnit: string
    no: number
    insuranceCode: number
    id?: number
    forceSelfPayment?: any
  }
  saveMappingBody: {
    billing_category?: string
    hilist_code?: string
    insuranceCode?: string
    is_reimbursement?: string
    limitId?: string
  }
}

export interface logoutOrEnableParams {
  ids: string
  state: number
}

export function examinePage(params: examinePageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/page`,
    method: 'GET',
    params,
  }
}

export function examineSubmit(body: examineSubmitbody): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/submit`,
    method: 'POST',
    body,
  }
}
export interface detailParams {
  id: number
  billingCategory?: string
  hilistCode?: string
  insuranceCode?: string
  mappingId?: string
}

export function examinDetail(params: detailParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/detail`,
    method: 'GET',
    params,
  }
}

export function examinelogoutorEnable(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/logoutOrEnable`,
    method: 'POST',
    params: {
      id,
    },
  }
}

export function examineLogoutEnableList(body: logoutOrEnableParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/logoutbatch`,
    method: 'POST',
    body,
  }
}

// 诊疗目录复制
export function copyZLMLCatalog(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/copy`,
    method: 'GET',
    params: {
      id,
    },
  }
}

// 导出
export function examineExportExcel(params: examinePageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/page/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
export function getSortNo(): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/getTreatmentNewestSortNo`,
    method: 'GET',
  }
}


