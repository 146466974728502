import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  TableColumnType,
  Popover,
  Table,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { NavBar } from '../../../compnents/nav/NavBar'
import { traceRoute } from '../../../layouts/layoutSlice'
import { CarryOverDetailEntry } from '../../../models/carryover'
import { Category } from '../../../models/category'
import { Page, startPageOf } from '../../../models/page'
import { SearchOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import { GetCarryOverDetailEntriesParams } from '../../../services/carryover'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import {
  getCarryOverDetailEntries,
  getCarryOverDetailExoprtFnAsync,
} from '../carryOverSlice'
import styles from './CarryOverDetail.module.css'

export const CarryOverDetail = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const query = useQuery()

  const [cell, setCell] = useState<any>({}) //合计栏

  const [page, setPage] = useState<Page<CarryOverDetailEntry>>(startPageOf())

  const [params, setParams] = useState<GetCarryOverDetailEntriesParams>({
    current: 1,
  })

  const [id, setId] = useState(0)

  useEffect(() => {
    const strId = query.get('id') as string
    setId(parseInt(strId))
  }, [query])

  useEffect(() => {
    setParams({ current: 1, tenantCarryOverId: id })
  }, [id])

  useEffect(() => {
    if (params.tenantCarryOverId) {
      dispatch(getCarryOverDetailEntries(params))
        .then(unwrapResult)
        .then((page) => setPage(page))
        .catch(() => {
          //
        })
    }
  }, [params])

  useEffect(() => {
    if (page.items.length == 0) return setCell({})

    const tempArr = page.items.filter((item: any) => {
      return item.totalIncomePrice != -1
    })

    setCell(tempArr[0])
  }, [page])

  const outCluedExport = () => {
    const data = {
      tenantCarryOverId: id,
      flag: 0,
      stock: 1,
      ...params,
    }
    dispatch(getCarryOverDetailExoprtFnAsync(data))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '库房结转明细.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const Hint = (title: string) => (
    <>
      {title}
      <Popover placement="top" content="展示这个周期内最后一次盘点的数量" >
        <QuestionCircleTwoTone style={{ marginLeft: '5px' }} />
      </Popover >
    </>
  );

  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        margin: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <NavBar
        where={['库房业务', '结转明细']}
        backtrace={{
          name: '库房结转',
          path: '/carryover',
          routeName: '库房工作站',
          state: {
            category: Category.JZ,
          },
        }}
        buttons={
          <Button
            type='primary'
            size='small'
            onClick={() =>
              dispatch(
                traceRoute({
                  name: '库房工作站',
                  path: '/carryover/items',
                  query: `id=${id}`,
                })
              )
            }
          >
            统计汇总
          </Button>
        }
      />

      <Col
        style={{
          flex: 1,
          marginTop: 10,
          borderRadius: theme.br,
          backgroundColor: theme.pn,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          initialValues={{ flag: 0, stock: 1 }}
          onFinish={(vs) => setParams({ ...params, ...vs, current: 1 })}
        >
          <Row
            align='middle'
            justify='space-between'
            style={{ margin: '20px 10px' }}
          >
            <Space>
              <Form.Item noStyle name='name'>
                <Input
                  placeholder='请输入药品名称'
                  prefix={<SearchOutlined />}
                  style={{ width: 220 }}
                  onChange={form.submit}
                  autoComplete='off'
                />
              </Form.Item>
              <Form.Item noStyle name='flag'>
                <Select onChange={form.submit}>
                  <Select.Option value={0}>多批次合并-否</Select.Option>
                  <Select.Option value={1}>多批次合并-是</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item noStyle name='stock'>
                <Select onChange={form.submit}>
                  <Select.Option value={0}>是否查询零库存-否</Select.Option>
                  <Select.Option value={1}>是否查询零库存-是</Select.Option>
                </Select>
              </Form.Item>
            </Space>
            <Space>
              <Button type='primary' onClick={() => outCluedExport()}>
                导出
              </Button>
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
        <EditableList
          rowKey={(_, i) => page.size * page.current + (i || 0)}
          onChangePage={(current, size) => {
            setParams({ ...params, current, size })
          }}
          style={{
            flex: 1,
            padding: '0 10px',
          }}
          page={page}
          bordered
          scroll={{ x: '28rem', y: '25rem' }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0} className={styles.total}></Table.Summary.Cell>
                <Table.Summary.Cell index={1} className={styles.total} align='right'>合计</Table.Summary.Cell>
                <Table.Summary.Cell index={2} className={styles.total}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={5}>
                  {cell.totalIncomeCount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={6}>
                  {cell.totalExpendCount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={7}>{cell.totalInventoryCount?.toFixed(2)}</Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={8}>
                  {cell.totalBeginningCount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={9}>
                  {cell.totalBeginningAmount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={10}>
                  {cell.totalCloseCount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={11}>
                  {cell.totalCloseAmount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={12}>
                  {cell.totalIncomePrice?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={13}>
                  {cell.totalExpendPrice?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={14}>
                  {cell.totalSplitIncomeCount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={15}>
                  {cell.totalSplitExpendCount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={16}>{cell.totalInventorySplitCount?.toFixed(2)}</Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={17}>
                  {cell.totalSplitBeginningCount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={18}>
                  {cell.totalSplitBeginningAmount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={19}>
                  {cell.totalSplitCloseCount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={20}>
                  {cell.totalSplitCloseAmount?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={21}>
                  {cell.totalSplitIncomePrice?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={22}>
                  {cell.totalSplitExpendPrice?.toFixed(2)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          columns={[
            {
              title: '序号',
              key: 'no',
              width: '3rem',
              align: 'center',
              fixed: 'left',

              render: (_1, _2, i) =>
                `${page.size * (page.current - 1) + i + 1}`,
            },
            {
              title: '药品名称',
              key: 'productInfo',
              align: 'center',
              width: '20rem',
              fixed: 'left',
              ellipsis: true,
              render: (_, r) => ({
                props: {
                  style: {
                    maxWidth: '20rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                },
                children: r.productInfo,
              }),
            },
            {
              title: '批次号',
              dataIndex: 'batchNo',
              align: 'center',
              width: '5rem',
              fixed: 'left',
              showSorterTooltip: true,
              render: (_, r) => ({
                props: {
                  style: {
                    maxWidth: '5rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                },
                children: r.batchNo,
              }),
            },
            {
              title: '进价',
              dataIndex: 'unitPrice',
              align: 'right',
              width: '8rem',
              render: (_: any, t: any) => {
                return params?.flag == 1
                  ? '-'
                  : t.unitPrice > 0
                    ? parseFloat(t.unitPrice).toFixed(5)
                    : '0.00000'
              },
            },
            {
              title: '售价',
              dataIndex: 'retailPrice',
              align: 'right',
              width: '8rem',
              render: (_: any, t: any) => {
                return params?.flag == 1
                  ? '-'
                  : t.retailPrice > 0
                    ? parseFloat(t.retailPrice).toFixed(5)
                    : '0.00000'
              },
            },
            {
              title: '以整存计',
              children: [
                {
                  title: '收入数量',
                  dataIndex: 'incomeCount',
                  align: 'right',
                  width: '6rem',
                  render: (_, t) => {
                    return t.incomeCount >= 0 ? t.incomeCount : '-'
                  },
                },
                {
                  title: '支出数量',
                  dataIndex: 'expendCount',
                  align: 'right',
                  width: '6rem',
                  // render: (_, t) => {
                  //   return  t.expendCount >= 0 ? t.expendCount : "-";
                  // },
                },
                {
                  title: Hint('盘点数量'),
                  dataIndex: 'inventoryCount',
                  align: 'right',
                  width: '6rem',
                  render: (_, t: any) => {
                    return t.inventoryCount >= 0
                      ? t.inventoryCount?.toFixed(2)
                      : '-'
                  },
                },
                {
                  title: '期初数量',
                  dataIndex: 'beginningCount',
                  align: 'right',
                  width: '6rem',
                },
                {
                  title: '期初金额',
                  dataIndex: 'beginningAmount',
                  align: 'right',
                  width: '6rem',
                  render: (_, t: any) => {
                    return t.beginningAmount > 0
                      ? t.beginningAmount?.toFixed(2)
                      : '0.00'
                  },
                },
                {
                  title: '结存数量',
                  dataIndex: 'closeCount',
                  align: 'right',
                  width: '6rem',
                },
                {
                  title: '结存金额',
                  dataIndex: 'closeAmount',
                  align: 'right',
                  width: '6rem',
                  render: (_, t: any) => {
                    return t.closeAmount > 0
                      ? t.closeAmount?.toFixed(2)
                      : '0.00'
                  },
                },
                {
                  title: '收入金额',
                  key: 'incomePrice',
                  align: 'right',
                  width: '7rem',
                  render: (_, r) => ({
                    props: {
                      style: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    },
                    children:
                      r.incomePrice >= 0 ? r.incomePrice?.toFixed(2) : '-',
                  }),
                },
                {
                  title: '支出金额',
                  dataIndex: 'expendPrice',
                  align: 'right',
                  width: '7rem',
                  render: (_, r) => ({
                    props: {
                      style: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    },
                    children:
                      r.expendPrice >= 0 ? r.expendPrice?.toFixed(2) : '-',
                  }),
                },
              ] as TableColumnType<CarryOverDetailEntry>[],
            } as TableColumnType<CarryOverDetailEntry>,
            {
              title: '以拆零计',
              children: [
                {
                  title: '拆零收入数量',
                  dataIndex: 'splitIncomeCount',
                  align: 'right',
                  width: '7rem',
                },
                {
                  title: '拆零支出数量',
                  dataIndex: 'splitExpendCount',
                  align: 'right',
                  width: '7rem',
                },
                {
                  title: Hint('拆零盘点数量'),
                  dataIndex: 'inventorySplitCount',
                  align: 'right',
                  width: '8rem',
                  render: (_, t: any) => {
                    return t.inventorySplitCount >= 0
                      ? t.inventorySplitCount?.toFixed(2)
                      : '-'
                  },
                },
                {
                  title: '拆零期初数量',
                  dataIndex: 'splitBeginningCount',
                  align: 'right',
                  width: '7rem',
                },
                {
                  title: '拆零期初金额',
                  dataIndex: 'splitBeginningAmount',
                  align: 'right',
                  width: '8rem',
                  render: (_, t: any) => {
                    return t.splitBeginningAmount > 0
                      ? t.splitBeginningAmount?.toFixed(2)
                      : '0.00'
                  },
                },
                {
                  title: '结存数量',
                  dataIndex: 'splitCloseCount',
                  align: 'right',
                  width: '7rem',
                },
                {
                  title: '拆零结存金额',
                  dataIndex: 'splitCloseAmount',
                  align: 'right',
                  width: '8rem',
                  render: (_, t: any) => {
                    return t.splitCloseAmount > 0
                      ? t.splitCloseAmount?.toFixed(2)
                      : '0.00'
                  },
                },
                {
                  title: '拆零收入金额',
                  dataIndex: 'splitIncomePrice',
                  align: 'right',
                  width: '7rem',
                  render: (_, r) => ({
                    props: {
                      style: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    },
                    children: r.splitIncomePrice?.toFixed(2),
                  }),
                },
                {
                  title: '拆零支出金额',
                  dataIndex: 'splitExpendPrice',
                  align: 'right',
                  width: '7rem',
                  render: (_, r) => ({
                    props: {
                      style: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    },
                    children: r.splitExpendPrice?.toFixed(2),
                  }),
                },
              ] as TableColumnType<CarryOverDetailEntry>[],
            } as TableColumnType<CarryOverDetailEntry>,
          ]}
        />
      </Col>
    </Col>
  )
}
