/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-30 09:43:40
 * @LastEditors: sj
 * @LastEditTime: 2022-07-25 14:09:24
 */
import React, { ReactElement } from "react";

export interface toothProps {
  pos?: boolean;
  style?: React.CSSProperties;
}

export const Cross: React.FC<toothProps> = ({
  pos,
  style,
  children,
}): ReactElement => {
  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        minWidth: "120px",
        minHeight: "70px",
        position: "relative",
        ...style,
      }}
    >
      <span
        style={{
          display: "inline-block",
          width: "100%",
          borderTop: "1px solid #666",
          position: "absolute",
          top: "50%",
          background: "#666",
          left: 0
        }}
      />
      <span
        style={{
          display: "inline-block",
          width: "0",
          borderLeft: "1px solid #666",
          height: "100%",
          minHeight: "70px",
          position: "absolute",
          left: "50%",
          background: "#666",
        }}
      />
      {pos ? (
        <>
          <span
            style={{
              position: "absolute",
              left: "25%",
              top: "50%",
              marginTop: "-9px",
              background: "#fff",
            }}
          >
            右
          </span>
          <span
            style={{
              position: "absolute",
              left: "75%",
              top: "50%",
              marginTop: "-9px",
              background: "#fff",
            }}
          >
            左
          </span>
        </>
      ) : (
        <></>
      )}
      {children}
    </div>
  );
};
