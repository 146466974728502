import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../../app/applicationSlice";
import { RootThunkApi } from "../../../app/store";
import {
  CancelOperation,
  CancelOperationParams,
  ExcuteDetailList,
  ExcuteDetailListParams,
  OperationSubmit,
  OperationSubmitParams,
  ToBeCompletedOperationList,
  ToBeCompletedOperationParams,
} from "../../../services/toBeCompletedOperation";

// 列表
export const getToBeCompletedOperationList = createAsyncThunk<
  void,
  ToBeCompletedOperationParams,
  RootThunkApi<void>
>("medicalstation/toBeCompletedOperationList", async (params, api) => {
  return sendAsync(ToBeCompletedOperationList(params), api);
});

// 详情
export const getExcuteDetailList = createAsyncThunk<
  void,
  ExcuteDetailListParams,
  RootThunkApi<void>
>("medicalstation/excuteDetailList", async (params, api) => {
  return sendAsync(ExcuteDetailList(params), api);
});

// 弹窗编辑确认提交
export const getOperationSubmit = createAsyncThunk<
  void,
  OperationSubmitParams,
  RootThunkApi<void>
>("medicalstation/OperationSubmit", async (params, api) => {
  return sendAsync(OperationSubmit(params), api);
});

// 弹窗取消
export const getCancelOperation = createAsyncThunk<
  void,
  CancelOperationParams,
  RootThunkApi<void>
>("medicalstation/CancelOperation", async (params, api) => {
  return sendAsync(CancelOperation(params), api);
});
