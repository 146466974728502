import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import styles from './recipeQueryList.module.css'
import { Columns } from './columns'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { getDepartmentList } from '../../department/departmentSlice'
import { pageRecipeQueryAsync } from './recipeQuery'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../../compnents/form/DateSelect'
import {
  EditableRecipeCategories,
  getRecipeCategoryName,
} from '../../../models/recipe'
import {
  selectDoctors,
  setDoctors,
  getDoctorsInDepartment,
  getOutpatientDepartment,
  selectDepartments,
} from '../../registration/register/registerSlice'
import { selectUserDepartmentId } from '../../../app/applicationSlice'
import { traceRoute } from '../../../layouts/layoutSlice'

export const RecipeQueryList = (props: { tabKey: string }): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [editForm] = Form.useForm()

  const [setForm] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false)

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    timeHead,
    timeTail,
    [DateRangeFieldName]: DateRangeType.Today,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const departments = useSelector(selectDepartments)

  const doctors = useSelector(selectDoctors)

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      pageRecipeQueryAsync({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const data = {
          total: res.total,
          items: res.records,
        }
        setPage(data)
      })
      .finally(() => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    if (!params.current) return
    getPage()
  }, [params])

  useEffect(() => {
    dispatch(getOutpatientDepartment())
  }, [])

  console.log(params, 'params')

  return (
    <Col className={styles.main}>
      <Form
        form={form}
        onFinish={(values) => {
          setParams({
            ...params,
            ...values,
            timeHead: values?.timeHead ? values?.timeHead : timeHead,
            timeTail: values?.timeTail ? values?.timeTail : timeTail,
            current: 1,
          })
        }}
      >
        <Row
          style={{ marginTop: 10, marginBottom: 20 }}
          justify='space-between'
        >
          <Space>
            <Form.Item noStyle name='param'>
              <Input
                autoComplete='off'
                prefix={<SearchOutlined />}
                placeholder='请输入患者姓名'
                style={{ width: '18rem' }}
                allowClear
                onChange={form.submit}
              />
            </Form.Item>
            <Form.Item noStyle name='departmentId'>
              <Select
                style={{ width: '8rem' }}
                placeholder='主管科室'
                allowClear
                onChange={(id: string) => {
                  form.setFieldsValue({
                    doctorId: undefined,
                  })
                  if (id) {
                    dispatch(getDoctorsInDepartment(id))
                  } else {
                    dispatch(setDoctors([]))
                  }
                  form.submit()
                }}
              >
                {departments.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='doctorId'>
              <Select
                style={{ width: '10rem' }}
                placeholder='主管医生'
                allowClear
                onChange={form.submit}
                filterOption={(input, option) => {
                  return option?.className
                    ? ((option?.className as unknown) as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    : false
                }}
              >
                {doctors.map((d) => (
                  <Select.Option
                    className={d.mnemonicCode}
                    key={d.id}
                    value={d.id}
                  >
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='category'>
              <Select
                style={{ width: '10rem' }}
                placeholder='处方类型'
                allowClear
                onChange={form.submit}
              >
                {EditableRecipeCategories?.map((v) => {
                  return (
                    <Select.Option value={v} key={v}>
                      {getRecipeCategoryName(v)}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <DateSelect
              style={{ width: '8.5rem' }}
              labelPrefix=''
              allowClear={false}
              namePrefix='time'
              options={TJFXTime}
              placeholder='日期范围-全部'
              initialValue={DateRangeType.Today}
              onChange={() => form.submit()}
            />
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
          </Space>
        </Row>
      </Form>
      <EditableList
        loading={pageLoading}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns(params.size * (params.current - 1), (action, t) => {
          switch (action) {
            case 'action':
              dispatch(
                traceRoute({
                  name: '医生门诊',
                  path: '/treatment',
                  query: `registrationId=${t.registrationId}`,
                  state: {
                    key: 'editor',
                  },
                })
              )
              break
            default:
              break
          }
        })}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
        // onRow={(d) => ({
        //   onClick: (e) => {
        //     e.stopPropagation()
        //     const isCheck = selectIds.includes(d?.id)
        //     let ids = []
        //     if (isCheck) {
        //       ids = selectIds.filter((id: any) => id !== d?.id)
        //     } else {
        //       ids = [...selectIds, d?.id]
        //     }
        //     setSelectIds(ids as string[])
        //   },
        // })}
      />
    </Col>
  )
}

// export const RecipeQueryListS = forwardRef(RecipeQueryList);
