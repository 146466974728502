import { Button, Col, Tabs, Row, TableColumnType, Form, Space, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DateRangeType, DateSelect, getDateRange } from "../../../compnents/form/DateSelect";
import { SupplierDealingParams } from "../../../services/supplierDealing";
import styles from "./supperDealing.module.css";
export const SupplierDealingQuery = (
  props: {
    onValueChange: (queries: SupplierDealingParams) => void;
    onExport: () => void
  }): ReactElement => {
  const [form] = Form.useForm();

  const { Option } = Select;
  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.ThisYear);
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
    });
    form.submit();
  }, []);
  return (
    <>
      <Form
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        form={form}
        onFinish={(values) => props.onValueChange(values)}>
        <Row
          style={{ marginBottom: 10, width: "100%" }}
          align="middle"
          justify="space-between"
        >
          <Space>
            <Form.Item name="goodsParam" noStyle>
              <Input
                autoComplete="off"
                placeholder="业务单号/商品名称/助记码/条形码"
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>

            <Form.Item name="supplierParam" noStyle>
              <Input
                autoComplete="off"
                placeholder="供应商名称/助记码"
                style={{ width: 220 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item name="category" noStyle>
              <Select placeholder="业务类型" allowClear style={{ width: 130 }} dropdownClassName={styles.select} onChange={() => form.submit()}>
                <Option value="1">业务类型-购入入库</Option>
                <Option value="2">业务类型-购入退库</Option>
              </Select>
            </Form.Item>
            <DateSelect
              style={{ width: "8.5rem" }}
              labelPrefix=""
              namePrefix="time"
              placeholder="今年"
              initialValue={DateRangeType.ThisYear}
              onChange={() => form.submit()}
              allowClear={false}
            />
          </Space>
          <Space>
            <Button
              type="primary"
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
            <Button
              type="primary"
              onClick={() => props.onExport()}
            >
              导出
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};
