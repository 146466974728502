/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-04 16:13:54
 */
import React, { ReactElement, useRef } from 'react'
import { useSelector } from 'react-redux'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { selectTwoMenus } from '../../layouts/layoutSlice'
import { Departments } from '../department/Departments'
import { OperatingRoom } from '../operatingRoom/operatingRoom'
import { PrintSettings } from '../print/PrintSettings'
import { Setting } from '../user/setting/setting'
import SettingTabbar from '../user/setting/settingTabbar'
import { Users, UsersRef } from '../user/Users'
import { InvoiceSetting } from './invoiceSetting'

export const TenantManager = (): ReactElement => {
  const twoMenus = useSelector(selectTwoMenus)

  const menuList = twoMenus?.filter((v) => {
    return v?.name == '机构管理'
  })?.[0]


  const getKeys = (i: string) => {
    switch (i) {
      case '员工管理':
        return '0'
      case '科室管理':
        return '1'
      case '手术室管理':
        return '4'
      case '打印设置':
        return '2'
      case '功能设置':
        return '3'
      case '发票号设置':
        return '5'

      default:
        return '-'
    }
  }
  const ref = useRef<UsersRef>(null)
  return (
    <TabBar style={{ margin: '10px 20px' }}>
      {menuList?.subMenus?.map((v) => {
        return (
          <>
            <TabPane key={getKeys(v?.name)} tab={v?.name}>
              {v?.name == '员工管理' ? (
                <Users ref={ref} />
              ) : v?.name == '科室管理' ? (
                <Departments onChange={(id) => id && ref.current?.refresh()} />
              ) : v?.name == '手术室管理' ? (
                <OperatingRoom
                  onChange={(id) => id && ref.current?.refresh()}
                />
              ) : v?.name == '打印设置' ? (
                <PrintSettings />
              ) : v?.name == '功能设置' ? (
                <SettingTabbar />
              ) : v?.name == '发票号设置' ? (
                <InvoiceSetting />
              ) : (
                <></>
              )}
            </TabPane>
          </>
        )
      })}
      {/* <TabPane key='0' tab='员工管理'>
        <Users ref={ref} />
      </TabPane>
      <TabPane key='1' tab='科室管理'>
        <Departments onChange={(id) => id && ref.current?.refresh()} />
      </TabPane>
      <TabPane key='4' tab='手术室管理'>
        <OperatingRoom onChange={(id) => id && ref.current?.refresh()} />
      </TabPane>
      <TabPane key='2' tab='打印设置'>
        <PrintSettings />
      </TabPane>
      <TabPane key='3' tab='功能设置'>
        <SettingTabbar />
      </TabPane>
      <TabPane key='5' tab='发票号设置'>
        <InvoiceSetting />
      </TabPane> */}
    </TabBar>
  )
}
