/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-17 10:58:18
 * @LastEditors: linxi
 * @LastEditTime: 2021-11-17 11:00:50
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import _ from "lodash"
import { sendAsync } from "../../app/applicationSlice"
import { RootThunkApi } from "../../app/store"
import {
  appealParams,
  standCountValue,
  standDetailValue,
  standListParams,
  standListValue,
} from "../../models/standbook"
import * as S from "../../services/live"
import {createLive, liveBody, searchLivesParams, startBody, startLives} from "../../services/live";


// 保存直播
export const saveLive = createAsyncThunk<void, liveBody, RootThunkApi>(
  "live/saveLive",
  async (params, api) => {
    return sendAsync(S.createLive(params), api)
  }
)

// 查询直播记录
export const searchLives = createAsyncThunk<void, searchLivesParams, RootThunkApi>(
    "live/searchLives",
    async (params, api) => {
      return sendAsync(S.searchLives(params), api)
    }
)
// 开播
export const startLive = createAsyncThunk<void, startBody, RootThunkApi>(
    "live/searchLives",
    async (body, api) => {
        return sendAsync(S.startLives(body), api)
    }
)
// 查询主播权限
export const checkAnchor = createAsyncThunk<void, void, RootThunkApi>(
    "live/searchLives",
    async (_, api) => {
        return sendAsync(S.checkAnchor(), api)
    }
)