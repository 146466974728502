import moment from 'moment'
import { DateTimeFormatSimple, DateTimeFormatSimpleOnlyOne } from './datetime'

export function singleZlPrintBody(data: any) {
  const tenantName = data?.tenantName
  const timeHead = data?.timeHead
  const timeTail = data?.timeTail
  const dataList = data?.data
  let dataStr = ''
  let totalPriceAll = 0.0
  let cashAmountAll = 0.0
  let insurancePayAmountAll = 0.0
  let jibenylbxtcjjzcAll = 0.0
  let gerenzhzcAll = 0.0
  let discountAmountAll = 0.0

  const hideList: any = localStorage.getItem(
    'jycxReload_table_column_filter'
  )
  const hideColumnList = JSON.parse(hideList)

  for (let j = 0; j < dataList.length; j++) {
    dataStr +=
      '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
      '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].patientName +
      '</div>'+
      (!hideColumnList?.find((v: any) => v == 'insuranceCode') ? '<div style="width: 7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].insuranceCode +
      '</div>'  :'') +
      (!hideColumnList?.find((v: any) => v == 'totalPrice') ?'<div style="width:9%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].totalPrice +
      '</div>':'' )+
      (!hideColumnList?.find((v: any) => v == 'cashAmount') ?'<div style="width:9%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].cashAmount +
      '</div>' :'')+
      (!hideColumnList?.find((v: any) => v == 'insurancePayAmount')?'<div style="width:9%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].insurancePayAmount +
      '</div>' :'')+
      '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].jibenylbxtcjjzc +
      '</div>' +
      '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].gerenzhzc +
      '</div>' +
      '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].discountAmount +
      '</div>' +
      (!hideColumnList?.find((v: any) => v == 'payMethodName') ?'<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].payMethodName +
      '</div>':'' )+
      (!hideColumnList?.find((v: any) => v == 'userName') ?'<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].userName +
      ' </div>':'') +
      (!hideColumnList?.find((v: any) => v == 'state') ?'<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].nameState +
      ' </div>' :'') +
      (!hideColumnList?.find((v: any) => v == 'chargeOrRefundTime') ?'<div style="width:10%;display: table-cell;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].chargeOrRefundTime +
      ' </div>' :'')+
      '</div>'
    totalPriceAll += parseFloat(
      dataList[j].stateNumber == 5 ? 0 : dataList[j].totalPrice
    )
    cashAmountAll += parseFloat(
      (dataList[j].stateNumber == 5 ? 0 : dataList[j].cashAmount)
    )
    insurancePayAmountAll += parseFloat(
      dataList[j].stateNumber == 5 ? 0 : dataList[j].insurancePayAmount
    )
    jibenylbxtcjjzcAll += parseFloat(
      dataList[j].stateNumber == 5 ? 0 : dataList[j].jibenylbxtcjjzc
    )
    gerenzhzcAll += parseFloat(
      dataList[j].stateNumber == 5 ? 0 : dataList[j].gerenzhzc
    )
    discountAmountAll += parseFloat(
      dataList[j].stateNumber == 5 ? 0 : dataList[j].discountAmount
    )
  }

  
  const contents =
    '<div style="page-break-after:always;page-break-inside:always;page-break-before:always;">' +
    '<h3 style="text-align: center;margin-bottom: 5px;" >结算清单</h3>' +
    '<div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size:13px">' +
    ' <div style="display: inline-block;width: 50%;text-align: left;">机构名称:' +
    '<font style="font-weight: 300;">' +
    tenantName +
    '</font>' +
    '</div>' +
    '<div style="display: inline-block;width: 50%;text-align: right;">结算日期:' +
    '<font style="font-weight: 300;">' +
    timeHead +
    '</font>' +
    ' <font style="font-weight: 300;">至</font>' +
    '<font style="font-weight: 300;">' +
    timeTail +
    '</font>' +
    '</div>' +
    '</div>' +
    '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-top: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">姓名' +
    '</div>' +
    (!hideColumnList?.find((v: any) => v == 'insuranceCode')  ? '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">参保险种' +
    '</div>' :'' )+
    (!hideColumnList?.find((v: any) => v == 'totalPrice')  ? '<div style="width:9%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">总金额' +
    '</div>' :'')+
    (!hideColumnList?.find((v: any) => v == 'cashAmount')  ?'<div style="width:9%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">现金金额' +
    '</div>' :'')+
    (!hideColumnList?.find((v: any) => v == 'insurancePayAmount')  ?'<div style="width:9%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">报销金额' +
    '</div>':'') +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">统筹金额' +
    '</div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">个人账户' +
    '</div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">优惠金额' +
    '</div>' +
    (!hideColumnList?.find((v: any) => v == 'payMethodName')  ? '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">支付方式' +
    ' </div>' :'')+
    (!hideColumnList?.find((v: any) => v == 'userName')  ? '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">收款人' +
    ' </div>':'') +
    (!hideColumnList?.find((v: any) => v == 'state')  ?'<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">状态' +
    ' </div>' :'')+
    (!hideColumnList?.find((v: any) => v == 'chargeOrRefundTime') ?'<div style="width:10%;display: table-cell;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">结算时间' +
    ' </div>':'') +
    '</div>' +
    dataStr +
    '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
    (!hideColumnList?.find((v: any) => v == 'insuranceCode')  ?
    '<div style="width: 15%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">合计' +
    '</div>' :
    '<div style="width: 8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">合计' +
    '</div>') +
    (!hideColumnList?.find((v: any) => v == 'totalPrice') ?'<div style="width:9%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
    +totalPriceAll?.toFixed(2) +
    '</div>' :'')+
    (!hideColumnList?.find((v: any) => v == 'cashAmount') ?'<div style="width:9%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
    +cashAmountAll?.toFixed(2) +
    '</div>' :'') +
    (!hideColumnList?.find((v: any) => v == 'insurancePayAmount') ?'<div style="width:9%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
    +insurancePayAmountAll?.toFixed(2) +
    '</div>' :'') +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
    +jibenylbxtcjjzcAll?.toFixed(2) +
    '</div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
    +gerenzhzcAll?.toFixed(2) +
    '</div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
    +discountAmountAll?.toFixed(2) +
    '</div>' +
    (!hideColumnList?.find((v: any) => v == 'payMethodName')  ?'<div style="width:8%;display: table-cell;box-sizing: border-box;;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
    '/' +
    ' </div>':'') +
    (!hideColumnList?.find((v: any) => v == 'userName')  ?'<div style="width:8%;display: table-cell;box-sizing: border-box;;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
    '/' +
    ' </div>' :'')+
    (!hideColumnList?.find((v: any) => v == 'state')  ?'<div style="width:8%;display: table-cell;box-sizing: border-box;;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
    '/' +
    ' </div>' :'') +
    (!hideColumnList?.find((v: any) => v == 'chargeOrRefundTime') ?'<div style="width:10%;display: table-cell;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
    '/' +
    ' </div>' :'') +
    ' </div>' +
    '<div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size:13px">' +
    ' <div style="display: inline-block;width: 19%;text-align: left;">单位领导审批:' +
    '</div>' +
    ' <div style="display: inline-block;width: 19%;text-align: left;">科室领导审批:' +
    '</div>' +
    ' <div style="display: inline-block;width: 17%;text-align: left;">审核人:' +
    '</div>' +
    '<div style="display: inline-block;width: 15%;text-align: left;">制表人:' +
    '</div>' +
    '<div style="display: inline-block;width: 28%;text-align: right;">制表时间: ' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</div>' +
    '</div>' +
    '</div>'
  return contents
}
