import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Form, notification } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { selectTenantName, selectUserName } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { traceRoute } from '../../../layouts/layoutSlice'
import { DateTimeFormatSimpleOnly } from '../../../models/datetime'
import { fromMaterialJson } from '../../../models/material'
import { PrintTemplateCategory } from '../../../models/print'
import { PrintZLZXDBody } from '../../../models/PrintTemplateModalPrint'
import { ToBeCompletedTreatmentParams } from '../../../services/toBeCompletedTreatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import { getWebPrintDataByPaymentId } from '../../recipe/recipeListSlice'
import { getPrintZXDData } from '../../treatment/editor/recipeEditorSlice'
import { ToBeCompletedTreatmentColumns } from './column'
import { ExcuteDetailModal } from './excuteDetailModal'
import { ToBeCompletedTreatmentquery } from './Query'
import {
  getLatelyOperatorList,
  getToBeCompletedTreatmentList,
} from './toBeCompletedTreatmentSlice'

export const ToBeCompletedTreatment = (props: {
  patientId?: string
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const tenantName = useSelector(selectTenantName)

  const userName = useSelector(selectUserName)

  const [printZXDData, setPrintZXDData] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [total, setTotal] = useState<any>()

  const [data, setData] = useState<any[]>([])

  const [id, setId] = useState<any>()

  const [doctorList, setDoctorList] = useState<any>([])

  const [queries, setQueries] = useState<ToBeCompletedTreatmentParams>({
    current: 0,
    size: 10,
    state: 0,
    startTime: null,
    endTime: null,
  })

  const [patientId, setPatientId] = useState<any>()

  const [recipeId, setRecipeId] = useState<any>()

  const [treatmentId, setTreatmentId] = useState<any>()

  const [printTemplateCategory, setPrintTemplateCategory] = useState<
    PrintTemplateCategory | undefined
  >()

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  const [latelyOperatorName, setLatelyOperatorName] = useState('') //查询  执行人数据


  const getReciptPrintList = (recipeId?: any) => {
    dispatch(getPrintZXDData(recipeId))
      .then(unwrapResult)
      .then((v: any) => {
        setPrintZXDData(v)
      })
  }

  const getDataList = () => {
    setPageLoading(true)

    // patientId 患者管理过来的 ID
    dispatch(
      getToBeCompletedTreatmentList({ ...queries, patientId: props.patientId })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setData(res.records)
        setTotal(res.total)
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (!queries.current) return
    getDataList()
    !props.patientId &&
      sessionStorage.setItem(location.pathname, JSON.stringify(queries))
  }, [queries])


  const getRecipeExecuteZLForWeb = (paymentId: any) => {
    const getWebPrintContent = getWebPrintDataByPaymentId

    if (paymentId) {
      dispatch(getWebPrintContent(paymentId))
        .then(unwrapResult)
        .then((data: any) => {
          // 执行单打印西药方处方明细接口获取
          const list = data?.recipeWebPrintVOList?.map((v: any) => {
            return {
              ...v,
              itemList: v?.recipeItemWebPrintVOList,
            }
          })
          const printList = list
            ?.map((val: any) => {
              return val?.itemList
            })
            ?.flat(1)
          let printZXDList
          //收费页面进来的，需要打印所有非药品处方
          const sortDataList =
            _.chain(printList)
              .map((m) => fromMaterialJson(m))
              .sort((a, b) => {
                if (a.groupNumber < b.groupNumber) {
                  return -1
                }
                if (a.groupNumber > b.groupNumber) {
                  return 1
                }
                return a.sort - b.sort
              })
              .value() || []
          printZXDList = sortDataList?.filter((v: any) => {
            return v?.drugType == 1
          })
          //否则，只打印当前处方
          printZXDList = sortDataList?.filter((v: any) => {
            return v?.drugType == 1
          })

          const dataMainContent = {
            tenantName: tenantName?.split('-')?.[0],
            userName: userName,
            dataMain: {
              treatment: data?.treatment,
              // 执行单打印数据
              materials: printZXDList,
              // 签名
              SignatureSrc: data?.printSettingData?.userSign,
              printAddress: data?.patientAddress,
            },
          }

          if (printZXDList?.length) {
            const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
            page?.document?.write(PrintZLZXDBody(dataMainContent)) // 写入打印页面的内容
            setTimeout(function () {
              page?.print() // 打印
              page?.close()
            }, 200)
          } else {
            notification.info({
              message: '暂无可打印执行单',
            })
          }
        })
    } else {
      notification.error({
        message: '缺少必要的打印参数',
      })
    }
  }

  return (
    <Col
      style={{
        height: props.patientId ? 'calc(100% - 20px)' : ' calc(100% - 30px)',
        overflow: 'hidden',
        position: 'relative',
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '10px 15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ToBeCompletedTreatmentquery
          DoctorList={doctorList}
          dataList={data}
          onValueChange={(v) => {
            setQueries({ ...queries, ...v })
          }}
          onSelectIpt={(v) => setLatelyOperatorName(v)}
          patientId={props.patientId}
        />

        <EditableList
          loading={pageLoading}
          page={{
            items: data,
            current: queries.current || 1,
            size: queries.size || 10,
            total: total,
          }}
          columns={ToBeCompletedTreatmentColumns((text, t) => {
            if (text === 'execute') {
              setIsModalVisible(true)
              setId(t?.id)
            } else if (text == 'print') {
              if (t?.recipeId) {
                setPatientId(t?.patientId)
                setRecipeId(t?.recipeId)
                setTreatmentId(t?.treatmentId)
                getReciptPrintList(t?.recipeId)
                setPrintTemplateCategory(PrintTemplateCategory.Zxd)
              }else{
                notification.info({
                  message: '暂无可打印的处方',
                })
              }
            }
          }, (queries.size || 10) * ((queries.current || 1) - 1))}
          onChangePage={(current: any, size: any) => {
            setQueries({
              ...queries,
              current,
              size,
            })
          }}
          onRow={(r) => ({
            onDoubleClick: () => {
              dispatch(
                traceRoute({
                  name: '执行记录',
                  path: '/excuteDetail',
                  query: `id=${r.id}`,
                  state: {
                    tag: 0,
                  },
                })
              )
              setId(r.id)
            },
          })}
        />
      </Col>
      <ExcuteDetailModal
        isModalVisible={isModalVisible}
        id={id}
        onCancel={() => {
          setIsModalVisible(false)
          setId('')
          getDataList()
        }}
        onOk={() => {
          setIsModalVisible(false)
          setId('')
          getDataList()
        }}
      />

      <PrintTemplateModal
        patientId={patientId}
        printZXDData={printZXDData}
        pagePrintFlag={'zxdPrint'}
        visible={!!printTemplateCategory}
        templateCategory={printTemplateCategory}
        recipeId={recipeId}
        treatmentId={treatmentId}
        onCancel={() => setPrintTemplateCategory(undefined)}
        onOk={() => setPrintTemplateCategory(undefined)}
      />
    </Col>
  )
}
