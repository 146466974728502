
import { SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Form, Input, Select, Space, Row, Col } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
    DateRangeFieldName,
    DateRangeType,
    DateSelect,
    getDateRange,
    ToothDay,
} from '../../compnents/form/DateSelect'
import {
    getPatientLabels,
} from '../patient/list/patientListSlice'
import { getCouponsSettingList } from './coupons'

const { Option } = Select

export const SettiongQuery = (props: {
    onValueChange: (queries: any) => void
    onAdd: () => void
}) => {
    const dispatch = useDispatch<RootDispatch>()
    const [form] = Form.useForm()

    return (
        <div>
            <Form
                form={form}
                autoComplete='off'
                onFinish={(values) => {
                    props.onValueChange({ ...values, name: undefined, couponsName: values?.name })
                }}
            >
                <Row style={{ height: '4rem' }} align='middle' justify='space-between'>
                    <Space>
                        <Form.Item noStyle name='name'>
                            <Input
                                placeholder='输入优惠券名称'
                                allowClear
                                prefix={<SearchOutlined />}
                                style={{ width: '14rem' }}
                                onChange={form.submit}
                            />
                        </Form.Item>
                        <Form.Item noStyle name='category'>
                            <Select
                                style={{ width: '10rem' }}
                                placeholder='类型'
                                allowClear
                                onChange={form.submit}
                            >
                                <Option value={2}>抵扣券</Option>
                                <Option value={1}>折扣券</Option>
                                <Option value={3}>体验券</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item noStyle name='status'>
                            <Select
                                style={{ width: '10rem' }}
                                // placeholder='随访类型'
                                defaultValue={0}
                                allowClear
                                onChange={form.submit}
                            >
                                <Option value={0}>未注销</Option>
                                <Option value={1}>已注销</Option>
                            </Select>
                        </Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                            style={{ width: '100%', marginRight: '10px' }}
                            onClick={() => props.onValueChange(form.getFieldsValue())}
                        >
                            查询
                        </Button>
                    </Space>
                    <Space>
                        <Button
                            type='primary'
                            style={{ width: '100%' }}
                            onClick={() => props.onAdd()}
                        >
                            新增优惠券
                        </Button>
                    </Space>
                    {/* {props.type != 1 && (
            <Button type='primary' onClick={refresh}>
              查询
            </Button>
          )} */}
                </Row>
            </Form>
        </div>
    )
}

export const ActiveSettiongQuery = (props: {
    onValueChange: (queries: any) => void
    onAdd: () => void
}) => {
    const dispatch = useDispatch<RootDispatch>()
    const [form] = Form.useForm()

    return (
        <div>
            <Form
                form={form}
                autoComplete='off'
                onFinish={(values) => {
                    props.onValueChange({ ...values })
                }}
            >
                <Row style={{ height: '4rem' }} align='middle' justify='space-between'>
                    <Space>
                        <Form.Item noStyle name='name'>
                            <Input
                                placeholder='输入活动名称'
                                allowClear
                                prefix={<SearchOutlined />}
                                style={{ width: '14rem' }}
                                onChange={form.submit}
                            />
                        </Form.Item>
                        <Form.Item noStyle name='status'>
                            <Select
                                style={{ width: '10rem' }}
                                // placeholder='随访类型'
                                defaultValue={0}
                                allowClear
                                onChange={form.submit}
                            >
                                <Option value={0}>未注销</Option>
                                <Option value={1}>已注销</Option>
                            </Select>
                        </Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                            style={{ width: '100%', marginRight: '10px' }}
                            onClick={() => props.onValueChange(form.getFieldsValue())}
                        >
                            查询
                        </Button>
                    </Space>
                    <Space>
                        <Button
                            type='primary'
                            style={{ width: '100%' }}
                            onClick={() => props.onAdd()}
                        >
                            新增活动
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    )
}

export const CouponsIssueQuery = (props: {
    onValueChange: (queries: any) => void
    onAdd: () => void
}) => {
    const dispatch = useDispatch<RootDispatch>()

    const [form] = Form.useForm()

    const inputRef = useRef<any>(null)

    const [labelList, setLabelList] = useState<any>([])

    useEffect(() => {
        dispatch(getPatientLabels({ current: 1, size: 50 }))
            .then(unwrapResult)
            .then((res: any) => {
                setLabelList(res)
            })
        inputRef.current!.focus({
            cursor: 'start',
        })
    }, [])

    return (
        <div>
            <Form
                form={form}
                autoComplete='off'
                onFinish={(values) => {
                    props.onValueChange({ ...values, labelIds: values.labelIds.join(',') })
                }}
            >
                <Row style={{ height: '4rem' }} align='middle' justify='space-between'>
                    <Space>
                        <Form.Item noStyle name='name'>
                            <Input
                                ref={inputRef}
                                placeholder='患者姓名/手机号/助记码/病历号'
                                allowClear
                                prefix={<SearchOutlined />}
                                style={{ width: '16rem' }}
                                onChange={form.submit}
                            />
                        </Form.Item>
                        <Form.Item name='labelIds' noStyle>
                            <Select
                                showSearch
                                placeholder='标签选择'
                                optionFilterProp='children'
                                style={{ width: 180 }}
                                mode='multiple'
                                onChange={form.submit}
                                onSearch={(v) => {
                                    dispatch(getPatientLabels({ current: 1, size: 50, name: v }))
                                        .then(unwrapResult)
                                        .then((res: any) => {
                                            setLabelList(res)
                                        })
                                }}
                                filterOption={false}
                            >
                                {labelList.length &&
                                    labelList.map((v: any) => (
                                        <Option key={v.id} value={v.id}>
                                            {v.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Button
                            type='primary'
                            htmlType='submit'
                            style={{ width: '100%', marginRight: '10px' }}
                            onClick={() => props.onValueChange(form.getFieldsValue())}
                        >
                            查询
                        </Button>
                    </Space>
                    <Space>
                        <Button
                            type='primary'
                            style={{ width: '100%' }}
                            onClick={() => props.onAdd()}
                        >
                            发放
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    )
}

export const CouponsRecordQuery = (props: {
    selectKey: string
    onValueChange: (queries: any) => void
}) => {
    const dispatch = useDispatch<RootDispatch>()
    const [form] = Form.useForm()

    const [labelList, setLabelList] = useState<any>([])

    useEffect(() => {
        dispatch(getCouponsSettingList({ current: 1, size: 50 }))
            .then(unwrapResult)
            .then((res: any) => {
                setLabelList(res.records)
            })
    }, [props.selectKey])

    return (
        <div>
            <Form
                form={form}
                autoComplete='off'
                onFinish={(values) => {
                    props.onValueChange({ ...values })
                }}
            >
                <Row style={{ height: '4rem' }} align='middle' justify='space-between'>
                    <Space>
                        <Form.Item noStyle name='patientParam'>
                            <Input
                                placeholder='患者姓名/手机号/助记码/病历号'
                                allowClear
                                prefix={<SearchOutlined />}
                                style={{ width: '18rem' }}
                                onChange={form.submit}
                            />
                        </Form.Item>
                        <Form.Item name='couponName' noStyle>
                            <Select
                                showSearch
                                placeholder='优惠券名称'
                                style={{ width: 180 }}
                                allowClear
                                onChange={form.submit}
                                filterOption={false}
                            >
                                {labelList.length &&
                                    labelList.map((v: any) => (
                                        <Option key={v.id} value={v.name}>
                                            {v.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item noStyle name='useStatus'>
                            <Select
                                style={{ width: '10rem' }}
                                placeholder='状态'
                                allowClear
                                onChange={form.submit}
                            >
                                <Option value={1}>未使用</Option>
                                <Option value={2}>已使用</Option>
                                <Option value={3}>已过期</Option>
                                <Option value={9}>已失效</Option>
                            </Select>
                        </Form.Item>
                        <DateSelect
                            labelPrefix=''
                            placeholder='接诊时间'
                            namePrefix='time'
                            allowClear={false}
                            initialValue={DateRangeType.Today}
                            style={{ width: 140 }}
                            onChange={form.submit}
                        />
                        <Button
                            type='primary'
                            htmlType='submit'
                            style={{ width: '100%', marginRight: '10px' }}
                            onClick={() => props.onValueChange(form.getFieldsValue())}
                        >
                            查询
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    )
}