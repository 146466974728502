import { Checkbox, Col, Row } from 'antd'
import _ from 'lodash'
import React, { ReactElement, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationType } from '../../app/applicationSlice'
import { NavBar } from '../../compnents/nav/NavBar'
import { Label } from '../../compnents/widgets/Label'
import { ThemeContext } from '../../theme/ThemeContext'
import {
  clearMenus,
  getGrantedMenuList,
  getMenuList,
  grantMenus,
  selectGrantedMenus,
  selectMenus,
} from './grantSlice'

interface GrantMenusProps {
  type: ApplicationType
  tenantId?: string
  userId?: string
  visible?: any
}

export const GrantMenus = ({
  type,
  tenantId,
  userId,
  visible,
}: GrantMenusProps): ReactElement => {
  const dispatch = useDispatch()

  const theme = useContext(ThemeContext)

  const menus = useSelector(selectMenus)

  const granted = useSelector(selectGrantedMenus)

  const class1Menus = menus.filter((m) => m.subMenus.length === 0)

  const class2Menus = menus.filter((m) => m.subMenus.length > 0)

  const spanCount = type === ApplicationType.Management ? 3 : 4

  const allSelected =
    class1Menus.length == 0
      ? false
      : class1Menus.reduce((b, m) => b && granted.includes(m.id), true)

  const anySelected =
    class1Menus.length == 0
      ? false
      : class1Menus.reduce((b, m) => b || granted.includes(m.id), false)

  useEffect(() => {
    if (visible == 'true') {
      dispatch(getMenuList(type, userId ? tenantId : undefined))
      dispatch(getGrantedMenuList(type, tenantId, userId))
    }
  }, [dispatch, type, tenantId, userId, visible])

  useEffect(() => {
    return () => {
      dispatch(clearMenus())
    }
  }, [])

  const grant = (ids: string[], parentId: string, selected: boolean) => {
    if (selected) {
      const menuIds = _.chain(granted)
        .concat(ids)
        .concat(parentId)
        .filter((id) => !!id && id !== '-1')
        .uniq()
        .value()
      dispatch(grantMenus(type, tenantId, userId, menuIds))
    } else {
      let menuIds = _.chain(granted).difference(ids).value()
      const parent = class2Menus.find((m) => m.id === parentId)
      if (parent) {
        const noChild = parent.subMenus.reduce(
          (b, m) => b && !menuIds.includes(m.id),
          true
        )
        if (noChild) {
          menuIds = menuIds.filter((id) => id !== parentId)
        }
      }
      dispatch(grantMenus(type, tenantId, userId, menuIds))
    }
  }

  return (
    <div style={{ height: '100%' }}>
      {tenantId && (
        <NavBar
          style={{ margin: '10px 20px' }}
          where={['机构管理', '功能设定']}
          backtrace={{
            name: '机构管理',
            path: '/admin/tenant',
          }}
        />
      )}
      <div
        style={
          type === ApplicationType.Management
            ? {
                padding: 15,
                margin: '10px 20px',
                borderRadius: 10,
                height: 'calc(100% - 70px)',
                backgroundColor: theme.pn,
              }
            : undefined
        }
      >
        <Label label='勾选需要开通的权限' />
        <Row
          style={{
            marginTop: 20,
            padding: '20px 20px 0 20px',
            backgroundColor: theme.c3,
          }}
        >
          <Col span={spanCount}>
            <Checkbox
              style={{
                color: theme.tc1,
                fontSize: '1.125rem',
                marginBottom: 18,
              }}
              indeterminate={!allSelected && anySelected}
              checked={allSelected}
              onChange={(e) => {
                grant(
                  class1Menus.map((m) => m.id),
                  '',
                  e.target.checked
                )
              }}
            >
              全选
            </Checkbox>
          </Col>
          {class1Menus.map((m) => (
            <Col key={m.id} span={spanCount}>
              <Checkbox
                style={{
                  color: theme.tc1,
                  fontSize: '1.125rem',
                  marginBottom: 18,
                }}
                checked={granted.includes(m.id)}
                onChange={(e) => grant([m.id], m.parentId, e.target.checked)}
              >
                {m.name}
              </Checkbox>
            </Col>
          ))}
        </Row>
        {class2Menus.map((p) => {
          const allSubmenuSelected = p.subMenus.reduce(
            (b, m) => b && granted.includes(m.id),
            true
          )
          const anySubmenuSelected = p.subMenus.reduce(
            (b, m) => b || granted.includes(m.id),
            false
          )
          return (
            <div key={p.id}>
              <Row
                align='middle'
                style={{
                  height: '3rem',
                  backgroundColor: theme.c3,
                  marginTop: 12,
                  padding: '0 20px',
                }}
              >
                <Checkbox
                  style={{ fontSize: '1.125rem', color: theme.tc1 }}
                  indeterminate={!allSubmenuSelected && anySubmenuSelected}
                  checked={allSubmenuSelected}
                  onChange={(e) => {
                    grant(
                      p.subMenus.map((m) => m.id),
                      p.id,
                      e.target.checked
                    )
                  }}
                >
                  {p.name}
                </Checkbox>
              </Row>
              <Row
                style={{
                  border: `1px solid ${theme.wbd}`,
                  borderTop: 'none',
                  padding: '0 48px 15px 48px',
                }}
              >
                {p.subMenus.map((m) => (
                  <Col key={m.id} span={spanCount}>
                    <Checkbox
                      style={{
                        fontSize: '1.125rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: theme.tc2,
                        marginTop: 15,
                        whiteSpace: 'nowrap',
                      }}
                      checked={granted.includes(m.id)}
                      onChange={(e) =>
                        grant([m.id], m.parentId, e.target.checked)
                      }
                    >
                      {m.name}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </div>
          )
        })}
      </div>
    </div>
  )
}
