import {
    Button,
    Col,
    Collapse,
    Form,
    Modal,
    Row,
    Space,
    Table,
    message,
    notification,
    Input,
    Upload
} from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import React, { useEffect, useState } from 'react'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CaretUpFilled } from '@ant-design/icons'
import _, { divide } from 'lodash'
import styles from '../../nurse/formula/formulas/formula.module.css'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../app/store'
import moment from 'moment'
import {
    DateTimeFormat,
    DateTimeFormatSimple,
} from '../../../models/datetime'
export const ModelList = (props: {
    detail: any,
    visible: boolean
    onOk: () => void
    onCancel: () => void
}): ReactElement => {
    const success = () => {
        message.success('操作成功')
    }
    const { Panel } = Collapse

    const { TextArea } = Input;

    const dispatch = useDispatch<RootDispatch>()

    const [nofarmulaDetails, setNofarmulaDetails] = useState<any[]>([])

    const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

    const [form] = Form.useForm()

    const detail = props.detail

    const [previewTitle, setPreviewTitle] = useState('');

    const [fileList, setFileList] = useState<UploadFile[]>([])

    const fileProps: UploadProps = {
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        defaultFileList: []
    }

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setFileList(newFileList);

    const uploadButton = (
        <div>
            <PlusOutlined />
        </div>
    );

    return (
        <>
            <Modal
                title='查看'
                width='800px'
                visible={props.visible}
                centered
                footer={null}
                onOk={() => {
                    props.onOk()
                }}
                onCancel={() => {
                    props.onCancel()
                }}
            >
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '400px',
                        marginLeft: '25px'
                    }}
                >
                    <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                        <Col span={12}>
                            <span>手术项目：{detail?.surgicalItems}</span>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                        <Col span={12}>
                            <span>消费者姓名：{detail?.name}</span>
                        </Col>
                        <Col span={12}>
                            <span>手机号码：{detail?.mobile}</span>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                        <Col span={12}>
                            <span>性别：{detail?.sex == '0' ? '女' : '男'}</span>
                        </Col>
                        <Col span={12}>
                            <span>年龄：{detail?.age}</span>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                        <Col span={12}>
                            <span>手术时间：{moment(detail?.surgicalDate).format(DateTimeFormatSimple)}</span>
                        </Col>
                        <Col span={12}>
                            <span>麻醉方式：{detail?.anaesth}</span>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                        <Col span={12}>
                            <span>主刀医生：{detail?.doctor}</span>
                        </Col>
                        <Col span={12}>
                            <span>麻醉医生：{detail?.anaedoctor}</span>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                        <Col span={12}>
                            <span>助手：{detail?.helper1}</span>
                        </Col>
                        <Col span={12}>
                            <span>状态：{detail?.certifyingStatus == 0 ? '已取消' : (
                                detail?.certifyingStatus == 1 ? '已术前确认' : '待术前确认')}</span>
                        </Col>
                    </Row>
                    <Row  style={{ marginTop: '30px' }}>
                        <Col span={23}>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ float: 'right' }}
                                onClick={() => {
                                    props.onCancel()
                                }}
                            >
                                确认
                            </Button>
                        </Col>
                        {/* <Col span={1}>
                            <Button
                                type='primary'
                                onClick={() => {
                                    form.resetFields()
                                    props.onCancel()
                                }}
                            >
                                关闭
                            </Button>
                        </Col> */}
                    </Row>
                </Col>
            </Modal>
        </>
    )
}
