/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-12 11:24:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-12 11:26:40
 */
export function getTransactionQueryName(tourCode: number): string {
  switch (tourCode) {
    case 3:
      return '已收费'
    case 5:
      return '已退费'
    case 7:
      return '欠费中'
  }
  return '-'
}
