import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store"
import { durgsystemDetail, durgsystemlogoutOrEnable, durgSystemPage, durgSystemSave, storageget, storehouseBody, storehouseParams } from "../../services/storehouse";

const initialState = {
  Durgsystemdata: [],
  Total: 0,
  Current: 0,
  Durgstate: undefined,
  Detail: {},
  StorageData: [],
  SelectId: 0,
  pageLoading: false,
}

export const durgsystemSlice = createSlice({
  name: 'durgsyetem',
  initialState,
  reducers: {
    setData(state, action) {
      state.Durgsystemdata = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setDurgState(state, action) {
      state.Durgstate = action.payload
    },
    setDetail(state, action) {
      state.Detail = action.payload
    },
    setStorage(state, action) {
      state.StorageData = action.payload
    },
    setSelectId(state, action) {
      state.SelectId = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})
export const {
  setData,
  setTotal,
  setCurrent,
  setDurgState,
  setDetail,
  setStorage,
  setSelectId,
  setPageLoading,
} = durgsystemSlice.actions

export function pageDurgsystem(
  current: number,
  size: number,
  state?: number
): RootThunk {
  return api(durgSystemPage(current, size, state), (data: any, dispatch) => {
    const records = data.records
    const total = data.total
    dispatch(setData(records))
    dispatch(setTotal(total))
    dispatch(setPageLoading(false))
  })
}

export function getStorage(params: storehouseParams): RootThunk {
  return api(storageget(params), (data: any, dispatch) => {
    dispatch(setStorage(data.records))
  })
}

export function saveDurgSystem(body: storehouseBody): RootThunk {
  return (dispatch, getState, args) => {
    const current = getState().durgsyetem.Current
    const DurgState = getState().durgsyetem.Durgstate
    api(durgSystemSave(body), (data: any, dispatch) => {
      dispatch(pageDurgsystem(current, 10, DurgState))
    })(dispatch, getState, args)
  }
}

export function logoutOrEnableDurgSystem(id: number): RootThunk {
  return (dispatch, getState, args) => {
    const current = getState().durgsyetem.Current
    const DurgState = getState().durgsyetem.Durgstate
    api(durgsystemlogoutOrEnable(id), (data: any, dispatch) => {
      dispatch(pageDurgsystem(current, 10, DurgState))
    })(dispatch, getState, args)
  }
}

export function detailDurgsystem(id: number): RootThunk {
  return api(durgsystemDetail(id), (data, dispatch) => {
    dispatch(setDetail(data))
  })
}

export const selectDurgsystemData = (state: RootState) =>
  state.durgsyetem.Durgsystemdata

export const selectTotal = (state: RootState) => state.durgsyetem.Total

export const selectCurrent = (state: RootState) => state.durgsyetem.Current

export const selectDurgState = (state: RootState) => state.durgsyetem.Durgstate

export const selectDetail = (state: RootState) => state.durgsyetem.Detail

export const selectStorage = (state: RootState) => state.durgsyetem.StorageData

export const selectDurgId = (state: RootState) => state.durgsyetem.SelectId

export const selectPageLoading = (state: RootState) =>
  state.durgsyetem.pageLoading

export default durgsystemSlice.reducer;
