/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-24 16:27:51
 * @LastEditors: K
 * @LastEditTime: 2022-09-16 14:32:36
 */
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Col, Modal, ModalProps, Row, Space, Table, TableColumnType, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../../app/store';
import { HistoryCaseAsyncResponse } from '../../../services/treatment';
import { ThemeContext } from '../../../theme/ThemeContext';
import styles from './Diagnosis.module.css'
import { getHistoryCase } from './diagnosisSlice';

interface HistoryCaseDialogProps {
  visible: boolean
  patientId?: number
  patientName?: string
}

export const HistoryCaseDialog = ({
  visible,
  onCancel,
  patientId,
  patientName,
  ...others
}: ModalProps & HistoryCaseDialogProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<HistoryCaseAsyncResponse[]>([])

  useEffect(() => {
    if (patientId && visible) {
      dispatch(getHistoryCase({ patientId })).then(unwrapResult).then((res: Array<HistoryCaseAsyncResponse>) => {
        setData(res)
      })
    }

  }, [visible])

  const columns: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '5rem',
      render: (_1, _2, index) => `${index + 1}`,
    },
    {
      title: '接诊时间',
      dataIndex: 'createTime',
      align: 'center'
    },
    {
      title: '主诉',
      dataIndex: 'chiefComplaint',
      align: 'center'
    },
    {
      title: '诊断',
      dataIndex: 'disease',
      align: 'center'
    },
    {
      title: '险种',
      dataIndex: 'insuranceName',
      width: '8rem',
      align: 'center'
    },
    {
      title: '接诊科室',
      dataIndex: 'treatmentDepartmentName',
      width: '7rem',
      align: 'center'
    },
    {
      title: '接诊医生',
      dataIndex: 'treatmentDoctorName',
      width: '6rem',
      align: 'center'
    },
  ];
  return (
    <Modal
      width={1200}
      visible={visible}
      footer={null}
      {...others}
      centered
      onCancel={onCancel}
    >
      <div>【{patientName}】的历史病历信息</div>
      <div style={{ color: theme.tc3, margin: '10px 0' }}>*仅展示最近一年数据，更多数据清前往患者信息中查看</div>
      <Table pagination={false} scroll={{ y: 400 }} columns={columns} dataSource={data}></Table>
      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Button
            onClick={onCancel}
            type='primary'
            style={{
              float: "right",
            }}
          >
            返回
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

