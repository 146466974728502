import { createAsyncThunk } from "@reduxjs/toolkit"
import { sendAsync } from "../../../app/applicationSlice"
import { RootThunkApi } from "../../../app/store"
import * as S from "../../../services/surgical"

export const surgicalApply = createAsyncThunk<
  any,
  S.surgicalBody,
  RootThunkApi<void>
>("surgical/apply", async (body, api) => {
  return sendAsync(S.surgicalApply(body), api)
})
