/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-26 09:46:17
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-18 16:53:03
 */

import { sum } from 'lodash'
import moment from 'moment'
import { BillingCategoryName } from './billingCategory'
import { DateTimeFormatSimpleOnlyOne } from './datetime'

//营收报表打印单
export function printBody(data: any) {
  const tenantName = data?.tenantName
  const timeHead = data?.timeHead
  const timeTail = data?.timeTail
  const local = data?.local

  const titleList = data?.titleList?.sort((a: any, b: any) =>
    a.payMethodName.localeCompare(b.payMethodName)
  )

  const total = sum(
    data.data?.[0]?.billingCategoryStatisticsSummary?.map((v: any) => {
      return v?.amount
    })
  )
  const ysDatas = data.data?.[0]?.incomeStatisticsSummary?.map((v: any) => {
    return {
      ...v,
      totalPayAmount: v?.totalPayAmount ? v?.totalPayAmount?.toFixed(2) : 0.0,
      totalShouldPayAmount: v?.totalShouldPayAmount
        ? v?.totalShouldPayAmount?.toFixed(2)
        : 0.0,
      totalRealPayAmount: v?.totalRealPayAmount
        ? v?.totalRealPayAmount?.toFixed(2)
        : 0.0,
      totalInsurancePayAmount: v?.totalInsurancePayAmount
        ? v?.totalInsurancePayAmount?.toFixed(2)
        : 0.0,
      aggrPay: v?.aggrPay ? v?.aggrPay?.toFixed(2) : 0.0,
      alipay: v?.alipay ? v?.alipay?.toFixed(2) : 0.0,
      memberPay: v?.memberPay ? v?.memberPay?.toFixed(2) : 0.0,
      wxPay: v?.wxPay ? v?.wxPay?.toFixed(2) : 0.0,
      unionPay: v?.unionPay ? v?.unionPay?.toFixed(2) : 0.0,
      cash: v?.cash ? v?.cash?.toFixed(2) : 0.0,
      otherPay: v?.otherPay ? v?.otherPay?.toFixed(2) : 0.0,
      customizePay: v?.customizePay
        ?.map((k: any) => {
          return { ...k }
        })
        ?.sort((a: any, b: any) =>
          a.payMethodName.localeCompare(b.payMethodName)
        ),
    }
  })

  const kpxmDatas = data.data?.[0]?.billingCategoryStatisticsSummary?.map(
    (v: any) => {
      return {
        ...v,
        count: v.amount ? v.amount?.toFixed(2) : '0.00',
        billingCategoryName: BillingCategoryName(
          v?.billingCategory?.toString()
        ),
      }
    }
  )
  let totalPayAmountTotal = 0
  let totalShouldPayAmountTotal = 0
  let totalRealPayAmountTotal = 0
  let totalInsurancePayAmountTotal = 0
  let aggrPayTotal = 0
  let alipayTotal = 0
  let memberPayTotal = 0
  let wxPayTotal = 0
  let unionPayTotal = 0
  let cashTotal = 0
  let otherPayTotal = 0

  let sjDatas = ''

  for (const keys in ysDatas) {
    const rowData = ysDatas[keys]

    sjDatas +=
      ' <div style="font: 8px \'宋体\';display:flex;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
      '                ' +
      rowData?.insuranceName +
      '            </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.totalPayAmount +
      '            </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.totalShouldPayAmount +
      '            </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.totalRealPayAmount +
      '            </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.totalInsurancePayAmount +
      '            </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.totalJijin +
      '            </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.totalGezhang +
      '            </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.aggrPay +
      '            </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.alipay +
      '            </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.memberPay +
      '            </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.wxPay +
      '            </div>' +
      '         <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '               ' +
      rowData?.unionPay +
      '           </div>' +
      '            <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
      '                ' +
      rowData?.cash +
      '            </div>' +
     (rowData?.customizePay?.length
      ? '             <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
        '                ' +
        rowData?.otherPay +
        '            </div>'
      : '             <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;">' +
        '                ' +
        rowData?.otherPay +
        '            </div>')
    const payMethods = rowData?.customizePay

    if (payMethods.length > 0) {
      for (let i = 0; i < payMethods.length; i++) {
        sjDatas +=
          i + 1 == payMethods?.length
            ? '<div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;">' +
              Number(payMethods?.[i].payAmount)?.toFixed(2) +
              '</div>'
            : '<div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:right;border-right: 1px solid #000">' +
              Number(payMethods?.[i].payAmount)?.toFixed(2) +
              '</div>'
      }
    }

    sjDatas += '    </div>'

    totalPayAmountTotal += rowData.totalPayAmount

    totalShouldPayAmountTotal += rowData.totalShouldPayAmount

    totalRealPayAmountTotal += rowData.totalRealPayAmount

    totalInsurancePayAmountTotal += rowData.totalInsurancePayAmount

    aggrPayTotal += rowData.aggrPay

    alipayTotal += rowData.alipay

    memberPayTotal += rowData.memberPay

    wxPayTotal += rowData.wxPay

    unionPayTotal += rowData.unionPay

    cashTotal += rowData.cash

    otherPayTotal += rowData.otherPay
  }

  const isJs = kpxmDatas?.length % 2 == 0 ? false : true

  let kpxmT = ''

  //渲染收费项目表格

  for (let i = 0; i < kpxmDatas?.length; i++) {
    const kpxm = kpxmDatas[i]
    const profitAmount =
      kpxmDatas[i].billingCategory == '01' ||
      kpxmDatas[i].billingCategory == '02' ||
      kpxmDatas[i].billingCategory == '03' ||
      kpxmDatas[i].billingCategory == '17'
        ? kpxmDatas[i].profitAmount
        : ''
    if (i % 2 == 0) {
      kpxmT +=
        '<div style="font: 8px \'宋体\';display:flex;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
        '        <div style="padding:0px 2px;word-break:break-all;width: 16%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            ' +
        kpxm.billingCategoryName +
        '        </div>' +
        '        <div style="padding:0px 2px;word-break:break-all;width: 17%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            ' +
        kpxm.amount +
        '        </div>' +
        '        <div style="padding:0px 2px;word-break:break-all;width: 17%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            ' +
        profitAmount +
        '        </div>'
    } else {
      kpxmT +=
        '<div style="padding:0px 2px;word-break:break-all;width: 16%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            ' +
        kpxm.billingCategoryName +
        '        </div>' +
        '<div style="padding:0px 2px;word-break:break-all;width: 17%;float: left;text-align:center;border-right: 1px solid #000">' +
        '            ' +
        kpxm.amount +
        '        </div>' +
        '        <div style="padding:0px 2px;word-break:break-all;width: 17%;float: left;text-align:center;">' +
        '            ' +
        profitAmount +
        '        </div>' +
        '    </div>'
    }
  }

  //判断奇数还是偶数

  if (isJs) {
    kpxmT +=
      '<div style="padding:0px 2px;word-break:break-all;width:16%;float: left;text-align:center;border-right: 1px solid #000">' +
      '            ' +
      '        </div>' +
      '        <div style="padding:0px 2px;word-break:break-all;width: 17%;float: left;text-align:center;border-right: 1px solid #000">' +
      '            ' +
      '        </div>' +
      '        <div style="padding:0px 2px;word-break:break-all;width: 17%;float: left;text-align:center;">' +
      '            ' +
      '        </div>' +
      '    </div>'
  }

  const title =
    '          <div style="text-align: center;width:100%;display: inline-flex;">' +
    '           <div style="width:25%;display: inline-block">营</div>' +
    '           <div style="width:25%;display: inline-block">收</div>' +
    '           <div style="width:25%;display: inline-block">报</div>' +
    '           <div style="width:25%;display: inline-block">表</div>' +
    '          </div>'

  let content =
    '<div style="margin-left: 10px;margin-right: 10px">' +
    '    <h4 style="text-align: center;margin-bottom: 0px">' +
    tenantName +
    '</h4>' +
    '    <div style="text-align: center;margin-top: 0px;margin-bottom: 0px;width:100%">' +
    '       <h3 style="display: inline-block;text-align: center;margin-top: 5px;margin-bottom: 5px;width: 20%;border-bottom: 1px solid #000;">' +
    title +
    '        </h3>' +
    '    </div>' +
    '    <div style="font-size:10px;text-align: right">' +
    '        <div style="float: left;margin-left: 10px">日期:   ' +
    timeHead +
    '  至  ' +
    timeTail +
    '</div>' +
    '       <div style="float: right;">单位:元</div>' +
    '    </div>' +
    '    <div style="font-size:8px;display:flex;clear: left;border: 1px solid #000">' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            险种' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            总费用' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            应收总金额' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            实收金额' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            医保报销总金额' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            基金金额' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            个账金额' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            聚合支付' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            支付宝' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            会员卡' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            微信' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            银联' +
    '        </div>' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            现金' +
    '        </div>' +
    (titleList?.length
      ? '       <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center;border-right: 1px solid #000">' +
        '           其他' +
        '       </div>'
      : '       <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 8%;float: left;text-align:center">' +
        '           其他' +
        '       </div>')
  let plusContent: any = ''
  titleList?.map((k: any, index: any) => {
    plusContent +=
      index + 1 == titleList?.length
        ? '        <div style="box-sizing: border-box;width: 8%;padding:0px 2px;word-break:break-all;float: left;text-align:center">' +
          k?.payMethodName +
          '        </div>'
        : '        <div style="box-sizing: border-box;width: 8%;padding:0px 2px;word-break:break-all;float: left;text-align:center;border-right: 1px solid #000">' +
          k?.payMethodName +
          '        </div>'
  })
  content +=
    plusContent +
    '    </div>' +
    sjDatas +
    '    <div style="font-size:8px;display:flex;clear: left;border: 1px solid #000;margin-top: 10px">' +
    '        <div style="word-break:break-all;width: 16%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            收费项目' +
    '        </div>' +
    '        <div style="word-break:break-all;width: 17%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            金额' +
    '        </div>' +
    '        <div style="word-break:break-all;width: 17%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            利润' +
    '        </div>' +
    '        <div style="word-break:break-all;width: 16%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            收费项目' +
    '        </div>' +
    '        <div style="word-break:break-all;width: 17%;float: left;text-align:center;border-right: 1px solid #000">' +
    '            金额' +
    '        </div>' +
    '        <div style="word-break:break-all;width: 17%;float: left;text-align:center;">' +
    '            利润' +
    '        </div>' +
    '    </div>' +
    kpxmT +
    '    <div style="font-size:8px;display:flex;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
    '        <div style="box-sizing: border-box;padding:0px 2px;word-break:break-all;width: 100%;float: left;text-align:center;">' +
    '            合计 ' +
    ':' +
    total?.toFixed(2) +
    '        </div>' +
    '    </div>' +
    '    <div style="font-size:10px;padding-top: 0px">' +
    '        <div style="width: 20%;float: left;">缴款人：</span></div>' +
    '        <div style="width: 20%;float: left;">财务:</div>' +
    '       <div style="width: 20%;float: left;">审核：</span></div>' +
    '       <div style="width: 40%;float: left;text-align: right">制表时间:' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</div>' +
    '    </div>' +
    '</div>'

  return content
}
