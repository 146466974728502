/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-15 15:55:18
 */
import {
  Button,
  Popconfirm,
  Space,
  Switch,
  TableColumnType,
  Tooltip,
} from 'antd'
import moment from 'moment'
import React from 'react'
import { DateTimeFormatSimple } from '../../models/datetime'
import { getAge } from '../../utils/StringUtils'

export const Columns = (
  onClick: (id: number, text: string, t?: any) => void,
  startIndex: number,
  state: string
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '患者姓名',
      align: 'center',
      width: '8rem',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '性别',
      align: 'center',
      dataIndex: 'sex',
      width: '5rem',
      key: 'sex',
      render: (_, t) => {
        return t.sex == 1 ? '男' : t.sex == 2 ? '女' : '未知'
      },
    },

    {
      title: '年龄',
      align: 'center',
      dataIndex: 'age',
      width: '6rem',
      key: 'age',
      render: (_, r) => getAge(r?.age, r?.ageMonth),
    },
    {
      title: '联系电话',
      align: 'center',
      width: '6rem',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '随访人',
      align: 'center',
      width: '6rem',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '随访时间',
      align: 'center',
      width: '6rem',
      dataIndex: 'visitTime',
      key: 'visitTime',
      render: (_, t) => {
        return t?.visitTime
          ? moment(t.visitTime).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '随访类型',
      align: 'center',
      width: '6rem',
      dataIndex: 'flag',
      key: 'flag',
    },
    {
      title: '随访内容',
      width: '10rem',
      align: 'center',
      dataIndex: 'content',
      key: 'content',
      render: function content(_, t) {
        return t?.content ? (
          // <Tooltip
          //   title={t.content}
          // >
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: 'center',
            }}
          >
            {t.content}
          </div>
        ) : (
          // {/* </Tooltip> */ }

          '-'
        )
      },
    },
    {
      title: '创建时间',
      align: 'center',
      dataIndex: 'createTime',
      width: '10rem',
      key: 'createTime',
    },

    {
      title: '操作',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      width: '10rem',
      render: function Element(_, t) {
        return state == '0' ? (
          <Space>
            <Button
              type='primary'
              size='small'
              onClick={() => {
                onClick(t.id, 'edit', t)
              }}
            >
              执行
            </Button>
            <Popconfirm
              title='确认进行此操作？'
              onConfirm={() => {
                onClick(t.id, 'cancel', t)
              }}
              okText='确认'
              cancelText='取消'
            >
              <Button type='primary' size='small'>
                取消
              </Button>
            </Popconfirm>
          </Space>
        ) : (
          <span
            style={{ color: '#027aff', cursor: 'pointer' }}
            onClick={() => {
              onClick(t.id, 'detail', t)
            }}
          >
            查看详情
          </span>
        )
      },
    },
  ]
}
