import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import _ from "lodash";
import {api, sendAsync} from "../../../../app/applicationSlice";
import {RootState, RootThunk, RootThunkApi} from "../../../../app/store";
import * as service from "../../../../services/purchaseControl";
import {
  exportPurchaseControl,
  exportPurchaseControlDetail,
  PurchaseControlParams
} from "../../../../services/purchaseControl";
interface PurchaseControlDetail {
  PurchaseControlDetail: [],
  Total: 0,
  Current: 1,
  pageLoading: false,
}

const initialState: PurchaseControlDetail = {
  PurchaseControlDetail: [],
  Total: 0,

  Current: 1,
  pageLoading: false,
};

const purchaseControlDetailSlice = createSlice({
  name: "purchaseControlDetailSlice",
  initialState,
  reducers: {
    setData(state, action) {
      state.PurchaseControlDetail = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
  },
});

export const { setData, 
  setTotal,
  setCurrent, setPageLoading } = purchaseControlDetailSlice.actions;

export const selectPurchaseControlDetailData = (state: RootState) =>
  state.purchaseControlDetail.PurchaseControlDetail;
  export const selectTotal = (state: RootState) => state.purchaseControlDetail.Total
  
  export const selectCurrent = (state: RootState) => state.purchaseControlDetail.Current

export const selectPageLoading = (state: RootState) => state.purchaseControlDetail.pageLoading
  
  


export function getPurchaseControlDetailList(params: PurchaseControlParams): RootThunk {
  return api(service.getPurchaseControlDetail(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  });
}

export const getExportListExoprt = createAsyncThunk<
    void,
    PurchaseControlParams,
    RootThunkApi
>('purchaseControlDetail/getExportListExport', async (params, api) => {
  return sendAsync(exportPurchaseControlDetail(params), api)
})

export default purchaseControlDetailSlice.reducer;
