import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Space,
  Table,
  notification,
  TableColumnType,
  Checkbox,
} from 'antd'
import moment from 'moment'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import {
  selectInsuranceArray, selectShortName,
  selectTenantName,
  selectUserName,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { DateRangeType, DateSelect, TJFXTime } from '../../../compnents/form/DateSelect'
import { EditableColumnType } from '../../../compnents/list/EditableList'
import { CarryOverDetailEntry } from '../../../models/carryover'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import { DoctorSaleEntry, DoctorSalesResult } from '../../../models/doctorSales'
import { ThemeContext } from '../../../theme/ThemeContext'
import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'
import styles from './DoctorSales.module.css'
import {
  getDoctorSaleEntries,
  selectGetDoctorSaleEntriesParams,
  setGetDoctorSaleEntriesParams,
  getDoctorAuditExoprt,
  doctorSaleBulletinAsync,
  getDoctorSaleEntriesArea,
} from './doctorSaleSlice'
import {printBody} from "./doctorSalesPrint";

interface DoctorSalesProps {
  doctorId?: string
  showPanle?: boolean
}

export const DoctorSales = ({
  doctorId,
  showPanle = false,
}: DoctorSalesProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const name = useSelector(selectTenantName)

  const shortName = useSelector(selectShortName)

  const doctorName = useSelector(selectUserName)

  const params = useSelector(selectGetDoctorSaleEntriesParams)

  const insuranceArray = useSelector(selectInsuranceArray)

  const tableRef = useRef(null)

  const printRef = useRef<PrintFrameRef>(null)

  const [result, setResult] = useState({
    headers: [],
    entries: [],
  } as DoctorSalesResult)

   const [resultArea, setResultArea] = useState({
     headers: [],
     entries: [],
   } as DoctorSalesResult)

  

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [bulletinData, setBulletinData] = useState<any>({}) // 看板数据

  const [isOpenArea, setIsOpenArea] = useState<any>(false)
  const [showYidibz, setShowYidibz] = useState<any>(false)

  const getPageList = () => {
    dispatch(
      getDoctorSaleEntries({
        ...params,
        timeHead:
          params?._dateRange == 6
            ? params?.timeHead
              ? params?.timeHead
              : moment().startOf('day').format(DateTimeFormat)
            : params?.timeHead,
        timeTail:
          params?._dateRange == 6
            ? params?.timeTail
              ? params?.timeTail
              : moment().endOf('day').format(DateTimeFormat)
            : params?.timeTail,
        doctorId,
      })
    )
      .then(unwrapResult)
      .then((result) => setResult(result))
      .finally(() => setPageLoading(false))
  }

  const getPageListArea = () => {
    dispatch(
      getDoctorSaleEntriesArea({
        ...params,
        timeHead:
          params?._dateRange == 6
            ? params?.timeHead
              ? params?.timeHead
              : moment().startOf('day').format(DateTimeFormat)
            : params?.timeHead,
        timeTail:
          params?._dateRange == 6
            ? params?.timeTail
              ? params?.timeTail
              : moment().endOf('day').format(DateTimeFormat)
            : params?.timeTail,
        doctorId,
      })
    )
      .then(unwrapResult)
      .then((result) => setResultArea(result))
      .finally(() => setPageLoading(false))
  }
  useEffect(() => {
    try {
      setPageLoading(true)
      if (isOpenArea) {
        getPageListArea()
      } else {
        getPageList()
      }
    } catch {
      // do nothing.
    }
    showPanle &&
      dispatch(
        doctorSaleBulletinAsync({
          ...params,
          timeHead:
            params?._dateRange == 6
              ? params?.timeHead
                ? params?.timeHead
                : moment().startOf('day').format(DateTimeFormat)
              : params?.timeHead,
          timeTail:
            params?._dateRange == 6
              ? params?.timeTail
                ? params?.timeTail
                : moment().endOf('day').format(DateTimeFormat)
              : params?.timeTail,
          doctorId,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setBulletinData(res)
        })
  }, [params, isOpenArea])

  useEffect(() => {
    setIsOpenArea(false)
    form.setFieldsValue(params)
  }, [])

  const columnsOpenForPrint: EditableColumnType<DoctorSaleEntry>[] = [
    {
      title: (
        <div
          style={{
            color: theme.tc2,
            height: '100%',
            width: '255px',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: 'calc(100% - 9rem)',
              height: '100%',
              position: 'absolute',
              background:
                'linear-gradient( to top right,' +
                'rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px),' +
                `${theme.wbd} 50%, rgba(0,0,0,0) calc(50% + 0.8px),` +
                'rgba(1,0,0,0) 100%)',
            }}
          ></div>
          <div
            style={{
              width: 'calc(100% - 2rem)',
              height: '100%',
              position: 'absolute',
              background:
                'linear-gradient( to top right,' +
                'rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px),' +
                `${theme.wbd} 50%, rgba(0,0,0,0) calc(50% + 0.8px),` +
                'rgba(1,0,0,0) 100%)',
            }}
          ></div>
          <div
            style={{
              height: '1rem',
              lineHeight: '1rem',
              position: 'absolute',
              bottom: 26,
              left: 80,
              transform: 'rotate(46deg)',
            }}
          >
            科室医生
          </div>
          <div
            style={{
              height: '1rem',
              lineHeight: '1rem',
              position: 'absolute',
              bottom: 36,
              right: 48,
              transform: 'rotate(33deg)',
            }}
          >
            汇总
          </div>
          <div
            style={{
              height: '1rem',
              lineHeight: '1rem',
              position: 'absolute',
              top: 6,
              right: 6,
            }}
          >
            项目
          </div>
        </div>
      ),
      key: 'treatmentDepartmentName',
      colSpan: 0,
      width: '4.5rem',
      align: 'center',
      render: (_, e, i) => {
        let span = 0
        if (
          i === 0 ||
          result.entries[i - 1].treatmentDepartmentId !==
          e.treatmentDepartmentId
        ) {
          for (let j = i; j < result.entries.length; ++j) {
            if (
              result.entries[j].treatmentDepartmentId ===
              e.treatmentDepartmentId
            ) {
              ++span
            } else {
              break
            }
          }
        }
        return {
          props: {
            colSpan: 1,
            rowSpan: span,
            style: {
              whiteSpace: 'nowrap',
            },
          },
          children: e.treatmentDepartmentName || '未指定科室',
        }
      },
      fixed: 'left',
    },
    {
      title: '',
      colSpan: 0,
      key: 'treatmentDoctorName',
      width: '50rem',
      align: 'center',
      render: (_, e) => ({
        props: {
          colSpan: 1,
          style: {
            whiteSpace: 'nowrap',
            color: theme.tc1,
            fontWeight: 'bold',
            padding: '0',
          },
        },
        children:
          e.treatmentDoctorName ||
          (e.treatmentDepartmentName === '机构总计' ? '' : '未指定医生'),
      }),
      fixed: 'left',
    },
    {
      title: '初诊人数',
      align: 'center',
      dataIndex: 'cz',
      render: (_, r) => r.cz,
    },
    {
      title: '复诊人数',
      dataIndex: 'fz',
      align: 'center',
    },
    {
      title: '总金额',
      dataIndex: 'allTotalAmount',
      align: 'center',
      render: (_, r) => r.allTotalAmount?.toFixed(2),
    }
     ,

    {
      title: '西药费',
      key: 'dr01',
      align: 'right',
      render: (_, r) => r.dr01?.toFixed(2),
    },
    {
      title: '中成药',
      key: 'dr02',
      align: 'right',
      render: (_, r) => r.dr02?.toFixed(2),
    },
    {
      title: '中草药',
      key: 'dr03',
      align: 'right',
      render: (_, r) => r.dr03?.toFixed(2),
    },

    {
      title: '医保中草药',
      key: 'dr101',
      align: 'right',
      render: (_, r) => r.dr101?.toFixed(2),
    },
    {
      title: '其他药品',
      key: 'drOther',
      align: 'right',
      render: (_, r) => r.drOther?.toFixed(2),
    },
    {
      title: '挂号费',
      key: 'tr30',
      align: 'right',
      render: (_, r) => r.tr30?.toFixed(2),
    },
    {
      title: '代煎费',
      key: 'tr97',
      align: 'right',
      render: (_, r) => r.tr97?.toFixed(2),
    },

    {
      title: '诊察费',
      key: 'tr04',
      align: 'right',
      render: (_, r) => r.tr04?.toFixed(2),
    },
    {
      title: '其他诊疗',
      key: 'OtherTreatment',
      align: 'right',
      render: (_, r) => r.tr06?.toFixed(2),
    },

    {
      title: '中药帖数',
      key: 'drTip',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.drTip,
    },
    {
      title: '医保帖数',
      key: 'drTipInsurance',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.drTipInsurance,
    },
    {
      title: '中药帖均',
      key: 'amountPerTip',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.amountPerTip?.toFixed(2),
    },
    {
      title: '医保帖均',
      key: 'amountPerTipInsurance',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.amountPerTipInsurance?.toFixed(2),
    },
  ]

  const columnsOpen: EditableColumnType<DoctorSaleEntry>[] = [
    {
      title: (
        <div
          style={{
            color: theme.tc2,
            height: '100%',
            width: '255px',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: 'calc(100% - 9rem)',
              height: '100%',
              position: 'absolute',
              background:
                'linear-gradient( to top right,' +
                'rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px),' +
                `${theme.wbd} 50%, rgba(0,0,0,0) calc(50% + 0.8px),` +
                'rgba(1,0,0,0) 100%)',
            }}
          ></div>
          <div
            style={{
              width: 'calc(100% - 2rem)',
              height: '100%',
              position: 'absolute',
              background:
                'linear-gradient( to top right,' +
                'rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px),' +
                `${theme.wbd} 50%, rgba(0,0,0,0) calc(50% + 0.8px),` +
                'rgba(1,0,0,0) 100%)',
            }}
          ></div>
          <div
            style={{
              width: '100%',
              height: '4rem',
              position: 'absolute',
              background:
                'linear-gradient( to top right,' +
                'rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px),' +
                `${theme.wbd} 50%, rgba(0,0,0,0) calc(50% + 0.8px),` +
                'rgba(1,0,0,0) 100%)',
            }}
          ></div>
          <div
            style={{
              height: '1rem',
              lineHeight: '1rem',
              position: 'absolute',
              bottom: 26,
              left: 80,
              transform: 'rotate(46deg)',
            }}
          >
            科室医生
          </div>
          <div
            style={{
              height: '1rem',
              lineHeight: '1rem',
              position: 'absolute',
              bottom: 36,
              right: 48,
              transform: 'rotate(33deg)',
            }}
          >
            汇总
          </div>
          <div
            style={{
              height: '1rem',
              lineHeight: '1rem',
              position: 'absolute',
              top: 6,
              right: 6,
            }}
          >
            项目
          </div>
          <div
            style={{
              height: '1rem',
              lineHeight: '1rem',
              position: 'absolute',
              bottom: 6,
              left: 6,
            }}
          >
            地区
          </div>
        </div>
      ),
      key: 'areaName',
      colSpan: 3,
      width: '4.5rem',
      align: 'center',
      render: (_, e, i) => {
        let span = 0
        if (i === 0 || resultArea.entries[i - 1].areaName !== e.areaName) {
          for (let j = i; j < resultArea.entries.length; ++j) {
            if (resultArea.entries[j].areaName === e.areaName) {
              ++span
            } else {
              break
            }
          }
        }
        return {
          props: {
            colSpan: 1,
            rowSpan: span,
            style: {
              whiteSpace: 'nowrap',
            },
          },
          children: e.areaName || '',
        }
      },
      fixed: 'left',
    },
    {
      key: 'treatmentDepartmentName',
      colSpan: 0,
      width: '4.5rem',
      align: 'center',
      render: (_, e, i) => {
        let span = 0
        if (
          i === 0 ||
          resultArea.entries[i - 1].treatmentAreaDepartmentId !==
            e.treatmentAreaDepartmentId
        ) {
          for (let j = i; j < resultArea.entries.length; ++j) {
            if (
              resultArea.entries[j].treatmentAreaDepartmentId ===
              e.treatmentAreaDepartmentId
            ) {
              ++span
            } else {
              break
            }
          }
        }
        return {
          props: {
            colSpan: 1,
            rowSpan: span,
            style: {
              whiteSpace: 'nowrap',
            },
          },
          children: e.treatmentDepartmentName || '未指定科室',
        }
      },
      fixed: 'left',
    },
    {
      title: '',
      colSpan: 0,
      key: 'treatmentDoctorName',
      width: '50rem',
      align: 'center',
      render: (_, e) => ({
        props: {
          colSpan: 1,
          style: {
            whiteSpace: 'nowrap',
            color: theme.tc1,
            fontWeight: 'bold',
            padding: '0',
          },
        },
        children:
          e.treatmentDoctorName ||
          (e.treatmentDepartmentName === '机构总计' ? '' : '未指定医生'),
      }),
      fixed: 'left',
    },
    {
      title: '初诊人数',
      align: 'center',
      dataIndex: 'cz',
      render: (_, r) => r.cz,
    },
    {
      title: '复诊人数',
      dataIndex: 'fz',
      align: 'center',
    },
    {
      title: '金额合计',
      children: [
        {
          title: '总金额',
          dataIndex: 'allTotalAmount',
          align: 'center',
          render: (_, r) => r.allTotalAmount?.toFixed(2),
        },
        {
          title: '应收金额',
          dataIndex: 'total',
          align: 'center',
          render: (_, r) => r.total?.toFixed(2),
        },
        {
          title: '实收金额',
          dataIndex: 'realTotal',
          align: 'center',
          render: (_, r) => r.realTotal?.toFixed(2),
        },
      ] as TableColumnType<CarryOverDetailEntry>[],
    },

    {
      title: '西药费',
      key: 'dr01',
      align: 'right',
      render: (_, r) => r.dr01?.toFixed(2),
    },
    {
      title: '中成药',
      key: 'dr02',
      align: 'right',
      render: (_, r) => r.dr02?.toFixed(2),
    },
    {
      title: '中草药',
      key: 'dr03',
      align: 'right',
      render: (_, r) => r.dr03?.toFixed(2),
    },

    {
      title: '医保中草药',
      key: 'dr101',
      align: 'right',
      render: (_, r) => r.dr101?.toFixed(2),
    },
    {
      title: '其他药品',
      key: 'drOther',
      align: 'right',
      render: (_, r) => r.drOther?.toFixed(2),
    },
    {
      title: '挂号费',
      key: 'tr30',
      align: 'right',
      render: (_, r) => r.tr30?.toFixed(2),
    },
    {
      title: '代煎费',
      key: 'tr97',
      align: 'right',
      render: (_, r) => r.tr97?.toFixed(2),
    },

    {
      title: '诊察费',
      key: 'tr04',
      align: 'right',
      render: (_, r) => r.tr04?.toFixed(2),
    },
    {
      title: '检查费',
      key: 'tr06',
      align: 'right',
      render: (_, r) => r.tr06?.toFixed(2),
    },

    {
      title: '治疗费',
      key: 'tr05',
      align: 'right',
      render: (_, r) => r.tr05?.toFixed(2),
    },
    {
      title: '化验费',
      key: 'tr07',
      align: 'right',
      render: (_, r) => r.tr07?.toFixed(2),
    },

    {
      title: '手术费',
      key: 'tr08',
      align: 'right',
      render: (_, r) => r.tr08?.toFixed(2),
    },
    {
      title: '放射费',
      key: 'tr09',
      align: 'right',
      render: (_, r) => r.tr09?.toFixed(2),
    },
    {
      title: '输血费',
      key: 'tr10',
      align: 'right',
      render: (_, r) => r.tr10?.toFixed(2),
    },
    {
      title: '输氧费',
      key: 'tr11',
      align: 'right',
      render: (_, r) => r.tr11?.toFixed(2),
    },
    {
      title: 'B超费',
      key: 'tr23',
      align: 'right',
      render: (_, r) => r.tr23?.toFixed(2),
    },
    {
      title: '心电图费',
      key: 'tr24',
      align: 'right',
      render: (_, r) => r.tr24?.toFixed(2),
    },
    {
      title: '透析费',
      key: 'tr26',
      align: 'right',
      render: (_, r) => r.tr26?.toFixed(2),
    },
    {
      title: '化疗费',
      key: 'tr27',
      align: 'right',
      render: (_, r) => r.tr27?.toFixed(2),
    },
    {
      title: '麻醉费',
      key: 'tr28',
      align: 'right',
      render: (_, r) => r.tr28?.toFixed(2),
    },

    {
      title: '注射费',
      key: 'tr25',
      align: 'right',
      render: (_, r) => r.tr25?.toFixed(2),
    },
    {
      title: '其他费',
      key: 'trOther',
      align: 'right',
      render: (_, r) => r.trOther?.toFixed(2),
    },
    {
      title: '中药帖数',
      key: 'drTip',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.drTip,
    },
    {
      title: '医保帖数',
      key: 'drTipInsurance',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.drTipInsurance,
    },
    {
      title: '中药帖均',
      key: 'amountPerTip',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.amountPerTip?.toFixed(2),
    },
    {
      title: '医保帖均',
      key: 'amountPerTipInsurance',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.amountPerTipInsurance?.toFixed(2),
    },
  ]

  const columns: EditableColumnType<DoctorSaleEntry>[] = [
    {
      title: (
        <div
          style={{
            color: theme.tc2,
            height: '100%',
            width: '150px',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: 'calc(100% - 3rem)',
              height: '100%',
              position: 'absolute',
              background:
                'linear-gradient( to top right,' +
                'rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px),' +
                `${theme.wbd} 50%, rgba(0,0,0,0) calc(50% + 0.8px),` +
                'rgba(1,0,0,0) 100%)',
            }}
          ></div>
          <div
            style={{
              width: '100%',
              height: '2.25rem',
              position: 'absolute',
              background:
                'linear-gradient( to top right,' +
                'rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px),' +
                `${theme.wbd} 50%, rgba(0,0,0,0) calc(50% + 0.8px),` +
                'rgba(1,0,0,0) 100%)',
            }}
          ></div>
          <div
            style={{
              height: '1rem',
              lineHeight: '1rem',
              position: 'absolute',
              bottom: 2,
            }}
          >
            科室医生
          </div>
          <div
            style={{
              height: '1rem',
              lineHeight: '1rem',
              position: 'absolute',
              right: 2,
              bottom: 2,
            }}
          >
            汇总
          </div>
          <div
            style={{
              height: '1rem',
              lineHeight: '1rem',
              position: 'absolute',
              right: 2,
              top: 2,
            }}
          >
            项目
          </div>
        </div>
      ),
      key: 'treatmentDepartmentName',
      colSpan: 2,
      width: '4.5rem',
      align: 'center',
      render: (_, e, i) => {
        let span = 0
        if (
          i === 0 ||
          result.entries[i - 1].treatmentDepartmentId !==
            e.treatmentDepartmentId
        ) {
          for (let j = i; j < result.entries.length; ++j) {
            if (
              result.entries[j].treatmentDepartmentId ===
              e.treatmentDepartmentId
            ) {
              ++span
            } else {
              break
            }
          }
        }
        return {
          props: {
            colSpan: 1,
            rowSpan: span,
            style: {
              whiteSpace: 'nowrap',
            },
          },
          children: e.treatmentDepartmentName || '未指定科室',
        }
      },
      fixed: 'left',
    },
    {
      title: '',
      colSpan: 0,
      key: 'treatmentDoctorName',
      width: '50rem',
      align: 'center',
      render: (_, e) => ({
        props: {
          colSpan: 1,
          style: {
            whiteSpace: 'nowrap',
            color: theme.tc1,
            fontWeight: 'bold',
            padding: '0',
          },
        },
        children:
          e.treatmentDoctorName ||
          (e.treatmentDepartmentName === '机构总计' ? '' : '未指定医生'),
      }),
      fixed: 'left',
    },
    {
      title: '初诊人数',
      align: 'center',
      dataIndex: 'cz',
      render: (_, r) => r.cz,
    },
    {
      title: '复诊人数',
      dataIndex: 'fz',
      align: 'center',
    },
    {
      title: '金额合计',
      children: [
        {
          title: '总金额',
          dataIndex: 'allTotalAmount',
          align: 'center',
          render: (_, r) => r.allTotalAmount?.toFixed(2),
        },
        {
          title: '应收金额',
          dataIndex: 'total',
          align: 'center',
          render: (_, r) => r.total?.toFixed(2),
        },
        {
          title: '实收金额',
          dataIndex: 'realTotal',
          align: 'center',
          render: (_, r) => r.realTotal?.toFixed(2),
        },
      ] as TableColumnType<CarryOverDetailEntry>[],
    },

    {
      title: '西药费',
      key: 'dr01',
      align: 'right',
      render: (_, r) => r.dr01?.toFixed(2),
    },
    {
      title: '中成药',
      key: 'dr02',
      align: 'right',
      render: (_, r) => r.dr02?.toFixed(2),
    },
    {
      title: '中草药',
      key: 'dr03',
      align: 'right',
      render: (_, r) => r.dr03?.toFixed(2),
    },

    {
      title: '医保中草药',
      key: 'dr101',
      align: 'right',
      render: (_, r) => r.dr101?.toFixed(2),
    },
    {
      title: '其他药品',
      key: 'drOther',
      align: 'right',
      render: (_, r) => r.drOther?.toFixed(2),
    },
    {
      title: '挂号费',
      key: 'tr30',
      align: 'right',
      render: (_, r) => r.tr30?.toFixed(2),
    },
    {
      title: '代煎费',
      key: 'tr97',
      align: 'right',
      render: (_, r) => r.tr97?.toFixed(2),
    },

    {
      title: '诊察费',
      key: 'tr04',
      align: 'right',
      render: (_, r) => r.tr04?.toFixed(2),
    },
    {
      title: '检查费',
      key: 'tr06',
      align: 'right',
      render: (_, r) => r.tr06?.toFixed(2),
    },

    {
      title: '治疗费',
      key: 'tr05',
      align: 'right',
      render: (_, r) => r.tr05?.toFixed(2),
    },
    {
      title: '化验费',
      key: 'tr07',
      align: 'right',
      render: (_, r) => r.tr07?.toFixed(2),
    },

    {
      title: '手术费',
      key: 'tr08',
      align: 'right',
      render: (_, r) => r.tr08?.toFixed(2),
    },
    {
      title: '放射费',
      key: 'tr09',
      align: 'right',
      render: (_, r) => r.tr09?.toFixed(2),
    },
    {
      title: '输血费',
      key: 'tr10',
      align: 'right',
      render: (_, r) => r.tr10?.toFixed(2),
    },
    {
      title: '输氧费',
      key: 'tr11',
      align: 'right',
      render: (_, r) => r.tr11?.toFixed(2),
    },
    {
      title: 'B超费',
      key: 'tr23',
      align: 'right',
      render: (_, r) => r.tr23?.toFixed(2),
    },
    {
      title: '心电图费',
      key: 'tr24',
      align: 'right',
      render: (_, r) => r.tr24?.toFixed(2),
    },
    {
      title: '透析费',
      key: 'tr26',
      align: 'right',
      render: (_, r) => r.tr26?.toFixed(2),
    },
    {
      title: '化疗费',
      key: 'tr27',
      align: 'right',
      render: (_, r) => r.tr27?.toFixed(2),
    },
    {
      title: '麻醉费',
      key: 'tr28',
      align: 'right',
      render: (_, r) => r.tr28?.toFixed(2),
    },

    {
      title: '注射费',
      key: 'tr25',
      align: 'right',
      render: (_, r) => r.tr25?.toFixed(2),
    },
    {
      title: '其他费',
      key: 'trOther',
      align: 'right',
      render: (_, r) => r.trOther?.toFixed(2),
    },
    {
      title: '中药帖数',
      key: 'drTip',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.drTip,
    },
    {
      title: '医保帖数',
      key: 'drTipInsurance',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.drTipInsurance,
    },
    {
      title: '中药帖均',
      key: 'amountPerTip',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.amountPerTip?.toFixed(2),
    },
    {
      title: '医保帖均',
      key: 'amountPerTipInsurance',
      align: 'right',
      fixed: 'right',
      render: (_, r) => r.amountPerTipInsurance?.toFixed(2),
    },
  ]

  const outClued = () => {
    dispatch(
      getDoctorAuditExoprt({ ...params, isShowArea: Number(isOpenArea) })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '销售统计表.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const colors: string[] = [
    '#378AE4',
    '#00AFDB',
    '#00C8A0',
    '#1BD179',
    '#74D236',
  ]

  const titles: any[] = [
    {
      name: '总号数',
      value: 'registrationSum',
    },
    {
      name: '处方数',
      value: 'recipeSum',
    },
    {
      name: '现金金额',
      value: 'amountSum',
    },
    {
      name: '首诊量',
      value: 'czSum',
    },
    { name: '复诊量', value: 'fzSum' },
  ]

  return (
    <Col
      style={{
        height: 'calc(100% - 10px)',
        padding: '0 15px 10px',
        borderRadius: theme.br,
        backgroundColor: theme.pn,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
      }}
    >
      <div style={{ marginBottom: 10 }}>
        <Form
          form={form}
          onFinish={(values) => {
            dispatch(setGetDoctorSaleEntriesParams({ ...params, ...values }))
          }}
        >
          <Row
            style={{
              padding: '15px 0',
            }}
            align='middle'
            justify='space-between'
          >
            <Space>
              <DateSelect
                disabledValue={pageLoading}
                style={{ width: '8.5rem' }}
                labelPrefix=''
                allowClear={false}
                namePrefix='time'
                options={TJFXTime}
                placeholder='收费列表-时间'
                initialValue={DateRangeType.Today}
                // onChange={form.submit}
                defaultTimeFlag={true}
              />
              {insuranceArray && insuranceArray.length !== 0 && (
                <Form.Item name='insuranceCode' noStyle>
                  <Select
                    disabled={pageLoading}
                    placeholder='险种类型'
                    style={{ width: '8.25rem' }}
                    allowClear
                    // suffixIcon={<CaretDownFilled />}
                    onChange={(v)=> {
                      if(v && v !== 'ZIFEI'){
                        setShowYidibz(true);
                      }else{
                        setShowYidibz(false);
                      }
                      form.submit();
                    }}
                  >
                    <Select.Option value={''}>全部</Select.Option>
                    <Select.Option value={'ZIFEI'}>自费</Select.Option>
                    {insuranceArray.map((v) => (
                      <Select.Option value={v.insuranceCode} key={v.id}>
                        {v.insuranceName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {showYidibz &&
                  <Form.Item name='yidibz' noStyle>
                      <Select
                          disabled={pageLoading}
                          placeholder='医保地区'
                          style={{ width: '8.25rem' }}
                          allowClear
                        // suffixIcon={<CaretDownFilled />}
                          onChange={form.submit}
                      >
                          <Select.Option value={'-1'}>全部</Select.Option>
                          <Select.Option value={'0'}>本地</Select.Option>
                          <Select.Option value={'1'}>省内异地</Select.Option>
                          <Select.Option value={'2'}>省外异地</Select.Option>
                          <Select.Option value={'3'}>省医保</Select.Option>
                      </Select>
                  </Form.Item>
              }

              <Form.Item name='orderSource' noStyle>
                <Select
                 disabled={pageLoading}
                  placeholder='收费标志'
                  style={{ width: '8.25rem' }}
                  allowClear
                  // suffixIcon={<CaretDownFilled />}
                  onChange={form.submit}
                >
                  <Select.Option value={''}>全部</Select.Option>
                  <Select.Option value={'1'}>门诊</Select.Option>
                  <Select.Option value={'2'}>零售</Select.Option>
                </Select>
              </Form.Item>
              <Checkbox
                disabled={pageLoading}
                checked={isOpenArea}
                onChange={(e) => {
                  setPageLoading(true)
                  setIsOpenArea(e.target.checked)
                }}
              >
                是否展示地区
              </Checkbox>
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>

            <Space>
              <Button type='primary' onClick={outClued}>
                导出
              </Button>

              <Button type='primary' onClick={() => {
                // console.log("printLog:",printRef.current)
                // printRef.current?.print()
                const head = {
                  title:doctorId ? doctorName : shortName,
                  timeHead:
                    params?._dateRange == 6
                      ? params?.timeHead
                        ? params?.timeHead
                        : moment().startOf('day').format(DateTimeFormat)
                      : params?.timeHead,
                  timeTail:
                    params?._dateRange == 6
                      ? params?.timeTail
                        ? params?.timeTail
                        : moment().endOf('day').format(DateTimeFormat)
                      : params?.timeTail
                }
                const content = printBody(head,result.entries)
                const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                page.document.write(content) // 写入打印页面的内容
                page.print() // 打印
                page?.close()
              }}>
                打印
              </Button>
            </Space>
          </Row>
        </Form>
        {showPanle && (
          <Row gutter={60} style={{ padding: '40px 0', margin: 0 }}>
            {titles.map((v, i) => (
              <Col span={4} key={v}>
                <div
                  className={styles.palneItem}
                  style={{
                    backgroundColor: colors[i],
                  }}
                >
                  {v.name}
                  <span>{bulletinData[v.value] || 0}</span>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </div>
      <div
        style={{
          textAlign: 'center',
          color: theme.tc1,
          fontWeight: 'bold',
          fontSize: '1.5rem',
          marginBottom: 10,
        }}
      >
        {doctorId ? doctorName : shortName}-销售统计表
      </div>
      <div ref={tableRef}>
        {isOpenArea ? (
          <Table<DoctorSaleEntry>
            className={styles.table}
            loading={pageLoading}
            scroll={{ x: true }}
            rowKey={(_, i) => `${i}`}
            pagination={false}
            bordered
            dataSource={resultArea.entries}
            style={{ flex: 1, overflow: 'auto' }}
            rowClassName={(r) =>
              r.treatmentDepartmentName === '机构总计'
                ? styles.total
                : r.treatmentDoctorName === '地区总计'
                ? styles.highlight
                : ''
            }
            columns={columnsOpen.filter(
              (c) =>
                resultArea.headers.length == 0 ||
                !c.dataIndex ||
                resultArea.headers.includes(c.dataIndex as string)
            )}
          />
        ) : (
          <Table<DoctorSaleEntry>
            className={styles.table}
            loading={pageLoading}
            scroll={{ x: true }}
            rowKey={(_, i) => `${i}`}
            pagination={false}
            bordered
            dataSource={result.entries}
            style={{ flex: 1, overflow: 'auto' }}
            rowClassName={(r) =>
              r.treatmentDepartmentName === '机构总计'
                ? styles.total
                : r.treatmentDoctorName === '科室总计'
                ? styles.highlight
                : ''
            }
            columns={columns.filter(
              (c) =>
                result.headers.length == 0 ||
                !c.dataIndex ||
                result.headers.includes(c.dataIndex as string)
            )}
          />
        )}
      </div>
      <PrintFrame
        ref={printRef}
        title={doctorId ? doctorName : shortName + '销售统计表'}
        subTitle=''
      >
        <Row style={{ marginBottom: '10px', lineHeight: '32px' }}></Row>
          <Table<DoctorSaleEntry>
            className={styles.table}
            loading={pageLoading}
            scroll={{ x: true }}
            rowKey={(_, i) => `${i}`}
            pagination={false}
            bordered
            dataSource={result.entries}
            style={{ flex: 1, overflow: 'auto' }}
            rowClassName={(r) =>
              r.treatmentDepartmentName === '机构总计'
                ? styles.total
                : r.treatmentDoctorName === '科室总计'
                ? styles.highlight
                : ''
            }
            columns={columnsOpenForPrint.filter(
              (c) =>
                result.headers.length == 0 ||
                !c.dataIndex ||
                result.headers.includes(c.dataIndex as string)
            )}
          />
      </PrintFrame>
    </Col>
  )
}
