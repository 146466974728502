/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-29 19:45:19
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-05 16:18:58
 */
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Modal, notification } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch } from "../../app/store";
import {
  EditableList,
  EditableListRef,
} from "../../compnents/containers/EditableList";
import { QingdouParams } from "../../services/qingdou";
import { ModalColumnsOne, ModalColumnsTwo } from "./columns";
import { ModalQuery } from "./Modalquery";
import styles from "./Qingdou.module.css";
import {
  changeRow,
  getQingdou,
  saveQingdouAsync,
  selectQingdouCurrent,
  selectQingdouData,
  selectQingdouFlag,
  selectQingdouTotal,
  setQingdouCurrent,
} from "./QingdouSlice";

export const QingDouModule = (props: {
  detail: any;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const ref = useRef<EditableListRef>(null);

  const total = useSelector(selectQingdouTotal);

  const flag = useSelector(selectQingdouFlag);

  const current = useSelector(selectQingdouCurrent);

  const data = useSelector(selectQingdouData);

  const [queries, setQueries] = useState<QingdouParams>({
    count: 20,
  });

  const [size, setSize] = useState(10);

  const [rowkey, setRowkey] = useState<any[]>([]);

  const [ids, setIds] = useState<any>([]);

  const handleChange = (prev: any, next: any) => {
    const newdata = {
      ...prev,
      totalPrice: 0,
      productInfo: `${prev.name} /${prev.tenantSupplierName} /${prev.spec} /${prev.doseCategory}`,
    };
    setRowkey(newdata)

    
    if (rowkey.length) {
      setRowkey(
        Array.from(rowkey).map((v: any) => {
          v?.tenantInventoryLedgerId
            ? v?.tenantInventoryLedgerId === newdata?.tenantInventoryLedgerId
              ? {
                  ...newdata,
                  tenantInventoryReceiptsId: newdata?.tenantInventoryLedgerId,
                  targetInventoryLedgerId: next?.targetInventoryLedgerId,
                }
              : v
            : v;
        })
      );
    }
    dispatch(
      changeRow(
        data.map((v: any) =>
          v?.tenantInventoryLedgerId === newdata?.tenantInventoryLedgerId
            ? newdata
            : { ...v }
        )
      )
    );
  };

  useEffect(() => {
    setIds([]);
    if (props.visible) {
      dispatch(
        getQingdou({
          storehouseId: props.detail.storehouseId,
          count: 20,
          current,
          size,
          flag,
          tenantInventoryReceiptsId: props.detail.id,
          ...queries,
        })
      );
    }
  }, [props.visible, queries, size, current]);

  return (
    <Modal
      visible={props.visible}
      title={flag === 1 ? "添加中药清斗" : "添加中药装斗"}
      okText="确认"
      cancelText="取消"
      className={styles.Modal}
      bodyStyle={{ height: "670px" }}
      onOk={() => {
        props.onOk();
        setQueries({ count: 20 });
      }}
      onCancel={() => {
        props.onCancel();
        setQueries({ count: 20 });
      }}
      footer={null}
    >
      <EditableList
        rowKey={(r) => {
          return (r as any).tenantInventoryLedgerId + size * current;
        }}
        style={{ flex: 1 }}
        hideActionBar
        ref={ref}
        query={
          <ModalQuery
            value={queries}
            onValueChange={(v) => {
              setQueries(v);
            }}
          />
        }
        pagination={{
          items: data,
          currentPage: current,
          pageSize: size,
          totalPages: total,
        }}
        onChangePage={(current, pageSize) => {
          setSize(pageSize as number);
          dispatch(setQingdouCurrent(current));
        }}
        columns={
          flag === 1
            ? ModalColumnsOne()
            : ModalColumnsTwo((text, t) => {
                handleChange(t.prev, t.next);
              })
        }
        rowSelection={
          {      
            selectedRowKeys: ids,
            onChange: (selectedRowKeys: React.Key[], row:any) => {
              setIds(selectedRowKeys);
              setRowkey(row);
            },     
          } as TableRowSelection<any>
        }
        modal={QingDouModule}
        bordered
      />
      <Button
        type="primary"
        style={{ float: "right", marginTop: "-70px", marginRight: "50px" }}
        onClick={() => {
          if (flag === 1) {
            dispatch(
              saveQingdouAsync(
                rowkey.map((v: any) => {
                  return {
                    ...v,
                    productInfo: `${v?.name} /${v?.tenantSupplierName} /${v?.spec} /${v?.doseCategory}`,
                    tenantInventoryReceiptsId: props.detail?.id,
                    totalPrice: 0,
                    targetInventoryLedgerId: "0",
                  };
                })
              )
            ).then(() => {
              // props.Refresh();
              props.onOk();
              setQueries({ count: 20 });
            });
          } else {
            dispatch(
              saveQingdouAsync(
                rowkey.map((v) => {
                  return {
                    ...v,
                    productInfo: `${v?.name} /${v?.tenantSupplierName} /${v?.spec} /${v?.doseCategory}`,
                    tenantInventoryReceiptsId: props.detail?.id,
                    targetInventoryLedgerId:
                      v?.outInvCleanListTargetList[0]?.targetInventoryLedgerId,
                    totalPrice: 0,
                  };
                })
              )
            )
              .then(unwrapResult)
              .then(() => {
                // props.Refresh();
                props.onOk();
                setQueries({ count: 20 });
              })
              .catch((res) => {
                notification.error({
                  message: "请选择装斗或清斗药品",
                  duration: 3,
                });
              });
          }
        }}
      >
        添加选中行
      </Button>
    </Modal>
  );
};
