/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-02-22 17:02:55
 */
import { baseDataType, serverType } from "./commodity";
import { API_PREFIX } from "./constant";
import { Request } from "./request";
import { storehouseBody } from "./storehouse";
import {getBatchReportDamagedAsync} from "../features/storesearch/storesearchSlice";

export interface storePageParams {
  current: number
  size: number
  name?: string
  storehouseId?: number
  billingCategory?: number
  categoryType?: string
  state?: number
  showFlag?: number
  deadlineEnd?: string
  deadlineStart?: string
  mergeFlag?: number
  registrationTimeHead?: string
  registrationTimeTail?: string
  length?: number
  materialsCategory?: any
  materialsCategoryList?: any
  category?: any
  isMate?:number
  sort?:number
}

export interface batchReportDamagedParams {
  mergeFlag?: number
  inventoryLedgerList? : any
}

export interface storePageBody {
  productDate?: string;
  deadline?: string;
  shelfNo?: number;
  plateNo?: number;
  id?: number;
}

export interface logoutOrTotalBody {
  count?: number;
  flag?: number;
  tenantInventoryLedgerId?: number;
}

export interface simpleParams {
  ids: string;
  state: number;
}

export interface MaintanceParams {
  tenantInventoryLedgerId?: number;
  current?: number;
  maintainResult?: number;
  size?: number;
}

export function storesearchPage(params: storePageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/list`,
    method: "GET",
    params,
  };
}

export function inventoryInquiryPage(params: storePageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/inventoryInquiry`,
    method: "GET",
    params,
  };
}

export function pageStoresearchPrint(params: storePageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/list/readyToPrint`,
    method: 'GET',
    params,
  }
}


export function storesearchUpdata(body: storehouseBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/update`,
    method: "POST",
    body,
  };
}

export function storesearchDetail(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/detail`,
    method: "GET",
    params: {
      id,
    },
  };
}

export function logoutlist(params: simpleParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/logoutlist`,
    method: "POST",
    params,
  };
}

export function splitOrTotal(body: logoutOrTotalBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/splitputtotal`,
    method: "POST",
    body,
  };
}

export function Maintanceindetail(params: MaintanceParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintainitem/maintainrecord`,
    method: "GET",
    params,
  };
}

// 导出
export function getStoreExport(
  body: storePageParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/list/exportexcel`,
    method: "POST",
    responseType: "blob",
    body,
  };
}

export function getBatchReportDamaged(
  body: batchReportDamagedParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/batchReportDamaged`,
    method: "POST",
    body,
  };
}



export interface onBatchInitializationBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    ids: any
  }
}

export function onBatchInitialization(bodyName: onBatchInitializationBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/InventoryInit_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
