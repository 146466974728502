import {
  CaretDownFilled,
  CaretUpFilled,
  SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  ModalProps,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import moment from 'moment'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { EditableList } from '../../../../compnents/list/EditableList'
import { Material } from '../../../../models/material'
import { Page, startPageOf } from '../../../../models/page'
import { RecipeCategory } from '../../../../models/recipe'
import { SetMeal, SetMealPageParams } from '../../../../services/setmeal'
import { ThemeContext } from '../../../../theme/ThemeContext'
import styles from '../../../setmeal/Setmeal.module.css'
import {
  getSetMealDetail,
  getSetMealList,
  selectCategory,
} from '../../../setmeal/setmealSlice'
import { selectEdition } from '../../../../app/applicationSlice'

type Action =
  | { type: 'toggle'; payload: number }
  | { type: 'set'; payload: { id: number; materials: Material[] } }
  | { type: 'clear' }

interface SetMealModalState {
  openIds: number[]
  materials: Record<number, Material[]>
}

function reducer(state: SetMealModalState, action: Action) {
  switch (action.type) {
    case 'toggle':
      if (state.openIds.includes(action.payload)) {
        return {
          ...state,
          openIds: state.openIds.filter((id) => id !== action.payload),
        }
      }
      return { ...state, openIds: state.openIds.concat(action.payload) }
    case 'set':
      return {
        ...state,
        materials: {
          ...state.materials,
          [action.payload.id]: action.payload.materials,
        },
      }
    case 'clear':
      return initialState
  }
}

const initialState = {
  openIds: [],
  materials: {},
} as SetMealModalState

interface SetMealModalProps {
  completionType?: any
  recipeCategory?: RecipeCategory
  onChange?: (materials: Material[], id?: any,category?:any) => void
}

export const SetMealModal = ({
  completionType,
  recipeCategory,
  onChange,
  visible,
  onCancel,
  ...others
}: SetMealModalProps & ModalProps): ReactElement => {
  const rootDispatch = useDispatch<RootDispatch>()

  const { Option } = Select

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [page, setPage] = useState<Page<SetMeal>>(startPageOf<SetMeal>())

  const [params, setParams] = useState<SetMealPageParams>({
    current: 0,
    detailFlag: 1,
    state: 1,
  })
  const hisVersion = useSelector(selectEdition) //区分口腔版还是普通

  const [state, dispatch] = useReducer(reducer, initialState)

  const [checkedIds, setCheckedIds] = useState<any>([])

  const [selectIds, setSelectIds] = useState<any>([])

  const [checkedList, setCheckedList] = useState<any>([])

  // const [data, setData] = useState<any[]>([])

  const [loading, setLoading] = useState(false)

  const [category, setCategory] = useState<any>()

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectIds(selectedRowKeys)
    },
  }

  const data = [
    {
      id: 1,
      selectedRowKeys: [],
      child: [
        {
          key: '1',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '2',
          name: 'Jim Green',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sydney No. 1 Lake Park',
        },
        {
          key: '4',
          name: 'Disabled User',
          age: 99,
          address: 'Sydney No. 1 Lake Park',
        },
      ],
    },
    {
      id: 2,
      selectedRowKeys: [],
      child: [
        {
          key: '1',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '2',
          name: 'Jim Green',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sydney No. 1 Lake Park',
        },
        {
          key: '4',
          name: 'Disabled User',
          age: 99,
          address: 'Sydney No. 1 Lake Park',
        },
      ],
    },
    {
      id: 3,
      selectedRowKeys: [],
      child: [
        {
          key: '1',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
        },
        {
          key: '2',
          name: 'Jim Green',
          age: 42,
          address: 'London No. 1 Lake Park',
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sydney No. 1 Lake Park',
        },
        {
          key: '4',
          name: 'Disabled User',
          age: 99,
          address: 'Sydney No. 1 Lake Park',
        },
      ],
    },
  ]
  const [selectionType] = useState<'checkbox'>('checkbox')
  const [isVisibleList, setVisibleList] = useState([])
  const [datas, setDatas] = useState(data)

  useEffect(() => {
    if (visible) {
      
      setSelectIds('')
      setLoading(true)
      if (params.current !== 0) {
        if (params?.category != undefined) {
          rootDispatch(
            getSetMealList({
              ...params,
              category: category == 99 ? (1 == recipeCategory || 14 == recipeCategory) ? '0,2,4' : undefined : params?.category,
              state: 1,
            })
          )
            .then(unwrapResult)
            .then((page) => {
              setLoading(false)
              setPage(page)
            })
            .catch(() => {
              setLoading(false)
            })
        }
      }
    } else {
      dispatch({ type: 'clear' })
    }
  }, [visible, params])

  useEffect(() => {
    setCheckedIds('')
  } ,[visible])

  useEffect(() => {
    if (visible) {
      if (hisVersion && hisVersion == 1) {
        // form.setFieldsValue({
        //   category: 4,
        // })
        setCategory(4)
        setParams({
          ...params,
          category: '4',
        })
      } else {
        setCategory(recipeCategory === RecipeCategory.Tcm ? 1 : completionType)
        setParams({
          ...params,
          category: recipeCategory === RecipeCategory.Tcm ? 1 : completionType,
        })
        // form.setFieldsValue({
        //   category: recipeCategory === RecipeCategory.Tcm ? 1 : completionType,
        // })
      }
    } else {
      setParams({ ...params, category: undefined })
      form.resetFields()
    }
  }, [visible, hisVersion, completionType])

  useEffect(() => {
    // 套餐类别:0西药套餐:1中药套餐:2诊疗套餐:3颗粒剂套餐
    if (visible) {
      const common = { current: 1, detailFlag: 1, state: 1 }
      if (recipeCategory === RecipeCategory.Tcm) {
        setParams({
          ...common,
          category: location.pathname == '/retail' ? category : 1,
        })
      } else if (recipeCategory === RecipeCategory.Treatment) {
        setParams({ ...common, category: '2' })
      } else if (recipeCategory === RecipeCategory.TcmGranules) {
        setParams({ ...common, category: '3' })
      } else if (recipeCategory === RecipeCategory.Others) {
        setParams({ ...common, category: '0,1,2,3' })
      } else if (recipeCategory === RecipeCategory.Examination) {
        setParams({ ...common, category: '0,2' })
      } else if (recipeCategory === RecipeCategory.Dispose) {
        setParams({ ...common, category: '2,4' })
      } else if (recipeCategory === RecipeCategory.Medicine) {
        setParams({ ...common, category: category })
      } else if (recipeCategory === RecipeCategory.ShuZhuFang) {
        setParams({ ...common, category: category })
      } else {
        setParams({ ...common, category: '0' })
      }
    }
  }, [recipeCategory, visible, category])

  useEffect(() => {
    // 一级下选中的所有二级
    const list =
      checkedIds?.length &&
      checkedIds
        ?.map((v: any) => {
          return state?.materials?.[v]
        })
        ?.flat(1)
    setCheckedList(list)
    setSelectIds(list?.length && list?.map((v: any) => v?.id))
  }, [checkedIds, state])

  return (
    <Modal
      visible={visible}
      {...others}
      onCancel={onCancel}
      title='套餐选择'
      width={1200}
      footer={null}
      style={{ overflow: 'hidden', padding: '0' }}
      bodyStyle={{ padding: '10px 15px 0 15px' }}
    >
      <Col
        style={{
          height: '36rem',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          onFinish={(values) =>
            setParams({
              ...params,
              ...values,
              category:
                recipeCategory === RecipeCategory.Medicine
                  ? category
                  : params?.category,
            })
          }
        >
          <Row
            style={{
              marginTop:
                recipeCategory === RecipeCategory.Medicine ||
                (recipeCategory === RecipeCategory.Tcm &&
                  location.pathname == '/retail')
                  ? -20
                  : -10,
            }}
            justify='space-between'
          >
            <Space>
              <Form.Item noStyle name='name'>
                <Input
                  placeholder='请输入套餐名/助记码'
                  prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
                  onChange={form.submit}
                />
              </Form.Item>
              <Form.Item noStyle name='itemName'>
                <Input
                  placeholder='请输入商品名称'
                  prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
                  onChange={form.submit}
                />
              </Form.Item>
              {(recipeCategory === RecipeCategory.Medicine ||
                recipeCategory === RecipeCategory.ShuZhuFang ||
                (recipeCategory === RecipeCategory.Tcm &&
                  location.pathname == '/retail')) && (
                <>
                  <Form.Item name='category'>
                    <a
                      onClick={() => {
                        setCategory(99)
                      }}
                      className={styles.queryB}
                      style={{
                        color: category === 99 ? '#027AFF' : '#666666',
                        border: category === 99 ? '' : '1px solid #DDDDDD',
                        fontWeight: 'bold',
                        backgroundColor:
                          category === 99 ? '#eef8ff' : '#ffffff',
                      }}
                    >
                      全部
                    </a>
                    {recipeCategory === RecipeCategory.Medicine || recipeCategory === RecipeCategory.ShuZhuFang ? (
                      <>
                        <a
                          onClick={() => {
                            setCategory(0)
                          }}
                          className={styles.queryB}
                          style={{
                            color: category === 0 ? '#027AFF' : '#666666',
                            border: category === 0 ? '' : '1px solid #DDDDDD',
                            fontWeight: 'bold',
                            backgroundColor:
                              category === 0 ? '#eef8ff' : '#ffffff',
                          }}
                        >
                          西药套餐
                        </a>
                        <a
                          onClick={() => {
                            setCategory(2)
                          }}
                          className={styles.queryB}
                          style={{
                            color: category === 2 ? '#027AFF' : '#666666',
                            border: category === 2 ? '' : '1px solid #DDDDDD',
                            fontWeight: 'bold',
                            backgroundColor:
                              category === 2 ? '#eef8ff' : '#ffffff',
                          }}
                        >
                          诊疗套餐
                        </a>
                        <a
                          onClick={() => {
                            setCategory(4)
                          }}
                          className={styles.queryB}
                          style={{
                            color: category === 4 ? '#027AFF' : '#666666',
                            border: category === 4 ? '' : '1px solid #DDDDDD',
                            fontWeight: 'bold',
                            backgroundColor:
                              category === 4 ? '#eef8ff' : '#ffffff',
                          }}
                        >
                          处置项目套餐
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          onClick={() => {
                            setCategory(1)
                          }}
                          className={styles.queryB}
                          style={{
                            color: category === 1 ? '#027AFF' : '#666666',
                            border: category === 1 ? '' : '1px solid #DDDDDD',
                            fontWeight: 'bold',
                            backgroundColor:
                              category === 1 ? '#eef8ff' : '#ffffff',
                          }}
                        >
                          中药套餐
                        </a>
                        <a
                          onClick={() => {
                            setCategory(3)
                          }}
                          className={styles.queryB}
                          style={{
                            color: category === 3 ? '#027AFF' : '#666666',
                            border: category === 3 ? '' : '1px solid #DDDDDD',
                            fontWeight: 'bold',
                            backgroundColor:
                              category === 3 ? '#eef8ff' : '#ffffff',
                          }}
                        >
                          颗粒剂套餐
                        </a>
                      </>
                    )}
                  </Form.Item>
                </>
              )}
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
            <Button
              type='primary'
              onClick={() => {
                if (!checkedList?.length) {
                  if (selectIds?.length) {
                    onChange && onChange(checkedList, '', params?.category)
                  }
                } else {
                  onChange && onChange(checkedList, '', params?.category)
                }
              }}
              style={{
                marginTop: '24px',
                position: 'relative',
                top:
                  recipeCategory === RecipeCategory.Medicine ||
                  (recipeCategory === RecipeCategory.Tcm &&
                    location.pathname == '/retail')
                    ? 0
                    : '-10px',
              }}
            >
              批量添加
            </Button>
          </Row>
        </Form>
        <Col flex={1} style={{ overflow: 'auto' }}>
          <Spin spinning={loading}>
            {page.items.map((s: any, index) => {
              const open = s.id && state.openIds.includes(s.id)
              return (
                <Col key={s.id}>
                  <Row
                    align='middle'
                    style={{
                      font: theme.tc1,
                      fontSize: '1rem',
                      height: '3rem',
                      border: `1px solid ${theme.wbd}`,
                      borderRadius: 2,
                      paddingLeft: 20,
                      marginBottom: 8,
                      whiteSpace: 'nowrap',
                      background: '#e9f3ffa1',
                      cursor: 'pointer',
                    }}
                    wrap={false}
                  >
                    <Row style={{ width: '100%' }}>
                      <Col style={{ marginRight: '16px', width: '1%' }}>
                        <Checkbox
                          checked={checkedIds?.includes(s?.id)}
                          onChange={(e) => {
                            if (!s.id) {
                              return
                            }
                            if (state.materials[s.id] === undefined) {
                              rootDispatch(getSetMealDetail(s.id))
                                .then(unwrapResult)
                                .then((ms) => {
                                  if (s.id) {
                                    dispatch({
                                      type: 'set',
                                      payload: { id: s.id, materials: ms },
                                    })
                                  }
                                })
                            }
                            if (e?.target?.checked) {
                              const isCheck = checkedIds?.includes(s?.id)
                              if (isCheck) {
                                const ids = checkedIds?.filter(
                                  (id: any) => id !== s?.id
                                )
                                setCheckedIds(ids)
                              } else {
                                setCheckedIds([...checkedIds, s?.id])
                              }
                            } else {
                              const ids = checkedIds?.filter(
                                (id: any) => id !== s?.id
                              )
                              setCheckedIds(ids)
                            }
                          }}
                        />
                      </Col>
                      <Row
                        style={{ width: '89%' }}
                        onClick={() => {
                          if (!s.id) {
                            return
                          }
                          setSelectIds([])
                          dispatch({ type: 'toggle', payload: s.id })
                          if (state.materials[s.id] === undefined) {
                            rootDispatch(getSetMealDetail(s.id))
                              .then(unwrapResult)
                              .then((ms) => {
                                if (s.id) {
                                  dispatch({
                                    type: 'set',
                                    payload: { id: s.id, materials: ms },
                                  })
                                }
                              })
                          }
                        }}
                      >
                        <Col style={{ marginRight: '16px' }}>
                          {moment(s.createTime).format('YYYY-MM-DD')}
                        </Col>
                        <Col
                          className={styles.title}
                          span={4}
                          style={{
                            display: 'inline-block',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginRight: '16px',
                            // minWidth:"200px"
                          }}
                        >
                          <i
                            style={{
                              width: 'auto',
                              height: '100%',
                              fontStyle: 'normal',
                              marginRight: '16px',
                            }}
                          >
                            {s.name}
                          </i>
                        </Col>
                        <Col style={{ marginRight: '16px' }}>
                          共{s.count}项明细
                        </Col>
                        <Col>总计{s.total.toFixed(2)}元</Col>
                        <Col flex={1}></Col>
                      </Row>
                      <Col>
                        <Button
                          size='small'
                          style={{ color: theme.c1, borderColor: theme.c1 }}
                          onClick={() => {
                            if (!s.id) {
                              return
                            }
                            const materials = state?.materials[s.id]
                            const listParams: any = []
                            selectIds?.length &&
                              selectIds?.forEach((item: any) => {
                                materials?.forEach((element) => {
                                  if (item == element.id) {
                                    listParams?.push(element)
                                  }
                                })
                              })
                            if (materials !== undefined) {
                              onChange &&
                                onChange(
                                  selectIds?.length ? listParams : materials,
                                  s?.id,
                                  params?.category
                                )
                            } else {
                              rootDispatch(getSetMealDetail(s.id))
                                .then(unwrapResult)
                                .then((ms) => {
                                  onChange &&
                                    onChange(ms, s?.id, params?.category)
                                })
                            }
                          }}
                        >
                          添加
                        </Button>
                      </Col>
                      <Col
                        style={{ padding: '0 15px', cursor: 'pointer' }}
                        onMouseDown={(e) => {
                          e.preventDefault()
                        }}
                        onClick={() => {
                          if (!s.id) {
                            return
                          }
                          dispatch({ type: 'toggle', payload: s.id })
                          if (state.materials[s.id] === undefined) {
                            rootDispatch(getSetMealDetail(s.id))
                              .then(unwrapResult)
                              .then((ms) => {
                                if (s.id) {
                                  dispatch({
                                    type: 'set',
                                    payload: { id: s.id, materials: ms },
                                  })
                                }
                              })
                          }
                        }}
                      >
                        {open ? (
                          <CaretUpFilled style={{ color: '#999999' }} />
                        ) : (
                          <CaretDownFilled style={{ color: '#999999' }} />
                        )}
                      </Col>
                    </Row>
                  </Row>
                  {s.id && open && state.materials[s.id] && (
                    <EditableList
                      rowKey={(v, i) => v.id}
                      rowSelection={
                        {
                          selectedRowKeys: selectIds,
                          onChange: (
                            selectedRowKeys: React.Key[],
                            selectedRows: any
                          ) => {
                            setCheckedList(selectedRows)
                            setSelectIds(selectedRowKeys)
                          },
                          getCheckboxProps: (record: any) => ({
                            disabled: checkedIds?.length ? true : false, // 根据条件设置禁用状态
                          }),
                        } as TableRowSelection<any>
                      }
                      onRow={(record) => ({
                        onClick: () => {
                          // 当前点击的这一行下边的所有，其余的不算
                          const thisIds = state.materials[s.id]?.map((v) => {
                            return v?.id
                          })
                          if (!checkedIds?.length) {
                            let data: any = []
                            if (!selectIds?.includes(record?.id.toString())) {
                              if (selectIds?.length) {
                                data = [...selectIds, record?.id.toString()]
                              } else {
                                data = [record?.id.toString()]
                              }
                            } else {
                              data = selectIds?.filter(
                                (v: any) => v != record?.id.toString()
                              )
                            }
                            const dataList: any = thisIds.filter((item) =>
                              data.includes(item)
                            )

                            const dataListData: any = thisIds.filter((item) =>
                              data.includes(item)
                            )
                            const materials = state.materials[s.id]
                            const listParams: any = []
                            dataListData?.forEach((item: any) => {
                              materials.forEach((element) => {
                                if (item == element.id) {
                                  listParams.push(element)
                                }
                              })
                            })

                            setCheckedList(listParams)
                            setSelectIds(dataList)
                          }
                        },
                      })}
                      style={{ marginBottom: '10px' }}
                      pagination={false}
                      size='small'
                      bordered
                      dataSource={state.materials[s.id]}
                      columns={[
                        {
                          title: '序号',
                          key: 'no',
                          align: 'center',
                          width: '3rem',
                          render: (_1, _2, i) => `${i + 1}`,
                        },
                        {
                          title: '组号',
                          dataIndex: 'groupNumber',
                          align: 'center',
                          width: '3rem',
                        },
                        {
                          title: '物资名称',
                          dataIndex: 'name',
                          align: 'center',
                          ellipsis: true,
                        },
                        {
                          title: '用法',
                          dataIndex: 'normalUsemethod',
                          align: 'center',
                          width: '8rem',
                          ellipsis: true,
                        },
                        {
                          title: '单位',
                          dataIndex: 'accountUnit',
                          align: 'center',
                          width: '8rem',
                          ellipsis: true,
                        },
                        {
                          title: '单价（元）',
                          dataIndex: 'retailPrice',
                          align: 'right',
                          width: '8rem',
                          ellipsis: true,
                          render: (_, t) => {
                            return t.retailPrice
                              ? t.retailPrice?.toFixed(5)
                              : ''
                          },
                        },
                      ]}
                    />
                  )}
                </Col>
              )
            })}
            {/* <div>
              {data.map((row, index) => (
                <div key={row.id}>
                  <Row>
                    <Col
                      span={24}
                      onDoubleClick={() => {
                        const newVisibleList: any = [...isVisibleList]
                        newVisibleList[index] = !newVisibleList[index]
                        setVisibleList(newVisibleList)
                      }}
                    >
                      <Checkbox
                        onChange={(e) => {
                          const newVisibleList: any = [...isVisibleList]
                          newVisibleList[index] = !newVisibleList[index]
                          setVisibleList(newVisibleList)
                          const arr: React.Key[] = []
                          data[index].child.map((item: any) => {
                            arr.push(item.key)
                          })
                          const arrData: any = datas.map((item, i) => {
                            console.log(index, i)
                            if (index === i) {
                              return {
                                ...item,
                                selectedRowKeys: arr,
                              }
                            } else {
                              return item
                            }
                          })
                          setDatas([...arrData])
                        }}
                      >
                        复选框
                      </Checkbox>
                    </Col>
                  </Row>
                  {isVisibleList?.[index] && (
                    <Table
                      rowSelection={{
                        selectedRowKeys: datas[index].selectedRowKeys,
                        type: selectionType,
                        onChange: (
                          newSelectedRowKeys: React.Key[],
                          selectedRows?: DataType[]
                        ) => {
                          const dataValue: any = datas.map((item, i) => {
                            console.log(index, i)
                            if (index === i) {
                              return {
                                ...item,
                                selectedRowKeys: newSelectedRowKeys,
                              }
                            } else {
                              return item
                            }
                          })

                          setDatas([...dataValue])
                        },
                      }}
                      columns={[
                        {
                          title: '序号',
                          key: 'no',
                          align: 'center',
                          width: '3rem',
                          render: (_1, _2, i) => `${i + 1}`,
                        },
                        {
                          title: '组号',
                          dataIndex: 'groupNumber',
                          align: 'center',
                          width: '3rem',
                        },
                        {
                          title: '物资名称',
                          dataIndex: 'name',
                          align: 'center',
                          ellipsis: true,
                        },
                        {
                          title: '用法',
                          dataIndex: 'normalUsemethod',
                          align: 'center',
                          width: '8rem',
                          ellipsis: true,
                        },
                        {
                          title: '单位',
                          dataIndex: 'accountUnit',
                          align: 'center',
                          width: '8rem',
                          ellipsis: true,
                        },
                        {
                          title: '单价（元）',
                          dataIndex: 'retailPrice',
                          align: 'right',
                          width: '8rem',
                          ellipsis: true,
                          render: (_, t: any) => {
                            return t.retailPrice
                              ? t.retailPrice?.toFixed(5)
                              : ''
                          },
                        },
                      ]}
                      dataSource={row.child}
                    />
                  )}
                </div>
              ))}
            </div> */}
          </Spin>
        </Col>
        <Row
          style={{ flex: '0 0 5rem' }}
          align='middle'
          justify='space-between'
        >
          <Pagination
            showQuickJumper
            showSizeChanger
            size='small'
            total={page.total}
            current={page.current}
            pageSize={page.size}
            onChange={(current, size) => {
              setParams({ ...params, current, size })
              if (params?.current != 0) {
                if (params?.category == undefined) {
                  rootDispatch(
                    getSetMealList({
                      ...params,
                      category: category == 99 ? undefined : params?.category,
                      current,
                      size,
                      state: 1,
                    })
                  )
                    .then(unwrapResult)
                    .then((page) => {
                      setLoading(false)
                      setPage(page)
                    })
                    .catch(() => {
                      setLoading(false)
                    })
                }
              }
            }}
          />
          <Button type='primary' onClick={(e) => onCancel && onCancel(e)}>
            关闭
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}
