/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-27 16:02:16
 * @LastEditors: K
 * @LastEditTime: 2022-10-09 09:27:52
 */
import { unwrapResult } from '@reduxjs/toolkit';
import { Col, Form, Input, Modal, Row, Select, Space, Tag } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../app/store';
import styles from './MembersManagementPage.module.css'
import { getAddMembersOperation, getMembersBaseInfo, getMembersInfo } from './MembersManagementPageSlice';
interface TopUpModalProps {
  visible: boolean
  btnType: boolean
  membersId?: number
  modalText: number
  onCancel: () => void
  onOk: (obj: any) => void
}

export const TopUpModal = ({
  visible,
  btnType,
  membersId,
  modalText,
  onCancel,
  onOk,
}: TopUpModalProps): ReactElement => {

  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm()

  const { Option } = Select

  const { CheckableTag } = Tag;

  const { TextArea } = Input;

  const [btnLoading, setBtnLoading] = useState(false)

  const [operation, setOperation] = useState<any>([])

  const [membersInfo, setMembersInfo] = useState<any>({})

  useEffect(() => {
    setBtnLoading(false)
  }, [btnType])

  useEffect(() => {
    if (!visible) {
      form.resetFields()
    } else {
      if (!membersId) return
      dispatch(getAddMembersOperation()).then(unwrapResult).then((res: any) => setOperation(res))
      dispatch(getMembersInfo({ memberId: membersId })).then(unwrapResult).then((res: any) => setMembersInfo(res))
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      title={modalText ? '退款' : '会员充值'}
      width={700}

      onCancel={onCancel}
      confirmLoading={btnLoading}
      onOk={form.submit}
    >
      <div className={styles.formWrap}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            createUser: Number(JSON.parse(localStorage.getItem('credentials') || '{}')?.userId)
          }}
          onFinish={(values) => {
          //   const res = /(^[0-9]*\.([0-9]{1}\d*)$)|(^[0-9]*$)/
          // if (!res.test(values.balanceMoney)) return
            setBtnLoading(true)
            onOk({ ...values, type: modalText, memberId: membersId })
          }}
          autoComplete="off"
        >
          <Form.Item label="姓名">{membersInfo?.memberName}</Form.Item>
          <Form.Item label={modalText ? '联系方式' : "手机号"}>{membersInfo?.memberPhone}</Form.Item>
          <Form.Item label="年龄"> {membersInfo?.age}</Form.Item>
          <Form.Item label="性别"> {membersInfo?.sexTypeStr}</Form.Item>
          <Form.Item label="生日"> {membersInfo?.birthday}</Form.Item>
          <Form.Item label="账户余额">{membersInfo?.balanceMoney?.toFixed(2)}</Form.Item>
          {modalText == 0 && <Form.Item label="标签">
            <Space>
              {membersInfo?.labelList?.map((item: any) => (
                <div key={item.id} style={{ padding: '2px 5px', fontSize: '12px', border: '2px solid #dcdcdc', borderRadius: '4px' }}>
                  {item.name}
                </div>
              ))}
            </Space>
          </Form.Item>}
          <Form.Item label={modalText ? '退款金额' : "储值金"} name="money" rules={[
            {
              required: true,
              pattern: new RegExp(/(^[0-9]*\.([0-9]{1}\d*)$)|(^[0-9]*$)/),
              message: "请输入正确的数字",
            }]}>
            <Input
              suffix={<div className={styles.iptText}>元</div>}
            />
          </Form.Item>
          <Form.Item label="操作员" name="createUser">
            <Select style={{ margin: '10px 0' }}>
              {
                operation.length && operation.map((v: any) => (
                  <Option key={v.userId} value={v.userId}>{v.userName}</Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label="备注" name="remark">
            <TextArea showCount maxLength={100} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}


