/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-13 11:48:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-04 10:44:59
 */
import {
  Col,
  Popover,
  Space,
} from 'antd'
import { TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
import { Dimen } from '../../models/dimen'
import { getOtcCategoryName } from '../../models/Otccategory'

export const DurgsalesColumns = (
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '对方名称',
      align: 'center',
      width: Dimen.Num,
      dataIndex: 'patientName',
      key: 'patientName',
      render: (_, t) => {
        return t.patientName ? t.patientName : '零售患者'
      },
    },
    {
      title: '身份证',
      align: 'center',
      width: '150px',
      render: (_, t) => {
        return t.idCardNumber
      },
    },
    {
      title: '开单科室',
      align: 'center',
      width: '150px',
      dataIndex: 'departmentName',
      key: 'departmentName',
    },
    {
      title: '开单医生',
      align: 'center',
      width: '150px',
      dataIndex: 'doctorName',
      key: 'doctorName',
    },
    {
      title: '物资编号',
      align: 'center',
      width: '150px',
      dataIndex: 'no',
      key: 'no',
    },
    {
      // 商品名称/生产厂家/规格/剂型
      title: '产品信息',
      align: 'left',
      width: '300px',
      render: function Element(_, t) {
        return (
          <Space
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Col>
              <Popover
                content={
                  <>
                    <div style={{ height: '55px' }}>
                      <p
                        style={{
                          height: '28px',
                          lineHeight: '36px',
                          padding: '0',
                          margin: '0',
                          fontSize: '16px',
                          fontWeight: 'bolder',
                        }}
                      >
                        {t.drugName}
                      </p>
                      <p
                        style={{
                          height: '28px',
                          lineHeight: '24px',
                          padding: '0',
                          margin: '0',
                          color: '#999999',
                          fontSize: '14px',
                        }}
                      >
                        {t.tenantSupplierName} / {t.packSpec}/{t.doseCategory}
                      </p>
                    </div>
                  </>
                }
                placement='bottom'
              >
                <div style={{ height: '55px', width: '300px', overflow: 'hidden' }}>
                  <p
                    style={{
                      height: '28px',
                      lineHeight: '36px',
                      padding: '0',
                      margin: '0',
                      fontSize: '16px',
                      fontWeight: 'bolder',
                    }}
                  >
                    {t.drugName}
                  </p>
                  <p
                    style={{
                      height: '28px',
                      lineHeight: '24px',
                      padding: '0',
                      margin: '0',
                      color: '#999999',
                      fontSize: '14px',
                    }}
                  >
                    {t.tenantSupplierName} / {t.packSpec}/{t.doseCategory}
                  </p>
                </div>
              </Popover>
            </Col>
          </Space>

        )
      },
    },
    // {
    //   title: "供应商",
    //   align: "center",
    //   width:"150px",
    //   dataIndex: "",
    //   key: "",
    // },
    // {
    //   title: "库房名称",
    //   align: "center",
    //   width:"150px",
    //   dataIndex: "",
    //   key: "",
    // },
    // {
    //   title: "批次号",
    //   align: "center",
    //   width:"150px",
    //   dataIndex: "",
    //   key: "",
    // },
    {
      title: '批次号',
      align: 'center',
      dataIndex: 'batchNo',
    },
    {
      title: 'OTC标记',
      align: 'center',
      width: '150px',
      dataIndex: 'otc',
      key: 'otc',
      render: (otc: any) => {
        return getOtcCategoryName(otc)
      },
    },
    {
      title: '麻黄碱标记',
      align: 'center',
      width: '150px',
      dataIndex: 'ephedrineTag',
      key: 'ephedrineTag',
      render: (_, t) => {
        return t.ephedrineTag > -1 ? (t.ephedrineTag ? '是' : '否') : ''
      },
    },
    {
      title: '进价',
      align: 'right',
      width: '150px',
      dataIndex: 'stockPrice',
      key: 'stockPrice',
      render: (_, t) => {
        return t.stockPrice ? parseFloat(t.stockPrice).toFixed(5) : ''
      },
    },
    {
      title: '原价',
      align: 'right',
      width: '150px',
      dataIndex: 'retailPriceValue',
      key: 'retailPriceValue ',
      render: (_, t) => {
        return t.retailPriceValue
          ? parseFloat(t.retailPriceValue).toFixed(5)
          : ''
      },
    },
    {
      title: '销售价',
      align: 'right',
      width: '150px',
      dataIndex: 'retailPrice',
      key: 'retailPrice',
      render: (_, t) => {
        return t.retailPrice ? parseFloat(t.retailPrice).toFixed(5) : ''
      },
    },

    {
      title: '销售数量',
      align: 'center',
      width: '150px',
      dataIndex: 'drugCount',
      key: 'drugCount',
    },
    {
      title: '销售金额',
      align: 'center',
      width: '150px',
      dataIndex: 'drugAmount',
      key: 'drugAmount',
      render: (_, t) => {
        return t.drugAmount ? parseFloat(t.drugAmount).toFixed(2) : ''
      },
    },
    {
      title: '销售时间',
      align: 'center',
      width: '150px',
      dataIndex: 'operateTime',
      key: 'operateTime',
      render: (_, t) => {
        return t.operateTime ? moment(t.operateTime).format(DateTimeFormat) : '-'
      },
    },
  ]
}
