/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-06 17:02:55
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-19 14:35:47
 */
import { Button, Popover } from 'antd'
import { Ellipse } from 'echarts/types/src/util/graphic'
import moment from 'moment'
import React, { useEffect } from 'react'
import { EditableColumnType } from '../../compnents/list/EditableList'
import { DateTimeFormatSimple } from '../../models/datetime'
import { Dimen } from '../../models/dimen'
import { ItemCompletionType } from '../completion/completionSlice'
import '../putstorelist/notshow.css'

export const Columns = (startIndex: number): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      fixed: 'left',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '产品信息',
      fixed: 'left',
      width: '400px',
      ellipsis: true,
      render: function Element(_, record) {
        return (
          <Popover
            content={
              <div style={{ height: '55px' }}>
              <p
                style={{
                  height: '28px',
                  lineHeight: '36px',
                  padding: '0',
                  margin: '0',
                  fontSize: '16px',
                  fontWeight: 'bolder',
                }}
              >
                {record.name}
              </p>
              <p
                style={{
                  height: '28px',
                  lineHeight: '24px',
                  padding: '0',
                  margin: '0',
                  color: '#999999',
                  fontSize: '14px',
                }}
              >
                {record.tenantSupplierName} / {record.spec} /
                {record.doseCategory}
              </p>
            </div>
            }
            placement='bottom'
          >
            <div style={{ height: '55px' }}>
              <p
                style={{
                  height: '28px',
                  lineHeight: '36px',
                  padding: '0',
                  margin: '0',
                  fontSize: '16px',
                  fontWeight: 'bolder',
                }}
              >
                {record.name}
              </p>
              <p
                style={{
                  height: '28px',
                  lineHeight: '24px',
                  padding: '0',
                  margin: '0',
                  color: '#999999',
                  fontSize: '14px',
                }}
              >
                {record.tenantSupplierName} / {record.spec} /
                {record.doseCategory}
              </p>
            </div>
          </Popover>
        )
      },
    },
    {
      title: '批号',
      align: 'center',
      dataIndex: 'batchNo',
      width: Dimen.BatchNo,
    },
    {
      title: '有效期至',
      align: 'center',
      dataIndex: 'deadline',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return moment(t.deadline).format(DateTimeFormatSimple)
      },
    },
    {
      title: '进价',
      align: 'center',
      dataIndex: '',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return `${parseFloat(t.stockPrice).toFixed(5)}`
      },
    },
    {
      title: '整存单价变化',
      align: 'center',
      width: '12rem',
      ellipsis: true,
      dataIndex: '',
      render: (_, t) => {
        return `${parseFloat(t.beforeRetailPrice).toFixed(5)}=>${parseFloat(
          t.retailPrice
        ).toFixed(5)}`
      },
    },
    {
      title: '拆零单价变化',
      align: 'center',
      dataIndex: '',
      width: '12rem',
      ellipsis: true,
      render: (_, t) => {
        return `${parseFloat(t.beforeSplitPrice).toFixed(5)}=>${parseFloat(
          t.splitPrice
        ).toFixed(5)}`
      },
    },
    {
      title: '调价整存数量',
      align: 'center',
      dataIndex: '',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => {
        return `${parseFloat(t.inventoryCount)}`
      },
    },
    {
      title: '调价拆零数量',
      align: 'center',
      dataIndex: '',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => {
        return `${parseFloat(t.inventorSplitCount)}`
      },
    },
    {
      title: '调亏调盈金额',
      align: 'center',
      dataIndex: '',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => {
        return `${parseFloat(t.adjustAmount).toFixed(2)}`
      },
    },
    {
      title: '执行时间',
      align: 'center',
      dataIndex: 'executeDate',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.executeDate
          ? moment(t.executeDate).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '执行状态',
      align: 'center',
      dataIndex: 'state',
      width: Dimen.State,
      render: (_, t) => {
        return t.state == 2 ? '已撤销' : t.state ? '执行' : '未执行'
      },
    },
  ]
}

export const InventoryPriceColumns = (
  onclick: (text: string, t: any) => void
): EditableColumnType<any>[] => {
  return [
    {
      title: '操作',
      align: 'center',
      dataIndex: 'id',
      width: '4rem',
      render: function Element(_: any, t: { id: string }) {
        return (
          <div>
            <Button
              type='link'
              onClick={() => {
                onclick('add', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              ＋
            </Button>
            <Button
              type='link'
              onClick={() => {
                onclick('remove', t)
              }}
              style={{ fontSize: '24px', padding: 0 }}
            >
              －
            </Button>
          </div>
        )
      },
    },
    {
      title: '名称',
      align: 'center',
      width: '10rem',
      inputType: 'material',
      dataIndex: 'name',
      ellipsis: true,
      editable: true,
      // autoFocus: (r) => false,
      autoFocus: (r) => !r.retailPrice && !r.name,
      nextDataIndex: 'splitPrice',
      completionType: ItemCompletionType.Inventory,
      onSelectMaterial: (prev, newdata) => {
        const beforeSplitPrice = newdata.splitPrice
        const beforeRetailPrice = newdata.retailPrice
        const tenantInventoryLedgerId = newdata.id
        const next = {
          ...newdata,
          beforeSplitPrice,
          beforeRetailPrice,
          retailPrice: undefined,
          tenantInventoryLedgerId,
        }
        onclick('edit', { prev, next })
      },
    },
    {
      title: '产品信息',
      align: 'center',
      width: '12rem',
      ellipsis: true,
      dataIndex: 'productInfo',
    },
    {
      title: '批号',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      dataIndex: 'batchNo',
    },
    {
      title: '有效期',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'deadline',
      render: (deadline) => {
        return deadline && deadline !== 'undefined'
          ? moment(deadline).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '拆零单价',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      dataIndex: 'beforeSplitPrice',
      render: (_, t) => {
        return t.beforeSplitPrice?.toString()
          ? parseFloat(t.beforeSplitPrice).toFixed(5)
          : ''
      },
    },
    {
      title: '整存单价',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      dataIndex: 'beforeRetailPrice',
      render: (_, t) => {
        return t.beforeRetailPrice?.toString()
          ? parseFloat(t.beforeRetailPrice).toFixed(5)
          : ''
      },
    },
    {
      title: '调后拆零单价',
      align: 'right',
      width: '7rem',
      ellipsis: true,
      editable: true,
      dataIndex: 'splitPrice',
      nextDataIndex: 'retailPrice',
      render: (_, t) => {
        return t.splitPrice?.toString()
          ? parseFloat(t.splitPrice).toFixed(5)
          : ''
      },
      onSave: (prev, next) => {
        onclick(prev.beforeSplitPrice?.toString() ? 'edit' : '', { prev, next })
      },
    },
    {
      title: '调后单价',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      editable: true,
      dataIndex: 'retailPrice',
      render: (_, t) => {
        return t.retailPrice?.toString()
          ? parseFloat(t.retailPrice).toFixed(5)
          : ''
      },
      onSave: (prev, next) => {
        onclick('edit', { prev, next })
      },
    },
  ]
}
