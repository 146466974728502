/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Form, Input, Modal, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { unwrapResult } from '@reduxjs/toolkit'
import styles from './addFollowUp.module.css'
import { RootDispatch } from '../../app/store'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import {
  getFollowUpTypeList,
  getFollowUpTypeSubmitAsyncs,
  getRemoveFollowUpType,
} from './followUpPageSlice'
import cnchar from 'cnchar'
import { stringTrim } from '../../utils/StringUtils'
import { RefreshFilled } from '../../compnents/icons/Icons'

export const SettingFollowUpTypeListModal = (props: {
  isSettingFollowUpTypeListModalVisible: boolean
  refresh: () => void
  onOk: () => void
  onCancel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [followUpTypeList, setFollowUpTypeList] = useState<any>([]) //随访类型list

  const [indexs, setIndex] = useState(0)

  const followUpTypeData = followUpTypeList?.map((v: any) => {
    return v?.name
  })

  // 随访类型
  useEffect(() => {
    if (props?.isSettingFollowUpTypeListModalVisible)
      dispatch(getFollowUpTypeList())
        .then(unwrapResult)
        .then((res) => {
          setFollowUpTypeList(res)
        })
  }, [dispatch, props?.isSettingFollowUpTypeListModalVisible])

  useEffect(() => {
    if (followUpTypeList?.length) {
      form.setFieldsValue({
        followUpType: [...followUpTypeData],
      })
    } else {
      form.setFieldsValue({
        followUpType: [''],
      })
    }
  }, [followUpTypeList])

  return (
    <div>
      <Modal
        className={styles.model}
        title={'随访类型设置'}
        visible={props.isSettingFollowUpTypeListModalVisible}
        okText='确认'
        cancelText='取消'
        onOk={() => {
          form.validateFields().then((values) => {
            const value = Object.values(values).flat(2)
            const index = value.findIndex((v) => v === '' || v === undefined)
            const newDate = index === -1 ? value : value.slice(0, index)
            const param: any = newDate?.map((v, index) => {
              return [{ name: v, id: followUpTypeList[index]?.id }]
            })
            dispatch(getFollowUpTypeSubmitAsyncs(param?.flat(1)))
              .then(unwrapResult)
              .then(() => {
                notification.success({ message: '保存成功', duration: 3 })
                props?.refresh()
              })
              .catch(() => {
                notification.error({ message: '保存失败', duration: 3 })
              })
            form.resetFields()
            props.onOk()
          })
        }}
        onCancel={() => {
          props?.refresh()   //更新数据
          form.resetFields()
          props.onCancel()
          form.setFieldsValue({
            followUpType: [''],
          })
        }}
        width='450px'
      >
        <Form
          name='dynamic_form_item'
          form={form}
          autoComplete='off'
          onFinish={(vs) => {
            form.resetFields()
            props.onOk()
          }}
        >
          <Form.List
            name='followUpType'
            rules={[
              {
                validator: async (_, followUpType) => {
                  if (!followUpType || followUpType.length < 1) {
                    return Promise.reject(new Error('随访类型不能为空'))
                  }
                },
              },
            ]}
          >
            {(fields: any, { add, remove }) => {
              return (
                <>
                  {fields.map((field: any, index: any) => (
                    <Form.Item
                      className={styles.name}
                      style={{ marginLeft: '20px' }}
                      label={'随访类型' + (index + 1)}
                      required
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        required
                        getValueFromEvent={stringTrim}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            ...(fields.length > 1
                              ? {
                                  required: true,
                                  message: '随访类型大于一条时不能为空',
                                }
                              : ''),
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder='请输入随访类型'
                          style={{ width: '60%' }}
                          maxLength={25}
                          onChange={(e) => {
                            setIndex(index)
                          }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <a
                          className={styles.add}
                          onClick={() => {
                            let tempFlag;
                            form.getFieldsValue(true).followUpType.forEach((item: any, num: number) => {
                              if (index == num && !item) {
                                tempFlag = true
                                remove(num)
                                notification.success({
                                  message: '删除成功',
                                  duration: 3,
                                })
                                return
                              }

                            })
                            if (tempFlag) return
                            remove(field.name)
                            const param = followUpTypeList?.[index]?.id
                            dispatch(getRemoveFollowUpType(param))
                              .then(unwrapResult)
                              .then(() => {
                                notification.success({
                                  message: '删除成功',
                                  duration: 3,
                                })
                                dispatch(getFollowUpTypeList())
                                  .then(unwrapResult)
                                  .then((res) => {
                                    setFollowUpTypeList(res)
                                  })
                              })
                          }}
                        >
                          <MinusOutlined
                            style={{ marginLeft: '10px', color: '#101010' }}
                          />
                        </a>
                      ) : null}
                      {fields.length === index + 1 ? (
                        <a onClick={() => add()}>
                          <PlusOutlined
                            style={{ marginLeft: '10px', color: '#101010' }}
                          />
                        </a>
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item></Form.Item>
                </>
              )
            }}
          </Form.List>
        </Form>
      </Modal>
    </div>
  )
}
