/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-05-31 15:46:05
 * @LastEditors: sj
 * @LastEditTime: 2022-06-08 17:31:49
 */
export const getQueryName = (v: number): string => {
  switch (v) {
    case 2001:
      return '人员待遇享受检查'
    case 5101:
      return '医保科室信息查询'
    case 5102:
      return '医执人员信息查询'
    case 7014:
      return '人员慢特病诊断备案查询'
    case 5201:
      return '就诊信息查询'
    case 5202:
      return '诊断信息查询'
    case 5203:
      return '结算信息查询'
    case 5204:
      return '费用信息查询'
    case 5205:
      return '人员慢特病用药记录查询'
    case 5302:
      return '人员定点信息查询'
    case 5303:
      return '在院信息查询'
    case 5304:
      return '转院信息查询'
    case 5401:
      return '项目互认信息查询'
    case 5402:
      return '报告明细信息查询'
    case 1399:
      return '药品目录下载（文件类）'
    case 1316:
      return '医疗目录与医保目录匹配信息下载'
    case 1317:
      return '医药机构目录匹配信息下载'
    case 1901:
      return '字典表查询'
    case 2601:
      return '冲正'
    case 1163:
      return '修改医保卡密码'
    case 1201:
      return '查询医药机构信息'
    case 3260:
      return '机构对账权限查询'
    case 3261:
      return '机构月度对账状态查询'
    default:
      return ''
  }
}

export const personInfoQuery = [
  { value: '01', label: '门诊慢特病登记' },
  { value: '03', label: '就医定点医疗机构登记' },
  { value: '05', label: '家庭医生签约登记' },
  { value: '07', label: '异地急诊住院登记' },
  { value: '09', label: '特检特治登记' },
  { value: '11', label: '长期带药登记' },
  { value: '13', label: '生育备案登记' },
  { value: '15', label: '临时医疗救助人员资格认定' },
  { value: '17', label: '按病种结算登记' },
  { value: '19', label: '退费备案登记' },
  { value: '88', label: '非差异化登记' },
  { value: '97', label: '产前检查选点' },
  { value: '990105', label: '重特大疾病登记' },
  { value: '9907', label: '医保个人账户取消结转' },
  { value: '9911', label: '住院跨县域审批' },
  { value: '9912', label: '生育跨县域审批' },
  { value: '991301', label: '转省外就医' },
  { value: '9915', label: '隔次退票审批' },
  { value: '991701', label: '城乡两病认定' },
  { value: '992', label: '居民门诊定点登记' },
  { value: '992701', label: '取消结算审批' },
  { value: '992901', label: '本地住院登记审批' },
  { value: '993001', label: '起付线审批' },
  { value: '993201', label: '离休人员不能使用账户备案' },
  { value: '995', label: '重大疾病登记' },
  { value: '02', label: '意外伤害登记' },
  { value: '04', label: '家庭病床登记' },
  { value: '06', label: '转诊转院登记' },
  { value: '08', label: '异地安置登记' },
  { value: '10', label: '特殊药品登记' },
  { value: '12', label: '代购药品登记' },
  { value: '14', label: '账户备案登记' },
  { value: '16', label: '年度救助备案登记' },
  { value: '18', label: '无医保凭证结算备案登记' },
  { value: '20', label: '外检外购登记' },
  { value: '96', label: '两病三师选点' },
  { value: '99', label: '其他' },
  { value: '9906', label: '医保个人账户结转' },
  { value: '991', label: '门诊两病登记' },
  { value: '991101', label: '居民门诊两病' },
  { value: '9913', label: '门诊手术跨县域审批' },
  { value: '9914', label: '住院登记审批' },
  { value: '9916', label: '门诊统筹审批' },
  { value: '991801', label: '交叉住院' },
  { value: '9926 ', label: '离休备案定点' },
  { value: '992801', label: '门诊重症病' },
  { value: '993', label: '离休人员门诊定点登记' },
  { value: '993101', label: '中心报销不按结算次序退票' },
  { value: '994', label: '择日住院备案' },
  { value: '996', label: '延时就医审批' },
]

export const medTypeOption = [
  { value: '11', label: '普通门诊' },
  { value: '12', label: '门诊挂号' },
  { value: '1401041', label: '门诊慢病' },
  { value: '140201', label: '门诊特病' },
]
export const fixmedinsType = [
  { value: '1', label: '定点医疗机构' },
  { value: '2', label: '定点零售药店' },
  { value: '3', label: '工伤定点康复机构' },
  { value: '4', label: '辅助器具配置机构' },
  { value: '5', label: '计划生育服务机构' },

]