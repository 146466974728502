import { TableColumnType } from 'antd'
import React from 'react'
import { Dimen } from '../../../models/dimen'
export const DrugStaticColumns = (
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
      width: Dimen.Num,
    },
    {
      title: '药品名称',
      dataIndex: '',
      align: 'left',
      key: 'name',
      render: function Element(_, record) {
        return (
          <div style={{ height: '55px' }}>
            <p
              style={{
                height: '28px',
                lineHeight: '36px',
                padding: '0',
                margin: '0',
                fontSize: '16px',
                fontWeight: 'bolder',
              }}
            >
              {record.name}
            </p>
            <p
              style={{
                height: '28px',
                lineHeight: '24px',
                padding: '0',
                margin: '0',
                color: '#999999',
                fontSize: '14px',
              }}
            >
              {record.tenantSupplierName} / {record.packSpec} /
              {record.accountUnit}/{record.doseCategory}
            </p>
          </div>
        )
      },
    },
    {
      title: '开票项目',
      dataIndex: 'billingCategory',
      width: Dimen.BillingCategory,
      align: 'center',
      key: 'billingCategory',
    },
    // {
    //   title: "进货单价",
    //   dataIndex: "costUnit",
    //   align: "right",
    //   key: "costUnit",
    //   render: (_: any, t: any) => {
    //     return t.costUnit
    //       ? parseFloat((t.costUnit as unknown) as string).toFixed(5)
    //       : 0.0;
    //   },
    // },
    // {
    //   title: "销售单价",
    //   dataIndex: "retailUnit",
    //   align: "right",
    //   key: "retailUnit",
    //   render: (_: any, t: any) => {
    //     return t.retailUnit
    //       ? parseFloat((t.retailUnit as unknown) as string).toFixed(5)
    //       : 0.0;
    //   },
    // },

    // 1.4.0需求
    {
      title: '库存数量',
      children: [
        {
          title: '整存数量',
          dataIndex: 'invWholeCount',
          width: Dimen.Count,
          align: 'center',
          key: 'invWholeCount',
          render: function Element(_: any, record: any) {
            return (
              <div>
                <p>
                  {record.invWholeCount} {record.accountUnit}
                </p>
              </div>
            )
          },
        },
        {
          title: '拆零数量',
          dataIndex: 'invSplitCount',
          width: Dimen.Count,
          align: 'center',
          key: 'invSplitCount',
          render: function Element(_: any, record: any) {
            return (
              <div>
                <p>
                  {record.invSplitCount} {record.splitUnit}
                </p>
              </div>
            )
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '销售数量',
      children: [
        {
          title: '整存数量',
          dataIndex: 'wholeCount',
          width: Dimen.Count,
          align: 'center',
          key: 'wholeCount',
          render: function Element(_: any, record: any) {
            return (
              <div>
                <p>
                  {record.wholeCount} {record.accountUnit}
                </p>
              </div>
            )
          },
        },
        {
          title: '拆零数量',
          dataIndex: 'splitCount',
          width: Dimen.Count,
          align: 'center',
          key: 'splitCount',
          render: function Element(_: any, record: any) {
            return (
              <div>
                <p>
                  {record.splitCount} {record.splitUnit}
                </p>
              </div>
            )
          },
        },
      ],
    } as TableColumnType<any>,

    {
      title: '药品成本',
      children: [
        {
          title: '整存成本',
          dataIndex: 'wholeCost',
          align: 'right',
          width: Dimen.Count,
          key: 'wholeCost',
          render: (_: any, t: any) => {
            return t.wholeCost ? t.wholeCost: '0.00'
          },
        },
        {
          title: '拆零成本',
          align: 'right',
          dataIndex: 'splitCost',
          width: Dimen.Count,
          key: 'splitCost',
          render: (_: any, t: any) => {
            return t.splitCost ? t.splitCost : '0.00'
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '销售额',
      children: [
        {
          title: '整存销售额',
          align: 'right',
          dataIndex: 'wholeRetail',
          width: Dimen.Count,
          key: 'wholeRetail',
          render: (_: any, t: any) => {
            return t.wholeRetail ? t.wholeRetail : '0.00'
          },
        },
        {
          title: '拆零销售额',
          align: 'right',
          dataIndex: 'splitRetail',
          width: Dimen.Count,
          key: 'splitRetail',
          render: (_: any, t: any) => {
            return t.splitRetail ?
              t.splitRetail
              : '0.00'
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '利润',
      align: 'right',
      dataIndex: 'profit',
      width: Dimen.Profit,
      key: 'profit',
      render: (_: any, t: any) => {
        return t.profit
          ? t.profit
          : '0.00'
      },
    },
    // {
    //   title: '供应商',
    //   align: 'center',
    //   dataIndex: 'vendorName',
    //   width: Dimen.Profit,
    //   key: 'vendorName',
    //   render: (_: any, t: any) => {
    //     return t.vendorName ? t?.vendorName : '-'
    //   },
    // },
  ]
}
