import {
  CaretDownFilled,
  CaretDownOutlined,
  CaretRightFilled,
  CaretRightOutlined,
  DownOutlined,
  FrownFilled,
  FrownOutlined,
  MehOutlined,
  SettingOutlined,
  SmileOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
  Tree,
} from 'antd'
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { payType } from '../../../../models/membersPage'
import styles from '../ToothHome.module.css'
import {
  getMessageCount,
  getMessageTemplate,
  getPatientPhoneAsync,
  getSignature,
  selectMessageCount,
  selectSendIDs,
  selectSignatureList,
  sendSmsPatientAsync,
  setSendIDs,
  setSignatureList,
} from '../toothHomeSlice'
import { MessageSignatureModal } from './messageSignatureModal'
import Qrcode from 'qrcode.react'
import { ThemeContext } from '../../../../theme/ThemeContext'
import {
  payStatusAsync,
  QRCodeAsync,
} from '../../../productCenter/productCenterSlice'
import moment from 'moment'
import { MorePatient } from './morePatient'
import {
  BoyIcon,
  FemaleFilled,
  GirlIcon,
  MaleFilled,
} from '../../../../compnents/icons/Icons'
import { Gender, getGenderName } from '../../../../models/user'

const { Panel } = Collapse

const { TextArea } = Input

let productTimer: any = null
interface sendMessageModalProps {
  visible: boolean
  onCancel: () => void
  onOk: () => void
  sendMsgObj?: any
  isBirthday?: boolean
}
export const SendMessageModal = ({
  visible,
  onCancel,
  onOk,
  sendMsgObj,
  isBirthday = false,
}: sendMessageModalProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const messageCount = useSelector(selectMessageCount)

  const selectedKeys = useSelector(selectSendIDs)

  const dispatch = useDispatch<RootDispatch>()

  const signatureList = useSelector(selectSignatureList)

  const [templateList, setTemplateList] = useState<any[]>([]) //  短信模板

  const [templateValue, setTemplateValue] = useState<any>('') //  短信内容

  const [selectTemplateId, setSelectTemplateId] = useState('')

  const [messageSignatureModal, setMessageSignatureModal] = useState(false) // 短信签名弹窗

  const [QRCodeVisible, setQRCodeVisible] = useState(false)

  const [loading, setLoading] = useState(false)

  const [payType, setpayType] = useState(1)

  const [payInfo, setPayInfo] = useState<any>({})

  const [messageSign, setMessageSign] = useState('')

  const [timeSendCheck, setTimeSendCheck] = useState(false)

  const [timeSend, setTimeSend] = useState('')

  const [MorePatientVisible, setMorePatientVisible] = useState(false)

  const [phoneList, setPhoneList] = useState<any[]>([])

  const [selectPhone, setSelectPhone] = useState('')

  useEffect(() => {
    return () => {
      productTimer && clearInterval(productTimer)
      productTimer = null
    }
  }, [])

  useEffect(() => {
    if (sendMsgObj && visible) {
      dispatch(getPatientPhoneAsync(sendMsgObj.id))
        .then(unwrapResult)
        .then((res: any) => {
          setSelectPhone(res[0] || '')
          setPhoneList(res)
        })
    }
  }, [sendMsgObj, visible])

  const getMessageTemplateFn = () => {
    //获取短信模板
    dispatch(getMessageTemplate())
      .then(unwrapResult)
      .then((res) => {
        if (isBirthday) {
          const birthday = res.find((v: any) => v.templateType == 1)
            ?.smsTemplateVOList[0]
          setSelectTemplateId(birthday?.id)
          setTemplateValue(birthday?.content || '')
        }
        setTemplateList(res)
      })
  }

  const getSignatureList = () => {
    dispatch(getSignature())
      .then(unwrapResult)
      .then((res: any) => {
        setMessageSign(res[0].signName)
        dispatch(setSignatureList(res))
      })
  }

  useEffect(() => {
    if (visible) {
      getMessageTemplateFn()
      getSignatureList()
    }
  }, [visible])

  useEffect(() => {
    if (!QRCodeVisible) return
    setLoading(true)
    productTimer && clearInterval(productTimer)
    getQrcode()
    return () => {
      productTimer && clearInterval(productTimer)
      productTimer = null
    }
  }, [payType])

  useEffect(() => {
    productTimer && clearInterval(productTimer)
    productTimer = null
    if (!QRCodeVisible) {
      setpayType(1)
    }
  }, [QRCodeVisible])

  useEffect(() => {
    productTimer && clearInterval(productTimer)
    productTimer = null
    if (!QRCodeVisible) {
      setpayType(1)
    }
  }, [QRCodeVisible])

  const getUnit = (v: number): string => {
    switch (v) {
      case 0:
        return '年'
      case 1:
        return '天'
      case 2:
        return '次'
      case 3:
        return '个'
      case 4:
        return '1个医生'
      default:
        return '年'
    }
  }

  const range = (start: number, end: number) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  const getQrcode = (productName = '短信', durationUnit = 3) => {
    const data = {
      payType,
      productId: 14,
      durationNum: 1,
    }
    dispatch(QRCodeAsync(data))
      .then(unwrapResult)
      .then((res: any) => {
        setPayInfo(res)
        res.productName = productName
        res.durationUnit = getUnit(durationUnit)
        setQRCodeVisible(true)
        productTimer && clearInterval(productTimer)
        setLoading(false)
        productTimer = setInterval(() => {
          dispatch(payStatusAsync(res.orderId))
            .then(unwrapResult)
            .then((res: any) => {
              if (res.status == 1) {
                setQRCodeVisible(false)
                dispatch(getMessageCount())
                productTimer && clearInterval(productTimer)
                productTimer = null
              }
            })
        }, 1000)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const close = useCallback(() => {
    onCancel()
    setTimeSendCheck(false)
    setMessageSign('')
    setTimeSend('')
    setMessageSign('')
    setTemplateValue('')
    setSelectPhone('')
    setSelectTemplateId('')
  }, [])

  return (
    <Modal
      title='发送短信'
      width={900}
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose
      centered
      wrapClassName={styles.sendMessageModal}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <div className={styles.sendModalWrap}>
        {/* 短信模板区域 */}
        <div style={{ overflow: 'hidden', width: 250 }}>
          <div style={{ overflow: 'auto', height: '100%' }}>
            {templateList.map((v: any) => {
              return (
                <Collapse
                  key={v.templateType}
                  style={{ width: 240 }}
                  className={styles.templates}
                  bordered={false}
                  defaultActiveKey={isBirthday ? 1 : ''}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                >
                  <Panel header={v.directory} key={v.templateType}>
                    {v.smsTemplateVOList.map(
                      (el: any, index: number, arr: any) => {
                        if (el.type == 0) {
                          return (
                            <Tooltip
                              placement='left'
                              title={el.content}
                              key={index}
                            >
                              <div
                                className={styles.cellItem}
                                onClick={() => {
                                  setSelectTemplateId(el.id)
                                  setTemplateValue(el.content || '')
                                }}
                                style={{
                                  color:
                                    el.id == selectTemplateId
                                      ? theme.c1
                                      : '#000',
                                }}
                              >
                                {el.content}
                              </div>
                            </Tooltip>
                          )
                        } else {
                          return (
                            <div key={index}>
                              <div
                                onClick={() => {
                                  const list = templateList.map((x) =>
                                    x.templateType == v.templateType
                                      ? {
                                          ...x,
                                          smsTemplateVOList: x.smsTemplateVOList.map(
                                            (c: any, i: number) => ({
                                              ...c,
                                              open:
                                                index == i ? !c.open : c.open,
                                            })
                                          ),
                                        }
                                      : x
                                  )
                                  setTemplateList(list)
                                }}
                                className={styles.cellTitle}
                              >
                                {el.open ? (
                                  <CaretDownFilled />
                                ) : (
                                  <CaretRightFilled />
                                )}
                                {el.directory}
                              </div>
                              {el.open &&
                                el.smsTemplateVOS.length &&
                                el.smsTemplateVOS.map((m: any) => {
                                  return (
                                    <>
                                      <Tooltip
                                        placement='left'
                                        title={m.content}
                                      >
                                        <div
                                          key={m.id}
                                          className={styles.cellItem}
                                          onClick={() => {
                                            setSelectTemplateId(m.id)
                                            setTemplateValue(m.content || '')
                                          }}
                                          style={{
                                            color:
                                              m.id == selectTemplateId
                                                ? theme.c1
                                                : '#000',
                                          }}
                                        >
                                          {m.content}
                                        </div>
                                      </Tooltip>
                                    </>
                                  )
                                })}
                            </div>
                          )
                        }
                      }
                    )}
                  </Panel>
                </Collapse>
              )
            })}
          </div>
        </div>

        {/* 中间编辑区域 */}
        <div className={styles.sendCenterWrap}>
          {!sendMsgObj ? (
            <div className={styles.centerText}>
              已选择 {selectedKeys.length} 位患者
              <Button
                type='link'
                onClick={() => {
                  setMorePatientVisible(true)
                  // dispatch(setSendIDs(selectedKeys))
                }}
              >
                继续添加
              </Button>
            </div>
          ) : (
            <div>
              <div className={styles.patientInfo}>
                <div className={styles.patientAvrter}>
                  {sendMsgObj?.sex == Gender.Female ? (
                    <GirlIcon
                      style={{
                        width: 45,
                        height: 45,
                      }}
                    />
                  ) : (
                    <BoyIcon
                      style={{
                        width: 45,
                        height: 45,
                      }}
                    />
                  )}
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Tooltip title={sendMsgObj.name}>
                      <span
                        style={{
                          fontSize: 20,
                          marginRight: 5,
                          display: 'inline-block',
                          maxWidth: '60%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {sendMsgObj.name}
                      </span>
                    </Tooltip>
                    <span
                      style={{
                        display: 'inline-block',
                      }}
                    >
                      ({getGenderName(sendMsgObj.sex)}/
                      {sendMsgObj.ageYear > 0 ? sendMsgObj.ageYear : 0}岁)
                    </span>
                  </div>

                  {sendMsgObj.phone}
                </div>
              </div>
              <div style={{ margin: '20px 0' }}>
                <span style={{ paddingLeft: 15 }}>手机号</span>
                <Select
                  disabled={isBirthday}
                  placeholder='请选择'
                  allowClear
                  value={selectPhone}
                  style={{ margin: '0 20px', width: 200 }}
                  onChange={(v) => {
                    setSelectPhone(v)
                  }}
                >
                  {phoneList?.map((v: any) => {
                    return (
                      <Select.Option key={v} value={v}>
                        {v}
                      </Select.Option>
                    )
                  })}
                </Select>
              </div>
            </div>
          )}
          <div
            style={{ margin: '20px 0', display: 'flex', alignItems: 'center' }}
          >
            <span>短信签名</span>
            <Select
              placeholder='请选择'
              allowClear
              value={messageSign}
              style={{ margin: '0 20px', width: 200 }}
              onChange={(v) => {
                setMessageSign(v)
              }}
            >
              {signatureList?.map((v: any) => {
                return (
                  <Select.Option key={v.id} value={v.signName}>
                    {v.signName}
                  </Select.Option>
                )
              })}
            </Select>
            <span
              style={{
                cursor: 'pointer',
                color: '#999999',
                fontSize: '20px',
                marginTop: 2,
              }}
              onClick={() => {
                setMessageSignatureModal(true)
              }}
            >
              <SettingOutlined />
            </span>
          </div>
          <div className={styles.centerText}>
            短信内容一共{templateValue.length}个字符(预计按{' '}
            {Math.ceil(templateValue.length / 70)} 条计算，70字符/条 )
          </div>
          <div style={{ marginBottom: 20 }}>
            <TextArea
              style={{
                backgroundColor: '#E7EFFD',
              }}
              value={templateValue}
              placeholder='请输入...'
              rows={7}
              onChange={(e) => {
                setTemplateValue(e.target.value.trim())
              }}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <Checkbox
              style={{ marginTop: 5 }}
              checked={timeSendCheck}
              onChange={(e) => {
                setTimeSendCheck(e.target.checked)
                setTimeSend('')
              }}
            >
              定时发送
            </Checkbox>
            {timeSendCheck && (
              <DatePicker
                showNow={false}
                disabledDate={(date) => {
                  return moment(date).isBefore(moment().subtract(1, 'day'))
                }}
                // disabledTime={(date) => {
                //   if (moment(date).format('YYYY/MM/DD') == (moment().format('YYYY/MM/DD'))) {
                //     const hour = moment().hours(); // 获取当前的小时
                //     const min = moment().minutes(); // 获取当前的分钟
                //     return {
                //       disabledHours: () => range(0, 24).splice(0, hour),
                //       disabledMinutes: () => range(0, 60).splice(0, min),
                //     };
                //   } else {
                //     return {
                //       disabledHours: () => [],
                //       disabledMinutes: () => [],
                //     };
                //   }
                // }}
                showTime={{ format: 'HH:mm' }}
                format='YYYY-MM-DD HH:mm'
                onChange={(value, date) => {
                  setTimeSend(date)
                }}
              />
            )}
          </div>
        </div>

        <div className={styles.messageImg}>
          {templateValue && (
            <div className={styles.messageImgInfo}>
              {`【${messageSign} 】 ${templateValue}`}
            </div>
          )}
        </div>
      </div>
      {timeSendCheck && (
        <div style={{ display: 'flex', marginLeft: '250px' }}>
          请注意：定时发送只能设置10分钟以后的时间，否则将立即发送。
        </div>
      )}
      <Divider
        style={{
          margin: '10px 0',
        }}
      />
      <div className={styles.sendModalFooter}>
        <Space>
          <p style={{ marginTop: 15 }}>
            短信套餐剩余:{' '}
            <span style={{ color: '#2a7ef7' }}>{messageCount}</span>条
          </p>
          <Button
            type='primary'
            onClick={() => {
              getQrcode()
            }}
          >
            短信购买
          </Button>
        </Space>
        <Space>
          <Button onClick={close}>取消</Button>
          <Button
            type='primary'
            onClick={() => {
              if (!selectedKeys.length && !sendMsgObj) {
                notification.info({
                  message: '请选择需要发送的患者',
                })
                return
              }
              if (!messageSign) {
                notification.info({
                  message: '请选择短信签名',
                })
                return
              }
              if (!templateValue) {
                notification.info({
                  message: '请输入发送内容',
                })
                return
              }
              if (timeSendCheck && !timeSend) {
                notification.info({
                  message: '请选择发送时间',
                })
                return
              }
              if (sendMsgObj && !selectPhone) {
                notification.info({
                  message: '请选择要发送的手机号',
                })
                return
              }
              dispatch(
                sendSmsPatientAsync({
                  patientIds: !sendMsgObj ? selectedKeys : undefined,
                  sign: messageSign,
                  smsContent: templateValue,
                  sendTime: timeSendCheck ? timeSend + ':00' : undefined,
                  phone: sendMsgObj ? selectPhone : undefined,
                  patientName: sendMsgObj ? sendMsgObj.name : undefined,
                  id: selectTemplateId,
                  templateType:
                    templateList.find((v: any) => v.templateType == 1)
                      ?.smsTemplateVOList[0]?.id == selectTemplateId
                      ? 1
                      : 0,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: '操作成功',
                  })
                  dispatch(getMessageCount())
                  dispatch(setSendIDs([]))
                  onOk()
                  close()
                })
            }}
          >
            发送
          </Button>
        </Space>
      </div>

      <MessageSignatureModal
        visible={messageSignatureModal}
        onCancel={() => {
          dispatch(setSendIDs([]))
          setMessageSignatureModal(false)
        }}
      />

      {/* 更多患者 */}
      <MorePatient
        visible={MorePatientVisible}
        onCancel={() => {
          setMorePatientVisible(false)
        }}
        onOk={() => {
          setMorePatientVisible(false)
        }}
      />

      <Modal
        width={500}
        className={styles.qrCodeModal}
        style={{ top: 200 }}
        footer={null}
        title='扫码付款'
        visible={QRCodeVisible}
        maskClosable={false}
        keyboard={false}
        onCancel={() => {
          setQRCodeVisible(false)
        }}
      >
        <Row justify='center'>
          <p style={{ marginBottom: '0px', fontSize: '17px', color: 'gray' }}>
            您正在购买【{payInfo.productName}】服务包，数量 1/
            {payInfo.durationUnit}
          </p>
          <p style={{ fontSize: '15px', color: 'gray' }}>
            支付成功后，系统会自动处理您的订单。
          </p>
        </Row>
        <Row justify='center'>
          <Radio.Group
            value={payType}
            buttonStyle='solid'
            onChange={(e) => {
              setpayType(e.target.value)
            }}
          >
            <Radio.Button
              disabled={loading}
              value={1}
              style={{ width: 150, textAlign: 'center' }}
            >
              微信
            </Radio.Button>
            <Radio.Button
              disabled={loading}
              value={3}
              style={{ width: 150, textAlign: 'center' }}
            >
              支付宝
            </Radio.Button>
          </Radio.Group>
        </Row>
        <Row style={{ margin: '20px 0' }} justify='center'>
          <Qrcode
            value={payInfo.payCode}
            color={theme.tc1}
            size={256}
            style={{
              width: 256,
              height: 256,
            }}
          />
        </Row>
        <Row justify='center'>（ 付款金额：{payInfo.money}元 ）</Row>
      </Modal>
    </Modal>
  )
}
