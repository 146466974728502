/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-05-31 15:04:36
 * @LastEditors: sj
 * @LastEditTime: 2022-06-08 17:29:55
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import * as S from '../../services/otherInsurance'
import {
  accountReconciliationPermissionQuery,
  changeCardPwd,
  downloadCate1399,
  getTenantInfo, monthlySummaryStatusQuery,
  queryDyInfo,
  search1316, search1317,
  zdSearch1901
} from "../../services/otherInsurance";

// 科室信息查询 5101
export const departmentInfoQueryAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/departmentInfoQueryAsync', async (body, api) => {
  return sendAsync(S.departmentInfoQuery(body), api)
})

// 医执人员信息查询 5102
export const medicalExecutiveAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/medicalExecutiveAsync', async (body, api) => {
  return sendAsync(S.medicalExecutive(body), api)
})
// 人员待遇享受检查 2001
export const queryDyInfoAsync = createAsyncThunk<
    any,
    S.OtherInsuranceBody,
    RootThunkApi
    >('/otherInsurance/queryDyInfoAsync', async (body, api) => {
  return sendAsync(S.queryDyInfo(body), api)
})
// 就诊信息查询 5201
export const queryMdtrtInfoAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/queryMdtrtInfoAsync', async (body, api) => {
  return sendAsync(S.queryMdtrtInfo(body), api)
})
// 诊断信息查询 5202
export const querySetlDiseListAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/querySetlDiseListAsync', async (body, api) => {
  return sendAsync(S.querySetlDiseList(body), api)
})
// 结算信息查询 5203
export const querySetlInfoAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/querySetlInfoAsync', async (body, api) => {
  return sendAsync(S.querySetlInfo(body), api)
})
// 费用信息查询 5204
export const queryFeeListAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/queryFeeListAsync', async (body, api) => {
  return sendAsync(S.queryFeeList(body), api)
})
// 人员慢特病用药记录查询 5205
export const querypsnmedcrcdAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/querypsnmedcrcdAsync', async (body, api) => {
  return sendAsync(S.querypsnmedcrcd(body), api)
})

// 人员定点信息查询 5302
export const queryPersonalInsuranceAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/queryPersonalInsuranceAsync', async (body, api) => {
  return sendAsync(S.queryPersonalInsurance(body), api)
})

// 在院信息查询 5303
export const inHospitalInfoQueryAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/inHospitalInfoQueryAsync', async (body, api) => {
  return sendAsync(S.inHospitalInfoQuery(body), api)
})

// 转院信息查询 5304
export const transferInfoQueryAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/transferInfoQueryAsync', async (body, api) => {
  return sendAsync(S.transferInfoQuery(body), api)
})

// 项目互认信息查询 5401
export const mutualInfoQueryAsync = createAsyncThunk<
    string,
    S.OtherInsuranceBody,
    RootThunkApi
    >('/otherInsurance/mutualInfoQueryAsync', async (body, api) => {
  return sendAsync(S.mutualInfoQuery(body), api)
})

// 修改卡密码 1163
export const changeCardPwdAsync = createAsyncThunk<
    string,
    S.OtherInsuranceBody,
    RootThunkApi
    >('/otherInsurance/changeCardPwdAsync',  (body, api) => {
  return sendAsync(S.changeCardPwd(body), api)
})

// 获取机构信息 1201
export const getTenantInfoAsync = createAsyncThunk<
    any,
    S.OtherInsuranceBody,
    RootThunkApi
    >('/otherInsurance/getTenantInfoAsync',  (body, api) => {
  return sendAsync(S.getTenantInfo(body), api)
})
// 1316
export const search1316Async = createAsyncThunk<
    any,
    S.OtherInsuranceBody,
    RootThunkApi
    >('/otherInsurance/search1316Async',  (body, api) => {
  return sendAsync(S.search1316(body), api)
})
// 1317
export const search1317Async = createAsyncThunk<
    any,
    S.OtherInsuranceBody,
    RootThunkApi
    >('/otherInsurance/search1317Async',  (body, api) => {
  return sendAsync(S.search1317(body), api)
})

// 字典表查询 1901
export const searchZd1901 = createAsyncThunk<
    any,
    S.OtherInsuranceBody,
    RootThunkApi
    >('/otherInsurance/searchZd1901',  (body, api) => {
  return sendAsync(S.zdSearch1901(body), api)
})

// 西药目录下载 1301
export const downloadCate1301Async = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/downloadCate1301',  (body, api) => {
  return sendAsync(S.downloadCate1301(body), api)
})

// 西药目录下载 1399
export const downloadCate1399Async = createAsyncThunk<
    string,
    S.OtherInsuranceBody,
    RootThunkApi
    >('/otherInsurance/downloadCate1399',  (body, api) => {
  return sendAsync(S.downloadCate1399(body), api)
})

// 报告信息查询 5402
export const detailInfoQueryAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/detailInfoQueryAsync', async (body, api) => {
  return sendAsync(S.detailInfoQuery(body), api)
})

// 人员慢特病诊断备案查询 7014
export const diseaseRecordQueryAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/diseaseRecordQueryAsync', async (body, api) => {
  return sendAsync(S.diseaseRecordQuery(body), api)
})

//
export const monthlySummaryStatusQueryAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/monthlySummaryStatusQueryAsync', async (body, api) => {
  return sendAsync(S.monthlySummaryStatusQuery(body), api)
})

export const accountReconciliationPermissionQueryAsync = createAsyncThunk<
  string,
  S.OtherInsuranceBody,
  RootThunkApi
>('/otherInsurance/accountReconciliationPermissionQueryAsync', async (body, api) => {
  return sendAsync(S.accountReconciliationPermissionQuery(body), api)
})
