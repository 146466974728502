/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-03-11 16:26:21
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-11 16:43:11
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface RemoteVerificationFormListParams {
  startTime?: any
  endTime?: any
  yidibz?: any
}

export function RemoteVerificationFormList(
  params: RemoteVerificationFormListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getAppropriationYD`,
    method: 'GET',
    params,
  }
}
