/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-20 17:56:26
 * @LastEditors: sj
 * @LastEditTime: 2022-12-22 09:41:19
 */
import { Form, Row, Col, Input, Button, Select, Space } from 'antd'
import { CaretDownFilled } from '@ant-design/icons'
import { SearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { DateTimeFormatSimpleOnly } from '../../models/datetime'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
  RecentTime,
} from '../../compnents/form/DateSelect'
import { AddPreviewingModal } from '../A-toothModule/toothHome/modal/addPreviewingModal'

const { Option } = Select

export const ListQuery = (props: {
  type: number
  onValueChange: (queries: any) => void
  onExport: () => void
  ids: any
}) => {
  const [form] = Form.useForm()

  const [head, tail] = getDateRange(DateRangeType.Today)

  const history = useHistory()

  const [addPreviewingModal, setAddPreviewingModal] = useState(false) // 口腔版 his 新增预检纷争


  useEffect(() => {
    // if (props.type != 1) return
    form.setFieldsValue({
      ...storageParams,
      _dateRange:storageParams?._dateRange||DateRangeType.Today,
      TimeHead: storageParams?.TimeHead||head,
      TimeTail: storageParams?.TimeTail||tail,
    })
    form.submit()
  }, [])

  const storageParams = JSON.parse(
      sessionStorage.getItem(location.pathname+"_yujian") || '{}'
  )

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'

      onFinish={(vs) => {
        // if (!vs.TimeHead && !vs.TimeTail) return
        props.onValueChange({
          ...vs,
          state: vs.state,
          patientName: vs.patientName,
          endTime: vs.TimeTail
            ? moment(vs.TimeTail).format(DateTimeFormatSimpleOnly)
            : undefined,
          startTime: vs.TimeHead
            ? moment(vs.TimeHead).format(DateTimeFormatSimpleOnly)
            : undefined,
        })
      }}
    >
      <Row gutter={11} wrap={false} style={{ height: '40px' }}>
        <Col span={6}>
          <Form.Item name='patientName'>
            <Input
              size='middle'
              placeholder='患者姓名'
              allowClear
              prefix={<SearchOutlined />}
              onChange={form.submit}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='state'>
            <Select
              placeholder='预检状态'
              onChange={form.submit}
              // suffixIcon={<CaretDownFilled />}
              allowClear
            >
              <Option value={0}>待预检</Option>
              <Option value={1}>预检完成</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col flex={props.type == 1 ? 0 : 1}>
          <Form.Item name='' >
            <DateSelect
              placeholder='预检时间'
              style={{ width: '10rem' }}
              labelPrefix=''
              namePrefix='Time'
              initialValue={DateRangeType.Today}
              options={RecentTime}
              onChange={(e) => {
                if (e != 6) {
                  form.submit()
                }
              }}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        {props.type == 1 && (
          <Col flex={props.type == 1 ? 1 : 0}>
            <Button
              type='primary'
              style={{
                marginRight: '10px',
              }}
              onClick={form.submit}
            >
              查询
            </Button>
          </Col>
        )}
        <Col
          span={4}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {props.type != 1 && (
            <Button
              type='primary'
              style={{
                marginRight: '10px',
              }}
              onClick={form.submit}
            >
              查询
            </Button>
          )}
          <Button
            type='primary'
            onClick={props.onExport}
            style={{
              marginRight: '10px',
            }}
            disabled={props?.ids.length ? false : true}
          >
            导出
          </Button>
          <Button
            type='primary'
            onClick={() => {
              if (props.type == 1) {
                setAddPreviewingModal(true)
              } else {
                history.push('/register')
              }
            }}
          >
            新患者登记
          </Button>
        </Col>
      </Row>
      <AddPreviewingModal
        visible={addPreviewingModal}
        onOk={() => {
          form.submit()
          setAddPreviewingModal(false)
        }}
        onCancel={() => setAddPreviewingModal(false)}
      />
    </Form>
  )
}
