import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { changePassword, getUserProfile } from "./personalDetailSlice";
import { RootDispatch } from "../../../app/store";

export const ChangePassWordModal = (props: {
  profile: any;
  isModalVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      footer={null}
      onCancel={() => {
        form.resetFields();
        props.onCancel();
      }}
      onOk={() => {
        props.onOk;
      }}
      visible={props.isModalVisible}
      title="修改密码"
      width="550px"
    >
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="advanced_search"
        className="ant-advanced-search-form"
        {...layout}
        onFinish={(vs) => {
          if (vs.password === vs.rePassword) {
            dispatch(
              changePassword({
                oldPwd: vs.oldPassword,
                newPwd: vs.password,
              })
            )
              .then(unwrapResult)
              .then((res) => {
                notification.success({
                  message: "密码修改成功",
                });
                form.resetFields();
                props.onOk();
              })
              .then(() => {
                dispatch(getUserProfile());
              });
          } else {
            notification.error({
              message: "两次输入密码不一致，请核对",
            });
          }
        }}
      >
        <Row gutter={20}>
          <Col span={20}>
            <Form.Item label=" 账户/手机号：" name="phone">
              {props?.profile?.phone}
            </Form.Item>
          </Col>
        </Row>
        {}
        <Row gutter={20}>
          <Col span={20} style={{ textAlign: "right" }}>
            <Form.Item
              label="旧密码"
              name="oldPassword"
              rules={[
                { required: true, message: "旧密码不能为空!" },
                {
                  pattern: /^[^\s]*$/,
                  message: "密码不能存在空格",
                },
              ]}
            >
              <Input.Password type="password" autoComplete="new-password" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={20} style={{ textAlign: "right" }}>
            <Form.Item
              label="新密码"
              name="password"
              rules={[
                { required: true, message: "新密码不能为空!" },
                {
                pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=\S+$)[a-zA-Z\d\W]{6,30}$/,
                  message: '新密码格式为6～30位，数字 + 字母',
                },
              ]}
            >
              <Input.Password type="password" autoComplete="new-password" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={20} style={{ textAlign: "right" }}>
            <Form.Item
              label="再次输入"
              name="rePassword"
              rules={[
                { required: true, message: "确认密码不能为空!" },
                {
                  pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=\S+$)[a-zA-Z\d\W]{6,30}$/,
                  message: '新密码格式为6～30位，数字 + 字母',
                },
              ]}
            >
              <Input.Password type="password" autoComplete="new-password" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={15}>
            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
              确定
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
