import Item from 'antd/lib/list/Item'
import moment from 'moment'
import {
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimplePrintCharge,
} from './datetime'

export function PrintChargeBody(data: any, flag?: any) {
  const tenantName = data?.tenantName
  const address = data?.dataMain?.tenantAddress
  const phone = data?.dataMain?.tenantPhone
  const patient = data?.dataMain?.patient
  const payment = data?.dataMain?.payment
  const treatment = data?.dataMain?.treatment
  const printRecipesList = data?.dataMain?.printRecipes
    ?.map((v: any) => v?.itemList)
    ?.flat(1)
  const payMethod = data?.dataMain?.payMethod

  // 一三象限值
  const getValue = (v: any) => {
    const data = v?.split(',').sort(function (a: any, b: any) {
      return b?.substr(0, 1) - a?.substr(0, 1)
    })
    const letters: any = data?.filter((element: any) => isNaN(element)) // 过滤出字母

    letters?.reverse() // 反转字母数组顺序
    const newData = data
      ?.map((element: any) => (isNaN(element) ? letters?.shift() : element))
      .join(',')
      ?.replace(/,/g, '')
      ?.replace('-', ',')
    // 将反转后的字母按顺序放回原数组
    return newData
  }

  // 二四象限值
  const getValues = (v: any) => {
    const newData = v?.replace(/,/g, '')?.replace('-', ',')
    // 将反转后的字母按顺序放回原数组
    return newData
  }

  const toothList = printRecipesList?.map((item: any, index: any) => {
    return {
      ...item,
      toothStructList: item?.toothStruct?.split('@')?.map((current: any) => {
        return current === '--' ? '' : current
      }),
    }
  }) as any

  let content =
    '<div style="padding: 20px 0 ;"><h3 style="text-align: center;margin-top: 0px;margin-bottom: 20px;">' +
    tenantName +
    '收费收据</h3>' +
    '        <div style="display:flex;font-size:12px;margin-bottom:10px">' +
    '        <div style=" display:flex;font-size:12px;width:31%">' +
    '            <span >' +
    '病历号：' +
    patient?.id +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:12px;width:31%">' +
    '            <span >' +
    '结算编号：' +
    payment?.id +
    '            </span>' +
    '        </div>' +
    '        <div style="display:flex;font-size:12px;width:38%">' +
    '            <span >' +
    '收费时间：' +
    (payment?.createTime
      ? moment(payment?.createTime)?.format(DateTimeFormatSimplePrintCharge)
      : '') +
    '            </span>' +
    '        </div>' +
    '    </div>' +
    '        <div style="font-size:12px;display:flex">' +
    '        <div style="display:flex;font-size:12px;width:31%">' +
    '            <span >姓名：' +
    patient?.name +
    '            </span>' +
    '        </div>' +
    '        <div style="display:flex;font-size:12px;width:69%">' +
    '            <span >诊疗日期：' +
    (treatment?.createTime
      ? moment(treatment?.createTime)?.format(DateTimeFormatSimplePrintCharge)
      : '') +
    '            </span>' +
    '        </div>' +
    '    </div>' +
    '            <div style="margin-top:20px;border-bottom:2px solid #000;">' +
    '            </div>' +
    '<div style="display: table;width: 100%;height:30px;justify-content: center;align-items: center;box-sizing: border-box;text-align: center;">' +
    '<div style="width:30%;display: table-cell;box-sizing: border-box;font-size: 12px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">牙位' +
    '</div>' +
    '<div style="width:30%;display: table-cell;box-sizing: border-box;font-size: 12px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">处置名称' +
    '</div>' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;font-size: 12px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">数量' +
    '</div>' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;font-size: 12px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">单位' +
    '</div>' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;font-size: 12px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: right;">单价(元)' +
    '</div>' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;font-size: 12px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: right;">总价(元)' +
    '</div>' +
    '</div>' +
    '            <div style="margin-top:6px;border-bottom:1px solid #000;">' +
    '            </div>'

  for (let i = 0; i < toothList?.length; i++) {
    content +=
      '<div style="display: table;width: 100%;border-collapse: collapse;margin-top: 5px;">' +
      '        <div style=" display: table-row;font-size:12px">' +
      '            <div style=" display: table-cell;width: 30%;">' +
      '                <div' +
      "                    style='font-size:12px;display:table;width:90%;clear: left;border-bottom:  1px solid #000;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 40%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="tr">' +
      (toothList?.[i]?.toothStructList?.[0]
        ? getValue(toothList?.[i]?.toothStructList?.[0])
        : '') +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 40%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="tl">' +
      (toothList?.[i]?.toothStructList?.[1]
        ? getValues(toothList?.[i]?.toothStructList?.[1])
        : '') +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      "                <div style='font-size:12px;display:table;width:90%;clear: left;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width: 40%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="lr">' +
      (toothList?.[i]?.toothStructList?.[2]
        ? getValue(toothList?.[i]?.toothStructList?.[2])
        : '') +
      '                        </div>' +
      "                        <div style='display: table-cell;font-weight: bolder;word-break:break-all;width:40%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="ll">' +
      (toothList?.[i]?.toothStructList?.[3]
        ? getValues(toothList?.[i]?.toothStructList?.[3])
        : '') +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      '            </div>' +
      '            <div style="text-align:center;display:flex;align-items:center;height:60px;line-height:60p">' +
      toothList?.[i]?.name +
      '            </div>' +
      '            <div style=" display: table-cell;width: 10%;text-align:center;height:60px;line-height:60px">' +
      toothList?.[i]?.drugCount +
      '            </div>' +
      '            <div style=" display: table-cell;width: 10%;text-align:center;height:60px;line-height:60px">' +
      toothList?.[i]?.accountUnit +
      '            </div>' +
      '            <div style=" display: table-cell;width: 10%;text-align:right;height:60px;line-height:60px">' +
      (toothList?.[i]?.retailPrice > 0
        ? toothList?.[i]?.retailPrice?.toFixed(2)
        : '0.00') +
      '            </div>' +
      '            <div style=" display: table-cell;width: 10%;text-align:right;height:60px;line-height:60px">' +
      (toothList?.[i]?.drugAmount > 0
        ? toothList?.[i]?.drugAmount?.toFixed(2)
        : '0.00') +
      '            </div>' +
      '        </div>' +
      '    </div>'
  }

  content +=
    '            <div style="margin-top:20px;border-bottom:1px solid #000;">' +
    '            </div>' +
    '            <div style="display: flex ;margin-top:15px">' +
    '    <div style="width: 28%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">本单费用 ' +
    '</span><span>' +
    (payment?.totalPayAmount ? payment?.totalPayAmount?.toFixed(2) : '0.00') +
    '</span></div>' +
    '    <div style="width: 44%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">折扣金额 ' +
    '</span><span>' +
    (Number(payment?.totalPayAmount) - Number(payment?.shouldPayAmount) < 0
      ? ' '
      : (
          Number(payment?.totalPayAmount) - Number(payment?.shouldPayAmount)
        )?.toFixed(2)) +
    '</span></div>' +
    '    <div style="width: 28%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">合计应收 ' +
    '</span><span> ' +
    (payment?.shouldPayAmount ? payment?.shouldPayAmount?.toFixed(2) : '0.00') +
    '</span></div>' +
    '            </div>' +
    '            <div style="display: flex ;margin-top:15px">' +
    '    <div style="width: 28%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">本次实收 ' +
    '</span><span>' +
    (payment?.thisAmount ? payment?.thisAmount?.toFixed(2) : '0.00') +
    '</span></div>' +
    '    <div style="width:44%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">实收金额(大写) ' +
    '</span><span>' +
    payment?.amountStr +
    '</span></div>' +
    '    <div style="width: 28%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">本次欠款 ' +
    '</span><span>' +
    (payment?.arrearageAmount > 0
      ? payment?.arrearageAmount?.toFixed(2)
      : '0.00') +
    '</span></div>' +
    '            </div>' +
    '            <div style="display: flex ;margin-top:15px">' +
    '    <div style="width: 28%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">本单已收 ' +
    '</span><span>' +
    (payment?.realPayAmount ? payment?.realPayAmount?.toFixed(2) : '0.00') +
    '</span></div>' +
    '    <div style="width: 72%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">付款方式 ' +
    '</span><span>' +
    (payMethod?.length ? payMethod?.toString() : '') +
    '</span></div>' +
    '            </div>' +
    '            <div style="margin-top:20px;border-bottom:2px solid #000;">' +
    '            </div>' +
    '            <div style="display: flex ;margin-top:15px">' +
    '    <div style="width: 33.3%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">单位(盖章) ' +
    '</span><span>' +
    '' +
    '</span></div>' +
    '    <div style="width: 33.3%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">主治医生 ' +
    '</span><span>' +
    treatment?.treatmentDoctorName +
    '</span></div>' +
    '    <div style="width: 33.3%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">收银员 ' +
    '</span><span>' +
    payment?.operateName +
    '</span></div>' +
    '            </div>' +
    '            <div style="display: flex ;margin-top:15px">' +
    '    <div style="width: 66.6%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">地址 ' +
    '</span><span>' +
    address +
    '</span></div>' +
    '    <div style="width: 33.3%;display: flex;text-align: left;font-size: 12px"><span style="font-size: 12px;margin-right:4px">电话 ' +
    '</span><span>' +
    phone +
    '</span></div>' +
    '            </div>'

  return content
}
