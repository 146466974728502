import React, { ReactElement } from "react";
import { useParams } from "react-router";
import { ApplicationType } from "../../app/applicationSlice";
import { GrantMenus } from "./Grant";

export const GrantPage = (): ReactElement => {
  const { tenantId, userId } = useParams<{
    tenantId?: string;
    userId: string;
  }>();
  return (
    <GrantMenus
      type={ApplicationType.Management}
      tenantId={tenantId}
      userId={userId}
    />
  );
};
