import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, sendAsync } from "../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../app/store";
import { fromJson, Patientlable } from "../../../models/patientlable"
import * as service from "../../../services/patientlable";
import { PatientTableParams } from "../../../services/patientlable";
interface labelState {
  patientLable: []
  Total: 0
  Current: 1
  detail: Patientlable[]
  lableId: 0
  pageLoading: false
}

const initialState: labelState = {
  patientLable: [],
  Total: 0,
  Current: 1,
  detail: [],
  lableId: 0,
  pageLoading: false,
}

const labelSlice = createSlice({
  name: 'patientlableSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.patientLable = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setDetail: (state, action) => {
      state.detail = action.payload
    },
    setLableId: (state, action) => {
      state.lableId = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setData,
  setTotal,
  setCurrent,
  setDetail,
  setLableId,
  setPageLoading,
} = labelSlice.actions

export const selectPatientLable = (state: RootState) =>
  state.patientLable.patientLable

export const selectTotal = (state: RootState) => state.patientLable.Total

export const selectCurrent = (state: RootState) => state.patientLable.Current

export const selectDetail = (state: RootState) => state.patientLable.detail

export const selectLableId = (state: RootState) => state.patientLable.lableId

export const selectPageLoading = (state: RootState) =>
  state.patientLable.pageLoading

export function getLableList(params: PatientTableParams): RootThunk {
  return api(service.getLableList(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

// export const getLableList = createAsyncThunk<
//   void,
//   service.getLableListDeleteFnParams,
//   RootThunkApi<void>
//   >('patient/getLableList', async (params, api) => {
//   return sendAsync(service.getLableList(params), api)
// })

// export function getLableListDelete(
//   params: service.getLableListDeleteFnParams
// ): RootThunk {
//   return api(service.getLableListDeleteFn(params), (data: any, dispatch) => {
//     return
//   })
// }

export const getLableListDelete = createAsyncThunk<
  void,
  service.getLableListDeleteFnParams,
  RootThunkApi<void>
  >('patient/lableListDelete', async (params, api) => {
    return sendAsync(service.getLableListDeleteFn(params), api)
})


// export function saveLabel(
//   body: Patientlable
// ): RootThunk {
//   return api(service.getLableSave(body), (data: any, dispatch) => {
//     return
//   })
// }

export const saveLabel = createAsyncThunk<
  void,
  Patientlable,
  RootThunkApi<void>
  >('patient/getLableSave', async (body, api) => {
  return sendAsync(service.getLableSave(body), api)
})


export function lableDetail(labelId: string): RootThunk {
  return api(service.getLableDetail(labelId), (data: any, dispatch) => {
    dispatch(setDetail(data));
  });
}

export const editSubLabel = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('patient/editSubLabel', async (params, api) => {
  return sendAsync(service.geteditSubLabelFn(params), api)
})

export const listSubLabel = createAsyncThunk<
  void,
  any,
  RootThunkApi
>('patient/listSubLabel', async (params, api) => {
  return sendAsync(service.getlistSubLabelFn(params), api)
})

export const invoicePageAsync = createAsyncThunk<
  any,
  any,
  RootThunkApi
  >('confirm/invoicePageAsync', async (params, api) => {
  return sendAsync(service.getlistSubLabelFn(params), api)
})


export default labelSlice.reducer;
