import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { DataType } from "../putstorage/putstorage";
import { saveDurgColumns } from "./columns";
import styles from "./Commodity.module.css";
import {
  LinKangDurgAsync,
  saveLinKangDurgAsync,
  selectSaveCurrent,
  selectSaveData,
  selectSaveTotal,
  setSaveCurrent,
} from "./commoditySlice";
import { getMaterialsName, Materiales } from "../../models/commodity";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../app/store";
import { FORMERR } from "node:dns";
import {
  BillingCategoryName,
  ShopBillingCategories,
} from "../../models/billingCategory";
import { EditableList } from "../../compnents/list/EditableList";
const { Option } = Select;

export const CommodityModal = (props: {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
  title: string;
}): ReactElement => {
  const [form] = Form.useForm();

  const dispatch = useDispatch<RootDispatch>();

  const [size, setSize] = useState(10);

  const [queries, setQueries] = useState<any>();

  const data = useSelector(selectSaveData);

  const total = useSelector(selectSaveTotal);

  const current = useSelector(selectSaveCurrent);

  const [selectedKeys, setSelectedKeys] = useState<number[]>([])

  const [tempArr, setTemArr] = useState<any>([])


  useEffect(() => {
    if (props.visible) {
      dispatch(
        LinKangDurgAsync({
          ...queries,
          current,
          size,
        })
      );
    } else {
      form.resetFields();
      setQueries("");
    }
  }, [current, size, queries, props.visible]);
  return (
    <Modal
      visible={props.visible}
      title={props.title}
      okText="确认"
      cancelText="取消"
      className={styles.Modal}
      bodyStyle={{ padding: "10px 20px" }}
      onOk={() => {
        props.onOk();
      }}
      onCancel={() => {
        props.onCancel();
      }}
      footer={null}
    >
      <Form
        form={form}
        onFinish={(vs) => {
          dispatch(setSaveCurrent(1));
          setQueries(vs);
        }}
        autoComplete="off"
      >
        <Row gutter={14}>
          <Col span={6}>
            <Form.Item name="name">
              <Input
                size="middle"
                placeholder="搜索关键词"
                allowClear
                prefix={<SearchOutlined />}
                onChange={() => {
                  form.submit();
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="tenantSupplierName">
              <Input
                size="middle"
                placeholder="搜索生产厂家"
                allowClear
                // prefix={<SearchOutlined />}
                onChange={() => {
                  form.submit();
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="materialsCategory">
              <Select
                onChange={() => {
                  form.submit();
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="药品类别"
                allowClear
              >
                {Materiales.map((v) => (
                  <Option value={v} key={v}>
                    {getMaterialsName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="billingCategory">
              <Select
                onChange={() => {
                  form.submit();
                }}
                placeholder="请选择开票项目"
                allowClear
              >
                {ShopBillingCategories.map((v) => (
                  <Option value={v} key={v}>
                    {BillingCategoryName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2} offset={4}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: "100%", float: "right" }}
              onClick={() => {
                setQueries(form.getFieldsValue());
              }}
            >
              查询
            </Button>
          </Col>
        </Row>
      </Form>
      <EditableList
        page={{
          current: current,
          size: size,
          total: total,
          items: data,
        }}
        columns={saveDurgColumns(size * (current - 1))}
        onChangePage={(current, size) => {
          dispatch(setSaveCurrent(current));
          setSize(size as number)
        }}
        rowSelection={{
          selectedRowKeys: selectedKeys,
          onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
            let data: any = []
            if (selected) {
              data = [...changeRows.map((v: any) => v.id), ...selectedKeys]
            } else {
              data = selectedKeys.filter(item => !changeRows.some((ele: any) => ele.id === item))
            }
            setSelectedKeys(data)
          },
          onSelect: (record, selected, selectedRows) => {
            let data = []
            if (selected) {
              data = [record.id, ...selectedKeys]
            } else {
              data = selectedKeys.filter(v => v != record.id)
            }
            setSelectedKeys(data)
          },

        }}
      />

      <Row gutter={20} style={{ marginTop: "20px" }}>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            onClick={() => {
              dispatch(saveLinKangDurgAsync(Array.from(new Set(selectedKeys)).join(',')))
                .then(unwrapResult)
                .then(() => {
                  props.onOk();
                  setSelectedKeys([])
                  dispatch(setSaveCurrent(1));
                });
            }}
          >
            导入{selectedKeys.length != 0 ? `(${selectedKeys.length})` : ''}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            取消
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
