/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-24 14:20:25
 */
import { Patient } from '../models/patient'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface GetPatientListParams {
  current?: any
  size?: any
  param?: string
  labelName?: string
  labelIds?: any
  source?: number
  name?: string
}

export function getPatientList(params: GetPatientListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/list`,
    method: 'GET',
    params,
  }
}


// 查询全国地区 省市区 联动
export function address(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/addresses/getListTree`,
    method: 'GET',
  }
}

export function getNewPatientList(params: GetPatientListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/patientList`,
    method: 'GET',
    params,
  }
}

export function getPatientDetail(patientId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/detail`,
    method: 'GET',
    params: {
      id: patientId,
    },
  }
}

export interface RegisterAppointDataParam {
  doctorId?: any
  registrationId?: any
}

export function changeRegisterAppointData(params: RegisterAppointDataParam): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/changeRegistrationDoctor`,
    method: 'POST',
    params,
  }
}

export function treatmentDetail(patientId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/getTreatmenWithPatientInfo`,
    method: 'GET',
    params: {
      treatmentId: patientId,
    },
  }
}

export function changeRegistration(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/changeRegistrationDoctorByOperator`,
    method: 'GET',
    params: {
      id: id,
    },
  }
}
export interface SavePatientParams {
  patient: Patient
  registrationId?: string
  treatmentId?: string
  labelIdList?: string[]
}

export function savePatient(params: SavePatientParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/save`,
    method: 'POST',
    body: params,
  }
}

// 获取预约电话
export function getAppointmentPhone(): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getDepartmentAppointmentSetting`,
    method: 'POST',
  }
}
