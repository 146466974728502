import { Button, notification, Row, Space, TableColumnType } from "antd";
import React, { ReactElement, useRef } from "react";
import { useDispatch } from "react-redux";
import { EditableListRef } from "../../../../compnents/containers/EditableList";
import { getGenderName } from "../../../../models/user";
import { FileTextOutlined } from "@ant-design/icons";
import { DelectOutlined, PitestOutlined } from "../../../../compnents/icons/Icons";
import { Dimen } from "../../../../models/dimen";
import { getAge } from '../../../../utils/StringUtils'

export const Columns = (
  onClick: (id: any, t: string) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const dispatch = useDispatch()
  const ref = useRef<EditableListRef>(null)

  return [
    {
      title: '序号',
      align: 'center',
      key: 'no',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },

    {
      title: '患者姓名',
      dataIndex: 'patientName',
      align: 'center',
      width: Dimen.Name,
      key: 'patientName',
    },
    {
      title: '病历号',
      key: 'patientId',
      width: Dimen.Sex,
      align: 'center',
      dataIndex: 'patientId',
    },
    {
      title: '性别',
      key: 'gender',
      align: 'center',
      width: Dimen.Sex,
      render: (_: string, u: any) => getGenderName(u.patientSex),
    },
    {
      title: '年龄',
      dataIndex: 'patientAge',
      align: 'center',
      width: Dimen.Age,
      key: 'patientAge',
      render: (_, r) => getAge(r?.patientAge, r?.patientMonth),
    },
    {
      title: '皮试药品名称',
      dataIndex: 'drugName',
      align: 'center',
      width: Dimen.Name,
      key: 'drugName',
    },
    {
      title: '皮试状态',
      dataIndex: 'state',
      align: 'center',
      width: Dimen.State,
      key: 'state',
      render: (state: any) => {
        switch (state) {
          case 0:
            return '未皮试'
          case 1:
            return '皮试中'
          case 2:
            return '皮试完成'
          case 3:
            return '已作废'
            break
        }
      },
    },
    {
      title: '预设皮试时间(分钟)',
      dataIndex: 'presetTime',
      align: 'center',
      width: Dimen.Time,
    },
    {
      title: '操作',
      align: 'center',
      width: '10rem',
      render: function userPermissions(_: string, t: any): ReactElement {
        return (
          <Space>
            {t.state == 0 ? (
              <Row>
                <Button
                  type="text"
                  style={{
                    color: "#666",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={(e: any) => {
                    onClick(t.id, "开始皮试");
                    if (t.recipeState == 0) {
                      notification.success({
                        message: "当前处方未结算",
                        duration: 3,
                      });
                    }
                  }}
                >
                  <PitestOutlined
                    style={{
                      fill: "#F5BB4A",
                      width: "18px",
                      height: "18px",
                    }}
                  />
                  开始皮试
                </Button>
              </Row>
            ) : t.state == 1 ? (
              <Row>
                <Button
                  type="text"
                  style={{
                    color: "#666",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={(e: any) => {
                    onClick(t.id, "登记皮试结果");
                  }}
                >
                  <FileTextOutlined
                    style={{
                      color: "#1CD0BD",
                      width: "12px",
                      height: "12px",
                      marginRight: "-4px",
                    }}
                  />
                  登记皮试结果
                </Button>
              </Row>
            ) : (
              <></>
            )}
            {t.state != 3 && t.state != 1 ? (
            <Row>
              <Button
                type="text"
                style={{
                  color: "#666",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={(e: any) => {
                  onClick(t.id, "作废");
                }}
              >
                <DelectOutlined
                  style={{
                    fill: "#FF5D5D",
                    width: "14px",
                    height: "14px",
                    marginRight: "4px",
                  }}
                />
                作废
              </Button>
            </Row>) : <></>}
          </Space>
        );
      },
    },
    // {
    //   title: "打印皮试单",
    //   align: "center",
    //   render: function element(_, t) {
    //     return (
    //       <>
    //         <a style={{color:"#027AFF"}}>打印皮试单</a>
    //       </>

    //     );
    //   },
    // }
  ]
}
