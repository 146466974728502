import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Select, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSettlementStatusName,
  settlementStatuss,
} from '../../../../models/ToothHome'
import {
  DateRangeChargeHZGLTypes,
  DateRangeChargeTypes,
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../../compnents/form/DateSelect'
import {
  getDepartments,
  selectDepartments,
} from '../../../payment/list/paymentSlice'
import { getUsersInDepartmentAsync } from '../../../completion/completionSlice'
import { StationCategory } from '../../../../models/user'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../../app/store'
import { selectInsuranceArray } from '../../../../app/applicationSlice'

interface ChargeQueryProps {
  onSubmit: (obj: any) => void
  patientId?: string
  flag?: any
}
export const ChargeQuery = ({
  onSubmit,
  patientId,
  flag,
}: ChargeQueryProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [head, tail] = getDateRange(
    flag ? DateRangeType.Auth : DateRangeType.Today
  )

  const departments = useSelector(selectDepartments) //科室

  const [doctorList, setDoctorList] = useState<any>([]) //医生数据

  const insuranceArray = useSelector(selectInsuranceArray)

  const getDoctorList = () => {
    //获取医生
    dispatch(
      getUsersInDepartmentAsync({
        departmentId: form.getFieldValue('departmentIdF'),
        stationCategory: StationCategory.DoctorOrPharmacist,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setDoctorList(res)
        form.submit()
      })
  }
  const storageParams = JSON.parse(
    sessionStorage.getItem(
      location.pathname + (flag ? '_shoufeiHZGL' : '_shoufei')
    ) || '{}'
  ) //数据回显

  useEffect(() => {
    getDoctorList()
    dispatch(getDepartments())
    if (
      Object.keys(storageParams).length &&
      (location.pathname == '/toothHome' ||
        location.pathname == '/toothPatient')
    ) {
      form.setFieldsValue({
        nameF: storageParams?.param || '',
        stateF: storageParams?.patientPayState,
        departmentIdF: storageParams?.treatmentDepartmentId,
        doctorIdF: storageParams?.treatmentDoctorId,
        insuranceStatus: storageParams?.insuranceStatus,
        timeHead: storageParams?.registrationTimeHead || head,
        timeTail: storageParams?.registrationTimeTail || tail,
        [DateRangeFieldName]: storageParams?._dateRange || DateRangeType.Today,
      })
    } else {
      form.setFieldsValue({
        timeHead: head,
        timeTail: tail,
      })
    }
    form.submit()
  }, [])

  return (
    <Form
      form={form}
      initialValues={{
        stateF: patientId ? 0 : 1,
      }}
      onFinish={(values) => {
        onSubmit({
          ...storageParams,
          ...values,
          startTimeF: values.timeHead || head,
          endTimeF: values.timeTail || tail,
          timeHead: undefined,
          timeTail: undefined,
        })
      }}
      style={{ padding: '0 10px' }}
    >
      {/* 收费查询条件 */}
      <Space wrap>
        <DateSelect
          placeholder='请选择时间'
          labelPrefix=''
          namePrefix='time'
          initialValue={flag ? DateRangeType.Auth : DateRangeType.Today}
          allowClear={false}
          options={flag ? DateRangeChargeHZGLTypes : DateRangeChargeTypes}
          style={{ width: '9.5rem' }}
          onChange={(e) => {
            //记录一下每个患者的查询时间，用于结算等页面返回时的回显
            // const charge_query = {
            //   type : e || 6,
            //   timeHead : form.getFieldValue("timeHead"),
            //   timeTail : form.getFieldValue("timeTail")
            // }
            // sessionStorage.setItem("charge_query",JSON.stringify(charge_query))
            if (e != 6) {
              form.submit()
            }
          }}
        />
        {!patientId ? (
          <>
            <Form.Item noStyle name='nameF'>
              <Input
                autoComplete='off'
                placeholder='患者姓名/助记码/手机号/病历号'
                style={{ width: '16rem' }}
                allowClear
                onChange={() => form.submit()}
              />
            </Form.Item>
            <Form.Item name='departmentIdF' noStyle>
              <Select
                placeholder='科室'
                style={{ width: '8.25rem' }}
                allowClear
                onChange={() => {
                  getDoctorList()
                  form.submit()
                }}
                showSearch
                filterOption={(input, option: any) =>
                  (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {departments.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='doctorIdF' noStyle>
              <Select
                placeholder='医生'
                style={{ width: '8.25rem' }}
                allowClear
                showSearch
                filterOption={(input, option: any) =>
                  (option?.children ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={form.submit}
              >
                {doctorList.map((v: any) => (
                  <Select.Option key={v.id} value={v.id}>
                    {v.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : (
          <Form.Item name='insuranceStatus' noStyle>
            <Select
              placeholder='结算险种'
              style={{ width: '8.25rem' }}
              allowClear
              onChange={form.submit}
            >
              <Select.Option value={''}>全部</Select.Option>
              <Select.Option value={'1'}>自费</Select.Option>
              {insuranceArray.map((v) => (
                <Select.Option value={'2'} key={v.id}>
                  {v.insuranceName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name='stateF' noStyle>
          <Select
            placeholder='结算状态'
            style={{ width: '8.25rem' }}
            allowClear
            onChange={form.submit}
          >
            {settlementStatuss.map((v: number) => {
              return (
                <Select.Option key={v} value={v}>
                  {getSettlementStatusName(v)}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>

        <Button type='primary' htmlType='submit'>
          查询
        </Button>
      </Space>
    </Form>
  )
}
