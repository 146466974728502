/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-10-18 17:39:24
 */
import { API_PREFIX } from './constant'
import { Request } from './request'
export enum Gender {
  Unknown = 0,
  Male = 1,
  Female = 2,
}
export interface NoFormulaParmas {
  current?: number
  timeHead?: string
  timeTail?: string
  registrationTimeHead?: string
  registrationTimeTail?: string
  // tenantId ?: string;
  registrationId?: number
  name?: string
  size?: number
  state?: number
  normalUsemethod?: string
  range?: string
  param?: string
}
// 处方列表
export function getNoformulaList(params: NoFormulaParmas): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeexecute/list`,
    method: 'GET',
    params,
  }
}
// 处方详情
export function getNoformula(params: NoFormulaParmas): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeexecute/detail`,
    method: 'GET',
    params,
  }
}

export interface ExcuteParams {
  id?: number
  state?: number
  useMethod?: string
  recipeId?: string
  range?: number
  treatmentId?: string
}
export function getExecute(params: ExcuteParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeexecute/execute`,
    method: 'GET',
    params,
  }
}
