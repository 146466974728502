import { API_PREFIX } from "./constant"
import { Request } from "./request"

export interface surgicalBody {
  anesthesiaDoctorId?: number
  anesthesiaDoctorName?: string
  anesthesiaMethod?: string
  appointmentDuration?: string
  appointmentTime?: string
  coherentVideo?: string
  helper?: string
  id?: number
  mainDoctorId?: number
  mainDoctorName?: string
  operatingContent?: string
  operatingDiagnoseBefore?: string
  operatingRoomId?: number
  operatingRoomName?: string
  patientAge?: number
  patientId?: number
  patientName?: string
  patientSex?: number
  position?: string
  registrationId?: number
  remark?: string
  treatmentDepartmentId?: number
  treatmentDepartmentName?: string
  treatmentId?: number
  treatmentNo?: string
}

export function surgicalApply(body: surgicalBody): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/operatingbill/submit`,
    method: "POST",
    body,
  }
}
