import { Button, Col, Row } from 'antd'
import React, { ReactElement, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootDispatch } from '../../../app/store'
import { NavBar } from '../../../compnents/nav/NavBar'
import { traceRoute } from '../../../layouts/layoutSlice'
import { Recipe, RecipeCategory } from '../../../models/recipe'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import {
  RecipeEditor,
  RecipeEditorMode,
} from '../../treatment/editor/RecipeEditor'

export const PaymentExtra = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const location = useLocation<{
    insuranceCode: string
  }>()

  const query = useQuery()

  const treatmentId = query.get('treatmentId') as string | undefined

  const recipeId = query.get('recipeId') as string | undefined

  const [recipe, setRecipe] = useState<Recipe | undefined>()

  return (
    <Col
      style={{
        height: 'calc(100% - 10px)',
        overflow: 'hidden',
        display: 'flex',
        margin: '10px 0',
        flexDirection: 'column',
      }}
    >
      <NavBar
        where={['收费业务', '额外费用']}
        backtrace={{
          name: '收费',
          path: '/payment/cart',
          query: `treatmentId=${treatmentId}`,
        }}
        style={{ marginBottom: 10, marginLeft: 20, marginRight: 20 }}
      />
      <RecipeEditor
        insuranceCode={location?.state?.insuranceCode || ''}
        treatmentId={treatmentId}
        recipeId={recipeId}
        recipeCategory={RecipeCategory.Extras}
        mode={RecipeEditorMode.Extra}
        style={{ flex: 1, marginLeft: 20, marginRight: 20, marginBottom: 10 }}
        onChange={(recipe) => setRecipe(recipe)}
      />
      <Row
        style={{ height: '5rem', backgroundColor: theme.pn, padding: '0 20px' }}
        align='middle'
        justify='space-between'
      >
        <Col style={{ fontSize: '1.5rem' }}>
          总金额：
          <span
            style={{
              color: theme.e,
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            ¥{recipe?.amount}
          </span>
        </Col>
        <Button
          type='primary'
          onClick={() =>
            dispatch(
              traceRoute({
                name: '收费',
                path: '/payment/cart',
                query: `treatmentId=${treatmentId}`,
              })
            )
          }
        >
          确定
        </Button>
      </Row>
    </Col>
  )
}
