/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-24 11:08:06
 * @LastEditors: K
 * @LastEditTime: 2022-07-13 20:54:29
 */
// 0西药套餐: 1中药套餐: 2诊疗套餐: 3颗粒剂套餐
export enum SetmealCategory {
    XYTC = 0,
    ZYTC = 1,
    ZLTC = 2,
    KLJTC = 3,
    CZXMTC = 4,
}

export const SetmealCategoris = [
    SetmealCategory.XYTC,
    SetmealCategory.ZYTC,
    SetmealCategory.ZLTC,
    SetmealCategory.KLJTC,
    SetmealCategory.CZXMTC
]

export function SetmealCategoryName(setmealCategory: SetmealCategory) {
    switch (setmealCategory) {
        case 0:
            return "西药套餐"
        case 1:
            return "中药套餐"
        case 2:
            return "诊疗套餐"
        case 3:
            return "颗粒剂套餐"
        case 4:
            return "处置项目套餐"
        default:
            break;
    }
}

export function setMealflag(setmealCategory: SetmealCategory) {
    switch (setmealCategory) {
        case 0:
            return 1;
        case 1:
            return 3;
        case 2:
            return null;
        case 3:
            return 2;
        case 4:
            return 4;
        default:
            break;
    }
}