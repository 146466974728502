/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-02-23 15:52:03
 */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { storePageParams } from '../../services/storesearch'
import { Columns, DoctorColumns } from './columns'
import { Storesearchquery } from './query'
import {
  detailStoresearch,
  getOnBatchInitialization,
  getStoreExportAsync,
  pageStoresearch,
  pageStoresearchPrintAsync,
  selectAmountTotal,
  selectCurrent,
  selectDetail,
  selectPageLoading,
  selectStoresearchData,
  selectTotal,
  setCurrent,
  setDetail,
  setlogoutlistStateAsync,
  setPageLoading,
  setsplitOrTotalAsync,
} from './inventoryInitSlice'
import styles from './InventoryInit.module.css'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Switch,
  Table,
} from 'antd'
import { RootDispatch } from '../../app/store'
import { serverType } from '../../services/commodity'
import { useHistory, useLocation } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { EditableList } from '../../compnents/list/EditableList'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import { medicalSendHttp, strParse } from '../../utils/MedicalUtils'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../app/applicationSlice'
import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { electronicMedicalRecordListUpload } from '../ElectronicMedicalRecordUpload/electronicMedicalRecordUploadSlice'
import { setLoading } from '../sales/DrugStatic/drugStaticSlice'
import { getPackedSettings } from 'http2'

export const InventoryInit = () => {
  const history = useHistory()

  const location: any = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const [loading, setLoading] = useState(false)

  const [SelectId, setSelectId] = useState(0)

  const [queries, setQueries] = useState<storePageParams>({
    state: 1,
    size: 10,
    showFlag: 0,
    mergeFlag: 0,
    current: 0,
    isMate:1,
  })

  const detail: any = useSelector(selectDetail)

  const total = useSelector(selectTotal)

  const data = useSelector(selectStoresearchData)

  const baseData = useSelector(selectInsuranceItem)

  const amountTotal = useSelector(selectAmountTotal)

  const pageLoadingRX = useSelector(selectPageLoading)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const insuranceArray = useSelector(selectInsuranceArray)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  useEffect(() => {
    if (!queries.current) return
    dispatch(setPageLoading(true))
    dispatch(pageStoresearch({ ...queries }))
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
    sessionStorage.setItem(
      location.pathname,
      JSON.stringify({ ...queries, category: location?.state?.category })
    )
  }, [queries])

  useEffect(() => {
    if (SelectId) {
      dispatch(detailStoresearch(SelectId))
    }
  }, [SelectId])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  let Ids: any = []
  const onBatchInitialization = (serverType: serverType) => {
    setLoading(true)
    dispatch(
      getOnBatchInitialization({
        serverType,
        body: {
          baseData: {
            ...baseData,
          },
          ids: Ids,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res: any) => {
        const data = JSON.parse(res)
        Ids = data?.map((v: any) => v?.id)
        if (data && data?.length > 0) {
          notification.success({
            message:
              '当前初始化进度：' + data?.[0]?.uploaded + '/' + data?.[0]?.total,
            duration: 5,
          })
        }

        for (let i = 0; i < data?.length; i++) {
          await first(data?.[i]?.init3501)
          await second(data?.[i]?.init3502)
        }
        setLoading(false)
        if (Ids && Ids.length > 0) {
          onBatchInitialization('requestString')
        } else {
          notification.success({
            message: '库存初始化完毕',
            duration: 3,
          })
          dispatch(pageStoresearch({ ...queries }))
        }
      })
      .catch((v) => {
        setLoading(false)
      })
  }

  const first = async (init3501?: any) => {
    const data = init3501
    try {
      const result = await new Promise((resolve, reject) => {
        medicalSendHttp(
          {
            trade_args: [
              data?.infno,
              data?.url,
              data,
              baseData?.countryHospitalNb,
              baseData?.secretToken,
            ],
            message: '库存初始化',
          },
          (call: any, err: any) => {
            if (!err) {
              resolve(true) // 如果成功，调用 resolve 并传递 true
            } else {
              reject(err) // 如果失败，调用 reject 并传递错误
            }
          }
        )
      })
      return result
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  // 3502前置机调用
  const second = async (init3502?: any) => {
    const data = init3502
    try {
      const result = await new Promise((resolve, reject) => {
        medicalSendHttp(
          {
            trade_args: [
              data?.infno,
              data?.url,
              data,
              baseData?.countryHospitalNb,
              baseData?.secretToken,
            ],
            message: '库存初始化',
          },
          (call: any, err) => {
            if (!err) {
              resolve(true) // 如果成功，调用 resolve 并传递 true
            } else {
              reject(err) // 如果失败，调用 reject 并传递错误
            }
          }
        )
      })
      return result
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  const table = (
    <EditableList
      className={styles.storetable}
      loading={pageLoadingRX}
      bordered
      page={{
        items: data,
        current: queries.current,
        size: queries.size || 10,
        total: total,
      }}
      // rowKey={(_, i) =>
      //   queries
      //     ? (queries.size || 0) * (queries.current || 0) + (i || 0)
      //     : 0
      // }
      summary={() => (
        <Table.Summary fixed>
          {location?.state?.flag != 1 && (
            <Table.Summary.Row className={styles.total}>
              <Table.Summary.Cell
                index={0}
                className={styles.fixed}
              ></Table.Summary.Cell>
              <Table.Summary.Cell
                index={1}
                className={styles.fixed}
              ></Table.Summary.Cell>

              <Table.Summary.Cell
                index={2}
                className={styles.fixed}
              ></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={10}></Table.Summary.Cell>
              <Table.Summary.Cell index={11} align='center'>
                合计
              </Table.Summary.Cell>
              <Table.Summary.Cell align='right' index={12}>
                {amountTotal?.totalAmountInput?.toFixed(2) || '0.00'}
              </Table.Summary.Cell>
              <Table.Summary.Cell align='right' index={13}>
                {amountTotal?.totalAmountSale?.toFixed(2) || '0.00'}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={14}></Table.Summary.Cell>
              <Table.Summary.Cell index={15}></Table.Summary.Cell>
              <Table.Summary.Cell index={16}></Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        </Table.Summary>
      )}
      rowKey={(record: any) => record.id}
      onChangePage={(current, pageSize) => {
        setQueries({ ...queries, size: pageSize as number, current })
      }}
      columns={
        location?.state?.flag
          ? DoctorColumns(
              location?.state?.flag as number,
              queries.size * (queries.current - 1)
            )
          : Columns(
              location?.state?.flag as number,
              queries.size * (queries.current - 1)
            )
      }
      rowClassName={(record: any) => {
        return !record.state ? styles.gray : ''
      }}
    />
  )

  return (
    <>
      <div className={styles.content}>
        <Storesearchquery
          onValueChange={(v: any) => {
            setQueries({
              ...queries,
              ...v,
              deadlineStart:
                v?._dateRange == 8 || v?._dateRange == 7
                  ? undefined
                  : v?.registrationTimeHead,
              deadlineEnd:
                v?._dateRange == 7 || v?._dateRange == 12
                  ? undefined
                  : v?.registrationTimeTail,
            })
          }}
          // 一键初始化
          onBatchInitialization={() => {
            Ids = []
            onBatchInitialization('requestString')
          }}
          loading={loading}
        />
        {table}
      </div>
    </>
  )
}
