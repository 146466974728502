/* eslint-disable react/display-name */
import { Avatar, Badge, Button, Space, TableColumnType, Tooltip } from 'antd'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { OweMoneyIcon } from '../../compnents/icons/Icons'
import { Dimen } from '../../models/dimen'
import { getPayFlagName, Payment } from '../../models/payment'
import { getGenderName } from '../../models/user'
import { getAge } from '../../utils/StringUtils'
import { selectTenant } from '../user/setting/settingSlice'
import styles from './ElectronicTicketUpload.module.css'

type Action =
  | 'upload'
  | 'retransmissionUpload'
  | 'lookDetail'
  | 'certificateResultQuery'
  | 'uploadResultQuery'

export const getCardType = (cardType: string) => {
  switch (cardType) {
    case '1':
      return '电子凭证'
    case '2':
      return '身份证'
    case '3':
      return '医保卡'
    default:
      return ''
  }
}

export const getInsuranceCodeName = (insuranceCode: string) => {
  switch (insuranceCode) {
    case 'ZIFEI':
      return '自费'
    case 'ZHEJIANG':
      return '浙江智慧医保'
    case 'CHONGQING':
      return '重庆医保'
    default:
      return ''
  }
}

export const getStateName = (invoiceUploadState: string) => {
  switch (invoiceUploadState) {
    case '-1':
      return '待上传就医信息'
    case '0':
      return '待确认电子票据'
    case '1':
      return '已上传完成'
    case '2':
      return '上传失败'
    case ' ':
      return '待上传就医信息'
    default:
      return ''
  }
}

export const Columns = (
  startIndex: number,
  disabled: any,
  onAction: (
    action: Action,

    t: any
  ) => void
): TableColumnType<Payment>[] => {
  const oweMoneyShow: any = useSelector(selectTenant) //欠费是否显示

  const colums: TableColumnType<Payment>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      fixed: 'left',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '患者姓名',
      key: 'patientName',
      align: 'center',
      width: '10rem',
      fixed: 'left',
      // ellipsis:true,
      render: (_, { treatment, arrearageAmountSum, registration, labels }) => {
        return (
          <div style={{ width: '100%', height: '100%' }}>
            <div style={{}}>
              <div
                style={{ width: '100%', height: '40px', lineHeight: '40px' }}
              >
                {treatment.patientName}
              </div>
              {arrearageAmountSum > 0 &&
                oweMoneyShow &&
                oweMoneyShow?.openOwe == 1 && (
                  <Tooltip title={`欠费金额 ${arrearageAmountSum} 元`}>
                    <div>
                      <OweMoneyIcon
                        style={{
                          marginLeft: '5px',
                          width: '18px',
                          height: '18px',
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: labels?.length ? '8px' : '0px',
                }}
              >
                {!!labels?.length &&
                  labels?.map((v: any) => (
                    <Tooltip key={v.id} title={v.name}>
                      <span
                        className={styles.label}
                        style={{
                          backgroundColor: v.backgroundColor,
                          color: v.fontColor,
                        }}
                      >
                        {v.headName}
                      </span>
                    </Tooltip>
                  ))}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: '病历号',
      key: 'patientId',
      align: 'center',
      width: '8em',
      dataIndex: 'patientId',
      render: (_, { registration }) => registration.patientId,
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '8em',
      align: 'center',
      render: (_, _2) => {
        return _2.paymentInsuranceCode === 'ZIFEI' || !_2.paymentInsuranceCode
          ? '自 费'
          : '智慧医保'
      },
    },
    {
      title: '性别',
      key: 'patientSex',
      align: 'center',
      width: '4em',
      render: (_, { treatment }) => getGenderName(treatment.patientSex),
    },
    {
      title: '年龄',
      key: 'patientAge',
      align: 'center',
      width: '5em',
      render: (_, { treatment }) =>
        getAge(treatment?.patientAge, treatment?.patientMonth),
    },
    {
      title: '诊断疾病',
      key: 'disease',
      align: 'center',
      width: '8em',
      ellipsis: true,
      render: (_, { treatment }) => treatment?.disease,
    },
    {
      title: '结算金额',
      key: 'amount',
      width: '8em',
      align: 'right',
      render: (_, { amount }) =>
        amount === undefined ? '' : amount?.toFixed(2),
    },
    {
      title: '结算时间',
      key: 'chargeOrRefundTime',
      align: 'center',
      width: Dimen.Time,
      render: (_, { treatment }) => treatment.chargeOrRefundTime,
    },
    {
      title: '状态',
      dataIndex: 'invoiceUploadFlag',
      width: Dimen.PayMethod,
      align: 'center',
      key: '',
      render: (_, t: any) => {
        return getStateName(t.invoiceUploadState?.toString())
      },
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '24rem',
      fixed: 'right',
      render: function ShowAction(_, t: any): ReactElement {
        const invoiceUploadStates = t?.invoiceUploadState?.toString()
        return (
          <Space>
            {invoiceUploadStates == 0 ||
            invoiceUploadStates == 1 ||
            invoiceUploadStates == 2 ? (
              <Button
                type='primary'
                disabled={disabled}
                onClick={() => onAction('retransmissionUpload', t)}
              >
                电子票据重传
              </Button>
            ) : (
              <></>
            )}

            {invoiceUploadStates == -1 || invoiceUploadStates == '' ? (
              <Button
                type='primary'
                disabled={disabled}
                onClick={() => onAction('upload', t)}
              >
                就医信息上传
              </Button>
            ) : invoiceUploadStates == 0 ? (
              <>
                <Button
                  type='primary'
                  disabled={disabled}
                  onClick={() => onAction('uploadResultQuery', t)}
                >
                  上传结果查询
                </Button>
              </>
            ) : invoiceUploadStates == 1 ? (
              <Button
                type='primary'
                disabled={disabled}
                onClick={() => onAction('lookDetail', t)}
              >
                查看详情
              </Button>
            ) : (
              <></>
            )}
            {(invoiceUploadStates == 1 || invoiceUploadStates == 2) && (
              <Button
                type='primary'
                disabled={disabled}
                onClick={() => onAction('certificateResultQuery', t)}
              >
                凭证状态查询
              </Button>
            )}
          </Space>
        )
      },
    },
  ]
  return colums
}
