import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  Button,
  Form,
  Input,
  notification,
  Pagination,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Columns } from './columns'
import styles from './inspect.module.css'
import { getExportExcel, getInspectDataList } from './inspectSlice'
import { InspectParams } from '../../../services/inspect'
import { getFullDeparmentList } from '../../completion/completionSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { getFullDoctorList } from '../../registration/list/registrationSlice'
import {
  selectUserDepartmentId,
  selectUserId,
} from '../../../app/applicationSlice'
import { Department } from '../../../models/department'
import { User } from '../../../models/user'
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { ThemeContext } from '../../../theme/ThemeContext'
import { RootDispatch } from '../../../app/store'
import { DateRangeFieldName, DateRangeType, DateSelect, getDateRange, TJFXTime } from '../../../compnents/form/DateSelect'

interface OutpatientAuditProps {
  currentUserMode?: boolean
  route?: {
    name: string
    path: string
  }
}

export const RealInspectList = (
  { currentUserMode }: OutpatientAuditProps,
  ref: any
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [current, setCurrent] = useState(1)

  const [size, setSize] = useState(10)

  const [total, setTotal] = useState<any>()

  const [departments, setDepartments] = useState<Department[]>([])

  const [doctors, setDoctors] = useState<User[]>([])

  const [inspectList, setInspectList] = useState<any>()

  const userId = useSelector(selectUserId)

  const departmentId = useSelector(selectUserDepartmentId)

    const [head, tail] = getDateRange(DateRangeType.Today)

  const [params, setParams] = useState<InspectParams>({
    current: 1,
    size: 10,
    treatmentDoctorId: currentUserMode ? userId : undefined,
    treatmentTimeHead: head,
    treatmentTimeTail: tail,
  })

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  useEffect(() => {
    if (!currentUserMode) {
      dispatch(getFullDeparmentList({ current: 1, size: 1000, status: 1 }))
        .then(unwrapResult)
        .then((ds) => setDepartments(ds))
        .catch(() => {
          // do nothing
        })
      dispatch(getFullDoctorList())
        .then(unwrapResult)
        .then((ds) => setDoctors(ds))
        .catch(() => {
          // do nothing
        })
    }
  }, [])

  useEffect(() => {
    setPageLoading(true)
    dispatch(getInspectDataList({ ...params, current, size }))
      .then(unwrapResult)
      .then((v: any) => {
        setInspectList(v?.records)
        setTotal(v?.total)
      })
      .finally(() => setPageLoading(false))
  }, [params, current, size])

  useImperativeHandle(
    ref,
    () => ({
      webExport: () => {
        params
        dispatch(
          getExportExcel({ ...params, current: undefined, size: undefined })
        )
          .then(unwrapResult)
          .then((res: any) => {
            const content = res
            const blob = new Blob([content])
            const fileName = '卫监第二版导出数据.xlsx'
            const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
            const elink = document.createElement('a')
            if ('download' in elink) {
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = selfURL['createObjectURL'](blob)
              document.body.appendChild(elink)
              // 触发链接
              elink.click()
              selfURL.revokeObjectURL(elink.href)
              document.body.removeChild(elink)
            } else {
              navigator.msSaveBlob(blob, fileName)
            }
          })
          .then(() => {
            notification.success({
              message: '导出成功',
              duration: 3,
            })
          })
          .catch((res: any) => {
            return
          })
      },
    }),
    [params]
  )

  return (
    <>
      <div className={styles.content}>
        <Form
          form={form}
          autoComplete='off'
          initialValues={{
            scope: currentUserMode ? 'user' : undefined,
          }}
          onChange={() => {
            setCurrent(1)
          }}
          onFieldsChange={(fs) => {
            setCurrent(1)
          }}
          onFinish={(values) => {
            setParams({
              ...params,
              ...values,
              scope: undefined,
              treatmentDoctorId:
                values.scope === 'user' ? userId : values.treatmentDoctorId,
              treatmentDepartmentId:
                values.scope === 'department'
                  ? departmentId
                  : values.treatmentDepartmentId,
              current: 1,
              treatmentTimeHead: values?.treatmentTimeHead
                ? values?.treatmentTimeHead
                : head,
              treatmentTimeTail: values?.treatmentTimeTail
                ? values?.treatmentTimeTail
                : tail,
            })
          }}
        >
          <Row align='middle' justify='space-between'>
            <Space>
              <Form.Item name='param' noStyle>
                <Input
                  onChange={form.submit}
                  style={{ width: 240 }}
                  placeholder='请输入关键词'
                  prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
                />
              </Form.Item>
              <Form.Item name='scope' noStyle>
                <Select
                  placeholder='查看范围'
                  allowClear
                  disabled={currentUserMode}
                  style={{ width: 180 }}
                  onChange={form.submit}
                  // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                >
                  <Select.Option value='user'>只看本人</Select.Option>
                  <Select.Option value='department'>只看本科室</Select.Option>
                </Select>
              </Form.Item>
              {!currentUserMode && (
                <>
                  <Form.Item name='treatmentDepartmentId' noStyle>
                    <Select
                      allowClear
                      style={{ width: 180 }}
                      placeholder='接诊科室'
                      onChange={form.submit}
                      // suffixIcon={
                      //   <CaretDownFilled style={{ color: theme.tc3 }} />
                      // }
                    >
                      {departments.map((d) => (
                        <Select.Option key={d.id} value={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name='treatmentDoctorId' noStyle>
                    <Select
                      allowClear
                      style={{ width: 180 }}
                      placeholder='接诊医生'
                      onChange={form.submit}
                      // suffixIcon={
                      //   <CaretDownFilled style={{ color: theme.tc3 }} />
                      // }
                    >
                      {doctors.map((d) => (
                        <Select.Option key={d.id} value={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              )}
              <DateSelect
                labelPrefix=''
                placeholder='接诊时间'
                namePrefix='treatmentTime'
                options={TJFXTime}
                allowClear={false}
                initialValue={DateRangeType.Today}
                style={{ width: 140 }}
                onChange={form.submit}
              />
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
        <Table
          bordered
          loading={pageLoading}
          className={styles.inspectTable}
          rowKey={(_, i) => `${i}}`}
          pagination={false}
          dataSource={inspectList}
          columns={Columns(size * (current - 1))}
        />
        <Pagination
          total={total}
          current={current}
          pageSize={size}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={['10', '20', '50', '100', '500']}
          showTotal={(total) => `共 ${total} 条`}
          style={{
            position: 'absolute',
            bottom: '40px',
            right: '40px',
          }}
          onChange={(current, size) => {
            setSize(size as number)
            setCurrent(current)
          }}
        />
      </div>
    </>
  )
}

export const Inspect = forwardRef(RealInspectList)
