/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-08 14:46:41
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-08 14:49:10
 */
import { API_PREFIX } from './constant'
import { Request } from './request'
import { baseDataType, serverType } from './commodity'

// 删除发票明细
export const invoiceDetailDelete = (id: string): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/invoicedetails/delete`,
    method: 'POST',
    body: {
      id,
    },
  }
}
// 查询红冲结果
export interface checkFlushParams {
  id: string
  supplierId: number
  serverType?:serverType
  responseString?:string
}

// 发票明细改状态
export interface invoiceDetailStatusParams {
  id: string
  state: number
}
export const invoiceDetailStatus = (
  body: invoiceDetailStatusParams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/invoicedetails/update`,
    method: 'POST',
    body,
  }
}
export const checkFlush = (body: checkFlushParams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/cancelApplyQuery4Ele`,
    method: 'POST',
    body,
  }
}
export const checkFlushForMbfe = (body: checkFlushParams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/cancelApplyQuery4Ele_${body.serverType}`,
    method: 'POST',
    body,
  }
}
export const dfpExportAsync = (id: any): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/export`,
    method: 'GET',
    params: { id },
  }
}
