
import { API_PREFIX } from "./constant";
import { Request } from "./request";
import { treatmentCostParams } from "./treatmentCost";


export function getTreatmentCostDetail(params: treatmentCostParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/treatmentServiceSaleDetail`,
    method: "GET",
    params,
  };
}