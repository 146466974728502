/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-27 11:16:55
 * @LastEditors: sj
 * @LastEditTime: 2022-09-20 15:40:33
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../app/applicationSlice";
import { RootThunkApi } from "../../app/store";
import * as Te from "../../models/template";
import { TemplateCategory, TemplateDetail } from "../../models/template";
import * as S from "../../services/template";
import { GetTemplateListParams } from "../../services/template";

export const getTemplateCategoryList = createAsyncThunk<
  TemplateCategory[],
  GetTemplateListParams,
  RootThunkApi<TemplateCategory[]>
>("template/getTemplateListStatus", async (params, api) => {
  return sendAsync(S.getTemplateCategoryList(params), api).then(
    (data: Record<string, never>) =>
      _.chain(data.records)
        .map((t) => Te.fromJson(t))
        .value()
  );
});

export const createOrUpdateTemplateCategory = createAsyncThunk<
  string,
  TemplateCategory,
  RootThunkApi<void>
>("template/createOrUpdateTemplateCategory", async (params, api) => {
  return sendAsync(S.createOrUpdateTemplateCategory(params), api);
});

export const getTempalteDetailList = createAsyncThunk<
  TemplateDetail[],
  string,
  RootThunkApi<TemplateDetail[]>
>("template/getTempalteCategoryListStatus", async (params, api) => {
  return sendAsync(S.getTemplateDetailList(params), api).then((data) =>
    _.chain(data.records)
      .map((t) => Te.fromDetailJson(t))
      .value()
  );
});

export const removeTemplateCategory = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>("template/removeTemplateCategoryStatus", async (params, api) => {
  return sendAsync(S.removeTempalteCategory(params), api);
});

export const createOrUpdateTemplateDetail = createAsyncThunk<
  void,
  TemplateDetail,
  RootThunkApi<void>
>("template/createOrUpdateTemplateDetailStatus", async (params, api) => {
  return sendAsync(S.createOrUpdateTemplateDetail(params), api, true);
});

export const removeTemplateDetail = createAsyncThunk<
  void,
  string | undefined,
  RootThunkApi
>("template/removeTemplateDetailStatus", async (params, api) => {
  return sendAsync(S.removeTempalteDetail(params), api);
});

export const commonListAsync = createAsyncThunk<
TemplateDetail[],
  string | undefined,
  RootThunkApi
>('template/commonListAsync', async (params, api) => {
  return sendAsync(S.commonList(params), api).then((data) =>
  _.chain(data.records)
    .map((t) => Te.fromDetailJson(t))
    .value()
);
})

export const commonSaveAsync = createAsyncThunk<
  void,
  S.CommonSaveParams,
  RootThunkApi
>('template/commonSaveAsync', async (params, api) => {
  return sendAsync(S.commonSave(params), api)
})

export const commonRemoveAsync = createAsyncThunk<void, number, RootThunkApi>(
  'template/commonRemoveAsync',
  async (params, api) => {
    return sendAsync(S.commonRemove(params), api)
  }
)