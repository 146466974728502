/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-20 13:58:21
 */
// JD(0, "静滴单"), KLJ(1, "颗粒剂"), REG(2, "挂号小票"),
// XYY(3, "西药方(有诊断)"), LY(4, "领药小票"), WH(5, "雾化单"), YPSY(6, "药品使用小票"),
// ZCY(7, "中草药"), ZDS(8, "诊断书"), ZXD(9, "执行单"),
// RETURN(10,"退费单"),SETTLE(11,"结算单"),XYW(12,"西药方(无诊断)");
export enum PrintTemplateCategory {
  Jd = 0,
  Klj = 1,
  Reg = 2,
  Xyy = 3,
  Ly = 4,
  Wh = 5,
  Ypsy = 6,
  Zcy = 7,
  Zds = 8,
  Zxd = 9,
  Return = 10,
  Settle = 11,
  Xyw = 12,
  Xycfjwzl = 15,
  Xycfjyzl = 16,
  Ybwpcfd = 112,
  Zfwpcfd = 113,
  Gzddy = 131,
  Jgbqdy = 22,
  Hbb = 132,
  Xbcfd = 1001,
  Reg_Zds = 29,
  YHT = 150
}

export function getTemplateCategoryName(
  category: PrintTemplateCategory
): string {
  switch (category) {
    case PrintTemplateCategory.Jd:
      return '静滴单'
    case PrintTemplateCategory.Klj:
      return '颗粒剂'
    case PrintTemplateCategory.Reg:
      return '挂号小票'
    case PrintTemplateCategory.Xyy:
      return '西药方（有诊断）'
    case PrintTemplateCategory.Ly:
      return '领药小票'
    case PrintTemplateCategory.Wh:
      return '雾化单'
    case PrintTemplateCategory.Ypsy:
      return '药品使用小票'
    case PrintTemplateCategory.Zcy:
      return '中草药'
    case PrintTemplateCategory.Zds:
      return '诊断书'
    case PrintTemplateCategory.Zxd:
      return '执行单'
    case PrintTemplateCategory.Return:
      return '退费单'
    case PrintTemplateCategory.Settle:
      return '结算单'
    case PrintTemplateCategory.Xyw:
      return '西药方（无诊断）'
    case PrintTemplateCategory.Xycfjwzl:
      return '西药处方笺(有诊疗)(每页最多8个药品)'
    case PrintTemplateCategory.Xycfjyzl:
      return '西药处方笺(无诊疗)(每页最多8个药品)'
    case PrintTemplateCategory.Ybwpcfd:
      return '医保外配处方单'
    case PrintTemplateCategory.Zfwpcfd:
      return '自费外配处方单'
    case PrintTemplateCategory.Gzddy:
      return '告知单'
    case PrintTemplateCategory.Jgbqdy:
      return '价格标签打印'
    case PrintTemplateCategory.Hbb:
      return '核拨表'
    case PrintTemplateCategory.Xbcfd:
      return '新版处方单(适用打印偏好设置)'
    case PrintTemplateCategory.  Reg_Zds:
      return '挂号小票+病历'
  }
  return ''
}

export interface PrintTemplate {
  id: string
  templateId: string
  defaulttemplate: number
  medicreaCode: string
  state: number
  templateCategory: number
  templateCode: string
  templateDesc: string
  templateName: string
  tenantId?: string
  assigned: boolean
  invoiceType?: string
}

export function fromJson(
  json: { id: number; templateId: number } & Record<string, never>
): PrintTemplate {
  return {
    id: json.id?.toString() || '',
    templateId: json.templateId?.toString() || '',
    defaulttemplate: json.defaulttemplate,
    medicreaCode: json.medicreaCode,
    state: json.state,
    templateCategory: json.templateCategory,
    templateCode: json.templateCode,
    templateDesc: json.templateDesc,
    invoiceType: json.invoiceType,
    templateName: json.templateName,
    assigned: false,
  }
}

export function checkImgExists(imgurl: string) {
    return new Promise(function(resolve, reject) {
      const ImgObj = new Image();
      ImgObj.src = imgurl;
      ImgObj.onload = function(res) {
        resolve(res);
      }
      ImgObj.onerror = function(err) {
        reject(err)
      }
    })
}
