/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-24 18:14:25
 * @LastEditors: linxi
 * @LastEditTime: 2021-04-24 18:15:42
 */
export enum OtcCategory {
    Prescription = 0,
    Otc = 1,
    DualPrescription = 2,
}

export const OtcCategories = [
    OtcCategory.Prescription,
    OtcCategory.Otc,
    OtcCategory.DualPrescription,
]

export function getOtcCategoryName(category: OtcCategory): string {
    switch (category) {
        case OtcCategory.Prescription:
            return "处方药";
        case OtcCategory.Otc:
            return "OTC";
        case OtcCategory.DualPrescription:
            return "处方药双轨";
    }
}
