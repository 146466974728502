/* eslint-disable react/prop-types */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-31 16:01:36
 * @LastEditors: linxi
 * @LastEditTime: 2021-09-03 11:16:10
 */
import React, { useEffect, useState } from "react";
import { DragOutlined } from "@ant-design/icons";
import { ruleSort } from "../../utils/ruleSort";

export interface teethProps {
  style: React.CSSProperties;
  MC?: boolean;
  teethKey?: string;
  defaultTeethKey: string;
  selTeethKey: (key: string) => void;
}

export const TeethSel: React.FC<teethProps> = ({
  MC,
  style,
  teethKey,
  defaultTeethKey,
  selTeethKey,
}) => {
  const [teethSel, setTeethSel] = useState([
    "a",
    "B",
    "F",
    "M",
    "O",
    "D",
    "L",
    "P",
  ]);

  const [selTeethStr, setSelTeethStr] = useState<string[]>([]);

  const selTeeth = (tp: string) => {
    const selIndex = selTeethStr.findIndex((v) => v.substr(0, 1) === teethKey);
    const newStr = selTeethStr[selIndex] ? selTeethStr[selIndex].substr(1) : "";
    const newList = newStr
      ? newStr.split("-").map((v) => (v === "La" ? "a" : v))
      : [];
    const flag = newList.findIndex((v) => v === tp);

    if (flag !== -1) {
      newList.splice(flag, 1);
    } else {
      newList.push(tp);
    }

    const overLayStr = ruleSort(newList)
      .map((v) => (v === "a" ? "La" : v))
      .join("-");

    const teethStr = selTeethStr.map((v, i) => {
      if (i === selIndex) {
        return v.substr(0, 1).concat(overLayStr);
      } else {
        return v;
      }
    });
    selTeethKey(teethStr.join(","));
  };

  const getTeethKey = (tp: string) => {
    const selIndex = selTeethStr.findIndex((v) => v.substr(0, 1) === teethKey);
    const newStr = selTeethStr[selIndex] ? selTeethStr[selIndex].substr(1) : "";
    const newList = newStr
      ? newStr.split("-").map((v) => (v === "La" ? "a" : v))
      : [];
    const flag = newList.findIndex((v) => v === tp);
    return flag !== -1 ? true : false;
  };

  useEffect(() => {
    if (teethKey === "4" || teethKey === "5" || teethKey === "6"|| teethKey === "7" || teethKey === "8") {
      setTeethSel(["a", "B", "F", "M", "O", "D", "L", "P"]);
    } else {
      setTeethSel(["a", "B", "F", "M", "I", "D", "L", "P"]);
    }
  }, [teethKey]);

  useEffect(() => {
    if (defaultTeethKey) {
      setSelTeethStr(defaultTeethKey ? defaultTeethKey.split(",") : []);
    }
  }, [defaultTeethKey]);

  return (
    <div style={{ background: "#f1f3f7", width: "120px", ...style }}>
      <div
        style={{
          borderBottom: "1px solid #d8d8d8",
          display: "flex",
          justifyContent: "center",
          height: "30px",
        }}
      >
        <span style={{ lineHeight: "30px", position: "relative" }}>
          {teethKey ? (
            <>
              <DragOutlined />
              <i
                style={{
                  fontSize: "5px",
                  fontStyle: "normal",
                  position: "absolute",
                  top: "-8px",
                  left: "8px",
                }}
              >
                {teethKey}
              </i>
            </>
          ) : (
            <>暂未选择牙齿</>
          )}
        </span>
      </div>
      <div
        style={{
          padding: "2px",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {teethSel.map((v) => (
          <span
            key={v}
            style={{
              background: getTeethKey(v) ? "#027AFF" : "#fff",
              color: getTeethKey(v) ? "#fff" : "#000",
              border: "none",
              margin: "2px",
              width: "30px",
              lineHeight: "25px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (MC) {
                selTeeth(v);
              }
            }}
          >
            {v === "a" ? "La" : v}
          </span>
        ))}
      </div>
    </div>
  );
};
