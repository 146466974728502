/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-19 15:18:50
 * @LastEditors: sj
 * @LastEditTime: 2022-08-19 17:29:58
 */
import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface outRecipeListParams {
  param?: string
  registrationTimeHead?: string
  registrationTimeTail?: string
  current?: number
  size?: number
}
export function outRecipeList(params: outRecipeListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/recipyCirculatio`,
    method: 'GET',
    params,
  }
}

// 7160
export interface CheckResultParams {
  serverType: serverType
  body: {
    baseData: baseDataType
    ybExternalNo?: number
    undoRea?:string
    treatmentId?:string
    doctorId?:string
    registrationId?:number
  }
}
export function CheckResult(bodyName: CheckResultParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/opsp7160_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 7161
export function buyResult(bodyName: CheckResultParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/opsp7161_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 7104
export function repealRecipe(bodyName: CheckResultParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/opsp7104_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}