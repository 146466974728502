/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-21 15:54:25
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-23 18:32:47
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface replaceMakeOrderListParams {
  size?: number
  current?: number
  param?: string
  createListTimeHead?: string
  createListTimeTail?: string
  state?: number
  status?: number
  boilType?: number
}
// 代煎订单列表
export function replaceMakeOrderList(
  params: replaceMakeOrderListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/list`,
    method: 'GET',
    params,
  }
}

export interface replaceMakeOrderDetailParams {
  id: string
  size: number
  current: number
}
// 代煎订单详情
export function replaceMakeOrderDetail(params: replaceMakeOrderDetailParams): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/detail`,
    method: 'GET',
    params
  }
}

export interface replaceMakeDetailParams {
  current: number
  size: number
  keyWord?: string
  machiningType?: number
  recipeStatus?: number
  startTime?: string
  endTime?: string
}
// 代煎明细列表查询
export function replaceMakeDetail(
  params: replaceMakeDetailParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/getPotionRecipeDrugsDetail`,
    method: 'GET',
    params,
  }
}


export interface replaceMakeRecipeDetailParams {
  current: number
  size: number
  keyWord?: string
  machiningType?: number
  recipeStatus?: number
  startTime?: string
  endTime?: string
}
// 代煎处方明细列表查询
export function replaceMakeRecipeDetail(
  params: replaceMakeRecipeDetailParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/getPotionPrescriptionDetails`,
    method: 'GET',
    params,
  }
}
// 代煎处方明细列表合计行
export function replaceMakeRecipeDetailAmount(
  params: replaceMakeRecipeDetailParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/getPotionPrescriptionDetailSum`,
    method: 'GET',
    params,
  }
}

// 代煎处方明细列表导出
export function replaceMakeRecipeDetailExport(
  params: replaceMakeRecipeDetailParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/potionPrescriptionDetailsExport`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}




// 代煎明细列表合计行
export function replaceMakeDetailAmount(
  params: replaceMakeDetailParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/getPotionRecipeDrugsDetailSum`,
    method: 'GET',
    params,
  }
}

export interface SupplierListResponse {
  appKey: string
  code: string
  createTime: string
  decoctionType: string
  id: number
  machiningType: string
  name: string
  secretKey: string
  status: number
}

// 获取代煎饮片厂
export function supplierList(): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionsupplier/selectList`,
    method: 'GET',
  }
}

// 获取代煎目录价格表
export interface SupplierPriceListParams {
  supplierId?:string
  name?:string
  size?:string
  current?:string
}
export function supplierPriceList(params:SupplierPriceListParams): Request {
  return {
    url: `${API_PREFIX}/blade-potion/drugsupplier/listByTenantId`,
    method: 'GET',
    params
  }
}




// 获取代煎统计
export interface SupplierStatisticsListParams {
  endTime?: string
  startTime?: string
  state?: string
  status?: string
  size?: string
  current?: string
}
export function supplierStatisticsList(body: SupplierPriceListParams): Request {
  return {
    url: `${API_PREFIX}/blade-potion/potionRecipes/getPotionStatistics`,
    method: 'POST',
    body,
  }
}


