/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-30 16:30:54
 * @LastEditors: K
 * @LastEditTime: 2022-09-30 16:36:24
 */

export enum type {
  CZ = 0,
  CZTK = 1,
  XFKK = 2,
  XFTK = 3,
}

export const types = [type.CZ, type.CZTK, type.XFKK, type.XFTK]

export function getTypesName(category: type): string {
  switch (category) {
    case type.CZ:
      return '充值'
    case type.CZTK:
      return '充值退款'
    case type.XFKK:
      return '消费扣款'
    case type.XFTK:
      return '消费扣退款'
  }
}
export enum payType {
  XJ = 0,
  HYK = 1,
}

export const payTypes = [payType.XJ, payType.HYK]

export function getPayTypesName(category: payType): string {
  switch (category) {
    case payType.XJ:
      return '现金'
    case payType.HYK:
      return '会员卡'
  }
}
