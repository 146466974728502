import {
  Button,
  Col,
  notification,
  Popover,
  Select,
  Space,
  Switch,
  TableColumnType,
} from 'antd'
import moment from 'moment'
import { ReadOutlined } from '@ant-design/icons'
import React from 'react'
import { BillingCategoryName } from '../../models/billingCategory'
import './notshow.css'
import {
  RegularOutlined,
  SparePartsOutlined,
} from '../../compnents/icons/Icons'
import {
  getMintenanceResultName,
  getMintenanceStepName,
  getQualityOfCommunityName,
} from '../../models/maintenance'
import { Dimen } from '../../models/dimen'
import { m } from '../../models/m'
import { getChrgitmLvName } from '../../models/commodity'

export const Columns = (
  flag: number,
  startIndex: number
): TableColumnType<any>[] => {
  const layout = {
    width: '14px',
    height: '18x',
    marginRight: '4px',
  }
  return [
    {
      title: '序号',
      dataIndex: '',
      // fixed: 'left',

      width: Dimen.Num,
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    // {
    //   title: '商品编码',
    //   dataIndex: 'no',
    //   // fixed: 'left',
    //   width: Dimen.Coding,
    //   align: 'center',
    // },
    {
      title: '商品信息',
      fixed: 'left',
      render: function Element(_, record) {
        const deadline = moment(record.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        if (record.name == '') {
          return ''
        } else {
          return {
            props: {
              style: {
                maxWidth: 500,
                overflow: 'hidden',
              },
            },
            children: (
              <Space
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Col>
                  <Popover
                    content={
                      <>
                        <div
                          style={{
                            height: '55px',
                            color:
                              record.inventoryAlert &&
                              record.inventoryAlert > 0 &&
                              record.count < record.inventoryAlert
                                ? 'red'
                                : '',
                          }}
                        >
                          <p
                            title={
                              record.name +
                              ' 在库存小于 ' +
                              record.inventoryAlert +
                              record.accountUnit +
                              ' 时预警'
                            }
                            style={{
                              height: '28px',
                              lineHeight: '36px',
                              padding: '0',
                              margin: '0',
                              fontSize: '16px',
                              fontWeight: 'bolder',
                              color:
                                days > 180 ||
                                days > 90 ||
                                days > 30 ||
                                days > 0 ||
                                record.mergeFlag
                                  ? '#333333'
                                  : '#999999',
                            }}
                          >
                            {record.inventoryAlert &&
                            record.inventoryAlert > 0 &&
                            record.count < record.inventoryAlert ? (
                              <span style={{ color: 'red' }}>
                                {record.name}
                              </span>
                            ) : (
                              <span>{record.name}</span>
                            )}

                            {record.inventoryAlert &&
                            record.inventoryAlert > 0 &&
                            record.count < record.inventoryAlert ? (
                              <span style={{ color: 'red' }}>
                                {' （不足' +
                                  record.inventoryAlert +
                                  record.accountUnit +
                                  '）'}
                              </span>
                            ) : (
                              ''
                            )}
                          </p>
                          <p
                            style={{
                              height: '28px',
                              lineHeight: '24px',
                              padding: '0',
                              margin: '0',
                              color: '#999999',
                              fontSize: '14px',
                            }}
                          >
                            {record.tenantSupplierName} / {record.spec} /
                            {record.packSpec} /{record.doseCategory}
                          </p>
                        </div>
                      </>
                    }
                    placement='bottom'
                  >
                    <div
                      style={{
                        height: '55px',
                        color:
                          record.inventoryAlert &&
                          record.inventoryAlert > 0 &&
                          record.count < record.inventoryAlert
                            ? 'red'
                            : '',
                      }}
                    >
                      <p
                        title={
                          record.name +
                          ' 在库存小于 ' +
                          record.inventoryAlert +
                          record.accountUnit +
                          ' 时预警'
                        }
                        style={{
                          height: '28px',
                          lineHeight: '36px',
                          padding: '0',
                          margin: '0',
                          fontSize: '16px',
                          fontWeight: 'bolder',
                          color:
                            days > 180 ||
                            days > 90 ||
                            days > 30 ||
                            days > 0 ||
                            record.mergeFlag
                              ? '#333333'
                              : '#999999',
                        }}
                      >
                        {record.inventoryAlert &&
                        record.inventoryAlert > 0 &&
                        record.count < record.inventoryAlert ? (
                          <span style={{ color: 'red' }}>{record.name}</span>
                        ) : (
                          <span>{record.name}</span>
                        )}

                        {record.inventoryAlert &&
                        record.inventoryAlert > 0 &&
                        record.count < record.inventoryAlert ? (
                          <span style={{ color: 'red' }}>
                            {' （不足' +
                              record.inventoryAlert +
                              record.accountUnit +
                              '）'}
                          </span>
                        ) : (
                          ''
                        )}
                      </p>
                      <p
                        style={{
                          height: '28px',
                          lineHeight: '24px',
                          padding: '0',
                          margin: '0',
                          color: '#999999',
                          fontSize: '14px',
                        }}
                      >
                        {record.tenantSupplierName} / {record.spec} /
                        {record.packSpec} /{record.doseCategory}
                      </p>
                    </div>
                  </Popover>
                </Col>
              </Space>
            ),
          }
        }
      },
      // 商品名/厂家/规格/剂型）name/tenantSupplierName/packSpec/doseCategory
    },
    {
      title: '初始化状态',
      dataIndex: 'approvalNumber',
      width: Dimen.BillingCategory,
      align: 'center',
      render: function Element(_, t) {
        if(t.initFlag == 1){
          return "已初始化";
        }else{
          return "未初始化";
        }
      },
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: Dimen.BatchNo,
      align: 'center',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div
            style={{
              color:
                days > 180 || days > 90 || days > 30 || days > 0 || t.mergeFlag
                  ? '#333333'
                  : '#999999',
            }}
          >
            {t.mergeFlag ? '-' : t.batchNo}
          </div>
        )
      },
    },
    {
      title: '生产日期',
      dataIndex: 'productDate',
      width: Dimen.Time,
      align: 'center',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        if (t.deadline != '') {
          return t.mergeFlag ? (
            '-'
          ) : (
            <div
              style={{
                color:
                  days > 180 ||
                  days > 90 ||
                  days > 30 ||
                  days > 0 ||
                  t.mergeFlag
                    ? '#333333'
                    : '#999999',
              }}
            >
              {moment(t.productDate).format('YYYY-MM-DD')}
            </div>
          )
        } else {
          return ''
        }
      },
    },
    {
      title: '有效期至',
      dataIndex: 'deadline',
      width: Dimen.Time,
      align: 'center',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        if (t.deadline != '') {
          return t.mergeFlag ? (
            '-'
          ) : (
            <div
              style={{
                color:
                  days > 180
                    ? '#000000'
                    : days > 90
                    ? '#027AFF'
                    : days > 30
                    ? '#F5BB4A'
                    : days > 0
                    ? '#FF5D5D'
                    : '#999999',
              }}
            >
              {moment(t.deadline).format('YYYY-MM-DD')}
            </div>
          )
        } else {
          return ''
        }
      },
    },
    {
      title: '库存数量',
      dataIndex: 'count',
      width: Dimen.Count,
      align: 'center',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        if (t.count != '') {
          return (
            <div
              style={{
                color:
                  days > 180 ||
                  days > 90 ||
                  days > 30 ||
                  days > 0 ||
                  t.mergeFlag
                    ? '#333333'
                    : '#999999',
              }}
            >
              {t.count + t.accountUnit}
            </div>
          )
        } else {
          return ''
        }
      },
    },
    {
      title: '拆零数量',
      dataIndex: 'splitCount',
      width: Dimen.Count,
      align: 'center',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div
            style={{
              color:
                days > 180 || days > 90 || days > 30 || days > 0 || t.mergeFlag
                  ? '#333333'
                  : '#999999',
            }}
          >
            {t.splitTag ? t.splitCount + t.splitUnit : '-'}
          </div>
        )
      },
    },
    {
      title: '进价',
      dataIndex: 'unitPrice',
      width: Dimen.Count,
      align: 'right',
      className: flag ? 'notshow' : '',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        if (t.mergeFlag) {
          return '-'
        } else if (t.unitPrice > 0) {
          return (
            <div
              style={{
                color:
                  days > 180 ||
                  days > 90 ||
                  days > 30 ||
                  days > 0 ||
                  t.mergeFlag
                    ? '#333333'
                    : '#999999',
              }}
            >
              {parseFloat(t.unitPrice).toFixed(5)}
            </div>
          )
        } else {
          return '-'
        }
      },
    },
    {
      title: '售价',
      dataIndex: 'retailPrice',
      width: Dimen.Price,
      align: 'right',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div
            style={{
              color:
                days > 180 || days > 90 || days > 30 || days > 0 || t.mergeFlag
                  ? '#333333'
                  : '#999999',
            }}
          >
            {t.mergeFlag ? '-' : parseFloat(t.retailPrice).toFixed(5)}
          </div>
        )
      },
    },
    {
      title: '甲乙分类',
      dataIndex: 'chrgitmLvs',
      width: Dimen.Price,
      align: 'center',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div
            style={{
              color:
                days > 180 || days > 90 || days > 30 || days > 0 || t.mergeFlag
                  ? '#333333'
                  : '#999999',
            }}
          >
            {t.chrgitmLv ? getChrgitmLvName(t.chrgitmLv) : '-'}
          </div>
        )
      },
    },
    {
      title: '医保国码',
      dataIndex: 'hilistCode',
      width: Dimen.BillingCategory,
      align: 'center',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div
            style={{
              color:
                days > 180 || days > 90 || days > 30 || days > 0 || t.mergeFlag
                  ? '#333333'
                  : '#999999',
            }}
          >
            {t.hilistCode}
          </div>
        )
      },
    },
    {
      title: '供应商',
      dataIndex: 'gysName',
      width: Dimen.BillingCategory,
      align: 'center',
      render: function Element(_, t) {
        const deadline = moment(t.deadline)
        const duration = moment.duration(deadline.diff(moment()))
        const days = duration.asDays()
        return (
          <div
            style={{
              color:
                days > 180 || days > 90 || days > 30 || days > 0 || t.mergeFlag
                  ? '#333333'
                  : '#999999',
            }}
          >
            {t.mergeFlag ? '-' : t.gysName}
          </div>
        )
      },
    },

    // {
    //   title: '操作',
    //   dataIndex: '',
    //   fixed: 'right',
    //
    //   width: '20rem',
    //   align: 'center',
    //   // fixed: "right",
    //   render: function Element(_, t) {
    //     if (t.mergeFlag) {
    //       return <></>
    //     } else {
    //       if (t.splitTag) {
    //         return (
    //           <div
    //             style={{
    //               display: 'flex',
    //               alignItems: 'center',
    //               justifyContent: 'center',
    //             }}
    //           >
    //             <Button
    //               type='text'
    //               size='small'
    //               disabled={!t.state ? true : false}
    //               style={{
    //                 fontSize: '14px',
    //                 display: 'flex',
    //                 alignItems: 'center',
    //               }}
    //               icon={
    //                 <SparePartsOutlined
    //                   style={{ fill: '#1CD0BD', ...layout }}
    //                 />
    //               }
    //               onClick={() => {
    //                 if (t.splitTag) {
    //                   onclick('split', t.id)
    //                 } else {
    //                   notification.error({
    //                     message: '当前商品拆零比例为1:1不可拆零操作!',
    //                     duration: 3,
    //                   })
    //                 }
    //               }}
    //             >
    //               拆零
    //             </Button>
    //             <Button
    //               type='text'
    //               size='small'
    //               disabled={!t.state ? true : false}
    //               style={{
    //                 fontSize: '14px',
    //                 display: 'flex',
    //                 alignItems: 'center',
    //               }}
    //               icon={
    //                 <RegularOutlined style={{ fill: '#1CD0BD', ...layout }} />
    //               }
    //               onClick={() => {
    //                 if (t.splitTag) {
    //                   onclick('total', t.id)
    //                 } else {
    //                   notification.error({
    //                     message: '该药品未设置拆零！',
    //                     duration: 3,
    //                   })
    //                 }
    //               }}
    //             >
    //               规整
    //             </Button>
    //             <Button
    //               type='link'
    //               style={{ fontSize: '14px' }}
    //               onClick={() => {
    //                 onclick('detail', t.id)
    //               }}
    //             >
    //               库存详情
    //             </Button>
    //           </div>
    //         )
    //       } else {
    //         return (
    //           <div>
    //             <Button
    //               type='link'
    //               style={{ fontSize: '14px' }}
    //               onClick={() => {
    //                 onclick('detail', t.id)
    //               }}
    //             >
    //               库存详情
    //             </Button>
    //           </div>
    //         )
    //       }
    //     }
    //   },
    // },
  ]
}

export const DoctorColumns = (
  flag: number,
  startIndex: number
): TableColumnType<any>[] => {
  const layout = {
    width: '14px',
    height: '18x',
    marginRight: '4px',
  }
  return [
    {
      title: '序号',
      dataIndex: '',
      width: Dimen.Num,
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    // {
    //   title: '商品编码',
    //   dataIndex: 'no',
    //   width: Dimen.Coding,
    //   align: 'center',
    // },
    {
      title: '商品信息',
      // fixed: "left",
      render: function Element(_, record) {
        return (
          <div
            style={{
              height: '55px',
              color:
                record.inventoryAlert &&
                record.inventoryAlert > 0 &&
                record.count < record.inventoryAlert
                  ? 'red'
                  : '',
            }}
          >
            <p
              title={
                record.name +
                ' 在库存小于 ' +
                record.inventoryAlert +
                record.accountUnit +
                ' 时预警'
              }
              style={{
                height: '28px',
                lineHeight: '36px',
                padding: '0',
                margin: '0',
                fontSize: '16px',
                fontWeight: 'bolder',
              }}
            >
              {record.name}
              {record.inventoryAlert &&
              record.inventoryAlert > 0 &&
              record.count < record.inventoryAlert
                ? ' （不足' + record.inventoryAlert + record.accountUnit + '）'
                : ''}
            </p>
            <p
              style={{
                height: '28px',
                lineHeight: '24px',
                padding: '0',
                margin: '0',
                color: '#999999',
                fontSize: '14px',
              }}
            >
              {record.tenantSupplierName} / {record.spec} /{record.packSpec} /
              {record.doseCategory}
            </p>
          </div>
        )
      },
      // 商品名/厂家/规格/剂型）name/tenantSupplierName/packSpec/doseCategory
    },
    {
      title: '开票项目',
      dataIndex: 'billingCategory',
      width: Dimen.BillingCategory,
      align: 'center',
      render: (billingCategory: any) => {
        return BillingCategoryName(billingCategory)
      },
    },
    {
      title: '批次号',
      dataIndex: 'batchNo',
      width: Dimen.BatchNo,
      align: 'center',
      render: function Element(_, t) {
        return t.mergeFlag ? '-' : t.batchNo
      },
    },
    {
      title: '有效期至',
      dataIndex: 'deadline',
      width: Dimen.Time,
      align: 'center',
      render: function Element(_, t) {
        return t.mergeFlag ? '-' : moment(t.deadline).format('YYYY-MM-DD')
      },
    },
    {
      title: '拆零比例',
      dataIndex: 'splitScale',
      width: Dimen.SplitScale,
      align: 'center',
      render: function Element(_, t) {
        return t.splitTag ? t.splitScale : '-'
      },
    },
    {
      title: '库存数量',
      dataIndex: 'count',
      width: Dimen.Count,
      align: 'center',
      render: function Element(_, t) {
        return t.count + t.accountUnit
      },
    },
    {
      title: '库存数量（拆零）',
      dataIndex: 'splitCount',
      width: Dimen.Count,
      align: 'center',
      render: function Element(_, t) {
        return t.splitTag ? t.splitCount + t.splitUnit : '-'
      },
    },
    {
      title: '进价',
      dataIndex: 'unitPrice',
      width: Dimen.Count,
      align: 'right',
      className: flag ? 'notshow' : '',
      render: function Element(_, t) {
        return t.mergeFlag
          ? '-'
          : t.unitPrice > 0
          ? t.unitPrice
          : // ? parseFloat(t.unitPrice).toFixed(5)
            0
      },
    },
    {
      title: '售价',
      dataIndex: 'retailPrice',
      width: Dimen.Price,
      align: 'right',
      render: function Element(_, t) {
        return t.mergeFlag ? '-' : parseFloat(t.retailPrice).toFixed(5)
      },
    },
  ]
}

export const AdditionColumns = (
  startIndex: number
): // startIndex: number
TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '药品信息',
      align: 'left',
      dataIndex: '',
      key: '',
      render: function Element(_, record) {
        return (
          <div style={{ height: '55px' }}>
            <p
              style={{
                height: '28px',
                lineHeight: '36px',
                padding: '0',
                margin: '0',
                fontSize: '16px',
                fontWeight: 'bolder',
              }}
            >
              {record.mnemonicName}
            </p>
            <p
              style={{
                height: '28px',
                lineHeight: '24px',
                padding: '0',
                margin: '0',
                color: '#999999',
                fontSize: '14px',
              }}
            >
              {moment(record.deadline).format('yyyyMMd')} /
              {record.tenantSupplierName} /{record.approvalNumber}/
              {record.packSpec}
            </p>
          </div>
        )
      },
    },
    {
      title: '批次',
      align: 'center',
      dataIndex: 'batchNo',
      key: 'batchNo',
    },
    {
      title: '库存数量',
      align: 'center',
      dataIndex: 'inventoryCount',
      key: 'inventoryCount',
    },
    {
      title: '养护数量',
      align: 'center',
      dataIndex: 'maintainCount',
      width: '10%',
      key: 'maintainCount',
    },
    {
      title: '不合格数量',
      align: 'center',
      dataIndex: 'belowCount',
      width: '10%',
      key: 'belowCount',
    },
    {
      title: '养护措施',
      align: 'center',
      dataIndex: 'maintainMeasure',
      key: 'maintainMeasure',
      render: function Element(_, t) {
        return JSON.parse(t?.maintainMeasure)
          ?.map((v: any) => {
            return getMintenanceStepName(v)
          })
          .join(',')
      },
    },
    {
      title: '质量状况',
      align: 'center',
      dataIndex: 'maintainQuality',
      key: 'maintainQuality',
      render: function Element(_, t) {
        return getQualityOfCommunityName(t.maintainQuality)
      },
    },
    {
      title: '养护结果',
      align: 'center',
      key: 'maintainResult',
      dataIndex: 'maintainResult',
      render: function Element(_, t) {
        return getMintenanceResultName(t.maintainResult)
      },
    },
  ]
}
