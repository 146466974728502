import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface InspectParams {
  current?: number;
  size?: number;
  treatmentDepartmentId?: string;
  treatmentDoctorId?: string;
  treatmentTimeHead?: string;
  treatmentTimeTail?: string;
}
export function getInspectData(params: InspectParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/secondlog`,
    method: "GET",
    params,
  };
}

export interface ExportExcelParams {
  current?: number;
  size?: number;
  treatmentDepartmentId?: string;
  treatmentDoctorId?: string;
  treatmentTimeHead?: string;
  treatmentTimeTail?: string;

}

// 导出
export function getExportExcel(params: ExportExcelParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/secondlog/exportexcel`,
    method: "GET",
    responseType: "blob",
    params,
  };
}
