/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-13 14:48:52
 * @LastEditors: K
 * @LastEditTime: 2022-10-19 17:11:52
 */
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface recentTimeParams {
  current?: number
  size?: number
  keyword?: string
  storehouseId?: number
  materialsCategory?: number
  deadlineEnd?: string
  deadlineStart?: string
  registrationTimeHead?: string
  registrationTimeTail?: string
  _dateRange: any
  templateCode?: string
  sort?: any
}

export function recentTimepage(
    params: recentTimeParams
): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryledger/deadlinepage`,
        method: "GET",
        params
    }
}
export interface recentTimelogoutlistParams {
    ids: string,state ?:number
}

export function recentTimelogoutlist(
    params: recentTimelogoutlistParams
): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryledger/logoutlist`,
        method: "POST",
        params
    }
}

// 打印

export interface getRecentTimePrintFnParams {
  addInvDeadlinepage: {
    current?: number
    size?: number
    keyword?: string
    storehouseId?: number
    materialsCategory?: number
    deadlineEnd?: string
    deadlineStart?: string
    registrationTimeHead?: string
    registrationTimeTail?: string
    _dateRange: any
  }
  templateCode?: string
  topOffset?: number
  leftOffset?: number
}
export function getRecentTimePrintFn(
    body: getRecentTimePrintFnParams
): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/lodopprint/doPrint`,
        method: "POST",
        body
    }
}
// 导出

export function getRecentTimeOutFn(params: recentTimeParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/deadlinepageExport`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}