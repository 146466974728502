/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-15 16:48:34
 */
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Space,
  notification,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import { recentTimeParams } from '../../services/recentTime'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  RecentTimesRealThreeMonths
} from '../../compnents/form/DateSelect'
import {
  pageDurgsystem,
  selectDurgsystemData,
} from '../durgsystem/durgsystemSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  getRecentTimeOut,
  getRecentTimePrint,
  setCurrent,
} from './recentTimeSlice'
import { PrintOutlined } from '../../compnents/icons/Icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../app/store'
import { DateTimeFormat } from '../../models/datetime'
import moment from 'moment'
import {
  getPrinters,
  initializePrintService,
  printTemplate,
  selectPrintServiceState,
} from '../print/printSlice'
import { LoadingState } from '../../models/common'
const { Option } = Select

export const RecentTimeQuery = (props: {
  onValueChange: (queries: recentTimeParams) => void
  exportExcel: () => void
  outLoading?: any
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()
  const initialized = useSelector(selectPrintServiceState)

  const durgData = useSelector(selectDurgsystemData)

  const [printLoading, setPrintLoading] = useState(false)
  const endTime = moment().add(3, 'months').endOf('days').format(DateTimeFormat)
  useEffect(() => {
    dispatch(pageDurgsystem(1, 100, 1))
    form.setFieldsValue({
      [DateRangeFieldName]: DateRangeType.ThreeMonths,
    })
  }, [])
  useEffect(() => {
    if (initialized === LoadingState.Idle) {
      dispatch(initializePrintService())
        .then(unwrapResult)
        .catch(() => {
          // do nothing.
        })
    }
    if (initialized === LoadingState.Finished) {
      dispatch(getPrinters())
    }
  }, [dispatch, initialized])

  return (
    <Form
      form={form}
      name='control-hooks'
      onFinish={(vs) => {
        props.onValueChange({
          ...form.getFieldsValue(),
          deadlineStart: form.getFieldsValue().registrationTimeHead
            ? form.getFieldsValue().registrationTimeHead
            : moment().format(DateTimeFormat),
          deadlineEnd: form.getFieldsValue().registrationTimeTail
            ? form.getFieldsValue().registrationTimeTail
            : endTime,
          registrationTimeHead: undefined,
          registrationTimeTail: undefined,
        })
      }}
    >
      <Row gutter={11}>
        <Col span={7}>
          <Form.Item name='keyword'>
            <Input
              size='middle'
              placeholder='请输入物资名称，批次号'
              onChange={form.submit}
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='storehouseId'>
            <Select
              placeholder='库房列表'
              allowClear
              showSearch
              // suffixIcon={<CaretDownFilled />}
              onChange={form.submit}
              optionFilterProp='children'
            >
              {durgData.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='materialsCategory'>
            <Select
              placeholder='物资类别'
              onChange={form.submit}
              allowClear
              // suffixIcon={<CaretDownFilled />}
            >
              <Option value={0}>药品</Option>
              <Option value={1}>材料</Option>
              <Option value={2}>器械</Option>
              <Option value={3}>其他</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <DateSelect
            style={{ width: '120px' }}
            placeholder='有效期至'
            allowClear={false}
            labelPrefix=''
            options={RecentTimesRealThreeMonths}
            namePrefix='registrationTime'
            onChange={form.submit}
          />
        </Col>
        <Col span={2} style={{ float: 'right' }}>
          <Space>
            <Button
              type='text'
              style={{
                color: '#666',
                display: 'flex',
                alignItems: 'center',
              }}
              loading={printLoading}
              onClick={async () => {
                // setPrintLoading(true)
                await dispatch(
                  printTemplate({
                    addInvDeadlinepage: {
                      ...form.getFieldsValue(),
                      deadlineStart: form.getFieldsValue().registrationTimeHead
                        ? form.getFieldsValue().registrationTimeHead
                        : moment().format(DateTimeFormat),
                      deadlineEnd: form.getFieldsValue().registrationTimeTail
                        ? form.getFieldsValue().registrationTimeTail
                        : endTime,
                      registrationTimeHead: undefined,
                      registrationTimeTail: undefined,
                    },
                    leftOffset: 0,
                    topOffset: 0,
                    templateCode: 'supplyDeadLine',
                    preview: true
                  })
                )
              }}
            >
              <PrintOutlined
                style={{
                  fill: '#666',
                  width: '18px',
                  height: '18px',
                  marginRight: '4px',
                }}
              />
              打印
            </Button>
            <Button
              type='primary'
              loading={props?.outLoading}
              onClick={() => {
                 props?.exportExcel()
              }}
            >
              导出
            </Button>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}
