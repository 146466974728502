
import { Category } from "../models/category";
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface RecevingParams {
  id?: number
  current?: number
  size?: number
  total?: number
  category?: Category
  mnemonicCode?: string
  name?: string
  timeHead?: string
  timeTail?: string
  pages?: number
  hitCount?: boolean
  acceptId?: string
  acceptPutNumber?: string
  acceptStorehouseId?: string
  acceptStorehouseName?: string
  departmentId?: string
  departmentName?: string
  flag?: string
  putNumber?: string
  realPayMoney?: number
  recepitsDates?: string
  recepitsNumber?: string
  remark?: string
  shouldPayMoney?: number
  status?: string
  storehouseId?: string
  storehouseName?: string
  storehouseTag?: string
  supplierId?: string
  supplierName?: string
  tenantId?: string
  upAccountTime?: string
  userId?: string
  userName?: string
  searchCount?: string
}
export function getReceive(params: RecevingParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/receive`,
    method: "GET",
    params,
  };
}
