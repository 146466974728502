/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-03 17:52:24
 */
import { Gender } from './user'

// NOT(0, "未知"), NO(1, "未婚"), YES(2, "已婚");
export enum MaritalStatus {
  Unknown = 0,
  Single = 1,
  Married = 2,
}

export function getMaritalStatusName(status: MaritalStatus): string {
  switch (status) {
    case MaritalStatus.Unknown:
      return '未知'
    case MaritalStatus.Single:
      return '未婚'
    case MaritalStatus.Married:
      return '已婚'
  }
  return ''
}

export enum patientSource {
  unKnow = 0,
  QYTJ = 1,
  MTBD = 2,
  HDTG = 3,
}

export const patientSources = [
  patientSource.unKnow,
  patientSource.QYTJ,
  patientSource.MTBD,
  patientSource.HDTG,
]

export const getPatientSourceName = (type: number): string => {
  switch (type) {
    case patientSource.unKnow:
      return '未知'
    case patientSource.QYTJ:
      return '亲友推荐'
    case patientSource.MTBD:
      return '媒体报道'
    case patientSource.HDTG:
      return '活动推广'
    default:
      return '-'
  }
}
export interface Patient {
  id: string
  name: string
  status: number
  sex: Gender
  phone: string
  address: string
  ageMonth: number
  ageYear: number
  birthday: string
  companyName: string
  idCardNumber: string
  emergencyContactName: string
  emergencyContactPhone: string
  tenantPatientCardId: string
  labelName: string
  maritalStatus: number
  nation: string
  emailAddress: string
  remark: string
  totalPayAmount?: number
  arrearageAmount?: number
  source?: string
  labelVo?: any
  mail?: string
  relationship?: string
  relationshipEmergencyContact?: string
  qq?: string
  job?: string
  groupId?: string
  star?: string
  introducer?: string
  introducerType?: string
  counselor?: string
  counselorId?: number
  pastMedicalHistory?: string
  allergyHistory?: string
  toothCleaningHabit?: string
  treatmentHistory?: string
  toothBrushingFrequency?: string
  toothBrushingTime?: string
  smokeFrequency?: string
  treatmentItem?: string
  attendingDoctor?: string
  attendingDoctorId?: number
  height?: string
  weight?: string
  outpatientNum?: number
  treatmentTime?: string
  operationNum?: number
  wechat?: number | string
  personalHistory?: string
  familyHistory?: string
  vaccineHistory?: string
  menstrualHistory?: string
  maritalHistory?: string
  cityCode?: any
  contryCode?: any
  provinceCode?: any
  patientId?: any
  provinceName?: any
  cityName?: any
  contryName?: any
  sameDayRegistrationFee?:any
  specialDiseaseInformation?:any
}

export interface AppointmentPatient {
  id: string
  name: string
  status: number
  sex: Gender
  phone: string
  address: string
  ageMonth: number
  ageYear: number
  birthday: string
  companyName: string
  idCardNumber: string
  emergencyContactName: string
  emergencyContactPhone: string
  tenantPatientCardId: string
  labelName: string
  maritalStatus: number
  nation: string
  emailAddress: string
  remark: string
  appointmentDepartmentId?: string
  appointmentDoctorId?: string
  appointmentType?: number
  receiveTag?: number
  appointmentStartTime?: string
  appointmentEndTime?: string
  appointmentComment?: string
  scheduleId?: string
  source?: string
  appointmentDate?: string
  appointmentItem?: string

}

export function fromJson(json: any): Patient {
  return {
    id: json.id?.toString() || '',
    name: json.name,
    status: json.status,
    sex: json.sex === -1 ? Gender.Unknown : json.sex,
    phone: json.phone,
    address: json.address,
    ageMonth: json.ageMonth > 0 ? json.ageMonth : '',
    ageYear: json.ageYear > 0 ? json.ageYear : 0,
    birthday: json.birthday,
    companyName: json.companyName,
    idCardNumber: json.idCardNumber,
    emergencyContactName: json.emergencyContactName,
    emergencyContactPhone: json.emergencyContactPhone,
    tenantPatientCardId: json.tenantPatientCardId,
    labelName: json.labelName,
    maritalStatus:
      json.maritalStatus === -1 ? MaritalStatus.Unknown : json.maritalStatus,
    nation: json.nation,
    emailAddress: json.emailAddress,
    remark: json.remark,
    source: json.source,
    totalPayAmount: json.totalPayAmount,
    labelVo: json.labelVo,
    mail: json.mail,
    wechat: json.wechat,
    relationship: json.relationship,
    relationshipEmergencyContact: json.relationshipEmergencyContact,
    qq: json.qq,
    job: json.job,
    groupId: json.groupId,
    star: json.star,
    introducer: json.introducer,
    introducerType: json.introducerType,
    counselor: json.counselor,
    counselorId: json.counselorId,
    pastMedicalHistory: json.pastMedicalHistory,
    allergyHistory: json.allergyHistory,
    toothCleaningHabit: json.toothCleaningHabit,
    treatmentHistory: json.treatmentHistory,
    toothBrushingFrequency: json.toothBrushingFrequency,
    toothBrushingTime: json.toothBrushingTime,
    smokeFrequency: json.smokeFrequency,
    treatmentItem: json.treatmentItem,
    attendingDoctor: json.attendingDoctor,
    attendingDoctorId: json.attendingDoctorId,
    height: json.height,
    weight: json.weight,
    outpatientNum: json.outpatientNum,
    treatmentTime: json.treatmentTime,
    operationNum: json.operationNum,
    personalHistory: json.personalHistory,
    familyHistory: json.familyHistory,
    vaccineHistory: json.vaccineHistory,
    menstrualHistory: json.menstrualHistory,
    maritalHistory: json.maritalHistory,
    cityCode: json.cityCode,
    contryCode: json.contryCode,
    provinceCode: json.provinceCode,
    patientId: json.patientId,
    sameDayRegistrationFee:json?.sameDayRegistrationFee,
    specialDiseaseInformation:json?.specialDiseaseInformation
  }
}

export function fromAppointmentJson(json: any): AppointmentPatient {
  return {
    id: json.id?.toString() || '',
    name: json.name,
    status: json.status,
    sex: json.sex === -1 ? Gender.Unknown : json.sex,
    phone: json.phone,
    address: json.address,
    ageMonth: json.ageMonth > 0 ? json.ageMonth : '',
    ageYear: json.ageYear > 0 ? json.ageYear : 0,
    birthday: json.birthday,
    companyName: json.companyName,
    idCardNumber: json.idCardNumber,
    emergencyContactName: json.emergencyContactName,
    emergencyContactPhone: json.emergencyContactPhone,
    tenantPatientCardId: json.tenantPatientCardId,
    labelName: json.labelName,
    maritalStatus:
      json.maritalStatus === -1 ? MaritalStatus.Unknown : json.maritalStatus,
    nation: json.nation,
    emailAddress: json.emailAddress,
    remark: json.remark,
    appointmentDepartmentId: json.appointmentDepartmentId,
    appointmentDoctorId: json.appointmentDoctorId,
    appointmentType: json.appointmentType,
    receiveTag: json.furtherConsideration ? 1 : 0,
    appointmentStartTime: json.appointmentStartTime,
    appointmentEndTime: json.appointmentEndTime,
    appointmentComment: json.appointmentComment,
    scheduleId: json.scheduleId,
    source: json.source,
    appointmentDate: json.appointmentDate,
    appointmentItem: json.appointmentItem,

  }
}

export function toJson(patient: Patient): any {
  return {
    id: patient.id,
    name: patient.name,
    sex: patient.sex,
    phone: patient.phone,
    address: patient.address,
    ageMonth: patient.ageMonth,
    ageYear: patient.ageYear,
    birthday: patient.birthday,
    idCardNumber: patient.idCardNumber,
    companyName: patient.companyName,
    emergencyContactName: patient.emergencyContactName,
    emergencyContactPhone: patient.emergencyContactPhone,
    tenantPatientCardId: patient.tenantPatientCardId,
    labelName: patient.labelName,
    maritalStatus: patient.maritalStatus,
    nation: patient.nation,
    emailAddress: patient.emailAddress,
    remark: patient.remark,
    source: patient.source,
    provinceCode: patient.provinceCode,
    cityCode: patient.cityCode,
    contryCode: patient.contryCode,
  }
}

export const gitTitleList = (type: number): any[] => {
  let list: any[] = []
  if (type == 1) {
    list = [
      {
        label: '合并的患者姓名',
        value: 'name',
      },
      {
        label: '年龄',
        value: 'ageYear',
      },
      {
        label: '性别',
        value: 'sex',
      },
      {
        label: '身高',
        value: 'height',
      },
      {
        label: '体重',
        value: 'weight',
      },
      {
        label: '民族',
        value: 'nation',
      },
      {
        label: '婚姻状况',
        value: 'maritalStatus',
      },
      {
        label: '邮箱地址',
        value: 'emailAddress',
      },
      {
        label: '微信号',
        value: 'wechat',
      },
      {
        label: '联系方式',
        value: 'phone',
      },
      {
        label: '身份证号',
        value: 'idCardNumber',
      },
      {
        label: '工作单位',
        value: 'companyName',
      },
      {
        label: '地址',
        value: 'address',
      },
      {
        label: '紧急联系人',
        value: 'emergencyContactName',
      },
      {
        label: '紧急联系人电话',
        value: 'emergencyContactPhone',
      },
      {
        label: '患者来源',
        value: 'source',
      },
      {
        label: '备注',
        value: 'remark',
      },
    ]
  } else if (type == 2) {
    list = [
      {
        label: '合并的患者姓名',
        value: 'name',
      },
      // {
      //   label: '病历号',
      //   value: 'ageYear',
      // },
      {
        label: '年龄',
        value: 'ageYear',
      },
      {
        label: '性别',
        value: 'sex',
      },
      {
        label: '联系方式',
        value: 'phone',
      },
      {
        label: '身份证号',
        value: 'idCardNumber',
      },
      {
        label: '紧急联系方式',
        value: 'emergencyContactPhone',
      },
      {
        label: 'QQ',
        value: 'qq',
      },
      {
        label: '微信',
        value: 'wechat',
      },
      {
        label: '邮箱地址',
        value: 'emailAddress',
      },
      {
        label: '家庭地址',
        value: 'address',
      },
      {
        label: '职业',
        value: 'job',
      },
      {
        label: '工作单位',
        value: 'companyName',
      },
      {
        label: '患者来源',
        value: 'source',
      },
      {
        label: '星级',
        value: 'star',
      },
      {
        label: '介绍人',
        value: 'introducer',
      },
      {
        label: '咨询师',
        value: 'counselor',
      },
      {
        label: '既往史',
        value: 'pastMedicalHistory',
      },
      {
        label: '过敏史',
        value: 'allergyHistory',
      },
      {
        label: '洁牙习惯',
        value: 'toothCleaningHabit',
      },
      {
        label: '就诊经历',
        value: 'treatmentHistory',
      },
      {
        label: '刷牙',
        value: 'toothBrushingTime',
      },
      {
        label: '吸烟',
        value: 'smokeFrequency',
      },
      {
        label: '就诊项目',
        value: 'treatmentItem',
      },
      {
        label: '主治医生',
        value: 'attendingDoctor',
      },
      {
        label: '备注',
        value: 'remark',
      },
    ]
  } else {
    list = []
  }
  return list
}
