/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-01-04 18:12:22
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-11 16:21:56
 */
// 统一的时间格式
export const DateTimeFormat = "YYYY/MM/DD HH:mm:ss";
export const DateTimeFormatSimple = "YYYY/MM/DD";
export const DateTimeFormatSimpleText = 'YYYY年MM月DD日'
export const DateTimeFormatSimpleOnly = "YYYY-MM-DD";
export const DateTimeFormatSimpleOnlyOne = "YYYY-MM-DD HH:mm:ss";
export const DateTimeFormatSimplePrintCharge = 'YYYY/MM/DD HH:mm'
export const DateTimeFormatSimpleOne = 'YYYYMMDD'

