import { Col, Form, notification, Row, Table } from 'antd'
import { unwrapResult } from '@reduxjs/toolkit'
import moment from 'moment'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'
import styles from './sellingExpenseSummary.module.css'
import {
  getSellingExpenseSummaryExport,
  getSellingExpenseSummaryList,
} from './sellingExpenseSummarySlice'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { TabBar, TabPane } from '../../../compnents/widgets/TabBar'
import { DateRangeType, getDateRange } from '../../../compnents/form/DateSelect'
import { sellingExpenseSummaryParams } from '../../../services/sellingExpenseSummary'
import { Columns, PrintColumns } from './column'
import { Query } from './Query'
import { selectTenantName } from '../../../app/applicationSlice'
import { PrintBody } from '../../../models/sellingExpenseSummary'

export const SellingExpenseSummaryTab = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const printRef = useRef<PrintFrameRef>(null)

  const tenantName = useSelector(selectTenantName)

  const [value, setValue] = useState<any>('0')

  const [timeHead, timeTail] = getDateRange(DateRangeType.ThisMonth);

  const [page, setPage] = useState<any>()

  const [timeFlag, setTimeFlag] = useState<any>('0')

  const [queries, setQueries] = useState<sellingExpenseSummaryParams>({
    current: 1,
    size: 10,
    timeHead: timeHead,
    timeTail: timeTail,
    timeType: '1',
  })

  // const page = {
  //   items: [{ name: 1 }],
  //   total: 10,
  // }

  const [pageLoading, setPageLoading] = useState<any>()

  useEffect(() => {
    if (value == '0') {
      if (queries?.timeHead) {
        const endTime = moment(queries?.timeHead).add(6, 'month').endOf('month')
        if (moment(queries?.timeTail) > endTime) {
          notification.info({
            message: '一次最多只能查询6个月的数据',
        })
      } else {
        setPageLoading(true);
        dispatch(getSellingExpenseSummaryList({ ...queries }))
          .then(unwrapResult)
          .then((v: any) => {
            setPage({
              items: v?.records,
              total: v?.total,
            });
            setPageLoading(false);
          });
        }
      }
    }
  }, [queries, value])

  const sellingExpenseSummaryExport = () => {
    dispatch(getSellingExpenseSummaryExport(queries))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '销售费用汇总.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

    const titleList = page?.items?.[0]?.customizePay?.map((v: any) => {
      return { payMethodName: v?.payMethodName, payMethod: v?.payMethod }
    })

  const dataMainList = {
    tenantName: tenantName?.split('-')?.[0],
    tjTimes:
      (queries.timeHead
        ? queries.timeHead
        : moment().startOf('month').format(DateTimeFormat)) +
      '~' +
      (queries.timeTail
        ? queries.timeTail
        : moment().endOf('month').format(DateTimeFormat)),
    dataList: page?.items?.map((v: any) => {
      return {
        ...v,
        date:
          timeFlag == 0
            ? v?.year + '年'
            : timeFlag == 3
            ? '第' + v?.quarter + '季度'
            : timeFlag == 1
            ? v?.month + '月'
            : timeFlag == 2
            ? v?.date
            : '',
        totalShouldPayAmount: v.totalShouldPayAmount
          ? parseFloat((v.totalShouldPayAmount as unknown) as string).toFixed(2)
          : 0,
        totalRealPayAmount: v.totalRealPayAmount
          ? parseFloat((v.totalRealPayAmount as unknown) as string).toFixed(2)
          : 0,
        totalInsurancePayAmount: v.totalInsurancePayAmount
          ? parseFloat(
              (v.totalInsurancePayAmount as unknown) as string
            ).toFixed(2)
          : 0,
        cash: v.cash ? parseFloat((v.cash as unknown) as string).toFixed(2) : 0,
        aggrPay: v.aggrPay ? v.aggrPay?.toFixed(2) : '0.00',
        alipay: v.alipay
          ? parseFloat((v.alipay as unknown) as string).toFixed(2)
          : 0,
        memberPay: v.memberPay ? v.memberPay?.toFixed(2) : '0.00',
        wxPay: v.wxPay
          ? parseFloat((v.wxPay as unknown) as string).toFixed(2)
          : 0,
        unionPay: v.unionPay
          ? parseFloat((v.unionPay as unknown) as string).toFixed(2)
          : 0,
        otherPay: v.otherPay
          ? parseFloat((v.otherPay as unknown) as string).toFixed(2)
          : 0,
      }
    }),
    titleList:titleList,
  }

  const XSFYHZContent = PrintBody(dataMainList)

  return (
    <>
      <TabBar
        defaultValue={value}
        style={{ margin: "10px 20px", position: "relative" }}
        onChange={(e) => {
          setValue(e);
        }}
      >
        <TabPane key="0" tab="销售费用汇总">
          <div className={styles.contents}>
            <Query
              onValueChange={(v: any, timeFlag: any) => {
                setQueries({
                  ...queries,
                  ...v,
                  // timeHead: v?.timeHead ? v?.timeHead : timeHead,
                  // timeTail: v?.timeTail ? v?.timeTail : timeTail,
                });
                setTimeFlag(timeFlag);
              }}
              print={() => {
                // printRef.current?.print()
                const page: any = window.open("", "_blank"); // 打开一个新窗口，用于打印
                page.document.write(XSFYHZContent); // 写入打印页面的内容
                page.print(); // 打印
                page?.close();
              }}
            />
            <EditableList
              className={styles.sellingExpenseSummaryMain}
              bordered
              loading={pageLoading}
              rowKey={(_, i) =>
                queries
                  ? (queries.size || 0) * (queries.current || 0) + (i || 0)
                  : 0
              }
              page={{
                items: page?.items,
                current: queries.current || 1,
                size: queries?.size || 10,
                total: page?.total,
              }}
              columns={Columns(
                queries?.size * (queries?.current - 1),
                timeFlag,
                page?.items
              )}
              onChangePage={(current: any, pageSize: any) => {
                setQueries({
                  ...queries,
                  current,
                  size: pageSize,
                });
              }}
            />
          </div>
        </TabPane>
        <p style={{ position: "absolute", right: "20px", top: "10px" }}>
          备注：一次最多导出6个月的数据，如果您想导出更多数据，请分次导出。
        </p>
      </TabBar>

      {/* <PrintFrame
        ref={printRef}
        title={
          JSON.parse(localStorage.getItem('credentials') as string)?.tenantName
        }
        subTitle='销售费用汇总'
      >
        <Row
          style={{
            marginBottom: '10px',
            lineHeight: '32px',
          }}
        >
          <Col span={12}>
            统计时段:
            {queries.timeHead
              ? queries.timeHead
              : moment().startOf('month').format(DateTimeFormat)}
            ~
            {queries.timeTail
              ? queries.timeTail
              : moment().endOf('month').format(DateTimeFormat)}
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            打印日期:{moment().format(DateTimeFormatSimple)}
          </Col>
        </Row>
        <EditableList
          style={{ height: 'auto' }}
          // className={styles.sellingExpenseSummaryMains}
          bordered
          pagination={false}
          rowKey={(_, i) =>
            queries
              ? (queries.size || 0) * (queries.current || 0) + (i || 0)
              : 0
          }
          page={{
            items: page?.items,
            current: queries.current,
            size: queries?.size,
            total: page?.total,
          }}
          columns={PrintColumns(
            queries?.size * (queries?.current - 1),
            timeFlag
          )}
        />
      </PrintFrame> */}
    </>
  );
}
