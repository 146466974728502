export enum Bool {
  No = 0,
  Yes = 1,
}

export enum LoadingState {
  Idle = 0,
  Loading = 1,
  Finished = 2,
  Error = 3,
}
