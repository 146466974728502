import React, {
    ReactElement, useEffect, useMemo, useState,
} from "react";
import {NavBar} from "../../../compnents/nav/NavBar";
import {PurchaseControlQuery} from "./query";
import {SupplierDealingParams} from "../../../services/supplierDealing";
import {useDispatch, useSelector} from "react-redux";
import {RootDispatch} from "../../../app/store";
import {unwrapResult} from "@reduxjs/toolkit";
import {notification, Table} from "antd";
import styles from "./purchaseControl.module.css";
import {EditableList} from "../../../compnents/list/EditableList";
import {PurchaseControlColumns} from "./columns";
import {
    getPurchaseControlList,
    selectPageLoading,
    selectTotal,
    selectCurrent,
    selectPurchaseControlsData,
    setCurrent,
    setPageLoading
} from "./purchaseControlSlice";
import {PurchaseControlParams} from "../../../services/purchaseControl";
import {getExportListExoprt} from "./purchaseControlSlice";
import {useHistory, useLocation} from "react-router-dom";


export const PurchaseControl = (): ReactElement => {

    const dispatch = useDispatch<RootDispatch>();

    const [queries, setQueries] = useState<PurchaseControlParams>();

    const Current = useSelector(selectCurrent);

    const [size, setSize] = useState(10);

    const total = useSelector(selectTotal);

    const data = useSelector(selectPurchaseControlsData);

    const pageLoadingRX = useSelector(selectPageLoading);

    const history = useHistory()

    const location: any = useLocation()

    const onExport = () => {
        dispatch(getExportListExoprt({ ...queries,current: Current, size: size }))
            .then(unwrapResult)
            .then((res: any) => {
                const content = res
                const blob = new Blob([content])
                const fileName = '进货管理.xlsx'
                const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
                const elink = document.createElement('a')
                if ('download' in elink) {
                    elink.download = fileName
                    elink.style.display = 'none'
                    elink.href = selfURL['createObjectURL'](blob)
                    document.body.appendChild(elink)
                    // 触发链接
                    elink.click()
                    selfURL.revokeObjectURL(elink.href)
                    document.body.removeChild(elink)
                } else {
                    (navigator as any).msSaveBlob(blob, fileName)
                }
            })
            .then(() => {
                notification.success({
                    message: '导出成功',
                    duration: 3,
                })
            })
            .catch((res: any) => {
                return
            })
    }

    useEffect(() => {
        if (queries?.timeHead) {
            dispatch(setPageLoading(true))
            dispatch(getPurchaseControlList({ ...queries, current: Current, size }));
            setTimeout(() => {
                dispatch(setPageLoading(false))
            }, 3000)
            sessionStorage.setItem(
                location.pathname,
                JSON.stringify({...queries, current: Current, size})
            )
        }
    }, [queries, size, Current]);


    return (
        <>
            <NavBar
                style={{ margin: "10px 20px 0px 20px" }}
                where={["供应商管理", "进货管理"]}
                backtrace={{
                    name: "库房工作站",
                    path: "/storage/station",
                    state: {
                        station: "8",
                    },
                }}
            />
            <div className={styles.content}>
                <PurchaseControlQuery
                    onValueChange={(v) => {
                        setQueries({ ...queries, ...v });
                        dispatch(setCurrent(1));
                    }}
                    onExport={() => { onExport() }}
                />
                <EditableList
                    className={styles.supper}
                    loading={pageLoadingRX}
                    rowKey={(t: any) => t.id}
                    bordered
                    page={{
                        items: data,
                        current: Current,
                        size: size,
                        total: total,
                    }}
                    onChangePage={(current, pageSize) => {
                        setSize(pageSize as number);
                        dispatch(setCurrent(current));
                    }}
                    columns={PurchaseControlColumns((supplierId : any) => {
                        history.push({
                            pathname: '/PurchaseControlDetail',
                            state: {
                                supplierId: supplierId
                            }
                        })
                    },size * (Current - 1))}

                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row className={styles.total}>
                                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                <Table.Summary.Cell index={1} align='center'>合计</Table.Summary.Cell>
                                <Table.Summary.Cell index={2} align='center'>
                                    { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.totalPurchasePrice || 0), 0), [data]).toFixed(2)}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3} align='center'>
                                    { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.totalSellingPrice || 0), 0), [data]).toFixed(2)}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} align='center'>
                                    { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.varieties || 0), 0), [data]).toFixed(2)}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} align='center'>
                                    { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.count || 0), 0), [data]).toFixed(2)}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                            </Table.Summary.Row>
                            {/* )} */}
                        </Table.Summary>
                    )}
                />
            </div>
        </>
    );
}