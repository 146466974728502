/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-18 09:53:09
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-28 15:30:35
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, notification, Table } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { traceRoute } from '../../../layouts/layoutSlice'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getJuhePage } from '../confirm/paymentConfirmSlice'
import { getPaymentListExoprt } from '../list/paymentSlice'
import { Columns } from './columns'
import { Query } from './Query'
import styles from './oweMoney.module.css'
import { RecipeStatus } from '../../../models/recipe'
import { selectTenantName, selectUserName } from '../../../app/applicationSlice'
import { printBody } from '../../../models/putstorelistPrint'
import { oweMonePagePrintBody } from '../../../models/oweMonePagePrint'
import { getGenderName } from '../../../models/user'
import { getAge } from '../../../utils/StringUtils'
import { getPayFlagName } from '../../../models/payment'

interface OweMonePageProps {
  status: number
}
export const OweMonePage = ({ status }: OweMonePageProps): ReactElement => {
  const history = useHistory()

  const theme = useContext(ThemeContext)

  const tenantName = useSelector(selectTenantName)

  const userName = useSelector(selectUserName)

  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({
    current: 0,
    size: 10,
    arrearageFlg: 1,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const [scrollHeight, setScrollHeight] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    //缓存状态
    params.current &&
      sessionStorage.setItem(
        location.pathname,
        JSON.stringify({ ...params, state: status, arrearageFlg: undefined })
      )
  }, [params, status])

  const getPage = () => {
    //欠费管理列表
    setPageLoading(true)
    dispatch(getJuhePage(params))
      .then(unwrapResult)
      .then((res) => {
        onResize() // 初始化设置高度
        window.addEventListener('resize', onResize)
        const data = {
          total: res.total,
          items: res.records,
        }
        setPage(data)
        if (params.param?.length == 9 && res.records.length == 1) {
          //病历号跳转
          setParams({ ...params, param: '' })
          setTimeout(() => {
            history.push(
              `/payment/oweMoney?paymentId=${res.records[0]?.paymentId}`
            )
          }, 100)
        }
      })
      .finally(() => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    if (params.current != 0) {
      getPage()
    }
  }, [params])

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const outCluedExport = () => {
    dispatch(
      getPaymentListExoprt({ ...params, size: undefined, arrearageFlg: 1 })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '欠费管理.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const onResize = () => {
    const table = document.getElementById(
      status == RecipeStatus.oweMone ? 'tableHeightOweMonePage' : ''
    )
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 20
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }

  const dataMainList = {
    tenantName: tenantName,
    userName: userName,
    dataList: page.items?.map((v: any) => {
      return {
        ...v,
        outpatientNo: v?.registration.outpatientNo,
        patientName: v?.treatment.patientName,
        patientId: v?.registration.patientId,
        patientSex: getGenderName(v?.treatment.patientSex),
        patientAge: getAge(
          v?.treatment?.patientAge,
          v?.treatment?.patientMonth
        ),
        insuranceCode:
          v?.registration.insuranceCode === 'ZIFEI' ||
          !v?.registration.insuranceCode
            ? '自费'
            : '智慧医保',
        settlementCategory: getPayFlagName(v?.treatment.settlementCategory),
        disease: v?.treatment?.disease,
        treatmentDepartmentName: v?.treatment.treatmentDepartmentName,
        treatmentDoctorName: v?.treatment.treatmentDoctorName,
        accountingStatus: v?.accountingStatus === 0 ? '已结清' : '欠费中',
        registrationTime: v.registration.registrationTime,
        arrearageAmount:
          v?.arrearageAmount === undefined
            ? ''
            : v?.arrearageAmount?.toFixed(2),
        lastPayTime: v?.treatment.lastPayTime,
      }
    }),
  }

  const content = oweMonePagePrintBody(dataMainList)

  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)',
        marginTop: '10px',
        overflow: 'hidden',
        padding: '10px 15px 0 15px',
      }}
    >
      <Query
        outExportExcel={() => {
          outCluedExport()
        }}
        print={() => {
          const page: any = window?.open('', '_blank') // 打开一个新窗口，用于打印
          page?.document?.write(content) // 写入打印页面的内容
          page?.print() // 打印
          page?.close()
        }}
        status={status}
        onValueChange={(v) => setParams({ ...params, ...v, current: 1 })}
      />
      <div
        id={status == RecipeStatus.oweMone ? 'tableHeightOweMonePage' : ''}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <EditableList
          style={{
            width: '100%',
            height: '89%',
            overflow: 'hidden',
          }}
          scroll={{ y: Number(scrollHeight) }}
          loading={pageLoading}
          page={{
            current: params.current,
            size: params.size,
            total: page.total,
            items: page.items,
          }}
          columns={Columns(params.size * (params.current - 1), (action, t) => {
            if (action == 'detail') {
              dispatch(
                traceRoute({
                  name: '收费',
                  path: '/payment/result',
                  query: `paymentId=${t.paymentId}&&status=${status}`,
                  state: {
                    name: '欠费管理',
                    path: '/payment',
                    routeName: '欠费管理',
                  },
                })
              )
            } else if (action == 'supplementary') {
              history.push(`/payment/oweMoney?paymentId=${t.paymentId}`)
              // dispatch(
              //   traceRoute({
              //     name: '补交',
              //     path: '/payment/oweMoney',
              //     query: `paymentId=${t.paymentId}`,
              //   })
              // )
            }
          })}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell
                  index={0}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={1}
                  align='center'
                  className={styles.fixed}
                >
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={3}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={4}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={5}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={6}
                  className={styles.fixed}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={7}
                  className={styles.fixed}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={8}
                  className={styles.fixed}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={9}
                  className={styles.fixed}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={10}
                  className={styles.fixed}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={11}
                  className={styles.fixed}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={12}
                  className={styles.fixed}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={13}
                  className={styles.fixed}
                  align='right'
                >
                  {page?.items?.[0]?.amountInArrears?.toFixed(2) || '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={14}
                  className={styles.fixed}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={15}
                  align='right'
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          onChangePage={(current, size) =>
            setParams({
              ...params,
              current,
              size: size || 10,
            })
          }
          onRow={() => ({
            onDoubleClick: () => {
              return
            },
          })}
        />
      </div>
    </Col>
  )
}
