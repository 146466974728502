/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-06 19:10:14
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-02 15:39:50
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Modal, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { DeliveryItem } from '../delivery/deliverySlice'
import { newData } from '../putstorelist/newdata'
import { InventoryPriceColumns } from './columns'
import styles from './InventoryPrice.module.css'
import { submitInventory, submitInventoryAsync } from './inventoryPriceSlice'
import { MoreBatchNumChangeModal } from './moreBatchNumChangeModal'

export const InventoryModal = (props: {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<any>([])

  const [idCount, setIdCount] = useState(-1)

  const [
    moreBatchNumChangeModalVisible,
    setMoreBatchNumChangeModalVisible,
  ] = useState(false)

  const handleChange = (prev: any, next: any) => {
    const newdata = {
      ...prev,
      ...next,
      id: prev.id,
      unitPrice:
        prev.unitPrice || next.unitPrice ? prev.unitPrice || next.unitPrice : 0,
    }
    setData(
      data.map((v: any) =>
        v.id === newdata.id
          ? { ...newdata, id: next.id || newdata.id }
          : { ...v }
      )
    )
  }

  useEffect(() => {
    if (!data.length) {
      setData([...data, newData])
    }
    if (data.every((v: any) => v.retailPrice) || data.every((v: any) => v?.splitPrice)) {
      setData([...data, newData])
    }
  }, [data])

  return (
    <>
      <Modal
        title='新增库存调价商品'
        visible={props.visible}
        okText='确认'
        cancelText='取消'
        className={styles.PriceModal}
        maskClosable={false}
        bodyStyle={{ height: '700px' }}
        onOk={() => {
          props.onOk()
          setData([])
        }}
        onCancel={() => {
          props.onCancel()
          setData([])
        }}
        footer={null}
      >
        <Button
          type='primary'
          onClick={() => {
            if (data.length > 1) {
              if (!data.every((v: any) => v.tenantInventoryLedgerId)) {
                const subData = data.pop()
              }
              dispatch(
                submitInventoryAsync(
                  data.map((v: any) => {
                    if (!v.splitPrice) {
                      return { ...v, splitPrice: v.beforeSplitPrice }
                    } else if (!v.retailPrice) {
                      return { ...v, retailPrice: v.beforeRetailPrice }
                    } else {
                      return v
                    }
                  })
                )
              )
                .then(unwrapResult)
                .then(() => {
                  setData([])
                  props.onOk()
                })
                .catch(() => {
                  notification.error({
                    message: '添加失败,请检查数据是否正确',
                    duration: 3,
                  })
                })
            }
          }}
        >
          保存
        </Button>
        <Button
          style={{ marginLeft: '10px' }}
          type='primary'
          onClick={() => {
            setMoreBatchNumChangeModalVisible(true)
          }}
        >
          多批号调价
        </Button>
        <EditableList<DeliveryItem>
          style={{ height: '600px', overflow: 'auto' }}
          columns={InventoryPriceColumns((text, t) => {
            if (text === 'add') {
              setData([...data, { ...newData, id: idCount.toString() }])
              setIdCount(idCount - 1)
            } else if (text === 'remove') {
              setData(data.filter((item: { id: any }) => item.id !== t.id))
            } else if (text === 'edit') {
              handleChange(t.prev, t.next)
            }
          })}
          dataSource={data}
          rowClassName={() => 'editable-row'}
          rowKey={(t: any) => t.id}
        />
      </Modal>
      <MoreBatchNumChangeModal
        visible={moreBatchNumChangeModalVisible}
        onOk={(v) => {
          setMoreBatchNumChangeModalVisible(false)
          setData(v?.concat(data))
        }}
        onCancel={() => {
          setMoreBatchNumChangeModalVisible(false)
        }}
      ></MoreBatchNumChangeModal>
    </>
  )
}
