import { Form, notification, Table } from 'antd'
import { unwrapResult } from '@reduxjs/toolkit'
import moment from 'moment'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import { treatmentCostParams } from '../../../services/treatmentCost'
import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'
import { TreatCostColumns } from './column'
import { TreatmentCostModel } from './Modal/Modal'
import { getTreatmentCostDetailList } from './Modal/modalSlice'
import { TreatCostQuery } from './Query'
import styles from './treatmentCost.module.css'
import {
  selectCurrent,
  selectTotal,
  selectTreatmentCostData,
  setCurrent,
  selectGetDoctorSaleEntriesParams,
  getTreatmentCostExoprt,
  selectPageLoading,
  setPageLoading,
  selectTreatmentCostAllData,
} from './treatmetCostSlice'
import { getTreatmentCostList } from './treatmetCostSlice'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { TabBar, TabPane } from '../../../compnents/widgets/TabBar'
import { DateRangeType, getDateRange } from '../../../compnents/form/DateSelect'

export const TreatmentCost = (props: {
  tabKey?: any
  oneTabKey?: any
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const Current = useSelector(selectCurrent)

  const params = useSelector(selectGetDoctorSaleEntriesParams)

  const total = useSelector(selectTotal)

  const data = useSelector(selectTreatmentCostData)

  const allData: any = useSelector(selectTreatmentCostAllData)

  const [size, setSize] = useState(10)

  const [name, setName] = useState('')

  const [id, setId] = useState<any>()

  const printRef = useRef<PrintFrameRef>(null)

  const [value, setValue] = useState<any>('0')

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const [queries, setQueries] = useState<treatmentCostParams>({
    current: 1,
    timeHead: timeHead,
    timeTail: timeTail,
  })

  const [isModalVisible, setIsModalVisible] = useState(false)

  const pageLoadingRX = useSelector(selectPageLoading)

  useEffect(() => {
    if (props?.tabKey == 4 && props?.oneTabKey == 0) {
      if (queries?.timeHead) {
        dispatch(setPageLoading(true))
        dispatch(getTreatmentCostList({ ...queries, size }))
        setTimeout(() => {
          dispatch(setPageLoading(false))
        }, 3000)
      }
    } else if (value == '0') {
      if (queries?.timeHead) {
        dispatch(setPageLoading(true))
        dispatch(getTreatmentCostList({ ...queries, size }))
        setTimeout(() => {
          dispatch(setPageLoading(false))
        }, 3000)
      }
    }
  }, [queries, size, value, props?.tabKey, props?.oneTabKey])

  const treatmentCostExport = () => {
    dispatch(getTreatmentCostExoprt({ ...queries, size }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '诊疗费用统计.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  return (
    <>
      {props?.tabKey ? (
        <div className={styles.content}>
          <TreatCostQuery
            onValueChange={(v) => {
              setQueries({
                ...queries,
                ...v,
                timeHead: v?.timeHead ? v?.timeHead : timeHead,
                timeTail: v?.timeTail ? v?.timeTail : timeTail,
              })
            }}
            print={() => printRef.current?.print()}
            outClued={() => {
              treatmentCostExport()
            }}
          />
          <EditableList
            className={styles.treatmentCost}
            loading={pageLoadingRX}
            rowKey={(_, i) =>
              queries
                ? (queries.size || 0) * (queries.current || 0) + (i || 0)
                : 0
            }
            page={{
              items: data,
              current: queries.current || 1,
              size: size,
              total: total,
            }}
            columns={TreatCostColumns(size * (Current - 1))}
            onChangePage={(current, pageSize) => {
              setSize(pageSize as number)
              setQueries({
                ...queries,
                current,
              })
            }}
            onRow={(d: any) => ({
              onDoubleClick: () => {
                setIsModalVisible(true)
                setName(d.name)
              },
            })}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row className={styles.total}>
                  <Table.Summary.Cell index={0} align='center'>
                    合计
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align='center'>
                    {allData?.countSUMFormat}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align='right'>
                    {allData?.averagePriceSUMFormat}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align='right'>
                    {allData?.retailSUMFormat}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      ) : (
        <TabBar
          defaultValue={value}
          style={{ margin: '10px 20px' }}
          onChange={(e) => {
            setValue(e)
          }}
        >
          <TabPane key='0' tab='诊疗费用统计'>
            <div className={styles.contents}>
              <TreatCostQuery
                onValueChange={(v) => {
                  setQueries({
                    ...queries,
                    ...v,
                    timeHead: v?.timeHead ? v?.timeHead : timeHead,
                    timeTail: v?.timeTail ? v?.timeTail : timeTail,
                  })
                }}
                print={() => printRef.current?.print()}
                outClued={() => {
                  treatmentCostExport()
                }}
              />
              <EditableList
                className={styles.treatmentCost}
                loading={pageLoadingRX}
                rowKey={(_, i) =>
                  queries
                    ? (queries.size || 0) * (queries.current || 0) + (i || 0)
                    : 0
                }
                page={{
                  items: data,
                  current: queries.current || 1,
                  size: size,
                  total: total,
                }}
                columns={TreatCostColumns(size * (Current - 1))}
                onChangePage={(current, pageSize) => {
                  setSize(pageSize as number)
                  setQueries({
                    ...queries,
                    current,
                  })
                }}
                onRow={(d: any) => ({
                  onDoubleClick: () => {
                    setIsModalVisible(true)
                    setName(d.name)
                  },
                })}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row className={styles.total}>
                      <Table.Summary.Cell index={0} align='center'>
                        合计
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={3} align='center'>
                        {allData?.countSUMFormat}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}></Table.Summary.Cell>
                      <Table.Summary.Cell index={5} align='right'>
                        {allData?.averagePriceSUMFormat}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} align='right'>
                        {allData?.retailSUMFormat}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </div>
          </TabPane>
        </TabBar>
      )}

      <TreatmentCostModel
        isModalVisible={isModalVisible}
        timeHead={
          queries.timeHead
            ? queries.timeHead
            : moment().startOf('month').format(DateTimeFormat)
        }
        drugName={name}
        id={id}
        timeTail={
          queries.timeTail
            ? queries.timeTail
            : moment().endOf('month').format(DateTimeFormat)
        }
        onOk={() => {
          setIsModalVisible(false)
        }}
        onCancel={() => {
          setIsModalVisible(false)
        }}
      />
      <PrintFrame
        ref={printRef}
        title={
          JSON.parse(localStorage.getItem('credentials') as string)?.tenantName
        }
        subTitle='诊疗费用统计'
      >
        <div style={{ display: 'flex',justifyContent:'space-between', marginBottom:'10px'}}>
          <div>
            统计时段:
            {queries.timeHead
              ? queries.timeHead
              : moment().startOf('month').format(DateTimeFormat)}
            ~
            {queries.timeTail
              ? queries.timeTail
              : moment().endOf('month').format(DateTimeFormat)}
          </div>
          <div>
            打印日期:{moment().format(DateTimeFormatSimple)}
          </div>
        </div>
        <EditableList
          className={styles.treatmentCosts}
          bordered
          pagination={false}
          rowKey={(_: any, i: any) =>
            queries
              ? (queries.size || 0) * (queries.current || 0) + (i || 0)
              : 0
          }
          page={{
            items: data,
            current: queries.current || 1,
            size: size,
            total: total,
          }}
          columns={TreatCostColumns(size * (Current - 1))}
          onChangePage={(current: any, pageSize: any) => {
            setSize(pageSize as number)
            setQueries({
              ...queries,
              current,
            })
          }}
          onRow={(d: any) => ({
            onDoubleClick: () => {
              setIsModalVisible(true)
              setName(d.name)
            },
          })}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0} align='center'>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3} align='center'>
                  {allData?.countSUMFormat}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5} align='right'>
                  {allData?.averagePriceSUMFormat}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align='right'>
                  {allData?.retailSUMFormat}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </PrintFrame>
    </>
  )
}
