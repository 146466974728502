import {AlipayCircleFilled, ExclamationCircleFilled, PlusOutlined, QuestionCircleTwoTone} from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import {Button, Col, Image, Form, Input, notification, Radio, Row, Space, Upload, Modal, Popover} from "antd";
import cnchar from "cnchar";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch } from "../../../app/store";
import { Label } from "../../../compnents/widgets/Label";
import { AccountType } from "../../../models/accounttype";
import {
  GenderOptions,
  getGenderName,
  StationCategory,
  User,
} from "../../../models/user";
import { ThemeContext } from "../../../theme/ThemeContext";
import { getThirdPartyLoginUrl, unbindAccount } from "../../login/loglinSlice";
import {
  createOrUpdateUser,
  deleteSignatureAsync,
  getSignatureAsync,
  getSignatureAsyncNum,
  getUserProfile,
  saveCwcaOptId,
  uploadSignatureAsync
} from "./personalDetailSlice";
// import { getThirdPartyLoginUrl, unbindAccount } from "../login/loglinSlice";
import styles from "./personalDetail.module.css";
import { ChangePassWordModal } from "./changePassWordModal";
import { useQuery } from "../../../utils/HttpUtils";
import FileUpload from "../../../compnents/file/FileUpload";
import { getBase64, imagesType } from "../../../utils/StringUtils";
import {selectUserId, selectUserName} from "../../../app/applicationSlice";
import { selectSettings, selectTenant } from "../../user/setting/settingSlice";
import {getUrl} from "../../treatment/oralCases/oralCasesSlice";
import {traceRoute} from "../../../layouts/layoutSlice";
import { UploadModal } from "../../putstorelist/Query/uploadModal";
import { deleteUploadFileList } from "../../treatment/diagnosis/diagnosisSlice";
import { useLocation } from "react-router-dom";
import {pinyin} from "pinyin-pro";
import {InvokeCWCAService,InvokeCWCAService2} from "../../../utils/MedicalUtils";
import {getInsuranceToSelfpay} from "../../treatment/editor/recipeEditorSlice";
import {setPageLoading} from "../../putstorage/putstorageSlice";
// import { createOrUpdateUser, getUserProfile } from "./userSlice";

export const PersonalDetail = (): ReactElement => {

  const location: any = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const userId = useSelector(selectUserId)

  const [form] = Form.useForm()

  const [profile, setProfile] = useState<User | undefined>()

  const [popup, setPopup] = useState<Window | null>(null)

  const [isDoctor, setIsDoctor] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [SignatureSrc, setSignatureSrc] = useState('')

  const userName = useSelector(selectUserName)
  //电子签章
  const [CASignatureKey, setCASignatureKey] = useState('')

  const [CASignatureKeyLoading, setCASignatureKeyLoading] = useState(false)

  const [numInfo, setNumInfo] = useState<any>()

  const query = useQuery()

  const state = query.get('state') as string

  const [isUploadVisible, setIsUploadVisible] = useState(false)   //上传附件弹窗

  // const isShow: any = useSelector(selectSettings)[30]   //是否显示点在签名

  useEffect(() => {

    if (!profile) return
    if (location?.state?.payStatus == '1') {  //购买医生签名过来
      setIsUploadVisible(true)
    }
    if(profile?.cwcaOptId){
      setCASignatureKey(profile?.cwcaOptId?.split("||")[0]);
    }
  }, [profile])

  const getProfile =()=>{
    dispatch(getUserProfile())
      .then(unwrapResult)
      .then((profile: any) => {
        form.setFieldsValue(profile)
        setIsDoctor(
          profile.stationCategory === StationCategory.DoctorOrPharmacist
        )
        setProfile(profile)
      })
  }

  useEffect(() => {
    getProfile();
    getNum()
  }, [])

  const getSignature = () => {
    dispatch(getSignatureAsync(userId)).then(unwrapResult).then((res: any) => {
      setSignatureSrc(res)
    })
  }

  useEffect(() => {
    getSignature()
  }, [userId])

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 19,
    },
  }

  const bindAccount = (accountType: AccountType) => {
    const location = window.location
    if (profile?.alipayUserId) {
      dispatch(unbindAccount(AccountType.Alipay))
        .then(() => dispatch(getUserProfile()))
        .then(unwrapResult)
        .then(setProfile)
        .catch(() => {
          // do nothing.
        })
    } else {
      dispatch(
        getThirdPartyLoginUrl({
          accountType,
          isLogin: false,
          redirectUrl: `${location.protocol}//${location.host}/userCenter/callback`,
        })
      )
        .then(unwrapResult)
        .then((url: any) => {
          setPopup(window.open(url))
        })
    }
  }

  const getNum = () => {   //电子签名上传数量
    dispatch(getSignatureAsyncNum()).then(unwrapResult).then((res: any) => {
      setNumInfo(res)

    })
  }

  const getCertOptID = () =>{
    try{
      //1.获取版本号 （确保通信正常）
      let result = InvokeCWCAService({
        "function":"SOF_GetVersion"
      });
      if(!result || result?.Success == 0){
        return;
      }
      //2.加载动态库
      result = InvokeCWCAService({"function":"SOF_LoadLibrary","Path":"mtoken_gm3000.dll", "Vendor":""});
      if(!result || result?.Success == 0){
        return;
      }
      //3.获取设备数量
      result = InvokeCWCAService({"function":"SOF_GetKeyCount"});
      if(!result || result?.Success == 0){
        return;
      }
      if(result?.RETURN != 1){
        notification.info({
          message: '未检测到密钥，请您插入USB密钥后再试。',
        })
        return;
      }
      //4.获取用户唯一ID
      result = InvokeCWCAService({"function":"SOF_GetUserList"});
      if(!result || result?.Success == 0){
        return;
      }
      const keyName = result?.RETURN?.split('||')[0];
      Modal.confirm({
        title: '',
        content: (
          <div>
            <p>当前登录用户：{userName}</p>
            <p>绑定的密钥信息是：{keyName}</p>
            <p>{result?.RETURN?.split('||')[1]}</p>
            <p style={{color:"orangered"}}>{keyName!=userName ? '密钥用户不匹配，插入本人密钥' : '每个密钥仅能绑定一次，点击确认完成绑定。'}</p>
          </div>
        ),
        okText: '确认',
        cancelText: '取消',
        icon: null,
        onOk: () => {
          if(keyName!=userName){
            notification.error({
              message: '密钥用户不匹配，插入本人密钥。',
            })
            return;
          }
          dispatch(saveCwcaOptId({"optMessage":result?.RETURN})).then(unwrapResult).then((res: any) => {
            notification.success({
              message: '绑定成功，重新登录后生效',
            })
            getProfile();
          })
        },
      })
    }catch(e){
      console.log(e);
    }finally {
      setCASignatureKeyLoading(false);
      InvokeCWCAService({"function":"SOF_FreeLibrary"});
    }
  }
  useEffect(() => {
    const timer = popup
      ? setInterval(() => {
          if (popup.closed) {
            setPopup(null)
            dispatch(getUserProfile())
              .then(unwrapResult)
              .then((profile: any) => {
                form.setFieldsValue(profile)
                setIsDoctor(
                  profile.stationCategory === StationCategory.DoctorOrPharmacist
                )
                setProfile(profile)
              })
          }
        }, 1000)
      : undefined
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [popup])




  return (
    <div className={state ? styles.mains : styles.main}>
      <Label label='基本信息' style={{ marginBottom: 10 }} />
      <Form
        form={form}
        onFinish={(values) => {
          dispatch(
            createOrUpdateUser({
              user: {
                ...profile,
                ...values,
              },
            })
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: '修改成功',
                duration: 3,
              })
            })
            .then(() => {
              dispatch(getUserProfile())
            })
        }}
      >
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              {...layout}
              name='name'
              label='姓名'
              rules={[
                { required: true, message: '姓名不能为空,请输入正确的值。' },
              ]}
            >
              <Input
                disabled={!!profile?.alipayUserId}
                autoComplete='off'
                onChange={(e) => {
                  form.setFieldsValue({
                    mnemonicCode: pinyin(e.target.value, { pattern: 'first', toneType: 'none' ,separator:''}).toUpperCase(),
                  })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              {...layout}
              name='mnemonicCode'
              label='助记码'
              required
              rules={[
                { required: true, message: '助记码不能为空,请输入正确的值。' },
              ]}
            >
              <Input autoComplete='off' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              {...layout}
              name='gender'
              label='性别'
              required
              rules={[
                { required: true, message: '性别不能为空,请输入正确的值。' },
              ]}
            >
              <Radio.Group>
                {GenderOptions.map((g) => (
                  <Radio key={g} value={g}>
                    {getGenderName(g)}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              {...layout}
              name='phone'
              label='手机号'
              required
              rules={[
                { required: true, message: '手机号不能为空,请输入正确的值。' },
                {
                  pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
                  message: '请输入正确的手机号',
                },

                {
                  max: 11,
                  message: '最长11位!',
                },
              ]}
            >
              <Input autoComplete='off' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              {...layout}
              name='identityCard'
              label='身份证号'
              rules={[
                {
                  pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                  message: '您的身份证格式不正确',
                },
                {
                  max: 18,
                  message: '最长18位!',
                },
              ]}
            >
              <Input disabled={!!profile?.alipayUserId} autoComplete='off' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item {...layout} name='email' label='电子邮件'>
              <Input autoComplete='off' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          {isDoctor ? (
            <>
              <Col span={8}>
                <Form.Item
                  {...layout}
                  name='doctorCode'
                  label='医师编号'
                  required
                  rules={[
                    {
                      required: true,
                      message: '医师编号不能为空,请输入正确的值。',
                    },
                  ]}
                >
                  <Input autoComplete='off' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  {...layout}
                  name='practiceCertificate'
                  label='执业证书'
                >
                  <Input autoComplete='off' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  {...layout}
                  name='qualificationCertificate'
                  label='资格证书'
                >
                  <Input autoComplete='off' />
                </Form.Item>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        <Row gutter={20}>
          <Col
            offset={10}
            span={10}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Button
              className={styles.buttom_submit}
              type='primary'
              htmlType='submit'
              size='middle'
            >
              保存
            </Button>

          </Col>
        </Row>
      </Form>
      <Label label='更换密码' style={{ marginTop: 20, marginBottom: 10 }} />
      <Row>
        <Col span={10}>
          <Button
            style={{ marginLeft: '20px' }}
            type='primary'
            size='small'
            htmlType='submit'
            onClick={() => {
              setIsModalVisible(true)
            }}
          >
            更换密码
          </Button>
        </Col>
      </Row>

      {/*<Label label='账号绑定' style={{ marginTop: 20, marginBottom: 10 }} />*/}
      {/*<Row gutter={20} align='middle'>*/}
      {/*  <Col span={10} style={{ fontSize: '0.875rem' }}>*/}
      {/*    <AlipayCircleFilled style={{ marginRight: 8 }} /> 支付宝{' '}*/}
      {/*    <span*/}
      {/*      style={{*/}
      {/*        fontSize: '0.75rem',*/}
      {/*        color: theme.tc3,*/}
      {/*        fontStyle: 'italic',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {profile?.alipayUserId ? '已绑定' : '未绑定'}*/}
      {/*    </span>*/}
      {/*  </Col>*/}
      {/*  <Col*/}
      {/*    span={10}*/}
      {/*    style={{*/}
      {/*      display: 'flex',*/}
      {/*      flexDirection: 'column',*/}
      {/*      alignItems: 'flex-end',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Button*/}
      {/*      type='primary'*/}
      {/*      size='middle'*/}
      {/*      onClick={() => {*/}
      {/*        bindAccount(AccountType.Alipay)*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {profile?.alipayUserId ? '解绑' : '绑定'}*/}
      {/*    </Button>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
            <Label label='电子签名' style={{ marginTop: 20, marginBottom: 10 }} />
      <Row gutter={20} align='middle'>
        <Col span={10} style={{ fontSize: '0.875rem' }}>
          {
            SignatureSrc ? (
              <div >
                        <Image
                style={{
                  width: 100,
                  height: 100
                }}
                src={SignatureSrc}
                onError={(e) => {
                  return
                }}
                />
                <div className={styles.textWrap}>
                  <span>服务包内总签名数</span>
                  <span className={styles.textNum}>{numInfo?.totalNum} </span>
                  <span>已分配</span>
                  <span className={styles.textNum}>{numInfo?.usedNum} </span>
                  <span>剩余可分配</span>
                  <span className={styles.textNum}>{numInfo?.useNum} </span>
                </div>
              </div>
            ) : <div className={styles.textWrap}>
                {numInfo?.useNum != 0 && <>
                <span>服务包内总签名数</span>
                <span className={styles.textNum}>{numInfo?.totalNum}</span>
                <span>已分配</span>
                <span className={styles.textNum}>{numInfo?.usedNum}</span>
                <span>剩余可分配</span>
                <span className={styles.textNum}>{numInfo?.useNum}</span>
              </>}

                {numInfo?.useNum == 0 &&
                  <div><span className={styles.textPrompt}>您的电子签名可分配数量为0，请先购买服务包 </span>
                    <Button style={{marginLeft:"10px"}}  type="primary" onClick={() => {
                            dispatch(
                                traceRoute({
                                    name: "产品中心",
                                    path: "/productCenter",
                                    state: {
                                        autoClick: 'ysqm'
                                    }
                                })
                            )
                    }}>点击购买</Button></div>
                }

            </div>
          }

        </Col>
        <Col
          span={10}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Space>
            {/* <Upload
              customRequest={async ({ file }) => {
                dispatch(uploadSignatureAsync({
                  file,
                  userId
                })).then(unwrapResult).then(() => {
                  getSignature()
                  notification.success({
                    message: '上传成功'
                  })
                  getNum()
                })
              }}
              maxCount={1}
              showUploadList={false}
              accept={'.png,.tiff,.jpg,.jpeg,bmp,.heic,.webp'}
              beforeUpload={(file) => {
                if (SignatureSrc) {
                  notification.info({ message: '请先删除签名再上传！' })
                  return false
                }
                if (imagesType.some((v) => file.type.includes(v))) {
                  if ((file.size / 1024 / 1024) < 1) {
                    return true
                  } else {
                    notification.info({ message: '图片大于1M,请重新选择!' })
                    return false
                  }
                }
                notification.info({ message: '请上传图片' })
                return false
              }}
            >
                    <Button icon={<PlusOutlined />} type='primary' disabled={numInfo?.useNum == 0}
                size='middle'>上传签名</Button>
            </Upload> */}
            <Button
              icon={<PlusOutlined />}
              disabled={(numInfo?.useNum == 0) || (SignatureSrc!=null && SignatureSrc.length>0)}
              onClick={() => {
                setIsUploadVisible(true)
              }}
            >上传附件</Button>
            {
              SignatureSrc && <Button type="primary" onClick={() => {
                dispatch(deleteSignatureAsync(userId)).then(unwrapResult).then(() => {
                  getSignature()
                  notification.success({
                    message: "删除成功"
                  })
                  getNum()
                })
              }} >删除</Button>
            }
          </Space>
        </Col>
      </Row>

      <Row gutter={20} align='middle'>
        <Col span={10} style={{ fontSize: '0.875rem' ,display:"ruby"}}>
        <Label label='CA电子签章' style={{ marginTop: 20, marginBottom: 10,paddingRight:10 }} />
        <Popover
          content={
            <div>
              <p>
                开通后，可为您的病历提供专业的第三方CA签名服务。
              </p>
              <p>
                不同于普通电子签名，CA电子签章功能具有更加安全的机制防止患者病历被篡改，同时更加符合相关监管规定。
              </p>
              <p>
                您可以联系我们的服务人员为您开通此功能。
              </p>

            </div>
          }
          placement='topRight'
          title='CA电子签章：'
        >
          <QuestionCircleTwoTone />
        </Popover>
        </Col>
      </Row>
      <Row gutter={20} align='middle'>
        <Col span={10}>
          {
            !CASignatureKey &&
              <Button
                style={{ marginLeft: '20px' }}
                type='primary'
                size='small'
                htmlType='submit'
                loading={CASignatureKeyLoading}
                onClick={() => {
                  setCASignatureKeyLoading(true);
                  setTimeout(() => {
                    getCertOptID()
                  }, 1000)
                }}
            >
            绑定密钥
            </Button>
          }
          {
            CASignatureKey &&
              <Button
                  style={{ marginLeft: '20px' }}
                  type='primary'
                  size='small'
                  htmlType='submit'
                  disabled={true}
              >
                  密钥：{CASignatureKey} 已绑定
              </Button>
          }

        </Col>
      </Row>

      <ChangePassWordModal
        profile={profile}
        isModalVisible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false)
        }}
        onCancel={() => {
          setIsModalVisible(false) 
        }}
      />

      <UploadModal
        uploadModalVisible={isUploadVisible}
        accept={'.png,.tiff,.jpg,.jpeg,bmp,.heic,.webp'}
        id={profile?.id || ''}
        uploadType={3}
        onCancel={() => {
          setIsUploadVisible(false)
        }}
        maxCount={1}
        onSubmit={(v: any, i: any, id: any) => {
          dispatch(deleteUploadFileList({ ids: id }))
            .then(unwrapResult)
          dispatch(uploadSignatureAsync({
            file: v[0],
            userId
          })).then(unwrapResult).then(() => {
            getSignature()
            notification.success({
              message: '上传成功'
            })
            getNum()
            setIsUploadVisible(false)
          })

        }}
      />

    </div>
  )
}
