/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
import { unwrapResult } from '@reduxjs/toolkit'
import { queries } from '@testing-library/react'
import { message, Modal, ModalProps, notification } from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import { resolve } from 'dns'
import { reject } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectTenantId,
  selectUserName,
  selectUserId,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectTenantCategory,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { data } from '../../features/composing/modal'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { AccountCheckListParams } from '../../services/config'
import { strParse, medicalSendHttp } from '../../utils/MedicalUtils'
import {
  checkLocalDataAsync,
  elseWhereDownloadCheckAsync,
  getAllopatryListAsync,
  localMedicalTradeAsync,
  medicalTradeAsync,
} from '../layoutSlice'
import { RegisterColumns, RegisterDetailColumns } from './Columns'
import { RegisterDetailQuery } from './RegisterQuery'

export interface RegisterDetailProps {
  viewList: any[]
  isLocal: string
  onRegister: () => void
}

export const RegisterDetailModal: React.FC<
  ModalProps & RegisterDetailProps
> = ({ visible, onCancel, onOk, viewList, isLocal }) => {
  const [queries, setQueries] = useState<any>({})
  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<any>([])

  const [isNew, setIsNew] = useState(false)

  const [loading, setLoading] = useState(false)

  const [searchOver, setSearchOver] = useState(false)

  const insuranceArray = useSelector(selectInsuranceArray)

  const tenantId = useSelector(selectTenantId)

  const [elseViewList, setElseViewList] = useState<any>([])

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const loadState = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 5000)
  }

  // 医保冲正
  const medicalReverseTarge = (
    vs: any,
    serverType: any,
    responseString?: string
  ) => {
    const insuranceItem = insuranceArray.find(
      (v) =>
        v.insuranceCode ===
        (queries.insuranceCode || insuranceArray[0].insuranceCode)
    )

    dispatch(
      medicalTradeAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString,
          },
          insuplcAdmdvs: vs.insuplcAdmdvs,
          oinfno: vs.refdSetlFlag,
          omsgid: vs.msgid,
          psnNo: vs.psnNo,
        },
      }) as any
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem?.countryHospitalNb,
                insuranceItem?.secretToken,
              ],
              message: '医保冲正',
            },
            (call, err) => {
              if (!err) {
                const requestString = call.netmessage
                medicalReverseTarge(
                  vs,
                  'response',
                  JSON.stringify(requestString)
                )
              }
            }
          )
          // })
        } else {
          notification.success({
            message: '医保冲正成功',
            duration: 2.5,
          })
          onOk && onOk({} as any)
        }
      })
  }

  // 本地冲正
  const localMedicalReverseTarge = (vs: any) => {
    dispatch(
      localMedicalTradeAsync({
        localOinfNo: vs.refdSetlFlag,
        localOmsgId: vs.setlId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        notification.success({
          message: '本地冲正成功',
          duration: 2.5,
        })
        onOk && onOk({} as any)
      })
  }

  // 本地更正
  const localCheckData = (vs: any) => {
    dispatch(
      checkLocalDataAsync({
        acctPay: vs?.acctPay,
        fundPaySumamt: vs?.fundPaySumamt,
        medfeeSumamt: vs?.medfeeSumamt,
        psnCashpay: vs?.psnCashpay,
        setlId: vs?.setlId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        notification.success({
          message: '本地更正成功',
          duration: 2.5,
        })
        onOk && onOk({} as any)
      })
  }

  const getElsePageParse = (
    accountDate: string,
    list?: any,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      getAllopatryListAsync({
        accountDate: moment(accountDate).format(DateTimeFormatSimple),
        list: list,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (res && JSON.stringify(res) !== '{}' && res.length) {
          setData(data.concat(res))
        }
        resolve && resolve()
      })
  }

  // 1812 异地对账
  const downLoadCheckItem = (
    serverType: any,
    accountData: string,
    loclDataVer?: number,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    const insuranceItem =
      insuranceArray.find(
        (v) =>
          v.insuranceCode ===
          (queries.insuranceCode || insuranceArray[0].insuranceCode)
      ) || []
    dispatch(
      elseWhereDownloadCheckAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem?.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString,
          },
          begndate: moment(accountData).startOf('day').format('YYYYMMDD'),
          enddate: moment(accountData).endOf('day').format('YYYYMMDD'),
          loclDataVer: loclDataVer || 0,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem?.countryHospitalNb,
                insuranceItem?.secretToken,
              ],
            },
            async (call, err) => {
              if (!err) {
                const requestString = call.netmessage
                if (
                  requestString.output?.data &&
                  requestString.output?.data.length > 0
                ) {
                  setElseViewList((i: any) => {
                    return [
                      ...i.map((v: any) => {
                        if (v.accountData === accountData) {
                          return {
                            ...v,
                            list: v.list.concat(requestString.output.data),
                          }
                        } else {
                          return v
                        }
                      }),
                    ]
                  })
                }
                await downLoadCheckItem(
                  'response',
                  accountData,
                  loclDataVer || 0,
                  JSON.stringify(requestString),
                  resolve,
                  reject
                )
              }
            },
            undefined,
            reject
          )
          // })
        } else {
          if (res?.continue) {
            downLoadCheckItem(
              'requestString',
              accountData,
              res?.loclDataVer || 0,
              undefined,
              resolve,
              reject
            )
          } else {
            resolve && resolve()
          }
        }
      })
  }

  const downLoadCheck = (vs: any) => {
    let promiseList = []
    const count = moment(vs.endDate).diff(moment(vs.startDate), 'days')
    message.loading('正在进行异地对账', 0)
    for (let index = 0; index <= count; index++) {
      const accountData = moment(vs.startDate)
        .add(index, 'days')
        .format(DateTimeFormatSimple)
      setElseViewList((i: any) => {
        return [
          ...i,
          {
            accountData,
            list: [],
          },
        ]
      })
      promiseList.push(
        new Promise((resolve, reject) => {
          downLoadCheckItem(
            'requestString',
            accountData,
            0,
            undefined,
            resolve,
            reject
          )
        })
      )
    }
    Promise.allSettled(promiseList)
      .then(() => {
        // setSearchOver(true)
      })
      .catch((err) => {
        return
      })
      .finally(() => {
        setSearchOver(true)
        setTimeout(() => {
          message.destroy()
        }, 1000)
      })
  }

  useEffect(() => {
    if (searchOver) {
      for (const key in Array.from(elseViewList)) {
        const v = elseViewList[key]
        getElsePageParse(v.accountData, v.list || [])
      }
    }
  }, [elseViewList, searchOver])

  useEffect(() => {
    if (visible) {
      setIsNew(true)
      setData(viewList)
    }
    return () => {
      if (visible) {
        setIsNew(false)
        setData([])
        setElseViewList([])
        setQueries({})
      }
    }
  }, [viewList, visible, isLocal])

  useEffect(() => {
    if (visible && isLocal === '1') {
      downLoadCheck(queries)
    }
  }, [queries, visible, isLocal])

  return (
    <Modal
      title='日对账交易明细'
      visible={visible}
      confirmLoading={loading}
      onCancel={onCancel}
      width={1200}
      footer={null}
    >
      <div>
        {isLocal === '1' && (
          <RegisterDetailQuery
            visible={isNew}
            onValueChange={(vs) => {
              loadState()
              setData([])
              setSearchOver(false)
              setElseViewList([])
              setQueries({ ...queries, ...vs })
            }}
          />
        )}
        <EditableList
          dataSource={data}
          columns={RegisterDetailColumns((text, id, t) => {
            if (text === 'medicalTarge') {
              Modal.confirm({
                title: '提示',
                content: `医保冲正后，会将该医保收费记录在医保进行退费操作以使得对账一致，请确认是否继续冲正？`,
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  medicalReverseTarge(t, 'requestString')
                },
              })
            } else if (text === 'localTarge') {
              Modal.confirm({
                title: '提示',
                content: `本地冲正后，会删除该笔交易记录，请确认是否继续冲正？`,
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  localMedicalReverseTarge(t)
                },
              })
            } else if (text === 'localCheck') {
              Modal.confirm({
                title: '提示',
                content: `本地更正后，会将医保的收费金额修正到本地以使得对账一致，请与管理员共同确认金额正确后继续操作？`,
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  localCheckData(t)
                },
              })
            }
          }, isLocal)}
          onChangePage={(current, size) => {
            setQueries({
              ...queries,
              current,
              size: size as number,
            })
          }}
          pagination={undefined}
          onRow={(d: any) => ({
            onDoubleClick: () => {
              // onRecord(d)
            },
          })}
        />
      </div>
    </Modal>
  )
}
