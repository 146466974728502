import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, notification, Pagination, Row, Spin, Table } from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  DelectOutlined,
  EditsOutlined,
  SettingOutlined,
} from '../../compnents/icons/Icons'
import { EditableList } from '../../compnents/list/EditableList'
import { DateTimeFormatSimple } from '../../models/datetime'
import { SetmealCategoryName } from '../../models/setmeal'
import { SetMealPageParams } from '../../services/setmeal'
import { ThemeContext } from '../../theme/ThemeContext'
import { mergeGroup, splitGroup } from '../../utils/MaterialUtils'
import { PurchaseModal } from '../putstorelist/purchaseModal'
import { Columns } from './columns'
import { SetmealQuery } from './query'
import styles from './Setmeal.module.css'
import { SetmealDurgModal } from './setmealListModal'
import { SetmealModal } from './setmealmodal'
import {
  getSetmeal,
  getSetmealDetail,
  getSetmealList,
  removeSetmealDurg,
  selectCategory,
  selectDurgFlag,
  selectSetmealCurrent,
  selectSetmealData,
  selectSetmealList,
  selectSetmealSelectId,
  selectSetmealTotal,
  setDurgFlag,
  setmealCtrlAsync,
  setSetmealCurrent,
  setSetmealDetail,
  setSetmealDurgId,
  setSetmealSelectId,
  setsource,
  setFlagText,
  updateSortAndGroupNumber,
  selectPageLoading,
  selectPageSpinLoading,
  setPageLoading,
  setPageSpinLoading,
  getUpdateSortNumber,
} from './setmealSlice'

export interface SetMealRef {
  setIsModalVisible: (visible: boolean) => void
}

function Setmeal(
  props: { style?: React.CSSProperties; flag?: number },
  ref: React.Ref<SetMealRef>
): ReactElement {
  const [queries, setQueries] = useState<SetMealPageParams>({
    state: 1,
  })

  const theme = useContext(ThemeContext)

  const [size, setSize] = useState(10)

  const current = useSelector(selectSetmealCurrent)

  const total = useSelector(selectSetmealTotal)

  const category = useSelector(selectCategory)

  const flag = useSelector(selectDurgFlag)

  const dispatch = useDispatch<RootDispatch>()

  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const setmealId = useSelector(selectSetmealSelectId)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isPurchaseModalVisible, setIsPurchaseModalVisible] = useState(false)

  const [isModalDurgVisible, setIsModalDurgVisible] = useState(false)

  const [isShowAgain, setIsShowAgain] = useState(false)

  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>(
    'checkbox'
  )

  const pageLoadingRX = useSelector(selectPageLoading)

  const pageSpinLoadingRX = useSelector(selectPageSpinLoading)

  const rowSelection = {
    onChange: (keys: string[]) => {
      setSelectedIds(keys)
    },
  }
  useEffect(() => {
    if (setmealId) {
      dispatch(setPageLoading(true)) //表格loading
      setSelectedIds([])
      dispatch(getSetmealList(setmealId))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }
  }, [setmealId])

  useEffect(() => {
    dispatch(setPageSpinLoading(true)) //列表 loading
    dispatch(getSetmeal({ ...queries, current, size, category }))
    setTimeout(() => {
      dispatch(setPageSpinLoading(false))
    }, 3000)
  }, [category, current, size, queries])

  const setmealData = useSelector(selectSetmealData)
  const setmealList = useSelector(selectSetmealList)
  useImperativeHandle(ref, () => ({
    setIsModalVisible,
  }))

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <Row style={{ marginTop: '10px' }}>
        <Button
          type='primary'
          onClick={() => {
            dispatch(setSetmealSelectId(0))
            setIsModalVisible(true)
          }}
        >
          添加套餐
        </Button>
        <Button
          type='primary'
          style={{ marginLeft: '10px' }}
          onClick={() => {
            setIsPurchaseModalVisible(true)
          }}
        >
          导入套餐
        </Button>
      </Row>
      <div className={styles.setmeal} style={{ height: 'calc(100% - 50px)' }}>
        <SetmealQuery
          onValueChange={(v) => {
            setQueries(v)
          }}
        />
        <div style={{ flex: 1, overflowY: 'scroll', overflowX: 'hidden' }}>
          {setmealData.map((v: any, i: number) => {
            return (
              <>
                <Spin spinning={pageSpinLoadingRX}>
                  <Row
                    key={i}
                    wrap={false}
                    className={styles.point}
                    style={{
                      marginTop: 10,
                      padding: '0 10px',
                      height: '2.5rem',
                      border: `solid 1px ${theme.wbd}`,
                      fontSize: '16px',
                    }}
                    align='middle'
                    onClick={() => {
                      dispatch(setDurgFlag(v.category))
                      if (setmealId === v.id) {
                        dispatch(setSetmealSelectId(0))
                      } else {
                        dispatch(setSetmealSelectId(v.id))
                      }
                    }}
                  >
                    <Col span={16}>
                      <Row>
                        <Col
                          style={{
                            textAlign: 'center',
                            marginRight: '20px',
                            width: '17px',
                          }}
                        >
                          {size * (current - 1) + i + 1}
                        </Col>
                        <Col
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginRight: '16px',
                          }}
                        >
                          {moment(v.createTime).format(DateTimeFormatSimple)}
                        </Col>
                        <Col
                          className={styles.title}
                          span={7}
                          style={{
                            display: 'inline-block',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginRight: '16px',
                          }}
                        >
                          <i
                            style={{
                              width: 'auto',
                              height: '100%',
                              fontStyle: 'normal',
                              marginRight: '16px',
                            }}
                          >
                            {v.name}
                          </i>
                        </Col>
                        <Col
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginRight: '16px',
                            width: '84px',
                          }}
                        >
                          {SetmealCategoryName(v.category)}
                        </Col>
                        <Col
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginRight: '16px',
                            minWidth: '200px',
                          }}
                        >
                          共{v.count}
                          项明细&emsp;总计:{v.total}元
                        </Col>
                        <Col
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            marginRight: '16px',
                          }}
                        >
                          排序：{v?.sort > 0 ? v?.sort : '-'}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <span
                        className={styles.point}
                        style={{ marginRight: '20px' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          dispatch(getSetmealDetail(v.id))
                          dispatch(setSetmealSelectId(v.id))
                          setIsModalVisible(true)
                        }}
                      >
                        <EditsOutlined
                          style={{
                            fill: '#027AFF',
                            width: '16px',
                            height: '16px',
                            marginRight: '9px',
                          }}
                        />
                        修改
                      </span>
                      <span
                        className={styles.point}
                        style={{ marginRight: '20px' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          dispatch(setmealCtrlAsync(v.id)).then(() => {
                            dispatch(
                              getSetmeal({
                                ...queries,
                                current,
                                size,
                                category,
                              })
                            )
                          })
                        }}
                      >
                        {v.state ? (
                          <DelectOutlined
                            style={{
                              fill: '#FF5D5D',
                              width: '16px',
                              height: '16px',
                              marginRight: '9px',
                            }}
                          />
                        ) : (
                          <SettingOutlined
                            style={{
                              fill: '#999999',
                              width: '16px',
                              height: '16px',
                              marginRight: '9px',
                            }}
                          />
                        )}

                        {v.state ? '停用套餐' : '启用套餐'}
                      </span>
                      <span
                        className={styles.point}
                        style={{ fontSize: '1rem', marginRight: 10 }}
                        onClick={() => {
                          dispatch(setDurgFlag(v.category))
                          if (setmealId === v.id) {
                            dispatch(setSetmealSelectId(0))
                          } else {
                            dispatch(setSetmealSelectId(v.id))
                          }
                        }}
                      >
                        <span style={{ color: '#027aff', marginRight: '10px' }}>
                          {setmealId === v.id
                            ? '单击收起新增药品'
                            : '单击展开新增药品'}
                        </span>
                        {setmealId === v.id ? (
                          <CaretUpFilled style={{ color: '#999999' }} />
                        ) : (
                          <CaretDownFilled style={{ color: '#999999' }} />
                        )}
                      </span>
                    </Col>
                  </Row>
                </Spin>
                {setmealId === v.id ? (
                  <>
                    <Row
                      key={i}
                      style={{
                        padding: '0 10px',
                        height: '2.5rem',
                        border: `solid 1px ${theme.wbd}`,
                        background: `${theme.wbd}`,
                      }}
                      align='middle'
                      gutter={10}
                    >
                      <Button
                        type='primary'
                        style={{ margin: '0 5px' }}
                        danger
                        onClick={() => {
                          dispatch(removeSetmealDurg(selectedIds.join(',')))
                            .then(unwrapResult)
                            .then(() => {
                              dispatch(
                                getSetmeal({
                                  ...queries,
                                  current,
                                  size,
                                  category,
                                })
                              )
                              dispatch(getSetmealList(setmealId))
                            })
                        }}
                      >
                        删除选中行
                      </Button>
                      <Button
                        type='primary'
                        style={{ margin: '0 5px' }}
                        onClick={(e: any) => {
                          setIsModalDurgVisible(true)
                          if (v.category === 4) {
                            dispatch(setsource(0))
                            dispatch(setFlagText('添加材料'))
                          } else {
                            dispatch(setsource(0))
                            dispatch(setFlagText('添加药品'))
                          }
                          setIsShowAgain(true)
                        }}
                      >
                        {v.category === 4 ? '添加材料' : '添加药品'}
                      </Button>
                      {v.category === 3 || v.category === 1 ? (
                        <></>
                      ) : (
                        <Button
                          type='primary'
                          onClick={() => {
                            setIsModalDurgVisible(true)
                            dispatch(setsource(1))
                          }}
                        >
                          添加诊疗项目
                        </Button>
                      )}

                      {v.category === 4 ? (
                        <></>
                      ) : (
                        <>
                          <Button
                            type='primary'
                            style={{ margin: '0 5px' }}
                            disabled={!mergeGroup(setmealList, selectedIds)}
                            onClick={() => {
                              mergeGroup(
                                setmealList,
                                selectedIds,
                                async (ms) => {
                                  await dispatch(updateSortAndGroupNumber(ms))
                                  dispatch(getSetmealList(setmealId))
                                }
                              )
                            }}
                          >
                            合并分组
                          </Button>
                          <Button
                            type='primary'
                            style={{ margin: '0 5px' }}
                            disabled={!splitGroup(setmealList, selectedIds)}
                            onClick={() => {
                              splitGroup(
                                setmealList,
                                selectedIds,
                                async (ms) => {
                                  await dispatch(updateSortAndGroupNumber(ms))
                                  dispatch(getSetmealList(setmealId))
                                }
                              )
                            }}
                          >
                            拆开分组
                          </Button>
                        </>
                      )}
                    </Row>
                    <EditableList
                      className={styles.detailtable}
                      loading={pageLoadingRX}
                      rowKey={(record: any) => record.id}
                      columns={Columns(
                        setmealList,
                        (id, source) => {
                          dispatch(setsource(source))
                          dispatch(setSetmealDurgId(id))
                          setIsModalDurgVisible(true)
                        },
                        (text: any, v) => {
                          if (text == 'edit') {
                            const sort = v?.next?.sort
                            const id = v?.prev?.id
                            dispatch(
                              getUpdateSortNumber({ sort: sort, id: id })
                            )
                              .then(unwrapResult)
                              .then((v) => {
                                dispatch(getSetmealList(setmealId))
                              })
                          }
                        }
                      )}
                      dataSource={setmealList?.map((v) => {
                        return { ...v, sort: v?.sort > 0 ? v?.sort : null }
                      })}
                      pagination={false}
                      bordered
                      rowSelection={
                        {
                          type: selectionType,
                          ...rowSelection,
                        } as TableRowSelection<any>
                      }
                    />
                    {/* <Table
                      className={styles.detailtable}
                      loading={pageLoadingRX}
                      rowKey={(record: any) => record.id}
                      columns={Columns(setmealList, (id, source) => {
                        dispatch(setsource(source))
                        dispatch(setSetmealDurgId(id))
                        setIsModalDurgVisible(true)
                        const text: any = id
                        if (text == 'edit') {
                          const data = source
                        }
                      })}
                      dataSource={setmealList}
                      pagination={false}
                      bordered
                      rowSelection={
                        {
                          type: selectionType,
                          ...rowSelection,
                        } as TableRowSelection<any>
                      }
                    ></Table> */}
                  </>
                ) : (
                  <></>
                )}
              </>
            )
          })}
        </div>
        <div className={styles.pagination}>
          <Pagination
            total={total}
            current={current}
            pageSize={size}
            showSizeChanger
            pageSizeOptions={['10', '20', '50', '100', '500']}
            showQuickJumper
            showTotal={(total) => `共 ${total} 条`}
            style={{
              position: 'absolute',
              top: '10px',
              right: '14px',
            }}
            onChange={(current, size) => {
              dispatch(setSetmealCurrent(current))
              setSize(Number(size))
            }}
          />
        </div>
        <SetmealModal
          isModalVisible={isModalVisible}
          onOk={() => {
            dispatch(setSetmealSelectId(0))
            dispatch(setSetmealDetail({}))
            setIsModalVisible(false)
            dispatch(
              getSetmeal({
                ...queries,
                current,
                size,
                category,
              })
            )
          }}
          onCancel={() => {
            dispatch(setSetmealDetail({}))
            dispatch(setSetmealSelectId(0))
            setIsModalVisible(false)
          }}
        />
        <SetmealDurgModal
          category={category}
          flag={props.flag}
          tenantGoodsComboId={setmealId}
          isModalDurgVisible={isModalDurgVisible}
          maxSort={setmealList.reduce((m, a) => Math.max(m, a.sort), 0)}
          maxGroupNumber={setmealList.reduce(
            (m, a) => Math.max(m, a.groupNumber),
            0
          )}
          onOk={() => {
            dispatch(setSetmealDurgId(0))
            dispatch(getSetmealList(setmealId))
            setIsModalDurgVisible(false)
            dispatch(setSetmealDetail({}))
            dispatch(
              getSetmeal({
                ...queries,
                current,
                size,
                category,
              })
            )
            if (isShowAgain) {
              notification.success({ message: '添加成功', duration: 1.5 })
              setIsModalDurgVisible(true)
              dispatch(setsource(0))
            }
          }}
          onCancel={() => {
            setIsShowAgain(false)
            dispatch(setSetmealDurgId(0))
            setIsModalDurgVisible(false)
          }}
        />
        <PurchaseModal
          refresh={() => {
            dispatch(
              getSetmeal({
                ...queries,
                current,
                size,
                category,
              })
            )
          }}
          state={99}
          isPurchaseModalVisible={isPurchaseModalVisible}
          onOk={() => {
            setIsPurchaseModalVisible(false)
          }}
          onCancel={() => {
            setIsPurchaseModalVisible(false)
          }}
        />
      </div>
    </div>
  )
}

export const SetMeal = forwardRef(Setmeal)
