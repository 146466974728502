/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-06 13:49:22
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-13 16:05:54
 */
import { Col, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { NavBar } from "../../../compnents/nav/NavBar";
import { TabPane } from "../../../compnents/widgets/TabBar";
import styles from "../Storesearch.module.css";
import { BasicFacts } from "./BasicFacts";
import { ChangePrice } from "./ChangePrice";
import { ChangeWasteBook } from "./ChangeWasteBook";
import { StockMaintance } from "./StockMaintance";

export const StoreSeaechDetail = () => {
  const location: any = useLocation();

  const dispatch = useDispatch();

  return (
    <>
      <Col
        style={{
          margin: "10px 20px",
        }}
      >
        <NavBar
          where={["库存查询列表", "库存查询明细"]}
          backtrace={{
            name: "库房查询",
            path: "/storesearch",
            state: {
              flag: location?.state?.flag,
            },
            routeName: location?.state?.flag ? "医生工作站" : "库房工作站",
          }}
          buttons={<></>}
        />
      </Col>
      <div className={styles.detailContent}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="基本信息" key="1">
            <BasicFacts
              SelectId={location?.state?.selectId}
              flag={location?.state?.flag}
            />
          </TabPane>
          <TabPane tab="库存变动流水" key="2">
            <ChangeWasteBook />
          </TabPane>
          <TabPane tab="库存养护记录" key="3">
            <StockMaintance />
          </TabPane>
          <TabPane tab="库存调价记录" key="4">
            <ChangePrice />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
