/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 10:00:17
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { api } from "../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../app/store";
import { fromJson, revenueReport } from "../../../models/revenueReport"
import * as service from "../../../services/revenueReport";
import { sendAsync } from "../../../app/applicationSlice";
import { revenueReportParams } from "../../../services/revenueReport";

interface RevenueReports {
    revenueReportList: revenueReport[];
  pageLoading: false
}

const initialState: RevenueReports = {
    revenueReportList: [], 
  pageLoading: false
  };

  const revenueReportSlice = createSlice({
    name: "revenueReportSlice",
    initialState,
    reducers: {
      setReportList: (state, action) => {
        state.revenueReportList = action.payload;
      },
      setPageLoading: (state, action) => {
        state.pageLoading = action.payload;
      },
    },
  });

  export const {
    setReportList,
    setPageLoading,
  } = revenueReportSlice.actions;


export const selectReportList =(state:RootState) =>state.revenueReport.revenueReportList;

export const selectPageLoading = (state: RootState) => state.revenueReport.pageLoading;

export function getRevenueReportList(params:revenueReportParams): RootThunk {
    return api(service.getRevenueReport(params), (data: any, dispatch) => {
      dispatch(setReportList([data]));
      dispatch(setPageLoading(false));
     
    });
  }

  export const getRevenueReportExoprt = createAsyncThunk<
  void,
  revenueReportParams,
  RootThunkApi
>("doctorAudit/getRevenueReportExoprt ", async (params, api) => {
  return sendAsync(service.getRevenueReportExport(params), api);
});


  export default revenueReportSlice.reducer;