import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../../app/applicationSlice";
import { RootThunkApi } from "../../../app/store";
import { CompletedOperationList, CompletedOperationParams, } from "../../../services/completedOperation";

// 列表
export const getCompletedOperationList = createAsyncThunk<
  void,
 CompletedOperationParams,
  RootThunkApi<void>
>("medicalstation/toBeCompletedOperationList", async (params, api) => {
  return sendAsync(CompletedOperationList(params), api);
});

