import { Button, Col, Row } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { Console } from "node:console";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditableList } from "../../compnents/list/EditableList";
import { permissionsParams } from "../../services/permissions";
import { DataType } from "../putstorage/putstorage";
import { Columns } from "./columns";
import {
  editPermissions,
  pagePermissions,
  // pagePermissionsAsync,
  selectpermissionsfromData,
  selectpermissionsfromTotal,
} from "./permissionsfromSlice";
import { PermissionsfromQuery } from "./query";

export const PermissionsFrom = (props: {
  id: number;
  name: string;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  const [queries, setQueries] = useState<permissionsParams>();

  const [Ids, setIds] = useState<number[]>([]);

  const [rowkey, setRowkey] = useState<any[]>([]);

  const total = useSelector(selectpermissionsfromTotal);

  const dispatch = useDispatch();

  const data = useSelector(selectpermissionsfromData);

  useEffect(() => {
    dispatch(pagePermissions({ storehouseId: props.id, ...queries }));
  }, [props.id, queries]);

  useEffect(() => {
    setIds(
      data
        .map((v: any, i) => (v.flag > 0 ? v.id + i + 100 : -1))
        .filter((n) => n >= 0)
    );
  }, [data]);

  return (
    <>
      <PermissionsfromQuery
        onValueChange={(v: any) => {
          setQueries(v);
        }}
      />
      <EditableList
        style={{ height: "70%", overflowY: "auto", marginBottom: "20px" }}
        page={{
          current: 1,
          size: 100,
          total,
          items: data,
        }}
        rowKey={(record, i) => record.id + i + 100}
        pagination={false}
        columns={Columns()}
        bordered={false}
        rowSelection={
          {
            type: selectionType,
            selectedRowKeys: Ids,
            onChange: (keys, row) => {
              setIds(keys as number[]);
              const arr = JSON.parse(JSON.stringify(row));
              const rowdata = arr.map((v: any) => {
                if (v.storehouseId === -1) {
                  v.storehouseId = props.id;
                }
                if (v.storehouseName === "") {
                  v.storehouseName = props.name;
                }
                return v;
              });
              setRowkey(rowdata);
            },
          } as TableRowSelection<any>
        }
      />
      <Row gutter={20}>
        <Col span={12}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            onClick={() => {
              dispatch(editPermissions(props.id, rowkey));
              props.onOk();
            }}
          >
            保存
          </Button>
        </Col>
        <Col span={12}>
          <Button
            htmlType="button"
            style={{ float: "left" }}
            onClick={() => {
              setIds([]);
              props.onCancel();
            }}
          >
            关闭
          </Button>
        </Col>
      </Row>
    </>
  );
};
