/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-13 10:49:55
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 14:03:23
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api, sendAsync } from '../.././app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import {
  durgSalesPage,
  DurgSalesParams,
  getDurgsalesExoprtFn,
} from '../../services/durgSales'

const initialState = {
  DurgSalesData: [],
  DurgSalesTotal: 0,
  DurgSalesCurrent: 1,
  otcTag: undefined,
  materialsCategory: undefined,
  pageLoading: false,
}

export const DurgSalesSlice = createSlice({
  name: 'DurgSales',
  initialState,
  reducers: {
    setDurgSaleData(state, action) {
      state.DurgSalesData = action.payload
    },
    setDurgSaleTotal(state, action) {
      state.DurgSalesTotal = action.payload
    },
    setDurgSaleCurrent(state, action) {
      state.DurgSalesCurrent = action.payload
    },
    setotcTag(state, action) {
      state.otcTag = action.payload
    },
    setmaterialsCategory(state, action) {
      state.materialsCategory = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setDurgSaleData,
  setDurgSaleCurrent,
  setDurgSaleTotal,
  setotcTag,
  setmaterialsCategory,
  setPageLoading,
} = DurgSalesSlice.actions

export function getDurgsales(params: DurgSalesParams): RootThunk {
  return api(durgSalesPage(params), (data: any, dispatch) => {
    dispatch(setDurgSaleData(data.records))
    dispatch(setDurgSaleTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

// 导出
export const getDurgsalesExoprt = createAsyncThunk<
  void,
  DurgSalesParams,
  RootThunkApi
>('doctorAudit/getDurgsalesExoprt ', async (params, api) => {
  return sendAsync(getDurgsalesExoprtFn(params), api)
})

export const selectDurgSalesData = (state: RootState) =>
  state.DurgSales.DurgSalesData

export const selectDurgSalesTotal = (state: RootState) =>
  state.DurgSales.DurgSalesTotal

export const selectDurgSalesCurrent = (state: RootState) =>
  state.DurgSales.DurgSalesCurrent

export const selectOtctag = (state: RootState) => state.DurgSales.otcTag

export const selectmaterialsCategory = (state: RootState) =>
  state.DurgSales.materialsCategory

export const selectPageLoading = (state: RootState) =>
  state.DurgSales.pageLoading

export default DurgSalesSlice.reducer