/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:28
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-01 15:34:26
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
  DatePicker,
  Checkbox,
} from 'antd'
import { unwatchFile } from 'fs'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectTenantCountyCode,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'

export const Query = (props: {
  onValueChange: (params: any) => void
  print:()=>void
}): ReactElement => {
  const [form] = Form.useForm()

  const { RangePicker } = DatePicker

  const dispatch = useDispatch<RootDispatch>()

  const [flag, setFlag] = useState<any>()

  const [timeList, setTimeList] = useState<any>()

  const tenantCountyCode = useSelector(selectTenantCountyCode)

  useEffect(() => {
    setTimeList([moment().startOf('month'), moment().endOf('day')])
    form.setFieldsValue({
      time: [moment().startOf('month'), moment().endOf('day')],
    })
    form.submit()
  }, [tenantCountyCode])

  // const disabledDate: any = (current: any) => {
  //   return timeList?.[0]
  //     ? moment(current)?.format('yyyy') != moment(timeList?.[0])?.format('yyyy')
  //     : false
  // }

  return (
    <Form
      form={form}
      onFinish={(values) => {
        const startTime = moment(values?.time?.[0])
          ?.startOf('day')
          ?.format(DateTimeFormatSimpleOnlyOne)
        const endTime = moment(values?.time?.[1])
          ?.endOf('day')
          ?.format(DateTimeFormatSimpleOnlyOne)
        props.onValueChange({
          ...values,
          timeHead: startTime,
          timeTail: endTime,
          time: undefined,
        })
      }}
    >
      <Row style={{ marginLeft: 4, marginBottom: 10 }} justify='space-between'>
        <Space>
          <Form.Item name='time' noStyle>
            <RangePicker
              format={DateTimeFormatSimple}
              style={{ width: '16em' }}
              onCalendarChange={(v: any) => {
                setTimeList(v)
                form.submit()
              }}
            />
          </Form.Item>
          <Form.Item name='yidibz' noStyle>
            <Select
              placeholder='异地标志'
              style={{ width: '10rem' }}
              allowClear
              onChange={() => {
                form.submit()
              }}
            >
              <Select.Option value={'1'}>省内异地</Select.Option>
              <Select.Option value={'2'}>省外异地</Select.Option>
            </Select>
          </Form.Item>
          <Button
            type='primary'
            onClick={() => {
              form.submit()
            }}
          >
            查询
          </Button>
        </Space>
        <Space>
          <span>核拨表的金额仅包含处方明细中:收费类别为医保或历年的金额</span>
          <Button
            type='primary'
            onClick={() => {
             props?.print()
            }}
          >
            打印
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
