/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-09-08 09:22:39
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface HistorylistParams {
  current?: number
  size?: number
  name?: string
}
export function getHistorylist(params: HistorylistParams): Request {
  return {
    url: `${API_PREFIX}/blade-precheck/api/precheck/historylist`,
    method: 'GET',
    params,
  }
}

export function registrationPatientList(params: HistorylistParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/registrationPatientList`,
    method: 'GET',
    params,
  }
}
