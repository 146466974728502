/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-09-03 13:38:14
 * @LastEditors: sj
 * @LastEditTime: 2022-08-19 09:48:37
 */
/* eslint-disable react/prop-types */
import { unwrapResult } from "@reduxjs/toolkit"
import { notification, Upload } from "antd"
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface"
import { nanoid } from "nanoid"
import React from "react"
import { useDispatch } from "react-redux"
import { RootDispatch } from "../../app/store"
import { OssParams } from "../../models/ossparams"
import { getFileUploadToken, uploadFileCloud } from "./fileSlice"

interface FileUploadProps {
  count?: number
  maxCount?: number
  files?: UploadFile[]
  onUpload?: (file: RcFile, key: string) => void
  bucketName?: string
  beforeUpload?: (file: RcFile) => void
  btn?: (bol: boolean) => void
  disabled?: boolean
  accept?: string
}

const FileUpload: React.FC<FileUploadProps & Pick<UploadProps, "onChange">> = ({
  count = 0,
  maxCount = 5,
  disabled = false,
  files,
  onChange,
  onUpload,
  children,
  bucketName,
  btn,
  beforeUpload,
  accept
}) => {
  const dispatch = useDispatch<RootDispatch>()
  return (
    <Upload
      accept={accept}
      disabled={disabled}
      maxCount={maxCount}
      fileList={files}
      onChange={onChange}
      showUploadList={false}
      beforeUpload={(file) => beforeUpload?.(file)}
      customRequest={async ({ file, onSuccess, onError }) => {
        let params: OssParams | undefined
        if (count < maxCount) {
          if (file instanceof File) {
            try {
              params = unwrapResult(
                await dispatch(getFileUploadToken(bucketName))
              )

              const index = file.name.lastIndexOf(".")
              const ext = index >= 0 ? file.name.substring(index) : ""
              const key = unwrapResult(
                await dispatch(
                  uploadFileCloud({
                    ...params,
                    file,
                    key: `${nanoid()}${ext}`,
                  })
                )
              )
              onSuccess?.(null, (null as unknown) as XMLHttpRequest)
              if (bucketName) {
                onUpload?.(file as RcFile, `${params.host}/${key}`)
              } else {
                onUpload?.(file as RcFile, key)
              }
            } catch (e) {
              onError?.({
                name: e.name,
                url: params?.host,
                method: "POST",
                message: e.message,
              })
            }
          }
        } else {
          btn?.(false)
          notification.warn({
            message: `限制最多上传${maxCount}个`
          })
        }
      }}
    >
      {children}
    </Upload>
  )
}

export default FileUpload
