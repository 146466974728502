/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-05-19 14:28:59
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-19 14:45:29
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import {
  failureDirectoryParams,
  getFailureDirectory,
} from '../../services/failureDirectory'

export const getFailureDirectoryPage = createAsyncThunk<
  void,
  failureDirectoryParams,
  RootThunkApi
>('ToothHome/getFailureDirectoryPage', async (params, api) => {
  return sendAsync(getFailureDirectory(params), api)
})
