/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2021-06-02 10:13:36
 */
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface storehouseParams {
    current?: number,
    size?: number,
    flag?: any,
    state?:any,
}

export interface storehouseBody {
    category?: number,
    deliveryMethod?: number,
    id?: number,
    name?: string,
    servicePurchaseStorage?: number,
    serviceShopPrice?: number,
    state?: number,
}

export function durgSystemPage(
    current: number,
    size: number,
    state?: number
): Request {
    return {
        url: `${API_PREFIX}/blade-store/storehouse/page`,
        method: "GET",
        params: {
            current,
            size,
            state
        }
    }
}

export function storageget(
    params: storehouseParams
): Request {
    return {
        url: `${API_PREFIX}/blade-store/storehouse/list`,
        method: "GET",
        params
    }
}

export function durgsystemDetail(
    id: number
): Request {
    return {
        url: `${API_PREFIX}/blade-store/storehouse/detail`,
        method: 'get',
        params: {
            id
        }
    }
}

export function durgsystemlogoutOrEnable(
    id: number
): Request {
    return {
        url: `${API_PREFIX}/blade-store/storehouse/logoutOrEnable`,
        method: 'POST',
        params: {
            id
        }
    }
}

export function durgSystemSave(
    body: storehouseBody
): Request {
    return {
        url: `${API_PREFIX}/blade-store/storehouse/submit`,
        method: 'POST',
        body
    }
}