import { Button, TableColumnType } from "antd";
import moment from "moment";
import React from "react";
import {
  DelectOutlined,
  PrintOutlined,
} from "../../../../compnents/icons/Icons";
import { DateTimeFormatSimple } from "../../../../models/datetime";

export const StockMaintenanceColumns = (
  onclick: (id: number, text: string, rowdata?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      key: "no",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "养护单号",
      align: "center",
      dataIndex: "no",
      key: "no",
    },

    {
      title: "库房名称",
      align: "center",
      dataIndex: "storehouseName",
      key: "storehouseName",
    },
    {
      title: "养护等级",
      align: "center",
      key: "maintainCategory",
      dataIndex: "maintainCategory",
      render: (_, t) => {
        return t.maintainCategory > -1
          ? t.maintainCategory === 1
            ? "重点养护"
            : "一般养护"
          : "";
      },
    },
    {
      title: "养护时间",
      align: "center",
      dataIndex: "maintainTime",
      key: "maintainTime",
      render: (_, t) => {
        return moment(t.maintainTime).format(DateTimeFormatSimple);
      },
    },
    {
      title: "养护人员",
      align: "center",
      dataIndex: "mainUserName",
      key: "mainUserName",
    },
    {
      title: "备注",
      align: "center",
      dataIndex: "remark",
      key: "remark",
    },
    // 养护中、养护完成
    {
      title: "状态",
      align: "center",
      key: "state",
      dataIndex: "state",
      render: (_, t) => {
        return t.state > -1 ? (t.state ? "养护完成" : "养护中") : "";
      },
    },
    {
      title: "操作",
      align: "center",
      render: function Element(_, t) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "32px",
            }}
          >
            <Button
              style={{
                color: "#666",
                display: "flex",
                height: "32px",
                alignItems: "center",
                marginRight: "10px",
              }}
              type="text"
              size="small"
              onClick={() => {
                onclick(t.id, "remove");
              }}
            >
              <DelectOutlined
                style={{
                  fill: "#FF5D5D",
                  width: "14px",
                  height: "14px",
                  marginRight: "4px",
                }}
              />
              删除
            </Button>
            {t.state == 0 ? (
              <>
                <Button
                  type="text"
                  size="small"
                  style={{
                    color: "rgb(28, 208, 189)",
                    display: "flex",
                    height: "32px",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    onclick(t.id, "completed");
                  }}
                >
                  养护完成
                </Button>
                <a
                  style={{ marginRight: "10px", color: "#027AFF" }}
                  onClick={() => {
                    onclick(t.id, "edit");
                  }}
                >
                  编辑内容
                </a>
              </>
            ) : (
              <>
                <Button
                  style={{
                    display: "flex",
                    height: "32px",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                  type="text"
                  size="small"
                  onClick={() => {
                    onclick(t.id, "print");
                  }}
                >
                  <PrintOutlined
                    style={{
                      fill: "rgb(28, 208, 189)",
                      width: "18px",
                      height: "32px",
                      marginRight: "4px",
                    }}
                  />
                  打印
                </Button>
                <a
                  style={{ marginRight: "10px", color: "#027AFF" }}
                  onClick={() => {
                    onclick(t.id, "edit");
                  }}
                >
                  查看详情
                </a>
              </>
            )}
          </div>
        );
      },
    },
  ];
};
