/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 17:21:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-04 18:31:27
 */
import { Button, Col } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { selectUserId } from '../../app/applicationSlice'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { selectMenus, selectTwoMenus } from '../../layouts/layoutSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import { OutpatientAudit } from '../outpatientaudit/OutpatientAudit'
import { OutPatientList } from '../outpatientList/outpatientList'
import { DoctorSales } from '../sales/doctorSales/doctorSales'
import { IllnessSettingS } from '../sales/illnessSetting/illnessSettingList'
import { PeopleSales } from '../sales/peopleSales/peopleSales'
import { SetMeal, SetMealRef } from '../setmeal/setmeal'
import { setSetmealSelectId } from '../setmeal/setmealSlice'
import { TemplateEditor } from '../template/TemplateEditor'
import styles from './Doctorworkstation.module.css'
import MedicalBeautyTemplateEditor from '../medicalBeautyTemplate/MedicalBeautyTemplateEditor'
import { RecipeQueryList } from './recipeQuery/recipeQueryList'

export const Doctorworkstation = () => {
  const history = useHistory()

  const dispatch = useDispatch()

  const ref = useRef<SetMealRef>(null)

  const location: any = useLocation()

  const theme = useContext(ThemeContext)

  const [tabKey, setSelectTabKey] = useState<any>('')

  const doctorId = useSelector(selectUserId)

  const exRef = useRef<any>(null)

  const twoMenus = useSelector(selectTwoMenus)

  const menuList = twoMenus?.filter((v) => {
    return v?.name == '医生工作站'
  })?.[0]

  useEffect(() => {
    setSelectTabKey(location?.state?.station || '4')
  }, [location?.state])



  const getKeys = (i: string) => {
    switch (i) {
      case '门诊看板':
        return '4'
      case '诊断模板':
        return '0'
      case '套餐设置':
        return '1'
      case '门诊日志':
        return '2'
      case '库存查询':
        return '3'
      case '人均统计':
        return '5'
      case '疾病设置':
        return '6'
      case '高级病历模板':
        return '7'
      default:
        return '-'
    }
  }

  return (
    <Col className={styles.container}>
      <TabBar
        activeKey={tabKey}
        onChange={(v) => {
          if (v === '3') {
            history.push({
              pathname: '/storesearch',
              state: { flag: 1 },
            })
          }
          setSelectTabKey(v)
        }}
      >
        {menuList?.subMenus?.map((v:any) => {
          return (
            <>
              <TabPane
                key={getKeys(v?.name)}
                tab={v?.name}
                style={{ height: tabKey == 3 ?'100%' :'' }}
              >
                {v?.name == '门诊看板' ? (
                  <DoctorSales doctorId={doctorId} showPanle={true} />
                ) : v?.name == '诊断模板' ? (
                  <TemplateEditor />
                ) : v?.name == '套餐设置' ? (
                  <SetMeal ref={ref} flag={0} />
                ) : v?.name == '门诊日志' ? (
                  <Col
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: theme.pn,
                      borderRadius: 10,
                      margin: '10px 0',
                      height: 'calc(100% - 10px)',
                    }}
                  >
                    <OutPatientList ref={exRef} state={1} parentId={v?.id} />
                  </Col>
                ) : v?.name == '人均统计' ? (
                  <PeopleSales doctorId={doctorId} />
                ) : v?.name == '疾病设置' ? (
                  <IllnessSettingS tabKey={tabKey} />
                ) : v?.name == '高级病历模板' ? (
                  <MedicalBeautyTemplateEditor />
                ) :v?.name == '处方查询' ? (
                  <RecipeQueryList tabKey={tabKey} />
                ) : (
                  <></>
                )}
              </TabPane>
            </>
          )
        })}
        {/* <TabPane key='4' tab='门诊看板'>
          <DoctorSales doctorId={doctorId} showPanle={true} />
        </TabPane>
        <TabPane key='0' tab='诊断模板'>
          <TemplateEditor />
        </TabPane>
        <TabPane key='1' tab='套餐设置'>
          <SetMeal ref={ref} flag={0} />
        </TabPane>
        <TabPane key='2' tab='门诊日志' style={{ height: '100%' }}>
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.pn,
              borderRadius: 10,
              margin: '10px 0',
              height: 'calc(100% - 10px)',
            }}
          >
            <OutPatientList ref={exRef} state={1} />
          </Col>
        </TabPane>
        <TabPane key='3' tab='库存查询'></TabPane>

        <TabPane key='5' tab='人均统计'>
          <PeopleSales doctorId={doctorId} />
        </TabPane>
        <TabPane key='6' tab='疾病设置'>
          <IllnessSettingS />
        </TabPane> */}
      </TabBar>
    </Col>
  )
}
