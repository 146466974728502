/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-15 13:46:42
 * @LastEditors: sj
 * @LastEditTime: 2022-06-27 17:42:20
 */
import React, { ReactElement, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { TabBar, TabPane } from "../../compnents/widgets/TabBar"
import { DeliveryState } from "../../models/delivery"
import { DeliveryList } from "./DeliveryList"

interface DeliveryParams {
  tockSalesUploadCategory?: string
}

export const Delivery = (
  { tockSalesUploadCategory }: DeliveryParams
): ReactElement => {
  const location: any = useLocation()

  const [selectedTab, setSelectedTab] = useState("0")

  const dataParams = JSON.parse(sessionStorage.getItem(location.pathname) || '{}')

  useEffect(() => {
    if (location?.state || tockSalesUploadCategory || dataParams?.shipmentsState) {
      setSelectedTab(tockSalesUploadCategory || location?.state?.station || dataParams?.shipmentsState?.toString())
    }
  }, [])


  return (
    <TabBar
      activeKey={selectedTab}
      onChange={(v) => setSelectedTab(v)}
      style={{ padding: tockSalesUploadCategory ? '10px 0' : '10px 20px' }}
    >
      {!tockSalesUploadCategory && (
        <TabPane key={DeliveryState.Unfilled} tab='待发药'>
          <DeliveryList
            deliveryState={DeliveryState.Unfilled}
            shipmentsState={0}
          />
        </TabPane>
      )}
      <TabPane key={DeliveryState.Fulfilled} tab='已发药'>
        <DeliveryList
          tockSalesUploadCategoryType={tockSalesUploadCategory ? 1 : undefined}
          deliveryState={DeliveryState.Fulfilled}
          shipmentsState={1}
        />
      </TabPane>
      <TabPane key={DeliveryState.Returned} tab='已退药'>
        <DeliveryList
          tockSalesUploadCategoryType={tockSalesUploadCategory ? 9 : undefined}
          deliveryState={DeliveryState.Returned}
          shipmentsState={9}
        />
      </TabPane>
    </TabBar>
  )
}
