/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-13 10:50:46
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-17 11:26:03
 */
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { pageSupplier, selectSupplierdata } from '../wms/supplierSlice'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { DurgSalesParams } from '../../services/durgSales'
import {
  BillingCategories,
  BillingCategoryName,
  InvoiceBillingCategories,
} from '../../models/billingCategory'
import { Department } from '../../models/department'
import { DepartmentDate, selectDepartData } from '../putstorage/putstorageSlice'
import {
  getAllUserName,
  sleectUserData,
} from '../putstorelist/putstorelistSlice'
import {
  setmaterialsCategory,
  setotcTag,
  selectOtctag,
  selectmaterialsCategory,
} from './DurgsalesSlice'
import { setDurgSaleCurrent } from './DurgsalesSlice'
import styles from './durgsales.module.css'
import {getDoctorsInDepartment, selectDoctors, setDoctors} from "../registration/register/registerSlice";
const { Option } = Select

export const DuresalesQuery = (props: {
  onValueChange: (queries: DurgSalesParams) => void
  outClued: () => void
  continueOrder: (v: any, type: string) => void
}) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const otc = useSelector(selectOtctag)

  const materialsCategory = useSelector(selectmaterialsCategory)

  const departmentData = useSelector(selectDepartData)

  const userData = useSelector(sleectUserData)

  const doctors = useSelector(selectDoctors)

  const [djddChecked, setDjddChecked] = useState<any>(true)

  const [jzcxChecked, setJzcxChecked] = useState<any>(false)

  useEffect(() => {
    dispatch(setDoctors(doctors))
  }, [doctors])

  useEffect(() => {
    dispatch(setDoctors(userData))
  }, [userData])

  useEffect(() => {
    dispatch(pageSupplier({ current: 1, size: 100, supplierCategory: 1 }))
    dispatch(DepartmentDate({ current: 1, size: 1000 }))
    dispatch(getAllUserName({ current: 1, size: 1000, stationCategory: 1 }))

    // const [head, tail] = getDateRange(DateRangeType.Today);
    // form.setFieldsValue({
    // [DateRangeFieldName]: DateRangeType.Today,
    //   timeHead: head,
    //   timeTail: tail,
    // });
    form.submit()
  }, [])

  // useEffect(() => {
  //   form.validateFields().then((values) => {
  //     props.onValueChange({
  //       ...form.getFieldsValue(),
  //       state: values?.state ? values.state : null,
  //       otc: otc,
  //       materialsCategory: materialsCategory,
  //     });
  //   });
  // }, [otc, materialsCategory]);

  return (
    <Form
      form={form}
      name='control-hooks'
      // onFinish={(fs) => {
      //   dispatch(setDurgSaleCurrent(1));
      //   const state = fs.find((v: any) => v.name[0] === "state");
      //   props.onValueChange({
      //     ...form.getFieldsValue(),
      //     state: state?.value ? state.value : null,
      //     otc: otc,
      //     materialsCategory: materialsCategory,
      //   });
      // }}
      onFieldsChange={(_, fs) => {
        dispatch(setDurgSaleCurrent(1))
        const state = fs.find((v: any) => v.name[0] === 'state')
        props.onValueChange({
          ...form.getFieldsValue(),
          showPotion: Number(djddChecked),
          isAccurate: Number(jzcxChecked),
          state: state?.value ? state.value : null,
        })
      }}
    >
      <Row gutter={11} wrap={false} style={{ height: '40px'}}>
        <Col span={4}>
          <Form.Item name='param'>
            <Input
              size='middle'
              placeholder='药品名称/助记码/条形码/物资编号'
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        {/* <Col span={2}>
          <Form.Item name="state">
            <Select
              placeholder="险种类别"
              allowClear
              suffixIcon={<CaretDownFilled />}
            >
              <Option value="0">注销</Option>
              <Option value="1">启用</Option>
            </Select>
          </Form.Item>
        </Col> */}
        {/* <Col span={2}> */}
        <Form.Item name='showPotion'>
          <Checkbox
              style={{
                color: '#666',
                display: 'flex',
                alignItems: 'center',
              }}
              checked={jzcxChecked}
              onChange={(e) => {
                props?.continueOrder && props?.continueOrder(e.target.checked, 'jzcx')
                setJzcxChecked(e.target.checked)
              }}
            >
              是否精准查询
            </Checkbox>
          </Form.Item>
        {/* </Col> */}
        <Col span={2}>
          <Form.Item name='state'>
            <Select
              placeholder='收费类型'
              allowClear
              dropdownClassName={styles.select}
              // suffixIcon={<CaretDownFilled />}
            >
              <Option value={0}>全部</Option>
              <Option value={3}>已收费</Option>
              <Option value={5}>已退费</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='billingCategory'>
            <Select
              placeholder='开票项目'
              allowClear
              dropdownClassName={styles.select}
              // suffixIcon={<CaretDownFilled />}
            >
              {InvoiceBillingCategories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {BillingCategoryName(v)}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='ephedrineTag'>
            <Select
              placeholder='是否麻黄碱'
              allowClear
              dropdownClassName={styles.select}
              // suffixIcon={<CaretDownFilled />}
            >
              <Option value={0}>是否麻黄碱—否</Option>
              <Option value={1}>是否麻黄碱—是</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='departmentId'>
            <Select
              placeholder='开单科室'
              allowClear
              dropdownClassName={styles.select}
              // suffixIcon={<CaretDownFilled />}
              onChange={(value) => {
                if (value) {
                  dispatch(setDoctors([]))
                  dispatch(getDoctorsInDepartment(value))
                } else {
                  dispatch(setDoctors(userData))
                }
              }}
            >
              {departmentData.map((v: Department) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='doctorId'>
            <Select
              showSearch
              placeholder='开单医生'
              allowClear
              dropdownClassName={styles.select}
              // suffixIcon={<CaretDownFilled />}
            >
              {doctors.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7} flex={1}>
          <Form.Item name=''>
            <DateSelect
              initialValue={DateRangeType.Today}
              placeholder='有效期范围'
              style={{ width: '8rem' }}
              namePrefix='time'
              labelPrefix=''
              allowClear={false}
            />
          </Form.Item>
        </Col>
        {/* <Col span={2}>
          <Button type="primary" style={{ float: "right", width: "100%" }}>
            导出
          </Button>
        </Col> */}
        <Col span={5} style={{ display: 'flex', justifyContent: 'end' }}>
          <Form.Item name='isAccurate'>
            <Checkbox
              style={{
                color: '#666',
                display: 'flex',
                alignItems: 'center',
                float: 'right',
              }}
              checked={djddChecked}
              onChange={(e) => {
                props?.continueOrder && props?.continueOrder(e.target.checked, 'djdd')
                setDjddChecked(e.target.checked)
              }}
            >
              包含代煎订单
            </Checkbox>
          </Form.Item>
          <Button
            htmlType='submit'
            type='primary'
            style={{ marginRight: '10px' }}
          >
            查询
          </Button>
          <Button type='primary' onClick={() => props.outClued()}>
            导出
          </Button>
        </Col>
      </Row>
      <Row gutter={20} style={{ height: '40px' }}>
        <Col>
          <Form.Item label='OTC分类'>
            <Button
              type='text'
              size='small'
              style={{ color: otc === 0 ? '#027AFF' : '#666' }}
              onClick={() => {
                if (otc === 0) {
                  dispatch(setotcTag(undefined))
                } else {
                  dispatch(setotcTag(0))
                }
              }}
            >
              处方药
            </Button>
            <Button
              type='text'
              size='small'
              style={{ color: otc === 1 ? '#027AFF' : '#666' }}
              onClick={() => {
                if (otc === 1) {
                  dispatch(setotcTag(undefined))
                } else {
                  dispatch(setotcTag(1))
                }
              }}
            >
              OTC
            </Button>
            <Button
              type='text'
              size='small'
              style={{ color: otc === 2 ? '#027AFF' : '#666' }}
              onClick={() => {
                if (otc === 2) {
                  dispatch(setotcTag(undefined))
                } else {
                  dispatch(setotcTag(2))
                }
              }}
            >
              处方药双轨
            </Button>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label='物资类别'>
            <Button
              type='text'
              size='small'
              style={{
                color: materialsCategory === '0' ? '#027AFF' : '#666',
              }}
              onClick={() => {
                if (materialsCategory === '0') {
                  dispatch(setmaterialsCategory(undefined))
                } else {
                  dispatch(setmaterialsCategory('0'))
                }
              }}
            >
              药品
            </Button>
            <Button
              type='text'
              size='small'
              style={{
                color: materialsCategory === '1' ? '#027AFF' : '#666',
              }}
              onClick={() => {
                if (materialsCategory === '1') {
                  dispatch(setmaterialsCategory(undefined))
                } else {
                  dispatch(setmaterialsCategory('1'))
                }
              }}
            >
              材料
            </Button>
            <Button
              type='text'
              size='small'
              style={{
                color: materialsCategory === '2' ? '#027AFF' : '#666',
              }}
              onClick={() => {
                if (materialsCategory === '2') {
                  dispatch(setmaterialsCategory(undefined))
                } else {
                  dispatch(setmaterialsCategory('2'))
                }
              }}
            >
              器械
            </Button>
            {/* <Button
              type="text"
              size="small"
              style={{
                color: materialsCategory === "3" ? "#027AFF" : "#666",
              }}
              onClick={() => {
                if (materialsCategory === "3") {
                  dispatch(setmaterialsCategory(undefined));
                } else {
                  dispatch(setmaterialsCategory("3"));
                }
              }}
            >
              保健品
            </Button> */}
            <Button
              type='text'
              size='small'
              style={{
                color: materialsCategory === '4' ? '#027AFF' : '#666',
              }}
              onClick={() => {
                if (materialsCategory === '4') {
                  dispatch(setmaterialsCategory(undefined))
                } else {
                  dispatch(setmaterialsCategory('4'))
                }
              }}
            >
              其他
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
