import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface CouponsSettingListPrams {
    current?: number
    size?: number
    couponsName?: string
    category?: number
    status?: number
}

// 优惠券设置列表
export function couponsSettingList(
    params: CouponsSettingListPrams
): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/coupons/list`,
        method: 'GET',
        params,
    }
}

export interface CouponsSettingSubmitParams {
    itemName?: string
    name?: string
    category?: number
    discount?: string
    id?: number
    status?: number
}

//优惠券新建与修改
export function couponsSettingSubmit(
    body: CouponsSettingSubmitParams
): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/coupons/submit`,
        method: 'POST',
        body,
    }
}

export interface CouponsSettingGenerateParams {
    id?: number
    quantity?: string
}

//生成券
export function couponsSettingGenerate(
    body: CouponsSettingGenerateParams
): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/coupons/generate`,
        method: 'POST',
        body,
    }
}


//活动设置列表
export interface ActiveSettingListPrams {
    current?: number
    size?: number
    name?: string
    status?: number
}

export function activeSettingList(
    params: ActiveSettingListPrams
): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/activity/list`,
        method: 'GET',
        params,
    }
}

//活动设置新增编辑
export interface ActiveSettingSubmitPrams {
    name?: string
    status?: number
    couponsIds?: Array<number>
    effectiveDays?: string
}

export function activeSettingSubmit(
    body: ActiveSettingSubmitPrams
): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/activity/submit`,
        method: 'post',
        body,
    }
}

//活动设置详情
export interface ActiveSettingDetailPrams {
    id?: number
}

export function activeSettingDetail(
    params: ActiveSettingDetailPrams
): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/activity/detail`,
        method: 'GET',
        params,
    }
}

//优惠券发放
export interface CouponsIssuePrams {
    activityId?: number
    activityName?: string
    couponsIds?: Array<number>
    patientIds?: Array<number>
    effectiveDays?: string
    issueCount?: string
    issueReason?: string
    issueType?: number
}

export function couponsIssueSubmit(
    body: CouponsIssuePrams
): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/coupons/issue`,
        method: 'post',
        body,
    }
}

//优惠券领取列表
export interface CouponsRecordPrams {
    current?: number
    size?: number
    timeTail?: string
    timeHead?: string
    couponName?: string
    status?: number
}

export function couponsRecordList(
    params: CouponsRecordPrams
): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/coupons/listRecord`,
        method: 'GET',
        params,
    }
}

//使用失效
export interface CouponsRecordUpdateStatusPrams {
    id?: number
    status?: number
    useStatus?: number
}

export function couponsRecordUpdateStatus(
    params: CouponsRecordUpdateStatusPrams
): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/coupons/updateStatus`,
        method: 'GET',
        params,
    }
}