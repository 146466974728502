/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-29 11:05:58
 * @LastEditors: linxi
 * @LastEditTime: 2021-04-29 11:48:13
 */
export enum StationMeal {
    KF = "1",
    KC = "2",
    LS = "3",
    YF = "4",
    SP = "5",
    ZL = "6",
    TC = "7",
    GYS = "8",
    QT = "9",
}

export const StationMealName = [
    StationMeal.KF,
    StationMeal.KC,
    StationMeal.LS,
    StationMeal.YF,
    StationMeal.SP,
    StationMeal.ZL,
    StationMeal.TC,
    StationMeal.GYS,
    StationMeal.QT,
]

export function getStationMealName(stationMeal: StationMeal) {
    switch (stationMeal) {
        case "1":
            return "库房业务"
        case "2":
            return "库存管理"
        case "3":
            return "库房流水查询"
        case "4":
            return "药房管理"
        case "5":
            return "商品管理"
        case "6":
            return "诊疗管理"
        case "7":
            return "套餐管理"
        case "8":
            return "供应商管理"
        case "9":
            return "其他业务"
    }
}