/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: K
 * @LastEditTime: 2022-05-26 14:22:23
 */
import { Button, Popconfirm, TableColumnType } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectInsuranceArray } from "../../app/applicationSlice";
import { StateSwitch } from "../../compnents/widgets/StateSwitch";
import {
  Department,
  getDepartmentCategoryName,
  getDepartmentSubCategoryName,
} from "../../models/department";
import { Dimen } from "../../models/dimen";

export const columns = (
  toggleStatus: (department: Department) => void,
  startIndex: number,
  onAction: (action: 'upload' | 'repeal', id: number, t?: any) => void
): TableColumnType<any>[] => {
  const insuranceArray = useSelector(selectInsuranceArray)
  const [isInsurance, setIsInsurance] = useState(false)

  useEffect(() => {
    if (insuranceArray) {
      const exists = insuranceArray.some(v => v.insuranceCode === 'ZHEJIANG') || insuranceArray.some(v => v.insuranceCode === 'CHONGQING')
      setIsInsurance(exists)
    }
  }, [insuranceArray])

  return [
    {
      title: "序号",
      dataIndex: "order",
      align: "center",
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "科室代码",
      dataIndex: "code",
      align: "center",
      width: Dimen.Code,
      ellipsis: true,
    },
    {
      title: "科室名称",
      dataIndex: "name",
      align: "center",
      width: Dimen.DepartmentName,
      ellipsis: true,
    },
    {
      title: "科室类别",
      dataIndex: "category",
      align: "center",
      width: Dimen.Category,
      render: (_, d) =>
        d.id === "" ? "" : getDepartmentCategoryName(d.category),
    },
    {
      title: "科室类型",
      dataIndex: "subCategory",
      align: "center",
      ellipsis: true,
      render: (_, d) =>
        d.id === ""
          ? ""
          : d.subCategory
            .map((s: any) => getDepartmentSubCategoryName(s))
              .join("，"),
    },
    {
      title: "状态",
      key: "actions",
      align: "center",
      width: Dimen.Actions,
      render: function DepartmentStatus(_, d): ReactElement {
        return (
          <StateSwitch
            value={d.status === 1}
            onChange={() => toggleStatus(d)}
          />
        );
      },
    },
    {
      title: "操作",
      align: 'center',
      width: Dimen.Actions,
      render: function Element(_, t) {
        return (isInsurance && t.status === 1) ? (
          <Popconfirm
            title="确认执行此操作？"
            onConfirm={() => {
              const action = t.ybUploadFlag === '1' ? 'repeal' : 'upload'
              onAction(action, t.id)
            }}
            okText="确认"
            cancelText="取消"
          >
            <Button
              type='primary'
              ghost
              size='small'

        >
          {t.ybUploadFlag === '1' ? '撤销' : '上传'}
            </Button>
          </Popconfirm>) : ''
      }
    }
  ];
};
