import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectInsuranceArray } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../../compnents/form/DateSelect'
import { User } from '../../../models/user'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getFullUserList } from '../../completion/completionSlice'
import { getFullDoctorList } from '../../registration/list/registrationSlice'

export const Query = (props: {
  onValueChange: (params: any) => void
  selectedTab?: any
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const insuranceArray = useSelector(selectInsuranceArray)

  const [doctors, setDoctors] = useState<any>([])

  const [users, setUsers] = useState<User[]>([])

  const [current, setCurrent] = useState<any>()

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname + '_jiaoyichaxun') || '{}'
  )

  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)

    if (
      Object.keys(storageParams).length &&
      props.selectedTab == storageParams.selectedTab
    ) {
      form.setFieldsValue({
        ...storageParams,
        timeHead: storageParams?.registrationTimeHead || head,
        timeTail: storageParams?.registrationTimeTail || tail,
        [DateRangeFieldName]: storageParams?._dateRange || DateRangeType.Today,
      })
    } else {
      form.setFieldsValue({
        _dateRange: 0,
        registrationTimeHead: head,
        registrationTimeTail: tail,
      })
    }

    form.submit()
  }, [])

  const refresh = () => {
    props.onValueChange({
      ...storageParams,
      ...form.getFieldsValue(),
      selectedTab: props.selectedTab,
      current: current || 1,
    })
  }

  useEffect(() => {
    dispatch(getFullDoctorList())
      .then(unwrapResult)
      .then((ds) => setDoctors(ds))
      .catch(() => {
        // do nothing
      })
  }, [])

  useEffect(() => {
    dispatch(getFullUserList({ current: 1, size: 1000, state: 1 }))
      .then(unwrapResult)
      .then((users) => setUsers(users))
      .catch(() => {
        // do nothing.
      })
  }, [])

  return (
    <Form
      form={form}
      onFinish={(values) => {
        const data =
          props.selectedTab == storageParams.selectedTab ? storageParams : {}
        props.onValueChange({
          ...data,
          ...values,
          current: current,
          selectedTab: props.selectedTab,
        })
      }}
    >
      <Row style={{ marginTop: 10, marginBottom: 20 }} justify='space-between'>
        <Space>
          <Form.Item noStyle name='param'>
            <Input
              autoComplete='off'
              prefix={<SearchOutlined />}
              placeholder='患者姓名/手机号/助记码'
              style={{ width: '14rem' }}
              allowClear
              onChange={() => {
                setCurrent(1)
                refresh()
              }}
            />
          </Form.Item>
          {insuranceArray && insuranceArray.length !== 0 && (
            <Form.Item name='insuranceCode' noStyle>
              <Select
                placeholder='险种类型'
                style={{ width: '8rem' }}
                allowClear
                onChange={() => {
                  setCurrent(1)
                  refresh()
                }}
              >
                <Select.Option value={''}>全部</Select.Option>
                <Select.Option value={'ZIFEI'}>自费</Select.Option>
                <Select.Option value={'0'}>本地</Select.Option>
                <Select.Option value={'1'}>省内异地</Select.Option>
                <Select.Option value={'2'}>省外异地</Select.Option>
                <Select.Option value={'3'}>省医保</Select.Option>
                {/* {insuranceArray.map((v) => (
                  <Select.Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Select.Option>
                ))} */}
              </Select>
            </Form.Item>
          )}
          <Form.Item name='orderSource' noStyle>
            <Select
              placeholder='收费标志'
              style={{ width: '8rem' }}
              allowClear
              onChange={() => {
                setCurrent(1)
                refresh()
              }}
            >
              <Select.Option value={1}>门诊</Select.Option>
              <Select.Option value={2}>零售</Select.Option>
              <Select.Option value={3}>挂号</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='treatmentDoctorId' noStyle>
            <Select
              allowClear
              placeholder='接诊医生'
              style={{
                width: '8rem',
              }}
              onChange={() => {
                setCurrent(1)
                refresh()
              }}
            >
              {doctors.map((d: any) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle name='operateUser'>
            <Select
              placeholder='收款人'
              allowClear
              style={{ width: '8rem' }}
              onChange={() => {
                setCurrent(1)
                refresh()
              }}
              showSearch
            >
              {users.map((u) => (
                <Select.Option key={u.id} value={u.id}>
                  {u.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='state' noStyle>
            <Select
              placeholder='结算状态'
              style={{ width: '8rem' }}
              allowClear
              // suffixIcon={<CaretDownFilled />}
              onChange={() => {
                setCurrent(1)
                refresh()
              }}
            >
              <Select.Option key={3} value={3}>
                已收费
              </Select.Option>
              <Select.Option key={5} value={5}>
                已退费
              </Select.Option>
              <Select.Option key={7} value={7}>
                欠费中
              </Select.Option>
            </Select>
          </Form.Item>

          <DateSelect
            allowClear={false}
            style={{ width: '8.5rem' }}
            labelPrefix=''
            namePrefix='time'
            options={TJFXTime}
            placeholder='时间范围-时间'
            initialValue={DateRangeType.Today}
            onChange={refresh}
          />
          <Button
            type='primary'
            onClick={() => {
              setCurrent(1)
              refresh()
            }}
          >
            查询
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
