import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Radio,
  Row,
  TimePicker,
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { NavBar } from '../../../compnents/nav/NavBar'
import {
  getPayMethodName,
  PayMethod,
  PayMethods,
} from '../../../models/payment'
import {
  Checks,
  MedicelListValue,
  Settinglable,
  Settings,
} from '../../../models/setting'
import { setSettings } from '../../settings/settingsSlice'
import {
  getMedicalSettingList,
  getSettingDetails,
  saveTenentSettingLabel,
  selectTenentSettings,
} from './TenentSettingSlice'
import styles from './TenantSetting.module.css'
import { TenentSettingModal } from './TenentSettingModal'

export const TenentSetting = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const settings = useSelector(selectTenentSettings)

  const [shopValue] = useState()

  const [previewPayMethod, setPreviewPayMethod] = useState(PayMethod.Cash)

  const [settingVisible, setSettingVisible] = useState({
    id: 0,
    tenantId: location.search.replace('?', '') as any,
    visible: false,
  })

  const [medicalList, setMedicalList] = useState<MedicelListValue[]>([])

  const MedicalList = () => {
    dispatch(getMedicalSettingList(Number(location.search.replace('?', ''))))
      .then(unwrapResult)
      .then((res: MedicelListValue[]) => {
        setMedicalList(res)
      })
  }

  useEffect(() => {
    if (settings) {
      const defaultMethod =
        (parseInt(settings[Checks.Mrzffs]) as PayMethod) || PayMethod.Cash
      setPreviewPayMethod(defaultMethod)
      dispatch(
        setSettings({
          [Settings.Treatment.Settlement]: settings[Checks.Zjjs] || false,
          [Settings.Payment.DefautMethod]: defaultMethod,
        })
      )
      const value = settings[Checks.Zdrjsj]
      form.setFieldsValue({
        ...settings,
        [Checks.Zdrjsj]:
          value === '' || value === undefined || value === 'Invadlid date'
            ? undefined
            : moment(value, 'HH:mm'),
      })
    }
  }, [settings])

  useEffect(() => {
    const id = location.search.replace('?', '')
    dispatch(getSettingDetails({ tenantId: id }))
  }, [])

  // 医保险种列表
  useEffect(() => {
    MedicalList()
  }, [])

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      //   span: 19,
    },
  }
  return (
    <>
      <NavBar
        style={{ margin: '10px 20px' }}
        where={['机构管理', '功能设定']}
        backtrace={{
          name: '机构用户',
          path: '/admin/tenant',
        }}
      />
      <div
        style={{
          height: 'calc(100% - 70px)',
          margin: '10px 20px',
          display: 'flex',
          flexDirection: 'column',
          background: '#FFFFFF',
          borderRadius: '10px',
          overflowY: 'scroll',
        }}
      >
        <Form
          labelAlign='right'
          initialValues={{ remember: true }}
          form={form}
          autoComplete='off'
          onFinish={(values) => {
            const pattern = /^[0-9]*$/
            const f = _.chain(values)
              .pickBy((_, k) => pattern.test(k))
              .mapValues(
                (v, k) =>
                  ({
                    type: k,
                    value:
                      k === Checks.Zdrjsj.toString()
                        ? moment(v).format('HH:mm:ss')
                        : v?.toString(),
                  } as Settinglable)
              )
              .values()
              .value()
            dispatch(
              saveTenentSettingLabel({
                tenant: {
                  id: location.search.replace('?', ''),
                },
                functionList: f,
              })
            )
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '机构设置保存成功',
                  duration: 3,
                })
                const id = location.search.replace('?', '')
                dispatch(getSettingDetails({ tenantId: id }))
                form.resetFields()
              })
              .catch(() => {
                notification.error({
                  message: '机构设置保存失败',
                  duration: 3,
                })
                const id = location.search.replace('?', '')
                dispatch(getSettingDetails({ tenantId: id }))
                form.resetFields()
              })
          }}
        >
          <Row
            style={{
              height: '40px',
              lineHeight: '40px',
              display: 'flex',
              alignItems: 'center',
              fontSize: '15px',
              borderBottom: '1px solid #e6e6e6',
              fontWeight: 700,
            }}
          >
            <i
              style={{
                width: '2px',
                height: '10px',
                backgroundColor: '#0052D9',
                margin: '-14px  10px 0 30px',
              }}
            ></i>
            <p>机构功能设置信息</p>
          </Row>
          <Row style={{ marginLeft: '60px', marginTop: '20px' }}>
            <Col span={4}>
              <Form.Item {...layout} name={Checks.Zdfy} valuePropName='checked'>
                <Checkbox>自动发药</Checkbox>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...layout} name={Checks.Psgl} valuePropName='checked'>
                <Checkbox>皮试管理</Checkbox>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                {...layout}
                name={Checks.Gbxgjg}
                valuePropName='checked'
              >
                <Checkbox>关闭修改价格(医生)</Checkbox>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                {...layout}
                name={Checks.Gbkdysstx}
                valuePropName='checked'
              >
                <Checkbox>关闭医生开关提醒</Checkbox>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...layout} name={Checks.Zjjs} valuePropName='checked'>
                <Checkbox>诊间结算</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Form.Item
                {...layout}
                name={Checks.Lkcky}
                valuePropName='checked'
              >
                <Checkbox>零库存开药</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '50px', marginRight: '60px' }}>
              <Form.Item label='指定日结时间' name={Checks.Zdrjsj}>
                <TimePicker format='HH:mm' style={{ width: '200px' }} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label='结转日期设定' name={Checks.Jzrqsd}>
                <Input style={{ width: '200px' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '50px' }}>
              <Form.Item label='默认支付方式' name={Checks.Mrzffs}>
                <Radio.Group
                  onChange={(e) => {
                    if (e.target.value === `${PayMethod.Juhe}`) {
                      notification.warn({
                        message: '您尚未开通聚合支付，请联系客服。',
                      })
                      form.setFieldsValue({
                        [Checks.Mrzffs]: `${previewPayMethod}`,
                      })
                    } else {
                      setPreviewPayMethod(parseInt(e.target.value))
                    }
                  }}
                >
                  {PayMethods.map((p) => (
                    <Radio key={p} value={`${p}`}>
                      {getPayMethodName(p)}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '50px' }}>
              <Form.Item
                label='商城'
                name={Checks.LKSC}
                valuePropName='checked'
              >
                <Checkbox>启用</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '50px' }}>
              <Form.Item
                label='辅助诊断'
                name={Checks.ZNFZ}
                valuePropName='checked'
              >
                <Checkbox>启用</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '50px' }}>
              <Form.Item label='医保服务' valuePropName='checked'>
                {medicalList.length !== 0 &&
                  medicalList.map((v: MedicelListValue) => (
                    <Button
                      key={v.id}
                      onClick={() => {
                        setSettingVisible({
                          id: v.id,
                          tenantId: v.tenantId,
                          visible: true,
                        })
                      }}
                      type='link'
                      className={!v.tenantStatus ? styles.underline : ''}
                    >
                      {v.insuranceName}
                    </Button>
                  ))}
                <Button
                  onClick={() => {
                    setSettingVisible({
                      id: 0,
                      tenantId: location.search.replace('?', ''),
                      visible: true,
                    })
                  }}
                  type='link'
                >
                  添加医保险种
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '260px',
            }}
          >
            <div
              style={{
                height: '50px',
                marginRight: '30px',
              }}
            >
              <Button onClick={() => form.resetFields()}>取消</Button>
            </div>
            <div
              style={{
                height: '50px',
              }}
            >
              <Button htmlType='submit' type='primary'>
                确认
              </Button>
            </div>
          </Row>
        </Form>
      </div>
      <TenentSettingModal
        id={settingVisible.id}
        tenantId={location.search.replace('?', '')}
        onOk={async () => {
          await setSettingVisible({
            id: 0,
            tenantId: location.search.replace('?', ''),
            visible: false,
          })
          MedicalList()
        }}
        onCancel={() => {
          setSettingVisible({
            id: 0,
            tenantId: location.search.replace('?', ''),
            visible: false,
          })
        }}
        visible={settingVisible.visible}
      />
    </>
  )
}
