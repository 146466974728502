/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-08-06 16:27:55
 */
import { CloseCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import {
  Button,
  Form,
  Modal,
  Popconfirm,
  Space,
  Switch,
  TableColumnType,
} from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectTenantName,
} from '../../app/applicationSlice'
import { StateSwitch } from '../../compnents/widgets/StateSwitch'
import { BillingCategoryName } from '../../models/billingCategory'
import { getApproveFlgName, getChrgitmLvName } from '../../models/commodity'
import { Dimen } from '../../models/dimen'
import styles from './Examinetreat.module.css'

export const Columns = (
  onClick: (id: number, text: string, t?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const insuranceArray = useSelector(selectInsuranceArray)

  const tenantName = useSelector(selectTenantName)

  const columns: TableColumnType<any>[] = [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '费用代码',
      align: 'center',
      dataIndex: 'no',
      width: Dimen.No,
      key: 'no',
    },
    {
      title: '项目名称',
      align: 'center',
      dataIndex: 'name',
      width: '14rem',
      ellipsis: true,
      key: 'name',
    },

    {
      title: '匹配状态',
      align: 'center',
      dataIndex: 'approveFlg',
      width: '8rem',
      ellipsis: true,
      key: 'approveFlg',
      className: insuranceArray.length == 0 ? 'notShow' : '',
      render: function Element(_, t) {
        return (
          <Button type={Number(t.approveFlg) ? 'link' : 'text'}>
            {getApproveFlgName(Number(t.approveFlg))}
          </Button>
        )
      },
    },
    {
      title: '医保国码',
      align: 'center',
      dataIndex: 'hilistCode',
      width: '22rem',
      ellipsis: true,
      className: insuranceArray.length == 0 ? 'notShow' : '',
      key: 'hilistCode',
      render: function Element(_, t) {
        return (
          <div className={t.hilistCode ? styles.delMedicalCode : ''}>
            <div
              className={styles.hilistName}
              onClick={() => {
                onClick(t.id, 'insurance', t)
              }}
            >
              {t.hilistCode && <div>{t.hilistName}</div>}
              <div>
                <Button type='link'>{t.hilistCode || '匹配'}</Button>
                {getChrgitmLvName(t.chrgitmLv)}
              </div>
            </div>

            {t.hilistCode && (
              <span className={styles.Close}>
                <CloseCircleOutlined
                  onClick={() => {
                    onClick(t.id, 'delCode', t)
                  }}
                />
              </span>
            )}
          </div>
        )
      },
      // render: function Element(_, t) {
      //   return (
      //     <Button
      //       type='link'
      //       onClick={() => {
      //         onClick('insurance', t.id, t)
      //       }}
      //     >
      //       查看
      //     </Button>
      //   )
      // },
    },
    {
      title: '医保限价',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: function Element(_, t) {
        const xj = Number(t.priceLimit || 0).toFixed(4)
        return (
          <span style={{ color: t.isCouldBx !== '0' ? '#15ad31' : '#000000' }}>
            {Number(xj) > 0 && Number(xj) <= 99998
              ? xj
              : Number(xj) == -1
              ? '-'
              : '不限价'}
          </span>
        )
      },
    },
    {
      title: '开票项目',
      align: 'center',
      width: Dimen.BillingCategory,
      ellipsis: true,
      dataIndex: 'billingCategory',
      key: 'billingCategory',
      render: (billingCategory: any) => {
        return BillingCategoryName(billingCategory)
      },
    },
    {
      title: '零售单价',
      align: 'right',
      dataIndex: 'retailPrice',
      width: Dimen.Price,
      ellipsis: true,
      key: 'retailPrice',
      render: (_, m) => m.retailPrice?.toFixed(2),
    },
    {
      title: '单位',
      align: 'center',
      dataIndex: 'accountUnit',
      width: Dimen.Unit,
      ellipsis: true,
      key: 'accountUnit',
    },
    {
      title: '排序',
      align: 'center',
      dataIndex: 'sortNo',
      width: Dimen.Unit,
      ellipsis: true,
      key: 'sortNo',
      render: (_, m) => m.sortNo > 0 ? m.sortNo: "" ,
    },
    {
      title: '最近一次修改',
      align: 'center',
      dataIndex: 'lastUpdateTime',
      width: '10rem',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <a onClick={(e) => {
            e.preventDefault()
            if (t?.lastUpdateTime) {
              onClick(t?.id,'lastEditOperation', t)
            }
          }}
             style={{cursor: 'pointer'}}
          >
            {t?.lastUpdateTime}
          </a>
        )
      }
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: 'state',
      width: Dimen.State,
      ellipsis: true,
      key: 'state',
      render: function Element(_, t) {
        return (
          <Popconfirm
            title='确认进行此操作？'
            onConfirm={() => {
              onClick(t.id, 'loE', t)
            }}
            onCancel={() => {
              return
            }}
            okText='确认'
            cancelText='取消'
          >
            <a href='#'>
              <StateSwitch value={t.state === 1} />
            </a>
          </Popconfirm>
          // <StateSwitch
          //   value={t.state === 1}
          //   onChange={() => {
          //     onClick(t.id, "loE", t)
          //   }}
          // />
        )
      },
    },
    {
      title: '操作',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      fixed: 'right',
      width: '10rem',
      render: function Element(_, t) {
        return (
          <>
            <Button
              type='primary'
              ghost
              size='small'
              style={{ marginRight: '6px' }}
              onClick={(e: any) => {
                onClick(t.id, 'edit', t)
              }}
            >
              编辑
            </Button>
            <Button
              type='primary'
              ghost
              size='small'
              onClick={() => {
                Modal.confirm({
                  centered: true,
                  title: '确认操作',
                  content: (
                    <div>
                      <ExclamationCircleFilled
                        style={{ color: '#FFAE55', fontSize: 18 }}
                      />
                      &nbsp;您确定复制目录吗？
                    </div>
                  ),
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    onClick(t.id, 'copyCatalog', t)
                  },
                })
              }}
            >
              复制目录
            </Button>
          </>
        )
      },
    },
  ]

  const result =
    tenantName?.indexOf('余姚健齿口腔诊所有限公司') >= 0 ||
    tenantName?.indexOf('余姚康民内科诊所') >= 0
      ? columns.filter((v) => v.key != 'approveFlg')
      : columns

  return result
}
