import React, { useEffect, useState } from 'react';
import { EditableList } from '../../../../compnents/list/EditableList';
import { BirthdayColumns } from './birthdayColumns';
import { BirthdayQuery } from './birthdayQuery';
import styles from "../ToothHome.module.css";
import { SendMessageModal } from '../modal/sendMessageModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch } from '../../../../app/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { getMessageCount, getPatientPage, selectMessageCount, selectSendIDs, setPatientBirthday, setSendIDs } from '../toothHomeSlice';
import { useLocation } from 'react-router-dom';

interface BirthdayPageProps {
  onLeftCount?: (obj: any) => void
}
export const BirthdayPage = ({ onLeftCount }: BirthdayPageProps) => {
  const location = useLocation()

  const messageCount = useSelector(selectMessageCount)

  const dispatch = useDispatch<RootDispatch>()

  const [pageLoading, setPageLoading] = useState(false)

  const [params, setParams] = useState<any>({
    current: 0,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 1,
    items: [],
  })

  const [sendMessageModal, setSendMessageModal] = useState(false) // 发送短信弹窗

  const [sendMsgObj, setSendMsgObj] = useState<any>(null)

  const selectedKeys = useSelector(selectSendIDs)

  const getTableList = () => {  //获取表格数据
    setPageLoading(true)
    dispatch(getPatientPage(params))
      .then(unwrapResult)
      .then(({ total, records }) => {
        setPage({ total, items: records })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (!params.current) return
    getTableList()
    dispatch(setPatientBirthday([params.startTime, params.endTime]))
  }, [params])

  useEffect(() => {
    dispatch(getMessageCount())
    return () => {
      dispatch(setPatientBirthday([]))
      dispatch(setSendIDs([]))
    }
  }, [])


  return (
    <>
      <BirthdayQuery
        messageCount={messageCount}
        onSend={() => {
          setSendMsgObj(null)
          setSendMessageModal(true)
        }}
        onSubmit={(obj) => {
          setParams({
            ...params,
            ...obj,
            current: 1,
          })
          onLeftCount &&
            onLeftCount({
              ...obj,
              startTimeP: obj.startTime,
              endTimeP: obj.endTime,
              startTime: undefined,
              endTime: undefined,
            })
        }}
      />
      <EditableList
        bordered
        className={styles.table}
        loading={pageLoading}
        sticky={location.pathname == '/toothHome'}
        rowKey={(v, i) => {
          return `${v.id}`
        }}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={BirthdayColumns(
          params.size * (params.current - 1),
          (action, t) => {
            if (action == 'send') {
              dispatch(setSendIDs([]))
              setSendMsgObj(t)
              setSendMessageModal(true)
            }
          }
        )}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
        onRow={(record) => ({
          onClick: () => {
            let data = []
            if (!selectedKeys.includes(record.id.toString())) {
              data = [record.id.toString(), ...selectedKeys]
            } else {
              data = selectedKeys.filter((v) => v != record.id.toString())
            }
            dispatch(setSendIDs(data))
          },
        })}
        rowSelection={{
          selectedRowKeys: selectedKeys,
          onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
            let data: any = []
            if (selected) {
              data = [
                ...changeRows.map((v: any) => v.id.toString()),
                ...selectedKeys,
              ]
            } else {
              data = selectedKeys.filter(
                (item) =>
                  !changeRows.some((ele: any) => ele.id.toString() === item)
              )
            }
            dispatch(setSendIDs(data))
          },
          onSelect: (record, selected, selectedRows) => {
            let data = []
            if (selected) {
              data = [record.id.toString(), ...selectedKeys]
            } else {
              data = selectedKeys.filter((v) => v != record.id.toString())
            }
            dispatch(setSendIDs(data))
          },
        }}
      />

      <SendMessageModal
        sendMsgObj={sendMsgObj}
        visible={sendMessageModal}
        onCancel={() => {
          setSendMsgObj(null)
          setSendMessageModal(false)
        }}
        onOk={() => {
          setSendMsgObj(null)
          getTableList()
        }}
        isBirthday={true}
      />
    </>
  )
};
