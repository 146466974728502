/* eslint-disable react/prop-types */
import { Button, Col, DatePicker, Form, Row, Select } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import 'moment/locale/zh-cn'
import locale from 'antd/es/date-picker/locale/zh_CN'
import { useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../app/applicationSlice'
import {
  AccountType,
  AccountTypes,
  getAccountTypeName,
} from '../../models/setting'
import moment from 'moment'
import { DateTimeFormatSimpleOnly } from '../../models/datetime'
const { Item } = Form
const { Option } = Select

export interface RegisterQueryProps {
  isLocal: string
  onRegister: (insuranceCode: string) => void
  onValueChange: (queries: any) => void
  onSearch: (queries: any) => void
  loadingState?: boolean
  visible?: boolean
}

export interface RegisterDetailQueryProps {
  onValueChange: (queries: any) => void
  loadingState?: false
  visible?: boolean
}

export const RegisterQuery: React.FC<RegisterQueryProps> = ({
  isLocal,
  visible,
  onRegister,
  onValueChange,
  onSearch,
  loadingState = false,
}): ReactElement => {
  const [form] = Form.useForm()

  const [searchLoad, setSearchLoad] = useState(false)

  const insuranceArray = useSelector(selectInsuranceArray)

  const disabledStartDate: any = (current: any) => {
    const vs = form.getFieldsValue()
    if (vs.endDate) {
      return current && current > moment(vs.endDate).endOf('day')
    }
  }

  const disabledEndDate: any = (current: any) => {
    const vs = form.getFieldsValue()
    if (vs.startDate) {
      return current && current < moment(vs.startDate).startOf('day')
    }
  }

  const loadState = () => {
    setSearchLoad(true)
    setTimeout(() => {
      setSearchLoad(false)
    }, 3000)
  }

  useEffect(() => {
    if (insuranceArray.length && visible) {
      form.setFieldsValue({
        insuranceCode: insuranceArray[0].insuranceCode,
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
        DZJG: AccountType.ALL,
      })
    }
    return () => {
      if (visible) {
        clearTimeout()
        setSearchLoad(false)
      }
    }
  }, [insuranceArray, visible])

  useEffect(() => {
    if (loadingState) {
      setSearchLoad(false)
    }
  }, [loadingState])

  return (
    <Form
      form={form}
      onFinish={(vs) => {
        onValueChange({
          ...vs,
          endDate: vs.endDate
            ? moment(vs.endDate).format(DateTimeFormatSimpleOnly)
            : null,
          startDate: vs.startDate
            ? moment(vs.startDate).format(DateTimeFormatSimpleOnly)
            : null,
        })
      }}
    >
      <Row gutter={10} wrap={false} style={{ height: '42px' }}>
        <Col span={3}>
          <Item name='startDate'>
            <DatePicker
              locale={locale}
              placeholder='开始时间'
              // allowClear={false}
              disabledDate={disabledStartDate}
            />
          </Item>
        </Col>
        <Col>至</Col>
        <Col span={3}>
          <Item name='endDate'>
            <DatePicker
              locale={locale}
              placeholder='结束时间'
              // allowClear={false}
              disabledDate={disabledEndDate}
            />
          </Item>
        </Col>
        <Col span={3}>
          <Item name='insuranceCode'>
            <Select placeholder='险种类型'>
              {insuranceArray.map((v) => {
                return (
                  <Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Option>
                )
              })}
            </Select>
          </Item>
        </Col>
        <Col span={3}>
          <Item name='insuranceType'>
            <Select placeholder='险种类型' defaultValue={""} key={""}>
              <Option value={""} key={""} >
                全部
              </Option>
              <Option value={"1"} key={"1"}>
                职工
              </Option>
              <Option value={"2"} key={"2"}>
                居民
              </Option>
              <Option value={"3"} key={"3"}>
                离休
              </Option>

            </Select>
          </Item>
        </Col>
        <Col span={3}>
          <Item name='DZJG'>
            <Select placeholder='对账结果'>
              {AccountTypes.map((v) => (
                <Option value={v} key={v}>
                  {getAccountTypeName(v)}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col flex={1}>
          <Button type='primary' htmlType='submit'>
            查询
          </Button>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          {isLocal === '1' && (
            <Button
              type='primary'
              style={{ marginRight: '10px' }}
              loading={searchLoad}
              onClick={() => {
                loadState()
                const vs = form.getFieldsValue()
                onSearch({
                  ...vs,
                  endDate: moment(vs.endDate).format(DateTimeFormatSimpleOnly),
                  startDate: moment(vs.startDate).format(
                    DateTimeFormatSimpleOnly
                  ),
                })
              }}
            >
              查询异地未对账
            </Button>
          )}
          <Button
            type='primary'
            onClick={() => {
              const vs = form.getFieldsValue()
              onRegister(vs.insuranceCode)
            }}
          >
            发起日对帐
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export const RegisterDetailQuery: React.FC<RegisterDetailQueryProps> = ({
  visible,
  onValueChange,
  loadingState = false,
}): ReactElement => {
  const [form] = Form.useForm()

  const insuranceArray = useSelector(selectInsuranceArray)

  const [searchLoad, setSearchLoad] = useState(false)

  const loadState = () => {
    setSearchLoad(true)
    setTimeout(() => {
      setSearchLoad(false)
    }, 1500)
  }

  const disabledStartDate: any = (current: any) => {
    const vs = form.getFieldsValue()
    if (vs.endDate) {
      return current && current > moment(vs.endDate).endOf('day')
    }
  }

  const disabledEndDate: any = (current: any) => {
    const vs = form.getFieldsValue()
    if (vs.startDate) {
      return current && current < moment(vs.startDate).startOf('day')
    }
  }

  useEffect(() => {
    if (insuranceArray.length && visible) {
      form.setFieldsValue({
        insuranceCode: insuranceArray[0].insuranceCode,
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
        DZJG: AccountType.ALL,
      })
    }
  }, [insuranceArray, visible])

  useEffect(() => {
    if (loadingState) {
      setSearchLoad(false)
    }
  }, [loadingState])

  return (
    <Form
      form={form}
      onFinish={(vs) => {
        onValueChange({
          ...vs,
          endDate: vs.endDate
            ? moment(vs.endDate).format(DateTimeFormatSimpleOnly)
            : null,
          startDate: vs.startDate
            ? moment(vs.startDate).format(DateTimeFormatSimpleOnly)
            : null,
        })
      }}
    >
      <Row gutter={10} wrap={false} style={{ height: '42px' }}>
        <Col span={3}>
          <Item name='startDate'>
            <DatePicker
              locale={locale}
              placeholder='开始时间'
              // allowClear={false}
              disabledDate={disabledStartDate}
            />
          </Item>
        </Col>
        <Col>至</Col>
        <Col span={3}>
          <Item name='endDate'>
            <DatePicker
              locale={locale}
              placeholder='结束时间'
              // allowClear={false}
              disabledDate={disabledEndDate}
            />
          </Item>
        </Col>
        <Col span={3}>
          <Item name='insuranceCode'>
            <Select placeholder='险种类型'>
              {insuranceArray.map((v) => {
                return (
                  <Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Option>
                )
              })}
            </Select>
          </Item>
        </Col>
        {/* <Col span={3}>
          <Item name='DZJG'>
            <Select placeholder='对账结果'>
              {AccountTypes.map((v) => (
                <Option value={v} key={v}>
                  {getAccountTypeName(v)}
                </Option>
              ))}
            </Select>
          </Item>
        </Col> */}
        <Col flex={1} style={{ textAlign: 'right' }}>
          <Button
            type='primary'
            htmlType='submit'
            loading={searchLoad}
            onClick={loadState}
          >
            查询
          </Button>
        </Col>
        {/* <Col span={3} style={{ textAlign: 'right' }}>
          <Button
            type='primary'
            onClick={() => {
              const vs = form.getFieldsValue()
              onRegister(vs.insuranceCode)
            }}
          >
            发起日对帐
          </Button>
        </Col> */}
      </Row>
    </Form>
  )
}
