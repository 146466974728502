/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-28 14:24:05
 */
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
    pageSupplier,
    selectSupplierdata,
    selectTotal,
    setCurrent,
    selectCurrent,
    setSelectId,
    setSupplierPage,
    selectSize,
    selectPageLoading,
    setPageLoading, submitSupplierAsync, deleteSupplierAsync,
} from "./supplierSlice";
import { Columns } from "./columns";
import { SupplierModal } from "./modal";
import { SupplierQuery } from "./query";
import { supplierParams } from "../../services/supplier";
import { Button, Col } from "antd";
import { NavBar } from "../../compnents/nav/NavBar";
import { EditableList } from "../../compnents/list/EditableList";
import styles from "./supplier.module.css";
import { useLocation } from "react-router";
import {DateTimeFormat} from "../../models/datetime";
import {unwrapResult} from "@reduxjs/toolkit";
import {RootDispatch} from "../../app/store";
import { PurchaseModal } from "../putstorelist/purchaseModal";

export const Supplier = () => {

  const dispatch = useDispatch();

  const dispatch_ = useDispatch<RootDispatch>()

  const total = useSelector(selectTotal);

  const Current = useSelector(selectCurrent);

  const size = useSelector(selectSize);

  const [queries, setQueries] = useState<supplierParams>();

  const [ismodalVisible, setIsmodalVisible] = useState(false);

  const [issSuppierModalVisible, setIsSuppierModalVisible] = useState(false) // 控制导入弹窗是否显示

  const pageLoadingRX = useSelector(selectPageLoading);

  useEffect(() => {
    getPage()
  }, [queries, Current, size]);

  const getPage=()=>{
    dispatch(setPageLoading(true))
    dispatch(pageSupplier({ ...queries, current: Current, size }))
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }

  const data = useSelector(selectSupplierdata);
  return (
    <>
      <Col
        style={{
          margin: "10px 20px",
        }}
      >
        <NavBar
          where={["供应商管理", "供应商设置"]}
          backtrace={{
            name: "库房工作站",
            path: "/storage/station",
            state: {
              station: "8",
            },
          }}
          buttons={
            <div>
             <Button
                type="primary"
                style={{marginRight:'10px'}}
                onClick={() => {
                  setIsSuppierModalVisible(true);
                }}
              >
                供应商导入
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setIsmodalVisible(true);
                }}
              >
                <PlusOutlined />
                新增
              </Button>
            </div>
          }
        />
      </Col>
      <div className={styles.content}>
        <SupplierQuery
          onValueChange={(v) => {
            setQueries(v);
          }}
        />
        <EditableList
          className={styles.suppliertable}
          loading={pageLoadingRX}
          page={{
            items: data,
            current: Current,
            size,
            total,
          }}
          onChangePage={(current, pageSize) => {
            dispatch(setSupplierPage(pageSize));
            dispatch(setCurrent(current));
          }}
          columns={Columns((id,type) => {
            if(type == "edit"){
              dispatch(setSelectId(id));
              setIsmodalVisible(true);
            }else if(type == "delete"){
              dispatch_(
                deleteSupplierAsync({
                  id: id,
                })
              ).then(unwrapResult)
               .then(() => {
                  dispatch(pageSupplier({ ...queries, current: Current, size }))
               })
            }
          }, size * (Current - 1))}
          onRow={(d: any) => ({
            onDoubleClick: () => {
              dispatch(setSelectId(d.id));
              setIsmodalVisible(true);
            },
          })}
        />
      </div>
      <SupplierModal
        visible={ismodalVisible}
        onOk={() => {
          dispatch(
            pageSupplier({ ...queries, current: Current, size })
          );
          setIsmodalVisible(false);
          dispatch(setSelectId(0));
        }}
        onCancel={() => {
          setIsmodalVisible(false);
          dispatch(setSelectId(0));
        }}
      />
       <PurchaseModal
        state={4}
        isPurchaseModalVisible={issSuppierModalVisible}
        onOk={() => {
          setIsSuppierModalVisible(false)
          getPage()
        }}
        onCancel={() => {
          setIsSuppierModalVisible(false)
          getPage()
        }}
        refresh={()=>{
          getPage()
        }}
      />
    </>
  );
};
