/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: linxi
 * @LastEditTime: 2021-04-22 20:11:11
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store";
import { StockfloorBody, stockfloorEditpage, stockfloorlogoutlistupdate, StockfloorParams } from "../../services/stockfloor";

const initialState = {
    StockfloorModalData: [],
    Total: 0,
    Current: 1
}

export const stockfloorModalSlice = createSlice({
    name: "stockfloorModal",
    initialState,
    reducers: {
        setData(state, action) {
            state.StockfloorModalData = action.payload
        },
        setTotal(state, action) {
            state.Total = action.payload
        },
        setCurrent(state, action) {
            state.Current = action.payload
        }
    }
})

export function pageStockfloorEdit(params: StockfloorParams): RootThunk {
    return api(stockfloorEditpage(params), (data: any, dispatch) => {
        dispatch(setData(data.records))
        dispatch(setTotal(data.total))
    })
}

export const setStockfloorlogoutlistAsync = createAsyncThunk<
    void, StockfloorBody, RootThunkApi<void>
>("stockfloor/list", async (body, api) => {
    return sendAsync(stockfloorlogoutlistupdate(body), api)
})

export const { setData, setTotal, setCurrent } = stockfloorModalSlice.actions

export const selectStockfloorModalData = (state: RootState) => state.stockfloorModal.StockfloorModalData

export const selectTotal = (state: RootState) => state.stockfloorModal.Total

export const selectCurrent = (state: RootState) => state.stockfloorModal.Current

export default stockfloorModalSlice.reducer