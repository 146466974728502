/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-07 09:51:27
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-18 14:19:21
 */
import {
  Col,
  Popover,
  Space,
} from 'antd'
import { TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import { EditableColumnType } from '../../compnents/list/EditableList'
import { CategoryName, getInorOutCome } from '../../models/category'
import { DateTimeFormatSimple } from '../../models/datetime'
import { Dimen } from '../../models/dimen'

export const InventoryChangeColumns = (
  startIndex: number,
  onClick: (t:any) => void,
): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      dataIndex: 'id',
      render: (_1, _2, index) => {
        if (_2.id === startIndex) {
          return '合计'
        } else {
          return `${startIndex + index + 1}`
        }
      },
    },
    // 产品信息（物资名称、规格、有效期至、剂型、生产厂家）
    {
      title: '产品信息',
      align: 'left',
      width: '300px',
      ellipsis: true,
      dataIndex: 'productInfo',
      render: function Element(_, t) {
        if (t.id === startIndex) {
          return ''
        } else {
          return t.name == '合计' ? (
            ''
          ) : (
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Popover
                  content={
                    <>
                      <div style={{ height: '55px' }}>
                        <p
                          style={{
                            height: '28px',
                            lineHeight: '36px',
                            padding: '0',
                            margin: '0',
                            fontSize: '16px',
                            fontWeight: 'bolder',
                          }}
                        >
                          {t.name}
                        </p>
                        <p
                          style={{
                            height: '28px',
                            lineHeight: '24px',
                            padding: '0',
                            margin: '0',
                            color: '#999999',
                            fontSize: '14px',
                          }}
                        >
                          {t.tenantSupplierName}/
                          {t.packSpec ? t.packSpec : '暂无'}/{t.doseCategory}/
                          {t.deadline
                            ? moment(t.deadline).format(DateTimeFormatSimple)
                            : '-'}
                        </p>
                      </div>
                    </>
                  }
                  placement='bottom'
                >
                  <div
                    style={{
                      height: '55px',
                      width: '300px',
                      overflow: 'hidden',
                    }}
                  >
                    <p
                      style={{
                        height: '28px',
                        lineHeight: '36px',
                        padding: '0',
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bolder',
                      }}
                    >
                      {t.name}
                    </p>
                    <p
                      style={{
                        height: '28px',
                        lineHeight: '24px',
                        padding: '0',
                        margin: '0',
                        color: '#999999',
                        fontSize: '14px',
                      }}
                    >
                      {t.tenantSupplierName}/{t.packSpec ? t.packSpec : '暂无'}/
                      {t.doseCategory}/
                      {t.deadline
                        ? moment(t.deadline).format(DateTimeFormatSimple)
                        : '-'}
                    </p>
                  </div>
                </Popover>
              </Col>
            </Space>
          )
        }
      },
    },
    {
      title: '库房名称',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'storehouseName',
      key: 'storehouseName',
      sorter: (a: any, b: any) =>
        a.storehouseName?.length - b.storehouseName?.length,
    },
    {
      title: '业务名称',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: function Element(_, t) {
        return t?.reverseCategory == 1
          ? CategoryName(t.category) + '冲正'
          : t.category == '10' || t.category == '13' || t.category == '14' || t.category == '15' || t.category == '16' ?
           CategoryName(t.category) :
           (<span style={{color: '#027AFF', cursor: 'pointer'}} onClick={() => {
            onClick(t)
          }}>{CategoryName(t.category)}</span>)
      },
    },
    {
      title: '批次号',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'batchNo',
    },
    {
      title: '对方部门/个人',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      dataIndex: 'supplierName',
      render: (_, t) => {
        return t.category === '11' || t.category === '12'
          ? t.patientName
          : t.category === '13' || t.category === '14'
          ? '-'
          : t.supplierName
      },
    },
    {
      title: '业务部门',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      dataIndex: 'departmentName',
    },
    {
      title: '业务人员',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      dataIndex: 'userName',
    },
    {
      title: '以整存计',
      align: 'center',
      children: [
        {
          title: '售价',
          align: 'right',
          dataIndex: 'retailPrice',
          width: '7rem',
          ellipsis: true,
          render: (_: any, t: any) => {
            return t.retailPrice > 0
              ? parseFloat(t.retailPrice).toFixed(5)
              : '-'
          },
        },
        {
          title: '进价',
          align: 'right',
          width: '7rem',
          ellipsis: true,
          dataIndex: 'unitPrice',
          render: (_: any, t: any) => {
            return t.unitPrice > 0 ? parseFloat(t.unitPrice).toFixed(5) : '-'
          },
        },
        {
          title: '数量',
          align: 'center',
          width: '7rem',
          ellipsis: true,
          dataIndex: 'count',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalCount)
            } else {
              return `${t.count}  ${t.accountUnit}`
            }
          },
        },
        {
          title: '售价金额',
          align: 'right',
          dataIndex: '',
          width: '7rem',
          ellipsis: true,
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalRetailPrice).toFixed(2)
            } else {
              return parseFloat(t.retailPriceTotal).toFixed(2)
            }
          },
        },
        {
          title: '进价金额',
          align: 'right',
          width: '7rem',
          ellipsis: true,
          dataIndex: '',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalUnitPrice).toFixed(2)
            } else {
              return parseFloat(t.unitPriceTotal).toFixed(2)
            }
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '以拆零计',
      align: 'center',
      children: [
        {
          title: '售价',
          align: 'right',
          width: '7rem',
          ellipsis: true,
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return '-'
            } else {
              return t.splitPrice > -1
                ? parseFloat((t.splitPrice as unknown) as string).toFixed(5)
                : '-'
            }
          },
        },
        //计算
        {
          title: '进价',
          align: 'right',
          width: '7rem',
          ellipsis: true,
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return '-'
            } else {
              return t.unitPrice / t.splitScale > -1
                ? parseFloat(
                    ((t.unitPrice / t.splitScale) as unknown) as string
                  ).toFixed(5)
                : '-'
            }
          },
        },
        {
          title: '数量',
          align: 'center',
          width: '7rem',
          ellipsis: true,
          dataIndex: 'splitCount',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalSplitCount)
            } else {
              return `${t.splitCount} ${t.splitUnit}`
            }
          },
        },
        {
          title: '售价金额',
          align: 'right',
          width: '6rem',
          ellipsis: true,
          dataIndex: '',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalSplitRetailPrice).toFixed(2)
            } else {
              return parseFloat(t.splitPriceTotal).toFixed(2)
            }
          },
        },
        {
          title: '进价金额',
          align: 'right',
          width: '6rem',
          ellipsis: true,
          dataIndex: '',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalSplitUnitPrice).toFixed(2)
            } else {
              return parseFloat(t.splitUnitPriceTotal).toFixed(2)
            }
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '结存数量',
      align: 'center',
      children: [
        {
          title: '整存',
          align: 'center',
          width: '6rem',
          ellipsis: true,
          dataIndex: 'retailCount',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return t.totalCloseCountAll || '-'
            } else {
              return t.tenantInventoryLedgerItemId
                ? t.closeCount
                : t.unitPriceTotal
            }
          },
        },
        {
          title: '拆零',
          align: 'center',
          width: '6rem',
          ellipsis: true,
          dataIndex: 'retailSplitCount',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return t.splitCloseCountAll || '-'
            } else {
              return t.tenantInventoryLedgerItemId
                ? t.closeSplitCount
                : t.unitPriceTotal
            }
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '变动日期',
      align: 'center',
      width: '7rem',
      ellipsis: true,
      dataIndex: 'createTime',
      render: (_, t) => {
        return t.createTime
          ? moment(t.createTime).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '利润',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      dataIndex: 'totalProfitPrice',
      render: (_, t) => {
        if (t.id === startIndex) {
          return parseFloat(t.totalProfitPrice).toFixed(2)
        } else {
          return parseFloat(t.profitPrice).toFixed(2)
        }
      },
    },
    {
      title: '供应商',
      align: 'center',
      dataIndex: 'vendorName',
      width: '12rem',
      ellipsis: true,
      key: 'vendorName',
      render: (_: any, t: any) => {
        return t.vendorName ? t?.vendorName : '-'
      },
    },
  ]
}

export const InventoryChangeSimpleColumns = (
  startIndex: number
): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      dataIndex: 'id',
      render: (_1, _2, index) => {
        if (_2.id === startIndex) {
          return '合计'
        } else {
          return `${startIndex + index + 1}`
        }
      },
    },
    // 产品信息（物资名称、规格、有效期至、剂型、生产厂家）
    {
      title: '产品信息',
      align: 'left',
      width: '300px',
      ellipsis: true,
      dataIndex: 'productInfo',
      render: function Element(_, t) {
        if (t.id === startIndex) {
          return ''
        } else {
          return (
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Popover
                  content={
                    <>
                      <div style={{ height: '55px' }}>
                        <p
                          style={{
                            height: '28px',
                            lineHeight: '36px',
                            padding: '0',
                            margin: '0',
                            fontSize: '16px',
                            fontWeight: 'bolder',
                          }}
                        >
                          {t.name}
                        </p>
                        <p
                          style={{
                            height: '28px',
                            lineHeight: '24px',
                            padding: '0',
                            margin: '0',
                            color: '#999999',
                            fontSize: '14px',
                          }}
                        >
                          {t.tenantSupplierName}/
                          {t.packSpec ? t.packSpec : '暂无'}/{t.doseCategory}/
                          {t.deadline
                            ? moment(t.deadline).format(DateTimeFormatSimple)
                            : '-'}
                        </p>
                      </div>
                    </>
                  }
                  placement='bottom'
                >
                  <div
                    style={{
                      height: '55px',
                      width: '300px',
                      overflow: ' hidden',
                    }}
                  >
                    <p
                      style={{
                        height: '28px',
                        lineHeight: '36px',
                        padding: '0',
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bolder',
                      }}
                    >
                      {t.name}
                    </p>
                    <p
                      style={{
                        height: '28px',
                        lineHeight: '24px',
                        padding: '0',
                        margin: '0',
                        color: '#999999',
                        fontSize: '14px',
                      }}
                    >
                      {t.tenantSupplierName}/{t.packSpec ? t.packSpec : '暂无'}/
                      {t.doseCategory}/
                      {t.deadline
                        ? moment(t.deadline).format(DateTimeFormatSimple)
                        : '-'}
                    </p>
                  </div>
                </Popover>
              </Col>
            </Space>
          )
        }
      },
    },
    {
      title: '库房名称',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'storehouseName',
      key: 'storehouseName',
      sorter: (a: any, b: any) =>
        a.storehouseName.length - b.storehouseName.length,
    },
    {
      title: '以整存计',
      align: 'center',
      children: [
        {
          title: '数量',
          align: 'center',
          width: '8rem',
          ellipsis: true,
          dataIndex: 'count',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalCount)
            } else {
              return `${t.count}  ${t.accountUnit}`
            }
          },
        },
        {
          title: '售价金额',
          align: 'right',
          dataIndex: '',
          width: '8rem',
          ellipsis: true,
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalRetailPrice).toFixed(2)
            } else {
              return parseFloat(t.retailPriceTotal).toFixed(2)
            }
          },
        },
        {
          title: '进价金额',
          align: 'right',
          width: '8rem',
          ellipsis: true,
          dataIndex: '',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalUnitPrice).toFixed(2)
            } else {
              return parseFloat(t.unitPriceTotal).toFixed(2)
            }
          },
        },
      ],
    } as TableColumnType<any>,
    {
      title: '以拆零计',
      align: 'center',
      children: [
        {
          title: '数量',
          align: 'center',
          width: '8rem',
          ellipsis: true,
          dataIndex: 'splitCount',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalSplitCount)
            } else {
              return `${t.splitCount} ${t.splitUnit}`
            }
          },
        },
        {
          title: '售价金额',
          align: 'right',
          width: '8rem',
          ellipsis: true,
          dataIndex: '',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalSplitRetailPrice).toFixed(2)
            } else {
              return parseFloat(t.splitPriceTotal).toFixed(2)
            }
          },
        },
        {
          title: '进价金额',
          align: 'right',
          width: '8rem',
          ellipsis: true,
          dataIndex: '',
          render: (_: any, t: any) => {
            if (t.id === startIndex) {
              return parseFloat(t.totalSplitUnitPrice).toFixed(2)
            } else {
              return parseFloat(t.splitUnitPriceTotal).toFixed(2)
            }
          },
        },
      ],
    } as TableColumnType<any>,
  ]
}

export const InventoryMergeColumns = (
  startIndex: number
): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '业务名称',
      align: 'center',
      width: Dimen.Category,
      render: (_, t) => {
        if (t.category <= 10) {
          if (t.reverseCategory > 0) {
            return CategoryName(t.category) + '(冲正)'
          } else {
            return CategoryName(t.category)
          }
        } else {
          return CategoryName(t.category)
        }
      },
    },
    {
      title: '商品名称',
      align: 'center',
      dataIndex: 'name',
      width: Dimen.Name,
    },
    {
      title: '规格',
      align: 'center',
      width: Dimen.PackSpec,
      dataIndex: 'packSpec',
    },
    {
      title: '批号',
      align: 'center',
      width: Dimen.BatchNo,
      dataIndex: 'batchNo',
    },
    {
      title: '对方名称',
      align: 'center',
      width: Dimen.Department,
      dataIndex: 'departmentName',
      render: (_, t) => {
        switch (t.category) {
          case '1':
          case '2':
          case '5':
          case '6':
            return t.supplierName
          case '11':
          case '12':
            return t.patientName
          default:
            return '-'
        }
      },
    },
    {
      title: '发生时间',
      align: 'center',
      width: Dimen.Time,
      dataIndex: 'createTime',
      render: (_, t) => {
        return moment(t.createTime).format(DateTimeFormatSimple)
      },
    },
    {
      title: '库存单价',
      align: 'right',
      width: Dimen.Price,
      dataIndex: 'unitPrice',
      render: (_, t) => {
        return parseFloat(t.unitPrice).toFixed(5)
      },
    },
    {
      title: '库存单位',
      width: Dimen.Num,
      align: 'center',
      dataIndex: 'accountUnit',
    },
    {
      title: '结前数量',
      align: 'center',
      dataIndex: 'beforeCount',
    },
    {
      title: '收入数量',
      align: 'center',
      dataIndex: '',
      render: (_, t) => {
        if (t.count >= 0) {
          return t.count
        } else {
          return 0
        }
      },
    },
    {
      title: '支出数量',
      align: 'center',
      dataIndex: '',
      render: (_, t) => {
        if (t.count >= 0) {
          return 0
        } else {
          return t.count * -1
        }
      },
    },
    {
      title: '结存数量',
      align: 'center',
      dataIndex: 'closeCount',
      render: (_, t) => {
        return t.closeCount > 0 ? t.closeCount : 0
      },
    },
    {
      title: '收入金额',
      align: 'center',
      dataIndex: '',
      render: (_, t) => {
        switch (t.category) {
          case '1':
          case '4':
          case '6':
          case '8':
          case '12':
            return parseFloat(String(t.count * t.unitPrice)).toFixed(2)
          default:
            return 0
        }
      },
    },
    {
      title: '支出金额',
      align: 'center',
      dataIndex: '',
      render: (_, t) => {
        switch (t.category) {
          case '2':
          case '3':
          case '5':
          case '7':
          case '9':
          case '11':
          case '13':
            return parseFloat(String(t.count * t.unitPrice)).toFixed(2)
          default:
            return 0
        }
      },
    },
    {
      title: '拆零单价',
      align: 'right',
      dataIndex: 'splitPrice',
      render: (_, t) => {
        return t.splitPrice > 0 ? t.splitPrice?.toFixed(5) : 0
      },
    },
    {
      title: '拆零单位',
      align: 'center',
      dataIndex: 'splitUnit',
    },
    {
      title: '拆零结前数量',
      align: 'center',
      dataIndex: 'beforeSplitCount',
    },
    {
      title: '拆零收入数量',
      align: 'center',
      dataIndex: '',
      render: (_, t) => {
        if (t.splitCount >= 0) {
          return t.splitCount
        } else {
          return 0
        }
      },
    },
    {
      title: '拆零支出数量',
      align: 'center',
      dataIndex: '',
      render: (_, t) => {
        if (t.splitCount >= 0) {
          return 0
        } else {
          return t.splitCount * -1
        }
      },
    },
    {
      title: '拆零结存数量',
      align: 'center',
      dataIndex: 'closeSplitCount',
      render: (_, t) => {
        return t.closeSplitCount > 0 ? t.closeSplitCount : 0
      },
    },
    {
      title: '拆零收入数量',
      align: 'center',
      dataIndex: '',
      render: (_, t) => {
        switch (t.category) {
          case '1':
          case '4':
          case '6':
          case '8':
          case '12':
            return t.splitPrice > 0 ? t.splitCount * t.unitPrice : 0
          default:
            return 0
        }
      },
    },
    {
      title: '拆零支出金额',
      align: 'right',
      dataIndex: '',
      render: (_, t) => {
        switch (t.category) {
          case '2':
          case '3':
          case '5':
          case '7':
          case '9':
          case '11':
          case '13':
            return t.splitPrice > 0
              ? parseFloat(String(t.splitCount * t.unitPrice)).toFixed(2)
              : '0.00'
          default:
            return '0.00'
        }
      },
    },
  ]
}
