import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EnvironmentOutlined, QuestionCircleTwoTone,TransactionOutlined
} from '@ant-design/icons'
import styles from '../ToothHome.module.css'
import { Button, Popover, Space, TableColumnType, Tooltip } from 'antd'
import React, { ReactElement } from 'react'
import {
  TransferTreatmentIcon,
  ClinicalReceptionIcon,
  PaymentToothIcon,
  FollowUpToothIcon,
  ApointmentToothIcon,
  AccomplishIcon,
  TreatmentOutLineIcon,
  ActiveTransferTreatmentIcon,
  ActiveAppointmentIcon,
  FollowUpActiveIcon,
  MembersIcon,
  InActiveClinicalReceptionIcon,
  DisposalOutLineIcon,
  OverIcon,
  ActiveFFAEAppointmentIcon,
  NoRetweetIcon,
  YBZZFIcons,
  ZFZYBIcons,
} from '../../../../compnents/icons/Icons'
import {
  getSexData,
  getVisitingStatusColor,
  getVisitingStatusName,
} from '../../../../models/ToothHome'
import moment from 'moment'
import { getAge } from '../../../../utils/StringUtils'
import { selectInsuranceArray } from '../../../../app/applicationSlice'
import { useSelector } from 'react-redux'

export const WorkStationColumns = (
  startIndex: number,
  onAction: (action: string, t: any) => void
): TableColumnType<any>[] => {
  const insuranceArray = useSelector(selectInsuranceArray)

  const columns: TableColumnType<any>[] = [
    //工作站表格
    {
      title: '序号',
      key: 'no',
       fixed: 'left',
      align: 'center',
      width: '3.5rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '到达',
      dataIndex: 'arriveFlag',
      align: 'center',
      fixed: 'left',
      width: '40px',
      render: function Element(_, t) {
        return (
          <div
            className={`${
              t.arriveFlag == 1 ? styles.activeTableIcon : styles.tableIcon
            }  `}
            onClick={() => (t.arriveFlag == 1 ? '' : onAction('arrive', t))}
          >
            {t.arriveFlag == 1 ? (
              <AccomplishIcon mode='small' style={{ width: 22, height: 22 }} />
            ) : (
              <EnvironmentOutlined
                style={{
                  width: 22,
                  height: 22,
                }}
              />
            )}
          </div>
        )
      },
    },
    {
      title: '接诊',
      dataIndex: 'treatmentFlag',
      align: 'center',
      fixed: 'left',
      width: '40px',
      render: function Element(_, t) {
        return (
          <div
            className={`${
              t.arriveFlag == 0 ? styles.activeTableIcon : styles.tableIcon
            }  `}
            style={{ display: 'grid' }}
            onClick={() =>
              t.arriveFlag == 0 ? '' : onAction('clinicalReception', t)
            }
          >
            {t.arriveFlag == 0 ? (
              <InActiveClinicalReceptionIcon
                mode='small'
                style={{ width: 22, height: 22 }}
              />
            ) : (
              <ClinicalReceptionIcon
                mode='small'
                style={{ width: 22, height: 22 }}
              />
            )}
          </div>
        )
      },
    },
    {
      title: '转诊',
      dataIndex: 'changeTreatmentFlag',
      align: 'center',
      fixed: 'left',
      width: '40px',
      render: function Element(_, t) {
        return (
          <div
            style={{
              cursor: t.changeTreatmentFlag == 1 ? 'pointer' : 'not-allowed',
            }}
            className={styles.flex}
            onClick={() =>
              t.changeTreatmentFlag == 1 ? onAction('referral', t) : ''
            }
          >
            {t.changeTreatmentFlag == 1 ? (
              <TransferTreatmentIcon
                mode='small'
                style={{ width: 22, height: 22 }}
              />
            ) : (
              <ActiveTransferTreatmentIcon
                mode='small'
                style={{ width: 22, height: 22 }}
              />
            )}
          </div>
        )
      },
    },
    {
      title: '病历',
      dataIndex: 'treatmentFlag',
      align: 'center',
      fixed: 'left',
      width: '40px',
      render: function Element(_, t) {
        return (
          <div
            style={{
              cursor: t.changeTreatmentFlag == 1 ? 'pointer' : 'not-allowed',
            }}
            className={styles.flex}
            onClick={() => onAction('treatment', t)}
          >
            {t?.medicalRecordStatus == 0 ? (
              <Popover
                placement='top'
                content={<span>主述和现病史未填写完整</span>}
              >
                <div style={{ marginTop: '10px' }}>
                  <ActiveFFAEAppointmentIcon
                    mode='small'
                    style={{ width: 22, height: 22 }}
                  />
                </div>
              </Popover>
            ) : t?.medicalRecordStatus == 1 ? (
              <div style={{ marginTop: '10px' }}>
                <ActiveAppointmentIcon
                  mode='small'
                  style={{ width: 22, height: 22 }}
                />
              </div>
            ) : (
              <TreatmentOutLineIcon
                mode='small'
                style={{ width: 22, height: 22 }}
              />
            )}
          </div>
        )
      },
    },
    {
      title: '处置',
      dataIndex: 'disposalFlag',
      align: 'center',
      fixed: 'left',
      width: '40px',
      render: function Element(_, t) {
        return (
          <div
            style={{
              cursor: t.changeTreatmentFlag == 1 ? 'pointer' : 'not-allowed',
            }}
            className={styles.flex}
            onClick={() => {
              //编辑中或者未开具的时候，按钮为打开状态，并且点击按钮跳转处置页面
              onAction('clinicalReception', t)
            }}
          >
            {t.treatmentRecipeState &&
            (t.treatmentRecipeState == -1 || t.treatmentRecipeState == 1) ? (
              //编辑中或者未开具的时候，按钮为打开状态，并且点击按钮跳转处置页面
              <DisposalOutLineIcon
                mode='small'
                style={{ width: 22, height: 22 }}
              />
            ) : (
              <div style={{ marginTop: '10px' }}>
                <ActiveAppointmentIcon
                  mode='small'
                  style={{ width: 22, height: 22 }}
                />
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: '收费',
      dataIndex: 'feeFlag',
      align: 'center',
      fixed: 'left',
      width: '40px',
      render: function Element(_, t) {
        return (
          <div
            className={styles.flex}
            onClick={() =>
              t.registrationState == 4 ? '' : onAction('charge', t)
            }
          >
            {t.registrationState == 4 ? (
              <div style={{ marginTop: '10px' }}>
                <ActiveAppointmentIcon
                  mode='small'
                  style={{ width: 22, height: 22 }}
                />
              </div>
            ) : (
              <PaymentToothIcon
                mode='small'
                style={{ width: 22, height: 22 }}
              />
            )}
          </div>
        )
      },
    },
    {
      title: '预约',
      dataIndex: 'appointmentFlag',
      align: 'center',
      fixed: 'left',
      width: '40px',
      render: function Element(_, t) {
        return (
          <div className={styles.flex} onClick={() => onAction('subscribe', t)}>
            {/* t.appointmentFlag == 0 || */}
            {t?.hasNextAppointment == false ? (
              <ApointmentToothIcon
                mode='small'
                style={{ width: 22, height: 22 }}
              />
            ) : t?.hasNextAppointment == true ? (
              <Popover
                trigger='hover'
                placement='topLeft'
                content={
                  <>
                    <p>下次预约时间：{t?.nextAppointmentTime || '-'}</p>
                    <p>预约医生：{t?.nextAppointmentDoctorName || '-'}</p>
                    <p>预约项目：{t?.nextAppointmentItem || '-'}</p>
                  </>
                }
              >
                <div style={{ marginTop: '10px' }}>
                  <ActiveAppointmentIcon
                    mode='small'
                    style={{ width: 22, height: 22 }}
                  />
                </div>
              </Popover>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      title: '随访',
      dataIndex: 'visitFlag',
      align: 'center',
      fixed: 'left',
      width: '40px',
      render: function Element(_, t) {
        return (
          <div className={styles.flex} onClick={() => onAction('followUp', t)}>
            {t.visitFlag == 0 ? (
              <FollowUpToothIcon
                mode='small'
                style={{ width: 22, height: 22 }}
              />
            ) : (
              <FollowUpActiveIcon
                mode='small'
                style={{ width: 22, height: 22 }}
              />
            )}
          </div>
        )
      },
    },
    {
      title: '完成',
      key: 'finishFlag',
      dataIndex: 'finishFlag',
      align: 'center',
      fixed: 'left',
      width: '40px',
      render: function Element(_, t) {
        return (
          <div
            className={
              t.finishFlag == 0 ? styles.activeTableIcon : styles.tableIcon
            }
            style={{ fontSize: '19px' }}
            onClick={() => (t.finishFlag == 1 ? onAction('accomplish', t) : '')}
          >
            {t.finishFlag == 0 ? (
              <CheckCircleOutlined style={{ width: 22, height: 22 }} />
            ) : t.finishFlag == 2 ? (
              <div style={{ marginTop: '10px' }}>
                <ActiveAppointmentIcon
                  mode='small'
                  style={{ width: 22, height: 22 }}
                />
              </div>
            ) : (
              <CheckCircleOutlined style={{ width: 22, height: 22 }} />
            )}
          </div>
        )
      },
    },
    {
      title: '取消',
      dataIndex: 'cancleFlag',
      align: 'center',
      fixed: 'left',
      width: '40px',
      render: function Element(_, t) {
        const result = t.registrationState != 4
        return (
          <div
            // className={result ? styles.tableIcon : styles.activeTableIcon}
            className={styles.tableIcon}
            style={{ fontSize: '19px' }}
            onClick={() => onAction('cancel', t)}
          >
            <CloseCircleOutlined style={{ width: 22, height: 22 }} />
          </div>
        )
      },
    },
    {
      title: '险种',
      dataIndex: 'transition',
      align: 'center',
      fixed: 'left',
      width: '40px',
      key:'transition',
      render: function Element(_, t) {
        const result = t?.registrationState > 0 && t?.registrationState < 4
        return (
          <div

            style={{width:"19px",height:"29px" ,cursor: result ? 'pointer' : 'not-allowed'}}
            onClick={() => (result ? onAction('transition', t) : '')}
          >
            {result ? (
              t?.insuranceCode == 'ZIFEI' ? (
                /* 自费转医保 */
                <Popover
                    content={
                      <div>
                        <p>
                          将该患者本次就诊转为医保支付（需要进行医保读卡）
                        </p>
                      </div>
                    }
                    placement='bottom'
                    title='说明'
                >
                  <TransactionOutlined style={{color:"green",fontSize:"20px"}}/>
                </Popover>
              ) : (
                /* 医保转自费 */
                  <Popover
                      content={
                        <div>
                          <p>
                            将该患者本次就诊转为自费支付（再次转回医保时需读卡）
                          </p>
                        </div>
                      }
                      placement='bottom'
                      title='说明'
                  >
                    <TransactionOutlined style={{color:"#fa8714",fontSize:"20px"}}/>
                  </Popover>
              )
            ) : (
                <Popover
                    content={
                      <div>
                        <p>
                          该患者未到达或者已结算，无法修改险种类型。
                        </p>
                      </div>
                    }
                    placement='bottom'
                    title='说明'
                >
                  <TransactionOutlined style={{color:"gray",fontSize:"20px"}}/>
                </Popover>
            )}
          </div>
        )
      },
    },
    {
      title: '患者姓名',
      dataIndex: 'patientName',
      align: 'center',
      width: '8.6rem',
      fixed: 'left',
      render: function Element(_, t) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <div className={styles.tableText} style={{ fontWeight: 'bold' }}>
              {t.patientName}
            </div>
            {t.labels.map((v: any) => {
              return (
                <Tooltip
                  key={v.headName}
                  title={t.changeRemark ? t.changeRemark : v.name}
                >
                  <div
                    className={styles.textName}
                    style={{
                      background: v.backgroundColor,
                      color: v.fontColor,
                    }}
                  >
                    {v.headName}
                  </div>
                </Tooltip>
              )
            })}
            {t.isMeber == 1 && (
              <Tooltip
                title={
                  <div>
                    <p>卡号：{t.member.memberPhone}</p>
                    <p>余额：{t.member.balanceMoney}</p>
                  </div>
                }
              >
                <span className={styles.icon}>
                  <MembersIcon
                    style={{
                      fontSize: 18,
                      width: 18,
                      height: 18,
                    }}
                  />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      title: '病历号',
      dataIndex: 'patientId',
      align: 'center',
      width: '7rem',
      render: (_, t) => (t.patientId == '-1' ? '-' : t.patientId),
    },
    {
      title: '门诊号',
      dataIndex: 'outpatientNo',
      align: 'center',
      width: '4rem',
      render: (_, t) => (t.outpatientNo == '-1' ? '-' : t.outpatientNo),
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '8em',
      align: 'center',
      render: (_, t) => {
        return !t || !t.insuranceCode || t.insuranceCode === 'ZIFEI'
          ? '自费'
          : '智慧医保'
      },
    },
    {
      title: '性别',
      dataIndex: 'patientSex',
      align: 'center',
      width: '3.25rem',
      render: (_, t) => getSexData(Number(t.patientSex)),
    },
    {
      title: '年龄',
      dataIndex: 'patientAge',
      align: 'center',
      width: '3.25rem',
      render: (_, r) => getAge(r?.patientAge, r?.patientMonth),
    },
    {
      title: '手机号',
      dataIndex: 'patientPhone',
      align: 'center',
      width: '7rem',
    },
    {
      title: '挂号时间',
      dataIndex: 'registrationTime',
      align: 'center',
      width: '160px',
      ellipsis: true,
      sorter: (a: any, b: any) =>
        moment(a.registrationTime).unix() - moment(b.registrationTime).unix(),
    },
    {
      title: '预约时间',
      dataIndex: 'appointmentTime',
      align: 'center',
      width: '200px',
      sorter: (a: any, b: any) =>
        moment(a.appointmentTime.substring(0, 16)).unix() -
        moment(b.appointmentTime.substring(0, 16)).unix(),
      ellipsis: true,
    },
    {
      title: '预约医生',
      dataIndex: 'appointmentDoctorName',
      align: 'center',
      width: '100px',
      ellipsis: true,
    },
    {
      title: '预约备注',
      dataIndex: 'appointmentComment',
      align: 'center',
      width: '100px',
      ellipsis: true,
    },
    {
      title: '咨询师',
      dataIndex: 'consultDoctorName',
      align: 'center',
      width: '80px',
      ellipsis: true,
    },
    {
      title: '就诊原因',
      dataIndex: 'reason',
      align: 'center',
      width: '100px',
      ellipsis: true,
    },
    {
      title: '挂号科室',
      dataIndex: 'registrationDepartmentName',
      align: 'center',
      width: '100px',
      ellipsis: true,
      render: function Actions(_, r): ReactElement | undefined {
        return (
          <div
            style={{
              color:
                r?.registrationDepartmentName == '未指定科室'
                  ? '#2D7BFF'
                  : '#333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (r?.registrationDepartmentName == '未指定科室') {
                onAction('registerNoAppoint', r)
              }
            }}
          >
            {r?.registrationDepartmentName}
          </div>
        )
      },
    },
    {
      title: '挂号医生',
      dataIndex: 'registrationDoctorName',
      align: 'center',
      width: '100px',
      ellipsis: true,
      render: function Actions(_, r): ReactElement | undefined {
        return (
          <div
            style={{
              color:
                r?.registrationDoctorName == '未指定医生' ? '#2D7BFF' : '#333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (r?.registrationDoctorName == '未指定医生') {
                onAction('registerNoAppoint', r)
              }
            }}
          >
            {r?.registrationDoctorName}
          </div>
        )
      },
    },
    {
      title: '就诊类型',
      dataIndex: 'receiveTag',
      align: 'center',
      width: '80px',
      render: (_, t) => (t.receiveTag == 0 ? '初诊' : '复诊'),
    },
    {
      title: '就诊状态',
      dataIndex: 'registrationState',
      align: 'center',
      fixed: 'right',
      width: '110px',
      render: function action(_, t) {
        return (
          <div
            style={{
              background: getVisitingStatusColor(Number(t.registrationState)),
              padding: '0 4px',
            }}
          >
            <span
              style={{
                color: '#333 ',
                // color: getVisitingStatusColor(Number(t.registrationState)),
              }}
            >
              {getVisitingStatusName(Number(t.registrationState))}
            </span>
          </div>
        )
      },
    },
    {
      title: '就诊科室',
      dataIndex: 'treatmentDepartmentName',
      align: 'center',
      fixed: 'right',
      width: '100px',
      render: function Actions(_, r): ReactElement | undefined {
        return (
          <div
            style={{
              color:
                r?.treatmentDepartmentName == '未指定科室' ? '#2D7BFF' : '#333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (r?.treatmentDepartmentName == '未指定科室') {
                onAction('registerNoAppoint', r)
              }
            }}
          >
            {r?.treatmentDepartmentName}
          </div>
        )
      },
    },
    {
      title: '就诊医生',
      dataIndex: 'treatmentDoctorName',
      align: 'center',
      fixed: 'right',
      width: '100px',
      render: function Actions(_, r): ReactElement | undefined {
        return (
          <div
            style={{
              color:
                r?.treatmentDoctorName == '未指定医生' ? '#2D7BFF' : '#333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (r?.treatmentDoctorName == '未指定医生') {
                onAction('registerNoAppoint', r)
              }
            }}
          >
            {r?.treatmentDoctorName}
          </div>
        )
      },
    },
  ]

  const result =
     insuranceArray.length !== 0
      ? columns
      : columns.filter((v) => v.key != 'transition')

  return result
}
