/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-27 10:32:48
 */
export interface StaffCostEntry {
  headCount?: number
  headCountAvgAmount?: number
  name?: string
  personTime?: number
  personTimeAvgAmount?: number
  totalRetail?: number
  cbxz?: string
  personHeadScale?: number
  totalInsurancePayAmount?: number

  totalJijin: number
  totalDangnianzh: number
  totalJijinPer: number

  totalBaoxiao: number
  
  id?:any
}

export function fromJson(json: any): StaffCostEntry {
  return {
    headCount: json.headCount,
    headCountAvgAmount: json.headCountAvgAmount,
    name: json.name,
    personTime: json.personTime,
    personTimeAvgAmount: json.personTimeAvgAmount,
    totalRetail: json.totalRetail,
    cbxz: json.cbxz,
    personHeadScale: json.personHeadScale,
    totalInsurancePayAmount: json.totalInsurancePayAmount,
    totalJijin: json.totalJijin,
    totalDangnianzh: json.totalDangnianzh,
    totalJijinPer: json.totalJijinPer,
    totalBaoxiao: json.totalBaoxiao,
    id: json.id,
  }
}

export const getIllnessName = (v = 0): string => {
  switch (v) {
    case 0:
      return '普病'
    case 1:
      return '特病'
    case 2:
      return '慢病'
    case 3:
      return '新冠门诊'
    default:
      return '普病'
  }
}

export const getIllnessDiagType = (v: number): string => {
  switch (v) {
    case 1:
      return '西医诊断'
    case 2:
      return '中医证候'
    case 3:
      return '中医诊断'
    default:
      return '-'
  }
}

export const getIllnessSource = (v: number): string => {
  switch (v) {
    case 1:
      return 'ICD10 1.0'
    case 2:
      return 'ICD10 2.0'
    case 9:
      return '其他'
    default:
      return '-'
  }
}