/* eslint-disable react/display-name */
import { TableColumnType, Typography } from 'antd'
import React from 'react'
import {
  getChrgitmLvName,
  getHilistLmtpricType,
  getIsCouldBxName,
} from '../../../models/commodity'
const { Text } = Typography

export const InsuranceMzColumn = (
  onClick: (text: string, id: any, item?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      fixed: 'left',
      render: (_1, _2, index) => <span>{startIndex + index + 1}</span>,
    },{
      title: '医疗目录编码',
      // width: '6rem',
      dataIndex: 'medListCodg',
      align: 'center',
      ellipsis: true,
    },{
      title: '药品商品名',
      // width: '6rem',
      dataIndex: 'drugProdname',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '药品通用名',
      // width: '6rem',
      dataIndex: 'drugGenname',
      align: 'center',
      ellipsis: true,
    },{
      title: '注册名称',
      // width: '6rem',
      dataIndex: 'regName',
      align: 'center',
      ellipsis: true,
    },{
      title: '中草药名称',
      // width: '6rem',
      dataIndex: 'tcmherbName',
      align: 'center',
      ellipsis: true,
    },{
      title: '更新时间',
      // width: '6rem',
      dataIndex: 'updtTime',
      align: 'center',
      ellipsis: true,
    }
  ]
}
