/* eslint-disable react/prop-types */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-12-31 13:41:58
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-28 10:29:49
 */
import React, { useContext, useRef, useState } from "react"
import styles from "./Surgical.module.css"
import { Row, Space, Button, notification, Tooltip } from "antd"
import moment from "moment"
import { TreatmentNotice } from "../../../models/treatment"
import { ThemeContext } from "../../../theme/ThemeContext"
import SurgicalFrom from "./SurgicalFrom"
import { useDispatch } from "react-redux"
import { RootDispatch } from "../../../app/store"
import { surgicalApply } from "./surgicalSlice"
import { surgicalBody } from "../../../services/surgical"
import { unwrapResult } from "@reduxjs/toolkit"

interface SurgicalProps {
  readMode?: boolean
  state?: number
  saveEdit: () => void
  PreferencesSetChangeFn?: () => void
}

export const Surgical: React.FC<SurgicalProps> = ({ saveEdit, PreferencesSetChangeFn }) => {
  const dispatch = useDispatch<RootDispatch>()

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const theme = useContext(ThemeContext)

  const [isCreating, setIsCreating] = useState(false)

  const ref = useRef<any>(null)

  return (
    <div className={styles.surgical}>
      <Row
        className={styles.container}
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          flex: 1,
          background: '#fff',
          marginBottom: '10px',
        }}
        wrap={false}
      >
        <SurgicalFrom
          ref={ref}
          subForm={(v: surgicalBody) => {
            dispatch(surgicalApply(v))
              .then(unwrapResult)
              .then(async () => {
                await notification.success({
                  message: '手术申请提交成功',
                })
                saveEdit()
              })
          }}
          PreferencesSetChange={() => {
            PreferencesSetChangeFn && PreferencesSetChangeFn()
          }}
        ></SurgicalFrom>
      </Row>
      <Row
        style={{
          width: '100%',
          height: '3.125rem',
          backgroundColor: '#fff',
          paddingRight: '40px',
        }}
        align='middle'
      >
        {notice && notice.recentlyTreatmentTime && (
          <div
            style={{
              marginLeft: 15,
              fontSize: '1rem',
              color: theme.e,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              setIsModalVisible(true)
            }}
          >
            最近就诊：
            {moment(notice.recentlyTreatmentTime).format('YYYY/MM/DD')}
            <Tooltip title={notice?.disease}>
              <span
                style={{
                  marginLeft: 15,
                  maxWidth: 400,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                疾病名称：{notice?.disease}
              </span>
            </Tooltip>
          </div>
        )}
        <div style={{ flex: 1 }} />
        <Space>
          <Button
            type='primary'
            loading={isCreating}
            onClick={() => {
              ref.current?.subSurgical()
            }}
          >
            保存
          </Button>
        </Space>
      </Row>
    </div>
  )
}
