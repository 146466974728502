import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Form, Row, Space, Table, notification } from "antd";
import React, { ReactElement, useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTenantName } from "../../../app/applicationSlice";
import { RootDispatch } from "../../../app/store";
import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'
import { DoctorSaleEntry, DoctorSalesResult } from '../../../models/doctorSales'
import { DateRangeType, DateSelect, TJFXTime } from "../../../compnents/form/DateSelect";
import { DrugCostEntry } from "../../../models/drugCost";
import { ThemeContext } from "../../../theme/ThemeContext";
import styles from "./DrugCosts.module.css";
import {
  getDrugCostEntries,
  selectDrugCostEntrisParams,
  setGetDrugCostEntriesParams,
  getDrugCostsExport,
} from "./drugCostSlice";

export const DrugCosts = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const theme = useContext(ThemeContext);

  const [form] = Form.useForm();

  const params = useSelector(selectDrugCostEntrisParams);

  const name = useSelector(selectTenantName);

  const [entries, setEntries] = useState<DrugCostEntry[]>([]);

  const printRef = useRef<PrintFrameRef>(null);

  const [result, setResult] = useState({
    headers: [],
    entries: [],
  } as DoctorSalesResult)

  const [pageLoading, setPageLoading] = useState(false)   //表格 loading

  useEffect(() => {
    try {
      setPageLoading(true)
      dispatch(getDrugCostEntries(params))
        .then(unwrapResult)
        .then((data) => setEntries(data))
        .finally(() => setPageLoading(false))
    } catch {
      // do nothing.
    }
  }, [params]);

  useEffect(() => {
    form.setFieldsValue(params);
  }, []);


  const table = (type = false) => (<>
    <Table
      className={type ? styles.tables : styles.table}
      pagination={false}
      rowClassName={(e) =>
        e.treatmentDoctorName === "合计" ? styles.total : ""
      }
      loading={pageLoading}
      dataSource={entries}
      bordered
      rowKey={(_, i) => `${i}}`}
      style={{ flex: 1, overflow: "auto" }}
      columns={[
        {
          title: "医生姓名",
          key: "treatmentDoctorName",
          width: "4.5rem",
          align: "center",
          render: (_, e) => {
            return {
              props: {
                style: {
                  whiteSpace: "nowrap",
                },
              },
              children: e.treatmentDoctorName,
            };
          },
        },
        {
          title: "处方数",
          dataIndex: "recipeCount",
          align: "right",
        },
        {
          title: "成本金额",
          key: "totalCost",
          align: "right",
          render: (_, r) => r.totalCost?.toFixed(2),
        },

        {
          title: "销售金额(应收)",
          key: "totalRetail",
          align: "right",
          render: (_, r) => r.totalRetail?.toFixed(2),
        },
        {
          title: "进销差价",
          key: "totalDiff",
          align: "right",
          render: (_, r) => r.totalDiff?.toFixed(2),
        },
      ]}
    />
  </>)

  const tablePrint = (type = false) => (<>
    <Table
      className={type ? styles.tables : styles.table}
      pagination={false}
      rowClassName={(e) =>
        e.treatmentDoctorName === "合计" ? "" : ""
      }
      loading={pageLoading}
      dataSource={entries}
      bordered
      rowKey={(_, i) => `${i}}`}
      style={{ flex: 1, overflow: "auto" }}
      columns={[
        {
          title: "医生姓名",
          key: "treatmentDoctorName",
          width: "4.5rem",
          align: "center",
          render: (_, e) => {
            return {
              props: {
                style: {
                  whiteSpace: "nowrap",
                },
              },
              children: e.treatmentDoctorName,
            };
          },
        },
        {
          title: "处方数",
          dataIndex: "recipeCount",
          align: "right",
        },
        {
          title: "成本金额",
          key: "totalCost",
          align: "right",
          render: (_, r) => r.totalCost?.toFixed(2),
        },

        {
          title: "销售金额(应收)",
          key: "totalRetail",
          align: "right",
          render: (_, r) => r.totalRetail?.toFixed(2),
        },
        {
          title: "进销差价",
          key: "totalDiff",
          align: "right",
          render: (_, r) => r.totalDiff?.toFixed(2),
        },
      ]}
    />
  </>)

  const outClued = () => {
    dispatch(getDrugCostsExport(params))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res;
        const blob = new Blob([content]);
        const fileName = "药品成本统计.xlsx";
        const selfURL = window[window.webkitURL ? "webkitURL" : "URL"];
        const elink = document.createElement("a");
        if ("download" in elink) {
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = selfURL["createObjectURL"](blob);
          document.body.appendChild(elink);
          // 触发链接
          elink.click();
          selfURL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        } else {
          navigator.msSaveBlob(blob, fileName);
        }
      })
      .then(() => {
        notification.success({
          message: "导出成功",
          duration: 3,
        });
      })
      .catch((res: any) => {
        return
      });
  };
  return (
    <Col
      style={{
        height: "calc(100% - 30px)",
        padding: "0 15px",
        borderRadius: theme.br,
        backgroundColor: theme.pn,
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Form
        form={form}
        onFinish={(values) =>
          dispatch(setGetDrugCostEntriesParams({ ...params, ...values }))
        }
      >
        <Row
          style={{ marginTop: 15, marginBottom: 15 }}
          align="middle"
          justify="space-between"
        >
          <Space>
            <DateSelect
              style={{ width: "8.5rem" }}
              labelPrefix=""
              allowClear={false}
              options={TJFXTime}
              namePrefix="time"
              placeholder="收费列表-时间"
              initialValue={DateRangeType.Today}
              onChange={form.submit}
            />
          </Space>
          <Space>
            <Button type="primary" onClick={outClued}>
              导出
            </Button>
            <Button type="primary" onClick={form.submit}>
              查询
            </Button>
            <Button type="primary" onClick={() => printRef.current?.print()
            }>
              打印
            </Button>
          </Space>


        </Row>
      </Form>
      <div
        style={{
          textAlign: "center",
          color: theme.tc1,
          fontWeight: "bold",
          fontSize: "1.5rem",
          marginBottom: 10,
        }}
      >
        {name}-药品成本统计
      </div>
      {table()}
      <PrintFrame ref={printRef} title={JSON.parse(localStorage.getItem("credentials") as string)?.tenantName}>
        <Row style={{ lineHeight: "32px", textAlign: 'right' }}>
          <Col span={13}>药品成本统计</Col>
          <Col span={11}>日期：{form.getFieldValue('timeHead')?.split(' ')[0]} - {form.getFieldValue('timeTail')?.split(' ')[0]}</Col>
        </Row>
        {tablePrint(true)}
      </PrintFrame>
    </Col>

  );
};
