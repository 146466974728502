/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-08 17:32:43
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-05 19:57:08
 */
import { Modelment } from '../models/list'
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface InventoryStockParams {
  billingCategory?: string
  current?: number
  flag?: number
  materialsCategory?: number
  name?: string
  sign?: number
  size?: number
  storehouseId?: number
  tenantId?: number
}

export interface stockParams {
    billingCategory?: string;
    current?: number;
    materialsCategory?: number;
    name?: string;
    size?: number;
    tenantId?: number;
    tenantInventoryReceiptsId?: number;
}

export function getInventoryPage(params: InventoryStockParams): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryledger/checklist`,
        method: "GET",
        params
    }
}

export function getInventStock(params: stockParams): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/fastcheck`,
        method: "GET",
        params
    }
}