/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { unwrapResult } from '@reduxjs/toolkit'
import { Modal, Form, Input, Select, Row, Col, Button, DatePicker } from 'antd'
import cnchar from 'cnchar'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  getMaterialsCategoryName,
  MaterialsCategories,
} from '../../models/commodity'
import { DateTimeFormat } from '../../models/datetime'
import {
  getSupplierCategory,
  SupplierCategories,
} from '../../models/supplierCategory'
import { isSameName } from '../../utils/fieldsUtils'
import styles from './supplier.module.css'
import {
  detailSupplier,
  selectDetail,
  selectId,
  setDetail,
  setSelectId,
  submitSupplierAsync,
} from './supplierSlice'
import { pinyin } from 'pinyin-pro'
const { Option } = Select

export const SupplierModal = (props: {
  visible: boolean
  onOk: () => void
  onCancel: () => void
  supplierCategory?: number
}) => {
  const [action, setAction] = useState('新增')

  const [title, setTitle] = useState('供应商')

  const [state, setState] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const detail: any = useSelector(selectDetail)

  const [form] = Form.useForm()

  const targetId = useSelector(selectId)

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  useEffect(() => {
    if (targetId) {
      setAction('修改')
      setState(true)
      dispatch(detailSupplier(targetId))
    } else {
      setAction('新增')
      setState(false)
    }
  }, [targetId])

  useEffect(() => {
    if (props.visible) {
      if (props.supplierCategory) {
        form.setFieldsValue({
          supplierCategory: props.supplierCategory,
        })
        setState(true)
      } else if (props.supplierCategory === 0) {
        form.setFieldsValue({
          supplierCategory: props.supplierCategory,
        })
        setState(true)
      }
    }
    return () => {
      setState(false)
    }
  }, [props.visible, props.supplierCategory])

  useEffect(() => {
    if (JSON.stringify(detail) !== '{}') {
      form.setFieldsValue({
        address: detail.address,
        companyName: detail.companyName,
        companyCode: detail.companyCode,
        createTime: detail.createTime,
        fax: detail.fax,
        licenceCategory: detail.licenceCategory,
        licenceDeadline: detail.licenceDeadline
          ? moment(detail.licenceDeadline)
          : null,
        licenceNumber: detail.licenceNumber,
        mnemonicCode: detail.mnemonicCode,
        phone: detail.phone,
        supplierMaterialsCategory: detail.supplierMaterialsCategory
          ? JSON.parse(detail.supplierMaterialsCategory)
          : undefined,
        postCode: detail.postCode,
        updateTime: detail.updateTime,
        supplierCategory: detail.supplierCategory,
      })
    }
  }, [detail])
  return (
    <Modal
      title={action + title}
      visible={props.visible}
      okText='确认'
      cancelText='取消'
      className={styles.form}
      maskClosable={false}
      onOk={() => {
        setState(false)
        dispatch(setSelectId(0))
        dispatch(setDetail({}))
        setAction('新增')
        form.resetFields()
        props.onOk()
      }}
      onCancel={() => {
        setState(false)
        setAction('新增')
        dispatch(setDetail({}))
        dispatch(setSelectId(0))
        form.resetFields()
        props.onCancel()
      }}
      footer={null}
    >
      <Form
        autoComplete='off'
        form={form}
        onFinish={(vs) => {
          const date = moment(vs.licenceDeadline)
          if (targetId) {
            dispatch(
              submitSupplierAsync({
                ...vs,
                id: targetId,
                licenceDeadline: vs.licenceDeadline?date.format(DateTimeFormat):null,
                address: vs.address ? vs.address : undefined,
                supplierMaterialsCategory: JSON.stringify(
                  vs.supplierMaterialsCategory
                ),
              })
            )
              .then(unwrapResult)
              .then(() => {
                form.resetFields()
                props.onOk()
                dispatch(setDetail({}))
                dispatch(setSelectId(0))
              })
          } else {
            dispatch(
              submitSupplierAsync({
                ...vs,
                licenceDeadline: vs.licenceDeadline?date.format(DateTimeFormat):null,
                address: vs.address ? vs.address : undefined,
                supplierMaterialsCategory: JSON.stringify(
                  vs.supplierMaterialsCategory
                ),
              })
            )
              .then(unwrapResult)
              .then(() => {
                form.resetFields()
                props.onOk()
                dispatch(setDetail({}))
                dispatch(setSelectId(0))
              })
          }

        }}
        name='advanced_search'
        className='ant-advanced-search-form'
        {...layout}
      >
        <Row>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='供应商名称'
              name='companyName'
              rules={[
                { required: true, message: '请输入供应商名称!' },
                {
                  max: 45,
                  message: '最长45位',
                },
              ]}
            >
              <Input
                autoFocus
                onInput={(e: any) => {
                  form.setFieldsValue({
                    mnemonicCode: e.target.value
                      ? pinyin(e.target.value, {
                          pattern: 'first',
                          toneType: 'none',
                          separator: '',
                        }).toUpperCase()
                      : '',
                  })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='供应类别'
              name='supplierCategory'
              rules={[{ required: true, message: '请输入供应商类别!' }]}
            >
              <Select placeholder='供应类别'>
                <Option value={0}>供应商</Option>
                <Option value={1}>生产厂商</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item label='自定义编号' name='companyCode'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='助记码'
              name='mnemonicCode'
              rules={[
                { required: true, message: '请输入助记码!' },
                {
                  max: 45,
                  message: '最长45位',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='地址'
              name='address'
              rules={[
                {
                  max: 45,
                  message: '最长45位',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='电话'
              name='phone'
              rules={[
                {
                  max: 45,
                  message: '最长45位',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='传真'
              name='fax'
              rules={[
                {
                  max: 45,
                  message: '最长45位',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='邮编'
              name='postCode'
              rules={[
                {
                  max: 45,
                  message: '最长45位',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='供应物资类别'
              name='supplierMaterialsCategory'
              rules={[{ required: true, message: '请输入供应物资类别!' }]}
            >
              <Select allowClear mode='multiple'>
                {MaterialsCategories.map((v) => (
                  <Option value={v} key={v}>
                    {getMaterialsCategoryName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='许可证类型'
              name='licenceCategory'
              rules={[
                // { required: true, message: "请输入许可证类型!" },
                {
                  max: 45,
                  message: '最长45位',
                },
              ]}
            >
              <Select>
                {SupplierCategories.map((v) => (
                  <Option value={v} key={v}>
                    {getSupplierCategory(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='许可证号码'
              name='licenceNumber'
              rules={[
                // { required: true, message: "请输入许可证号码!" },
                {
                  max: 45,
                  message: '最长45位',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              label='许可证有效期'
              name='licenceDeadline'
              // rules={[{ required: true, message: "请输入许可证有效期!" }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
              确认
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={{ float: 'left' }}
              onClick={() => {
                form.resetFields()
                setState(false)
                props.onCancel()
              }}
            >
              取消
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
