/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-31 14:23:29
 * @LastEditors: linxi
 * @LastEditTime: 2021-06-24 19:16:41
 */
import { api } from "../app/applicationSlice";
import { API_PREFIX } from "./constant";
import { Request } from "./request";
export interface AddtionDrugParams {
  current?: number;
  size?: number;
  tenantMaintainId?: number;
  materialsCategory?: number;
  name?: string;
}

export interface ModalDurgParams {
  current?: number
  maintainCategory?: number
  materialsCategory?: number
  name?: string
  size?: number
  storehouseId?: number
  tenantId?: number
}

export interface SaveAllDurgBody {
  maintainCategory?: number
  maintainMeasure?: string
  maintainQuality?: number
  maintainResult?: number
  materialsCategory?: number
  name?: string
  status?: number
  storehouseId?: number
  tenantMaintainId?: number
}

export function getAddtionDrugPage(params: AddtionDrugParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintainitem/page`,
    method: "GET",
    params,
  };
}

export function getAddtionDrugData(params: AddtionDrugParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintainitem/list`,
    method: "GET",
    params
  }
}

export function getModalDurgData(params: ModalDurgParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/maintainlist`,
    method: "GET",
    params
  }
}

export function saveDurgbatch(ids: Array<any>): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintainitem/submit`,
    method: "POST",
    body: ids
  }
}

export function removeDurg(ids: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintainitem/remove`,
    method: "POST",
    params: {
      ids
    }
  }
}

export function saveAllDurg(body: SaveAllDurgBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintainitem/addbatch`,
    method: "POST",
    body
  }
}
