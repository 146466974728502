/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-27 15:08:32
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-05 18:24:43
 */

import { Button, Col, Row } from 'antd'
import React from 'react'
import styles from '../Putstorelist.module.css'
import { Category, CategoryName } from '../../../models/category'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../../models/datetime'
import { useHistory } from 'react-router-dom'

export const DebitOutQuery = (props: {
  category: Category
  Detail: any
  outClued?: () => void
}) => {
  const history = useHistory()

  return (
    <>
      <Row
        className={styles.titletext}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Col
          span={24}
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            paddingLeft: '10px',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex' }}>
            <span
              style={{
                width: '2px',
                height: '16px',
                background: ' #0052D9',
                position: 'relative',
                top: '6px',
                marginRight: '10px',
              }}
            ></span>
            {CategoryName(props.category)}业务单据
          </div>
          <Button
            type='primary'
            style={{ float: 'right', position: 'relative', top: '-30px' }}
            disabled={props.Detail.storehouseTag !== 0 ? true : false}
            onClick={() => {
              switch (props.category) {
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                case '10':
                  history.push({
                    pathname: '/debitOut',
                    state: { category: props.category, detail: props.Detail },
                  })
                  break
                default:
                  break
              }
            }}
          >
            修改业务单据
          </Button>
          {props?.category != '10' && (
            <Button
              type='primary'
              ghost
              style={{
                float: 'right',
                position: 'relative',
                top: '-30px',
                marginRight: '20px',
              }}
              onClick={() => {
                props.outClued && props.outClued()
              }}
            >
              导出
            </Button>
          )}
        </Col>
      </Row>
      <Row
        style={{
          marginTop: '10px',
          lineHeight: '30px',
          paddingLeft: '20px',
          marginBottom: props.category == '9' ? 0 : '10px',
          fontSize: '18px',
        }}
      >
        <Col span={6}>库房名称：{props.Detail.storehouseName}</Col>
        <Col span={6}>业务部门：{props.Detail.departmentName}</Col>
        <Col span={6}>业务人员：{props.Detail.userName}</Col>
        <Col span={6}>
          业务日期：
          {moment(props.Detail.recepitsDate).format(DateTimeFormatSimple)}
        </Col>
      </Row>
      {props.category == '9' && (
        <Row
          style={{
            lineHeight: '30px',
            paddingLeft: '20px',
            fontSize: '18px',
            marginBottom: '10px',
          }}
        >
          <Col span={24}>备注：{props.Detail.remark}</Col>
        </Row>
      )}
    </>
  )
}
