import { Department } from '../models/department'
import { baseDataType, serverType } from './commodity'
import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { Request } from './request'

export interface GetDepartmentListParams {
  current: number
  name?: string
  size?: number
  status?: number
  suitForClinc?: number
  tenantId?: string
}

export function getDepartmentList(params: GetDepartmentListParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/department/list`
      : `${API_PREFIX}/blade-user/department/list`,
    method: 'GET',
    params,
  }
}

export interface DepartmentBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    id: number
  }
}

// 科室上传 1991
export function DepartmentUpload(bodyName: DepartmentBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/saveDepartmentManageInfo_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 科室撤销 1991
export function DepartmentRepeal(bodyName: DepartmentBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/pauseDepartmentInfo_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export function getOutpatientDepartment(): Request {
  return {
    url: `${API_PREFIX}/blade-user/department/list`,
    method: 'GET',
    params: {
      size: 1000,
      current: 0,
      status: 1,
      suitForClinc: 1,
    },
  }
}

export function getAppointmentOutpatientDepartment(): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentDepartment`,
    method: 'GET',
    params: {
      size: 1000,
      current: 0,
      status: 1,
      suitForClinc: 1,
    },
  }
}


export interface GetDepartmentDetailParams {
  departmentId: string
  tenantId?: string
}

export function getDepartmentDetail(
  params: GetDepartmentDetailParams
): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/department/detail`
      : `${API_PREFIX}//blade-user/department/view`,
    method: params.tenantId ? 'POST' : 'GET',
    params: {
      departmentId: params.departmentId,
    },
  }
}

export interface CreateDepartmentParams {
  department: Department
  tenantId?: string
}

export function createOrUpdateDepartment(
  params: CreateDepartmentParams
): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/department/save`
      : `${API_PREFIX}/blade-user/department/save`,
    method: 'POST',
    body: {
      tenantId: params.tenantId,
      id: params.department.id,
      name: params.department.name,
      code: params.department.code,
      category: params.department.category,
      subCategory: params.department.subCategory.join(','),
      ybKebie: params.department.ybKebie,
      resperName: params.department.resperName,
      resperTel: params.department.resperTel,
      showFlg: 1,
    },
  }
}

export interface ToggleDepartmentStateParams {
  departmentId: string
  tenantId?: string
}

export function toggleDepartmentState(
  params: ToggleDepartmentStateParams
): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/department/logoutOrEnable`
      : `${API_PREFIX}/blade-user/department/logoutOrEnable`,
    method: 'POST',
    params,
  }
}
