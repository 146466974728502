/* eslint-disable react/display-name */
import { Avatar, Badge, Button, Space, TableColumnType, Tooltip } from 'antd'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { OweMoneyIcon } from '../../../compnents/icons/Icons'
import { Dimen } from '../../../models/dimen'
import {
  getInvoiceType,
  getPayFlagName,
  Payment,
  PaymentStatus,
} from '../../../models/payment'
import { RecipeStatus } from '../../../models/recipe'
import { getGenderName } from '../../../models/user'
import { getAge } from '../../../utils/StringUtils'
import { selectTenant } from '../../user/setting/settingSlice'
import styles from './Payments.module.css'

type Action =
  | 'pay'
  | 'return'
  | 'detail'
  | 'upload'
  | 'repeal'
  | 'supplementary'

export const getCardType = (cardType: string) => {
  switch (cardType) {
    case '1':
      return '电子凭证'
    case '2':
      return '身份证'
    case '3':
      return '医保卡'
    default:
      return ''
  }
}

export const getInsuranceCodeName = (insuranceCode: string) => {
  switch (insuranceCode) {
    case 'ZIFEI':
      return '自费'
    case 'ZHEJIANG':
      return '浙江智慧医保'
    case 'CHONGQING':
      return '重庆医保'
    default:
      return ''
  }
}

export const PaymentColumns = (
  //待收费
  _: PaymentStatus,
  startIndex: number,
  onAction: (
    action: Action,
    id: string,
    t: any,
    settlementCategory?: number
  ) => void
): TableColumnType<Payment>[] => {
  const oweMoneyShow: any = useSelector(selectTenant) //欠费是否显示
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      fixed: 'left',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '门诊号',
      key: 'outpatientNo',
      width: Dimen.OutpatientNo,
      align: 'center',
      fixed: 'left',
      render: (_, { registration }) => registration.outpatientNo,
    },
    {
      title: '患者姓名',
      key: 'patientName',
      align: 'center',
      width: '10rem',
      fixed: 'left',
      // ellipsis:true,
      render: (_, { treatment, arrearageAmountSum, registration, labels }) => {
        return (
          <div style={{ width: '100%', height: '100%' }}>
            {/* {registration.insuranceCode ? (
              <Badge
                offset={[0, 10]}
                dot={true}
                color={
                  registration.insuranceCode === 'ZHEJIANG'
                    ? '#07BD4F'
                    : registration.insuranceCode === 'ZIFEI'
                    ? '#9B3EDF'
                    : ''
                }
              >
                <div style={{ width: '100%', height: '100%' }}>
                  {treatment.patientName}
                </div>
              </Badge>
            ) : ( */}
            <div style={{}}>
              <div
                style={{ width: '100%', height: '40px', lineHeight: '40px' }}
              >
                {treatment.patientName}
              </div>
              {/* {arrearageAmountSum > 0 &&
                oweMoneyShow &&
                oweMoneyShow?.openOwe == 1 && (
                  <Tooltip title={`欠费金额 ${arrearageAmountSum} 元`}>
                    <div>
                      <OweMoneyIcon
                        style={{
                          marginLeft: '5px',
                          width: '18px',
                          height: '18px',
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: labels?.length ? '8px' : '0px',
                }}
              >
                {!!labels?.length &&
                  labels?.map((v: any) => (
                    <Tooltip key={v.id} title={v.name}>
                      <span
                        className={styles.label}
                        style={{
                          backgroundColor: v.backgroundColor,
                          color: v.fontColor,
                        }}
                      >
                        {v.headName}
                      </span>
                    </Tooltip>
                  ))}
              </div> */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: labels?.length ? '8px' : '0px',
                }}
              >
                {arrearageAmountSum > 0 &&
                  oweMoneyShow &&
                  oweMoneyShow?.openOwe == 1 && (
                    <Tooltip title={`欠费金额 ${arrearageAmountSum} 元`}>
                      <span style={{
                        width: '18px',
                        height: '18px',
                        lineHeight: '18px',
                        marginBottom: labels?.length ? '0px' : '8px',
                      }}>
                        <OweMoneyIcon
                          style={{
                            marginRight: '3px',
                            width: '18px',
                            height: '18px',
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}
                {!!labels?.length &&
                  labels?.map((v: any) => (
                    <Tooltip key={v.id} title={v.name}>
                      <span
                        className={styles.label}
                        style={{
                          backgroundColor: v.backgroundColor,
                          color: v.fontColor,
                        }}
                      >
                        {v.headName}
                      </span>
                    </Tooltip>
                  ))}
              </div>
            </div>
            {/* )} */}
          </div>
        )
        // return treatment.patientName
      },
    },
    {
      title: '病历号',
      dataIndex: 'patientId',
      key: 'patientId',
      align: 'center',
      width: '8rem',
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '8em',
      align: 'center',
      render: (_, { registration }) => {
        return registration.insuranceCode === 'ZIFEI' ||
          !registration.insuranceCode
          ? '自费'
          : '智慧医保'
      },
    },
    {
      title: '读卡方式',
      key: 'cardType',
      align: 'center',
      width: '8em',
      render: (_, { registration }) => {
        return getCardType(registration.cardType)
      },
    },
    {
      title: '收费标志',
      key: 'settlementCategory',
      align: 'center',
      width: '8em',
      render: (_, { settlementCategory }) => getPayFlagName(settlementCategory),
    },
    {
      title: '性别',
      key: 'patientSex',
      align: 'center',
      width: Dimen.Sex,
      render: (_, { treatment }) => getGenderName(treatment.patientSex),
    },
    {
      title: '年龄',
      key: 'patientAge',
      align: 'center',
      width: Dimen.Age,
      render: (_, { treatment }) =>
        getAge(treatment?.patientAge, treatment?.patientMonth),
    },
    {
      title: '诊断疾病',
      key: 'disease',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, { treatment }) => treatment?.disease,
    },
    // {
    //   title: '诊断疾病',
    //   key: 'disease',
    //   align: 'center',
    //   width: '8rem',
    //   ellipsis: true,
    //   render: (_, { treatment }) => ({
    //     props: {
    //       style: {
    //         maxWidth: '14rem',
    //         overflow: 'hidden',
    //         textOverflow: 'ellipsis',
    //       },
    //     },
    //     children: treatment.disease,
    //   }),
    // },
    {
      title: '总金额',
      key: 'amount',
      width: '6em',
      align: 'right',
      render: (_, { amount }) =>
        amount === undefined ? '' : amount?.toFixed(2),
    },
    {
      title: '接诊科室',
      key: 'treatmentDepartmentName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, { treatment }) => treatment.treatmentDepartmentName,
    },
    {
      title: '接诊医生',
      key: 'treatmentDoctorName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, { treatment }) => treatment.treatmentDoctorName,
    },
    {
      title: '挂号时间',
      key: 'registrationTime',
      align: 'center',
      width: Dimen.Time,
      render: (_, { registration }) => registration.registrationTime,
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '5rem',
      fixed: 'right',
      render: function ShowAction(_, t): ReactElement {
        return (
          <Button
            size='small'
            type='primary'
            onClick={() =>
              onAction('pay', t.treatment.id, t, t.settlementCategory)
            }
          >
            结算
          </Button>
        )
      },
    },
  ]
}

export const PaymentColumnsWithAmount = (
  //已收费
  status: PaymentStatus,
  startIndex: number,
  onAction: (
    action: Action,
    id: any,
    t: any,
    settlementCategory?: number
  ) => void,
  YBZFUploadflag?: boolean
): TableColumnType<Payment>[] => {
  const oweMoneyShow: any = useSelector(selectTenant) //欠费是否显示

  const colums: TableColumnType<Payment>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      fixed: 'left',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '门诊号',
      key: 'outpatientNo',
      align: 'center',
      fixed: 'left',
      width: Dimen.OutpatientNo,
      render: (_, { registration }) => registration.outpatientNo,
    },
    {
      title: '患者姓名',
      key: 'patientName',
      align: 'center',
      width: '10rem',
      fixed: 'left',
      // ellipsis:true,
      render: (_, { treatment, arrearageAmountSum, registration, labels }) => {
        return (
          <div style={{ width: '100%', height: '100%' }}>
            {/* {registration.insuranceCode ? (
              <Badge
                offset={[0, 10]}
                dot={true}
                color={
                  registration.insuranceCode === 'ZHEJIANG'
                    ? '#07BD4F'
                    : registration.insuranceCode === 'ZIFEI'
                    ? '#9B3EDF'
                    : ''
                }
              >
                <div style={{ width: '100%', height: '100%' }}>
                  {treatment.patientName}
                </div>
              </Badge>
            ) : ( */}
            <div style={{}}>
              <div
                style={{ width: '100%', height: '40px', lineHeight: '40px' }}
              >
                {treatment.patientName}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: labels?.length ? '8px' : '0px',
                }}
              >
              {arrearageAmountSum > 0 &&
                oweMoneyShow &&
                oweMoneyShow?.openOwe == 1 && (
                  <Tooltip title={`欠费金额 ${arrearageAmountSum} 元`}>
                    <span style={{
                      width: '18px',
                      height: '18px',
                      lineHeight: '18px',
                      marginBottom: labels?.length ?  '0px' : '8px',
                    }}>
                      <OweMoneyIcon
                        style={{
                          marginRight: '3px',
                          width: '18px',
                          height: '18px',
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {!!labels?.length &&
                  labels?.map((v: any) => (
                    <Tooltip key={v.id} title={v.name}>
                      <span
                        className={styles.label}
                        style={{
                          backgroundColor: v.backgroundColor,
                          color: v.fontColor,
                        }}
                      >
                        {v.headName}
                      </span>
                    </Tooltip>
                  ))}
              </div>
            </div>
            {/* )} */}
          </div>
        )
        // return treatment.patientName
      },
    },
    {
      title: '病历号',
      key: 'patientId',
      align: 'center',
      width: '8em',
      dataIndex: 'patientId',
      render: (_, { registration }) => registration.patientId,
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '8em',
      align: 'center',
      render: (_, _2) => {
        return _2.paymentInsuranceCode === 'ZIFEI' || !_2.paymentInsuranceCode
          ? '自 费'
          : '智慧医保'
      },
    },

    {
      title: '读卡方式',
      key: 'cardType',
      align: 'center',
      width: '6em',
      render: (_, { registration }) => {
        return getCardType(registration.cardType)
      },
    },
    {
      title: '收费标志',
      key: 'settlementCategory',
      align: 'center',
      width: '7em',
      render: (_, { treatment }) =>
        getPayFlagName(treatment.settlementCategory),
    },
    {
      title: '性别',
      key: 'patientSex',
      align: 'center',
      width: '4em',
      render: (_, { treatment }) => getGenderName(treatment.patientSex),
    },
    {
      title: '年龄',
      key: 'patientAge',
      align: 'center',
      width: '4em',
      render: (_, { treatment }) =>
        getAge(treatment?.patientAge, treatment?.patientMonth),
    },
    {
      title: '诊断疾病',
      key: 'disease',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, { treatment }) => treatment?.disease,
    },
    {
      title: '报销金额',
      key: 'treatmentDoctorName',
      align: 'right',
      width: '8em',
      render: (_, { insurancePayAmount }) =>
        insurancePayAmount === undefined ? '' : insurancePayAmount?.toFixed(2),
    },
    {
      title: '现金金额',
      key: 'treatmentDoctorName',
      align: 'right',
      width: '8em',
      render: (_, { realPayAmount }) =>
        realPayAmount === undefined ? '' : realPayAmount?.toFixed(2),
    },
    {
      title: '总金额',
      key: 'amount',
      width: '8em',
      align: 'right',
      render: (_, { amount }) =>
        amount === undefined ? '' : amount?.toFixed(2),
    },
    // {
    //   title: '欠费补缴金额',
    //   key: 'realPayAmount',
    //   align: 'right',
    //   width: '10em',
    //   render: (
    //     _,
    //     { shouldPayAmount, realPayAmount, arrearageAmount, accountingStatus }
    //   ) =>
    //     accountingStatus === 0
    //       ? '-'
    //       : ((realPayAmount || 0) - (arrearageAmount || 0))?.toFixed(2),
    // },
    {
      title: '欠费金额',
      key: 'arrearageAmount',
      align: 'right',
      width: '8em',
      render: (_, { realPayAmount, arrearageAmount, accountingStatus }) =>
        accountingStatus === 0
          ? '-'
          : arrearageAmount && arrearageAmount.toFixed(2),
    },
    {
      title: '收款人',
      key: 'payee',
      align: 'right',
      width: '8em',
      render: (_, { treatment }) => treatment.payeeName,
    },
    {
      title: '接诊科室',
      key: 'treatmentDepartmentName',
      align: 'center',
      width: '8em',
      render: (_, { treatment }) => treatment.treatmentDepartmentName,
    },
    {
      title: '接诊医生',
      key: 'treatmentDoctorName',
      align: 'center',
      width: '8em',
      render: (_, { treatment }) => treatment.treatmentDoctorName,
    },
    {
      title: '结算状态',
      key: 'accountingStatus',
      width: Dimen.Price,
      align: 'center',
      render: (_, { accountingStatus }) =>
        accountingStatus === 0 ? '已结清' : '欠费中',
    },
    {
      title: status === RecipeStatus.Settled ? '收费时间' : '退费时间',
      key: 'chargeOrRefundTime',
      align: 'center',
      width: Dimen.Time,
      render: (_, { treatment }) => treatment.chargeOrRefundTime,
    },
    {
      title: '支付方式',
      dataIndex: 'payMethodName',
      width: Dimen.PayMethod,
      align: 'center',
      key: 'payMethodName',
      render: (_, t) => {
        return t.payMethodName
      },
    },
    {
      title: '发票类型',
      key: 'invoiceType',
      align: 'center',
      width: '8em',
      render: (_, t) => getInvoiceType(t.invoiceDetails.invoiceType),
    },
    {
      title: '发票号',
      key: 'invoiceNo',
      align: 'center',
      width: '8em',
      render: (_, t) => t.invoiceDetails.invoiceNum || '-',
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '12rem',
      fixed: 'right',
      render: function ShowAction(_, t): ReactElement {
        {
          return !YBZFUploadflag ? (
            <Space>
              {status === RecipeStatus.Settled && (
                <Button
                  size='small'
                  type='primary'
                  onClick={() => onAction('return', t.id, t)}
                >
                  退费
                </Button>
              )}
              {oweMoneyShow &&
                oweMoneyShow?.openOwe == 1 &&
                t.accountingStatus === 1 &&
                status === RecipeStatus.Settled && (
                  <Button
                    size='small'
                    type='primary'
                    onClick={() => onAction('supplementary', t.id, t)}
                  >
                    补交
                  </Button>
                )}

              <a onClick={() => onAction('detail', t.id, t)}>查看详情</a>
            </Space>
          ) : (
            <Button
              size='small'
              type='primary'
              onClick={() =>
                onAction(
                  t.treatment.zfUploadFlag === 0 ? 'upload' : 'repeal',
                  t.id,
                  t
                )
              }
            >
              {t.treatment.zfUploadFlag === 0 ? '上传' : '撤销'}
            </Button>
          )
        }
      },
    },
  ]
  return status === RecipeStatus.Settled
    ? colums
    : colums.filter(
        (v) =>
          v.key != 'invoiceType' &&
          v.key != 'invoiceNo' &&
          v.title != '欠费实收金额' &&
          v.title != '结算状态' &&
          v.key != 'payee'

      )
}
