/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-07 14:14:49
 * @LastEditors: sj
 * @LastEditTime: 2022-06-07 14:21:39
 */
import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface InfoQueryBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    pageNum?: number
    pageSize?: number
    treatmentId?: number
  }
}
// 自费病人就医费用明细查询4207
export function costInfo(bodyName: InfoQueryBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryOwnpayPatnFeeList_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 自费病人就医就诊信息查询4208
export function clinicInfo(bodyName: InfoQueryBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryOwnpayPatnMdtrtD_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 自费病人就医诊断信息查询4209
export function diagnoseInfo(bodyName: InfoQueryBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryOwnpayPatnDiseListD_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
