/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-20 11:21:05
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-01 11:07:06
 */
import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import {
  replaceMakeDetailParams,
  SupplierListResponse,
} from '../../../services/OReplaceMake'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../app/store'
import { getSupplierList } from '../OReplaceMakeSlice'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'

export const Query = (props: {
  onValueChange: (queries: replaceMakeDetailParams) => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [supplierOptions, setSupplierOptions] = useState<
    Array<SupplierListResponse>
  >([])

  useEffect(() => {
    dispatch(getSupplierList())
      .then(unwrapResult)
      .then((res: Array<SupplierListResponse>) => {
        setSupplierOptions(res)
      })
  }, [])

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(v) => {
        props.onValueChange({ ...v })
      }}
      style={{ paddingBottom: '20px' }}
    >
      <Row>
        <Space>
          <Form.Item name='status' noStyle>
            <Select
              allowClear
              placeholder='上传状态'
              onChange={form.submit}
              style={{ width: 200 }}
            >
              <Select.Option key='0'>未上传</Select.Option>
              <Select.Option key='1'>已上传</Select.Option>
              <Select.Option key='3'>已取消</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='state' noStyle>
            <Select
              allowClear
              placeholder='结算状态'
              onChange={form.submit}
              style={{ width: 200 }}
            >
              <Select.Option key='0'>已退费</Select.Option>
              <Select.Option key='1'>已结算</Select.Option>
            </Select>
          </Form.Item>
          <DateSelect
            style={{ width: '8.5rem' }}
            labelPrefix=''
            allowClear={false}
            namePrefix='time'
            placeholder='开单时间-时间'
            initialValue={DateRangeType.ThisMonth}
            onChange={form.submit}
          />
          <Button htmlType='submit' type='primary'>
            查询
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
