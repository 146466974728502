/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-15 11:18:17
 * @LastEditors: sj
 * @LastEditTime: 2022-12-06 19:52:32
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import * as S from '../../../services/toothPatient'

export interface PatientSliceType {
  groupList: any[]
  attendingDoctorList: any[] // 主治医生
  counselorList: any[] //咨询师
  activeObj: any
  patientInfo: any
  historyProvider: any[] // 历史加工单位
  patientManagementStatus: number
}

const initialState: PatientSliceType = {
  groupList: [],
  attendingDoctorList: [],
  counselorList: [],
  activeObj: {},
  patientInfo: {},
  historyProvider: [],
  patientManagementStatus: 0
}

export const PatientToothSlice = createSlice({
  name: 'PatientToothSlice',
  initialState,
  reducers: {
    setGroupList(state, { payload }) {
      state.groupList = payload
    },
    setAttendingDoctorList(state, { payload }) {
      state.attendingDoctorList = payload
    },
    setCounselorList(state, { payload }) {
      state.counselorList = payload
    },
    setActiveObj(state, { payload }) {
      state.activeObj = payload
    },
    setPatientInfo(state, { payload }) {
      state.patientInfo = payload
    },
    setHistoryProvider(state, { payload }) {
      state.historyProvider = payload
    },
    setPatientManagementStatus(state, { payload }) {
      state.patientManagementStatus = payload
    },
  },
})


// 获取患者详情
export const detailForPatientAsync = createAsyncThunk<
  void,
  S.detailForPatientParams,
  RootThunkApi<void>
>('toothPatient/detailForPatientAsync', async (id, api) => {
  return sendAsync(S.detailForPatient(id), api)
})

// 获取患者简略详情
export const getChangetreatmentAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('toothPatient/getChangetreatmentAsync', async (id, api) => {
  return sendAsync(S.getChangetreatment(id), api)
})

// 就诊信息列表
export const getRegistByPatientIdAsync = createAsyncThunk<
  void,
  S.RegistByPatientIdParams,
  RootThunkApi<void>
>('toothPatient/getRegistByPatientIdAsync', async (params, api) => {
  return sendAsync(S.getRegistByPatientId(params), api)
})

// 就诊信息详情
export const getPatientInfoAsync = createAsyncThunk<
  void,
  S.PatientInfoParams,
  RootThunkApi<void>
>('toothPatient/getPatientInfoAsync', async (params, api) => {
  return sendAsync(S.getPatientInfo(params), api)
})


// 就诊信息详情
export const getRegistByPatientIdGneralAsync = createAsyncThunk<
  void,
  S.RegistByPatientIdGneralParams,
  RootThunkApi<void>
>('toothPatient/getPatientInfoAsync', async (params, api) => {
  return sendAsync(S.getRegistByPatientIdGneral(params), api)
})

export const getAllTreatmentWithRecipeByPatientidAsync = createAsyncThunk<
  void,
  S.RegistByPatientIdGneralParams,
  RootThunkApi<void>
>(
  'toothPatient/getAllTreatmentWithRecipeByPatientidAsync',
  async (params, api) => {
    return sendAsync(S.getAllTreatmentWithRecipeByPatientid(params), api)
  }
)


// 最近
export const getPatientTodayAsync = createAsyncThunk<
  void,
  S.PatientTodayParams,
  RootThunkApi<void>
>('toothPatient/getPatientLatelyAsync', async (params, api) => {
  return sendAsync(S.getPatientToday(params), api)
})

// 最近就诊
export const getPatientLatelyAsync = createAsyncThunk<
  void,
  S.PatientLatelyParams,
  RootThunkApi<void>
>('toothPatient/getPatientLatelyAsync', async (params, api) => {
  return sendAsync(S.getPatientLately(params), api)
})

// 更新时间
export const updatePatientTimeAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('toothPatient/updatePatientTimeAsync', async (id, api) => {
  return sendAsync(S.updatePatientTime(id), api)
})

// 处置记录列表
export const getAllRecipeByPatientIdAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('toothPatient/getAllRecipeByPatientIdAsync', async (id, api) => {
  return sendAsync(S.getAllRecipeByPatientId(id), api)
})

// 处置记录详情
export const getRecipeItemByRecipeIdAsync = createAsyncThunk<
  void,
  S.RecipeItemByRecipeIdParams,
  RootThunkApi<void>
>('toothPatient/getRecipeItemByRecipeIdAsync', async (params, api) => {
  return sendAsync(S.getRecipeItemByRecipeId(params), api)
})

// 获取处置记录的处方
export const getAllRecipeByRegistrationIdAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('toothPatient/getAllRecipeByRegistrationIdAsync', async (id, api) => {
  return sendAsync(S.getAllRecipeByRegistrationId(id), api)
})

// 获取患者病历
export const getAllTreatmentByPatientidAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('toothPatient/getAllTreatmentByPatientidAsync', async (id, api) => {
  return sendAsync(S.getAllTreatmentByPatientid(id), api)
})

// 患者分组列表
export const getPatientGroupAsync = createAsyncThunk<
  void,
  void,
  RootThunkApi<void>
>('toothPatient/getPatientGroupAsync', async (_, api) => {
  return sendAsync(S.getPatientGroup(), api)
})

// 新增患者分组
export const addPatientGroupAsync = createAsyncThunk<
  void,
  S.AddPatientGroupParams,
  RootThunkApi<void>
>('toothPatient/addPatientGroupAsync', async (body, api) => {
  return sendAsync(S.addPatientGroup(body), api)
})


// 删除患者分组
export const removePatientGroupAsync = createAsyncThunk<
  void,
  S.RemovePatientGroupParams,
  RootThunkApi<void>
>('toothPatient/removePatientGroupAsync', async (body, api) => {
  return sendAsync(S.removePatientGroup(body), api)
})

// 删除患者
export const removePatientAsync = createAsyncThunk<
  void,
  S.RemovePatientGroupParams,
  RootThunkApi<void>
>('toothPatient/removePatientAsync', async (body, api) => {
  return sendAsync(S.removePatient(body), api)
})


// 全部分组列表
export const getPatientByGroupAsync = createAsyncThunk<
  void,
  S.PatientByGroupparams,
  RootThunkApi<void>
>('toothPatient/getPatientByGroupAsync', async (params, api) => {
  return sendAsync(S.getPatientByGroup(params), api)
})

// 加入分组
export const addGroupAsync = createAsyncThunk<
  void,
  S.EditGroupParams,
  RootThunkApi<void>
>('toothPatient/addGroupAsync', async (body, api) => {
  return sendAsync(S.addGroup(body), api)
})

// 修改分组
export const updateGroupAsync = createAsyncThunk<
  void,
  S.EditGroupParams,
  RootThunkApi<void>
>('toothPatient/updateGroupAsync', async (body, api) => {
  return sendAsync(S.updateGroup(body), api)
})

// 患者交接
export const patienHandoverAsync = createAsyncThunk<
  void,
  S.PatienHandoverParams,
  RootThunkApi<void>
>('toothPatient/patienHandoverAsync', async (body, api) => {
  return sendAsync(S.patienHandover(body), api)
})

// 亲友关系列表
export const patientrelationshipAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('toothPatient/patientrelationshipAsync', async (id, api) => {
  return sendAsync(S.patientrelationship(id), api)
})

// 亲友关系修改
export const addPatientrelationshipAsync = createAsyncThunk<
  void,
  S.AddPatientrelationshipParams,
  RootThunkApi<void>
>('toothPatient/addPatientrelationshipAsync', async (body, api) => {
  return sendAsync(S.addPatientrelationship(body), api)
})

// 外加工列表
export const externalprocessListAsync = createAsyncThunk<
  void,
  S.ExternalprocessPrams,
  RootThunkApi<void>
>('toothPatient/externalprocessListAsync', async (body, api) => {
  return sendAsync(S.externalprocessList(body), api)
})

// 新增外加工
export const externalprocessAddAsync = createAsyncThunk<
  void,
  S.ExternalprocessAddParams,
  RootThunkApi<void>
>('toothPatient/externalprocessAddAsync', async (body, api) => {
  return sendAsync(S.externalprocessAdd(body), api)
})

// 删除外加工
export const externalprocessRemoveAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('toothPatient/externalprocessRemoveAsync', async (id, api) => {
  return sendAsync(S.externalprocessRemove(id), api)
})

// 外加工详情
export const externalprocessDetailAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('toothPatient/externalprocessRemoveAsync', async (id, api) => {
  return sendAsync(S.externalprocessDetail(id), api)
})

// 外加工 关联处置
export const getRecipeItemsByPatientIdAsync = createAsyncThunk<
  void,
  S.tRecipeItemsByPatientIdParams,
  RootThunkApi<void>
>('toothPatient/getRecipeItemsByPatientIdAsync', async (params, api) => {
  return sendAsync(S.getRecipeItemsByPatientId(params), api)
})

// 历史加工单位
export const historyProviderAsync = createAsyncThunk<
  void,
  void,
  RootThunkApi<void>
>('toothPatient/historyProviderAsync', async (_, api) => {
  return sendAsync(S.historyProvider(), api)
})

// 约 弹窗信息
export const patientLastAppointmentInfo = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('toothPatient/PatientLastAppointmentInfo', async (params, api) => {
  return sendAsync(S.getPatientLastAppointmentInfo(params), api)
})


export const { setPatientInfo, setHistoryProvider, setGroupList, setAttendingDoctorList, setCounselorList, setActiveObj, setPatientManagementStatus } = PatientToothSlice.actions

export const selectGroupList = (state: RootState) =>
  state.patientTooth.groupList

export const selectAttendingDoctorList = (state: RootState) =>
  state.patientTooth.attendingDoctorList

export const selectCounselorList = (state: RootState) =>
  state.patientTooth.counselorList

export const selectActiveObj = (state: RootState) =>
  state.patientTooth.activeObj

export const selectPatientInfo = (state: RootState) =>
  state.patientTooth.patientInfo

export const selectHistoryProvider = (state: RootState) =>
  state.patientTooth.historyProvider

export const selectPatientManagementStatus = (state: RootState) =>
  state.patientTooth.patientManagementStatus


export default PatientToothSlice.reducer
