import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../../app/applicationSlice";
import { RootThunkApi } from "../../../app/store";
import { CompletedTreatmentList } from "../../../services/completedTreatment";
import { ToBeCompletedTreatmentParams } from "../../../services/toBeCompletedTreatment";

// 列表
export const getCompletedTreatmentList = createAsyncThunk<
  void,
  ToBeCompletedTreatmentParams,
  RootThunkApi<void>
>("medicalstation/completedTreatmentList", async (params, api) => {
  return sendAsync(CompletedTreatmentList(params), api);
});
