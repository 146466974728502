import { PlusOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  AutoComplete,
  Form,
  Input,
  Modal,
  ModalProps,
  notification,
  Select,
} from "antd";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../app/store";
import { TemplateCategory, TemplateDetail } from "../../models/template";
import { sleep } from "../../utils/SystemUtils";
import {
  createOrUpdateTemplateCategory,
  createOrUpdateTemplateDetail,
  getTemplateCategoryList,
} from "./templateSlice";

interface SaveTemplateModalProps {
  templates?:any
  medicalRecord?: number
  categoryName?: string
  detail?: TemplateDetail
  onSave?: (categoryId?: string) => void
  tooth?: any
  commonName?: any
}

export const SaveTemplateModal = ({
  templates,
  medicalRecord,
  categoryName,
  detail,
  visible,
  onCancel,
  onSave,
  tooth,
  commonName,
}: SaveTemplateModalProps & ModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [categories, setCategories] = useState<TemplateCategory[]>([])

  const ref = useRef<any>(null)

  useEffect(() => {
    if (visible) {
      dispatch(getTemplateCategoryList({ current: 1, size: 1000 }))
        .then(unwrapResult)
        .then((cs) => setCategories(cs))
      sleep(200).then(() => {
        ref.current?.focus()
      })
    } else {
      setCategories([])
    }
  }, [visible])

  useEffect(() => {
    const cName =
      commonName?.id == '-1' ? commonName.categoryName : categoryName
    form.setFieldsValue({
      categoryName: cName == '收藏' ? '' : cName,
      name: detail?.itemName || '',
    })
  }, [categoryName, detail, commonName])

  return (
    <Modal
      title='模版另存为'
      visible={visible}
      onCancel={onCancel}
      onOk={async () => {
        if (!detail) {
          return
        }
        try {
          const values = await form.validateFields()
          let categoryId = categories.find(
            (c) => c.categoryName === values.categoryName
          )?.id

          if (!categoryId) {
            categoryId = await dispatch(
              createOrUpdateTemplateCategory({
                id: '',
                categoryName: values.categoryName,
              })
            ).then(unwrapResult)
          }
          if (categoryId) {
            const toothData = tooth
              ? {
                  stomatologyTooth: tooth[0]
                    .map((v: any) =>
                      v.teethStr.map((item: any) => item || '--').join('@')
                    )
                    .join('#'),
                  inspectionTooth: tooth[1]
                    .map((v: any) =>
                      v.teethStr.map((item: any) => item || '--').join('@')
                    )
                    .join('#'),
                  diseaseTooth: tooth[2]
                    .map((v: any) =>
                      v.teethStr.map((item: any) => item || '--').join('@')
                    )
                    .join('#'),
                  treatmentTooth: tooth[3]
                    .map((v: any) =>
                      v.teethStr.map((item: any) => item || '--').join('@')
                    )
                    .join('#'),
                  disposeTooth: tooth[4]
                    .map((v: any) =>
                      v.teethStr.map((item: any) => item || '--').join('@')
                    )
                    .join('#'),
                  // 输入内容
                  stomatologyCheck: tooth[0]
                    .map((v: any) => v.Check)
                    .join('#%#'),
                  inspectionCheck:
                    medicalRecord === 3
                      ? tooth[1].map((v: any) => v.Check).join('#%#')
                      : detail.inspectionCheck,
                  treatmentPlan: tooth[3].map((v: any) => v.Check).join('#%#'),
                  disposeContent: tooth[4].map((v: any) => v.Check).join('#%#'),
                }
              : {}
            await dispatch(
              createOrUpdateTemplateDetail({
                ...detail,
                templateId: categoryId,
                itemName: values.name,
                ...toothData,
              })
            ).then(unwrapResult)
          }
          onSave && onSave(categoryId)
          notification.success({
            message: '模版保存成功',
          })
        } catch {
          notification.error({
            message: '已有此模板，请重新选择！',
          })
          // do nothing.
        }
      }}
    >
      <Form
        form={form}
        colon={false}
        autoComplete='off'
        labelCol={{ flex: '5rem' }}
        wrapperCol={{ flex: 1 }}
      >
        <Form.Item
          label='模版分类'
          name='categoryName'
          required
          rules={[{ required: true, message: '请输入模版分类名称' }]}
        >
          <Select placeholder='请选择模版分类'>
            {templates?.map((v:any) => {
              return (
                <Select.Option value={v?.categoryName} key={v?.categoryName}>
                  {v?.categoryName}
                </Select.Option>
              )
            })}
          </Select>
          {/* <AutoComplete
            // style={{ width: '300px', position: 'relative' }}
            disabled
            ref={ref}
            options={categories.map((c) => ({ value: c.categoryName }))}
            filterOption={(input, options: any) =>
              options?.value?.indexOf(input) >= 0
            }
          /> */}
        </Form.Item>
        {/* <span
          style={{ position: 'absolute', right: 26, top: 83, color: '#027AFF' }}
        >
          <PlusOutlined style={{ marginRight: '6px' }} />
          添加分类
        </span> */}
        <Form.Item
          label='模版名称'
          name='name'
          required
          rules={[{ required: true, message: '请输入模版名称' }]}
        >
          <Input maxLength={8} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
