import { Modal, message, Input, Form, Row, Col, Radio } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactElement } from "react";
import _, { divide } from "lodash";
import { GenderOptions, getGenderName } from "../../../models/user";
import { useDispatch, useSelector } from "react-redux";
import { selectDetail } from "./changeWaterSlice";
import {
  getStationOrderSourceNames,
} from "../../../models/changeWater";

export const ChargeWaterModel = (props: {
  state: any;
  isModalVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
}): ReactElement => {
  const [form] = Form.useForm();
  const detailList: any = useSelector(selectDetail);
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  useEffect(() => {
    if (detailList) {
      form.setFieldsValue({
        ...detailList,
        payMethod: detailList?.payMethodName,
        registrationCategory:
          detailList.registrationCategory === 0
            ? '自费'
            : detailList.registrationCategory === -1
            ? '-'
            : '医保',
        orderSource: getStationOrderSourceNames(detailList.orderSource),
        state:
          detailList.state === 3
            ? '结算支付'
            : detailList.state === 5
            ? '结算退费'
            : '',
      })
    }
  }, [detailList]);

  return (
    <Modal
      title='费用明细'
      width='1020px'
      okText='确定'
      visible={props.isModalVisible}
      footer={null}
      onOk={() => {
        props.onOk()
      }}
      onCancel={() => {
        props.onCancel()
      }}
    >
      <Form
        form={form}
        initialValues={{ remember: true }}
        name='advanced_search'
        className='ant-advanced-search-form'
        {...layout}
      >
        <p style={{ fontWeight: 700, fontSize: '16px' }}>就诊病人信息</p>
        <Row gutter={20}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item {...layout} label='姓名' name='patientName'>
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item label='结算险种' name='registrationCategory'>
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item {...layout} name='patientSex' label='性别'>
              <Radio.Group disabled>
                {GenderOptions.map((g) => (
                  <Radio key={g} value={g}>
                    {getGenderName(g)}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item label='就诊类别' name='orderSource'>
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item label='疾病名称' name='disease'>
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>
        </Row>

        <p style={{ fontWeight: 700, fontSize: '16px' }}>费用流水信息</p>
        <Row gutter={20}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item label='发生人员' name='patientName'>
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>

          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              style={{ display: 'flex' }}
              label='发生类型'
              name='state'
            >
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              style={{ display: 'flex' }}
              label='支付方式'
              name='payMethod'
            >
              <Input autoComplete='off' disabled value='payMethod' />
            </Form.Item>
          </Col>
          {props.state === 5 ? (
            ''
          ) : (
            <Col span={8} style={{ textAlign: 'right' }}>
              <Form.Item
                style={{ display: 'flex' }}
                label='支付时间'
                name='createTime'
              >
                <Input autoComplete='off' disabled />
              </Form.Item>
            </Col>
          )}

          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              style={{ display: 'flex' }}
              label='发生金额'
              name='realPayAmount'
            >
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>
          {props.state === 3 ? (
            ''
          ) : (
            <Col span={8} style={{ textAlign: 'right' }}>
              <Form.Item
                style={{ display: 'flex' }}
                label='退费时间'
                name='updateTime'
              >
                <Input autoComplete='off' disabled />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={20}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item
              style={{ display: 'flex' }}
              label='自费金额'
              name='shouldPayAmount'
            >
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>
          {/* <Col span={8} style={{ textAlign: "right" }}>
            <Form.Item style={{ display: "flex" }} label="记账金额" name="">
              <Input autoComplete="off" disabled />
            </Form.Item>
          </Col> */}
        </Row>
        <p style={{ fontWeight: 700, fontSize: '16px' }}>操作员信息</p>
        <Row gutter={20}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Form.Item label='操作人员' name='operateUserName'>
              <Input autoComplete='off' disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};
