export interface Patientlable {
  id?: string;
  labelId?: string;
  backgroundColor?: string;
  fontColor?: string;
  name: string;
  headName?: string;
  status?: number;
  tenantId?: number;
  labelType?: any;
  level?: any;
}

export function fromJson(json: any): Patientlable {
  return {
    id: json.id?.toString() || "",
    backgroundColor: json.backgroundColor,
    fontColor: json.fontColor,
    name: json.name,
    status: json.status,
    tenantId: json.tenantId,
    headName: json.headName,
    labelType: json.labelType,
    level: json.level,
    labelId: json.labelId?.toString() || "",
  };
}

export function toJson(patientlable: Patientlable): any {
  return {
    id: patientlable.id,
    backgroundColor: patientlable.backgroundColor,
    fontColor: patientlable.fontColor,
    name: patientlable.name,
    status: patientlable.status,
    tenantId: patientlable.tenantId,
    headName: patientlable.headName,
    labelType: patientlable.labelType,
    level: patientlable.level,
    // labelId:patientlable.labelId
  };
}
