/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 19:32:02
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-15 10:03:01
 */
import {
  Button,
  Col,
  Form,
  Modal,
  notification,
  Radio,
  Select,
  Space,
  Table,
  Popover,
  Row
} from 'antd'
import { QuestionCircleTwoTone } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  AutomaticSchedulingDetailAsync,
  AutomaticSchedulingSettingAsync,
} from './composingSlice'
import { RootDispatch } from '../../app/store'
import { useDispatch } from 'react-redux'

export const AutomaticSchedulingModal = (props: {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [radioValue, setRadioValue] = useState(1)

  const [detail, setDetail] = useState<any>()

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 14,
    },
  }

  useEffect(() => {
    if (props?.visible) {
      dispatch(AutomaticSchedulingDetailAsync())
        .then(unwrapResult)
        .then((v: any) => {
          if (JSON.stringify(v) != '{}') {
            setDetail(v)
            form.setFieldsValue({ day: v?.day, switchFlag: v?.switchFlag })
          } else {
            form.setFieldsValue({ switchFlag: 1 })
          }
        })
    }
  }, [props?.visible])

  return (
    <div>
      <Modal
        title='设置'
        visible={props.visible}
        onOk={(v) => {
          form.submit()
        }}
        onCancel={() => {
          form.resetFields()
          props.onCancel()
        }}
        width={570}
      >
        <Form
          form={form}
          onFinish={(v) => {
            dispatch(
              AutomaticSchedulingSettingAsync({
                day: v?.day,
                switchFlag: v?.switchFlag,
                autoSettingId: detail?.id ? detail?.id : '',
              })
            )
              .then(unwrapResult)
              .then((val) => {
                notification.success({
                  message: '设置成功',
                })
                form.resetFields()
                props.onOk()
              })
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name='day'
                label='排班时长'
                {...layout}
                required
                rules={[
                  {
                    required: true,
                    message: '请选择排班时长',
                  },
                ]}
              >
                <Select placeholder='时长选择' allowClear>
                  <Select.Option value={7} key={7}>
                    7天
                  </Select.Option>
                  <Select.Option value={30} key={30}>
                    30天
                  </Select.Option>
                  <Select.Option value={90} key={90}>
                    90天
                  </Select.Option>
                  <Select.Option value={180} key={180}>
                    180天
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={2} style={{ marginLeft: '10px',marginTop:"6px" }}>
              <Popover
                content={
                  <div>
                    <p>
                      设置排班时长后系统每天都会往后自动排一天，保证可预约的排班时长永远都是设置的天数
                    </p>
                  </div>
                }
                placement='bottom'
                title='说明'
              >
                <QuestionCircleTwoTone />
              </Popover>
            </Col>
          </Row>
          <Col span={12}>
            <Form.Item name='switchFlag' label='自动排班状态' {...layout}>
              <Radio.Group
              // defaultValue={1}
              // value={radioValue}
              // onChange={(e: any) => {
              //   setRadioValue(e.target.value)
              // }}
              >
                <Radio value={1}>开启</Radio>
                <Radio value={0}>关闭</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Form>
        <div style={{ marginLeft: '64px' }}>
          备注：开启后所有的状态为正常的医生、护士会按照机构营业时间排班
        </div>
      </Modal>
    </div>
  )
}
