/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 14:39:40
 */
import { Col, Modal } from 'antd'
import React, { ReactElement } from 'react'
import styles from './official.module.css'
import Qrcode from "qrcode.react";

interface officialQrCodeModalProps {
  qrCodeUrl:string
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
const changeCanvasToPic = (id: string, linkId: string, title: string) => {
  const canvasImg: any = document.getElementById(id) // 获取canvas类型的二维码
  const img = new Image()
  img.src = canvasImg.toDataURL('image/png') // 将canvas对象转换为图片的data url
  const downLink: any = document.getElementById(linkId)
  downLink.href = img.src
  downLink.download = title // 图片name
}
export const OfficialQrCodeModal = ({
  qrCodeUrl,
  visible,
  onCancel,
  onOk,
}: officialQrCodeModalProps): ReactElement => {
  return (
    <Modal
      title='公众号二维码'
      width={400}
      visible={visible}
      onCancel={() => {
        onCancel()
      }}
      onOk={() => {
        onOk()
      }}
      footer={null}
      destroyOnClose
      centered
      wrapClassName={styles.qrMessageModal}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <div className={styles.officialQrCode}>
        <img src={qrCodeUrl} width={220}/>
      </div>
      <Col className={styles.downLoad} >右键图片即可保存到本地</Col>
    </Modal>
  )
}
