import { Settinglable } from "../models/setting";
import { Tenant } from "../models/tenant";
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export function getSettingDetail(): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/tenant/detail`,
    method: "GET",
  };
}

export interface SettingMessageParams {
  tenant?: Tenant;
  functionList: Settinglable[];
}

export interface SettingTenantOneFunction {
  functionList: Settinglable[];
}
export function getSettingSave(body: 
  SettingMessageParams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/tenant/save`,
    method: "POST",
    body,
  };
}
export function getSettingOneSave(body:SettingTenantOneFunction
): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/tenant/save`,
    method: "POST",
    body,
  };
}
