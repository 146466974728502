/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-09-02 14:02:21
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-02 16:37:21
 */
import moment from 'moment'
import { BillingCategoryName } from './billingCategory'
import {
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
  DateTimeFormatSimplePrintCharge,
} from './datetime'

export function PrintZLJHBody(data: any) {
  const tenantName = data?.tenantName
  const userName = data?.userName
  const showFlagList = data?.showFlag
  const message = data?.message
  const treatmentTpsDetail = data?.dataList?.map((item: any, index: any) => {
    return {
      ...item,
      toothStructList: item?.toothStruct?.split('@')?.map((current: any) => {
        return current === '--' ? '' : current
      }),
    }
  }) as any
  const amount = data?.amount
  const codeImg = data?.codeImg

  let dataStr = ''

  // 一三象限值
  const getValue = (v: any) => {
    const data = v?.split(',').sort(function (a: any, b: any) {
      return b?.substr(0, 1) - a?.substr(0, 1)
    })
    const letters: any = data?.filter((element: any) => isNaN(element)) // 过滤出字母

    letters?.reverse() // 反转字母数组顺序
    const newData = data
      ?.map((element: any) => (isNaN(element) ? letters?.shift() : element))
      .join(',')
      ?.replace(/,/g, '')
      ?.replace('-', ',')
    // 将反转后的字母按顺序放回原数组
    return newData
  }

  // 二四象限值
  const getValues = (v: any) => {
    const newData = v?.replace(/,/g, '')?.replace('-', ',')
    // 将反转后的字母按顺序放回原数组
    return newData
  }

  // 病历号一维码
  let blhImg = ''
  if (showFlagList?.blhFlag == 'true') {
    if (codeImg) {
      blhImg =
        '<img src=' +
        codeImg +
        ' style="width:136px;height:56px;font-weight:bold;margin-left:20px" />'
    }
  } else {
    blhImg = ''
  }

  for (let j = 0; j < treatmentTpsDetail?.length; j++) {
    dataStr +=
      '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
      '<div style="width: 10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">' +
      (Number(j) + 1) +
      '</div>' +
      '<div style="width:25%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">' +
      '            <div style=" display: table-cell;width:90%;display:flex;justify-content: center;flex-direction: column;padding:10px">' +
      '                <div' +
      "                    style='font-size:12px;display:table;width:100%;clear: left;border-bottom:  1px solid #000;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;word-break:break-all;width: 44%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="tr">' +
      (treatmentTpsDetail[j]?.toothStructList?.[0]
        ? getValue(treatmentTpsDetail[j]?.toothStructList?.[0])
        : '') +
      '                        </div>' +
      "                        <div style='display: table-cell;word-break:break-all;width: 44%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="tl">' +
      (treatmentTpsDetail[j]?.toothStructList?.[1]
        ? getValues(treatmentTpsDetail[j]?.toothStructList?.[1])
        : '') +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      "                <div style='font-size:12px;display:table;width:100%;clear: left;height:30px;'>" +
      "                    <div style='display:table-row;'>" +
      "                        <div style='display: table-cell;word-break:break-all;width: 44%;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000'" +
      '                            class="lr">' +
      (treatmentTpsDetail[j]?.toothStructList?.[2]
        ? getValue(treatmentTpsDetail[j]?.toothStructList?.[2])
        : '') +
      '                        </div>' +
      "                        <div style='display: table-cell;word-break:break-all;width:44%;vertical-align: middle;text-align:center;box-sizing: border-box;'" +
      '                            class="ll">' +
      (treatmentTpsDetail[j]?.toothStructList?.[3]
        ? getValues(treatmentTpsDetail[j]?.toothStructList?.[3])
        : '') +
      '                        </div>' +
      '                    </div>' +
      '                </div>' +
      '            </div>' +
      '</div>' +
      '<div style="width:25%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">' +
      treatmentTpsDetail[j].name +
      '</div>' +
      '<div style="width:20%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">' +
      BillingCategoryName(treatmentTpsDetail[j].billingCategory) +
      '</div>' +
      '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">' +
      (treatmentTpsDetail[j].drugCount +
        (treatmentTpsDetail[j].splitTag
          ? treatmentTpsDetail[j].splitUnit
          : treatmentTpsDetail[j].accountUnit)) +
      '</div>' +
      '<div style="width:10%;display: table-cell;box-sizing: border-box;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">' +
      Number(treatmentTpsDetail[j]?.retailPrice)?.toFixed(5) +
      '</div>' +
      '</div>'
  }
  const contents =
    '<div style="padding: 20px 0 ;"> ' +
    '   <div style="margin: 5px 0">' +
    '   <div style="display:flex">' +
    '   <div style="display: flex;align-items: flex-end">' +
    blhImg +
    ' </div>' +
    ' <div style="flex:1;display: flex;justify-content:center;margin-right:100px">' +
    ' <div style="text-align: center;margin:0 20px;">' +
    '   <div style="padding: 10px 0;font-size: 20px;"></div><h3 style="text-align: center;margin-top: 0px;margin-bottom:6px;">' +
    tenantName +
    '</h3>' +
    '<h3 style="text-align: center;margin-top: 0px;margin-bottom: 10px;">' +
    '治疗计划' +
    '</h3>' +
    '</div>' +
    ' </div>' +
    ' </div>' +
    ' </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style="display:flex;font-size:13px">' +
    '        <div style=" display:flex;font-size:13px;width:33%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">客户姓名：' +
    '            </span>' +
    '            <span style="flex:1;min-height:20px">' +
    message?.patientName +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;width:33%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">治疗计划名：' +
    '            </span>' +
    '            <span style="flex:1;min-height:20px">' +
    message?.tpsName +
    '            </span>' +
    '        </div>' +
    '        <div style=" display:flex;font-size:13px;width:33%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">医生：' +
    '            </span>' +
    '            <span style="flex:1;min-height:20px">' +
    message?.performDoctorName +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style="display:flex;font-size:13px">' +
    '        <div style=" display:flex;font-size:13px;width:100%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">备注：' +
    '            </span>' +
    '            <span style="flex:1;min-height:20px">' +
    message?.comment +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="display: table;width: 100%;border-collapse: collapse;margin-bottom:5px;">' +
    '        <div style="display:flex;font-size:13px">' +
    '        <div style=" display:flex;font-size:13px;width:100%;margin-right:1%;display:flex;justify-content: flex-start;>' +
    '            <span style="height: 100%;width:15%">创建时间：' +
    '            </span>' +
    '            <span style="flex:1;min-height:20px">' +
    (message?.tpsTime
      ? moment(message?.tpsTime)?.format(DateTimeFormatSimpleOnly)
      : '-') +
    '            </span>' +
    '        </div>' +
    '        </div>' +
    '    </div>' +
    '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-top: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">序号' +
    '</div>' +
    '<div style="width:25%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">牙位' +
    '</div>' +
    '<div style="width:25%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">项目名称' +
    '</div>' +
    '<div style="width:20%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">项目类别' +
    '</div>' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">数量' +
    '</div>' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;font-size: 12px;word-break:break-all;vertical-align: middle;text-align: center;">单价' +
    '</div>' +
    '</div>' +
    dataStr +
    '      <div style="font-size: 12px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 88%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                总金额：' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;">' +
    '                ' +
    amount +
    '            </div>' +
    '        </div>' +
    '    <div>' +
    '<div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size:13px;margin-top:10px">' +
    '<div style="display: inline-block;width: 50%;text-align: left;">核对人:' +
    '</div>' +
    '<div style="display: inline-block;width: 50%;text-align: right;">制单日期: ' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</div>' +
    '</div>' +
    '</div>'
  return contents
}
