import {  TableColumnType } from "antd";
import { useDispatch } from "react-redux";
export const TreatCostDetailColumns = (startIndex: number): TableColumnType<any>[] => {
  const dispatch = useDispatch();
  return [
    {
      title: "序号",
      key: "no",
      align: "center",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
      width: "3rem",
    },
  {
    title: "项目名称",
    dataIndex: "name",
    align: "center",
    key: "no",
    
  },
  // {
  //   title: "医保属性",
  //   dataIndex: "personTime",
  //   align: "center",
  // },
  {
    title: "开票项目",
    dataIndex: "billingCategory",
    align: "center",
    key: "billingCategory",
  },
 
  {
    title: "数量",
    dataIndex: "count",
    align: "center",
    key: "count",
  },
  {
    title: "单位",
    dataIndex: "accountUnit",
    align: "center",
    key: "accountUnit",
  },
  {
    title: "均价(元)",
    dataIndex: "averagePrice",
    align: "right",
    key: "averagePrice",
  },
  {
    title: "金额(元)",
    dataIndex: "retail",
    align: "right",
    key: "retail",
  },
  {
    title: "开单医生",
    dataIndex: "treatmentDoctorName",
    align: "center",
    key: "treatmentDoctorName",
  },
  {
    title: "业务日期",
    dataIndex: "createTime",
      align: "center",
      key: "createTime",
      render: (_, t) => {
        const createTime = (t.createTime).substring(0, 10);
        return createTime;
      },
    }
  ];
};
