/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-06-16 10:09:37
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface GetDoctorSaleEntriesParams {
  doctorId?: string
  timeHead?: string
  timeTail?: string
}
export interface GetDoctorAuditEntriesParams {
  doctorId?: string
  billingCategory?: string
  drugType?: number
  timeHead?: string
  timeTail?: string
}
export interface GetDrugCostEntriesParams {
  timeHead?: string
  timeTail?: string
}
export function getDrugCostEntries(params: GetDrugCostEntriesParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/doctorDrugCostSale`,
    method: 'GET',
    params,
  }
}

export function getDrugCostsExport(
  params: GetDoctorAuditEntriesParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/doctorDrugCostSale/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
