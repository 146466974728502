import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface GetDoctorAuditEntriesParams {
  doctorId?: string;
  billingCategory?: string;
  drugType?: number;
  timeHead?: string;
  timeTail?: string;
}

export function getDoctorAuditEntries(
  params: GetDoctorAuditEntriesParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/doctorAccountingList`,
    method: "GET",
    params,
  };
}

export function getDoctorAuditExport(
  params: GetDoctorAuditEntriesParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/doctorAccountingList/exportexcel`,
    method: "GET",
    responseType: "blob",
    params,
  };
}
