/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-07 14:13:16
 * @LastEditors: sj
 * @LastEditTime: 2022-06-07 14:24:09
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import * as S from '../../services/selfPayUpload'

// 自费病人就医费用明细查询4207
export const costInfoAsync = createAsyncThunk<
  string,
  S.InfoQueryBody,
  RootThunkApi
>('/selfPayUpload/costInfoAsync', async (body, api) => {
  return sendAsync(S.costInfo(body), api)
})

// 自费病人就医就诊信息查询4208
export const clinicInfoAsync = createAsyncThunk<
  string,
  S.InfoQueryBody,
  RootThunkApi
>('/selfPayUpload/clinicInfoAsync', async (body, api) => {
  return sendAsync(S.clinicInfo(body), api)
})

// 自费病人就医诊断信息查询4209
export const diagnoseInfoAsync = createAsyncThunk<
  string,
  S.InfoQueryBody,
  RootThunkApi
>('/selfPayUpload/diagnoseInfoAsync', async (body, api) => {
  return sendAsync(S.diagnoseInfo(body), api)
})
