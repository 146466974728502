import { unwrapResult } from "@reduxjs/toolkit";
import {Button, Col, Form, Row, Select, Space, Table, notification, Popover} from "antd";
import React, { ReactElement, useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTenantName,
  selectUserName,
} from "../../../app/applicationSlice";
import { RootDispatch } from "../../../app/store";
import { DateRangeType, DateSelect, TJFXTime } from "../../../compnents/form/DateSelect";
import { StaffCostEntry } from "../../../models/peopleSales";
import { ThemeContext } from "../../../theme/ThemeContext";
import styles from "./PeopleSales.module.css";
import {
  getStaffCostEntries,
  selectGetStaffCostEntriesParams,
  setGetStaffCostEntriesParam,
  getPeopleSaleExport
} from "./peopleSaleSlice";

import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'

import { DoctorSaleEntry, DoctorSalesResult } from '../../../models/doctorSales'
import {QuestionCircleTwoTone} from "@ant-design/icons";

interface PeopleSalesProps {
  doctorId?: string;
}

export const PeopleSales = ({ doctorId }: PeopleSalesProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const theme = useContext(ThemeContext);

  const [form] = Form.useForm();

  const name = useSelector(selectTenantName);

  const doctorName = useSelector(selectUserName);

  const params = useSelector(selectGetStaffCostEntriesParams);

  const [entries, setEntries] = useState<StaffCostEntry[]>([]);

  const [message, setMessage] = useState("");

  const printRef = useRef<PrintFrameRef>(null);

  const [result, setResult] = useState({
    headers: [],
    entries: [],
  } as DoctorSalesResult)

  const [pageLoading, setPageLoading] = useState(false)   //表格 loading

  useEffect(() => {
    try {
      setPageLoading(true)
      dispatch(
        getStaffCostEntries(
          doctorId ? { ...params, doctorId, queryType: "1" } : params
        )
      )
        .then(unwrapResult)
        .then(([message, entries]) => {
          setEntries(entries);
          setMessage(message);
        })
        .finally(() => setPageLoading(false))
    } catch {
      // do nothing.
    }
  }, [params]);

  useEffect(() => {
    form.setFieldsValue(params);
  }, []);

  const Hint = (title: string) => (
    <>
      {title}
      <Popover placement="top" content="人次 = 未退费交易的笔数（一个患者当天多次结算人次为1）" >
        <QuestionCircleTwoTone style={{ marginLeft: '5px' }} />
      </Popover >
    </>
  );
  const HintHead = (title: string) => (
    <>
      {title}
      <Popover placement="top" content="人头 = 未退费交易的人头数" >
        <QuestionCircleTwoTone style={{ marginLeft: '5px' }} />
      </Popover >
    </>
  );

  const table = (type = false) => (<>
    <Table<StaffCostEntry>
      bordered
      className={type ? styles.tables : styles.table}
      loading={pageLoading}
      rowClassName={(r) =>
        r.name === '总合计'
          ? styles.total
          : r.cbxz === '合计'
          ? styles.highlight
          : ''
      }
      // rowClassName={(r:any) => (r.name?.includes("合计")  ? styles.total : "")}
      pagination={false}
      rowKey={(_, i) => `${i}`}
      columns={[
        {
          title: "名称",
          dataIndex: "name",
          align: "center",
          render: (_, e:any, i) => {
            let span = 0
            if (
              i === 0 ||
              entries[i - 1].id !==
                e.id
            ) {
              for (let j = i; j < entries.length; ++j) {
                if (
                  entries[j].id ===
                  e.id
                ) {
                  ++span
                } else {
                  break
                }
              }
            }
            return {
              props: {
                colSpan: 1,
                rowSpan: span,
                style: {
                  whiteSpace: 'nowrap',
                },
              },
              children: e.name ,
            }
          },
        },
        {
          title: "医保小险种",
          dataIndex: "cbxz",
          align: "center",
          render: (_, e) => ({
            props: {
              colSpan: 1,
              style: {
                whiteSpace: 'nowrap',
                color: theme.tc1,
                fontWeight: 'bold',
                padding: '0',
              },
            },
            children:
              e.cbxz 
          }),
        },
        {
          title: Hint('人次'),
          dataIndex: "personTime",
          align: "center",
        },
        {
          title: HintHead('人头'),
          dataIndex: "headCount",
          align: "center",
        },
        {
          title: "人次人头比",
          dataIndex: "personHeadScale",
          align: "center",
        },
        {
          title: "医保总金额",
          dataIndex: "totalInsurancePayAmount",
          align: "right",
          render: (_, r) => r.totalInsurancePayAmount?.toFixed(2),
        },
          {
              title: "报销总金额",
              dataIndex: "totalBaoxiao",
              align: "right",
              render: (_, r) => r.totalBaoxiao?.toFixed(2),
          },
          {
              title: "统筹基金",
              dataIndex: "totalJijin",
              align: "right",
              render: (_, r) => r.totalJijin?.toFixed(2),
          },
          {
              title: "当年账户支出",
              dataIndex: "totalDangnianzh",
              align: "right",
              render: (_, r) => r.totalDangnianzh?.toFixed(2),
          },
        {
          title: "总金额(元)",
          dataIndex: "totalRetail",
          align: "right",
          render: (_, r) => r.totalRetail?.toFixed(2),
        },
          {
              title: "人均基金",
              dataIndex: "totalJijinPer",
              align: "right",
              render: (_, r) => r.totalJijinPer?.toFixed(2),
          },
        {
          title: "平均值(人次)",
          dataIndex: "personTimeAvgAmount",
          align: "center",
          render: (_, r) => r.personTimeAvgAmount?.toFixed(1),
        },
        {
          title: "平均值(人头)",
          dataIndex: "headCountAvgAmount",
          align: "center",
          render: (_, r) => r.headCountAvgAmount?.toFixed(1),
        },
      ]}
      dataSource={entries}
    />
    {!doctorId && (
      <div
        style={{ marginTop: 10, fontSize: "0.875rem", color: theme.tc2 }}
      >
        {message}
      </div>
    )}
  </>)
  const outClued = () => {
    dispatch(getPeopleSaleExport(params))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res;
        const blob = new Blob([content]);
        const fileName = "医保人均费用.xlsx";
        const selfURL = window[window.webkitURL ? "webkitURL" : "URL"];
        const elink = document.createElement("a");
        if ("download" in elink) {
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = selfURL["createObjectURL"](blob);
          document.body.appendChild(elink);
          // 触发链接
          elink.click();
          selfURL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        } else {
          (navigator as any).msSaveBlob(blob, fileName);
        }
      })
      .then(() => {
        notification.success({
          message: "导出成功",
          duration: 3,
        });
      })
      .catch((res: any) => {
        return
      });
  };
  return (
    <Col
      style={{
        height: "calc(100% - 30px)",
        padding: "0 15px",
        borderRadius: theme.br,
        backgroundColor: theme.pn,
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Form
        form={form}
        onFinish={(values) =>
          dispatch(setGetStaffCostEntriesParam({ ...params, ...values }))
        }
      >
        <Row
          style={{ marginBottom: 15, marginTop: 15 }}
          align="middle"
          justify="space-between"
        >
          <Space>
            {!doctorId && (
              <Form.Item name="queryType" noStyle>
                <Select
                  showSearch
                  style={{ width: "200px" }}
                  onChange={form.submit}
                >
                  <Select.Option value="0">租户</Select.Option>
                  <Select.Option value="1">开单医生</Select.Option>
                  <Select.Option value="2">科室</Select.Option>
                </Select>
              </Form.Item>
            )}
            <DateSelect
              style={{ width: "8.5rem" }}
              labelPrefix=""
              allowClear={false}
              namePrefix="time"
              options={TJFXTime}
              placeholder="日期范围-全部"
              initialValue={DateRangeType.Today}
              onChange={() => form.submit()}
            />
            <Form.Item name="yiliaolb" noStyle>
                <Select
                    showSearch
                    style={{ width: "200px" }}
                    onChange={form.submit}
                    placeholder='医疗类别'
                    allowClear
                >
                    <Select.Option value="0">全部</Select.Option>
                    <Select.Option value="11">普通门诊（11）</Select.Option>
                    <Select.Option value="140104">门诊慢病（140104）</Select.Option>
                    <Select.Option value="140201">门诊特病（140201）</Select.Option>
                    <Select.Option value="14">门诊慢特病（14）</Select.Option>

                </Select>
            </Form.Item>
              <Form.Item name="yidibz" noStyle>
                  <Select
                      showSearch
                      style={{ width: "200px" }}
                      onChange={form.submit}
                      placeholder='险种类型'
                      allowClear
                  >
                      <Select.Option value={''}>全部</Select.Option>
                      <Select.Option value={'0'}>本地</Select.Option>
                      <Select.Option value={'1'}>省内异地</Select.Option>
                      <Select.Option value={'2'}>省外异地</Select.Option>
                      <Select.Option value={'3'}>省医保</Select.Option>
                  </Select>
              </Form.Item>
          </Space>


          <Space>
            <Button type="primary" onClick={outClued}>
              导出
            </Button>
            <Button type="primary" onClick={form.submit}>
            查询
          </Button>
            <Button type="primary" onClick={() => printRef.current?.print()}>
              打印
            </Button>
          </Space>
        </Row>
      </Form>
      <div
        style={{
          textAlign: "center",
          color: theme.tc1,
          fontWeight: "bold",
          fontSize: "1.5rem",
          marginBottom: 10,
        }}
      >
        {doctorId ? doctorName : name}-医保人均费用
      </div>
      <Col style={{ flex: 1, overflow: "auto" }}>
        {/* <Table<StaffCostEntry>
          className={styles.table}
          rowClassName={(r) => (r.name === "合计" ? styles.total : "")}
          pagination={false}
          rowKey={(_, i) => `${i}`}
          columns={Columns}
          dataSource={entries}
        />
        {!doctorId && (
          <div
            style={{ marginTop: 10, fontSize: "0.875rem", color: theme.tc2 }}
          >
            {message}
          </div>
        )} */}
        {table()}
      </Col>
      <PrintFrame ref={printRef} title={JSON.parse(localStorage.getItem("credentials") as string)?.tenantName} subTitle="医保人均费用">
        <Row style={{ marginBottom: "10px", lineHeight: "32px" }}></Row>
        {table(true)}
      </PrintFrame>
    </Col>
  );
};
