import { unwrapResult } from '@reduxjs/toolkit'
import { Card, Col, Row } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { selectInsuranceArray } from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import {
  EditOutlined,
  FemaleFilled,
  MaleFilled,
} from '../../compnents/icons/Icons'
import { useSelector } from 'react-redux'
import { Patient } from '../../models/patient'
import { Patientlable } from '../../models/patientlable'
import { Gender, getGenderName } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import { getAge } from '../../utils/StringUtils'
import { PatientModal } from './modals/PatientModal'
import {
  getPatientDetailInfoAsync,
  getPatientLabelList,
} from './modals/patientSlice'
import styles from './PatientDetailPanel.module.css'
import { getPatientDetail } from './patientSlice'

export interface PatientDetailPanelRef {
  reload: () => void
}
interface PatientDetailProps {
  patientId?: string
  registrationId?: string
  treatmentId?: string
  style?: React.CSSProperties
  editable?: boolean
  onChange?: (patient?: Patient) => void
}

const RealPatientDetailPanel = (
  {
    patientId,
    registrationId,
    treatmentId,
    style,
    editable = true,
    onChange,
  }: PatientDetailProps,
  ref: React.Ref<PatientDetailPanelRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()
  const theme = useContext(ThemeContext)

  const insuranceArray:any = useSelector(selectInsuranceArray)

  const [patient, setPatient] = useState<Patient | undefined>()

  const [labels, setLabels] = useState<Patientlable[]>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  useImperativeHandle(ref, () => ({
    reload: () => {
      if (patientId) {
        dispatch(getPatientDetailInfoAsync({ id: patientId,insuranceCode:insuranceArray?.[0]?.insuranceCode }))
          .then(unwrapResult)
          .then((res: any) => {
            setPatient(res)
            setLabels(res.labelVo)
          })
        // dispatch(getPatientDetail(patientId))
        //   .then(unwrapResult)
        //   .then((p) => setPatient(p))
        //   .catch(() => {
        //     // do nothing.
        //   });
        // dispatch(getPatientLabelList(patientId))
        //   .then(unwrapResult)
        //   .then((ls) => setLabels(ls))
        //   .catch(() => {
        //     // do nothing.
        //   });
      }
    },
  }))

  useEffect(() => {
    if (patientId) {
      // dispatch(getPatientDetail(patientId))
      //   .then(unwrapResult)
      //   .then((p) => setPatient(p))
      //   .catch(() => {
      //     // do nothing.
      //   });
      // dispatch(getPatientLabelList(patientId))
      //   .then(unwrapResult)
      //   .then((ls) => setLabels(ls))
      //   .catch(() => {
      //     // do nothing.
      //   });
      dispatch(getPatientDetailInfoAsync({ id: patientId,insuranceCode:insuranceArray?.[0]?.insuranceCode }))
        .then(unwrapResult)
        .then((res: any) => {
          setPatient(res)
          setLabels(res.labelVo)
        })
    }
  }, [patientId])

  useEffect(() => {
    onChange && onChange(patient)
  }, [patient])

  const cardTitleStyle = {
    color: theme.tc1,
    fontSize: '1rem',
    paddingLeft: 20,
    backgroundColor: theme.c3,
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    borderLeft: `2px solid ${theme.c1}`,
  }

  return (
    <Col
      style={{
        ...style,
        backgroundColor: theme.pn,
        borderRadius: theme.br,
        boxSizing: 'border-box',
        padding: 10,
        overflow: 'auto',
        height: '100%',
      }}
    >
      <Row align='middle' style={{ marginRight: 10 }} wrap={false}>
        <Col
          style={{
            width: '2.25rem',
            height: '2.25rem',
            fill: theme.tt,
            backgroundColor: theme.c1,
            borderRadius: 999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
          }}
        >
          {patient?.sex === Gender.Female ? <FemaleFilled /> : <MaleFilled />}
        </Col>
        <Col flex={1} style={{ paddingLeft: 10, overflow: 'hidden' }}>
          <div
            style={{
              width: '100%',
              color: theme.tc1,
              fontSize: '1.25rem',
              fontWeight: 'bold',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {patient?.name}
          </div>
          <div>病历号：{patient?.id}</div>
          <div style={{ fontSize: '1rem', color: theme.tc2 }}>
            {patient &&
              `(${getGenderName(patient.sex)}, ${getAge(
                patient.ageYear,
                patient.ageMonth,
                '岁'
              )})`}
          </div>
        </Col>
        {editable && (
          <Col
            style={{
              width: '1rem',
              height: '1rem',
              fill: theme.tc2,
              cursor: 'pointer',
            }}
            onClick={() => setIsModalVisible(true)}
          >
            <EditOutlined
              mode='small'
              onClick={(e) => {
                e.preventDefault()
                setIsModalVisible(true)
              }}
            />
          </Col>
        )}
      </Row>

      <Card
        title={<div style={cardTitleStyle}>基本信息</div>}
        className={styles.card}
        style={{ marginTop: 20 }}
      >
        <Col>身高：{patient?.height && patient?.height + 'cm'}</Col>
        <Col>体重：{patient?.weight && patient?.weight + 'KG'}</Col>
        <Col>联系方式：{patient?.phone}</Col>
      </Card>
      <Card
        title={<div style={cardTitleStyle}>就诊数据</div>}
        className={styles.card}
      >
        <Col>门诊：{(patient?.outpatientNum || 0) + '次'}</Col>
        <Col>手术：{(patient?.operationNum || 0) + '次'}</Col>
        <Col>
          消费：
          {((patient?.totalPayAmount &&
            patient?.totalPayAmount > 0 &&
            patient?.totalPayAmount) ||
            '0') + '元'}
        </Col>
        <Col>
          最近就诊日期：
          {patient?.treatmentTime
            ? moment(patient?.treatmentTime).format('YYYY/MM/DD')
            : '-'}
        </Col>
        <Col style={{ color: '#F00' }}>
          欠费金额：{(patient?.arrearageAmount || 0) + '元'}
        </Col>
      </Card>
      {/* <Card
        title={<div style={cardTitleStyle}>工作单位</div>}
        className={styles.card}
      >
        {patient?.companyName}
      </Card>
      <Card
        title={<div style={cardTitleStyle}>地址</div>}
        className={styles.card}
      >
        {patient?.address}
      </Card>
      <Card
        title={<div style={cardTitleStyle}>紧急联系人</div>}
        className={styles.card}
      >
        {patient?.emergencyContactName}
      </Card>
      <Card
        title={<div style={cardTitleStyle}>紧急联系方式</div>}
        className={styles.card}
      >
        {patient?.emergencyContactPhone}
      </Card> */}
      <Card
        title={
          <Row style={cardTitleStyle} align='middle'>
            <div>标签</div>
            <EditOutlined
              mode='small'
              style={{ marginLeft: 10, cursor: 'pointer', fill: theme.tc3 }}
              onClick={(e) => {
                e.preventDefault()
                setIsModalVisible(true)
              }}
            />
          </Row>
        }
        className={styles.card}
      >
        <Row gutter={15}>
          {labels?.map((l) => (
            <Col key={l.id} style={{ marginBottom: 10 }}>
              <div
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                  lineHeight: '1.5rem',
                  fontSize: '0.75rem',
                  color: l.fontColor,
                  backgroundColor: l.backgroundColor,
                  borderRadius: '2rem',
                  textAlign: 'center',
                  border: `1px solid ${theme.wbd}`,
                }}
              >
                {l.headName}
              </div>
            </Col>
          ))}
        </Row>
      </Card>
      <PatientModal
        patient={patient}
        registrationId={registrationId}
        treatmentId={treatmentId}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        onChange={(patient) => setPatient(patient)}
        onLabelChange={() =>
          patientId &&
          // dispatch(getPatientLabelList(patientId))
          //   .then(unwrapResult)
          //   .then((ls) => setLabels(ls))
          //   .catch(() => {
          //     // do nothing.
          //   })
          dispatch(getPatientDetailInfoAsync({ id: patientId,insuranceCode:insuranceArray?.[0]?.insuranceCode }))
            .then(unwrapResult)
            .then((res: any) => {
              setPatient(res)
              setLabels(res.labelVo)
            })
        }
      />
    </Col>
  )
}

export const PatientDetailPanel = forwardRef(RealPatientDetailPanel)
