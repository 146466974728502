/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-10 14:57:46
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-06 10:36:54
 */
import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  BillingCategories,
  BillingCategoryName,
  InvoiceBillingCategories,
} from "../../../models/billingCategory";
import { useDispatch } from "react-redux";
import { supplierParams } from "../../../services/supplier";
import { setModalCurrent } from "./InventStockModalSlice";
import {
  getMaterialsCategoryName,
  MaterialsCategories,
} from "../../../models/commodity";
const { Option } = Select;

export const InventModalQuery = (props: {
  onValueChange: (queries: supplierParams) => void;
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  return (
    <Form
      form={form}
      name="control-hooks"
      onFieldsChange={(_, fs) => {
        dispatch(setModalCurrent(1));
        props.onValueChange(form.getFieldsValue());
      }}
    >
      <Row gutter={11}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              placeholder="请输入药品名称进行检索"
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="billingCategory">
            <Select placeholder="开票项目" allowClear>
              {InvoiceBillingCategories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {BillingCategoryName(v)}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="materialsCategory">
            <Select
              allowClear
              placeholder="物资类别"
              // mode="multiple"
            >
              {MaterialsCategories.map((v) => (
                <Option value={v} key={v}>
                  {getMaterialsCategoryName(v)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2} offset={8}>
          <Button htmlType="submit" type="primary" style={{ float: "right" }}>
            查询
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
