/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-09 15:36:50
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-28 10:10:43
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Row, Space, Table } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTenantName } from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { ThemeContext } from '../../theme/ThemeContext'
import { medicalOverviewAsync } from './medicalOverviewSlice'
import styles from './medicalOverview.module.css'
import { medicalOverviewPrintBody } from '../../models/medicalOverviewPrint'
import { PrintFrame, PrintFrameRef } from '../print/PrintFrame'
import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'

export const MedicalOverview = (props: {
  registrations?: any
  activeKey?: any
}): ReactElement => {
  const theme = useContext(ThemeContext)

  const printRef = useRef<PrintFrameRef>(null)

  const dispatch = useDispatch<RootDispatch>()

  const tenantName = useSelector(selectTenantName)

  const [page, setPage] = useState<any>([])

  const [data, setData] = useState([])

  const [pageLoading, setPageLoading] = useState(false)

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      medicalOverviewAsync({
        patientId: props?.registrations?.patientId,
        stateFlag: 1,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        // const data = res?.map((v: any, i: any) => {
        //   return v?.recipeItems?.map((i: any) => {
        //     return {
        //       ...i,
        //       amount: v?.amount,
        //       insuranceCode: v?.insuranceCode,
        //       patientName: v?.patientName,
        //       registrationDoctorId: v?.registrationDoctorId,
        //       registrationDoctorName: v?.registrationDoctorName,
        //       registrationId: v?.registrationId,
        //       registrationTime: v?.registrationTime,
        //       treatmentDoctorId: v?.treatmentDoctorId,
        //       treatmentDoctorName: v?.treatmentDoctorName,
        //       treatmentId: v?.treatmentDoctorName,
        //     }
        //   })
        // })

        setPage(res)
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (props?.activeKey == 'medicalOverview') {
      getPage()
    }
  }, [props?.activeKey, props?.registrations])

  const dataMainList = {
    tenantName: tenantName?.split('-')?.[0],
    data: page,
    local: {
      name: props?.registrations?.patientName,
    },
  }

  const content = medicalOverviewPrintBody(dataMainList)

  return (
    <>
      <Col
        style={{
          backgroundColor: theme.pn,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 10px)',
          marginTop: '10px',
          overflow: 'hidden',
          padding: '10px 15px 0 15px',
        }}
      >
        <Space
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          }}
        >
          <Button
            type='primary'
            onClick={() => {
              // const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
              // page.document.write(content) // 写入打印页面的内容
              // page.print() // 打印
              printRef.current?.print()
            }}
          >
            打印
          </Button>
        </Space>
        <Row
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          }}
        >
          <Col span={12}>定点单位：{tenantName}</Col>
          <Col span={12}>姓名：{props?.registrations?.patientName}</Col>
        </Row>
        <div className={styles.medicalOverviewContents}>
          <table className={styles.medicalOverviewTable}>
            <thead style={{ height: '40px' }}>
              <tr>
                <th>就诊信息</th>
                <th>用药/治疗</th>
                <th>规格</th>
                <th>剂型</th>
                <th>单位</th>
                <th>数量</th>
                <th>频次</th>
                <th>用法</th>
                <th>每次用量</th>
              </tr>
            </thead>
            <tbody className={styles.medicalOverviewContentScroll}>
              {page?.map((t: any, i: number) => {
                return (
                  <tr key={i}>
                    <td style={{ width: '20%' }}>
                      <div style={{ textAlign: 'left', padding: '10px' }}>
                        <div>就诊日期 ： {t?.registrationTime}</div>
                        <div>挂号医生 ：{t?.registrationDoctorName}</div>
                        <div>接诊医生 ： {t?.treatmentDoctorName}</div>
                        <div>疾病名称 ： {t?.disease}</div>
                        <div>费用金额 ： {t?.amount}</div>
                      </div>
                    </td>
                    <td style={{ width: '20%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v?.name}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '20%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v?.packSpec ? v?.packSpec : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '6%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v?.doseCategory ? v?.doseCategory : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '6%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v.accountUnit ? v.accountUnit : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '6%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v.drugCount ? v.drugCount : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '7%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v.normalFrequency ? v.normalFrequency : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '7%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v.normalUsemethod ? v.normalUsemethod : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '8%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v.normalDose > 0 ? v.normalDose : '-'}
                              {v?.doseUnit}
                            </div>
                          </>
                        )
                      })}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Col>
      <PrintFrame ref={printRef} title='就诊总览'>
        <Row style={{ margin: '20px 0 ' }}>
          <Col span={12}>定点单位：{tenantName}</Col>
          <Col span={12}>姓名：{props?.registrations?.patientName}</Col>
        </Row>
        <div style={{ height: '100%' }}>
          <table className={styles.medicalOverviewTable}>
            <thead style={{ height: '40px' }}>
              <tr>
                <th>就诊信息</th>
                <th>用药/治疗</th>
                <th>规格</th>
                <th>剂型</th>
                <th>单位</th>
                <th>数量</th>
                <th>频次</th>
                <th>用法</th>
                <th>每次用量</th>
              </tr>
            </thead>
            <tbody className={styles.medicalOverviewContentScroll}>
              {page?.map((t: any, i: number) => {
                return (
                  <tr key={i}>
                    <td style={{ width: '20%' }}>
                      <div style={{ textAlign: 'left', padding: '10px' }}>
                        <div>就诊日期 ： {t?.registrationTime}</div>
                        <div>挂号医生 ：{t?.registrationDoctorName}</div>
                        <div>接诊医生 ： {t?.treatmentDoctorName}</div>
                        <div>疾病名称 ： {t?.disease}</div>
                        <div>费用金额 ： {t?.amount}</div>
                      </div>
                    </td>
                    <td style={{ width: '20%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v?.name}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '20%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v?.packSpec ? v?.packSpec : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '6%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v?.doseCategory ? v?.doseCategory : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '6%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v.accountUnit ? v.accountUnit : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '6%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v.drugCount ? v.drugCount : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '7%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v.normalFrequency ? v.normalFrequency : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '7%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v.normalUsemethod ? v.normalUsemethod : '-'}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td style={{ width: '8%' }}>
                      {t?.recipeItems?.map((v: any) => {
                        return (
                          <>
                            <div className={styles.tableRightBottom}>
                              {v.normalDose > 0 ? v.normalDose : '-'}
                              {v?.doseUnit}
                            </div>
                          </>
                        )
                      })}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <Row
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Col
            span={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            制表日期：{moment().format(DateTimeFormatSimpleOnlyOne)}
          </Col>
        </Row>
      </PrintFrame>
    </>
  )
}
