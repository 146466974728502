/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-06-16 11:37:24
 */
import { Modelment } from '../models/list'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface GetDoctorSaleEntriesParams {
  doctorId?: string
  timeHead?: string
  timeTail?: string
}
export interface statementParams {
  id?: number
  current?: number
  alipayAmount?: number
  buyOnCreditAmount?: number
  cashAmount?: number
  checkoutTime?: string
  medicalInsuranceSettlementAmount?: number
  membershipCardPaymentAmount?: number
  operateUserId?: number
  operateUserName?: string
  otherPaymentAmount?: number
  payBuyOnCreditAmount?: number
  selfPaymentAmount?: number
  status?: string
  tenantId?: number
  totalAmount?: number
  wechatAmount?: number
  size?: number
  total?: number
  timeHead?: string
  timeTail?: string
}
export function getStatementList(params: statementParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/dailyStatementList`,
    method: 'GET',
    params,
  }
}

export interface TimeParams {
  timeHead?: string
  timeTail?: string
  startTime?: string
  endTime?: string
}

export function getStatementState(params: TimeParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/manualDaily`,
    method: 'GET',
    params,
  }
}

export function getDoctorAuditExport(
  params: GetDoctorSaleEntriesParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/dailyStatementList/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
