/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-11 15:26:20
 */

import { Category } from '../models/category'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface InventoryRecordsParams {
  id?: number
  current?: number
  size?: number
  total?: number
  name?: string
  timeHead?: string
  timeTail?: string
  pages?: number
  billingCategory?: string
  materialsCategory?: string
  storehouseId?: string
  userId?: string
  hitCount?: boolean
  approvalNumber?: string
  count?: number
  deadline?: string
  doseCategory?: string
  no?: string
  recepitsDate?: string
  retailPrice?: number
  spec?: string
  status?: string
  storehouseName?: string
  supplierName?: string
  tenantSupplierName?: string
  unitPrice?: number
  userName?: string
  searchCount?: string
  realCount?: number
  realSplitCount?: number
  splitCount?: number
  splitPrice?: number
  upAccountTime?: string
  upAccountTimeStart?: string
  upAccountTimeEnd?: string
}
export function getInventoryRecords(params: InventoryRecordsParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/checklist`,
    method: 'GET',
    params,
  }
}

//导出
export function getPutstorageExoprtFn(params: InventoryRecordsParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/checklist/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
