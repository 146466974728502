/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-19 15:06:40
 * @LastEditors: sj
 * @LastEditTime: 2022-08-22 11:19:56
 */

import { Button, Popconfirm, Space, Switch, TableColumnType, Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'
import { DateTimeFormatSimple } from '../../models/datetime'

export const Columns = (
  onClick: (id: number, action: 'check' | 'buy' | 'repeal', t?: any) => void,
  startIndex: number,
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: '4rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '患者姓名',
      align: 'center',
      dataIndex: 'patientName',
    },
    {
      title: '联系方式',
      align: 'center',
      dataIndex: 'patientPhone',
    },

    {
      title: '接诊医生',
      align: 'center',
      dataIndex: 'treatmentDoctorName',
    },
    {
      title: '开方时间',
      align: 'center',
      dataIndex: 'createTime',
    },
    {
      title: '处方有效期',
      align: 'center',
      dataIndex: 'ybValidDays',

    },
    {
      title: '处方有效期截止',
      align: 'center',
      dataIndex: 'ybValidEndtime',
    },
    {
      title: '外配处方编号',
      align: 'center',
      dataIndex: 'ybExternalNo',

    },
    {
      title: '原处方编号',
      align: 'center',
      dataIndex: 'ybInitRxno',

    },
    {
      title: '是否已撤销',
      align: 'center',
      dataIndex: 'ybInitRxno',
      render: (_, t) => t.ybRxFlag == 1 ? '否' : '是'
    },
    {
      title: '操作',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      width: '6rem',
      render: function Element(_, t) {
        {
          return t.ybRxFlag == 1 ? (
            <Space>
          <Button
            type='primary'
            size='small'
            onClick={() => {
              onClick(t.ybExternalNo, 'check', t)
            }}
            ghost
          >
            审核结果查询
          </Button>
          <Button type='primary' size='small' onClick={() => {
            onClick(t.ybExternalNo, 'buy', t)
          }}>
            购药结果查询
          </Button>
          <Button type='primary' danger size='small' onClick={() => {
                onClick(t.id, 'repeal', t)
          }}>
            撤销
          </Button>
        </Space>
          ) : ''
        } 
      },
    },
  ]
}
