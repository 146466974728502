/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-02-22 17:03:59
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store";
import {
  getStoreExport,
  logoutlist,
  logoutOrTotalBody,
  Maintanceindetail,
  MaintanceParams,
  onBatchInitialization,
  onBatchInitializationBody,
  pageStoresearchPrint,
  simpleParams,
  splitOrTotal,
  storePageBody,
  storePageParams,
  storesearchDetail,
  storesearchPage,
  storesearchUpdata,
} from '../../services/storesearch'

const initialState = {
  StoresearchData: [],
  Total: 0,
  Detail: [],
  Current: 0,
  amountTotal: {
    totalAmountInput: 0,
    totalAmountSale: 0,
    subTotalRetailPrice: 0,
    subTotalUnitPrice: 0,
  },
  pageLoading: false,
}

export const inventoryInitSlice = createSlice({
  name: 'storesearch',
  initialState,
  reducers: {
    setData(state, action) {
      state.StoresearchData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setDetail(state, action) {
      state.Detail = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setAmountTotalt(state, action) {
      state.amountTotal = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setData,
  setTotal,
  setDetail,
  setCurrent,
  setAmountTotalt,
  setPageLoading,
} = inventoryInitSlice.actions

export function pageStoresearch(params: storePageParams): RootThunk {
  return api(storesearchPage(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
    const amountTotal = data.records.length
      ? {
          totalAmountInput: data.records[0].totalAmountInput,
          totalAmountSale: data.records[0].totalAmountSale,
        }
      : { totalAmountInput: 0, totalAmountSale: 0 }
    dispatch(setAmountTotalt(amountTotal))
  })
}


export const pageStoresearchPrintAsync = createAsyncThunk<
  void,
  storePageParams,
  RootThunkApi<void>
>('storeSearch/pageStoresearchPrintAsync', async (body, api) => {
  return sendAsync(pageStoresearchPrint(body), api)
})

export function detailStoresearch(id: number): RootThunk {
  return api(storesearchDetail(id), (data: any, dispatch) => {
    dispatch(setDetail(data.records[0]))
  })
}

export function updataStoreseach(body: storePageBody): RootThunk {
  return (dispatch, getState, args) => {
    const current = getState().storesearch.Current
    api(storesearchUpdata(body), (data, dispatch) => {
      dispatch(pageStoresearch({ current, size: 10 }))
    })(dispatch, getState, args)
  }
}

export const updataStoreseachAsync = createAsyncThunk<
  void,
  storePageBody,
  RootThunkApi<void>
>('storeSearch/updata', async (body, api) => {
  return sendAsync(storesearchUpdata(body), api)
})

export const setlogoutlistStateAsync = createAsyncThunk<
  void,
  simpleParams,
  RootThunkApi<void>
>('storesearch/setlogoutlistState', async (params, api) => {
  return sendAsync(logoutlist(params), api)
})

export const setsplitOrTotalAsync = createAsyncThunk<
  void,
  logoutOrTotalBody,
  RootThunkApi<void>
>('storesearch/setsplitOrTotalAsyncStatus', async (body, api) => {
  return sendAsync(splitOrTotal(body), api, true)
})

export const getMaintanceInDetail = createAsyncThunk<
  void,
  MaintanceParams,
  RootThunkApi<void>
>('storeSearch/maintance', async (params, api) => {
  return sendAsync(Maintanceindetail(params), api)
})

// 导出
export const getStoreExportAsync = createAsyncThunk<
  void,
  storePageParams,
  RootThunkApi<void>
>('storeSearch/getStoreExportAsync', async (params, api) => {
  return sendAsync(getStoreExport(params), api)
})

export const getOnBatchInitialization = createAsyncThunk<
  void,
  onBatchInitializationBody,
  RootThunkApi
>('/settlement/getOnBatchInitialization', async (body, api) => {
  return sendAsync(onBatchInitialization(body), api)
})





export const selectStoresearchData = (state: RootState) =>
  state.storesearch.StoresearchData

export const selectTotal = (state: RootState) => state.storesearch.Total

export const selectDetail = (state: RootState) => state.storesearch.Detail

export const selectCurrent = (state: RootState) => state.storesearch.Current

export const selectAmountTotal = (state: RootState) =>
  state.storesearch.amountTotal

export const selectPageLoading = (state: RootState) =>
  state.storesearch.pageLoading

export default inventoryInitSlice.reducer;
