import { unwrapResult } from '@reduxjs/toolkit'
import {
  Col,
  Dropdown,
  Input,
  InputProps,
  Pagination,
  Table,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch,  } from 'react-redux'
import { Material } from '../../../models/material'
import { FirstPage, Pager } from '../../../models/pagination'
import { ThemeContext } from '../../../theme/ThemeContext'
import { sleep } from '../../../utils/SystemUtils'
import { useTimeout } from '../../../utils/timehooks'
import { RootDispatch } from '../../../app/store'
import styles from '../../treatment/editor/components/RecipeItemInput.module.css'
import { getDrugSearchList } from '../../commodity/commoditySlice'
import { RecipeItemInputRef } from '../../treatment/editor/components/RecipeItemInput'
import { DrugSearchInput } from '../columns'

export interface DrugSearchRecipeItemInputRef {
  focus: () => void
  select: () => void
}

interface DrugSearchRecipeItemInputProps {
  value?: string
  onChange?: (value: string) => void
  onSelect?: (material: Material) => void
  onItems?: (v?: any) => void
  autoFocus?: boolean
  style?: React.CSSProperties
  disabled?: boolean
}

const DrugSearchRealRecipeItemInput = (
  {
    value,
    onChange,
    onSelect,
    onItems,
    onBlur,
    autoFocus = false,
    onPressEnter,
    onKeyDown,
    style,
    disabled = false,
  }: DrugSearchRecipeItemInputProps &
    Pick<InputProps, 'onBlur' | 'onPressEnter' | 'onKeyDown'>,
  ref: React.Ref<RecipeItemInputRef>
): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const inputRef = useRef<Input>(null)

  const [isVisible, setIsVisible] = useState(false)

  const [items, setItems] = useState<Material[]>([])

  const [pager, setPager] = useState<Pager>(FirstPage)

  const [selectedRowId, setSelectedRowId] = useState('')

  const [isComposing, setIsComposing] = useState(false)

  const [params, setParams] = useState<{ keyword: string; current: number }>({
    keyword: '',
    current: 1,
  })

  const [queryStr, setQueryStr] = useState('')

  const complete = (keyword: string, current: number) => {
    if (keyword) {
      dispatch(
        getDrugSearchList({
          allStr: keyword,
          current: current,
          size: 5,
        })
      )
        .then(unwrapResult)
        .then((v?: any) => {
          const items = v?.records
          setItems(items)
          onItems && onItems(items)
          setPager({ current: v?.current, size: v?.size, total: v?.total })
          setIsVisible(true)
          if (items.length > 0) {
            setSelectedRowId(items[0].id)
          } else {
            setSelectedRowId('')
          }
        })
    } else {
      reset()
    }
  }

  useTimeout(
    () => {
      complete(params.keyword, params.current)
    },
    50,
    [params]
  )

  useEffect(() => {
    if (!isComposing) {
      setParams({ keyword: queryStr, current: 1 })
    }
  }, [isComposing, queryStr])

  const overlay = (
    <Col>
      <Table
      style={{margin:'0 10px'}}
        className={styles.table}
        columns={DrugSearchInput(theme)}
        dataSource={items}
        size='small'
        pagination={false}
        rowKey={(m) => m.id}
        bordered
        onRow={(m) => ({
          onClick: () => {
            if (onSelect) {
              onSelect(m)
              reset()
              onPressEnter && onPressEnter(m as any)
            }
          },
        })}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [selectedRowId],
          onChange: (ks) => setSelectedRowId(ks[0] as string),
        }}
        rowClassName={styles.gury}
      />
      <Pagination
        pageSize={pager.size}
        current={pager.current}
        total={pager.total}
        size='small'
        style={{
          margin: '10px 10px',
        }}
        onChange={(current) => {
          setParams({ keyword: value || '', current })
          inputRef.current?.focus()
        }}
      />
    </Col>
  )

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus()
    },
    select: () => {
      inputRef.current?.select()
    },
  }))

  const reset = () => {
    setItems([])
    setPager(FirstPage)
    setSelectedRowId('')
    setIsVisible(false)
  }

  return (
    <Dropdown
      overlay={overlay}
      disabled={disabled}
      trigger={[]}
      visible={isVisible && items.length > 0}
      onVisibleChange={(v) => setIsVisible(v)}
    >
      <Input
        ref={inputRef}
        autoFocus={autoFocus}
        value={value}
        style={style}
        onClick={() => {
          complete(value || '', params.current)
        }}
        onFocus={() => setIsVisible(true)}
        onBlur={async (e) => {
          onBlur && onBlur(e)
          await sleep(200)
          setIsVisible(false)
        }}
        onCompositionStart={() => {
          setIsComposing(true)
        }}
        onCompositionEnd={(e) => {
          setIsComposing(false)
          setParams({ keyword: e.data, current: 1 })
        }}
        onChange={(e) => {
          setQueryStr(e.target.value)
          if (onChange) {
            onChange(e.target.value)
          }
        }}
        onKeyDown={(event) => {
          onKeyDown && onKeyDown(event)
          if (!selectedRowId) {
            return
          }
          let index = items.findIndex((m) => m.id === selectedRowId)
          const oneId = items?.[0]?.id
          const lastId = items?.[4]?.id
          switch (event.key) {
            case 'ArrowDown':
              // index = index + 1
              index = lastId == selectedRowId ? 0 : index + 1
              break
            case 'ArrowUp':
              index =
                oneId == selectedRowId ? index + (items?.length - 1) : index - 1
              break
            case 'ArrowRight':
              if (pager.current * pager.size < pager.total) {
                setParams({ keyword: value || '', current: pager.current + 1 })
              }
              break
            case 'ArrowLeft':
              if (pager.current > 1) {
                setParams({ keyword: value || '', current: pager.current - 1 })
              }
              break
          }
          if (index >= 0 && index < items.length) {
            setSelectedRowId(items[index].id)
          }
        }}
        onPressEnter={(e) => {
          onPressEnter && onPressEnter(e)
          if (!selectedRowId || !onSelect) {
            return
          }
          const item = items.find((m) => m.id === selectedRowId)
          if (item) {
            onSelect(item)
            reset()
          }
        }}
      />
    </Dropdown>
  )
}

export const DrugSearchRecipeItemInput = forwardRef(
  DrugSearchRealRecipeItemInput
)
