/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-08-08 11:48:02
 */
import {notification, Table} from "antd";
import React, {ReactElement, useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EditableList,
} from "../../../../compnents/list/EditableList";
import { NavBar } from "../../../../compnents/nav/NavBar";
import { PurchaseControlDetailColumns } from "./columns";
import { ReceivingQuery } from "./Query";
import { getPurchaseControlDetailList, selectCurrent, selectPageLoading, selectPurchaseControlDetailData, selectTotal, setCurrent, setPageLoading } from "./PurchaseControlDetailSlice";
import styles from "../purchaseControl.module.css";
import {PurchaseControlParams} from "../../../../services/purchaseControl";
import {useLocation} from "react-router-dom";
import {getExportListExoprt} from "./PurchaseControlDetailSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {RootDispatch} from "../../../../app/store";
export const PurchaseControlDetail = (): ReactElement => {

  const dispatch = useDispatch<RootDispatch>();

  const location: any = useLocation();
  const [queries, setQueries] = useState<PurchaseControlParams>();

  const pageLoadingRX = useSelector(selectPageLoading);

  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectPurchaseControlDetailData);

  const [size, setSize] = useState(10);
  useEffect(() => {
    if (queries?.timeHead) {
      dispatch(setPageLoading(true))
      dispatch(getPurchaseControlDetailList({ ...queries, current: Current, size ,supplierId:location.state.supplierId}))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }

  }, [queries,size, Current,]);


    const onExport = () => {
        dispatch(getExportListExoprt({ ...queries,current: Current, size: size,supplierId:location.state.supplierId }))
            .then(unwrapResult)
            .then((res: any) => {
                const content = res
                const blob = new Blob([content])
                const fileName = '进货管理详情.xlsx'
                const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
                const elink = document.createElement('a')
                if ('download' in elink) {
                    elink.download = fileName
                    elink.style.display = 'none'
                    elink.href = selfURL['createObjectURL'](blob)
                    document.body.appendChild(elink)
                    // 触发链接
                    elink.click()
                    selfURL.revokeObjectURL(elink.href)
                    document.body.removeChild(elink)
                } else {
                    (navigator as any).msSaveBlob(blob, fileName)
                }
            })
            .then(() => {
                notification.success({
                    message: '导出成功',
                    duration: 3,
                })
            })
            .catch((res: any) => {
                return
            })
    }

  return (
    <>
      <NavBar 
    style={{ margin: "10px 0 0px 20px" }}
        where={["进货管理", "进货管理详情"]}
        backtrace={{
          name: "进货管理",
          path: "/purchaseControl",
          state: {
            station: "8",
          },
        }}
      />
      <div className={styles.content}>
      <ReceivingQuery
            onValueChange={(v) => {
              setQueries({ ...queries, ...v ,supplierId:location.state.supplierId});
              dispatch(setCurrent(1));
            }}
            onExport={() => { onExport() }}
          />
      <EditableList
      className={styles.receving}
          loading={pageLoadingRX}
      rowKey={(t: any) => t.id}
      page={{
        items: data,
        current: Current,
        size: size,
        total: total,
      }}
      onChangePage={(current, pageSize) => {
        setSize(pageSize as number);
        dispatch(setCurrent(current));
      }}
        columns={PurchaseControlDetailColumns(size * (Current - 1))}

      summary={() => (
          <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align='center'>合计</Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align='center'></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align='center'></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align='center'></Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align='center'></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align='center'>
                      { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.count || 0), 0), [data]).toFixed(2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align='center'>
                      { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.unitPrice || 0), 0), [data]).toFixed(5)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align='center'>
                      { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.totalPrice || 0), 0), [data]).toFixed(2)}
                  </Table.Summary.Cell>
              </Table.Summary.Row>
              {/* )} */}
          </Table.Summary>
      )}
      />
      </div>
    </>
  );
};
