/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-07 11:21:35
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-18 11:58:24
 */
import { Button, Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { Categories, CategoryName } from '../../models/category'
import { User } from '../../models/user'
import { InventoryChangeParams } from '../../services/InventChange'
import { getStorage, selectStorage } from '../durgsystem/durgsystemSlice'
import {
  getDepartment,
  sleectUserData,
} from '../putstorelist/putstorelistSlice'
import { setChangeCurrent } from './InventoryChangSlice'
import { head, tail } from 'lodash'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../models/datetime'
import { useLocation } from 'react-router-dom'
const { Option } = Select

export const InventoryChangeQuery = (props: {
  onValueChange: (queries: InventoryChangeParams) => void
  outClued: () => void
}) => {
  const dispatch = useDispatch()

  const location: any = useLocation()

  const [form] = Form.useForm()

  const durgsystemData = useSelector(selectStorage)

  const userData = useSelector(sleectUserData)

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  useEffect(() => {
    dispatch(getStorage({ current: 1 }))
    dispatch(getDepartment({ current: 1, size: 1000, state: 1 }))
    form.setFieldsValue({
      [DateRangeFieldName]: DateRangeType.Today,
      timeHead: head,
      timeTail: tail,
    })
  }, [])

  useEffect(() => {
    if (Object.keys(storageParams).length) {
      form.setFieldsValue({
        ...storageParams,
        registrationTimeTail: storageParams.createTimeEnd,
        registrationTimeHead: storageParams.createTimeStart,
        // param: '', // 防止缓存数据输入
      })
    } else {
      const [head, tail] = getDateRange(DateRangeType.Today)
      form.setFieldsValue({
        [DateRangeFieldName]: DateRangeType.Today,
        timeHead: head,
        timeTail: tail,
      })
    }
    form.submit()
  }, [])

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(fs: any) => {
        const storehouseName =
          (durgsystemData.find(
            (d: any) => d.storehouseId === fs.storehouseId
          ) as any)?.storehouseName || ''
        const userName =
          (userData.find((u: any) => u.id === fs.userId) as any)?.name || ''
        props.onValueChange({
          ...fs,
          current: 1,
          createTimeEnd: fs.registrationTimeTail,
          createTimeStart: fs.registrationTimeHead,
          storehouseName: storehouseName || undefined,
          userName: userName || undefined,
        })
      }}
    >
      <Row
        gutter={10}
        style={{ height: '42px', position: 'relative', zIndex: 999 }}
        wrap={false}
      >
        <Col span={4}>
          <Form.Item name='name'>
            <Input
              placeholder='请输入搜索关键词'
              allowClear
              prefix={<SearchOutlined />}
              onChange={() => {
                form.submit()
              }}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='vendorName' >
            <Input
              placeholder='供应商'
              allowClear
              prefix={<SearchOutlined />}
              onChange={form.submit}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='storehouseId'>
            <Select
              placeholder='库房名称'
              allowClear
              // suffixIcon={<CaretDownFilled />}
              onChange={() => {
                form.submit()
              }}
            >
              {durgsystemData.map((v: any, i) => {
                return (
                  <Option value={v.storehouseId} key={i}>
                    {v.storehouseName}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='category'>
            <Select
              allowClear
              placeholder='库房业务'
              // suffixIcon={<CaretDownFilled />}
              onChange={() => {
                form.submit()
              }}
            >
              {Categories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {CategoryName(v)}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='flag'>
            <Select
              placeholder='合并批次'
              // suffixIcon={<CaretDownFilled />}
              onChange={() => {
                form.submit()
              }}
            >
              <Option value={0}>不合并</Option>
              <Option value={1}>合并</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='userId'>
            <Select
              showSearch
              placeholder='业务人员'
              allowClear
              // suffixIcon={<CaretDownFilled />}
              onChange={() => {
                form.submit()
              }}
            >
              {userData.map((v: User) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={3}>
          <Form.Item name=''>
            <DateSelect
              placeholder='发生时间'
              style={{ width: '8rem' }}
              onChange={() => {
                form.submit()
              }}
              labelPrefix=''
              namePrefix='registrationTime'
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Col span={3} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            type='primary'
            htmlType='submit'
            style={{ width: '100%', marginRight: '10px' }}
          >
            查询
          </Button>
          <Button
            type='primary'
            style={{ width: '100%' }}
            onClick={() => props.outClued()}
          >
            导出
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
