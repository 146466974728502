/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-06 17:12:30
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-05 13:55:14
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification, Pagination, Row, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootDispatch } from '../../../app/store'
import { DateRangeType, DateSelect } from '../../../compnents/form/DateSelect'
import { EditableList } from '../../../compnents/list/EditableList'
import { DateTimeFormat } from '../../../models/datetime'
import { InventoryChangeParams } from '../../../services/InventChange'
import { InventoryChangeColumns } from '../../InventoryChange/columns'
import { getInventoryChangePageAsync, getJumpDataByLedgerItemId } from '../../InventoryChange/InventoryChangSlice'
import styles from '../Storesearch.module.css'
import { PaymentResult } from '../../payment/result/PaymentResult'
import { InventoryStock } from '../../Inventorystock/InventoryStock'
import { Putstortlist } from '../../putstorelist/putstortlist'

export const ChangeWasteBook = () => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const location: any = useLocation()

  const [size, setSize] = useState(5)

  const [total, setTotal] = useState(0)

  const [current, setCurrent] = useState(1)

  const [data, setData] = useState<any>([])

  const [totalData, setTotalData] = useState<any>()

  const [queries, setQueries] = useState<InventoryChangeParams>({
    createTimeStart: moment().startOf('day').format(DateTimeFormat),
    createTimeEnd: moment().endOf('day').format(DateTimeFormat),
  })

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [rowData, setRowData] = useState<any>({});

  const [visibleCategory, setVisibleCategory] = useState(false);

  useEffect(() => {
    setTotalData(data?.[0])
  }, [data])

  useEffect(() => {
    if (location) {
      setPageLoading(true)
      dispatch(
        getInventoryChangePageAsync({
          size,
          current,
          tenantInventoryLedgerId: location.state.selectId,
          ...queries,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setData(res.outInvItemPages.records)
          setTotal(res.outInvItemPages.total)
        })
        .finally(() => setPageLoading(false))
    }
  }, [size, current, queries])

  return (
    <>
      <Form
        form={form}
        name="control-hooks"
        autoComplete="off"
        onFinish={(vs) => {
          setQueries({
            createTimeEnd: vs.registrationTimeTail,
            createTimeStart: vs.registrationTimeHead,
          });
        }}
      >
        <Row
          style={{
            height: "30px",
            margin: "20px 0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Col>
            <Form.Item name="">
              <DateSelect
                placeholder="时间范围"
                style={{ width: "8rem" }}
                onChange={form.submit}
                labelPrefix=""
                initialValue={DateRangeType.Today}
                namePrefix="registrationTime"
              />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
              查询
            </Button>
          </Col>
        </Row>
      </Form>
      <div className={styles.detailTable}>
        <EditableList
          style={{ height: "100%" }}
          loading={pageLoading}
          columns={InventoryChangeColumns(size * (current - 1), (t: any) => {
            if (t?.category === "11" || t?.category === "12") {
              dispatch(
                getJumpDataByLedgerItemId({
                  tenantInventoryLedgerItemId: t?.tenantInventoryLedgerItemId,
                })
              )
                .then(unwrapResult)
                .then((res: any) => {
                  if (!res?.isDeleted) {
                    setRowData({ ...t, ...res });
                    setVisibleCategory(true);
                  } else {
                    notification.error({
                      message: "该记录已被删除",
                      duration: 3,
                    });
                  }
                });
            } else {
              setRowData({...t});
              setVisibleCategory(true);
            }
          })}
          pagination={false}
          bordered
          page={{
            items: data,
            current,
            size: size,
            total,
          }}
        />
        <Pagination
          total={total}
          current={current}
          pageSize={size}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={["5", "10", "15", "20"]}
          style={{
            position: "absolute",
            bottom: "30px",
            right: "40px",
          }}
          showTotal={(total) => `共 ${total} 条`}
          onChange={(current, size) => {
            setSize(size as number);
            setCurrent(current);
          }}
        />
      </div>
      
      <Modal
        visible={visibleCategory}
        width={"85vw"}
        onOk={() => {
          setVisibleCategory(false);
        }}
        onCancel={() => {
          setVisibleCategory(false);
        }}
        footer={null}
        destroyOnClose
      >
        <div
          style={{
            maxHeight: "80vh",
            overflow: "auto",
            paddingBottom: rowData?.category === "8" ? "40px" : "0px",
          }}
        >
          {rowData?.category === "11" || rowData?.category === "12" ? (
            <PaymentResult currentPaymentId={rowData?.paymentId} />
          ) : rowData?.category === "8" ? (
            <InventoryStock
              category={rowData?.category}
              selectId={rowData?.tenantInventoryReceiptsId}
            />
          ) : (
            <Putstortlist
              putNumber={rowData?.putNumber}
              category={rowData?.category}
              selectId={rowData?.tenantInventoryReceiptsId}
            />
          )}
        </div>
      </Modal>
    </>
  );
}
