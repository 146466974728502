import { API_PREFIX } from './constant'
import { Request, TextPlain } from './request'

export function updataTreatment(body: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/changeRegistrationTime`,
    method: 'POST',
    body,
  }
}

export function paymentDetailRecord(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/paymenthistory/getPaymentDetailRecord`,
    method: 'GET',
    params,
  }
}

export function getChangePayTimeAndMethod(body: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/changePayTimeAndMethod`,
    method: 'POST',
    body,
  }
}

export function getAllRegistiongAndTreatmentOperatioEditLogPage(body: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/getAllRegistiongAndTreatmentOperatioEditLogPage`,
    method: 'POST',
    body,
  }
}

export function getAllPaymentOperatioEditLogPage(body: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/getAllPaymentOperatioEditLogPage`,
    method: 'POST',
    body,
  }
}