import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Select } from 'antd'
const { Option } = Select
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  pageDurgsystem,
  selectDurgsystemData,
} from '../durgsystem/durgsystemSlice'
import { pageSupplier } from '../wms/supplierSlice'
import {
  DepartmentDate,
  pagePutstorageUser,
  selectDepartData,
  selectPutstorageUser,
  setCategory,
  setCurrent,
} from './putstorageSlice'
import { putStorePageParams } from '../../services/putstorage'

import { DateRangeFieldName, DateSelect } from '../../compnents/form/DateSelect'
import {
  getDepartment,
  sleectUserData,
} from '../putstorelist/putstorelistSlice'
import { User } from '../../models/user'
import { getStorehouseTagName, StorehouseTags, StorehouseTagsCategoryOne } from '../../models/category'
import styles from './putstorage.module.css'
import { uploadOptions } from '../InventoryChecking/query'
import { stringTrim } from '../../utils/StringUtils'
import { useLocation } from 'react-router-dom'

export const Putquery = (props: {
  category?: any
  YBUploadType?: boolean
  onValueChange: (queries: putStorePageParams) => void
  outClued: () => void
  massUpload: () => void
}) => {
  const [form] = Form.useForm()

  const location: any = useLocation()

  const dispatch = useDispatch()

  const userData = useSelector(sleectUserData)

  const departmentData = useSelector(selectDepartData)

  const durgData = useSelector(selectDurgsystemData)

  const putstorageUser = useSelector(selectPutstorageUser)

  const [putstorageUserList, setPutstorageUserList] = useState<any[]>([])

  const storageParams = JSON.parse(
    sessionStorage.getItem(
      location.pathname + '_' + location?.state?.category
    ) || '{}'
  )

  useEffect(() => {
    dispatch(pageDurgsystem(1, 100, 1))
    dispatch(pageSupplier({ current: 1, size: 10, supplierCategory: 0 }))
    dispatch(DepartmentDate({ current: 1, size: 500 }))
    dispatch(getDepartment({ current: 1, size: 1000, state: 1 }))
    dispatch(pagePutstorageUser({ current: 1, size: 500 }))
  }, [])

  useEffect(() => {
    setPutstorageUserList(
      putstorageUser?.map((v: any) => {
        return { name: v.supplierName, id: v.supplierId }
      })
    )
  }, [putstorageUser])

  useEffect(() => {
    if (Object.keys(storageParams).length) {
      form.setFieldsValue({
        ...storageParams,
        timeHead: storageParams?.timeHead,
        timeTail: storageParams?.timeTail,
        [DateRangeFieldName]: storageParams?._dateRange,
      })
    }
    form.submit()
  }, [])

  const refresh = () => {
    props.onValueChange({
      ...storageParams,
      ...form.getFieldsValue(),
      current: 1,
    })
  }

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(fs) => {
        const data = storageParams
        props.onValueChange({
          ...data,
          ...fs,
          current: data.current || 1,
        })
        dispatch(setCurrent(data.current || 1))
        // props.onValueChange({ ...fs, current: 1 })
      }}
    >
      <Row
        gutter={11}
        style={{ height: '42px', position: 'relative', zIndex: 999 }}
        wrap={false}
      >
        <Col span={5}>
          <Form.Item name='allNum' getValueFromEvent={stringTrim}>
            <Input
              placeholder='输入业务单号'
              allowClear
              prefix={<SearchOutlined />}
              onChange={refresh}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='supplierName'>
            <Input
              placeholder='供应商'
              allowClear
              prefix={<SearchOutlined />}
              onChange={refresh}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='storehouseId'>
            <Select
              placeholder='库房列表'
              allowClear
              showSearch
              dropdownClassName={styles.select}
              optionFilterProp='children'
              // suffixIcon={<CaretDownFilled />}
              onSelect={(key: any) => {
                dispatch(setCategory(key))
              }}
              onChange={refresh}
            >
              {durgData.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        {!props.YBUploadType &&
          (props?.category == 1 ? (
            <>
              <Col span={2}>
                <Form.Item name='storehouseTag'>
                  <Select
                    placeholder='状态'
                    dropdownClassName={styles.select}
                    // suffixIcon={<CaretDownFilled />}
                    onChange={refresh}
                    allowClear
                  >
                    {StorehouseTagsCategoryOne.map((v) => (
                      <Option value={v} key={v}>
                        {getStorehouseTagName(v)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          ) : (
            <>
              <Col span={2}>
                <Form.Item name='storehouseTag'>
                  <Select
                    placeholder='是否登账'
                    dropdownClassName={styles.select}
                    // suffixIcon={<CaretDownFilled />}
                    onChange={refresh}
                    allowClear
                  >
                    {StorehouseTags.map((v) => (
                      <Option value={v} key={v}>
                        {getStorehouseTagName(v)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          ))}
        <Col span={2}>
          <Form.Item name='departmentId'>
            <Select
              placeholder='业务部门'
              allowClear
              showSearch
              onChange={refresh}
              dropdownClassName={styles.select}
              // suffixIcon={<CaretDownFilled />}
              optionFilterProp='children'
            >
              {departmentData.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='userId'>
            <Select
              showSearch
              onChange={refresh}
              dropdownClassName={styles.select}
              placeholder='业务人员'
              allowClear
              // suffixIcon={<CaretDownFilled />}
            >
              {userData.map((v: User) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={2}>
          <Form.Item name="supplierId">
            <Select
              placeholder="对方单位"
              allowClear
              showSearch
              dropdownClassName={styles.select}
              // suffixIcon={<CaretDownFilled />}
              optionFilterProp="children"
            >
              {supplierData.map((v: any, i) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.companyName}
                  </Option>
                );
              })}
                 {durgData.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col> */}
        {props.YBUploadType && (
          <Col span={3}>
            <Form.Item name='ybUploadFlag'>
              <Select
                placeholder='是否已上传'
                allowClear
                // suffixIcon={<CaretDownFilled />}
                dropdownClassName={styles.select}
                onChange={refresh}
              >
                {uploadOptions.map((v) => (
                  <Option value={v.value} key={v.value}>
                    {v.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col flex={1}>
          <Form.Item name='recepitsDateEnd'>
            <DateSelect
              placeholder='发生时间'
              style={{ width: '8rem' }}
              labelPrefix=''
              onChange={refresh}
              namePrefix='registrationTime'
            />
          </Form.Item>
        </Col>
        <Col style={{ display: 'flex' }}>
          <Button
            htmlType='submit'
            type='primary'
            style={{ marginRight: '10px' }}
            onClick={refresh}
          >
            查询
          </Button>
          {!props.YBUploadType && (
            <Button type='primary' onClick={() => props.outClued()}>
              导出
            </Button>
          )}
          {props.YBUploadType && (
              <Button type='primary' onClick={() => props.massUpload()}>
                批量上传
              </Button>
          )}
        </Col>
      </Row>
    </Form>
  )
}
