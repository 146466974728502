/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-25 16:52:22
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../../app/applicationSlice";
import { RootState, RootThunkApi } from "../../../app/store";
import {
  DateRangeFieldName,
  DateRangeType,
  getDateRange,
} from "../../../compnents/form/DateSelect";
import { DrugCostEntry, fromJson } from "../../../models/drugCost";
import * as S from "../../../services/drugCost";
import { GetDrugCostEntriesParams, GetDoctorSaleEntriesParams } from "../../../services/drugCost";

interface DrugCostsState {
  params: GetDrugCostEntriesParams;
}

const [timeHead, timeTail] = getDateRange(DateRangeType.Today);

const initialState = {
  params: {
    timeHead,
    timeTail,
    [DateRangeFieldName]: DateRangeType.Today,
  },
} as DrugCostsState

const drugCostSlice = createSlice({
  name: "drugCostSlice",
  initialState,
  reducers: {
    setGetDrugCostEntriesParams: (
      state,
      action: PayloadAction<GetDrugCostEntriesParams>
    ) => {
      state.params = action.payload;
    },
  },
});

// 导出
export const getDrugCostsExport = createAsyncThunk<
  void,
  GetDoctorSaleEntriesParams,
  RootThunkApi
>("doctorAudit/getDrugCostsExport ", async (params, api) => {
  return sendAsync(S.getDrugCostsExport(params), api);
});


export const { setGetDrugCostEntriesParams } = drugCostSlice.actions;

export const getDrugCostEntries = createAsyncThunk<
  DrugCostEntry[],
  GetDrugCostEntriesParams,
  RootThunkApi<DrugCostEntry[]>
>("treatmentCost/getTreatmentCostEntriesStatus", async (params, api) => {
  return sendAsync(S.getDrugCostEntries(params), api).then((data) =>
    _.chain(data)
      .map((e) => fromJson(e))
      .value()
  );
});

export const selectDrugCostEntrisParams = (
  state: RootState
): GetDrugCostEntriesParams => state.drugCost.params;

export default drugCostSlice.reducer;
