/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-19 09:21:52
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-23 18:35:35
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import * as S from '../../services/OReplaceMake'

// 代煎订单列表
export const getreplaceMakeOrderList = createAsyncThunk<
  void,
  S.replaceMakeOrderListParams,
  RootThunkApi<void>
>('recipeEditor/getreplaceMakeOrderList', async (params, api) => {
  return sendAsync(S.replaceMakeOrderList(params), api)
})

// 代煎订单详情
export const getreplaceMakeOrderDetail = createAsyncThunk<
  void,
  S.replaceMakeOrderDetailParams,
  RootThunkApi<void>
>('recipeEditor/getreplaceMakeOrderDetail', async (params, api) => {
  return sendAsync(S.replaceMakeOrderDetail(params), api)
})

// 代煎明细列表
export const getreplaceMakeDetail = createAsyncThunk<
  void,
  S.replaceMakeDetailParams,
  RootThunkApi<void>
>('recipeEditor/getreplaceMakeDetail', async (params, api) => {
  return sendAsync(S.replaceMakeDetail(params), api)
})

// 代煎明细合计行
export const getreplaceMakeDetailAmount = createAsyncThunk<
  void,
  S.replaceMakeDetailParams,
  RootThunkApi<void>
>('recipeEditor/getreplaceMakeDetailAmount', async (params, api) => {
  return sendAsync(S.replaceMakeDetailAmount(params), api)
})

// 代煎处方明细列表
export const getReplaceMakeRecipeDetail = createAsyncThunk<
  void,
  S.replaceMakeDetailParams,
  RootThunkApi<void>
>('recipeEditor/getReplaceMakeRecipeDetail', async (params, api) => {
  return sendAsync(S.replaceMakeRecipeDetail(params), api)
})
// 代煎处方明细合计
export const getReplaceMakeRecipeDetailAmount = createAsyncThunk<
  void,
  S.replaceMakeDetailParams,
  RootThunkApi<void>
>('recipeEditor/getReplaceMakeRecipeDetailAmount', async (params, api) => {
  return sendAsync(S.replaceMakeRecipeDetailAmount(params), api)
})


// 代煎处方明细列表导出
export const getReplaceMakeRecipeDetailExport = createAsyncThunk<
  void,
  S.replaceMakeDetailParams,
  RootThunkApi<void>
>('recipeEditor/getReplaceMakeRecipeDetail', async (params, api) => {
  return sendAsync(S.replaceMakeRecipeDetailExport(params), api)
})



// 获取代煎饮片厂
export const getSupplierList = createAsyncThunk<
  Array<S.SupplierListResponse>,
  void,
  RootThunkApi<void>
>('recipeEditor/getSupplierList', async (params, api) => {
  return sendAsync(S.supplierList(), api)
})

// 获取代煎目录价格表
export const supplierPriceListAsync = createAsyncThunk<
  void,
  S.SupplierPriceListParams,
  RootThunkApi<void>
>('recipeEditor/supplierPriceListAsync', async (params, api) => {
  return sendAsync(S.supplierPriceList(params), api)
})




// 获取代煎统计
export const supplierStatisticsListAsync = createAsyncThunk<
  void,
  S.SupplierStatisticsListParams,
  RootThunkApi<void>
>('recipeEditor/supplierStatisticsListAsync', async (params, api) => {
  return sendAsync(S.supplierStatisticsList(params), api)
})
