/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-03-11 14:06:33
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-11 14:51:05
 */

import { API_PREFIX } from './constant'
import { Request } from './request'

export interface FeeLimitListParams {
  startTime?: any
  endTime?: any
  fylb?: any
}

export function FeeLimitList(params: FeeLimitListParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getFeeLimit`,
    method: 'GET',
    params,
  }
}

