/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 1373842098@qq.com
 * @Date: 2022-04-29 11:09:50
 * @LastEditors: sj
 * @LastEditTime: 2022-12-15 18:03:39
 */
import _ from 'lodash'
import { fromMaterialJson, Material } from './material'

interface ConfirmationSlot {
  name: string
  value: number
  payType?: string
  children: ConfirmationSlot[]
}

export interface PaymentConfirmation {
  [x: string]: any
  patient: string
  amount: number
  prePay?: any
  ids?: any
  errorMaterials: Material[]
  slots: ConfirmationSlot[]
}

export function fromJson(json: any): PaymentConfirmation {
  const payment = json.payment
  return {
    ...json,
    patient: payment?.patientName || '',
    patientId: payment?.patientId || '',
    treatmentId: payment?.treatmentId || '',
    amount: payment?.shouldPayAmount || 0,
    errorMaterials: _.chain(json.errorRecipeItemList)
      .map((m) => fromMaterialJson(m))
      .value(),
    slots:
      _.chain(json.paymentSummaryList)
        .map((json) => ({
          name: json?.paymentSummary?.billingCategoryName,
          value: json?.paymentSummary?.amount,
          children: _.chain(json?.paymentItemList)
            .map(
              (json) =>
                ({
                  name: json?.drugName,
                  value: json?.drugAmount,
                  payType: json?.itemPayType,
                } as ConfirmationSlot)
            )
            .value(),
        }))
        .value() || [],
  }
}
