/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-08-01 14:28:39
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface CompletedOperationParams {
  current?: number
  size?: number
  flag?: number
  startTime?: any
  endTime?: any
  patientId?: string
}

export function CompletedOperationList(
  params: CompletedOperationParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/operatingbill/page`,
    method: 'GET',
    params,
  }
}
