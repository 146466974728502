/*
 * @Descripttion: s
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-08 16:57:18
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-05 20:15:30
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store"
import { stockDataValue } from "../../models/InventStock";
import { Page, pageOf, startPageOf } from "../../models/page";
import { fromJson } from "../../models/supplierCategory";
import { getInventoryPage, getInventStock, InventoryStockParams, stockParams } from "../../services/InventStock";
import {ContentType, MultiPartFormData, Request} from "../../services/request";
import {API_PREFIX} from "../../services/constant";

export interface page {
    page: Page<any>;
}
const initialState: page = {
    page: startPageOf(),
};

export interface ImportExcelParams {
    file?: any
    inventoryReceiptsId:any
}

export const getInventroyAsync = createAsyncThunk<
    Page<any>,
    InventoryStockParams,
    RootThunkApi<Page<any>>
>("Inventory/page", async (params, api) => {
    return sendAsync(getInventoryPage(params), api)
        .then(
            (data: Record<string, never>) =>
                pageOf(
                    data,
                    _.chain(data.records)
                        .map((d) => fromJson(d))
                        .value()
                )

        )
})

export function getTakeStockImportExcel(params: ImportExcelParams): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryledger/importTakeStock`,
        headers: {
            [ContentType]: MultiPartFormData,
        },
        method: 'POST',
        params,
    }
}

export const getStockDataAsync = createAsyncThunk<
    stockDataValue,
    stockParams,
    RootThunkApi<void>
>("Inventory/stockData", async (params, api) => {
    return sendAsync(getInventStock(params), api)
})


const InventoryStockSlice = createSlice({
    name: "department",
    initialState,
    reducers: {
        changeRow(state, action) {
            state.page.items = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getInventroyAsync.fulfilled, (state, action) => {
            state.page = action.payload;
        })
    }
});

export const getTakeStockImportExcelAsync = createAsyncThunk<
  void,
  ImportExcelParams,
  RootThunkApi
>('/Inventory/importExcel', async (body, api) => {
    return sendAsync(getTakeStockImportExcel(body), api)
})


export const { changeRow } = InventoryStockSlice.actions

export const selectPage = (state: RootState): Page<any> => state.InventoryStock.page

export default InventoryStockSlice.reducer;