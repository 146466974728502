/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-14 10:04:09
 * @LastEditors: K
 * @LastEditTime: 2022-07-14 17:28:08
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState, RootThunkApi } from '../../app/store'
import { api, sendAsync } from '../../app/applicationSlice'
import {
  detailFollowUpListPageFn,
  detailFollowUpListPageFnParams,
  FollowUpTypeFn,
  followUpPeopleList,
  followUpSubmit,
  followUpTypeList,
  followUpTypeSubmit,
  getFollowUpListPageFn,
  getFollowUpListPageFnParams,
  getFollowUpPeopleListParams,
  getFollowUpSubmitListParams,
  getFollowUpTypeSubmitListParams,
  cancelFollowUpFn,
  followUpWaitDetail,
  followUpWaitCancel,
  removeFollowUpType,
} from '../../services/followUp'

const initialState = {
  isVisible: '',
}

// 随访列表
export const getFollowUpListPage = createAsyncThunk<
  any,
  getFollowUpListPageFnParams,
  RootThunkApi<void>
>('confirm/getFollowUpListPage', async (params, api) => {
  return sendAsync(getFollowUpListPageFn(params), api)
})
// 随访列表-查看详情
export const detailFollowUpListPage = createAsyncThunk<
  any,
  detailFollowUpListPageFnParams,
  RootThunkApi<void>
>('confirm/detailFollowUpListPage', async (params, api) => {
  return sendAsync(detailFollowUpListPageFn(params), api)
})
// 随访类型
export const FollowUpType = createAsyncThunk<any, void, RootThunkApi<void>>(
  'confirm/FollowUpType',
  async (_, api) => {
    return sendAsync(FollowUpTypeFn(), api)
  }
)
// 随访人
export const getFollowUpPeopleList = createAsyncThunk<
  any,
  getFollowUpPeopleListParams,
  RootThunkApi<void>
>('confirm/getFollowUpPeopleList', async (params, api) => {
  return sendAsync(followUpPeopleList(params), api)
})

// 随访类型
export const getFollowUpTypeList = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('confirm/getFollowUpTypeList', async (params, api) => {
  return sendAsync(followUpTypeList(), api)
})

//待随访详情
export const getFollowUpWaitDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('detail/getFollowUpWaitDetail', async (params, api) => {
  return sendAsync(followUpWaitDetail(params), api)
})

//待随访取消
export const getFollowUpWaitCancel = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('detail/getFollowUpWaitCancel', async (params, api) => {
  return sendAsync(followUpWaitCancel(params), api)
})

// 随访保存
export const getFollowUpTypeSubmitAsyncs = createAsyncThunk<
  any,
  getFollowUpTypeSubmitListParams,
  RootThunkApi<void>
>('confirm/getFollowUpTypeSubmit', async (params, api) => {
  return sendAsync(followUpTypeSubmit(params), api)
})

//删除
export const getRemoveFollowUpType = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('confirm/getRemoveFollowUpType', async (params, api) => {
  return sendAsync(removeFollowUpType(params), api)
})




// 新增随访保存
export const getFollowUpSubmitList = createAsyncThunk<
  any,
  getFollowUpSubmitListParams,
  RootThunkApi<void>
>('confirm/getFollowUpSubmit', async (params, api) => {
  return sendAsync(followUpSubmit(params), api)
})




export const followUpSlice = createSlice({
  name: 'commodity',
  initialState,
  reducers: {
    setAddVisible(state, action) {
      state.isVisible = action.payload
    },
  },
})

export const { setAddVisible } = followUpSlice.actions

export const selectAddVisibleData = (state: RootState) =>
  state.followUp.isVisible

export default followUpSlice.reducer
// 取消随访
export const cancelFollowUp = createAsyncThunk<
  any,
  detailFollowUpListPageFnParams,
  RootThunkApi<void>
>('confirm/cancelFollowUp', async (params, api) => {
  return sendAsync(cancelFollowUpFn(params), api)
})
