/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 13:43:06
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-24 15:17:38
 */
import { unwrapResult } from '@reduxjs/toolkit'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootDispatch } from '../../../app/store'
import { getStateTag } from '../youYiKangManagement/clinicManagement/clinicManagementSlice'
import styles from './youYiKang.module.css'
import logo from '../images/logo.png'
import noMessagePic from '../images/noMessagePic.png'
import { Modal } from 'antd'

export const YouYiKang = (props: { selectKey: any }): ReactElement => {
  const history = useHistory()

  const location: any = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<any>()


  useEffect(() => {
    if (props?.selectKey == '1' || location?.state?.selectKey == '1') {
      dispatch(getStateTag({ current: 1, size: 10 }))
        .then(unwrapResult)
        .then((v: any) => {
          setData(v?.records)
        })
    }
  }, [props?.selectKey, location?.state])

  return (
    <div className={styles.container}>
      {data && data?.length ? (
        <div
          className={styles.mainContent}
          onClick={(event: any) => {
            event.stopPropagation()
            history.push({
              pathname: '/youYiKangMainManagement',
              state: {},
            })
          }}
        >
          <div className={styles.pic}>
            <img src={logo} style={{ width: '100%', height: '100%' }}></img>
          </div>
          <div className={styles.name}>阿里健康</div>
          <div
            className={styles.title}
            onClick={(event: any) => {
              event.stopPropagation()
            }}
          >
            在线预约第三方平台引流
          </div>
        </div>
      ) : (
        <div className={styles.noMainContent}>
          <div className={styles.noMessagePic}>
            <img
              src={noMessagePic}
              style={{ width: '100%', height: '100%' }}
            ></img>
          </div>
          <div>未开通第三方预约平台，请联系客服开通</div>
          <div
            className={styles.ButtonStyle}
            onClick={(event: any) => {
              Modal.info({
                title: '您好',
                content: (
                  <div>
                    <p>您可以拨打服务电话：17366656508 咨询代办事宜</p>
                  </div>
                ),
                okText: '好的',
                icon: null,
                centered: true,
                closable: true,
                maskClosable: true,
                onOk: () => {
                  return
                },
              })
            }}
          >
            点击咨询
          </div>
        </div>
      )}
    </div>
  )
}
