/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-27 09:53:27
 * @LastEditors: linxi
 * @LastEditTime: 2024-01-08 14:16:57
 */

import { sum } from 'lodash'
import moment from 'moment'
import { BillingCategoryName } from './billingCategory'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from './datetime'

//打印单
export function printBodyInventoryCheck(data: any) {
  const userName = data?.userName
  const tenantName = data?.tenantName
  const datas = data?.dataList?.filter((v: any) => v?.id != 0 || !v?.name)
  let sjDatas = ''

  const total = data?.dataList?.filter((v: any) => v?.id == 0)?.[0]

  for (let i = 0; i < datas.length; i++) {
    sjDatas +=
      '<div style="font-size: 8px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      (Number(i) + 1) +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].storehouseName +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 14%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].putNumber +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 15%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].departmentName +
      '            </div>' +
      '             <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].userName +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:13%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].createTime +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:13%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].recepitsDate +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:15%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box">' +
      '                ' +
      datas[i].totalUnitPrice +
      '            </div>' +
      '        </div>'
  }
  // const userBean = getUserBean()
  const content =
    '<div style="margin-left: 10px;margin-right: 10px">' +
    '    <h4 style="text-align: center;margin-bottom: 5px;">' +
    '库存业务单' +
    '</h4>' +
    '    <div style="text-align: center;margin-top: 5px;margin-bottom: 5px;width:100%">' +
    '       <h3 style="display: inline-block;text-align: center;margin-top: 5px;margin-bottom: 5px;width: 20%;border-bottom: 1px solid #000;">' +
    '          <div style="text-align: center;width:100%;display: inline-flex;">' +
    '           <div style="width:20%;display: inline-block">库</div>' +
    '           <div style="width:20%;display: inline-block">存</div>' +
    '           <div style="width:20%;display: inline-block">盘</div>' +
    '           <div style="width:20%;display: inline-block">点</div>' +
    '           <div style="width:20%;display: inline-block">单</div>' +
    '          </div></h3>' +
    '    </div>' +
    '    <div style="font-size:10px;display:flex;text-align: left;margin-bottom:10px">' +
    '        <div style="float: left;width:50%;padding-left: 10px">单位:<font style="word-break:break-all;padding-left: 10px">' +
    tenantName +
    '</font></div>' +
    '       <div style="float: left;width:50%;padding-right: 10px;text-align: right">金额:<font style="word-break:break-all;padding-left: 10px">' +
    '元' +
    '</font></div>' +
    '    </div>' +
    '    <div style="font-size:8px;display:table;width:100%;align-items:center;clear: left;border: 1px solid #000">' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            序号' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 14%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           库房名称' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 14%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            业务单号' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 15%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            业务部门' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           业务人员' +
    '       </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 13%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           创建时间' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 13%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            登账时间' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 15%;text-align:right;box-sizing: border-box">' +
    '            盈亏金额（进价）' +
    '        </div>' +
    '    </div>' +
    sjDatas +
    '      <div style="font-size: 8px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 85%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                合计：' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 15%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box">' +
    '                ' +
    total?.totalUnitPrice +
    '            </div>' +
    '        </div>' +
    '    <div>' +
    '    <div style="font-size:8px;display:flex;padding-top: 10px">' +
    '        <div style="word-break:break-all;width: 30%;padding-left:25px;float: left;">单位负责人:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width: 30%;padding-left:25px;float: left;">仓管员:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width:38%;float: left;text-align: left;">财务:</div>' +
    '    </div>' +
    '    <div style="font-size:8px;display:flex;padding-top: 10px">' +
    '        <div style="word-break:break-all;width: 30%;padding-left:25px;float: left;">审核:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width: 30%;padding-left:25px;float: left;">制单人:<span style="padding-left: 20px;">' +
    userName +
    '</span></div>' +
    '        <div style="word-break:break-all;width:38%;float: left;text-align: left;">制单日期 : ' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</div>' +
    '    </div>' +
    '</div>'

  return content
}

//详情打印单
export function printBodyInventoryCheckDetail(data: any) {
  const userName = data?.userName
  const tenantName = data?.tenantName
  const detail = data?.detail
  const datas = data?.dataList?.filter((v: any) => v?.id != 0)
  let sjDatas = ''
  let sumZsl = 0
  let sumJjJe = 0
  let sumSjJe = 0
  let sumJxce = 0
  const total = data?.dataList?.filter((v: any) => v?.id == 0)?.[0]

  for (let i = 0; i < datas?.length; i++) {
    sjDatas +=
      '<div style="font-size: 8px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      (Number(i) + 1) +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 17%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].name +
      '(' +
      datas[i].productInfo +
      ')' +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].batchNo +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].beforeCount +
      '            </div>' +
      '             <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].beforeSplitCount +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].realCount +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].realSplitCount +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].count +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      parseFloat((datas[i].splitCount as unknown) as string) +
      '            </div>' +
      '           <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:12%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].changePrice +
      '           </div>' +
      '           <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box">' +
      '                ' +
      moment(datas[i].updateTime).format(DateTimeFormatSimple) +
      '           </div>' +
      '        </div>'
    sumZsl += Number(datas[i].count)
    sumJjJe += Number(datas[i].unitPriceAllPrice)
    sumSjJe += Number(datas[i].retailPriceAllPrice)
    const jxce: any = Number(datas[i].differencePrice)
    sumJxce += Number(datas[i].differencePrice)
  }
  // const userBean = getUserBean()
  const content =
    '<div style="margin-left: 10px;margin-right: 10px">' +
      '    <h5 style="text-align: center;margin-bottom: 5px;">' +
      tenantName +
      '</h5>' +
      '    <h6 style="text-align: center;margin-top: 5px;margin-bottom: 5px;">库&nbsp;&nbsp;存&nbsp;&nbsp;盘&nbsp;&nbsp;点&nbsp;&nbsp;清&nbsp;&nbsp;单</h6>' +
      ((total?.storehouseTag ==
      3 || total?.storehouseTag ==1) ? 
    '    <div style="font-size:10px;display:flex;text-align: right;margin-bottom:10px">' +
    '       <div style="float: left;width:100%;padding-left: 10px">盘点时间:<font style="word-break:break-all;padding-left: 10px">' +
    moment(total?.upAccountTime).format(DateTimeFormatSimpleOnly) +
    '</font></div>' +
    '    </div>'  :'')+
    '    <div style="font-size:8px;display:table;width:100%;align-items:center;clear: left;border: 1px solid #000">' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            序号' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 17%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           产品信息' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            批次号' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;width: 15%;border-right: 1px solid #000;box-sizing: border-box;">' +
    '            <div style="word-break:break-all;width: 100%;float: left;text-align:center;">' +
    '                盘点前' +
    '            </div>' +
    '            <div style="display: table-cell;vertical-align: middle;width: 100%;float: left;text-align:center;border-top: 1px solid #000;">' +
    '                <div style="word-break:break-all;width:50%;float: left;text-align:center;">整存</div>' +
    '                <div style="word-break:break-all;width:50%;float: left;text-align:center;">拆零</div>' +
    '            </div>' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;width: 15%;border-right: 1px solid #000;box-sizing: border-box;">' +
    '            <div style="word-break:break-all;width: 100%;float: left;text-align:center;">' +
    '                盘点后' +
    '            </div>' +
    '            <div style="display: table-cell;vertical-align: middle;width: 100%;float: left;text-align:center;border-top: 1px solid #000;">' +
    '                <div style="word-break:break-all;width:50%;float: left;text-align:center;">整存</div>' +
    '                <div style="word-break:break-all;width:50%;float: left;text-align:center;">拆零</div>' +
    '            </div>' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;width: 15%;border-right: 1px solid #000;box-sizing: border-box;">' +
    '            <div style="word-break:break-all;width: 100%;float: left;text-align:center;">' +
    '                盘盈盘亏数' +
    '            </div>' +
    '            <div style="display: table-cell;vertical-align: middle;width: 100%;float: left;text-align:center;border-top: 1px solid #000;">' +
    '                <div style="word-break:break-all;width:50%;float: left;text-align:center">整存</div>' +
    '                <div style="word-break:break-all;width:50%;float: left;text-align:center;">拆零</div>' +
    '            </div>' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 12%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            变动金额(进价)' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 10%;text-align:center;">' +
    '            操作时间' +
    '        </div>' +
    '    </div>' +
    sjDatas +
    '      <div style="font-size: 8px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 33%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                合计：' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    total?.beforeTotalCount +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    total?.checkSplitCountBefore +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    total?.afterTotalCount +
    '            </div>' +
    '             <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    total?.checkSplitCountAfter +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    total?.checkCount +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7.5%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    total?.checkSplitCount +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:12%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    total?.checkPrice +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;">' +
    '                ' +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '    <div style="font-size:8px;display:flex;padding-top: 10px">' +
    '        <div style="word-break:break-all;width: 30%;padding-left:25px;float: left;">单位负责人:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width: 30%;padding-left:25px;float: left;">仓管员:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width:38%;float: left;text-align: left;">财务:</div>' +
    '    </div>' +
    '    <div style="font-size:8px;display:flex;padding-top: 10px">' +
    '        <div style="word-break:break-all;width: 30%;padding-left:25px;float: left;">审核:<span style="padding-left: 20px;"></span></div>' +
    '        <div style="word-break:break-all;width: 30%;padding-left:25px;float: left;">制单人:<span style="padding-left: 20px;">' +
    userName +
    '</span></div>' +
    '        <div style="word-break:break-all;width:38%;float: left;text-align: left;">制单日期:' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</div>' +
    '    </div>' +
    '</div>'

  return content
}
