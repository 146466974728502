/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-01 11:03:49
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Row, Space, Table } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'

import { ThemeContext } from '../../../theme/ThemeContext'
import { Columns } from './Columuns'
import styles from './statistics.module.css'
import { supplierStatisticsListAsync } from '../OReplaceMakeSlice'
import { useHistory } from 'react-router-dom'
import { Query } from './Query'
import { DateRangeType, getDateRange } from '../../../compnents/form/DateSelect'

// 代煎价格
export const ReplaceMakeAStatistics = (props: {
  tabSelect?: any
}): ReactElement => {
  const theme = useContext(ThemeContext)

  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<any>([])

  const [total, setTotal] = useState(0)

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const [allData, setAllData] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    startTime: head,
    endTime: tail,
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props?.tabSelect == 3) {
      setLoading(true)
      dispatch(
        supplierStatisticsListAsync({
          ...params,
        })
      )
        .then(unwrapResult)
        .then((d: any) => {
          setAllData(d)
          setTotal(d?.res?.total)
          setData(d?.res?.records)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [params, props?.tabSelect])

  return (
    <Col
      style={{
        height: 'calc(100% - 20px) ',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: theme.pn,
          marginTop: 10,
          borderRadius: 10,
          padding: '10px 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Query
          onValueChange={(v: any) => {
            setParams({
              ...params,
              ...v,
              current: 1,
              startTime: v?.timeHead ? v?.timeHead : head,
              endTime: v?.timeTail ? v?.timeTail : tail,
              timeHead: undefined,
              timeTail: undefined,
            })
          }}
        />
        <EditableList
          page={{
            items: data,
            current: params.current || 1,
            size: params.size || 10,
            total,
          }}
          loading={loading}
          style={{ color: theme.tc3 }}
          className={styles.table}
          columns={Columns((params.size || 10) * ((params.current || 1) - 1))}
          onChangePage={(current: number, size?: number) => {
            setParams({
              ...params,
              current: current,
              size: size || 10,
            })
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={1}
                  className={styles.footingTitle}
                  align='center'
                >
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align='center'>
                  {allData?.recipeCountSum > -1 ? allData?.recipeCountSum : '0'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align='center'>
                  {allData?.recipeAmountSum > -1
                    ? allData?.recipeAmountSum?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align='center'>
                  {allData?.drugAmountSum > -1
                    ? allData?.drugAmountSum?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align='center'>
                  {allData?.priceRatioSum > -1
                    ? allData?.priceRatioSum?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align='center'>
                  {allData?.potionMoneySum > -1
                    ? allData?.potionMoneySum?.toFixed(2)
                    : '0.00'}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Col>
    </Col>
  )
}
