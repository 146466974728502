/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-08-04 11:26:53
 * @LastEditors: K
 * @LastEditTime: 2022-08-04 14:01:56
 */
import { Col } from 'antd';
import React from 'react';
import { TabBar, TabPane } from '../../compnents/widgets/TabBar';
import { SendMessagePage } from './sendMessagePage/sendMessagePage';

export const SendMessageTab = () => {
  return (
    <Col
      style={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      <TabBar style={{ margin: '10px 20px' }}>
        <TabPane key='0' tab='短信推送记录'>
          <SendMessagePage />
        </TabPane>
        {/* <TabPane key='2' tab='机构短信套餐' style={{ height: '100%' }}>
          <ReplaceMakeDetail />
        </TabPane> */}
      </TabBar>
    </Col>
  );
}

