/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-26 16:05:31
 */
import { Col, Modal } from 'antd'
import React, { ReactElement } from 'react'
import styles from './modal.module.css'
import Qrcode from 'qrcode.react'

interface qrCodeModalProps {
  visible: boolean
  onCancel: () => void
  onOk: () => void
  sendMsgObj?: any
  isBirthday?: boolean
}
export const QrCodeModal = ({
  visible,
  onCancel,
  onOk,
  sendMsgObj,
  isBirthday = false,
}: qrCodeModalProps): ReactElement => {
  return (
    <Modal
      title='患者二维码'
      width={600}
      visible={visible}
      onCancel={() => {
        onCancel()
      }}
      onOk={() => {
        onOk()
      }}
      footer={null}
      destroyOnClose
      centered
      wrapClassName={styles.qrMessageModal}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Col className={styles.QrCodeTitle}>微信扫一扫，随时关注最新信息</Col>
      <Col className={styles.QrCodeSubTitle}>患者姓名：张三</Col>
      <div className={styles.QrCodeMain}></div>
    </Modal>
  )
}
