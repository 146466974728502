/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-09 13:59:51
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-14 14:43:30
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  pageDurgsystem,
  selectDurgState,
  setCurrent,
  setDurgState,
} from "./durgsystemSlice";
import styles from "./Durgsystem.module.css";

export const Durgsystemquery = () => {
  const State = useSelector(selectDurgState);
  const dispatch = useDispatch();
  function getpage() {
    dispatch(pageDurgsystem(1, 10, State));
  }
  useEffect(() => {
    dispatch(setCurrent(1));
    getpage();
  }, [State]);
  return (
    <div className={styles.queryA}>
      <a
        onClick={() => {
          dispatch(setDurgState(undefined));
        }}
        style={{
          backgroundColor: State === undefined ? "#eef8ff" : "#ffffff",
          color: State === undefined ? "#027AFF" : "#666666",
          border: State === undefined ? "" : "1px solid #DDDDDD",
        }}
      >
        全部
      </a>
      <a
        onClick={() => {

          dispatch(setDurgState(1));

        }}
        style={{
          backgroundColor: State === 1 ? "#eef8ff" : "#ffffff",
          color: State === 1 ? "#027AFF" : "#666666",
          border: State === 1 ? "" : "1px solid #DDDDDD",
        }}
      >
        未注销
      </a>
      <a
        onClick={() => {
          dispatch(setDurgState(0));
        }}
        style={{
          backgroundColor: State === 0 ? "#eef8ff" : "#ffffff",
          color: State === 0 ? "#027AFF" : "#666666",
          border: State === 0 ? "" : "1px solid #DDDDDD",
        }}
      >
        已注销
      </a>
    </div>
  );
};
