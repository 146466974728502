/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-10 14:13:05
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../../app/store'
import { sendAsync } from '../../../../app/applicationSlice'
import * as DT from '../../../../services/scheduleOfExpenses'

// 列表
export const getScheduleOfExpensesList = createAsyncThunk<
  void,
  DT.ScheduleOfExpensesPrams,
  RootThunkApi<void>
>('clinic/getScheduleOfExpensesList', async (params, api) => {
  return sendAsync(DT.scheduleOfExpensesList(params), api)
})

// 合计行
export const getScheduleOfExpensesTotalList = createAsyncThunk<
  void,
  DT.ScheduleOfExpensesPrams,
  RootThunkApi<void>
>('clinic/getScheduleOfExpensesTotalList', async (params, api) => {
  return sendAsync(DT.scheduleOfExpensesTotalList(params), api)
})


// 导出
export const getScheduleOfExpensesListExport = createAsyncThunk<
  void,
  DT.ScheduleOfExpensesPrams,
  RootThunkApi
>('stand/getUseDetailExport', async (params, api) => {
  return sendAsync(DT.getExportFn(params), api)
})

