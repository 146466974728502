import { unwrapResult } from "@reduxjs/toolkit";
import { Col } from "antd";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootDispatch } from "../../app/store";
import { EditableList } from "../../compnents/list/EditableList";
import { NavBar } from "../../compnents/nav/NavBar";
import { StateSwitch } from "../../compnents/widgets/StateSwitch";
import { PrintTemplate } from "../../models/print";
import { GetPrintTemplateListParams } from "../../services/print";
import { ThemeContext } from "../../theme/ThemeContext";
import {
  assginPrintTemplate,
  getPrintTemplateList,
  removePrintTemplate,
  selectPrintTemplatePage,
} from "./printSlice";

export const PrintAssignment = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const theme = useContext(ThemeContext);

  const { tenantId } = useParams<{ tenantId?: string }>();

  const page = useSelector(selectPrintTemplatePage);

  const [params, setParams] = useState<GetPrintTemplateListParams>({
    current: 1,
    tenantId,
  });

  useEffect(() => {
    dispatch(getPrintTemplateList(params));
  }, [params]);

  return (
    <Col
      style={{
        height: "calc(100% - 20px)",
        overflow: "hidden",      
        display: "flex",
        flexDirection: "column",
      }}
    >
     

      <Col
        style={{
          flex: 1,
          marginTop: 10,
          padding: 15,
          backgroundColor: theme.pn,
          borderRadius: theme.br,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <EditableList<PrintTemplate>
          style={{ flex: 1, overflow: "auto" }}
          page={page}
          columns={[
            {
              title: "序号",
              key: "no",
              align: "center",
              width: "3rem",
              render: (_1, _2, i) =>
                `${page.size * (page.current - 1) + i + 1}`,
            },
            {
              title: "模版描述",
              dataIndex: "templateDesc",
              align: "center",
            },
            {
              title: "分配状态",
              key: "state",
              align: "center",
              width: "10rem",
              render: function ShowState(_, t) {
                return (
                  <StateSwitch
                    value={t.assigned}
                    onLabel="已分配"
                    offLabel="未分配"
                    onChange={(value) => {
                      let promise: Promise<any>;
                      if (value) {
                        promise = dispatch(
                          assginPrintTemplate({
                            ...t,
                            templateId: t.id,
                            tenantId,
                            id: "",
                          })
                        );
                      } else {
                        promise = dispatch(removePrintTemplate(t.templateId));
                      }
                      promise
                        .then(unwrapResult)
                        .then(() => {
                          dispatch(getPrintTemplateList(params));
                        })
                        .catch(() => {
                          // do nothing.
                        });
                    }}
                  />
                );
              },
            },
          ]}
          onChangePage={(current, size) =>
            setParams({ ...params, current, size })
          }
        />
      </Col>
    </Col>
  );
};
