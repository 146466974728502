/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-12 17:50:42
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-06 20:56:34
 */
import { TableColumnType } from 'antd'

export const StatementColumns = (
  onclick: (id: any) => void,
  startIndex: number,
  data?: any,
  printNoDataCustomizedAmountArray?: any
): TableColumnType<any>[] => {
  const cols: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
      width: '3rem',
    },
    {
      title: '操作员姓名',
      dataIndex: 'operateUserName',
      align: 'center',
      key: 'operateUserName',
    },

    {
      title: '结账周期',
      dataIndex: 'checkStartTime',
      align: 'center',
      key: 'checkStartTime',
      render: (_, t) => {
        return t.checkStartTime + '~' + t.checkEndTime
      },
    },
    // {
    //   title: "实收金额(元)",
    //   dataIndex: "profit",
    //   align: "center",
    // },
    {
      title: '总费用',
      dataIndex: 'totalPayAmount',
      align: 'right',
      key: 'totalAmount',
      render: (_, t) => {
        return t.totalPayAmount?.toFixed(2)
      },
    },
    {
      title: '应收金额',
      dataIndex: 'shouldPayAmount',
      align: 'right',
      render: (_, t) => {
        return t.shouldPayAmount?.toFixed(2)
      },
    },
    {
      title: '医保报销金额',
      dataIndex: 'insurancePayAmount',
      align: 'right',
      render: (_, t) => {
        return t.insurancePayAmount?.toFixed(2)
      },
    },
    {
      title: '聚合支付金额',
      dataIndex: 'aggregateAmount',
      align: 'right',
      render: (_, t) => {
        return t.aggregateAmount?.toFixed(2)
      },
    },
    {
      title: '现金',
      dataIndex: 'cashAmount',
      align: 'right',
      key: 'cashAmount',
      render: (_, t) => {
        return t.cashAmount?.toFixed(2)
      },
    },
    {
      title: '支付宝',
      dataIndex: 'alipayAmount',
      align: 'right',
      key: 'alipayAmount',
      render: (_, t) => {
        return t.alipayAmount?.toFixed(2)
      },
    },
    {
      title: '微信',
      dataIndex: 'wechatAmount',
      align: 'right',
      key: 'wechatAmount',
      render: (_, t) => {
        return t.wechatAmount?.toFixed(2)
      },
    },
    {
      title: '会员卡支付',
      dataIndex: 'membershipCardPaymentAmount',
      align: 'right',
      render: (_, t) => {
        return t.membershipCardPaymentAmount?.toFixed(2)
      },
    },
    // {
    //   title: "挂账金额",
    //   dataIndex: "buyOnCreditAmount",
    //   align: "right",
    // },
    // {
    //   title: "挂账支付金额",
    //   dataIndex: "payBuyOnCreditAmount",
    //   align: "right",
    // },
    {
      title: '其他',
      dataIndex: 'otherPaymentAmount',
      align: 'right',
      key: 'otherPaymentAmount',
      render: (_, t) => {
        return t.otherPaymentAmount?.toFixed(2)
      },
    },
  ]

  if (data?.length) {
    const customizedCollectionAmountArrayList = data?.[0]?.customizedCollectionAmountArray
      ?.map((k: any) => {
        return { ...k }
      })
      ?.sort((a: any, b: any) => a.payMethodName.localeCompare(b.payMethodName))
    if (customizedCollectionAmountArrayList?.length > 0) {
      const iv = customizedCollectionAmountArrayList
      for (let i = 0; i < iv.length; i++)
        cols.push({
          title: iv[i]?.payMethodName,
          dataIndex: 'otherPay_' + i,
          align: 'right',
          key: 'otherPay_' + i,
          render: (_, r: any) => {
            const value = r?.customizedCollectionAmountArray.filter(
              (vv: any) => vv.payMethodName == iv[i]?.payMethodName
            )[0]?.payAmount
            return Number(value)?.toFixed(2)
          },
        })
    }
  } else {
    const customizedCollectionAmountArrayList = printNoDataCustomizedAmountArray
      ?.map((k: any) => {
        return { ...k }
      })
      ?.sort((a: any, b: any) => a.payMethodName.localeCompare(b.payMethodName))
    if (customizedCollectionAmountArrayList?.length > 0) {
      const iv = customizedCollectionAmountArrayList
      for (let i = 0; i < iv.length; i++)
        cols.push({
          title: iv[i]?.payMethodName,
          dataIndex: 'otherPay_' + i,
          align: 'right',
          key: 'otherPay_' + i,
        })
    }
  }

  return cols
}
