import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Space, Tabs, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styles from "./patientLable.module.css";
import {
  EditableList,
} from "../../../compnents/list/EditableList";
import { Columns } from "../../../features/patient/patientLable/columns";
import { LableQuery } from "../patientLable/Query";
import {
  getLableList,
  getLableListDelete,
  selectCurrent,
  selectPageLoading,
  selectPatientLable,
  selectTotal,
  setCurrent,
  setLableId,
  setPageLoading,
  editSubLabel,
  listSubLabel
} from "./patientlableSlice";
import { PatientTableParams } from "../../../services/patientlable";
import { useParams } from "react-router-dom";
import { LobleModal } from "./Modal";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../../app/store";

interface PatientLableProps {
  state: string
}

export const PatientLable = ({ state }: PatientLableProps): ReactElement => {

  const dispatch = useDispatch<RootDispatch>();

  const { tenantId } = useParams<{ tenantId: string }>();

  const [queries, setQueries] = useState<PatientTableParams>();


  const pageLoadingRX = useSelector(selectPageLoading);

  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectPatientLable);

  const [size, setSize] = useState(10);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();

  const [visableAuto, setVisableAuto] = useState(false)

  const [activeKey, setActiveKey] = useState('1')

  const [status, setStatus] = useState(false)

  const [labelData, setLabelData] = useState<any>([])

  const [example, setExample] = useState('')

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const getLabels = (v: string) => {
    dispatch(listSubLabel({ category: v }))
      .then(unwrapResult)
      .then((res: any) => {
        setStatus(res?.state == 1 ? false : true)
        setLabelData(res?.list)
        if (v == '1') {
          form.setFieldsValue({
            age: res?.list?.length ? res?.list?.map((v: any) => {
              return {
                lowerLimit: v?.lowerLimit,
                upperLimit: v?.upperLimit,
                name: v?.name,
                id: v?.id
              }
            }) : ['']
          })
        } else if (v == '2') {
          form.setFieldsValue({
            sex: 
            res?.list?.length ? res?.list?.map((v: any) => {
              return {
                reference: v?.reference,
                name: v?.name,
                id: v?.id
              }
            }) : 
            [{ reference: '男', name: '男' }, { reference: '女', name: '女' }, { reference: '未知', name: '未知' }]
          })
        } else if (v == '3') {
          form.setFieldsValue({
            disease: res?.list?.length ? res?.list?.map((v: any) => {
              return {
                reference: v?.reference,
                name: v?.name,
                id: v?.id
              }
            }) : ['']
          })
        } else {
          if (res?.optInsuplcAdmdvs == 1 && res?.optInsuplcType == 1) {
            setExample('本地(职工)')
          } else if (res?.optInsuplcAdmdvs == 1 && res?.optInsuplcType == 0) {
            setExample('本地')
          } else if (res?.optInsuplcAdmdvs == 0 && res?.optInsuplcType == 1) {
            setExample('职工')
          } else {
            setExample('')
          }
          form.setFieldsValue({
            optInsuplcAdmdvs: res?.optInsuplcAdmdvs,
            optInsuplcType: res?.optInsuplcType,
          })
        }
      })
  }

  useEffect(() => {
    state && sessionStorage.setItem(location.pathname, JSON.stringify({ state }))
  }, [state])

  useEffect(() => {
    dispatch(setPageLoading(true))
    dispatch(getLableList({ ...queries, current: Current, size, tenantId, level: 1 }))
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }, [queries, size, Current, tenantId]);


  return (
    <>
      {/* <div
        style={{ height:"30px",margin: "10px 0 0 0",display:"flex",alignItems:"center",borderRadius:"10px" ,paddingLeft:"20px",justifyContent:"right"}}>

            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsModalVisible(true)}
            >
             新增
              </Button>
          </div> */}
      <div className={styles.content}>
        <LableQuery
          onValueChange={(v) => {
            setQueries({ ...queries, ...v });
            dispatch(setCurrent(1));
          }}
          onAdd={() => setIsModalVisible(true)}
          onSetting={() => {
            setVisableAuto(true)
            getLabels(activeKey)
          }}
        />
        <EditableList
          className={styles.patientLable}
          loading={pageLoadingRX}
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number);
            dispatch(setCurrent(current));
          }}
          columns={Columns((text, id,) => {
            if (text === "edit") {
              dispatch(setLableId(id));
              setIsModalVisible(true)
            }
            if (text === "delete") {
              dispatch(getLableListDelete({ id }))
              .then(unwrapResult)
              .then(() => {
                setQueries({ ...queries });
                dispatch(setCurrent(1));
              })
            }
          }, size * (Current - 1))}

        />
      </div>
      <LobleModal
        visible={isModalVisible}
        onOk={() => {
          dispatch(getLableList({ ...queries, current: Current, size, tenantId, level: 1 }))
          dispatch(setLableId(0))
          setIsModalVisible(false);

        }}
        onCancel={() => {
          dispatch(getLableList({ ...queries, current: Current, size, tenantId, level: 1 }));
          dispatch(setLableId(0))
          // dispatch(setCurrent(Current))
          setIsModalVisible(false);

        }} />

      <Modal
        className={styles.autoLabel}
        cancelText="取消"
        okText="确定"
        visible={visableAuto}
        title="自动标签设置"
        onCancel={() => {
          setVisableAuto(false)
          setActiveKey('1')
          form.resetFields()
        }}
        width={700}
        onOk={() => {
          form
            .validateFields()
            .then((values: any) => {
              console.log(values);

              dispatch(editSubLabel({
                labelList: activeKey == '1' ? values?.age
                  : activeKey == '2' ? values?.sex
                    : activeKey == '3' ?
                      values?.disease : activeKey == '4' ? undefined : [],
                category: activeKey,
                state: status ? 0 : 1,
                optInsuplcAdmdvs: activeKey == '4' ? (values?.optInsuplcAdmdvs ? 1 : 0) : undefined,
                optInsuplcType: activeKey == '4' ? (values?.optInsuplcType ? 1 : 0) : undefined,
              }))
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '操作成功'
                })
                form.resetFields()
                setVisableAuto(false)
                dispatch(getLableList({ ...queries, current: Current, size, tenantId, level: 1 }));
                setActiveKey('1')
              })
            })
        }}
      >
        <Form
          form={form}
          onFinish={(values) => {
            console.log(values);

          }}
          {...layout}
        >
          <Tabs
            activeKey={activeKey}
            // className={styles.patientInfoTabs}
            onChange={(v) => {
              setActiveKey(v)
              getLabels(v)
            }}
            destroyInactiveTabPane
          >
            <Tabs.TabPane tab='年龄标签' key="1">
              <Form.List name="age">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <span>年龄：</span>
                        <Form.Item {...restField} name={[name, 'lowerLimit']} rules={[{ required: true, message: '' }]} >
                          <Input style={{ width: 60 }} />
                        </Form.Item>
                        <span>~</span>
                        <Form.Item {...restField} name={[name, 'upperLimit']} rules={[{ required: true, message: '' }]} >
                          <Input style={{ width: 60 }} />
                        </Form.Item>
                        <span>岁</span>
                        <span style={{ paddingLeft: '16px' }}>标签名：</span>
                        <Form.Item {...restField} name={[name, 'name']} rules={[{ required: true, message: '' }]} >
                          <Input style={{ width: 200 }} />
                        </Form.Item>
                        {index == 0 ? (
                          <PlusCircleOutlined
                            onClick={() => { add() }}
                          />
                        ) : (
                          <MinusCircleOutlined onClick={() => {
                            remove(name)
                            if (labelData?.length >= name + 1) {
                              dispatch(getLableListDelete({ id: labelData[name]?.id }))
                                .then(unwrapResult)
                                .then(() => {
                                  dispatch(listSubLabel({ category: '1' }))
                                    .then(unwrapResult)
                                    .then((res: any) => {
                                      const list = form.getFieldValue('age')?.filter((v: any) => !v?.id)
                                      form.setFieldsValue({
                                        age: res?.list?.length ? [...res?.list?.map((v: any) => {
                                          return {
                                            lowerLimit: v?.lowerLimit,
                                            upperLimit: v?.upperLimit,
                                            name: v?.name,
                                            id: v?.id
                                          }
                                        }), ...list] : ['']
                                      })
                                    })
                                })
                            }
                          }} />
                        )}
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            </Tabs.TabPane>
            <Tabs.TabPane tab='性别标签' key="2">
              <Form.List name="sex">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <span>性别：</span>
                        <Form.Item {...restField} name={[name, 'reference']} rules={[{ required: true, message: '' }]} >
                          <Input disabled style={{ width: 60 }} />
                        </Form.Item>
                        <span style={{ paddingLeft: '16px' }}>标签名：</span>
                        <Form.Item {...restField} name={[name, 'name']} rules={[{ required: true, message: '' }]} >
                          <Input style={{ width: 200 }} />
                        </Form.Item>
                        {/* {index == 0 ? (
                          <PlusCircleOutlined
                            onClick={() => { add() }}
                          />
                        ) : (
                          <MinusCircleOutlined onClick={() => {
                            remove(name)
                            if (labelData?.length >= name + 1) {
                              dispatch(getLableListDelete({ id: labelData[name]?.id }))
                                .then(unwrapResult)
                                .then(() => {
                                  dispatch(listSubLabel({ category: '2' }))
                                    .then(unwrapResult)
                                    .then((res: any) => {
                                      const list = form.getFieldValue('sex')?.filter((v: any) => !v?.id)
                                      form.setFieldsValue({
                                        sex: res?.list?.length ? [...res?.list?.map((v: any) => {
                                          return {
                                            reference: v?.reference,
                                            name: v?.name,
                                            id: v?.id
                                          }
                                        }), ...list] : ['']
                                      })
                                    })
                                })
                            }
                          }} />
                        )} */}
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            </Tabs.TabPane>
            <Tabs.TabPane tab='疾病标签' key="3">
              <Form.List name="disease">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <span>诊断：</span>
                        <Form.Item {...restField} name={[name, 'reference']} rules={[{ required: true, message: '' }]} >
                          <Input style={{ width: 120 }} />
                        </Form.Item>
                        <span style={{ paddingLeft: '16px' }}>标签名：</span>
                        <Form.Item {...restField} name={[name, 'name']} rules={[{ required: true, message: '' }]} >
                          <Input style={{ width: 200 }} />
                        </Form.Item>
                        {index == 0 ? (
                          <PlusCircleOutlined
                            onClick={() => { add() }}
                          />
                        ) : (
                          <MinusCircleOutlined onClick={() => {
                            remove(name)
                            if (labelData?.length >= name + 1) {
                              dispatch(getLableListDelete({ id: labelData[name]?.id }))
                                .then(unwrapResult)
                                .then(() => {
                                  dispatch(listSubLabel({ category: '3' }))
                                    .then(unwrapResult)
                                    .then((res: any) => {
                                      const list = form.getFieldValue('disease')?.filter((v: any) => !v?.id)
                                      form.setFieldsValue({
                                        disease: res?.list?.length ? [...res?.list?.map((v: any) => {
                                          return {
                                            reference: v?.reference,
                                            name: v?.name,
                                            id: v?.id
                                          }
                                        }), ...list] : ['']
                                      })
                                    })
                                })
                            }
                          }} />
                        )}
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            </Tabs.TabPane>
            <Tabs.TabPane tab='医保标签' key="4">
              <Form.Item name='optInsuplcAdmdvs' valuePropName='checked'>
                <Checkbox onChange={(e) => {
                  if (e.target.checked && form.getFieldValue('optInsuplcType')) {
                    setExample('本地(职工)')
                  } else if (e.target.checked && !form.getFieldValue('optInsuplcType')) {
                    setExample('本地')
                  } else if (!e.target.checked && form.getFieldValue('optInsuplcType')) {
                    setExample('职工')
                  } else {
                    setExample('')
                  }
                }}>参保地</Checkbox>
              </Form.Item>
              <Form.Item name='optInsuplcType' valuePropName='checked'>
                <Checkbox onChange={(e) => {
                  if (e.target.checked && form.getFieldValue('optInsuplcAdmdvs')) {
                    setExample('本地(职工)')
                  } else if (e.target.checked && !form.getFieldValue('optInsuplcAdmdvs')) {
                    setExample('职工')
                  } else if (!e.target.checked && form.getFieldValue('optInsuplcAdmdvs')) {
                    setExample('本地')
                  } else {
                    setExample('')
                  }
                }}>参保类型</Checkbox>
              </Form.Item>
              {example && (
                <Form.Item>
                  <Row>例：{example}</Row>
                </Form.Item>
              )}
            </Tabs.TabPane>
          </Tabs>
          <Form.Item name='state'>
            <Checkbox
              checked={status}
              onChange={() => {
                setStatus(!status)
              }}
            >
              是否启用
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>

    </>
  );
};
