/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-10-18 17:39:24
 */
import { API_PREFIX } from './constant'
import { Request, MultiPartFormData } from './request'
export enum Gender {
    Unknown = 0,
    Male = 1,
    Female = 2,
}
export interface BeforeOperatingParmas {
    current?: any
    size?: any
}
// 手术列表
export function getBeforeList(params: BeforeOperatingParmas): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/operatingbill/sqrz/page`,
        method: 'POST',
        body: params,
    }
}
// 手术详情
export function getOperatingDetail(id: string): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/operatingbill/sqrz/detail`,
        method: 'GET',
        params: {
            id
        },
    }
}
// 手术状态
export function getOperatingStatus(id: string): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/operatingbill/sqrz/cancel`,
        method: 'GET',
        params: {
            id
        },
    }
}
// 申诉列表
export function getAppealList(params: BeforeOperatingParmas): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/operatingbill/sqrz-appeal/page`,
        method: 'POST',
        body: params
    }
}
// 详情
export function getAppealDeatil(id: string): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/operatingbill/sqrz-appeal/detail`,
        method: 'GET',
        params: {
            id
        }
    }
}
// 举证
export function getProof(params: any): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/operatingbill/sqrz/proof`,
        method: 'POST',
        body: params
    }
}
// 文件上传
export function uploadFile(params: any): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/operatingbill/sqrz/simpleUpload`,
        method: 'POST',
        headers: {
            'Content-Type': MultiPartFormData
        },
        body: params
    }
}

// 申诉过程
export function appealProcess(id: string): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/operatingbill/sqrz/process`,
        method: 'GET',
        params: {
            operationId: id
        }
    }
}



export interface ExcuteParams {
    id?: number
    state?: number
    useMethod?: string
    recipeId?: string
    range?: number
    treatmentId?: string
}
export function getExecute(params: ExcuteParams): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/recipeexecute/execute`,
        method: 'GET',
        params,
    }
}
