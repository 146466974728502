import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { queries } from '@testing-library/react'
import {
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
  Col,
} from 'antd'
import { head, tail } from 'lodash'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'

export const PurchaseAcceptanceQuery = (props: {
  onValueChange: (params: any) => void
  outCluedExport: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const [form] = Form.useForm()

  const inputRef = useRef<any>(null)

  const location: any = useLocation()

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  useEffect(() => {
    if (Object.keys(storageParams).length) {
      form.setFieldsValue({
        ...storageParams,
        timeHead: storageParams?.timeHead || head,
        timeTail: storageParams?.timeTail || tail,
         [DateRangeFieldName]: storageParams?._dateRange || DateRangeType.ThisMonth,
      })
    } 
    else {
      const [head, tail] = getDateRange(DateRangeType.ThisMonth)
      form.setFieldsValue({
        _dateRange: 3,
        timeHead: head,
        timeTail: tail,
      })
    }
    form.submit()
  }, [])

  const refresh = () => {
    props.onValueChange({
      ...storageParams,
      ...form.getFieldsValue(),
      current: 1,
    })
  }

  return (
    <Form
      form={form}
      initialValues={{
        timeHead: head,
        timeTail: tail,
        [DateRangeFieldName]: DateRangeType.ThisMonth,
      }}
      onFinish={(vs) => {
        const data = storageParams
        props.onValueChange({
          ...data,
          ...vs,
          current: data.current || 1,
        })
      }}
    >
      <Row
        style={{ margin: '20px 0px' }}
        align='middle'
        justify='space-between'
      >
        <Space>
          <Form.Item noStyle name='param'>
            <Input
              placeholder='商品名称'
              allowClear
              onChange={refresh}
              prefix={<SearchOutlined />}
            />
          </Form.Item>
          <DateSelect
            namePrefix='time'
            labelPrefix=''
            placeholder='时间范围'
            style={{ width: 135 }}
            initialValue={DateRangeType.ThisMonth}
            onChange={refresh}
            allowClear={false}
          />
          <Button
            style={{ marginRight: '10px' }}
            type='primary'
            onClick={refresh}
          >
            查询
          </Button>
        </Space>
        <Col>
          <Button
            type='primary'
            onClick={() => props?.outCluedExport && props?.outCluedExport()}
          >
            导出
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
