import { Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditableListRef } from "../../../../compnents/containers/EditableList";
import { selectUserName } from "../../../../app/applicationSlice";
import { getPitestList, selectCurrent, selectDetail, startpitest } from "./nopitestSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../../../app/store";
const { Option } = Select;
export const RegisterModal = (props: {
  isModalRegisterVisible: boolean;
  queries?:any;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const dispatch = useDispatch<RootDispatch>();


  const Current = useSelector(selectCurrent);

  const userName = useSelector(selectUserName);

  const profile = useSelector(selectDetail);

  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        ...profile,
        result:undefined,
        name:userName,
        });
    }
  }, [profile]);

  return (
    <Modal
      cancelText="取消"
      okText="确定"
      onCancel={() => {
        form.resetFields();
        props.onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            dispatch(
              startpitest({
                 ...profile,
                ...values, 
              })
            )
            .then(unwrapResult)
            .then(()=>{
              dispatch(getPitestList({...props.queries, current: Current, size:10 }));
            })      
            form.resetFields()
            props.onOk();
          })
          .catch(() => {
            // do nothing.
          });
      }}
      visible={props.isModalRegisterVisible}
      title="皮试登记"
      width="450px"
    >
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="advanced_search"
        className="ant-advanced-search-form"
        {...layout}
      >
        <Row gutter={20}>
          <Col span={20} style={{ textAlign: "right" }}>
            <Form.Item
              label="皮试人员"
              name="name"
            
              rules={[{ required: true, message: "皮试人员不能为空!" }]}
            >
              <Input autoComplete="off"  disabled/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={20} style={{ textAlign: "right" }}>
            <Form.Item
              label="皮试开始时间"
              name="startTime">
              <Input autoComplete="off"   disabled/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={20} style={{ textAlign: "left" }}>
            <Form.Item
              style={{ display: "flex" }}
              label="皮试结果"
              name="result"
              rules={[{ required: true, message: "皮试结果不能为空!" }]}
            >
              <Select placeholder="皮试结果" allowClear>
                <Option value="0"> 阴性</Option>
                <Option value="1">阳性</Option>
                <Option value="2">弱阳性</Option>
                <Option value="3">强阳性</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
