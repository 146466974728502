import { RecipeCategory } from "./recipe";

export enum Gender {
  Unknown = 0,
  Male = 1,
  Female = 2,
}

export const GenderOptions = [Gender.Unknown, Gender.Male, Gender.Female];

export const GenderNames = ["未知", "男", "女"];

export enum registrationCategory {
  Nurse = 0,
  DoctorOrPharmacist = 1,
  Warehouse = 2,
  Count = 3,
}

export const StationRegistrations = [
  registrationCategory.DoctorOrPharmacist,
  registrationCategory.Nurse,
  registrationCategory.Warehouse,
  registrationCategory.Count,
];

export const StationRegistrationNames = [
  "未就诊",
  "已就诊",
  "就诊中",
  "已退号",
];

export function getStationCategoryName(category: registrationCategory): string {
  if (category < 0 || category >= StationRegistrationNames.length) {
    return "";
  }
  return StationRegistrationNames[category];
}




export interface Modelment {
  id: string;
  drugCount: number;
  executeTime: string;
  executeUser: string;
  groupNumber: string;
  name: string;
  normalDose: number;
  normalFrequency: string;
  normalUsemethod: string;
  state: string;
  amount: number;
  category: RecipeCategory;
  registrationId: string;
  doctorId: number;
  doctorName: string;
  patientId: number;
  patientName: string;
  recipeTime: string;
  status: number;
  tenantId: number;
  tip: number;
  treatmentId: string;
  useMethod: string;
  medicareCode: string;
  outpatientNo: number;
  patientAge: number;
  gender: Gender;
  patientPhone: string;
  // patientSex:string,
  receiveInAdvance: string;
  receiveTag: string;
  registrationCategory: string;
  registrationDepartmentId: number;
  registrationDepartmentName: string;
  registrationDoctorId: number;
  registrationDoctorName: string;
  registrationFeeServiceId: number;
  registrationPayState: number;
  registrationState: registrationCategory;
  registrationTime: string;
  settlementTypesOfInsurance: number;

  treatmentDepartmentId: number;
  treatmentDepartmentName: string;
  treatmentDoctorId: number;
  treatmentDoctorName: string;
  treatmentTime: string;
}

export function fromJson(json: any): Modelment {
  return {
    id: json.id?.toString() || "",
    drugCount: json.drugCount,
    executeTime: json.executeTime,
    executeUser: json.executeUser,
    groupNumber: json.groupNumber,
    gender: json.patientSex,
    name: json.name,
    normalDose: json.normalDose,
    normalFrequency: json.normalFrequency,
    normalUsemethod: json.normalUsemethod,
    state: json.state,
    amount: json.amount,
    category: json.category,
    doctorId: json.doctorId,
    doctorName: json.doctorName,
    patientId: json.patientId,

    patientName: json.patientName,
    recipeTime: json.recipeTime,

    status: json.status,
    tenantId: json.tenantId,
    tip: json.tip,
    treatmentId: json.treatmentId,
    useMethod: json.useMethod,
    medicareCode: json.medicareCode,
    outpatientNo: json.outpatientNo,
    patientAge: json.patientAge,
    patientPhone: json.patientPhone,
    // patientSex:json.,
    receiveInAdvance: json.receiveInAdvance,
    receiveTag: json.receiveTag,
    registrationCategory: json.registrationCategory,
    registrationDepartmentId: json.registrationDepartmentId,
    registrationDepartmentName: json.registrationDepartmentName,
    registrationDoctorId: json.registrationDoctorId,
    registrationDoctorName: json.registrationDoctorName,
    registrationFeeServiceId: json.registrationFeeServiceId,
    registrationPayState: json.registrationPayState,
    registrationState: json.registrationState,
    registrationTime: json.registrationTime,
    settlementTypesOfInsurance: json.settlementTypesOfInsurance,
    registrationId:json.registrationId,
    treatmentDepartmentId: json.treatmentDepartmentId,
    treatmentDepartmentName: json.treatmentDepartmentName,
    treatmentDoctorId: json.treatmentDoctorId,
    treatmentDoctorName: json.treatmentDoctorName,
    treatmentTime: json.treatmentTime,
  };
}
