import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Row, Select, Space, Table } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as echarts from 'echarts'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../compnents/form/DateSelect'
import styles from './dynamicGrossProfitStatement.module.css'
import { ThemeContext } from '../../theme/ThemeContext'
import { RootDispatch } from '../../app/store'
import { getDynamicGrossProfitStatementList } from './dynamicGrossProfitStatementSlice'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { getMaterialsName, Materiales } from '../../models/commodity'
import {
  BillingCategoryName,
  BillingCategories,
} from '../../models/billingCategory'
import moment from 'moment'
import noDataPng from './images/noData.png'

const { Option } = Select

export const DynamicGrossProfitStatementLists = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const ref = useRef<any>(null)

  const [currentTab, setCurrentTab] = useState('0')

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [options, setOptions] = useState<any>()

  const [timeHead, timeTail] = getDateRange(DateRangeType.ThisMonth)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    startTime: timeHead,
    endTime: timeTail,
  })

  const [page, setPage] = useState<any>()

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getDynamicGrossProfitStatementList({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (res?.length) {
          setPage(res)
        } else {
          setPage([])
        }
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params])

  useEffect(() => {
    if (page) {
      const xData = page?.map((v: any) => {
        return v?.date ? moment(v?.date).format('YYYYMMDD') : ''
      })
      const myChart = echarts?.init(ref?.current)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '4%',
          right: '2%',
          bottom: '15em',
          top: '60em',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            rotate: xData?.length > 25 ? 30 : 0,
            inside: false,
            textStyle: {
              color: '#000',
              fontSize: xData?.length > 25 ? '8' : '12',
              itemSize: '',
            },
          },
        },
        yAxis: [
          {
            name: '金额（元）',
            type: 'value',
            min: 0,
          },
        ],
        series: [
          {
            data: page?.map((v: any) => {
              return v?.value 
            }),
            type: 'bar',
          },
        ],
      }
      option && myChart.setOption(option)
    }
  }, [page])

  return (
    <TabBar
      className={styles.tabs}
      activeKey={currentTab}
      onChange={(t) => setCurrentTab(t)}
    >
      <TabPane tab='毛利动态统计' key='0'>
        <Col className={styles.content}>
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Form
              form={form}
              autoComplete='off'
              onFinish={(values) => {
                setParams({
                  ...params,
                  ...values,
                  current: 1,
                  startTime: values?.timeHead ? values?.timeHead : timeHead,
                  endTime: values?.timeTail ? values?.timeTail : timeTail,
                  timeHead: undefined,
                  timeTail: undefined,
                  billingCategory: undefined,
                  billingCategoryList: values?.billingCategory,
                })
              }}
            >
              <Row
                align='middle'
                justify='space-between'
                style={{ marginTop: 10, marginBottom: 20 }}
              >
                <Space>
                  <Form.Item name='materialsCategory' label='物资类别' noStyle>
                    <Select
                      placeholder='物资类别'
                      allowClear
                      style={{ width: '200px' }}
                      onChange={() => {
                        form.submit()
                      }}
                    >
                      {Materiales.map((v) => (
                        <Option value={v} key={v}>
                          {getMaterialsName(v)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name='billingCategory' noStyle>
                    <Select
                      mode='multiple'
                      style={{ minWidth: '200px' }}
                      onChange={() => {
                        form.submit()
                      }}
                      placeholder='请选择开票项目'
                      allowClear
                    >
                      {BillingCategories.map((v) => (
                        <Option value={v} key={v}>
                          {BillingCategoryName(v)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item name='tenantSupplierName' noStyle>
                    <Input
                      size='middle'
                      placeholder='搜索费用名称'
                      allowClear
                      onChange={() => {
                        form.submit()
                      }}
                    />
                  </Form.Item> */}
                  <DateSelect
                    style={{ width: '8.5rem' }}
                    labelPrefix=''
                    allowClear={false}
                    options={TJFXTime}
                    namePrefix='time'
                    placeholder='时间'
                    initialValue={DateRangeType.ThisMonth}
                    onChange={form.submit}
                  />
                  <Button type='primary' onClick={form.submit}>
                    查询
                  </Button>
                </Space>
              </Row>
            </Form>
          </Col>
          {/* <div>
              <img
                src={noDataPng}
                style={{ width: '270px', height: '536px' }}
              ></img>
            </div> */}
          <div ref={ref} className={styles.echarts}></div>
        </Col>
      </TabPane>
    </TabBar>
  )
}
