export interface PatientPayment {
  id: string;
  disease: string;
  needPayAmount: number;
  operateUser: number;
  outpatientNo: number;
  patientId: string;
  patientName: string;
  paymentId: string;
  payMethod: number;
  realPayAmount: number;
  receivePayAmount: number;
  registrationCategory: number;
  registrationTime: string;
  returnPayAmount: number;
  shouldPayAmount: number;
  treatmentDepartmentName: string;
  treatmentDoctorName: string;
  treatmentId: string;
}

export function fromJson(
  json: { id?: number; patientId?: number; treatmentId?: number } & Record<
    string,
    never
  >
): PatientPayment {
  return {
    id: json.id?.toString() || "",
    disease: json.disease,
    needPayAmount: json.needPayAmount,
    operateUser: json.operateUser,
    outpatientNo: json.outpatientNo,
    patientId: json.patientId?.toString() || "",
    patientName: json.patientName,
    paymentId: json.paymentId,
    payMethod: json.payMethod,
    realPayAmount: json.realPayAmount,
    receivePayAmount: json.receivePayAmount,
    registrationCategory: json.registrationCategory,
    registrationTime: json.registrationTime,
    returnPayAmount: json.returnPayAmount,
    shouldPayAmount: json.shouldPayAmount,
    treatmentDepartmentName: json.treatmentDepartmentName,
    treatmentDoctorName: json.treatmentDoctorName,
    treatmentId: json.treatmentId?.toString() || "",
  };
}
