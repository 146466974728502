/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-10 09:32:13
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-20 14:00:52
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import {
  DateSelect as DateSelectOne,
  RecentTime, RecentTimePotion,
} from '../../../compnents/date/TimeSelect'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { getSupplierList } from '../OReplaceMakeSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { SupplierListResponse } from '../../../services/OReplaceMake'
import styles from './order.module.css'
import { getShopAddress } from '../../treatment/editor/recipeEditorSlice'

export const Query = (props: {
  initValue?: any
  onValueChange: (queries: any) => void
  onExport: () => void
}) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [supplierOptions, setSupplierOptions] = useState<
    Array<SupplierListResponse>
  >([])

  const [address, setAddress] = useState<any>()

  const updateStatus = [
    {
      value: 0,
      label: '未上传',
    },
    {
      value: 1,
      label: '已上传',
    },
    {
      value: 3,
      label: '已取消',
    },
  ]
  const payStatus = [
    {
      value: 1,
      label: '已结算',
    },
    {
      value: 0,
      label: '已退费',
    },
  ]
  const replaceType = [
    {
      value: 9,
      label: '委外代煎颗粒剂',
    },
    {
      value: 1,
      label: '委外代煎袋装液体',
    },
    {
      value: 2,
      label: '委外外配药材',
    },
    {
      value: 3,
      label: '委外膏方代煎',
    },
  ]

  useEffect(() => {
    dispatch(getSupplierList())
      .then(unwrapResult)
      .then((res: Array<SupplierListResponse>) => {
        setSupplierOptions(res)
      })
  }, [])

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(v) => {
        const time = {
          pullTimeHead: null,
          pullTimeTail: null,
          createListTimeHead: v?.pullTimeHead,
          createListTimeTail: v?.pullTimeTail,
        }
        props.onValueChange({ ...v, ...time })
      }}
      style={{ paddingBottom: '20px' }}
    >
      <Row>
        <Space>
          <Form.Item name='param' noStyle>
            <Input
              size='middle'
              placeholder='患者姓名/手机号/助记码'
              onChange={form.submit}
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
          <Form.Item name='boilType' noStyle>
            <Select
              allowClear
              placeholder='代煎类别'
              onChange={form.submit}
              dropdownClassName={styles.select}
            >
              {replaceType.map((c) => (
                <Select.Option key={c.label} value={c.value}>
                  代煎类别-{c.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='supplierId' noStyle>
            <Select allowClear placeholder='代煎饮片厂' onChange={form.submit}>
              {supplierOptions.length &&
                supplierOptions?.map((c) => (
                  <Select.Option key={c.name} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name='addressType' noStyle>
            <Select
              allowClear
              placeholder='收货位置'
              onChange={form.submit}
              dropdownClassName={styles.select}
            >
              <Select.Option key={0} value={0}>
                患者地址
              </Select.Option>
              <Select.Option key={1} value={1}>
                诊所自取
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='status' noStyle>
            <Select
              allowClear
              placeholder='上传状态'
              onChange={form.submit}
              dropdownClassName={styles.selects}
            >
              {updateStatus.map((c) => (
                <Select.Option key={c.label} value={c.value}>
                  上传状态-{c.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='state' noStyle>
            <Select
              allowClear
              placeholder='结算状态'
              onChange={form.submit}
              dropdownClassName={styles.selects}
            >
              {payStatus.map((c) => (
                <Select.Option key={c.label} value={c.value}>
                  结算状态-{c.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle>
            <DateSelectOne
              placeholder='开单时间'
              options={RecentTimePotion}
              labelPrefix=''
              onChange={form.submit}
              namePrefix='pullTime'
            />
          </Form.Item>
          <Button htmlType='submit' type='primary'>
            查询
          </Button>
        </Space>
        <Space style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Button type='primary' onClick={() => props.onExport()}>
            导出
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
