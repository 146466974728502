import { Button, TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import { DateTimeFormat } from '../../../models/datetime'
import { Dimen } from '../../../models/dimen'
import { getGenderName } from '../../../models/user'
import { getAge } from '../../../utils/StringUtils'

export const ToBeCompletedOperationColumns = (
  onClick: (
    text: string,
    id: any,
    mainDoctorId?: any,
    registrationId?: any,
    treatmentNo?: any
  ) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      dataIndex: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '手术时间',
      dataIndex: 'appointmentTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => {
        return t.appointmentTime
          ? moment(t.appointmentTime).format(DateTimeFormat)
          : '-'
      },
    },
    {
      title: '时长',
      dataIndex: 'appointmentDuration',
      ellipsis: true,
      width: '8em',
      align: 'center',
      render: (_, t) => {
        return t.appointmentDuration ? t.appointmentDuration + ' min' : '-'
      },
    },
    {
      title: '手术室',
      dataIndex: 'operatingRoomName',
      ellipsis: true,
      width: '8em',
      align: 'center',
      render: (_, t) => {
        return t.operatingRoomName ? t.operatingRoomName : '-'
      },
    },
    {
      title: '门诊号',
      dataIndex: 'outpatientNo',
      ellipsis: true,
      width: '5rem',
      align: 'center',
      render: (_, t) => {
        return Number(t.outpatientNo) > 0 ? t.outpatientNo : '-'
      },
    },
    {
      title: '患者姓名',
      dataIndex: 'patientName',
      ellipsis: true,
      width: '6em',
      align: 'center',
      render: (_, t) => {
        return t.patientName ? t.patientName : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'patientSex',
      ellipsis: true,
      width: '4em',
      align: 'center',
      render: (_: string, u: any) => getGenderName(u.patientSex),
    },
    {
      title: '年龄',
      dataIndex: 'patientAge',
      ellipsis: true,
      width: '5em',
      align: 'center',
      render: (_, r) => getAge(r?.patientAge, r?.patientMonth),
    },
    {
      title: '术前诊断',
      ellipsis: true,
      width: '8em',
      dataIndex: 'operatingDiagnoseBefore',
      align: 'center',
      render: (_, t) => {
        return t.operatingDiagnoseBefore ? t.operatingDiagnoseBefore : '-'
      },
    },
    {
      title: '手术名称',
      ellipsis: true,
      width: '8em',
      dataIndex: 'operatingContent',
      align: 'center',
      render: (_, t) => {
        const operatingContentList =
          t.operatingContent[0] === '['
            ? JSON.parse(t.operatingContent)
            : t.operatingContent
        return operatingContentList[0]?.surgIcalName || ''
      },
    },
    {
      title: '主刀医生',
      ellipsis: true,
      width: '8em',
      dataIndex: 'mainDoctorName',
      align: 'center',
    },
    {
      title: '麻醉医生',
      ellipsis: true,
      width: '8em',
      dataIndex: 'anesthesiaDoctorName',
      align: 'center',
      render: (_, t) => {
        return t.anesthesiaDoctorName ? t.anesthesiaDoctorName : '-'
      },
    },
    {
      title: '麻醉方式',
      ellipsis: true,
      width: '7em',
      dataIndex: 'anesthesiaMethod',
      align: 'center',
      render: (_, t) => {
        return t.anesthesiaMethod ? t.anesthesiaMethod : '-'
      },
    },
    {
      title: '其他人员',
      dataIndex: 'helper',
      ellipsis: true,
      width: '8em',
      align: 'center',
      render: (_, t) => {
        const helperList = t.helper[0] === '[' ? JSON.parse(t.helper) : t.helper
        return helperList[0]?.assistant || ''
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: true,
      width: '8em',
      align: 'center',
      render: (_, t) => {
        return t.remark ? t.remark : '-'
      },
    },
    {
      title: '操作',
      align: 'center',
      fixed: 'right',
      width: '9rem',
      render: function Element(_, t) {
        return (
          <>
            {t.state === 0 ? (
              <>
                <Button
                  type='primary'
                  size='small'
                  onClick={() => {
                    onClick(
                      'execute',
                      t.id,
                      t.mainDoctorId,
                      t.registrationId,
                      t?.treatmentNo
                    )
                  }}
                  style={{ marginRight: '10px' }}
                >
                  执行
                </Button>
                <Button
                  type='primary'
                  size='small'
                  onClick={() => {
                    onClick('cancel', t.id)
                  }}
                >
                  取消
                </Button>
              </>
            ) : t.state === 1 ? (
              <Button
                type='primary'
                size='small'
                onClick={() => {
                  onClick(
                    'execute',
                    t.id,
                    t.mainDoctorId,
                    t.registrationId,
                    t?.treatmentNo
                  )
                }}
                style={{ marginRight: '10px' }}
              >
                执行
              </Button>
            ) : t.state === 2 ? (
              <Button
                type='primary'
                size='small'
                onClick={() => {
                  onClick('cancel', t.id)
                }}
              >
                取消
              </Button>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
  ]
}


export const ToothToBeCompletedOperationColumns = (
  onClick: (
    text: string,
    id: any,
    mainDoctorId?: any,
    registrationId?: any,
    treatmentNo?: any
  ) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      dataIndex: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '手术时间',
      dataIndex: 'appointmentTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => {
        return t.appointmentTime
          ? moment(t.appointmentTime).format(DateTimeFormat)
          : '-'
      },
    },
    {
      title: '时长',
      dataIndex: 'appointmentDuration',
      ellipsis: true,
      width: '8em',
      align: 'center',
      render: (_, t) => {
        return t.appointmentDuration ? t.appointmentDuration + ' min' : '-'
      },
    },
    {
      title: '手术室',
      dataIndex: 'operatingRoomName',
      ellipsis: true,
      width: '8em',
      align: 'center',
      render: (_, t) => {
        return t.operatingRoomName ? t.operatingRoomName : '-'
      },
    },
    {
      title: '门诊号',
      dataIndex: 'outpatientNo',
      ellipsis: true,
      width: '5rem',
      align: 'center',
      render: (_, t) => {
        return Number(t.outpatientNo) > 0 ? t.outpatientNo : '-'
      },
    },
    {
      title: '术前诊断',
      ellipsis: true,
      width: '8em',
      dataIndex: 'operatingDiagnoseBefore',
      align: 'center',
      render: (_, t) => {
        return t.operatingDiagnoseBefore ? t.operatingDiagnoseBefore : '-'
      },
    },
    {
      title: '手术名称',
      ellipsis: true,
      width: '8em',
      dataIndex: 'operatingContent',
      align: 'center',
      render: (_, t) => {
        const operatingContentList =
          t.operatingContent[0] === '['
            ? JSON.parse(t.operatingContent)
            : t.operatingContent
        return operatingContentList[0]?.surgIcalName || ''
      },
    },
    {
      title: '主刀医生',
      ellipsis: true,
      width: '8em',
      dataIndex: 'mainDoctorName',
      align: 'center',
    },
    {
      title: '麻醉医生',
      ellipsis: true,
      width: '8em',
      dataIndex: 'anesthesiaDoctorName',
      align: 'center',
      render: (_, t) => {
        return t.anesthesiaDoctorName ? t.anesthesiaDoctorName : '-'
      },
    },
    {
      title: '麻醉方式',
      ellipsis: true,
      width: '7em',
      dataIndex: 'anesthesiaMethod',
      align: 'center',
      render: (_, t) => {
        return t.anesthesiaMethod ? t.anesthesiaMethod : '-'
      },
    },
    {
      title: '其他人员',
      dataIndex: 'helper',
      ellipsis: true,
      width: '8em',
      align: 'center',
      render: (_, t) => {
        const helperList = t.helper[0] === '[' ? JSON.parse(t.helper) : t.helper
        return helperList[0]?.assistant || ''
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: true,
      width: '8em',
      align: 'center',
      render: (_, t) => {
        return t.remark ? t.remark : '-'
      },
    },
    {
      title: '操作',
      align: 'center',
      fixed: 'right',
      width: '9rem',
      render: function Element(_, t) {
        return (
          <>
            {t.state === 0 ? (
              <>
                <Button
                  type='primary'
                  size='small'
                  onClick={() => {
                    onClick(
                      'execute',
                      t.id,
                      t.mainDoctorId,
                      t.registrationId,
                      t?.treatmentNo
                    )
                  }}
                  style={{ marginRight: '10px' }}
                >
                  执行
                </Button>
                <Button
                  type='primary'
                  size='small'
                  onClick={() => {
                    onClick('cancel', t.id)
                  }}
                >
                  取消
                </Button>
              </>
            ) : t.state === 1 ? (
              <Button
                type='primary'
                size='small'
                onClick={() => {
                  onClick(
                    'execute',
                    t.id,
                    t.mainDoctorId,
                    t.registrationId,
                    t?.treatmentNo
                  )
                }}
                style={{ marginRight: '10px' }}
              >
                执行
              </Button>
            ) : t.state === 2 ? (
              <Button
                type='primary'
                size='small'
                onClick={() => {
                  onClick('cancel', t.id)
                }}
              >
                取消
              </Button>
            ) : (
              '-'
            )}
          </>
        )
      },
    },
  ]
}
