/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 11:13:19
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../../app/store'
import { nopiTest } from '../../../../models/nopitest'
import * as service from '../../../../services/nopitest'
import { pitestQueryParams } from '../../../../services/nopitest'

interface PitestState {
  NopitestData: []
  Total: 0
  Current: 1
  detail: nopiTest[]
  pageLoading: false
}

const initialState: PitestState = {
  NopitestData: [],
  Total: 0,
  Current: 1,
  detail: [],
  pageLoading: false,
}

const pitestSlice = createSlice({
  name: 'nopitestSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.NopitestData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },

    setDetail: (state, action) => {
      state.detail = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setData,
  setTotal,
  setCurrent,
  setDetail,
  setPageLoading,
} = pitestSlice.actions

export const selectNopitestData = (state: RootState) =>
  state.nopitest.NopitestData

export const selectTotal = (state: RootState) => state.nopitest.Total

export const selectCurrent = (state: RootState) => state.nopitest.Current

export const selectDetail = (state: RootState) => state.nopitest.detail

export const selectPageLoading = (state: RootState) =>
  state.nopitest.pageLoading

export function getPitestList(params: pitestQueryParams): RootThunk {
  return api(service.getPitestList(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

// export function startpitest(nopitest: nopiTest): RootThunk {
//   return api(service.getPitestDetail(nopitest), (data: any, dispatch) => {
//       dispatch(setDetail(data.records))
//       dispatch(setTotal(data.total))
//     })
// }
export const startpitest = createAsyncThunk<void, nopiTest, RootThunkApi>(
  'KeyWordsubmit/submit',
  async (body, api) => {
    return sendAsync(service.getPitestDetail(body), api)
  }
)

export function pitestDetail(id: string, recipeState?: string): RootThunk {
  return api(service.getDetailList(id), (data: any, dispatch) => {
    dispatch(setDetail(data))
  })
}

export const pitestInvalidate = createAsyncThunk<void, string, RootThunkApi>(
  'KeyWordsubmit/invalidate',
  async (params, api) => {
    return sendAsync(service.getPitestInvalidate(params), api)
  }
)

export default pitestSlice.reducer
