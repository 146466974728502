/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-04 10:50:48
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../app/store'
import { sendAsync } from '../../../app/applicationSlice'
import * as CM from '../../../services/official'

// 查询是否开通公众号
export const getWechatConfig = createAsyncThunk<any, void, RootThunkApi<void>>(
  'official/getWechatConfig',
  async (params, api) => {
    return sendAsync(CM.wechatConfig(), api)
  }
)

// 生成公众号授权二维码
export const getGenerateAccessUrl = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('official/getGenerateAccessUrl', async (params, api) => {
  return sendAsync(CM.generateAccessUrl(), api)
})

//支付码
export const getPayInfo = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('official/getPayInfo', async (params, api) => {
  return sendAsync(CM.payInfo(params), api)
})

