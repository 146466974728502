/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-10 11:44:20
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-03 16:36:39
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Modal, notification, Row, Table } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  EditableColumnType,
  EditableList,
} from '../../../compnents/list/EditableList'

import { NavBar } from '../../../compnents/nav/NavBar'
import { ThemeContext } from '../../../theme/ThemeContext'
import styles from './order.module.css'
import { getreplaceMakeOrderDetail } from '../OReplaceMakeSlice'
import { useLocation } from 'react-router-dom'
import { CellParams } from './ReplaceMakeOrder'
import { ReplaceMakeSettingModal } from './ReplaceMakeSettingModal'
import {
  isAutoUpload,
  isCancelUpload,
} from '../../payment/result/paymentResultSlice'
import { DecoctingSettingModal } from '../../treatment/editor/modals/DecoctingSettingModal'
import { getCarryTypeName } from '../../../models/ReplaceMakeSupplier'

export interface HeaderDataParams {
  recipientName: string
  recipientPhone: string
  machiningType: string
  remark: string
  addressType: string
  recipientAddress: string
  specification: string
  uploadStatus: string
  supplierName: string
  carryType: string
}

export interface ModalParams {
  recipeId: string
  recipeCategory?: string
  patientId: string
  potionType?: number
  settingDecoctionType?: number
}

// 代煎订单详情
export const ReplaceMakeOrderDetail = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const location: any = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  // const uploadStatus = location?.state?.status // 上传状态

  // const uploadType = location?.state?.type // 上传状态

  const uploadId = location?.state?.id // 处方id

  const [uploadStatus, setUploadStatus] = useState(location?.state?.status)

  const [uploadType, setUploadType] = useState(location?.state?.type)

  const [state, setState] = useState(location?.state?.settlementState)

  const [data, setData] = useState<any>([]) // 表格数据

  const [total, setTotal] = useState(0)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    id: uploadId,
  })

  const [visible, setVisible] = useState(false)

  const [cellData, setCelldata] = useState<CellParams>() // 合计行数据

  const [headerData, setHeaderData] = useState<HeaderDataParams>() // 页头数据

  const [isUpload, setIsUpload] = useState(false)
  
  useEffect(() => {
    setUploadStatus(location?.state?.status)
    setUploadType(location?.state?.type)
    setState(location?.state?.settlementState)
    setIsUpload(false)
  }, [location])

  const columns: EditableColumnType<Record<string, any>>[] = [
    {
      title: `序号`,
      key: 'no',
      align: 'center',
      render: (_1, _2, i) => i + 1,
    },
    {
      title: '药品名称',
      key: 'medicineName',
      align: 'center',
      render: function medicineName(_, t): ReactElement {
        return (
          <div>
            <span>{t.medicineName}</span>
            <span style={{ fontSize: 14, marginLeft: 5, color: '#666' }}>
              {t.accountUnit}
            </span>
          </div>
        )
      },
    },
    {
      title: '单价',
      dataIndex: 'retailPrice',
      key: 'retailPrice',
      align: 'center',
      render: (_, t) => t.retailPrice.toFixed(4),
    },
    {
      title: '帖数',
      dataIndex: 'tip',
      key: 'tip',
      align: 'center',
    },
    {
      title: '每帖数量',
      dataIndex: 'countPerTip',
      key: 'countPerTip',
      align: 'center',
    },

    {
      title: '总数量',
      dataIndex: 'drugCount',
      key: 'drugCount',
      align: 'center',
    },
    {
      title: '金额',
      key: 'drugAmount',
      align: 'center',
      render: (_, r) => r.drugAmount?.toFixed(4),
    },
    {
      title: '代煎成本价',
      key: 'priceRatio',
      align: 'center',
      render: (_, r) =>
        r.priceRatio && r.priceRatio > 0 ? r.priceRatio?.toFixed(2) : '--',
    },
  ]

  const [isReplaceMakeModalVisible, setIsReplaceMakeModalVisible] = useState(
    false
  )

  const [modalParams, setModalParams] = useState<ModalParams>()

  useEffect(() => {
    if (!isReplaceMakeModalVisible) {
      getPage()
    }
  }, [params, isReplaceMakeModalVisible])

  const getPage = () => {
    dispatch(
      getreplaceMakeOrderDetail({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setCelldata({
          name: res.name,
          moneySum: res.moneySum,
        })
        setHeaderData({
          recipientName: res.recipientName,
          recipientPhone: res.recipientPhone,
          machiningType: res.boilTypeName,
          remark: res.remark,
          addressType: res.addressType === 0 ? '患者地址' : '诊所自取',
          recipientAddress:
            res.province + res.city + res.region + res.recipientAddress,
          specification: res.specification,
          uploadStatus: res.uploadStatus,
          supplierName: res.supplierName,
          carryType: res.carryType,
        })
        setTotal(res?.res?.total)
        setData(res?.res?.records)
        setModalParams({
          recipeId: res.id,
          potionType: res.decoctionType,
          patientId: res.patientId,
          settingDecoctionType: res.settingDecoctionType,
          // recipeCategory: res.machiningType,
        })
        setIsUpload(false)
        setUploadStatus(res?.status)
        setUploadType(res?.decoctionType)
        // setState(res?.state) //结算状态
      })
  }

  // 取消上传 强制取消
  const cancelUpload = () => {
    dispatch(
      isCancelUpload({
        recipeId: modalParams!.recipeId,
        potionRecipeStatus: 3,
      })
    )
      .then(unwrapResult)
      .then(() => {
        notification.success({ message: '取消成功' })
        setIsUpload(true)
        getPage()
      })
      .catch(() => {
        notification.info({ message: '取消失败，请强制取消。' })
      })
  }
  // 代煎设置
  const setting = () => {
    setIsReplaceMakeModalVisible(true)
  }

  // 上传代煎订单
  const uploadLoad = () => {
    dispatch(isAutoUpload(modalParams!.recipeId))
      .then(unwrapResult)
      .then((res) => {
        notification.success({ message: '代煎订单上传成功', duration: 6 })
        setIsUpload(true)
        getPage()
      })
      .catch(() => {
        notification.error({
          message: '代煎订单自动上传失败,请尝试手动上传或联系管理员处理。',
          duration: 20,
        })
      })
  }

  return (
    <Col
      style={{
        height: 'calc(100% - 20px) ',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px',
      }}
    >
      <NavBar
        where={['代煎订单', '订单详情']}
        backtrace={{
          name: '代煎订单',
          path: '/OReplaceMake',
          routeName: '代煎订单',
          // state: {
          //   current: location?.state?.current,
          // },
        }}
      />
      <Col
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: theme.pn,
          marginTop: 10,
          borderRadius: 10,
          padding: '20px 35px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <section>
          <Row className={styles.detail}>
            <Col span={8}>患者姓名：{headerData?.recipientName}</Col>
            <Col span={8}>联系电话：{headerData?.recipientPhone}</Col>
            <Col span={8}>代煎饮片厂：{headerData?.supplierName}</Col>
          </Row>
          <Row className={styles.detail}>
            <Col span={8}>加工方式：{headerData?.machiningType}</Col>
            <Col span={8}>上传状态：{headerData?.uploadStatus}</Col>
            <Col span={8}>包装规格：{headerData?.specification}</Col>
          </Row>
          <Row className={styles.detail}>
            <Col span={8}>代煎备注：{headerData?.remark}</Col>
            <Col span={8}>
              收货位置：{headerData?.addressType} （配送方式：
              {getCarryTypeName(Number(headerData?.carryType))}）
            </Col>
            <Col span={8}>收货地址：{headerData?.recipientAddress}</Col>
          </Row>
        </section>
        <EditableList
          page={{
            items: data,
            current: params.current || 1,
            size: params.size || 10,
            total,
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell className={styles.footingTitle} index={0}>
                  {cellData?.name}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6} align='center'>
                  {cellData?.moneySum?.toFixed(4)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          style={{ color: theme.tc3 }}
          className={styles.table}
          columns={columns}
          onChangePage={(current: number, size?: number) => {
            setParams({
              ...params,
              current: current,
              size: size || 10,
            })
          }}
        />
      </Col>
      {state == 5 ? (
        <></>
      ) : (uploadStatus === 1 && uploadType === 1 ? (
        <Row
          style={{
            marginTop: 15,
            padding: 20,
            backgroundColor: '#FFF',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type='primary'
            onClick={() => {
              setVisible(true)
            }}
          >
            强制取消
          </Button>
          <Button
            type='primary'
            style={{ margin: '0px 25px' }}
            onClick={cancelUpload}
          >
            取消上传{' '}
          </Button>
          {/* <Button type='primary' style={{ margin: '0px 25px' }} onClick={setting}>代煎设置 </Button> */}
        </Row>
      ) : uploadStatus === 0 ? (
        <Row
          style={{
            marginTop: 15,
            padding: 20,
            backgroundColor: '#FFF',
            justifyContent: 'flex-end',
          }}
        >
          <Button type='primary' onClick={setting}>
            代煎设置
          </Button>
          <Button
            type='primary'
            style={{ margin: '0px 25px' }}
            onClick={uploadLoad}
          >
            上传{' '}
          </Button>
        </Row>
      ) : (
        ''
      ))}

      <Modal
        title='强制取消'
        visible={visible}
        onOk={() => {
          dispatch(
            isCancelUpload({
              recipeId: modalParams!.recipeId,
              potionRecipeStatus: 4,
            })
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({ message: '强制取消成功', duration: 1.5 })
              setVisible(false)
              setIsUpload(true)
              getPage()
            })
        }}
        onCancel={() => {
          setVisible(false)
        }}
      >
        强制取消上传代煎订单为未上传可能带来损失，确定强制取消？
      </Modal>
      {/* <ReplaceMakeSettingModal
        uploadStatus={uploadStatus}
        visible={isReplaceMakeModalVisible}
        recipeId={Number(modalParams?.recipeId)} // 处方id
        // recipeCategory={Number(modalParams?.recipeCategory)} // 代煎类型 种药，颗粒剂
        patientId={Number(modalParams?.patientId)} // 患者ID
        isSetting={true} // 是否设置过代煎
        potionType={modalParams?.potionType} // 上次代煎的方式
        isOpenReplaceMake={true} // 是否开启代煎
        settingDecoctionType={modalParams?.settingDecoctionType}
        onCancel={() => {
          setIsReplaceMakeModalVisible(false)
        }}
        onOk={() => {
          setIsReplaceMakeModalVisible(false)
        }}
      /> */}
      {/* 代煎设置 */}
      <DecoctingSettingModal
        disabledFlag={true}
        settingDecoctionType={modalParams?.settingDecoctionType}
        // uploadStatus={uploadStatus}
        visible={isReplaceMakeModalVisible}
        isSetting={true} // 是否设置过代煎
        potionType={modalParams?.potionType} // 上次代煎的方式
        recipeId={Number(modalParams?.recipeId)} // 处方id
        patientId={Number(modalParams?.patientId)} // 患者ID
        recipeCategory={Number(modalParams?.recipeCategory)} // 代煎类型 种药，颗粒剂
        isOpenReplaceMake={true} // 是否开启代煎
        onCancel={() => {
          setIsReplaceMakeModalVisible(false)
        }}
        onOk={() => {
          setIsReplaceMakeModalVisible(false)
        }}
      ></DecoctingSettingModal>
    </Col>
  )
}
