/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import { CaretDownFilled } from '@ant-design/icons'
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Modal,
  ModalProps,
  notification,
  Row,
  Select,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCredentials,
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantId,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../app/applicationSlice'
import 'moment/locale/zh-cn'
import locale from 'antd/es/date-picker/locale/zh_CN'
import moment from 'moment'
import { RootDispatch } from '../../app/store'
import { accountCheckAsync, elseWhereCheckAsync } from '../layoutSlice'
import {
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { unwrapResult } from '@reduxjs/toolkit'
import { medicalSendHttp, strParse } from '../../utils/MedicalUtils'
const { Item } = Form
const { Option } = Select

export interface ResetModalProps {
  insuranceCode: string
}

export const ResetModal: React.FC<ModalProps & ResetModalProps> = ({
  insuranceCode,
  visible,
  onCancel,
  onOk,
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const insuranceArray = useSelector(selectInsuranceArray)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const [loading, setLoading] = useState(false)

  const tenantId = useSelector(selectTenantId)

  const userId = useSelector(selectUserId)

  const userName = useSelector(selectUserName)

  const credentials = useSelector(selectCredentials)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 15 },
  }

  const loadState = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 5000)
    notification.info
  }

  const notificationFn = (type: string, str: string) => {
    switch (type) {
      case 'info':
        notification.info({
          message: str,
          duration: 1,
        })
        break
      case 'error':
        notification.error({
          message: str,
          duration: 1,
        })
        break
      case 'success':
        notification.success({
          message: str,
          duration: 1,
        })
        break
    }
    setLoading(false)
  }

  const disabledStartDate: any = (current: any) => {
    const vs = form.getFieldsValue()
    if (vs.endTime) {
      return (
        current &&
        (current > moment(vs.endTime).endOf('day') ||
          current < moment(vs.endTime).subtract(30, 'day'))
      )
    }
  }

  const disabledEndDate: any = (current: any) => {
    const vs = form.getFieldsValue()
    if (vs.startTime) {
      return (
        current &&
        (current < moment(vs.startTime).endOf('day') ||
          current > moment(vs.startTime).add(30, 'days'))
      )
    }
  }

  const resetSubmitItem = (
    accountData: string,
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any,
    call?: any
  ) => {
    dispatch(
      accountCheckAsync({
        serverType,
        body: {
          // accountDate: accountData,
          baseData: {
            responseString: responseString ? responseString : null,
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            
          },
          insuranceCode,
          accountDate: moment(accountData).format(DateTimeFormatSimpleOnly),
          qingsuanlb: call ? call.input.data.clr_type : undefined,
          xianzhonglx: call ? call.input.data.insutype : undefined,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          data.map((v: any) => {
            medicalSendHttp(
              {
                trade_args: [
                  ...v,
                  insuranceItem.countryHospitalNb,
                  insuranceItem.secretToken,
                ],
              },
              (call, err) => {
                if (!err) {
                  resetSubmitItem(
                    accountData,
                    'response',
                    JSON.stringify(call.netmessage)+"####"+JSON.stringify(v[2]),
                    resolve,
                    reject,
                    v[2]
                  )
                } else {
                  reject && reject()
                }
              },
              undefined,
              reject
            )
          })
        } else {
          resolve && resolve()
        }
      })
  }

  const resetElseSubmitItem = (
    accountData: string,
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      elseWhereCheckAsync({
        serverType,
        body: {
          // accountDate: accountData,
          baseData: {
            responseString: responseString ? responseString : null,
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            cycleCode,
            
          },
          accountDate: accountData,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                resetElseSubmitItem(
                  accountData,
                  'response',
                  JSON.stringify(call.netmessage),
                  resolve,
                  reject
                )
              } else {
                reject && reject()
              }
            },
            undefined,
            reject
          )
        } else {
          resolve && resolve()
        }
      })
  }

  const resetIsLocalItem = (vs: any) => {
    let promiseList = []
    const count = moment(vs.endTime).diff(moment(vs.startTime), 'days')
    if (vs.status.find((v: string) => v == '2')) {
      for (let index = 0; index <= count; index++) {
        const accountData = moment(vs.startTime)
          .add(index, 'days')
          .format(DateTimeFormatSimpleOnlyOne)
        promiseList.push(
          new Promise((resolve, reject) =>
            resetElseSubmitItem(
              accountData,
              'requestString',
              undefined,
              resolve,
              reject
            )
          )
        )
      }
    }
    if (vs.status.find((v: string) => v == '1')) {
      for (let index = 0; index <= count; index++) {
        const accountData = moment(vs.startTime)
          .add(index, 'days')
          .format(DateTimeFormatSimpleOnlyOne)
        promiseList.push(
          new Promise((resolve, reject) =>
            resetSubmitItem(
              accountData,
              'requestString',
              undefined,
              resolve,
              reject
            )
          )
        )
      }
    }

    Promise.all(promiseList)
      .then(() => {
        notificationFn('success', '对账完毕，返回列表查看')
      })
      .catch(() => {
        setLoading(false)
        // notificationFn('error', '对账失败，返回列表查看')
      })
      .finally(() => {
        onOk && onOk({} as any)
      })
  }

  const resetSubmit = (vs: any) => {
    if (vs.status.length) {
      resetIsLocalItem(vs)
    } else {
      notificationFn('info', '请选择本地，异地')
    }
  }

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        startTime: moment().startOf('day'),
        endTime: moment().endOf('day'),
        status: ['1'],
      })
      if (insuranceArray.length) {
        setInsuranceItem(insuranceArray[0])
        form.setFieldsValue({
          insuranceCode: insuranceArray[0].insuranceCode,
        })
      }
    }
    return () => {
      clearTimeout()
    }
  }, [insuranceArray, visible])

  return (
    <Modal
      title='医保日对帐'
      visible={visible}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={() => {
        loadState()
        form.submit()
      }}
    >
      <Form
        form={form}
        {...layout}
        onFinish={(vs) => {
          if (!vs.endTime) {
            notificationFn('info', '请选择结束时间')
            return
          }
          if (!vs.startTime) {
            notificationFn('info', '请选择开始时间')
            return
          }
          if (moment(vs.endTime).isBefore(vs.startTime)) {
            notificationFn('info', '请选择正确的时间范围')
            return
          }
          let days = moment(vs.endTime).diff(moment(vs.startTime), 'days')
          if (days + 1 > 31) {
            notificationFn('info', '最大支持31天时间范围')
            return
          }
          resetSubmit(vs)

        }}
      >
        <Row>
          <Col span={12}>
            <Item label='结算险种' name='insuranceCode'>
              <Select
                placeholder='请选择医保险种'
                // suffixIcon={<CaretDownFilled />}
                style={{ width: '100%' }}
              >
                {insuranceArray.map((v) => {
                  return (
                    <Option value={v.insuranceCode} key={v.id}>
                      {v.insuranceName}
                    </Option>
                  )
                })}
              </Select>
              {/* <DatePicker
                locale={locale}
                placeholder='结束时间'
                disabledDate={disabledEndDate} 
              />*/}
            </Item>
          </Col>
          <Col span={12}>
            <Item name='status'>
              <Checkbox.Group>
                <Checkbox value='1'>本地</Checkbox>
                <Checkbox value='2'>异地</Checkbox>
              </Checkbox.Group>
            </Item>
          </Col>
          <Col span={12}>
            <Item name='startTime' label='起始时间'>
              <DatePicker
                locale={locale}
                placeholder='起始时间'
                // disabledDate={disabledStartDate}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item name='endTime' label='结束时间'>
              <DatePicker
                locale={locale}
                placeholder='结束时间'
                // disabledDate={disabledEndDate}
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
