import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../../app/store'
import {
  DateRangeFieldName,
  DateRangeType,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import * as U from '../../../models/user'
import { StationCategory, User } from '../../../models/user'
import { getOutpatientDepartment } from '../../../services/department'
import * as S from '../../../services/doctorAudit'
import { GetDoctorAuditEntriesParams } from '../../../services/doctorAudit'
import * as SU from '../../../services/user'
import { getDoctorsInDepartment } from '../../../services/user'

interface DoctorAuditState {
  params: GetDoctorAuditEntriesParams
  // drugType:any
}

const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

const initialState = {
  params: {
    timeHead,
    timeTail,
    [DateRangeFieldName]: DateRangeType.Today,
  },
  // drugType
} as DoctorAuditState

const doctorAuditSlice = createSlice({
  name: 'doctorAudit',
  initialState,
  reducers: {
    setGetDoctorAuditEntriesParam: (state, action) => {
      state.params = action.payload
    },
  },
})

export const { setGetDoctorAuditEntriesParam } = doctorAuditSlice.actions

export const selectGetDoctorAuditEntriesParam = (
  state: RootState
): GetDoctorAuditEntriesParams => state.doctorAudit.params

export const getDoctorAuditEntries = createAsyncThunk<
  Record<string, unknown>[],
  GetDoctorAuditEntriesParams,
  RootThunkApi<Record<string, unknown>[]>
>('doctorAudit/getDoctorAuditEntriesStatus', async (params, api) => {
  return sendAsync(S.getDoctorAuditEntries(params), api)
})

export const getDoctorAuditExoprt = createAsyncThunk<
  void,
  GetDoctorAuditEntriesParams,
  RootThunkApi
>('doctorAudit/getDoctorAuditExoprt ', async (params, api) => {
  return sendAsync(S.getDoctorAuditExport(params), api)
})

export const getDoctorDepartmentList = createAsyncThunk<
  void,
  any,
  RootThunkApi
>('doctorAudit/getDoctorDepartmentList ', async (params, api) => {
  return sendAsync(getDoctorsInDepartment(params), api)
})

export const getDepartmentDoctorList = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('doctorAudit/getDepartmentDoctorList ', async (params, api) => {
  return sendAsync(getOutpatientDepartment(), api)
})

export const getDoctorList = createAsyncThunk<
  User[],
  void,
  RootThunkApi<User[]>
>('doctorAudit/getDoctorList', async (_1, api) => {
  return sendAsync(
    SU.getUserList({
      current: 1,
      size: 1000,
      stationCategory: StationCategory.DoctorOrPharmacist,
    }),
    api
  ).then((data: Record<string, never>) =>
    _.chain(data.records)
      .map((u) => U.fromJson(u))
      .value()
  )
})

export default doctorAuditSlice.reducer
