/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-06-16 16:31:53
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import { Page, pageOf, startPageOf } from '../../models/page'
import * as R from '../../models/registration'
import { Registration } from '../../models/registration'
import * as T from '../../models/treatment'
import { Treatment } from '../../models/treatment'
import * as S from '../../services/outpatient'
import {
  GetOutpatientAuditListParams,
} from '../../services/outpatient'

export type OutpatientAuditItem = Registration &
  Treatment & { address: string; idCardNumber: string; companyName?: string;}

interface OutpatientAuditState {
  page: Page<OutpatientAuditItem>
}

const initialState: OutpatientAuditState = {
  page: startPageOf(),
}

// export const getOutpatientAuditList = createAsyncThunk<
//   Page<OutpatientAuditItem>,
//   GetOutpatientAuditListParams,
//   RootThunkApi<Page<OutpatientAuditItem>>
// >('outpatientAudit/getOutpatientAuditListStatus', async (params, api) => {
//   return sendAsync(S.getOutpatientAuditList(params), api).then(
//     (data: any) =>
//       pageOf(
//         data,
//         _.chain(data.records)
//           .map((m) => ({
//             ...R.fromJson(m),
//             ...T.fromJson(m),
//             address: m.address || '',
//             idCardNumber: m.idCardNumber || '',
//           }))
//           .value()
//       )
//   )
// })


export const getOutpatientAuditList = createAsyncThunk<
  void,
 GetOutpatientAuditListParams,
  RootThunkApi
>('InspectData/list', async (params, api) => {
  return sendAsync(S.getOutpatientAuditList(params), api)
})



const outpaientAuditSlice = createSlice({
  name: 'outpatientAudit',
  initialState,
  reducers: {},
  // extraReducers: (builder) => {
  //   builder.addCase(getOutpatientAuditList.fulfilled, (state, action) => {
  //     state.page = action.payload
  //   })
  // },
})

// 导出
export const getOutpatientAuditExoprt = createAsyncThunk<
  void,
  GetOutpatientAuditListParams,
  RootThunkApi
>('doctorAudit/getOutpatientAuditExoprt ', async (params, api) => {
  return sendAsync(S.getOutpatientAuditsExoprt(params), api)
})

export const selectOutpatientAuditPage = (
  state: RootState
): Page<OutpatientAuditItem> => state.outpatientAudit.page

export default outpaientAuditSlice.reducer