/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-19 15:17:27
 * @LastEditors: sj
 * @LastEditTime: 2022-08-19 17:18:00
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import * as S from '../../services/outRecipe'

// 外配处方列表
export const outRecipeListAsync = createAsyncThunk<
  string,
  S.outRecipeListParams,
  RootThunkApi
>('/outRecipe/outRecipeListAsync', async (body, api) => {
  return sendAsync(S.outRecipeList(body), api)
})

// 7160 处方审核结果
export const CheckResultAsync = createAsyncThunk<
  string,
  S.CheckResultParams,
  RootThunkApi
>('/outRecipe/CheckResultAsync', async (body, api) => {
  return sendAsync(S.CheckResult(body), api)
})

// 7161 处方购药结果
export const buyResultAsync = createAsyncThunk<
  string,
  S.CheckResultParams,
  RootThunkApi
>('/outRecipe/buyResultAsync', async (body, api) => {
  return sendAsync(S.buyResult(body), api)
})

// 电子处方撤销 7104
export const repealRecipeAsync = createAsyncThunk<
  string,
  S.CheckResultParams,
  RootThunkApi
>('/outRecipe/repealRecipeAsync', async (body, api) => {
  return sendAsync(S.repealRecipe(body), api)
})
