/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-03 10:52:57
 */
import { Form, Input, Select, Button, Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import { FieldData } from '../../models/fielddata'
import { examinePageParams } from '../../services/examine'
import {
  BillingCategoryName,
  TreatmentBillingCategories,
} from '../../models/billingCategory'
import { useSelector } from 'react-redux'
import { selectInsuranceArray, selectTenantName } from '../../app/applicationSlice'
const { Option } = Select

export const ExamineQuery = (props: {
  onValueChange: (queries: examinePageParams) => void
}) => {
  const [form] = Form.useForm()

  const tenantName = useSelector(selectTenantName)

  const DefaultFields: FieldData[] = [
    {
      name: 'name',
      value: undefined,
    },
    {
      name: 'state',
      value: undefined,
    },
    {
      name: 'billingCategory',
      value: undefined,
    },
  ]

  const [fields, setFields] = useState(DefaultFields)

  const insuranceArray = useSelector(selectInsuranceArray)

  useEffect(() => {
    if (insuranceArray.length) {
      form.setFieldsValue({
        insuranceCode: insuranceArray[0].insuranceCode,
      })
    }
  }, [insuranceArray])
  useEffect(() => {
    form.setFieldsValue({
      state: 1,
    })
  }, [])
  return (
    <Form
      fields={fields}
      form={form}
      name='control-hooks'
      onFinish={(values) => {
        props.onValueChange(form.getFieldsValue())
      }}
      autoComplete='off'
    >
      <Row gutter={11} style={{ height: '40px' }}>
        <Col span={3}>
          <Form.Item name='name'>
            <Input
              placeholder='搜索关键词'
              allowClear
              onChange={form.submit}
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item name='state'>
            <Select
              placeholder='是否注销'
              onChange={form.submit}
              // suffixIcon={<CaretDownFilled />}
            >
              <Option value={0}>已注销</Option>
              <Option value={1}>未注销</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='billingCategory'>
            <Select
              placeholder='开票项目类型'
              allowClear
              onChange={form.submit}
              // suffixIcon={<CaretDownFilled />}
            >
              {TreatmentBillingCategories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {BillingCategoryName(v)}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        {insuranceArray.length !== 0 && (
          <>
            <Col span={3}>
              <Form.Item name='insuranceCode'>
                <Select
                  placeholder='医保险种'
                  // suffixIcon={<CaretDownFilled />}
                  onChange={form.submit}
                >
                  {insuranceArray.map((v) => {
                    return (
                      <Option value={v.insuranceCode} key={v.id}>
                        {v.insuranceName}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>{' '}
            {(tenantName?.indexOf('余姚健齿口腔诊所有限公司') < 0 && tenantName?.indexOf('余姚康民内科诊所') < 0) && (
              <Col span={3}>
                <Form.Item name='isMapping'>
                  <Select
                    placeholder='匹配状态'
                    allowClear
                    onChange={form.submit}
                    // suffixIcon={<CaretDownFilled />}
                  >
                    <Option value='0'>未匹配</Option>
                    <Option value='1'>已匹配</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={3}>
              <Form.Item name='chrgitmLv'>
                <Select
                    placeholder='甲乙分类'
                    allowClear
                    onChange={form.submit}
                >
                  <Option value='0'>全部</Option>
                  <Option value='01'>甲类</Option>
                  <Option value='02'>乙类</Option>
                  <Option value='03'>丙类</Option>
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={4} offset={insuranceArray.length !== 0 ? 3 : 6}>
          <Form.Item name='allStr'>
            <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
              查询
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
