import { Button, Col, notification, Row } from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { NavBar } from '../../../compnents/nav/NavBar'
import { RoundTabBar, TabPane } from '../../../compnents/widgets/RoundTabBar'
import { getRecipeCategoryName, Recipe } from '../../../models/recipe'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import { sleep } from '../../../utils/SystemUtils'
import { PatientDetailPanel } from '../../patient/PatientDetailPanel'
import { RecipeList } from '../../recipe/RecipeList'
import { BeautyClinic } from '../beautyClinic/beautyClinic'
import { Diagnosis, DiagnosisRef } from '../diagnosis/Diagnosis'
import { selectTreatment } from '../diagnosis/diagnosisSlice'
import { OralCases } from '../oralCases/OralCases'
import { TCMCases } from '../tcmCases/TcmCases'

export const TreatmentDetail = (): ReactElement => {
  const location = useLocation() as {
    state: {
      where: [string, string]
      backtrace: { name: string; path: string; query?: string }
    }
  }

  const query = useQuery()

  const theme = useContext(ThemeContext)

  const ref = useRef<DiagnosisRef>(null)

  const treatment = useSelector(selectTreatment)

  const [selectedTab, setSelectedTab] = useState('0')

  const [selectedRecipes, setSelectedRecipes] = useState<Recipe[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const medicalRecord: any = query.get('medicalRecord') as string

  console.log(location?.state);
  

  return (
    <Col
      style={{
        height: 'calc(100% - 10px)',
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <NavBar
        where={location?.state?.where}
        backtrace={location?.state?.backtrace}
        style={{ marginLeft: 20, marginRight: 20 }}
      />
      <Row
        wrap={false}
        align='stretch'
        style={{ flex: 1, overflow: 'hidden', margin: '10px 20px' }}
      >
        <Col>
          <PatientDetailPanel
            style={{
              width: 242,
              height: '100%',
              marginRight: 20,
            }}
            patientId={treatment?.patientId}
            treatmentId={treatment?.id}
          />
        </Col>
        <Col flex={1} style={{ overflow: 'hidden' }}>
          <RoundTabBar
            activeKey={selectedTab}
            onChange={(k) => setSelectedTab(k)}
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <TabPane key='0' tab='诊断信息'>
              {medicalRecord == 1 ? (
                <Diagnosis readMode ref={ref} state={1} />
              ) : medicalRecord == 2 ? (
                <TCMCases readMode ref={ref} state={1} />
              ) : medicalRecord == 3 ? (
                <OralCases readMode ref={ref} state={1} />
              ) : (
                <></>
              )}
            </TabPane>
            <TabPane key='1' tab='医嘱处方'>
              <RecipeList
                readMode
                yidibz={'0'}
                autoSelect
                treatmentId={treatment?.id}
                onSelect={(recipes) => setSelectedRecipes(recipes)}
                style={{ width: '100%', height: '100%' }}
              />
            </TabPane>
          </RoundTabBar>
          {treatment?.auditDoctorName ? (
            <div
              style={{
                position: 'absolute',
                right: 20,
                top: 12,
                color: '#fff',
                fontSize: '16px',
              }}
            >
              {treatment?.auditDoctorName}已审核
            </div>
          ) : (
            ''
          )}
        </Col>
      </Row>
      <Row
        style={{ height: '5rem', backgroundColor: theme.pn, padding: '0 30px' }}
        justify={selectedTab === '0' ? 'end' : 'start'}
        align='middle'
      >
        {selectedTab === '1' && (
          <>
            <span style={{ fontSize: '1.5rem' }}>
              总金额：
              <span
                style={{
                  color: theme.e,
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                }}
              >
                ¥{selectedRecipes.reduce((s, r) => s + r.amount, 0).toFixed(2)}
              </span>
            </span>
            {selectedRecipes.map((r) => (
              <span key={r.id} style={{ marginLeft: 10 }}>
                {getRecipeCategoryName(r.category)}：
                <span
                  style={{
                    color: theme.tc1,
                    fontSize: '1.125rem',
                    fontWeight: 'bold',
                  }}
                >
                  ¥{r.amount}
                </span>
              </span>
            ))}
          </>
        )}
        {selectedTab === '0' && (
          <Button
            type='primary'
            loading={isLoading}
            onClick={async () => {
              if (isLoading || !ref.current) {
                return
              }
              setIsLoading(true)
              const timestamp = moment()
              try {
                await ref.current.submit()
                notification.success({
                  message: '保存成功',
                })
                const duration = moment
                  .duration(moment().diff(timestamp))
                  .milliseconds()
                if (duration < 1000) {
                  await sleep(1000 - duration)
                }
              } catch {
                // do nothing.
              } finally {
                setIsLoading(false)
              }
            }}
          >
            保存
          </Button>
        )}
      </Row>
    </Col>
  )
}
