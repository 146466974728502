/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-23 09:32:02
 * @LastEditors: sj
 * @LastEditTime: 2022-09-07 10:33:34
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { sendAsync } from "../../app/applicationSlice"
import { RootState, RootThunkApi } from "../../app/store"
import {
  RiskAreaValue,
  TenantValue,
  TriageItemValue,
  TriageListValue,
} from "../../models/previewTriageList"
import * as S from "../../services/previewTriageList"

interface Code {
  nationUniqueCode: any
}

const NATIONUNIQUECODE = "nationUniqueCode"

const initialState = {
  nationUniqueCode: parseInt(localStorage.getItem(NATIONUNIQUECODE) || "0"),
} as Code

// 获取H5所需参数
export const getTenantParams = createAsyncThunk<
  TenantValue,
  any,
  RootThunkApi<void>
>("list/getTenant", async (_, api) => {
  return sendAsync(S.getTenantParams(), api)
})

const menuContentListSlice = createSlice({
  name: "menuContentList",
  initialState,
  reducers: {
    setNationUniqueCode: (state, action) => {
      state.nationUniqueCode = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTenantParams.fulfilled, (state, action: any) => {
      state.nationUniqueCode = action.payload?.nationUniqueCode
      localStorage.setItem(
        NATIONUNIQUECODE,
        action.payload?.nationUniqueCode?.toString()
      )
    })
  },
})

export const { setNationUniqueCode } = menuContentListSlice.actions

//预检分诊列表
export const getTriageList = createAsyncThunk<
  TriageListValue,
  S.TriageListParams,
  RootThunkApi<void>
>("list/getTriage", async (params, api) => {
  return sendAsync(S.getTriageList(params), api)
})

// 预检分诊详情
export const getTriageItem = createAsyncThunk<
  TriageItemValue,
  number,
  RootThunkApi<void>
>("list/getTriageItem", async (params, api) => {
  return sendAsync(S.getTriageItem(params), api)
})

// 新增或修改预检分诊
export const submitTriage = createAsyncThunk<
  any,
  S.submitTriageBody,
  RootThunkApi<void>
>("list/editTriageItem", async (body, api) => {
  return sendAsync(S.submitTriage(body), api)
})

// 删除预检分诊
export const removeTriageItem = createAsyncThunk<
  any,
  string,
  RootThunkApi<void>
>("list/removeTriageItem", async (ids, api) => {
  return sendAsync(S.removeTriageItem(ids), api)
})

// 删除预检分诊登记记录
export const removeSingleAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>("list/removeSingleAsync", async (id, api) => {
  return sendAsync(S.removeSingle(id), api)
})

export const getRiskArea = createAsyncThunk<
  RiskAreaValue[],
  S.RiskAreaParams,
  RootThunkApi<void>
>("app/getRiskArea", async (params, api) => {
  return sendAsync(S.getRiskArea(params), api)
})

export const selectNationUniqueCode = (state: RootState) =>
  state.menuContentList.nationUniqueCode

export default menuContentListSlice.reducer
