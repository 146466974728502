/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-17 10:40:09
 * @LastEditors: sj
 * @LastEditTime: 2022-12-15 16:25:05
 */
import { unwrapResult } from '@reduxjs/toolkit';
import { Modal, notification, Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../../../../app/store';
import { EditableList } from '../../../../../compnents/list/EditableList';
import { getRecipeItemsByPatientIdAsync } from '../../patientSlice';
import { RelevanceDisposeColumns } from './columns';

interface RelevanceDisposeModalProps {
  visible: boolean
  onCancel: () => void
  patientId?: string
  onOK: (v: any) => void
  selectIDs?: any[]
  id?: string
  currentList?: any[]
}

export const RelevanceDisposeModal = ({
  visible,
  onCancel,
  patientId,
  onOK,
  selectIDs,
  id,
  currentList
}: RelevanceDisposeModalProps): ReactElement => {

  const dispatch = useDispatch<RootDispatch>()

  const [selectIds, setSelectIds] = useState<any>([])

  const [data, setData] = useState<any[]>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectIds(selectedRowKeys)
    },
  }

  useEffect(() => {
    if (visible) {
      patientId && dispatch(getRecipeItemsByPatientIdAsync({ patientId, externalProcessId: id })).then(unwrapResult).then((res: any) => {
        const currentIds = currentList?.length ? currentList?.map((v: any) => v.recipeItemId) : []

        const list = res.map((v: any) => {
          return currentIds?.includes(v.recipeItemId) ? currentList?.find((el: any) => el.recipeItemId == v.recipeItemId) : v
        })
        setData(list)
        setSelectIds(list.filter((v: any) => Number(v.bcglsl) > 0).map((v: any) => v.recipeItemId))
      })
    } else {
      setData([])
      setSelectIds([])
    }
  }, [visible, patientId])

  // useEffect(() => {
  //   return () => {
  //     setData([])
  //     setSelectIds([])
  //   }
  // }, [])

  return (
    <Modal
      visible={visible}
      title='关联处置'
      onCancel={onCancel}
      width={1200}
      destroyOnClose
      bodyStyle={{
        padding: 10,
        height: 600,
        overflow: 'auto'
      }}
      onOk={() => {
        if (!selectIds.length) {
          notification.info({
            message: "请选择需要关联的处置"
          })
          return
        }
        const list = data.filter((v: any) => selectIds.includes(v.recipeItemId))
        if (list.some((v: any) => !v.bcglsl)) {
          notification.info({
            message: "请输入关联数量"
          })
          return
        }
        onOK(list)
      }}
    >
      <EditableList
        rowKey={(v, i) => v.recipeItemId}
        pagination={false}
        columns={RelevanceDisposeColumns(1, (m, values) => {
          const reg = /^\d+$/
          const value = values.bcglsl

          if (!reg.test(value)) return
          if (Number(value) > 0 && Number(value) + m.associatedCount <= m.drugCount) {
            setData(data.map((v: any) => v.recipeItemId == m.recipeItemId ? {
              ...v,
              bcglsl: value
            } : v))
          }
        },)}
        dataSource={data}
        rowSelection={
          {
            selectedRowKeys: selectIds,
            ...rowSelection,
          } as TableRowSelection<any>
        }
      />
    </Modal>
  );
}
