import { Button, Col, Tabs, Row, TableColumnType, Form, Space, Input, Select, Checkbox } from "antd";
import { SearchOutlined, CaretDownFilled } from "@ant-design/icons";
import React, { ReactElement, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styles from "./recardDurg.module.css";
import {DateRangeFieldName, DateRangeType, DateSelect, getDateRange} from "../../../compnents/form/DateSelect";
import { RecevingParams } from "../../../services/receving";
import { pagePutstorageUser, setCategory } from "../../putstorage/putstorageSlice";
import { pageDurgsystem, selectDurgsystemData } from "../../durgsystem/durgsystemSlice";
import { sleectUserData } from "../../putstorelist/putstorelistSlice";
import { BillingCategoryName, InvoiceBillingCategories } from "../../../models/billingCategory";
export const RecardDrugQuery = (
  props: {
    onValueChange: (queries: RecevingParams) => void;
    outClued: () => void;
    continueOrder: (v: any, type: string) => void
  }): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const durgData = useSelector(selectDurgsystemData);

  const userData = useSelector(sleectUserData);

  const [jzcxChecked, setJzcxChecked] = useState<any>()

  const recardDrugParams = JSON.parse(
    sessionStorage.getItem('/recardDrug-queries') || '{}'
  )

  useEffect(() => {
    dispatch(pageDurgsystem(1, 100, 1));

    dispatch(pagePutstorageUser({ current: 1, size: 500 }));
  }, []);
  useEffect(() => {

    if (Object.keys(recardDrugParams).length) {
      form.setFieldsValue({
        ...recardDrugParams,
        timeHead: recardDrugParams?.timeHead,
        timeTail: recardDrugParams?.timeTail,
        [DateRangeFieldName]: recardDrugParams?._dateRange,
      })
    } else {
      const [head, tail] = getDateRange(DateRangeType.ThisMonth);
      form.setFieldsValue({
        timeHead: head,
        timeTail: tail,
      });
    }

    form.submit();
  }, []);
  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(values) => props.onValueChange(values)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='name' noStyle>
              <Input
                autoComplete='off'
                placeholder='药品名称/助记码/条形码/物资编号'
                style={{ width: 260 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item name='' noStyle>
              <Checkbox
                style={{
                  color: '#666',
                  display: 'flex',
                  alignItems: 'center',
                }}
                checked={jzcxChecked}
                onChange={(e) => {
                  props?.continueOrder && props?.continueOrder(e.target.checked, 'jzcx')
                  setJzcxChecked(e.target.checked)
                }}
              >
                是否精准查询
              </Checkbox>
            </Form.Item>
            <Form.Item name='storehouseId' noStyle>
              <Select
                style={{ width: 100 }}
                placeholder='库房名称'
                allowClear
                showSearch
                dropdownClassName={styles.select}
                optionFilterProp='children'
                onChange={() => form.submit()}
                onSelect={(key: any) => {
                  dispatch(setCategory(key))
                }}
              >
                {durgData.map((v: any) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item noStyle name='billingCategory'>
              <Select
                placeholder='开票项目'
                allowClear
                style={{ width: 100 }}
                dropdownClassName={styles.select}
                onChange={() => form.submit()}
              >
                {InvoiceBillingCategories.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {BillingCategoryName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name='materialsCategory' noStyle>
              <Select
                placeholder='物资类别'
                allowClear
                style={{ width: 100 }}
                dropdownClassName={styles.select}
                onChange={() => form.submit()}
              >
                <Option value='0'>药品</Option>
                <Option value='1'>材料</Option>
                <Option value='2'>器械</Option>
                <Option value='3'>保健品</Option>
                <Option value='4'>其他</Option>
              </Select>
            </Form.Item>
            <Form.Item name='userId' noStyle>
              <Select
                showSearch
                placeholder='业务人员'
                allowClear
                dropdownClassName={styles.select}
                onChange={() => form.submit()}
                // suffixIcon={<CaretDownFilled />}
              >
                {userData.map((v: any) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <DateSelect
              style={{ width: '7rem' }}
              labelPrefix=''
              namePrefix='time'
              placeholder='今天'
              initialValue={DateRangeType.ThisMonth}
              onChange={() => form.submit()}
              allowClear={false}
            />
          </Space>
          <Space>
            <Button
              type='primary'
              style={{ marginRight: '10px' }}
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
            <Button type='primary' onClick={() => props.outClued()}>
              导出
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
};
