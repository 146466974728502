import { Button, Popover, Row, Space, TableColumnType, Tooltip } from 'antd'
import React, { ReactElement, useContext } from 'react'
import { Dimen } from '../../models/dimen'
import styles from './treatmentLog.module.css'
import { getRegistrationStateName, Registration } from '../../models/registration'
import { AppointmentIcon, OweMoneyIcon } from '../../compnents/icons/Icons'
import { useSelector } from 'react-redux'
import { selectTenant } from '../user/setting/settingSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import { selectInsuranceArray } from '../../app/applicationSlice'
import { getAge } from '../../utils/StringUtils'
import { getGenderName } from '../../models/user'
import moment from 'moment'
import { DateTimeFormat } from '../../models/datetime'
import { getCardType } from '../ElectronicTicketUpload/columns'
import { getPayFlagName } from '../../models/payment'
import { getTransactionQueryName } from '../../models/TransactionQuery'

type ActionType = 'menus' | 'toggle' | 'reset' | 'setting' | 'login'

export const PaymentColumns = (
    onAction: (
        action:
            | 'treatment'
            | 'cancel'
            | 'print'
            | 'register'
            | 'detail'
            | 'convertInsurance'
            | 'registerNoAppoint',
        reg: Registration
    ) => void,
    startIndex: number,
    patientsMode = false,
): TableColumnType<Registration>[] => {
    const oweMoneyShow: any = useSelector(selectTenant)
    const theme = useContext(ThemeContext)
    const insuranceArray = useSelector(selectInsuranceArray)
    return [
      {
        title: "序号",
        key: "no",
        align: "center",
        width: Dimen.Num,
        render: (_1, _2, i) => `${1 + i + startIndex}`,
      },
      {
        title: "门诊号",
        dataIndex: "outpatientNo",
        width: Dimen.OutpatientNo,
        align: "center",
        render: (_, t) => {
          return Number(t.outpatientNo) > 0 ? t.outpatientNo : "-";
        },
      },
      // {
      //   title: "病历号",
      //   dataIndex: "treatmentNo",
      //   width: Dimen.Name,

      //   align: "center",
      //   // render: (_, t) => {
      //   //   return t.treatmentNo ? t.treatmentNo : "-"
      //   // },
      // },
      {
        title: "患者姓名",
        dataIndex: "patientName",
        width: "10em",
        align: "center",
        render: function action(_, t) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {_}
              {t.appointmentId != -1 && (
                <AppointmentIcon
                  style={{ marginLeft: "5px", width: "18px", height: "18px" }}
                />
              )}
              {t.arrearageAmountSum &&
              t.arrearageAmountSum > 0 &&
              oweMoneyShow?.openOwe == 1 ? (
                <Tooltip title={`欠费金额 ${t.arrearageAmountSum} 元`}>
                  <div style={{ marginTop: "8px" }}>
                    <OweMoneyIcon
                      style={{
                        marginLeft: "5px",
                        width: "18px",
                        height: "18px",
                      }}
                    />
                  </div>
                </Tooltip>
              ) : (
                ""
              )}
              {!!t.labels.length &&
                t.labels.map((v: any) => (
                  <Tooltip key={v.id} title={v.name}>
                    <span
                      className={styles.label}
                      style={{
                        backgroundColor: v.backgroundColor,
                        color: v.fontColor,
                      }}
                    >
                      {v.headName}
                    </span>
                  </Tooltip>
                ))}
            </div>
          );
        },
      },
      {
        title: "病历号",
        key: "patientId",
        width: Dimen.Sex,
        align: "center",
        dataIndex: "patientId",
      },
      {
        title: "结算险种",
        dataIndex: "insuranceCode",
        width: "10em",
        align: "center",
        render: (_, t) => {
          return !t || !t.insuranceCode || t.insuranceCode === "ZIFEI"
            ? "自费"
            : "智慧医保";
        },
      },
      {
        title: "性别",
        key: "gender",
        width: Dimen.Sex,
        align: "center",
        render: (_, r) => getGenderName(r.patientSex),
      },
      {
        title: "年龄",
        dataIndex: "patientAge",
        width: Dimen.Age,
        align: "center",
        render: (_, r) => getAge(r.patientAge, r.patientMonth),
      },
      {
        title: "手机号",
        dataIndex: "patientPhone",
        key: "patientPhone",
        width: Dimen.PatientPhone,
        align: "center",
      },
      {
        title: patientsMode ? "就诊科室" : "挂号科室",
        dataIndex: patientsMode
          ? "treatmentDepartmentName"
          : "registrationDepartmentName",
        width: Dimen.Department,
        align: "center",
        render: function Actions(_, r): ReactElement | undefined {
          return patientsMode ? (
            <div
              style={{
                color:
                  r?.treatmentDepartmentName == "未指定科室"
                    ? "#2D7BFF"
                    : "#333",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (r?.treatmentDepartmentName == "未指定科室") {
                  onAction("registerNoAppoint", r);
                }
              }}
            >
              {r?.treatmentDepartmentName}
            </div>
          ) : (
            <div
              style={{
                color:
                  r?.registrationDepartmentName == "未指定科室"
                    ? "#2D7BFF"
                    : "#333",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (r?.registrationDepartmentName == "未指定科室") {
                  onAction("registerNoAppoint", r);
                }
              }}
            >
              {r?.registrationDepartmentName}
            </div>
          );
        },
      },
      {
        title: patientsMode ? "就诊医生" : "挂号医生",
        dataIndex: patientsMode
          ? "treatmentDoctorName"
          : "registrationDoctorName",
        width: Dimen.Doctor,
        align: "center",
        render: function Actions(_, r): ReactElement | undefined {
          return patientsMode ? (
            <div
              style={{
                color:
                  r?.treatmentDoctorName == "未指定医生" ? "#2D7BFF" : "#333",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (r?.treatmentDoctorName == "未指定医生") {
                  onAction("registerNoAppoint", r);
                }
              }}
            >
              {r?.treatmentDoctorName}
            </div>
          ) : (
            <div
              style={{
                color:
                  r?.registrationDoctorName == "未指定医生"
                    ? "#2D7BFF"
                    : "#333",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (r?.registrationDoctorName == "未指定医生") {
                  onAction("registerNoAppoint", r);
                }
              }}
            >
              {r?.registrationDoctorName}
            </div>
          );
        },
      },
      {
        title: "就诊状态",
        key: "state",
        width: Dimen.Status,
        align: "center",
        render: (_, r) => getRegistrationStateName(r.registrationState),
      },
      {
        title: "挂号时间",
        dataIndex: "registrationTime",
        width: Dimen.Time,
        align: "center",
        render: (_, r) => {
          return moment(r.registrationTime).format(DateTimeFormat);
        },
      },
      {
        title: "预检分诊",
        dataIndex: "precheckState",
        width: Dimen.Time,
        align: "center",
        render: function Actions(_, r): ReactElement | undefined {
          return (
            <Space>
              {r.precheckState == 0 ? (
                <Row>
                  <Button
                    type="text"
                    style={{
                      color: "#333",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => onAction("register", r)}
                  >
                    登记
                  </Button>
                </Row>
              ) : r.precheckState == 1 ? (
                <Row>
                  <Button
                    size="small"
                    type="text"
                    style={{
                      color: "#027aff",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => onAction("detail", r)}
                  >
                    详情
                  </Button>
                </Row>
              ) : (
                <></>
              )}
            </Space>
          );
        },
      },
      {
        title: "操作",
        key: "actions",
        width: "6rem",
        align: "center",
        render: function UserSettings(_, u): ReactElement {
          return (
            <>
              <a onClick={() => onAction("cancel", u)}>修改</a>
              {/* <a style={{marginLeft: '10px'}} onClick={() => onAction("detail", u)}>修改记录</a> */}
            </>
          );
        },
      },
    ];
}


type Action =
  | "pay"
  | "return"
  | "detail"
  | "upload"
  | "repeal"
  | "supplementary";
export const RegistrationColumns = (
  startIndex?: any,
  isEdit?: boolean,
  onAction?: (
    action: Action,
    id: any,
    t: any,
    settlementCategory?: number
  ) => void,
  openDetail?: (text?: any, v?: any) => void
): TableColumnType<any>[] => {
  const insuranceArray = useSelector(selectInsuranceArray);

  return [
    {
      title: "序号",
      key: "no",
      align: "center",
      width: Dimen.Num,
      fixed: "left",
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: "患者姓名",
      key: "patientName",
      align: "center",
      width: "6rem",
      fixed: "left",
      render: function element(_, t) {
        return (
          <Popover
            content={
              <>
                <div style={{ cursor: "pointer" }}>
                  {insuranceArray.length !== 0 &&
                    "医保卡号" +
                      "：" +
                      (t?.medicalCardNo ? t?.medicalCardNo : "-") +
                      "，"}
                  联系方式：
                  {t?.patientPhone ? t?.patientPhone : "-"}
                  ，身份证号：{t?.idCard ? t?.idCard : "-"}
                </div>
              </>
            }
            placement="bottom"
          >
            <div
              style={{
                color: "#027AFF",
                cursor: "pointer",
              }}
              onClick={() => {
                openDetail && openDetail("openDetail", t);
              }}
            >
              {t?.patientName ? t?.patientName : "-"}
            </div>
          </Popover>
        );
      },
    },

    {
      title: "结算险种",
      dataIndex: "insuranceCode",
      width: "6em",
      align: "center",
      render: (_, r) => {
        return r?.insuranceCode === "ZIFEI" || !r?.insuranceCode
          ? "自费"
          : "智慧医保";
      },
    },
    {
      title: "总金额",
      key: "totalPrice",
      width: "6em",
      align: "right",
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? "red" : "",
            }}
          >
            {Number(t?.totalPrice) > 0
              ? Number(t?.totalPrice)?.toFixed(2)
              : "0.00"}
          </div>
        );
      },
    },
    {
      title: "报销金额",
      dataIndex: "insurancePayAmount",
      align: "right",
      width: "6rem",
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? "red" : "",
            }}
          >
            {Number(t?.insurancePayAmount) > 0
              ? Number(t?.insurancePayAmount)?.toFixed(2)
              : "0.00"}
          </div>
        );
      },
    },
    {
      title: "现金金额",
      dataIndex: "cashAmount",
      align: "right",
      width: "6rem",
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? "red" : "",
            }}
          >
            {Number(t?.cashAmount) > 0
              ? Number(t?.cashAmount)?.toFixed(2)
              : "0.00"}
          </div>
        );
      },
    },
    {
      title: "欠费金额",
      dataIndex: "arrearageAmount",
      align: "right",
      width: "6rem",
      ellipsis: true,
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? "red" : "",
            }}
          >
            {Number(t?.arrearageAmount) > 0
              ? Number(t?.arrearageAmount)?.toFixed(2)
              : "0.00"}
          </div>
        );
      },
    },
    {
      title: "超限价金额",
      dataIndex: "overlmtSelfpay",
      align: "right",
      width: "6rem",
      ellipsis: true,
      render: (_, t) => {
        return Number(t?.overlmtSelfpay) > 0
          ? Number(t?.overlmtSelfpay)?.toFixed(2)
          : "-";
      },
    },
    {
      title: "收款人",
      dataIndex: "userName",
      align: "center",
      width: "6rem",
      key: "userName",
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? "red" : "",
            }}
          >
            {t?.userName ? t?.userName : "-"}
          </div>
        );
      },
    },
    {
      title: "接诊医生",
      key: "treatmentDoctorName",
      align: "center",
      width: "6em",
      render: (_, r) => {
        return r?.treatmentDoctorName ? r?.treatmentDoctorName : "-";
      },
    },
    {
      title: "读卡方式",
      key: "cardType",
      align: "center",
      width: "6em",
      render: (_, r) => {
        return r?.cardType ? getCardType(r?.cardType) : "-";
      },
    },
    {
      title: "支付方式",
      dataIndex: "payMethodName",
      key: "payMethodName",
      align: "center",
      width: "8rem",
      ellipsis: true,
      render: (_, r) => {
        return r?.payMethodName ? r?.payMethodName : "-";
      },
    },
    {
      title: "收费标志",
      key: "settlementCategory",
      align: "center",
      width: "6em",
      render: (_, r) => {
        return getPayFlagName(r?.settlementCategory);
      },
    },
    {
      title: "病历号",
      key: "patientId",
      align: "center",
      width: "6em",
      dataIndex: "patientId",
      render: (_, r) => (r?.patientId > -1 ? r?.patientId : "-"),
    },
    {
      title: "手机号",
      key: "patientPhone",
      align: "center",
      width: "8em",
      dataIndex: "patientPhone",
      render: (_, r) => {
        return r?.patientPhone ? r?.patientPhone : "-";
      },
    },
    {
      title: "收费时间",
      key: "chargeOrRefundTime",
      align: "center",
      width: "10em",
      fixed: "right",
      render: (_, r) => {
        return r?.chargeOrRefundTime
          ? moment(r?.chargeOrRefundTime)?.format(DateTimeFormat)
          : "-";
      },
    },
    {
      title: "结算状态",
      align: "center",
      key: "state",
      width: "6rem",
      fixed: "right",
      render: function element(_, t) {
        return (
          <div
            style={{
              color: t?.state == 5 ? "red" : "",
            }}
          >
            {t?.state ? getTransactionQueryName(Number(t?.state)) : "-"}
          </div>
        );
      },
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      width: "6rem",
      fixed: "right",
      render: function ShowAction(_, t): ReactElement {
        return isEdit && t?.state != 5 ? (
          <>
            <a onClick={() => onAction && onAction("return", t.paymentId, t)}>
              修改
            </a>
            {/* <a style={{marginLeft: '10px'}} onClick={() => onAction && onAction("detail", t.paymentId, t)}>
              修改记录
            </a> */}
          </>
        ) : (
          <>-</>
        );
      },
    },
  ];
};
