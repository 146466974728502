/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-04 17:59:54
 */
import { Form, Input, Select, Button, Row, Col, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { UserOutlined, CaretDownFilled } from '@ant-design/icons'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../../compnents/form/DateSelect'
import { treatmentCostParams } from '../../../services/treatmentCost'
import {
  BillingCategoryName,
  TreatmentBillingCategories,
} from '../../../models/billingCategory'
import { stringTrim } from '../../../utils/StringUtils'
import { PrintOutlined } from '../../../compnents/icons/Icons'
import { useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../app/applicationSlice'
const { Option } = Select

export const TreatCostQuery = (props: {
  timeHead?: string
  timeTail?: string
  onValueChange: (queries: treatmentCostParams) => void
  print: () => void
  outClued: () => void
}) => {
  const [form] = Form.useForm()

  const insuranceArray = useSelector(selectInsuranceArray)
  const [insuranceItem, setInsuranceItem] = useState<any>()

  return (
    <Form
      form={form}
      onFinish={(vs) => {
        props.onValueChange(vs)
      }}
    >
      <Row
        style={{ marginBottom: 10, width: '100%' }}
        align='middle'
        justify='space-between'
      >
        <Space>
          <Form.Item name='drugName' noStyle getValueFromEvent={stringTrim}>
            <Input
              placeholder='请输入项目名称'
              allowClear
              onChange={() => form.submit()}
              prefix={<UserOutlined />}
            />
          </Form.Item>
          <Form.Item name='billingCategory' noStyle>
            <Select
              placeholder='开票项目类型'
              style={{ width: '170px' }}
              allowClear
              // suffixIcon={<CaretDownFilled />}
              onChange={() => form.submit()}
            >
              {TreatmentBillingCategories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {BillingCategoryName(v)}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          {insuranceArray && insuranceArray.length !== 0 && (
            <Form.Item name='insuranceCode' noStyle>
              <Select
                placeholder='险种类型'
                style={{ width: '8.25rem' }}
                onChange={(key) => {
                  const insuranceItem = insuranceArray.find(
                    (v) => v.insuranceCode === key
                  )
                  setInsuranceItem(insuranceItem)
                  form.submit()
                }}
              >
                <Select.Option value={''}>全部</Select.Option>
                <Select.Option value={'ZIFEI'}>自费</Select.Option>
                {insuranceArray.map((v) => (
                  <Select.Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <DateSelect
            style={{ width: '8.5rem' }}
            labelPrefix=''
            namePrefix='time'
            options={TJFXTime}
            placeholder='时间'
            initialValue={DateRangeType.Today}
            onChange={() => form.submit()}
            allowClear={false}
          />
          <Button
            htmlType='submit'
            type='primary'
          >
            查询
          </Button>
        </Space>
        <Space style={{ float: 'right' }}>
          <Button
            type='text'
            style={{
              color: '#666',
              display: 'flex',
              alignItems: 'center',
              float: 'right',
            }}
            onClick={() => {
              props.print()
            }}
          >
            <PrintOutlined
              style={{
                fill: '#666',
                width: '18px',
                height: '18px',
                marginRight: '4px',
              }}
            />
            打印
          </Button>
          <Button type='primary' onClick={() => props.outClued()}>
            导出
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
