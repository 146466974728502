/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-31 10:05:10
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import {RootThunkApi } from '../../../app/store'
import { sellingExpenseSummaryParams } from '../../../services/sellingExpenseSummary'
import * as service from '../../../services/sellingExpenseSummary'

export const getSellingExpenseSummaryList = createAsyncThunk<
  void,
 sellingExpenseSummaryParams,
  RootThunkApi
>('doctorAudit/getSellingExpenseSummaryList ', async (params, api) => {
  return sendAsync(service.sellingExpenseSummaryList(params), api)
})


// 导出
export const getSellingExpenseSummaryExport = createAsyncThunk<
  void,
  sellingExpenseSummaryParams,
  RootThunkApi
>('doctorAudit/getSellingExpenseSummaryExport ', async (params, api) => {
  return sendAsync(service.sellingExpenseSummaryExport(params), api)
})
