/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-13 11:49:18
 * @LastEditors: sj
 * @LastEditTime: 2022-12-19 10:46:51
 */
import { Button, Col, Tabs } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { TabBar } from '../../compnents/widgets/TabBar'
import { FollowUpPage } from './followUpPage'
import styles from './followUpPage.module.css'
const { TabPane } = Tabs
export const FollowUpTab = (props: {
  patientId?: string, // 患者管理页面过来
  toothPatient?: boolean //口腔专业版
}): ReactElement => {

  const UseTabs = props.patientId ? Tabs : TabBar

  const UseTabPane = props.patientId ? Tabs.TabPane : TabPane

  const [selectedTab, setSelectedTab] = useState('0')

  const storageParams = JSON.parse(sessionStorage.getItem(location.pathname) || '{}')   //缓存数据回显

  useEffect(() => {

    storageParams?.state && setSelectedTab(storageParams?.state)
  }, [])

  return (
    <>
      <Col className={props?.patientId ? styles.containerP : styles.container}>

        <UseTabs style={{ marginBottom: '20px', height: '100%' }} activeKey={selectedTab} onChange={(v) => setSelectedTab(v)}>
          <UseTabPane key='0' tab='待随访' style={{ marginTop: 10 }}>
            <FollowUpPage state={selectedTab} patientId={props.patientId} toothPatient={props.toothPatient} status={props.toothPatient ? 1 : 0} />
          </UseTabPane>
          <UseTabPane key='1' tab='已完成' style={{ marginTop: 10 }}>
            <FollowUpPage state={selectedTab} patientId={props.patientId} toothPatient={props.toothPatient} status={props.toothPatient ? 1 : 0} />
          </UseTabPane>
        </UseTabs>
        {/* {props.patientId ? (
          <Tabs style={{ marginBottom: '20px', height: '100%' }} activeKey={selectedTab} onChange={(v) => setSelectedTab(v)}>
            <Tabs.TabPane key='0' tab='待随访'>
              <FollowUpPage state={selectedTab} patientId={props.patientId} toothPatient={props.toothPatient} />
            </Tabs.TabPane>
            <Tabs.TabPane key='1' tab='已完成'>
              <FollowUpPage state={selectedTab} patientId={props.patientId} toothPatient={props.toothPatient} />
            </Tabs.TabPane>
          </Tabs>
        ) : (

            <TabBar style={{ marginBottom: '20px' }} activeKey={selectedTab} onChange={(v) => setSelectedTab(v)}>
              <TabPane key='0' tab='待随访'>
                <FollowUpPage state={selectedTab} patientId={props.patientId} toothPatient={props.toothPatient} />
              </TabPane>
              <TabPane key='1' tab='已完成'>
                <FollowUpPage state={selectedTab} patientId={props.patientId} toothPatient={props.toothPatient} />
              </TabPane>
            </TabBar>
        )} */}
      </Col>
    </>
  )
}
