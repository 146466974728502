/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-06 15:26:42
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 11:43:36
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import {
  cancelChange,
  changePrices,
  changePricesParams,
  InventoryPriceBody,
  InventoryPriceImplement,
  InventoryPricePage,
  InventoryPriceParams,
  InventoryPriceRemove,
  InventoryPriceSubmit,
  InventoryPriceTovoid,
  getInventoryPriceSliceExoprtFn,
  ImplementInventoryParams,
  changeMoreBatchSavePrice,
  changeMoreBatchSaveParams,
} from '../../services/InventPrice'

export const initialState = {
  PriceData: [],
  PriceSumData: {},
  PriceTotal: 0,
  Current: 1,
  PriceDetail: [],
  PriceSeletId: 0,
  pageLoading: false,
}

export const InvantPriceSlice = createSlice({
  name: 'InventoryPrice',
  initialState,
  reducers: {
    setPriceData(state, action) {
      state.PriceData = action.payload
    },
    setPriceSumData(state, action) {
      state.PriceSumData = action.payload
    },
    setPriceTotal(state, action) {
      state.PriceTotal = action.payload
    },
    setPriceCurrent(state, action) {
      state.Current = action.payload
    },
    setPriceDetaol(state, action) {
      state.PriceDetail = action.payload
    },
    setPriceSelector(state, action) {
      state.PriceSeletId = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setPriceCurrent,
  setPriceData,
  setPriceSumData,
  setPriceDetaol,
  setPriceSelector,
  setPriceTotal,
  setPageLoading,
} = InvantPriceSlice.actions

export function getInventoryPage(params: InventoryPriceParams): RootThunk {
  return api(InventoryPricePage(params), (data: any, dispatch) => {
    dispatch(setPriceData(data?.page?.records))
    dispatch(
      setPriceSumData({
        adjustAmountSum: data?.adjustAmountSum,
        inventorSplitCountSum: data?.inventorSplitCountSum,
        inventoryCountSum: data?.inventoryCountSum,
      })
    )
    dispatch(setPriceTotal(data.page.total))
    dispatch(setPageLoading(false))
  })
}

export const getInventoryPageAsync = createAsyncThunk<
  void,
  InventoryPriceParams,
  RootThunkApi<void>
>('InventoryPrice/submit', async (params, api) => {
  return sendAsync(InventoryPricePage(params), api, true)
})

export function submitInventory(body: InventoryPriceBody): RootThunk {
  return (dispatch, getState, args) => {
    const current = getState().supplier.Current
    api(InventoryPriceSubmit(body), (data: any, dispatch) => {
      dispatch(getInventoryPage({ current }))
    })(dispatch, getState, args)
  }
}

export const submitInventoryAsync = createAsyncThunk<
  void,
  InventoryPriceBody,
  RootThunkApi<void>
>('InventoryPrice/submit', async (body, api) => {
  return sendAsync(InventoryPriceSubmit(body), api, true)
})

export const TovoidInventoryAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('InventoryPrice/tovoid', async (ids, api) => {
  return sendAsync(InventoryPriceTovoid(ids), api)
})

export const ImplementInventoryAsync = createAsyncThunk<
  void,
  ImplementInventoryParams,
  RootThunkApi<void>
>('InventoryPrice/implement', async (params, api) => {
  return sendAsync(InventoryPriceImplement(params), api)
})

export const RemoveInventoryAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('InventoryPrice/RemoveInventoryAsync', async (ids, api) => {
  return sendAsync(InventoryPriceRemove(ids), api)
})

// 撤回调价
export const cancelChangeAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('InventoryPrice/cancelChangeAsync', async (ids, api) => {
  return sendAsync(cancelChange(ids), api)
})

// 中草药调价
export const changePricesAsync = createAsyncThunk<
  void,
  changePricesParams,
  RootThunkApi<void>
>('InventoryPrice/changePricesAsync', async (body, api) => {
  return sendAsync(changePrices(body), api)
})

// 中草药调价
export const changeMoreBatchSavePriceAsync = createAsyncThunk<
  void,
  changeMoreBatchSaveParams,
  RootThunkApi<void>
>('InventoryPrice/changeMoreBatchSavePriceAsync', async (body, api) => {
  return sendAsync(changeMoreBatchSavePrice(body), api)
})

// 导出
export const getInventoryPriceSliceExoprt = createAsyncThunk<
  void,
  InventoryPriceParams,
  RootThunkApi
>('doctorAudit/getInventoryPriceSliceExoprt ', async (params, api) => {
  return sendAsync(getInventoryPriceSliceExoprtFn(params), api)
})

export const selectPriceData = (state: RootState) =>
  state.InventoryPrice.PriceData

export const selectPriceSumData = (state: RootState) =>
  state.InventoryPrice.PriceSumData

export const selectPriceTotal = (state: RootState) =>
  state.InventoryPrice.PriceTotal

export const selectPriceDetail = (state: RootState) =>
  state.InventoryPrice.PriceDetail

export const selectPriceCurrent = (state: RootState) =>
  state.InventoryPrice.Current

export const selectPriceId = (state: RootState) =>
  state.InventoryPrice.PriceSeletId

export const selectPageLoading = (state: RootState) =>
  state.InventoryPrice.pageLoading

export default InvantPriceSlice.reducer
