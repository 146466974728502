import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-12-07 15:13:49
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-20 19:23:24
 */

export interface ElectronicTicketUploadParams {
  state: number
  current: number
  size?: number
  param?: string
  registrationTimeHead?: string
  registrationTimeTail?: string
  treatmentDepartmentId?: string
  treatmentDepartmentName?: string
  treatmentDoctorName?: string
  payMethod?: any
}

// 列表
export function electronicTicketUploadList(
  params: ElectronicTicketUploadParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/paylist`,
    method: 'GET',
    params,
  }
}

export interface MedicalInformationUploadModalDetailParams {
  patientId: any
  paymentId: any
  treatmentId: any
}

// 列表
export function medicalInformationUploadModalDetail(
  body: MedicalInformationUploadModalDetailParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/paymentDetail`,
    method: 'POST',
    body,
  }
}


// 自费保存
export interface selfMedicalInformationUploadBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    certNo?:any
    invoiceCheckCode?:any
    invoiceCode?:any
    invoiceNumber?:any
    isThirdpart?:any
    nwbFlag?:any
    ownpayType?:any
    paymentId?:any
    treatmentCategory?:any
    treatmentId?:any
  }
}
export function selfMedicalInformationUpload(
  bodyName: selfMedicalInformationUploadBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/ownPayPatnUpload_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}


// 医疗机构上传电子结算凭证信息结果查询
export interface selfMedicalInformationUploadElecSetlCertBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    elecSetlCertType?: any
    insuranceCode?: any
    invoiceCheckCode?: any
    invoiceCode?: any
    invoiceFile?: any
    invoiceId?: any
    invoiceNumber?: any
    isThirdpart?: any
    paymentid?: any
    rea?:any
    iscc?: any
  }
}
export function selfMedicalInformationUploadElecSetlCert(
  bodyName: selfMedicalInformationUploadElecSetlCertBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/upldElecSetlCert_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 医疗机构上传电子结算凭证信息结果查询
export interface selfQueryElecSetlCertInfoUpldRsltBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    invoiceId?: any
    paymentid?: any
  }
}
export function selfQueryElecSetlCertInfoUpldRslt(
  bodyName: selfQueryElecSetlCertInfoUpldRsltBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryElecSetlCertInfoUpldRslt_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 查询电子结算凭证状态
export interface selfQuerySetlCertStasBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    elecSetlCertType:any
    invoiceCheckCode:any
    invoiceCode:any
    invoiceNumber:any
  }
}
export function selfQuerySetlCertStas(bodyName: selfQuerySetlCertStasBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/querySetlCertStas_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}