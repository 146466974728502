/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-29 13:55:46
 * @LastEditors: linxi
 * @LastEditTime: 2021-09-13 16:27:16
 */
export enum PayMethod {
  // 0网银转账;1支付宝转账;2微信转账
  WZ = 0,
  MEN = 1,
  WM = 2,
}

export const PayMethods = [PayMethod.WZ, PayMethod.MEN, PayMethod.WM];

export const getPayMethodName = (pay: PayMethod) => {
  switch (pay) {
    case 0:
      return "未知";
    case 1:
      return "男";
    case 2:
      return "女";
  }
};

export enum reportDepartment {
  GB = 1,
  WEY = 2,
  All = 3,
}

export const reportDepartments = [
  reportDepartment.GB,
  reportDepartment.WEY,
  reportDepartment.All,
];

export const getReportName = (department: reportDepartment) => {
  switch (department) {
    case 1:
      return "报告社区干部";
    case 2:
      return "报告属地卫生院(社区卫生服务中心)";
    case 3:
      return "其他";
  }
};
