/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-08 21:39:22
 * @LastEditors: sj
 * @LastEditTime: 2022-07-26 11:59:35
 */
import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { EditableList } from "../../compnents/containers/EditableList";
import { NavBar } from "../../compnents/nav/NavBar";
import { InventoryMergeParams } from "../../services/InventChange";
import { InventoryMergeColumns } from "./columns";

import {
  getInventMergeList,
  selectChangeCurrent,
  selectChangeMergeList,
  selectChangeTotal,
  setChangeCurrent,
} from "./InventoryChangSlice";
import { InventoryMergeQuery } from "./InventoryMergeQuery";

export const InventoryMerge = () => {
  const dispatch = useDispatch();

  const data = useSelector(selectChangeMergeList);

  const total = useSelector(selectChangeTotal);

  const [queries, setQueries] = useState<InventoryMergeParams>({
    current: 1,
    size: 10,
  });

  const location: any = useLocation();

  useEffect(() => {
    if (location.state) {
      dispatch(
        getInventMergeList({
          tenantMaterialsCatalogId: location.state.tenantMaterialsCatalogId,
          endTime: location.state.endTime,
          startTime: location.state.startTime,
          ...queries,
        })
      );
    }
  }, [location, queries]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Col
        style={{
          margin: "10px 20px",
        }}
      >
        <NavBar
          where={["库房流水查询", "库存变动流水合并明细"]}
          backtrace={{
            name: "库房库存变动流水",
            path: "/InventoryChange",
            routeName: "库房工作站",
          }}
          buttons={<div></div>}
        />
      </Col>
      <EditableList
        hideActionBar
        pagination={{
          items: data,
          currentPage: queries.current || 1,
          pageSize: queries.size || 10,
          totalPages: total,
        }}
        onChangePage={(current, pagesize) => {
          setQueries({
            ...queries,
            current: current,
            size: pagesize,
          });
        }}
        columns={InventoryMergeColumns(
          (queries.size || 10) * ((queries.current || 1) - 1)
        )}
        query={
          <InventoryMergeQuery
            onValueChange={(v: any) => {
              setQueries(v);
            }}
          />
        }
      />
    </div>
  );
};
