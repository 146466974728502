/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-03 17:32:24
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-10 16:56:02
 */
import { Button, Popconfirm, Space, TableColumnType } from 'antd'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../models/datetime'
import { getGenderName } from '../../models/user'
import React from 'react'
import { getAge } from '../../utils/StringUtils'

export const TriageListColumns = (
  onclick: (action: 'register' | 'del', id: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '患者姓名',
      align: 'center',
      dataIndex: 'patientName',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '性别',
      align: 'center',
      dataIndex: 'sex',
      width: '4rem',
      render: (_: string, u: any) => getGenderName(u.sex),
      ellipsis: true,
    },
    {
      title: '联系方式',
      align: 'center',
      dataIndex: 'phone',
      width: '8rem',
      ellipsis: true,
    },
    {
      title: '年龄',
      align: 'center',
      dataIndex: 'age',
      width: '6rem',
      ellipsis: true,
      render: (_, r: any) => getAge(r?.age, r?.ageMonth),
    },
    {
      title: '身份证号',
      align: 'center',
      dataIndex: 'identityCard',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '症状',
      align: 'center',
      dataIndex: 'symptom',
      width: '8rem',
      render: (_, r) => {
        return r.symptom === '无以上症状' ? '无以下症状' : r.symptom
      },
      ellipsis: true,
    },
    {
      title: '预检时间',
      align: 'center',
      dataIndex: 'createTime',
      width: '10rem',
      ellipsis: true,
    },
    {
      title: '预检状态',
      align: 'center',
      dataIndex: 'state',
      width: '8rem',
      render: (_, t) => {
        return t.state ? '预检完成' : '待预检'
      },
      ellipsis: true,
    },
    {
      title: '操作',
      align: 'center',
      width: 190,
      // eslint-disable-next-line react/display-name
      render: (_, t) => {
        return (
          <>
            <Space>
              <Button
                type='primary'
                size='small'
                onClick={() => {
                  onclick('register', t.id)
                }}
              >
                预检分诊登记
              </Button>
              <Popconfirm
                title='确认执行删除操作？'
                onConfirm={() => {
                  onclick('del', t.id)
                }}
                okText='确认'
                cancelText='取消'
              >
                <Button type='primary' size='small'>
                  删除
                </Button>
              </Popconfirm>
            </Space>
          </>
        )
      },
    },
  ]
}
