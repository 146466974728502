/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-08 14:25:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-29 09:56:51
 */
import { Row, Col, Button, Form, Input, Select, notification } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import styles from '../Putstorelist.module.css'
import { Category, CategoryName } from '../../../models/category'
import { DateTimeFormatSimple } from '../../../models/datetime'
import {
  BillingCategories,
  BillingCategoryName,
  InvoiceBillingCategories,
} from '../../../models/billingCategory'
import {
  getMaterialsCategoryName,
  MaterialsCategories,
} from '../../../models/commodity'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { getListExport } from '../putstorelistSlice'
import {PurchaseModal} from "../purchaseModal";
const { Option } = Select

export const InventStockQuery = (props: {
  tenantInventoryReceiptsId?: any
  refresh : () => void
  params: any
  category: Category
  Detail: any
  onValueChange: (queries: any) => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const history = useHistory()

  const [isPurchaseModalVisible, setIsPurchaseModalVisible] = useState(false)

  const [form] = Form.useForm()

  // useEffect(() => {
  //   dispatch()
  // }, [])

  const exportExcel = () => {
    dispatch(
      getListExport({
        ...props?.params,
        tenantInventoryReceiptsId: props?.tenantInventoryReceiptsId,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '库存盘点.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  return (
    <>
      <Row
        className={styles.titletext}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Col
          span={24}
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            paddingLeft: '10px',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex' }}>
            <span
              style={{
                width: '2px',
                height: '16px',
                background: ' #0052D9',
                position: 'relative',
                top: '6px',
                marginRight: '10px',
              }}
            ></span>
            {CategoryName(props.category)}业务单据
          </div>
          <Button
            type='primary'
            style={{ float: 'right', position: 'relative', top: '-28px' }}
            disabled={props.Detail.storehouseTag !== 0 ? true : false}
            onClick={() => {
              switch (props.category) {
                case '5':
                case '6':
                case '7':
                case '8':
                  history.push({
                    pathname: '/debitOut',
                    state: { category: props.category, detail: props.Detail },
                  })
                  break
                case '10':
                  history.push({
                    pathname: '/debitOut',
                    state: { category: props.category, detail: props.Detail },
                  })
                  break
                default:
                  break
              }
            }}
          >
            修改业务单据
          </Button>
          {(props.category as any) == 8 ? (
            <>
              <Button
                type='primary'
                style={{
                  float: 'right',
                  position: 'relative',
                  top: '-28px',
                  marginRight: '10px',
                }}
                disabled={props.Detail.storehouseTag !== 0 ? true : false}
                onClick={() => {
                  setIsPurchaseModalVisible(true)
                }}
              >
                导入盘点
              </Button>
              <Button
                type='primary'
                style={{
                  float: 'right',
                  position: 'relative',
                  top: '-28px',
                  marginRight: '10px',
                }}
                onClick={() => {
                  exportExcel()
                }}
              >
                导出
              </Button>
            </>
          ) : (
            ''
          )}
        </Col>
      </Row>
      <Row
        style={{
          marginTop: props.category == '8' ? '6px' : '20px',
          lineHeight: '30px',
          paddingLeft: '20px',
          marginBottom: props.category == '8' ? 0 : '30px',
          fontSize: '18px',
        }}
      >
        <Col span={6}>库房名称：{props.Detail.storehouseName}</Col>
        <Col span={6}>业务部门：{props.Detail.departmentName}</Col>
        <Col span={6}>业务人员：{props.Detail.userName}</Col>
        <Col span={6}>
          业务日期：
          {moment(props.Detail.recepitsDate).format(DateTimeFormatSimple)}
        </Col>
      </Row>
      <PurchaseModal
        state={5}
        refresh={props?.refresh}
        id={props?.tenantInventoryReceiptsId}
        storehouseId={props?.Detail?.storehouseId}
        isPurchaseModalVisible={isPurchaseModalVisible}
        onOk={() => {
          props.onValueChange(form.getFieldsValue())
          setIsPurchaseModalVisible(false)
        }}
        onCancel={() => {
          setIsPurchaseModalVisible(false)
        }}
      />
      {props.category == '8' ? (
        <Row
          style={{
            lineHeight: '30px',
            paddingLeft: '20px',
            fontSize: '18px',
            marginBottom: '6px',
          }}
        >
          <Col span={24}>备注：{props.Detail.remark}</Col>
        </Row>
      ) : (
        ''
      )}
      <Form
        form={form}
        name='control-hooks'
        onFieldsChange={(_, fs) => {
          props.onValueChange(form.getFieldsValue())
        }}
        autoComplete='off'
      >
        <Row gutter={20} style={{ height: '40px' }}>
          <Col span={5}>
            <Form.Item name='name'>
              <Input
                placeholder='请输入关键字（助记码，物资名称，条形码）'
                allowClear
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='billingCategory'>
              <Select placeholder='开票项目' allowClear>
                {InvoiceBillingCategories.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {BillingCategoryName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='materialsCategory'>
              <Select
                allowClear
                placeholder='物资类别'
                // mode="multiple"
              >
                {MaterialsCategories.map((v) => (
                  <Option value={v} key={v}>
                    {getMaterialsCategoryName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='flag'>
              <Select allowClear placeholder='是否显示零库存'>
                <Option value='0'>否</Option>
                <Option value='1'>是</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='sign'>
              <Select placeholder='是否显示过期药品' allowClear>
                <Option value='0'>否</Option>
                <Option value='1'>是</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={3} offset={4}>
            <Button type='primary' style={{ float: 'right' }}>
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
