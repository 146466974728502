/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-27 11:16:55
 * @LastEditors: sj
 * @LastEditTime: 2022-05-30 11:29:56
 */
export enum CommonCodeCategory {
  Usage = "YF", // 用法
  TcmUsage = "CYYF", // 中药用法
  Frequency = "PC", // 频次
  Unit = "DW", // 单位
  Durg = "YPFL", // 药品分类
  Dosago = "YWJX", // 药品剂型
  KQJC = "KQKQJC", // 口腔检查
  JYJC = "KQJYJC", // 检验检查
  ZLJH = "KQZLJH", // 治疗计划
  KQCZ = "KQCZ", // 口腔处置
  KQZL = "KQZLYJ", // 口腔治疗意见
  MZFS = "MZFS", // 麻醉方式
  SSBW = "SSBW", // 手术部位
  SSDJ = "SSDJ", // 手术等级
  SSQK = "SSQK", // 手术切口
  TW = "TW", // 体位
  ZZ = "ZZ", // 职责
}

export const SurgicalList = [
  CommonCodeCategory.MZFS, // 麻醉方式
  CommonCodeCategory.SSBW, // 手术部位
  CommonCodeCategory.SSDJ, // 手术等级
  CommonCodeCategory.SSQK, // 手术切口
  CommonCodeCategory.TW, // 体位
  CommonCodeCategory.ZZ, // 职责
] as CommonCodeCategory[]

export interface CommonCode {
  id: string
  name: string
  code: string
  codeCalc: number
}

export function fromJson(json: any): CommonCode {
  return {
    id: json.id?.toString() || '',
    name: json.codeName,
    code: json.mnemonicCode,
    codeCalc: json.codeCalc,
  }
}
