/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-06 17:33:24
 * @LastEditors: K
 * @LastEditTime: 2022-07-11 14:27:46
 */
import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined, CaretDownFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from "../../compnents/form/DateSelect";
import { InventoryPriceParams } from "../../services/InventPrice";

const { Option } = Select;

export const InventoryQuery = (props: {
  table: any;
  onValueChange: (queries: InventoryPriceParams) => void;
  outClued: () => void;
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [show, setShow] = useState(false)

  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today);
    // form.setFieldsValue({
    //   timeHead: head,
    //   timeTail: tail,
    // });
    form.submit();
  }, []);

  return (
    <Form
      form={form}
      name="control-hooks"
      autoComplete="off"
      // onFieldsChange={(_, fs) => {
      //   dispatch(setPriceCurrent(1));
      //   props.onValueChange({
      //     ...form.getFieldsValue(),
      //     executeDateStart: form.getFieldsValue().registrationTimeHead,
      //     executeDateEnd: form.getFieldsValue().registrationTimeTail,
      //     registrationTimeHead: null,
      //     registrationTimeTail: null,
      //   });
      // }}
      onFinish={(values) => props.onValueChange(values)}
    >
      <Row gutter={20} wrap={false}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              onChange={() => form.submit()}
              placeholder="请输入物资名,助记码或条形码"
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="state">
            <Select
              onChange={(e) => {
                form.submit()
                setShow(e === 1)

                if (e !== 1) {
                  form.setFieldsValue({
                    timeHead: null,
                    timeTail: null,
                    _dateRange: null
                  })
                }
              }}
              allowClear
              placeholder="执行状态"
              // suffixIcon={<CaretDownFilled />}
            >
              <Option value={0}>未执行</Option>
              <Option value={1}>执行</Option>
            </Select>
          </Form.Item>
        </Col>
        {
          show && (
            <Col span={3}>
          <Form.Item name="">
            <DateSelect
              placeholder="执行时间"
              style={{ width: "8rem" }}
              labelPrefix=""
              // initialValue={DateRangeType.Today}
              namePrefix="time"
              onChange={() => form.submit()}
            />
          </Form.Item>
        </Col>
          )
        }
        <Col style={{ width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
          <Form.Item name="">
            <Button
              // onClick={() => props.onValueChange(form.getFieldsValue())}
              type="primary"
              htmlType="submit"
              style={{ width: "64px", marginRight: '10px' }}
            >
              查询
            </Button>
            <Button
              onClick={() => props.outClued()}
              type="primary"
              style={{ width: "64px" }}
            >
              导出
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
