import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import { fromMaterialJson, Material } from '../../models/material'
import { Page, pageOf } from '../../models/page'
import {
  ctrlSetmeal,
  getSetMealImportExcel,
  ImportExcelParams,
  SetMeal,
  setmealDetail,
  SetMealDurgBody,
  SetmealDurgDetail,
  setmealDurgRemove,
  setmealDurgSubmit,
  setmealList,
  setmealListReplace,
  setmealPage,
  SetMealPageParams,
  setmealSubmit,
  setmealUpdateSortAndGroupNumber,
  updateSortNumber,
  updateSortNumberParams,
} from '../../services/setmeal'
import { fixGroupNumber } from '../../utils/MaterialUtils'

const initialState = {
  SetmealData: [],
  SetmealCurrent: 1,
  SetmealTotal: 0,
  SetmealDetail: {},
  SetmealDurgDetail: {},
  SetmealSelectId: 0,
  SetmealDurgId: 0,
  SetmealTreatId: 0,
  Category: undefined,
  SetmealList: [],
  source: 0,
  flagText: '',
  setmealCategory: 0,
  durgFlag: 0,
  pageLoading: false,
  pageSpinLoading: false,
}

export const setmealSlice = createSlice({
  name: 'setmeal',
  initialState,
  reducers: {
    setSetmealData(state, action) {
      state.SetmealData = action.payload
    },
    setSetmealCurrent(state, action) {
      state.SetmealCurrent = action.payload
    },
    setSetmealTotal(state, action) {
      state.SetmealTotal = action.payload
    },
    setSetmealDetail(state, action) {
      state.SetmealDetail = action.payload
    },
    setSetmealDurgDetail(state, action) {
      state.SetmealDurgDetail = action.payload
    },
    setSetmealSelectId(state, action) {
      state.SetmealSelectId = action.payload
    },
    setCategory(state, action) {
      state.Category = action.payload
    },
    setSetmealList(state, action) {
      state.SetmealList = action.payload
    },
    setSetmealDurgId(state, action) {
      state.SetmealDurgId = action.payload
    },
    setSetmealTreatId(state, action) {
      state.SetmealTreatId = action.payload
    },
    setsource(state, action) {
      state.source = action.payload
    },
    setFlagText(state, action) {
      state.flagText = action.payload
    },
    setDurgFlag(state, action) {
      state.durgFlag = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
    setPageSpinLoading: (state, action) => {
      state.pageSpinLoading = action.payload
    },
  },
})

export const {
  setSetmealData,
  setSetmealTotal,
  setCategory,
  setSetmealDetail,
  setSetmealCurrent,
  setSetmealList,
  setSetmealSelectId,
  setSetmealDurgId,
  setSetmealTreatId,
  setSetmealDurgDetail,
  setsource,
  setFlagText,
  setDurgFlag,
  setPageLoading,
  setPageSpinLoading,
} = setmealSlice.actions

export function getSetmeal(params: SetMealPageParams): RootThunk {
  return api(setmealPage(params), (data: any, dispatch) => {
    dispatch(setSetmealData(data.records))
    dispatch(setSetmealTotal(data.total))
    dispatch(setPageSpinLoading(false))
  })
}

export function getSetmealDetail(id: number): RootThunk {
  return api(setmealDetail(id), (data: any, dispatch) => {
    dispatch(setSetmealDetail(data))
  })
}

export const setmealCtrlAsync = createAsyncThunk<
  void,
  number,
  RootThunkApi<void>
>('setmeal/setmealCtrlAsyncStatus', async (id, api) => {
  return sendAsync(ctrlSetmeal(id), api)
})

export function setmealCtrl(id: number): RootThunk {
  return (dispatch, getState, args) => {
    const current = getState().setmeal.SetmealCurrent
    api(ctrlSetmeal(id), (data, dispatch) => {
      dispatch(getSetmeal({ current }))
    })(dispatch, getState, args)
  }
}

export const submitsetmealAsync = createAsyncThunk<
  void,
  SetMeal,
  RootThunkApi<void>
>('setmel/setmealsubmitAsync', async (body, api) => {
  return sendAsync(setmealSubmit(body), api)
})

export function submitSetmeal(body: SetMeal): RootThunk {
  return (dispatch, getState, args) => {
    const current = getState().setmeal.SetmealCurrent
    api(setmealSubmit(body), (data, dispatch) => {
      dispatch(getSetmeal({ current }))
    })(dispatch, getState, args)
  }
}

export function getSetmealList(id: number): RootThunk {
  return api(setmealList(id), (data: any, dispatch) => {
    const items =
      _.chain(data.records)
        .map((m) => fromMaterialJson(m))
        .sort((a, b) => {
          if (a.groupNumber < b.groupNumber) {
            return -1
          }
          if (a.groupNumber > b.groupNumber) {
            return 1
          }
          return a.sort - b.sort
        })
        .value() || []
    dispatch(setSetmealList(items))
    dispatch(setPageLoading(false))
  })
}

export const updateSortAndGroupNumber = createAsyncThunk<
  void,
  Material[],
  RootThunkApi
>('setmeal/updateSortAndGroupNumber', async (params, api) => {
  return sendAsync(setmealUpdateSortAndGroupNumber(params), api)
})

// 套餐排序
export const getUpdateSortNumber = createAsyncThunk<
  void,
  updateSortNumberParams,
  RootThunkApi
>('setmeal/getUpdateSortNumber', async (params, api) => {
  return sendAsync(updateSortNumber(params), api)
})

export function getsetmealDurgDetail(id: number): RootThunk {
  return api(SetmealDurgDetail(id), (data: any, dispatch) => {
    dispatch(setSetmealDurgDetail(data))
  })
}

export const submitSetmealDurgAsync = createAsyncThunk<
  void,
  SetMealDurgBody,
  RootThunkApi<void>
>('setmeal/getSetMealListStatus', async (body, api) => {
  return sendAsync(setmealDurgSubmit(body), api)
})

export function submitSetmealDurg(
  body: SetMealDurgBody,
  flag?: number
): RootThunk {
  return (dispatch, getState, args) => {
    const current = getState().setmeal.SetmealCurrent
    const id = getState().setmeal.SetmealSelectId
    api(setmealDurgSubmit(body), (data, dispatch) => {
      dispatch(getSetmeal({ current }))
      dispatch(getSetmealList(id))
    })(dispatch, getState, args)
  }
}

export const removeSetmealDurg = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('addtion/remove', async (ids, api) => {
  await sendAsync(setmealDurgRemove(ids), api, true)
  const idsArr = ids.split(',')
  const materials = selectSetmealList(api.getState()).filter(
    (m) => !idsArr.includes(m.id)
  )

  materials.length &&
    (await api.dispatch(updateSortAndGroupNumber(fixGroupNumber(materials))))
})

export const getSetMealList = createAsyncThunk<
  Page<SetMeal>,
  SetMealPageParams,
  RootThunkApi<Page<SetMeal>>
>('setmeal/getSetMealListStatus', async (params, api) => {
  return sendAsync(
    setmealPage({
      ...params,
      category:
        params.category === '2' || params.category === '0'
          ? '0,2'
          : params.category,
    }),
    api
  ).then((data: Record<string, never>) => {
    return pageOf(
      data,
      _.chain(data.records)
        .map((s) => s as SetMeal)
        .value()
    )
  })
})

export const getSetMealDetail = createAsyncThunk<
  Material[],
  string | number,
  RootThunkApi<Material[]>
>('setmail/getSetMealDetailStatus', async (id, api) => {
  return sendAsync(setmealListReplace(id), api).then(
    (data: Record<string, never>) =>
      _.chain(data.records)
        .map((m) => {
          const material = fromMaterialJson(m)
          material.drugCount = m.count
          material.countPerTip = m.count
          material.splitTag = 0
          return material
        })
        .value()
  )
})

export const getSetMealImportExcelAsync = createAsyncThunk<
  void,
  ImportExcelParams,
  RootThunkApi
>('/Inventory/importExcel', async (body, api) => {
    return sendAsync(getSetMealImportExcel(body), api)
})


export const selectSetmealData = (state: RootState) => state.setmeal.SetmealData

export const selectSetmealCurrent = (state: RootState) =>
  state.setmeal.SetmealCurrent

export const selectSetmealTotal = (state: RootState) =>
  state.setmeal.SetmealTotal

export const selectSetmealDetail = (state: RootState) =>
  state.setmeal.SetmealDetail

export const selectSetmealDurgDetail = (state: RootState) =>
  state.setmeal.SetmealDurgDetail

export const selectSetmealSelectId = (state: RootState) =>
  state.setmeal.SetmealSelectId

export const selectCategory = (state: RootState) => state.setmeal.Category

export const selectSetmealList = (state: RootState): Material[] =>
  state.setmeal.SetmealList

export const selectDurgId = (state: RootState) => state.setmeal.SetmealDurgId

export const selectTreatId = (state: RootState) => state.setmeal.SetmealTreatId

export const selectSource = (state: RootState) => state.setmeal.source

export const selectFlagText = (state: RootState) => state.setmeal.flagText //是否是添加材料

export const selectDurgFlag = (state: RootState) => state.setmeal.durgFlag

export const selectPageLoading = (state: RootState) => state.setmeal.pageLoading

export const selectPageSpinLoading = (state: RootState) =>
  state.setmeal.pageSpinLoading

export default setmealSlice.reducer
