import { Form, Space, Col, Popover, Switch, TableColumnType } from "antd";
import moment from "moment";
import React from "react";
import { BillingCategoryName } from "../../../models/billingCategory";
import { DateTimeFormat, DateTimeFormatSimple } from "../../../models/datetime";
import { Dimen } from "../../../models/dimen";

export const RecardDrugColumns = (
  startIndex: number,
  receiptDetail: (id:any) => void
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      key: 'no',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '业务单号',
      align: 'center',
      dataIndex: 'putNumber',
      width: Dimen.Coding,
      key: 'putNumber',
      render: function Element(_, record) {
        return (
          <a onClick={() => {
            receiptDetail(record.tenantInventoryReceiptId)
          }} style={{cursor: 'pointer',width:'100%',height:'100%',alignItems: 'center',display: 'flex',margin:'auto'}}> {record.putNumber} </a>
        );
      }
    },
    {
      title: '商品编码',
      align: 'center',
      dataIndex: 'no',
      width: Dimen.Coding,
      key: 'no',
    },
    {
      title: '产品信息',
      align: 'left',
      width: '300px',
      dataIndex: 'producInfo',
      key: 'producInfo',
      render: function Element(_, record) {
        return (
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              <Popover
                content={
                  <div style={{ height: '55px' }}>
                    <p
                      style={{
                        height: '28px',
                        lineHeight: '36px',
                        padding: '0',
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bolder',
                      }}
                    >
                      {record.name}
                    </p>
                    <p
                      style={{
                        height: '28px',
                        lineHeight: '24px',
                        padding: '0',
                        margin: '0',
                        color: '#999999',
                        fontSize: '14px',
                      }}
                    >
                      {record.tenantSupplierName} /{' '}
                      {record.packSpec ? record.packSpec : '暂无'} /
                      {record.doseCategory}
                    </p>
                  </div>
                }
                placement='bottom'
              >
                <div
                  style={{ height: '55px', width: '300px', overflow: 'hidden' }}
                >
                  <p
                    style={{
                      height: '28px',
                      lineHeight: '36px',
                      padding: '0',
                      margin: '0',
                      fontSize: '16px',
                      fontWeight: 'bolder',
                    }}
                  >
                    {record.name}
                  </p>
                  <p
                    style={{
                      height: '28px',
                      lineHeight: '24px',
                      padding: '0',
                      margin: '0',
                      color: '#999999',
                      fontSize: '14px',
                    }}
                  >
                    {record.tenantSupplierName} /{' '}
                    {record.packSpec ? record.packSpec : '暂无'} /
                    {record.doseCategory}
                  </p>
                </div>
              </Popover>
            </Col>
          </Space>
        )
      },
    },
    {
      title: '制剂规格',
      align: 'center',
      width: Dimen.ApprovalNumber,
      dataIndex: 'spec',
      key: 'spec',
    },
    {
      title: '剂型',
      align: 'center',
      width: Dimen.ApprovalNumber,
      dataIndex: 'doseCategory',
      key: 'doseCategory',
    },
    {
      title: '通用名',
      align: 'center',
      width: Dimen.ApprovalNumber,
      dataIndex: 'genericName',
      key: 'genericName',
    },
    {
      title: '产地',
      align: 'center',
      width: Dimen.ApprovalNumber,
      dataIndex: 'origin',
      key: 'origin',
    },
    {
      title: '医保国码',
      align: 'center',
      width: Dimen.ApprovalNumber,
      dataIndex: 'hilistCode',
      key: 'hilistCode',
    },
    {
      title: '批次号',
      align: 'center',
      width: Dimen.ApprovalNumber,
      dataIndex: 'batchNo',
      key: 'batchNo',
    },
    {
      title: '批准文号',
      align: 'center',
      width: Dimen.ApprovalNumber,
      dataIndex: 'approvalNumber',
      key: 'approvalNumber',
    },
    {
      title: '库房名称',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'storehouseName',
      key: 'storehouseName',
    },
    {
      title: '生产日期',
      align: 'center',
      width: Dimen.Time,
      dataIndex: 'productDate',
      key: 'productDate',
      render: (_: any, t: any) => {
        return t.productDate
          ? moment(t.productDate).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '有效期至',
      align: 'center',
      width: Dimen.Time,
      dataIndex: 'deadline',
      key: 'deadline',
      render: (_: any, t: any) => {
        return t.deadline
          ? moment(t.deadline).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '进价',
      align: 'right',
      width: Dimen.Price,
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (_: any, t: any) => {
        return t.unitPrice
          ? parseFloat((t.unitPrice as unknown) as string).toFixed(5)
          : 0
      },
    },
    {
      title: '销售价',
      align: 'center',
      width: Dimen.Price,
      dataIndex: 'retailPrice',
      key: 'retailPrice',
      render: (_: any, t: any) => {
        return t.retailPrice
          ? parseFloat((t.retailPrice as unknown) as string).toFixed(5)
          : 0
      },
    },
    {
      title: '采购数量',
      align: 'center',
      width: Dimen.Count,
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: '变动金额(进价)',
      align: 'right',
      width: Dimen.Price,
      dataIndex: '',
      key: '',
      render: (_: any, t: any) => {
        return t.unitPrice * t.count
          ? parseFloat(((t.unitPrice * t.count) as unknown) as string).toFixed(
              2
            )
          : 0
      },
    },
    {
      title: '供应商',
      align: 'center',
      width: Dimen.SupplierName,
      dataIndex: 'supplierName',
      key: 'supplierName',
    },
    {
      title: '采购时间',
      align: 'center',
      width: Dimen.Time,
      dataIndex: 'recepitsDate',
      key: 'recepitsDate',
      render: (_: any, t: any) => {
        return t.recepitsDate
          ? moment(t.recepitsDate).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '采购人员',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'userName',
      key: 'userName',
    },
  ]
};
