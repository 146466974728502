/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-03-29 13:33:08
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-15 16:48:09
 */
import { StationCategory, toJson, User } from '../models/user'
import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface GetUserListParams {
  current: number
  size?: number
  outpatientDepartmentId?: string
  innerDepartmentId?: string
  param?: string
  state?: number
  tenantId?: string
  stationCategory?: number
}

export function getUserList(params: GetUserListParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/list`
      : `${API_PREFIX}/blade-user/user/list`,
    method: 'GET',
    params,
  }
}

export interface CreateUserParams {
  tenantId?: any
  user: User
  headPhoto?: any
}

export function createOrUpdateUser(params: CreateUserParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/save`
      : `${API_PREFIX}/blade-user/user/save`,
    method: 'POST',
    body: {
      ...toJson(params.user),
      tenantId: params.tenantId ,
      headPhoto: params?.headPhoto,
    },
  }
}

export interface UploadHeadPhotoParams {
  userId?: any
  file?: any
}

export function UploadHeadPhotoAsync(params: UploadHeadPhotoParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/uploadHeadPhoto`,
    method: 'POST',
    headers: {
      [ContentType]: MultiPartFormData,
    },
    params: {
      userId: params.userId ,
      file: params?.file,
    },
  }
}

UploadHeadPhotoAsync

export interface GetUserDetailParams {
  tenantId?: string
  userId: string
}

export function getUserDetail(params: GetUserDetailParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/detail`
      : `${API_PREFIX}/blade-user/user/detail`,
    method: 'POST',
    params: {
      userId: params.userId,
    },
  }
}

export interface ToggleUserStateParams {
  tenantId?: string
  userId: string
}

export function toggleUserState(params: ToggleUserStateParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/logoutOrEnable`
      : `${API_PREFIX}/blade-user/user/logoutOrEnable`,
    method: 'POST',
    params: {
      userId: params.userId,
    },
  }
}

export interface ResetUserPasswordParams {
  id: string
  phone: string
  tenantId?: string
}

export function resetUserPassword(params: ResetUserPasswordParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/passwordReset`
      : `${API_PREFIX}/blade-user/user/password/reset`,
    method: 'POST',
    body: {
      id: params.id,
      phone: params.phone,
    },
  }
}

export function thirdTenantMenuTreeFn(params: any): Request {
  return {
    url: `${API_PREFIX}/blade-user/usermenu/listThirdTenantMenuTree`,
    method: 'GET',
    params,
  }
}

export function setThirdTenantMenuTreeFn(body: any): Request {
  return {
    url: `${API_PREFIX}/blade-user/usermenu/saveUserMenuPermissions`,
    method: 'POST',
    body,
  }
}

export function getDoctorsInDepartment(departmentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/list`,
    method: 'GET',
    params: {
      outpatientDepartmentId: departmentId,
      stationCategory: StationCategory.DoctorOrPharmacist,
      state: 1,
      size: 1000,
    },
  }
}

export function getNurse(departmentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/list`,
    method: 'GET',
    params: {
      stationCategory: StationCategory.Nurse,
      state: 1,
      size: 1000,
    },
  }
}

// 获取预约医生
export function getAppointmentDoctors(departmentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentRecordDoctor`,
    method: 'GET',
    params: {
      departmentId: departmentId,
    },
  }
}

// 新增编辑预约
export function addAppointment(body: any): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/appointmentRecordSave`,
    method: 'POST',
    body,
  }
}

// 预约回显
export function seeAppointment(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentRecord`,
    method: 'GET',
    params: {
      id,
    },
  }
}

// 获取医生可用时间
export function getDoctorsTimes(doctorId: string): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getDoctorScheduleTime`,
    method: 'GET',
    params: {
      doctorId,
    },
  }
}

// 获取医生可用时间刻度
export interface DoctorsTimeStepParams {
  id?: string
  doctorId?: string
}
export function getDoctorsTimeStep(params: DoctorsTimeStepParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentRecord/getAppointmentDoctorScale`,
    method: 'GET',
    params,
  }
}

export function getUsersInDepartment(
  departmentId: string,
  stationCategory?: StationCategory
): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/list`,
    method: 'GET',
    params: {
      outpatientDepartmentId: departmentId,
      stationCategory,
      state: 1,
      size: 1000,
    },
  }
}
export function getUserProfile(): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/profile`,
    method: 'GET',
  }
}
