import { Form, Row, Col, Input, Button, Select, notification } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BillingCategories,
  BillingCategoryName,
} from '../../../models/billingCategory'
import { CommonCodeCategory } from '../../../models/commoncode'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import { getOtcCategoryName, OtcCategories } from '../../../models/Otccategory'
import {
  getCommonCodeCompletions,
  selectCommonCodes,
} from '../../completion/completionSlice'
import {
  detailStoresearch,
  selectDetail,
  updataStoreseachAsync,
} from '../storesearchSlice'
import styles from '../Storesearch.module.css'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../app/store'
import { useLocation } from 'react-router-dom'
const { Option } = Select
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const BasicFacts = (props: {
  SelectId: number
  flag: number
  title?: string
}) => {
  const [form] = Form.useForm()

  const location: any = useLocation()

  const detail: any = useSelector(selectDetail)

  const dispatch = useDispatch<RootDispatch>()

  const onReset = () => {
    form.resetFields()
  }

  useEffect(() => {
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Dosago, ''))
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Unit, ''))
    form.setFieldsValue({
      no: Math.random().toString().substr(2, 19),
    })
    if (location) {
      dispatch(detailStoresearch(location.state.selectId))
    }
  }, [])
  const YWdata = useSelector(selectCommonCodes(CommonCodeCategory.Dosago))
  const DWdata = useSelector(selectCommonCodes(CommonCodeCategory.Unit))

  useEffect(() => {
    form.setFieldsValue({
      name: detail.name,
      storehouseName: detail.storehouseName,
      mnemonicCode: detail.mnemonicCode,
      genericName: detail.genericName,
      barCode: detail.barCode,
      billingCategory: detail.billingCategory,
      compoundTag: detail.compoundTag,
      otc: detail.otc,
      origin: detail.origin,
      batchNo: detail.batchNo,
      no: detail.no,
      tenantSupplierName: detail.tenantSupplierName,
      approvalNumber: detail.approvalNumber,
      unitPrice: detail.unitPrice,
      retailPrice: detail.retailPrice,
      count: detail.count,
      splitCount: detail.splitCount,
      splitTag: detail.splitTag,
      splitScale: detail.splitScale,
      splitPrice: detail.splitPrice,
      productDate: moment(detail.productDate).format(DateTimeFormatSimple),
      deadline: moment(detail.deadline).format(DateTimeFormatSimple),
      id: detail.id,
      packSpec: detail.packSpec,
      doseCategory: detail.doseCategory,
      accountUnit: detail.accountUnit,
      shelfNo: detail.shelfNo,
      plateNo: detail.plateNo
    })
  }, [detail])
  return (
    <Form
      className={styles.basicFactsMain}
      {...layout}
      style={{ overflowY: 'scroll', overflowX: 'hidden' }}
      form={form}
      name='control-hooks'
      onFinish={(fs) => {
        const endTime = moment(fs.deadline).endOf('day').format(DateTimeFormat)
        const productTime = moment(fs.productDate).endOf('day').format(DateTimeFormat)
        dispatch(
          updataStoreseachAsync({
            productDate: productTime == 'Invalid date' ? '' : productTime,
            deadline: endTime,
            shelfNo: fs.shelfNo,
            plateNo: fs.plateNo,
            id: props.SelectId,
          })
        )
          .then(unwrapResult)
          .then((res) => {
            history.go(-1)
            notification.success({
              message: '修改成功',
              duration: 3,
            })
          })
      }}
    >
      <Row gutter={20} style={{ marginTop: '40px' }} >
        <Col span={16}>
          <Form.Item
            name='name'
            label='名称'
            labelCol={{
              span: 3,
            }}
            wrapperCol={{
              span: 21,
            }}
            rules={[{ max: 45, message: '最长45位!' }]}
          >
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='storehouseName' label='库房'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='no' label='商品编码'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='mnemonicCode' label='助记码'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='genericName' label='通用名'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='barCode' label='条形码'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='billingCategory' label='开票项目'>
            <Select placeholder='开票项目' allowClear disabled>
              {BillingCategories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {BillingCategoryName(v)}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='compoundTag' label='复方标志'>
            <Select allowClear disabled>
              <Option value={1}>单味</Option>
              <Option value={2}>复方</Option>
              <Option value={0}>非草药</Option>
            </Select>
          </Form.Item>
        </Col>
        {detail.billingCategory == '01' ? (
          <Col span={8}>
            <Form.Item name='otc' label='OTC分类'>
              <Select placeholder='OTC分类' allowClear disabled>
                {OtcCategories.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {getOtcCategoryName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
        ) : (
          ''
        )}
        <Col span={8}>
          <Form.Item name='packSpec' label='规格'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='doseCategory' label='剂型'>
            <Select showSearch placeholder='剂型' disabled>
              {YWdata.map((v: any, i) => {
                return (
                  <Option value={v.name} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name='accountUnit' label='账簿单位'>
            <Select
              showSearch
              placeholder='账簿单位'
              optionFilterProp='children'
              disabled
            >
              {DWdata.map((v: any, i) => {
                return (
                  <Option value={v.name} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='origin' label='产地'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='batchNo' label='批号'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            name='tenantSupplierName'
            label='生产厂家'
            labelCol={{
              span: 3,
            }}
            wrapperCol={{
              span: 21,
            }}
          >
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='approvalNumber' label='批准文号'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        {!props.flag ? (
          <Col span={8}>
            <Form.Item name='unitPrice' label='库存进价'>
              <Input size='middle' allowClear disabled />
            </Form.Item>
          </Col>
        ) : (
          <></>
        )}
        <Col span={8}>
          <Form.Item name='retailPrice' label='库存售价'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='count' label='库存数量'>
            <Input size='middle' allowClear disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='splitTag' label='是否拆零'>
            <Select allowClear disabled>
              <Option value={0}>否</Option>
              <Option value={1}>是</Option>
            </Select>
          </Form.Item>
        </Col>
        {detail.splitTag ? (
          <>
            <Col span={8}>
              <Form.Item name='splitCount' label='拆零数量'>
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name='splitScale' label='拆零比例'>
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name='splitPrice' label='拆零单价'>
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>
      <div className={styles.title}>
        <p className={styles.titletext}>可编辑</p>
      </div>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            name='productDate'
            label='生产日期'
            rules={[
              {
                required: true,
                pattern: new RegExp(
                  /(([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})\/(((0[13578]|1[02])\/(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)\/(0[1-9]|[12][0-9]|30))|(02\/(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))\/02\/29)/
                ),
                message: '请输入正确时间,时间格式为YYYY/mm/DD',
              },
            ]}
          >
            <Input size='middle' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name='deadline'
            label='有效期至'
            rules={[
              {
                required: true,
                pattern: new RegExp(
                  /(([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})\/(((0[13578]|1[02])\/(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)\/(0[1-9]|[12][0-9]|30))|(02\/(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))\/02\/29)/
                ),
                message: '请输入正确时间,时间格式为YYYY/mm/DD',
              },
            ]}
          >
            <Input size='middle' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='shelfNo' label='货架号'>
            <Input size='middle' allowClear />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name='plateNo' label='牌号'>
            <Input size='middle' allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <Button
            htmlType='button'
            style={{ float: 'right' }}
            onClick={() => {
              history.go(-1)
            }}
          >
            返回
          </Button>
        </Col>
        <Col span={12}>
          <Button type='primary' htmlType='submit'>
            完成
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
