/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-23 10:31:04
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface DepartmentManagementListPrams {
  current: number
  size: number
  startTime: string
  endTime: string
}

// 列表
export function departmentManagementList(
  params: DepartmentManagementListPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/department/list`,
    method: 'GET',
    params,
  }
}

// 详情
export function departmentManagementDetail(departmentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-user/department/view`,
    method: 'GET',
    params: { departmentId },
  }
}

export interface DepartmentManagementEditPrams {
  name?: string
  deptLogoUrl?: string
  deptSpecial?: string
  id?: string
  itro?: string
}

// 保存
export function departmentManagementEditSubmit(
  body: DepartmentManagementEditPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/department/save`,
    method: 'POST',
    body,
  }
}

export interface DepartmentManagementUploadPrams {
  departmentId?: string
}

// 保存
export function departmentManagementUpload(
  params: DepartmentManagementUploadPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/department/showFlg`,
    method: 'POST',
    params,
  }
}
