/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 1373842098@qq.com
 * @Date: 2022-05-05 17:44:51
 * @LastEditors: sj
 * @LastEditTime: 2022-05-07 17:13:13
 */
import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface ApplyBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    begndate?: string
    clr_type?: string
    enddate?: string
    setlym?: string
  }
}

// 清算申请 3203
export function settlementApply(bodyName: ApplyBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/liquidationApply_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export interface ApplyRepealBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    clr_appy_evt_id?: string
  }
}

// 清算申请撤销 3204
export function settlementApplyRepeal(bodyName: ApplyRepealBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/liquidationApplyRepeal_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export interface ListUploadBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    treatmentid: number
    paymentid:number
  }
}
// 清单上传 4101A
export function settlementListUpload(bodyName: ListUploadBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/fundSettlementListUpload_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export interface ListChangeBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    stasType?: string
    treatmentid?: number
    paymentid?: number
  }
}

export interface upldElecSetlCertBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    paymentid: number
    invoiceId?: string
  }
}
// 清单修改 4102
export function settlementListChange(bodyName: ListChangeBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/fundSettlementListChange_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 清单查询 4103
export function settlementListSearch(bodyName: ListChangeBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/fundSettlementListSearch_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 电子票据上传 4906
export function upldElecSetlCert(bodyName: upldElecSetlCertBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/upldElecSetlCert_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 电子票据状态查询 4907
export function queryElecSetlCertInfoUpldRslt(bodyName: upldElecSetlCertBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryElecSetlCertInfoUpldRslt_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

