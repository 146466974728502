/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-07-07 11:49:52
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-05 10:44:55
 */
import { CloseOutlined, SettingOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  FollowUpTypeTimeList,
  FollowUpWayList,
  getDiffDate,
  getDiffDay,
  getFollowUpTypeCategoryList,
  getFollowUpWayCategoryList,
} from '../../models/followUp'
import { stringTrim } from '../../utils/StringUtils'
import { SettingFollowUpTypeListModal } from './settingFollowUpTypeListModal'
import styles from './addFollowUp.module.css'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnly,
} from '../../models/datetime'
import {
  getFollowUpPeopleList,
  getFollowUpSubmitList,
  getFollowUpTypeList,
  getFollowUpWaitDetail,
  selectAddVisibleData,
} from './followUpPageSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { setFlagText } from '../setmeal/setmealSlice'
import { selectEdition } from '../../app/applicationSlice'
export const CommonRightForm = (props: {
  bindType?: any
  status?: any
  appointmentId?: string
  registrationId?: string
  recipeId?: string // his 口腔版 诊断记录 Id
  id?: any //当前列表id
  treatmentData?: any
  treatmentId?: any //就诊记录传
  tcId?: any //治疗计划传
  selectKey?: any //区分是就诊记录（0）还是治疗计划 （1）
  name?: any //左边名字
  paytientId?: any //随访患者id
  state?: any //区分随访计划或者随访记录
  onValueChange: (c: any) => void // 点击取消调用form表单
  onCancel: () => void
  onOk: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [commonForm] = Form.useForm()

  const { Option } = Select

  const { RangePicker } = DatePicker

  const [value, setValue] = useState(1)

  const [overlayVisible, setOverlayVisible] = useState<any>(true)

  const [followUpTimeValue, setFollowUpTimeValue] = useState<any>(1)

  const [
    isSettingFollowUpTypeListModalVisible,
    setIsSettingFollowUpTypeListModalVisible,
  ] = useState(false)

  const followUpLContentData: any = JSON.parse(
    sessionStorage?.getItem('followUpContent') || '{}'
  ) //缓存中的数据

  const [followUpTypeList, setFollowUpTypeList] = useState<any>([]) //随访类型list

  const [followUpPeopleList, setFollowUpPeopleList] = useState<any>([]) //随访人list

  const [followUpWaitDetail, setFollowUpWaitDetail] = useState<any>() //待随访详情

  const followUpLContentList: any = followUpLContentData?.followUpContent

  const [followUpType, setFollowUpType] = useState<any>(Number(7)) //随访类型的值

  const [startTime, setStartTime] = useState<any>()

  const [endTime, setEndTime] = useState<any>()

  const [moreFollowUpTimeList, setMoreFollowUpTimeList] = useState<any>([])

  const [weekTimeList, setWeekTimeList] = useState<any>([])

  const [state, setState] = useState(0) //1随访记录 2待随访 0 随访计划

  const [folllowUpPeopleName, setFolllowUpPeopleName] = useState('')

  const [oneTime, setOneTime] = useState<any>() //单次随访时间

  const [editOneTime, setEditOneTime] = useState<any>()

  const [loading, setLoading] = useState(false)

  const [trData, setTrData] = useState<any>()

  const isAddVisibel: any = useSelector(selectAddVisibleData)

  const edition = useSelector(selectEdition)

  // 随访人
  useEffect(() => {
    // if (isAddVisibel)
    dispatch(getFollowUpPeopleList({ size: 10000, current: 1 }))
      .then(unwrapResult)
      .then((res) => {
        setFollowUpPeopleList(
          res?.records
            ?.filter((v: any) => v.kssxbz != 1)
            .filter((v: any) => v.state == 1)
        )
      })
  }, [dispatch, isAddVisibel])

  useEffect(() => {
    if (props?.selectKey) {
      commonForm.setFieldsValue({
        name: '',
      })
    }
  }, [props?.selectKey])

  // 随访类型
  useEffect(() => {
    // if (isAddVisibel)
    dispatch(getFollowUpTypeList())
      .then(unwrapResult)
      .then((res) => {
        setFollowUpTypeList(res)
      })
  }, [dispatch, isAddVisibel])

  //待随访详情
  useEffect(() => {
    if (props?.id && isAddVisibel)
      dispatch(getFollowUpWaitDetail(props?.id))
        .then(unwrapResult)
        .then((res) => {
          setFollowUpWaitDetail(res)
        })
  }, [dispatch, props?.id, isAddVisibel])

  //计算时间差
  useEffect(() => {
    if (
      getDiffDay(startTime, endTime) &&
      followUpType &&
      getDiffDay(startTime, endTime) !== 0 &&
      followUpTimeValue == 2
    ) {
      const differ =
        Math.floor(getDiffDay(startTime, endTime) / followUpType) + 1 //差
      getList(getDiffDate(startTime, endTime), differ)
    }
  }, [startTime, endTime, followUpType])

  const getList = (v: any, num: any) => {
    for (let i = 0; i <= num; i++) {
      setWeekTimeList((g: any) => {
        return [...g, v?.[i * followUpType]]
      })
    }
  }

  const weekList = weekTimeList?.filter((v: any) => {
    return v != undefined || v != null
  })

  const moreFollowUpTime = moreFollowUpTimeList?.filter((v: any) => {
    return v != undefined || v != null
  })

  const refresh = () => {
    setLoading(false)
    setFollowUpTimeValue(1)
    commonForm?.resetFields()
    setFollowUpTimeValue(1)
    setMoreFollowUpTimeList([])
    setWeekTimeList([])
  }

  useEffect(() => {
    if (isAddVisibel == true) {
      commonForm.setFieldsValue({
        state: 0,
        followtype: 7,
        contentTime: followUpTimeValue || 1,
      })
    } else {
      commonForm?.resetFields()
    }
  }, [isAddVisibel])

  useEffect(() => {
    if (props?.name) {
      commonForm.setFieldsValue({
        name: props?.name,
      })
    } else if (props?.treatmentData) {
      commonForm.setFieldsValue({
        name: props?.treatmentData?.patientName,
      })
    }
  }, [props?.name, props?.treatmentData])

  useEffect(() => {
    if (props?.id && followUpWaitDetail) {
      const id = followUpWaitDetail?.userId
      const followList = followUpPeopleList?.filter((v: any) => v.id == id)
      commonForm.setFieldsValue({
        ...followUpWaitDetail,
        userId: followList?.length ? followUpWaitDetail.userId : '',
        visitTimeList: followUpWaitDetail?.visitTime
          ? moment(followUpWaitDetail?.visitTime)
          : null,
      })
    }
  }, [props?.id, followUpWaitDetail])

  useEffect(() => {
    if (props?.selectKey) {
      commonForm.setFieldsValue({
        name: '',
      })
    }
  }, [props?.selectKey])

  return (
    <>
      <Form
        // {...layout}
        style={{
          height: '556px',
          overflow: 'auto',
          padding: '0 20px 0 30px',
        }}
        autoComplete='off'
        form={commonForm}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        onFinish={(fs: any) => {
          setLoading(true)
          if (props?.id) {
            dispatch(
              getFollowUpSubmitList({
                ...followUpWaitDetail,
                ...fs,
                bindType: props?.selectKey,
                userName: folllowUpPeopleName
                  ? folllowUpPeopleName
                  : followUpWaitDetail?.userName,
                userId: fs?.userId ? fs?.userId : followUpWaitDetail?.userId,
                paytientId: followUpWaitDetail?.paytientId, //随访患者id
                visitTimeList: editOneTime
                  ? [editOneTime]
                  : [followUpWaitDetail?.visitTime],
                state: 1,
                bindId: followUpWaitDetail?.bindId,
                contentTime: undefined,
                followtype: undefined,
                times1: undefined,
                times2: undefined,
                times3: undefined,
                recipeId: props.recipeId ? props.recipeId : undefined,
              })
            )
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '操作成功',
                })
                props?.onOk()
                refresh()
              })
              .catch(() => {
                props?.onCancel()
                refresh()
              })
          } else {
            dispatch(
              getFollowUpSubmitList({
                ...fs,
                name: props?.name || trData?.patientName,
                bindType: props?.selectKey,
                userName: folllowUpPeopleName,
                bindId:
                  props?.selectKey == "0"
                    ? props?.treatmentId || trData?.id
                    : props?.selectKey == "1"
                    ? props?.tcId //绑定病历表，treatmentId 绑定治疗计划tps_id
                    : props?.selectKey == "3"
                    ? props?.treatmentId
                    : props.recipeId, //绑定 处置记录
                paytientId: props?.paytientId || trData?.patientId, //随访患者id
                visitTimeList:
                  state == 1
                    ? [editOneTime]
                    : followUpTimeValue == 1
                    ? [oneTime]
                    : followUpTimeValue == 2
                    ? weekList
                    : moreFollowUpTime,
                contentTime: undefined,
                followtype: undefined,
                times1: undefined,
                times2: undefined,
                times3: undefined,
                appointmentId: props.appointmentId,
                registrationId: props.registrationId,
              })
            )
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: "操作成功",
                });
                props?.onOk();
                refresh();
              })
              .catch(() => {
                props?.onCancel();
                refresh();
              });
          }
          props?.onValueChange(fs)
          if (fs?.contentUp) {
            const data = JSON.parse(
              sessionStorage.getItem('followUpContent') || '{}'
            )
            const storge: any = {
              followUpContent: [],
            }
            if (Object.keys(data).length) {
              if (data.followUpContent.includes(fs?.contentUp)) {
                storge.followUpContent = [...data.followUpContent]
              } else {
                storge.followUpContent = [
                  ...data.followUpContent,
                  fs?.contentUp,
                ]
              }
            } else {
              storge.followUpContent = [fs?.contentUp]
            }
            if (storge?.followUpContent?.length > 5) {
              storge?.followUpContent?.shift()
            }
            sessionStorage.setItem('followUpContent', JSON.stringify(storge))
          }
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label='患者姓名'
              name='name'
              rules={[
                {
                  required: true,
                  message: `请选择一个 ${
                    props.status == 1 ? '病历信息' : '就诊记录'
                  } ${edition == 1 ? '或处置记录' : ''}或治疗计划`,
                },
              ]}
              // getValueFromEvent={stringTrim}
            >
              <Input placeholder='请输入' disabled maxLength={10} />
            </Form.Item>
          </Col>
          {!props?.id && (
            <Col span={24}>
              <Form.Item
                label='随访状态'
                name='state'
                rules={[
                  {
                    required: true,
                    message: '请选择随访状态',
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e: any) => {
                    setState(e?.target.value)
                  }}
                >
                  <Radio value={0}>随访计划</Radio>
                  <Radio value={1}>随访记录</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              label='随访类型'
              name='flag'
              rules={[
                {
                  required: true,
                  message: '请选择随访类型',
                },
              ]}
            >
              <Select
                placeholder='随访类型'
                allowClear
                style={{ position: 'relative' }}
              >
                {followUpTypeList.map((v: any) => {
                  return (
                    <Option value={v?.name} key={v?.name}>
                      {v?.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <div style={{ position: 'absolute', top: '5px', right: '30px' }}>
              <SettingOutlined
                style={{ color: '#999999' }}
                onClick={() => {
                  setIsSettingFollowUpTypeListModalVisible(true)
                }}
              />
            </div>
          </Col>
          <Col span={24}>
            <Form.Item
              label='随访方式'
              name='way'
              rules={[
                {
                  required: true,
                  message: '请选择随访方式',
                },
              ]}
            >
              <Select placeholder='随访方式' allowClear>
                {FollowUpWayList.map((v: any) => {
                  return (
                    <Option value={v} key={v}>
                      {getFollowUpWayCategoryList(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label='随访内容'
              name='content'
              rules={[
                {
                  required: true,
                  message: '请输入随访内容',
                },
              ]}
              getValueFromEvent={stringTrim}
            >
              <TextArea placeholder='多行输入' maxLength={128} />
            </Form.Item>
          </Col>
          {/* <Select placeholder='随访内容' allowClear showSearch>
            {followUpLContentList?.map((v: any) => {
              return (
                <Option value={v} key={v}>
                  {v}
                </Option>
              )
            })}
          </Select> */}
          {(props?.id || state == 1) && (
            <Col span={24}>
              <Form.Item
                label='随访结果'
                name='result'
                rules={[
                  {
                    required: true,
                    message: '请输入随访结果',
                  },
                ]}
                getValueFromEvent={stringTrim}
              >
                <TextArea placeholder='多行输入' maxLength={128} />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              label='随访人员'
              name='userId'
              rules={[
                {
                  required: true,
                  message: '请选择随访人员',
                },
              ]}
            >
              <Select
                placeholder='随访人'
                allowClear
                onChange={(v) => {
                  const peopleData: any = followUpPeopleList?.filter(
                    (i: any) => {
                      return i?.id == v
                    }
                  )
                  setFolllowUpPeopleName(peopleData?.[0]?.realname)
                }}
              >
                {followUpPeopleList.map((v: any) => {
                  return (
                    <Option value={v?.id} key={v?.id}>
                      {v?.realname}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          {(props?.id || state == 1) && (
            <Col span={24}>
              <Form.Item
                label='随访时间'
                name='visitTimeList'
                rules={[
                  {
                    required: true,
                    message: '请选择随访时间',
                  },
                ]}
              >
                <DatePicker
                  onChange={(v) => {
                    setEditOneTime(moment(v).format(DateTimeFormatSimple))
                  }}
                />
              </Form.Item>
            </Col>
          )}
          {state == 0 && !props?.id && (
            <>
              <Col span={24}>
                <Form.Item
                  label='随访时间'
                  name='contentTime'
                  rules={[
                    {
                      required: true,
                      message: '请选择随访时间',
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setFollowUpTimeValue(e?.target?.value)
                    }}
                    value={followUpTimeValue}
                  >
                    <Space direction='vertical'>
                      <Row style={{ height: '56px' }}>
                        <Radio value={1} style={{ marginTop: '6px' }}>
                          单次随访
                        </Radio>
                        {followUpTimeValue == 1 && (
                          <Form.Item
                            name='times1'
                            rules={[
                              {
                                required: true,
                                message: '请选择随访时间',
                              },
                            ]}
                          >
                            <DatePicker
                              onChange={(v) => {
                                setOneTime(
                                  moment(v).format(DateTimeFormatSimple)
                                )
                              }}
                            />
                          </Form.Item>
                        )}
                      </Row>
                      <Row
                        style={{
                          minHeight: followUpTimeValue == 2 ? '112px' : '56px',
                        }}
                      >
                        <Radio value={2} style={{ marginTop: '6px' }}>
                          周期随访
                        </Radio>
                        {followUpTimeValue == 2 && (
                          <>
                            <div
                              style={{
                                color: '#333',
                                fontSize: '14px',
                                margin: '6px 6px 6px 6px',
                              }}
                            >
                              每
                            </div>

                            <Form.Item
                              label=''
                              name='followtype'
                              rules={[
                                {
                                  required: true,
                                  message: '请选择随访周期',
                                },
                              ]}
                            >
                              <Select
                                placeholder='随访周期'
                                allowClear
                                style={{ width: '85px' }}
                                onChange={(v) => {
                                  setFollowUpType(Number(v))
                                  setWeekTimeList([])
                                }}
                              >
                                {FollowUpTypeTimeList?.map((v: any) => {
                                  return (
                                    <Option value={v} key={v}>
                                      {getFollowUpTypeCategoryList(v)}
                                    </Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>

                            <div
                              style={{
                                color: '#333',
                                fontSize: '14px',
                                margin: '6px 6px 6px 6px',
                              }}
                            >
                              一次
                            </div>
                            <Row
                              style={{ marginLeft: '10px' }}
                              className={styles.time}
                            >
                              <Form.Item
                                label='随访时间段'
                                name='times2'
                                rules={[
                                  {
                                    required: true,
                                    message: '请选择随访时间段',
                                  },
                                ]}
                              >
                                <RangePicker
                                  onChange={(v) => {
                                    setFollowUpTimeValue(2)
                                    setWeekTimeList([])
                                    setStartTime(
                                      moment(v?.[0]).format(
                                        DateTimeFormatSimple
                                      )
                                    )
                                    setEndTime(
                                      moment(v?.[1])
                                        ?.add(1, 'days')
                                        .format(DateTimeFormatSimple)
                                    )
                                    getDiffDay(
                                      moment(v?.[0]).format(
                                        DateTimeFormatSimple
                                      ),
                                      moment(v?.[1])
                                        ?.add(1, 'days')
                                        .format(DateTimeFormatSimple)
                                    )
                                    getDiffDate(
                                      moment(v?.[0]).format(
                                        DateTimeFormatSimple
                                      ),
                                      moment(v?.[1])
                                        ?.add(1, 'days')
                                        .format(DateTimeFormatSimple)
                                    )
                                  }}
                                />
                              </Form.Item>
                            </Row>
                          </>
                        )}

                        {weekTimeList?.length != 0 && followUpTimeValue == 2 && (
                          <Col
                            span={24}
                            style={{
                              padding: '5px 5px 0 5px',
                              margin: '-10px 10px 10px 20px',
                            }}
                          >
                            {weekTimeList.map((d: any) => (
                              <div key={d} className={styles.timeColors}>
                                {d && d != undefined
                                  ? `${moment(d).format('MM-DD')},`
                                  : undefined}
                              </div>
                            ))}
                          </Col>
                        )}
                      </Row>
                      <Row
                        style={{
                          position: 'relative',
                          zIndex: 999,
                          cursor: 'pointer',
                        }}
                      >
                        <Radio value={3}>多次随访</Radio>
                        {followUpTimeValue == 3 && (
                          <>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: '请添加多次随访时间',
                                },
                              ]}
                              name='times3'
                            >
                              <DatePicker
                                disabledDate={(date) => {
                                  const days = moment(date).format(
                                    DateTimeFormatSimpleOnly
                                  )
                                  if (moreFollowUpTimeList?.length) {
                                    return moreFollowUpTimeList
                                      .map((v: any) => {
                                        return v
                                      })
                                      .some((v: any) => v == days)
                                  } else {
                                    return false
                                  }
                                }}
                                onChange={(v: any) => {
                                  if (
                                    !moreFollowUpTimeList?.find(
                                      (d: any) =>
                                        moment(d).format(
                                          DateTimeFormatSimpleOnly
                                        ) ==
                                        moment(v).format(
                                          DateTimeFormatSimpleOnly
                                        )
                                    )
                                  ) {
                                    setMoreFollowUpTimeList(
                                      moreFollowUpTimeList?.concat(
                                        moment(v).format(
                                          DateTimeFormatSimpleOnly
                                        )
                                      )
                                    )
                                  }
                                }}
                                style={{
                                  zIndex: 999,
                                  opacity: 0,
                                  cursor: 'pointer',
                                }}
                              />
                            </Form.Item>
                            <div
                              style={{
                                color: '#0479FF',
                                fontSize: '14px',
                                marginTop: '-4px',
                                position: 'absolute',
                                top: 5,
                                left: 116,
                                cursor: 'pointer',
                              }}
                            >
                              添加
                            </div>
                          </>
                        )}
                      </Row>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {moreFollowUpTimeList?.length != 0 && followUpTimeValue == 3 && (
                <Col
                  span={18}
                  style={{
                    padding: '5px 5px 0 5px',
                    margin: '-40px 10px 10px 94px',
                    // border:"1px  solid red"
                  }}
                >
                  {moreFollowUpTimeList.map((d: any) => (
                    <div key={d} className={styles.timeColor}>
                      {`${moment(d).format('MM-DD')}`}

                      <CloseOutlined
                        style={{ cursor: 'pointer', color: '#bbc3cc ' }}
                        onClick={() => {
                          setMoreFollowUpTimeList(
                            moreFollowUpTimeList.filter(
                              (i: any) =>
                                moment(i).format('MM-DD') !==
                                moment(d).format('MM-DD')
                            )
                          )
                        }}
                      />
                    </div>
                  ))}
                </Col>
              )}
            </>
          )}
        </Row>
        <SettingFollowUpTypeListModal
          isSettingFollowUpTypeListModalVisible={
            isSettingFollowUpTypeListModalVisible
          }
          onCancel={() => {
            setIsSettingFollowUpTypeListModalVisible(false)
          }}
          onOk={() => {
            setIsSettingFollowUpTypeListModalVisible(false)
          }}
          refresh={() => {
            dispatch(getFollowUpTypeList())
              .then(unwrapResult)
              .then((res) => {
                setFollowUpTypeList(res)
              })
          }}
        ></SettingFollowUpTypeListModal>
      </Form>
      <Row
        style={{
          width: '100%',
        }}
      >
        <Row style={{ width: '100%', justifyContent: 'end' }}>
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => {
              props?.onCancel()
              commonForm?.resetFields()
              setMoreFollowUpTimeList([])
              setWeekTimeList([])
              setFollowUpTimeValue(1)
            }}
          >
            {props?.id ? '返回' : '取消'}
          </Button>
          <Button
            loading={loading}
            type='primary'
            onClick={() => {
              commonForm.submit()
            }}
            // htmlType='submit'
          >
            确认
          </Button>
        </Row>
      </Row>
    </>
  )
}
