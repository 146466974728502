/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-19 15:06:40
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-19 16:51:36
 */

import { Button, TableColumnType } from 'antd'
import moment from 'moment'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
import React from 'react'

export const Columns = (
  startIndex: number,
  onAction: (action: string, t: any) => void
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: '4rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '国家编码',
      align: 'center',
      dataIndex: 'hilistCode',
    },
    {
      title: '医保项目名称',
      align: 'center',
      dataIndex: 'hilistName',
    },
    {
      title: '本地项目名称',
      align: 'center',
      dataIndex: 'localItemName',
    },
    {
      title: '过期时间',
      align: 'center',
      dataIndex: 'endDate',
      render: (_, r) =>
        r.endDate ? moment(r?.endDate).format(DateTimeFormat) : '-',
    },
    {
      title: '有效标志',
      align: 'center',
      dataIndex: 'valiFlag',
      render: (_, r) =>
        r.valiFlag == 0 ? '失效' : r.valiFlag == 1 ? '有效' : '-',
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '8rem',
      render: function Element(_, t) {
        return (
          <Button
            type='link'
            onClick={() => {
              onAction('resetMatching', t)
            }}
          >
            重新匹配
          </Button>
        )
      },
    },
  ]
}
