/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-20 10:34:15
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-10 10:12:40
 */
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  Modal,
  Input,
  notification,
  DatePicker,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import {
  costFlagOptions,
  getEchartTitle,
  getLineColor,
  getTips,
  insuranceItem,
  timeFlagOptions,
  timeFlagOptionsZDY, yidibzItem,
} from '../../../../models/echart'
import {
  getDepartments,
  selectDepartments,
} from '../../../payment/list/paymentSlice'
import styles from '../insuranceContorl.module.css'
import * as echarts from 'echarts'
import {
  averageTipAsync,
  averageTipByTenantIdAsync,
  fundQuotaAsync,
  medicalListAsync,
  patientNumAsync,
  patientNumRateAsync,
  patientRateAsync,
  patientTimesRateAsync,
  selectSettingVisibile,
  setSettingVisibile,
  settingDetailAsync,
  settingSaveAsync,
  sweepRateAsync,
} from '../InsuranceContorlSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { averageTipParams } from '../../../../services/echarts'
import { stringTrim } from '../../../../utils/StringUtils'
import { Checks } from '../../../../models/setting'
import moment from 'moment'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
} from '../../../../models/datetime'
import { selectEdition } from '../../../../app/applicationSlice'

interface StackedProps {
  queryType: number
}

export const Stacked = ({ queryType }: StackedProps) => {
  const [form] = Form.useForm()

  const [settingForm] = Form.useForm()

  const { RangePicker } = DatePicker

  const { Option } = Select

  const ref = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch<RootDispatch>()

  const isSettingVisible = useSelector(selectSettingVisibile)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const departments = useSelector(selectDepartments)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [params, setParams] = useState<averageTipParams>({
    timeFlag: queryType === 8 || queryType === 9 ? '1' : '4',
    costFlag: queryType === 8 ? '1' : undefined,
  })

  const [pageData, setPageData] = useState<any>() // 医生数据

  const [stackData, setStackData] = useState<any>() // 折线图和柱状图数据

  const [flag, setFlag] = useState(true)

  const [nextSendDay, setNextSendDay] = useState('')

  const invoiceLayout = {
    labelCol: {
      span: 10,
    },
  }

  useEffect(() => {
    if (!isSettingVisible) return
    dispatch(settingDetailAsync())
      .then(unwrapResult)
      .then((res: any) => {
        const data: any = {}
        res.forEach((v: any) => {
          data[v.type] = v.value
        })
        setNextSendDay(
          res?.find((v: any) => v?.type == 27)?.updateTime?.split(' ')[0]
        )
        settingForm.setFieldsValue({
          ...data,
        })
      })
  }, [isSettingVisible])

  const rules = [
    {
      pattern: new RegExp(/^([0-9]+[\d]*(.[0-9]{1,2})?)$/, 'g'),
      message: '请输入保留小数两位以内的数',
    },
  ]

  const phoneRules = [
    {
      pattern: /^1\d{10}$/,
      message: '请输入正确的手机号',
    },
  ]

  useEffect(() => {
    queryType === 1 && dispatch(getDepartments())
  }, [])

  const getFlag = (list: any[]) => {
    setIsModalVisible(
      list.some(
        (v) =>
          v.averageTip == 0 ||
          v.field0 == 0 ||
          v.patientPercentage == 0 ||
          v.fundQuota == 0 ||
          v.medicalList == 0
      )
    )
  }

  const getSMLData = () => {
    dispatch(sweepRateAsync(params))
      .then(unwrapResult)
      .then((res: any) => {
        const data = {
          dataList: res?.sweepRateData,
          dataName: {
            percentage: '扫码率',
          },
          electronicVoucherRatio: res?.electronicVoucherRatio,
          electronicVoucherTotal: res?.electronicVoucherTotal,
          physicalCardRatio: res?.physicalCardRatio,
          physicalCardTotal: res?.physicalCardTotal,
        }
        setPageData(data)
      })
  }

  useEffect(() => {
    if (queryType === 1) {
      //医生中草药帖均
      dispatch(averageTipAsync(params))
        .then(unwrapResult)
        .then((res: any) => {
          setPageData(res)
        })
    } else if (queryType === 2) {
      // 机构中草药帖均
      dispatch(averageTipByTenantIdAsync(params))
        .then(unwrapResult)
        .then((res: any) => {
          const data = {
            dataList: res,
            dataName:
              params.timeFlag !== '4' && params.timeFlag !== '5'
                ? {
                    realAverageTip: '每日帖均费',
                    averageTip: '预警线',
                  }
                : {
                    realAverageTip: '每月帖均费',
                    averageTip: '预警线',
                    dynameicAverageTip: '本机构年度帖均费',
                  },
          }
          setPageData(data)
        })
    } else if (queryType === 3) {
      // 扫码率
      if (params?.timeFlag == '6' && params?.startTime && params?.endTime) {
        getSMLData()
      } else if (params?.timeFlag != '6') {
        getSMLData()
      }
    } else if (queryType === 4) {
      // 次均费用
      dispatch(patientTimesRateAsync(params))
        .then(unwrapResult)
        .then((res: any) => {
          const data = {
            dataList: res,
            dataName: {
              realAverageTip: '实际次均费用',
              averageTip: '次均费用额度',
              dynameicAverageTip: '动态次均费用额度',
            },
          }
          setPageData(data)
        })
    } else if (queryType === 5) {
      // 人均费用
      dispatch(patientNumRateAsync(params))
        .then(unwrapResult)
        .then((res: any) => {
          const data = {
            dataList: res,
            dataName: {
              realAverageTip: '实际人均费用',
              averageTip: '人均费用额度',
              dynameicAverageTip: '动态人均费用额度',
            },
          }
          setPageData(data)
        })
    } else if (queryType === 6) {
      // 人次人头比
      dispatch(patientRateAsync(params))
        .then(unwrapResult)
        .then((res: any) => {
          const data = {
            dataList: res,
            dataName: {
              patientNum: '人头',
              patientTimes: '人次',
              percentage: '人次人头比',
              patientPercentage: '预警线',
              dynameicPercentage: '动态人次人头比',
            },
          }
          setStackData(data)
        })
    } else if (queryType === 7) {
      // 就诊人数
      dispatch(patientNumAsync(params))
        .then(unwrapResult)
        .then((res: any) => {
          const data = {
            dataList: res,
            dataName: {
              patientNum: '实际就诊人数',
            },
          }
          setPageData(data)
        })
    } else if (queryType === 8) {
      // 医保基金支付限额
      dispatch(fundQuotaAsync(params))
        .then(unwrapResult)
        .then((res: any) => {
          const data = {
            dataList: res,
            dataName: {
              fundQuota: '预警线',
              realFundQuota: '医保基金实际支付',
              dynameicFundQuota: '动态医保基金实际支付',
            },
          }
          setPageData(data)
        })
    } else if (queryType === 9) {
      // 医保列支医疗费支付限额
      dispatch(medicalListAsync(params))
        .then(unwrapResult)
        .then((res: any) => {
          const data = {
            dataList: res,
            dataName: {
              medicalList: '预警线',
              realMedicalList: '医保列支医疗费实际支付',
              dynameicMedicalList: '动态医保列支医疗费实际支付',
            },
          }
          setPageData(data)
        })
    }
  }, [params, queryType])

  useEffect(() => {
    if (!ref.current || !stackData) return
    if (flag && stackData?.dataList?.length) {
      getFlag(stackData?.dataList)
      setFlag(false)
    }
    const legendData = Object.values(stackData?.dataName) || []
    const xAxisData = stackData?.dataList?.map((v: any) => v.paymentTime) || []
    const series: any = {}
    Object.keys(stackData?.dataName).forEach((item: any) => {
      series[item] = []
      stackData?.dataList?.forEach((v: any) => {
        series[item].push(Number(v[item]))
      })
    })
    const seriesData = Object.keys(stackData?.dataName).map((v) => {
      return {
        name: stackData?.dataName[v],
        type: v == 'patientNum' || v == 'patientTimes' ? 'bar' : 'line',
        yAxisIndex: v == 'patientNum' || v == 'patientTimes' ? 0 : 1,
        data: series[v],
        smooth: v == 'dynameicPercentage',
        color: getLineColor(v),
      }
    })
    const myChart = echarts.init(ref?.current)
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
      },
      grid: {
        right: '20%',
      },
      legend: {
        data: legendData,
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true,
          },
          // prettier-ignore
          data: xAxisData,
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '个数',
          position: 'left',
          alignTicks: true,
          axisLine: {
            show: true,
          },
        },
        {
          type: 'value',
          name: '人次人头比',
          position: 'right',
          alignTicks: true,
          axisLine: {
            show: true,
          },
          axisLabel: {
            formatter: '{value} %',
          },
        },
      ],
      series: seriesData,
    }

    option && myChart.setOption(option)
  }, [stackData])

  useEffect(() => {
    if (!ref.current || !pageData) return
    if (flag && pageData?.dataList.length) {
      getFlag(pageData?.dataList)
      setFlag(false)
    }
    const legendData = Object.values(pageData?.dataName) || []
    const xAxisData = pageData?.dataList?.map((v: any) => v.paymentTime) || []
    const series: any = {}
    Object.keys(pageData?.dataName).forEach((item: any) => {
      series[item] = []
      pageData?.dataList?.forEach((v: any) => {
        series[item].push(Number(v[item]))
      })
    })
    const seriesData = Object.keys(pageData?.dataName).map((v) => {
      return {
        name: pageData?.dataName[v],
        type: 'line',
        data: series[v],
        smooth: [
          'dynameicAverageTip',
          'dynameicFundQuota',
          'dynameicMedicalList',
        ].includes(v),
        color: getLineColor(v),
      }
    })
    const myChart = echarts.init(ref?.current)
    const option = {
      title: {
        text: getEchartTitle(queryType),
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: legendData,
      },
      grid: {
        // width: '100%',
        left: '3%',
        right: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisData,
        axisLabel: {
          showMaxLabel: true,
        },
      },
      yAxis: {
        type: 'value',
      },
      series: seriesData,
    }
    option && myChart.setOption(option, true)
  }, [pageData, params?.timeFlag, queryType])

  useEffect(() => {
    if (params?.timeFlag != '6') {
      form.setFieldsValue({
        time: [],
      })
    }
  }, [params?.timeFlag])

  return (
    <div className={styles.stacked}>
      <Form
        form={form}
        initialValues={{
          timeFlag: queryType === 8 || queryType === 9 ? '1' : '4',
          costFlag: '1',
        }}
        onFinish={(values: any) => {
          const startTime = values?.time
            ? moment(values?.time?.[0])?.format(DateTimeFormatSimple)
            : ''
          const endTime = values?.time
            ? moment(values?.time?.[1])?.format(DateTimeFormatSimple)
            : ''
          if (values?.timeFlag == 6 && startTime && endTime) {
            setParams({
              ...values,
              startTime: startTime,
              endTime: endTime,
              time: null,
            })
          } else {
            setParams({ ...values, time: null })
          }
        }}
      >
        <Row gutter={11}>
          {/* <Col span={4}>
            <Form.Item name='prodentpNameKeyWorld'>
              <Input placeholder='搜索商品厂家关键字' allowClear onInput={form.submit} />
            </Form.Item>
          </Col> */}
          {queryType === 1 && (
            <Col span={4}>
              <Form.Item name='treatmentDepartmentId'>
                <Select
                  placeholder='查看范围-科室列表'
                  allowClear
                  onChange={() => form.submit()}
                >
                  {departments.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {(queryType == 1 || queryType == 2) && (
            <Col span={4}>
              <Form.Item name='yidibz'>
                <Select
                  placeholder='请选择险种类型'
                  allowClear
                  onChange={(v) => form.submit()}
                >
                  {yidibzItem.map((v: any) => {
                    return (
                      <Option value={v.value} key={v.value}>
                        {v.label}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}
          {queryType !== 3 && queryType !== 9 && (
            <Col span={4}>
              <Form.Item name='cbxz'>
                <Select
                  placeholder='请选择医保险种'
                  allowClear
                  onChange={(v) => form.submit()}
                >
                  {insuranceItem.map((v: any) => {
                    return (
                      <Option value={v.value} key={v.value}>
                        {v.label}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}
          {queryType === 8 && (
            <Col span={4}>
              <Form.Item name='costFlag'>
                <Select
                  placeholder='请选择费用标志'
                  onChange={() => form.submit()}
                >
                  {costFlagOptions.map((v: any) => {
                    return (
                      <Option value={v.value} key={v.value}>
                        {v.label}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={4}>
            <Form.Item name='timeFlag'>
              <Select
                placeholder='请选择时间'
                onChange={() => {
                  form.submit()
                }}
              >
                {queryType === 8 || queryType === 9 ? (
                  <>
                    <Option value={'1'}>本年</Option>
                    <Option value={'2'}>全部</Option>
                  </>
                ) : queryType == 3 ? (
                  timeFlagOptionsZDY.map((v: any) => {
                    return (
                      <Option value={v.value} key={v.value}>
                        {v.label}
                      </Option>
                    )
                  })
                ) : (
                  timeFlagOptions.map((v: any) => {
                    return (
                      <Option value={v.value} key={v.value}>
                        {v.label}
                      </Option>
                    )
                  })
                )}
              </Select>
            </Form.Item>
          </Col>
          {params?.timeFlag == '6' && queryType == 3 && (
            <>
              <Col span={5}>
                <Form.Item name='time'>
                  <RangePicker
                    onChange={(v) => {
                      form.submit()
                    }}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col>
            <Button htmlType='submit' type='primary' style={{ float: 'right' }}>
              查询
            </Button>
          </Col>
          {queryType == 3 && (
            <Col flex={1} style={{ marginTop: '5px', fontSize: '16px' }}>
              实体卡：{Number(pageData?.physicalCardRatio * 100)?.toFixed(2)}
              {'% ，'}
              {pageData?.physicalCardTotal} {'条 ；'}
              电子凭证：
              {Number(pageData?.electronicVoucherRatio * 100)?.toFixed(2)}
              {'% , '}
              {pageData?.electronicVoucherTotal} {'条'}
            </Col>
          )}

          {getTips(queryType) && (
            <div
              style={{ color: '#999', marginLeft: '15px', marginTop: '6px' }}
            >
              <span style={{ color: '#F00', marginRight: '3px' }}>*</span>
              {getTips(queryType)}{' '}
            </div>
          )}
        </Row>
        {params?.timeFlag == '6' && queryType == 3 && (
          <Row gutter={11} style={{ margin: '4px 0 0 0px' }}>
            <span style={{ marginLeft: '6px' }}>
              备注：自定义时不展示折线图
            </span>
          </Row>
        )}
      </Form>
      <div className={styles.echarts}>
        <div ref={ref} className={styles.echarts}></div>
        {/*<Modal*/}
        {/*  okText='立即设置'*/}
        {/*  cancelText='暂不设置'*/}
        {/*  title='友情提示'*/}
        {/*  visible={isModalVisible}*/}
        {/*  onOk={() => {*/}
        {/*    setIsModalVisible(false)*/}
        {/*    dispatch(setSettingVisibile(true))*/}
        {/*  }}*/}
        {/*  onCancel={() => setIsModalVisible(false)}*/}
        {/*>*/}
        {/*  <p>设置预警线后，图表将更加直观</p>*/}
        {/*</Modal>*/}
        {/* 设置预警线 */}
        <Modal
          title='设置预警线'
          width={900}
          visible={isSettingVisible}
          onOk={() => settingForm.submit()}
          onCancel={() => dispatch(setSettingVisibile(false))}
        >
          <Form
            form={settingForm}
            onFinish={(values) => {
              if (
                (values[Checks.SJHM] && !values[Checks.TSPL]) ||
                (!values[Checks.SJHM] && values[Checks.TSPL])
              ) {
                notification.error({
                  message: '请完善手机号码或推送频率',
                })
                return
              }
              const params = Object.keys(values).map((v: any) => {
                return {
                  type: v,
                  value: values[v],
                }
              })
              dispatch(settingSaveAsync(params))
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: '设置成功，请刷新页面',
                  })
                  dispatch(setSettingVisibile(false))
                })
            }}
          >
            <Row style={{ marginLeft: '40px' }}>
              <Col style={{ marginLeft: '20px' }} span={18}>
                {hisVersion == 1 ? (
                  ' '
                ) : (
                  <Form.Item
                    {...invoiceLayout}
                    label='中草药年度帖均额度'
                    rules={rules}
                    getValueFromEvent={stringTrim}
                    name={Checks.ZCYNDJTED}
                  >
                    <Input addonAfter='元' />
                  </Form.Item>
                )}

                <Form.Item
                  {...invoiceLayout}
                  label='医保基金年度支付限额'
                  rules={rules}
                  getValueFromEvent={stringTrim}
                  name={Checks.YBJJNDZFXE}
                >
                  <Input addonAfter='元' />
                </Form.Item>
                <Form.Item
                  {...invoiceLayout}
                  label='医保列支医疗费年度支付限额'
                  rules={rules}
                  getValueFromEvent={stringTrim}
                  name={Checks.YBLZYLFNDZFXE}
                >
                  <Input addonAfter='元' />
                </Form.Item>
                <Form.Item
                  {...invoiceLayout}
                  label='人次人头比'
                  rules={rules}
                  getValueFromEvent={stringTrim}
                  name={Checks.RCRTB}
                >
                  <Input addonAfter='%' />
                </Form.Item>
                <Form.Item
                  {...invoiceLayout}
                  label='次均年度费用限额'
                  rules={rules}
                  getValueFromEvent={stringTrim}
                  name={Checks.CJNDFYXE}
                >
                  <Input addonAfter='元' />
                </Form.Item>
                <Form.Item
                  {...invoiceLayout}
                  label='人均年度费用限额'
                  rules={rules}
                  getValueFromEvent={stringTrim}
                  name={Checks.RJNDFYXE}
                >
                  <Input addonAfter='元' />
                </Form.Item>
                <Form.Item
                  {...invoiceLayout}
                  label='手机号码'
                  rules={phoneRules}
                  getValueFromEvent={stringTrim}
                  name={Checks.SJHM}
                >
                  <Input maxLength={11} />
                </Form.Item>
                <Form.Item
                  name={Checks.TSPL}
                  label='推送频率'
                  {...invoiceLayout}
                >
                  <Form.Item
                    rules={[
                      {
                        pattern: /^\d+$/,
                        message: '请输入正确数字',
                      },
                    ]}
                    getValueFromEvent={stringTrim}
                    name={Checks.TSPL}
                  >
                    <Input
                      addonAfter='天/次'
                      onChange={(e) => {
                        const day = moment(new Date())
                          .add(e.target.value, 'days')
                          .format('YYYY/MM/DD')
                        setNextSendDay(day)
                        // setNextSendDay(day)
                      }}
                    />
                  </Form.Item>
                  <span>下次推送时间：{nextSendDay}</span>
                </Form.Item>
                {/* <Form.Item {...invoiceLayout} label='推送频率' rules={[{
                  pattern: /^\d+$/,
                  message: "请输入正确数字",
                }]} getValueFromEvent={stringTrim} name={Checks.TSPL}>
                  <Input addonAfter="天/次" />
                </Form.Item> */}
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </div>
  )
}
