/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-08-08 11:48:35
 */
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditableList } from "../../../compnents/list/EditableList";
import { NavBar } from "../../../compnents/nav/NavBar";
import { SupplierDealingParams } from "../../../services/supplierDealing";
import { SupplierDealingColumns } from "./columns";
import { SupplierDealingQuery } from "./query";
import {
  getSupplierDealingList,
  selectCurrent,
  selectPageLoading,
  selectSupplierDealingData,
  selectTotal,
  setCurrent,
  setPageLoading,
  getExportListExoprt
} from "./supplierDealingSlice";
import styles from "./supperDealing.module.css";
import { Col, notification } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../../app/store";
export const SupplierDealing = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();
  const [queries, setQueries] = useState<SupplierDealingParams>();

  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectSupplierDealingData);

  const [size, setSize] = useState(10);

  const pageLoadingRX = useSelector(selectPageLoading);

  const onExport = () => {
    dispatch(getExportListExoprt({ current: Current, size: size }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '药品往来.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  useEffect(() => {
    if (queries?.timeHead) {
      dispatch(setPageLoading(true))
      dispatch(getSupplierDealingList({ ...queries, current: Current, size }));
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }

  }, [queries, size, Current]);
  return (
    <>
      <NavBar
        style={{ margin: "10px 20px 0px 20px" }}
        where={["供应商管理", "药品往来"]}
        backtrace={{
          name: "库房工作站",
          path: "/storage/station",
          state: {
            station: "8",
          },
        }}
      />
      <div className={styles.content}>
        <SupplierDealingQuery
          onValueChange={(v) => {
            setQueries({ ...queries, ...v });
            dispatch(setCurrent(1));
          }}
          onExport={() => { onExport() }}
        />
        <EditableList
          className={styles.supper}
          loading={pageLoadingRX}
          rowKey={(t: any) => t.id}
          bordered
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number);
            dispatch(setCurrent(current));
          }}
          columns={SupplierDealingColumns(size * (Current - 1))}
        />
      </div>
    </>
  );
};
