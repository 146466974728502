import { API_PREFIX } from "./constant"
import { Request } from "./request"

export function getFileUploadToken(bucketName: string | undefined): Request {
  return {
    url: `${API_PREFIX}/blade-user/oss/endpoint/upload-token`,
    method: "GET",
    params: { bucketName },
  }
}
