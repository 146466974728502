/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: sj
 * @LastEditTime: 2022-01-26 13:36:09
 */
/// API prefix for normal users.
export const API_PREFIX = '/api'

/// API prefix for administrators.
export const ADMIN_API_PREFIX = `${API_PREFIX}/blade-administer`
