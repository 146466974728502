/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-07 14:00:33
 * @LastEditors: sj
 * @LastEditTime: 2022-09-22 15:24:55
 */
import {
  CheckCircleFilled,
  InfoCircleFilled,
  WarningFilled,
} from '@ant-design/icons'
import { Dropdown, Menu, Row } from 'antd'
import React, { ReactElement, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectSettings, selectTenant } from '../../user/setting/settingSlice'
import { selectRemindDetail } from './diagnosisSlice'

export const RemindTips = (props: { tag?: any }): ReactElement => {
  const remindDetail = useSelector(selectRemindDetail)

  const isShow: any = useSelector(selectSettings)[29] //判断是否开通智能审方

  const overlay = (
    <Menu>
      {remindDetail?.map((v: any, i: number) => (
        <Menu.Item key={i}>
          {v.reminderName} {v.reminderDesc}
        </Menu.Item>
      ))}
    </Menu>
  )

  return isShow &&  props?.tag != 'retail' ? (
    <Row
      align='middle'
      style={{
        padding: '5px 10px',
        backgroundColor: '#F5F8FB',
        marginRight: 10,
        borderRadius: 5,
      }}
    >
      智能审核&nbsp;{' '}
      <InfoCircleFilled
        style={{
          color: '#e1e1e1',
        }}
      />{' '}
      &emsp;
      {remindDetail && remindDetail?.length ? (
        <Dropdown
          overlay={overlay}
          getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
          trigger={['click']}
          placement='topCenter'
        >
          <div
            style={{
              color: '#F98E35',
              cursor: 'pointer',
            }}
          >
            <WarningFilled /> 提醒
          </div>
        </Dropdown>
      ) : (
        <div
          style={{
            color: '#08a011',
          }}
        >
          <CheckCircleFilled /> 通过
        </div>
      )}
    </Row>
  ) : (
    <span></span>
  )
}
