/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-17 11:16:48
 * @LastEditors: sj
 * @LastEditTime: 2022-12-07 15:04:07
 */
import { unwrapResult } from '@reduxjs/toolkit';
import { Col, Modal, Row, Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../../../../app/store';
import { EditableList } from '../../../../../compnents/list/EditableList';
import { getOutsideStatus } from '../../../../../models/toothOutside';
import PatientLabel from '../../components/patientLabel';
import { externalprocessDetailAsync, getRecipeItemsByPatientIdAsync } from '../../patientSlice';
import { DetailModalColumns, RelevanceDisposeColumns } from './columns';

interface DetailModalProps {
  id: string
  visible: boolean
  onCancel: () => void
  patientId: string
}

export const DetailModal = ({
  id,
  visible,
  onCancel,
  patientId
}: DetailModalProps): ReactElement => {

  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<any>({})

  const [disposeList, setDisPoseList] = useState<any[]>([])

  useEffect(() => {
    if (visible && id) {
      dispatch(externalprocessDetailAsync(id)).then(unwrapResult).then((res: any) => {
        setData(res)
        // dispatch(getRecipeItemsByPatientIdAsync(patientId)).then(unwrapResult).then((list: any) => {
        //   const ids = res.externalProcessRecipeList.map((v: any) => Number(v.recipeItemId))
        //   setDisPoseList(list.filter((v: any) => ids.includes(Number(v.recipeItemId))))
        // })
      })

    }
  }, [visible, id])

  return (
    <Modal
      visible={visible}
      title='外加工详情'
      onCancel={onCancel}
      width={1200}
      bodyStyle={{
        padding: 10,
        maxHeight: 600,
        overflow: 'auto'
      }}
      footer={null}
    >
      <div>
        <PatientLabel label='外加工明细' border={false} padding={10} />
        <EditableList
          rowKey={(v, i) => `${i}`}
          pagination={false}
          columns={DetailModalColumns()}
          dataSource={data.externalProcessItemList}
          bordered
        />
        <PatientLabel label='关联处置' border={false} padding={10} />
        <EditableList
          bordered
          rowKey={(v, i) => `${i}`}
          pagination={false}
          columns={RelevanceDisposeColumns(2)}
          dataSource={data.externalProcessRecipeList}
        />
        <PatientLabel label='其他信息' border={false} padding={10} />
        <Row gutter={[10, 10]} style={{
          padding: 10
        }}>
          <Col span={8}>加工单位：{data.processProvider}</Col>
          <Col span={8}>取件人：{data.addresseeName}</Col>
          <Col span={8}>取件人电话：{data.addresseePhone}</Col>
          <Col span={8}>加工天数：{data.processCycle}</Col>
          <Col span={8}>加工单号：{data.orderNo}</Col>
          <Col span={8}>加工颗数：{data.processNumber}</Col>
          <Col span={8}>医生：{data.doctorName}</Col>
          <Col span={8}>送件日期：{data.processStartTime && moment(data.processStartTime).format('YYYY-MM-DD')}</Col>
          <Col span={8}>回件日期：{data.processEndTime && moment(data.processEndTime).format('YYYY-MM-DD')}</Col>
          <Col span={8}>状态：{getOutsideStatus(data.status)}</Col>
          <Col span={8}>加工厂价：{data.factoryPrice > 0 ? data.factoryPrice : ''}</Col>
          <Col span={8}>对患者价：{data.patientPrice > 0 ? data.patientPrice : ''}</Col>
          <Col span={8}>快递公司：{data.logisticsOmpany}</Col>
          <Col span={8}>快递单号：{data.trackingNo}</Col>
          <Col span={8}>备注：{data.remark}</Col>
        </Row>
      </div>
    </Modal>
  );
}