import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface CompletedTreatmentParams {
  current?: number;
  size?: number;
  state?: number;
  startTime?: any;
  endTime?: any;
}

export function CompletedTreatmentList(
  params: CompletedTreatmentParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalstation/page`,
    method: "GET",
    params,
  };
}

export interface ExcuteDetailListParams {
  current?: number;
  size?: number;
}

export function ExcuteDetailList(params: ExcuteDetailListParams): Request {
  return {
    url: `${API_PREFIX}/`,
    method: "GET",
    params,
  };
}
