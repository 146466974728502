/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 14:08:53
 */
import {Col, message, Modal, notification} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import styles from './official.module.css'
import Qrcode from 'qrcode.react'
import { unwrapResult } from '@reduxjs/toolkit'
import {getGenerateAccessUrl, getWechatConfig} from './officialSlice'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { setCurePlanListVisible } from '../../treatment/editor/recipeEditorSlice'
import {useInterval} from "../../../utils/timehooks";
import {queryScanStatus} from "../../login/loglinSlice";
import {traceRoute} from "../../../layouts/layoutSlice";
import {success} from "../../../utils/MedicalUtils";

interface accreditQrCodeModalProps {
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const AccreditQrCodeModal = ({
  visible,
  onCancel,
  onOk,
}: accreditQrCodeModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [url, setUrl] = useState<any>('')

  useEffect(() => {
    if (visible)
      dispatch(getGenerateAccessUrl())
        .then(unwrapResult)
        .then((v) => {
          setUrl(v)
        })
  }, [visible])

  useInterval(
    async () => {
      dispatch(getWechatConfig())
          .then(unwrapResult)
          .then((v) => {
              if(v&&v.appid){
                  message.success("授权成功，正在跳转...",3);
                  window.location.reload();
              }
          })
    },
    visible ? 2000 : null
  )

  return (
    <Modal
      title='公众号账号授权'
      width={400}
      visible={visible}
      onCancel={() => {
        onCancel()
      }}
      onOk={() => {
        onOk()
      }}
      footer={null}
      destroyOnClose
      centered
      wrapClassName={styles.qrMessageModal}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      {url ? (
        <div className={styles.QrCodeMain}>
          <Qrcode
            value={url}
            size={220}
            style={{
              width: 220,
              height: 220,
            }}
          />
        </div>
      ) : (
        ''
      )}

      <Col className={styles.QrCodeTitle}>
        使用公众号绑定的管理员个人微信号扫码
      </Col>
    </Modal>
  )
}
