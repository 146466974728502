import { Button, Col, Modal, ModalProps, Row } from "antd"
import React, { ReactElement } from "react"

interface DiagnosisModalProps {
  visible: boolean
}

export const DiagnosisModal = ({
  visible,
  onOk,
  onCancel,
  ...others
}: DiagnosisModalProps & ModalProps): ReactElement => {
  return (
    <Modal
      width={450}
      visible={visible}
      footer={null}
      {...others}
      centered
      onCancel={onCancel}
      onOk={onOk}
    >
      <h3 style={{ textAlign: "center" }}>免责声明</h3>
      <div>
        鉴于本系统依托于腾讯医学AI智能工具，无法确定您输入的条件是否合法，所以本系统对检索/分析出的结果不承担任何责任。检索/分析的结果仅供您参考，请不要作为你诊断的唯一依据，具体方案请您以具备资质的医护人员独立判断为准。如果因以本系统的检索/分析结果作为任何商业行为或者学术研究的依据而产生不良后果，本系统不承担任何法律责任。
      </div>
      <Row gutter={20} style={{ marginTop: "10px" }}>
        <Col span={12}>
          <Button
            onClick={onCancel}
            danger
            style={{
              float: "right",
            }}
          >
            拒绝
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type='primary'
            onClick={onOk}
            style={{
              float: "left",
            }}
          >
            同意
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}
