import { CommonCodeCategory } from "../models/commoncode";
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export function getCommonCodes(
  category: CommonCodeCategory,
  keyword: string
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/commoncode/list`,
    method: "GET",
    params: {
      codeCategory: category,
      mnemonicCode: keyword,
      size: 100,
    },
  };
}
