/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-12 10:42:52
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-06 19:46:21
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import * as T from '../../services/transactionQuery'

interface TransactionQuerying {
  exportTag: []
}

const initialState: TransactionQuerying = {
  exportTag: [],
}

const transactionQuerySlice = createSlice({
  name: 'transactionQuerySlice',
  initialState,
  reducers: {
    setExportTag(state, action) {
      state.exportTag = action.payload
    },
  },
})

export const { setExportTag } = transactionQuerySlice.actions

export const selectExportTag = (state: RootState) =>
  state.transactionQuerys.exportTag

export const getTransactionQueryList = createAsyncThunk<
  void,
  T.TransactionQueryListParams,
  RootThunkApi
>('payment/getTransactionQueryList', async (params, api) => {
  return sendAsync(T.transactionQueryList(params), api)
})

export const getTransactionDetailQueryList = createAsyncThunk<
  void,
  T.TransactionQueryDetailListParams,
  RootThunkApi
>('payment/getTransactionDetailQueryList', async (params, api) => {
  return sendAsync(T.transactionDetailQueryList(params), api)
})

//导出
export const getTransactionDetailQueryExport = createAsyncThunk<
  void,
  T.TransactionQueryDetailListParams,
  RootThunkApi
>('doctorAudit/getTransactionDetailQueryExport ', async (params, api) => {
  return sendAsync(T.transactionDetailQueryExport(params), api)
})


//导出
export const getTransactionQueryExport = createAsyncThunk<
  void,
  T.TransactionQueryListParams,
  RootThunkApi
>('doctorAudit/getTransactionQueryExport ', async (params, api) => {
  return sendAsync(T.transactionQueryExport(params), api)
})
export default transactionQuerySlice.reducer
