/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 11:16:34
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-19 09:42:29
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Checkbox, Col, Form, Modal, Row } from 'antd'
import React, { forwardRef, ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import styles from '../appointment.module.css'
// import { cancelInvoiceAsync, getInvoicingStatusAsync, invoicePageAsync, redeliveryAsync } from '..//confirm/paymentConfirmSlice'
import { Columns } from './columns'
import { Query } from './Query'
import { traceRoute } from '../../../layouts/layoutSlice'
import {
  appointmentListAsync,
  cancelAppointmentAsync,
  setBtnLoading,
  sureAppointmentAsync,
} from '../appointmentSelice'
import { useHistory, useLocation } from 'react-router-dom'
import { setAppointmentIdSlice } from '../../registration/register/registerSlice'
import { DateRangeType, getDateRange } from '../../../compnents/form/DateSelect'
import { AppointmentDetail } from './detail'
import { AddEditAppointment } from '../addEditAppointment'
import { AppointmentRegister } from '../../registration/register/appointmentRegister'
import { selectEdition } from '../../../app/applicationSlice'
import { RegisteredModal } from '../../A-toothModule/toothHome/modal/registeredModal'
import moment from 'moment'
interface AppointmentListProps {
  status?: number
  type?: number //口腔 his版 预约
  onLeftCount?: (obj: any) => void //口腔 his版 预约
  patientId?: string // 患者管理进入
  // onlyThisAccount?: any
  activeKey?:any
}

const AppointmentList = ({
  status,
  type,
  onLeftCount,
  patientId,
  // onlyThisAccount,
  activeKey,
}: AppointmentListProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const history = useHistory()

  const location = useLocation()

  const [form] = Form.useForm()

  const [params, setParams] = useState<any>({
    current: 0,
    size: 10,
    state: status,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const [reasonModal, setReasonModal] = useState(false)

  const [appointmentDetailModal, setAppointmentDetailModal] = useState(false) //预约详情弹窗

  const [visibleTag, setVisibleTag] = useState('false')

  const [failReason, setFailReason] = useState('')

  const [head, tail] = getDateRange(DateRangeType.Today)

  const [detailId, setDetailId] = useState('') //   预约详情 ID

  const [toothEditAppointmentMoal, setToothEditAppointmentMoal] = useState(
    false
  ) //  修改预约弹窗

  const [tableEditId, setTableEditId] = useState('') // 修改 ID
  const [tableEditPatientId, setTableEditPatientId] = useState('') // 修改 patientID

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const [registeredModal, setRegisteredModal] = useState(false) //挂号弹窗

  const [detailVisible, setDetailVisible] = useState(false) //详情弹窗

  const [visibleTags, setVisibleTags] = useState('false')

  const [loading, setLoading] = useState(false)

  const [sureVisible, setSureVisible] = useState(false)

  const [sureData, setSureData] = useState<any>({})

  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (appointmentDetailModal) {
      setVisibleTag('true')
    } else {
      setVisibleTag('false')
    }
  }, [appointmentDetailModal])

  useEffect(() => {
    if (detailVisible) {
      setVisibleTags('true')
    } else {
      setVisibleTags('false')
    }
  }, [detailVisible])

  //  useEffect(() => {
  //    if (activeKey == '4' && params.current !== 0) {
  //      if (onlyThisAccount == true || onlyThisAccount == false) {
  //        getPage()
  //      }
  //    }
  //  }, [onlyThisAccount, activeKey])

  const getPage = () => {
    dispatch(
      appointmentListAsync({
        ...params,
        startTime: params?.startTime || head,
        endTime: params?.endTime || tail,
        actionTimeHead: undefined,
        actionTimeTail: undefined,
        patientId,
        // onlyDisplayThisAccount: onlyThisAccount || false,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const data = {
          total: res.total,
          items: res.records,
        }
        setPage(data)
        dispatch(setBtnLoading(false))
      })
  }

  useEffect(() => {
    if (params.current !== 0) {
      getPage()
      sessionStorage.setItem(
        location.pathname + '_yuyue',
        JSON.stringify({ ...params, tabBar: '4' })
      )
    }
  }, [params])

  // useImperativeHandle(ref, () => ({
  //   reload: () => {
  //     dispatch(getPaymentList(params))
  //   },
  // }))

  return (
    <Col
      className={styles.main}
      style={{
        marginTop: patientId ? 0 : type == 1 ? '-0' : '10px',
        height: type == 1 ? '100%' : 'calc(100% - 10px)',
        paddingLeft: type == 1 ? 10 : 15,
      }}
    >
      <Query
        onValueChange={(v) => {
          setParams({
            ...params,
            ...v,
            current: 1,
            endTime: v.actionTimeTail,
            startTime: v.actionTimeHead,
          })

          onLeftCount &&
            onLeftCount({
              //口腔版 his 左侧统计数据
              departmentIdA: v.departmentId,
              doctorIdA: v.mainDoctorId,
              nameA: v.name,
              startTimeA: v.actionTimeHead ? v.actionTimeHead : v.startTime,
              endTimeA: v.actionTimeTail ? v.actionTimeTail : v.endTime,
              appointmentStateA: v.appointmentState,
              appointmentTypeA: v.appointmentType,
            })
        }}
        patientId={patientId}
        type={type || 0}
      />
      <EditableList
        bordered={type == 1 ? true : false}
        className={styles.table}
        rowKey={(v, i) => `${i}`}
        sticky={location.pathname == '/toothHome'}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns(
          params.size * (params.current - 1),
          (action, t) => {
            switch (action) {
              case 'apply':
                if (hisVersion == 1) {
                  setTableEditId(t.id)
                  setTableEditPatientId(t.patientId)
                  setRegisteredModal(true)
                } else {
                  dispatch(
                    traceRoute({
                      name: '挂号',
                      path: '/registration',
                    })
                  )
                  dispatch(setAppointmentIdSlice(t.id))
                }
                break
              case 'detail':
                if (hisVersion == 1) {
                  setTableEditId(t.id)
                  setDetailVisible(true)
                } else {
                  history.push({
                    pathname: '/appointmentDetail',
                    state: {
                      id: t.id,
                      appointmentState: t.appointmentState,
                      tag: '0', //专业版标志位
                    },
                  })
                }

                // dispatch(
                //   traceRoute({
                //     name: "预约详情",
                //     path: "/appointmentDetail",
                //     state: {
                //       id: t.id,
                //     },
                //   })
                // )
                break
              case 'edit':
                if (hisVersion == 1) {
                  setTableEditId(t.id)
                  setToothEditAppointmentMoal(true)
                } else {
                  history.push({
                    pathname: '/addEditAppointment',
                    state: {
                      id: t.id,
                    },
                  })
                }

                // dispatch(
                //   traceRoute({
                //     name: "修改预约",
                //     path: "/addEditAppointment",
                //     state: {
                //       id: t.id,
                //     },
                //   })
                // )
                break
              case 'cancel':
                dispatch(cancelAppointmentAsync({ id: t.id, sendSMSFlag: t.sendSMSFlag }))
                  .then(unwrapResult)
                  .then(() => {
                    getPage()
                  })
                break
              case 'sure':
                setSureData(t)
                setSureVisible(true)
                setChecked(false)
                break
              default:
                break
            }
          },
          type
        ).filter((v) =>
          !patientId
            ? v
            : v.dataIndex != 'name' &&
              v.dataIndex != 'sex' &&
              v.dataIndex != 'name' &&
              v.dataIndex != 'ageYear' &&
              v.dataIndex != 'phone'
        )}
        onChangePage={(current, size) => {
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }}
        onRow={(r) => ({
          onDoubleClick: () => {
            setDetailId(r.id)
            if (hisVersion == 1) {
              setAppointmentDetailModal(true)
            } else {
              dispatch(
                traceRoute({
                  name: '预约详情',
                  path: '/appointmentDetail',
                  state: {
                    id: r.id,
                    appointmentState: r.appointmentState,
                    tag: '0', //专业版标志位
                  },
                })
              )
            }
          },
        })}
      />
      <Modal
        title='预约详情'
        width={1050}
        visible={appointmentDetailModal}
        onCancel={() => {
          setAppointmentDetailModal(false)
        }}
        footer={null}
      >
        {/* <div> */}
        <AppointmentDetail id={detailId} visibleTag={visibleTag} />
        {/* </div> */}
      </Modal>
      <Modal
        title='失败原因'
        visible={reasonModal}
        onCancel={() => {
          setReasonModal(false)
          form.resetFields()
        }}
        footer={null}
      >
        {failReason}
        <Row justify='end'>
          <Button type='primary' onClick={() => setReasonModal(false)}>
            确认
          </Button>
        </Row>
      </Modal>

      {/* his 口腔版修改预约 */}
      <Modal
        title='修改预约'
        visible={toothEditAppointmentMoal}
        onCancel={() => {
          setToothEditAppointmentMoal(false)
        }}
        width={1200}
        footer={null}
        destroyOnClose
      >
        <div style={{ height: 600 }}>
          <AppointmentRegister
            AppointmentId={Number(tableEditId)}
            status={2}
            onsubmit={() => {
              setParams({
                ...params,
                current: 1,
              })
              setToothEditAppointmentMoal(false)
            }}
            onCancel={() => {
              setToothEditAppointmentMoal(false)
            }}
          />
        </div>
      </Modal>
      {/* 工作站-挂号 */}
      <RegisteredModal
        title={0}
        patientId={tableEditPatientId}
        appointmentId={tableEditId}
        visible={registeredModal}
        onOk={() => {
          setTableEditPatientId('');
          setTableEditId('');
          setRegisteredModal(false)
        }}
        onCancel={() => {
          setRegisteredModal(false)
        }}
      />

      <Modal
        width={1050}
        title='预约详情'
        destroyOnClose
        visible={detailVisible}
        onCancel={() => setDetailVisible(false)}
        footer={null}
      >
        <div>
          <AppointmentDetail
            id={tableEditId}
            status={String(hisVersion)}
            visibleTag={visibleTags}
          />
        </div>
      </Modal>

      <Modal
        width={500}
        title='确认操作'
        confirmLoading={loading}
        destroyOnClose
        visible={sureVisible}
        onCancel={() => setSureVisible(false)}
        onOk={() => {
          setLoading(true)
          dispatch(sureAppointmentAsync({id: sureData?.id, flag: checked ? 1 : 0}))
            .then(unwrapResult)
            .then(() => {
              setLoading(false)
              setSureVisible(false)
              getPage()
            })
        }}
      >
        <div style={{paddingLeft: '10px'}}>
          <Checkbox checked={checked} onChange={() => {
            setChecked(!checked)
          }}>
            发送短信 {sureData?.tenantName && '-'} 您已成功预约
            请在{sureData?.appointmentDate}&nbsp;&nbsp;
            {sureData?.appointmentStartTime}
            {sureData?.appointmentStartTime && sureData?.appointmentEndTime ? '~' + sureData?.appointmentEndTime : ''}
            到挂号处登记信息。地址：{sureData?.tenantAddress || '-'} 电话：
            {sureData?.tenantPhone || '-'}
          </Checkbox>
          {/* {sureData?.smsSendFlg == 1 ? (<p>该预约已发送过短信</p>) : ''} */}
        </div>
      </Modal>
    </Col>
  )
}

export const AppointmentLists = forwardRef(AppointmentList)
