import React from "react";
import { DefaultTheme } from "./Default";

export interface ThemeData {
  // Colors.
  c1: string;
  c2: string;
  c3: string;

  // Text colors.
  tc1: string;
  tc2: string;
  tc3: string;
  // 标题文字
  tt: string;

  // Other colors.
  // 背景色
  bg: string;
  // 大块Panel背景色
  pn: string;
  // Table header background color.
  th: string;
  // Disabled.
  ds: string;

  // header icon color.
  hic: string;

  // Widget colors.
  // Input border.
  wbd: string;
  // Switch unchecked background color.
  wsu: string;

  // Role colors.
  // Successful.
  s: string;
  // Warning.
  w: string;
  // Error.
  e: string;

  // Selected color.
  sl: string;

  // Border radius.
  br: number;
}

export const ThemeContext = React.createContext(DefaultTheme);
