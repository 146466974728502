/* eslint-disable react/no-unknown-property */
import { unwrapResult } from "@reduxjs/toolkit"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch } from "../../app/store"
import { replaceAccessToken } from "../login/loglinSlice"

export const Shoper = () => {
  const dispatch = useDispatch<RootDispatch>()

  const [iframeUrl, setIframeUrl] = useState("")

  useEffect(() => {
    dispatch(replaceAccessToken())
      .then(unwrapResult)
      .then((res) => {
        const url = window.location.host
        if (res.token) {
          // 有权限
          if (url.indexOf("hydhis.cn")>=0) {
            // 正式
            setIframeUrl(
              `https://${
                res.type || "fs"
              }.store.mall.linkang-medical.com/?accessToken=${res.token}`
            )
          } else if (url === "his.test.hydhis.com") {
            // 测试
            setIframeUrl(
              `http://${
                res.type || "fs"
              }.store.mall.test.hydhis.com/?accessToken=${res.token}`
            )
          } else if (url === `his.develop.hydhis.com`) {
            // 开发
            setIframeUrl(
              `http://${
                res.type || "fs"
              }.store.mall.develop.hydhis.com/?accessToken=${res.token}`
            )
          } else {
            // 其他端口
            setIframeUrl(
              `http://${
                res.type || "fs"
              }.store.mall.develop.hydhis.com/?accessToken=${res.token}`
            )
          }
        } else {
          // 无权限
          if (url.indexOf("hydhis.cn")>=0) {
            // 正式
            setIframeUrl(
              `https://${res.type || "fs"}.store.mall.linkang-medical.com/`
            )
          } else if (url === "his.test.hydhis.com") {
            // 测试
            setIframeUrl(
              `http://${res.type || "fs"}.store.mall.test.hydhis.com/`
            )
          } else if (url === `his.develop.hydhis.com`) {
            // 开发
            setIframeUrl(
              `http://${res.type || "fs"}.store.mall.develop.hydhis.com/`
            )
          } else {
            // 其他端口
            setIframeUrl(
              `http://${res.type || "fs"}.store.mall.develop.hydhis.com/`
            )
          }
        }
      })
  }, [])
  return (
    <div style={{ padding: "20px", height: "100%" }}>
      <iframe
        src={iframeUrl}
        style={{ width: "100%", height: "100%" }}
        scrolling='auto'
        frameBorder='0'
      ></iframe>
    </div>
  )
}
