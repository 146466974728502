/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-13 13:53:27
 */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import { emptyOf, Pagination } from '../../../models/pagination'
import { fromJson, treatmentCost } from '../../../models/treatmentCost'
import * as service from '../../../services/treatmentCost'
import {
  treatmentCostParams,
  GetDoctorSaleEntriesParams,
} from '../../../services/treatmentCost'
interface TreatCostState {
  TreatmentCostData: []
  Total: 0
  Current: 1
  pageLoading: false
  allData: any
}
const initialState = {
  TreatmentCostData: [],
  Total: 0,
  Current: 1,
  pageLoading: false,
  allData: {},
} as TreatCostState

const treatmentCostSlice = createSlice({
  name: 'treatmentCostSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.TreatmentCostData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setPageLoading(state, action) {
      state.pageLoading = action.payload
    },
    setAllData(state, action) {
      state.allData = action.payload
    },
  },
})

export const selectGetDoctorSaleEntriesParams = (
  state: RootState
): GetDoctorSaleEntriesParams => state.doctorSale.params

// 导出
export const getTreatmentCostExoprt = createAsyncThunk<
  void,
  GetDoctorSaleEntriesParams,
  RootThunkApi
>('doctorAudit/getTreatmentCostExoprt ', async (params, api) => {
  return sendAsync(service.getDoctorAuditExport(params), api)
})

export const {
  setAllData,
  setData,
  setTotal,
  setCurrent,
  setPageLoading,
} = treatmentCostSlice.actions

export const selectTreatmentCostData = (state: RootState) =>
  state.treatmentCost.TreatmentCostData

export const selectTotal = (state: RootState) => state.treatmentCost.Total

export const selectCurrent = (state: RootState) => state.treatmentCost.Current

export const selectPageLoading = (state: RootState) =>
  state.treatmentCost.pageLoading

export const selectTreatmentCostAllData = (state: RootState) =>
  state.treatmentCost.allData

export function getTreatmentCostList(params: treatmentCostParams): RootThunk {
  return api(service.getTreatmentCostList(params), (data: any, dispatch) => {
    dispatch(setAllData(data))
    dispatch(setData(data.treatmentServiceSaleSummary.records))
    dispatch(setTotal(data.treatmentServiceSaleSummary.total))
    dispatch(setPageLoading(false))
  })
}

export default treatmentCostSlice.reducer
