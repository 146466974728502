import { Button, TableColumnType } from "antd"
import moment from "moment"
import React, { ReactElement } from "react"
import { DateTimeFormat } from "../../../models/datetime"
import { Dimen } from "../../../models/dimen"
import { getStationUseName } from "../../../models/formula"
import { getOrderCategoryName } from "../../../models/toBeCompletedTreatment"

export const CompletedTreatmentColumns = (
  onClick: (text: string, t: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      dataIndex: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '门诊号',
      dataIndex: 'outpatientNo',
      width: Dimen.Name,
      align: 'center',

      render: (_, t) => {
        return Number(t.outpatientNo) > 0 ? t.outpatientNo : '-'
      },
    },
    {
      title: '患者姓名',
      dataIndex: 'patientName',

      align: 'center',
    },
    {
      title: '项目',
      dataIndex: 'treatmentServiceName',

      align: 'center',
      render: (_, t) => {
        return t.treatmentServiceName ? t.treatmentServiceName : '-'
      },
    },
    {
      title: '数量',
      dataIndex: 'num',

      align: 'center',
    },
    {
      title: '频次',
      dataIndex: 'normalFrequency',

      align: 'center',
      render: (_, t) => {
        return t.normalFrequency ? t.normalFrequency : '-'
      },
    },
    {
      title: '用法',
      dataIndex: 'normalUsemethod',

      align: 'center',
      render: (_, t) => {
        return t.normalUsemethod ? t.normalUsemethod : '-'
      },
    },
    {
      title: '每次用量',
      dataIndex: 'normalDose',

      align: 'center',
      render: (_, t) => {
        return Number(t.normalDose) > 0 ? t.normalDose : '-'
      },
    },
    {
      title: '天数',
      dataIndex: 'useDays',

      align: 'center',
      render: (_, t) => {
        return t.useDays ? t.useDays : '-'
      },
    },
    {
      title: '金额',
      dataIndex: 'drugAmount',

      align: 'center',
      render: (_, t) => {
        return t.drugAmount >= 0 ? t.drugAmount?.toFixed(2) : '-'
      },
    },
    {
      title: '甲/乙',
      dataIndex: 'orderCategory',

      align: 'center',
      render: (_, t) => {
        return t.orderCategory ? getOrderCategoryName(t.orderCategory) : '-'
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',

      align: 'center',
      render: (_, t) => {
        return t.remark ? t.remark : '-'
      },
    },
    {
      title: '上次执行人',
      dataIndex: 'latelyOperatorName',

      align: 'center',
      render: (_, t) => {
        return t.latelyOperatorName ? t.latelyOperatorName : '-'
      },
    },
    {
      title: '上次执行时间',
      dataIndex: 'latelyTime',

      width: '12rem',
      align: 'center',
      render: (_, t) =>
        t.latelyTime ? moment(t.latelyTime).format(DateTimeFormat) : '-',
    },
    {
      title: '操作',
      align: 'center',
      fixed: 'right',
      width: '6rem',
      render: function Element(_, t) {
        return (
          <>
            <Button
              type='primary'
              ghost
              size='small'
              onClick={() => {
                onClick('print', t)
              }}
            >
              打印
            </Button>
          </>
        )
      },
    },
  ]
}
