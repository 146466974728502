/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-15 19:04:22
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-01 16:05:31
 */
import { Row, Col } from 'antd';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { getPatientDataTypeName } from '../../../../models/toothPatient';
import { ToothComponents } from '../../../treatment/editor/modals/toothComponents';
import styles from './outpatientInfoTime.module.css'
import {getAppointTypeName, getAppointTypeType} from '../../../../models/appointment'

interface TimeLineProps {
  data: any
}

export const TimeLine = ({ data }: TimeLineProps): ReactElement => {
  const [operatingContent, setOperatingContent] = useState('')
  useEffect(() => {
    const operatingContentList =
      data.operatingContent[0] === "["
        ? JSON.parse(data.operatingContent)
        : []
    setOperatingContent(
      operatingContentList.map((v: any) => {
        return `${v.surgIcalName}/${v.dj}/${v.qk}`
      })
    )
  }, [data])
  return (
    <div className={styles.timeline}>
      <div className={styles.time}>
        <span>{moment(data.creatTime).format('MM-DD')}</span>
        <span>{moment(data.creatTime).format('hh:mm')}</span>
      </div>
      <div className={styles.timeCell}>
        <div className={styles.cell}>
          <div className={styles.timeType}>
            {getPatientDataTypeName(data.patientDataType)}
          </div>
          <div className={styles.timeContent}>
            {data.patientDataType == 3 && (
              <Row>
                <Col span={8}>结算编号：{data.paymentId}</Col>
                <Col span={8}>总金额：{data.totalPayAmount}</Col>
                <Col span={8}>报销金额：{data.insurancePayAmount}</Col>
                <Col span={8}>本次实收：{data.realPayAmount}</Col>
                <Col span={8}>
                  欠费金额：
                  {data?.arrearageAmount > 0 ? data?.arrearageAmount : 0}
                </Col>
                <Col span={8}>
                  结算状态：{data.arrearageFlg == 1 ? '欠费中' : '已结清'}
                </Col>
              </Row>
            )}
            {data.patientDataType == 2 && (
              <Row gutter={[10, 10]}>
                <Col span={8}>医生：{data.doctorName}</Col>
                {data.recipeItemShort.length != 0 &&
                  data.recipeItemShort.map((v: any) => (
                    <Col span={24} key={v}>
                      <div className={styles.tooth}>
                        <div>处置名称：</div>
                        <div style={{ margin: '0 10px' }}>
                          <ToothComponents
                            isEdit={false}
                            data={v.toothStruct || '--@--@--@--'}
                          />
                        </div>
                        <div>{v.name}</div>
                      </div>
                    </Col>
                  ))}
              </Row>
            )}
            {data.patientDataType == 4 && (
              <Row gutter={[10, 10]}>
                <Col span={8}>接诊科室：{data.treatmentDepartmentName}</Col>
                <Col span={8}>接诊医生：{data.treatmentDoctorName}</Col>
                <Col span={24}>
                  <div className={styles.tooth}>
                    <div>口腔诊断：</div>
                    <div style={{ margin: '0 10px' }}>
                      <ToothComponents
                        isEdit={false}
                        data={data.diseaseToothStomatology || '--@--@--@--'}
                      />
                    </div>
                    <div>{data.diseaseStomatology}</div>
                  </div>
                </Col>
              </Row>
            )}
            {data.patientDataType == 5 && (
              <Row>
                <Col span={8}>手术名称：{operatingContent}</Col>
                <Col span={8}>主刀医生：{data.mainDoctorName}</Col>
                <Col span={8}>麻醉医生：{data.anesthesiaDoctorName}</Col>
                <Col span={8}>手术时间：{data.appointmentTime}</Col>
                <Col span={8}>
                  手术状态：
                  {data.state == 0
                    ? '未完成'
                    : data.state == 1
                    ? '执行'
                    : '取消'}
                </Col>
              </Row>
            )}
            {data.patientDataType == 1 && (
              <Row>
                <Col span={8}>
                  挂号类别：{data.registrationCategory == 0 ? '自费' : '医保'}
                </Col>
                <Col span={8}>挂号科室：{data.registrationDepartmentName}</Col>
                <Col span={8}>挂号医生：{data.registrationDoctorName}</Col>
                <Col span={8}>
                  就诊类型：{data.receiveTag == 0 ? '初诊' : '复诊'}
                </Col>
                <Col span={8}>挂号时间：{data.registrationTime}</Col>
                <Col span={8}>咨询师：{data.consultDoctorName}</Col>
                <Col span={8}>就诊原因：{data.reason}</Col>
              </Row>
            )}
            {data.patientDataType == 6 && (
              <Row>
                <Col span={8}>
                  预约方式：{getAppointTypeName(data.appointmentType)}
                </Col>
                <Col span={8}>预约科室：{data.appointmentDepartmentName}</Col>
                <Col span={8}>预约医生：{data.appointmentDoctorName}</Col>
                <Col span={8}>预约时间：{data.appointmentRecordTime}</Col>
                <Col span={8}>
                  预约项目：
                  {data.appointmentItem && data.appointmentItem.length > 0
                    ? data.appointmentItem
                    : '-'}
                </Col>
                <Col span={8}>
                  预约备注：
                  {data.appointmentComment && data.appointmentComment.length > 0
                    ? data.appointmentComment
                    : '-'}
                </Col>
                <Col span={8}>
                  预约状态：{getAppointTypeType(data.appointmentState)}
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
