/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-10 13:16:31
 */
import { Space, Switch, TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'

type ActionType = 'edit' | 'stateEdit'

export const columns = (
  flag: any,
  startIndex: number
): TableColumnType<any>[] => {
  let c = []

  const colums: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '5rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '库房名称',
      dataIndex: 'storehouseName',
      align: 'center',
      ellipsis: true,
      width: '8rem',
      render: (_, r) => {
        return r.storehouseName ? r.storehouseName : '-'
      },
    },
    {
      title: '批次',
      dataIndex: 'batchNo',
      align: 'center',
      ellipsis: true,
      width: '5rem',
      render: (_, r) => {
        return r.batchNo ? r.batchNo : '-'
      },
    },
    {
      // 商品名称/生产厂家/规格/剂型
      title: '产品信息',
      align: 'left',
      width: '300px',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <div style={{ height: '55px' }}>
            <p
              style={{
                height: '28px',
                lineHeight: '36px',
                padding: '0',
                margin: '0',
                fontSize: '16px',
                fontWeight: 'bolder',
              }}
            >
              {t.name}
            </p>
            <p
              style={{
                height: '28px',
                lineHeight: '24px',
                padding: '0',
                margin: '0',
                color: '#999999',
                fontSize: '14px',
              }}
            >
              {t.productInfo}
            </p>
          </div>
        )
      },
    },
    {
      title: '入库单价',
      dataIndex: 'unitPrice',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return flag == 1 ? '-' : r.unitPrice ? r.unitPrice?.toFixed(5) : '0'
      },
    },
    {
      title: '期初数量',
      dataIndex: 'beginningCount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: function Element(_, r) {
        return r?.splitTag == '1' ? (
          <div>
            <span>{r?.beginningCount + r?.accountUnit}</span>
            <span>/{r.splitBeginningCount + r?.splitUnit} </span>
          </div>
        ) : (
          <div>
            <span>{r?.beginningCount + r?.accountUnit}</span>
          </div>
        )
      },
    },
    {
      title: '购入入库',
      dataIndex: 'storageCount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return   r.storageCount + r?.accountUnit
        
      },
    },
    {
      title: '购入退库',
      dataIndex: 'returnStorageCount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return   r.returnStorageCount + r?.accountUnit
      },
    },
    {
      title: '发药',
      dataIndex: '',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: function Element(_, r) {
        return r?.splitTag == '1' ? (
          <div>
            <span>{r.shipmentsCount + r?.accountUnit} </span>
            <span>/{r?.shipmentsSplitCount + r?.splitUnit}</span>
          </div>
        ) : (
          <div>
            <span>{r.shipmentsCount + r?.accountUnit} </span>
          </div>
        )
      },
    },
    {
      title: '退药',
      dataIndex: 'realPayAmount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: function Element(_, r) {
        return r?.splitTag == '1' ? (
          <div>
            <span>{r.returnShipmentsCount + r?.accountUnit} </span>
            <span>/{r?.returnShipmentsSplitCount + r?.splitUnit}</span>
          </div>
        ) : (
          <div>
            <span>{r.returnShipmentsCount + r?.accountUnit} </span>
          </div>
        )
      },
    },
    {
      title: '领用出库',
      dataIndex: 'obtainCount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r.obtainCount + r?.accountUnit
      },
    },
    {
      title: '调拨',
      dataIndex: 'allocationCount',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_, r) => {
        return  r.allocationCount + r?.accountUnit
      },
    },
    {
      title: '借记',
      dataIndex: 'borrowCount',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_, r) => {
        return r.borrowCount + r?.accountUnit
      },
    },
    {
      title: '库存盘点',
      dataIndex: '',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: function Element(_, r) {
        return r?.splitTag == '1' ? (
          <div>
            <span>{r.auditCount + r?.accountUnit} </span>
            <span>/{r?.auditSplitCount + r?.splitUnit}</span>
          </div>
        ) : (
          <div>
            <span>{r.auditCount + r?.accountUnit} </span>
          </div>
        )
      },
    },
    {
      title: '报损',
      dataIndex: 'damageCount',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_, r) => {
        return  r.damageCount + r?.accountUnit
      },
    },
    {
      title: '其他出库',
      dataIndex: '',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: function Element(_, r) {
        return r?.splitTag == '1' ? (
          <div>
            <span>{r.otherReturnStorageCount + r?.accountUnit} </span>
            <span>/{r?.otherReturnStorageSplitCount + r?.splitUnit}</span>
          </div>
        ) : (
          <div>
            <span>{r.otherReturnStorageCount + r?.accountUnit} </span>
          </div>
        )
      },
    },
    {
      title: '其他入库',
      dataIndex: '',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: function Element(_, r) {
        return r?.splitTag == '1' ? (
          <div>
            <span>{r.otherStorageCount + r?.accountUnit} </span>
            <span>/{r?.otherStorageSplitCount + r?.splitUnit}</span>
          </div>
        ) : (
          <div>
            <span>{r.otherStorageCount + r?.accountUnit} </span>
          </div>
        )
      },
    },
    {
      title: '结存数量',
      dataIndex: '',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: function Element(_, r) {
        return r?.splitTag == '1' ? (
          <div>
            <span>{r.closeCount + r?.accountUnit} </span>
            <span>/{r?.splitCloseCount + r?.splitUnit}</span>
          </div>
        ) : (
          <div>
            <span>{r.closeCount + r?.accountUnit} </span>
          </div>
        )
      },
    },
  ]
  c = flag == 1 ? colums.filter((v) => v.title !== '批次') : colums
  return c
}
