/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:28
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-11 15:47:35
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
  DatePicker,
  Checkbox,
} from 'antd'
import { unwatchFile } from 'fs'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectTenantCountyCode,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'

export const Query = (props: {
  onValueChange: (params: any) => void
}): ReactElement => {
  const [form] = Form.useForm()

  const { RangePicker } = DatePicker

  const dispatch = useDispatch<RootDispatch>()

  const [flag, setFlag] = useState<any>()

  const [timeList, setTimeList] = useState<any>()

  const tenantCountyCode = useSelector(selectTenantCountyCode)

  useEffect(() => {
    setTimeList([moment().startOf('month'), moment().endOf('day')])
    form.setFieldsValue({
      time: [moment().startOf('month'), moment().endOf('day')],
    })
    form.submit()
  }, [tenantCountyCode])

  const disabledDate: any = (current: any) => {
    return timeList?.[0]
      ? moment(current)?.format('yyyy') != moment(timeList?.[0])?.format('yyyy')
      : false
  }

  return (
    <Form
      form={form}
      onFinish={(values) => {
        const startTime = moment(values?.time?.[0])?.format(
          DateTimeFormatSimpleOnlyOne
        )
        const endTime = moment(values?.time?.[1])?.format(
          DateTimeFormatSimpleOnlyOne
        )
        props.onValueChange({
          ...values,
          timeHead: startTime,
          timeTail: endTime,
          time: undefined,
        })
      }}
    >
      <Row style={{ marginLeft: 4, marginBottom: 10 }} justify='space-between'>
        <Space>
          <Form.Item name='time' noStyle>
            <RangePicker
              format={DateTimeFormatSimple}
              style={{ width: '16em' }}
              disabledDate={disabledDate}
              onCalendarChange={(v: any) => {
                setTimeList(v)
              }}
            />
          </Form.Item>
          <Form.Item name='fylb' noStyle>
            <Select
              showSearch
              style={{ width: '200px' }}
              onChange={form.submit}
              placeholder='医疗类别'
              allowClear
            >
              <Select.Option value='11'>普通门诊</Select.Option>
              <Select.Option value='140104'>门诊慢病</Select.Option>
              <Select.Option value='140201'>门诊特病</Select.Option>
              <Select.Option value='14'>门诊慢特病</Select.Option>
              <Select.Option value='110101'>个人账户下账</Select.Option>
            </Select>
          </Form.Item>
          <Button
            type='primary'
            onClick={() => {
              form.submit()
            }}
          >
            查询
          </Button>
        </Space>
      </Row>
      <Row style={{ marginLeft: 4, marginBottom: 10 }}>
        <span>时间不支持跨年度查询；</span>
      </Row>
    </Form>
  )
}
