import { unwrapResult } from '@reduxjs/toolkit'
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Space,
  Table,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import { BillingCategory } from '../../models/billingCategory'
import { DeliveryState } from '../../models/delivery'
import { getOrderCategoryName, Material } from '../../models/material'
import { Page, startPageOf } from '../../models/page'
import { DeliveryDetailParams } from '../../services/delivery'
import { ThemeContext } from '../../theme/ThemeContext'
import { useQuery } from '../../utils/HttpUtils'
import {
  directDeliver,
  directDeliverSingleItem,
  getDeliveryDetail,
} from './deliverySlice'
import { ManualDeliveryModal } from './ManualDeliveryModal'
import { RecipeStatus } from '../../models/recipe'
import {
  selectInsuranceArray,
  selectTenantAddress, selectTenantAreaCd, selectTenantCategory,
  selectTenantId, selectTenantName,
  selectUserId,
  selectUserName
} from "../../app/applicationSlice";
import {getPaymentResults} from "../payment/result/paymentResultSlice";
import {TraceCodeInputModal} from "../payment/list/TraceCodeInputModal";
import {setInsurance} from "../../layouts/insuranceSlice";
import {DateTimeFormatSimpleOnlyOne} from "../../models/datetime";
import moment from "moment";

export const ManualDelivery = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [pageLoading, setPageLoading] = useState(false)

  const query = useQuery()

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantId = useSelector(selectTenantId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const treatmentId = query.get('treatmentId') || ''

  const paymentId = query.get('paymentId') || ''

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const [result, setResult] = useState<any>()

  const [showCodeInputModal, setShowCodeInputModal] = useState(false)

  const insuranceArray = useSelector(selectInsuranceArray)

  const deliveryState =
    (parseInt(query.get('state') || '') as DeliveryState) ||
    DeliveryState.Unfilled

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const [queries, setQueries] = useState<DeliveryDetailParams>({
    current: 1,
    size: 100,
    // treatmentId,
    paymentId,
    shipmentsState: deliveryState,
  })

  const [page, setPage] = useState<Page<Material>>(startPageOf())

  const [errors, setErrors] = useState<{ name: string; message: string }[]>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [batchDeliverTarget, setBatchDeliverTarget] = useState<
    Material | undefined
  >()

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])


  useEffect(() => {
    if(paymentId){
      dispatch(getPaymentResults(paymentId))
        .then(unwrapResult)
        .then(async (val: any) => {
          setResult(val)
        })
    }else{
      setResult(null);
    }
  }, [paymentId])


  useEffect(() => {
    dispatch(getDeliveryDetail(queries))
      .then(unwrapResult)
      .then((page) => setPage(page))
  }, [queries])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  const actionName = deliveryState === DeliveryState.Fulfilled ? '退药' : '发药'

  const flag = page?.items?.filter((v) => {
    return v?.state != 5
  })

  return (
    <Col
      style={{
        height: 'calc(100% - 10px)',
        margin: '10px 30px',
      }}
    >
      <NavBar
        where={[
          deliveryState === DeliveryState.Fulfilled ? '已发药' : '待发药',
          `手动${actionName}`,
        ]}
        backtrace={{
          name: deliveryState === DeliveryState.Fulfilled ? '已发药' : '待发药',
          path: '/delivery',
          state: {
            station:
              deliveryState === DeliveryState.Unfilled
                ? '0'
                : deliveryState === DeliveryState.Fulfilled
                ? '1'
                : '9',
          },
          routeName: '库房发药',
        }}
        buttons={
          flag?.length ? (
            <div style={{display: "flex"}}>
              <Button
                loading={loading}
                disabled={page.items.length <= 0}
                type='primary'
                size='small'
                onClick={() => {
                  setLoading(true)
                  if (paymentId && page.items.length > 0) {
                    dispatch(
                      directDeliver({
                        paymentId,
                        recipeItemList: page.items?.map((i) => i.id),
                        deliveryState,
                      })
                    )
                      .then(unwrapResult)
                      .then((s) => {
                        if (s.successful) {
                          notification.success({
                            message: '操作成功',
                          })
                          setLoading(false)
                          dispatch(getDeliveryDetail(queries))
                            .then(unwrapResult)
                            .then((page) => setPage(page))
                        } else {
                          setErrors(s.errors)
                          setIsModalVisible(true)
                          setLoading(false)
                        }
                      })
                      .catch((v) => {
                        setLoading(false)
                      })
                  }
                }}
              >
                一键{actionName}
              </Button>

              {(insuranceItem && JSON.stringify(insuranceItem) !== '{}' ) &&
                  <Badge count={result?.traceCodeCnt<0?0:result?.traceCodeCnt} showZero={result?.traceCodeCnt>-1}>
                      <Button
                          style={{marginLeft:"10px"}}
                          size='small'
                          type='primary'
                          disabled={result?.traceCodeCnt == -1}
                          onClick={() => {
                            setShowCodeInputModal(true)
                          }}
                      >
                        {result?.traceCodeCnt == -1 ? '无需录入追溯码' : '录入追溯码'}
                      </Button>
                  </Badge>
              }
            </div>
          ) : (
            <div style={{display: "flex"}}>
              {(insuranceItem && JSON.stringify(insuranceItem) !== '{}') &&
                <Badge count={result?.traceCodeCnt<0?0:result?.traceCodeCnt} showZero={result?.traceCodeCnt>-1}>
                    <Button
                        type='primary'
                        size='small'
                        disabled={result?.traceCodeCnt == -1}
                        onClick={() => {
                          setShowCodeInputModal(true)
                        }}
                    >
                      {result?.traceCodeCnt == -1 ? '无需录入追溯码' : '录入追溯码'}
                    </Button>
                </Badge>
              }
            </div>
          )

        }
      />
      <Col
        style={{
          height: 'calc(100% - 60px)',
          marginTop: 10,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '0px 15px',
        }}
      >
        <Form
          form={form}
          initialValues={{
            all: true,
            categories: [],
          }}
          onFinish={(values) => {
            setQueries({
              ...queries,
              billingCategorys: values.categories.join(','),
            })
          }}
        >
          <Row align='middle' style={{ height: '3.75rem' }}>
            <Form.Item name='all' noStyle valuePropName='checked'>
              <Checkbox
                onChange={(e) => {
                  form.setFieldsValue({
                    categories: e.target.checked
                      ? ['01', '02', '03', '00']
                      : [],
                  })
                  form.submit()
                }}
              >
                全部
              </Checkbox>
            </Form.Item>
            <Form.Item name='categories' noStyle>
              <Checkbox.Group
                style={{ marginLeft: 8 }}
                onChange={(vs) => {
                  form.setFieldsValue({
                    all: vs.length === 4,
                  })
                  form.submit()
                }}
              >
                <Checkbox value='01'>西药</Checkbox>
                <Checkbox value='02'>中成药</Checkbox>
                <Checkbox value='03'>中药</Checkbox>
                <Checkbox value='00'>其他</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Row>
        </Form>
        <EditableList<Material>
          style={{ height: 'calc(100% - 155px)' }}
          loading={pageLoading}
          defaultPageSize={100}
          page={page}
          columns={[
            {
              title: '序号',
              key: 'no',
              align: 'center',
              render: (_1, _2, i) =>
                `${(page.current - 1) * page.size + i + 1}`,
            },
            {
              title: '商品名称',
              dataIndex: 'name',
              align: 'center',
            },
            {
              title: '医保国码',
              dataIndex: 'hilistCode',
              align: 'center',
            },
            {
              title: '数量',
              dataIndex: 'drugCount',
              align: 'center',
            },
            {
              title: '单位',
              dataIndex: 'accountUnit',
              align: 'center',
              render: (_, m) => (m.splitTag ? m.splitUnit : m.accountUnit),
            },
            {
              title: '规格',
              dataIndex: 'packSpec',
              align: 'center',
            },
            {
              title: '每帖数量',
              key: 'countPerTip',
              align: 'center',
              render: (_, m) =>
                m.billingCategory === BillingCategory.ZY ? m.countPerTip : '-',
            },
            {
              title: '中药帖数',
              key: 'tip',
              align: 'center',
              render: (_, m) =>
                m.billingCategory === BillingCategory.ZY ? m.tip : '-',
            },
            {
              title: '单价（元）',
              key: 'retailPrice',
              align: 'right',
              render: (_, m) => m.retailPrice?.toFixed(5),
            },
            {
              title: '金额（元）',
              key: 'drugAmount',
              align: 'right',
              render: (_, m) => m.drugAmount?.toFixed(5),
            },
            {
              title: '批次号',
              key: 'batchNo',
              align: 'center',
              render: (_, m) =>
                m.batchNo === '' || m.batchNo === undefined ? '-' : m.batchNo,
            },
            {
              title: '甲乙分类',
              key: 'orderCategory',
              align: 'center',
              render: (_, m) => getOrderCategoryName(m.orderCategory),
            },
            {
              title: '操作',
              key: 'actions',
              align: 'center',
              render: function ShowActions(_, m) {
                if (m.shipmentsState === 8) {
                  // 已退费，则不显示发药退药按钮
                  return <div>-</div>
                }
                return (
                  <Space>
                    {m?.state == 5 ? (
                      <span>-</span>
                    ) : (
                      <Button
                        type='primary'
                        size='small'
                        onClick={() => {
                          dispatch(
                            directDeliverSingleItem({
                              recipeItemId: m.id,
                              deliveryState,
                            })
                          )
                            .then(unwrapResult)
                            .then((s) => {
                              if (s.successful) {
                                notification.success({
                                  message: '操作成功',
                                })
                                dispatch(getDeliveryDetail(queries))
                                  .then(unwrapResult)
                                  .then((page) => setPage(page))
                              } else {
                                setErrors(s.errors)
                                setIsModalVisible(true)
                              }
                              dispatch(getDeliveryDetail(queries))
                                .then(unwrapResult)
                                .then((page) => setPage(page))
                            })
                        }}
                      >
                        {actionName}
                      </Button>
                    )}

                    {deliveryState === DeliveryState.Unfilled && (
                      <Button
                        size='small'
                        style={{
                          color: theme.c1,
                          borderColor: theme.c1,
                        }}
                        onClick={() => setBatchDeliverTarget(m)}
                      >
                        按批次{actionName}
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          onChangePage={(current, size) =>
            setQueries({ ...queries, current, size: size || 100 })
          }
        />
      </Col>
      <Modal
        title='一键发药失败'
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        okText='确认'
        onOk={() => {
          setErrors([])
          setIsModalVisible(false)
        }}
      >
        <Table
          dataSource={errors}
          columns={[
            {
              title: '药品名称',
              dataIndex: 'name',
              align: 'center',
            },
            {
              title: '药品数量',
              dataIndex: 'count',
              align: 'center',
            },
            {
              title: '原因',
              dataIndex: 'message',
              align: 'center',
            },
          ]}
          pagination={false}
          rowKey={(_, i) => `${i}`}
        />
      </Modal>
      <TraceCodeInputModal
        showCodeInputModal={showCodeInputModal}
        paymentResult={result}
        onCancel={() => setShowCodeInputModal(false)}
        onOk={() => {
          setShowCodeInputModal(false)
          dispatch(getPaymentResults(result.id))
            .then(unwrapResult)
            .then(async (val: any) => {
              setResult(val)
            })
        }}
        isShowUpload={result?.ybSaleUploadStatus == 0 ? true : false}
      />
      <ManualDeliveryModal
        material={batchDeliverTarget}
        visible={!!batchDeliverTarget}
        onCancel={() => setBatchDeliverTarget(undefined)}
        onOk={() => {
          setBatchDeliverTarget(undefined)
          dispatch(getDeliveryDetail(queries))
            .then(unwrapResult)
            .then((page) => setPage(page))
        }}
      />
    </Col>
  )
}
