import { Button, Col, Form, Row, Space, Tooltip, Image } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './imageInformation.module.css'
import { useLocation } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { unwrapResult } from '@reduxjs/toolkit'
import { getImageInformationPage } from './imageInformationSlice'
import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'

export const ImageInformation = (props: { patientId?: any; tabKey?: any }) => {
  const location = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [timeHead, timeTail] = getDateRange(DateRangeType.ThisMonth)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 500,
    startTime: moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
    endTime: moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
  })

  const [ListItem, setListItem] = useState<any>()

  useEffect(() => {
    if (props?.tabKey == 6)
      dispatch(
        getImageInformationPage({ ...params, patientId: props?.patientId })
      )
        .then(unwrapResult)
        .then((v: any) => {
          setListItem(v?.records)
        })
  }, [params, props?.tabKey, props?.patientId])

  return (
    <>
      <Form
        form={form}
        onFinish={(values) => {
          setParams({
            ...values,
            startTime: values?.timeHead
              ? moment(values?.timeHead).format(DateTimeFormatSimpleOnlyOne)
              : moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
            endTime: values?.timeTail
              ? moment(values?.timeTail).format(DateTimeFormatSimpleOnlyOne)
              : moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
            timeHead: undefined,
            timeTail: undefined,
          })
        }}
        style={{ padding: '0 10px' }}
      >
        {/* 收费查询条件 */}
        <Space wrap>
          <DateSelect
            placeholder='请选择时间'
            labelPrefix=''
            namePrefix='time'
            initialValue={DateRangeType.ThisMonth}
            allowClear={false}
            style={{ width: '8.5rem' }}
            onChange={(e) => {
              form.submit()
            }}
          />

          <Button type='primary' htmlType='submit'>
            查询
          </Button>
        </Space>
      </Form>
      <div className={styles.content}>
        {ListItem?.length
          ? ListItem?.map((v: any) => {
              return (
                <>
                  {v?.coherentVideo?.length ? (
                    <>
                      <div className={styles.backs}>
                        <Row
                          style={{ paddingLeft: 10 }}
                          className={styles.header}
                          justify='space-between'
                        >
                          <Col className={styles.headerTitle}>
                            <Space>
                              <div>就诊信息：{v?.registrationTime}</div>
                              <div>{v?.patientName}</div>
                              <div>
                                (
                                {v?.insuranceCode == 'ZIFEI'
                                  ? '自费'
                                  : v?.insuranceCode == '' ||
                                    v?.insuranceCode == null
                                  ? ' 未知险种'
                                  : '医保刷卡'}
                                ) {'  '}
                                {v?.treatmentDoctorName} (
                                {v?.treatmentDepartmentName})
                              </div>
                              <div
                                className={styles.outpatientName}
                                style={{ color: '#027AFF' }}
                              >
                                <Tooltip title={v?.disease}>
                                  {v?.disease}
                                </Tooltip>
                              </div>
                            </Space>
                          </Col>
                        </Row>
                        {v?.coherentVideo?.length ? (
                          <div className={styles.backsImgMain}>
                            {v?.coherentVideo?.map((i: any) => {
                              return (
                                <>
                                  <div className={styles.backsImgBack}>
                                    <div className={styles.backsImg}>
                                      <Image
                                        width={'100%'}
                                        height={'100%'}
                                        src={i}
                                      />
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )
            })
          : ''}
      </div>
    </>
  )
}
