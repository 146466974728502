import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Image,
  Spin,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { Label } from '../../../compnents/widgets/Label'
import styles from './personalSetting.module.css'
import {
  CheckOptions,
  Checks,
  clinicCheckOptions,
  clinicChecks,
  getCheckName,
  getClinicCheckName,
  getShopName,
  Settinglable,
  shopOptions,
} from '../../../models/personalSetting'
import { useQuery } from '../../../utils/HttpUtils'
import { NavBar } from '../../../compnents/nav/NavBar'
import {
  getUserProfileList,
  saveSetting,
} from '../personalDetail/personalDetailSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import _ from 'lodash'
import { selectEdition, selectUserId } from '../../../app/applicationSlice'
import { CaretDownFilled, CloseCircleOutlined } from '@ant-design/icons'
import {
  EditableRecipeCategories,
  getRecipeCategoryName,
} from '../../../models/recipe'

export const PersonalSetting = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const query = useQuery()

  const userId = useSelector(selectUserId)

  const id = query.get('state') as string

  const { Option } = Select

  const [form] = Form.useForm()

  const [checkData, setCheckData] = useState<any>([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [data, setData] = useState<string[]>([''])

  const [state, setState] = useState(true)

  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    setTimeout(function () {
      setIsModalVisible(false)
    }, 3000)
  }, [dispatch, isModalVisible])

  useEffect(() => {
    setData(
      checkData.map((v: any) => {
        return v?.code == 5
          ? v.codeName
          : v.codeName === '0'
          ? ''
          : v.codeName === 'true'
          ? '1'
          : v.codeName
      })
    )
  }, [checkData])

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  }

  useEffect(() => {
    if (id) {
      dispatch(getUserProfileList({ userId: id }))
        .then(unwrapResult)
        .then((c: any) => {
          setCheckData(c)
        })
    } else {
      dispatch(getUserProfileList({ userId: userId }))
        .then(unwrapResult)
        .then((c: any) => {
          setCheckData(c)
        })
    }
  }, [dispatch])

  useEffect(() => {
    const value = checkData?.filter((v: any) => v?.code == '5')?.[0]?.codeName
    const XCXQXValue = checkData?.filter((v: any) => v?.code == '7')?.[0]
      ?.codeName
    form.setFieldsValue({
      [Checks.Zdblsz]: data[0] ? data[0]?.split(',').map(Number) : [],
      [Checks.Mrcfsz]: data[1] ? data[1]?.split(',').map(Number) : [],
      // [Checks.Sc]: data[2] ? data[2]?.split(",").map(Number) : [],
      [Checks.BLFX]: data[2] === '1' ? 'true' : 'false',
      [Checks.SRFSZ]: value ? value : '0',
      [Checks.XCXQX]: XCXQXValue == 'true' ? true : false,
      // [Checks.CFFX]: data[4] === "1" ? "true" : "false",
    })
  }, [dispatch, data])

  // const getWXBindingFn = () => { //微诊所是否绑定
  //   dispatch(getWXBinding())
  //     .then(unwrapResult)
  //     .then((c: any) => setWXBinding(c))
  // }
  //
  // const getRemoveWXBinding = () => {//微诊所解绑
  //   dispatch(removeBinding())
  //     .then(unwrapResult)
  //     .then((c: any) => getWXBindingFn())
  //
  // }

  return (
    <>
      {id ? (
        <Col
          style={{
            margin: '10px 20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <NavBar
            where={['员工管理', '设置']}
            backtrace={{
              name: '机构管理',
              path: '/tenant/manager',
              state: {
                station: '3',
              },
            }}
          />
        </Col>
      ) : (
        <></>
      )}
      <div className={id ? styles.mains : styles.main}>
        <Label label='功能设置' style={{ marginBottom: 10 }} />
        <Form
          {...layout}
          form={form}
          onFinish={(values) => {
            if (values[2].length == 0) {
              notification.error({ message: '请选择默认处方设置' })
              return
            }

            const pattern = /^[1-8]*$/
            const f = _.chain(values)
              .pickBy((_, k) => pattern.test(k))
              .mapValues(
                (v, k) =>
                  ({
                    code: k,
                    codeName:
                      k === Checks.Sc.toString()
                        ? state
                        : k === Checks.XCXQX.toString()
                        ? v
                          ? true
                          : false
                        : typeof v === 'string'
                        ? v.toString()
                        : v?.join(','),
                    userId: id ? id : userId,
                  } as Settinglable)
              )
              .values()
              .value()

            if (
              (f[0].codeName ? f[0].codeName.split(',') : [])
                .map(Number)
                .some(
                  (v: any) =>
                    v === 1 || v === 2 || v === 3 || v === 4 || v === 7
                )
            ) {
              setSubmitLoading(true)
              dispatch(saveSetting(f))
                .then(unwrapResult)
                .then(() => {
                  setSubmitLoading(false)
                  notification.success({
                    message: '保存成功',
                    duration: 3,
                  })
                  const tabNum = Number(localStorage.getItem('tabNum'))
                  !f[0]?.codeName
                    ?.split(',')
                    ?.some((item: any) => item == tabNum) &&
                    localStorage.setItem(
                      'tabNum',
                      f[0]?.codeName
                        ?.split(',')
                        ?.filter((v) => v != '5' && v != '6')[0]
                    )
                  if (id) {
                    dispatch(getUserProfileList({ userId: id }))
                      .then(unwrapResult)
                      .then((c: any) => setCheckData(c))
                  } else {
                    dispatch(getUserProfileList({ userId: userId }))
                      .then(unwrapResult)
                      .then((c: any) => {
                        setCheckData(c)
                      })
                  }
                })
                .catch(() => {
                  setSubmitLoading(false)
                })
            } else {
              setIsModalVisible(true)
            }
          }}
        >
          <Row>
            <Form.Item
              label='诊断病历设置'
              name={Checks.Zdblsz}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Checkbox.Group>
                {CheckOptions.map((c) => {
                  const name = getCheckName(c)
                  return (
                    <Checkbox
                      key={c}
                      value={c}
                      onChange={(e) => {
                        if (e.target.value == 3 && e.target.checked) {
                          //口腔病例与处置项目联动
                          if (
                            !form.getFieldValue(Checks.Mrcfsz)?.includes(13)
                          ) {
                            form.setFieldsValue({
                              [Checks.Mrcfsz]: [
                                ...form.getFieldValue(Checks.Mrcfsz),
                                13,
                              ],
                            })
                          }
                        } else if (e.target.value == 3 && !e.target.checked) {
                          form.setFieldsValue({
                            [Checks.Mrcfsz]: form
                              .getFieldValue(Checks.Mrcfsz)
                              .filter((item: any) => item != 13),
                          })
                        }
                      }}
                    >
                      {name}
                    </Checkbox>
                  )
                })}
              </Checkbox.Group>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              label='保存病历后，自动创建空处方'
              name={Checks.Mrcfsz}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Select
                style={{ width: '70%' }}
                className={styles.addtion}
                mode='multiple'
                showArrow
                showSearch={false}
                // suffixIcon={<CaretDownFilled />}
                defaultValue={checkData}
              >
                {EditableRecipeCategories.map((v: any) => (
                  <Option key={v} value={v}>
                    {getRecipeCategoryName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Row>
          {/* <Row>
            <Form.Item
              label='商城'
              name={Checks.Sc}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Checkbox.Group>
                {shopOptions.map((c) => {
                  const name = getShopName(c)
                  return (
                    <Checkbox
                      key={c}
                      value={c}
                      onChange={(e) => {
                        setState(e.target.checked)
                      }}
                    >
                      {name}
                    </Checkbox>
                  )
                })}
              </Checkbox.Group>
            </Form.Item>
          </Row> */}
          {checkData && checkData.find((v: any) => v.code === '4') ? (
            <>
              <Row>
                <Form.Item
                  colon={false}
                  label='辅助诊断设置'
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                ></Form.Item>
              </Row>
              {/* <Row>
                <Form.Item
                  label='病例诊断风险提示'
                  name={Checks.BLFX}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Radio.Group>
                    <Radio value='true'>风险提示</Radio>
                    <Radio value='false'>不提示</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row> */}
              <Row>
                <Form.Item
                  label='医嘱处方风险提示'
                  name={Checks.BLFX}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                  // valuePropName='checked'
                >
                  {/* <Checkbox>启用</Checkbox> */}
                  <Radio.Group>
                    <Radio value='true'>风险提示</Radio>
                    <Radio value='false'>不提示</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </>
          ) : (
            <></>
          )}
          <Row style={{ position: 'relative' }}>
            <Form.Item
              label='输入法设置'
              name={Checks.SRFSZ}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
              initialValue={'0'}
            >
              <Radio.Group>
                <Radio value='0'>拼音</Radio>
                <Radio value='1'>五笔</Radio>
              </Radio.Group>
            </Form.Item>
            <span style={{ position: 'absolute', top: 6, left: '28rem' }}>
              （作用于处方搜索）
            </span>
          </Row>
          <Row>
            <Form.Item
              label='小程序权限'
              name={Checks.XCXQX}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
              }}
              valuePropName='checked'
            >
              <Checkbox />
            </Form.Item>
          </Row>
          <Row gutter={20}>
            <Col
              offset={10}
              span={10}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Button
                className={styles.buttom_submit}
                type='primary'
                htmlType='submit'
                size='middle'
                loading={submitLoading}
              >
                保存
              </Button>
            </Col>
          </Row>
        </Form>
        <Modal
          width={350}
          title={null}
          visible={isModalVisible}
          footer={null}
          centered
          closable={false}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloseCircleOutlined
              style={{ fill: 'red', fontSize: '18px', marginRight: '20px' }}
            />
            <p className={styles.message}>请至少勾选保存一份诊断病例</p>
          </div>
        </Modal>
      </div>
    </>
  )
}
