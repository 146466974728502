/* eslint-disable @typescript-eslint/no-explicit-any */
import { CaretDownFilled, CaretRightFilled } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Divider,
  Modal,
  notification,
  Popover,
  Row,
  Space,
  Tooltip,
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import Icon, {
  UserHomeOutlined1,
  UserHomeOutlined2,
  UserHomeOutlined3,
  UserHomeOutlined4,
  UserHomeOutlined5,
  UserHomeOutlined6,
  UserHomeOutlined8,
} from '../../compnents/icons/Icons'
import { Label } from '../../compnents/widgets/Label'
import {
  selectMenus,
  selectTwoMenus,
  traceRoute,
} from '../../layouts/layoutSlice'
import { DateTimeFormat } from '../../models/datetime'
import { PrintTemplateCategory } from '../../models/print'
import { getRegistrationStateName } from '../../models/registration'
import { Settings } from '../../models/setting'
import { getGenderName } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import {
  getCancel,
  getCountList,
  getHomeList,
  getMessageList,
  getSelectMenuAsync,
  getState,
  saveSelectMenuAsync,
  selectCountdata,
  selectHomedata,
  selectMessagedata,
} from '../home/HomeSlice'
import { PrintTemplateModal } from '../print/PrintTemplateModal'
import { RegisterNoAppointModal } from '../registration/list/RegisterNoAppointModal'
import { selectBooleanSetting } from '../settings/settingsSlice'
import { getTreatments } from '../treatment/diagnosis/diagnosisSlice'
import { CatalogueUpdate } from './catalogueUpdate'
import styles from './Home.module.css'
import {selectUserId} from "../../app/applicationSlice";
import {Menu} from "../../models/menu";

interface HomeMenuProps {
  data: any
  state: string
  hoverIndex: number
  setHoverIndex: (index: number) => void
  onAction: (
    action:
      | '开始就诊'
      | '继续就诊'
      | '取消挂号'
      | '查看详情'
      | '病历打印'
      | '开始结算'
      | '医保卡'
  ) => void
}

const HomeMenu = ({
  data,
  state,
  hoverIndex,
  setHoverIndex,
  onAction,
}: HomeMenuProps): ReactElement => {
  const theme = useContext(ThemeContext)
  const hasTreatmentTemplate = useSelector(
    selectBooleanSetting(Settings.Printing.HasTreatmentTemplate)
  )
  const [isVisible, setIsVisible] = useState(false)
  const extra = hasTreatmentTemplate && state !== '未就诊' ? ['病历打印'] : []
  const menus = (state === '未就诊'
    ? ['开始就诊', '取消挂号']
    : state === '未结算'
    ? ['继续就诊', '开始结算']
    : ['查看详情']
  ).concat(extra)
  const menusYB = (state === '未就诊'
    ? ['开始就诊', '取消挂号', '医保卡']
    : state === '未结算'
    ? ['继续就诊', '开始结算', '医保卡']
    : ['查看详情', '医保卡']
  ).concat(extra)

  const menusAll = data?.insuranceCode == 'ZIFEI' ? menus : menusYB

  return (
    <Row
      align='middle'
      style={{
        fontSize: '1rem',
        height: '3rem',
        paddingLeft: 20,
        paddingRight: 20,
        cursor: 'pointer',
      }}
      className={styles.treatment}
    >
      <Col
        style={{
          width: 8,
          height: 8,
          backgroundColor:
            state === '未就诊'
              ? theme.e
              : state === '未结算'
              ? theme.w
              : state === '已结算'
              ? theme.s
              : state === '已退费'
              ? theme.tc3
              : theme.s,
          borderRadius: 10,
        }}
      />
      <Popover
        placement='right'
        content={
          <Col>
            {menusAll.map((m) => (
              <Row
                key={m}
                align='middle'
                justify='center'
                style={{
                  width: '6.25rem',
                  height: '3.125rem',
                  flex: 1,
                  fontSize: '1rem',
                  color: theme.c1,
                  cursor: 'pointer',
                }}
                className={styles.menu}
                onClick={() => {
                  setIsVisible(false)
                  onAction(m as any)
                }}
              >
                {m}
              </Row>
            ))}
          </Col>
        }
        className={styles.popover}
        overlayClassName={
          hoverIndex === 1 ? styles['overlay-hover'] : styles.overlay
        }
        visible={isVisible}
        mouseLeaveDelay={0.3}
        onVisibleChange={(v) => {
          if (!v) {
            setHoverIndex(-1)
          }
          setIsVisible(v)
        }}
      >
        <Col style={{ marginLeft: 20, whiteSpace: 'nowrap' }}>
          {data.patientName}（{getGenderName(data.patientSex)},{data.patientAge}
          周岁）（
          {data.insuranceCode == 'ZIFEI'
            ? '自费'
            : data.insuranceCode == '' || data.insuranceCode == null
            ? ' 未知险种'
            : '医保'}
          ）（{data?.outpatientNo}）{data.treatmentDoctorName}
          {moment(data.createTime, DateTimeFormat).format('HH:mm:ss')}
          <span
            style={{
              fontSize: '0.875rem',
              color: theme.tc3,
              marginLeft: 8,
            }}
          >
            {getRegistrationStateName(data.registrationState)}
          </span>
        </Col>
      </Popover>
    </Row>
  )
}

export const Home = (): ReactElement => {
  const history = useHistory()

  const allMenus = useSelector(selectMenus)
  const twoMenus = useSelector(selectTwoMenus);


  const hasAppointment = allMenus?.filter((v) => {
    return v?.name == '预约'
  })?.[0]

  const hasfollowUp = allMenus?.filter((v) => {
    return v?.name == '随访'
  })?.[0]

  const hasOutpatient = allMenus?.filter((v) => {
    return v?.name == '医生门诊'
  })?.[0]

  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const homeData = useSelector(selectHomedata)

  const count = useSelector(selectCountdata)

  const message = useSelector(selectMessagedata)

  const menus = useSelector(selectMenus)

  const [untreatedCount, setUntreatedCount] = useState(0)

  const [treatedCount, setTreatedCount] = useState(0)

  const [treatedList, setTreatedList] = useState<any[]>([])

  const [finishedList, setFinisedList] = useState<any[]>([])

  const [returnList, setReturnList] = useState<any[]>([])

  const [jycxMenu, setJycxMenu] = useState<Menu>()

  const [collapsed, setCollapsed] = useState<any>({})

  const [treatmentId, setTreatmentId] = useState('')

  const newMenu = menus?.slice(1, 6)

  const colors1: string[] = ['#2A99EE', '#B48EF1', '#0DD78D', '#FCA43A','#FFDD5E']

  const colors2: string[] = ['#E9F5FE', '#F7F3FE', '#E6FBF3', '#FFF6EB','#FFF6D6']

  const [hoverIndex, setHoverIndex] = useState(-1)

  const [menuSettingVisible, setMenuSettingVisible] = useState(false)

  const [patientInsurance, setPatientInsurance] = useState<any>()

  const [settingMenus, setSettingMenus] = useState<any>([])

  const [selectSettingMenus, setSelectSettingMenus] = useState<any>([])

  const [activeIconId, setActiveIconId] = useState('')

  const [currentMenus, setCurrentMenus] = useState<any>([])

  const [selectActiveId, setSelectActiveId] = useState('')

  const [catalogueUpdateVisible, setCatalogueUpdateVisible] = useState(false)

  const [noMessage, setNoMessage] = useState(0)

  const [open, setOpen] = useState(false)

  const flag = menus?.filter((v) => v?.path == '/patients')

  const [patientFlag, setPatientFlag] = useState(false)

  const [
    registerNoAppointModalVisible,
    setRegisterNoAppointModalVisible,
  ] = useState(false)

  const [remark, setRemark] = useState('')

  const [registerNoAppointId, setRegisterNoAppointId] = useState('')

  useEffect(() => {
    if (flag?.length) {
      setPatientFlag(true)
    } else {
      setPatientFlag(false)
    }
  }, [flag])

  useEffect(() => {
    // if (!menuSettingVisible) return
    const selectIds = currentMenus.map((v: any) => v.id.toString())
    const setMenus = menus.filter((v) => !selectIds.includes(v.id) && v.path != '/home');

    menus.map((v: any) => {
      if(v?.subMenus?.length > 0){
        v?.subMenus?.map((vv:Menu) =>{
          if(vv?.path === '/transactionQuery' ){
            setJycxMenu(vv);
            if(!selectIds.includes(vv.id)){
              setMenus.push(vv);
            }
          }

        })
      }
    })
    setSettingMenus(setMenus)
    getUserMenus()
  }, [menus, menuSettingVisible])

  useEffect(() => {
    const selectIds = selectSettingMenus.map((v: any) => v?.id.toString())
    if (selectIds.indexOf(activeIconId) > -1) {
      selectIds.splice(selectIds.indexOf(activeIconId), 1, selectActiveId)
      const list: any = []
      let menuWithJycx:any ;
      if(jycxMenu){
        menuWithJycx = [...menus,jycxMenu];
      }else{
        menuWithJycx = [...menus];
      }
      // const menuWithJycx = [...menus,jycxMenu];
      selectIds?.forEach((v: any) => {
        menuWithJycx.forEach((e:any) => {
          if (e?.id == v) {
            list.push(e)
          }
        })
      })
      setSettingMenus(
        menuWithJycx.filter((v:any) => !selectIds.includes(v?.id) && v?.path != '/home')
      )

      setSelectSettingMenus(list)
      setActiveIconId('')
    }
  }, [selectActiveId])

  const getUserMenus = () => {
    dispatch(getSelectMenuAsync())
      .then(unwrapResult)
      .then((res: any) => {
        let list = newMenu
        if (res.length) {
          list = res
        }
        setCurrentMenus(list)
        setSelectSettingMenus(list)
      })
  }

  useEffect(() => {
    setNoMessage(message.filter((v: any) => v.state == 0).length)
  }, [message])

  useEffect(() => {
    dispatch(getHomeList())
    dispatch(getCountList())
    dispatch(getMessageList())
  }, [dispatch])

  useEffect(() => {
    if (homeData.length > 0) {
      const untreated = (homeData[0] as any).registrationSummary
      setUntreatedCount(
        _.chain(untreated)
          .values()
          .reduce((s, a) => s + a.length, 0)
          .value()
      )
    }
    if (homeData.length > 1) {
      const treated = (homeData[1] as any).registrationSummary
      setTreatedCount(
        _.chain(treated)
          .values()
          .reduce((s, a) => s + a.length, 0)
          .value()
      )
      setTreatedList(treated['未结算(待收费)'] || [])
      setFinisedList(treated['已结算(已收费)'] || [])
      setReturnList(treated['已退费'] || [])
    }
  }, [homeData])

  const dashboardStyle: React.CSSProperties = {
    flex: '1 0',
    minWidth: '11.25rem',
    height: '6.25rem',
    borderRadius: theme.br,
    backgroundColor: theme.pn,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }

  const dashboardCountStyle: React.CSSProperties = {
    color: theme.tc1,
    minHeight: '1.5rem',
    fontSize: '1.875rem',
    lineHeight: '1.5rem',
  }

  const dashboardLabelStyle: React.CSSProperties = {
    fontSize: '1rem',
    lineHeight: '1rem',
    color: theme.tc3,
    marginTop: 14,
  }

  const render = (data: any, i: number, state: string) => {
    return (
      <>
        <HomeMenu
          key={`${state} - ${i}`}
          data={data}
          state={state}
          hoverIndex={hoverIndex}
          setHoverIndex={setHoverIndex}
          onAction={(m) => {
            const registrationId: any = data?.id
            switch (m) {
              case '开始就诊':
                if (
                  (data?.treatmentDepartmentName == '未指定科室' &&
                    data?.treatmentDoctorName == '未指定医生') ||
                  (data?.registrationDepartmentName == '未指定科室' &&
                    data?.registrationDoctorName == '未指定医生')
                ) {
                  setRemark(data?.remark?.replace('#', ','))
                  setRegisterNoAppointId(data.id)
                  setRegisterNoAppointModalVisible(true)
                } else {
                  dispatch(
                    traceRoute({
                      name: '医生门诊',
                      path: '/treatment',
                      query: `registrationId=${data.id}`,
                    })
                  )
                }

                break
              case '继续就诊':
              case '查看详情':
                dispatch(
                  traceRoute({
                    name: '医生门诊',
                    path: '/treatment',
                    query: `registrationId=${data.id}`,
                  })
                )

                break
              case '开始结算':
                if (data.treatmentId > 0) {
                  dispatch(
                    traceRoute({
                      name: '收费',
                      path: '/payment/cart',
                      query: `treatmentId=${data.treatmentId}`,
                    })
                  )
                }
                break
              case '病历打印':
                if (data.treatmentId > 0) {
                  setTreatmentId(data.treatmentId.toString())
                }
                break
              case '取消挂号':
                Modal.confirm({
                  title: `确定取消${data.patientName}的挂号?`,
                  onOk: () => {
                    dispatch(getCancel(data.id))
                  },
                })
                break
              case '医保卡':
                dispatch(
                  getTreatments({
                    registrationId,
                  })
                )
                  .then(unwrapResult)
                  .then((v: any) => {
                    setPatientInsurance(v.patientInsurance)
                    setOpen(true)
                  })
                break
            }
          }}
        />
      </>
    )
  }

  const shadow = '0px 4px 8px 0px rgba(203, 223, 255, 0.5)'

  const closeSettingModal = () => {
    setMenuSettingVisible(false)
    setActiveIconId('')
    setSelectActiveId('')
  }

  return (
    <Col
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: '#EBF0F5',
        alignItems: 'stretch',
      }}
    >
      <Row style={{ marginLeft: 20, marginRight: 20 }} wrap={false}>
        {hasOutpatient?.id && (
          <Col
            style={dashboardStyle}
            className={styles.top}
            onClick={() => {
              if (patientFlag) {
                dispatch(
                  traceRoute({
                    name: '医生门诊',
                    path: '/patients',
                    // (count as any).stationCategory == 1
                    //   ? '/patients'
                    //   : '/registration',
                  })
                )
              }
            }}
          >
            <Row align='middle' style={{ marginLeft: 24, cursor: 'pointer' }}>
              <div>
                <UserHomeOutlined1
                  mode='large'
                  style={{ fill: theme.c1 }}
                  className={styles.photo}
                />
              </div>
              <Col style={{ marginLeft: 10 }}>
                <div style={dashboardCountStyle} className={styles.number}>
                  {(count as any).totalCount === -1
                    ? 0
                    : (count as any).totalCount}
                </div>
                <div style={dashboardLabelStyle} className={styles.text}>
                  患者数量
                </div>
              </Col>
            </Row>
          </Col>
        )}
        {hasOutpatient?.id && (
          <Col
            style={{ ...dashboardStyle, marginLeft: 20 }}
            className={styles.top}
            onClick={() => {
              if (patientFlag) {
                dispatch(
                  traceRoute({
                    name: '医生门诊',
                    path: '/patients',
                    // (count as any).stationCategory == 1
                    //   ? '/patients'
                    //   : '/registration',
                  })
                )
              }
            }}
          >
            <Row align='middle' style={{ marginLeft: 24, cursor: 'pointer' }}>
              <UserHomeOutlined2
                mode='large'
                style={{ fill: theme.c1 }}
                className={styles.photo}
              />
              <Col style={{ marginLeft: 10 }}>
                <div style={dashboardCountStyle} className={styles.number}>
                  {(count as any).notCount === -1 ? 0 : (count as any).notCount}
                </div>
                <div style={dashboardLabelStyle} className={styles.text}>
                  待接诊
                </div>
              </Col>
            </Row>
          </Col>
        )}
        {hasOutpatient?.id && (
          <Col
            style={{ ...dashboardStyle, marginLeft: 20 }}
            className={styles.top}
            onClick={() => {
              if (patientFlag) {
                dispatch(
                  traceRoute({
                    name: '医生门诊',
                    path: '/patients',
                    state: {
                      receiveTag: 0,
                    },
                    // (count as any).stationCategory == 1
                    //   ? '/patients'
                    //   : '/registration',
                  })
                )
              }
            }}
          >
            <Row align='middle' style={{ marginLeft: 24, cursor: 'pointer' }}>
              <UserHomeOutlined3
                mode='large'
                style={{ fill: theme.c1 }}
                className={styles.photo}
              />
              <Col style={{ marginLeft: 10 }}>
                <div style={dashboardCountStyle} className={styles.number}>
                  {(count as any).firstVisitCount === -1
                    ? 0
                    : (count as any).firstVisitCount}
                </div>
                <div style={dashboardLabelStyle} className={styles.text}>
                  初诊
                </div>
              </Col>
            </Row>
          </Col>
        )}
        {hasOutpatient?.id && (
          <Col
            style={{ ...dashboardStyle, marginLeft: 20 }}
            className={styles.top}
            onClick={() => {
              if (patientFlag) {
                dispatch(
                  traceRoute({
                    name: '医生门诊',
                    path: '/patients',
                    state: {
                      receiveTag: 1,
                    },
                    // (count as any).stationCategory == 1
                    //   ? '/patients'
                    //   : '/registration',
                  })
                )
              }
            }}
          >
            <Row align='middle' style={{ marginLeft: 24, cursor: 'pointer' }}>
              <UserHomeOutlined4
                mode='large'
                style={{ fill: theme.c1 }}
                className={styles.photo}
              />
              <Col style={{ marginLeft: 10 }}>
                <div style={dashboardCountStyle} className={styles.number}>
                  {(count as any).returnVisitCount === -1
                    ? 0
                    : (count as any).returnVisitCount}
                </div>
                <div style={dashboardLabelStyle} className={styles.text}>
                  复诊
                </div>
              </Col>
            </Row>
          </Col>
        )}
        {hasAppointment?.id && (
          <Col
            style={{ ...dashboardStyle, marginLeft: 20 }}
            className={styles.top}
            onClick={() => {
              dispatch(
                traceRoute({
                  name: '预约',
                  path: '/appointment',
                  state: {
                    selectKey: '4',
                  },
                })
              )
            }}
          >
            <Row align='middle' style={{ marginLeft: 24, cursor: 'pointer' }}>
              <UserHomeOutlined5
                mode='large'
                style={{ fill: theme.c1 }}
                className={styles.photo}
              />
              <Col style={{ marginLeft: 10 }}>
                <div style={dashboardCountStyle} className={styles.number}>
                  {(count as any).appointmentCount === -1
                    ? 0
                    : (count as any).appointmentCount}
                </div>
                <div style={dashboardLabelStyle} className={styles.text}>
                  今日预约
                </div>
              </Col>
            </Row>
          </Col>
        )}
        {hasfollowUp?.id && (
          <Col
            style={{ ...dashboardStyle, marginLeft: 20 }}
            className={styles.top}
            onClick={() => {
              dispatch(
                traceRoute({
                  name: '随访',
                  path: '/followUp',
                })
              )
            }}
          >
            <Row align='middle' style={{ marginLeft: 24, cursor: 'pointer' }}>
              <UserHomeOutlined6
                mode='large'
                style={{ fill: theme.c1 }}
                className={styles.photo}
              />
              <Col style={{ marginLeft: 10 }}>
                <div style={dashboardCountStyle} className={styles.number}>
                  {(count as any).visitRecordCount === -1
                    ? 0
                    : (count as any).visitRecordCount}
                </div>
                <div style={dashboardLabelStyle} className={styles.text}>
                  今日待随访
                </div>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row
        style={{
          marginTop: 20,
          marginLeft: 20,
          marginRight: 20,
          display: 'flex',
        }}
        wrap={false}
      >
        <Col
          style={{
            flex: '1 0',
            minWidth: '36.25rem',
            height: '14rem',
            backgroundColor: theme.pn,
            borderRadius: theme.br,
            marginRight: 10,
            boxShadow: shadow,
          }}
        >
          <div
            className={styles.menuSetting}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setMenuSettingVisible(true)
            }}
          >
            设置常用菜单
          </div>
          <Label
            label='常用入口'
            style={{ marginLeft: 10, fontSize: '1.125rem' }}
          />
          <Divider style={{ margin: 0, color: theme.c3 }} />
          <Row
            style={{ marginTop: 10, marginRight:20, marginLeft: 20 }}
            justify='space-around'
            wrap={false}
          >
            {currentMenus.map((m: any, i: number) => (
              <Col
                key={m.id}
                className={styles.entry}
                style={{
                  display: 'flex',
                  borderRadius: theme.br,
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingTop: 18,
                  paddingBottom: 10,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  dispatch(traceRoute({ ...m, state: { backHome: true } }))
                }}
              >
                <div
                  style={{
                    width:80,
                    height:80,
                    borderRadius: 999,
                    backgroundColor: colors2[i],
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <div
                    style={{
                      width: 56,
                      height: 56,
                      borderRadius: 999,
                      backgroundColor: colors1[i],
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      icon={m.icon}
                      mode='normal'
                      style={{ color: theme.tt, fill: theme.tt }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    fontSize: '1.125rem',
                    color: theme.tc2,
                    lineHeight: '1.125rem',
                    marginTop: 28,
                  }}
                >
                  {m.name}
                </div>
              </Col>
            ))}

          </Row>
        </Col>
        <Col
          style={{
            flex: '1 0',
            minWidth: '36.25rem',
            backgroundColor: theme.pn,
            borderRadius: theme.br,
            marginLeft: 10,
            boxShadow: shadow,
          }}
        >
          {!!noMessage && (
            <div className={styles.menuSetting} style={{ cursor: 'pointer' }}>
              未读 <span style={{ color: 'red' }}>{noMessage}</span>
            </div>
          )}
          <Label
            label='通知中心'
            style={{ marginLeft: 10, fontSize: '1.125rem' }}
          />
          <Divider style={{ margin: 0, color: theme.c3, marginBottom: 4 }} />
          <div
            style={{ height: '11rem', overflow: 'auto' }}
            className={styles.popoverContent}
          >
            {message.map((m: any) => (
              <Row
                key={m.id}
                style={{
                  height: '2.75rem',
                  paddingLeft: 20,
                  paddingRight: 20,
                  cursor: m.state === 0 ? 'pointer' : undefined,
                }}
                align='middle'
                wrap={false}
                onClick={() =>
                  m.state == 0 && dispatch(getState({ messageReadId: m.id }))
                }
                className={styles.message}
              >
                {m.innerUrl == '/recentTime' || m.innerUrl == '/stockfloor' || m.innerUrl.indexOf('/insuranceDirectory?') > -1 ? (
                  <Col
                    onClick={() => {
                      if (m.innerUrl.indexOf('/insuranceDirectory?') > -1) {
                        dispatch(
                          traceRoute({
                            name: '产品中心',
                            path: m.innerUrl,
                          })
                        )
                      } else {
                        history.push({
                          pathname: m.innerUrl,
                          state: { station: '2' },
                        })
                      }
                    }}
                    style={{
                      flex: 1,
                      color: m.state === 1 ? theme.tc3 : theme.tc1,
                      fontSize: '1rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginRight: 20,
                      cursor: 'pointer',
                    }}
                  >
                    <div>{m.title}</div>
                  </Col>
                ) : (
                  <Col
                    style={{
                      flex: 1,
                      color: m.state === 1 ? theme.tc3 : theme.tc1,
                      fontSize: '1rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      marginRight: 20,
                      cursor: 'pointer',
                    }}
                  >
                    <Popover
                      placement='top'
                      content={
                        <div style={{ width: '350px' }}>
                          <p style={{ marginBottom: '0px' }}>
                            {m.content}
                            <br />
                            {m.url != '' && (
                              <a
                                href={m.url}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                {m.url}
                              </a>
                            )}
                            {m.innerUrl == 'hyd.catalogupdate' && (
                              <a
                                href='javascript:;'
                                onClick={() => setCatalogueUpdateVisible(true)}
                              >
                                查看与本机构有关的目录变化
                              </a>
                            )}
                            <a
                              style={{ color: '#40a9ff' }}
                              onClick={() => {
                                dispatch(
                                  traceRoute({
                                    name: '产品中心',
                                    path: m.innerUrl,
                                    query: m.innerUrl,
                                  })
                                )
                              }}
                            >
                              {m.hyperlinkText}
                            </a>
                          </p>
                        </div>
                      }
                      trigger='click'
                      zIndex={98}
                    >
                      <div>{m.title}</div>
                    </Popover>
                  </Col>
                )}
                <Col
                  style={{
                    color: theme.tc3,
                    fontSize: '1rem',
                    whiteSpace: 'nowrap',
                    // marginRight: 4,
                  }}
                >
                  {m?.effectiveTime?.slice(0, 10)}
                </Col>
                <Col
                  style={{
                    color: m.state === 1 ? theme.tc3 : theme.e,
                    fontSize: '0.75rem',
                    whiteSpace: 'nowrap',
                    marginLeft: '10px',
                    width: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {m.state === 0 ? '未读' : m?.state === 1 ? '已读' : '-'}
                </Col>
              </Row>
            ))}
          </div>
        </Col>
      </Row>
      <div
        style={{
          flex: 1,
          minWidth: 'calc(72rem + 60px)',
          overflow: 'hidden',
        }}
      >
        <Row
          style={{
            height: '100%',
            marginLeft: 20,
            marginRight: 20,
            marginTop: 20,
            overflowX: 'visible',
          }}
          wrap={false}
        >
          <Col
            style={{
              flex: '1 0',
              minWidth: '36.25rem',
              height: 'calc(100% - 20px)',
              backgroundColor: theme.pn,
              borderRadius: theme.br,
              marginRight: 10,
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxShadow: shadow,
            }}
          >
            <Label
              label={`未就诊（${untreatedCount}）`}
              style={{ marginLeft: 10, fontSize: '1.125rem', flexShrink: 0 }}
            />
            <Divider style={{ margin: 0, color: theme.c3 }} />
            <div style={{ overflow: 'auto' }}>
              {homeData.length > 0 &&
                _.chain((homeData[0] as any).registrationSummary)
                  .mapValues((v, k) => (
                    <Col key={k}>
                      <Row
                        key={k}
                        style={{
                          color: theme.tc1,
                          paddingLeft: 20,
                          lineHeight: '2.5rem',
                          fontSize: '1rem',
                          backgroundColor: '#F3F7FD',
                          cursor: 'pointer',
                        }}
                        wrap={false}
                        align='middle'
                        onClick={() =>
                          setCollapsed({ ...collapsed, [k]: !collapsed[k] })
                        }
                      >
                        {k}
                        {collapsed[k] ? (
                          <CaretRightFilled
                            style={{ color: theme.tc3, marginLeft: 10 }}
                          />
                        ) : (
                          <CaretDownFilled
                            style={{ color: theme.tc3, marginLeft: 10 }}
                          />
                        )}
                      </Row>
                      {!collapsed[k] &&
                        v.map((r: any, i: number) => render(r, i, '未就诊'))}
                    </Col>
                  ))
                  .values()
                  .value()}
            </div>
          </Col>
          <Col
            style={{
              flex: '1 0',
              minWidth: '.25rem',
              height: 'calc(100% - 20px)',
              backgroundColor: theme.pn,
              borderRadius: theme.br,
              marginLeft: 10,
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              boxShadow: shadow,
            }}
          >
            <Label
              label={`已就诊（${treatedCount}）`}
              style={{ marginLeft: 10, fontSize: '1.125rem', flexShrink: 0 }}
            />
            <Divider style={{ margin: 0, color: theme.c3 }} />
            <div style={{ flex: 1, overflow: 'auto' }}>
              {(treatedList.length >= 0 || finishedList.length > 0 || returnList.length > 0) &&
                _.chain([treatedList, finishedList,returnList])
                  .map((v, ci) => (
                    <Col key={ci}>
                      <Row
                        key={ci === 0 ? '未结算' : ci === 1 ? '未结算' : '已退费'}
                        style={{
                          color: theme.tc1,
                          paddingLeft: 20,
                          lineHeight: '2.5rem',
                          fontSize: '1rem',
                          backgroundColor: '#F3F7FD',
                          cursor: 'pointer',
                        }}
                        align='middle'
                        wrap={false}
                        onClick={() =>
                            {console.log(v,ci)
                          setCollapsed({ ...collapsed, [ci]: !collapsed[ci] })}
                        }
                      >
                        {ci === 0
                          ? '未结算' + '（' + treatedList?.length + '）' : ci===1?
                           '已结算' + '（' + finishedList?.length + '）':   '已退费' + '（' + returnList?.length + '）'}
                        {collapsed[ci] ? (
                          <CaretRightFilled
                            style={{ color: theme.tc3, marginLeft: 10 }}
                          />
                        ) : (
                          <CaretDownFilled
                            style={{ color: theme.tc3, marginLeft: 10 }}
                          />
                        )}
                      </Row>
                      {!collapsed[ci] &&
                        v.map((r: any, i: number) =>
                          render(r, i, ci === 0 ? '未结算' : ci === 1 ? '已结算' : '已退费')
                        )}
                    </Col>
                  ))
                  .value()}
            </div>
          </Col>
        </Row>
        <PrintTemplateModal
          templateCategory={PrintTemplateCategory.Zds}
          visible={!!treatmentId}
          treatmentId={treatmentId}
          onCancel={() => setTreatmentId('')}
        />
        <Modal
          width={890}
          style={{ top: 200 }}
          footer={null}
          title='设置常用菜单'
          visible={menuSettingVisible}
          destroyOnClose
          onCancel={closeSettingModal}
          className={styles.menuSetting}
        >
          <div
            onClick={() => {
              setActiveIconId('')
            }}
          >
            <header>全部菜单列表</header>
            <Row
              style={{
                height: 240,
                overflow: 'auto',
              }}
            >
              {settingMenus.map((m: any, i: number) => (
                <Col
                  key={m.id}
                  className={styles.entry}
                  style={{
                    display: 'flex',
                    borderRadius: theme.br,
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (!activeIconId) return
                    setSelectActiveId(m.id.toString())
                  }}
                >
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: 999,
                      backgroundColor: '#E9F5FE',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 18,
                      marginRight: 18,
                      cursor: 'pointer',
                    }}
                  >
                    <div
                      style={{
                        width: 56,
                        height: 56,
                        borderRadius: 999,
                        backgroundColor: '#2A99EE',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon
                        icon={m.icon}
                        mode='normal'
                        style={{ color: '#FFF', fill: '#FFF' }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: '1.125rem',
                      color: theme.tc2,
                      lineHeight: '1.125rem',
                      // marginTop: 28,
                    }}
                  >
                    {m.name}
                  </div>
                </Col>
              ))}
            </Row>
            <Row justify='center' style={{ margin: '20px 0 0', height: 25 }}>
              {activeIconId ? '请选择一个要替换的菜单' : ''}
            </Row>
            <header>您的常用菜单</header>
            <Row justify='center' style={{ margin: '10px 0', height: 25 }}>
              {activeIconId ? '' : '请点击要替换的菜单'}
            </Row>
            <Row
              justify='space-around'
              style={{
                padding: ' 0 120px ',
              }}
            >
              {selectSettingMenus.map((m: any, i: number) => (
                <Col
                  key={m.id}
                  className={styles.entry}
                  style={{
                    display: 'flex',
                    borderRadius: theme.br,
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: m.id == activeIconId ? '#E9F5FE' : '',
                    paddingBottom: 10,
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setActiveIconId(m.id.toString())
                  }}
                >
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: 999,
                      backgroundColor: colors2[i],
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 18,
                      marginRight: 18,
                      cursor: 'pointer',
                    }}
                  >
                    <div
                      style={{
                        width: 56,
                        height: 56,
                        borderRadius: 999,
                        backgroundColor: colors1[i],
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon
                        icon={m.icon}
                        mode='normal'
                        style={{ color: theme.tt, fill: theme.tt }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: '1.125rem',
                      color: theme.tc2,
                      lineHeight: '1.125rem',
                    }}
                  >
                    {m.name}
                  </div>
                </Col>
              ))}
            </Row>
            <Row justify='end'>
              <Space>
                <div className={styles.cancelButton}>
                  <Button
                    type='primary'
                    ghost
                    onClick={() => {
                      closeSettingModal()
                    }}
                  >
                    取消
                  </Button>
                </div>
                <Button
                  type='primary'
                  onClick={() => {
                    const menuIds = selectSettingMenus.map((v: any) => v.id)
                    dispatch(saveSelectMenuAsync(menuIds))
                      .then(unwrapResult)
                      .then(() => {
                        getUserMenus()
                        notification.success({
                          message: '保存成功',
                        })
                        setMenuSettingVisible(false)
                      })
                  }}
                >
                  确定
                </Button>
              </Space>
            </Row>
          </div>
        </Modal>
        <CatalogueUpdate
          visible={catalogueUpdateVisible}
          onCancel={() => setCatalogueUpdateVisible(false)}
        />
      </div>
      <Modal
        className={styles.moreYBModal}
        title='更多医保信息'
        visible={open}
        closeIcon={null}
        maskClosable
        footer={null}
        onOk={() => {
          setOpen(false)
        }}
        onCancel={() => {
          setOpen(false)
        }}
      >
        <div>
          <div>
            <p>姓名：{patientInsurance?.name}</p>
            <p style={{ fontWeight: 'bold' }}>
              个账余额：{patientInsurance?.zhye}
            </p>
            <p style={{ fontWeight: 'bold' }}>
              当年账户余额：{patientInsurance?.dnzhye}
            </p>
            <p style={{ fontWeight: 'bold' }}>
              历年账户余额：{patientInsurance?.lnzhye}
            </p>
            <p>工作单位：{patientInsurance?.brdw}</p>
            <p>参保险种：{patientInsurance?.cbxzmc}</p>
            <p>人员类型：{patientInsurance?.memberTypemc}</p>
          </div>
          <Row className={styles.moreYBButtonMain}>
            <Col span={24} className={styles.moreYBButton}>
              <Button
                type='primary'
                onClick={() => {
                  setOpen(false)
                }}
              >
                确定
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      <RegisterNoAppointModal
        id={registerNoAppointId}
        remark={remark}
        visible={registerNoAppointModalVisible}
        onCancel={() => {
          setRegisterNoAppointModalVisible(false)
        }}
        onOk={() => {
          dispatch(getHomeList())
          setRegisterNoAppointModalVisible(false)
        }}
      />
    </Col>
  )
}
