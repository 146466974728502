import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import {Request} from "../../../services/request";
import {API_PREFIX} from "../../../services/constant";
import {SaveRecipeMaterialsParams} from "../../../services/recipe";


export interface TraceCode {
  recipeItemId: number
  treatmentId: number
  traceCode : string
}
export interface param {
  traceCodes : TraceCode[]
  treatmentId : number
}

export const saveTraceCodesAsync = createAsyncThunk<void,param , RootThunkApi<void>>(
  'traceCode/saveTraceCodesAsync',
  async (param, api) => {
    return sendAsync(apiSaveTraceCodesAsync(param), api)
  }
)


export function apiSaveTraceCodesAsync(
  params: param
): Request {
  return {
    url: `${API_PREFIX}/blade-store/traceCode/submitTraceCodes`,
    method: 'POST',
    body: params.traceCodes,
    params: {
      treatmentId: params.treatmentId,
    }
  }
}
