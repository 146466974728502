/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-15 13:46:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-10 16:32:35
 */
export function isValidMobileNumber(num: string): boolean {
  return num.length === 11 && (num[0] === '1' || num[0] === '5')
}

export function getAge(age = 0, month = 0, postfix = ''): string {
  if (age > 0) {
    return `${age}${postfix}`
  }
  if (month <= 0) {
    return `-`
  }
  return `${month}个月`
}
export function getAgeName(age: number, month: number): string {
  if (age > 0) {
    return `${age}y`
  }
  if (month <= 0) {
    return `0d`
  }
  return `${month}m`
}

// 输入框前后去空格
export const stringTrim = (e: any) => {
  return e.target.value.replace(/(^\s*)|(\s*$)/g, '')
}

// 获取sessionStorage 里面的值
export const getSessionStorageData = (value: string): any => {
  return JSON.parse(sessionStorage.getItem(value) || '{}')
}

// 图片转base64 file
export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      resolve(reader.result)
    }
    reader.onerror = (error) => reject(error)
  })
}

// 数组去重
export const tempArr = (arr: any, u_key: string) => {
  if (!arr.length) return []
  const result: any = []
  result[0] = arr[0]
  arr.forEach((meta_item: any, i: number) => {
    //声明计数变量，如果源数组中的一个对象和result结果数组中的所有对象不同，就push
    let num = 0
    result.forEach((r_item: any) => {
      if (meta_item[u_key] !== r_item[u_key]) {
        num++
      }
      if (num === result.length) {
        result.push(meta_item)
      }
    })
  })
  return result
}

// 是否为图片
export const imagesType = ['png', 'tiff', 'jpg', 'jpeg', 'bmp', 'heic', 'webp']
