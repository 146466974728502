/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-12 18:10:16
 */
import { Space, Switch, TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { DateTimeFormat } from '../../../../models/datetime'

type ActionType = 'edit' | 'stateEdit'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '科室',
      dataIndex: 'departmentName',
      align: 'center',
      ellipsis: true,
      fixed: 'left',
      render: (_, r) => {
        return r.departmentName ? r.departmentName : '-'
      },
    },

    {
      title: '医生',
      dataIndex: 'doctorName',
      align: 'center',
      ellipsis: true,
      fixed: 'left',
      render: (_, r) => {
        return r.doctorName ? r.doctorName : '-'
      },
    },
    {
      title: '患者姓名',
      dataIndex: 'patientName',
      align: 'center',
      ellipsis: true,
      fixed: 'left',
      render: (_, r) => {
        return r.patientName ? r.patientName : '-'
      },
    },
    // {
    //   title: '结算时间',
    //   align: 'center',
    //   width:'10rem',
    //   ellipsis: true,
    //   render: (_: any, t: any) => {
    //     return t.payTime ? moment(t.payTime).format(DateTimeFormat) : ''
    //   },
    // },
    // {
    //   title: '初复诊',
    //   dataIndex: 'adeptAt',
    //   align: 'center',
    //   ellipsis: true,
    //   render: (_, r) => {
    //     return r.adeptAt ? r.adeptAt : '-'
    //   },
    // },

    {
      title: '西药费',
      dataIndex: 'westernMedicineFee',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.westernMedicineFee > 0
            ? r.westernMedicineFee?.toFixed(2)
            : '0.00'
      },
    },
    {
      title: '中成药',
      dataIndex: 'patentChineseMedicineFee',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.patentChineseMedicineFee > 0
            ? r.patentChineseMedicineFee?.toFixed(2)
            : '0.00'
      },
    },
    {
      title: '中草药',
      dataIndex: 'herbalMedicineFee',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.herbalMedicineFee > 0
            ? r.herbalMedicineFee?.toFixed(2)
            : '0.00'
      },
    },
    {
      title: '其他药品',
      dataIndex: 'otherMedicineFee',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.otherMedicineFee > 0 ? r.otherMedicineFee?.toFixed(2) : '0.00'
      },
    },

    {
      title: '挂号费',
      dataIndex: 'registrationAmount',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.registrationAmount > 0
          ? r.registrationAmount?.toFixed(2)
          : '0.00'
      },
    },
    {
      title: '代煎费',
      dataIndex: 'potionMoney',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.potionMoney > 0 ? r.potionMoney?.toFixed(2) : '0.00'
      },
    },
    {
      title: '诊察费',
      dataIndex: 'diagnosisFee',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.diagnosisFee > 0 ? r.diagnosisFee?.toFixed(2) : '0.00'
      },
    },
    {
      title: '检查费',
      dataIndex: 'examinationFee',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.examinationFee > 0 ? r.examinationFee?.toFixed(2) : '0.00'
      },
    },
    {
      title: '治疗费',
      key: 'tr05',
      align: 'right',
      render: (_, r) => r.tr05?.toFixed(2),
    },
    {
      title: '化验费',
      key: 'tr07',
      align: 'right',
      render: (_, r) => r.tr07?.toFixed(2),
    },


    {
      title: '手术费',
      key: 'tr08',
      align: 'right',
      render: (_, r) => r.tr08?.toFixed(2),
    },
    {
      title: '放射费',
      key: 'tr09',
      align: 'right',
      render: (_, r) => r.tr09?.toFixed(2),
    },
    {
      title: '输血费',
      key: 'tr10',
      align: 'right',
      render: (_, r) => r.tr10?.toFixed(2),
    },
    {
      title: '输氧费',
      key: 'tr11',
      align: 'right',
      render: (_, r) => r.tr11?.toFixed(2),
    },
    {
      title: 'B超费',
      key: 'tr23',
      align: 'right',
      render: (_, r) => r.tr23?.toFixed(2),
    },
    {
      title: '心电图费',
      key: 'tr24',
      align: 'right',
      render: (_, r) => r.tr24?.toFixed(2),
    },
    {
      title: '透析费',
      key: 'tr26',
      align: 'right',
      render: (_, r) => r.tr26?.toFixed(2),
    },
    {
      title: '化疗费',
      key: 'tr27',
      align: 'right',
      render: (_, r) => r.tr27?.toFixed(2),
    },
    {
      title: '麻醉费',
      key: 'tr28',
      align: 'right',
      render: (_, r) => r.tr28?.toFixed(2),
    },


    {
      title: '注射费',
      key: 'tr25',
      align: 'right',
      render: (_, r) => r.tr25?.toFixed(2),
    },
    {
      title: '其他费',
      dataIndex: 'otherMedicalFee',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.otherMedicalFee > 0 ? r.otherMedicalFee?.toFixed(2) : '0.00'
      },
    },

    {
      title: '合计',
      dataIndex: 'totalCost',
      align: 'center',
      ellipsis: true,
      fixed: 'right',
      render: (_, r) => {
        return r.totalCost > 0 ? r.totalCost?.toFixed(2) : '0.00'
      },
    },
  ]
}
