/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-15 11:47:31
 * @LastEditors: linxi
 * @LastEditTime: 2024-05-31 17:14:25
 */
import { Material } from "../models/material";
import { API_PREFIX } from "./constant";
import { ContentType, MultiPartFormData, Request } from "./request";

export interface SetMealPageParams {
  current?: number
  category?: any
  name?: string
  size?: number
  detailFlag?: number
  state?: number | string
}

export interface SetMeal {
  id?: number;
  category?: number;
  flag?: number;
  mnemonicCode: string;
  name: string;
  count: number;
  total: number;
  createTime: string;
  state?: number;
  status?: number;
  tenantId?: number;
  useArea?: number;
  useDepartmentId?: number;
  useDepartmentName?: string;
  useUserId?: number;
  useUserName?: string;
}

export interface SetMealDurgBody {
  accountUnit?: string;
  catalogId?: number;
  count?: number;
  dose?: string;
  doseUnit?: string;
  id?: number;
  name?: string;
  normalFrequency?: string;
  normalUsemathod?: string;
  retailPrice?: number;
  groupNumber?: number;
  sort?: number;
  source?: number;
  spec?: string;
  status?: number;
  tenantGoodsComboId?: number;
  tenantId?: number;
}

export function setmealPage(params: SetMealPageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/goodscombo/page`,
    method: "GET",
    params,
  };
}

export function setmealUpdateSortAndGroupNumber(
  materials: Material[]
): Request {
  return {
    url: `${API_PREFIX}/blade-store/goodscomboitem/sortbatch`,
    method: "POST",
    body: materials.map((m) => ({
      id: m.id,
      // sort: m.sort,
      groupNumber: m.groupNumber,
    })),
  };
}


export interface updateSortNumberParams {
  id?: number;
  sort?:any
}

export function updateSortNumber(body:updateSortNumberParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/goodscomboitem/sortSet`,
    method: 'POST',
    body
  }
}



export function setmealDetail(id: number | string): Request {
  return {
    url: `${API_PREFIX}/blade-store/goodscombo/detail`,
    method: "GET",
    params: { id },
  };
}

export function ctrlSetmeal(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/goodscombo/logoutOrEnable`,
    method: "POST",
    params: {
      id,
    },
  };
}

export function setmealSubmit(body: SetMeal): Request {
  return {
    url: `${API_PREFIX}/blade-store/goodscombo/submit`,
    method: "POST",
    body,
  };
}

export function setmealList(id: number | string): Request {
  return {
    url: `${API_PREFIX}/blade-store/goodscomboitem/list`,
    method: "GET",
    params: {
      tenantGoodsComboId: id,
    },
  };
}
export function setmealListReplace(id: number | string): Request {
  // 医嘱处方-添加套餐接口替换 goodscomboitem/list
  return {
    url: `${API_PREFIX}/blade-store/goodscomboitem/listSplitTag`,
    method: 'GET',
    params: {
      tenantGoodsComboId: id,
    },
  }
}

export function SetmealDurgDetail(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/goodscomboitem/detail`,
    method: "GET",
    params: {
      id,
    },
  };
}

export function setmealDurgSubmit(body: SetMealDurgBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/goodscomboitem/submit`,
    method: "POST",
    body,
  };
}

export function setmealDurgRemove(ids: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/goodscomboitem/remove`,
    method: "POST",
    params: {
      ids,
    },
  };
}


export interface ImportExcelParams {
  file?: any
  inventoryReceiptsId:any
}


export function getSetMealImportExcel(params: ImportExcelParams): Request {
  return {
      url: `${API_PREFIX}/blade-store/goodscombo/importExcell`,
      headers: {
          [ContentType]: MultiPartFormData,
      },
      method: 'POST',
      params,
  }
}