import {
  Button,
  Col,
  Collapse,
  Form,
  Modal,
  Row,
  Space,
  Table,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CaretUpFilled } from '@ant-design/icons'
import _, { divide } from 'lodash'
import styles from '../formulas/formula.module.css'
import { selectFarmulaDetail } from './formulaSlice'
import { getRecipeCategoryName } from '../../../../models/recipe'
import { PrintTemplateCategory } from '../../../../models/print'
import { RootDispatch } from '../../../../app/store'
import { PrintTemplateModal } from '../../../print/PrintTemplateModal'
import moment from 'moment'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
} from '../../../../models/datetime'
import { getPrintZXDData } from '../../../treatment/editor/recipeEditorSlice'
import { unwrapResult } from '@reduxjs/toolkit'

export const ModelList = (props: {
  treatmentId?: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const success = () => {
    message.success('操作成功')
  }
  const { Panel } = Collapse

  const [recipeId, setRecipeId] = useState<any>()

  const dispatch = useDispatch<RootDispatch>()

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const farmulaDetail = useSelector(selectFarmulaDetail)

  const [farmulaDetails, setFarmulaDetails] = useState<any[]>([])

  const [printZXDData, setPrintZXDData] = useState<any>()

  const treatmentId = props.treatmentId

  const [form] = Form.useForm()

  useEffect(() => {
    setFarmulaDetails(farmulaDetail)
  }, [farmulaDetail])

  
  const getReciptPrintList = (recipeId?:any) => {
    dispatch(getPrintZXDData(recipeId))
      .then(unwrapResult)
      .then((v: any) => {
        setPrintZXDData(v)
      })
  }
  return (
    <>
      <Modal
        className={styles.modal}
        title='处方执行信息'
        width='1035px'
        visible={props.visible}
        footer={null}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          props.onCancel()
        }}
      >
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '600px',
          }}
        >
          {farmulaDetails &&
            farmulaDetails.map((b: any) => (
              <Row
                key={b.index}
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p>
                  {b.registration.patientName} (
                  {b.registration.patientSex == 1
                    ? '男，'
                    : b.registration.patientSex == 2
                    ? '女，'
                    : '未知，'}
                  {b.registration.patientAge}周岁)
                  {b.registration.registrationCategory == 0 ? '自费' : '医保'}
                </p>
              </Row>
            ))}
          <div
            style={{
              overflow: 'scroll',
              minHeight: '600px',
            }}
          >
            <Space direction='vertical' className={styles.space}>
              <Collapse
                className={styles.head}
                collapsible='header'
                expandIcon={({ isActive }) => (
                  <CaretUpFilled
                    rotate={isActive ? 90 : 0}
                    style={{ marginBottom: '10px', color: '#666666' }}
                  />
                )}
                expandIconPosition='right'
              >
                {farmulaDetails &&
                  farmulaDetails.map((n: any) => (
                    <>
                      {n.recipeExecuteShortVOList.map((v: any) => (
                        <Panel
                          className={styles.content}
                          style={{ marginBottom: '10px' }}
                          key={v.index}
                          header={
                            <Row
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                height: '100%',
                                width: '906px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  height: '100%',
                                }}
                              >
                                <span
                                  style={{ border: 'none', marginRight: '5px' }}
                                >
                                  {moment(v.recipeTime).format(
                                    DateTimeFormatSimple
                                  )}
                                </span>
                                <span
                                  style={{
                                    border: 'none',
                                    fontWeight: 'bold',
                                    color: '#333333',
                                    marginRight: '5px',
                                    fontSize: '16px',
                                  }}
                                >
                                  {getRecipeCategoryName(v.category)}
                                </span>
                                <span>编号：{v.recipeId}</span>
                              </div>
                              <Button
                                type='primary'
                                style={{ marginLeft: '10px' }}
                                onClick={() => {
                                  const recipeId = v.recipeId
                                   setRecipeId(recipeId)
                                  if (v.recipeId) {
                                    getReciptPrintList(v.recipeId)
                                  }
                                  setIsPrintModalVisible(true)
                                }}
                              >
                                执行单打印
                              </Button>
                            </Row>
                          }
                        >
                          <Collapse
                            className={styles.header}
                            ghost
                            style={{ background: '#F4F8FB', padding: 0 }}
                            expandIcon={({ isActive }) => (
                              <CaretUpFilled
                                rotate={isActive ? 90 : 0}
                                style={{ color: '#666666' }}
                              />
                            )}
                            expandIconPosition='right'
                          >
                            <Panel
                              key={v.index}
                              className={styles.main}
                              header={
                                <Row
                                  style={{
                                    width: '906px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      height: '100%',
                                    }}
                                  >
                                    <Col style={{ width: '752px' }}>
                                      {getRecipeCategoryName(v.category)}
                                      处方单，处方编号：
                                      {v.recipeId}
                                    </Col>
                                    <Col
                                      style={{
                                        color: '#027AFF',
                                        fontSize: '16px',
                                      }}
                                    >
                                      单击查看处方信息
                                    </Col>
                                  </div>
                                </Row>
                              }
                            >
                              <Table
                                className={styles.table}
                                pagination={false}
                                size='small'
                                bordered
                                dataSource={v.recipeItemExecuteVOList}
                                rowKey={(t: any) => t.id}
                                columns={[
                                  {
                                    title: '序号',
                                    key: 'order',
                                    align: 'center',
                                    render: (_1, _2, index) =>
                                      (index + 1).toString(),
                                  },
                                  {
                                    title: '组号',
                                    dataIndex: 'groupNumber',
                                    align: 'center',
                                    key: 'groupNumber',
                                  },
                                  {
                                    title: '处方名称',
                                    dataIndex: 'name',
                                    align: 'center',
                                    key: 'name',
                                  },
                                  {
                                    title: '药品用法',
                                    dataIndex: 'normalUsemethod',
                                    align: 'center',
                                    key: 'normalUsemethod',
                                  },
                                  {
                                    title: '数量',
                                    dataIndex: 'drugCount',
                                    align: 'center',
                                    key: 'drugCount',
                                  },
                                  {
                                    title: '每次用量',
                                    dataIndex: 'normalDose',
                                    align: 'center',
                                    key: 'normalDose',
                                  },
                                  {
                                    title: '频次',
                                    dataIndex: 'normalFrequency',
                                    align: 'center',
                                    key: 'normalFrequency',
                                  },
                                  {
                                    title: '执行状态',
                                    dataIndex: 'state',
                                    align: 'center',
                                    key: 'state',
                                    render: (_, t) => {
                                      switch (t.state) {
                                        case 0:
                                          return '未执行'
                                        case 1:
                                          return '已执行'
                                        default:
                                          break
                                      }
                                    },
                                  },
                                  {
                                    title: '执行人',
                                    dataIndex: 'executeUserName',
                                    align: 'center',
                                    key: 'executeUserName',
                                  },
                                  {
                                    title: '执行时间',
                                    dataIndex: 'executeTime',
                                    align: 'center',
                                    key: 'executeTime',
                                    render: (_, t) => {
                                      const executeTime: any = t.executeTime.substring(
                                        0,
                                        10
                                      )
                                      return executeTime
                                    },
                                  },
                                ]}
                              ></Table>
                            </Panel>
                          </Collapse>
                        </Panel>
                      ))}
                    </>
                  ))}
              </Collapse>
            </Space>
          </div>
        </Col>
        <Row gutter={20} style={{ marginTop: '10px' }}>
          <Col span={20}>
            {/* <Button
              type='primary'
              style={{ float: 'right' }}
              onClick={() => {
                setIsPrintModalVisible(true)
              }}
            >
              打印执行单
            </Button> */}
          </Col>
          <Col span={1}>
            <Button
              type='primary'
              onClick={() => {
                form.resetFields()
                props.onCancel()
              }}
            >
              关闭(ESC)
            </Button>
          </Col>
        </Row>
      </Modal>
      <PrintTemplateModal
        recipeId={recipeId}
        printZXDData={printZXDData}
        pagePrintFlag={'zxdPrint'}
        templateCategory={PrintTemplateCategory.Zxd}
        visible={isPrintModalVisible}
        treatmentId={treatmentId}
        onCancel={() => setIsPrintModalVisible(false)}
      />
    </>
  )
}
