/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-10 11:20:25
 * @LastEditors: linxi
 * @LastEditTime: 2024-01-08 14:14:47
 */
import { Button, Col, Modal, notification, Pagination, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import styles from '../InventoryStock.module.css'
import { AccountBookOutlined, DeleteOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectModalCurrent,
  selectModalToatl,
  setModalCurrent,
} from './InventStockModalSlice'
import { DataType } from '../../putstorage/putstorage'
import { TableRowSelection } from 'antd/lib/table/interface'
import { InventoryModalCloumns } from './columns'
import { InventModalQuery } from './query'
import {
  Removestoredetail,
  RemovestoredetailAsync,
  selectorPutstoreData,
} from '../../putstorelist/putstorelistSlice'
import {
  detailPutstorage,
  savelistPutstorageAsync,
  selectDetail,
} from '../../putstorage/putstorageSlice'
import { RootDispatch } from '../../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { EditableList } from '../../../compnents/list/EditableList'
import { AccountOutlined, PrintOutlined } from '../../../compnents/icons/Icons'
import { PrintFrame, PrintFrameRef } from '../../print/PrintFrame'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../../models/datetime'
import { getStockDataAsync } from '../InventStockSlice'
import { useLocation } from 'react-router-dom'
import { printBodyInventoryCheckDetail } from '../../../models/InventoryCheckPrint'
import { selectTenantName, selectUserName } from '../../../app/applicationSlice'

export const InventStockModal = (props: {
  visible: boolean
  onOk: () => void
  onCancel: () => void
  RegisterOk: () => void
  RegisterCancel: () => void
}) => {
  const printRef = useRef<PrintFrameRef>(null)

  const location: any = useLocation()

  const tableRef = useRef(null)

  const dispatch = useDispatch<RootDispatch>()

  const [size, setSize] = useState(5)

  const [Ids, setIds] = useState('')

  const [selectionType] = useState<'checkbox' | 'radio'>('checkbox')

  const detail: any = useSelector(selectDetail)

  const [queries, setQueries] = useState<any>()

  const [StockList, setStockList] = useState<any>()

  const [printList, setPrintList] = useState<any>()

  const data = useSelector(selectorPutstoreData)

  // const total = useSelector(selectModalToatl)
  const [total,setTotal] = useState(0)

  const [local, setlocal] = useState<any>()

  const tenantName = useSelector(selectTenantName)

  const userName = useSelector(selectUserName)

  const current = useSelector(selectModalCurrent)

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setIds(selectedRowKeys.join(','))
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.id === (current - 1) * size, // Column configuration not to be checked
      // name: record.name,
    }),
  }
  useEffect(() => {
    setlocal(JSON.parse(localStorage.getItem('credentials') as string))
  }, [])

  const getDetailMaintance = () => {
    if (props.visible) {
      dispatch(
        getStockDataAsync({
          ...queries,
          current,
          size,
          tenantId: detail.tenantId,
          tenantInventoryReceiptsId: detail.id,
        })
      )
        .then(unwrapResult)
        .then((res:any) => {
          setTotal(res.outInvReceiptsItemList.total)
          setPrintList([
            ...res.outInvReceiptsItemList?.records,
            {
              id: 0,
              checkCount: res.checkCount,
              checkPrice: res.checkPrice,
              checkSplitCount: res.checkSplitCount,
              checkSplitCountBefore: res?.checkSplitCountBefore,
              checkSplitCountAfter: res?.checkSplitCountAfter,
              beforeTotalCount: res?.beforeTotalCount,
              afterTotalCount: res?.afterTotalCount,
              storehouseTag: res?.storehouseTag,
              upAccountTime: res?.upAccountTime,
            },
          ])
          setStockList([
            ...res.outInvReceiptsItemList?.records,
            {
              id: (current - 1) * size,
              checkCount: res.checkCount,
              checkPrice: res.checkPrice,
              checkSplitCount: res.checkSplitCount,
              checkSplitCountBefore: res?.checkSplitCountBefore,
              checkSplitCountAfter: res?.checkSplitCountAfter,
              beforeTotalCount: res?.beforeTotalCount,
              afterTotalCount: res?.afterTotalCount,
            },
          ])
        })
    }
  }

  useEffect(() => {
    getDetailMaintance()
  }, [props.visible, queries, size, current])

  const dataMainList = {
    tenantName: tenantName?.split('-')?.[0],
    userName: userName,
    dataList: printList?.map((v: any) => {
      return {
        ...v,
      }
    }),
  }

  const contentInventoryCheckDetail = printBodyInventoryCheckDetail(
    dataMainList
  )

  return (
    <div>
      <Modal
        title='快速盘点'
        visible={props.visible}
        okText='确认'
        cancelText='取消'
        className={styles.form}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          props.onCancel()
        }}
        footer={null}
      >
        <Row gutter={20}>
          <Col span={2}>
            <Button
              type='link'
              style={{
                color: '#666',
              }}
              onClick={() => {
                if (Ids) {
                  dispatch(RemovestoredetailAsync(Ids))
                    .then(unwrapResult)
                    .then(() => {
                      getDetailMaintance()
                    })
                } else {
                  notification.info({
                    message: '请选择要删除的药品',
                    duration: 3,
                  })
                }
              }}
              disabled={detail.storehouseTag > 0 ? true : false}
            >
              <DeleteOutlined />
              删除
            </Button>
          </Col>
          <Col span={2}>
            <Button
              type='link'
              style={{
                color: '#666',
              }}
              onClick={() => {
                props.RegisterOk()
                // dispatch(savelistPutstorageAsync(detail.id))
                //   .then(unwrapResult)
                //   .then(() => {
                //     notification.success({
                //       message: "登账成功",
                //       duration: 3,
                //     });
                //     props.onOk();
                //   })
                //   .catch(() => {
                //     notification.error({
                //       message: "登账失败",
                //       duration: 3,
                //     });
                //   });
              }}
              disabled={detail.storehouseTag > 0 ? true : false}
            >
              <AccountOutlined
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '4px',
                  fill: '#666',
                  marginTop: '2px',
                }}
              />
              登账
            </Button>
          </Col>
          <Col span={2}>
            <Button
              type='link'
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#666',
              }}
              onClick={() => {
                const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                page.document.write(contentInventoryCheckDetail) // 写入打印页面的内容
                page.print() // 打印
                page?.close()
                // printRef.current?.print()
              }}
            >
              <PrintOutlined
                style={{
                  width: '18px',
                  height: '18px',
                  marginRight: '4px',
                  fill: '#666',
                  marginTop: '0px',
                }}
              />
              打印
            </Button>
          </Col>
          {/* <Col span={2}>
            <Button
              type="link"
              style={{
                color: "#666",
              }}
            >
              <AccountBookOutlined />
              导出
            </Button>
          </Col> */}
        </Row>

        <div
          style={{
            height: '90%',
            padding: '20px',
            background: '#fff',
            borderRadius: '10px',
          }}
        >
          <InventModalQuery
            onValueChange={(v) => {
              setQueries(v)
            }}
          />
          <div ref={tableRef} style={{ height: '85%' }}>
            <EditableList
              style={{ height: '80%' }}
              bordered
              className={styles.puttable}
              pagination={false}
              page={{
                items: StockList,
                current,
                size: size,
                total: total,
              }}
              columns={InventoryModalCloumns(
                size * (current - 1),
                detail.storehouseTag
              )}
              rowSelection={
                {
                  type: selectionType,
                  ...rowSelection,
                } as TableRowSelection<any>
              }
            />
          </div>
          <div className={styles.pagination}>
            <Pagination
              total={total}
              current={current}
              pageSize={size}
              showSizeChanger
              showQuickJumper
              pageSizeOptions={['5', '10', '15', '20', '50', '100', '500']}
              style={{
                position: 'absolute',
                top: '10px',
                right: '14px',
              }}
              onChange={(current, size) => {
                setSize(size as number)
                dispatch(setModalCurrent(current))
              }}
            />
          </div>
        </div>
      </Modal>
      <PrintFrame
        ref={printRef}
        title={local?.tenantName}
        subTitle='库存盘点清单'
      >
        <Row style={{ lineHeight: '32px' }}>
          <Col span={24} style={{ textAlign: 'right' }}>
            盘点时间:{moment().format(DateTimeFormatSimple)}
          </Col>
        </Row>
        <EditableList
          style={{ height: 'auto' }}
          bordered
          className={styles.puttable}
          pagination={false}
          page={{
            items: StockList,
            current,
            size: size,
            total: total,
          }}
          columns={InventoryModalCloumns(
            size * (current - 1),
            detail.storehouseTag
          )}
        />
        <Row style={{ lineHeight: '32px' }}>
          <Col span={12}>盘点:</Col>
          <Col span={12}>复核:</Col>
        </Row>
      </PrintFrame>
    </div>
  )
}
