/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-28 17:23:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-20 16:22:13
 */
import {PlusOutlined, QuestionCircleTwoTone} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification, Popover,
  Radio,
  Row,
  Select,
  Space,
  Upload,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getBase64, stringTrim } from '../../utils/StringUtils'
import { setDetail } from '../durgsystem/durgsystemSlice'
import styles from './ElectronicTicketUpload.module.css'
import { getMedicalInformationUploadModalDetailAsync } from './ElectronicTicketUploadSlice'

interface LookDetailProps {
  ids?: any
  paymentInsurance?: any
  visible: boolean
  onCancel: () => void
  onOk: (obj: any) => void
}

export const LookDetailModal = ({
  ids,
  paymentInsurance,
  visible,
  onCancel,
  onOk,
}: LookDetailProps): ReactElement => {
  const formGutter = 20

  const firstColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  const secondColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  const thirdColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [uploadImgSrc, setUploadImgSrc] = useState('')

  const [fileName, setFileName] = useState('')

  const [detail, setDetail] = useState<any>()

  const [isThirdpart, setIsThirdpart] = useState<any>()

  useEffect(() => {
    if (visible) {
      dispatch(getMedicalInformationUploadModalDetailAsync(ids))
        .then(unwrapResult)
        .then((v: any) => {
          setDetail(v)
          setIsThirdpart(JSON.stringify(v?.invoiceDetails) != '{}' ? 2 : 1)
          form.setFieldsValue({
            elecSetlCertType: '2',
            idCardNumber: v?.patient?.idCardNumber,
            treatmentCategory: v?.treatment.treatmentCategory?.toString(),
            nwbFlag:
              v?.treatment.nwbFlag > -1
                ? v?.treatment.nwbFlag?.toString()
                : '0',
            ownpayType:
              v?.payment.ownpayType > -1
                ? v?.payment.ownpayType?.toString()
                : '3',
            invoiceCode:
              v?.payment.invoiceCode > -1
                ? v?.payment.invoiceCode?.toString()
                : '',
            invoiceNumber:
              v?.payment.invoiceNumber > -1
                ? v?.payment.invoiceNumber?.toString()
                : '',
            invoiceCheckCode:
              v?.payment.invoiceCheckCode > -1
                ? v?.payment.invoiceCheckCode?.toString()
                : '',
            isThirdpart: JSON.stringify(v?.invoiceDetails) !== '{}' ? 2 : 1,
          })
        })
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      title='详情'
      width={1000}
      footer={null}
      onCancel={onCancel}
      className={styles.formModal}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 22 }}
        autoComplete='off'
        onFinish={(values) => {
          return
        }}
      >
        <Row
          gutter={formGutter}
          className={styles.formItem}
          style={{ marginRight: '10px' }}
        >
          <Col span={8} order={1}>
            <Form.Item label='患者姓名' name='phone' {...firstColumnLayout}>
              {detail?.patient?.name}
            </Form.Item>
          </Col>
          <Col span={8} order={2}>
            <Form.Item
              label='证件号码'
              name='idCardNumber'
              {...secondColumnLayout}
            >
              {detail?.patient?.idCardNumber}
            </Form.Item>
          </Col>
          <Col span={8} order={2}>
            <Form.Item
              label='就诊医生'
              name='companyName'
              {...thirdColumnLayout}
            >
              {detail?.treatment?.treatmentDoctorName}
            </Form.Item>
          </Col>

          <Col span={24} order={5}>
            <Form.Item label='诊断信息' name='phone' {...secondColumnLayout}>
              {detail?.treatment?.disease}
            </Form.Item>
          </Col>
          <Col span={8} order={6}>
            <Form.Item
              label='医疗类别'
              name='treatmentCategory'
              {...firstColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请选择医疗类别',
                },
              ]}
            >
              <Select
                disabled={true}
                placeholder='医疗类别'
                allowClear
                // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              >
                <Select.Option value='0'>普通门诊</Select.Option>
                <Select.Option value='1'>门诊特病</Select.Option>
                <Select.Option value='2'>门诊慢病</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} order={7}>
            <Form.Item
              label='新生儿标志'
              name='nwbFlag'
              {...secondColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请选择新生儿标志',
                },
              ]}
            >
              <Select
                disabled={true}
                placeholder='新生儿标志'
                allowClear
                // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              >
                <Select.Option value='0'>非新生儿</Select.Option>
                <Select.Option value='1'>新生儿(使用母亲身份证)</Select.Option>
                <Select.Option value='2'>新生儿(使用本人身份证)</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} order={8}>
            <Form.Item
              label='自费类型'
              name='ownpayType'
              {...thirdColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请选择自费类型',
                },
              ]}
            >
              <Select
                disabled={true}
                placeholder='自费类型'
                allowClear
                // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              >
                <Select.Option value='3'>医保不可报</Select.Option>
                <Select.Option value='1'>待医保报销</Select.Option>
                <Select.Option value='4'>放弃报销</Select.Option>
                <Select.Option value='2'>意外伤害</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} order={9}>
            <Form.Item
              name='isThirdpart'
              label='是否第三方系统发票'
              initialValue={0}
            >
              <Radio.Group
                value={isThirdpart}
                onChange={(v) => {
                  setIsThirdpart(v?.target?.value)
                }}
              >
                {isThirdpart == 1 ? (
                  <Radio value={1}>是</Radio>
                ) : (
                  <Radio value={2}>否</Radio>
                )}
              </Radio.Group>
              <Popover
                content={
                  <div>
                    <p>
                      如果您所在的机构接入了电子发票，且该患者的票据是通过电子发票开出的；
                    </p>
                    <p>
                      则您不需要登记下方的票据信息，系统会自动为您登记。
                    </p>
                  </div>
                }
                placement='bottom'
                title='说明'
              >
                <QuestionCircleTwoTone />
              </Popover>
            </Form.Item>

          </Col>
          <Col span={24} order={10}>
            <Form.Item
              label='发票类型'
              name='elecSetlCertType'
              {...firstColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请选择发票类型',
                },
              ]}
            >
              <Select
                disabled={true}
                placeholder='发票类型'
                allowClear
                style={{ width: '12.4rem' }}
                // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              >
                <Select.Option value='2'>增值税发票</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} order={11}>
            <Form.Item
              label='发票代码'
              name='invoiceCode'
              {...firstColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请输入发票代码',
                },
              ]}
            >
              <Input disabled={true} placeholder='请输入发票代码' />
            </Form.Item>
          </Col>
          <Col span={8} order={12}>
            <Form.Item
              label='发票号码'
              name='invoiceNumber'
              {...secondColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请输入发票号码',
                },
              ]}
            >
              <Input disabled={true} placeholder='请输入发票号码' />
            </Form.Item>
          </Col>
          <Col span={8} order={13}>
            <Form.Item
              label='发票校验码'
              name='invoiceCheckCode'
              {...thirdColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请输入校验码',
                },
              ]}
            >
              <Input disabled={true} placeholder='请输入校验码' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
