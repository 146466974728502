/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-06 16:27:04
 * @LastEditors: linxi
 * @LastEditTime: 2024-01-22 14:44:28
 */
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface InventoryPriceParams {
  current?: number;
  executeDateEnd?: string;
  executeDateStart?: string;
  name?: string;
  size?: number;
  state?: number;
  tenantId?: number;
  tenantInventoryLedgerId?: number;
}

export interface InventoryPriceBody {
  beforeRetailPrice?: number;
  beforeUnitPrice?: number;
  executeDate?: string;
  id?: number;
  retailPrice?: number;
  state?: number;
  status?: number;
  tenantId?: number;
  tenantInventoryLedgerId?: number;
  unitPrice?: number;
  timeHead?: string;
  timeTail?: string;
}

export function InventoryPricePage(params: InventoryPriceParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/adjustprice/page`,
    method: "GET",
    params,
  };
}

export function InventoryPriceSubmit(body: InventoryPriceBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/adjustprice/submitlist`,
    method: "POST",
    body,
  };
}

export function InventoryPriceRemove(ids: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/adjustprice/remove`,
    method: "POST",
    params: {
      ids,
    },
  };
}

export function InventoryPriceTovoid(ids: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/adjustprice/logoutlist`,
    method: "POST",
    params: {
      ids,
    },
  };
}

export interface ImplementInventoryParams {
  ids: any
  state: number
}

export function InventoryPriceImplement(params: ImplementInventoryParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/adjustprice/executelist`,
    method: 'POST',
    params
  }
}


// 撤回调价
export function cancelChange(ids: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/adjustprice/cancel`,
    method: "POST",
    params: {
      ids,
    },
  };
}


// 中草药批量调价
export interface changePricesParams {
  scale:number
  state:number
}
export function changePrices(params: changePricesParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/adjustprice/execute/chineseherb`,
    method: 'POST',
    params,
  }
}


export interface changeMoreBatchSaveParams {
  catalogId?: any
  retailPrice?: any
  splitPrice?: any
}
export function changeMoreBatchSavePrice(params: changeMoreBatchSaveParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/adjustprice/executeBatch`,
    method: 'POST',
    params,
  }
}



// 导出
export function getInventoryPriceSliceExoprtFn(
  params: InventoryPriceParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/adjustprice/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
