import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification, Row, Upload } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './mergePatientModal.module.css'
import _ from 'lodash'
import React from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import { RootDispatch } from '../../../app/store'
import { getpatientsExcel } from './patientListSlice'
import { UploadOutlined } from '../../../compnents/icons/Icons'
import { selectTenantId, selectSupplierId } from '../../../app/applicationSlice'

interface PurchaseModalProps {
  isPurchaseModalVisible?: boolean
  state?: number
  onOk: () => void
  onCancel: () => void
}

export const PurchaseModal = ({
  isPurchaseModalVisible,
  state,
  onOk,
  onCancel,
}: PurchaseModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState<any>()

  const [btnLoading, setBtnLoading] = useState(false)

  const tenantId: any = useSelector(selectTenantId)

  const supplierId: any = useSelector(selectSupplierId)

  const getUrl = (state?: number) => {
    switch (state) {
      case 1:
        return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/misc/%E6%82%A3%E8%80%85%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF2023-12-18.xlsx'
    }
  }

  const submitBtn = () => {
    if (!fileList) return
    setBtnLoading(true)
    const params = {
      file: fileList,
      tenantId: tenantId,
      supplierId: supplierId,
    }
    switch (state) {
      case 1: //患者导入
        dispatch(getpatientsExcel(params))
          .then(unwrapResult)
          .then(successFn)
          .catch(errorFn)
        break
    }
  }
  const successFn = () => {
    setBtnLoading(false)
    notification.success({
      message: '导入成功',
      duration: 3,
    })
    onOk()
  }
  const errorFn = () => {
    setBtnLoading(false)
    onOk()
  }

  useEffect(() => {
    !isPurchaseModalVisible && setFileList(undefined)
  }, [isPurchaseModalVisible])
  return (
    <>
      <Modal
        className={styles.main}
        width={550}
        title={state ? '导入' : '导入库存'}
        okText='提交'
        footer={null}
        centered
        onOk={onOk}
        onCancel={onCancel}
        visible={isPurchaseModalVisible}
        destroyOnClose
      >
        <Form form={form} name='basic' autoComplete='off'>
          <Form.Item label='上传文件' style={{ margin: '25px' }}>
            <Dragger
              accept='.xls,.xlsx'
              maxCount={1}
              beforeUpload={(file) => {
                setFileList(file)
                return false
              }}
            >
              <div className={styles.upload}>
                <UploadOutlined mode='large'></UploadOutlined>
                <div>
                  将文件拖至此处，或{' '}
                  <span style={{ color: '#3894ff' }}>点击上传</span>
                </div>
              </div>
            </Dragger>
          </Form.Item>
          <Row style={{ marginBottom: '20px', marginTop: '-10px' }}>
            <p style={{ marginLeft: '90px' }}>只能上传.xls,.xlxs等文件</p>
            <a style={{ marginLeft: '140px' }} href={getUrl(state)} id='link1'>
              <DownloadOutlined />
              下载模板
            </a>
          </Row>
          <Form.Item>
            <Row gutter={20}>
              <Col span={12}>
                <Button
                  style={{ float: 'right' }}
                  onClick={() => {
                    form.resetFields()
                    onCancel()
                  }}
                >
                  关闭
                </Button>
              </Col>
              <Col span={12}>
                <Button type='primary' loading={btnLoading} onClick={submitBtn}>
                  确认提交
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
