import { Button, TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import { DateTimeFormat } from '../../../models/datetime'
import { Dimen } from '../../../models/dimen'
import { getGenderName } from '../../../models/user'
import { getAge } from '../../../utils/StringUtils'

export const CompletedOperationColumns = (
  onClick: (text: string, id: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      dataIndex: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '状态',
      dataIndex: 'state',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.state === 0
          ? '已完成'
          : t.state === 1
          ? '已执行'
          : t.state === 2
          ? '已取消'
          : ' -'
      },
    },
    {
      title: '手术时间',
      dataIndex: 'appointmentTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => {
        return t.appointmentTime
          ? moment(t.appointmentTime).format(DateTimeFormat)
          : '-'
      },
    },
    {
      title: '时长',
      dataIndex: 'appointmentDuration',
      ellipsis: true,
      width: '8em',
      align: 'center',
      render: (_, t) => {
        return t.appointmentDuration ? t.appointmentDuration + ' min' : '-'
      },
    },
    {
      title: '手术室',
      dataIndex: 'operatingRoomName',
      width: '8em',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        return t.operatingRoomName ? t.operatingRoomName : '-'
      },
    },
    {
      title: '门诊号',
      dataIndex: 'outpatientNo',
      ellipsis: true,
      width: '10rem',
      align: 'center',
      render: (_, t) => {
        return Number(t.outpatientNo) > 0 ? t.outpatientNo : '-'
      },
    },
    {
      title: '患者姓名',
      dataIndex: 'patientName',
      ellipsis: true,
      width: '6em',
      align: 'center',
      render: (_, t) => {
        return t.patientName ? t.patientName : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'patientSex',
      ellipsis: true,
      width: '4em',
      align: 'center',
      render: (_: string, u: any) => getGenderName(u.patientSex),
    },
    {
      title: '年龄',
      dataIndex: 'patientAge',
      width: '6em',
      ellipsis: true,
      align: 'center',
      render: (_, r) => getAge(r?.patientAge, r?.patientMonth),
    },
    {
      title: '术前诊断',
      width: '8em',
      ellipsis: true,
      dataIndex: 'operatingDiagnoseBefore',
      align: 'center',
      render: (_, t) => {
        return t.operatingDiagnoseBefore ? t.operatingDiagnoseBefore : '-'
      },
    },
    {
      title: '术后诊断',
      width: '8em',
      ellipsis: true,
      dataIndex: 'operatingDiagnoseAfter',
      align: 'center',
      render: (_, t) => {
        return t.operatingDiagnoseAfter ? t.operatingDiagnoseAfter : '-'
      },
    },
    {
      title: '手术名称',
      dataIndex: 'operatingContent',
      width: '8em',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        const operatingContentList =
          t.operatingContent[0] === '['
            ? JSON.parse(t.operatingContent)
            : t.operatingContent
        return operatingContentList[0]?.surgIcalName || ''
      },
    },
    {
      title: '主刀医生',
      dataIndex: 'mainDoctorName',
      width: '8em',
      ellipsis: true,
      align: 'center',
    },
    {
      title: '麻醉医生',
      dataIndex: 'anesthesiaDoctorName',
      width: '8em',
      ellipsis: true,
      align: 'center',
    },
    {
      title: '麻醉方式',
      dataIndex: 'anesthesiaMethod',
      width: '8em',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        return t.anesthesiaDoctorName ? t.anesthesiaDoctorName : '-'
      },
    },
    {
      title: '其他人员',
      dataIndex: 'helper',
      width: '8em',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        const helperList = t.helper[0] === '[' ? JSON.parse(t.helper) : t.helper
        return helperList[0]?.assistant || ''
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: '8em',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        return t.remark ? t.remark : '-'
      },
    },
  ]
}
export const ToothCompletedOperationColumns = (
  onClick: (text: string, id: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      dataIndex: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '状态',
      dataIndex: 'state',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.state === 0
          ? '已完成'
          : t.state === 1
          ? '已执行'
          : t.state === 2
          ? '已取消'
          : ' -'
      },
    },
    {
      title: '手术时间',
      dataIndex: 'appointmentTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => {
        return t.appointmentTime
          ? moment(t.appointmentTime).format(DateTimeFormat)
          : '-'
      },
    },
    {
      title: '时长',
      dataIndex: 'appointmentDuration',
      ellipsis: true,
      width: '8em',
      align: 'center',
      render: (_, t) => {
        return t.appointmentDuration ? t.appointmentDuration + ' min' : '-'
      },
    },
    {
      title: '手术室',
      dataIndex: 'operatingRoomName',
      width: '8em',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        return t.operatingRoomName ? t.operatingRoomName : '-'
      },
    },
    {
      title: '门诊号',
      dataIndex: 'outpatientNo',
      ellipsis: true,
      width: '10rem',
      align: 'center',
      render: (_, t) => {
        return Number(t.outpatientNo) > 0 ? t.outpatientNo : '-'
      },
    },
    {
      title: '术前诊断',
      width: '8em',
      ellipsis: true,
      dataIndex: 'operatingDiagnoseBefore',
      align: 'center',
      render: (_, t) => {
        return t.operatingDiagnoseBefore ? t.operatingDiagnoseBefore : '-'
      },
    },
    {
      title: '术后诊断',
      width: '8em',
      ellipsis: true,
      dataIndex: 'operatingDiagnoseAfter',
      align: 'center',
      render: (_, t) => {
        return t.operatingDiagnoseAfter ? t.operatingDiagnoseAfter : '-'
      },
    },
    {
      title: '手术名称',
      dataIndex: 'operatingContent',
      width: '8em',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        const operatingContentList =
          t.operatingContent[0] === '['
            ? JSON.parse(t.operatingContent)
            : t.operatingContent
        return operatingContentList[0]?.surgIcalName || ''
      },
    },
    {
      title: '主刀医生',
      dataIndex: 'mainDoctorName',
      width: '8em',
      ellipsis: true,
      align: 'center',
    },
    {
      title: '麻醉医生',
      dataIndex: 'anesthesiaDoctorName',
      width: '8em',
      ellipsis: true,
      align: 'center',
    },
    {
      title: '麻醉方式',
      dataIndex: 'anesthesiaMethod',
      width: '8em',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        return t.anesthesiaDoctorName ? t.anesthesiaDoctorName : '-'
      },
    },
    {
      title: '其他人员',
      dataIndex: 'helper',
      width: '8em',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        const helperList = t.helper[0] === '[' ? JSON.parse(t.helper) : t.helper
        return helperList[0]?.assistant || ''
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: '8em',
      ellipsis: true,
      align: 'center',
      render: (_, t) => {
        return t.remark ? t.remark : '-'
      },
    },
  ]
}
