/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-17 17:11:51
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-16 17:33:26
 */
export const number2Pattern = {
  pattern: /^([0-9]+(\.[0-9]{1,2})?)$/,
  message: '请输入正确数字',
}

export const phonePattern = {
  pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
  message: '请输入正确的手机号',
}

// 座机也可
export const phonePatterns = {
  pattern: /^(?=[0-9-]{5,20})[0-9]*-?[0-9]*-?[0-9]*$/,
  message: '请输入正确的手机号',
}


export const numberPattern = {
  pattern: /^\d+$/,
  message: '请输入正确数字',
}

export const IDcardPattern = {
  pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
  message: '您的身份证格式不正确',
}
