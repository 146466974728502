/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-09-26 11:48:15
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Row, Space, Table } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'

import { ThemeContext } from '../../../theme/ThemeContext'
import { Columns } from './Columuns'
import styles from './price.module.css'
import { getreplaceMakeOrderList, supplierPriceListAsync } from '../OReplaceMakeSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import { Query } from './Query'

// 代煎价格
export const ReplaceMakePrice = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<any>([])

  const [total, setTotal] = useState(0)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch(
      supplierPriceListAsync({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setTotal(res.total)
        setData(res.records)
      }).finally(() => {
        setLoading(false)
      })
  }, [params])

  return (
    <Col
      style={{
        height: 'calc(100% - 20px) ',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: theme.pn,
          marginTop: 10,
          borderRadius: 10,
          padding: '10px 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Query
          onValueChange={(v) => {
            setParams({
              ...params,
              ...v,
              current: 1,
            })
          }}
        />
        <EditableList
          page={{
            items: data,
            current: params.current || 1,
            size: params.size || 10,
            total,
          }}
          loading={loading}
          style={{ color: theme.tc3 }}
          className={styles.table}
          columns={Columns((params.size || 10) * ((params.current || 1) - 1))}
          onChangePage={(current: number, size?: number) => {
            setParams({
              ...params,
              current: current,
              size: size || 10,
            })
          }}
        />
      </Col>
    </Col>
  )
}
