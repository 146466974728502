/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 15:49:42
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-20 16:36:58
 */
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface permissionsParams {
  storehouseId: number
  userName?: string
}

export function permissionsPage(params: permissionsParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/storehousepermission/page`,
    method: 'GET',
    params,
  }
}

export function permissionsEdit(
  storehouseId: number,
  Ids: Array<any>
): Request {
  return {
    url: `${API_PREFIX}/blade-store/storehousepermission/savelist`,
    method: 'POST',
    body: {
      storehouseId,
      storehousePermissions: Ids,
    },
  }
}