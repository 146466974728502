import { Button, Row, Form, Space, Input, Select, Checkbox } from 'antd'
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import React, { ReactElement, useEffect, useState } from 'react'
import {} from 'antd'

import {
  DateRangeDrugStaticTypes,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { drugStaticParams } from '../../../services/drugStatic'
import { stringTrim } from '../../../utils/StringUtils'
import { PrintOutlined } from '../../../compnents/icons/Icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectPotionFlag,
} from '../../../app/applicationSlice'
import { selectLoading } from './drugStaticSlice'
import styles from '../../storesearch/Storesearch.module.css'
import {
  pageDurgsystem,
  selectDurgsystemData,
} from '../../durgsystem/durgsystemSlice'
export const DrugStaticQuery = (props: {
  onValueChange: (queries: drugStaticParams) => void
  outClued: () => void
  continueOrder: (v: any, type: string) => void
  ids: any
  print: () => void
}): ReactElement => {
  const [form] = Form.useForm()
  const { Option } = Select
  const insuranceArray = useSelector(selectInsuranceArray)
  const dispatch = useDispatch()

  const loading = useSelector(selectLoading)

  const potionFlag: any = useSelector(selectPotionFlag)

  const [djddChecked, setDjddChecked] = useState<any>()

  const [jzcxChecked, setJzcxChecked] = useState<any>()

  const [insuranceItem, setInsuranceItem] = useState<any>()
  const data: any = useSelector(selectDurgsystemData)

  useEffect(() => {
    dispatch(pageDurgsystem(1, 100, 1))
    const [head, tail] = getDateRange(DateRangeType.Today)
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
    })
    form.submit()
  }, [])

  useEffect(() => {
    if (potionFlag == 0) {
      setDjddChecked(true)
       props?.continueOrder && props?.continueOrder(1, 'djdd')
    }
  }, [potionFlag])

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='drugName' noStyle getValueFromEvent={stringTrim}>
              <Input
                autoComplete='off'
                placeholder='请输入药品名称'
                style={{ width: 160 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Checkbox
              style={{
                color: '#666',
                display: 'flex',
                alignItems: 'center',
              }}
              checked={jzcxChecked}
              onChange={(e) => {
                props?.continueOrder && props?.continueOrder(e.target.checked, 'jzcx')
                setJzcxChecked(e.target.checked)
              }}
            >
              是否精准查询
            </Checkbox>
            <Form.Item
              name='tenantSupplierName'
              noStyle
              getValueFromEvent={stringTrim}
            >
              <Input
                autoComplete='off'
                placeholder='请输入生产厂家'
                style={{ width: 160 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            {/* <Form.Item name='vendorName' noStyle>
              <Input
                style={{ width: 200 }}
                placeholder='供应商'
                allowClear
                prefix={<SearchOutlined />}
                onChange={form.submit}
              />
            </Form.Item> */}
            <Form.Item name='state' noStyle>
              <Select
                placeholder='收费类型'
                allowClear
                onChange={() => form.submit()}
                style={{ width: 130 }}
                defaultValue={'全部'}
                // suffixIcon={<CaretDownFilled />}
              >
                <Option value={3}>已收费</Option>
                <Option value={5}>已退费</Option>
                <Option value={99}>已发药</Option>
              </Select>
            </Form.Item>
            {insuranceArray && insuranceArray.length !== 0 && (
              <Form.Item name='insuranceCode' noStyle>
                <Select
                  placeholder='险种类型'
                  style={{ width: '8.25rem' }}
                  onChange={(key) => {
                    const insuranceItem = insuranceArray.find(
                      (v) => v.insuranceCode === key
                    )
                    setInsuranceItem(insuranceItem)
                    form.submit()
                  }}
                >
                  <Select.Option value={''}>全部</Select.Option>
                  <Select.Option value={'ZIFEI'}>自费</Select.Option>
                  {insuranceArray.map((v) => (
                    <Select.Option value={v.insuranceCode} key={v.id}>
                      {v.insuranceName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <DateSelect
              style={{ width: '7rem' }}
              labelPrefix=''
              namePrefix='time'
              placeholder='挂号时间-今天'
              initialValue={DateRangeType.Today}
              onChange={() => form.submit()}
              options={DateRangeDrugStaticTypes}
              allowClear={false}
            />
            <Form.Item name='storehouseId' noStyle>
              <Select
                allowClear
                placeholder='库房列表'
                dropdownClassName={styles.select}
                showSearch
                onChange={() => form.submit()}
                // suffixIcon={<CaretDownFilled />}
                optionFilterProp='children'
              >
                {data.map((v: any, i: any) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Button type='primary' htmlType='submit' loading={loading}>
              查询
            </Button>
          </Space>
          <Space>
            {potionFlag == 0 ? (
              <Checkbox
                style={{
                  color: '#666',
                  display: 'flex',
                  alignItems: 'center',
                  float: 'right',
                }}
                checked={djddChecked}
                onChange={(e) => {
                  props?.continueOrder && props?.continueOrder(e.target.checked, 'djdd')
                  setDjddChecked(e.target.checked)
                }}
              >
                包含代煎订单
              </Checkbox>
            ) : (
              ''
            )}

            <Button
              type='text'
              style={{
                color: '#666',
                display: 'flex',
                alignItems: 'center',
                float: 'right',
              }}
              onClick={() => {
                props.print()
              }}
            >
              <PrintOutlined
                style={{
                  fill: '#666',
                  width: '18px',
                  height: '18px',
                  marginRight: '4px',
                }}
              />
              打印
            </Button>
            <Button
              type='primary'
              // disabled={!props?.ids.length}
              onClick={props.outClued}
            >
              导出
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
