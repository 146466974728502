/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-09 16:20:36
 */
export enum BillingCategory {
  XY = "01",
  ZC = "02",
  ZY = "03",
  ZCF = "04",
  ZLF = "05",
  JCF = "06",
  HYF = "07",
  SSF = "08",
  FSF = "09",
  SXF = "10",
  SYF = "11",
  CLF = "17",
  BCF = "23",
  XDTF = "24",
  ZSF = "25",
  TXF = "26",
  HLF = "27",
  MZF = "28",
  GHF = "30",
  XL = "32",
  FL = "33",
  DJF = "97",
  QTF = "99",
}
// 全部
export const BillingCategories = [
  BillingCategory.XY,
  BillingCategory.ZC,
  BillingCategory.ZY,
  BillingCategory.ZCF,
  BillingCategory.ZLF,
  BillingCategory.JCF,
  BillingCategory.HYF,
  BillingCategory.SSF,
  BillingCategory.FSF,
  BillingCategory.SXF,
  BillingCategory.SYF,
  BillingCategory.CLF,
  BillingCategory.BCF,
  BillingCategory.XDTF,
  BillingCategory.ZSF,
  BillingCategory.TXF,
  BillingCategory.HLF,
  BillingCategory.MZF,
  BillingCategory.GHF,
  BillingCategory.XL,
  BillingCategory.FL,
  BillingCategory.DJF,
  BillingCategory.QTF,
];
// 诊疗项目
export const TreatmentBillingCategories = [
  BillingCategory.ZCF,
  BillingCategory.ZLF,
  BillingCategory.JCF,
  BillingCategory.HYF,
  BillingCategory.SSF,
  BillingCategory.FSF,
  BillingCategory.SXF,
  BillingCategory.SYF,
  BillingCategory.BCF,
  BillingCategory.XDTF,
  BillingCategory.ZSF,
  BillingCategory.TXF,
  BillingCategory.HLF,
  BillingCategory.MZF,
  BillingCategory.GHF,
  BillingCategory.DJF,
  BillingCategory.QTF,
];
//开票项目
export const InvoiceBillingCategories = [
  BillingCategory.XY,
  BillingCategory.ZC,
  BillingCategory.ZY,
  BillingCategory.CLF,
  BillingCategory.QTF,
  BillingCategory.XL,
  BillingCategory.FL,
];
// 商品管理查询条件
export const ShopBillingCategories = [
  BillingCategory.XY,
  BillingCategory.ZC,
  BillingCategory.ZY,
];
export function BillingCategoryName(billingCategory: BillingCategory): string {
  switch (billingCategory) {
    case "01":
      return "西药费";
    case "02":
      return "中成药";
    case "03":
      return "中草药";
    case "04":
      return "诊察费";
    case "05":
      return "治疗费";
    case "06":
      return "检查费";
    case "07":
      return "化验费";
    case "08":
      return "手术费";
    case "09":
      return "放射费";
    case "10":
      return "输血费";
    case "11":
      return "输氧费";
    case "17":
      return "材料费";
    case "23":
      return "B超费";
    case "24":
      return "心电图费";
    case "25":
      return "注射费";
    case "26":
      return "透析费";
    case "27":
      return "化疗费";
    case "28":
      return "麻醉费";
    case "30":
      return "挂号费";
    case "32":
      return "细料";
    case "33":
      return "辅料";
    case "97":
      return "代煎费";
    case "99":
      return "其他费";
  }
  return "";
}

// : 01:西药费 02：中成药 03：中草药 04诊察费: 05治疗费: 06检查费: 07化验费: 08手术费: 09放射费: 10输血费: 11输氧费: 17材料费 23B超费: 24心电图费: 25注射费: 26透析费: 27化疗费: 28麻醉费: 30挂号费: 32：细聊 33：辅料 97代煎费: 99其他费
