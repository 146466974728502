/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-06 19:10:14
 * @LastEditors: linxi
 * @LastEditTime: 2024-01-22 14:49:24
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { ThemeContext } from '../../theme/ThemeContext'
import { RecipeItemInput } from '../treatment/editor/components/RecipeItemInput'
import { changeMoreBatchSavePriceAsync } from './inventoryPriceSlice'

export const MoreBatchNumChangeModal = (props: {
  visible: boolean
  onOk: (v?:any) => void
  onCancel: () => void
}) => {
  const [form] = Form.useForm()

  const theme = useContext(ThemeContext)

  const insuranceArray = useSelector(selectInsuranceArray)

  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<any>([])

  const [price, setPrice] = useState<any>()

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  }

  return (
    <Modal
      title='多批号调价'
      centered
      visible={props.visible}
      okText='确认'
      cancelText='取消'
      width={600}
      bodyStyle={{ height: '400px' }}
      onOk={() => {
        props.onOk()
        setData([])
        setPrice({})
      }}
      onCancel={() => {
        props.onCancel()
        setData([])
        form.resetFields()
        setPrice({})
      }}
      footer={null}
    >
      <Form {...layout} form={form} name='control-hooks'>
        <Row>
          <Col span={24}>
            <Form.Item name='name' label='商品选择'>
              <RecipeItemInput
                noSetting={true}
                isRestrictUser={1} //是否限制用户
                wbTag={0}
                insuranceCode={insuranceArray[0]?.insuranceCode}
                // disabled
                style={{
                  width: '400px',
                  height: '2rem',
                  color: theme.tc1,
                }}
                autoFocus
                type={0}
                onSelect={(m) => {
                  setPrice(m)
                  form.setFieldsValue({ name: m?.name })
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='' label='拆零单价'>
              {price?.splitPrice?.toString() || '-'}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='splitPrice' label='调后拆零单价'>
              <Input style={{ width: '400px' }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='' label='整存单价'>
              {price?.retailPrice?.toString() || '-'}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='retailPrice' label='调后整存单价'>
              <Input style={{ width: '400px' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Button
              htmlType='button'
              onClick={() => {
                props.onCancel()
              }}
              style={{
                float: 'right',
              }}
            >
              取消
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type='primary'
              onClick={() => {
                if (
                  form.getFieldValue('splitPrice') ||
                  form.getFieldValue('retailPrice')
                ) {
                  dispatch(
                    changeMoreBatchSavePriceAsync({
                      catalogId: price?.localItemId,
                      splitPrice: form.getFieldValue('splitPrice'),
                      retailPrice: form.getFieldValue('retailPrice'),
                    })
                  )
                    .then(unwrapResult)
                    .then((v: any) => {
                      notification.success({
                        message: '修改成功',
                      })
                      props?.onOk(
                        v?.map((n: any) => {
                          return {
                            ...n,
                            beforeSplitPrice: n.splitPrice,
                            beforeRetailPrice: n.retailPrice,
                            retailPrice: n?.adjustedRetailPrice,
                            splitPrice: n?.adjustedSplitPrice,
                          }
                        })
                      )
                      form.resetFields()
                    })
                } else {
                  notification.info({
                    message: '请填写调后拆零单价或调后整存单价',
                  })
                }
              }}
              // htmlType='submit'
              style={{
                float: 'left',
              }}
            >
              确定
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
