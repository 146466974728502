export enum ErrorCode {
  Ok,
  ResourceDuplicated,
  ResourceNotFound,
  Unknown,
  WrongUsernameOrPassword,
  RemoteServiceError,
}

export interface ErrorState {
  code: ErrorCode;
  message: string;
  cause?: Error;
}

export const NoErrorState: ErrorState = {
  code: ErrorCode.Ok,
  message: "",
};
