/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-06-16 16:31:30
 */
import { API_PREFIX } from './constant'
import { Request } from './request'


export interface GetOutpatientAuditListParams {
  current?: number
  size?: number
  param?: string
  treatmentDepartmentId?: string
  treatmentDoctorId?: string
  treatmentTimeHead?: string
  treatmentTimeTail?: string
  sort?: number
}


export function getOutpatientAuditList(
  params: GetOutpatientAuditListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/log`,
    method: 'GET',
    params,
  }
}

export function getOutpatientAuditsExoprt(
  params: GetOutpatientAuditListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/log/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
