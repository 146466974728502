import { SettingOutlined } from "@ant-design/icons";
import { Button, Space, TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { StateSwitch } from '../../compnents/widgets/StateSwitch'
import { DateTimeFormatSimple } from '../../models/datetime'
import { getTenantCategoryName, Tenant } from '../../models/tenant'
import { ThemeData } from '../../theme/ThemeContext'
import styles from './Tenants.module.css'

export const columns = (
  onClick: (
    type: 'settings' | 'toggle' | 'detail' | 'openReplaceMake',
    tenant: Tenant
  ) => void,
  startIndex: number,
  theme: ThemeData
): TableColumnType<Tenant>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '机构编号',
      dataIndex: 'medicalOrganCode',
      align: 'center',
      width: '4rem',
      render: function Element(_, t) {
        return (
          <div
            className={styles.title}
            style={{
              width: 220,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <i
              style={{
                width: 'auto',
                height: '100%',
                fontStyle: 'normal',
                marginRight: '16px',
              }}
            >
              {t.medicalOrganCode}
            </i>
          </div>
        )
      },
    },
    {
      title: '机构类别',
      key: 'tenantCategory',
      align: 'center',
      width: '5rem',
      render: (_, t) => getTenantCategoryName(t.tenantCategory),
    },
    {
      title: '名称',
      key: 'name',
      align: 'center',
      render: function Element(_, t) {
        return (
          <div
            className={styles.title}
            style={{
              width: 220,
              margin: 'auto',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <i
              style={{
                width: 'auto',
                height: '100%',
                fontStyle: 'normal',
                marginRight: '16px',
              }}
            >
              {t.name}
            </i>
          </div>
        )
      },
    },
    {
      title: '地址',
      key: 'address',
      align: 'left',
      render: function Element(_, t) {
        return (
          <div
            className={styles.title}
            style={{
              width: 220,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <i
              style={{
                width: 'auto',
                height: '100%',
                fontStyle: 'normal',
                marginRight: '16px',
              }}
            >
              {t.address}
            </i>
          </div>
        )
      },
    },
    {
      title: '开通时间',
      key: 'createTime',
      align: 'center',
      width: '8rem',
      render: (_, t) => t.createTime?.split(' ')?.[0],
    },
    {
      title: '登录时间',
      key: 'lastLoginTime',
      align: 'center',
      width: '8rem',
      render: (_, t) =>
        t.lastLoginTime
          ? moment(t.lastLoginTime).format(DateTimeFormatSimple)
          : '-',
    },
    {
      title: '起始时间',
      key: 'startTime',
      align: 'center',
      width: '8rem',
      render: (_, t) => t.startTime?.split(' ')?.[0],
    },
    {
      title: '终止时间',
      key: 'endTime',
      align: 'center',
      width: '8rem',
      render: (_, t) => t.endTime?.split(' ')?.[0],
    },
    {
      title: '服务状态',
      key: 'status',
      align: 'center',
      width: '8rem',
      render: function Status(_, t): ReactElement {
        const active = t.status === 1
        return (
          <StateSwitch value={active} onChange={() => onClick('toggle', t)} />
        )
      },
    },
    {
      title: '代煎设置',
      key: 'status',
      align: 'center',
      width: '8rem',
      render: function ReplaceMake(_, t): ReactElement {
        return (
          <Button
            type='primary'
            style={{ width: 115, borderRadius: 5 }}
            onClick={() => onClick('openReplaceMake', t)}
          >
            {t.status === 0 ? '开通代缴' : '代缴选项设置'}
          </Button>
        )
      },
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '10em',
      render: function ActionBar(_, t): ReactElement {
        return (
          <Space>
            <a
              onClick={() => onClick('settings', t)}
              style={{ color: theme.tc2 }}
            >
              <SettingOutlined
                style={{
                  color: theme.c1,
                  marginRight: 4,
                  fontSize: '0.875rem',
                }}
              />
              设置
            </a>
            <a onClick={() => onClick('detail', t)}>查看详情 </a>
          </Space>
        )
      },
    },
  ]
}
