/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-31 11:05:42
 * @LastEditors: sj
 * @LastEditTime: 2022-06-02 11:03:52
 */
import { TableColumnType, Button } from 'antd'

export const Columns = (startIndex: number): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '药品名称',
      align: 'center',
      dataIndex: 'materialsCatalogName',
      key: 'materialsCatalogName',
    },
    {
      title: '销售数量',
      align: 'center',
      dataIndex: 'sellNumber',
      key: 'sellNumber',
    },
    {
      title: '进价',
      align: 'center',
      key: 'stockPrice',
      render: (_, t) => t.stockPrice.toFixed(5)
    },
    {
      title: '总成本',
      align: 'center',
      dataIndex: 'totalCost',
      render: (_, t) => t.totalCost.toFixed(2)
    },
    {
      title: '销售单价',
      align: 'center',
      key: 'unitPrice',
      render: (_, t) => t.unitPrice.toFixed(5)
    },
    {
      title: '销售总金额',
      align: 'center',
      dataIndex: 'sellAmount',
      render: (_, t) => t.sellAmount.toFixed(2)
    },
  ]
}
