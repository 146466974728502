import { Form, Input, Select, Button, Row, Col, DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState, } from "react";
import { DateTimeFormatSimple } from "../../../../models/datetime";
import { treatmentCostParams } from "../../../../services/treatmentCost";
export const TreatCostDetailQuery = (props: {
  timeHead?: any;
  timeTail?: string;
  onValueChange: (queries: treatmentCostParams,) => void;
}) => {
  
  const [form] = Form.useForm();

  useEffect(() => { 
    const head = moment(props.timeHead)
    const tail = moment(props.timeTail)
    if(props.timeTail && props.timeHead){
      form.setFieldsValue({
        timeHead:head,
        timeTail:tail, 
      }); 
      form.submit();
    }
  },[])

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={(vs) => props.onValueChange(vs)}
      >
      <Row gutter={11}>
        <Col style={{ marginTop: "5px" }}>日期范围:</Col>
        <Col>
          <Form.Item name="timeHead">
            <DatePicker placeholder="请选择开始时间"  format="YYYY-MM-DD" />
          </Form.Item>
        </Col>—
            <Col >
          <Form.Item name="timeTail">
            <DatePicker placeholder="请选择结束时间"  format="YYYY-MM-DD"/>
          </Form.Item>
        </Col>
        <Col span={13} >
          <Form.Item name="allStr">
            <Button type="primary" style={{ float: "right" }}   onClick={() => props.onValueChange(form.getFieldsValue())}>
              查询
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
