/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: K
 * @LastEditTime: 2022-05-25 15:15:26
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import { Department, fromJson } from '../../models/department'
import { Page, pageOf, startPageOf } from '../../models/page'
import * as S from '../../services/department'
import {
  CreateDepartmentParams,
  GetDepartmentDetailParams,
  GetDepartmentListParams,
  ToggleDepartmentStateParams,
} from '../../services/department'

interface DepartmentState {
  page: Page<Department>
}

const initialState: DepartmentState = {
  page: startPageOf(),
}

export const getDepartmentList = createAsyncThunk<
  Page<Department>,
  GetDepartmentListParams,
  RootThunkApi<Page<Department>>
>('department/getDepartmentListStatus', async (params, api) => {
  return sendAsync(S.getDepartmentList(params), api).then(
    (data: Record<string, never>) =>
      pageOf(
        data,
        _.chain(data.records)
          .map((d) => fromJson(d))
          .value()
      )
  )
})

// 科室上传
export const departmentUploadAsync = createAsyncThunk<
  string,
  S.DepartmentBody,
  RootThunkApi
>('/department/departmentUploadAsync', async (body, api) => {
  return sendAsync(S.DepartmentUpload(body), api)
})

// 科室撤销
export const departmentRepealAsync = createAsyncThunk<
  string,
  S.DepartmentBody,
  RootThunkApi
>('/department/departmentRepealAsync', async (body, api) => {
  return sendAsync(S.DepartmentRepeal(body), api)
})

export const createOrUpdateDepartment = createAsyncThunk<
  void,
  CreateDepartmentParams,
  RootThunkApi<void>
>('department/createOrUpdateDepartmentStatus', async (params, api) => {
  return sendAsync(S.createOrUpdateDepartment(params), api)
})

export const getDepartmentDetail = createAsyncThunk<
  Department,
  GetDepartmentDetailParams,
  RootThunkApi<Department>
>('department/getDepartmentDetailStatus', async (params, api) => {
  return sendAsync(S.getDepartmentDetail(params), api).then((data: unknown) =>
    fromJson(data)
  )
})

export const toggleDepartmentState = createAsyncThunk<
  void,
  ToggleDepartmentStateParams,
  RootThunkApi<void>
>('department/toggleDepartmentStateStatus', async (params, api) => {
  return sendAsync(S.toggleDepartmentState(params), api)
})

const depertmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDepartmentList.fulfilled, (state, action) => {
      state.page = action.payload
    })
  },
})

export const selectDepartmentPage = (state: RootState): Page<Department> =>
  state.department.page

export default depertmentSlice.reducer
