import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import * as S from '../../services/electronicMedicalRecordUpload'


//  上传
export const electronicMedicalRecordListUpload = createAsyncThunk<
  any,
  S.ListUploadBody,
  RootThunkApi
>('/settlement/electronicMedicalRecordListUpload', async (body, api) => {
  return sendAsync(S.electronicMedicalRecordListUpload(body), api)
})

