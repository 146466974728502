import { unwrapResult } from '@reduxjs/toolkit';
import { message, notification, Row } from 'antd';
import moment from 'moment';
import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectInsuranceArray, selectTenantAddress, selectTenantAreaCd, selectTenantCategory, selectTenantName, selectUserId, selectUserName } from '../../../app/applicationSlice';
import { RootDispatch } from '../../../app/store';
import { EditableList } from '../../../compnents/list/EditableList';
import { DateTimeFormatSimpleOnlyOne } from '../../../models/datetime';
import { MedicalItem } from '../../../models/material';
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils';
import { delCodeMedicalListAsync, revokeCatalogAsync, saveCodeMedicalListAsync, selectCommodityData, updateCatalogAsync } from '../../commodity/commoditySlice';
import { MedicalModal } from '../../commodity/medical/MedicalModal';
import { getShoopList } from '../HomeSlice';
import { Columns } from './columns'
export const ShoopManagement = () => {
  const dispatch = useDispatch<RootDispatch>()
  const [pageLoading, setPageLoading] = useState(false)

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantAddress = useSelector(selectTenantAddress)

  const data = useSelector(selectCommodityData)

  const [componentItem, setComponentItem] = useState<any>()

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const tenantCategory = useSelector(selectTenantCategory)

  const [medicalModalVisible, setMedicalModalVisible] = useState(false)

  // const [selectRow, setSelectRow] = useState<any>([])

  // const [selectIds, setSelectIds] = useState<any>([])

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const cycleCode = sessionStorage.getItem('cycleCode')   //上传

  const success = (str: string) => {
    const hide = message.loading(str, 0)
    // Dismiss manually and asynchronously
    setTimeout(hide, 1500)
  }

  const notificationInfo = (str: string) => {
    notification.info({
      message: str,
    })
  }
  // 撤销匹配医保目录
  const delNedicalCode = (item: any) => {
    if (item.approveFlg !== '1') {
      dispatch(
        delCodeMedicalListAsync({
          // insuranceCode: 'ZHEJIANG',
          mapping_id: String(item?.mappingId),
        })
      )
        .then(unwrapResult)
        .then(() => {
          getPage()
          notification.success({
            message: '撤销匹配成功',
          })
        })
    } else {
      notification.info({
        message: '不可删除已上传的匹配记录!',
      })
    }
  }

  // 匹配医保目录
  const saveMedicalCode = (item: MedicalItem) => {
    dispatch(
      saveCodeMedicalListAsync({
        billing_category: componentItem.billingCategory,
        hilist_code: item?.hiListCode,
        // insuranceCode: 'ZHEJIANG',
        is_reimbursement: item?.isCouldBx,
        local_item_id: String(componentItem?.id),
        rid:item?.rid,
        proviceHilistCode:item?.proviceHilistCode

      })
    )
      .then(unwrapResult)
      .then(() => {
        getPage()
        notification.success({
          message: '匹配成功',
        })
      })
  }

  const getPage = () => {
    dispatch(getShoopList(params))
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          total: res.total,
          items: res.records
        })
      })
      .catch(() => {
        // do nothing.
      });
  }

  useEffect(() => {
    if (insuranceArray.length) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])
  useEffect(() => {
    getPage()
  }, [params])

  const updataCatalogItem = (
    serverType: any,
    catMappingId: number,
    resolve: any,
    reject: any
  ) => {
    dispatch(
      updateCatalogAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
          },
          catMappingIds: [catMappingId],
        },
      })
    )
      .then(unwrapResult)
      .then(async (res: string) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data.data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                updataCatalogItem('response', catMappingId, resolve, reject)
              }
            },
            undefined,
            reject
          )
        } else {
          resolve()
        }
      })
      .catch((err) => {
        reject(err)
      })
  }

  // 上传医保数据
  const updataCatalog = async (serverType: any, id?: any, isAll?: boolean) => {
    success('上传中')

    const promiseData: any = []

    promiseData.push(
      new Promise((resolve, reject) => {
        updataCatalogItem(serverType, id, resolve, reject)
      })
    )
    Promise.allSettled(promiseData).then((settled: any) => {
      const success: any = settled.filter(
        (v: any) => v.status === 'fulfilled'
      )
      const error: any = settled.filter((v: any) => v.status === 'rejected')
      const msgContent = (
        <div>
          上传完毕，本次共上传{settled.length}条，成功{success.length}条，失败
          {error.length}条，
          {error.length > 0 ? (
            <>
              <Row>详细信息如下：</Row>
              {error.map((v: any, i: number) => {
                if (!v?.reason?.message) {
                  return <div>{v?.reason}</div>
                } else {
                  return (<div
                    key={i}
                    dangerouslySetInnerHTML={{ __html: v?.reason?.message }}
                  ></div>)
                }
              })}
            </>
          ) : (
            ''
          )}
        </div>
      )
      if (success.length == 0) {
        notification.error({
          message: msgContent,
          duration: 3,
        })
      } else if (success.length == settled.length) {
        notification.success({
          message: msgContent,
          duration: 3,
        })
      } else {
        notification.warn({
          message: msgContent,
          duration: 3,
        })
      }
      // notification.success({
      //   message: `上传成功，本次共上传${settled.length}条，成功${success.length}条，失败${error.length}条`,
      //   duration: 3,
      // })
      getPage()

    })

  }


  // 撤销上传
  const revokeCatalog = async (serverType: any, id: any) => {
    const promiseData: any = []
    promiseData.push(
      new Promise((resolve, reject) => {
        revokeCatalogItem(serverType, id, resolve, reject)
      })
    )
    success('撤销中')
    Promise.allSettled(promiseData).then((settled: any) => {
      const success: any = settled.filter(
        (v: any) => v.status === 'fulfilled'
      )
      const error: any = settled.filter((v: any) => v.status === 'rejected')
      const msgContent = (
        <div>
          撤销完毕，本次共撤销{settled.length}条，成功{success.length}条，失败
          {error.length}条，
          {error.length > 0 ? (
            <>
              <Row>详细信息如下：</Row>
              {error.map((v: any, i: number) => {
                if (!v?.reason?.message) {
                  return <div>{v?.reason}</div>
                } else {
                  return (<div
                    key={i}
                    dangerouslySetInnerHTML={{ __html: v?.reason?.message }}
                  ></div>)
                }
              })}
            </>
          ) : (
            ''
          )}
        </div>
      )
      if (success.length == 0) {
        notification.error({
          message: msgContent,
          duration: 3,
        })
      } else if (success.length == settled.length) {
        notification.success({
          message: msgContent,
          duration: 3,
        })
      } else {
        notification.warn({
          message: msgContent,
          duration: 3,
        })
      }
      getPage()
    })
  }


  const revokeCatalogItem = (
    serverType: any,
    catMappingId: number,
    resolve: any,
    reject: any
  ) => {
    dispatch(
      revokeCatalogAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
          },
          catMappingIds: [catMappingId],
        },
      })
    )
      .then(unwrapResult)
      .then(async (res: string) => {
        if (serverType === 'requestString') {
          const data = JSON.parse(res.replace(/\\"/g, '"'))
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data.data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call, err) => {
              if (!err) {
                revokeCatalogItem('response', catMappingId, resolve, reject)
              }
            },
            undefined,
            reject
          )
        } else {
          resolve()
        }
      })
      .catch((err) => {
        reject(err)
      })
  }

  return (
    <div>
      <EditableList
        loading={pageLoading}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns((text, id: number, item) => {
          if (text === 'upload') {
            cycleCode
              ? updataCatalog('requestString', id)
              : notificationInfo('请先签到')

          } else if (text === 'insurance') {
            if (item.approveFlg !== '1') {
              setComponentItem(item)
              setMedicalModalVisible(true)
            } else {
              notification.info({
                message: '不可修改已上传的匹配记录!',
              })
            }
            // medicalSendHttp("")
          } else if (text === 'delCode') {
            delNedicalCode(item)
          } else if (text === 'revokeCatalog') {
            cycleCode
              ? revokeCatalog('requestString', id)
              : notificationInfo('请先签到')
          }
        }, params.size * ((params.current || 1) - 1))}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
        onRow={() => ({
          onDoubleClick: () => {
           return
          },
        })}
      />
      <MedicalModal
        visible={medicalModalVisible}
        onOk={() => {
          setMedicalModalVisible(false)
        }}
        onCancel={() => {
          setMedicalModalVisible(false)
        }}
        onRecord={(item) => {
          setMedicalModalVisible(false)
          saveMedicalCode(item)
        }}
        componentItem={{
          ...componentItem,
          insuranceCode: 'ZHEJIANG',
        }}
      />
    </div>
  );
}

