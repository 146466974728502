/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-05-30 17:46:33
 * @LastEditors: K
 * @LastEditTime: 2022-06-24 15:57:26
 */
import { Col, Form, Input, Row, Select } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectInsuranceArray } from '../../app/applicationSlice';
import { getQueryName } from '../../models/otherInsurance';
import { OtherInsuranceModel } from './model';
import styles from './otherInsuranceAPI.module.css'

export const OtherInsuranceAPI = (): ReactElement => {
  const [form] = Form.useForm()

  const { Option } = Select;

  const insuranceArray = useSelector(selectInsuranceArray)

  const [options, setOptions] = useState<any>([])

  const [value, setValue] = useState(0)

  const [readCard, setReadCard] = useState(false)

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setOptions(insuranceArray)
  }, [insuranceArray])



  const queryLayout = {
    labelCol: { flex: '5rem', labelAlign: 'left' },
    wrapperCol: { flex: '12rem' },
  }

  const organizationOptions = [
    { value: 5101, readCard: false },
    { value: 5102, readCard: false },
    { value: 1201, readCard: false },
    { value: 3260, readCard: false },
    { value: 3261, readCard: false },

  ]

  const patientOptions = [
    { value: 2001, readCard: true },
    { value: 5201, readCard: true },
    { value: 5203, readCard: true },
    { value: 5205, readCard: true },
    { value: 5302, readCard: true },
    { value: 5303, readCard: true },
    { value: 5304, readCard: true },
    { value: 7014, readCard: true },


  ]

  const checkOptions = [
    { value: 5401, readCard: true },
    // { value: 5402, readCard: true },
  ]

  const catagoryOptions = [
    { value: 1399, readCard: false },
    { value: 1316, readCard: false },
    { value: 1317, readCard: false },

    // { value: 5402, readCard: true },
  ]

  const otherOptions = [
    { value: 1901, readCard: false },
    { value: 2601, readCard: false },
    { value: 1163, readCard: false },


    // { value: 5402, readCard: true },
  ]
  const [code, setCode] = useState('')

  return (
    <div className={styles.content}>
      <Form form={form} onFinish={(v) => {
        return
      }}>
        <Row gutter={10}>
          <Col span={8}>
            <Form.Item {...queryLayout} name="billingCategory" label='结算险种'>
              <Select
                placeholder="结算险种"
                onChange={(v) => {
                  form.submit()
                  setCode(v)
                }}
              >
                {options.map((v: any) => {
                  return (
                    <Option value={v.insuranceCode} key={v.insuranceCode}>
                      {v.insuranceName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* 机构相关 */}
      <div className={styles.category}>
        <header>机构相关</header>
        <section className={styles.categoryInfo}>
          <Row gutter={15}>
            {
              organizationOptions.map(v => (
                <Col className={styles.categoryItem} key={v.value} onClick={() => {
                  setReadCard(v.readCard)
                  setVisible(true)
                  setValue(v.value)
                }}>
                  {'【' + v.value + '】' + getQueryName(v.value)}
                </Col>
              ))
            }
          </Row>
        </section>
      </div>
      {/* 医保患者相关 */}
      <div className={styles.category}>
        <header>医保患者相关</header>
        <section className={styles.categoryInfo}>
          <Row gutter={15}>
            {
              patientOptions.map(v => (
                <Col className={styles.categoryItem} key={v.value} onClick={() => {
                  setReadCard(v.readCard)
                  setVisible(true)
                  setValue(v.value)
                }}>
                  {'【' + v.value + '】' + getQueryName(v.value)}
                </Col>
              ))
            }
          </Row>
        </section>
      </div>
      {/* 检查检验相关 */}
      <div className={styles.category}>
        <header>检查检验相关</header>
        <section className={styles.categoryInfo}>
          <Row gutter={15}>
            {
              checkOptions.map(v => (
                <Col className={styles.categoryItem} key={v.value} onClick={() => {
                  setReadCard(v.readCard)
                  setVisible(true)
                  setValue(v.value)
                }}>
                  {'【' + v.value + '】' + getQueryName(v.value)}
                </Col>
              ))
            }
          </Row>
        </section>
      </div>
      {/* 医疗目录相关 */}
      <div className={styles.category}>
        <header>医疗目录相关</header>
        <section className={styles.categoryInfo}>
          <Row gutter={15}>
            {
              catagoryOptions.map(v => (
                  <Col className={styles.categoryItem} key={v.value} onClick={() => {
                    setReadCard(v.readCard)
                    setVisible(true)
                    setValue(v.value)
                  }}>
                    {'【' + v.value + '】' + getQueryName(v.value)}
                  </Col>
              ))
            }
          </Row>
        </section>
      </div>
      {/* 其他接口 */}
      <div className={styles.category}>
        <header>其他接口</header>
        <section className={styles.categoryInfo}>
          <Row gutter={15}>
            {
              otherOptions.map(v => (
                  <Col className={styles.categoryItem} key={v.value} onClick={() => {
                    setReadCard(v.readCard)
                    setVisible(true)
                    setValue(v.value)
                  }}>
                    {'【' + v.value + '】' + getQueryName(v.value)}
                  </Col>
              ))
            }
          </Row>
        </section>
      </div>
      <OtherInsuranceModel
        value={value}
        visibility={visible}
        readCard={readCard}
        code={code}
        onOk={() => setVisible(false)}
      />
    </div>
  );
}

