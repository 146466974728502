import { Modelment } from "../models/list";
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface revenueReportParams {
  id ?: string;
  amount?: number;
  billingCategory?: string;
  aggrPay?: number;
  alipay?: number;
  cash?: number;
  name?: string;
  otherPay?: number;
  totalRealPayAmount?: number;
  totalShouldPayAmount?: number;
  unionPay?: number;
  wxPay?: number;
  timeHead ?:string,
  timeTail ?:string
}
export function getRevenueReport(params: revenueReportParams): Request {
  return {
    // url: `${API_PREFIX}/blade-outpatient/statistics/incomeList?timeHead=2021%2F04%2F21+00:00:00&timeTail=2021%2F04%2F22+23:59:59`,
    url: `${API_PREFIX}/blade-outpatient/statistics/incomeList`,
    method: "GET",
    params,
  };
}

export function getRevenueReportExport(
  params: revenueReportParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/incomeList/exportexcel`,
    method: "GET",
    responseType: "blob",
    params,
  };
}

