import { AlipayCircleFilled } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Form, Input, notification, Radio, Row } from "antd";
import cnchar from "cnchar";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../app/store";
import { Label } from "../../compnents/widgets/Label";
import { AccountType } from "../../models/accounttype";
import {
  GenderOptions,
  getGenderName,
  StationCategory,
  User,
} from "../../models/user";
import { ThemeContext } from "../../theme/ThemeContext";
import { getThirdPartyLoginUrl, unbindAccount } from "../login/loglinSlice";
import styles from "./Users.module.css";
import { createOrUpdateUser, getUserProfile } from "./userSlice";
import {pinyin} from "pinyin-pro";

export const UserCenter = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const theme = useContext(ThemeContext);

  const [form] = Form.useForm();

  const [profile, setProfile] = useState<User | undefined>();

  const [popup, setPopup] = useState<Window | null>(null);

  const [isDoctor, setIsDoctor] = useState(false);

  useEffect(() => {
    dispatch(getUserProfile())
      .then(unwrapResult)
      .then((profile) => {
        form.setFieldsValue(profile);
        setIsDoctor(
          profile.stationCategory === StationCategory.DoctorOrPharmacist
        );
        setProfile(profile);
      });
  }, []);

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 19,
    },
  };

  const bindAccount = (accountType: AccountType) => {
    const location = window.location;
    if (profile?.alipayUserId) {
      dispatch(unbindAccount(AccountType.Alipay))
        .then(() => dispatch(getUserProfile()))
        .then(unwrapResult)
        .then(setProfile)
        .catch(() => {
          // do nothing.
        });
    } else {
      dispatch(
        getThirdPartyLoginUrl({
          accountType,
          isLogin: false,
          redirectUrl: `${location.protocol}//${location.host}/userCenter/callback`,
        })
      )
        .then(unwrapResult)
        .then((url) => {
          setPopup(window.open(url));
        });
    }
  };

  useEffect(() => {
    const timer = popup
      ? setInterval(() => {
          if (popup.closed) {
            setPopup(null);
            dispatch(getUserProfile())
              .then(unwrapResult)
              .then((profile) => {
                form.setFieldsValue(profile);
                setIsDoctor(
                  profile.stationCategory === StationCategory.DoctorOrPharmacist
                );
                setProfile(profile);
              });
          }
        }, 1000)
      : undefined;
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [popup]);

  return (
    <div className={styles.main}>
      <Label label="用户信息" style={{ marginBottom: 10 }} />
      <Form
        form={form}
        onFinish={(values) => {
          dispatch(
            createOrUpdateUser({
              user: {
                ...profile,
                ...values,
              },
            })
          )
          .then(unwrapResult)
            .then(() => {
              notification.success({
                message: "修改成功",
                duration: 3,
              });
            })
            .then(() => {
              dispatch(getUserProfile());
            });
        }}
      >
        <Row gutter={20}>
          <Col span={10}>
            <Form.Item
              {...layout}
              name="name"
              label="姓名"
              rules={[
                { required: true, message: "姓名不能为空,请输入正确的值。" },
              ]}
            >
              <Input
                disabled={!!profile?.alipayUserId}
                autoComplete="off"
                onChange={(e) => {
                  form.setFieldsValue({
                    mnemonicCode: pinyin(e.target.value, { pattern: 'first', toneType: 'none' ,separator:''}).toUpperCase(),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              {...layout}
              name="gender"
              label="性别"
              required
              rules={[
                { required: true, message: "性别不能为空,请输入正确的值。" },
              ]}
            >
              <Radio.Group>
                {GenderOptions.map((g) => (
                  <Radio key={g} value={g}>
                    {getGenderName(g)}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={10}>
            <Form.Item
              {...layout}
              name="mnemonicCode"
              label="助记码"
              required
              rules={[
                { required: true, message: "助记码不能为空,请输入正确的值。" },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              {...layout}
              name="phone"
              label="手机号"
              required
              rules={[
                { required: true, message: "手机号不能为空,请输入正确的值。" },
                {
                  pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
                  message: "请输入正确的手机号",
                },

                {
                  max: 11,
                  message: "最长11位!",
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={10}>
            <Form.Item
              {...layout}
              name="identityCard"
              label="身份证号"
              rules={[
                {
                  pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                  message: "您的身份证格式不正确",
                },
                {
                  max: 18,
                  message: "最长18位!",
                },
              ]}
            >
              <Input disabled={!!profile?.alipayUserId} autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              {...layout}
              name="password"
              label="密码"
              required
              rules={[
                { required: true, message: "密码不能为空,请输入正确的值。" },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        {isDoctor ? (
          <>
            <Row gutter={20}>
              <Col span={10}>
                <Form.Item
                  {...layout}
                  name="doctorCode"
                  label="医师编号"
                  required
                  rules={[
                    {
                      required: true,
                      message: "医师编号不能为空,请输入正确的值。",
                    },
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  {...layout}
                  name="practiceCertificate"
                  label="执业证书"
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={10}>
                <Form.Item
                  {...layout}
                  name="qualificationCertificate"
                  label="资格证书"
                >
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item {...layout} name="email" label="电子邮件">
                  <Input autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <Row gutter={20}>
            <Col span={10}>
              <Form.Item {...layout} name="email" label="电子邮件">
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                {...layout}
                label="国家编码"
                name="countryCode"
                required
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={20}>
          <Col
            offset={10}
            span={10}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button
              className={styles.buttom_submit}
              type="primary"
              htmlType="submit"
            >
              保存
            </Button>
          </Col>
        </Row>
      </Form>
      {/*<Label label="账号绑定" style={{ marginTop: 20, marginBottom: 10 }} />*/}
      {/*<Row gutter={20} align="middle">*/}
      {/*  <Col span={10} style={{ fontSize: "0.875rem" }}>*/}
      {/*    <AlipayCircleFilled style={{ marginRight: 8 }} /> 支付宝{" "}*/}
      {/*    <span*/}
      {/*      style={{*/}
      {/*        fontSize: "0.75rem",*/}
      {/*        color: theme.tc3,*/}
      {/*        fontStyle: "italic",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {profile?.alipayUserId ? "已绑定" : "未绑定"}*/}
      {/*    </span>*/}
      {/*  </Col>*/}
      {/*  <Col*/}
      {/*    span={10}*/}
      {/*    style={{*/}
      {/*      display: "flex",*/}
      {/*      flexDirection: "column",*/}
      {/*      alignItems: "flex-end",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Button*/}
      {/*      type="primary"*/}
      {/*      size="small"*/}
      {/*      onClick={() => {*/}
      {/*        bindAccount(AccountType.Alipay);*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {profile?.alipayUserId ? "解绑" : "绑定"}*/}
      {/*    </Button>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </div>
  );
};
