/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-14 14:35:08
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-10 16:29:11
 */
import { Col, Modal, Row, Tooltip } from 'antd'
import React, { ReactElement } from 'react'
import { getAge } from '../../utils/StringUtils'
import styles from './followUpPage.module.css'

export const DetailModal = (props: {
  title: string
  visible: boolean
  detailDataInfo: any
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  return (
    <div>
      <Modal
        title={props.title}
        visible={props.visible}
        wrapClassName={styles.detailModalWrap}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          props.onCancel()
        }}
        footer={null}
        width={600}
      >
        {/* 个人信息 */}
        <div className={styles.detailWrap}>
          <Row>
            <div className={styles.detailContentTitle}>个人信息</div>
          </Row>
          <Row>
            <Col span={12}>患者姓名：{props.detailDataInfo?.patientName}</Col>
            <Col span={12}>
              患者性别：
              {props.detailDataInfo?.patientSex == 1
                ? '男'
                : props.detailDataInfo?.patientSex == 2
                ? '女'
                : '未知'}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              年龄：
              {props.detailDataInfo?.patientAge}岁
            </Col>
            <Col span={12}>联系电话：{props.detailDataInfo?.patientPhone}</Col>
          </Row>
        </div>

        {/* 就诊记录 */}
        <div className={styles.detailWrap}>
          <Row>
            <div className={styles.detailContentTitle}>就诊记录</div>
          </Row>
          <Row>
            <Col span={4} style={{ fontWeight: 'bold' }}>
              {props.detailDataInfo?.patientName}
            </Col>
            <Col span={20} style={{ color: '#027aff' }}>
              {props.detailDataInfo?.commonDisease}
            </Col>
          </Row>
          <Row>
            <Col span={4}>就诊医生：</Col>
            <Col span={5}>{props.detailDataInfo?.commonDoctorName}</Col>
            <Col span={4}>最近就诊：</Col>
            <Col span={8}>{props.detailDataInfo?.commonCreateTime}</Col>
          </Row>
        </div>

        {/* 随访记录 */}
        <div className={styles.detailWrap} style={{ height: '200px' }}>
          <Row>
            <div className={styles.detailContentTitle}>随访记录</div>
          </Row>
          <Row>
            <Col span={4}>患者姓名：</Col>
            <Col span={20}>{props.detailDataInfo?.patientName}</Col>
          </Row>
          <Row>
            <Col span={4}>随访类型：</Col>
            <Col span={20}>{props.detailDataInfo?.flag}</Col>
          </Row>
          <Row>
            <Col span={4}>随访内容：</Col>
            <Col span={20}>
              <Tooltip title={props.detailDataInfo?.content}>
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.detailDataInfo?.content}
                </div>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col span={4}>随访结果：</Col>
            <Col span={20}>
              <Tooltip title={props.detailDataInfo?.result}>
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.detailDataInfo?.result}
                </div>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col span={5}>随访人：{props.detailDataInfo?.userName}</Col>
            <Col span={9}>
              随访时间：{props.detailDataInfo?.visitTime?.slice(0, -8)}
            </Col>
            <Col span={9}>
              随访方式：
              {props.detailDataInfo?.way == 0
                ? '门诊'
                : props.detailDataInfo?.way == 1
                ? '电话'
                : props.detailDataInfo?.way == 2
                ? '微信'
                : '其他'}
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}
