/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-29 14:23:19
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../../app/applicationSlice'
import { RootThunkApi } from '../../../app/store'
import * as U from '../../../models/user'
import { User } from '../../../models/user'
import * as SU from '../../../services/personalDetail'
import {Request} from "../../../services/request";
import {API_PREFIX} from "../../../services/constant";

export const getUserProfile = createAsyncThunk<User, void, RootThunkApi<User>>(
  'user/getUserProfile',
  async (_, api) => {
    return sendAsync(SU.getUserProfile(), api).then((data: unknown) =>
      U.fromJson(data)
    )
  }
)

// 上传电子签名
export const uploadSignatureAsync = createAsyncThunk<
  void,
  SU.SignatureParams,
  RootThunkApi
>('user/uploadSignatureAsync', async (body, api) => {
  return sendAsync(SU.uploadSignature(body), api)
})

// 获取电子签名
export const getSignatureAsync = createAsyncThunk<void, string, RootThunkApi>(
  'user/getSignatureAsync',
  async (id, api) => {
    return sendAsync(SU.getSignature(id), api)
  }
)
// 获取电子签名
export const getSignatureEntityAsync = createAsyncThunk<
  void,
  SU.SignatureEntityParams,
  RootThunkApi
>('user/getSignatureAsync', async (id, api) => {
  return sendAsync(SU.getSignatureEntity(id), api)
})


// 删除电子签名
export const deleteSignatureAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi
>('user/getSignatureAsync', async (id, api) => {
  return sendAsync(SU.deleteSignature(id), api)
})
// 获取电子签名次数
export const getSignatureAsyncNum = createAsyncThunk<void, void, RootThunkApi>(
  'user/getSignatureAsyncNum',
  async (_, api) => {
    return sendAsync(SU.getSignatureAsyncNumFn(), api)
  }
)

export const getUserProfileList = createAsyncThunk<any, any, RootThunkApi>(
  'user/getUserProfileList',
  async (params, api) => {
    return sendAsync(SU.getUserProfileList(params), api).then((data) => {
      return data?.records
    })
  }
)
// 获取微诊所是否绑定
export const getWXBinding = createAsyncThunk<any, void, RootThunkApi>(
  'user/getWXBinding',
  async (_, api) => {
    return sendAsync(SU.getWXBindingFn(), api)
  }
)
// 解除微诊所绑定
export const removeBinding = createAsyncThunk<any, void, RootThunkApi>(
  'user/removeBinding',
  async (_, api) => {
    return sendAsync(SU.removeBindingFn(), api)
  }
)

export const createOrUpdateUser = createAsyncThunk<
  void,
  SU.CreateUserParams,
  RootThunkApi<void>
>('user/createOrUpdateUserStatus', async (params, api) => {
  return sendAsync(SU.createOrUpdateUser(params), api).then(() => {
    // do nothing.
  })
})

export const saveSetting = createAsyncThunk<void, any, RootThunkApi>(
  'user/createOrUpdateUserStatus',
  async (body, api) => {
    return sendAsync(SU.saveSettingLable(body), api).then(() => {
      // do nothing.
    })
  }
)
export const saveCwcaOptId = createAsyncThunk<void, any, RootThunkApi>(
  'user/cwca/saveCwcaOptId',
  async (body, api) => {
    return sendAsync(SU.saveCwcaOptId(body), api).then(() => {
      // do nothing.
    })
  }
)
export const setShopType = createAsyncThunk<
  void,
  SU.shopTypeBody,
  RootThunkApi
>('user/setShopType', async (body, api) => {
  return sendAsync(SU.setShopType(body), api)
})

export const getShopType = createAsyncThunk<
  any,
  SU.shopTypeParams,
  RootThunkApi
>('user/setShopType', async (params, api) => {
  return sendAsync(SU.getShopType(params), api)
})

export const changePassword = createAsyncThunk<
  any,
  SU.changePasswordBody,
  RootThunkApi<void>
>('user/changePassword', async (body, api) => {
  return sendAsync(SU.getChangePasswordSave(body), api).then(() => {
    // do nothing.
  })
})
