import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import * as D from '../../models/delivery'
import { DeliveryResult } from '../../models/delivery'
import { fromMaterialJson, Material } from '../../models/material'
import { Page, startPageOf } from '../../models/page'
import * as R from '../../models/registration'
import { Registration } from '../../models/registration'
import * as T from '../../models/treatment'
import { Treatment } from '../../models/treatment'
import * as SD from '../../services/delivery'
import {
  DeliveryDetailParams,
  DeliveryListParams,
  DirectDeliveryParams,
} from '../../services/delivery'

export type DeliveryItem = [Registration, Treatment]

interface DeliveryState {
  pages: {
    [D.DeliveryState.Unfilled]: Page<DeliveryItem>
    [D.DeliveryState.Fulfilled]: Page<DeliveryItem>
    [D.DeliveryState.Returned]: Page<DeliveryItem>
  }
}

const initialState = {
  pages: {
    [D.DeliveryState.Unfilled]: startPageOf<DeliveryItem>(),
    [D.DeliveryState.Fulfilled]: startPageOf<DeliveryItem>(),
    [D.DeliveryState.Returned]: startPageOf<DeliveryItem>(),
  },
} as DeliveryState

export const getDeliveryList = createAsyncThunk<
  Page<DeliveryItem>,
  DeliveryListParams,
  RootThunkApi<Page<DeliveryItem>>
>('deliver/getDeliveryListStatus', async (params, api) => {
  return sendAsync(SD.getDeliveryList(params), api).then(
    (data: Record<string, never>) => (
      {
      current: data.current,
      size: data.size,
      total: data.total,
      items: _.chain(data.records)
        .map(
          (d) =>
            [
              R.fromJson(d.registration),
              T.fromJson({ ...d.treatment, payStatus: d?.payStatus,ybSaleUploadStatus : d?.ybSaleUploadStatus }),
            ] as DeliveryItem
        )
        .value(),
    })
  )
})

export const getDeliveryDetail = createAsyncThunk<
  Page<Material>,
  DeliveryDetailParams,
  RootThunkApi<Page<Material>>
>('deliver/getDeliveryDetailStatus', async (params, api) => {
  return sendAsync(SD.getDeliveryDetail(params), api).then(
    (data: Record<string, never>) => ({
      current: data.current,
      size: data.size,
      total: data.total,
      items: _.chain(data.records)
        .map((m) => fromMaterialJson(m))
        .value(),
    })
  )
})

export const directDeliver = createAsyncThunk<
  DeliveryResult,
  DirectDeliveryParams,
  RootThunkApi<DeliveryResult>
>('deliver/directDeliverStatus', async (params, api) => {
  return sendAsync(SD.directDeliver(params), api).then(
    (data: Record<string, never>) => {
      return {
        successful: data.result,
        message: data.msg,
        errors:
          _.chain(data.shipmentVO)
            .map((m) => ({
              name: m.name,
              count: m.drugCount,
              message: m.inventoryInfo || m.unShelveInfo,
            }))
            .value() || [],
      } as DeliveryResult
    }
  )
})

export const directDeliverSingleItem = createAsyncThunk<
  DeliveryResult,
  SD.DirectDeliverItemParams,
  RootThunkApi<void>
>('deliver/directDeliverSingleItemStatus', async (params, api) => {
  return sendAsync(SD.directDeliverSingleItem(params), api).then(
    (data: Record<string, never>) => {
      return {
        successful: data.result,
        message: data.msg,
        errors: _.chain(data.shipmentVO)
          .map((m) => ({
            name: m.name,
            count: m.drugCount,
            message: m.inventoryInfo || m.unShelveInfo,
          }))
          .value(),
      } as DeliveryResult
    }
  )
})

export const getMaterialBatchInfo = createAsyncThunk<
  Material[],
  Material,
  RootThunkApi<Material[]>
>('delivery/getMaterialBatchInfoStatus', async (param, api) => {
  return sendAsync(SD.getMaterialBatchInfo(param), api).then(
    (data: Record<string, never>) => {
      return _.chain(data.records)
        .map((m) => fromMaterialJson(m))
        .value()
    }
  )
})

export const batchDeliver = createAsyncThunk<
  void,
  SD.BatchDeliveryParams,
  RootThunkApi<void>
>('delivery/batchDeliverStatus', async (param, api) => {
  return sendAsync(SD.batchDeliver(param), api)
})

// 库房发药导出
export const getDeliveryListExoprt = createAsyncThunk<
  void,
  DeliveryListParams,
  RootThunkApi
>('doctorAudit/getDeliveryListExoprt ', async (params, api) => {
  return sendAsync(SD.getDeliveryListExoprtFn(params), api)
})

const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDeliveryList.fulfilled, (state, action) => {
      state.pages[action.meta.arg.shipmentsState] = action.payload
    })
  },
})

export const selectDeliveryListPage = (deliveryState: D.DeliveryState) => (
  state: RootState
): Page<DeliveryItem> => state.delivery.pages[deliveryState]

export default deliverySlice.reducer
