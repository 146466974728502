import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Table,
} from "antd";
import { ThemeContext } from "../../theme/ThemeContext";
import { RootDispatch } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { Query } from "./Query";
import moment from "moment";
import { DateRangeType, getDateRange } from "../../compnents/form/DateSelect";
import {
  DateTimeFormat,
  DateTimeFormatSimpleOnlyOne,
} from "../../models/datetime";
import { EditableList } from "../../compnents/list/EditableList";
import { RegistrationColumns } from "./columns";
import { Columns } from "../transactionQuery/TransactionQuery/columns";
import { getTransactionQueryList } from "../transactionQuery/transactionQuerySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import styles from "./treatmentLog.module.css";
import { set, sum } from "lodash";
import { selectEdition } from "../../app/applicationSlice";
import { allPaymentOperatioEditLogPage, getChangePayTimeAndMethod, getPaymentDetailRecord } from "./treament";
import { getPaymentWayList } from "../payment/confirm/paymentConfirmSlice";
import { use } from "echarts/types/src/extension";
import { PayMethod } from "../../models/payment";
import _ from "lodash";
import { CommodityLastEditModal } from "../commodity/CommodityLastEditModal";
import { Registration } from "../../models/registration";

export const RegistrationList = (): ReactElement => {
  const theme = useContext(ThemeContext);

  const [formModal] = Form.useForm();

  const dispatch = useDispatch<RootDispatch>();

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today);

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname + "_jiaoyichaxun") || "{}"
  );

  const [pageLoading, setPageLoading] = useState(false);

  const [page, setPage] = useState<any>();

  const [params, setParams] = useState<any>({
    current: storageParams?.current ? Number(storageParams?.current) : 1,
    size: 20,
  });

  const [scrollHeight, setScrollHeight] = useState<any>();

  const [totalData, setTotalData] = useState<any>();
  
  const hisVersion = useSelector(selectEdition); //his版本 0通用 1口腔

  const [loading, setLoading] = useState<boolean>(false);

  const [visible, setVisible] = useState(false);

  const [rowData, setRowData] = useState<any>();

  const [paymentList, setPaymentList] = useState<any>([
    { id: PayMethod.Cash, methodName: "现金" },
    { id: PayMethod.Juhe, methodName: "聚合支付" },
    { id: PayMethod.Weipay, methodName: "微信" },
    { id: PayMethod.Alipay, methodName: "支付宝" },
    { id: PayMethod.UnionPay, methodName: "银联" },
    // { id: PayMethod.HY, methodName: "会员" },
  ]);
  
  const [paymentWay, setPaymentWay] = useState("");

  const [payMethod, setPayMethod] = useState<any[]>([]);

  const [selectZuHeOrNoZuHeList, setSelectZuHeOrNoZuHeList] = useState<any>();

  const inputRefs = selectZuHeOrNoZuHeList?.map(() => React.createRef());

  const [systemPayMethod, setSystemPayMethod] = useState<any[]>([]);

  const [paymentState, setPaymentState] = useState<any>(); //0 只有会员 1 有会员有其他 2 没会员

  const [paymentHY, setPaymentHY] = useState<any>(); //会员支付的数据

  const [paymentId, setPaymentId] = useState<any>();

  const [isCtrl, setIsCtrl] = useState(false); //是否按住ctrl

  const [isEdit, setIsEdit] = useState(false); // 修改按钮的显示

  const [lastEditVisable, setLastEditVisable] = useState(false);

  const [lastEditId, setLastEditId] = useState<any>();

  const [editVisible, setEditVisible] = useState(false);

  const [queries, setQueries] = useState<any>({
    current: 1,
    size: 10,
  });

  const [modalPage, setModalPage] = useState<any>({
    total: 0,
    items: [],
  });

  const [pageLoadingModal, setPageLoadingModal] = useState(false);

  const getPage = () => {
    dispatch(getTransactionQueryList({ ...params, selectedTab: undefined }))
      .then(unwrapResult)
      .then((v: any) => {
        onResize(); // 初始化设置高度
        window.addEventListener("resize", onResize);
        setPage({
          items: v?.records,
          size: v?.size,
          total: v?.total,
          current: v?.current,
        });
        setPageLoading(false);
      });
  };
  useEffect(() => {
    setPageLoading(true);
    if (params?.registrationTimeHead && params?.registrationTimeTail) {
      getPage();
    }
  }, [params]);

  useEffect(() => {
    dispatch(getPaymentWayList())
      .then(unwrapResult)
      .then((v: any) => {
        const list = v?.records?.filter((t: any) => t?.status == 1);
        setPaymentList([...paymentList, ...list]);
      });
  }, []);

  const onResize = () => {
    const table = document.getElementById("tableHeights");
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 20;
      setScrollHeight(height);
    } else {
      return "100%";
    }
  };

  useEffect(() => {
    // 组合支付选中的列表
    const selectZuHeList = paymentList?.filter((v: any) => {
      return payMethod?.some((i: any) => i == v?.id);
    });
    console.log(inputRefs);

    // 使用排序函数对数组进行排序
    const sortedData = selectZuHeList?.sort((a: any, b: any) => a.id - b.id);
    setSelectZuHeOrNoZuHeList(sortedData);
    setSystemPayMethod(
      selectZuHeList?.map((v: any) => {
        return {
          payAmount: "0.00",
          payMethod: v?.id,
        };
      })
    );
  }, [paymentList, payMethod]);

  // 计算合计数据
  useEffect(() => {
    const data = page?.items?.filter((v: any) => v?.state != 5);
    if (data?.length) {
      // 没勾选的情况下计算全部
      const totalPriceAll = sum(
        data?.map((v: any) => (v?.totalPrice > -1 ? Number(v?.totalPrice) : 0))
      );
      const insurancePayAmountAll = sum(
        data?.map((v: any) =>
          v?.insurancePayAmount > -1 ? Number(v?.insurancePayAmount) : 0
        )
      );
      const cashAmountAll = sum(
        data?.map((v: any) => (v?.cashAmount > -1 ? Number(v?.cashAmount) : 0))
      );
      const arrearageAmountAll = sum(
        data?.map((v: any) =>
          v?.arrearageAmount > -1 ? Number(v?.arrearageAmount) : 0
        )
      );
      const overlmtSelfpayAll = sum(
        data?.map((v: any) =>
          v?.overlmtSelfpay > -1 ? Number(v?.overlmtSelfpay) : 0
        )
      );

      setTotalData({
        totalPriceAll: totalPriceAll,
        insurancePayAmountAll: insurancePayAmountAll,
        cashAmountAll: cashAmountAll,
        arrearageAmountAll: arrearageAmountAll,
        overlmtSelfpayAll: overlmtSelfpayAll,
      });
    } else {
      setTotalData({
        totalPriceAll: 0.0,
        insurancePayAmountAll: 0.0,
        cashAmountAll: 0.0,
        arrearageAmountAll: 0.0,
        overlmtSelfpayAll: 0.0,
      });
    }
  }, [page]);

  useEffect(() => {
    dispatch(allPaymentOperatioEditLogPage(queries))
      .then(unwrapResult)
      .then((res: any) => {
        setModalPage({
          items: res?.records,
          total: res?.total,
        });
      });
  }, [queries]);

  
document.addEventListener("keydown", function (event) {
  if (event.ctrlKey) {
    setIsCtrl(true);
  }
  document.onkeyup = function () {
    setIsCtrl(false);
  };

});
  return (
    <Col
      style={{
        margin: "0px 0px",
        height: "calc(100% - 30px)",
        padding: "0px 5px",
        borderRadius: theme.br,
        backgroundColor: theme.pn,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Query
        onValueChange={(v: any) => {
          const paramData = {
            ...params,
            ...v,
            registrationTimeHead: v?.timeHead
              ? moment(v?.timeHead).format(DateTimeFormatSimpleOnlyOne)
              : moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
            registrationTimeTail: v?.timeTail
              ? moment(v?.timeTail).format(DateTimeFormatSimpleOnlyOne)
              : moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
            timeHead: undefined,
            timeTail: undefined,
            current: v?.current
              ? v?.current
              : storageParams?.current
              ? storageParams?.current
              : 1,
          };
          setParams({
            ...paramData,
          });
        }}
        onClick={() => {
          dispatch(allPaymentOperatioEditLogPage(queries))
            .then(unwrapResult)
            .then((res: any) => {
              setModalPage({
                items: res?.records,
                total: res?.total,
              });
              setEditVisible(true);
            });
        }}
      />
      <div
        id="tableHeights"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <EditableList
          // className={flag ? styles.table : ''}
          style={{
            width: "100%",
            height: "89%",
            overflow: "hidden",
          }}
          scroll={{
            y: Number(scrollHeight),
            x: (hisVersion == 1 && 1900) as any,
          }}
          loading={pageLoading}
          page={page}
          columns={RegistrationColumns(
            page?.size * (page?.current - 1),
            isEdit,
            (action, id: any, t, settlementCategory) => {
              switch (action) {
                case "return":
                  dispatch(getPaymentDetailRecord({ paymentId: id }))
                    .then(unwrapResult)
                    .then((res: any) => {
                      setVisible(true);
                      setRowData(t);
                      const isHY = res?.filter(
                        (v: any) => v.payMethodName == "会员卡"
                      );
                      setPaymentHY(isHY?.[0]);
                      setPaymentId(id);
                      setPaymentState(
                        isHY?.length > 0 && res?.length == 1
                          ? 0
                          : isHY?.length > 0 && res?.length > 1
                          ? 2
                          : 3
                      );
                      let paystr = "";
                      res?.forEach((v: any) => {
                        paystr += v?.payMethodName + v?.realPayAmount + "元,";
                      });
                      paystr = paystr.substring(0, paystr.length - 1);
                      setPaymentWay(paystr);
                      formModal.setFieldsValue({
                        chargeOrRefundTime: t?.chargeOrRefundTime
                          ? moment(t?.chargeOrRefundTime)
                          : "",
                        patientName: t?.patientName,
                      });
                    });
                  break;
                case "detail":
                  setLastEditVisable(true);
                  setLastEditId(id);
              }
            }
          ).map((col) => {
            return {
              ...col,
              onHeaderCell: (cell: any) => ({
                ...cell,
                onDoubleClick: () => {
                  if (cell?.title === "操作" && isCtrl) {
                    setIsEdit(true);
                  }
                },
              }),
            };
          })}
          onChangePage={(current, size) => {
            setParams({
              ...params,
              current,
              size,
            });
          }}
          rowKey={(m) => m.paymentId}
          sticky
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell
                  index={0}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={1}
                  align="center"
                  className={styles.fixed}
                >
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                  {totalData?.totalPriceAll?.toFixed(2)}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={6} align="right">
                  {totalData?.insurancePayAmountAll?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} align="right">
                  {totalData?.cashAmountAll?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} align="right">
                  {totalData?.arrearageAmountAll?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} align="right">
                  {totalData?.overlmtSelfpayAll?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={10}
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={12}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={15}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={16}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={17}
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </div>
      <Modal
        title="收费时间修改"
        width="500px"
        okText="确定"
        cancelText="取消"
        visible={visible}
        confirmLoading={loading}
        destroyOnClose
        onOk={(e) => {
          setLoading(true);
          formModal
            .validateFields()
            .then((values) => {
              console.log(inputRefs);
              const paymethods: any = [];
              selectZuHeOrNoZuHeList.forEach((v: any, index: any) => {
                paymethods.push({
                  realPayAmount: Number(
                    systemPayMethod?.[index].payAmount
                  ).toFixed(2),
                  payMethodName: v?.methodName,
                  payMethodId: v?.id,
                });
              });
              if (paymentState == 0 || paymentState == 2) {
                paymethods.push({
                  realPayAmount: paymentHY?.realPayAmount,
                  payMethodName: paymentHY?.payMethodName,
                  payMethodId: PayMethod.HY,
                });
              }
              dispatch(
                getChangePayTimeAndMethod({
                  paymentId: paymentId,
                  paymentUpdateTime: values?.chargeOrRefundUpdataTime
                    ? moment(values?.chargeOrRefundUpdataTime).format(
                        DateTimeFormat
                      )
                    : "",
                  changePaymentHistoryDTO: paymethods,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: "操作成功",
                  });
                  formModal.resetFields();
                  setSelectZuHeOrNoZuHeList([]);
                  setPaymentHY({});
                  setVisible(false);
                  setLoading(false);
                  getPage();
                });
            })
            .catch(() => {
              // Do nothing.
            })
            .finally(() => {
              setLoading(false);
            });
        }}
        onCancel={() => {
          formModal.resetFields();
          setSelectZuHeOrNoZuHeList([]);
          setPaymentHY({});
          setVisible(false);
        }}
      >
        <Form
          form={formModal}
          autoComplete="off"
          labelAlign="right"
          labelCol={{ span: 6 }}
        >
          <Form.Item name="patientName" label="姓名">
            <Input style={{ width: "200px" }} disabled />
          </Form.Item>
          <Form.Item name="chargeOrRefundTime" label="收费时间">
            <DatePicker
              placeholder="请选择"
              format="YYYY/MM/DD HH:mm:ss"
              disabled
            />
          </Form.Item>
          <Form.Item name="chargeOrRefundUpdataTime" label="修改收费时间">
            <DatePicker
              showTime
              placeholder="请选择"
              format="YYYY-MM-DD hh:mm:ss"
            />
          </Form.Item>
          <Form.Item label="支付方式">{paymentWay}</Form.Item>
          <Form.Item label="修改支付方式" name="paymentUpdata">
            <Select
              allowClear
              placeholder="支付方式"
              mode="multiple"
              onChange={(value) => {
                if (value.length > paymentState) {
                  // message.error("已超过最大数量");
                  formModal.setFieldsValue({
                    paymentUpdata: _.take(value, paymentState),
                  });
                  setPayMethod(_.take(value, paymentState));
                  // setPayMethod([PayMethod.HY, ..._.take(value, paymentState)]);
                } else {
                  setPayMethod(value);
                  // if (paymentState == 2) {
                  //   setPayMethod([PayMethod.HY, ...value]);
                  // } else {

                  // }
                }
              }}
            >
              {paymentList?.map((d: any) => (
                <Select.Option key={d.id} value={d.id} disabled={d.id == 6}>
                  {d?.methodName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {paymentState == 0 || paymentState == 2 ? (
            <>
              <Row align="middle" style={{ textAlign: "right", marginTop: 30 }}>
                <Col flex="7rem" style={{ marginLeft: "-10px" }}>
                  {paymentHY?.payMethodName + " : "}
                </Col>
                <Col style={{ marginLeft: "10px" }}>
                  <Input
                    style={{
                      width: "18rem",
                      marginLeft: 10,
                      fontSize: "1rem",
                      color: theme.tc1,
                    }}
                    key={0}
                    maxLength={12}
                    inputMode="decimal"
                    value={paymentHY?.realPayAmount}
                    defaultValue={"0.00"}
                    disabled
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
          <>
            {selectZuHeOrNoZuHeList?.map((k: any, index: any) => {
              return (
                <>
                  <Row
                    align="middle"
                    style={{ textAlign: "right", marginTop: 30 }}
                  >
                    <Col flex="7rem" style={{ marginLeft: "-10px" }}>
                      {k?.methodName + " : "}
                    </Col>
                    <Col style={{ marginLeft: "10px" }}>
                      <Input
                        style={{
                          width: "18rem",
                          marginLeft: 10,
                          fontSize: "1rem",
                          color: theme.tc1,
                        }}
                        maxLength={12}
                        ref={inputRefs[index]} // 分配 inputRef 给 Input
                        onClick={() => {
                          systemPayMethod?.[index]?.payAmount &&
                            inputRefs[index].current?.select(); // 选择文本
                        }}
                        inputMode="decimal"
                        value={systemPayMethod
                          ?.find((n) => n?.payMethod == k?.id)
                          ?.payAmount?.toString()}
                        defaultValue={"0.00"}
                        onChange={(e) => {
                          const { value } = e.target;
                          // 使用正则表达式验证输入值
                          const regex = /^\d+(\.\d{0,2})?$/;
                          if (regex.test(value) || value === "") {
                            const updatedValues: any = systemPayMethod
                              ? [...systemPayMethod]
                              : [];

                            updatedValues[index] = {
                              payAmount: value,
                              payMethod: k?.id,
                            };
                            setSystemPayMethod(updatedValues);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </>
              );
            })}
          </>
        </Form>
      </Modal>

      <CommodityLastEditModal
        visible={lastEditVisable}
        title={"修改记录"}
        id={lastEditId}
        onOk={() => {
          setLastEditVisable(false);
          setLastEditId("");
        }}
        onCancel={() => {
          setLastEditVisable(false);
          setLastEditId("");
        }}
        operateType={4}
      />

      <Modal
        title="修改记录"
        width={"80vw"}
        okText="确定"
        cancelText="取消"
        visible={editVisible}
        destroyOnClose
        onCancel={() => {
          setEditVisible(false);
        }}
        onOk={() => {
          setEditVisible(false);
        }}
      >
        <EditableList<Registration>
          page={{ ...modalPage, ...queries }}
          loading={pageLoadingModal}
          columns={[
            {
              title: "序号",
              key: "no",
              align: "center",
              fixed: "left",
              width: "4em",
              render: (_1, _2, i) =>
                `${(queries.current - 1) * queries.size + i + 1}`,
            },
            {
              title: "患者姓名",
              dataIndex: "operateItem",
              width: "10em",
              align: "center",
            },
            {
              title: "修改内容",
              dataIndex: "patientName",
              width: "30em",
              align: "center",
              render: function Element(_, t: any) {
                return (
                  <span>
                    {t?.operateAttr} 由 {t?.beforeValue || "空"} 修改为{" "}
                    {t?.afterValue || "空"}
                  </span>
                );
              },
            },
            {
              title: "操作员",
              dataIndex: "operateName",
              width: "10em",
              align: "center",
              // render: (_, t) => t.operateName,
            },
            {
              title: "修改时间",
              dataIndex: "operateTime",
              width: "20em",
              align: "center",
            },
          ]}
          onChangePage={(current, size) => {
            setQueries({ ...queries, current, size });
          }}
          style={{ maxHeight: "440px" }}
        />
      </Modal>
    </Col>
  );
};
