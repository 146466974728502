/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2022-01-14 16:03:07
 */
import { Button, TableColumnType } from "antd"
import React from "react"
import { Dimen } from "../../../models/dimen"

export const SurgicalWordColumns = (): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      width: Dimen.Num,
      render: (_1, _2, index) => `${index + 1}`,
    },
    {
      title: "文件名称",
      align: "left",
      dataIndex: "name",
      width: "16rem",
    },
    {
      title: "文件下载",
      align: "center",
      dataIndex: "",
      width: "8rem",
      key: "x",
      render: function edit(_, t) {
        return (
          <Button
            type='primary'
            size='middle'
            onClick={() => {
              window.open(t.src, "_blank")
            }}
          >
            下载
          </Button>
        )
      },
    },
  ]
}
