/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-19 14:33:03
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-16 12:03:41
 */
import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Dropdown, Input, InputProps, Pagination, Table } from "antd";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../../app/store";
import { Material } from "../../../models/material";
import { Page, startPageOf } from "../../../models/page";
import { RecipeCategory } from "../../../models/recipe";
import { ThemeContext } from "../../../theme/ThemeContext";
import { getLinKangDurgAsync } from "../../commodity/commoditySlice";
import { ItemCompletionType } from "../../completion/completionSlice";
import { RecipeItemColumnsSimple } from "../../treatment/editor/components/columns";
import styles from "../Commodity.module.css";

interface RecipeItemInputProps {
  source?: number;
  materialsCategory?: number;
  type?: ItemCompletionType;
  category?: RecipeCategory;
  filters?: boolean;
  defaultUsingSpecifiedBatch?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  onSelect?: (material: Material, ignoreStorage: boolean) => void;
  style?: React.CSSProperties;
}

export const RecipeItemInput = ({
  source,
  materialsCategory,
  value,
  onChange,
  onSelect,
  onBlur,
  onPressEnter,
  style,
}: RecipeItemInputProps &
  Pick<InputProps, "onBlur" | "onPressEnter">): ReactElement => {
  const theme = useContext(ThemeContext);

  const dispatch = useDispatch<RootDispatch>();

  const [isIgnoringStorage, setIsIgnoringStorage] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const [iptValue, setIptValue] = useState('');

  const [page, setPage] = useState<Page<Material>>(startPageOf());

  const [selectedRowId, setSelectedRowId] = useState("");
  const complete = (keyword: string, current: number,size?:any) => {
    if (keyword) {
      if (source === 0 || source === 2) {
        dispatch(
          getLinKangDurgAsync({
            name: keyword,
            materialsCategory,
            current,
            size:size || 5,
            insuranceCode: source == 0 ? 'ZIFEI' : 'ZHEJIANG'
          })
        )
          .then(unwrapResult)
          .then((page) => {
            setPage(page);
            setIsVisible(true);
            if (page.items.length > 0) {
              setSelectedRowId(page.items[0].id);
            } else {
              setSelectedRowId("");
            }
          });
      }
    } else {
      reset();
    }
  };

  useEffect(() => {
    setIptValue('')
  }, [source])

  const overlay = (
    <Col>
      <Table
        className={styles.table}
        columns={RecipeItemColumnsSimple(theme)}
        dataSource={page.items}
        size="small"
        pagination={false}
        rowKey={(m) => m.id}
        bordered
        onRow={(m) => ({
          onClick: () => {
            if (onSelect) {
              onSelect(m, isIgnoringStorage);
              reset();
            }
          },
        })}
        // rowSelection={{
        //   type: "radio",
        //   selectedRowKeys: [selectedRowId],
        //   onChange: (ks) => setSelectedRowId(ks[0] as string),
        // }}
      />
      <Pagination
        pageSize={page.size}
        current={page.current}
        total={page.total}
        pageSizeOptions={['5','10', '20', '50', '100', '500']}
        size="small"
        style={{
          margin: "10px 10px",
        }}
        onChange={(current,size) => {
          complete(value || "", current,size);
        }}
      />
    </Col>
  );
  const reset = () => {
    setPage(startPageOf());
    setSelectedRowId("");
    setIsVisible(false);
  };

  return (
    <Dropdown
      overlay={overlay}
      visible={isVisible}
      getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
      onVisibleChange={(v) => setIsVisible(v)}
    >
      <Input
        value={iptValue}
        style={style}
        onBlur={onBlur}
        onChange={(e) => {
          setIptValue(e.target.value)
          complete(e.target.value, 1);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        onKeyDown={(event) => {
          event.stopPropagation();
          if (!selectedRowId) {
            return;
          }
          let index = page.items.findIndex((m) => m.id === selectedRowId);
          switch (event.key) {
            case "ArrowDown":
              index = index + 1;
              break;
            case "ArrowUp":
              index = index - 1;
              break;
          }
          if (index >= 0 && index < page.items.length) {
            setSelectedRowId(page.items[index].id);
          }
        }}
        onPressEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onPressEnter && onPressEnter(e);
          if (!selectedRowId || !onSelect) {
            return;
          }
          const item = page.items.find((m) => m.id === selectedRowId);
          if (item) {
            onSelect(item, isIgnoringStorage);
            reset();
          }
        }}
      />
    </Dropdown>
  );
};
