export interface DrugCostEntry {
  recipeCount: number;
  totalCost: number;
  totalDiff: number;
  totalRetail: number;
  treatmentDoctorId: number;
  treatmentDoctorName: string;
}

export function fromJson(json: Record<string, never>): DrugCostEntry {
  return {
    recipeCount: json.recipeCount,
    totalCost: json.totalCost,
    totalDiff: json.totalDiff,
    totalRetail: json.totalRetail,
    treatmentDoctorId: json.treatmentDoctorId,
    treatmentDoctorName: json.treatmentDoctorName,
  };
}
