import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  TimePicker,
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  getPayMethodName,
  PayMethod,
  PayMethods,
} from '../../../models/payment'
import { Checks, Settinglable, Settings } from '../../../models/setting'
import {
  getTenantCategoryName,
  Tenant,
  TenantCategory,
} from '../../../models/tenant'
import { stringTrim } from '../../../utils/StringUtils'
import { setSettings } from '../../settings/settingsSlice'
import {
  getSettingDetail,
  saveSettingLabel,
  selectNextSendDay,
  selectSettings,
  selectTenant,
  setIsChange,
  setNextSendDay,
} from './settingSlice'
import styles from '../Users.module.css'
import { selectInsuranceArray } from '../../../app/applicationSlice'

export const Setting = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const { TextArea } = Input

  const tenant = useSelector(selectTenant)

  const insuranceArray = useSelector(selectInsuranceArray)

  const settings = useSelector(selectSettings)

  const nextSendDay = useSelector(selectNextSendDay)

  const [previewPayMethod, setPreviewPayMethod] = useState(PayMethod.Cash)

  useEffect(() => {
    if (tenant) {
      form.setFieldsValue({
        ...tenant,
        registeredFund: null,
        medicalOrganCode: tenant?.medicalOrganCode,
        // || insuranceArray?.[0]?.countryHospitalNb
        // tenantCategory: getTenantCategoryName(tenant.tenantCategory)
      })
    }
    if (settings) {
      const defaultMethod =
        (parseInt(settings[Checks.Mrzffs]) as PayMethod) || PayMethod.Cash
      setPreviewPayMethod(defaultMethod)
      dispatch(
        setSettings({
          [Settings.Treatment.DoNotWarnDoctorMismatch]:
            settings[Checks.Gbkdysstx] || false,
          [Settings.Treatment.Settlement]: settings[Checks.Zjjs] || false,
          [Settings.Recipe.ChangePrice]: !(settings[Checks.Gbxgjg] || false),
          [Settings.Payment.DefautMethod]: defaultMethod,
        })
      )
      const value = settings[Checks.Zdrjsj]
      form.setFieldsValue({
        ...settings,
        [Checks.Zdrjsj]:
          value === '' || value === undefined || value === 'Invadlid date'
            ? undefined
            : moment(value, 'HH:mm'),
      })
    }
  }, [tenant, settings])

  useEffect(() => {
    dispatch(getSettingDetail())
  }, [])

  const formGutter = 20

  const firstColumnLayout = {
    labelCol: {
      flex: '0 0 7rem',
    },
  }

  const secondColumnLayout = {
    labelCol: {
      flex: '0 0 8.25rem',
    },
  }

  const thirdColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  return (
    <div
      style={{
        height: 'calc(100%)',
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
        borderRadius: '10px',
        overflowY: 'scroll',
      }}
    >
      <Form
        labelAlign='right'
        initialValues={{
          remember: true,
          [Checks.ISEDIT]: '1',
          [Checks.FYFS]: '1',
        }}
        form={form}
        autoComplete='off'
        onFinish={(values) => {
          if (
            (values[Checks.SJHM] && !values[Checks.TSPL]) ||
            (!values[Checks.SJHM] && values[Checks.TSPL])
          ) {
            notification.error({
              message: '请完善手机号码或推送频率',
            })
            return
          }
          const pattern = /^[0-9]*$/
          const t = _.chain(values)
            .omitBy((_, k) => pattern.test(k))
            .value() as Tenant
          const f = _.chain(values)
            .pickBy((_, k) => pattern.test(k))
            .mapValues(
              (v, k) =>
                ({
                  type: k,
                  value:
                    k === Checks.Zdrjsj.toString()
                      ? moment(v).format('HH:mm:ss')
                      : v?.toString(),
                } as Settinglable)
            )
            .values()
            .value()
          dispatch(
            saveSettingLabel({
              tenant: {
                ...tenant,
                ...t,
              },
              functionList: [{ type: '-99', value: '-99' }],
            })
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: '机构设置保存成功',
                duration: 3,
              })
              dispatch(getSettingDetail())
            })
            .catch(() => {
              notification.error({ message: '机构设置保存失败', duration: 3 })
              dispatch(getSettingDetail())
            })
            .finally(() => {
              dispatch(setIsChange(false))
            })
        }}
        onValuesChange={(values, all) => {
          dispatch(setIsChange(true))
        }}
      >
        {/* <Row
          style={{
            height: "40px",
            lineHeight: "40px",
            display: "flex",
            alignItems: "center",
            fontSize: "15px",
            borderBottom: "1px solid #e6e6e6",
            fontWeight: 700,
          }}
        >
          <i
            style={{
              width: "2px",
              height: "10px",
              backgroundColor: "#0052D9",
              margin: "-14px  10px 0 30px",
            }}
          ></i>
          <p>机构基本信息</p>
        </Row> */}
        <div style={{ marginTop: '20px' }}>
          <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='机构名称'
                name='name'
                rules={[
                  { required: true, message: '机构名称不能为空' },
                  { max: 500, message: '最长500位' },
                ]}
              >
                <Input autoComplete='off' disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...thirdColumnLayout}
                label='机构略称'
                name='shortName'
                rules={[
                  { required: true, message: '机构略称不能为空' },
                  { max: 120, message: '最长120位' },
                ]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                label='医院编号'
                name='hospitalCode'
                rules={[{ max: 120, message: '最长120位' }]}
              >
                <Input autoComplete='off' disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label='机构类别'
                name='tenantCategory'
                {...secondColumnLayout}
              >
                <Select>
                  {[
                    TenantCategory.hospital,
                    TenantCategory.maternityHealthCenter,
                    TenantCategory.communityHealthServiceCenter,
                    TenantCategory.surgery,
                    TenantCategory.nursingHome,
                    TenantCategory.outpatientDepartment,
                    TenantCategory.Clinic,
                    TenantCategory.villageHealthClinic,
                    TenantCategory.emergencyCenter,
                    TenantCategory.clinicalLaboratory,
                    TenantCategory.specializedDiseasePreventionAndTreatmentInstitution,
                    TenantCategory.nursingStation,
                    TenantCategory.center,
                    TenantCategory.Pharmacy,
                    TenantCategory.other
                  ].map((c) => (
                    <Select.Option key={c} value={c}>
                      {getTenantCategoryName(c)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...thirdColumnLayout}
                label='医疗机构编号'
                name='medicalOrganCode'
                rules={[{ max: 120, message: '最长120位!' }]}
              >
                <Input autoComplete='off' disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={formGutter}
            align='middle'
            style={{ marginLeft: 5, marginRight: 20 }}
          >
            <Col span={8}>
              <Form.Item
                label='服务起始时间'
                name='startTime'
                {...firstColumnLayout}
                rules={[{ required: true, message: '服务起始时间不能为空' }]}
              >
                <Input autoComplete='off' disabled />
                {/* <DatePicker format="YYYY/MM/DD" /> */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...secondColumnLayout}
                label='服务终止时间'
                name='endTime'
                rules={[{ required: true, message: '服务终止时间不能为空' }]}
              >
                <Input autoComplete='off' disabled />
                {/* <DatePicker format="YYYY/MM/DD" /> */}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...thirdColumnLayout}
                label='机构电话'
                name='tenantPhone'
                rules={[{ max: 40, message: '最长40位!' }]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}>
            <Col span={24}>
              <Form.Item
                label='地址'
                {...firstColumnLayout}
                name='address'
                rules={[
                  { required: true, message: '地址不能为空' },
                  { max: 500, message: '最长500位' },
                ]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                label='联系人'
                name='contactName'
                rules={[
                  { required: true, message: '联系人不能为空' },
                  { max: 60, message: '最长60位' },
                ]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='联系电话'
                name='phone'
                {...secondColumnLayout}
                rules={[
                  { required: true, message: '联系电话不能为空' },
                  { max: 40, message: '最长40位' },
                ]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...thirdColumnLayout}
                label='邮编'
                name='postCode'
                rules={[{ max: 40, message: '最长40位' }]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                label='机构代码'
                name='organCode'
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...secondColumnLayout}
                label='主管单位'
                name='managerCompany'
                rules={[{ max: 40, message: '最长40位' }]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...thirdColumnLayout}
                label='法人代表'
                name='legalPerson'
                rules={[{ max: 40, message: '最长40位' }]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}>
            <Col span={8}>
              <Form.Item
                {...firstColumnLayout}
                label='许可证类型'
                name='licenceCategory'
                rules={[{ max: 60, message: '最长60位' }]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...secondColumnLayout}
                label='许可证号码'
                name='licenceNumber'
                rules={[{ max: 120, message: '最长120位' }]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                {...thirdColumnLayout}
                label='许可证期限'
                name='licenceDeadline'
                rules={[{ max: 40, message: '最长40位' }]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item {...firstColumnLayout} label='企业税号' name='taxNum'>
                <Input autoComplete='off' />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                {...secondColumnLayout}
                label='小票打印底部备注'
                name='printRemark'
              >
                <TextArea showCount maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div
          style={{
            width: '100%',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button htmlType='submit' type='primary'>
            保存
          </Button>
        </div>
      </Form>
    </div>
  )
}
