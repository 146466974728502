/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-01-09 16:30:19
 * @LastEditors: linxi
 * @LastEditTime: 2024-01-10 11:49:46
 */
export function getYBTypeName(ybType: any): string {
  switch (ybType) {
    case '0':
      return '市医保'
    case '1':
      return '省内异地'
    case '2':
      return '省外异地'
    case '3':
      return '省医保'
  }
  return '-'
}
