import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api, sendAsync } from "../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../app/store";
import { LoadingState } from "../../../models/common";
import * as R from "../../../models/registration";
import { Registration } from "../../../models/registration";
import * as Tr from "../../../models/treatment";
import {Treatment, TreatmentCategory} from "../../../models/treatment";
import { startTreatment as apiStartTreatment } from "../../../services/registration";
import {
  copyTreatment as apiCopyTreatment,
  CopyTreatmentParams,
  createTreatment as apiCreateTreatment,
  getTreatment as apiGetTreatment,
  GetTreatmentDetailParams,
  getUrlLink,
  urlLinkParams,
} from "../../../services/treatment";
import { setTreatmentRegistration } from "../treatmentSlice";

interface OralCaseState {
  registration?: Registration ;
  treatment?: Treatment;
  creatingState: LoadingState;
}


export enum TreatmentCategory_Oral {
  Normal = 0,
  Special = 1,
  Slow = 2,
  Cod19 = 3,
  GeZhang = 4,
  SlowAndSpecial = 5,
}

export const TreatmentCategories_Oral = [
  TreatmentCategory.Normal,
  TreatmentCategory.Special,
  TreatmentCategory.Slow,
  TreatmentCategory.SlowAndSpecial,
  TreatmentCategory.Cod19,
  TreatmentCategory.GeZhang,
]

export const TreatmentCategories_Oral_CQ = [
  TreatmentCategory_Oral.Normal,
  TreatmentCategory_Oral.SlowAndSpecial,
]

export const TreatmentCategories_Oral_ZF = [
  TreatmentCategory_Oral.Normal
]

const TreatmentCategoryNames_Oral = ['普通', '特病', '慢病','新冠门诊','个人账户下账','门诊特慢病']

export function getTreatmentCategoryName_Oral(category: TreatmentCategory_Oral): string {
  if (category < 0 || category >= TreatmentCategoryNames_Oral.length) {
    return ''
  }
  return TreatmentCategoryNames_Oral[category]
}

const initialState = {
  registration: undefined,
  creatingState: LoadingState.Idle,
} as OralCaseState;

export const createTreatment = createAsyncThunk<void, Treatment, RootThunkApi>(
  "oralCase/createTreatmentStatus",
  async (treatment, api) => {
    return sendAsync(apiCreateTreatment(treatment), api).then(() => {
      // const id = api.getState().oralCase.registration?.id;
      // if (id) {
      //   api.dispatch(getTreatment({ registrationId: id,medicalRecord:1 }));
      // }
    });
  }
);

const oralCaseSlice = createSlice({
  name: 'oralCase',
  initialState,
  reducers: {
    setRegistration: (state, action: PayloadAction<Registration>) => {
      state.registration = action.payload 
    },
    
    setTreatment: (state, action: PayloadAction<Treatment | undefined>) => {
      state.treatment = action.payload
    },
    setTreatmentCreatingState: (state, action: PayloadAction<LoadingState>) => {
      state.creatingState = action.payload
    },
    reSetRegistration: (state, action) => {
      state.registration = undefined
    },
    resetOralCase: (state) => {
      state.registration = undefined
      state.treatment = undefined
      state.creatingState = LoadingState.Idle
    },
  },
})


export const {
  setTreatmentCreatingState,
  reSetRegistration,
  resetOralCase,
  setTreatment,
  setRegistration,
} = oralCaseSlice.actions

export function getTreatment(params: GetTreatmentDetailParams): RootThunk {
  return api(
    apiGetTreatment(params),
    (data: Record<string, never>, dispatch) => {
      const registration = R.fromJson(data.registration);
      dispatch(setRegistration(registration));
      dispatch(setTreatmentRegistration(registration))
      const treatment =
        JSON.stringify(data.treatment) !== "{}"
          ? Tr.fromJson(data.treatment)
          : undefined;
      dispatch(setTreatment(treatment));
    }
  );
}

export function startTreatment(registrationId: string): RootThunk {
  return api(apiStartTreatment(registrationId), () => {
    // Do nothing.
  });
}

export const copyTreatment = createAsyncThunk<
  void,
  CopyTreatmentParams,
  RootThunkApi<void>
>("oralCase/copyTreatmentStatus", async (params, api) => {
  return sendAsync(apiCopyTreatment(params), api).then(() => {
    // do nothing.
  });
});

export const getUrl = createAsyncThunk<void, urlLinkParams, RootThunkApi>(
  "oralCase/getUrl",
  async (params, api) => {
    return sendAsync(getUrlLink(params), api);
  }
);

export const selectRegistration = (
  state: RootState
): Registration | undefined => state.oralCase.registration;

export const selectTreatmentO = (state: RootState): Treatment | undefined =>
  state.oralCase.treatment;

export const selectTreatmentCreatingState = (state: RootState): LoadingState =>
  state.oralCase.creatingState;

export default oralCaseSlice.reducer;
