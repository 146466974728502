import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { OperatingRoomParams } from '../../services/operatingRoom'
import { ThemeContext } from '../../theme/ThemeContext'
import { OperatingRoomColumns } from './column'
import { OperatingRoomModal } from './operatingRoomModal'
import {
  getOperaingRoomList,
  getToggleOperatingRoomState,
} from './operatingRoomSlice'

interface OperatingRoomProps {
  onChange?: (id: string) => void
}
export const OperatingRoom = ({
  onChange,
}: OperatingRoomProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [current, setCurrent] = useState(1)

  const [size, setSize] = useState(10)

  const [total, setTotal] = useState<any>()

  const [data, setData] = useState<any[]>([])

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [id, setId] = useState<any>()

  const [params, setParams] = useState<OperatingRoomParams>({
    current: 1,
    size: 10,
  })

  useEffect(() => {
    setPageLoading(true)
    dispatch(getOperaingRoomList({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        setData(res.records)
        setTotal(res.total)
      })
      .finally(() => {
        setPageLoading(false)
      })
  }, [dispatch, params, current, size])
  return (
    <Col
      style={{
        height: 'calc(100% - 30px)',
        overflow: 'hidden',
        position: 'relative',
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Button
        icon={<PlusOutlined />}
        type='primary'
        onClick={() => setIsModalVisible(true)}
        size='small'
        style={{
          position: 'fixed',
          top: 68,
          right: 45,
        }}
      >
        新增
      </Button>

      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '10px 15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          onFinish={(values) => {
            setParams({ ...params, ...values })
            setCurrent(1)
          }}
        >
          <Row
            align='middle'
            justify='space-between'
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            <Space>
              <Form.Item name='name' noStyle>
                <Input
                  placeholder='请输入手术室名称'
                  style={{ width: 315 }}
                  prefix={<SearchOutlined color={theme.tc3} />}
                  allowClear
                  onChange={form.submit}
                />
              </Form.Item>
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
        <EditableList
          page={{
            items: data,
            current,
            size,
            total: total,
          }}
          loading={pageLoading}
          columns={OperatingRoomColumns((d) => {
            dispatch(getToggleOperatingRoomState({ id: d.id }))
              .then(unwrapResult)
              .then(() => {
                dispatch(getOperaingRoomList({ ...params }))
                  .then(unwrapResult)
                  .then((res: any) => {
                    setData(res.records)
                    setTotal(res.total)
                  })
              })
          }, size * (current - 1))}
          onChangePage={(current: any, size: any) => {
            setSize(size as number)
            setCurrent(current as number)
          }}
          onRow={(r: any) => ({
            onDoubleClick: () => {
              setIsModalVisible(true)
              setId(r.id)
            },
          })}
        />
      </Col>
      <OperatingRoomModal
        isModalVisible={isModalVisible}
        id={id}
        onCancel={() => {
          setIsModalVisible(false)
          setId('')
        }}
        onOk={() => {
          setIsModalVisible(false)
          setId('')
          dispatch(getOperaingRoomList({ ...params }))
            .then(unwrapResult)
            .then((res: any) => {
              setData(res.records)
              setTotal(res.total)
            })
        }}
      />
    </Col>
  )
}
