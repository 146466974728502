import { Button, Col, Tabs, Row, TableColumnType, Form, Space, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DateRangeType, DateSelect, getDateRange } from "../../../compnents/form/DateSelect";
import {PurchaseControlParams} from "../../../services/purchaseControl";
export const PurchaseControlQuery = (
    props: {
        onValueChange: (queries: PurchaseControlParams) => void;
        onExport: () => void
    }): ReactElement => {
    const [form] = Form.useForm();

    const dataParams = JSON.parse(
        sessionStorage.getItem(location.pathname) || '{}'
    )

    const { Option } = Select;

    useEffect(() => {

        const [head, tail] = getDateRange(DateRangeType.ThisYear);
        form.setFieldsValue({
            timeHead: head,
            timeTail: tail,
            ...dataParams,
        });
        form.submit();
    }, []);
    return (
        <>
            <Form
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
                form={form}
                onFinish={(values) => props.onValueChange(values)}>
                <Row
                    style={{ marginBottom: 10, width: "100%" }}
                    align="middle"
                    justify="space-between"
                >
                    <Space>
                        <Form.Item name="supplierParam" noStyle>
                            <Input
                                autoComplete="off"
                                placeholder="供应商名称/助记码"
                                style={{ width: 220 }}
                                onChange={() => form.submit()}
                                prefix={<SearchOutlined />}
                                allowClear
                            />
                        </Form.Item>
                        <DateSelect
                            style={{ width: "8.5rem" }}
                            labelPrefix=""
                            namePrefix="time"
                            placeholder="今年"
                            initialValue={DateRangeType.ThisYear}
                            onChange={() => form.submit()}
                            allowClear={false}
                        />
                        <Button
                            type="primary"
                            onClick={() => props.onValueChange(form.getFieldsValue())}
                        >
                            查询
                        </Button>
                    </Space>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => props.onExport()}
                        >
                            导出
                        </Button>
                    </Space>
                </Row>
            </Form>
        </>
    );
}

export const PurchaseControlDetailQuery = (
    props: {
        onValueChange: (queries: PurchaseControlParams) => void;
        onExport: () => void
    }): ReactElement => {
    const [form] = Form.useForm();

    const { Option } = Select;
    useEffect(() => {
        const [head, tail] = getDateRange(DateRangeType.ThisYear);
        form.setFieldsValue({
            timeHead: head,
            timeTail: tail,
        });
        form.submit();
    }, []);
    return (
        <>
            <Form
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
                form={form}
                onFinish={(values) => props.onValueChange(values)}>
                <Row
                    style={{ marginBottom: 10, width: "100%" }}
                    align="middle"
                    justify="space-between"
                >
                    <Space>
                        <Form.Item name="supplierParam" noStyle>
                            <Input
                                autoComplete="off"
                                placeholder="供应商名称/助记码"
                                style={{ width: 220 }}
                                onChange={() => form.submit()}
                                prefix={<SearchOutlined />}
                                allowClear
                            />
                        </Form.Item>
                        <DateSelect
                            style={{ width: "8.5rem" }}
                            labelPrefix=""
                            namePrefix="time"
                            placeholder="今年"
                            initialValue={DateRangeType.ThisYear}
                            onChange={() => form.submit()}
                            allowClear={false}
                        />
                    </Space>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => props.onValueChange(form.getFieldsValue())}
                        >
                            查询
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => props.onExport()}
                        >
                            导出
                        </Button>
                    </Space>
                </Row>
            </Form>
        </>
    );
};
