import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import { Page, pageOf, startPageOf } from '../../models/page'
import * as P from '../../models/patient'
import { Patient } from '../../models/patient'
import {
  fromJson,
  Registration,
  RegistrationCount,
} from '../../models/registration'
import * as SP from '../../services/patient'
import { SavePatientParams } from '../../services/patient'
import * as S from '../../services/registration'
import { RegistrationQueryParams } from '../../services/registration'

interface PatientState {
  pages: Page<Registration>[]
}

const initialState = {
  pages: [startPageOf(), startPageOf()],
} as PatientState

export const getRegistrationList = createAsyncThunk<
  Page<Registration>,
  RegistrationQueryParams,
  RootThunkApi<Page<Registration>>
>('patient/getRegistrationList', async (params, api) => {
  return sendAsync(S.getRegistrationList(params), api).then(
    (data: Record<string, never>) =>
      pageOf(
        data,
        _.chain(data.records)
          .map((r) => fromJson(r))
          .value()
      )
  )
})

export const getRegistrationCounts = createAsyncThunk<
  RegistrationCount,
  Record<string, unknown>,
  RootThunkApi<RegistrationCount>
>('patient/getRegistrationCountsStatus', async (params, api) => {
  return sendAsync(S.getRegistrationCounts(params), api).then(
    (data: Record<string, never>) =>
      ({
        treated: data.yesCount,
        notTreated: data.notCount,
        total: data.totalCount,
        treating: data.ingCount,
      } as RegistrationCount)
  )
})

export const getPatientRegistrationCountAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('patient/getTreatmentDetail', async (params, api) => {
  return sendAsync(S.getPatientRegistrationCounts(), api)
})

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRegistrationList.fulfilled, (state, action) => {
      const index = action.meta.arg.index
      if (index !== undefined && index >= 0 && index < 2) {
        state.pages[index] = action.payload
      }
    })
  },
})

export const selectRegistrationPage = (index: number) => (
  state: RootState
): Page<Registration> => {
  if (index >= 0 && index < 2) {
    return state.patient.pages[index]
  }
  return startPageOf()
}

export default patientSlice.reducer

export const getPatientDetail = createAsyncThunk<
  Patient,
  any,
  RootThunkApi<Patient>
>('patient/getPatientDetailStatus', async (patientId, api) => {
  return sendAsync(
    SP.getPatientDetail(patientId),
    api
  ).then((data: Record<string, never>) => P.fromJson(data))
})

export const getTreatmentDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('patient/getTreatmentDetail', async (params, api) => {
  return sendAsync(SP.treatmentDetail(params), api)
})

// 判断点击能不能跳转
export const getChangeRegistration = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('patient/getChangeRegistration', async (params, api) => {
  return sendAsync(SP.changeRegistration(params), api)
})

export const savePatient = createAsyncThunk<
  void,
  SavePatientParams,
  RootThunkApi<void>
>('patient/savePatientStatus', async (params, api) => {
  return sendAsync(SP.savePatient(params), api)
})
