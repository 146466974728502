/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {CaretDownFilled, CaretRightFilled, EllipsisOutlined, PlusOutlined} from '@ant-design/icons'
import {unwrapResult} from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  notification,
  Row,
  Space,
  Image, Popover, Select, Modal
} from 'antd'
import {RcFile, UploadFile} from 'antd/lib/upload/interface'
import _ from 'lodash'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {selectShortName, selectTenantAddress, selectTenantName, selectTimestamp} from '../../../app/applicationSlice'
import {RootDispatch} from '../../../app/store'
import FileUpload from '../../../compnents/file/FileUpload'
import {Label} from '../../../compnents/widgets/Label'
import {patientInfo} from '../../../models/intelligence'
import {PrintTemplateCategory} from '../../../models/print'
import {
  BeautyValue,
  MedicalValue,
  TemplateDetail,
} from '../../../models/template'
import {TreatmentNotice} from '../../../models/treatment'
import {ThemeContext} from '../../../theme/ThemeContext'
import {useQuery} from '../../../utils/HttpUtils'
import {imagesType} from '../../../utils/StringUtils'
import {PrintFrame, PrintFrameRef} from '../../print/PrintFrame'
import {PrintTemplateModal} from '../../print/PrintTemplateModal'
import {UploadModal} from '../../putstorelist/Query/uploadModal'
import {SaveTemplateModal} from '../../template/SaveTemplateModal'
import {deleteUploadFileList} from '../diagnosis/diagnosisSlice'
import {PreferencesSetModal} from '../diagnosis/preferencesSetModal'
import styles from "../../medicalBeautyTemplate/MedicalBeautyTemplateEditor.module.css";
import {
  getBeaytyClinicDetail,
  getBeaytyClinicList,
  getMedicalDetailAsync,
  getMedicalListAsync,
  getMedicalRemoveAsync,
  getTreatment,
  getTreatments,
  getUrl,
  getVideoSave, historicalRecordsCopy,
  selectRegistration,
  selectTreatmentB, setTreatment,
} from './beautyClinicSlice'
import DCWriterControl, {
  EditorRendering,
  ImportComponent,
  setWriterControl_OnLoad, StringToUtf8Base64,
} from "../../medicalBeautyTemplate/DCWriterControl";
import Handle_EventShowContextMenu from "../../medicalBeautyTemplate/ContextMenuSetting";
import {saveTreatmentBeauty} from "../../medicalBeautyTemplate/MedicalBeautyTemplateSlice";
import {DelectOutlined} from "../../../compnents/icons/Icons";
import {ReactComponent as FemaleFilledIcon} from '../../../compnents/icons/svg/filled/view.svg'
import {HistoricalRecords} from "./historicalRecords";
import {getPatientDetailInfoAsync} from "../../patient/modals/patientSlice";
import {Patient} from "../../../models/patient";

export interface BeautyClinicRef {
  submit: () => Promise<void>
  showTreatments: () => void
}

interface DiagnosisProps {
  tabKey?: string
  readMode?: boolean
  state?: number
  saveEdit?: () => void
  PreferencesSetChange?: () => void
}

const templateNames = [
  {
    id: 1,
    name: '个人模版',
    type: 3
  },
  {
    id: 2,
    name: '机构模版',
    type: 2
  },
  {
    id: 3,
    name: '系统模版',
    type: 1
  },
]

const RealBeautyClinicDC = (
  {readMode = false, saveEdit, PreferencesSetChange}: DiagnosisProps,
  ref: React.Ref<BeautyClinicRef>
): ReactElement => {
  const printRef = useRef<PrintFrameRef>(null)

  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const [xml, setXml] = useState<any>('')

  const [data, setData] = useState<MedicalValue[]>([])

  const theme = useContext(ThemeContext)

  const query = useQuery()

  const registration = useSelector(selectRegistration)

  const treatment = useSelector(selectTreatmentB)

  const [selectedDiseases, setSelectedDiseases] = useState<any>()

  const [templateKeyword, setTemplateKeyword] = useState('')

  const [templates, setTemplates] = useState<BeautyValue[]>([])

  const EditorRef = useRef<any>(null)

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const [templateDetails, setTemplateDetails] = useState<
    Record<string, BeautyValue[]>
  >({})

  const registrationId = query.get('registrationId') as string

  const treatmentId = query.get('treatmentId') as string

  const [patientId, setPatientId] = useState(-1)


  const [targetTemplate, setTargetTemplate] = useState<
    TemplateDetail | undefined
  >(undefined)

  const tenantName = useSelector(selectShortName)

  const tenantAddress = useSelector(selectTenantAddress)

  const timestamp = useSelector(selectTimestamp)

  const [historicalRecordsBtn, setHistoricalRecordsBtn] = useState(false)  //上传按钮 loading

  const [visible, setVisible] = useState(false)   //预览图片是否显示

  const [imgSrc, setImgSrc] = useState('')   //预览图片路径

  const [printStr, setPrintStr] = useState('')

  const refWriterControl = useRef<any>(null);

  const id = query.get('id') as string  //患者管理-病历信息跳转过来的

  const [preferencesSetShow, setiPreferencesSetShow] = useState(false) //偏好设置

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [patientData, setPatientData] = useState<patientInfo>()

  const [patient, setPatient] = useState<Patient | undefined>()

  const [selectTemplate, setSelectTemplate] = useState('')

  const [selectTemplateName, setSelectTemplateName] = useState<string>('')

  const [selectHistoryId, setSelectHistoryId] = useState<string>('')

  const [loadFinish, setLoadFinish] = useState(false)

  ImportComponent()

  //保存病历
  const saveMedicalRecords = (medicalBeautyId: string, medicalBeautyName: string) => {

    const xmlStr = StringToUtf8Base64(refWriterControl.current.XMLTextForSave)

    const rootElement = refWriterControl.current


    const disease = rootElement.GetInputFieldInnerValue('disease');
    const personalHistory = rootElement.GetInputFieldInnerValue('personalHistory');
    const historyOfPresentIllness = rootElement.GetInputFieldInnerValue('historyOfPresentIllness');
    const chiefComplaint = rootElement.GetInputFieldInnerValue('chiefComplaint');
    const pastMedicalHistory = rootElement.GetInputFieldInnerValue('pastMedicalHistory');
    const treatmentId = treatment?.id && Number(treatment.id) > 0 ? treatment?.id : String(patientData?.registration.treatmentId)

    dispatch(
      saveTreatmentBeauty({
        ...patientData?.registration,
        id: 1,
        registrationId: registrationId,
        treatmentId: treatmentId,
        medicalBeautyId: medicalBeautyId && medicalBeautyId !== '' ? medicalBeautyId : selectTemplate,
        medicalBeautyName: medicalBeautyName && medicalBeautyName !== '' ? medicalBeautyName : selectTemplateName,
        medicalRecord: 4,
        category: 1,
        version: '2',
        itemId: selectHistoryId,
        content: xmlStr,
        disease: disease && disease !== '' ? disease : null,
        personalHistory: personalHistory && personalHistory !== '' ? personalHistory : null,
        historyOfPresentIllness: historyOfPresentIllness && historyOfPresentIllness !== '' ? historyOfPresentIllness : null,
        chiefComplaint: chiefComplaint && chiefComplaint !== '' ? chiefComplaint : null,
        pastMedicalHistory: pastMedicalHistory && pastMedicalHistory !== '' ? pastMedicalHistory : null
      })
    )
      .then(unwrapResult)
      .then((res) => {
        notification.success({
          message: '保存成功',
        })
        getMedical()
        setSelectHistoryId(res.medicalBeautyItem.id)
        dispatch(setTreatment(res.treatment))
      })
  }

  const onParentStateUpdate = (event: string) => {
    switch (event) {
      case 'Save' :
        if (selectTemplate === '') {
          Modal.info({
            title: `请选择模版`,
            okText: '确定',
            maskClosable: true,
            keyboard: true,
          })
          return
        }
        saveMedicalRecords('', '')
        break
      default :
        break
    }
  }

  //数据回写
  const writeBack = () => {

    const rootElement = refWriterControl.current

    if (treatment?.disease && treatment?.disease !== '') {
      rootElement.SetElementTextByID('disease', treatment?.disease)
    }

    if (treatment?.personalHistory && treatment?.personalHistory !== '') {
      rootElement.SetElementTextByID('personalHistory', treatment?.personalHistory)
    }

    if (treatment?.historyOfPresentIllness && treatment?.historyOfPresentIllness !== '') {
      rootElement.SetElementTextByID('historyOfPresentIllness', treatment?.historyOfPresentIllness)
    }

    if (treatment?.chiefComplaint && treatment?.chiefComplaint !== '') {
      rootElement.SetElementTextByID('chiefComplaint', treatment?.chiefComplaint)
    }

    if (treatment?.pastMedicalHistory && treatment?.pastMedicalHistory !== '') {
      rootElement.SetElementTextByID('pastMedicalHistory', treatment?.pastMedicalHistory)
    }

    //患者信息
    if (patientData?.registration.patientName && patientData?.registration.patientName !== '') {
      rootElement.SetElementTextByID('patientName', patientData?.registration.patientName)
    }

    if (patient?.idCardNumber && patient?.idCardNumber !== '') {
      rootElement.SetElementTextByID('patientIdCard', patient?.idCardNumber)
    }

    if (patient?.emergencyContactName && patient?.emergencyContactName !== '') {
      rootElement.SetElementTextByID('emergencyContactName', patient?.emergencyContactName)
    }

    if (patient?.emergencyContactPhone && patient?.emergencyContactPhone !== '') {
      rootElement.SetElementTextByID('emergencyContactPhone', patient?.emergencyContactPhone)
    }

    if (patient?.birthday && patient?.birthday !== '') {
      rootElement.SetElementTextByID('birthday', patient?.birthday)
    }

    if (patient?.companyName && patient?.companyName !== '') {
      rootElement.SetElementTextByID('patientCompanyName', patient?.companyName)
    }

    if (patient?.address && patient?.address !== '') {
      rootElement.SetElementTextByID('patientAddress', patient?.address)
    }

    if (patient?.height && patient?.height !== '') {
      rootElement.SetElementTextByID('patientHeight', patient?.height)
    }

    if (patient?.weight && patient?.weight !== '') {
      rootElement.SetElementTextByID('patientWeight', patient?.weight)
    }

    if (patient?.wechat && patient?.wechat !== '') {
      rootElement.SetElementTextByID('patientWechat', patient?.wechat)
    }

    if (patient?.emailAddress && patient?.emailAddress !== '') {
      rootElement.SetElementTextByID('patientEmailAddress', patient?.emailAddress)
    }

    if (patient?.maritalStatus == 1) {
      rootElement.SetElementTextByID('patientMaritalStatus', '未婚')
    } else if (patient?.maritalStatus == 2) {
      rootElement.SetElementTextByID('patientMaritalStatus', '已婚')
    } else {
      rootElement.SetElementTextByID('patientMaritalStatus', '未知')
    }

    //性别
    if (patientData?.registration.patientSex && patientData?.registration.patientSex > 0) {

      if (patientData?.registration.patientSex === 2) {
        rootElement.SetElementChecked('patientGenderChk1', false)
        rootElement.SetElementChecked('patientGenderChk2', true)
      } else {
        rootElement.SetElementChecked('patientGenderChk1', true)
        rootElement.SetElementChecked('patientGenderChk2', false)
      }
    }

    if (patient?.phone && patient?.phone !== '') {
      rootElement.SetElementTextByID('patientPhone',  patient?.phone)
    }

    if (patientData?.registration.patientAge && patientData?.registration.patientAge > 0) {
      rootElement.SetElementTextByID('txtAge', String(patientData?.registration.patientAge))
    }

    //机构信息
    if (tenantName && tenantName !== '') {
      rootElement.SetElementTextByID('tenantName', tenantName)
    }

    if (tenantAddress && tenantAddress !== '') {
      rootElement.SetElementTextByID('tenantAddress', tenantAddress)
    }

    if (timestamp && timestamp !== '') {
      rootElement.SetElementTextByID('tenantPhone', String(timestamp))
    }

    //就诊信息
    if (patientData?.registration.registrationDepartmentName && patientData?.registration.registrationDepartmentName !== '') {
      rootElement.SetElementTextByID('registrationDepartmentName', patientData?.registration.registrationDepartmentName)
    }

    if (patientData?.registration.registrationDoctorName && patientData?.registration.registrationDoctorName !== '') {
      rootElement.SetElementTextByID('registrationDoctorName', patientData?.registration.registrationDoctorName)
    }

    if (patientData?.registration.registrationTime && patientData?.registration.registrationTime !== '') {
      rootElement.SetElementTextByID('registrationTime', patientData?.registration.registrationTime)
    }

    if (patientData?.registration.patientId && patientData?.registration.patientId > 0) {
      rootElement.SetElementTextByID('outpatientNo', String(patientData?.registration.patientId))
    }

  }

  const getMedical = () => {
    dispatch(
      getMedicalListAsync({
        category: 1,
        showImgFlag: 1,
        registrationId: Number(registrationId),
        version: '2'
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setData(res)
      })
  }
  useEffect(() => {

    dispatch(getTreatment({registrationId, treatmentId}))
    // if (!readMode) {
    //   dispatch(startTreatment(registrationId))
    // }
  }, [dispatch, registrationId, treatmentId])

  useEffect(() => {

    getMedical()
    dispatch(getBeaytyClinicList({category: 1, version: '2', templateType: 99}))
      .then(unwrapResult)
      .then((res: any) => {
        setTemplates(res)
      })
    dispatch(
      getTreatments({
        registrationId,
        treatmentId,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        dispatch(getPatientDetailInfoAsync({id:v.registration.patientId}))
          .then((res : any) => {
            setPatient(res.payload)
          })
        setPatientData(v)
      })

    id && dispatch(
      getMedicalDetailAsync({
        id: Number(id)
      })
    )
      .then(unwrapResult)
      .then((res: string) => {
        EditorRef.current?.importXmltoSde(res)
      })
  }, [dispatch, registration, id])

  useEffect(() => {

    setWriterControl_OnLoad((rootElement: any) => {
      console.log("执行回调")

      rootElement.style.display = null;

      //右键菜单
      rootElement.EventShowContextMenu = Handle_EventShowContextMenu

      rootElement.Readonly = true

      setLoadFinish(true)
    })
  }, []);


  return (
    <div
      style={{
        display: 'flex',
        height: 'calc(100% - 5px)',
        position: 'relative',
        flexDirection: 'column',
        marginTop: '5px',
      }}
    >
      <Row
        className={styles.container}
        style={{
          height: '100%',
          overflow: 'hidden',
          flex: 1,
          marginBottom: '10px',
        }}
        wrap={false}
      >
        {!readMode && (
          <Col
            style={{
              width: 230,
              height: '100%',
              marginRight: 10,
              backgroundColor: theme.pn,
              borderRadius: theme.br,
              overflow: 'auto',
            }}
          >
            <Label label='诊断模板' style={{marginLeft: 20}}/>
            <Divider style={{margin: '0px'}}/>
            <Input
              value={templateKeyword}
              placeholder='请输入内容检索'
              onChange={(e) =>
                setTemplateKeyword((e.target.value || '').trim())
              }
              style={{
                width: 'calc(100% - 40px)',
                marginTop: 20,
                marginLeft: 20,
              }}
              allowClear
            />


            <Collapse
              className={styles.templates}
              bordered={false}
              expandIcon={(v) =>
                v.isActive ? (
                  <CaretDownFilled style={{lineHeight: '26px'}}/>
                ) : (
                  <CaretRightFilled style={{lineHeight: '26px'}}/>
                )
              }
              style={{
                marginTop: 30,
                marginLeft: 20,
                marginRight: 20,
                flex: 1,
                overflow: 'auto',
                backgroundColor: 'transparent',
              }}
            >
              {templateNames.map(t => (
                <Collapse.Panel
                  key={t.type}
                  header={
                    <Row
                      align='middle'
                      justify='space-between'
                      wrap={false}
                      style={{
                        color: undefined,
                        fontWeight: 'normal',
                        width: '100%',
                      }}
                    >
                                        <span
                                          style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            marginRight: 10,
                                          }}
                                        >
                                          {t.name}
                                        </span>
                    </Row>
                  }
                >
                  <Collapse
                    className={styles.secondLevel}
                    bordered={false}
                    expandIcon={(v) =>
                      v.isActive ? (
                        <CaretDownFilled style={{lineHeight: '26px'}}/>
                      ) : (
                        <CaretRightFilled style={{lineHeight: '26px'}}/>
                      )
                    }
                    onChange={(ids) => {
                      _.forEach(ids, (id) => {
                        if (!templateDetails[id]) {
                          const template = templates.find((t) => t.id === Number(id))
                          if (template) {
                            dispatch(
                              getBeaytyClinicList({
                                category: 1,
                                parentId: Number(id),
                                version: '2',
                                templateType: t.type
                              })
                            )
                              .then(unwrapResult)
                              .then((details: any) => {
                                setTemplateDetails({
                                  ...templateDetails,
                                  [id]: details,
                                })
                              })
                          }
                        }
                      })
                    }}
                  >
                    {templates.filter(tt => tt.templateType === t.type).map((t) => (
                      <Collapse.Panel
                        key={t.id}
                        header={
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t.name}
                          </div>
                        }
                      >
                        {templateDetails[t.id] &&
                          templateDetails[t.id]
                            .filter(
                              (d) =>
                                !templateKeyword ||
                                d.name.indexOf(templateKeyword) >= 0 ||
                                (d.mnemonicCode &&
                                  d.mnemonicCode.indexOf(
                                    templateKeyword.toUpperCase()
                                  ) >= 0)
                            )
                            .map((d, i) => (
                              <div
                                key={d.id}
                                onClick={() => {
                                  if (selectedDiseases?.medicalBeautyId !== d.id && loadFinish) {
                                    dispatch(
                                      getBeaytyClinicDetail({
                                        id: d.id,
                                        registrationId: Number(registrationId),
                                        version: '2'
                                      })
                                    )
                                      .then(unwrapResult)
                                      .then((res: string) => {
                                        setSelectTemplate(String(d.id))
                                        setSelectTemplateName(d.name)
                                        setSelectHistoryId('')
                                        EditorRendering(refWriterControl.current, res, false);
                                        writeBack()
                                      })
                                  }
                                }}
                                style={{
                                  color: theme.tc2,
                                  fontSize: '1.125rem',
                                  marginTop: 10,
                                  cursor: 'pointer',
                                }}
                              >
                                {i + 1}. {d.name}
                              </div>
                            ))}
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Col>
        )}
        <Col
          flex='1'
          style={{
            height: '100%',
            borderRadius: theme.br,
            backgroundColor: theme.pn,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            marginRight: 10,
          }}
        >
          <Divider style={{margin: 0, borderTopColor: theme.th}}/>
          <DCWriterControl onParentStateUpdate={onParentStateUpdate}
                           editorStyle={{width: "100%", marginTop: "1px", height: "calc(-250px + 92vh)"}}
                           ref={refWriterControl} page={'medicalWrite'}/>
          <Row
            justify='end'
            style={{marginTop: '10px', marginRight: '10px'}}
          >
            <Space>
              <Button
                type='primary'
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                查看/选择 历史病历
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  if (selectTemplate === '') {
                    Modal.info({
                      title: `请选择模版`,
                      okText: '确定',
                      maskClosable: true,
                      keyboard: true,
                    })
                    return
                  }
                  saveMedicalRecords('', '')
                }}
              >
                保存
              </Button>
            </Space>
          </Row>
        </Col>
        <Modal
          className={styles.history}
          width={1200}
          style={{height: 720, maxHeight: 720, overflow: 'hidden'}}
          title='历史就诊信息'
          visible={isModalVisible}
          footer={null}
          onCancel={() => setIsModalVisible(false)}
          destroyOnClose
        >
          <HistoricalRecords
            version={'2'}
            patientId={patientData?.registration?.patientId}
            historicalRecordsBtn={historicalRecordsBtn}
            onCopy={(oldId, beautyId, name) => {
              setHistoricalRecordsBtn(false)

              dispatch(
                getMedicalDetailAsync({
                  id: oldId,
                })
              )
                .then(unwrapResult)
                .then((res: string) => {
                  setSelectTemplate(String(beautyId))
                  setSelectTemplateName(name)
                  setSelectHistoryId('')
                  EditorRendering(refWriterControl.current, res, false)
                  saveMedicalRecords(String(beautyId), name)
                  setIsModalVisible(false)
                }).finally(() => setHistoricalRecordsBtn(true))

            }}
          />
        </Modal>
        <Col
          style={{
            width: 240,
            height: '100%',
            overflow: 'auto',
            marginLeft: readMode ? 10 : 10,
            backgroundColor: theme.pn,
            borderRadius: readMode ? `0 0 ${theme.br}px 0` : `${theme.br}px`,
          }}
        >
          <Label label='诊断记录' style={{marginLeft: 20}}/>
          <Divider style={{margin: '0px'}}/>
          {data.map((v: any) => (
            <>
              <Row
                key={v.id}
                align='middle'
                justify='space-between'
                wrap={false}
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  marginRight: 20,
                  flex: 1,
                  overflow: 'auto',
                  backgroundColor: 'transparent',
                  fontWeight: 'normal',
                }}
              >
                {/* <Col span={24}> */}
                <a
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginRight: 10,
                    width: '145px',
                    color: '#000',
                  }}
                  title={v.name}
                >
                  {v.name}
                </a>
                <Space className={styles.opBar}>
                  <Button
                    ghost
                    type='link'
                    style={{
                      color: '#027AFF',
                      marginRight: '-5px',
                    }}
                    onClick={() => {
                      if (v.category == 3) {
                        setImgSrc('')
                        v.coherentVideo.split(',').map((v: any) => {
                          dispatch(getUrl({fileName: v.split('@')[2]})) //获取图片路径
                            .then(unwrapResult)
                            .then((v: any) => {
                              setImgSrc(v)
                              setVisible(true)
                            })
                        })
                      } else if (v.category == 1) {
                        dispatch(
                          getMedicalDetailAsync({
                            id: v.id,
                          })
                        )
                          .then(unwrapResult)
                          .then((res: string) => {
                            setSelectTemplate(v.medicalBeautyId)
                            setSelectTemplateName(v.name)
                            setSelectHistoryId(v.id)
                            EditorRendering(refWriterControl.current, res, false)
                            writeBack()
                          })
                      }
                    }}
                    icon={<FemaleFilledIcon style={{width: '18px', height: '18px'}}/>}
                  >
                  </Button>
                  <Button
                    ghost
                    type='link'
                    style={{
                      color: '#FF5D5D',
                    }}
                    onClick={() => {
                      dispatch(getMedicalRemoveAsync({ids: v.id}))
                        .then(unwrapResult)
                        .then(() => {
                          getMedical()
                          notification.success({
                            message: '删除成功',
                          })
                        })
                    }}
                    icon={<DelectOutlined style={{fill: "#FF5D5D", width: '19px', height: '19px'}}/>}
                  >
                  </Button>
                </Space>
              </Row>
              <Row
                key={v}
                align='middle'
                justify='space-between'
                wrap={false}
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  flex: 1,
                  overflow: 'auto',
                  backgroundColor: 'transparent',
                  fontWeight: 'normal',
                }}
              >
                {/* <Col span={24}> */}
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginRight: 10,
                  }}
                >
                  {v.createTime}
                </span>
              </Row>
            </>
          ))}
        </Col>
        <PrintTemplateModal
          templateCategory={PrintTemplateCategory.Zds}
          visible={isPrintModalVisible && !!treatment?.id}
          treatmentId={treatment?.id}
          onCancel={() => setIsPrintModalVisible(false)}
        />
        <SaveTemplateModal
          categoryName=''
          detail={targetTemplate}
          visible={!!targetTemplate}
          onCancel={() => setTargetTemplate(undefined)}
          onSave={() => setTargetTemplate(undefined)}
        />
      </Row>
      <PrintFrame ref={printRef} direction={'portrait'}>
        {/* <h3>hello</h3> */}
        {printStr && <div dangerouslySetInnerHTML={{__html: printStr}}></div>}
        {/* {EditorRef.current?.getSdeContent()} */}
      </PrintFrame>
      {/* 上传附件预览 */}
      <Image
        style={{display: 'none'}}
        src={imgSrc}
        preview={{
          visible,

          onVisibleChange: (value) => {
            setVisible(value)
          },
        }}
      />

      <PreferencesSetModal
        tabNum={'4'}
        isModalVisible={preferencesSetShow}
        handleCancel={() => setiPreferencesSetShow(false)}
        onsubmit={() => {
          PreferencesSetChange && PreferencesSetChange()
          setiPreferencesSetShow(false)
        }}
      />
    </div>
  )
}

export const BeautyClinicDC = forwardRef(RealBeautyClinicDC)
