import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  message,
  Modal,
  notification,
  Row,
  Space,
  TableColumnType,
  Tooltip,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { traceRoute } from '../../layouts/layoutSlice'
import {
  Registration,
  RegistrationCount,
  RegistrationState,
} from '../../models/registration'
import { RegistrationQueryParams } from '../../services/registration'
import { ThemeContext } from '../../theme/ThemeContext'
import { RegisterNoAppointModal } from '../registration/list/RegisterNoAppointModal'
import { RegistrationColumns } from './columns'
import styles from './PatientList.module.css'
import {
  getChangeRegistration,
  getPatientRegistrationCountAsync,
  getRegistrationCounts,
  getRegistrationList,
  selectRegistrationPage,
} from './patientSlice'
import { PatientQuery } from './Query'
import {
  checkTreatmentAsync,
  finishTreatment,
} from '../treatment/editor/recipeListEditorSlice'
import { getIconImg, getPayMethodName, PayMethod } from '../../models/payment'
import {
  AlipayCircleFilled,
  CreditCardFilled,
  DollarCircleFilled,
  ExclamationCircleFilled,
  WechatFilled,
} from '@ant-design/icons'
import { JuhePay, MembersIcon, YBIcon } from '../../compnents/icons/Icons'
import { PaymentResult } from '../../models/paymentresult'
import {
  getTradeName,
  medicalSendHttp,
  readCardEHttp,
  readCardSHttp,
  strParse,
} from '../../utils/MedicalUtils'
import {
  getPatientInfoAsync,
  medicalRegisterAsync,
} from '../registration/register/registerSlice'
import { selectInsuranceItem } from '../../layouts/insuranceSlice'
import { getTradeCode } from '../../models/material'
import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import {
  getInsuranceToSelfpay,
  getInsuranceToSelfpayAsync,
} from '../treatment/editor/recipeEditorSlice'

interface PatientListProps {
  today?: boolean
  currentTab?: any
}

export const PatientList = ({
  today = false,
  currentTab,
}: PatientListProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const userName = useSelector(selectUserName)
  const cycleCode = sessionStorage.getItem('cycleCode')

  const insuranceArray = useSelector(selectInsuranceArray)

  const theme = useContext(ThemeContext)

  const history = useHistory()

  const location: any = useLocation()

  const receiveTag = location?.state?.receiveTag

  const userId = useSelector(selectUserId)

  const baseData = useSelector(selectInsuranceItem)

  const page = useSelector(selectRegistrationPage(today ? 0 : 1))

  const [params, setParams] = useState<RegistrationQueryParams>({})

  const dataParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )
  const [
    registrationForConvert,
    setRegistrationForConvert,
  ] = useState<Registration | null>()
  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const [counts, setCounts] = useState<any>({
    ingCount: 0,
    notCount: 0,
    totalCount: 0,
    yesCount: 0,
  })

  const [queryBtnLoading, setQueryBtnLoading] = useState(false)

  const [registrationState, setRegistrationState] = useState<
    RegistrationState | undefined
  >(undefined)
  const tenantCategory = useSelector(selectTenantCategory)

  const tenantName = useSelector(selectTenantName)

  const tenantAddress = useSelector(selectTenantAddress)
  const [readCardLoading, setReadCardLoading] = useState(false)
  const [
    registerNoAppointModalVisible,
    setRegisterNoAppointModalVisible,
  ] = useState(false)

  const [remark, setRemark] = useState('')

  const [registerNoAppointId, setRegisterNoAppointId] = useState('')

  const getCount =()=>{
    dispatch(getPatientRegistrationCountAsync())
        .then(unwrapResult)
        .then((counts: any) => setCounts(counts))
        .catch(() => {
          // do nothing.
        })
  }
  useEffect(() => {
    if (today) {
      getCount()
    }
  }, [dispatch, today])

  useEffect(() => {
    sessionStorage.setItem(
      location.pathname,
      JSON.stringify({
        ...params,
        registrationState,
        index: today ? '0' : '1',
        receiveTag: receiveTag,
      })
    )
  }, [dispatch, params, today])



  useEffect(() => {
    if (params.current !== undefined) {
      getPage()
    }
  }, [params])

  useEffect(() => {
    setRegistrationForConvert(null)
  }, [])

  useEffect(() => {
    if (!registrationForConvert) {
      setReadCardLoading(false)
    }
  }, [registrationForConvert])

  const success = (str: string, type: number) => {
    if (type === 1) {
      setReadCardLoading(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadCardLoading(false)
      }, 3000)
    } else if (type === 2) {
      setReadCardLoading(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadCardLoading(false)
      }, 3000)
    } else if (type === 3) {
      setReadCardLoading(true)
      const hide = message.loading(str, 0)
      setTimeout(() => {
        hide()
        setReadCardLoading(false)
      }, 3000)
    }
  }

  const readCard = (cardType: 1 | 2 | 3) => {
    if (cardType == 1) {
      success('读卡中', 1)
      readEType()
    } else if (cardType == 2) {
      // setOtherPatientInsuranceItem({}) // 防止误用他人信息
      // readCardRef.current.blur() //按钮失焦，防止重复点击
      // // const readType = form.getFieldsValue().readType
      // success('读卡中', 2)
      // const idCardNumber = form.getFieldsValue().idCardNumber
      // if (idCardNumber) {
      //     getPatientInfo(idCardNumber, 'requestString')
      // } else {
      //     notification.info({
      //         message: '请输入身份证号',
      //     })
      // }
    } else if (cardType === 3) {
      success('读卡中', 3)
      readSType()
    }
  }

  // 实体卡读卡
  const readSType = () => {
    readCardSHttp(
      {
        trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
      },
      (data, err) => {
        if (!err) {
          const tradeType = getTradeName()
          const Name =
            tradeType === 'JS_ZHYB'
              ? data?.netmessage
                  ?.split('|')[4]
                  ?.replace('\r', '')
                  .replace('\t', '')
                  .replace(' ', '')
                  .trim()
              : data?.netmessage?.Name
          if (Name == registrationForConvert?.patientName) {
            getPatientInfoAndConvertToYb(
              tradeType === 'JS_ZHYB'
                ? data.netmessage
                : JSON.stringify(data.netmessage),
              'requestString',
              3
            )
          } else {
            Modal.error({
              title: '提示',
              content:
                `患者姓名与卡片姓名不一致，请确认是否插错卡，就诊人：` +
                registrationForConvert?.patientName +
                '，卡片姓名：' +
                Name,
              okText: '确认',
            })
            setReadCardLoading(false)
          }
        } else {
          setReadCardLoading(false)
        }
      }
    )
  }
  // 电子凭证读卡
  const readEType = () => {
    readCardEHttp(
      {
        trade_args: [
          insuranceArray[0].countryHospitalNb,
          '01301',
          '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        if (!err) {
          const readName = data?.netmessage?.split('|')[0]
          if (readName != registrationForConvert?.patientName) {
            Modal.error({
              title: '提示',
              content:
                `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                registrationForConvert?.patientName +
                '，电子凭证姓名：' +
                readName,
              okText: '确认',
            })
            setReadCardLoading(false)
          } else {
            getPatientInfoAndConvertToYb(data.netmessage, 'requestString', 1)
          }
        } else {
          setReadCardLoading(false)
        }
      }
    )
  }

  // 获取参保人信息
  const getPatientInfoAndConvertToYb = (
    fullCardInfo: string,
    serverType: any,
    cardType: number,
    responseString?: string
  ) => {
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceArray[0],
            endTime: moment(insuranceArray[0].endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          registrationId: registrationForConvert?.id,
          cardType: String(cardType),
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceArray[0].countryHospitalNb,
                insuranceArray[0].secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                success('正在获取人员信息', cardType)
                getPatientInfoAndConvertToYb(
                  fullCardInfo,
                  'response',
                  cardType,
                  JSON.stringify(call.netmessage)
                )
                // }
              } else {
                setReadCardLoading(false)
              }
            }
          )
        } else {
          message.success('读卡成功', 0)
          //紧跟着做一次医保挂号
          medicalSubmitAndConvertToYb(
            res,
            fullCardInfo,
            cardType,
            'requestString'
          )
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 3,
        })
        setReadCardLoading(false)
      })
  }

  const medicalSubmitAndConvertToYb = (
    patientInsurance: any,
    fullCardInfo: any,
    cardType: number,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      medicalRegisterAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceArray[0],
            endTime: moment(insuranceArray[0].endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          begntime: moment().format(DateTimeFormatSimpleOnlyOne),
          cardType: String(cardType),
          fullCardInfo: fullCardInfo,
          patientId: registrationForConvert?.patientId,
          registrationId: registrationForConvert?.id,
          treatmentId: registrationForConvert?.treatmentId,
          patientName: registrationForConvert?.patientName,
          pi: { ...patientInsurance?.patientInsurance },
          // cbxz: patientInsurance.cbxz,
          // doctorCode: doctorItem?.countryCode,
          // doctorName: doctorItem?.name,`
          // gwybz: patientInsurance.gwybz,
          // insuranceRegion: patientInsurance.insuranceRegion,
          // psnType: patientInsurance.memberType,
          // treatmentDepartmentCode: treatmentItem.code,
          // treatmentDepartmentName: treatmentItem.name,
          // treatmentNo: treatmentNum,
          // ybKebie: treatmentItem.ybKebie,
          // ybPersonalNo: vs.ybPersonalNo,
          // name: patientInsurance.name,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        setReadCardLoading(true)
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceArray[0].countryHospitalNb,
                insuranceArray[0].secretToken,
              ],
              massage: '医保挂号',
            },
            (call: any, err) => {
              if (!err) {
                medicalSubmitAndConvertToYb(
                  patientInsurance,
                  fullCardInfo,
                  cardType,
                  'response',
                  JSON.stringify(call.netmessage)
                )
              } else {
                setReadCardLoading(false)
              }
            }
          )
        } else {
          notification.success({
            message: '自费转医保成功，请继续操作。',
            duration: 2,
          })
          setReadCardLoading(false)
          setRegistrationForConvert(null)
          getPage()
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 2,
        })
        setReadCardLoading(false)
      })
  }

  //自费转医保
  const convertInsuranceToYb = (r: Registration) => {
    setRegistrationForConvert(r)
  }

  //医保转自费
  const convertInsuranceToZf = (r: Registration) => {
    Modal.confirm({
      title: '',
      content: (
        <div>
          <ExclamationCircleFilled style={{ color: '#FFAE55', fontSize: 18 }} />
          &nbsp; 医保转自费后，该患者的所有费用将转成现金结算，请确认此操作!
        </div>
      ),
      okText: '确认',
      cancelText: '取消',
      icon: null,
      onOk: () => {
        dispatch(
          getInsuranceToSelfpayAsync({
            registrationId: r?.id,
            treatmentId: r?.treatmentId,
            patientName: r?.patientName,
          })
        )
          .then(unwrapResult)
          .then((v) => {
            getPage()
            notification.success({
              message: '操作成功',
            })
          })
      },
    })
  }
  const getPage = () => {
    setQueryBtnLoading(true)
    dispatch(
      getRegistrationList({
        ...params,
        registrationState,
        index: today ? 0 : 1,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (params.param?.length == 9 && res.items.length == 1) {
          setParams({ ...params, param: '' })
          if (
            (res.items[0]?.treatmentDepartmentName == '未指定科室' &&
              res.items[0]?.treatmentDoctorName == '未指定医生') ||
            (res.items[0]?.registrationDepartmentName == '未指定科室' &&
              res.items[0]?.registrationDoctorName == '未指定医生')
          ) {
            setRemark(res.items[0]?.remark?.replace('#', ','))
            setRegisterNoAppointId(res.items[0]?.id)
            setRegisterNoAppointModalVisible(true)
          } else {
            dispatch(
              traceRoute({
                name: '医生门诊',
                path: '/treatment',
                query: `registrationId=${res.items[0]?.id}`,
              })
            )
          }
        }
      })
      .finally(() => {
        setQueryBtnLoading(false)
      })
  }

  return (
    <Col
      style={{
        height: 'calc(100% - 10px)',
        overflowY: 'hidden',
        backgroundColor: theme.pn,
        borderRadius: 10,
        margin: '10px 0',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {today && (
        <Row className={styles.dashboard} align='middle'>
          <Space size='large'>
            <div
              onClick={() => {
                setRegistrationState(undefined)
                setParams({ ...params, registrationState: undefined })
              }}
              style={
                registrationState === undefined
                  ? { color: theme.c1, fontWeight: 'bold', cursor: 'pointer' }
                  : { cursor: 'pointer' }
              }
            >
              全部<span>{counts.totalCount}</span>
            </div>
            <div
              onClick={() => {
                setRegistrationState(RegistrationState.NotTreated)
                setParams({
                  ...params,
                  registrationState: RegistrationState.NotTreated,
                })
              }}
              style={
                registrationState === RegistrationState.NotTreated
                  ? { color: theme.c1, fontWeight: 'bold', cursor: 'pointer' }
                  : { cursor: 'pointer' }
              }
            >
              未就诊<span>{counts.notCount}</span>
            </div>
            <div
              onClick={() => {
                setRegistrationState(RegistrationState.Treating)
                setParams({
                  ...params,
                  registrationState: RegistrationState.Treating,
                })
              }}
              style={
                registrationState === RegistrationState.Treating
                  ? { color: theme.c1, fontWeight: 'bold', cursor: 'pointer' }
                  : { cursor: 'pointer' }
              }
            >
              就诊中<span>{counts.ingCount}</span>
            </div>
            <div
              onClick={() => {
                setRegistrationState(RegistrationState.Treated)
                setParams({
                  ...params,
                  registrationState: RegistrationState.Treated,
                })
              }}
              style={
                registrationState === RegistrationState.Treated
                  ? { color: theme.c1, fontWeight: 'bold', cursor: 'pointer' }
                  : { cursor: 'pointer' }
              }
            >
              已就诊<span>{counts.yesCount}</span>
            </div>
          </Space>
        </Row>
      )}

      <PatientQuery
        onValueChange={(vs) => {
          if (today) {
            setParams({ ...vs, registrationState, _dateRange: undefined })
          } else {
            setRegistrationState(vs.registrationState)
            setParams({ ...vs })
          }
        }}
        today={today}
        currentTab={currentTab}
      />
      <EditableList
        loading={queryBtnLoading}
        page={page}
        style={{
          flex: 1,
          paddingBottom: today ? '50px' : '10px',
          paddingLeft: 15,
          paddingRight: 15,
        }}
        onRow={(r) => ({
          onDoubleClick: () => {
            if (
              (r?.treatmentDepartmentName == '未指定科室' &&
                r?.treatmentDoctorName == '未指定医生') ||
              (r?.registrationDepartmentName == '未指定科室' &&
                r?.registrationDoctorName == '未指定医生')
            ) {
              dispatch(getChangeRegistration(r.id))
                .then(unwrapResult)
                .then((v: any) => {
                  dispatch(
                    traceRoute({
                      name: '医生门诊',
                      path: '/treatment',
                      query: `registrationId=${r.id}`,
                    })
                  )
                })
                .catch((v) => {
                  setRemark(r?.remark?.replace('#', ','))
                  setRegisterNoAppointId(r?.id)
                  setRegisterNoAppointModalVisible(true)
                })
            } else {
              dispatch(
                traceRoute({
                  name: '医生门诊',
                  path: '/treatment',
                  query: `registrationId=${(r as Registration)?.id}`,
                })
              )
            }
          },
        })}
        columns={RegistrationColumns(
          (action, r) => {
            switch (action) {
              case 'registerNoAppoint':
                dispatch(getChangeRegistration(r.id))
                  .then(unwrapResult)
                  .then((v: any) => {
                    dispatch(
                      traceRoute({
                        name: '医生门诊',
                        path: '/treatment',
                        query: `registrationId=${r.id}`,
                      })
                    )
                  })
                  .catch((v) => {
                    setRemark(r?.remark?.replace('#', ','))
                    setRegisterNoAppointId(r?.id)
                    setRegisterNoAppointModalVisible(true)
                  })
                break
              case 'treatment':
                dispatch(
                  traceRoute({
                    name: '医生门诊',
                    path: '/treatment',
                    query: `registrationId=${r.id}`,
                  })
                )
                break
              case 'register':
                history.push({
                  pathname: '/RegistrationsDetail',
                  state: {
                    registrationId: r.id,
                    patientId: r.patientId,
                    tag: today ? 2 : 3,
                    name: 'patient',
                    patientInfo: {
                      patientName: r.patientName,
                      sex: r.patientSex || undefined,
                      age: r.patientAge,
                      ageMonth: r.patientMonth,
                      phone: r.patientPhone,
                      address: r.address,
                      identityCard: r.idCardNumber,
                      ageYear: r.birthday,
                    },
                  },
                })
                sessionStorage.setItem('tag', (today ? 2 : 3) as any)
                sessionStorage.removeItem('id')
                break
              case 'detail':
                history.push({
                  pathname: '/RegistrationsDetail',
                  state: {
                    registrationId: r.id,
                    id: r?.precheckId,
                    tag: today ? 2 : 3,
                    name: 'patient',
                  },
                })
                sessionStorage.setItem('id', r?.precheckId as any)
                sessionStorage.setItem('tag', (today ? 2 : 3) as any)
                break
              case 'convertInsurance':
                if (r.insuranceCode == 'ZIFEI') {
                  //自费转医保前，需要先读卡
                  convertInsuranceToYb(r)
                  // Modal.confirm({
                  //   centered: true,
                  //   title: '确认操作',
                  //   width: 600,
                  //   content: '确认完成该处置？',
                  //   okText: '确认',
                  //   cancelText: '取消',
                  //   icon: null,
                  //   onOk: () => {
                  //   },
                  // })
                } else {
                  convertInsuranceToZf(r)
                }
                break
            }
          },
          (page.current - 1) * page.size,
          true,
          today
        )}
        onChangePage={(current, size) => {
          setParams({
            ...params,
            current,
            size,
            index: today ? 0 : 1,
          })
        }}
      />
      <RegisterNoAppointModal
        id={registerNoAppointId}
        remark={remark}
        visible={registerNoAppointModalVisible}
        onCancel={() => {
          setRegisterNoAppointModalVisible(false)
        }}
        onOk={() => {
          getPage()
          setRegisterNoAppointModalVisible(false)
        }}
      />
      <Modal
        title='自费转医保'
        visible={registrationForConvert != null}
        onCancel={() => setRegistrationForConvert(null)}
        footer={null}
      >
        <span>
          您正在操作
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            [{registrationForConvert?.patientName}]
          </span>{' '}
          的自费转医保，请先读卡。
        </span>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            loading={readCardLoading}
            style={{
              marginRight: '14px',
            }}
            type='primary'
            onClick={() => {
              readCard(3)
            }}
          >
            读实体卡
          </Button>
          <Button
            loading={readCardLoading}
            style={{
              marginRight: '14px',
            }}
            type='primary'
            onClick={() => {
              readCard(1)
            }}
          >
            读电子凭证
          </Button>
          <Button
            style={{
              marginRight: '14px',
            }}
            onClick={() => {
              setRegistrationForConvert(null)
            }}
          >
            取消
          </Button>
        </Row>
      </Modal>
    </Col>
  )
}
