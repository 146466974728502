/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-29 15:43:45
 * @LastEditors: K
 * @LastEditTime: 2022-10-08 11:59:51
 */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, notification, Row, Space } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import styles from './PaymentConfirm.module.css'

interface MembersSettlementModalProps {
  visible: boolean
  membersInfo: any
  onCancel: () => void
  onOk: () => void
}

export const MembersSettlementModal = ({
  visible,
  membersInfo,
  onCancel,
  onOk,
}: MembersSettlementModalProps): ReactElement => {
  const [form] = Form.useForm()
  return (
    <Modal
      visible={visible}
      title='会员结算'
      width={700}
      maskClosable={false}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 17 }}
        autoComplete="off"
      >

        <Form.Item label="会员卡号">{membersInfo?.memberPhone}</Form.Item>
        <Form.Item label="账户余额">{membersInfo?.balanceMoney?.toFixed(2)}</Form.Item>

      </Form>
      <div className={styles.infoWrap}>
        <span className={styles.infoIcon}><ExclamationCircleOutlined /></span>
        <span className={styles.infoText} >当前会员余额不足</span>
      </div>

      <div className={styles.footerBtn}>
        <Space>
          <Button onClick={onCancel}>取消</Button>
          <Button
            type='primary'
            onClick={onOk}
          >去充值</Button>

        </Space>
      </div>
    </Modal>
  );
}

