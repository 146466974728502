/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-01 16:31:08
 * @LastEditors: K
 * @LastEditTime: 2022-07-22 11:06:33
 */
import { Form, Input, Modal, TimePicker } from 'antd';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { getModalTypeName } from '../../../models/rankingListPage';
import { stringTrim } from '../../../utils/StringUtils';
import styles from "../composing.module.css";

export const RankingModal = (props: {
  title: number;
  visible: boolean;
  tableInfo: any;
  onOk: (obj: any) => void;
  onCancel: () => void;
}): ReactElement => {

  const [form] = Form.useForm()

  const [workTime, setWorkTime] = useState<any>([])

  useEffect(() => {
    if (props.title != 1) {
      form.setFieldsValue({
        username: props.tableInfo?.shiftName,
        workTime: [moment(props.tableInfo?.shiftStartTime, 'HH:mm:ss'), moment(props.tableInfo?.shiftEndTime, 'HH:mm:ss')]
      })
    } else {
      form.resetFields()
    }

  }, [props.visible])
  const Rules = [
    {
      required: true,
    },
  ]
  return (
    <div>
      <Modal
        title={getModalTypeName(props.title)}
        visible={props.visible}
        onOk={() => {
          props.title == 1 || (props.title !== 3 && props.tableInfo?.appointmentStatus < 3) ? form.submit() :
            props.title === 3 && props.tableInfo?.appointmentStatus === 2 ? props.onOk({
              id: props.tableInfo.id,
              textType: props.title
            }) : props.onOk('')


        }}
        onCancel={() => {
          props.onCancel();
        }}
        width={500}
      >
        <div>
          {props.title == 1 || (props.title !== 3 && props.tableInfo?.appointmentStatus != 3) ?
            (<Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 12 }}
              onFinish={(value) => { 
                props.onOk({
                  id: props.title !== 1 ? props.tableInfo.id : null,
                  shiftName: value.username,
                  shiftStartTime: workTime[0],
                  shiftEndTime: workTime[1],
                })

              }}
            >
              <Form.Item
                label="班次"
                name="username"
                getValueFromEvent={stringTrim}
                rules={Rules}
              >
                <Input maxLength={18} />
              </Form.Item>
              <Form.Item name='workTime' label='营业时间' rules={Rules}>
                <TimePicker.RangePicker value={workTime} minuteStep={30} onChange={(v) => {
                  if (!v) return
                  const start = moment(v[0]).format('HH:mm')
                  const end = moment(v[1]).format('HH:mm')
                  setWorkTime([start, end])
                }} format="HH:mm" />
              </Form.Item>
            </Form>) : (<div>{props.tableInfo?.appointmentStatus != 3 && props.title == 3 ? '当前班次已有排班,是否确定删除班次?' :
              props.tableInfo?.appointmentStatus == 3 ? '该排班时间下存在有效预约订单及预约设置，请取消预约订单后再进行班次操作' : ''}</div>)
          }
        </div>
      </Modal>

    </div>
  );
}

export default RankingModal;
