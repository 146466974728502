import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store";
import { LoadingState } from "../../models/common";
import { fromJson,  serviceDelay} from "../../models/serviceDelay";
import * as service from "../../services/serviceDelay";
interface servicedelay {
  creatingState: LoadingState;
  states: [];

}

const initialState: servicedelay = {
  creatingState: LoadingState.Idle,
  states:[],
};

const serviceDelaySlice = createSlice({
  name: "serviceDelaySlice",
  initialState,
  reducers: {
    setCreatingState: (state, action: PayloadAction<LoadingState>) => {
      state.creatingState = action.payload;
    },

    setState: (state, action) => {
      state.states= action.payload;
    },
  },
});

export const { setCreatingState,setState, } = serviceDelaySlice.actions;


  export const getDelayState = createAsyncThunk<
  void,
  service.ServiceDelayParams,
  RootThunkApi
>("Delay/getDelayStatus", async (body, api) => {
  return sendAsync(service.getDelay(body), api);
});

export default serviceDelaySlice.reducer;
