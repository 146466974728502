import {createAsyncThunk} from "@reduxjs/toolkit";

import {RootThunkApi} from "../../app/store";
import {sendAsync} from "../../app/applicationSlice";
import * as S from "../../services/treatment";
import {AddDetail} from "../../services/treatment";
import {Treatment} from "../../models/treatment";



export const saveBeautyTemplateClassify = createAsyncThunk<
    void,
    AddDetail,
    RootThunkApi<void>
>("medicalbeauty/saveBeautyTemplateClassify", async (params, api) => {
    return sendAsync(S.saveBeautyClinic(params), api);
});


export interface treatmentBeauty {
    id?: number
    parentId?: number
    pastMedicalHistory?: string
    chiefComplaint?: string
    historyOfPresentIllness?: string
    personalHistory?: string
    disease : string
    content : string
    medicalBeautyId : string
    treatmentId ?: string
    version : string
    medicalBeautyName : string
    patientAge?: number
    patientName?: string
    patientId ?: number
    registrationId : string
    medicalRecord : number
    category : number
    itemId:string
    insuranceCode?:string
}

interface SaveTreatmentBeautyReturn {
    treatment:Treatment,
    medicalBeautyItem : {
        id : string
    }
}

export const saveTreatmentBeauty = createAsyncThunk<
    SaveTreatmentBeautyReturn,
    treatmentBeauty,
    RootThunkApi<void>
>("treatment/saveTreatmentBeauty", async (params, api) => {
    return sendAsync(S.saveTreatmentBeauty(params), api);
});