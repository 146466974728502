import { Button, Col, Form, Input, Row, Select, Space } from "antd"
import React, { useEffect, useState } from "react"
import { SearchOutlined, CaretDownFilled } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { DateRangeType, DateSelect, getDateRange } from "../../../compnents/form/DateSelect"
import { CompletedOperationParams } from "../../../services/completedOperation"
import moment from "moment"
import { DateTimeFormatSimpleOnly } from "../../../models/datetime"
import { RootDispatch } from "../../../app/store"
import { OpRecord } from "../../../services/operatingRoom"
import { unwrapResult } from "@reduxjs/toolkit"
import { StationCategory } from "../../../models/user"
import { getUsersInDepartmentAsync } from "../../completion/completionSlice"
import { getOperaingRoomList } from "../../operatingRoom/operatingRoomSlice"

const { Option } = Select

export const CompletedOperationQuery = (props: {
  onValueChange: (queries: CompletedOperationParams) => void
  patientId?: string
}) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [opRoom, setOpRoom] = useState<OpRecord[]>([])

  const [doctorList, setDoctorList] = useState<any>([])

  const dataParams = JSON.parse(sessionStorage.getItem('/operationStation_CompletedOperation') || '{}')

  useEffect(() => {
    dispatch(getOperaingRoomList({ current: 1, size: 1000 }))
      .then(unwrapResult)
      .then((res) => {
        setOpRoom(res.records.filter((v) => v.state === 1))
      })
    dispatch(
      getUsersInDepartmentAsync({
        departmentId: "",
        stationCategory: StationCategory.DoctorOrPharmacist,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setDoctorList(res)
      })
    const [head, tail] = getDateRange(DateRangeType.Today)
    if (Object.keys(dataParams).length && !props.patientId) {
      form.setFieldsValue({
        ...dataParams,
        timeHead: dataParams?.startTime
          ? moment(dataParams?.startTime).format(DateTimeFormatSimpleOnly)
          : head,
        timeTail: dataParams?.endTime
          ? moment(dataParams?.endTime).format(DateTimeFormatSimpleOnly)
          : tail,
        flag: (dataParams?.flag == 1 || dataParams?.flag == 2) ? dataParams?.flag : null
      })
    } else {
      form.setFieldsValue({
        timeHead: head,
        timeTail: tail,
      })
    }
    form.submit()
  }, [])

  const width = '8rem'

  const refresh = () => {
    const vs = form.getFieldsValue()
    props.onValueChange({
      ...dataParams,
      ...vs,
      timeHead: null,
      timeTail: null,
      startTime: vs?.timeHead
        ? moment(vs?.timeHead).format(DateTimeFormatSimpleOnly)
        : undefined,
      endTime: vs?.timeTail
        ? moment(vs?.timeTail).format(DateTimeFormatSimpleOnly)
        : undefined,
      flag: vs.flag ? vs.flag : 3,
      current: 1,
    })
  }

  return (
    <Form
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(vs) => {
        props.onValueChange({
          current: 1,
          ...dataParams,
          ...vs,
          timeHead: null,
          timeTail: null,
          startTime: vs?.timeHead
            ? moment(vs?.timeHead).format(DateTimeFormatSimpleOnly)
            : undefined,
          endTime: vs?.timeTail
            ? moment(vs?.timeTail).format(DateTimeFormatSimpleOnly)
            : undefined,
          flag: vs.flag ? vs.flag : 3,
        })
      }}
    >
      <Row
        style={{ marginBottom: 10, width: '100%', height: '42px' }}
        align='middle'
        justify='space-between'
      >
        <Space>
          {!props.patientId && (
            <Form.Item name='keyword' noStyle>
              <Input
                size='middle'
                placeholder='患者姓名/手机号/助记码'
                allowClear
                onChange={refresh}
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          )}
          <Form.Item name='flag' noStyle>
            <Select placeholder='状态' allowClear onChange={refresh}>
              <Option value={2}>已取消</Option>
              <Option value={1}>已完成</Option>
            </Select>
          </Form.Item>
          <Form.Item name='mainDoctorId' noStyle>
            <Select
              placeholder='主刀医生'
              allowClear
              onChange={refresh}
              showSearch
              filterOption={(input, option) => {
                return option?.className
                  ? ((option?.className as unknown) as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  : false
              }}
              style={{ width }}
            >
              {doctorList.map((v: any) => (
                <Option className={v.mnemonicCode} key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='anesthesiaDoctorId' noStyle>
            <Select
              placeholder='麻醉医生'
              allowClear
              onChange={refresh}
              showSearch
              filterOption={(input, option) => {
                return option?.className
                  ? ((option?.className as unknown) as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  : false
              }}
              style={{ width }}
            >
              {/* <Option value={0}>1</Option> */}
              {doctorList.map((v: any) => (
                <Option className={v.mnemonicCode} key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='operatingRoomId' noStyle>
            <Select
              placeholder='手术室'
              allowClear
              // suffixIcon={<CaretDownFilled />}
              onChange={refresh}
            >
              {opRoom.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='' noStyle>
            <DateSelect
              placeholder='时间范围'
              style={{ width: '9rem' }}
              initialValue={DateRangeType.Today}
              onChange={refresh}
              labelPrefix=''
              namePrefix='time'
            />
          </Form.Item>
          <Button onClick={refresh} type='primary' style={{ float: 'right' }}>
            查询
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
