// 0门诊 1微信 2电话 3其他
export enum followUpWay {
  MZ = 0,
  DH = 1,
  WX = 2,
  QT = 3,
}

export const FollowUpWayList = [
  followUpWay.MZ,
  followUpWay.DH,
  followUpWay.WX,
  followUpWay.QT,
]

export function getFollowUpWayCategoryList(cate: followUpWay) {
  switch (cate) {
    case 0:
      return '门诊'
    case 1:
      return '电话'
    case 2:
      return '微信'
    case 3:
      return '其他'
    default:
      break
  }
}

// 3天 1 5天 2 一周 3 两周 4一个月 5两个月
export enum followUpTypeTime {
  ST = 3,
  WT = 5,
  YZ = 7,
  LZ = 14,
  YGY = 30,
  LGY = 60,
}

export const FollowUpTypeTimeList = [
  followUpTypeTime.ST,
  followUpTypeTime.WT,
  followUpTypeTime.YZ,
  followUpTypeTime.LZ,
  followUpTypeTime.YGY,
  followUpTypeTime.LGY,
]

export function getFollowUpTypeCategoryList(cate: followUpTypeTime) {
  switch (cate) {
    case 3:
      return '3天'
    case 5:
      return '5天'
    case 7:
      return '一周'
    case 14:
      return '两周'
    case 30:
      return '一个月'
    case 60:
      return '两个月'
    default:
      break
  }
}


export function getDiffDay(date_1: any, date_2: any) {
  // 计算两个日期之间的差值

  const myDate_1 = Date.parse(date_1)
  const myDate_2 = Date.parse(date_2)
  // 将两个日期都转换为毫秒格式，然后做差
  const diffDate = Math.abs(myDate_1 - myDate_2) // 取相差毫秒数的绝对值

  const totalDays = Math.floor(diffDate / (1000 * 3600 * 24)) // 向下取整

  return totalDays // 相差的天数
}

export function getDate(datestr: any) {
  const temp = datestr.split('/')
  if (temp[1] === '01') {
    temp[0] = parseInt(temp[0], 10) - 1
    temp[1] = '12'
  } else {
    temp[1] = parseInt(temp[1], 10) - 1
  }
  //new Date()的月份入参实际都是当前值-1
  const date = new Date(temp[0], temp[1], temp[2])
  return date
}

//获取两个日期间的所有日期
export function getDiffDate(start: any, end: any) {
  const startTime = getDate(start)
  const endTime = getDate(end)
  const dateArr = []
  while (endTime.getTime() - startTime.getTime() > 0) {
    const year = startTime.getFullYear()
    const month =
      (startTime.getMonth() + 1).toString().length === 1
        ? '0' + (parseInt(startTime.getMonth().toString(), 10) + 1)
        : startTime.getMonth() + 1
    const day =
      startTime.getDate().toString().length === 1
        ? '0' + startTime.getDate()
        : startTime.getDate()
    dateArr.push(year + '-' + month + '-' + day)
    startTime.setDate(startTime.getDate() + 1)
  }
  return dateArr
}