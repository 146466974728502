/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Space,
  Image
} from 'antd'
import Sider from 'antd/lib/layout/Sider'
import { UploadFile } from 'antd/lib/upload/interface'
import _ from 'lodash'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {selectShortName, selectTenantName} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import Editor from '../../../compnents/editor/Editor'
import { EditableList } from '../../../compnents/list/EditableList'
import { Label } from '../../../compnents/widgets/Label'
import { DateTimeFormatSimpleOnly } from '../../../models/datetime'
import { Disease } from '../../../models/disease'
import { patientInfo } from '../../../models/intelligence'
import {
  BeautyValue,
  MedicalValue,
  TemplateCategory,
  TemplateDetail,
} from '../../../models/template'
import { TreatmentNotice } from '../../../models/treatment'
import { getGenderName } from '../../../models/user'
import { CopyTreatmentParams } from '../../../services/treatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { useQuery } from '../../../utils/HttpUtils'
import { UploadModal } from '../../putstorelist/Query/uploadModal'
import {
  getBeautyClinicSaveAsync,
  getBeaytyClinicDetail,
  getBeaytyClinicList,
  getMedicalDetailAsync,
  getMedicalListAsync,
  getMedicalRemoveAsync,
  getTreatments,
  getUrl,
  getVideoSave,
} from '../beautyClinic/beautyClinicSlice'
import { deleteUploadFileList } from '../diagnosis/diagnosisSlice'
import { PreferencesSetModal } from '../diagnosis/preferencesSetModal'
import { Treatments, TreatmentsRef } from '../list/Treatments'
import { SurgicalWordColumns } from './columns'
import styles from './SurgicalWord.module.css'
import {
  copyTreatment,
  getTreatment,
  resetBeautyClinic,
  selectRegistration,
} from './SurgicalWordSlice'

export interface BeautyClinicRef {
  submit: () => Promise<void>
  showTreatments: () => void
}

export const templateDetail = []

interface DiagnosisProps {
  tabKey?: string
  readMode?: boolean
  state?: number
  saveEdit?: () => void
  PreferencesSetChange?: () => void
}

const RealSurgicalWord = ({
  tabKey,
  readMode = false,
  state = undefined,
  PreferencesSetChange
}: DiagnosisProps): ReactElement => {
  const data = [
    {
      name: '术前小结',
      id: 1,
      src:
        'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/%E6%89%8B%E6%9C%AF%E6%96%87%E4%B9%A6/%E6%9C%AF%E5%89%8D%E5%B0%8F%E7%BB%93%E3%80%81%E6%9C%AF%E5%89%8D%E8%AE%A8%E8%AE%BA.doc',
    },
    {
      name: '手术知情同意书',
      id: 2,
      src:
        'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/%E6%89%8B%E6%9C%AF%E6%96%87%E4%B9%A6/%E6%89%8B%E6%9C%AF%E7%9F%A5%E6%83%85%E5%90%8C%E6%84%8F%E4%B9%A6.doc',
    },
    {
      name: '手术风险评估表',
      id: 4,
      src:
        'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/%E6%89%8B%E6%9C%AF%E6%96%87%E4%B9%A6/%E6%89%8B%E6%9C%AF%E9%A3%8E%E9%99%A9%E8%AF%84%E4%BC%B0%E8%A1%A8.doc',
    },
    {
      name: '手术安全评估表',
      id: 5,
      src:
        'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/%E6%89%8B%E6%9C%AF%E6%96%87%E4%B9%A6/%E6%89%8B%E6%9C%AF%E5%AE%89%E5%85%A8%E6%A0%B8%E6%9F%A5%E8%A1%A8.doc',
    },
    {
      name: '手术记录',
      id: 5,
      src:
        'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/手术文书/%E6%89%8B%E6%9C%AF%E8%AE%B0%E5%BD%95.doc?versionId=CAEQJBiBgMDM2.be8hciIGQyNDE2ZDQwOWJiNTQxY2U5YzI5ZmU0ZjI4NWQzMGY1',
    },
  ]
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const query = useQuery()

  const treatmentsRef = useRef<TreatmentsRef>(null)

  const registration = useSelector(selectRegistration)

  const [selectedDiseases, setSelectedDiseases] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [diagnosisForm] = Form.useForm()

  const [patientForm] = Form.useForm()

  const [treatmentForm] = Form.useForm()

  const [templates, setTemplates] = useState<BeautyValue[]>([])

  const [menuKey, setMenuKey] = useState(['术前小结'])

  const EditorRef = useRef<any>(null)

  const [xml, setXml] = useState<any>('')

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const [isCreating, setIsCreating] = useState(false)

  const [templateDetails, setTemplateDetails] = useState<
    Record<string, TemplateDetail[]>
  >({})

  const [notice, setNotice] = useState<TreatmentNotice | undefined>()

  const registrationId = query.get('registrationId') as string

  const treatmentId = query.get('treatmentId') as string

  const [Data, setData] = useState<MedicalValue[]>([])

  const TenantName = useSelector(selectTenantName)

  const TenantShortName = useSelector(selectShortName)

  const [patientData, setpatientData] = useState<patientInfo>()

  const [preferencesSetShow, setiPreferencesSetShow] = useState(false) //偏好设置

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [isUploadVisible, setIsUploadVisible] = useState(false)   //上传附件弹窗

  const [imgSrc, setImgSrc] = useState('')   //预览图片路径

  const [visible, setVisible] = useState(false)   //预览图片是否显示

  const getMedical = () => {
    dispatch(
      getMedicalListAsync({
        category: 2,
        showImgFlag: 1,
        registrationId: Number(registrationId),
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setData(res)
      })
  }

  const submit = async () => {
    dispatch(
      getBeautyClinicSaveAsync({
        id: String(selectedDiseases?.id),
        name: String(selectedDiseases?.name),
        registrationId: String(registrationId),
        category: '2',
        version: '1',
        medicalBeautyId: String(selectedDiseases?.medicalBeautyId),
        XMl: EditorRef.current?.getXmltoSde(),
        diseaseType: '1',
      })
    )
      .then(unwrapResult)
      .then(() => {
        getMedical()
      })
  }

  useEffect(() => {
    getMedical()
    dispatch(getBeaytyClinicList({ category: 2, parentId: 8 }))
      .then(unwrapResult)
      .then((res) => {
        setSelectedDiseases({ ...res[0], id: '', medicalBeautyId: res[0].id })
        setTemplates(res)
        dispatch(
          getBeaytyClinicDetail({
            id: res[0].id,
            registrationId: Number(registrationId),
          })
        )
          .then(unwrapResult)
          .then((res: string) => {
            EditorRef.current?.importXmltoSde(res)
            setXml(res)
          })
      })
    dispatch(
      getTreatments({
        registrationId,
        treatmentId,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        setpatientData(v)
      })
  }, [dispatch])

  useEffect(() => {
    if (xml && JSON.stringify(patientData?.registration) !== '{}') {
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-institutionName-1',
        TenantShortName
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-currentDate-1',
        moment().format(DateTimeFormatSimpleOnly)
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-department-1',
        patientData?.registration.treatmentDepartmentName
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-userName-1',
        patientData?.registration.patientName
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-userSex-1',
        getGenderName(patientData?.registration.patientSex as number)
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-userAge-1',
        patientData?.registration.patientAge
      )
      EditorRef.current?.sdeSetControlByIdSetValue(
        'sde-parameter-mrn-1',
        patientData?.registration.patientId
      )
    }
  }, [patientData, xml])

  return (
    <div
      style={{
        display: 'flex',
        height: 'calc(100% - 5px)',
        position: 'relative',
        flexDirection: 'column',
      }}
    >
      <Row
        className={styles.container}
        style={{
          height: '100%',
          overflow: 'hidden',
          flex: 1,
        }}
        wrap={false}
      >
        {!readMode && (
          <Col
            style={{
              width: 242,
              height: '100%',
              marginRight: 10,
              backgroundColor: theme.pn,
              borderRadius: theme.br,
              overflow: 'auto',
            }}
          >
            <Label label='诊断模板' style={{ marginLeft: 20 }} />
            <Divider />
            <div style={{ padding: '0 10px' }}>
              <Menu
                theme='light'
                mode='inline'
                defaultSelectedKeys={['68']}
                style={{ fontSize: '16px' }}
                onClick={({ item, key }) => {
                  setMenuKey([key])
                }}
              >
                {templates.map((v) => (
                  <Menu.Item
                    key={v.id}
                    onClick={() => {
                      setSelectedDiseases({
                        ...v,
                        medicalBeautyId: v.id,
                        id: '',
                      })
                      if (selectedDiseases.medicalBeautyId !== v.id) {
                        dispatch(
                          getBeaytyClinicDetail({
                            id: v.id,
                            registrationId: Number(registrationId),
                          })
                        )
                          .then(unwrapResult)
                          .then((res: string) => {
                            EditorRef.current?.importXmltoSde(res)
                            setXml(res)
                          })
                      }
                    }}
                  >
                    {v.name}
                  </Menu.Item>
                ))}
                {/* <Menu.Item key={73}>文书下载</Menu.Item> */}
              </Menu>
            </div>
            <Button style={{ margin: '20px' }} icon={<PlusOutlined />} onClick={() => {
              setIsUploadVisible(true)
            }}>上传附件(图片)</Button>
          </Col>
        )}
        <Col
          flex='1'
          style={{
            height: '100%',
            overflow: 'hidden',
            borderRadius: readMode ? `0 0 0 ${theme.br}px` : `${theme.br}px`,
            backgroundColor: theme.pn,
          }}
        >
          {/* <Label label='手术文书' style={{ marginLeft: 20 }} /> */}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px' }}>
            <Label label='手术文书' />
            <div style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => setiPreferencesSetShow(true)}><EllipsisOutlined /></div>
          </div>
          <Divider />
          {menuKey[0] === '73' ? (
            <>
              <EditableList
                bordered
                page={{
                  items: data,
                  current: 1,
                  size: 10,
                  total: 10,
                }}
                columns={SurgicalWordColumns()}
                pagination={false}
                style={{ margin: '20px' }}
              />
            </>
          ) : (
            <>
              <Editor ref={EditorRef} />
              <Row
                justify='end'
                style={{ marginRight: '20px', marginBottom: '20px' }}
              >
                <Space>
                  {/* <Button type='primary' onClick={() => {}}>
                    文书打印
                  </Button> */}
                  <Button
                    type='primary'
                    loading={isCreating}
                    onClick={() => {
                      submit()
                    }}
                  >
                    保存
                  </Button>
                </Space>
              </Row>
            </>
          )}
        </Col>
        <Col
          style={{
            width: 270,
            height: '100%',
            overflow: 'auto',
            marginLeft: readMode ? 10 : 10,
            backgroundColor: theme.pn,
            borderRadius: readMode ? `0 0 ${theme.br}px 0` : `${theme.br}px`,
          }}
        >
          <Label label='文书记录' style={{ marginLeft: 20 }} />
          <Divider />
          {Data.map((v: any) => (
            <>
              <Row
                key={v}
                align='middle'
                justify='space-between'
                wrap={false}
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  marginRight: 20,
                  flex: 1,
                  overflow: 'auto',
                  backgroundColor: 'transparent',
                  fontWeight: 'normal',
                }}
              >
                {/* <Col span={24}> */}
                <a
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginRight: 10,
                    width: '150px',
                    color: '#000',
                  }}
                  title={v.name}
                >
                  {v.name}
                </a>
                <Space className={styles.opBar}>
                  <Button
                    ghost
                    type='link'
                    style={{
                      color: '#027AFF',
                      marginRight: '-20px',
                    }}
                    onClick={() => {
                      if (v.category == 5) {
                        setImgSrc('')
                        v.coherentVideo.split(',').map((v: any) => {
                          dispatch(getUrl({ fileName: v.split('@')[2] }))   //获取图片路径
                            .then(unwrapResult)
                            .then((v: any) => {
                              setImgSrc(v)
                              setVisible(true)
                            })
                        })
                      } else if (v.category == 2) {
                        setSelectedDiseases(v)
                        dispatch(
                          getMedicalDetailAsync({
                            id: v.id,
                          })
                        )
                          .then(unwrapResult)
                          .then((res: string) => {
                            EditorRef.current?.importXmltoSde(res)
                            setXml(res)
                          })
                      }



                    }}
                  >
                    查看
                  </Button>
                  <Button
                    ghost
                    type='link'
                    style={{
                      color: '#FF5D5D',
                    }}
                    onClick={() => {
                      dispatch(getMedicalRemoveAsync({ ids: v.id }))
                        .then(unwrapResult)
                        .then(() => {
                          getMedical()
                        })
                    }}
                  >
                    删除
                  </Button>
                </Space>
              </Row>
              <Row
                key={v}
                align='middle'
                justify='space-between'
                wrap={false}
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  flex: 1,
                  overflow: 'auto',
                  backgroundColor: 'transparent',
                  fontWeight: 'normal',
                }}
              >
                {/* <Col span={24}> */}
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    marginRight: 10,
                  }}
                >
                  {v.createTime}
                </span>
              </Row>
            </>
          ))}
        </Col>
      </Row>
      <PreferencesSetModal
        tabNum={'6'}
        isModalVisible={preferencesSetShow}
        handleCancel={() => setiPreferencesSetShow(false)}
        onsubmit={() => {
          PreferencesSetChange && PreferencesSetChange()
          setiPreferencesSetShow(false)
        }}
      />

      <UploadModal
        uploadModalVisible={isUploadVisible}
        accept={'.png,.tiff,.jpg,.jpeg,bmp,.heic,.webp'}
        id={registrationId}
        uploadType={5}
        onCancel={() => {
          setIsUploadVisible(false)
        }}
        maxCount={20}
        onSubmit={(v, i, id) => {
          if (fileList.length + v.length > 20) {
            notification.info({ message: '已达到最大上传数量，请先删除再上传！' })
            return
          }
          dispatch(deleteUploadFileList({ ids: id }))
            .then(unwrapResult)
          // 上传影像保存
          const imgList: any = []
          v.forEach((item: any) => {
            imgList.push(new Promise((resolve, reject) => {
              // 上传影像保存
              dispatch(
                getVideoSave({
                  category: 5,
                  id: String(selectedDiseases?.id),
                  registrationId: String(registrationId),
                  coherentVideo: `${item.name}@${item.uid}@${item.url}`,
                })).then(unwrapResult)
                .then((res: any) => {
                  resolve(res)
                }).catch(() => reject())
            }))
          })
          Promise.all(imgList).then(() => {
            getMedical()
            notification.success({ message: '上传成功' })
          })
          setFileList([...fileList, ...v])
          setIsUploadVisible(false)
        }}

      />
      <Image
        style={{ display: 'none' }}
        src={imgSrc}
        preview={{
          visible,

          onVisibleChange: value => {
            setVisible(value);
          },
        }}
      />
    </div>
  )
}

export const SurgicalWord = forwardRef(RealSurgicalWord)
