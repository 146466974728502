/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 13:49:00
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-28 17:31:59
 */
import { Form, Row, Col, Input, Select, Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FieldData } from "../../models/fielddata";
import { SearchOutlined } from "@ant-design/icons";
import { StockfloorParams } from "../../services/stockfloor";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommonCodeCompletions,
  selectCommonCodes,
} from "../completion/completionSlice";
import { CommonCodeCategory } from "../../models/commoncode";
import {
  BillingCategories,
  BillingCategoryName,
} from "../../models/billingCategory";
import { pageStockfloorEdit } from "./stockfloorModalSlice";
const { Option } = Select;

export const StockfloorModalQuery = (props: {
  onValueChange: (queries: StockfloorParams) => void;
  onclick: () => void;
}) => {
  const [form] = Form.useForm();

  const [fields, setFields] = useState<FieldData[]>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommonCodeCompletions(CommonCodeCategory.Durg, ""));
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      flag:0
    });
  }, [])

  const YPdata = useSelector(selectCommonCodes(CommonCodeCategory.Durg));

  return (
    <Form
      fields={fields}
      form={form}
      name='control-hooks'
      onFieldsChange={(_, fs) => {
        props.onValueChange(form.getFieldsValue())
        setFields(fs)
      }}
      // onFinish={(fs) => {
      //   dispatch(pageStockfloorEdit({ ...fs }));
      // }}
    >
      <Row gutter={11}>
        <Col span={7}>
          <Form.Item name='Keyword'>
            <Input
              size='middle'
              placeholder='请输入关键字'
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='drugCategory'>
            <Select
              showSearch
              placeholder='药品分类'
              optionFilterProp='children'
              allowClear
            >
              {YPdata.map((v: any) => {
                return (
                  <Option value={v.name} key={v.name}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='billingCategory'>
            <Select placeholder='开票项目' allowClear>
              {BillingCategories.map((v) => {
                return (
                  <Option value={v} key={v}>
                    {BillingCategoryName(v)}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name='flag'>
            <Select placeholder='是否库存目录' allowClear>
              <Option value={0}>是否库存目录-否</Option>
              <Option value={1}>是否库存目录-是</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4} offset={1}>
          <Space>
            <Button type='primary' style={{ float: 'right' }} onClick={() => { props.onclick() }}>
              批量修改
            </Button>
            <Button htmlType='submit' type='primary' style={{ float: 'right' }}>
              查询
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  )
};
