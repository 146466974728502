/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-15 14:16:21
 * @LastEditors: sj
 * @LastEditTime: 2022-11-17 11:30:00
 */
import { Typography } from 'antd';
import React, { ReactElement } from 'react';
import styles from './listItem.module.css'
const { Text } = Typography

interface PatientLabelProps {
  label: string
  subLabel?: string
  padding?: number | string
  border?: boolean
}

function PatientLabel({ label, subLabel, padding = 20, border = true }: PatientLabelProps): ReactElement {
  return (
    <div className={styles.label} style={{
      padding,
      borderBottom: border ? '1px solid #e3e3e3' : '',
      marginBottom: border ? 20 : 0
    }}>
      {label}
      {
        subLabel && (
          <Text italic >
            <span style={{ color: '#999', fontSize: 14, fontWeight: 'normal', marginLeft: 10 }}>{subLabel}</span>
          </Text>
        )
      }
    </div>
  );
}

export default PatientLabel;
