import React, { ReactElement, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { TabBar, TabPane } from "../../compnents/widgets/TabBar";
import { selectTwoMenus } from "../../layouts/layoutSlice";
import { Users, UsersRef } from "../user/Users";
import { Col, Tabs } from "antd";
import { TreatmentLog } from "./treatmentLog";
import { ThemeContext } from "../../theme/ThemeContext";

export const TreatmentLogManagement = (): ReactElement => {
  const theme = useContext(ThemeContext);

  return (
    <TabBar style={{ margin: "10px 20px" }}>
      <TabPane tab="门诊日志管理" key="1">
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.pn,
            borderRadius: 10,
            margin: "10px 0",
            height: "calc(100% - 10px)",
          }}
        >
          <TreatmentLog />
        </Col>
      </TabPane>
    </TabBar>
  );
};
