/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 12:05:04
 * @LastEditors: K
 * @LastEditTime: 2022-07-19 17:33:02
 */
import { Button, Col } from "antd"
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { traceRoute } from '../../layouts/layoutSlice'
import { Composing } from './composing'
import styles from './composing.module.css'
import { MonthTab } from './monthTab'
import { RootDispatch } from '../../app/store'
import { SettingOutlined } from '@ant-design/icons'

export const ComposingTabBar = () => {
  const [selectKey, setSelectKey] = useState<any>()

  const dispatch = useDispatch<RootDispatch>()
  return (
    <Col className={styles.container}>
      <TabBar
        defaultActiveKey={'1'}
        onChange={(v) => {
          setSelectKey(v)
        }}
      >
        <TabPane key='1' tab='周'>
          <Composing />
        </TabPane>
        <TabPane key='2' tab='月'>
          <MonthTab selectKey={selectKey} />
        </TabPane>
      </TabBar>
      <Button
        className={styles.set}
        icon={<SettingOutlined />}
        style={{ position: 'absolute', top: '13px' }}
        type='primary'
        onClick={() => {
          dispatch(
            traceRoute({
              name: '班次设置',
              path: '/rankingList',
              state: {
                tag: 1,
              },
            })
          )
        }}
      >
        设置
      </Button>
    </Col>
  )
}
