/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-09-22 17:34:38
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, sendAsync } from "../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../app/store";
import { Tenant } from "../../../models/tenant";
import * as service from "../../../services/setting";

interface settingState {
  tenant?: Tenant;
  settings: any;
  nextSendDay: string
  isChange: boolean
}

const initialState: settingState = {
  settings: [],
  nextSendDay: '',
  isChange: false
};

const settingSlice = createSlice({
  name: "settingSlice",
  initialState,
  reducers: {
    setDetail: (state, action) => {
      state.tenant = action.payload.tenant;
      state.settings = _.reduce(
        action.payload.functionList,
        (acc, { type, value }) => ({
          ...acc,
          [type]: value === "true" ? true : value === "false" ? false : value,
        }),
        {}
      );
      state.nextSendDay = action.payload?.functionList?.find((v: any) => v.type == 27)?.updateTime?.split(' ')[0]
    },
    setNextSendDay: (state, action) => {
      state.nextSendDay = action.payload
    },
    setIsChange: (state, action) => {
      state.isChange = action.payload
    },
  },
});

export const { setDetail, setNextSendDay, setIsChange } = settingSlice.actions;

export const selectTenant = (state: RootState) => state.settinglable.tenant;

export const selectSettings = (state: RootState): any =>
  state.settinglable.settings;

export const selectIsChange = (state: RootState): boolean =>
  state.settinglable.isChange;

export const selectNextSendDay = (state: RootState): any =>
  state.settinglable.nextSendDay;

export const saveSettingLabel = createAsyncThunk<
  void,
  service.SettingMessageParams,
  RootThunkApi
>("setting/getSettingStatus", async (body, api) => {
  return sendAsync(service.getSettingSave(body), api);
});

export function getSettingDetail(): RootThunk {
  return api(service.getSettingDetail(), (data, dispatch) => {
    dispatch(setDetail(data));
  });
}

export default settingSlice.reducer;
