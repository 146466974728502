/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-30 10:59:05
 * @LastEditors: linxi
 * @LastEditTime: 2021-09-10 10:29:13
 */
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

export interface ToothSelProps {
  defaultTeethKey: string[];
  teethSelect: (teeth: string[]) => void;
  selTooth: (sel: boolean, tooth: number, teeth: string) => void;
}

export const ToothSel: React.FC<ToothSelProps> = ({
  defaultTeethKey,
  teethSelect,
  selTooth,
}) => {
  const [teeth] = useState<any[]>([1, 2, 3, 4, 5, 6, 7, 8]);

  const [toothP] = useState([0, 1, 2, 3]);

  const [toothStr, setToothStr] = useState(["", "", "", ""]);

  useEffect(() => {
    setToothStr(defaultTeethKey);
  }, [defaultTeethKey]);

  const selTeeth = (t: number, tp: string) => {
    const selObj = toothStr[t] ? toothStr[t].split(",").map(String) : [];
    const index = selObj.findIndex((v: any) => v.substr(0, 1) === tp);
    if (index !== -1) {
      selObj.splice(index, 1);
      selTooth(false, 0, "");
    } else {
      selObj.push(tp);
      selTooth(true, t, tp);
    }
    teethSelect(
      toothStr.map((v: any, i: number) =>
        i === t
          ? selObj
              .sort(function (a: any, b: any) {
                return a.substr(0, 1) - b.substr(0, 1);
              })
              .join(",")
          : v
      )
    );
  };

  const selTeethKey = (t: number, tp: string) => {
    const selObj = toothStr[t] ? toothStr[t].split(",").map(String) : [];
    const index = selObj.findIndex((v: any) => v.substr(0, 1) === tp);
    return index !== -1 ? true : false;
  };

  return (
    <>
      <Row style={{ height: "100%", verticalAlign: "top" }} gutter={7}>
        {toothP.map((v, i) => {
          return (
            <Col
              span={12}
              key={i}
              style={{
                paddingLeft: i % 2 ? "" : "7px",
                marginTop: i < 2 ? "" : "25px",
              }}
            >
              {i % 2 ? (
                <>
                  {teeth
                    .sort(function (a, b) {
                      return a - b;
                    })
                    .map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "inline-block",
                            width: "20px",
                            height: "20px",
                            border: "1px solid #eee",
                            margin: "0 2px",
                            lineHeight: "20px",
                            textAlign: "center",
                            cursor: "pointer",
                            background: selTeethKey(i, String(item))
                              ? "#027AFF"
                              : "",
                            color: selTeethKey(i, String(item))
                              ? "#fff"
                              : "#000",
                          }}
                          onClick={() => {
                            selTeeth(i, String(item));
                          }}
                          key={item}
                        >
                          {item}
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {teeth
                    .sort(function (a, b) {
                      return b - a;
                    })
                    .map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "inline-block",
                            width: "20px",
                            height: "20px",
                            border: "1px solid #eee",
                            margin: "0 2px",
                            lineHeight: "20px",
                            textAlign: "center",
                            cursor: "pointer",
                            background: selTeethKey(i, String(item))
                              ? "#027AFF"
                              : "",
                            color: selTeethKey(i, String(item))
                              ? "#fff"
                              : "#000",
                          }}
                          onClick={() => {
                            selTeeth(i, String(item));
                          }}
                          key={item}
                        >
                          {item}
                        </div>
                      );
                    })}
                </>
              )}
            </Col>
          );
        })}
      </Row>
    </>
  );
};
