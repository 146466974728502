import { Form, Input, Modal } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldData } from "../../models/fielddata";
import { findMenuById, getMenuMaxSort, MenuCategory } from "../../models/menu";
import { createMenu, selectMenus } from "./menuSlice";

interface MenuModalProps {
  visible: boolean;
  parentId?: string;
  menuId: string;
  onHide: () => void;
}

export const MenuModal = (props: MenuModalProps): ReactElement => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const menus = useSelector(selectMenus);

  const [fields, setFields] = useState<FieldData[]>([]);

  useEffect(() => {
    const [menu, parent] = findMenuById(menus, props.menuId);
    let extra = { name: "parent", value: "" };
    const fields = [
      {
        name: "name",
        value: menu?.name,
      },
      {
        name: "path",
        value: menu?.path,
      },
      {
        name: "icon",
        value: menu?.icon,
      },
    ];
    if (parent) {
      extra = { name: "parent", value: parent.name };
    } else if (props.parentId) {
      const [parent] = findMenuById(menus, props.parentId);
      if (parent) {
        extra = { name: "parent", value: parent.name };
      }
    }
    setFields(fields.concat(extra));
  }, [menus, props.menuId, props.parentId]);

  const params = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const required = {
    rules: [
      {
        required: true,
      },
    ],
  };

  return (
    <Modal
      title={props.menuId === "" ? "创建新菜单" : "更新菜单"}
      visible={props.visible}
      onOk={() => {
        props.onHide();
        form
          .validateFields()
          .then((values) => {
            dispatch(
              createMenu({
                ...values,
                id: props.menuId,
                parentId: props.parentId,
                category: MenuCategory.Menu,
                sort:
                  props.menuId && props.menuId !== ""
                    ? props.menuId
                    : getMenuMaxSort(menus),
              })
            );
          })
          .catch((_) => {
            // Do nothing.
          });
      }}
      onCancel={() => props.onHide()}
    >
      <Form
        form={form}
        fields={fields}
        onFieldsChange={(_, fs) => setFields(fs)}
      >
        <Form.Item {...params} name="parent" label="父菜单">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item {...params} name="name" label="名称" {...required}>
          <Input />
        </Form.Item>
        <Form.Item {...params} name="path" label="路径" {...required}>
          <Input />
        </Form.Item>
        <Form.Item {...params} name="icon" label="图标" {...required}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
