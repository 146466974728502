/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 16:35:24
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-20 11:11:19
 */

export const dateFormat = 'YYYY-MM-DD'

export const appointmentStatus = [
  // { value: 0, label: '预约中' },
  { value: 0, label: '待确认' },
  { value: 4, label: '已确认' },
  { value: 1, label: '已挂号' },
  { value: 2, label: '已就诊' },
  { value: 3, label: '已取消' },
  { value: -1, label: '流失' },
  { value: 9, label: '超时' },
]

export enum appointType {
  Phone = 1,
  weChart = 2,
  site = 3,
  other = 4,
  Aline = 5,
  Yyk = 6,
}

export const appointmentStatusOptions = [
  appointType.Aline,
  appointType.Phone,
  appointType.weChart,
  appointType.site,
  appointType.other,
  appointType.Yyk,
]

export function getAppointTypeName(type: appointType): string {
  switch (type) {
    case 1:
      return '电话预约'
    case 2:
      return '微信预约'
    case 3:
      return '现场预约'
    case 4:
      return '其他预约'
    case 5:
      return '在线预约'
    case 6:
      return '优医康'
    default:
      return '-'
  }
}

export function getAppointTypeType(type: number): string {
  switch (type) {
    case 0:
      return '待确认'
    case 4:
      return '已确认'
    case 1:
      return '已挂号'
    case 2:
      return '已就诊'
    case 3:
      return '已取消'
    case 9:
      return '超时'
    case -1:
      return '流失'
    default:
      return '-'
  }
}

export function getSVGSrc(type: number): string {
  switch (type) {
    case 0:
      return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/appointment/appointmenting.svg?versionId=CAEQPxiBgMDq35PdjhgiIDkwMjBkNjE2N2VkZDQ0ZWM4MjRhN2UxYThlNTgzYjdl'
    case 4:
      return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/checkSvg.svg'
    case 1:
      return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/appointment/registrationed.svg?versionId=CAEQPxiBgMDi35PdjhgiIDZhMGVkOGM0NmY2NjQ5MGNiMDE1YWI2YTEzMGJhYWQz'
    case 2:
      return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/appointment/outpatiented.svg?versionId=CAEQPxiBgIDm35PdjhgiIDg0ZjYzNGRiYzYxYjRmZDM5MzgxODAzMmViMWE1MmNh'
    case 3:
      return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/appointment/cancel.svg?versionId=CAEQPxiBgMC214jejhgiIDJkZTYxOWM0ZmMwNjQ1YzI4NmZmMmRmZTcwMWFkNGJi'
    case 9:
      return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/appointment/overtime.svg?versionId=CAEQPxiCgMDq35PdjhgiIDIyMTUzZWY3MDM3ZTQwOWI5NGNmMWVjOGNhZjVhZDEy'
    case -1:
      return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/appointment/runoff.svg?versionId=CAEQPxiBgMDl35PdjhgiIDBjNzk5NDVkMTM3MTRmNDg4M2RlNTZhZGFhNWNiMTVh'
    default:
      return 'https://hydhis-public.oss-cn-hangzhou.aliyuncs.com/hydhis/appointment/appointmenting.svg?versionId=CAEQPxiBgMDq35PdjhgiIDkwMjBkNjE2N2VkZDQ0ZWM4MjRhN2UxYThlNTgzYjdl'
  }
}

export function getBgc(type: number) {
  switch (type) {
    case 0:
      return '#fff0c5'
    case 1:
      return '#ffe0c2'
    case 2:
      return '#cde0ff'
    case 3:
      return '#e1e1e1'
    case 4:
      return '#b1f9e4'
    case 9:
      return '#ffd5d5'
    case -1:
      return '#f1e3ff'
    default:
      return '#fff0c5'
  }
}


export function getColor(type: number) {
  switch (type) {
    case 0:
      return '#f5cf00'
    case 1:
      return '#ee9900'
    case 2:
      return '#5091f8'
    case 3:
      return '#999999'
    case 4:
      return '#43c096'
    case 9:
      return '#ed3936'
    case -1:
      return '#af62fe'
    default:
      return '#f5cf00'
  }
}