import _ from "lodash";
import { Material } from "../models/material";

export function mergeGroup(
  materials: Material[],
  selectedIds: string[],
  onMerge?: (materials: Material[]) => void
): boolean {
  // 只选中了一个，则不能合并
  if (selectedIds.length <= 1) {
    return false;
  }
  const ms = materials.filter((m) => selectedIds.find((id) => id === m.id));
  // 选中的Id在列表中只搜到一个，不能合并
  if (ms.length <= 1) {
    return false;
  }
  const gids = _.chain(ms)
    .map((m) => m.groupNumber)
    .uniq()
    .value();
  // 选中到Id在同一个分组，不能合并
  if (gids.length <= 1) {
    return false;
  }
  // index一般会 >= 0
  const index = materials.findIndex((m) => m.id === ms[0].id);
  if (index < 0) {
    return false;
  }
  // 如果只是测试标记，则返回
  if (!onMerge) {
    return true;
  }

  const others = materials.filter(
    (m) => !selectedIds.find((id) => id === m.id)
  );

  const gid = ms[0].groupNumber;

  const sorted = _.chain(others)
    .take(index)
    .concat(ms.map((m) => ({ ...m, groupNumber: gid })))
    .concat(_.chain(others).drop(index).value())
    .map((m, i) => ({ ...m, sort: i }))
    .value();

  onMerge(fixGroupNumber(sorted));
  return true;
}

export function fixGroupNumber(sortedMatrials: Material[]): Material[] {
  const groups = _.chain(sortedMatrials)
      .groupBy((m) => m.groupNumber)
      .value();
  return _.chain(groups)
      .keys()
      .sortBy((m) =>Number(m))
      .map((k, i) => {
        return _.chain(groups[k])
            .map((m) => ({

              ...m,
              groupNumber: m.billingCategory === '97' ? m.groupNumber : i + 1,
            }))
            .value()
      })
      .flatten()
      .sortBy((m) => Number(m.sort))
      .value()
}

export function splitGroup(
  materials: Material[],
  selectedIds: string[],
  onSplit?: (materials: Material[]) => void
): boolean {
  // 只选中了一个，则不能拆分
  if (selectedIds.length <= 1) {
    return false;
  }
  const ms = materials
    .filter((m) => selectedIds.find((id) => id === m.id))
    .map((m) => ({ ...m }));
  // 选中的Id在列表中只搜到一个，不能拆分
  if (ms.length <= 1) {
    return false;
  }
  // 选中到Id在不同分组，不能拆分
  if (
    _.chain(ms)
      .map((m) => m.groupNumber)
      .uniq()
      .value().length === ms.length
  ) {
    return false;
  }
  // index一般会 >= 0
  const index = materials.findIndex((m) => m.id === ms[0].id);
  if (index < 0) {
    return false;
  }
  if (!onSplit) {
    return true;
  }

  const others = _.chain(materials).filter(
    (m) => !selectedIds.find((id) => id === m.id)
  );

  const gids = others.map((m) => m.groupNumber).value();

  let gid = 1;

  ms.forEach((m) => {
    while (gids.indexOf(gid) >= 0) ++gid;
    m.groupNumber = gid;
    gids.push(gid);
  });
  const updated = others
    .concat(ms)
    .sortBy((m) => m.groupNumber)
    .map((m, i) => ({ ...m, sort: i }))
    .value();
  onSplit(updated);
  return true;
}
