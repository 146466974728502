/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-23 09:32:02
 * @LastEditors: sj
 * @LastEditTime: 2022-09-08 09:27:43
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendAsync } from "../../app/applicationSlice";
import { RootThunkApi } from "../../app/store";

import * as S from "../../services/commonTriageDetail";
import { HistorylistParams } from "../../services/commonTriageDetail";

// 查询预检
export const getTriageHistorylist = createAsyncThunk<
  void ,
  HistorylistParams,
  RootThunkApi
  >("commonTriageDetail/getTriageHistorylist", async (params, api) => {
  return sendAsync(S.getHistorylist(params), api);
});

// 查询挂号
export const registrationPatientListAsync = createAsyncThunk<
  void,
  HistorylistParams,
  RootThunkApi
>("commonTriageDetail/registrationPatientListAsync", async (params, api) => {
  return sendAsync(S.registrationPatientList(params), api);
});