
import { PayMethod } from "./payment";
import { Gender } from "./user";
export enum orderSource {
 
Outpatient = 0, 
Drug  = 1,
}

export const StationRegistrations = [ 
   orderSource.Outpatient,
  orderSource.Drug,

];

export const StationOrderSourceNames = [
  "门诊开单",
  "药品零售",
];

export function getStationOrderSourceName(orderSource: orderSource): string {
  if (orderSource < 0 || orderSource >= StationOrderSourceNames.length) {
    return "";
  }
  return StationOrderSourceNames[orderSource];
}

// 收费流水枚举
export enum orderSources {
  Unknown = 0,
  Outpatient = 1, 
  Quicksale  = 2,
  Reg = 3
  }
export const StationRegistration = [ 
  orderSources.Outpatient,
 orderSources.Quicksale,
 orderSources.Reg,

];

export const StationOrderSourceName = [
 "门诊开单",
 "快速开单",
 "挂号"
];
export function getStationOrderSourceNames(orderSource: orderSources): string {
  switch (orderSource) {
    case  orderSources.Outpatient:
      return "门诊开单";
    case orderSources.Quicksale:
      return "快速开单";
    case orderSources.Reg:
      return "挂号";
  }
  return "";
}

  






export interface changeWater {
  id?: number;
  current?: number;
  pages?: number;
  size?: number;
  total?: number;
  medicareCode?: string;
  needPayAmount?: number;
  operateUser?: string;
  patientId?: number;
  patientName?: string;
  payMethod: PayMethod;
  realPayAmount?: number;
  receivePayAmount?: number;
  registrationCategory?: string;
  returnPayAmount?: number;
  settlementTypesOfInsurance?: number;
  shouldPayAmount?: number;
  state?: boolean;
  status?: number;
  tenantId?: number;
  treatmentId?: number;
  disease?:string;
  gender ?: Gender;
  realPay ?:string,
  operateUserName?:string,
  outpatientNo ?:string,
  orderSource ?: string,
  createTime?:string,
  
}

export function fromJson(json: any): changeWater {
  return {
    id: json.id?.toString() || "",
    current: json.current,
    pages: json.pages,
    size: json.size,
    total: json.total,
    medicareCode:json.medicareCode,
    needPayAmount: json.needPayAmount,
    operateUser: json.operateUser,
    patientId: json.patientId,
    patientName: json.patientName,
    payMethod: json.payMethod || PayMethod.Cash,
    realPayAmount: json.realPayAmount,
    receivePayAmount: json.receivePayAmount,
    registrationCategory: json.registrationCategory,
    returnPayAmount: json.returnPayAmount,
    settlementTypesOfInsurance: json.settlementTypesOfInsurance,
    shouldPayAmount: json.shouldPayAmount,
    state: json.state,
    status: json.status,
    tenantId: json.tenantId,
    treatmentId: json.treatmentId,
    disease:json.disease,
    gender: json.patientSex,
    realPay:json.realPay,
    operateUserName:json.operateUserName,
    outpatientNo :json.outpatientNo,
    orderSource :json.orderSource,
    createTime :json.createTime,
  };
}

export function toJson(changeWater: changeWater): any {
  return {
    id: changeWater.id,
    current: changeWater.current,
    pages: changeWater.pages,
    size: changeWater.size,
    total: changeWater.total,
    medicareCode:changeWater.medicareCode,
    operateUser: changeWater.operateUser,
    patientId: changeWater.patientId,
    patientName: changeWater.patientName,
    payMethod: changeWater.payMethod,
    realPayAmount: changeWater.realPayAmount,
    receivePayAmount: changeWater.receivePayAmount,
    registrationCategory: changeWater.registrationCategory,
    returnPayAmount: changeWater.returnPayAmount,
    settlementTypesOfInsurance: changeWater.settlementTypesOfInsurance,
    shouldPayAmount: changeWater.shouldPayAmount,
    state: changeWater.state,
    status: changeWater.status,
    tenantId: changeWater.tenantId,
    treatmentId: changeWater.treatmentId,
    disease:changeWater.disease,
    patientSex: changeWater.gender,
    realPay:changeWater.realPay,
    operateUserName:changeWater.operateUserName,
    outpatientNo :changeWater.outpatientNo,
    orderSource:changeWater.orderSource,
    createTime:changeWater.createTime
  };
}
