import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { api } from "../../app/applicationSlice";
import { RootState, RootThunk } from "../../app/store";
import { constructMenuLayers, Menu } from "../../models/menu";
import {
  createMenu as apiCreateMenu,
  getAvailableMenuList as apiGetMenuList,
  removeMenu as apiRemoveMenu,
  updateMenu,
} from "../../services/menu";

interface MenuState {
  menus: Menu[];
}

const initialState: MenuState = {
  menus: [],
};

const menuSlice = createSlice({
  name: "admin.menu",
  initialState,
  reducers: {
    setMenus: (state, action: PayloadAction<Menu[]>) => {
      state.menus = action.payload;
    },
  },
});

const { setMenus } = menuSlice.actions;

export function getMenuList(): RootThunk {
  return api(apiGetMenuList(), (data: any, dispatch) => {
    const menus = constructMenuLayers(data.records);
    dispatch(setMenus(menus));
  });
}

export function createMenu(menu: Menu): RootThunk {
  return api(
    menu.id === "" ? apiCreateMenu(menu) : updateMenu(menu),
    (_, dispatch) => {
      dispatch(getMenuList());
    }
  );
}

export function removeMenu(menu: Menu): RootThunk {
  return api(apiRemoveMenu(menu), (_, dispatch) => {
    dispatch(getMenuList());
  });
}

export function moveMenu(
  menu: Menu,
  parent: Menu | undefined,
  forward: boolean
): RootThunk {
  return (dispatch, getState) => {
    const siblings = parent?.subMenus || selectMenus(getState());
    let index = _.findIndex(siblings, (m) => m.id === menu.id);
    if (index < 0) {
      return;
    }
    if (forward) {
      ++index;
    } else {
      --index;
    }
    if (index < 0 || index >= siblings.length) {
      return;
    }
    const sibling = siblings[index];
    const sort =
      sibling.sort !== menu.sort
        ? sibling.sort
        : forward
        ? menu.sort + 1
        : menu.sort - 1;
    dispatch(createMenu({ ...menu, sort: sort }));
    dispatch(createMenu({ ...sibling, sort: menu.sort }));
  };
}

export const selectMenus = (state: RootState): Menu[] => state.menu.menus;

export default menuSlice.reducer;
