/* eslint-disable react/display-name */

import {
  AlipayCircleFilled,
  CreditCardFilled,
  DollarCircleFilled,
  SettingOutlined,
  WechatFilled,
} from '@ant-design/icons'

import { unwrapResult } from '@reduxjs/toolkit'

import {
  Button,
  Col,
  Divider,
  Input,
  message,
  Modal,
  notification,
  Form,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Checkbox,
  Tag,
} from 'antd'

import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useLocation } from 'react-router'

import Decimal from 'decimal.js'

import { RootDispatch } from '../../../app/store'

import { NavBar } from '../../../compnents/nav/NavBar'

import { Label } from '../../../compnents/widgets/Label'

import { Breadcrumb, traceRoute } from '../../../layouts/layoutSlice'

import {
  getIconImg,
  getPayMethodName,
  PayMethod,
} from '../../../models/payment'

import { ThemeContext } from '../../../theme/ThemeContext'

import { useQuery } from '../../../utils/HttpUtils'

import styles from './PaymentConfirm.module.css'

import {
  selectDrugList,
  getDrugList,
  submitSupplementary,
  getPaymentWayList,
} from '../confirm/paymentConfirmSlice'

import { MembersIcon } from '../../../compnents/icons/Icons'

import { TopUpModal } from '../../membersManagement/topUpModal'

import { MembersSettlementModal } from './membersSettlementModal'

import { AddMembersModal } from '../../membersManagement/addMembersModal'

import {
  getAddMembers,
  getMembersTopUp,
  getSearchMembers,
} from '../../membersManagement/MembersManagementPageSlice'

import { SearchMembersModal } from '../../membersManagement/searchMembersModal'

import { useHistory } from 'react-router-dom'

import { setActiveObj } from '../../A-toothModule/patient/patientSlice'

import { ChargeSettingModal } from './ChargeSettingModal'

import { sum } from 'lodash'
import { GlobalKeyboardAction } from '../../../compnents/actions/GlobalKeyboardAction'

const { Text } = Typography

export interface PaymentConfirmRef {
  reset: () => void

  submit: () => void
}

const OweMoneySettlement = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const location = useLocation<{
    where: [string, string]

    backtrace: Breadcrumb

    routerLink: boolean

    prePay: any

    treatment: any

    settlementCategory?: any
  }>()

  const query = useQuery()

  const history = useHistory()

  const paymentId = query.get('paymentId') as string

  const toothPatient = query.get('toothPatient')

  const status = query.get('status') as string

  const receivedAmountRef = useRef<any>(null)

  const discountRef = useRef<any>(null)

  const drugList = useSelector(selectDrugList) //获取药品区域信息

  const [amount, setAmount] = useState('') //实收金额

  const [selectZuHeOrNoZuHeList, setSelectZuHeOrNoZuHeList] = useState<any>()

  const [loading, setLoading] = useState(false) //补交按钮加载

  const [receivedAmount, setReceivedAmount] = useState('') //本次收费

  const [systemPayMethod, setSystemPayMethod] = useState<any[]>([])

  const [
    isChargeSettingModalVisible,

    setIsChargeSettingModalVisible,
  ] = useState(false)

  const [combinationChargeFlag, setCombinationChargeFlag] = useState(false)

  const [discount, setDiscount] = useState('0.00') //找零

  const [oweMoneNum, setOweMoneNum] = useState('0.00') //欠费

  const [form] = Form.useForm()

  const { CheckableTag } = Tag

  const [payMethod, setPayMethod] = useState<any[]>([PayMethod.Cash])

  //支付方式

  const [membersInfo, setMembersInfo] = useState<any>() //会员信息弹窗

  const [searchMembersModalVisible, setSearchMembersModalVisible] = useState(
    false
  ) //搜索会员弹窗

  const [addMembersVisible, setAddMembersVisible] = useState(false) // 新增会员弹窗

  const [addBtnType, setAddBtnType] = useState(false) // 新增会员弹窗

  const [membersSettlementModal, setMembersSettlementModal] = useState(false) // 会员结算弹窗

  const [topUpModalVisible, setTopUpModalVisible] = useState(false) // 充值 or 退款 弹窗

  const [paymentWayList, setPaymentWayList] = useState<any>()

  const [allPaymentWayList, setAllPaymentWayList] = useState<any>()

  const [zHSFAllMoney, setZHSFAllMoney] = useState<any>()

  const inputRef = useRef<Input>(null)

  const inputRefs = selectZuHeOrNoZuHeList?.map(() => React.createRef())

  const payList = [
    {
      icon: DollarCircleFilled,

      color: '#69A6FD',

      method: PayMethod.Cash,

      type: 1,
    },

    {
      icon: WechatFilled,

      color: '#00BC00',

      method: PayMethod.Weipay,

      type: 1,
    },

    {
      icon: AlipayCircleFilled,

      color: '#00A9F2',

      method: PayMethod.Alipay,

      type: 1,
    },

    {
      icon: CreditCardFilled,

      color: '#107C84',

      method: PayMethod.UnionPay,

      type: 1,
    },
  ]

  useEffect(() => {
    dispatch(getDrugList(paymentId))
  }, [paymentId])

  useEffect(() => {
    setReceivedAmount(Number(drugList?.payment?.arrearageAmount)?.toFixed(2))

    const payValue = Number(drugList?.payment?.arrearageAmount)?.toFixed(2)

    if (combinationChargeFlag) {
      if (selectZuHeOrNoZuHeList?.length == 1) {
        setSystemPayMethod(
          selectZuHeOrNoZuHeList?.map((v: any) => {
            return {
              payAmount: Number(payValue)?.toFixed(2),

              payMethod: v?.method,
            }
          })
        )

        // 默认进来开通组合支付将第一个设置为应收现金 其余为0.00元
      } else if (
        selectZuHeOrNoZuHeList?.length == 2 ||
        selectZuHeOrNoZuHeList?.length == 3
      ) {
        setSystemPayMethod(
          selectZuHeOrNoZuHeList?.map((v: any, index: any) => {
            return {
              payAmount: index == 0 ? Number(payValue)?.toFixed(2) : '0.00',

              payMethod: v?.method,
            }
          })
        )
      }
    }

    // setOweMoneNum(drugList?.payment?.arrearageAmount?.toFixed(2))
  }, [drugList, combinationChargeFlag])

  useEffect(() => {
    if (toothPatient == '1') {
      toothPatient &&
        dispatch(
          setActiveObj({
            tabsActiveKey: '3',

            rightTabsActiveKey: '4',
          })
        )
    } else if (toothPatient == '2') {
      sessionStorage.setItem('routerStatus', 'feeNum')
    }
  }, [toothPatient])

  useEffect(() => {
    const money = combinationChargeFlag ? zHSFAllMoney : receivedAmount

    const num = Number(money) - Number(drugList?.payment?.arrearageAmount)

    if (num > 0) {
      //找零

      setDiscount(num.toFixed(2))

      setOweMoneNum('0.00')
    } else if (num < 0) {
      //欠费

      setDiscount('0.00')

      setOweMoneNum(Math.abs(num).toFixed(2))
    } else if (num == 0) {
      //刚好

      setDiscount('0.00')

      setOweMoneNum('0.00')
    }
  }, [receivedAmount, drugList, combinationChargeFlag, zHSFAllMoney])

  const reCalcPrice = (editIndex: any) => {
    const hasXj = systemPayMethod.find((v) => v.payMethod == '1')

    const nextInput = editIndex + 1 >= inputRefs.length ? 0 : editIndex + 1

    const currentPayAmount = new Decimal(
      inputRefs?.[editIndex]?.current.input.value || 0
    )

    const yingshouAmount = new Decimal(receivedAmount)

    const shengyuAmount = yingshouAmount.minus(currentPayAmount)

    let pageTotalAmount = new Decimal(0) //页面合计的收款金额

    inputRefs.forEach((ref: any, index: any) => {
      //遍历付款金额并计算页面合计的收款金额（不用zHSFAllMoney是因为此时还没有触发effect）

      pageTotalAmount = pageTotalAmount.plus(ref.current.input.value || 0)
    })

    const currentPayMethod = systemPayMethod?.[editIndex]?.payMethod

    const paymethods: any = []

    if ('1' == currentPayMethod) {
      const comparisonResult = currentPayAmount.comparedTo(yingshouAmount)

      //如果当前输入框是现金，则在金额超出应收金额的时候，把另外两个输入框都设置为0

      if (comparisonResult >= 0) {
        inputRefs.forEach((ref: any, index: any) => {
          if (index != editIndex) {
            ref.current.input.value = 0
          }
        })
      }

      inputRefs.forEach((ref: any, index: any) => {
        paymethods.push({
          payAmount: ref.current.input.value,

          payMethod: systemPayMethod?.[index]?.payMethod,
        })
      })

      setSystemPayMethod(paymethods)

      return
    }

    //如果当前修改的金额不是现金，则在大于等于应收金额时，将另一个清空，将当前输入框设置为应收金额

    if (
      '1' != currentPayMethod &&
      currentPayAmount.comparedTo(yingshouAmount) > 0
    ) {
      inputRefs.forEach((ref: any, index: any) => {
        if (index == editIndex) {
          inputRefs[index].current.input.value = yingshouAmount
        } else {
          inputRefs[index].current.input.value = 0
        }
      })

      notification.error({
        message: '收款金额不能大于应收金额',

        duration: 2,
      })

      inputRefs.forEach((ref: any, index: any) => {
        paymethods.push({
          payAmount: ref.current.input.value,

          payMethod: systemPayMethod?.[index]?.payMethod,
        })
      })

      setSystemPayMethod(paymethods)

      return

      //在小于等于应收现金时，判断另一个输入框是不是现金
    }

    //2种付款方式时

    if (inputRefs.length == 2) {
      //如果当前的输入框是现金

      if ('1' == currentPayMethod) {
        //如果当前修改的金额是现金，则在小于等于应收金额时，自动计算第二个输入框为剩余金额，大于应收金额时，把另外一个清空

        if (currentPayAmount.comparedTo(yingshouAmount) <= 0) {
          inputRefs[1].current.input.value = yingshouAmount.minus(
            currentPayAmount
          )
        } else {
          inputRefs[1].current.input.value = 0
        }
      } else {
        //如果另一个输入框不是现金，则当另一个输入框不是现金的时候，自动计算另一个输入框的金额为应收金额 - 当前输入框的金额

        if (!hasXj) {
          inputRefs[nextInput].current.input.value = shengyuAmount
        }
      }
    }

    //3种付款方式时

    if (inputRefs.length == 3) {
      const firstAmount = new Decimal(inputRefs[0].current.input.value || 0)

      const secondAmount = new Decimal(inputRefs[1].current.input.value || 0)

      const thirdAmount = new Decimal(inputRefs[2].current.input.value || 0)

      //如果当前在编辑第一个输入框，且不是现金的情况下

      if (editIndex == 0) {
        //如果当前输入框不是现金（第一个框不是现金的情况下，即没有现金）

        //如果第一个+第三个的钱 小于应收，则把第二个设置为 应收 - 第一个 - 第三个

        if (firstAmount.plus(thirdAmount).comparedTo(yingshouAmount) <= 0) {
          inputRefs[1].current.input.value = yingshouAmount

            .minus(firstAmount)

            .minus(thirdAmount)
        } else {
          //否则就把第二个设置为0，把第三个设置为应收 - 第一个

          inputRefs[1].current.input.value = 0

          inputRefs[2].current.input.value = yingshouAmount.minus(firstAmount)
        }

        //如果当前在编辑第二个输入框，则在第一个金额+第二个金额小于等于应收金额的时候，修改第三个金额，
      } else if (editIndex == 1) {
        if (firstAmount.plus(secondAmount).comparedTo(yingshouAmount) <= 0) {
          inputRefs[2].current.input.value = yingshouAmount.minus(
            firstAmount.plus(secondAmount)
          )
        } else {
          //在第一个金额+第二个金额大于应收金额的时候，如果没现金，则把第三个金额改为0，第一个金额改为应收金额 - 第二个金额

          inputRefs[2].current.input.value = 0

          if (!hasXj) {
            inputRefs[0].current.input.value = yingshouAmount.minus(
              secondAmount
            )
          }
        }

        //如果当前在编辑第三个输入框
      } else if (editIndex == 2) {
        //如果总额全部相加小于等于应收金额，则不做处理

        //如果总额全部相加大于应收金额，则只处理第二个金额 = 应收金额 - 第一个金额 + 第三个金额，当结果小于0时，也设置为0（即找零），

        if (pageTotalAmount.comparedTo(yingshouAmount) > 0) {
          const restAmount = pageTotalAmount.minus(yingshouAmount)

          if (hasXj) {
            const chaochuAmount = firstAmount.minus(restAmount)

            //有现金 并且超出部分小于现金时，不做处理，否则 在第二个金额上减去超出部分

            if (chaochuAmount.comparedTo(new Decimal(0)) < 0) {
              inputRefs[1].current.input.value = secondAmount.plus(
                chaochuAmount
              )
            }
          } else {
            //无现金，并且超出部分小于第二个金额时，在第二个金额上做扣减，否则，多出部分在第一个金额上做扣减

            if (secondAmount.comparedTo(restAmount) >= 0) {
              inputRefs[1].current.input.value = secondAmount.minus(restAmount)
            } else {
              inputRefs[0].current.input.value = firstAmount.plus(
                secondAmount.minus(restAmount)
              )

              inputRefs[1].current.input.value = 0
            }
          }
        }
      }
    }

    inputRefs.forEach((ref: any, index: any) => {
      paymethods.push({
        payAmount: ref.current.input.value,

        payMethod: systemPayMethod?.[index]?.payMethod,
      })
    })

    setSystemPayMethod(paymethods)
  }

  useEffect(() => {
    const flag: any = localStorage.getItem('combinationChargeFlag')

    setCombinationChargeFlag(flag == 'true' ? true : false)
  }, [])

  // 是否是会员

  const getIsMembers = (data: number) => {
    dispatch(getSearchMembers({ memberPhone: data }))
      .then(unwrapResult)

      .then((res: any) => {
        if (!Object.keys(res).length) {
          setSearchMembersModalVisible(true)
        }

        setMembersInfo(Object.keys(res).length ? res : undefined)

        setTopUpModalVisible(false)
      })
  }

  const handleChange = (tag: any, checked: boolean) => {
    const nextSelectedTags = checked
      ? combinationChargeFlag
        ? payMethod
          ? [...payMethod, tag]
          : [tag]
        : [tag]
      : combinationChargeFlag
      ? payMethod?.filter((t: any) => t !== tag)
      : []

    if (nextSelectedTags?.length > 3) {
      notification.info({
        message: '组合收费支持最多选中3个',
      })

      setPayMethod(nextSelectedTags?.slice(0, 3))
    } else {
      setPayMethod(nextSelectedTags)
    }
  }

  // 组合收费

  const getSFFSList = () => {
    dispatch(getPaymentWayList())
      .then(unwrapResult)

      .then((v: any) => {
        const data = v?.records?.filter((v: any) => v?.status == 1)

        setPaymentWayList(data)

        const list: any = []

        const allList = list.concat(
          payList?.map((c: any) => {
            return { ...c, methodName: getPayMethodName(c.method) }
          }),

          data?.map((item: any) => {
            return {
              ...item,

              icon: getIconImg(item?.methodIcon),

              method: item?.id,
            }
          })
        )

        // 所有的支付方式

        setAllPaymentWayList(allList)
      })
  }

  useEffect(() => {
    getSFFSList()
  }, [])

  useEffect(() => {
    // 没有开启组合收费的收费以及欠费等的金额

    if (!combinationChargeFlag) {
      const value = receivedAmount

      if (value)
        setSystemPayMethod(
          selectZuHeOrNoZuHeList?.map((v: any) => {
            return {
              payAmount: value,

              payMethod: v?.method,
            }
          })
        )

      setReceivedAmount(value)
    } else {
      // 开启组合收费的收费以及欠费等的金额

      const value = receivedAmount

      if (value) {
        const value = receivedAmount

        setSystemPayMethod(
          selectZuHeOrNoZuHeList?.map((v: any, index: any) => {
            return {
              payAmount: index == 0 ? value : '0.00',

              payMethod: v?.method,
            }
          })
        )
      }
    }
  }, [combinationChargeFlag, selectZuHeOrNoZuHeList, receivedAmount])

  useEffect(() => {
    // 开启组合收费的所有值的总合

    if (combinationChargeFlag) {
      const addZuHePayMoney: any = sum(
        systemPayMethod?.map((a: any) => Number(a?.payAmount))
      )

      setZHSFAllMoney(Number(addZuHePayMoney)?.toFixed(2))
    }
  }, [systemPayMethod, combinationChargeFlag])

  // 排序

  const customSort = (a: any, b: any) => {
    const aIndex = payMethod.indexOf(a.method)

    const bIndex = payMethod.indexOf(b.method)

    return aIndex - bIndex
  }

  useEffect(() => {
    // 组合支付选中的列表

    const selectZuHeList = allPaymentWayList?.filter((v: any) => {
      return payMethod?.some((i: any) => i == v?.method)
    })

    // 使用排序函数对数组进行排序

    const sortedData = selectZuHeList?.sort(customSort)

    setSelectZuHeOrNoZuHeList(selectZuHeList)
  }, [allPaymentWayList, payMethod])

  const confirmButton = () => {
    if (!payMethod?.length) {
      notification.error({
        message: '请至少选择一种收费方式',
      })
    } else {
      if (
        membersInfo &&
        Number(membersInfo?.balanceMoney) < Number(receivedAmount) &&
        payMethod?.find((v) => v == 6)
      ) {
        setMembersSettlementModal(true)

        return
      }
      setLoading(true)
      const money = combinationChargeFlag
        ? Number(zHSFAllMoney)
        : Number(receivedAmount)
      const params = {
        money: Number(money),
        payMethod: payMethod?.join(','),
        paymentId,
        memberId: membersInfo?.id,

        systemPayMethod: !systemPayMethod.length
          ? [
              {
                payAmount: '0.00',
                payMethod: 1,
              },
            ]
          : systemPayMethod,
      }

      dispatch(submitSupplementary(params))
        .then(unwrapResult)
        .then((res) => {
          setLoading(false)
          notification.success({ message: '补交成功' })
          toothPatient
            ? history.push({
                pathname: toothPatient == '1' ? '/toothPatient' : '/toothHome',
              })
            : status == '8' ? 
              dispatch(
                traceRoute({
                  name: '收费',
                  path: '/payment/result',
                  query: `paymentId=${paymentId}&&status=${status}`,
                  state: {
                    name: '欠费管理',
                    path: '/payment',
                    routeName: '欠费管理',
                  },
                })
              )
            : history.push({
                pathname: '/payment',
                state: {
                  station: '5',
                },
              })
          // dispatch(
          //   traceRoute({
          //     name: '欠费管理',
          //     path: '/payment',
          //     state: {
          //       station: '5'
          //     },
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  return (
    <div className={styles.prePay}>
      <GlobalKeyboardAction
        onSpacePress={() => {
          confirmButton()
        }}
      />
      <Col
        style={{
          height: 'calc(100%)',
          padding: '10px 20px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.bg,
          overflow: 'hidden',
        }}
      >
        {toothPatient ? (
          <NavBar
            where={['收费业务', '结算明细']}
            backtrace={{
              name: toothPatient == '1' ? '患者管理' : '首页',
              path: toothPatient == '1' ? '/toothPatient' : '/toothHome',
            }}
          />
        ) : (
          <NavBar
            where={['收费业务', '结算明细']}
            backtrace={{
              name: '欠费管理',
              path: '/payment',
              state: {
                station: '5',
              },
            }}
          />
        )}
        <Row
          wrap={false}
          style={{
            flex: 1,
            marginTop: location.state ? 10 : 0,
            overflow: 'hidden',
          }}
        >
          <Col
            style={{
              width: '19.5rem',
              borderRadius: theme.br,
              backgroundColor: theme.pn,
              overflow: 'auto',
            }}
          >
            <div className={styles.print}>
              <Label label='药品区域' style={{ marginLeft: 10 }} />
            </div>
            <Divider style={{ borderColor: theme.c3, margin: 0 }} />
            <div
              style={{
                margin: '15px 20px 8px 20px',
                fontSize: '1rem',
                color: theme.tc2,
              }}
            >
              {drugList?.patient} 本次费用明细如下：
            </div>

            {drugList?.slots?.map((s, i) => (
              <Table
                style={{
                  margin: '5px 20px',

                  border: `1px solid ${theme.wbd}`,
                }}
                key={i}
                dataSource={s.children}
                columns={[
                  {
                    title: (
                      <span style={{ color: theme.tc1, fontSize: '1rem' }}>
                        {s.name}
                      </span>
                    ),

                    dataIndex: 'name',

                    render: (_, r: any) => ({
                      props: {
                        style: {
                          color: theme.tc2,

                          fontSize: '0.875rem',
                        },
                      },

                      children: (
                        <>
                          <div>{r.name}</div>

                          {location.state?.prePay ? (
                            r.payType == 0 ? (
                              <div>(强制自费)</div>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                        </>
                      ),
                    }),
                  },

                  {
                    title: (
                      <span style={{ color: theme.tc1, fontSize: '1rem' }}>
                        ¥{s.value?.toFixed(2)}
                      </span>
                    ),

                    align: 'right',

                    dataIndex: 'value',

                    render: (_, r) => ({
                      proprs: {
                        style: {
                          color: theme.tc2,

                          fontSize: '0.875rem',
                        },
                      },

                      children: `¥${r.value?.toFixed(2)}`,
                    }),
                  },
                ]}
                pagination={false}
                size='small'
              />
            ))}
          </Col>

          <Col
            flex={1}
            style={{
              color: theme.tc1,

              fontSize: '1rem',

              paddingBottom: 30,

              borderRadius: theme.br,

              backgroundColor: theme.pn,

              marginLeft: 20,

              overflow: 'auto',
            }}
          >
            <Label label='收银结算' style={{ marginLeft: 10 }} />

            <Divider style={{ borderColor: theme.c3, margin: 0 }} />

            <Row
              style={{
                marginTop: location.state && !location.state.prePay ? 80 : 20,
              }}
              align='bottom'
              wrap={false}
            >
              <Col flex='7rem' style={{ textAlign: 'right' }}>
                应收现金：
              </Col>

              <Col
                style={{
                  color: theme.e,

                  fontSize: '1.25rem',

                  fontWeight: 'bold',

                  marginLeft: 10,
                }}
              >
                {Number(drugList?.payment?.shouldPayAmount)?.toFixed(2)}
              </Col>
            </Row>

            <Row align='middle' style={{ marginTop: 30 }}>
              <Col flex='7rem' style={{ textAlign: 'right' }}>
                实收金额：
              </Col>

              <Col>
                <Input
                  autoFocus
                  style={{
                    width: '18rem',

                    marginLeft: 10,

                    fontSize: '1rem',

                    color: theme.tc1,
                  }}
                  disabled
                  inputMode='decimal'
                  value={Number(drugList?.payment?.realPayAmount)?.toFixed(2)}

                  // onChange={(e) => {

                  //   const { value } = e.target

                  //   const reg = /^\d*(\.\d*)?$/

                  //   if (reg.test(value) || value === '') {

                  //     setAmount(value)

                  //   }

                  // }}
                />
              </Col>
            </Row>

            <Row align='middle' style={{ marginTop: 30 }}>
              <Col flex='7rem' style={{ textAlign: 'right' }}>
                收费方式：
              </Col>

              <Col
                style={{ marginLeft: 10, flex: 1 }}
                className={styles.paymentWay}
              >
                <Space size='large' wrap>
                  {payList?.map(({ icon, color, method, type }) => {
                    const Icon = icon

                    return (
                      <Tooltip key={method} overlay={getPayMethodName(method)}>
                        <CheckableTag
                          style={{
                            width: 34,

                            height: 34,

                            border: '1px solid #d2d2d2',

                            display: 'flex',

                            alignItems: 'center',

                            justifyContent: 'center',
                          }}
                          key={method}
                          checked={payMethod?.find((v) => v == method)}
                          onChange={(checked: any) =>
                            handleChange(method, checked)
                          }
                        >
                          <div
                            className={
                              payMethod?.find((v) => v == method)
                                ? styles.selectIcon
                                : ''
                            }
                          >
                            <Icon
                              style={{
                                fontSize: '1.5rem',

                                color,

                                display: type == 1 ? 'block' : 'none',

                                borderRadius: 2,

                                padding: '4px',
                              }}
                            />

                            <div
                              onClick={() => {
                                if (!membersInfo) {
                                  //是否有会员

                                  if (payMethod?.find((v) => v == method)) {
                                    setSearchMembersModalVisible(false)
                                  } else {
                                    setSearchMembersModalVisible(true)
                                  }
                                }

                                setPayMethod([method])
                              }}
                            >
                              <MembersIcon
                                mode='small'
                                style={{
                                  display: type == 2 ? 'block' : 'none',

                                  borderRadius: 2,

                                  padding: '4px',

                                  width: '35px',

                                  height: '35px',

                                  fontSize: '30px',

                                  cursor: 'pointer',
                                }}
                              />
                            </div>
                          </div>
                        </CheckableTag>
                      </Tooltip>
                    )
                  })}

                  {paymentWayList?.map((v: any) => {
                    const icon = getIconImg(v?.methodIcon)

                    return (
                      <Tooltip key={v?.id} overlay={v?.methodName}>
                        <div
                          className={
                            payMethod?.find((k) => k == v?.id)
                              ? styles.selectIcon
                              : ''
                          }
                        >
                          <CheckableTag
                            style={{
                              width: 34,

                              height: 34,

                              border: '1px solid #d2d2d2',

                              display: 'flex',

                              alignItems: 'center',

                              justifyContent: 'center',
                            }}
                            key={v?.id}
                            checked={payMethod?.find((k) => k == v?.id)}
                            onChange={(checked: any) =>
                              handleChange(v?.id, checked)
                            }
                          >
                            <div
                              onClick={() => {
                                setPayMethod(v?.id)
                              }}
                            >
                              <img
                                src={icon}
                                style={{
                                  borderRadius: 2,

                                  padding: '4px',

                                  width: '32px',

                                  height: '32px',

                                  fontSize: '30px',

                                  cursor: 'pointer',
                                }}
                              />
                            </div>
                          </CheckableTag>
                        </div>
                      </Tooltip>
                    )
                  })}

                  <Checkbox
                    checked={combinationChargeFlag}
                    onChange={(e: any) => {
                      setPayMethod([PayMethod.Cash])

                      setCombinationChargeFlag(e.target.checked)

                      localStorage.setItem(
                        'combinationChargeFlag',

                        e.target.checked
                      )

                      setReceivedAmount(receivedAmount)
                    }}
                    style={{ marginLeft: 50 }}
                  >
                    组合收费
                  </Checkbox>

                  <div
                    style={{
                      display: 'flex',

                      alignItems: 'center',
                    }}
                  >
                    <SettingOutlined
                      style={{
                        fontSize: '18px',

                        color: '#027AFF',
                      }}
                    />

                    <span
                      style={{
                        marginLeft: '6px',

                        fontSize: '14px',

                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setIsChargeSettingModalVisible(true)
                      }}
                    >
                      收费设置
                    </span>
                  </div>
                </Space>
              </Col>
            </Row>

            {!combinationChargeFlag ? (
              <Row align='middle' style={{ textAlign: 'right', marginTop: 30 }}>
                <Col flex='7rem'>{'本次收费：'}</Col>

                <Col>
                  <Input
                    style={{
                      width: '18rem',

                      marginLeft: 10,

                      fontSize: '1rem',

                      color: theme.tc1,
                    }}
                    ref={inputRef}
                    onClick={() => {
                      inputRef?.current?.select() // 选择文本
                    }}
                    inputMode='decimal'
                    value={receivedAmount}
                    onChange={(e) => {
                      const { value } = e.target

                      const reg = /^\d*(\.\d*)?$/

                      if (reg.test(value) || value === '') {
                        setReceivedAmount(value)
                      }
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <>
                {selectZuHeOrNoZuHeList?.map((k: any, index: any) => {
                  return (
                    <>
                      <Row
                        align='middle'
                        style={{ textAlign: 'right', marginTop: 30 }}
                      >
                        <Col flex='7rem' style={{ marginLeft: '-10px' }}>
                          {k?.methodName + ' : '}
                        </Col>

                        <Col style={{ marginLeft: '10px' }}>
                          <Input
                            style={{
                              width: '18rem',

                              marginLeft: 10,

                              fontSize: '1rem',

                              color: theme.tc1,
                            }}
                            maxLength={12}
                            ref={inputRefs[index]} // 分配 inputRef 给 Input
                            onClick={() => {
                              systemPayMethod?.[index]?.payAmount &&
                                inputRefs[index].current?.select() // 选择文本
                            }}
                            inputMode='decimal'
                            value={systemPayMethod

                              ?.find((n) => n?.payMethod == k?.method)

                              ?.payAmount?.toString()}
                            defaultValue={'0.00'}
                            onChange={(e) => {
                              const { value } = e.target

                              // 使用正则表达式验证输入值

                              const regex = /^\d+(\.\d{0,2})?$/

                              if (regex.test(value) || value === '') {
                                const updatedValues: any = systemPayMethod
                                  ? [...systemPayMethod]
                                  : []

                                updatedValues[index] = {
                                  payAmount: Number(value).toFixed(2),

                                  payMethod: k?.method,
                                }

                                setSystemPayMethod(updatedValues)

                                reCalcPrice(index)
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                })}
              </>
            )}

            <Row ref={discountRef} align='bottom' style={{ marginTop: 30 }}>
              <Col flex='7rem' style={{ textAlign: 'right' }}>
                {'找　　零：'}
              </Col>

              <Col
                style={{
                  color: theme.e,

                  fontSize: '1.25rem',

                  fontWeight: 'bold',

                  marginLeft: 10,
                }}
              >
                {discount}
              </Col>

              <Col flex='7rem' style={{ textAlign: 'right' }}>
                {'欠费：'}
              </Col>

              <Col
                style={{
                  color: theme.e,

                  fontSize: '1.25rem',

                  fontWeight: 'bold',

                  marginLeft: 10,
                }}
              >
                {oweMoneNum}
              </Col>
            </Row>

            <Row style={{ marginTop: 100, paddingLeft: 156 }}>
              <Space>
                <Button
                  onClick={() => {
                    toothPatient
                      ? dispatch(
                          traceRoute({
                            name: toothPatient == '1' ? '患者管理' : '首页',

                            path:
                              toothPatient == '1'
                                ? '/toothPatient'
                                : '/toothHome',
                          })
                        )
                      : status == '8' ?
                        dispatch(
                          traceRoute({
                            name: '收费',
                            path: '/payment/result',
                            query: `paymentId=${paymentId}&&status=${status}`,
                            state: {
                              name: '欠费管理',
                              path: '/payment',
                              routeName: '欠费管理',
                            },
                          })
                        )
                      : dispatch(
                          traceRoute({
                            name: '欠费管理',

                            path: '/payment',

                            state: {
                              station: '5',
                            },
                          })
                        )
                  }}
                >
                  取消
                </Button>

                <Button
                  type='primary'
                  loading={loading}
                  onClick={() => {
                    confirmButton()
                  }}
                >
                  补交（空格）
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Col>
 
      {membersInfo && (
        <div className={styles.membersInfoWrap}>
          <span style={{ fontWeight: 'bold' }}>会员信息</span>

          <Row style={{ marginTop: 10 }}>
            <Col span={10} style={{ textAlign: 'right' }}>
              手机号：
            </Col>

            <Col span={12} style={{ textAlign: 'left' }}>
              {membersInfo?.memberPhone}
            </Col>
          </Row>

          <Row style={{ margin: '10px 0' }}>
            <Col span={10} style={{ textAlign: 'right' }}>
              姓名：
            </Col>

            <Col span={12} style={{ textAlign: 'left' }}>
              {membersInfo?.memberName}
            </Col>
          </Row>

          <Row>
            <Col span={10} style={{ textAlign: 'right' }}>
              账户余额：
            </Col>

            <Col span={12} style={{ textAlign: 'left' }}>
              {membersInfo?.balanceMoney?.toFixed(2)}
            </Col>
          </Row>

          <div style={{ marginTop: 20 }}>
            <Button
              size='middle'
              type='primary'
              onClick={() => {
                setMembersInfo(undefined)

                setPayMethod([PayMethod.Cash])
              }}
            >
              登出当前会员
            </Button>
          </div>
        </div>
      )}

      <SearchMembersModal
        visible={searchMembersModalVisible}
        onCancel={() => {
          if (combinationChargeFlag) {
            setPayMethod(payMethod?.filter((v) => v != 6))
          } else {
            setPayMethod([PayMethod.Cash])
          }

          setSearchMembersModalVisible(false)
        }}
        onOk={(data) => {
          setMembersInfo(data)

          setSearchMembersModalVisible(false)
        }}
        openFn={() => {
          setAddMembersVisible(true)

          setSearchMembersModalVisible(false)
        }}
      />

      <AddMembersModal
        visible={addMembersVisible}
        btnType={addBtnType}
        onCancel={() => setAddMembersVisible(false)}
        onOk={(data) => {
          dispatch(getAddMembers(data))
            .then(unwrapResult)

            .finally(() => {
              setAddBtnType(!addBtnType)

              setAddMembersVisible(false)

              getIsMembers(Number(data.memberPhone))
            })
        }}
      />

      <MembersSettlementModal
        visible={membersSettlementModal}
        membersInfo={membersInfo}
        onCancel={() => setMembersSettlementModal(false)}
        onOk={() => {
          setMembersSettlementModal(false)

          setTopUpModalVisible(true)
        }}
      />

      <TopUpModal // 0.充值 or 1.退款
        visible={topUpModalVisible}
        btnType={addBtnType}
        membersId={membersInfo?.id}
        modalText={0}
        onCancel={() => setTopUpModalVisible(false)}
        onOk={(data) => {
          dispatch(getMembersTopUp(data))
            .then(unwrapResult)

            .then(() => {
              getIsMembers(Number(membersInfo.memberPhone))

              notification.success({ message: '充值成功' })
            })

            .finally(() => setAddBtnType(!addBtnType))
        }}
      />

      <ChargeSettingModal
        visible={isChargeSettingModalVisible}
        onCancel={() => {
          setIsChargeSettingModalVisible(false)

          getSFFSList()
        }}
        onOk={() => {
          setIsChargeSettingModalVisible(false)

          getSFFSList()

          setSystemPayMethod(
            selectZuHeOrNoZuHeList?.map((v: any, index: any) => {
              return {
                payAmount:
                  index == 0 ? Number(receivedAmount)?.toFixed(2) : '0.00',

                payMethod: v?.method,
              }
            })
          )
        }}
      />
    </div>
  )
}

export const OweMoneySettlementPage = forwardRef(OweMoneySettlement)
