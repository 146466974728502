/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-17 11:18:17
 * @LastEditors: linxi
 * @LastEditTime: 2021-11-17 11:22:34
 */

import { appealParams, standListParams } from "../models/standbook"

import { API_PREFIX } from "./constant"
import { Request } from "./request"
//    警报数量
export const getStandCount = (): Request => {
  return {
    url: `${API_PREFIX}/blade-precheck/caution/count`,
    method: "GET",
  }
}
//   警报列表
export const getStandList = (params: standListParams): Request => {
  return {
    url: `${API_PREFIX}/blade-precheck/caution/list`,
    method: "GET",
    params,
  }
}
//   警报申诉
export const appealSave = (body: appealParams): Request => {
  return {
    url: `${API_PREFIX}/blade-precheck/caution/appeal`,
    method: "POST",
    body,
  }
}
//   警报详情
export const getStandDetail = (cautionId: string): Request => {
  return {
    url: `${API_PREFIX}/blade-precheck/caution/detail`,
    method: "GET",
    params: {
      cautionId,
    },
  }
}
