/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-20 13:55:26
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-04 11:34:45
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

// 中草药帖均--医生中草药帖均
export interface averageTipParams {
  timeFlag?: string
  cbxz?: string
  yidibz?: number
  treatmentDepartmentId?: string
  tenantId?: string
  costFlag?: string
  startTime?:any
  endTime?:any
}
export function averageTip(params: averageTipParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/averageTip`,
    method: 'GET',
    params,
  }
}

// 中草药帖均--机构中草药帖均
export function averageTipByTenantId(params: averageTipParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/averageTipByTenantId`,
    method: 'GET',
    params,
  }
}

// 电子凭证扫码率
export function sweepRate(params: averageTipParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/sweepRate`,
    method: 'GET',
    params,
  }
}

// 医保费用-次均费用
export function patientTimesRate(params: averageTipParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/patientTimesRate`,
    method: 'GET',
    params,
  }
}

// 医保费用-人均费用
export function patientNumRate(params: averageTipParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/patientNumRate`,
    method: 'GET',
    params,
  }
}

// 医保费用-人次人头比
export function patientRate(params: averageTipParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/patientRate`,
    method: 'GET',
    params,
  }
}

// 医保费用-就诊人数
export function patientNum(params: averageTipParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/patientNum`,
    method: 'GET',
    params,
  }
}

// 医保支付限额-医保基金支付限额
export function fundQuota(params: averageTipParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/fundQuota`,
    method: 'GET',
    params,
  }
}

// 医保支付限额-医保列支医疗费支付限额
export function medicalList(params: averageTipParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/medicalList`,
    method: 'GET',
    params,
  }
}

// 医保控费设置
export function settingDetail(): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/tenant/functionSelect`,
    method: 'GET',
  }
}

// 医保控费设置保存
export function settingSave(body:any): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/tenant/functionSaveBatch`,
    method: 'POST',
    body
  }
}