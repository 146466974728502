import { Form, Space, Switch, TableColumnType } from "antd";
import moment from "moment";
import React from "react";
import { CategoryName } from "../../../models/category";
import { Dimen } from "../../../models/dimen";

export const SupplierDealingColumns = (
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      width: Dimen.Num,
      key: "no",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "业务单号",
      align: "center",
      width: Dimen.PutNumber,
      dataIndex: "putNumber",
      key: "putNumber",
      sorter: (a: any, b: any) => a.putNumber - b.putNumber,
    },

    {
      title: "供应商名称",
      align: "center",
      dataIndex: "companyName",
      width: Dimen.SupplierName,
      key: "companyName",
      sorter: (a: any, b: any) => a.companyName.length - b.companyName.length,
    },
    {
      title: "商品名称(名称/规格/剂型)",
      align: "left",
      dataIndex: "name",
      key: "",
      render: function Element(_, record) {
        return (
          <div style={{ height: "55px" }}>
            <p
              style={{
                height: "28px",
                lineHeight: "36px",
                padding: "0",
                margin: "0",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              {record.name}
            </p>
            <p
              style={{
                height: "28px",
                lineHeight: "24px",
                padding: "0",
                margin: "0",
                color: "#999999",
                fontSize: "14px",
              }}
            >
              {record?.tenantSupplierName} /{record.spec} /{record.doseCategory}
            </p>
          </div>
        );
      },
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: "业务类别",
      align: "center",
      dataIndex: "category",
      width: Dimen.Category,
      key: "category",
      render: (_, t) => {
        return CategoryName(t.category);
      },
      sorter: (a: any, b: any) => a.category.length - b.category.length,
    },
    {
      title: "发生日期",
      align: "center",
      dataIndex: "createTime",
      width: Dimen.Time,
      key: "createTime",
      render: (_, t) => {
        const createTime = t.createTime.substring(0, 10);
        return createTime;
      },
      sorter: (a: any, b: any) =>
        moment(a.createTime).unix() - moment(b.createTime).unix(),
    },

    {
      title: "数量",
      align: "center",
      dataIndex: "count",
      width: Dimen.Count,
      key: "count",
      sorter: (a: any, b: any) => a.count - b.count,
    },
    {
      title: "成本价",
      align: "right",
      dataIndex: "unitPrice",
      width: Dimen.Price,
      key: "unitPrice",
      render: (_: any, t: any) => {
        return t.unitPrice
          ? parseFloat((t.unitPrice as unknown) as string).toFixed(5)
          : 0;
      },
    },
    {
      title: "总金额",
      align: "right",
      dataIndex: "totalPrice",
      width: Dimen.Price,
      key: "totalPrice",
      render: (_: any, t: any) => {
        return t.totalPrice
          ? parseFloat((t.totalPrice as unknown) as string).toFixed(2)
          : 0;
      },
    },
  ];
};
