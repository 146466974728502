import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState, RootThunk, RootThunkApi, } from "../../../app/store";
import { api, sendAsync } from "../../../app/applicationSlice";
import * as service from "../../../services/beforeOperatingManagePage";
import { BeforeOperatingParmas } from "../../../services/beforeOperatingManagePage";
import { NoFormulasDetail } from "../../../models/ModalList";
import * as B from '../../../services/beforeOperatingManagePage'
import { Page, pageOf, startPageOf } from '../../../models/page'
import _ from "lodash";


// 申诉列表
export const getAppealListAsync = createAsyncThunk<
    void,
    B.BeforeOperatingParmas,
    RootThunkApi
>('/appeal/getAppealListAsync', async (params, api) => {
    return sendAsync(B.getAppealList(params), api)
})
// 详情
export const getAppealDetailAsync = createAsyncThunk<
    void,
    string,
    RootThunkApi
>('/appeal/getAppealDetailAsync', async (id, api) => {
    return sendAsync(B.getAppealDeatil(id), api)
})
// 举证
export const getProofAsync = createAsyncThunk<
    void,
    any,
    RootThunkApi
    >('/appeal/getProofAsync', async (params, api) => {
        return sendAsync(B.getProof(params), api)
})
// 上传
export const uploadFileAsync = createAsyncThunk<
    void,
    any,
    RootThunkApi
    >('/appeal/uploadFileAsync', async (params, api) => {
    return sendAsync(B.getAppealDeatil(params), api)
})
// 过程
export const appealProcessAsync = createAsyncThunk<
    void,
    string,
    RootThunkApi
    >('/appeal/appealProcessAsync', async (id, api) => {
    return sendAsync(B.appealProcess(id), api)
})
