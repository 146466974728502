import {
  EditableList,
} from "../../../../compnents/list/EditableList";
import React, { ReactElement, useEffect, useState } from "react";
import { notification } from "antd";
import { NurseQuerys } from "../../formula/noformula/Query";
import { useDispatch, useSelector } from "react-redux";
import {  doctorcolumn } from "../noformula/columns";
import {
  selectNoformulaData,
  getNoformulaList,
  getNoformula,
  selectCurrent,
  selectTotal,
  setCurrent,
  getExecuteState,
  selectPageLoading,
  setPageLoading
} from "./noformulaSlice";
import { NoFormulaParmas } from "../../../../services/formula";
import { ModelList } from "./Modallist";
import styles from "../formulas/formula.module.css";
import { recipeColumns } from "./column";
import { RootDispatch } from "../../../../app/store";
import { unwrapResult } from "@reduxjs/toolkit";

export const NoFormula = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const CREDENTIAL_KEY = "states";

  const [queries, setQueries] = useState<NoFormulaParmas>({

  });

  const [isTreatmentId, setIsTreatmentId] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [SelectId, setSelectId] = useState(0);

  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectNoformulaData);

  const [size, setSize] = useState(10);

  const pageLoadingRX = useSelector(selectPageLoading);

  useEffect(() => {
    if (queries?.timeHead){
      dispatch(setPageLoading(true))
      dispatch(getNoformulaList({
        ...queries, current: Current, size, state: 0
      }))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }
    
  }, [queries, size, Current,]);

  useEffect(() => {
    if (queries?.param?.length == 9 && Number(data.length) == 1) {
      dispatch(getNoformula({ registrationId: data[0]?.registrationId, state: 0, normalUsemethod: data[0]?.normalUsemethod }));
      setIsModalVisible(true);
      setIsTreatmentId(data[0]?.treatmentId)
      //  setPaymentId(data[0]?.paymentId)
    }
  }, [data])

  const range: any = sessionStorage.getItem(CREDENTIAL_KEY);
  return (
    <>
      <div className={styles.contenter}>
        <NurseQuerys
          isModalVisible={isModalVisible}
          onValueChange={(v) => {
            setQueries({ ...queries, ...v })
            dispatch(setCurrent(1))
            const state: any = v.range
            sessionStorage.setItem(CREDENTIAL_KEY, state)
          }}
        />
        <EditableList
          rowKey={(_, i) =>
            queries
              ? (queries.size || 0) * (queries.current || 0) + (i || 0)
              : 0
          }
          loading={pageLoadingRX}
          className={styles.noformula}
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number)
            dispatch(setCurrent(current))
          }}
          columns={
            range === '1'
              ? doctorcolumn((useMethod, treatmentId, t) => {
                  if (t == '执行') {
                    dispatch(getExecuteState({ useMethod, treatmentId, range }))
                      .then(unwrapResult)
                      .then(() => {
                        notification.success({
                          message: '执行成功',
                          duration: 3,
                        })
                        dispatch(
                          getNoformulaList({
                            ...queries,
                            current: Current,
                            size,
                            state: 0,
                          })
                        )
                      })
                  } else {
                    return
                  }
                }, size * (Current - 1))
              : recipeColumns((useMethod, recipeId, t) => {
                  if (t == '执行') {
                    dispatch(getExecuteState({ useMethod, recipeId, range }))
                      .then(unwrapResult)
                      .then(() => {
                        notification.success({
                          message: '执行成功',
                          duration: 3,
                        })
                        dispatch(
                          getNoformulaList({
                            ...queries,
                            current: Current,
                            size,
                            state: 0,
                            range: range,
                          })
                        )
                      })
                  } else {
                    return
                  }
                }, size * (Current - 1))
          }
          onRow={(d: any) => ({
            onDoubleClick: () => {
              dispatch(
                getNoformula({
                  registrationId: d.registrationId,
                  state: 0,
                  normalUsemethod: d.normalUsemethod,
                })
              )
              setIsModalVisible(true)
              setIsTreatmentId(d.treatmentId)

            },
          })}
        />
        <ModelList
          visible={isModalVisible}
          treatmentId={isTreatmentId}
          onOk={() => {
            setIsModalVisible(false)
            dispatch(
              getNoformulaList({
                ...queries,
                current: Current,
                size,
                state: 0,
                range: range,
              })
            )

            setSelectId(0)
          }}
          onCancel={() => {
            setIsModalVisible(false)
            dispatch(
              getNoformulaList({
                ...queries,
                current: Current,
                size,
                state: 0,
                range: range,
              })
            )
            setSelectId(0)
          }}
        />
      </div>
    </>
  )
};
