/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-09-02 17:10:30
 * @LastEditors: sj
 * @LastEditTime: 2022-12-06 19:02:14
 */
import { TemplateCategory, TemplateDetail } from "../models/template";
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface GetTemplateListParams {
  current?: number;
  size?: number;
  categoryName?: string;
  medicalRecord ?: any
}

export function getTemplateCategoryList(
  params: GetTemplateListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatmenttemplate/list`,
    method: 'GET',
    params: {
      ...params,
      size:  params?.size&&params?.size<200? 200:params?.size,
      categoryName: params.categoryName ? params.categoryName : undefined,
    },
  }
}

export function createOrUpdateTemplateCategory(
  template: TemplateCategory
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatmenttemplate/submit`,
    method: "POST",
    body: template,
  };
}

export function removeTempalteCategory(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatmenttemplate/remove`,
    method: "POST",
    params: {
      ids: id,
    },
  };
}

export function getTemplateDetailList(templateId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatmenttemplateitem/list`,
    method: 'GET',
    params: {
      current: 1,
      size: 200,
      templateId,
    },
  }
}

export function createOrUpdateTemplateDetail(detail: TemplateDetail): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatmenttemplateitem/submit`,
    method: "POST",
    body: detail,
  };
}

export function removeTempalteDetail(id?: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatmenttemplateitem/remove`,
    method: "POST",
    params: {
      ids: id,
    },
  };
}


// 收藏列表
export function commonList(medicalRecord?: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatmenttemplateitemcommon/list`,
    method: "GET",
    params: {
      medicalRecord,
      size:200,
      current:1
    },
  };
}

// 收藏
export interface CommonSaveParams {
  itemId?:string
  medicalRecord:number
}
export function commonSave(body?: CommonSaveParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatmenttemplateitemcommon/save`,
    method: "POST",
    body
  };
}

// 取消收藏
export function commonRemove(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatmenttemplateitemcommon/remove`,
    method: "POST",
    params: {
      ids: id,
    },
  };
}
