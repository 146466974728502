/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:23:52
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-12 16:16:23
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 17:00:20
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { StateSwitch } from '../../../../compnents/widgets/StateSwitch'
import { Dimen } from '../../../../models/dimen'
import { getGenderName } from '../../../../models/user'
import femaleHead from '../../images/female.png'
import maleHead from '../../images/male.png'


type ActionType = 'edit' | 'stateEdit'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const [checked, setChecked] = useState<any>()
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '头像',
      dataIndex: 'name',
      align: 'center',
      ellipsis: true,
      render: function ShowActions(_, r) {
        const href =window.location?.origin
        const headPhotoList =r?.headPhoto?.split('/')

        return r.headPhoto && headPhotoList?.[0]== 'proxyToOss' ? (
          <img
            src={href +'/' + r.headPhoto}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        ) :r.headPhoto && headPhotoList?.[0] != 'proxyToOss' ? (
          <img
            src={r.headPhoto}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        ) : r.sex == 2 ? (
          <img
            src={femaleHead}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        ) : (
          <img
            src={maleHead}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        )
      },
    },

    {
      title: '医生姓名',
      dataIndex: 'realname',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.realname ? r.realname : '-'
      },
    },

    {
      title: '科室',
      dataIndex: 'outpatientDepartmentName',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.outpatientDepartmentName ? r.outpatientDepartmentName : '-'
      },
    },
    {
      title: '职位',
      dataIndex: 'jobTitle',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.jobTitle ? r.jobTitle : '-'
      },
    },

    {
      title: '擅长',
      dataIndex: 'adeptAt',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.adeptAt ? r.adeptAt : '-'
      },
    },
    {
      title: '简介',
      dataIndex: 'intro',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.intro ? r.intro : '-'
      },
    },
    {
      title: '是否展示到公众号',
      dataIndex: '',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: function UserSettings(_, d): ReactElement {
        const active = d.status === 1
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {d.wechatShowFlg == 0
              ? '不展示'
              : d.wechatShowFlg == 1
              ? '展示'
              : ''}

            <Switch
              checked={Boolean(d.wechatShowFlg)}
              style={{ marginLeft: '10px' }}
              onChange={(e) => {
                setChecked(e)
                onClick('stateEdit', d, e)
              }}
            />
          </div>
        )
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '6rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('edit', u)}
              style={{ marginRight: '4px' }}
            >
              编辑
            </a>
          </>
        )
      },
    },
  ]
}
