/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-10 14:24:06
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface ScheduleOfExpensesPrams {
  current: number
  size: number
  startTime: string
  endTime: string
  doctorId?: any
}

// 列表
export function scheduleOfExpensesList(
  params: ScheduleOfExpensesPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/calculateDoctorPatientFees`,
    method: 'GET',
    params,
  }
}

export function scheduleOfExpensesTotalList(
  params: ScheduleOfExpensesPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/calculateDoctorPatientFeesSUM`,
    method: 'GET',
    params,
  }
}



// 导出
export const getExportFn = (params: ScheduleOfExpensesPrams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/calculateDoctorPatientFees/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}

