/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-16 17:03:21
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-17 14:13:53
 */
import { Category } from '../models/category'
import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface purchaseAcceptanceParams {
  current?: number
  size?: number
  param?: string
  timeHead?: string
  timeTail?: string
}

export function purchaseAcceptanceList(
  params: purchaseAcceptanceParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/tenantinventoryPurchAseacceptance/page`,
    method: 'GET',
    params,
  }
}

export function purchaseAcceptanceDetail(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-store/tenantinventoryPurchAseacceptance/detail`,
    method: 'GET',
    params: {
      id,
    },
  }
}

export function generateAcceptanceInfo(
  tenantInventoryReceiptsId: any
): Request {
  return {
    url: `${API_PREFIX}/blade-store/tenantinventoryPurchAseacceptance/generateAcceptanceInfo`,
    method: 'GET',
    params: {
      tenantInventoryReceiptsId,
    },
  }
}

export function batchGenerateAcceptanceInfo(
    ids: any
): Request {
  return {
    url: `${API_PREFIX}/blade-store/tenantinventoryPurchAseacceptance/batchGenerateAcceptanceInfo`,
    method: 'GET',
    params: {
      ids,
    },
  }
}

export function purchaseAcceptanceExport(
  params: purchaseAcceptanceParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/tenantinventoryPurchAseacceptance/pageExportExcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}

export function generateAcceptancePrintInfo(
  tenantInventoryReceiptsId: any
): Request {
  return {
    url: `${API_PREFIX}/blade-store/tenantinventoryPurchAseacceptance/pageFrontPrint`,
    method: 'GET',
    params: {
      tenantInventoryReceiptsId,
    },
  }
}
