import {Button, Col, Tabs, Row, TableColumnType, Form, Space, Input, Select, Cascader} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {DateRangeType, DateSelect, getDateRange} from "../../../../compnents/form/DateSelect";
import {PurchaseControlParams} from "../../../../services/purchaseControl";
import {getMaterialsName, Materiales} from "../../../../models/commodity";
import {getCommonCodeCompletionsAsync} from "../../../completion/completionSlice";
import {CommonCodeCategory} from "../../../../models/commoncode";
import {unwrapResult} from "@reduxjs/toolkit";
import {RootDispatch} from "../../../../app/store";

export const ReceivingQuery = (
    props: {
        onValueChange: (queries: PurchaseControlParams) => void;
        onExport: () => void
    }): ReactElement => {

    const [treeData, setTreeData] = useState<any>([])

    const dispatch = useDispatch<RootDispatch>()

    const [form] = Form.useForm();
    const [values, setValues] = useState("");

    const {Option} = Select;

    const getYPFL = () => {
        const data = Materiales.map((v) => {
            return {
                id: v,
                value: v.toString(),
                label: getMaterialsName(v),
                children: [],
            }
        })

        dispatch(
            getCommonCodeCompletionsAsync({
                category: CommonCodeCategory.Durg,
                keyword: '',
            })
        )
            .then(unwrapResult)
            .then((res: any) => {
                const tree = data.map((v) => {
                    if (v.id == 0) {
                        return {
                            ...v,
                            children: res.records.map((el: any) => ({
                                value: el.codeName,
                                label: el.codeName,
                            })),
                        }
                    } else if (v.id == 1) {
                        return {
                            ...v,
                            children: [
                                {value: '材料', label: '材料'},
                                {value: '器械', label: '器械'},
                                {value: '消耗品', label: '消耗品'},
                                {value: '其他', label: '其他'},
                            ],
                        }
                    } else if (v.id == 2) {
                        return {
                            ...v,
                            children: [
                                {value: '第一类医疗器械', label: '第一类医疗器械'},
                                {value: '第二类医疗器械', label: '第二类医疗器械'},
                                {value: '第三类医疗器械', label: '第三类医疗器械'},
                            ],
                        }
                    } else {
                        return v
                    }
                })

                setTreeData(tree)
            })
    }

    useEffect(() => {
        getYPFL()
        const [head, tail] = getDateRange(DateRangeType.ThisYear);
        form.setFieldsValue({
            timeHead: head,
            timeTail: tail,
        });
        form.submit();
    }, []);


    const refresh = () => {
        const materialsCategory = form.getFieldsValue()?.materialsCategory

        props.onValueChange({
            ...form.getFieldsValue(),
            materialsCategory: Array.isArray(materialsCategory) ? materialsCategory?.[0] : undefined,
            category: Array.isArray(materialsCategory) ? materialsCategory?.[1] : undefined,
        })
    }


    return (
        <>
            <Form
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
                form={form}
                onFinish={(values) => props.onValueChange(values)}>
                <Row
                    style={{marginBottom: 10, width: "100%"}}
                    align="middle"
                    justify="space-between"
                >
                    <Space>
                        <Form.Item name="goodsParam" noStyle>
                            <Input
                                autoComplete="off"
                                placeholder="请输入供应商名称/助记码"
                                style={{width: 300}}
                                onChange={() => form.submit()}
                                prefix={<SearchOutlined/>}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item name='materialsCategory' noStyle>
                            <Cascader
                                options={treeData}
                                onChange={() => refresh()}
                                changeOnSelect
                                placeholder='商品类别'
                            />
                        </Form.Item>
                        <Form.Item name="businessType" noStyle>
                            <Select placeholder="业务类型" allowClear style={{width: "200px"}}
                                    onChange={() => form.submit()}>
                                <Option value="1">业务类型-购入入库</Option>
                                <Option value="2">业务类型-购入退库</Option>
                            </Select>
                        </Form.Item>

                        <DateSelect
                            style={{width: "8.5rem"}}
                            labelPrefix=""
                            namePrefix="time"
                            placeholder="挂号时间-今天"
                            initialValue={DateRangeType.ThisYear}
                            onChange={() => form.submit()}
                            allowClear={false}
                        />
                        <Button
                            type="primary"
                            onClick={() => props.onValueChange(form.getFieldsValue())}
                        >
                            查询
                        </Button>
                    </Space>

                    <Button
                        type="primary"
                        onClick={() => props.onExport()}
                    >
                        导出
                    </Button>
                </Row>
            </Form>
        </>
    );
};
