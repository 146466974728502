import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Form, Row, Select,Input, message, notification } from "antd";
import React, { ReactElement,  useState } from "react";
import { useDispatch,  } from "react-redux";
import { DatePicker,  } from "antd";
import { getDelayState } from "./serviceDelaySlice";
import { NavBar } from "../../compnents/nav/NavBar";
import { RootDispatch } from "../../app/store";

export const ServiceDelay = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm();

  const { Option } = Select;

  const [isSelect, setIsSelect] = useState(1);
  return (
    <div
      style={{
      height:"100%",
        margin: "10px 20px 10px 20px",

      }}
    >
       <NavBar
       style={{marginBottom:"10px"}}
        where={["机构管理", "租户延期"]}
        backtrace={{
          name: "返回机构用户",
          path: "/admin/tenant",
        }}
      />
      <div style={{ height: "calc(100% - 70px)",marginTop:"10px", background:"#FFFFFF", borderRadius:"10px"}}>
      <Form
        form={form}
        style={{ padding: "0 27px",marginBottom:"20px" , background: "#FFFFFF",
        borderRadius: "30px",}}
        onFinish={() => {
          form
            .validateFields()
            .then((values) => {
              const format = "YYYY-MM-DD";
              const tenantId = location.search.replace('?', '')
              const endTime = values.endTime && values.endTime.format(format);
              dispatch(getDelayState({ ...values, endTime,tenantId }))
              .then(unwrapResult)
              .then(()=>{
                notification.success({
                  message: "服务延期成功",
                  duration: 3,
                });
              })
              .catch(()=>{
                notification.error({
                  message: "服务延期失败",
                  duration: 3,
                });
              })

            })            
            .then(()=>{
              form.resetFields()
            })
            .catch(() => {
              // Do nothing.
              form.resetFields()
            });
        }}
      >
         
        <Row
          style={{
            height: "50px",
            borderBottom: "1px solid ##F3F7FD",
            paddingTop: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <i
            style={{
              width: "2px",
              height: "10px",
              background: "#0052D9",
              marginRight: "9px",
              marginTop: "-15px",
            }}
          ></i>
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "#333333" }}>
            延长有效期
          </p>
        </Row>
        <Row style={{ marginTop: "20px", marginLeft: "10px" }}>
          <Col>
            <Form.Item name="type">
              <Select
                placeholder="时间段"
                allowClear
                  onSelect={(values: number) => {
                  if (values == 1) {
                    setIsSelect(values as number);
                  } else if (values == 2) {
                    setIsSelect(values as number);
                  } else if (values == 3) {
                    setIsSelect(values as number);
                  } else {
                    setIsSelect(values as number);
                  }
                }}
                style={{ width: "126px", marginRight: "20px" }}
              >
                <Option value={1}>按天</Option>
                <Option value={2}>按月</Option>
                <Option value={3}>按年</Option>
                <Option value={4}>自定义</Option>
              </Select>
            </Form.Item>
          </Col>
          {isSelect == 1 ? (
            <Col >
              <Form.Item name="value">
              <Input style={{ width: "280px", height: "33px" }} placeholder="请先选中时间段" allowClear/> 
              </Form.Item>
            </Col>
          ) : isSelect == 2 ? (
            <Col>
              <Form.Item name="value">
                <Select
                  placeholder="请先选中时间段"
                  allowClear
                  showSearch
                  style={{ width: "280px", height: "40px" }}
                >
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                  <Option value={6}>6</Option>
                  <Option value={7}>7</Option>
                  <Option value={8}>8</Option>
                  <Option value={9}>9</Option>
                  <Option value={10}>10</Option>
                  <Option value={11}>11</Option>
                </Select>
              </Form.Item>
            </Col>
          ) : isSelect == 3 ? (
            <Col>
              <Form.Item name="value">
                <Select
                  placeholder="请先选中时间段"
                  allowClear
                  showSearch
                  style={{ width: "280px", height: "40px" }}
                >
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <Form.Item name="endTime">
              <DatePicker
                style={{ width: "280px" }}
                placeholder="请选择结束时间"
                format="YYYY/MM/DD"
              />
            </Form.Item>
          )}
        </Row>
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "307px",
          }}
        >
          <Button style={{ marginRight: "20px", color: "#666666" }}>
            取消
          </Button>
          <Button
            style={{ background: "#027AFF", color: "#FFFFFF" }}
            htmlType="submit"
          >
            确定
          </Button>
        </Row>
      </Form>
    </div>
    </div>
  );
};
