/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-10-11 09:53:06
 */
import { Button, Row,  Form, Space, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, { ReactElement,  useEffect } from "react";
import { } from "antd";
import { DateRangeType, DateSelect, getDateRange } from "../../../../compnents/form/DateSelect";
import { pitestQueryParams } from "../../../../services/nopitest";
export const NoPitestQuery = (
  props: {
    onValueChange: (queries: pitestQueryParams) => void;
  }): ReactElement => {
  const [form] = Form.useForm();
  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today);
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
    });
    form.submit();
  }, []);
  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='param' noStyle>
              <Input
                autoComplete='off'
                placeholder='请输入患者姓名或药品名称或病历号'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <DateSelect
              style={{ width: '10rem' }}
              labelPrefix=''
              namePrefix='time'
              placeholder='就诊执行-时间'
              initialValue={DateRangeType.Today}
              onChange={() => form.submit()}
              allowClear={false}
            />
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
};
