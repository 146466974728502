import { nopiTest, toJson } from "../models/nopitest";
import { piTest } from "../models/pitest";
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface pitestQueryParams {
  id?: number;
  state?: number;
  current: number;
  size?: number;
  param?: string;
  timeHead?: string;
 timeTail?: string;
  treatmentDepartmentId?: string;
  recipeState?:string

}

export function getPitestList(params: pitestQueryParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/skintest/list`,
    method: "GET",
    params,
  };
}
export function getPitestDetail(nopitest: nopiTest): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/skintest/update`,
    method: "POST",
    body: { ...toJson(nopitest) },
  };
}


export function getDetailList(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/skintest/detail`,
    method: "GET",
    params: {
      id
    },
  };
}
export function getPeopleList(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/skintest/listTestUser`,
    method: "GET",
  };
}

export function getPitestInvalidate(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/skintest/Invalidate`,
    method: "GET",
    params: {
      id
    },
  };
}