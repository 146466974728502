/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 13:58:32
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import * as service from '../../../services/recardDrug'
import {
  RecardDrugParams,
  getRecardDrugListExoprtFn,
} from '../../../services/recardDrug'
interface RecardDrug {
  RecardDrug: []
  Total: 0
  Current: 1
  pageLoading: false
}

const initialState: RecardDrug = {
  RecardDrug: [],
  Total: 0,
  Current: 1,
  pageLoading: false,
}

const recardDrugSlice = createSlice({
  name: 'recardDrugSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.RecardDrug = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setData,
  setTotal,
  setCurrent,
  setPageLoading,
} = recardDrugSlice.actions

export const selectRecardDrugList = (state: RootState) =>
  state.recardDrug.RecardDrug

export const selectTotal = (state: RootState) => state.recardDrug.Total

export const selectCurrent = (state: RootState) => state.recardDrug.Current

export const selectPageLoading = (state: RootState) =>
  state.recardDrug.pageLoading

export function getRecardDrugList(params: RecardDrugParams): RootThunk {
  return api(service.getRecardDrug(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

// 导出
export const getRecardDrugListExoprt = createAsyncThunk<
  void,
  RecardDrugParams,
  RootThunkApi
>('doctorAudit/getRecardDrugListExoprt ', async (params, api) => {
  return sendAsync(getRecardDrugListExoprtFn(params), api)
})

export default recardDrugSlice.reducer
