import { EnvironmentOutlined } from '@ant-design/icons'
import { Button, Space, TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
} from '../../../../models/datetime'
import { getSexData } from '../../../../models/ToothHome'
import { getAge } from '../../../../utils/StringUtils'
import styles from './chargePage.module.css'

export const HomeChargeColumns = (
  startIndex: number,
  onAction: (action: string, t: any) => void
): TableColumnType<any>[] => {
  return [
    //收费表格
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3.5rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '患者姓名',
      dataIndex: 'patientName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.insuranceCode == 'ZIFEI'
          ? '自费'
          : t.insuranceCode == '' || t.insuranceCode == null
          ? '未知险种'
          : '医保刷卡'
      },
    },
    {
      title: '性别',
      dataIndex: 'patientSex',
      align: 'center',
      width: '3.5rem',
      ellipsis: true,
      render: (_, t) => getSexData(Number(t.patientSex)),
    },
    {
      title: '年龄',
      dataIndex: 'patientAge',
      align: 'center',
      width: '4.5em',
      ellipsis: true,
      render: (_, r) => getAge(r?.patientAge, r?.patientMonth),
    },
    {
      title: '疾病诊断',
      dataIndex: 'disease',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '诊断科室',
      dataIndex: 'treatmentDepartmentName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '诊断医生',
      dataIndex: 'treatmentDoctorName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '挂号时间',
      dataIndex: 'registrationTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
    },
    {
      title: '总金额',
      dataIndex: 'totalPayAmount',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (t) => (t >= 0 ? t.toFixed(2) : '-'),
    },
    {
      title: '报销金额',
      dataIndex: 'insurancePayAmount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (t) => (t >= 0 ? t.toFixed(2) : '-'),
    },
    {
      title: '现金金额',
      dataIndex: 'realPayAmount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (t) => (t >= 0 ? t.toFixed(2) : '-'),
    },
    {
      title: '实收金额',
      dataIndex: 'realerPayAmount',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (t) => (t >= 0 ? t.toFixed(2) : '-'),
    },
    {
      title: '欠费金额',
      dataIndex: 'arrearageAmount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (t) => (t >= 0 ? t.toFixed(2) : '-'),
    },
    {
      title: '结算状态',
      dataIndex: 'accountingStatus',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        switch (t.state) {
          case 101:
            return '待收费'
          case 3:
            return t.accountingStatus == 0
              ? '已结清'
              : t.accountingStatus == 1
              ? '欠费中'
              : '-'
          case 5:
            return '已退款'
        }
      },
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '8rem',
      render: function Element(_, t) {
        return (
          <Space className={styles.buttonStyle}>
            {(t.state == 101 || t.accountingStatus == 1) && (
              <Button
                type='link'
                onClick={() => {
                  onAction(
                    t.accountingStatus == 1 ? 'bujiao' : 'settleAccounts',
                    t
                  )
                }}
              >
                {t.accountingStatus == 1 ? '补交' : '结算'}
              </Button>
            )}
            {t.state != 101 && (
              <Button type='link' onClick={() => onAction('detail', t)}>
                详情
              </Button>
            )}
            {t.state == 3 &&
              (t.accountingStatus == 0 || t.accountingStatus == 1) && (
                <Button type='link' onClick={() => onAction('returRremium', t)}>
                  退费
                </Button>
              )}
          </Space>
        )
      },
    },
  ]
}

export const ChargeColumns = (
  startIndex: number,
  onAction: (action: string, t: any) => void
): TableColumnType<any>[] => {
  return [
    //收费表格
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3.5rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    // {
    //   title: '患者姓名',
    //   dataIndex: 'patientName',
    //   align: 'center',
    //   width: '6rem',
    //   ellipsis: true,
    // },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.insuranceCode == 'ZIFEI'
          ? '自费'
          : t.insuranceCode == '' || t.insuranceCode == null
          ? '未知险种'
          : '医保刷卡'
      },
    },
    // {
    //   title: '性别',
    //   dataIndex: 'patientSex',
    //   align: 'center',
    //   width: '3.5rem',
    //   ellipsis: true,
    //   render: (_, t) => getSexData(Number(t.patientSex)),
    // },
    // {
    //   title: '年龄',
    //   dataIndex: 'patientAge',
    //   align: 'center',
    //   width: '4.5em',
    //   ellipsis: true,
    //   render: (_, r) => getAge(r?.patientAge, r?.patientMonth),
    // },
    {
      title: '疾病诊断',
      dataIndex: 'disease',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '诊断科室',
      dataIndex: 'treatmentDepartmentName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '诊断医生',
      dataIndex: 'treatmentDoctorName',
      align: 'center',
      width: '6rem',
      ellipsis: true,
    },
    {
      title: '挂号时间',
      dataIndex: 'registrationTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
    },
    {
      title: '总金额',
      dataIndex: 'totalPayAmount',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (t) => (t >= 0 ? t.toFixed(2) : '-'),
    },
    {
      title: '报销金额',
      dataIndex: 'insurancePayAmount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (t) => (t >= 0 ? t.toFixed(2) : '-'),
    },
    {
      title: '现金金额',
      dataIndex: 'realPayAmount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (t) => (t >= 0 ? t.toFixed(2) : '-'),
    },
    {
      title: '实收金额',
      dataIndex: 'realerPayAmount',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (t) => (t >= 0 ? t.toFixed(2) : '-'),
    },
    {
      title: '欠费金额',
      dataIndex: 'arrearageAmount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (t) => (t >= 0 ? t.toFixed(2) : '-'),
    },
    {
      title: '收费时间',
      dataIndex: 'payTime',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t?.state == '101'
          ? '-'
          : t?.payTime
          ? moment(t?.payTime).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '结算状态',
      dataIndex: 'accountingStatus',
      align: 'center',
      fixed: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        switch (t.state) {
          case 101:
            return '待收费'
          case 3:
            return t.accountingStatus == 0
              ? '已结清'
              : t.accountingStatus == 1
              ? '欠费中'
              : '-'
          case 5:
            return '已退款'
        }
      },
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '8rem',
      fixed: 'right',
      render: function Element(_, t) {
        return (
          <Space className={styles.buttonStyle}>
            {(t.state == 101 || t.accountingStatus == 1) && (
              <Button
                type='link'
                onClick={() => {
                  onAction(
                    t.accountingStatus == 1 ? 'bujiao' : 'settleAccounts',
                    t
                  )
                }}
              >
                {t.accountingStatus == 1 ? '补交' : '结算'}
              </Button>
            )}
            {t.state != 101 && (
              <Button type='link' onClick={() => onAction('detail', t)}>
                详情
              </Button>
            )}
            {t.state == 3 &&
              (t.accountingStatus == 0 || t.accountingStatus == 1) && (
                <Button type='link' onClick={() => onAction('returRremium', t)}>
                  退费
                </Button>
              )}
          </Space>
        )
      },
    },
  ]
}
