import { Button, Popconfirm, TableColumnType } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { DateTimeFormatSimple } from '../../models/datetime'
import {
  BillName,
  Category,
  ctrlShowCol,
  getOutTitleName,
  getUnitTitleName,
} from '../../models/category'
import '../putstorelist/notshow.css'
import {
  AccountOutlined,
  DelectOutlined,
  ReverseOutlined,
} from '../../compnents/icons/Icons'
import { Dimen } from '../../models/dimen'

export const ColumnsAuthInclude = (
  onClick: (id: number, text: string) => void,
  startIndex: number,
  category: Category,
  YBUploadcategory?: string
): TableColumnType<any>[] => {
  const layout = {
    width: '14px',
    height: '18x',
    marginRight: '4px',
  }
  return [
    {
      title: '序号',
      align: 'center',
      width: '50px',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '库房名称',
      align: 'center',
      dataIndex: 'storehouseName',
      width: '120px',
      ellipsis: true,
      key: 'storehouseName',
      sorter: (a, b) => a.storehouseName.localeCompare(b.storehouseName),
    },
    {
      title: '业务单号',
      align: 'center',
      width: '180px',
      ellipsis: true,
      dataIndex: 'putNumber',
      key: 'putNumber',
    },
    {
      title: '供应商',
      align: 'center',
      width: '200px',
      ellipsis: true,
      dataIndex: 'supplierName',
      key: 'supplierName',
      sorter: (a: any, b: any) => a.supplierName.length - b.supplierName.length,
      render: (_, t) => {
        return t.supplierName ? t.supplierName : t.acceptStorehouseName
      },
    },
    {
      title: '业务部门',
      align: 'center',
      dataIndex: 'departmentName',
      width: '110px',
      ellipsis: true,
      key: 'departmentName',
      sorter: (a, b) => a.departmentName.localeCompare(b.departmentName),
    },
    {
      title: '业务人员',
      align: 'center',
      dataIndex: 'userName',
      width: '110px',
      ellipsis: true,
      key: 'userName',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: '是否登账',
      align: 'center',
      width: '110px',
      ellipsis: true,
      dataIndex: 'storehouseTag',
      key: 'storehouseTag',
      sorter: (a: any, b: any) => a.storehouseTag - b.storehouseTag,
      render: (storehouseTag: number) => {
        switch (storehouseTag) {
          case 0:
            return '否'
          case 1:
            return <span style={{ color: '#1CD0BD' }}>是</span>
          case 2:
            return '已作废'
          case 3:
            return '已冲正'
          default:
            break
        }
      },
    },
    {
      title: '是否验收',
      align: 'center',
      width: '110px',
      ellipsis: true,
      dataIndex: 'acceptanceStatus',
      key: 'acceptanceStatus',
      render: (acceptanceStatus: number) => {
        switch (acceptanceStatus) {
          case 0:
            return '未验收'
          case 1:
            return '已验收'
          default:
            break
        }
      },
    },
    {
      title: '业务日期',
      align: 'center',
      dataIndex: 'recepitsDate',
      width: '110px',
      ellipsis: true,
      key: 'recepitsDate',
      sorter: (a: any, b: any) =>
        moment(a.recepitsDate).unix() - moment(b.recepitsDate).unix(),
      render: (recepitsDate) => {
        return recepitsDate
          ? moment(recepitsDate).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '创建时间',
      align: 'center',
      dataIndex: 'createTime',
      width: '110px',
      ellipsis: true,
      key: 'createTime',
      sorter: (a: any, b: any) =>
        moment(a.createTime).unix() - moment(b.createTime).unix(),
      render: (createTime) => {
        return createTime ? moment(createTime).format(DateTimeFormatSimple) : ''
      },
    },
    {
      title: '登账时间',
      align: 'center',
      width: '110px',
      ellipsis: true,
      sorter: (a: any, b: any) =>
        moment(a.upAccountTime || moment()).unix() -
        moment(b.upAccountTime || moment()).unix(),
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
          case 3:
            return moment(t.upAccountTime).format(DateTimeFormatSimple)
          default:
            return ''
        }
      },
    },
    {
      title: getUnitTitleName(category),
      align: 'right',
      dataIndex: 'shouldPayMoney',
      width: '100px',
      ellipsis: true,
      key: 'shouldPayMoney',
      render: (shouldPayMoney) => {
        return shouldPayMoney > -1 ? parseFloat(shouldPayMoney).toFixed(2) : 0
      },
    },
    {
      title: getOutTitleName(category),
      align: 'right',
      dataIndex: 'realPayMoney',
      width: '100px',
      ellipsis: true,
      className: ctrlShowCol(category) ? 'notshow' : '',
      key: 'realPayMoney',
      render: (realPayMoney) => {
        return realPayMoney > -1 ? parseFloat(realPayMoney).toFixed(2) : 0
      },
    },
    {
      title: '售价金额',
      align: 'right',
      dataIndex: 'totalRetailPrice',
      width: '100px',
      ellipsis: true,
      key: 'totalRetailPrice',
      render: (totalRetailPrice) => {
        return totalRetailPrice > -1
          ? parseFloat(totalRetailPrice).toFixed(2)
          : 0
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '13rem',
      dataIndex: 'storehouseTag',
      key: 'storehouseTag',
      render: (storehouseTag, data) => {
        if (YBUploadcategory) {
          return data.ybUploadFlag === '2' ? (
            '已上传'
          ) : (
            <Popconfirm
              title='确认执行此操作？'
              onConfirm={() => {
                if (YBUploadcategory === '1') {
                  onClick(
                    data.id,
                    storehouseTag === 1 ? '采购单上传' : '冲正上传'
                  )
                } else if (YBUploadcategory === '2') {
                  onClick(
                    data.id,
                    storehouseTag === 1 ? '出库单上传' : '冲正上传'
                  )
                }
              }}
              okText='确认'
              cancelText='取消'
            >
              <Button type='primary' ghost size='small'>
                {storehouseTag === 1 ? '上传' : '冲正上传'}
              </Button>
            </Popconfirm>
          )
        } else {
          switch (storehouseTag) {
            case 0:
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type='text'
                    size='small'
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <AccountOutlined style={{ fill: '#1CD0BD', ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, '登账')
                    }}
                  >
                    登账
                  </Button>
                  <Button
                    type='text'
                    size='small'
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <DelectOutlined style={{ fill: '#FF5D5D', ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, '作废')
                    }}
                  >
                    作废
                  </Button>
                  <Button
                    type='text'
                    size='small'
                    style={{ color: '#027AFF', fontSize: '14px' }}
                    onClick={(t: any) => {
                      onClick(data.id, '查看详情')
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              )
            case 1:
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type='text'
                    size='small'
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <ReverseOutlined style={{ fill: '#F5BB4A', ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, '冲正')
                    }}
                  >
                    冲正
                  </Button>
                  <Button
                    type='text'
                    size='small'
                    style={{ color: '#027AFF', fontSize: '14px' }}
                    onClick={(t: any) => {
                      onClick(data.id, '查看详情')
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              )
            case 2:
            case 3:
              return (
                <div>
                  <Button
                    type='text'
                    size='small'
                    style={{ color: '#027AFF', fontSize: '14px' }}
                    onClick={(t: any) => {
                      onClick(data.id, '查看详情')
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              )
            default:
              break
          }
        }
      },
    },
  ]
}

export const ColumnsAuth = (
  onClick: (id: number, text: string) => void,
  startIndex: number,
  category: Category,
  YBUploadcategory?: string
): TableColumnType<any>[] => {
  const layout = {
    width: '14px',
    height: '18x',
    marginRight: '4px',
  }
  return [
    {
      title: '序号',
      align: 'center',
      width: '50px',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '库房名称',
      align: 'center',
      dataIndex: 'storehouseName',
      width: '120px',
      ellipsis: true,
      key: 'storehouseName',
      sorter: (a, b) => a.storehouseName.localeCompare(b.storehouseName),
    },
    {
      title: '业务单号',
      align: 'center',
      width: '180px',
      ellipsis: true,
      dataIndex: 'putNumber',
      key: 'putNumber',
    },

    {
      title: '供应商',
      align: 'center',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: '200px',
      ellipsis: true,
      sorter: (a: any, b: any) => a.supplierName.length - b.supplierName.length,
      render: (_, t) => {
        return t.supplierName ? t.supplierName : t.acceptStorehouseName
      },
    },
    {
      title: '业务部门',
      align: 'center',
      dataIndex: 'departmentName',
      width: '110px',
      ellipsis: true,
      key: 'departmentName',
      sorter: (a, b) => a.departmentName.localeCompare(b.departmentName),
    },
    {
      title: '业务人员',
      align: 'center',
      dataIndex: 'userName',
      width: '110px',
      ellipsis: true,
      key: 'userName',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: '是否登账',
      align: 'center',
      width: '110px',
      ellipsis: true,
      dataIndex: 'storehouseTag',
      key: 'storehouseTag',
      sorter: (a: any, b: any) => a.storehouseTag - b.storehouseTag,
      render: (storehouseTag: number) => {
        switch (storehouseTag) {
          case 0:
            return '否'
          case 1:
            return <span style={{ color: '#1CD0BD' }}>是</span>
          case 2:
            return '已作废'
          case 3:
            return '已冲正'
          default:
            break
        }
      },
    },
    {
      title: '业务日期',
      align: 'center',
      dataIndex: 'recepitsDate',
      width: '110px',
      ellipsis: true,
      key: 'recepitsDate',
      sorter: (a: any, b: any) =>
        moment(a.recepitsDate).unix() - moment(b.recepitsDate).unix(),
      render: (recepitsDate) => {
        return recepitsDate
          ? moment(recepitsDate).format(DateTimeFormatSimple)
          : ''
      },
    },
    {
      title: '创建时间',
      align: 'center',
      dataIndex: 'createTime',
      width: '110px',
      ellipsis: true,
      key: 'createTime',
      sorter: (a: any, b: any) =>
        moment(a.createTime).unix() - moment(b.createTime).unix(),
      render: (createTime) => {
        return createTime ? moment(createTime).format(DateTimeFormatSimple) : ''
      },
    },
    {
      title: '登账时间',
      align: 'center',
      width: '110px',
      ellipsis: true,
      sorter: (a: any, b: any) =>
        moment(a.upAccountTime || moment()).unix() -
        moment(b.upAccountTime || moment()).unix(),
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
          case 3:
            return moment(t.upAccountTime).format(DateTimeFormatSimple)
          default:
            return ''
        }
      },
    },
    {
      title: getUnitTitleName(category),
      align: 'right',
      dataIndex: 'shouldPayMoney',
      width: '100px',
      ellipsis: true,
      key: 'shouldPayMoney',
      render: (shouldPayMoney) => {
        return shouldPayMoney > -1 ? parseFloat(shouldPayMoney).toFixed(2) : 0
      },
    },
    {
      title: getOutTitleName(category),
      align: 'right',
      dataIndex: 'realPayMoney',
      width: '100px',
      ellipsis: true,
      className: ctrlShowCol(category) ? 'notshow' : '',
      key: 'realPayMoney',
      render: (realPayMoney) => {
        return realPayMoney > -1 ? parseFloat(realPayMoney).toFixed(2) : 0
      },
    },
    {
      title: '操作',
      align: 'center',
      ellipsis: true,
      width: '13rem',
      dataIndex: 'storehouseTag',
      key: 'storehouseTag',
      render: (storehouseTag, data) => {
        if (YBUploadcategory) {
          return data.ybUploadFlag === '2' ? (
            '已上传'
          ) : (
            <Popconfirm
              title='确认执行此操作？'
              onConfirm={() => {
                if (YBUploadcategory === '1') {
                  onClick(
                    data.id,
                    storehouseTag === 1 ? '采购单上传' : '冲正上传'
                  )
                } else if (YBUploadcategory === '2') {
                  onClick(
                    data.id,
                    storehouseTag === 1 ? '出库单上传' : '冲正上传'
                  )
                }
              }}
              okText='确认'
              cancelText='取消'
            >
              <Button type='primary' ghost size='small'>
                {storehouseTag === 1 ? '上传' : '冲正上传'}
              </Button>
            </Popconfirm>
          )
        } else {
          switch (storehouseTag) {
            case 0:
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type='text'
                    size='small'
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <AccountOutlined style={{ fill: '#1CD0BD', ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, '登账')
                    }}
                  >
                    登账
                  </Button>
                  <Button
                    type='text'
                    size='small'
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <DelectOutlined style={{ fill: '#FF5D5D', ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, '作废')
                    }}
                  >
                    作废
                  </Button>
                  <Button
                    type='text'
                    size='small'
                    style={{ color: '#027AFF', fontSize: '14px' }}
                    onClick={(t: any) => {
                      onClick(data.id, '查看详情')
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              )
            case 1:
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type='text'
                    size='small'
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <ReverseOutlined style={{ fill: '#F5BB4A', ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, '冲正')
                    }}
                  >
                    冲正
                  </Button>
                  <Button
                    type='text'
                    size='small'
                    style={{ color: '#027AFF', fontSize: '14px' }}
                    onClick={(t: any) => {
                      onClick(data.id, '查看详情')
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              )
            case 2:
            case 3:
              return (
                <div>
                  <Button
                    type='text'
                    size='small'
                    style={{ color: '#027AFF', fontSize: '14px' }}
                    onClick={(t: any) => {
                      onClick(data.id, '查看详情')
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              )
            default:
              break
          }
        }
      },
    },
  ]
}
export const PrintColumnsAuth = (
  startIndex: number,
  category: Category
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => {
        if (_2.id === 0) {
          return '合计'
        } else {
          return `${startIndex + index + 1}`
        }
      },
    },
    {
      title: '库房名称',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'storehouseName',
      key: 'storehouseName',
    },
    {
      title: '业务单号',
      align: 'center',
      width: Dimen.PutNumber,
      dataIndex: 'putNumber',
      key: 'putNumber',
    },
    {
      title: '业务部门',
      align: 'center',
      width: Dimen.Department,
      dataIndex: 'departmentName',
      key: 'departmentName',
    },
    {
      title: '业务人员',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '创建时间',
      align: 'center',
      width: Dimen.Time,
      dataIndex: 'createTime',
      key: 'createTime',
      render: (createTime) => {
        return createTime ? moment(createTime).format(DateTimeFormatSimple) : ''
      },
    },
    {
      title: '登账时间',
      align: 'center',
      width: Dimen.Time,
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
            return moment(t.upAccountTime).format(DateTimeFormatSimple)
          default:
            return ''
        }
      },
    },
    {
      title: getUnitTitleName(category),
      align: 'right',
      width: Dimen.Price,
      dataIndex: 'shouldPayMoney',
      key: 'shouldPayMoney',
      render: (shouldPayMoney) => {
        return shouldPayMoney > -1 ? parseFloat(shouldPayMoney).toFixed(2) : 0
      },
    },
    {
      title: getOutTitleName(category),
      align: 'right',
      width: Dimen.Price,
      dataIndex: 'realPayMoney',
      className: ctrlShowCol(category) ? 'notshow' : '',
      key: 'realPayMoney',
      render: (realPayMoney) => {
        return realPayMoney > -1 ? parseFloat(realPayMoney).toFixed(2) : 0
      },
    },
  ]
}

export const PrintColumnsGRTK = (
  startIndex: number,
  category: Category
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: '4rem',
      render: (_1, _2, index) => {
        if (_2.id === 0) {
          return '合计'
        } else {
          return `${startIndex + index + 1}`
        }
      },
    },
    {
      title: '库房名称',
      align: 'center',
      // width:'6rem',
      dataIndex: 'storehouseName',
      key: 'storehouseName',
    },
    {
      title: '业务单号',
      align: 'center',
      // width: Dimen.PutNumber,
      dataIndex: 'putNumber',
      key: 'putNumber',
    },
    {
      title: '供应商',
      align: 'center',
      dataIndex: 'supplierName',
      key: 'supplierName',
      // width: '6rem',
      render: (_, t) => {
        return t.supplierName ? t.supplierName : t.acceptStorehouseName
      },
    },
    {
      title: '业务部门',
      align: 'center',
      // width: Dimen.Department,
      dataIndex: 'departmentName',
      key: 'departmentName',
    },
    {
      title: '业务人员',
      align: 'center',
      // width: Dimen.Name,
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '创建时间',
      align: 'center',
      // width: Dimen.Time,
      dataIndex: 'createTime',
      key: 'createTime',
      render: (createTime) => {
        return createTime ? moment(createTime).format(DateTimeFormatSimple) : ''
      },
    },
    {
      title: '登账时间',
      align: 'center',
      // width: Dimen.Time,
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
            return moment(t.upAccountTime).format(DateTimeFormatSimple)
          default:
            return ''
        }
      },
    },
    {
      title: getUnitTitleName(category),
      align: 'right',
      // width: Dimen.Price,
      dataIndex: 'shouldPayMoney',
      key: 'shouldPayMoney',
      render: (shouldPayMoney) => {
        return shouldPayMoney > -1 ? parseFloat(shouldPayMoney).toFixed(2) : 0
      },
    },
    {
      title: getOutTitleName(category),
      align: 'right',
      // width: Dimen.Price,
      dataIndex: 'realPayMoney',
      className: ctrlShowCol(category) ? 'notshow' : '',
      key: 'realPayMoney',
      render: (realPayMoney) => {
        return realPayMoney > -1 ? parseFloat(realPayMoney).toFixed(2) : 0
      },
    },
  ]
}

export const TBCKColumnsAuthOut = (
  onClick: (id: number, text: string) => void,
  startIndex: number,
  category: Category
): TableColumnType<any>[] => {
  const layout = {
    width: '14px',
    height: '14x',
    marginRight: '4px',
  }
  const columns: TableColumnType<any>[] = [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '接收库房',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      dataIndex: 'acceptStorehouseName',
      key: 'acceptStorehouseName',
      sorter: (a: any, b: any) =>
        a.acceptStorehouseName?.length - b.acceptStorehouseName?.length,
    },
    {
      title: '调出库房',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      dataIndex: 'storehouseName',
      key: 'storehouseName',
      sorter: (a: any, b: any) =>
        a.storehouseName.length - b.storehouseName.length,
    },
    {
      title: '业务单号',
      align: 'center',
      dataIndex: 'putNumber',
      width: '12rem',
      ellipsis: true,
      key: 'putNumber',
    },
    {
      title: '业务部门',
      align: 'center',
      dataIndex: 'departmentName',
      key: 'departmentName',
      width: '8rem',
      ellipsis: true,
      sorter: (a: any, b: any) =>
        a.departmentName.length - b.departmentName.length,
    },
    {
      title: '业务人员',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'userName',
      key: 'userName',
      sorter: (a: any, b: any) => a.userName.length - b.userName.length,
    },
    {
      title: '是否登账',
      align: 'center',
      dataIndex: 'storehouseTag',
      key: 'storehouseTag',
      width: '8rem',
      ellipsis: true,
      sorter: (a: any, b: any) => a.storehouseTag - b.storehouseTag,
      render: (storehouseTag: number) => {
        switch (storehouseTag) {
          case 0:
            return '否'
          case 1:
            return <span style={{ color: '#1CD0BD' }}>是</span>
          case 2:
            return '已作废'
          case 3:
            return '已冲正'
          default:
            break
        }
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'createTime',
      sorter: (a: any, b: any) =>
        moment(a.createTime).unix() - moment(b.createTime).unix(),
      render: (createTime) => {
        return createTime ? moment(createTime).format(DateTimeFormatSimple) : ''
      },
    },
    {
      title: '登账时间',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      sorter: (a: any, b: any) =>
        moment(a.upAccountTime).unix() - moment(b.upAccountTime).unix(),
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
            return moment(t.upAccountTime).format(DateTimeFormatSimple)
          default:
            return ''
        }
      },
    },
    {
      title: getUnitTitleName(category),
      align: 'right',
      width: '10rem',
      ellipsis: true,
      dataIndex: 'totalUnitPrice',
      key: 'totalUnitPrice',
      render: (totalUnitPrice) => {
        return totalUnitPrice > -1 ? parseFloat(totalUnitPrice).toFixed(2) : 0
      },
    },
    {
      title: getOutTitleName(category),
      align: 'right',
      width: '10rem',
      ellipsis: true,
      dataIndex: 'totalRetailPrice',
      className: ctrlShowCol(category) ? 'notshow' : '',
      key: 'totalRetailPrice',
      render: (totalRetailPrice) => {
        return totalRetailPrice > -1
          ? parseFloat(totalRetailPrice).toFixed(2)
          : 0
      },
    },
    {
      width: '13rem',
      title: '操作',
      align: 'center',
      dataIndex: 'storehouseTag',
      key: 'storehouseTag',
      render: (storehouseTag, data) => {
        switch (storehouseTag) {
          case 0:
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type='text'
                  size='small'
                  style={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  icon={
                    <AccountOutlined style={{ fill: '#1CD0BD', ...layout }} />
                  }
                  onClick={(t: any) => {
                    onClick(data.id, '登账')
                  }}
                >
                  登账
                </Button>
                <Button
                  type='text'
                  size='small'
                  style={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  icon={
                    <DelectOutlined style={{ fill: '#FF5D5D', ...layout }} />
                  }
                  onClick={(t: any) => {
                    onClick(data.id, '作废')
                  }}
                >
                  作废
                </Button>
                <Button
                  type='text'
                  size='small'
                  style={{ color: '#027AFF', fontSize: '14px' }}
                  onClick={(t: any) => {
                    onClick(data.id, '查看详情')
                  }}
                >
                  查看详情
                </Button>
              </div>
            )
          case 1:
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type='text'
                  size='small'
                  style={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={
                    <ReverseOutlined style={{ fill: '#F5BB4A', ...layout }} />
                  }
                  onClick={(t: any) => {
                    onClick(data.id, '冲正')
                  }}
                >
                  冲正
                </Button>
                <Button
                  type='text'
                  size='small'
                  style={{
                    color: '#027AFF',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={(t: any) => {
                    onClick(data.id, '查看详情')
                  }}
                >
                  查看详情
                </Button>
              </div>
            )
          case 2:
          case 3:
            return (
              <div>
                <Button
                  type='text'
                  size='small'
                  style={{ color: '#027AFF', fontSize: '14px' }}
                  onClick={(t: any) => {
                    onClick(data.id, '查看详情')
                  }}
                >
                  查看详情
                </Button>
              </div>
            )
          default:
            break
        }
      },
    },
  ]
  return ctrlShowCol(category)
    ? columns?.filter((v: any) => v.key != 'totalRetailPrice')
    : columns
}

export const ColumnsAuthOut = (
  onClick: (id: number, text: string) => void,
  startIndex: number,
  category: Category
): TableColumnType<any>[] => {
  const layout = {
    width: '14px',
    height: '14x',
    marginRight: '4px',
  }
  const columns: TableColumnType<any>[] = [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '库房名称',
      align: 'center',
      width: Dimen.Name,
      ellipsis: true,
      dataIndex: 'storehouseName',
      key: 'storehouseName',
      sorter: (a: any, b: any) =>
        a.storehouseName.length - b.storehouseName.length,
    },
    {
      title: '业务单号',
      align: 'center',
      dataIndex: 'putNumber',
      width: '12rem',
      ellipsis: true,
      key: 'putNumber',
    },
    {
      title: '业务部门',
      align: 'center',
      dataIndex: 'departmentName',
      key: 'departmentName',
      width: Dimen.Department,
      ellipsis: true,
      sorter: (a: any, b: any) =>
        a.departmentName.length - b.departmentName.length,
    },
    {
      title: '业务人员',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      dataIndex: 'userName',
      key: 'userName',
      sorter: (a: any, b: any) => a.userName.length - b.userName.length,
    },
    {
      title: '是否登账',
      align: 'center',
      dataIndex: 'storehouseTag',
      key: 'storehouseTag',
      width: Dimen.State,
      ellipsis: true,
      sorter: (a: any, b: any) => a.storehouseTag - b.storehouseTag,
      render: (storehouseTag: number) => {
        switch (storehouseTag) {
          case 0:
            return '否'
          case 1:
            return <span style={{ color: '#1CD0BD' }}>是</span>
          case 2:
            return '已作废'
          case 3:
            return '已冲正'
          default:
            break
        }
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      width: Dimen.Time,
      ellipsis: true,
      key: 'createTime',
      sorter: (a: any, b: any) =>
        moment(a.createTime).unix() - moment(b.createTime).unix(),
      render: (createTime) => {
        return createTime ? moment(createTime).format(DateTimeFormatSimple) : ''
      },
    },
    {
      title: '登账时间',
      align: 'center',
      width: Dimen.Time,
      ellipsis: true,
      sorter: (a: any, b: any) =>
        moment(a.upAccountTime).unix() - moment(b.upAccountTime).unix(),
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
            return moment(t.upAccountTime).format(DateTimeFormatSimple)
          default:
            return ''
        }
      },
    },
    {
      title: getUnitTitleName(category),
      align: 'right',
      width: Dimen.Price,
      ellipsis: true,
      dataIndex: 'totalUnitPrice',
      key: 'totalUnitPrice',
      render: (totalUnitPrice) => {
        return totalUnitPrice > -1 ? parseFloat(totalUnitPrice).toFixed(2) : 0
      },
    },
    {
      title: getOutTitleName(category),
      align: 'right',
      width: Dimen.Price,
      ellipsis: true,
      dataIndex: 'totalRetailPrice',
      className: ctrlShowCol(category) ? 'notshow' : '',
      key: 'totalRetailPrice',
      render: (totalRetailPrice) => {
        return totalRetailPrice > -1
          ? parseFloat(totalRetailPrice).toFixed(2)
          : 0
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '16rem',
      dataIndex: 'storehouseTag',
      key: 'storehouseTag',
      render: (storehouseTag, data) => {
        switch (storehouseTag) {
          case 0:
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type='text'
                  size='small'
                  style={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  icon={
                    <AccountOutlined style={{ fill: '#1CD0BD', ...layout }} />
                  }
                  onClick={(t: any) => {
                    onClick(data.id, '登账')
                  }}
                >
                  登账
                </Button>
                <Button
                  type='text'
                  size='small'
                  style={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  icon={
                    <DelectOutlined style={{ fill: '#FF5D5D', ...layout }} />
                  }
                  onClick={(t: any) => {
                    onClick(data.id, '作废')
                  }}
                >
                  作废
                </Button>
                <Button
                  type='text'
                  size='small'
                  style={{ color: '#027AFF', fontSize: '14px' }}
                  onClick={(t: any) => {
                    onClick(data.id, '查看详情')
                  }}
                >
                  查看详情
                </Button>
              </div>
            )
          case 1:
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type='text'
                  size='small'
                  style={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={
                    <ReverseOutlined style={{ fill: '#F5BB4A', ...layout }} />
                  }
                  onClick={(t: any) => {
                    onClick(data.id, '冲正')
                  }}
                >
                  冲正
                </Button>
                <Button
                  type='text'
                  size='small'
                  style={{
                    color: '#027AFF',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={(t: any) => {
                    onClick(data.id, '查看详情')
                  }}
                >
                  查看详情
                </Button>
              </div>
            )
          case 2:
          case 3:
            return (
              <div>
                <Button
                  type='text'
                  size='small'
                  style={{ color: '#027AFF', fontSize: '14px' }}
                  onClick={(t: any) => {
                    onClick(data.id, '查看详情')
                  }}
                >
                  查看详情
                </Button>
              </div>
            )
          default:
            break
        }
      },
    },
  ]
  return ctrlShowCol(category)
    ? columns?.filter((v: any) => v.key != 'totalRetailPrice')
    : columns
}

// 调拨出库打印
export const PrintTBCKColumnsAuthOut = (
  startIndex: number,
  category: Category
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => {
        if (_2.id === 0) {
          return '合计'
        } else {
          return `${startIndex + index + 1}`
        }
      },
    },
    {
      title: '接收库房',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'acceptStorehouseName',
      key: 'acceptStorehouseName',
      sorter: (a: any, b: any) =>
        a.acceptStorehouseName?.length - b.acceptStorehouseName?.length,
    },
    {
      title: '调出库房',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'storehouseName',
      key: 'storehouseName',
      sorter: (a: any, b: any) =>
        a.storehouseName.length - b.storehouseName.length,
    },
    {
      title: '业务单号',
      align: 'center',
      width: Dimen.PutNumber,
      dataIndex: 'putNumber',
      key: 'putNumber',
    },
    {
      title: '业务部门',
      align: 'center',
      width: Dimen.Department,
      dataIndex: 'departmentName',
      key: 'departmentName',
    },
    {
      title: '业务人员',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '创建时间',
      align: 'center',
      width: Dimen.Time,
      dataIndex: 'createTime',
      key: 'createTime',
      render: (createTime) => {
        return createTime ? moment(createTime).format(DateTimeFormatSimple) : ''
      },
    },
    {
      title: '登账时间',
      align: 'center',
      width: Dimen.Time,
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
            return moment(t.upAccountTime).format(DateTimeFormatSimple)
          default:
            return ''
        }
      },
    },
    {
      title: getUnitTitleName(category),
      align: 'right',
      width: Dimen.Price,
      dataIndex: 'totalUnitPrice',
      key: 'totalUnitPrice',
      render: (totalUnitPrice) => {
        return totalUnitPrice > -1 ? parseFloat(totalUnitPrice).toFixed(2) : 0
      },
    },
    {
      title: getOutTitleName(category),
      align: 'right',
      width: Dimen.Price,
      dataIndex: 'totalRetailPrice',
      className: ctrlShowCol(category) ? 'notshow' : '',
      key: 'totalRetailPrice',
      render: (totalRetailPrice) => {
        return totalRetailPrice > -1
          ? parseFloat(totalRetailPrice).toFixed(2)
          : 0
      },
    },
  ]
}

export const PrintColumnsAuthOut = (
  startIndex: number,
  category: Category
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => {
        if (_2.id === 0) {
          return '合计'
        } else {
          return `${startIndex + index + 1}`
        }
      },
    },
    {
      title: '库房名称',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'storehouseName',
      key: 'storehouseName',
    },
    {
      title: '业务单号',
      align: 'center',
      width: Dimen.PutNumber,
      dataIndex: 'putNumber',
      key: 'putNumber',
    },
    {
      title: '业务部门',
      align: 'center',
      width: Dimen.Department,
      dataIndex: 'departmentName',
      key: 'departmentName',
    },
    {
      title: '业务人员',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '创建时间',
      align: 'center',
      width: Dimen.Time,
      dataIndex: 'createTime',
      key: 'createTime',
      render: (createTime) => {
        return createTime ? moment(createTime).format(DateTimeFormatSimple) : ''
      },
    },
    {
      title: '登账时间',
      align: 'center',
      width: Dimen.Time,
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
            return moment(t.upAccountTime).format(DateTimeFormatSimple)
          default:
            return ''
        }
      },
    },
    {
      title: getUnitTitleName(category),
      align: 'right',
      width: Dimen.Price,
      dataIndex: 'totalUnitPrice',
      key: 'totalUnitPrice',
      render: (totalUnitPrice) => {
        return totalUnitPrice > -1 ? parseFloat(totalUnitPrice).toFixed(2) : 0
      },
    },
    {
      title: getOutTitleName(category),
      align: 'right',
      width: Dimen.Price,
      dataIndex: 'totalRetailPrice',
      className: ctrlShowCol(category) ? 'notshow' : '',
      key: 'totalRetailPrice',
      render: (totalRetailPrice) => {
        return totalRetailPrice > -1
          ? parseFloat(totalRetailPrice).toFixed(2)
          : 0
      },
    },
  ]
}

export const ColumnsTransferIn = (
  onClick: (id: number, text: string, data?: any) => void,
  startIndex: number,
  YBUploadcategory?: string
): TableColumnType<any>[] => {
  const layout = {
    width: '14px',
    height: '14x',
    marginRight: '4px',
  }
  let c = []
  const colums: TableColumnType<any>[] = [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '接收库房',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'storehouseName',
      key: 'storehouseName',
      sorter: (a: any, b: any) =>
        a.storehouseName.length - b.storehouseName.length,
    },
    {
      title: '入库业务单号',
      align: 'center',
      width: '12rem',
      ellipsis: true,
      dataIndex: 'putNumber',
      key: 'putNumber',
    },
    {
      title: '出库业务单号',
      align: 'center',
      width: '12rem',
      ellipsis: true,
      dataIndex: 'acceptPutNumber',
      key: 'acceptPutNumber',
    },
    {
      title: '调出库房',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'supplierName',
      key: 'supplierName',
      sorter: (a: any, b: any) => a.supplierName.length - b.supplierName.length,
      render: (_, t) => {
        return t.supplierName ? t.supplierName : t.acceptStorehouseName
      },
    },
    {
      title: '业务部门',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'departmentName',
      key: 'departmentName',
      sorter: (a: any, b: any) =>
        a.departmentName.length - b.departmentName.length,
    },
    {
      title: '业务人员',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'userName',
      key: 'userName',
      sorter: (a: any, b: any) => a.userName.length - b.userName.length,
    },
    {
      title: '是否登账',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'storehouseTag',
      key: 'storehouseTag',
      sorter: (a: any, b: any) => a.storehouseTag - b.storehouseTag,
      render: (storehouseTag: number) => {
        switch (storehouseTag) {
          case 0:
            return '否'
          case 1:
            return <span style={{ color: '#1CD0BD' }}>是</span>
          case 2:
            return '已作废'
          case 3:
            return '已冲正'
          default:
            break
        }
      },
    },
    {
      title: '创建时间',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: (a: any, b: any) =>
        moment(a.createTime).unix() - moment(b.createTime).unix(),
      render: (createTime) => {
        return createTime ? moment(createTime).format(DateTimeFormatSimple) : ''
      },
    },
    {
      title: '登账时间',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      sorter: (a: any, b: any) =>
        moment(a.storehouseTag === 1 ? a.upAccountTime : null).unix() -
        moment(a.storehouseTag === 1 ? b.upAccountTime : null).unix(),
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
          case 3:
            return moment(t.upAccountTime).format(DateTimeFormatSimple)
          default:
            return ''
        }
      },
    },
    {
      title: '总金额（进价）',
      align: 'right',
      width: '9rem',
      ellipsis: true,
      dataIndex: 'totalUnitPrice',
      key: 'totalUnitPrice',
      render: (totalUnitPrice) => {
        return totalUnitPrice > -1 ? parseFloat(totalUnitPrice).toFixed(2) : 0
      },
    },
    {
      title: '总金额（售价）',
      align: 'right',
      width: '9rem',
      ellipsis: true,
      dataIndex: 'totalRetailPrice',
      key: 'totalRetailPrice',
      render: (totalRetailPrice) => {
        return totalRetailPrice > -1
          ? parseFloat(totalRetailPrice).toFixed(2)
          : 0
      },
    },
    {
      title: '操作类型',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'category',
      key: 'category',
      render: (_, t) => {
        return BillName(_)
      },
    },
    {
      title: '操作',
      align: 'center',
      dataIndex: 'storehouseTag',
      key: 'storehouseTag',
      width: '14rem',
      render: (storehouseTag, data) => {
        if (YBUploadcategory) {
          return data.ybUploadFlag === '2' ? (
            '已上传'
          ) : (
            <Popconfirm
              title='确认执行此操作？'
              onConfirm={() => {
                onClick(
                  data.id,
                  storehouseTag === 1 ? '其他上传' : '冲正上传',
                  data
                )
              }}
              okText='确认'
              cancelText='取消'
            >
              <Button type='primary' ghost size='small'>
                {storehouseTag === 1 ? '上传' : '冲正上传'}
              </Button>
            </Popconfirm>
          )
        } else {
          switch (storehouseTag) {
            case 0:
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type='text'
                    size='small'
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <AccountOutlined style={{ fill: '#1CD0BD', ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, '登账')
                    }}
                  >
                    登账
                  </Button>
                  <Button
                    type='text'
                    size='small'
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <DelectOutlined style={{ fill: '#FF5D5D', ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, '作废')
                    }}
                  >
                    作废
                  </Button>
                  <Button
                    type='text'
                    size='small'
                    style={{ fill: '#027AFF', fontSize: '14px' }}
                    onClick={(t: any) => {
                      onClick(data.id, '查看详情')
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              )
            case 1:
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type='text'
                    size='small'
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    icon={
                      <ReverseOutlined style={{ fill: '#F5BB4A', ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, '冲正')
                    }}
                  >
                    冲正
                  </Button>
                  <Button
                    type='text'
                    size='small'
                    style={{ color: '#027AFF', fontSize: '14px' }}
                    onClick={(t: any) => {
                      onClick(data.id, '查看详情')
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              )
            case 2:
            case 3:
              return (
                <div>
                  <Button
                    type='text'
                    size='small'
                    style={{ color: '#027AFF', fontSize: '14px' }}
                    onClick={(t: any) => {
                      onClick(data.id, '查看详情')
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              )
            default:
              break
          }
        }
      },
    },
  ]
  c = !YBUploadcategory ? colums.filter((v) => v.title != '操作类型') : colums
  return c
}

export const PrintColumnsTransferIn = (
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => {
        if (_2.id === 0) {
          return '合计'
        } else {
          return `${startIndex + index + 1}`
        }
      },
    },
    {
      title: '接收库房',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'storehouseName',
      key: 'storehouseName',
      sorter: (a: any, b: any) =>
        a.storehouseName.length - b.storehouseName.length,
    },
    {
      title: '入库业务单号',
      align: 'center',
      width: Dimen.PutNumber,
      dataIndex: 'putNumber',
      key: 'putNumber',
    },
    {
      title: '出库业务单号',
      align: 'center',
      width: Dimen.PutNumber,
      dataIndex: 'acceptPutNumber',
      key: 'acceptPutNumber',
    },
    {
      title: '调出库房',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'supplierName',
      key: 'supplierName',
      render: (_, t) => {
        return t.supplierName ? t.supplierName : t.acceptStorehouseName
      },
    },
    {
      title: '业务部门',
      align: 'center',
      width: Dimen.Department,
      dataIndex: 'departmentName',
      key: 'departmentName',
    },
    {
      title: '业务人员',
      align: 'center',
      width: Dimen.Name,
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '创建时间',
      align: 'center',
      width: Dimen.Time,
      dataIndex: 'createTime',
      key: 'createTime',
      render: (createTime) => {
        return createTime ? moment(createTime).format(DateTimeFormatSimple) : ''
      },
    },
    {
      title: '登账时间',
      align: 'center',
      width: Dimen.Time,
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
            return moment(t.upAccountTime).format(DateTimeFormatSimple)
          default:
            return ''
        }
      },
    },
    {
      title: '总金额（进价）',
      align: 'right',
      width: Dimen.Price,
      dataIndex: 'totalUnitPrice',
      key: 'totalUnitPrice',
      render: (totalUnitPrice) => {
        return totalUnitPrice > -1 ? parseFloat(totalUnitPrice).toFixed(2) : 0
      },
    },
    {
      title: '总金额（售价）',
      align: 'right',
      width: Dimen.Price,
      dataIndex: 'totalRetailPrice',
      key: 'totalRetailPrice',
      render: (totalRetailPrice) => {
        return totalRetailPrice > -1
          ? parseFloat(totalRetailPrice).toFixed(2)
          : 0
      },
    },
  ]
}
