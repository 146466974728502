/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 1373842098@qq.com
 * @Date: 2022-01-12 13:49:58
 * @LastEditors: sj
 * @LastEditTime: 2022-11-21 17:25:58
 */
import { Settinglable } from '../models/setting'
import { TenentSettinglable } from '../models/tenantSetting'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface TenentSettingParams {
  tenant: TenentSettinglable
  functionList: Settinglable[]
}
export function getTenentSettingSave(body: TenentSettingParams): Request {
  return {
    url: `${API_PREFIX}/blade-administer/tenant/function/save`,
    method: 'POST',
    body,
  }
}

export interface TenentSettingParmas {
  tenantId: string
}

export function getSettingDetail(params: TenentSettingParmas): Request {
  return {
    url: `${API_PREFIX}/blade-administer/tenant/function/detail`,
    method: 'POST',
    params,
  }
}

export interface MedicalSettingParams {
  cityHospitalNb: string
  countryHospitalNb: string
  endTime: string
  id: number
  insuranceCode: string
  insuranceName: string
  startTime: string
  status: number
  tenantId: number
  ybIp: string
  ybMac: string
  yidiHospitalNb: string
}

export interface MedicalItemParams {
  id: number
  tenantId: string
}

export function getMedicalSettingList(tenantId: number): Request {
  return {
    url: `${API_PREFIX}/blade-administer/insurance/list`,
    method: 'GET',
    params: {
      tenantId,
    },
  }
}

export function editMedicalSetting(body: MedicalSettingParams): Request {
  return {
    url: `${API_PREFIX}/blade-administer/insurance/submit`,
    method: 'POST',
    body,
  }
}

export function getSystemList(status: number): Request {
  return {
    url: `${API_PREFIX}/blade-administer/insurance/syslist`,
    method: 'GET',
    params: {
      status,
    },
  }
}

export function getMedicalItem(params: MedicalItemParams): Request {
  return {
    url: `${API_PREFIX}/blade-administer/insurance/detail`,
    method: 'GET',
    params,
  }
}
