/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-30 11:14:14
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-18 10:02:04
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
    Cascader,
    Checkbox,
    Form,
    Input,
    Modal,
    ModalProps,
    notification,
    Radio,
    Select,
    Spin,
    TimePicker,
    Tree,
} from 'antd'
import { DataNode } from 'antd/lib/tree'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import styles from "./appointment.module.css"
import { getThirdTenantMenuTree, setThirdTenantMenuTree } from './userSlice'

interface ReplaceMakeSettingModalProps {
    visible?: boolean
    userId?: string
    onOk?: () => void
    onCancel?: () => void
}
export const GrantNewMenus = ({
    onOk,
    onCancel,
    visible,
    userId,
}: ReplaceMakeSettingModalProps): ReactElement => {
    const [form] = Form.useForm()

    const dispatch = useDispatch<RootDispatch>()

    const { Option } = Select

    const [loading, setLoading] = useState(false)

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    }

    const Rules = [
        {
            required: true,
        },
    ]
    interface Option {
        value: string | number;
        label?: React.ReactNode;
        disabled?: boolean;
        children?: Option[];
        // 标记是否为叶子节点，设置了 `loadData` 时有效
        // 设为 `false` 时会强制标记为父节点，即使当前节点没有 children，也会显示展开图标
        isLeaf?: boolean;
    }

    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([0]);
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
    // const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [treeData, setTreeData] = useState<any>([])

    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeysValue: any, e: any) => {
        setCheckedKeys(checkedKeysValue);
        // setSelectedKeys(e.checkedNodes)
    };

    const onSelect = (selectedKeysValue: React.Key[], info: any) => {
        console.log('onSelect', info);
    };

    // 获取所有选中节点的父节点
    const getParentKeys = (treeData: any, key: number) => {
        const parentKeys: any = [];
        const dfs = (nodes: any, parent: any) => {
            nodes.forEach((node: any) => {
                if (node.key === key) {
                    parentKeys.push(parent);
                } else if (node.children) {
                    dfs(node.children, node.key);
                }
            });
        };
        dfs(treeData, null);
        return parentKeys;
    }

    //获取节点的数据
    const getNodeData = (treeData: any, key: number) => {
        let nodeKey: any = {};
        const dfs = (nodes: any) => {
            nodes.forEach((node: any) => {
                if (node.key === key) {
                    nodeKey = node;
                } else if (node.children) {
                    dfs(node.children);
                }
            });
        };
        dfs(treeData);
        return nodeKey;
    }

    useEffect(() => {
        if (!visible) return
        dispatch(getThirdTenantMenuTree({ userId }))
            .then(unwrapResult)
            .then((res: any) => {
                if (res) {
                    const tree = res.map((el: any) => ({
                        title: el.menuName,
                        key: el.id,
                        ...el,
                        children: el?.subMenus?.map((v: any) => ({
                            ...v,
                            title: v.menuName,
                            key: v.id,
                            children: v?.subMenus?.map((n: any) => ({
                                ...n,
                                title: n.menuName,
                                key: n.id,
                                children: n?.subMenus?.map((g: any) => ({
                                    ...g,
                                    title: g.menuName,
                                    key: g.id,
                                }))
                            }))
                        }))
                    }))
                    const data = [
                        {
                            title: '全部',
                            key: 0,
                            children: tree
                        }
                    ]
                    // 默认选中
                    const defaultSelectList: any = []
                    res.forEach((el: any) => {
                        // 一级菜单
                        if (el?.subMenus?.length > 0 && el.status == 1) {
                            el?.subMenus?.forEach((v: any) => {
                                // 二级菜单
                                if (v?.subMenus?.length > 0 && v.status == 1) {
                                    v?.subMenus?.forEach((n: any) => {
                                        // 三级菜单
                                        if (n?.subMenus?.length > 0 && n.status == 1) {
                                            n?.subMenus?.forEach((m: any) => {
                                                // 四级菜单
                                                if (m?.subMenus?.length > 0 && m.status == 1) {
                                                    m?.subMenus?.forEach((g: any) => {
                                                        // 五级菜单
                                                        if (g.status == 1) {
                                                            defaultSelectList.push(g)
                                                        }
                                                    })
                                                } else {
                                                    if (m.status == 1) {
                                                        defaultSelectList.push(m)
                                                    }
                                                }
                                            })
                                        } else {
                                            if (n.status == 1) {
                                                defaultSelectList.push(n)
                                            }
                                        }
                                    })
                                } else {
                                    if (v.status == 1) {
                                        defaultSelectList.push(v)
                                    }
                                }
                            })
                        } else {
                            if (el.status == 1) {
                                defaultSelectList.push(el)
                            }
                        }

                    })
                    console.log(data);
                    
                    setCheckedKeys(defaultSelectList.map((v: any) => v.id))
                    // setSelectedKeys(defaultSelectList)
                    setTreeData(data)
                }

            })
    }, [visible, userId])

    return (
        // 代煎订单详情，只能修改地址
        <Modal
            width={600}
            title='菜单权限'
            visible={visible}
            onCancel={() => {
                form.resetFields()
                setCheckedKeys([])
                setTreeData([])
                onCancel && onCancel()
            }}
            confirmLoading={loading}
            onOk={() => {
                form.submit()
            }}
            // footer={null}
            maskClosable={false}
        >   
            {/* <Spin spinning={loading}> */}
                <Form
                    form={form}
                    style={{ height: '600px', overflow: 'auto' }}
                    {...layout}
                    onFinish={(values) => {
                        setLoading(true)
                        let checkIds: any = []
                        checkedKeys?.forEach((v: any) => {
                            checkIds.push(...getParentKeys(treeData, v))
                        })
                        checkIds = Array.from(new Set(checkIds.concat(checkedKeys)))?.filter(v => !!v)
                        let newCheckIds: any = []
                        checkIds?.forEach((v: any) => {
                            newCheckIds.push(...getParentKeys(treeData, v))
                        })
                        newCheckIds = Array.from(new Set(newCheckIds.concat(checkIds)))?.filter(v => !!v)
                        let finailyCheckIds: any = []
                        newCheckIds?.forEach((v: any) => {
                            finailyCheckIds.push(...getParentKeys(treeData, v))
                        })
                        finailyCheckIds = Array.from(new Set(finailyCheckIds.concat(newCheckIds)))?.filter(v => !!v)
                        const data = finailyCheckIds?.map((v: any) => {
                            const node = getNodeData(treeData, v)
                            return {
                                level: node?.level,
                                menuId: node?.menuId
                            }
                        })
                        dispatch(setThirdTenantMenuTree({ userId, menuList: data }))
                            .then(unwrapResult)
                            .then(() => {
                                setLoading(false)
                                notification.success({
                                    message: '操作成功' 
                                })
                                form.resetFields()
                                onOk && onOk()
                            })
                    }}
                >
                    <Form.Item name='openDoctor' label=''>
                        {Array.isArray(treeData) && treeData.length > 0 && (
                            <Tree
                            checkable
                            onExpand={onExpand}
                            defaultExpandAll={true}
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                            // onSelect={onSelect}
                            // selectedKeys={selectedKeys}
                            treeData={treeData}
                        />)}
                    </Form.Item>
                </Form>
            {/* </Spin> */}
        </Modal>
    )
}
