/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-12 10:42:52
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-18 17:29:03
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, message, notification, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { Columns } from './columns'
import styles from '../transactionQueryTab.module.css'
import { Query } from './Query'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getDateRange, DateRangeType } from '../../../compnents/form/DateSelect'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import {
  getTransactionDetailQueryExport,
  getTransactionDetailQueryList,
  setExportTag,
} from '../transactionQuerySlice'
import { sum } from 'lodash'
import { selectEdition } from '../../../app/applicationSlice'
const exportXLSXList: any[] = []

interface TransactionDetailQueryProps {
  selectedTab?: any
  flag?: any
}

export interface TransactionDetailQueryRef {
  reload: () => void
}

const TransactionDetailQuery = (
  { selectedTab, flag }: TransactionDetailQueryProps,
  ref: React.Ref<TransactionDetailQueryRef>
): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const [scrollHeight, setScrollHeight] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    registrationTimeHead: moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
    registrationTimeTail: moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
  })

  const [ids, setIds] = useState<number[]>([])

  const [page, setPage] = useState<any>()

  const [allPage, setAllPage] = useState<any>()

  const [totalData, setTotalData] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  useEffect(() => {
    if (selectedTab == '2') {
      setPageLoading(true)
      if (params?.registrationTimeHead && params?.registrationTimeTail) {
        getPage()
      }

      // dispatch(
      //   getTransactionDetailQueryList({
      //     ...params,
      //     size: -1,
      //     selectedTab: undefined,
      //   })
      // )
      //   .then(unwrapResult)
      //   .then((v: any) => {
      //     setAllPage(v?.records)
      //   })
    }
  }, [selectedTab, params])

  const getPage = () => {
    dispatch(getTransactionDetailQueryList(params))
      .then(unwrapResult)
      .then((v: any) => {
        onResize() // 初始化设置高度
        window.addEventListener('resize', onResize)
        setPage({
          items: v?.records,
          size: v?.size,
          total: v?.total,
          current: v?.current,
        })
        setPageLoading(false)
      })
  }

  // 计算合计数据
  useEffect(() => {
    // if (ids?.length) {
    //   if (allPage?.length) {
    //     // 过滤出的包含勾选的所有数据
    //     const filterList: any = []
    //     ids.forEach((item: any) => {
    //       allPage.forEach((element: any) => {
    //         if (item == element.paymenItemtId) {
    //           filterList.push(element)
    //         }
    //       })
    //     })
    //     // 计算合计
    //     const drugAmountAll = sum(
    //       filterList?.map((v: any) =>
    //         v?.drugAmount > -1 ? Number(v?.drugAmount) : 0
    //       )
    //     )
    //     setTotalData({
    //       drugAmountAll: drugAmountAll,
    //     })
    //   }
    // } else {
    const data = page?.items
    if (data?.length) {
      // 没勾选的情况下计算全部
      const drugAmountAll = sum(
        data?.map((v: any) => (v?.drugAmount > -1 ? Number(v?.drugAmount) : 0))
      )
      setTotalData({
        drugAmountAll: drugAmountAll,
      })
    } else {
      setTotalData({
        drugAmountAll: 0,
      })
    }
    // }
  }, [page])

  useEffect(() => {
    dispatch(setExportTag(ids?.length ? true : false))
  }, [ids])

  useImperativeHandle(ref, () => ({
    reload: () => {
      outCluedExport()
    },
  }))

  const outCluedExport = () => {
    // const theader = [
    //   '结算编号',
    //   '患者姓名',
    //   '病历号',
    //   '手机号',
    //   '结算险种',
    //   '开票项目',
    //   '药品名称',
    //   '单价',
    //   '数量',
    //   '金额',
    //   '接诊医生',
    //   '收费时间',
    //   '结算状态',
    // ]

    // const filterVal = [
    //   'paymentId',
    //   'patientName',
    //   'patientId',
    //   'patientPhone',
    //   'insuranceCode',
    //   'billingCategory',
    //   'drugName',
    //   'retailPrice',
    //   'drugCount',
    //   'drugAmount',
    //   'treatmentDoctorName',
    //   'chargeOrRefundTime',
    //   'state',
    // ]
    // exportXLSXList = exportXLSXList?.map((item) => {
    //   return {
    //     ...item,
    //     paymentId: item?.paymentId ? item?.paymentId : '-',
    //     patientName: item?.patientName ? item?.patientName : '-',
    //     patientId: item?.patientId > -1 ? item?.patientId : '-',
    //     patientPhone: item?.patientPhone ? item?.patientPhone : '-',
    //     insuranceCode:
    //       item?.insuranceCode == 'ZIFEI' || !item?.insuranceCode
    //         ? '自费'
    //         : '智慧医保',
    //     billingCategory: item?.billingCategory
    //       ? BillingCategoryName(item?.billingCategory)
    //       : '-',
    //     drugName: item?.drugName ? item?.drugName : '-',
    //     retailPrice:
    //       Number(item?.retailPrice) > 0 ? item?.retailPrice.toFixed(2) : '-',

    //     drugCount: Number(item?.drugCount) > 0 ? item?.drugCount : '-',
    //     drugAmount:
    //       Number(item?.drugAmount) > 0 ? item?.drugAmount.toFixed(2) : '-',

    //     treatmentDoctorName: item?.treatmentDoctorName
    //       ? item?.treatmentDoctorName
    //       : '-',
    //     chargeOrRefundTime: item?.chargeOrRefundTime
    //       ? moment(item?.chargeOrRefundTime)?.format(DateTimeFormatSimple)
    //       : '-',
    //     state: item?.state ? getTransactionQueryName(Number(item?.state)) : '-',
    //   }
    // })
    // const data = exportXLSXList?.slice(0, 500)
    // const value =
    //   exportXLSXList?.length >= 500
    //     ? data?.map
    //     : exportXLSXList?.map((v: any) => filterVal?.map((j: any) => v[j]))
    // import('../../../ExportXLSX/Export2Excel').then((excel) => {
    //   excel.export_json_to_excel({
    //     header: theader,
    //     data: value,
    //     filename: '交易明细查询',
    //     autoWidth: true,
    //     bookType: 'xlsx',
    //   })
    //   setIds([])
    //   setParams({
    //     ...params,
    //     current: 1,
    //   })
    //   exportXLSXList = []
    // })
    dispatch(getTransactionDetailQueryExport({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '交易明细查询.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          return (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        notification.success({
          message: '导出失败',
          duration: 3,
        })
      })
  }

  // useEffect(() => {
  //   if (exportXLSXList?.length >= 500) {
  //     message.info('最多可导出500条')
  //   }
  // }, [exportXLSXList])

  const onResize = () => {
    const table = document.getElementById('tableHeight')
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 20
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }

  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)',
        marginTop: flag ? 0 : '10px',
        overflow: 'hidden',
        padding: flag ? 0 : '10px 15px 0 15px',
      }}
    >
      <Query
        onValueChange={(v: any) => {
          setParams({
            ...params,
            ...v,
            registrationTimeHead: v?.timeHead
              ? moment(v?.timeHead).format(DateTimeFormatSimpleOnlyOne)
              : moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
            registrationTimeTail: v?.timeTail
              ? moment(v?.timeTail).format(DateTimeFormatSimpleOnlyOne)
              : moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
            startTime: undefined,
            endTime: undefined,
          })
        }}
        selectedTab={selectedTab}
      />
      <div
        id='tableHeight'
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <EditableList
          scroll={{
            x: (hisVersion == 1 && 700) as any,
            y: Number(scrollHeight),
          }}
          style={{
            width: '100%',
            height: '89%',
            overflow: 'hidden',
          }}
          rowKey={(m: any) => m?.paymenItemtId}
          loading={pageLoading}
          page={page}
          columns={Columns(page?.size * (page?.current - 1))}
          // rowSelection={{
          //   selectedRowKeys: ids,
          //   onSelect: (record: any, selected: boolean, row: DataType[]) => {
          //     if (selected) {
          //       exportXLSXList.push(record)
          //     } else {
          //       exportXLSXList = exportXLSXList.filter(
          //         (item) => item.paymenItemtId != record.paymenItemtId
          //       )
          //     }
          //     exportXLSXList = [...(new Set(exportXLSXList) as any)]
          //     setIds(exportXLSXList.map((item) => item.paymenItemtId))
          //   },
          //   onSelectAll: (selected: boolean, rows: any, changeRows: any) => {
          //     if (selected) {
          //       exportXLSXList.push(...changeRows)
          //     } else {
          //       changeRows.forEach((row: any) => {
          //         exportXLSXList = exportXLSXList.filter(
          //           (item) => item.paymenItemtId !== row.paymenItemtId
          //         )
          //       })
          //     }
          //     exportXLSXList = [...(new Set(exportXLSXList) as any)]
          //     setIds(exportXLSXList?.map((item) => item.paymenItemtId))
          //   },
          // }}
          onChangePage={(current, size) =>
            setParams({
              ...params,
              current,
              size,
            })
          }
          // onRow={(record) => ({
          //   onDoubleClick: () => tableDoubleFn(record),
          // })}
          // sticky
          summary={() => (
            <Table.Summary fixed='bottom'>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell
                  index={0}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={1}
                  align='center'
                  className={styles.fixed}
                >
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={3}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={4}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={5}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={6}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={7}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={8}
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={9}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={10}
                  align='right'
                >
                  {totalData?.drugAmountAll?.toFixed(2)}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={11}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={12}
                  align='right'
                  className={styles.fixed}
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={13}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={14}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={15}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={16}
                  align='right'
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  className={styles.fixed}
                  index={17}
                  align='right'
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </div>
    </Col>
  )
}

export const TransactionDetailQuerys = forwardRef(TransactionDetailQuery)
