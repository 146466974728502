/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-16 18:06:31
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-13 10:13:58
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, message, notification, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { ThemeContext } from '../../../theme/ThemeContext'
import { Query } from './Query'
import {
  getAdlopuuploadAsync,
  getMedicalRecordListAsync,
  getUploadRequestStringAsync,
} from './MedicalRecordSlice'
import { serverType } from '../../../services/commodity'
import { Columns } from './columns'
import { MbfeService, medicalSendHttp } from '../../../utils/MedicalUtils'
import { TableRowSelection } from 'antd/lib/table/interface'
import { DataType } from '../../putstorage/putstorage'

export interface MedicalRecordRef {
  reload: () => void
}

interface MedicalRecordProps {
  selectedTab?: any
}

const RealMedicalRecords = (
  { selectedTab }: MedicalRecordProps,
  ref: React.Ref<MedicalRecordRef>
): ReactElement => {
  const theme = useContext(ThemeContext)
  const [buttonLoading, setButtonLoading] = useState(false)

  const [disabled, setDisabled] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({ current: 0, size: 10 })

  const [page, setPage] = useState<any>({ items: [], total: 0 })

  const [pageLoading, setPageLoading] = useState(false)

  const [selectedKeys, setSelectedKeys] = useState<any>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedKeys(selectedRowKeys)
    },
  }

  useEffect(() => {
    if (params.current !== 0) {
      getPageList()
    }
  }, [params])

  const getPageList = () => {
    setPageLoading(true)
    dispatch(
      getMedicalRecordListAsync({
        ...params,
        state: 3,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    params.current &&
      sessionStorage.setItem(location.pathname, JSON.stringify({ ...params }))
  }, [params])

  // 单条前端上传
  const selfPayUploadSingle = (
    index?: any,
    v?: any,
    serverType?: serverType,
    responseString?: string
  ) => {
    setDisabled(true)
    dispatch(
      getUploadRequestStringAsync({
        serverType,
        treatmentId: v?.treatment?.id,
        responseString: serverType == 'response' ? responseString : null,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          MbfeService(
            'SUPERVISE_UPLOAD',
            {
              trade_args: ['SUPERVISE', res],
              message: '正在上传第：' + index + '条',
            },
            (call: any, err) => {
              if (!err) {
                selfPayUploadSingle(
                  index,
                  v,
                  'response',
                  JSON.stringify(call?.netmessage)
                )
              } else {
                setDisabled(false)
                getPageList()
              }
            }
          )
        } else {
          setDisabled(false)
          notification.success({
            message: '上传成功',
          })
          getPageList()
        }
      })
      .catch((e) => {
        notification.error({
          message: e?.message,
          // message: ' ' + err,
          duration: 3,
        })
        setDisabled(false)
      })
  }

  // 前端上传
  const selfPayUpload = (
    index?: any,
    v?: any,
    serverType?: serverType,
    responseString?: string
  ) => {
    setDisabled(true)
    dispatch(
      getUploadRequestStringAsync({
        serverType,
        treatmentId: v?.treatment?.id,
        responseString: serverType == 'response' ? responseString : null,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          MbfeService(
            'SUPERVISE_UPLOAD',
            {
              trade_args: ['SUPERVISE', res],
              message: '正在上传第：' + index + '条',
            },
            (call: any, err) => {
              if (!err) {
                selfPayUpload(
                  index,
                  v,
                  'response',
                  JSON.stringify(call?.netmessage)
                )
              } else {
                setDisabled(false)
              }
            }
          )
        } else {
          setDisabled(false)
          getPageList()
          notification.success({
            message: '上传成功',
          })
        }
      })
      .catch(() => {
        setDisabled(false)
      })
  }

  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)',
        marginTop: '10px',
        overflow: 'hidden',
        padding: '10px 15px 0 15px',
      }}
    >
      <Query
        state={3}
        onValueChange={(v) => {
          setParams({
            ...params,
            ...v,
            payMethod: null,
          })
        }}
        buttonLoading={buttonLoading}
        allUpload={async () => {
          const isFront = page.items?.[0]?.isFront
          if (selectedKeys.length <= 0) {
            notification.error({
              message: '请至少选择一条未上传的明细进行上传',
            })
            return
          }
          setButtonLoading(true)
          if (isFront == 1) {
            let uploadFlag = false
            for (let i = 0; i < selectedKeys.length; i++) {
              const b = selectedKeys?.[i]
              const data = page.items?.find((v: any) => b == v?.paymentId)
              if (data?.registration?.wjUploadFlag != 1) {
                await new Promise((resolve) => setTimeout(resolve, 1000))
                await selfPayUpload(i + 1, data, 'requestString')
                uploadFlag = true
              }
            }
            if (!uploadFlag) {
              notification.error({
                message: '请至少选择一条未上传的明细进行上传',
              })
              setButtonLoading(false)
              return
            }
            setButtonLoading(false)
          } else {
            // 后端上传
            // selectedKeys?.map((b: any) => {
            //   const data = page.items?.find((v: any) => b == v?.paymentId)
            dispatch(
              getAdlopuuploadAsync({
                treatmentId: selectedKeys,
              })
            )
              .then(unwrapResult)
              .then((v) => {
                notification.success({
                  message: '上传成功',
                })
                setButtonLoading(false)
              })
              .catch(() => {
                setButtonLoading(false)
              })
            // })
          }
          if (params.current != 0) {
            getPageList()
          }
        }}
      />
      <EditableList
        // style={{ overflow: 'hidden' }}
        style={{ flex: 1 }}
        scroll={{ x: '30rem' }}
        loading={pageLoading}
        page={{
          items: page.items,
          current: params.current,
          size: params.size,
          total: page.total,
        }}
        rowSelection={
          {
            selectedRowKeys: selectedKeys,
            ...rowSelection,
          } as TableRowSelection<any>
        }
        rowKey={(m: any) => m.paymentId}
        columns={Columns(
          params.size * (params.current - 1),
          disabled,
          (action, t) => {
            switch (action) {
              case 'upload':
                // 前端
                if (t?.isFront == 1) {
                  selfPayUploadSingle(1, t, 'requestString')
                } else {
                  // 后端上传
                  dispatch(
                    getAdlopuuploadAsync({
                      treatmentId: t?.treatment?.id,
                    })
                  )
                    .then(unwrapResult)
                    .then((v) => {
                      notification.success({
                        message: '上传成功',
                      })
                      getPageList()
                    })
                }

                break
            }
          }
        )}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size: size || 10 })
        }
        sticky
      />
    </Col>
  )
}

export const MedicalRecords = forwardRef(RealMedicalRecords)
