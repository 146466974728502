import { Col, Modal, Button, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Durgsystem.module.css";
import { columns } from "./columns";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import {
  pageDurgsystem,
  selectTotal,
  selectDurgsystemData,
  logoutOrEnableDurgSystem,
  selectDurgState,
  setSelectId,
  selectCurrent,
  setCurrent,
  selectPageLoading,
  setPageLoading,
} from "./durgsystemSlice";
import { DurgModal } from "./modal";
import { Durgsystemquery } from "./query";
import { PermissionsFrom } from "../permissionsFrom/PermissionsFrom";
import { EditableList } from "../../compnents/list/EditableList";

export const Durgsystem = () => {
  const State = useSelector(selectDurgState);

  const current = useSelector(selectCurrent);

  const [size, setSize] = useState(10);

  const [isVisible, setIsVisible] = useState(false);

  const [storehouseId, setStorehouseId] = useState(0);

  const [storehouseName, setStorehouseName] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const pageLoadingRX = useSelector(selectPageLoading);

  const dispatch = useDispatch();
  function getpage() {
    dispatch(setPageLoading(true))
    dispatch(pageDurgsystem(current, size, State))
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }
  useEffect(() => {
    getpage();
  }, [current, size]);
  const total = useSelector(selectTotal);
  const data = useSelector(selectDurgsystemData);
  return (
    <div className={styles.durg}>
      <Row>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginTop: "10px" }}
          onClick={() => setIsVisible(true)}
        >
          新增药房
        </Button>
      </Row>
      <div
        style={{
          height: "calc(100% - 60px)",
          margin: "10px 0px",
          padding: "20px",
          background: "#fff",
          borderRadius: "10px",
        }}
      >
        <Durgsystemquery />
        <EditableList
          style={{ height: "calc(100% - 105px)" }}
          loading={pageLoadingRX}
          className={styles.durgsystemtable}
          page={{
            items: data,
            current,
            size,
            total,
          }}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number);
            dispatch(setCurrent(current));
          }}
          columns={columns((text: string, t: any) => {
            if (text === "finish") {
              dispatch(logoutOrEnableDurgSystem(t.id));
            } else if (text === "edit") {
              setIsVisible(true);
              dispatch(setSelectId(t));
            } else if (text === "Permissions") {
              setIsModalVisible(true);
              setStorehouseId(t.id);
              setStorehouseName(t.name);
            }
          }, size * (current - 1))}
          onRow={(d: any) => ({
            onDoubleClick: () => {
              dispatch(setSelectId(d.id));
              setIsVisible(true);
            },
          })}
          bordered={false}
        />
      </div>
      <DurgModal
        visible={isVisible}
        onOk={() => {
          setIsVisible(false);
        }}
        onCancel={() => {
          setIsVisible(false);
        }}
      />
      <Modal
        title={storehouseName + "药房权限设置"}
        visible={isModalVisible}
        okText="确认"
        cancelText="取消"
        className={styles.permissionform}
        bodyStyle={{ height: "40rem" }}
        onOk={() => {
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        footer={null}
      >
        <PermissionsFrom
          id={storehouseId}
          name={storehouseName}
          onOk={() => {
            setIsModalVisible(false);
          }}
          onCancel={() => {
            setIsModalVisible(false);
          }}
        />
      </Modal>
    </div>
  );
};
