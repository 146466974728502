import { CaretDownFilled, PlusOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { DeleteOutlined } from '../../compnents/icons/Icons'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import { traceRoute } from '../../layouts/layoutSlice'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
import { GetCarryOverListParams } from '../../services/carryover'
import { ThemeContext } from '../../theme/ThemeContext'
import { sleep } from '../../utils/SystemUtils'
import { getFullStorageHouseList as getFullStoragehouseList } from '../completion/completionSlice'
import {
  createCarryOver,
  getCarryOverList,
  removeCarryOver,
  selectCarryOverPage,
  getCarryOverListExoprt,
} from './carryOverSlice'

export const CarryOver = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const page = useSelector(selectCarryOverPage)

  const [form] = Form.useForm()

  const [newForm] = Form.useForm()

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const { RangePicker } = DatePicker

  const [storehouses, setStorehouses] = useState<any[]>([])

  const [params, setParams] = useState<GetCarryOverListParams>({
    current: 1,
    timeHead: head,
    timeTail: tail,
  })

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [cell, setCell] = useState<any>({}) //合计栏

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const disabledDate = (timeTail: any) => {
    return timeTail > moment().endOf('day')
  }

  useEffect(() => {
    dispatch(getFullStoragehouseList())
      .then(unwrapResult)
      .then((ss) => {
        setStorehouses(ss)
        if (ss.length > 0) {
          setParams({ ...params, storehouseId: ss[0].id })
          form.setFieldsValue({
            ...params,
            storehouseId: ss[0].id,
          })
        }
      })
      .catch(() => {
        //
      })
  }, [])
  useEffect(() => {
    if (page.items.length == 0) return setCell({})

    const tempArr = page.items.filter((item: any) => {
      return item.totalIncomePrice != -1
    })

    setCell(tempArr[0])
  }, [page])
  useEffect(() => {
    if (params.storehouseId) {
      setPageLoading(true)
      dispatch(getCarryOverList(params)).finally(() => setPageLoading(false))
    }
  }, [params])

  const outCluedExport = () => {
    dispatch(getCarryOverListExoprt({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '库房结转.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        notification.success({
          message: '导出失败',
          duration: 3,
        })
      })
  }
  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        margin: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <NavBar
        where={['库房业务', '库房结转']}
        backtrace={{
          name: '库房业务',
          path: '/storage/station',
          state: {
            station: '3',
          },
          routeName: '库房工作站',
        }}
        buttons={
          <Button
            icon={<PlusOutlined />}
            type='primary'
            onClick={() => setIsModalVisible(true)}
            size='small'
          >
            添加
          </Button>
        }
      />
      <Col
        style={{
          flex: 1,
          marginTop: 10,
          borderRadius: theme.br,
          backgroundColor: theme.pn,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          initialValues={{
            timeHead: head,
            timeTail: tail,
            [DateRangeFieldName]: DateRangeType.ThisMonth,
          }}
          onFinish={(vs) => {
            setParams({ ...params, ...vs })
          }}
        >
          <Row
            style={{ margin: '20px 10px' }}
            align='middle'
            justify='space-between'
          >
            <Space>
              <Form.Item noStyle name='storehouseId'>
                <Select
                  style={{ width: 220 }}
                  // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                  onChange={form.submit}
                >
                  {storehouses.map((s) => (
                    <Select.Option key={s.id} value={s.id}>
                      {s.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <DateSelect
                namePrefix='time'
                labelPrefix=''
                placeholder='时间范围'
                style={{ width: 135 }}
                onChange={form.submit}
                allowClear={false}
              />
            </Space>
            <Col>
              <Button
                style={{ marginRight: '10px' }}
                type='primary'
                onClick={form.submit}
              >
                查询
              </Button>
              <Button type='primary' onClick={() => outCluedExport()}>
                导出
              </Button>
            </Col>
          </Row>
        </Form>
        <EditableList
          style={{ flex: 1, margin: '0 10px' }}
          loading={pageLoading}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>合计</Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={4}>
                  {cell.totalIncomePrice}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={5}>
                  {cell.totalExpendPrice}
                </Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={6}>
                  {cell.totalCarryOverPrice}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          page={page}
          onRow={(r) => ({
            onDoubleClick: () =>
              dispatch(
                traceRoute({
                  name: '库房工作站',
                  path: '/carryover/detail',
                  query: `id=${r.id}`,
                })
              ),
          })}
          onChangePage={(current, size) => {
            setParams({ ...params, current, size })
          }}
          columns={[
            {
              title: '序号',
              key: 'no',
              align: 'center',
              width: '3rem',
              render: (_1, _2, i) =>
                `${page.size * (page.current - 1) + i + 1}`,
            },
            {
              title: '库房名称',
              dataIndex: 'storehouseName',
              width: '10rem',
            },
            {
              title: '起始时间',
              dataIndex: 'startTime',
              width: '10rem',
            },
            {
              title: '终止时间',
              dataIndex: 'endTime',
              width: '10rem',
            },
            {
              title: '收入金额',
              key: 'incomePrice',
              width: '5rem',
              align: 'right',
              render: (_, r) => r.incomePrice?.toFixed(2),
            },
            {
              title: '支出金额',
              key: 'expendPrice',
              width: '5rem',
              align: 'right',
              render: (_, r) => r.expendPrice?.toFixed(2),
            },
            {
              title: '结转金额',
              key: 'carryOverPrice',
              width: '5rem',
              align: 'right',
              render: (_, r) => r.carryOverPrice?.toFixed(2),
            },
            {
              title: '操作',
              key: 'actions',
              width: '6rem',
              align: 'center',
              render: function ShowActions(_, r) {
                if (r.state === 1) {
                  return (
                    <a
                      onClick={() =>
                        dispatch(
                          traceRoute({
                            name: '库房工作站',
                            path: '/carryover/detail',
                            query: `id=${r.id}`,
                          })
                        )
                      }
                    >
                      查看详情
                    </a>
                  )
                }
                return (
                  <Space>
                    <a
                      onClick={() =>
                        dispatch(
                          traceRoute({
                            name: '库房工作站',
                            path: '/carryover/detail',
                            query: `id=${r.id}`,
                          })
                        )
                      }
                    >
                      查看详情
                    </a>
                    <Row
                      align='middle'
                      wrap={false}
                      style={{ cursor: 'pointer', color: theme.tc2 }}
                      onClick={() =>
                        Modal.confirm({
                          title: '警告',
                          content: '确认删除该条结转？',
                          onOk: async () => {
                            await dispatch(removeCarryOver(r.id))
                            dispatch(getCarryOverList(params))
                          },
                        })
                      }
                    >
                      <DeleteOutlined
                        mode='small'
                        style={{
                          fill: theme.e,
                          marginRight: 4,
                          width: '14px',
                          height: '14px',
                        }}
                      />
                      删除
                    </Row>
                  </Space>
                )
              },
            },
          ]}
        />
      </Col>
      <Modal
        title='添加结转记录'
        visible={isModalVisible}
        confirmLoading={isLoading}
        onCancel={() => {
          setIsModalVisible(false)
          newForm.resetFields()
        }}
        onOk={async () => {
          try {
            setIsLoading(true)
            const values = await newForm.validateFields()
            const storehouseName = storehouses.find(
              (s) => s.id === params.storehouseId
            )?.name
            const timestamp = moment()
            await dispatch(
              createCarryOver({
                startTime: values.range.format(DateTimeFormat),
                endTime: moment().endOf('days').format(DateTimeFormat),
                storehouseId: params.storehouseId,
                storehouseName,
              })
            ).then(unwrapResult)
            const duration = moment
              .duration(moment().diff(timestamp))
              .milliseconds()
            if (duration < 1000) {
              await sleep(1000 - duration)
            }
            setIsModalVisible(false)
            newForm.resetFields()
            dispatch(getCarryOverList(params))
          } catch {
            // do nothing
          } finally {
            setIsLoading(false)
          }
        }}
      >
        <Form form={newForm}>
          <Row align='middle'>
            <Col
              style={{ color: theme.tc1, fontSize: '1rem', marginRight: 20 }}
            >
              结转起始日期：
            </Col>
            <Form.Item
              required={true}
              noStyle
              rules={[{ required: true }]}
              name='range'
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </Col>
  )
}
