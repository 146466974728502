/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-30 09:27:19
 * @LastEditors: linxi
 * @LastEditTime: 2021-06-09 19:56:43
 */
import { Form, Input, Button, Row, Col } from "antd";
import React, { useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { QingdouParams } from "../../services/qingdou";

export const ModalQuery = (props: {
  value?: QingdouParams;
  onValueChange: (queries: QingdouParams) => void;
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.value);
  }, [props.value]);

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={(values) => {
          props.onValueChange(values)
      }}
    >
      <Row gutter={11}>
        <Col span={6}>
          <Form.Item name="name">
            <Input
              placeholder="请输入助记码和商品名称"
              allowClear
              onChange={() => form.submit()}
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name="count"
            rules={[
              {
                pattern: new RegExp(/^[0-9]\d*$/, "g"),
                message: "请输入数字",
              },
            ]}
          >
            <Input
              onChange={() => form.submit()}
              style={{
                padding: 0,
                height: "32px",
              }}
              prefix={
                <span
                  style={{
                    display: "inline-block",
                    height: "34px",
                    padding: "13px 12px",
                    lineHeight: "8px",
                    background: "#F4F8FB",
                    marginLeft: "-1px",
                    marginRight:"7px"
                  }}
                >
                  库存数量  :
                </span>
              }
            />
          </Form.Item>
        </Col>
        <Col span={5} offset={8}>
          <Button type="primary" style={{ float: "right" }}  onClick={() => props.onValueChange(form.getFieldsValue())}>
            查询
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
