/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-09 14:41:36
 */
import { RecipeStatus } from './recipe'
import * as R from './registration'
import { Registration } from './registration'
import * as T from './treatment'
import { Treatment } from './treatment'
import bankCardIcon from '../features/payment/confirm/assets/bankCardIcon.png'
import dyIcon from '../features/payment/confirm/assets/dyIcon.png'
import dzdpIcon from '../features/payment/confirm/assets/dzdpIcon.png'
import elmIcon from '../features/payment/confirm/assets/elmIcon.png'
import hykfxIcon from '../features/payment/confirm/assets/hykfxIcon.png'
import kjlIcon from '../features/payment/confirm/assets/kjlIcon.png'
import mtIcon from '../features/payment/confirm/assets/mtIcon.png'
import mtISmallcon from '../features/payment/confirm/assets/mtISmallcon.png'
import tbIcon from '../features/payment/confirm/assets/tbIcon.png'
import upsizedIcon from '../features/payment/confirm/assets/upsizedIcon.png'
import xhsIcon from '../features/payment/confirm/assets/xhsIcon.png'
import xykIcon from '../features/payment/confirm/assets/xykIcon.png'
import ybIcon from '../features/payment/confirm/assets/ybIcon.png'
import yhqIcon from '../features/payment/confirm/assets/yhqIcon.png'
import yhqTwoIcon from '../features/payment/confirm/assets/yhqTwoIcon.png'

export type PaymentStatus =
  | RecipeStatus.ToSettle
  | RecipeStatus.Settled
  | RecipeStatus.Returned


  export interface ChargeSetting {
    id?: string
    isDeleted?: any
    methodIcon?: any
    methodName?: any
    status?: any
  }
export interface Payment {
  id: string
  amount: number
  actualAmountOfArrears: number
  amountInArrears: number
  arrearageAmountSum: number
  settlementCategory: number
  insurancePayAmount: number
  shouldPayAmount: number
  potionRecipeStatus: number
  recipeId: number
  registration: Registration
  treatment: Treatment
  // records: Records
  totalPrice?: number
  totalReimbursementAmount?: number
  totalcashAmount?: number
  accountingStatus?: number
  realPayAmount?: number
  totalRealPayAmount?: number
  payMethods?: number
  patientId?: number
  labels?: any
  invoiceDetails?: any
  arrearageAmount?: number
  paymentInsuranceCode?: string
  payMethodName?:any
}

export interface PaymentRegistrationParams {
  realPayAmount?: string
  receivePayAmount?: string
  payMethod?: any
  recipeIdList?: any
  success?: string
  systemPayMethod ?: any
  shouldPayAmount?: string
  payCode ?: any
  paymentData?:any
}

export interface otherPatientInsuranceItemParams {
  dbbcqflj?: number
  dnqfxljPt?: number
  dnqfxljMb?: number
}

// XJ("01", "现金"), ZFB("02", "支付宝"), WX("03", "微信"), DWJZ("04", "单位记账"),
// YL("05", "银联"), HYKKK("06", "会员卡扣款"), JHZF("07", "聚合支付")
// ,POSD("08","智能POSD支付"),QT("99", "其他"),SFKZF("98","市民卡支付");
export enum PayMethod {
  Unknown = 0,
  Cash = 1,
  Alipay = 3,
  Weipay = 4,
  UnionPay = 5,
  Juhe = 2,
  HY = 6,
  YB = 99,
}

export const PayMethods = [
  PayMethod.Cash,
  PayMethod.Alipay,
  PayMethod.Weipay,
  PayMethod.UnionPay,
  PayMethod.Juhe,
  PayMethod.HY,
]

export function getPayMethodName(method: PayMethod): string {
  switch (method) {
    case PayMethod.Cash:
      return '现金'
    case PayMethod.Alipay:
      return '支付宝'
    case PayMethod.Weipay:
      return '微信'
    case PayMethod.UnionPay:
      return '银联'
    case PayMethod.Juhe:
      return '聚合支付'
    case PayMethod.HY:
      return '会员'
    case PayMethod.YB:
      return '医保'
  }
  return ''
}

export function getPayFlagName(type = -1): string {
  switch (type) {
    case 0:
      return '门诊'
    case 1:
      return '挂号'
    case 2:
      return '门诊药品零售'
    case 3:
      return '药店药品零售'
    default:
      return '-'
  }
}

export function fromJson(
  data: { paymentId?: number } & Record<string, never>
): Payment {
  const id = data.paymentId?.toString() || ''
  const potionRecipeStatus = data.potionRecipeStatus
  const registration = R.fromJson(data.registration)
  const treatment = T.fromJson(data.treatment)
  return {
    id: id ? id : registration.id,
    potionRecipeStatus,
    actualAmountOfArrears: data.actualAmountOfArrears,
    amountInArrears: data.amountInArrears,
    amount: data.amount,
    arrearageAmountSum: data.arrearageAmountSum,
    recipeId: data.recipeId,
    insurancePayAmount: data.insurancePayAmount,
    shouldPayAmount: data.shouldPayAmount,
    registration,
    settlementCategory: data.settlementCategory,
    treatment,
    totalPrice: data?.totalPrice,
    totalReimbursementAmount: data?.totalReimbursementAmount,
    totalcashAmount: data?.totalcashAmount,
    accountingStatus: data?.accountingStatus,
    realPayAmount: data?.realPayAmount,
    totalRealPayAmount: data?.totalRealPayAmount,
    payMethods: data?.payMethods,
    patientId: data?.patientId,
    labels: data?.labels,
    invoiceDetails: data?.invoiceDetails || {},
    arrearageAmount: data?.arrearageAmount || 0,
    paymentInsuranceCode: data?.paymentInsuranceCode,
    payMethodName: data?.payMethodName,
  }
}
// 获取票据对账状态
export function getAccountStatus(status: number): string {
  switch (Number(status)) {
    case 1:
      return '已对账'
    default:
      return '未对账'
  }
}
// 获取票据状态
export function getInvoiceStatus(status: number): string {
  switch (Number(status)) {
    case 0:
      return '已申请开票'
    case 1:
      return '开票成功'
    case -1:
      return '开票失败'
    case 5:
      return '作废'
    case 8:
      return '已申请红冲'
    case 9:
      return '红冲成功'
    case -9:
      return '红冲失败'
    case 11:
      return '正在申请红冲'
    case 12:
      return '红冲申请已提交'
    case 98:
      return '已对账'
    case 99:
      return '未对账'
    default:
      return '-'
  }
}

// 获取票据类型
export function getInvoiceType(status: number): string {
  switch (Number(status)) {
    case 0:
      return '纸质发票'
    case 1:
      return '电子发票'
    default:
      return '-'
  }
}

export const getActiveKey = (key = 0) => {
  switch (key) {
    case 0:
      return '0'
    case 3:
      return '1'
    case 5:
      return '2'
    case 7:
      return '4'
    case 8:
      return '5'
    default:
      return '0'
  }
}



  export const getIconImg = (v?: any) => {
    if (v == 'ybIcon') {
      return ybIcon
    } else if (v == 'mtISmallcon') {
      return mtISmallcon
    } else if (v == 'dyIcon') {
      return dyIcon
    } else if (v == 'elmIcon') {
      return elmIcon
    } else if (v == 'dzdpIcon') {
      return dzdpIcon
    } else if (v == 'xhsIcon') {
      return xhsIcon
    } else if (v == 'mtIcon') {
      return mtIcon
    } else if (v == 'xykIcon') {
      return xykIcon
    } else if (v == 'yhqIcon') {
      return yhqIcon
    } else if (v == 'tbIcon') {
      return tbIcon
    } else if (v == 'hykfxIcon') {
      return hykfxIcon
    } else if (v == 'kjlIcon') {
      return kjlIcon
    } else if (v == 'yhqTwoIcon') {
      return yhqTwoIcon
    } else if (v == 'bankCardIcon') {
      return bankCardIcon
    } else if (v == 'upsizedIcon') {
      return upsizedIcon
    }
  }

