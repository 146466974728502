/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-31 10:19:04
 */
import { Form, Input, Select, Button, Row, Col } from "antd";
import React, { useState } from "react";
import { SearchOutlined, CaretDownFilled } from "@ant-design/icons";
import { FieldData } from "../../models/fielddata";
// import { setSupplierState } from "./supplierSlice";
import { useDispatch } from "react-redux";
import { supplierParams } from "../../services/supplier";
import { setCurrent } from "./supplierSlice";
const { Option } = Select;

export const SupplierQuery = (props: {
  onValueChange: (queries: supplierParams) => void;
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const DefaultFields: FieldData[] = [
    {
      name: "name",
      value: undefined,
    },
    {
      name: "supplierCategory",
      value: undefined,
    },
  ];

  const [fields, setFields] = useState(DefaultFields);

  return (
    <Form
      fields={fields}
      form={form}
      autoComplete="off"
      name="control-hooks"
      onFieldsChange={(_, fs) => {
        dispatch(setCurrent(1));
        props.onValueChange(form.getFieldsValue());
      }}
    >
      <Row gutter={12}>
        <Col span={4}>
          <Form.Item name="name">
            <Input
              placeholder="供应商名称/助记码"
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="supplierCategory">
            <Select
              placeholder="供应商类别"
              allowClear
              // suffixIcon={<CaretDownFilled />}
            >
              <Option value={0}>供应商类别-供应商</Option>
              <Option value={1}>供应商类别-生产厂商</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="flag" initialValue={0}>

            <Select
                placeholder="查询范围"
                // suffixIcon={<CaretDownFilled />}
            >
              <Option value={0}>仅查询本机构的供应商</Option>
              <Option value={1}>包含系统自带的默认供应商</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}  >
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => {
              props.onValueChange(form.getFieldsValue());
            }}
          >
            查询
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
