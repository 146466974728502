/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-11 11:57:48
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../../app/store'
import { fromJson, PaymentResult } from '../../../models/paymentresult'
import { PaymentResults, fromJsons} from '../../../models/paymentresults'
import {
  autoUpload,
  cancelUpload,
  CancelUploadParams,
  getPaymentResult as apiGetPaymentResult,
  getPaymentResults as apiGetPaymentResults,
  readyUpload, undoSaleUpload,
  updateStates,
  updateStatesResponse,
} from '../../../services/payment'

interface PaymentResultState {
  result?: PaymentResult
  results?: PaymentResults
}

const initialState = {} as PaymentResultState

export const getPaymentResult = createAsyncThunk<
  PaymentResult,
  string,
  RootThunkApi<PaymentResult>
>('pay/getPaymentResultStatus', async (paymentId, api) => {
  return sendAsync(apiGetPaymentResult(paymentId), api).then((data) =>
    fromJson(data)
  )
})

export const getPaymentResults = createAsyncThunk<
  PaymentResult,
  string,
  RootThunkApi<PaymentResults>
>('pay/getPaymentResults', async (paymentId, api) => {
  return sendAsync(apiGetPaymentResults(paymentId), api).then((data) =>
    fromJsons(data)
  )
})

export const cancelSaleUpload = createAsyncThunk<
  PaymentResult,
  string,
  RootThunkApi<PaymentResults>
>('pay/getPaymentResults', async (paymentId, api) => {
  return sendAsync(undoSaleUpload(paymentId), api).then((data) =>
    fromJsons(data)
  )
})

export const getAllPaymentResult = createAsyncThunk<
  PaymentResult,
  string,
  RootThunkApi<PaymentResult>
>('pay/getAllPaymentResult', async (paymentId, api) => {
  return sendAsync(apiGetPaymentResult(paymentId), api)
})

// 获取是否开启代煎
export const getUpdateStates = createAsyncThunk<
  updateStatesResponse,
  string,
  RootThunkApi<void>
>('pay/getUpdateStates', async (params, api) => {
  return sendAsync(updateStates(params), api)
})

// 上传代煎订单接口
export const isAutoUpload = createAsyncThunk<void, string, RootThunkApi<void>>(
  'pay/isAutoUpload',
  async (params, api) => {
    return sendAsync(autoUpload(params), api)
  }
)

// 待上传代煎订单接口
export const isReadyUpload = createAsyncThunk<void, string, RootThunkApi<void>>(
  'pay/isReadyUpload',
  async (params, api) => {
    return sendAsync(readyUpload(params), api)
  }
)

// 取消上传代煎订单接口
export const isCancelUpload = createAsyncThunk<
  void,
  CancelUploadParams,
  RootThunkApi<void>
>('pay/isCancelUpload', async (params, api) => {
  return sendAsync(cancelUpload(params), api)
})

const resultSlice = createSlice({
  name: 'payment/result',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentResult.fulfilled, (state, action) => {
      state.result = action.payload
    })
    builder.addCase(getPaymentResults.fulfilled, (state, action) => {      
      state.results = action.payload
    })
  },
})

export const selectResult = (state: RootState): PaymentResult | undefined =>
  state.result.result

export const selectResults = (state: RootState): PaymentResults | undefined =>
  state.result.results

export default resultSlice.reducer
