/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-31 14:10:45
 * @LastEditors: linxi
 * @LastEditTime: 2021-09-02 11:48:17
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-06-09 17:12:42
 * @LastEditors: linxi
 * @LastEditTime: 2021-08-09 19:34:57
 */
import React, { ReactElement, useEffect, useRef, useState } from "react";
import styles from "./Tooth.module.css";

export interface TabsProps {
  name?: string;
  style?: React.CSSProperties;
  defaultKey?: number;
  click?: (i: number) => void;
  scrollTab?: (height: number, scrollY: number) => void;
}

export const ToothMenu: React.FC<TabsProps> = ({
  style,
  children,
  defaultKey,
  click = () => {},
}): ReactElement => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollRef = useRef<any>();

  useEffect(() => {
    setCurrentIndex(defaultKey || 0);
  }, [defaultKey]);

  return (
    <div className={styles.tabContent} style={style}>
      <ul>
        {React.Children.map(children, (element: any, index) => {
          return (
            <li
              key = {index === currentIndex ? styles.active : ""}
              id={index === currentIndex ? styles.active : ""}
              onClick={() => {
                setCurrentIndex(index);
                click(index);
                scrollRef.current.scrollTop = 0;
              }}
            >
              {element.props.name}
            </li>
          );
        })}
      </ul>
      <div style={{ flex: 1, overflow: "auto" }} ref={scrollRef}>
        {React.Children.map(children, (element: any, index) => {
          return (
            <div
              className={
                index === currentIndex ? styles.showItem : styles.hideItem
              }
            >
              {element}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ToothBal: React.FC<TabsProps> = ({ children }): ReactElement => {
  return <div>{children}</div>;
};

export const TeethLab: React.FC<any> = ({
  children,
  labClick,
  style,
}): ReactElement => {
  return (
    <span
      style={{
        display: "inline-block",
        padding: "0 5px",
        lineHeight: "20px",
        textAlign: "center",
        border: "1px solid #ccc",
        cursor: "pointer",
        ...style,
      }}
      onClick={() => {
        labClick();
      }}
    >
      {children}
    </span>
  );
};
