/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-28 17:23:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-20 20:05:02
 */
import { PlusOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Upload,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectUserName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectUserId,
  selectTenantCategory,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { selfMedicalInformationUploadElecSetlCert } from '../../services/electronicTicketUpload'
import { strParse, medicalSendHttp } from '../../utils/MedicalUtils'
import { getBase64, stringTrim } from '../../utils/StringUtils'
import styles from './ElectronicTicketUpload.module.css'
import { serverType } from '../../services/commodity'
import { selfQuerySetlCertStasAsync } from './ElectronicTicketUploadSlice'

interface CertificateResultQueryProps {
  visible: boolean
  message?: any
  onCancel: () => void
  onOk: () => void
}

export const CertificateResultQueryModal = ({
  visible,
  message,
  onCancel,
  onOk,
}: CertificateResultQueryProps): ReactElement => {
  const formGutter = 20

  const firstColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  const secondColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  const thirdColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const baseData = useSelector(selectInsuranceItem)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [submitLoading, setSubmitLoading] = useState<any>()

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        elecSetlCertType: '2',
        invoiceCheckCode: message?.invoiceCheckCode,
        invoiceCode: message?.invoiceCode,
        invoiceNumber: message?.invoiceNumber,
      })
    }else{
      form.resetFields()
    }
  }, [visible])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  // 保存
  const selfQuerySetlCertStasSubmit = (
    serverType: serverType,
    elecSetlCertType: string,
    invoiceCheckCode: string,
    invoiceCode: string,
    invoiceNumber: string,
    responseString?: string
  ) => {
    setSubmitLoading(true)
    dispatch(
      selfQuerySetlCertStasAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          elecSetlCertType: elecSetlCertType,
          invoiceCheckCode: invoiceCheckCode,
          invoiceCode: invoiceCode,
          invoiceNumber: invoiceNumber,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '凭证结果查询',
            },
            (call: any, err) => {
              !err &&
                selfQuerySetlCertStasSubmit(
                  'response',
                  elecSetlCertType,
                  invoiceCheckCode,
                  invoiceCode,
                  invoiceNumber,
                  JSON.stringify(call.netmessage)
                )
              if (err) {
                setSubmitLoading(false)
                 onOk && onOk()
                 form.resetFields()
              }
            }
          )
        } else {
          setSubmitLoading(false)
          onOk && onOk()
          form.resetFields()
        }
      })
      .catch(() => {
        setSubmitLoading(false)
      })
  }

  return (
    <Modal
      visible={visible}
      title='凭证结果查询'
      width={1000}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 22 }}
        autoComplete='off'
        onFinish={(values) => {
          selfQuerySetlCertStasSubmit(
            'requestString',
            values?.elecSetlCertType,
            values?.invoiceCheckCode,
            values?.invoiceCode,
            values?.invoiceNumber
          )
        }}
      >
        <Row
          gutter={formGutter}
          className={styles.formItem}
          style={{ marginRight: '10px' }}
        >
          {/* <Col span={8} order={1}>
            <Form.Item
              label='机构编号'
              name='idCardNumber'
              {...firstColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请填写机构编号',
                },
              ]}
            >
              <Input disabled placeholder='请输入机构编号' />
            </Form.Item>
          </Col>
          <Col span={16} order={2}>
            <Form.Item
              label='机构名称'
              name='idCardNumber'
              {...secondColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请填写机构名称',
                },
              ]}
            >
              <Input disabled placeholder='请输入机构名称' />
            </Form.Item>
          </Col> */}
          <Col span={24} order={3}>
            <Form.Item
              label='发票类型'
              name='elecSetlCertType'
              {...firstColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请选择发票类型',
                },
              ]}
            >
              <Select
                placeholder='发票类型'
                allowClear
                style={{ width: '12.4rem' }}
                // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              >
                <Select.Option value='2'>增值税发票</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8} order={4}>
            <Form.Item
              label='发票代码'
              name='invoiceCode'
              {...firstColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请输入发票代码',
                },
              ]}
            >
              <Input placeholder='请输入发票代码' />
            </Form.Item>
          </Col>
          <Col span={8} order={5}>
            <Form.Item
              label='发票号码'
              name='invoiceNumber'
              {...secondColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请输入发票号码',
                },
              ]}
            >
              <Input placeholder='请输入发票号码' />
            </Form.Item>
          </Col>
          <Col span={8} order={6}>
            <Form.Item
              label='发票校验码'
              name='invoiceCheckCode'
              {...thirdColumnLayout}
              rules={[
                {
                  required: true,
                  message: '请输入校验码',
                },
              ]}
            >
              <Input placeholder='请输入校验码' />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
            marginRight: '24px',
          }}
        >
          <Button
            style={{ marginRight: '20px', color: '#666666' }}
            onClick={() => {
              onCancel()
              form.resetFields()
            }}
          >
            取消
          </Button>
          <Button type='primary' htmlType='submit' loading={submitLoading}>
            确定
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}
