import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Row, Space } from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { serverType } from '../../services/commodity'
import { GetDepartmentListParams } from '../../services/department'
import { ThemeContext } from '../../theme/ThemeContext'
import { medicalSendHttp, strParse } from '../../utils/MedicalUtils'
import { selectDepartments } from '../payment/list/paymentSlice'
import { columns } from './columns'
import { DepartmentModal } from './DepartmentModal'
import {
  getDepartmentList,
  selectDepartmentPage,
  toggleDepartmentState,
  departmentUploadAsync,
  departmentRepealAsync,
} from './departmentSlice'

interface DepartmentsProps {
  onChange?: (id: string) => void
}

export const Departments = ({ onChange }: DepartmentsProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const { tenantId } = useParams<{ tenantId: string | undefined }>()

  const page = useSelector(selectDepartmentPage)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [params, setParams] = useState<GetDepartmentListParams>({
    current: 1,
  })

  const [departmentId, setDepartmentId] = useState('')

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [isModalVisible, setIsModalVisible] = useState(false)

  const baseData = useSelector(selectInsuranceItem)

  useEffect(() => {
    setPageLoading(true)
    dispatch(getDepartmentList({ ...params, tenantId })).finally(() => {
      setPageLoading(false)
    })
  }, [dispatch, params, tenantId])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
    form.submit()
  }, [insuranceArray])

  // 科室上传
  const DepartmentUpload = (
    serverType: serverType,
    id: number,
    responseString?: string
  ) => {
    dispatch(
      departmentUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          id,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '科室上传',
            },
            (call: any, err) => {
              !err &&
                DepartmentUpload(
                  'response',
                  id,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          dispatch(getDepartmentList({ ...params, tenantId }))
        }
      })
  }

  // 科室撤销
  const DepartmentRepeal = (
    serverType: serverType,
    id: number,
    responseString?: string
  ) => {
    dispatch(
      departmentRepealAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          id,
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '科室撤销上传',
            },
            (call: any, err) => {
              !err &&
                DepartmentRepeal(
                  'response',
                  id,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          const data = strParse(res)
          // dispatch(getDepartmentList({ ...params, tenantId }));
        }
      })
  }

  return (
    <Col
      style={{
        position: 'relative',
        height: tenantId ? 'calc(100% - 20px)' : 'calc(100% - 30px)',
        overflow: 'hidden',
        marginTop: 10,
        marginBottom: tenantId ? 0 : 10,
        marginLeft: tenantId ? 30 : 0,
        marginRight: tenantId ? 30 : 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {tenantId ? (
        <NavBar
          where={['机构管理', '科室管理']}
          backtrace={{
            name: '机构管理',
            path: '/admin/tenant',
          }}
          style={{ marginBottom: 10 }}
          buttons={[
            <Button
              key='0'
              type='primary'
              icon={<PlusOutlined />}
              size='small'
              onClick={() => setIsModalVisible(true)}
            >
              新增
            </Button>,
          ]}
        />
      ) : (
        <Button
          type='primary'
          icon={<PlusOutlined />}
          size='small'
          style={{
            position: 'fixed',
            top: 68,
            right: 45,
          }}
          onClick={() => setIsModalVisible(true)}
        >
          新增
        </Button>
      )}
      <Col
        style={{
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '10px 15px 0 15px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          onFinish={(values) => setParams({ ...params, ...values, current: 1 })}
        >
          <Row
            align='middle'
            justify='space-between'
            style={{ marginBottom: 20, marginTop: 10 }}
          >
            <Space>
              <Form.Item name='name' noStyle>
                <Input
                  placeholder='请输入科室名称'
                  style={{ width: 315 }}
                  prefix={<SearchOutlined color={theme.tc3} />}
                  allowClear
                  onChange={form.submit}
                />
              </Form.Item>
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
        <EditableList
          page={page}
          loading={pageLoading}
          columns={columns(
            (d) => {
              dispatch(
                toggleDepartmentState({ departmentId: d.id, tenantId })
              ).then(() => dispatch(getDepartmentList(params)))
            },
            page.size * (page.current - 1),
            (action, id, t) => {
              switch (action) {
                case 'repeal':
                  DepartmentRepeal('requestString', id)
                  break
                case 'upload':
                  DepartmentUpload('requestString', id)
              }
            }
          )}
          onChangePage={(current, size) => {
            setParams({ ...params, current, size })
          }}
          onRow={(d) => ({
            onDoubleClick: () => {
              setDepartmentId(d.id)
              setIsModalVisible(true)
            },
          })}
        />
      </Col>
      <DepartmentModal
        maskClosable={false}
        departmentId={departmentId}
        visible={isModalVisible}
        onCancel={() => {
          setDepartmentId('')
          setIsModalVisible(false)
        }}
        onOk={() => {
          onChange && onChange(departmentId)
          setDepartmentId('')
          setIsModalVisible(false)
          dispatch(getDepartmentList(params))
        }}
      />
    </Col>
  )
}
