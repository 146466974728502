import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../../app/applicationSlice'
import { RootDispatch, RootState, RootThunkApi } from '../../../app/store'
import { fromMaterialJson, Material } from '../../../models/material'
import * as R from '../../../models/recipe'
import {
  getRecipeCategoryName,
  Recipe,
  RecipeCategory,
} from '../../../models/recipe'
import * as SR from '../../../services/recipe'
import {
  CreateRetailRecipeParams,
  CreateRetailRecipePatientParams,
  PayTypeBodyBatch,
  updateItemPayTypeBatch,
} from '../../../services/recipe'
import { remindSaveRecipes, SaveRecipesParams } from '../../../services/remind'
import { fixGroupNumber } from '../../../utils/MaterialUtils'
import { setRemindDetail } from '../diagnosis/diagnosisSlice'
import { baseDataType } from '../../../services/commodity'
import { notification } from 'antd'

interface RecipeEditorState {
  recipe?: Recipe
  materials: Material[]
  batchAddVisibel?: boolean // 批量添加弹框
  curePlanListVisible?: boolean
  AddCurePlanModalVisible?: boolean
  planCount?: number // 治疗计划数量
  pageLoading?: boolean
  editPlanId?: string
}

const initialState = {
  materials: [],
  batchAddVisibel: false,
  curePlanListVisible: false,
  AddCurePlanModalVisible: false,
  planCount: 0,
  pageLoading: false,
  editPlanId: '',
} as RecipeEditorState

function reloadRecipeMaterials({
  dispatch,
  getState,
}: {
  dispatch: RootDispatch
  getState: () => RootState
}) {
  const recipe = selectRecipe(getState())
  if (recipe) {
    dispatch(getRecipeDetail(recipe.id))
  }
}

export const createOrUpdateRecipe = createAsyncThunk<
  string,
  Recipe,
  RootThunkApi<string>
>('recipeEditor/createOrUpdateRecipe', async (recipe, api) => {
  return sendAsync(SR.createOrUpdateRecipe(recipe), api).then((data) => {
    reloadRecipeMaterials(api)
    return data?.toString() || ''
  })
})

export const createRetailRecipe = createAsyncThunk<
  Recipe,
  CreateRetailRecipeParams,
  RootThunkApi<Recipe>
>('recipeEditor/createRetailRecipeStatus', async (params, api) => {
  return sendAsync(
    SR.createRetailRecipe(params),
    api
  ).then((data: Record<string, never>) => R.fromJson(data))
})

export const createRetailRecipePatient = createAsyncThunk<
  Recipe,
  CreateRetailRecipePatientParams,
  RootThunkApi<Recipe>
>('recipeEditor/createRetailRecipePatient', async (params, api) => {
  return sendAsync(
    SR.createRetailRecipePatients(params),
    api
  ).then((data: Record<string, never>) => R.fromJson(data))
})

export const copyRecipe = createAsyncThunk<
  void,
  SR.CopyRecipeParams,
  RootThunkApi<void>
>('recipeEditor/copyRecipeStatus', async (params, api) => {
  return sendAsync(SR.copyRecipe(params), api).then((v: any) => {
    reloadRecipeMaterials(api)
    if (v.errorMsg !== '') {
      notification.error({
        message: v.errorMsg,
      })
    }
  })
})

export const exchangeList = createAsyncThunk<
  void,
  SR.ExchangeParams,
  RootThunkApi<void>
>('recipeEditor/exchangeList', async (params, api) => {
  return sendAsync(SR.exchangeRecipe(params), api)
})

// 执行单打印内容
export const getPrintZXDData = createAsyncThunk<void, any, RootThunkApi<void>>(
  'recipeEditor/getPrintZXDData',
  async (params, api) => {
    return sendAsync(SR.printZXDData(params), api)
  }
)

export const getRecipeCurePlanDetail = createAsyncThunk<
  [Recipe, Material[]],
  string,
  RootThunkApi<[Recipe, Material[]]>
>('recipeEditor/getRecipeCurePlanDetail', async (param, api) => {
  return sendAsync(SR.getRecipeDetail(param), api).then(
    (data: { records: [Record<string, never>] }) => {
      const items =
        _.chain(data.records?.[0]?.itemList)
          .map((m) => fromMaterialJson(m))
          .sort((a, b) => {
            if (a.groupNumber < b.groupNumber) {
              return -1
            }
            if (a.groupNumber > b.groupNumber) {
              return 1
            }
            return a.sort - b.sort
          })
          .value() || []
      return [R.fromJson(data.records[0]), items] as [Recipe, Material[]]
    }
  )
})

export const getRecipeDetail = createAsyncThunk<
  [Recipe, Material[], any],
  string,
  RootThunkApi<[Recipe, Material[]]>
>('recipeEditor/getRecipeDetailStatus', async (param, api) => {
  return sendAsync(SR.getRecipeDetail(param), api).then(
    (data: { records: [Record<string, never>] }) => {
      const items =
        _.chain(data.records?.[0]?.itemList)
          .map((m) => fromMaterialJson(m))
          .sort((a, b) => {
            if (a.groupNumber < b.groupNumber) {
              return -1
            }
            if (a.groupNumber > b.groupNumber) {
              return 1
            }
            return a.sort - b.sort
          })
          .value() || []
      // const path = window.location.pathname // 仅在医嘱处方页面调用
      // path == '/treatment' &&
      //   api.dispatch(
      //     remindSaveRecipesAsync({
      //       recipeName: getRecipeCategoryName(data.records[0].category),
      //       recipeItems: items.length
      //         ? items.map((v) => ({ name: v.name }))
      //         : [],
      //     })
      //   )
      let oneselfAmount = 0 //医嘱处方-自费总价
      let insuranceAmount = 0 //医嘱处方-医保总价
      items.forEach((i: any) => {
        if (i.itemPayType == 0) {
          oneselfAmount = i.drugAmount + oneselfAmount
        } else if (i.itemPayType == 1) {
          insuranceAmount = i.drugAmount + insuranceAmount
        }
      })
      const replaceMakePrice =
        items.find(
          (v) => v.billingCategory == '97' && v?.insuranceCode == 'ZIFEI'
        )?.retailPrice || 0

      const YBReplaceMakePrice =
        items.find(
          (v) => v.billingCategory == '97' && v?.insuranceCode != 'ZIFEI'
        )?.retailPrice || 0
      return [
        R.fromJson({
          ...data.records[0],
          replaceMakePrice,
          YBReplaceMakePrice,
          oneselfAmount,
          insuranceAmount,
        }),
        items,
        data?.records[0]?.uncompletedPlanNum || 0,
      ] as [Recipe, Material[], any]
    }
  )
})

export const saveRecipeMaterials = createAsyncThunk<
  void,
  SR.SaveRecipeMaterialsParams,
  RootThunkApi<void>
>('recipeEditor/saveRecipeMaterials', async (params, api) => {
  return sendAsync(SR.saveRecipeMaterials(params), api)
    .then(() => {
      reloadRecipeMaterials(api)
    })
    .catch((e: any) => {
      reloadRecipeMaterials(api)
    })
})

export const removeMaterials = createAsyncThunk<void, string[], RootThunkApi>(
  'recipeEditor/removeMaterialsStatus',
  async (params, api) => {
    await sendAsync(SR.removeMaterials(params), api)
    const materials = selectRecipeMaterials(api.getState()).filter(
      (m) => !params.includes(m.id)
    )
    api.dispatch(updateSortAndGroupNumber(fixGroupNumber(materials)))
  }
)

export interface convertToZifeiParam {
  registrationId?: string
  patientName?: string
  treatmentId?: string
}
export const getInsuranceToSelfpay = createAsyncThunk<
  void,
  convertToZifeiParam,
  RootThunkApi<void>
>('recipeEditor/getInsuranceToSelfpay', async (params, api) => {
  return sendAsync(SR.insuranceToSelfpay(params), api).then(() => {
    reloadRecipeMaterials(api)
  })
})
export const getInsuranceToSelfpayAsync = createAsyncThunk<
  void,
  convertToZifeiParam,
  RootThunkApi<void>
>('recipeEditor/getInsuranceToSelfpay', async (params, api) => {
  return sendAsync(SR.insuranceToSelfpay(params), api)
})
export const updateSortAndGroupNumber = createAsyncThunk<
  void,
  Material[],
  RootThunkApi<void>
>('recipeEditor/updateSortAndGroupNumberState', async (params, api) => {
  return sendAsync(SR.updateSortAndGroupNumber(params), api).then(() => {
    reloadRecipeMaterials(api)
  })
})

// 医嘱处方-代煎处方信息
export const getReplaceMakeInfo = createAsyncThunk<
  void,
  SR.replaceMakeInfoParams,
  RootThunkApi<void>
>('recipeEditor/getReplaceMakeInfo', async (params, api) => {
  return sendAsync(SR.replaceMakeInfo(params), api)
})

// 医嘱处方-批量变更收费类型
export const updateItemPayTypeBatchAsync = createAsyncThunk<
  void,
  SR.PayTypeBodyBatch,
  RootThunkApi<void>
>('recipeEditor/updateItemPayTypeBatch', async (params, api) => {
  return sendAsync(SR.updateItemPayTypeBatch(params), api)
})

// 医嘱处方-签约
export const replaceMakeInfoAgreementAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('recipeEditor/replaceMakeInfoAgreementAsync', async (params, api) => {
  return sendAsync(SR.replaceMakeInfoAgreement(params), api)
})

// 医嘱处方-代煎设置详情
export const getReplaceMakeSettingDetail = createAsyncThunk<
  void,
  SR.replaceMakeInfoParams,
  RootThunkApi<void>
>('recipeEditor/getReplaceMakeSettingDetail', async (params, api) => {
  return sendAsync(SR.replaceMakeSettingDetail(params), api)
})

// 获取机构支持的代煎方式
export const getTenantPreferencesListAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('recipeEditor/getTenantPreferencesListAsync', async (params, api) => {
  return sendAsync(SR.getTenantPreferencesList(), api)
})

// 设置为默认偏好接口
export const getPreferencesUpgradeAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('recipeEditor/getPreferencesUpgradeAsync', async (params, api) => {
  return sendAsync(SR.getPreferencesUpgrade(params), api)
})

// 医嘱处方-代煎设置保存
export const isSaveReplaceMakeSetting = createAsyncThunk<
  void,
  SR.saveReplaceMakeSettingParams,
  RootThunkApi<void>
>('recipeEditor/isSaveReplaceMakeSetting', async (params, api) => {
  return sendAsync(SR.saveReplaceMakeSetting(params), api)
})

// 医嘱处方-删除代煎设置
export const isdeleteReplaceMakeSetting = createAsyncThunk<
  void,
  SR.deleteReplaceMakeSettingParams,
  RootThunkApi<void>
>('recipeEditor/isdeleteReplaceMakeSetting', async (params, api) => {
  return sendAsync(SR.deleteReplaceMakeSetting(params), api)
})

// 医嘱处方-获取全国位置
export const getAddress = createAsyncThunk<void, string, RootThunkApi<void>>(
  'recipeEditor/getAddress',
  async (params, api) => {
    return sendAsync(SR.address(params), api)
  }
)
// 医嘱处方-获取上一次地址
export const getOldAddress = createAsyncThunk<
  void,
  SR.oldAddressParams,
  RootThunkApi<void>
>('recipeEditor/getOldAddress', async (params, api) => {
  return sendAsync(SR.oldAddress(params), api)
})
// 医嘱处方-获取收货位置
export const getShopAddress = createAsyncThunk<
  void,
  SR.shopAddressParams,
  RootThunkApi<void>
>('recipeEditor/getShopAddress', async (params, api) => {
  return sendAsync(SR.shopAddress(params), api)
})
// 医嘱处方-// 获取电话列表
export const getPhoneList = createAsyncThunk<void, any, RootThunkApi<void>>(
  'recipeEditor/getPhoneList',
  async (params, api) => {
    return sendAsync(SR.phoneList(params), api)
  }
)

// 同步修改用法
export const getBatchSynchronizationRecipeItem = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('recipeEditor/batchSynchronizationRecipeItem', async (params, api) => {
  return sendAsync(SR.batchSynchronizationRecipeItem(params), api)
})

// 优惠券列表
export const getPatientCouponsList = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('recipeEditor/getPhoneList', async (params, api) => {
  return sendAsync(SR.patientCouponsList(params), api)
})

// 优惠券使用
export const getCouponsUse = createAsyncThunk<
  void,
  SR.CouponsUseParams,
  RootThunkApi<void>
>('recipeEditor/getPhoneList', async (params, api) => {
  return sendAsync(SR.CouponsUse(params), api)
})

// 代煎偏好设置
export const getDecoctingPersonalSettingAsync = createAsyncThunk<
  void,
  SR.DecoctingPersonalSettingParams,
  RootThunkApi<void>
>('recipeEditor/getDecoctingPersonalSettingAsync', async (params, api) => {
  return sendAsync(SR.decoctingPersonalSetting(params), api)
})

// 代煎回填偏好设置
export const getDecoctingPersonalDetailAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('recipeEditor/getDecoctingPersonalDetailAsync', async (params, api) => {
  return sendAsync(SR.decoctingPersonalDetail(), api)
})

// 委外代煎通过饮片厂回填偏好设置
export const getBackfillDataAsync = createAsyncThunk<
  void,
  SR.BackfillDataParams,
  RootThunkApi<void>
>('recipeEditor/getBackfillDataAsync', async (params, api) => {
  return sendAsync(SR.backfillData(params), api)
})

// 本地代煎通过回填偏好设置
export const getPotionpreferencesSettingAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('recipeEditor/getPotionpreferencesSettingAsync', async (params, api) => {
  return sendAsync(SR.getPotionpreferencesSetting(params), api)
})
// 事中提醒保存处方时
export const remindSaveRecipesAsync = createAsyncThunk<
  any,
  SaveRecipesParams,
  RootThunkApi
>('recipeEditor/remindSaveMedicalAsync', async (body, api) => {
  return sendAsync(remindSaveRecipes(body), api).then((data) => {
    api.dispatch(setRemindDetail(data))
  })
})

const recipeEditorSlice = createSlice({
  name: 'recipeEditor',
  initialState,
  reducers: {
    resetRecipeEditor: (state) => {
      state.recipe = undefined
      state.materials = []
    },
    setMaterials: (state, action) => {
      // const oldMaterials = JSON.parse(JSON.stringify(state.materials))
      // state.materials = oldMaterials.concat(action.payload)
      state.materials = action.payload
    },
    setBatchAddVisibel: (state, action) => {
      state.batchAddVisibel = action.payload
    },
    setAddCurePlanModalVisible: (state, action) => {
      state.AddCurePlanModalVisible = action.payload
    },
    setEditPlanId: (state, { payload }) => {
      state.editPlanId = payload
    },
    setCurePlanListVisible: (state, action) => {
      state.curePlanListVisible = action.payload
    },
    setPlanCount: (state, action) => {
      state.planCount = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecipeDetail.fulfilled, (state, action) => {
      state.recipe = action.payload[0]
      state.materials = action.payload[1]
      state.planCount = action.payload[2]
      state.pageLoading = false
    })
    builder.addCase(createRetailRecipe.fulfilled, (state, action) => {
      state.recipe = action.payload
    })
    // builder.addCase(saveRecipeMaterials.fulfilled, (state, action) => {
    //   const oldMaterials = JSON.parse(JSON.stringify(state.materials))
    //   const item: any = action.payload
    //   if (oldMaterials.find((v: any) => v.id === String(item[0].id))) {
    //     state.materials = oldMaterials.map((v: any) => {
    //       if (v.id === String(item[0].id)) {
    //         return item[0]
    //       }
    //       return v
    //     })
    //   } else {
    //     state.materials = oldMaterials.concat(action.payload)
    //   }
    // })
  },
})

export const {
  resetRecipeEditor,
  setMaterials,
  setBatchAddVisibel,
  setCurePlanListVisible,
  setAddCurePlanModalVisible,
  setPlanCount,
  setEditPlanId,
  setPageLoading,
} = recipeEditorSlice.actions

export const selectRecipe = (state: RootState): Recipe | undefined =>
  state.recipeEditor.recipe

export const selectRecipeMaterials = (state: RootState): Material[] =>
  state.recipeEditor.materials

export const selectBatchAddVisibel = (state: RootState) =>
  state.recipeEditor.batchAddVisibel

export const selectAddCurePlanModalVisible = (state: RootState) =>
  state.recipeEditor.AddCurePlanModalVisible

export const selectCurePlanListVisible = (state: RootState) =>
  state.recipeEditor.curePlanListVisible

export const selectPlanCount = (state: RootState) =>
  state.recipeEditor.planCount

export const selectPageLoading = (state: RootState) =>
  state.recipeEditor.pageLoading

export const selectsEtEditPlanId = (state: RootState) =>
  state.recipeEditor.editPlanId

export default recipeEditorSlice.reducer
