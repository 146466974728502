/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-11 14:24:32
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-12 14:26:51
 */
//接口
import { API_PREFIX } from './constant'
import { Request } from './request'
export function getHomeList(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/summary`,
    method: 'GET',
    params: {
      id,
    },
  }
}

export function getCountList(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/count`,
    method: 'GET',
    params: {
      id,
    },
  }
}

export function getMessageList(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/detailMessage`,
    method: 'GET',
    params: {
      id,
    },
  }
}

export function readMessage(messageReadId: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/change`,
    method: 'POST',
    body: {
      messageReadId,
    },
  }
}

export interface IdParams {
  messageReadId: string
}

export function getState(body: IdParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/change`,
    method: 'POST',
    body,
  }
}

export function getCancel(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/cancel`,
    method: 'POST',
    params: {
      id,
    },
  }
}
// 获取默认菜单
export function getSelectMenu(): Request {
  return {
    url: `${API_PREFIX}/blade-user/defaultMenu/list`,
    method: 'GET',
  }
}

export function saveSelectMenu(menuIds: string[]): Request {
  return {
    url: `${API_PREFIX}/blade-user/defaultMenu/saveDefaultMenu`,
    method: 'POST',
    body: { menuIds },
  }
}
// 获取商品列表
export interface getShoopListFnParams {
  allMaterials?: number
  allStr?: string
  approveFlg?: number
  billingCategory?: number
  categoryType?: number
  current?: number
  flag?: number
  insuranceCode?: number
  materialsCategory?: number
  otc?: number
  size?: number
  state?: number
  tenantId?: number
  tenantSupplierId?: number
  treatmentCategory?: number
}
export function getShoopListFn(params: getShoopListFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/invalidpage`,
    method: 'GET',
    params,
  }
}

// 获取诊疗列表
export function getInvalidPage(params: getShoopListFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/invalidpage`,
    method: 'GET',
    params,
  }
}

// 获取诊疗列表
export function versionPageAsync(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/getTrialVersionList`,
    method: 'GET',
  }
}

// 获取诊疗列表
export function versionConsultVersionAsync(versionId: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/consultVersion`,
    method: 'GET',
    params: { versionId },
  }
}
