/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-01-24 15:30:56
 * @LastEditors: linxi
 * @LastEditTime: 2024-01-24 17:44:12
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, notification } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectUserName,
  selectUserId,
  selectTenantAreaCd,
  selectTenantAddress,
  selectTenantName,
  selectTenantCategory,
  selectInsuranceArray,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { Payment, PaymentStatus } from '../../models/payment'
import { serverType } from '../../services/commodity'
import { PaymentListParams } from '../../services/payment'
import { ThemeContext } from '../../theme/ThemeContext'
import { medicalSendHttp, strParse } from '../../utils/MedicalUtils'
import { PaymentsRef } from '../payment/list/Payments'
import {
  getInsurancePaymentListAsync,
  selectPaymentPage,
} from '../payment/list/paymentSlice'
import { PaymentColumns, PaymentColumnsWithAmount } from './columns'
import { PaymentQuery } from './Query'
import { electronicMedicalRecordListUpload } from './electronicMedicalRecordUploadSlice'
import { DataType } from '../putstorage/putstorage'
import { TableRowSelection } from 'antd/lib/table/interface'

interface PaymentProps {
  status: PaymentStatus
  sibling?: PaymentsRef | null
}

export const ElectronicMedicalRecordUpload = (
  { status, sibling = null }: PaymentProps,
  ref: React.Ref<PaymentsRef>
): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const page = useSelector(selectPaymentPage(status))

  const [params, setParams] = useState<PaymentListParams>({
    current: 0,
    state: status,
  })

  const [disabled, setDisabled] = useState(false)

  const [loading, setLoading] = useState(false)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const insuranceArray = useSelector(selectInsuranceArray)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const baseData = useSelector(selectInsuranceItem)

  const columns = PaymentColumnsWithAmount

  const [selectedKeys, setSelectedKeys] = useState<any>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedKeys(selectedRowKeys)
    },
  }

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  // 获取数据
  const getData = () => {
    setPageLoading(true)
    dispatch(getInsurancePaymentListAsync({...params,fromPage:"electronicMedicalRecordUpload"}))
      .then(unwrapResult)
      .finally(() => {
        setPageLoading(false)
      })
  }

  const electronicMedicalRecordUpload = (
    serverType: serverType,
    treatmentId: number,
    responseString?: string
  ) => {
    dispatch(
      electronicMedicalRecordListUpload({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          treatmentId: Number(treatmentId),
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType == 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '电子病例上传',
            },
            (call: any, err) => {
              if (!err) {
                electronicMedicalRecordUpload(
                  'response',
                  treatmentId,
                  JSON.stringify(call.netmessage)
                )
              } else {
                setDisabled(false)
                setLoading(false)
              }
            }
          )
        } else if (serverType === 'response') {
          setDisabled(false)
          setLoading(false)
          getData()
        }
      })
      .catch((e) => {
        setDisabled(false)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (params.current !== 0) {
      getData()
    }
  }, [params])

  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 40px)',
        margin: '20px',
        overflow: 'hidden',
        padding: '10px 15px 0 15px',
      }}
    >
      <PaymentQuery
        loading={loading}
        selectedKeys={selectedKeys}
        onValueChange={(v) => {
          setParams({ ...params, ...v, current: 1 })
        }}
        massUpload={() => {
          if (selectedKeys?.length > 10) {
            notification.info({
              message: '一次最多上传10条',
            })
          } else {
            selectedKeys?.map((treatmentId: any) => {
              electronicMedicalRecordUpload('requestString', treatmentId)
            })
          }
        }}
      />
      <EditableList<Payment>
        page={page}
        loading={pageLoading}
        columns={columns(
          status,
          page.size * (page.current - 1),
          (action, id, t) => {
            if (action === 'apply') {
              setDisabled(true)
              setLoading(true)
              electronicMedicalRecordUpload(
                'requestString',
                t.registration.treatmentId
              )
            }
          },
          disabled,
          loading
        )}
        rowKey={(m) => {
          return m?.treatment?.id
        }}
        rowSelection={
          {
            selectedRowKeys: selectedKeys,
            ...rowSelection,
          } as TableRowSelection<any>
        }
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size,
          })
        }
        onRow={(record) => ({})}
      />
    </Col>
  )
}
