/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-31 11:05:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-24 13:58:49
 */
import { TableColumnType, Button } from 'antd'
import moment from 'moment'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'

export const Columns = (startIndex: number): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '姓名',
      align: 'center',
      dataIndex: 'patientName',
      key: 'patientName',
    },
    {
      title: '开单日期',
      align: 'center',
      dataIndex: 'recipeTime',
      key: 'recipeTime',
      render: (_, t) =>
        t.recipeTime ? moment(t?.recipeTime).format(DateTimeFormatSimple) : '',
    },
    {
      title: '药品名称',
      align: 'center',
      dataIndex: 'drugName',
      key: 'drugName',
    },
    {
      title: '帖数',
      align: 'center',
      dataIndex: 'tip',
      key: 'tip',
    },
    {
      title: '每帖数量',
      align: 'center',
      key: 'countPerTip',
      render: (_, t) => t.countPerTip,
    },
    {
      title: '总量',
      align: 'right',
      dataIndex: 'drugCount',
      render: (_, t) => t.drugCount,
    },
    {
      title: '代煎公司',
      align: 'center',
      key: 'supplierName',
      render: (_, t) => t.supplierName,
    },
  ]
}
