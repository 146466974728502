
//购入入库详情入库单
import moment from "moment";
import {DateTimeFormatSimpleOnlyOne} from "../../../models/datetime";

export function printBody(header:any,data: any) {
  console.log(data,"dddd");
  let sjDatas = ''
  const date = new Date()
  for (let i = 0; i < data.length; i++) {
    const hjStyle = data?.[i]?.treatmentDoctorName == '科室总计' || data?.[i]?.treatmentDepartmentName == '机构总计' ? 'font-weight:bold;' : ''
    sjDatas +=
      '<div style="'+hjStyle+'font-size: 13px;line-height:30px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '        <div style="font-size:12px;display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.treatmentDepartmentName +
      '        </div>' +
      '       <div style="font-size:12px;display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.treatmentDoctorName +
      '       </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.allTotalAmount?.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 4%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.cz +
      '        </div>' +
      '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 4%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.fz +
      '       </div>' +
      '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.dr01.toFixed(2) +
      '       </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.dr02.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.dr03.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 7%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.dr101.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.drOther.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.tr30.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.tr97.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.tr04.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.otherTreatment.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.drTip.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width:6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.drTipInsurance.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      data?.[i]?.amountPerTip.toFixed(2) +
      '        </div>' +
      '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;">' +
      data?.[i]?.amountPerTipInsurance.toFixed(2) +
      '        </div>' +
      '        </div>'
  }
  // const userBean = getUserBean()
  const content =
    '<div style="margin-left: 10px;margin-right: 10px">' +
    '    <h3 style="text-align: center;">' +
    header?.title + ' - 医生销售统计表' +
    '</h3>' +
    '    <h5 style="text-align: center; position: absolute;top:0px;right:20px">' +
    header?.timeHead?.substring(0,10) + ' ~ ' + header?.timeTail.substring(0,10) +
    '</h5>' +
    '    <div style="margin-top:20px;font-size:13px;line-height:30px;font-weight:bold;display:table;width:100%;align-items:center;clear: left;border: 1px solid #000">' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            科室' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           医生' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            总金额' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 4%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            初诊' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 4%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           复诊' +
    '       </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           西药费' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            中成药' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            中草药' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 7%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            医保中草药' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            其他药品' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            挂号费' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            代煎费' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            诊察费' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            其他诊疗' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            中药帖数' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width:6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            医保帖数' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            中药帖均' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;">' +
    '            医保帖均' +
    '        </div>' +
    '    </div>' +
    sjDatas +
    '</div>'

  return content
}