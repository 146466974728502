import { PhoneFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Modal,
  message,
  Table,
  Button,
  notification,
  Dropdown,
  Input,
  Tooltip,
  Divider,
  Row,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ReactElement } from "react";
import styles from "./patientModal.module.css";
import {
  BoyOutfillIcon,
  GirlOutfillIcon,
  HospitalOutlineIcon,
} from "../compnents/icons/Icons";
import { getWorkStation } from "../features/A-toothModule/toothHome/toothHomeSlice";
import { RootDispatch } from "../app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientList,
  selectPatients,
} from "../features/registration/register/registerSlice";
import { getAge } from "../utils/StringUtils";
import { RegisteredModal } from "../features/A-toothModule/toothHome/modal/registeredModal";
import { getPatientByGroupAsync, setActiveObj, updatePatientTimeAsync } from "../features/A-toothModule/patient/patientSlice";
import { SubscribeModal } from "../features/A-toothModule/toothHome/modal/subscribeModal";
import { AddPatientModal } from "../features/A-toothModule/toothHome/modal/addPatientModal";
import { useHistory } from "react-router-dom";
import { traceRoute } from "./layoutSlice";
import { RegisterNoAppointModal } from "../features/registration/list/RegisterNoAppointModal";

export const PatientModal = () => {
  const dispatch = useDispatch<RootDispatch>();

  const history = useHistory();

  const [nameWS, setNameWS] = useState("");

  const [patients, setPatients] = useState<any>([]);

  const [total, setTotal] = useState(0);

  const [registeredModal, setRegisteredModal] = useState(false); //工作站-转诊弹窗

  const [cardType, setCardType] = useState<any>(); // 读卡类型

  const [selectId, setSelectId] = useState(""); //弹窗 患者ID

  const [currentRegistrationId, setCurrentRegistrationId] = useState("");

  const [currentAppointmentId, setcurrentAppointmentId] = useState("");

  const [visible, setVisible] = useState<boolean>(false);

  const [subscribeModal, setSubscribeModal] = useState(false); // 工作站-预约弹窗

  const [currentTreatmentDoctorId, setCurrentTreatmentDoctorId] = useState("");

  const [addPatientModal, setAddPatientModal] = useState(false); // 新增患者弹窗

  const [modalPatientID, setModalPatientID] = useState(""); //弹窗 患者ID

  const [remark, setRemark] = useState("");

  const [registerNoAppointId, setRegisterNoAppointId] = useState("");

  const [
    registerNoAppointModalVisible,
    setRegisterNoAppointModalVisible,
  ] = useState(false);

  useEffect(() => {
    if (nameWS) {
      dispatch(
        getPatientByGroupAsync({
          groupId: "0",
          current: 1,
          size: 10,
          key: nameWS,
          orderType: 1,
        })
      )
        .then(unwrapResult)
        .then((v: any) => {
          setPatients(v?.records);
          setTotal(v?.total);
          setVisible(true);
        });
    } else {
      setPatients([]);
      setTotal(0);
    }
  }, [nameWS]);

  const patientId0 = () => {
    Modal.confirm({
      title: "提示",
      content: (
        <p>
          请先到【快速开单】-
          <span
            style={{
              padding: "0 10px",
              cursor: "pointer",
              textDecoration: "none",
              color: "#027AFF",
            }}
            onClick={() => {
              Modal.destroyAll();
              history.push({
                pathname: "/retail",
                state: {
                  selectKey: "1",
                },
              });
            }}
          >
            【收费明细】
          </span>
          页面更改姓名后，再进行操作
        </p>
      ),
      onOk: () => {
        return;
      },
    });
  };


  const tableUpdata = (id: string, registrationId: string, num: string) => {
    dispatch(updatePatientTimeAsync(id))
      .then(unwrapResult)
      .then(() => {
        // history.push({
        //   pathname: '/toothPatient',
        // })
        dispatch(
          setActiveObj({
            tabsActiveKey: "3",
            patientId: id,
            registrationId:
              Number(registrationId) > 0 ? registrationId : undefined,
            rightTabsActiveKey: num,
          })
        )
        dispatch(
          traceRoute({
            name: "患者管理",
            path: "/toothPatient",
          })
        );
      });
  };
  const PatientItem = (props: { patientInfo: any }) => {
    const onclick = () => {
      setVisible(false)
      const patient = props?.patientInfo?.patient;
      const registion = props?.patientInfo?.registration;
      if (
        (registion?.treatmentDepartmentName == "未指定科室" &&
          registion?.treatmentDoctorName == "未指定医生") ||
        (registion?.registrationDepartmentName == "未指定科室" &&
          registion?.registrationDoctorName == "未指定医生")
      ) {
        setRemark(registion?.remark?.replace("#", ","));
        setRegisterNoAppointId(registion?.id);
        setRegisterNoAppointModalVisible(true);
      } else {
        if (patient?.id == 0) {
          patientId0();
        } else {
          tableUpdata(patient.id, registion.id, "1");
        }
      }
    };

    return (
      <div
        className={styles.listItem}
        style={{ borderTop: "1px solid #eee" }}
        onClick={() => onclick()}
      >
        <div className={styles.cell}>
          <div className={styles.left}>
            {props?.patientInfo?.patient.sex == 1 && (
              <BoyOutfillIcon
                style={{
                  color: "#77A1FF",
                  width: 16,
                  height: 16,
                }}
              />
            )}
            {props?.patientInfo?.patient.sex == 2 && (
              <GirlOutfillIcon
                style={{
                  color: "#FF8181",
                  width: 16,
                  height: 16,
                }}
              />
            )}
            &nbsp;
            <Tooltip
              placement="topLeft"
              title={props?.patientInfo?.patient.name}
              arrowPointAtCenter
            >
              <div className={styles.name}>
                {props?.patientInfo?.patient.name}
              </div>
            </Tooltip>
            <div className={styles.age}>
              (
              {getAge(
                props?.patientInfo?.patient?.ageYear,
                props?.patientInfo?.patient?.ageMonth,
                "岁"
              )}
              )
            </div>
          </div>
          <div className={styles.right}>
            <Button
              size="small"
              type="link"
              onClick={(e) => {
                e.stopPropagation()
                setVisible(false);
                setSelectId(props?.patientInfo?.patient.id);
                setcurrentAppointmentId(props?.patientInfo.appointmentId);
                setTimeout(() => {
                  setRegisteredModal(true);
                }, 100)
              }}
            >
              挂号
            </Button>
            <Divider
              type="vertical"
              style={{ borderLeft: "1.5px solid #027AFF", margin: "4px 0 0 0" }}
            />
            <Button
              size="small"
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                setVisible(false);
                setSelectId(props?.patientInfo?.patient.id);
                setcurrentAppointmentId(props?.patientInfo.appointmentId);
                setTimeout(() => {
                  setSubscribeModal(true);
                }, 100)
              }}
            >
              预约
            </Button>
          </div>
        </div>
        <div className={styles.cell} style={{ marginTop: "10px" }}>
          <div className={styles.left}>
            <HospitalOutlineIcon
              style={{
                color: "#77A1FF",
                width: 18,
                height: 18,
              }}
            />
            &nbsp;
            <span>{props?.patientInfo?.patient.id}</span>
          </div>
          <div className={styles.right}>
            <PhoneFilled
              style={{
                color: "#ccc",
              }}
            />
            <span>{props?.patientInfo?.patient.phone}</span>
          </div>
        </div>
      </div>
    );
  };

  const Overlay = () => {
    return (
      <>
        {total ? (
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid #2D7BFF",
              borderRadius: "4px",
            }}
          >
            <div className={styles.cell} style={{ padding: "10px" }}>
              <span style={{ color: "#666" }}>共找到{total}个患者</span>
              <span
                style={{ color: "#027AFF", cursor: "pointer" }}
                onClick={(e) => {
                  setVisible(false);
                  setTimeout(() => {
                    setAddPatientModal(true);
                    setModalPatientID("");
                  }, 100)
                }}
              >
                <span style={{ fontSize: "16px" }}>+</span>新增患者
              </span>
            </div>
            <div style={{ maxHeight: "350px", overflow: "auto" }}>
              {patients.map((v: any) => {
                return <PatientItem key={v.id} patientInfo={v} />;
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <Row>
        <Dropdown
          overlay={Overlay}
          visible={visible}
          trigger={["click"]}
          onVisibleChange={(visible) => setVisible(visible)}
        >
          <Input
            autoComplete="off"
            placeholder="患者姓名/助记码/手机号/病历号"
            style={{ width: "15rem" }}
            allowClear
            prefix={<SearchOutlined />}
            onChange={(e) => {
              setNameWS(e.target.value);
            }}
          />
        </Dropdown>
        {/* <Button
          style={{ marginLeft: "10px" }}
          type="primary"
          ghost
          icon={<PlusOutlined />}
          onClick={() => {
            setAddPatientModal(true);
            setModalPatientID("");
          }}
        >
          新增患者
        </Button> */}
      </Row>
      {/* 工作站-挂号 */}
      <RegisteredModal
        title={0}
        patientId={selectId}
        appointmentId={currentAppointmentId} //预约 ID
        registrationId={currentRegistrationId} //挂号 ID
        visible={registeredModal}
        onOk={() => {
          setRegisteredModal(false);
        }}
        onCancel={() => {
          setRegisteredModal(false);
        }}
      />

      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={selectId} //患者 ID
        appointmentId={currentAppointmentId} //预约 ID
        registrationId={currentRegistrationId} //挂号 ID
        treatmentDoctorId={currentTreatmentDoctorId} //就诊医生 ID
        onOk={() => {
          setSubscribeModal(false);
        }}
        onCancel={() => setSubscribeModal(false)}
      />

      {/* 新增患者弹窗 */}
      <AddPatientModal
        visible={addPatientModal}
        onOk={(num, patientId, cardType) => {
          setModalPatientID(patientId || "");
          setAddPatientModal(false);
        }}
        onCancel={() => {
          setAddPatientModal(false);
        }}
      />

      <RegisterNoAppointModal
        id={registerNoAppointId}
        remark={remark}
        visible={registerNoAppointModalVisible}
        onCancel={() => {
          setRegisterNoAppointModalVisible(false);
        }}
        onOk={() => {
          setRegisterNoAppointModalVisible(false);
        }}
      />
    </>
  );
};
