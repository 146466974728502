/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 13:35:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-24 09:26:13
 */
import { Col } from 'antd'
import React, { ReactElement, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Official } from './official/official'
import styles from './wechart.module.css'
import { YouYiKang } from './youYiKang/YouYiKang'

export const WeChartTabbar = (): ReactElement => {
  const [activeId, setActiveId] = useState('0')

  const location: any = useLocation()

  return (
    <Col className={styles.main}>
      <TabBar
        defaultActiveKey={location.state ? location.state.selectKey : '0'}
        onChange={(v) => {
          setActiveId(v)
        }}
      >
        <TabPane key='0' tab='公众号'>
          <Official />
        </TabPane>
        <TabPane key='1' tab='第三方平台'>
          <YouYiKang selectKey={activeId} />
        </TabPane>
      </TabBar>
    </Col>
  )
}
