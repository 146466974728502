/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:48:24
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-09 16:03:44
 */

import { API_PREFIX } from './constant'
import {Request } from './request'

export interface medicalOverviewParams {
  patientId?: any
  stateFlag?: any
}

export function medicalOverviewPage(params: medicalOverviewParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/patientVisitOverview`,
    method: 'GET',
    params
  }
}

 
