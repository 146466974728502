/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 12:05:04
 * @LastEditors: sj
 * @LastEditTime: 2022-07-27 13:42:42
 */
import { Button, Col } from "antd"
import React from "react"
import { useDispatch } from "react-redux"
import { RootDispatch } from "../../app/store"
import { TabBar, TabPane } from "../../compnents/widgets/TabBar"
import styles from "./productCenter.module.css"
import { ProductCenter } from "./productCenter"

export const ProductCenterTabBar = () => {
  const dispatch = useDispatch<RootDispatch>()
  return (
    <Col className={styles.container}>
      <TabBar
        defaultActiveKey={"1"}
        destroyInactiveTabPane
      >
        <TabPane key='1' tab='产品中心'>
          <ProductCenter />
        </TabPane>
      </TabBar>
    </Col>
  )
}
