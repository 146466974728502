import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Form, Input, Modal, Radio, Row } from "antd";
import moment from "moment";
import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserName } from "../../../../app/applicationSlice";
import { RootDispatch } from "../../../../app/store";
import { DateTimeFormat } from "../../../../models/datetime";
import { GenderOptions, getGenderName } from "../../../../models/user";
import {
  getPitestList,
  selectCurrent,
  selectDetail,
  startpitest
} from "./nopitestSlice";

export const PitestModal = (props: {
  queries?:any;
  isModalVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const dispatch = useDispatch<RootDispatch>();
  const [form] = Form.useForm();

  const Current = useSelector(selectCurrent);

  const profile = useSelector(selectDetail);

  const userName = useSelector(selectUserName);
  
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        ...profile,
        result:undefined,
        startTime: moment().format(DateTimeFormat),
        name:userName
        });
    }
  }, [profile,]);
  
  return (
    <Modal
      cancelText="取消"
      okText="确定"
      footer={null}
      visible={props.isModalVisible}
      title="开始皮试"
      width="890px"
      onOk={() => {
        props.onOk();
      }}
      onCancel={() => {
        props.onCancel();
      }}
      
    >
      <Form
        form={form}
        initialValues={{ remember: true }}
        onFinish={(values) => {
          dispatch(
            startpitest({
              result:undefined,
              ...profile,
              ...values,
              
            })
          )
          .then(unwrapResult)
          .then(()=>{
            props.onOk()
            dispatch(getPitestList({...props.queries, current: Current, size:10 }));

          })
        }}
        name="advanced_search"
        className="ant-advanced-search-form"
        {...layout}
      >
        <Row gutter={20}>
          <Col span={10} style={{ textAlign: "right" }}>
            <Form.Item {...layout} label="患者姓名" name="patientName">
              <Input autoComplete="off" disabled/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item {...layout} name="sex" label="性别" >
              <Radio.Group disabled>
                {GenderOptions.map((g) => (
                  <Radio key={g} value={g}>
                    {getGenderName(g)}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={10} style={{ textAlign: "right" }}>
            <Form.Item label="皮试药物" name="drugName">
              <Input autoComplete="off" disabled/>
            </Form.Item>
          </Col>
          {/* <Col   */}
            <Row  style={{ textAlign: "right",marginLeft:50 }}>
            <Form.Item label="年龄" name="ageYear">
              <Input style={{width:40}} disabled/> 
            </Form.Item>
            <Form.Item label="&nbsp;&nbsp;岁" name="ageMonth">
              <Input style={{width:40}} disabled/> 
            </Form.Item>
            <Form.Item label="&nbsp;&nbsp;月&nbsp;&nbsp;" name="ageMonth">
            <Input style={{width:0,background:"#ffffff",border:"none"}} disabled/> 
            </Form.Item>
            </Row>
          {/* </Col> */}
        </Row>
        <Row gutter={20}>
          <Col span={10} style={{ textAlign: "right" }}>
            <Form.Item
              label="开始时间"
              name="startTime"
              rules={[{ required: true, message: "开始时间不能为空!" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={10} style={{ textAlign: "right" }}>
            <Form.Item
              label="皮试人员"
              name="name"
              rules={[{ required: true, message: "皮试人员不能为空!" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={10} style={{ textAlign: "right" }}>
            <Form.Item
              style={{ display: "flex" }}
              label="皮试时间"
              name="presetTime"
            >
            <Input autoComplete="off" />
            {/* <p>分钟</p> */}
            </Form.Item>
          </Col>
         
        </Row>
        <Row gutter={20}>
            <Col span={12}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: "right" }}
              >
                确认
              </Button>
            </Col>
            <Col span={12}>
              <Button
                style={{ float: "left" }}
                onClick={() => {
                  form.resetFields();
                  props.onCancel();
                }}
              >
                取消
              </Button>
            </Col>
          </Row>
      </Form>
    </Modal>
  );
};
