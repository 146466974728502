import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Modal, Form, Select, Input, Row, Col, Button } from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import _ from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../app/store";
import FileUpload from "../../compnents/file/FileUpload";
import { appealSave, getStandDetail } from "./journalSlice";
import styles from "./supplier.module.css";
const { Item } = Form;
const { TextArea } = Input;

export const JournalModal = (props: {
  isJournalModalVisible?: any;
  onOk: () => void;
  onCancel: () => void;
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm();

  const [appealReason, setAppealReason] = useState("");

  const [loadingType, setLoadingType] = useState(false);

  const [detail, setDetail] = useState<any>({});

  const [cautionDetailList, setCautionDetailList] = useState<string[]>([]);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  useEffect(() => {
    if (props.isJournalModalVisible.visible) {
      dispatch(getStandDetail(props.isJournalModalVisible.cautionId))
        .then(unwrapResult)
        .then((res) => {
          setDetail(res);
          setCautionDetailList(
            res.cautionDetail
              .replace(new RegExp("@_@", "gm"), "：")
              .split("&_&")
          );
        });
    }
    return () => {
      setDetail({});
      setFileList([]);
      form.resetFields();
      setLoadingType(false);
    };
  }, [props.isJournalModalVisible.visible]);

  return (
    <Modal
      width={550}
      title='申诉'
      okText='提交'
      footer={null}
      onOk={() => {
        props.onOk()
      }}
      onCancel={() => {
        props.onCancel()
      }}
      visible={props.isJournalModalVisible.visible}
    >
      <Form
        form={form}
        name='advanced_search'
        className='ant-advanced-search-form'
        {...layout}
        onFinish={() => {
          setLoadingType(true)
          dispatch(
            appealSave({
              cautionId: props.isJournalModalVisible.cautionId,
              appealReason,
              attachment: fileList.map((v: any) =>
                `${v.name}@`.concat(v.url || '')
              ),
            })
          )
            .then(unwrapResult)
            .then(() => {
              props.onOk()
              setLoadingType(false)
            })
            .catch(() => {
              _.debounce(() => {
                setLoadingType(false)
              }, 1000)
            })
        }}
      >
        <Item label='警报详情' name='error'>
          <p style={{ margin: 0, marginTop: 5 }}>
            {cautionDetailList.map((v: string, i: number) => {
              if (v.substring(0, 1) === '0') {
                return (
                  <span
                    style={{ marginRight: 5, whiteSpace: 'nowrap' }}
                    key={i}
                  >
                    {v.substring(1)}
                  </span>
                )
              } else {
                return (
                  <p key={i} style={{ marginBottom: 0 }}>
                    {v.substring(1)}
                  </p>
                )
              }
            })}
          </p>
        </Item>
        <Item
          label='申诉理由'
          name='appealReason'
          rules={[{ required: true, message: '请输入申诉理由!' }]}
        >
          <TextArea
            style={{ marginTop: 10 }}
            onChange={(e) => {
              setAppealReason(e.target.value)
            }}
          />
        </Item>
        <Item label='' name='file'>
          <Row style={{ marginLeft: 80 }}>
            <Col span={24}>添加附件</Col>
            <Col
              span={24}
              style={{ fontSize: '12px', color: 'rgb(157 157 157)' }}
            >
              支持图片，word，Excel，txt,pdf；图片最多5张图片，
            </Col>
            <Col
              span={24}
              style={{ fontSize: '12px', color: 'rgb(157 157 157)' }}
            >
              格式为：jpg、png、jpeg等，2MB以内
            </Col>
            <Col span={24} style={{ marginTop: '15px' }}>
              <FileUpload
                bucketName='hydhis-public'
                count={fileList.length || 0}
                files={fileList}
                onUpload={(v, i) => {
                  setFileList([
                    ...fileList,
                    {
                      uid: v.uid,
                      size: v.size,
                      name: v.name,
                      type: v.type,
                      originFileObj: {} as RcFile,
                      url: i,
                    },
                  ])
                }}
              >
                <Button icon={<PlusOutlined />}>上传附件</Button>
              </FileUpload>
              {fileList.map((item) => (
                <Row
                  gutter={24}
                  key={item.uid}
                  style={{
                    padding: '0 10px',
                    width: '400-px',
                  }}
                >
                  <Col flex={1}>
                    <span
                      style={{
                        display: 'inline-block',
                        maxWidth: '60px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        verticalAlign: 'middle',
                      }}
                    >
                      {item.name.split('.')[0]}
                    </span>
                    <span
                      style={{
                        maxWidth: '40px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        verticalAlign: 'middle',
                      }}
                    >
                      .{item.name.split('.')[1]}
                    </span>
                  </Col>
                  <Col span={6}>
                    <a
                      onClick={() => {
                        window.open(item.url, '_blank')
                      }}
                    >
                      查看
                    </a>
                  </Col>
                  <Col span={6}>
                    <CloseOutlined
                      onClick={() => {
                        //  e.stopPropagation();
                        setFileList(() =>
                          fileList.filter((ite) => ite.uid !== item.uid)
                        )
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Item>
        <Row style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button style={{ marginRight: 10 }} onClick={props.onCancel}>
            取消
          </Button>
          <Button type='primary' onClick={form.submit} loading={loadingType}>
            确认
          </Button>
        </Row>
      </Form>
    </Modal>
  )
};
