/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-31 14:23:29
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-13 15:44:48
 */
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountBookOutlined } from "@ant-design/icons";
import { EditableList } from "../../../../compnents/list/EditableList";
import { NavBar } from "../../../../compnents/nav/NavBar";
import { AdditionDrugsColumns } from "./column";
import { HeadQuery } from "./Querys/headQuery";
import { ButtonQuery } from "./Querys/buttonQuery";
import {
  getAddtionDrug,
  getAddtionDrugList,
  removeDurgAsync,
  selectAddtionCurrent,
  selectAddtionDrugData,
  selectAddtionDrugHead,
  selectAddtionTotal,
  setAddtionCurrent,
  submitDurgAsync,
} from "./addtionDrugSlice";
import styles from "./additionDrug.module.css";
import { DataType } from "../../../putstorage/putstorage";
import {
  AddtionDrugParams,
  getAddtionDrugData,
} from "../../../../services/addtionDrug";
import { MintenanceModule } from "./modal";
import { useLocation } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../../../app/store";
import { Button, notification, Row, Table, Pagination } from "antd";
import { maintenanceCompletedAsync } from "../StockMaintenance/stockMaintenanceSlice";
export const AdditionDrug = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const [queries, setQueries] = useState<AddtionDrugParams>();

  const location: any = useLocation();

  const [size, setSize] = useState(5);

  const [IsVisible, setIsVisible] = useState(false);

  const current = useSelector(selectAddtionCurrent);

  const total = useSelector(selectAddtionTotal);

  const data = useSelector(selectAddtionDrugData);

  const headDetail = useSelector(selectAddtionDrugHead);

  const [ids, setIds] = useState<any>();

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setIds(selectedRowKeys.join(","));
    },
  };
  const handleChange = (prev: any, next: any) => {
    const newdata: any = {
      ...prev,
      ...next,
    };
    dispatch(submitDurgAsync([newdata]))
      .then(unwrapResult)
      .then(() => {
        dispatch(
          getAddtionDrugList({
            current,
            size,
            tenantMaintainId: location.state.selectId,
          })
        );
      });
  };

  useEffect(() => {
    if (location.state) {
      dispatch(
        getAddtionDrugList({
          current,
          size,
          tenantMaintainId: location.state.selectId,
        })
      );
    }
  }, [location]);

  useEffect(() => {
    if (location.state) {
      dispatch(
        getAddtionDrug({
          current,
          size,
          tenantMaintainId: location.state.selectId,
          ...queries,
        })
      );
    }
  }, [location, size, current, queries]);


  return (
    <>
      <NavBar
        style={{ margin: "10px 20px 0px 20px" }}
        where={["库存管理", "库存养护单据"]}
        backtrace={{
          name: "养护列表",
          path: "/stockMaintenance",
          routeName: "库房工作站",
        }}
        buttons={
          headDetail.state ? (
            <></>
          ) : (
            <Button
              type="link"
              onClick={() => {
                dispatch(maintenanceCompletedAsync(headDetail.id))
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: "养护完成",
                      duration: 3,
                    });
                    dispatch(
                      getAddtionDrugList({
                        current,
                        size,
                        tenantMaintainId: location.state.selectId,
                      })
                    );
                  });
              }}
              style={{
                color: "#666",
              }}
            >
              <AccountBookOutlined />
              养护完成
            </Button>
          )
        }
      />
      <div className={styles.content}>
        <HeadQuery detail={headDetail} />
        <ButtonQuery
          detail={headDetail}
          onValueChange={(v) => {
            setQueries(v);
          }}
          onSave={() => {
            setIsVisible(true);
          }}
          onRemove={() => {
            dispatch(removeDurgAsync(ids))
              .then(unwrapResult)
              .then(() => {
                dispatch(setAddtionCurrent(1));
                dispatch(
                  getAddtionDrug({
                    current,
                    size,
                    tenantMaintainId: location.state.selectId,
                    ...queries,
                  })
                );
              })
              .catch(() => {
                notification.error({
                  message: "请选择要删除的药品",
                  duration: 3,
                });
              });
          }}
        />
        <EditableList
          pagination={false}
          className={styles.supper}
          rowKey={(t: any) => t.id}
          dataSource={data}
          columns={AdditionDrugsColumns(
            (t) => {
              handleChange(t.prev, t.next);
            },
            size * (current - 1),
            headDetail.state
          )}
          rowSelection={{
            // type: selectionType,
            ...rowSelection,
          }}
        ></EditableList>

        <Row style={{ justifyContent: "flex-end" }}>
          <Pagination
            total={total}
            current={current}
            pageSize={size}
            showSizeChanger
            showQuickJumper
            pageSizeOptions={["5", "10", "15", "20"]}
            style={{
              marginTop: "10px",
            }}
            onChange={(current, pageSize) => {
              setSize(pageSize as number);
              dispatch(setAddtionCurrent(current));
            }}
          />
        </Row>
      </div>
      <MintenanceModule
        tenantMaintainId={location.state.selectId}
        maintainCategory={headDetail.maintainCategory}
        storehouseId={headDetail.storehouseId}
        visible={IsVisible}
        onOk={() => {
          setIsVisible(false);
          dispatch(
            getAddtionDrugList({
              current,
              size,
              tenantMaintainId: location.state.selectId,
            })
          );
        }}
        onCancel={() => {
          setIsVisible(false);
        }}
      />
    </>
  );
};
