/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-20 15:14:05
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-27 13:56:44
 */
import { createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import { fromJson } from '../../models/user'
import {
  getExportBSExcel,
  getExportJJCKExcel,
  getExportJJHKExcel,
  getExportLYCKExcel,
  getExportZYQDExcel,
  getImportExcel,
  ImportExcelParams,
  listExport,
  listExportBody,
  stordetailBody,
  storedetailInvoiceNbSave,
  storedetailRemove,
  storedetailSave,
} from '../../services/storedetail'
import {
  getUserList,
  GetUserListParams,
  getUsersInDepartment,
} from '../..//services/user'
import { detailPutstorage } from '../putstorage/putstorageSlice'
const initialState = {
  PutstoreDate: [],
  SelectId: 0,
  UserData: [],
  ListCurrent: 1,
  ListTotal: 0,
}
export const putstoreSlice = createSlice({
  name: 'putstore',
  initialState,
  reducers: {
    setDurgData(state, action) {
      state.PutstoreDate = action.payload
    },
    setListCurrent(state, action) {
      state.ListCurrent = action.payload
    },
    setlistTotal(state, action) {
      state.ListTotal = action.payload
    },
    setSelectId(state, action) {
      state.SelectId = action.payload
    },
    setUserData(state, action) {
      state.UserData = action.payload
    },
    addRow(state, action) {
      state.PutstoreDate = action.payload
    },
    deleteRow(state, action) {
      state.PutstoreDate = action.payload
    },
    changeRow(state, action) {
      state.PutstoreDate = action.payload
    },
  },
})

export const {
  setDurgData,
  setListCurrent,
  setlistTotal,
  setSelectId,
  setUserData,
  addRow,
  deleteRow,
  changeRow,
} = putstoreSlice.actions

//新增
export const saveStoreDetailAsync = createAsyncThunk<
  void,
  stordetailBody,
  RootThunkApi<void>
>('Putstorage/savelist', async (body, api) => {
  return sendAsync(storedetailSave(body), api)
})

//保存发票号
export const saveStoreDetailInvoiceNbAsync = createAsyncThunk<
  void,
  stordetailBody,
  RootThunkApi<void>
>('Putstorage/savelistInvoiceNb', async (body, api) => {
  return sendAsync(storedetailInvoiceNbSave(body), api)
})

//业务人员
export function getUserName(departmentId: string): RootThunk {
  return api(
    getUsersInDepartment(departmentId, undefined),
    (data: any, dispatch) => {
      const users = _.chain(data.records)
        .map((u) => fromJson(u))
        .value()
      dispatch(setUserData(users))
    }
  )
}

export function getAllUserName(params: GetUserListParams): RootThunk {
  return api(getUserList(params), (data: any, dispatch) => {
    const users = _.chain(data.records)
      .map((u) => fromJson(u))
      .value()
    dispatch(setUserData(users))
  })
}

//删除
export function Removestoredetail(id: string, detailId?: number): RootThunk {
  return api(storedetailRemove(id), (data, dispatch) => {
    dispatch(detailPutstorage(detailId as number))
  })
}


// 导入
export const getImportExcelAsync = createAsyncThunk<
  void,
  ImportExcelParams,
  RootThunkApi
>('/commodity/importExcel', async (body, api) => {
  return sendAsync(getImportExcel(body), api)
})

// 领用出库导出
export const getExportLYCKExcelAsync = createAsyncThunk<void, any, RootThunkApi>(
  '/commodity/exportExcel',
  async (body, api) => {
    return sendAsync(getExportLYCKExcel(body), api)
  }
)


//借记出库导出
export const getExportJJCKxcelAsync = createAsyncThunk<void, any, RootThunkApi>(
  '/commodity/exportExcel',
  async (body, api) => {
    return sendAsync(getExportJJCKExcel(body), api)
  }
)

//借记还库导出
export const getExportJJHKExcelAsync = createAsyncThunk<void, any, RootThunkApi>(
  '/commodity/exportExcel',
  async (body, api) => {
    return sendAsync(getExportJJHKExcel(body), api)
  }
)

//中药清斗单导出
export const getExportZYQDExcelAsync = createAsyncThunk<void, any, RootThunkApi>(
  '/commodity/exportExcel',
  async (body, api) => {
    return sendAsync(getExportZYQDExcel(body), api)
  }
)


//报损导出
export const getExportBSExcelAsync = createAsyncThunk<void, any, RootThunkApi>(
  '/commodity/exportExcel',
  async (body, api) => {
    return sendAsync(getExportBSExcel(body), api)
  }
)


// 导出
export const getListExport = createAsyncThunk<void, listExportBody, RootThunkApi>(
  '/commodity/exportExcel',
  async (body, api) => {
    return sendAsync(listExport(body), api)
  }
)

export const RemovestoredetailAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi<void>
>('Putstorage/removelist', async (id, api) => {
  return sendAsync(storedetailRemove(id), api, true)
})

export function getDepartment(params: GetUserListParams): RootThunk {
  return api(getUserList(params), (data: any, dispatch) => {
    const users = _.chain(data.records)
      .map((u) => fromJson(u))
      .value()
    dispatch(setUserData(users))
  })
}

export const selectorPutstoreData = (state: RootState) =>
  state.putstore.PutstoreDate

export const selectstoreId = (state: RootState) => state.putstore.SelectId

export const sleectUserData = (state: RootState) => state.putstore.UserData

export default putstoreSlice.reducer
