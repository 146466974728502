/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-19 11:46:50
 * @LastEditors: sj
 * @LastEditTime: 2022-09-22 17:47:59
 */
import { Col, Modal, Tabs } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch } from '../../../app/store';
import { Setting } from './setting';
import styles from './setting.module.css'
import { SettingFunction } from './settingFunction';
import { SettingInsurance } from './settingInsurance';
import { selectIsChange, setIsChange } from './settingSlice';

const { TabPane } = Tabs;
function SettingTabbar() {
  const dispatch = useDispatch<RootDispatch>()

  const isChange = useSelector(selectIsChange)

  const [activeKey, setActiveKey] = useState('1')

  return (
    <Col className={styles.containerItem}>
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          if (isChange) {
            Modal.confirm({
              content: '当前页面尚未保存，确认离开？',
              onCancel: () => {
                return
              },
              onOk: () => {
                setActiveKey(key)
                dispatch(setIsChange(false))
              }
            })
            return
          }
          setActiveKey(key)
        }}
        destroyInactiveTabPane
      >
        <TabPane key='1' tab='机构基本信息'>
          <Setting />
        </TabPane>
        <TabPane key='2' tab='机构功能设置信息'>
          <SettingFunction />
        </TabPane>
        <TabPane key='3' tab='医保控费设置'>
          <SettingInsurance />
        </TabPane>
      </Tabs>
    </Col>
  );
}

export default SettingTabbar;
