/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-03-29 13:33:08
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-23 10:23:23
 */
import { Pagination as Pager, Table, TableColumnType, TableProps } from "antd";
import { GetRowKey, TableRowSelection } from "antd/lib/table/interface";
import React, { ReactElement } from "react";
import { Pagination } from "../../models/pagination";
import styles from "./List.module.css";

export interface ListProps<T> extends TableProps<T> {
  pagination: Pagination<T>;
  columns: TableColumnType<T>[];
  onChangePage?: (currentPage: number, pageSize?: number) => void;
  className?: string;
  rowClassName?: any;
}

export function List<T>(props: ListProps<T>): ReactElement {
  const pagination = props.pagination;
  return (
    <div
      style={{
        backgroundColor: props.style?.backgroundColor || "#FFFFFF",
        display: "flex",
        flexDirection: "column",
      }}
      className={props.className}
    >
      <Table
        dataSource={pagination.items as any[]}
        columns={props.columns as TableColumnType<any>[]}
        rowKey={
          props.rowKey
            ? (props.rowKey as GetRowKey<any>)
            : (t: any, index) => (t.id === -1 ? index : t.id)
        }
        pagination={false}
        className={styles.table}
        bordered={props.bordered}
        onRow={props.onRow as any}
        rowClassName={props.rowClassName}
        rowSelection={props.rowSelection as TableRowSelection<any>}
        style={{
          flex: "1",
          overflowY: "auto",
        }}
      />
      <div
        style={{
          padding: "50px 20px 70px 0px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Pager
          pageSize={pagination.pageSize}
          current={pagination.currentPage}
          total={pagination.totalPages}
          showQuickJumper
          showSizeChanger
          size="small"
          onChange={(page, size) => {
            if (props.onChangePage) {
              props.onChangePage(page, size);
            }
          }}
        />
      </div>
    </div>
  );
}
