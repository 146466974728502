/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-07-25 16:52:02
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface GetDiseaseCompletionParams {
  current?: number
  size?: number
  param?: string
  insuranceCode?: string
  querySource?: number
}

export function getDiseaseCompletions(
  params: GetDiseaseCompletionParams
): Request {
  return {
    url: `${API_PREFIX}${
      params.insuranceCode === 'ZIFEI' || !params.insuranceCode
        ? '/blade-outpatient/diseasecode/list'
        : '/blade-insurance/insurance/selectDiseaseCodeList'
    }`,
    method: 'GET',
    params: {
      current: params.current,
      size: params.size,
      param: params.param,
      querySource: params.querySource
    },
  }
}
