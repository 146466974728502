/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-28 14:29:18
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store";
import { API_PREFIX } from "../../services/constant";
import {
  getSuppierImportExcel,
  ImportExcelParams,
  supplierDelete,
  supplierDeleteBody,
  supplierDetail,
  supplierPage,
  supplierParams,
  supplierSubmit,
  supplierSubmitBody,
} from "../../services/supplier"

const initialState = {
  Supplierdata: [],
  Total: 0,
  Detail: {},
  SupplierCategory: 0,
  Current: 1,
  SelectId: 0,
  SupplierSize: 10,
  pageLoading: false,
}

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    setData(state, action) {
      state.Supplierdata = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setDetail(state, action) {
      state.Detail = action.payload
    },
    setSupplierCategory(state, action) {
      state.SupplierCategory = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setSelectId(state, action) {
      state.SelectId = action.payload
    },
    setSupplierPage(state, action) {
      state.SupplierSize = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setData,
  setTotal,
  setDetail,
  setSupplierCategory,
  setCurrent,
  setSelectId,
  setSupplierPage,
  setPageLoading,
} = supplierSlice.actions

export function pageSupplier(params: supplierParams): RootThunk {
  return api(supplierPage(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

export function detailSupplier(id: number): RootThunk {
  return api(supplierDetail(id), (data: any, dispatch) => {
    dispatch(setDetail(data))
    dispatch(setSupplierCategory(data.supplierCategory))
  })
}

export const submitSupplierAsync = createAsyncThunk<
  void,
  supplierSubmitBody,
  RootThunkApi<void>
>('Putstorage/savelist', async (body, api) => {
  return sendAsync(supplierSubmit(body), api)
})

export const deleteSupplierAsync = createAsyncThunk<
  void,
  supplierDeleteBody,
  RootThunkApi<void>
  >('Putstorage/deleteSupplier', async (body, api) => {
  return sendAsync(supplierDelete(body), api)
})


// 导入
export const getSuppierImportExcelAsync = createAsyncThunk<
  void,
  ImportExcelParams,
  RootThunkApi
>('/commodity/importExcel', async (body, api) => {
  return sendAsync(getSuppierImportExcel(body), api)
})


export const selectSupplierdata = (state: RootState) =>
  state.supplier.Supplierdata

export const selectTotal = (state: RootState) => state.supplier.Total

export const selectDetail = (state: RootState) => state.supplier.Detail

export const selectSupplierCategory = (state: RootState) =>
  state.supplier.SupplierCategory

export const selectCurrent = (state: RootState) => state.supplier.Current

export const selectId = (state: RootState) => state.supplier.SelectId

export const selectSize = (state: RootState) => state.supplier.SupplierSize

export const selectPageLoading = (state: RootState) =>
  state.supplier.pageLoading

export default supplierSlice.reducer;