
import { Col } from 'antd'
import React, { ReactElement, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import styles from './coupons.module.css'
import { CouponsSetting } from './couponsSetting'
import { ActiveSetting } from './activeSetting'
import { CouponsIssue } from './couponsIssue'
import { CouponsRecord } from './couponsRecord'


export const CouponsTabBar = (): ReactElement => {
    const [activeId, setActiveId] = useState('0')

    const location: any = useLocation()

    return (
        <Col className={styles.main}>
            <TabBar
                defaultActiveKey={location.state ? location.state.selectKey : '0'}
                onChange={(v) => {
                    setActiveId(v)
                }}
            >
                <TabPane key='0' tab='优惠券领取'>
                    <CouponsRecord selectKey={activeId} />
                </TabPane>
                <TabPane key='1' tab='优惠券发放'>
                    <CouponsIssue selectKey={activeId} />
                </TabPane>
                <TabPane key='2' tab='活动设置'>
                    <ActiveSetting selectKey={activeId} />
                </TabPane>
                <TabPane key='3' tab='优惠券设置'>
                    <CouponsSetting selectKey={activeId} />
                </TabPane>
            </TabBar>
        </Col>
    )
}