import { InfoCircleTwoTone, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Cascader as Cascaded,
  Radio,
  Row,
  Select,
  Space,
  Rate,
  Dropdown,
  Menu,
  message,
  Spin,
  Popover,
} from 'antd'
import { number } from 'echarts/types/src/echarts.all'
import _ from 'lodash'
import moment from 'moment'
import { OptGroup } from 'rc-select'
import React, { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../../../app/applicationSlice'
import { RootDispatch } from '../../../../app/store'
import {
  AppointmentIcon,
  FemaleFilled,
  MaleFilled,
} from '../../../../compnents/icons/Icons'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../../layouts/insuranceSlice'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../../../models/datetime'
import { getTradeCode } from '../../../../models/material'
import { MedicelListValue } from '../../../../models/setting'
import {
  identitys,
  getIdentityName,
  sources,
  getSourceName,
  medicalTreatmentItemss,
  getMedicalTreatmentItemsName,
} from '../../../../models/ToothHome'
import { Gender, getGenderName } from '../../../../models/user'
import { ThemeContext } from '../../../../theme/ThemeContext'
import {
  medicalSendHttp,
  readCardEHttp,
  readCardSHttp,
  strParse,
} from '../../../../utils/MedicalUtils'
import {
  IDcardPattern,
  number2Pattern,
  phonePattern,
  phonePatterns,
} from '../../../../utils/RegExp'
import { getAge } from '../../../../utils/StringUtils'
import { getFollowUpPeopleList } from '../../../followUp/followUpPageSlice'
import { getPrintTemplateSettingUserList } from '../../../print/printSlice'
import {
  getMedicalNum,
  getPatientInfoAsync,
  getPatientList,
  searchQueryPsnAsync,
  selectPatients,
  updateInsuranceCardInfoAsync,
} from '../../../registration/register/registerSlice'
import { getAddress } from '../../../treatment/editor/recipeEditorSlice'
import { FastInput } from '../../../treatment/tcmCases/fastInput'
import styles from '../ToothHome.module.css'
import {
  getCounselor,
  getPatientgroup,
  getSaveAddPatient,
} from '../toothHomeSlice'
import {getLabelList} from "../../../patient/modals/patientSlice";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}
const firstColumnLayout = {
  labelCol: {
    flex: '0 0 7rem',
  },
}
interface AddPatientModalProps {
  visible: boolean
  detailData?: any //修改患者信息
  onOk: (num?: number, obj?: string, cardType?: any) => void
  onCancel: () => void
}

export const AddPatientModal = ({
  visible,
  detailData,
  onOk,
  onCancel,
}: AddPatientModalProps) => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const { TextArea } = Input

  const [treatmentNum, setReatmentNum] = useState<any>() //病历号

  const [patientId, setPatientId] = useState<any>('') //历史病历 ID

  const [cardType, setCardType] = useState('') // 读卡类型

  const [readLoading, setReadLoading] = useState(false)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const [fullCardInfo, setFullCardInfo] = useState<string>()

  const baseData = useSelector(selectInsuranceItem)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const [patientInsurance, setPatientInsurance] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const tenantCategory = useSelector(selectTenantCategory)

  const tenantName = useSelector(selectTenantName)

  const tenantAddress = useSelector(selectTenantAddress)

  const [users, setUsers] = useState<any>([])

  const [readDisabled, setReadDisabled] = useState(false) //   //读卡后禁用

  const [nameDisabled, setNameDisabled] = useState(false) // 自费姓名筛查后禁用

  const [hasCard, setHasCard] = useState(false) //年龄

  const [patientGroupList, setPatientGroupList] = useState<any[]>() //患者分组数据

  const [counselorList, setCounselorList] = useState<any[]>() //咨询师数据

  const [labels, setLabels] = useState<any[]>([]) //标签数据

  const [labelList, setLabelList] = useState<any[]>([]) //标签数据

  const [allList, setAllList] = useState<any[]>() //家庭地址数据

  const [detailAllList, setDetailAllList] = useState<any[]>([]) //修改信息-家庭地址数据

  const [areaId, setAreaId] = useState<any>([]) //家庭地址

  const [codes, setCode] = useState<any>()

  const [isEditPatientName, setIsEditPatientName] = useState(false) //判断用于修改患者姓名时使用

  const [isPatientOverlayVisible, setIsPatientOverlayVisible] = useState(false) //患者姓名输入框下拉查询弹窗

  const [introducerVisible, setIntroducerVisible] = useState(false) //介绍人姓名输入框下拉查询弹窗

  const [keyword, setKeyword] = useState('') //患者姓名输入框

  const [introducerKeyword, setIntroducerKeyword] = useState('') //介绍人姓名输入框

  const [keyWordType, setKeyWordType] = useState(1) //   患者姓名 or 介绍人

  const [peopleType, setPeopleType] = useState<any>(0)

  const [peopleDisabled, setPeopleDisabled] = useState<any>(false)

  const [attendingDoctorList, setAttendingDoctorList] = useState<any>([]) //  主治医生数据

  const [pageLoading, setPageLoading] = useState(false) // 页面加载状态

  const [saveType, setSaveType] = useState(1) //  保存状态

  const [btnLoading, setBtnLoading] = useState(false) //  按钮保存状态

  const patients: any = useSelector(selectPatients)

  const [personList, setPersonList] = useState<any>() //员工介绍人

  const [ageYears, setAgeYear] = useState<any>()

  const [ageMonths, setAgeMonth] = useState<any>()

  const [param, setParam] = useState<any>()

  const [idCardRules, setIdCardRules] = useState<any[]>([
    {
      pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
      message: '身份证格式错误',
    },
    {
      max: 18,
      message: '最长18位!',
    },
  ])

  const [otherCountyCard, setOtherCountyCard] = useState(false)

  useEffect(() => {
    if (visible) {
      setPeopleDisabled(false)
      setPeopleType(0)
      getDoctorList()
    }
  }, [visible])

  const getDoctorList = () => {
    dispatch(getPrintTemplateSettingUserList({ state: 1, param: param }))
      .then(unwrapResult)
      .then((users: any) => {
        setUsers(users)
      })
      .catch(() => {
        // do nothing.
      })
  }
  useEffect(() => {
    getDoctorList()
  }, [param])

  useEffect(() => {
    // 员工介绍人
    if (peopleType == 1)
      dispatch(
        getFollowUpPeopleList({
          current: 1,
          size: 500,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setPersonList(
            res.records?.map((v: any) => {
              return {
                name: v.realname,
              }
            })
          )
        })
  }, [peopleType])

  const getResiterMedicalNum = () => {
    //获取病历号
    dispatch(getMedicalNum())
      .then(unwrapResult)
      .then((res) => {
        setReatmentNum(res)
      })
  }

  const readMessage = () => {
    if (hasCard && !otherCountyCard) {
      notification.info({
        message: '医保信息只读，不可修改',
        duration: 1,
      })
    }
  }

  // useEffect(() => {
  //   setIsPatientOverlayVisible(patients.length != 0)
  // }, [patients])

  const patientOverlay = ( //患者姓名 and 介绍人 查询内容
    <Menu style={{ height: '300px', overflowY: 'scroll' }}>
      {(peopleType == 1 && keyWordType == 2 ? personList : patients)?.map(
        (p: any) => (
          <Menu.Item
            key={p.id}
            onClick={() => {
              dispatch(getAddress(p.cityCode))

              setCode({
                provinceCode: p?.provinceCode,
                contryCode: p?.contryCode,
                cityCode: p?.cityCode,
              })

              if (keyWordType == 1) {
                const tempArr = p?.labelName?.split(',')?.map((v: string) => {
                  return labels?.filter((i: any) => i.label == v)
                })
                p.provinceCode && getaddressDetail(p.provinceCode)
                p.cityCode && getaddressDetail(p.cityCode)
                form.setFieldsValue({
                  ...p,
                  sex: String(p.sex),
                  placeholderAge: '  ', //年龄占位
                  // attendingDoctor: Number(p.attendingDoctor) == 0 ? '' : Number(p.attendingDoctor),
                  // counselor: Number(p.counselor) == 0 ? '' : Number(p.counselor),
                  labelIdList: tempArr?.flat()?.map((v: any) => v.value),
                  introducerType:
                    p.introducerType && p.introducerType == '-1'
                      ? ''
                      : p.introducerType,
                  attendingDoctor: p.attendingDoctor
                    ? attendingDoctorList?.filter(
                      (v: any) => v.id == p.attendingDoctorId
                    )[0]?.id
                    : undefined,
                  counselor:
                    p.counselor && counselorList
                      ? counselorList.filter(
                        (v: any) => v.id == p.counselorId
                      )[0]?.id
                      : undefined,
                })
                if (p?.idCardNumber) {
                  getIdCardInfo(p?.idCardNumber)
                } else if (p?.birthday) {
                  updateYearsAndMonths()
                }
                setPeopleDisabled(
                  p.introducerType && p.introducerType == '-1' ? true : false
                )
                setPatientId(p.id)
                setIntroducerKeyword(p.introducer || '')
              } else if (keyWordType == 2) {
                form.setFieldsValue({
                  introducer: p.name,
                })
              }
              keyWordType == 1 && setNameDisabled(true)
              keyWordType == 1
                ? setKeyword(p.name)
                : setIntroducerKeyword(p.name)
              keyWordType == 1
                ? setIsPatientOverlayVisible(false)
                : setIntroducerVisible(false)
            }}
          >
            {peopleType == 1 && keyWordType == 2 ? (
              <Space>
                <span>{p.name}</span>
              </Space>
            ) : (
              <Space>
                <span>{p.name}</span>
                <span>{getGenderName(p.sex)}</span>
                <span>{getAge(p.ageYear, p.ageMonth, '岁')}</span>
                <span>{p.phone}</span>
                <span>{p.tenantPatientCardId}</span>
              </Space>
            )}
          </Menu.Item>
        )
      )}
    </Menu>
  )

  const updateBirthday = () => {
    const year = parseInt(form.getFieldValue('ageYear')) || 0
    const month = parseInt(form.getFieldValue('ageMonth')) || 0
    if (year !== 0 || month !== 0) {
      const months = year * 12 + month
      const birthday = moment().subtract(months, 'months').format('YYYY/MM/DD')
      form.setFieldsValue({ birthday })
    }
  }

  const updateYearsAndMonths = (brdy?: any) => {
    let date
    if (!brdy) {
      date = moment(form.getFieldValue('birthday'))
    } else {
      date = moment(brdy)
    }
    if (!date.isValid()) {
      return
    }
    const duration = moment.duration(moment().diff(date))
    if (duration.years() < 0 || duration.months() < 0) {
      return
    }

    form.setFieldsValue({
      ageYear: duration.years(),
      ageMonth: duration.months() == 0 ? 1 : duration.months(),
    })
    const year = duration.years()
    const month = duration.months() == 0 ? 1 : duration.months()
    if (year !== 0 || month !== 0) {
      if (brdy) {
        const months = year * 12 + month
        const birthday = moment()
          .subtract(months, 'months')
          .format('YYYY/MM/DD')
        form.setFieldsValue({ birthday })
        form.setFieldsValue({ placeholderAge: '1111' })
      }
    }
  }

  const getGroup = () => {
    // 获取患者分组
    dispatch(
      getPatientgroup({
        current: 1,
        size: 100,
      })
    )
      .then(unwrapResult)
      .then(({ records }) =>
        setPatientGroupList(
          records?.map((v: any) => {
            return {
              label: v.groupName,
              value: v.id,
            }
          })
        )
      )
  }

  const getlabelList = () => {
    //获取标签
    dispatch(getLabelList({current:1,size:1000,state:0}))
      .then(unwrapResult)
      .then((ls) => {
        setLabels(
          ls?.map((v) => {
            return {
              label: v.name,
              value: v.id,
              labelType: v.labelType,
              level: v.level
            }
          })
        )
      })
  }

  useEffect(() => {
    //过滤自动标签的父标签
    const filter = labels.filter((v) => !(v.labelType === 1 && v.level === 1));
    setLabelList(filter)
  }, [labels])

  const getCounselorFn = (num: number) => {
    // 获取咨询师
    dispatch(
      getCounselor({
        current: 1,
        size: 100,
        stationCategory: num, // 1:主治医生  4:咨询师
      })
    )
      .then(unwrapResult)
      .then(({ records }) =>
        num == 1
          ? setAttendingDoctorList(
            records
              .filter((v: any) => v.kssxbz != 1)
              .filter((v: any) => v.state == 1)
          )
          : setCounselorList(
            records
              .filter((v: any) => v.kssxbz != 1)
              .filter((v: any) => v.state == 1)
          )
      )
  }
  const getAddressFn = () => {
    //获取地址
    dispatch(getAddress('0'))
      .then(unwrapResult)
      .then((res: any) => {
        setAllList(
          res?.map((el: any) => {
            return { value: el.code, label: el.name, isLeaf: false }
          })
        )
      })
  }

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    if (selectedOptions.length <= 2) {
      dispatch(getAddress(targetOption?.value))
        .then(unwrapResult)
        .then((res: any) => {
          targetOption.children = res?.map((el: any) => {
            return {
              value: el.code,
              label: el.name,
              isLeaf: selectedOptions.length === 2,
            }
          })
          setAllList([...allList!])
        })
    }
  }

  useEffect(() => {
    if (detailAllList.length) {
      const tempArr =
        allList?.filter((v: any) => v.value == codes?.provinceCode) || ''
      const tempArr2 =
        detailAllList?.filter((v: any) => v.code == codes?.cityCode) || ''
      const tempArr3 =
        detailAllList?.filter((v: any) => v.code == codes?.contryCode) || ''
      if (tempArr?.length && tempArr2?.length && tempArr2?.length)
        form.setFieldsValue({
          addressData: [
            tempArr[0]?.label,
            tempArr2[0]?.name,
            tempArr3[0]?.name,
          ],
        })
    } else {
      const tempArr =
        allList?.filter(
          (v: any) => v.value == detailData?.patient?.provinceCode
        ) || ''
      const tempArr2 =
        detailAllList?.filter(
          (v: any) => v.code == detailData?.patient?.cityCode
        ) || ''
      const tempArr3 =
        detailAllList?.filter(
          (v: any) => v.code == detailData?.patient?.contryCode
        ) || ''
      if (tempArr?.length && tempArr2?.length && tempArr2?.length)
        form.setFieldsValue({
          addressData: [
            tempArr[0]?.label,
            tempArr2[0]?.name,
            tempArr3[0]?.name,
          ],
        })
    }
  }, [detailAllList, allList])

  const getaddressDetail = (valueCode: string) => {
    dispatch(getAddress(valueCode))
      .then(unwrapResult)
      .then((res: any) => {
        setDetailAllList((el) => {
          return [...el, ...res]
        })
      })
  }

  const SaveAdd = (params: any) => {
    //保存患者
    setBtnLoading(true)
    dispatch(getSaveAddPatient(params))
      .then(unwrapResult)
      .then((res) => {
        notification.success({ message: '保存成功' })
        onOk(saveType, res?.id, cardType)
        setDetailAllList([])
        form.resetFields()
        form.setFieldsValue({
          addressData: undefined,
        })
      })
      .finally(() => setBtnLoading(false))
  }

  const getIdCardInfo = (idCard: string) => {
    const date = new Date()
    const getMonth = date.getMonth() + 1 //当前月份
    const getFullYear = date.getFullYear() //当前年份

    let month = 0
    let year = 0
    let birthday = ''
    //  身份证上的年月日
    if (idCard) {
      //先判断是不是中国大陆身份证
      if (
        (idCard.length != 15 && idCard.length != 16 && idCard.length != 18) ||
        isNaN(Number(idCard.substr(0, 6)))
      ) {
        setOtherCountyCard(true)
        setIdCardRules([
          {
            max: 18,
            message: '最长18位!',
          },
        ])
      } else {
        setOtherCountyCard(false)
        setIdCardRules([
          {
            pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '身份证格式错误',
          },
          {
            max: 18,
            message: '最长18位!',
          },
        ])
        if (idCard.length === 18) {
          month = parseInt(idCard?.substr(10, 2)) //身份证上的月份
          year = parseInt(idCard?.substr(6, 4)) //身份证上的年份
          birthday = moment(idCard?.substr(6, 8)).format(DateTimeFormatSimple)
        } else if (idCard.length === 15 || idCard.length === 16) {
          //16位示例3305229603021031，少了两位年份
          //15位示例330522960302103，少了两位年份，最后的序号是3位
          month = parseInt(idCard?.substr(8, 2)) //身份证上的月份
          year = parseInt('19' + idCard?.substr(6, 2)) //身份证上的年份
          birthday = moment('19' + idCard?.substr(6, 6)).format(
            DateTimeFormatSimple
          )
        }
        if (
          (idCard.length != 18 && idCard.length != 16 && idCard.length != 15) ||
          idCard === ''
        ) {
          form.setFieldsValue({
            ageYear: '',
            age: '',
            ageMonth: '',
            birthday: '',
            placeholderAge: '', //年龄占位
          })
          setAgeYear('')
          setAgeMonth('')
        } else {
          if (getMonth - month > 0) {
            form.setFieldsValue({
              ageYear: getFullYear - year,
              age: getFullYear - year,
              ageMonth: getMonth - month,
              birthday:
                idCard.length === 18 ||
                  idCard.length == 16 ||
                  idCard.length == 15
                  ? birthday
                  : '',
              placeholderAge: '1111', //年龄占位
            })
            setAgeYear(getFullYear - year)
            setAgeMonth(getMonth - month)
          } else if (getMonth - month < 0) {
            form.setFieldsValue({
              ageYear: getFullYear - year - 1,
              age: getFullYear - year - 1,
              ageMonth: 12 - (month - getMonth),
              birthday:
                idCard.length === 18 ||
                  idCard.length == 16 ||
                  idCard.length == 15
                  ? birthday
                  : '',
              placeholderAge: '1111', //年龄占位
            })
            setAgeYear(getFullYear - year - 1)
            setAgeMonth(12 - (month - getMonth))
          } else if (getMonth - month === 0) {
            form.setFieldsValue({
              ageYear: getFullYear - year < 0 ? 1 : getFullYear - year,
              age: getFullYear - year < 0 ? 1 : getFullYear - year,
              ageMonth: 0,
              birthday:
                idCard.length === 18 ||
                  idCard.length == 16 ||
                  idCard.length == 15
                  ? birthday
                  : '',
              placeholderAge: '1111', //年龄占位
            })
            setAgeYear(getFullYear - year - 1)
            setAgeMonth(1)
          }
        }
      }
    }
  }

  const searchMedical = (
    // 获取患者特慢病信息
    res: any,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      searchQueryPsnAsync({
        serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          insuplcAdmdvs: res?.insuranceRegion,
          psnNo: res?.ybPersonalNo,
          // 测试代码
          // insuplcAdmdvs: '330599',
          // psnNo: '33050099000010000000525114',
        },
      }) as any
    )
      .then(unwrapResult)
      .then(async (result: any) => {
        if (serverType === 'requestString') {
          const data = strParse(result)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              noSuccess: true,
            },
            (call: any) => {
              searchMedical(res, 'response', JSON.stringify(call.netmessage))
            }
          )
        }
      })
  }

  // 获取参保人信息
  const getPatientInfo = (
    fullCardInfo: string,
    serverType: any,
    responseString?: string
  ) => {
    setFullCardInfo(fullCardInfo)
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          cardType: cardType,
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                success('正在获取人员信息')
                getPatientInfo(
                  fullCardInfo,
                  'response',
                  JSON.stringify(call.netmessage)
                )
              } else {
                setPageLoading(false)
                form.resetFields()
                setCardType('')
              }
            }
          )
        } else {
          setPageLoading(false)
          const name = form.getFieldValue('name')
          const idCardNumber = form.getFieldValue('idCardNumber')
          if (
            (name && res?.patientInsurance.name !== name) ||
            (idCardNumber && res?.patientInsurance.idCard !== idCardNumber)
          ) {
            notification.error({
              message: '人卡不一致',
            })
            setCardType('')
            return
          }
          successMessage('读卡成功')
          patientId &&
            dispatch(
              updateInsuranceCardInfoAsync({
                ...res?.patientInsurance,
                fullCardInfo: fullCardInfo || '',
                insuranceCode: insuranceItem.insuranceCode,
                cardType,
                patientId,
              })
            )
          setPatientInsurance(res?.patientInsurance)
          searchMedical(res?.patientInsurance, 'requestString')
          setReadDisabled(true)
          getIdCardInfo(res?.patientInsurance.idCard)
          if (
            res?.patientInsurance?.birthday &&
            res?.patientInsurance?.idCard?.length < 15
          ) {
            updateYearsAndMonths(res?.patientInsurance?.birthday)
          }
          form.setFieldsValue({
            // ybkh: res?.patientInsurance.CardNum,
            name: res?.patientInsurance.name,
            idCardNumber: res?.patientInsurance.idCard,
            // cbxzmc: res?.patientInsurance.cbxzmc,
            // memberTypemc: res?.patientInsurance.memberTypemc,
            // treatmentStatusmc: res?.patientInsurance.treatmentStatusmc,
            // zhye: res?.patientInsurance.zhye,
            // dnzhye: res?.patientInsurance.dnzhye,
            // lnzhye: res?.patientInsurance.lnzhye,
            companyName: res?.patientInsurance.brdw,
            // ybPersonalNo: res?.patientInsurance.ybPersonalNo,
            sex: res?.patientInsurance.sex,
            phone: res?.patientInsurance.phone,
          })
          setKeyword(res?.patientInsurance.name)
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 3,
        })
        setCardType('')
        setPageLoading(false)
      })
  }

  const success = (str: string) => {
    setReadLoading(true)
    const hide = message.loading(str, 0)
    setTimeout(() => {
      hide()
      setReadLoading(false)
    }, 3000)
  }
  const successMessage = (str: string) => {
    setHasCard(true)
    notification.success({
      message: str,
    })
  }

  // 电子凭证读卡
  const readEType = () => {
    // setCardType('')
    readCardEHttp(
      {
        trade_args: [
          insuranceItem.countryHospitalNb,
          '01301',
          '',
          userId,
          userName,
          '',
          '',
        ],
      },
      (data, err) => {
        if (!err) {
          const infoList = data.netmessage.split('|')
          getIdCardInfo(infoList[1])
          form.setFieldsValue({
            idCardNumber: infoList[1],
            // ybkh: infoList[1],
          })
          getPatientInfo(data.netmessage, 'requestString')
        }
      }
    )
  }

  const readSfz = () => {
    setPageLoading(true)
    const idCardNumber = form.getFieldsValue().idCardNumber
    if (idCardNumber) {
      getPatientInfo(idCardNumber, 'requestString')
    } else {
      notification.info({
        message: '请输入身份证号',
      })
    }
  }
  const readSType = () => {
    //医保读卡
    setPageLoading(true)
    readCardSHttp(
      {
        trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
      },
      (data, err) => {
        if (!err) {
          getPatientInfo(
            baseData?.insuranceCode == 'JIANGSU'
              ? data.netmessage
              : JSON.stringify(data.netmessage),
            'requestString'
          )
        } else {
          setCardType('')
          setPageLoading(false)
        }
      }
    )
  }

  useEffect(() => {
    if (detailData) return
    cardType == '3' && readSType()
    cardType == '2' && readSfz()
    cardType == '1' && readEType()
  }, [cardType])

  useEffect(() => {
    //患者姓名 or 介绍人查询
    if (peopleType == 0) {
      dispatch(getPatientList(keyWordType == 1 ? keyword : introducerKeyword))
    } else {
      dispatch(
        getFollowUpPeopleList({
          current: 1,
          size: 500,
          param: introducerKeyword,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setPersonList(
            res.records?.map((v: any) => {
              return {
                name: v.realname,
              }
            })
          )
        })
    }
  }, [dispatch, keyword, introducerKeyword])

  useEffect(() => {
    //医保信息
    if (!visible) return
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    } else {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem, visible])

  useEffect(() => {
    let timer: any = null
    if (visible) {
      
      getGroup()
      getCounselorFn(1)
      getCounselorFn(4)
      getlabelList()
      getAddressFn()
      getResiterMedicalNum() //获取病历号
      timer = setInterval(() => {
        getResiterMedicalNum()
      }, 5000)
      insuranceArray.length !== 0 && setInsuranceItem(insuranceArray[0])
      if (detailData && JSON.stringify(detailData) != '{}') {

        //患者管理-修改患者信息
        const { patient, labels, patientInsuranceVO } = detailData
        console.log(patient,"patientpatient");
        setKeyWordType(
          patient.introducerType == -1 ? '' : patient.introducerType
        )
        setPeopleType(
          patient.introducerType == -1 ? '' : patient.introducerType
        )
        setPatientId(patient.id)
        setKeyword(patient.name)
        setIntroducerKeyword(patient.introducer)
        setNameDisabled(true)
        if (patientInsuranceVO && JSON.stringify(patientInsuranceVO) != '{}') {
          setReadDisabled(true)
          setCardType('3')
          setPatientInsurance(patientInsuranceVO)
        }
        patient.provinceCode && getaddressDetail(patient.provinceCode)
        patient.cityCode && getaddressDetail(patient.cityCode)
        form.setFieldsValue({
          ...patient,
          labelIdList: labels?.map((v: any) => String(v.id)),
          sex: String(patient.sex),
          introducerType:
            patient.introducerType == -1 ? '' : patient.introducerType,
          counselor: patient.counselorId == -1 ? '' : patient.counselorId,
          attendingDoctor:
            patient.attendingDoctorId == -1 ? '' : patient.attendingDoctorId,
          placeholderAge: '  ', //年龄占位
          ageYear: patient.ageYear == -1 ? 0 : patient.ageYear,
          ageMonth: patient.ageMonth == -1 ? 0 : patient.ageMonth,
        })
      }
    } else {
      form.resetFields()
      setCardType('')
      setKeyword('')
      setIntroducerKeyword('')
      setReadDisabled(false)
      setPageLoading(false)
      setPatientId('')
      setNameDisabled(false)
      setHasCard(false)
      timer && clearInterval(timer)
    }

    return () => {
      setIsPatientOverlayVisible(false)
      timer && clearInterval(timer)
    }
  }, [visible])

  const close = useCallback(
    _.throttle(() => {
      setIsPatientOverlayVisible(false)
    }, 500),
    []
  )

  return (
    <Modal
      title={detailData ? '修改患者' : '新增患者'}
      width={1200}
      visible={visible}
      onCancel={() => {
        onCancel()
        setDetailAllList([])
      }}
      className={styles.addPatientModal}
      footer={null}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      centered
    >
      <div
        // className={styles.modalWrap}
        onScroll={(e) => {
          close()
        }}
      >
        <Spin spinning={pageLoading}>
          <Form
            form={form}
            {...layout}
            style={{ paddingRight: 10 }}
            initialValues={{
              groupId: patientGroupList?.find((v: any) => v.label == '最近患者')
                ?.value
                ? [
                  patientGroupList?.find((v: any) => v.label == '最近患者')
                    ?.value,
                ]
                : undefined, //默认选中最近分组
              relationship: '本人', //联系方式关系
              // relationshipEmergencyContact: '本人',   //紧急联系方式关系
            }}
            onFinish={(values) => {
              console.log(patientId,"patientId");
              
              // if (
              //   values.idCardNumber &&
              //   !IDcardPattern.pattern.test(values.idCardNumber)
              // ) {
              //   notification.error({ message: '请输入正确的身份证号码！' })
              //   return
              // }
              if (
                values.emergencyContactPhone &&
                !phonePatterns.pattern.test(values.emergencyContactPhone)
              ) {
                notification.error({ message: '请输入正确的紧急联系方式！' })
                return
              }
              if (values.qq && !number2Pattern.pattern.test(values.qq)) {
                notification.error({ message: '请输入正确的QQ号码！' })
                return
              }
              if (
                values?.addressData?.length == 1 ||
                values?.addressData?.length == 2
              ) {
                notification.error({ message: '请选择家庭地址！' })
                return
              }
              const obj = {
                ...values,
                treatmentNo: treatmentNum, // 病历号
                labelIdList: undefined,
                provinceCode: values.addressData?.length
                  ? values.addressData[0]
                  : undefined,
                cityCode:
                  values.addressData?.length >= 1
                    ? values.addressData[1]
                    : undefined,
                contryCode:
                  values.addressData?.length >= 2
                    ? values.addressData[2]
                    : undefined,
                attendingDoctorId: values.attendingDoctor,
                attendingDoctor: attendingDoctorList?.filter(
                  (v: any) => v.id == values.attendingDoctor
                )[0]?.realname,
                groupId: undefined,
                id: patientId,
                counselorId: values.counselor,
                counselor: counselorList?.filter(
                  (v: any) => v.id == values.counselor
                )[0]?.realname,
              }
              if (
                values.addressData?.length &&
                !Number(values.addressData[0])
              ) {
                const tempArr =
                  allList?.filter(
                    (v: any) => v.label == values.addressData[0]
                  ) || ''
                obj.provinceCode = tempArr[0].value
              }
              if (
                values.addressData?.length &&
                !Number(values.addressData[1])
              ) {
                const tempArr2 =
                  detailAllList?.filter(
                    (v: any) => v.name == values.addressData[1]
                  ) || ''
                obj.cityCode = tempArr2[0].code
              }
              if (
                values.addressData?.length &&
                !Number(values.addressData[2])
              ) {
                const tempArr3 =
                  detailAllList?.filter(
                    (v: any) => v.name == values.addressData[2]
                  ) || ''
                obj.contryCode = tempArr3[0].code
              }
              SaveAdd({
                cardType: cardType,
                labelIdList: values.labelIdList || undefined,
                groupIdList: values?.groupId || undefined,
                patient: {
                  ...obj,
                  introducerType: peopleDisabled ? '' : obj.introducerType || 0,
                }, //自费
                patientInsurance: cardType
                  ? {
                    ...patientInsurance,
                    fullCardInfo: fullCardInfo || '',
                    insuranceCode: insuranceItem.insuranceCode,
                    cardType,
                  }
                  : {}, //医保
              })
            }}
            onFinishFailed={({ errorFields }) => {
              if (errorFields.length) {
                notification.info({
                  message: '请填写必填项',
                })
              }
              form.scrollToField(errorFields[0]?.name[0])
            }}
          >
            <div
              className={styles.modalWrapAddPatient}
              onScroll={(e) => {
                close()
              }}
            >
              <Row>
                {detailData ? (
                  <>
                    <Col span={12}>
                      <Form.Item
                        name='name'
                        label='患者姓名'
                        rules={[{ required: true }]}
                      >
                        <Input allowClear placeholder={'请输入患者姓名'} />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <Col span={12}>
                    <Form.Item
                      name='name'
                      label={'患者姓名'}
                      rules={[{ required: true }]}
                    >
                      <Dropdown
                        overlay={patientOverlay}
                        visible={isPatientOverlayVisible}
                        disabled={readDisabled || nameDisabled}
                      >
                        <Input
                          allowClear
                          placeholder={'患者姓名/助记码/手机号/病历号'}
                          prefix={
                            <SearchOutlined style={{ color: theme.tc3 }} />
                          }
                          value={keyword}
                          onFocus={() => {
                            setKeyWordType(1)
                            dispatch(getPatientList(keyword))
                            setIsPatientOverlayVisible(true)
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              setIsPatientOverlayVisible(false)
                            }, 300)
                          }}
                          onClick={() => {
                            setKeyWordType(1)
                            setIsPatientOverlayVisible(true)
                          }}
                          onChange={(e) => {
                            form.setFieldsValue({
                              name: e.target.value,
                            })
                            setIsPatientOverlayVisible(true)
                            setKeyword(e.target.value)
                          }}
                        />
                      </Dropdown>
                    </Form.Item>
                  </Col>
                )}
                {!detailData ? (
                  <Col span={1} style={{ marginLeft: '30px' }}>
                    <a
                      style={{ borderBottom: '2px solid #69a2f9' }}
                      onClick={() => {
                        form.resetFields()
                        setReadDisabled(false)
                        setCardType('')
                        setKeyword('')
                        setIntroducerKeyword('')
                        setPatientId('')
                        setNameDisabled(false)
                      }}
                    >
                      清空
                    </a>
                  </Col>
                ) : (
                  <Col span={3} style={{ marginLeft: '30px' }}></Col>
                )}

                {insuranceArray.length !== 0 &&
                  (!detailData ? (
                    <>
                      <Col span={2}>
                        <a
                          style={{ borderBottom: '2px solid #69a2f9' }}
                          onClick={() => {
                            setCardType('1')
                          }}
                        >
                          读电子凭证
                        </a>
                      </Col>
                      {tenantName?.indexOf('测试') >= 0 && (
                        <Col style={{ width: '100px' }}>
                          <Button
                            style={{
                              padding: '0px 5px 0px 5px',
                              // borderBottom: '2px solid',
                            }}
                            // type='link'
                            type='primary'
                            onClick={() => {
                              setCardType('2')
                            }}
                          >
                            身份证号码
                          </Button>
                        </Col>
                      )}
                      <Col span={3}>
                        <a
                          style={{ borderBottom: '2px solid #69a2f9' }}
                          onClick={() => {
                            if (cardType == '3') {
                              notification.info({ message: '已读卡请清空信息' })
                              return
                            }
                            setCardType('3')
                          }}
                        >
                          读医保卡
                        </a>
                      </Col>
                    </>
                  ) : (
                    ''
                  ))}
                <div
                  className={
                    detailData ? styles.caseNumWrapEdit : styles.caseNumWrap
                  }
                >
                  病历号：{patientId || '-'}
                </div>
              </Row>
              {readDisabled && (
                <Row
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    margin: '-14px 0px 4px 11.9%',
                  }}
                >
                  <Col span={24}>
                    {/* {patientInsurance?.name} */}
                    {patientInsurance?.insuranceCode !== 'ZIFEI'
                      ? `${insuranceArray?.filter(
                        (v) =>
                          v?.insuranceCode ==
                          patientInsurance?.insuranceCode
                      )?.[0]?.insuranceName
                      } 个账余额：${patientInsurance?.zhye}元 `
                      : ' '}
                    {patientInsurance?.memberTypemc} {'（'}
                    {getGenderName(patientInsurance?.sex)},
                    {getAge(
                      form.getFieldValue('ageYear') || ageYears,
                      form.getFieldValue('ageMonth') || ageMonths,
                      '周岁'
                    )}
                    {'）'}
                    {patientInsurance?.insuranceCode &&
                      patientInsurance?.insuranceCode != 'ZIFEI' && (
                        <Popover
                          content={
                            <div>
                              <p>姓名：{patientInsurance?.name}</p>
                              <p style={{ fontWeight: 'bold' }}>
                                个账余额：{patientInsurance?.zhye}
                              </p>
                              <p style={{ fontWeight: 'bold' }}>
                                当年账户余额：{patientInsurance?.dnzhye}
                              </p>
                              <p style={{ fontWeight: 'bold' }}>
                                历年账户余额：{patientInsurance?.lnzhye}
                              </p>
                              <p>工作单位：{patientInsurance?.brdw}</p>
                              <p>参保险种：{patientInsurance?.cbxzmc}</p>
                              <p>人员类型：{patientInsurance?.memberTypemc}</p>
                            </div>
                          }
                          placement='bottom'
                          title='更多医保信息'
                        >
                          <InfoCircleTwoTone
                            style={{
                              color: '#2092c9',
                              fontSize: '18px',
                              marginTop: '4px',
                            }}
                          />
                        </Popover>
                      )}
                  </Col>
                </Row>
              )}

              <Row>
                <Col span={12}>
                  <Form.Item
                    name='sex'
                    label='性别'
                    rules={[{ required: true }]}
                  >
                    <Radio.Group disabled={readDisabled}>
                      <Radio value='1'>男</Radio>
                      <Radio value='2'>女</Radio>
                      <Radio value='0'>未知</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}  className={styles.birthdayStyle}
                >
                  <Form.Item
                    label='年龄'
                    name='placeholderAge'
                    rules={[{ required: true, message: '' }]}
                  >
                    <Row
                      wrap={false}
                      align='middle'
                      style={{ marginTop: '-6px' }}
                    >
                      <Form.Item
                        name='ageYear'
                        noStyle
                        dependencies={['ageMonth']}
                        rules={[
                          {
                            pattern: /^\d{0,3}$/,
                            message: '请输入正确的年龄',
                          },
                          ({ getFieldValue }) => ({
                            validator: (_, value) => {
                              if (!value && !getFieldValue('ageMonth')) {
                                return Promise.reject(
                                  new Error('请输入正确的年龄')
                                )
                              }
                              return Promise.resolve()
                            },
                          }),
                        ]}
                      >
                        <Input
                          // disabled={readDisabled && !otherCountyCard}
                          readOnly={hasCard && !otherCountyCard}
                          onClick={() => readMessage()}
                          onChange={updateBirthday}
                          style={{ maxWidth: '3rem' }}
                        />
                      </Form.Item>
                      <div
                        style={{
                          color: theme.tc2,
                          fontSize: '1rem',
                          margin: '0 6px',
                        }}
                      >
                        岁
                      </div>
                      <Form.Item
                        name='ageMonth'
                        noStyle
                        dependencies={['ageYear']}
                        rules={[
                          {
                            pattern: /^(0?[0-9]|1[0-1])$/,
                            message: '请输入正确的月份',
                          },
                        ]}
                      >
                        <Input
                          // disabled={readDisabled && !otherCountyCard}
                          readOnly={hasCard && !otherCountyCard}
                          onClick={() => readMessage()}
                          onChange={updateBirthday}
                          style={{ maxWidth: '3rem' }}
                        />
                      </Form.Item>
                      <div
                        style={{
                          color: theme.tc2,
                          fontSize: '1rem',
                          margin: '0 6px',
                        }}
                      >
                        月
                      </div>
                      <Form.Item
                        name='birthday'
                        noStyle
                        style={{ flex: 1 }}
                        rules={[
                          { max: 10, message: '最多10个字' },
                          {
                            pattern: /^(?:1\d{3}|2\d{3})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])|(?:1\d{3}|2\d{3})\/(?:0[1-9]|1[0-2])\/(?:0[1-9]|[12]\d|3[01])|(?:1\d{3}|2\d{3})(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])$/,
                            message: '格式参考:19950816',
                          },
                        ]}
                      >
                        <Input
                          // disabled={readDisabled && !otherCountyCard}
                          onChange={() => updateYearsAndMonths()}
                          readOnly={hasCard && !otherCountyCard}
                          onClick={() => readMessage()}
                          style={{width:"100px"}}
                        />
                      </Form.Item>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Row gutter={10}>
                    <Col span={16}>
                      <Form.Item
                        name='phone'
                        labelCol={{ span: 9 }}
                        wrapperCol={{ span: 18 }}
                        label='联系方式'
                        rules={[{ required: true }, { ...phonePatterns }]}
                      >
                        <Input allowClear maxLength={13} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name='relationship'
                        wrapperCol={{ span: 24 }}
                        rules={[
                          { required: true, message: '请选择与本人关系' },
                        ]}
                      >
                        <Select placeholder='请选择' allowClear>
                          {identitys.map((v: any) => {
                            return (
                              <Select.Option key={v} value={getIdentityName(v)}>
                                {getIdentityName(v)}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='idCardNumber'
                    label='身份证号'
                    rules={idCardRules}
                  >
                    <Input
                      allowClear
                      // disabled={readDisabled && !otherCountyCard}
                      onChange={(e: any) => {
                        // setIdCard(e.target.value)
                        getIdCardInfo(e.target.value)
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Row gutter={10}>
                    <Col span={16}>
                      <Form.Item
                        name='emergencyContactPhone'
                        labelCol={{ span: 9 }}
                        wrapperCol={{ span: 18 }}
                        label='紧急联系方式'
                      >
                        <Input allowClear maxLength={13} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name='relationshipEmergencyContact'
                        wrapperCol={{ span: 24 }}
                      >
                        <Select placeholder='请选择' allowClear>
                          {identitys.map((v: any) => {
                            return (
                              <Select.Option key={v} value={getIdentityName(v)}>
                                {getIdentityName(v)}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Form.Item name='qq' label='QQ号'>
                    <Input allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name='wechat' label='微信'>
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='mail' label='邮箱'>
                    <Input allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name='addressData' label='家庭地区'>
                    <Cascaded
                      loadData={loadData}
                      changeOnSelect
                      options={allList}
                      onChange={() => {
                        setAreaId([])
                        setDetailAllList([])
                      }}
                      placeholder='请选择省市区'
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='address' label='详细地址'>
                    <Input placeholder='详细地址' allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name='job' label='职业'>
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='companyName' label='工作单位'>
                    <Input allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name='source' label='患者来源'>
                    <Select placeholder='请选择' allowClear>
                      {sources.map((v: number) => {
                        return (
                          <Select.Option key={v} value={v}>
                            {getSourceName(v)}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='groupId' label='患者分组'>
                    <Select
                      mode='multiple'
                      allowClear
                      placeholder='请选择'
                      options={patientGroupList}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className={styles.labelRow}>
                <Col span={12}>
                  <Form.Item name='labelIdList' label='患者标签'>
                    <Select
                      mode='multiple'
                      allowClear
                      placeholder='请选择'
                      options={labelList}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='star' label='星级'>
                    <Rate style={{ marginTop: -5 }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item
                        name='introducerType'
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}
                        label='介绍人'
                      >
                        <Select
                          placeholder='请选择'
                          allowClear
                          defaultValue={0}
                          onChange={(v) => {
                            setPeopleType(v)
                            setPeopleDisabled(false)
                            if (v == 1) {
                              setKeyWordType(2)
                            }
                          }}
                        >
                          <Select.Option value={0}>患者介绍</Select.Option>
                          <Select.Option value={1}>员工介绍</Select.Option>
                          <Select.Option value={2}>朋友介绍</Select.Option>
                          <Select.Option value={3}>其他</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='introducer' wrapperCol={{ span: 24 }}>
                        <Dropdown
                          overlay={patientOverlay}
                          disabled={peopleDisabled}
                        >
                          <Input
                            allowClear
                            value={introducerKeyword}
                            onMouseMove={() => {
                              setKeyWordType(2)
                            }}
                            onFocus={() => {
                              setKeyWordType(2)
                              if (peopleType == 0) {
                                dispatch(getPatientList(introducerKeyword))
                              } else {
                                dispatch(
                                  getFollowUpPeopleList({
                                    current: 1,
                                    size: 500,
                                    param: introducerKeyword,
                                  })
                                )
                                  .then(unwrapResult)
                                  .then((res) => {
                                    setPersonList(
                                      res.records?.map((v: any) => {
                                        return {
                                          name: v.realname,
                                        }
                                      })
                                    )
                                  })
                              }
                              setIntroducerVisible(true)
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                setIntroducerVisible(false)
                              }, 300)
                            }}
                            onClick={() => {
                              setKeyWordType(2)
                              setIntroducerVisible(true)
                            }}
                            onChange={(e) => {
                              form.setFieldsValue({
                                introducer: e.target.value,
                              })
                              setIntroducerVisible(true)
                              setIntroducerKeyword(e.target.value)
                            }}
                          />
                        </Dropdown>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Form.Item name='counselor' label='咨询师'>
                    <Select
                      placeholder='请选择'
                      allowClear
                      showSearch
                      filterOption={(input, option: any) =>
                        (option?.children ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {counselorList?.map((v: any) => {
                        return (
                          <Select.Option key={v.id} value={v.id}>
                            {v.realname}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name='pastMedicalHistory' label='既往史'>
                    <FastInput action='jiwang' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='allergyHistory' label='过敏史'>
                    <FastInput action='guoming' />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name='toothCleaningHabit' label='洁牙习惯'>
                    <FastInput action='jieya' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='treatmentHistory' label='就诊经历'>
                    <FastInput action='jiuzheng' />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name='toothBrushingFrequency'
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}
                        label='刷牙'
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={3} style={{ margin: '5px 0 0 5px' }}>
                      次/天
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        name='toothBrushingTime'
                        wrapperCol={{ span: 24 }}
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={3} style={{ margin: '5px 0 0 5px' }}>
                      分钟/次
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={18}>
                      <Form.Item
                        name='smokeFrequency'
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 24 }}
                        label='吸烟'
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={3} style={{ margin: '5px 0 0 5px' }}>
                      次/天
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item name='treatmentItem' label='就诊项目'>
                    <Select placeholder='请选择' allowClear>
                      {medicalTreatmentItemss.map((v: any) => {
                        return (
                          <Select.Option
                            key={v}
                            value={getMedicalTreatmentItemsName(v)}
                          >
                            {getMedicalTreatmentItemsName(v)}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='attendingDoctor' label='主治医生'>
                    <Select
                      placeholder='请选择'
                      allowClear
                      showSearch
                      filterOption={false}
                      optionFilterProp='children'
                      onSearch={(v) => {
                        setParam(v)
                      }}
                    >
                      {users?.map((v: any) => {
                        return (
                          <Select.Option key={v.id} value={v.id}>
                            {v.realname}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name='remark'
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 24 }}
                    colon={false}
                    label='备注'
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={styles.footerBtnWrap}>
              <Space>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={btnLoading}
                  onClick={() => setSaveType(1)}
                >
                  保存患者
                </Button>
                {!detailData && (
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={btnLoading}
                    onClick={() => setSaveType(2)}
                  >
                    保存并挂号
                  </Button>
                )}
                {!detailData && (
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={btnLoading}
                    onClick={() => setSaveType(3)}
                  >
                    保存并预约
                  </Button>
                )}
                <Button
                  onClick={() => {
                    onCancel()
                    setDetailAllList([])
                    form.resetFields()
                    form.setFieldsValue({
                      addressData: undefined,
                    })
                  }}
                >
                  取消
                </Button>
              </Space>
            </div>
          </Form>
        </Spin>
      </div>
    </Modal>
  )
}
