import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../../app/applicationSlice";
import { RootState, RootThunkApi } from "../../../app/store";
import { Page, pageOf, startPageOf } from "../../../models/page";
import * as P from "../../../models/patientpayment";
import { PatientPayment } from "../../../models/patientpayment";
import { fromJson, SkinTest } from "../../../models/skintest";
import * as S from "../../../services/payment";
import { GetPatientPaymentListParams } from "../../../services/payment";
import { getPitestList, pitestQueryParams } from "../../../services/pitest";

interface PatientDetailState {
  skinTestReportPage: Page<SkinTest>;
  patientPaymentPage: Page<PatientPayment>;
}

const initialState = {
  skinTestReportPage: startPageOf(),
} as PatientDetailState;

export const getSkinTestList = createAsyncThunk<
  Page<SkinTest>,
  pitestQueryParams,
  RootThunkApi<Page<SkinTest>>
>("patientDetail/getSkinTestListStatus", async (params, api) => {
  return sendAsync(getPitestList(params), api).then((data) => {
    return pageOf(
      data,
      _.chain(data.records)
        .map((s) => fromJson(s))
        .value()
    );
  });
});

export const getPatientPaymentList = createAsyncThunk<
  Page<PatientPayment>,
  GetPatientPaymentListParams,
  RootThunkApi
>("patientDetail/getPatientPaymentListStatus", async (params, api) => {
  return sendAsync(S.getPatientPaymentList(params), api).then((data) => {
    return pageOf(
      data,
      _.chain(data.records)
        .map((p) => P.fromJson(p))
        .value()
    );
  });
});

const patientDetailSlice = createSlice({
  name: "patientDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSkinTestList.fulfilled, (state, action) => {
      state.skinTestReportPage = action.payload;
    });
    builder.addCase(getPatientPaymentList.fulfilled, (state, action) => {
      state.patientPaymentPage = action.payload;
    });
  },
});

export const selectSkinTestReportPage = (state: RootState): Page<SkinTest> =>
  state.patientDetail.skinTestReportPage;

export const selectPatientPaymentPage = (
  state: RootState
): Page<PatientPayment> => state.patientDetail.patientPaymentPage;

export default patientDetailSlice.reducer;
