/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-08-08 11:48:02
 */
import { Row, Table } from "antd";
import React, { ReactElement, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EditableList,
} from "../../../compnents/list/EditableList";
import { NavBar } from "../../../compnents/nav/NavBar";
import { RecevingParams } from "../../../services/receving";
import { ReceivingColumns } from "./columns";
import { ReceivingQuery } from "./Query";
import { getReceiveList, selectCurrent, selectPageLoading, selectRecevingData, selectTotal, setCurrent, setPageLoading } from "./recevingSlice";
import styles from "./receving.module.css";
export const Receiving = (): ReactElement => {
  const dispatch = useDispatch();
  const [queries, setQueries] = useState<RecevingParams>();

  const pageLoadingRX = useSelector(selectPageLoading);

  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectRecevingData);

  const [size, setSize] = useState(10);
  useEffect(() => {
    if (queries?.timeHead) {
      dispatch(setPageLoading(true))
      dispatch(getReceiveList({ ...queries, current: Current, size }))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }

  }, [queries,size, Current,]);
  return (
    <>
      <NavBar 
    style={{ margin: "10px 0 0px 20px" }}
        where={["供应商管理", "收付款"]}
        backtrace={{
          name: "库房工作站",
          path: "/storage/station",
          state: {
            station: "8",
          },
        }}
      />
      <div className={styles.content}>
      <ReceivingQuery
            onValueChange={(v) => {
              setQueries({ ...queries, ...v });
              dispatch(setCurrent(1));
            }}
          />
      <EditableList
      className={styles.receving}
          loading={pageLoadingRX}
      rowKey={(t: any) => t.id}
      page={{
        items: data,
        current: Current,
        size: size,
        total: total,
      }}
      onChangePage={(current, pageSize) => {
        setSize(pageSize as number);
        dispatch(setCurrent(current));
      }}
        columns={ReceivingColumns((id) => {

          dispatch(getReceiveList({ current: 1 }));
        },size * (Current - 1))}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0} align='center'></Table.Summary.Cell>
                <Table.Summary.Cell index={1} align='center' className={styles.total}>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3} align='right'></Table.Summary.Cell>
                <Table.Summary.Cell index={4} align='right'></Table.Summary.Cell>
                <Table.Summary.Cell index={5} align='right'></Table.Summary.Cell>
                <Table.Summary.Cell index={6} align='right'></Table.Summary.Cell>
                <Table.Summary.Cell index={7} align='right'>
                  {useMemo(() => data.reduce((acc: any, item: any) => acc + (item.shouldPayMoney || 0), 0), [data]).toFixed(2)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
      />
      </div>
    </>
  );
};
