/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:05
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-11 16:38:30
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Row } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { Columns } from './column'
import { Query } from './Query'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getDateRange, DateRangeType } from '../../../compnents/form/DateSelect'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
  DateTimeFormatSimpleText,
} from '../../../models/datetime'
import styles from './LocalVerificationForm.module.css'
import { BillName } from '../../../models/category'
import { PrintFrameRef, PrintFrame } from '../../print/PrintFrame'
import {
  selectInsuranceArray,
  selectTenantName,
} from '../../../app/applicationSlice'
import { getLocalVerificationFormList } from './LocalVerificationFormSlice'

interface LocalVerificationProps {
  selectedTab?: any
}

const LocalVerificationForm = ({
  selectedTab,
}: LocalVerificationProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const tenantName = useSelector(selectTenantName)

  const insuranceArray = useSelector(selectInsuranceArray)

  const printRef = useRef<PrintFrameRef>(null)

  const dispatch = useDispatch<RootDispatch>()

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const [params, setParams] = useState<any>({})

  const [selectedKeys, setSelectedKeys] = useState<any>([])

  const [page, setPage] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    if (selectedTab == '0') {
      if (params?.startTime) {
        setPageLoading(true)
        getPage()
      }
    }
  }, [selectedTab, params])

  console.log(page, page?.length, 'page')

  const getPage = () => {
    dispatch(getLocalVerificationFormList({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        if (res?.length) {
          setPage(
            res?.map((v: any) => {
              return {
                ...v,
                startTime: params?.startTime,
                endTime: params?.endTime,
              }
            })
          )
        } else {
          setPage([])
        }
        setPageLoading(false)
      })
      .catch(() => {
        setPageLoading(false)
      })
  }

  return (
    <>
      <Col
        style={{
          backgroundColor: theme.pn,
          borderRadius: 10,
          display: 'flex',
          margin: '10px 0px',
          padding: '10px 20px',
          flexDirection: 'column',
          height: 'calc(100% - 30px)',
          overflow: 'hidden',
        }}
      >
        <Query
          onValueChange={(v: any) => {
            const paramData = {
              ...params,
              ...v,
              startTime: v?.timeHead
                ? moment(v?.timeHead).format(DateTimeFormatSimpleOnlyOne)
                : moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
              endTime: v?.timeTail
                ? moment(v?.timeTail).format(DateTimeFormatSimpleOnlyOne)
                : moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
              timeHead: undefined,
              timeTail: undefined,
            }
            setParams({
              ...paramData,
            })
          }}
          print={() => {
            printRef.current?.print()
          }}
        />
        <div style={{ flex: 1 }}>
          <EditableList
            bordered
            // rowClassName={(e) =>
            //   e.xianzhonglxmc === '合计' || e.xianzhonglxmc === '总合计'
            //     ? styles.total
            //     : ''
            // }
            loading={pageLoading}
            pagination={false}
            page={{
              current: 1,
              size: 10,
              total: 0,
              items: page,
            }}
            columns={Columns(page)}
          />
        </div>
      </Col>
      <PrintFrame
        ref={printRef}
        title='浙江省基本医疗保险定点医药机构申请核拨表'
      >
        <Row style={{ marginTop: '20px' }}>
          <Col span={8}>
            定点医药机构编码：{insuranceArray?.[0]?.countryHospitalNb}
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            定点医药机构名称(盖章)：{tenantName?.split('-')?.[0]}
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            结算起止时间：
            {moment(params?.startTime).format(DateTimeFormatSimpleText)}至
            {moment(params?.endTime).format(DateTimeFormatSimpleText)}
          </Col>
        </Row>
        <EditableList
          loading={pageLoading}
          pagination={false}
          page={{
            current: 1,
            size: 10,
            total: 0,
            items: page,
          }}
          columns={Columns(page)}
        />
        <Row style={{ lineHeight: '32px', marginTop: '20px' }}>
          <Col span={6}>单位负责人：</Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            财务负责人：
          </Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            制表人：
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            制表日期：{moment().format(DateTimeFormatSimpleText)}
          </Col>
        </Row>
      </PrintFrame>
    </>
  )
}

export const LocalVerificationForms = forwardRef(LocalVerificationForm)
