import { unwrapResult } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from 'antd'
import styles from '../directory.module.css'
import { MedicalListBody } from '../../../services/commodity'
import { List } from '../../../models/page'
import { EditableList } from '../../../compnents/list/EditableList'
import {
  getDirectoryUpload,
  getInsuranceMzListAsync,
  getLimitDirectoryUpload,
  getMedicalListAsync,
  queryNatyPreparedByPageAsync,
} from '../../commodity/commoditySlice'
import {
  directoryCategoryList,
  directoryCategoryListInsurance,
} from '../../../models/insuranceDirectory'
import moment from 'moment'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../../app/applicationSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../../models/datetime'
import { serverType } from '../../../services/commodity'
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils'
import { number } from 'echarts'
import { InsuranceOperationColumn } from './InsuranceOperationColumn'
import { InsuranceMzColumn } from './InsuranceMzColumn'

function MzInsuranceCategory() {
  const dispatch = useDispatch<RootDispatch>()

  // form 表单初始化
  const [form] = Form.useForm()
  const [limitModalForm] = Form.useForm()

  const [modalForm] = Form.useForm()
  // 表格参数
  const [queries, setQueries] = useState<MedicalListBody>({
    current: 1,
    size: 10,
    // itemNameKeyWorld: componentItem?.name,
  })
  const [data, setData] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [modalBtnLoading, setModalBtnLoading] = useState(false) //弹窗 loading

  const [lnsuranceModalVisible, setLnsuranceModalVisible] = useState(false) //目录下载弹窗

  const [limitlnsuranceModalVisible, setLimitLnsuranceModalVisible] = useState(
    false
  ) //限价目录下载弹窗

  const insuranceArray = useSelector(selectInsuranceArray)

  const baseData = useSelector(selectInsuranceItem)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const tenantCategory = useSelector(selectTenantCategory)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantAddress = useSelector(selectTenantAddress)

  const directoryDownload = (
    serverType: serverType,
    formObj: any,
    responseString?: string
  ) => {
    setModalBtnLoading(true)
    dispatch(
      queryNatyPreparedByPageAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          ...formObj,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '医保民族目录下载',
            },
            (call: any, err) => {
              !err &&
                directoryDownload(
                  'response',
                  formObj,
                  JSON.stringify(call.netmessage)
                )
              if (err || err == undefined) {
                setModalBtnLoading(false)
              }
            }
          )
        } else {
          if (res.isFinsh) return
          directoryDownload('requestString', {
            ...formObj,
            page_num: Number(formObj.page_num) + 1,
          })
          modalForm.setFieldsValue({
            page_num: Number(formObj.page_num) + 1,
          })
        }
      })
  }

  useEffect(() => {
    getTable()
  }, [queries])
  // 获取表格数据
  const getTable = () => {
    setPageLoading(true)
    dispatch(
      getInsuranceMzListAsync({
        ...queries,
      })
    )
      .then(unwrapResult)
      .then((res: any) => setData(res))
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  return (
    <div className={styles.wrap}>
      <Form
        form={form}
        initialValues={{ billingCategory: '01' }}
        onFinish={(values: any) => {
          setQueries({ ...queries, ...values, current: 1 })
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '42px',
          }}
        >
          <Col>
            <Space>
              <Form.Item name='drugProdname'>
                <Input
                  size='middle'
                  placeholder='名称关键词'
                  allowClear
                  prefix={<SearchOutlined />}
                />
              </Form.Item>
              <Form.Item name='medListCodg'>
                <Input placeholder='医疗目录编码' allowClear />
              </Form.Item>
            </Space>
          </Col>
          <Col>
            <Space>
              <Button htmlType='submit' type='primary'>
                查询
              </Button>
              {JSON.parse(
                localStorage.getItem('credentials') as string
              ).tenantName.indexOf('测试') >= 0 && (
                <Button
                  type='primary'
                  onClick={() => {
                    setLnsuranceModalVisible(true)
                  }}
                >
                  目录下载
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
      <EditableList
        loading={pageLoading}
        columns={InsuranceMzColumn(() => {
          return
        }, (queries.size || 10) * ((queries.current || 1) - 1))}
        onChangePage={(current, size) => {
          setQueries({
            ...queries,
            current,
            size,
          })
        }}
        page={{
          size: queries.size || 10,
          current: queries.current || 1,
          total: data?.total || 0,
          items: data?.records || [],
        }}
        rowKey={(_, i) => `${i}`}
      />
      <Modal
        title='医保目录下载'
        visible={lnsuranceModalVisible}
        onOk={() => modalForm.submit()}
        onCancel={() => setLnsuranceModalVisible(false)}
        confirmLoading={modalBtnLoading}
      >
        <Form
          form={modalForm}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 17 }}
          onFinish={(values) => {
            directoryDownload('requestString', {
              ...values,
              updt_time: moment(values.updt_time).format('YYYY-MM-DD 00:00:00'),
            })
          }}
        >
          <Form.Item
            label='更新时间'
            name='updt_time'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label='当前页数'
            name='page_num'
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[1-9]\d*$/),
                message: '请输入数字',
              },
            ]}
          >
            <Input size='middle' allowClear />
          </Form.Item>

          <Form.Item
            label='本页数据量'
            name='page_size'
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[1-9]\d*$/),
                message: '请输入数字',
              },
            ]}
          >
            <Input size='middle' allowClear />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default MzInsuranceCategory
