/* eslint-disable react/prop-types */
import { CaretDownFilled } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Col,
  Form,
  Input,
  Modal,
  ModalProps,
  notification,
  Row,
  Select,
  Typography,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCredentials,
  selectCycleCode,
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantId,
  selectTenantName,
  selectUserId,
  selectUserName,
  setCredentials,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { medicalSendHttp } from '../../utils/MedicalUtils'
import {
  getSignInfoAsync,
  selectSignInfo,
  SignInfoValue,
  signInToMedicalAsync,
  signOutToMedicalAsync,
} from '../layoutSlice'
const { Item } = Form
const { Option } = Select
const { Text } = Typography

export const SignInModal: React.FC<ModalProps> = ({
  visible,
  onOk,
  onCancel,
}): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [isSign, setIsSign] = useState(false)

  const [loading, setLoading] = useState(false)

  const insuranceArray = useSelector(selectInsuranceArray)

  const tenantId = useSelector(selectTenantId)

  const userId = useSelector(selectUserId)

  const userName = useSelector(selectUserName)

  const credentials = useSelector(selectCredentials)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const signInfo = useSelector(selectSignInfo)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const load = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }

  // 获取签到信息
  const getSignInInfo = (insuranceCode?: string) => {
    dispatch(
      getSignInfoAsync({
        insuranceCode: insuranceCode || insuranceArray[0].insuranceCode,
        tenantId,
        userId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (JSON.stringify(res) !== '{}') {
          setIsSign(true)
        } else {
          setIsSign(false)
        }
      })
  }

  const signIn = (serverType: any, e: any, responseString?: string) => {
    dispatch(
      signInToMedicalAsync({
        serverType: serverType,
        params: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = JSON.parse(res.replace(/\\"/g, '"'))
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              // message: '医保签到',
            },
            (call, err) => {
              if (!err) {
                signIn('response', e, JSON.stringify(call.netmessage))
              } else {
                setLoading(false)
              }
            }
          )
        } else {
          notification.success({
            message: '医保签到成功',
            duration: 1,
          })
          // onOk && onOk(e)
          // getPage()
          setLoading(false)
          getSignInInfo()
        }
      })
  }

  const signOut = (serverType: any, e: any, responseString?: string) => {
    dispatch(
      signOutToMedicalAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
            cycleCode: signInfo?.signCycleCd,
          },
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = JSON.parse(res.replace(/\\"/g, '"'))
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              message: '医保签退',
            },
            (call, err) => {
              if (!err || call.netcode === '1') {
                signOut('response', e, JSON.stringify(call.netmessage))
              } else {
                setLoading(false)
              }
            }
          )
        } else {
          Modal.destroyAll()
          getSignInInfo()
          setLoading(false)
          onOk && onOk(e)
          // getPage()
        }
      })
  }

  const modalOnOk = (e: any) => {
    if (isSign) {
      signOut('requestString', e)
    } else {
      signIn('requestString', e)
    }
  }

  useEffect(() => {
    if (insuranceArray.length) {
      setInsuranceItem(insuranceArray[0])
      form.setFieldsValue({
        userName: userName,
        insuranceCode: insuranceArray[0].insuranceCode,
      })
    }
  }, [insuranceArray])

  useEffect(() => {
    if (visible) {
      getSignInInfo()
    }
  }, [visible])

  return (
    <Modal
      title={!isSign ? '医保签到' : '医保签退'}
      visible={visible}
      okText={isSign ? '签退' : '签到'}
      confirmLoading={loading}
      onOk={(e) => {
        load()
        modalOnOk(e)
      }}
      onCancel={onCancel}
    >
      <Form form={form}>
        <Row gutter={10}>
          <Col span={14}>
            <Item label='选择结算险种' name='insuranceCode'>
              <Select
                placeholder='请选择医保险种'
                // suffixIcon={<CaretDownFilled />}
                onSelect={(v: any) => {
                  const insuranceItem = insuranceArray.find(
                    (item) => item.insuranceCode === v
                  )
                  setInsuranceItem(insuranceItem)
                  getSignInInfo(v)
                  // setInsuranceItem()
                }}
              >
                {insuranceArray.map((v) => {
                  return (
                    <Option value={v.insuranceCode} key={v.id}>
                      {v.insuranceName}
                    </Option>
                  )
                })}
              </Select>
            </Item>
          </Col>
          <Col span={10}>
            <Item label='操作员' name='userName'>
              <Input disabled />
            </Item>
          </Col>
          {isSign && (
            <>
              <Col span={12}>
                <Item label='您已签到，签到流水号'>
                  <Text type='success'>{signInfo?.signCycleCd}</Text>
                </Item>
              </Col>
              <Col span={12}>
                <Item label='签到时间'>
                  <Text>{signInfo?.checkinTm}</Text>
                </Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Modal>
  )
}
