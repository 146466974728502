import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  notification,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './triage.module.css'
import { RootDispatch } from '../../app/store'
import { getRiskArea, getTriageItem, submitTriage } from './listslice'
import { getReportName, reportDepartments } from '../../models/payMethod'
import { ThemeContext } from '../../theme/ThemeContext'
import { Label } from '../../compnents/widgets/Label'
import { SearchOutlined } from '@ant-design/icons'
import {
  getTriageHistorylist,
  registrationPatientListAsync,
} from '../commonTriageDetail/commonTriageDetailSlice'
import { RiskAreaValue } from '../../models/previewTriageList'
import { NavBar } from '../../compnents/nav/NavBar'
import { DateTimeFormatSimple } from '../../models/datetime'
import moment from 'moment'
import { traceRoute } from '../../layouts/layoutSlice'
import {
  getPatientList,
  getTriagePatientList,
  selectPatients,
} from '../registration/register/registerSlice'
import { RegisteredModal } from '../A-toothModule/toothHome/modal/registeredModal'
const { Option } = Select

export const buttonSize = 'large'

interface DetailProps {
  visible?: any
  selectId?: string //口腔 his 版 预检纷争
  status?: number
  onSubmit?: () => void
  cancel?: () => void
}

export const Detail = ({
  visible,
  selectId,
  status,
  onSubmit,
  cancel,
}: DetailProps) => {
  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  }

  const tailLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  }

  const outerLayout = {
    labelCol: { span: status == 1 ? 20 : 12 },
    wrapperCol: { span: status == 1 ? 4 : 12 },
  }

  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const [select] = useState<any>(0)

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const history = useHistory()

  const [reportTag, setReportTag] = useState(false)

  const [riskAddress, setRiskAddress] = useState(false)

  const [starTag, setStarTag] = useState(false)

  const [livingHistory, setLivingHistory] = useState(false)

  const [leaveProvince, setLeaveProvince] = useState(false)

  const [checkAll, setCheckAll] = useState(true)

  const [keyword, setKeyword] = useState<string>()

  const [list, setList] = useState<any[]>([])

  const [idCard, setIdCard] = useState('')

  const [triageId, setTriageId] = useState<any>()

  const [riskArea, setRiskArea] = useState<RiskAreaValue[]>([])

  const [isPatientOverlayVisible, setIsPatientOverlayVisible] = useState(false)

  const patientInfo = sessionStorage.getItem('patientInfo')

  const [isEdit, setIsEdit] = useState(false)

  const [isRegistra, setIsRegistra] = useState(false)

  const [regisionModalTag, setRegisionModalTag] = useState(false)

  const [isRegistraBtn, setIsRegistraBtn] = useState(false)

  const [source, setSource] = useState(0)

  const [takeDrug, setTakeDrug] = useState(false)

  const [modalPatientID, setModalPatientID] = useState('') //弹窗 患者ID

  const [registeredModal, setRegisteredModal] = useState(false) //挂号模态框

  const [nameDisabled, setNameDisabled] = useState(false) // 自费姓名筛查后禁用

  const patients: any = useSelector(selectPatients)

  useEffect(() => {
    if (status == 1) {
      setSource(1)
    }
  }, [status])

  // useEffect(() => {
  //   if (visible == 'false') {
  //     form.resetFields()
  //   }
  // }, [visible])

  const plainOptions = [
    '发热',
    '干咳',
    '乏力',
    '嗅觉味觉减退',
    '鼻塞',
    '流涕',
    '咽痛',
    '结膜炎',
    '肌痛',
    '腹泻',
  ]

  const [checkedList, setCheckedList] = useState<any>()

  const reset = () => {
    setCheckAll(true)
    setCheckedList([])
    setTakeDrug(false)
  }

  const formChange = (vs: any, basics?: boolean) => {
    reset()
    if (basics) {
      // 从上边筛选的
      form.setFieldsValue({
        patientName: vs.patientName,
        sex: vs.sex,
        age: vs.age,
        phone: vs.phone,
        identityCard: vs.identityCard,
        occupation: vs.occupation,
        address: vs.address,
      })
      setIsPatientOverlayVisible(false)
    } else {
      if (!patientInfo) {
        setCheckAll(
          vs.symptom === '无以下症状' || vs?.symptom === '无以上症状'
            ? true
            : false
        )
        setCheckedList(
          vs?.symptom === '无以下症状' || vs?.symptom === '无以上症状'
            ? []
            : vs?.symptom?.split(',')
        )
        // 点击进入的
        setTakeDrug(vs.takeDrug == 1)
        setIsRegistraBtn(vs.patientFlow == 0)
        form.setFieldsValue({
          ...vs,
          patientFlow: vs?.patientFlow === -1 ? null : vs?.patientFlow,
          symptom:
            vs?.symptom === '无以下症状' || vs?.symptom === '无以上症状'
              ? ''
              : vs?.symptom?.split(','),
          followUp: vs?.followUp ? vs?.followUp.split(',').map(Number) : [''],
          reportDepartment:
            vs?.reportDepartment === -1 ? '' : vs?.reportDepartment,
        })
        if (
          vs?.tourCodeType == 1 &&
          vs?.riskAreaType === 1 &&
          vs?.leaveProvince === 1
        ) {
          setStarTag(true)
          setLivingHistory(true)
          setLeaveProvince(true)
        } else if (vs?.tourCodeType == 1 && vs?.leaveProvince == 1) {
          setStarTag(true)
          setLeaveProvince(true)
        } else if (vs?.tourCodeType == 1 && vs?.riskAreaType == 1) {
          setStarTag(true)
          setLivingHistory(true)
        } else if (vs?.tourCodeType == 1) {
          setStarTag(true)
        } else {
          return
        }
      }
    }
  }

  const sucessSubmit = () => {
    notification.success({
      message: '患者登记成功',
    })
    if (regisionModalTag) {
      setRegisteredModal(true)
    }
    setRegisionModalTag(false)
    setIsRegistra(false)
    setIsRegistraBtn(false)
    form.resetFields()
    setKeyword('')
    sessionStorage.removeItem('traiget')
    if (status == 1) {
      onSubmit && onSubmit()
      return
    }
    if (isRegistra) {
      dispatch(
        traceRoute({
          name: '挂号',
          path: '/registration',
        })
      )
    } else {
      history.push('/triage')
    }
  }

  const submitDispatch = (vs: any) => {
    if (isRegistra) {
      const data = {
        name: vs.patientName,
        sex: vs.sex,
        age: vs.age || 0,
        source: 1,
        ageMonth: vs.ageMonth,
        birthday: vs.ageYear,
        phone: vs.phone,
        idCardNumber: vs.identityCard,
        address: vs.address,
      }
      sessionStorage.setItem('TRIAGEPATIENTINFO', JSON.stringify(data))
    }
    if (
      (vs.animalHeat < 50 && (location.state?.selectId || selectId)) ||
      sessionStorage.getItem('traiget')
    ) {
      dispatch(
        submitTriage({
          ...vs,
          age: vs.age || 0,
          symptom: '无以下症状',
          // symptom: checkAll ? '无以下症状' : checkedList.join(','),
          followUp: vs.followUp?.join(',') || '',
          coldChain: vs.coldChain || 0,
          pastMedicalHistory: vs.pastMedicalHistory || 0,
          fever: vs.fever || 0,
          healthCodeColor: vs.healthCodeColor || 0,
          highRisk: vs.highRisk || 0,
          inbound: vs.inbound || 0,
          mask: vs.mask || 1,
          messageSend: vs.messageSend || 0,
          sex: vs.sex || 0,
          touch: vs.touch || 0,
          id:
            location.state?.selectId ||
            triageId ||
            sessionStorage.getItem('traiget') ||
            selectId,
          tourCodeType: vs?.tourCodeType || 0,
          riskAreaType: vs?.riskAreaType || 0,
          riskArea: vs?.riskArea == null ? '' : vs?.riskArea,
          leaveProvince: vs?.leaveProvince || 0,
          travelTool:
            vs?.travelTool == undefined
              ? ''
              : vs?.travelTool && vs?.travelTool.toString(),
          destinationAddress:
            vs?.destinationAddress == null ? '' : vs?.destinationAddress,
          riskSymptom: vs?.riskSymptom || 0,
          riskTouch: vs?.riskTouch || 0,
          takeDrug: undefined,
          // takeDrug: checkAll ? undefined : takeDrug ? 1 : 0
        })
      )
        .then(unwrapResult)
        .then((v) => {
          setModalPatientID(v.patientId)
          sucessSubmit()
        })
    } else if (vs.animalHeat < 50 && !(location.state?.selectId || selectId)) {
      dispatch(
        submitTriage({
          ...vs,
          age: vs.age || 0,
          symptom: '无以下症状',
          followUp: vs.followUp?.join(',') || '',
          coldChain: vs.coldChain || 0,
          pastMedicalHistory: vs.pastMedicalHistory || 0,
          fever: vs.fever || 0,
          healthCodeColor: vs.healthCodeColor || 0,
          highRisk: vs.highRisk || 0,
          inbound: vs.inbound || 0,
          mask: vs.mask || 1,
          messageSend: vs.messageSend || 0,
          sex: vs.sex || 0,
          touch: vs.touch || 0,
          tourCodeType: vs?.tourCodeType || 0,
          riskAreaType: vs?.riskAreaType || 0,
          riskArea: vs?.riskArea == null ? '' : vs?.riskArea,
          leaveProvince: vs?.leaveProvince || 0,
          travelTool:
            vs?.travelTool == undefined
              ? ''
              : vs?.travelTool && vs?.travelTool.toString(),
          destinationAddress:
            vs?.destinationAddress == null ? '' : vs?.destinationAddress,
          riskSymptom: vs?.riskSymptom || 0,
          riskTouch: vs?.riskTouch || 0,
          takeDrug: undefined,
        })
      )
        .then(unwrapResult)
        .then((v) => {
          setModalPatientID(v.patientId)
          sucessSubmit()
        })
    } else {
      notif('请输入正确范围内的体温')
    }
  }

  const notif = (msg: string) => {
    notification.error({
      message: msg,
    })
  }

  const patientOverlay = (
    <Menu>
      {list?.map((p) => (
        <Menu.Item
          key={p.id}
          onClick={() => {
            form.resetFields()
            setIsPatientOverlayVisible(false)
            setKeyword(p.patientName || p.name)
            setModalPatientID(p.patientId || p.id)
            if (source) {
              setNameDisabled(true)
              reset()
              form.setFieldsValue({
                patientName: p.patientName || p.name,
                sex: p.sex,
                age: p.age,
                ageMonth: p.ageMonth,
                ageYear: p.ageYear,
                phone: p.phone,
                identityCard: p.identityCard,
                address: p.address,
              })
            } else {
              setTriageId(p.id)
            }
          }}
        >
          <Space>
            <span>{p.patientName || p.name}</span>
            <span>{p.phone}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )

  const submitTriageItem = (vs: any) => {
    // if (!checkAll && !checkedList) {
    //   notif('请选择症状')
    // } else {
    //   if (
    //     !checkAll &&
    //     (checkedList?.length === 0 ||
    //       checkedList?.every((v: string) => v === ''))
    //   ) {
    //     notif('请选择症状')
    //   } else {
    // if (checkedList?.find((v: string) => v === '发热')) {
    //   if (vs.identityCard) {
    //     submitDispatch(vs)
    //   } else {
    //     notif('请填写身份证号')
    //   }
    // } else {
    submitDispatch(vs)
    // }
    //   }
    // }
  }

  useEffect(() => {
    if (visible == 'true') {
      if ((selectId || location.state?.selectId) && !patientInfo) {
        dispatch(getTriageItem(location.state?.selectId || selectId))
          .then(unwrapResult)
          .then((res) => {
            status == 1 && setKeyword(res.patientName)
            setIsEdit(
              moment().subtract(1, 'day').endOf('day').isAfter(res.createTime)
            )
            setRiskAddress(Boolean(res.highRisk))
            setReportTag(Boolean(res.messageSend))
            setStarTag(Boolean(res.tourCodeType))
            setLivingHistory(Boolean(res.riskAreaType))
            setLeaveProvince(Boolean(res.leaveProvince))
            formChange(res, false)
          })
      }
      dispatch(
        getRiskArea({
          grade: 3,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setRiskArea(res)
        })
      // flag 证明预检分诊是通用版本
    } else if (location.state?.flag) {
      if ((selectId || location.state?.selectId) && !patientInfo) {
        dispatch(getTriageItem(location.state?.selectId || selectId))
          .then(unwrapResult)
          .then((res) => {
            status == 1 && setKeyword(res.patientName)
            setIsEdit(
              moment().subtract(1, 'day').endOf('day').isAfter(res.createTime)
            )
            setRiskAddress(Boolean(res.highRisk))
            setReportTag(Boolean(res.messageSend))
            setStarTag(Boolean(res.tourCodeType))
            setLivingHistory(Boolean(res.riskAreaType))
            setLeaveProvince(Boolean(res.leaveProvince))
            formChange(res, false)
          })
      }
    }
  }, [dispatch, location.state?.selectId, selectId, visible])

  useEffect(() => {
    if (triageId) {
      dispatch(getTriageItem(triageId))
        .then(unwrapResult)
        .then((res) => {
          formChange(res, true)
        })
    }
  }, [triageId])

  useEffect(() => {
    status == 1
      ? PatientList()
      : source == 0
      ? TriageHistorylist()
      : registrationPatientList()
  }, [keyword, status, source])

  const TriageHistorylist = () => {
    dispatch(getTriageHistorylist({ name: keyword, size: 10 }))
      .then(unwrapResult)
      .then((v: any) => {
        setList(v?.records)
      })
  }

  const PatientList = () => {
    dispatch(getTriagePatientList({ param: keyword, size: 100 }))
      .then(unwrapResult)
      .then((v: any) => {
        setList(
          v?.records?.map((v: any) => {
            return {
              ...v,
              age: v.ageYear,
              ageMonth: v.ageMonth == -1 ? '' : v.ageMonth,
              ageYear: v.birthday,
            }
          })
        )
      })
  }

  const registrationPatientList = () => {
    dispatch(
      registrationPatientListAsync({ name: keyword ? keyword : '', size: 10 })
    )
      .then(unwrapResult)
      .then((v: any) => {
        setList(v?.records)
      })
  }

  useEffect(() => {
    if (patientInfo) {
      const res = JSON.parse(patientInfo)
      setRiskAddress(!!res.highRisk)
      setReportTag(Boolean(res.messageSend))
      setStarTag(Boolean(res.tourCodeType))
      setLivingHistory(Boolean(res.riskAreaType))
      setLeaveProvince(Boolean(res.leaveProvince))
      setCheckAll(
        JSON.parse(patientInfo).symptom === '无以下症状' ||
          JSON.parse(patientInfo).symptom === '无以上症状'
          ? true
          : false
      )
      setCheckedList(
        JSON.parse(patientInfo)?.symptom === '无以下症状' ||
          JSON.parse(patientInfo).symptom === '无以上症状'
          ? []
          : JSON.parse(patientInfo)?.symptom?.split(',')
      )

      form.setFieldsValue({
        // ...JSON.parse(patientInfo),
        ...res,
        symptom:
          JSON.parse(patientInfo)?.symptom === '无以下症状' ||
          JSON.parse(patientInfo).symptom === '无以上症状'
            ? ''
            : JSON.parse(patientInfo)?.symptom?.split(','),
      })


      if (JSON.parse(patientInfo).id) {
        setTriageId(JSON.parse(patientInfo).id)
      }
      dispatch(
        getRiskArea({
          grade: 3,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setRiskArea(res)
        })
    }
  }, [patientInfo])


  const updateAgeYear = () => {
    const year = parseInt(form.getFieldValue('age')) || 0
    const month = parseInt(form.getFieldValue('ageMonth')) || 0
    if (year !== 0 || month !== 0) {
      const months = year * 12 + month
      const ageYear = moment().subtract(months, 'months').format('YYYY/MM/DD')
      form.setFieldsValue({ ageYear })
    }
  }

  const updateYearsAndMonths = () => {
    const date = moment(form.getFieldValue('ageYear'))
    if (!date.isValid()) {
      return
    }
    const duration = moment.duration(moment().diff(date))
    if (duration.years() < 0 || duration.months() < 0) {
      return
    }
    form.setFieldsValue({
      age: duration.years(),
      ageMonth: duration.months() == 0 ? 1 : duration.months(),
    })
  }
  return (
    <>
      {!status && (
        <Col
          style={{
            margin: '10px 20px',
          }}
        >
          <NavBar
            where={['预检分诊', '预检登记']}
            backtrace={{
              name: '预检分诊',
              path: '/triage',
              state: {
                tag: true,
              },
            }}
          />
        </Col>
      )}
      <div
        className={styles.form}
        style={{
          padding: status == 1 ? '0' : '20px',
          margin: status == 1 ? '0' : '10px 20px',
        }}
      >
        <Form
          form={form}
          {...layout}
          autoComplete='off'
          colon={false}
          onFinish={(vs) => {
            submitTriageItem(vs)
          }}
        >
          {status != 1 && (
            <Row
              style={{
                height: '5rem',
                borderRadius: theme.br,
                backgroundColor: theme.pn,
                padding: '0 30px',
              }}
              align='middle'
              wrap={false}
              className={styles.bar}
            >
              <Label label='已登记预检分诊或挂号的用户' />
              <Dropdown
                overlay={patientOverlay}
                overlayStyle={{ height: '100px' }}
                getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
                visible={isPatientOverlayVisible}
              >
                <Input
                  name='param'
                  placeholder='患者姓名/手机号'
                  prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
                  value={keyword}
                  onFocus={() => {
                    source == 0
                      ? TriageHistorylist()
                      : registrationPatientList()
                    setIsPatientOverlayVisible(true)
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsPatientOverlayVisible(false)
                    }, 300)
                  }}
                  onClick={() => {
                    setIsPatientOverlayVisible(true)
                  }}
                  onChange={(e) => {
                    setIsPatientOverlayVisible(true)
                    setKeyword(e.target.value)
                  }}
                  style={{ width: '28rem', marginLeft: 40 }}
                />
              </Dropdown>
              <Col span={10} style={{ marginLeft: '20px' }}>
                <Radio.Group
                  // defaultValue={source}
                  value={source}
                  onChange={(e: any) => {
                    setSource(e.target.value)
                    setKeyword('')
                  }}
                >
                  <Radio value={0}>预检分诊患者</Radio>
                  <Radio value={1}>挂号患者</Radio>
                </Radio.Group>
              </Col>
            </Row>
          )}
          <Divider
            style={{ marginTop: 0, marginBottom: 20, borderColor: theme.c3 }}
          />
          {status && status != 1 && (
            <Row
              style={{
                height: '3rem',
                borderRadius: theme.br,
                backgroundColor: theme.pn,
                padding: '0 30px',
              }}
              align='middle'
              wrap={false}
            >
              <Label label='预检分诊信息' style={{ marginTop: '20px' }} />
            </Row>
          )}
          <Row>
            <Col span={12}>
              <Form.Item
                label='姓名'
                name='patientName'
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                {status == 1 ? (
                  <Dropdown
                    disabled={selectId ? true : nameDisabled}
                    overlay={patientOverlay}
                    overlayStyle={{
                      maxHeight: '200px',
                      overflowY: 'scroll',
                    }}
                    getPopupContainer={(triggerNode: any) =>
                      triggerNode.parentNode
                    }
                    visible={isPatientOverlayVisible}
                  >
                    <Input
                      placeholder='患者姓名/手机号'
                      prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
                      value={keyword}
                      onFocus={() => {
                        status == 1
                          ? PatientList()
                          : source == 0
                          ? TriageHistorylist()
                          : registrationPatientList()
                        setIsPatientOverlayVisible(true)
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setIsPatientOverlayVisible(false)
                        }, 300)
                      }}
                      onClick={() => {
                        setIsPatientOverlayVisible(true)
                      }}
                      onChange={(e) => {
                        form.setFieldsValue({
                          patientName: e.target.value,
                        })
                        setIsPatientOverlayVisible(true)
                        setKeyword(e.target.value)
                      }}
                    />
                  </Dropdown>
                ) : (
                  <Input />
                )}
              </Form.Item>
            </Col>
            {/*只有口腔版时：status = 1并且不是编辑状态：selectId 为空 >才会显示*/}
            {!selectId && status == 1 ? (
              <Col span={2} style={{ marginLeft: '30px' }}>
                <a
                  style={{ borderBottom: '2px solid #69a2f9' }}
                  onClick={() => {
                    form.resetFields()
                    setKeyword('')
                    setIsRegistraBtn(false)
                    setNameDisabled(false)
                  }}
                >
                  清空
                </a>
              </Col>
            ) : (
              ''
            )}
            <Col span={12}>
              <Form.Item
                label='性别'
                name='sex'
                rules={[
                  {
                    required: true,
                    message: '请选择性别',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>男</Radio>
                  <Radio value={2}>女</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='年龄' required>
                <Row wrap={false} align='middle'>
                  <Form.Item
                    name='age'
                    noStyle
                    dependencies={['ageMonth']}
                    rules={[
                      {
                        pattern: /^\d{0,3}$/,
                        message: '请输入正确的年龄',
                      },
                      ({ getFieldValue }) => ({
                        validator: (_, value) => {
                          if (!value && !getFieldValue('ageMonth')) {
                            return Promise.reject(new Error('请输入正确的年龄'))
                          }
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  >
                    <Input
                      style={{ maxWidth: '3rem' }}
                      onChange={updateAgeYear}
                    />
                  </Form.Item>

                  <div
                    style={{
                      color: theme.tc2,
                      fontSize: '1rem',
                      margin: '0 6px',
                    }}
                  >
                    岁
                  </div>
                  <Form.Item
                    name='ageMonth'
                    noStyle
                    dependencies={['ageYear']}
                    rules={[
                      {
                        pattern: /^(0?[0-9]|1[0-1])$/,
                        message: '请输入正确的月份',
                      },
                    ]}
                  >
                    <Input
                      style={{ maxWidth: '3rem' }}
                      onChange={updateAgeYear}
                    />
                  </Form.Item>
                  <div
                    style={{
                      color: theme.tc2,
                      fontSize: '1rem',
                      margin: '0 6px',
                    }}
                  >
                    月
                  </div>
                  <Form.Item
                    name='ageYear'
                    noStyle
                    style={{ flex: 1 }}
                    rules={[{ max: 16, message: '最多16个字' }]}
                  >
                    <Input onChange={updateYearsAndMonths} />
                  </Form.Item>
                </Row>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='联系方式'
                name='phone'
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: new RegExp(
                      /^\d{3}-\d{8}|\d{4}-\d{7}|^(1[3,4,5,6,7,8,9][0-9]{9})$/
                    ),
                    message: '请输入正确的联系方式',
                  },
                ]}
              >
                <Input maxLength={13} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='身份证号码'
                name='identityCard'
                rules={[
                  {
                    pattern: new RegExp(
                      /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
                    ),
                    message: '请输入合法身份证号',
                  },
                ]}
              >
                <Input
                  onChange={(e: any) => {
                    setIdCard(e.target.value)
                    const date = new Date()

                    const getMonth = date.getMonth() + 1 //当前月份
                    const month = parseInt(e.target.value?.substr(10, 2)) //身份证上的月份

                    const getFullYear = date.getFullYear() //当前年份
                    const year = parseInt(e.target.value?.substr(6, 4)) //身份证上的年份
                    const birthday = moment(
                      e.target.value?.substr(6, 8)
                    ).format(DateTimeFormatSimple)

                    //  身份证上的年月日
                    if (idCard) {
                      if (idCard.length === 17) {
                        if (getMonth - month > 0) {
                          form.setFieldsValue({
                            age: getFullYear - year,
                            ageMonth: getMonth - month,
                            ageYear: idCard.length === 17 ? birthday : '',
                          })
                        } else if (getMonth - month < 0) {
                          form.setFieldsValue({
                            age: getFullYear - year - 1,
                            ageMonth: 12 - (month - getMonth),
                            ageYear: idCard.length === 17 ? birthday : '',
                          })
                        } else if (getMonth - month === 0) {
                          form.setFieldsValue({
                            age: getFullYear - year - 1,
                            ageMonth: 0,
                            ageYear: idCard.length === 17 ? birthday : '',
                          })
                        }
                      } else if (idCard.length < 17 || e.target.value === '') {
                        form.setFieldsValue({
                          age: '',
                          ageMonth: '',
                          ageYear: '',
                        })
                      }
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='体温'
                name='animalHeat'
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,2})?$/),
                    message: '请输入正确体温',
                  },
                ]}
              >
                <Input
                  suffix='℃'
                  style={{ width: '95px' }}
                  onChange={async (e) => {
                    await form.validateFields(['animalHeat'])
                    if (Number(e.target.value) > 45) {
                      notification.error({
                        message: '体温最高为45度',
                      })
                      form.setFieldsValue({
                        animalHeat: '45',
                      })
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='职业' name='occupation'>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='来院目的' name='purpose'>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label='当前居住地址'
                name='address'
                {...tailLayout}
                rules={[
                  {
                    required: true,
                    message: '请输入居住地址',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                label='症状(可多选)'
                name='symptom'
                required
                {...tailLayout}
              >
                <Checkbox
                  value='无以下症状'
                  style={{ lineHeight: '32px' }}
                  checked={checkAll}
                  onChange={() => {
                    setCheckAll(!checkAll)
                    setCheckedList([''])
                    setTakeDrug(false)
                  }}
                >
                  无以下症状
                </Checkbox>
                <ox.Group
                  options={plainOptions}
                  value={checkedList}
                  onChange={(list) => {
                    if (list.length) {
                      setCheckAll(false)
                    }
                    setCheckedList(list)
                  }}
                ></Checkbox.Group>
                {
                  !checkAll && (
                    <Checkbox
                      value='无以下症状'
                      style={{ lineHeight: '32px' }}
                      checked={takeDrug}
                      onChange={() => {
                        setTakeDrug(!takeDrug)
                      }}
                    >
                      已服用治疗缓解上述症状的药物
                    </Checkbox>
                  )
                }
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                label='健康码颜色'
                name='healthCodeColor'
                required
                {...tailLayout}
              >
                <Radio.Group defaultValue={0}>
                  <Radio value={0}>绿色</Radio>
                  <Radio value={1}>黄色</Radio>
                  <Radio value={2}>红色</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                label='行程码颜色'
                name='tourCode'
                required
                {...tailLayout}
              >
                <Radio.Group defaultValue={0}>
                  <Radio value={0}>绿色</Radio>
                  <Radio value={1}>黄色</Radio>
                  <Radio value={2}>橙色</Radio>
                  <Radio value={3}>红色</Radio>
                </Radio.Group>
              </Form.Item>
            </Col> */}
            {/* <Col span={24}>
            <Form.Item
              label="有无新冠肺炎相关症状"
              name="describes"
              {...tailLayout}
            >
              <TextArea placeholder="具体症状，日期写明" autoSize />
            </Form.Item>
          </Col> */}
            {/* <Col span={24}>
              <Form.Item
                style={{ marginLeft: '3.4%' }}
                label='行程码是否带星号'
                name='tourCodeType'
                required
                {...tailLayout}
              >
                <Radio.Group
                  defaultValue={select}
                  onChange={(e: any) => {
                    if (e.target.value !== 0) {
                      setStarTag(true)
                    } else {
                      setStarTag(false)
                    }
                  }}
                >
                  <Radio value={1}>有</Radio>
                  <Radio value={0}>无</Radio>
                </Radio.Group>
              </Form.Item>
            </Col> */}
            <Form
              form={form}
              name='form1'
              autoComplete='off'
              colon={false}
              style={{ marginLeft: '17%' }}
              layout='vertical'
              onFinish={(vs) => {
                submitTriageItem(vs)
              }}
            >
              {starTag ? (
                <Col span={24}>
                  <Form.Item
                    label='(1)28天内本人或家属有无中高风险地区病例持续传播的旅行史或居住史，或是来自病例报告社区的发热伴呼吸道症状的患者?'
                    name='riskAreaType'
                  >
                    <Radio.Group
                      style={{ marginLeft: '2%' }}
                      defaultValue={0}
                      onChange={(e: any) => {
                        if (e.target.value !== 0) {
                          setLivingHistory(true)
                        } else {
                          setLivingHistory(false)
                        }
                      }}
                    >
                      <Radio value={1}>有</Radio>
                      <Radio value={0}>无</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {livingHistory && starTag ? (
                <Col span={24}>
                  <Form.Item
                    style={{ marginLeft: '2%', marginTop: '-2%' }}
                    label='如有，请填写具体信息'
                    name='riskArea'
                    required
                    rules={[
                      {
                        required: true,
                        message: '请填写具体信息',
                      },
                    ]}
                  >
                    <Input style={{ width: '45rem' }} />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {starTag ? (
                <Col span={24}>
                  <Form.Item
                    label='(2)28天内本人或家属有离开本省，使用何种交通工具?'
                    name='leaveProvince'
                  >
                    <Radio.Group
                      style={{ marginLeft: '2%' }}
                      defaultValue={0}
                      onChange={(e: any) => {
                        if (e.target.value !== 0) {
                          setLeaveProvince(true)
                        } else {
                          setLeaveProvince(false)
                        }
                      }}
                    >
                      <Radio value={1}>有</Radio>
                      <Radio value={0}>无</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {starTag && leaveProvince ? (
                <Col span={24}>
                  <Form.Item
                    label=''
                    name='travelTool'
                    required
                    style={{ marginLeft: '2%', marginTop: '-2%' }}
                    rules={[
                      {
                        required: true,
                        message: '请至少勾选一项',
                      },
                    ]}
                  >
                    <Checkbox.Group>
                      <Checkbox value={0} style={{ lineHeight: '32px' }}>
                        公共交通
                      </Checkbox>
                      <Checkbox value={1} style={{ lineHeight: '32px' }}>
                        自驾
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {starTag && leaveProvince ? (
                <Col span={24}>
                  <Form.Item
                    style={{ marginLeft: '2%' }}
                    label='如有，请填写具体信息'
                    name='destinationAddress'
                    required
                    rules={[
                      {
                        required: true,
                        message: '请填写具体信息',
                      },
                    ]}
                  >
                    <Input style={{ width: '45rem' }} />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {starTag ? (
                <Col span={24}>
                  <Form.Item
                    label='(3)28天内有无发热、乏力、喉咙痛，拉肚子或其呼吸道症状?'
                    name='riskSymptom'
                  >
                    <Radio.Group
                      style={{ marginLeft: '2%' }}
                      defaultValue={0}
                      // onChange={(e: any) => {
                      //   if (e.target.value !== 0) {
                      //     setLivingHistory(true);
                      //   } else {
                      //     setLivingHistory(false);
                      //   }
                      // }}
                    >
                      <Radio value={1}>有</Radio>
                      <Radio value={0}>无</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {starTag ? (
                <Col span={24}>
                  <Form.Item
                    label='(4)周边有无≥2人发病或与确诊病例、疑似病例的人有关联?'
                    name='riskTouch'
                  >
                    <Radio.Group
                      style={{ marginLeft: '2%' }}
                      defaultValue={0}
                      // onChange={(e: any) => {
                      //   if (e.target.value !== 0) {
                      //     setLivingHistory(true);
                      //   } else {
                      //     setLivingHistory(false);
                      //   }
                      // }}
                    >
                      <Radio value={1}>有</Radio>
                      <Radio value={0}>无</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
            </Form>
            <Col span={24} style={{ backgroundColor: '#F5F5F5' }}>
              <Form.Item
                label='信息报告'
                name='messageSend'
                required
                {...tailLayout}
              >
                <Radio.Group
                  defaultValue={0}
                  onChange={(e: any) => {
                    if (e.target.value !== 0) {
                      setReportTag(true)
                    } else {
                      setReportTag(false)
                    }
                  }}
                >
                  <Radio value={1}>报告</Radio>
                  <Radio value={0}>无需报告</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {status == 1 && reportTag && (
            <>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label='报告接收单位'
                    name='acceptDepartment'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='报告对象'
                    {...layout}
                    name='reportDepartment'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select>
                      {reportDepartments.map((v) => (
                        <Option key={v} value={v}>
                          {getReportName(v)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='接收人'
                    name='acceptName'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label='接收人联系方式'
                    name='acceptPhone'
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {status != 1 && reportTag ? (
            <Row>
              <Col span={12}>
                <Form.Item
                  label='报告接收单位'
                  name='acceptDepartment'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='报告对象'
                  {...layout}
                  name='reportDepartment'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    {reportDepartments.map((v) => (
                      <Option key={v} value={v}>
                        {getReportName(v)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='接收人'
                  name='acceptName'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='接收人联系方式'
                  name='acceptPhone'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Col span={24} style={{ backgroundColor: '#F5F5F5' }}>
              <Form.Item
                label='发热病人去向电话随访(可多选)'
                name='followUp'
                // {...tailLayout}
                labelCol={{ span: status == 1 ? 8 : 5 }}
                wrapperCol={{ span: status == 1 ? 16 : 19 }}
              >
                <Checkbox.Group>
                  <Row>
                    <Checkbox value={0} style={{ lineHeight: '32px' }}>
                      自测体温已&lt;37.3℃
                    </Checkbox>
                    <Checkbox value={1} style={{ lineHeight: '32px' }}>
                      未进一步就诊
                    </Checkbox>
                    <Checkbox value={2} style={{ lineHeight: '32px' }}>
                      药店配药
                    </Checkbox>
                    <Checkbox value={3} style={{ lineHeight: '32px' }}>
                      发热门诊就诊
                    </Checkbox>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label='进入医疗机构是否佩戴口罩'
                name='mask'
                required
                {...outerLayout}
              >
                <Radio.Group defaultValue={1}>
                  <Radio value={1}>有</Radio>
                  <Radio value={0}>无</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                label='周围有无来自或途经中高风险地区的人员'
                name='highRisk'
                required
                {...outerLayout}
                style={{ marginBottom: riskArea.length ? 0 : '' }}
              >
                <Radio.Group
                  defaultValue={select}
                  onChange={(e: any) => {
                    if (e.target.value !== 0) {
                      setRiskAddress(true)
                    } else {
                      setRiskAddress(false)
                    }
                  }}
                >
                  <Radio value={1}>有</Radio>
                  <Radio value={0}>无</Radio>
                </Radio.Group>
              </Form.Item>
              <Row>
                <Col
                  span={status == 1 ? 20 : 12}
                  style={{ textAlign: 'right', paddingRight: '10px' }}
                >
                  {riskArea.length !== 0 && (
                    <p>
                      <span
                        style={{ color: '#1890ff', cursor: 'pointer' }}
                        onClick={async () => {
                          await sessionStorage.setItem(
                            'patientInfo',
                            JSON.stringify({
                              ...form.getFieldsValue(),
                              symptom: checkAll
                                ? '无以下症状'
                                : checkedList?.join(','),
                              id: triageId || location.state?.selectId || selectId,
                            })
                          )
                          history.replace({
                            pathname: '/riskArea',
                            state: {
                              tag: 3,
                            },
                          })
                        }}
                      >
                        点击查看
                      </span>
                      中高风险地区
                    </p>
                  )}
                </Col>
              </Row>
            </Col> */}
            {riskAddress ? (
              <Col span={24}>
                <Form.Item
                  label='中高风险地区'
                  name='riskAddress'
                  rules={[
                    { required: true, message: '请填写途经中高风险地区' },
                  ]}
                  {...outerLayout}
                >
                  <Input />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            {/* <Col span={24}>
              <Form.Item
                label='既往史：有无新冠肺炎确诊或疑似病例治愈7天内；密接、次密接或其他重点管控人员解除管控7天内等'
                name='pastMedicalHistory'
                required
                {...outerLayout}
              >
                <Radio.Group defaultValue={select}>
                  <Radio value={1}>有</Radio>
                  <Radio value={0}>无</Radio>
                </Radio.Group>
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                label='旅居史：7 天内有过流行病学、境外等旅居史'
                name='inbound'
                required
                {...outerLayout}
              >
                <Radio.Group defaultValue={select}>
                  <Radio value={1}>有</Radio>
                  <Radio value={0}>无</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label='工作史：是否集中隔离点、进口冷链监管仓储或加工、口岸等工作人员'
                name='coldChain'
                required
                {...outerLayout}
              >
                <Radio.Group defaultValue={select}>
                  <Radio value={1}>有</Radio>
                  <Radio value={0}>无</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label='7天内有无接触家人/同事等之间多人出现流行病学聚集性疾病'
                name='fever'
                required
                {...outerLayout}
              >
                <Radio.Group defaultValue={select}>
                  <Radio value={1}>有</Radio>
                  <Radio value={0}>无</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label='接触史：7 天内是否与上述人员、流行病学或疑似患者有接触史'
                name='touch'
                required
                {...outerLayout}
              >
                <Radio.Group defaultValue={select}>
                  <Radio value={1}>有</Radio>
                  <Radio value={0}>无</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24} style={{ backgroundColor: '#F5F5F5' }}>
              <Form.Item
                label='患者流向'
                name='patientFlow'
                {...tailLayout}
                rules={[
                  {
                    required: true,
                    message: '请选择患者流向',
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setIsRegistraBtn(e.target.value == 0)
                  }}
                >
                  <Radio value={0}>机构内就诊</Radio>
                  {/* <Radio value={1}>引导到发热门诊就诊(非公共交通)</Radio> */}
                  <Radio value={2}>留观并转运</Radio>
                  <Radio value={3}>非医患，系陪同人员</Radio>
                  <Radio value={4}>机构工作人员自查</Radio>
                  <Radio value={5}>咨询</Radio>
                  <Radio value={6}>代注</Radio>
                  <Radio value={7}>简单换药</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row justify='center' style={{ marginTop: 10 }}>
            <Space>
              {isRegistraBtn && (
                <Button
                  size={buttonSize}
                  type='primary'
                  disabled={isEdit}
                  style={{ float: 'right' }}
                  onClick={() => {
                    //口腔专业版
                    if (status === 1) {
                      form.submit()
                      setRegisionModalTag(true)
                    } else {
                      form.submit()
                      setIsRegistra(true)
                    }
                  }}
                >
                  确定并挂号
                </Button>
              )}
              <Button
                size={buttonSize}
                disabled={isEdit}
                type='primary'
                // htmlType='submit'
                style={{ float: 'right' }}
                onClick={() => {
                  setIsRegistra(false)
                  form.submit()
                }}
              >
                确定
              </Button>
              <Button
                size={buttonSize}
                type='primary'
                onClick={() => {
                  form.resetFields()
                  setKeyword('')
                  setIsRegistraBtn(false)
                  if (status == 1) {
                    cancel && cancel()
                  } else {
                    setIsRegistra(false)
                    history.push('/triage')
                  }
                  setNameDisabled(false)
                }}
              >
                返回
              </Button>
            </Space>
          </Row>
        </Form>
        <RegisteredModal
          title={0} // 0 挂号1 转诊
          patientId={modalPatientID} //新增患者-保存并挂号
          visible={registeredModal}
          registrationId={''} //挂号 ID
          appointmentId={''} //挂号 ID
          onOk={() => {
            setRegisteredModal(false)
            setList([])
          }}
          onCancel={() => {
            setRegisteredModal(false)
            setList([])
          }}
        />
      </div>
    </>
  )
}
