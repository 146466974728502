/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-20 11:21:05
 * @LastEditors: sj
 * @LastEditTime: 2022-09-22 11:48:16
 */
import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import {
  DateSelect as DateSelectOne,
  RecentTime,
} from '../../../compnents/date/TimeSelect'
import {
  replaceMakeDetailParams,
  SupplierListResponse,
} from '../../../services/OReplaceMake'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../app/store'
import { getSupplierList } from '../OReplaceMakeSlice'

export const Query = (props: {
  onValueChange: (queries: replaceMakeDetailParams) => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [supplierOptions, setSupplierOptions] = useState<
    Array<SupplierListResponse>
  >([])

  useEffect(() => {
    dispatch(getSupplierList())
      .then(unwrapResult)
      .then((res: Array<SupplierListResponse>) => {
        setSupplierOptions(res)
      })
  }, [])

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(v) => {
        props.onValueChange({ ...v })
      }}
      style={{ paddingBottom: '20px' }}
    >
      <Row>
        <Space>
          <Form.Item name='name' noStyle>
            <Input
              size='middle'
              placeholder='药品名称/助记码'
              onChange={form.submit}
              allowClear
              style={{ width: 220 }}
              prefix={<SearchOutlined />}
            />
          </Form.Item>
          <Form.Item name='supplierId' noStyle>
            <Select
              allowClear
              placeholder='代煎饮片厂'
              onChange={form.submit}
              style={{ width: 200 }}
            >
              {supplierOptions.length &&
                supplierOptions?.map((c) => (
                  <Select.Option key={c.name} value={c.id}>
                    {c.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Button htmlType='submit' type='primary'>
            查询
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
