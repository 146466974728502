import { unwrapResult } from '@reduxjs/toolkit'
import { Col, notification, Table } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'

import { ThemeContext } from '../../../theme/ThemeContext'
import { Columns } from './Columuns'
import { Query } from './Query'
import styles from './order.module.css'
import {
  getReplaceMakeRecipeDetail,
  getreplaceMakeDetailAmount,
  getReplaceMakeRecipeDetailExport,
  getReplaceMakeRecipeDetailAmount,
} from '../OReplaceMakeSlice'
import { sum } from 'lodash'
import { selectUserName, selectTenantName } from '../../../app/applicationSlice'
import { printKfywmxBody } from '../../../models/putstorelistPrint'
import { ReplaceMackRecipeDetailPrint } from '../../../models/replaceMackRecipeDetail'

// 详情
export const ReplaceMakeRecipeDetail = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const userName = useSelector(selectUserName)

  const tenantName = useSelector(selectTenantName)

  const [data, setData] = useState<any>([])

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [total, setTotal] = useState(0)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [totalAmount, setTotalAmount] = useState<any>({})

  useEffect(() => {
    setPageLoading(true)
    dispatch(
      getReplaceMakeRecipeDetail({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setTotal(res?.total)
        setData(res?.records)
      })
      .finally(() => {
        setPageLoading(false)
      })

    dispatch(getReplaceMakeRecipeDetailAmount({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        setTotalAmount(res)
      })
  }, [params])

  // 导出
  const onExport = () => {
    const param = {
      ...params,
      current: 1,
      size: 1000,
    }
    dispatch(getReplaceMakeRecipeDetailExport({ ...param }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '代煎处方明细.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const dataMainList = {
    userName: userName,
    tenantName: tenantName?.split('-')?.[0],
    dataList: data,
    amount:totalAmount?.drugCount
  }

  const content = ReplaceMackRecipeDetailPrint(dataMainList)

  // 打印
  const print = () => {
    const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
    page.document.write(content) // 写入打印页面的内容
    page.print() // 打印
    page?.close()
  }

  return (
    <Col
      style={{
        height: 'calc(100% - 20px) ',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: theme.pn,
          marginTop: 10,
          borderRadius: 10,
          padding: '10px 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Query
          onValueChange={(v) => {
            setParams({
              ...params,
              ...v,
              current: 1,
            })
          }}
          onExport={onExport}
          print={print}
        />
        <EditableList
          page={{
            items: data,
            current: params.current || 1,
            size: params.size || 10,
            total,
          }}
          loading={pageLoading}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell className={styles.footingTitle} index={1}>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="right">
                  {totalAmount?.drugCount}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          style={{ color: theme.tc3 }}
          className={styles.table}
          columns={Columns((params.size || 10) * ((params.current || 1) - 1))}
          onChangePage={(current: number, size?: number) => {
            setParams({
              ...params,
              current: current,
              size: size || 10,
            })
          }}
        />
      </Col>
    </Col>
  )
}
