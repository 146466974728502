import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import { fromJson, PaymentConfirmation } from '../../../models/confirmation'
import * as R from '../../../models/paymentresult'
import { PaymentResult } from '../../../models/paymentresult'
import {
  confirmPayment as apiConfirmPayment,
  getDrugListFn,
} from '../../../services/confirmation'
import {
  AnalysisBody,
  cancelInvoice,
  CancelInvoiceParams,
  cancelSettlement,
  faceCall,
  FaceCallBody,
  FeeListUp,
  FeeListUpCancel,
  getInvoicingStatus,
  GetInvoicingStatusParams,
  inprocessAnalysis,
  invoiceDetail,
  invoicePage,
  InvoicePageParams,
  InvoiceUrlParams,
  MdtrtinfoUp,
  MdtrtinfoUpABody,
  pay as apiPay,
  PayParams,
  preSettlement,
  redelivery,
  RedeliveryParams,
  saveSettlement,
  SaveSettlementBody,
  selfPayRepeal,
  selfPayUpload,
  SelfPayUploadBody,
  setUpdateFlags,
  updateCardInfo,
  updateCardInfoParams,
  updateFlagsParams,
  JuhePaySave,
  JuhePaySaveParams,
  SearchJuhePayFn,
  SearchJuhePayFnParams,
  JuhePayCancleFn,
  JuhePayCancelParams,
  getJuhePageFn,
  getJuhePageFnParams,
  submitSupplementaryFn,
  submitSupplementaryFnParams,
  QZFaceCall,
  outRecipeBody,
  outRecipe,
  invoiceExport,
  exportListHz,
  getSingleDiscountFn,
  getSingleDiscountFnParams,
  doBatchPay,
  ticketNum,
  beforeAlertBody,
  beforeAlert,
  getInvoicingStatusForMbfe,
  cancelInvoiceMbfe,
  paymentWayList,
  paymentWayListAddOrEditSubmitParams,
  paymentWayListAddOrEditSubmit,
  paymentWayListDelete,
  paymentWayListAllSubmitParams,
  ticketAccountCheckParams,
  ticketAccountCheckMbfe,
  LSFaceCall,
  getNoInvoicedQuery,
  NoInvoicedQueryParams,
  recipeInventoryVerification,
  electronicPrescriptionInfoVerification, electronicPrescriptionSign, electronicPrescriptionUpload,
} from '../../../services/payment'

interface PaymentConfirmState {
  confirmation?: PaymentConfirmation
  drugList?: PaymentConfirmation
}

const initialState = {} as PaymentConfirmState

export const pay = createAsyncThunk<PaymentResult, PayParams, RootThunkApi>(
  'confirm/payStatus',
  async (params, api) => {
    return sendAsync(apiPay(params), api).then((data) => R.fromJson(data))
  }
)

export const doBatchPayAsync = createAsyncThunk<
  PaymentResult,
  PayParams,
  RootThunkApi
>('confirm/payStatus', async (params, api) => {
  return sendAsync(doBatchPay(params), api).then((data) => R.fromJson(data))
})

// 修改诊断结果表相关FLAG标志
export const setUpdateFlagsAsync = createAsyncThunk<
  string,
  updateFlagsParams,
  RootThunkApi
>('confirm/setUpdateFlags', async (params, api) => {
  if (!params?.treatmentId) return
  return sendAsync(setUpdateFlags(params), api)
})

// 2203 就诊信息上传
export const MdtrtinfoUpAsync = createAsyncThunk<
  string,
  MdtrtinfoUpABody,
  RootThunkApi
>('confirm/MdtrtinfoUp', async (body, api) => {
  return sendAsync(MdtrtinfoUp(body), api)
})

// 2204 门诊费用明细信息上传
export const FeeListUpAsync = createAsyncThunk<
  string,
  MdtrtinfoUpABody,
  RootThunkApi
>('confirm/FeeListUp', async (body, api) => {
  return sendAsync(FeeListUp(body), api)
})
// 2205 门诊费用明细信息撤销
export const FeeListUpCancelAsync = createAsyncThunk<
  string,
  MdtrtinfoUpABody,
  RootThunkApi
>('confirm/FeeListUpCancel', async (body, api) => {
  return sendAsync(FeeListUpCancel(body), api)
})
// 2206 预结算
export const preSettlementAsync = createAsyncThunk<
  string,
  SaveSettlementBody,
  RootThunkApi
>('confirm/preSettlement', async (body, api) => {
  return sendAsync(preSettlement(body), api)
})

// 7101外配处方
export const outRecipeAsync = createAsyncThunk<
  string,
  outRecipeBody,
  RootThunkApi
>('confirm/outRecipeAsync', async (body, api) => {
  return sendAsync(outRecipe(body), api)
})

// 人脸识别
export const faceCallAsync = createAsyncThunk<
  string,
  FaceCallBody,
  RootThunkApi
>('confirm/faceCall', async (body, api) => {
  return sendAsync(faceCall(body), api)
})

// 衢州人脸识别
export const QZFaceCallAsync = createAsyncThunk<
  string,
  FaceCallBody,
  RootThunkApi
>('confirm/QZFaceCallAsync', async (body, api) => {
  return sendAsync(QZFaceCall(body), api)
})
// 丽水人脸识别
export const LSFaceCallAsync = createAsyncThunk<
  string,
  FaceCallBody,
  RootThunkApi
  >('confirm/LSFaceCallAsync', async (body, api) => {
  return sendAsync(LSFaceCall(body), api)
})

// 2207 门诊结算
export const saveSettlementAsync = createAsyncThunk<
  any,
  SaveSettlementBody,
  RootThunkApi
>('confirm/saveSettlement', async (body, api) => {
  return sendAsync(saveSettlement(body), api)
})
// 2208 门诊结算
export const cancelSettlementAsync = createAsyncThunk<
  string,
  SaveSettlementBody,
  RootThunkApi
>('confirm/cancelSettlement', async (body, api) => {
  return sendAsync(cancelSettlement(body), api)
})

// 3102 事中提醒
export const inprocessAnalysisAsync = createAsyncThunk<
  any,
  AnalysisBody,
  RootThunkApi
>('confirm/inprocessAnalysis', async (body, api) => {
  return sendAsync(inprocessAnalysis(body), api)
})
// 3101 事前提醒
export const beforeAlertAsync = createAsyncThunk<
  any,
  beforeAlertBody,
  RootThunkApi
>('confirm/beforeAlertAsync', async (body, api) => {
  return sendAsync(beforeAlert(body), api)
})
// 更新医保卡信息
export const updateCardInfoAsync = createAsyncThunk<
  any,
  updateCardInfoParams,
  RootThunkApi
>('confirm/updateCardInfoAsync', async (body, api) => {
  return sendAsync(updateCardInfo(body), api)
})

// 自费上传接口
export const selfPayUploadAsync = createAsyncThunk<
  any,
  SelfPayUploadBody,
  RootThunkApi
>('confirm/selfPayUploadAsync', async (body, api) => {
  return sendAsync(selfPayUpload(body), api)
})

// 自费上传撤销接口
export const selfPayRepealAsync = createAsyncThunk<
  any,
  SelfPayUploadBody,
  RootThunkApi
>('confirm/selfPayRepealAsync', async (body, api) => {
  return sendAsync(selfPayRepeal(body), api)
})

// 获取开通电子发票列表
export const invoiceDetailAsync = createAsyncThunk<
  any,
  InvoiceUrlParams,
  RootThunkApi
>('confirm/invoiceDetailAsync', async (body, api) => {
  return sendAsync(invoiceDetail(body), api)
})

// 开票记录列表
export const invoicePageAsync = createAsyncThunk<
  any,
  InvoicePageParams,
  RootThunkApi
>('confirm/invoicePageAsync', async (body, api) => {
  return sendAsync(invoicePage(body), api)
})

// 诺诺电子发票余票
export const getTicketNumAsync = createAsyncThunk<void, any, RootThunkApi>(
  'confirm/ticketNumAsync',
  async (params, api) => {
    return sendAsync(ticketNum(params), api)
  }
)

// 国税明细导出
export const invoiceExportAsync = createAsyncThunk<
  any,
  InvoicePageParams,
  RootThunkApi
>('confirm/invoiceExportAsync', async (body, api) => {
  return sendAsync(invoiceExport(body), api)
})

// 国税明细导出
export const exportListHzAsync = createAsyncThunk<
  any,
  InvoicePageParams,
  RootThunkApi
>('confirm/exportListHzAsync', async (body, api) => {
  return sendAsync(exportListHz(body), api)
})

// 聚合支付
export const JuhePayFn = createAsyncThunk<any, JuhePaySaveParams, RootThunkApi>(
  'confirm/JuhePayFn',
  async (body, api) => {
    return sendAsync(JuhePaySave(body), api)
  }
)
// 撤销聚合支付
export const JuhePayCancle = createAsyncThunk<
  any,
  JuhePayCancelParams,
  RootThunkApi
>('confirm/JuhePayCancle', async (params, api) => {
  return sendAsync(JuhePayCancleFn(params), api)
})
// 查询聚合支付状态
export const SearchJuhePay = createAsyncThunk<
  any,
  SearchJuhePayFnParams,
  RootThunkApi
>('confirm/SearchJuhePay', async (params, api) => {
  return sendAsync(SearchJuhePayFn(params), api)
})

// 查询开票结果
export const getInvoicingStatusAsync = createAsyncThunk<
  any,
  GetInvoicingStatusParams,
  RootThunkApi
>('confirm/getInvoicingStatusAsync', async (body, api) => {
  return sendAsync(getInvoicingStatus(body), api)
})

// 查询未开票数据
export const getNoInvoicedQueryList = createAsyncThunk<
  any,
  NoInvoicedQueryParams,
  RootThunkApi
>('confirm/getNoInvoicedQueryList', async (body, api) => {
  return sendAsync(getNoInvoicedQuery(body), api)
})

// 查询开票结果（前置机交易模式）
export const getInvoicingStatusForMbfeAsync = createAsyncThunk<
  any,
  GetInvoicingStatusParams,
  RootThunkApi
>('confirm/getInvoicingStatusAsyncForMbfe', async (body, api) => {
  return sendAsync(getInvoicingStatusForMbfe(body), api)
})

// 电子发票红冲申请（前置机模式）
export const cancelInvoiceMbfeAsync = createAsyncThunk<
  any,
  CancelInvoiceParams,
  RootThunkApi
>('confirm/cancelInvoiceMbfeAsync', async (body, api) => {
  return sendAsync(cancelInvoiceMbfe(body), api)
})

// 电子发票日对账（前置机模式）
export const ticketAccountCheckMbfeAsync = createAsyncThunk<
    any,
    ticketAccountCheckParams,
    RootThunkApi
    >('confirm/ticketAccountCheckMbfeAsync', async (body, api) => {
  return sendAsync(ticketAccountCheckMbfe(body), api)
})


// 电子发票红冲申请
export const cancelInvoiceAsync = createAsyncThunk<
  any,
  CancelInvoiceParams,
  RootThunkApi
>('confirm/cancelInvoiceAsync', async (body, api) => {
  return sendAsync(cancelInvoice(body), api)
})

// 电子发票重新交付
export const redeliveryAsync = createAsyncThunk<
  any,
  RedeliveryParams,
  RootThunkApi
>('confirm/redeliveryAsync', async (body, api) => {
  return sendAsync(redelivery(body), api)
})

const confirmSlice = createSlice({
  name: 'confirm',
  initialState,
  reducers: {
    setConfirmation: (state, action: PayloadAction<PaymentConfirmation>) => {
      state.confirmation = action.payload
    },
    clearConfirmation: (state) => {
      state.confirmation = undefined
    },
    setDrugList: (state, action: PayloadAction<PaymentConfirmation>) => {
      //赋值
      state.drugList = action.payload
    },
  },
})

// 获取聚合支付列表 / 欠费管理列表
export const getJuhePage = createAsyncThunk<
  any,
  getJuhePageFnParams,
  RootThunkApi
>('confirm/getJuhePage', async (params, api) => {
  return sendAsync(getJuhePageFn(params), api)
})
// 欠费补交
export const submitSupplementary = createAsyncThunk<
  any,
  submitSupplementaryFnParams,
  RootThunkApi
>('confirm/submitSupplementary', async (params, api) => {
  return sendAsync(submitSupplementaryFn(params), api)
})
// 整单折扣
export const getSingleDiscount = createAsyncThunk<
  any,
  getSingleDiscountFnParams,
  RootThunkApi
>('confirm/getSingleDiscount', (params, api) => {
  return sendAsync(getSingleDiscountFn(params), api)
})

const { setConfirmation, setDrugList } = confirmSlice.actions
export const { clearConfirmation } = confirmSlice.actions

export const selectConfirmation = (
  state: RootState
): PaymentConfirmation | undefined => state.confirm.confirmation

export const selectDrugList = (
  state: RootState
): PaymentConfirmation | undefined => state.confirm.drugList

export function confirmPayment(ids: string): RootThunk {
  return api(apiConfirmPayment(ids), (data: any, dispatch) => {
    const confirmation = fromJson(data)
    dispatch(setConfirmation(confirmation))
  })
}

export function getDrugList(paymentId: string): RootThunk {
  //抛出药品列表信息
  return api(getDrugListFn(paymentId), (data: any, dispatch) => {
    const confirmation = fromJson(data)
    dispatch(setDrugList(confirmation))
  })
}

// 获取收费设置支付方式
export const getPaymentWayList = createAsyncThunk<any, void, RootThunkApi>(
  'confirm/getPaymentWayList',
  (params, api) => {
    return sendAsync(paymentWayList(), api)
  }
)
// 收费设置支付方式新增或修改
export const getPaymentWayListAddOrEditSubmit = createAsyncThunk<
  void,
  paymentWayListAddOrEditSubmitParams,
  RootThunkApi
>('confirm/getPaymentWayListAddOrEditSubmit', (params, api) => {
  return sendAsync(paymentWayListAddOrEditSubmit(params), api)
})

// 收费设置支付方式删除
export const getPaymentWayListDelete = createAsyncThunk<
  void,
  any,
  RootThunkApi
>('confirm/getPaymentWayListDelete', (params, api) => {
  return sendAsync(paymentWayListDelete(params), api)
})


// 收费设置支付方式整体保存
export const getPaymentWayListAllSubmit = createAsyncThunk<void, any, RootThunkApi>(
  'confirm/getPaymentWayListAllSubmit',
  (params, api) => {
    return sendAsync(paymentWayListAllSubmitParams(params), api)
  }
)

export const recipeInventoryVerificationAsync = createAsyncThunk<
  string,
  outRecipeBody,
  RootThunkApi
>('confirm/recipeInventoryVerificationAsync', async (body, api) => {
  return sendAsync(recipeInventoryVerification(body), api)
})

export const electronicPrescriptionInfoVerificationAsync = createAsyncThunk<
  string,
  outRecipeBody,
  RootThunkApi
>('confirm/electronicPrescriptionInfoVerificationAsync', async (body, api) => {
  return sendAsync(electronicPrescriptionInfoVerification(body), api)
})

export const electronicPrescriptionSignAsync = createAsyncThunk<
  string,
  outRecipeBody,
  RootThunkApi
>('confirm/electronicPrescriptionSignAsync', async (body, api) => {
  return sendAsync(electronicPrescriptionSign(body), api)
})

export const electronicPrescriptionUploadAsync = createAsyncThunk<
  string,
  outRecipeBody,
  RootThunkApi
>('confirm/electronicPrescriptionUploadAsync', async (body, api) => {
  return sendAsync(electronicPrescriptionUpload(body), api)
})

export default confirmSlice.reducer
