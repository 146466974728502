/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-10 11:13:31
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-15 18:54:25
 */
import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { Detail } from '../../../triage/Detail'
import styles from '../ToothHome.module.css'

interface AddPreviewingModalProps {
  selectId?: string
  status?: number
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

export const AddPreviewingModal = ({
  selectId,
  visible,
  onOk,
  status,
  onCancel,
}: AddPreviewingModalProps) => {
  const [visibleFlag, setVisibleFlag] = useState<any>(false)

  useEffect(() => {
    if (visible && visible != undefined) {
      setVisibleFlag('true')
    } else {
      setVisibleFlag('false')
    }
  }, [visible])

  return (
    <Modal
      title={selectId ? '编辑预检分诊' : '新增预检分诊'}
      width={900}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      footer={null}
    >
      <div className={styles.addPreviewingModalWrap}>
        <Detail
          visible={visibleFlag}
          selectId={selectId}
          status={1}
          onSubmit={onOk}
          cancel={() => {
            onCancel()
          }}
        />
      </div>
    </Modal>
  )
}
