/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-15 09:59:10
 * @LastEditors: sj
 * @LastEditTime: 2022-11-28 16:21:09
 */
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../../../app/store';
import { NavBar } from '../../../../compnents/nav/NavBar';
import { PatientLable } from '../../../patient/patientLable/patientlable';
import { setActiveObj } from '../patientSlice';

export const APatientLabel = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()
  return (
    <div style={{
      padding: 10,
      height: '100%'
    }}>
      <NavBar
        where={['患者管理', '标签管理']}
        backtrace={{
          name: '患者管理',
          path: '/toothPatient',
          // state: {
          //   backType: 'label',
          // },
        }}
        onClick={() => {
          dispatch(setActiveObj({
            tabsActiveKey: '2'
          }))
        }}
      />
      <PatientLable state='' />
    </div>
  );
}
