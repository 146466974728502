import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { Button, Col, Row, Table } from "antd";
import React, { ReactElement, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch } from "../../../app/store";
import { Page } from "../../../models/page";
import {
  getRecipeCategoryName,
  isTcmRecipeCategory,
  RecipeCategory,
} from "../../../models/recipe";
import { Treatment } from "../../../models/treatment";
import { CopyRecipeParams } from "../../../services/recipe";
import { CopyTreatmentParams } from "../../../services/treatment";
import { ThemeContext } from "../../../theme/ThemeContext";
import { MedicineMaterialColumns, TcmColumns } from "../editor/columns";
import {
  selectMaterials,
  openTreatment,
  openMaterial,
  getMaterialList,
  getRecipeList,
  selectRecipes,
} from "../list/treatmentSlice";

interface HistoryTreatmentBoxListProps {
  page: any;
  recipeCategory?: RecipeCategory;
  showDiagnosis?: boolean;
  exact?: boolean;
  onConnect?: (v: any) => void;
}

export const HistoryTreatmentBox = ({
  page,
  recipeCategory,
  exact = true,
  showDiagnosis = true,
  onConnect,
}: HistoryTreatmentBoxListProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const theme = useContext(ThemeContext);

  const recipes = useSelector(selectRecipes);

  const materials = useSelector(selectMaterials);


  return (
    <>
      {page?.map((t: any, i: any) => {
        const rs: any = recipes[t.id];
        return (
          <Col key={t.id}>
            <Row
              style={{
                height: "3rem",
                border: `solid 1px ${theme.wbd}`,
                color: theme.tc1,
                fontSize: "1rem",
                padding: "0 10px",
                whiteSpace: "nowrap",
                marginTop: i ? 10 : 0,
              }}
              onClick={() => {
                if (rs) {
                  dispatch(openTreatment({ id: t.id, open: !rs.open }));
                } else {
                  dispatch(getRecipeList(t.id));
                }
              }}
              align="middle"
              wrap={false}
              justify="space-between"
            >
              <Col>{t.createTime}</Col>
              <Col style={{ marginLeft: 10 }}>{t.patientName}</Col>
              <Col style={{ marginLeft: 10 }}>{t.treatmentDoctorName}</Col>
              <Col style={{ marginLeft: 10 }}>
                （{t.treatmentDepartmentName}）
              </Col>
              <Col
                style={{
                  marginLeft: 10,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: theme.c1,
                  fontWeight: "bold",
                }}
                flex={1}
              >
                {t.disease}
              </Col>
              {showDiagnosis && onConnect && (
                <>
                  <Col style={{ marginLeft: 10 }}>
                    <Button
                      style={{ fontSize: "1rem" }}
                      onClick={() => {
                        onConnect &&
                          onConnect({
                            t,
                          });
                      }}
                    >
                      关联
                    </Button>
                  </Col>
                </>
              )}
              <Col style={{ marginLeft: 10, cursor: "pointer" }}>
                {rs?.open ? (
                  <CaretUpFilled style={{ color: "#999999" }} />
                ) : (
                  <CaretDownFilled style={{ color: "#999999" }} />
                )}
              </Col>
            </Row>
            {rs?.open && (
              <Row>
                <Col
                  style={{
                    alignItems: "stretch",
                    borderLeft: `solid 1px ${theme.wbd}`,
                    borderRight: `solid 1px ${theme.wbd}`,
                    borderBottom: `solid 1px ${theme.wbd}`,
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                  flex={1}
                >
                  {showDiagnosis && (
                    <div
                      style={{
                        marginLeft: 10,
                        color: theme.tc2,
                        fontSize: "1rem",
                      }}
                    >
                      <div>主诉：{t.chiefComplaint}</div>
                      <div>现病史：{t.medicalHistory}</div>
                      <div>既往史：{t.pastMedicalHistory}</div>
                      <div>疾病名称：{t.disease}</div>
                      <div>治疗意见：{t.treatmentConclusion}</div>
                    </div>
                  )}
                  {rs.recipes
                    .filter((r: any) => {
                      if (recipeCategory === undefined) {
                        return true;
                      }
                      if (exact) {
                        return recipeCategory === r.category;
                      }
                      if (recipeCategory === RecipeCategory.Medicine) {
                        return !isTcmRecipeCategory(r.category);
                      }
                      return isTcmRecipeCategory(r.category);
                    })
                    .map((r: any) => {
                      const ms = materials[r.id];
                      return (
                        <>
                          <Row
                            key={r.id}
                            style={{
                              marginTop: 20,
                              marginLeft: 10,
                              marginRight: 10,
                              padding: "0 10px",
                              height: "2.5rem",
                              backgroundColor: theme.th,
                            }}
                            onClick={() => {
                              if (ms) {
                                dispatch(
                                  openMaterial({ id: r.id, open: !ms.open })
                                );
                              } else {
                                // dispatch(getMaterialList(r.id));
                                  getMaterialList({
                                    recipeId: r.id,
                                    size: 100,
                                  })
                              }
                            }}
                            align="middle"
                          >
                            <Col
                              flex={1}
                              style={{ color: theme.tc1, fontSize: "1rem" }}
                            >
                              {getRecipeCategoryName(r.category)}
                              处方单，处方编号：
                              {r.id}
                            </Col>
                            {!showDiagnosis && (
                              <Col style={{ marginRight: 20 }}>
                                <Button
                                  onClick={() =>
                                    onConnect &&
                                    onConnect({
                                      srcRecipeId: r.id,
                                    } as CopyRecipeParams)
                                  }
                                >
                                  复制处方
                                </Button>
                              </Col>
                            )}
                            <Col>
                              <a style={{ fontSize: "1rem", marginRight: 10 }}>
                                单击查看处方信息
                              </a>
                            </Col>
                            <Col>
                              {ms?.open ? (
                                <CaretUpFilled />
                              ) : (
                                <CaretDownFilled />
                              )}
                            </Col>
                          </Row>
                          {ms?.open && (
                            <Table
                              style={{ margin: "0 10px" }}
                              pagination={false}
                              size="small"
                              bordered
                              columns={
                                r.category === RecipeCategory.Tcm ||
                                r.category === RecipeCategory.TcmGranules
                                  ? TcmColumns(theme)
                                  : MedicineMaterialColumns(theme, ms.materials)
                              }
                              dataSource={ms.materials}
                            />
                          )}
                        </>
                      );
                    })}
                </Col>
              </Row>
            )}
          </Col>
        );
      })}
    </>
  );
};
