/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 12:05:04
 * @LastEditors: sj
 * @LastEditTime: 2022-06-07 16:06:47
 */
import { Col } from "antd"
import React, { useRef } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { TabBar, TabPane } from "../../compnents/widgets/TabBar"
import { RecipeStatus } from "../../models/recipe"
import { selfPayUploadType } from "../../models/selfPayUpload"
import { Delivery } from "../delivery/Delivery"
import { InventoryChecking } from "../InventoryChecking/InventoryChecking"
import { Payments, PaymentsRef } from "../payment/list/Payments"
import { Putstorage } from "../putstorage/putstorage"
import { SetMealRef } from "../setmeal/setmeal"
import { InfoQueryRef, InfoQueryRefProps } from "./infoQuery"
import styles from "./selfPayUpload.module.css"

export const SelfPayUploadTabBar = () => {
  const ref = useRef<PaymentsRef>(null)

  const queryRef = useRef<InfoQueryRefProps>(null)

  const location: any = useLocation()

  return (
    <Col className={styles.container}>
      <TabBar
        defaultActiveKey={location.state ? location.state.station : "1"}
      >
        <TabPane key='1' tab='自费结算上传'>
          <Payments YBZFUploadflag={true} status={RecipeStatus.Settled} sibling={ref.current} />
        </TabPane>
        <TabPane key='2' tab='自费病人就医费用明细'>
          <InfoQueryRef type={selfPayUploadType.costInfo} ref={queryRef} />
        </TabPane>
        <TabPane key='3' tab='自费病人就医就诊信息'>
          <InfoQueryRef type={selfPayUploadType.clinicInfo} ref={queryRef} />
        </TabPane>
        <TabPane key='5' tab='自费病人就医诊断信息'>
          <InfoQueryRef type={selfPayUploadType.diagnoseInfo} ref={queryRef} />
        </TabPane>
      </TabBar>
    </Col>
  )
}
