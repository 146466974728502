/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-04 09:24:42
 * @LastEditors: sj
 * @LastEditTime: 2022-01-05 16:47:36
 */
import { Modal, ModalProps, Form, Radio } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'

interface ReplaceMakrProps {
  replaceMakeId?: string
  replaceMakeStatus?: number
}

export const ReplaceMakeModal = ({
  replaceMakeId,
  replaceMakeStatus,
  onCancel,
  onOk,
  ...others
}: ReplaceMakrProps & ModalProps): ReactElement => {
  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = useState(false)

  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (!isOpen) {
      form.setFieldsValue({
        isChinese: 0,
        isPM: 0,
        isUpdate: 0,
      })
    }else{
      form.setFieldsValue({
        isChinese: 1,
        isPM: 1,
        isUpdate: 1,
      })
    }
  }, [isOpen])

  const required = {
    rules: [
      {
        required: true,
      },
    ],
  }

  return (
    <Modal
      {...others}
      title={!replaceMakeStatus ? '开通代煎' : '代煎选项设置'}
      width={600}
      confirmLoading={isLoading}
      cancelText='取消'
      okText='确定'
      onCancel={(e) => {
        if (!replaceMakeId) {
          form.resetFields()
        }
        onCancel && onCancel(e)
      }}
      onOk={(e) => {
        form
          .validateFields()
          .then((values) => {
            return
          })
          .catch(() => {
            // Do nothing.
          })
      }}
    >
      <Form
        form={form}
        colon={false}
        autoComplete='off'
        initialValues={{
          isOpen: 1,
          isChinese: 1,
          isPM: 1,
          isUpdate: 1,
        }}
      >
        <Form.Item label='是否开通代煎' name='isOpen' {...required}>
          <Radio.Group
            onChange={(e) => {
              setIsOpen(!!e.target.value)
            }}
          >
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='是否开通中药代煎' name='isChinese' {...required}>
          <Radio.Group disabled={!isOpen}>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='是否开通颗粒剂代煎' name='isPM' {...required}>
          <Radio.Group disabled={!isOpen}>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='是否自动上传' name='isUpdate' {...required}>
          <Radio.Group disabled={!isOpen}>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}
