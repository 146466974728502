
import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space, Modal, Col, Radio, notification } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import {
    DateRangeType,
    DateSelect,
    getDateRange,
} from '../../compnents/form/DateSelect'
import { EditableList } from '../../compnents/list/EditableList'
import styles from './coupons.module.css'
import { CouponsIssueColumns } from './columns'
import { RootDispatch } from '../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { CouponsIssueQuery } from './query'
import { CheckOutlined } from '@ant-design/icons'
import { TableRowSelection } from 'antd/lib/table/interface';
import { getActiveSettingList, getActiveSettingDetail, getCouponsIssueSubmit, getCouponsSettingList } from './coupons'
import { CouponsItems } from './couponsItems'
import { getPatientList } from '../patient/list/patientListSlice'

const { Option } = Select

export const CouponsIssue = (props: { selectKey: string }) => {
    const dispatch = useDispatch<RootDispatch>()

    const [params, setParams] = useState<any>({
        current: 1,
        size: 20,
    })

    const [page, setPage] = useState<any>({
        total: 0,
        items: [],
    })

    const [form] = Form.useForm()

    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 20 },
    }

    const [selectIds, setSelectIds] = useState<any>([])

    const [examineData, setExamineData] = useState([])

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            setSelectIds(selectedRowKeys)
        },
    }

    const [title, setTitle] = useState('')

    const [editId, setEditId] = useState<any>()

    const [pageLoading, setPageLoading] = useState(false)

    const [visable, setVisable] = useState(false)

    const [couponsList, setCouponsList] = useState<any>([])

    const [couponsIds, setCouponsIds] = useState<any>([])

    const [radioState, setRadioState] = useState(1)

    const [activityId, setActivityId] = useState()

    const [isDisabled, setIsDisabled] = useState(false)

    const getPage = () => {
        setPageLoading(true)
        dispatch(getPatientList(params))
            .then(unwrapResult)
            .then((res: any) => {
                setPage({
                    items: res.items,
                    total: res.total,
                })
            })
            .finally(() => {
                setPageLoading(false)
            })

    }

    const getCouponsList = () => {
        dispatch(getCouponsSettingList({
            current: 1,
            size: 1000,
            status: 0
        }))
            .then(unwrapResult)
            .then((res: any) => {
                setCouponsList(res.records)
            })

        dispatch(getActiveSettingList({
            current: 1,
            size: 1000,
            status: 0
        }))
            .then(unwrapResult)
            .then((res: any) => {
                setExamineData(res.records)
            })
    }

    useEffect(() => {
        getCouponsList()
        setSelectIds([])
    }, [props.selectKey])

    useEffect(() => {
        getPage()
    }, [params])

    return (
        <div className={styles.wrap}>
            {/* 查询区域 */}
            <CouponsIssueQuery
                onAdd={() => {
                    if (selectIds.length === 0) {
                        notification.info({
                            message: '请先选择患者',
                        })
                        return
                    }
                    setCouponsList(couponsList.map((item: any) => {
                        return {
                            ...item,
                            isActive: false
                        }
                    }))
                    setVisable(true)
                    setTitle('优惠券发放')
                    setEditId('')
                    setIsDisabled(false)
                    setRadioState(1)
                    setCouponsIds([])
                    form.setFieldsValue({ issueType: 1, issueCount: 1 })
                }}
                onValueChange={(v) => {
                    setParams({
                        ...params,
                        ...v,
                        current: 1,
                    })
                }} />

            {/* 表格区域 */}
            <EditableList
                style={{ overflow: 'hidden' }}
                scroll={{ y: '620px' }}
                loading={pageLoading}
                page={{
                    current: params.current,
                    size: params.size,
                    total: page.total,
                    items: page.items,
                }}
                rowSelection={
                    {
                        selectedRowKeys: selectIds,
                        ...rowSelection,
                    } as TableRowSelection<any>
                }
                columns={CouponsIssueColumns(params.size * (params.current - 1))}
                onChangePage={(current, size) =>
                    setParams({
                        ...params,
                        current,
                        size: size || 10,
                    })
                }
                onRow={(d) => ({
                    onClick: (e) => {
                        e.stopPropagation()
                        const isCheck = selectIds.includes(d?.id)
                        let ids = []
                        if (isCheck) {
                            ids = selectIds.filter((id:any) => id !== d?.id)
                        } else {
                            ids = [...selectIds, d?.id]
                        }
                        setSelectIds(ids as string[])
                    },
                })}
            />
            <Modal
                width={800}
                title={title}
                okText='提交'
                footer={null}
                centered
                onOk={() => {
                    setVisable(false)
                }}
                onCancel={() => {
                    form.resetFields()
                    setVisable(false)
                }}
                visible={visable}
                destroyOnClose
            >
                <Form
                    form={form}
                    name='basic'
                    {...layout}
                    autoComplete='off'
                    onFinish={(v) => {
                        dispatch(getCouponsIssueSubmit({ ...v, couponsIds, patientIds: selectIds, activityId }))
                            .then(unwrapResult)
                            .then(() => {
                                notification.success({
                                    message: '操作成功',
                                    duration: 3,
                                })
                                getPage()
                            }).finally(() => {
                                form.resetFields()
                                setCouponsIds([])
                                setVisable(false)
                                setSelectIds([])
                            })
                    }}
                >
                    <Form.Item
                        name='issueType'
                        label='发放方式'
                        rules={[{ required: true }]}
                    >
                        <Radio.Group onChange={(e) => {
                            setRadioState(e.target.value)
                            setIsDisabled(false)
                            setCouponsList(couponsList.map((n: any) => {
                                return { ...n, isActive: false }
                            }))
                            setCouponsIds([])
                            form.setFieldsValue({
                                effectiveDays: '',
                                activityName: ''
                            })
                        }}>
                            <Radio value={1}>自定义优惠券</Radio>
                            <Radio value={2}>指定活动</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {radioState === 2 ? <Form.Item label='活动名称' name='activityName' rules={[{ required: true, message: '请输入活动名称' }]}>
                        <Select
                            getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                            }
                            showSearch
                            optionFilterProp='children'
                            placeholder='请选择优惠项目'
                            onChange={(v, o: any) => {
                                dispatch(getActiveSettingDetail({ id: o.key }))
                                    .then(unwrapResult)
                                    .then((res: any) => {
                                        form.setFieldsValue({
                                            effectiveDays: res.effectiveDays,
                                            couponsName: res.couponsName
                                        })
                                        setCouponsList(couponsList.map((n: any) => {
                                            if (res.couponsIds.indexOf(n.id) !== -1) {
                                                return { ...n, isActive: true }
                                            } else {
                                                return { ...n, isActive: false }
                                            }
                                        }))
                                        setCouponsIds(res.couponsIds)
                                        setActivityId(o.key)
                                        setIsDisabled(true)
                                    })
                            }}
                        >
                            {examineData.map((v: any, i) => {
                                return (
                                    <Option value={v.name} key={v.id} item={v}>
                                        {v.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item> : <></>}
                    <Form.Item label='发放原因' name='issueReason' rules={[{ required: true, message: '请输入内容' }]}>
                        <Input
                            placeholder='请输入内容'
                            allowClear
                            size='middle'
                        />
                    </Form.Item>
                    <Form.Item label='券有效天数' name='effectiveDays' rules={[{ required: true, message: '请选择优惠券领取后有效天数' }]}>
                        <Input
                            placeholder='请选择优惠券领取后有效天数'
                            allowClear
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <Form.Item label='发放数量' name='issueCount' rules={[{ required: true, message: '请输入发放数量' }]}>
                        <Input
                            placeholder='请输入发放数量'
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item label='优惠项目' name='couponsName' rules={[{ required: true, message: '请选择优惠项目' }]}>
                        <CouponsItems
                            couponsList={couponsList}
                            couponsIds={couponsIds}
                            disabled={isDisabled}
                            onClick={(v, n, i) => {
                                setCouponsIds(i)
                                setCouponsList(v)
                                form.setFieldsValue({ couponsName: n })
                            }}
                        />
                    </Form.Item>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ float: "right", marginLeft: '10px' }}
                            >
                                确定
                            </Button>
                            <Button
                                style={{ float: "right" }}
                                onClick={() => {
                                    form.resetFields()
                                    setVisable(false)
                                }}
                            >
                                取消
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}
