import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, sendAsync } from "../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../app/store";
import {
  RecipeQueryParams,
  pageRecipeQuery
} from '../../../services/recipeQuery'

export const pageRecipeQueryAsync = createAsyncThunk<
  void,
  RecipeQueryParams,
  RootThunkApi<void>
>('examine/page', async (params, api) => {
  return sendAsync(pageRecipeQuery(params), api)
})