import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  api,
  ApplicationType,
  sendAsync,
  setCredentials,
  setErrorState,
  setTemporaryCredentials,
} from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import { AccountType } from '../../models/accounttype'
import { ShopperTokenValue } from '../../models/auth'
import { ErrorCode } from '../../models/error'
import { fromJson, Tenant } from '../../models/tenant'
import {
  createAdminToken,
  createDirectLoginToken,
  CreateDirectLoginTokenParams,
  getSmsCode as apiGetSmsCode,
  faceLogin, temporaryToken,
} from '../../services/admin'
import { createWebSocket } from '../websocket/websocketService';
import {
  createToken,
  getImgListFn,
  getScanUrl as apiGetScanUrl,
  getTenantByUsername,
  getThirdPartyLoginToken as apiGetThirdPartyLoginToken,
  getThirdPartyLoginUrl as apiGetThirdPartyLoginUrl,
  queryScanStatus as apiQueryScanStatus,
  replaceToken,
  ScanQueryParams,
  ThirdPartyLoginParams,
  ThirdPartyLoginTokenParams,
  unbindAccount as apiUnbindAccount,
} from '../../services/auth'
import axios from "axios";
import {TextPlain} from "../../services/request";
import {getTimeOut} from "../../utils/MedicalUtils";

interface LoginState {
  tenants: Tenant[]
  smsCountdown: number
}

const LOGIN_SMS_COUNTDOWN = 'login_sms_countdown'

const initialState: LoginState = {
  tenants: [],
  smsCountdown: parseInt(localStorage.getItem(LOGIN_SMS_COUNTDOWN) || '0'),
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setTenants: (state, action: PayloadAction<Array<Tenant>>) => {
      state.tenants = action.payload
    },
    setSmsCountdown: (state, action: PayloadAction<number>) => {
      state.smsCountdown = action.payload
      localStorage.setItem(LOGIN_SMS_COUNTDOWN, action.payload.toString())
    },
  },
})

export const { setTenants } = loginSlice.actions

export function getTenants(username: string): RootThunk {
  return api(
    getTenantByUsername(username),
    (data: Array<Record<string, never>>, dispatch) => {
      const tenants = data.map((t) => fromJson(t))
      if (tenants.length === 0) {
        dispatch(
          setErrorState({
            code: ErrorCode.ResourceNotFound,
            message: '手机号码未注册',
          })
        )
      }
      dispatch(setTenants(tenants))
    }
  )
}

export function login(params: {
  username: string
  tenantId: string
  password: string
  smsCode?: string
  tenantName: string
  rememberMe: boolean
  platform: ApplicationType
  clientSource?: string
}): RootThunk {
  const request =
    params.platform === ApplicationType.Application
      ? createToken(params.username, params.tenantId, params.password)
      : createAdminToken(params.username, params.password, params.smsCode)
  return api(
    request,
    (data: any, dispatch) => {
      const token = data.accessToken as string
      dispatch(
        setCredentials({
          creds: {
            ...data,
            applicationType: params.platform,
            id: data.userId?.toString(),
            name: data.userName,
            departmentId: data.outpatientDepartmentId?.toString(),
            tenantName: params.tenantName,
            shortName: data.shortName || '',
            token,
            endTime: data.endTime,
            stationCategory: data.stationCategory,
            serverTimeStamp: data?.timestamp,
            dzfpSupplierId: data?.supplierId
          },
          rememberMe: params.rememberMe,
        })
      )
      const diff = (data?.timestamp || (new Date().getTime()))  - (new Date().getTime());
      localStorage.setItem("timeDiff", diff.toString());
      if(params?.clientSource && params?.clientSource == "1"){
        axios({
          url: 'http://127.0.0.1:9956',
          proxy: {
            host: 'http://127.0.0.1',
            port: 9956,
          },
          // responseType: 'text',
          headers: {
            'Content-Type': TextPlain,
          },
          withCredentials: false,
          method: 'POST',
          data: JSON.stringify({
            username: params.username,
            tenantId: params.tenantId,
            password: params.password,
            rememberMe: params.rememberMe
          }),
        })
      }
    },
    (e, dispatch): boolean => {
      const error = {
        code:
          e.code === 400
            ? ErrorCode.WrongUsernameOrPassword
            : ErrorCode.Unknown,
        message: e.message,
      }
      dispatch(setErrorState(error))
      return true
    }
  )
}

export const directLogin = createAsyncThunk<
  void,
  CreateDirectLoginTokenParams,
  RootThunkApi
>('login/directLoginStatus', async (params, api) => {
  return sendAsync(createDirectLoginToken(params), api).then((data) => {
    const token = data.accessToken as string
    api.dispatch(
      setTemporaryCredentials({
        ...data,
        applicationType: ApplicationType.Application,
        id: data.userId?.toString(),
        name: data.userName,
        departmentId: data.outpatientDepartmentId?.toString(),
        tenantName: data.tenantName || '',
        shortName: data.shortName || '',
        token,
        stationCategory: data.stationCategory,
      })
    )
  })
})

export const getSmsCode = createAsyncThunk<void, string, RootThunkApi>(
  'login/getSmsCode',
  async (params, api) => {
    return sendAsync(apiGetSmsCode(params), api)
  }
)

// 人脸识别
export const getFaceLogin = createAsyncThunk<void, string, RootThunkApi>(
  'login/getFaceLogin',
  async (params, api) => {
    return sendAsync(faceLogin(params), api)
  }
)

export const getTemporaryToken = createAsyncThunk<void, string, RootThunkApi>(
  'login/getTemporaryToken',
  async (params, api) => {
    return sendAsync(temporaryToken(), api)
  }
)

export const getThirdPartyLoginUrl = createAsyncThunk<
  string,
  ThirdPartyLoginParams,
  RootThunkApi
>('login/getThirdPartyLoginUrl', async (params, api) => {
  return sendAsync(apiGetThirdPartyLoginUrl(params), api)
})

export const getThirdPartyLoginToken = createAsyncThunk<
  void,
  ThirdPartyLoginTokenParams,
  RootThunkApi
>('login/getThirdPartyLoginToken', async (params, api) => {
  return sendAsync(apiGetThirdPartyLoginToken(params), api).then((data) => {
    if (params.isLogin) {
      const token = data.accessToken as string
      api.dispatch(
        setCredentials({
          creds: {
            ...data,
            applicationType: ApplicationType.Application,
            id: data.userId?.toString(),
            name: data.userName,
            departmentId: data.outpatientDepartmentId?.toString(),
            tenantName: data.tenantName || '',
            shortName: data.shortName || '',
            token,
            endTime: data.endTime,
            stationCategory: data.stationCategory,
          },
          rememberMe: true,
        })
      )
    }
  })
})

export const unbindAccount = createAsyncThunk<void, AccountType, RootThunkApi>(
  'login/unbindAccount',
  async (params, api) => {
    sendAsync(apiUnbindAccount(params), api)
  }
)

export const getScanUrl = createAsyncThunk<string, void, RootThunkApi>(
  'login/getScanUrl',
  async (_params, api) => {
    return sendAsync(apiGetScanUrl(), api)
  }
)
// 获取轮播图
// export const getImgList = createAsyncThunk<void, void, RootThunkApi>(
//   'login/replace',
//   async (_, api) => {
//     return sendAsync(getImgListFn(), api)
//   }
// )
export const getImgList = createAsyncThunk<string, void, RootThunkApi>(
  'login/getImgList',
  async (_params, api) => {
    return sendAsync(getImgListFn(), api)
  }
)

export const replaceAccessToken = createAsyncThunk<
  ShopperTokenValue,
  void,
  RootThunkApi
>('login/replace', async (_, api) => {
  return sendAsync(replaceToken(), api)
})



export const queryScanStatus = createAsyncThunk<
  number,
  ScanQueryParams,
  RootThunkApi
>('login/queryScanStatus', async (params, api) => {
  return sendAsync(apiQueryScanStatus(params), api).then((data) => {
    if (data.status === 2) {
      const auth = data.authInfoDTO
      api.dispatch(
        setCredentials({
          creds: {
            ...data,
            applicationType: ApplicationType.Application,
            id: auth.userId?.toString(),
            name: auth.userName,
            departmentId: auth.outpatientDepartmentId?.toString(),
            tenantName: auth.tenantName || '',
            shortName: auth.shortName || '',
            token: auth.accessToken,
            endTime: auth.endTime,
            stationCategory: auth.stationCategory,
          },
          rememberMe: true,
        })
      )
    }
    return data.status
  })
})

export const { setSmsCountdown } = loginSlice.actions

export const selectTenants = (state: RootState): Tenant[] => state.login.tenants

export const selectSmsCountdown = (state: RootState): number =>
  state.login.smsCountdown

export default loginSlice.reducer
