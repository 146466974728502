import {
  CaretDownFilled,
  CaretUpFilled,
  SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  ModalProps,
  notification,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
} from 'antd'
import moment from 'moment'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { EditableList } from '../../../../compnents/list/EditableList'
import { ThemeContext } from '../../../../theme/ThemeContext'
import {
  pageDurgsystem,
  selectDurgsystemData,
} from '../../../durgsystem/durgsystemSlice'
import styles from './stockMaintenance.module.css'
import {
  getOneKeyStockMaintenanceList,
  getOneKeyStockMaintenanceSubmit,
} from './stockMaintenanceSlice'

export const OneKeyStockMaintenanceModal = (props: {
  visible?: any
  onOK: () => void
  onCancel: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const { Option } = Select

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const data: any = useSelector(selectDurgsystemData)

  const [pageList, setPageList] = useState<any>({
    total: 1,
    items: [],
  })
  const [loading, setLoading] = useState(false)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  useEffect(() => {
    if (props?.visible) {
      dispatch(pageDurgsystem(1, 100, 1))
      setParams({
        current: 1,
        size: 10,
      })
    }
  }, [props?.visible])

  const page = () => {
    setLoading(true)
    dispatch(getOneKeyStockMaintenanceList({ ...params ,type:1}))
      .then(unwrapResult)
      .then((v: any) => {
        setPageList({ total: v?.total, items: v?.records })
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (params?.maintainCategory && params?.storehouseId) {
      page()
    }
  }, [params])

  return (
    <Modal
      visible={props?.visible}
      onOk={() => {
        Modal.confirm({
          width: 450,
          centered: true,
          title: '提示',
          content: (
            <>
              <div>
                该操作只会生成养护天数剩余7天的药品数据,是否将养护等级“
                {params?.maintainCategory == 1 ? '重点养护' : '一般养护'}
                “的药品生成养护信息？
              </div>
              <div>（根据药品数量会有卡顿，请勿重复操作！）</div>
            </>
          ),
          onOk: () => {
            dispatch(
              getOneKeyStockMaintenanceSubmit({
                maintainItems: pageList?.items,
                maintainCategory: params?.maintainCategory,
                storehouseName: data?.find(
                  (i: any) => i?.id == params?.storehouseId
                )?.name,
                storehouseId: params?.storehouseId,
              })
            )
              .then(unwrapResult)
              .then((v) => {
                notification.success({
                  message: '操作成功',
                })
                props?.onOK()
                form.resetFields()
              })
          },
          onCancel: () => {
            form.resetFields()

          },
        })
        // props?.onOK()
      }}
      onCancel={() => {
        props?.onCancel()
         form.resetFields()
      }}
      title='一键养护'
      width={1200}
      style={{ overflow: 'hidden', padding: '0' }}
      bodyStyle={{ padding: '10px 15px 0 15px' }}
    >
      <Col
        style={{
          height: '36rem',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          onFinish={(values) => {
            setParams({ ...params, ...values })
          }}
        >
          <Row justify='space-between'>
            <Space>
              <Form.Item name='storehouseId' style={{ width: '150px' }}>
                <Select
                  allowClear
                  placeholder='库房列表'
                  dropdownClassName={styles.select}
                  showSearch
                  onChange={() => {
                    form.submit()
                  }}
                >
                  {data.map((v: any, i: any) => {
                    return (
                      <Option value={v.id} key={v.id}>
                        {v.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item name='maintainCategory' style={{ width: '120px' }}>
                <Select
                  placeholder='养护等级'
                  allowClear
                  onChange={() => {
                    form.submit()
                  }}
                >
                  <Option value={'0'}>一般养护</Option>
                  <Option value={'1'}>重点养护</Option>
                </Select>
              </Form.Item>
            </Space>
          </Row>
        </Form>
        {form.getFieldValue('storehouseId') &&
        form.getFieldValue('maintainCategory') ? (
          <Col flex={1} style={{ overflow: 'auto' }}>
            <EditableList
              scroll={{ y: 400 }}
              rowKey={(v: any, i) => v.id}
              style={{ marginBottom: '10px', height: '450px' }}
              size='small'
              loading={loading}
              onChangePage={(current, size) =>
                setParams({
                  ...params,
                  current,
                  size: size || 10,
                })
              }
              page={{
                current: params?.current,
                size: params?.size,
                total: pageList?.total,
                items: pageList?.items,
              }}
              columns={[
                {
                  title: '序号',
                  key: 'no',
                  align: 'center',
                  width: '3rem',
                  render: (_1, _2, index) =>
                    `${params.size * (params.current - 1) + index + 1}`,
                },
                {
                  title: '药品信息',
                  fixed: 'left',
                  render: function Element(_, record: any) {
                    return (
                      <div style={{ height: '55px' }}>
                        <p
                          style={{
                            height: '28px',
                            lineHeight: '36px',
                            padding: '0',
                            margin: '0',
                            fontSize: '16px',
                            fontWeight: 'bolder',
                          }}
                        >
                          {record.mnemonicName}
                        </p>
                        <p
                          style={{
                            height: '28px',
                            lineHeight: '24px',
                            padding: '0',
                            margin: '0',
                            color: '#999999',
                            fontSize: '14px',
                          }}
                        >
                          {record.lastTimeMaintain} /{' '}
                          {record.tenantSupplierName} / {record.spec} /
                          {record.doseCategory}
                        </p>
                      </div>
                    )
                  },
                },
                {
                  title: '批次',
                  dataIndex: 'batchNo',
                  align: 'center',
                  width: '8rem',
                  ellipsis: true,
                },
                {
                  title: '库存数量',
                  dataIndex: 'inventoryCount',
                  align: 'center',
                  width: '8rem',
                  ellipsis: true,
                },
                {
                  title: '上次养护时间',
                  dataIndex: 'lastTimeMaintain',
                  align: 'center',
                  width: '12rem',
                  ellipsis: true,
                },
                {
                  title: '剩余天数',
                  dataIndex: 'remainingMaintainDays',
                  align: 'center',
                  width: '8rem',
                  ellipsis: true,
                  render: (_, t) => {
                    return t.remainingMaintainDays
                  },
                },
              ]}
            />
          </Col>
        ) : (
          ''
        )}
      </Col>
    </Modal>
  )
}
