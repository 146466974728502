/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-15 14:11:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-31 14:57:40
 */
/* eslint-disable react/display-name */
import { Button, Popconfirm, Space, TableColumnType } from 'antd'
import React, { ReactElement } from 'react'
import { getInvoiceStatus, getPayFlagName } from '../../../models/payment'
import { getGenderName } from '../../../models/user'
import { Dimen } from '../../../models/dimen'
import { getAge } from '../../../utils/StringUtils'

export const getCardType = (cardType: string) => {
  switch (cardType) {
    case '1':
      return '电子凭证'
    case '2':
      return '身份证'
    case '3':
      return '医保卡'
    default:
      return ''
  }
}
type Action = 'supplementary' | 'detail'

export const Columns = (
  startIndex: number,
  onAction: (action: Action, t: any) => void
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '5rem',
      fixed: 'left',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '门诊号',
      key: 'outpatientNo',
      align: 'center',
      fixed: 'left',
      width: Dimen.OutpatientNo,
      render: (_, { registration }) => registration.outpatientNo,
    },
    {
      title: '患者姓名',
      key: 'patientName',
      align: 'center',
      fixed: 'left',
      width: '8rem',
      render: (_, { treatment }) => {
        return (
          <div style={{ width: '100%', height: '100%' }}>
            <>{treatment.patientName}</>
          </div>
        )
      },
    },
    {
      title: '病历号',
      key: 'patientId',
      align: 'center',
      width: '8rem',
      dataIndex: 'patientId',
      render: (_, { registration }) => registration.patientId,
    },
    {
      title: '性别',
      key: 'patientSex',
      align: 'center',
      width: Dimen.Sex,
      render: (_, { treatment }) => getGenderName(treatment.patientSex),
    },
    {
      title: '年龄',
      key: 'patientAge',
      align: 'center',
      width: Dimen.Age,
      render: (_, { treatment }) =>
        getAge(treatment?.patientAge, treatment?.patientMonth),
    },
    {
      title: '结算险种',
      key: 'insuranceCode',
      width: '6em',
      align: 'center',
      render: (_, { registration }) => {
        return registration.insuranceCode === 'ZIFEI' ||
          !registration.insuranceCode
          ? '自费'
          : '智慧医保'
      },
    },
    {
      title: '收费标志',
      key: 'settlementCategory',
      align: 'center',
      width: '6rem',
      render: (_, { treatment }) =>
        getPayFlagName(treatment.settlementCategory),
    },
    {
      title: '诊断疾病',
      key: 'disease',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, { treatment }) => treatment?.disease,
    },
    // {
    //   title: '诊断疾病',
    //   key: 'disease',
    //   align: 'center',
    //   width: '8rem',
    //   ellipsis: true,
    //   render: (_, { treatment }) => ({
    //     props: {
    //       style: {
    //         maxWidth: '14rem',
    //         overflow: 'hidden',
    //         textOverflow: 'ellipsis',
    //       },
    //     },
    //     children: treatment.disease,
    //   }),
    // },
    {
      title: '接诊科室',
      key: 'treatmentDepartmentName',
      align: 'center',
      width: '8rem',
      render: (_, { treatment }) => treatment.treatmentDepartmentName,
    },
    {
      title: '接诊医生',
      key: 'treatmentDoctorName',
      align: 'center',
      width: '8rem',
      render: (_, { treatment }) => treatment.treatmentDoctorName,
    },
    {
      title: '欠费状态',
      key: 'accountingStatus',
      align: 'center',
      width: '6rem',
      render: (_, { accountingStatus }) =>
        accountingStatus === 0 ? '已结清' : '欠费中',
    },
    {
      title: '挂号时间',
      key: 'registrationTime',
      align: 'center',
      width: '10rem',
      render: (_, { registration }) => registration.registrationTime,
    },
    {
      title: '欠费金额',
      key: 'arrearageAmount',
      align: 'right',
      width: '6rem',
      render: (_, { arrearageAmount }) =>
        arrearageAmount === undefined ? '' : arrearageAmount?.toFixed(2),
    },
    {
      title: '最近补交时间',
      key: 'lastPayTime',
      align: 'center',
      width: '10rem',
      render: (_, { treatment }) => {
        return (
          <div style={{ width: '100%', height: '100%' }}>
            <>{treatment.lastPayTime}</>
          </div>
        )
      },
    },
    // {
    //   title: '现金金额',
    //   key: 'treatmentDoctorName',
    //   align: 'right',
    //   width: Dimen.Price,
    //   render: (_, { shouldPayAmount }) =>
    //     shouldPayAmount === undefined ? '' : shouldPayAmount?.toFixed(2),
    // },
    // {
    //   title: '总金额',
    //   key: 'amount',
    //   width: Dimen.Price,
    //   align: 'right',
    //   render: (_, { amount }) =>
    //     amount === undefined ? '' : amount?.toFixed(2),
    // },
    // {
    //   title: '收费时间',
    //   key: 'chargeOrRefundTime',
    //   align: 'center',
    //   width: Dimen.Time,
    //   render: (_, { treatment }) => treatment.chargeOrRefundTime,
    // },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '10rem',
      fixed: 'right',
      ellipsis: true,
      render: (_, t) => {
        return (
          <Space key={t.registration.patientId}>
            {t.accountingStatus == 1 && (
              <Button
                size='small'
                type='primary'
                onClick={() => {
                  onAction('supplementary', t)
                }}
              >
                补交
              </Button>
            )}
            <a onClick={() => onAction('detail', t)}>查看详情</a>
          </Space>
        )
      },
    },
  ]
}
