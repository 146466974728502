import { AnyARecord } from "dns";
import { piTest, toJson } from "../models/pitest";
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface pitestQueryParams {
  id?: number;
  state?: number;
  current?: number;
  size?: number;
  param?: string;
  patientId?: string;
  timeHead?: string;
  timeTail?: string;
  treatmentDepartmentId?: string;
  realname?: string,
  recipeState?: string
}

export function getPitestList(params: pitestQueryParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/skintest/list`,
    method: "GET",
    params,
  };
}
export function getPitestDetail(pitest: piTest): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/skintest/update`,
    method: "POST",
    body: { ...toJson(pitest) },
  };
}
export function getDetailList(skinTestId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/skintest/detail`,
    method: "GET",
    params: {
      skinTestId,
    },
  };
}
export function getPeopleList(size ?:any): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/list`,
    method: "GET",
    params:{size}
  };
}
export function pitestSubmit(data: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/skintest/update`,
    method: "POST",
    body: data
  };
}
