import { createAsyncThunk } from "@reduxjs/toolkit"
import _ from "lodash"
import { sendAsync } from "../../app/applicationSlice"
import { RootThunkApi } from "../../app/store"
import {
  OperaingRoomList,
  OperatingRoomDetail,
  OperatingRoomDetailParams,
  OperatingRoomParams,
  OperatingRoomSubmit,
  OperatingRoomSubmitParams,
  OperatingRoomValue,
  ToggleOreratingRoomState,
  ToggleOreratingRoomStateParams,
} from "../../services/operatingRoom"

// 列表
export const getOperaingRoomList = createAsyncThunk<
  OperatingRoomValue,
  OperatingRoomParams,
  RootThunkApi<void>
>("operatingRoom/operaingRoomList", async (params, api) => {
  return sendAsync(OperaingRoomList(params), api)
})

// 注销状态
export const getToggleOperatingRoomState = createAsyncThunk<
  void,
  ToggleOreratingRoomStateParams,
  RootThunkApi<void>
>("operatingRoom/toggleOperatingRoomState", async (params, api) => {
  return sendAsync(ToggleOreratingRoomState(params), api)
})

// 保存
export const getOperatingRoomSubmit = createAsyncThunk<
  void,
  OperatingRoomSubmitParams,
  RootThunkApi<void>
>("operatingRoom/operatingRoomSubmit", async (params, api) => {
  return sendAsync(OperatingRoomSubmit(params), api)
})

// 详情
export const getOperaingRoomDetail = createAsyncThunk<
  void,
  OperatingRoomDetailParams,
  RootThunkApi<void>
>("operatingRoom/operaingRoomDetail", async (params, api) => {
  return sendAsync(OperatingRoomDetail(params), api)
})
