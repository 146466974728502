/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 17:27:48
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-06 17:28:14
 */
export function getXianzhonglxName(type: any): string {
  switch (type) {
    case '310':
      return '职工医疗保险'
    case '390':
      return '城乡居民基本医疗保险'
    case '340':
      return '离休人员医疗保障'
    case '510':
      return '职工生育保险'
    case '391':
      return '城镇居民基本医疗保险'
    case '':
      return '全部'
    default:
      return '全部'
  }
}
