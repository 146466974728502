import { Button, Col, Modal, notification, Tabs } from "antd";
import _ from "lodash";
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { selectTwoMenus } from "../../layouts/layoutSlice";
import { PaymentList } from './paymentList'
import { RegistrationList } from './registrationList'
import styles from './treatmentLog.module.css'

const { TabPane } = Tabs;

export const RealTreatmentLog = (): ReactElement => {
  const twoMenus = useSelector(selectTwoMenus);

  const menuList = twoMenus?.filter((v) => {
    return v?.name == "门诊日志管理";
  })?.[0];

  const getKeys = (i: string) => {
    switch (i) {
      case "挂号列表":
        return "0";
      case "收费列表":
        return "1";

      default:
        return "-";
    }
  };

  const [currentTab, setCurrentTab] = useState("0");

  return (
    <>
      <Tabs
        className={styles.tabs}
        activeKey={currentTab}
        onChange={(t) => setCurrentTab(t)}
      >
        {menuList?.subMenus?.map((v: any) => {
          return (
            <>
              <TabPane key={getKeys(v?.name)} tab={v?.name}>
                {v?.name == "挂号列表" ? (
                    <PaymentList />
                ) : v?.name == "收费列表" ? (
                    <RegistrationList />
                ) : (
                  <></>
                )}
              </TabPane>
            </>
          );
        })}
      </Tabs>
    </>
  );
};

export const TreatmentLog = forwardRef(RealTreatmentLog);
