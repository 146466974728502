/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 15:46:06
 */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { api, sendAsync } from "../../../app/applicationSlice"
import _ from 'lodash'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import * as service from '../../../services/supplierDealing'
import { SupplierDealingParams, exportListExoprt } from '../../../services/supplierDealing'
interface SupplierDealing {
  SupplierDealing: []
  Total: 0
  Current: 1
  pageLoading: false
}

const initialState: SupplierDealing = {
  SupplierDealing: [],
  Total: 0,
  Current: 1,
  pageLoading: false,
}

const supplierDealingSlice = createSlice({
  name: 'supplierDealingSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.SupplierDealing = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setData,
  setTotal,
  setCurrent,
  setPageLoading,
} = supplierDealingSlice.actions

export const selectSupplierDealingData = (state: RootState) =>
  state.supplierDealing.SupplierDealing
export const selectTotal = (state: RootState) => state.supplierDealing.Total

export const selectCurrent = (state: RootState) => state.supplierDealing.Current

export const selectPageLoading = (state: RootState) =>
  state.supplierDealing.pageLoading

export function getSupplierDealingList(
  params: SupplierDealingParams
): RootThunk {
  return api(service.getSupplierDealing(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

export default supplierDealingSlice.reducer


export const getExportListExoprt = createAsyncThunk<
  void,
  any,
  RootThunkApi
  >('doctorAudit/getExportListExoprt', async (params, api) => {
  return sendAsync(exportListExoprt(params), api)
})