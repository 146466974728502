/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: K
 * @LastEditTime: 2022-10-19 17:10:39
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store";
import {
  getRecentTimeOutFn,
  getRecentTimePrintFn,
  getRecentTimePrintFnParams,
  recentTimelogoutlist,
  recentTimelogoutlistParams,
  recentTimepage,
  recentTimeParams,
} from '../../services/recentTime'
import { getPatientgroupFnPrams, getPatientgroupFn } from "../../services/toothHome";

const initialState = {
  RecentTimeData: [],
  Total: 0,
  Current: 1,
  keyword: '',
  StorehouseId: 0,
  MaterialsCategory: 0,
  DeadlineEnd: '',
  DeadlineStart: '',
  Rejection: false,
  pageLoading: false,
}

export const recentTimeSlice = createSlice({
  name: 'recentTime',
  initialState,
  reducers: {
    setData(state, action) {
      state.RecentTimeData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setRejection(state, action) {
      state.Rejection = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export function pageRecentTime(params: recentTimeParams): RootThunk {
  return api(recentTimepage(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

export const logoutlistrecentTimeAsync = createAsyncThunk<
  void,
  recentTimelogoutlistParams,
  RootThunkApi<void>
>('Putstorage/savelist', async (params, api) => {
  return sendAsync(recentTimelogoutlist(params), api)
})


// 打印
export const getRecentTimePrint = createAsyncThunk<
  void,
  getRecentTimePrintFnParams,
  RootThunkApi<void>
>('Putstorage/getRecentTimePrint', async (params, api) => {
  return sendAsync(getRecentTimePrintFn(params), api).then((source) => {
    eval(source)
    LODOP.SET_PRINTER_INDEX('${printer}')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    LODOP.PRINT()
  })
})
// 导出
export const getRecentTimeOut = createAsyncThunk<
  void,
  recentTimeParams,
  RootThunkApi<void>
>('Putstorage/getRecentTimeOut', async (params, api) => {
  return sendAsync(getRecentTimeOutFn(params), api)
})

export const {
  setData,
  setTotal,
  setCurrent,
  setRejection,
  setPageLoading
} = recentTimeSlice.actions

export const selectRecentTimeData = (state: RootState) =>
  state.recentTime.RecentTimeData

export const selectTotal = (state: RootState) => state.recentTime.Total

export const selectCurrent = (state: RootState) => state.recentTime.Current

export const selectRejection = (state: RootState) => state.recentTime.Rejection

export const selectPageLoading = (state: RootState) =>
  state.recentTime.pageLoading

export default recentTimeSlice.reducer