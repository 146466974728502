import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { notification } from 'antd'
import _ from 'lodash'
import { sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../../app/store'
import { LoadingState } from '../../../models/common'
import * as M from '../../../models/material'
import { Page, pageOf, startPageOf } from '../../../models/page'
import * as R from '../../../models/recipe'
import * as T from '../../../models/treatment'
import { Treatment } from '../../../models/treatment'
import * as SR from '../../../services/recipe'
import { RecipeListParams } from "../../../services/recipe";
import * as ST from '../../../services/treatment'

type RecipeMap = {
  [key: string]: { open: boolean; state: LoadingState; recipes: R.Recipe[] }
}

type MaterialMap = {
  [key: string]: {
    open: boolean
    state: LoadingState
    materials: M.Material[]
  }
}

interface TreatmentState {
  page: Page<Treatment>
  recipes: RecipeMap
  materials: MaterialMap
}

const initialState = {
  page: startPageOf(),
  recipes: {},
  materials: {},
} as TreatmentState

export const getTreatmentList = createAsyncThunk<
  Page<Treatment>,
  ST.GetTreatmentListParams,
  RootThunkApi<Page<Treatment>>
>(
  'treatment/getTreatmentListStatus',
  async (params: ST.GetTreatmentListParams, api) => {
    return sendAsync(ST.getTreatmentList(params), api).then((data) => {
      return pageOf(
        data,
        _.chain(data.records)
          .map((t) => T.fromJson(t))
          .value()
      )
    })
  }
)

// 选择查看历史病历
export const getTreatmentOldList = createAsyncThunk<
  Page<Treatment>,
  ST.GetTreatmentListParams,
  RootThunkApi<Page<Treatment>>
>(
  'treatment/getTreatmentListStatus',
  async (params: ST.GetTreatmentListParams, api) => {
    return sendAsync(ST.getTreatmentOldList(params), api).then((data) => {
      // if (!data.records.length) {
      //   notification.error({
      //     message: '查询不到该患者的历史病历信息',
      //   }) 
      // }
      return pageOf(
        data,
        _.chain(data.records)
          .map((t) => T.fromJson(t))
          .value()
      )
    })
  }
)

// 中医历史病历
export const getTcmTreatmentOldList = createAsyncThunk<
  Page<Treatment>,
  ST.GetTreatmentListParams,
  RootThunkApi<Page<Treatment>>
>(
  'treatment/getTreatmentListStatus',
  async (params: ST.GetTreatmentListParams, api) => {
    return sendAsync(ST.getTreatmentOldList(params), api)
  }
)
// 病历信息回显
export const getMedicalRecordsShow = createAsyncThunk<
  void,
  ST.getMedicalRecordsShowFnParams,
  RootThunkApi<Page<Treatment>>
>(
  'treatment/getMedicalRecordsShow',
  async (params: ST.getMedicalRecordsShowFnParams, api) => {
    return sendAsync(ST.getMedicalRecordsShowFn(params), api)
  }
)

export const getRecipeLists = createAsyncThunk<
  R.Recipe[],
  RecipeListParams,
  RootThunkApi<R.Recipe[]>
>('treatment/getRecipeListStatus', async (params, api) => {
  return sendAsync(SR.getRecipeLists(params), api).then((data) =>
    _.chain(data.records)
      .filter((r) => r.itemList.length > 0)
      .map((r) => R.fromJson(r))
      .filter((r) => r.category !== R.RecipeCategory.Extras)
      .value()
  )
})

export const getRecipeList = createAsyncThunk<
  R.Recipe[],
  string,
  RootThunkApi<R.Recipe[]>
>("treatment/getRecipeListStatus", async (treatmentId: string, api) => {
  return sendAsync(SR.getRecipeList(treatmentId), api).then((data) =>
    _.chain(data.records)
      .filter((r) => r.itemList.length > 0)
      .map((r) => R.fromJson(r))
      .filter((r) => r.category !== R.RecipeCategory.Extras)
      .value()
  );
});

export const getMaterialList = createAsyncThunk<
  M.Material[],
  any,
  RootThunkApi<M.Material[]>
>('treatment/getMaterialListStatus', async (params: any, api) => {
  return sendAsync(SR.getMaterials(params), api).then((data) =>
    _.chain(data.records)
      .map((m) => M.fromMaterialJson(m))
      .value()
  )
})

const treatmentSlice = createSlice({
  name: "treatment",
  initialState,
  reducers: {
    openTreatment: (
      state,
      action: PayloadAction<{ id: string; open: boolean }>
    ) => {
      const treatment = state.recipes[action.payload.id];
      if (treatment) treatment.open = action.payload.open;
    },
    openMaterial: (
      state,
      action: PayloadAction<{ id: string; open: boolean }>
    ) => {
      const recipe = state.materials[action.payload.id];
      if (recipe) recipe.open = action.payload.open;
    },
    reset: (state) => {
      state.page = startPageOf();
      state.recipes = {};
      state.materials = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTreatmentList.fulfilled, (state, action) => {
      state.page = action.payload;
    });
    builder.addCase(getRecipeList.pending, (state, action) => {
      state.recipes[action.meta.arg] = {
        open: true,
        state: LoadingState.Loading,
        recipes: [],
      };
    });
    builder.addCase(getRecipeList.fulfilled, (state, action) => {
      state.recipes[action.meta.arg].recipes = action.payload;
      state.recipes[action.meta.arg].state = LoadingState.Finished;
    });
    builder.addCase(getMaterialList.pending, (state, action) => {
      state.materials[action.meta.arg?.recipeId] = {
        open: true,
        state: LoadingState.Loading,
        materials: [],
      }
    });
    builder.addCase(getMaterialList.fulfilled, (state, action) => {
      state.materials[action.meta.arg?.recipeId].materials = action.payload
      state.materials[action.meta.arg?.recipeId].state = LoadingState.Finished
    });
  },
});

export const { openTreatment, openMaterial, reset } = treatmentSlice.actions;

export const selectTreatmentPage = (state: RootState): Page<Treatment> =>
  state.treatment.page;

export const selectRecipes = (state: RootState): RecipeMap =>
  state.treatment.recipes;

export const selectMaterials = (state: RootState): MaterialMap =>
  state.treatment.materials;

export default treatmentSlice.reducer;
