import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import React, { ReactElement, useEffect, useState } from 'react'
import styles from './excuteDetailModal.module.css'
import {
  CaretDownFilled,
  CloseOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import FileUpload from '../../../compnents/file/FileUpload'
import { DateTimeFormat } from '../../../models/datetime'
import {
  getExcuteDetailList,
  getOperationSubmit,
} from './toBeCompletedOperationSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { getGenderName, StationCategory } from '../../../models/user'
import moment from 'moment'
import { OpRecord } from '../../../services/operatingRoom'
import { getOperaingRoomList } from '../../operatingRoom/operatingRoomSlice'
import {
  getCommonCodeCompletionsAsync,
  getUsersInDepartmentAsync,
} from '../../completion/completionSlice'
import { getDepartmentList } from '../../department/departmentSlice'
import { SurgicalList } from '../../../models/commoncode'
import { surgicalProps } from '../../treatment/surgical/SurgicalFrom'
import { getUrl } from '../../treatment/oralCases/oralCasesSlice'
import { ToothComponents } from '../../treatment/editor/modals/toothComponents'
import { getRegistByPatientIdGneralAsync } from '../../A-toothModule/patient/patientSlice'
import { getAge } from '../../../utils/StringUtils'

const { Option } = Select
const { Item } = Form

export const ExcuteDetailModal = (props: {
  isModalVisible: boolean
  id: any
  mainDoctorId: any
  registrationId: any
  treatmentNo: any
  onOk: () => void
  onCancel: () => void
  toothPatient?: boolean // 口腔专业版进入
  patientId?: string
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [detail, setDetail] = useState<any>()

  const [opRoom, setOpRoom] = useState<OpRecord[]>([])

  const [surgical, setSurgical] = useState<surgicalProps>()

  const [surgicalName, setSurgicalName] = useState([])

  const [doctorList, setDoctorList] = useState<any>([])

  const [assistantList, setAssistantList] = useState<any>([])

  const [departmentList, setDepartmentList] = useState<any>([])

  const [operatingContent, setOperatingContent] = useState('')

  const [disposeSelectOptions, setDisposeSelectOptions] = useState<any[]>([])

  const [operatingToothBefore, setoperatingToothBefore] = useState('')

  const [operatingToothAfter, setoperatingToothAfter] = useState('')

  const [editoperatingToothAfter, seteditoperatingToothAfter] = useState('')

  const tips = {
    input: '请输入',
    select: '请选择',
    position: '请选择手术部位',
    level: '请选择手术等级',
    incision: '请选择手术切开',
    assistant: '请选择助手',
    duty: '请选择职责',
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const simpleLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  }

  const BatLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  }
  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    if (props.isModalVisible) {
      SurgicalList.forEach((v) => {
        dispatch(getCommonCodeCompletionsAsync({ category: v, keyword: '' }))
          .then(unwrapResult)
          .then((res) => {
            setSurgical((i: any) => {
              return { ...i, [v]: res.records }
            })
          })
      })
      dispatch(getOperaingRoomList({ current: 1, size: 1000 }))
        .then(unwrapResult)
        .then((res) => {
          setOpRoom(res.records.filter((v) => v.state === 1))
        })
      dispatch(
        getUsersInDepartmentAsync({
          departmentId: '',
          stationCategory: StationCategory.DoctorOrPharmacist,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setDoctorList(res)
        })
      dispatch(getDepartmentList({ current: 1, size: 1000, status: 1 }))
        .then(unwrapResult)
        .then((res) => {
          setDepartmentList(res.items)
        })
    }
  }, [props.isModalVisible])

  useEffect(() => {
    props.patientId &&
      dispatch(
        getRegistByPatientIdGneralAsync({
          patientId: props.patientId,
          size: 30,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setDisposeSelectOptions(res)
        })
  }, [props.patientId])

  useEffect(() => {
    if (JSON.stringify(detail) !== '{}') {
      detail?.coherentVideo &&
        setFileList(
          detail?.coherentVideo.split(',').map((v: any) => {
            return ({
              name: v.split('@')[0],
              uid: v.split('@')[1],
              url: v.split('@')[2],
            } as unknown) as UploadFile
          })
        )
      const operatingContentList =
        detail?.operatingContent[0] === '['
          ? JSON.parse(detail?.operatingContent)
          : [{}]
      const helperList =
        detail?.helper[0] === '[' ? JSON.parse(detail?.helper) : [{}]

      setoperatingToothBefore(detail?.operatingToothBefore)

      setoperatingToothAfter(detail?.operatingToothAfter)

      form.setFieldsValue({
        ...detail,
        appointmentTime: detail?.appointmentTime
          ? moment(detail?.appointmentTime)
          : undefined,
        appointmentDuration: detail?.appointmentDuration,
        operatingRoomId:
          Number(detail?.operatingRoomId) > 0
            ? detail?.operatingRoomId
            : undefined,
        treatmentDepartmentId:
          Number(detail?.treatmentDepartmentId) > 0
            ? detail?.treatmentDepartmentId.toString()
            : undefined,
        mainDoctorId: detail?.mainDoctorId.toString(),
        anesthesiaDoctorId:
          detail?.anesthesiaDoctorId > 0
            ? detail?.anesthesiaDoctorId.toString()
            : null,
        operatingContent: operatingContentList,
        helper: helperList,
      })
    }
  }, [detail])

  useEffect(() => {
    if (doctorList) {
      dispatch(
        getUsersInDepartmentAsync({
          departmentId: '',
          stationCategory: StationCategory.DoctorOrPharmacist,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setAssistantList(doctorList.concat(res))
        })
    }
  }, [doctorList])

  useEffect(() => {
    if (props.isModalVisible)
      dispatch(getExcuteDetailList({ id: props?.id }))
        .then(unwrapResult)
        .then((res: any) => {
          const operatingContentList =
            res.operatingContent[0] === '['
              ? JSON.parse(res.operatingContent)
              : res.operatingContent
          setDetail(res)
          setOperatingContent(operatingContentList[0]?.surgIcalName || '')
        })
  }, [props.isModalVisible])

  return (
    <Modal
      width={1050}
      title='执行(术后记录)'
      visible={props.isModalVisible}
      footer={null}
      bodyStyle={{
        height: 700,
        overflow: 'hidden',
      }}
      onCancel={() => {
        props.onCancel()
      }}
      onOk={() => {
        props?.onOk()
      }}
      destroyOnClose
      style={{ position: 'relative' }}
    >
      <Row style={{height: '650px', overflow: 'scroll'}}>
        <Row
          style={{
            lineHeight: '30px',
            marginBottom: '20px',
            paddingLeft: '100px',
            fontSize: '14px',
          }}
        >
          {!props.toothPatient && (
            <>
              <Col span={8}>患者姓名：{detail?.patientName}</Col>
              <Col span={8}>性别：{getGenderName(detail?.patientSex)}</Col>
              <Col span={8}>
                年龄：
                {getAge(detail?.patientAge, detail?.patientMonth)}
              </Col>
              <Col span={8}>手术名称：{operatingContent}</Col>
            </>
          )}
          <Col span={8}>开单人：{detail?.createUserName}</Col>
          <Col span={8}>
            开单时间：
            {detail?.createTime
              ? moment(detail?.createTime).format(DateTimeFormat)
              : '-'}
          </Col>
        </Row>
        <Form
          name='dynamic_form_nest_item'
          style={{
            height: 'calc(100% - 130px)',
          }}
          {...layout}
          form={form}
          initialValues={{ remember: true }}
          autoComplete='off'
          onFinish={(values) => {
            const mainDoctorName =
              (doctorList.find((u: any) => u.id === values.mainDoctorId) as any)
                ?.name || detail.mainDoctorName
            const anesthesiaDoctorName =
              (doctorList.find(
                (u: any) => u.id === values.anesthesiaDoctorId
              ) as any)?.name || ''
            const operatingRoomName =
              (opRoom.find((u: any) => u.id === values.operatingRoomId) as any)
                ?.name || ''
            const treatmentDepartmentName =
              (departmentList.find(
                (u: any) => u.id === values.treatmentDepartmentId
              ) as any)?.name || ''

            dispatch(
              getOperationSubmit({
                ...detail,
                ...values,
                appointmentTime: moment(values.appointmentTime).format(
                  DateTimeFormat
                ),
                operatingContent: JSON.stringify(values.operatingContent),
                helper: JSON.stringify(
                  values.helper.map((v: any) => {
                    if (v.assistant || v.zz) {
                      return v
                    }
                  })
                ),
                id: props?.id,
                registrationId: props?.registrationId || detail?.registrationId,
                anesthesiaDoctorName,
                mainDoctorName,
                operatingRoomName,
                treatmentDepartmentName,
                coherentVideo:
                  fileList &&
                  fileList
                    .map((v) => `${v.name}@${v.uid}@`.concat(v.url || ''))
                    .join(','),
                treatmentNo: detail?.treatmentNo || props?.treatmentNo,
                operatingToothBefore,
                operatingToothAfter:
                  editoperatingToothAfter || operatingToothAfter,
              })
            )
              .then(unwrapResult)
              .then(() => {
                props?.onOk()
                form.resetFields()
                notification.success({
                  message: '执行成功',
                })
              })
              .catch(() => {
                props?.onCancel()
                form.resetFields()
                notification.error({
                  message: '执行失败',
                })
              })
          }}
        >
          <Row
            style={{
              paddingRight: '60px',
              height: '100%',
              overflow: 'auto',
            }}
          >
            {props.toothPatient && (
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <Form.Item label='就诊信息' name='registrationId'>
                      <Select
                        placeholder={tips.select}
                        suffixIcon={<CaretDownFilled />}
                        allowClear
                        showSearch
                        filterOption={(input, option: any) => {
                          return (option?.children ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }}
                        disabled
                      >
                        {disposeSelectOptions.length != 0 &&
                          disposeSelectOptions.map((v: any) => (
                            <Option
                              key={v.registrationId}
                              value={v.registrationId}
                            >
                              {`${v.registrationTime} ${v.treatmentDoctorName} ${v.insuranceCode == 'ZIFEI'
                                ? '自费'
                                : v.insuranceCode == '' ||
                                  v.insuranceCode == null
                                  ? ' 未知险种'
                                  : '医保刷卡'
                                } (${v.treatmentDepartmentName}) ${v.disease}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={12} style={{ height: '40px' }}>
              <Form.Item label='预约手术时间' name='appointmentTime'>
                <DatePicker
                  // onChange={onChange}
                  style={{ width: '100%' }}
                  format={DateTimeFormat}
                  showTime
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{ height: '40px' }}>
              <Form.Item label='手术时长' name='appointmentDuration'>
                <Input suffix='分钟' placeholder={tips.input} />
              </Form.Item>
            </Col>
            <Col span={12} style={{ height: '40px' }}>
              <Form.Item name='operatingRoomId' label='手术室：'>
                <Select
                  placeholder={tips.select + '手术室'}
                  suffixIcon={<CaretDownFilled />}
                  allowClear
                >
                  {opRoom.map((v) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} style={{ height: '40px' }}>
              <Form.Item name='treatmentDepartmentId' label='手术科室：'>
                <Select placeholder={tips.select + '手术科室'} allowClear>
                  {departmentList.map((v: any) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {props.toothPatient ? (
              <>
                <Col span={24} style={{ height: '40px' }}>
                  <Form.Item {...BatLayout} label='术前诊断'>
                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: 'calc(20% - 8px)',
                      }}
                    >
                      <ToothComponents
                        data={operatingToothBefore}
                        isEdit={false}
                      />
                    </Form.Item>
                    <Form.Item
                      name='operatingDiagnoseBefore'
                      style={{
                        display: 'inline-block',
                        width: 'calc(80% - 8px)',
                      }}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ height: '40px' }}>
                  <Form.Item {...BatLayout} label='术后诊断'>
                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: 'calc(20% - 8px)',
                      }}
                    >
                      <ToothComponents
                        data={operatingToothAfter}
                        isShowSave={false}
                        onChange={(v) => {
                          seteditoperatingToothAfter(
                            v.map((el: any) => (el ? el : '--')).join('@')
                          )
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name='operatingDiagnoseAfter'
                      style={{
                        display: 'inline-block',
                        width: 'calc(80% - 8px)',
                      }}
                    >
                      <Input />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </>
            ) : (
              <Col span={24} style={{ height: '40px' }}>
                <Form.Item
                  {...BatLayout}
                  label='手术诊断'
                  name='operatingDiagnoseBefore'
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.List name='operatingContent'>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row key={field.key} style={{ height: '40px' }}>
                        <Col span={12}>
                          <Item
                            label='手术名称'
                            {...layout}
                            name={[field.name, 'surgIcalName']}
                          >
                            <Input
                              placeholder={tips.input + '手术名称'}
                              disabled
                            ></Input>
                          </Item>
                        </Col>
                        <Col span={12}>
                          <Row
                            gutter={10}
                            style={{ paddingLeft: '10px', position: 'relative' }}
                          >
                            {!props.toothPatient && (
                              <Col span={8}>
                                <Item
                                  label={''}
                                  noStyle
                                  name={[field.name, 'bw']}
                                >
                                  <Select
                                    placeholder={tips.position}
                                    suffixIcon={<CaretDownFilled />}
                                    style={{ width: '100%' }}
                                    disabled
                                  >
                                    {surgical?.SSBW &&
                                      surgical?.SSBW.map((v: any) => (
                                        <Option key={v.id} value={v.codeName}>
                                          {v.codeName}
                                        </Option>
                                      ))}
                                  </Select>
                                </Item>
                              </Col>
                            )}
                            <Col span={8}>
                              <Item label={''} noStyle name={[field.name, 'dj']}>
                                <Select
                                  placeholder={tips.level}
                                  suffixIcon={<CaretDownFilled />}
                                  style={{ width: '100%' }}
                                  disabled
                                >
                                  {surgical?.SSDJ &&
                                    surgical?.SSDJ.map((v: any) => (
                                      <Option key={v.id} value={v.codeName}>
                                        {v.codeName}
                                      </Option>
                                    ))}
                                </Select>
                              </Item>
                            </Col>
                            <Col span={8}>
                              <Item label={''} noStyle name={[field.name, 'qk']}>
                                <Select
                                  placeholder={tips.incision}
                                  suffixIcon={<CaretDownFilled />}
                                  style={{ width: '100%' }}
                                  disabled
                                >
                                  {surgical?.SSQK &&
                                    surgical?.SSQK.map((v: any) => (
                                      <Option key={v.id} value={v.codeName}>
                                        {v.codeName}
                                      </Option>
                                    ))}
                                </Select>
                              </Item>
                            </Col>
                            {/* {fields.length > 1 &&
                          (fields.length !== index + 1 || fields.length == 2) &&
                          index !== 0 ? (
                            <MinusCircleOutlined
                              className={styles.moveBtn}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null}
                          {(index + 1 === fields.length &&
                            fields.length !== 2) ||
                          index === 0 ? (
                            <PlusCircleOutlined
                              className={styles.moveBtn}
                              onClick={() => {
                                if (fields.length < 5) {
                                  add();
                                }
                              }}
                            />
                          ) : null} */}
                          </Row>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>

            <Col span={12} style={{ height: '40px' }}>
              <Form.Item label='麻醉方式' name='anesthesiaMethod'>
                <Select
                  placeholder={tips.select + '手术科室'}
                  allowClear
                  disabled
                >
                  {surgical?.MZFS &&
                    surgical?.MZFS.map((v: any) => (
                      <Option key={v.id} value={v.codeName}>
                        {v.codeName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} style={{ height: '40px' }}>
              <Form.Item label='体位' name='position'>
                <Select
                  placeholder={tips.select + '手术科室'}
                  allowClear
                  disabled
                >
                  {surgical?.TW &&
                    surgical?.TW.map((v: any) => (
                      <Option key={v.id} value={v.codeName}>
                        {v.codeName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} style={{ height: '40px' }}>
              <Item label='相关影像' {...BatLayout} name='coherentVideo'>
                <FileUpload
                  // disabled={true}
                  count={(fileList && fileList.length) || 0}
                  files={fileList}
                  onUpload={(v, i) => {
                    setFileList([
                      ...fileList,
                      {
                        uid: v.uid,
                        size: v.size,
                        name: v.name,
                        type: v.type,
                        originFileObj: {} as RcFile,
                        url: i,
                      },
                    ])
                  }}
                >
                  <Button icon={<PlusOutlined />}>上传附件</Button>
                </FileUpload>
                {fileList &&
                  fileList.map((item) => (
                    <Row
                      gutter={24}
                      key={item.uid}
                      style={{
                        padding: '10px',
                        width: '300px',
                      }}
                    >
                      <Col flex={1}>
                        <span
                          style={{
                            display: 'inline-block',
                            maxWidth: '60px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            verticalAlign: 'middle',
                          }}
                        >
                          {item.name.split('.')[0]}
                        </span>
                        <span
                          style={{
                            verticalAlign: 'middle',
                          }}
                        >
                          .{item.name.split('.')[1]}
                        </span>
                      </Col>
                      <Col span={6}>
                        <a
                          onClick={() => {
                            dispatch(getUrl({ fileName: item.url }))
                              .then(unwrapResult)
                              .then((v: any) => {
                                window.open(v, '_blank')
                              })
                          }}
                        >
                          查看
                        </a>
                      </Col>
                      <Col span={6}>
                        <CloseOutlined
                          onClick={() => {
                            //  e.stopPropagation();
                            setFileList(() =>
                              fileList.filter((ite) => ite.uid !== item.uid)
                            )
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
              </Item>
            </Col>
            <Col span={12} style={{ height: '40px' }}>
              <Form.Item name='mainDoctorId' label='主刀医生：'>
                <Select
                  placeholder={tips.select + '主刀医生'}
                  allowClear
                  onChange={(key) => {
                    return
                  }}
                >
                  {doctorList.map((v: any) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} style={{ height: '40px' }}>
              <Form.Item name='anesthesiaDoctorId' label='麻醉医生：'>
                <Select placeholder={tips.select + '麻醉医生'} allowClear>
                  {doctorList.map((v: any) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.List name='helper'>
                {(fields, { add, remove }) => (
                  <Row wrap style={{ height: '40px' }}>
                    {fields.map((field, index) => (
                      <Col span={12} key={field.key}>
                        <Row gutter={10}>
                          <Col span={16}>
                            <Item
                              label='助手'
                              {...simpleLayout}
                              name={[field.name, 'assistant']}
                            >
                              <Select
                                placeholder={tips.select}
                                suffixIcon={<CaretDownFilled />}
                                style={{ width: '100%' }}
                              >
                                {assistantList.map((v: any) => (
                                  <Option key={v.id} value={v.name}>
                                    {v.name}
                                  </Option>
                                ))}
                              </Select>
                            </Item>
                          </Col>
                          <Col span={8}>
                            <Item label={''} noStyle name={[field.name, 'zz']}>
                              <Select
                                placeholder={tips.duty}
                                suffixIcon={<CaretDownFilled />}
                                style={{ width: '100%' }}
                              >
                                {surgical?.ZZ &&
                                  surgical?.ZZ.map((v: any) => (
                                    <Option key={v.id} value={v.codeName}>
                                      {v.codeName}
                                    </Option>
                                  ))}
                              </Select>
                            </Item>
                            {index !== 0 ? (
                              <MinusCircleOutlined
                                className={styles.moveBtn}
                                onClick={() => {
                                  remove(field.name)
                                }}
                              />
                            ) : null}
                            {index === 0 ? (
                              <PlusCircleOutlined
                                className={styles.moveBtn}
                                onClick={() => {
                                  if (fields.length < 5) {
                                    add()
                                  }
                                }}
                              />
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                )}
              </Form.List>
            </Col>
            <Col span={24}>
              <Form.Item name='remark' label='备注' {...BatLayout} >
                <Input.TextArea placeholder={tips.input} />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={20}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              margin: ' 20px 40px 0 0 ',
              position: 'absolute',
              bottom: '0px',
              padding: '10px 0',
              left: '0',
              backgroundColor: '#fff'
            }}
          >
            <Col>
              <Button
                htmlType='button'
                onClick={() => {
                  props.onCancel()
                  form.resetFields()
                }}
                style={{
                  float: 'right',
                }}
              >
                取消
              </Button>
            </Col>
            <Col>
              <Button type='primary' htmlType='submit' style={{ float: 'left' }}>
                确认
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </Modal>
  )
}
