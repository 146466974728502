import {
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { EditableList } from '../../../../compnents/list/EditableList'
import { traceRoute } from '../../../../layouts/layoutSlice'
import { ThemeContext } from '../../../../theme/ThemeContext'
import {
  getDepartments,
  selectDepartments,
} from '../../../payment/list/paymentSlice'
import { columns } from './columns'
import {
  getDoctorManagementList,
  getDoctorManagementUpload,
} from './doctorManagementSlice'
import { EditDetailModal } from './editDetailModal'

export interface DoctorManagementRef {
  refresh?: () => void
  tabKey?: any
}

interface DoctorManagementProps {
  tabKey?: any
}
const RealDoctorManagements = (
  { tabKey }: DoctorManagementProps,
  ref: React.Ref<DoctorManagementRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const departments = useSelector(selectDepartments)

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [isEditRemarkVisible, setIsEditRemarkVisible] = useState(false)

  const [id, setId] = useState<string>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({ items: [], total: 0 })

  useEffect(() => {
    dispatch(getDepartments())
  }, [dispatch])

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getDoctorManagementList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (tabKey == 2) {
      getPage()
    }
  }, [params, tabKey])

  return (
    <Col
      style={{
        height: 'calc(100% - 30px)',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '0px 15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          onFinish={(values) => {
            setParams({ ...params, ...values, current: 1 })
          }}
        >
          <Row
            align='middle'
            justify='space-between'
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            <Space>
              <Form.Item name='name' noStyle>
                <Input
                  placeholder='医生姓名/助记码'
                  style={{ width: 200 }}
                  prefix={<SearchOutlined color={theme.tc3} />}
                  allowClear
                  onChange={form.submit}
                />
              </Form.Item>
              <Form.Item noStyle name='outpatientDepartmentId'>
                <Select
                  style={{ width: '10rem' }}
                  placeholder='科室'
                  allowClear
                  onChange={form.submit}
                >
                  {departments.map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name='flag' noStyle>
                <Select
                  placeholder='状态'
                  onChange={form.submit}
                  allowClear
                  style={{ width: 140 }}
                >
                  <Select.Option value={0}>不展示</Select.Option>
                  <Select.Option value={1}>展示</Select.Option>
                </Select>
              </Form.Item>
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
            <div style={{ color: 'gray' }}>
              提示：您的排班设置和预约设置会自动同步到公众号中。
            </div>
          </Row>
        </Form>
        <EditableList
          page={{
            items: page.items,
            current: params.current,
            size: params.size,
            total: page.total,
          }}
          loading={pageLoading}
          columns={columns((action: any, v: any, e: any) => {
            switch (action) {
              case 'edit':
                setIsEditRemarkVisible(true)
                setId(v.id)
                break
              case 'stateEdit':
                dispatch(
                  getDoctorManagementUpload({
                    wechatShowFlg: Number(e),
                    id: v.id,
                  })
                )
                  .then(unwrapResult)
                  .then((v) => {
                    notification.success({
                      message: '操作成功',
                      duration: 3,
                    })
                    getPage()
                  })

                break
            }
          }, params.size * (params.current - 1))}
          onChangePage={(current, size) => {
            setParams({ ...params, current, size: size || 10 })
          }}
        />
      </Col>
      <EditDetailModal
        id={id}
        visible={isEditRemarkVisible}
        onCancel={() => {
          setIsEditRemarkVisible(false)
          setId('')
          getPage()
        }}
        onOk={() => {
          setIsEditRemarkVisible(false)
          setId('')
          getPage()
        }}
      />
    </Col>
  )
}

export const DoctorManagements = forwardRef(RealDoctorManagements)
