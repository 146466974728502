/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-27 15:25:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-12 10:53:15
 */
import { Button, Space, TableColumnType, Tag, Tooltip } from 'antd'
import React, { ReactElement } from 'react'
import { getTypesName, getPayTypesName } from '../../models/membersPage'
import styles from './MembersManagementPage.module.css'

export const Columns = (
  onClick: (text: string, obj: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '患者姓名',
      dataIndex: 'memberName',
      align: 'center',
      key: 'memberName',
    },
    {
      title: '手机号',
      dataIndex: 'memberPhone',
      align: 'center',
      key: 'memberPhone',
    },
    {
      title: '标签',
      dataIndex: 'name',
      align: 'center',
      key: '',
      render: function element(_, t) {
        return (
          <Space>
            {t.labelList.map((item: any) => {
              return (
                <Tooltip key={item.id} placement='top' title={item.name}>
                  <Tag
                    className={styles.iconStyle}
                    color={item.backgroundColor}
                    style={{ color: `${item.fontColor}` }}
                  >
                    {item.headName}
                  </Tag>
                </Tooltip>
              )
            })}
          </Space>
        )
      },
    },
    {
      title: '当前余额',
      dataIndex: 'balanceMoney',
      align: 'center',
      key: 'balanceMoney',
      render: (_, r) => r.balanceMoney?.toFixed(2),
    },
    {
      title: '开卡时间',
      dataIndex: 'createTime',
      align: 'center',
      key: 'createTime',
    },
    {
      title: '操作员',
      dataIndex: 'realname',
      align: 'center',
      key: 'realname',
    },

    {
      title: '操作',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      render: function Actions(_, t): ReactElement | undefined {
        return (
          <Space style={{ lineHeight: 0 }}>
            <p className={styles.tableText} onClick={() => onClick('topUp', t)}>
              充值
            </p>
            <p
              className={styles.tableText}
              onClick={() => onClick('topUpRecord', t)}
            >
              充值记录
            </p>
            <p
              className={styles.tableText}
              onClick={() => onClick('refund', t)}
            >
              退款
            </p>
            <p
              className={styles.tableText}
              onClick={() => onClick('detail', t)}
            >
              详情
            </p>
          </Space>
        )
      },
    },
  ]
}
export const TopUpRecordModalColumns = (
  startIndex: number,
  type?: number // '' 会员管理-充值记录  1.会员信息详情
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: ` ${type == 1 ? '充值金额' : '交易金额'}`,
      dataIndex: 'traderMoney',
      align: 'center',
      key: 'traderMoney',
      render: (_, r) =>
        r.type == 1 || r.type == 2
          ? '-' + r.traderMoney?.toFixed(2)
          : r.traderMoney?.toFixed(2),
    },
    {
      title: '账户余额',
      dataIndex: 'accountMoney',
      align: 'center',
      key: 'accountMoney',
      render: (_, r) => r.accountMoney?.toFixed(2),
    },
    {
      title: '类型',
      dataIndex: 'type',
      align: 'center',
      key: 'type',
      render: (_, r) => getTypesName(r.type),
    },
    {
      title: '支付方式',
      dataIndex: 'payType',
      align: 'center',
      key: 'payType',
      render: (_, r) => getPayTypesName(r.payType),
    },
    {
      title: '操作时间',
      dataIndex: 'updateTime',
      align: 'center',
      key: 'updateTime',
    },
    {
      title: '操作员',
      dataIndex: 'createUserName',
      align: 'center',
      key: 'createUserName',
    },

    {
      title: '备注',
      align: 'center',
      dataIndex: 'remarks',
      key: 'remarks',
      ellipsis: true,
    },
  ]
}
export const detailColumns = (startIndex: number): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '刷卡人',
      dataIndex: 'traderName',
      align: 'center',
      key: 'traderName',
    },
    {
      title: '交易金额',
      dataIndex: 'traderMoney',
      align: 'center',
      key: 'traderMoney',
      render: (_, r) =>
        r.type == 1 || r.type == 2
          ? '-' + r.traderMoney?.toFixed(2)
          : r.traderMoney?.toFixed(2),
    },
    {
      title: '账户余额',
      dataIndex: 'accountMoney',
      align: 'center',
      key: 'accountMoney',
      render: (_, r) => r.accountMoney?.toFixed(2),
    },
    {
      title: '项目',
      dataIndex: 'itemName',
      align: 'center',
      key: 'itemName',
    },
    {
      title: '操作时间',
      dataIndex: 'updateTime',
      align: 'center',
      key: 'updateTime',
    },
    {
      title: '操作员',
      dataIndex: 'createUserName',
      align: 'center',
      key: 'createUserName',
    },

    // {
    //   title: "备注",
    //   align: "center",
    //   dataIndex: "remarks",
    //   key: "remarks",
    //   ellipsis: true

    // },
  ]
}
