import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Form, Input, Modal, ModalProps, Row, Tag } from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { Patient } from '../../../models/patient'
import { Patientlable } from '../../../models/patientlable'
import { ThemeContext } from '../../../theme/ThemeContext'
import { sleep } from '../../../utils/SystemUtils'
import styles from './PatientForm.module.css'
import { savePatient } from '../patientSlice'
import { PatientForm } from './PatientForm'
import {
  getLabelList,
  getPatientLabelList,
  setPatientLabel,
  getPatientDetail,
} from './patientSlice'
import { selectInsuranceArray } from '../../../app/applicationSlice'

interface PatientModalProps {
  tag?: any //交易查询多一行医保信息
  registrations?: any
  patient?: any
  status?: number
  onRetailFn?: (obj: any) => void
  registrationId?: string
  treatmentId?: string
  onChange?: (patient: Patient) => void
  onLabelChange?: () => void
}

export const PatientModal = ({
  tag,
  registrations,
  patient,
  status,
  onRetailFn,
  registrationId,
  treatmentId,
  onCancel,
  onOk,
  onChange,
  onLabelChange,
  visible,
  ...others
}: PatientModalProps & ModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const { CheckableTag } = Tag

  const insuranceArray = useSelector(selectInsuranceArray)

  const [selectedTags, setSelectedTags] = useState<string[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const [labels, setLabels] = useState<Patientlable[]>([])

  const [data, setData] = useState<any>()

  const [selectedLabels, setSelectedLabels] = useState<Patientlable[]>([])

  useEffect(() => {
    if (visible) {
      dispatch(getLabelList({current:1,size:1000,state:0,labelType:0}))
        .then(unwrapResult)
        .then((ls) => setLabels(ls))

      const id = tag ? patient && patient?.patientId : patient && patient?.id

      if (id && status == 1) {
        dispatch(
          getPatientDetail({
            patientId: id,
          })
        )
          .then(unwrapResult)
          .then((res) => {
            setSelectedTags(res.labelIdList.map((v: number) => v.toString()))
            setData(res)
            form.setFieldsValue({
              ...res.patient,
              ageMonth:
                (res.patient.ageMonth || -1) < 0
                  ? undefined
                  : res.patient.ageMonth,
            })
          })
      }
    } else {
      if (status == 1) {
        form.resetFields()
        setSelectedTags([])
      }
    }
  }, [visible])

  useEffect(() => {
    const id = tag ? patient && patient?.patientId : patient && patient?.id
    if (patient) {
      dispatch(getPatientLabelList(id))
        .then(unwrapResult)
        .then((ls) => setSelectedLabels(ls))
        .catch(() => {
          // do nothing
        })
    }
  }, [patient?.id, patient?.patientId])

  return (
    <Modal
      title={tag ? '病人信息' : '病人信息修改'}
      visible={visible}
      className={styles.modal}
      {...others}
      width={1200}
      bodyStyle={{ padding: '20px 30px 20px 15px' }}
      confirmLoading={isLoading}
      onCancel={(e) => {
        onCancel && onCancel(e)
      }}
      onOk={async (e) => {
        setIsLoading(true)
        try {
          const values = await form.validateFields()
          const now = moment()
          const newPatient = {
            ...patient,
            ...values,
            id: patient?.id ? patient?.id : 0, ////快速开单没有传0
            area: undefined,
            provinceCode: values?.area?.[0],
            cityCode: values?.area?.[1],
            contryCode: values?.area?.[2],
          }
          await dispatch(
            savePatient({
              patient: newPatient,
              registrationId,
              treatmentId,
              labelIdList:
                status == 1
                  ? selectedTags.length != 0
                    ? selectedTags
                    : ['0']
                  : undefined, //快速开单没选标签传0
            })
          )
            .then(unwrapResult)
            .then((res: any) => {
              onRetailFn && onRetailFn({ ...newPatient, id: res }) //快速开单
            })

          const milliseconds = moment
            .duration(moment().diff(now))
            .milliseconds()
          if (1000 - milliseconds > 0) {
            await sleep(1000 - milliseconds)
          }
          onChange && onChange(newPatient)
          onOk && onOk(e)
        } catch {
          //
        } finally {
          setIsLoading(false)
        }
      }}
    >
      <Col>
        <PatientForm
          registrations={registrations || data?.patientInsurance}
          form={form}
          initialValues={{
            ...patient,
            area:patient?.provinceCode? [
              Number(patient?.provinceCode),
              Number(patient?.cityCode),
              Number(patient?.contryCode),
            ]:[],
            name: status == 1 ? '零售患者' : patient?.name,
            ageYear:
              (patient?.ageYear || -1) < 0 ? undefined : patient?.ageYear,
            ageMonth:
              (patient?.ageMonth || -1) < 0 ? undefined : patient?.ageMonth,
          }}
        />
        {status != 1 && (
          <Row>
            <Col
              flex='6.75rem'
              style={{
                fontSize: '1.125rem',
                color: theme.tc1,
                textAlign: 'right',
                paddingRight: 10,
              }}
            >
              标签
            </Col>
            <Col flex='1'>
              <Row gutter={15}>
                {labels.map((l) => {
                  const selected = !!selectedLabels.find(
                    (ll) => ll.labelId == l.id
                  )
                  return (
                    <Col
                      key={l.id}
                      span={3}
                      style={{ marginBottom: 15 }}
                      onClick={async () => {
                        dispatch(
                          setPatientLabel({
                            patientId: patient?.id,
                            labelId: selected
                              ? selectedLabels.find((lb) => lb.labelId === l.id)
                                  ?.id
                              : l.id,
                            set: !selected,
                          })
                        )
                          .then(unwrapResult)
                          .then(() => {
                            if (patient) {
                              dispatch(getPatientLabelList(patient?.id))
                                .then(unwrapResult)
                                .then((ls) => setSelectedLabels(ls))
                                .then(() => onLabelChange && onLabelChange())
                                .catch(() => {
                                  // do nothing
                                })
                            }
                          })
                      }}
                    >
                      <div
                        style={{
                          fontSize: '1rem',
                          lineHeight: '2.25rem',
                          color: selected ? theme.tt : theme.tc2,
                          textAlign: 'center',
                          borderRadius: 2,
                          border: selected
                            ? undefined
                            : `1px solid ${theme.wbd}`,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          cursor: 'pointer',
                          backgroundColor: selected ? theme.c1 : undefined,
                        }}
                      >
                        {l.name}
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
        )}
        {status == 1 && (
          <Row>
            <Col
              flex='6.75rem'
              style={{
                fontSize: '1.125rem',
                color: theme.tc1,
                textAlign: 'right',
                paddingRight: 10,
                marginBottom: labels?.length ? 0 : '26px',
              }}
            >
              标签
            </Col>
            {labels.map((item: any) => (
              <CheckableTag
                style={{
                  marginBottom: 20,
                  paddingRight: 10,
                  width: 120,
                  fontSize: '1.125rem',
                  lineHeight: '2.25rem',
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  border: '1px solid #d2d2d2',
                  borderRadius: 3,
                }}
                key={item.id}
                checked={selectedTags.indexOf(item.id) > -1}
                onChange={(checked) => {
                  const nextSelectedTags = checked
                    ? [...selectedTags, item.id]
                    : selectedTags.filter((t) => t !== item.id)
                  setSelectedTags(nextSelectedTags)
                }}
              >
                {item.name}
              </CheckableTag>
            ))}
          </Row>
        )}
        {tag && insuranceArray?.length !== 0 && (
          <>
            <Row>
              <Col
                flex='6.75rem'
                style={{
                  fontSize: '1.125rem',
                  color: theme.tc1,
                  textAlign: 'right',
                  paddingRight: 10,
                }}
              >
                医保信息
              </Col>
              <Col flex='1' style={{ marginLeft: '8px' }}>
                <Row gutter={15} className={styles.patientModalTextArea}>
                  <Input.TextArea
                    disabled
                    style={{ fontSize: '16px' }}
                    value={
                      '医保卡号：' +
                      (data?.patientInsurance?.medicalCardNo
                        ? data?.patientInsurance?.medicalCardNo
                        : '-') +
                      ' ，' +
                      '个账余额：' +
                      (data?.patientInsurance?.zhye
                        ? data?.patientInsurance?.zhye
                        : '-') +
                      ' ，' +
                      '当年账户余额：' +
                      (data?.patientInsurance?.dnzhye
                        ? data?.patientInsurance?.dnzhye
                        : '-') +
                      ' ，' +
                      '历年账户余额：' +
                      (data?.patientInsurance?.lnzhye
                        ? data?.patientInsurance?.lnzhye
                        : '-') +
                      ' ，' +
                      '工作单位：' +
                      (data?.patientInsurance?.brdw
                        ? data?.patientInsurance?.brdw
                        : '-') +
                      ' ，' +
                      '参保险种：' +
                      (data?.cbxz ? data?.cbxz : '-') +
                      ' ，' +
                      '人员类型：' +
                      (data?.memberType ? data?.memberType : '-')
                    }
                  />
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Modal>
  )
}
