/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-07 16:12:55
 */
import { Form, Input, Select, Button, Row, Col, Space, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { treatmentCostParams } from '../../../services/treatmentCost'
import { PrintOutlined } from '../../../compnents/icons/Icons'
import { useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../app/applicationSlice'
import moment from 'moment'
import {
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import {
  getQuarterMonthTime,
  getQuarterTime,
  getTime,
} from '../../../models/sellingExpenseSummary'
const { Option } = Select

export const Query = (props: {
  timeHead?: string
  timeTail?: string
  onValueChange: (queries: treatmentCostParams, timeFlag: any) => void
  print: () => void
}) => {
  const [form] = Form.useForm()

  const { RangePicker } = DatePicker

  const insuranceArray = useSelector(selectInsuranceArray)

  const [timeFlag, setTimeFlag] = useState<any>('1')

  const [datas, setDatas] = useState<any>()

  const [params, setParams] = useState<any>({
    timeHead: moment().startOf('year').format(DateTimeFormatSimpleOnlyOne),
    timeTail: moment().startOf('year').endOf('month').format(DateTimeFormatSimpleOnlyOne),
  })

  useEffect(() => {
    if (timeFlag == '0') {
      setDatas({
        placeholder: '不选择按年度分组',
        list: [
          { name: moment().format('YYYY'), key: moment().format('YYYY') },
          {
            name: moment().add(-1, 'year').format('YYYY'),
            key: moment().add(-1, 'year').format('YYYY'),
          },
          {
            name: moment().add(-2, 'year').format('YYYY'),
            key: moment().add(-2, 'year').format('YYYY'),
          },
          {
            name: moment().add(-3, 'year').format('YYYY'),
            key: moment().add(-3, 'year').format('YYYY'),
          },
          {
            name: moment().add(-4, 'year').format('YYYY'),
            key: moment().add(-4, 'year').format('YYYY'),
          },
          {
            name: moment().add(-5, 'year').format('YYYY'),
            key: moment().add(-5, 'year').format('YYYY'),
          },
          {
            name: moment().add(-6, 'year').format('YYYY'),
            key: moment().add(-6, 'year').format('YYYY'),
          },
        ],
      })
    } else if (timeFlag == '3') {
      setDatas({
        placeholder: '不选择按季度分组',
        list: [
          { name: '第一季度(1-3)', key: '1' },
          { name: '第二季度(4-6)', key: '2' },
          { name: '第三季度(7-9)', key: '3' },
          { name: '第四季度(10-12)', key: '4' },
        ],
      })
    } else if (timeFlag == '1') {
      setDatas({
        placeholder: '不选择按月度分组',
        list: [
          { name: '一月', key: '1' },
          { name: '二月', key: '2' },
          { name: '三月', key: '3' },
          { name: '四月', key: '4' },
          { name: '五月', key: '5' },
          { name: '六月', key: '6' },
          { name: '七月', key: '7' },
          { name: '八月', key: '8' },
          { name: '九月', key: '9' },
          { name: '十月', key: '10' },
          { name: '十一月', key: '11' },
          { name: '十二月', key: '12' },
        ],
      })
    }
  }, [timeFlag])

  const times = () => {
    setParams({
      timeHead: moment().startOf('year').format(DateTimeFormatSimpleOnlyOne),
      timeTail: moment().endOf('year').format(DateTimeFormatSimpleOnlyOne),
    })
  }

  return (
    <Form
      form={form}
      onFinish={(vs) => {
        const startTime =
          moment(vs?.time?.[0])?.format(DateTimeFormatSimpleOnly) +
          ' ' +
          '00:00:00'
        const endTime =
          moment(vs?.time?.[1])?.format(DateTimeFormatSimpleOnly) +
          ' ' +
          '23:59:59'
        if (vs?.timeType == '2') {
          props.onValueChange(
            {
              ...vs,
              timeHead: vs?.time
                ? startTime
                : moment().startOf('day').format(DateTimeFormatSimpleOnlyOne),
              timeTail: vs?.time
                ? endTime
                : moment().endOf('day').format(DateTimeFormatSimpleOnlyOne),
              years: undefined,
              time: undefined,
              quarter: undefined,
            },
            timeFlag
          )
        } else {
          props.onValueChange(
            {
              ...vs,
              ...params,
              years: undefined,
              time: undefined,
              quarter: undefined,
            },
            timeFlag
          )
        }
      }}
    >
      <Row
        style={{ marginBottom: 10, width: '100%' }}
        align='middle'
        justify='space-between'
      >
        <Space>
          <Form.Item name='timeType' noStyle initialValue={'1'}>
            <Select
              allowClear={false}
              placeholder='时间段'
              style={{ width: '10rem' }}
              onChange={(v) => {
                // if (v == '2') {
                //   form.setFieldsValue({
                //     time: [moment().startOf('day'), moment().endOf('day')],
                //   })
                // } else {
                //   form.setFieldsValue({
                //     time: [moment().startOf('day'), moment().endOf('day')],
                //   })
                //   times()
                // }
                // form.setFieldsValue({
                //   quarter: v == 0 ? moment().format('YYYY') : null,
                //   years: moment().format('YYYY'),
                // })
                if (v == '2') {
                  form.setFieldsValue({
                    time: [moment().startOf('day'), moment().endOf('day')],
                  })
                }
                const quarter = form.getFieldValue("quarter");
                const years = form.getFieldValue("years");
                setParams({
                  ...getQuarterMonthTime(years, quarter),
                });
                setTimeFlag(v)
                form.submit()
              }}
            >
              {/* <Select.Option value={'0'}>年度</Select.Option>
              <Select.Option value={'3'}>季度</Select.Option> */}
              <Select.Option value={'1'}>月度</Select.Option>
              <Select.Option value={'2'}>每天</Select.Option>
            </Select>
          </Form.Item>
          {timeFlag && (timeFlag == 1 || timeFlag == 3) && (
            <Form.Item name='years' noStyle initialValue={moment().format('YYYY')}>
              <Select
                placeholder='请选择年份'
                style={{ width: '10rem' }}
                onChange={(v) => {
                  // form.setFieldsValue({
                  //   quarter: timeFlag == 0 ? moment().format('YYYY') : null,
                  // })
                  // if (v == moment().format('YYYY')) {
                  //   times()
                  // } else if (v == moment().add(-1, 'year').format('YYYY')) {
                  //   setParams({
                  //     timeHead: moment()
                  //       .add(-1, 'year')
                  //       .startOf('year')
                  //       .format(DateTimeFormatSimpleOnlyOne),
                  //     timeTail: moment()
                  //       .add(-1, 'year')
                  //       .endOf('year')
                  //       .format(DateTimeFormatSimpleOnlyOne),
                  //   })
                  // }
                  const quarter = form.getFieldValue("quarter");
                  setParams({
                    ...getQuarterMonthTime(v, quarter)
                  });
                  form.submit()
                }}
              >
                <>
                  <Select.Option value={moment().format('YYYY')}>
                    {moment().format('YYYY')}
                  </Select.Option>
                  <Select.Option
                    value={moment().add(-1, 'year').format('YYYY')}
                  >
                    {moment().add(-1, 'year').format('YYYY')}
                  </Select.Option>
                </>
              </Select>
            </Form.Item>
          )}
          {timeFlag && timeFlag != 2 && (
            <Form.Item
              name='quarter'
              noStyle
              initialValue={String(moment().month() + 1)}
            >
              <Select
                placeholder={datas?.placeholder}
                style={{ width: '10rem' }}
                onChange={(v) => {
                  if (timeFlag == 0) {
                    setParams({
                      ...getTime(v),
                    })
                    form.submit()
                  } else if (timeFlag == 3) {
                    const years = form.getFieldValue('years')
                    setParams({
                      ...getQuarterTime(years, v),
                    })
                    form.submit()
                  } else if (timeFlag == 1) {
                    const years = form.getFieldValue('years')
                    setParams({
                      ...getQuarterMonthTime(years, v),
                    })
                    form.submit()
                  }
                }}
              >
                {datas?.list?.map((v: any) => {
                  return (
                    <>
                      <Select.Option value={v?.key}>{v?.name}</Select.Option>
                    </>
                  )
                })}
              </Select>
            </Form.Item>
          )}
          {timeFlag == 2 && (
            <Form.Item name='time' noStyle>
              <RangePicker
                onChange={(v) => {
                  form.submit()
                }}
              />
            </Form.Item>
          )}
          {insuranceArray && insuranceArray.length !== 0 && (
            <Form.Item name='insuranceCode' noStyle>
              <Select
                placeholder='险种选择'
                style={{ width: '8.25rem' }}
                onChange={(v) => {
                  form.submit()
                }}
              >
                <Select.Option value={''}>全部</Select.Option>
                <Select.Option value={'ZIFEI'}>自费</Select.Option>
                {insuranceArray.map((v) => (
                  <Select.Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Button htmlType='submit' type='primary'>
            查询
          </Button>
        </Space>
        <Space style={{ float: 'right' }}>
          <Button
            type='text'
            style={{
              color: '#666',
              display: 'flex',
              alignItems: 'center',
              float: 'right',
            }}
            onClick={() => {
              props.print()
            }}
          >
            <PrintOutlined
              style={{
                fill: '#666',
                width: '18px',
                height: '18px',
                marginRight: '4px',
              }}
            />
            打印
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
