/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-29 09:52:44
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-13 15:41:17
 */
import { Button, Col, Modal, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CategoryName } from "../../models/category";
import { NavBar } from "../../compnents/nav/NavBar";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  detailPutstorage,
  savelistPutstorageAsync,
  selectCurrent,
  selectDetail,
  selectTotal,
  setCurrent,
} from "../putstorage/putstorageSlice";
import { DataType } from "../putstorage/putstorage";
import { EditableList } from "../../compnents/list/EditableList";
import { TableRowSelection } from "antd/lib/table/interface";
import { QingDouModule } from "./module";
import {
  Removestoredetail,
  selectorPutstoreData,
} from "../putstorelist/putstorelistSlice";
import styles from "../putstorelist/Putstorelist.module.css";
import { QingdouQuery } from "./Qingdouquery";
import { settenantInventoryReceiptsId } from "./QingdouSlice";
import { QingDouColumns } from "./columns";
import { RootDispatch } from "../../app/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { AccountOutlined } from "../../compnents/icons/Icons";

export const QingdouDetail = (props: {
  selectId?: number;
  category?: number;
}) => {
  const location: any = useLocation();

  const selectId = location.state.selectId || props.selectId

  const category = location.state.category || props.category

  const dispatch = useDispatch<RootDispatch>();

  const [size, setSize] = useState(10);

  const history = useHistory();

  const [Ids, setIds] = useState("");

  const [Isvisable, setIsvisable] = useState(false);

  const [text, setText] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const data = useSelector(selectorPutstoreData);

  const detail: any = useSelector(selectDetail);

  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );

  const total = useSelector(selectTotal);

  const Current = useSelector(selectCurrent);

  function onShowSizeChange(current: any, pageSize: any) {
    setSize(pageSize);
    dispatch(setCurrent(current));
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setIds(selectedRowKeys.join(","));
    },
  };

  useEffect(() => {
    if (selectId) {
      dispatch(detailPutstorage(selectId));
    }
  }, [location, props?.selectId]);

  useEffect(() => {
    dispatch(settenantInventoryReceiptsId(detail.id));
  }, [detail]);

  return (
    <>
      <NavBar
        style={{ margin: "10px 20px 0 20px" }}
        where={["库存查询", CategoryName(category) as string]}
        backtrace={{
          name: CategoryName(category) as string,
          path: "/InventoryCheck",
          state: { category: category },
          routeName: "库房工作站",
        }}
        buttons={
          detail.storehouseTag === 0 ? (
            <Button
              type="text"
              icon={
                <AccountOutlined
                  style={{
                    fill: "#666",
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                />
              }
              onClick={() => {
                // if (!Ids) {
                //   notification.error({
                //     message: "请选择要登账的信息!",
                //     duration: 3,
                //   });
                // } else {
                setText("登账");
                setIsModalVisible(true);
                // }
              }}
              style={{
                color: "#666",
                display: "flex",
                alignItems: "center",
              }}
            >
              登账
            </Button>
          ) : (
            <></>
          )
        }
      />
      <div className={styles.content}>
        <QingdouQuery
          detail={detail}
          remove={() => {
            dispatch(Removestoredetail(Ids, selectId));
          }}
          isModal={() => {
            setIsvisable(true);
          }}
        />
        <EditableList
          className={styles.Qingdou}
          style={{ flex: 1 }}
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          pagination={false}
          onChangePage={(current, pageSize) => {
            onShowSizeChange(current, pageSize);
          }}
          columns={QingDouColumns()}
          rowSelection={
            {
              type: selectionType,
              ...rowSelection,
            } as TableRowSelection<any>
          }
          bordered
        />
        <QingDouModule
          detail={detail}
          visible={Isvisable}
          onOk={() => {
            dispatch(detailPutstorage(selectId));
            dispatch(settenantInventoryReceiptsId(0));
            setIsvisable(false);
          }}
          onCancel={() => {
            dispatch(settenantInventoryReceiptsId(0));
            setIsvisable(false);
          }}
        />
      </div>
      <Modal
        title="信息"
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
          if (text == "登账")
            dispatch(savelistPutstorageAsync(String(selectId)))
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: "登账成功",
                  duration: 3,
                });
                history.push({
                  pathname: "/InventoryCheck",
                  state: { category: "10", station: "2" },
                });
              })
              .catch(() => {
                notification.error({
                  message: "当前单据单据无明细,单据登账失败",
                  duration: 3,
                });
              });
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        okText="确认"
        cancelText="取消"
      >
        <h3>是否登账？</h3>
        <p>请确认各项信息是否填写正确，登账后将不可修改</p>
      </Modal>
    </>
  );
};
