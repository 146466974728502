import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, Switch } from "antd";
import styles from "./Durgsystem.module.css";
import {
  detailDurgsystem,
  saveDurgSystem,
  selectDetail,
  selectDurgId,
  setDetail,
  setSelectId,
} from "../durgsystem/durgsystemSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  durgSystemCategories,
  durgSystemCategoryName,
} from "../../models/durgSystem";
const { Option } = Select;
const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

export const DurgModal = (props: {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const [action, setAction] = useState("新增");

  const [title, setTitle] = useState("药房");

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const selectId = useSelector(selectDurgId);
  useEffect(() => {
    if (selectId) {
      setAction("修改");
      dispatch(detailDurgsystem(selectId));
    } else {
      setAction("新增");
    }
  }, [selectId]);

  const detail: any = useSelector(selectDetail);

  useEffect(() => {
    if (JSON.stringify(detail) !== "{}") {
      setAction("修改");
      form.setFieldsValue({
        name: detail.name,
        category: detail.category,
        deliveryMethod: detail.deliveryMethod,
        servicePurchaseStorage: Boolean(detail.servicePurchaseStorage),
        serviceShopPrice: Boolean(detail.serviceShopPrice),
      });
    } else {
      setAction("新增");
      form.setFieldsValue({
        category: 0,
        servicePurchaseStorage: 1,
        serviceShopPrice: 1,
        deliveryMethod:0
      });
    }
  }, [detail]);
  return (
    <div>
      <Modal
        title={action + title}
        visible={props.visible}
        okText="确认"
        cancelText="取消"
        className={styles.form}
        onOk={() => {
          props.onOk();
        }}
        onCancel={() => {
          form.resetFields();
          dispatch(setSelectId(0));
          dispatch(setDetail({}));
          props.onCancel();
        }}
        footer={null}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          autoComplete="off"
          initialValues={{ remember: true }}
          onFinish={(vs) => {
            if (detail) {
              dispatch(
                saveDurgSystem({
                  ...vs,
                  servicePurchaseStorage: vs.servicePurchaseStorage
                    ? Number(vs.servicePurchaseStorage)
                    : 0,
                  serviceShopPrice: vs.serviceShopPrice
                    ? Number(vs.serviceShopPrice)
                    : 0,
                  id: detail.id,
                 
                })
                
              );
            } else {
              dispatch(
                saveDurgSystem({
                  ...vs,
                  servicePurchaseStorage: Number(vs.servicePurchaseStorage),
                  serviceShopPrice: Number(vs.serviceShopPrice),
                })
              );
            }
            form.resetFields();
            dispatch(setSelectId(0));
            dispatch(setDetail({}));
            props.onOk();
          }}
        >
          <Row gutter={20}>
            <Col span={24} style={{ height: "50px" }}>
              <Form.Item
                label="药房名称"
                name="name"
                rules={[
                  {
                    max: 100,
                    message: "药房名称最长100个字符",
                  },
                ]}
              >
                <Input autoFocus/>
              </Form.Item>
            </Col>
            <Col span={24} style={{ height: "50px" }}>
              <Form.Item label="药房类别" name="category">
                <Select>
                  {durgSystemCategories.map((v) => {
                    return (
                      <Option value={v} key={v}>
                        {durgSystemCategoryName(v)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            {/* <Col span={24} style={{ height: "50px" }}>
              <Form.Item label="出库方式" name="deliveryMethod">
                <Select>
                  <Option value={0}>最短有效期</Option>
                  <Option value={1}>最少库存</Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label="售药权限"
                name="serviceShopPrice"
                valuePropName="checked"
                labelCol={{ span: "12" }}
                wrapperCol={{ span: "12" }}
                style={{ float: "left" }}
              >
                <Switch checkedChildren="开启" unCheckedChildren="关闭" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="购入入库权限"
                name="servicePurchaseStorage"
                valuePropName="checked"
                labelCol={{ span: "15" }}
                wrapperCol={{ span: "9" }}
                style={{ float: "left" }}
              >
                <Switch checkedChildren="开启" unCheckedChildren="关闭" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Button
                style={{ float: "right" }}
                onClick={() => {
                  form.resetFields();
                  dispatch(setSelectId(0));
                  dispatch(setDetail({}));
                  props.onCancel();
                }}
              >
                取消
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType="submit"
                type="primary"
                style={{ float: "left" }}
              >
                确认
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
