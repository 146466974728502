import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Dimen } from "../../models/dimen";
import { EditableList } from "../../compnents/list/EditableList";
import { selectInsuranceArray } from '../../app/applicationSlice';
import { Retail } from "../../models/retail";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Commodity.module.css";
import {
  getMedicarePage,
  saveMedicare
} from "./commoditySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../app/store";

import { TableRowSelection } from "antd/lib/table/interface";
const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CommodityMedicareModal = (props: {
  onOk: () => void;
  onCancel: () => void;
  visible: boolean;
  title: string;
}) => {
  const [form] = Form.useForm();


  const [options, setOptions] = useState<any>([])

  const dispatch = useDispatch<RootDispatch>();

  const [params, setParams] = useState<any>({ current: 0, size: 10 });

  const [page, setPage] = useState<any>()

  const insuranceArray = useSelector(selectInsuranceArray) //获取结算险种

  const [selectIds, setSelectIds] = useState<any>([])

  const [tempIds, setTempIds] = useState<any>([])


  const [tempIdsList, setTempIdsList] = useState<any>([])






  useEffect(() => {   //获取结算险种
    setOptions(insuranceArray)
  }, [insuranceArray])

  useEffect(() => {
    props.visible && form.submit()
  }, [props.visible])

  useEffect(() => {   //获取表格数据
    if(!params.current) return
    dispatch(getMedicarePage(params)).then(unwrapResult).then(res => {
      res?.items?.forEach((item: any) => {
        item.sta = true
        // item.sellingPrice = ''
      })
      setPage(res)
    })
  }, [params]);

  useEffect(() => {
    setTempIds(selectIds)

  }, [page])
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      page.items.forEach((item: any) => {
        item.sta = true
      })
      selectedRows.forEach((item: any) => {
        item.sta = false

      })
      setTempIdsList(selectedRows)
      setSelectIds([...selectedRowKeys])

      
    },
  }
  const MedicarMateriales = [
    { value: '01', label: '床位费', },
    { value: '02', label: '诊察费', },
    { value: '03', label: '检查费', },
    { value: '04', label: '化验费', },
    { value: '05', label: '治疗费', },
    { value: '06', label: '手术费', },
    { value: '07', label: '护理费', },
    { value: '1401', label: '特殊材料费', },
    { value: '14011', label: '血液及血液制品', },
    { value: '14012', label: '单病种除外内容', },
    { value: '1402', label: '国产材料', },
    { value: '14021', label: '集中带量', },
    { value: '1403', label: '进口材料', },
    { value: '14031', label: '康复项目', },
    { value: '08', label: '卫生材料费', },
    { value: '09', label: '西药费', },
    { value: '10', label: '中药饮片费', },
    { value: '11', label: '中成药费', },
    { value: '12', label: '一般诊疗费', },
    { value: '13', label: '挂号费', },
    { value: '14', label: '其他费', },
    { value: '1404', label: '国谈药品', },
    { value: '1405', label: '手术材料费', },
    { value: '1406', label: '中草药及中医适宜技术', },
    { value: '15', label: '氧费', },
    { value: '16', label: '血制品', },
    { value: '17', label: '人工晶体', }
  ]

  return (
    <Modal
      visible={props.visible}
      title={props.title}
      okText="确认"
      cancelText="取消"
      className={styles.Modal}
      bodyStyle={{ padding: "10px 20px" }}
      onOk={() => {
        props.onOk();
      }}
      onCancel={() => {
        props.onCancel();
      }}
      footer={null}
    >

      <Form
        form={form}
        autoComplete="off"
        initialValues={{
          insuranceCode: insuranceArray?.[0]?.insuranceCode,
          medChrgitmType: '05'
        }}
        onFinish={(values) => {
          setParams({ ...params, ...values, current: 1 });
        }}
      >

        <Row gutter={14}>
          <Col span={6}>
            <Form.Item name="hilistName">
              <Input
                size="middle"
                placeholder="搜索关键词"
                allowClear
                prefix={<SearchOutlined />}
                // onChange={form.submit}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="hilistCode">
              <Input
                size="middle"
                placeholder="搜索医保编码"
                allowClear
                prefix={<SearchOutlined />}
              // onChange={form.submit}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="medChrgitmType">
              <Select
                // onChange={form.submit}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择开票项目"
                allowClear
              >
                {MedicarMateriales.map((v) => (
                  <Option value={v.value} key={v.value}>
                    {v.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
          <Form.Item name="insuranceCode">
              <Select
                placeholder="结算险种"
                // onChange={form.submit}
              >
                {options.map((v: any) => {
                  return (
                    <Option value={v.insuranceCode} key={v.insuranceCode}>
                      {v.insuranceName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2} offset={4}>
            <Button
              type="primary"
              style={{ width: "100%", float: "right" }}
              onClick={form.submit}
            >
              查询
            </Button>
          </Col>
        </Row>
      </Form>
      <EditableList<Retail>
        style={{ flex: 1 }}
        rowKey={(record: any) => record.rid}
        page={page}
        columns={[
          {
            title: "序号",
            key: "no",
            align: "center",
            width: Dimen.Num,
            render: (_1, _2, i) => `${(page.current - 1) * page.size + i + 1}`,
          },
          {
            title: "医保目录名称",
            dataIndex: "hilistName",
            align: "center",
            width: Dimen.Time,
          },
          {
            title: "国家医保编码",
            dataIndex: "hilistCode",
            align: "center",
            width: Dimen.Time,
          },
          {
            title: "所属险种",
            dataIndex: "insuranceCode",
            align: "center",
            width: Dimen.Time,
          },
          // {
          //   title: "目录类别",
          //   dataIndex: "listType",
          //   align: "center",
          //   width: Dimen.Time,
          // },
          {
            title: "售价",
            dataIndex: "medChrgitmType",
            align: "center",
            width: Dimen.Time,
            render: function Element(_, t) {
              selectIds?.forEach((item: any) => {
                if (t.rid == item) {
                  t.sta = false
                }
              })
              return <Input
                size="middle"
                placeholder="请输入售价"
                value={t.sellingPrice}
                disabled={t.sta}
                onChange={(e) => {
                  const { value } = e.target;
                  t.sellingPrice = value

                }}
                allowClear
              />

            }
          },
        ]}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size })
        }
        rowSelection={
          {
            selectedRowKeys: Array.from(new Set(selectIds)),
            ...rowSelection,
          } as TableRowSelection<any>
        }
        />

<Row gutter={20} style={{ marginTop: "20px" }}>
      <Col span={12}>
        <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            onClick={() => {
              const arrParams = tempIdsList.map((item: any) => {
                return {
                  rid: item.rid,
                  sellingPrice: item.sellingPrice
                }
              })
              const reg = /^([0](\.\d{1,4}))$|^([1-9][0-9]*(\.\d{1,4})?)$/;   //校验输入的值
              let tempFlag = false
              arrParams.forEach((item: any) => {
                if (!reg.test(item.sellingPrice)) {
                  tempFlag = true
                }
              })

              if (!tempFlag) {
                dispatch(saveMedicare([...arrParams]))
                  .then(unwrapResult)
                  .then((res: any) => {
                    notification.open({ message: res, duration: 5 })
                    props.onOk();
                    setSelectIds([])
                    setParams({ ...params, current: 1, size: 10 })
                    form.resetFields()
                  });
              } else {
                notification.error({ message: '请输入正确的售价', duration: 3 })
              }
            }
            } 

          >
            导入
          </Button>
        </Col>
        <Col span={12}>
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            取消
          </Button>
        </Col>
      </Row>

    </Modal>
  );

};
