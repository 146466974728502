/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-02-27 15:26:46
 */
import { TableColumnType } from 'antd'
import React from 'react'

export const Columns = (
  startIndex: number,
  sortedInfo?: any
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '物资信息',
      align: 'left',
      dataIndex: 'name',
      sorter: true,
      key: 'wzxx',
      sortOrder: sortedInfo?.columnKey === 'wzxx' && sortedInfo?.order,
      // sorter: (a, b) => a.name.localeCompare(b.name),
      render: function Element(_, record) {
        return (
          <div style={{ height: '55px' }}>
            <p
              style={{
                height: '28px',
                lineHeight: '36px',
                padding: '0',
                margin: '0',
                fontSize: '16px',
                fontWeight: 'bolder',
              }}
            >
              {record.name}
            </p>
            <p
              style={{
                height: '28px',
                lineHeight: '24px',
                padding: '0',
                margin: '0',
                color: '#999999',
                fontSize: '14px',
              }}
            >
              {record.tenantSupplierName} / {record.packSpec} /
              {record.doseCategory}
            </p>
          </div>
        )
      },
    },
    // {
    //   title: '批次号',
    //   align: 'center',
    //   dataIndex: 'batchNo',
    //   render: (_, t) => {
    //     return  t.batchNo
    //   },
    // },
    {
      title: '库存下限值',
      align: 'center',
      dataIndex: 'inventoryAlert',
      sorter: true,
      key: 'kcxxz',
      sortOrder:
        sortedInfo?.columnKey === 'kcxxz' && sortedInfo?.order,
      // sorter: (a: any, b: any) => a.inventoryAlert - b.inventoryAlert,
      render: (_, t) => {
        return `${t.inventoryAlert} ${t.accountUnit}`
      },
    },
    {
      title: '库存数量',
      align: 'center',
      dataIndex: 'count',
      sorter: true,
      key: 'kcsl',
      sortOrder:
        sortedInfo?.columnKey === 'kcsl' && sortedInfo?.order,
      // sorter: (a: any, b: any) => a.count - b.count,
      render: (_, t) => {
        return `${t.count} ${t.accountUnit}`
      },
    },
  ]
}
