/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-19 14:33:03
 * @LastEditors: linxi
 * @LastEditTime: 2024-02-01 10:00:29
 */
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Divider, Dropdown, Input, InputProps } from "antd";
import { debounce } from "lodash";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../../../app/store";
import { CommonCodeCategory } from "../../../../models/commoncode";
import { commonCode } from "../../../../models/kqCommoncode";
import { ThemeContext } from "../../../../theme/ThemeContext";
import { getCommonCodeCompletionsAsync } from "../../../completion/completionSlice";

interface RecipeItemInputProps {
  isLastOneTop?:any,
  value?: string
  style?: React.CSSProperties
  record: CommonCodeCategory
  colBack: (value: string) => void
  onChange: (value: string) => void
  visibleChange?: (isvisible: boolean) => void
}

export const AuxiliaryInput = ({
  isLastOneTop,
  value,
  style,
  record,
  colBack,
  onChange,
  visibleChange = () => {},
}: RecipeItemInputProps &
  Pick<InputProps, "onBlur" | "onPressEnter">): ReactElement => {
  const inputRef = useRef<any>();

  const theme = useContext(ThemeContext);

  const dispatch = useDispatch<RootDispatch>();

  const [isVisible, setIsVisible] = useState(false);

  const [oralCase, setOralCase] = useState<any>([]);

  const [clickColor, setClickColor] = useState<any>("");

  const overlay = (
    <div
      style={{
        padding: "0 10px",
        maxHeight: "240px",
        overflowY: "scroll",
        background: "#fff",
        boxShadow: "1px 1px 5px 2px #ccc",
      }}
    >
      <Col style={{ lineHeight: "34px" }}>
        <Button
          type="text"
          style={{ color: theme.c1, float: "right" }}
          onClick={() => {
            setIsVisible(false);
          }}
        >
          关闭
        </Button>
      </Col>
      <Divider />
      {oralCase?.map((v: commonCode, i: number) => {
        return (
          <Col key={i} style={{ padding: "5 10px", lineHeight: "26px" }}>
            <span style={{ fontWeight: "bold" }}>{v.categoryName}：</span>
            {v.codeName?.split("  ").map((item, index) => {
              return (
                <span
                  id={v.categoryName+"_"+item}
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={(e: any) => {
                    document?.getElementById(v.categoryName+"_"+item)?.setAttribute("style","color:#027aff;cursor:pointer");
                    let endWord = " ";//默认不给逗号
                    if(`${item}`.indexOf("（")>=0){
                      endWord = "，"
                    }
                    colBack(
                      `${item}${item === "" || item.endsWith("。") ? "" : endWord}`
                    );
                    setIsVisible(true);
                    inputRef.current!.focus({
                      cursor: "end",
                    });
                  }}
                >
                  {item}
                  {item === "" || item.endsWith("。") ? "" : "  "}
                </span>
              );
            })}
          </Col>
        );
      })}
    </div>
  );

  useEffect(() => {
    if (isVisible) {
      dispatch(
        getCommonCodeCompletionsAsync({
          category: record,
          keyword: "",
        })
      )
        .then(unwrapResult)
        .then((v) => {
          setOralCase(v.records);
        });
    }
    visibleChange(isVisible);
  }, [isVisible]);

  useEffect(() => {
    return () => {
      setOralCase([]);
    };
  }, []);

  return (
    <Dropdown
      overlay={overlay}
      visible={isVisible}
      overlayStyle={{ width: "100%" }}
      placement={ isLastOneTop ? 'topCenter' :"bottomCenter"}
      align={{
        overflow: {
          adjustX: false,
          adjustY: false,
        },
      }}
      getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
      onVisibleChange={(v) => {
        if (!v) {
          setIsVisible(v);
        }
      }}
    >
      <Input.TextArea
        ref={inputRef}
        placeholder="ctrl辅助输入"
        autoSize
        value={value}
        style={style}
        onFocus={() => {
          setIsVisible(true);
        }}
        onClick={(e: any) => {
          if (!e.target.value) {
            setIsVisible(true);
          }
        }}
        onChange={(e) => {
          onChange(e.target.value);
          setIsVisible(false);
        }}
        onKeyDown={(event: any) => {
          if (event.keyCode === 17) {
            setIsVisible(!isVisible);
          }
          if (event.keyCode === 13) {
            onChange(event.target.value);
          }
        }}
      />
    </Dropdown>
  );
};
