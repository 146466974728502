/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:43:15
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-05 15:15:08
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-04 10:50:48
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../../app/store'
import { sendAsync } from '../../../../app/applicationSlice'
import * as DT from '../../../../services/doctorManagement'

// 列表
export const getDoctorManagementList = createAsyncThunk<
  void,
  DT.DoctorManagementListPrams,
  RootThunkApi<void>
>('clinic/getDoctorManagementList', async (params, api) => {
  return sendAsync(DT.doctorManagementList(params), api)
})

// 详情
export const getDoctorManagementDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getDoctorManagementDetail', async (params, api) => {
  return sendAsync(DT.doctorManagementDetail(params), api)
})

// 保存
export const getDoctorManagementEditSubmit = createAsyncThunk<
  void,
  DT.DoctorManagementEditPrams,
  RootThunkApi<void>
>('clinic/getDoctorManagementEditSubmit', async (params, api) => {
  return sendAsync(DT.doctorManagementEditSubmit(params), api)
})

export const getDoctorManagementUpload = createAsyncThunk<
  void,
  DT.DoctorManagementUploadPrams,
  RootThunkApi<void>
>('clinic/getDoctorManagementUpload', async (params, api) => {
  return sendAsync(DT.doctorManagementUpload(params), api)
})
