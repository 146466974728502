/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-17 09:55:18
 * @LastEditors: sj
 * @LastEditTime: 2022-12-08 09:15:44
 */
/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 16:15:53
 * @LastEditors: sj
 * @LastEditTime: 2022-07-13 19:59:10
 */
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Modal, Space, TableColumnType } from 'antd'
import moment from 'moment'
import React, { } from 'react'
import { EditableColumnType } from '../../../../../compnents/list/EditableList'
import { getOutsideStatus } from '../../../../../models/toothOutside'
import { ToothComponents } from '../../../../treatment/editor/modals/toothComponents'
import styles from './outside.module.css'

type Action = 'detail' | 'delete' | 'edit'

export const Columns = (
  startIndex: number,
  onAction: (action: Action, t: any) => void
): TableColumnType<any>[] => {
  // const [a, sets] = useState(false)
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '4rem',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
      fixed: 'left',
    },
    {
      title: '加工单位',
      dataIndex: 'processProvider',
      align: 'center',
      fixed: 'left',
      width: '10rem',
      ellipsis: true,
    },
    {
      title: '取件人',
      dataIndex: 'addresseeName',
      align: 'center',
      width: '8rem',
      ellipsis: true,
    },
    {
      title: '送件日期',
      dataIndex: 'processStartTime',
      align: 'center',
      width: '8rem',
      render: (t) => {
        return t ? moment(t).format('YYYY-MM-DD') : ''
      },
    },
    {
      title: '回件日期',
      dataIndex: 'processEndTime',
      align: 'center',
      width: '8rem',
      render: (t) => {
        return t ? moment(t).format('YYYY-MM-DD') : ''
      },
    },
    {
      title: '医生',
      dataIndex: 'doctorName',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '加工单号',
      dataIndex: 'orderNo',
      align: 'center',
      width: '10rem',
      ellipsis: true,
    },
    {
      title: '加工颗数',
      dataIndex: 'processNumber',
      align: 'center',
      width: '10rem',
      ellipsis: true,
    },
    {
      title: '加工厂价',
      dataIndex: 'factoryPrice',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (t) => (t > 0 ? t : ''),
    },
    {
      title: '快递公司',
      dataIndex: 'logisticsOmpany',
      align: 'center',
      width: '10rem',
      ellipsis: true,
    },
    {
      title: '快递单号',
      dataIndex: 'trackingNo',
      align: 'center',
      width: '10rem',
      ellipsis: true,
    },
    {
      title: '状态',
      dataIndex: 'appointmentState',
      align: 'center',
      fixed: 'right',
      width: '5rem',
      render: (_, t) => getOutsideStatus(Number(t?.status)),
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      fixed: 'right',
      width: '10rem',
      render: function action(_, t) {
        return (
          <Space>
            <Button
              type='link'
              size='small'
              onClick={() => {
                onAction('detail', t)
              }}
            >
              详情
            </Button>
            <Button
              type='link'
              size='small'
              onClick={() => {
                onAction('edit', t)
              }}
            >
              编辑
            </Button>
            <Button
              type='link'
              size='small'
              onClick={() => {
                Modal.confirm({
                  centered: true,
                  title: '删除',
                  width: 600,
                  content: (
                    <div>
                      <ExclamationCircleFilled
                        style={{ color: '#FFAE55', fontSize: 18 }}
                      />
                      &nbsp;是否删除这个加工单?
                    </div>
                  ),
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    onAction('delete', t)
                  },
                })
              }}
            >
              删除
            </Button>
          </Space>
        )
      },
    },
  ]
}

export const RelevanceDisposeColumns = (
  type: number,
  onSave?: (m: any, value: any) => void
): EditableColumnType<any>[] => {
  let columns: EditableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '4rem',
      render: (_1, _2, i) => `${i + 1}`,
    },
    {
      title: '就诊信息',
      dataIndex: 'disease',
      align: 'center',
      width: '15rem',
      render: function action(_, t) {
        return <div className={styles.flex}>{t.treatmentTime} {t.disease}</div>
      }
    },
    {
      title: '牙位',
      dataIndex: 'sex',
      align: 'center',
      width: '15rem',
      render: function tooth(_, t) {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 8

          }}>
            <ToothComponents data={t.toothStruct} isEdit={false} />
          </div>
          // 3La-B,5,6@3D-P,5O@--@2L
        )
      },
    },
    {
      title: '处置名称',
      dataIndex: 'name',
      align: 'center',
      width: '12rem',
      ellipsis: true
    },
    {
      title: '单价',
      dataIndex: 'drugAmount',
      align: 'center',
      width: '6rem',
      render: (_, t) => {
        return t?.drugAmount?.toFixed(2)
      },
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '3rem',
    },
    {
      title: '已关联数量',
      dataIndex: 'associatedCount',
      align: 'center',
      width: '6rem',
    },
    {
      title: '关联数量',
      dataIndex: 'bcglsl',
      align: 'center',
      editable: !!onSave,
      onSave: onSave,
      width: '6rem',
    },
    {
      title: '总金额',
      dataIndex: 'furtherConsideration',
      align: 'center',
      width: '7rem',
      render: (_, t) => {
        return (t?.drugCount * t?.drugAmount)?.toFixed(2)
      },
    },
  ]
  if (type == 1) {
    columns = columns.filter(v => v.title != '序号')
  } else if (type == 2) {
    columns = columns.filter(v => v.title != '牙位' && v.title != '已关联数量')
  }
  return columns
}

export const DetailModalColumns = (): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '4rem',
      render: (_1, _2, i) => `${i + 1}`,
    },
    {
      title: '牙位',
      dataIndex: 'sex',
      align: 'center',
      width: '15rem',
      render: function tooth(_, t) {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 8
          }}>
            <ToothComponents data={t.processItemTooth} isEdit={false} />
          </div>
          // 3La-B,5,6@3D-P,5O@--@2L
        )
      },
    },
    {
      title: '加工内容',
      dataIndex: 'processItemDisease',
      align: 'center',
    },
    {
      title: '色号',
      dataIndex: 'processItemColorNo',
      align: 'center',
    },
  ]
}