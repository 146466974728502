/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-09-17 11:32:30
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-04 14:35:57
 */
import { Button, Col } from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { selectTwoMenus,selectMenus } from '../../layouts/layoutSlice'
import { ThemeContext } from '../../theme/ThemeContext'
import { MembersManagementPage } from '../membersManagement/membersManagementPage'
import { OutPatientList } from '../outpatientList/outpatientList'
import { Patients } from '../patient/list/Patients'
import { PatientLable } from '../patient/patientLable/patientlable'

export const OutpatientStatistics = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const exRef = useRef<any>(null)

  const location: any = useLocation()

  const [selectedTab, setSelectedTab] = useState('0')

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  useEffect(() => {
    setSelectedTab(location?.state?.station || storageParams.state)
  }, [location?.state])

 const twoMenus = useSelector(selectTwoMenus)

  const menuList = twoMenus?.filter((v) => {
    return v?.name == '患者管理'
  })?.[0]



  const getKeys = (i: string) => {
    switch (i) {
      case '患者管理':
        return '0'
      case '患者标签管理':
        return '1'
      case '会员管理':
        return '2'
      case '门诊日志':
        return '3'
      default:
        return '-'
    }
  }

  return (
    <Col
      style={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      <TabBar
        style={{ margin: '10px 20px' }}
        activeKey={selectedTab}
        destroyInactiveTabPane
        onChange={(v) => setSelectedTab(v)}
      >
        {menuList?.subMenus?.map((v: any) => {
          return (
            <>
              <TabPane key={getKeys(v?.name)} tab={v?.name}>
                {v?.name == '患者管理' ? (
                  <Patients state={selectedTab} />
                ) : v?.name == '患者标签管理' ? (
                  <PatientLable state={selectedTab} />
                ) : v?.name == '会员管理' ? (
                  <MembersManagementPage state={selectedTab} />
                ) : v?.name == '门诊日志' ? (
                  <Col
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: theme.pn,
                      borderRadius: 10,
                      margin: '10px 0',
                      height: 'calc(100% - 10px)',
                    }}
                  >
                    <Col
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: theme.pn,
                        borderRadius: 10,
                        margin: '10px 0',
                        height: 'calc(100% - 10px)',
                      }}
                    >
                      <OutPatientList ref={exRef} parentId={v?.id} />
                    </Col>
                  </Col>
                )  : (
                  <></>
                )}
              </TabPane>
            </>
          )
        })}
        {/* <TabPane key='0' tab='患者管理'>
          <Patients state={selectedTab} />
        </TabPane>
        <TabPane key='1' tab='患者标签管理'>
          <PatientLable state={selectedTab} />
        </TabPane>
        <TabPane key='2' tab='会员管理'>
          <MembersManagementPage state={selectedTab} />
        </TabPane>
        <TabPane key='3' tab='门诊日志' style={{ height: '100%' }}>
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.pn,
              borderRadius: 10,
              margin: '10px 0',
              height: 'calc(100% - 10px)',
            }}
          >
            <OutPatientList ref={exRef} />
          </Col>
        </TabPane> */}
      </TabBar>
    </Col>
  )
}
