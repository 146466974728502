import { Button, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import { CategoryName } from '../../models/category'
import styles from './PurchaseAcceptanceList.module.css'
import { RootDispatch } from '../../app/store'
import { PrintOutlined } from '../../compnents/icons/Icons'
import { Columns } from './column'
import { DateRangeType, getDateRange } from '../../compnents/form/DateSelect'
import {
  generateAcceptancePrintInfoAsync,
  getPurchaseAcceptanceExport,
  purchaseAcceptanceListAsync,
} from './PurchaseAcceptanceSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { traceRoute } from '../../layouts/layoutSlice'
import { PurchaseAcceptanceQuery } from './Query'
import { PrintBody, PurchaseAcceptancePrintBody } from '../../models/PurchaseAcceptancePrint'
import { selectTenantName, selectUserName } from '../../app/applicationSlice'

export const PurchaseAcceptanceList = () => {
  const location: any = useLocation()

  const tenantName = useSelector(selectTenantName)

  const userName = useSelector(selectUserName)

  const dispatch = useDispatch<RootDispatch>()

  const [queries, setQueries] = useState<any>({
    size: 10,
    current: 1,
  })

  const [head, tail] = getDateRange(DateRangeType.ThisMonth)

  const [data, setData] = useState<any>()

  const [total, setTotal] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  useEffect(() => {
    queries.current &&
      sessionStorage.setItem(location.pathname, JSON.stringify({ ...queries }))
  }, [queries])

  useEffect(() => {
    if (queries?.timeHead && queries?.timeTail) {
      setPageLoading(true)
      dispatch(purchaseAcceptanceListAsync({ ...queries }))
        .then(unwrapResult)
        .then((v: any) => {
          setData(v?.records)
          setTotal(v?.total)
          setPageLoading(false)
        })
    }
  }, [queries])

  const outCluedExport = () => {
    dispatch(getPurchaseAcceptanceExport({ ...queries }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '采购验收记录.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          (navigator as any).msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const dataMainList = {
    tenantName: tenantName?.split('-')?.[0],
    userName: userName,
    dataList: data,
  }

  const content = PurchaseAcceptancePrintBody(dataMainList)

  return (
    <div className={styles.PurchaseAcceptanceList}>
      <NavBar
        style={{ margin: '0px 20px' }}
        where={[
          '库房流水查询',
          CategoryName(location.state.catgetory) as string,
        ]}
        backtrace={{
          name: '库房业务',
          path: '/storage/station',
          state: {
            station: '3',
          },
          routeName: '库房工作站',
        }}
        buttons={
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Button
                style={{
                  border: 'none',
                  background: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                }}
                size='small'
                // loading={printLoading}
                onClick={() => {
                  const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                  page.document.write(content) // 写入打印页面的内容
                  page.print() // 打印
                  page?.close()
                }}
              >
                <PrintOutlined
                  style={{
                    fill: '#666',
                    width: '18px',
                    height: '18px',
                    marginRight: '8px',
                  }}
                />
                打印
              </Button>
            </div>
          </>
        }
      />
      <div className={styles.content}>
        <PurchaseAcceptanceQuery
          outCluedExport={() => {
            outCluedExport()
          }}
          onValueChange={(v) => {
            setQueries({ ...queries, ...v })
          }}
        />
        <EditableList
          className={styles.table}
          loading={pageLoading}
          columns={Columns(queries.size * ((queries.current || 1) - 1))}
          rowKey={(t: any) => t.id}
          page={{
            items: data,
            current: queries.current,
            size: queries.size,
            total: total,
          }}
          onRow={(r) => ({
            onDoubleClick: () =>
              dispatch(
                traceRoute({
                  name: '采购验收记录详情',
                  path: '/purchaseAcceptanceDetail',
                  query: `id=${r.id}`,
                })
              ),
          })}
          onChangePage={(current, size) => {
            setQueries({ ...queries, current, size })
          }}
        />
      </div>
    </div>
  )
}
