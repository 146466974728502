import { Col, Tabs } from "antd";
import React, { ReactElement, useState } from "react";
import {} from "antd";
import { TabBar } from "../../compnents/widgets/TabBar";
import { Pitest } from "./pitest/pitestover/pitest";
import { NoPitest } from "./pitest/nopitest/nopitest";
import styles from "./nurse.module.css";
const { TabPane } = Tabs;
export const SkinTest = (): ReactElement => {
  const [value, setValue] = useState<any>();
  return (
    <>
      <Col className={styles.container}>
        <TabBar
          onChange={(e) => {
            setValue(e)
          }}
        >
          <TabPane key="0" tab="皮试执行">
            <NoPitest value={value}/>
          </TabPane>
          <TabPane key="1" tab="皮试完成">
            <Pitest value={value}/>
          </TabPane>
        </TabBar>
      </Col>
    </>
  );
};
