/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-07 13:37:05
 * @LastEditors: K
 * @LastEditTime: 2022-07-25 16:40:47
 */
import moment from "moment";
import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectInsuranceArray, selectUserName, selectUserId, selectTenantAddress, selectTenantAreaCd, selectTenantName, selectTenantCategory } from "../../app/applicationSlice";
import { RootDispatch } from "../../app/store";
import { EditableList } from "../../compnents/list/EditableList";
import { selectInsuranceItem, setInsurance } from "../../layouts/insuranceSlice";
import { DateTimeFormatSimpleOnlyOne } from "../../models/datetime";
import { ClinicInfoColumus, CostInfoColumus, DiagnoseInfoColumus } from "./columus";
import styles from './selfPayUpload.module.css'
import { serverType } from "../../services/commodity";
import { unwrapResult } from "@reduxjs/toolkit";
import { strParse, medicalSendHttp } from "../../utils/MedicalUtils";
import { costInfoAsync, clinicInfoAsync, diagnoseInfoAsync } from "./selfPayUploadSlice";


interface InfoQueryProps {
  type?: number
}

interface InfoQueryResponse {
  total: number
  size: number
  current: number
  records: []
}

export interface InfoQueryRefProps {
  reload: () => void
}

const InfoQuery = ({
  type
}: InfoQueryProps, ref: React.Ref<InfoQueryRefProps>): ReactElement => {

  const dispatch = useDispatch<RootDispatch>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const baseData = useSelector(selectInsuranceItem)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])


  const [params, setParams] = useState({
    pageNum: 1,
    pageSize: 10
  })

  const [total, setTotal] = useState(0)

  const [page, setPage] = useState<any>([])

  useEffect(() => {
    if (type === 1) {
      getCostInfo('requestString')
    } else if (type === 2) {
      getclinicInfo('requestString')
    } else if (type === 3) {
      getdiagnoseInfo('requestString')
    }
  }, [type, params])

  // 自费病人就医费用明细查询4207
  const getCostInfo = (
    serverType: serverType,
    responseString?: string,
  ) => {
    setPageLoading(true)
    dispatch(costInfoAsync(
      {
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString
          },
          ...params
        }
      }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
          {
            trade_args: [
              data.infno,
              data.url,
              data,
              baseData?.countryHospitalNb,
              baseData?.secretToken,
            ],
            message: '自费病人就医费用明细查询',
          },
          (call: any, err) => {
            !err && getCostInfo('response', JSON.stringify(call.netmessage))
          }
        )
      } else {
        getData(res)
      }

    })
      .finally(() => {
        setPageLoading(false)
    })
  }

  // 自费病人就医就诊信息查询4208
  const getclinicInfo = (
    serverType: serverType,
    responseString?: string,
  ) => {
    setPageLoading(true)
    dispatch(clinicInfoAsync(
      {
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString
          },
          ...params
        }
      }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
          {
            trade_args: [
              data.infno,
              data.url,
              data,
              baseData?.countryHospitalNb,
              baseData?.secretToken,
            ],
            message: '自费病人就医就诊信息查询',
          },
          (call: any, err) => {
            !err && getclinicInfo('response', JSON.stringify(call.netmessage))
          }
        )
      } else {
        getData(res)
      }

    })
      .finally(() => {
        setPageLoading(false)
    })
  }

  // 自费病人就医诊断信息查询4209
  const getdiagnoseInfo = (
    serverType: serverType,
    responseString?: string,
  ) => {
    setPageLoading(true)
    dispatch(diagnoseInfoAsync(
      {
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString
          },
          ...params
        }
      }
    )).then(unwrapResult).then(res => {
      if (serverType === 'requestString') {
        const data = strParse(res)
        medicalSendHttp(
          {
            trade_args: [
              data.infno,
              data.url,
              data,
              baseData?.countryHospitalNb,
              baseData?.secretToken,
            ],
            message: '自费病人就医诊断信息查询',
          },
          (call: any, err) => {
            !err && getdiagnoseInfo('response', JSON.stringify(call.netmessage))
          }
        )
      } else {
        getData(res)
      }

    })
      .finally(() => {
        setPageLoading(false)
    })
  }

  const getData = (res: any) => {
    setTotal(res.total)
    setPage(res.records)
  }

  const columus = (type?: number) => {
    const index = params.pageSize * (params.pageNum - 1)
    switch (type) {
      case 1:
        return CostInfoColumus(index);
      case 2:
        return ClinicInfoColumus(index);
      case 3:
        return DiagnoseInfoColumus(index);
      default:
        return []
    }
  }
  // useImperativeHandle(ref, () => ({
  //   reload: () => {
  //   },
  // }))
  return (
    <div className={styles.content}>
      <EditableList
        page={{
          items: page,
          current: params.pageNum,
          size: params.pageSize,
          total: total
        }}
        loading={pageLoading}
        rowKey={(_, i) => `${i}`}
        columns={columus(type)}
        onChangePage={(pageNum, pageSize) =>
          setParams({
            ...params,
            pageNum,
            pageSize: pageSize ?? 10,
          })
        }>

      </EditableList>
    </div>
  )
}

export const InfoQueryRef = forwardRef(InfoQuery)