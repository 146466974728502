/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-22 17:11:05
 */
import React from 'react'
import { EditableColumnType } from '../../compnents/list/EditableList'

export const Columns = (
  onclick: (text: string, t: any) => void,
  startIndex: number
): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      width: '10%',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '物资信息',
      align: 'left',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: function Element(_, record) {
        return (
          <div style={{ height: '52px' }}>
            <p
              style={{
                padding: '0',
                height: '16px',
                marginBottom: '7px',
                fontSize: '16px',
                fontWeight: 'bolder',
              }}
            >
              {record.name}
            </p>
            <p
              style={{
                padding: '0',
                margin: '0',
                height: '16px',
                color: '#999999',
                fontSize: '14px',
              }}
            >
              {record.tenantSupplierName} / {record.packSpec} /{' '}
              {record.doseCategory}
            </p>
          </div>
        )
      },
    },
    {
      title: '库存下限值',
      align: 'center',
      width: '14rem',
      dataIndex: 'inventoryAlert',
      ellipsis: true,
      editable: true,
      onSave: (prev, next) => {
        onclick('submit', { prev, next })
      },
    },
  ]
}
