/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-05-19 14:28:49
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-06 11:08:06
 */
import React, { useEffect, useRef, useState } from 'react'
import { EditableList } from '../../compnents/list/EditableList'
import styles from './failureDirectory.module.css'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { Query } from './Query'
import { Columns } from './column'
import { MedicalModal } from '../commodity/medical/MedicalModal'
import { getFailureDirectoryPage } from './failureDirectorySlice'
import { MedicalItem } from '../../models/material'
import { saveCodeMedicalListAsync } from '../commodity/commoditySlice'
import { selectInsuranceArray } from '../../app/applicationSlice'
import { notification } from 'antd'

export const FailureDirectory = () => {
  const location = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const insuranceArray = useSelector(selectInsuranceArray)

  const [componentItem, setComponentItem] = useState<any>()

  const [medicalModalVisible, setMedicalModalVisible] = useState(false)

  const [pageLoading, setPageLoading] = useState(false)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 1,
    items: [],
  })

  const getTableList = () => {
    //获取表格数据
    setPageLoading(true)
    dispatch(
      getFailureDirectoryPage({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        setPage({
          total: v?.endDateMappingBody?.total,
          items: v?.endDateMappingBody?.records,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (params) {
      getTableList()
    }
  }, [params])

  // 匹配医保目录
  const saveMedicalCode = (item: MedicalItem) => {
    dispatch(
      saveCodeMedicalListAsync({
        billing_category: componentItem.billingCategory,
        hilist_code: item?.hiListCode,
        insuranceCode: insuranceArray[0].insuranceCode,
        is_reimbursement: item?.isCouldBx,
        local_item_id: String(componentItem?.id),
        limitId: item.limitId,
        rid:item?.rid,
        proviceHilistCode:item?.proviceHilistCode

      })
    )
      .then(unwrapResult)
      .then(() => {
        getTableList()
        notification.success({
          message: '匹配成功',
        })
        setParams({ ...params, current: 1 })
      })
  }

  return (
    <div className={styles.wrap}>
      <Query
        onSubmit={(values) => {
          setParams({
            ...params,
            ...values,
            current: 1,
          })
        }}
      />
      <EditableList
        className={styles.table}
        style={{
          height: 'calc(100% - 160px)',
        }}
        loading={pageLoading}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns(
          (params.size || 10) * ((params.current || 1) - 1),
          (action, t) => {
            switch (action) {
              case 'resetMatching':
                // if (t.approveFlg !== '1') {
                //   setComponentItem({
                //     ...t,
                //     name: t?.hilistName,
                //     id: t?.localItemId,
                //   })
                //   setMedicalModalVisible(true)
                // } else {
                //   notification.info({
                //     message: '不可修改已上传的匹配记录!',
                //   })
                // }
                setComponentItem({
                  ...t,
                  name: t?.hilistName,
                  id: t?.localItemId,
                })
                setMedicalModalVisible(true)
                break
            }
          }
        )}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
      />
      <MedicalModal
        visible={medicalModalVisible}
        onOk={() => {
          setMedicalModalVisible(false)
        }}
        onCancel={() => {
          setMedicalModalVisible(false)
        }}
        onRecord={(item) => {
          setMedicalModalVisible(false)
          saveMedicalCode(item)
        }}
        componentItem={{
          ...componentItem,
          insuranceCode: insuranceArray[0].insuranceCode,
        }}
      />
    </div>
  )
}
