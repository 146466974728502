import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { Request } from './request'

// 获取-首页-头部数据统计
export const getHomeHeaderCountFn = (): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/count`,
    method: 'GET',
  }
}
// 获取-首页-左侧数据统计
export interface getHomeLeftCountFnParams {
  appointmentStateA?: number //:0预约中,1已挂号,2已就诊,3已取消,9超时,-1已流失(预约)
  consultDoctorIdWS?: string
  stationCategory?: string
  departmentIdA?: string
  departmentIdF?: string
  doctorIdA?: string
  doctorIdF?: string
  endTimeA?: string
  endTimeF?: string
  endTimeP?: string
  endTimePre?: string
  endTimeV?: string
  endTimeWS?: string
  flag?: string
  nameA?: string
  nameF?: string
  namePre?: string
  nameV?: string
  nameWS?: string
  receiveTagWS?: string
  registrationDoctorIdWS?: string
  registrationStateWS?: string
  startTimeA?: string
  startTimeF?: string
  startTimeP?: string
  startTimePre?: string
  startTimeV?: string
  startTimeWS?: string
  stateF?: string
  statePre?: string
  stateV?: string
  treatmentDoctorIdWS?: string
  visitFlagV?: string
  visitUserIdV?: string
}
export const getHomeLeftCountFn = (
  params: getHomeLeftCountFnParams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/oral/count`,
    method: 'GET',
    params,
  }
}
// 获取-首页-工作站列表
export interface getWorkStationFnParams {
  current: number
  size: number
  startTimeWS?: string
  endTimeWS?: string
  nameWS?: string
  receiveTagWS?: string
  registrationDoctorIdWS?: string
  registrationStateWS?: string
  stationCategory?: string
  treatmentDoctorIdWS?: string
}
export const getWorkStationFn = (params: getWorkStationFnParams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/oral/workstation`,
    method: 'GET',
    params,
  }
}
// 获取-新增患者-患者分组
export interface getPatientgroupFnPrams {
  current: number
  size: number
}
export const getPatientgroupFn = (params: getPatientgroupFnPrams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientgroup/listGroup`,
    method: 'GET',
    params,
  }
}

// 获取-新增患者-咨询师
export interface getCounselorFnPrams {
  current: number
  size: number
  stationCategory: number //1:主治医生  4:咨询师
}
export const getCounselorFn = (params: getPatientgroupFnPrams): Request => {
  return {
    url: `${API_PREFIX}/blade-user/user/list`,
    method: 'GET',
    params,
  }
}
// 获取-新增患者-保存
export interface getSaveAddPatientFnPrams {
  isPrecheck?: number
  labelIdList?: string[]
  groupId?: string[]
  patient?: {
    address?: string
    ageMonth?: string
    ageYear?: string
    allergyHistory?: string
    attendingDoctor?: string
    attendingDoctorId?: string
    birthday?: string
    provinceCode?: string
    cityCode?: string
    contryCode?: string
    companyName?: string
    counselor?: string
    counselorId?: string
    emailAddress?: string
    emergencyContactName?: string
    emergencyContactPhone?: string
    familyHistory?: string
    height?: string
    idCardNumber?: string
    introducer?: string
    introducerId?: string
    introducerType?: string
    job?: string
    mail?: string
    maritalHistory?: string
    maritalStatus?: string
    menstrualHistory?: string
    mnemonicCode?: string
    name?: string
    pastMedicalHistory?: string
    phone?: string
    qq?: string
    relationship?: string
    relationshipEmergencyContact?: string
    remark?: string
    sex?: string
    smokeFrequency?: string
    source?: string
    star?: string
    status?: string
    tenantId?: string
    tenantPatientCardId?: string
    toothBrushingFrequency?: string
    toothBrushingTime?: string
    toothCleaningHabit?: string
    treatmentHistory?: string
    treatmentItem?: string
    vaccineHistory?: string
    wechat?: string
    weight?: string
  }
  patientInsurance?: {
    birthday?: string
    brdw?: string
    cardInfo?: string
    cardType?: string
    cbxz?: string
    cbxzmc?: string
    certType?: string
    dbbcqflj?: string
    dnqfxljMb?: string
    dnqfxljPt?: string
    dnzhye?: string
    expContent?: string
    fullCardInfo?: string
    gwybz?: string
    insuranceCode?: string
    insuranceRegion?: string
    insurance_start_date?: string
    insurance_stop_date?: string
    lnzhye?: string
    medicalTreatmentType?: string
    memberType?: string
    memberTypemc?: string
    mztclj?: string
    name?: string
    naty?: string
    sex?: string
    tbno?: string
    treatmentStatus?: string
    treatmentStatusmc?: string
    ybPersonalNo?: string
    zhye?: string
  }
}
export const getSaveAddPatientFn = (
  body: getSaveAddPatientFnPrams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/saveOrUpdatePatient`,
    method: 'POST',
    body,
  }
}

// 获取-挂号-医生
export interface getAllDoctorListFnPrams {
  size: number
  state: number
  stationCategory: number
  outpatientDepartmentId?: string
}
export const getAllDoctorListFn = (
  params: getAllDoctorListFnPrams
): Request => {
  return {
    url: `${API_PREFIX}/blade-user/user/listWithBusyInfo`,
    method: 'GET',
    params,
  }
}

// 获取-首页-收费-列表
export interface getChargePageFnPrams {
  current: number
  size: number
  insuranceStatus?: string
  param?: string
  patientPayState?: string
  registrationTimeHead?: string
  registrationTimeTail?: string
  treatmentDepartmentId?: string
  treatmentDoctorId?: string
  patientId?: string
}
export const getChargePageFn = (params: getChargePageFnPrams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/getPatientPayList`,
    method: 'GET',
    params,
  }
}
// 获取-首页-收费-列表
export const getChargePageTotalCountFn = (
  params: getChargePageFnPrams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/getPatientPaySum`,
    method: 'GET',
    params,
  }
}
// 获取-首页-收费-批量预结算
export interface getBatchChargeFnPrams {
  patientId: string
}
export const getBatchChargeFn = (parmas: getBatchChargeFnPrams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/batchPrePay`,
    method: 'POST',
    params: {
      patientId: parmas.patientId,
    },
  }
}
// 获取-首页-收费-划价信息
export const getCutPriceDetailFn = (): Request => {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/searchOneKeypricingItems`,
    method: 'GET',
  }
}
// 获取-首页-收费-划价保存
export interface getCutPriceAddFnParams {
  medicalRecordType: number
  oneKeyPricingBean: any[]
  registrationId: string
}
export const getCutPriceAddFn = (body: getCutPriceAddFnParams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/oneKeyPricing`,
    method: 'POST',
    body,
  }
}
// 获取-首页-患者生日-列表
export interface getPatientPageFnPrams {
  current: number
  size: number
  startTime: string
  endTime: string
}
export const getPatientPageFn = (params: getPatientPageFnPrams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/getPatientBirthday`,
    method: 'GET',
    params,
  }
}
// 获取-首页-患者生日-短信条数
export const getMessageCountFn = (): Request => {
  return {
    url: `${API_PREFIX}/blade-user/smsTemplate/getSmsVaildNum`,
    method: 'GET',
  }
}
// 获取-首页-患者生日-发送短信弹窗-短信模板
export const getMessageTemplateFn = (): Request => {
  return {
    url: `${API_PREFIX}/blade-user/smsTemplate/getSmsTemplateList`,
    method: 'GET',
  }
}
// 获取-首页-患者生日-发送短信弹窗-签名列表
export const getSignatureFn = (): Request => {
  return {
    url: `${API_PREFIX}/blade-user/smsTemplate/getSignList`,
    method: 'GET',
  }
}
// 获取-首页-挂号-挂号详情

export interface getRegistrationDetailFnParams {
  appointmentId?: string //预约 ID
  patientId?: string //患者 ID
  registrationId?: string //挂号 ID  三选一
  removePatientInsurance?: any
}
export const getRegistrationDetailFn = (
  params: getRegistrationDetailFnParams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/oral/changetreatment`,
    method: 'GET',
    params,
  }
}
// 获取-首页-转诊-保存
export interface getTransferTreatmentFnParams {
  departmentId: string
  departmentName: string
  doctorId: string
  doctorName: string
  registrationId: string
}
export const getTransferTreatmentFn = (
  body: getTransferTreatmentFnParams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/registration/oral/changetreatmentexecute`,
    method: 'POST',
    body,
  }
}
// 获取-首页-随访-获取列表
export interface getFollowUpListFnParams {
  patientId: string
  id?: string
  keyWord?: any
  startTime?: any
  endTime?: any
}
export const getFollowUpListFn = (params: getFollowUpListFnParams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/searchDisposal`,
    method: 'GET',
    params,
  }
}
// 获取-首页-随访-获取列表明细
export interface getFollowUpListDetailFnParams {
  recipeId: string
}
export const getFollowUpListDetailFn = (
  params: getFollowUpListDetailFnParams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/searchDisposalDetail`,
    method: 'GET',
    params,
  }
}

export interface SmsTemplateParams {
  id?: string
  signName: string
}
export const smsTemplate = (body: SmsTemplateParams): Request => {
  return {
    url: `${API_PREFIX}/blade-user/smsTemplate/saveOrUpdate`,
    method: 'POST',
    body,
  }
}

export const signDeleteById = (id: string): Request => {
  return {
    url: `${API_PREFIX}/blade-user/smsTemplate/signDeleteById`,
    method: 'POST',
    params: {
      id,
    },
  }
}

export interface SendSmsPatientParams {
  patientIds?: string[]
  sendTime?: string
  sign: string
  smsContent: string
  phone?: string
  patientName?: string
  templateType?: number
  id?: string
}
export const sendSmsPatient = (body: SendSmsPatientParams): Request => {
  return {
    url: `${API_PREFIX}/blade-user/user/sms/sendSmsPatient`,
    method: 'POST',
    body,
  }
}

export const getPatientPhone = (id: string): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/getPatientPhone`,
    method: 'GET',
    params: {
      id,
    },
  }
}
