import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space } from 'antd'
import 'moment/locale/zh-cn'
import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../../compnents/form/DateSelect'
import { PrintOutlined } from '../../../compnents/icons/Icons'
import { changeWaterParams } from '../../../services/changewater'
import {
  getpeopleList,
  selectPeopleList,
} from '../../nurse/pitest/pitestover/pitestSlice'
import {selectInsuranceArray} from "../../../app/applicationSlice";

export const ChangeWaterQuerys = (props: {
  onValueChange: (queries: changeWaterParams) => void
  print: () => void
  outClued: () => void
}): ReactElement => {
  const dispatch = useDispatch()
  const { Option } = Select
  const [form] = Form.useForm()
  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
      insuranceCode:'2'
    })
    form.submit()
  }, [])
  const insuranceArray = useSelector(selectInsuranceArray)
  
  const peopleList = useSelector(selectPeopleList)
  useEffect(() => {
    dispatch(getpeopleList(10000))
  }, [dispatch])

  return (
    <Form
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      form={form}
      onFinish={(values) => {
        props.onValueChange(values)
      }}
    >
      <Row
        style={{ marginBottom: 10, width: '100%' }}
        align='middle'
        justify='space-between'
      >
        <Space>
          <Form.Item name='name' noStyle>
            <Input
              autoComplete='off'
              placeholder='请输入姓名/手机号'
              style={{ width: 200 }}
              onChange={() => form.submit()}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          { <Form.Item name="insuranceCode" noStyle>
            <Select
              showSearch
              style={{ width: "200px" }}
              placeholder="结算险种"
              allowClear
              onChange={() => form.submit()}
            >
              <Select.Option value="2">全部</Select.Option>
              <Select.Option value="0">自费</Select.Option>
              {insuranceArray.map((v) => (
                  <Select.Option value='1' key={v.id}>
                    {v.insuranceName}
                  </Select.Option>
              ))}
            </Select>
          </Form.Item> }
          <Form.Item name='operateUser' noStyle>
            <Select
              showSearch
              style={{ width: '200px' }}
              placeholder='收款人列表'
              optionFilterProp='children'
              allowClear
              onChange={form.submit}
            >
              {peopleList.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.realname}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <DateSelect
            style={{ width: '8.5rem' }}
            labelPrefix=''
            namePrefix='time'
            options={TJFXTime}
            placeholder='时间'
            initialValue={DateRangeType.Today}
            onChange={() => form.submit()}
            allowClear={false}
          />
          <Button
            type='primary'
            onClick={() => props.onValueChange(form.getFieldsValue())}
          >
            查询
          </Button>
        </Space>
        <Space>
          <Button
            type='text'
            style={{
              color: '#666',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => props.print()}
          >
            <PrintOutlined
              style={{
                fill: '#666',
                width: '18px',
                height: '18px',
                marginRight: '4px',
              }}
            />
            打印
          </Button>
          <Button type='primary' onClick={() => props.outClued()}>
            导出
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
