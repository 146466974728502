/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-03 20:22:17
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-09 15:12:28
 */
import { TableColumnType } from 'antd'
import { useDispatch } from 'react-redux'

export const DrugStaticDetailColumns = (
  startIndex: number
): TableColumnType<any>[] => {
  const dispatch = useDispatch()
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
      width: '3rem',
    },
    {
      title: '患者姓名',
      dataIndex: 'patientName',
      align: 'center',
    },
    {
      title: '产品信息',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
    },
    {
      title: '整存数量',
      dataIndex: 'wholeCount',
      align: 'center',
      key: 'wholeCount',
    },

    {
      title: '拆零数量',
      dataIndex: 'splitCount',
      align: 'center',
      key: 'splitCount',
    },
    {
      title: '整存金额',
      dataIndex: 'wholeRetail',
      align: 'right',
      key: 'wholeRetail',
    },
    {
      title: '拆零金额',
      dataIndex: 'splitRetail',
      align: 'right',
      key: 'splitRetail',
    },
    {
      title: '整存结存数',
      dataIndex: 'invRemainNum',
      align: 'center',
      render: (_, t) => {
        return t?.invRemainNum == -1  ? '':  t?.invRemainNum
      },
    },
    {
      title: '拆零结存数',
      dataIndex: 'invSplitRemainNum',
      align: 'center',
      render: (_, t) => {
        return t?.invSplitRemainNum == -1  ? '':  t?.invSplitRemainNum
      },
    },
    {
      title: '批号',
      dataIndex: 'batchNo',
      align: 'center'
    },
    {
      title: '发生时间',
      dataIndex: 'createTime',
      align: 'center',
      key: 'createTime',
      render: (_, t) => {
        const createTime = t.createTime.substring(0, 10)
        return createTime
      },
    },
  ]
}
