/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-31 14:23:29
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-29 15:57:04
 */
import { API_PREFIX } from "./constant";
import { Request } from "./request";
export interface StockMaintenanceParams {
  id?: number;
  current?: number;
  size?: number;
  total?: number;
  name?: string;
  timeHead?: string;
  timeTail?: string;
  pages?: number;
}

export interface StockMaintenanceBody {
  createTime?: string
  createUser?: string
  id?: number
  isDeleted?: number
  mainUserId?: number
  mainUserName?: string
  maintainCategory?: number
  maintainTime?: string
  no?: string
  remark?: string
  sort?: number
  state?: number
  status?: number
  storehouseId?: number
  storehouseName?: string
  tenantId?: number
  updateTime?: string
  updateUser?: number
}

export function getStockMaintenance(params: StockMaintenanceParams): Request {
  return {
    // url: `${API_PREFIX}/page`,
    url: `${API_PREFIX}/blade-store/maintain/page`,
    method: "GET",
    params,
  };
}

export function getStockMaintenanceDetail(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintain/detail`,
    method: "GET",
    params: {
      id
    }
  }
}

export function removeStockMintenance(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintainitem/removeall`,
    method: "POST",
    params: {
      ids: id
    }
  }
}

export function maintenanceCompleted(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintain/updatefinish`,
    method: "POST",
    body: {
      id
    }
  }
}

export function submitStockMaintenance(body: StockMaintenanceBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintain/submit`,
    method: "POST",
    body
  }
}

export interface OneKeyStockMaintenanceParams {
  current?: number
  size?: number
  total?: number
  storehouseId?: any
  maintainCategory:any
}

export function oneKeyStockMaintenanceList(params: StockMaintenanceBody): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/maintainlist`,
    method: 'GET',
    params,
  }
}


export interface OneKeyStockMaintenanceSubmitParams {
  maintainItems?: any
  maintainCategory?: any
  storehouseName?: any
  storehouseId: any
}

export function oneKeyStockMaintenanceSubmit(
  body: OneKeyStockMaintenanceSubmitParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/maintain/oneClickUpdateFinish`,
    method: 'POST',
    body,
  }
}
