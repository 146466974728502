import { Form, Space, Switch, TableColumnType } from "antd";
import React from "react";
import { Dimen } from "../../../models/dimen";
export const InventoryRecordsColumns = (
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      key: "no",
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "商品编码",
      align: "center",
      dataIndex: "no",
      width: Dimen.Coding,
      key: "no",
    },
    {
      title: "产品信息",
      align: "left",
      dataIndex: "producInfo",
      key: "producInfo",
      render: function Element(_, record) {
        return (
          <div style={{ height: "55px" }}>
            <p
              style={{
                height: "28px",
                lineHeight: "36px",
                padding: "0",
                margin: "0",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              {record.name}
            </p>
            <p
              style={{
                height: "28px",
                lineHeight: "24px",
                padding: "0",
                margin: "0",
                color: "#999999",
                fontSize: "14px",
              }}
            >
              {record.tenantSupplierName} / {record.packSpec} /
              {record.doseCategory}
            </p>
          </div>
        );
      },
    },

    {
      title: "账簿数量",
      align: "center",
      dataIndex: "count",
      key: "count",
      render: (_: any, t: any) => {
        return t.realCount - t.count < -1 ? "-" : t.realCount - t.count;
      },
    },
    {
      title: "实际数量",
      align: "center",
      dataIndex: "realCount",
      key: "realCount",
    },
    {
      title: "盘盈盘亏数",
      align: "center",
      dataIndex: "count",
      key: "count",
      render: (_: any, t: any) => {
        return t.count ? parseFloat((t.count as unknown) as string) : 0;
      },
    },

    {
      title: "账簿数量(拆零)",
      align: "center",
      dataIndex: "",
      key: "",
      render: (_: any, t: any) => {
        return t.count
          ? parseFloat(((t.realSplitCount - t.splitCount) as unknown) as string)
          : 0;
      },
    },
    {
      title: "实际数量（拆零）",
      align: "center",
      dataIndex: "",
      key: "",
      render: (_: any, t: any) => {
        return t.realSplitCount
          ? parseFloat((t.realSplitCount as unknown) as string)
          : 0;
      },
    },
    {
      title: "盘盈盘亏（拆零）",
      align: "center",
      dataIndex: "",
      key: "",
      render: (_: any, t: any) => {
        return t.splitCount
          ? parseFloat((t.splitCount as unknown) as string)
          : 0;
      },
    },
    {
      title: "进价",
      align: "right",
      dataIndex: "",
      key: "",
      render: (_: any, t: any) => {
        return t.unitPrice
          ? parseFloat((t.unitPrice as unknown) as string).toFixed(5)
          : 0;
      },
    },
    {
      title: "变动金额(进价)",
      align: "right",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (_: any, t: any) => {
        return t.unitPrice * t.count
          ? parseFloat(((t.unitPrice * t.count) as unknown) as string).toFixed(
              2
            )
          : "";
      },
    },

    {
      title: "盘点时间",
      align: "center",
      dataIndex: "upAccountTime",
      key: "upAccountTime",
    },
  ];
};
