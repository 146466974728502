import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Modal, ModalProps, notification, Row } from "antd";
import { isError } from "lodash";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../app/store";
import { EditableList } from "../../compnents/list/EditableList";
import { LoadingState } from "../../models/common";
import { Material } from "../../models/material";
import { ThemeContext } from "../../theme/ThemeContext";
import { batchDeliver, getMaterialBatchInfo } from "./deliverySlice";

interface ManualDeliveryModalProps {
  material?: Material;
}

export const ManualDeliveryModal = ({
  material,
  onOk,
  ...others
}: ManualDeliveryModalProps & ModalProps): ReactElement => {
  const theme = useContext(ThemeContext);

  const dispatch = useDispatch<RootDispatch>();

  const [materials, setMaterials] = useState<Material[]>([]);

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const [loadingState, setLoadingState] = useState(LoadingState.Idle);

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  useEffect(() => {
    if (material) {
      let remain = material.drugCount;
      dispatch(getMaterialBatchInfo(material))
        .then(unwrapResult)
        .then((ms) =>
          setMaterials(
            ms.map((m) => {
              let drugCount = 0;
              const count =
                material.splitTag === 1
                  ? m.count * m.splitScale + m.splitCount
                  : m.count;
              if (remain > 0) {
                if (remain <= count) {
                  drugCount = remain;
                  remain = 0;
                } else {
                  drugCount = count;
                  remain -= count;
                }
              }
              return { ...m, count, drugCount };
            })
          )
        );
    }
    setSelectedKeys([]);
    setIsErrorVisible(false);
    setLoadingState(LoadingState.Idle);
  }, [material]);
  return (
    <Modal {...others} footer={null} title="按批次发药" width={960}>
      <Row
        style={{
          padding: "0 15px",
          fontSize: "1rem",
          color: theme.tc1,
          marginBottom: 20,
        }}
      >
        <Col span={6} style={{ textAlign: "left" }}>
          商品名称：{material?.name}
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          规格：{material?.spec}
        </Col>
        <Col span={6} style={{ textAlign: "center" }}>
          单位：
          {material?.splitTag ? material?.splitUnit : material?.accountUnit}
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          应发数量：{material?.drugCount}
        </Col>
      </Row>
      <EditableList
        pagination={false}
        dataSource={materials}
        columns={[
          {
            title: "序号",
            key: "no",
            align: "center",
            render: (_1, _2, i) => `${i + 1}`,
            width: "3rem",
          },
          { title: "库房", dataIndex: "storehouseName", align: "center" },
          {
            title: "批次号",
            dataIndex: "batchNo",
            align: "center",
            width: "6rem",
          },
          {
            title: "库存数量",
            dataIndex: "count",
            align: "center",
            width: "10rem",
          },
          {
            title: "发货数量",
            dataIndex: "drugCount",
            align: "center",
            width: "10rem",
            editable: true,
            onSave: (m, values) => {
              const drugCount = parseInt(values.drugCount);
              if (isNaN(drugCount)) {
                return;
              }
              setMaterials(
                materials.map((mt) => {
                  if (m.id === mt.id) {
                    return { ...mt, drugCount };
                  }
                  return mt;
                })
              );
            },
          },
        ]}
        size="small"
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedKeys,
          onChange: (ks) => setSelectedKeys(ks as string[]),
        }}
      />
      <Row justify="end" style={{ margin: "20px 0 0 0" }} align="middle">
        <div
          style={{
            color: theme.e,
            fontSize: ".875rem",
            marginRight: 15,
            display: isErrorVisible ? "inline-block" : "none",
          }}
        >
          勾选的发货数量和总数量不匹配，请再次确认。
        </div>
        <Button
          type="primary"
          disabled={loadingState !== LoadingState.Idle}
          loading={loadingState === LoadingState.Loading}
          onClick={(e) => {
            if (material) {
              const deliveries = selectedKeys
                .map((id) => materials.find((m) => m.id === id))
                .filter((m) => !!m)
                .map((m) => ({
                  inventoryLedgerId: m?.id || "",
                  shipmentsCount: m?.drugCount || 0,
                }));
              if (
                deliveries.length === 0 ||
                deliveries
                  .map((m) => m.shipmentsCount)
                  .reduce((a, b) => a + b) !== material.drugCount
              ) {
                setIsErrorVisible(true);
                return;
              }
              setIsErrorVisible(false);
              setLoadingState(LoadingState.Loading);
              dispatch(
                batchDeliver({
                  recipeItemId: material.id,
                  choseBatchList: deliveries,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: '操作成功',
                  })
                  setLoadingState(LoadingState.Idle)
                  onOk && onOk(e)
                })
                .catch(() => {
                  setLoadingState(LoadingState.Idle)
                })
            }
          }}
        >
          一键发药
        </Button>
      </Row>
    </Modal>
  );
};
