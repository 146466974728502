import {  Gender, registrationCategory } from "./list";
import { RecipeCategory } from "./recipe";

export interface nopiTest {
  id : number;
  registrationState: registrationCategory;
  gender: Gender;
  category: RecipeCategory;
  startTime:number,
  actualTime: number;
  drugName: string;
  outpatientNo: number;
  patientAge: number;
  patientId: number;
  patientMnemonicCode: string;
  patientName: string;
  patientPhone: string;
  patientSex: number;
  presetTime: string;
  receiveInAdvance: number;
  receiveTag: number;
  registrationDepartmentId: number;
  registrationDepartmentName: string;
  registrationDoctorId: number;
  registrationDoctorName: string;
  registrationFeeServiceId: number;
  registrationPayState: number;
  registrationTime: string;
  result: string;
  settlementTypesOfInsurance: string;
  skinTestId: string;
  state: number;
  status: number;
  tenantId: string;
  testUser: string;
  testUserName: string;
  treatmentDepartmentId: number;
  treatmentDepartmentName: string;
  treatmentDoctorId: number;
  treatmentDoctorName: string;
  treatmentId: number;
  treatmentTime: string;
  realname ?:string,
  recipeState ?:string,
  actulTime ?:string
}

export function fromJson(json: any): nopiTest {
  return {
    id: json.id?.toString() || "",
    // id: json.id,
    gender: json.patientSex,
    registrationState: json.registrationState,
    category: json.category,
    startTime:json.startTime,
    actualTime: json.actualTime,
    drugName: json.drugName,
    outpatientNo: json.outpatientNo,
    patientAge: json.patientAge,
    patientId: json.patientId,
    patientMnemonicCode: json.patientMnemonicCode,
    patientName: json.patientName,
    patientPhone: json.patientPhone,
    patientSex: json.patientSex,
    presetTime: json.presetTime,
    receiveInAdvance: json.receiveInAdvance,
    receiveTag: json.receiveTag,
    registrationDepartmentId: json.registrationDepartmentId,
    registrationDepartmentName: json.registrationDepartmentName,
    registrationDoctorId: json.registrationDoctorId,
    registrationDoctorName: json.registrationDoctorName,
    registrationFeeServiceId: json.registrationFeeServiceId,
    registrationPayState: json.registrationPayState,
    registrationTime: json.registrationTime,
    result: json.result,
    settlementTypesOfInsurance: json.settlementTypesOfInsurance,
    skinTestId: json.skinTestId,
    state: json.state,
    status: json.status,
    tenantId: json.tenantId,
    testUser: json.testUser,
    testUserName: json.testUserName,
    treatmentDepartmentId: json.treatmentDepartmentId,
    treatmentDepartmentName: json.treatmentDepartmentName,
    treatmentDoctorId: json.treatmentDoctorId,
    treatmentDoctorName: json.treatmentDoctorName,
    treatmentId: json.treatmentId,
    treatmentTime: json.treatmentTime,
    realname :json.realname,
    recipeState:json.recipeState,
    actulTime:json.actulTime
  };
}

export function toJson(nopitest: nopiTest): any {
  return {
    id: nopitest.id,
    category: nopitest.category,
    registrationState: nopitest.registrationState,
    patientSex: nopitest.gender,
    actualTime: nopitest.actualTime,
    startTime:nopitest.startTime,
    drugName: nopitest.drugName,
    outpatientNo: nopitest.outpatientNo,
    patientAge: nopitest.patientAge,
    patientId: nopitest.patientId,
    patientMnemonicCode: nopitest.patientMnemonicCode,
    patientName: nopitest.patientName,
    patientPhone: nopitest.patientPhone,
    presetTime: nopitest.presetTime,
    receiveInAdvance: nopitest.receiveInAdvance,
    receiveTag: nopitest.receiveTag,
    registrationDepartmentId: nopitest.registrationDepartmentId,
    registrationDepartmentName: nopitest.registrationDepartmentName,
    registrationDoctorId: nopitest.registrationDoctorId,
    registrationDoctorName: nopitest.registrationDoctorName,
    registrationFeeServiceId: nopitest.registrationFeeServiceId,
    registrationPayState: nopitest.registrationPayState,
    registrationTime: nopitest.registrationTime,
    result: nopitest.result,
    settlementTypesOfInsurance: nopitest.settlementTypesOfInsurance,
    skinTestId: nopitest.skinTestId,
    state: nopitest.state,
    status: nopitest.status,
    tenantId: nopitest.tenantId,
    testUser: nopitest.testUser,
    testUserName: nopitest.testUserName,
    treatmentDepartmentId: nopitest.treatmentDepartmentId,
    treatmentDepartmentName: nopitest.treatmentDepartmentName,
    treatmentDoctorId: nopitest.treatmentDoctorId,
    treatmentDoctorName: nopitest.treatmentDoctorName,
    treatmentId: nopitest.treatmentId,
    treatmentTime: nopitest.treatmentTime,
    realname :nopitest.realname,
    recipeState:nopitest.recipeState,
    actulTime:nopitest.actulTime
  };
}
