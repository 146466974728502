import { TableColumnType, Tooltip, Button, Switch, Space, Modal, notification } from 'antd'
import React, { ReactElement, useState } from 'react'
import { getGenderName } from '../../models/user'
import { getAge } from '../../utils/StringUtils'
import { Dimen } from "../../models/dimen"
import {
    ExclamationCircleFilled,
} from '@ant-design/icons'
import { getCouponsRecordupdateStatus } from './coupons'
import { RootDispatch } from '../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'


export const SettingColumns = (
    onClick: (text: string, id: any, item?: any) => void,
    startIndex: number,
): TableColumnType<any>[] => {
    return [
        {
            title: '序号',
            key: 'no',
            align: 'center',
            width: '5rem',
            render: (_1, _2, i) => `${startIndex + i + 1}`,
        },
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            // width: '10rem',
            render: (_, r) => {
                return r.name ? r.name : '-'
            },
        },
        {
            title: '优惠项目',
            dataIndex: 'itemName',
            align: 'center',
            // width: '8rem',
            render: (_, r) => {
                return r.itemName ? r.itemName : '-'
            },
        },
        {
            title: '类型',
            dataIndex: 'category',
            align: 'center',
            // width: '8rem',
            render: (_, r) => {
                return r.category === 1 ? '折扣券' : r.category === 2 ? '抵扣券' : r.category === 3 ? '体验券' : '-'
            },
        },

        {
            title: '优惠力度',
            dataIndex: 'discount',
            // width: '8rem',
            align: 'center',
            ellipsis: true,
            render: (_, r) => {
                return r.category === 1 ? r.discount + '折' : r.category === 2 ? r.discount + '元' : r.category === 3 ? '免费' : '-'
            },
        },
        {
            title: '优惠券总数',
            dataIndex: 'couponsTotal',
            align: 'center',
            render: (_, r) => {
                return r.couponsTotal
            },
        },
        {
            title: '已发数量',
            dataIndex: 'issuedTotal',
            align: 'center',
            render: (_, r) => {
                return r.issuedTotal
            },
        },
        {
            title: '剩余数量',
            dataIndex: 'surplus',
            align: 'center',
            // width: '8rem',
            ellipsis: true,
            render: (_, r) => {
                return r.surplus
            },
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            width: '8rem',
            ellipsis: true,
            render: function Settings(_, r): ReactElement {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{ width: '46px' }}>
                            {r.status == 0
                                ? '正常'
                                : r.status == 1
                                    ? '已注销'
                                    : ''}
                        </span>
                        <Switch
                            checked={r.status == 0 ? true : false}
                            style={{ marginLeft: '10px' }}
                            onChange={(e) => {
                                onClick('change', r.id, r)
                            }}
                        />
                    </div>
                )
            },
        },
        {
            title: '操作',
            align: 'center',
            // fixed: 'right',
            width: '12rem',
            render: function Element(_, t) {
                return (
                    <>
                        {t.status == 1 ? '' :
                            <div>
                                <Button
                                    type='primary'
                                    // ghost
                                    size='small'
                                    onClick={() => {
                                        onClick('generate', t.id, t)
                                    }}
                                >
                                    生成券
                                </Button>
                                <Button
                                    type='primary'
                                    // ghost
                                    size='small'
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                        onClick('upload', t.id, t)
                                    }}
                                >
                                    编辑
                                </Button></div>}
                    </>
                )
            },
        },
    ]
}

export const ActiveSettingColumns = (
    onClick: (text: string, id: any, item?: any) => void,
    startIndex: number,
): TableColumnType<any>[] => {
    return [
        {
            title: '序号',
            key: 'no',
            align: 'center',
            width: '5rem',
            render: (_1, _2, i) => `${startIndex + i + 1}`,
        },
        {
            title: '活动名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: '10rem',
            render: (_, r) => {
                return r.name ? r.name : '-'
            },
        },
        {
            title: '优惠券名称',
            dataIndex: 'couponsName',
            align: 'center',
            width: '15rem',
            render: (_, r) => {
                return r.couponsName ? r.couponsName : '-'
            },
        },
        {
            title: '有效天数',
            dataIndex: 'effectiveDays',
            align: 'center',
            width: '8rem',
            render: (_, r) => {
                return r.effectiveDays
            },
        },

        {
            title: '发放数量',
            dataIndex: 'couponsIssuedTotal',
            width: '8rem',
            align: 'center',
            ellipsis: true,
            render: (_, r) => {
                return r.couponsIssuedTotal
            },
        },
        {
            title: '创建日期',
            dataIndex: 'createTime',
            align: 'center',
            width: '8rem',
            ellipsis: true,
            render: (_, r) => {
                return r.createTime || '-'
            },
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            width: '8rem',
            ellipsis: true,
            render: function Settings(_, r): ReactElement {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{ width: '46px' }}>
                            {r.status == 0
                                ? '正常'
                                : r.status == 1
                                    ? '已注销'
                                    : ''}
                        </span>
                        <Switch
                            checked={r.status == 0 ? true : false}
                            style={{ marginLeft: '10px' }}
                            onChange={(e) => {
                                onClick('change', r.id, r)
                            }}
                        />
                    </div>
                )
            },
        },
        {
            title: '操作',
            align: 'center',
            fixed: 'right',
            width: '6rem',
            render: function Element(_, t) {
                return (
                    <>
                        {t.status == 0 ? <Button
                            type='primary'
                            // ghost
                            size='small'
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                                onClick('upload', t.id, t)
                            }}
                        >
                            编辑
                        </Button> : ''}
                    </>
                )
            },
        },
    ]
}

export const CouponsIssueColumns = (
    startIndex: number,
): TableColumnType<any>[] => {
    const dispatch = useDispatch<RootDispatch>()
    return [
        {
            title: '序号',
            key: 'no',
            width: '5rem',
            align: 'center',
            render: (_1, _2, i) => `${startIndex + i + 1}`,
        },
        {
            title: '患者姓名',
            dataIndex: 'name',
            // width: '12rem',
            align: 'center',
        },
        {
            title: '病历号',
            dataIndex: 'id',
            align: 'center',
            // width: '10rem',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            align: 'center',
            // width: '8rem',
            render: function ShowActions(_, p) {
                return getGenderName(p.sex)
            },
        },
        {
            title: '年龄',
            dataIndex: 'ageYear',
            align: 'center',
            // width: '8rem',
            render: (_, r) => getAge(r?.ageYear, r?.ageMonth),
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            // width: '10rem',
            align: 'center',
        },
        {
            title: '标签',
            dataIndex: 'labelVo',
            align: 'center',
            render: function ShowActions(_, p) {
                return (
                    <Space>
                        {!!p.labelVo.length &&
                            p.labelVo.map((v: any) => {
                                return (
                                    <Tooltip key={v.id} title={v.name}>
                                        <span
                                            style={{
                                                color: v.fontColor,
                                                backgroundColor: v.backgroundColor,
                                                cursor: 'pointer',
                                                padding: '2px 5px',
                                            }}
                                        >
                                            {v.headName}
                                        </span>
                                    </Tooltip>
                                )
                            })}
                    </Space>
                )
            },
        },
    ]
}

export const CouponsRecodeColumns = (
    onClick: () => void,
    startIndex: number,
): TableColumnType<any>[] => {
    const dispatch = useDispatch<RootDispatch>()
    return [
        {
            title: '序号',
            key: 'no',
            width: '5rem',
            align: 'center',
            render: (_1, _2, i) => `${startIndex + i + 1}`,
        },
        {
            title: '患者姓名',
            dataIndex: 'patientName',
            width: '8rem',
            align: 'center',
        },
        {
            title: '病历号',
            dataIndex: 'patientId',
            align: 'center',
            width: '8rem',
        },
        {
            title: '性别',
            dataIndex: 'gender',
            align: 'center',
            width: '6rem',
            render: function ShowActions(_, p) {
                return getGenderName(p.gender)
            },
        },
        {
            title: '年龄',
            dataIndex: 'ageYear',
            align: 'center',
            width: '6rem',
            render: (_, r) => getAge(r?.ageYear, r?.ageMonth),
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            width: '8rem',
            align: 'center',
        },
        {
            title: '发放原因',
            dataIndex: 'issueReason',
            width: '8rem',
            align: 'center',
        },
        {
            title: '优惠券名称',
            dataIndex: 'couponsName',
            width: '8rem',
            align: 'center',
        },
        {
            title: '类型',
            dataIndex: 'couponsCategory',
            width: '8rem',
            align: 'center',
            render: (_, r) => {
                return r.couponsCategory === 1 ? '折扣券' : r.couponsCategory === 2 ? '抵扣券' : r.couponsCategory === 3 ? '体验券' : '-'
            },
        },
        {
            title: '优惠力度',
            dataIndex: 'discount',
            width: '8rem',
            align: 'center',
            render: (_, r) => {
                return r.couponsCategory === 1 ? r.discount + '折' : r.couponsCategory === 2 ? r.discount + '元' : r.couponsCategory === 3 ? '免费' : '-'
            },
        },
        {
            title: '领取时间',
            dataIndex: 'pickUPTime',
            width: '10rem',
            align: 'center',
        },
        {
            title: '结束日期',
            dataIndex: 'expirationTime',
            width: '10rem',
            align: 'center',
        },
        {
            title: '使用时间',
            dataIndex: 'useTime',
            width: '8rem',
            align: 'center',
        },
        {
            title: '状态',
            dataIndex: 'useStatus',
            width: '8rem',
            align: 'center',
            fixed: 'right',
            render: (_, r) => {
                return r.useStatus === 1 ? '未使用' : r.useStatus === 2 ? '已使用' : r.useStatus === 3 ? '已过期' : r.useStatus === 9 ? '已失效' : '-'
            },
        },
        {
            title: '操作',
            align: 'center',
            fixed: 'right',
            width: '12rem',
            render: function Element(_, t) {
                return (
                    <>
                        {t.useStatus == 1 ?
                            <div>
                                <Button
                                    type='primary'
                                    // ghost
                                    size='small'
                                    onClick={() => {
                                        Modal.confirm({
                                            centered: true,
                                            title: '确认操作',
                                            content: (
                                                <div>
                                                    <ExclamationCircleFilled
                                                        style={{ color: '#FFAE55', fontSize: 18 }}
                                                    />
                                                    &nbsp;是否确认使用{t.patientName}的{t.couponsName}？
                                                </div>
                                            ),
                                            okText: '确认',
                                            cancelText: '取消',
                                            icon: null,
                                            onOk: () => {
                                                dispatch(getCouponsRecordupdateStatus({ id: t.id, useStatus: 2 }))
                                                    .then(unwrapResult)
                                                    .then(() => {
                                                        notification.success({
                                                            message: '操作成功',
                                                            duration: 3,
                                                        })
                                                        onClick()
                                                    })
                                            },
                                        })
                                    }}
                                >
                                    使用
                                </Button>
                                <Button
                                    type='primary'
                                    // ghost
                                    size='small'
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                        Modal.confirm({
                                            centered: true,
                                            title: '确认操作',
                                            content: (
                                                <div>
                                                    <ExclamationCircleFilled
                                                        style={{ color: '#FFAE55', fontSize: 18 }}
                                                    />
                                                    &nbsp;是否确认失效{t.patientName}的{t.couponsName}？
                                                </div>
                                            ),
                                            okText: '确认',
                                            cancelText: '取消',
                                            icon: null,
                                            onOk: () => {
                                                dispatch(getCouponsRecordupdateStatus({ id: t.id, useStatus: 9 }))
                                                    .then(unwrapResult)
                                                    .then(() => {
                                                        notification.success({
                                                            message: '操作成功',
                                                            duration: 3,
                                                        })
                                                        onClick()
                                                    })
                                            },
                                        })
                                    }}
                                >
                                    失效
                                </Button>
                            </div> : ''
                        }
                    </>
                )
            },
        },
    ]
}