/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-25 10:30:33
 */
import {  TableColumnType } from "antd";
import { useDispatch } from "react-redux";
import { Dimen } from "../../../models/dimen";
export const TreatCostColumns = (startIndex: number): TableColumnType<any>[] => {
  return [
    {
        title: "序号",
        key: "no",
        align: "center",
        render: (_1, _2, index) => `${startIndex + index + 1}`,
        width: Dimen.Num,
      },
    {
      title: "项目名称",
      dataIndex: "name",
      align: "center",
      width: Dimen.Name,
      key: "name",
      
    },
    // {
    //   title: "医保属性",
    //   dataIndex: "personTime",
    //   align: "center",
    // },
    {
      title: "开票项目",
      dataIndex: "billingCategory",
      align: "center",
      width: Dimen.BillingCategory,
      key: "billingCategory",
    },
   
    {
      title: "数量",
      dataIndex: "count",
      align: "center",
      width: Dimen.Count,
      key: "count",
    },
    {
      title: "单位",
      dataIndex: "accountUnit",
      align: "center",
      width: Dimen.Unit,
      key: "accountUnit",
    },
    {
      title: "均价(元)",
      dataIndex: "averagePrice",
      align: "right",
      width: Dimen.Price,
      key: "averagePrice",
      render: (_: any, t: any) => {
        return t.averagePrice 
          ? parseFloat(
              ((t.averagePrice ) as unknown) as string
            ).toFixed(2)
          : 0;
      },
    },
    {
      title: "应收金额",
      dataIndex: "retail",
      align: "right",
      key: "retail",
      width: Dimen.Price,
      render: (_: any, t: any) => {
        return t.retail
          ? parseFloat(
              ((t.retail ) as unknown) as string
            ).toFixed(2)
          : 0;
      },
    },
    // {
    //   title: "实收金额",
    //   dataIndex: "realRetail",
    //   align: "right",
    //   key: "realRetail",
    //   width: Dimen.Price,
    //   render: (_: any, t: any) => {
    //     return t.realRetail
    //       ? parseFloat(
    //         ((t.realRetail) as unknown) as string
    //       ).toFixed(2)
    //       : 0;
    //   },
    // },
  ];
};
