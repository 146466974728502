/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-16 13:45:12
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-02 15:32:24
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Modal } from 'antd'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectStationCategory } from '../../../../../app/applicationSlice'
import { RootDispatch } from '../../../../../app/store'
import { PrintTemplateCategory } from '../../../../../models/print'
import { StationCategory } from '../../../../../models/user'
import { getChangeRegistration } from '../../../../patient/patientSlice'
import { PrintTemplateModal } from '../../../../print/PrintTemplateModal'
import { RegisterNoAppointModal } from '../../../../registration/list/RegisterNoAppointModal'
import {
  getTreatments,
  setTreatment,
} from '../../../../treatment/diagnosis/diagnosisSlice'
import { RecipeListEditor } from '../../../../treatment/editor/RecipeListEditor'
import { TreatmentBoxList } from '../../../../treatment/list/TreatmentBoxList'
import {
  getTreatmentList,
  selectTreatmentPage,
} from '../../../../treatment/list/treatmentSlice'
import { OralCases } from '../../../../treatment/oralCases/OralCases'
import { reSetRegistration } from '../../../../treatment/treatmentSlice'
import { AddDisposeModal } from '../../components/addDisposeModal'
import PatientLabel from '../../components/patientLabel'
import { getAllTreatmentByPatientidAsync } from '../../patientSlice'

interface MedicalInfoProps {
  refreshPatient?: () => void
  refreshData?: () => void
  patientId: string
  currentRegistrationId?: string
}

export const MedicalInfo = ({
  refreshPatient,
  refreshData,
  patientId,
  currentRegistrationId,
}: MedicalInfoProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const page = useSelector(selectTreatmentPage)

  const [editMedicalVisible, setEditMedicalVisible] = useState(false)

  const [registrations, setRegistration] = useState<any>()

  const editMedicalVisibleRef = useRef(false) // 使用一个ref来追踪editMedicalVisible的值

  const [appointmentRemark, setAppointmentRemark] = useState('')

  const [patientInsurance, setPatientInsurance] = useState<any>()

  const [treatment, setTreatment] = useState<any>()

  const [insuranceBean, setInsuranceBean] = useState<any>()

  const [registrationId, setRegistrationId] = useState()

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const [printParams, setPrintParams] = useState<any>({})

  const [addVisible, setAddVisible] = useState(false)

  const [editVisible, setEditVisible] = useState(false)

  const [
    registerNoAppointModalVisible,
    setRegisterNoAppointModalVisible,
  ] = useState(false)

  const [remark, setRemark] = useState('')

  const [registerNoAppointId, setRegisterNoAppointId] = useState('')

  const stationCategory = useSelector(selectStationCategory)

  // const [page, setPage] = useState({
  //   current: 1,
  //   size: 10,
  //   total: 0,
  //   items: [],
  // })

  useEffect(() => {
    patientId && getPage()
  }, [patientId])

  useEffect(() => {
    if (!editMedicalVisible && patientId) {
      getPage()
    }
  }, [editMedicalVisible])

  const getPage = () => {
    dispatch(getTreatmentList({ patientId, current: 1, size: 30 }))
    // dispatch(getAllTreatmentByPatientidAsync(patientId)).then(unwrapResult).then((res: any) => {
    //   setPage({
    //     current: 1,
    //     size: res.length,
    //     total: res.length,
    //     items: res
    //   })
    // })
  }

  const getTreatmentsDetail = (id: string, t?: any) => {
    dispatch(
      getTreatments({
        registrationId: id,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        setRegistration(v?.registration)
        setAppointmentRemark(v.appointmentRemark)
        setInsuranceBean(v.insuranceBean)
        setPatientInsurance(v.patientInsurance)
        setTreatment(v?.treatment)
        editMedicalVisibleRef.current = true // 在获取数据后设置ref为true
        // 未指定科室医生时
        if (
          v?.registration?.registrationDepartmentId == '0' &&
          v?.registration?.registrationDoctorId == '0'
        ) {
          // 如果是护士
          if (stationCategory == StationCategory.Nurse) {
            dispatch(getChangeRegistration(id))
              .then(unwrapResult)
              .then((v: any) => {
                setEditMedicalVisible(true)
              })
              .catch((v) => {
                setRemark(t?.remark?.replace('#', ','))
                setRegisterNoAppointId(id)
                setRegisterNoAppointModalVisible(true)
              })
          } else if (stationCategory == StationCategory.DoctorOrPharmacist) {
            // 如果是医生
            setEditMedicalVisible(true)
          }
        } else {
          // 正常情况
          setEditMedicalVisible(true)
        }
      })
  }

  return (
    <div>
      <TreatmentBoxList
        page={page}
        toothAction={(action, t) => {
          if (action == 'edit') {
            getTreatmentsDetail(t.registrationId, t)
            setRegistrationId(t.registrationId)
          } else if (action == 'print') {
            setPrintParams({
              templateCategory: PrintTemplateCategory.Zds,
              registrationId: undefined,
              treatmentId: t.id,
            })
            dispatch(
              getTreatments({
                registrationId: t.registrationId,
              })
            )
              .then(unwrapResult)
              .then((v: any) => {
                setTreatment(v?.treatment)
              })
            setIsPrintModalVisible(true)
          }
        }}
      />
      <Button
        type='primary'
        style={{
          position: 'absolute',
          bottom: 20,
          left: 20,
        }}
        onClick={() => {
          setAddVisible(true)
        }}
      >
        新增病历
      </Button>
      <Modal
        visible={editMedicalVisible}
        title='病历编辑'
        width={1600}
        footer={null}
        bodyStyle={{
          height: 750,
          padding: 0,
          position: 'relative',
        }}
        maskClosable={false}
        destroyOnClose
        centered
        onCancel={() => {
          setEditMedicalVisible(false)
          refreshPatient && refreshPatient()
        }}
      >
        <OralCases
          registrations={registrations}
          insuranceBean={insuranceBean}
          patientInsurance={patientInsurance}
          toothRegistrationId={registrationId}
          // patientInsurance={patientInsurance}
          refreshPatient={(v) => {
            getTreatmentsDetail(v)
          }}
          saveEdit={(v) => {
            setEditMedicalVisible(false)
            refreshPatient && refreshPatient()
            if (v == 1) {
              setEditVisible(true)
            }
          }}
        />
      </Modal>

      <PrintTemplateModal
        templateCategory={printParams.templateCategory}
        visible={isPrintModalVisible}
        treatmentId={printParams.treatmentId}
        onCancel={() => setIsPrintModalVisible(false)}
      />
      <Modal
        title='编辑处置'
        width={1600}
        bodyStyle={{
          height: 750,
          padding: 10,
          position: 'relative',
        }}
        maskClosable={false}
        // destroyOnClose
        visible={editVisible}
        onCancel={() => {
          setEditVisible(false)
          refreshData && refreshData()
        }}
        footer={null}
        centered
      >
        <RecipeListEditor
          toothRegistrationId={registrationId}
          currentTab={'editor'}
          onAction={(action) => {
            if (action == 'wancheng') {
              setEditVisible(false)
              refreshData && refreshData()
              // getAllData()
            }
          }}
        />
      </Modal>
      <AddDisposeModal
        type={2}
        onCancel={() => {
          setAddVisible(false)
        }}
        addVisible={addVisible}
        patientId={patientId}
        registrationId={currentRegistrationId}
        onOk={(id) => {
          getTreatmentsDetail(id)
          setRegistrationId(id)
          setAddVisible(false)
        }}
      />
      <RegisterNoAppointModal
        id={registerNoAppointId}
        remark={remark}
        visible={registerNoAppointModalVisible}
        onCancel={() => {
          setRegisterNoAppointModalVisible(false)
        }}
        onOk={() => {
          getPage()
          refreshData && refreshData()
          setEditMedicalVisible(true)
          setRegisterNoAppointModalVisible(false)
        }}
      />
    </div>
  )
}
