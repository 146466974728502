/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-16 18:01:59
 */
import React from "react"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { notification } from "antd"
import { EditableList } from "../../compnents/list/EditableList"
import styles from "./Journal.module.css"
import { JournalQuery } from "./JournalQuery"
import { JournalColumns } from "./JournalColumns"
import { getStandCount, getStandList } from "./journalSlice"
import { unwrapResult } from "@reduxjs/toolkit"
import { RootDispatch } from "../../app/store"
import {
  getStandStateName,
  standCountValue,
  standListParams,
} from "../../models/standbook"
import { RejectDetailModal } from "./RejectModal"
import { JournalModal } from "./JournalModal"
// import Icon, { AppealReady } from "../../compnents/icons/Icons"

export const Journal = () => {
  const dispatch = useDispatch<RootDispatch>()

  const [total, setTotal] = useState(0)

  const [countList, setCountList] = useState<standCountValue[]>([])

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [isRejectModalVisible, setIsRejectModalVisible] = useState({
    cautionId: "",
    visible: false,
  })

  const [isJournalModalVisible, setIsJournalModalVisible] = useState({
    cautionId: "",
    visible: false,
  })

  const [data, setData] = useState<any>([])

  const [params, setParams] = useState<standListParams>({
    current: 1,
    size: 10,
  })

  useEffect(() => {
    setPageLoading(true)
    dispatch(getStandList({ ...params }))
      .then(unwrapResult)
      .then((res) => {
        setTotal(res.total)
        setData(res.records)
      })
      .finally(() => {
        setPageLoading(false)
      })
    dispatch(getStandCount())
      .then(unwrapResult)
      .then((res) => {
        setCountList(res)
      })
  }, [params])

  return (
    <div style={{ padding: 20, height: "100%" }}>
      <div className={styles.header}>
        {countList.map((v, i) => (
          <div className={styles.card} key={i}>
            <div style={{ textAlign: "center" }}>
              <h2 style={{ fontSize: "30px", marginBottom: "10px" }}>
                {countList[i].count}
              </h2>
              <p>{getStandStateName(v.state)}</p>
            </div>
            <span className={styles.Icons}></span>
          </div>
        ))}
      </div>
      <div className={styles.content}>
        <JournalQuery
          onValueChange={(v) => {
            setParams({
              ...params,
              ...v,
            })
          }}
        />
        <EditableList
          // rowKey={(record: any) => (record as any).cautionId}
          className={styles.suppliertable}
          loading={pageLoading}
          page={{
            items: data,
            current: params.current || 1,
            size: params.size || 10,
            total,
          }}
          onChangePage={(current, pageSize) => {
            setParams({
              ...params,
              current: current,
              size: pageSize || 10,
            })
          }}
          columns={JournalColumns((id, t) => {
            if (t === "search") {
              setIsRejectModalVisible({
                cautionId: id,
                visible: true,
              })
            } else if (t === "appeal") {
              setIsJournalModalVisible({
                cautionId: id,
                visible: true,
              })
            }
          }, (params.size || 10) * ((params.current || 1) - 1))}
          // onRow={(d: any) => ({
          //   // onDoubleClick: () => {
          //   //   dispatch(setSelectId(d.id))
          //   //   setIsmodalVisible(true)
          //   // },
          // })}
        />
      </div>
      <JournalModal
        isJournalModalVisible={isJournalModalVisible}
        onOk={() => {
          setIsJournalModalVisible({
            ...isJournalModalVisible,
            visible: false,
          })
          notification.success({
            message: "申诉成功",
          })
          setParams({
            ...params,
            current: 1,
          })
        }}
        onCancel={() => {
          setIsJournalModalVisible({
            ...isJournalModalVisible,
            visible: false,
          })
        }}
      />
      <RejectDetailModal
        isRejectModalVisible={isRejectModalVisible}
        onOk={() => {
          setIsRejectModalVisible({
            ...isRejectModalVisible,
            visible: false,
          })
        }}
        onCancel={() => {
          setIsRejectModalVisible({
            ...isRejectModalVisible,
            visible: false,
          })
        }}
      />
    </div>
  )
}
