/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-05 19:57:35
 * @LastEditors: linxi
 * @LastEditTime: 2022-07-06 18:13:04
 */

export interface stockDataValue {
  checkCount: number
  checkPrice: number
  checkSplitCount: number
  checkSplitCountBefore: number
  checkSplitCountAfter: number
  beforeTotalCount: number
  afterTotalCount: number
  outInvReceiptsItemList: {
    current: number
    size: number
    total: number
    hitCount: boolean
    pages: number
    searchCount: boolean
    records: {
      batchNo: string
      beforeCount: number
      beforeSplitCount: number
      changePrice: number
      count: number
      id: number
      name: string
      productInfo: string
      realCount: number
      realSplitCount: number
      splitCount: number
      splitScale: number
      unitPrice: number
      updateTime: string
    }[]
  }
}

export const getincDataType = (id?: string) => {
  switch (id) {
    case '1':
      return 3
    case '2':
      return 3
    case '8':
      return 1
    case '4':
      return 2
    default:
      return 0
  }
}
