/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-06-08 11:34:05
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-15 16:43:10
 */

import { Modal, message, Table, Button } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { ReactElement } from 'react'
import styles from './Layout.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { getVersionPageAsync } from '../features/home/HomeSlice'
import { RootDispatch } from '../app/store'
import { setId } from '../features/putstorage/putstorageSlice'
import { TipsModal } from './tipsModal'

export const VersionsListModal = (props: {
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const [list, setList] = useState<any>()

  const [id, setId] = useState<any>('')

  const dispatch = useDispatch<RootDispatch>()

  const [isTipsModalVisible, setIsTipsModalVisible] = useState(false)

  useEffect(() => {
    if (props?.visible) {
      dispatch(getVersionPageAsync())
        .then(unwrapResult)
        .then((v) => {
          const data = v
            ?.map((v: any) => {
              return {
                imgList: [
                  ...v?.versionPropertiesVOList?.filter(
                    (i: any) => i?.propName == '5'
                  ),
                ],
                parentId: v?.id,
              }
            })
            .flat()

          const newData = data?.filter((v: any) => v?.imgList?.length != 0)

          setList(newData)
        })
    }
  }, [props?.visible])

  return (
    <>
      <Modal
        visible={props.visible}
        className={styles.upModal}
        title='版本试用'
        width='1000px'
        footer={null}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          props.onCancel()
        }}
      >
        <div className={styles.backsMain}>
          {list?.map((v: any) => {
            return (
              <>
                {v?.imgList?.map((i: any) => {
                  return (
                    <>
                      <div
                        className={styles.backs}
                        onClick={() => {
                          setId(v?.parentId)
                          setIsTipsModalVisible(true)
                        }}
                      >
                        <img
                          src={i?.propValue}
                          alt=''
                          className={styles.imgs}
                        />
                      </div>
                    </>
                  )
                })}
              </>
            )
          })}
        </div>
      </Modal>
      <TipsModal
        id={id}
        visible={isTipsModalVisible}
        onOk={() => {
          setIsTipsModalVisible(false)
          props?.onOk()
        }}
        onCancel={() => {
          setIsTipsModalVisible(false)
          // props?.onCancel()
        }}
      />
    </>
  )
}
