/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 12:05:04
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-10 13:44:46
 */
import { Col, Form, Input, Modal, notification, Row, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Stacked } from '../components/Stacked'
import { AnnualCapitalFunds } from '../../../AnnualCapitalFund/AnnualCapitalFund'
import styles from '../insuranceContorl.module.css'
import { FeeLimits } from '../../../FeeLimit/FeeLimit'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { selectEdition } from '../../../../app/applicationSlice'

export const MoneyTabBar = () => {
  const { TabPane } = Tabs

  const [selectTab, setSelectTab] = useState<any>('6')

  return (
    <Col className={styles.containerItem}>
      <Tabs
        defaultActiveKey={'6'}
        onChange={(v) => {
          setSelectTab(v)
        }}
      >
        <TabPane key='6' tab='费用额度'>
          <FeeLimits selectedTab={selectTab} />
        </TabPane>
        <TabPane key='5' tab='年度人头基金数'>
          <AnnualCapitalFunds selectedTab={selectTab} />
        </TabPane>
        <TabPane key='1' tab='次均费用'>
          <Stacked queryType={4} />
        </TabPane>
        <TabPane key='2' tab='人均费用'>
          <Stacked queryType={5} />
        </TabPane>
        <TabPane key='3' tab='人次人头比'>
          <Stacked queryType={6} />
        </TabPane>
        <TabPane key='4' tab='就诊人数'>
          <Stacked queryType={7} />
        </TabPane>
      </Tabs>
    </Col>
  )
}
