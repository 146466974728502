import {Button, Modal, TableColumnType} from 'antd'
import React, {ReactElement} from 'react'

export const columns = (): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, i) => `${i + 1}`,
    },

    {
      title: '固化ID',
      dataIndex: 'solidify_id',
      align: 'center',
      key: 'solidify_id',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.solidify_id
      },
    },
    {
      title: '医疗机构编号',
      dataIndex: 'fixmedins_code',
      align: 'center',
      key: 'fixmedins_code',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.fixmedins_code
      },
    },
    {
      title: '医疗机构名称',
      dataIndex: 'fixmedins_name',
      align: 'center',
      key: 'fixmedins_name',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.fixmedins_name
      },
    },
    {
      title: '结算月份',
      dataIndex: 'setl_mon',
      align: 'center',
      key: 'setl_mon',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.setl_mon
      },
    },
    {
      title: '险种类型',
      dataIndex: 'insutype',
      align: 'center',
      key: 'insutype',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.insutype
      },
    },
    {
      title: '人员类型',
      dataIndex: 'psn_type',
      align: 'center',
      key: 'psn_type',
      width: '7rem',
      ellipsis: true,
      render: (_, t) => {
        return t.psn_type
      },
    },
    {
      title: '参保地区划',
      dataIndex: 'insu_admdvs',
      align: 'center',
      key: 'insu_admdvs',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.insu_admdvs
      },
    },
    {
      title: '人次',
      dataIndex: 'psn_time',
      align: 'center',
      key: 'psn_time',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.psn_time
      },
    },
    {
      title: '人数',
      dataIndex: 'psn_cnt',
      align: 'center',
      key: 'psn_cnt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.psn_cnt
      },
    },
    {
      title: '医疗费总额',
      dataIndex: 'medfee_sumamt',
      align: 'center',
      key: 'medfee_sumamt',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.medfee_sumamt
      },
    },
    {
      title: '符合范围金额',
      dataIndex: 'inscp_amt',
      align: 'center',
      key: 'inscp_amt',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.inscp_amt
      },
    },
    {
      title: '费用编号',
      dataIndex: 'fund_code',
      align: 'center',
      key: 'fund_code',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.fund_code
      },
    },
    {
      title: '费用名称',
      dataIndex: 'fund_name',
      align: 'center',
      key: 'fund_name',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.fund_name
      },
    },
    {
      title: '费用金额',
      dataIndex: 'fund_amt',
      align: 'center',
      key: 'fund_amt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.fund_amt
      },
    },
  ]
}

export const DetailColumns = (): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, i) => `${i + 1}`,
    },

    {
      title: '医药机构医保区划',
      dataIndex: 'fix_blng_admdvs',
      align: 'center',
      key: 'fix_blng_admdvs',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => {
        return t.fix_blng_admdvs
      },
    },
    {
      title: '险种类型',
      dataIndex: 'insutype',
      align: 'center',
      key: 'insutype',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.insutype
      },
    },
    {
      title: '人员类型',
      dataIndex: 'psn_type',
      align: 'center',
      key: 'psn_type',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.psn_type
      },
    },
    {
      title: '医疗类型',
      dataIndex: 'med_type',
      align: 'center',
      key: 'med_type',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.med_type
      },
    },
    {
      title: '参保地医保区划',
      dataIndex: 'insu_admdvs',
      align: 'center',
      key: 'insu_admdvs',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.insu_admdvs
      },
    },
    {
      title: '费用类别',
      dataIndex: 'hifp_type',
      align: 'center',
      key: 'hifp_type',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.hifp_type
      },
    },
    {
      title: '医药金额',
      dataIndex: 'fix_amt',
      align: 'center',
      key: 'fix_amt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.fix_amt
      },
    },
    {
      title: '中心总额',
      dataIndex: 'hicent_amt',
      align: 'center',
      key: 'hicent_amt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.hicent_amt
      },
    },
    {
      title: '对账差额',
      dataIndex: 'stmt_diff_amt',
      align: 'center',
      key: 'stmt_diff_amt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.stmt_diff_amt
      },
    },
    {
      title: '对账结果',
      dataIndex: 'stmt_rslt',
      align: 'center',
      key: 'stmt_rslt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.stmt_rslt
      },
    },
    {
      title: '对账地点类别',
      dataIndex: 'stmt_loc',
      align: 'center',
      key: 'stmt_loc',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.stmt_loc
      },
    },
  ]
}

export const BDJSColumns = (): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, i) => `${i + 1}`,
    },

    {
      title: '结算ID',
      dataIndex: 'setl_id',
      align: 'center',
      key: 'setl_id',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.setl_id
      },
    },
    {
      title: '就诊ID',
      dataIndex: 'mdtrt_id',
      align: 'center',
      key: 'mdtrt_id',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.mdtrt_id
      },
    },
    {
      title: '人员编号',
      dataIndex: 'psn_no',
      align: 'center',
      key: 'psn_no',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.psn_no
      },
    },
    {
      title: '结算日期',
      dataIndex: 'setl_date',
      align: 'center',
      key: 'setl_date',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.setl_date
      },
    },
    {
      title: '险种类型',
      dataIndex: 'insutype',
      align: 'center',
      key: 'insutype',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.insutype
      },
    },
    {
      title: '人员类型',
      dataIndex: 'psn_type',
      align: 'center',
      key: 'psn_type',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.psn_type
      },
    },
    {
      title: '医疗类别',
      dataIndex: 'med_type',
      align: 'center',
      key: 'med_type',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.med_type
      },
    },
    {
      title: '医疗费总额',
      dataIndex: 'medfee_sumamt',
      align: 'center',
      key: 'medfee_sumamt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.medfee_sumamt
      },
    },
    {
      title: '费用支付总额',
      dataIndex: 'fund_pay_sumamt',
      align: 'center',
      key: 'fund_pay_sumamt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.fund_pay_sumamt
      },
    },
    {
      title: '退费结算标志',
      dataIndex: 'refd_setl_flag',
      align: 'center',
      key: 'refd_setl_flag',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.refd_setl_flag
      },
    },
    {
      title: '个人账户支出',
      dataIndex: 'acct_pay',
      align: 'center',
      key: 'acct_pay',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.acct_pay
      },
    },
  ]
}

export const ELSEColumns = (
  columnsName : string,
  noCardRefund ?: (
    btn : any,
    mdtrtId : string,
    setlId : string
  ) => void
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, i) => `${i + 1}`,
    },

    {
      title: '结算ID',
      dataIndex: 'setl_id',
      align: 'center',
      key: 'setl_id',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.setl_id
      },
    },
    {
      title: '就诊ID',
      dataIndex: 'mdtrt_id',
      align: 'center',
      key: 'mdtrt_id',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.mdtrt_id
      },
    },
    {
      title: '人员编号',
      dataIndex: 'psn_no',
      align: 'center',
      key: 'psn_no',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.psn_no
      },
    },
    {
      title: '结算日期',
      dataIndex: 'setl_date',
      align: 'center',
      key: 'setl_date',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.setl_date
      },
    },
    {
      title: '险种类型',
      dataIndex: 'insutype',
      align: 'center',
      key: 'insutype',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.insutype
      },
    },
    {
      title: '人员类型',
      dataIndex: 'psn_type',
      align: 'center',
      key: 'psn_type',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.psn_type
      },
    },
    {
      title: '医疗类别',
      dataIndex: 'med_type',
      align: 'center',
      key: 'med_type',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.med_type
      },
    },
    {
      title: '医疗费总额',
      dataIndex: 'medfee_sumamt',
      align: 'center',
      key: 'medfee_sumamt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.medfee_sumamt
      },
    },
    {
      title: '费用支付总额',
      dataIndex: 'fund_pay_sumamt',
      align: 'center',
      key: 'fund_pay_sumamt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.fund_pay_sumamt
      },
    },
    {
      title: '退费结算标志',
      dataIndex: 'refd_setl_flag',
      align: 'center',
      key: 'refd_setl_flag',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.refd_setl_flag
      },
    },
    {
      title: '备注',
      dataIndex: 'memo',
      align: 'center',
      key: 'memo',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.memo
      },
    },
    {
      title: '对账结果',
      dataIndex: 'stmt_rslt',
      align: 'center',
      key: 'stmt_rslt',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.stmt_rslt
      },
    },
    {
      title: '操作',
      align: 'center',
      width: '4rem',
      render: function Actions(_, t): ReactElement | undefined {
        return columnsName === 'compareLessList' ? (
          <Button
            type={'primary'}
            size='small'
            key={t}
            onClick={(e ) => {
              Modal.confirm({
                title: '提示',
                content: `无卡退费后，会将该医保收费记录在医保进行退费操作以使得对账一致，请确认是否继续无卡退费？`,
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                  if (noCardRefund) {
                    noCardRefund(null, t.mdtrt_id, t.setl_id)
                  }
                },
              })
            }}
          >无卡退费</Button>
        ) : (<></>)
      },
    },
  ]
}
