/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-25 16:54:31
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../../app/store'
import {
  DateRangeFieldName,
  DateRangeType,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { fromJson, StaffCostEntry } from '../../../models/peopleSales'
import * as service from '../../../services/peopleSales'
import {
  GetStaffCostEntriesParams,
  getPeopleSalesExport,
  illnessSettingPageParams,
} from '../../../services/peopleSales'

interface StaffCostsState {
  params: GetStaffCostEntriesParams
}

const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

const initialState = {
  params: {
    queryType: '0',
    timeHead,
    timeTail,
    [DateRangeFieldName]: DateRangeType.Today,
  },
} as StaffCostsState

const peopleSaleSlice = createSlice({
  name: 'peopleSaleSlice',
  initialState,
  reducers: {
    setGetStaffCostEntriesParam: (state, action) => {
      state.params = action.payload
    },
  },
})

// 导出
export const getPeopleSaleExport = createAsyncThunk<
  void,
  GetStaffCostEntriesParams,
  RootThunkApi
>('doctorAudit/getPeopleSalesExport ', async (params, api) => {
  return sendAsync(service.getPeopleSalesExport(params), api)
})

// 医生工作站 疾病设置
export const illnessSettingPageAsync = createAsyncThunk<
  void,
  illnessSettingPageParams,
  RootThunkApi
>('doctorAudit/illnessSettingPageAsync ', async (params, api) => {
  return sendAsync(service.illnessSettingPage(params), api)
})

// 医生工作站 新增疾病目录
export const saveIllnessAsync = createAsyncThunk<
  void,
  service.saveIllnessParams,
  RootThunkApi
>('doctorAudit/illnessSettingPageAsync ', async (params, api) => {
  return sendAsync(service.saveIllness(params), api)
})


//疾病详情
export const detailIllnessAsync = createAsyncThunk<
    void,
    service.detailIllnessParams,
    RootThunkApi
>('doctorAudit/illnessSettingPageAsync ', async (params, api) => {
  return sendAsync(service.detailIllness(params), api)
})

//单个疾病权限
export const getSubmitDeptDisease = createAsyncThunk<
    void,
    service.submitDeptDiseaseParams,
    RootThunkApi
>('doctorAudit/submitDeptDiseaseAsync ', async (params, api) => {
  return sendAsync(service.submitDeptDisease(params), api)
})

//批量疾病
export const getBachSubmitDeptDisease = createAsyncThunk<
    void,
    service.submitDeptDiseaseParams,
    RootThunkApi
  >('doctorAudit/bachSubmitDeptDiseaseAsync ', async (params, api) => {
  return sendAsync(service.bachSubmitDeptDisease(params), api)
})

// 医生工作站 禁用疾病目录
export const forbiddenIllnessAsync = createAsyncThunk<
  void,
  service.forbiddenIllnessParams,
  RootThunkApi
>('doctorAudit/illnessSettingPageAsync ', async (params, api) => {
  return sendAsync(service.forbiddenIllness(params), api)
})


export const { setGetStaffCostEntriesParam } = peopleSaleSlice.actions

export const selectGetStaffCostEntriesParams = (
  state: RootState
): GetStaffCostEntriesParams => state.peopleSale.params

export const getStaffCostEntries = createAsyncThunk<
  [string, StaffCostEntry[]],
  GetStaffCostEntriesParams,
  RootThunkApi
>('peopleSales/getStaffCostEntriesStatus', async (params, api) => {
  return sendAsync(service.getpeopleSale(params), api).then(
    (data) =>
      [
        data.quickSaleSummary as string,
        _.chain(data.summaryList)
          .map((e) => fromJson(e))
          .value(),
      ] as [string, StaffCostEntry[]]
  )
})

export default peopleSaleSlice.reducer
