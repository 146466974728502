/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-08-29 10:18:30
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-12 13:41:00
 */
import React, { useContext, useEffect, useState } from 'react'
import {
  Col,
  Row,
  Form,
  Modal,
  Tabs,
  Input,
  notification,
  Button,
  Select,
} from 'antd'
import { TabPane } from '../../../compnents/widgets/TabBar'
import { ThemeContext } from '../../../theme/ThemeContext'
import { DrugSearchRecipeItemInput } from './drugSearchInput'
import { CloseOutlined } from '@ant-design/icons'
import styles from '../drugWarning.module.css'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { getDrugWarningDetail, getDrugWarningSubmit } from '../drugWarningSlice'
const { Option } = Select

export const AddDrugModal = (props: {
  id?: any
  visible: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [ageForm] = Form.useForm()

  const [overdoseForm] = Form.useForm()

  const [sexForm] = Form.useForm()

  const theme = useContext(ThemeContext)

  const [value, setValue] = useState<any>('1')

  const [editId, setEditId] = useState<any>()

  const [selectedDrugName, setSelectedDrugNames] = useState<any[]>([])

  const [selectedAgeDrugName, setSelectedAgeDrugNames] = useState<any[]>([])

  const [selectedOverdoseDrugName, setSelectedOverdoseDrugNames] = useState<
    any[]
  >([])

  const [selectedSexDrugName, setSelectedSexDrugNames] = useState<any[]>([])

  const [reminderContent, setReminderContent] = useState<any>()

  const [beforeAge, setBeforeAge] = useState<any>()

  const [ageLimit, setAgeLimit] = useState<any>()

  const [judgmentType, setJudgmentType] = useState<any>()

  const [afterAge, setAfterAge] = useState<any>()

  const [overdoseNum, setOverdoseNum] = useState<any>()

  const [overdoseAccountUnit, setOverdoseAccountUnit] = useState<any>()

  const [genderFlag, setGenderFlag] = useState<any>()

  const [showFlag, setShowFlag] = useState(false)

  const [showAgeFlag, setShowAgeFlag] = useState(false)

  const [showOverdoseFlag, setShowOverdoseFlag] = useState(false)

  const [showSexFlag, setShowSexFlag] = useState(false)

  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  }

  useEffect(() => {
    if (props?.visible) {
      setGenderFlag('1')
      setReminderContent('')
    } else {
      setSelectedDrugNames([])
      setSelectedAgeDrugNames([])
      setSelectedOverdoseDrugNames([])
      setSelectedSexDrugNames([])
      form.resetFields()
      ageForm.resetFields()
      overdoseForm.resetFields()
      sexForm.resetFields()
      setGenderFlag('1')
    }
  }, [props?.visible])

  const getText =(v?:any)=>{
    switch (v) {
      case '1':
        return '小于'
      case '2':
        return '大于'
      // case '3':
      //   return '等于'
      // case '4':
      //   return '大于等于'
    }
  }

  const getSubmit = (params: any) => {
    dispatch(
      getDrugWarningSubmit({ ...params, id: props?.id ? editId : undefined })
    )
      .then(unwrapResult)
      .then((v) => {
        notification.success({ message: '操作成功' })
        props?.onOk()
      })
      .catch(() => {
        // notification.error({ message: '操作失败' })
        props?.onCancel()
      })
  }

  useEffect(() => {
    if (props?.visible)
      if (props?.id) {
        dispatch(getDrugWarningDetail({ ruleId: props?.id }))
          .then(unwrapResult)
          .then((v: any) => {
            setEditId(v?.id)
            if (v?.ruleJson?.type == 1) {
              setValue('0')
              setShowFlag(true)
              setShowAgeFlag(false)
              setShowOverdoseFlag(false)
              setShowSexFlag(false)
              setSelectedDrugNames(v?.drugNameList)
              setReminderContent(v?.reminderContent)
            } else if (v?.ruleJson?.type == 2) {
              setAgeLimit(v?.ruleJson?.ageLimit)
              setJudgmentType(v?.ruleJson?.judgmentType?.toString())
              ageForm.setFieldsValue({
                ageLimit: v?.ruleJson?.ageLimit,
                judgmentType:v?.ruleJson?.judgmentType?.toString()
              })
              setValue('1')
              setShowFlag(false)
              setShowAgeFlag(true)
              setShowOverdoseFlag(false)
              setShowSexFlag(false)
              setSelectedAgeDrugNames(v?.drugNameList)
            } else if (v?.ruleJson?.type == 3) {
              setValue('2')
              setShowFlag(false)
              setShowAgeFlag(false)
              setShowOverdoseFlag(true)
              setShowSexFlag(false)
              setOverdoseNum(v?.ruleJson?.dose)
              setOverdoseAccountUnit(v?.ruleJson?.doseUnit)
              overdoseForm.setFieldsValue({
                dose: v?.ruleJson?.dose,
                doseUnit: v?.ruleJson?.doseUnit,
              })
              setSelectedOverdoseDrugNames(v?.drugNameList)
            } else if (v?.ruleJson?.type == 4) {
              setValue('3')
              setShowFlag(false)
              setShowAgeFlag(false)
              setShowOverdoseFlag(false)
              setShowSexFlag(true)
              setGenderFlag(v?.ruleJson?.gender?.toString())
              sexForm.setFieldsValue({
                gender: v?.ruleJson?.gender?.toString(),
              })
              setSelectedSexDrugNames(v?.drugNameList)
            }
          })
      } else {
        setShowFlag(true)
        setShowAgeFlag(true)
        setShowOverdoseFlag(true)
        setShowSexFlag(true)
        setValue('1')
        setEditId('')
      }
  }, [props?.visible, props?.id])

  return (
    <div>
      <Modal
        title={props?.id ? '编辑药品' : '新增药品'}
        visible={props.visible}
        okText='确认'
        cancelText='取消'
        width={1000}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          form.resetFields()
          props.onCancel()
        }}
        bodyStyle={{
          height: '550px',
          padding: '10px 20px',
          position: 'relative',
        }}
        footer={null}
        destroyOnClose
      >
        <Tabs
          onChange={(v) => {
            setValue(v)
          }}
          activeKey={value}
        >
          {/* {showFlag ? (
            <TabPane key='0' tab='多种药品误配预警'>
              <Form
                autoComplete='off'
                form={form}
                name='basic'
                onFinish={(fs) => {
                  const drugNameList = selectedDrugName?.map((v) => {
                    return { name: v?.name, id: v?.id }
                  })
                  const params = {
                    drugName: drugNameList,
                    reminderContent: reminderContent,
                    type: 1,
                  }
                  if (!selectedDrugName?.length) {
                    notification.error({ message: '请选择药品' })
                  } else if (!reminderContent) {
                    notification.error({ message: '请输入提醒内容' })
                  } else {
                    getSubmit(params)
                  }
                }}
              >
                <div style={{ height: '300px' }}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name='drugName' label='药品搜索' {...layout}>
                        <DrugSearchRecipeItemInput
                          autoFocus={selectedDrugName?.length ? true : false}
                          style={{
                            width: '18rem',
                            color: theme.tc1,
                          }}
                          onSelect={(v) => {
                            if (selectedDrugName?.length >= 10) {
                              notification.info({
                                message: '最多选择10条',
                              })
                              form.resetFields(['drugName'])
                            } else {
                              if (
                                !selectedDrugName.find((d) => d.id === v.id)
                              ) {
                                setSelectedDrugNames(selectedDrugName.concat(v))
                                form.resetFields(['drugName'])
                              }
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display:
                        selectedDrugName.length === 0 ? 'none' : undefined,
                    }}
                  >
                    <Col
                      span={3}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      药品 ：
                    </Col>
                    <Col
                      flex='1'
                      style={{
                        borderRadius: 2,
                        border: `1px solid ${theme.wbd}`,
                        padding: '10px 0 0 10px',
                        marginBottom: 10,
                      }}
                    >
                      {selectedDrugName?.map((d: any) => (
                        <div key={d.id} className={styles.disease}>
                          {`${d.name}`}
                          <CloseOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedDrugNames(
                                selectedDrugName.filter(
                                  (i: any) => i.id !== d.id
                                )
                              )
                            }}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                </div>
                {selectedDrugName?.length ? (
                  <Row style={{ margin: '0 0 0 40px' }}>
                    <Col span={24} style={{ marginBottom: '10px' }}>
                      <span>预览效果：</span>
                    </Col>
                    <Col span={24}>
                      <span style={{ marginBottom: '6px' }}>
                        {selectedDrugName
                          ?.map((v) => {
                            return v?.name
                          })
                          ?.join('、')}
                      </span>
                      <span>：</span>
                      <Input
                        value={reminderContent}
                        onChange={(v) => {
                          setReminderContent(v?.target?.value)
                        }}
                        style={{
                          width: '160px',
                          marginLeft: '6px',
                          marginTop: '6px',
                        }}
                      ></Input>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Form>
            </TabPane>
          ) : (
            ''
          )} */}
          {showAgeFlag ? (
            <TabPane key='1' tab='年龄段预警'>
              <Form
                autoComplete='off'
                form={ageForm}
                name='basic'
                onFinish={(fs) => {
                  const ageDrugNameList = selectedAgeDrugName?.map((v) => {
                    return { name: v?.name, id: v?.id }
                  })
                  const params = {
                    drugName: ageDrugNameList,
                    ageLimit: ageLimit,
                    judgmentType:judgmentType,
                    type: 2,
                  }
                  if (!selectedAgeDrugName?.length) {
                    notification.error({ message: '请选择药品' })
                  } else {
                    getSubmit(params)
                  }
                }}
              >
                <div style={{ height: '300px' }}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name='drugName' label='药品搜索' {...layout}>
                        <DrugSearchRecipeItemInput
                          autoFocus={selectedAgeDrugName?.length ? true : false}
                          style={{
                            width: '18rem',
                            color: theme.tc1,
                          }}
                          onSelect={(v) => {
                            if (selectedAgeDrugName?.length >= 10) {
                              notification.info({
                                message: '最多选择10条',
                              })
                              ageForm.resetFields(['drugName'])
                            } else {
                              if (
                                !selectedAgeDrugName.find((d) => d.id === v.id)
                              ) {
                                setSelectedAgeDrugNames(
                                  selectedAgeDrugName.concat(v)
                                )
                                ageForm.resetFields(['drugName'])
                              }
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display:
                        selectedAgeDrugName.length === 0 ? 'none' : undefined,
                    }}
                  >
                    <Col
                      span={3}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      药品 ：
                    </Col>
                    <Col
                      flex='1'
                      style={{
                        borderRadius: 2,
                        border: `1px solid ${theme.wbd}`,
                        padding: '10px 0 0 10px',
                        marginBottom: 10,
                      }}
                    >
                      {selectedAgeDrugName?.map((d: any) => (
                        <div key={d.id} className={styles.disease}>
                          {`${d.name}`}
                          <CloseOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedAgeDrugNames(
                                selectedAgeDrugName.filter(
                                  (i: any) => i.id !== d.id
                                )
                              )
                            }}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={3}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      年龄：
                    </Col>
                    <Col
                      flex='1'
                      style={{
                        marginBottom: 10,
                        display: 'flex',
                      }}
                    >
                      {/* <Form.Item
                        name='ageUpper'
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                            message: '请输入数字',
                          },
                          { required: true, message: '请输入年龄下限' },
                        ]}
                      >
                        <Input
                          style={{ width: 60 }}
                          onChange={(v) => {
                            setBeforeAge(v?.target?.value)
                          }}
                        /> 
                      </Form.Item>*/}
                      <Form.Item
                        name='judgmentType'
                        rules={[{ required: true, message: '请选择' }]}
                      >
                        <Select
                          placeholder='请选择'
                          style={{
                            width: 90,
                            margin: '0 10px',
                          }}
                          onChange={((v)=>{
                            setJudgmentType(v)
                          })}
                        >
                          <Option value={'1'} key={'1'}>
                            小于
                          </Option>
                          <Option value={'2'} key={'2'}>
                            大于
                          </Option>
                          {/* <Option value={'3'} key={'3'}>
                            等于
                          </Option>
                          <Option value={'4'} key={'4'}>
                            大于等于
                          </Option> */}
                        </Select>
                      </Form.Item>
                      {/* <span style={{ margin: '5px 10px 0 10px' }}> ~ </span> */}
                      <Form.Item
                        name='ageLimit'
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                            message: '请输入数字',
                          },
                          { required: true, message: '请输入年龄下限' },
                        ]}
                      >
                        <Input
                          style={{ width: 60 }}
                          onChange={(v) => {
                            setAgeLimit(v?.target?.value)
                          }}
                        />
                      </Form.Item>
                      <span style={{ margin: '5px 0 0 10px' }}> 岁 </span>
                    </Col>
                  </Row>
                </div>
                {selectedAgeDrugName?.length && (ageLimit || judgmentType) ? (
                  <Row style={{ margin: '0 0 0 40px' }}>
                    <Col span={24} style={{ marginBottom: '10px' }}>
                      <span>预览效果：</span>
                    </Col>
                    <Col span={24}>
                      <span style={{ marginBottom: '6px' }}>
                        {selectedAgeDrugName
                          ?.map((v) => {
                            return v?.name
                          })
                          ?.join('、')}
                      </span>
                      <span style={{ marginLeft: '6px' }}>
                        仅限{getText(judgmentType)}{ageLimit} 岁患者使用。
                      </span>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Form>
            </TabPane>
          ) : (
            ''
          )}
          {/* {showOverdoseFlag ? (
            <TabPane key='2' tab='超剂量预警'>
              <Form
                autoComplete='off'
                form={overdoseForm}
                name='basic'
                onFinish={(fs) => {
                  const overdoseDrugNameList = selectedOverdoseDrugName?.map(
                    (v) => {
                      return { name: v?.name, id: v?.id }
                    }
                  )
                  const params = {
                    drugName: overdoseDrugNameList,
                    dose: overdoseNum,
                    doseUnit: overdoseAccountUnit,
                    type: 3,
                  }
                  if (!selectedOverdoseDrugName?.length ) {
                    notification.error({ message: '请选择药品' })
                  }else{
                    getSubmit(params)
                  }
                }}
              >
                <div style={{ height: '300px' }}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name='drugName' label='药品搜索' {...layout}>
                        <DrugSearchRecipeItemInput
                          autoFocus={
                            selectedOverdoseDrugName?.length ? true : false
                          }
                          style={{
                            width: '18rem',
                            color: theme.tc1,
                          }}
                          onSelect={(v) => {
                            if (selectedOverdoseDrugName?.length >= 10) {
                              notification.info({
                                message: '最多选择10条',
                              })
                              overdoseForm.resetFields(['drugName'])
                            } else {
                              if (
                                !selectedOverdoseDrugName.find(
                                  (d) => d.id === v.id
                                )
                              ) {
                                setSelectedOverdoseDrugNames(
                                  selectedOverdoseDrugName.concat(v)
                                )
                                overdoseForm.resetFields(['drugName'])
                              }
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display:
                        selectedOverdoseDrugName.length === 0
                          ? 'none'
                          : undefined,
                    }}
                  >
                    <Col
                      span={3}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      药品 ：
                    </Col>
                    <Col
                      flex='1'
                      style={{
                        borderRadius: 2,
                        border: `1px solid ${theme.wbd}`,
                        padding: '10px 0 0 10px',
                        marginBottom: 10,
                      }}
                    >
                      {selectedOverdoseDrugName?.map((d: any) => (
                        <div key={d.id} className={styles.disease}>
                          {`${d.name}`}
                          <CloseOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedOverdoseDrugNames(
                                selectedOverdoseDrugName.filter(
                                  (i: any) => i.id !== d.id
                                )
                              )
                            }}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={3}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      输入剂量范围：
                    </Col>
                    <Col
                      flex='1'
                      style={{
                        marginBottom: 10,
                        display: 'flex',
                      }}
                    >
                      <Form.Item
                        name='dose'
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]\d*$/, 'g'),
                            message: '请输入数字',
                          },
                          { required: true, message: '请输入剂量' },
                        ]}
                      >
                        <Input
                          style={{ width: 80 }}
                          onChange={(v) => {
                            setOverdoseNum(v?.target?.value)
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name='doseUnit'
                        rules={[{ required: true, message: '请选择剂量单位' }]}
                      >
                        <RecipeItemFieldInput
                          field={'accountUnit'}
                          disabled={false}
                          noSize={true}
                          style={{ width: 60, marginLeft: '10px' }}
                          onChange={(v) => {
                            setOverdoseAccountUnit(v)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                {selectedOverdoseDrugName?.length &&
                (overdoseNum || overdoseAccountUnit) ? (
                  <Row style={{ margin: '0 0 0 40px' }}>
                    <Col span={24} style={{ marginBottom: '10px' }}>
                      <span>预览效果：</span>
                    </Col>
                    <Col span={24}>
                      <span style={{ marginBottom: '6px' }}>
                        {selectedOverdoseDrugName
                          ?.map((v) => {
                            return v?.name
                          })
                          ?.join('、')}
                      </span>
                      <span style={{ marginLeft: '6px' }}>
                        限量 {overdoseNum} {overdoseAccountUnit} 使用范围
                      </span>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Form>
            </TabPane>
          ) : (
            ''
          )} */}
          {showSexFlag ? (
            <TabPane key='3' tab='指定性别预警'>
              <Form
                autoComplete='off'
                form={sexForm}
                name='basic'
                onFinish={(fs) => {
                  const sexDrugNameList = selectedSexDrugName?.map((v) => {
                    return { name: v?.name, id: v?.id }
                  })
                  const params = {
                    drugName: sexDrugNameList,
                    gender: genderFlag,
                    type: 4,
                  }
                  if (!selectedSexDrugName?.length) {
                    notification.error({ message: '请选择药品' })
                  } else {
                    getSubmit(params)
                  }
                }}
              >
                <div style={{ height: '300px' }}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name='drugName' label='药品搜索' {...layout}>
                        <DrugSearchRecipeItemInput
                          autoFocus={selectedSexDrugName?.length ? true : false}
                          style={{
                            width: '18rem',
                            color: theme.tc1,
                          }}
                          onSelect={(v) => {
                            if (selectedSexDrugName?.length >= 10) {
                              notification.info({
                                message: '最多选择10条',
                              })
                              sexForm.resetFields(['drugName'])
                            } else {
                              if (
                                !selectedSexDrugName.find((d) => d.id === v.id)
                              ) {
                                setSelectedSexDrugNames(
                                  selectedSexDrugName.concat(v)
                                )
                                sexForm.resetFields(['drugName'])
                              }
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      display:
                        selectedSexDrugName.length === 0 ? 'none' : undefined,
                    }}
                  >
                    <Col
                      span={3}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      药品 ：
                    </Col>
                    <Col
                      flex='1'
                      style={{
                        borderRadius: 2,
                        border: `1px solid ${theme.wbd}`,
                        padding: '10px 0 0 10px',
                        marginBottom: 10,
                      }}
                    >
                      {selectedSexDrugName?.map((d: any) => (
                        <div key={d.id} className={styles.disease}>
                          {`${d.name}`}
                          <CloseOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedSexDrugNames(
                                selectedSexDrugName.filter(
                                  (i: any) => i.id !== d.id
                                )
                              )
                            }}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name='gender'
                        label='性别'
                        {...layout}
                        initialValue={'1'}
                        rules={[{ required: true, message: '请选择性别' }]}
                      >
                        <Select
                          placeholder='请选择性别'
                          style={{
                            width: '8rem',
                          }}
                          onChange={(v) => {
                            setGenderFlag(v)
                          }}
                        >
                          <Option value={'1'} key={'1'}>
                            男性
                          </Option>
                          <Option value={'2'} key={'2'}>
                            女性
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                {selectedSexDrugName?.length && genderFlag ? (
                  <Row style={{ margin: '0 0 0 40px' }}>
                    <Col span={24} style={{ marginBottom: '10px' }}>
                      <span>预览效果：</span>
                    </Col>
                    <Col span={24}>
                      <span style={{ marginBottom: '6px' }}>
                        {selectedSexDrugName
                          ?.map((v) => {
                            return v?.name
                          })
                          ?.join('、')}
                      </span>
                      <span style={{ marginLeft: '6px' }}>
                        仅限
                        {genderFlag == '1'
                          ? '男'
                          : genderFlag == '2'
                          ? '女'
                          : ''}
                        性使用
                      </span>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Form>
            </TabPane>
          ) : (
            ''
          )}
        </Tabs>
        <Row
          style={{
            marginTop: '10px',
            display: 'flex',
            position: 'absolute',
            right: 20,
            bottom: 20,
          }}
        >
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => {
              form.resetFields()
              props.onCancel()
            }}
          >
            取消
          </Button>
          <Button
            type='primary'
            onClick={() => {
              if (value == '0') {
                form.submit()
              } else if (value == '1') {
                ageForm.submit()
              } else if (value == '2') {
                overdoseForm.submit()
              }
              if (value == '3') {
                sexForm.submit()
              }
            }}
          >
            确认
          </Button>
        </Row>
      </Modal>
    </div>
  )
}
