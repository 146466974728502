/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-27 16:02:16
 * @LastEditors: K
 * @LastEditTime: 2022-10-10 09:26:44
 */
import { unwrapResult } from '@reduxjs/toolkit';
import { Col, Modal, Row } from 'antd';

import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../app/store';
import { EditableList } from '../../compnents/list/EditableList';
import { TopUpRecordModalColumns } from "./columns";
import styles from './MembersManagementPage.module.css'
import { getMembersBaseInfo, getMembersInfo, getMembersTopUpRecord } from './MembersManagementPageSlice';
interface TopUpRecordModalProps {
  visible: boolean
  membersId: number
  onCancel: () => void
}

export const TopUpRecordModal = ({
  visible,
  membersId,
  onCancel,
}: TopUpRecordModalProps): ReactElement => {

  const dispatch = useDispatch<RootDispatch>();

  const [params, setParams] = useState({ current: 0, size: 10 });

  const [page, setPage] = useState({
    items: [],
    total: 10
  });

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  const [membersInfo, setMembersInfo] = useState<any>({})

  const getPage = () => {
    setPageLoading(true)
    dispatch(getMembersTopUpRecord({ ...params, memberId: membersId, type: 0 }))
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    params.current && getPage()
  }, [params])

  useEffect(() => {
    if (visible) {
      setParams({ ...params, current: 1 })
      dispatch(getMembersInfo({ memberId: membersId })).then(unwrapResult).then((res: any) => setMembersInfo(res))
    }
  }, [visible])
  return (
    <Modal
      visible={visible}
      title='充值记录'
      width={1100}
      cancelText='取消'
      okText='确定'
      onCancel={onCancel}
      footer={null}
    >
      <Row >
        <Col span={6} >
          <span style={{ display: 'inline-block', fontWeight: 'bold', fontSize: '16px', marginRight: 10 }}>{membersInfo?.memberName}</span>
          <span>({membersInfo?.sexTypeStr}，{membersInfo?.age}，{membersInfo?.memberPhone})</span>
        </Col>

      </Row>
      <Row style={{ margin: '20px 0' }}>
        <Col span={6}>会员卡金额：<span style={{ color: '#ff5f61', fontWeight: 'bold' }}>￥{membersInfo?.balanceMoney?.toFixed(2)}</span></Col>
      </Row>

      <EditableList
        className={styles.patientLable}
        loading={pageLoading}
        page={{
          items: page.items,
          current: params.current,
          size: params.size,
          total: page.total,
        }}
        onChangePage={(current, size) => setParams({ ...params, current, size: size || 10 })}
        columns={TopUpRecordModalColumns(params.size * (params.current - 1))}
      />
    </Modal>
  );
}



