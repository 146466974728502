/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-09-16 09:21:54
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-31 16:05:14
 */
import { PlusSquareFilled } from '@ant-design/icons'
import { Button, Col, Tabs } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { TabBar } from '../../compnents/widgets/TabBar'
import { traceRoute } from '../../layouts/layoutSlice'
import { reSetRegistration } from '../treatment/treatmentSlice'
import { PatientList } from './PatientList'
import styles from './Patients.module.css'

const { TabPane } = Tabs

export const Patients = (): ReactElement => {
  const dispatch = useDispatch()

  const location: any = useLocation()

  const [currentTab, setCurrentTab] = useState('0')

  useEffect(() => {
    dispatch(reSetRegistration({}))
    setCurrentTab(
      JSON.parse(sessionStorage.getItem(location.pathName) || '{}').index || '0'
    )
  }, [])

  

  return (
    <Col className={styles.container}>
      <TabBar
        // activeKey={currentTab}
        onChange={(t) => setCurrentTab(t)}
        defaultActiveKey={
          JSON.parse(sessionStorage.getItem('/patients') || '{}').index || '0'
        }
      >
        <TabPane key='0' tab='今日患者'>
          <PatientList
            today={currentTab == '0' ? true : false}
            currentTab={currentTab}
          />
        </TabPane>
        <TabPane key='1' tab='历史患者'>
          <PatientList currentTab={currentTab} />
        </TabPane>
      </TabBar>
      {currentTab === '0' && (
        <Button
          type='primary'
          className={styles.create}
          icon={<PlusSquareFilled />}
          onClick={() =>
            dispatch(
              traceRoute({
                name: '挂号',
                path: '/registration',
                query: 'redirect',
              })
            )
          }
        >
          快速就诊
        </Button>
      )}
    </Col>
  )
}
