/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-02 11:34:13
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-03 17:31:25
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import { consult, payStatus, productCenterInfo, productCenterInfoYearAsync, QRCode, QRCodeParams, renewDetails, sendMessage } from '../../services/productCenter'

// 获取产品中心信息
export const productCenterInfoAsync = createAsyncThunk<
  void,
  void,
  RootThunkApi
>('/productCenter/productCenterInfoAsync', async (_, api) => {
  return sendAsync(productCenterInfo(), api)
})

// 获取本机构续期价格表
export const getProductCenterInfoYearAsync = createAsyncThunk<
  void,
  void,
  RootThunkApi
>('/productCenter/getProductCenterInfoYearAsync', async (_, api) => {
  return sendAsync(productCenterInfoYearAsync(), api)
})



// 续订详情
export const renewDetailsAsync = createAsyncThunk<void, void, RootThunkApi>(
  '/productCenter/renewDetailsAsync',
  async (_, api) => {
    return sendAsync(renewDetails(), api)
  }
)

// 获取支付二维码
export const QRCodeAsync = createAsyncThunk<void, QRCodeParams, RootThunkApi>(
  '/productCenter/QRCodeAsync',
  async (params, api) => {
    return sendAsync(QRCode(params), api)
  }
)

// 支付状态查询
export const payStatusAsync = createAsyncThunk<void, string, RootThunkApi>(
  '/productCenter/payStatusAsync',
  async (params, api) => {
    return sendAsync(payStatus(params), api)
  }
)

// 咨询
export const consultAsync = createAsyncThunk<void, number, RootThunkApi>(
  '/productCenter/consultAsync',
  async (params, api) => {
    return sendAsync(consult(params), api)
  }
)

// 预约续费咨询

export const getSendMessage = createAsyncThunk<any, void, RootThunkApi>(
  '/productCenter/sendMessage',
  async (params, api) => {
    return sendAsync(sendMessage(), api)
  }
)
