/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 16:55:05
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-20 17:29:20
 */
import { Button, Col, Divider, Row, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavBar } from '../../compnents/nav/NavBar';
import { ThemeContext } from '../../theme/ThemeContext';
import { AppointmentRegister } from '../registration/register/appointmentRegister';
import { Register } from '../registration/register/Register';
import styles from "./appointment.module.css"

export const AddEditAppointment = () => {
  const theme = useContext(ThemeContext)

  const [id, setId] = useState(0)

  const location = useLocation<any>()
  useEffect(() => {
    setId(location?.state?.id)
  }, [location])

  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        overflow: 'hidden',
        position: 'relative',
        marginTop: 10,
        marginBottom: 10,
        padding: '0px 20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <NavBar
        style={{ marginBottom: '10px' }}
        where={['预约', location?.state?.id ? '修改预约' : '新增预约']}
        backtrace={{
          name: '预约',
          path: '/appointment',
          routeName: '预约',
        }}
      />
      <AppointmentRegister
        AppointmentId={Number(id)}
        activeKey={location?.state?.activeKey}
      />
    </Col>
  )
}

