/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-01 10:05:03
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-12 14:42:49
 */
import { SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { EditableList } from '../../../../compnents/list/EditableList'
import { outpatientProjectOptions } from '../../../../models/toothPatient'
import { MorePatientColumns } from '../../patient/patientList/columns'
import {
  getPatientByGroupAsync,
  getPatientGroupAsync,
  setGroupList,
  selectAttendingDoctorList,
  selectCounselorList,
  setAttendingDoctorList,
  setCounselorList,
  selectGroupList,
} from '../../patient/patientSlice'
import {
  getCounselor,
  selectPatientBirthday,
  selectSendIDs,
  setSendIDs,
} from '../toothHomeSlice'
import styles from './modal.module.css'

interface MorePatientProps {
  visible: boolean
  onCancel: () => void
  onOk: () => void
}

export const MorePatient = ({
  visible,
  onOk,
  onCancel,
}: MorePatientProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const [loading, setLoading] = useState(false)

  const [selectIds, setSelectIds] = useState<any[]>([])

  const attendingDoctorList = useSelector(selectAttendingDoctorList)

  const counselorList = useSelector(selectCounselorList)

  const allGroupLists = useSelector(selectGroupList)

  const PatientBirthday = useSelector(selectPatientBirthday)

  const selectedKeys = useSelector(selectSendIDs)

  const [currentSelect, setCurrentSelect] = useState<any[]>([])

  useEffect(() => {
    if (visible) {
      if (selectedKeys) {
        setCurrentSelect([...selectedKeys, ...currentSelect])
      }
    }
  }, [selectedKeys, visible])

  useEffect(() => {
    if (!visible) return
    dispatch(
      getCounselor({
        current: 1,
        size: 100,
        stationCategory: 1,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(setAttendingDoctorList(res.records))
      })
    dispatch(
      getCounselor({
        current: 1,
        size: 100,
        stationCategory: 4,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(setCounselorList(res.records))
      })
    dispatch(getPatientGroupAsync())
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(setGroupList(res.records))
      })
  }, [visible])

  const getPage = () => {
    setLoading(true)
    dispatch(
      getPatientByGroupAsync({
        ...params,
        groupId: params.groupId || 0,
        startTime: PatientBirthday[0],
        endTime: PatientBirthday[1],
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const data = {
          total: res.total,
          items: res.records,
        }
        setPage(data)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!visible) return
    getPage()
  }, [params, visible])

  useEffect(() => {
    form.setFieldsValue({
      groupId: 0,
    })
  }, [])

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel()
      }}
      width={1350}
      destroyOnClose
      bodyStyle={{
        height: 700,
        padding: 0,
      }}
      title='选择患者'
      footer={null}
      centered
    >
      <div className={styles.main}>
        <Form
          form={form}
          onFinish={(values) => {
            setParams({
              ...params,
              ...values,
              current: 1,
            })
          }}
        >
          <div className={styles.query}>
            <Row
              style={{
                flex: 1,
              }}
              gutter={10}
            >
              <Col span={5}>
                <Form.Item name='key'>
                  <Input
                    autoComplete='off'
                    prefix={<SearchOutlined />}
                    placeholder='患者姓名/助记码/手机号/病历号'
                    allowClear
                    onChange={form.submit}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name='attendingDoctorId'>
                  <Select
                    placeholder='主治医生'
                    allowClear
                    onChange={form.submit}
                    showSearch
                    filterOption={(input, option: any) =>
                      (option?.children ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {attendingDoctorList.map((d) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.realname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name='counselorId'>
                  <Select
                    placeholder='咨询师'
                    allowClear
                    showSearch
                    onChange={form.submit}
                    filterOption={(input, option: any) =>
                      (option?.children ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {counselorList.map((d) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.realname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name='treatmentItem'>
                  <Select
                    placeholder='就诊项目'
                    allowClear
                    onChange={form.submit}
                  >
                    {outpatientProjectOptions.map((d) => (
                      <Select.Option key={d.name} value={d.name}>
                        {d.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name='groupId'>
                  <Select
                    placeholder='患者分组'
                    allowClear
                    onChange={form.submit}
                    // defaultValue='0'
                  >
                    {allGroupLists.map((d) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.groupName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button type='primary' htmlType='submit'>
                  查询
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
        <EditableList
          loading={loading}
          className={styles.table}
          rowKey={(v, i) => {
            return `${v.patient.id}`
          }}
          page={{
            current: params.current,
            size: params.size,
            total: page.total,
            items: page.items,
          }}
          columns={MorePatientColumns(params.size * (params.current - 1))}
          onChangePage={(current, size) =>
            setParams({
              ...params,
              current,
              size: size || 20,
            })
          }
          onRow={(record) => ({
            onClick: () => {
              let data = []
              if (!currentSelect.includes(record.patient.id.toString())) {
                data = [record.patient.id.toString(), ...currentSelect]
              } else {
                data = currentSelect.filter(
                  (v) => v != record.patient.id.toString()
                )
              }
              setCurrentSelect(data)
            },
          })}
          rowSelection={{
            selectedRowKeys: currentSelect,
            onSelectAll: (
              selected: any,
              selectedRows: any,
              changeRows: any
            ) => {
              let data: any = []
              if (selected) {
                data = [
                  ...changeRows.map((v: any) => v.patient.id.toString()),
                  ...currentSelect,
                ]
              } else {
                data = currentSelect.filter(
                  (item) =>
                    !changeRows.some(
                      (ele: any) => ele.patient.id.toString() === item
                    )
                )
              }
              setCurrentSelect(data)
              // dispatch(setSendIDs(data))
            },
            onSelect: (record, selected) => {
              let data = []
              if (selected) {
                data = [record.patient.id.toString(), ...currentSelect]
              } else {
                data = currentSelect.filter(
                  (v) => v != record.patient.id.toString()
                )
              }
              setCurrentSelect(data)
              // dispatch(setSendIDs(data))
            },
          }}
        />
        <Row
          justify='space-between'
          style={{
            padding: '0 10px 0 30px`',
          }}
        >
          <div>
            已选择<span style={{ color: 'red' }}>{currentSelect.length}</span>
            位患者
          </div>
          <Space>
            <Button
              onClick={() => {
                onCancel()
                setCurrentSelect([])
              }}
            >
              {' '}
              取消
            </Button>
            <Button
              type='primary'
              onClick={() => {
                const list = Array.from(
                  new Set([...selectedKeys, ...currentSelect])
                )
                dispatch(setSendIDs(currentSelect))
                setCurrentSelect([])
                onOk()
              }}
            >
              确认
            </Button>
          </Space>
        </Row>
      </div>
    </Modal>
  )
}
