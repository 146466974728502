/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-05-19 14:28:59
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-06 11:45:06
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import {
  ImageInformationPage,
  ImageInformationParams,
} from '../../services/imageInformation'

export const getImageInformationPage = createAsyncThunk<
  void,
  ImageInformationParams,
  RootThunkApi
>('ToothHome/getImageInformationPage', async (params, api) => {
  return sendAsync(ImageInformationPage(params), api)
})
