/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-17 11:18:17
 * @LastEditors: linxi
 * @LastEditTime: 2021-11-17 11:22:34
 */

import { appealParams, standListParams } from "../models/standbook"

import { API_PREFIX } from "./constant"
import { Request } from "./request"


export type liveBody = {
  anchorName: string
  coverUrl: string
  endTime: string
  introduction: string
  startTime: string
  title: string
}
//   保存直播
export const createLive = (body:liveBody): Request => {
  return {
    url: `${API_PREFIX}/blade-user/live/createLive`,
    method: "POST",
    body
  }
}
//   查询直播
export type searchLivesParams = {
  current: number,
  size: number,
}
export const searchLives = (params:searchLivesParams): Request => {
  return {
    url: `${API_PREFIX}/blade-user/live/searchLives`,
    method: "GET",
    params
  }
}
export const checkAnchor = (): Request => {
  return {
    url: `${API_PREFIX}/blade-user/live/check`,
    method: "POST",
  }
}


export type startBody = {
  liveId: string
}
//   开播
export const startLives = (body:startBody): Request => {
  return {
    url: `${API_PREFIX}/blade-user/live/startLive`,
    method: "POST",
    body
  }
}
