import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Select, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'

interface QueryProps {
  onSubmit: (obj: any) => void
}
export const Query = ({ onSubmit }: QueryProps) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      onFinish={(values) => {
        onSubmit({
          ...values,
        })
      }}
      style={{ padding: '0 10px' }}
    >
      {/* 查询条件 */}
      <Space wrap>
        <Form.Item noStyle name='hilistCode'>
          <Input
            autoComplete='off'
            placeholder='国家编码'
            style={{ width: '10rem' }}
            allowClear
            onChange={() => form.submit()}
          />
        </Form.Item>
        <Form.Item noStyle name='hilistName'>
          <Input
            autoComplete='off'
            placeholder='医保项目名称'
            style={{ width: '10rem' }}
            allowClear
            onChange={() => form.submit()}
          />
        </Form.Item>
        <Form.Item noStyle name='localItemName'>
          <Input
            autoComplete='off'
            placeholder='本地项目名称'
            style={{ width: '10rem' }}
            allowClear
            onChange={() => form.submit()}
          />
        </Form.Item>
        <Button type='primary' htmlType='submit'>
          查询
        </Button>
      </Space>
    </Form>
  )
}
