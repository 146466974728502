import { Popover } from "antd";
import { Modal, Form, Input, Row, Col, } from "antd";
import cnchar from "cnchar";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditableListRef } from "../../../compnents/containers/EditableList";
import { setCurrent } from "../../sales/DrugStatic/Modal/modalSlice";
import styles from "./patientLable.module.css";
import { lableDetail, saveLabel, selectDetail, selectLableId, setDetail } from "./patientlableSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../../app/store";

export const LobleModal = (props: {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const dispatch = useDispatch<RootDispatch>();

  const [state, setState] = useState("新增");

  const [form] = Form.useForm();

  const ref = useRef<EditableListRef>(null);

  const lable = useSelector(selectDetail);

  const selectId = useSelector(selectLableId);

  const colorList = [
    {
      index: 0,
      backgorundColor: "#f80e0e",
      text: "上",
      fontColor: "#FFFFFF",
    },
    {
      index: 1,
      backgorundColor: "#AD7CF0",
      text: "推",
      fontColor: "#FFFFFF",
    },
    {
      index: 2,
      backgorundColor: "#55E089",
      text: "高",
      fontColor: "#FFFEFE",
    },

    {
      index: 3,
      backgorundColor: "#FF96AF",
      text: "按",
      fontColor: "#FFFFFF",
    },
    {
      index: 4,
      backgorundColor: "#FFB54B",
      text: "妇",
      fontColor: "#FFFFFF",
    },
    {
      index: 5,
      backgorundColor: "#3FD4DD",
      text: "特",
      fontColor: "#FFFFFF",
    },
  ];
  useEffect(() => {
    if (selectId) {
      dispatch(lableDetail(selectId))
      setState("修改");
      setCurrent(1)
    } else {
      setState("新增");
      setCurrent(1)
    }
  }, [selectId]);

  useEffect(() => {
    if (JSON.stringify(lable) !== "{}") {
      form.setFieldsValue({
        ...lable,
      });
    }
  }, [lable]);
  lable
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const content = (
    <div>
      <p style={{ marginBottom: 0 }}>请选择背景颜色</p>
    </div>
  );
  const content1 = (
    <div>
      <p style={{ marginBottom: 0 }}>请选择字体颜色</p>
    </div>
  );

  return (
    <Modal
      cancelText="取消"
      okText="确定"
      onCancel={() => {
        form.resetFields();
        props.onCancel();
        dispatch(setDetail(undefined));
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (selectId) {
              dispatch(
                saveLabel({
                  ...lable,
                  ...values,

                })
              ).then(unwrapResult)
                .then(() => {
                  form.resetFields();
                  ref.current?.setIsModalVisible(false);
                  props.onOk();
                })
            } else {
              if (values?.backgroundColor) {
                dispatch(
                  saveLabel({
                    ...values,
                  })
                ).then(unwrapResult)
                  .then(() => {
                    form.resetFields();
                    ref.current?.setIsModalVisible(false);
                    props.onOk();
                  })

              } else {
                dispatch(
                  saveLabel({
                    ...values,
                  })
                ).then(unwrapResult)
                .then(() => {
                  form.resetFields();
                  ref.current?.setIsModalVisible(false);
                  props.onOk();
                })
              }

            }

          })
          .catch(() => {
            // do nothing.
          });
      }}
      visible={props.visible}
      title={state + "患者标签"}
      width="450px"
    >
      <Form
        form={form}
        initialValues={{
          backgroundColor: "#f80e0e",
          fontColor: "#FFFFFF",
        }}
        onValuesChange={(values) => {
          const back = values.backgroundColor;
          const color = values.fontColor
          const tag: any = document.getElementById("inputs")
          tag.style.background = back
          tag.style.color = color
        }}
        onFinish={(values) => {
          dispatch(
            saveLabel({
              ...values,
            })
          )

        }}
        name="advanced_search"
        className="ant-advanced-search-form"
        {...layout}
      >
        <Row gutter={20}>
          <Col span={20} style={{ textAlign: "right" }}>
            <Form.Item
              label="名称"
              name="name"
              rules={[{ required: true, message: "名称不能为空!" }, { max: 40, message: "最大长度40" }]}

            >
              <Input autoComplete="off"
                onChange={(e) => {
                  form.setFieldsValue({
                    headName: (e.target.value).substr(0, 1),
                  });
                }} />
            </Form.Item>
          </Col>
          <Col span={20} style={{ textAlign: "right" }}>
            <Form.Item
              label="推荐颜色"
              name="backgroundColor"
              style={{ display: "flex" }}

            >
              <div style={{ display: "flex" }}>
                {colorList.map((v: any, i) => (
                  <div key={v.index} style={{ display: "flex" }}>
                    <div
                      onClick={() => {
                        const back = v.backgorundColor;
                        const color = v.fontColor
                        const tag: any = document.getElementById("inputs")
                        const inputs: any = document.getElementById("colors")
                        const input: any = document.getElementById("colord")
                        tag.style.background = back
                        tag.style.color = color
                        inputs.value = color
                        input.value = back
                        form.setFieldsValue({
                          backgroundColor: back,
                          fontColor: color,
                          range: '0'
                        });
                      }
                      }

                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        textAlign: "center",
                        lineHeight: "30px",
                        color: "#FFFFFF",
                        backgroundColor: v.backgorundColor,
                        marginRight: "7px",
                      }}
                    >
                      {v.text}
                    </div>
                  </div>
                ))}
              </div>
            </Form.Item>
          </Col>
          <Col span={20} style={{ textAlign: "right" }}>
            <Form.Item label="缩写:" name="headName" rules={[{ required: true, message: "缩写不能为空!" }, { max: 1, message: "最多可输入一个字符" }]}>
              <Input autoComplete="off" disabled />

            </Form.Item>
          </Col>
          <Col span={20} style={{ textAlign: "right" }}>
            <Popover content={content1} trigger="hover" placement="right">
              <Form.Item label="字体颜色" name="fontColor" rules={[{ max: 45, message: "最大长度45" }]}>
                <Input
                  id="colors"
                  type="color"
                  autoComplete="off"

                  style={{ paddingLeft: "190px", paddingRight: "-40px" }}

                />
              </Form.Item>
            </Popover>
          </Col>
          <Col span={20} style={{ textAlign: "right" }}>
            <Popover
              content={content}
              trigger="hover"
              placement="right"
              style={{ height: "10px", padding: 0 }}
            >
              <Form.Item label="背景颜色" name="backgroundColor" rules={[{ max: 45, message: "最大长度45" }]}>
                <Input id="colord" type="color" style={{ padding: 0 }} autoComplete="off" />
              </Form.Item>
            </Popover>
          </Col>

          <Col span={6} style={{ textAlign: "right", marginLeft: "60px" }}>

            <Form.Item label="预览:" name="headName" >
              <Input id="inputs" className={styles.inputs} style={{ width: "38px", height: "38px", borderRadius: "50%", background: "#f80e0e", color: "#FFFFFF" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
