import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { use } from "echarts";
import "moment/locale/zh-cn";
import React, { ReactElement, useEffect, useRef } from "react";
import {
    DateRangeType,
    DateSelect,
    getDateRange,
} from "../../../compnents/form/DateSelect";

import { BeforeOperatingParmas } from "../../../services/beforeOperatingManagePage";
export const NurseQuerys = (props: {
    isModalVisible: boolean
    onValueChange: (queries: BeforeOperatingParmas) => void;
}): ReactElement => {

    const [form] = Form.useForm();

    const inputRef = useRef<any>(null)


    useEffect(() => {
        const [head, tail] = getDateRange(DateRangeType.Today);
        form.setFieldsValue({
            timeHead: head,
            timeTail: tail,
            range: '1',
        });
        inputRef.current!.focus({ //搜索框获取焦点
            cursor: 'start',
        });

        form.submit();
    }, []);

    useEffect(() => {
        if (!props.isModalVisible) {
            form.setFieldsValue({
                param: ''
            });
            form.submit();
        }
    }, [props.isModalVisible])
    return (
        <Form
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
            form={form}
            onFinish={(vs) => props.onValueChange(vs)}
        >
            <Row style={{ height: '80px' }} >
                <Space>
                    <Form.Item name='name' noStyle>
                        <Input
                            ref={inputRef}
                            autoComplete='off'
                            placeholder='消费者名称'
                            style={{ width: 160 }}
                            onChange={() => form.submit()}
                            prefix={<SearchOutlined />}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item name='surgicalItems' noStyle>
                        <Input
                            autoComplete='off'
                            placeholder='手术名称'
                            style={{ width: 160 }}
                            onChange={() => form.submit()}
                            prefix={<SearchOutlined />}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item name='doctor' noStyle >
                        <Input
                            autoComplete='off'
                            style={{ width: 160 }}
                            placeholder='医生名称'
                            onChange={() => form.submit()}
                            prefix={<SearchOutlined />}
                            allowClear
                        />

                    </Form.Item>
                    <Form.Item name='inappealState' noStyle >
                        <Select
                            showSearch
                            placeholder='处置状态'
                            style={{width: '8rem'}}
                            optionFilterProp='children'
                            allowClear
                            onChange={() => form.submit()}
                        >
                            <Select.Option value='1'>机构举证中</Select.Option>
                            <Select.Option value='4'>申诉人举证中</Select.Option>
                            <Select.Option value='8'>案件立案中</Select.Option>
                            <Select.Option value='10'>申诉完成</Select.Option>
                        </Select>
                    </Form.Item>
                        <DateSelect
                            labelPrefix=''
                            namePrefix='surgicalDate'
                            placeholder='手术时间'
                            onChange={() => form.submit()}
                            allowClear
                            style={{ width: '8rem' }}
                            onlyTime="1"
                        />

                        <DateSelect
                            labelPrefix=''
                            namePrefix='applyDate'
                            placeholder='申诉时间'
                            onChange={() => form.submit()}
                            allowClear
                            style={{ width: '8rem' }}
                            onlyTime="2"
                        />
                        <DateSelect
                            labelPrefix=''
                            namePrefix='disposeDate'
                            placeholder='处置时间'
                            onChange={() => form.submit()}
                            style={{ width: '8rem' }}
                            allowClear
                            onlyTime="3"
                        />

                    <Button type='primary' 
                        onClick={() => props.onValueChange(form.getFieldsValue())}>
                        查询
                    </Button>
                </Space>
            </Row>
        </Form>
    )
};
