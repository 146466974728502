/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-02 18:49:01
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface GetDoctorSaleEntriesParams {
  doctorId?: string
  timeHead?: string
  timeTail?: string
  _dateRange?: any
  isShowArea?: any
}
export interface GetDoctorAuditEntriesParams {
  doctorId?: string
  billingCategory?: string
  drugType?: number
  timeHead?: string
  timeTail?: string
}

export function getDoctorSale(params: GetDoctorSaleEntriesParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/doctorSaleListNew`,
    method: 'GET',
    params,
  }
}

export function getDoctorSaleArea(params: GetDoctorSaleEntriesParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/doctorSaleList`,
    method: 'GET',
    params,
  }
}



// 医生看板
export function doctorSaleBulletin(params: GetDoctorSaleEntriesParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/doctorSaleBulletin`,
    method: 'GET',
    params,
  }
}

export function getDoctorAuditExport(
  params: GetDoctorAuditEntriesParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/doctorSaleList/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}

