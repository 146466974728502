import { ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { traceRoute } from '../../../layouts/layoutSlice'
import { Dimen } from '../../../models/dimen'
import {
  getPatientSourceName,
  Patient,
  patientSources,
} from '../../../models/patient'
import {
  getSourceTypeName,
  SourceTypeCategories,
} from '../../../models/registration'
import { getGenderName } from '../../../models/user'
import { GetPatientListParams } from '../../../services/patient'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getAge } from '../../../utils/StringUtils'
import { removePatientAsync } from '../../A-toothModule/patient/patientSlice'
import { QrCodeModal } from '../../A-toothModule/toothHome/modal/qrCodeModal'
import { setPatientPageId } from '../../registration/register/registerSlice'
import { LabelComplete } from '../patientLable/LabelComplete'
import { MergePatientModal } from './mergePatientModal'
import { PurchaseModal } from './patientImportModal'
import {
  getPatientLabels,
  getPatientList,
  selectPatientPage,
} from './patientListSlice'
interface PatientsProps {
  state: string
}

export const Patients = ({ state }: PatientsProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const inputRef = useRef<any>(null)

  const history = useHistory()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const { Option } = Select

  const page = useSelector(selectPatientPage)

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  const [params, setParams] = useState<GetPatientListParams>({ current: 1 })

  const [labelList, setLabelList] = useState<any>([])

  const [mergePatientVisible, setMergePatientVisible] = useState(false)

  const [patientId, setPatientId] = useState('')

  const [qrCodeModal, setQrCodeModal] = useState(false) //二维码弹窗

  const [isPurchaseModalVisible, setIsPurchaseModalVisible] = useState(false) // 控制导入弹窗是否显示

  const [purchaseModalState, setPurchaseModalState] = useState<number>() //模板弹窗导入模板控制

  useEffect(() => {
    sessionStorage.setItem(location.pathname, JSON.stringify({ state }))
  }, [state])

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getPatientList({
        ...params,
        labelName: params.labelName ? params.labelName : undefined,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (params.name?.length == 9 && res.items.length == 1) {
          navigateTo(res.items[0])
        }
      })
      .finally(() => setPageLoading(false))
  }
  useEffect(() => {
    getPage()
  }, [dispatch, params])

  useEffect(() => {
    dispatch(getPatientLabels({ current: 1, size: 50 }))
      .then(unwrapResult)
      .then((res: any) => {
        setLabelList(res)
      })
    inputRef.current!.focus({
      cursor: 'start',
    })
  }, [])

  const navigateTo = (patient: Patient) => {
    dispatch(
      traceRoute({
        name: '门诊统计',
        path: '/patient/detail',
        query: `patientId=${patient.id}`,
      })
    )
  }

  const menu = (p: any) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setMergePatientVisible(true)
          setPatientId(p.id)
        }}
      >
        合并患者
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          Modal.confirm({
            centered: true,
            title: '确认操作',
            content: (
              <div>
                <ExclamationCircleFilled
                  style={{ color: '#FFAE55', fontSize: 18 }}
                />
                &nbsp;您确定删除 [{p?.name}] 患者吗？
              </div>
            ),
            okText: '确认',
            cancelText: '取消',
            icon: null,
            onOk: () => {
              dispatch(
                removePatientAsync({
                  ids: p?.id.toString(),
                })
              )
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: '删除成功',
                  })
                  setParams({
                    ...params,
                    current: 1,
                    size: 10,
                  })
                  getPage()
                })
            },
          })
        }}
      >
        删除
      </Menu.Item>
    </Menu>
  )

  const importTemplateFn = (state: number) => {
    // 导入模板
    setPurchaseModalState(state)
    setIsPurchaseModalVisible(true)
  }

  const exportExcel = () => {
    const theader = [
      '患者姓名',
      '病历号',
      '性别',
      '年龄',
      '身份证',
      '联系电话',
      '患者来源',
      '标签',
      '消费金额',
    ]

    const filterVal = [
      'name',
      'id',
      'sex',
      'ageYear',
      'idCardNumber',
      'phone',
      'source',
      'labelVo',
      'totalPayAmount',
    ]

    dispatch(
      getPatientList({
        ...params,
        labelName: params.labelName ? params.labelName : undefined,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const data = res?.items
          ?.map((item: any) => {
            return {
              ...item,
              patientName: item?.patientName ? item?.patientName : '-',
              id: item?.id > -1 ? item?.id : '-',
              sex: item?.sex ? getGenderName(item.sex) : '-',
              ageYear: getAge(item?.ageYear, item?.ageMonth),
              idCardNumber: item?.idCardNumber ? item?.idCardNumber : '-',
              phone: item?.phone ? item?.phone : '-',
              source: getSourceTypeName(Number(item?.source || 0)) || '-',
              labelVo: item?.labelVo?.length
                ? item?.labelVo?.map((v: any) => {
                    return v?.headName
                  })
                : '-',
              totalPayAmount: item?.totalPayAmount ? item?.totalPayAmount : '0',
            }
          })
          ?.map((s: any) => filterVal?.map((j: any) => s[j]))
        const datas = res?.items?.slice(0, 500)?.map((item: any) => {
          return {
            ...item,
            patientName: item?.patientName ? item?.patientName : '-',
            id: item?.id > -1 ? item?.id : '-',
            sex: item?.sex ? getGenderName(item.sex) : '-',
            ageYear: getAge(item?.ageYear, item?.ageMonth),
            idCardNumber: item?.idCardNumber ? item?.idCardNumber : '-',
            phone: item?.phone ? item?.phone : '-',
            source: getSourceTypeName(Number(item?.source || 0)) || '-',
            labelVo: item?.labelVo?.length
              ? item?.labelVo?.map((v: any) => {
                  return v?.headName
                })
              : '-',
            totalPayAmount: item?.totalPayAmount ? item?.totalPayAmount : '0',
          }
        })

        const exportData =
          res?.items?.length >= 500
            ? datas?.map((s: any) => filterVal?.map((j: any) => s[j]))
            : data
        import('../../../ExportXLSX/Export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: theader,
            data: exportData,
            filename: '患者管理',
            autoWidth: true,
            bookType: 'xlsx',
          })
        })
        if (res?.items?.length >= 500) {
          message.info('最多可导出500条')
        }
        setParams({
          ...params,
          current: 1,
          size: 10,
        })
      })
  }

  return (
    <Col
      style={{
        marginTop: 10,
        padding: '15px 15px 0 15px',
        height: 'calc(100% - 30px)',
        borderRadius: theme.br,
        backgroundColor: theme.pn,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Form
        form={form}
        autoComplete='off'
        onFinish={(values) => {
          setParams({
            ...params,
            ...values,
            labelIds: values?.labelIds?.toString(),
            current: 1,
          })
        }}
      >
        <Row
          style={{ marginBottom: 15 }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='name' noStyle>
              <Input
                ref={inputRef}
                allowClear
                style={{ width: 315 }}
                onChange={form.submit}
                placeholder='请输入姓名/手机号/助记码/病历号'
                prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
              />
            </Form.Item>
            <Form.Item name='labelIds' noStyle>
              <Select
                showSearch
                placeholder='标签选择'
                optionFilterProp='children'
                style={{ width: 180 }}
                mode='multiple'
                onChange={form.submit}
                onSearch={(v) => {
                  dispatch(getPatientLabels({ current: 1, size: 50, name: v }))
                    .then(unwrapResult)
                    .then((res: any) => {
                      setLabelList(res)
                    })
                }}
                filterOption={false}
              >
                {labelList.length &&
                  labelList.map((v: any) => (
                    <Option key={v.id} value={v.id}>
                      {v.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item name='source' noStyle>
              <Select
                allowClear
                placeholder='患者来源'
                style={{ width: 150 }}
                onChange={form.submit}
              >
                {SourceTypeCategories.map((v) => (
                  <Option key={v} value={v}>
                    {getSourceTypeName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button type='primary' htmlType='submit'>
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type='primary'
              onClick={() => {
                exportExcel()
              }}
            >
              导出
            </Button>
            <Button type='primary' onClick={() => importTemplateFn(1)}>
              患者信息导入
            </Button>
          </Space>
        </Row>
      </Form>
      <EditableList<Patient>
        style={{ flex: 1 }}
        loading={pageLoading}
        page={page}
        columns={[
          {
            title: '序号',
            key: 'no',
            width: Dimen.Num,
            align: 'center',
            render: (_1, _2, i) => `${page.size * (page.current - 1) + i + 1}`,
          },
          {
            title: '患者姓名',
            dataIndex: 'name',
            width: Dimen.Name,
            align: 'center',
          },
          {
            title: '病历号',
            key: 'id',
            width: Dimen.Sex,
            align: 'center',
            dataIndex: 'id',
          },
          {
            title: '性别',
            dataIndex: 'sex',
            align: 'center',
            render: function ShowActions(_, p) {
              return getGenderName(p.sex)
            },
          },
          {
            title: '年龄',
            dataIndex: 'ageYear',
            align: 'center',
            render: (_, r) => getAge(r?.ageYear, r?.ageMonth),
          },
          {
            title: '身份证',
            dataIndex: 'idCardNumber',
            width: Dimen.IdCardNumber,
            align: 'center',
          },
          {
            title: '联系电话',
            dataIndex: 'phone',
            width: Dimen.TelPhone,
            align: 'center',
          },
          {
            title: '患者来源',
            dataIndex: 'source',
            align: 'center',
            render: function ShowActions(_, p) {
              return getSourceTypeName(Number(p.source || 0))
            },
          },
          {
            title: '标签',
            dataIndex: 'labelVo',
            width: Dimen.Tag,
            align: 'center',
            render: function ShowActions(_, p) {
              return (
                <Space>
                  {!!p.labelVo.length &&
                    p.labelVo.map((v: any) => {
                      return (
                        <Tooltip key={v.id} title={v.name}>
                          <span
                            style={{
                              color: v.fontColor,
                              backgroundColor: v.backgroundColor,
                              cursor: 'pointer',
                              padding: '2px 5px',
                            }}
                          >
                            {v.headName}
                          </span>
                        </Tooltip>
                      )
                    })}
                </Space>
              )
            },
          },
          {
            title: '消费金额',
            dataIndex: 'totalPayAmount',
            width: Dimen.Tag,
            align: 'center',
            render: function ShowActions(_, p) {
              return p.totalPayAmount && p.totalPayAmount.toFixed(2)
            },
          },
          {
            title: '操作',
            key: 'actions',
            width: '8rem',
            align: 'center',
            render: function ShowActions(_, p) {
              return (
                <>
                  <Space>
                    <a
                      onClick={() => {
                        dispatch(setPatientPageId(p.id))
                        dispatch(
                          traceRoute({
                            name: '挂号',
                            path: '/registration',
                          })
                        )
                      }}
                    >
                      挂号
                    </a>
                    <a
                      onClick={() => {
                        dispatch(setPatientPageId(p.id))
                        dispatch(
                          traceRoute({
                            name: '预约',
                            path: '/addEditAppointment',
                          })
                        )
                      }}
                    >
                      预约
                    </a>
                    <a
                      onClick={() => {
                        dispatch(
                          traceRoute({
                            name: '随访',
                            path: '/followUp',
                          })
                        )
                      }}
                    >
                      随访
                    </a>
                    <Dropdown overlay={menu(p)} trigger={['click']}>
                      <a>更多</a>
                    </Dropdown>

                    {/*<a*/}
                    {/*  onClick={() => {*/}
                    {/*    setQrCodeModal(true)*/}
                    {/*    setPatientId(p.id)*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  二维码*/}
                    {/*</a>*/}
                  </Space>
                </>
              )
            },
          },
        ]}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size })
        }
        onRow={(p) => ({
          onDoubleClick: () => {
            navigateTo(p)
          },
        })}
      />
      {/* 二维码 */}
      <QrCodeModal
        visible={qrCodeModal}
        onCancel={() => {
          setQrCodeModal(false)
        }}
        onOk={() => {
          setQrCodeModal(false)
        }}
      />
      <MergePatientModal
        patientId={patientId}
        visible={mergePatientVisible}
        onCancel={() => setMergePatientVisible(false)}
        onOk={() => {
          getPage()
        }}
        type={1}
      />

      <PurchaseModal
        state={purchaseModalState}
        isPurchaseModalVisible={isPurchaseModalVisible}
        onOk={() => {
          setIsPurchaseModalVisible(false)
          getPage()
        }}
        onCancel={() => {
          setIsPurchaseModalVisible(false)
          getPage()
        }}
      />
    </Col>
  )
}
