/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-25 09:44:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-28 17:50:16
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Form, Modal, Select } from 'antd'
import React, { ReactElement, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { resetOralCase } from '../../../treatment/oralCases/oralCasesSlice'
import { reSetRegistration } from '../../../treatment/treatmentSlice'
import { getRegistByPatientIdGneralAsync } from '../patientSlice'

interface AddDisposeModalProps {
  type: number // 1 新增处置 2新增病历
  addVisible: boolean
  onOk: (v?: any) => void
  onCancel: () => void
  patientId: string
  registrationId?: string
}

const { Option } = Select

export const AddDisposeModal = ({
  type,
  addVisible,
  onOk,
  onCancel,
  patientId,
  registrationId,
}: AddDisposeModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [disposeSelectOptions, setDisposeSelectOptions] = useState<any[]>([])

  useEffect(() => {
    addVisible && patientId && getAllData()
  }, [addVisible, patientId])

  useEffect(() => {
    if (addVisible) {
      dispatch(reSetRegistration(undefined))
      dispatch(resetOralCase())
    }
  }, [addVisible])

  const getAllData = () => {
    dispatch(getRegistByPatientIdGneralAsync({ patientId, size: 30 }))
      .then(unwrapResult)
      .then((res: any) => {
        setDisposeSelectOptions(res)
        if (
          registrationId &&
          res.some((v: any) => v.registrationId == registrationId)
        ) {
          form.setFieldsValue({
            registrationId,
          })
        } else {
          if (res.length) {
            form.setFieldsValue({
              registrationId: res[0].registrationId,
            })
          }
        }
      })
  }

  return (
    <Modal
      title={type == 1 ? '新增处置' : '新增病历'}
      visible={addVisible}
      centered
      width={600}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      onOk={form.submit}
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={(vs) => {
          onOk(vs.registrationId)
          form.resetFields()
        }}
      >
        <Form.Item
          name='registrationId'
          label='就诊信息'
          rules={[{ required: true, message: '请选择就诊信息' }]}
        >
          <Select
            showSearch
            optionFilterProp='children'
            allowClear
            filterOption={(input, option: any) => {
              return (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }}
          >
            {disposeSelectOptions.length != 0 &&
              disposeSelectOptions.map((v: any) => (
                <Option key={v.registrationId} value={v.registrationId}>
                  {`${v.registrationTime} ${v.treatmentDoctorName} ${
                    v.insuranceCode == 'ZIFEI'
                      ? '自费'
                      : v.insuranceCode == '' || v.insuranceCode == null
                      ? ' 未知险种'
                      : '医保刷卡'
                  } (${v.treatmentDepartmentName}) ${v.disease}`}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
