/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-31 11:29:03
 * @LastEditors: sj
 * @LastEditTime: 2021-12-31 14:31:40
 */

// 代煎中心

// 代煎供应商枚举
export enum ReplaceMakeCategory {
  All = 0,
  Pharmacy = 1,
  Clinic = 2,
}

export const ReplaceMakeCategories = [
  ReplaceMakeCategory.All,
  ReplaceMakeCategory.Pharmacy,
  ReplaceMakeCategory.Clinic,
]

export function getReplaceMakeType(category: ReplaceMakeCategory): string {
  if (category === ReplaceMakeCategory.Pharmacy) {
    return '中药'
  } else if (category === ReplaceMakeCategory.Clinic) {
    return '颗粒剂'
  }
  return '全部'
}
export enum ReplaceMakeSupplierstatus {
  Normal = 0,
  Cancel = 1,
}
export const ReplaceMakeSupplierstatusies = [
  ReplaceMakeSupplierstatus.Normal,
  ReplaceMakeSupplierstatus.Cancel,
]

export function getReplaceMakeStatus(
  category: ReplaceMakeSupplierstatus
): string {
  if (category === ReplaceMakeSupplierstatus.Cancel) {
    return '已注销'
  }
  return '正常'
}

// 新建弹框枚举

export enum ReplaceMakeType {
  ChineseMedicine = 0,
  PM = 1,
}
export const ReplaceMakeTypes = [
  ReplaceMakeType.ChineseMedicine,
  ReplaceMakeType.PM,
]

export function getReplaceMakeTypes(category: ReplaceMakeType): string {
  if (category === ReplaceMakeType.PM) {
    return '颗粒剂'
  }
  return '中药'
}

export enum ReplaceMakeProcessType {
  WeiWaikeli = 1,
  WeiWaiyeti = 2,
  WeiWaiyaocai = 3,
  WeiWaigaofang = 4,
}

export const ReplaceMakeProcessTypes = [
  ReplaceMakeProcessType.WeiWaikeli,
  ReplaceMakeProcessType.WeiWaiyeti,
  ReplaceMakeProcessType.WeiWaiyaocai,
  ReplaceMakeProcessType.WeiWaigaofang,
]

export const ReplaceMakeProcessTypeName = [
  '委外代煎颗粒剂',
  '委外代煎袋装液体',
  '委外外配药材',
  '委外膏方代煎',
]

export function getReplaceMakeProcessTypeName(
  level: ReplaceMakeProcessType
): string {
  if (level <= 0 || level > ReplaceMakeProcessTypes.length) {
    return ''
  }
  return ReplaceMakeProcessTypeName[level - 1]
}

// 1顺丰 2 邮政 3 送医院(患者到医院自取) 99快递 (医院上传处方时不知道用哪家快递可用此编码，让煎药中心决定) 7药店自取 (患者到药企的药店取药)，10顺真 (上海地区)，15 东方汇 (上海地区)，98 其他，18 京东  ，20 同城配送
export enum carryTypeNum {
  SF = 1,
  YZ = 2,
  SYY = 3,
  KD = 99,
  YDZQ = 7,
  SZ = 10,
  DFH = 15,
  QT = 98,
  JD = 18,
  TCPS = 20,
}

export const carryTypeNumType = [
  carryTypeNum.SF,
  carryTypeNum.YZ,
  carryTypeNum.SYY,
  carryTypeNum.KD,
  carryTypeNum.YDZQ,
  carryTypeNum.SZ,
  carryTypeNum.DFH,
  carryTypeNum.QT,
  carryTypeNum.JD,
  carryTypeNum.TCPS,
]

export function getCarryTypeName(carryType: carryTypeNum) {
  switch (carryType) {
    case 1:
      return '顺丰'
    case 2:
      return '邮政'
    case 3:
      return '送医院'
    case 99:
      return '快递'
    case 7:
      return '药店自取'
    case 10:
      return '顺真(上海地区)'
    case 15:
      return '东方汇(上海地区)'
    case 98:
      return '其他'
    case 18:
      return '京东'
    case 20:
      return '同城配送'
    default:
      break
  }
}
