import { Rule } from 'antd/lib/form'
import { url } from 'node:inspector'
import { Treatment } from '../models/treatment'
import { API_PREFIX } from './constant'
import { Request, TextPlain } from './request'
import {treatmentBeauty} from "../features/medicalBeautyTemplate/MedicalBeautyTemplateSlice";

export function createTreatment(treatment: Treatment): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/submit`,
    method: 'POST',
    body: treatment,
  }
}

export interface GetTreatmentDetailParams {
  registrationId?: string
  treatmentId?: string
  medicalRecord?: number
}

export function getTreatment(params: GetTreatmentDetailParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/detail`,
    method: 'GET',
    params,
  }
}

export function getTreatmentDetail(params: GetTreatmentDetailParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/detail`,
    method: 'GET',
    params,
  }
}

// 获取updateFlag
export function selectTreatmentUpdateFlgs(treatmentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/selectTreatmentUpdateFlgs`,
    method: 'GET',
    params: {
      treatmentId,
    },
  }
}

export interface GetTreatmentListParams {
  current?: number
  medicalRecord?: number
  size?: number
  param?: string
  patientId?: string
  treatmentDepartmentId?: string
  treatmentDoctorId?: string
  treatmentTimeHead?: string
  treatmentTimeTail?: string
  flag?: any
  id?: any
  treatmentId?: any
  hideRecipeId?:any
}

export function getTreatmentList(params: GetTreatmentListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/page`,
    method: 'GET',
    params,
  }
}

// 选择查看历史病历
export function getTreatmentOldList(params: GetTreatmentListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/pageWithPayed`,
    method: 'GET',
    params,
  }
}
// 病历信息回显
export interface getMedicalRecordsShowFnParams {
  patientId?: string
}
export function getMedicalRecordsShowFn(params: getMedicalRecordsShowFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/autoCompletionHistoryPatientInfo`,
    method: 'GET',
    params,
  }
}

export interface CopyTreatmentParams {
  registrationId?: string
  srcTreatmentId: string
  copyRecipe: boolean
  copyWithAmount?: boolean
}

export function copyTreatment(params: CopyTreatmentParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/copy`,
    method: 'POST',
    body: params,
  }
}

// 病程
export function getPatientCourse(patientId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/getPatientCourse`,
    method: 'GET',
    params: {
      patientId
    },
  }
}

// 常用主诉列表
export function defaultMainComplaintList(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/defaultMainComplaintList`,
    method: 'POST',
  }
}


// 口腔常用主诉列表
export function defaultMainComplaintKQList(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/oralMainComplaintList`,
    method: 'GET',
  }
}


// 脉象
export function defaultMaiXiangList(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/defaulPulseList`,
    method: 'POST',
  }
}


// 舌象
export function defaultSheXiangList(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/defaulTongueList`,
    method: 'POST',
  }
}

export function defaulTallergenList(flag: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/allergen`,
    method: 'GET',
    params: {
      flag, //:1过敏源,2既往史,3家族史
    },
  }
}

export function getTreatmentNotice(patientId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/notice`,
    method: 'GET',
    params: {
      patientId,
    },
  }
}
export function getTab(registrationId?: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/selectmenu`,
    method: 'GET',
    params: {
      registrationId,
    },
  }
}

export interface urlLinkParams {
  fileName?: any
  bucketName?: any
}
export function getUrlLink(params: urlLinkParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/oss/endpoint/file-link`,
    method: 'GET',
    params,
  }
}

// 获取小程序上传附件二维码
export interface getWXUploadCodeFnParams {
  id?: string
  type?: number
}
export function getWXUploadCodeFn(body: getWXUploadCodeFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/weChat/tenant/getUserImageUploadCode`,
    method: 'POST',
    body,
  }
}

// 获取已上传文件
export function getUploadFileListFn(params: getWXUploadCodeFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/wx/upload/getUploadList`,
    method: 'GET',
    params,
  }
}
// 删除已上传文件
export interface deleteUploadFileListFnParams {
  ids: string[]
}
export function deleteUploadFileListFn(body: deleteUploadFileListFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/wx/upload/userUploadDel`,
    method: 'POST',
    body,
  }
}

export interface tabStateParams {
  medicalRecord?: number
  registrationId?: string
}

export function getCutTab(body: tabStateParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/checkrepetition`,
    method: 'POST',
    body,
  }
}

export interface BeautyParams {
  category: number
  version?: string
  parentId?: number
  templateType?:number
  level?:string
}

export function getBeautyClinic(params: BeautyParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalbeauty/list`,
    method: 'GET',
    params,
  }
}

export interface AddDetail {
  id?: number
  name?: string
  level: number
  parentId?:number
  tenantId?: string
  content?: string
  version?: string
  category: number
  isDeleted?: number
  templateType?:number
}

export function saveBeautyClinic(body: AddDetail): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalbeauty/submit`,
    method: 'POST',
    body,
  }
}

export function saveTreatmentBeauty(body: treatmentBeauty): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/saveTreatmentBeauty`,
    method: 'POST',
    body,
  }
}

export interface DetailBeauty {
  id: number
  registrationId: number
  version?: string
}

export function getBeautyDetail(params: DetailBeauty): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalbeauty/detail`,
    method: 'GET',
    responseType: 'text',
    params,
  }
}
export interface SaveBeautyBody {
  id?: string
  name: string
  registrationId: string
  category: string
  version?: string
  XMl: string
  medicalBeautyId?: string
  diseaseType?: string
}

export function SaveBeauty(body: SaveBeautyBody): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalbeautyitem/submit?id=${body.id}&name=${body.name}&registrationId=${body.registrationId}&category=${body.category}&version=${body.version}&medicalBeautyId=${body.medicalBeautyId}`,
    method: 'POST',
    headers: {
      'Content-Type': TextPlain,
    },
    body: body.XMl as string,
  }
}

// 医美/上传影像保存
export interface getVideoSaveFnParams {
  category?: number
  id?: string
  registrationId: string
  coherentVideo?: string
}

export function getVideoSaveFn(body: getVideoSaveFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalbeautyitem/submitImg`,
    method: 'POST',
    body,
  }
}

export interface MedicalParams {
  category: number
  registrationId: number
  showImgFlag?: number
  version?:string
}

export function getMedicalList(params: MedicalParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalbeautyitem/list`,
    method: 'GET',
    params,
  }
}

export interface MedicalDetail {
  id: number
}

export function getMedicalDetail(params: MedicalDetail): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalbeautyitem/detail`,
    method: 'GET',
    responseType: 'text',
    params,
  }
}

export interface MedicalRemove {
  ids: string
}

export function getMedicalRemove(params: MedicalRemove): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalbeautyitem/remove`,
    method: 'POST',
    params,
  }
}

export interface HistoryMedical {
  current?: number
  departmentId?: number
  doctorId?: number
  endTime?: string
  keyword?: string
  patientId?: number
  size?: number
  startTime?: string
}

export function getHistoryMedical(params: HistoryMedical): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/page`,
    method: 'GET',
    params,
  }
}

export interface PayTypeBody {
  itemPayType: number
  recipeItemId: number
}
// 更改收费类型
export function updateItemPayType(body: PayTypeBody): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/updateItemPayType`,
    method: 'POST',
    body,
  }
}

export interface HistoryCaseAsyncResponse {
  chiefComplaint: string
  createTime: string
  disease: string
  id: number
  insuranceName: string
  treatmentDepartmentName: string
  treatmentDoctorName: string
}

// 查看历史病历
export function getHistoryCaseAsync(params: { patientId: number }): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/getTreatmentHistory`,
    method: 'GET',
    params,
  }
}
export function treatmentCASignAsync(body: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/treatmentCASign`,
    method: 'POST',
    body,
  }
}
export function removeCASignAsync(body: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/removeCASign`,
    method: 'POST',
    body,
  }
}

// 查看医生是否可修改他人病历
export function isEditTreatment(): Request {
  return {
    url: `${API_PREFIX}/blade-user/function/nonpersonal`,
    method: 'GET',
  }
}

export interface editSTFnParams {
  patientId: number
  recipeId: number
  skinTestState: number
  skinTestStr: number
  tenantRecipeItemId: number
}
// 皮试
export function editSTFn(body: editSTFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/generate/skintest`,
    method: 'POST',
    body,
  }
}

// 新增治疗计划
export interface addSurePlanParams {
  comment: string
  doctorId: number
  isTemplete: number
  name: string
  patientId: number
  treatmentId?: string
  patientName?: string
  id?: string
  treatmentTpsDetail: [
    {
      accountUnit: string
      billingCategory: string
      drugCount: number
      localItemId: string
      name: string
      retailPrice: number
      toothStruct: string
    }
  ]
}
export function addSurePlan(body: addSurePlanParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/tpsSubmit`,
    method: 'POST',
    body,
  }
}

// 模板列表
export function templateList(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/tpsReadyToChoose`,
    method: 'POST',
  }
}

// 模板数据
export function templateDetail(templateId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/tpsChoose`,
    method: 'GET',
    params: {
      templateId,
    },
  }
}

// 保存牙位
export interface toothParams {
  itemTooth?: string
  recipeId?: number
  recipeItemId?: number
}
export function saveTooth(body: toothParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/itemToothSave`,
    method: 'POST',
    body,
  }
}

// 同步牙位
export function syncTooth(body: toothParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/itemToothSync`,
    method: 'POST',
    body,
  }
}

// 治疗计划列表、、
export interface curePlanListParams {
  treatmentId?: string
  patientId?: string
  parameter?: any
  startTime?: any
  endTime?: any
  tpsId?: any
}
export function curePlanList(body: curePlanListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/tpsFindInfo`,
    method: 'POST',
    body,
  }
}

// 删除一个计划
export function removeCurePlan(templateIdS: string[]): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/tpsRemove`,
    method: 'POST',
    body: {
      templateIdS,
    },
  }
}

// 批量导入计划
export interface importCurePlanParams {
  insuranceCode?: string
  recipeId?: string
  tpsId: []
  treatmentId: string
}
export function importCurePlan(body: importCurePlanParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/tpsImportRecipeItem`,
    method: 'POST',
    body,
  }
}
// 判断病历和医美病历数据是否合规
export function checkTreatment(treatmentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/checkTreatment`,
    method: 'POST',
    body: {
      treatmentId,
    },
  }
}

// 查看/选择 历史病历列表
export interface historicalRecordsListFnParams {
  current?: number
  size?: number
  patientId?: number
  startTime?: string
  endTime?: string
  version?: string
}

export function historicalRecordsListFn(
  params: historicalRecordsListFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalbeautyitem/historylist`,
    method: 'GET',
    params,
  }
}
// 查看/选择 历史病历复制
export interface historicalRecordsCopyFnParams {
  registrationId?: string
  oldId?: number
}

export function historicalRecordsCopyFn(
  body: historicalRecordsCopyFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalbeautyitem/copyMedicalBeautyItem`,
    method: 'POST',
    body,
  }
}

// 获取省市区列表
export interface cityCodeFnParams {
  current?: number
  size?: number
  regionLevel?: number
}
// 查询传入的指定的recipeId 或者treatmentId，是否全是自费项目(优先取recipeId)
export interface isAllZifeiParams {
  recipeIdList?: number[]
  treatmentId?: number
}
export function cityCodeFn(params: cityCodeFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/city/list`,
    method: 'GET',
    params,
  }
}
// 查询传入的指定的recipeId 或者treatmentId，是否全是自费项目
export function selectIsAllZifei(params: isAllZifeiParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/isAllZifei`,
    method: 'POST',
    body: params,
  }
}