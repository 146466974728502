/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-09-17 11:32:30
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-05 15:36:41
 */
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Modal, notification, Tabs } from 'antd'
import _ from 'lodash'
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { OutpatientAudit } from '../outpatientaudit/OutpatientAudit'
import { Inspect } from './inspect/inspect'
import styles from "./outPatientList.module.css";
import { useSelector } from "react-redux";
import { selectThreeMenus } from "../../layouts/layoutSlice";
import { selectEdition } from "../../app/applicationSlice";

const { TabPane } = Tabs

export const RealOutPatientList = (
  props: {
    state?: any //1是从套餐那边过来的,
    parentId?: string
  },
  ref: any
): ReactElement => {
  const InspectRef = useRef<any>()

  const threeMenus = useSelector(selectThreeMenus)?.filter(
    (v: any) => v.parentId == props?.parentId
  )

  const [flag, setFlag] = useState(true)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const getKeys = (i: string) => {
    switch (i) {
      case '通用版':
        return '0'
      case '卫监第二版':
        return '1'
      default:
        return '-'
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      webExport: () => {
        InspectRef.current?.webExport()
      },
    }),
    [InspectRef.current]
  )

  const [currentTab, setCurrentTab] = useState('0')

  useEffect(() => {
    if (!flag) return
    const tab = getKeys(threeMenus[0]?.name)
    console.log(tab)
    if (threeMenus?.length) {
      setCurrentTab(tab)
      setFlag(false)
    } else {
      setCurrentTab('0')
    }
  }, [props?.parentId, threeMenus])

  return (
    <>
      <Tabs
        className={styles.tabs}
        activeKey={currentTab}
        onChange={(t) => setCurrentTab(t)}
      >
        {threeMenus?.map((v: any) => {
          return (
            <>
              <TabPane key={getKeys(v?.name)} tab={v?.name}>
                {v?.name == '通用版' ? (
                  <OutpatientAudit
                    currentUserMode={false}
                    route={{
                      name: '',
                      path:
                        props?.state == 1
                          ? '/doctor/station' 
                          : hisVersion == 1
                          ? '/outpatientReport' 
                          : '/statistics/outpatient',
                    }}
                  />
                ) : v?.name == '卫监第二版' ? (
                  <Inspect ref={InspectRef} />
                ) : (
                  <></>
                )}
              </TabPane>
            </>
          )
        })}

        {/* <TabPane tab='通用版' key='0'>
          <OutpatientAudit
            currentUserMode={false}
            route={{
              name: '',
              path:
                props?.state == 1
                  ? '/doctor/station'
                  : '/statistics/outpatient',
            }}
          />
        </TabPane>
        <TabPane tab='卫监第二版' key='1'>
          <Inspect ref={InspectRef} />
        </TabPane> */}
      </Tabs>
      {currentTab === '1' ? (
        <>
          <Button
            type='primary'
            style={{
              position: 'absolute',
              top: '20px',
              right: '30px',
            }}
            onClick={() => {
              ref.current.webExport()
              // Modal.confirm({
              //   icon: <ExclamationCircleOutlined />,
              //   title:
              //     "导出时间最长90天，最大数量<=10000条，如需全量导出，联系客服",
              //   onOk: () => {
              //     ref.current.webExport();
              //   },
              // });
            }}
          >
            导出
          </Button>
          <p
            style={{
              position: 'absolute',
              top: '20px',
              right: '120px',
              lineHeight: '37px',
            }}
          >
            导出时间最长90天,最大数量&lt;=10000条，如需全量导出，联系客服
          </p>
        </>
      ) : currentTab === '0' ? (
        <p
          style={{
            position: 'absolute',
            top: '20px',
            right: '30px',
            lineHeight: '37px',
          }}
        >
          备注：一次最多支持导出3个月的数据
        </p>
      ) : (
        <></>
      )}
    </>
  )
}

export const OutPatientList = forwardRef(RealOutPatientList)
