/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-11 14:24:26
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-17 18:43:37
 */

import { Form, Space, Dropdown, Button, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cross } from '../../../../compnents/tooth/Cross';
import { CToothSel } from '../../../../compnents/tooth/CtoothSel';
import { TeethSel } from '../../../../compnents/tooth/TeethSel';
import { ToothMenu, ToothBal, TeethLab } from '../../../../compnents/tooth/ToothMenu';
import { ToothSel } from '../../../../compnents/tooth/ToothSel';
import { ToothTable } from '../../../../compnents/tooth/ToothTable';
import { selTeethKeyType, toothProps } from '../../oralCases/OralCases';
import styles from './RecipeItemModal.module.css'

export interface ToothComponentsProps {
  data?: any
  onChange?: (v: any, type?: number) => void
  isEdit?: boolean
  isShowSave?: boolean
  showSubSelect?: boolean
  hiddenSelect?: (v: any) => void // 隐藏时触发
}

export const ToothComponents = ({
  data = '--@--@--@--',
  onChange,
  isEdit = true,
  isShowSave = true,
  showSubSelect,
  hiddenSelect,
}: ToothComponentsProps): any => {
  const [diagnosisForm] = Form.useForm()

  const { Item } = Form

  const [flag, setFlag] = useState(false)

  useEffect(() => {
    if (data) {
      const toothData = tooth.map((v, i) => {
        if (i === 0) {
          return data
            ?.split('#')
            .map((item: string, index: string | number) => {
              return {
                teethStr: item.split('@').map((current) => {
                  return current === '--' ? '' : current
                }),
                modalShow: false,
                // Check: treatment.stomatologyCheck?.split('#').map((v) => {
                //   return v
                // })[index],
              }
            }) as any
          // return v;
        }
      })
      setFlag(true)
      setTooth(toothData)
    }
  }, [data])

  const [tooth, setTooth] = useState([
    [{ teethStr: ['', '', '', ''], modalShow: false, }],
  ])

  useEffect(() => {
    if (isShowSave) return //口腔专业版生效
    onChange && onChange(tooth[0][0].teethStr, 2)
  }, [tooth])

  useEffect(() => {
    if (!tooth[0][0].modalShow && flag) {
      hiddenSelect && hiddenSelect(tooth[0][0].teethStr)
    }
  }, [tooth[0][0].modalShow])

  const [selTeethKey, setSelTeethKey] = useState<selTeethKeyType>()

  const [menuKey, setMenuKey] = useState(0)

  const Menu = ({
    syn,
    toothIndex,
    teethIndex,
    authChangeTooth,
  }: toothProps) => {
    return (
      <>
        <div
          style={{
            height: 'auto',
            background: '#fff',
            width: 'auto',
            border: '1px solid rgba(224, 224, 224, 0.644)',
            position: 'relative',
          }}

        >
          <span
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 999,
              cursor: 'pointer',
            }}
            onClick={() => {
              toothChange(toothIndex, 'notShow', teethIndex)
            }}
          >
            X
          </span>
          <ToothMenu
            defaultKey={menuKey}
            click={(v) => {
              setMenuKey(v)
            }}
          >
            <ToothBal name='恒牙'>
              <div style={{ display: 'flex' }} >
                <div>
                  <Row
                    gutter={10}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 0)
                        }}
                      >
                        全
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 1)
                        }}
                      >
                        上
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 2)
                        }}
                      >
                        下
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 3)
                        }}
                      >
                        清空
                      </TeethLab>
                    </Col>
                  </Row>
                  <Cross style={{ width: '410px' }} pos={true}>
                    <ToothSel
                      defaultTeethKey={tooth[toothIndex][teethIndex].teethStr}
                      selTooth={(sel, t, tp) => {
                        setSelTeethKey({
                          sel: sel,
                          tooth: t,
                          teeth: tp,
                        })
                      }}
                      teethSelect={(v) => {
                        toothChange(toothIndex, 'toothChange', teethIndex, v)
                      }}
                    ></ToothSel>
                  </Cross>
                  {syn && isShowSave && (
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Button type='primary' ghost onClick={() => {
                        onChange && onChange(tooth[0][0]?.teethStr, 0)
                      }}>
                        同步牙位
                      </Button>
                          <Button type='primary' style={{ marginLeft: 10 }} ghost onClick={() => {
                            onChange && onChange(tooth[0][0]?.teethStr, 1)
                            toothChange(0, 'notShow', 0)
                          }}>
                            保存
                      </Button>
                    </Row>
                  )}
                </div>
                {
                  showSubSelect && (
                    <TeethSel
                      MC={selTeethKey?.sel}
                      teethKey={selTeethKey?.teeth}
                      defaultTeethKey={
                        tooth[toothIndex][teethIndex].teethStr[
                        selTeethKey?.tooth || 0
                        ]
                      }
                      selTeethKey={(v) => {
                        const selTooth = tooth[toothIndex][teethIndex].teethStr.map(
                          (item, index) => {
                            if (index === selTeethKey?.tooth) {
                              return v
                            } else {
                              return item
                            }
                          }
                        )
                        toothChange(toothIndex, 'toothChange', teethIndex, selTooth)
                      }}
                      style={{ marginLeft: '30px', marginTop: '30px' }}
                    ></TeethSel>
                  )
                }
              </div>
            </ToothBal>
            <ToothBal name='乳牙'>
              <div style={{ display: 'flex' }}>
                <div>
                  <Row
                    gutter={10}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 0)
                        }}
                      >
                        全
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 1)
                        }}
                      >
                        上
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 2)
                        }}
                      >
                        下
                      </TeethLab>
                    </Col>
                    <Col>
                      <TeethLab
                        labClick={() => {
                          getToothKey(toothIndex, teethIndex, 3)
                        }}
                      >
                        清空
                      </TeethLab>
                    </Col>
                  </Row>
                  <Cross style={{ width: '260px' }} pos={true}>
                    <CToothSel
                      defaultTeethKey={tooth[toothIndex][teethIndex].teethStr}
                      selTooth={(sel, t, tp) => {
                        setSelTeethKey({
                          sel: sel,
                          tooth: t,
                          teeth: tp,
                        })
                      }}
                      teethSelect={(v) => {
                        toothChange(toothIndex, 'toothChange', teethIndex, v)
                      }}
                    ></CToothSel>
                  </Cross>
                  {syn && isShowSave && (
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Button type='primary' ghost onClick={() => {
                        onChange && onChange(tooth[0][0]?.teethStr, 0)
                      }}>
                        同步牙位
                      </Button>
                          <Button type='primary' style={{ marginLeft: 10 }} ghost onClick={() => {
                            onChange && onChange(tooth[0][0]?.teethStr, 1)
                            toothChange(0, 'notShow', 0)
                          }}>
                            保存
                      </Button>
                    </Row>
                  )}
                </div>
                {
                  showSubSelect && (
                    <TeethSel
                      MC={selTeethKey?.sel}
                      teethKey={selTeethKey?.teeth}
                      defaultTeethKey={
                        tooth[toothIndex][teethIndex].teethStr[
                        selTeethKey?.tooth || 0
                        ]
                      }
                      selTeethKey={(v) => {
                        const selTooth = tooth[toothIndex][teethIndex].teethStr.map(
                          (item, index) => {
                            if (index === selTeethKey?.tooth) {
                              return v
                            } else {
                              return item
                            }
                          }
                        )
                        toothChange(toothIndex, 'toothChange', teethIndex, selTooth)
                      }}
                      style={{ marginLeft: '30px', marginTop: '30px' }}
                    ></TeethSel>
                  )
                }
              </div>
            </ToothBal>
          </ToothMenu>
        </div>
      </>
    )
  }

  const toothChange = (
    currentIndex: number,
    changeMove: string,
    changeIndex: number,
    selToothStr?: string[]
  ) => {
    if (changeMove === 'show') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.map((item, index) => {
              if (index === changeIndex) {
                return {
                  ...item,
                  modalShow: true,
                }
              } else {
                return {
                  ...item,
                  modalShow: false,
                }
              }
            })
          } else {
            return v.map((item) => {
              return {
                ...item,
                modalShow: false,
              }
            })
          }
        })
      )
    } else if (changeMove === 'notShow') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.map((item, index) => {
              if (index === changeIndex) {
                return {
                  ...item,
                  modalShow: false,
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (changeMove === 'notAllShow') {
      setTooth(
        tooth.map((v, i) => {
          return v.map((item, index) => {
            return {
              ...item,
              modalShow: false,
            }
          })
        })
      )
    } else if (changeMove === 'toothChange') {
      setTooth(
        tooth.map((v, i) => {
          if (i === currentIndex) {
            return v.map((item, index) => {
              if (index === changeIndex) {
                return {
                  ...item,
                  teethStr: selToothStr || ['', '', '', ''],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    }
  }

  const changeTooth = (record: any) => {
    setTooth(
      tooth.map((v, i) => {
        if (i === 0) {
          return v
        } else {
          return v.map((item) => {
            return {
              ...item,
              teethStr: record,
            }
          })
        }
      })
    )
  }
  const getToothKey = (toothIndex: number, teethIndex: number, key: number) => {
    if (key === 0) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: !menuKey
                    ? [
                      '1,2,3,4,5,6,7,8',
                      '1,2,3,4,5,6,7,8',
                      '1,2,3,4,5,6,7,8',
                      '1,2,3,4,5,6,7,8',
                    ]
                    : ['A,B,C,D,E', 'A,B,C,D,E', 'A,B,C,D,E', 'A,B,C,D,E'],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (key === 1) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: !menuKey
                    ? ['1,2,3,4,5,6,7,8', '1,2,3,4,5,6,7,8', '', '']
                    : ['A,B,C,D,E', 'A,B,C,D,E', '', ''],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (key === 2) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: !menuKey
                    ? ['', '', '1,2,3,4,5,6,7,8', '1,2,3,4,5,6,7,8']
                    : ['', '', 'A,B,C,D,E', 'A,B,C,D,E'],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    } else if (key === 3) {
      setTooth(
        tooth.map((v, i) => {
          if (i === toothIndex) {
            return v.map((item, index) => {
              if (index === teethIndex) {
                return {
                  ...item,
                  teethStr: ['', '', '', ''],
                }
              } else {
                return item
              }
            })
          } else {
            return v
          }
        })
      )
    }
  }

  const getTeethStr = (v: string) => {
    const teethStr = v.substring(1)
    return teethStr.replace('-', ',')
  }

  const renderTeeth = (toothIndex: number, teethIndex: number, i: number) => {
    if (i % 2) {
      return tooth[toothIndex][teethIndex].teethStr[i]?.split(',').map((v) => {
        if (v.length !== 1) {
          return v ? (
            <>
              {v[0]}
              <sup>{getTeethStr(v)}</sup>
            </>
          ) : (
            ''
          )
        } else {
          return v
        }
      })
    } else {
    
      const data = tooth[toothIndex][teethIndex].teethStr[i]
        ?.split(',')
        .sort(function (a: any, b: any) {
          return b.substr(0, 1) - a.substr(0, 1)
        })
      const letters: any = data?.filter((element: any) => isNaN(element)) // 过滤出字母

      letters?.reverse() // 反转字母数组顺序
      const newData = data?.map((element: any) =>
        isNaN(element) ? letters?.shift() : element
      ) // 将反转后的字母按顺序放回原数组
      return newData?.map((v) => {
        if (v.length > 1) {
          return v ? (
            <>
              {v[0]}
              <sup>{getTeethStr(v)}</sup>
            </>
          ) : (
            ''
          )
        } else {
          return v
        }
      })
    }
  }

  useEffect(() => {
    diagnosisForm.setFieldsValue({
      stomatologyTooth: ['']
    })
  }, [])

  return (
    <Form
      form={diagnosisForm}
      colon={false}
      className={styles.info}
      autoComplete='off'
      labelCol={{
        flex: '6.5rem',
      }}
      // onMouseLeave={() => {
      //   toothChange(0, 'notShow', 0)
      // }}
    >
      <div className={styles.itemList}>
        <Form.List name='stomatologyTooth'>
          {(fields) => (
            <Item>
              {fields.map((field, index) => (
                  <Space
                    key={field.key}
                    align='baseline'
                    style={{ display: 'flex', alignItems: 'center' }}
                  // onMouseLeave={() => {
                  //   toothChange(0, 'notShow', index)
                  // }}
                  >
                    <Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.sights !== curValues.sights
                      }
                    >
                      {() => (
                        <Item required>
                          <Dropdown
                            overlay={
                            <Menu
                                syn={true}
                                toothIndex={0}
                                teethIndex={index}
                                authChangeTooth={() => {
                                  changeTooth(tooth[0][index].teethStr)
                                }}
                              />
                            }
                            placement='bottomLeft'
                            getPopupContainer={(triggerNode: any) =>
                              triggerNode.parentNode
                            }
                          visible={isEdit && tooth[0][index].modalShow}
                          // visible={true}
                          onVisibleChange={(v) => {
                              if (!v) {
                                toothChange(0, 'notShow', index)
                              }
                            }}
                          >
                            <ToothTable
                              tableShow={() => {
                                toothChange(0, 'show', index)
                              }}
                              LT={renderTeeth(0, index, 0)}
                              RT={renderTeeth(0, index, 1)}
                              LB={renderTeeth(0, index, 2)}
                              RB={renderTeeth(0, index, 3)}
                            />
                          </Dropdown>
                        </Item>
                      )}
                    </Item>
                  </Space>
              )
              )}
            </Item>
          )}
        </Form.List>
      </div>
    </Form>
  );
}