/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 11:16:34
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-18 16:53:33
 */

import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './patientList.module.css'
import { Columns } from './columns'
import { RootDispatch } from '../../../../app/store'
import { EditableList } from '../../../../compnents/list/EditableList'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import {outpatientProjectOptions, sourceOptions} from '../../../../models/toothPatient'
import { TableRowSelection } from 'antd/lib/table/interface'
import { getPatientList } from '../../../patient/list/patientListSlice'
import { useHistory } from 'react-router-dom'
import { PatientGroupModal } from '../components/group'
import { PatientPanel } from '../patientPanel/patientPanel'
import {
  getCounselor,
  getMessageCount,
  selectSendIDs,
  setSendIDs,
} from '../../toothHome/toothHomeSlice'
import {
  getPatientByGroupAsync,
  getPatientGroupAsync,
  removePatientAsync,
  selectAttendingDoctorList,
  selectCounselorList,
  setAttendingDoctorList,
  setCounselorList,
  setGroupList,
} from '../patientSlice'
import { MergePatientModal } from '../../../patient/list/mergePatientModal'
import { SendMessageModal } from '../../toothHome/modal/sendMessageModal'
import { SubscribeModal } from '../../toothHome/modal/subscribeModal'
import { RegisteredModal } from '../../toothHome/modal/registeredModal'
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
  ToothDay,
} from '../../../../compnents/form/DateSelect'
import { PurchaseModal } from '../../../patient/list/patientImportModal'
interface PatientListsProps {
  groupId?: number
  refreshFlag?: number
}

export interface PatientListsRef {
  refresh: () => void
}

const PatientLists = (
  { groupId, refreshFlag }: PatientListsProps,
  ref: React.Ref<PatientListsRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const history = useHistory()

  const [form] = Form.useForm()

  const attendingDoctorList = useSelector(selectAttendingDoctorList)

  const counselorList = useSelector(selectCounselorList)

  const [loading, setLoading] = useState(false)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 20,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  // const [selectIds, setSelectIds] = useState<any>([])
  const selectedKeys = useSelector(selectSendIDs)

  const [PatientGroupModalType, setPatientGroupModalType] = useState(0)

  const [groupVisible, setGroupVisible] = useState(false)

  const [currentId, setCurrentId] = useState('')

  const [currentPatientId, setCurrentPatientId] = useState('')

  const [mergePatientVisible, setMergePatientVisible] = useState(false)

  const [sendMessageModal, setSendMessageModal] = useState(false) // 发送短信弹窗

  const [sendMsgObj, setSendMsgObj] = useState<any>(null)

  const [subscribeModal, setSubscribeModal] = useState(false) // 工作站-预约弹窗

  const [tablePppointmentId, setTablePppointmentId] = useState('') //  表格预约 ID

  const [tableRegistrationId, setTableRegistrationId] = useState('') //  表格挂号 ID

  const [tableTreatmentDoctorId, setTableTreatmentDoctorId] = useState('') //  表格=预约-就诊医生 ID

  const [registeredModal, setRegisteredModal] = useState(false) //工作站-挂号弹窗

  const [isPurchaseModalVisible, setIsPurchaseModalVisible] = useState(false) // 控制导入弹窗是否显示

  const [purchaseModalState, setPurchaseModalState] = useState<number>() //模板弹窗导入模板控制

  useImperativeHandle(ref, () => ({
    refresh: () => {
      getPage(true)
    },
  }))

  useEffect(() => {
    dispatch(
      getCounselor({
        current: 1,
        size: 100,
        stationCategory: 1,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(
          setAttendingDoctorList(
            res?.records
              ?.filter((v: any) => v.kssxbz != 1)
              .filter((v: any) => v.state == 1)
          )
        )
      })
    dispatch(
      getCounselor({
        current: 1,
        size: 100,
        stationCategory: 4,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(
          setCounselorList(
            res?.records
              ?.filter((v: any) => v.kssxbz != 1)
              .filter((v: any) => v.state == 1)
          )
        )
      })
    dispatch(getMessageCount())
    return () => {
      dispatch(setSendIDs([]))
    }
  }, [])

  const getPage = (flag = false) => {
    setLoading(true)
    dispatch(
      getPatientByGroupAsync({
        ...params,
        current: flag ? 1 : params.current,
        groupId,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const data = {
          total: res.total,
          items: res.records,
        }
        setPage(data)
        // setSelectIds([])
        // dispatch(setSendIDs([]))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params])

  useEffect(() => {
    getPage(true)
    setCurrentId('')
    setParams({
      ...params,
      current: 1,
    })
  }, [groupId, refreshFlag])

  // useImperativeHandle(ref, () => ({
  //   reload: () => {
  //     dispatch(getPaymentList(params))
  //   },
  // }))

  const disable = (callback: () => void) => {
    if (selectedKeys.length == 0) {
      notification.info({
        message: '请先选择患者',
      })
      return
    }
    callback()
  }

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          disable(() => {
            setSendMsgObj(null)
            setSendMessageModal(true)
          })
        }}
      >
        发送短信
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          disable(() => {
            setPatientGroupModalType(4)
            setGroupVisible(true)
          })
        }}
      >
        患者交接
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          disable(() => {
            setPatientGroupModalType(3)
            setGroupVisible(true)
          })
        }}
      >
        加入分组
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          disable(() => {
            setPatientGroupModalType(5)
            setGroupVisible(true)
          })
        }}
      >
        更改分组
      </Menu.Item>
    </Menu>
  )
  const importTemplateFn = (state: number) => {
    // 导入模板
    setPurchaseModalState(state)
    setIsPurchaseModalVisible(true)
  }

  return (
    <Col
      style={{
        height: '100%',
      }}
    >
      {currentId ? (
        <PatientPanel
          editGroupId={currentId}
          appointmentId={tablePppointmentId} //预约 ID
          patientId={currentId}
          backID={currentId}
          backCall={() => {
            setCurrentId('')
          }}
        />
      ) : (
        <div className={styles.main}>
          <Form
            form={form}
            onFinish={(values) => {
              setParams({
                ...params,
                ...values,
                current: 1,
                registrationTimeHead: undefined,
                registrationTimeTail: undefined,
                registEndTime: values.registrationTimeTail,
                registStartTime: values.registrationTimeHead,
              })
            }}
          >
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              // gutter={10}
            >
              <div style={{ marginBottom: '10px' }}>
                <Form.Item name='key' noStyle>
                  <Input
                    autoComplete='off'
                    prefix={<SearchOutlined />}
                    placeholder='患者姓名/助记码/手机号/病历号'
                    allowClear
                    onChange={form.submit}
                    style={{
                      width: '16.2rem',
                      marginLeft: '10px',
                      marginRight: '6px',
                    }}
                  />
                </Form.Item>
                <Form.Item name='attendingDoctorId' noStyle>
                  <Select
                    placeholder='主治医生'
                    allowClear
                    style={{ width: '7.5em', marginRight: '6px' }}
                    onChange={form.submit}
                    showSearch
                    filterOption={(input, option: any) =>
                      (option?.children ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {attendingDoctorList.map((d) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.realname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name='counselorId' noStyle>
                  <Select
                    placeholder='咨询师'
                    allowClear
                    showSearch
                    style={{ width: '7em', marginRight: '6px' }}
                    onChange={form.submit}
                    filterOption={(input, option: any) =>
                      (option?.children ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {counselorList.map((d) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.realname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name='treatmentItem' noStyle>
                  <Select
                    placeholder='就诊项目'
                    allowClear
                    style={{ width: '7.5em', marginRight: '6px' }}
                    onChange={form.submit}
                  >
                    {outpatientProjectOptions.map((d) => (
                      <Select.Option key={d.name} value={d.name}>
                        {d.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <DateSelect
                  placeholder='最近一次挂号'
                  style={{ width: '8rem', marginRight: '6px' }}
                  onChange={form.submit}
                  labelPrefix=''
                  namePrefix='registrationTime'
                />
                <Form.Item name='introducer' noStyle>
                  <Input
                    autoComplete='off'
                    placeholder='介绍人'
                    allowClear
                    style={{
                      width: '7.5rem',
                      marginRight: '6px',
                    }}
                  />
                </Form.Item>

                <Form.Item name='source' noStyle>
                  <Select
                    placeholder='患者来源'
                    allowClear
                    style={{ width: '7.5em', marginRight: '6px' }}
                    onChange={form.submit}
                  >
                    {sourceOptions.map((d) => (
                      <Select.Option key={d.name} value={d.value}>
                        {d.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button type='primary' htmlType='submit'>
                  查询
                </Button>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <Button
                  type='primary'
                  style={{
                    backgroundColor: '#FFB841',
                    borderColor: '#FFB841',
                    marginRight: '6px',
                  }}
                  onClick={() => {
                    history.push('/toothPatient/label')
                  }}
                >
                  标签设置
                </Button>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button
                    style={{ marginRight: '6px' }}
                    type='primary'
                    className={styles.add}
                    disabled={selectedKeys.length == 0}
                  >
                    批量操作
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <Button type='primary' onClick={() => importTemplateFn(1)}>
                  导入患者
                </Button>
              </div>
            </Row>
          </Form>
          <EditableList
            bordered
            loading={loading}
            scroll={{ x: 1550 }}
            sticky
            className={styles.table}
            rowKey={(v, i) => {
              return `${v.patient.id}`
            }}
            page={{
              current: params.current,
              size: params.size,
              total: page.total,
              items: page.items,
            }}
            columns={Columns(
              params.size * (params.current - 1),
              (action, t) => {
                setCurrentPatientId(t.patient.id)
                setTablePppointmentId(t.registration.appointmentId)
                if (action == 'merge') {
                  setMergePatientVisible(true)
                } else if (action == 'delete') {
                  dispatch(
                    removePatientAsync({
                      ids: [t.patient.id].toString(),
                    })
                  )
                    .then(unwrapResult)
                    .then(() => {
                      notification.success({
                        message: '删除成功',
                      })
                      dispatch(getPatientGroupAsync())
                        .then(unwrapResult)
                        .then((res: any) => {
                          dispatch(setGroupList(res.records))
                          getPage()
                        })
                    })
                } else if (action == 'message') {
                  const data = {
                    name: t.patient.name,
                    sex: t.patient.sex,
                    ageYear: t.patient.ageYear,
                    phone: t.patient.phone,
                    id: t.patient.id,
                  }
                  dispatch(setSendIDs([]))
                  setSendMsgObj(data)
                  setSendMessageModal(true)
                } else if (action == 'appointment') {
                  setSubscribeModal(true)
                } else if (action == 'registration') {
                  setRegisteredModal(true)
                }
              }
            )}
            onChangePage={(current, size) =>
              setParams({
                ...params,
                current,
                size: size || 20,
              })
            }
            onRow={(r) => ({
              onDoubleClick: () => {
                setCurrentId(r.patient.id)
                setTablePppointmentId(r.registration.appointmentId)
              },
              // onClick: () => {
              //   let data = []
              //   if (!selectedKeys.includes(r.id.toString())) {
              //     data = [r.id.toString(), ...selectedKeys]
              //   } else {
              //     data = selectedKeys.filter(v => v != r.id.toString())
              //   }
              //   dispatch(setSendIDs(data))
              // }
            })}
            rowSelection={{
              selectedRowKeys: selectedKeys,
              onSelectAll: (
                selected: any,
                selectedRows: any,
                changeRows: any
              ) => {
                let data: any = []
                if (selected) {
                  data = [
                    ...changeRows.map((v: any) => v.patient.id.toString()),
                    ...selectedKeys,
                  ]
                } else {
                  data = selectedKeys.filter(
                    (item) =>
                      !changeRows.some(
                        (ele: any) => ele.patient.id.toString() === item
                      )
                  )
                }
                dispatch(setSendIDs(data))
              },
              onSelect: (record, selected, selectedRows) => {
                let data = []
                if (selected) {
                  data = [record.patient.id.toString(), ...selectedKeys]
                } else {
                  data = selectedKeys.filter(
                    (v) => v != record.patient.id.toString()
                  )
                }
                dispatch(setSendIDs(data))
              },
            }}
          />
        </div>
      )}

      <PatientGroupModal
        type={PatientGroupModalType}
        visible={groupVisible}
        onCancel={() => {
          setGroupVisible(false)
          dispatch(setSendIDs([]))
        }}
        patientIds={selectedKeys}
        onOk={() => {
          setGroupVisible(false)
          getPage()
        }}
      />

      <MergePatientModal
        patientId={currentPatientId}
        visible={mergePatientVisible}
        onCancel={() => setMergePatientVisible(false)}
        onOk={() => {
          getPage()
        }}
        type={2}
      />

      <SendMessageModal
        sendMsgObj={sendMsgObj}
        visible={sendMessageModal}
        onCancel={() => {
          setSendMsgObj(null)
          setSendMessageModal(false)
          dispatch(setSendIDs([]))
        }}
        onOk={() => {
          setSendMsgObj(null)
          // getTableList()
        }}
      />

      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={currentPatientId} //患者 ID
        appointmentId={tablePppointmentId} //预约 ID
        registrationId={tableRegistrationId} //挂号 ID
        treatmentDoctorId={tableTreatmentDoctorId} //就诊医生 ID
        onOk={() => {
          setSubscribeModal(false)
          setParams({ ...params, current: 1 })
        }}
        onCancel={() => setSubscribeModal(false)}
      />

      {/* 工作站-挂号 */}
      <RegisteredModal
        title={0}
        patientId={currentPatientId}
        appointmentId={tablePppointmentId} //预约 ID
        registrationId={tableRegistrationId} //挂号 ID
        visible={registeredModal}
        onOk={() => {
          setRegisteredModal(false)
          setParams({ ...params, current: 1 })
        }}
        onCancel={() => {
          setRegisteredModal(false)
        }}
      />
      <PurchaseModal
        state={purchaseModalState}
        isPurchaseModalVisible={isPurchaseModalVisible}
        onOk={() => {
          setIsPurchaseModalVisible(false)
          getPage()
        }}
        onCancel={() => {
          setIsPurchaseModalVisible(false)
          getPage()
        }}
      />
    </Col>
  )
}

export const PatientList = forwardRef(PatientLists)
