/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: sj
 * @LastEditTime: 2022-06-15 15:25:44
 */
import { ConfigProvider, notification } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Login } from '../features/login/Login'
import { OAuthorProxy } from '../features/login/OAuthorProxy'
import { DefaultLayout } from '../layouts/DefaultLayout'
import { ErrorCode, NoErrorState } from '../models/error'
import { DefaultTheme } from '../theme/Default'
import { ThemeContext } from '../theme/ThemeContext'
import './App.less'
import {
  selectCredentials,
  selectErrorState,
  setCredentials,
  setErrorState,
} from './applicationSlice'

function App(): ReactElement {
  const dispatch = useDispatch()

  const credentials = useSelector(selectCredentials)

  const errorState = useSelector(selectErrorState)

  useEffect(() => {
    if (errorState.code !== ErrorCode.Ok) {
      notification.error({ message: errorState.message, duration: 3 })
      dispatch(setErrorState(NoErrorState))
    }
  }, [dispatch, errorState])

  useEffect(() => {
    // 处理代码分离后的单点登录问题
    try{
      const data = window.name && JSON.parse(window.name)
      if (data) {

        localStorage.clear()
      }
      if (data.accessToken) {
        window.name = ''
        const token = data.accessToken as string
        dispatch(setCredentials({
          creds: {
            ...data,
            applicationType: 1,
            id: data.userId?.toString(),
            name: data.userName,
            departmentId: data.outpatientDepartmentId?.toString(),
            tenantName: data.tenantName,
            shortName: data.shortName || '',
            token,
            endTime: data.endTime,
            stationCategory: data.stationCategory,
          },
          rememberMe: false,
        }))
      }
    }catch(e){
      //do nothing
    }

  }, [window.name])


  return (
    <ConfigProvider locale={zhCN}>
      <ThemeContext.Provider value={DefaultTheme}>
        <Switch>
          <Route path={['/login/callback', '/userCenter/callback']}>
            <OAuthorProxy />
          </Route>
          <Route path='*'>
            {credentials ? <DefaultLayout /> : <Login qrcodeEnabled={true} />}
          </Route>
        </Switch>
      </ThemeContext.Provider>
    </ConfigProvider>
  )
}

export default App
