/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-27 19:04:42
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-11 15:23:14
 */
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Select } from 'antd'
const { Option } = Select
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  pageDurgsystem,
  selectDurgsystemData,
} from '../durgsystem/durgsystemSlice'
import { pageSupplier } from '../wms/supplierSlice'
import {
  DepartmentDate,
  pagePutstorageUser,
  selectDepartData,
  selectPutstorageUser,
  setCategory,
  setCurrent,
} from '../putstorage/putstorageSlice'
import { putStorePageParams } from '../../services/putstorage'
import { DateRangeFieldName, DateSelect } from '../../compnents/form/DateSelect'
import { sleectUserData } from '../putstorelist/putstorelistSlice'
import { User } from '../../models/user'
import { getStorehouseTagName, StorehouseTags } from '../../models/category'
import styles from '../putstorage/putstorage.module.css'
import { useLocation } from 'react-router-dom'

export const uploadOptions = [
  {
    value: 1,
    label: '已上传',
  },
  {
    value: 0,
    label: '未上传',
  },
]

export const InventoryCheckingQuery = (props: {
  YBUploadType?: boolean
  onValueChange: (queries: putStorePageParams) => void
  massUpload: () => void
}) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const location: any = useLocation()

  const userData = useSelector(sleectUserData)

  const departmentData = useSelector(selectDepartData)

  const durgData = useSelector(selectDurgsystemData)

  // const putstorageUser = useSelector(selectPutstorageUser);

  const storageParams = JSON.parse(
    sessionStorage.getItem(
      location.pathname + '_' + location?.state?.category
    ) || '{}'
  )

  useEffect(() => {
    dispatch(pageDurgsystem(1, 100, 1))
    dispatch(pageSupplier({ current: 1, size: 10, supplierCategory: 0 }))
    dispatch(DepartmentDate({ current: 1, size: 1000 }))
    // dispatch(pagePutstorageUser({ current: 1, size: 1000 }));
  }, [])

  // useEffect(() => {
  // const userData = JSON.parse(JSON.stringify(putstorageUser));
  // const data = userData.filter((v: any, i: any, userData: any[]) => {
  //   return userData.findIndex((item) => item.userName === v.userName) === i;
  // });
  // setuserdata(data);
  // }, [putstorageUser]);

  useEffect(() => {
    if (location?.state?.category == '8') {
      if (Object.keys(storageParams).length) {
        form.setFieldsValue({
          ...storageParams,
        })
      }
      form.submit()
    }
  }, [])

  const refresh = () => {
    if (location?.state?.category == '8') {
      props.onValueChange({
        ...storageParams,
        ...form.getFieldsValue(),
        recepitsDateStart: form.getFieldsValue().registrationTimeHead,
        recepitsDateEnd: form.getFieldsValue().registrationTimeTail,
        registrationTimeHead: null,
        registrationTimeTail: null,
        current: 1,
      })
    } else {
      props.onValueChange({
        ...form.getFieldsValue(),
        recepitsDateStart: form.getFieldsValue().registrationTimeHead,
        recepitsDateEnd: form.getFieldsValue().registrationTimeTail,
        registrationTimeHead: null,
        registrationTimeTail: null,
        current: 1,
      })
    }

    dispatch(setCurrent(1))
  }

  return (
    <Form
      form={form}
      name='control-hooks'
      onFinish={(fs) => {
        if (location?.state?.category == '8') {
          const data = storageParams
          props.onValueChange({
            ...data,
            ...fs,
          })
          dispatch(setCurrent(data.current || 1))
        } else {
          props.onValueChange({
            ...fs,
          })
          dispatch(setCurrent(1))
        }
      }}
    >
      <Row
        gutter={11}
        style={{ height: '42px', position: 'relative', zIndex: 999 }}
        wrap={false}
      >
        <Col span={4}>
          <Form.Item name='allNum'>
            <Input
              placeholder='输入业务单号'
              allowClear
              prefix={<SearchOutlined />}
              onChange={refresh}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='storehouseId'>
            <Select
              placeholder='库房列表'
              allowClear
              showSearch
              dropdownClassName={styles.select}
              optionFilterProp='children'
              onChange={refresh}
              // suffixIcon={<CaretDownFilled />}
              onSelect={(key: any) => {
                dispatch(setCategory(key))
              }}
            >
              {durgData.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='departmentId'>
            <Select
              placeholder='业务部门'
              allowClear
              showSearch
              dropdownClassName={styles.select}
              optionFilterProp='children'
              onChange={refresh}
              // suffixIcon={<CaretDownFilled />}
            >
              {departmentData.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='userId'>
            <Select
              showSearch
              placeholder='业务人员'
              allowClear
              dropdownClassName={styles.select}
              onChange={refresh}
              // suffixIcon={<CaretDownFilled />}
            >
              {userData.map((v: User) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={2}>
          <Form.Item name="supplierId">
            <Select
              placeholder="对方单位"
              allowClear
              showSearch
              optionFilterProp="children"
            >
              {supplierData.map((v: any, i) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.companyName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col> */}
        {!props.YBUploadType && (
          <Col span={3}>
            <Form.Item name='storehouseTag'>
              <Select
                placeholder='是否登账'
                allowClear
                // suffixIcon={<CaretDownFilled />}
                dropdownClassName={styles.select}
                onChange={refresh}
              >
                {StorehouseTags.map((v) => (
                  <Option value={v} key={v}>
                    {getStorehouseTagName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {props.YBUploadType && (
          <Col span={3}>
            <Form.Item name='ybUploadFlag'>
              <Select
                placeholder='是否已上传'
                allowClear
                // suffixIcon={<CaretDownFilled />}
                dropdownClassName={styles.select}
                onChange={refresh}
              >
                {uploadOptions.map((v) => (
                  <Option value={v.value} key={v.value}>
                    {v.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col flex={2}>
          <Form.Item name='recepitsDateEnd'>
            <DateSelect
              placeholder='发生时间'
              style={{ width: '8.5rem' }}
              labelPrefix=''
              namePrefix='registrationTime'
              onChange={refresh}
            />
          </Form.Item>
        </Col>

        <Col span={3} style={{ display: 'flex',justifyContent:'flex-end' }}>
          <Button htmlType='submit' type='primary'
                  style={{ marginRight: '10px' }}
          >
            查询
          </Button>
          {props.YBUploadType && (
              <Button type='primary' onClick={() => props.massUpload()}>
                批量上传
              </Button>
          )}
        </Col>
      </Row>
    </Form>
  )
}
