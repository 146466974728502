import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface RecipeQueryParams {
    current?: number,
    size?: number,
    param?: string,
    category?: string,
    timeHead?: string,
    timeTail?: string,
    doctorId?: number
    departmentId?: number
}

export function pageRecipeQuery(params: RecipeQueryParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipe/recipeQuery`,
    method: 'GET',
    params,
  }
}