/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-08-16 15:24:14
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface ToBeCompletedTreatmentParams {
  current?: number
  size?: number
  state?: number
  patientId?: string
  startTime?: any
  endTime?: any
}

export function ToBeCompletedTreatmentList(
  params: ToBeCompletedTreatmentParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalstation/page`,
    method: 'GET',
    params,
  }
}

export interface ExcuteDetailListParams {
  id?: any
}

export function ExcuteDetailList(params: ExcuteDetailListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalstation/detail`,
    method: 'GET',
    params,
  }
}

export interface ExcuteTableListParams {
  id?: any
}

export function ExcuteTableList(params: ExcuteTableListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalstation/itemdetail`,
    method: 'GET',
    params,
  }
}

export interface ToBeCompletedTreatmentSubmitParams {
  id?: any
}

export function ToBeCompletedTreatmentSubmit(
  body: ToBeCompletedTreatmentSubmitParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalstation/execute`,
    method: 'POST',
    body,
  }
}
// 项目
export interface ToBeCompletedTreatmentProjectParams {
  state?: any
}

export function ToBeCompletedTreatmentProject(
  params: ToBeCompletedTreatmentProjectParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalstation/servicenamelist`,
    method: 'GET',
    params,
  }
}
// 执行人
export interface LatelyOperatorParams {
  state?: any
  latelyOperatorName?: any
}

export function LatelyOperatorPeople(params: LatelyOperatorParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/medicalstation/operatorlist`,
    method: 'GET',
    params,
  }
}
