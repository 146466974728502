/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-03 20:22:17
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-29 11:30:39
 */
import { API_PREFIX } from "./constant";
import { drugStaticParams } from "./drugStatic";
import { Request } from "./request";


export function getDrugStaticDetail(params: drugStaticParams): Request {
    return {
      url: `${API_PREFIX}/blade-outpatient/statistics/drugSaleDetail`,
      method: "GET",
      params,
    };
    }


// 详情导出
export function drugStaticDetailExoprt(params: drugStaticParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/drugSaleDetail/exportExcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}

    