import { PlusOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {Button, message, notification, Table} from 'antd'
import React, {ReactElement, useEffect, useMemo, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditableList } from '../../../compnents/list/EditableList'
import { NavBar } from '../../../compnents/nav/NavBar'
import { statementParams } from '../../../services/statement'
import { StatementColumns } from './columns'
import { StatementModal } from './Modal'
import { StatementQuery } from './Query'
import {
  getStatementList,
  selectCurrent,
  selectStatement,
  selectTotal,
  setCurrent,
  selectGetDoctorSaleEntriesParams,
  getStatementExoprt,
  selectPageLoading,
  setPageLoading,
  getStatementLists,
} from './statementSlice'
import styles from './statement.module.css'
import moment from 'moment'
import {
  DateTimeFormat,
  DateTimeFormatSimpleOnly,
} from '../../../models/datetime'
import { RootDispatch } from '../../../app/store'
import { TabBar, TabPane } from '../../../compnents/widgets/TabBar'
import { selectTenantName } from '../../../app/applicationSlice'
import {statementPrintBody, statementSubPrintBody} from '../../../models/statementPrint'
export const Statement = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [queries, setQueries] = useState<statementParams>({})

  const Current = useSelector(selectCurrent)

  const total = useSelector(selectTotal)

  const data: any = useSelector(selectStatement)

  const [size, setSize] = useState(10)

  const [printData, setPrintData] = useState<any>()

  const [
    printNoDataCustomizedAmountArray,
    setPrintNoDataCustomizedAmountArray,
  ] = useState<any>()

  const pageLoadingRX = useSelector(selectPageLoading)

  const tenantName = useSelector(selectTenantName)

  useEffect(() => {
    dispatch(setPageLoading(true))
    if (queries?.timeHead && queries?.timeTail){
      dispatch(getStatementList({ ...queries, current: Current, size }))
    dispatch(getStatementLists({ ...queries, current: Current, size }))
      .then(unwrapResult)
      .then((v: any) => {
        setPrintData(v?.page?.records)
        setPrintNoDataCustomizedAmountArray(v?.customizedAmountArray)
      })
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)}
  }, [queries, size, Current])

  const treatmentCostExport = () => {
    dispatch(getStatementExoprt({ ...queries, current: Current, size }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '销售员日结.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  // 动态合计行
  const groupByName = (arr: any) => {
    const groupedData: any = {}
    // 遍历原始数组
    arr?.forEach(function (item: any) {
      const name = item.payMethodName
      // 如果名称在 groupedData 中不存在，创建一个新数组
      if (!groupedData[name]) {
        groupedData[name] = []
      }
      // 将当前项添加到对应名称的数组中
      groupedData[name].push(item)
    })
    // 将结果从对象转换为数组
    const result = Object.values(groupedData)
    return result
  }

  const getTotalPageList = () => {
    return !data?.length
      ? printNoDataCustomizedAmountArray?.map((v: any) => {
        return { payMethodName: v?.payMethodName, totalValue: 0 }
      })
      : groupByName(
        data
          ?.map((v: any) => {
            return v?.customizedCollectionAmountArray
          })
          ?.flat(2)
      )
        ?.map((paymentMethod: any) => {
          const payMethodName = paymentMethod[0].payMethodName
          const totalValue = paymentMethod.reduce(
            (total: any, payment: any) =>
              Number(total) + Number(payment.payAmount),
            0
          )
          return { payMethodName, totalValue }
        })
        ?.sort((a: any, b: any) =>
          a.payMethodName?.localeCompare(b.payMethodName)
        )

  }



  const getTitleList = () => {
    return !data?.length
      ? printNoDataCustomizedAmountArray
      : data?.[0]?.customizedCollectionAmountArray?.map((v: any) => {
        return { payMethodName: v?.payMethodName, payMethod: v?.payMethod }
      });
  }

  const getDataMainList = async() => {
    return await dispatch(getStatementLists({ ...queries, current: Current, size }))
      .then(unwrapResult)
      .then((v: any) => {
        setPrintNoDataCustomizedAmountArray(v?.customizedAmountArray)
        return {
          tenantName: tenantName?.split('-')?.[0],
          dataList: v?.page?.records?.map((v: any) => {
            return {
              ...v,
              checkTime: v.checkStartTime + '~' + v.checkEndTime,
            }
          }),
          timeHead: moment(queries?.timeHead).format(DateTimeFormatSimpleOnly),
          timeTail: moment(queries?.timeTail).format(DateTimeFormatSimpleOnly),
          titleList: getTitleList(),
          totalPageList: getTotalPageList(),
        };
      })

  }



  return (
    <>
      <TabBar style={{ margin: '10px 20px', position: 'relative' }}>
        <TabPane key='0' tab='销售员日结'>
          <Button
            style={{ position: 'absolute', right: 20, top: 3 }}
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            一键日结
          </Button>
          <div className={styles.content}>
            <StatementQuery
              onValueChange={(v) => {
                setQueries({ ...queries, ...v })
                dispatch(setCurrent(1))
              }}
              outClued={() => {
                treatmentCostExport()
              }}
              print={(v) => {
                const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                const data = getDataMainList();
                data.then((res) => {
                  const content = statementPrintBody(res)
                  page.document.write(content) // 写入打印页面的内容
                  page.print() // 打印
                  page?.close();
                })
              }}
              subPrint={(v) =>{
                const page: any = window.open('', '_blank') // 打开一个新窗口，用于打印
                const data = getDataMainList();
                data.then((res) => {
                  const content = statementSubPrintBody(res)
                  page.document.write(content) // 写入打印页面的内容
                  page.print() // 打印
                  page?.close();
                })
              }}
            />
            <EditableList
              className={styles.statement}
              loading={pageLoadingRX}
              summary={() => (
                  <Table.Summary fixed>
                      <Table.Summary.Row className={styles.total}>
                          <Table.Summary.Cell index={0} align='center'></Table.Summary.Cell>
                          <Table.Summary.Cell index={1} align='center'>
                              合计
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2}></Table.Summary.Cell>
                          <Table.Summary.Cell index={3} align='right'>
                              { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.totalPayAmount || 0), 0), [data]).toFixed(2)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4} align='right'>
                              { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.shouldPayAmount || 0), 0), [data]).toFixed(2)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5} align='right'>
                              { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.insurancePayAmount || 0), 0), [data]).toFixed(2)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6} align='right'>
                              { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.aggregateAmount || 0), 0), [data]).toFixed(2)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7} align='right'>
                              { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.cashAmount || 0), 0), [data]).toFixed(2)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={8} align='right'>
                              { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.alipayAmount || 0), 0), [data]).toFixed(2)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={9} align='right'>
                              { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.wechatAmount || 0), 0), [data]).toFixed(2)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={10} align='right'>
                              { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.membershipCardPaymentAmount || 0), 0), [data]).toFixed(2)}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={11} align='right'>
                              { useMemo(() => data.reduce((acc : any, item : any) => acc + (item.otherPaymentAmount || 0), 0), [data]).toFixed(2)}
                          </Table.Summary.Cell>
                          {getTotalPageList()?.map((v: any,index:number) => {
                              return (
                                  <>
                                      <Table.Summary.Cell index={12 + index} align='right'>
                                          {Number(v?.totalValue)?.toFixed(2)}
                                      </Table.Summary.Cell>
                                  </>
                              )
                          })}
                      </Table.Summary.Row>
                  </Table.Summary>
              )}
              rowKey={(t: any) => t.id}
              // rowKey={(_, i) => queries ? (queries.size || 0) * (queries.current || 0) + (i || 0) : 0}
              onChangePage={(current, pageSize) => {
                setSize(pageSize as number)
                dispatch(setCurrent(current))
              }}
              page={{
                items: data,
                current: Current,
                size: size,
                total: total,
              }}
              columns={StatementColumns(
                (id) => {
                  dispatch(getStatementList({ current: 1 }))
                },
                size * (Current - 1),
                data,
                printNoDataCustomizedAmountArray
              )}
            />
          </div>
        </TabPane>
      </TabBar>
      <StatementModal
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false)
          dispatch(getStatementList({ ...queries, current: Current, size }))
        }}
        onCancel={() => {
          setIsModalVisible(false)
          dispatch(getStatementList({ ...queries, current: Current, size }))
        }}
      />
    </>
  )
}
