/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-01 12:00:34
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-11 17:16:50
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Button, DatePickerProps, Form, Input, Modal, Row, Space } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import {
  DateSelect,
  DateRangeType,
  DateRangeTypeNotInvoicedQuery,
} from '../../../compnents/form/DateSelect'
import { EditableList } from '../../../compnents/list/EditableList'
import { traceRoute } from '../../../layouts/layoutSlice'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import { getNoInvoicedQueryList } from '../confirm/paymentConfirmSlice'
import styles from './InvoiveRecord.module.css'

interface ChargeSettingModalProps {
  visible: boolean,
  status?:any,
  onCancel: () => void
  onOk: () => void
}

export const NotInvoicedQueryModal = ({
  visible,
  status,
  onCancel,
  onOk,
}: ChargeSettingModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [dates, setDates] = useState<any>([]);

  const [startValue, setStartValue] = useState(null);

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
    startTime:moment().startOf('day').format(DateTimeFormat),
    endTime:moment().endOf('day').format(DateTimeFormat)
  })

  const [pageLoading, setPageLoading] = useState(false)

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const disabledThreeMonthDate:any = (current:any) => {
    const tooLate = dates[0] && current.diff(dates[0], 'months') > 3;
    const tooEarly = dates[1] && dates[1].diff(current, 'months') > 3;
    return tooEarly || tooLate;
  };

    // 处理日期变化的函数
    const onDateChange = (values:any) => {
      setDates(values);
      if (values.length === 1) {
        setStartValue(values[0]);
      } else {
        setStartValue(null);
      }
    };

  useEffect(() => {
    if (visible) {
      setPageLoading(true)
      dispatch(getNoInvoicedQueryList({ ...params }))
        .then(unwrapResult)
        .then((res) => {
          setPageLoading(false)
          const data = {
            total: res.total,
            items: res.records,
          }
          setPage(data)
        })
        .catch((v) => {
          setPageLoading(false)
        })
    }
  }, [params, visible])

  return (
    <Modal
      visible={visible}
      title='未开票查询'
      width={1100}
      maskClosable={false}
      onCancel={onCancel}
      footer={null}
      className={styles.notInvoicedStyle}
    >
      <Form
        form={form}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        autoComplete='off'
        onFinish={(values: any) => {
          setParams({
            ...params,
            current:1,
            startTime: values?.startHead
              ? moment(values?.startHead)?.format(DateTimeFormat)
              : undefined,
            endTime: values?.startTail
              ? moment(values?.startTail)?.format(DateTimeFormat)
              : undefined,
          })
        }}
      >
        <Row style={{ marginTop: 10, marginBottom: 20 }}>
          <DateSelect
            style={{ width: '9.5rem', marginRight: '10px' }}
            labelPrefix=''
            namePrefix='start'
            placeholder='时间范围-时间'
            allowClear={false}
            onCalendarChange={((v)=>{
              onDateChange(v)
            })}
            disabledDate={disabledThreeMonthDate}
            options={DateRangeTypeNotInvoicedQuery}
            initialValue={DateRangeType.Today}
            onChange={() => form.submit()}
          />
          <Button type='primary' onClick={() => form.submit()}>
            查询
          </Button>
        </Row>
        <EditableList
          style={{
            width: '100%',
            height: '450px',
            overflow: 'hidden',
          }}
          scroll={{ y: '25rem' }}
          loading={pageLoading}
          page={{
            current: params.current,
            size: params.size,
            total: page.total,
            items: page.items,
          }}
          columns={[
            {
              title: '序号',
              key: 'no',
              align: 'center',
              width: '5rem',
              render: (_1, _2, i) =>
                `${params.size * (params.current - 1) + i + 1}`,
            },
            {
              title: '患者姓名',
              dataIndex: 'patientName',
              align: 'center',
              width: '8rem',
              ellipsis: true,
            },
            {
              title: '病历号',
              key: 'patientId',
              align: 'center',
              dataIndex: 'patientId',
              width: '8rem',
              ellipsis: true,
            },
            {
              title: '险种',
              dataIndex: 'insuranceCode',
              width: '10em',
              align: 'center',
              ellipsis: true,
              render: (_, t: any) => {
                return t.insuranceCode === 'ZIFEI' || !t.insuranceCode || !t
                  ? '自费'
                  : '智慧医保'
              },
            },
            {
              title: '报销金额',
              dataIndex: 'insurancePayAmount',
              align: 'center',
              width: '6rem',
              ellipsis: true,
              render: (_, t: any) => (t?.insurancePayAmount >= 0 ? t?.insurancePayAmount.toFixed(2) : '-'),
            },
            {
              title: '现金金额',
              dataIndex: 'cashAmount',
              align: 'center',
              width: '6rem',
              ellipsis: true,
              render: (_, t: any) => (t?.cashAmount >= 0 ? t?.cashAmount.toFixed(2) : '-'),
            },
            {
              title: '总金额',
              dataIndex: 'totalAmount',
              align: 'center',
              width: '7rem',
              ellipsis: true,
              render: (_, t: any) => (t?.totalAmount >= 0 ? t?.totalAmount.toFixed(2) : '-'),
            },
            {
              title: '收费时间',
              dataIndex: 'payTime',
              align: 'center',
              width: '8rem',
              ellipsis: true,
              render: (_, t: any) => {
                return t?.payTime
                  ? moment(t?.payTime).format(DateTimeFormatSimple)
                  : '-'
              },
            },
            {
              title: '操作',
              key: 'action',
              align: 'center',
              width: '8rem',
              ellipsis: true,
              render: function Element(_, t:any) {
                return (
                  <Space>
                    <Button
                      type='link'
                      onClick={() => {
                        dispatch(
                          traceRoute({
                            name: '收费',
                            path: '/payment/result',
                            query: `paymentId=${t.paymentId}&&status=${status}`,
                            state: {
                              name:'发票明细',
                              path: '/payment',
                              routeName: '发票明细',
                            },
                          })
                        )
                      }}
                    >
                      开票
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          onChangePage={(current: any, size: any) =>
            setParams({
              ...params,
              current,
              size: size || 10,
            })
          }
          onRow={() => ({
            onDoubleClick: () => {
              return
            },
          })}
        />
      </Form>
    </Modal>
  )
}
