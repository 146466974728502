// 0 阴性 1 阳性 2弱阳性 3强阳性 skinTestResult
export enum SkinTestResult {
  Negtive = 0,
  Positive = 1,
  Weak = 2,
  Strong = 3,
}

export function getSkinTestResultName(result: SkinTestResult): string {
  switch (result) {
    case SkinTestResult.Negtive:
      return "阴性";
    case SkinTestResult.Positive:
      return "阳性";
    case SkinTestResult.Weak:
      return "弱阳性";
    case SkinTestResult.Strong:
      return "强阳性";
  }
  return "";
}

export interface SkinTest {
  id: string;
  actualTime: string;
  drugName: string;
  outpatientNo: number;
  patientAge: number;
  patientId: string;
  patientMnemonicCode: string;
  patientName: string;
  patientPhone: string;
  patientSex: number;
  presetTime: string;
  receiveInAdvance: number;
  receiveTag: number;
  registrationCategory: number;
  registrationDepartmentId: string;
  registrationDepartmentName: string;
  registrationDoctorId: string;
  registrationDoctorName: string;
  registrationFeeServiceId: string;
  registrationPayState: number;
  registrationState: number;
  registrationTime: string;
  result: SkinTestResult;
  settlementTypesOfInsurance: number;
  skinTestId: string;
  testUser: number;
  testUserName: string;
  treatmentDepartmentId: string;
  treatmentDepartmentName: string;
  treatmentDoctorId: string;
  treatmentDoctorName: string;
  treatmentId: string;
  treatmentTime: string;
}

export function fromJson(
  json: {
    id?: number;
    patientId?: number;
    registrationDepartmentId?: number;
    registrationDoctorId?: number;
    skinTestId?: number;
    treatmentDepartmentId?: number;
    treatmentDoctorId?: number;
    treatmentId: number;
  } & Record<string, never>
): SkinTest {
  return {
    id: json.id?.toString() || "",
    actualTime: json.actualTime,
    drugName: json.drugName,
    outpatientNo: json.outpatientNo,
    patientAge: json.patientAge,
    patientId: json.patientId?.toString() || "",
    patientMnemonicCode: json.patientMnemonicCode,
    patientName: json.patientName,
    patientPhone: json.patientPhone,
    patientSex: json.patientSex,
    presetTime: json.presetTime,
    receiveInAdvance: json.receiveInAdvance,
    receiveTag: json.receiveTag,
    registrationCategory: json.registrationCategory,
    registrationDepartmentId: json.registrationDepartmentId?.toString() || "",
    registrationDepartmentName: json.registrationDepartmentName,
    registrationDoctorId: json.registrationDoctorId?.toString() || "",
    registrationDoctorName: json.registrationDoctorName,
    registrationFeeServiceId: json.registrationFeeServiceId,
    registrationPayState: json.registrationPayState,
    registrationState: json.registrationState,
    registrationTime: json.registrationTime,
    result: json.result,
    settlementTypesOfInsurance: json.settlementTypesOfInsurance,
    skinTestId: json.skinTestId?.toString() || "",
    testUser: json.testUser,
    testUserName: json.testUserName,
    treatmentDepartmentId: json.treatmentDepartmentId?.toString() || "",
    treatmentDepartmentName: json.treatmentDepartmentName,
    treatmentDoctorId: json.treatmentDoctorId?.toString() || "",
    treatmentDoctorName: json.treatmentDoctorName,
    treatmentId: json.treatmentId?.toString() || "",
    treatmentTime: json.treatmentTime,
  };
}
