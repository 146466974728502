import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Form, Row, Select, Space } from "antd";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch } from "../../../app/store";
import { DateSelect } from "../../../compnents/form/DateSelect";
import { EditableList } from "../../../compnents/list/EditableList";
import { traceRoute } from "../../../layouts/layoutSlice";
import { Department } from "../../../models/department";
import { Dimen } from "../../../models/dimen";
import { PatientPayment } from "../../../models/patientpayment";
import { GetPatientPaymentListParams } from "../../../services/payment";
import { ThemeContext } from "../../../theme/ThemeContext";
import { getFullDeparmentList } from "../../completion/completionSlice";
import {
  getPatientPaymentList,
  selectPatientPaymentPage,
} from "./patientDetailSlice";

interface PatientPaymentsProps {
  patientId?: string;
}

export const PatientPayments = ({
  patientId,
}: PatientPaymentsProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const theme = useContext(ThemeContext);

  const [form] = Form.useForm();

  const [departments, setDepartments] = useState<Department[]>([]);

  const [params, setParams] = useState<GetPatientPaymentListParams>({
    current: 1,
    patientId,
  });

  const page = useSelector(selectPatientPaymentPage);

  useEffect(() => {
    dispatch(getFullDeparmentList({ current: 1, size: 1000, status: 1 }))
      .then(unwrapResult)
      .then((departments) => setDepartments(departments));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPatientPaymentList(params));
  }, [dispatch, params]);

  return (
    <Col
      style={{
        height: '100%',
        padding: '15px 15px 0 15px',
        borderRadius: `0 0 ${theme.br}px ${theme.br}px`,
        backgroundColor: theme.pn,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Form
        form={form}
        autoComplete='off'
        onFinish={(values) => setParams({ ...params, ...values, current: 1 })}
      >
        <Row
          style={{ marginBottom: 15 }}
          justify='space-between'
          align='middle'
        >
          <Space>
            <Form.Item noStyle name='departmentId'>
              <Select
                placeholder='科室名称'
                style={{ width: 180 }}
                allowClear
                onChange={form.submit}
              >
                {departments.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <DateSelect
              placeholder='结算时间'
              labelPrefix=''
              namePrefix='time'
              style={{ width: 180 }}
              onChange={form.submit}
            />
          </Space>
          <Button type='primary' onClick={form.submit}>
            查询
          </Button>
        </Row>
      </Form>

      <EditableList<PatientPayment>
        page={page}
        columns={[
          {
            title: '序号',
            key: 'no',
            width: Dimen.Num,
            align: 'center',
            render: (_1, _2, i) => `${page.size * (page.current - 1) + i + 1}`,
          },
          {
            title: '门诊号',
            dataIndex: 'outpatientNo',
            width: Dimen.OutpatientNo,
            align: 'center',
          },
          {
            title: '疾病诊断',
            dataIndex: 'disease',
            align: 'center',
          },
          {
            title: '诊断科室',
            dataIndex: 'treatmentDepartmentName',
            width: Dimen.Department,
            align: 'center',
          },
          {
            title: '诊断医生',
            dataIndex: 'treatmentDoctorName',
            width: Dimen.Doctor,
            align: 'center',
          },
          {
            title: '挂号时间',
            dataIndex: 'registrationTime',
            width: Dimen.Time,
            align: 'center',
          },
          {
            title: '结算金额',
            dataIndex: 'realPayAmount',
            width: Dimen.Price,
            align: 'right',
          },
          {
            title: '操作',
            key: 'actions',
            align: 'center',
            render: function ShowActions(_, p) {
              return (
                <a
                  onClick={() => {
                    dispatch(
                      traceRoute({
                        name: '门诊统计',
                        path: '/payment/result',
                        query: `paymentId=${p.paymentId}&&status=8`, // status 暂时用欠费管理的 status
                        state: {
                          name: '门诊统计',
                          path: '/patient/detail',
                          query: `patientId=${patientId}`,
                        },
                      })
                    )
                  }}
                >
                  查看详情
                </a>
              )
            },
          },
        ]}
        onChangePage={(current, size) => {
          setParams({ ...params, current, size })
        }}
      />
    </Col>
  )
};
