/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-08 15:25:40
 */
import _ from 'lodash'
import { fromMaterialJson, Material } from './material'
import { RecipeStatus } from './recipe'

export interface PaymentResult {
  id: string
  success: boolean
  patientName: string
  totalAmount: number
  totalPayAmount: number
  realPayAmount: number
  payMethod: number
  arrearageAmount: number
  arrearageFlg: number
  receivePayAmount?: number
  clsdamount: number
  refundAmount: number
  patientId: number
  realAmount: number
  treatmentId: string
  materials: Material[]
  paymentDetailRecordVOS: any[]
  state: RecipeStatus
  createTime?: string
  operateName?: string
  machiningTypeMsg?: string
  insuranceCode?: string
  methodIcon?: any
  payMethodICon?: any
  payMethodName?: any
  autoDispenseErrMsg?:any
  payment?:any
  systemPayMethod?:any
  recipeIdList?:any
}

export function fromJson(json: any): PaymentResult {
  const payment = json.payment
  return {
    success: true,
    id: payment?.id?.toString() || '',
    patientName: payment?.patientName,
    patientId: payment?.patientId || 0,
    totalAmount: payment?.shouldPayAmount || 0,
    realPayAmount: payment?.realPayAmount || 0,
    payMethod: payment?.payMethod || 0,
    arrearageAmount: payment?.arrearageAmount || 0,
    arrearageFlg: payment?.arrearageFlg || 0,
    clsdamount: payment?.clsdamount || 0,
    totalPayAmount: payment?.totalPayAmount || 0,
    refundAmount: payment?.refundAmount || 0,
    realAmount: payment?.realPayAmount || 0,
    materials: _.chain(json.recipeItemList)
      .map((m) => fromMaterialJson(m))
      .value(),
    paymentDetailRecordVOS: _.chain(json.paymentDetailRecordVOS)
      .map((m) => fromMaterialJson(m))
      .value(),
    state: payment?.state || RecipeStatus.ToSettle,
    treatmentId: payment?.treatmentId?.toString() || '',
    createTime: payment?.createTime,
    operateName: payment?.operateName,
    insuranceCode: payment?.insuranceCode,
    methodIcon: payment?.methodIcon,
    payMethodICon: payment?.payMethodICon,
    payMethodName: payment?.payMethodName,
    autoDispenseErrMsg: json?.autoDispenseErrMsg,
    receivePayAmount: payment?.receivePayAmount,
    payment:json.payment,
    systemPayMethod:json?.systemPayMethod,
    recipeIdList:json?.recipeIdList
  }
}

export function getBoilTypeName(boilType: any): string {
  switch (boilType) {
    case '0':
      return '委外代煎颗粒剂'
    case '1':
      return '委外代煎袋装液体'
    case '2':
      return '委外外配药材'
    case '3':
      return '委外膏方代煎'
    case '4':
      return '本地代煎袋装液体'
    case '5':
      return '本地代煎颗粒剂'
    case '9':
      return '委外代煎颗粒剂'
  }
  return '-'
}
