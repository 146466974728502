/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-15 17:56:01
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-22 14:33:41
 */
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { Row, Col, Tooltip, Timeline } from 'antd';
import React, { ReactElement, useState } from 'react';
import styles from './outpatientInfoTime.module.css'
import { TimeLine } from './timeLine';

interface OutpatientInfoTimeProps {
  data: any[]
  onAction: (id: string) => void
}

export const OutpatientInfoTime = ({ data, onAction }: OutpatientInfoTimeProps): ReactElement => {
  return (
    <div>
      {data.length != 0 &&
        data.map((v, index) => (
          <div
            key={v.registrationId}
            className={styles.OutpatientInfoTime}
            onClick={() => {
              onAction(v.registrationId)
            }}
          >
            <div className={styles.OutpatientInfoTimeHeader}>
              <Row justify='space-between'>
                <Col flex={1} style={{ display: 'flex' }}>
                  {v.registrationTime} {v.treatmentDoctorName}{' '}
                  {v.insuranceCode == 'ZIFEI'
                    ? '自费'
                    : v?.insuranceCode == '' || v?.insuranceCode == null
                    ? '未知险种'
                    : '医保刷卡'}{' '}
                  ({v.treatmentDepartmentName})
                  <Tooltip title={v.disease}>
                    <div className={styles.itemText}>{v.disease}</div>
                  </Tooltip>
                  {v.receiveTag == 0 ? '初诊' : '复诊'}
                </Col>
                <Col flex={'3%'}>
                  {v.open ? (
                    <CaretUpFilled style={{ color: '#999999' }} />
                  ) : (
                    <CaretDownFilled style={{ color: '#999999' }} />
                  )}
                </Col>
              </Row>
            </div>
            {v.open && (
              <div
                style={{
                  padding: 10,
                  userSelect: 'none',
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {v.children.length != 0 &&
                  v.children.map((v: any) => <TimeLine key={v} data={v} />)}
              </div>
            )}
          </div>
        ))}
    </div>
  )
}
