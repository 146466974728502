/* eslint-disable react/display-name */
import { Avatar, Badge, Button, Space, TableColumnType } from 'antd'
import React, {ReactElement, useState} from 'react'
import { Dimen } from '../../../models/dimen'
import { Payment, PaymentStatus } from '../../../models/payment'
import { RecipeStatus } from '../../../models/recipe'
import { getGenderName } from '../../../models/user'
import { getAge } from '../../../utils/StringUtils'

type Action = 'pay' | 'return' | 'detail' | 'apply'

export const getCardType = (cardType: string) => {
  switch (cardType) {
    case '1':
      return '电子凭证'
    case '2':
      return '身份证'
    case '3':
      return '医保卡'
    default:
      return ''
  }
}

export const getInsuranceCodeName = (insuranceCode: string) => {
  switch (insuranceCode) {
    case 'ZIFEI':
      return '自费'
    case 'ZHEJIANG':
      return '浙江智慧医保'
    case 'CHONGQING':
      return '重庆医保'
    default:
      return ''
  }
}

export const PaymentColumns = (
  _: PaymentStatus,
  startIndex: number,
  onAction: (action: Action, id: string, t: any) => void
): TableColumnType<Payment>[] => {

  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '门诊号',
      key: 'outpatientNo',
      width: Dimen.OutpatientNo,
      align: 'center',
      render: (_, { registration }) => registration.outpatientNo,
    },
    {
      title: '患者姓名',
      key: 'patientName',
      align: 'center',
      width: Dimen.Name,
      render: (_, { treatment, registration }) => {
        return (
          <div style={{ width: '100%', height: '100%' }}>
            {/* {registration.insuranceCode ? (
              <Badge
                offset={[0, 10]}
                dot={true}
                color={
                  registration.insuranceCode === 'ZHEJIANG'
                    ? '#07BD4F'
                    : registration.insuranceCode === 'ZIFEI'
                    ? '#9B3EDF'
                    : ''
                }
              >
                <div style={{ width: '100%', height: '100%' }}>
                  {treatment.patientName}
                </div>
              </Badge>
            ) : ( */}
            <>{treatment.patientName}</>
            {/* )} */}
          </div>
        )
        // return treatment.patientName
      },
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '10em',
      align: 'center',
      render: (_, { registration }) => {
        return (!registration || !registration.insuranceCode || registration.insuranceCode === 'ZIFEI')
            ? '自费'
            : '智慧医保'
      },
    },
    {
      title: '读卡方式',
      key: 'cardType',
      align: 'center',
      width: Dimen.Sex,
      render: (_, { registration }) => {
        return getCardType(registration.cardType)
      },
    },
    {
      title: '性别',
      key: 'patientSex',
      align: 'center',
      width: Dimen.Sex,
      render: (_, { treatment }) => getGenderName(treatment.patientSex),
    },
    {
      title: '年龄',
      key: 'patientAge',
      align: 'center',
      width: Dimen.Age,
      render: (_, { treatment }) =>
        getAge(treatment?.patientAge, treatment?.patientMonth),
    },
    {
      title: '诊断疾病',
      key: 'disease',
      align: 'center',
      render: (_, { treatment }) => ({
        props: {
          style: {
            maxWidth: '14rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
        children: treatment.disease,
      }),
    },
    {
      title: '接诊科室',
      key: 'treatmentDepartmentName',
      align: 'center',
      width: Dimen.Department,
      render: (_, { treatment }) => treatment.treatmentDepartmentName,
    },
    {
      title: '接诊医生',
      key: 'treatmentDoctorName',
      align: 'center',
      width: Dimen.Doctor,
      render: (_, { treatment }) => treatment.treatmentDoctorName,
    },
    {
      title: '总金额',
      key: 'amount',
      width: Dimen.Price,
      align: 'right',
      render: (_, { amount }) =>
        amount === undefined ? '' : amount?.toFixed(2),
    },
    {
      title: '挂号时间',
      key: 'registrationTime',
      align: 'center',
      width: Dimen.Time,
      render: (_, { registration }) => registration.registrationTime,
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '5rem',
      render: function ShowAction(_, { treatment }): ReactElement {
        return (
          <Button
            size='small'
            type='primary'
            onClick={() => onAction('pay', treatment.id, treatment)}
          >
            结算
          </Button>
        )
      },
    },
  ]
}

export const PaymentColumnsWithAmount = (
  status: PaymentStatus,
  startIndex: number,
  onAction: (action: Action, id: number, t: any) => void,
  disabled?: boolean
): TableColumnType<Payment>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '门诊号',
      key: 'outpatientNo',
      align: 'center',
      width: Dimen.OutpatientNo,
      render: (_, { registration }) => registration.outpatientNo,
    },
    {
      title: '患者姓名',
      key: 'patientName',
      align: 'center',
      width: Dimen.Name,
      render: (_, { treatment, registration }) => {
        return (
          <div style={{ width: '100%', height: '100%' }}>
            {/* {registration.insuranceCode ? (
              <Badge
                offset={[0, 10]}
                dot={true}
                color={
                  registration.insuranceCode === 'ZHEJIANG'
                    ? '#07BD4F'
                    : registration.insuranceCode === 'ZIFEI'
                    ? '#9B3EDF'
                    : ''
                }
              >
                <div style={{ width: '100%', height: '100%' }}>
                  {treatment.patientName}
                </div>
              </Badge>
            ) : ( */}
            <>{treatment.patientName}</>
            {/* )} */}
          </div>
        )
        // return treatment.patientName
      },
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '10em',
      align: 'center',
      render: (_, { registration }) => {
        return (!registration || !registration.insuranceCode || registration.insuranceCode === 'ZIFEI')
            ? '自费'
            : '智慧医保'
      },
    },

    {
      title: '读卡方式',
      key: 'cardType',
      align: 'center',
      width: Dimen.Sex,
      render: (_, { registration }) => {
        return getCardType(registration.cardType)
      },
    },
    {
      title: '性别',
      key: 'patientSex',
      align: 'center',
      width: Dimen.Sex,
      render: (_, { treatment }) => getGenderName(treatment.patientSex),
    },
    {
      title: '年龄',
      key: 'patientAge',
      align: 'center',
      width: Dimen.Age,
      render: (_, { treatment }) =>
        getAge(treatment?.patientAge, treatment?.patientMonth),
    },
    {
      title: '诊断疾病',
      key: 'disease',
      align: 'center',
      render: (_, { treatment }) => ({
        props: {
          style: {
            maxWidth: '14rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
        children: treatment.disease,
      }),
    },
    {
      title: '接诊科室',
      key: 'treatmentDepartmentName',
      align: 'center',
      width: Dimen.Department,
      render: (_, { treatment }) => treatment.treatmentDepartmentName,
    },
    {
      title: '接诊医生',
      key: 'treatmentDoctorName',
      align: 'center',
      width: Dimen.Doctor,
      render: (_, { treatment }) => treatment.treatmentDoctorName,
    },
    status === RecipeStatus.Settled
      ? {
          title: '结算金额',
          key: 'amount',
          width: Dimen.Price,
          align: 'right',
          render: (_, { amount }) =>
            amount === undefined ? '' : amount?.toFixed(2),
        }
      : {
          title: '退费金额',
          key: 'amount',
          width: Dimen.Price,
          align: 'right',
          render: (_, { amount }) =>
            amount === undefined ? '' : amount?.toFixed(2),
        },
    status === RecipeStatus.Settled
      ? {
          title: '收费时间',
          key: 'chargeOrRefundTime',
          align: 'center',
          width: Dimen.Time,
          render: (_, { treatment }) => treatment.chargeOrRefundTime,
        }
      : {
          title: '退费时间',
          key: 'chargeOrRefundTime',
          align: 'center',
          width: Dimen.Time,
          render: (_, { treatment }) => treatment.chargeOrRefundTime,
        },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '5rem',
      render: function ShowAction(_, t): ReactElement {
        return (
          <Space>
            <Button
              type='link'
              disabled={disabled}
              onClick={() => {
                onAction('apply', Number(t.id), t)
              }}
            >
              清单上传
            </Button>
          </Space>
        )
      },
    },
  ]
}
