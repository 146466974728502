/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-31 11:05:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-23 11:09:28
 */
import { Row, TableColumnType, Tooltip } from 'antd'
import React from 'react'

export const Columns = (startIndex: number): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '4rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },

    {
      title: '代煎饮片厂',
      align: 'center',
      dataIndex: 'supplierName',
    },
    {
      title: '处方数',
      align: 'center',
      dataIndex: 'supplyPrice',
      render: (_, t) => (t.recipeCount > 0 ? t.recipeCount : '0'),
    },
    {
      title: '处方金额',
      align: 'center',
      dataIndex: '',
      render: (_, t) =>
        t.recipeAmount > 0 ? t.recipeAmount?.toFixed(2) : '0.00',
    },
    {
      title: '药方金额',
      align: 'center',
      dataIndex: '',
      render: (_, t) => (t.drugAmount > 0 ? t.drugAmount?.toFixed(2) : '0.00'),
    },
    {
      title: '代煎成本价',
      align: 'center',
      dataIndex: '',
      render: (_, t) => (t.priceRatio > 0 ? t.priceRatio?.toFixed(2) : '0.00'),
    },
    {
      title: '代煎费',
      align: 'center',
      dataIndex: '',
      render: (_, t) =>
        t.potionMoney > 0 ? t.potionMoney?.toFixed(2) : '0.00',
    },
  ]
}
