/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-03-25 19:43:49
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-12 16:01:22
 */
import { Action, configureStore, Dispatch, ThunkAction } from '@reduxjs/toolkit'
import carryOverReducer from '../features/carryover/carryOverSlice'
import commodityReducer from '../features/commodity/commoditySlice'
import completionReducer from '../features/completion/completionSlice'
import deliveryReducer from '../features/delivery/deliverySlice'
import departmentReducer from '../features/department/departmentSlice'
import DurgSalesReducer from '../features/durgSales/DurgsalesSlice'
import durgsyetemReducer from '../features/durgsystem/durgsystemSlice'
import examineReducer from '../features/examinetreat/examinetreatSlice'
import grantReducer from '../features/grant/grantSlice'
import homeReducer from '../features/home/HomeSlice'
import InventoryChangSliceReducer from '../features/InventoryChange/InventoryChangSlice'
import InventoryPriceReducer from '../features/InventoryPrice/inventoryPriceSlice'
import InventorStockModalSliceReducer from '../features/Inventorystock/InventoryMerge/InventStockModalSlice'
import InventorStockSliceReducer from '../features/Inventorystock/InventStockSlice'
import loginReducer from '../features/login/loglinSlice'
import menuReducer from '../features/menu/menuSlice'
import {
  default as FormulaReducer,
  default as FormulasDetailReducer,
} from '../features/nurse/formula/formulas/formulaSlice'
import {
  default as noFormulasDetailReducers,
  default as noFormulaReducer,
} from '../features/nurse/formula/noformula/noformulaSlice'
import nopitestReducer from '../features/nurse/pitest/nopitest/nopitestSlice'
import pitestReducer from '../features/nurse/pitest/pitestover/pitestSlice'
import outpatientAuditReducer from '../features/outpatientaudit/outpatientAuditSlice'
import patientDetailReducer from '../features/patient/detail/patientDetailSlice'
import patientListReducer from '../features/patient/list/patientListSlice'
import patientLableReducer from '../features/patient/patientLable/patientlableSlice'
import patientReducer from '../features/patient/patientSlice'
import paymentConfirmReducer from '../features/payment/confirm/paymentConfirmSlice'
import paymentReducer from '../features/payment/list/paymentSlice'
import paymentResultReducer from '../features/payment/result/paymentResultSlice'
import permissionsfromReducer from '../features/permissionsFrom/permissionsfromSlice'
import printReducer from '../features/print/printSlice'
import putstorageReducer from '../features/putstorage/putstorageSlice'
import putstoreReducer from '../features/putstorelist/putstorelistSlice'
import QingdouReducer from '../features/QingdouDetail/QingdouSlice'
import recentTimeReducer from '../features/recentTime/recentTimeSlice'
import recipeListReducer from '../features/recipe/recipeListSlice'
import registrationReducer from '../features/registration/list/registrationSlice'
import registerReducer from '../features/registration/register/registerSlice'
import retailReducer from '../features/retail/retailSlice'
import changeWaterReducer from '../features/sales/ChargeWater/changeWaterSlice'
import doctorAuditReducer from '../features/sales/DoctorAccont/doctorAuditSlice'
import doctorSaleReducer from '../features/sales/doctorSales/doctorSaleSlice'
import drugCostReducer from '../features/sales/DrugCosts/drugCostSlice'
import drugStaticReducer from '../features/sales/DrugStatic/drugStaticSlice'
import peopleSaleReducer from '../features/sales/peopleSales/peopleSaleSlice'
import revenueReportReducer from '../features/sales/RevenueReport/revenueReportSlice'
import statementReducer from '../features/sales/Statement/statementSlice'
import treatmentCostReducer from '../features/sales/TreatmentCost/treatmetCostSlice'
import treatmentModalReducer from '../features/sales/TreatmentCost/Modal/modalSlice'
import setmealReducer from '../features/setmeal/setmealSlice'
import settingsReducer from '../features/settings/settingsSlice'
import stockfloorReducer from '../features/stockfloor/stockfloorSlice'
import stockfloorModalReducer from '../features/StockfloorModal/stockfloorModalSlice'
import inventoryRecordReducer from '../features/storehouse/InventoryRecords/inventoryRecordSlice'
import recardDrugReducer from '../features/storehouse/recardDrug/recardDrugSlice'
import recevingReducer from '../features/storehouse/Receiving/recevingSlice'
import supplierDealingReducer from '../features/storehouse/SupplierDealing/supplierDealingSlice'
import purchaseControlReducer from '../features/storehouse/PurchaseControl/purchaseControlSlice'
import purchaseControlDetailReducer from '../features/storehouse/PurchaseControl/PurchaseControlDetail/PurchaseControlDetailSlice'
import storesearchReducer from '../features/storesearch/storesearchSlice'
import tenentSettingReducer from '../features/tenant/setting/TenentSettingSlice'
import tenantReducer from '../features/tenant/tenantSlice'
import diagnosisReducer from '../features/treatment/diagnosis/diagnosisSlice'
import recipeEditorReducer from '../features/treatment/editor/recipeEditorSlice'
import recipeListEditorReducer from '../features/treatment/editor/recipeListEditorSlice'
import treatmentReducer from '../features/treatment/list/treatmentSlice'
import settingLableReducer from '../features/user/setting/settingSlice'
import userReducer from '../features/user/userSlice'
import supplierReducer from '../features/wms/supplierSlice'
import layoutReducer from '../layouts/layoutSlice'
import applicationReducer from './applicationSlice'
import treatmentRegistReducer from '../features/treatment/treatmentSlice'
import stockMaintenanceReducer from '../features/storehouse/Maintenance/StockMaintenance/stockMaintenanceSlice'
import maintenanceDrugReducer from '../features/storehouse/Maintenance/maintenanceDrugs/maintenanceDrugSlice'
import addtionDrugReducer from '../features/storehouse/Maintenance/AdditionDrugs/addtionDrugSlice'
import drugStaticModelDetailReducer from '../features/sales/DrugStatic/Modal/modalSlice'
import menuContentListReducer from '../features/triage/listslice'
import appointmentReducer from '../features/appointment/appointmentSelice'
import oralCaseReducer from '../features/treatment/oralCases/oralCasesSlice'
import TcmCaseReducer from '../features/treatment/tcmCases/tcmCasesSlice'
import BeautyClinicReducer from '../features/treatment/beautyClinic/beautyClinicSlice'
import InsuranceContorlReducer from '../features/Echarts/insuranceContorl/InsuranceContorlSlice'
import insuranceReducer from '../layouts/insuranceSlice'
import followUpReducer from '../features/followUp/followUpPageSlice'
import patientToothReducer from '../features/A-toothModule/patient/patientSlice'
import patientHomeToothReducer from '../features/A-toothModule/toothHome/toothHomeSlice'
import transactionQueryReducer from '../features/transactionQuery/transactionQuerySlice'

export const store = configureStore({
  reducer: {
    application: applicationReducer,
    carryOver: carryOverReducer,
    commodity: commodityReducer,
    completion: completionReducer,
    confirm: paymentConfirmReducer,
    delivery: deliveryReducer,
    department: departmentReducer,
    diagnosis: diagnosisReducer,
    oralCase: oralCaseReducer,
    TcmCase: TcmCaseReducer,
    beautyClinic: BeautyClinicReducer,
    durgsyetem: durgsyetemReducer,
    examine: examineReducer,
    grant: grantReducer,
    home: homeReducer,
    layout: layoutReducer,
    login: loginReducer,
    menu: menuReducer,
    noformula: noFormulaReducer,
    noFormulasDetails: noFormulasDetailReducers,
    outpatientAudit: outpatientAuditReducer,
    patient: patientReducer,
    patientList: patientListReducer,
    patientDetail: patientDetailReducer,
    payment: paymentReducer,
    permissions: permissionsfromReducer,
    print: printReducer,
    putstorage: putstorageReducer,
    putstore: putstoreReducer,
    recentTime: recentTimeReducer,
    recipeEditor: recipeEditorReducer,
    recipeList: recipeListReducer,
    recipeListEditor: recipeListEditorReducer,
    register: registerReducer,
    registration: registrationReducer,
    result: paymentResultReducer,
    retail: retailReducer,
    settings: settingsReducer,
    setmeal: setmealReducer,
    stockfloor: stockfloorReducer,
    stockfloorModal: stockfloorModalReducer,
    storesearch: storesearchReducer,
    supplier: supplierReducer,
    tenant: tenantReducer,
    treatment: treatmentReducer,
    user: userReducer,
    formula: FormulaReducer,
    FormulasDetail: FormulasDetailReducer,

    // 已经皮试
    pitest: pitestReducer,
    // 未皮试
    nopitest: nopitestReducer,
    // 患者标签管理
    doctorAudit: doctorAuditReducer,
    patientLable: patientLableReducer,
    settinglable: settingLableReducer,
    tenentSetting: tenentSettingReducer,
    peopleSale: peopleSaleReducer,
    drugCost: drugCostReducer,
    doctorSale: doctorSaleReducer,
    revenueReport: revenueReportReducer,
    treatmentCost: treatmentCostReducer,
    treatmentModalCost: treatmentModalReducer,
    Qingdou: QingdouReducer,
    drugStatic: drugStaticReducer,
    drugStaticModelDetail: drugStaticModelDetailReducer,
    statement: statementReducer,
    changeWater: changeWaterReducer,
    receving: recevingReducer,
    supplierDealing: supplierDealingReducer,
    purchaseControl: purchaseControlReducer,
    purchaseControlDetail: purchaseControlDetailReducer,
    recardDrug: recardDrugReducer,
    inventoryRecord: inventoryRecordReducer,
    InventoryPrice: InventoryPriceReducer,
    InventoryChang: InventoryChangSliceReducer,
    InventoryStock: InventorStockSliceReducer,
    InventoryStockModal: InventorStockModalSliceReducer,
    DurgSales: DurgSalesReducer,
    stockMaintenance: stockMaintenanceReducer,
    maintenanceDrug: maintenanceDrugReducer,
    addtionDrug: addtionDrugReducer,
    menuContentList: menuContentListReducer,
    treatmentRegist: treatmentRegistReducer,
    insurance: insuranceReducer,
    InsuranceContorl: InsuranceContorlReducer,
    appointment: appointmentReducer,
    followUp: followUpReducer,
    patientTooth: patientToothReducer,
    patientHomeTooth: patientHomeToothReducer,
    transactionQuerys: transactionQueryReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type RootDispatch = typeof store.dispatch
export type RootThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
export type RootThunkApi<RejectValue = void> = {
  state: RootState
  // dispatch: any
  dispatch: Dispatch<any>
  // dispatch: RootDispatch
  // dispatch: ReturnType<RootDispatch>
  rejectValue: RejectValue
}
