import { Col, Tabs , } from "antd";

import React, { ReactElement,  } from "react";
import { } from "antd";
import { useDispatch } from "react-redux";
import { TabBar } from "../../compnents/widgets/TabBar";
import { Formula } from "./formula/formulas/formula";
import { NoFormula } from "./formula/noformula/noformula";
import styles from "./nurse.module.css";
const { TabPane } = Tabs;
export const Nurse = (): ReactElement => {
 const dispatch = useDispatch();
  return (
    <>    
  <Col className={styles.container}>
      <TabBar style={{marginBottom:"20px"}}>
        <TabPane key="0" tab="未执行处方">
        < NoFormula/>
        </TabPane>
        <TabPane key="1" tab="已执行处方">
          <Formula/>
        </TabPane>  
      </TabBar>
    </Col>
    </>
  );
};


