/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-01 11:03:01
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-15 11:17:12
 */
import React from 'react'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { api, sendAsync } from '../../app/applicationSlice'
import {
  getJuhePageFn,
  getJuhePageFnParams,
  getRankingListPageParams,
  getRankingListPageFn,
  editRankingListPageFn,
  editRankingListPageParams,
  deleteRankingListFn,
  deleteRankingListParams,
  getArrangeListPageFn,
  getArrangeListPageFnParams,
  addArrangeListPageFn,
  addArrangeListPageFnParams,
  resetArrangeListPageFn,
  resetArrangeListPageFnParams,
  getDoctorInfoFn,
  getDoctorInfoFnParams,
  deleteDoctorInfoFn,
  deleteDoctorInfoFnParams,
  copyLatWeekDoctorInfoFn,
  copyLatWeekDoctorInfoFnParams,
  automaticSchedulingSetting,
  AutomaticSchedulingSettingParams,
  automaticSchedulingDetail,
} from '../../services/composing'

// 获取聚合支付列表
export const getJuhePage = createAsyncThunk<
  any,
  getJuhePageFnParams,
  RootThunkApi<void>
>('confirm/getJuhePage123', async (params, api) => {
  return sendAsync(getJuhePageFn(params), api)
})
// 排班-排班列表
export const getArrangeListPage = createAsyncThunk<
  any,
  getArrangeListPageFnParams,
  RootThunkApi<void>
>('confirm/getArrangeListPage', async (params, api) => {
  return sendAsync(getArrangeListPageFn(params), api)
})
// 排班-新增/编辑医生班次
export const addArrangeListPage = createAsyncThunk<
  any,
  addArrangeListPageFnParams,
  RootThunkApi<void>
>('confirm/addArrangeListPage', async (params, api) => {
  return sendAsync(addArrangeListPageFn(params), api)
})
// 排班-重置排班列表
export const resetArrangeListPage = createAsyncThunk<
  any,
  resetArrangeListPageFnParams,
  RootThunkApi<void>
>('confirm/resetArrangeListPage', async (params, api) => {
  return sendAsync(resetArrangeListPageFn(params), api)
})
// 排班-获取医生排班信息
export const getDoctorInfo = createAsyncThunk<
  any,
  getDoctorInfoFnParams,
  RootThunkApi<void>
>('confirm/getDoctorInfo', async (params, api) => {
  return sendAsync(getDoctorInfoFn(params), api)
})
// 排班-删除医生排班信息
export const deleteDoctorInfo = createAsyncThunk<
  any,
  deleteDoctorInfoFnParams,
  RootThunkApi<void>
>('confirm/deleteDoctorInfo', async (params, api) => {
  return sendAsync(deleteDoctorInfoFn(params), api)
})
// 排班-copy 上周医生排班信息
export const copyLatWeekDoctorInfo = createAsyncThunk<
  any,
  copyLatWeekDoctorInfoFnParams,
  RootThunkApi<void>
>('confirm/copyLatWeekDoctorInfo', async (params, api) => {
  return sendAsync(copyLatWeekDoctorInfoFn(params), api)
})
// 排班-设置-班次列表
export const getRankingListPage = createAsyncThunk<
  any,
  getRankingListPageParams,
  RootThunkApi<void>
>('confirm/getRankingListPage', async (params, api) => {
  return sendAsync(getRankingListPageFn(params), api)
})
// 排班-设置-编辑班次列表
export const editRankingListPage = createAsyncThunk<
  any,
  editRankingListPageParams,
  RootThunkApi<void>
>('confirm/editRankingListPage', async (params, api) => {
  return sendAsync(editRankingListPageFn(params), api)
})
// 排班-设置-删除班次
export const deleteRankingList = createAsyncThunk<
  any,
  deleteRankingListParams,
  RootThunkApi<void>
>('confirm/deleteRankingList', async (params, api) => {
  return sendAsync(deleteRankingListFn(params), api)
})

// 自动排班-详情
export const AutomaticSchedulingDetailAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('confirm/AutomaticSchedulingDetailAsync', async (params, api) => {
  return sendAsync(automaticSchedulingDetail(), api)
})


// 自动排班-设置
export const AutomaticSchedulingSettingAsync = createAsyncThunk<
  any,
  AutomaticSchedulingSettingParams,
  RootThunkApi<void>
>('confirm/AutomaticSchedulingSettingAsync', async (params, api) => {
  return sendAsync(automaticSchedulingSetting(params), api)
})
