/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, TableColumnType } from "antd"
import moment from "moment"
import React from "react"
import { DateTimeFormatSimple } from "../../models/datetime"
import { Dimen } from "../../models/dimen"
import { getStandStateName } from "../../models/standbook"

export const JournalColumns = (
  onclick: (id: string, t: string) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "警报编号",
      align: "center",
      dataIndex: "cautionId",
      width: Dimen.Sex,
    },
    {
      title: "发生时间",
      align: "center",
      dataIndex: "companyCode",
      width: Dimen.Sex,
      render: (_, t) => {
        return moment(t.happenTime ? t.happenTime : undefined).format(
          DateTimeFormatSimple
        )
      },
    },
    {
      title: "推送时间",
      align: "center",
      dataIndex: "licenceCategory",
      width: Dimen.Sex,
      render: (_, t) => {
        return moment(t.pullTime ? t.pullTime : undefined).format(
          DateTimeFormatSimple
        )
      },
    },
    {
      title: "处置状态",
      align: "center",
      dataIndex: "supplierCategory",
      width: Dimen.Sex,
      render: (_, t) => {
        return getStandStateName(t.state)
      },
    },
    {
      title: "警报原因",
      align: "center",
      width: "10rem",
      dataIndex: "reason",
      render: (_, t) => {
        return (
          <p
            style={{
              textAlign: "center",
              maxWidth: "240px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              margin: "0 auto",
            }}
          >
            {t.reason}
          </p>
        )
      },
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "",
      width: Dimen.Num,
      key: "x",
      render: function Element(_, t) {
        return (
          <div
            style={
              {
                // display: "flex",
                // width: "8rem",
                // alignItems: "center",
                // justifyContent: "space-around",
              }
            }
          >
            {t.state === 0 ? (
              <Button
                type='primary'
                size='small'
                style={{ fontSize: '14px', marginRight: '10px' }}
                onClick={() => {
                  onclick(t.cautionId, 'appeal')
                }}
              >
                申诉
              </Button>
            ) : (
              <></>
            )}
            <Button
              size='small'
              style={{
                color: '#027AFF',
                fontSize: '14px',
                border: '1px solid  #027AFF',
              }}
              onClick={() => {
                onclick(t.cautionId, 'search')
              }}
            >
              查看
            </Button>
          </div>
        )
      },
    },
  ]
}
