/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-03 15:27:21
 */
import { Space, Switch, TableColumnType } from 'antd'
import moment from 'moment'
import { DateTimeFormatSimpleOnly } from '../../../../models/datetime'

type ActionType = 'edit' | 'stateEdit'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '门诊日期',
      dataIndex: 'registionDate',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.registionDate
          ? moment(r.registionDate).format(DateTimeFormatSimpleOnly)
          : '-'
      },
    },

    {
      title: '初诊人次',
      dataIndex: 'fristVisitNumber',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.fristVisitNumber ? r.fristVisitNumber : '0'
      },
    },
    {
      title: '复诊人次',
      dataIndex: 'returnVisitNumber',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.returnVisitNumber ? r.returnVisitNumber : '0'
      },
    },

    {
      title: '总人次',
      dataIndex: 'visitNumber',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.visitNumber ? r.visitNumber : '0'
      },
    },
    {
      title: '挂号费',
      dataIndex: 'realPayAmount',
      align: 'right',
      ellipsis: true,
      render: (_, r) => {
        return r.realPayAmount > 0 ? r.realPayAmount?.toFixed(2) : '0.00'
      },
    },
  ]
}
