import { Query } from '@testing-library/react'
import { method } from 'lodash'
import { serverType, baseDataType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'

// 获取菜单
export function getUserMenus(): Request {
  return {
    url: `${API_PREFIX}/blade-user/usermenu/list`,
    method: 'GET',
  }
}


// 获取二级菜单
export function getUserTwoMenus(): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantmenu/listThirdTenantmenu`,
    method: 'GET',
  }
}
export interface SignInfoParams {
  insuranceCode?: string
  tenantId?: number
  userId?: string
}

// 签到信息获取
export function getSignInfo(params: SignInfoParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getSignCycleStatusByUserId`,
    method: 'GET',
    params,
  }
}
// 获取广告图片
export interface getImgFnParams {
  isInsuranceTenant?: boolean
}
export const getImgFn = (params: getImgFnParams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/sysPopupAdvertising`,
    method: 'GET',
    params,
  }
}

 // 关闭广告图片
export const getCloseImgPhotoFn = (): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/homepage/userClosedAD`,
    method: 'GET',

  }
}

export interface SignParams {
  serverType: serverType
  params: {
    baseData: baseDataType
  }
}

// 医保签到
export function signInToMedical(SignParams: SignParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/signIn_${SignParams.serverType}`,
    method: 'POST',
    body: SignParams.params,
  }
}

export interface SignBody {
  serverType: serverType
  body: {
    baseData: baseDataType
  }
}

// 医保签退
export function signOutToMedical(SignBody: SignBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/signOut_${SignBody.serverType}`,
    method: 'POST',
    body: SignBody.body,
  }
}

export interface AccountCheckListParams {
  DZJG?: string
  tenantId?: number
  endDate?: string
  startDate?: string
  insuranceCode?: string
  isLocal?: string
  current: number
  size: number
}

// 日对帐列表
export function getAccountCheckList(params: AccountCheckListParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getAccountCheckList`,
    method: 'GET',
    params,
  }
}

export interface CheckBody {
  serverType: serverType
  body: {
    accountDate: string
    baseData: baseDataType
    insuranceCode: string
    qingsuanlb?: string
    xianzhonglx?: string
  }
}

// 医保日对帐
export function accountCheck(CheckBody: CheckBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/totalBalanceAccountCheck_${CheckBody.serverType}`,
    method: 'POST',
    body: CheckBody.body,
  }
}

export interface updateBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    date: string
    fromInfno: string
    isLocal:string
  }
}

// 明细下载 9101上传空文件
export function updateFire(updateBody: updateBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/fileUpload_${updateBody.serverType}`,
    method: 'POST',
    body: updateBody.body,
  }
}

export interface detailBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    fileQuryNo?: string
    setlOptins?: string
    stmtBegndate?: string
    stmtEnddate?: string
    clrType?: string
    isLocal?:string
  }
}

// 明细下载 3202
export function detailCheck(detailBody: detailBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/detailAccountCheck_${detailBody.serverType}`,
    method: 'POST',
    body: detailBody.body,
  }
}
export interface downloadBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    fileQuryNo?: string
    filename?: string
  }
}

// 明细下载 9102 文件上传
export function fileDownload(downloadBody: downloadBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/fileDownload_${downloadBody.serverType}`,
    method: 'POST',
    body: downloadBody.body,
  }
}

export interface pageParseBody {
  baseData: baseDataType
  accountDate?: string
  clrType?: string
  isLocal?: string
}

export interface mlpageParseBody {
  baseData: baseDataType
  infno?: string
}

// 明细下载 10000 文件下载
export function filePageParse(body: pageParseBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/detailAccountFilePageParse`,
    method: 'POST',
    body,
  }
}
// 目录下载 10000 文件下载
export function mlDownfilePageParse(body: pageParseBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/cataloggFileParse`,
    method: 'POST',
    body,
  }
}
export interface elseWhereCheckBody {
  serverType: serverType
  body: {
    accountDate?: string
    baseData: baseDataType
  }
}

// 异地对账 1505
export function elseWhereCheck(
  elseWhereCheckBody: elseWhereCheckBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/elsewhereAccountCheck_${elseWhereCheckBody.serverType}`,
    method: 'POST',
    body: elseWhereCheckBody.body,
  }
}

export interface elseWhereDownloadBody {
  serverType: serverType
  body: {
    recipeIdList?:any
    treatmentId?:string
    mdtrtId?:string
    baseData: baseDataType
    begndate?: string
    enddate?: string
    loclDataVer?: number
  }
}

// 异地对账 1812
export function elseWhereDownloadCheck(
  elseWhereDownloadBody: elseWhereDownloadBody
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/downloadElsewhereUnreconciledData_${elseWhereDownloadBody.serverType}`,
    method: 'POST',
    body: elseWhereDownloadBody.body,
  }
}

export interface AllopatryListBody {
  accountDate: string
  list: {
    dataTypy?: string
    fixmedinsNo?: string
    insuplcAdmdvs?: string
    loclDataVer?: string
    mdtrtSeq?: string
    mdtrtSetlTime?: string
    mdtrtareaAdmdvs?: string
    medType?: string
    medfeeSumamt?: string
    optinsPaySumamt?: string
    psnCashpay?: string
    psnNo?: string
    recerMsgId?: string
    senderMsgId?: string
    setlSn?: string
    trnsType?: string
  }[]
}

// 获取异地未对账数据
export function getAllopatryList(body: AllopatryListBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getAllopatryList`,
    method: 'POST',
    body,
  }
}

export interface medicalTradeBody {
  serverType: serverType
  body: {
    baseData?: baseDataType
    insuplcAdmdvs?: string
    oinfno?: string
    omsgid?: string
    psnNo?: string
  }
}

// 2601 医保冲正
export function medicalTrade(medicalTradeBody: medicalTradeBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/reversalTrade_${medicalTradeBody.serverType}`,
    method: 'POST',
    body: medicalTradeBody.body,
  }
}

export interface localMedicalTradeBody {
  localOinfNo?: string
  localOmsgId?: string
}

export function localMedicalTrade(params: localMedicalTradeBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/localCZ`,
    method: 'POST',
    params,
  }
}

export interface checkDataParams {
  acctPay?: string
  fundPaySumamt?: string
  medfeeSumamt?: string
  psnCashpay?: string
  setlId?: string
}

// 本地更正
export const checkLocalData = (params: checkDataParams): Request => {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/changeLocalData`,
    method: 'GET',
    params,
  }
}

// 前五疾病
export const rankingList = (): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/diseasecode/rankingList`,
    method: 'GET',
  }
}

// 核拨明细导出
export interface InsuranceTradeExportParams {
  endTime:string
  insuranceCode:string
  startTime:string
}
export const insuranceTradeExport = (params:InsuranceTradeExportParams): Request => {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/insuranceTradeExport`,
    method: 'GET',
    responseType: 'blob',
    params
  }
}
