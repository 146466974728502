import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../../app/store'
import {
  getAllDoctorListFn,
  getAllDoctorListFnPrams,
  getCounselorFn,
  getCounselorFnPrams,
  getFollowUpListDetailFn,
  getFollowUpListDetailFnParams,
  getFollowUpListFn,
  getFollowUpListFnParams,
  getHomeHeaderCountFn,
  getHomeLeftCountFn,
  getHomeLeftCountFnParams,
  getMessageCountFn,
  getMessageTemplateFn,
  getPatientgroupFn,
  getPatientgroupFnPrams,
  getPatientPageFn,
  getPatientPageFnPrams,
  getPatientPhone,
  getRegistrationDetailFn,
  getRegistrationDetailFnParams,
  getSaveAddPatientFn,
  getSaveAddPatientFnPrams,
  getSignatureFn,
  getTransferTreatmentFn,
  getTransferTreatmentFnParams,
  getWorkStationFn,
  getWorkStationFnParams,
  sendSmsPatient,
  SendSmsPatientParams,
  signDeleteById,
  smsTemplate,
  SmsTemplateParams,
  getChargePageFn, getChargePageFnPrams, getChargePageTotalCountFn, getBatchChargeFnPrams, getBatchChargeFn, getCutPriceDetailFn, getCutPriceAddFn, getCutPriceAddFnParams,
} from '../../../services/toothHome'

export interface PatientHomeSliceType {
  messageCount: number
  patientBirthday: any[] // 生日的时间范围
  sendIDs: string[] // 需要发送短信的患者id集合
  SignatureList: any[] // 签名列表
  ToothHomeStatus: number  //首页新增患者挂号

}

const initialState: PatientHomeSliceType = {
  messageCount: 0,
  patientBirthday: [],
  sendIDs: [],
  SignatureList: [],
  ToothHomeStatus: 0
}

// 获取-首页-患者生日-短信条数
export const getMessageCount = createAsyncThunk<any, void, RootThunkApi>(
  'ToothHome/getMessageCount',
  async (_, api) => {
    return sendAsync(getMessageCountFn(), api)
  }
)

export const PatientHomeToothSlice = createSlice({
  name: 'PatientToothSlice',
  initialState,
  reducers: {
    setMessageCount(state, { payload }) {
      state.messageCount = payload
    },
    setPatientBirthday(state, { payload }) {
      state.patientBirthday = payload
    },
    setSendIDs(state, { payload }) {
      state.sendIDs = payload
    },
    setSignatureList(state, { payload }) {
      state.SignatureList = payload
    },
    setToothHomeStatus(state, { payload }) {
      state.ToothHomeStatus = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessageCount.fulfilled, (state, action: any) => {
      state.messageCount = action.payload
    })
  },
})

export const {
  setPatientBirthday,
  setSendIDs,
  setSignatureList,
  setToothHomeStatus,
} = PatientHomeToothSlice.actions

// 获取-首页-头部数据统计
export const getHomeHeaderCount = createAsyncThunk<any, void, RootThunkApi>(
  'ToothHome/getHomeHeaderCount',
  async (_, api) => {
    return sendAsync(getHomeHeaderCountFn(), api)
  }
)
// 获取-首页-左侧数据统计
export const getHomeLeftCount = createAsyncThunk<
  any,
  getHomeLeftCountFnParams,
  RootThunkApi
>('ToothHome/getHomeLeftCount', async (params, api) => {
  return sendAsync(getHomeLeftCountFn(params), api)
})
// 获取-首页-工作站列表
export const getWorkStation = createAsyncThunk<
  any,
  getWorkStationFnParams,
  RootThunkApi
>('ToothHome/getWorkStation', async (params, api) => {
  return sendAsync(getWorkStationFn(params), api)
})
// 获取-新增患者-患者分组
export const getPatientgroup = createAsyncThunk<
  any,
  getPatientgroupFnPrams,
  RootThunkApi
>('ToothHome/getPatientgroup', async (params, api) => {
  return sendAsync(getPatientgroupFn(params), api)
})
// 获取-新增患者-咨询师
export const getCounselor = createAsyncThunk<
  any,
  getCounselorFnPrams,
  RootThunkApi
>('ToothHome/getCounselor', async (params, api) => {
  return sendAsync(getCounselorFn(params), api)
})
// 获取-新增患者-保存
export const getSaveAddPatient = createAsyncThunk<
  any,
  getSaveAddPatientFnPrams,
  RootThunkApi
>('ToothHome/getSaveAddPatient', async (params, api) => {
  return sendAsync(getSaveAddPatientFn(params), api)
})
// 获取-挂号-医生
export const getAllDoctorList = createAsyncThunk<
  any,
  getAllDoctorListFnPrams,
  RootThunkApi
>('ToothHome/getAllDoctorList', async (params, api) => {
  return sendAsync(getAllDoctorListFn(params), api)
})
// 获取-首页-收费-列表
export const getChargePage = createAsyncThunk<
  any,
  getChargePageFnPrams,
  RootThunkApi
>('ToothHome/getChargePage', async (params, api) => {
  return sendAsync(getChargePageFn(params), api)
})
// 获取-首页-收费-列表总金额
export const getChargePageTotalCount = createAsyncThunk<
  any,
  getChargePageFnPrams,
  RootThunkApi
>('ToothHome/getChargePageTotalCount', async (params, api) => {
  return sendAsync(getChargePageTotalCountFn(params), api)
})
// 获取-首页-收费-批量预结算
export const getBatchCharge = createAsyncThunk<
  any,
  getBatchChargeFnPrams,
  RootThunkApi
>('ToothHome/getBatchCharge', async (params, api) => {
  return sendAsync(getBatchChargeFn(params), api)
})
// 获取-首页-收费-划价信息
export const getCutPriceDetail = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('ToothHome/getCutPriceDetail', async (_, api) => {
  return sendAsync(getCutPriceDetailFn(), api)
})
// 获取-首页-收费-划价保存
export const getCutPriceAdd = createAsyncThunk<
  any,
  getCutPriceAddFnParams,
  RootThunkApi
>('ToothHome/getCutPriceAdd', async (params, api) => {
  return sendAsync(getCutPriceAddFn(params), api)
})
// 获取-首页-患者生日-列表
export const getPatientPage = createAsyncThunk<
  any,
  getPatientPageFnPrams,
  RootThunkApi
>('ToothHome/getPatientPage', async (params, api) => {
  return sendAsync(getPatientPageFn(params), api)
})

// 获取-首页-患者生日-发送短信弹窗-短信模板
export const getMessageTemplate = createAsyncThunk<any, void, RootThunkApi>(
  'ToothHome/getMessageTemplate',
  async (_, api) => {
    return sendAsync(getMessageTemplateFn(), api)
  }
)
// 获取-首页-患者生日-发送短信弹窗-签名列表
export const getSignature = createAsyncThunk<any, void, RootThunkApi>(
  'ToothHome/getSignature',
  async (_, api) => {
    return sendAsync(getSignatureFn(), api)
  }
)
// 获取-首页-挂号-挂号详情
export const getRegistrationDetail = createAsyncThunk<
  any,
  getRegistrationDetailFnParams,
  RootThunkApi
>('ToothHome/getRegistrationDetail', async (params, api) => {
  return sendAsync(getRegistrationDetailFn(params), api)
})
// 获取-首页-转诊-保存
export const getTransferTreatment = createAsyncThunk<
  any,
  getTransferTreatmentFnParams,
  RootThunkApi
>('ToothHome/getTransferTreatment', async (params, api) => {
  return sendAsync(getTransferTreatmentFn(params), api)
})
// 获取-首页-随访-获取列表
export const getFollowUpList = createAsyncThunk<
  any,
  getFollowUpListFnParams,
  RootThunkApi
>('ToothHome/getFollowUpList', async (params, api) => {
  return sendAsync(getFollowUpListFn(params), api)
})
// 获取-首页-随访-获取列表明细
export const getFollowUpListDetail = createAsyncThunk<
  any,
  getFollowUpListDetailFnParams,
  RootThunkApi
>('ToothHome/getFollowUpListDetail', async (params, api) => {
  return sendAsync(getFollowUpListDetailFn(params), api)
})

// 短信签名新增、修改
export const smsTemplateAsync = createAsyncThunk<
  void,
  SmsTemplateParams,
  RootThunkApi
>('ToothHome/smsTemplateAsync', async (params, api) => {
  return sendAsync(smsTemplate(params), api)
})

// 短信签名删除
export const signDeleteByIdAsync = createAsyncThunk<void, string, RootThunkApi>(
  'ToothHome/signDeleteByIdAsync',
  async (params, api) => {
    return sendAsync(signDeleteById(params), api)
  }
)

// 发送短信
export const sendSmsPatientAsync = createAsyncThunk<
  void,
  SendSmsPatientParams,
  RootThunkApi
>('ToothHome/sendSmsPatientAsync', async (params, api) => {
  return sendAsync(sendSmsPatient(params), api)
})

// 获取患者手机号
export const getPatientPhoneAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi
>('ToothHome/sendSmsPatientAsync', async (params, api) => {
  return sendAsync(getPatientPhone(params), api)
})

export const selectMessageCount = (state: RootState) =>
  state.patientHomeTooth.messageCount

export const selectPatientBirthday = (state: RootState) =>
  state.patientHomeTooth.patientBirthday

export const selectSendIDs = (state: RootState) =>
  state.patientHomeTooth.sendIDs

export const selectSignatureList = (state: RootState) =>
  state.patientHomeTooth.SignatureList

export const selectToothHomeStatus = (state: RootState) =>
  state.patientHomeTooth.ToothHomeStatus

export default PatientHomeToothSlice.reducer
