/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-24 15:09:09
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-04 17:57:02
 */
import {Button, Row, Form, Space, Input, message} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect } from 'react'
import {} from 'antd'
import { useDispatch } from 'react-redux'

import {
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../../compnents/form/DateSelect'
import { statementParams } from '../../../services/statement'
import { PrintOutlined } from '../../../compnents/icons/Icons'
export const StatementQuery = (props: {
  onValueChange: (queries: statementParams) => void
  outClued: () => void
  print: (queries: statementParams) => void
  subPrint:(queries: statementParams) => void
}): ReactElement => {
  const [form] = Form.useForm()

  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
    })
    form.submit()
  }, [])
  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(values) => props.onValueChange(values)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='operateUserName' noStyle>
              <Input
                autoComplete='off'
                placeholder='请输入操作员姓名'
                style={{ width: 300 }}
                allowClear
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
              />
            </Form.Item>
            <DateSelect
              allowClear={false}
              style={{ width: '8.5rem' }}
              labelPrefix=''
              namePrefix='time'
              options={TJFXTime}
              placeholder='今天'
              initialValue={DateRangeType.Today}
              onChange={() => form.submit()}
            />
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type='text'
              style={{
                color: '#666',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                props.onValueChange(form.getFieldsValue())
                props.print(form.getFieldsValue())
              }}
            >
              <PrintOutlined
                style={{
                  fill: '#666',
                  width: '18px',
                  height: '18px',
                  marginRight: '4px',
                }}
              />
              打印
            </Button>
            <Button
              type='text'
              style={{
                color: '#666',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                props.onValueChange(form.getFieldsValue())
                props.subPrint(form.getFieldsValue())
              }}
            >
              <PrintOutlined
                style={{
                  fill: '#666',
                  width: '18px',
                  height: '18px',
                  marginRight: '4px',
                }}
              />
              汇总打印（当页）
            </Button>

            <Button type='primary' onClick={() => {
              props.outClued()}
            }>
              导出
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
