/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-30 10:06:44
 * @LastEditors: K
 * @LastEditTime: 2022-10-08 16:28:28
 */
import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { Request } from './request'
export interface getMembersPageFnParams {
  size?: number
  current?: number
  endTime?: string
  startTime?: number
  labelIds?: string
  keyWord?: number
}
//  会员管理-列表
export const getMembersPageFn = (params: getMembersPageFnParams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/member/memberPage`,
    method: 'GET',
    params,
  }
}

//  会员管理-新增
export interface getAddMembersFnParams {
  age?: string
  balanceMoney?: number
  birthday?: string
  createUser?: string
  labelIds?: string
  memberName?: string
  memberPhone?: number
  remarks?: string
  sexType?: string
}
export const getAddMembersFn = (body: getAddMembersFnParams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/member/saveMember`,
    method: 'POST',
    body,
  }
}

//  会员管理-新增会员-操作人
export const getAddMembersOperationFn = (): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/member/getCreateList`,
    method: 'GET',
  }
}
//  会员管理-会员基础信息
export interface getMembersBaseInfoFnParams {
  memberId?: number
}
export const getMembersBaseInfoFn = (
  params: getMembersBaseInfoFnParams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/member/getMemberBasicsDetails`,
    method: 'GET',
    params,
  }
}
//  会员管理-充值 or 退款详情信息
export const getMembersInfoFn = (
  params: getMembersBaseInfoFnParams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/member/getMemberDetails`,
    method: 'GET',
    params,
  }
}
//  会员管理-充值 or 退款
export interface getMembersTopUpFnParams {
  createUser?: string
  memberId?: number
  money?: number
  remark?: string
  type?: number
}
export const getMembersTopUpFn = (body: getMembersTopUpFnParams): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/member/rechargeRefund`,
    method: 'POST',
    body,
  }
}
//  会员管理-充值记录
export interface getMembersTopUpRecordFnParams {
  memberId?: number
  current?: number
  size?: number
  type?: number
}
export const getMembersTopUpRecordFn = (
  params: getMembersTopUpRecordFnParams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/member/getRechargeList`,
    method: 'GET',
    params,
  }
}
//  药品零售-搜索会员
export interface getSearchMembersFnParams {
  memberPhone?: number
}
export const getSearchMembersFn = (
  params: getSearchMembersFnParams
): Request => {
  return {
    url: `${API_PREFIX}/blade-outpatient/member/searchMember`,
    method: 'GET',
    params,
  }
}
