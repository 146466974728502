/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-15 13:46:42
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-06 18:40:32
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Space, Tabs } from 'antd'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { RecipeStatus } from '../../models/recipe'
import styles from './transactionQueryTab.module.css'

import { useSelector } from 'react-redux'
import {
  TransactionQueryRef,
  TransactionQuerys,
} from './TransactionQuery/TransactionQuery'
import {
  TransactionDetailQueryRef,
  TransactionDetailQuerys,
} from './TransactionDetailQuery/TransactionDetailQuery'
import { selectExportTag } from './transactionQuerySlice'

interface TransactionQueryProps {
  status?: any
}

export const TransactionQueryTab = ({
  status,
}: TransactionQueryProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const [selectedTab, setSelectedTab] = useState<any>('1')

  const ref = useRef<TransactionQueryRef>(null)

  const detailRef = useRef<TransactionDetailQueryRef>(null)

  const exporTags: any = useSelector(selectExportTag)


  return (
    <Col className={status ? styles.containers : styles.container}>
      {status == 1 ? (
        <>
          <Tabs
            className={styles.tabs}
            defaultActiveKey={
              location?.state
                ? location?.state?.selectedTab
                : selectedTab || '1'
            }
            onChange={(v) => {
              setSelectedTab(v)
            }}
          >
            <TabPane key='1' tab='交易查询'>
              <TransactionQuerys
                selectedTab={selectedTab}
                ref={ref}
                flag={status}
              />
            </TabPane>
            <TabPane key='2' tab='交易明细查询'>
              <TransactionDetailQuerys
                selectedTab={selectedTab}
                ref={detailRef}
                flag={status}
              />
            </TabPane>
          </Tabs>
        </>
      ) : (
        <TabBar
          style={{ position: 'relative' }}
          defaultActiveKey={
            location?.state ? location?.state?.selectedTab : selectedTab || '1'
          }
          onChange={(v) => {
            setSelectedTab(v)
          }}
        >
          <TabPane key='1' tab='交易查询'>
            <TransactionQuerys selectedTab={selectedTab} ref={ref} />
          </TabPane>
          <TabPane key='2' tab='交易明细查询'>
            <TransactionDetailQuerys
              selectedTab={selectedTab}
              ref={detailRef}
            />
          </TabPane>
        </TabBar>
      )}

      {selectedTab == '1' ? (
        <Space
          style={{
            position: 'absolute',
            top: status ? '6px' : '14px',
            right: '30px',
          }}
        >
          <div style={{ color: '#333333' }}>
            （最多支持导出近3个月10000条内的数据，更多请联系客服）
          </div>
          <Button
            // disabled={!exporTags}
            type='primary'
            onClick={() => {
              ref?.current?.outClude()
            }}
          >
            导出
          </Button>
          <Button
            type='primary'
            onClick={(e) => {
              ref?.current?.reload()
            }}
          >
            打印
          </Button>
        </Space>
      ) : (
        <Space
          style={{
            position: 'absolute',
            top: status ? '6px' : '14px',
            right: '30px',
          }}
        >
          <div style={{ color: '#333333' }}>
            （最多支持导出近3个月10000条内的数据，更多请联系客服）
          </div>
          <Button
            // disabled={!exporTags}
            type='primary'
            onClick={() => {
              detailRef?.current?.reload()
            }}
          >
            导出
          </Button>
        </Space>
      )}
    </Col>
  )
}
