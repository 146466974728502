/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-16 09:41:13
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-22 19:07:33
 */
import {
  CaretUpFilled,
  CaretDownFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Modal,
  Radio,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import React, { ReactElement, useContext, useState } from 'react'
import {
  getRecipeCategoryName,
  RecipeCategory,
} from '../../../../../models/recipe'
import { ThemeContext } from '../../../../../theme/ThemeContext'
import {
  DisposeColumns,
  MedicineMaterialColumns,
  TcmColumns,
  toothDisposeColumns,
  WorkDisposeColumns,
} from '../../../../treatment/editor/columns'
import styles from './dispose.module.css'

type action = 'edit' | 'done' | 'open' | 'radioBox'
interface DisposeItemProps {
  ListItem?: any
  status?: number
  bindType?: string
  tag?:any
  onAction?: (action: action, id: any) => void
}

export const DisposeItem = ({
  ListItem,
  status,
  bindType,
  onAction,
}: DisposeItemProps): ReactElement => {
  const theme = useContext(ThemeContext)


  return (
    <div className={styles.DisposeItem}>
      {status == 1 && !(bindType == '2') && (
        <Radio
          style={{
            position: 'absolute',
            top: '11px',
            left: '5px',
          }}
          value={ListItem?.recipeId}
          // onChange={()=>{
          //    onAction && onAction('radioBox', ListItem)
          // }}
        />
      )}

      <Row
        style={{ paddingLeft: status == 1 ? 30 : 10 }}
        className={styles.header}
        justify='space-between'
        onClick={(v) => {
          // setCheckDataId(ListItem?.recipeId)
          onAction && onAction('open', ListItem)
        }}
      >
        <Col
          className={
            status == 1 ? styles.activeHeaderTitle : styles.headerTitle
          }
        >
          {/* <Space>
            <div>处置时间：{ListItem.recipeTime}</div>
            <div>医生：{ListItem.doctorName}</div>
          </Space> */}
          <Space>
            <div>
              {status == 1 ? ListItem.dateTime : ListItem.registrationTime}
            </div>
            <div>{ListItem.patientName}</div>
            <div>
              {status == 1 ? ListItem.doctorName : ListItem.treatmentDoctorName}{' '}
              {ListItem.insuranceCode == 'ZIFEI'
                ? '自费'
                : ListItem.insuranceCode == '' || ListItem.insuranceCode == null
                ? ' 未知险种'
                : '医保刷卡'}{' '}
              (
              {status == 1
                ? ListItem.departmentName
                : ListItem.treatmentDepartmentName}
              )
            </div>

            <div className={styles.outpatientName}>
              <Tooltip title={ListItem.disease}>{ListItem.disease}</Tooltip>
            </div>
          </Space>
        </Col>
        {status == 1 && (
          <Space>
            <Button size='small' type='primary'>
              {ListItem.state == -1
                ? '编辑中'
                : ListItem.state == 0
                ? '待缴费'
                : '已缴费'}
            </Button>
            {ListItem.open ? (
              <CaretUpFilled style={{ color: '#999999' }} />
            ) : (
              <CaretDownFilled style={{ color: '#999999' }} />
            )}
          </Space>
        )}
        {status != 1 && (
          <Space onClick={(e) => e.stopPropagation()}>
            {ListItem.stateType == 1 && (
              <Button
                type='link'
                size='small'
                onClick={() => {
                  onAction && onAction('done', ListItem)
                }}
              >
                完成
              </Button>
            )}
            <Button
              type='link'
              size='small'
              onClick={() => {
                onAction && onAction('edit', ListItem)
              }}
            >
              编辑
            </Button>
            {/* }}>{ListItem.insuranceCode != 'ZIFEI' ? '预览' : '编辑'}</Button> */}
            {ListItem.stateType == 0 && <Tag color='#D14242'>待收费</Tag>}
            {ListItem.stateType == 1 && <Tag color='#FFAE55'>编辑中</Tag>}
            {ListItem.stateType == 2 && <Tag color='#2A7CF6'>已收费</Tag>}
            {ListItem.open ? (
              <CaretUpFilled style={{ color: '#999999' }} />
            ) : (
              <CaretDownFilled style={{ color: '#999999' }} />
            )}
          </Space>
        )}
      </Row>
      {ListItem.open &&
        status != 1 &&
        ListItem.materials &&
        ListItem.materials.length != 0 &&
        ListItem.materials.map((el: any, index: number) => (
          <div style={{ padding: 10 }} key={index}>
            <Row
              style={{
                padding: '5px 10px',
                backgroundColor: theme.th,
              }}
            >
              <Col flex={1}>
                {getRecipeCategoryName(el.category)}
                处方单，处方编号：{el.recipeId}
              </Col>
              <Col >
                {ListItem.stateType == 0 && <Tag color='#D14242'>待收费</Tag>}
                {ListItem.stateType == 1 && <Tag color='#FFAE55'>编辑中</Tag>}
                {ListItem.stateType == 2 && <Tag color='#2A7CF6'>已收费</Tag>}
              </Col>
            </Row>
            <div
              style={{
                overflow: 'auto',
              }}
            >
              <Table
                pagination={false}
                size='small'
                bordered
                columns={
                  el.category === RecipeCategory.Dispose
                    ? toothDisposeColumns(theme, el.list)
                    : MedicineMaterialColumns(theme, el.list)
                }
                dataSource={el.list || []}
              />
            </div>
            <Row
              justify='end'
              style={{
                padding: '5px 15px',
                backgroundColor: theme.wbd,
              }}
            >
              总金额：￥ {status != 1 ? el?.list?.[0]?.amount : el.totalAmount}
            </Row>
          </div>
        ))}
      {
        //工作站-表格随访
        ListItem.open &&
          status == 1 &&
          ListItem.materials &&
          ListItem.materials.length != 0 && (
            <div style={{ padding: 10 }}>
              {/* <Row style={{
                padding: '5px 10px',
                backgroundColor: theme.th,
              }}>
                <Col
                  flex={1}
                >
                  {getRecipeCategoryName(el.category)}
                  处方单，处方编号：{el.recipeId}
                </Col>
              </Row> */}
              <div
                style={{
                  overflow: 'auto',
                }}
              >
                <Table
                  pagination={false}
                  size='small'
                  bordered
                  columns={WorkDisposeColumns(theme)}
                  dataSource={ListItem.materials}
                />
              </div>
              <Row
                justify='end'
                style={{
                  padding: '5px 15px',
                  backgroundColor: theme.wbd,
                }}
              >
                总金额：￥ {ListItem.materials[0].totalAmount}
              </Row>
            </div>
          )
      }
    </div>
  )
}
