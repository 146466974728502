/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-06 16:48:53
 * @LastEditors: sj
 * @LastEditTime: 2022-09-19 14:46:42
 */
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Form, Input, notification, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../app/store';
import styles from './Tenants.module.css'
import { invoicenumberconfigAsync, invoiceSaveAsync } from './tenantSlice';

export const InvoiceSetting = () => {
  const dispatch = useDispatch<RootDispatch>()

  const [invoiceNumberViewA,setInvoiceNumberViewA] = useState("");
  const [invoiceNumberViewB,setInvoiceNumberViewB] = useState("");

  const [form] = Form.useForm()

  const [data, setData] = useState([])

  useEffect(() => {
    getPage()
  }, [])

  const refreshInvoiceNumberView = () => {
    const anumber = form.getFieldValue("acurrent");
    const ainvoiceNumberLength = form.getFieldValue("ainvoiceNumberLength");
    const bnumber = form.getFieldValue("bcurrent");
    const binvoiceNumberLength = form.getFieldValue("binvoiceNumberLength");
    if(anumber){
      if(!ainvoiceNumberLength || ainvoiceNumberLength <=0 || ainvoiceNumberLength<=anumber.length || ainvoiceNumberLength>15){
        setInvoiceNumberViewA(anumber);
      }else{
        const diffLength = ainvoiceNumberLength - anumber.length  ;
        let Azero = '';
        for(let i = 0;i<diffLength;i++){
          Azero += "0";
        }
        setInvoiceNumberViewA(Azero+""+anumber);
      }
    }

    if(bnumber){
      if(!binvoiceNumberLength || binvoiceNumberLength <=0 || binvoiceNumberLength<=bnumber.length || binvoiceNumberLength>15){
        setInvoiceNumberViewB(bnumber);
      }else{
        const diffLength = binvoiceNumberLength - bnumber.length  ;
        let Bzero = '';
        for(let i = 0;i<diffLength;i++){
          Bzero += "0";
        }
        setInvoiceNumberViewB(Bzero+""+bnumber);
      }
    }
  }

  const getPage = () => {
    dispatch(invoicenumberconfigAsync()).then(unwrapResult).then((res: any) => {
      const a = res.records.length ? res.records.find((v: any) => v.categoryType == 0) : {}
      const b = res.records.length ? res.records.find((v: any) => v.categoryType == 1) : {}
      form.setFieldsValue({
        aCode: a.invoiceCode,
        ano: {
          astart: a.start,
          aend: a.end
        },
        ainvoiceNumberLength:a.invoiceNumberLength&&a.invoiceNumberLength>0?a.invoiceNumberLength:0,

        acurrent: a.currentNo,
        bCode: b.invoiceCode,
        bno: {
          bstart: b.start,
          bend: b.end
        },
        bcurrent: b.currentNo,
        binvoiceNumberLength:b.invoiceNumberLength&&b.invoiceNumberLength>0?b.invoiceNumberLength:0,

      })

      setData(res.records.length ? res.records : [])
      refreshInvoiceNumberView();
    })
  }

  const required = {
    rules: [{
      required: true
    },
      {
        pattern: /^\d+$/,
        message: '请输入正确数字',
      },
    ]
  }

  const numberRules = {
    rules: [
      {
        pattern: /^\d+$/,
        message: '请输入正确数字',
      },
    ]
  }

  return (
    <div className={styles.container}>
      <Form
        form={form}
        colon={false}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        onFinish={(vs) => {
          if (vs.ano.astart - vs.ano.aend >= 0) {
            notification.info({
              message: '收费发票起始号码须大于结束号码'
            })
            return
          }
          if (vs.acurrent - vs.ano.aend >= 0) {
            notification.info({
              message: '收费发票当前号码须小于收费发票结束号码'
            })
            return
          }
          if (vs.bno.bstart && vs.bno.bend) {
            if (vs.bno.bstart - vs.bno.bend >= 0) {
              notification.info({
                message: '挂号发票起始号码须大于结束号码'
              })
              return
            }
            if (vs.bcurrent - vs.bno.bend >= 0) {
              notification.info({
                message: '挂号发票当前号码须小于收费发票结束号码'
              })
              return
            }
          }
          const a: any = data.length ? data.find((v: any) => v.categoryType == 0) : {}
          const b: any = data.length ? data.find((v: any) => v.categoryType == 1) : {}
          const result = [{
            categoryType: '0',
            currentNo: vs.acurrent,
            start: vs.ano.astart,
            end: vs.ano.aend,
            invoiceCode: vs.aCode,
            id: a ? a.id : undefined,
            invoiceNumberLength: vs.ainvoiceNumberLength
          }, {
            categoryType: '1',
            currentNo: vs.bcurrent,
            start: vs.bno.bstart,
            end: vs.bno.bend,
            invoiceCode: vs.bCode,
            id: b ? b.id : undefined,
            invoiceNumberLength: vs.binvoiceNumberLength

          }]
          dispatch(invoiceSaveAsync(result)).then(() => {
            notification.success({
              message: '保存成功'
            })
            getPage()
          })
        }}
      >
        <Form.Item
          label='收费发票代码'
          name='aCode'
          {...required}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='收费发票号码'
          name='ano'
          rules={[{ required: true, message: ' ' }]}
          className={styles.input}
        >
          <Input.Group compact>
            <Form.Item
              name={['ano', 'astart']}
              rules={
                [{
                  required: true,
                  message: '请输入收费发票号码',
                },
                {
                  pattern: /^\d+$/,
                  message: '请输入正确数字',
                },
                ]
              } 
            >
              <Input />
            </Form.Item>
            <span style={{
              margin: 5
            }}>至</span>
            <Form.Item
              name={['ano', 'aend']}
              rules={
                [{
                  required: true,
                  message: '请输入收费发票号码',
                },
                {
                  pattern: /^\d+$/,
                  message: '请输入正确数字',
                },
                ]
              } 
            >
              <Input />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          label='收费发票当前号码'
          name='acurrent'
          {...required}
        >
          <Input onChange={refreshInvoiceNumberView} />
        </Form.Item>
        <Form.Item
            label='发票位数'
            name='ainvoiceNumberLength'
            rules={[{ required: true, message: ' ' }]}
            className={styles.input}
        >
          <Input.Group compact>
            <Form.Item
                name={['ainvoiceNumberLength']}
                rules={
                  [{
                    required: true,
                    message: '请输入发票位数，若不设定固定长度则输入0',
                  },
                    {
                      pattern: /^(1[0-5]|[0-9])$/,
                      message: '请输入0-15之间的数字',
                    },
                  ]
                }
            >
              <Input onChange={refreshInvoiceNumberView} />
            </Form.Item>
            <span style={{
              margin: 5
            }}>实际打印号码：{invoiceNumberViewA}</span>

          </Input.Group>
        </Form.Item>
        <Form.Item
          label='挂号发票代码'
          name='bCode'
          {...numberRules}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='挂号发票号码'
          name='bno'
          className={styles.input}
        >
          <Input.Group compact>
            <Form.Item
              name={['bno', 'bstart']}
              rules={
                [
                {
                  pattern: /^\d+$/,
                  message: '请输入正确数字',
                },
                ]
              } 
            >
              <Input />
            </Form.Item>
            <span style={{
              margin: 5
            }}>至</span>
            <Form.Item
              name={['bno', 'bend']}
              rules={
                [
                {
                  pattern: /^\d+$/,
                  message: '请输入正确数字',
                },
                ]
              } 
            >
              <Input />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          label='挂号发票当前号码'
          name='bcurrent'
          {...numberRules}
        >
          <Input onChange={refreshInvoiceNumberView} />
        </Form.Item>
        <Form.Item
            label='发票位数'
            name='binvoiceNumberLength'
            rules={[{ required: true, message: ' ' }]}
            className={styles.input}
        >
          <Input.Group compact>
            <Form.Item
                name={['binvoiceNumberLength']}
                rules={
                  [{
                    required: true,
                    message: '请输入发票位数，若不设定固定长度则输入0',
                  },
                    {
                      pattern: /^(1[0-5]|[0-9])$/,
                      message: '请输入1-15之间的数字',
                    },
                  ]
                }
            >
              <Input onChange={refreshInvoiceNumberView}/>
            </Form.Item>
            <span style={{
              margin: 5
            }}>实际打印号码：{invoiceNumberViewB}</span>

          </Input.Group>
        </Form.Item>
        <Row justify='center'>
          <Space>
            <Button type='primary' htmlType='submit'>保存</Button>
            <Button>取消</Button>
          </Space>
        </Row>
      </Form>
    </div >
  );
}
