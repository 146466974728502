/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 11:11:19
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-23 14:08:02
 */
import { Button, Col, Modal, Space, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { TabBar, TabPane } from '../../../compnents/widgets/TabBar'
import styles from './main.module.css'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootDispatch } from '../../../app/store'
import { NavBar } from '../../../compnents/nav/NavBar'
import { FanManagements } from './FanManagement/fanManagement'
import { DoctorManagements } from './DoctorManagement/DoctorManagement'
import { ClinicManagement } from './clinicManagement/clinicManagement'
import { UrlManagement } from './UrlManagement/UrlManagement'
import { MenuManagement } from './UrlManagement/MenuManagement'
import { YouYiKang } from '../youYiKang/YouYiKang'

export const MainManagementTabBar = () => {
  const location: any = useLocation()

  const history = useHistory()

  const [value, setValue] = useState<any>(3)

  const dispatch = useDispatch<RootDispatch>()

  const [activeId, setActiveId] = useState('0')

  return (
    <Col className={styles.container}>
      <TabBar
        defaultActiveKey={location.state ? location.state.selectKey : '0'}
        onChange={(v) => {
          setActiveId(v)
        }}
      >
        <TabPane key='0' tab='公众号'>
          <Tabs
            className={styles.tabs}
            style={{marginTop: '10px'}}
            onChange={(e) => {
              setValue(e)
            }}
          >
            {/*<TabPane key='0' tab='粉丝管理'>*/}
            {/*  <FanManagements tabKey={value} />*/}
            {/*</TabPane>*/}
            <TabPane key='3' tab='访问链接管理'>
              <UrlManagement tabKey={value} />
            </TabPane>
            <TabPane key='4' tab='菜单配置'>
              <MenuManagement tabKey={value} />
            </TabPane>
            <TabPane key='1' tab='诊所管理'>
              <ClinicManagement tabKey={value} />
            </TabPane>
            <TabPane key='2' tab='预约医生管理'>
              <DoctorManagements tabKey={value} />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane key='1' tab='第三方平台'>
          <div style={{ marginTop: '10px', height: '100%' }}> <YouYiKang selectKey={activeId} /></div>
        </TabPane>
      </TabBar>
      {/* <div
        style={{
          margin: '10px 0px 10px 0px',
        }}
      >
        <NavBar
          style={{ marginBottom: '10px' }}
          where={['公众号', '公众号管理']}
          // backtrace={{
          //   name: '公众号',
          //   path: '/weChart',
          //   state: {
          //     selectKey: '0',
          //   },
          // }}
        />
      </div>
       */}
    </Col>
  )
}
