import React, { MouseEventHandler, ReactElement } from 'react'
import { AlertOutlined } from '@ant-design/icons'
import styles from './Icons.module.css'
import { ReactComponent as AccountFilledIcon } from './svg/filled/account.svg'
import { ReactComponent as BackFilledIcon } from './svg/filled/back.svg'
import { ReactComponent as FemaleFilledIcon } from './svg/filled/female.svg'
import { ReactComponent as LogoFilledIcon } from './svg/filled/logo.svg'
import { ReactComponent as MaleFilledIcon } from './svg/filled/male.svg'
import { ReactComponent as PasswordFilledIcon } from './svg/filled/password.svg'
import { ReactComponent as RefreshFilledIcon } from './svg/filled/refresh.svg'
import { ReactComponent as TenantFilledIcon } from './svg/filled/tenant.svg'
import { ReactComponent as TreatmentFilledIcon } from './svg/filled/treatment.svg'
import { ReactComponent as AccountOutlinedIcon } from './svg/outlined/account.svg'
import { ReactComponent as ApointmentOutlinedIcon } from './svg/outlined/appointment.svg'
import { ReactComponent as JournalIcon } from './svg/filled/journal.svg'
import { ReactComponent as BackOutlinedIcon } from './svg/outlined/back.svg'
import { ReactComponent as HomeOutlineIcon3 } from './svg/outlined/chuzhen.svg'
import { ReactComponent as HomeOutlineIcon2 } from './svg/outlined/daijiezhen.svg'
import { ReactComponent as DelectOutlinedIcon } from './svg/outlined/delect.svg'
import { ReactComponent as DeleteOutlinedIcon } from './svg/outlined/delete.svg'
import { ReactComponent as DeliveryOutlinedIcon } from './svg/outlined/delivery.svg'
import { ReactComponent as DoctorStationOutlinedIcon } from './svg/outlined/doctorstation.svg'
import { ReactComponent as MoreBatchNumFilledIcon } from './svg/filled/moreBatchNum.svg'
import { ReactComponent as EditOutlinedIcon } from './svg/outlined/edit.svg'
import { ReactComponent as EditsOutlinedIcon } from './svg/outlined/edits.svg'
import { ReactComponent as HomeOutlineIcon4 } from './svg/outlined/fuzhen.svg'
import { ReactComponent as HomeOutlined7 } from './svg/outlined/jrys.svg'
import { ReactComponent as HomeOutlined8 } from './svg/outlined/jycx.svg'
import { ReactComponent as HelperCenterOutlinedIcon } from './svg/outlined/helpercenter.svg'
import { ReactComponent as HomeOutlinedIcon } from './svg/outlined/home.svg'
import { ReactComponent as HomeOutlineIcon1 } from './svg/outlined/huanzhe.svg'
import { ReactComponent as HomeOutlineIcon5 } from './svg/outlined/jinriyuyue.svg'
import { ReactComponent as LogoutOutlinedIcon } from './svg/outlined/logout.svg'
import { ReactComponent as MenuOutlinedIcon } from './svg/outlined/menu.svg'
import { ReactComponent as NotificationOutlinedIcon } from './svg/outlined/notification.svg'
import { ReactComponent as StatementOutlinedIcon } from './svg/outlined/statement.svg'
import { ReactComponent as NurseStationOutlinedIcon } from './svg/outlined/nursestation.svg'
import { ReactComponent as PatientsOutlinedIcon } from './svg/outlined/patients.svg'
import { ReactComponent as PaymentOutlinedIcon } from './svg/outlined/payment.svg'
import { ReactComponent as ComposingIcon } from './svg/filled/composing.svg'
import { ReactComponent as PitestOutlinedIcon } from './svg/outlined/pitest.svg'
import { ReactComponent as PrintOutlinedIcon } from './svg/outlined/print.svg'
import { ReactComponent as YSOutlinedIcon } from './svg/outlined/ys.svg'
import { ReactComponent as RegistrationOutlinedIcon } from './svg/outlined/registration.svg'
import { ReactComponent as RetailOutlinedIcon } from './svg/outlined/retail.svg'
import { ReactComponent as ReverseOutlinedIcon } from './svg/outlined/reverse.svg'
import { ReactComponent as SalesOutlinedIcon } from './svg/outlined/sales.svg'
import { ReactComponent as SettingOutlinedIcon } from './svg/outlined/setting.svg'
import { ReactComponent as HomeOutlineIcon6 } from './svg/outlined/suifang.svg'
import { ReactComponent as FilterOutlineIcon } from './svg/outlined/filterIcon.svg'
import { ReactComponent as TenantOutlinedIcon } from './svg/outlined/tenant.svg'
import { ReactComponent as TenantManagerOutlinedIcon } from './svg/outlined/tenantmanager.svg'
import { ReactComponent as TreatmentOutlinedIcon } from './svg/outlined/treatment.svg'
import { ReactComponent as UpgradeOutlinedIcon } from './svg/outlined/upgrade.svg'
import { ReactComponent as CountryHospitalNbOutlinedIcon } from './svg/outlined/countryHospitalNb.svg'
import { ReactComponent as CountryDoctorNbOutlinedIcon } from './svg/outlined/countryDoctorNb.svg'
import { ReactComponent as UserHomeOutlinedIcon } from './svg/outlined/userhome.svg'
import { ReactComponent as WarehouseStationOutlinedIcon } from './svg/outlined/warehousestation.svg'
import { ReactComponent as SparePartsOutlinedIcon } from './svg/outlined/spareparts.svg'
import { ReactComponent as RegularOutlinedIcon } from './svg/outlined/Regular.svg'
import { ReactComponent as OperatingRoomOutlinedIcon } from './svg/outlined/operatingRoom.svg'
import { ReactComponent as MedicalStationOutlinedIcon } from './svg/outlined/medicalStation.svg'
import { ReactComponent as TriageOutlinedIcon } from './svg/outlined/triage.svg'
import { ReactComponent as PersonalManageOutlinedIcon } from './svg/outlined/personalManage.svg'
import { ReactComponent as ShoperOutlinedIcon } from './svg/outlined/shoper.svg'
import { ReactComponent as ReadyAppealIcon } from './svg/filled/readyAppeal.svg'
import { ReactComponent as AppealInIcon } from './svg/filled/appealIn.svg'
import { ReactComponent as AppealOverIcon } from './svg/filled/appealOver.svg'
import { ReactComponent as AppealAllIcon } from './svg/filled/appealAll.svg'
import { ReactComponent as CloseIcon } from './svg/filled/close.svg'
import { ReactComponent as FaceLoginIcon } from './svg/filled/faceLogin.svg'
import { ReactComponent as JuhePayIcon } from './svg/filled/Juhe.svg'
import { ReactComponent as OvertimeSvg } from './svg/filled/overtime.svg'
import { ReactComponent as RunOffSvg } from './svg/filled/runoff.svg'
import { ReactComponent as ValueAddServiceSvg } from './svg/filled/valueAddService.svg'
import { ReactComponent as ProductCenterSvg } from './svg/filled/productCenter.svg'
import { ReactComponent as InsuranceBusinessSvg } from './svg/filled/insuranceBusiness.svg'
import { ReactComponent as FollowUpSvg } from './svg/filled/followUp.svg'
import { ReactComponent as AppointmentingSvg } from './svg/filled/appointmenting.svg'
import { ReactComponent as AppointmentingSureSvg } from './svg/filled/sure.svg'
import { ReactComponent as OutpatientedSvg } from './svg/filled/outpatiented.svg'
import { ReactComponent as RegistrationedSvg } from './svg/filled/registrationed.svg'
import { ReactComponent as CancelSvg } from './svg/filled/cancel.svg'
import { ReactComponent as AppointmentSvg } from './svg/filled/apointmentIcon.svg'
import { ReactComponent as WarningSvg } from './svg/filled/warning.svg'
import { ReactComponent as OweMoneySvg } from './svg/filled/oweMoney.svg'
import { ReactComponent as AppointmentedSvg } from './svg/filled/appointmented.svg'
import { ReactComponent as MessageTip } from './svg/filled/messageTip.svg'
import { ReactComponent as FireSVG } from './svg/filled/fire.svg'
import { ReactComponent as CASVG } from './svg/filled/ca.svg'
import { ReactComponent as UNCASVG } from './svg/filled/unca.svg'
import { ReactComponent as LikeSVG } from './svg/filled/like.svg'
import { ReactComponent as LikeedSVG } from './svg/filled/likeed.svg'
import { ReactComponent as LiveSVG } from './svg/filled/live.svg'
import { ReactComponent as PriceLowSVG } from './svg/filled/priceLow.svg'
import { ReactComponent as PriceChangeSVG } from './svg/filled/priceChange.svg'
import { ReactComponent as MembersSVG } from './svg/filled/members.svg'
import { ReactComponent as XiaoChengXu } from './svg/filled/xiaochengxu.svg'
import { ReactComponent as Over } from './svg/outlined/CheckCircle.svg'
import { ReactComponent as TransferTreatment } from './svg/filled/transferTreatment.svg'
import { ReactComponent as ActiveTransferTreatment } from './svg/filled/activeTransferTreatment.svg'
import { ReactComponent as ClinicalReception } from './svg/filled/clinicalReception.svg'
import { ReactComponent as InActiveClinicalReception } from './svg/filled/inActiveClinicalReception.svg'
import { ReactComponent as PaymentTooth } from './svg/outlined/payment.svg'
import { ReactComponent as FollowUpTooth } from './svg/filled/followUp.svg'
import { ReactComponent as FollowUpActive } from './svg/filled/followUpActive.svg'
import { ReactComponent as ApointmentTooth } from './svg/outlined/appointment.svg'
import { ReactComponent as ActiveAppointment } from './svg/filled/activeAppointment.svg'
import { ReactComponent as ActiveFFAEAppointment } from './svg/filled/activeFFAEAppointment.svg'
import { ReactComponent as NoRetweet } from './svg/filled/noRetweetIcon.svg'
import { ReactComponent as YBZZFIcon } from './svg/filled/YBZZFIcon.svg'
import { ReactComponent as ZFZYBIcon } from './svg/filled/ZFZYBIcon.svg'
import { ReactComponent as CashTooth } from './svg/filled/cash.svg'
import { ReactComponent as OfficialReceiptsTooth } from './svg/filled/officialReceipts.svg'
import { ReactComponent as Accomplish } from './svg/filled/accomplish.svg'
import { ReactComponent as NoDataSVG } from './svg/filled/noData.svg'
import { ReactComponent as AppointSVG } from './svg/filled/appoint.svg'
import { ReactComponent as GroupSVG } from './svg/filled/group.svg'
import { ReactComponent as MessageSVG } from './svg/filled/message.svg'
import { ReactComponent as RegistrationSVG } from './svg/filled/registration.svg'
import { ReactComponent as WomenIcon } from './svg/filled/womenIcon.svg'
import { ReactComponent as GirlSVG } from './svg/filled/girl.svg'
import { ReactComponent as BoySVG } from './svg/filled/boy.svg'
import { ReactComponent as GirlOutfillSVG } from './svg/outlined/girl.svg'
import { ReactComponent as BoyOutfillSVG } from './svg/outlined/boy.svg'
import { ReactComponent as VIPMenuSVG } from './svg/filled/VIPMenu.svg'
import { ReactComponent as DoctorSVG } from './svg/filled/doctor.svg'
import { ReactComponent as YBSVG } from './svg/filled/insuranceBusiness.svg'
import { ReactComponent as TreatmentIcon } from './svg/outlined/treatmentIcon.svg'
import { ReactComponent as DisposalIcon } from './svg/outlined/disposal.svg'
import { ReactComponent as HospitalIcon } from "./svg/outlined/hospital.svg";

type IconMode = 'large' | 'normal' | 'small'

interface IconProps {
  mode?: IconMode
  icon?: string
  className?: string
  onClick?: MouseEventHandler
  style?: React.CSSProperties
}

function getStyleName(props: IconProps): string {
  if (props.className && props.className !== 'ant-menu-item-icon') {
    return props.className
  }
  if (props.mode === 'large') {
    return styles.large
  }
  if (props.mode === 'small') {
    return styles.small
  }
  return styles.normal
}

export const AccountFilled = (props: IconProps): ReactElement => (
  <AccountFilledIcon className={getStyleName(props)} />
)

export const BackFilled = (props: IconProps): ReactElement => (
  <BackFilledIcon className={getStyleName(props)} style={props.style} />
)

export const BackOutlined = (props: IconProps): ReactElement => (
  <BackOutlinedIcon className={getStyleName(props)} />
)

export const DeleteOutlined = (props: IconProps): ReactElement => (
  <DeleteOutlinedIcon className={getStyleName(props)} style={props.style} />
)

export const EditOutlined = (props: IconProps): ReactElement => (
  <EditOutlinedIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const FemaleFilled = (props: IconProps): ReactElement => (
  <FemaleFilledIcon className={getStyleName(props)} style={props.style} />
)

export const LogoFilled = (props: IconProps): ReactElement => (
  <LogoFilledIcon className={getStyleName(props)} />
)

export const LogoutOutlined = (props: IconProps): ReactElement => (
  <LogoutOutlinedIcon className={getStyleName(props)} />
)

export const MaleFilled = (props: IconProps): ReactElement => (
  <MaleFilledIcon className={getStyleName(props)} style={props.style} />
)

export const PasswordFilled = (props: IconProps): ReactElement => (
  <PasswordFilledIcon className={getStyleName(props)} />
)

export const RefreshFilled = (props: IconProps): ReactElement => (
  <RefreshFilledIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const TenantFilled = (props: IconProps): ReactElement => (
  <TenantFilledIcon className={getStyleName(props)} />
)

export const TenantOutlined = (props: IconProps): ReactElement => (
  <TenantOutlinedIcon className={getStyleName(props)} />
)

export const TreatmentFilled = (props: IconProps): ReactElement => (
  <TreatmentFilledIcon className={getStyleName(props)} style={props.style} />
)


export const MoreBatchNumFilled = (props: IconProps): ReactElement => (
  <MoreBatchNumFilledIcon className={getStyleName(props)} style={props.style} />
)


export const UpgradeOutlined = (props: IconProps): ReactElement => (
  <UpgradeOutlinedIcon className={getStyleName(props)} />
)

export const CountryHospitalNbOutlined = (props: IconProps): ReactElement => (
  <CountryHospitalNbOutlinedIcon className={getStyleName(props)} />
)

export const CountryDoctorNbOutlined = (props: IconProps): ReactElement => (
  <CountryDoctorNbOutlinedIcon className={getStyleName(props)} />
)

export const UserHomeOutlined = (props: IconProps): ReactElement => (
  <UserHomeOutlinedIcon className={getStyleName(props)} />
)
export const UploadOutlined = (props: IconProps): ReactElement => (
  <UpgradeOutlinedIcon className={getStyleName(props)} />
)

export const UserHomeOutlined1 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon1 className={getStyleName(props)} style={props.style} />
)
export const UserHomeOutlined2 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon2 className={getStyleName(props)} style={props.style} />
)
export const UserHomeOutlined3 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon3 className={getStyleName(props)} style={props.style} />
)
export const UserHomeOutlined4 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon4 className={getStyleName(props)} style={props.style} />
)
export const UserHomeOutlined5 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon5 className={getStyleName(props)} style={props.style} />
)
export const UserHomeOutlined6 = (props: IconProps): ReactElement => (
  <HomeOutlineIcon6 className={getStyleName(props)} style={props.style} />
)

export const UserHomeOutlined7 = (props: IconProps): ReactElement => (
  <HomeOutlined7 className={getStyleName(props)} style={props.style} />
)

export const UserHomeOutlined8 = (props: IconProps): ReactElement => (
  <HomeOutlined8 className={getStyleName(props)} style={props.style} />
)

export const FilterIconOutlined = (props: IconProps): ReactElement => (
  <FilterOutlineIcon className={getStyleName(props)} style={props.style} />
)
export const xiaoChengXu = (props: IconProps): ReactElement => (
  <XiaoChengXu className={getStyleName(props)} style={props.style} />
)

export const OverOutlined = (props: IconProps): ReactElement => (
  <Over className={getStyleName(props)} style={props.style} />
)

export const transferTreatment = (props: IconProps): ReactElement => (
  <TransferTreatment className={getStyleName(props)} style={props.style} />
)
export const activeTransferTreatment = (props: IconProps): ReactElement => (
  <ActiveTransferTreatment
    className={getStyleName(props)}
    style={props.style}
  />
)
export const clinicalReception = (props: IconProps): ReactElement => (
  <ClinicalReception className={getStyleName(props)} style={props.style} />
)
export const inActiveClinicalReception = (props: IconProps): ReactElement => (
  <InActiveClinicalReception
    className={getStyleName(props)}
    style={props.style}
  />
)
export const paymentTooth = (props: IconProps): ReactElement => (
  <PaymentTooth className={getStyleName(props)} style={props.style} />
)
export const followUpTooth = (props: IconProps): ReactElement => (
  <FollowUpTooth className={getStyleName(props)} style={props.style} />
)
export const followUpActive = (props: IconProps): ReactElement => (
  <FollowUpActive className={getStyleName(props)} style={props.style} />
)
export const apointmentTooth = (props: IconProps): ReactElement => (
  <ApointmentTooth className={getStyleName(props)} style={props.style} />
)

export const activeAppointment = (props: IconProps): ReactElement => (
  <ActiveAppointment className={getStyleName(props)} style={props.style} />
)
export const cashTooth = (props: IconProps): ReactElement => (
  <CashTooth className={getStyleName(props)} style={props.style} />
)
export const officialReceiptsTooth = (props: IconProps): ReactElement => (
  <OfficialReceiptsTooth className={getStyleName(props)} style={props.style} />
)
export const womenIcon = (props: IconProps): ReactElement => (
  <WomenIcon className={getStyleName(props)} style={props.style} />
)
export const accomplish = (props: IconProps): ReactElement => (
  <Accomplish className={getStyleName(props)} style={props.style} />
)
export const PitestOutlined = (props: IconProps): ReactElement => (
  <PitestOutlinedIcon className={getStyleName(props)} style={props.style} />
)
export const EditsOutlined = (props: IconProps): ReactElement => (
  <EditsOutlinedIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const DelectOutlined = (props: IconProps): ReactElement => (
  <DelectOutlinedIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const SettingOutlined = (props: IconProps): ReactElement => (
  <SettingOutlinedIcon className={getStyleName(props)} style={props.style} />
)
export const AccountOutlined = (props: IconProps): ReactElement => (
  <AccountOutlinedIcon className={getStyleName(props)} style={props.style} />
)
export const ReverseOutlined = (props: IconProps): ReactElement => (
  <ReverseOutlinedIcon className={getStyleName(props)} style={props.style} />
)
export const PrintOutlined = (props: IconProps): ReactElement => (
  <PrintOutlinedIcon className={getStyleName(props)} style={props.style} />
)

export const YSOutlined = (props: IconProps): ReactElement => (
  <YSOutlinedIcon className={getStyleName(props)} style={props.style} />
)


export const SparePartsOutlined = (props: IconProps): ReactElement => (
  <SparePartsOutlinedIcon className={getStyleName(props)} style={props.style} />
)

export const RegularOutlined = (props: IconProps): ReactElement => (
  <RegularOutlinedIcon className={getStyleName(props)} style={props.style} />
)
export const PersonalManagementOutlinedIcon = (
  props: IconProps
): ReactElement => (
  <PersonalManageOutlinedIcon
    className={getStyleName(props)}
    style={props.style}
  />
)
export const ShoperOutlined = (props: IconProps): ReactElement => (
  <ShoperOutlinedIcon className={getStyleName(props)} style={props.style} />
)

export const AppealReady = (props: IconProps): ReactElement => (
  <ReadyAppealIcon className={getStyleName(props)} style={props.style} />
)

export const AppealOver = (props: IconProps): ReactElement => (
  <AppealOverIcon className={getStyleName(props)} style={props.style} />
)
export const AppealIn = (props: IconProps): ReactElement => (
  <AppealInIcon className={getStyleName(props)} style={props.style} />
)
export const AppealAll = (props: IconProps): ReactElement => (
  <AppealAllIcon className={getStyleName(props)} style={props.style} />
)
export const CloseFaceLoginLine = (props: IconProps): ReactElement => (
  <CloseIcon className={getStyleName(props)} style={props.style} />
)
export const FaceLoginLine = (props: IconProps): ReactElement => (
  <FaceLoginIcon className={getStyleName(props)} style={props.style} />
)
export const JuhePay = (props: IconProps): ReactElement => (
  <JuhePayIcon className={getStyleName(props)} style={props.style} />
)
// export const MembersLine = (props: IconProps): ReactElement => (
//   <MembersSVG className={getStyleName(props)} style={props.style} />
// )
export const OverTimeIcon = (props: IconProps): ReactElement => (
  <OvertimeSvg className={getStyleName(props)} style={props.style} />
)
export const MembersIcon = (props: IconProps): ReactElement => (
  <MembersSVG className={getStyleName(props)} style={props.style} />
)
export const RunOffIcon = (props: IconProps): ReactElement => (
  <RunOffSvg className={getStyleName(props)} style={props.style} />
)
export const AppointmentingIcon = (props: IconProps): ReactElement => (
  <AppointmentingSvg className={getStyleName(props)} style={props.style} />
)
export const AppointmentingSureIcon = (props: IconProps): ReactElement => (
  <AppointmentingSureSvg className={getStyleName(props)} style={props.style} />
)
export const OutpatientedIcon = (props: IconProps): ReactElement => (
  <OutpatientedSvg className={getStyleName(props)} style={props.style} />
)
export const RegistrationedIcon = (props: IconProps): ReactElement => (
  <RegistrationedSvg className={getStyleName(props)} style={props.style} />
)
export const CancelIcon = (props: IconProps): ReactElement => (
  <CancelSvg className={getStyleName(props)} style={props.style} />
)
export const AppointmentIcon = (props: IconProps): ReactElement => (
  <AppointmentSvg className={getStyleName(props)} style={props.style} />
)
export const OweMoneyIcon = (props: IconProps): ReactElement => (
  <OweMoneySvg className={getStyleName(props)} style={props.style} />
)
export const AppointmentedIcon = (props: IconProps): ReactElement => (
  <AppointmentedSvg className={getStyleName(props)} style={props.style} />
)
export const MessageTipIcon = (props: IconProps): ReactElement => (
  <MessageTip className={getStyleName(props)} style={props.style} />
)
export const WarningIcon = (props: IconProps): ReactElement => (
  <WarningSvg className={getStyleName(props)} style={props.style} />
)
export const FireIcon = (props: IconProps): ReactElement => (
  <FireSVG className={getStyleName(props)} style={props.style} />
)
export const CAIcon = (props: IconProps): ReactElement => (
  <CASVG className={getStyleName(props)} style={props.style} />
)
export const UNCAIcon = (props: IconProps): ReactElement => (
  <UNCASVG className={getStyleName(props)} style={props.style} />
)
export const LikeIcon = (props: IconProps): ReactElement => (
  <LikeSVG
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const LikeedIcon = (props: IconProps): ReactElement => (
  <LikeedSVG
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const LiveIcon = (props: IconProps): ReactElement => (
  <LiveSVG
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const XiaoChengXuIcon = (props: IconProps): ReactElement => (
  <XiaoChengXu
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const OverIcon = (props: IconProps): ReactElement => (
  <Over
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const TransferTreatmentIcon = (props: IconProps): ReactElement => (
  <TransferTreatment
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const ActiveTransferTreatmentIcon = (props: IconProps): ReactElement => (
  <ActiveTransferTreatment
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const ClinicalReceptionIcon = (props: IconProps): ReactElement => (
  <ClinicalReception
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const InActiveClinicalReceptionIcon = (
  props: IconProps
): ReactElement => (
  <InActiveClinicalReception
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const PaymentToothIcon = (props: IconProps): ReactElement => (
  <PaymentTooth
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const FollowUpToothIcon = (props: IconProps): ReactElement => (
  <FollowUpTooth
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const FollowUpActiveIcon = (props: IconProps): ReactElement => (
  <FollowUpActive
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const ApointmentToothIcon = (props: IconProps): ReactElement => (
  <ApointmentTooth
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const ActiveAppointmentIcon = (props: IconProps): ReactElement => (
  <ActiveAppointment
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const ActiveFFAEAppointmentIcon = (props: IconProps): ReactElement => (
  <ActiveFFAEAppointment
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const NoRetweetIcon = (props: IconProps): ReactElement => (
  <NoRetweet
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const YBZZFIcons = (props: IconProps): ReactElement => (
  <YBZZFIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const ZFZYBIcons = (props: IconProps): ReactElement => (
  <ZFZYBIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const TreatmentOutLineIcon = (props: IconProps): ReactElement => (
  <TreatmentIcon className={getStyleName(props)} style={props.style} />
)
export const DisposalOutLineIcon = (props: IconProps): ReactElement => (
    <DisposalIcon className={getStyleName(props)} style={props.style} />
)
export const CashToothIcon = (props: IconProps): ReactElement => (
  <CashTooth
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const OfficialReceiptsToothIcon = (props: IconProps): ReactElement => (
  <OfficialReceiptsTooth
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const WomenIconHeader = (props: IconProps): ReactElement => (
  <WomenIcon
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const AccomplishIcon = (props: IconProps): ReactElement => (
  <Accomplish
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)
export const PriceLowIcon = (props: IconProps): ReactElement => (
  <PriceLowSVG
    className={getStyleName(props)}
    style={props.style}
    onClick={props.onClick}
  />
)

export const PriceChangeIcon = (props: IconProps): ReactElement => (
  <PriceChangeSVG className={getStyleName(props)} style={props.style} />
)

export const NoDataIcon = (props: IconProps): ReactElement => (
  <NoDataSVG className={getStyleName(props)} style={props.style} />
)

export const AppointIcon = (props: IconProps): ReactElement => (
  <AppointSVG className={getStyleName(props)} style={props.style} />
)

export const GroupIcon = (props: IconProps): ReactElement => (
  <GroupSVG className={getStyleName(props)} style={props.style} />
)

export const MessageIcon = (props: IconProps): ReactElement => (
  <MessageSVG className={getStyleName(props)} style={props.style} />
)

export const RegistrationIcon = (props: IconProps): ReactElement => (
  <RegistrationSVG className={getStyleName(props)} style={props.style} />
)

export const GirlIcon = (props: IconProps): ReactElement => (
  <GirlSVG className={getStyleName(props)} style={props.style} />
)

export const BoyIcon = (props: IconProps): ReactElement => (
  <BoySVG className={getStyleName(props)} style={props.style} />
)

export const GirlOutfillIcon = (props: IconProps): ReactElement => (
  <GirlOutfillSVG className={getStyleName(props)} style={props.style} />
)

export const BoyOutfillIcon = (props: IconProps): ReactElement => (
  <BoyOutfillSVG className={getStyleName(props)} style={props.style} />
)

export const DoctorIcon = (props: IconProps): ReactElement => (
  <DoctorSVG className={getStyleName(props)} style={props.style} />
)

export const YBIcon = (props: IconProps): ReactElement => (
  <YBSVG className={getStyleName(props)} style={props.style} />
)

export const HospitalOutlineIcon = (props: IconProps): ReactElement => (
  <HospitalIcon className={getStyleName(props)} style={props.style} />
);




export const Icon = (props: IconProps): ReactElement => {
  switch (props.icon) {
    case 'appointment':
      return (
        <ApointmentOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'delivery':
      return (
        <DeliveryOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'doctorstation':
      return (
        <DoctorStationOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'home':
      return (
        <HomeOutlinedIcon className={getStyleName(props)} style={props.style} />
      )
    case 'menu':
      return (
        <MenuOutlinedIcon className={getStyleName(props)} style={props.style} />
      )
    case 'notification':
      return (
        <NotificationOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'nursestation':
      return (
        <NurseStationOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'payment':
      return (
        <PaymentOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'composing':
      return (
        <ComposingIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'registration':
      return (
        <RegistrationOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'retail':
      return (
        <RetailOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'sales':
      return (
        <SalesOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'tenant':
      return (
        <TenantOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'tenantmanager':
      return (
        <TenantManagerOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'treatment':
      return (
        <TreatmentOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'warehousestation':
      return (
        <WarehouseStationOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'patients':
      return (
        <PatientsOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'statement':
      return (
        <StatementOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'triage':
      return (
        <TriageOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'personalManagement':
      return (
        <PersonalManageOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'shoper':
      return (
        <ShoperOutlinedIcon
          className={getStyleName(props)}
          style={props.style}
        />
      )
    case 'journal':
      return (
        <JournalIcon
          className={getStyleName(props)}
          style={{
            fontSize: '22px',
            marginLeft: '0',
            color: '#FFF'
          }}
        />
      )
    case 'operationStation':
      return (
        <OperatingRoomOutlinedIcon
          className={getStyleName(props)}
          style={{
            fontSize: '22px',
            fill: "#fff",
            marginLeft: '0',
          }}
        />
      )
    case 'medicalSation':
      return (
        <MedicalStationOutlinedIcon
          className={getStyleName(props)}
          style={{
            fontSize: '22px',
            fill: "#fff",
            marginLeft: '0',
          }}
        />
      )
    case 'followUp':
      return (
        <FollowUpSvg
          className={getStyleName(props)}
          style={{
            fontSize: '22px',
            marginLeft: '0',
            fill: "#fff",
          }}
        />
      )
    case 'insuranceBusiness':
      return (
        <InsuranceBusinessSvg
          className={getStyleName(props)}
          style={{
            fontSize: '22px',
            marginLeft: '0',
          }}
        />
      )
    case 'valueAddService':
      return (
        <ValueAddServiceSvg
          className={getStyleName(props)}
          style={{
            fontSize: '22px',
            marginLeft: '0',
          }}
        />
      )
    case 'productCenter':
      return (
        <ProductCenterSvg
          className={getStyleName(props)}
          style={{
            fontSize: '22px',
            marginLeft: '0',
          }}
        />
      )
    case 'appealReady':
      return (
        <ReadyAppealIcon className={getStyleName(props)} style={props.style} />
      )
    case 'appealIn':
      return (
        <AppealInIcon className={getStyleName(props)} style={props.style} />
      )
    case 'appealOver':
      return (
        <AppealOverIcon className={getStyleName(props)} style={props.style} />
      )
    case 'appealAll':
      return (
        <AppealAllIcon className={getStyleName(props)} style={props.style} />
      )
    case 'close':
      return <CloseIcon className={getStyleName(props)} style={props.style} />
    case 'faceLogin':
      return (
        <FaceLoginIcon className={getStyleName(props)} style={props.style} />
      )
    case 'juhePay':
      return (
        <JuhePayIcon className={getStyleName(props)} style={props.style} />
      )
    case 'member':
      return (
        <VIPMenuSVG className={getStyleName(props)} style={props.style} />
      )
  }
  return (
    <HelperCenterOutlinedIcon
      className={getStyleName(props)}
      style={props.style}
    />
  )
}

export default Icon
