/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-13 14:53:45
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-26 09:52:38
 */
import { Button, Checkbox, Col, Collapse, Divider, Row } from 'antd'
import React, { ReactElement, useState } from 'react'
import { Label } from '../../../compnents/widgets/Label'
import { HistoryContent } from './historyContent'
import { HistoryTitle } from './historyTitle'
import styles from './TcmCases.module.css'

type action = 'check' | 'active' | 'again'
interface HistoryPanelProps {
  historyList: any
  historyData: any
  tag?: any
  onChange: (v: string) => void
  onCopy: (copyRecipe: boolean, srcTreatmentId: string) => void
  CollapseActivekey: string
  changeAction: (action: action, v?: any) => void
  seeOtherDoctor: boolean
  reuseTreatmentId?: string
  toothRegistrationId?: string
}

export const HistoryPanel = ({
  historyList,
  historyData,
  tag,
  onChange,
  onCopy,
  CollapseActivekey,
  changeAction,
  seeOtherDoctor,
  reuseTreatmentId,
  toothRegistrationId, // 口腔专业版
}: HistoryPanelProps): ReactElement => {

  const historyItemTwo = [
    {
      name: '主诉：',
      value: 'chiefComplaint',
      flag: 1,
    },
    {
      name: '现病史：',
      value: 'historyOfPresentIllness',
      flag: 4,
    },
    {
      name: '既往史：',
      value: 'pastMedicalHistory',
      flag: 4,
    },
    {
      name: '过敏史：',
      value: 'allergen',
      flag: 4,
    },
    {
      name: '脉象：',
      value: 'pulseCondition',
      flag: 1,
    },
    {
      name: '舌象：',
      value: 'tonguePicture',
      flag: 1,
    },
    {
      name: '疾病名称：',
      value: tag == 'tooth' ? 'diseaseList' : 'disease',
      flag: 2,
    },
    {
      name: '个人史：',
      value: 'personalHistory',
      flag: 4,
    },
    {
      name: '家族史：',
      value: 'familyHistory',
      flag: 4,
    },
    {
      name: '疫苗接种史：',
      value: 'vaccineHistory',
      flag: 3,
    },
    {
      name: '月经史：',
      value: 'menstrualHistory',
      flag: 4,
    },
    {
      name: '婚育史：',
      value: 'maritalHistory',
      flag: 4,
    },
    {
      name: '体格检查：',
      value: 'tgjc',
      flag: 2,
    },
    {
      name: '口腔检查：',
      value: 'stomatologyToothList',
      flag: 2,
    },

    {
      name: '检验检查：',
      value: tag == 'tooth' ? 'inspectionToothList' : 'inspectionCheck',
      flag: 2,
    },
    {
      name: '治疗计划：',
      value: 'treatmentToothList',
      flag: 2,
    },
    {
      name: '处置：',
      value: 'disposeToothList',
      flag: 1,
    },
    {
      name: '治疗意见：',
      value: 'treatmentConclusion',
      flag: 2,
    },
  ]

  const { Panel } = Collapse

  const message = seeOtherDoctor
    ? '您没有接诊过该患者'
    : '该患者在本机构没有历史就诊记录'

  return (
    <>
      {!toothRegistrationId && (
        <>
          <Label label='就诊历史' style={{ marginLeft: 20 }} />
          <Divider />
        </>
      )}
      <Col
        style={{
          padding: 10,
        }}
      >
        <Checkbox
          checked={seeOtherDoctor}
          onChange={(e) => {
            changeAction('check', e.target.checked)
            const check:any =e.target.checked
            localStorage.setItem('historyPanelCheck',check)
          }}
        >
          不看其他医生
        </Checkbox>
      </Col>
      <Col className={styles.historyContainer}>
        {historyList.length ? (
          <Collapse
            key={historyList[0]?.id}
            expandIconPosition='right'
            defaultActiveKey={historyList[0]?.id}
            accordion
            activeKey={CollapseActivekey}
            onChange={(v) => {
              changeAction('active', v as string)
              if (v && !historyData[v as string]) {
                onChange(v as string)
              }
            }}
          >
            {historyList.map((t: any) => (
              <Panel
                header={
                  <HistoryTitle t={t} CollapseActivekey={CollapseActivekey} />
                }
                key={t.id}
                style={{
                  backgroundColor: CollapseActivekey == t.id ? '#FAFAFA' : '',
                }}
              >
                <Row
                  justify='space-between'
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <Button
                    size='small'
                    // type='primary'
                    onClick={() => {
                      onCopy(true, t.id)
                    }}
                  >
                    复制病历及处方
                  </Button>
                  <Button
                    // type='primary'
                    size='small'
                    onClick={() => {
                      onCopy(false, t.id)
                    }}
                  >
                    复制病历
                  </Button>
                  <Button
                    size='small'
                    type={reuseTreatmentId == t.id ? 'primary' : 'default'}
                    onClick={() => {
                      changeAction(
                        'again',
                        reuseTreatmentId == t.id ? '' : t.id
                      )
                    }}
                  >
                    复诊
                  </Button>
                </Row>
                {historyItemTwo.map((v) => {
                  return t[v.value] ? (
                    <div className={styles.historyItem}>
                      <div
                        className={
                          v.flag == 1
                            ? styles.historyNoWarp
                            : v.flag == 3
                            ? styles.historyWarpWidth
                            : v.flag == 4
                            ? styles.historyWarpWidthThreeText
                            : styles.historyWarp
                        }
                      >
                        {v.name}
                      </div>
                      <div className={styles.rightContent}>
                        {tag === 'tooth' && v?.value == 'diseaseList' ? (
                          t?.diseaseList?.map((item: any) => {
                            return (
                              <div
                                key={item}
                                className={styles.rightContentText}
                              >
                                {item}
                              </div>
                            )
                          })
                        ) : tag === 'tooth' &&
                          v?.value == 'stomatologyToothList' ? (
                          t?.stomatologyToothList?.map((item: any) => {
                            return (
                              <div
                                key={item}
                                className={styles.rightContentText}
                              >
                                {item}
                              </div>
                            )
                          })
                        ) : tag === 'tooth' &&
                          v?.value == 'inspectionToothList' ? (
                          t?.inspectionToothList?.map((item: any) => {
                            return (
                              <div
                                key={item}
                                className={styles.rightContentText}
                              >
                                {item}
                              </div>
                            )
                          })
                        ) : tag === 'tooth' &&
                          v?.value == 'treatmentToothList' ? (
                          t?.treatmentToothList?.map((item: any) => {
                            return (
                              <div
                                key={item}
                                className={styles.rightContentText}
                              >
                                {item}
                              </div>
                            )
                          })
                        ) : tag === 'tooth' &&
                          v?.value == 'disposeToothList' ? (
                          t?.disposeToothList?.map((item: any) => {
                            return (
                              <div
                                key={item}
                                className={styles.rightContentText}
                              >
                                {item}
                              </div>
                            )
                          })
                        ) : (
                          <div>{t[v.value].split('#%#').join('，')}</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                })}
                {historyData[t.id] &&
                  historyData[t.id].map((re: any, i: number) => (
                    <HistoryContent v={re} key={i} />
                  ))}
                <Row
                  justify='space-between'
                  style={{
                    marginTop: 5,
                    color: '#666',
                  }}
                >
                  <span>{t.createTime}</span>
                  <span>
                    金额:￥
                    {historyData[t.id] &&
                      historyData[t.id]
                        .map((v: any) => v.amount)
                        ?.reduce((total: number, currentValue: number) => {
                          return total + currentValue
                        }, 0)
                        ?.toFixed(2)}
                  </span>
                </Row>
              </Panel>
            ))}
          </Collapse>
        ) : (
          <div
            className={styles.historyMessage}
            style={{
              fontStyle: 'oblique',
              color: '#999',
            }}
          >
            {message}
          </div>
        )}
      </Col>
      <Row
        style={{
          fontStyle: 'oblique',
          color: '#999',
          paddingRight: 15,
          marginTop: 3,
        }}
        justify='end'
      >
        仅展示1年以内的历史就诊记录
      </Row>
    </>
  )
}
