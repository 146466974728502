/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-24 09:22:58
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../../app/store'
import { sendAsync } from '../../../../app/applicationSlice'
import * as CM from '../../../../services/youYiKangClinicManagement'
import { ClinicSubmitParams } from '../../../../services/youYiKangClinicManagement'

// 右侧详情
export const getClinicDetail = createAsyncThunk<any, void, RootThunkApi>(
  'clinic/getClinicDetail',
  async (params, api) => {
    return sendAsync(CM.clinicDetail(), api)
  }
)

// 保存接口
export const getClinicSubmit = createAsyncThunk<
  void,
  ClinicSubmitParams,
  RootThunkApi<void>
>('clinic/getClinicSubmit', async (params, api) => {
  return sendAsync(CM.clinicSubmit(params), api)
})



// 优医康判断是否展示接口
export const getStateTag = createAsyncThunk<
  void,
  CM.StateTagParams,
  RootThunkApi<void>
>('clinic/getStateTag', async (params, api) => {
  return sendAsync(CM.stateTag(params), api)
})