/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 16:15:53
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-05 11:42:48
 */
import { CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'
import {
  Button,
  Col,
  Dropdown,
  Menu,
  Modal,
  Popconfirm,
  Rate,
  Space,
  TableColumnType,
  Tooltip,
} from 'antd'
import moment from 'moment'
import { win32 } from 'path'
import React, { ReactElement, useState } from 'react'
import { MembersIcon, OweMoneyIcon } from '../../../../compnents/icons/Icons'
import { getSourceTypeName } from '../../../../models/registration'
import { getGenderName } from '../../../../models/user'
import { getAge } from '../../../../utils/StringUtils'
import styles from './patientList.module.css'

type Action =
  | 'appointment'
  | 'registration'
  | 'message'
  | 'delete'
  | 'merge'
  | 'more'

export const Columns = (
  startIndex: number,
  onAction: (action: Action, t: any) => void
): TableColumnType<any>[] => {
  const menu = (t: any) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          onAction('merge', t)
        }}
      >
        合并患者
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          Modal.confirm({
            centered: true,
            title: '确认操作',
            content: (
              <div>
                <ExclamationCircleFilled
                  style={{ color: '#FFAE55', fontSize: 18 }}
                />
                &nbsp;您确定删除 {t?.patient?.name} 患者吗？
              </div>
            ),
            okText: '确认',
            cancelText: '取消',
            icon: null,
            onOk: () => {
              onAction('delete', t)
            },
          })
        }}
      >
        删除
      </Menu.Item>
    </Menu>
  )

  const w3 = '3rem'
  const w5 = '5rem'
  const w8 = '8rem'
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '4rem',
      fixed: 'left',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '患者姓名',
      dataIndex: 'name',
      align: 'center',
      fixed: 'left',
      width: '8.6rem',
      render: function a(_, t) {
        return (
          <div className={styles.cellLeft}>
            <div
              className={styles.name}
              style={{
                width:
                  t.isMeber != 1 &&
                  Number(t.arrearageAmountSum) <= 0 &&
                  !t.patient?.pastMedicalHistory &&
                  !t.patient?.allergyHistory &&
                  t.labels?.length == 0
                    ? '100%'
                    : '',
              }}
            >
              <Tooltip title={t?.patient?.name}>{t?.patient?.name}</Tooltip>
            </div>
            <div className={styles.labelName}>
              {t.isMeber == 1 && (
                <Tooltip
                  title={
                    <div>
                      <p>卡号：{t.member.memberPhone}</p>
                      <p>余额：{t.member.balanceMoney}</p>
                    </div>
                  }
                >
                  <span className={styles.icon}>
                    <MembersIcon
                      style={{
                        fontSize: 18,
                        width: 18,
                        height: 18,
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {Number(t.arrearageAmountSum) > 0 && (
                <Tooltip title={<div>欠费：{t.arrearageAmountSum}元</div>}>
                  <span className={styles.icon}>
                    <OweMoneyIcon
                      style={{
                        fontSize: 18,
                        width: 18,
                        height: 18,
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {(t.patient?.pastMedicalHistory || t.patient?.allergyHistory) && (
                <span
                  className={styles.iconlabel}
                  style={{ backgroundColor: '#FF7373', color: '#FFF' }}
                >
                  <Tooltip
                    title={
                      <div>
                        既往史：
                        {t.patient?.pastMedicalHistory && (
                          <p>{t.patient?.pastMedicalHistory}</p>
                        )}
                        过敏史：
                        {t.patient?.allergyHistory && (
                          <p>{t.patient?.allergyHistory}</p>
                        )}
                      </div>
                    }
                  >
                    史
                  </Tooltip>
                </span>
              )}
              {t?.labels &&
                t.labels?.length != 0 &&
                t.labels.map((v: any, i: number) => (
                  <span
                    key={i}
                    className={styles.iconlabel}
                    style={{
                      backgroundColor: v.backgroundColor,
                      color: v.fontColor,
                    }}
                  >
                    <Tooltip title={v.name}>{v.headName}</Tooltip>
                  </span>
                ))}
            </div>
          </div>
        )
      },
    },
    {
      title: '病历号',
      dataIndex: 'ageYear',
      align: 'center',
      width: '6rem',
      render: (_, { patient }) => {
        return patient.id
      },
      // sorter: (a, b) => {
      //   return a.patient.id - b.patient.id
      // },
    },
    {
      title: '星级',
      dataIndex: 'star',
      align: 'center',
      width: '4.5rem',
      render: function action(_, { patient }) {
        return patient?.star ? (
          <div className={styles.star}>
            {' '}
            <Rate
              disabled
              value={patient?.star}
              style={{
                fill: '#FFAE55',
              }}
            />
          </div>
        ) : (
          ''
        )
        // return <div className={styles.star}><Rate disabled defaultValue={4} /></div>
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      width: w3,
      render: (_, { patient }) => {
        return getGenderName(patient?.sex)
      },
    },
    {
      title: '年龄',
      dataIndex: 'ageYear',
      align: 'center',
      width: w3,
      render: (_, { patient }) => {
        return getAge(patient?.ageYear, patient?.ageMonth)
      },
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      width: '8rem',
      render: (_, { patient }) => {
        return patient?.phone
      },
    },
    {
      title: '介绍人',
      dataIndex: 'introducer',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, { patient }) => {
        return patient?.introducer
      },
    },
    {
      title: '咨询师',
      dataIndex: 'counselor',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, { patient }) => {
        return patient?.counselor
      },
    },
    {
      title: '患者分组',
      dataIndex: 'createTime',
      align: 'center',
      width: '11rem',
      ellipsis: true,
      render: (_, { groupItemVos }) => {
        return groupItemVos.length != 0
          ? groupItemVos?.map((v: any) => v.groupName).join('，')
          : ''
      },
    },
    {
      title: '患者来源',
      dataIndex: 'source',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, { patient }) => {
        return getSourceTypeName(patient?.source)
      },
    },
    {
      title: '就诊项目',
      dataIndex: 'treatmentItem',
      align: 'center',
      width: '6rem',
      render: (_, { patient }) => {
        return patient?.treatmentItem
      },
    },
    {
      title: '主治医生',
      dataIndex: 'attendingDoctor',
      align: 'center',
      width: '7rem',
      render: (_, { patient }) => {
        return patient?.attendingDoctor
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      ellipsis: true,
      width: '11rem',
      render: (_, { patient }) => {
        return patient?.createTime
      },
      sorter: (a: any, b: any) =>
        moment(a?.patient?.createTime).unix() -
        moment(b?.patient?.createTime).unix(),
    },
    {
      title: '最近一次挂号时间',
      dataIndex: 'registrationTime',
      align: 'center',
      ellipsis: true,
      fixed: 'right',
      width: '11rem',
      sorter: (a: any, b: any) =>
        moment(a.registrationTime || moment()).unix() -
        moment(b.registrationTime || moment()).unix(),
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      fixed: 'right',
      width: '11rem',
      render: function action(_, t) {
        return (
          <Space>
            <Button
              type='link'
              size='small'
              onClick={() => {
                onAction('appointment', t)
              }}
            >
              预约
            </Button>
            <Button
              type='link'
              size='small'
              onClick={() => {
                onAction('registration', t)
              }}
            >
              挂号
            </Button>
            <Button
              type='link'
              size='small'
              onClick={() => {
                onAction('message', t)
              }}
            >
              短信
            </Button>
            <Dropdown overlay={menu(t)} trigger={['click']}>
              <Button
                type='link'
                size='small'
                onClick={() => {
                  onAction('more', t)
                }}
              >
                更多
              </Button>
            </Dropdown>
          </Space>
        )
      },
    },
  ]
}

export const MorePatientColumns = (
  startIndex: number
): TableColumnType<any>[] => {
  const w3 = '3rem'
  return [
    // {
    //   title: '序号',
    //   key: 'no',
    //   align: 'center',
    //   width: '4rem',
    //   render: (_1, _2, i) => `${startIndex + i + 1}`,
    // },
    {
      title: '患者姓名',
      dataIndex: 'name',
      align: 'center',
      width: '8rem',
      render: function a(_, t) {
        return (
          <div className={styles.cellLeft}>
            <div
              className={styles.name}
              style={{
                width: '100%',
              }}
            >
              <Tooltip title={t?.patient?.name}>{t?.patient?.name}</Tooltip>
            </div>
          </div>
        )
      },
    },
    {
      title: '病历号',
      dataIndex: 'ageYear',
      align: 'center',
      render: (_, { patient }) => {
        return patient.id
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      // width: w3,
      render: (_, { patient }) => {
        return getGenderName(patient?.sex)
      },
    },
    {
      title: '年龄',
      dataIndex: 'ageYear',
      align: 'center',
      // width: w3,
      render: (_, { patient }) => {
        return getAge(patient?.ageYear, patient?.ageMonth)
      },
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      width: '7rem',
      render: (_, { patient }) => {
        return patient?.phone
      },
    },
    {
      title: '咨询师',
      dataIndex: 'counselor',
      align: 'center',
      render: (_, { patient }) => {
        return patient?.counselor
      },
    },
    {
      title: '患者分组',
      dataIndex: 'createTime',
      align: 'center',
      width: '11rem',
      ellipsis: true,
      render: (_, { groupItemVos }) => {
        return groupItemVos.length != 0
          ? groupItemVos?.map((v: any) => v.groupName).join('，')
          : ''
      },
    },
    {
      title: '患者来源',
      dataIndex: 'source',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, { patient }) => {
        return getSourceTypeName(patient?.source)
      },
    },
    {
      title: '就诊项目',
      dataIndex: 'treatmentItem',
      align: 'center',
      render: (_, { patient }) => {
        return patient?.treatmentItem
      },
    },
    {
      title: '主治医生',
      dataIndex: 'attendingDoctor',
      align: 'center',
      render: (_, { patient }) => {
        return patient?.attendingDoctor
      },
    },
  ]
}
