import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { identity } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { NavBar } from "../../compnents/nav/NavBar";
import { CategoryName, getflagCategory } from "../../models/category";
import { DateTimeFormat } from "../../models/datetime";
import { Department } from "../../models/department";
import { User } from "../../models/user";
import { getStorage, selectStorage } from "../durgsystem/durgsystemSlice";
import {
  DepartmentDate,
  savePutstorage,
  selectDepartData,
} from "../putstorage/putstorageSlice";
import {
  getUserName,
  selectstoreId,
  setSelectId,
  sleectUserData,
} from "../putstorelist/putstorelistSlice";
import { SupplierModal } from "../savebusiness/modal";
import { SupplierModal as SupplierSaveModal } from "../wms/modal";
import styles from "../savebusiness/Savebusiness.module.css";
import { setCurrent } from "../wms/supplierSlice";
import {
  selectUserDepartmentId,
  selectUserId,
} from "../../app/applicationSlice";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const TransferOut = () => {
  const history = useHistory();

  const location: any = useLocation();

  const [form] = Form.useForm();

  const [Text, setText] = useState("新增");

  const [supplierId, setSupplierId] = useState(0);

  const [filterPutStorage, setfilterPutStorage] = useState<any[]>([]);

  const dispatch = useDispatch();

  const [outOrInflag, setOutOrInflag] = useState<any>(0);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [ismodalVisible, setIsmodalVisible] = useState(false);

  const Id = useSelector(selectstoreId);

  const durgsystemData = useSelector(selectStorage);

  const departmentData = useSelector(selectDepartData);

  const userData = useSelector(sleectUserData);

  const userId = useSelector(selectUserId);

  const departmentId = useSelector(selectUserDepartmentId);

  useEffect(() => {
    dispatch(
      getStorage({ current: 1, flag: getflagCategory(location.state.category) })
    );
    dispatch(DepartmentDate({ current: 1, size: 1000 }));

    if (userId || departmentId) {
      dispatch(getUserName(departmentId));
      form.setFieldsValue({
        departmentId: Number(departmentId),
        userId,
      });
    }
  }, []);


  useEffect(() => {
    if (Id) {
      history.push({
        pathname: "/putlist",
        state: { category: location.state.category, selectId: Id },
      });
      dispatch(setSelectId(0));
    }
  }, [Id]);

  useEffect(() => {
    if (location.state.detail) {
      const detail = location.state.detail;
      setText("修改");

      if (JSON.stringify(detail) !== "{}") {
        setOutOrInflag(detail.flag);
        setSupplierId(detail.supplierId);
        form.setFieldsValue({
          storehouseId: detail.storehouseId,
          storehouseName: detail.storehouseName,
          flag: detail.flag,
          acceptStorehouseId: detail.acceptStorehouseId,
          acceptStorehouseName: detail.acceptStorehouseName,
          supplierName: detail.supplierName,
          departmentId: detail.departmentId,
          departmentName: detail.departmentName,
          userId: detail.userId?.toString(),
          userName: detail.userName,
          putNumber: detail.putNumber,
          recepitsDate: moment(detail.recepitsDate),
        });
        dispatch(getUserName(detail.departmentId));
        setfilterPutStorage(
          durgsystemData.filter((v: any) => {
            return v.storehouseId !== Number(detail.storehouseId);
          })
        );
        setfilterPutStorage(
          durgsystemData.filter((v: any) => {
            return v.storehouseId !== detail.storehouseId;
          })
        );
      }
    } else {
      form.setFieldsValue({
        recepitsDate: moment(),
        flag:0
      });
    }
  }, [location.state.detail]);

  return (
    <div>
      <Col
        style={{
          margin: '10px 20px',
        }}
      >
        <NavBar
          where={['库房业务', CategoryName(location.state.category) as string]}
          backtrace={{
            name: '入库列表',
            path: '/putstorage',
            state: { category: location.state.category },
            routeName: '库房工作站',
          }}
          buttons={<></>}
        />
      </Col>
      <div className={styles.content}>
        <div className={styles.title}>
          <p className={styles.titletext}>
            {Text + CategoryName(location.state.category)}
          </p>
        </div>
        <Form
          {...layout}
          form={form}
          name='control-hooks'
          autoComplete='off'
          onFinish={(values) => {
            const storehouseName =
              (durgsystemData.find(
                (d: any) => d.storehouseId === values.storehouseId
              ) as any)?.storehouseName || ''
            const departmentName =
              (departmentData.find(
                (d: any) => d.id === values.departmentId
              ) as any)?.name || ''
            const userName =
              (userData.find((u: any) => u.id === values.userId) as any)
                ?.name || ''
            const acceptStorehouseName =
              (filterPutStorage.find(
                (d: any) => d.storehouseId === values.acceptStorehouseId
              ) as any)?.storehouseName || ''
            const date = moment(values.recepitsDate)
            if (location.state.detail) {
              dispatch(
                savePutstorage({
                  ...values,
                  acceptStorehouseName,
                  storehouseName,
                  departmentName,
                  userName,
                  supplierId,
                  category: location.state.category,
                  recepitsDate: date.format(DateTimeFormat),
                  id: location.state.detail.id,
                })
              )
            } else {
              dispatch(
                savePutstorage({
                  ...values,
                  acceptStorehouseName,
                  storehouseName,
                  departmentName,
                  userName,
                  supplierId,
                  category: location.state.category,
                  recepitsDate: date.format(DateTimeFormat),
                })
              )
            }
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name='storehouseId'
                label='库房名称'
                rules={[
                  {
                    required: true,
                    message: '库房名称不能为空,请输入正确的值。',
                  },
                ]}
              >
                <Select
                  placeholder='调拨药房'
                  allowClear
                  onSelect={(key: any) => {
                    form.setFieldsValue({
                      acceptStorehouseId: undefined,
                    })
                    setfilterPutStorage(
                      durgsystemData.filter((v: any) => {
                        return v.storehouseId !== Number(key)
                      })
                    )
                  }}
                  disabled={location.state.detail ? true : false}
                >
                  {durgsystemData.map((v: any, i) => {
                    return (
                      <Option value={v.storehouseId} key={i}>
                        {v.storehouseName}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='flag'
                label='对内对外'
                rules={[
                  { required: true, message: '供应商不能为空,请输入正确的值' },
                ]}
              >
                <Select
                  disabled
                  allowClear
                  onChange={(value) => {
                    setOutOrInflag(value)
                  }}
                >
                  <Option value={0}>对内</Option>
                  {/* <Option value={1}>对外</Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Form.Item style={{ display: 'none' }} name='supplierId'>
              <Input type='hidden' style={{ display: 'none' }} />
            </Form.Item>
            {!outOrInflag ? (
              <Col span={12}>
                <Form.Item
                  name='acceptStorehouseId'
                  label='接收库房'
                  rules={[
                    {
                      required: true,
                      message: '接收库房不能为空,请输入正确的值。',
                    },
                  ]}
                >
                  <Select
                    placeholder='接收药房'
                    allowClear
                    onSelect={(key: any) => {
                      return
                    }}
                  >
                    {filterPutStorage.map((v: any, i) => {
                      return (
                        <Option value={v.storehouseId} key={i}>
                          {v.storehouseName}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <Col span={12}>
                <Form.Item
                  name='supplierName'
                  label='供应商'
                  rules={[
                    {
                      required: true,
                      message: '供应商不能为空,请输入正确的值。',
                    },
                  ]}
                >
                  <Input
                    onClick={() => {
                      setIsModalVisible(true)
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                name='departmentId'
                label='业务部门'
                rules={[
                  {
                    required: true,
                    message: '业务部门不能为空,请输入正确的值。',
                  },
                ]}
              >
                <Select
                  placeholder='业务部门'
                  allowClear
                  showSearch
                  onSelect={(key: any) => {
                    form.setFieldsValue({
                      userId: undefined,
                    })
                    dispatch(getUserName(key))
                  }}
                >
                  {departmentData.map((v: Department) => {
                    return (
                      <Option value={v.id} key={v.id}>
                        {v.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='userId'
                label='业务人员'
                rules={[
                  {
                    required: true,
                    message: '业务人员不能为空,请输入正确的值。',
                  },
                ]}
              >
                <Select showSearch placeholder='业务人员' allowClear>
                  {userData.map((v: User) => {
                    return (
                      <Option value={v.id} key={v.id}>
                        {v.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='recepitsDate'
                label='业务日期'
                rules={[
                  {
                    required: true,
                    message: '业务日期不能为空,请输入正确的值。',
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            {Text === '修改' ? (
              <Col span={12}>
                <Form.Item name='putNumber' label='调拨出库单号'>
                  <Input disabled></Input>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Button
                type='primary'
                htmlType='submit'
                style={{
                  float: 'right',
                }}
              >
                保存
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType='button'
                onClick={() => {
                  history.go(-1)
                }}
                style={{
                  float: 'left',
                }}
              >
                取消
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <SupplierModal
        supplierCategory={0}
        visible={isModalVisible}
        switchModal={() => {
          setIsModalVisible(false)
          setIsmodalVisible(true)
        }}
        onOk={() => {
          setIsModalVisible(false)
        }}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        onRecord={(record) => {
          form.setFieldsValue({
            supplierId: record.id,
            supplierName: record.companyName,
          })
        }}
      />
      <SupplierSaveModal
        visible={ismodalVisible}
        onOk={() => {
          setIsmodalVisible(false)
          dispatch(setCurrent(1))
          setIsModalVisible(true)
          dispatch(setSelectId(0))
        }}
        onCancel={() => {
          setIsmodalVisible(false)
          setIsModalVisible(true)
          dispatch(setSelectId(0))
        }}
      />
    </div>
  )
};
