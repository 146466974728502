import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import {
  getCancel as apiGetCancel,
  getCountList as apiGetCountList,
  getHomeList as apiGetHomeList,
  getInvalidPage,
  getMessageList as apiGetMessageList,
  getSelectMenu,
  getShoopListFn,
  getShoopListFnParams,
  getState as apiGetStates,
  IdParams,
  readMessage,
  saveSelectMenu,
  versionConsultVersionAsync,
  versionPageAsync,
} from '../../services/home'

const initialState = {
  homeDate: [],
  countData: {},
  messageData: [],
  stateData: [],
  cancelData: [],
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setDepartments: (state, action) => {
      state.homeDate = action.payload
    },
    setCountList: (state, action) => {
      state.countData = action.payload
    },
    setMessageList: (state, action) => {
      state.messageData = action.payload
    },
    setStates: (state, action) => {
      state.stateData = action.payload
    },
    setCancels: (state, action) => {
      state.cancelData = action.payload
    },
  },
})

const {
  setDepartments,
  setCountList,
  setMessageList,
  setStates,
  setCancels,
} = homeSlice.actions

export function getHomeList(id?: number): RootThunk {
  return api(apiGetHomeList(id), (data: any, dispatch) => {
    dispatch(setDepartments([data.unRegVO, data.inReg]))
  })
}
export function getCountList(id?: number): RootThunk {
  return api(apiGetCountList(id), (data: any, dispatch) => {
    const count = data
    dispatch(setCountList(count))
  })
}
export function getMessageList(id?: number): RootThunk {
  return api(apiGetMessageList(id), (data: any, dispatch) => {
    // dispatch(setMessageList(_.chain(data).take(4).value()));
    dispatch(setMessageList(data))
  })
}

export function readMessageAsync(id?: number): RootThunk {
  return api(readMessage(id), (data: any, dispatch) => {
    // dispatch(setMessageList(_.chain(data).take(4).value()));
    dispatch(getMessageList())
  })
}

export function getState(body: IdParams): RootThunk {
  return (dispatch, getState, args) => {
    const state = getState().home.messageData
    api(apiGetStates(body), (data: any, dispatch) => {
      dispatch(setStates(state))
      dispatch(getMessageList())
    })(dispatch, getState, args)
  }
}
export function getCancel(id: number): RootThunk {
  return (dispatch, getState, args) => {
    const cancel = getState().home.cancelData
    api(apiGetCancel(id), (data: any, dispatch) => {
      dispatch(setCancels(cancel))
      dispatch(getCountList())
      dispatch(getHomeList())
    })(dispatch, getState, args)
  }
}

export const getRegisterCancel = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('home/getSelectMenuAsync', async (params, api) => {
  return sendAsync(apiGetCancel(params), api)
})

// 获取默认菜单
export const getSelectMenuAsync = createAsyncThunk<
  void,
  void,
  RootThunkApi<void>
>('home/getSelectMenuAsync', async (_, api) => {
  return sendAsync(getSelectMenu(), api)
})

// 保存菜单
export const saveSelectMenuAsync = createAsyncThunk<
  void,
  string[],
  RootThunkApi<void>
>('home/getSelectMenuAsync', async (body, api) => {
  return sendAsync(saveSelectMenu(body), api)
})

// 获取商品管理列表
export const getShoopList = createAsyncThunk<
  void,
  getShoopListFnParams,
  RootThunkApi<void>
>('home/getShoopList', async (params, api) => {
  return sendAsync(getShoopListFn(params), api)
})

// 获取诊疗列表
export const getInvalidPageAsync = createAsyncThunk<
  void,
  getShoopListFnParams,
  RootThunkApi<void>
>('home/getShoopList', async (params, api) => {
  return sendAsync(getInvalidPage(params), api)
})

// 获取试用版本列表
export const getVersionPageAsync = createAsyncThunk<
  any,
  void,
  RootThunkApi<void>
>('home/getVersionPageAsync', async (params, api) => {
  return sendAsync(versionPageAsync(), api)
})

// 右上角的试用其他版本按钮
export const getVersionConsultVersionAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('home/getVersionConsultVersionAsync', async (params, api) => {
  return sendAsync(versionConsultVersionAsync(params), api)
})

export const selectHomedata = (state: RootState) => state.home.homeDate

export const selectCountdata = (state: RootState) => state.home.countData

export const selectMessagedata = (state: RootState) => state.home.messageData

export const selectStatedata = (state: RootState) => state.home.stateData

export const selectCanceldata = (state: RootState) => state.home.cancelData

export default homeSlice.reducer
