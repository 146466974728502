export interface Pagination<T> {
  items: T[];
  currentPage: number;
  totalPages: number;
  pageSize: number;
}

export const DefaultPageSize = 10;

export function emptyOf<T>(size: number): Pagination<T> {
  return {
    items: [],
    pageSize: size,
    totalPages: 0,
    currentPage: 1,
  };
}

export interface Pager {
  current: number;
  size: number;
  total: number;
}

export const FirstPage = {
  current: 1,
  size: DefaultPageSize,
  total: 0,
} as Pager;
