/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-15 14:11:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-19 09:25:06
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Table } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { PaymentStatus } from '../../../models/payment'
import styles from './JuhePayPage.module.css'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getJuhePage } from '../confirm/paymentConfirmSlice'
import { JuhePremium } from '../list/paymentSlice'
import { Columns } from './columns'
import { Query } from './Query'

interface JuhePayPageProps {
  selectedTab?: any
  status: number
  type?: number // his 口腔版
  patientId?: string // his 口腔版
}

const JuhePayPage = ({
  selectedTab,
  status,
  type,
  patientId,
}: JuhePayPageProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({
    current: 0,
    size: 10,
    payMethod: 2,
    state: status,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    params.current &&
      sessionStorage.setItem(location.pathname, JSON.stringify({ ...params }))
  }, [params])

  const getPage = () => {
    //聚合支付退费列表
    setPageLoading(true)
    dispatch(getJuhePage(params))
      .then(unwrapResult)
      .then((res) => {
        const data = {
          total: res.total,
          items: res.records,
        }
        setPage(data)
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (params.current !== 0) {
      getPage()
      // sessionStorage.setItem(location.pathname, JSON.stringify({ ...params }))
    }
  }, [params])

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  return (
    <Col
      className={styles.content}
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)',
        marginTop: '10px',
        overflow: 'hidden',
        padding: '10px 15px 0 15px',
      }}
    >
      <Query
        status={status}
        type={type || 0}
        onValueChange={(v) => {
          setParams({
            ...params,
            ...v,
            current: 1,
            param: patientId ? patientId : v.param,
          })
        }}
      />
      <EditableList
        style={{ flex: 1 }}
        // scroll={{ y: '39rem' }}
        loading={pageLoading}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns(params.size * (params.current - 1), (action, t) => {
          dispatch(
            JuhePremium({
              //聚合支付退费
              treatmentId: t.registration.treatmentId,
              paymentId:t.paymentId
            })
          )
            .then(unwrapResult)
            .then(() => {
              getPage()
            })
        })}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row className={styles.total}>
              <Table.Summary.Cell
                index={0}
                className={styles.total}
              ></Table.Summary.Cell>
              <Table.Summary.Cell
                index={1}
                align='center'
                className={styles.total}
              >
                合计
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                className={styles.total}
              ></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}></Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}></Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}></Table.Summary.Cell>
              <Table.Summary.Cell index={10}></Table.Summary.Cell>
              <Table.Summary.Cell index={11}></Table.Summary.Cell>
              <Table.Summary.Cell index={12}></Table.Summary.Cell>
              <Table.Summary.Cell index={13} align='right'>
                {page.items?.[0]?.totalInsurancePayAmount?.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={14} align='right'>
                {page.items?.[0]?.totalRealPayAmount?.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={15} align='right'>
                {page.items?.[0]?.totalShouldPayAmount?.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={16}
                className={styles.total}
              ></Table.Summary.Cell>
              <Table.Summary.Cell
                index={17}
                className={styles.total}
              ></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        onRow={() => ({
          onDoubleClick: () => {
            return
          },
        })}
      />
    </Col>
  )
}

export const JuhePayPageRef = forwardRef(JuhePayPage)
