/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-12 17:02:25
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-02 16:10:56
 */
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Modal,
  Row,
  Col,
  Space,
  Button,
  Form,
  Input,
  notification,
  Select,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { EditableList } from '../../../../compnents/list/EditableList'
import { RecipeCategory } from '../../../../models/recipe'
import { stringTrim } from '../../../../utils/StringUtils'
import { getFollowUpPeopleList } from '../../../followUp/followUpPageSlice'
import { CurePlanColumns } from '../columns'
import { RecipeEditor, RecipeEditorMode } from '../RecipeEditor'
import {
  selectAddCurePlanModalVisible,
  selectsEtEditPlanId,
  setAddCurePlanModalVisible,
  setCurePlanListVisible,
  setEditPlanId,
} from '../recipeEditorSlice'
import {
  addSurePlanAsync,
  curePlanListAsync,
  selectPatientInfo,
  templateDetailAsync,
  templateListAsync,
} from '../recipeListEditorSlice'
import styles from './RecipeItemModal.module.css'
import { selectPatientInfo as toothselectPatientInfo } from '../../../A-toothModule/patient/patientSlice'
import { RecipeItemEditor } from '../components/RecipeItemSelect'

const { Option } = Select

type action = 'edit'
interface AddCurePlanModalProps {
  onOk?: (v: any) => void
  insuranceCode?: string
  mode?: RecipeEditorMode
  recipeCategory?: RecipeCategory
  data?: any
  treatmentId?: string
  toothAction?: (action: action) => void //口腔专业版
}

function AddCurePlanModal({
  insuranceCode,
  mode,
  data,
  recipeCategory,
  onOk,
  treatmentId,
  toothAction,
}: AddCurePlanModalProps): ReactElement {
  const dispatch = useDispatch<RootDispatch>()

  const patientInfo = useSelector(selectPatientInfo)

  const toothPatientDetail = useSelector(toothselectPatientInfo) // 口腔版新增治疗计划患者信息

  const AddCurePlanModalVisible = useSelector(selectAddCurePlanModalVisible)

  const etEditPlanId = useSelector(selectsEtEditPlanId)

  const [form] = Form.useForm()

  const [selectedKeys, setSelectedKeys] = useState<string[]>([])

  const [planListData, setPlanListData] = useState<any>([])

  const [isTemplete, setisTemplete] = useState(0)

  const [templateVisible, setTemplateVisible] = useState(false)

  const [templateList, setTemplateList] = useState<any>([])

  const [doctorList, setDoctorList] = useState<any[]>([])

  const [oldData, setOldData] = useState<any>({})

  const updateData = (data: any, id: string) => {
    const newData = planListData.map((v: any) => {
      if (v.rowKey == id) {
        return data
      } else {
        return v
      }
    })
    setSelectedKeys([])
    setPlanListData(newData)
  }

  useEffect(() => {
    if (templateVisible) {
      dispatch(templateListAsync())
        .then(unwrapResult)
        .then((res) => {
          setTemplateList(res)
        })
    }
  }, [templateVisible])

  const close = () => {
    dispatch(setEditPlanId(''))
    dispatch(setAddCurePlanModalVisible(false))
    !toothAction && dispatch(setCurePlanListVisible(true))
    form.resetFields()
    setPlanListData([])
    setSelectedKeys([])
    setOldData({})
  }

  useEffect(() => {
    if (AddCurePlanModalVisible) {
      dispatch(
        getFollowUpPeopleList({
          current: 1,
          size: 1000,
          stationCategory: 1,
          state: 1,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setDoctorList(res.records)
        })
    }
  }, [AddCurePlanModalVisible])

  useEffect(() => {
    if (!etEditPlanId) return
    dispatch(curePlanListAsync({ tpsId: etEditPlanId }))
      .then(unwrapResult)
      .then((res: any) => {
        const flag = doctorList?.filter((v) => {
          return v?.id == res[0]?.performDoctorId
        })
        setOldData(res[0])
        form.setFieldsValue({
          name: res[0]?.tpsName,
          comment: res[0]?.comment,
          performDoctorId: flag?.length ? res[0]?.performDoctorId : '',
        })
        setPlanListData(
          [...res[0].treatmentTpsDetail].map((v: any, i: number) => {
            return {
              ...v,
              localItemId: v.localItemId > 0 ? v.localItemId : null,
              rowKey: i,
            }
          })
        )
      })
  }, [etEditPlanId, doctorList])

  return (
    <Modal
      title={
        toothAction ? '编辑计划' : `${patientInfo?.patientName} 新建治疗计划`
      }
      width={1200}
      visible={AddCurePlanModalVisible}
      onCancel={() => {
        close()
      }}
      footer={null}
      className={styles.AddCurePlanModal}
      destroyOnClose
    >
      <Row gutter={10}>
        <Col span={21}>
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={(values) => {
              if (!planListData.length) {
                notification.error({
                  message: '请添加诊疗材料项',
                })
                return
              }
              const data = {
                ...values,
                isTemplete,
                // treatmentId,
                patientName:
                  toothPatientDetail?.patientName ||
                  patientInfo?.patientName ||
                  oldData?.patientName,
                doctorId:
                  toothPatientDetail?.treatmentDoctorId ||
                  patientInfo?.treatmentDoctorId ||
                  oldData?.treatmentDoctorId,
                patientId:
                  toothPatientDetail?.patientId ||
                  patientInfo?.patientId ||
                  oldData?.patientId,
                treatmentTpsDetail: planListData,
                id: etEditPlanId || undefined,
              }
              dispatch(addSurePlanAsync(data))
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: isTemplete == 1 ? '保存成功' : '创建成功',
                  })
                  toothAction && toothAction('edit')
                  isTemplete == 0 && close()
                })
            }}
          >
            <Row>
              <Col span={11}>
                <Form.Item
                  label='计划名称'
                  name='name'
                  getValueFromEvent={stringTrim}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input maxLength={10} placeholder='请输入计划名称' />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label='医生'
                  name='performDoctorId'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder='请选择医生'
                    allowClear
                    showSearch
                    filterOption={(input, option: any) => {
                      return (option?.children ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }}
                  >
                    {doctorList.length != 0 &&
                      doctorList.map((v) => (
                        <Option value={v.id} key={v.id}>
                          {v.realname}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={22}>
                <Form.Item
                  label='计划备注'
                  name='comment'
                  labelCol={{ span: 2 }}
                  wrapperCol={{ span: 22 }}
                  getValueFromEvent={stringTrim}
                >
                  <Input maxLength={100} placeholder='请输入计划备注' />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        {!toothAction && (
          <Col span={3}>
            <Button
              type='primary'
              style={{ float: 'right' }}
              onClick={() => {
                setTemplateVisible(true)
              }}
            >
              查看模板
            </Button>
          </Col>
        )}
      </Row>
      {/* <Divider /> */}
      <div>
        <div style={{position:'relative', height: '45px'}}>
          <RecipeItemEditor
            insuranceCode={insuranceCode}
            data={data}
            category={recipeCategory}
            onChange={(m, ignoreStorage) => {
              const data = {
                accountUnit: m.accountUnit,
                billingCategory: m.billingCategory,
                drugCount: m.drugCount,
                localItemId: m.localItemId || m.id,
                id: m.localItemId || m.id,
                name: m.name,
                retailPrice: m.retailPrice,
                toothStruct: '',
              }
              const rowData = [...planListData, data].map(
                (v: any, i: number) => {
                  return {
                    ...v,
                    rowKey: i,
                  }
                }
              )
              setSelectedKeys([])
              setPlanListData(rowData)
              // add(m, ignoreStorage)
            }}
            includeAllTcm={mode === RecipeEditorMode.Retail}
            isCurePlanModa={true}
          />
        </div>
        <Row className={styles.bar} align='middle'>
          <Button
            danger
            type='primary'
            className={styles.button}
            disabled={selectedKeys.length === 0}
            onClick={() => {
              Modal.confirm({
                title: '确定删除选中的项目',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                  const data = planListData.filter(
                    (v: any) => !selectedKeys.includes(v.rowKey)
                  )
                  setSelectedKeys([])
                  setPlanListData(data)
                },
              })
            }}
          >
            删除选中行
          </Button>
        </Row>
        {/* 若dropdown弹框位置异常，则需改变ant-dropdown-placement-bottomLeft 的top */}
        <EditableList
          className={styles.AddCurePlanModalTable}
          dataSource={planListData}
          columns={CurePlanColumns((m: any, values: any, isSaveTooth: any) => {
            if (isSaveTooth) {
              // 保存
              const newdata = planListData.map((v: any) => ({
                ...v,
                ...values,
              }))
              setSelectedKeys([])
              setPlanListData(newdata)
            } else {
              // 同步
              const data = { ...m, ...values }
              // add(data, false, m.id)
              updateData(data, m.rowKey || '0')
            }
          })}
          pagination={false}
          size='small'
          bordered
          rowKey={(m) => m.rowKey}
          rowSelection={{
            selectedRowKeys: selectedKeys,
            onChange: (ks, r: any) => {
              setSelectedKeys(ks as string[])
            },
          }}
          sticky
        />
      </div>
      <Row justify='space-between' style={{ marginTop: 10 }}>
        {toothAction ? (
          <div></div>
        ) : (
          <Button
            type='primary'
            onClick={() => {
              setisTemplete(1)
              form.submit()
            }}
          >
            另存为模板
          </Button>
        )}
        <Space>
          <Button onClick={close}>取消</Button>
          <Button
            type='primary'
            onClick={() => {
              setisTemplete(0)
              form.submit()
            }}
          >
            创建
          </Button>
        </Space>
      </Row>
      <Modal
        title={'治疗计划模板'}
        width={400}
        visible={templateVisible}
        onCancel={() => {
          setTemplateVisible(false)
          setTemplateList([])
        }}
        footer={null}
        className={styles.templateModal}
      >
        <div className={styles.templateContainer}>
          {templateList?.length
            ? templateList.map((v: any) => {
                return (
                  <div
                    key={v.id}
                    className={styles.templateItem}
                    onClick={() => {
                      dispatch(templateDetailAsync(v.id))
                        .then(unwrapResult)
                        .then((res: any) => {
                          form.setFieldsValue({
                            name: res[0]?.tipsName,
                            comment: res[0]?.tipsComment,
                          })
                          setPlanListData(
                            [...res].map((v: any, i: number) => {
                              return {
                                ...v,
                                rowKey: i,
                              }
                            })
                          )
                          setTemplateList([])
                          setSelectedKeys([])
                          setTemplateVisible(false)
                        })
                    }}
                  >
                    {v.name}
                  </div>
                )
              })
            : ''}
        </div>
      </Modal>
    </Modal>
  )
}

export default AddCurePlanModal
