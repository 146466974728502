import { TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import { DateTimeFormatSimple } from '../../../models/datetime'
import { Dimen } from '../../../models/dimen'
import { getRegistrationStateName } from '../../../models/registration'
import { getGenderName } from '../../../models/user'
import { getAge } from '../../../utils/StringUtils'
import { useHistory, useLocation } from 'react-router-dom'

export const appealcolumn = (
    onProof: (id: string, operationId: string) => void,
    onDetails: () => void,
    startIndex: number
): TableColumnType<any>[] => {

    const location: any = useLocation()
    const history: any = useHistory()
    return [
        {
            title: '序号',
            key: 'no',
            align: 'center',
            width: Dimen.Num,
            render: (_1, _2, index) => `${index + 1}`,
        },

        {
            title: '消费者名称',
            dataIndex: 'name',
            width: Dimen.Name,
            align: 'center',
            render: function element(_, r) {
                return r?.isRegulator == '1' ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span>{r?.name}</span>
                        <span style={{
                            color: '#fff',
                            width: '18px',
                            height: '18px',
                            textAlign: 'center',
                            lineHeight: '20px',
                            backgroundColor: '#FF9F2C',
                            marginLeft: '4px'
                        }}>监</span>
                    </div>
                ) : r?.name
            },
        },
        {
            title: '手术项目',
            dataIndex: 'surgicalItems',
            width: Dimen.surgicalItems,
            align: 'center',
        },
        {
            title: '医生名称',
            dataIndex: 'doctor',
            width: Dimen.opdoctor,
            align: 'center',
        },
        {
            title: '手术时间',
            dataIndex: 'surgicalDate',
            width: Dimen.surgicalDate,
            align: 'center',
            render: (_, r) => {
                return moment(r.surgicalDate).format(DateTimeFormatSimple)
            },
        },
        {
            title: '申诉时间',
            dataIndex: 'createTime',
            width: Dimen.surgicalDate,
            align: 'center',
            render: (_, r) => {
                return moment(r.createTime).format(DateTimeFormatSimple)
            },
        },
        {
            title: '处理时间',
            dataIndex: 'updateTime',
            width: Dimen.surgicalDate,
            align: 'center',
            render: function element(_, r) {
                return r.inappealState != '10' ? (r?.disposeDays >= 3 ? (<span style={{ color: '#FF0909' }}>{r?.disposeDays}天</span>) : `${r?.disposeDays}天`) :
                    r?.disposeDays + `天(${moment(r.updateTime).format(DateTimeFormatSimple)})`
                // moment(r.updateTime).format(DateTimeFormatSimple)
            },
        },
        {
            title: '处置状态',
            dataIndex: 'inappealState ',
            width: Dimen.certifyingStatus,
            align: 'center',
            render: (_, r) => {
                return r.inappealState == '4' ? '机构举证中' : (
                    r.inappealState == '1' ? '申诉人举证中' : (
                        r.inappealState == '8' ? '案件立案中' : '申诉完成'
                    )
                )
            },
        },
        {
            title: '操作',
            align: 'center',
            render: function element(_, t) {
                return (
                    <div
                        style={{
                            width: '150px',
                            height: '30px',
                            margin: 'auto',
                        }}
                    >
                        {t?.inappealState != '10' ?
                            <a
                                style={{
                                    color: '#FFFFFF',
                                    background: '#027AFF',
                                    padding: '0 18px',
                                    borderRadius: '4px',
                                    float: 'left'
                                }}
                                type='primary'
                                onClick={() => onProof(t.id, t.operationId)}
                            >
                                举证
                            </a> : ''
                        }
                        <a
                            style={{
                                color: '#FFFFFF',
                                background: '#027AFF',
                                padding: '0 18px',
                                borderRadius: '4px',
                                float: 'right'
                            }}
                            type='primary'
                            onClick={() => {
                                history.push({
                                    pathname: '/appealDetail',
                                    state: {
                                        detailsId: t.id,
                                        processId: t.operationId
                                    },
                                })
                            }}
                        >
                            查看
                        </a>
                    </div>
                    // <div
                    //     style={{
                    //         width: '130px',
                    //         height: '30px',
                    //         margin: 'auto',
                    //         display: 'flex'
                    //     }}
                    // >
                    //     <a
                    //         style={{ color: '#FFFFFF', background: '#027AFF', padding: '0 18px', borderRadius: '4px' }}
                    //         type='primary'
                    //         onClick={() => onProof(t.id, t.operationId)}
                    //     >
                    //         
                    //     </a>
                    //     <a
                    //         style={{
                    //             color: '#FFFFFF', marginLeft: '20px', background: '#027AFF', padding: '0 18px'
                    //             , borderRadius: '4px'
                    //         }}
                    //         type='primary'
                    //         onClick={() => {
                    //             history.push({
                    //                 pathname: '/appealDetail',
                    //                 state: {
                    //                     detailsId: t.operationId
                    //                 },
                    //             })
                    //         }}
                    //     >
                    //         查看
                    //     </a>
                    // </div>
                )
            },
        },
    ]
}
