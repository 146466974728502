/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-13 15:26:00
 */
import React, { useEffect, useState } from "react";
import styles from "./library.module.css";
import { setCategory } from "../../putstorage/putstorageSlice";
import { useDispatch, useSelector } from "react-redux";
import { Category } from "../../../models/category";
import { selectThreeMenus, selectTwoMenus, traceRoute } from "../../../layouts/layoutSlice";

export const WarehouseBusinessLibrary = (props: { parentId: string }) => {
  const dispatch = useDispatch();

  const threeMenus = useSelector(selectThreeMenus)

  const getStatus = (name: string) => {
    const menus = threeMenus?.filter((v: any) => v.parentId == props?.parentId)
    return menus?.some((v:any) => v.name == name)
  }

  return (
    <div className={styles.content}>
      {getStatus('购入入库') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(setCategory(Category.GRRK));
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/putstorage",
              state: {
                category: Category.GRRK,
              },
            })
          );
        }}
      >
        购入入库
      </div>) : ''}
      {getStatus('购入退库') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/putstorage",
              state: {
                category: Category.GRTK,
              },
            })
          );
        }}
      >
        购入退库
      </div>): ''}
      {getStatus('库存调价') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/InventoryPrice",
            })
          );
        }}
      >
        库存调价
      </div>) : ''}
      {getStatus('调拨出库') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/putstorage",
              state: {
                category: Category.DBCK,
              },
            })
          );
        }}
      >
        调拨出库
      </div>) : ''}
      {getStatus('调拨入库') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/putstorage",
              state: {
                category: Category.DBRK,
              },
            })
          );
        }}
      >
        调拨入库
      </div>) : ''}
      {getStatus('借记出库') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/putstorage",
              state: {
                category: Category.JJCK,
              },
            })
          );
        }}
      >
        借记出库
      </div>) : ''}
      {getStatus('借记还库') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/putstorage",
              state: {
                category: Category.JJRK,
              },
            })
          );
        }}
      >
        借记还库
      </div>) : ''}
      {getStatus('领用出库') ? (<div
      className={styles.card}
      onClick={() => {
        dispatch(
          traceRoute({
            name: "库房工作站",
            path: "/putstorage",
            state: {
              category: Category.LYCK,
            },
          })
        );
      }}
    >
      领用出库
    </div>) : ''}
      {getStatus('库房发药') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/delivery",
            })
          );
        }}
      >
        库房发药
      </div>) : ''}
    </div>
  );
};
