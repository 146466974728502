import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {selectCredentials, selectInsuranceArray} from '../../../app/applicationSlice'
import {
  DateRangeChargeTypes,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { getInvoiceStatus } from '../../../models/payment'
import { RecipeStatus } from '../../../models/recipe'
import { InvoicePageParams } from '../../../services/payment'
import { selectDepartments } from '../list/paymentSlice'

export const Query = (props: {
  onAction:(v?:any)=>void
  status: number
  type: any
  onValueChange: (params: InvoicePageParams) => void
  export: (v?: any) => void
  ticketsLeftNum: (v?: any) => void
  notInvoicedQuery:()=>void
  YBZFUploadflag?: boolean
}): ReactElement => {
  const [form] = Form.useForm()

  const { Option } = Select

  // const departments = useSelector(selectDepartments)
  const credentials = useSelector(selectCredentials)

  const insuranceArray = useSelector(selectInsuranceArray)

  const [statusOptions, setStatusOptions] = useState([-1, 0, 1, 5, 8, 9, -9])

  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)
    form.setFieldsValue({
      actionTimeHead: head,
      actionTimeTail: tail,
    })
    form.submit()
  }, [])

  return (
    <Form form={form} onFinish={(values) => props.onValueChange(values)}>
      <Row style={{ marginTop: 10, marginBottom: 20 }} justify='space-between'>
        <Space wrap>
          {props.type && props.type != 1 ? (
            <Form.Item noStyle name='name'>
              <Input
                autoComplete='off'
                prefix={<SearchOutlined />}
                placeholder='患者姓名/手机号/病历号'
                style={{ width: '16rem' }}
                allowClear
                onChange={() => form.submit()}
              />
            </Form.Item>
          ) : (
            ''
          )}
          <Form.Item noStyle name='invoiceNum'>
            <Input
              autoComplete='off'
              placeholder='发票号码'
              style={{ width: '10rem' }}
              allowClear
              onChange={() => form.submit()}
            />
          </Form.Item>
          {/* <Form.Item noStyle name='treatmentDepartmentId'>
            <Select
              style={{ width: '12.5rem' }}
              placeholder='查看范围-科室列表'
              allowClear
              onChange={() => form.submit()}
            >
              {departments.map((d) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          {insuranceArray &&
            insuranceArray.length !== 0 &&
            !props.YBZFUploadflag && (
              <Form.Item name='insuranceCode' noStyle>
                <Select
                  placeholder='险种类型'
                  style={{ width: '8.25rem' }}
                  allowClear
                  // suffixIcon={<CaretDownFilled />}
                  onChange={form.submit}
                >
                  <Select.Option value={''}>全部</Select.Option>
                  <Select.Option value={'ZIFEI'}>自费</Select.Option>
                  {insuranceArray.map((v) => (
                    <Select.Option value={v.insuranceCode} key={v.id}>
                      {v.insuranceName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          <Form.Item name='invoiceType' noStyle>
            <Select
              placeholder='票据类型'
              allowClear
              // suffixIcon={<CaretDownFilled />}
              style={{ width: '8.25rem' }}
              onChange={(e) => {
                if (e == 0) {
                  setStatusOptions([1, 5])
                } else if (e == 1) {
                  if (credentials?.dzfpSupplierId == '4') {
                    setStatusOptions([-1, 0, 1, 8, 9, -9, 98, 99])
                  } else {
                    setStatusOptions([-1, 0, 1, 8, 9, -9])
                  }
                } else {
                  setStatusOptions([-1, 0, 1, 5, 8, 9, -9])
                }
                form.setFieldsValue({
                  einvoiceStatus: undefined,
                })
                form.submit()
              }}
            >
              <Option value={0}>纸质发票</Option>
              <Option value={1}>电子发票</Option>
            </Select>
          </Form.Item>
          <Form.Item name='einvoiceStatus' noStyle>
            <Select
              placeholder='票据状态'
              allowClear
              // suffixIcon={<CaretDownFilled />}
              style={{ width: '8.25rem' }}
              onChange={form.submit}
            >
              {statusOptions.map((v) => (
                <Option value={v} key={v}>
                  {getInvoiceStatus(v)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <DateSelect
            style={{ width: '9.5rem' }}
            labelPrefix=''
            namePrefix='actionTime'
            placeholder='时间范围-时间'
            allowClear={false}
            initialValue={DateRangeType.Today}
            options={DateRangeChargeTypes}
            onChange={() => form.submit()}
          />
          <Button type='primary' htmlType='submit'>
            查询
          </Button>
        </Space>
        <Space style={{ marginTop: props.type && props.type == 1 ? 0 : 0 }}>
          {credentials?.dzfpSupplierId == '4' && (
            <Button
              type='primary'
              onClick={() => {
                const args = { type: 'ticketAccountCheck' }
                props?.onAction(args)
              }}
            >
              票据日对账
            </Button>
          )}
          {(credentials?.dzfpSupplierId == '1' ||
            credentials?.dzfpSupplierId == '2') && (
            <Button
              type='primary'
              onClick={() => {
                props?.ticketsLeftNum()
              }}
            >
              余票查询
            </Button>
          )}
            <Button
              type='primary'
              onClick={() => {
                props?.notInvoicedQuery()
              }}
            >
              未开票查询
            </Button>
          {credentials?.dzfpSupplierId == '10' &&
            credentials?.tenantCityCode == '330200' && (
              <div>
                <Button
                  type='primary'
                  onClick={() => {
                    props?.export(1)
                  }}
                >
                  导出国税明细（本页）
                </Button>
                <Button
                  type='primary'
                  onClick={() => {
                    props?.export(2)
                  }}
                >
                  导出国税汇总（本页）
                </Button>
              </div>
            )}
        </Space>
      </Row>
    </Form>
  )
}
