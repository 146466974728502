import React, { ReactElement, useState, useEffect } from "react";
import { Modal, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { EditableList } from "../../../../compnents/list/EditableList";
import { pitestQueryParams } from "../../../../services/nopitest";
import {
  getPitestList,
  pitestDetail,
  selectCurrent,
  selectNopitestData,
  selectPageLoading,
  selectTotal,
  setCurrent,
  setPageLoading,
  pitestInvalidate,
} from "./nopitestSlice";
import { Columns } from "./columns";
import { NoPitestQuery } from "./Query";
import { PitestModal } from "./Modal";
import { RegisterModal } from "./registerModal";
import styles from "./nopitest.module.css";
import { RootDispatch } from "../../../../app/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { ExclamationCircleFilled } from "@ant-design/icons";
export const NoPitest = (
  props:{
    value ?:any,
  }
  
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const [SelectId, setSelectId] = useState(0);

  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectNopitestData);

  const [size, setSize] = useState(10);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isModalRegisterVisible, setIsModalRegisterVisible] = useState(false);

  const [queries, setQueries] = useState<pitestQueryParams>();

  const pageLoadingRX = useSelector(selectPageLoading);

  const [invalidateVisible, setInvalidateVisible] = useState(false)
  
  const [modalBtnLoading, setModalBtnLoading] = useState(false)

  const [invalidateId, setInvalidateId] = useState('')

  useEffect(() => {
    if (queries?.timeHead) {
      dispatch(setPageLoading(true))
      dispatch(getPitestList({ ...queries, current: Current, size }))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }

  }, [queries, size, Current]);

  useEffect(() => {
    if (props.value == 0) {
      dispatch(setPageLoading(true))
      dispatch(getPitestList({ ...queries, current: Current, size }));
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }

  }, [props.value])
  return (
    <>
      <div className={styles.content}>
        <NoPitestQuery
          onValueChange={(v) => {
            setQueries({ ...queries, ...v });
            dispatch(setCurrent(1));
          }}
        />
        <EditableList
          rowKey={(_, i) => `${i}}`}
          loading={pageLoadingRX}
          className={styles.nopitest}
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number);
            dispatch(setCurrent(current));
          }}
          columns={Columns((id, text) => {
            if (text == "开始皮试") {
              setIsModalVisible(true);
              dispatch(pitestDetail(id));
            } else if (text == "作废") {
              setInvalidateVisible(true);
              setInvalidateId(id);
            } else {
              setIsModalRegisterVisible(true);
              dispatch(pitestDetail(id));
            }
          }, size * (Current - 1))}
        />
      </div>
      <PitestModal
        isModalVisible={isModalVisible}
        queries={queries}
        onOk={() => {
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
          dispatch(getPitestList({ ...queries, current: Current, size }));
        }}
      />
      <RegisterModal
        isModalRegisterVisible={isModalRegisterVisible}
        queries={queries}
        onOk={() => {
          setIsModalRegisterVisible(false);
          // setSelectId(0)
        }}
        onCancel={() => {
          setIsModalRegisterVisible(false);
          dispatch(getPitestList({ ...queries, current: Current, size }));
          // setSelectId(0)
        }}
      />
      <Modal
        title={"确认操作"}
        width={400}
        visible={invalidateVisible}
        confirmLoading={modalBtnLoading}
        onOk={() => {
          setModalBtnLoading(true)
          dispatch(pitestInvalidate(invalidateId))
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: "操作成功",
                duration: 3,
              })
              setModalBtnLoading(false)
              setInvalidateVisible(false)
              dispatch(setPageLoading(true))
              dispatch(getPitestList({ ...queries, current: Current, size }))
              setTimeout(() => {
                dispatch(setPageLoading(false))
              }, 3000)
            })
        }}
        onCancel={() => setInvalidateVisible(false)}
      >
        <div style={{ display: "flex", lineHeight: "22px" }}>
          <div style={{ color: '#ffae55', fontSize: 24, marginRight: 10 }}>
            <ExclamationCircleFilled />
          </div>
          <p>确认作废？</p>
        </div>
      </Modal>
    </>
  );
};
