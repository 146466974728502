import { Bool } from './common'
import { Gender } from './user'

export enum RegistrationCategory {
  SelfPay = 0,
  Insurance = 1,
}

// CZ(0, "初诊"), FZ(1, "复诊") 就诊类型
export enum VisitType {
  First = 0,
  Subsequence = 1,
}

export function getVisitTypeName(visit: VisitType): string {
  switch (visit) {
    case VisitType.First:
      return '初诊'
    case VisitType.Subsequence:
      return '复诊'
  }
  return ''
}

// 患者来源 (0, "未知"), QYTJ(1, "亲友推荐"), MTBD(2, "媒体报道"), HDTG(3, "活动推广");
export enum source {
  QT = 0,
  QYTJ = 1,
  MTBD = 2,
  HDTG = 3,
  WLZX = 4,
  JZFJ = 5,
}

export const SourceTypeCategories = [
  source.QT,
  source.QYTJ,
  source.MTBD,
  source.HDTG,
  source.WLZX,
  source.JZFJ,
]
export function getSourceTypeName(visit: source): string {
  switch (visit) {
    case 0:
      return '其他'
    case 1:
      return '亲友推荐'
    case 2:
      return '媒体报道'
    case 3:
      return '活动推广'
    case 4:
      return '网络咨询'
    case 5:
      return '家住附近'
  }
  return ''
}

export enum RegistrationState {
  NotTreated = 0,
  Treated = 1,
  Treating = 2,
  Withdraw = 3,
  Canceled = 9,
}

export const RegistrationStates = [
  RegistrationState.NotTreated,
  RegistrationState.Treated,
  RegistrationState.Treating,
]

const RegistrationStateNames = ['未就诊', '已就诊', '就诊中', '已退号']

export function getRegistrationStateName(state: RegistrationState): string {
  if (RegistrationState.Canceled === state) {
    return '已取消'
  }
  if (state < 0 || state >= RegistrationStateNames.length) {
    return ''
  }
  return RegistrationStateNames[state]
}

export function fromJson(json: any): Registration {
  return {
    ...json,
    id: json.id,
    outpatientNo: json.outpatientNo,
    patientId: json.patientId,
    patientName: json.patientName,
    patientPhone: json.patientPhone,
    patientSex: json.patientSex,
    patientAge: json.patientAge > 0 ? json.patientAge : 0,
    patientMonth: json.patientMonth > 0 ? json.patientMonth : 0,
    receiveInAdvance: json.receiveInAdvance,
    receiveTag: json.receiveTag,
    registrationCategory: json.registrationCategory ? 1 : 0,
    registrationDepartmentId: json.registrationDepartmentId?.toString() || '',
    registrationDepartmentName: json.registrationDepartmentName,
    registrationDoctorId: json.registrationDoctorId?.toString() || '',
    registrationDoctorName: json.registrationDoctorName,
    registrationFeeServiceId: json.registrationFeeServiceId?.toString() || '',
    registrationPayState: json.registrationPayState,
    registrationState: json.registrationState,
    registrationTime: json.registrationTime,
    treatmentId: json.treatmentId,
    settlementTypesOfInsurance: json.settlementTypesOfInsurance,
    treatmentDepartmentId: json.treatmentDepartmentId?.toString() || '',
    treatmentDepartmentName: json.treatmentDepartmentName,
    treatmentDoctorId: json.treatmentDoctorId?.toString() || '',
    treatmentDoctorName: json.treatmentDoctorName,
    precheckState: json.precheckState,
    precheckId: json.precheckId,
    ybJiuzhenid: json.ybJiuzhenid,
    appointmentId: json.appointmentId,
    labels: json.labels,
    address: json.address,
    idCardNumber: json.idCardNumber,
    birthday: json.birthday,
    source: json.source,
    area:json.area,
  }
}

export interface Registration {
  appointmentId?: number
  cardType: string
  id: string
  outpatientNo: string
  paymentId?:any
  patientId: string
  patientName: string
  patientPhone: string
  patientSex: Gender
  patientAge: number
  patientMonth: number
  receiveInAdvance: Bool
  receiveTag: VisitType
  registrationCategory: RegistrationCategory
  registrationDepartmentId: string
  registrationDepartmentName: string
  registrationDoctorId: string
  registrationDoctorName: string
  registrationFeeServiceId: string
  registrationPayState: Bool
  registrationState: RegistrationState
  registrationTime: string
  settlementTypesOfInsurance: string
  treatmentDepartmentId: string
  treatmentDepartmentName: string
  treatmentDoctorId: string
  treatmentId: string
  treatmentDoctorName: string
  precheckState: any
  precheckId?: number
  insuranceCode?: string
  ybJiuzhenid?: string
  address?: string
  birthday?: string
  idCardNumber?: string
  arrearageAmountSum?: number
  labels?: any
  source?: number
  remark?: any
  area?: any
  wjUploadFlag?: any
  treatmentTime: string
  cwcaSignature?: string
  cwcaSigntime?: string
}

export const patientFromJson = (fullInfo?: any) => {
  return {
    birthday: fullInfo?.birthday,
    brdw: fullInfo?.brdw,
    cbxz: fullInfo?.cbxz,
    certType: fullInfo?.certType,
    createUser: '',
    dnzhye: fullInfo?.dnzhye,
    expContent: fullInfo?.expContent,
    gwybz: fullInfo?.gwybz,
    insuranceRegion: fullInfo?.insuranceRegion,
    insurance_start_date: fullInfo?.insurance_start_date,
    insurance_stop_date: fullInfo?.insurance_stop_date,
    lnzhye: fullInfo?.lnzhye,
    medicalTreatmentType: fullInfo?.medicalTreatmentType,
    memberType: fullInfo?.memberType,
    mztclj: fullInfo?.mztclj,
    name: fullInfo?.name,
    naty: fullInfo?.naty,
    sex: fullInfo?.sex,
    tbno: fullInfo?.tbno,
    treatmentStatus: fullInfo?.treatmentStatus,
    updateUser: fullInfo?.updateUser,
    ybPersonalNo: fullInfo?.ybPersonalNo,
    zhye: fullInfo?.zhye,
    // birthday: registerInfo?.birthday,
    // fullCardInfo: fullCardInfo,
    // insuranceCode: insuranceCode,
  }
}

export interface RegistrationCount {
  treated: number
  notTreated: number
  treating: number
  total: number
}
