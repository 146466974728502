import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { NavBar } from '../../compnents/nav/NavBar'
import { DateTimeFormat } from '../../models/datetime'
import { getGenderName } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import { useQuery } from '../../utils/HttpUtils'
import { getAge } from '../../utils/StringUtils'
import { ExcuteDetailColumn } from './excuteDetailColumn'
import { LookModal } from './lookModal'
import { getExcuteTableList } from './toBeCompletedTreatment/toBeCompletedTreatmentSlice'

export const ExcuteDetail = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const location: any = useLocation()

  const query = useQuery()

  const [current, setCurrent] = useState(1)

  const [size, setSize] = useState(10)

  const [total, setTotal] = useState<any>()

  const [id, setId] = useState<any>()

  const [detail, setDetail] = useState<any>()

  const [data, setData] = useState<any>([])

  const [coherentVideo, setCoherentVideo] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const tag = location?.state?.tag

  useEffect(() => {
    if (query.get('id'))
      dispatch(getExcuteTableList({ id: query.get('id') }))
        .then(unwrapResult)
        .then((res: any) => {
          setDetail(res)
          setData(res?.medicalStationItemList)
        })
  }, [])

  return (
    <Col
      style={{
        height: "calc(100% - 20px)",
        overflow: "hidden",
        position: "relative",
        marginTop: 10,
        marginBottom: 10,
        padding: "0px 20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavBar
        style={{ marginBottom: "10px" }}
        where={[`${tag == 0 ? "待完成诊疗" : "已完成诊疗"}`, "执行记录"]}
        backtrace={{
          name: tag == 0 ? "待完成诊疗" : "已完成诊疗",
          path: "/medicalSation",
          routeName: "医技站",
          state: {
            selectKey: tag == 0 ? "0" : tag == 1 ? "1" : "",
          },
        }}
      />
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: "10px 15px 0 15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row
          style={{
            lineHeight: "30px",
            marginBottom: "20px",
            paddingLeft: "20px",
            fontSize: "16px",
          }}
        >
          <Col span={6}>患者姓名：{detail?.patientName}</Col>
          <Col span={6}>性别：{getGenderName(detail?.patientSex)}</Col>
          <Col span={6}>
            年龄：{detail?.patientAge}
            {/* {getAge(detail?.patientYear, detail?.patientMonth)} */}
          </Col>
          <Col span={6}>项目：{detail?.treatmentServiceName}</Col>
          <Col span={6}>就诊医生：{detail?.doctorName}</Col>
          <Col span={6}>
            就诊时间：{" "}
            {detail?.clinicTime
              ? moment(detail?.clinicTime).format(DateTimeFormat)
              : "-"}
          </Col>
        </Row>
        <EditableList
          style={{ marginBottom: "20px" }}
          bordered
          pagination={false}
          page={{
            items: data.sort(
              (a: any, b: any) =>
                moment(b.executeTime).unix() - moment(a.executeTime).unix()
            ),
            current,
            size,
            total: total,
          }}
          columns={ExcuteDetailColumn((text, id, coherentVideo) => {
            if (text == "look") {
              setId(id);
              setCoherentVideo(coherentVideo);
              setIsModalVisible(true);
            }
          }, size * (current - 1))}
          onChangePage={(current: any, size: any) => {
            setSize(size as number);
            setCurrent(current as number);
          }}
          onRow={(r: any) => ({
            onDoubleClick: () => {
              setId(r.id);
            },
          })}
        />
      </Col>
      <LookModal
        isModalVisible={isModalVisible}
        id={id}
        fileList={coherentVideo}
        onCancel={() => {
          setIsModalVisible(false);
          setId("");
        }}
        onOk={() => {
          setIsModalVisible(false);
          setId("");
        }}
      />
    </Col>
  );
}
