/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-04 10:50:48
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../../app/store'
import { sendAsync } from '../../../../app/applicationSlice'
import * as CM from '../../../../services/clinicManagement'
import { ClinicSubmitParams } from '../../../../services/clinicManagement'

// 右侧详情
export const getClinicDetail = createAsyncThunk<any, void, RootThunkApi<void>>(
  'clinic/getClinicDetail',
  async (params, api) => {
    return sendAsync(CM.clinicDetail(), api)
  }
)

// 保存接口

export const getClinicSubmit = createAsyncThunk<
  void,
  ClinicSubmitParams,
  RootThunkApi<void>
>('clinic/getClinicSubmit', async (params, api) => {
  return sendAsync(CM.clinicSubmit(params), api)
})
