/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-10 11:54:15
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-14 11:13:11
 */
import {
  DownOutlined,
  EditFilled,
  ExclamationCircleFilled,
  LeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
  RightOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, DatePicker, Input, Modal, notification, Tabs } from 'antd'
import moment from 'moment'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootDispatch } from '../../../app/store'
import {
  DelectOutlined,
  EditsOutlined,
  NoDataIcon,
} from '../../../compnents/icons/Icons'
import { dateFormat } from '../../../models/appointment'
import {
  patientLeftList,
  PatientLeftListItem,
  CZFZLeftDefaultList,
  allGroupList,
  PatientListItem,
} from '../../../models/toothPatient'
import { getPatientLately } from '../../../services/toothPatient'
import { PatientGroupModal } from './components/group'
import { ListItem } from './components/listItem'
import styles from './PatientLayout.module.css'
import { PatientList, PatientListsRef } from './patientList/patientList'
import { PatientPanel } from './patientPanel/patientPanel'
import {
  getPatientGroupAsync,
  getPatientLatelyAsync,
  getPatientTodayAsync,
  removePatientGroupAsync,
  selectActiveObj,
  selectGroupList,
  selectPatientManagementStatus,
  setActiveObj,
  setAttendingDoctorList,
  setCounselorList,
  setGroupList,
  updatePatientTimeAsync,
} from './patientSlice'
import { getCounselor, getMessageCount } from '../toothHome/toothHomeSlice'
import { AddPatientModal } from '../toothHome/modal/addPatientModal'
import { SubscribeModal } from '../toothHome/modal/subscribeModal'
import { SendMessageModal } from '../toothHome/modal/sendMessageModal'
import { RegisteredModal } from '../toothHome/modal/registeredModal'
import { QrCodeModal } from '../toothHome/modal/qrCodeModal'
import { detailPutstorage } from '../../putstorage/putstorageSlice'

export const PatientLayout = (): ReactElement => {
  const location: any = useLocation()

  const allRef = useRef<PatientListsRef>(null)

  const dispatch = useDispatch<RootDispatch>()

  const allGroupLists = useSelector(selectGroupList)

  const activeObj = useSelector(selectActiveObj)

  const [expansion, setExpansion] = useState(true)

  const [tabsActiveKey, setTabsActiveKey] = useState('')

  const [selectId, setSelectId] = useState('')

  const [cardType, setCardType] = useState<any>() // 读卡类型

  const [subTabsActiveKey, setSubTabsActiveKey] = useState('1')

  const [day, setDay] = useState(moment().format('YYYY-MM-DD'))

  const [todayValue, setTodayValue] = useState('')

  const [todayList, setTodayList] = useState<any[]>([])

  const [outpatientLeftList, seOutpatientLeftList] = useState<
    PatientLeftListItem[]
  >(patientLeftList)

  const [CZFZLeftList, setCZFZLeftList] = useState<PatientLeftListItem[]>(
    CZFZLeftDefaultList
  )

  const [latelyList, setLatelyList] = useState<any>([])

  const [registration, setRegistration] = useState<any>()

  const [PatientGroupModalType, setPatientGroupModalType] = useState(0)

  const [groupVisible, setGroupVisible] = useState(false)

  const [groupId, setGroupId] = useState(0)

  const [refreshFlag, setRefreshFlag] = useState(0)

  const [refreshDetailFlag, setRefreshDetailFlag] = useState(false)

  const [editGroupId, setEditGroupId] = useState('')

  const [isASync, setisASync] = useState(false)

  const [listItemActive, setListItemActive] = useState('')

  const [rightTabsActiveKey, setRightTabsActiveKey] = useState('')

  const [currentRegistrationId, setCurrentRegistrationId] = useState('')

  const [currentAppointmentId, setcurrentAppointmentId] = useState('')

  const [sendMessageModal, setSendMessageModal] = useState(false) // 发送短信弹窗

  const [subscribeModal, setSubscribeModal] = useState(false) // 工作站-预约弹窗

  const [registeredModal, setRegisteredModal] = useState(false) //工作站-转诊弹窗

  const [sendMsgObj, setSendMsgObj] = useState<any>(null)

  const [changeFlag, setChangeFlag] = useState(false)

  const [addPatientModal, setAddPatientModal] = useState(false) // 新增患者弹窗

  const [modalTile, setModalTile] = useState(0) //  弹窗标题

  const [notAppointmentIdFlag, setNotAppointmentIdFlag] = useState(false)

  const [modalPatientID, setModalPatientID] = useState('') //弹窗 患者ID

  const [qrCodeModal, setQrCodeModal] = useState(false)

  const patientManagementStatus = useSelector(selectPatientManagementStatus)

  useEffect(() => {
    setRefreshDetailFlag(addPatientModal)
  }, [addPatientModal])

  useEffect(() => {
    if (location?.state?.selectId) {
      setSelectId(location?.state?.selectId)
    }
  }, [location])

  useEffect(() => {
    setNotAppointmentIdFlag(false)
  }, [])

  useEffect(() => {
    if (Object.keys(activeObj).length) {
      setTabsActiveKey(activeObj.tabsActiveKey || '3')
      setRightTabsActiveKey(activeObj.rightTabsActiveKey || '1')
      activeObj.patientId && setSelectId(activeObj.patientId)
      activeObj.registrationId &&
        setCurrentRegistrationId(activeObj.registrationId)
      activeObj.appointmentId &&
        setcurrentAppointmentId(activeObj.appointmentId)
      getLately()
      // dispatch(setActiveObj({}))
    } else {
      setTabsActiveKey('3')
    }
  }, [activeObj])

  // 张强说的 (11.27 18:45)临时去掉
  // useEffect(() => {
  //   // 收费返回
  //   if (sessionStorage.getItem('routerStatus') == 'back') {
  //     dispatch(
  //       setActiveObj({
  //         tabsActiveKey: '3',
  //         rightTabsActiveKey: '4',
  //       })
  //     )
  //   }
  // }, [sessionStorage.getItem('routerStatus')])

  useEffect(() => {
    // 收费返回清除存储
    if (
      sessionStorage.getItem('routerStatus') == 'back' &&
      tabsActiveKey == '3'
    ) {
      sessionStorage.removeItem('routerStatus')
    }
  }, [tabsActiveKey])

  useEffect(() => {
    return () => {
      dispatch(setActiveObj({}))
    }
  }, [])

  useEffect(() => {
    if (latelyList.length) {
      const id = latelyList[0]?.patient?.id
      const RegistrationId = latelyList[0]?.registration?.id
      setSelectId(id)
      setCurrentRegistrationId(RegistrationId)
      setRegistration(latelyList?.[0]?.registration)
    }
  }, [latelyList])

  useEffect(() => {
    if (!changeFlag) return
    const first = todayList.find((v: any) => v.patienLatelyVOS.length != 0)
    const id = first ? first?.patienLatelyVOS[0]?.patient?.id : ''
    const rid = first ? first?.patienLatelyVOS[0]?.registration?.id : ''
    const appointmentId = first ? first?.patienLatelyVOS[0]?.appointmentId : ''
    setSelectId(id)
    setCurrentRegistrationId(rid)
    setcurrentAppointmentId(appointmentId)
    setListItemActive(first?.type + '' + 0 + id)
  }, [todayList, changeFlag])

  useEffect(() => {
    if (tabsActiveKey == '2') {
      setRightTabsActiveKey('')
      setSelectId('')
      getGroup()
    } else if (tabsActiveKey == '3') {
      setListItemActive('')
      // getGroup()
      getLately()
    }
  }, [tabsActiveKey])

  const getTodayData = () => {
    const data = {
      startTime: day + ' 00:00:00',
      endTime: day + ' 23:59:59',
      key: todayValue || undefined,
      type: subTabsActiveKey,
    }
    dispatch(getPatientTodayAsync({ ...data }))
      .then(unwrapResult)
      .then((res: any) => {
        setTodayList(res)
        if (subTabsActiveKey == '1') {
          seOutpatientLeftList(
            outpatientLeftList.map((v) => {
              const count = res.find((el: any) => el.type == v.value)?.count
              return {
                ...v,
                count: count || 0,
                list:
                  res.find((el: any) => el.type == v.value)?.patienLatelyVOS ||
                  [],
                open: !!count,
              }
            })
          )
        } else if (subTabsActiveKey == '2') {
          setCZFZLeftList(
            CZFZLeftList.map((v) => {
              const count = res.find((el: any) => el.type == v.value)?.count
              return {
                ...v,
                count: count || 0,
                list:
                  res.find((el: any) => el.type == v.value)?.patienLatelyVOS ||
                  [],
                open: !!count,
              }
            })
          )
        }
      })
  }

  const getGroup = () => {
    dispatch(getPatientGroupAsync())
      .then(unwrapResult)
      .then((res: any) => {
        setGroupId(res.records[0]?.id)
        dispatch(setGroupList(res.records))
        setGroupVisible(false)
      })
  }

  const getLately = () => {
    const data = {
      startTime: moment().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment().format('YYYY-MM-DD 23:59:59'),
    }
    dispatch(getPatientLatelyAsync(data))
      .then(unwrapResult)
      .then((res: any) => {
        setLatelyList(res.records)
      })
  }

  useEffect(() => {
    if (tabsActiveKey == '1') {
      getTodayData()
    }
  }, [day, todayValue, tabsActiveKey, subTabsActiveKey, activeObj])

  useEffect(() => {
    if (!patientManagementStatus) return
    getGroup()
    getLately()
  }, [patientManagementStatus])

  useEffect(() => {
    dispatch(getMessageCount())
  }, [])

  const modalAction = (
    action: string,
    id: string,
    el: any,
    type: number,
    v?: any,
    index?: number
  ) => {
    if (!id) return
    setcurrentAppointmentId(el?.appointmentId)
    setCurrentRegistrationId(el?.registration?.id)
    selectId != id && setSelectId(id)

    if (action == 'updateTime') {
      selectId != id &&
        dispatch(updatePatientTimeAsync(id))
          .then(unwrapResult)
          .then(() => {
            type == 1 && getLately()
          })
    } else if (action == 'group') {
      setisASync(true)
      setPatientGroupModalType(5)
      setGroupVisible(true)
    } else if (action == 'message') {
      const res = {
        name: el.patient.name,
        sex: el.patient.sex,
        ageYear: el.patient.ageYear,
        phone: el.patient.phone,
        id: el.patient.id,
      }
      setSendMessageModal(true)
      setSendMsgObj(res)
    } else if (action == 'appointment') {
      setSubscribeModal(true)
    } else if (action == 'transfer') {
      setModalTile(1)
      setRegisteredModal(true)
    } else if (action == 'registration') {
      setModalTile(0)
      setRegisteredModal(true)
      setCardType('')
    }
    type == 0 && setListItemActive(v.value + '' + index + el?.patient?.id)
  }

  return (
    <div className={styles.PatientLayout}>
      <div
        className={styles.PatientLeftAction}
        style={{
          width: expansion ? 260 : 50,
        }}
      >
        <div className={styles.expansionAction}>
          {expansion ? (
            <MenuFoldOutlined
              onClick={() => {
                setExpansion(false)
              }}
            />
          ) : (
            <MenuUnfoldOutlined
              onClick={() => {
                setExpansion(true)
              }}
            />
          )}
        </div>
        {expansion && (
          <>
            <Tabs
              className={styles.tabsContainer}
              tabBarGutter={55}
              activeKey={tabsActiveKey}
              onChange={(key) => {
                setChangeFlag(true)
                setRightTabsActiveKey('')
                setTabsActiveKey(key)
                setCurrentRegistrationId('')
              }}
            >
              <Tabs.TabPane tab='今日' key='1' style={{ padding: 0 }}>
                <div style={{ padding: '0 10px' }}>
                  <div className={styles.todayHeader}>
                    <div className={styles.todayTime}>
                      <Button
                        icon={<LeftOutlined />}
                        type='text'
                        onClick={() => {
                          setDay(
                            moment(day).subtract(1, 'd').format(dateFormat)
                          )
                        }}
                      />
                      {/* <div>{day}</div> */}
                      <DatePicker
                        value={moment(day, dateFormat)}
                        format={dateFormat}
                        allowClear={false}
                        bordered={false}
                        onChange={(date, d) => {
                          setDay(d)
                        }}
                      />
                      <Button
                        icon={<RightOutlined />}
                        type='text'
                        onClick={() => {
                          setDay(moment(day).add(1, 'd').format(dateFormat))
                        }}
                      />
                    </div>
                    <Button
                      type='primary'
                      size='small'
                      style={{
                        height: 32,
                      }}
                      onClick={() => {
                        setDay(moment().format('YYYY-MM-DD'))
                      }}
                    >
                      回到今日
                    </Button>
                  </div>
                  <Input
                    placeholder='患者姓名/助记码/手机号/病历号'
                    allowClear
                    value={todayValue}
                    onChange={(e) => {
                      const query = e.target.value.trim()
                      setTodayValue(query)
                    }}
                  />
                </div>
                <Tabs
                  activeKey={subTabsActiveKey}
                  className={styles.todayTabs}
                  centered
                  tabBarGutter={55}
                  onChange={(key) => {
                    setSubTabsActiveKey(key)
                  }}
                >
                  <Tabs.TabPane tab='就诊列表' key='1'>
                    {outpatientLeftList.map((v) => (
                      <div className={styles.listItem} key={v.value}>
                        <div
                          className={styles.listItemHeader}
                          style={{
                            marginBottom: v.open ? 5 : 0,
                            paddingBottom: v.open ? 5 : 0,
                            borderBottom: v.open ? '1px solid #f1f1f1' : 'none',
                          }}
                          onClick={() => {
                            const newList = outpatientLeftList.map((el) =>
                              el.value == v.value
                                ? { ...el, open: !el.open }
                                : el
                            )
                            seOutpatientLeftList(newList)
                          }}
                        >
                          <div>{v.name}</div>
                          <div>
                            {v.count}
                            <span>
                              {v.open ? <DownOutlined /> : <UpOutlined />}
                            </span>
                          </div>
                        </div>
                        {v.open &&
                          (v.list?.length !== 0 ? (
                            v.list.map((el: any, index: number) => (
                              <ListItem
                                listItemActive={listItemActive}
                                selectId={selectId}
                                data={el}
                                type={v.value}
                                key={index}
                                index={v.value + '' + index + el?.patient?.id}
                                onClick={(action, id) => {
                                  modalAction(action, id, el, 0, v, index)
                                }}
                              />
                            ))
                          ) : (
                            <div className={styles.noData}>暂无数据</div>
                          ))}
                      </div>
                    ))}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab='初诊/复诊' key='2'>
                    {CZFZLeftList.map((v) => (
                      <div className={styles.listItem} key={v.value}>
                        <div
                          className={styles.listItemHeader}
                          style={{
                            marginBottom: v.open ? 5 : 0,
                            paddingBottom: v.open ? 5 : 0,
                            borderBottom: v.open ? '1px solid #f1f1f1' : 'none',
                          }}
                          onClick={() => {
                            const newList = CZFZLeftList.map((el) =>
                              el.value == v.value
                                ? { ...el, open: !el.open }
                                : el
                            )
                            setCZFZLeftList(newList)
                          }}
                        >
                          <div>{v.name}</div>
                          <div>
                            {v.count}{' '}
                            <span>
                              {v.open ? <DownOutlined /> : <UpOutlined />}
                            </span>
                          </div>
                        </div>
                        {v.open &&
                          (v.list?.length !== 0 ? (
                            v.list.map((el: any, index: number) => (
                              <ListItem
                                listItemActive={listItemActive}
                                selectId={selectId}
                                data={el}
                                type={v.value}
                                index={v.value + '' + index + el?.patient?.id}
                                key={index}
                                onClick={(action, id) => {
                                  modalAction(action, id, el, 0, v, index)
                                }}
                              />
                            ))
                          ) : (
                            <div className={styles.noData}>暂无数据</div>
                          ))}
                      </div>
                    ))}
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab='全部'
                key='2'
                style={{
                  overflow: 'auto',
                }}
              >
                <div>
                  <div className={styles.allAdd}>
                    <Button
                      type='link'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setPatientGroupModalType(1)
                        setGroupVisible(true)
                      }}
                      style={{
                        paddingRight: 0,
                      }}
                    >
                      新增分组
                    </Button>
                  </div>
                  {allGroupLists.map((v) => (
                    <div
                      key={v.id}
                      className={styles.allItem}
                      style={{
                        backgroundColor: groupId == v.id ? '#F0F6FF' : '',
                        fontWeight: 'bold',
                      }}
                      onClick={() => {
                        setGroupId(v.id)
                        //主要用于groupid没有变化时的监听效果↓
                        setRefreshFlag(-refreshFlag)
                      }}
                    >
                      <div
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        {v.groupName}&ensp;({v.sum})
                      </div>
                      {v.status == 1 && (
                        <div
                          className={styles.allEdit}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            onClick={() => {
                              setEditGroupId(v.id)
                              setPatientGroupModalType(2)
                              setGroupVisible(true)
                            }}
                          >
                            <EditsOutlined
                              style={{
                                fill: '#027AFF',
                                width: '16px',
                                height: '16px',
                                marginRight: 5,
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            />
                          </div>
                          <div
                            onClick={() => {
                              Modal.confirm({
                                centered: true,
                                title: '确认操作',
                                content: (
                                  <div>
                                    <ExclamationCircleFilled
                                      style={{ color: '#FFAE55', fontSize: 18 }}
                                    />
                                    &nbsp;是否确认删除该分组？
                                  </div>
                                ),
                                okText: '确认',
                                cancelText: '取消',
                                icon: null,
                                onOk: () => {
                                  Modal.confirm({
                                    centered: true,
                                    title: '确认操作',
                                    width: 600,
                                    content: (
                                      <div>
                                        <ExclamationCircleFilled
                                          style={{
                                            color: '#FFAE55',
                                            fontSize: 18,
                                          }}
                                        />
                                        &nbsp;删除后此分组下患者将移到最近患者中,您确定删除该分组?？
                                      </div>
                                    ),
                                    okText: '确认',
                                    cancelText: '取消',
                                    icon: null,
                                    onOk: () => {
                                      dispatch(
                                        removePatientGroupAsync({
                                          ids: [v.id].toString(),
                                        })
                                      )
                                        .then(unwrapResult)
                                        .then(() => {
                                          notification.success({
                                            message: '删除成功',
                                          })
                                          getGroup()
                                        })
                                    },
                                  })
                                },
                              })
                            }}
                          >
                            <DelectOutlined
                              style={{
                                fill: '#FF1B1B',
                                cursor: 'pointer',
                                width: '16px',
                                height: '16px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab='最近'
                key='3'
                style={{
                  overflow: 'auto',
                }}
              >
                {latelyList?.length !== 0 &&
                  latelyList.map((el: any, index: number) => (
                    <ListItem
                      selectId={selectId}
                      data={el}
                      type={PatientListItem.ZJ}
                      key={el.id}
                      onClick={(action, id) => {
                        setRegistration(el?.registration)
                        modalAction(action, id, el, 1)
                      }}
                    />
                  ))}
              </Tabs.TabPane>
            </Tabs>
            <div className={styles.leftFooter}>
              <Button
                type='primary'
                style={{
                  width: '80%',
                }}
                onClick={() => {
                  setAddPatientModal(true)
                  setModalPatientID('')
                }}
              >
                新增患者
              </Button>
            </div>
          </>
        )}
      </div>
      <div className={styles.PatientRight}>
        {tabsActiveKey != '2' && selectId && (
          <PatientPanel
            refreshData={() => {
              getLately()
            }}
            registration={registration}
            latelyCallBackList={() => {
              getLately()
            }}
            refreshDetailFlag={refreshDetailFlag}
            patientId={selectId}
            tabsActiveKey={tabsActiveKey}
            rightTabsActiveKey={rightTabsActiveKey}
            currentRegistrationId={currentRegistrationId}
          />
        )}
        {tabsActiveKey != '2' && !selectId && (
          <div className={styles.noPageData}>
            <NoDataIcon
              style={{
                width: 200,
                height: 200,
                opacity: 0.5,
              }}
            />
            <span>暂无数据</span>
          </div>
        )}
        {tabsActiveKey == '2' && (
          <PatientList
            groupId={groupId}
            refreshFlag={refreshFlag}
            ref={allRef}
          />
        )}
      </div>
      <PatientGroupModal
        type={PatientGroupModalType}
        visible={groupVisible}
        patientIds={[selectId]}
        editGroupId={editGroupId}
        isASync={isASync}
        onCancel={() => {
          setGroupVisible(false)
          setEditGroupId('')
          setisASync(false)
        }}
        onOk={() => {
          if (!isASync) {
            getGroup()
            setEditGroupId('')
          } else {
            setGroupVisible(false)
          }
        }}
      />

      {/* 新增患者弹窗 */}
      <AddPatientModal
        visible={addPatientModal}
        onOk={(num, patientId, cardType) => {
          setModalPatientID(patientId || '')
          if (num == 2) {
            setNotAppointmentIdFlag(true)
            setModalTile(0)
            setCardType(cardType)
            setRegisteredModal(true)
            
          } else if (num == 3) {
            setSubscribeModal(true)
          }
          // else {
          //   if (tabsActiveKey == '1') {
          //     setRightTabsActiveKey('')
          //     getTodayData()
          //   }
          // }
          if (tabsActiveKey == '1') {
            setRightTabsActiveKey('')
            getTodayData()
          } else if (tabsActiveKey == '2') {
            setRightTabsActiveKey('')
            setSelectId('')
            getGroup()
            setRefreshFlag(-refreshFlag)
          } else if (tabsActiveKey == '3') {
            // setListItemActive('')
            // getGroup()
            getLately()
          }
          setAddPatientModal(false)
        }}
        onCancel={() => {
          setAddPatientModal(false)
          setModalPatientID('')
        }}
      />

      {/* 二维码 */}
      <QrCodeModal
        visible={qrCodeModal}
        onCancel={() => {
          setQrCodeModal(false)
        }}
        onOk={() => {
          setQrCodeModal(false)
        }}
      />
      {/* 短信 */}
      <SendMessageModal
        sendMsgObj={sendMsgObj}
        visible={sendMessageModal}
        onCancel={() => {
          setSendMsgObj(null)
          setSendMessageModal(false)
        }}
        onOk={() => {
          setSendMsgObj(null)
          // getTableList()
        }}
      />

      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={modalPatientID ? modalPatientID : selectId} //患者 ID
        treatmentDoctorId={''} //就诊医生 ID
        onOk={() => {
          if (tabsActiveKey == '1') {
            setRightTabsActiveKey('')
            getTodayData()
          }
          setSubscribeModal(false)
          setModalPatientID('')
        }}
        onCancel={() => {
          setSubscribeModal(false)
          setModalPatientID('')
        }}
      />

      {/* 工作站-挂号 */}
      <RegisteredModal
        title={modalTile} // 0 挂号1 转诊
        patientId={modalPatientID ? modalPatientID : selectId} //新增患者-保存并挂号
        visible={registeredModal}
        cardType={cardType}
        registrationId={modalTile == 1 ? currentRegistrationId : ''} //挂号 ID
        appointmentId={notAppointmentIdFlag ? '' : currentAppointmentId} //保存并挂号不需要预约ID
        onOk={() => {
          if (tabsActiveKey == '1') {
            setRightTabsActiveKey('')
            getTodayData()
          } else if (tabsActiveKey == '2') {
            getGroup()
            allRef?.current?.refresh()
          } else {
            getLately()
          }
          setRegisteredModal(false)
          setcurrentAppointmentId('')
          setModalPatientID('')
          setCardType('')
        }}
        onCancel={() => {
          setRegisteredModal(false)
          setcurrentAppointmentId('')
          setModalPatientID('')
          setCardType('')
        }}
      />
    </div>
  )
}
