/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 13:35:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-03 11:11:12
 */
import { Col } from 'antd'
import React, { ReactElement, useContext, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { ThemeContext } from '../../theme/ThemeContext'
import { OutPatientList } from '../outpatientList/outpatientList'
import { OutpatientReport } from './outpatientReport/outpatientReport'
import styles from './outPatientStatistics.module.css'
import { useSelector } from 'react-redux'
import { selectTwoMenus } from '../../layouts/layoutSlice'

export const OutpatientStatisticsTabs = (): ReactElement => {

  const twoMenus = useSelector(selectTwoMenus)

  const menuList = twoMenus?.filter((v) => {
    return v?.name == '门诊报表'
  })?.[0]

  const getKeys = (i: string) => {
    switch (i) {
      case '门诊报表':
        return '0'
      case '门诊日志':
        return '1'
      default:
        return '-'
    }
  }
  

  const [activeId, setActiveId] = useState('0')

  const exRef = useRef<any>(null)

  const location: any = useLocation()

  const theme = useContext(ThemeContext)

  return (
    <Col className={styles.main}>
      <TabBar
        defaultActiveKey={location.state ? location.state.selectKey || location.state.station : '0'}
        onChange={(v) => {
          setActiveId(v)
        }}
      >
        {menuList?.subMenus?.map((v: any) => {
          return (
            <>
              <TabPane key={getKeys(v?.name)} tab={v?.name}>
                {v?.name == '门诊报表' ? (
                  <OutpatientReport oneTabKey={activeId} parentId={v?.id} />
                ) : v?.name == '门诊日志' ? (
                    <Col
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: theme.pn,
                        borderRadius: 10,
                        margin: '10px 0',
                        height: 'calc(100% - 10px)',
                      }}
                    >
                      <OutPatientList ref={exRef} parentId={v?.id} />
                    </Col>
                ) : (
                  <></>
                )}
              </TabPane>
            </>
          )
        })}
        {/* <TabPane key='0' tab='门诊报表'>
          <OutpatientReport oneTabKey={activeId} />
        </TabPane>
        <TabPane key='1' tab='门诊日志'>
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.pn,
              borderRadius: 10,
              margin: '10px 0',
              height: 'calc(100% - 10px)',
            }}
          >
            <OutPatientList ref={exRef} />
          </Col>
        </TabPane> */}
      </TabBar>
    </Col>
  )
}
