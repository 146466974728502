/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-20 13:58:51
 * @LastEditors: sj
 * @LastEditTime: 2022-06-29 15:12:17
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import * as S from '../../../services/echarts'


const initialState = {
  settingVisibile:false
}

export const InsuranceContorlSlice = createSlice({
  name: 'InsuranceContorl',
  initialState,
  reducers: {
    setSettingVisibile(state, action) {
      state.settingVisibile = action.payload
    },
  },
})

export const {
  setSettingVisibile
} = InsuranceContorlSlice.actions

// 中草药帖均--医生中草药帖均
export const averageTipAsync = createAsyncThunk<
  string,
  S.averageTipParams,
  RootThunkApi
>('/InsuranceContorl/averageTipAsync', async (params, api) => {
  return sendAsync(S.averageTip(params), api)
})

// 中草药帖均--机构中草药帖均
export const averageTipByTenantIdAsync = createAsyncThunk<
  string,
  S.averageTipParams,
  RootThunkApi
>('/InsuranceContorl/averageTipByTenantIdAsync', async (params, api) => {
  return sendAsync(S.averageTipByTenantId(params), api)
})

// 电子凭证扫码率
export const sweepRateAsync = createAsyncThunk<
  string,
  S.averageTipParams,
  RootThunkApi
>('/InsuranceContorl/sweepRateAsync', async (params, api) => {
  return sendAsync(S.sweepRate(params), api)
})

// 医保费用-次均费用
export const patientTimesRateAsync = createAsyncThunk<
  string,
  S.averageTipParams,
  RootThunkApi
>('/InsuranceContorl/patientTimesRateAsync', async (params, api) => {
  return sendAsync(S.patientTimesRate(params), api)
})
// 医保费用-人均费用
export const patientNumRateAsync = createAsyncThunk<
  string,
  S.averageTipParams,
  RootThunkApi
>('/InsuranceContorl/patientNumRateAsync', async (params, api) => {
  return sendAsync(S.patientNumRate(params), api)
})
// 医保费用-人次人头比
export const patientRateAsync = createAsyncThunk<
  string,
  S.averageTipParams,
  RootThunkApi
>('/InsuranceContorl/patientRateAsync', async (params, api) => {
  return sendAsync(S.patientRate(params), api)
})
// 医保费用-就诊人数
export const patientNumAsync = createAsyncThunk<
  string,
  S.averageTipParams,
  RootThunkApi
>('/InsuranceContorl/patientNumAsync', async (params, api) => {
  return sendAsync(S.patientNum(params), api)
})
// 医保支付限额-医保基金支付限额
export const fundQuotaAsync = createAsyncThunk<
  string,
  S.averageTipParams,
  RootThunkApi
>('/InsuranceContorl/fundQuotaAsync', async (params, api) => {
  return sendAsync(S.fundQuota(params), api)
})
// 医保支付限额-医保列支医疗费支付限额
export const medicalListAsync = createAsyncThunk<
  string,
  S.averageTipParams,
  RootThunkApi
>('/InsuranceContorl/medicalListAsync', async (params, api) => {
  return sendAsync(S.medicalList(params), api)
})

// 医保控费设置
export const settingDetailAsync = createAsyncThunk<
  void,
  void,
  RootThunkApi
>('/InsuranceContorl/settingDetailtAsync', async (params, api) => {
  return sendAsync(S.settingDetail(), api)
})

// 医保控费设置保存
export const settingSaveAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi
>('/InsuranceContorl/settingSaveAsync', async (body, api) => {
  return sendAsync(S.settingSave(body), api)
})

export const selectSettingVisibile = (state: RootState) => state.InsuranceContorl.settingVisibile

export default InsuranceContorlSlice.reducer