/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-26 10:16:13
 */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { storePageParams } from '../../services/storesearch'
import { Columns, DoctorColumns } from './columns'
import { Storesearchquery } from './query'
import {
  detailStoresearch,
  getBatchReportDamagedAsync,
  getStoreExportAsync,
  pageStoresearch,
  pageStoresearchPrintAsync,
  selectAmountTotal,
  selectCurrent,
  selectDetail,
  selectPageLoading,
  selectStoresearchData,
  selectTotal,
  setCurrent,
  setDetail,
  setlogoutlistStateAsync,
  setPageLoading,
  setsplitOrTotalAsync,
} from './storesearchSlice'
import styles from './Storesearch.module.css'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Switch,
  Table,
} from 'antd'
import { NavBar } from '../../compnents/nav/NavBar'
import { TableRowSelection } from 'antd/lib/table/interface'
import { DataType } from '../putstorage/putstorage'
import { RootDispatch } from '../../app/store'
import { useHistory, useLocation } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { EditableList } from '../../compnents/list/EditableList'
import { PrintFrame, PrintFrameRef } from '../print/PrintFrame'
import { printBody } from '../../models/storesearchPrint'
import {
  selectCredentials,
  selectInsuranceArray,
  selectTenantName,
  selectUserName,
} from '../../app/applicationSlice'
import { selectDurgsystemData } from '../durgsystem/durgsystemSlice'
import { BillingCategoryName } from '../../models/billingCategory'
import moment from 'moment'
import { sum } from 'lodash'
import {
  pagePutstorage,
  savelistPutstorageAsync,
} from '../putstorage/putstorageSlice'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { getCouponsRecordupdateStatus } from '../coupons/coupons'

export const Storesearch = () => {
  const history = useHistory()

  const [sortedInfo, setSorter] = useState<any>()

  const location: any = useLocation()

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [PrintForm] = Form.useForm()

  const [scrollHeight, setScrollHeight] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [Ids, setIds] = useState('')

  const [SelectId, setSelectId] = useState(0)

  const [selectIds, setSelectIds] = useState<any>([])

  const [Text, setText] = useState('拆零')

  const [isSplitVisible, setisSplitVisible] = useState(false)

  const [printVisible, setIsPrintVisible] = useState(false)

  const [queries, setQueries] = useState<storePageParams>({
    state: 1,
    size: 10,
    showFlag: 0,
    mergeFlag: 0,
    current: 0,
  })

  const [printData, setPrintData] = useState<any>()

  const [printListData, setPrintListData] = useState<any>()

  const [selectionType] = useState<'checkbox' | 'radio'>('checkbox')

  const detail: any = useSelector(selectDetail)

  // const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal)

  const data = useSelector(selectStoresearchData)

  const amountTotal = useSelector(selectAmountTotal)

  const tenantName = useSelector(selectTenantName)

  const storehouseList: any = useSelector(selectDurgsystemData)

  const userName = useSelector(selectUserName)

  const printRef = useRef<PrintFrameRef>(null)

  const pageLoadingRX = useSelector(selectPageLoading)

  const insuranceArray = useSelector(selectInsuranceArray)

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  const onResize = () => {
    const table = document.getElementById('tableHeightStoreSearch')
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 20 - 20
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }
  useEffect(() => {
    if (!queries.current) return
    dispatch(setPageLoading(true))
    onResize() // 初始化设置高度
    window.addEventListener('resize', onResize)
    dispatch(
      pageStoresearch({
        ...queries,
        materialsCategoryList: undefined,
        materialsCategory: queries?.materialsCategoryList?.[0],
        category: queries?.materialsCategoryList?.[1],
      })
    )
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
    sessionStorage.setItem(
      location.pathname,
      JSON.stringify({ ...queries, category: location?.state?.category })
    )
  }, [queries])

  useEffect(() => {
    let sortN = 0
    if (sortedInfo?.order === 'ascend') {
      switch (sortedInfo?.columnKey) {
        case 'count':
          sortN = 1
          break
        case 'additionRatio':
          sortN = 3
          break
        case 'splitCount':
          sortN = 5
          break
        default:
          break
      }
    } else if (sortedInfo?.order === 'descend') {
      switch (sortedInfo?.columnKey) {
        case 'count':
          sortN = 2
          break
        case 'additionRatio':
          sortN = 4
          break
        case 'splitCount':
          sortN = 6
          break
        default:
          break
      }
    }

    if (sortedInfo && sortedInfo?.order == undefined) {
      setQueries({
        ...queries,
        sort: undefined,
      })
    } else {
      setQueries({
        ...queries,
        sort: sortN,
      })
    }
  }, [sortedInfo])

  const dataMainList = {
    tenantName: tenantName,
    userName: userName,
    dataList: printListData?.map((v: any) => {
      return {
        ...v,
        counts: sum(
          v?.value?.map((i: any) => {
            return i?.count ? Number(i?.count) : 0
          })
        ),
        splitCounts: sum(
          v?.value?.map((i: any) => {
            return i?.splitCount ? Number(i?.splitCount) : 0
          })
        ),
        totals: sum(
          v?.value?.map((i: any) => {
            return i.total > -1 ? Number(i?.total) : 0
          })
        ),
        retailPriceTotals: sum(
          v?.value?.map((i: any) => {
            return i.retailPriceTotal > -1 ? Number(i?.retailPriceTotal) : 0
          })
        ),
        value: v?.value?.map((val: any) => {
          return {
            ...val,
            billingCategory: BillingCategoryName(val?.billingCategory),
            productDate:
              val.deadline != ''
                ? val.mergeFlag
                  ? '-'
                  : moment(val.productDate).format('YYYY-MM-DD')
                : '',
            deadline:
              val.deadline != ''
                ? val.mergeFlag
                  ? '-'
                  : moment(val.deadline).format('YYYY-MM-DD')
                : '',
            count: val.count != '' ? val.count + val.accountUnit : '',
            splitCounts: val.splitCounts ? Number(val?.splitCount) : 0,
            splitCount: val.splitTag ? val.splitCount + val.splitUnit : '-',
            unitPrice: val.mergeFlag
              ? '-'
              : val.unitPrice > 0
              ? parseFloat(val.unitPrice).toFixed(5)
              : '-',
            retailPrice: val.mergeFlag
              ? '-'
              : val.retailPrice > 0
              ? parseFloat(val.retailPrice).toFixed(5)
              : '-',
            total: val.total > -1 ? val.total.toFixed(2) : '0.00',
            retailPriceTotal:
              val.retailPriceTotal > -1
                ? val.retailPriceTotal.toFixed(2)
                : '0.00',
          }
        }),
      }
    }),
    totalAmountInput:
      printListData?.[0].value?.[0].totalAmountInput?.toFixed(2) || '0.00',
    totalAmountSale:
      printListData?.[0].value?.[0].totalAmountSale?.toFixed(2) || '0.00',
    storehouseName:
      storehouseList?.filter((v: any) => v?.id == queries?.storehouseId)?.[0]
        ?.name || '-',
  }
  const content = printBody(dataMainList)

  useEffect(() => {
    // printData && printRef.current?.print();
    if (printListData) {
      const page: any = window?.open('', '_blank') // 打开一个新窗口，用于打印
      page?.document?.write(content) // 写入打印页面的内容
      page?.print() // 打印
      setIsPrintVisible(false)
    }
  }, [printListData])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectIds(selectedRowKeys)
      setIds(selectedRowKeys.join(','))
    },
  }

  useEffect(() => {
    form.setFieldsValue({
      invcount: detail.count,
      invSplitCount: detail.splitCount,
      splitScale: detail.splitScale,
      splitPrice: detail.splitPrice,
    })
  }, [detail])

  useEffect(() => {
    if (SelectId) {
      dispatch(detailStoresearch(SelectId))
    }
  }, [SelectId])

  useEffect(() => {
    if (printVisible) {
      PrintForm.resetFields()
      setPrintListData(undefined)
    }
  }, [printVisible])

  //批量报损
  const batchReportDamaged = () => {
    if (selectIds.length <= 0) {
      notification.info({
        message: '请选择需要报损的商品',
        duration: 3,
      })
      return
    }

    Modal.confirm({
      centered: true,
      title: '信息',
      width: 600,
      content: (
        <div>
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>
            是否确认报损？
          </span>
          <p style={{ fontSize: 16 }}>
            已选中
            <span style={{ color: 'red', fontSize: 16 }}>
              {' ' + selectIds.length + ' '}
            </span>
            个商品，请确认各项信息是否正确，报损后将不可修改
          </p>
        </div>
      ),
      okText: '确认',
      cancelText: '取消',
      icon: null,
      onOk: () => {
        const inventoryLedgerList = data.filter((v: any) =>
          selectIds.includes(v.id)
        )

        dispatch(
          getBatchReportDamagedAsync({
            mergeFlag: queries.mergeFlag,
            inventoryLedgerList: inventoryLedgerList,
          })
        ).then((res: any) => {
          if (res.payload.length > 0) {
            dispatch(savelistPutstorageAsync(res.payload.join(',')))
              .then(unwrapResult)
              .then(() => {
                dispatch(
                  pageStoresearch({
                    ...queries,
                    materialsCategoryList: undefined,
                    materialsCategory: queries?.materialsCategoryList?.[0],
                    category: queries?.materialsCategoryList?.[1],
                  })
                )
                notification.success({
                  message: '批量报损成功',
                  duration: 3,
                })
              })
              .catch(() => {
                notification.error({
                  message: '报损单据生成成功 自动登账失败 请手动登账',
                  duration: 3,
                })
              })
          }
        })
      },
    })
  }

  const exportExcel = () => {
    dispatch(getStoreExportAsync(queries))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '库存导出.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const table = (
    <EditableList
      scroll={{
        y: Number(scrollHeight),
      }}
      style={{
        width: '100%',
        height: '89%',
        overflow: 'hidden',
      }}
      // className={styles.storetable}
      loading={pageLoadingRX}
      bordered
      page={{
        items: data,
        current: queries.current,
        size: queries.size || 10,
        total: total,
      }}
      // rowKey={(_, i) =>
      //   queries
      //     ? (queries.size || 0) * (queries.current || 0) + (i || 0)
      //     : 0
      // }
      summary={() => (
        <Table.Summary fixed>
          {/* {location?.state?.flag != 1 && ( */}
          <Table.Summary.Row className={styles.total}>
            <Table.Summary.Cell
              index={0}
              className={styles.fixed}
            ></Table.Summary.Cell>
            <Table.Summary.Cell
              index={1}
              className={styles.fixed}
            ></Table.Summary.Cell>

            <Table.Summary.Cell
              index={2}
              className={styles.fixed}
            ></Table.Summary.Cell>
            <Table.Summary.Cell index={3}></Table.Summary.Cell>
            <Table.Summary.Cell index={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={5}></Table.Summary.Cell>
            <Table.Summary.Cell index={6}></Table.Summary.Cell>
            <Table.Summary.Cell index={7}></Table.Summary.Cell>
            <Table.Summary.Cell index={8}></Table.Summary.Cell>
            <Table.Summary.Cell index={9}></Table.Summary.Cell>
            <Table.Summary.Cell index={10}></Table.Summary.Cell>
            <Table.Summary.Cell index={11}></Table.Summary.Cell>
            <Table.Summary.Cell index={12}></Table.Summary.Cell>
            {insuranceArray.length > 0 ? (
              insuranceArray[0].insuranceCode !== 'ZIFEI' ? (
                <Table.Summary.Cell index={13}></Table.Summary.Cell>
              ) : (
                ''
              )
            ) : (
              ''
            )}
            <Table.Summary.Cell index={14}></Table.Summary.Cell>
            <Table.Summary.Cell index={15} align='center'>
              合计
            </Table.Summary.Cell>
            <Table.Summary.Cell align='right' index={16}>
              {amountTotal?.totalAmountInput?.toFixed(2) || '0.00'}
            </Table.Summary.Cell>
            <Table.Summary.Cell align='right' index={17}>
              {amountTotal?.totalAmountSale?.toFixed(2) || '0.00'}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={18}></Table.Summary.Cell>
            <Table.Summary.Cell index={19}></Table.Summary.Cell>
            <Table.Summary.Cell index={20}></Table.Summary.Cell>
            <Table.Summary.Cell index={21}></Table.Summary.Cell>
            <Table.Summary.Cell index={22}></Table.Summary.Cell>
            <Table.Summary.Cell index={23}></Table.Summary.Cell>
            <Table.Summary.Cell index={24}></Table.Summary.Cell>
            <Table.Summary.Cell index={25}></Table.Summary.Cell>
            <Table.Summary.Cell index={26}></Table.Summary.Cell>
            <Table.Summary.Cell index={27}></Table.Summary.Cell>
            <Table.Summary.Cell
              index={28}
              className={styles.fixed}
            ></Table.Summary.Cell>
          </Table.Summary.Row>
          {/* )} */}
        </Table.Summary>
      )}
      rowKey={(record: any) => record.id}
      onChangePage={(current, pageSize) => {
        setQueries({ ...queries, size: pageSize as number, current })
      }}
      rowSelection={
        {
          type: selectionType,
          ...rowSelection,
        } as TableRowSelection<any>
      }
      onChange={(a, b, sorter: any) => {
        setSorter(sorter)
      }}
      columns={
        // location?.state?.flag
        //   ? DoctorColumns(
        //       (text, id) => {
        //         switch (text) {
        //           case 'detail':
        //             history.push({
        //               pathname: '/StoreSeaechDetail',
        //               state: {
        //                 selectId: id,
        //                 flag: location?.state?.flag,
        //               },
        //             })
        //             // setSelectId(id);
        //             // setIsVisible(true);
        //             break
        //           case 'split':
        //             setSelectId(id)
        //             setText('拆零')
        //             setisSplitVisible(true)
        //             break
        //           case 'total':
        //             setSelectId(id)
        //             setText('规整')
        //             setisSplitVisible(true)
        //             break
        //           default:
        //             break
        //         }
        //       },
        //       location?.state?.flag as number,
        //       queries.size * (queries.current - 1)
        //     ):
        Columns(
          (text, id) => {
            switch (text) {
              case 'detail':
                history.push({
                  pathname: '/StoreSeaechDetail',
                  state: {
                    selectId: id,
                    flag: location?.state?.flag,
                  },
                })
                // setSelectId(id);
                // setIsVisible(true);
                break
              case 'split':
                setSelectId(id)
                setText('拆零')
                setisSplitVisible(true)
                break
              case 'total':
                setSelectId(id)
                setText('规整')
                setisSplitVisible(true)
                break
              default:
                break
            }
          },
          location?.state?.flag as number,
          queries.size * (queries.current - 1),
          sortedInfo
        )
      }
      rowClassName={(record: any) => {
        return !record.state ? styles.gray : ''
      }}
      onRow={(d: any) => ({
        onDoubleClick: () => {
          if (!d.mergeFlag) {
            history.push({
              pathname: '/StoreSeaechDetail',
              state: {
                selectId: d.id,
                flag: location?.state?.flag,
              },
            })
          }
        },
      })}
    />
  )

  return (
    <>
      <Col
        style={{
          margin: '10px 20px',
        }}
      >
        <NavBar
          where={['库存查询', '库存查询列表']}
          backtrace={{
            name: !location?.state?.flag ? '库房工作站' : '医生工作站',
            path: !location?.state?.flag
              ? '/storage/station'
              : '/doctor/station',
            state: {
              station: '2',
            },
          }}
          buttons={
            <>
              <Space>
                <Switch
                  size='small'
                  style={{
                    backgroundColor: queries.state ? '#FF5D5D' : '#1CD0BD',
                  }}
                  checked={(queries.state as unknown) as boolean}
                  onClick={() => {
                    if (!Ids) {
                      notification.error({
                        message: queries.state
                          ? '请选择要停用的药品!'
                          : '请选择要启用的药品',
                        duration: 3,
                      })
                    } else {
                      setIsModalVisible(true)
                    }
                  }}
                />
                {queries.state ? '停用' : '启用'}
              </Space>
            </>
          }
        />
      </Col>
      <div className={styles.content}>
        <Storesearchquery
          onValueChange={(v: any) => {
            // const vs = Object.values(v);
            // if (!vs.every((item) => item === undefined)) {
            setQueries({
              ...queries,
              ...v,
              deadlineStart:
                v?._dateRange == 8 || v?._dateRange == 7
                  ? undefined
                  : v?.registrationTimeHead,
              deadlineEnd:
                v?._dateRange == 7 || v?._dateRange == 12
                  ? undefined
                  : v?.registrationTimeTail,
            })
            // }
            setSorter(undefined)
          }}
          onExport={() => {
            exportExcel()
          }}
          onBatchReportDamaged={() => {
            batchReportDamaged()
          }}
          print={() => {
            setIsPrintVisible(true)
            // setPrintData([...data])
          }}
        />{' '}
        <div
          id='tableHeightStoreSearch'
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {table}
        </div>
      </div>
      <Modal
        title={queries.state ? '确认停用？' : '确认启用?'}
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false)
          dispatch(
            setlogoutlistStateAsync({ ids: Ids, state: Number(!queries.state) })
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: queries.state ? '停用成功' : '启用成功',
                duration: 3,
              })
              dispatch(pageStoresearch({ ...queries }))
            })
        }}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        okText='确认'
        cancelText='取消'
      >
        <h3>{queries.state ? '确认停用？' : '确认启用'}</h3>
        {queries.state ? (
          <p>请确认各项信息是否填写正确，停用后将不可销售</p>
        ) : (
          <></>
        )}
      </Modal>
      <Modal
        title='打印类型'
        visible={printVisible}
        onOk={() => {
          PrintForm.resetFields()
          setIsPrintVisible(false)
        }}
        onCancel={() => {
          PrintForm.resetFields()
          setIsPrintVisible(false)
        }}
        okText='确认'
        cancelText='取消'
        footer={null}
      >
        <Form
          {...layout}
          autoComplete='off'
          form={PrintForm}
          name='control-hooks'
          onFinish={(vs) => {
            if (vs?.flag == 0) {
              dispatch(pageStoresearchPrintAsync({ ...queries }))
                .then(unwrapResult)
                .then((v: any) => {
                  const arr: any = []
                  for (let i = 0; i < Object.keys(v).length; i++) {
                    const valueList: any = Object.values(v)[i]
                    arr.push({
                      key: Object.keys(v)[i],
                      name: valueList?.[0]?.name,
                      value: valueList,
                    })
                  }
                  setPrintListData(arr)
                  setIsPrintVisible(false)
                })
            } else {
              dispatch(
                pageStoresearchPrintAsync({
                  ...queries,
                  size: 10000,
                  current: 1,
                })
              )
                .then(unwrapResult)
                .then((v: any) => {
                  const arr: any = []
                  for (let i = 0; i < Object.keys(v).length; i++) {
                    const valueList: any = Object.values(v)[i]
                    arr.push({
                      key: Object.keys(v)[i],
                      name: valueList?.[0]?.name,
                      value: valueList,
                    })
                  }
                  setPrintListData(arr)
                  setIsPrintVisible(false)
                })
            }
          }}
        >
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                {...layout}
                name='flag'
                label='请选择打印类型'
                rules={[{ required: true, message: '打印类型不能为空!' }]}
              >
                <Radio.Group>
                  <Radio key={0} value={0}>
                    打印当页
                  </Radio>
                  <Radio key={1} value={1}>
                    打印全部
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Button
                htmlType='submit'
                type='primary'
                style={{ float: 'right' }}
              >
                确认
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={Text}
        visible={isSplitVisible}
        onOk={() => {
          form.resetFields()
          setSelectId(0)

          setisSplitVisible(false)
        }}
        onCancel={() => {
          form.resetFields()
          setSelectId(0)

          setisSplitVisible(false)
        }}
        okText='确认'
        cancelText='取消'
        footer={null}
      >
        <Form
          {...layout}
          autoComplete='off'
          form={form}
          name='control-hooks'
          onFinish={(vs) => {
            const flag = Text === '拆零' ? 1 : 2
            if (Text === '拆零') {
              if (vs.count === undefined) {
                notification.error({
                  message: '请输入要拆零/规整的数量',
                  duration: 3,
                })
              } else if (vs.count <= vs.invcount) {
                dispatch(
                  setsplitOrTotalAsync({
                    ...vs,
                    flag,
                    tenantInventoryLedgerId: detail.id,
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: '操作成功',
                      duration: 3,
                    })
                    setisSplitVisible(false)
                    dispatch(pageStoresearch({ ...queries }))
                    form.resetFields()
                    dispatch(setDetail([]))
                    setSelectId(0)
                  })
                  .catch(() => {
                    notification.error({
                      message: '请输入正确数量',
                      duration: 3,
                    })
                  })
              } else {
                notification.error({
                  message: '拆零数量不可大于现有库存',
                  duration: 3,
                })
              }
            } else {
              if (vs.count === undefined) {
                notification.error({
                  message: '请输入要拆零/规整的数量',
                  duration: 3,
                })
              } else if (vs.count * vs.splitScale <= vs.invSplitCount) {
                dispatch(
                  setsplitOrTotalAsync({
                    ...vs,
                    flag,
                    tenantInventoryLedgerId: detail.id,
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: '操作成功',
                      duration: 3,
                    })
                    setisSplitVisible(false)
                    dispatch(pageStoresearch({ ...queries }))
                    form.resetFields()
                    dispatch(setDetail([]))
                    setSelectId(0)
                  })
                  .catch(() => {
                    notification.error({
                      message: '请输入正确数量',
                      duration: 3,
                    })
                  })
              } else {
                notification.error({
                  message: '规整数量乘以拆零比例不可大于现有拆零库存',
                  duration: 3,
                })
              }
            }
          }}
        >
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={'需要' + Text + '数量'}
                name='count'
                rules={[
                  {
                    pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,2})?$/),
                    message: '请输入数字',
                  },
                ]}
              >
                <Input
                  onInput={(e: any) => {
                    if (Text === '拆零') {
                      form.setFieldsValue({
                        outCount:
                          Number(e.target.value) * Number(detail.splitScale),
                      })
                    } else {
                      form.setFieldsValue({
                        outCount:
                          Number(e.target.value) * Number(detail.splitScale),
                      })
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {Text === '拆零' ? (
              <Col span={24}>
                <Form.Item label='现有库存数量' name='invcount'>
                  <Input disabled />
                </Form.Item>
              </Col>
            ) : (
              <Col span={24}>
                <Form.Item label='现有拆零库存数量' name='invSplitCount'>
                  <Input disabled />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                label={Text === '拆零' ? '收入数量' : '支出数量'}
                name='outCount'
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={'拆零比例'} name='splitScale'>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                htmlType='submit'
                type='primary'
                style={{ float: 'right' }}
              >
                确认
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <PrintFrame
        ref={printRef}
        title={
          JSON.parse(localStorage.getItem('credentials') as string)?.tenantName
        }
        subTitle='库存管理'
      >
        <EditableList
          bordered
          pagination={false}
          page={{
            items: printData,
            current: queries.current,
            size: queries.size,
            total: total,
          }}
          columns={
            // location?.state?.flag
            //   ? DoctorColumns(
            //       (text, id) => {
            //         switch (text) {
            //           case 'detail':
            //             history.push({
            //               pathname: '/StoreSeaechDetail',
            //               state: {
            //                 selectId: id,
            //                 flag: location?.state?.flag,
            //               },
            //             })
            //             // setSelectId(id);
            //             // setIsVisible(true);
            //             break
            //           case 'split':
            //             setSelectId(id)
            //             setText('拆零')
            //             setisSplitVisible(true)
            //             break
            //           case 'total':
            //             setSelectId(id)
            //             setText('规整')
            //             setisSplitVisible(true)
            //             break
            //           default:
            //             break
            //         }
            //       },
            //       location?.state?.flag as number,
            //       queries.size *
            //         (queries.current - 1 > 0 ? queries.current - 1 : 0)
            //     )
            //   :
            Columns(
              (text, id) => {
                return
              },
              location?.state?.flag as number,
              queries.size * (queries.current - 1 > 0 ? queries.current - 1 : 0)
            ).filter((v) => v.title !== '拆零比例' && v.title !== '操作')
          }
          rowClassName={(record: any) => {
            return !record.state ? styles.gray : ''
          }}
        />
        {/* <EditableList
          bordered
          page={{
            items: data,
            current: queries.current,
            size: queries.size,
            total: total,
          }}
          pagination={false}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align='center'>合计</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6}></Table.Summary.Cell>
                <Table.Summary.Cell index={9}></Table.Summary.Cell>
                <Table.Summary.Cell index={10}></Table.Summary.Cell>
                <Table.Summary.Cell index={11}></Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={12}>{amountTotal?.totalAmountInput || 0}</Table.Summary.Cell>
                <Table.Summary.Cell align='right' index={13}>{amountTotal?.totalAmountSale || 0}</Table.Summary.Cell>
                <Table.Summary.Cell index={14}></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          rowKey={(record: any) => record.id}
          columns={
            location.state.flag
              ? DoctorColumns(
                (text, id) => {
                  switch (text) {
                    case "detail":
                      history.push({
                        pathname: "/StoreSeaechDetail",
                        state: {
                          selectId: id,
                          flag: location.state.flag,
                        },
                      });
                      // setSelectId(id);
                      // setIsVisible(true);
                      break;
                    case "split":
                      setSelectId(id);
                      setText("拆零");
                      setisSplitVisible(true);
                      break;
                    case "total":
                      setSelectId(id);
                      setText("规整");
                      setisSplitVisible(true);
                      break;
                    default:
                      break;
                  }
                },
                location.state.flag as number,
                queries.size * (queries.current - 1 > 0 ? queries.current - 1 : 0)
              )
              : Columns(
                (text, id) => {
                 return
                },
                location.state.flag as number,
                queries.size * (queries.current - 1 > 0 ? queries.current - 1 : 0)
              ).filter(v => (v.title !== '拆零比例' && v.title !== '操作'))
          }
          rowClassName={(record: any) => {
            return !record.state ? styles.gray : "";
          }}
        /> */}
      </PrintFrame>
    </>
  )
}
