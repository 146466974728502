/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-08 11:03:08
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 11:38:57
 */
import { Form, Row, Col, Button, Select, Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { CaretDownFilled } from "@ant-design/icons";
import { DateRangeType, DateSelect } from "../../../compnents/form/DateSelect";
import { EditableList } from "../../../compnents/list/EditableList";
import { Columns } from "../../InventoryPrice/columns";
import styles from "../Storesearch.module.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../../app/store";
import { getInventoryChangePageAsync } from "../../InventoryChange/InventoryChangSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getInventoryPageAsync } from "../../InventoryPrice/inventoryPriceSlice";
import { InventoryPriceParams } from "../../../services/InventPrice";
import { DateTimeFormat } from "../../../models/datetime";
import moment from "moment";
const { Option } = Select;

export const ChangePrice = () => {
  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm();

  const location: any = useLocation();

  const [size, setSize] = useState(5);

  const [total, setTotal] = useState(0);

  const [current, setCurrent] = useState(1);

  const [data, setData] = useState<any>([]);

  const [queries, setQueries] = useState<InventoryPriceParams>({
    executeDateStart: moment().startOf("day").format(DateTimeFormat),
    executeDateEnd: moment().endOf("day").format(DateTimeFormat),
  });

  const [pageLoading, setPageLoading] = useState(false)   //表格 loading


  useEffect(() => {
    if (location) {
      setPageLoading(true)
      dispatch(
        getInventoryPageAsync({
          size,
          current,
          tenantInventoryLedgerId: location.state.selectId,
          ...queries,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setData(res.records);
          setTotal(res.total);
        })
        .finally(() => setPageLoading(false))
    }
  }, [size, current, queries]);

  return (
    <>
      <Form
        form={form}
        name="control-hooks"
        autoComplete="off"
        onFinish={(vs) => {
          setQueries({
            state: vs.state,
            executeDateStart: vs.timeHead,
            executeDateEnd: vs.timeTail,
          });
        }}
      >
        <Row
          wrap={false}
          style={{
            height: "40px",
            margin: "20px 0",
          }}
        >
          <Col span={4}>
            <Form.Item name="state">
              <Select
                onChange={() => form.submit()}
                allowClear
                placeholder="执行状态"
                // suffixIcon={<CaretDownFilled />}
              >
                <Option value={0}>未执行</Option>
                <Option value={1}>执行</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="">
              <DateSelect
                placeholder="调价日期"
                style={{ width: "10rem", marginLeft: "10px" }}
                labelPrefix=""
                initialValue={DateRangeType.Today}
                namePrefix="time"
                onChange={form.submit}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
              查询
            </Button>
          </Col>
        </Row>
      </Form>
      <div className={styles.detailTable}>
        <EditableList
          style={{ height: "100%" }}
          loading={pageLoading}
          columns={Columns(size * (current - 1))}
          pagination={false}
          page={{
            items: data,
            current,
            size: size,
            total,
          }}
        />
        <Pagination
          total={total}
          current={current}
          pageSize={size}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={["5", "10", "15", "20"]}
          style={{
            position: "absolute",
            bottom: "30px",
            right: "40px",
          }}
          showTotal={(total) => `共 ${total} 条`}
          onChange={(current, size) => {
            setSize(size as number);
            setCurrent(current);
          }}
        />
      </div>
    </>
  );
};
