/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-12-13 09:34:24
 * @LastEditors: linxi
 * @LastEditTime: 2021-12-13 09:41:35
 */
import { API_PREFIX } from "./constant"
import { Request } from "./request"

export interface NatureBody {
  code: string
  codeName: string
}

export interface PreScriptBody {
  prescriptionReviewRequestType?: number
  requestCase?: {
    caseId?: string
    caseType?: number
    chiefComplaint?: string
    patientBaseInfo?: {
      age?: string
      height?: string
      patientId?: string
      sex?: string
      weight?: string
    }
    patientEmrDiagnosis?: string
    prescriptions?: {
      dosagePerDay?: string
      dosagePerTime?: string
      dosagePerTimeUnit?: string
      drugId?: string
      drugName?: string
      packAge?: string
      packageUnit?: string
      prescriptionId?: string
      timePerDay?: string
      usage?: string
    }[]
    presentIllness?: string
    visitId?: string
  }
}

const getNature = (code: string): Request => {
  return {
    url: `${API_PREFIX}/blade-user/signature/detail`,
    method: "GET",
    params: {
      code,
    },
  }
}

const setNature = (body: NatureBody): Request => {
  return {
    url: `${API_PREFIX}/blade-user/signature/submit`,
    method: "POST",
    body,
  }
}

const getNatureToken = (): Request => {
  return {
    url: `${API_PREFIX}/blade-user/user/gettoken`,
    method: "POST",
  }
}

const getIframePreScription = (body: PreScriptBody): Request => {
  return {
    url: `${API_PREFIX}/blade-user/user/prescriptionreview`,
    method: "POST",
    body,
  }
}

export { getNature, setNature, getNatureToken, getIframePreScription }
