/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-14 13:44:55
 * @LastEditors: K
 * @LastEditTime: 2022-07-14 13:47:39
 */
export enum modalType {
  add = 1,
  edit = 2,
  delete = 3,
}

export const tankingListModalText = [
  modalType.add,
  modalType.edit,
  modalType.delete,
]

export function getModalTypeName(type: modalType): string {
  switch (type) {
    case 1:
      return '新增班次'
    case 2:
      return '编辑排班'
    case 3:
      return '删除班次'
    default:
      return '-'
  }
}
