/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 11:16:34
 * @LastEditors: K
 * @LastEditTime: 2022-09-01 16:00:25
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Dropdown, Form, Input, Menu, Modal, notification, Radio, Row, Select, Space } from 'antd'
import React, { forwardRef, ReactElement, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import styles from '../peopleSales/PeopleSales.module.css'
import { Columns } from './colums'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { forbiddenIllnessAsync, illnessSettingPageAsync, saveIllnessAsync, detailIllnessAsync, getSubmitDeptDisease, getBachSubmitDeptDisease } from '../peopleSales/peopleSaleSlice'
import { stringTrim } from '../../../utils/StringUtils'
import cnchar from 'cnchar'
import { pinyin } from "pinyin-pro";
import { TableRowSelection } from 'antd/lib/table/interface'
import {
  getZjWbCodes,
} from '../../commodity/commoditySlice'
import { getDepartmentList } from '../../department/departmentSlice'

const IllnessSetting = (props: {
  tabKey: string
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [editForm] = Form.useForm()

  const [setForm] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false)

  const [title, setTitle] = useState("新增疾病目录")


  const [params, setParams] = useState<any>({
    current: 0,
    size: 10,
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const [modalVisibal, setModalVisibal] = useState(false)


  const [id, setId] = useState<any>();

  const [type, setType] = useState(0)

  const [visible, setVisible] = useState(false)

  const [selectIds, setSelectIds] = useState<any>([])

  const [departmentList, setDepartmentList] = useState<any>([])

  const rules = [
    {
      required: true
    }
  ]

  const close = () => {
    setModalVisibal(false)
    editForm.resetFields()
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  const getTitle = () => {
    switch (type) {
      case 1:
        return '单个疾病权限设置'
      case 2:
        return '批量更改权限'
      case 3:
        return '批量加入权限'
      default:
        return '单个疾病权限设置'
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectIds(selectedRowKeys)
    },
  }

  const disable = (callback: () => void) => {
    if (selectIds.length == 0) {
      notification.info({
        message: '请先选择疾病',
      })
      return
    }
    callback()
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => {
        disable(() => {
          setVisible(true)
          setTitle('批量加入权限')
          setType(2)
        })
      }}
      >
        加入科室
      </Menu.Item>
      <Menu.Item onClick={() => {
        disable(() => {
          setVisible(true)
          setTitle('批量更改权限')
          setType(3)
        })
      }}
      >
        更改科室
      </Menu.Item>
    </Menu>
  )


  useEffect(() => {
    if (modalVisibal && id) {
      dispatch(detailIllnessAsync({
        id: id,
      }))
        .then(unwrapResult)
        .then((res: any) => {
          editForm.setFieldsValue({
            ...res
          })
        })
    }
  }, [modalVisibal])
  const getPage = () => {
    setPageLoading(true)
    dispatch(
      illnessSettingPageAsync({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(getDepartmentList({
          size: 1000,
          current: 0,
          status: 1,
          suitForClinc: 1
        }))
          .then(unwrapResult)
          .then((t: any) => {
            const data = {
              total: res.total,
              items: res.records?.map((v: any) => {
                const departs = t?.items?.filter((c: any) => {
                  return v?.deptDiseaseList?.includes(c?.id)
                })?.map((o: any) => o.name)?.join(',')
                return {
                  ...v,
                  departmentList: departs
                }
              }),
            }
            setPage(data)
            setDepartmentList(
              t?.items?.map((c: any) => {
                return {
                  label: c?.name,
                  value: c?.id,
                }
              })
            )
          })
      }).finally(() => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    if (!params.current) return
    getPage()
  }, [params])

  useEffect(() => {
    form.submit()
  }, [])

  // useImperativeHandle(ref, () => ({
  //   reload: () => {
  //     dispatch(getPaymentList(params))
  //   },
  // }))

  useEffect(() => {
    console.log(props.tabKey);

    setSelectIds([])
  }, [props?.tabKey])

  return (
    <Col className={styles.main}>
      <Form
        form={form}
        onFinish={(values) => {
          setParams({
            ...params,
            ...values,
            current: 1
          })
        }}
        initialValues={{
          status: 2,
          listType: 1,
          customFlag: 0,
        }}
      >
        <Row style={{ marginTop: 10, marginBottom: 20 }} justify='space-between'>
          <Space>

            <Form.Item noStyle name='param'>
              <Input
                autoComplete='off'
                prefix={<SearchOutlined />}
                placeholder='请输入疾病名称/助记码/ICD10编码'
                style={{ width: '18rem' }}
                allowClear
                onChange={form.submit}
              />
            </Form.Item>
            <Form.Item noStyle name='diagType'>
              <Select
                style={{ width: '8rem' }}
                placeholder='疾病类别'
                onChange={form.submit}
                allowClear
              >
                <Select.Option value={1}>西医诊断</Select.Option>
                <Select.Option value={2}>中医证候</Select.Option>
                <Select.Option value={3}>中医诊断</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle name='listType'>
              <Select
                style={{ width: '10rem' }}
                placeholder='目录类别'
                onChange={form.submit}
              >
                <Select.Option value={0}>目录类别-医保目录</Select.Option>
                <Select.Option value={1}>目录类别-自费目录</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle name='customFlag'>
              <Select
                style={{ width: '10rem' }}
                placeholder='数据来源'
                onChange={form.submit}
              >
                <Select.Option value={0}>数据来源-系统录入</Select.Option>
                <Select.Option value={1}>数据来源-手工录入</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle name='status'>
              <Select
                style={{ width: '8rem' }}
                placeholder='状态'
                onChange={form.submit}
                allowClear
              >
                <Select.Option value={2}>状态-启用</Select.Option>
                <Select.Option value={1}>状态-禁用</Select.Option>
              </Select>
            </Form.Item>
            {/* <Form.Item noStyle name=''>
              <Select
                style={{ width: '8rem' }}
                placeholder='标签'
                onChange={form.submit}
                allowClear
              >
              </Select>
            </Form.Item> */}
            <Form.Item noStyle name='deptId'>
              <Select
                style={{ width: '8rem' }}
                placeholder='使用科室'
                onChange={form.submit}
                allowClear
              >
                {
                  departmentList?.map((v: any) => {
                    return <Select.Option value={v.value} key={v.value}>{v.label}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Button
              type='primary'
              htmlType='submit'
            >
              查询
            </Button>
          </Space>
          <Space>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button
                style={{ marginRight: '6px' }}
                type='primary'
                className={styles.add}
              >
                批量操作
                <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              type='primary'
              onClick={() => {
                setModalVisibal(true)
                setTitle('新增疾病目录')
                setId(undefined)
              }}
            >
              新增疾病
            </Button>
          </Space>
        </Row>
      </Form>
      <EditableList
        loading={pageLoading}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns(params.size * (params.current - 1), (action, t) => {
          switch (action) {
            case 'forbidden':
              dispatch(forbiddenIllnessAsync({
                id: t.id,
                status: t.status == 0 ? 1 : 0
              })).then(() => {
                getPage()
              })
              break
            case 'edit':
              setTitle("修改疾病目录")
              setId(t.id)
              setModalVisibal(true)
              break
            case 'setting':
              setVisible(true)
              setId(t.id)
              setTitle('单个疾病权限设置')
              setType(1)
              setForm.setFieldsValue({
                name: t.name,
                deptIdList: t.deptDiseaseList
              })
              break
            default:
              break
          }
        })}
        rowSelection={
          {
            selectedRowKeys: selectIds,
            ...rowSelection,
          } as TableRowSelection<any>
        }
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
      // onRow={(d) => ({
      //   onClick: (e) => {
      //     e.stopPropagation()
      //     const isCheck = selectIds.includes(d?.id)
      //     let ids = []
      //     if (isCheck) {
      //       ids = selectIds.filter((id: any) => id !== d?.id)
      //     } else {
      //       ids = [...selectIds, d?.id]
      //     }
      //     setSelectIds(ids as string[])
      //   },
      // })}
      />
      <Modal
        title={title}
        visible={modalVisibal}
        onCancel={close}
        footer={null}
      >
        <Form
          form={editForm}
          onFinish={(values) => {
            dispatch(saveIllnessAsync(values)).then(unwrapResult).then(() => {
              notification.success({
                message: '新增成功'
              })
              close()
              getPage()
            })
          }}
          initialValues={{
            listType: 1,
            category: 0,
          }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item name='listType' label="目录类别" rules={rules}>
            <Radio.Group>
              <Radio value={0} disabled> 医保目录 </Radio>
              <Radio value={1}> 自费目录 </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name='id' label="id" style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item name='source' label="疾病来源" rules={rules}>
            <Radio.Group>
              <Radio value={2}> ICD10 2.0 </Radio>
              <Radio value={1}> ICD10 1.0 </Radio>
              <Radio value={9}> 其他 </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name='diagType' label="疾病类别" rules={rules}>
            <Radio.Group>
              <Radio value={1}> 西医诊断 </Radio>
              <Radio value={3}> 中医诊断 </Radio>
              <Radio value={2}> 中医证候 </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name='category' label="疾病类型" rules={rules}>
            <Radio.Group>
              <Radio value={0}> 普通病 </Radio>
              <Radio value={1}> 特殊病 </Radio>
              <Radio value={2}> 慢性病 </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name='icds' label="疾病编码" rules={rules} getValueFromEvent={stringTrim}>
            <Input placeholder='请输入疾病编码' maxLength={116} />
          </Form.Item>
          <Form.Item name='name' label="疾病名称" rules={rules} getValueFromEvent={stringTrim}>
            <Input placeholder='请输入疾病名称' onInput={(e: any) => {
              // editForm.setFieldsValue({
              //   mnemonicCode: pinyin(e.target.value, { pattern: 'first', toneType: 'none', separator: '' }).toUpperCase(),
              // })
              dispatch(getZjWbCodes(e.target.value))
                .then(unwrapResult)
                .then((v: any) => {
                  editForm.setFieldsValue({
                    mnemonicCode: v?.pinYin,
                    wubiCode: v?.wuBi,
                  })
                })
            }} maxLength={16} />
          </Form.Item>
          <Form.Item name='mnemonicCode' label="助记码" rules={rules} getValueFromEvent={stringTrim}>
            <Input placeholder='请输入助记码' maxLength={116} />
          </Form.Item>
          <Form.Item name='wubiCode' label="五笔" getValueFromEvent={stringTrim}>
            <Input placeholder='请输入五笔' maxLength={116} />
          </Form.Item>

        </Form>
        <Row justify='end'>
          <Space>
            <Button onClick={() => setModalVisibal(false)}>
              取消
            </Button>
            <Button type='primary' onClick={() => {
              editForm.submit()
            }}>
              确认
            </Button>
          </Space>
        </Row>
      </Modal>

      <Modal
        title={title}
        visible={visible}
        centered
        onCancel={() => {
          setVisible(false)
          setForm.resetFields()
        }}
        maskClosable={false}
        footer={null}
      >
        <Form
          form={setForm}
          colon={false}
          {...layout}
          onFinish={(vs) => {
            if (type === 1) {
              dispatch(getSubmitDeptDisease({ ...vs, diseaseId: id, listType: form.getFieldValue('listType') }))
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: '操作成功'
                  })
                  setVisible(false)
                  setForm.resetFields()
                  setSelectIds([])
                  getPage()
                })
            } else {
              dispatch(getBachSubmitDeptDisease({ ...vs, diseaseIdList: selectIds, listType: form.getFieldValue('listType'), changeWay: type === 3 ? 1 : 2 }))
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: '操作成功'
                  })
                  setVisible(false)
                  setSelectIds([])
                  setForm.resetFields()
                  getPage()
                })
            }
          }}
        >
          {
            type === 1 ? (
              <Form.Item name='name' label="疾病名称" getValueFromEvent={stringTrim}>
                <Input placeholder='请输入疾病名称' disabled maxLength={116} />
              </Form.Item>
            ) : <></>
          }
          <Form.Item label='使用科室' name='deptIdList'>
            <Select
              mode='multiple'
              allowClear
              placeholder='请选择科室'
            // options={departmentList}
            >
              {
                departmentList?.map((v: any) => {
                  return <Select.Option value={v.value} key={v.value}>{v.label}</Select.Option>
                })
              }
            </Select>
          </Form.Item>
          {
            type === 2 ? (
              <Form.Item label=' ' name=''>
                <div style={{ color: '#101010' }}>
                  所选疾病将加入新科室并同时保留原科室
                </div>
              </Form.Item>
            ) : type === 3 ? (
              <Form.Item label=' ' name=''>
                <div style={{ color: '#101010' }}>
                  所选疾病将移出原科室并移入新科室
                </div>
              </Form.Item>
            ) : <></>
          }
        </Form>
        <Row justify='space-between'>
          {
            type !== 1 ? (
              <div>
                已选择 <span style={{ color: '#F00' }}>{selectIds?.length}</span>{' '}个疾病
              </div>
            ) : (<div></div>)
          }
          <Space>
            <Button onClick={() => {
              setVisible(false)
              setForm.resetFields()
            }}>取消</Button>
            <Button type='primary' onClick={setForm.submit}>
              确认
            </Button>
          </Space>
        </Row>
      </Modal>
    </Col>
  )
}

export const IllnessSettingS = forwardRef(IllnessSetting)
