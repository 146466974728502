/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-16 18:06:31
 * @LastEditors: linxi
 * @LastEditTime: 2024-08-08 15:18:43
 */
import {
  AlipayCircleFilled,
  CreditCardFilled,
  DollarCircleFilled,
  WechatFilled,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Col,
  message,
  Modal,
  notification,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectUserName,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectTenantCategory,
  selectUserId,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import { traceRoute } from '../../../layouts/layoutSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../../models/datetime'
import { Payment, PaymentStatus } from '../../../models/payment'
import { RecipeStatus } from '../../../models/recipe'
import { Refund } from '../../../models/refund'
import { PaymentListParams } from '../../../services/payment'
import { ThemeContext } from '../../../theme/ThemeContext'
import {
  strParse,
  medicalSendHttp,
  readCardSHttp,
  getTradeName,
  readCardEHttp,
} from '../../../utils/MedicalUtils'
import {
  cancelSettlementAsync,
  saveSettlementAsync,
  selfPayUploadAsync,
  selfPayRepealAsync,
  preSettlementAsync,
  pay,
  FeeListUpAsync,
  FeeListUpCancelAsync,
  setUpdateFlagsAsync,
  updateCardInfoAsync,
} from '../confirm/paymentConfirmSlice'
import {
  getPaymentResult,
  isCancelUpload,
  selectResult,
} from '../result/paymentResultSlice'
import { PaymentColumns, PaymentColumnsWithAmount } from './columns'
import {
  getPaymentDetail,
  getPaymentList,
  refund as refundPayment,
  selectPaymentPage,
  JuhePremium,
  getAllDepartmentUser,
  getPaymentListExoprt,
} from './paymentSlice'
import { PaymentQuery } from './Query'
import { serverType } from '../../../services/commodity'
import { PaymentConfirm, PaymentConfirmRef } from '../confirm/PaymentConfirm'
import { PaymentConfirmation } from '../../../models/confirmation'
import {
  getPatientInfoAsync,
  registerValue,
} from '../../registration/register/registerSlice'
import { JuhePay } from '../../../compnents/icons/Icons'
import styles from './Payments.module.css'
import { RealPayModal } from './realPayModal'
import { TableRowSelection } from 'antd/lib/table/interface'
import { DataType } from '../../putstorage/putstorage'
import { sum } from 'lodash'
import { getTradeCode } from '../../../models/material'

export interface PaymentsRef {
  reload: () => void
}

interface PaymentProps {
  selectedTab?: any
  status: PaymentStatus
  sibling?: PaymentsRef | null
  YBZFUploadflag?: boolean
}

const RealPayments = (
  { selectedTab, status, sibling = null, YBZFUploadflag = false }: PaymentProps,
  ref: React.Ref<PaymentsRef>
): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const refModal = useRef<PaymentConfirmRef>(null)

  const [confirmation, setConfirmation] = useState<
    PaymentConfirmation | undefined
  >(undefined)

  const [registerData, setRegisterData] = useState<registerValue | undefined>()

  const [buttonLoading, setButtonLoading] = useState(false)

  const [stkButtonLoading, setStkButtonLoading] = useState(false)

  const [dzpzButtonLoading, setDzpzButtonLoading] = useState(false)

  const page: any = useSelector(selectPaymentPage(status))

  const [params, setParams] = useState<PaymentListParams>({
    current: 0,
    state: status,
  })

  const [selectedKeys, setSelectedKeys] = useState<any>([])

  const result = useSelector(selectResult) //预退费详情接口

  const [totalData, setTotalData] = useState<any>()

  const [totalDataSettled, setTotalDataSettled] = useState<any>()

  const [isReplaceMakeVisible, setIsReplaceMakeVisible] = useState(false)

  const [sureReplaceMakeVisible, setSureReplaceMakeVisible] = useState(false)

  const [refundParams, setRefundParams] = useState<Payment>()

  const [refund, setRefund] = useState<Refund | undefined>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const baseData = useSelector(selectInsuranceItem)

  const [pageLoading, setPageLoading] = useState(false)

  const [tableDoubleFnData, setTableDoubleFnData] = useState<any>()

  const [tableId, setTableId] = useState('')

  const [loading, setLoading] = useState(false)

  const [scrollHeight, setScrollHeight] = useState<any>()

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedKeys(selectedRowKeys)
    },
  }

  useEffect(() => {
    setButtonLoading(false)
    setDzpzButtonLoading(false)
    setStkButtonLoading(false)
  }, [])

  // 计算合计数据
  useEffect(() => {
    if (selectedKeys?.length) {
      // const data = page?.items?.filter((v: any) => v?.state != 5)
      const data = page?.items
      if (data?.length) {
        // 过滤出的包含勾选的所有数据
        const filterList: any = []
        selectedKeys.forEach((item: any) => {
          data.forEach((element: any) => {
            if (item == element.id) {
              filterList.push(element)
            }
          })
        })
        // 计算合计
        const insurancePayAmountAll = sum(
          filterList?.map((v: any) =>
            v?.insurancePayAmount > -1 ? Number(v?.insurancePayAmount) : 0
          )
        )
        const realPayAmountAll = sum(
          filterList?.map((v: any) =>
            v?.realPayAmount > -1 ? Number(v?.realPayAmount) : 0
          )
        )
        const amountAll = sum(
          filterList?.map((v: any) => (v?.amount > -1 ? Number(v?.amount) : 0))
        )
        const qfssAmount = filterList?.map((v: any) =>
          Number(
            v?.accountingStatus == 0
              ? 0
              : (v?.realPayAmount || 0) - (v?.arrearageAmount || 0)
          )?.toFixed(2)
        )
        const qfssAmountAll = sum(qfssAmount?.map((v: any) => Number(v)))

        const arrearageAmountAll = sum(
          filterList?.map((v: any) =>
            v?.accountingStatus === 0
              ? 0
              : v?.arrearageAmount > -1
              ? Number(v?.arrearageAmount)
              : 0
          )
        )
        setTotalDataSettled({
          insurancePayAmountAll: insurancePayAmountAll,
          realPayAmountAll: realPayAmountAll,
          amountAll: amountAll,
          qfssAmountAll: qfssAmountAll,
          arrearageAmountAll: arrearageAmountAll,
        })
      } else {
        setTotalDataSettled({
          insurancePayAmountAll: 0.0,
          realPayAmountAll: 0.0,
          amountAll: 0.0,
          qfssAmountAll: 0.0,
          arrearageAmountAll: 0.0,
        })
      }
    } else {
      const data = page?.items
      if (data?.length) {
        // 没勾选的情况下计算全部

        const insurancePayAmountAll = sum(
          data?.map((v: any) =>
            v?.insurancePayAmount > -1 ? Number(v?.insurancePayAmount) : 0
          )
        )
        const realPayAmountAll = sum(
          data?.map((v: any) =>
            v?.realPayAmount > -1 ? Number(v?.realPayAmount) : 0
          )
        )
        const amountAll = sum(
          data?.map((v: any) => (v?.amount > -1 ? Number(v?.amount) : 0))
        )
        const qfssAmount = data?.map((v: any) =>
          Number(
            v?.accountingStatus == 0
              ? 0
              : (v?.realPayAmount || 0) - (v?.arrearageAmount || 0)
          )?.toFixed(2)
        )
        const qfssAmountAll = sum(qfssAmount?.map((v: any) => Number(v)))

        const arrearageAmountAll = sum(
          data?.map((v: any) =>
            v?.accountingStatus === 0
              ? 0
              : v?.arrearageAmount > -1
              ? Number(v?.arrearageAmount)
              : 0
          )
        )

        setTotalDataSettled({
          insurancePayAmountAll: insurancePayAmountAll,
          realPayAmountAll: realPayAmountAll,
          amountAll: amountAll,
          qfssAmountAll: qfssAmountAll,
          arrearageAmountAll: arrearageAmountAll,
        })
      } else {
        setTotalDataSettled({
          insurancePayAmountAll: 0.0,
          realPayAmountAll: 0.0,
          amountAll: 0.0,
          qfssAmountAll: 0.0,
          arrearageAmountAll: 0.0,
        })
      }
    }
  }, [page, selectedKeys])

  const refondSubmit = (id: any) => {
    setButtonLoading(true)
    dispatch(refundPayment(id))
      .then(unwrapResult)
      .then(() => {
        notification.success({ message: '退费完成' })
        setButtonLoading(false)
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
        setRefund(undefined)
        dispatch(getPaymentList(params))
        sibling?.reload()
      })
      .catch(() => {
        setButtonLoading(false)
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
      })
  }

  useEffect(() => {
    setTotalData(page?.items?.[0])
  }, [page])

  const getPaymentListData = () => {
    dispatch(getPaymentList(params))
      .then(unwrapResult)
      .then((res: any) => {
        onResize() // 初始化设置高度
        window.addEventListener('resize', onResize)
        // if (params.param?.length == 9 && res.items.length == 1) {
        //   //获取焦点好病历号自动跳转
        //   tableDoubleFn(res.items[0])
        // }
      })
      .finally(() => setPageLoading(false))
  }
  useEffect(() => {
    if (params.current !== 0) {
      setPageLoading(true)
      getPaymentListData()
    }
  }, [params])

  useEffect(() => {
    params.current &&
      sessionStorage.setItem(location.pathname, JSON.stringify({ ...params }))
  }, [params, status])

  useImperativeHandle(ref, () => ({
    reload: () => {
      dispatch(getPaymentList(params))
    },
  }))

  useEffect(() => {
    if (!tableId) return
    result &&
      dispatch(getPaymentDetail(tableId))
        .then(unwrapResult)
        .then((refund) => {
          setRefund(refund)
        })
  }, [result])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  const doRefund = (cardType?: string, fullCardInfo?: string) => {
    const payMethodList = refund?.payMethod?.toString()?.split(',')
    const isJuHe: any = payMethodList?.find((v: any) => {
      return v == 2
    })
    if (isJuHe) {
      //是否聚合支付退费
      dispatch(
        JuhePremium({
          treatmentId: refund?.treatmentId?.toString() || '',
          paymentId:refund?.id || ''
        })
      )
        .then(unwrapResult)
        .then(() => {
          setButtonLoading(false)
          setStkButtonLoading(false)
          setDzpzButtonLoading(false)
          notification.success({ message: '聚合支付退费成功' })
          if (refund) {
            if (refund.insuranceCode !== 'ZIFEI') {
              cancelSettlement(
                'requestString',
                refund.id,
                cardType,
                fullCardInfo
              )
            } else {
              refondSubmit(refund.id)
            }
          }
        })
        .catch((v) => {
          setButtonLoading(false)
          setStkButtonLoading(false)
          setDzpzButtonLoading(false)
        })
    } else {
      if (refund) {
        if (refund.insuranceCode !== 'ZIFEI') {
          cancelSettlement('requestString', refund.id, cardType, fullCardInfo)
        } else {
          refondSubmit(refund.id)
        }
      }
    }
  }

  const validCardInfoBeforeGoRefund = (type: string) => {
    //实体卡读卡校验
    if (type == '1') {
      setStkButtonLoading(true)
      //部分强制读卡的地区，跳过校验
      const tradeType = getTradeName()
      if (tradeType === 'CQ_ZHYB') {
        setLoading(false)
        doRefund()
        return
      }
      readCardSHttp(
        {
          trade_args: [getTradeCode(tenantAreaCd.slice(0, 4))],
        },
        (data, err) => {
          setLoading(false)
          if (!err) {
            //其他地区需要做二次读卡校验
            const Name =
              tradeType === 'JS_ZHYB'
                ? data?.netmessage
                    ?.split('|')[4]
                    ?.replace('\r', '')
                    .replace('\t', '')
                    .replace(' ', '')
                    .trim()
                : data?.netmessage?.Name
            if (Name != refund?.patientName) {
              Modal.error({
                title: '提示',
                content:
                  `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                  refund?.patientName +
                  '，卡片姓名：' +
                  data?.netmessage.Name,
                okText: '确认',
              })
              setStkButtonLoading(false)
            } else {
              doRefund(type, data?.netmessage)
            }
          }
        }
      )
      //电子凭证读卡校验
    } else if (type == '2') {
      setDzpzButtonLoading(true)
      setTimeout(() => {
        setDzpzButtonLoading(false)
      }, 3000)
      readCardEHttp(
        {
          trade_args: [
            baseData?.countryHospitalNb,
            '01301',
            '',
            userId,
            userName,
            '',
            '',
          ],
        },
        (data, err) => {
          if (!err) {
            // {"netcode":"1","netmessage":"徐春霞|330621198804253802|01|330000ecaqm4mb0mvk0b8fab0a0000236f0d3c|330182|","QrCode":"","ChnlId":""}
            const readName = data?.netmessage?.split('|')[0]
            if (readName != refund?.patientName) {
              Modal.error({
                title: '提示',
                content:
                  `退费信息与卡片信息不一致，请确认是否插错卡，就诊人：` +
                  refund?.patientName +
                  '，电子凭证姓名：' +
                  readName,
                okText: '确认',
              })
              setDzpzButtonLoading(false)
            } else {
              doRefund(type, data?.netmessage)
            }
          }
        }
      )
      //自费直接调用退费
    } else if (type == '3') {
      doRefund()
      //自费直接调用退费
    } else if (type == '0') {
      doRefund()
    } else {
      Modal.error({
        title: '错误',
        content: `未知的退费类型` + type + `请联系客服人员处理`,
        okText: '确认',
      })
      //11
    }
  }

  const cancelSettlement = (
    serverType: any,
    paymentId?: string,
    cardType?: string,
    fullCardInfo?: string,
    responseString?: string
  ) => {
    const insuranceItem = insuranceArray.find(
      (v: any) => v.insuranceCode === refund?.insuranceCode
    )
    dispatch(
      cancelSettlementAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          exp_content: '',
          treatmentId: refund?.treatmentId,
          paymentId,
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
              notification: '退费',
            },
            (call: any, err) => {
              if (!err) {
                cancelSettlement(
                  'response',
                  paymentId,
                  cardType,
                  fullCardInfo,
                  JSON.stringify(call.netmessage)
                )
              }
              setStkButtonLoading(false)
              setDzpzButtonLoading(false)
            }
          )
        } else {
          //调用本地退费
          refondSubmit(refund?.id)
          if (cardType == '1') {
            //实体卡
            getPatientInfo(
              '3',
              baseData?.insuranceCode == 'JIANGSU'
                ? String(fullCardInfo)
                : JSON.stringify(fullCardInfo),
              'requestString'
            )
          } else if (cardType == '2') {
            //电子凭证
            getPatientInfo('1', String(fullCardInfo), 'requestString')
          }
          // resolve()
          // submit(vs, undefined, res)
        }
      })
      .catch((v) => {
        setStkButtonLoading(false)
        setDzpzButtonLoading(false)
      })
  }

  // 获取参保人信息
  const getPatientInfo = (
    cardType: string,
    fullCardInfo: string,
    serverType: any,
    responseString?: string
  ) => {
    dispatch(
      getPatientInfoAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          cardType: String(cardType),
          fullCardInfo,
          otherMap: {},
        },
      })
    )
      .then(unwrapResult)
      .then(async (res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          await medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                insuranceItem.countryHospitalNb,
                insuranceItem.secretToken,
              ],
            },
            (call: any, err) => {
              if (!err) {
                message.loading('正在更新人员信息')
                getPatientInfo(
                  cardType,
                  fullCardInfo,
                  'response',
                  JSON.stringify(call.netmessage)
                )
                // }
              }
            }
          )
        } else {
          message.success('人员信息已更新')
        }
      })
      .catch((ex) => {
        notification.error({
          message: ex.message,
          duration: 3,
        })
      })
  }

  // 医保自费上传
  const selfPayUpload = (
    serverType: serverType,
    treatmentId: number,
    responseString?: string
  ) => {
    dispatch(
      selfPayUploadAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          treatmentId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '自费上传',
            },
            (call: any, err) => {
              !err &&
                selfPayUpload(
                  'response',
                  treatmentId,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          dispatch(getPaymentList(params))
        }
      })
  }

  // 医保自费上传撤销
  const selfPayRepeal = (
    serverType: serverType,
    treatmentId: number,
    responseString?: string
  ) => {
    dispatch(
      selfPayRepealAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          treatmentId,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '自费撤销上传',
            },
            (call: any, err) => {
              !err &&
                selfPayRepeal(
                  'response',
                  treatmentId,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          dispatch(getPaymentList(params))
        }
      })
  }

  const success = (str: string) => {
    const hide = message.loading(str, 0)
    setTimeout(() => {
      hide()
    }, 3000)
  }

  // 2204
  const FeeListUp = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      FeeListUpAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          registerFlag: 1,
          treatmentId: Number(registerData?.treatmentId),
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setUpdateFlags('1', '0')
        if (serverType === 'requestString') {
          success('正在上传费用')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                setUpdateFlags('1', '1')
                setLoading(false)
              } else {
                setUpdateFlags('1', '0'),
                  FeeListUp('response', JSON.stringify(call.netmessage))
              }
            },
            undefined,
            reject
          )
        } else {
          messageNotification('费用上传完毕')
          registerData && preSettlement('requestString', registerData)
          resolve && resolve()
        }
      })
      .catch(() => {
        resolve && resolve()
        if (serverType === 'response') {
          setUpdateFlags('1', '1')
        }
      })
  }

  // 2205
  const FeeListUpCancel = (
    serverType: any,
    responseString?: string,
    resolve?: any,
    reject?: any
  ) => {
    dispatch(
      FeeListUpCancelAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...insuranceItem,
            endTime: moment(insuranceItem.endTime).format(
              DateTimeFormatSimpleOnlyOne
            ),
            userName,
            userId,
            cycleCode,
            tenantCategory,
            tenantAreaCd,
            name: tenantName,
            address: tenantAddress,
            areaCode: tenantAreaCd,
            responseString: responseString ? responseString : null,
          },
          exp_content: '',
          chrg_bchno: '2',
          treatmentId: Number(registerData?.treatmentId),
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          success('门诊费用明细信息撤销中')
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
            },
            (call, err) => {
              if (err) {
                // setLoading(false)
              } else {
                // setUpdateFlags('2', '0')
                messageNotification('撤销成功')
                FeeListUp('requestString')
              }

              // FeeListUpCancel('response', JSON.stringify(call.netmessage))
            },
            undefined,
            reject
          )
        } else {
          FeeListUp('requestString')
          resolve && resolve()
        }
      })
      .catch(() => {
        setLoading(false)
        if (serverType === 'requestString') {
          setUpdateFlags('1', '1')
        }
        resolve && resolve()
      })
  }

  // 挂号费收取 2206
  const preSettlement = (
    serverType: serverType,
    treatmentData: registerValue,
    responseString?: string
  ) => {
    dispatch(
      preSettlementAsync({
        serverType: serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          chrg_bchno: '2',
          exp_content: '',
          treatmentId: treatmentData?.treatmentId,
          patientId: String(treatmentData?.patientId),
          registrationId: String(treatmentData?.registrationId),
        },
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              // message: '正在挂号费预结算',
            },
            (call, err) => {
              !err &&
                preSettlement(
                  'response',
                  treatmentData,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          registerPayment(res)
        }
      })
  }

  const setUpdateFlags = (insuranceFlag: string, updateFlag: string) => {
    if (insuranceFlag === '1' && updateFlag === '1') {
      setLoading(false)
    }
    dispatch(
      setUpdateFlagsAsync({
        treatmentId: registerData?.treatmentId,
        insuranceFlag,
        updateFlag,
      })
    ).then(unwrapResult)
    // .then(getTreatmentsDetail)
  }

  const messageNotification = async (str: string) => {
    await message.destroy()
    notification.success({
      message: str,
      duration: 1,
    })
  }

  const doPay = (doPayparams: any, insuranceTradeId?: string) => {
    dispatch(pay({ ...doPayparams, insuranceTradeId, settlementCategory: 1 }))
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: '挂号费支付成功',
          duration: 1.5,
        })
        setIsModalVisible(false)
        dispatch(getPaymentList(params))
      })
      .catch(() => {
        setIsModalVisible(false)
      })
      .finally(() => {
        setConfirmation(undefined)
        setRegisterData(undefined)
      })
  }
  const registerPayment = (prePay?: any) => {
    setConfirmation({
      ...confirmation,
      prePay,
    } as PaymentConfirmation)

    refModal.current?.reset()
    setIsModalVisible(true)
  }

  // useEffect(() => {
  //   if (tableDoubleFnData) {
  //       tableDoubleFn(tableDoubleFnData)
  //   }
  // }, [tableDoubleFnData])

  // 表格双击跳转
  const tableDoubleFn = (record: any) => {
    if (YBZFUploadflag) return
    if (record.settlementCategory === 1) {
      const data = {
        patient: record.treatment.patientName,
        amount: record.amount,
        // prePay,
        ids: record.recipeId,
        slots: [
          {
            name: '挂号费',
            value: record.amount,
            children: [
              {
                name: '挂号费',
                value: record.amount,
                children: [],
              },
            ],
          },
        ],
        errorMaterials: [],
      }
      const preSettlementParams: registerValue = {
        treatmentId: Number(record.treatment?.id || 0),
        patientId: Number(record.treatment?.patientId || 0),
        registrationId: Number(record.registration?.id || 0),
        recipeId: record.recipeId,
      }
      setConfirmation(data)
      setRegisterData(preSettlementParams)
      return
    }
    if (status !== RecipeStatus.ToSettle) {
      dispatch(
        traceRoute({
          name: '收费',
          path: '/payment/result',
          query: `paymentId=${record.id}&status=${status}&selectedTab=${selectedTab}`,
          state: {
            name: status === RecipeStatus.Settled ? '已收费' : '已退费',
            path: '/payment',
            routeName: '收费',
          },
        })
      )
    } else {
      dispatch(
        traceRoute({
          name: '收费',
          path: '/payment/cart',
          query: `treatmentId=${record.treatment.id}`,
        })
      )
    }
  }
  useEffect(() => {
    if (confirmation && registerData) {
      // preSettlement('requestString', registerData)
      FeeListUpCancel('requestString', undefined, undefined, undefined)
    }
  }, [registerData])

  const outCluedExport = () => {
    dispatch(getPaymentListExoprt({ ...params, size: 10000 }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '已收费.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  const onResize = () => {
    const table = document.getElementById(
      status == RecipeStatus.ToSettle
        ? 'tableHeightPaymentToSettle'
        : status == RecipeStatus.Settled
        ? 'tableHeightPaymentSettle'
        : status == RecipeStatus.Returned
        ? 'tableHeightPaymentReturned'
        : ''
    )
    if (table) {
      const height = table.offsetHeight - 60 - 80 - 20 - 20
      setScrollHeight(height)
    } else {
      return '100%'
    }
  }
  const columns =
    status === RecipeStatus.ToSettle ? PaymentColumns : PaymentColumnsWithAmount
  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)',
        marginTop: '10px',
        overflow: 'hidden',
        padding: '10px 15px 0 15px',
      }}
    >
      <PaymentQuery
        outExportExcel={() => {
          outCluedExport()
        }}
        onValueChange={(v) => {
          setSelectedKeys([])
          if (selectedTab == '0' || selectedTab == '1' || selectedTab == '2') {
            setParams({
              ...params,
              ...v,
              actionTimeHead: null,
              actionTimeTail: null,
              payMethod: null,
            })
          } else {
            setParams({ ...params, ...v })
          }
        }}
        status={status}
        selectedTab={selectedTab}
        YBZFUploadflag={YBZFUploadflag}
      />
      <div
        id={
          status == RecipeStatus.ToSettle
            ? 'tableHeightPaymentToSettle'
            : status == RecipeStatus.Settled
            ? 'tableHeightPaymentSettle'
            : status == RecipeStatus.Returned
            ? 'tableHeightPaymentReturned'
            : ''
        }
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <EditableList<Payment>
          // sticky
          // style={{ overflow: 'hidden' }}
          style={{
            width: '100%',
            height: '89%',
            overflow: 'hidden',
          }}
          scroll={{
            x: '33rem',
            y: page?.items?.length > 0 ? Number(scrollHeight) : undefined,
          }}
          loading={pageLoading}
          page={page}
          rowKey={(m, index) =>
            status == RecipeStatus.ToSettle ? Number(index) : m.id
          }
          columns={columns(
            status,
            page.size * (page.current - 1),
            (action, id, t, settlementCategory) => {
              switch (action) {
                case 'pay':
                  if (settlementCategory === 1) {
                    // return
                    const data = {
                      patient: t.treatment.patientName,
                      amount: t.amount,
                      // prePay,
                      ids: t.recipeId,
                      slots: [
                        {
                          name: '挂号费',
                          value: t.amount,
                          children: [
                            {
                              name: '挂号费',
                              value: t.amount,
                              children: [],
                            },
                          ],
                        },
                      ],
                      errorMaterials: [],
                    }
                    const preSettlementParams = {
                      treatmentId: t.treatment?.id,
                      patientId: t.treatment?.patientId,
                      registrationId: t.registration?.id,
                      recipeId: t.recipeId,
                    }
                    setConfirmation(data)
                    setRegisterData(preSettlementParams)
                  } else {
                    // &&oweMoneyNum=${JSON.stringify(res)}
                    dispatch(
                      traceRoute({
                        name: '收费',
                        path: '/payment/cart',
                        query: `treatmentId=${id}&phone=${t.registration.patientPhone}`,
                      })
                    )
                  }
                  break
                case 'return':
                  setTableId(t?.id)
                  if (t.potionRecipeStatus !== 1) {
                    dispatch(getPaymentResult(t?.id)) //获取预退费表格信息
                    // dispatch(getPaymentDetail(t?.id))
                    //   .then(unwrapResult)
                    //   .then((refund) => {
                    //     setRefund(refund)
                    //   })
                  } else {
                    setIsReplaceMakeVisible(true)

                    setRefundParams(t)
                  }
                  break
                case 'detail':
                  dispatch(
                    traceRoute({
                      name: '收费',
                      path: '/payment/result',
                      query: `paymentId=${id}&status=${status}&selectedTab=${selectedTab}`,
                      state: {
                        name:
                          status === RecipeStatus.Settled ? '已收费' : '已退费',
                        path: '/payment',
                        routeName: '收费',
                      },
                    })
                  )
                  break
                case 'upload':
                  selfPayUpload('requestString', t.treatment.id)
                  break
                case 'repeal':
                  selfPayRepeal('requestString', t.treatment.id)
                  break
                case 'supplementary':
                  dispatch(
                    traceRoute({
                      name: '补交',
                      path: '/payment/oweMoney',
                      query: `paymentId=${id}`,
                    })
                  )
                  break
              }
            },
            YBZFUploadflag
          )}
          rowSelection={
            status == RecipeStatus.Settled
              ? ({
                  selectedRowKeys: selectedKeys,
                  ...rowSelection,
                } as TableRowSelection<any>)
              : (null as any)
          }
          onChangePage={(current, size) => {
            setSelectedKeys([])
            setParams({
              ...params,
              current,
              size,
            })
          }}
          onRow={(record) => ({
            onDoubleClick: () => {
              // setTableDoubleFnData(record)
              tableDoubleFn(record)
            },
          })}
          summary={() =>
            status === RecipeStatus.Settled ? (
              <Table.Summary fixed>
                <Table.Summary.Row className={styles.total}>
                  <Table.Summary.Cell
                    index={0}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    align='center'
                    className={styles.fixed}
                  >
                    合计
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={3}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={4}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={5}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={6}
                    className={styles.fixed}
                    align='right'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={7}
                    className={styles.fixed}
                    align='right'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={8}
                    className={styles.fixed}
                    align='right'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={9}
                    className={styles.fixed}
                    align='right'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={10}
                    className={styles.fixed}
                    align='right'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={11}
                    className={styles.fixed}
                    align='right'
                  >
                    {totalDataSettled?.insurancePayAmountAll?.toFixed(2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={12}
                    className={styles.fixed}
                    align='right'
                  >
                    {totalDataSettled?.realPayAmountAll?.toFixed(2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={13}
                    className={styles.fixed}
                    align='right'
                  >
                    {totalDataSettled?.amountAll?.toFixed(2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={14}
                    className={styles.fixed}
                    align='right'
                  >
                    {totalDataSettled?.arrearageAmountAll?.toFixed(2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    className={styles.fixed}
                    index={15}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    className={styles.fixed}
                    index={16}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    className={styles.fixed}
                    index={17}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    className={styles.fixed}
                    index={18}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={19}
                    align='right'
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={20}
                    className={styles.fixed}
                    align='right'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={21}
                    className={styles.fixed}
                    align='right'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={22}
                    className={styles.fixed}
                    align='right'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={23}
                    className={styles.fixed}
                    align='right'
                  ></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            ) : status === RecipeStatus.Returned ||
              status === RecipeStatus.ToSettle ? (
              <Table.Summary fixed>
                <Table.Summary.Row className={styles.total}>
                  <Table.Summary.Cell
                    index={0}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    align='center'
                    className={styles.fixed}
                  >
                    合计
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={12}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={3}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={4}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={5}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={6}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={7}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={8}
                    className={styles.fixed}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={9}
                    className={styles.fixed}
                    align='right'
                  >
                    {status !== RecipeStatus.ToSettle
                      ? totalData?.totalReimbursementAmount?.toFixed(2)
                      : totalData?.totalPrice?.toFixed(2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={10}
                    className={styles.fixed}
                    align='right'
                  >
                    {status !== RecipeStatus.ToSettle &&
                      totalData?.totalRealPayAmount?.toFixed(2)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={11}
                    className={styles.fixed}
                    align='right'
                  >
                    {status !== RecipeStatus.ToSettle &&
                      totalData?.totalPrice?.toFixed(2)}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell
                    index={12}
                    align='right'
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={13}
                    align='right'
                  ></Table.Summary.Cell>
                  {status !== RecipeStatus.ToSettle && (
                    <>
                      <Table.Summary.Cell
                        index={14}
                        className={styles.fixed}
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={15}
                        className={styles.fixed}
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={16}
                        className={styles.fixed}
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={17}
                        className={styles.fixed}
                      ></Table.Summary.Cell>
                    </>
                  )}
                </Table.Summary.Row>
              </Table.Summary>
            ) : (
              ''
            )
          }
        />
      </div>

      {/* </div> */}
      {/* 挂号费收取弹窗 */}
      <Modal
        title='预收挂号费'
        width={900}
        cancelText='取消'
        okText='确认'
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
          setUpdateFlags('0', '0')
        }}
        onOk={() => {
          refModal.current?.submit()
        }}
        bodyStyle={{ padding: 0 }}
      >
        <PaymentConfirm
          ref={refModal}
          confirmation={confirmation}
          YUSHOUGUAHAOFEI={true}
          YUSHOUtreatment={registerData}
          isPayPage={true}
          onPay={(callParams) => {
            setUpdateFlags('0', '0')
            if (callParams.success == '1') {
              notification.success({
                message: '挂号费支付成功',
                duration: 1.5,
              })
              setIsModalVisible(false)
              dispatch(getPaymentList(params))
            } else {
              setIsModalVisible(false)
            }
            setConfirmation(undefined)
            setRegisterData(undefined)
          }}
        />
      </Modal>
      {/* 预退费弹窗 */}
      <RealPayModal
        buttonLoading={buttonLoading}
        stkButtonLoading={stkButtonLoading}
        dzpzButtonLoading={dzpzButtonLoading}
        refund={refund}
        onOK={(type) => {
          validCardInfoBeforeGoRefund(type)
        }}
        onCancel={() => setRefund(undefined)}
        resultData={result?.paymentDetailRecordVOS}
      />

      <Modal
        closable={false}
        maskClosable={false}
        cancelText='不撤销'
        okText='撤销'
        visible={isReplaceMakeVisible}
        title=''
        onOk={() => {
          setIsReplaceMakeVisible(false)
          dispatch(
            isCancelUpload({
              treatmentId: refundParams!.registration.treatmentId,
              potionRecipeStatus: 3,
              paymentId: refundParams!.id,
            })
          )
            .then(unwrapResult)
            .then(() => {
              dispatch(getPaymentDetail(refundParams!.id))
                .then(unwrapResult)
                .then((refund) => {
                  // setRefund(refund)
                  dispatch(getPaymentResult(tableId)) //获取预退费表格信息
                })
            })
        }}
        onCancel={() => {
          setIsReplaceMakeVisible(false)
          setSureReplaceMakeVisible(true)
        }}
      >
        本单已传至代煎方，需要撤销代煎吗？
      </Modal>
      <Modal
        visible={sureReplaceMakeVisible}
        title=''
        onOk={() => {
          setSureReplaceMakeVisible(false)
          dispatch(getPaymentDetail(refundParams!.id))
            .then(unwrapResult)
            .then((refund) => {
              // setRefund(refund)
              dispatch(getPaymentResult(tableId)) //获取预退费表格信息
            })
        }}
        onCancel={() => {
          setSureReplaceMakeVisible(false)
        }}
      >
        退费时不撤销订单可能带来损失，确认不需要撤销代煎吗？
      </Modal>
    </Col>
  )
}

export const Payments = forwardRef(RealPayments)
