import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch } from "../../../../app/store";
import {
  getStorage,
  selectStorage,
} from "../../../durgsystem/durgsystemSlice";
import {
  setCategory,
} from "../../../putstorage/putstorageSlice";
import { NavBar } from "../../../../compnents/nav/NavBar";
import styles from "./stockMaintenance.module.css";
import moment from "moment";
import {
  getAllUserName,
  sleectUserData,
} from "../../../putstorelist/putstorelistSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { User } from "../../../../models/user";
import {
  getStockMaintenanceItem,
  selectStockMaintenanceDetail,
  selectStockMaintenanceId,
  setDetail,
  setId,
  submitStockMaintenanceAsync,
} from "./stockMaintenanceSlice";
import { useLocation } from "react-router-dom";
import {
  DateTimeFormat,
} from "../../../../models/datetime";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const NewEditor = () => {
  const { Option } = Select;

  const [form] = Form.useForm();

  const location: any = useLocation();

  const dispatch = useDispatch<RootDispatch>();

  const [title, setTitle] = useState("新增");

  const userData = useSelector(sleectUserData);

  const durgsystemData = useSelector(selectStorage);

  const selectId = useSelector(selectStockMaintenanceId);

  const detail = useSelector(selectStockMaintenanceDetail);

  const history = useHistory();

  useEffect(() => {
    dispatch(getStorage({ current: 1, size: 1000 }));
    dispatch(getAllUserName({ current: 1, size: 1000 }));
    form.setFieldsValue({
      maintainTime: moment(),
    });
  }, []);

  useEffect(() => {
    if (location.state) {
      setTitle("修改");
      dispatch(getStockMaintenanceItem(location.state.selectId));
    } else {
      setTitle("新增");
      form.setFieldsValue({
        maintainCategory: 0,
      });
    }
  }, [location]);

  useEffect(() => {
    if (JSON.stringify(detail) !== "{}") {
      form.setFieldsValue({
        ...detail,
        maintainTime: moment(detail.maintainTime),
        mainUserId: detail.mainUserId?.toString(),
      });
    }
  }, [detail]);

  useEffect(() => {
    return () => {
      dispatch(setDetail({}));
      form.resetFields();
    };
  }, []);

  useEffect(() => {
    if (selectId) {
      history.push({
        pathname: "/additionDrug",
        state: {
          selectId,
        },
      });
    }
  }, [selectId]);

  useEffect(() => {
    return () => {
      dispatch(setId(0));
    };
  }, []);

  return (
    <>
      <NavBar
        style={{ margin: "10px 20px 0px 20px" }}
        where={["库存", "库存养护"]}
        backtrace={{
          name: "养护列表",
          path: "/stockMaintenance",
          state: {
            station: "2",
          },
        }}
        buttons={<></>}
      />
      <div
        style={{
          height: "calc(100% - 30px)",
          margin: "10px 20px 0 20px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          background: "#FFFFFF",
          borderRadius: "10px",
          overflowY: "scroll",
        }}
      >
        <div className={styles.title}>
          <p className={styles.titletext}>{title + "库存养护单据"}</p>
        </div>
        <Form
          form={form}
          name="basic"
          autoComplete="off"
          {...layout}
          onFinish={(vs) => {
            const storehouseName =
              (durgsystemData.find(
                (d: any) => d.storehouseId === vs.storehouseId
              ) as any)?.storehouseName || "";
            const mainUserName =
              (userData.find((u: any) => u.id === vs.mainUserId) as any)
                ?.name || "";
            if (location.state) {
              dispatch(
                submitStockMaintenanceAsync({
                  ...vs,
                  storehouseName,
                  mainUserName,
                  maintainTime: moment(vs.maintainTime).format(DateTimeFormat),
                  id: location.state.selectId,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  if (location.state.location) {
                    history.push("/stockMaintenance");
                  }
                });
            } else {
              dispatch(
                submitStockMaintenanceAsync({
                  ...vs,
                  storehouseName,
                  mainUserName,
                  maintainTime: moment(vs.maintainTime).format(DateTimeFormat),
                })
              );
            }
          }}
        >
          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            <Col span={12}>
              <Form.Item
                name="storehouseId"
                label="库房名称"
                rules={[{ required: true, message: "库房列表不能为空!" }]}
              >
                <Select
                  placeholder="库房名称"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onSelect={(key: any) => {
                    dispatch(setCategory(key));
                  }}
                  disabled={location.state ? true : false}
                >
                  {durgsystemData.map((v: any, i) => {
                    return (
                      <Option value={v.storehouseId} key={i}>
                        {v.storehouseName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="maintainTime"
                label="业务日期:"
                rules={[{ required: true, message: "业务日期不能为空!" }]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="请选择业务日期"
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            <Col span={12}>
              <Form.Item
                label="养护人员"
                name="mainUserId"
                rules={[{ required: true, message: "养护人员不能为空!" }]}
              >
                <Select>
                  {userData.map((v: User) => {
                    return (
                      <Option value={v.id} key={v.id}>
                        {v.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} style={{ height: "60px" }}>
              <Form.Item
                label="养护等级"
                name="maintainCategory"
                rules={[{ required: true, message: "养护等级不能为空!" }]}
              >
                <Select
                  disabled={location.state ? true : false}
                  defaultValue={0}
                >
                  <Option value={0}>一般养护</Option>
                  <Option value={1}>重点养护</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            <Col span={24}>
              <Form.Item
                name="remark"
                label="单据备注"
                rules={[{ max: 256, message: "最大256位!" }]}
                labelCol={{
                  span: 2,
                }}
                wrapperCol={{
                  span: 22,
                }}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Button
                type="primary"
                style={{
                  float: "right",
                }}
                onClick={() => {
                  form.submit();
                }}
              >
                保存
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType="reset"
                onClick={() => {
                  dispatch(setDetail({}));
                  form.resetFields();
                  history.go(-1);
                }}
                style={{
                  float: "left",
                }}
              >
                取消
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
