import { Button, Popconfirm, Space, TableColumnType } from "antd";
import React, { ReactElement, useState } from "react";
import { getAppointTypeType } from "../../../models/appointment";
import { getInvoiceStatus } from "../../../models/payment";
import {
  getIllnessDiagType,
  getIllnessName,
  getIllnessSource,
} from "../../../models/peopleSales";
import { getGenderName } from "../../../models/user";
import { setPatientPageId } from "../../registration/register/registerSlice";
import { traceRoute } from "../../../layouts/layoutSlice";
import { getRecipeCategoryName } from "../../../models/recipe";

type Action = "action" | "edit" | "setting";

export const Columns = (
  startIndex: number,
  onAction: (action: Action, t: any) => void
): TableColumnType<any>[] => {
  // const [a, sets] = useState(false)
  return [
    {
      title: "序号",
      key: "no",
      align: "center",
      width: "4rem",
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: "病人姓名",
      dataIndex: "patientName",
      // width: "10rem",
      align: "center",
    },
    {
      title: "处方类型",
      dataIndex: "category",
      // width: "10rem",
      align: "center",
      render: (_, t) => getRecipeCategoryName(t.category),
    },
    {
      title: "疾病名称",
      dataIndex: "disease",
      // width: "10rem",
      align: "center",
      render: (_, t) => t.disease? t.disease : '-',
    },
    {
      title: "开单医生",
      dataIndex: "doctorName",
      // width: "10rem",
      align: "center",
      render: (_, t) => (t.doctorName ? t.doctorName : "-"),
    },
    {
      title: "主管科室",
      dataIndex: "departmentName",
      // width: "10rem",
      align: "center",
      render: (_, t) => (t.departmentName ? t.departmentName : "-"),
    },
    {
      title: "开单时间",
      dataIndex: "recipeTime",
      // width: "10rem",
      align: "center",
      render: (_, t) => (t.recipeTime ? t.recipeTime : "-"),
    },
    {
      title: "操作",
      dataIndex: "action",
      align: "center",
      render: function action(_, t) {
        return (
          <Space>
            <a onClick={() => onAction("action", t)}>处方查看</a>
          </Space>
        );
      },
    },
  ];
};
