/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:05
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-18 11:46:41
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Col } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
// import styles from './AnnualCapitalFund.module.css'
import { Column, Columns } from './column'
import { Query } from './Query'
import { ThemeContext } from '../../theme/ThemeContext'
import { getDateRange, DateRangeType } from '../../compnents/form/DateSelect'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
} from '../../models/datetime'
import { getFeeLimitList } from './FeeLimitSlice'

interface FeeLimitProps {
  selectedTab?: any
}

const FeeLimit = ({ selectedTab }: FeeLimitProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [timeHead, timeTail] = getDateRange(DateRangeType.Today)

  const [params, setParams] = useState<any>({})

  const [selectedKeys, setSelectedKeys] = useState<any>([])

  const [page, setPage] = useState<any>()

  const [pages, setPages] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    if (selectedTab == '6') {
      if (params?.startTime) {
        setPageLoading(true)
        getPage()
        getLastYearPage()
      }
    }
  }, [selectedTab, params])

  const getPage = () => {
    dispatch(getFeeLimitList({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        if (res?.length) {
          setPage(
            res?.map((v: any) => {
              return {
                ...v,
                startTime: params?.startTime,
                endTime: params?.endTime,
              }
            })
          )
        } else {
          setPage([])
        }
        setPageLoading(false)
      })
      .catch(() => {
        setPageLoading(false)
      })
  }

  const getLastYearPage = () => {
    dispatch(
      getFeeLimitList({
        ...params,
        startTime: moment(params?.startTime)
          ?.subtract(1, 'year')
          .format(DateTimeFormatSimpleOnlyOne),
        endTime: moment(params?.endTime)
          ?.subtract(1, 'year')
          .format(DateTimeFormatSimpleOnlyOne),
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (res?.length) {
          setPages(
            res?.map((v: any) => {
              return {
                ...v,
                startTime: moment(params?.startTime)
                  ?.subtract(1, 'year')
                  ?.format(DateTimeFormatSimpleOnlyOne),
                endTime: moment(params?.endTime)
                  ?.subtract(1, 'year')
                  .format(DateTimeFormatSimpleOnlyOne),
              }
            })
          )
        } else {
          setPages([])
        }
        setPageLoading(false)
      })
      .catch(() => {
        setPageLoading(false)
      })
  }

  return (
    <Col
      style={{
        backgroundColor: theme.pn,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 10px)',
        overflow: 'hidden',
      }}
    >
      <Query
        onValueChange={(v: any) => {
          const paramData = {
            ...params,
            ...v,
            startTime: v?.timeHead
              ? moment(v?.timeHead).format(DateTimeFormatSimpleOnlyOne)
              : moment(timeHead).format(DateTimeFormatSimpleOnlyOne),
            endTime: v?.timeTail
              ? moment(v?.timeTail).format(DateTimeFormatSimpleOnlyOne)
              : moment(timeTail).format(DateTimeFormatSimpleOnlyOne),
            timeHead: undefined,
            timeTail: undefined,
          }
          setParams({
            ...paramData,
          })
        }}
      />
      <div style={{ flex: 1 }}>
        <EditableList
          rowKey={(v, i) => `${i}`}
          loading={pageLoading}
          pagination={false}
          page={{
            current: 1,
            size: 10,
            total: 0,
            items: page,
          }}
          columns={Column()}
          onChangePage={(current, size) => {
            setParams({
              ...params,
              current,
              size,
            })
          }}
        />
        <div style={{ margin: '10px 0' }}>前年同期数据</div>
        <EditableList
          rowKey={(v, i) => `${i}`}
          loading={pageLoading}
          pagination={false}
          page={{
            current: 1,
            size: 10,
            total: 0,
            items: pages,
          }}
          columns={Columns(params)}
          onChangePage={(current, size) => {
            setParams({
              ...params,
              current,
              size,
            })
          }}
        />
      </div>
    </Col>
  )
}

export const FeeLimits = forwardRef(FeeLimit)
