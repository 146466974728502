/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-06-23 17:48:32
 * @LastEditors: sj
 * @LastEditTime: 2022-08-08 15:49:10
 */
import React, {
  forwardRef,
  ReactElement,
  ReactNode,
  useImperativeHandle,
  useRef,
} from "react";
import { useReactToPrint } from "react-to-print";
import styles from "./PrintFrame.module.css";

interface PrintFrameProps {
  title?: string;
  subTitle?: string;
  children?: ReactNode;
  direction?: string // 横向纵向
}

export interface PrintFrameRef {
  print: () => void;
}

const RealPrintFrame = (
  { title, subTitle, children, direction = 'landscape' }: PrintFrameProps,
  ref: React.Ref<PrintFrameRef>
): ReactElement => {
  const frameRef = useRef<HTMLDivElement>(null);

  const handler = useReactToPrint({
    pageStyle: `
      @page {
        size: ${direction};
      }
    `,
    // size: landscape 横向
    // size: portrait  纵向
    content: () => frameRef.current,
  });

  useImperativeHandle(ref, () => ({
    print: () => {
      handler && handler();
    },
  }));

  return (
    <div ref={frameRef} className={styles.container}>
      {title && (
        <div
          style={{
            fontSize: 20,
            textAlign: "center",
          }}
        >
          {title}
        </div>
      )}
      {subTitle && (
        <div
          style={{
            fontSize: 16,
            textAlign: "center",
          }}
        >
          {subTitle}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

export const PrintFrame = forwardRef(RealPrintFrame);
