/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 13:35:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-09 11:20:24
 */
import { Col, Tabs } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TreatmentCost } from '../../sales/TreatmentCost/treatmentCost'
import { OutPatientCostStatisticsLists } from './outPatientCostStatistics/outPatientCostStatistics'
import { OutPatientRegistrationReportLists } from './outPatientRegistrationReport/outPatientRegistrationReport'
import styles from './outpatientReport.module.css'
import { ScheduleOfExpensesList } from './scheduleOfExpenses/scheduleOfExpenses'
import { useSelector } from 'react-redux'
import { selectThreeMenus } from '../../../layouts/layoutSlice'

export const OutpatientReport = (props: { oneTabKey: any, parentId: string }): ReactElement => {
  const [currentTab, setCurrentTab] = useState('0')

  const location: any = useLocation()

  const { TabPane } = Tabs

  const threeMenus = useSelector(selectThreeMenus)?.filter((v: any) => v.parentId == props?.parentId)

  const [flag, setFlag] = useState(true)

  console.log(threeMenus);
  

  const getKeys = (i: string) => {
    switch (i) {
      case '门诊挂号统计':
        return '0'
      case '诊疗费用统计':
        return '4'
      case '门诊费用统计':
        return '1'
      case '医生病人费用种类表':
        return '2'
      default:
        return '-'
    }
  }

  useEffect(() => {
    if (!flag) return
    const tab = getKeys(threeMenus[0]?.name)
    console.log(tab);
    if (threeMenus?.length) {
      setCurrentTab(tab)
      setFlag(false)
    } else {
      setCurrentTab('0')
    }
  }, [props?.parentId, props?.oneTabKey, threeMenus])


  return (
    <Col className={styles.main}>
      <Tabs
        className={styles.tabs}
        activeKey={currentTab}
        onChange={(t) => setCurrentTab(t)}
      >
        {threeMenus?.map((v: any) => {
          return (
            <>
              <TabPane key={getKeys(v?.name)} tab={v?.name}>
                {v?.name == '门诊挂号统计' ? (
                  <OutPatientRegistrationReportLists
                    tabKey={currentTab}
                    oneTabKey={props?.oneTabKey}
                  ></OutPatientRegistrationReportLists>
                ) : v?.name == '诊疗费用统计' ? (
                    <TreatmentCost
                      tabKey={currentTab}
                      oneTabKey={props?.oneTabKey}
                    ></TreatmentCost>
                ) : v?.name == '门诊费用统计' ? (
                    <OutPatientCostStatisticsLists
                      tabKey={currentTab}
                      oneTabKey={props?.oneTabKey}
                    ></OutPatientCostStatisticsLists>
                ) : v?.name == '医生病人费用种类表' ? (
                    <ScheduleOfExpensesList tabKey={currentTab} oneTabKey={props?.oneTabKey} />
                ) : (
                  <></>
                )}
              </TabPane>
            </>
          )
        })}

        {/* <TabPane tab='门诊挂号统计' key='0'>
          <OutPatientRegistrationReportLists
            tabKey={currentTab}
            oneTabKey={props?.oneTabKey}
          ></OutPatientRegistrationReportLists>
        </TabPane>
        <TabPane tab='诊疗费用统计' key='4'>
          <TreatmentCost
            tabKey={currentTab}
            oneTabKey={props?.oneTabKey}
          ></TreatmentCost>
        </TabPane>
        <TabPane tab='门诊费用统计' key='1'>
          <OutPatientCostStatisticsLists
            tabKey={currentTab}
            oneTabKey={props?.oneTabKey}
          ></OutPatientCostStatisticsLists>
        </TabPane>
        <TabPane tab='医生病人费用种类表' key='2'>
          <ScheduleOfExpensesList tabKey={currentTab}   oneTabKey={props?.oneTabKey}></ScheduleOfExpensesList>
        </TabPane> */}
      </Tabs>
    </Col>
  )
}
