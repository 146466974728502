/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
 * @Description:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-10 09:32:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 18:04:36
 */
import { unwrapResult } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from 'antd'
import styles from '../directory.module.css'
import { MedicalListBody } from '../../../services/commodity'
import { List } from '../../../models/page'
import { EditableList } from '../../../compnents/list/EditableList'
import {
  getDirectoryUpload,
  getInsuranceOperationListAsync,
  getLimitDirectoryUpload,
  getMedicalListAsync,
} from '../../commodity/commoditySlice'
import { directoryCategoryList } from '../../../models/insuranceDirectory'
import moment from 'moment'
import {
  selectInsuranceItem,
  setInsurance,
} from '../../../layouts/insuranceSlice'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../../app/applicationSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../../models/datetime'
import { serverType } from '../../../services/commodity'
import { medicalSendHttp, strParse } from '../../../utils/MedicalUtils'
import { number } from 'echarts'
import { InsuranceOperationColumn } from './InsuranceOperationColumn'

function InsuranceOperation() {
  const dispatch = useDispatch<RootDispatch>()

  // form 表单初始化
  const [form] = Form.useForm()
  const [limitModalForm] = Form.useForm()

  const [modalForm] = Form.useForm()
  // 表格参数
  const [queries, setQueries] = useState<MedicalListBody>({
    current: 1,
    size: 10,
    // itemNameKeyWorld: componentItem?.name,
  })
  const [data, setData] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [modalBtnLoading, setModalBtnLoading] = useState(false) //弹窗 loading

  const [lnsuranceModalVisible, setLnsuranceModalVisible] = useState(false) //目录下载弹窗

  const [limitlnsuranceModalVisible, setLimitLnsuranceModalVisible] = useState(
    false
  ) //限价目录下载弹窗

  const insuranceArray = useSelector(selectInsuranceArray)

  const baseData = useSelector(selectInsuranceItem)

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const tenantCategory = useSelector(selectTenantCategory)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const tenantAddress = useSelector(selectTenantAddress)

  useEffect(() => {
    getTable()
  }, [queries])
  // 获取表格数据
  const getTable = () => {
    setPageLoading(true)
    dispatch(
      getInsuranceOperationListAsync({
        ...queries,
      })
    )
      .then(unwrapResult)
      .then((res: any) => setData(res))
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  return (
    <div className={styles.wrap}>
      <Form
        form={form}
        initialValues={{ billingCategory: '01' }}
        onFinish={(values: any) => {
          setQueries({ ...queries, ...values, current: 1 })
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '42px',
          }}
        >
          <Col>
            <Space>
              <Form.Item name='oprnOprtName'>
                <Input
                  size='middle'
                  placeholder='手术操作名称关键词'
                  allowClear
                  prefix={<SearchOutlined />}
                />
              </Form.Item>
              <Form.Item name='oprnOprtCode'>
                <Input placeholder='搜索手术操作代码' allowClear />
              </Form.Item>
            </Space>
          </Col>
          <Col>
            <Space>
              <Button htmlType='submit' type='primary'>
                查询
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <EditableList
        loading={pageLoading}
        columns={InsuranceOperationColumn(() => {
          return
        }, (queries.size || 10) * ((queries.current || 1) - 1))}
        onChangePage={(current, size) => {
          setQueries({
            ...queries,
            current,
            size,
          })
        }}
        page={{
          size: queries.size || 10,
          current: queries.current || 1,
          total: data?.total || 0,
          items: data?.records || [],
        }}
        rowKey={(_, i) => `${i}`}
      />
    </div>
  )
}

export default InsuranceOperation
