/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-12-07 15:09:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-15 15:20:19
 */
import React from 'react'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import {
  ReconciliationListParams,
  reconciliationList,
  SelfMonthSummaryUploadBody,
  selfMonthSummaryUpload,
  SelfMonthSummaryUploadInfoBody,
  selfMonthSummaryUploadInfo,
  SelfMonthSummaryUploadInfoDeleteBody,
  selfMonthSummaryUploadInfoDelete,
  SelfMonthSummaryUploadInfoConfirmBody,
  selfMonthSummaryUploadInfoConfirm,
  SelfMonthReconciliationBody,
  selfMonthReconciliation,
  SelfMonthReconciliationInfoBody,
  selfMonthReconciliationInfo,
  SelfDaySummaryUploadBody,
  selfDaySummaryUpload,
  SelfDaySummaryUploadInfoBody,
  selfDaySummaryUploadInfo,
  SelfDaySummaryUploadInfoDeleteBody,
  selfDaySummaryUploadInfoDelete,
  SelfDaySummaryUploadInfoConfirmBody,
  selfDaySummaryUploadInfoConfirm,
  SelfDayReconciliationBody,
  selfDayReconciliation,
  SelfDayReconciliationInfoBody,
  selfDayReconciliationInfo,
  SettlementExpenseReportBody,
  SettlementExpenseReport,
  SettlementChargeReversalBody,
  SettlementChargeReversal,
  PaymentInformationQueryBody,
  PaymentInformationQuery,
  SelfDetailedReconciliationOfSettlementInfoBody,
  SelfDetailedReconciliationOfSettlementInfo,
  selfDetailedReconciliationOfSettlementInfoExport,
  SelfDetailedReconciliationOfSettlementInfoExportBody, NoCardRefundBody, NoCardRefund,
} from '../../services/reconciliation'

// 对账记录列表
export const getReconciliationAsync = createAsyncThunk<
  void,
  ReconciliationListParams,
  RootThunkApi
>('payment/getOweMoneyDetail', async (params, api) => {
  return sendAsync(reconciliationList(params), api)
})

// 月对账
// 汇总上传
export const selfMonthSummaryUploadAsync = createAsyncThunk<
  void,
  SelfMonthSummaryUploadBody,
  RootThunkApi
>('confirm/selfMonthSummaryUploadAsync', async (body, api) => {
  return sendAsync(selfMonthSummaryUpload(body), api)
})

// 上传查询
export const selfMonthSummaryUploadInfoAsync = createAsyncThunk<
  void,
  SelfMonthSummaryUploadInfoBody,
  RootThunkApi
>('confirm/selfMonthSummaryUploadInfoAsync', async (body, api) => {
  return sendAsync(selfMonthSummaryUploadInfo(body), api)
})

// 删除
export const selfMonthSummaryUploadInfoDeleteAsync = createAsyncThunk<
  void,
  SelfMonthSummaryUploadInfoDeleteBody,
  RootThunkApi
>('confirm/selfMonthSummaryUploadInfoDeleteAsync', async (body, api) => {
  return sendAsync(selfMonthSummaryUploadInfoDelete(body), api)
})

// 上传确认
export const selfMonthSummaryUploadInfoConfirmAsync = createAsyncThunk<
  void,
  SelfMonthSummaryUploadInfoConfirmBody,
  RootThunkApi
>('confirm/selfMonthSummaryUploadInfoConfirmAsync', async (body, api) => {
  return sendAsync(selfMonthSummaryUploadInfoConfirm(body), api)
})

// 对账
export const selfMonthReconciliationAsync = createAsyncThunk<
  void,
  SelfMonthReconciliationBody,
  RootThunkApi
>('confirm/selfMonthReconciliationAsync', async (body, api) => {
  return sendAsync(selfMonthReconciliation(body), api)
})

// 月对账明细查询
export const selfMonthReconciliationInfoAsync = createAsyncThunk<
  void,
  SelfMonthReconciliationInfoBody,
  RootThunkApi
>('confirm/selfMonthReconciliationInfoAsync', async (body, api) => {
  return sendAsync(selfMonthReconciliationInfo(body), api)
})

// 日对账
// 汇总上传
export const selfDaySummaryUploadAsync = createAsyncThunk<
  void,
  SelfDaySummaryUploadBody,
  RootThunkApi
>('confirm/selfDaySummaryUploadAsync', async (body, api) => {
  return sendAsync(selfDaySummaryUpload(body), api)
})

// 上传查询
export const selfDaySummaryUploadInfoAsync = createAsyncThunk<
  void,
  SelfDaySummaryUploadInfoBody,
  RootThunkApi
>('confirm/selfDaySummaryUploadInfoAsync', async (body, api) => {
  return sendAsync(selfDaySummaryUploadInfo(body), api)
})

// 删除
export const selfDaySummaryUploadInfoDeleteAsync = createAsyncThunk<
  void,
  SelfDaySummaryUploadInfoDeleteBody,
  RootThunkApi
>('confirm/selfDaySummaryUploadInfoDeleteAsync', async (body, api) => {
  return sendAsync(selfDaySummaryUploadInfoDelete(body), api)
})

// 上传确认
export const selfDaySummaryUploadInfoConfirmAsync = createAsyncThunk<
  void,
  SelfDaySummaryUploadInfoConfirmBody,
  RootThunkApi
>('confirm/selfDaySummaryUploadInfoConfirmAsync', async (body, api) => {
  return sendAsync(selfDaySummaryUploadInfoConfirm(body), api)
})

// 对账
export const selfDayReconciliationAsync = createAsyncThunk<
  void,
  SelfDayReconciliationBody,
  RootThunkApi
>('confirm/selfDayReconciliationAsync', async (body, api) => {
  return sendAsync(selfDayReconciliation(body), api)
})

// 日对账明细查询
export const selfDayReconciliationInfoAsync = createAsyncThunk<
  void,
  SelfDayReconciliationInfoBody,
  RootThunkApi
>('confirm/selfDayReconciliationInfoAsync', async (body, api) => {
  return sendAsync(selfDayReconciliationInfo(body), api)
})

// 结算明细对账
export const selfDetailedReconciliationOfSettlementInfoAsync = createAsyncThunk<
  void,
  SelfDetailedReconciliationOfSettlementInfoBody,
  RootThunkApi
>(
  'confirm/selfDetailedReconciliationOfSettlementInfoAsync',
  async (body, api) => {
    return sendAsync(SelfDetailedReconciliationOfSettlementInfo(body), api)
  }
)

export const noCardRefundAsync = createAsyncThunk<
  void,
  NoCardRefundBody,
  RootThunkApi
>(
  'confirm/noCardRefundAsync',
  async (body, api) => {
    return sendAsync(NoCardRefund(body), api)
  }
)

// 结算明细对账导出
export const selfDetailedReconciliationOfSettlementInfoExportAsync = createAsyncThunk<
  void,
  SelfDetailedReconciliationOfSettlementInfoExportBody,
  RootThunkApi
>(
  'confirm/selfDetailedReconciliationOfSettlementInfoExportAsync',
  async (params, api) => {
    return sendAsync(
      selfDetailedReconciliationOfSettlementInfoExport(params),
      api
    )
  }
)

// 申报
export const SettlementExpenseReportAsync = createAsyncThunk<
  void,
  SettlementExpenseReportBody,
  RootThunkApi
>('confirm/SettlementExpenseReportAsync', async (body, api) => {
  return sendAsync(SettlementExpenseReport(body), api)
})

// 申报撤销
export const SettlementChargeReversalAsync = createAsyncThunk<
  void,
  SettlementChargeReversalBody,
  RootThunkApi
>('confirm/SettlementChargeReversalAsync', async (body, api) => {
  return sendAsync(SettlementChargeReversal(body), api)
})

// 结付信息查询_
export const PaymentInformationQueryAsync = createAsyncThunk<
  void,
  PaymentInformationQueryBody,
  RootThunkApi
>('confirm/PaymentInformationQueryAsync', async (body, api) => {
  return sendAsync(PaymentInformationQuery(body), api)
})
