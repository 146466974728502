/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-03-29 13:33:08
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-06 10:48:13
 */
import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useState,
} from "react";
import { ActionBar, ActionType } from "../widgets/ActionBar";
import { List, ListProps } from "../widgets/List";
import styles from "./EditableList.module.css";

declare module "react" {
  function forwardRef<A, B>(
    Component: (props: A) => B
  ): (props: A) => ReactElement | null;
}

export interface EditableListRef {
  setIsModalVisible: (visible: boolean) => void;
}

interface EditableListProp<T> extends ListProps<T> {
  actions?: ActionType[];
  onAction?: (_: ActionType) => void;
  query?: ReactElement;
  modal?: (props: any) => ReactElement;
  hideActionBar?: boolean;
  noMargin?: boolean;
}

function RealEditableList<T>(
  props: EditableListProp<T>,
  ref: React.Ref<EditableListRef>
): ReactElement {
  const actions = props.actions || [];

  if (actions.length == 0) {
    actions.push("add");
  }

  const Modal = props.modal;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const noMargin = props.noMargin === undefined ? false : props.noMargin;

  useImperativeHandle(ref, () => ({
    setIsModalVisible,
  }));

  return (
    <div
      className={`${noMargin ? styles.nomargin : styles.container} ${
        props.className
      }`}
      style={props.style}
    >
      {(props.hideActionBar === undefined || !props.hideActionBar) && (
        <ActionBar
          actions={actions}
          onAction={(type) => {
            if (type === "add") {
              setIsModalVisible(true);
            } else {
              props.onAction && props.onAction(type);
            }
          }}
          className={styles.bar}
        />
      )}
      <div className={styles.panel}>
        {props.query}
        <List
          rowKey={props.rowKey}
          pagination={props.pagination}
          columns={props.columns}
          onChangePage={props.onChangePage}
          bordered={props.bordered}
          rowSelection={props.rowSelection}
          className={styles.list}
          onRow={props.onRow}
          rowClassName={props.rowClassName}
        />
      </div>
      {Modal && (
        <Modal
          visible={isModalVisible}
          onOk={() => {
            setIsModalVisible(false);
          }}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </div>
  );
}

export const EditableList = forwardRef(RealEditableList);
