import { ReactElement } from 'react'

// 首页-头部卡片项
export enum header {
  HZSL = 'totalCount',
  DJZ = 'notCount',
  CZ = 'firstVisitCount',
  // FZ = 'returnVisitCount',

  JRYY = 'appointmentCount',
  JRDSF = 'visitRecordCount',
  JRYS = 'shouldPayAmount',
  JRSS = 'realPayAmount',
  BXJE= 'insurancePayAmount',
  JRXJLR = 'cash',
  ZJE = 'totalPayAmount',
}

export const headers = [
  header.HZSL,
  header.DJZ,
  header.CZ,
  // header.FZ,
  header.JRYY,
  header.JRDSF,
  header.JRYS,
  header.JRSS,
  header.JRXJLR,
]

export function getheaderName(str: header,isInsurance:boolean) {
  switch (str) {
    case 'totalCount':
      return '患者数量'
    case 'notCount':
      return '待接诊'
    case 'firstVisitCount':
      return '初诊/复诊'
    // case 'returnVisitCount':
    //   return '复诊'
    case 'appointmentCount':
      return '今日预约'
    case 'visitRecordCount':
      return '今日待随访'
    case 'shouldPayAmount':
      return '今日应收'
    case 'insurancePayAmount':
      return '今日报销金额'
    case 'realPayAmount':
        return '今日实收'
    case 'totalPayAmount':
        return '今日总金额'
    case 'cash':
      return '今日现金流入'
  }
}

export function getheaderNum(headerList: any, str: header) {
  if (str === 'realPayAmount' || str === 'cash' || str === 'shouldPayAmount') {
    return headerList.length && headerList[0][str]?.toFixed(2)
  } else if (str === 'firstVisitCount') {
    return (
      headerList.length &&
      headerList[0]['firstVisitCount'] + '/' + headerList[0]['returnVisitCount']
    )
  } else {
    return headerList.length && headerList[0][str]
  }
}

// 首页-侧边栏菜单
export enum sideMenu {
  GZZ = 'workStationNum',
  YY = 'appointmentNum',
  SF = 'feeNum',
  SF2 = 'visitNum', //随访
  HZSR = 'patientNum',
  YJFZ = 'precheckNum',
  JYCX = 'transactionQueryNum',
}

export const sideMenus = [
  sideMenu.GZZ,
  sideMenu.YY,
  sideMenu.SF,
  sideMenu.SF2,
  sideMenu.HZSR,
  sideMenu.YJFZ,
  sideMenu.JYCX,
]

export function getSideMenuName(num: sideMenu) {
  switch (num) {
    case 'workStationNum':
      return '工作站'
    case 'appointmentNum':
      return '预约'
    case 'feeNum':
      return '收费'
    case 'transactionQueryNum':
      return '交易查询'
    case 'visitNum':
      return '随访'
    case 'patientNum':
      return '患者生日'
    case 'precheckNum':
      return '预检分诊'
  }
}
// 首页-选择框-就诊状态
export enum visitingStatus {
  QB = '',
  YYWD = 0,
  DAI = 1,
  JZZ = 2,
  DJF = 3, //随访
  YJZ = 4,
}

export const visitingStatuss = [
  visitingStatus.QB,
  visitingStatus.YYWD,
  visitingStatus.DAI,
  visitingStatus.JZZ,
  visitingStatus.DJF,
  visitingStatus.YJZ,
]

export function getVisitingStatusName(num: visitingStatus) {
  switch (num) {
    case 0:
      return '预约未到'
    case 1:
      return '待接诊'
    case 2:
      return '就诊中'
    case 3:
      return '待缴费'
    case 4:
      return '已就诊'
    default:
      return '全部'
  }
}

export function getVisitingStatusColor(num: visitingStatus) {
  switch (num) {
    case 0:
      return 'rgb(255, 180, 51,.3)'
    case 1:
      return 'rgb(41, 190, 120,.3)'
    case 2:
      return 'rgb(41, 190, 120,.3)'
    case 3:
      return 'rgb(255, 67, 67,.3)'
    case 4:
      return 'rgb(2, 122, 255,.3)'
    default:
      return 'rgb(2, 122, 255,.3)'
  }
}
// 首页-选择框-预约状态
export enum makeAnAppointment {
  YYZ = 0,
  YGH = 1,
  JIUZHEN = 2, //已就诊
  LS = 3,
  CS = 4,
}

export const makeAnAppointments = [
  makeAnAppointment.YYZ,
  makeAnAppointment.YGH,
  makeAnAppointment.JIUZHEN,
  makeAnAppointment.LS,
  makeAnAppointment.CS,
]

export function getMakeAnAppointmentName(num: makeAnAppointment) {
  switch (num) {
    case 0:
      return '预约中'
    case 1:
      return '已挂号'
    case 2:
      return '已就诊'
    case 3:
      return '流失'
    case 4:
      return '超时'
  }
}
// 首页-选择框-结算状态
export enum settlementStatus {
  QB = 0,
  DJS = 1,
  YJS = 2, //已就诊
  QFZ = 3,
  YCD = 4,
}

export const settlementStatuss = [
  settlementStatus.QB,
  settlementStatus.DJS,
  settlementStatus.YJS,
  settlementStatus.QFZ,
  settlementStatus.YCD,
]

export function getSettlementStatusName(num: settlementStatus) {
  switch (num) {
    case 0:
      return '全部'
    case 1:
      return '待结算'
    case 2:
      return '已结清'
    case 3:
      return '欠费中'
    case 4:
      return '已退费'
  }
}
// 首页-新增-选择框-联系方式
export enum identity {
  BR = '0',
  BB = '1',
  MM = '2',
  EZ = '3',
  NE = '4',
  QQ = '5',
  PY = '6',
  PO = '7',
}

export const identitys = [
  identity.BR,
  identity.BB,
  identity.MM,
  identity.EZ,
  identity.NE,
  identity.QQ,
  identity.PY,
  identity.PO,
]

export function getIdentityName(num: identity) {
  switch (num) {
    case '0':
      return '本人'
    case '1':
      return '爸爸'
    case '2':
      return '妈妈'
    case '3':
      return '儿子'
    case '4':
      return '女儿'
    case '5':
      return '亲戚'
    case '6':
      return '朋友'
    case '7':
      return '配偶'
  }
}
// 首页-新增-选择框-患者来源
export enum source {
  QT = 0,
  QYTJ = 1,
  MTBD = 2,
  HDTG = 3,
  WLZX = 4,
  JZFJ = 5,
}

export const sources = [
  source.QT,
  source.QYTJ,
  source.MTBD,
  source.HDTG,
  source.WLZX,
  source.JZFJ,
]

export function getSourceName(num: source) {
  switch (num) {
    case 0:
      return '其他'
    case 1:
      return '亲友推荐'
    case 2:
      return '媒体报道'
    case 3:
      return '活动推广'
    case 4:
      return '网络咨询'
    case 5:
      return '家住附近'
  }
}
// 首页-新增-选择框-就诊项目
export enum medicalTreatmentItems {
  ZL = '0',
  XF = '1',
  ZZ = '2',
  ZJ = '3',
  JZ = '4',
  XY = '5',
  QT = '6',
}

export const medicalTreatmentItemss = [
  medicalTreatmentItems.ZL,
  medicalTreatmentItems.XF,
  medicalTreatmentItems.ZZ,
  medicalTreatmentItems.ZJ,
  medicalTreatmentItems.JZ,
  medicalTreatmentItems.XY,
  medicalTreatmentItems.QT,
]

export function getMedicalTreatmentItemsName(num: medicalTreatmentItems) {
  switch (num) {
    case '0':
      return '治疗'
    case '1':
      return '修复'
    case '2':
      return '种植'
    case '3':
      return '正畸'
    case '4':
      return '检查'
    case '5':
      return '洗牙'
    case '6':
      return '其他'
  }
}
// 首页-新增-词条
export enum entry {
  JYXG = 0,
  JZJL = 1,
  JZYY = 2,
}

export const entrys = [entry.JYXG, entry.JZJL, entry.JZYY]

export function getEntryData(num: entry) {
  switch (num) {
    case 0:
      return ['电动牙刷', '使用牙线', '定期洗牙', '漱口水', '牙签']
    case 1:
      return ['拔牙史', '种牙史', '修复史', '正畸史', '补牙史']
    case 2:
      return ['牙疼', '蛀牙', '种植牙', '缺牙', '牙龈出血']
  }
}
// 患者生日-表格-性别
export enum sex {
  N = 0,
  V = 1,
  WZHI = 2,
}

export const sexs = [sex.N, sex.V, sex.WZHI]

export function getSexData(num: sex) {
  switch (num) {
    case 0:
      return '未知'
    case 1:
      return '男'
    case 2:
      return '女'
    default:
      return '-'
  }
}
// 患者生日-发送短信-签名状态
export enum birthdayStatus {
  SQZ = 0,
  SQTG = 1,
  SQJJ = 2,
}

export const birthdayStatuss = [
  birthdayStatus.SQZ,
  birthdayStatus.SQTG,
  birthdayStatus.SQJJ,
]

export function getbirthdayStatusName(num: birthdayStatus) {
  switch (num) {
    case 0:
      return '申请中'
    case 1:
      return '申请通过'
    case 2:
      return '申请拒绝'
    default:
      return '-'
  }
}
