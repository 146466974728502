import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import React, { useEffect, useState } from 'react'
import styles from './excuteDetailModal.module.css'
import moment from 'moment'
import {
  DateTimeFormat,
  DateTimeFormatSimpleOnlyOne,
} from '../../../models/datetime'
import { CaretDownFilled, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { RcFile, UploadFile } from 'antd/lib/upload/interface'
import FileUpload from '../../../compnents/file/FileUpload'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getExcuteDetailList,
  getLatelyOperatorList,
  getToBeCompletedTreatmentSubmit,
} from './toBeCompletedTreatmentSlice'
import { getUrl } from '../../treatment/diagnosis/diagnosisSlice'
import { getGenderName, StationCategory } from '../../../models/user'
import { getUsersInDepartmentAsync } from '../../completion/completionSlice'
import { selectUserId } from '../../../app/applicationSlice'
import { getAge } from '../../../utils/StringUtils'

const { Option } = Select

export const ExcuteDetailModal = (props: {
  isModalVisible: boolean
  id: any
  onOk: () => void
  onCancel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const [value, setValue] = useState<any>()

  const [detail, setDetail] = useState<any>()

  const [doctorList, setDoctorList] = useState<any>([])

  const [assistantList, setAssistantList] = useState<any>([])

  const time: any = moment().format(DateTimeFormat)

  const UserId = useSelector(selectUserId)

  useEffect(() => {
    if (!props.isModalVisible) return
    dispatch(getExcuteDetailList({ id: props?.id }))
      .then(unwrapResult)
      .then((res: any) => {
        setDetail(res)
      })

    dispatch(
      getUsersInDepartmentAsync({
        departmentId: '',
        stationCategory: StationCategory.DoctorOrPharmacist,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setDoctorList(res)
      })
  }, [props.isModalVisible])

  useEffect(() => {
    if (doctorList) {
      dispatch(
        getUsersInDepartmentAsync({
          departmentId: '',
          stationCategory: StationCategory.DoctorOrPharmacist,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setAssistantList(doctorList.concat(res))
        })
    }
  }, [doctorList])

  useEffect(() => {
    if (props.isModalVisible) {
      form.setFieldsValue({
        executeTime: moment(),
        operatorId: UserId.toString(),
      })
    }
  }, [UserId, props.isModalVisible])

  return (
    <Modal
      width={680}
      title='执行'
      visible={props.isModalVisible}
      footer={null}
      onCancel={() => {
        props.onCancel()
      }}
      onOk={() => {
        props?.onOk()
      }}
    >
      <Row
        style={{
          lineHeight: '30px',
          marginBottom: '20px',
          fontSize: '14px',
        }}
      >
        <Col span={8}>患者姓名：{detail?.patientName}</Col>
        <Col span={8}>性别：{getGenderName(detail?.patientSex)}</Col>
        <Col span={8}>
          年龄：
          {getAge(detail?.patientYear, detail?.patientMonth)}
        </Col>
        <Col span={8}>项目：{detail?.treatmentServiceName}</Col>
        <Col span={8}>就诊医生：{detail?.doctorName}</Col>
        <Col
          span={8}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          就诊时间：
          <a
            title={
              detail?.clinicTime
                ? moment(detail?.clinicTime).format(DateTimeFormat)
                : '-'
            }
            style={{ color: '#000' }}
          >
            {detail?.clinicTime
              ? moment(detail?.clinicTime).format(DateTimeFormat)
              : '-'}
          </a>
        </Col>
      </Row>
      <Form
        className={styles.form}
        name='basic'
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        autoComplete='off'
        onFinish={(values) => {
          if (value > detail?.remainNum) {
            notification.error({
              message: '本次执行次数不能大于剩余次数，请重新输入次数',
            })
            // form.resetFields()
            // setFileList([])
          } else {
            const operatorName =
              (assistantList.find(
                (u: any) => u.id === values.operatorId
              ) as any)?.name || ''
            dispatch(
              getToBeCompletedTreatmentSubmit({
                ...values,
                executeTime: values?.lastModifiedDate
                  ? moment(values?.lastModifiedDate).format(
                      DateTimeFormatSimpleOnlyOne
                    )
                  : moment().format(DateTimeFormatSimpleOnlyOne),
                operatorName: operatorName ? operatorName : detail?.doctorName,
                operatorId: values?.operatorId,
                attachmentFile: fileList
                  ?.map((v) => `${v.name}@${v.uid}@`.concat(v.url || ''))
                  .join(','),
                id: props?.id,
              })
            )
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '执行成功',
                })
                form.resetFields()
                props?.onOk()
                setFileList([])
              })
              .catch(() => {
                // form.resetFields()
                // props?.onCancel()
                // setFileList([])
              })
          }
        }}
      >
        <Form.Item
          style={{ padding: '30px 0' }}
          label='本次执行次数：'
          name='executeNum'
          rules={[
            { required: true, message: '请填写本次执行次数' },
            {
              pattern: /^\d+$|^\d+[.]?\d+$/,
              message: '只能输入纯数字，请重新输入',
            },
          ]}
        >
          <Input
            onChange={(e) => {
              setValue(e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item
          required
          name='executeTime'
          label='本次执行时间'
          rules={[{ required: true, message: '请选择本次执行时间' }]}
        >
          <DatePicker
            format='YYYY/MM/DD HH:mm:ss'
            defaultValue={moment(time, DateTimeFormat)}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        <Form.Item
          name='operatorId'
          label='执行人：'
          rules={[{ required: true, message: '请选择执行人' }]}
        >
          <Select
            placeholder='执行人'
            allowClear
            // suffixIcon={<CaretDownFilled />}
          >
            {assistantList.map((v: any) => (
              <Option key={v.id} value={v.id}>
                {v.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name='remark' label='备注'>
          <Input.TextArea />
        </Form.Item>
        <Form.Item name='attachmentFile' label='附件'>
          <FileUpload
            count={(fileList && fileList.length) || 0}
            files={fileList}
            onUpload={(v, i) => {
              setFileList([
                ...fileList,
                {
                  uid: v.uid,
                  size: v.size,
                  name: v.name,
                  type: v.type,
                  originFileObj: {} as RcFile,
                  url: i,
                },
              ])
            }}
          >
            <Button icon={<PlusOutlined />}>上传附件</Button>
          </FileUpload>
          {fileList &&
            fileList.map((item) => (
              <Row
                gutter={24}
                key={item.uid}
                style={{
                  padding: '10px',
                  width: '300px',
                }}
              >
                <Col flex={1}>
                  <span
                    style={{
                      display: 'inline-block',
                      maxWidth: '60px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      verticalAlign: 'middle',
                    }}
                  >
                    {item.name.split('.')[0]}
                  </span>
                  <span
                    style={{
                      verticalAlign: 'middle',
                    }}
                  >
                    .{item.name.split('.')[1]}
                  </span>
                </Col>
                <Col span={6}>
                  <a
                    onClick={() => {
                      dispatch(getUrl({ fileName: item.url }))
                        .then(unwrapResult)
                        .then((v: any) => {
                          window.open(v, '_blank')
                        })
                    }}
                  >
                    查看
                  </a>
                </Col>
                <Col span={6}>
                  <CloseOutlined
                    onClick={() => {
                      //  e.stopPropagation();
                      setFileList(() =>
                        fileList.filter((ite) => ite.uid !== item.uid)
                      )
                    }}
                  />
                </Col>
              </Row>
            ))}
        </Form.Item>
        <Row gutter={20}>
          <Col span={24}>
            <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
              确认
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
