/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-10 09:21:12
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 17:59:45
 */
import { Col } from "antd"
import React, {useEffect} from "react"
import { TabBar, TabPane } from "../../compnents/widgets/TabBar"
import styles from "./directory.module.css"
import Insurance from "./insurance/Insurance"
import InsuranceOperation from "./insurance/insuranceOperation"
import MzInsuranceCategory from "./insurance/insuranceMz";
import ZfblInsuranceCategory from "./insurance/insuranceZfbl";
import LimitPriceChange from "./insurance/limitPriceChange";
import {useLocation} from "react-router-dom";

export const DirectoryTabBar = () => {

  const location = useLocation();
  const [activeKey, setActiveKey] = React.useState('1');
  useEffect(() => {
    if (location.search.length > 0 && location.search.indexOf('tab=')) {
      console.log(location.search.replace('?tab=', ''))
      setActiveKey(location.search.replace('?tab=', ''));
    }
  }, [location.search]);

  return (
    <Col className={styles.container}>
      <TabBar activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
        <TabPane key='1' tab='医保目录查询'>
          <Insurance />
        </TabPane>
        <TabPane key='2' tab='医保手术目录查询'>
          <InsuranceOperation />
        </TabPane>
        <TabPane key='3' tab='民族药品目录查询'>
          <MzInsuranceCategory />
        </TabPane>
        <TabPane key='4' tab='自付比例目录查询'>
          <ZfblInsuranceCategory />
        </TabPane>
        <TabPane key='5' tab='目录限价变动查询'>
          <LimitPriceChange />
        </TabPane>
      </TabBar>
    </Col>
  )
}
