/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-06-08 11:34:05
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-09 16:02:22
 */
/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-09 10:35:00
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Modal, message, Table, Button, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { ReactElement } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../app/store'
import { getVersionConsultVersionAsync } from '../features/home/HomeSlice'

export const TipsModal = (props: {
  id?: any
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  return (
    <Modal
      visible={props.visible}
      title='温馨提醒'
      width='600px'
      footer={null}
      onOk={() => {
        props.onOk()
      }}
      onCancel={() => {
        props.onCancel()
      }}
    >
      <div style={{ height: '100px', display: 'flex', alignItems: 'center' }}>
        请保持电话通畅，服务人员将会在24小时内联系您
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}
      >
        <Button
          style={{}}
          type='primary'
          onClick={() => {
            dispatch(getVersionConsultVersionAsync(props?.id))
              .then(unwrapResult)
              .then((v) => {
                notification.success({
                  message: '操作成功',
                })
                props.onOk()
              })
          }}
        >
          预约免费试用
        </Button>
      </div>
    </Modal>
  )
}
