import { Tenant, TenantCategory, toJson } from "../models/tenant";
import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface TenantQueryParams {
  current?: number
  endTimeHead?: string
  endTimeTail?: string
  name?: string
  tenantCategory?: TenantCategory
  size?: number
  area?: string
  provinceCode?: string
  cityCode?: string
  countyCode?: string
}

export function getTenantList(params: TenantQueryParams): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/list`,
    method: 'GET',
    params,
  }
}

export function getTenantDetail(tenantId: string): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/detail`,
    method: 'GET',
    params: {
      tenantId,
    },
  }
}

export function createOrUpdateTenant(tenant: Tenant): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/save`,
    method: 'POST',
    body: toJson(tenant),
  }
}

export function toggleTenantState(tenantId: string): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/logoutOrEnable`,
    method: 'POST',
    params: {
      tenantId,
    },
  }
}

export interface AllParams {
  regionLevel?: number
  parentRegionCode?: string
  current?: number
  size?: number
}
// 省市区
export function getProvince(params: AllParams): Request {
  return {
    url: `${ADMIN_API_PREFIX}/api/city/list`,
    method: 'GET',
    params,
  }
}

// 所有省市区
export function getAll(): Request {
  return {
    url: `${ADMIN_API_PREFIX}/api/city/alldata`,
    method: 'GET',
  }
}
export interface ExportExcelParams {
  current?: number
  endTimeHead?: string
  endTimeTail?: string
  name?: string
  tenantCategory?: TenantCategory
  size?: number
  area?: string
  provinceCode: any
  cityCode: any
  countyCode: any
}
// 导出
export function getExportExcel(body: ExportExcelParams): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/log/exportexcel`,
    method: 'POST',
    responseType: 'blob',
    body,
  }
}

export interface ImportExcelParams {
  file?: any
}
// 导入
export function getImportExcel(params: ImportExcelParams): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/openaccount`,
    headers: {
      [ContentType]: MultiPartFormData,
    },
    method: 'POST',
    params,
  }
}

// 发票号回显
export function invoicenumberconfig(): Request {
  return {
    url: `${API_PREFIX}/blade-user/invoicenumberconfig/list`,
    method: 'GET',
    params: {
      current: 1,
      size: 10,
    },
  }
}

// 发票号保存
export interface InvoiceSaveParams {
  categoryType: string
  currentNo: string
  end: string
  id: number
  invoiceCode: string
  start: string
  invoiceNumberLength: number
}
export function invoiceSave(body: InvoiceSaveParams[]): Request {
  return {
    url: `${API_PREFIX}/blade-user/invoicenumberconfig/submit`,
    method: 'POST',
    body,
  }
}
