import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import { LoadingState } from '../../../models/common'
import {
  NatureTokenValue,
  NatureValue,
  patientInfo,
  PreScriptionIframeValue,
} from '../../../models/intelligence'
import * as R from '../../../models/registration'
import { Registration } from '../../../models/registration'
import * as Tr from '../../../models/treatment'
import { Treatment } from '../../../models/treatment'
import {
  getIframePreScription,
  getNature,
  getNatureToken,
  NatureBody,
  PreScriptBody,
  setNature,
} from '../../../services/intelligence'
import { startTreatment as apiStartTreatment } from '../../../services/registration'
import { remindSaveMedical, SaveMedicalParams } from '../../../services/remind'
import {
  copyTreatment as apiCopyTreatment,
  CopyTreatmentParams,
  createTreatment as apiCreateTreatment,
  deleteUploadFileListFn,
  deleteUploadFileListFnParams,
  getHistoryCaseAsync,
  getTreatment as apiGetTreatment,
  GetTreatmentDetailParams,
  getUploadFileListFn,
  getUrlLink,
  getWXUploadCodeFn,
  getWXUploadCodeFnParams,
  HistoryCaseAsyncResponse,
  isEditTreatment, removeCASignAsync, treatmentCASignAsync,
  urlLinkParams,
} from '../../../services/treatment'
import { setRecipeEditorTreatment } from '../editor/recipeListEditorSlice'
import { setTreatmentRegistration } from '../treatmentSlice'
import * as SU from "../../../services/personalDetail";
import {Request} from "../../../services/request";
import {API_PREFIX} from "../../../services/constant";

interface DiagnosisState {
  registration?: Registration
  treatment?: Treatment
  creatingState: LoadingState
  remindDetail: any
}

const initialState = {
  registration: undefined,
  creatingState: LoadingState.Idle,
  remindDetail: [],
} as DiagnosisState

export const createTreatment = createAsyncThunk<void, Treatment, RootThunkApi>(
  'diagnosis/createTreatment',
  async (treatment, api) => {
    return sendAsync(apiCreateTreatment(treatment), api)
  }
)

export const getTreatments = createAsyncThunk<
  patientInfo,
  GetTreatmentDetailParams,
  RootThunkApi<void>
>('diagnosis/getTreatments', async (params, api) => {
  return sendAsync(apiGetTreatment(params), api)
})

// 事中提醒保存病历时
export const remindSaveMedicalAsync = createAsyncThunk<
  any,
  SaveMedicalParams,
  RootThunkApi
>('diagonsis/remindSaveMedicalAsync', async (body, api) => {
  return api.getState().settinglable?.settings[29]
    ? sendAsync(remindSaveMedical(body), api)
    : []
})

const diagnosisSlice = createSlice({
  name: 'diagnosis',
  initialState,
  reducers: {
    setRegistration: (state, action: PayloadAction<Registration>) => {
      state.registration = action.payload
    },
    setTreatment: (state, action: PayloadAction<Treatment | undefined>) => {
      state.treatment = action.payload
    },
    setTreatmentCreatingState: (state, action: PayloadAction<LoadingState>) => {
      state.creatingState = action.payload
    },
    resetDiagnosis: (state) => {
      state.registration = undefined
      state.treatment = undefined
      state.creatingState = LoadingState.Idle
    },
    setRemindDetail: (state, action) => {
      state.remindDetail = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(remindSaveMedicalAsync.fulfilled, (state, action) => {
      state.remindDetail = action.payload
    })
  },
})

export const {
  setTreatmentCreatingState,
  resetDiagnosis,
  setTreatment,
  setRemindDetail,
  setRegistration,
} = diagnosisSlice.actions

export function getTreatment(params: GetTreatmentDetailParams): RootThunk {
  return api(
    apiGetTreatment(params),
    (data: Record<string, never>, dispatch) => {
      const registration = R.fromJson(data.registration)
      dispatch(setRegistration(registration))
      dispatch(setTreatmentRegistration(registration))
      const treatment =
        JSON.stringify(data.treatment) !== '{}'
          ? Tr.fromJson(data.treatment)
          : undefined
      dispatch(setTreatment(treatment))
      dispatch(setRecipeEditorTreatment(treatment))
    }
  )
}

export function startTreatment(registrationId: string): RootThunk {
  return api(apiStartTreatment(registrationId), () => {
    // Do nothing.
  })
}

export const copyTreatment = createAsyncThunk<
  void,
  CopyTreatmentParams,
  RootThunkApi<void>
>('diagnosis/copyTreatmentStatus', async (params, api) => {
  return sendAsync(apiCopyTreatment(params), api).then(() => {
    // do nothing.
  })
})

export const getUrl = createAsyncThunk<void, urlLinkParams, RootThunkApi>(
  'diagnosis/getUrl',
  async (params, api) => {
    return sendAsync(getUrlLink(params), api)
  }
)
// 获取小程序上传附件二维码
export const getWXUploadCode = createAsyncThunk<
  void,
  getWXUploadCodeFnParams,
  RootThunkApi
>('diagnosis/getWXUploadCode', async (params, api) => {
  return sendAsync(getWXUploadCodeFn(params), api)
})
// 获取已上传文件
export const getUploadFileList = createAsyncThunk<
  void,
  getWXUploadCodeFnParams,
  RootThunkApi
>('diagnosis/getUploadFileList', async (params, api) => {
  return sendAsync(getUploadFileListFn(params), api)
})
// 删除已上传文件
export const deleteUploadFileList = createAsyncThunk<
  void,
  deleteUploadFileListFnParams,
  RootThunkApi
>('diagnosis/deleteUploadFileList', async (params, api) => {
  return sendAsync(deleteUploadFileListFn(params), api)
})

export const getNatureAsync = createAsyncThunk<
  NatureValue,
  string,
  RootThunkApi<void>
>('diagonsis/getNature', async (code, api) => {
  return sendAsync(getNature(code), api)
})

export const setNatureAsync = createAsyncThunk<
  any,
  NatureBody,
  RootThunkApi<void>
>('diagonsis/setNature', async (body, api) => {
  return sendAsync(setNature(body), api)
})

export const getNatureTokenAsync = createAsyncThunk<
  NatureTokenValue,
  void,
  RootThunkApi<void>
>('diagonsis/getNatureToken', async (_, api) => {
  return sendAsync(getNatureToken(), api)
})

export const getIframePreScriptionAsync = createAsyncThunk<
  PreScriptionIframeValue,
  PreScriptBody,
  RootThunkApi<void>
>('diagonsis/getIframe', async (body, api) => {
  return sendAsync(getIframePreScription(body), api)
})

// 查看历史病历
export const getHistoryCase = createAsyncThunk<
  Array<HistoryCaseAsyncResponse>,
  { patientId: number },
  RootThunkApi<void>
>('diagonsis/getHistoryCase', async (body, api) => {
  return sendAsync(getHistoryCaseAsync(body), api)
})


export const treatmentCASign = createAsyncThunk<void, any, RootThunkApi>(
  'tretment/cwca/treatmentCASign',
  async (body, api) => {
    return sendAsync(treatmentCASignAsync(body), api).then(() => {
      // do nothing.
    })
  }
)
export const removeCASign = createAsyncThunk<void, any, RootThunkApi>(
  'tretment/cwca/removeCASign',
  async (body, api) => {
    return sendAsync(removeCASignAsync(body), api).then(() => {
      // do nothing.
    })
  }
)


// 查看医生是否可修改他人病历
export const isEditTreatmentAsync = createAsyncThunk<void, void, RootThunkApi>(
  'diagonsis/isEditTreatmentAsync',
  async (_, api) => {
    return sendAsync(isEditTreatment(), api)
  }
)

export const selectRegistration = (
  state: RootState
): Registration | undefined => state.diagnosis.registration

export const selectTreatment = (state: RootState): Treatment | undefined =>
  state.diagnosis.treatment

export const selectTreatmentCreatingState = (state: RootState): LoadingState =>
  state.diagnosis.creatingState

export const selectRemindDetail = (state: RootState) =>
  state.diagnosis.remindDetail

export default diagnosisSlice.reducer
