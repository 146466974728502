import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunkApi } from "../../app/store";
import {
  CarryOverDetailEntry,
  CarryOverEntry,
  CarryOverItem,
  fromJson,
} from "../../models/carryover";
import { Page, pageOf, startPageOf } from "../../models/page";
import * as S from "../../services/carryover";
import {
  GetCarryOverListParams,
  getCarryOverListExoprtFn,
  getCarryOverDetailExoprtFn,
} from '../../services/carryover'

interface CarryOverState {
  page: Page<CarryOverEntry>
}

const initialState = {
  page: startPageOf(),
} as CarryOverState

export const getCarryOverList = createAsyncThunk<
  Page<CarryOverEntry>,
  GetCarryOverListParams,
  RootThunkApi
>('carryOver/getCarryOverListStatus', async (params, api) => {
  return sendAsync(S.getCarryOverList(params), api).then((data) =>
    pageOf(
      data,
      _.chain(data.records)
        .map((e) => fromJson(e))
        .value()
    )
  )
})

export const createCarryOver = createAsyncThunk<
  void,
  S.CreateCarryOverParam,
  RootThunkApi
>('carryOver/createCarryOverStatus', async (params, api) => {
  return sendAsync(S.createCaryOver(params), api)
})

export const removeCarryOver = createAsyncThunk<void, string, RootThunkApi>(
  'carryOver/removeCarryOverState',
  async (params, api) => {
    return sendAsync(S.removeCarryOver(params), api)
  }
)

export const getCarryOverDetailEntries = createAsyncThunk<
  Page<CarryOverDetailEntry>,
  S.GetCarryOverDetailEntriesParams,
  RootThunkApi
>('carryOver/getCarryOverDetailEntriesStatus', async (params, api) => {
  return sendAsync(S.getCarryOverDetailEntries(params), api).then((data) =>
    pageOf(
      data,
      _.chain(data.records)
        .map((e) => e as CarryOverDetailEntry)
        .value()
    )
  )
})

export const getCarryOverItemList = createAsyncThunk<
  CarryOverItem[],
  S.GetCarryOverItemListParams,
  RootThunkApi
>('carryOver/getCarryOverItemList', async (params, api) => {
  return sendAsync(S.getCarryOverItemList(params), api).then(
    (data) => data.records
  )
})

const carryOverSlice = createSlice({
  name: 'carryOver',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCarryOverList.fulfilled, (state, action) => {
      state.page = action.payload
    })
  },
})

//导出
export const getCarryOverListExoprt = createAsyncThunk<
  void,
  GetCarryOverListParams,
  RootThunkApi
>('doctorAudit/getCarryOverListExoprt ', async (params, api) => {
  return sendAsync(getCarryOverListExoprtFn(params), api)
})

// 详情导出
export const getCarryOverDetailExoprtFnAsync = createAsyncThunk<
  void,
  GetCarryOverListParams,
  RootThunkApi
>('doctorAudit/getCarryOverListExoprt ', async (params, api) => {
  return sendAsync(getCarryOverDetailExoprtFn(params), api)
})


export const selectCarryOverPage = (state: RootState): Page<CarryOverEntry> =>
  state.carryOver.page;

export default carryOverSlice.reducer;
