import _ from 'lodash'

// 部门类型 0 全科 1临床 2医技 3药剂 4病区 5行政/财务/其他
export enum DepartmentCategory {
  General = 0,
  Clinic = 1,
  Technology = 2,
  Medicament = 3,
  Ward = 4,
  Others = 5,
}

export const DepartmentCategories = [
  DepartmentCategory.General,
  DepartmentCategory.Clinic,
  DepartmentCategory.Technology,
  DepartmentCategory.Medicament,
  DepartmentCategory.Ward,
  DepartmentCategory.Others,
]

export const DepartmentCategoryNames = [
  '全部',
  '临床',
  '医技',
  '药剂',
  '病区',
  '行政/财务/其他',
]

export function getDepartmentCategoryName(
  category: DepartmentCategory
): string {
  if (category < 0 || category >= DepartmentCategoryNames.length) {
    return ''
  }
  return DepartmentCategoryNames[category]
}

// 部门子类型 0 临床 1手术 2医技 3库房 4病区 5财务 6院外单位
export enum DepartmentSubCategory {
  Clinic = 0,
  Surgery = 1,
  Technology = 2,
  Warehouse = 3,
  Ward = 4,
  Finance = 5,
  External = 6,
}

export const DepartmentSubCategories = [
  DepartmentSubCategory.Clinic,
  DepartmentSubCategory.Surgery,
  DepartmentSubCategory.Technology,
  DepartmentSubCategory.Warehouse,
  DepartmentSubCategory.Ward,
  DepartmentSubCategory.Finance,
  DepartmentSubCategory.External,
]

export const DepartmentSubCategoryNames = [
  '临床/出诊',
  '手术',
  '医技',
  '库房',
  '病区',
  '财务/其他行政',
  '院外单位',
]

export function getDepartmentSubCategoryName(
  category: DepartmentSubCategory
): string {
  if (category < 0 || category >= DepartmentSubCategoryNames.length) {
    return ''
  }
  return DepartmentSubCategoryNames[category]
}

export function getDepartmentSubCategories(
  category: DepartmentCategory
): { editable: boolean; categories: DepartmentSubCategory[] } {
  switch (category) {
    case DepartmentCategory.General:
      return {
        editable: true,
        categories: [
          DepartmentSubCategory.Clinic,
          DepartmentSubCategory.Surgery,
          DepartmentSubCategory.Technology,
          DepartmentSubCategory.Warehouse,
          DepartmentSubCategory.Ward,
          DepartmentSubCategory.Finance,
        ],
      }
    case DepartmentCategory.Clinic:
      return { editable: false, categories: [DepartmentSubCategory.Clinic] }
    case DepartmentCategory.Technology:
      return {
        editable: false,
        categories: [DepartmentSubCategory.Technology],
      }
    case DepartmentCategory.Medicament:
      return {
        editable: false,
        categories: [DepartmentSubCategory.Warehouse],
      }
    case DepartmentCategory.Ward:
      return { editable: false, categories: [DepartmentSubCategory.Ward] }
    case DepartmentCategory.Others:
      return { editable: false, categories: [DepartmentSubCategory.Finance] }
  }
}

export interface Department {
  id: string
  name: string
  // 科室编码
  code: string
  // 状态
  status: number
  // 科室类型
  category: DepartmentCategory
  // 科室子类型
  subCategory: DepartmentSubCategory[]
  //医保科比
  ybKebie: string
  resperName: string
  resperTel: string
  ybUploadFlag: string
  tenantId: string
  showFlg: any
}

export function fromJson(json: any): Department {
  return {
    id: json.id?.toString() || '',
    name: json.name,
    code: json.code,
    status: json.status,
    category: json.category,
    subCategory: json.subCategory
      ? _.chain(json.subCategory)
          .split(',')
          .map((c) => parseInt(c))
          .value()
      : [],
    ybKebie: json.ybKebie,
    resperName: json.resperName,
    resperTel: json.resperTel,
    ybUploadFlag: json.ybUploadFlag,
    tenantId: json.tenantId,
    showFlg: json.showFlg,
  }
}

export const YBKBTYPE = [
  'A01',
  'A02',
  'A03',
  'A03.01',
  'A03.02',
  'A03.03',
  'A03.04',
  'A03.05',
  'A03.06',
  'A03.07',
  'A03.08',
  'A03.09',
  'A03.10',
  'A03.11',
  'A04',
  'A04.01',
  'A04.01.01',
  'A04.01.02',
  'A04.01.03',
  'A04.02',
  'A04.03',
  'A04.04',
  'A04.04.01',
  'A04.05',
  'A04.05.01',
  'A04.06',
  'A04.06.01',
  'A04.07',
  'A04.08',
  'A04.09',
  'A05',
  'A05.01',
  'A05.02',
  'A05.03',
  'A05.04',
  'A05.05',
  'A05.06',
  'A06',
  'A06.01',
  'A06.02',
  'A06.03',
  'A06.04',
  'A06.05',
  'A06.06',
  'A07',
  'A07.01',
  'A07.02',
  'A07.03',
  'A07.04',
  'A07.05',
  'A07.06',
  'A07.07',
  'A07.08',
  'A07.09',
  'A07.10',
  'A07.11',
  'A07.12',
  'A08',
  'A08.01',
  'A08.02',
  'A08.03',
  'A08.04',
  'A08.05',
  'A08.06',
  'A09',
  'A09.01',
  'A09.02',
  'A09.03',
  'A09.04',
  'A09.05',
  'A09.06',
  'A10',
  'A11',
  'A11.01',
  'A11.02',
  'A11.03',
  'A11.04',
  'A12',
  'A12.01',
  'A12.02',
  'A12.03',
  'A12.04',
  'A12.05',
  'A12.06',
  'A12.07',
  'A12.08',
  'A12.09',
  'A12.10',
  'A13',
  'A13.01',
  'A13.02',
  'A13.03',
  'A14',
  'A15',
  'A15.01',
  'A15.02',
  'A15.03',
  'A15.04',
  'A15.05',
  'A15.06',
  'A15.07',
  'A15.08',
  'A16',
  'A16.01',
  'A16.02',
  'A16.03',
  'A16.04',
  'A16.05',
  'A16.06',
  'A16.07',
  'A17',
  'A18',
  'A19',
  'A20',
  'A21',
  'A22',
  'A23',
  'A23.01',
  'A23.02',
  'A23.03',
  'A23.04',
  'A23.05',
  'A23.06',
  'A24',
  'A25',
  'A26',
  'A27',
  'A28',
  'A30',
  'A30.01',
  'A30.02',
  'A30.03',
  'A30.04',
  'A30.05',
  'A30.06',
  'A31',
  'A32',
  'A32.01 X ',
  'A32.02 CT',
  'A32.03',
  'A32.04',
  'A32.05',
  'A32.06',
  'A32.07',
  'A32.08',
  'A32.09',
  'A32.10',
  'A32.11',
  'A50',
  'A50.01',
  'A50.02',
  'A50.03',
  'A50.04',
  'A50.05',
  'A50.06',
  'A50.07',
  'A50.08',
  'A50.09',
  'A50.10',
  'A50.11',
  'A50.12',
  'A50.13',
  'A50.14',
  'A50.15',
  'A50.16',
  'A50.17',
  'A50.18',
  'A51',
  'A51.01',
  'A51.02',
  'A51.03',
  'A51.04',
  'A51.05',
  'A51.06',
  'A52',
  'A69',
  'B01',
  'B02',
  'B03',
  'B04',
  'B05',
  'B06',
  'B07',
  'B08',
  'B09',
  'B10',
  'B11',
  'B12',
  'B13',
  'B14',
  'B15',
  'B16',
  'B17',
  'B18',
  'B19',
  'B69',
  'C01',
  'C02',
  'C03',
  'C04',
  'C05',
  'C06',
  'C07',
  'C08',
  'C09',
  'C10',
  'C11',
  'C69',
  'D71',
  'D72',
  'D73',
  'D74',
  'D81',
  'D82',
  'D83',
  'D84',
  'D85',
  'D86',
  'D87',
  'D88',
  'D89',
  'D99',
]

export const getYBKBName = (key: string) => {
  switch (key) {
    case 'A01':
      return '预防保健科'
    case 'A02':
      return '全科医疗科'
    case 'A03':
      return '内科'
    case 'A03.01':
      return '呼吸内科专业'
    case 'A03.02':
      return '消化内科专业'
    case 'A03.03':
      return '神经内科专业'
    case 'A03.04':
      return '心血管内科专业'
    case 'A03.05':
      return '血液内科专业'
    case 'A03.06':
      return '肾病学专业'
    case 'A03.07':
      return '内分泌专业'
    case 'A03.08':
      return '免疫学专业'
    case 'A03.09':
      return '变态反应专业'
    case 'A03.10':
      return '老年病专业'
    case 'A03.11':
      return '其他'
    case 'A04':
      return '外科'
    case 'A04.01':
      return '普通外科专业'
    case 'A04.01.01':
      return '肝脏移植项目'
    case 'A04.01.02':
      return '胰腺移植项目'
    case 'A04.01.03':
      return '小肠移植项目'
    case 'A04.02':
      return '神经外科专业'
    case 'A04.03':
      return '骨科专业'
    case 'A04.04':
      return '泌尿外科专业'
    case 'A04.04.01':
      return '肾脏移植项目'
    case 'A04.05':
      return '胸外科专业'
    case 'A04.05.01':
      return '肺脏移植项目'
    case 'A04.06':
      return '心脏大血管外科专业'
    case 'A04.06.01':
      return '心脏移植项目'
    case 'A04.07':
      return '烧伤科专业'
    case 'A04.08':
      return '整形外科专业'
    case 'A04.09':
      return '其他'
    case 'A05':
      return '妇产科'
    case 'A05.01':
      return '妇科专业'
    case 'A05.02':
      return '产科专业'
    case 'A05.03':
      return '计划生育专业'
    case 'A05.04':
      return '优生学专业'
    case 'A05.05':
      return '生殖健康与不孕症专业'
    case 'A05.06':
      return '其他'
    case 'A06':
      return '妇女保健科'
    case 'A06.01':
      return '青春期保健专业'
    case 'A06.02':
      return '围产期保健专业'
    case 'A06.03':
      return '更年期保健专业'
    case 'A06.04':
      return '妇女心理卫生专业'
    case 'A06.05':
      return '妇女营养专业'
    case 'A06.06':
      return '其他'
    case 'A07':
      return '儿科'
    case 'A07.01':
      return '新生儿专业'
    case 'A07.02':
      return '小儿传染病专业'
    case 'A07.03':
      return '小儿消化专业'
    case 'A07.04':
      return '小儿呼吸专业'
    case 'A07.05':
      return '小儿心脏病专业'
    case 'A07.06':
      return '小儿肾病专业'
    case 'A07.07':
      return '小儿血液病专业'
    case 'A07.08':
      return '小儿神经病学专业'
    case 'A07.09':
      return '小儿内分泌专业'
    case 'A07.10':
      return '小儿遗传病专业'
    case 'A07.11':
      return '小儿免疫专业'
    case 'A07.12':
      return '其他'
    case 'A08':
      return '小儿外科'
    case 'A08.01':
      return '小儿普通外科专业'
    case 'A08.02':
      return '小儿骨科专业'
    case 'A08.03':
      return '小儿泌尿外科专业'
    case 'A08.04':
      return '小儿胸心外科专业'
    case 'A08.05':
      return '小儿神经外科专业'
    case 'A08.06':
      return '其他'
    case 'A09':
      return '儿童保健科'
    case 'A09.01':
      return '儿童生长发育专业'
    case 'A09.02':
      return '儿童营养专业'
    case 'A09.03':
      return '儿童心理卫生专业'
    case 'A09.04':
      return '儿童五官保健专业'
    case 'A09.05':
      return '儿童康复专业'
    case 'A09.06':
      return '其他'
    case 'A10':
      return '眼科'
    case 'A11':
      return '耳鼻咽喉科'
    case 'A11.01':
      return '耳科专业'
    case 'A11.02':
      return '鼻科专业'
    case 'A11.03':
      return '咽喉科专业'
    case 'A11.04':
      return '其他'
    case 'A12':
      return '口腔科'
    case 'A12.01':
      return '牙体牙髓病专业'
    case 'A12.02':
      return '牙周病专业'
    case 'A12.03':
      return '口腔黏膜病专业'
    case 'A12.04':
      return '儿童口腔专业'
    case 'A12.05':
      return '口腔颌骨外科专业'
    case 'A12.06':
      return '口腔修复专业'
    case 'A12.07':
      return '口腔正畸专业'
    case 'A12.08':
      return '口腔种植专业'
    case 'A12.09':
      return '口腔麻醉专业'
    case 'A12.10':
      return '口腔颌面医学影像专业'
    case 'A13':
      return '皮肤科'
    case 'A13.01':
      return '皮肤病专业'
    case 'A13.02':
      return '性传播疾病专业'
    case 'A13.03':
      return '其他'
    case 'A14':
      return '医疗美容科'
    case 'A15':
      return '精神科'
    case 'A15.01':
      return '精神病专业'
    case 'A15.02':
      return '精神卫生专业'
    case 'A15.03':
      return '药物依赖专业'
    case 'A15.04':
      return '精神康复专业'
    case 'A15.05':
      return '社区防治专业'
    case 'A15.06':
      return '临床心理专业'
    case 'A15.07':
      return '司法精神专业'
    case 'A15.08':
      return '其他'
    case 'A16':
      return '传染科'
    case 'A16.01':
      return '肠道传染病专业'
    case 'A16.02':
      return '呼吸道传染病专业'
    case 'A16.03':
      return '肝炎专业'
    case 'A16.04':
      return '虫媒传染病专业'
    case 'A16.05':
      return '动物源性传染病专业'
    case 'A16.06':
      return '蠕虫病专业'
    case 'A16.07':
      return '其它'
    case 'A17':
      return '结核病科'
    case 'A18':
      return '地方病科'
    case 'A19':
      return '肿瘤科'
    case 'A20':
      return '急诊医学科'
    case 'A21':
      return '康复医学科'
    case 'A22':
      return '运动医学科'
    case 'A23':
      return '职业病科'
    case 'A23.01':
      return '职业中毒专业'
    case 'A23.02':
      return '尘肺专业'
    case 'A23.03':
      return '放射病专业'
    case 'A23.04':
      return '物理因素损伤专业'
    case 'A23.05':
      return '职业健康监护专业'
    case 'A23.06':
      return '其他'
    case 'A24':
      return '临终关怀科'
    case 'A25':
      return '特种医学与军事医学科'
    case 'A26':
      return '麻醉科'
    case 'A27':
      return '疼痛科'
    case 'A28':
      return '重症医学科'
    case 'A30':
      return '医学检验科'
    case 'A30.01':
      return '临床体液、血液专业'
    case 'A30.02':
      return '临床微生物学专业'
    case 'A30.03':
      return '临床生化检验专业'
    case 'A30.04':
      return '临床免疫、血清学专业'
    case 'A30.05':
      return '临床细胞分子遗传学专业'
    case 'A30.06':
      return '其他'
    case 'A31':
      return '病理科'
    case 'A32':
      return '医学影像科'
    case 'A32.01 X ':
      return ' 线诊断'
    case 'A32.02 CT':
      return '诊断专业'
    case 'A32.03':
      return '磁共振成像诊断专业'
    case 'A32.04':
      return '核医学专业'
    case 'A32.05':
      return '超声诊断专业'
    case 'A32.06':
      return '心电诊断专业'
    case 'A32.07':
      return '脑电及脑血流图诊断专业'
    case 'A32.08':
      return '神经肌肉电图专业'
    case 'A32.09':
      return '介入放射学专业'
    case 'A32.10':
      return '放射治疗专业'
    case 'A32.11':
      return '其他'
    case 'A50':
      return '中医科'
    case 'A50.01':
      return '内科专业'
    case 'A50.02':
      return '外科专业'
    case 'A50.03':
      return '妇产科专业'
    case 'A50.04':
      return '儿科专业'
    case 'A50.05':
      return '皮肤科专业'
    case 'A50.06':
      return '眼科专业'
    case 'A50.07':
      return '耳鼻咽喉科专业'
    case 'A50.08':
      return '口腔科专业'
    case 'A50.09':
      return '肿瘤科专业'
    case 'A50.10':
      return '骨伤科专业'
    case 'A50.11':
      return '肛肠科专业'
    case 'A50.12':
      return '老年病科专业'
    case 'A50.13':
      return '针灸科专业'
    case 'A50.14':
      return '推拿科专业'
    case 'A50.15':
      return '康复医学专业'
    case 'A50.16':
      return '急诊科专业'
    case 'A50.17':
      return '预防保健科专业'
    case 'A50.18':
      return '其他'
    case 'A51':
      return '民族医学科'
    case 'A51.01':
      return '维吾尔医学'
    case 'A51.02':
      return '藏医学'
    case 'A51.03':
      return '蒙医学'
    case 'A51.04':
      return '彝医学'
    case 'A51.05':
      return '傣医学'
    case 'A51.06':
      return '其他'
    case 'A52':
      return '中西医结合科'
    case 'A69':
      return '其他业务科室'
    case 'B01':
      return '传染病预防控制科(中心)'
    case 'B02':
      return '性病艾滋病预防控制科(中心)'
    case 'B03':
      return '结核病预防控制科(中心)'
    case 'B04':
      return '血吸虫预防控制科(中心)'
    case 'B05':
      return '慢性非传染性疾病预防控制科(中心)'
    case 'B06':
      return '寄生虫病预防控制科(中心)'
    case 'B07':
      return '地方病控制科(中心)'
    case 'B08':
      return '精神卫生科(中心)'
    case 'B09':
      return '妇幼保健科'
    case 'B10':
      return '免疫规划科(中心)'
    case 'B11':
      return '农村改水技术指导科(中心)'
    case 'B12':
      return '疾病控制与应急处理办公室'
    case 'B13':
      return '食品卫生科'
    case 'B14':
      return '环境卫生所'
    case 'B15':
      return '职业卫生科'
    case 'B16':
      return '放射卫生科'
    case 'B17':
      return '学校卫生科'
    case 'B18':
      return '健康教育科(中心)'
    case 'B19':
      return '预防医学门诊'
    case 'B69':
      return '其他业务科室'
    case 'C01':
      return '综合卫生监督科'
    case 'C02':
      return '产品卫生监督科'
    case 'C03':
      return '职业卫生监督科'
    case 'C04':
      return '环境卫生监督科'
    case 'C05':
      return '传染病执法监督科'
    case 'C06':
      return '医疗服务监督科'
    case 'C07':
      return '稽查科(大队)'
    case 'C08':
      return '许可受理科'
    case 'C09':
      return '放射卫生监督科'
    case 'C10':
      return '学校卫生监督科'
    case 'C11':
      return '食品安全监督科'
    case 'C69':
      return '其他'
    case 'D71':
      return '护理部'
    case 'D72':
      return '药剂科(药房)'
    case 'D73':
      return '感染科'
    case 'D74':
      return '输血科(血库)'
    case 'D81':
      return '办公室'
    case 'D82':
      return '人事科'
    case 'D83':
      return '财务科'
    case 'D84':
      return '设备科'
    case 'D85':
      return '信息科(中心)'
    case 'D86':
      return '医政科'
    case 'D87':
      return '教育培训科'
    case 'D88':
      return '总务科'
    case 'D89':
      return '新农合管理办公室'
    case 'D99':
      return '其他科'
    default:
      break
  }
}
