import { API_PREFIX } from "./constant";
import { Request } from "./request";
import { treatmentCostParams } from "./treatmentCost";


export function urlManagementList(): Request {
    return {
        url: `${API_PREFIX}/blade-user/wechatAPPMenu/getUrl`,
        method: "GET",
    }
}

// 菜单
export function menuManagementList(): Request {
    return {
        url: `${API_PREFIX}/blade-user/wechatAPPMenu/getWechatMenu`,
        method: "GET",
    }
}

export interface setMenuParams {
    menuList: string,
    id: number
}

//保存菜单
export function setMenuManagementList(params: setMenuParams): Request {
    return {
        url: `${API_PREFIX}/blade-user/wechatAPPMenu/submit`,
        method: "POST",
        body: params
    }
}