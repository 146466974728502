/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-03-29 13:33:08
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-29 14:15:59
 */
import { Settinglable } from "../models/personalSetting"
import { StationCategory, toJson, User } from "../models/user"
import { ADMIN_API_PREFIX, API_PREFIX } from "./constant"
import { ContentType, MultiPartFormData, Request } from "./request"

export interface GetUserListParams {
  current: number
  size?: number
  outpatientDepartmentId?: string
  innerDepartmentId?: string
  param?: string
  state?: number
  tenantId?: string
  stationCategory?: number
}

export interface CreateUserParams {
  tenantId?: string
  user: User
  diagnoses?: string
  prescription?: string
}

export interface shopTypeParams {
  tenantId?: number
  userId?: number
}
export interface shopTypeBody {
  code?: string
  codeName?: string
  userId?: number
}

export function createOrUpdateUser(params: CreateUserParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/save`
      : `${API_PREFIX}/blade-user/user/submit`,
    method: 'POST',
    body: { ...toJson(params.user), tenantId: params.tenantId },
  }
}

export function saveSettingLable(body: Settinglable): Request {
  return {
    url: `${API_PREFIX}/blade-user/personage/submit`,
    method: 'POST',
    body,
  }
}

export function saveCwcaOptId(body: string): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/cwca/saveCwcaOptId`,
    method: 'POST',
    body,
  }
}

export interface GetUserDetailParams {
  tenantId?: string
  userId: string
}

export function getUserDetail(params: GetUserDetailParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/detail`
      : `${API_PREFIX}/blade-user/user/detail`,
    method: 'POST',
    params: {
      userId: params.userId,
    },
  }
}

export function setShopType(body: shopTypeBody): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/personage/submit`,
    method: 'POST',
    body,
  }
}

export function getShopType(params: shopTypeParams): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/personage/detail`,
    method: 'GET',
    params,
  }
}

export interface ToggleUserStateParams {
  tenantId?: string
  userId: string
}

export function toggleUserState(params: ToggleUserStateParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/logoutOrEnable`
      : `${API_PREFIX}/blade-user/user/logoutOrEnable`,
    method: 'POST',
    params: {
      userId: params.userId,
    },
  }
}

export interface ResetUserPasswordParams {
  id: string
  phone: string
  tenantId?: string
}

export function resetUserPassword(params: ResetUserPasswordParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/passwordReset`
      : `${API_PREFIX}/blade-user/user/password/reset`,
    method: 'POST',
    body: {
      id: params.id,
      phone: params.phone,
    },
  }
}

export function getUserProfile(): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/profile`,
    method: "GET",
  }
}

// 上传电子签名
export interface SignatureParams {
  file?: any;
  userId?:string
}
export function uploadSignature(params: SignatureParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/usersign/submitSign`,
    headers: {
      [ContentType]: MultiPartFormData,
    },
    method: 'POST',
    params,
  }
}

// 获取电子签名
export function getSignature(userId?: any): Request {
  return {
    url: `${API_PREFIX}/blade-user/usersign/getUserSign`,
    method: 'GET',
    params: { userId },
  }
}



export interface SignatureEntityParams {
  userId?: any
  tenantId?: any
  signId?:any
}
// 获取电子签名
export function getSignatureEntity(body?: SignatureEntityParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/userSign/getUserSignEntity`,
    method: 'POST',
    body,
  }
}


// 删除电子签名
export function deleteSignature(userId?: any): Request {
  return {
    url: `${API_PREFIX}/blade-user/usersign/signRemove`,
    method: 'GET',
    params: { userId },
  }
}
// 删除电子签名
export function getSignatureAsyncNumFn(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/product/getProductUse`,
    method: 'GET',
    params: {type:'12'},
  }
}

export function getUserProfileList(userId?: any): Request {
  return {
    url: `${API_PREFIX}/blade-user/personage/list`,
    method: "GET",
    params: userId,
  }
}
// 获取微诊所是否绑定
export function getWXBindingFn(): Request {
  return {
    url: `${API_PREFIX}/blade-user/userApplet/getIsBinding`,
    method: "GET",
  }
}
// 解除微诊所绑定
export function removeBindingFn(): Request {
  return {
    url: `${API_PREFIX}/blade-user/userApplet/tenantUserUntie`,
    method: "POST",
  }
}


export interface changePasswordBody {
  oldPwd: string
  newPwd: string
}

export function getChangePasswordSave(body: changePasswordBody): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/vaschangepwd`,
    method: "POST",
    body,
  }
}
