/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-14 09:59:22
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-19 11:04:46
 */

//销售员日结打印单
export function statementPrintBody(data: any) {
  const tenantName = data?.tenantName
  const timeHead = data?.timeHead
  const timeTail = data?.timeTail
  const totalPageList = data?.totalPageList
  const datas = data?.dataList?.map((v: any) => {
    return {
      ...v,
      customizedCollectionAmountArray: v?.customizedCollectionAmountArray
        ?.map((k: any) => {
          return { ...k }
        })
        ?.sort((a: any, b: any) =>
          a.payMethodName.localeCompare(b.payMethodName)
        ),
    }
  })

  const titleList = data?.titleList?.sort((a: any, b: any) =>
    a.payMethodName.localeCompare(b.payMethodName)
  )


  let sjDatas = ''
  let totalPayAmountAll = 0
  let insurancePayAmountAll = 0
  let aggregateAmountAll = 0
  let shouldPayAmountAll = 0
  let cashAmountAll = 0
  let alipayAmountAll = 0
  let wechatAmountAll = 0
  let membershipCardPaymentAmountAll = 0
  let otherPaymentAmountAll = 0
  for (let i = 0; i < datas?.length; i++) {
    totalPayAmountAll += Number(
      datas[i]?.totalPayAmount ? datas[i]?.totalPayAmount : 0
    )
    insurancePayAmountAll += Number(
      datas[i]?.insurancePayAmount ? datas[i]?.insurancePayAmount : 0
    )
    aggregateAmountAll += Number(
      datas[i]?.aggregateAmount ? datas[i]?.aggregateAmount : 0
    )
    shouldPayAmountAll += Number(
      datas[i]?.shouldPayAmount ? datas[i]?.shouldPayAmount : 0
    )
    cashAmountAll += Number(datas[i]?.cashAmount ? datas[i]?.cashAmount : 0)
    alipayAmountAll += Number(
      datas[i]?.alipayAmount ? datas[i]?.alipayAmount : 0
    )
    wechatAmountAll += Number(
      datas[i]?.wechatAmount ? datas[i]?.wechatAmount : 0
    )
    membershipCardPaymentAmountAll += Number(
      datas[i]?.membershipCardPaymentAmount
        ? datas[i]?.membershipCardPaymentAmount
        : 0
    )
    otherPaymentAmountAll += Number(
      datas[i]?.otherPaymentAmount ? datas[i]?.otherPaymentAmount : 0
    )
    sjDatas +=
      ' <div style="min-height:30px;font-size: 13px ;display:flex;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '            <div style="line-height:25px;font-weight:bold;padding-left:2px;padding-right:2px;word-break:break-all;width: 8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].operateUserName +
      '            </div>' +
      '            <div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width: 12.5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].checkoutTime?.substr(0,10) +
      '            </div>' +
      '            <div name="'+datas[i].operateUserName+'_totalPayAmount"  style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:  10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].totalPayAmount?.toFixed(2) +
      '            </div>' +
      '             <div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].shouldPayAmount?.toFixed(2) +
      '            </div>' +
      '            <div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:  10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].insurancePayAmount?.toFixed(2) +
      '           </div>' +
      '            <div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].cashAmount?.toFixed(2) +
      '            </div>' +
      '           <div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width: 9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].aggregateAmount?.toFixed(2) +
      '           </div>' +
      '            <div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].alipayAmount?.toFixed(2) +
      '            </div>' +
      '            <div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].wechatAmount?.toFixed(2) +
      '            </div>' +
      '            <div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].membershipCardPaymentAmount?.toFixed(2) +
      '            </div>'
    sjDatas += datas[i]?.customizedCollectionAmountArray?.length
      ? '            <div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:8.5%;text-align:center;box-sizing: border-box;;border-right: 1px solid #000">' +
        '                ' +
        datas[i]?.otherPaymentAmount?.toFixed(2) +
        '            </div>'
      : '             <div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:8.5%;text-align:center;box-sizing: border-box;">' +
        '                ' +
        datas[i]?.otherPaymentAmount?.toFixed(2) +
        '            </div>'
    const payMethods = datas[i]?.customizedCollectionAmountArray
    if (payMethods?.length > 0) {
      for (let i = 0; i < payMethods.length; i++) {
        sjDatas +=
          i + 1 == payMethods?.length
            ? '<div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:7%;text-align:center;box-sizing: border-box;">' +
              Number(payMethods?.[i].payAmount)?.toFixed(2) +
              '</div>'
            : '<div style="line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:7%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
              Number(payMethods?.[i].payAmount)?.toFixed(2) +
              '</div>'
      }
    }
    sjDatas += '    </div>'
  }
  let content =
    '<div style="margin-left: 10px;margin-right: 10px">' +
    '    <h3 style="text-align: center;margin-bottom: 5px;">' +
    tenantName +
    '</h3>' +
    '    <h3 style="text-align: center;margin-top: 5px;margin-bottom: 5px;">销&nbsp;&nbsp;售&nbsp;&nbsp;员&nbsp;&nbsp;日&nbsp;&nbsp;结</h3>' +
    '    <div style="font-size:14px;display:flex;text-align: left;margin-bottom:6px">' +
    '        <div style="font-size:14px;width:50%;padding-left: 10px">定点机构名称:<font style="word-break:break-all;padding-left: 10px">' +
    tenantName +
    '</font></div>' +
    '       <div style="font-size:14px;width:50%;padding-left: 10px;display:flex;justify-content:flex-end;">汇总范围:<font style="font-size:14px;word-break:break-all;padding-left: 10px">' +
    timeHead +
    '至' +
    timeTail +
    '</font></div>' +
    '    </div>' +
    '   <div style="font-weight:bold;min-height:25px;line-height:25px;font-size:14px;display:flex;clear: left;border: 1px solid #000">' +
    '        <div style="word-break:break-all;width: 8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            操作员' +
    '        </div>' +
    '       <div style="word-break:break-all;width: 12.5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           日期' +
    '       </div>' +
    '        <div style="word-break:break-all;width:  10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            总费用' +
    '        </div>' +
    '       <div style="word-break:break-all;width:  10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           应收' +
    '       </div>' +
    '        <div style="word-break:break-all;width:  10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            医保' +
    '        </div>' +
    '        <div style="word-break:break-all;width:  10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            现金' +
    '        </div>' +
    '       <div style="word-break:break-all;width: 9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           聚合' +
    '       </div>' +
    '        <div style="word-break:break-all;width: 9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            支付宝' +
    '        </div>' +
    '        <div style="word-break:break-all;width: 9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            微信' +
    '        </div>' +
    '        <div style="word-break:break-all;width: 9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            会员卡' +
    '        </div>' +
    (titleList?.length
      ? '        <div style="word-break:break-all;width: 8.5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '            其他' +
        '        </div>'
      : '        <div style="word-break:break-all;width: 8.5%;text-align:center;box-sizing: border-box">' +
        '            其他' +
        '        </div>')

  let plusContent: any = ''
  titleList?.map((k: any, index: any) => {
    plusContent +=
      index + 1 == titleList?.length
        ? '         <div style="word-break:break-all;width: 7%;text-align:center;box-sizing: border-box">' +
          k?.payMethodName  +
          '        </div>'
        : '         <div style="word-break:break-all;width: 7%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
          k?.payMethodName  +
          '        </div>'
  })
  content +=
    plusContent +
    '    </div>' +
    sjDatas +
    '   <div style="min-height:25px;line-height:25px;font-size:13px;display:flex;clear: left;border: 1px solid #000;border-top:none">' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 20.5%;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                合计：' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    totalPayAmountAll?.toFixed(2) +
    '            </div>' +
    '             <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    shouldPayAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    insurancePayAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    cashAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    aggregateAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    alipayAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    wechatAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:9%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    membershipCardPaymentAmountAll?.toFixed(2) +
    '            </div>' +
    (totalPageList?.length
      ? '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8.5%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                ' +
        otherPaymentAmountAll?.toFixed(2) +
        '            </div>'
      : '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8.5%;text-align:center;box-sizing: border-box;">' +
        '                ' +
        otherPaymentAmountAll?.toFixed(2) +
        '            </div>')
  let totalPageContent: any = ''
  totalPageList?.map((v: any, index: any) => {
    totalPageContent +=
      index + 1 == totalPageList?.length
        ? '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7%;text-align:center;box-sizing: border-box;">' +
          '                ' +
          Number(v?.totalValue)?.toFixed(2) +
          '            </div>'
        : '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7%;text-align:center;box-sizing: border-box;;border-right: 1px solid #000">' +
          '                ' +
          Number(v?.totalValue)?.toFixed(2) +
          '            </div>'
  })
  content +=
    totalPageContent + '        </div>' + '    <div>' + '    </div>' + '</div>'

  return content
}



function toNumberFix2(str : any){
  try{
    return parseFloat(Number(str).toFixed(2));
  }catch(e){
    return 0.00;
  }
}
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-14 09:59:22
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-21 14:03:55
 */

//销售员日结汇总打印单（合并每个员工的不同日期）
export function statementSubPrintBody(data: any) {
  let moneyGrounByUserId:any = {};
  const tenantName = data?.tenantName
  const timeHead = data?.timeHead
  const timeTail = data?.timeTail
  const totalPageList = data?.totalPageList
  const datas = data?.dataList?.map((v: any) => {
    return {
      ...v,
      customizedCollectionAmountArray: v?.customizedCollectionAmountArray
        ?.map((k: any) => {
          return { ...k }
        })
        ?.sort((a: any, b: any) =>
          a.payMethodName.localeCompare(b.payMethodName)
        ),
    }
  })

  const titleList = data?.titleList?.sort((a: any, b: any) =>
    a.payMethodName.localeCompare(b.payMethodName)
  )


  let sjDatas = ''
  let totalPayAmountAll = 0
  let insurancePayAmountAll = 0
  let aggregateAmountAll = 0
  let shouldPayAmountAll = 0
  let cashAmountAll = 0
  let alipayAmountAll = 0
  let wechatAmountAll = 0
  let membershipCardPaymentAmountAll = 0
  let otherPaymentAmountAll = 0
  moneyGrounByUserId = {};
  for (let x = 0; x < datas?.length; x++) {

    if(datas[x]?.operateUserName in moneyGrounByUserId){
      let oldOne:any = {};
      oldOne = moneyGrounByUserId[datas[x]?.operateUserName];
      oldOne["totalPayAmount"] = (toNumberFix2(oldOne["totalPayAmount"]) +toNumberFix2(datas[x]?.totalPayAmount))?.toFixed(2);
      oldOne["shouldPayAmount"] = (toNumberFix2(oldOne["shouldPayAmount"]) +toNumberFix2(datas[x]?.shouldPayAmount))?.toFixed(2);
      oldOne["aggregateAmount"] = (toNumberFix2(oldOne["aggregateAmount"]) +toNumberFix2(datas[x]?.aggregateAmount))?.toFixed(2);
      oldOne["insurancePayAmount"] = (toNumberFix2(oldOne["insurancePayAmount"]) +toNumberFix2(datas[x]?.insurancePayAmount))?.toFixed(2);
      oldOne["cashAmount"] = (toNumberFix2(oldOne["cashAmount"]) +toNumberFix2(datas[x]?.cashAmount))?.toFixed(2);
      oldOne["alipayAmount"] = (toNumberFix2(oldOne["alipayAmount"]) +toNumberFix2(datas[x]?.alipayAmount))?.toFixed(2);
      oldOne["wechatAmount"] = (toNumberFix2(oldOne["wechatAmount"]) +toNumberFix2(datas[x]?.wechatAmount))?.toFixed(2);
      oldOne["membershipCardPaymentAmount"] = (toNumberFix2(oldOne["membershipCardPaymentAmount"]) +toNumberFix2(datas[x]?.membershipCardPaymentAmount))?.toFixed(2);
      oldOne["otherPaymentAmount"] = (toNumberFix2(oldOne["otherPaymentAmount"]) +toNumberFix2(datas[x]?.otherPaymentAmount))?.toFixed(2);

      const oldCusArray = oldOne["customizedCollectionAmountArray"] ;
      const newCusArray = datas[x]?.customizedCollectionAmountArray;
      oldOne["customizedCollectionAmountArray"] = [];
      if(oldCusArray && oldCusArray.length>=1 && newCusArray && newCusArray.length>=1 ){
        for(let j = 0 ;j<oldCusArray.length;j++){
          for(let k = 0;k<newCusArray.length;k++){
            if(oldCusArray[j]?.payMethod == newCusArray[k]?.payMethod){
              const cus = {
                payAmount : (toNumberFix2(oldCusArray[j]?.payAmount) +  toNumberFix2(newCusArray[k]?.payAmount) )?.toFixed(2) ,
                payMethod : oldCusArray[j]?.payMethod,
                payMethodName : oldCusArray[j]?.payMethodName
              }
              oldOne["customizedCollectionAmountArray"] .push(cus) ;
            }
          }
        }
      }

      // moneyGrounByUserId[datas[i]?.operateUserName]?.totalAmount = datas[i]?.totalAmount;
      moneyGrounByUserId[datas[x]?.operateUserName] = oldOne;
    }else{
      moneyGrounByUserId[datas[x]?.operateUserName] = datas[x];
    }

  }
  const moneyListByUserId = [];
  for (const key in moneyGrounByUserId) {
    moneyListByUserId.push(moneyGrounByUserId[key]);
  }
  for(let i = 0;i<moneyListByUserId.length;i++){
    totalPayAmountAll += Number(
      moneyListByUserId[i]?.totalPayAmount ? moneyListByUserId[i]?.totalPayAmount : 0
    )
    insurancePayAmountAll += Number(
      moneyListByUserId[i]?.insurancePayAmount ? moneyListByUserId[i]?.insurancePayAmount : 0
    )
    aggregateAmountAll += Number(
      moneyListByUserId[i]?.aggregateAmount ? moneyListByUserId[i]?.aggregateAmount : 0
    )
    shouldPayAmountAll += Number(
      moneyListByUserId[i]?.shouldPayAmount  ? moneyListByUserId[i]?.shouldPayAmount : 0
    )
    cashAmountAll += Number(moneyListByUserId[i]?.cashAmount  ? moneyListByUserId[i]?.cashAmount : 0)
    alipayAmountAll += Number(
      moneyListByUserId[i]?.alipayAmount ? moneyListByUserId[i]?.alipayAmount : 0
    )
    wechatAmountAll += Number(
      moneyListByUserId[i]?.wechatAmount ? moneyListByUserId[i]?.wechatAmount : 0
    )
    membershipCardPaymentAmountAll += Number(
      moneyListByUserId[i]?.membershipCardPaymentAmount
        ? moneyListByUserId[i]?.membershipCardPaymentAmount
        : 0
    )
    otherPaymentAmountAll += Number(
      moneyListByUserId[i]?.otherPaymentAmount  ? moneyListByUserId[i]?.otherPaymentAmount : 0
    )
    sjDatas +=
      ' <div style="min-height:30px;font-size: 13px ;line-height:30px;display:flex;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '            <div style="font-size: 13px ;font-weight:bold;padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      moneyListByUserId[i].operateUserName +
      '            </div>' +
      '            <div name="' +
      moneyListByUserId[i].operateUserName +
      '_totalPayAmount"  style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      Number(moneyListByUserId[i].totalPayAmount)?.toFixed(2) +
      '            </div>' +
      '             <div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      Number(moneyListByUserId[i].shouldPayAmount)?.toFixed(2) +
      '            </div>' +
      '            <div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      Number(moneyListByUserId[i].insurancePayAmount)?.toFixed(2) +
      '           </div>' +
      '           <div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      Number(moneyListByUserId[i].aggregateAmount)?.toFixed(2) +
      '           </div>' +
      '            <div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      Number(moneyListByUserId[i].cashAmount)?.toFixed(2) +
      '            </div>' +
      '            <div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      Number(moneyListByUserId[i].alipayAmount)?.toFixed(2) +
      '            </div>' +
      '            <div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      Number(moneyListByUserId[i].wechatAmount)?.toFixed(2) +
      '            </div>' +
      '            <div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      Number(moneyListByUserId[i].membershipCardPaymentAmount)?.toFixed(2) +
      '            </div>'
    sjDatas += moneyListByUserId[i]?.customizedCollectionAmountArray?.length
      ? '            <div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;;border-right: 1px solid #000">' +
        '                ' +
      Number(moneyListByUserId[i]?.otherPaymentAmount)?.toFixed(2) +
        '            </div>'
      : '             <div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;">' +
        '                ' +
      Number(moneyListByUserId[i]?.otherPaymentAmount)?.toFixed(2) +
        '            </div>'
    const payMethods = moneyListByUserId[i]?.customizedCollectionAmountArray
    if (payMethods?.length > 0) {
      for (let i = 0; i < payMethods.length; i++) {
        sjDatas +=
          i + 1 == payMethods?.length
            ? '<div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;">' +
              Number(payMethods?.[i].payAmount)?.toFixed(2) +
              '</div>'
            : '<div style="font-size: 13px;line-height:25px;padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
              Number(payMethods?.[i].payAmount)?.toFixed(2) +
              '</div>'
      }
    }
    sjDatas += '    </div>'
  }


  let content =
    '<div style="margin-left: 10px;margin-right: 10px">' +
    '    <h3 style="text-align: center;margin-bottom: 5px;">' +
    tenantName +
    '</h3>' +
    '    <h3 style="text-align: center;margin-top: 5px;margin-bottom: 5px;">销&nbsp;&nbsp;售&nbsp;&nbsp;员&nbsp;&nbsp;日&nbsp;&nbsp;结</h3>' +
    '    <div style="font-size:14px;display:flex;text-align: left;margin-bottom:6px">' +
    '        <div style="width:50%;padding-left: 10px">定点机构名称:<font style="word-break:break-all;padding-left: 10px">' +
    tenantName +
    '</font></div>' +
    '       <div style="font: 14px \'宋体\';width:50%;padding-left: 10px;display:flex;justify-content:flex-end;">汇总范围:<font style="font-size:14px;word-break:break-all;padding-left: 10px">' +
    timeHead +
    '至' +
    timeTail +
    '</font></div>' +
    '    </div>' +
    '   <div style="min-height:30px;line-height:25px;font-size:13px;font-weight:bold;display:flex;clear: left;border: 1px solid #000">' +
    '        <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            操作员' +
    '        </div>' +

    '        <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            总费用' +
    '        </div>' +
    '       <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           应收金额' +
    '       </div>' +
    '        <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            医保报销' +
    '        </div>' +
    '       <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           聚合支付' +
    '       </div>' +
    '        <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            现金' +
    '        </div>' +
    '        <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            支付宝' +
    '        </div>' +
    '        <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            微信' +
    '        </div>' +
    '        <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            会员卡' +
    '        </div>' +
    (titleList?.length
      ? '        <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '            其他' +
      '        </div>'
      : '        <div style="word-break:break-all;width: 10%;text-align:center;box-sizing: border-box">' +
      '            其他' +
      '        </div>')

  let plusContent: any = ''
  titleList?.map((k: any, index: any) => {
    plusContent +=
      index + 1 == titleList?.length
        ? '         <div style="font: 14px \'宋体\';word-break:break-all;width: 10%;text-align:center;box-sizing: border-box">' +
          k?.payMethodName +
          '        </div>'
        : '         <div style="font: 14px \'宋体\';word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
          k?.payMethodName +
          '        </div>'
  })
  content +=
    plusContent +
    '    </div>' +
    sjDatas +
    '   <div style="min-height:30px;line-height:25px;font-size:13px;display:flex;clear: left;border: 1px solid #000;border-top:none">' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                合计：' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    totalPayAmountAll?.toFixed(2) +
    '            </div>' +
    '             <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    shouldPayAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    insurancePayAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    aggregateAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    cashAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    alipayAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    wechatAmountAll?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    membershipCardPaymentAmountAll?.toFixed(2) +
    '            </div>' +
    (totalPageList?.length
      ? '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                ' +
        otherPaymentAmountAll?.toFixed(2) +
        '            </div>'
      : '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;">' +
        '                ' +
        otherPaymentAmountAll?.toFixed(2) +
        '            </div>')
  let totalPageContent: any = ''
  totalPageList?.map((v: any, index: any) => {
    totalPageContent +=
      index + 1 == totalPageList?.length
        ? '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;">' +
          '                ' +
          Number(v?.totalValue)?.toFixed(2) +
          '            </div>'
        : '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;;border-right: 1px solid #000">' +
          '                ' +
          Number(v?.totalValue)?.toFixed(2) +
          '            </div>'
  })
  content +=
    totalPageContent + '        </div>' + '    <div>' + '    </div>' + '</div>'

  return content
}
