import { TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import { DateTimeFormatSimple } from '../../../models/datetime'
import { Dimen } from '../../../models/dimen'
import { getRegistrationStateName } from '../../../models/registration'
import { getGenderName } from '../../../models/user'
import { getAge } from '../../../utils/StringUtils'

export const appealcolumn = (
    onVisible: (id: string) => void,
    onDetails: (id: string) => void,
    onVideo: (videopath: string) => void,
    startIndex: number
): TableColumnType<any>[] => {
    return [
        {
            title: '序号',
            key: 'no',
            align: 'center',
            width: Dimen.Num,
            render: (_1, _2, index) => `${startIndex + index + 1}`,
        },
        // {
        //   title: "结算险种",
        //   dataIndex: "registrationCategory",
        //   align: "center",
        //   render: function registrationCategory(registrationCategory: number) {
        //     return registrationCategory === 0 ? "自费" : "医保";
        //   },
        // },

        {
            title: '消费者名称',
            dataIndex: 'name',
            width: Dimen.Name,
            align: 'center',
        },
        {
            title: '手术项目',
            dataIndex: 'surgicalItems',
            width: Dimen.Name,
            align: 'center',
        },
        {
            title: '主刀医生',
            dataIndex: 'opdoctor',
            width: Dimen.Name,
            align: 'center',
        },
        {
            title: '状态',
            dataIndex: 'certifyingStatus',
            width: Dimen.Name,
            align: 'center',
            render: (_, r) => {
                return r.certifyingStatus == 0 ? '已取消' : (
                    r.certifyingStatus == 1 ? '已认证' : '-'
                )
            }
        },
        {
            title: '手术时间',
            dataIndex: 'surgicalDate',
            width: Dimen.Name,
            align: 'center',
            render: (_, r) => {
                return moment(r.surgicalDate).format(DateTimeFormatSimple)
            },
        },
        {
            title: '操作',
            align: 'center',
            render: function element(_, t) {
                return (
                    <div
                        style={{
                            width: '300px',
                            height: '30px',
                            margin: 'auto',
                        }}
                    >
                        {t.certifyingStatus == '2' ?
                            <a
                                style={{
                                    color: '#FFFFFF',
                                    background: '#027AFF',
                                    padding: '0 18px',
                                    borderRadius: '4px',
                                    float: 'left'
                                }}
                                type='primary'
                                onClick={() => onVisible(t.id)}
                            >
                                取消
                            </a> : ''
                        }
                        <a
                            style={{
                                color: '#FFFFFF',
                                background: '#027AFF',
                                padding: '0 18px',
                                borderRadius: '4px',
                                float: 'right'
                            }}
                            type='primary'
                            onClick={() => onDetails(t.id)}
                        >
                            查看
                        </a>
                        {t.certifyingStatus == '1' ?<a
                            style={{
                                color: '#FFFFFF',
                                background: '#027AFF',
                                padding: '0 18px',
                                borderRadius: '4px',
                                marginRight: '18px',
                                float: 'right'
                            }}
                            type='primary'
                            onClick={() => {
                                const link = document.createElement('a')
                                link.href = t?.pdfpath
                                link.target = '_blank'
                                link.download = '术前文书.pdf'
                                link.click()

                            }}
                        >
                            认证查看
                        </a> : '' }
                        {t?.videopath ? <a
                            style={{
                                color: '#FFFFFF',
                                background: '#027AFF',
                                padding: '0 18px',
                                borderRadius: '4px',
                                marginRight: '18px',
                                float: 'right'
                            }}
                            type='primary'
                            onClick={() => onVideo(t?.videopath)}
                        >
                            见证视频
                        </a> : ''} 
                    </div>
                )
            },
        },
    ]
}
