import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  api,
  ApplicationType,
  selectApplicationType,
  sendAsync,
} from '../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../app/store'
import { constructMenuLayers, Menu, MenuCategory } from '../models/menu'
import {
  accountCheck,
  AccountCheckListParams,
  AllopatryListBody,
  CheckBody,
  checkDataParams,
  checkLocalData,
  detailBody,
  detailCheck,
  downloadBody,
  elseWhereCheck,
  elseWhereCheckBody,
  elseWhereDownloadBody,
  elseWhereDownloadCheck,
  fileDownload,
  filePageParse,
  getAccountCheckList,
  getAllopatryList,
  getCloseImgPhotoFn,
  getImgFn,
  getImgFnParams,
  getSignInfo,
  getUserMenus,
  getUserTwoMenus,
  insuranceTradeExport,
  InsuranceTradeExportParams,
  localMedicalTrade,
  localMedicalTradeBody,
  medicalTrade,
  medicalTradeBody,
  mlDownfilePageParse,
  mlpageParseBody,
  pageParseBody,
  rankingList,
  SignBody,
  SignInfoParams,
  signInToMedical,
  signOutToMedical,
  SignParams,
  updateBody,
  updateFire,
} from '../services/config'

const BreadcrumbKey = 'breadcrumbs'

export interface Breadcrumb<S = unknown> {
  name: string
  path: string
  query?: string
  state?: S
  isNavBar?: boolean //若是点击面包屑路由跳转，则不刷新页面，否则读取缓存组件
}

interface LayoutState {
  menus: Menu[]
  twoMenus: Menu[]
  threeMenus: Menu[]
  fixed: Menu[]
  breadcrumbs: Breadcrumb[]
  current?: Breadcrumb
  signInfo?: SignInfoValue
  showFollowUp?: boolean // 是否在医嘱处方显示创建随访按钮
  rankingList?: any // 前五疾病
}

const initialState: LayoutState = {
  fixed: [],
  threeMenus: [],
  twoMenus: [],
  menus: [],
  breadcrumbs: [],
  showFollowUp: true,
  rankingList: {},
}

export interface SignInfoValue {
  checkinTm: string
  checkoutTm: string
  createUser: number
  id: number
  insuranceCode: string
  isDeleted: number
  operatorNb: string
  signCycleCd: string
  status: number
  tenantId: number
  updateUser: number
}

// 获取签到信息
export const getSignInfoAsync = createAsyncThunk<
  SignInfoValue,
  SignInfoParams,
  RootThunkApi
>('config/getSignInfo', async (params, api) => {
  return sendAsync(getSignInfo(params), api)
})
// 获取广告图片
export const getImgPhoto = createAsyncThunk<any, getImgFnParams, RootThunkApi>(
  'config/getImgPhoto',
  async (params, api) => {
    return sendAsync(getImgFn(params), api)
  }
)
// 关闭广告图片
export const getCloseImgPhoto = createAsyncThunk<any, void, RootThunkApi>(
  'config/getCloseImgPhoto',
  async (_, api) => {
    return sendAsync(getCloseImgPhotoFn(), api)
  }
)
// export const getImgPhoto = createAsyncThunk<
//   string,
//   getImgFnParams,
//   RootThunkApi
// >('/config/getImgPhoto', async (params, api) => {
//   return sendAsync(getImgFn(params), api)
// })
// 医保签到
export const signInToMedicalAsync = createAsyncThunk<
  string,
  SignParams,
  RootThunkApi
>('/config/signInMedical', async (params, api) => {
  return sendAsync(signInToMedical(params), api)
})

// 医保签退
export const signOutToMedicalAsync = createAsyncThunk<
  string,
  SignBody,
  RootThunkApi
>('/config/signOutMedical', async (body, api) => {
  return sendAsync(signOutToMedical(body), api)
})

export interface AccountCheckListValue {
  current: number
  hitCount: false
  pages: number
  records: {
    accountDate: string
    chaoxianjzffy: string
    duizhangjg: string
    duizhangjgsm: string
    fuhezcfwje: string
    gerenfdzje: string
    gerenxjzc: string
    gerenzhzc: string
    gongwuyylbzzjzc: string
    id: number
    insuranceCode: string
    insuranceRegion: string
    isLocal: string
    jibenylbxtcjjzc: string
    jibenylbxtcjjzfbl: string
    jiesuanbs: string
    jijinzfze: string
    jumindbbxjjzc: string
    patientName: string
    qingsuanjbjg: string
    qingsuanlb: string
    qitazc: string
    qiyebcylbxjjzc: string
    quanzifje: string
    shijizfqfx: string
    status: number
    tenantId: number
    xianxingzfje: string
    xianzhonglx: string
    yiliaofze: string
    yiliaojzjjzc: string
    yiyuancdfy: string
    zhigongdeylfybzjjzc: string
  }[]
  searchCount: false
  size: number
  total: number
}

// 日对帐记录查询
export const getAccountCheckListAsync = createAsyncThunk<
  AccountCheckListValue,
  AccountCheckListParams,
  RootThunkApi
>('/config/accountCheck', async (params, api) => {
  return sendAsync(getAccountCheckList(params), api)
})

// 医保日对帐
export const accountCheckAsync = createAsyncThunk<
  string,
  CheckBody,
  RootThunkApi
>('/config/accountCheck', async (body, api) => {
  return sendAsync(accountCheck(body), api)
})

// 明细下载 9101上传空文件
export const updateFireAsync = createAsyncThunk<
  string,
  updateBody,
  RootThunkApi
>('/config/updateFire', async (body, api) => {
  return sendAsync(updateFire(body), api)
})

// 明细下载 3202
export const detailCheckAsync = createAsyncThunk<
  string,
  detailBody,
  RootThunkApi
>('/config/detailCheck', async (body, api) => {
  return sendAsync(detailCheck(body), api)
})

// 明细下载 9102 文件下载
export const fileDownloadAsync = createAsyncThunk<
  string,
  downloadBody,
  RootThunkApi
>('/config/fileDownload', (body, api) => {
  return sendAsync(fileDownload(body), api)
})

// 明细下载 3102 - 10000 文件解析
export const filePageParseAsync = createAsyncThunk<
  string,
  pageParseBody,
  RootThunkApi
>('/config/filePageParse', async (body, api) => {
  return sendAsync(filePageParse(body), api)
})

// 目录下载 - 10000 文件解析
export const mlDownFilePageParseAsync = createAsyncThunk<
  string,
  mlpageParseBody,
  RootThunkApi
>('/config/filePageParse', (body, api) => {
  return sendAsync(mlDownfilePageParse(body), api)
})

// 异地对账 1505
export const elseWhereCheckAsync = createAsyncThunk<
  string,
  elseWhereCheckBody,
  RootThunkApi
>('/config/elseWhereCheck', async (body, api) => {
  return sendAsync(elseWhereCheck(body), api)
})

// 异地对账 1812
export const elseWhereDownloadCheckAsync = createAsyncThunk<
  any,
  elseWhereDownloadBody,
  RootThunkApi
>('/config/elseWhereDownloadCheck', async (body, api) => {
  return sendAsync(elseWhereDownloadCheck(body), api)
})

export const getAllopatryListAsync = createAsyncThunk<
  any,
  AllopatryListBody,
  RootThunkApi
>('/config/getAllopatryList', async (body, api) => {
  return sendAsync(getAllopatryList(body), api)
})

// 前五疾病
export const getRankingListAsync = createAsyncThunk<void, void, RootThunkApi>(
  '/config/getRankingListAsync',
  async (_, api) => {
    return sendAsync(rankingList(), api)
  }
)

// 2601 医保冲正
export const medicalTradeAsync = createAsyncThunk<
  string,
  medicalTradeBody,
  RootThunkApi
>('/config/medicalTrade', async (body, api) => {
  return sendAsync(medicalTrade(body), api)
})

// 本地冲正
export const localMedicalTradeAsync = createAsyncThunk<
  string,
  localMedicalTradeBody,
  RootThunkApi
>('/config/localMedicalTrade', async (body, api) => {
  return sendAsync(localMedicalTrade(body), api)
})

// 本地更正
export const checkLocalDataAsync = createAsyncThunk<
  string,
  checkDataParams,
  RootThunkApi
>('/config/checkLocalData', async (params, api) => {
  return sendAsync(checkLocalData(params), api)
})

// 本地更正
export const insuranceTradeExportAsync = createAsyncThunk<
  string,
  InsuranceTradeExportParams,
  RootThunkApi
>('/config/insuranceTradeExportAsync', async (params, api) => {
  return sendAsync(insuranceTradeExport(params), api)
})

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    traceRoute: (state, action: PayloadAction<Breadcrumb>) => {
      let breadcrumb = state.breadcrumbs.find(
        (m) => m.name === action.payload.name
      )
      if (!breadcrumb) {
        breadcrumb = state.fixed.find((m) => m.name === action.payload.name)
      }
      if (breadcrumb) {
        breadcrumb.path = action.payload.path
        breadcrumb.query = action.payload.query
        breadcrumb.state = action.payload.state
        breadcrumb.isNavBar = !!action.payload.isNavBar //若是点击面包屑路由跳转，则不刷新页面，否则读取缓存组件
      } else {
        state.breadcrumbs.push(action.payload)
      }
      // state.breadcrumbs = state.breadcrumbs.map((v) => ({
      //   ...v,
      //   isNavBar: true,
      // }))
      sessionStorage.setItem(BreadcrumbKey, JSON.stringify(state.breadcrumbs))
      state.current = breadcrumb || action.payload
    },
    untraceRoute: (state, action: PayloadAction<Breadcrumb>) => {
      let current = state.current
      if (action.payload.name === state.current?.name) {
        current = undefined
      }
      state.breadcrumbs = _.chain(state.breadcrumbs)
        .filter((b) => b.name !== action.payload.name)
        .value()
      if (!current) {
        current = _.chain(state.breadcrumbs).last().value()
      }
      if (!current && state.fixed.length > 0) {
        current = state.fixed[0]
      }
      sessionStorage.setItem(BreadcrumbKey, JSON.stringify(state.breadcrumbs))
      state.current = current
    },
    traceHint: (state, action: PayloadAction<string>) => {
      if (
        state.current ||
        (state.fixed.length === 0 && state.breadcrumbs.length === 0)
      ) {
        return
      }
      const breadcrumb =
        state.fixed.find((b) => b.path === action.payload) ||
        state.breadcrumbs.find((b) => b.path === action.payload)
      state.current = breadcrumb
    },
    removeBreadcrumbs: (state, action: PayloadAction<void>) => {
      state.breadcrumbs = []
      state.current = undefined
      sessionStorage.removeItem(BreadcrumbKey)
    },
    setMenus: (state, action: PayloadAction<Menu[]>) => {
      state.current = undefined
      const json = sessionStorage.getItem(BreadcrumbKey) || '[]'
      state.breadcrumbs = JSON.parse(json)
      state.menus = action.payload
      if (action.payload.length > 0) {
        let home = action.payload[0]
        while (home.subMenus.length > 0) {
          home = home.subMenus[0]
        }
        state.fixed = [home]
        if (state.breadcrumbs.length == 0) {
          state.current = home
        }
      }
    },
    setTwoMenus: (state, action: PayloadAction<Menu[]>) => {
      state.current = undefined
      const json = sessionStorage.getItem(BreadcrumbKey) || '[]'
      state.breadcrumbs = JSON.parse(json)
      state.twoMenus = action.payload
      if (action.payload.length > 0) {
        let home = action.payload[0]
        while (home.subMenus.length > 0) {
          home = home.subMenus[0]
        }
        state.fixed = [home]
        if (state.breadcrumbs.length == 0) {
          state.current = home
        }
      }
    },
    setThreeMenus: (state, action: PayloadAction<Menu[]>) => {
      state.threeMenus = action.payload
    },
    setSignInfo: (state, action: any) => {
      state.signInfo = action.payload
    },
    setShowFollowUp: (state, action: any) => {
      state.showFollowUp = action.payload
    },
    setRankingList: (state, action: any) => {
      state.rankingList = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSignInfoAsync.fulfilled, (state, action) => {
      if (JSON.stringify(action.payload) !== '{}') {
        sessionStorage.setItem('cycleCode', action.payload.signCycleCd)
      } else {
        sessionStorage.removeItem('cycleCode')
      }
      state.signInfo = action.payload
    })
  },
})

export const BackendDefaultMenus: Menu[] = [
  {
    id: 'toothHome',
    parentId: '-1',
    icon: 'home',
    path: '/toothHome',
    name: '首页',
    category: MenuCategory.Menu,
    sort: 1,
    subMenus: [],
  },
  {
    id: 'toothPatient',
    parentId: '-1',
    icon: 'patient',
    path: '/toothPatient',
    name: '患者管理',
    category: MenuCategory.Menu,
    sort: 2,
    subMenus: [],
  },
  {
    id: 'appointment',
    parentId: '-1',
    icon: 'appointment',
    path: '/appointment',
    name: '预约',
    category: MenuCategory.Menu,
    sort: 3,
    subMenus: [],
  },
  {
    id: 'followUp',
    parentId: '-1',
    icon: 'followUp',
    path: '/followUp',
    name: '随访',
    category: MenuCategory.Menu,
    sort: 4,
    subMenus: [],
  },
  {
    id: 'payment',
    parentId: '-1',
    icon: 'payment',
    path: '/payment',
    name: '收费',
    category: MenuCategory.Menu,
    sort: 5,
    subMenus: [],
  },
  {
    id: 'doctorstation',
    parentId: '-1',
    icon: 'doctorstation',
    path: '/doctor/station',
    name: '医生工作站',
    category: MenuCategory.Menu,
    sort: 5,
    subMenus: [],
  },
  {
    id: '/medicalSation',
    parentId: '-1',
    icon: 'medicalSation',
    path: '/medicalSation',
    name: '医技站',
    category: MenuCategory.Menu,
    sort: 5,
    subMenus: [],
  },
  {
    id: '/storage/station',
    parentId: '-1',
    icon: 'warehousestation',
    path: '/storage/station',
    name: '库房',
    category: MenuCategory.Menu,
    sort: 6,
    subMenus: [],
  },
  {
    id: '/member',
    parentId: '-1',
    icon: '/member',
    path: '/member',
    name: '会员管理',
    category: MenuCategory.Menu,
    sort: 6,
    subMenus: [],
  },
  {
    id: '/registration',
    parentId: '-1',
    icon: 'registration',
    path: '/registration',
    name: '挂号',
    category: MenuCategory.Menu,
    sort: 7,
    subMenus: [],
  },
  {
    id: '/statistics/outpatient',
    parentId: '-1',
    icon: 'registration',
    path: '/statistics/outpatient',
    name: '通用患者',
    category: MenuCategory.Menu,
    sort: 7,
    subMenus: [],
  },
  {
    id: '/followUp',
    parentId: '-1',
    icon: 'registration',
    path: '/followUp',
    name: '通用随访',
    category: MenuCategory.Menu,
    sort: 7,
    subMenus: [],
  },
  {
    id: '/operationStation',
    parentId: '-1',
    icon: 'operationStation',
    path: '/operationStation',
    name: '通用手术',
    category: MenuCategory.Menu,
    sort: 7,
    subMenus: [],
  },
]

export const {
  traceRoute,
  untraceRoute,
  traceHint,
  removeBreadcrumbs,
  setMenus,
  setTwoMenus,
  setThreeMenus,
  setSignInfo,
  setShowFollowUp,
  setRankingList,
} = layoutSlice.actions

export function getMenus(): RootThunk {
  return (dispatch, getState, args) => {
    // dispatch(setMenus(BackendDefaultMenus))
    if (selectApplicationType(getState()) === ApplicationType.Management) {
      dispatch(setMenus(BackendDefaultMenus))
    } else {
      api(getUserMenus(), (data: any, dispatch) => {
        const menus = constructMenuLayers(
          data.records.filter((v: any) => v.category != 5)
        )
        dispatch(setMenus(menus))
      })(dispatch, getState, args)
    }
  }
}


export function getTwoMenus(): RootThunk {
  return (dispatch, getState, args) => {
    if (selectApplicationType(getState()) === ApplicationType.Management) {
      dispatch(setTwoMenus(BackendDefaultMenus))
      dispatch(setThreeMenus(BackendDefaultMenus))
    } else {
      api(getUserTwoMenus(), (data: any, dispatch) => {
        const twoMenus = constructMenuLayers(
          data.filter((v: any) => v.category != 5)
        )
        const threeMenus = data.filter((v: any) => v.level == 4)
        dispatch(setTwoMenus(twoMenus))
        dispatch(setThreeMenus(threeMenus))
      })(dispatch, getState, args)
    }
  }
}

export const selectMenus = (state: RootState): Menu[] => state.layout.menus

export const selectTwoMenus = (state: RootState): Menu[] =>
  state.layout.twoMenus

export const selectThreeMenus = (state: RootState): Menu[] =>
  state.layout.threeMenus

export const selectFixedMenus = (state: RootState): Menu[] => state.layout.fixed

export const selectBreadcrumbs = (state: RootState): Breadcrumb[] =>
  state.layout.breadcrumbs

export const selectCurrentRoute = (state: RootState): Breadcrumb | undefined =>
  state.layout.current

export const selectSignInfo = (state: RootState): SignInfoValue | undefined =>
  state.layout.signInfo

export const selectFollowUp = (state: RootState) => state.layout.showFollowUp

export const selectRankingList = (state: RootState) => state.layout.rankingList

export default layoutSlice.reducer
