import { unwrapResult } from "@reduxjs/toolkit";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  TimePicker,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch } from "../../../app/store";
import {
  PayMethod,
} from "../../../models/payment";
import { Checks, Settinglable, Settings } from "../../../models/setting";
import {
  getTenantCategoryName,
  Tenant,
  TenantCategory,
} from "../../../models/tenant";
import { stringTrim } from "../../../utils/StringUtils";
import { setSettings } from "../../settings/settingsSlice";
import {
  getSettingDetail,
  saveSettingLabel,
  selectNextSendDay,
  selectSettings,
  selectTenant,
  setIsChange,
  setNextSendDay,
} from "./settingSlice";
import styles from '../Users.module.css'

export const SettingInsurance = (): ReactElement => {

  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm();

  const tenant = useSelector(selectTenant);

  const settings = useSelector(selectSettings);

  const nextSendDay = useSelector(selectNextSendDay)

  const [previewPayMethod, setPreviewPayMethod] = useState(PayMethod.Cash);

  useEffect(() => {
    if (tenant) {
      form.setFieldsValue({
        ...tenant,
        registeredFund: null,
        // tenantCategory: getTenantCategoryName(tenant.tenantCategory)
      });
    }
    if (settings) {
      const defaultMethod =
        (parseInt(settings[Checks.Mrzffs]) as PayMethod) || PayMethod.Cash;
      setPreviewPayMethod(defaultMethod);
      dispatch(
        setSettings({
          [Settings.Treatment.DoNotWarnDoctorMismatch]:
            settings[Checks.Gbkdysstx] || false,
          [Settings.Treatment.Settlement]: settings[Checks.Zjjs] || false,
          [Settings.Recipe.ChangePrice]: !(settings[Checks.Gbxgjg] || false),
          [Settings.Payment.DefautMethod]: defaultMethod,
        })
      );
      const value = settings[Checks.Zdrjsj];
      form.setFieldsValue({
        ...settings,
        [Checks.Zdrjsj]:
          value === "" || value === undefined || value === "Invadlid date"
            ? undefined
            : moment(value, "HH:mm"),
      });
    }
  }, [tenant, settings]);

  useEffect(() => {
    dispatch(getSettingDetail());
  }, []);

  const invoiceLayout = {
    labelCol: {
      span: 10,
    },
  };

  const rules = [{
    pattern: new RegExp(/^([0-9]+[\d]*(.[0-9]{1,2})?)$/, "g"),
    message: "请输入保留小数两位以内的数",
  },]

  const phoneRules = [{
    pattern: /^1\d{10}$/,
    message: "请输入正确的手机号",
  },]

  return (
    <div
      style={{
        height: "calc(100%)",
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        background: "#FFFFFF",
        borderRadius: "10px",
        overflowY: "scroll",
      }}
    >
      <Form
        labelAlign="right"
        initialValues={{ remember: true, [Checks.ISEDIT]: '1', [Checks.FYFS]: '1' }}
        form={form}
        autoComplete="off"
        onFinish={(values) => {
          if ((values[Checks.SJHM] && !values[Checks.TSPL]) || (!values[Checks.SJHM] && values[Checks.TSPL])) {
            notification.error({
              message: '请完善手机号码或推送频率'
            })
            return
          }
          const pattern = /^[0-9]*$/;
          const t = _.chain(values)
            .omitBy((_, k) => pattern.test(k))
            .value() as Tenant;
          const f = _.chain(values)
            .pickBy((_, k) => pattern.test(k))
            .mapValues(
              (v, k) =>
              ({
                type: k,
                value:
                  k === Checks.Zdrjsj.toString()
                    ? moment(v).format("HH:mm:ss")
                    : v?.toString(),
              } as Settinglable)
            )
            .values()
            .value();
          dispatch(
            saveSettingLabel({
              tenant: {
                ...tenant,
                ...t,
              },
              functionList: f,
            })
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: "机构设置保存成功",
                duration: 3,
              });
              dispatch(getSettingDetail());
            })
            .catch(() => {
              notification.error({ message: "机构设置保存失败", duration: 3 });
              dispatch(getSettingDetail());
            }).finally(() => {
              dispatch(setIsChange(false))
            })
        }}
        onValuesChange={(values, all) => {
          dispatch(setIsChange(true))
        }}
      >
        <Row style={{ marginLeft: "40px", marginTop: "20px" }}>
          <Col style={{ marginLeft: "20px" }} span={10}>
            <Form.Item {...invoiceLayout} label='中草药年度帖均额度' rules={rules} getValueFromEvent={stringTrim} name={Checks.ZCYNDJTED}>
              <Input addonAfter="元" />
            </Form.Item>
            <Form.Item {...invoiceLayout} label='医保基金年度支付限额' rules={rules} getValueFromEvent={stringTrim} name={Checks.YBJJNDZFXE}>
              <Input addonAfter="元" />
            </Form.Item>
            <Form.Item {...invoiceLayout} label='医保列支医疗费年度支付限额' rules={rules} getValueFromEvent={stringTrim} name={Checks.YBLZYLFNDZFXE}>
              <Input addonAfter="元" />
            </Form.Item>
            <Form.Item {...invoiceLayout} label='人次人头比' rules={rules} getValueFromEvent={stringTrim} name={Checks.RCRTB}>
              <Input addonAfter="%" />
            </Form.Item>
            <Form.Item {...invoiceLayout} label='次均年度费用限额' rules={rules} getValueFromEvent={stringTrim} name={Checks.CJNDFYXE}>
              <Input addonAfter="元" />
            </Form.Item>
            <Form.Item {...invoiceLayout} label='人均年度费用限额' rules={rules} getValueFromEvent={stringTrim} name={Checks.RJNDFYXE}>
              <Input addonAfter="元" />
            </Form.Item>
            <Form.Item {...invoiceLayout} label='手机号码' rules={phoneRules} getValueFromEvent={stringTrim} name={Checks.SJHM}>
              <Input maxLength={11} />
            </Form.Item>
            <Form.Item name={Checks.TSPL} className={styles.formItem}>
              <Form.Item {...invoiceLayout} label='推送频率' rules={[{
                pattern: /^\d+$/,
                message: "请输入正确数字",
              }]} getValueFromEvent={stringTrim} name={Checks.TSPL} >
                <Input addonAfter="天/次" onChange={(e) => {
                  const day = moment(new Date()).add(e.target.value, 'days').format('YYYY/MM/DD')
                  dispatch(setNextSendDay(day))
                }} />
              </Form.Item>
              <span className={styles.span}>下次推送时间：{nextSendDay}</span>
            </Form.Item>
            {/* <Form.Item {...invoiceLayout} label='推送频率' rules={[{
                pattern: /^\d+$/,
                message: "请输入正确的手机号",
              }]} getValueFromEvent={stringTrim} name={Checks.TSPL} >
                <Input addonAfter="天/次" />
              </Form.Item> */}
          </Col>
        </Row>
        <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button htmlType="submit" type="primary">
            保存
          </Button>
        </div>
      </Form>
    </div>
  );
};
