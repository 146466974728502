/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-15 16:59:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-22 19:05:07
 */
import { SearchOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../../../../app/store';
import { outPatientInfoOptions } from '../../../../../models/toothPatient';
import { OutpatientInfoTime } from '../../components/outpatientInfoTime';
import { getPatientInfoAsync, getRegistByPatientIdAsync } from '../../patientSlice';
import { PatientPanelProps } from '../patientPanel';
import styles from '../patientPanel.module.css'
const { Text } = Typography

const { Option } = Select

export const OutpatientInfo = ({ patientId }: PatientPanelProps): ReactElement => {

  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [dataList, setDataList] = useState<any[]>([])

  const [searchList, setSearchList] = useState<any[]>([])

  const [registrationId, setRegistrationId] = useState()

  useEffect(() => {
    if (!patientId) return
    getPage()
  }, [patientId, registrationId])

  const getPage = () => {
    dispatch(getRegistByPatientIdAsync({ patientId, registrationId })).then(unwrapResult).then((res: any) => {
      setDataList(res.map((v: any) => ({ ...v, open: false, children: [] })))
    })
  }

  useEffect(() => {
    dispatch(getRegistByPatientIdAsync({ patientId, size: 50 })).then(unwrapResult).then((res: any) => {
      setSearchList(res)
    })
  }, [])

  return (
    <div>
      <Form
        form={form}
        onFinish={(values) => {
          setRegistrationId(values.registrationId)
        }}
      >
        <div className={styles.outpatientInfoContainer}>
          <Row
            style={{
              flex: 1,
            }}
            gutter={10}
          >
            <Col span={10}>
              <Form.Item name='registrationId'>
                <Select
                  showSearch
                  placeholder='搜索近50条数据'
                  optionFilterProp='children'
                  allowClear
                  onChange={form.submit}
                  filterOption={(input, option: any) => {
                    return (option?.children ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }}
                >
                  {searchList.length != 0 &&
                    searchList.map((v: any) => (
                      <Option key={v.registrationId} value={v.registrationId}>
                        {`${v.registrationTime} ${v.treatmentDoctorName} (${
                          v.treatmentDepartmentName
                        }) ${
                          v.insuranceCode == 'ZIFEI'
                            ? '自费'
                            : v.insuranceCode == '' || v.insuranceCode == null
                            ? ' 未知险种'
                            : '医保刷卡'
                        } ${v.disease}`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={3}>
              <Form.Item name='departmentId'>
                <Select
                  placeholder='类型'
                  allowClear
                  onChange={form.submit}
                >
                  {outPatientInfoOptions.map((d) => (
                    <Select.Option key={d.value} value={d.value}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={2}>
              <Button type='primary' onClick={getPage}>
                查询
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <div
        style={{
          padding: 10,
        }}
      >
        <Text italic>
          <span style={{ color: '#999', fontSize: 14 }}>
            仅展示1年以内的历史就诊信息
          </span>
        </Text>
      </div>
      <div>
        <OutpatientInfoTime
          data={dataList}
          onAction={async (id) => {
            let childrenList: any[] = dataList.find(
              (v: any) => v.registrationId == id
            ).children
            if (childrenList.length == 0) {
              const list: any = await dispatch(
                getPatientInfoAsync({
                  registrationId: id,
                  type: 0,
                })
              ).then(unwrapResult)
              childrenList = list
            }
            const newDataList = dataList.map((v: any) => {
              return v.registrationId == id
                ? { ...v, open: !v.open, children: childrenList }
                : v
            })
            setDataList(newDataList)
          }}
        />
      </div>
    </div>
  )
}
