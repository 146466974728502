import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  notification,
  Radio,
  Row,
  Select,
  Space,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CREDENTIAL_KEY,
  selectEdition,
  selectUserDepartmentId,
  selectUserId,
} from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import { Label } from '../../../compnents/widgets/Label'
import { DateTimeFormatSimple } from '../../../models/datetime'
import {
  getSourceTypeName,
  getVisitTypeName,
  RegistrationCategory,
  SourceTypeCategories,
  VisitType,
} from '../../../models/registration'
import { Settings } from '../../../models/setting'
import { Gender, getGenderName } from '../../../models/user'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getTriageHistorylist } from '../../commonTriageDetail/commonTriageDetailSlice'
import { selectBooleanSetting } from '../../settings/settingsSlice'
import { getTriageItem } from '../../triage/listslice'
import styles from './Register.module.css'
import {
  addAppointmentAsync,
  getAppointmentDoctorsInDepartment,
  getAppointmentPatientDetail,
  getDoctorsTimesAsync,
  getDoctorsTimeStepAsync,
  getAppointmentOutpatientDepartmentAsync,
  getPatientDetail,
  getPatientList,
  selectAppointmentDoctors,
  selectDepartments,
  selectPatientDetail,
  selectPatients,
  setDoctors,
  setPatient,
  selectPatientPageId,
  getAppointmentPhoneAsync,
} from './registerSlice'
import {
  appointmentStatusOptions,
  getAppointTypeName,
} from '../../../models/appointment'
import { DoctorsTimeStepParams } from '../../../services/user'
import { useLocation } from 'react-router-dom'
import { traceRoute } from '../../../layouts/layoutSlice'
import {
  selectAddAppointmentInfo,
  setAddAppointmentInfo,
} from '../../appointment/appointmentSelice'
import { getIdentityName, identitys } from '../../../models/ToothHome'

interface AppointmentRegisterProps {
  activeKey?: any
  AppointmentId?: number
  status?: number //his 口腔版 1新增预约 2修改预约
  onsubmit?: () => void
  onCancel?: () => void
  addPatient?: () => void
}

const { TextArea } = Input

export const AppointmentRegister = ({
  activeKey,
  AppointmentId,
  status,
  onsubmit,
  onCancel,
  addPatient,
}: AppointmentRegisterProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const { Option } = Select

  const [form] = Form.useForm()

  const addAppointmentInfo = useSelector(selectAddAppointmentInfo) // 预约 日 的快速挂号

  const theme = useContext(ThemeContext)

  const departments = useSelector(selectDepartments)

  const patients = useSelector(selectPatients)

  const patient: any = useSelector(selectPatientDetail)

  // const doctors = useSelector(selectDoctors)

  const AppointmentDoctors = useSelector(selectAppointmentDoctors)

  const departmentId = useSelector(selectUserDepartmentId)

  const storedepartmentId =
    localStorage.getItem('appointmentDepartmentId') || departmentId

  const userId = useSelector(selectUserId)

  const edtion = useSelector(selectEdition) //区分口腔版还是普通

  const skipPrinting = useSelector(
    selectBooleanSetting(Settings.Registration.SkipPrinting)
  )
  const [text, setText] = useState<any>('')

  const [keyword, setKeyword] = useState('')

  const [keywords, setKeywords] = useState('')

  const [patientOverlayDisabled, setPatientOverlayDisabled] = useState(false) //  是否禁用搜索框

  const [patientId, setPatientId] = useState('')

  const [TriageDetailId, setTriageDetailId] = useState('')

  const [list, setList] = useState<any[]>([])

  const [triageDetail, setTriageDetail] = useState<any>()

  const [source, setSource] = useState(0)

  const [loading, setLoading] = useState(false)

  const [isPatientOverlayVisible, setIsPatientOverlayVisible] = useState(false)

  const [
    isPatientNameOverlayVisible,
    setIsPatientNameOverlayVisible,
  ] = useState(false)

  const [hasCard, setHasCard] = useState(false)

  const [showStopAction, setShowStopAction] = useState(false)

  const [useDays, setUseDays] = useState([])

  const [appointmentDateDisable, setAppointmentDateDisable] = useState(true)

  const [appointmentTimeDisable, setAppointmentTimeDisable] = useState(true)

  const [startTimeList, setStartTimeList] = useState([])

  const [endTimeList, setEndTimeList] = useState<any>([])

  const [sendMessage, setSendMessage] = useState(true)

  const credentialsInfo = JSON.parse(
    localStorage.getItem(CREDENTIAL_KEY) || '{}'
  )

  const [timeStr, setTimeStr] = useState({
    start: '',
    end: '',
  })

  const [appointmentPhone, setAppointmentPhone] = useState('')

  const isPatientPageId = useSelector(selectPatientPageId)

  useEffect(() => {
    // 编辑，预约回显
    if (location?.state?.id || AppointmentId) {
      dispatch(
        getAppointmentPatientDetail(location?.state?.id || AppointmentId)
      )
      dispatch(getAppointmentPhoneAsync())
        .then(unwrapResult)
        .then((res: any) => {
          setAppointmentPhone(res.adviceTel)
        })
    } else {
      dispatch(getAppointmentPhoneAsync())
        .then(unwrapResult)
        .then((res: any) => {
          setAppointmentPhone(res.adviceTel)
        })
    }
  }, [location, AppointmentId])

  // 患者管理过来
  useEffect(() => {
    if (isPatientPageId) {
      dispatch(getPatientDetail(isPatientPageId))
    }
  }, [isPatientPageId])

  const required = {
    rules: [
      {
        required: true,
      },
    ],
  }

  const formGutter = 20

  const firstColumnLayout = {
    labelCol: {
      flex: '0 0 7rem',
    },
  }

  const secondColumnLayout = {
    labelCol: {
      flex: '0 0 8.25rem',
    },
  }

  const thirdColumnLayout = {
    labelCol: {
      flex: '0 0 6rem',
    },
  }

  const patientOverlay = (
    <Menu>
      {patients.map((p) => (
        <Menu.Item
          key={p.id}
          onClick={() => {
            setIsPatientOverlayVisible(false)
            dispatch(getPatientDetail(p.id))
            status == 1 && setKeyword(p.name)
            status == 1 && setPatientOverlayDisabled(true)
            status == 1 && setPatientId(p.id)
          }}
        >
          <Space>
            <span>{p.name}</span>
            <span>{getGenderName(p.sex)}</span>
            <span>{p.phone}</span>
            <span>{p.id}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )
  const taiagelistOverlay = (
    <Menu>
      {list.map((p) => (
        <Menu.Item
          key={p.id}
          onClick={() => {
            setIsPatientOverlayVisible(false)
            setIsPatientNameOverlayVisible(false)
            dispatch(getTriageItem(p.id))
              .then(unwrapResult)
              .then((v) => {
                setTriageDetail(v)
              })
          }}
        >
          <Space>
            <span>{p.patientName}</span>
            <span>{getGenderName(p.sex)}</span>
            <span>{p.phone}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )

  const getIdCardInfo = (idCard: string) => {
    const date = new Date()
    const getMonth = date.getMonth() + 1 //当前月份
    const getFullYear = date.getFullYear() //当前年份

    let month = 0
    let year = 0
    let birthday = ''
    //  身份证上的年月日
    if (idCard) {
      if (idCard.length === 18) {
        month = parseInt(idCard?.substr(10, 2)) //身份证上的月份
        year = parseInt(idCard?.substr(6, 4)) //身份证上的年份
        birthday = moment(idCard?.substr(6, 8)).format(DateTimeFormatSimple)
      } else if (idCard.length === 15 || idCard.length === 16) {
        //16位示例330522199603021031，少了两位年份
        //15位示例330522960302103，少了两位年份，最后的序号是3位
        month = parseInt(idCard?.substr(8, 2)) //身份证上的月份
        year = parseInt('19' + idCard?.substr(6, 2)) //身份证上的年份
        birthday = moment('19' + idCard?.substr(6, 6)).format(
          DateTimeFormatSimple
        )
      }
      if (
        (idCard.length != 18 && idCard.length != 16 && idCard.length != 15) ||
        idCard === ''
      ) {
        form.setFieldsValue({
          ageYear: '',
          age: '',
          ageMonth: '',
          birthday: '',
        })
      } else {
        if (getMonth - month > 0) {
          form.setFieldsValue({
            ageYear: getFullYear - year,
            age: getFullYear - year,
            ageMonth: getMonth - month,
            birthday: idCard.length === 18 ? birthday : '',
          })
        } else if (getMonth - month < 0) {
          form.setFieldsValue({
            ageYear: getFullYear - year - 1,
            age: getFullYear - year - 1,
            ageMonth: 12 - (month - getMonth),
            birthday: idCard.length === 18 ? birthday : '',
          })
        } else if (getMonth - month === 0) {
          form.setFieldsValue({
            ageYear: getFullYear - year - 1,
            age: getFullYear - year - 1,
            ageMonth: 0,
            birthday: idCard.length === 18 ? birthday : '',
          })
        }
      }
    }
  }

  const readMessage = () => {
    if (hasCard) {
      notification.info({
        message: '医保信息只读，不可修改',
        duration: 1,
      })
    }
  }

  const updateBirthday = () => {
    const year = parseInt(form.getFieldValue('ageYear')) || 0
    const month = parseInt(form.getFieldValue('ageMonth')) || 0
    if (year !== 0 || month !== 0) {
      const months = year * 12 + month
      const birthday = moment().subtract(months, 'months').format('YYYY/MM/DD')
      form.setFieldsValue({ birthday })
    }
  }

  const updateYearsAndMonths = () => {
    const date = moment(form.getFieldValue('birthday'))
    if (!date.isValid()) {
      return
    }
    const duration = moment.duration(moment().diff(date))
    if (duration.years() < 0 || duration.months() < 0) {
      return
    }
    form.setFieldsValue({
      ageYear: duration.years(),
      ageMonth: duration.months() == 0 ? 1 : duration.months(),
    })
  }

  useEffect(() => {
    dispatch(getAppointmentOutpatientDepartmentAsync())
  }, [dispatch])
  useEffect(() => {
    if (!status) return
    form.resetFields()
    setKeyword('')
    setPatientOverlayDisabled(false)
  }, [])

  useEffect(() => {
    const flag = sessionStorage.getItem('SENDAPPOINTMENTMESSAGE') || '0'
    setSendMessage(!!Number(flag))

    const departmentId =
      addAppointmentInfo?.appointmentDepartmentId &&
      ((addAppointmentInfo?.appointmentDepartmentId as unknown) as number) > 0
        ? addAppointmentInfo?.appointmentDepartmentId
        : patient?.appointmentDoctorId &&
          ((patient?.appointmentDoctorId as unknown) as number) > 0
        ? patient?.appointmentDoctorId
        : storedepartmentId
    //   if (departments.find((d) => d.id === departmentId)) {
    //     form.setFieldsValue({ appointmentDepartmentId: departmentId })
    //     dispatch(setDoctors([]))
    dispatch(getAppointmentDoctorsInDepartment(departmentId))
    //   }
  }, [])

  useEffect(() => {
    if (!departments.length || location?.state?.id) return
    let id = ''
    if (departments.some((v: any) => v?.id == storedepartmentId)) {
      id = storedepartmentId
    } else {
      id = departments[0].id
    }
    // form.setFieldsValue({ appointmentDepartmentId: id })
  }, [storedepartmentId])

  useEffect(() => {
    if (source === 0) {
      dispatch(getPatientList(keyword || keywords))
    } else if (source === 1) {
      dispatch(getTriageHistorylist({ name: keyword || keywords, size: 10 }))
        .then(unwrapResult)
        .then((v: any) => {
          setList(v?.records)
        })
    }
  }, [dispatch, keyword, keywords, source])

  useEffect(() => {
    if (Object.keys(addAppointmentInfo).length) {
      form.setFieldsValue({
        ...patient,
        appointmentDepartmentId: addAppointmentInfo.appointmentDepartmentId?.toString(),
        appointmentDoctorId: addAppointmentInfo.appointmentDoctorId?.toString(),
        workTime: {
          appointmentStartTime: addAppointmentInfo.appointmentStartTime,
          appointmentEndTime: addAppointmentInfo.appointmentEndTime,
        },
        appointmentDate: moment(addAppointmentInfo.appointmentDate),
        // appointmentDate: undefined
      })
      setTimeStr({
        start: addAppointmentInfo.appointmentStartTime || '',
        end: addAppointmentInfo.appointmentEndTime || '',
      })
      dispatch(
        getAppointmentDoctorsInDepartment(
          addAppointmentInfo?.appointmentDepartmentId || '0'
        )
      )
      dispatch(
        getDoctorsTimesAsync(addAppointmentInfo?.appointmentDoctorId || '0')
      )
        .then(unwrapResult)
        .then((res: any) => {
          setUseDays(res)
          setAppointmentDateDisable(false)
          if (res.some((v: any) => v.scheduleDate)) {
            const scheduleObj = res.find(
              (v: any) => v.scheduleDate == addAppointmentInfo.appointmentDate
            )
            scheduleObj &&
              dispatch(
                getDoctorsTimeStepAsync({
                  id: scheduleObj.id,
                  doctorId: addAppointmentInfo?.appointmentDoctorId,
                })
              ).then((res: any) => {
                setEndTimeList(res?.payload?.endTimeList)
                const startTimeList = res?.payload?.startTimeList.map(
                  (el: any) => {
                    const str =
                      el.timeScale +
                      (el.status == 1 ? '(已满)' : `(余${el.surplusNum}人)`)
                    return {
                      label: str,
                      value: el.timeScale,
                      status: el.status,
                    }
                  }
                )
                setStartTimeList(startTimeList)
                setAppointmentTimeDisable(false)
              })
          }
        })
      // dispatch(getDoctorsTimeStepAsync({
      //   id: '',
      //   doctorId: addAppointmentInfo?.appointmentDoctorId
      // })).then((res: any) => {
      //   setEndTimeList(res?.payload?.endTimeList)
      //   const startTimeList = res?.payload?.startTimeList.map((el: any) => {
      //     const str = el.timeScale + (el.status == 1 ? '(已满)' : `(余${el.surplusNum}人)`)
      //     return {
      //       label: str,
      //       value: el.timeScale,
      //       status: el.status
      //     }
      //   })
      //   setStartTimeList(startTimeList)
      //   setAppointmentTimeDisable(false)
      // })
      dispatch(setAddAppointmentInfo({}))
    }
  }, [addAppointmentInfo])

  const doctorId =
    form?.getFieldValue('appointmentDoctorId') ||
    patient?.appointmentDoctorId?.toString()

  useEffect(() => {
    if (doctorId) {
      form.setFieldsValue({
        appointmentDoctorId: doctorId,
      })
    }
    if (doctorId && AppointmentDoctors?.length) {
      const flag = AppointmentDoctors?.filter(
        (v: any) => v.doctorId == doctorId
      )
      if (flag?.length == 0) {
        form.setFieldsValue({
          appointmentDoctorId: '',
        })
      }
    }
  }, [AppointmentDoctors, doctorId])

  useEffect(() => {
    const days = form.getFieldValue('appointmentDate')
      ? moment(form.getFieldValue('appointmentDate')).diff(moment(), 'days') + 1
      : ''
    const weeks = getWeek(form.getFieldValue('appointmentDate'))
    if (form.getFieldValue('appointmentDate')) {
      if (
        moment(form.getFieldValue('appointmentDate')).format(
          DateTimeFormatSimple
        ) == moment().format(DateTimeFormatSimple)
      ) {
        setText('今天，' + weeks)
      } else {
        setText(days + '天后，' + weeks)
      }
    }

    if (patient && source === 0) {
      setPatientId(patient.id)
      form.setFieldsValue({
        ...patient,
        appointmentDepartmentId:
          form.getFieldValue('appointmentDepartmentId') ||
          patient.appointmentDepartmentId?.toString(),
        appointmentDoctorId:
          form.getFieldValue('appointmentDoctorId') ||
          patient.appointmentDoctorId?.toString(),
        workTime: {
          appointmentStartTime:
            form.getFieldValue('workTime')?.appointmentStartTime ||
            patient.appointmentStartTime,
          appointmentEndTime:
            form.getFieldValue('workTime')?.appointmentEndTime ||
            patient.appointmentEndTime,
        },
        appointmentDate:
          form.getFieldValue('appointmentDate') ||
          (patient?.scheduleId ? moment(patient.appointmentDate) : undefined),
        appointmentType:
          patient?.appointmentType > 0 ? patient?.appointmentType : '',
        // appointmentDate: undefined
      })

      status && setKeyword(patient.name)
      setKeywords(patient.name)
      status && setPatientOverlayDisabled(true)
      //setAppointmentPhone(patient.phone)
      if (patient?.appointmentDepartmentId) {
        dispatch(
          getAppointmentDoctorsInDepartment(
            patient?.appointmentDepartmentId || '0'
          )
        )
      }
      if (patient?.appointmentDoctorId) {
        dispatch(getDoctorsTimesAsync(patient?.appointmentDoctorId || '0'))
          .then(unwrapResult)
          .then((res: any) => {
            setUseDays(res)
            setAppointmentDateDisable(!patient?.scheduleId)
          })
      }
      // dispatch(getDoctorsTimesAsync(patient?.appointmentDoctorId || '0')).then(unwrapResult).then((res: any) => {
      //   setUseDays(res)
      //   setAppointmentDateDisable(!patient?.scheduleId)
      // })
      patient?.scheduleId &&
        dispatch(
          getDoctorsTimeStepAsync({
            id: patient?.scheduleId,
            doctorId: patient?.appointmentDoctorId,
          })
        ).then((res: any) => {
          setEndTimeList(res?.payload?.endTimeList)
          const startTimeList = res?.payload?.startTimeList.map((el: any) => {
            const str =
              el.timeScale +
              (el.status == 1 ? '(已满)' : `(余${el.surplusNum}人)`)
            return {
              label: str,
              value: el.timeScale,
              status: el.status,
            }
          })
          setStartTimeList(startTimeList)
          setAppointmentTimeDisable(false)
        })
      dispatch(setPatient(undefined))
    } else if (triageDetail && source === 1) {
      setTriageDetailId(triageDetail.id)
      setKeywords(triageDetail.patientName)
      form.setFieldsValue({
        ...triageDetail,
        name: triageDetail.patientName,
        ageYear:
          triageDetail.age > 0
            ? triageDetail.age
            : (triageDetail?.ageMonth || '-') + '个月',
        idCardNumber: triageDetail.identityCard,
        //   ageMonth: "",
        birthday: triageDetail.birthday ? triageDetail.birthday : '',
        appointmentType:
          triageDetail?.appointmentType > 0
            ? triageDetail?.appointmentType
            : '',
      })

      setTriageDetail(undefined)
      setIsPatientOverlayVisible(false)
    }
  }, [dispatch, patient, triageDetail])

  // useEffect(() => {
  //   if (doctors && doctors.length === 1) {
  //     form.setFieldsValue({
  //       registrationDoctorId: doctors[0].id,
  //     })
  //   } else {
  //     const storeuserId = sessionStorage.getItem('registrationDoctorId') || userId
  //     if (doctors.find((u) => u.id === storeuserId)) {
  //       form.setFieldsValue({ registrationDoctorId: storeuserId })
  //     }
  //   }
  // }, [doctors])

  const submit = (values: any) => {
    if (sendMessage && !values.phone) {
      notification.info({
        message: '请填写预约患者手机号',
      })
      return
    }
    if (status == 1 && !patientOverlayDisabled) {
      notification.info({
        message: '请选择预约患者',
      })
      return
    }

    setLoading(true)
    const scheduleObj: any = useDays?.find(
      (v: any) =>
        v.scheduleDate == moment(values.appointmentDate).format('YYYY-MM-DD')
    )
    const data = {
      ...values,
      id: location?.state?.id
        ? location?.state?.id
        : AppointmentId
        ? AppointmentId
        : undefined,
      workTime: undefined,
      appointmentStartTime: values.workTime.appointmentStartTime,
      appointmentEndTime: values.workTime.appointmentEndTime,
      appointmentDate: moment(values.appointmentDate).format('YYYY-MM-DD'),
      scheduleId: scheduleObj?.id,
      smsStatus: sendMessage ? 1 : 0,
      patientId,
    }

    dispatch(addAppointmentAsync(data))
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message:
            location?.state?.id || AppointmentId
              ? '预约修改成功'
              : '新增预约成功',
        })
        setLoading(false)
        setPatientOverlayDisabled(false)
        if (status) {
          form.resetFields()
          setKeyword('')
          onsubmit && onsubmit()
        } else {
          dispatch(
            traceRoute({
              name: '预约',
              path: '/appointment',
              state: {
                activeId: activeKey || '4',
              },
            })
          )
        }
      })
    // .finally(() => setPatientOverlayDisabled(false))
  }

  const getWeek = (date: any) => {
    // 时间戳
    const week = moment(date).day()
    switch (week) {
      case 1:
        return '星期一'
      case 2:
        return '星期二'
      case 3:
        return '星期三'
      case 4:
        return '星期四'
      case 5:
        return '星期五'
      case 6:
        return '星期六'
      case 0:
        return '星期日'
    }
  }

  return (
    <Col
      style={{
        // paddingTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
      }}
    >
      {addPatient && (
        <Button
          style={{
            position: 'absolute',
            top: 13,
            right: 0,
          }}
          type='primary'
          onClick={() => {
            addPatient()
          }}
        >
          新增患者
        </Button>
      )}
      {/* {!location?.state?.id && !status && (
        <Row
          style={{
            height: '5rem',
            borderRadius: theme.br,
            backgroundColor: theme.pn,
            padding: status == 2 ? '' : '0 30px',
          }}
          align='middle'
          wrap={false}
          className={styles.bar}
        >
          <Label label='历史患者搜索' style={{ marginLeft: 30 }} />
          <Dropdown
            overlay={source === 0 ? patientOverlay : taiagelistOverlay}
            visible={isPatientOverlayVisible}
            disabled={hasCard}
            // onVisibleChange={(v) => setIsPatientOverlayVisible(v)}
          >
            <Input
              allowClear
              placeholder={
                source === 0 ? '姓名/性别/手机号/就诊卡号' : '姓名/性别/手机号'
              }
              prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
              value={keyword}
              onFocus={() => {
                if (source === 0) {
                  dispatch(getPatientList(keyword))
                  setSource(0)
                } else if (source === 1) {
                  dispatch(getTriageHistorylist({ name: keyword, size: 10 }))
                    .then(unwrapResult)
                    .then((v: any) => {
                      setList(v?.records)
                    })
                  setSource(1)
                }
                setIsPatientOverlayVisible(true)
                setIsPatientNameOverlayVisible(false)
              }}
              onBlur={() => {
                setTimeout(() => {
                  setIsPatientOverlayVisible(false)
                }, 300)
              }}
              onClick={() => {
                setIsPatientOverlayVisible(true)
                setIsPatientNameOverlayVisible(false)
              }}
              onChange={(e) => {
                setIsPatientOverlayVisible(true)
                setIsPatientNameOverlayVisible(false)
                setKeyword(e.target.value)
                setKeywords('')
              }}
              style={{ width: '28rem', marginLeft: 40 }}
            />
          </Dropdown>
          <Col span={10} style={{ marginLeft: '20px' }}>
            <Radio.Group
              defaultValue={source}
              onChange={(e: any) => {
                setKeywords('')
                setKeyword('')
                setPatientId('')
                setTriageDetailId('')
                setSource(e.target.value)
                form.resetFields()
              }}
            >
              <Radio value={0}>就诊患者</Radio>
              <Radio value={1}>预检分诊患者</Radio>
            </Radio.Group>
          </Col>
        </Row>
      )} */}

      <Form
        form={form}
        autoComplete='off'
        className={styles.form}
        colon={false}
        labelAlign='left'
        initialValues={{
          registrationCategory: RegistrationCategory.SelfPay,
          sex: Gender.Unknown,
          skipPrinting,
          relationship: '本人', //联系方式关系
        }}
        onFinish={(values) => {
          submit({
            ...values,
            name: status == 1 ? keyword : values.name,
          })
        }}
        style={{
          flex: 1,
          marginTop: 5,
          // marginBottom: 20,
          paddingBottom: 20,
          borderRadius: theme.br,
          backgroundColor: theme.pn,
          overflow: status ? '' : 'auto',
        }}
      >
        <Label
          label={status ? '基本信息' : '患者基本信息'}
          style={{ marginLeft: 30 }}
        />
        <Divider
          style={{ marginTop: 0, marginBottom: 20, borderColor: theme.c3 }}
        />
        {!status && (
          <>
            <Row
              gutter={formGutter}
              style={{ marginLeft: 5, marginRight: 20 }}
              className={styles.formItem}
            >
              <Col span={16} order={1}>
                <Form.Item
                  required
                  name='name'
                  label='患者姓名'
                  {...firstColumnLayout}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Row wrap={false}>
                    {status == 1 && (
                      <Dropdown
                        overlay={
                          source === 0 ? patientOverlay : taiagelistOverlay
                        }
                        visible={isPatientOverlayVisible}
                        disabled={patientOverlayDisabled}
                      >
                        <Input
                          allowClear
                          placeholder='患者姓名/助记码/手机号/病历号'
                          value={keyword}
                          onFocus={() => {
                            if (source === 0) {
                              dispatch(getPatientList(keyword))
                              setSource(0)
                            } else if (source === 1) {
                              dispatch(
                                getTriageHistorylist({
                                  name: keyword,
                                  size: 10,
                                })
                              )
                                .then(unwrapResult)
                                .then((v: any) => {
                                  setList(v?.records)
                                })
                              setSource(1)
                            }
                            setIsPatientOverlayVisible(true)
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              setIsPatientOverlayVisible(false)
                            }, 300)
                          }}
                          onClick={() => {
                            setIsPatientOverlayVisible(true)
                          }}
                          onChange={(e) => {
                            setIsPatientOverlayVisible(true)
                            setKeyword(e.target.value)
                          }}
                          style={{ width: '28rem' }}
                        />
                      </Dropdown>
                    )}
                    {status != 1 && (
                      <Dropdown
                        overlay={
                          source === 0 ? patientOverlay : taiagelistOverlay
                        }
                        visible={isPatientNameOverlayVisible}
                        disabled={!!patientId || !!TriageDetailId}

                        // onVisibleChange={(v) => setIsPatientOverlayVisible(v)}
                      >
                        <Input
                          disabled={!!patientId || !!TriageDetailId}
                          readOnly={hasCard}
                          allowClear
                          placeholder={
                            source === 0
                              ? '姓名/性别/手机号/就诊卡号'
                              : '姓名/性别/手机号'
                          }
                          prefix={
                            <SearchOutlined style={{ color: theme.tc3 }} />
                          }
                          value={keywords}
                          onFocus={() => {
                            if (source === 0) {
                              dispatch(getPatientList(keywords))
                              setSource(0)
                            } else if (source === 1) {
                              dispatch(
                                getTriageHistorylist({
                                  name: keywords,
                                  size: 10,
                                })
                              )
                                .then(unwrapResult)
                                .then((v: any) => {
                                  setList(v?.records)
                                })
                              setSource(1)
                            }
                            setIsPatientNameOverlayVisible(true)
                            setIsPatientOverlayVisible(false)
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              setIsPatientNameOverlayVisible(false)
                            }, 300)
                          }}
                          onClick={() => {
                            setIsPatientNameOverlayVisible(true)
                            setIsPatientOverlayVisible(false)
                          }}
                          onChange={(e) => {
                            setIsPatientNameOverlayVisible(true)
                            setIsPatientOverlayVisible(false)
                            setKeywords(e.target.value)
                            setKeyword('')
                          }}
                        />
                      </Dropdown>
                      // <Form.Item
                      //   noStyle
                      //   name='name'
                      //   rules={[
                      //     {
                      //       required: true,
                      //       max: 40,
                      //       message: '请输入患者姓名',
                      //     },
                      //   ]}
                      // >
                      //   <Input
                      //     autoFocus
                      //     disabled={!!patientId || !!TriageDetailId}
                      //     readOnly={hasCard}
                      //     onClick={() => readMessage()}
                      //   />
                      // </Form.Item>
                    )}
                    {/* <Button
                   style={{
                     color: theme.tc3,
                     height: 32,
                     marginLeft: 10,
                     backgroundColor: theme.c3,
                     border: 'none',
                   }}
                   onClick={() => {
                     setPatientId('')
                     setTriageDetailId('')
                     form.setFieldsValue({ name: '' })
                   }}
                 >
                   清空
                 </Button> */}
                  </Row>
                </Form.Item>
              </Col>
              <Col span={8} order={3}>
                <Form.Item
                  label='年龄'
                  {...firstColumnLayout}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                  }}
                  required
                >
                  <Row wrap={false} align='middle' style={{ height: '50px' }}>
                    <Form.Item
                      name='ageYear'
                      noStyle
                      dependencies={['ageMonth']}
                      rules={[
                        {
                          pattern: /^\d{0,3}$/,
                          message: '请输入正确的年龄',
                        },
                        ({ getFieldValue }) => ({
                          validator: (_, value) => {
                            if (!value && !getFieldValue('ageMonth')) {
                              return Promise.reject(
                                new Error('请输入正确的年龄')
                              )
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <Input
                        readOnly={hasCard}
                        onClick={() => readMessage()}
                        onChange={updateBirthday}
                        style={{ maxWidth: '3rem' }}
                      />
                    </Form.Item>
                    <div
                      style={{
                        color: theme.tc2,
                        fontSize: '1rem',
                        margin: '0 6px',
                      }}
                    >
                      岁
                    </div>
                    <Form.Item
                      name='ageMonth'
                      noStyle
                      dependencies={['ageYear']}
                      rules={[
                        {
                          pattern: /^(0?[0-9]|1[0-1])$/,
                          message: '请输入正确的月份',
                        },
                      ]}
                    >
                      <Input
                        readOnly={hasCard}
                        onClick={() => readMessage()}
                        onChange={updateBirthday}
                        style={{ maxWidth: '3rem' }}
                      />
                    </Form.Item>
                    <div
                      style={{
                        color: theme.tc2,
                        fontSize: '1rem',
                        margin: '0 6px',
                      }}
                    >
                      月
                    </div>
                    <Form.Item
                      name='birthday'
                      noStyle
                      style={{ flex: 1 }}
                      rules={[
                        { max: 10, message: '最多10个字' },
                        {
                          pattern: /^(?:1\d{3}|2\d{3})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])|(?:1\d{3}|2\d{3})\/(?:0[1-9]|1[0-2])\/(?:0[1-9]|[12]\d|3[01])|(?:1\d{3}|2\d{3})(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])$/,
                          message: '格式参考:19950816',
                        },
                      ]}
                    >
                      <Input
                        onChange={updateYearsAndMonths}
                        readOnly={hasCard}
                        onClick={() => readMessage()}
                      />
                    </Form.Item>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={8} order={2}>
                <Form.Item
                  {...required}
                  label='性别'
                  name='sex'
                  className={styles.normal}
                  {...thirdColumnLayout}
                >
                  <Radio.Group disabled={hasCard}>
                    {[Gender.Male, Gender.Female, Gender.Unknown].map((g) => (
                      <Radio key={g} value={g}>
                        {getGenderName(g)}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
              {/* </Row>
         <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}> */}
              <Col span={8} order={6}>
                <Form.Item
                  label='联系方式'
                  name='phone'
                  {...firstColumnLayout}
                  rules={[
                    {
                      pattern: new RegExp(
                        /^\d{3}-\d{8}|\d{4}-\d{7}|^(1[3,4,5,6,7,8,9][0-9]{9})$/
                      ),
                      message: '请输入正确的联系方式',
                      required: status == 1,
                    },
                  ]}
                >
                  <Input maxLength={13} />
                </Form.Item>
              </Col>
              <Col span={8} order={4}>
                <Form.Item
                  label='身份证号'
                  name='idCardNumber'
                  {...secondColumnLayout}
                  rules={[
                    {
                      pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                      message: '身份证格式错误',
                    },
                    {
                      max: 18,
                      message: '最长18位!',
                    },
                  ]}
                >
                  <Input
                    readOnly={hasCard}
                    onClick={() => readMessage()}
                    onChange={(e: any) => {
                      // setIdCard(e.target.value)
                      getIdCardInfo(e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8} order={5}>
                <Form.Item
                  label='工作单位'
                  name='companyName'
                  {...thirdColumnLayout}
                  rules={[
                    {
                      max: 120,
                      message: '最长120位!',
                    },
                  ]}
                >
                  <Input readOnly={hasCard} onClick={() => readMessage()} />
                </Form.Item>
              </Col>
              {/* </Row>
         <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}> */}
              <Col span={16} order={7}>
                <Form.Item
                  label='地址'
                  name='address'
                  {...secondColumnLayout}
                  rules={[
                    {
                      max: 250,
                      message: '最长250位!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={formGutter}
              style={{ marginLeft: 5, marginRight: 20 }}
              className={styles.formItem}
            >
              <Col span={8}>
                <Form.Item
                  label='紧急联系人'
                  name='emergencyContactName'
                  {...firstColumnLayout}
                  rules={[
                    {
                      max: 40,
                      message: '最长40位!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='紧急联系人方式'
                  name='emergencyContactPhone'
                  className={styles.normal}
                  {...secondColumnLayout}
                  rules={[
                    {
                      pattern: /^[0-9-]*$/,
                      message: '请输入正确的联系方式',
                    },
                    {
                      max: 40,
                      message: '最长40位!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='患者来源'
                  name='source'
                  {...thirdColumnLayout}
                >
                  <Select
                    placeholder='患者来源'
                    allowClear
                    // suffixIcon={
                    //   <CaretDownFilled style={{ color: theme.tc3 }} />
                    // }
                  >
                    {SourceTypeCategories.map((v) => {
                      return (
                        <Option value={v} key={v}>
                          {getSourceTypeName(v)}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Label
              label={status == 1 ? '预约信息' : '就诊信息'}
              style={{ marginLeft: 30 }}
            />
            <Divider
              style={{ marginTop: 0, marginBottom: 20, borderColor: theme.c3 }}
            />
            <Row
              gutter={formGutter}
              style={{ marginLeft: 5, marginRight: 20 }}
              className={styles.formItem}
            >
              <Col span={8}>
                <Form.Item
                  label='医生科室'
                  name='appointmentDepartmentId'
                  {...firstColumnLayout}
                  {...required}
                >
                  <Select
                    placeholder='请选择'
                    // suffixIcon={
                    //   <CaretDownFilled style={{ color: theme.tc3 }} />
                    // }
                    onChange={(id: string) => {
                      form.setFieldsValue({
                        appointmentDate: '',
                        workTime: {
                          appointmentStartTime: '',
                          appointmentEndTime: '',
                        },
                        appointmentDoctorId: '',
                      })
                      setAppointmentDateDisable(true)
                      setStartTimeList([])
                      setEndTimeList([])
                      sessionStorage.setItem('appointmentDepartmentId', id)
                      dispatch(setDoctors([]))
                      dispatch(getAppointmentDoctorsInDepartment(id))
                    }}
                  >
                    {departments.map((d) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='医生'
                  name='appointmentDoctorId'
                  {...required}
                  {...secondColumnLayout}
                >
                  <Select
                    placeholder='请选择'
                    // suffixIcon={
                    //   <CaretDownFilled style={{ color: theme.tc3 }} />
                    // }
                    // showSearch
                    // filterOption={(input, option) => {
                    //   return option?.className ? (option?.className as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                    // }}
                    onChange={(e) => {
                      form.setFieldsValue({
                        appointmentDate: '',
                        workTime: {
                          appointmentStartTime: '',
                          appointmentEndTime: '',
                        },
                      })
                      setStartTimeList([])
                      setEndTimeList([])
                      dispatch(getDoctorsTimesAsync(e))
                        .then(unwrapResult)
                        .then((res: any) => {
                          setUseDays(res)
                          setAppointmentDateDisable(false)
                        })
                    }}
                  >
                    {AppointmentDoctors.map((d) => (
                      <Select.Option key={d.doctorId} value={d.doctorId}>
                        {d.doctorName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='预约方式'
                  name='appointmentType'
                  {...thirdColumnLayout}
                >
                  <Select
                    placeholder='请选择'
                    // suffixIcon={
                    //   <CaretDownFilled style={{ color: theme.tc3 }} />
                    // }
                  >
                    {appointmentStatusOptions.map((d) => (
                      <Select.Option key={d} value={d}>
                        {getAppointTypeName(d)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={formGutter} style={{ marginLeft: 5, marginRight: 20 }}>
              <Col span={8}>
                <Form.Item
                  label='接诊类型'
                  name='receiveTag'
                  initialValue={VisitType.First}
                  {...required}
                  {...firstColumnLayout}
                >
                  <Radio.Group>
                    {[VisitType.First, VisitType.Subsequence].map((t) => (
                      <Radio key={t} value={t}>
                        {getVisitTypeName(t)}
                      </Radio>
                    ))}
                  </Radio.Group>
                  {/* <Select
                 suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
               >
                 {[VisitType.First, VisitType.Subsequence].map((t) => (
                   <Select.Option key={t} value={t}>
                     {getVisitTypeName(t)}
                   </Select.Option>
                 ))}
               </Select> */}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='预约日期'
                  name='appointmentDate'
                  {...required}
                  {...secondColumnLayout}
                >
                  <DatePicker
                    style={{
                      width: text ? '65%' : '100%',
                      position: 'relative',
                    }}
                    allowClear={false}
                    showToday={false}
                    disabled={appointmentDateDisable}
                    disabledDate={(date) => {
                      const days = moment(date).format('YYYY-MM-DD')
                      if (useDays.length) {
                        return !useDays
                          .map((v: any) => {
                            return v.scheduleDate
                          })
                          .some((v) => v == days)
                      } else {
                        return true
                      }
                    }}
                    onChange={(v) => {
                      const days = moment(v).diff(moment(), 'days') + 1
                      const weeks = getWeek(v)
                      if (
                        moment(v).format(DateTimeFormatSimple) ==
                        moment().format(DateTimeFormatSimple)
                      ) {
                        setText('今天，' + weeks)
                      } else {
                        setText(days + '天后，' + weeks)
                      }
                      if (!v) return
                      form.setFieldsValue({
                        workTime: {
                          appointmentStartTime: '',
                          appointmentEndTime: '',
                        },
                      })
                      setAppointmentTimeDisable(false)
                      const scheduleObj: any = useDays?.find(
                        (el: any) =>
                          el.scheduleDate == moment(v).format('YYYY-MM-DD')
                      )
                      const params: DoctorsTimeStepParams = {
                        id: scheduleObj?.id,
                        doctorId: form.getFieldValue('appointmentDoctorId'),
                      }

                      dispatch(getDoctorsTimeStepAsync(params)).then(
                        (res: any) => {
                          setEndTimeList(res?.payload?.endTimeList)
                          const startTimeList = res?.payload?.startTimeList.map(
                            (el: any) => {
                              const str =
                                el.timeScale +
                                (el.status == 1
                                  ? '(已满)'
                                  : `(余${el.surplusNum}人)`)
                              return {
                                label: str,
                                value: el.timeScale,
                                status: el.status,
                              }
                            }
                          )
                          setStartTimeList(startTimeList)
                        }
                      )
                    }}
                  />
                </Form.Item>
                <span
                  style={{
                    position: 'absolute',
                    top: 6,
                    bottom: 0,
                    left: '76%',
                    color: '#f47137',
                  }}
                >
                  {text ? '(' + text + ')' : ''}
                </span>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='预约时间'
                  name='workTime'
                  {...thirdColumnLayout}
                  rules={[{ required: true, message: ' ' }]}
                >
                  <Input.Group compact>
                    <Form.Item
                      name={['workTime', 'appointmentStartTime']}
                      rules={[{ required: true, message: '请选择时间' }]}
                    >
                      <Select
                        style={{ width: '130px' }}
                        onChange={(v) => {
                          setTimeStr({
                            start: v,
                            end: timeStr.end,
                          })
                        }}
                      >
                        {startTimeList?.length &&
                          startTimeList.map((v: any) => (
                            <Option
                              key={v.value}
                              value={v.value}
                              disabled={v.status == 1}
                            >
                              {v.label}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    &nbsp;&nbsp; ~ &nbsp;&nbsp;
                    <Form.Item
                      name={['workTime', 'appointmentEndTime']}
                      rules={[{ required: true, message: '请选择时间' }]}
                    >
                      <Select
                        style={{ width: '130px' }}
                        onChange={(v) => {
                          setTimeStr({
                            start: timeStr.start,
                            end: v,
                          })
                        }}
                      >
                        {endTimeList?.length &&
                          endTimeList.map((v: any) => (
                            <Option key={v} value={v}>
                              {v}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {status && (
          <>
            <Row>
              <Col span={12}>
                <Form.Item
                  required
                  label='患者姓名'
                  {...firstColumnLayout}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Dropdown
                    overlay={source === 0 ? patientOverlay : taiagelistOverlay}
                    visible={isPatientOverlayVisible}
                    disabled={patientOverlayDisabled}
                  >
                    <Input
                      allowClear
                      placeholder='患者姓名/助记码/手机号/病历号'
                      value={keyword}
                      onFocus={() => {
                        if (source === 0) {
                          dispatch(getPatientList(keyword))
                          setSource(0)
                        } else if (source === 1) {
                          dispatch(
                            getTriageHistorylist({ name: keyword, size: 10 })
                          )
                            .then(unwrapResult)
                            .then((v: any) => {
                              setList(v?.records)
                            })
                          setSource(1)
                        }
                        setIsPatientOverlayVisible(true)
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setIsPatientOverlayVisible(false)
                        }, 300)
                      }}
                      onClick={() => {
                        setIsPatientOverlayVisible(true)
                      }}
                      onChange={(e) => {
                        setIsPatientOverlayVisible(true)
                        setKeyword(e.target.value)
                        setKeywords('')
                      }}
                    />
                  </Dropdown>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  {...required}
                  label='性别'
                  name='sex'
                  className={styles.normal}
                  {...firstColumnLayout}
                >
                  <Radio.Group disabled={hasCard}>
                    {[Gender.Male, Gender.Female, Gender.Unknown].map((g) => (
                      <Radio key={g} value={g}>
                        {getGenderName(g)}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label='年龄'
                  {...firstColumnLayout}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                  }}
                  required
                >
                  <Row wrap={false} align='middle' style={{ height: '50px' }}>
                    <Form.Item
                      name='ageYear'
                      dependencies={['ageMonth']}
                      rules={[
                        {
                          pattern: /^\d{0,3}$/,
                          message: '请输入正确的年龄',
                        },
                        ({ getFieldValue }) => ({
                          validator: (_, value) => {
                            if (!value && !getFieldValue('ageMonth')) {
                              return Promise.reject(
                                new Error('请输入正确的年龄')
                              )
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <Input
                        readOnly={hasCard}
                        onClick={() => readMessage()}
                        onChange={updateBirthday}
                        style={{ maxWidth: '3rem' }}
                      />
                    </Form.Item>
                    <div
                      style={{
                        color: theme.tc2,
                        fontSize: '1rem',
                        margin: '0 6px',
                      }}
                    >
                      岁
                    </div>
                    <Form.Item
                      name='ageMonth'
                      dependencies={['ageYear']}
                      rules={[
                        {
                          pattern: /^(0?[0-9]|1[0-1])$/,
                          message: '请输入正确的月份',
                        },
                      ]}
                    >
                      <Input
                        readOnly={hasCard}
                        onClick={() => readMessage()}
                        onChange={updateBirthday}
                        style={{ maxWidth: '3rem' }}
                      />
                    </Form.Item>
                    <div
                      style={{
                        color: theme.tc2,
                        fontSize: '1rem',
                        margin: '0 6px',
                      }}
                    >
                      月
                    </div>
                    <Form.Item
                      name='birthday'
                      style={{ flex: 1 }}
                      rules={[
                        { max: 10, message: '最多10个字' },
                        {
                          pattern: /^(?:1\d{3}|2\d{3})-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])|(?:1\d{3}|2\d{3})\/(?:0[1-9]|1[0-2])\/(?:0[1-9]|[12]\d|3[01])|(?:1\d{3}|2\d{3})(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])$/,
                          message: '格式参考:19950816',
                        },
                      ]}
                    >
                      <Input
                        style={{ width: '100%' }}
                        onChange={updateYearsAndMonths}
                        readOnly={hasCard}
                        onClick={() => readMessage()}
                      />
                    </Form.Item>
                  </Row>
                </Form.Item>
              </Col>
              {edtion == 1 ? (
                <Col span={12}>
                  <Row gutter={10}>
                    <Col span={16}>
                      <Form.Item
                        name='phone'
                        {...firstColumnLayout}
                        label='联系方式'
                        rules={[
                          {
                            pattern: new RegExp(
                              /^\d{3}-\d{8}|\d{4}-\d{7}|^(1[3,4,5,6,7,8,9][0-9]{9})$/
                            ),
                            message: '请输入正确的联系方式',
                            required: status == 1,
                          },
                        ]}
                      >
                        <Input allowClear maxLength={13} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name='relationship'
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: '请选择与本人关系',
                          },
                        ]}
                      >
                        <Select placeholder='请选择与本人关系' allowClear>
                          {identitys.map((v: any) => {
                            return (
                              <Select.Option key={v} value={getIdentityName(v)}>
                                {getIdentityName(v)}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col span={12}>
                  <Form.Item
                    label='联系方式'
                    name='phone'
                    {...firstColumnLayout}
                    rules={[
                      {
                        pattern: new RegExp(
                          /^\d{3}-\d{8}|\d{4}-\d{7}|^(1[3,4,5,6,7,8,9][0-9]{9})$/
                        ),
                        message: '请输入正确的联系方式',
                        required: status == 1,
                      },
                    ]}
                  >
                    <Input maxLength={13} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Label
              label={status == 1 ? '预约信息' : '就诊信息'}
              style={{ marginLeft: 30 }}
            />
            <Divider
              style={{ marginTop: 0, marginBottom: 20, borderColor: theme.c3 }}
            />
            <Row>
              <Col span={12}>
                <Form.Item
                  label='预约科室'
                  name='appointmentDepartmentId'
                  {...firstColumnLayout}
                  {...required}
                >
                  <Select
                    placeholder='请选择'
                    // suffixIcon={
                    //   <CaretDownFilled style={{ color: theme.tc3 }} />
                    // }
                    onChange={(id: string) => {
                      form.setFieldsValue({
                        appointmentDate: '',
                        workTime: {
                          appointmentStartTime: '',
                          appointmentEndTime: '',
                        },
                        appointmentDoctorId: '',
                      })
                      setAppointmentDateDisable(true)
                      setStartTimeList([])
                      setEndTimeList([])
                      sessionStorage.setItem('appointmentDepartmentId', id)
                      dispatch(setDoctors([]))
                      dispatch(getAppointmentDoctorsInDepartment(id))
                    }}
                  >
                    {departments.map((d) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='预约医生'
                  name='appointmentDoctorId'
                  {...required}
                  {...firstColumnLayout}
                >
                  <Select
                    placeholder='请选择'
                    // suffixIcon={
                    //   <CaretDownFilled style={{ color: theme.tc3 }} />
                    // }
                    onChange={(e) => {
                      form.setFieldsValue({
                        appointmentDate: '',
                        workTime: {
                          appointmentStartTime: '',
                          appointmentEndTime: '',
                        },
                      })
                      setStartTimeList([])
                      setEndTimeList([])
                      dispatch(getDoctorsTimesAsync(e))
                        .then(unwrapResult)
                        .then((res: any) => {
                          setUseDays(res)
                          setAppointmentDateDisable(false)
                        })
                    }}
                  >
                    {AppointmentDoctors.map((d) => (
                      <Select.Option key={d.doctorId} value={d.doctorId}>
                        {d.doctorName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={edtion == 0 ? 8 : 12}>
                <Form.Item
                  label='预约方式'
                  name='appointmentType'
                  {...firstColumnLayout}
                >
                  <Select
                    placeholder='请选择'
                    // suffixIcon={
                    //   <CaretDownFilled style={{ color: theme.tc3 }} />
                    // }
                  >
                    {appointmentStatusOptions.map((d) => (
                      <Select.Option key={d} value={d}>
                        {getAppointTypeName(d)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='就诊类型'
                  name='receiveTag'
                  initialValue={VisitType.First}
                  {...required}
                  {...firstColumnLayout}
                >
                  <Radio.Group>
                    {[VisitType.First, VisitType.Subsequence].map((t) => (
                      <Radio key={t} value={t}>
                        {getVisitTypeName(t)}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ marginTop: 10 }}>
                <Form.Item
                  label='预约日期'
                  name='appointmentDate'
                  {...required}
                  {...firstColumnLayout}
                >
                  <DatePicker
                    style={{
                      width: text ? '68%' : '100%',
                      position: 'relative',
                    }}
                    allowClear={false}
                    showToday={false}
                    disabled={appointmentDateDisable}
                    disabledDate={(date) => {
                      const days = moment(date).format('YYYY-MM-DD')
                      if (useDays.length) {
                        return !useDays
                          .map((v: any) => {
                            return v.scheduleDate
                          })
                          .some((v) => v == days)
                      } else {
                        return true
                      }
                    }}
                    onChange={(v) => {
                      const days = moment(v).diff(moment(), 'days') + 1
                      const weeks = getWeek(v)
                      if (
                        moment(v).format(DateTimeFormatSimple) ==
                        moment().format(DateTimeFormatSimple)
                      ) {
                        setText('今天，' + weeks)
                      } else {
                        setText(days + '天后，' + weeks)
                      }
                      if (!v) return
                      form.setFieldsValue({
                        workTime: {
                          appointmentStartTime: '',
                          appointmentEndTime: '',
                        },
                      })
                      setAppointmentTimeDisable(false)
                      const scheduleObj: any = useDays?.find(
                        (el: any) =>
                          el.scheduleDate == moment(v).format('YYYY-MM-DD')
                      )
                      const params: DoctorsTimeStepParams = {
                        id: scheduleObj?.id,
                        doctorId: form.getFieldValue('appointmentDoctorId'),
                      }

                      dispatch(getDoctorsTimeStepAsync(params)).then(
                        (res: any) => {
                          setEndTimeList(res?.payload?.endTimeList)
                          const startTimeList = res?.payload?.startTimeList.map(
                            (el: any) => {
                              const str =
                                el.timeScale +
                                (el.status == 1
                                  ? '(已满)'
                                  : `(余${el.surplusNum}人)`)
                              return {
                                label: str,
                                value: el.timeScale,
                                status: el.status,
                              }
                            }
                          )
                          setStartTimeList(startTimeList)
                        }
                      )
                    }}
                  />
                </Form.Item>
                <span
                  style={{
                    position: 'absolute',
                    top: 6,
                    bottom: 0,
                    left: '78%',
                    color: '#f47137',
                  }}
                >
                  {text ? '(' + text + ')' : ''}
                </span>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='预约时间'
                  name='workTime'
                  {...firstColumnLayout}
                  rules={[{ required: true, message: ' ' }]}
                >
                  <Input.Group compact style={{ marginTop: 10 }}>
                    <Form.Item
                      name={['workTime', 'appointmentStartTime']}
                      rules={[{ required: true, message: '请选择时间' }]}
                    >
                      <Select
                        style={{ width: '130px' }}
                        onChange={(v) => {
                          setTimeStr({
                            start: v,
                            end: timeStr.end,
                          })
                        }}
                      >
                        {startTimeList?.length &&
                          startTimeList.map((v: any) => (
                            <Option
                              key={v.value}
                              value={v.value}
                              disabled={v.status == 1}
                            >
                              {v.label}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    &nbsp;&nbsp; ~ &nbsp;&nbsp;
                    <Form.Item
                      name={['workTime', 'appointmentEndTime']}
                      rules={[{ required: true, message: '请选择时间' }]}
                    >
                      <Select
                        style={{ width: '130px' }}
                        onChange={(v) => {
                          setTimeStr({
                            start: timeStr.start,
                            end: v,
                          })
                        }}
                      >
                        {endTimeList?.length &&
                          endTimeList.map((v: any) => (
                            <Option key={v} value={v}>
                              {v}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {edtion == 1 ? (
          <>
            <Row>
              <Col span={12}>
                <Form.Item
                  label='预约项目'
                  name='appointmentItem'
                  {...firstColumnLayout}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={formGutter} style={{ marginLeft: 5 }}>
              <Col span={16}>
                <Form.Item
                  label='预约备注'
                  name='appointmentComment'
                  {...thirdColumnLayout}
                >
                  <TextArea showCount maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row
              gutter={formGutter}
              style={{ marginLeft: 5, marginRight: 20 }}
              className={styles.formItem}
            >
              <Col span={16} order={1}>
                <Form.Item
                  label='预约项目'
                  name='appointmentItem'
                  {...firstColumnLayout}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={formGutter}
              style={{ marginLeft: 5, marginRight: 20 }}
              className={styles.formItem}
            >
              <Col span={16}>
                <Form.Item
                  label='预约备注'
                  name='appointmentComment'
                  {...firstColumnLayout}
                >
                  <TextArea showCount maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {!location?.state?.id && (
          <Row style={{ paddingLeft: 40, marginTop: 10 }}>
            <Col span={24} style={{ color: sendMessage ? '#000' : '#999' }}>
              <Checkbox
                checked={sendMessage}
                onChange={(v) => {
                  setSendMessage(v.target.checked)
                  const flag = v.target.checked ? '1' : '0'
                  sessionStorage.setItem('SENDAPPOINTMENTMESSAGE', flag)
                }}
                style={{ marginRight: 8 }}
              />
              发送短信 {credentialsInfo?.shortName} 您已成功预约
              {form.getFieldValue('appointmentDepartmentId')
                ? departments.find(
                    (v) => v.id == form.getFieldValue('appointmentDepartmentId')
                  )?.name
                : '-'}
              请在
              {form.getFieldValue('appointmentDate')
                ? moment(form.getFieldValue('appointmentDate'))?.format(
                    'YYYY-MM-DD'
                  )
                : '-'}
              &nbsp;&nbsp;{timeStr.start}
              {timeStr.start && timeStr.end ? '~' + timeStr.end : ''}
              到挂号处登记信息。地址：{credentialsInfo?.tenantAddress} 电话：
              {appointmentPhone || '-'}
            </Col>
          </Row>
        )}
        <Row justify='center' style={{ marginTop: 15 }}>
          <Space size='large'>
            {AppointmentId ? (
              ''
            ) : (
              <Button
                onClick={() => {
                  form.resetFields()
                  setKeyword('')
                  setKeywords('')
                  setPatientId('')
                  setText('')
                  setTriageDetailId('')
                  setPatientOverlayDisabled(false)
                }}
                style={{
                  lineHeight: '2.25rem',
                  height: '2.25rem',
                  padding: '0 24px',
                }}
              >
                清空
              </Button>
            )}

            <Button
              onClick={() => {
                form.resetFields()
                setKeyword('')
                setKeywords('')
                setPatientOverlayDisabled(false)
                status
                  ? onCancel && onCancel()
                  : dispatch(
                      traceRoute({
                        name: '预约',
                        path: '/appointment',
                        state: {
                          activeId: '4',
                        },
                      })
                    )
              }}
              style={{
                lineHeight: '2.25rem',
                height: '2.25rem',
                padding: '0 24px',
              }}
            >
              取消
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              style={{
                lineHeight: '2.25rem',
                height: '2.25rem',
                padding: '0 24px',
              }}
            >
              预约
            </Button>
          </Space>
        </Row>
      </Form>
      {showStopAction && (
        <div className={styles.stopAction}>
          正在进行挂号费结算...
          <br />
          请不要离开
        </div>
      )}
    </Col>
  )
}
