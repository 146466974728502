import { Tabs } from 'antd'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { TabBar } from '../../compnents/widgets/TabBar'
import { DoctorSales } from './doctorSales/doctorSales'
import { DrugCosts } from '../../features/sales/DrugCosts/drugCosts'
import { PeopleSales } from '../../features/sales/peopleSales/peopleSales'
import { selectTwoMenus } from '../../layouts/layoutSlice'
import { DoctorAudit } from './DoctorAccont/DoctorAudit'

const { TabPane } = Tabs

export const Sales = (): ReactElement => {
  const twoMenus = useSelector(selectTwoMenus)

  const menuList = twoMenus?.filter((v) => {
    return v?.name == '医生销售统计'
  })?.[0]


  const getKeys = (i: string) => {
    switch (i) {
      case '医生销售统计':
        return '0'
      case '药品成本统计':
        return '1'
      case '医生核算明细':
        return '2'
      case '医保人均费用':
        return '3'
      default:
        return '-'
    }
  }
  return (
    <TabBar style={{ margin: '10px 20px' }}>
      {menuList?.subMenus?.map((v) => {
        return (
          <>
            <TabPane key={getKeys(v?.name)} tab={v?.name}>
              {v?.name == '医生销售统计' ? (
                <DoctorSales />
              ) : v?.name == '药品成本统计' ? (
                <DrugCosts />
              ) : v?.name == '医生核算明细' ? (
                <DoctorAudit />
              ) : v?.name == '医保人均费用' ? (
                <PeopleSales />
              ) : (
                <></>
              )}
            </TabPane>
          </>
        )
      })}
      {/* <TabPane key='0' tab='医生销售统计'>
        <DoctorSales />
      </TabPane>
      <TabPane key='1' tab='药品成本统计'>
        <DrugCosts />
      </TabPane>
      <TabPane key='2' tab='医生核算明细'>
        <DoctorAudit />
      </TabPane>
      <TabPane key='3' tab='医保人均费用'>
        <PeopleSales />
      </TabPane> */}
    </TabBar>
  )
}
