/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 11:11:19
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-24 11:38:52
 */
import { Button, Col, Modal, Space, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { TabBar, TabPane } from '../../../compnents/widgets/TabBar'
import styles from './youYiKangManagement.module.css'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootDispatch } from '../../../app/store'
import { NavBar } from '../../../compnents/nav/NavBar'
import { YouYiKangClinicManagement } from './clinicManagement/clinicManagement'
import { YouYiKangDoctorManagements } from './DoctorManagement/DoctorManagement'
import { YouYiKangDepartmentManagements } from './DepartmentManagement/DepartmentManagement'

export const YouYiKangMainManagementTabBar = () => {
  const location: any = useLocation()

  const history = useHistory()

  const [value, setValue] = useState<any>('0')

  const dispatch = useDispatch<RootDispatch>()

  return (
    <Col className={styles.container}>
      <div
        style={{
          margin: '10px 0px 10px 0px',
        }}
      >
        <NavBar
          style={{ marginBottom: '10px' }}
          where={['第三方平台', '阿里健康']}
          backtrace={{
            name: '第三方平台',
            path: 'weChart',
            state: {
              selectKey: '1',
            },
            routeName: '营销工具',
          }}
        />
      </div>
      <Tabs
        className={styles.tabs}
        onChange={(e) => {
          setValue(e)
        }}
      >
        <TabPane key='0' tab='诊所管理'>
          <YouYiKangClinicManagement tabKey={value} />
        </TabPane>
        <TabPane key='1' tab='科室管理'>
          <YouYiKangDepartmentManagements tabKey={value} />
        </TabPane>
        <TabPane key='2' tab='医生管理'>
          <YouYiKangDoctorManagements tabKey={value} />
        </TabPane>
      </Tabs>
    </Col>
  )
}
