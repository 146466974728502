/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: sj
 * @LastEditTime: 2022-08-05 09:28:09
 */
import { API_PREFIX } from './constant'
import { Request } from './request'
import {storePageParams} from "./storesearch";

export interface GetDoctorSaleEntriesParams {
  doctorId?: string
  timeHead?: string
  timeTail?: string
}
export interface GetStaffCostEntriesParams {
  queryType?: string
  doctorId?: string
  timeHead?: string
  timeTail?: string
}

export function getpeopleSale(params: GetStaffCostEntriesParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/avgRetailAmountList`,
    method: 'GET',
    params,
  }
}

export function getPeopleSalesExport(
  params: GetDoctorSaleEntriesParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/avgRetailAmountList/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}

// 医生工作站 疾病设置
export interface illnessSettingPageParams {
  current:number
  size:number
  customFlag:number
  listType:number
  specialFlag:number
  status:number
  param:string
}
export function illnessSettingPage(params: illnessSettingPageParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/diseasecode/customList`,
    method: 'GET',
    params,
  }
}


// 医生工作站 新增疾病目录
export interface saveIllnessParams{
  category:number,
  icds: string,
  listType: number,
  name: string
  id : number
}

export interface detailIllnessParams{
  id : number
}
export function detailIllness(params: detailIllnessParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/diseasecode/detail`,
    method: 'GET',
    params,
  }
}
export function saveIllness(body: saveIllnessParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/diseasecode/customSave`,
    method: 'POST',
    body,
  }
}

// 医生工作站 禁用疾病目录
export interface forbiddenIllnessParams {
  id:string
  status:number
}
export function forbiddenIllness(body: forbiddenIllnessParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/diseasecode/customStatusSwatch`,
    method: 'POST',
    body,
  }
}

export interface submitDeptDiseaseParams {
  changeWay?: number
  diseaseId?:number
  diseaseIdList?: Array<string | number>
  listType?:number
  deptIdList?: Array<string | number>
}

export function submitDeptDisease(body: submitDeptDiseaseParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/diseasecode/submitDeptDisease`,
    method: 'POST',
    body,
  }
}

export function bachSubmitDeptDisease(body: submitDeptDiseaseParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/diseasecode/bachSubmitDeptDisease`,
    method: 'POST',
    body,
  }
}
