import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from 'antd'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../app/store'
import { changePassword } from '../features/personalManagement/personalDetail/personalDetailSlice'
import styles from './Layout.module.css'
export const ForcedModificationPassWordModal = (props: {
  isModalVisible: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  return (
    <Modal
      className={styles.changePassWordModal}
      footer={null}
      onOk={() => {
        props.onOk
      }}
      closeIcon={null}
      visible={props.isModalVisible}
      title='修改密码'
      width='650px'
    >
      <Form
        form={form}
        initialValues={{ remember: true }}
        name='advanced_search'
        className='ant-advanced-search-form'
        {...layout}
        onFinish={(vs) => {
          if (vs.password === vs.rePassword) {
            if (vs.password == 'abc123456' || vs.rePassword == 'abc123456') {
              notification.error({
                message: '简单密码，请重新修改',
              })
            } else {
              dispatch(
                changePassword({
                  oldPwd: vs.oldPassword,
                  newPwd: vs.password,
                })
              )
                .then(unwrapResult)
                .then((res) => {
                  notification.success({
                    message: '密码修改成功，请重新登陆！',
                    duration: 2,
                    onClose: () => {
                      form.resetFields()
                      props.onOk()
                    },
                  })
                })
                .then(() => {
                  // history
                })
            }
          } else {
            notification.error({
              message: '两次输入密码不一致，请核对',
            })
          }
        }}
      >
        <Row gutter={20}>
          <Col span={20}>
            <div className={styles.tipsText}>
              您的密码过于简单，为防止账号被盗用，请尽快修改密码。
            </div>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={20} style={{ textAlign: 'left' }}>
            <Form.Item
              label='旧密码'
              name='oldPassword'
              rules={[
                { required: true, message: '旧密码不能为空!' },
                {
                  pattern: /^[^\s]*$/,
                  message: '密码不能存在空格',
                },
              ]}
            >
              <Input.Password type='password' autoComplete='new-password' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={20} style={{ textAlign: 'left' }}>
            <Form.Item
              label='新密码'
              name='password'
              rules={[
                { required: true, message: '新密码不能为空!' },
                {
                  pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=\S+$)[a-zA-Z\d\W]{6,30}$/,
                  message: '新密码格式为6～30位，数字 + 字母',
                },
              ]}
            >
              <Input.Password type='password' autoComplete='new-password' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={20} style={{ textAlign: 'left' }}>
            <Form.Item
              label='确认密码'
              name='rePassword'
              rules={[
                { required: true, message: '确认密码不能为空!' },
                {
                  pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=\S+$)[a-zA-Z\d\W]{6,30}$/,
                  message: '确认密码格式为6～30位，数字 + 字母',
                },
              ]}
            >
              <Input.Password type='password' autoComplete='new-password' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20} style={{ marginTop: '30px' }}>
          <Col span={14} style={{ color: 'red' }}>
            密码格式为6～30位，数字 + 字母
          </Col>
          <Col span={10}>
            <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
              确定
            </Button>
            <Button
              type='primary'
              onClick={() => {
                props?.onCancel()
              }}
              style={{ float: 'right', marginRight: '10px' }}
            >
              退出登录
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
