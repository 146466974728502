/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-13 15:32:19
 */
import { Modelment } from '../models/list'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface GetDoctorSaleEntriesParams {
  doctorId?: string
  timeHead?: string
  timeTail?: string
  current?: number
  size?: number
  billingCategory?: string
  drugName?: any
  insuranceCode?: any
}
export interface treatmentCostParams {
  timeHead?: any
  timeTail?: any
  id?: number
  current?: number
  hitCount?: boolean
  pages?: string
  accountUnit?: string
  averagePrice?: number
  billingCategory?: string
  count?: number
  name?: string
  drugName?: any
  retail?: number
  status?: number
  searchCount?: boolean
  size?: number
  total?: number
  state?: number
  treatmentDoctorName?: string
}
export function getTreatmentCostList(params: treatmentCostParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/treatmentServiceSaleList`,
    method: 'GET',
    params,
  }
}
export function getTreatmentCostDetail(params: treatmentCostParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/treatmentServiceSaleDetail`,
    method: 'GET',
    params,
  }
}

export function getDoctorAuditExport(
  params: GetDoctorSaleEntriesParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/treatmentServiceSaleList/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
