
import { Modal } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { PaymentConfirmation } from '../../../../models/confirmation';
import { PaymentConfirm, PaymentConfirmRef } from '../../../payment/confirm/PaymentConfirm';


interface SettlementModalProps {
  visible: boolean
  remoteConfirmation?: any
  onCancel: () => void
}
export const SettlementModal = ({
  visible,
  remoteConfirmation,
  onCancel,
}: SettlementModalProps) => {

  const ref = useRef<PaymentConfirmRef>(null)

  return <Modal
    title='结算'
    width={905}
    visible={visible}
    onCancel={onCancel}
    onOk={() => {
     return
    }}
    bodyStyle={{ padding: 0 }}
    footer={null}
  >
    <div style={{ height: 690 }}>
      <PaymentConfirm
        ref={ref}
        membersStatus={0}
        confirmation={remoteConfirmation}
        onPay={(params) => {
          return
        }}
      />
    </div>
  </Modal>
}