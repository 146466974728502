import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { api, sendAsync } from "../../../app/applicationSlice"
import { RootState, RootThunk, RootThunkApi } from "../../../app/store"
import { LoadingState } from "../../../models/common"
import * as R from "../../../models/registration"
import { Registration } from "../../../models/registration"
import * as Tr from "../../../models/treatment"
import { Treatment } from "../../../models/treatment"
import { startTreatment as apiStartTreatment } from "../../../services/registration"
import {
  copyTreatment as apiCopyTreatment,
  CopyTreatmentParams,
  createTreatment as apiCreateTreatment,
  getTreatment as apiGetTreatment,
  GetTreatmentDetailParams,
  getUrlLink,
  urlLinkParams,
} from "../../../services/treatment"
import { setTreatmentRegistration } from "../treatmentSlice"

interface BeautyClinicState {
  registration?: Registration
  treatment?: Treatment
  creatingState: LoadingState
}

const initialState = {
  registration: undefined,
  creatingState: LoadingState.Idle,
} as BeautyClinicState

export const createTreatment = createAsyncThunk<void, Treatment, RootThunkApi>(
  "BeautyClinic/createTreatmentStatus",
  async (treatment, api) => {
    return sendAsync(apiCreateTreatment(treatment), api).then(() => {
      // const id = api.getState().beautyClinic.registration?.id;
      // if (id) {
      //   api.dispatch(getTreatment({ registrationId: id,medicalRecord:1 }));
      // }
    })
  }
)

const beautyClinicSlice = createSlice({
  name: "beautyClinic",
  initialState,
  reducers: {
    setRegistration: (state, action: PayloadAction<Registration>) => {
      state.registration = action.payload
    },
    setTreatment: (state, action: PayloadAction<Treatment | undefined>) => {
      state.treatment = action.payload
    },
    setTreatmentCreatingState: (state, action: PayloadAction<LoadingState>) => {
      state.creatingState = action.payload
    },
    resetBeautyClinic: (state) => {
      state.registration = undefined
      state.treatment = undefined
      state.creatingState = LoadingState.Idle
    },
  },
})

const { setRegistration } = beautyClinicSlice.actions

export const {
  setTreatmentCreatingState,
  resetBeautyClinic,
  setTreatment,
} = beautyClinicSlice.actions

export function getTreatment(params: GetTreatmentDetailParams): RootThunk {
  return api(
    apiGetTreatment(params),
    (data: Record<string, never>, dispatch) => {
      const registration = R.fromJson(data.registration)
      dispatch(setRegistration(registration))
      dispatch(setTreatmentRegistration(registration))
      const treatment =
        JSON.stringify(data.treatment) !== "{}"
          ? Tr.fromJson(data.treatment)
          : undefined
      dispatch(setTreatment(treatment))
    }
  )
}

export const getTreatments = createAsyncThunk<
  void,
  GetTreatmentDetailParams,
  RootThunkApi<void>
>("beautyClinic/getTreatments", async (params, api) => {
  return sendAsync(apiGetTreatment(params), api)
})

export function startTreatment(registrationId: string): RootThunk {
  return api(apiStartTreatment(registrationId), () => {
    // Do nothing.
  })
}

export const copyTreatment = createAsyncThunk<
  void,
  CopyTreatmentParams,
  RootThunkApi<void>
>("beautyClinic/copyTreatmentStatus", async (params, api) => {
  return sendAsync(apiCopyTreatment(params), api).then(() => {
    // do nothing.
  })
})

export const getUrl = createAsyncThunk<void, urlLinkParams, RootThunkApi>(
  "beautyClinic/getUrl",
  async (params, api) => {
    return sendAsync(getUrlLink(params), api)
  }
)

export const selectRegistration = (
  state: RootState
): Registration | undefined => state.beautyClinic.registration

export const selectTreatmentB = (state: RootState): Treatment | undefined =>
  state.beautyClinic.treatment

export const selectTreatmentCreatingState = (state: RootState): LoadingState =>
  state.beautyClinic.creatingState

export default beautyClinicSlice.reducer
