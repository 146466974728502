/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-08-05 10:40:05
 */
import { Button, Col, Form, Row, Select, Space } from "antd";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateSelect } from "../../../compnents/form/DateSelect";
import { EditableList } from "../../../compnents/list/EditableList";
import { getSkinTestResultName, SkinTest } from "../../../models/skintest";
import { pitestQueryParams } from "../../../services/pitest";
import { ThemeContext } from "../../../theme/ThemeContext";
import {
  getSkinTestList,
  selectSkinTestReportPage,
} from "./patientDetailSlice";

interface PatientReportListProps {
  patientId: string;
}

export const PatientReports = ({
  patientId,
}: PatientReportListProps): ReactElement => {
  const dispatch = useDispatch();

  const theme = useContext(ThemeContext);

  const page = useSelector(selectSkinTestReportPage);

  const [params, setParams] = useState<pitestQueryParams>({
    current: 1,
    patientId,
    state: 2,
  });

  useEffect(() => {
    dispatch(getSkinTestList(params));
  }, [dispatch, params]);

  return (
    <Col
      style={{
        height: "100%",
        padding: "15px 15px 0 15px",
        borderRadius: `0 0 ${theme.br}px ${theme.br}px`,
        backgroundColor: theme.pn,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <EditableList<SkinTest>
        style={{ flex: 1 }}
        page={page}
        columns={[
          {
            title: "序号",
            key: "no",
            align: "center",
            render: (_1, _2, i) => `${page.size * (page.current - 1) + i + 1}`,
          },
          {
            title: "皮试时间",
            dataIndex: "presetTime",
            align: "center",
          },
          {
            title: "皮试药品",
            dataIndex: "drugName",
            align: "center",
          },
          {
            title: "皮试登记人",
            dataIndex: "testUserName",
            align: "center",
          },
          {
            title: "皮试结果",
            key: "result",
            align: "center",
            render: (_, p) => getSkinTestResultName(p.result),
          },
          // {
          //   title: "操作",
          //   key: "actions",
          //   align: "center",
          //   width: "6rem",
          //   render: function ShowActions(_, p) {
          //     return <a>打印报告单</a>;
          //   },
          // },
        ]}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size })
        }
      />
    </Col>
  );
};
