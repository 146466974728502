/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-15 14:12:33
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-03 14:45:14
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Row, Col, Table, TableColumnType, Space, Button } from 'antd'
import React, { ReactElement, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../../../app/applicationSlice'
import { RootDispatch } from '../../../../../app/store'
import { getSourceTypeName } from '../../../../../models/registration'
import { getGenderName } from '../../../../../models/user'
import { getAge } from '../../../../../utils/StringUtils'
import { getAddress } from '../../../../treatment/editor/recipeEditorSlice'
import { AddPatientModal } from '../../../toothHome/modal/addPatientModal'
import { RegisteredModal } from '../../../toothHome/modal/registeredModal'
import { SubscribeModal } from '../../../toothHome/modal/subscribeModal'
import PatientLabel from '../../components/patientLabel'
import {
  detailForPatientAsync,
  patientrelationshipAsync,
  selectPatientInfo,
  selectPatientManagementStatus,
  setPatientInfo,
  setPatientManagementStatus,
  updatePatientTimeAsync,
} from '../../patientSlice'
import { PatientPanelProps } from '../patientPanel'
import styles from '../patientPanel.module.css'
import { EditRelation } from './editRelation'

export const PatientInfo = ({
  patientId,
  refreshDetailFlag,
  getDetailMessageCallBack,
}: PatientPanelProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const insuranceArray: any = useSelector(selectInsuranceArray)

  const [EditRelationVisible, setEditRelationVisible] = useState(false)

  const [detail, setDetail] = useState<any>({
    labels: [],
    patient: {},
    patientInsuranceVO: {},
    treatment: {},
  })

  const [data, setData] = useState<any[]>([])

  const [addPatientModal, setAddPatientModal] = useState(false) // 新增患者弹窗

  const [modalPatientID, setModalPatientID] = useState('') //弹窗 患者ID

  const [registeredModal, setRegisteredModal] = useState(false) //挂号弹窗

  const [subscribeModal, setSubscribeModal] = useState(false) // 工作站-预约弹窗

  const patientManagementStatus = useSelector(selectPatientManagementStatus)

  const [allList, setAllList] = useState<any[]>() //家庭地址数据

  const [detailAllList, setDetailAllList] = useState<any[]>([]) //修改信息-家庭地址数据

  const [addresStr, setAddresStr] = useState('') // 地址

  const getDetail = () => {
    dispatch(
      detailForPatientAsync({
        id: patientId,
        insuranceCode: insuranceArray?.[0]?.insuranceCode,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setAddresStr('')
        res.patient.provinceCode && getaddressDetail(res.patient.provinceCode)
        res.patient.cityCode && getaddressDetail(res.patient.cityCode)
        setDetail(res)
      })
    dispatch(patientrelationshipAsync(patientId))
      .then(unwrapResult)
      .then((res: any) => {
        setData(res.records)
      })
  }

  useEffect(() => {
    if (!refreshDetailFlag) {
      getDetail()
    }
  }, [refreshDetailFlag])

  const getAddressFn = () => {
    //获取地址
    dispatch(getAddress('0'))
      .then(unwrapResult)
      .then((res: any) => {
        setAllList(
          res?.map((el: any) => {
            return { value: el.code, label: el.name, isLeaf: false }
          })
        )
      })
  }

  const getaddressDetail = (valueCode: string) => {
    dispatch(getAddress(valueCode))
      .then(unwrapResult)
      .then((res: any) => {
        setDetailAllList((el) => {
          return [...el, ...res]
        })
      })
  }

  useEffect(() => {
    if (!detailAllList.length) return
    const tempArr =
      allList?.filter((v: any) => v.value == detail.patient.provinceCode) || ''
    const tempArr2 =
      detailAllList?.filter((v: any) => v.code == detail.patient.cityCode) || ''
    const tempArr3 =
      detailAllList?.filter((v: any) => v.code == detail.patient.contryCode) ||
      ''
    setAddresStr(
      `${tempArr[0]?.label},${tempArr2[0]?.name},${tempArr3[0]?.name}`
    )
  }, [detailAllList])

  useEffect(() => {
    getDetail()
    getAddressFn()
  }, [patientId])

  const columns = (
    onAction: (action: string, t: any) => void
  ): TableColumnType<any>[] => [
    {
      title: '关系人',
      dataIndex: 'relationshipName',
      align: 'center',
      render: function action(_, t) {
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onAction('includeDetail', t)
            }}
          >
            {t?.relationshipName ? t?.relationshipName : '-'}
          </span>
        )
      },
    },
    {
      title: '关系',
      dataIndex: 'relationship',
      align: 'center',
    },
    {
      title: '备注',
      dataIndex: 'remark',
      align: 'center',
    },
  ]

  return (
    <div className={styles.patientInfoContainer}>
      <PatientLabel label='患者基础信息' />
      <Row gutter={[10, 10]}>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              患者姓名：
            </Col>
            <Col span={16}>{detail?.patient?.name}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              病历号：
            </Col>
            <Col span={16}>{detail?.patient?.id}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              患者分组：
            </Col>
            <Col span={16}>{detail?.groupNames}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              性别：
            </Col>
            <Col span={16}>{getGenderName(detail?.patient?.sex)}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              年龄：
            </Col>
            <Col span={16}>
              {getAge(detail?.patient?.ageYear, detail?.patient?.ageMonth)}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              出生日期：
            </Col>
            <Col span={16}>{detail?.patient?.birthday}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              联系方式：
            </Col>
            <Col span={16}>
              {detail?.patient?.phone}{' '}
              {detail?.patient?.relationship
                ? `(${detail?.patient?.relationship})`
                : ''}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              紧急联系方式：
            </Col>
            <Col span={16}>
              {detail?.patient?.emergencyContactPhone}{' '}
              {detail?.patient?.relationshipEmergencyContact
                ? `(${detail?.patient?.relationshipEmergencyContact})`
                : ''}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              身份证号：
            </Col>
            <Col span={16}>{detail?.patient?.idCardNumber}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              QQ：
            </Col>
            <Col span={16}>{detail?.patient?.qq}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              微信号：
            </Col>
            <Col span={16}>{detail?.patient?.wechat}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              邮箱地址：
            </Col>
            <Col span={16}>{detail?.patient?.mail}</Col>
          </Row>
        </Col>
        <Col span={16}>
          <Row>
            <Col span={4} style={{ textAlign: 'right' }}>
              地址：
            </Col>
            <Col span={16}>{addresStr + ' ' + detail?.patient?.address}</Col>
          </Row>
        </Col>
        {/* <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              备注：
            </Col>
            <Col span={16}>{detail?.patient?.remark}</Col>
          </Row>
        </Col> */}
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              职业：
            </Col>
            <Col span={16}>{detail?.patient?.job}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              工作单位：
            </Col>
            <Col span={16}>{detail?.patientInsuranceVO?.brdw || detail?.patient?.companyName}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              患者来源：
            </Col>
            <Col span={16}>{getSourceTypeName(detail?.patient?.source)}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              医保卡余额：
            </Col>
            <Col span={16}>{detail?.patientInsuranceVO?.zhye}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              当年账户余额：
            </Col>
            <Col span={16}>{detail?.patientInsuranceVO?.dnzhye}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              历年账户余额：
            </Col>
            <Col span={16}>{detail?.patientInsuranceVO?.lnzhye}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              医保刷卡时间：
            </Col>
            <Col span={16}>{detail?.insuranceTime}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              医保卡号：
            </Col>
            <Col span={16}>{detail?.patientInsuranceVO?.medicalCardNo}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              参保险种：
            </Col>
            <Col span={16}>{detail?.patientInsuranceVO?.cbxzmc}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              人员类型：
            </Col>
            <Col span={16}>{detail?.patientInsuranceVO?.memberTypemc}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              创建人：
            </Col>
            <Col span={16}>{detail?.patient?.creatUserName}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              创建时间：
            </Col>
            <Col span={16}>{detail?.patient?.createTime}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              患者标签：
            </Col>
            <Col
              span={16}
              // style={{
              //   display: 'flex',
              //   flexWrap: 'wrap',
              // }}
            >
              {detail?.labels?.length != 0 &&
                detail?.labels.map((v: any, index: number) => {
                  return (
                    <span
                      key={index}
                      style={{
                        color: v.fontColor,
                        backgroundColor: v.backgroundColor,
                        padding: '0 8px',
                        marginRight: 8,
                      }}
                    >
                      {v.name}
                    </span>
                  )
                })}
            </Col>{' '}
          </Row>
        </Col>
        {detail?.specialDiseaseInformation && (
          <Col span={24}>
            <Row
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Col flex={'11.1%'} style={{ textAlign: 'right' }}>
                备案特病信息：
              </Col>
              <Col
                span={20}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {detail?.specialDiseaseInformation}
              </Col>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Row>
            <Col flex={'11.1%'} style={{ textAlign: 'right' }}>
              备注：
            </Col>
            <Col span={20} style={{ wordWrap: 'break-word' }}>
              {detail?.patient?.remark}
            </Col>
          </Row>
        </Col>
      </Row>
      <PatientLabel label='全身检查' />
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Row>
            <Col flex={'11.1%'} style={{ textAlign: 'right' }}>
              既往史：
            </Col>
            <Col flex={'88%'}>{detail?.patient?.pastMedicalHistory}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col flex={'11.1%'} style={{ textAlign: 'right' }}>
              过敏史：
            </Col>
            <Col flex={'88%'}>{detail?.patient?.allergyHistory}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col flex={'11.1%'} style={{ textAlign: 'right' }}>
              就诊经历：
            </Col>
            <Col flex={'88%'}>{detail?.patient?.treatmentHistory}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              洁牙习惯：
            </Col>
            <Col span={15}>{detail?.patient?.toothCleaningHabit}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              刷牙：
            </Col>
            <Col span={15}>
              {detail?.patient?.toothBrushingFrequency
                ? detail?.patient?.toothBrushingFrequency + '次/天 ，'
                : ''}

              {detail?.patient?.toothBrushingTime
                ? detail?.patient?.toothBrushingTime + '分钟/次'
                : ''}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              吸烟：
            </Col>
            <Col span={15}>
              {detail?.patient?.smokeFrequency
                ? detail?.patient?.smokeFrequency + '次/天'
                : ''}
            </Col>
          </Row>
        </Col>
      </Row>
      <PatientLabel label='首诊信息' />
      <Row gutter={[10, 10]}>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              首诊医生：
            </Col>
            <Col span={15}>{detail?.treatment?.treatmentDoctorName}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              首诊时间：
            </Col>
            <Col span={15}>{detail?.treatment?.createTime}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col flex={'11.1%'} style={{ textAlign: 'right' }}>
              主诉：
            </Col>
            <Col flex={'88%'}>{detail?.treatment?.chiefComplaint}</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col flex={'11.1%'} style={{ textAlign: 'right' }}>
              诊断疾病：
            </Col>
            <Col flex={'88%'}>{detail?.treatment?.disease}</Col>
          </Row>
        </Col>
      </Row>
      <PatientLabel label='就诊信息' />
      <Row gutter={[10, 10]}>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              咨询师：
            </Col>
            <Col span={15}>{detail?.patient?.counselor}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              介绍人：
            </Col>
            <Col span={15}>{detail?.patient?.introducer}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={12} style={{ textAlign: 'right' }}>
              最近一次挂号时间：
            </Col>
            <Col span={12}>{detail?.registrationTime}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8} style={{ textAlign: 'right' }}>
              主治医生：
            </Col>
            <Col span={16}>{detail?.patient?.attendingDoctor}</Col>
          </Row>
        </Col>
      </Row>
      <PatientLabel label='亲友关系' />
      <Table
        columns={columns((action: any, t: any) => {
          if (action == 'includeDetail') {
            if (t?.relationshipId) {
              dispatch(updatePatientTimeAsync(t?.relationshipId))
                .then(unwrapResult)
                .then(() => {
                  getDetailMessageCallBack && getDetailMessageCallBack()
                })
            }
          }
        })}
        dataSource={data}
        pagination={false}
      />
      <Space className={styles.patientInfoAction}>
        <Button
          type='primary'
          onClick={() => {
            setAddPatientModal(true)
          }}
        >
          修改信息
        </Button>
        <Button
          type='primary'
          onClick={() => {
            setEditRelationVisible(true)
          }}
        >
          修改亲友关系
        </Button>
      </Space>

      <EditRelation
        visible={EditRelationVisible}
        data={data}
        patientId={patientId}
        onCancel={() => setEditRelationVisible(false)}
        onOk={() => {
          dispatch(patientrelationshipAsync(patientId))
            .then(unwrapResult)
            .then((res: any) => {
              setData(res.records)
              setEditRelationVisible(false)
            })
        }}
      />

      {/* 新增患者弹窗 */}
      <AddPatientModal
        visible={addPatientModal}
        detailData={detail}
        onOk={(num, patientId) => {
          setModalPatientID(patientId || '')
          getDetail()
          if (num == 2) {
            setRegisteredModal(true)
          } else if (num == 3) {
            setSubscribeModal(true)
          }
          dispatch(setPatientManagementStatus(patientManagementStatus + 1))
          setAddPatientModal(false)
        }}
        onCancel={() => {
          setAddPatientModal(false)
        }}
      />

      {/* 工作站-挂号 */}
      <RegisteredModal
        title={0}
        patientId={modalPatientID} //新增患者-保存并挂号
        visible={registeredModal}
        onOk={() => {
          setRegisteredModal(false)
        }}
        onCancel={() => {
          setRegisteredModal(false)
        }}
      />

      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={modalPatientID} //患者 ID
        treatmentDoctorId={''} //就诊医生 ID
        onOk={() => {
          setSubscribeModal(false)
        }}
        onCancel={() => setSubscribeModal(false)}
      />
    </div>
  )
}
