/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-24 19:32:02
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-28 10:48:56
 */
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Form, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";

export const InspectListModal = (props: {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const [form] = Form.useForm();

  const data = [
    {
      project: "单核细胞百分比[M]",
      result: "2",
      nature: "低",
      referenceValue: "3~10",
      unit: "%",
      id: 0,
    },
    {
      project: "已型副伤寒抗体[B]",
      result: "阳性(+)",
      nature: "异常",
      referenceValue: "阴性(-)",
      unit: "",
      id: 1,
    },
    {
      project: "基因型C",
      result: "弱阳性(±)",
      nature: "异常",
      referenceValue: "阴性(-)",
      unit: "",
      id: 2,
    },
    {
      project: "红细胞电泳指数",
      result: "7.5",
      nature: "高",
      referenceValue: "3.08~5.97",
      unit: "",
      id: 3,
    },
  ];

  return (
    <Modal
      title="报告结果"
      visible={props.visible}
      onOk={() => {
        props.onOk();
        form.resetFields();
      }}
      onCancel={() => {
        form.resetFields();
        props.onCancel();
      }}
      width={750}
    >
      <Table
        rowKey={(record: any) => record.id}
        pagination={false}
        columns={[
          {
            title: "序号",
            key: "no",
            align: "center",
            render: (_1, _2, index) => `${index + 1}`,
          },
          {
            title: "检验项目",
            dataIndex: "project",
            key: "project",
            align: "center",
          },
          {
            title: "结果",
            dataIndex: "result",
            key: "result",
            align: "center",
          },
          {
            title: "性质",
            dataIndex: "nature",
            key: "nature",
            align: "center",
            render: function Element(_, t) {
              return (
                <div>
                  {t.nature === "低" ? (
                    <>
                      <p style={{ color: "blue", marginBottom: 0 }}>
                        {t.nature}
                        <ArrowDownOutlined />
                      </p>
                    </>
                  ) : t.nature === "高" ? (
                    <>
                      <p style={{ color: "red", marginBottom: 0 }}>
                        {t.nature}
                        <ArrowUpOutlined />
                      </p>
                    </>
                  ) : (
                    <p style={{ marginBottom: 0 }}>{t.nature}</p>
                  )}
                </div>
              );
            },
          },
          {
            title: "参考值",
            dataIndex: "referenceValue",
            key: "referenceValue",
            align: "center",
          },
          {
            title: "单位",
            dataIndex: "unit",
            key: "unit",
            align: "center",
          },
        ]}
        dataSource={data}
        style={{ paddingTop: "10px" }}
      />
    </Modal>
  );
};
