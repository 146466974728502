/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-05-31 15:07:07
 * @LastEditors: sj
 * @LastEditTime: 2022-06-09 13:39:11
 */
import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface OtherInsuranceBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    pracPsnType?: number
    psnNo?: string
    bizAppyType?: string
    medType?: string
    mdtrtId?: string
    fixmedinsCode?: string
    rpotcNo?: string
    setlId?: string
    ver?: string
    infno?: string
    zdType?: string
    insutype?: string
    fixmedinsType?: string
    hilistCode?: string
    pageSize?: number
    pageNum?: number
    fixmedinsName?: string
    accountDate?: string
  }
}

// 科室信息查询 5101
export function departmentInfoQuery(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/departmenInfoQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 医执人员信息查询 5102
export function medicalExecutive(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/medicalExecutive_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 人员待遇享受检查 2001
export function queryDyInfo(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/personEntitlementSearch_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 就诊信息查询 5201
export function queryMdtrtInfo(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryMdtrtInfo_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 诊断信息查询 5202
export function querySetlDiseList(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/querySetlDiseList_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 结算信息查询 5203
export function querySetlInfo(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/querySetlInfo_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 费用信息查询 5204
export function queryFeeList(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryFeeList_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 人员慢特病用药记录查询 5205
export function querypsnmedcrcd(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/querypsnmedcrcd_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 人员定点信息查询 5302
export function queryPersonalInsurance(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryPersonalInsurance_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 在院信息查询 5303
export function inHospitalInfoQuery(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/inHospitalInfoQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 转院信息查询 5304
export function transferInfoQuery(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/transferInfoQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 人员慢特病诊断备案查询 7014
export function diseaseRecordQuery(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/diseaseRecordQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 修改卡密码 1163
export function changeCardPwd(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/updateCardPassword_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 获取机构信息 1201
export function getTenantInfo(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getHospitalInfo_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 获取机构信息 1316
export function search1316(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryMedinsHilistMapByPage_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 获取机构信息 1317
export function search1317(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/queryMedListMapByPage_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 字典表查询 1901
export function zdSearch1901(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/dictionaryQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 医疗目录下载 (支持1301，1302，1303，1305，1306，1307，1308，1309，1310，1311，1313，1314，1315，1320，1321)
export function downloadCate1399(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/downCatalogCQ_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 西药目录下载 1301
export function downloadCate1301(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/downCatalog1301_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}
// 项目互认信息查询 5401
export function mutualInfoQuery(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/mutualInfoQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 报告信息查询 5402
export function detailInfoQuery(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/detailInfoQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export function monthlySummaryStatusQuery(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/monthlySummaryStatusQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export function accountReconciliationPermissionQuery(bodyName: OtherInsuranceBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/accountReconciliationPermissionQuery_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}