/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-15 13:46:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-18 14:11:48
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col } from 'antd'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { RootDispatch } from '../../app/store'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { getActiveKey } from '../../models/payment'
import { RecipeStatus } from '../../models/recipe'
import { invoiceDetailAsync } from './confirm/paymentConfirmSlice'
import { InvoiveRecords } from './invoice/InvoiveRecord'
import { JuhePayPageRef } from './JuhePayPage/JuhePayPage'
import { Payments, PaymentsRef } from './list/Payments'
import { getDepartments } from './list/paymentSlice'
import styles from './Payment.module.css'
import { invoicenumberconfigAsync } from '../tenant/tenantSlice'
import { useSelector } from 'react-redux'
import { selectTenant } from '../../features/user/setting/settingSlice'
import { OweMonePage } from './oweMoney/oweMonePage'
import { selectMenus, selectTwoMenus } from '../../layouts/layoutSlice'
import { ElectronicTicketUploads } from '../ElectronicTicketUpload/ElectronicTicketUpload'
import { ChargeWater } from '../sales/ChargeWater/changeWater'

export const Payment = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const history = useHistory()

  const location: any = useLocation()

  const [selectedTab, setSelectedTab] = useState<any>('0')

  const ref = useRef<PaymentsRef>(null)

  const [showInvoice, setShowInvoice] = useState(false)

  const [showInvoiceLocal, setShowInvoiceLocal] = useState(false)

  const JuheShow: any = useSelector(selectTenant) //是否显示聚合支付退费tab 栏

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  const twoMenus = useSelector(selectTwoMenus)

  const menuList = twoMenus?.filter((v) => {
    return v?.name == '收费'
  })?.[0]


  const getKeys = (i: string) => {
    switch (i) {
      case '待收费':
        return '0'
      case '已收费':
        return '1'
      case '已退费':
        return '2'
      case '收费流水':
        return '8'
      default:
        return '-'
    }
  }
  useEffect(() => {
    if (menuList?.subMenus?.length) {
      setSelectedTab(getKeys(menuList?.subMenus[0]?.name))
    }
 
  }, [menuList])
  
  useEffect(() => {
    dispatch(getDepartments())
  }, [dispatch])

  useEffect(() => {
    setSelectedTab(
      location?.state?.station || getActiveKey(storageParams.state)
    )
  }, [location?.state])

  useEffect(() => {
    const tenantId = JSON.parse(localStorage.getItem('credentials') || '{}')
      .tenantId
    dispatch(invoiceDetailAsync({ tenantId }))
      .then(unwrapResult)
      .then((res) => {
        if (res) {
          res.length && setShowInvoice(res.some((v: any) => v.status === 1))
        }
      })
  }, [])


  useEffect(() => {
    dispatch(invoicenumberconfigAsync())
        .then(unwrapResult)
        .then((res:any) => {
          if (res.records.length>0){
            setShowInvoiceLocal(true)
          }
        })

  }, [])
  useEffect(() => {
    sessionStorage.removeItem('toothHomeReturnTag')
  }, [])
  
  return (
    <Col className={styles.container}>
      <TabBar
        defaultActiveKey={
          location.state ? location.state.station : selectedTab || '0'
        }
        activeKey={selectedTab}
        onChange={(v) => {
          setSelectedTab(v)
        }}
      >
        {menuList?.subMenus?.map((v: any) => {
          return (
            <>
              <TabPane key={getKeys(v?.name)} tab={v?.name}>
                {v?.name == '待收费' ? (
                  <Payments
                    status={RecipeStatus.ToSettle}
                    selectedTab={selectedTab}
                  />
                ) : v?.name == '已收费' ? (
                  <Payments
                    status={RecipeStatus.Settled}
                    sibling={ref.current}
                    selectedTab={selectedTab}
                  />
                ) : v?.name == '已退费' ? (
                  <Payments
                    status={RecipeStatus.Returned}
                    ref={ref}
                    selectedTab={selectedTab}
                  />
                ) : v?.name == '收费流水' ? (
                  <ChargeWater type='1' />
                ) : (
                  <></>
                )}
              </TabPane>
            </>
          )
        })}
        {/* <TabPane key='8' tab='收费流水'>
          <ChargeWater type='1' />
        </TabPane> */}
        {/* <TabPane key='0' tab='待收费'>
          <Payments status={RecipeStatus.ToSettle} selectedTab={selectedTab} />
        </TabPane>
        <TabPane key='1' tab='已收费'>
          <Payments
            status={RecipeStatus.Settled}
            sibling={ref.current}
            selectedTab={selectedTab}
          />
        </TabPane>
        <TabPane key='2' tab='已退费'>
          <Payments
            status={RecipeStatus.Returned}
            ref={ref}
            selectedTab={selectedTab}
          />
        </TabPane> */}
        {JuheShow?.openOwe == 1 && (
          <TabPane key='5' tab='欠费管理'>
            <OweMonePage status={RecipeStatus.oweMone} />
          </TabPane>
        )}
        {(showInvoice||showInvoiceLocal) && (
          <TabPane key='3' tab='发票明细'>
            <InvoiveRecords status={RecipeStatus.invoice} />
          </TabPane>
        )}
        {/* <TabPane key='8' tab='收费流水'>
          <ChargeWater type='1' />
        </TabPane> */}
        {/* {(JuheShow?.openHubPay == 1 ||
          JuheShow?.openHubPayJiali == 1 ||
          JuheShow?.openHubPayYinlian == 1 ||
          JuheShow?.openHubPayKanglin == 1) && (
          <TabPane key='4' tab='聚合支付记录'>
            <JuhePayPageRef
              status={RecipeStatus.juhePay}
              selectedTab={selectedTab}
            />
          </TabPane>
        )} */}
        {/* <TabPane key='6' tab='电子票据上传'> */}
          {/* <ElectronicTicketUploads /> */}
        {/* </TabPane> */}
      </TabBar>
    </Col>
  )
}
