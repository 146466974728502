/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-15 14:11:27
 * @LastEditors: sj
 * @LastEditTime: 2022-08-09 13:31:29
 */
/* eslint-disable react/display-name */
import { Button, TableColumnType, Tooltip } from 'antd'
import React from 'react'
import { selectInsuranceArray } from '../../../app/applicationSlice'
import { Dimen } from '../../../models/dimen'
import { useSelector } from 'react-redux'
import { getApproveFlgName, getChrgitmLvName } from '../../../models/commodity'
import { CloseCircleOutlined } from '@ant-design/icons'
import styles from '../Home.module.css'
import { BillingCategoryName } from '../../../models/billingCategory'


export const Columns = (
  onClick: (text: string, id: any, item?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const insuranceArray = useSelector(selectInsuranceArray)
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '5rem',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '商品信息',
      // fixed: "left",
      render: function Element(_, record) {
        return (
          <div
            style={{
              height: '55px',
              color:
                record.count <=
                  (record.inventoryAlert > 0 ? record.inventoryAlert : 0)
                  ? 'red'
                  : '',
            }}
          >
            <p
              style={{
                height: '28px',
                lineHeight: '36px',
                padding: '0',
                margin: '0',
                fontSize: '16px',
                fontWeight: 'bolder',
              }}
            >
              {record.name}
              {record.count <=
                (record.inventoryAlert > 0 ? record.inventoryAlert : 0)
                ? '(库存预警)'
                : ''}
            </p>
            <p
              style={{
                height: '28px',
                lineHeight: '24px',
                padding: '0',
                margin: '0',
                color: '#999999',
                fontSize: '14px',
              }}
            >
              {record.packSpec} /{record.doseCategory}/
              {record.tenantSupplierName}
            </p>
          </div>
        )
      },
      // 商品名/厂家/规格/剂型）name/tenantSupplierName/packSpec/doseCategory
    },
    {
      title: '匹配状态',
      align: 'center',
      dataIndex: 'approveFlg',
      width: Dimen.GenericName,
      key: 'approveFlg',
      className: insuranceArray.length == 0 ? 'notShow' : '',
      render: function Element(_, t) {
        return (
          <Button type={Number(t.approveFlg) ? 'link' : 'text'}>
            {getApproveFlgName(Number(t.approveFlg))}
          </Button>
        )
      },
    },
    {
      title: '医保国码',
      align: 'center',
      dataIndex: 'hilistCode',
      width: Dimen.ApprovalNumber,
      className: insuranceArray.length == 0 ? 'notShow' : '',
      key: 'hilistCode',
      render: function Element(_, t) {
        if (t.materialsCategory !== 4) {
          return (
            <div className={t.hilistCode ? styles.delMedicalCode : ''}>
              <div className={styles.hilistName} onClick={() => {
                onClick('insurance', t.mappingId, t)
              }} >
                {t.hilistCode && (<div>{t.hilistName}</div>)}
                <div>
                  <Button
                    type='link'

                  >
                    {t.hilistCode || '匹配'}
                  </Button>
                  {getChrgitmLvName(t.chrgitmLv)}
                </div>
              </div>
              {t.hilistCode && (
                <span className={styles.Close}>
                  <CloseCircleOutlined
                    onClick={() => {
                      onClick('delCode', t.mappingId, t)
                    }}
                  />
                </span>
              )}
            </div>
          )
        } else {
          return <></>
        }
      },
    },
    {
      title: '药品类别',
      align: 'center',
      width: Dimen.BillingCategory,
      dataIndex: 'billingCategory',
      sorter: (a: any, b: any) => a.billingCategory - b.billingCategory,
      render: (billingCategory: any) => {
        return BillingCategoryName(billingCategory)
      },
    },
    {
      title: '变动类别',
      dataIndex: 'catalogType',
      key: 'catalogType',
      align: 'center',
      render: (_, t) => {
        return t.catalogType == '1' ? '失效' :
          t.catalogType == '2' ? '限价变动' : ''
      },
    },
    {
      title: '限价变动',
      dataIndex: 'catalogType',
      key: 'catalogType',
      align: 'center',
      render: (_, t) => {
        return t.catalogType == '2' ? `${t.oldLimit}->${t.newLimit}` : '-'

      },
    },
    {
      title: '操作',
      align: 'center',
      fixed: 'right',
      width: '6rem',
      render: function Element(_, t) {
        return (
          <>
            {
              t.approveFlg == 0 ? (
            <Button
              type='primary'
              ghost
              size='small'
              onClick={() => {
                onClick('upload', t.mappingId, t)
              }}
            >
              上传
            </Button>
              ) : (
            <Button
              style={{ marginLeft: 5 }}
              type='primary'
              ghost
              size='small'
              onClick={() => {
                onClick('revokeCatalog', t.mappingId, t)
              }}
            >
              撤销
            </Button>
              )
            }
          </>
        )
      },
    },

  ]
}