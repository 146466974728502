/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-12 15:44:35
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface DoctorManagementListPrams {
  current: number
  size: number
  startTime: string
  endTime: string
}

// 列表
export function doctorManagementList(
  params: DoctorManagementListPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/wechat/list`,
    method: 'GET',
    params,
  }
}

// 详情
export function doctorManagementDetail(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/wechat/detail`,
    method: 'GET',
    params: { id },
  }
}

export interface DoctorManagementEditPrams {
  adeptAt?: string
  headPhoto?: any
  file?:any
  intro?: string
  id?: string
  jobTitle?: string
  flag?: any
}

// 保存
export function doctorManagementEditSubmit(
  params: DoctorManagementEditPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/wechat/submit`,
    headers: {
      [ContentType]: MultiPartFormData,
    },
    method: 'POST',
    params,
  }
}

export interface DoctorManagementUploadPrams {
  id?: string
  wechatShowFlg?: any
}

// 保存
export function doctorManagementUpload(
  body: DoctorManagementUploadPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/wechat/updateShowFlag`,
    method: 'POST',
    body,
  }
}
