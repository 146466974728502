import {
  Button,
  Col,
  Collapse,
  Form,
  Modal,
  Row,
  Space,
  Table,
  message,
  notification,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CaretUpFilled } from '@ant-design/icons'
import { getExecuteState, selectNofarmulaDetail } from './noformulaSlice'
import _, { divide } from 'lodash'
import styles from '../formulas/formula.module.css'
import { getRecipeCategoryName } from '../../../../models/recipe'
import { PrintTemplateModal } from '../../../print/PrintTemplateModal'
import { PrintTemplateCategory } from '../../../../models/print'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../../app/store'
import moment from 'moment'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
} from '../../../../models/datetime'
import { getPrintZXDData } from '../../../treatment/editor/recipeEditorSlice'
export const ModelList = (props: {
  paymentId?: any
  treatmentId?: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const success = () => {
    message.success('操作成功')
  }
  const { Panel } = Collapse

  const dispatch = useDispatch<RootDispatch>()

  const nofarmulaDetail = useSelector(selectNofarmulaDetail)

  const [nofarmulaDetails, setNofarmulaDetails] = useState<any[]>([])

  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

  const [recipeId, setRecipeId] = useState<any>()

  const [printZXDData, setPrintZXDData] = useState<any>()

  const [form] = Form.useForm()

  const treatmentId = props.treatmentId

  useEffect(() => {
    setNofarmulaDetails(nofarmulaDetail)
  }, [nofarmulaDetail])

  const getReciptPrintList = (recipeId?: any) => {
    dispatch(getPrintZXDData(recipeId))
      .then(unwrapResult)
      .then((v: any) => {
        setPrintZXDData(v)
      })
  }
  return (
    <>
      <Modal
        title='处方执行信息'
        width='1035px'
        visible={props.visible}
        footer={null}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          props.onCancel()
        }}
      >
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '600px',
          }}
        >
          {nofarmulaDetails &&
            nofarmulaDetails.map((b: any) => (
              <Row
                key={b.index}
                style={{
                  marginBottom: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p>
                  {b.registration.patientName} (
                  {b.registration.patientSex == 1
                    ? '男，'
                    : b.registration.patientSex == 2
                    ? '女，'
                    : '未知，'}
                  {b.registration.patientAge}周岁)
                  {b.registration.registrationCategory == 0 ? ' 自费' : ' 医保'}
                </p>
                <Button
                  type='primary'
                  onClick={() => {
                    const treatmentId = b.registration.treatmentId
                    dispatch(getExecuteState({ treatmentId: treatmentId }))
                      .then(unwrapResult)
                      .then(() => {
                        notification.success({
                          message: '执行成功',
                          duration: 3,
                        })
                      })
                    form.resetFields()
                    props.onCancel()
                  }}
                >
                  全部执行
                </Button>
              </Row>
            ))}

          <div
            style={{
              overflowY: 'scroll',
              minHeight: '600px',
            }}
          >
            <Space direction='vertical' className={styles.space}>
              <Collapse
                className={styles.head}
                collapsible='header'
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretUpFilled
                    rotate={isActive ? 90 : 0}
                    style={{ marginBottom: '10px', color: '#666666' }}
                  />
                )}
                expandIconPosition='right'
              >
                {nofarmulaDetails &&
                  nofarmulaDetails.map((n: any) => (
                    <>
                      {n.recipeExecuteShortVOList.map((v: any) => (
                        <Panel
                          className={styles.content}
                          style={{ marginBottom: '10px' }}
                          key={v.index}
                          header={
                            <Row
                              style={{
                                width: '906px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  height: '100%',
                                }}
                              >
                                <span
                                  style={{ border: 'none', marginRight: '5px' }}
                                >
                                  {moment(v.recipeTime).format(
                                    DateTimeFormatSimple
                                  )}
                                </span>
                                <span
                                  style={{
                                    border: 'none',
                                    fontWeight: 'bold',
                                    color: '#333333',
                                    marginRight: '5px',
                                    fontSize: '16px',
                                  }}
                                >
                                  {getRecipeCategoryName(v.category)}
                                </span>
                                <span>编号：{v.recipeId}</span>
                              </div>
                              <div>
                                <Button
                                  type='primary'
                                  style={{ marginLeft: '10px' }}
                                  onClick={() => {
                                    const recipeId = v.recipeId
                                    setRecipeId(recipeId)
                                    if (v.recipeId) {
                                      getReciptPrintList(v.recipeId)
                                    }
                                    setIsPrintModalVisible(true)
                                  }}
                                >
                                  执行单打印
                                </Button>
                                <Button
                                  type='primary'
                                  style={{ marginLeft: '10px' }}
                                  ghost
                                  onClick={() => {
                                    const recipeId = v.recipeId
                                    dispatch(
                                      getExecuteState({ recipeId: recipeId })
                                    )
                                      .then(unwrapResult)
                                      .then(() => {
                                        notification.success({
                                          message: '执行成功',
                                          duration: 3,
                                        })
                                      })
                                    props.onCancel()
                                  }}
                                >
                                  全部执行
                                </Button>
                              </div>
                            </Row>
                          }
                        >
                          <Collapse
                            className={styles.header}
                            ghost
                            style={{ background: '#F4F8FB', padding: 0 }}
                            expandIcon={({ isActive }) => (
                              <CaretUpFilled
                                rotate={isActive ? 90 : 0}
                                style={{ color: '#666666' }}
                              />
                            )}
                            expandIconPosition='right'
                          >
                            <Panel
                              key={v.index}
                              className={styles.main}
                              header={
                                <Row
                                  style={{
                                    display: 'flex',
                                    height: '24px',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                    }}
                                  >
                                    <Col style={{ width: '752px' }}>
                                      {getRecipeCategoryName(v.category)}
                                      处方单，处方编号：
                                      {v.recipeId}
                                    </Col>
                                    <Col
                                      style={{
                                        color: '#027AFF',
                                        fontSize: '16px',
                                      }}
                                    >
                                      单击查看处方信息
                                    </Col>
                                  </div>
                                </Row>
                              }
                            >
                              <Table
                                className={styles.table}
                                pagination={false}
                                size='small'
                                bordered
                                dataSource={v.recipeItemExecuteVOList}
                                rowKey={(t: any) => t.id}
                                columns={[
                                  {
                                    title: '序号',
                                    key: 'order',
                                    align: 'center',
                                    render: (_1, _2, index) =>
                                      (index + 1).toString(),
                                  },
                                  {
                                    title: '组号',
                                    dataIndex: 'groupNumber',
                                    align: 'center',
                                    key: 'groupNumber',
                                  },
                                  {
                                    title: '处方名称',
                                    dataIndex: 'name',
                                    align: 'center',
                                    key: 'name',
                                  },
                                  {
                                    title: '药品用法',
                                    dataIndex: 'normalUsemethod',
                                    align: 'center',
                                    key: 'normalUsemethod',
                                  },
                                  {
                                    title: '数量',
                                    dataIndex: 'drugCount',
                                    align: 'center',
                                    key: 'drugCount',
                                  },
                                  {
                                    title: '每次用量',
                                    dataIndex: 'normalDose',
                                    align: 'center',
                                    key: 'normalDose',
                                  },
                                  {
                                    title: '频次',
                                    dataIndex: 'normalFrequency',
                                    align: 'center',
                                    key: 'normalFrequency',
                                  },
                                  {
                                    title: '操作',
                                    align: 'center',
                                    render: function element(_, t) {
                                      return (
                                        <Button
                                          type='primary'
                                          onClick={() => {
                                            dispatch(
                                              getExecuteState({ id: t.id })
                                            )
                                              .then(unwrapResult)
                                              .then(() => {
                                                notification.success({
                                                  message: '执行成功',
                                                  duration: 3,
                                                })
                                              })
                                            props.onCancel()
                                          }}
                                        >
                                          全部执行
                                        </Button>
                                      )
                                    },
                                  },
                                ]}
                              ></Table>
                            </Panel>
                          </Collapse>
                        </Panel>
                      ))}
                    </>
                  ))}
              </Collapse>
            </Space>
          </div>
          <Row gutter={20} style={{ marginTop: '10px' }}>
            <Col span={20}>
              {/* <Button
                type='primary'
                htmlType='submit'
                style={{ float: 'right' }}
                onClick={() => {
                  setIsPrintModalVisible(true)
                }}
              >
                打印执行单
              </Button> */}
            </Col>
            <Col span={1}>
              <Button
                type='primary'
                onClick={() => {
                  form.resetFields()
                  props.onCancel()
                }}
              >
                关闭(ESC)
              </Button>
            </Col>
          </Row>
        </Col>
      </Modal>
      <PrintTemplateModal
        recipeId={recipeId}
        printZXDData={printZXDData}
        pagePrintFlag={'zxdPrint'}
        paymentId={props?.paymentId}
        templateCategory={PrintTemplateCategory.Zxd}
        visible={isPrintModalVisible}
        treatmentId={treatmentId}
        onCancel={() => setIsPrintModalVisible(false)}
      />
    </>
  )
}
