/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-14 10:11:49
 * @LastEditors: sj
 * @LastEditTime: 2022-12-01 11:32:01
 */

import { API_PREFIX } from './constant'
import { Request } from './request'

// 随访列表
export interface getFollowUpListPageFnParams {
  current: number
  size: number
  state: number
  endTime?: string
  startTime?: string
  name?: string
  userId?: string
  patientId?: string
}
export function getFollowUpListPageFn(
  params: getFollowUpListPageFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/visitrecord/list`,
    method: 'GET',
    params,
  }
}

// 随访列表-查看详情
export interface detailFollowUpListPageFnParams {
  id: number
}
export function detailFollowUpListPageFn(
  params: detailFollowUpListPageFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/visitrecord/detail`,
    method: 'GET',
    params,
  }
}

//随访人
export interface getFollowUpPeopleListParams {
  current: number
  size: number
  stationCategory?: number
  state?: number
  param?: any
}
export function followUpPeopleList(
  params: getFollowUpPeopleListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/list`,
    method: 'GET',
    params,
  }
}
// 随访类型
export function FollowUpTypeFn(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/visittype/list`,
    method: 'GET',
  }
}
// 取消随访
export function cancelFollowUpFn(
  body: detailFollowUpListPageFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/visitrecord/remove`,
    method: 'POST',
    body,
  }
}

// 随访类型

export function followUpTypeList(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/visittype/list`,
    method: 'GET',
  }
}

// 待随访详情
export function followUpWaitDetail(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/visitrecord/detailById`,
    method: 'GET',
    params: { id },
  }
}

// 待随访取消
export function followUpWaitCancel(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/visitrecord/remove`,
    method: 'POST',
    body: { id },
  }
}

// 随访保存
export interface getFollowUpTypeSubmitListParams {
  id?: string
  mnemonicCode?: any
  name?: any
  tenantId?: any
}
export function followUpTypeSubmit(
  body: getFollowUpTypeSubmitListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/visittype/save`,
    method: 'POST',
    body,
  }
}

// 删除

export function removeFollowUpType(id: any): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/visittype/remove`,
    method: 'POST',
    body: { id },
  }
}

export interface getFollowUpSubmitListParams {
  bindId: any
  bindType: string
  content?: string
  flag?: string
  id?: string
  name?: string
  paytientId?: string
  result?: string
  state: string
  tenantId?: string
  userId: string
  userName?: string
  visitTimeList: any
  way: number
}
export function followUpSubmit(body: getFollowUpSubmitListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/visitrecord/submit`,
    method: 'POST',
    body,
  }
}
