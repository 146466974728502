/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 11:13:36
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../../app/store'
import { LoadingState } from '../../../../models/common'
import { emptyOf, Pagination } from '../../../../models/pagination'
import { fromJson, piTest } from '../../../../models/pitest'
import * as service from '../../../../services/pitest'
import { pitestQueryParams } from '../../../../services/pitest'
interface PitestState {
  PitestData: []
  Total: 0
  Current: 1
  peopleList: piTest[]
  pageLoading: false
}

const initialState: PitestState = {
  PitestData: [],
  Total: 0,
  Current: 1,
  peopleList: [],
  pageLoading: false,
}

const pitestSlice = createSlice({
  name: 'pitestSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.PitestData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setpeopleList: (state, action) => {
      state.peopleList = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setData,
  setTotal,
  setCurrent,
  setpeopleList,
  setPageLoading,
} = pitestSlice.actions

export const selectPitestData = (state: RootState) => state.pitest.PitestData

export const selectTotal = (state: RootState) => state.pitest.Total

export const selectCurrent = (state: RootState) => state.pitest.Current

export const selectPageLoading = (state: RootState) => state.pitest.pageLoading

export const selectPeopleList = (state: RootState) => state.pitest.peopleList

export function getPitestList(params: pitestQueryParams): RootThunk {
  return api(service.getPitestList(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

export function getpeopleList(size?: any): RootThunk {
  return api(service.getPeopleList(size), (data: any, dispatch) => {
    const list = data.records
    dispatch(setpeopleList(list))
  })
}

// 弹窗编辑确认提交
export const getPitestSubmit = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>("medicalstation/pitestSubmit", async (params, api) => {
  return sendAsync(service.pitestSubmit(params), api);
});

export default pitestSlice.reducer
