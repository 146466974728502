/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 14:10:35
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-23 14:41:26
 */
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Col, Divider, Modal, Row, Radio, notification } from 'antd'
import React, { ReactElement, useEffect, useState, useContext } from 'react'
import Qrcode from 'qrcode.react'
import { useHistory } from 'react-router-dom'
import { DelectOutlined } from '../../../compnents/icons/Icons'
import { AccreditQrCodeModal } from './accreditQrCodeModal'
import { ThemeContext } from '../../../theme/ThemeContext'
import styles from './official.module.css'
import { OfficialQrCodeModal } from './officialQrCodeModal'
import imgOne from '../images/imgOne.png'
import imgTwo from '../images/imgTwo.png'
import imgThree from '../images/imgThree.png'
import imgFour from '../images/imgFour.png'
import imgFive from '../images/imgFive.jpg'
import { getWechatConfig, getPayInfo } from './officialSlice'
import { RootDispatch } from '../../../app/store'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { setDetail } from '../../sales/DrugStatic/Modal/modalSlice'
import { payStatusAsync } from '../../productCenter/productCenterSlice'

let productTimer: any = null

export const Official = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const [authModal, setAuthModal] = useState(false)

  const [isAccreditVisible, setIsAccreditVisible] = useState(false)

  const [weChatOpen, setWeChatOpen] = useState<any>(0)

  const [data, setData] = useState<any>()

  const [loading, setLoading] = useState(false)

  const [payType, setpayType] = useState(1)

  const [payInfo, setPayInfo] = useState<any>({})

  const [isOfficialQrCodeVisible, setIsOfficialQrCodeVisible] = useState(false)

  const getQrcode = () => {
    dispatch(getPayInfo({ payType: payType, productId: 21, durationNum: 1 }))
      .then(unwrapResult)
      .then((res: any) => {
        setPayInfo(res)
        setAuthModal(true)
        productTimer && clearInterval(productTimer)
        setLoading(false)
        productTimer = setInterval(() => {
          dispatch(payStatusAsync(res.orderId))
            .then(unwrapResult)
            .then((res: any) => {
              if (res.status == 1) {
                notification.success({
                  message: '支付成功',
                  // onClose: () => {

                  // },
                })
                productTimer && clearInterval(productTimer)
                productTimer = null
                getPage()
                setAuthModal(false)
              }
            })
        }, 1000)
      })
  }

  useEffect(() => {
    if (!authModal) return
    setLoading(true)
    productTimer && clearInterval(productTimer)
    getQrcode()
    return () => {
      productTimer && clearInterval(productTimer)
      productTimer = null
    }
  }, [payType])

  const getPage = () => {
    dispatch(getWechatConfig())
      .then(unwrapResult)
      .then((v) => {
        if (JSON.stringify(v) !== '{}') {
          setWeChatOpen(2)
          history.push({
            pathname: '/mainManagement',
            state: {},
          })
          setData(v)
        } else {
          setWeChatOpen(1)
        }
      })

    const headItem = document.head
    const oMeta = document.createElement('meta')
    oMeta.name = 'referrer'
    oMeta.content = 'never'
    headItem.appendChild(oMeta)
  }

  useEffect(() => {
    getPage()
  }, [])

  useEffect(() => {
    return () => {
      productTimer && clearInterval(productTimer)
      productTimer = null
    }
  }, [])

  useEffect(() => {
    productTimer && clearInterval(productTimer)
    productTimer = null
    if (!authModal) {
      setpayType(1)
    }
  }, [authModal])

  return (
    <>
      {weChatOpen === 0 ? (<></>) : weChatOpen === 1 ? (
        <div className={styles.container}>
          <div>
            <div className={styles.auth}>
              <div>
                <p>
                  授权微信公众号，诊所和微信打通，授权后可以在这里管理公众号：
                </p>
                <p>1.支持自定义移动端诊所简介页，起到宣传诊所的作用；</p>
                <p>
                  2.支持自定义医生信息，让顾客在家提前了解并选择合适的医生；
                </p>
                <p>3.支持顾客随时随地预约诊所服务。</p>
              </div>
              <div
                className={styles.authBtn}
                onClick={() => {
                  // setAuthModal(true)
                  getQrcode()
                }}
              >
                点击开通
              </div>
            </div>
            <Divider />
            <p className={styles.example}>示例图</p>
            <Row gutter={20}>
              <Col span={6} className={styles.exampleImg}>
                <img
                  src={imgFive}
                  style={{ width: '270px', height: '536px' }}
                ></img>
                <div className={styles.backName}>诊所首页</div>
              </Col>
              <Col span={6} className={styles.exampleImg}>
                <img
                  src={imgTwo}
                  style={{ width: '270px', height: '536px' }}
                ></img>
                <div className={styles.backName}>诊所概况</div>
              </Col>
              <Col span={6} className={styles.exampleImg}>
                <img
                  src={imgThree}
                  style={{ width: '270px', height: '536px' }}
                ></img>
                <div className={styles.backName}>医生详情</div>
              </Col>
              <Col span={6} className={styles.exampleImg}>
                <img
                  src={imgFour}
                  style={{ width: '270px', height: '536px' }}
                ></img>
                <div className={styles.backName}>在线预约</div>
              </Col>
            </Row>
          </div>

          <Modal
            width={500}
            className={styles.qrCodeModal}
            style={{ top: 200 }}
            footer={null}
            title='扫码付款'
            visible={authModal}
            maskClosable={false}
            keyboard={false}
            onCancel={() => {
              setAuthModal(false)
              setPayInfo({})
            }}
          >
            <Row justify='center'>
              <p style={{ marginBottom: '0px', fontSize: '17px', color: 'gray' }}>
                您正在购买【公众服务包】服务包，数量 1/次</p>
              <p style={{ fontSize: '15px', color: 'gray' }}>
                支付成功后，系统会自动处理您的订单。
              </p>
            </Row>
            <Row justify='center'>
              <Radio.Group
                value={payType}
                buttonStyle='solid'
                onChange={(e) => {
                  setpayType(e.target.value)
                }}
              >
                <Radio.Button
                  disabled={loading}
                  value={1}
                  style={{ width: 150, textAlign: 'center' }}
                >
                  微信
                </Radio.Button>
                <Radio.Button
                  disabled={loading}
                  value={3}
                  style={{ width: 150, textAlign: 'center' }}
                >
                  支付宝
                </Radio.Button>
              </Radio.Group>
            </Row>
            <Row style={{ margin: '20px 0' }} justify='center'>
              <Qrcode
                value={payInfo.payCode}
                color={theme.tc1}
                size={256}
                style={{
                  width: 256,
                  height: 256,
                }}
              />
            </Row>
            <Row justify='center'>（ 付款金额：{payInfo.money}元 ）</Row>
          </Modal>

          {/* <Modal
            visible={authModal}
            title='授权公众号'
            onCancel={() => {
              setAuthModal(false)
            }}
            centered
            width={500}
            footer={null}
            maskClosable={false}
            keyboard={false}
          >
            <div className={styles.authCellModal}>
              <span>诊所已有公众号（必须是服务号），立即授权</span>
              <span
                className={styles.authBtnModal}
                onClick={() => {
                  setIsAccreditVisible(true)
                }}
              >
                立即授权
              </span>
            </div>
            <div className={styles.authCellModal}>
              <span>诊所未注册公众号，诊所先去注册公众号</span>
              <span
                className={styles.authBtnModal}
                onClick={() => {
                  window.open(
                    'https://mp.weixin.qq.com/cgi-bin/registermidpage?action=index&lang=zh_CN&token='
                  )
                }}
              >
                注册公众号
              </span>
            </div>
            <div className={styles.authCellModal}>
              <span>诊所未注册公众号，可联系平台代办</span>
              <span
                className={styles.authBtnModal}
                onClick={() => {
                  Modal.info({
                    title: '您好',
                    content: (
                      <div>
                        <p>您可以拨打服务电话：17366656508 咨询代办事宜</p>
                      </div>
                    ),
                    okText: '好的',
                    icon: null,
                    centered: true,
                    closable: true,
                    maskClosable: true,
                    onOk: () => {
                      return
                    },
                  })
                }}
              >
                联系客服
              </span>
            </div>
          </Modal> */}

          {/* 二维码 */}
          <AccreditQrCodeModal
            visible={isAccreditVisible}
            onCancel={() => {
              setIsAccreditVisible(false)
            }}
            onOk={() => {
              setIsAccreditVisible(false)
            }}
          />
        </div>
      ) : weChatOpen === 2 ? (
        <div className={styles.container}>
          <div
            className={styles.aleadyAccredit}
            onClick={(event: any) => {
              event.stopPropagation()
              history.push({
                pathname: '/mainManagement',
                state: {},
              })
            }}
          >
            <div className={styles.aleadyAccreditTitle}>
              <div className={styles.aleadyAccreditTitleLeft}>服务号</div>
              <div className={styles.aleadyAccreditTitleRight}>
                <span>已授权</span>
              </div>
            </div>
            <div className={styles.aleadyAccreditQrCode}>
              <img
                src={data?.headImg}
                style={{ width: '100%', height: '100%' }}
              ></img>
            </div>
            <div className={styles.aleadyAccreditSubTitle}>
              点击进入 — {data?.nickName}
            </div>
            <div
              className={styles.aleadyAccreditSubButtonTitle}
              onClick={(event: any) => {
                event.stopPropagation()
                setIsOfficialQrCodeVisible(true)
              }}
            >
              <span>公众号二维码</span>
            </div>
            <div
              className={styles.aleadyAccreditSubDelete}
              onClick={(event: any) => {
                event.stopPropagation()
                Modal.info({
                  centered: true,
                  title: '提示',
                  width: 500,
                  content: (
                    <div>
                      若要取消授权，请登录微信公众平台，在【公众号设置】 ——
                      【授权管理】中 取消授权即可。
                    </div>
                  ),
                  icon: null,
                  onOk: () => {
                    // onAction('delete', t)
                  },
                })
              }}
            >
              <DelectOutlined style={{ fill: '#999999 ' }} />
            </div>
          </div>
          {/* 公众号二维码 */}
          <OfficialQrCodeModal
            qrCodeUrl={data?.qrCodeUrl}
            visible={isOfficialQrCodeVisible}
            onCancel={() => {
              setIsOfficialQrCodeVisible(false)
            }}
            onOk={() => {
              setIsOfficialQrCodeVisible(false)
            }}
          />
        </div>
      ) : <></>}
    </>
  )
}
