import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Form, Input, Modal, notification, Popconfirm, Space, TableColumnType } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch } from '../../../../app/store';
import { EditableList } from '../../../../compnents/list/EditableList';
import { getbirthdayStatusName } from '../../../../models/ToothHome';
import styles from "../ToothHome.module.css";
import { getSignature, selectSignatureList, setSignatureList, signDeleteByIdAsync, smsTemplateAsync } from '../toothHomeSlice';
interface messageSignatureModalProps {
  visible: boolean
  onCancel: () => void
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}
export const MessageSignatureModal = ({
  visible,
  onCancel
}: messageSignatureModalProps): ReactElement => {

  const dispatch = useDispatch<RootDispatch>()

  const signatureList = useSelector(selectSignatureList)

  const [form] = Form.useForm();

  const [page, setPage] = useState<any>({
    items: [],
  })

  const [addMessageLabelModal, setAddMessageLabelModal] = useState(false) //新增短信标签弹窗

  const [editId, setEditId] = useState('')
  const [editName, setEditName] = useState('')

  useEffect(() => {
    if (!addMessageLabelModal) {
      form.resetFields()
    } else {
      form.setFieldsValue({
        signName: editName
      })
    }
  }, [addMessageLabelModal, editName])

  useEffect(() => {
    setPage({
        items: signatureList
      })
  }, [signatureList])

  useEffect(() => {
    getSignatureList()
  }, [])

  const getSignatureList = () => {
    dispatch(getSignature())
      .then(unwrapResult)
      .then((res: any) => {
        dispatch(setSignatureList(res))
      })
  }

  const close = () => {
    setAddMessageLabelModal(false)
    setEditId('')
    setEditName('')
  }

  return (
    <Modal
      title='短信签名'
      width={900}
      visible={visible}
      onCancel={onCancel}
      mask={!addMessageLabelModal}
      footer={null}
    >
      <div style={{ height: 550 }}>
        <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
          <Button type='primary' onClick={() => setAddMessageLabelModal(true)}>
            新增短信签名
          </Button>
        </div>

        <EditableList
          className={styles.messageTable}
          pagination={false}
          dataSource={page.items}
          columns={
            [
              {
                title: '序号',
                key: 'no',
                align: 'center',
                width: '3rem',
                render: (_1, _2, index) => `${index + 1}`,
              },
              {
                title: '短信签名',
                // width: '6rem',
                dataIndex: 'signName',
                align: 'center',
                ellipsis: true,
              },
              {
                title: '操作',
                dataIndex: 'action',
                align: 'center',
                // width: '6rem',
                ellipsis: true,
                render: function Element(_, t) {
                  return (
                    <>
                      {t.signName != '好医点' && t.signName != '麟康医疗' && (
                        <Space>
                          <Button
                            type='link'
                            onClick={() => {
                              setEditName(t.signName)
                              setEditId(t.id)
                              setAddMessageLabelModal(true)
                            }}
                          >
                            编辑
                          </Button>
                          <Popconfirm
                            title='确认此操作？'
                            onConfirm={async () => {
                              await dispatch(signDeleteByIdAsync(t.id)).then(
                                unwrapResult
                              )
                              notification.success({
                                message: '删除成功',
                              })
                              getSignatureList()
                            }}
                            okText='确认'
                            cancelText='取消'
                          >
                            <Button type='link'>删除</Button>
                          </Popconfirm>
                        </Space>
                      )}
                    </>
                  )
                },
              },
            ] as TableColumnType<any>[]
          }
        />
      </div>
      <Modal
        title={(editId ? '修改' : '新增') + '新增短信标签'}
        width={500}
        visible={addMessageLabelModal}
        onCancel={close}
        footer={null}
        destroyOnClose
      >
        <div>
          <Form
            form={form}
            {...layout}
            style={{ paddingRight: 10 }}
            onFinish={(values) => {
              dispatch(
                smsTemplateAsync({
                  signName: values.signName,
                  id: editId ? editId : undefined,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  notification.success({
                    message: editId ? '修改成功' : '新增成功',
                  })
                  getSignatureList()
                  close()
                })
            }}
          >
            <Form.Item
              name='signName'
              label='短信标签'
              rules={[{ required: true }]}
            >
              <Input allowClear showCount maxLength={6} />
            </Form.Item>
            <div
              style={{ display: 'flex', justifyContent: 'end', width: '100%' }}
            >
              <Space>
                <Button onClick={() => setAddMessageLabelModal(false)}>
                  取消
                </Button>
                <Button type='primary' htmlType='submit'>
                  提交
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </Modal>
    </Modal>
  )
};
