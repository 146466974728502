import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {PurchaseControl} from "./purchaseControl";
import {RootState, RootThunk, RootThunkApi} from "../../../app/store";
import {api, sendAsync} from "../../../app/applicationSlice";
import * as service from "../../../services/purchaseControl";
import {exportPurchaseControl, PurchaseControlParams} from "../../../services/purchaseControl";
import {exportListExoprt} from "../../../services/supplierDealing";


interface PurchaseControls {
    PurchaseControl: []
    Total: 0
    Current: 1
    pageLoading: false
}

const initialState: PurchaseControls = {
    PurchaseControl: [],
    Total: 0,
    Current: 1,
    pageLoading: false,
}

const purchaseControlSlice = createSlice({
    name: 'purchaseControlSlice',
    initialState,
    reducers: {
        setData(state, action) {
            state.PurchaseControl = action.payload
        },
        setTotal(state, action) {
            state.Total = action.payload
        },
        setCurrent(state, action) {
            state.Current = action.payload
        },
        setPageLoading: (state, action) => {
            state.pageLoading = action.payload
        },
    },
})

export const {
    setData,
    setTotal,
    setCurrent,
    setPageLoading,
} = purchaseControlSlice.actions

export function getPurchaseControlList(
    params: PurchaseControlParams
): RootThunk {
    return api(service.getPurchaseControl(params), (data: any, dispatch) => {
        dispatch(setData(data.records))
        dispatch(setTotal(data.total))
        dispatch(setPageLoading(false))
    })
}

export const getExportListExoprt = createAsyncThunk<
    void,
    PurchaseControlParams,
    RootThunkApi
>('purchaseControl/getExportListExport', async (params, api) => {
    return sendAsync(exportPurchaseControl(params), api)
})


export const selectPurchaseControlsData = (state: RootState) =>
    state.purchaseControl.PurchaseControl
export const selectTotal = (state: RootState) => state.purchaseControl.Total

export const selectCurrent = (state: RootState) => state.purchaseControl.Current

export const selectPageLoading = (state: RootState) =>
    state.purchaseControl.pageLoading


export default purchaseControlSlice.reducer