/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-25 16:35:54
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../../app/applicationSlice";
import { RootState, RootThunkApi } from "../../../app/store";
import {
  DateRangeFieldName,
  DateRangeType,
  getDateRange,
} from "../../../compnents/form/DateSelect";
import { DoctorSalesResult, fromJson } from "../../../models/doctorSales";
import * as service from "../../../services/doctorSales";
import { GetDoctorSaleEntriesParams, getDoctorAuditExport } from "../../../services/doctorSales";

interface DoctorSalesState {
  params: GetDoctorSaleEntriesParams;
}

const [timeHead, timeTail] = getDateRange(DateRangeType.Today);

const initialState = {
  params: {
    timeHead,
    timeTail,
    [DateRangeFieldName]: DateRangeType.Today,
  },
} as DoctorSalesState

const doctorSaleSlice = createSlice({
  name: "drugCostSlice",
  initialState,
  reducers: {
    setGetDoctorSaleEntriesParams: (
      state,
      action: PayloadAction<Partial<GetDoctorSaleEntriesParams>>
    ) => {
      state.params = { ...state.params, ...action.payload };
    },
  },
});

export const { setGetDoctorSaleEntriesParams } = doctorSaleSlice.actions;

export const selectGetDoctorSaleEntriesParams = (
  state: RootState
): GetDoctorSaleEntriesParams => state.doctorSale.params;

export const getDoctorSaleEntries = createAsyncThunk<
  DoctorSalesResult,
  GetDoctorSaleEntriesParams,
  RootThunkApi<DoctorSalesResult>
>("doctorSales/getDoctorSaleEntriesStatus", async (params, api) => {
  return sendAsync(service.getDoctorSale(params), api).then((data) => {
    return {
      headers: data.headerList || [],
      entries:
        _.chain(data.allDoctorSaleList)
          .map((e) => fromJson(e))
          .value() || [],
    };
  });
});

export const getDoctorSaleEntriesArea = createAsyncThunk<
  DoctorSalesResult,
  GetDoctorSaleEntriesParams,
  RootThunkApi<DoctorSalesResult>
>('doctorSales/getDoctorSaleEntriesArea', async (params, api) => {
  return sendAsync(service.getDoctorSaleArea(params), api).then((data) => {
    return {
      headers: data.headerList || [],
      entries:
        _.chain(data.allDoctorSaleList)
          .map((e) => fromJson(e))
          .value() || [],
    }
  })
})

// 医生看板
export const doctorSaleBulletinAsync = createAsyncThunk<
  void,
  GetDoctorSaleEntriesParams,
  RootThunkApi
>("doctorAudit/doctorSaleBulletinAsync ", async (params, api) => {
  return sendAsync(service.doctorSaleBulletin(params), api);
});



// 导出
export const getDoctorAuditExoprt = createAsyncThunk<
  void,
  GetDoctorSaleEntriesParams,
  RootThunkApi
>("doctorAudit/getDoctorAuditExport ", async (params, api) => {
  return sendAsync(service.getDoctorAuditExport(params), api);
});

export default doctorSaleSlice.reducer;
