import { TableColumnType } from "antd";
import React from "react";

export const Columns = (): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      dataIndex: "",
      render: (text: any, record: any, index: number) => `${index + 1}`,
    },
    {
      title: "姓名",
      align: "center",
      dataIndex: "userName",
    },
    {
      title: "行政科室",
      align: "center",
      dataIndex: "departmentName",
    },
  ];
};
