/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-14 19:47:06
 */
export interface DoctorSaleEntry {
  id: string
  amountPerTip: number
  dr01: number
  dr02: number
  dr03: number
  dr101: number
  drOther: number
  drTip: number
  personTime: string
  total: number
  realTotal: number
  tr04: number
  cz: number
  fz: number
  tr06: number
  tr30: number
  tr07: number

  tr08: number
  tr09: number
  tr10: number
  tr11: number
  tr23: number
  tr24: number
  tr26: number
  tr27: number
  tr28: number

  tr97: number
  tr05: number
  dr17: number
  tr25: number
  trOther: number
  otherTreatment: number
  treatmentDepartmentId: number
  treatmentDepartmentName: string
  treatmentDoctorId?: number
  treatmentDoctorName?: string
  amountPerTipInsurance?: any
  drTipInsurance?: number
  allTotalAmount?: number
  areaName?: any
  treatmentAreaDepartmentId?: any
}

export interface DoctorSalesResult {
  headers: string[]
  entries: DoctorSaleEntry[]
}

export function fromJson(json: any): DoctorSaleEntry {
  return {
    id: json.id?.toString() || '',
    amountPerTip: json.amountPerTip,
    dr01: json.dr01,
    dr02: json.dr02,
    dr03: json.dr03,
    dr101: json.dr101,
    drOther: json.drOther,
    drTip: json.drTip,
    personTime: json.personTime,
    total: json.total,
    realTotal: json.realTotal,
    tr04: json.tr04,
    cz: json.cz,
    fz: json.fz,
    tr06: json.tr06,
    tr30: json.tr30,
    tr97: json.tr97,
    tr05: json.tr05,
    tr07: json.tr07,
    dr17: json.dr17,
    tr25: json.tr25,

    tr08: json.tr08,
    tr09: json.tr09,
    tr10: json.tr10,
    tr11: json.tr11,
    tr23: json.tr23,
    tr24: json.tr24,
    tr26: json.tr26,
    tr27: json.tr27,
    tr28: json.tr28,
    otherTreatment: json.otherTreatment,
    trOther: json.trOther,
    treatmentDepartmentId: json.treatmentDepartmentId,
    treatmentDepartmentName: json.treatmentDepartmentName,
    treatmentDoctorId: json.treatmentDoctorId,
    treatmentDoctorName: json.treatmentDoctorName,
    amountPerTipInsurance: json.amountPerTipInsurance,
    drTipInsurance: json?.drTipInsurance,
    allTotalAmount: json?.allTotalAmount,
    areaName: json?.areaName,
    treatmentAreaDepartmentId: json?.treatmentAreaDepartmentId,
  }
}
