import { Button, Form, Space, Switch, TableColumnType } from "antd";
import React from "react";

export const MaintenanceDrugsColumns = (
    // onclick: (t: string, id: any, name: string) => void,
    // startIndex: number
): TableColumnType<any>[] => {
    return [
        {
            title: "序号",
            align: "center",
            key: "no",
            render: (text: any, record: any, index: number) => `${index + 1}`,
        },
        {
            title: "药品信息",
            align: "center",
            dataIndex: "",
            key: "",

        },

        {
            title: "批次",
            align: "center",
            dataIndex: "",
            key: "",
        },
        
        {
            title: "库存数量",
            align: "left",
            dataIndex: "",
            key: "",

        },
        // 可编辑
        {
            title: "养护数量",
            align: "left",
            dataIndex: "",
            key: "",

        },
         // 可编辑
        {
            title: "不合格数量",
            align: "left",
            dataIndex: "",
            key: "",

        },
        // 下拉框 
        {
            title: "养护措施",
            align: "left",
            dataIndex: "",
            key: "",

        },
        // 下拉框 
        {
            title: "质量状况",
            align: "center",
            dataIndex: "",
            key: "",
        },
        {
            title: "养护结果",
            align: "center",
            dataIndex: "",
            key: "",
        },

    ];
};
