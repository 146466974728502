import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import {} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { FieldData } from '../../../../models/fielddata'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../../compnents/form/DateSelect'
import { pitestQueryParams } from '../../../../services/nopitest'
import { getpeopleList, selectPeopleList } from './pitestSlice'
export const PitestQuery = (props: {
  onValueChange: (queries: pitestQueryParams) => void
}): ReactElement => {
  const [form] = Form.useForm()
  const [values, setValues] = useState('')
  const { Option } = Select

  const peopleList = useSelector(selectPeopleList)
  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
    })
    form.submit()
  }, [])
  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='param' noStyle>
              <Input
                autoComplete='off'
                placeholder='请输入患者姓名或药品名称或病历号'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle name='result'>
              <Select
                placeholder='皮试结果'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                <Option value='0'> 阴性</Option>
                <Option value='1'>阳性</Option>
                <Option value='2'>弱阳性</Option>
                <Option value='3'>强阳性</Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle name='testUser'>
              <Select
                placeholder='皮试人员'
                allowClear
                style={{ width: 140 }}
                onChange={form.submit}
              >
                {peopleList.map((v: any) => {
                  return (
                    <Option value={v.id} key={v.id}>
                      {v.realname}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <DateSelect
              style={{ width: '8.5rem' }}
              labelPrefix=''
              namePrefix='time'
              placeholder='就诊时间'
              onChange={() => form.submit()}
              initialValue={DateRangeType.Today}
              allowClear={false}
            />
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
