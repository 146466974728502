/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-08-08 09:33:56
 * @LastEditors: sj
 * @LastEditTime: 2022-08-09 10:39:30
 */
import { Col, Modal, Tabs } from 'antd';
import React, { ReactElement } from 'react';
import { Commodity } from '../commodity/commodity';
import { Durgsystem } from '../durgsystem/durgsystem';
import { Examinetreat } from '../examinetreat/examinetreat';
import { SetMeal } from '../setmeal/setmeal';
import { SupplierLibrary } from '../storehouse/SupplierLibrary/library';
import { WarehouseBusinessLibrary } from '../storehouse/WarehouseBusinessLibrary/library';
import { WarehouseManagementLibrary } from '../storehouse/WarehouseManagement/library';
import { WarehouseSearchLibrary } from '../storehouse/WarehouseSearchLibrary/library';
import { ShoopManagement } from './directoryUpdate/shoopManagement';
import { TreatmentManagement } from './directoryUpdate/treatmentManagement';
import styles from './Home.module.css'
interface CatalogueUpdateModalProps {
  visible: boolean
  onOk?: () => void
  onCancel?: () => void
}

export const CatalogueUpdate = ({
  onOk,
  onCancel,
  visible,
}: CatalogueUpdateModalProps): ReactElement => {
  const { TabPane } = Tabs;
  return (
    <Modal
      visible={visible}
      title='目录更新'
      width={1200}
      maskClosable={false}
      destroyOnClose
      style={{ overflow: 'hidden', padding: '0' }}
      bodyStyle={{ padding: '0 5px', height: 600, overflow: 'auto' }}
      onCancel={() => {
        onCancel && onCancel()
      }}
      onOk={() => {
        return
      }}
      zIndex={99}
    >
      <Col className={styles.container}>
        <Tabs>
          <TabPane key='5' tab='商品管理'>
            <ShoopManagement />
            {/* <Commoity isHome={true} /> */}
          </TabPane>
          <TabPane key='6' tab='诊疗管理'>
            <TreatmentManagement />
            {/* <Examinetreat isHome={true} /> */}
          </TabPane>
        </Tabs>
      </Col>
    </Modal>
  );
}

