/* eslint-disable react/display-name */
import { TableColumnType, Typography } from 'antd'
import React from 'react'
import {
  getChrgitmLvName,
  getHilistLmtpricType,
  getIsCouldBxName,
} from '../../../models/commodity'
const { Text } = Typography

export const InsuranceZfblColumn = (
  onClick: (text: string, id: any, item?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      fixed: 'left',
      render: (_1, _2, index) => <span>{startIndex + index + 1}</span>,
    },{
      title: 'rid',
      // width: '6rem',
      dataIndex: 'rid',
      align: 'center',
      ellipsis: true,
    },{
      title: '医保目录国码',
      // width: '6rem',
      dataIndex: 'hilistCode',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '适用人员类型',
      // width: '6rem',
      dataIndex: 'selfpayPropPsnType',
      align: 'center',
      ellipsis: true,
    },{
      title: '自付比例',
      // width: '6rem',
      dataIndex: 'selfpayProp',
      align: 'center',
      ellipsis: true,
    }
  ]
}
