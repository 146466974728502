/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-06-10 15:45:22
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-16 14:45:10
 */

// 处方分类 1:西药方 3:草药方 4:治疗 6:检查 7:其他 12:颗粒草药方 101:预收挂号费 102:额外费用
// export enum DirectoryCategory {
//   XYZCY = '01' ,
//   ZCY = '03',
//   ZLFW = '30',
//   HCQX = '17',
// }

// export const directoryCategoryLists = [
//   DirectoryCategory.XYZCY,
//   DirectoryCategory.ZCY,
//   DirectoryCategory.ZLFW,
//   DirectoryCategory.HCQX,
// ]

// export function getRecipeCategoryName(category: DirectoryCategory): string {
//   switch (category) {
//     case DirectoryCategory.XYZCY:
//       return '西药/中成药'
//     case DirectoryCategory.ZCY:
//       return '中草药'
//     case DirectoryCategory.ZLFW:
//       return '诊疗服务'
//     case DirectoryCategory.HCQX:
//       return '耗材/器械'
//   }
//   return ''
// }

export const directoryCategoryList = [
  {
    label: '西药/中成药',
    value: '01' ,
  },
  {
    label: '中草药',
    value: '03',
  },
  {
    label: '诊疗服务',
    value: '30',
  },
  {
    label: '耗材/器械',
    value: '17',
  },
]
export const directoryCategoryListInsurance = [
  {
    label: '西药/中成药',
    value: '101',
  },
  {
    label: '中草药',
    value: '102',
  },
  {
    label: '诊疗服务',
    value: '201',
  },
  {
    label: '耗材/器械',
    value: '301',
  },
]
