/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-31 14:23:29
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-29 16:19:33
 */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditableList } from '../../../../compnents/list/EditableList'
import { NavBar } from '../../../../compnents/nav/NavBar'
import { StockMaintenanceParams } from '../../../../services/StockMaintenance'
import { StockMaintenanceColumns } from './column'
import { StockMaintenanceQuery } from './query'
import {
  getStockMaintenanceList,
  maintenanceCompletedAsync,
  removeStockMaintenanceAsync,
  selectPageLoading,
  selectStockMaintenanceCurrent,
  selectStockMaintenanceData,
  selectStockMaintenanceTotal,
  setCurrent,
  setPageLoading,
} from './stockMaintenanceSlice'
import styles from './stockMaintenance.module.css'
import { DateTimeFormat } from '../../../../models/datetime'
import moment from 'moment'
import { DataType } from '../../../putstorage/putstorage'
import { Button, Col, notification, Row } from 'antd'
import { useHistory } from 'react-router'
import { RootDispatch } from '../../../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { PlusOutlined } from '@ant-design/icons'
import { PrintFrame, PrintFrameRef } from '../../../print/PrintFrame'
import { getAddtionDrugListAsync } from '../AdditionDrugs/addtionDrugSlice'
import { PrintAdditionDrugsColumns } from '../AdditionDrugs/column'
import { OneKeyStockMaintenanceModal } from './OneKeyStockNaintenanceModal'

export const StockMaintenance = (): ReactElement => {
  const printRef = useRef<PrintFrameRef>(null)

  const dispatch = useDispatch<RootDispatch>()

  const [queries, setQueries] = useState<StockMaintenanceParams>({
    timeHead: moment().startOf('year').format(DateTimeFormat),
    timeTail: moment().endOf('year').format(DateTimeFormat),
  })

  const current = useSelector(selectStockMaintenanceCurrent)

  const total = useSelector(selectStockMaintenanceTotal)

  const data = useSelector(selectStockMaintenanceData)

  const [size, setSize] = useState(10)

  const pageLoadingRX = useSelector(selectPageLoading)

  const history = useHistory()

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      return
    },
  }

  const [printData, setPrintData] = useState<any>([])

  const [local, setlocal] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    dispatch(setCurrent(1))
    setlocal(JSON.parse(localStorage.getItem('credentials') as string))
  }, [])

  useEffect(() => {
    dispatch(setPageLoading(true))
    dispatch(getStockMaintenanceList({ ...queries, current: current, size }))
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }, [queries, size, current])

  return (
    <>
      <NavBar
        style={{ margin: '10px 20px 0px 20px' }}
        where={['库存管理', '库存养护']}
        backtrace={{
          name: '库房工作站',
          path: '/storage/station',
          state: {
            station: '2',
          },
        }}
        buttons={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type='link'
              // icon={<PlusOutlined />}
              onClick={() => {
                setIsModalVisible(true)
              }}
            >
              一键养护
            </Button>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => history.push('/newEditor')}
            >
              新增
            </Button>
          </div>
        }
      />
      <div className={styles.content}>
        <StockMaintenanceQuery
          onValueChange={(v: any) => {
            setQueries({ ...queries, ...v })
          }}
        />
        <EditableList
          className={styles.supper}
          loading={pageLoadingRX}
          rowKey={(t: any) => t.id}
          page={{
            items: data,
            current: current,
            size: size,
            total: total,
          }}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number)
            dispatch(setCurrent(current))
          }}
          columns={StockMaintenanceColumns((id, t, rowdata) => {
            switch (t) {
              case 'edit':
                history.push({
                  pathname: '/additionDrug',
                  state: {
                    selectId: id,
                  },
                })
                break
              case 'remove':
                dispatch(removeStockMaintenanceAsync(id))
                  .then(unwrapResult)
                  .then(() => {
                    dispatch(
                      getStockMaintenanceList({
                        ...queries,
                        current: current,
                        size,
                      })
                    )
                  })
                break
              case 'completed':
                dispatch(maintenanceCompletedAsync(id))
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: '养护完成',
                      duration: 3,
                    })
                    dispatch(
                      getStockMaintenanceList({
                        ...queries,
                        current: current,
                        size,
                      })
                    )
                  })
                break
              case 'print':
                dispatch(
                  getAddtionDrugListAsync({
                    current: 1,
                    size: 1000,
                    tenantMaintainId: id,
                  })
                )
                  .then(unwrapResult)
                  .then((res: any) => {
                    setPrintData(res)
                    printRef.current?.print()
                  })
                break
              default:
                break
            }
          }, size * (current - 1))}
          rowSelection={{
            // type: selectionType,
            ...rowSelection,
          }}
          onRow={(records: any) => ({
            onDoubleClick: () => {
              history.push({
                pathname: '/additionDrug',
                state: {
                  selectId: records.id,
                },
              })
            },
          })}
        />
      </div>
      <PrintFrame
        ref={printRef}
        title={local?.tenantName}
        subTitle='养护检查记录'
      >
        <EditableList
          bordered
          pagination={false}
          className={styles.supper}
          rowKey={(t: any) => t.id}
          dataSource={printData.maintainItems}
          columns={PrintAdditionDrugsColumns(size * (current - 1))}
        ></EditableList>
        <Row style={{ lineHeight: '32px', marginTop: '10px' }}>
          <Col span={24} style={{ textAlign: 'right' }}>
            养护人员：{printData.maintain?.mainUserName}
          </Col>
        </Row>
      </PrintFrame>
      <OneKeyStockMaintenanceModal
        visible={isModalVisible}
        onOK={() => {
          setIsModalVisible(false)
          dispatch(
            getStockMaintenanceList({
              ...queries,
              current: current,
              size,
            })
          )
        }}
        onCancel={() => {
          setIsModalVisible(false)
            dispatch(
              getStockMaintenanceList({
                ...queries,
                current: current,
                size,
              })
            )
        }}
      ></OneKeyStockMaintenanceModal>
    </>
  )
}
