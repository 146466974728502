/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-13 11:49:18
 * @LastEditors: sj
 * @LastEditTime: 2022-12-19 10:46:51
 */
import { Button, Col, Tabs } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { TabBar } from '../../compnents/widgets/TabBar'
import { BeforeOperatingManagePage } from './appeal/beforeOperatingManagePage'
import { OperatingManagePage } from './operating/operatingManagePage'
import styles from '../followUp/followUpPage.module.css'
const { TabPane } = Tabs
export const BeforeOperatingManage = (): ReactElement => {
    const [value, setValue] = useState<any>();


    return (
        <>
            <Col className={styles.container}>
                <TabBar style={{ marginBottom: "20px" }}
                    onChange={(e) => {
                        setValue(e)
                    }}>
                    <TabPane key="0" tab="申诉列表">
                        < BeforeOperatingManagePage value={value} />
                    </TabPane>
                    <TabPane key="1" tab="手术列表">
                        <OperatingManagePage value={value} />
                    </TabPane>
                </TabBar>
            </Col>
        </>
    )
}
