/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-15 10:06:02
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-20 14:48:25
 */
import { Form, Row, Col, Input, Button } from "antd";
import React, { useState } from "react";
import { FieldData } from "../../models/fielddata";
import { SearchOutlined } from "@ant-design/icons";
import { StockfloorParams } from "../../services/stockfloor";

export const PermissionsfromQuery = (props: {
  onValueChange: (queries: StockfloorParams) => void;
}) => {
  const [form] = Form.useForm();

  return ( 
    <Form
      form={form}
      name="control-hooks"
      onFieldsChange={(_, fs) => {
        props.onValueChange(form.getFieldsValue());
      }}
    >
      <Row gutter={11}>
        <Col span={7}>
          <Form.Item name="userName">
            <Input
              size="middle"
              placeholder="请输入姓名"
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>

        <Col span={2} offset={15}>
          <Button type="primary" style={{ float: "right" }}>
            查询
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
