/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-09-05 14:22:52
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-12 13:42:29
 */
import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space } from 'antd'
import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'

export const Query = (props: {
  onValueChange: (params: any) => void
  addDrug: () => void
  selectedTab?: any
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      onFinish={(values) => {
        props.onValueChange({
          ...values,
        })
      }}
    >
      <Row style={{ marginTop: 10, marginBottom: 20 }} justify='space-between'>
        <Space>
          <Form.Item noStyle name='drugName'>
            <Input
              autoComplete='off'
              prefix={<SearchOutlined />}
              placeholder='请输入商品名称'
              style={{ width: '14rem' }}
              allowClear
              onChange={() => {
                form.submit()
              }}
            />
          </Form.Item>
          <Form.Item name='type' noStyle initialValue={0}>
            <Select
              placeholder=''
              style={{ width: '8rem' }}
              allowClear
              onChange={() => {
                form.submit()
              }}
            >
              <Select.Option value={0}>全部</Select.Option>
              {/* <Select.Option value={1}>两种药品禁忌</Select.Option> */}
              <Select.Option value={2}>超年龄禁忌</Select.Option>
              {/* <Select.Option value={3}>超剂量禁忌</Select.Option> */}
              <Select.Option value={4}>性别禁忌</Select.Option>
            </Select>
          </Form.Item>
          <Button
            type='primary'
            onClick={() => {
              form.submit()
            }}
          >
            查询
          </Button>
        </Space>
        <Space>
          <Button
            type='primary'
            onClick={() => {
              props?.addDrug()
            }}
          >
            新增
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
