import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Row, TableColumnType } from "antd";
import moment from "moment";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../../app/store";
import { EditableList } from "../../../compnents/list/EditableList";
import { NavBar } from "../../../compnents/nav/NavBar";
import { CarryOverItem } from "../../../models/carryover";
import { ThemeContext } from "../../../theme/ThemeContext";
import { useQuery } from "../../../utils/HttpUtils";
import { getCarryOverItemList } from "../carryOverSlice";
import ReactToPrint from "react-to-print";
import { PrintOutlined } from "../../../compnents/icons/Icons";

// 类型:1

function getEntryName(category: number): string {
  switch (category) {
    case 1:
      return "上月结存";
    case 2:
      return "购入入库";
    case 3:
      return "购入退库";
    case 4:
      return "门诊发药";
    case 5:
      return "门诊退药";
    case 6:
      return "其他入库";
    case 7:
      return "其他出库";
    case 8:
      return "冲正";
    case 9:
      return "库存盘点";
    case 10:
      return "中药清斗";
    case 11:
      return "调价";
    case 12:
      return "本月结存";
  }
  return "";
}

export const CarryOverItemList = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const theme = useContext(ThemeContext);

  const query = useQuery();

  const ref = useRef(null);

  const id = parseInt(query.get("id") as string);

  const [items, setItems] = useState<CarryOverItem[]>([]);

  const [start, setStart] = useState("");

  const [end, setEnd] = useState("");

  useEffect(() => {
    dispatch(getCarryOverItemList({ tenantCarryOverId: id }))
      .then(unwrapResult)
      .then((items) => setItems(items))
      .catch(() => {
        // do nothing.
      });
  }, [id]);

  useEffect(() => {
    if (items.length > 0) {
      const format = "YYYY年MM月DD日";
      const sm = moment(items[0].startTime);
      const em = moment(items[1].endTime);
      setStart(sm.format(format));
      setEnd(em.format(format));
    } else {
      setStart("");
      setEnd("");
    }
  }, [items]);

  return (
    <Col
      style={{
        height: "calc(100% - 20px)",
        margin: "10px 20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavBar
        where={["库房业务", "统计汇总"]}
        backtrace={{
          name: "库房结转",
          path: "/carryover/detail",
          query: `id=${id}`,
          routeName: "库房工作站",
        }}
        buttons={
          <ReactToPrint
            trigger={() => (
              <Button
                size="small"
                type="text"
                style={{ display: 'flex', height: 30, lineHeight: '25px', paddingTop: 2 }}
                icon={<PrintOutlined style={{ color: theme.tc2 }} />}
              >
                打印
              </Button>
            )}
            content={() => ref.current}
          />
        }
      />

      <Col
        ref={ref}
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: theme.br,
          marginTop: 10,
          paddingBottom: 20,
          overflow: "auto",
        }}
      >
        <div
          style={{
            textAlign: "center",
            color: theme.tc1,
            fontWeight: "bold",
            fontSize: "1.5rem",
            marginBottom: 10,
            marginTop: 20,
          }}
        >
          药房药品收支月报表
        </div>
        <Row justify="space-between">
          <div
            style={{
              fontSize: "1rem",
              color: theme.tc1,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            库房名称：
            <span style={{ color: theme.tc2 }}>
              {items.length > 0 ? items[0].storehouseName : ""}
            </span>
          </div>
          <div
            style={{
              fontSize: "1rem",
              color: theme.tc1,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            结算周期：
            <span style={{ color: theme.tc2 }}>
              {start ? `${start}至${end}` : ""}
            </span>
          </div>
        </Row>
        <EditableList
          bordered
          dataSource={items}
          columns={[
            {
              title: "药品名称",
              key: "entry",
              align: "center",
              render: (_, r) => ({
                props: {
                  style: {
                    fontWeight: "bold",
                  },
                },
                children: getEntryName(r.category),
              }),
            },
            {
              title: "西药",
              align: "center",
              children: [
                {
                  title: "售价",
                  key: "westernSellingPrice",
                  align: "right",
                  render: (_, r) => ({
                    props: {
                      style: {
                        textAlign: "right",
                      },
                    },
                    children: r.westernSellingPrice?.toFixed(2),
                  }),
                },
                {
                  title: "进价计",
                  key: "westernPrice",
                  align: "right",
                  render: (_, r) => ({
                    props: {
                      style: {
                        textAlign: "right",
                      },
                    },
                    children: r.westernPrice?.toFixed(2),
                  }),
                },
              ] as TableColumnType<CarryOverItem>[],
            } as TableColumnType<CarryOverItem>,
            {
              title: "中成药",
              align: "center",
              children: [
                {
                  title: "售价",
                  key: "chinesePatentSellingPrice",
                  align: "right",
                  render: (_, r) => ({
                    props: {
                      style: {
                        textAlign: "right",
                      },
                    },
                    children: r.chinesePatentSellingPrice?.toFixed(2),
                  }),
                },
                {
                  title: "进价计",
                  key: "chinesePatentPrice",
                  align: "right",
                  render: (_, r) => ({
                    props: {
                      style: {
                        textAlign: "right",
                      },
                    },
                    children: r.chinesePatentPrice?.toFixed(2),
                  }),
                },
              ] as TableColumnType<CarryOverItem>[],
            } as TableColumnType<CarryOverItem>,
            {
              title: "中药",
              align: "center",
              children: [
                {
                  title: "售价",
                  key: "chineseSellingPrice",
                  align: "right",
                  render: (_, r) => ({
                    props: {
                      style: {
                        textAlign: "right",
                      },
                    },
                    children: r.chineseSellingPrice?.toFixed(2),
                  }),
                },
                {
                  title: "进价计",
                  key: "chinesePrice",
                  align: "right",
                  render: (_, r) => ({
                    props: {
                      style: {
                        textAlign: "right",
                      },
                    },
                    children: r.chinesePrice?.toFixed(2),
                  }),
                },
              ] as TableColumnType<CarryOverItem>[],
            } as TableColumnType<CarryOverItem>,
            {
              title: "器械",
              align: "center",
              children: [
                {
                  title: "售价",
                  key: "instrumentSellingPrice",
                  align: "right",
                  render: (_, r) => ({
                    props: {
                      style: {
                        textAlign: "right",
                      },
                    },
                    children: r.instrumentSellingPrice?.toFixed(2),
                  }),
                },
                {
                  title: "进价计",
                  key: "instrumentPrice",
                  align: "right",
                  render: (_, r) => ({
                    props: {
                      style: {
                        textAlign: "right",
                      },
                    },
                    children: r.instrumentPrice?.toFixed(2),
                  }),
                },
              ] as TableColumnType<CarryOverItem>[],
            } as TableColumnType<CarryOverItem>,
            {
              title: "其他",
              align: "center",
              children: [
                {
                  title: "售价",
                  key: "restSellingPrice",
                  align: "right",
                  render: (_, r) => ({
                    props: {
                      style: {
                        textAlign: "right",
                      },
                    },
                    children: r.restSellingPrice?.toFixed(2),
                  }),
                },
                {
                  title: "进价计",
                  key: "restPrice",
                  align: "right",
                  render: (_, r) => ({
                    props: {
                      style: {
                        textAlign: "right",
                      },
                    },
                    children: r.restPrice?.toFixed(2),
                  }),
                },
              ] as TableColumnType<CarryOverItem>[],
            } as TableColumnType<CarryOverItem>,
            {
              title: "合计进价",
              key: "totalPrice",
              align: "right",
              render: (_, r) => ({
                props: {
                  style: {
                    textAlign: "right",
                  },
                },
                children: r.totalPrice?.toFixed(2),
              }),
            },
            {
              title: "合计售价",
              key: "totalSellingPrice",
              align: "right",
              render: (_, r) => ({
                props: {
                  style: {
                    textAlign: "right",
                  },
                },
                children: r.totalSellingPrice?.toFixed(2),
              }),
            },
          ]}
          style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
        />
      </Col>
    </Col>
  );
};
