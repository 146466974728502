import _ from "lodash";
import { FieldData } from "../models/fielddata";

export function isSameName(data: FieldData, name: string): boolean {
    if (!data.name) {
        return false;
    }
    if (typeof data.name === "string") {
        return data.name === name;
    }
    if (Array.isArray(data.name)) {
        return _.includes(data.name, name);
    }
    return false;
}