import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, ApplicationType } from "../../app/applicationSlice";
import { RootState, RootThunk } from "../../app/store";
import { constructMenuLayers, fromJson, Menu } from "../../models/menu";
import {
  getAvailableMenuList as apiGetMenuList,
  getGrantedMenuList as apiGetGrantedMenuList,
  getTenantAvailableMenuList,
  getUserGrantedMenuList,
  grantMenus as apiGrantMenus,
  grantUserMenus,
} from '../../services/menu'

interface GrantState {
  menus: Menu[]
  granted: string[]
  imageBast: string
}

const initialState: GrantState = {
  menus: [],
  granted: [],
  imageBast: '',
}

const grantSlice = createSlice({
  name: "grant",
  initialState,
  reducers: {
    setMenus: (state, action: PayloadAction<Menu[]>) => {
      state.menus = action.payload;
    },
    setGrantedMenus: (state, action: PayloadAction<string[]>) => {
      state.granted = action.payload;
    },
    clearMenus: (state) => {
      state.menus = [];
      state.granted = [];
    },
  },
});

const { setMenus, setGrantedMenus } = grantSlice.actions;

export const { clearMenus } = grantSlice.actions;

export function getMenuList(
  type: ApplicationType,
  tenantId?: string
): RootThunk {
  return api(
    type === ApplicationType.Management
      ? apiGetMenuList(tenantId)
      : getTenantAvailableMenuList(),
    (data: Record<string, never>, disptach) => {
      const menus = constructMenuLayers(data.records);
      disptach(setMenus(menus));
    }
  );
}

export function getGrantedMenuList(
  type: ApplicationType,
  tenantId?: string,
  userId?: string
): RootThunk {
  return api(
    type === ApplicationType.Management
      ? apiGetGrantedMenuList(tenantId as string, userId)
      : getUserGrantedMenuList(userId),
    (data: Record<string, never>, disptach) => {
      const menus = _.chain(data.records)
        .map((m) => fromJson(m).id)
        .value();
      disptach(setGrantedMenus(menus));
    }
  );
}



export function grantMenus(
  type: ApplicationType,
  tenantId: string | undefined,
  userId: string | undefined,
  menuIds: string[]
): RootThunk {
  return api(
    type === ApplicationType.Management
      ? apiGrantMenus(tenantId as string, userId, menuIds)
      : grantUserMenus(userId as string, menuIds),
    (_, dispatch) => {
      dispatch(getGrantedMenuList(type, tenantId, userId));
    }
  );
}

export const selectMenus = (state: RootState): Menu[] => state.grant.menus;

export const selectGrantedMenus = (state: RootState): string[] =>
  state.grant.granted;

export default grantSlice.reducer;
