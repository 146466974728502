/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-17 10:47:44
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-18 17:08:01
 */
import moment from 'moment'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnlyOne,
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleText,
} from './datetime'

export function PurchaseAcceptancePrintBody(data: any) {
  const tenantName = data?.tenantName
  const dataList = data?.dataList
  let dataStr = ''

  for (let j = 0; j < dataList?.length; j++) {
    const productDate = dataList[j].productDate
      ? moment(dataList[j].productDate)?.format(DateTimeFormatSimpleOnly)
      : '-'
    const deadline = dataList[j].deadline
      ? moment(dataList[j].deadline)?.format(DateTimeFormatSimpleOnly)
      : '-'
    const creatTime = dataList[j].creatTime
      ? moment(dataList[j].creatTime)?.format(DateTimeFormatSimpleOnly)
      : '-'

    dataStr +=
      '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
      '<div style="width: 10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].arrivalOrderNumber +
      '</div>' +
      '<div style="width:15%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      '<div style="text-align: left;" >' +
      dataList[j].name +
      '</div>' +
      '<div style="text-align: left;" >' +
      dataList[j].productInfo +
      '</div>' +
      '</div>' +
      '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].supplierName +
      '</div>' +
      '<div style="width:6%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].batchNo +
      '</div>' +
      '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      productDate +
      '</div>' +
      '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      deadline +
      '</div>' +
      '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].totalQuantity +
      '</div>' +
      '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].qualifiedQuantity +
      '</div>' +
      '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].defectiveQuantity +
      '</div>' +
      '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].createUser +
      ' </div>' +
      '<div style="width:8%;display: table-cell;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      creatTime +
      ' </div>' +
      '</div>'
  }
  const contents =
    '<div style="page-break-after:always;page-break-inside:always;page-break-before:always;">' +
    '    <h3 style="text-align: center;margin-bottom: 0px">' +
    '采购验收记录' +
    '</h3>' +
    '<h4 style="text-align: center;margin-bottom: 5px" >' +
    tenantName +
    '</h4>' +
    '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-top: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">到货单号' +
    '</div>' +
    '<div style="width:15%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">产品信息' +
    '</div>' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">供应商' +
    '</div>' +
    '<div style="width:6%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">批号' +
    '</div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">生产日期' +
    '</div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">有效期至' +
    '</div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">到货数量' +
    '</div>' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">验收合格数量' +
    '</div>' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">验收不合格数量' +
    '</div>' +
    '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">验收人' +
    ' </div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">验收日期' +
    ' </div>' +
    '</div>' +
    dataStr +
    '<div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size:13px;margin-top:10px">' +
    '</div>' +
    '</div>'
  return contents
}

export function PrintBody(data: any) {
  const tenantName = data?.tenantName
  const dataList = data?.dataList
  const detail = data?.detail
  let dataStr = ''

  for (let j = 0; j < dataList?.length; j++) {
    const productDate = dataList[j].productDate
      ? moment(dataList[j].productDate)?.format(DateTimeFormatSimpleOnly)
      : '-'
    const deadline = dataList[j].deadline
      ? moment(dataList[j].deadline)?.format(DateTimeFormatSimpleOnly)
      : '-'

    dataStr +=
      '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
      '<div style="width: 10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].name +
      '</div>' +
      '<div style="width:6%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].doseCategory +
      '</div>' +
      '<div style="width:6%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].spec +
      '</div>' +
      '<div style="width:14%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].tenantSupplierName +
      '</div>' +
      '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].origin +
      '</div>' +
      '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].approvalNumber +
      '</div>' +
      '<div style="width:6%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].qualifiedQuantity +
      '</div>' +
      '<div style="width:6%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].accountUnit +
      '</div>' +
      '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].batchNo +
      ' </div>' +
      '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      productDate +
      ' </div>' +
      '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      deadline +
      ' </div>' +
      '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      '完好' +
      ' </div>' +
      '<div style="width:8%;display: table-cell;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      '质量合格,同意' +
      ' </div>' +
      '</div>'
  }
  const contents =
    '<div style="page-break-after:always;page-break-inside:always;page-break-before:always;">' +
    '    <h4 style="text-align: center;margin-bottom: 0px">' +
    tenantName +
    '</h4>' +
    '<h3 style="text-align: center;margin-bottom: 5px" >验收单</h3>' +
    '<div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size:13px">' +
    ' <div style="display: inline-block;width: 33%;text-align: left;">供应商:' +
    '<font style="font-weight: 300;">' +
    detail?.supplierName +
    '</font>' +
    '</div>' +
    ' <div style="display: inline-block;width: 33%;text-align: center;">验收人:' +
    '<font style="font-weight: 300;">' +
    '</font>' +
    '</div>' +
    '<div style="display: inline-block;width: 33%;text-align: right;">验收日期:' +
    '<font style="font-weight: 300;">' +
    moment(detail?.createTime)?.format(DateTimeFormatSimpleText) +
    '</font>' +
    '</div>' +
    '</div>' +
    '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-top: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
    '<div style="width:10%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">名称' +
    '</div>' +
    '<div style="width:6%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">剂型' +
    '</div>' +
    '<div style="width:6%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">规格' +
    '</div>' +
    '<div style="width:14%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">生产厂家' +
    '</div>' +
    '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">产地' +
    '</div>' +
    '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">批准文号' +
    '</div>' +
    '<div style="width:6%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">数量' +
    '</div>' +
    '<div style="width:6%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">单位' +
    '</div>' +
    '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">批次号' +
    ' </div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">生产日期' +
    ' </div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">有效期' +
    ' </div>' +
    '<div style="width:7%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">质量状况' +
    ' </div>' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">验收结论' +
    ' </div>' +
    '</div>' +
    dataStr +
    '<div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size:13px;margin-top:10px">' +
    '<div style="display: inline-block;width:33%;text-align: left;">核对人:' +
    '</div>' +
    '<div style="display: inline-block;width: 33%;text-align: center;">制单人:' +
    detail?.userName +
    '</div>' +
    '<div style="display: inline-block;width: 33%;text-align: right;">制单日期: ' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</div>' +
    '</div>' +
    '</div>'
  return contents
}
