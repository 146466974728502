import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space } from 'antd'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInsuranceArray,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantCategory,
  selectTenantName,
  selectUserId,
  selectUserName,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { setInsurance } from '../../layouts/insuranceSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { PaymentListParams } from '../../services/payment'
import { selectDepartments } from '../payment/list/paymentSlice'

export const PaymentQuery = (props: {
  loading?: any
  selectedKeys?: any
  onValueChange: (params: PaymentListParams) => void
  massUpload: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const departments = useSelector(selectDepartments)

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const userId = useSelector(selectUserId)

  const tenantCategory = useSelector(selectTenantCategory)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const [awaitLoading, setAwaitLoading] = useState<any>(false)

  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)
    form.setFieldsValue({
      registrationTimeHead: head,
      registrationTimeTail: tail,
      yb4701UploadFlag:'0',
    })
    if (insuranceArray.length !== 0) {
      form.setFieldsValue({
        insuranceCode: insuranceArray[0].insuranceCode,
      })
      setInsuranceItem(insuranceArray[0])
    }
    form.submit()
  }, [insuranceArray])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  return (
    <Form form={form} onFinish={(values) => props.onValueChange(values)}>
      <Row style={{ marginTop: 10, marginBottom: 20 }} justify='space-between'>
        <Space>
          <Form.Item noStyle name='param'>
            <Input
              autoComplete='off'
              prefix={<SearchOutlined />}
              placeholder='患者姓名/手机号'
              style={{ width: '14rem' }}
              allowClear
              onChange={() => form.submit()}
            />
          </Form.Item>
          <Form.Item noStyle name='treatmentDepartmentId'>
            <Select
              style={{ width: '12rem' }}
              placeholder='查看范围-科室列表'
              allowClear
              onChange={() => form.submit()}
            >
              {departments.map((d) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='yidibz' noStyle>
            <Select
              placeholder='险种'
              onChange={form.submit}
              allowClear
              style={{ width: 120 }}
            >
              <Select.Option value={0}>本地</Select.Option>
              <Select.Option value={1}>省内异地</Select.Option>
              <Select.Option value={2}>省外异地</Select.Option>
              <Select.Option value={3}>省医保</Select.Option>
            </Select>
          </Form.Item>
          {insuranceArray && insuranceArray.length !== 0 && (
            <Form.Item name='insuranceCode' noStyle>
              <Select
                placeholder='险种类型'
                style={{ width: '8rem' }}
                onChange={(key) => {
                  const insuranceItem = insuranceArray.find(
                    (v) => v.insuranceCode === key
                  )
                  setInsuranceItem(insuranceItem)
                  form.submit()
                }}
              >
                {/* <Select.Option value={''}>全部</Select.Option> */}
                {/* <Select.Option value={'ZIFEI'}>自费</Select.Option> */}
                {insuranceArray.map((v) => (
                  <Select.Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <DateSelect
            style={{ width: '8.5rem' }}
            labelPrefix=''
            namePrefix='registrationTime'
            placeholder='时间范围-时间'
            initialValue={DateRangeType.Today}
            onChange={() => form.submit()}
          />
          <Form.Item name='yb4701UploadFlag' noStyle>
            <Select
              placeholder='上传状态'
              style={{ width: '8rem' }}
              allowClear
              // suffixIcon={<CaretDownFilled />}
              onChange={() => form.submit()}
            >
              <Select.Option value={''}>全部</Select.Option>
              <Select.Option value={'0'}>未上传</Select.Option>
              <Select.Option value={'1'}>已上传</Select.Option>
            </Select>
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            查询
          </Button>
        </Space>
        <Space>
          <Button
            loading={props?.loading || awaitLoading}
            disabled={props?.selectedKeys?.length ? false : true}
            type='primary'
            onClick={(v) => {
              setAwaitLoading(true)
              setTimeout(() => {
                setAwaitLoading(false)
              }, 5000)
              props?.massUpload()
            }}
          >
            批量上传
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
