/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-15 14:11:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-25 09:24:35
 */
/* eslint-disable react/display-name */
import { Button, Popconfirm, Space, TableColumnType } from 'antd'
import React, { ReactElement } from 'react'
import {getAccountStatus, getInvoiceStatus, Payment} from '../../../models/payment'
import {useSelector} from "react-redux";
import {selectCredentials} from "../../../app/applicationSlice";

type Action =
  | 'downLoad'
  | 'result'
  | 'apply'
  | 'detail'
  | 'reason'
  | 'again'
  | 'detailDel'
  | 'detailCancel'
  | 'detailNo'
  | 'print'
  | 'checkflush'

export const Columns = (
  // 开票记录
  startIndex: number,
  onAction: (action: Action, t: any) => void
): TableColumnType<any>[] => {
    const credentials = useSelector(selectCredentials)
    const colums: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '5rem',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '患者姓名',
      dataIndex: 'patientName',
      align: 'center',
      width: '8rem',
      ellipsis: true,
    },
    {
      title: '病历号',
      key: 'patientId',
      align: 'center',
      dataIndex: 'patientId',
      width: '8rem',
      ellipsis: true,
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '10em',
      align: 'center',
      ellipsis: true,
      render: (_, t) => {
        return t.insuranceCode === 'ZIFEI' || !t.insuranceCode || !t
          ? '自费'
          : '智慧医保'
      },
    },
    {
      title: '票据类型',
      key: 'openType',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => (t.invoiceType === 1 ? '电子发票' : '普通发票'),
    },
    {
      title: '发票代码',
      dataIndex: 'invoiceCode',
      align: 'center',
      width: '8rem',
      ellipsis: true,
    },
    {
      title: '发票号码',
      dataIndex: 'invoiceNum',
      align: 'center',
      width: '8rem',
      ellipsis: true,
    },
    {
      title: '开票金额',
      key: 'patientAge',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => t.invoiceAmount,
    },
    {
      title: '开票时间',
      key: 'disease',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => t.createTime,
    },
    // {
    //   title: '接诊科室',
    //   key: 'treatmentDepartmentName',
    //   align: 'center',
    //   width: Dimen.Department,
    //   render: (_, { treatment }) => treatment.treatmentDepartmentName,
    // },
    {
      title: '票据状态',
      key: 'einvoiceStatus',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => getInvoiceStatus(t.einvoiceStatus),
    },
    {
      title: '对账状态',
      key: 'accountFlag',
      align: 'center',
      width: '8rem',
      ellipsis: true,

      render: (_, t) => getAccountStatus(t.accountFlag),
    },
    {
      title: '操作',
      key: 'action',
      width: '20rem',
      align: 'center',
      render: (_, t) => {
        // supplierId 3 江苏航信
        // supplierId 4 浙江财政票据
        // supplierId 1 诺诺发票
        // supplierId 2 诺诺财政票据
        // supplierId 5 浙江数电票
        const status = Number(t.einvoiceStatus || 0)
        return t.invoiceType == 1 ? (
          t?.supplierId == 3 ? (
            <Space>
              {status == 0 && (
                <a onClick={() => onAction('result', t)}>查询开票结果</a>
              )}
              {status == 8 && (
                <a onClick={() => onAction('checkflush', t)}>查询红冲结果</a>
              )}
              {(status == 1 || status == 9) && (
                <>
                  <a onClick={() => onAction('downLoad', t)}>下载</a>
                </>
              )}
              {status == 1 && t.cancelFlag !== 1 && (
                <Popconfirm
                  title='确认进行此操作？'
                  onConfirm={() => onAction('apply', t)}
                  okText='确认'
                  cancelText='取消'
                >
                  <a href='javascript:;'>红冲申请</a>
                </Popconfirm>
              )}
            </Space>
          ) : (t?.supplierId == 4 ||t?.supplierId == 11||t?.supplierId == 13) ? (
            <Space>
              {status == 0 && (
                <a onClick={() => onAction('result', t)}>查询开票结果</a>
              )}
              {status == 8 && (
                <a onClick={() => onAction('checkflush', t)}>查询红冲结果</a>
              )}
              {status == 1 && t.cancelFlag !== 1 && (
                <Popconfirm
                  title='确认进行此操作？'
                  onConfirm={() => onAction('apply', t)}
                  okText='确认'
                  cancelText='取消'
                >
                  <a href='javascript:;'>红冲申请</a>
                </Popconfirm>
              )}
            </Space>
          ) : t?.supplierId == 5 ? (
            <Space>
              {status == 0 && (
                <a onClick={() => onAction('result', t)}>查询开票结果</a>
              )}
              {status == 8 && (
                <a onClick={() => onAction('checkflush', t)}>查询红冲结果</a>
              )}
              {status == 1 && t.cancelFlag !== 1 && (
                <Popconfirm
                  title='确认进行此操作？'
                  onConfirm={() => onAction('apply', t)}
                  okText='确认'
                  cancelText='取消'
                >
                  <a href='javascript:;'>红冲申请</a>
                </Popconfirm>
              )}
              {(status == 1 || status == 9) && (
                <>
                  <a onClick={() => onAction('downLoad', t)}>发票预览</a>
                </>
              )}
                {status == 1 && (
                    <>
                        <a onClick={() => onAction('print', t)}>告知单打印</a>
                    </>
                )}
            </Space>
          ): t?.supplierId == 12 ? (
              <Space>
                  {status == 0 && (
                      <a onClick={() => onAction('result', t)}>查询开票结果</a>
                  )}
                  {status == 8 && (
                      <a onClick={() => onAction('checkflush', t)}>查询红冲结果</a>
                  )}
                  {status == 1 && t.cancelFlag !== 1 && (
                      <Popconfirm
                          title='确认进行此操作？'
                          onConfirm={() => onAction('apply', t)}
                          okText='确认'
                          cancelText='取消'
                      >
                          <a href='javascript:;'>红冲申请</a>
                      </Popconfirm>
                  )}
                  {(status == 1 || status == 9) && (
                      <>
                          <a onClick={() => onAction('downLoad', t)}>发票预览</a>
                      </>
                  )}
              </Space>
          ) : (
            <Space>
              {status == 1 && (
                <a onClick={() => onAction('print', t)}>告知单打印</a>
              )}
              {(status == 0 ||
                status == 8 ||
                status == -1 ||
                status == -9 ||
                status == 12) && (
                <a onClick={() => onAction('result', t)}>查询开票结果</a>
              )}
              {(status == 1 || status == 9) && (
                <>
                  <a onClick={() => onAction('detail', t)}>发票预览</a>
                  <a onClick={() => onAction('again', t)}>重新发送</a>
                </>
              )}
              {(status == -1 || status == -9) && (
                <a onClick={() => onAction('reason', t)}>查看失败原因</a>
              )}
              {/* {(status == 1 && t.cancelFlag !== 1) && <a onClick={() => onAction('apply', t)}>红冲申请</a>} */}
              {status == 1 && t.cancelFlag !== 1 && (
                <Popconfirm
                  title='确认进行此操作？'
                  onConfirm={() => onAction('apply', t)}
                  okText='确认'
                  cancelText='取消'
                >
                  <a href='javascript:;'>红冲申请</a>
                </Popconfirm>
              )}
              {status == 11 && (
                <a onClick={() => onAction('checkflush', t)}>查询红冲结果</a>
              )}
            </Space>
          )
        ) : (
          <Space>
            {status == 5 ? (
              <Popconfirm
                title='确认进行此操作？'
                onConfirm={() => onAction('detailCancel', t)}
                okText='确认'
                cancelText='取消'
              >
                <a href='javascript:;'>取消作废</a>
              </Popconfirm>
            ) : (
              <Popconfirm
                title='确认进行此操作？'
                onConfirm={() => onAction('detailNo', t)}
                okText='确认'
                cancelText='取消'
              >
                <a href='javascript:;'>发票作废</a>
              </Popconfirm>
            )}

            <Popconfirm
              title='确认进行此操作？'
              onConfirm={() => onAction('detailDel', t)}
              okText='确认'
              cancelText='取消'
            >
              <a href='javascript:;'>删除发票</a>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]
  return credentials?.dzfpSupplierId == "4"
      ? colums
      : colums.filter(
          (v) =>
              v.key != 'accountFlag'
      )
}
