export enum TenantCategory {
  All = 0,
  Pharmacy = 1,
  //历史遗留问题 Clinic 为诊所
  Clinic = 2,
  // 医院
  hospital = 3,
  // 妇幼保健院
  maternityHealthCenter = 4,
  // 社区卫生服务中心
  communityHealthServiceCenter = 5,
  // 卫生院
  surgery = 6,
  // 疗养院
  nursingHome = 7,
  // 门诊部
  outpatientDepartment = 8,
  // 村卫生室
  villageHealthClinic = 9,
  // 急救中心（站）
  emergencyCenter = 10,
  // 临床检验中心
  clinicalLaboratory = 11,
  // 专科疾病防治机构
  specializedDiseasePreventionAndTreatmentInstitution = 12,
  // 护理院（站）
  nursingStation = 13,
  // 中心（实验室）
  center = 14,
  // 其他
  other = 15,
}

export const TenantCategories = [
  TenantCategory.All,
  TenantCategory.Pharmacy,
  TenantCategory.Clinic,
  TenantCategory.hospital,
  TenantCategory.maternityHealthCenter,
  TenantCategory.communityHealthServiceCenter,
  TenantCategory.surgery,
  TenantCategory.nursingHome,
  TenantCategory.outpatientDepartment,
  TenantCategory.villageHealthClinic,
  TenantCategory.emergencyCenter,
  TenantCategory.clinicalLaboratory,
  TenantCategory.specializedDiseasePreventionAndTreatmentInstitution,
  TenantCategory.center,
  TenantCategory.other,
];


export function getTenantCategoryName(category: TenantCategory): string {
  switch (category) {
    case TenantCategory.Pharmacy:
      return "药店";
    case TenantCategory.Clinic:
      return "诊所";
    case TenantCategory.hospital:
      return "医院";
    case TenantCategory.maternityHealthCenter:
      return "妇幼保健院";
    case TenantCategory.communityHealthServiceCenter:
      return "社区卫生服务中心";
    case TenantCategory.surgery:
      return "卫生院";
    case TenantCategory.nursingHome:
      return "疗养院";
    case TenantCategory.outpatientDepartment:
      return "门诊部";
    case TenantCategory.villageHealthClinic:
      return "村卫生室";
    case TenantCategory.emergencyCenter:
      return "急救中心（站）";
    case TenantCategory.clinicalLaboratory:
      return "临床检验中心";
    case TenantCategory.specializedDiseasePreventionAndTreatmentInstitution:
      return "专科疾病防治机构";
    case TenantCategory.nursingStation:
      return "护理院（站）";
    case TenantCategory.center:
      return "中心（实验室）";
    case TenantCategory.other:
      return "其他";
    default:
      return "全部";
  }
}

export enum TenantLevel {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
  Level4 = 4,
  Level5 = 5,
  Level6 = 6,
}

export const TenantLevels = [
  TenantLevel.Level1,
  TenantLevel.Level2,
  TenantLevel.Level3,
  TenantLevel.Level4,
  TenantLevel.Level5,
  TenantLevel.Level6,
];

export const TenantLevelNames = [
  "一级",
  "二级",
  "三级",
  "四级",
  "五级",
  "未定级",
];

export function getTenantLevelName(level: TenantLevel): string {
  if (level <= 0 || level > TenantLevels.length) {
    return "";
  }
  return TenantLevelNames[level - 1];
}

export const HospitalLevels = [
  TenantLevel.Level1,
  TenantLevel.Level2,
  TenantLevel.Level3,
  TenantLevel.Level6,
];

export interface Tenant {
  id: string;
  name: string;
  shortName: string;
  status: number;
  tenantCategory: TenantCategory;
  tenantPhone: string;
  hospitalCode: string;
  legalPerson: string;
  licenceCategory: string;
  licenceDeadline: string;
  licenceNumber: string;
  managerCompany: string;
  medicalOrganCode: string;
  medicalOrganLevel: number;
  organCode: number;
  organLevel: TenantLevel;
  contactName: string;
  phone: string;
  postcode: string;
  address: string;
  createTime: string;
  startTime: string;
  endTime: string;
  registeredFund: string;
  nationUniqueCode?: string;
  lastLoginTime?: string;
  provinceName?: string;
  cityName?: string;
  countyName?: string;
  provinceCode: string;
  cityCode: string;
  countyCode: string;
}

export function fromJson(json: any): Tenant {
  return {
    id: json.id?.toString() || "",
    name: json.name,
    shortName: json.shortName,
    status: json.status,
    tenantCategory: json.tenantCategory,
    tenantPhone: json.tenantPhone,
    hospitalCode: json.hospitalCode,
    legalPerson: json.legalPerson,
    licenceCategory: json.licenceCategory,
    licenceDeadline: json.licenceDeadline,
    licenceNumber: json.licenceNumber,
    managerCompany: json.managerCompany,
    medicalOrganCode: json.medicalOrganCode,
    medicalOrganLevel: json.medicalOrganLevel,
    organCode: json.organCode,
    organLevel: json.organLevel,
    contactName: json.contactName,
    phone: json.phone,
    postcode: json.postCode,
    address: json.address,
    createTime: json.createTime,
    startTime: json.startTime,
    endTime: json.endTime,
    registeredFund: json.registeredFund == -1 ? "" : json.registeredFund,
    nationUniqueCode: json.nationUniqueCode,
    provinceName: json.provinceName,
    cityName: json.cityName,
    countyName: json.countyName,
    provinceCode: json.provinceCode,
    cityCode: json.cityCode,
    countyCode: json.countyCode,
    lastLoginTime:json.lastLoginTime
  };
}

export function toJson(tenant: Tenant): any {
  return {
    id: tenant.id,
    name: tenant.name,
    shortName: tenant.shortName,
    status: tenant.status,
    tenantCategory: tenant.tenantCategory,
    hospitalCode: tenant.hospitalCode,
    legalPerson: tenant.legalPerson,
    tenantPhone: tenant.tenantPhone,
    licenceCategory: tenant.licenceCategory,
    licenceDeadline: tenant.licenceDeadline,
    licenceNumber: tenant.licenceNumber,
    managerCompany: tenant.managerCompany,
    medicalOrganCode: tenant.medicalOrganCode,
    medicalOrganLevel: tenant.medicalOrganLevel,
    organCode: tenant.organCode,
    organLevel: tenant.organLevel,
    contactName: tenant.contactName,
    phone: tenant.phone,
    postCode: tenant.postcode,
    address: tenant.address,
    createTime: tenant.createTime,
    startTime: tenant.startTime,
    endTime: tenant.endTime,
    registeredFund: tenant.registeredFund == "-1" ? "" : tenant.registeredFund,
    nationUniqueCode: tenant.nationUniqueCode,
    provinceName: tenant.provinceName,
    cityName: tenant.cityName,
    countyName: tenant.countyName,
    provinceCode: tenant.provinceCode,
    cityCode: tenant.cityCode,
    countyCode: tenant.countyCode,
    lastLoginTime:tenant.lastLoginTime
  };
}