import { unwrapResult } from "@reduxjs/toolkit"
import { Col, Row } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { RootDispatch } from "../../app/store"
import { NavBar } from "../../compnents/nav/NavBar"
import { RiskAreaValue } from "../../models/previewTriageList"
import { getRiskArea } from "./listslice"
import styles from "./triage.module.css"

export const RiskArea = () => {
  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const pathName = location.state?.name

  const [data, setRiskArea] = useState<RiskAreaValue[]>([])

  const patientInfo: any = sessionStorage.getItem("patientInfo")

  const RiskItem = (child: any) => {
    const { item, key } = child
    return (
      <div key={key} className={styles.riskItem}>
        <p style={{ fontWeight: "bolder" }}>
          <span className={styles.area}>{item.province}</span>
          <span className={styles.area}>{item.city}</span>
          {item.addr === item.county ? (
            <></>
          ) : (
            <span className={styles.area}>{item.county}</span>
          )}
          <span
            className={styles.area}
            style={{
              color: item.grade === 2 ? "#f00" : "#E1CE72",
              fontWeight: 400,
              fontSize: "12px",
              padding: "2px 5px",
              border: "0.5px solid",
              borderColor: item.grade === 2 ? "#f00" : "#E1CE72",
            }}
          >
            {item.grade === 2 ? "高风险" : "中风险"}
          </span>
        </p>
        <p>
          <span
            style={{
              width: "80%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              verticalAlign: "middle",
            }}
          >
            {item.addr}
          </span>
        </p>
      </div>
    )
  }

  useEffect(() => {
    dispatch(
      getRiskArea({
        grade: 3,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setRiskArea(res)
      })
  }, [])

  return (
    <>
      <Col
        style={{
          margin: "10px 20px",
        }}
      >
        <NavBar
          where={["预检分诊", "中高风险地区"]}
          backtrace={{
            name: "预检分诊",
            path:
              pathName == "registration" || pathName == "patient"
                ? "/RegistrationsDetail"
                : "/register",
            state: {
              selectId: JSON.parse(patientInfo).id as string,
              name: location.state?.name,
            },
          }}
        />
      </Col>
      {data.length !== 0 && (
        <div className={styles.riskArea}>
          <div className={styles.riskheader}>
            <p>截至{data[0].updateTime}，全国疫情：</p>
            <p
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div className={styles.riskcard}>
                <div style={{ fontSize: "18px", textAlign: "center", flex: 1 }}>
                  <span style={{ color: "#f00" }}>{data[0].countHigh}</span>个
                </div>
                <div style={{ textAlign: "center", flex: 1 }}>高风险地区</div>
              </div>
              <div className={styles.riskcard}>
                <div style={{ fontSize: "18px", textAlign: "center", flex: 1 }}>
                  <span style={{ color: "#E1CE72" }}>
                    {data[0].countMiddle}
                  </span>
                  个
                </div>
                <div style={{ textAlign: "center", flex: 1 }}>中风险地区</div>
              </div>
            </p>
            <p style={{ textAlign: "center" }}>
              <span style={{ fontWeight: "bolder" }}>
                注：其余未列出地区均为
              </span>
              <span style={{ fontWeight: "bolder", color: "#88BB62" }}>
                低风险
              </span>
            </p>
            <div>有关信息来自当地上报的疫情风险登记</div>
          </div>
          {/* <div className={styles.riskConnect}> */}
          <Row style={{ alignItems: "center", lineHeight: "36px" }}>
            <span
              style={{
                display: "inline-block",
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                background: "#f00",
                verticalAlign: "middle",
                marginRight: "10px",
              }}
            ></span>{" "}
            高风险地区
          </Row>
          {data
            .filter((v) => v.grade === 2)
            .map((item, index) => (
              <RiskItem item={item} key={index} />
            ))}
          <Row style={{ alignItems: "center", lineHeight: "36px" }}>
            <span
              style={{
                display: "inline-block",
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                background: "#E1CE72",
                verticalAlign: "middle",
                marginRight: "10px",
              }}
            ></span>
            中风险地区
          </Row>
          {data
            .filter((v) => v.grade === 1)
            .map((item, index) => (
              <RiskItem item={item} key={index} />
            ))}
          {/* </div> */}
        </div>
      )}
    </>
  )
}
