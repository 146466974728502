import { TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import { DateTimeFormatSimple } from '../../../models/datetime'
import { getPatientFlowName } from '../../../models/inspect'
import { getGenderName } from '../../../models/user'
import { getAge } from '../../../utils/StringUtils'
export const Columns = (startIndex: number): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      key: 'no',
      width: '70px',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '日期',
      dataIndex: 'createTime',
      align: 'center',
      key: 'createTime',
      width: '150px',
      render: (_, r) => {
        return moment(r.createTime).format(DateTimeFormatSimple)
      },
      sorter: (a, b) => moment(a.createTime).diff(moment(b.createTime)),
    },
    {
      title: '姓名',
      dataIndex: 'patientName',
      align: 'center',
      width: '100px',
      key: 'patientName',
      sorter: (a, b) => a.patientName.localeCompare(b.patientName),
    },
    {
      title: '性别',
      key: 'gender',
      width: '70px',
      align: 'center',
      render: (_: string, u: any) => getGenderName(u.patientSex),
      sorter: (a, b) => a.patientSex - b.patientSex,
    },
    {
      title: '年龄',
      dataIndex: 'patientAge',
      align: 'center',
      width: '70px',
      key: 'patientAge',
      render: (_, r) => getAge(r?.patientAge, r?.patientMonth),
      sorter: (a, b) => {
        const aMonths =
          (a.patientAge < 0 ? 0 : a.patientAge) * 12 +
          (a.patientMonth < 0 ? 0 : a.patientMonth)
        const bMonths =
          (b.patientAge < 0 ? 0 : b.patientAge) * 12 +
          (b.patientMonth < 0 ? 0 : b.patientMonth)
        return aMonths - bMonths
      },
    },
    {
      title: '职业',
      dataIndex: 'occupation',
      align: 'center',
      width: '100px',
      key: '',
    },
    {
      title: '联系电话',
      dataIndex: 'patientPhone',
      align: 'center',
      width: '120px',
      key: '',
    },
    {
      title: '现居住地址',
      dataIndex: 'address',
      align: 'center',
      width: '150px',
      ellipsis: true,
      key: '',
      render: function Element(_, r) {
        return (
          <p
            style={{
              textAlign: 'center',
              padding: '0 20px',
              margin: '0',
              fontSize: '14px',
              width: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {r.address}
          </p>
        )
      },
    },
    {
      title: '工作单位',
      dataIndex: 'companyName',
      align: 'center',
      width: '120px',
      ellipsis: true,
      key: '',
    },
    {
      title: '疾病诊断(初步诊断)',
      dataIndex: 'disease',
      align: 'center',
      width: '200px',
      ellipsis: true,
      key: '',
      render: function Element(_, r) {
        return (
          <p
            style={{
              textAlign: 'center',
              padding: '0 20px',
              margin: '0',
              fontSize: '14px',
              width: '200px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {r.disease}
          </p>
        )
      },
    },
    {
      title: '初复诊',
      key: 'receiveTag',
      align: 'center',
      width: '70px',
      render: (_, r) => (r.receiveTag ? '复诊' : '初诊'),
    },
    {
      title: '有没有新冠肺炎相关"十大"症状',
      dataIndex: 'symptom',
      align: 'center',
      width: '150px',
      key: '',
    },
    {
      title: '新冠肺炎流行病学史',
      dataIndex: 'combination',
      align: 'center',
      width: '200px',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <p
            style={{
              textAlign: 'center',
              padding: '0 20px',
              margin: '0',
              fontSize: '14px',
              width: '200px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {t.combination}
          </p>
        )
      },
    },
    {
      title: '信息报告情况',
      dataIndex: 'acceptDepartment',
      align: 'center',
      key: '',
      width: '150px',
      children: [
        {
          title: '(向哪个医疗机构疾病、疾控机构报告)',
          dataIndex: 'acceptDepartment',
          key: '',
          align: 'center',
          width: '150px',
        },
      ],
    } as TableColumnType<any>,
    {
      title: '患者流向',
      dataIndex: 'patientFlow',
      align: 'center',
      width: '120px',
      key: '',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <p
            style={{
              textAlign: 'center',
              padding: '0 20px',
              margin: '0',
              fontSize: '14px',
              width: '120px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {getPatientFlowName(t.patientFlow)}
          </p>
        )
      },
    },
  ]
}
