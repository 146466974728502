import { CaretDownFilled } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Col,
  Form,
  FormProps,
  Input,
  Radio,
  Row,
  Select,
  Cascader as Cascaded,
} from 'antd'
import moment from 'moment'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { getMaritalStatusName, MaritalStatus } from '../../../models/patient'
import {
  getSourceTypeName,
  SourceTypeCategories,
} from '../../../models/registration'
import { Gender, GenderOptions, getGenderName } from '../../../models/user'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getAddress } from '../../registration/register/registerSlice'

import styles from './PatientForm.module.css'

interface RegistrationsProps {
  registrations?: any
}

export const PatientForm = ({
  registrations,
  form,
  ...others
}: RegistrationsProps & FormProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const [idCardRules, setIdCardRules] = useState<any[]>([
    {
      pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
      message: '你的身份证格式不正确',
    },
    {
      max: 18,
      message: '最长18位!',
    },
  ])

  const getIdCardInfo = (idCard: string) => {
    if (idCard) {
      //先判断是不是中国大陆身份证
      if (
        (idCard.length != 15 && idCard.length != 16 && idCard.length != 18) ||
        isNaN(Number(idCard.substr(0, 6)))
      ) {
        setIdCardRules([
          {
            max: 18,
            message: '最长18位!',
          },
        ])
      } else {
        setIdCardRules([
          {
            pattern: /(^\d{15}$)|(^\d{16}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '身份证格式错误',
          },
          {
            max: 18,
            message: '最长18位!',
          },
        ])
      }
    }
  }

  const updateBirthday = () => {
    if (form) {
      const year = parseInt(form.getFieldValue('ageYear')) || 0
      const month = parseInt(form.getFieldValue('ageMonth')) || 0
      if (year !== 0 || month !== 0) {
        const months = year * 12 + month
        const birthday = moment()
          .subtract(months, 'months')
          .format('YYYY/MM/DD')
        form.setFieldsValue({ birthday })
      }
    }
  }

  const updateYearsAndMonths = () => {
    if (form) {
      const date = moment(form.getFieldValue('birthday'))
      if (!date.isValid()) {
        return
      }
      const duration = moment.duration(moment().diff(date))
      if (duration.years() < 0 || duration.months() < 0) {
        return
      }
      form.setFieldsValue({
        ageYear: duration.years(),
        ageMonth: duration.months() == 0 ? 1 : duration.months(),
      })
    }
  }

  const dispatch = useDispatch<RootDispatch>()

  const [allList, setAllList] = useState<any[]>() //省市数据

  useEffect(() => {
    //获取省市数据
    dispatch(getAddress())
      .then(unwrapResult)
      .then((res: any) => {
        setAllList(res)
      })
  }, [])

  return (
    <Form
      form={form}
      className={styles.form}
      autoComplete='off'
      colon={false}
      initialValues={{
        sex: Gender.Male,
      }}
      {...others}
    >
      <Row>
        <Col span={8} className={styles.fst}>
          <Form.Item
            name='name'
            label='患者姓名'
            rules={[{ required: true }, { max: 40, message: '最长40位' }]}
          >
            <Input
              disabled={
                registrations?.insuranceCode &&
                registrations?.insuranceCode != 'ZIFEI'
                  ? true
                  : false
              }
            />
          </Form.Item>
        </Col>
        <Col span={8} className={styles.snd}>
          <Form.Item name='sex' label='性别' rules={[{ required: true }]}>
            <Radio.Group
              disabled={
                registrations?.insuranceCode &&
                registrations?.insuranceCode != 'ZIFEI'
                  ? true
                  : false
              }
            >
              {GenderOptions.map((g) => (
                <Radio key={g} value={g}>
                  {getGenderName(g)}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label='年龄'
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
            }}
            required
            className={styles.snd}
          >
            <Row wrap={false} align='middle'>
              <Form.Item
                name='ageYear'
                noStyle
                dependencies={['ageMonth']}
                rules={[
                  {
                    max: 4,
                    pattern: /^\d*$/,
                    message: '请输入正确的年龄',
                  },

                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (!value && !getFieldValue('ageMonth')) {
                        return Promise.reject(new Error('请输入正确的年龄'))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  disabled={
                    registrations?.insuranceCode &&
                    registrations?.insuranceCode != 'ZIFEI'
                      ? true
                      : false
                  }
                  style={{ width: '5rem', textAlign: 'center' }}
                  onChange={updateBirthday}
                />
              </Form.Item>
              <div
                style={{
                  color: theme.tc2,
                  fontSize: '1.125rem',
                  margin: '0 10px',
                }}
              >
                岁
              </div>
              <Form.Item
                name='ageMonth'
                noStyle
                rules={[
                  {
                    max: 2,
                    pattern: /^(0?[0-9]|1[0-1])$/,
                    message: '请输入正确的月份',
                  },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (!value && !getFieldValue('ageYear')) {
                        return Promise.reject(new Error('请输入正确的月份'))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  disabled={
                    registrations?.insuranceCode &&
                    registrations?.insuranceCode != 'ZIFEI'
                      ? true
                      : false
                  }
                  style={{ width: '5rem', textAlign: 'center' }}
                  onChange={updateBirthday}
                />
              </Form.Item>
              <div
                style={{
                  color: theme.tc2,
                  fontSize: '1.125rem',
                  margin: '0 10px',
                }}
              >
                月
              </div>
              <Form.Item name='birthday' noStyle style={{ flex: 1 }}>
                <Input
                  onChange={updateYearsAndMonths}
                  disabled={
                    registrations?.insuranceCode &&
                    registrations?.insuranceCode != 'ZIFEI'
                      ? true
                      : false
                  }
                />
              </Form.Item>
            </Row>
          </Form.Item>
        </Col>
        <Col span={8} className={styles.fst}>
          <Form.Item
            label='身高'
            name='height'
            rules={[
              {
                message: '请输入最多带两位小数的数值',
                pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
              },
            ]}
          >
            <Input suffix='cm' />
          </Form.Item>
        </Col>
        <Col span={8} className={styles.fst}>
          <Form.Item
            label='体重'
            name='weight'
            rules={[
              {
                message: '请输入最多带两位小数的数值',
                pattern: new RegExp(/^[0-9]+((\.)[0-9]{0,2})?$/),
              },
            ]}
          >
            <Input suffix='kg' />
          </Form.Item>
        </Col>
        <Col span={8} className={styles.snd}>
          <Form.Item label='民族' name='nation'>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} className={styles.fst}>
          <Form.Item
            label='婚姻状况'
            name='maritalStatus'
            initialValue={MaritalStatus.Unknown}
          >
            <Select>
              {[
                MaritalStatus.Unknown,
                MaritalStatus.Single,
                MaritalStatus.Married,
              ].map((s) => (
                <Select.Option key={s} value={s}>
                  {getMaritalStatusName(s)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} className={styles.fst}>
          <Form.Item
            label='邮箱地址'
            name='emailAddress'
            rules={[
              {
                max: 50,
                message: '最长50位!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} className={styles.snd}>
          <Form.Item label='微信号' name='wechat'>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} className={styles.fst}>
          <Form.Item
            name='phone'
            label='联系方式'
            rules={[
              {
                pattern: /^[0-9-]*$/,
                message: '请输入正确的联系方式',
              },
            ]}
          >
            <Input maxLength={11} />
          </Form.Item>
        </Col>

        <Col span={8} className={styles.fst}>
          <Form.Item
            name='idCardNumber'
            label='身份证号'
            rules={idCardRules}
          >
            <Input
              disabled={
                registrations?.insuranceCode &&
                registrations?.insuranceCode != 'ZIFEI'
                  ? true
                  : false
              }
              onChange={(e: any) => {
                // setIdCard(e.target.value)
                getIdCardInfo(e.target.value)
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8} className={styles.snd}>
          <Form.Item
            name='companyName'
            label='工作单位'
            rules={[
              {
                max: 120,
                message: '最长120位!',
              },
            ]}
          >
            <Input
              maxLength={120}
              disabled={
                registrations?.insuranceCode &&
                registrations?.insuranceCode != 'ZIFEI'
                  ? true
                  : false
              }
            />
          </Form.Item>
        </Col>
        <Col span={8} className={styles.fst}>
          <Form.Item name='area' label='家庭地区'>
            <Cascaded
              allowClear={false}
              // loadData={loadData}
              options={allList}
              placeholder='请选择'
            />
          </Form.Item>
        </Col>
        <Col span={16} className={styles.fst}>
          <Form.Item
            name='address'
            label='详细地址'
            rules={[
              {
                max: 250,
                message: '最长250位!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        {/* <Col span={24} className={styles.fst}>
          <Form.Item
            name='address'
            label='地址'
            rules={[
              {
                max: 250,
                message: '最长250位!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col> */}
        <Col span={8} className={styles.fst}>
          <Form.Item
            name='emergencyContactName'
            label='紧急联系人'
            rules={[
              {
                max: 40,
                message: '最长40位!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} className={styles.fst}>
          <Form.Item
            name='emergencyContactPhone'
            label='紧急联系方式'
            rules={[
              {
                pattern: /^[0-9-]*$/,
                message: '请输入正确的联系方式',
              },
              {
                max: 40,
                message: '最长40位!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} className={styles.snd}>
          <Form.Item label='患者来源' name='source'>
            <Select>
              {SourceTypeCategories.map((v) => {
                return (
                  <Select.Option value={v} key={v}>
                    {getSourceTypeName(v)}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} className={styles.fst}>
          <Form.Item
            name='remark'
            label='备注'
            rules={[
              {
                max: 250,
                message: '最长250位!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
