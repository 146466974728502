/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-08-06 14:36:40
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store";
import {
  examinePage,
  examineSubmit,
  examinDetail,
  examinelogoutorEnable,
  examineSubmitbody,
  examinePageParams,
  logoutOrEnableParams,
  examineLogoutEnableList,
  detailParams,
  examineExportExcel,
  copyZLMLCatalog,
  getSortNo,
} from '../../services/examine'

const initialState = {
  Current: 1,
  ExamineData: [],
  Total: 0,
  Detail: {},
  Code: 0,
  examineTargetId: 0,
}

export const examineSlice = createSlice({
  name: 'examine',
  initialState,
  reducers: {
    setData(state, action) {
      state.ExamineData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setDetail(state, action) {
      state.Detail = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setCode(state, action) {
      state.Code = action.payload
    },
    setTargetId(state, action) {
      state.examineTargetId = action.payload
    },
  },
})

export const {
  setData,
  setTotal,
  setDetail,
  setCurrent,
  setCode,
  setTargetId,
} = examineSlice.actions

export const pageExamineAsync = createAsyncThunk<
  void,
  examinePageParams,
  RootThunkApi<void>
>('examine/page', async (params, api) => {
  return sendAsync(examinePage(params), api)
})

export const submitExamineAsync = createAsyncThunk<
  void,
  examineSubmitbody,
  RootThunkApi<void>
>('examinetreate/submit', async (params, api) => {
  return sendAsync(examineSubmit(params), api)
})

// 导出
export const examineExportExcelAsync = createAsyncThunk<
  void,
  examinePageParams,
  RootThunkApi
>('examinetreate/examineExportExcelAsync', async (params, api) => {
  return sendAsync(examineExportExcel(params), api)
})

export function detailExamine(params: detailParams): RootThunk {
  return api(examinDetail(params), (data: any, dispatch) => {
    dispatch(setDetail(data.records[0]));
  });
}


// 获取排序码
export const getSortNoAsync = createAsyncThunk<
   any,void,
 
  RootThunkApi
>('examinetreate/getSortNoAsync', async (params, api) => {
  return sendAsync(getSortNo(), api)
})

export const logoutorEnableExamineAsync = createAsyncThunk<
  void,
  number,
  RootThunkApi<void>
>("examinetreate/logoutor", async (id, api) => {
  return sendAsync(examinelogoutorEnable(id), api);
});

export const examineLogoutEnableListAsync = createAsyncThunk<
  void,
  logoutOrEnableParams,
  RootThunkApi<void>
>("examine/logoutList", async (params, api) => {
  return sendAsync(examineLogoutEnableList(params), api);
});

//诊疗目录复制
export const copyALMUCatalogAsync = createAsyncThunk<void, any, RootThunkApi<void>>(
  'commodity/start',
  async (id, api) => {
    return sendAsync(copyZLMLCatalog(id), api)
  }
)

export const selectCurrent = (state: RootState) => state.examine.Current;

export const selectExamineData = (state: RootState) =>
  state.examine.ExamineData;

export const selectTotal = (state: RootState) => state.examine.Total;

export const selectDetail = (state: RootState) => state.examine.Detail;

export const selectCode = (state: RootState) => state.examine.Code;

export const selectTargetId = (state: RootState) =>
  state.examine.examineTargetId;

export default examineSlice.reducer;
