/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-20 16:46:46
 * @LastEditors: sj
 * @LastEditTime: 2022-06-20 16:47:42
 */
import React from 'react';
import { Stacked } from '../components/Stacked';
import styles from "../insuranceContorl.module.css"

export const QRcode = () => {
  return (
    <div className={styles.qrCode}>
      <Stacked queryType={3} />
    </div>
  );
}

