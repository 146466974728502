import { unwrapResult } from "@reduxjs/toolkit";
import { Col,  Modal, notification, Row,  } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RootDispatch } from "../../../app/store";
import {
  getCancelOperation,
  getExcuteDetailList,
} from "./toBeCompletedOperationSlice";

export const CancelOperationModal = (props: {
  isModalVisible: boolean;
  id: any;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const dispatch = useDispatch<RootDispatch>();

  const [detail, setDetail] = useState<any>();

  const [surgicalName, setSurgicalName] = useState("");

  useEffect(() => {
    if (props.isModalVisible)
      dispatch(getExcuteDetailList({ id: props?.id }))
        .then(unwrapResult)
        .then((res: any) => {
          const operatingContentList =
            res.operatingContent[0] === "["
              ? JSON.parse(res.operatingContent)
              : res.operatingContent;
          setDetail(res);
          setSurgicalName(operatingContentList[0]?.surgIcalName || "");
        });
  }, [props.isModalVisible]);

  return (
    <Modal
      width={500}
      title="取消手术"
      visible={props.isModalVisible}
      onCancel={() => {
        props.onCancel();
      }}
      onOk={() => {
        dispatch(getCancelOperation({ id: props?.id }))
          .then(unwrapResult)
          .then((res: any) => {
            props?.onOk();
            notification.success({
              message: "手术取消成功",
            })
          })
          .catch(() => {
            props?.onOk();
            notification.error({
              message: "手术取消失败",
            })
          });
      }}
    >
      <Row
        style={{
          lineHeight: "30px",
          marginBottom: "20px",
          paddingLeft: "20px",
          fontSize: "14px",
        }}
      >
        <Col span={24}>
          是否确定取消{detail?.appointmentTime || "-"}给患者
          {detail?.patientName}做{surgicalName}的手术 ？
        </Col>
      </Row>
    </Modal>
  );
};
