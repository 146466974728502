export interface TemplateCategory {
  id: string
  categoryName: string
  medicalRecord?: any
}

export function fromJson(
  json: { id?: number } & Record<string, never>
): TemplateCategory {
  return {
    id: json.id?.toString() || '',
    categoryName: json.categoryName,
    medicalRecord: json.medicalRecord,
  }
}

export enum Diagnoses {
  XYF = 1,
  ZYF = 2,
  KLCYF = 3,
  JCF = 4,
  QTF = 5,
  SSWS = 6,
}

export const DiagnosesOptions = [
  Diagnoses.XYF,
  Diagnoses.ZYF,
  Diagnoses.KLCYF,
  Diagnoses.JCF,
  Diagnoses.QTF,
  Diagnoses.SSWS,
]

export function getDiagnosesName(diagnoses: Diagnoses) {
  switch (diagnoses) {
    case 1:
      return '通用病历'
    case 2:
      return '中医病历'
    case 3:
      return '口腔病历'
    case 4:
      return '医美病历'
    case 5:
      return '手术申请'
    case 6:
      return '手术文书'
    default:
      break
  }
}

export function getDiagnosesKey(key: number) {
  switch (key) {
    case 1:
      return 'diagnosis'
    case 2:
      return 'tcmCases'
    case 3:
      return 'toothCases'
    case 4:
      return 'BeautyClinic'
    case 5:
      return 'Surgical'
    case 6:
      return 'SurgicalWord'
    default :
      return 'diagnosis'
  }
}
export function getDiagnosesState(state: string) {
  switch (state) {
    case 'diagnosis':
      return 1
    case 'tcmCases':
      return 2
    case 'toothCases':
      return 3
    case 'BeautyClinic':
      return 4
    case 'Surgical':
      return 5
    case 'SurgicalWord':
      return 6
  }
}

export interface TemplateDetail {
  id?: string
  itemName: string
  templateId: string
  chiefComplaint: string
  disease: string
  historyOfPresentIllness: string
  inspectionCheck: string
  medicalHistory: string
  pastMedicalHistory: string
  physicalExamination: string
  treatmentConclusion: string
  // 中医新增字段
  medicalRecord: number
  pulseCondition?: string
  tonguePicture?: string
  // 口腔新增字段
  stomatologyCheck?: string
  treatmentPlan?: string
  disposeContent?: string
  stomatologyTooth?: string
  inspectionTooth?: string
  diseaseTooth?: string
  treatmentTooth?: string
  disposeTooth?: string
  commonId: number
  categoryName?: string
  mnemonicCode: string
}

export function fromDetailJson(
  json: { id?: number; templateId?: number } & Record<string, never>
): TemplateDetail {
  return {
    id: json.id?.toString() || '',
    itemName: json.itemName,
    templateId: json.templateId?.toString() || '',
    chiefComplaint: json.chiefComplaint,
    disease: json.disease,
    historyOfPresentIllness: json.historyOfPresentIllness,
    inspectionCheck: json.inspectionCheck,
    medicalHistory: json.medicalHistory,
    pastMedicalHistory: json.pastMedicalHistory,
    physicalExamination: json.physicalExamination,
    treatmentConclusion: json.treatmentConclusion,
    medicalRecord: json.medicalRecord,
    pulseCondition: json.pulseCondition,
    tonguePicture: json.tonguePicture,
    stomatologyCheck: json.stomatologyCheck,
    treatmentPlan: json.treatmentPlan,
    disposeContent: json.disposeContent,
    stomatologyTooth: json.stomatologyTooth,
    inspectionTooth: json.inspectionTooth,
    diseaseTooth: json.diseaseTooth,
    treatmentTooth: json.treatmentTooth,
    disposeTooth: json.disposeTooth,
    commonId: json.commonId,
    categoryName: json.categoryName,
    mnemonicCode: json.mnemonicCode,
  }
}

export interface BeautyValue {
  id: number
  level: number
  name: string
  parentId: number
  version: string
  mnemonicCode?: string
  templateType?: number
}

export interface MedicalValue {
  createTime: string
  id: number
  name: string
  version: string
}

export interface HistoryMedicalValue {
  current: number
  hitCount: boolean
  pages: number
  records: {
    createTime: string
    departmentName: string
    disease: string
    doctorName: string
    id: number
    name: string
    patientName: string
  }[]
  searchCount: boolean
  size: number
  total: number
}
