import { Button, notification, Pagination, Row, Table } from "antd";
import moment from "moment";
import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import {
  EditableList,
} from "../../../compnents/list/EditableList";
import { NavBar } from "../../../compnents/nav/NavBar";
import { DateTimeFormatSimple } from "../../../models/datetime";
import { RecardDrugParams } from "../../../services/recardDrug";
import { ThemeContext } from "../../../theme/ThemeContext";
import { RecardDrugColumns } from "./columns";
import { RecardDrugQuery } from "./Query";
import { getRecardDrugList, selectCurrent, selectRecardDrugList, selectTotal, setCurrent, getRecardDrugListExoprt, selectPageLoading, setPageLoading } from "./recardDrugSlice";
import styles from "./recardDurg.module.css";
import { PrintOutlined } from "../../../compnents/icons/Icons";
import { sleectUserData } from "../../putstorelist/putstorelistSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../../app/store";
import { getRecentTimePrint } from "../../recentTime/recentTimeSlice";
import { getPrinters, initializePrintService, printTemplate, selectPrintServiceState, selectTemplateOffsets } from "../../print/printSlice";
import { LoadingState } from "../../../models/common";
import {setSelectId} from "../../wms/supplierSlice";
import {useHistory} from "react-router-dom";
import {traceRoute} from "../../../layouts/layoutSlice";

export const RecardDrug = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>(); 
  const [queries, setQueries] = useState<RecardDrugParams>({
    recepitsDateStart: moment().startOf("day").format(DateTimeFormatSimple),
    recepitsDateEnd: moment().endOf("day").format(DateTimeFormatSimple),
  });


  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectRecardDrugList);

  const history = useHistory()

  const [size, setSize] = useState(10);

  const theme = useContext(ThemeContext);

  const tableRef = useRef(null);

  const [cell, setCell] = useState<any>({})   //合计栏

  const [printLoading, setPrintLoading] = useState(false)

  const offsets = useSelector(selectTemplateOffsets)

  const initialized = useSelector(selectPrintServiceState)   //初始化打印


  const pageLoadingRX = useSelector(selectPageLoading);

  useEffect(() => {

    if (initialized === LoadingState.Idle) {
      dispatch(initializePrintService())
        .then(unwrapResult)
        .catch(() => {
          // do nothing.
        })
    }
    if (initialized === LoadingState.Finished) {
      dispatch(getPrinters())
    }

  }, [dispatch, initialized])


  useEffect(() => {
    if (queries?.timeHead) {
      dispatch(setPageLoading(true))
      dispatch(getRecardDrugList({
        ...queries, current: Current, size, timeTail: undefined,
        timeHead: undefined,
        recepitsDateStart: moment(queries.timeHead).format(
          DateTimeFormatSimple
        ),
        recepitsDateEnd: moment(queries.timeTail).format(
          DateTimeFormatSimple
        ),
      }))

      sessionStorage.setItem('/recardDrug-queries',JSON.stringify(queries))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }

  }, [queries, size, Current,]);

  useEffect(() => {
    if (data.length == 0) {
        setCell({});
        return
    }
    const tempArr = data.filter((item: any) => {
      return item.totalUnitPrice != -1
    })
    setCell(tempArr[0])
  }, [data])

  const outCluedExport = () => {
    dispatch(getRecardDrugListExoprt({
      ...queries, current: Current, size, timeTail: undefined,
      timeHead: undefined,
      recepitsDateStart: moment(queries.timeHead).format(
        DateTimeFormatSimple
      ),
      recepitsDateEnd: moment(queries.timeTail).format(
        DateTimeFormatSimple
      ),
    }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res;
        const blob = new Blob([content]);
        const fileName = '药品购进记录.xlsx';
        const selfURL = window[window.webkitURL ? "webkitURL" : "URL"];
        const elink = document.createElement("a");
        if ("download" in elink) {
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = selfURL["createObjectURL"](blob);
          document.body.appendChild(elink);
          // 触发链接
          elink.click();
          selfURL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        } else {
          navigator.msSaveBlob(blob, fileName);
        }
      })
      .then(() => {
        notification.success({
          message: "导出成功",
          duration: 3,
        });
      })
      .catch((res: any) => {
        return
      });
  }
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <NavBar
        style={{ margin: '10px 20px 0px 20px' }}
        where={['库存', '药品购进记录']}
        backtrace={{
          name: '库房工作站',
          path: '/storage/station',
          state: { station: '3' },
        }}
        buttons={
          // <ReactToPrint
          //   trigger={() => (
          //     <Button
          //     style={{border:"none",background:"#FFFFFF",display: "flex",
          //     alignItems:"center"}}
          //       size="small"
          //       loading={printLoading}
          //       onClick={() => {
          //         // setPrintLoading(true)
          //         // dispatch(getRecentTimePrint({ templateCode: 'RecordOfDrugPurchase' }))
          //         //   .then(unwrapResult)
          //         //   // .then(() => notification.success({ message: '打印成功' }))
          //         //   .finally(() => setPrintLoading(false))
          //       }}
          //     >
          //        <PrintOutlined style={{fill:"#666",width:"18px",height:"18px",marginRight:"8px"}}/>
          //       打印
          //     </Button>
          //   )}
          //   content={() => tableRef.current}
          // />

          <Button
            style={{
              border: 'none',
              background: '#FFFFFF',
              display: 'flex',
              alignItems: 'center',
            }}
            size='small'
            loading={printLoading}
            onClick={async () => {
              await dispatch(
                printTemplate({
                  addInvRecItemBuyList: {
                    ...queries,
                  },
                  leftOffset: 0,
                  topOffset: 0,
                  templateCode: 'RecordOfDrugPurchase',
                })
              )
            }}
          >
            <PrintOutlined
              style={{
                fill: '#666',
                width: '18px',
                height: '18px',
                marginRight: '8px',
              }}
            />
            打印
          </Button>
        }
      />
      <div className={styles.content}>
        <RecardDrugQuery
          onValueChange={(v) => {
            setQueries({ ...queries, ...v })
            dispatch(setCurrent(1))
          }}
          outClued={() => outCluedExport()}
          continueOrder={(v:any, type: string) => {
            if(type === 'jzcx') {
              setQueries({ ...queries, ...v, isAccurate: Number(v) })
            }
            dispatch(setCurrent(1))
          }}
        />
        <div ref={tableRef} style={{ height: 'calc(100% - 92px)' }}>
          <EditableList
            style={{ height: '100%', overflowY: 'auto' }}
            loading={pageLoadingRX}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row className={styles.total}>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell className={styles.footingTitle} index={2}>
                    合计
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={13}></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={14}></Table.Summary.Cell>
                  <Table.Summary.Cell index={15}></Table.Summary.Cell>
                  <Table.Summary.Cell index={16}></Table.Summary.Cell>
                  <Table.Summary.Cell index={17}></Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={7}>
                    {cell?.totalUnitPrice || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={8}>
                    {cell?.totalRetailPrice || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={9}>
                    {cell?.totalCount || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='right' index={10}>
                    {cell?.totalChangePrice || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}></Table.Summary.Cell>
                  <Table.Summary.Cell index={12}></Table.Summary.Cell>
                  <Table.Summary.Cell index={13}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
            className={styles.recardDrug}
            bordered
            rowKey={(_, i) =>
              queries
                ? (queries.size || 0) * (queries.current || 0) + (i || 0)
                : 0
            }
            onChangePage={(current, pageSize) => {
              setSize(pageSize as number)
              dispatch(setCurrent(current))
            }}
            page={{
              items: data,
              current: Current,
              size: size,
              total: total,
            }}
            columns={RecardDrugColumns(size * (Current - 1),(id:number) => {
              history.push({
                pathname: "/putlist",
                state: { category: '1', selectId: id , source : 1 },
              });
            })}
          />
        </div>
      </div>
    </div>
  )
};
