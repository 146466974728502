import { Category } from "../models/category";
import { baseDataType, serverType } from './commodity'
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface putStorePageParams {
  current?: any
  size?: any
  allNum?: string
  storehouseId?: number
  storehouseTag?: string
  recepitsDateEnd?: string
  recepitsDateStart?: string
  departmentId?: string
  userId?: string
  supplierId?: string
  category?: Category
  registrationTimeHead?: string
  registrationTimeTail?: string
}

export interface putStorageparams {
  acceptStorehouseId?: number
  acceptStorehouseName?: string
  flag?: number
  category?: Category
  departmentId?: number
  departmentName?: string
  id?: number
  putNumber?: string
  realPayMoney?: number
  recepitsDate?: string
  recepitsNumber?: string
  remark?: string
  shouldPayMoney?: number
  status?: number
  storehouseId?: number
  storehouseName?: string
  storehouseTag?: number
  supplierId?: number
  supplierName?: string
  tenantId?: number
  userId?: number
  userName?: string
}

export interface multifunctionalUpAccountReq{
  tenantInventoryReceiptsIds:string
  fullTakeStockFlag?:boolean
  priceBackfillFlag?:boolean
}

export function putstoragePage(params: putStorePageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/page`,
    method: 'GET',
    params,
  }
}

export function putlistDetail(putNumber: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/detailputnumber`,
    method: 'GET',
    params: {
      putNumber,
    },
  }
}

export function putstorageDetail(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/list`,
    method: 'GET',
    params: {
      tenantInventoryReceiptsId: id,
    },
  }
}


export function exportDetailListExoprt(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/list/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params: {
      tenantInventoryReceiptsId: id,
    },
  }
}


export function exportDetailOutListExoprt(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/list/return/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params: {
      tenantInventoryReceiptsId: id,
    },
  }
}



export function putstorageSave(params: putStorageparams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/submit`,
    method: 'POST',
    body: params,
  }
}
export function putstorageUpdata(params: putStorageparams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/submit`,
    method: 'POST',
    body: params,
  }
}

export function putstorageSavelist(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/upaccount`,
    method: 'POST',
    body: {
      tenantInventoryReceiptsIds: id,
    },
  }
}

export function multifunctionalUpAccount(params:multifunctionalUpAccountReq): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/upaccount`,
    method: 'POST',
    body: params,
  }
}

export function takeStock(params:multifunctionalUpAccountReq): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/takeStock`,
    method: 'POST',
    body: params,
  }
}

export function putstorageRect(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/upreverse`,
    method: 'POST',
    body: {
      tenantInventoryReceiptsId: id,
    },
  }
}

export function putstorageFinish(id: number): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/finish`,
    method: 'GET',
    params: {
      id,
    },
  }
}

// 医保 采购单上传
export interface PurchaseBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    inventoryReceiptsId: number
    inventoryReceiptsItemId?: string
    invDataType?: number
    category?: string
  }
}

// 医保 采购单上传
export function purchaseUpload(bodyName: PurchaseBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/MerchandisePurchase_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 医保 采购单冲正上传
export function purchaseRepeal(bodyName: PurchaseBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/RemoveMerchandiseInfo_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 医保 盘点单上传
export function checkSheetUpload(bodyName: PurchaseBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/InventoryUpload_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 医保 盘点单上传
export function otherUpload(bodyName: PurchaseBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/InventoryChange_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 医保 出库单上传
export function outboundUpload(bodyName: PurchaseBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/MerchandisePurchaseReturn_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

export interface drugBody {
  serverType: serverType
  body: {
    baseData: baseDataType
    treatmentId: number | string
    recipeItemId?: string
    shipmentState:number
  }
}

export interface drugBodyA {
  serverType: serverType
  body: {
    baseData: baseDataType
    paymentIds: number[] | string[]
    recipeId?: number
    shipmentState:number
  }
}

// 医保 发药单上传
export function drugUpload(bodyName: drugBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/MerchandiseSales_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 医保 发药单批量上传
export function drugUploadA(bodyName: drugBodyA): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/MerchandiseSalesA_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 医保 退药单上传
export function returnDrugUpload(bodyName: drugBody): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/MerchandiseSalesReturn_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 医保 退药单批量上传
export function returnDrugUploadA(bodyName: drugBodyA): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/MerchandiseSalesReturnA_${bodyName.serverType}`,
    method: 'POST',
    body: bodyName.body,
  }
}

// 导出

export function getPutstorageExoprtFn(
  params: putStorePageParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}

// 复制单据
export function putstorageCopy(ids: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/copy`,
    method: 'POST',
    body: {
      ids:ids.split(',')
    },
  }
}


// 复制单据
export function readyToPrintPrintData(params: putStorePageParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/inventoryreceipts/list/readyToPrint`,
    method: 'GET',
    params,
  }
}
