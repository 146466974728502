import { Button, Space, TableColumnType, Tag } from "antd";
import React, { ReactElement, useRef } from "react";
import styles from "./patientLable.module.css";
import { EditableListRef } from "../../../compnents/containers/EditableList";

export const Columns = (
  onClick: (text: string, id: any, labelId: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const ref = useRef<EditableListRef>(null);
  return [
    {
      title: "序号",
      key: "no",
      align: "center",
      width: "5rem",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "标签名称",
      dataIndex: "name",
      align: "center",
      key: "",
    },
    {
      title: "标签分类",
      dataIndex: "labelType",
      align: "center",
      render: (_, t) => {
        return t?.labelType === 0 ? '普通标签' : t?.labelType === 1 ? '自动标签' : ''
      }
    },
    {
      title: "标签颜色",
      dataIndex: "headName",
      align: "center",
      ellipsis: true,
      render: function element(_, t) {
        return (
          <Tag
            className={styles.icon}
            color={t.backgroundColor}
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "none",
            }}
          >
            <a
              style={{
                lineHeight: "30px",
                textAlign: "center",
                width: "30px",
                height: "30px",
                color: t.fontColor,
              }}
            >
              {t.headName}
            </a>
          </Tag>
        );
      },
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "id",
      key: "id",
      render: function Actions(_, t): ReactElement | undefined {
        return (
          <Space>
            <Button
              type="primary"
              ghost
              size="small"
              onClick={(e: any) => {
                onClick("edit", t.id, e.labelId);
              }}
            >
              编辑
            </Button>
            {
              t?.labelType === 0 ? (
                <Button
                  type="primary"
                  danger
                  ghost
                  size="small"
                  onClick={(e: any) => {
                    onClick("delete", t.id, e.labelId);
                  }}
                >
                  删除
                </Button>
              ) : ''
            }
          </Space>
        );
      },
    },
  ];
};
