/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-18 17:44:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-29 11:17:41
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../../app/store'
import * as service from '../../../../services/drugStaticModel'
import { drugStaticParams } from '../../../../services/drugStatic'
import { drugStaticDetailExoprt } from '../../../../services/drugStaticModel'

interface modelDetail {
  detail: []
  loading?: false
  Total: 0
  Current: 1
}

const initialState = {
  detail: [],
  loading: false,
  Total: 0,
  Current: 1,
} as modelDetail

const drugStaticDetailSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setDetail(state, action) {
      state.detail = action.payload
    },
  },
})

export const {
  setTotal,
  setCurrent,
  setDetail,
  setLoading,
} = drugStaticDetailSlice.actions

export const selectDetail = (state: RootState) =>
  state.drugStaticModelDetail.detail

export const selectTotal = (state: RootState) =>
  state.drugStaticModelDetail.Total

export const selectCurrent = (state: RootState) =>
  state.drugStaticModelDetail.Current

  export const selectLoading = (state: RootState) =>
    state.drugStaticModelDetail.loading

export function getDrugStaticDetailList(params: drugStaticParams): RootThunk {
  return api(service.getDrugStaticDetail(params), (data: any, dispatch) => {
    dispatch(setDetail(data.records))
    dispatch(setTotal(data.total))
    dispatch(setLoading(false))
  })
}



export const getDrugStaticDetailExoprtFnAsync = createAsyncThunk<
  void,
  drugStaticParams,
  RootThunkApi
>('doctorAudit/getDrugStaticDetailExoprtFnAsync ', async (params, api) => {
  return sendAsync(drugStaticDetailExoprt(params), api)
})


export default drugStaticDetailSlice.reducer
