/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 13:53:34
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-26 17:11:56
 */
/* eslint-disable react/display-name */
import {Popover, TableColumnType} from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import {QuestionCircleTwoTone} from "@ant-design/icons";

export const Columns = (startIndex?: any): TableColumnType<any>[] => {

  const Hint = (title: string) => (
    <>
      {title}
      <Popover placement="top" content="人次 = 未退费交易的笔数（一个患者当天多次结算人次为1）" >
        <QuestionCircleTwoTone style={{ marginLeft: '5px' }} />
      </Popover >
    </>
  );
  return [
    {
      title: '类别',
      dataIndex: 'xianzhonglxmc',
      width: '6em',
      align: 'center',
    },
    {
      title: Hint('人次'),
      dataIndex: 'personTime',
      width: '6em',
      align: 'center',
    },
    {
      title: '总额',
      dataIndex: 'totalAmount',
      width: '6em',
      align: 'right',
      render: (_, r) => (r?.totalAmount ? r?.totalAmount?.toFixed(2) : '0.00'),
    },
    {
      title: '个人现金',
      dataIndex: 'cashAmount',
      width: '6em',
      align: 'right',
      render: (_, r) => (r?.cashAmount ? r?.cashAmount?.toFixed(2) : '0.00'),
    },
    {
      title: '核拨',
      key: 'reimbursementAmount',
      width: '6em',
      align: 'right',
      render: (_, r) =>
        r?.reimbursementAmount ? r?.reimbursementAmount?.toFixed(2) : '0.00',
    },
  ]
}
