import { Form, Space, Switch, TableColumnType } from "antd";
import moment from "moment";
import { CategoryName } from "../../../../models/category";
import { Dimen } from "../../../../models/dimen";
import React from "react";

export const PurchaseControlDetailColumns = (
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      key: "no",
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
        title: "业务单号",
        align: "center",
        dataIndex: "putNumber",
        width: Dimen.PutNumber,
        key: "putNumber",
        sorter: (a: any, b: any) => a.putNumber - b.putNumber,
      },
    {
      title: "商品类别",
      align: "center",
      dataIndex: "drugCategory",
      width: Dimen.categoryType,
      key: "drugCategory",
    },
    {
      title: "商品名称",
      align: "left",
      dataIndex: "name",
      key: "name",
      width: Dimen.PutNumber,
      render: function Element(_, record) {
        return (
            <div style={{ height: "55px" }}>
              <p
                  style={{
                    height: "28px",
                    lineHeight: "36px",
                    padding: "0",
                    margin: "0",
                    fontSize: "16px",
                    fontWeight: "bolder",
                  }}
              >
                {record.name}
              </p>
              <p
                  style={{
                    height: "28px",
                    lineHeight: "24px",
                    padding: "0",
                    margin: "0",
                    color: "#999999",
                    fontSize: "14px",
                  }}
              >
                {record?.tenantSupplierName} /{record.spec} /{record.doseCategory}
              </p>
            </div>
        );
      },
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: "业务类别",
      align: "center",
      key: "category",
      width: Dimen.Category,
      render: (_, t) => {
        return CategoryName (t.category);
      },
    },
    {
      title: "发生日期",
      align: "center",
      dataIndex: "createTime",
      width: Dimen.Time,
      key: "upAccountTime",
      render: (_, t) => {
        const upAccountTime = (t.createTime).substring(0, 10);
        return upAccountTime;
      },
      sorter: (a: any, b: any) =>
      moment(a.createTime).unix() - moment(b.createTime).unix(),
    },
    {
      title: "数量",
      align: "center",
      dataIndex: "count",
      width: Dimen.Count,
      key: "count",
      render: (_, r) => r.count?.toFixed(2),
    },
    {
      title: "进价",
      align: "center",
      dataIndex: "unitPrice",
      width: Dimen.Price,
      key: "unitPrice",
      render: (_, r) => r.unitPrice?.toFixed(5),
    },
    {
      title: "总金额",
      align: "center",
      dataIndex: "totalPrice",
      width: Dimen.Price,
      key: "totalPrice",
      render: (_, r) => r.totalPrice?.toFixed(2),
    }

  ];
};
