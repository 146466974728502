/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-14 19:47:00
 */
export interface CarryOverEntry {
  id: string
  storehouseId: number
  storehouseName: string
  carryOverPrice: number
  expendPrice: number
  incomePrice: number
  startTime: string
  endTime: string
  statisticsTime: string
  state: number
  totalIncomePrice: number
  totalExpendPrice: number
  totalCarryOverPrice: number
}

export function fromJson(
  json: { id?: number } & Record<string, never>
): CarryOverEntry {
  return {
    id: json.id?.toString() || '',
    storehouseId: json.storehouseId,
    storehouseName: json.storehouseName,
    carryOverPrice: json.carryOverPrice,
    expendPrice: json.expendPrice,
    incomePrice: json.incomePrice,
    startTime: json.startTime,
    endTime: json.endTime,
    statisticsTime: json.statisticsTime,
    state: json.state,
    totalIncomePrice: json.totalIncomePrice,
    totalExpendPrice: json.totalExpendPrice,
    totalCarryOverPrice: json.totalCarryOverPrice,
  }
}

export interface CarryOverDetailEntry {
  id: number
  accountUnit: string
  batchNo: string
  beginningCount: number
  carryOverPrice: number
  closeCount: number
  doseCategory: string
  expendCount: number
  expendPrice: number
  incomeCount: number
  incomePrice: number
  name: string
  productInfo: string
  spec: string
  splitBeginningCount: number
  splitCloseCount: number
  splitExpendCount: number
  splitExpendPrice: number
  splitIncomeCount: number
  splitIncomePrice: number
  splitScale: number
  statisticsTime: string
  storehouseId: number
  storehouseName: string
  tenantCarryOverId: number
  tenantInventoryLedgerId: number
  tenantMaterialsCatalogId: number
  tenantSupplierName: string
  unitPrice: number
  shouldPayTotal?: number
  realPayTotal?: number
  str30?: number
  rtr30?: number
  str97?: number
  rtr97?: number
  str04?: number
  rtr04?: number
  str06?: number
  rtr06?: number
  strOther?: number
  rtrOther?: number
  sdr01?: number
  rdr01?: number
  sdr02?: number
  rdr02?: number
  sdr03?: number
  rdr03?: number
  sdrOther?: number
  rdrOther?: number
  samountPerTip?: number
  ramountPerTip?: number
  total?: number
  realTotal?: number
  allTotalAmount?: number
}

export interface CarryOverItem {
  id: number
  category: number
  chinesePatentPrice: number
  chinesePrice: number
  endTime: string
  instrumentPrice: number
  restPrice: number
  startTime: string
  statisticsTime: string
  status: number
  storehouseId: number
  storehouseName: string
  tenantCarryOverId: number
  totalPrice: number
  westernPrice: number
  westernSellingPrice: number
  chinesePatentSellingPrice: number
  chineseSellingPrice: number
  instrumentSellingPrice: number
  restSellingPrice: number
  totalSellingPrice: number
}
