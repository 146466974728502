/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-22 20:44:24
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-13 15:25:53
 */
import React from "react";
import styles from "../WarehouseBusinessLibrary/library.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setCategory } from "../../putstorage/putstorageSlice";
import { Category } from "../../../models/category";
import { selectThreeMenus, traceRoute } from "../../../layouts/layoutSlice";

export const WarehouseManagementLibrary = (props: { parentId: string}) => {
  const dispatch = useDispatch();

  const threeMenus = useSelector(selectThreeMenus)

  const getStatus = (name: string) => {
    const menus = threeMenus?.filter((v: any) => v.parentId == props?.parentId)
    return menus?.some((v: any) => v.name == name)
  }
  
  return (
    <div className={styles.content}>
      {getStatus('库存查询') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/storesearch",
              state: { flag: 0 },
            })
          );
        }}
      >
        库存查询
      </div>) : ''}
      {getStatus('库存盘点') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(setCategory(Category.KCPD));
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/InventoryCheck",
              state: { category: "8", station: "2" },
            })
          );
        }}
      >
        库存盘点
      </div>) : ''}
      {getStatus('中药清斗') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(setCategory(Category.ZYQD));
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/InventoryCheck",
              state: { category: "10", station: "2" },
            })
          );
        }}
      >
        中药清斗
      </div>) : ''}
      {getStatus('报损') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(setCategory(Category.BS));
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/InventoryCheck",
              state: { category: "9", station: "2" },
            })
          );
        }}
      >
        报损
      </div>) : ''}
      {getStatus('库存养护') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/stockMaintenance",
            })
          );
        }}
      >
        库存养护
      </div>) : ''}
      {getStatus('近效期管理') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/recentTime",
              state: { station: "2" },
            })
          );
        }}
      >
        近效期管理
      </div>) : ''}
      {getStatus('库存下限管理') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: "库房工作站",
              path: "/stockfloor",
              state: { station: "2" },
            })
          );
        }}
      >
        库存下限管理
      </div>) : ''}
    </div>
  );
};
