/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 12:05:04
 * @LastEditors: sj
 * @LastEditTime: 2022-06-20 16:45:40
 */
import { Col, Tabs } from "antd"
import React, { useRef } from "react"
import { Stacked } from "../components/Stacked";
import styles from "../insuranceContorl.module.css"

export const LimitTabBar = () => {
  const { TabPane } = Tabs;

  return (
    <Col className={styles.containerItem}>
      <Tabs
        defaultActiveKey={"1"}
      >
        <TabPane key='1' tab='医保基金支付限额'>
          <Stacked queryType={8} />
        </TabPane>
        <TabPane key='2' tab='医保列支医疗费支付限额'>
          <Stacked queryType={9} />
        </TabPane>
      </Tabs>
    </Col>
  )
}
