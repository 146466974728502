/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-14 09:57:36
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-28 17:32:50
 */
import { Form, Row, Col, Input, Select, Button } from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { StockfloorParams } from "../../services/stockfloor";
import { useDispatch, useSelector } from "react-redux";
import {
  pageDurgsystem,
  selectDurgsystemData,
} from "../durgsystem/durgsystemSlice";
import { pageStockfloor } from "./stockfloorSlice";
const { Option } = Select;

export const StockfloorQuery = (props: {
  onValueChange: (queries: StockfloorParams) => void;
  onedit: (text: string) => void;
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pageDurgsystem(1, 100));
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      flag:0
    });
  }, [])
  const durgsystemData = useSelector(selectDurgsystemData);

  return (
    <Form
    style={{marginBottom: "10px"}}
      form={form}
      name="control-hooks"
      onFieldsChange={(_, fs) => {
        props.onValueChange(form.getFieldsValue());
      }}
    >
      <Row gutter={11}>
        <Col span={4}>
          <Form.Item name="keyword">
            <Input
              size="middle"
              placeholder="请输入关键字"
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="storehouseId">
            <Select placeholder="库房" allowClear>
              {durgsystemData.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item name="flag">
            <Select placeholder="是否合并批次号" allowClear defaultValue={0}>
              <Option value={0}>是否合并批次号-否</Option>
              <Option value={1}>是否合并批次号-是</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item name="type">
            <Select placeholder="" allowClear defaultValue={0}>
              <Option value={0}>全部</Option>
              <Option value={1}>库存不足</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={2} offset={4}>
          <Button
            type="primary"
            style={{ float: "right"}}
            onClick={() => {
              props.onValueChange(form.getFieldsValue());
            }}
          >
            查询
          </Button>
        </Col>
        {/* <Col span={2}>
          <Button type="primary" style={{ float: "right", width: "100%" }}>
            导出
          </Button>
        </Col> */}
        <Col >
          <Button
            type="primary"
            style={{ float: "right"}}
            onClick={() => {
              props.onedit("edit");
            }}
          >
            修改
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
