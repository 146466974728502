/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-31 14:23:29
 * @LastEditors: linxi
 * @LastEditTime: 2021-06-02 20:00:19
 */
import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  getMaterialsCategoryName,
  getMaterialsName,
  Materiales,
  MaterialsCategories,
} from "../../../../../models/commodity";
import { useDispatch } from "react-redux";
import { setAddtionCurrent } from "../addtionDrugSlice";
const { Option } = Select;

export const ButtonQuery = (props: {
  detail: any;
  onRemove: () => void;
  onValueChange: (queries: any) => void;
  onSave: () => void;
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  return (
    <>
      <Row style={{ marginBottom: "10px" }} gutter={10}>
        <Col span={2}>
          <Button
            type="primary"
            danger
            style={{ width: "100%" }}
            onClick={() => {
              props.onRemove();
            }}
            disabled={props.detail.state ? true : false}
          >
            删除
          </Button>
        </Col>
        <Col span={3}>
          <Button
            htmlType="submit"
            type="primary"
            style={{ width: "100%" }}
            onClick={() => {
              props.onSave();
            }}
            disabled={props.detail.state ? true : false}
          >
            添加养护药品
          </Button>
        </Col>
      </Row>
      <Form
        form={form}
        onFieldsChange={(_, fs) => {
          dispatch(setAddtionCurrent(1));
          props.onValueChange({
            ...form.getFieldsValue(),
            name: form.getFieldsValue().name
              ? form.getFieldsValue().name
              : null,
          });
        }}
        autoComplete="off"
      >
        <Row
          gutter={11}
          style={{ height: "42px", position: "relative", zIndex: 999 }}
          wrap={false}
        >
          <Col span={6}>
            <Form.Item name="name">
              <Input
                placeholder="药品名称或助记码"
                allowClear
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="materialsCategory">
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="药品分类"
                allowClear
              >
                {Materiales.map((v) => (
                  <Option value={v} key={v}>
                    {getMaterialsName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2} offset={12}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: "100%", float: "right" }}
            >
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
