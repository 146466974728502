import { Button, Col, Form, Input, Modal, Row } from "antd";

import { useDispatch } from "react-redux";
import { RootDispatch } from "../../app/store";
import React, { useEffect, useState } from "react";
import {
  getOperaingRoomDetail,
  getOperatingRoomSubmit,
} from "./operatingRoomSlice";
import { unwrapResult } from "@reduxjs/toolkit";

export const OperatingRoomModal = (props: {
  isModalVisible: boolean;
  id: any;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm();

  const [detail, setDetail] = useState<any>();

  useEffect(() => {
    if (props.id)
      dispatch(getOperaingRoomDetail({ id: props.id }))
        .then(unwrapResult)
        .then((res: any) => {
          setDetail(res);
        });
  }, [props.id]);

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...detail,
      });
    }
  }, [detail, form]);

  return (
    <Modal
      width={560}
      maskClosable={false}
      title={props?.id ? '编辑手术室' : ' 创建手术室'}
      visible={props.isModalVisible}
      footer={null}
      onCancel={() => {
        props.onCancel()
      }}
      onOk={() => {
        props?.onOk()
      }}
    >
      <Form
        name='basic'
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 10 }}
        initialValues={{ remember: true }}
        autoComplete='off'
        onFinish={(values) => {
          dispatch(
            getOperatingRoomSubmit({
              ...values,
              id: props?.id,
            })
          )
            .then(() => {
              props?.onOk()
              form.resetFields()
            })
            .catch(() => {
              props?.onCancel()
              form.resetFields()
            })
        }}
      >
        <Form.Item
          style={{ padding: '30px 0' }}
          label='手术室名称：'
          name='name'
          rules={[
            { required: true, message: '请填写手术室名称' },
            {
              pattern: new RegExp(/^.*[^\d].*$/),
              message: '不能纯数字，请重新输入',
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>
        <Row gutter={20}>
          <Col span={12}>
            <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
              确认
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={{ float: 'left' }}
              onClick={() => {
                form.resetFields()
                props.onCancel()
              }}
            >
              取消
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};
