/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {Button, Space, TableColumnType} from "antd"
import moment from "moment"
import React from "react"
import { DateTimeFormatSimple } from "../../models/datetime"
import { Dimen } from "../../models/dimen"
import { getStandStateName } from "../../models/standbook"
import {getPaymentDetail} from "../payment/list/paymentSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {selectUserId, selectUserName} from "../../app/applicationSlice";
import {Registration} from "../../models/registration";
export const LiveColumns = (
    onAction: (
        action: 'startLive' | 'openLive',
        liveId: string,
        liveUrl:string,
        liveType:'kaibo' | 'open'
    ) => void,
  startIndex: number
): TableColumnType<any>[] => {

  const userId = useSelector(selectUserId);
  return [
    {
      title: "序号",
      align: "center",
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "直播标题",
      align: "center",
      dataIndex: "title",
      width: Dimen.Sex,
    },
    {
      title: "直播简介",
      align: "center",
      dataIndex: "introduction",
      width: Dimen.Sex,
    },
    {
      title: "主播",
      align: "center",
      dataIndex: "anchorName",
      width: Dimen.Sex,
    },
    {
      title: "拟定开播时间",
      align: "center",
      dataIndex: "startTime",
      width: Dimen.Sex,
    },
    {
      title: "拟定结束时间",
      align: "center",
      dataIndex: "endTime",
      width: Dimen.Sex,
    },
    {
      title: "创建时间",
      align: "center",
      dataIndex: "createTime",
      width: Dimen.Sex,
    },
    {
      title: "直播状态",
      align: "center",
      dataIndex: "status",
      render: function content(status: number) {
        return status === 0 ? "未开播" : status === 1 ? "直播中" : "已结束";
      },
      width: Dimen.Sex,
    },
    {
      title: "操作",
      key: "actions",
      align: "center",
      width: "6rem",
      render: function ShowActions(_, r) {
        return <Space>
          { (r.status == 0 && r.anchorUserId == userId )&& (
              <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    onAction("startLive",r.id,"","kaibo");
                  }}
              >
                开播
              </Button>
          )}
          { (r.status == 1 )&& (
              <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    if(r.anchorUserId == userId){
                      onAction("openLive",r.id,r.anchorLiveUrl,"kaibo");
                    }else{
                      onAction("openLive",r.id,r.liveUrl,"open");
                    }
                  }}
              >
                进入直播
              </Button>
          )}

        </Space>
      },
    },
  ]
}
