import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import * as D from '../../../models/department'
import { Department } from '../../../models/department'
import { Page, startPageOf } from '../../../models/page'
import * as P from '../../../models/payment'
import { Payment, PaymentStatus } from '../../../models/payment'
import { RecipeStatus } from '../../../models/recipe'
import * as R from '../../../models/refund'
import { Refund } from '../../../models/refund'
import { getOutpatientDepartment } from '../../../services/department'
import {
  getInsurancePaymentList,
  getPaymentDetail as apiGetPaymentDetail,
  getPaymentList as apiGetPaymentList,
  paymentDelete as apiGetPaymentDelete,
  PaymentListParams,
  refund as apiRefund,
  JuheReturnPremium,
  JuheReturnPremiumParams,
  oweMoneyFnParams,
  oweMoneyFn,
  getPaymentResult,
  getAllDepartmentUserFn,
  paymentListExoprt,
} from '../../../services/payment'

interface PaymentState {
  pages: {
    [RecipeStatus.ToSettle]: Page<Payment>
    [RecipeStatus.Settled]: Page<Payment>
    [RecipeStatus.Returned]: Page<Payment>
  }
  departments: Department[]
}

const initialState = {
  pages: {
    [RecipeStatus.ToSettle]: startPageOf(),
    [RecipeStatus.Settled]: startPageOf(),
    [RecipeStatus.Returned]: startPageOf(),
  },
  departments: [],
} as PaymentState

export const getPaymentDetail = createAsyncThunk<
  Refund,
  string,
  RootThunkApi<Refund>
>('payment/getPaymentDetailStatus', async (paymentId, api) => {
  return sendAsync(apiGetPaymentDetail(paymentId), api).then(
    (data: Record<string, never>) => {
      return R.fromJson(data.payment)
    }
  )
})
export const getPaymentDetail2 = createAsyncThunk<
  any,
  string,
  RootThunkApi<Refund>
>('payment/getPaymentDetailStatus', async (paymentId, api) => {
  return sendAsync(apiGetPaymentDetail(paymentId), api)
})

// 快速开单删除
export const getPaymentDelete = createAsyncThunk<any, string, RootThunkApi<Refund>>(
  'payment/getPaymentDelete',
  async (paymentId, api) => {
    return sendAsync(apiGetPaymentDelete(paymentId), api)
  }
)

export const refund = createAsyncThunk<void, string, RootThunkApi<void>>(
  'payment/refundStatus',
  async (paymentId, api) => {
    return sendAsync(apiRefund(paymentId), api)
  }
)

//导出
export const getPaymentListExoprt = createAsyncThunk<
  void,
  PaymentListParams,
  RootThunkApi
>('doctorAudit/getPaymentListExoprt ', async (params, api) => {
  return sendAsync(paymentListExoprt(params), api)
})


export const getPaymentList = createAsyncThunk<
  Page<Payment>,
  PaymentListParams,
  RootThunkApi<Page<Payment>>
>('payment/getPaymentListStatus', async (params, api) => {
  return sendAsync(apiGetPaymentList(params), api).then(
    (data: Record<string, never>) => {
      const payments = _.chain(data.records)
        .map((p) => P.fromJson(p))
        .value()
      return {
        current: data.current || 1,
        size: data.size || 10,
        total: data.total || 0,
        items: payments,
      }
    }
  )
})

export const getInsurancePaymentListAsync = createAsyncThunk<
  Page<Payment>,
  PaymentListParams,
  RootThunkApi<Page<Payment>>
>('payment/getPaymentListStatus', async (params, api) => {
  return sendAsync(getInsurancePaymentList(params), api).then(
    (data: Record<string, never>) => {
      const payments = _.chain(data.records)
        .map((p) => P.fromJson(p))
        .value()
      return {
        current: data.current,
        size: data.size,
        total: data.total,
        items: payments,
      }
    }
  )
})

const paytmentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setDepartments: (state, action: PayloadAction<Department[]>) => {
      state.departments = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentList.fulfilled, (state, action) => {
      state.pages[action.meta.arg.state] = action.payload
    })
  },
})

const { setDepartments } = paytmentSlice.actions

export const selectPaymentPage = (status: PaymentStatus) => (
  state: RootState
): Page<Payment> => state.payment.pages[status]

export const selectDepartments = (state: RootState): Department[] =>
  state.payment.departments

export function getDepartments(): RootThunk {
  return api(
    getOutpatientDepartment(),
    (data: Record<string, never>, dispatch) => {
      const departments = _.chain(data.records)
        .map((d) => D.fromJson(d))
        .value()
      dispatch(setDepartments(departments))
    }
  )
}

// 聚合支付退费
export const JuhePremium = createAsyncThunk<
  any,
  JuheReturnPremiumParams,
  RootThunkApi
>('confirm/JuhePremium', async (params, api) => {
  return sendAsync(JuheReturnPremium(params), api)
})
// 是否欠费
export const oweMoney = createAsyncThunk<any, oweMoneyFnParams, RootThunkApi>(
  'confirm/oweMoney',
  async (params, api) => {
    return sendAsync(oweMoneyFn(params), api)
  }
)

// 欠费管理详情
export const getOweMoneyDetail = createAsyncThunk<any, string, RootThunkApi>(
  'payment/getOweMoneyDetail',
  async (params, api) => {
    return sendAsync(getPaymentResult(params), api)
  }
)
// 获取全部科室 + 医生
export const getAllDepartmentUser = createAsyncThunk<any, void, RootThunkApi>(
  'payment/getAllDepartmentUser',
  async (_, api) => {
    return sendAsync(getAllDepartmentUserFn(), api)
  }
)
// async (paymentId, api) => {
//   return sendAsync(getPaymentResult(paymentId), api).then(
//     (data: Record<string, never>) => {
//       return sendAsync(oweMoneyFn(params), api)
//       return R.fromJson(data.payment)
//     }
//   )
// })
export default paytmentSlice.reducer
