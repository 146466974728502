export interface standCountValue {
  count: number
  state: number
  stateName: string
}

export interface standListParams {
  cautionId?: number
  current?: number
  size?: number
  state?: number
  happenTimeHead?: string
  happenTimeTail?: string
  pullTimeHead?: string
  pullTimeTail?: string
}

export interface standListValue {
  current: number
  hitCount: boolean
  pages: number
  records: {
    cautionId: number
    happenTime: string
    pullTime: string
    reason: string
    state: number
  }[]
  searchCount: boolean
  size: number
  total: number
}

export interface appealParams {
  appealPerson?: string
  appealReason?: string
  attachment?: any
  cautionId?: number
  tenantId?: number
}

export interface standDetailValue {
  cautionDetail: string
  cautionProcessModelVOS: {
    attachment: []
    attachmentStr: string
    person: string
    reason: string
    timeNode: string
    type: number
  }[]
  cautionTime: string
  state: number
}

export const getStandStateName = (state: number) => {
  switch (state) {
    case 0:
      return "待申诉"
    case 1:
      return "申诉中"
    case 2:
      return "申诉完成"
    default:
      return "全部"
  }
}

export const getTypeName = (state: number) => {
  switch (state) {
    case 1:
      return "警报分发"
    case 2:
      return "警报申诉"
    case 3:
      return "警报驳回"
    case 4:
      return "申诉完成"
  }
}
