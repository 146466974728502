/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-03 10:54:39
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../../app/store'
import { sendAsync } from '../../../../app/applicationSlice'
import * as DT from '../../../../services/warehouseChangeReport'

// 列表
export const getWarehouseChangeReportList = createAsyncThunk<
  void,
  DT.WarehouseChangeReportPrams,
  RootThunkApi<void>
>('clinic/getOutPatientRegistrationReportList', async (params, api) => {
  return sendAsync(DT.treatmentCostReportList(params), api)
})

// 导出
export const getWarehouseChangeReportExport = createAsyncThunk<
  void,
  DT.WarehouseChangeReportPrams,
  RootThunkApi
>('stand/getUseDetailExport', async (params, api) => {
  return sendAsync(DT.getExportFn(params), api)
})
