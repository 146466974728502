import { SkinTestStatus } from './treatment'

export enum OrderCategory {
  Jia = 1,
  Yi = 2,
  Bing = 3,
  Mixed = 4,
}

export function getOrderCategoryName(category: OrderCategory): string {
  switch (category) {
    case OrderCategory.Jia:
      return '甲'
    case OrderCategory.Yi:
      return '乙'
    case OrderCategory.Bing:
      return '丙'
    default:
      return '丙'
  }
}

export enum OtcCategory {
  Prescription = 0,
  Otc = 1,
  DualPrescription = 2,
}

export function getOtcCategoryName(category: OtcCategory): string {
  switch (category) {
    case OtcCategory.Prescription:
      return '处方药'
    case OtcCategory.Otc:
      return 'OTC'
    case OtcCategory.DualPrescription:
      return '处方药双轨'
  }
  return ''
}

export interface Material {
  couponsItemName: string
  id: string
  localItemId?: string
  hilistCode: string
  accountUnit: string
  antibioticTag: number
  approvalNumber: string
  insuranceCode?: string
  batchNo: string
  barCode: string
  basicMedicineTag: number
  executeState?:any
  billingCategory: string
  count: number
  compoundTag: number
  doseCategory: number
  doseUnit: string
  recipeId: number
  deadline: string
  drugCategory: string
  ephedrineTag: number
  freezeTemperature: string
  genericName: string
  inventoryAlert: number
  materialsCategory: number
  miscNo: string
  mnemonicCode: string
  name: string
  no: string
  normalDose: number
  normalFrequency: string
  normalUsemethod: string
  orderCategory: OrderCategory
  origin: string
  otc: number
  packSpec: string
  productionLicence: string
  putCategory: string
  qualityControlCategory: string
  reportNo: string
  retailPrice: number
  skinTestTag: SkinTestStatus
  source: number
  sourceMaterialsCatalogId: string
  spec: string
  splitPrice: number
  splitScale: number
  splitTag: number
  comboSplitTag: number
  comboSplitScale: number
  comboSplitUnit: string
  comboSplitPrice: number
  splitUnit: string
  splitCount: number
  state: number
  status: number
  stimulantTag: number
  stockPrice: number
  tenantSupplierId: string
  tenantSupplierName: string
  tradeRemark: string
  transitionScale: number
  useDays: string
  drugCount: number
  groupNumber: number
  sort: number
  drugType: number
  drugTypeA: number
  tip: number
  countPerTip: number
  materialsCatalogId: string
  inventoryLedgerId: string
  treatmentServiceId: string
  inventoryInfo: string
  unShelveInfo: string
  skinTestInfo: string
  storehouseId: string
  storehouseName: string
  drugAmount: number
  shipmentsState: number
  materTreatPrice: number
  remark: string
  tenantMaterialsCatalogId: string
  productDate: string
  productInfo: string
  unitPrice: number
  splitUnitPrice: number
  itemPayType: number
  limitPrice: number
  limitType: number
  frequencyCalc: number
  quarterType: number
  skinTestState: number
  alreadyNoticed: number
  toothStruct?: string
  catalogSplitPrice: number
  catalogUnit: string
  catalogPrice: number
  priceLimit: number
  memo: string
  unitPriceInterval?: string
  retailPriceInterval?: string
  discount?: any
  doseConvert?:number
}

export function fromMaterialJson(json: any): any {
  return {
    ...json,
    id: json.id?.toString() || '',
    accountUnit: json.accountUnit,
    antibioticTag: json.antibioticTag,
    approvalNumber: json.approvalNumber,
    batchNo: json.batchNo,
    barCode: json.barCode,
    deadline: json.deadline,
    basicMedicineTag: json.basicMedicineTag,
    executeState:json.executeState,
    billingCategory: json.billingCategory,
    compoundTag: json.compoundTag,
    doseCategory: json.doseCategory,
    count: json.count,
    doseUnit: json.doseUnit,
    drugCategory: json.drugCategory,
    ephedrineTag: json.ephedrineTag,
    freezeTemperature: json.freezeTemperature,
    genericName: json.genericName,
    inventoryAlert: json.inventoryAlert,
    materialsCategory: json.materialsCategory,
    miscNo: json.miscNo,
    mnemonicCode: json.mnemonicCode,
    name: json.name,
    normalDose: json.normalDose,
    normalFrequency: json.normalFrequency,
    normalUsemethod: json.normalUsemethod,
    orderCategory: json.orderCategory,
    origin: json.origin,
    otc: json.otc,
    drugType: json.drugType,
    drugTypeA: json.drugTypeA,
    packSpec: json.packSpec,
    productionLicence: json.productionLicence,
    putCategory: json.putCategory,
    qualityControlCategory: json.qualityControlCategory,
    reportNo: json.reportNo,
    retailPrice: json.retailPrice,
    recipeId: json.recipeId,
    skinTestTag: json.skinTestTag,
    source: json.source,
    sourceMaterialsCatalogId: json.sourceMaterialsCatalogId?.toString() || '',
    spec: json.spec,
    splitPrice: json.splitPrice,
    splitScale: json.splitScale,
    splitTag: json.splitTag,
    comboSplitTag: json.comboSplitTag,
    comboSplitScale: json.comboSplitScale,
    comboSplitUnit: json.comboSplitUnit,
    comboSplitPrice: json.comboSplitPrice,
    splitUnit: json.splitUnit,
    splitCount: json.splitCount,
    state: json.state,
    status: json.status,
    stimulantTag: json.stimulantTag,
    stockPrice: json.stockPrice,
    tenantSupplierId:
      json.tenantSupplierId > 0 ? json.tenantSupplierId?.toString() : '',
    tenantSupplierName: json.tenantSupplierName,
    tradeRemark: json.tradeRemark,
    transitionScale: json.transitionScale,
    frequencyCalc: json.frequencyCalc,
    useDays: json.useDays,
    drugCount: json.drugCount,
    groupNumber: json.groupNumber,
    sort: json.sort === undefined ? 0 : json.sort,
    tip: json.tip || 1,
    countPerTip: json.countPerTip,
    materialsCatalogId:
      json.materialsCatalogId > 0 ? json.materialsCatalogId?.toString() : '',
    inventoryLedgerId:
      json.inventoryLedgerId > 0 ? json.inventoryLedgerId?.toString() : '',
    treatmentServiceId:
      json.treatmentServiceId > 0 ? json.treatmentServiceId?.toString() : '',
    inventoryInfo: json.inventoryInfo,
    unShelveInfo: json.unShelveInfo,
    skinTestInfo: json.skinTestInfo,
    storehouseId: json.storehouseId,
    storehouseName: json.storehouseName,
    drugAmount: json.drugAmount,
    shipmentsState: json.shipmentsState,
    materTreatPrice: json.materTreatPrice,
    remark: json.remark,
    tenantMaterialsCatalogId: json.tenantMaterialsCatalogId,
    productDate: json.productDate,
    productInfo: json.productInfo,
    unitPrice: json.unitPrice,
    splitUnitPrice: json.splitUnitPrice,
    itemPayType: json.itemPayType,
    limitPrice: json.limitPrice,
    limitType: json.limitType,
    toothStruct: json.toothStruct,
    catalogSplitPrice: json.catalogSplitPrice,
    realPayAmount: json.realPayAmount,
    operateUserName: json.operateUserName,
    payMethod: json.payMethod,
    createTime: json.createTime,
    catalogUnit: json.catalogUnit,
    catalogPrice: json.catalogPrice
  }
}

export function fromTreatmentJson(json: any): Material {
  return {
    id: json.id,
    name: json.name,
    accountUnit: json.accountUnit,
    billingCategory: json.billingCategory,
    mnemonicCode: json.mnemonicCode,
    insuranceCode: json.insuranceCode,
    no: json.no,
    retailPrice: json.retailPrice,
    state: json.state,
    status: json.status,
    quarterType: json.quarterType,
  } as Material
}

export interface MedicalItem {
  chrgitmLv: string
  conversion: number
  dosformName: string
  hiListCode: string
  hilistName: string
  hilistPricUplmtAmt: string
  insuranceCode: string
  isCouldBx: string
  medChrgitmType: string
  pacunt: string
  prodentpName: string
  spec: string
  valiFlag: string
  limitId: string
  trtItemDscr: string
  otcFlag: string
  billingCategory?: any
  no?: any
  retailPrice?: any
  treatmentCategory?: any
  mnemonicCode?: any
  wubiCode?: any
  rid?:string
  proviceHilistCode?:string
  limitCount?: number
}

export interface PriceChangeDetail {
  hilistCode: string
  hilistName: string
  oldHilistPrice: string
  hilistPrice: string
  changeDate: string
}

// 嘉兴  ZJ_SX_MT_TSW  3304
// 舟山  ZJ_ZS_DC_MT  3309
// 衢州  ZJ_QZ_MT_MH  3308
// 杭州  ZJ_HZ_310000  3301
// 金华  ZJ_JH_321000  3307
// 湖州  ZJ_HZ_313000  3305
// 台州  ZJ_TZ_318000  3310
// 宁波  ZJ_NB_315000  3302
// 丽水  ZJ_LS_323000  3311
// 温州  ZJ_WZ_325000  3303
// 绍兴  ZJ_SX_312000  3306

export const getTradeCode = (tenantAreaCd: string) => {
  switch (tenantAreaCd) {
    case '3304':
      return 'ZJ_SX_MT_TSW'
    case '3309':
      return 'ZJ_ZS_DC_MT'
    case '3308':
      return 'ZJ_QZ_MT_MH'
    case '3301':
      return 'ZJ_HZ_310000'
    case '3307':
      return 'ZJ_JH_321000'
    case '3305':
      return 'ZJ_HZ_313000'
    case '3310':
      return 'ZJ_TZ_318000'
    case '3302':
      return 'ZJ_NB_315000'
    case '3311':
      return 'ZJ_LS_323000'
    case '3303':
      // return 'ZJ_WZ_325000'
      return 'ZJ_NB_315000'
    case '3306':
      return 'ZJ_SX_312000'
  }
}
export const testReadTypes = [
  {
    label: '电子凭证',
    value: 1,
  },
  {
    label: '身份证号码',
    value: 2,
  },
  {
    label: '医保卡',
    value: 3,
  },
]
export const readTypes = [
  {
    label: '电子凭证',
    value: 1,
  },
  //  {
  //    label: '身份证号码',
  //    value: 2,
  //  },
  {
    label: '医保卡',
    value: 3,
  },
]

export const medicalCategory = [
  { value: 1, label: '医师' },
  { value: 2, label: '护师' },
  { value: 3, label: '药师' },
  { value: 4, label: '医技人员' },
]
