/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 12:05:04
 * @LastEditors: linxi
 * @LastEditTime: 2024-09-11 09:44:51
 */
import { Col } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { selectMenus, selectTwoMenus } from '../../layouts/layoutSlice'
import { Commodity } from '../commodity/commodity'
import { DrugWarningList } from '../drugWarning/drugWarning'
import { Durgsystem } from '../durgsystem/durgsystem'
import { Examinetreat } from '../examinetreat/examinetreat'
import { SetMeal, SetMealRef } from '../setmeal/setmeal'
import { SupplierLibrary } from '../storehouse/SupplierLibrary/library'
import { WarehouseBusinessLibrary } from '../storehouse/WarehouseBusinessLibrary/library'
import { WarehouseManagementLibrary } from '../storehouse/WarehouseManagement/library'
import { WarehouseSearchLibrary } from '../storehouse/WarehouseSearchLibrary/library'
import styles from './Storagewordstation.module.css'

export const Storagewordstation = () => {
  const ref = useRef<SetMealRef>(null)

  const location: any = useLocation()

  const dispatch = useDispatch()

  const [defaultActivity, setDefaultActivity] = useState('')

  const twoMenus = useSelector(selectTwoMenus)

  const menuList = twoMenus?.filter((v) => {
    return v?.name == '库房工作站'
  })?.[0]

  const getKeys = (i: string) => {
    switch (i) {
      case '库房业务':
        return '1'
      case '库存管理':
        return '2'
      case '库房流水查询':
        return '3'
      case '药房管理':
        return '4'
      case '商品管理':
        return '5'
      case '诊疗管理':
        return '6'
      case '套餐管理':
        return '7'
      case '供应商管理':
        return '8'
      case '违规预警设置':
        return '9'
      default:
        return '-'
    }
  }

  useEffect(() => {
    const setoreTab = sessionStorage.getItem('StoragewordstationTabBar')
    setDefaultActivity(
      setoreTab
        ? setoreTab
        : location.state
        ? location.state.station
        : menuList?.subMenus?.length
        ? getKeys(menuList?.subMenus?.[0]?.name)
        : '1'
    )
  }, [menuList?.subMenus])

  return (
    <Col className={styles.container}>
      <TabBar
        activeKey={defaultActivity}
        destroyInactiveTabPane
        onChange={(e: string) => {
          setDefaultActivity(e)
          sessionStorage.setItem('StoragewordstationTabBar', e)
        }}
      >
        {menuList?.subMenus?.map((v: any) => {
          return (
            <>
              <TabPane key={getKeys(v?.name)} tab={v?.name}>
                {v?.name == '库房业务' ? (
                  <WarehouseBusinessLibrary parentId={v?.id} />
                ) : v?.name == '库存管理' ? (
                  <WarehouseManagementLibrary parentId={v?.id} />
                ) : v?.name == '库房流水查询' ? (
                  <WarehouseSearchLibrary parentId={v?.id} />
                ) : v?.name == '药房管理' ? (
                  <Durgsystem />
                ) : v?.name == '商品管理' ? (
                  <Commodity />
                ) : v?.name == '诊疗管理' ? (
                  <Examinetreat />
                ) : v?.name == '套餐管理' ? (
                  <SetMeal ref={ref} flag={1} />
                ) : v?.name == '供应商管理' ? (
                  <SupplierLibrary parentId={v?.id} />
                ) : v?.name == '违规预警设置' ? (
                  <DrugWarningList />
                ) : (
                  <></>
                )}
              </TabPane>
            </>
          )
        })}
        {/* <TabPane key='1' tab='库房业务'>
          <WarehouseBusinessLibrary />
        </TabPane>
        <TabPane key='2' tab='库存管理'>
          <WarehouseManagementLibrary />
        </TabPane>
        <TabPane key='3' tab='库房流水查询'>
          <WarehouseSearchLibrary />
        </TabPane>
        <TabPane key='4' tab='药房管理'>
          <Durgsystem />
        </TabPane>
        <TabPane key='5' tab='商品管理'>
          <Commodity />
        </TabPane>
        <TabPane key='6' tab='诊疗管理'>
          <Examinetreat />
        </TabPane>
        <TabPane key='7' tab='套餐管理'>
          <SetMeal ref={ref} flag={1} />
        </TabPane>
        <TabPane key='8' tab='供应商管理'>
          <SupplierLibrary />
        </TabPane> */}
      </TabBar>
    </Col>
  )
}
