/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-12 11:56:33
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface InsuranceSettlementRecordListPrams {
  current: number
  size: number
  startTime?: string
  endTime?: string
  psnName?: any
  xianzhonglx?: any
  yidibz?: any
}

// 列表
export function insuranceSettlementRecordList(
  params: InsuranceSettlementRecordListPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getInsuranceSettlementRecord`,
    method: 'GET',
    params,
  }
}
// 导出
export function insuranceSettlementRecordExport(
  params: InsuranceSettlementRecordListPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insurance/getInsuranceSettlementRecord/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
