/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-17 09:41:38
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-19 09:58:56
 */
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, notification } from 'antd';
import React, { ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch } from '../../../../../app/store';
import { EditableList } from '../../../../../compnents/list/EditableList';
import { ExternalprocessPrams } from '../../../../../services/toothPatient';
import { externalprocessListAsync, externalprocessRemoveAsync, historyProviderAsync, selectHistoryProvider, setHistoryProvider } from '../../patientSlice';
import { AddOutSideModal } from './addOutsideModal';
import { Columns } from './columns';
import { DetailModal } from './detailModal';
import styles from './outside.module.css'
import { Query } from './Query';
import { RelevanceDisposeModal } from './relevanceDisposeModal';

interface OutsideProps {
  patientId: string
}

export const Outside = ({ patientId }: OutsideProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()


  const [params, setParams] = useState<ExternalprocessPrams>({
    current: 0,
    size: 10,
    patientId
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [{ name: 1 }],
  })

  useEffect(() => {
    getHistoryProviderAsync()
  }, [])

  useEffect(() => {
    if (!params.current) return
    getPageData()
  }, [params])

  useEffect(() => {
    setParams({
      ...params,
      patientId
    })
  }, [patientId])

  const getHistoryProviderAsync = () => {
    dispatch(historyProviderAsync()).then(unwrapResult).then((res: any) => {
      dispatch(setHistoryProvider(res))
    })
  }

  const getPageData = () => {
    dispatch(externalprocessListAsync(params)).then(unwrapResult).then((res: any) => {
      setPage({
        items: res.records,
        total: res.total
      })
    })
  }

  const [currentId, setCurrentId] = useState('')

  const [DetailModalVisible, setDetailModalVisible] = useState(false)

  const [AddOutSideModalVisible, setAddOutSideModalVisible] = useState(false)

  return (
    <div className={styles.main}>
      <Button
        type='primary'
        className={styles.add}
        onClick={() => {
          setAddOutSideModalVisible(true)
          setCurrentId('')
        }}
      >
        新增外加工
      </Button>
      <Query
        onValueChange={(v) => {
          setParams({
            ...params,
            ...v,
          })
        }}
      />
      <EditableList
        bordered
        scroll={{ x: 1300 }}
        className={styles.table}
        page={{
          current: params.current,
          size: params.size,
          total: page.total,
          items: page.items,
        }}
        columns={Columns(params.size * (params.current - 1), (action, t) => {
          if (action == 'detail') {
            setCurrentId(t.id)
            setDetailModalVisible(true)
          } else if (action == 'delete') {
            dispatch(externalprocessRemoveAsync(t.id))
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '删除成功',
                })
                getPageData()
              })
          } else if (action == 'edit') {
            setCurrentId(t.id)
            setAddOutSideModalVisible(true)
          }
        })}
        onChangePage={(current, size) =>
          setParams({
            ...params,
            current,
            size: size || 10,
          })
        }
      />
      <DetailModal
        id={currentId}
        patientId={patientId}
        visible={DetailModalVisible}
        onCancel={() => {
          setDetailModalVisible(false)
          setCurrentId('')
        }}
      />

      <AddOutSideModal
        visible={AddOutSideModalVisible}
        id={currentId}
        onCancel={() => {
          setAddOutSideModalVisible(false)
          setCurrentId('')
        }}
        onOk={() => {
          getPageData()
          setAddOutSideModalVisible(false)
          setCurrentId('')
        }}
        patientId={patientId}
      />
    </div>
  )
}
