/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-31 14:23:29
 * @LastEditors: linxi
 * @LastEditTime: 2021-06-24 19:21:28
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { send } from "node:process";
import { api, sendAsync } from "../../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../../app/store";
import { AddtionDrugParams, getAddtionDrugData, getAddtionDrugPage, getModalDurgData, ModalDurgParams, removeDurg, saveAllDurg, SaveAllDurgBody, saveDurgbatch } from "../../../../services/addtionDrug";
interface AddtionDrug {
    addtionHead: any,
    addtionDrug: [],
    addtionTotal: 0,
    addtionCurrent: 1,
    modalDurgData: [],
    modalDurgTotal: 0,
    modalDurgCurrent: 1,
}

const initialState: AddtionDrug = {
    addtionHead: {},
    addtionDrug: [],
    addtionTotal: 0,
    addtionCurrent: 1,
    modalDurgData: [],
    modalDurgTotal: 0,
    modalDurgCurrent: 1,
};

const addtionDrugSlice = createSlice({
    name: "addtionDrugSliceSlice",
    initialState,
    reducers: {
        setAddtionHead(state, action) {
            state.addtionHead = action.payload
        },
        setAddtionData(state, action) {
            state.addtionDrug = action.payload
        },
        setAddtionTotal(state, action) {
            state.addtionTotal = action.payload
        },
        setAddtionCurrent(state, action) {
            state.addtionCurrent = action.payload
        },
        setModalData(state, action) {
            state.modalDurgData = action.payload
        },
        setModalTotal(state, action) {
            state.modalDurgTotal = action.payload
        },
        setModalCurrent(state, action) {
            state.modalDurgCurrent = action.payload
        },
    },
});

export const { setAddtionHead, setAddtionData, setAddtionTotal, setAddtionCurrent, setModalCurrent, setModalTotal, setModalData } = addtionDrugSlice.actions;


export const selectAddtionDrugHead = (state: RootState) => state.addtionDrug.addtionHead;

export const selectAddtionDrugData = (state: RootState) => state.addtionDrug.addtionDrug;

export const selectAddtionTotal = (state: RootState) => state.addtionDrug.addtionTotal

export const selectAddtionCurrent = (state: RootState) => state.addtionDrug.addtionCurrent

export const selectModalData = (state: RootState) => state.addtionDrug.modalDurgData

export const selectModalTotal = (state: RootState) => state.addtionDrug.modalDurgTotal

export const selectModalCurrent = (state: RootState) => state.addtionDrug.modalDurgCurrent

export function getAddtionDrugList(params: AddtionDrugParams): RootThunk {
    return api(getAddtionDrugPage(params), (data: any, dispatch) => {
        dispatch(setAddtionHead(data.maintain))
        dispatch(setAddtionData(data.maintainItems))
        dispatch(setAddtionTotal(data.total))
    });
}

export const getAddtionDrugListAsync = createAsyncThunk<
    void, AddtionDrugParams, RootThunkApi<void>
>("addtion/remove", async (params, api) => {
    return sendAsync(getAddtionDrugPage(params), api, true)
})

export function getAddtionDrug(params: AddtionDrugParams): RootThunk {
    return api(getAddtionDrugData(params), (data: any, dispatch) => {
        dispatch(setAddtionData(data.records))
        dispatch(setAddtionTotal(data.total))
    });
}

export function getModalDurg(params: ModalDurgParams): RootThunk {
    return api(getModalDurgData(params), (data: any, dispatch) => {
        dispatch(setModalData(data.records))
        dispatch(setModalTotal(data.total))
    })
}

export const SaveAllDurgAsync = createAsyncThunk<
    void, SaveAllDurgBody, RootThunkApi<void>
>("addtion/SaveAlldurg", async (body, api) => {
    return sendAsync(saveAllDurg(body), api)
})

export const removeDurgAsync = createAsyncThunk<
    void, string, RootThunkApi<void>
>("addtion/remove", async (ids, api) => {
    return sendAsync(removeDurg(ids), api, true)
})

export const submitDurgAsync = createAsyncThunk<
    void, Array<any>, RootThunkApi<void>
>("addtion/SubmitDurg", async (ids, api) => {
    return sendAsync(saveDurgbatch(ids), api)
})

export default addtionDrugSlice.reducer;
