/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:48:24
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-24 09:22:19
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

//右侧详情
export function clinicDetail(): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/middleGround/detail4middleground`,
    method: 'GET',
  }
}

export interface ClinicSubmitParams {
  appointmentBaseSettingsVO: any
  tenantDetail: any
}

// 保存
export function clinicSubmit(body: ClinicSubmitParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/middleGround/save4Middleground`,
    method: 'POST',
    body,
  }
}


export interface StateTagParams {
  current: any
  size: any
}

// 保存
export function stateTag(body: StateTagParams): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentchannel/list`,
    method: 'GET',
    body,
  }
}
