/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-18 10:28:01
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../../app/store'
import { Page, pageOf, startPageOf } from '../../../models/page'
import * as P from '../../../models/patient'
import { Patient } from '../../../models/patient'
import * as L from '../../../models/patientlable'
import { Patientlable } from '../../../models/patientlable'
import * as SP from '../../../services/patient'
import { GetPatientListParams } from '../../../services/patient'
import * as SL from '../../../services/patientlable'

interface PatientListState {
  page: Page<Patient>
}

const initialState: PatientListState = {
  page: startPageOf(),
}

export const getPatientList = createAsyncThunk<
  Page<Patient>,
  GetPatientListParams,
  RootThunkApi<Page<Patient>>
>('patientList/getPatientListStatus', async (params, api) => {
  return sendAsync(SP.getNewPatientList(params), api).then((data) => {
    return pageOf(
      data,
      _.chain(data.records)
        .map((p) => P.fromJson(p))
        .value()
    )
  })
})

export const getPatientLabels = createAsyncThunk<
  Patientlable[],
  SL.PatientTableParams,
  RootThunkApi<Patientlable[]>
>('patientList/getPatientLabels', async (params, api) => {
  return sendAsync(SL.getLableList(params), api).then((data) =>
    _.chain(data.records)
      .map((l) => L.fromJson(l))
      .value()
  )
})

export const mergePatientAsync = createAsyncThunk<
  void,
  SL.mergePatientParams,
  RootThunkApi
>('patientList/mergePatientAsync', async (body, api) => {
  return sendAsync(SL.mergePatient(body), api)
})

const patientListSlice = createSlice({
  name: 'patientList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPatientList.fulfilled, (state, action) => {
      state.page = action.payload
    })
  },
})

// 患者导入 excel
export const getpatientsExcel = createAsyncThunk<
  void,
  SL.ImportExcelParams,
  RootThunkApi
>('/commodity/getpatientsExcel', async (body, api) => {
  return sendAsync(SL.getpatientsExcelFn(body), api)
})
export const selectPatientPage = (state: RootState): Page<Patient> =>
  state.patientList.page

export default patientListSlice.reducer
