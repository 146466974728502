/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-27 15:41:19
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { api } from "../../../app/applicationSlice";
import { RootState, RootThunk } from "../../../app/store";
import { RecevingParams } from "../../../services/receving";
import * as service from "../../../services/receving";
interface Receving {
  Receving: [],
  Total: 0,
  Current: 1,
  pageLoading: false,
}

const initialState: Receving = {
  Receving: [],
  Total: 0,

  Current: 1,
  pageLoading: false,
};

const recevingSlice = createSlice({
  name: "recevingSlice",
  initialState,
  reducers: {
    setData(state, action) {
      state.Receving = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
  },
});

export const { setData, 
  setTotal,
  setCurrent, setPageLoading } = recevingSlice.actions;

export const selectRecevingData = (state: RootState) =>
  state.receving.Receving;
  export const selectTotal = (state: RootState) => state.receving.Total
  
  export const selectCurrent = (state: RootState) => state.receving.Current

export const selectPageLoading = (state: RootState) => state.receving.pageLoading
  
  


export function getReceiveList(params: RecevingParams): RootThunk {
  return api(service.getReceive(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  });
}


export default recevingSlice.reducer;
