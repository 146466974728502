/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-16 17:11:33
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-17 17:38:37
 */
import { Form, Row, Col, Input, Select, DatePicker } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import styles from './PurchaseAcceptanceList.module.css'
import { useDispatch, useSelector } from 'react-redux'

import cnchar from 'cnchar'
import {
  BillingCategoryName,
  InvoiceBillingCategories,
} from '../../models/billingCategory'
import { getOtcCategoryName, OtcCategories } from '../../models/Otccategory'
import { useHistory } from 'react-router-dom'
import { NavBar } from '../../compnents/nav/NavBar'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { RecipeItemFieldInput } from '../treatment/editor/components/RecipeItemFieldInput'
import { useQuery } from '../../utils/HttpUtils'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
import {
  getDepartment,
  sleectUserData,
} from '../putstorelist/putstorelistSlice'
import { User } from '../../models/user'
import moment from 'moment'
import { purchaseAcceptanceDetailAsync } from './PurchaseAcceptanceSlice'
import { setDetail } from '../durgsystem/durgsystemSlice'
const { Option } = Select
const { TextArea } = Input
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const PurchaseAcceptanceDetail = (): ReactElement => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const query = useQuery()

  const [form] = Form.useForm()

  const userData = useSelector(sleectUserData)

  const [detail, setDetail] = useState<any>()

  const colProps = { span: 8 }

  const wideColumnLayout = {
    labelCol: { flex: '6.5rem' },
    wrapperCol: { flex: 1 },
  }

  useEffect(() => {
    dispatch(getDepartment({ current: 1, size: 1000, state: 1 }))
  }, [])

  useEffect(() => {
    if (query.get('id')) {
      dispatch(purchaseAcceptanceDetailAsync(query.get('id')))
        .then(unwrapResult)
        .then((v) => {
          setDetail(v)
        })
    }
  }, [query.get('id')])

  useEffect(() => {
    if (JSON.stringify(detail) !== '{}') {
      form.setFieldsValue({
        ...detail?.materialsCatalog,
        ...detail?.tenantInventoryPurchaseAcceptance,
        arrivalUnitPrice: Number(
          detail?.tenantInventoryPurchaseAcceptance?.arrivalUnitPrice
        )?.toFixed(5),
        totalReceiptAmount: Number(
          detail?.tenantInventoryPurchaseAcceptance?.totalReceiptAmount
        )?.toFixed(2),
        createTime: moment(
          detail?.tenantInventoryPurchaseAcceptance?.createTime
        ),

        productDate: moment(
          detail?.tenantInventoryPurchaseAcceptance?.productDate
        ),
        deadline: moment(detail?.tenantInventoryPurchaseAcceptance?.deadline),
        qualityStatus: '完好',
        packagingQuality: '合格',
        transportationStatus: '运输正常',
        acceptanceConclusion: '质量合格，同意',
        defectiveQuantity: 0,
      })
    }
  }, [detail])

  return (
    <>
      <Col
        style={{
          margin: '10px 20px',
        }}
      >
        <NavBar
          where={['库房流水查询', '采购验收记录详情']}
          backtrace={{
            name: '采购验收记录',
            path: '/purchaseAcceptance',
            state: {
              catgetory: '16',
            },
          }}
        />
      </Col>
      <div className={styles.saveform}>
        <Form {...layout} form={form} name='control-hooks' autoComplete='off'>
          <div className={styles.title}>
            <p className={styles.titletext}>业务信息</p>
          </div>
          <Row>
            <Col {...colProps} span={8}>
              <Form.Item
                name='arrivalOrderNumber'
                label='到货单号'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='createTime'
                label='接收时间'
                {...wideColumnLayout}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={DateTimeFormatSimple}
                  showTime
                  disabled
                />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8} {...wideColumnLayout}>
              <Form.Item name='operateUserName' label='操作人'>
                {/* <Select showSearch placeholder='操作人' allowClear disabled>
                  {userData.map((v: User) => {
                    return (
                      <Option value={v.id} key={v.id}>
                        {v.name}
                      </Option>
                    )
                  })}
                </Select> */}
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.title}>
            <p className={styles.titletext}>商品信息</p>
          </div>
          <Row>
            <Col {...colProps} span={8}>
              <Form.Item name='no' label='商品编码' {...wideColumnLayout}>
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item name='name' label='商品名称' {...wideColumnLayout}>
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='genericName'
                label='通用名'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='barCode'
                label='条形码'
                rules={[{ max: 35, message: '最长35位!' }]}
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='billingCategory'
                label='开票项目'
                {...wideColumnLayout}
              >
                <Select placeholder='开票项目' allowClear disabled>
                  {InvoiceBillingCategories.map((v) => {
                    return (
                      <Option value={v} key={v}>
                        {BillingCategoryName(v)}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='compoundTag'
                label='复方标志'
                {...wideColumnLayout}
              >
                <Select disabled placeholder='复方标志' allowClear>
                  <Option value={0}>非草药</Option>
                  <Option value={1}>单味</Option>
                  <Option value={2}>复方</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item name='otc' label='OTC分类' {...wideColumnLayout}>
                <Select placeholder='OTC分类' allowClear disabled>
                  {OtcCategories.map((v) => {
                    return (
                      <Option value={v} key={v}>
                        {getOtcCategoryName(v)}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item name='packSpec' label='规格' {...wideColumnLayout}>
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item name='doseCategory' label='剂型' {...wideColumnLayout}>
                <RecipeItemFieldInput
                  field={'doseCategory'}
                  disabled={true}
                  noSize={true}
                />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='accountUnit'
                label='账簿单位'
                {...wideColumnLayout}
              >
                <RecipeItemFieldInput
                  field={'accountUnit'}
                  disabled={true}
                  noSize={true}
                />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item name='origin' label='产地' {...wideColumnLayout}>
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item name='batchNo' label='批号' {...wideColumnLayout}>
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={16}>
              <Form.Item
                name='tenantSupplierName'
                label='生产厂家'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='approvalNumber'
                label='批准文号'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='arrivalUnitPrice'
                label='到货单价'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='totalQuantity'
                label='总收货数量'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='totalReceiptAmount'
                label='总收货金额'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='productDate'
                label='生产日期'
                {...wideColumnLayout}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={DateTimeFormatSimple}
                  showTime
                  disabled
                />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item name='deadline' label='有效期' {...wideColumnLayout}>
                <DatePicker
                  style={{ width: '100%' }}
                  format={DateTimeFormatSimple}
                  showTime
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.title}>
            <p className={styles.titletext}>合格记录</p>
          </div>
          <Row gutter={21}>
            <Col {...colProps} span={8}>
              <Form.Item
                name='qualifiedQuantity'
                label='合格数量'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='qualityStatus'
                label='质量状态'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='packagingQuality'
                label='包装质量'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='transportationStatus'
                label='运输状态'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={16}>
              <Form.Item
                name='acceptanceConclusion'
                label='验收结论'
                {...wideColumnLayout}
              >
                <Input size='middle' allowClear disabled />
              </Form.Item>
            </Col>
          </Row>

          <div className={styles.title}>
            <p className={styles.titletext}>不合格记录及处置措施</p>
          </div>
          <Row>
            <Col {...colProps} span={8}>
              <Form.Item
                name='defectiveQuantity'
                label='不合格数量'
                {...wideColumnLayout}
              >
                <Input size='middle' disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='nonconformityIssues'
                label='不合格事项'
                {...wideColumnLayout}
              >
                <Input size='middle' disabled />
              </Form.Item>
            </Col>
            <Col {...colProps} span={8}>
              <Form.Item
                name='disposalMeasures'
                label='处置措施'
                {...wideColumnLayout}
              >
                <Input size='middle' disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}
