/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: K
 * @LastEditTime: 2022-10-13 09:21:58
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../../app/applicationSlice'
import { RootThunkApi } from '../../../app/store'
import { fromJson, Patientlable } from '../../../models/patientlable'
import * as S from '../../../services/patientlable'
import {getPatientDetailFnParams, PatientTableParams} from '../../../services/patientlable'

export const getPatientLabelList = createAsyncThunk<
  Patientlable[],
  string,
  RootThunkApi
>('patientDetail/getPatientLabel', async (params, api) => {
  return sendAsync(S.getPatientLabelList(params), api).then((data) =>
    _.chain(data.records)
      .map((l) => fromJson(l))
      .value()
  )
})

// 获取详情
export const getPatientDetail = createAsyncThunk<
  any,
  getPatientDetailFnParams,
  RootThunkApi
>('patientDetail/getPatientDetail', async (params, api) => {
  return sendAsync(S.getPatientDetailFn(params), api)
})

export const getLabelList = createAsyncThunk<
  Patientlable[],
  PatientTableParams,
  RootThunkApi
>('patientDetail/getLabelList', async (_1, api) => {
  return sendAsync(
    S.getLableList(_1),
    api
  ).then((data) =>
    _.chain(data.records)
      .map((l) => fromJson(l))
      .value()
  )
})

export const setPatientLabel = createAsyncThunk<
  void,
  S.LabelPatientParams,
  RootThunkApi
>('patientDetail/setPatientLabel', async (params, api) => {
  return sendAsync(S.labelPatient(params), api)
})

export const getPatientDetailInfoAsync = createAsyncThunk<
  void,
  S.PatientDetailInfoParams,
  RootThunkApi
>('patientDetail/getPatientDetailInfoAsync', async (params, api) => {
  return sendAsync(S.getPatientDetailInfo(params), api)
})
