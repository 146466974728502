/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 1373842098@qq.com
 * @Date: 2022-05-16 10:52:46
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-18 11:26:56
 */
import { TableColumnType } from 'antd'
import React from 'react'
import { BillingCategoryName } from '../../../models/billingCategory'


export const headerColumns = (v: any, d: any): TableColumnType<any>[] => {
  if (d.length > 0) {
    const cols: TableColumnType<any>[] = [
      {
        title: '险种',
        dataIndex: 'insuranceName',
        align: 'center',
        key: 'insuranceName',
      },
      {
        title: '总费用',
        dataIndex: 'totalPayAmount',
        align: 'right',
        key: 'totalPayAmount',
        render: (_, r: any) =>
          r?.totalPayAmount ? r?.totalPayAmount?.toFixed(2) : '0.00',
      },
      {
        title: '应收总现金',
        dataIndex: 'totalShouldPayAmount',
        align: 'right',
        key: 'totalShouldPayAmount',
        render: (_, r: any) =>
          r?.totalShouldPayAmount
            ? r?.totalShouldPayAmount?.toFixed(2)
            : '0.00',
      },
      {
        title: '实收金额',
        dataIndex: 'totalRealPayAmount',
        align: 'right',
        key: 'totalRealPayAmount',
        render: (_, r: any) =>
          r?.totalRealPayAmount ? r?.totalRealPayAmount?.toFixed(2) : '0.00',
      },
      {
        title: '医保报销总金额',
        dataIndex: 'totalInsurancePayAmount',
        align: 'right',
        key: 'totalInsurancePayAmount',
        render: (_, r: any) =>
          r?.totalInsurancePayAmount
            ? r?.totalInsurancePayAmount?.toFixed(2)
            : '0.00',
      },
      {
        title: '基金金额',
        dataIndex: 'totalJijin',
        align: 'right',
        key: 'totalJijin',
        render: (_, r: any) =>
          r?.totalJijin
            ? r?.totalJijin?.toFixed(2)
            : '0.00',
      },
      {
        title: '个账金额',
        dataIndex: 'totalGezhang',
        align: 'right',
        key: 'totalGezhang',
        render: (_, r: any) =>
          r?.totalGezhang
            ? r?.totalGezhang?.toFixed(2)
            : '0.00',
      },
      {
        title: '聚合支付',
        dataIndex: 'aggrPay',
        align: 'right',
        key: 'aggrPay',
        render: (_, r: any) => (r?.aggrPay ? r?.aggrPay?.toFixed(2) : '0.00'),
      },
      {
        title: '支付宝',
        dataIndex: 'alipay',
        align: 'right',
        key: 'alipay',
        render: (_, r: any) => (r?.alipay ? r?.alipay?.toFixed(2) : '0.00'),
      },
      {
        title: '会员卡',
        dataIndex: 'memberPay',
        align: 'right',
        key: 'memberPay',
        render: (_, r: any) =>
          r?.memberPay ? r?.memberPay?.toFixed(2) : '0.00',
      },
      {
        title: '微信',
        dataIndex: 'wxPay',
        align: 'right',
        key: 'wxPay',
        render: (_, r: any) => (r?.wxPay ? r?.wxPay?.toFixed(2) : '0.00'),
      },
      {
        title: '银联',
        dataIndex: 'unionPay',
        align: 'right',
        key: 'unionPay',
        render: (_, r: any) => (r?.unionPay ? r?.unionPay?.toFixed(2) : '0.00'),
      },
      {
        title: '现金',
        dataIndex: 'cash',
        align: 'right',
        key: 'cash',
        render: (_, r: any) => (r?.cash ? r?.cash?.toFixed(2) : '0.00'),
      },
      {
        title: '其他',
        dataIndex: 'otherPay',
        align: 'right',
        key: 'otherPay',
        render: (_, r: any) => (r?.otherPay ? r?.otherPay?.toFixed(2) : '0.00'),
      },
    ]

    const customizePayList = v?.[0]?.customizePay
      ?.map((k: any) => {
        return { ...k }
      })
      ?.sort((a: any, b: any) => a.payMethodName.localeCompare(b.payMethodName))
    //首先判断第一行的自定义收费有没有结构（后台每一行的自定义收费结构体是一样的，如果没有 就都没有，如果有 就每一行都有）
    if (customizePayList?.length > 0) {
      const iv = customizePayList
      for (let i = 0; i < iv.length; i++)
        cols.push({
          title: iv[i]?.payMethodName,
          dataIndex: 'otherPay_' + i,
          align: 'right',
          key: 'otherPay_' + i,
          render: (_, r: any) => {
            const value = r?.customizePay.filter(
              (vv: any) => vv.payMethodName == iv[i]?.payMethodName
            )[0]?.payAmount
            return Number(value)?.toFixed(2)
          },
        })
    }
    return cols
  } else {
    const cols: TableColumnType<any>[] = [
      {
        title: '险种',
        dataIndex: 'insuranceName',
        align: 'center',
        key: 'insuranceName',
      },
      {
        title: '总费用',
        dataIndex: 'totalPayAmount',
        align: 'right',
        key: 'totalPayAmount',
        render: (_, r: any) =>
          r?.totalPayAmount ? r?.totalPayAmount?.toFixed(2) : '0.00',
      },
      {
        title: '应收总现金',
        dataIndex: 'totalShouldPayAmount',
        align: 'right',
        key: 'totalShouldPayAmount',
        render: (_, r: any) =>
          r?.totalShouldPayAmount
            ? r?.totalShouldPayAmount?.toFixed(2)
            : '0.00',
      },
      {
        title: '实收金额',
        dataIndex: 'totalRealPayAmount',
        align: 'right',
        key: 'totalRealPayAmount',
        render: (_, r: any) =>
          r?.totalRealPayAmount ? r?.totalRealPayAmount?.toFixed(2) : '0.00',
      },
      {
        title: '医保报销总金额',
        dataIndex: 'totalInsurancePayAmount',
        align: 'right',
        key: 'totalInsurancePayAmount',
        render: (_, r: any) =>
          r?.totalInsurancePayAmount
            ? r?.totalInsurancePayAmount?.toFixed(2)
            : '0.00',
      },

      {
        title: '聚合支付',
        dataIndex: 'aggrPay',
        align: 'right',
        key: 'aggrPay',
        render: (_, r: any) => (r?.aggrPay ? r?.aggrPay?.toFixed(2) : '0.00'),
      },
      {
        title: '支付宝',
        dataIndex: 'alipay',
        align: 'right',
        key: 'alipay',
        render: (_, r: any) => (r?.alipay ? r?.alipay?.toFixed(2) : '0.00'),
      },
      {
        title: '会员卡',
        dataIndex: 'memberPay',
        align: 'right',
        key: 'memberPay',
        render: (_, r: any) =>
          r?.memberPay ? r?.memberPay?.toFixed(2) : '0.00',
      },
      {
        title: '微信',
        dataIndex: 'wxPay',
        align: 'right',
        key: 'wxPay',
        render: (_, r: any) => (r?.wxPay ? r?.wxPay?.toFixed(2) : '0.00'),
      },
      {
        title: '银联',
        dataIndex: 'unionPay',
        align: 'right',
        key: 'unionPay',
        render: (_, r: any) => (r?.unionPay ? r?.unionPay?.toFixed(2) : '0.00'),
      },
      {
        title: '现金',
        dataIndex: 'cash',
        align: 'right',
        key: 'cash',
        render: (_, r: any) => (r?.cash ? r?.cash?.toFixed(2) : '0.00'),
      },
      {
        title: '其他',
        dataIndex: 'otherPay',
        align: 'right',
        key: 'otherPay',
        render: (_, r: any) => (r?.otherPay ? r?.otherPay?.toFixed(2) : '0.00'),
      },
    ]

    const customizePayList = v?.[0]?.customizePay
      ?.map((k: any) => {
        return { ...k }
      })
      ?.sort((a: any, b: any) => a.payMethodName.localeCompare(b.payMethodName))
    //首先判断第一行的自定义收费有没有结构（后台每一行的自定义收费结构体是一样的，如果没有 就都没有，如果有 就每一行都有）
    if (customizePayList?.length > 0) {
      const iv = customizePayList
      for (let i = 0; i < iv.length; i++)
        cols.push({
          title: iv[i]?.payMethodName,
          dataIndex: 'otherPay_' + i,
          align: 'right',
          key: 'otherPay_' + i,
          render: (_, r: any) => {
            const value = r?.customizePay.filter(
              (vv: any) => vv.payMethodName == iv[i]?.payMethodName
            )[0]?.payAmount
            return Number(value)?.toFixed(2)
          },
        })
    }
    return cols
  }
}

export const priceColumns = (): TableColumnType<any>[] => {
  return [
    {
      title: '收费项目',
      key: 'billingCategory',
      align: 'center',
      render: (_, r: any) => {
        return BillingCategoryName(r?.[0]?.billingCategory)
      },
    },
    {
      title: '应收金额',
      dataIndex: 'amount',
      align: 'right',
      key: 'amount',
      render: (_, r: any) => {
        return r?.[0]?.amount ? r?.[0]?.amount?.toFixed(2) : '0.00'
      },
    },
    {
      title: '利润',
      dataIndex: 'profitAmount',
      align: 'right',
      key: '',
      render: (_, r: any) => {
        return r?.[0].billingCategory == '01' ||
          r?.[0].billingCategory == '02' ||
          r?.[0].billingCategory == '03' ||
          r?.[0].billingCategory == '17'
          ? r?.[0]?.profitAmount
          : ''
      },
    },
    {
      title: '收费项目',
      key: 'billingCategory',
      align: 'center',
      render: (_, r: any) => {
        if (r.length > 1) return BillingCategoryName(r?.[1]?.billingCategory)
        return ''
      },
    },
    {
      title: '应收金额',
      dataIndex: 'amount',
      align: 'right',
      key: 'amount',
      render: (_, r: any) => {
        if (r.length > 1) {
          return r?.[1]?.amount ? r?.[1]?.amount?.toFixed(2) : '0.00'
        }
      },
    },
    {
      title: '利润',
      dataIndex: 'profitAmount',
      align: 'right',
      key: '',
      render: (_, r: any) => {
        return r?.[1]?.billingCategory == '01' ||
          r?.[1]?.billingCategory == '02' ||
          r?.[1]?.billingCategory == '03' ||
          r?.[1]?.billingCategory == '17'
          ? r?.[1]?.profitAmount
          : ''
      },
    },
  ]
}


