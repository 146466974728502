/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-08-01 14:20:06
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface ToBeCompletedOperationParams {
  current?: number
  size?: number
  flag: number
  startTime?: any
  endTime?: any
  patientId?: string
}

export function ToBeCompletedOperationList(
  params: ToBeCompletedOperationParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/operatingbill/page`,
    method: 'GET',
    params,
  }
}

export interface ExcuteDetailListParams {
  id?: any
}

export function ExcuteDetailList(params: ExcuteDetailListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/operatingbill/detail`,
    method: 'GET',
    params,
  }
}
export interface CancelOperationParams {
  id?: any
}

export function CancelOperation(body: CancelOperationParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/operatingbill/logout`,
    method: 'POST',
    body,
  }
}
export interface OperationSubmitParams {
  id?: any
}

export function OperationSubmit(body: OperationSubmitParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/operatingbill/submit`,
    method: 'POST',
    body,
  }
}
