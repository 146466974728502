import moment from 'moment'
import { DateTimeFormatSimpleOnlyOne } from './datetime'

//欠费管理打印
export function oweMonePagePrintBody(data: any) {
  const userName = data?.userName
  const tenantName = data?.tenantName
  const datas = data?.dataList
  let sjDatas = ''
  let sumArrearageAmount = 0
  const date = new Date()

  for (let i = 0; i < datas?.length; i++) {
    sjDatas +=
      '<div style="font-size: 8px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].outpatientNo +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].patientName +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].patientId +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].patientSex +
      '            </div>' +
      '             <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].patientAge +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].insuranceCode +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].settlementCategory +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].disease +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].treatmentDepartmentName +
      '            </div>' +
      '           <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:6%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].treatmentDoctorName +
      '           </div>' +
      '           <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].accountingStatus +
      '           </div>' +
      '           <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '              ' +
      datas[i].registrationTime +
      '           </div>' +
      '           <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:7%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '              ' +
      datas[i].arrearageAmount +
      '           </div>' +
      '           <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box">' +
      '              ' +
      datas[i].lastPayTime +
      '           </div>' +
      '        </div>'
    sumArrearageAmount += Number(datas[i].arrearageAmount)
  }

  const content =
    '<div style="margin-left: 10px;margin-right: 10px">' +
    '    <h5 style="text-align: center;margin-bottom: 5px;">' +
    tenantName +
    '</h5>' +
    '    <h6 style="text-align: center;margin-top: 5px;margin-bottom: 5px;">欠&nbsp;&nbsp;费&nbsp;&nbsp;管&nbsp;&nbsp;理</h6>' +
    '    <div style="font-size:8px;display:table;width:100%;align-items:center;clear: left;border: 1px solid #000">' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            门诊号' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           患者姓名' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            病历号' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            性别' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           年龄' +
    '       </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           结算险种' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            收费标志' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width:8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           诊断疾病' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            接诊科室' +
    '        </div>' +
    '       <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           接诊医生' +
    '       </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 7%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            欠费状态' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            挂号时间' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width:7%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            欠费金额' +
    '        </div>' +
    '        <div style="display: table-cell;vertical-align: middle;word-break:break-all;width: 8%;text-align:center;">' +
    '            最近补交时间' +
    '        </div>' +
    '    </div>' +
    sjDatas +
    '      <div style="font-size: 8px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 84%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                合计：' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 7%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    (sumArrearageAmount ? sumArrearageAmount?.toFixed(2) : '0.00') +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 8%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box">' +
    '                ' +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '</div>'

  return content
}
