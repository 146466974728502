/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-04 10:50:48
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/InsuranceSettlementRecord'

// 列表
export const getInsuranceSettlementRecordList = createAsyncThunk<
  void,
  DT.InsuranceSettlementRecordListPrams,
  RootThunkApi<void>
>('clinic/getInsuranceSettlementRecordList', async (params, api) => {
  return sendAsync(DT.insuranceSettlementRecordList(params), api)
})

// 导出
export const getInsuranceSettlementRecordExport = createAsyncThunk<
  void,
  DT.InsuranceSettlementRecordListPrams,
  RootThunkApi<void>
>('clinic/getInsuranceSettlementRecordExport', async (params, api) => {
  return sendAsync(DT.insuranceSettlementRecordExport(params), api)
})
