/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-03 13:53:04
 */
import { Button, Row, Form, Space, Input } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useState } from 'react'
import {} from 'antd'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { FieldData } from '../../../models/fielddata'
import { PatientTableParams } from '../../../services/patientlable'
export const LableQuery = (props: {
  onValueChange: (queries: PatientTableParams) => void
  onAdd: () => void
  onSetting: () => void
}): ReactElement => {
  const dispatch = useDispatch()
  const { tenantId } = useParams<{ tenantId: any }>()
  const [form] = Form.useForm()
  const [values, setValues] = useState('')

  const [fields, setFields] = useState<FieldData[]>()

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFieldsChange={(_, fs) => {
          props.onValueChange(form.getFieldsValue())
          setFields(fs)
        }}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='name' noStyle>
              <Input
                autoComplete='off'
                placeholder='请输入标签名称'
                style={{ width: 500 }}
                onChange={(a: any) => setValues(a)}
                value={values}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type='primary'
              onClick={() => props.onSetting()}
            >
              自动标签设置
            </Button>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => props.onAdd()}
            >
              新增
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
