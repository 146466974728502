import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTenantId } from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { Material } from '../../models/material'
import { ThemeContext } from '../../theme/ThemeContext'
import { isSameName } from '../../utils/fieldsUtils'
import {
  pageExamineAsync,
  setData as setExaminData,
} from '../examinetreat/examinetreatSlice'
import { RecipeItemFieldInput } from '../treatment/editor/components/RecipeItemFieldInput'
import { getSettingDetail, selectSettings } from '../user/setting/settingSlice'
import { Checks, Settings } from '../../models/setting'
import { RecipeItemInput } from './componment/RecipeItemInput'
import styles from './Setmeal.module.css'
import {
  getsetmealDurgDetail,
  selectDurgId,
  selectSetmealData,
  selectSetmealDurgDetail,
  selectSource,
  setSetmealDurgId,
  submitSetmealDurgAsync,
} from './setmealSlice'
import { selectBooleanSetting } from '../settings/settingsSlice'
const { Option } = Select
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}

export const SetmealDurgModal = (props: {
  tenantGoodsComboId: number
  isModalDurgVisible: boolean
  maxSort: number
  maxGroupNumber: number
  onOk: () => void
  onCancel: () => void
  flag?: number
  category?: any
}): ReactElement => {
  const [form] = Form.useForm()

  const theme = useContext(ThemeContext)

  const countref = useRef<Input>(null)

  const doesref = useRef<Input>(null)

  const accountref = useRef<Input>(null)

  const doesunitref = useRef<Input>(null)

  const methodref = useRef<Input>(null)

  const quencyref = useRef<Input>(null)

  const retailref = useRef<Input>(null)

  const buttonRef = useRef<HTMLElement>(null)

  const settings = useSelector(selectSettings)

  const dispatch = useDispatch<RootDispatch>()

  const [setmealTitle, setSetmealTitle] = useState('新增')

  const [setmealcategory, setsetmealcategory] = useState('药品')

  const [catalogId, setCatalogId] = useState(0)

  const [isSplitModalVisible, setIsSplitModalVisible] = useState(false) //拆零弹窗

  const [material, setMaterial] = useState<Material | undefined>()

  const [ExamineData, setExamineData] = useState([])

  const [showFlagChecked, setShowFlagChecked] = useState(false)

  const Source = useSelector(selectSource)

  const DurgId = useSelector(selectDurgId)

  const tenantId = useSelector(selectTenantId)

  const detail: any = useSelector(selectSetmealDurgDetail)

  useEffect(() => {
    if (props?.isModalDurgVisible) {
      dispatch(getSettingDetail())
    }
  }, [props?.isModalDurgVisible])

  useEffect(() => {
    if (DurgId) {
      setSetmealTitle('修改')
      dispatch(getsetmealDurgDetail(DurgId))
    } else {
      setSetmealTitle('新增')
      form.resetFields()
    }
  }, [DurgId])

  useEffect(() => {
    if (!Source) {
      setsetmealcategory('药品')
    } else {
      setsetmealcategory('诊疗')
    }
  }, [Source])

  useEffect(() => {
    if (props?.isModalDurgVisible) {
      setShowFlagChecked(settings[Checks.Lkcky] ? false : true)
    }
  }, [props?.isModalDurgVisible])

  useEffect(() => {
    if (JSON.stringify(detail) !== '{}') {
      setCatalogId(detail.catalogId)
      form.setFieldsValue({
        ...detail,
        name: detail.name,
        count: detail?.count || 1,
        accountUnit: detail.accountUnit,
        dose: detail.dose,
        doseUnit: detail.doseUnit,
        packSpec: detail.packSpec,
        normalUsemethod: detail.normalUsemethod,
        normalFrequency: detail.normalFrequency,
        retailPrice: detail.retailPrice,
        flag: detail.retailPrice,
      })
      //  countref?.current?.select()
      //  countref.current?.focus({ cursor: 'end' })
    }
  }, [detail])

  const setFieldsFromMaterial = (
    material: Material | undefined,
    isSpliting: boolean
  ) => {
    if (!material) {
      return
    }
    // const drugCount = Math.ceil((material.frequencyCalc * Number(material.useDays) * material.normalDose) / material.transitionScale)
    const m = {
      ...material,
      splitTag: isSpliting && material.splitTag === 1 ? 1 : 0,
      groupNumber: 0,
      drugCount: 1,
      retailPrice: isSpliting ? material.splitPrice : material.retailPrice,
      accountUnit: isSpliting ? material.splitUnit : material.accountUnit,
    } as Material
    setMaterial(m)
    form.setFieldsValue({
      ...m,
      groupNumber: '',
      name: m.name,
      count: 1,
      dose: m.normalDose,
      flag: parseFloat(String(m.retailPrice)).toFixed(2),
    })
  }

  const handler = (e: KeyboardEvent) => {
    if (!isSplitModalVisible) {
      document.removeEventListener('keydown', handler)
      return
    }
    if (e.key === '1' || e.key === '2') {
      setIsSplitModalVisible(false)
      document.removeEventListener('keydown', handler)
      setFieldsFromMaterial(material, e.key === '1')
      setTimeout(() => {
        // const ref = refs.current[names.length - 1]
        // ref?.focus()
      }, 300)
    }
  }

  useEffect(() => {
    if (isSplitModalVisible) {
      document.addEventListener('keydown', handler)
      setTimeout(() => {
        buttonRef.current?.focus()
      }, 300)
    } else {
      document.removeEventListener('keydown', handler)
    }
    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [isSplitModalVisible])

  useEffect(() => {
    if (Source) {
      getExaminePage(detail?.name)
    }
  }, [Source, detail])

  const getExaminePage = (name?: any) => {
    dispatch(
      pageExamineAsync({
        name: name,
        state: 1,
        isFullMatch: 0,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setExamineData(res.records)
      })
  }

  return (
    <Modal
      title={setmealTitle + setmealcategory}
      visible={props.isModalDurgVisible}
      className={styles.setmealDurgform}
      onOk={() => {
        props.onOk()
        setSetmealTitle('新增')
        form.resetFields()
      }}
      onCancel={() => {
        props.onCancel()
        setSetmealTitle('新增')
        dispatch(setSetmealDurgId(0))
        form.resetFields()
      }}
      footer={null}
    >
      <Form
        autoComplete='off'
        {...layout}
        form={form}
        name='control-hooks'
        onFinish={(vs) => {
          dispatch(
            submitSetmealDurgAsync({
              ...vs,
              spec: vs?.packSpec,
              tenantGoodsComboId: props.tenantGoodsComboId,
              source: Source,
              sort: setmealTitle == '新增' ? props.maxSort + 1 : detail.sort,
              groupNumber:
                setmealTitle == '新增'
                  ? props.maxGroupNumber + 1
                  : detail.groupNumber,
              id: DurgId,
              catalogId,
              splitPrice: material?.splitTag == 1 ? vs.retailPrice : undefined,
              splitScale:
                material?.splitTag == 1 ? material.splitScale : undefined,
              splitTag:
                setmealTitle == '新增' ? material?.splitTag : detail?.splitTag,
              splitUnit: material?.splitTag == 1 ? vs.accountUnit : undefined,
            })
          )
            .then(unwrapResult)
            .then(() => {
              props.onOk()
              setCatalogId(0)
              setMaterial(undefined)
              form.resetFields()
              dispatch(setSetmealDurgId(0))
            })
        }}
        onFieldsChange={(_, fs) => {
          const count = fs.find((f) => isSameName(f, 'count'))?.value || ''
          const retailPrice =
            fs.find((f) => isSameName(f, 'retailPrice'))?.value || ''
          if (Number(count) || Number(retailPrice)) {
            form.setFieldsValue({
              flag: parseFloat(String(count * retailPrice)).toFixed(2),
            })
          }
        }}
      >
        {!Source ? (
          <>
            <Row style={{ position: 'relative' }}>
              <Col span={20} style={{ marginLeft: '12px' }}>
                <Form.Item name='name' label='商品名称'>
                  <RecipeItemInput
                    isRestrictUser={1} //是否限制用户
                    showFlag={showFlagChecked ? 1 : 0}
                    isFullMatchTag={0}
                    useSpecifiedBatch={true}
                    filters={false}
                    defaultUsingSpecifiedBatch={false}
                    autoFocus={true}
                    nameValue={DurgId ? form.getFieldValue('name') : undefined}
                    onSelect={(m: any) => {
                      setCatalogId(
                        showFlagChecked ? m?.tenantMaterialsCatalogId : m?.id
                      )
                      if (m.splitTag === 1) {
                        setMaterial(m)
                        setIsSplitModalVisible(true)
                      } else {
                        form.setFieldsValue({
                          ...m,
                          name: m.name,
                          count:
                            setmealTitle == '新增' ? 1 : detail?.count || 1,
                          dose: m.normalDose,
                          flag: parseFloat(String(m.retailPrice)).toFixed(2),
                        })
                        setTimeout(() => {
                          countref.current?.focus({ cursor: 'end' })
                          countref?.current?.select()
                        }, 100)
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ position: 'absolute', right: 0, top: 4 }}>
                <Checkbox
                  checked={showFlagChecked}
                  onChange={(e: any) => {
                    setShowFlagChecked(e?.target?.checked)
                  }}
                >
                  库存
                </Checkbox>
              </Col>
            </Row>
          </>
        ) : (
          <Form.Item name='name' label='诊疗名称'>
            <Select
              showSearch
              onClick={() => {
                getExaminePage(form.getFieldValue('name'))
              }}
              onSearch={(v) => {
                if (v) {
                  dispatch(
                    pageExamineAsync({
                      name: v,
                      state: 1,
                      isFullMatch: 0,
                    })
                  )
                    .then(unwrapResult)
                    .then((res: any) => {
                      setExamineData(res.records)
                    })
                } else {
                  setExamineData([])
                }
              }}
              onBlur={() => {
                setExamineData([])
                dispatch(setExaminData([]))
              }}
              onSelect={(key: any) => {
                const rowdata: any = ExamineData.find((d: any) => d.id === key)
                setCatalogId(rowdata.id)
                form.setFieldsValue({
                  name: rowdata.name,
                  count: 1,
                  accountUnit: rowdata.accountUnit,
                  dose: rowdata.doseCategory,
                  doseUnit: rowdata.doseUnit,
                  packSpec: rowdata.packSpec,
                  retailPrice: rowdata.retailPrice,
                  flag: rowdata.retailPrice,
                })
                setTimeout(() => {
                  countref.current?.focus({ cursor: 'end' })
                  countref?.current?.select()
                }, 100)
              }}
              filterOption={false}
              optionFilterProp='children'
              allowClear
            >
              {ExamineData.map((v: any) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name='count'
          label='数量'
          rules={[
            {
              pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,2})?$/),
              message: '请输入数字',
            },
          ]}
        >
          <Input
            onClick={() => {
              countref?.current?.select()
            }}
            ref={countref}
            onPressEnter={() => {
              form.submit()
            }}
          />
        </Form.Item>
        <Form.Item name='accountUnit' label='数量单位'>
          <Input
            ref={accountref}
            onPressEnter={() => {
              !Source
                ? doesref.current?.focus({ cursor: 'end' })
                : retailref.current?.focus({ cursor: 'end' })
            }}
          />
        </Form.Item>
        {!Source ? (
          <>
            <Form.Item name='dose' label='剂量'>
              <Input
                ref={doesref}
                onPressEnter={() => {
                  doesunitref.current?.focus({ cursor: 'end' })
                }}
              ></Input>
            </Form.Item>
            <Form.Item name='doseUnit' label='剂量单位'>
              <Input
                ref={doesunitref}
                onPressEnter={() => {
                  methodref.current?.focus({ cursor: 'end' })
                }}
              />
            </Form.Item>
            <Form.Item
              name='packSpec'
              label='规格'
              rules={[
                {
                  max: 128,
                  message: '最长128位',
                },
              ]}
            >
              <Input disabled></Input>
            </Form.Item>
            <Form.Item
              name='normalUsemethod'
              label='用法'
              rules={[
                {
                  max: 45,
                  message: '最长45位',
                },
              ]}
            >
              <RecipeItemFieldInput
                ref={methodref}
                field={'normalUsemethod'}
                disabled={false}
                noSize={true}
                onPressEnter={() => {
                  quencyref.current?.focus({ cursor: 'end' })
                }}
              />
            </Form.Item>
            <Form.Item
              name='normalFrequency'
              label='频次'
              rules={[
                {
                  max: 16,
                  message: '最长16位',
                },
              ]}
            >
              <RecipeItemFieldInput
                ref={quencyref}
                field={'normalFrequency'}
                disabled={false}
                noSize={true}
                onPressEnter={() => {
                  retailref.current?.focus({ cursor: 'end' })
                }}
              />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        <Form.Item
          name='retailPrice'
          label='单价'
          rules={[
            {
              pattern: new RegExp(/^([0-9]+[\d]*(.[0-9]{1,5})?)$/, 'g'),
              message: '请输入保留小数五位以内的数',
            },
          ]}
        >
          <Input ref={retailref} />
        </Form.Item>
        <Form.Item
          name='flag'
          label='金额'
          rules={[
            {
              pattern: new RegExp(/^([0-9]+[\d]*(.[0-9]{1,2})?)$/, 'g'),
              message: '请输入保留小数五位以内的数',
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Row gutter={20}>
          <Col span={12}>
            <Button
              onClick={() => {
                dispatch(setSetmealDurgId(0))
                form.resetFields()
                // props.onOk();
                props.onCancel()
              }}
              style={{ float: 'right' }}
            >
              取消
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type='primary'
              style={{ float: 'left' }}
              onClick={() => {
                form.submit()
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        title='可选单位'
        className={styles.split}
        visible={isSplitModalVisible}
        onCancel={() => {
          // ref.current?.focus()
          setIsSplitModalVisible(false)
        }}
        footer={null}
      >
        <Space direction='vertical'>
          <Button
            type='primary'
            onClick={() => {
              setFieldsFromMaterial(material, true)
              setIsSplitModalVisible(false)
            }}
          >
            <span
              style={{
                backgroundColor: theme.pn,
                borderRadius: 999,
                color: theme.c1,
                lineHeight: '1rem',
                marginRight: 8,
                textAlign: 'center',
                width: '1rem',
              }}
            >
              1
            </span>
            {`${material?.splitPrice || ''}元/${material?.splitUnit || ''}`}
          </Button>
          <Button
            ref={buttonRef}
            type='primary'
            onClick={() => {
              setFieldsFromMaterial(material, false)
              setIsSplitModalVisible(false)
            }}
          >
            <span
              style={{
                backgroundColor: theme.pn,
                borderRadius: 999,
                color: theme.c1,
                lineHeight: '1rem',
                marginRight: 8,
                textAlign: 'center',
                width: '1rem',
              }}
            >
              2
            </span>
            {`${material?.retailPrice || ''}元/${material?.accountUnit || ''}`}
          </Button>
        </Space>
        <div className={styles.hint}>可键盘选择“1”或“2”</div>
      </Modal>
    </Modal>
  )
}
