/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-20 17:56:26
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-19 09:48:31
 */
import { Card, Dropdown, message, notification, Pagination, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { TriageListColumns } from './Columns'
import { ListQuery } from './ListQuery'
import styles from './triage.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory, useLocation } from 'react-router-dom'
import { TriageListParams } from '../../services/previewTriageList'
import { RootDispatch } from '../../app/store'
import { DownloadOutlined, QrcodeOutlined } from '@ant-design/icons'
import { selectUserId } from '../../app/applicationSlice'
import Qrcode from 'qrcode.react'
import { getTenantParams, getTriageList, removeSingleAsync } from './listslice'
import { TableRowSelection } from 'antd/lib/table/interface'
import { DataType } from '../putstorage/putstorage'
import { getGenderName } from '../../models/user'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
import moment from 'moment'
import {
  getHealthCodeColorName,
  getTourCodeColorName,
} from '../../models/triage'
import { getPatientFlowName } from '../../models/inspect'
import { AddPreviewingModal } from '../A-toothModule/toothHome/modal/addPreviewingModal'
import { getAge } from '../../utils/StringUtils'
let triageExportXLSXList: any[] = []

interface ListProps {
  status?: number //口腔 his 首页-预检分诊
  onLeftCount?: (obj: any) => void //口腔 his 首页-预检分诊
}

export const List = ({ status, onLeftCount }: ListProps) => {
  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const userId = useSelector(selectUserId)

  const [total, setTotal] = useState(0)

  const [data, setData] = useState<any>([])

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [queries, setQueries] = useState<TriageListParams>({
    current: 0,
    size: 10,
  })

  const [doctorUrl, setDoctorUrl] = useState('')

  const [patientUrl, setPatientUrl] = useState('')

  const [ids, setIds] = useState<any>([])

  const [selectionType] = useState<'checkbox' | 'radio'>('checkbox')

  const location: any = useLocation()

  const [qrCodeOverlayVisible, setQrcodeOverlayVisible] = useState(false)

  const [addPreviewingModal, setAddPreviewingModal] = useState(false) // 口腔版 his 新增预检纷争

  const [addPreviewingModalID, setAddPreviewingModalID] = useState('') //

  const rowSelection = {
    selectedRowKeys: ids,
    onSelect: (record: any, selected: boolean, row: DataType[]) => {
      if (selected) {
        triageExportXLSXList.push(record)
      } else {
        triageExportXLSXList = triageExportXLSXList.filter(
          (item) => item.id !== record.id
        )
      }
      triageExportXLSXList = [...(new Set(triageExportXLSXList) as any)]
      setIds(triageExportXLSXList.map((item) => item.id))
    },
    onSelectAll: (selected: boolean, rows: any, changeRows: any) => {
      if (selected) {
        triageExportXLSXList.push(...changeRows)
      } else {
        changeRows.forEach((row: any) => {
          triageExportXLSXList = triageExportXLSXList.filter(
            (item) => item.id !== row.id
          )
        })
      }
      triageExportXLSXList = [...(new Set(triageExportXLSXList) as any)]
      setIds(triageExportXLSXList?.map((item) => item.id))
    },
  }

  // 表格数据导出
  const exportXSLX = () => {
    const theader = [
      '日期',
      '姓名',
      '性别',
      '年龄',
      '职业',
      '单位/家庭地址',
      '联系方式',
      '体温',
      '症状',
      '旅居史',
      '接触史',
      '健康码颜色',
      '患者流向',
      '回访/上报情况',
    ]
    const multiHeader = [
      [
        '',
        '',
        '',
        '',
        '',
        '',
        `${triageExportXLSXList[0].title}`,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ],
    ]
    const merges = ['G1:J1']
    const filterVal = [
      'createTime',
      'patientName',
      'sex',
      'age',
      'occupation',
      'address',
      'phone',
      'animalHeat',
      'symptom',
      'inbound',
      'touch',
      'healthCodeColor',
      'patientFlow',
      'a',
    ]
    triageExportXLSXList = triageExportXLSXList?.map((item) => {
      return {
        ...item,
        age: getAge(item?.age, item?.ageMonth),
        createTime: item?.createTime
          ? moment(item?.createTime).format(DateTimeFormatSimple)
          : '-',
        sex: getGenderName(item?.sex),
        occupation: item?.occupation ? item?.occupation : '-',
        address: item?.address ? item?.address : '-',
        phone: item?.phone ? item?.phone : '-',
        animalHeat: item?.animalHeat ? item?.animalHeat : '-',
        symptom: item?.symptom ? item?.symptom : '-',
        // tourCode: getTourCodeColorName(item?.tourCode) || '-',
        healthCodeColor: getHealthCodeColorName(item?.healthCodeColor) || '-',
        patientFlow: getPatientFlowName(item.patientFlow) || '-',
        inbound: item.inbound == 1 ? ' 有' : item.inbound == 0 ? '无' : '-',
        touch: item.inbound == 1 ? ' 有' : item.inbound == 0 ? '无' : '-',
        a: '无',
      }
    })
    const data = triageExportXLSXList?.slice(0, 500)
    const value =
      triageExportXLSXList?.length >= 500
        ? data?.map
        : triageExportXLSXList?.map((v: any) =>
            filterVal?.map((j: any) => v[j])
          )
    import('../../ExportXLSX/Export2Excel').then((excel) => {
      excel.export_json_to_excel({
        header: theader,
        data: value,
        filename: '预检分诊数据',
        autoWidth: true,
        bookType: 'xlsx',
        multiHeader,
        merges,
      })
      setIds([])
      setQueries({ ...queries, current: 1, size: 10 })
      triageExportXLSXList = []
    })
  }

  useEffect(() => {
    if (triageExportXLSXList?.length >= 500) {
      message.info('最多可导出500条')
    }
  }, [triageExportXLSXList])

  const QrCodeOverLay = (
    <Card
      style={{
        width: '22rem',
        position: 'relative',
        right: '2rem',
        display: 'flex',
        padding: '20px',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
    >
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Qrcode
          id='qrcode1'
          value={doctorUrl}
          size={512}
          style={{
            width: 128,
            height: 128,
          }}
        />
        <div style={{ flex: '1', padding: '20px 10px' }}>
          <h3>预检分诊诊所端二维码</h3>
          <a
            href='/#'
            id='link1'
            onClick={() => {
              changeCanvasToPic('qrcode1', 'link1', '预检分诊诊所端二维码')
            }}
          >
            <DownloadOutlined />
            点击下载
          </a>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Qrcode
          id='qrcode2'
          value={patientUrl}
          size={512}
          style={{
            width: 128,
            height: 128,
          }}
        />
        <div style={{ flex: '1', padding: '20px 10px' }}>
          <h3>预检分诊患者端二维码</h3>
          <a
            href='/#'
            id='link2'
            onClick={() => {
              changeCanvasToPic('qrcode2', 'link2', '预检分诊患者端二维码')
            }}
          >
            <DownloadOutlined />
            点击下载
          </a>
        </div>
      </div>
    </Card>
  )
  // onExport

  const changeCanvasToPic = (id: string, linkId: string, title: string) => {
    const canvasImg: any = document.getElementById(id) // 获取canvas类型的二维码
    const img = new Image()
    img.src = canvasImg.toDataURL('image/png') // 将canvas对象转换为图片的data url
    const downLink: any = document.getElementById(linkId)
    downLink.href = img.src
    downLink.download = title // 图片name
  }

  useEffect(() => {
    const host = window.location.host

    if (qrCodeOverlayVisible && userId) {
      dispatch(getTenantParams({}))
        .then(unwrapResult)
        .then((res) => {
          setDoctorUrl(
            `http://${host}/h5/triage/index.html#/home?appkey=${res.appKey}&sign=${res.sign}&nationUniqueCode=${res.nationUniqueCode}&timestamp=${res.timestamp}&userId=${userId}&his=true`
          )
          setPatientUrl(
            `http://${host}/h5/triage/index.html#/register?appkey=${res.appKey}&sign=${res.sign}&nationUniqueCode=${res.nationUniqueCode}&timestamp=${res.timestamp}`
          )
        })
    }
  }, [dispatch, qrCodeOverlayVisible, userId, location])

  useEffect(() => {
    sessionStorage.removeItem('patientInfo')
    if (queries.current !== 0) {
      sessionStorage.setItem(
          location.pathname+"_yujian",
          JSON.stringify({ ...queries}));
      getPage()
    }
  }, [queries, dispatch])

  const getPage = () => {
    setPageLoading(true)
    dispatch(getTriageList(queries))
      .then(unwrapResult)
      .then((res) => {
        setTotal(res.total)
        setData(res.records)
      })
      .finally(() => {
        setPageLoading(false)
      })
  }

  // useEffect(() => {
  //   if (location.state?.tag == true) {
  //     sessionStorage.removeItem("traiget");
  //   }
  // }, [location]);

  useEffect(() => {
    sessionStorage.removeItem('traiget')
  }, [])

  return (
    <div
      className={styles.content}
      style={{
        padding: status == 1 ? 0 : '20px',
        margin: status == 1 ? 0 : '10px 20px',
      }}
    >
      <ListQuery
        ids={ids}
        type={status || 0} //口腔版 his 预检分诊
        onExport={() => {
          exportXSLX()
        }}
        onValueChange={(vs) => {
          setQueries({
            ...queries,
            current: 1,
            ...vs,
          })
          onLeftCount &&
            onLeftCount({
              namePre: vs.patientName,
              startTimePre: vs.startTime,
              endTimePre: vs.endTime,
              statePre: vs.state,
            })
        }}
      ></ListQuery>
      <Table
        bordered={status == 1 ? true : false}
        className={styles.table}
        sticky={location.pathname == '/toothHome'}
        loading={pageLoading}
        dataSource={data}
        pagination={false}
        rowKey={(record) => record.id}
        rowSelection={
          {
            type: selectionType,
            ...rowSelection,
          } as TableRowSelection<any>
        }
        columns={TriageListColumns((action, id: any) => {
          // setTriageId(id);
          if (action == 'register') {
            if (status == 1) {
              setAddPreviewingModalID(id)
              setAddPreviewingModal(true)
            } else {
              history.push({
                pathname: '/register',
                state: {
                  selectId: id,
                  flag: true,
                },
              })
              sessionStorage.setItem('traiget', id)
            }
          } else if (action == 'del') {
            dispatch(removeSingleAsync(id))
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: '删除成功。',
                })
                getPage()
              })
          }
        }, queries.size || 10 * (queries.current || 1 - 1))}
        onRow={(res) => ({
          onDoubleClick: () => {
            if (status == 1) {
              setAddPreviewingModalID(res.id)
              setAddPreviewingModal(true)
            } else {
              history.push({
                pathname: '/register',
                state: {
                  selectId: res.id,
                  flag: true,
                },
              })
              sessionStorage.setItem('traiget', res.id)
            }
          },
        })}
      ></Table>
      <Pagination
        total={total}
        current={queries.current}
        pageSize={queries.size}
        showSizeChanger
        showQuickJumper
        style={{
          position: 'absolute',
          bottom: status == 1 ? '80px' : '40px',
          right: '40px',
        }}
        showTotal={(total) => `共 ${total} 条`}
        onChange={(current, size) => {
          setQueries({ ...queries, current, size })
        }}
      />

      <Dropdown
        overlay={QrCodeOverLay}
        trigger={['click']}
        visible={qrCodeOverlayVisible}
        onVisibleChange={(visible) => setQrcodeOverlayVisible(visible)}
      >
        <QrcodeOutlined
          style={{
            fontSize: '2rem',
            position: 'fixed',
            right: '0',
            bottom: '160px',
            background: '#fff',
            boxShadow: '1px 1px 5px #ccc',
            width: '4rem',
            height: '4rem',
            lineHeight: '4.5rem',
            borderRadius: '50%',
          }}
        />
      </Dropdown>

      <AddPreviewingModal
        status={status}
        selectId={addPreviewingModalID}
        visible={addPreviewingModal}
        onOk={() => setAddPreviewingModal(false)}
        onCancel={() => setAddPreviewingModal(false)}
      />
    </div>
  )
}
