/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-09-14 14:41:30
 * @LastEditors: sj
 * @LastEditTime: 2022-09-15 13:42:14
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-09-14 14:17:30
 * @LastEditors: linxi
 * @LastEditTime: 2021-09-14 14:28:05
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { LoadingState } from "../../models/common";
import { Registration } from "../../models/registration";
import { Treatment } from "../../models/treatment";

interface OralCaseState {
  registration?: Registration
  treatment?: Treatment
  creatingState: LoadingState
  PatientModalVisible: boolean
  medicalRecordVisible: boolean
  detailVisible: boolean,
  treatmentLeftPage:any
}

const initialState = {
  registration: undefined,
  creatingState: LoadingState.Idle,
  PatientModalVisible: false,
  medicalRecordVisible: false,
  detailVisible: false,
  treatmentLeftPage:[]
} as OralCaseState

const treatmentRegistSlice = createSlice({
  name: 'oralCase',
  initialState,
  reducers: {
    setTreatmentRegistration: (state, action: PayloadAction<Registration>) => {
      state.registration = action.payload
    },
    reSetRegistration: (state, action) => {
      state.registration = undefined
    },
    setPatientModalVisible: (state, action) => {
      state.PatientModalVisible = action.payload
    },
    setMedicalRecordVisible: (state, action) => {
      state.medicalRecordVisible = action.payload
    },
    setDetailVisible: (state, action) => {
      state.detailVisible = action.payload
    },
    setTreatmentLeftPage :(state, action) => {
      state.treatmentLeftPage = action.payload
    },
  },
})

export const {
  setTreatmentRegistration,
  reSetRegistration,
  setPatientModalVisible,
  setMedicalRecordVisible,
  setDetailVisible,
  setTreatmentLeftPage
} = treatmentRegistSlice.actions

export const treatmentRegistration = (
  state: RootState
): Registration | undefined => state.treatmentRegist.registration

export const selectPatientModalVisible = (state: RootState): boolean =>
  state.treatmentRegist.PatientModalVisible

export const selectMedicalRecordVisible = (state: RootState): boolean =>
  state.treatmentRegist.medicalRecordVisible

export const selectDetailVisible = (state: RootState): boolean =>
  state.treatmentRegist.detailVisible

  export const selectTreatmentLeftPage = (state: RootState) =>
  state.treatmentRegist.treatmentLeftPage


export default treatmentRegistSlice.reducer;
