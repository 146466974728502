import { serviceDelay } from "../models/serviceDelay";
import { ADMIN_API_PREFIX, API_PREFIX } from "./constant";
import { Request } from "./request";

export interface ServiceDelayParams {
  tenantId : string;
  type?: number;
  value?: number;
  startTime?: number;
  endTime?: number;
}

export function getDelay(body: ServiceDelayParams): Request {
  return {
    url: `${API_PREFIX}/blade-administer/tenant/delay`,
    method: "POST",
    body,
  };
}
