/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-09 13:59:51
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-21 13:12:12
 */
import { Button, Space, Switch, TableColumnType } from "antd";
import React from "react";
import { StateSwitch } from "../../compnents/widgets/StateSwitch";
import { durgSystemCategoryName } from "../../models/durgSystem";
export const columns = (
  onClick: (text: string, t: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      width: "5%",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "药房名称",
      align: "center",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "药房类别",
      align: "center",
      dataIndex: "category",
      render: function Category(category: number) {
        return durgSystemCategoryName(category);
      },
    },
    {
      title: "售药权限",
      align: "center",
      dataIndex: "serviceShopPrice",
      render: function content(serviceShopPrice: number) {
        return serviceShopPrice === 1 ? "√" : "×";
      },
    },
    {
      title: "购入入库权限",
      align: "center",
      dataIndex: "servicePurchaseStorage",
      render: function content(servicePurchaseStorage: number) {
        return servicePurchaseStorage === 1 ? "√" : "×";
      },
    },
    {
      title: "操作人",
      align: "center",
      dataIndex: "updateUserName",
    },
    {
      title: "状态",
      align: "center",
      key: "state",
      render: function Element(_, t) {
        return (
          <div>
            <StateSwitch
              value={t.state === 1}
              onChange={() => onClick("finish", t)}
            />
          </div>
        );
      },
    },
    {
      title: "操作",
      align: "center",
      width: "20%",
      render: function element(_, t) {
        return (
          <div>
            <Button
              type="primary"
              size="small"
              ghost
              style={{ marginRight: "10px" }}
              onClick={() => {
                onClick("edit", t.id);
              }}
            >
              编辑
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                onClick("Permissions", t);
              }}
              disabled={t.state ? false : true}
            >
              设置用户权限
            </Button>
          </div>
        );
      },
    },
  ];
};
