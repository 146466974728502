import { Patientlable } from "../models/patientlable";
import { API_PREFIX } from "./constant";
import { ContentType, MultiPartFormData, Request } from "./request";

export interface PatientTableParams {
  current?: number;
  tenantId?: string;
  id?: number;
  backgroundColor?: string;
  fontColor?: string;
  name?: string;
  headName?: string;
  size?: number;
  state?: number;
  level?: number;
  labelType?: number;
}

export function getLableList(params: PatientTableParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/label/list`,
    method: "GET",
    params,
  };
}
export interface getLableListDeleteFnParams {
  id?: number
}
export function getLableListDeleteFn(
  params: getLableListDeleteFnParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/label/disable`,
    method: 'GET',
    params,
  }
}

export function getPatientLabelList(patientId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patientlabel/list`,
    method: "GET",
    params: {
      current: 1,
      size: 1000,
      patientId,
    },
  };
}

// 获取详情
export interface getPatientDetailFnParams {
  patientId?: string
}
export function getPatientDetailFn(params: getPatientDetailFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/detailGroup`,
    method: "GET",
    params,
  };
}

export interface PatientDetailInfoParams {
  id?: string
  insuranceCode?:any
}

// 患者信息页面重构
export function getPatientDetailInfo(params:PatientDetailInfoParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/patientDetail`,
    method: "GET",
    params
  };
}

export function getLableDetail(labelId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/label/detail`,
    method: "GET",
    params: {
      labelId,
    },
  };
}

export function getLableSave(body: Patientlable): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/label/save`,
    method: "POST",
    body,
  };
}

export interface LabelPatientParams {
  patientId?: string;
  labelId?: string;
  set: boolean;
}

export function labelPatient(params: LabelPatientParams): Request {
  return params.set
    ? {
        url: `${API_PREFIX}/blade-outpatient/patientlabel/submit`,
        method: "POST",
        body: {
          ...params,
          set: undefined,
        },
      }
    : {
        url: `${API_PREFIX}/blade-outpatient/patientlabel/remove`,
        method: "POST",
        params: {
          ids: params.labelId,
        },
      };
}


// 合并患者
export interface mergePatientParams {
  address: string,
  ageYear: number,
  companyName: string,
  emailAddress: string,
  emergencyContactName: string,
  emergencyContactPhone: string,
  height: string,
  id?: number,
  idCardNumber: string,
  maritalStatus: number,
  name: string,
  nation: string,
  oldId?: number,
  phone: string,
  remark: string,
  sex: number,
  source: number,
  tenantId: number,
  updateUser: number,
  wechat: string,
  weight: string
}
export function mergePatient(body: mergePatientParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/mergeUpdate`,
    method: "POST",
    body,
  };
}

// 导入
export interface ImportExcelParams {
  file?: any
  tenantId?: string
  supplierId?: string
}

// 患者导入 excel
export function getpatientsExcelFn(params: ImportExcelParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/patient/log/importexcel`,
    headers: {
      [ContentType]: MultiPartFormData,
    },
    method: 'POST',
    params,
  }
}

export interface EditSubLabelParams {
  labelList?: any
  category?: string
  state?: number
}

// 自动标签编辑
export function geteditSubLabelFn(body: EditSubLabelParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/label/editSubLabel`,
    method: "POST",
    body,
  }
}
// 自动标签数据
export function getlistSubLabelFn(params: EditSubLabelParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/label/listSubLabel`,
    method: "GET",
    params,
  }
}