/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-25 20:48:51
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-08 16:50:01
 */

import _ from "lodash";

// 0药品生产许可证: 1药品经营许可证: 2医疗器械经营许可证: 3食品经营许可证: 4医疗器械生产许可证
export enum SupplierCategory {
  YPSC = "0",
  YPJY = "1",
  YLQXJJ = "2",
  SPJJ = "3",
  YLQXSC = "4",
}

export const SupplierCategories = [
  SupplierCategory.YPSC,
  SupplierCategory.YPJY,
  SupplierCategory.YLQXJJ,
  SupplierCategory.SPJJ,
  SupplierCategory.YLQXSC,
];

export function getSupplierCategory(supplierCategory: SupplierCategory) {
  switch (supplierCategory) {
    case "0":
      return "药品生产许可证";
    case "1":
      return "药品经营许可证";
    case "2":
      return "医疗器械经营许可证";
    case "3":
      return "食品经营许可证";
    case "4":
      return "医疗器械生产许可证";
  }
}

export function fromJson(json: any): any {
  return {
    id: json.id?.toString() || "",
    name: json.name,
    code: json.code,
    status: json.status,
    category: json.category,
    batchNo: json.batchNo,
    createTime: json.createTime,
    tenantSupplierName: json.tenantSupplierName,
    spec: json.spec,
    packSpec: json.packSpec,
    doseCategory: json.doseCategory,
    count: json.count,
    unitPrice: json.unitPrice,
    splitCount: json.splitCount,
    splitScale: json.splitScale,
    splitPrice: json.splitPrice,
    splitTag: json.splitTag,
    productDate: json.productDate,
    deadline: json.deadline,
    retailPrice: json.retailPrice,
    tenantInventoryLedgerId: json.tenantInventoryLedgerId,
    tenantMaterialsCatalogId: json.tenantMaterialsCatalogId,
    flag: json.flag,
    outInvCleanListTargetList: json.outInvCleanListTargetList,
    subCategory: json.subCategory
      ? _.chain(json.subCategory)
          .split(",")
          .map((c) => parseInt(c))
          .value()
      : [],
    realCount:json.realCount,
    realSplitCount:json.realSplitCount,
    cabineNo:json?.cabineNo,
    tenantInventoryReceiptItemId:json?.tenantInventoryReceiptItemId
  };
}
