import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../../app/store'
import { sendAsync } from '../../../../app/applicationSlice'
import * as DT from '../../../../services/urlManagement'

// 列表
export const getUrlManagementList = createAsyncThunk<
    any,
    void,
    RootThunkApi<void>
    >('clinic/getUrlManagementList', async (params, api) => {
    return sendAsync(DT.urlManagementList(), api)
})

//菜单
export const getMenuManagementList = createAsyncThunk<
    any,
    void,
    RootThunkApi<void>
    >('clinic/getMenuManagementList', async (params, api) => {
    return sendAsync(DT.menuManagementList(), api)
})

//保存菜单
export const setMenuManagementList = createAsyncThunk<
    void,
    DT.setMenuParams,
    RootThunkApi<void>
>('clinic/getMenuManagementList', async (params, api) => {
    return sendAsync(DT.setMenuManagementList(params), api)
})