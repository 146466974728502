import { TableColumnType } from 'antd'
import moment from 'moment'
import React from 'react'
import { DateTimeFormatSimple } from '../../../../models/datetime'
import { Dimen } from '../../../../models/dimen'
import { getRegistrationStateName } from '../../../../models/registration'
import { getGenderName } from '../../../../models/user'
import { getAge } from '../../../../utils/StringUtils'

export const doctorcolumn = (
  onclick: (normalUsemethod: any, treatmentId: any, id: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    // {
    //   title: "结算险种",
    //   dataIndex: "registrationCategory",
    //   align: "center",
    //   render: function registrationCategory(registrationCategory: number) {
    //     return registrationCategory === 0 ? "自费" : "医保";
    //   },
    // },

    {
      title: '门诊号',
      dataIndex: 'outpatientNo',
      width: Dimen.OutpatientNo,
      align: 'center',
    },

    {
      title: '姓名',
      dataIndex: 'patientName',
      align: 'center',
      width: Dimen.Name,
    },
    {
      title: '病历号',
      key: 'patientId',
      width: Dimen.Sex,
      align: 'center',
      dataIndex: 'patientId',
    },
    {
      title: '性别',
      key: 'gender',
      width: Dimen.Sex,
      align: 'center',
      render: (_: string, u: any) => getGenderName(u.patientSex),
    },
    {
      title: '年龄',
      dataIndex: 'patientAge',
      width: Dimen.Age,
      align: 'center',
      render: (_, r) => getAge(r?.patientAge, r?.patientMonth),
    },
    {
      title: '疾病名称',
      dataIndex: 'disease',
      align: 'center',
    },
    {
      title: '就诊科室',
      dataIndex: 'treatmentDepartmentName',
      width: Dimen.Department,
      align: 'center',
    },
    {
      title: '就诊医生',
      dataIndex: 'treatmentDoctorName',
      width: Dimen.Doctor,
      align: 'center',
    },
    {
      title: '就诊时间',
      dataIndex: 'registrationTime',
      width: Dimen.Time,
      align: 'center',
      render: (_, r) => {
        return moment(r.registrationTime).format(DateTimeFormatSimple)
      },
    },

    {
      title: '就诊状态',
      dataIndex: 'registrationState',
      width: Dimen.Status,
      align: 'center',
      render: (_, r) => getRegistrationStateName(r.registrationState),
    },
    {
      title: '药品用法',
      dataIndex: 'normalUsemethod',
      width: Dimen.Usemethod,
      key: 'normalUsemethod',
      align: 'center',
    },

    {
      title: '操作',
      align: 'center',
      render: function element(_, t) {
        return (
          <div
            style={{
              background: '#027AFF',
              width: '63px',
              height: '30px',
              margin: 'auto',
            }}
          >
            <a
              style={{ color: '#FFFFFF' }}
              type='primary'
              onClick={(e: any) => {
                onclick(t.normalUsemethod, t.treatmentId, e.target.text)
              }}
            >
              执行
            </a>
          </div>
        )
      },
    },
  ]
}
