/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-31 11:05:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-14 09:19:30
 */
import { Row, TableColumnType, Tooltip } from 'antd'
import React from 'react'
import { PriceChangeIcon } from '../../../compnents/icons/Icons'

export const Columns = (startIndex: number): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '药品名称',
      align: 'center',
      dataIndex: 'drugName',
      render: function actino(_1, t) {
        return (
          <Row align='middle' justify='center'>
            {t.drugName}&ensp;{t.unit}
            {t.flag == 1 && (
              <Tooltip title={`${t.lastSupplyPrice} -> ${t.supplyPrice}`}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PriceChangeIcon
                    mode='small'
                    style={{
                      marginLeft: '10px',
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </Row>
        )
      },
    },
    {
      title: '建议零售价',
      align: 'center',
      dataIndex: 'retailPrice',
      render: (_, t) => t.retailPrice.toFixed(2),
    },
    {
      title: '供货价',
      align: 'center',
      dataIndex: 'supplyPrice',
      render: (_, t) => t.supplyPrice.toFixed(2),
    },
    {
      title: '诊所利润',
      align: 'center',
      dataIndex: 'profit',
      render: (_, t) => t?.profit.toFixed(2),
    },
    {
      title: '代煎饮片厂',
      align: 'center',
      dataIndex: 'supplierName',
    },
  ]
}
