/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-31 14:23:29
 * @LastEditors: linxi
 * @LastEditTime: 2021-06-02 15:22:43
 */
import { Button, Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router";
import {
  DateTimeFormat,
  DateTimeFormatSimple,
} from "../../../../../models/datetime";
import styles from "../../AdditionDrugs/additionDrug.module.css";
export const HeadQuery = (props: { detail: any }) => {
  const history = useHistory();
  return (
    <>
      <Row
        className={styles.titletext}
        style={{
          lineHeight: '40px',
        }}
      >
        <Col
          span={24}
          style={{
            marginTop: '20px',
            lineHeight: '10px',
            fontSize: '18px',
            fontWeight: 'bold',
            paddingLeft: '10px',
            width: '100%',
            height: '10px',
            borderLeft: '2px solid #0052D9',
          }}
        >
          库存养护业务单据
          <Button
            type='primary'
            style={{ float: 'right', position: 'relative', top: '-20px' }}
            // disabled={props.detail.storehouseTag !== 0 ? true : false}
            onClick={() => {
              history.push({
                pathname: '/newEditor',
                state: { selectId: props.detail.id },
              })
            }}
            disabled={props.detail.state ? true : false}
          >
            修改业务单据
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: '20px',
          lineHeight: '40px',
          // paddingLeft: "20px",
          marginBottom: '40px',
          fontSize: '18px',
        }}
      >
        <Col span={6}>库房名称：{props.detail.storehouseName}</Col>
        <Col span={6}>
          业务时间：
          {moment(props.detail.maintainTime).format(DateTimeFormatSimple)}
        </Col>
        <Col span={6}>养护人员：{props.detail.mainUserName}</Col>
        <Col span={6}>
          养护等级：{props.detail.maintainCategory ? '重点养护' : '一般养护'}
        </Col>
        <Col span={6}>备注：{props.detail.remark}</Col>
      </Row>
    </>
  )
};
