/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-18 17:44:37
 * @LastEditors: K
 * @LastEditTime: 2022-07-22 14:24:16
 */
import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import { DateSelect } from '../../compnents/form/DateSelect'
import { RootDispatch } from '../../app/store'
import { DateTimeFormat } from '../../models/datetime'
import { selectAddVisibleData } from './followUpPageSlice'

export const AddFollowUpQuery = (props: {
  visibel?: any
  selectKey?: any
  onValueChange: (queries: any) => void
}) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const isAddVisibel: any = useSelector(selectAddVisibleData)

  useEffect(() => {
    if (isAddVisibel == false) form.resetFields()
  }, [isAddVisibel])

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      onFinish={(vs) => {
        props.onValueChange({
          ...vs,
          timeHead: null,
          timeTail: null,
          startTime: vs?.timeHead
            ? moment(vs?.timeHead).format(DateTimeFormat)
            : null,
          endTime: vs?.timeTail
            ? moment(vs?.timeTail).format(DateTimeFormat)
            : null,
        })
      }}
    >
      <Row
        style={{
          marginBottom: 10,
          width: '100%',
          height: '42px',
        }}
        align='middle'
        justify='space-between'
      >
        <Space>
          <Form.Item name='name' noStyle>
            <Input
              size='middle'
              placeholder={
                props.selectKey == 1
                  ? '患者姓名/手机号/助记码'
                  : props.selectKey == 2
                  ? '患者姓名/手机号/病历号'
                  : '疾病名称/患者姓名/手机号'
              }
              allowClear
              // onChange={() => form.submit()}
              onPressEnter={() => {
                form.submit()
              }}
              prefix={<SearchOutlined />}
              style={{ width: '300px' }}
            />
          </Form.Item>
          <Form.Item name='' noStyle>
            <DateSelect
              placeholder='就诊时间'
              style={{ width: '9rem' }}
              onChange={() => form.submit()}
              labelPrefix=''
              namePrefix='time'
              initialValue={0}
              allowClear={false}
            />
          </Form.Item>
        </Space>
        <Space>
          <Button onClick={() => form.submit()} type='primary'>
            查询
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
