/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-09-06 14:13:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-06 17:05:51
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface AnnualCapitalFundListParams {
  current?: number
  size?: number
  startTime?: any
  endTime?: any
  codes?: any
  xianzhonglx?: any
  yidibz?:any
}

export function AnnualCapitalFundList(body: AnnualCapitalFundListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/getPreFee`,
    method: 'POST',
    body,
  }
}


export function AnnualCapitalFundAreaList(
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/city/listByParent`,
    method: 'GET',
  }
}

