/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-09-28 17:23:27
 * @LastEditors: K
 * @LastEditTime: 2022-10-09 13:57:52
 */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Col, Form, Input, Modal, notification, Row, Space } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from '../../app/store';
import { stringTrim } from '../../utils/StringUtils';
import styles from './MembersManagementPage.module.css'
import { getSearchMembers } from './MembersManagementPageSlice';


interface SearchMembersModalProps {
  visible: boolean
  onCancel: () => void
  onOk: (obj: any) => void
  openFn: () => void
}

export const SearchMembersModal = ({
  visible,
  onCancel,
  onOk,
  openFn,
}: SearchMembersModalProps): ReactElement => {

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const [infoShow, setInfoShow] = useState(0)

  const [phoneNumber, setPhoneNumber] = useState(0)

  const [membersInfo, setMembersInfo] = useState<any>()

  const getSearch = () => {
    dispatch(getSearchMembers({ memberPhone: phoneNumber }))
      .then(unwrapResult)
      .then((res: any) => {
        setMembersInfo(Object.keys(res).length ? res : {})
        setInfoShow(Object.keys(res).length ? 1 : 2)
      })
  }

  useEffect(() => {
    if (phoneNumber) {
      getSearch()
    } else {
      setInfoShow(0)
    }
  }, [phoneNumber])


  useEffect(() => {
    if (!visible) {
      form.resetFields()
      setMembersInfo({})
      setInfoShow(0)
      setPhoneNumber(0)
    }
  }, [visible])
  return (
    <Modal
      visible={visible}
      title='搜索会员'
      width={700}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 17 }}
        autoComplete="off"
        onFinish={(values) => setPhoneNumber(values.memberPhone)}
        onFinishFailed={() => setPhoneNumber(0)}
      >
        <Form.Item label="会员手机号码" name="memberPhone" getValueFromEvent={stringTrim} rules={[
          {
            required: true,
            pattern: new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/),
            message: "请输入正确的会员手机号码",
          }
        ]}>
          <Input onChange={form.submit} />
        </Form.Item>
        {infoShow == 1 && <>
          <Form.Item label="姓名">{membersInfo?.memberName}</Form.Item>
          <Form.Item label="账户余额">{membersInfo?.balanceMoney?.toFixed(2)}</Form.Item>
        </>}

      </Form>
      {infoShow == 2 && <div className={styles.infoWrap}>
        <span className={styles.infoIcon}><ExclamationCircleOutlined /></span>
        <span className={styles.infoText} >{phoneNumber}找不到会员信息</span>
      </div>}

      <div className={styles.footerBtn}>
        <Space>
          <Button onClick={onCancel}>取消</Button>
          <Button
            type='primary'
            disabled={!phoneNumber}
            onClick={() => {
              switch (infoShow) {
                case 1:
                  onOk(membersInfo)   //确定
                  break
                case 2:
                  openFn()    //去开通
                  break
                default:
                  form.submit()   //确定
              }

            }}
          >{infoShow == 2 ? '去开通' : '确定'}</Button>

        </Space>
      </div>
    </Modal>
  );
}

