import { Col, Divider, Pagination, Row } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Label } from '../../../compnents/widgets/Label'
import { Treatment } from '../../../models/treatment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { TreatmentBoxList } from '../../treatment/list/TreatmentBoxList'
import {
  getTreatmentList,
  selectTreatmentPage,
} from '../../treatment/list/treatmentSlice'

interface PatientTreatmentHistoryProps {
  patientId?: string
}

export const PatientTreatmentHistory = ({
  patientId,
}: PatientTreatmentHistoryProps): ReactElement => {

  const dispatch = useDispatch()

  const theme = useContext(ThemeContext)

  const page = useSelector(selectTreatmentPage)

    const [params, setParams] = useState<any>({
      current: 1,
      size: 10,
    })

  const [treatment, setTreatment] = useState<Treatment | undefined>()

  useEffect(() => {
    dispatch(getTreatmentList({ ...params, patientId }))
  }, [patientId, params])

  useEffect(() => {
    if (page?.items?.length > 0) {
      setTreatment(page.items[0])
    }
  }, [page])

  return (
    <Col
      style={{
        height: '100%',
        borderRadius: `0 0 ${theme.br}px ${theme.br}px`,
        backgroundColor: theme.pn,
        paddingTop: 10,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
      }}
    >
      <Label label='体征数据' style={{ marginLeft: 20 }} />
      <Divider style={{ margin: 0, marginBottom: 20, borderColor: theme.c3 }} />
      <Row
        style={{ fontSize: '1.125rem', color: theme.tc2, whiteSpace: 'nowrap' }}
      >
        <Col flex='10.5rem' style={{ textAlign: 'right' }}>
          身高(cm)：
        </Col>
        <Col flex='10%'>{treatment?.patientHeight || '--'}</Col>
        <Col flex='25%' style={{ textAlign: 'right' }}>
          体重(kg)：
        </Col>
        <Col flex='10%' style={{ textAlign: 'left' }}>
          {treatment?.patientWeight || '--'}
        </Col>
        <Col flex='1' style={{ textAlign: 'right' }}>
          体温(℃)：
        </Col>
        <Col flex='15%'>{treatment?.patientTemperature || '--'}</Col>
      </Row>
      <Row
        style={{
          fontSize: '1.125rem',
          color: theme.tc2,
          whiteSpace: 'nowrap',
          marginTop: 20,
        }}
      >
        <Col flex='10.5rem' style={{ textAlign: 'right' }}>
          脉搏(次/分)：
        </Col>
        <Col flex='10%'>{treatment?.patientPulse || '--'}</Col>
        <Col flex='25%' style={{ textAlign: 'right' }}>
          收缩压(mmHg)：
        </Col>
        <Col flex='10%' style={{ textAlign: 'left' }}>
          {treatment?.patientSystolicPressure || '--'}
        </Col>
        <Col flex='1' style={{ textAlign: 'right' }}>
          舒张压(mmHg)：
        </Col>
        <Col flex='15%'>{treatment?.patientDiastolicPressure || '--'}</Col>
      </Row>
      <Label label='病历信息' style={{ marginTop: 20, marginLeft: 20 }} />
      <Divider style={{ margin: 0, marginBottom: 20, borderColor: theme.c3 }} />
      <Col style={{ marginLeft: 20, marginRight: 20, height: '60%',overflow: 'auto' }}>
        <TreatmentBoxList page={page} />
      </Col>
      <Row justify='end'>
        <Pagination
          style={{ marginTop: 20, marginRight: 30 }}
          total={page.total}
          showSizeChanger
          current={page.current}
          pageSize={page.size}
          showTotal={(total) => `共 ${total} 条`}
          onChange={(current, size) => setParams({ ...params, current, size })}
        />
      </Row>
    </Col>
  )
}
