import {
    Button,
    Col,
    Collapse,
    Form,
    Modal,
    Row,
    Space,
    Table,
    message,
    notification,
    Input,
    Upload,
    Timeline,
    Image
} from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import React, { useEffect, useState } from 'react'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CaretUpFilled } from '@ant-design/icons'
import _, { divide } from 'lodash'
import styles from '../../nurse/formula/formulas/formula.module.css'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../../app/store'
import { CategoryName } from '../../../models/category'
import { useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'
import {
    DateTimeFormat,
    DateTimeFormatSimple,
} from '../../../models/datetime'
import { getAppealDetailAsync, appealProcessAsync } from './beforeOperatingManagePageSlice'
import { NavBar } from '../../../compnents/nav/NavBar';
export const AppealDetail = (): ReactElement => {
    const success = () => {
        message.success('操作成功')
    }
    const { Panel } = Collapse

    const location: any = useLocation()

    const history: any = useHistory()

    const { TextArea } = Input;

    const dispatch = useDispatch<RootDispatch>()

    const [nofarmulaDetails, setNofarmulaDetails] = useState<any[]>([])

    const [isPrintModalVisible, setIsPrintModalVisible] = useState(false)

    const [form] = Form.useForm()

    const [previewTitle, setPreviewTitle] = useState('');

    const [fileList, setFileList] = useState<UploadFile[]>([])

    const [dataList, setDataList] = useState([{ handleType: 1 }])

    const [detail, setDetail] = useState<any>()


    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setFileList(newFileList);

    const uploadButton = (
        <div>
            <PlusOutlined />
        </div>
    );

    useEffect(() => {
        if (location.state.detailsId) {
            dispatch(getAppealDetailAsync(location.state.detailsId))
                .then(unwrapResult)
                .then((v: any) => {
                    setDetail(v)
                })
        }
    }, [location.state.detailsId])

    useEffect(() => {
        if (location.state.processId) {
            dispatch(appealProcessAsync(location.state.processId))
                .then(unwrapResult)
                .then((v: any) => {
                    setDataList(v?.data)
                })
        }
    }, [location.state.processId])

    return (
        <>
            <div style={{ overflow: 'scroll', height: '100%' }}>
                <div className={styles.putStorage} style={{ backgroundColor: '#fff' }}>
                    <NavBar
                        style={{ margin: '0px 20px' }}
                        where={['申诉列表', '手术列表']}
                        backtrace={{
                            path: '/beforeOperatingManage',
                            name: '申诉列表',
                        }}
                    />
                </div>
                <div style={{ padding: '40px 100px', backgroundColor: '#fff' }}>
                    <Row style={{ marginTop: '10px', marginBottom: '16px', fontWeight: 700, fontSize: '18px' }}>
                        <Col span={12}>
                            <span>申诉信息</span>
                        </Col>
                    </Row>
                    <div>
                        <div style={{ paddingLeft: '60px' }}>
                            <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                                <Col span={12}>
                                    <span>手术项目：{detail?.surgicalItems}</span>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                                <Col span={12}>
                                    <span>消费者姓名：{detail?.name}</span>
                                </Col>
                                <Col span={12}>
                                    <span>手机号码：{detail?.mobile}</span>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                                <Col span={12}>
                                    <span>性别：{detail?.sex == '0' ? '女' : '男'}</span>
                                </Col>
                                <Col span={12}>
                                    <span>年龄：{detail?.age}</span>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                                <Col span={12}>
                                    <span>手术时间：{moment(detail?.surgicalDate).format(DateTimeFormatSimple)}</span>
                                </Col>
                                <Col span={12}>
                                    <span>申诉时间：{moment(detail?.createTime).format(DateTimeFormatSimple)}</span>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                                <Col span={12}>
                                    <span>状态：{detail?.inappealState == '4' ? '机构举证中' : (
                                        detail?.inappealState == '1' ? '申诉人举证中' : (
                                            detail?.inappealState == '8' ? '案件立案中' : '申诉完成'
                                        )
                                    )}</span>
                                </Col>
                                <Col span={12}>
                                    <span>医生姓名：{detail?.opdoctor}</span>
                                </Col>
                            </Row>
                            {detail?.comment ?
                                <Row style={{ marginTop: '10px', marginBottom: '16px' }}>
                                    <Col span={24}>
                                        <span>申诉描述：{detail?.comment}</span>
                                    </Col>
                                </Row> : ''}
                        </div>
                        <Row style={{ marginTop: '40px', marginBottom: '16px', fontWeight: 700, fontSize: '18px' }}>
                            <Col span={12}>
                                <span>申诉过程</span>
                            </Col>
                        </Row>
                        <Timeline mode='left' style={{ marginLeft: '60px', marginTop: '40px' }}>
                            {dataList?.map((item: any, index) => {
                                return (
                                    item?.handleType == 0 ? (
                                        <Timeline.Item
                                            key={index}
                                            style={{ width: '400px' }}
                                            label={
                                                moment(item?.updateTime).format(DateTimeFormat)
                                            }
                                        >
                                            <p>申诉发起</p>
                                            <p>申诉人：{item?.name}</p>
                                            <p>申诉项目：{item?.surgicalItems}</p>
                                            <p>就诊机构：{item?.orgnationName}</p>
                                            <p>申诉描述：{item?.comment}</p>
                                            {item?.docList || item?.urlList ?
                                                <div style={{ display: 'flex', width: '600px' }}>
                                                    <span style={{ width: '60px' }}>附件：</span>
                                                    <div>
                                                        {
                                                            item?.docList && item?.docList?.map((i: any, v: any) => {
                                                                return (
                                                                    <p key={v}>
                                                                        <span style={{ color: '#3894FF' }} onClick={() => {
                                                                            i?.url && (window.open(i?.url))
                                                                        }}>{i?.filename}</span>
                                                                    </p>
                                                                )
                                                            })
                                                        }
                                                        <div style={{ display: 'flex', flex: 1 }}>
                                                            {
                                                                item?.urlList && item?.urlList?.map((i: any, v: any) => {
                                                                    return (
                                                                        <div key={v} style={{ marginRight: '10px' }}>
                                                                            <Image style={{ height: '100px' }} width={100} src={i?.url} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : ''
                                            }
                                        </Timeline.Item>
                                    ) : item?.handleType == 5 ? (
                                        <Timeline.Item
                                            key={index}
                                            style={{ width: '400px' }}
                                            label={
                                                moment(item?.updateTime).format(DateTimeFormat)
                                            }
                                        >
                                            <p>申诉自辩</p>
                                            <p>机构名称：{item?.orgnationName}</p>
                                            <p>自辩描述：{item?.comment}</p>
                                            {item?.docList || item?.urlList ?
                                                <div style={{ display: 'flex', width: '600px' }}>
                                                    <span style={{ width: '60px' }}>附件：</span>
                                                    <div>
                                                        {
                                                            item?.docList && item?.docList?.map((i: any, v: any) => {
                                                                return (
                                                                    <p key={v}>
                                                                        <span style={{ color: '#3894FF' }} onClick={() => {
                                                                            i?.url && (window.open(i?.url))
                                                                        }}>{i?.filename}</span>
                                                                    </p>
                                                                )
                                                            })
                                                        }
                                                        <div style={{ display: 'flex', flex: 1 }}>
                                                            {
                                                                item?.urlList && item?.urlList?.map((i: any, v: any) => {
                                                                    return (
                                                                        <div key={v} style={{ marginRight: '10px' }}>
                                                                            <Image style={{ height: '100px' }} width={100} src={i?.url} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : ''
                                            }
                                        </Timeline.Item>
                                    ) : item?.handleType == 1 ? (
                                        <Timeline.Item
                                            key={index}
                                            style={{ width: '400px' }}
                                            label={
                                                moment(item?.updateTime).format(DateTimeFormat)
                                            }
                                        >
                                            <p>申诉举证</p>
                                            <p>申举证人：{item?.name}</p>
                                            <p>举证描述：{item?.comment}</p>
                                            {item?.docList || item?.urlList ?
                                                <div style={{ display: 'flex', width: '600px' }}>
                                                    <span style={{ width: '60px' }}>附件：</span>
                                                    <div>
                                                        {
                                                            item?.docList && item?.docList?.map((i: any, v: any) => {
                                                                return (
                                                                    <p key={v}>
                                                                        <span style={{ color: '#3894FF' }} onClick={() => {
                                                                            i?.url && (window.open(i?.url))
                                                                        }}>{i?.filename}</span>
                                                                    </p>
                                                                )
                                                            })
                                                        }
                                                        <div style={{ display: 'flex', flex: 1 }}>
                                                            {
                                                                item?.urlList && item?.urlList?.map((i: any, v: any) => {
                                                                    return (
                                                                        <div key={v} style={{ marginRight: '10px' }}>
                                                                            <Image style={{ height: '100px' }} width={100} src={i?.url} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div> : ''
                                            }
                                        </Timeline.Item>
                                    ) : item?.handleType == 7 ? (
                                        <Timeline.Item
                                            key={index}
                                            style={{ width: '400px' }}
                                            label={
                                                moment(item?.updateTime).format(DateTimeFormat)
                                            }
                                        >
                                            <p>申诉监督</p>
                                            <p>监督部门：{item?.regName}</p>
                                            <p>监督人员：{item?.custodyName}</p>
                                            <p>处置建议：{item?.comment}</p>
                                        </Timeline.Item>
                                    ) : item?.handleType == 10 ? (
                                        <Timeline.Item
                                            key={index}
                                            style={{ width: '400px' }}
                                            label={
                                                moment(item?.updateTime).format(DateTimeFormat)
                                            }
                                        >
                                            <p>申诉完成</p>
                                            <p>处置部门：{item?.regName}</p>
                                            <p>处置人员：{item?.custodyName}</p>
                                            <p>处置说明：{item?.comment}</p>
                                        </Timeline.Item>
                                    ) : (
                                        <Timeline.Item
                                            key={index}
                                            style={{ width: '400px' }}
                                            label={
                                                moment(item?.updateTime).format(DateTimeFormat)
                                            }
                                        >
                                            <p>申诉完成</p>
                                            <p>处置人：{item?.name}</p>
                                            <p>处置项目：{item?.surgicalItems}</p>
                                            {
                                                item?.comment ? <p>处置说明：{item?.comment}</p> : ''
                                            }
                                        </Timeline.Item>
                                    )
                                )
                            })
                            }
                        </Timeline>
                        <Row gutter={20} style={{ marginTop: '40px', display: 'flex', justifyContent: 'center', fontWeight: 700, fontSize: '18px' }}>
                            <Col>
                                <Button
                                    type='primary'
                                    htmlType="submit"
                                    onClick={() => {
                                        history.push({
                                            pathname: '/beforeOperatingManage',
                                            state: {
                                                toUseAppeal: true,
                                                id: location.state.detailsId,
                                                operationId: location.state.processId
                                            },
                                        })

                                    }}
                                >
                                    举证
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        </>
    )
}
