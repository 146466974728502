/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 14:58:10
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-16 16:38:18
 */
import React from "react";
import styles from "../WarehouseBusinessLibrary/library.module.css";
import { Category } from "../../../models/category";
import { setCategory } from "../../putstorage/putstorageSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectThreeMenus, traceRoute } from "../../../layouts/layoutSlice";

export const WarehouseSearchLibrary = (props: { parentId: string}) => {
  const dispatch = useDispatch();

  const threeMenus = useSelector(selectThreeMenus)

  const getStatus = (name: string) => {
    const menus = threeMenus?.filter((v: any) => v.parentId == props?.parentId)
    return menus?.some((v: any) => v.name == name)
  }
  
  return (
    <div className={styles.content}>
      {getStatus('库存变动流水') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: '库房工作站',
              path: '/InventoryChange',
            })
          )
        }}
      >
        库存变动流水
      </div>) : ''}
      {getStatus('盘点记录查询') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: '库房工作站',
              path: '/inventoryRecords',
            })
          )
        }}
      >
        盘点记录查询
      </div>) : ''}
      {getStatus('药品购进记录') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: '库房工作站',
              path: '/recardDrug',
            })
          )
        }}
      >
        药品购进记录
      </div>) : ''}
      {getStatus('药品销售记录') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(
            traceRoute({
              name: '库房工作站',
              path: '/durgsales',
            })
          )
        }}
      >
        药品销售记录
      </div>) : ''}
      {getStatus('库存结转') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(setCategory(Category.JZ))
          dispatch(
            traceRoute({
              name: '库房工作站',
              path: '/carryover',
              state: { catgetory: Category.JZ },
            })
          )
        }}
      >
        库存结转
      </div>) : ''}
      {getStatus('采购验收记录') ? (<div
        className={styles.card}
        onClick={() => {
          dispatch(setCategory(Category.CGYSJL))
          dispatch(
            traceRoute({
              name: '采购验收记录',
              path: '/purchaseAcceptance',
              state: { catgetory: Category.CGYSJL },
            })
          )
        }}
      >
        采购验收记录
      </div>) : ''}
    </div>
  )
};
