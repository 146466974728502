import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { api } from "../../../../app/applicationSlice";
import { RootState, RootThunk } from "../../../../app/store";
import * as service from "../../../../services/maintenanceDrug";
import { MaintenanceDrugParams } from "../../../../services/maintenanceDrug";
interface MaintenanceDrug {
    maintenanceDrug: [],
    Total: 0,
    Current: 1,
}

const initialState: MaintenanceDrug = {
    maintenanceDrug: [],
    Total: 0,
    Current: 1,
};

const MaintenanceDrugSlice = createSlice({
    name: "MaintenanceDrugSlice",
    initialState,
    reducers: {
        setData(state, action) {
            state.maintenanceDrug = action.payload
        },
        setTotal(state, action) {
            state.Total = action.payload
        },
        setCurrent(state, action) {
            state.Current = action.payload
        },
    },
});

export const { setData,
    setTotal,
    setCurrent, } = MaintenanceDrugSlice.actions;


export const selectMaintenanceDrugData = (state: RootState) =>
    state.maintenanceDrug.maintenanceDrug;

export const selectTotal = (state: RootState) => state.maintenanceDrug.Total

export const selectCurrent = (state: RootState) => state.maintenanceDrug.Current



export function getMaintenanceDrugList(params: MaintenanceDrugParams): RootThunk {
    return api(service.getMaintenanceDrug(params), (data: any, dispatch) => {
        dispatch(setData(data.records))
        dispatch(setTotal(data.total))
    });
}


export default MaintenanceDrugSlice.reducer;
