import { TableColumnType } from 'antd'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { DateTimeFormatSimple } from '../../../../models/datetime'
import { Dimen } from '../../../../models/dimen'
import { Formulas } from '../../../../models/formula'
import {} from '../../../../models/list'
import { getRecipeCategoryName } from '../../../../models/recipe'
import { getRegistrationStateName } from '../../../../models/registration'
import { getGenderName } from '../../../../models/user'
import { getAge } from '../../../../utils/StringUtils'

export const Columns = (startIndex: number): TableColumnType<any>[] => {
  const dispatch = useDispatch()
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    // {
    //   title: "结算险种",
    //   dataIndex: "registrationCategory",
    //   align: "center",
    //   render: function registrationCategory(registrationCategory: number) {
    //     return registrationCategory === 0 ? "自费" : "医保";
    //   },
    // },
    {
      title: '门诊号',
      dataIndex: 'outpatientNo',
      align: 'center',
      width: Dimen.OutpatientNo,
    },
    {
      title: '姓名',
      dataIndex: 'patientName',
      align: 'center',
      width: Dimen.Name,
    },
    {
      title: '病历号',
      key: 'patientId',
      width: Dimen.Sex,
      align: 'center',
      dataIndex: 'patientId',
    },
    {
      title: '性别',
      key: 'gender',
      align: 'center',
      width: Dimen.Sex,
      render: (_: string, u: any) => getGenderName(u.patientSex),
    },
    {
      title: '年龄',
      dataIndex: 'patientAge',
      align: 'center',
      width: Dimen.Age,
      render: (_, r) => getAge(r?.patientAge, r?.patientMonth),
    },
    {
      title: '疾病名称',
      dataIndex: 'disease',
      align: 'center',
    },
    {
      title: '就诊科室',
      dataIndex: 'treatmentDepartmentName',
      align: 'center',
      width: Dimen.Department,
    },
    {
      title: '就诊医生',
      dataIndex: 'treatmentDoctorName',
      align: 'center',
      width: Dimen.Doctor,
    },
    {
      title: '就诊时间',
      dataIndex: 'registrationTime',
      align: 'center',
      width: Dimen.Time,
      render: (_, r) => {
        return moment(r.registrationTime).format(DateTimeFormatSimple)
      },
    },
    {
      title: '就诊状态',
      dataIndex: 'registrationState',
      align: 'center',
      width: Dimen.State,
      render: (_, r) => getRegistrationStateName(r.registrationState),
    },

    {
      title: '药品用法',
      dataIndex: 'normalUsemethod',
      key: 'normalUsemethod',
      width: Dimen.Usemethod,
      align: 'center',
    },

    {
      title: '处方名称',
      dataIndex: 'category',
      align: 'center',
      width: Dimen.Category,
      render: (_: string, u: Formulas) => getRecipeCategoryName(u.category),
    },
  ]
}
