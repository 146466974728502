/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-03 20:22:17
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-03 14:23:24
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Button, Col, Form, Input, Row, Select } from 'antd'
import React from 'react'
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { commodityPageParams } from '../../services/commodity'
import styles from './Commodity.module.css'
import { DateSelect } from '../../compnents/form/DateSelect'
import { DateSelect as DateSelectOne } from '../../compnents/date/TimeSelect'
import { fromPairs } from 'lodash'

const { Option } = Select

export const JournalQuery = (props: {
  onValueChange: (queries: commodityPageParams) => void
}) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch()

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFinish={(fs) => {
        props.onValueChange({
          ...fs,
          current: 1,
          state: fs.state === 3 ? undefined : fs.state,
          cautionId: fs.cautionId ? fs.cautionId : undefined,
        })
      }}
    >
      <Row
        gutter={11}
        style={{ height: '60px', position: 'relative', zIndex: 999 }}
      >
        <Col span={5}>
          <Form.Item name='cautionId'>
            <Input
              size='middle'
              placeholder='警报编号'
              onChange={form.submit}
              allowClear
              prefix={<SearchOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <DateSelect
              placeholder='发生时间'
              style={{ width: '100%' }}
              labelPrefix=''
              onChange={form.submit}
              namePrefix='happenTime'
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <DateSelectOne
              placeholder='推送时间'
              style={{ width: '100%' }}
              labelPrefix=''
              onChange={form.submit}
              namePrefix='pullTime'
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='state'>
            <Select
              placeholder='处置状态'
              allowClear
              // suffixIcon={<CaretDownFilled />}
              onChange={form.submit}
            >
              <Option value={3}>全部</Option>
              <Option value={0}>待申诉</Option>
              <Option value={1}>申诉中</Option>
              <Option value={2}>申诉完成</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={1}>
          <Button htmlType='submit' type='primary' onClick={form.submit}>
            查询
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
