/* eslint-disable react/display-name */
import { TableColumnType, Typography } from 'antd'
import React from 'react'
import { getChrgitmLvName, getHilistLmtpricType, getIsCouldBxName } from '../../../models/commodity'
const { Text } = Typography

export const MedicalColumns = (
  onClick: (text: string, id: any, item?: any) => void,
  startIndex: number,
): TableColumnType<any>[] => {
  const getColor=(v:any)=>{
    if (v?.isCouldBx!=='0'){
      if (v?.chrgitmLv==='02'){
        return "#FA8714";
      }else if (v?.chrgitmLv==='03') {
        return "#2A7CF6";
      }else {
        return "#15ad31";
      }

    }else {
      return '#000000';
    }

  }
  return [
    {
      title: '序号',
      align: 'center',
      fixed: 'left',
      render: (_1, _2, index) => (
        <span style={{ color: getColor(_2) }}>
          {startIndex + index + 1}
        </span>
      ),
    },
    {
      title: '国家医保编码',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color: getColor(t) }}>
            {t.hiListCode}
          </span>
        )
      },
    },
    {
      title: '医保类型',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {/*{(t.insuAdmdvs == '330000' || !t.insuAdmdvs) ? '省医保' : t.insuAdmdvs == '330700' ? '金华医保' : '杭州医保'}*/}
            {t.insuAdmdvs}
          </span>
        )
      },
    },
    {
      title: '医保项目名称',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {t.hilistName}
          </span>
        )
      },
    },
    {
      title: '医保规格',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {t.spec}
          </span>
        )
      },
    },
    {
      title: '医保单位',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {t.pacunt}
          </span>
        )
      },
    },
    {
      title: '包装材质',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {t.pacmatl}
          </span>
        )
      },
    },
    {
      title: '医保剂型',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {t.dosformName}
          </span>
        )
      },
    },{
      title: '批准文号',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.aprvno}
          </span>
        )
      },
    },
    {
      title: '医保厂家',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {t.prodentpName}
          </span>
        )
      },
    },
    {
      title: '转化比',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {(isNaN(t.conversion) || Number(t.conversion) <=0 ) ?1:t.conversion}
          </span>
        )
      },
    },
    {
      title: '型号',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
             {t.specMol}
          </span>
        )
      },
    },
    {
      title: '医保限价',
      align: 'center',
      render: function Element(_, t) {
        const xj = Number(t.hilistPricUplmtAmt || 0).toFixed(4)
        return (
          <span style={{ color:  getColor(t) }}>
            {Number(xj) > 0 && Number(xj) <= 99998
              ? xj
              : Number(xj) == -1
              ? '-'
              : '不限价'}
          </span>
        )
      },
    },
    {
      title: '限价类型',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {getHilistLmtpricType(t.hilistLmtpricType)}
          </span>
        )
      },
    },
    {
      title: '甲乙分类',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {getChrgitmLvName(t.chrgitmLv)}
          </span>
        )
      },
    },
    {
      title: '是否报销',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {getIsCouldBxName(t.isCouldBx)}
          </span>
        )
      },
    },
    {
      title: '二级伤残默认自付比例',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {t.disabled}
          </span>
        )
      },
    },
    {
      title: '城乡居民默认自付比例',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {t.residents}
          </span>
        )
      },
    },
    {
      title: '离休人员默认自付比例',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {t.retired}
          </span>
        )
      },
    },
    {
      title: '城乡职工默认自付比例',
      align: 'center',
      render: function Element(_, t) {
        return (
          <span style={{ color:  getColor(t) }}>
            {t.workers}
          </span>
        )
      },
    },
    {
      title: '备案公司',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.regerName}
          </span>
        )
      },
    },
    {
      title: '备注',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.memo}
          </span>
        )
      },
    },{
      title: '市场状态',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.mktStas}
          </span>
        )
      },
    }
  ]
}




export const MedicalColumnsWithProv = (
    onClick: (text: string, id: any, item?: any) => void,
    startIndex: number,
): TableColumnType<any>[] => {
  const getColor=(v:any)=>{
    if (v?.isCouldBx!=='0'){
      if (v?.chrgitmLv==='02'){
        return '#FA8714'
      }else if (v?.chrgitmLv==='03') {
        return "#2A7CF6";
      }else {
        return "#15ad31";
      }

    }else {
      return '#000000';
    }
  }

  return [
    {
      title: '序号',
      align: 'center',
      fixed: 'left',
      render: (_1, _2, index) => (
          <span style={{ color: getColor(_2)  }}>
          {startIndex + index + 1}
        </span>
      ),
    },
    {
      title: '国家医保编码',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.hiListCode}
          </span>
        )
      },
    },
    {
      title: '省市编码',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.proviceHilistCode}
          </span>
        )
      },
    },
    {
      title: '医保类型',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span>
            {/*{(t.insuAdmdvs == '330000' || !t.insuAdmdvs) ? '省医保' : t.insuAdmdvs == '330700' ? '金华医保' : '杭州医保'}*/}
              {t.insuAdmdvs}
          </span>
        )
      },
    },
    {
      title: '医保项目名称',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.hilistName}
          </span>
        )
      },
    },
    {
      title: '医保规格',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.spec}
          </span>
        )
      },
    },
    {
      title: '医保单位',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.pacunt}
          </span>
        )
      },
    },
    {
      title: '包装材质',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.pacmatl}
          </span>
        )
      },
    },
    {
      title: '医保剂型',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.dosformName}
          </span>
        )
      },
    },
    {
      title: '批准文号',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.aprvno}
          </span>
        )
      },
    },
    {
      title: '医保厂家',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.prodentpName}
          </span>
        )
      },
    },
    {
      title: '转化比',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {(isNaN(t.conversion) || Number(t.conversion) <=0 ) ?1:t.conversion}
          </span>
        )
      },
    },
    {
      title: '型号',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
             {t.specMol}
          </span>
        )
      },
    },
    {
      title: '医保限价',
      align: 'center',
      render: function Element(_, t) {
        const xj = Number(t.hilistPricUplmtAmt || 0).toFixed(4)
        return (
            <span style={{ color:  getColor(t) }}>
            {Number(xj) > 0 && Number(xj) <= 99998
                ? xj
                : Number(xj) == -1
                    ? '-'
                    : '不限价'}
          </span>
        )
      },
    },
    {
      title: '限价类型',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {getHilistLmtpricType(t.hilistLmtpricType)}
          </span>
        )
      },
    },
    {
      title: '甲乙分类',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {getChrgitmLvName(t.chrgitmLv)}
          </span>
        )
      },
    },
    {
      title: '是否报销',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {getIsCouldBxName(t.isCouldBx)}
          </span>
        )
      },
    },
    {
      title: '二级伤残默认自付比例',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.disabled}
          </span>
        )
      },
    },
    {
      title: '城乡居民默认自付比例',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.residents}
          </span>
        )
      },
    },
    {
      title: '离休人员默认自付比例',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.retired}
          </span>
        )
      },
    },
    {
      title: '城乡职工默认自付比例',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.workers}
          </span>
        )
      },
    },
    {
      title: '备案公司',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.regerName}
          </span>
        )
      },
    },
    {
      title: '备注',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.memo}
          </span>
        )
      },
    },{
      title: '市场状态',
      align: 'center',
      render: function Element(_, t) {
        return (
            <span style={{ color:  getColor(t) }}>
            {t.mktStas}
          </span>
        )
      },
    }
  ]
}