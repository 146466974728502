import JSEncrypt from "jsencrypt";
import crypto from "crypto";

export function m() {
  const key = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgOzcPg/RWnYxyk8EGQ9vwgdXS/TLbGKu233du2064XpIUw34dGPhDdRrSfTg7bHSKuV2WlbeaWfRDW5mpurpbuZp2CCgbs1Uy+/MBD2zVIsyc1otY7er3LC/xMKnzfZmdLKqCyL5UkmTYqOF422EdnekiNyZLRSMasWWIOjfw/9yFEluU4iABaJiTvP253n+LsQYUO18QdKWIY87FBiny+kZYxrON22ypSlVJrref2Z0/wZnachZUz+1Ko87ymlrPSpuLgchjoCZyRKp2sv3Aywc6POWbcv6pQ6E3olD6oYN5C+XTs9cmg3H1B0GT0cZn+hK6I9Hx878ftzz2zLVowIDAQAB-----END PUBLIC KEY-----";
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(key)
  const diff = localStorage.getItem("timeDiff") || "0";
  return encrypt.encrypt((new Date().getTime() + parseInt(diff)).toString());
}

export function getSecretKey() {
  return "hTf0qtZwPeMlRXwpu2SZdAG3T8y4mwUE";
}

export function getHashStr() {
  return "lXfxs4xzAI";
}

export function encrypt(password : string,ss : string) {

  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(getSecretKey()), iv);
  let encrypted = cipher.update(password + getHashStr() + ss, 'utf8', 'base64');

  encrypted += cipher.final('base64');

  password = iv.toString('base64') + getHashStr() + encrypted;
  return password;
}