/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-15 18:53:24
 */
import { TableColumnType } from 'antd'
import { Dimen } from '../../../models/dimen'
export const Columns = (
  startIndex: number,
  timeFlag: any,
  data?: any
): TableColumnType<any>[] => {
  const cols: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
      width: Dimen.Num,
    },
    {
      title: '时间范围',
      dataIndex: 'date',
      align: 'center',
      width: Dimen.Name,
      key: 'date',
      render: (_: any, t: any) => {
        return timeFlag == 0
          ? t?.year + '年'
          : timeFlag == 3
          ? '第' + t?.quarter + '季度'
          : timeFlag == 1
          ? t?.month + '月'
          : timeFlag == 2
          ? t?.date
          : ''
      },
    },
    {
      title: '应收金额',
      dataIndex: 'totalShouldPayAmount',
      align: 'center',
      width: Dimen.Price,
      key: 'totalShouldPayAmount',
      render: (_: any, t: any) => {
        return t.totalShouldPayAmount
          ? parseFloat((t.totalShouldPayAmount as unknown) as string).toFixed(2)
          : 0
      },
    },
    {
      title: '实收金额',
      dataIndex: 'totalRealPayAmount',
      align: 'center',
      width: Dimen.Price,
      key: 'totalRealPayAmount',
      render: (_: any, t: any) => {
        return t.totalRealPayAmount
          ? parseFloat((t.totalRealPayAmount as unknown) as string).toFixed(2)
          : 0
      },
    },
    {
      title: '医保金额',
      dataIndex: 'totalInsurancePayAmount',
      align: 'center',
      width: Dimen.Unit,
      key: 'totalInsurancePayAmount',
      render: (_: any, t: any) => {
        return t.totalInsurancePayAmount
          ? parseFloat(
              (t.totalInsurancePayAmount as unknown) as string
            ).toFixed(2)
          : 0
      },
    },
    {
      title: '现金金额',
      dataIndex: 'cash',
      align: 'right',
      width: Dimen.Price,
      key: 'cash',
      render: (_: any, t: any) => {
        return t.cash ? parseFloat((t.cash as unknown) as string).toFixed(2) : 0
      },
    },
    {
      title: '聚合支付',
      dataIndex: 'aggrPay',
      align: 'right',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.aggrPay ? t.aggrPay?.toFixed(2) : '0.00'
      },
    },
    {
      title: '支付宝',
      dataIndex: 'alipay',
      align: 'right',
      key: 'alipay',
      width: Dimen.Price,
      render: (_: any, t: any) => {
        return t.alipay
          ? parseFloat((t.alipay as unknown) as string).toFixed(2)
          : 0
      },
    },
    {
      title: '会员卡',
      dataIndex: 'memberPay',
      align: 'right',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.memberPay ? t.memberPay?.toFixed(2) : '0.00'
      },
    },
    {
      title: '微信',
      dataIndex: 'wxPay',
      align: 'right',
      key: 'wxPay',
      width: Dimen.Price,
      render: (_: any, t: any) => {
        return t.wxPay
          ? parseFloat((t.wxPay as unknown) as string).toFixed(2)
          : 0
      },
    },
    {
      title: '银联',
      dataIndex: 'unionPay',
      align: 'right',
      key: 'unionPay',
      width: Dimen.Price,
      render: (_: any, t: any) => {
        return t.unionPay
          ? parseFloat((t.unionPay as unknown) as string).toFixed(2)
          : 0
      },
    },
    {
      title: '其他',
      dataIndex: 'otherPay',
      align: 'right',
      key: 'otherPay',
      width: Dimen.Price,
      render: (_: any, t: any) => {
        return t.otherPay
          ? parseFloat((t.otherPay as unknown) as string).toFixed(2)
          : 0
      },
    },
  ]
  const customizePayList = data?.[0]?.customizePay
    ?.map((k: any) => {
      return { ...k }
    })
    ?.sort((a: any, b: any) => a.payMethodName.localeCompare(b.payMethodName))
  if (customizePayList?.length > 0) {
    const iv = customizePayList
    for (let i = 0; i < iv.length; i++)
      cols.push({
        title: iv[i]?.payMethodName,
        width: '8rem',
        dataIndex: 'otherPay_' + i,
        align: 'right',
        key: 'otherPay_' + i,
        render: (_, r: any) => {
          const value = r?.customizePay.filter(
            (vv: any) => vv.payMethodName == iv[i]?.payMethodName
          )[0]?.payAmount
          return Number(value)?.toFixed(2)
        },
      })
  }
  return cols
}

export const PrintColumns = (
  startIndex: number,
  timeFlag: any
): TableColumnType<any>[] => {
  return [
    {
      title: '时间范围',
      dataIndex: 'date',
      align: 'center',
      // width: '4.5rem',
      key: 'date',
      render: (_: any, t: any) => {
        return timeFlag == 0
          ? t?.year + '年'
          : timeFlag == 3
          ? '第' + t?.quarter + '季度'
          : timeFlag == 1
          ? t?.month + '月'
          : timeFlag == 2
          ? t?.date
          : ''
      },
    },
    {
      title: '应收金额',
      dataIndex: 'totalShouldPayAmount',
      align: 'center',
      // width: '5rem',
      key: 'totalShouldPayAmount',
      render: (_: any, t: any) => {
        return t.totalShouldPayAmount
          ? parseFloat((t.totalShouldPayAmount as unknown) as string).toFixed(2)
          : 0
      },
    },
    {
      title: '实收金额',
      dataIndex: 'totalRealPayAmount',
      align: 'center',
      // width: '5rem',
      key: 'totalRealPayAmount',
      render: (_: any, t: any) => {
        return t.totalRealPayAmount
          ? parseFloat((t.totalRealPayAmount as unknown) as string).toFixed(2)
          : 0
      },
    },
    {
      title: '医保金额',
      dataIndex: 'totalInsurancePayAmount',
      align: 'center',
      // width: '5rem',
      key: 'totalInsurancePayAmount',
      render: (_: any, t: any) => {
        return t.totalInsurancePayAmount
          ? parseFloat(
              (t.totalInsurancePayAmount as unknown) as string
            ).toFixed(2)
          : 0
      },
    },
    {
      title: '现金金额',
      dataIndex: 'cash',
      align: 'right',
      // width: '5rem',
      key: 'cash',
      render: (_: any, t: any) => {
        return t.cash ? parseFloat((t.cash as unknown) as string).toFixed(2) : 0
      },
    },
    {
      title: '聚合支付金额',
      dataIndex: 'aggrPay',
      align: 'right',
      // width: '5rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.aggrPay ? t.aggrPay?.toFixed(2) : '0.00'
      },
    },
    {
      title: '支付宝现金金额',
      dataIndex: 'alipay',
      align: 'right',
      key: 'alipay',
      // width: '6rem',
      render: (_: any, t: any) => {
        return t.alipay
          ? parseFloat((t.alipay as unknown) as string).toFixed(2)
          : 0
      },
    },
    {
      title: '会员卡金额',
      dataIndex: 'memberPay',
      align: 'right',
      // width: '5rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.memberPay ? t.memberPay?.toFixed(2) : '0.00'
      },
    },
    {
      title: '微信现金金额',
      dataIndex: 'wxPay',
      align: 'right',
      key: 'wxPay',
      // width: '5rem',
      render: (_: any, t: any) => {
        return t.wxPay
          ? parseFloat((t.wxPay as unknown) as string).toFixed(2)
          : 0
      },
    },
    {
      title: '银联现金金额',
      dataIndex: 'unionPay',
      align: 'right',
      key: 'unionPay',
      // width: '5rem',
      render: (_: any, t: any) => {
        return t.unionPay
          ? parseFloat((t.unionPay as unknown) as string).toFixed(2)
          : 0
      },
    },
    {
      title: '其他现金金额',
      dataIndex: 'otherPay',
      align: 'right',
      key: 'otherPay',
      // width: '5rem',
      render: (_: any, t: any) => {
        return t.otherPay
          ? parseFloat((t.otherPay as unknown) as string).toFixed(2)
          : 0
      },
    },
  ]
}
