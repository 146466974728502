/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-13 11:02:38
 * @LastEditors: K
 * @LastEditTime: 2022-07-11 15:44:40
 */
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface DurgSalesParams {
    billingCategory?: string,
    current?: number,
    departmentId?: number,
    doctorId?: number,
    ephedrineTag?: number,
    materialsCategory?: number,
    orderCategory?: number,
    otc?: number,
    param?: string,
    size?: number,
    staten?: number,
    timeHead?: string,
    timeTail?: string,
}

export function durgSalesPage(params: DurgSalesParams): Request {
    return {
        url: `${API_PREFIX}/blade-outpatient/recipeitem/page`,
        method: "GET",
        params
    }
}

// 导出

export function getDurgsalesExoprtFn(
  params: DurgSalesParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/recipeitem/page/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}