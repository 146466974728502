/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-07-27 17:37:19
 */
import { Col, Row } from "antd";
import React, { ReactElement, useContext } from "react";
import { ThemeContext } from "../../theme/ThemeContext";

interface LabelProps {
  label?: string;
  style?: React.CSSProperties;
  text ?:string;

}

export const Label = ({ label, style,text }: LabelProps): ReactElement => {
  const theme = useContext(ThemeContext);
  return (
    <Row
      style={{ ...style, height: style?.height ? style.height : "2.5rem" }}
      align="middle"
    >
      <Col
        style={{
          width: 2,
          height: 10,
          backgroundColor: theme.c1,
        }}
      />
      <Col
        style={{
          fontSize: style?.fontSize ? style.fontSize : "1.25rem",
          color: theme.tc1,
          fontWeight: "bold",
          marginLeft: 10,
        }}
      >
      {label}
      </Col>
      <Col>{text}</Col>
    </Row>
  );
};
