/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-10 14:26:17
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-10 14:48:55
 */
import { createSlice } from "@reduxjs/toolkit";
import { api, sendAsync } from "../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../app/store";

const initialState = {
  ModalData: [],
  ModalTotal: 0,
  ModalCurrent: 1,
};
export const InventoryStockModalSlice = createSlice({
  name: "InventoryStockModal",
  initialState,
  reducers: {
    setModalData(state, action) {
      state.ModalData = action.payload;
    },
    setModalTotal(state, action) {
      state.ModalTotal = action.payload;
    },
    setModalCurrent(state, action) {
      state.ModalCurrent = action.payload;
    },
  },
});

export const {setModalCurrent,setModalData,setModalTotal} = InventoryStockModalSlice.actions;




export const selectModalData = (state: RootState) => state.InventoryStockModal.ModalData;

export const selectModalToatl = (state: RootState) => state.InventoryStockModal.ModalTotal;

export const selectModalCurrent = (state: RootState) => state.InventoryStockModal.ModalCurrent;

export default InventoryStockModalSlice.reducer;
