import moment from 'moment'
import { DateTimeFormatSimple, DateTimeFormatSimpleOnlyOne } from './datetime'

export function getTime(timeA: any) {
  if (timeA == moment().format('YYYY')) {
    return {
      timeHead: moment().startOf('year').format(DateTimeFormatSimpleOnlyOne),
      timeTail: moment().endOf('year').format(DateTimeFormatSimpleOnlyOne),
    }
  } else if (timeA == moment().add(-1, 'year').format('YYYY')) {
    const startTime = moment()
      .subtract(1, 'years')
      .startOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    const endTime = moment()
      .subtract(1, 'years')
      .endOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    return {
      timeHead: startTime,
      timeTail: endTime,
    }
  } else if (timeA == moment().add(-2, 'year').format('YYYY')) {
    const startTime = moment()
      .subtract(2, 'years')
      .startOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    const endTime = moment()
      .subtract(2, 'years')
      .endOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    return {
      timeHead: startTime,
      timeTail: endTime,
    }
  } else if (timeA == moment().add(-3, 'year').format('YYYY')) {
    const startTime = moment()
      .subtract(3, 'years')
      .startOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    const endTime = moment()
      .subtract(3, 'years')
      .endOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    return {
      timeHead: startTime,
      timeTail: endTime,
    }
  } else if (timeA == moment().add(-4, 'year').format('YYYY')) {
    const startTime = moment()
      .subtract(4, 'years')
      .startOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    const endTime = moment()
      .subtract(4, 'years')
      .endOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    return {
      timeHead: startTime,
      timeTail: endTime,
    }
  } else if (timeA == moment().add(-5, 'year').format('YYYY')) {
    const startTime = moment()
      .subtract(5, 'years')
      .startOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    const endTime = moment()
      .subtract(5, 'years')
      .endOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    return {
      timeHead: startTime,
      timeTail: endTime,
    }
  } else if (timeA == moment().add(-6, 'year').format('YYYY')) {
    const startTime = moment()
      .subtract(6, 'years')
      .startOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    const endTime = moment()
      .subtract(6, 'years')
      .endOf('year')
      .format(DateTimeFormatSimpleOnlyOne)
    return {
      timeHead: startTime,
      timeTail: endTime,
    }
  } else {
    return {
      timeHead: moment().startOf('year').format(DateTimeFormatSimpleOnlyOne),
      timeTail: moment().endOf('year').format(DateTimeFormatSimpleOnlyOne),
    }
  }
}

export function getQuarterTime(timeA: any, quarter: any) {
  if (timeA == moment().format('YYYY')) {
    if (quarter == 1) {
      return {
        timeHead: moment().startOf('year').format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(2, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 2) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(3, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(5, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 3) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(6, 'month')

          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(8, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 4) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(9, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment().endOf('year').format(DateTimeFormatSimpleOnlyOne),
      }
    } else {
      return {
        timeHead: moment().startOf('year').format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment().endOf('year').format(DateTimeFormatSimpleOnlyOne),
      }
    }
  } else if (timeA == moment().add(-1, 'year').format('YYYY')) {
    if (quarter == 1) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(2, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 2) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(3, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(5, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 3) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(6, 'month')

          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(8, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 4) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(9, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .endOf('year')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .endOf('year')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    }
  }
}

export function getQuarterMonthTime(timeA: any, quarter: any) {
  if (timeA == moment().format('YYYY')) {
    if (quarter == 1) {
      return {
        timeHead: moment().startOf('year').format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')

          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 2) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(1, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(1, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 3) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(2, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(2, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 4) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(3, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(3, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 5) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(4, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(4, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 6) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(5, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(5, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 7) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(6, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(6, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 8) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(7, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(7, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 9) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(8, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(8, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 10) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(9, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(9, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 11) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(10, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(10, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 12) {
      return {
        timeHead: moment()
          .startOf('year')
          .add(11, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(11, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else {
      return {
        timeHead: moment().startOf('year').format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment().endOf('year').format(DateTimeFormatSimpleOnlyOne),
      }
    }
  } else if (timeA == moment().add(-1, 'year').format('YYYY')) {
    if (quarter == 1) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 2) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(1, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(1, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 3) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(2, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(2, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 4) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(3, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .startOf('year')
          .add(-1, 'year')
          .add(3, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 5) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(4, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(4, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 6) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(5, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(5, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 7) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(6, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(6, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 8) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(7, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(7, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 9) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(8, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(8, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 10) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(9, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(9, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 11) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(10, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(10, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else if (quarter == 12) {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(11, 'month')
          .startOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .startOf('year')
          .add(11, 'month')
          .endOf('month')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    } else {
      return {
        timeHead: moment()
          .add(-1, 'year')
          .startOf('year')
          .format(DateTimeFormatSimpleOnlyOne),
        timeTail: moment()
          .add(-1, 'year')
          .endOf('year')
          .format(DateTimeFormatSimpleOnlyOne),
      }
    }
  }
}

export function PrintBody(data: any) {
  const tenantName = data?.tenantName
  const dataList = data?.dataList?.map((v:any)=>{
    return {
      ...v,
      customizePay: v?.customizePay
        ?.map((k: any) => {
          return { ...k }
        })
        ?.sort((a: any, b: any) =>
          a.payMethodName.localeCompare(b.payMethodName)
        ),
    }
  })
  const tjTimes = data?.tjTimes
  const titleList = data?.titleList?.sort((a: any, b: any) =>
    a.payMethodName.localeCompare(b.payMethodName)
  )
  let dataStr = ''

  for (let j = 0; j < dataList?.length; j++) {
    dataStr +=
      '    <div style="font-size:8px;display:flex;clear: left;border: 1px solid #000;border-top:none">' +
      '<div style="width:5%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      (Number(j) + 1) +
      '</div>' +
      '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].date +
      '</div>' +
      '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].totalShouldPayAmount +
      '</div>' +
      '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].totalRealPayAmount +
      '</div>' +
      '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].totalInsurancePayAmount +
      '</div>' +
      '<div style="width:10%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].cash +
      '</div>' +
      '<div style="width:10%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].aggrPay +
      '</div>' +
      '<div style="width:9%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].alipay +
      '</div>' +
      '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].memberPay +
      ' </div>' +
      '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].wxPay +
      ' </div>' +
      '<div style="width:10%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].unionPay +
      ' </div>' +
      (dataList[j]?.customizePay?.length
        ? '             <div style="width:10%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
          '                ' +
          dataList[j]?.otherPay +
          '            </div>'
        : '             <div style="width:10%;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
          '                ' +
          dataList[j]?.otherPay +
          '            </div>')
    const payMethods = dataList[j]?.customizePay
    if (payMethods.length > 0) {
      for (let i = 0; i < payMethods.length; i++) {
        dataStr +=
          i + 1 == payMethods?.length
            ? '<div style="width:10%;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
              Number(payMethods?.[i].payAmount)?.toFixed(2) +
              '</div>'
            : '<div style="width:10%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
              Number(payMethods?.[i].payAmount)?.toFixed(2) +
              '</div>'
      }
    }

    dataStr += '    </div>'
  }
  let contents =
    '<div style="page-break-after:always;page-break-inside:always;page-break-before:always;">' +
    '    <h4 style="text-align: center;margin-bottom: 0px">' +
    tenantName +
    '</h4>' +
    '<h3 style="text-align: center;margin-bottom: 5px" >销售费用汇总</h3>' +
    '<div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size:13px">' +
    ' <div style="display: inline-block;width: 50%;text-align: left;"> 统计时段 : ' +
    '<font style="font-weight: 300;">' +
    tjTimes +
    '</font>' +
    '</div>' +
    '<div style="display: inline-block;width: 50%;text-align: right;">验收日期 : ' +
    '<font style="font-weight: 300;">' +
    moment().format(DateTimeFormatSimple) +
    '</font>' +
    '</div>' +
    '</div>' +
    '    <div style="font-size:8px;display:flex;clear: left;border: 1px solid #000">' +
    '<div style="width:5%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">序号' +
    '</div>' +
    '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">时间范围' +
    '</div>' +
    '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">应收金额' +
    '</div>' +
    '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">实收金额' +
    '</div>' +
    '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">医保金额' +
    '</div>' +
    '<div style="width:10%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">现金金额' +
    '</div>' +
    '<div style="width:10%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">聚合支付' +
    '</div>' +
    '<div style="width:9%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">支付宝' +
    '</div>' +
    '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">会员卡' +
    ' </div>' +
    '<div style="width:8%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">微信' +
    ' </div>' +
    '<div style="width:10%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">银联' +
    ' </div>' +
    (titleList?.length
      ? '       <div style="width:10%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
        '           其他' +
        '       </div>'
      : '       <div style="width:10%;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
        '           其他' +
        '       </div>')
  let plusContent: any = ''
  titleList?.map((k: any, index: any) => {
    plusContent +=
      index + 1 == titleList?.length
        ? '        <div style="width:10%;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
          k?.payMethodName +
          '        </div>'
        : '       <div style="width:10%;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
          k?.payMethodName +
          '        </div>'
  })
  contents += plusContent + '    </div>' + dataStr + '</div>'
  return contents
}
