/* eslint-disable react/prop-types */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-31 11:42:19
 * @LastEditors: linxi
 * @LastEditTime: 2021-09-02 11:44:41
 */

import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

export interface ToothSelProps {
  defaultTeethKey: string[];
  teethSelect: (teeth: any) => void;
  selTooth: (sel: boolean, tooth: number, teeth: string) => void;
}

export const CToothSel: React.FC<ToothSelProps> = ({
  defaultTeethKey,
  teethSelect,
  selTooth,
}) => {
  const [teeth] = useState<any[]>(["A", "B", "C", "D", "E"]);

  const [toothP] = useState([0, 1, 2, 3]);

  const [toothStr, setToothStr] = useState(["", "", "", ""]);

  useEffect(() => {
    setToothStr(defaultTeethKey);
  }, [defaultTeethKey]);

  const selTeeth = (t: number, tp: string) => {
    const selObj = toothStr[t] ? toothStr[t].split(",").map(String) : [];
    const index = selObj.findIndex((v: any) => v.substr(0, 1) === tp);
    if (index !== -1) {
      selObj.splice(index, 1);
      selTooth(false, 0, "");
    } else {
      selObj.push(tp);
      selTooth(true, t, tp);
    }
    teethSelect(
      toothStr.map((v: any, i: number) =>
        i === t ? selObj.sort().join(",") : v
      )
    );
  };

  const selTeethKey = (t: number, tp: string) => {
    const selObj = toothStr[t] ? toothStr[t].split(",").map(String) : [];
    const index = selObj.findIndex((v: any) => v.substr(0, 1) === tp);
    return index !== -1 ? true : false;
  };

  return (
    <>
      <Row style={{ height: "100%", verticalAlign: "top" }} gutter={7}>
        {toothP.map((v, i) => {
          return (
            <Col
              span={12}
              key={v}
              style={{
                paddingLeft: i % 2 ? "" : "7px",
                marginTop: i < 2 ? "" : "25px",
              }}
            >
              {i % 2 ? (
                <>
                  {teeth.sort().map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "inline-block",
                          width: "20px",
                          height: "20px",
                          border: "1px solid #eee",
                          margin: "0 2px",
                          lineHeight: "20px",
                          textAlign: "center",
                          cursor: "pointer",
                          background: selTeethKey(v, String(item))
                            ? "#027AFF"
                            : "",
                          color: selTeethKey(v, String(item)) ? "#fff" : "#000",
                        }}
                        onClick={() => {
                          selTeeth(v, String(item));
                        }}
                        key={item}
                      >
                        {item}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {teeth
                    .sort(function (item1, item2) {
                      return item2.localeCompare(item1);
                    })
                    .map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "inline-block",
                            width: "20px",
                            height: "20px",
                            border: "1px solid #eee",
                            margin: "0 2px",
                            lineHeight: "20px",
                            textAlign: "center",
                            cursor: "pointer",
                            background: selTeethKey(v, String(item))
                              ? "#027AFF"
                              : "",
                            color: selTeethKey(v, String(item))
                              ? "#fff"
                              : "#000",
                          }}
                          onClick={() => {
                            selTeeth(v, String(item));
                          }}
                          key={item}
                        >
                          {item}
                        </div>
                      );
                    })}
                </>
              )}
            </Col>
          );
        })}
      </Row>
    </>
  );
};
