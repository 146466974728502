/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-08 11:17:07
 * @LastEditors: linxi
 * @LastEditTime: 2021-07-08 10:55:17
 */
import { unwrapResult } from "@reduxjs/toolkit";
import { queries } from "@testing-library/react";
import { Button, Col, Form, Pagination, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootDispatch } from "../../../app/store";
import { EditableList } from "../../../compnents/list/EditableList";
import {
  getMintenanceResultName,
  MintenanceResults,
} from "../../../models/maintenance";
import { MaintanceParams } from "../../../services/storesearch";
import { AdditionColumns } from "../columns";
import styles from "../Storesearch.module.css";
import { getMaintanceInDetail } from "../storesearchSlice";
const { Option } = Select;

export const StockMaintance = () => {
  const dispatch = useDispatch<RootDispatch>();

  const [form] = Form.useForm();

  const location: any = useLocation();

  const [size, setSize] = useState(5);

  const [total, setTotal] = useState(0);

  const [current, setCurrent] = useState(1);

  const [data, setData] = useState<any>([]);

  const [queries, setQueries] = useState<MaintanceParams>();

  useEffect(() => {
    if (location) {
      dispatch(
        getMaintanceInDetail({
          current,
          size,
          tenantInventoryLedgerId: location.state.selectId,
          ...queries,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setTotal(res.total)
          setData(res.records)
        });
    }
  }, [current, size, queries]);

  return (
    <>
      <Form
        form={form}
        name="control-hooks"
        autoComplete="off"
        onFinish={(vs) => {
          setQueries(vs);
        }}
      >
        <Row
          style={{
            height: "40px",
            margin: "20px 0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Col span={4}>
            <Form.Item name="maintainResult">
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="养护结果"
                onChange={form.submit}
              >
                {MintenanceResults.map((v) => (
                  <Option key={v} value={v}>
                    {getMintenanceResultName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
              查询
            </Button>
          </Col>
        </Row>
      </Form>
      <div className={styles.detailTable}>
        <EditableList
          style={{ height: "100%" }}
          columns={AdditionColumns(size * (current - 1))}
          pagination={false}
          page={{
            items: data,
            current,
            size: size,
            total,
          }}
        />
        <Pagination
          total={total}
          current={current}
          pageSize={size}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={["5", "10", "15", "20"]}
          style={{
            position: "absolute",
            bottom: "30px",
            right: "40px",
          }}
          showTotal={(total) => `共 ${total} 条`}
          onChange={(current, size) => {
            setSize(size as number);
            setCurrent(current);
          }}
        />
      </div>
    </>
  );
};
