import { Col, Modal, ModalProps, Row } from "antd";
import React, { ReactElement, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { traceRoute } from "../../layouts/layoutSlice";
import { ThemeContext } from "../../theme/ThemeContext";

interface SettingsModalProps {
  tenantId?: string;
  tenantName?: string;
}

export const SettingsModal = ({
  tenantId,
  tenantName,
  onCancel,
  ...others
}: SettingsModalProps & ModalProps): ReactElement => {
  const theme = useContext(ThemeContext);

  const dispatch = useDispatch();

  const [name, setName] = useState("");

  const routes = [
    {
      name: "权限管理",
      path: `/admin/tenant/${tenantId}/menu`,
    },
    {
      name: "科室管理",
      path: `/admin/tenant/${tenantId}/department`,
    },
    {
      name: "员工管理",
      path: `/admin/tenant/${tenantId}/user`,
    },
    {
      name: "租户延期",
      path: `/admin/tenant/serviceDelay?${tenantId}`,
    },
    {
      name: "功能设定",
      path: `/admin/tenant/setting/tenentSetting?${tenantId}`,
    },
    {
      name: "打印管理",
      path: `/admin/tenant/${tenantId}/print`,
    },
  ];

  return (
    <Modal
      {...others}
      title="操作设置"
      footer={null}
      onCancel={onCancel}
      width={720}
    >
      <Row justify="space-between" style={{ padding: "0 30px" }}>
        {routes.map((r) => (
          <Col
            key={r.name}
            onMouseEnter={() => setName(r.name)}
            onMouseLeave={() => setName("")}
            onClick={(e) => {
              onCancel && onCancel(e);
              dispatch(
                traceRoute({
                  ...r,
                  name: `${tenantName}-${r.name}`,
                })
              );
            }}
          >
            <div
              style={{
                color: name === r.name ? theme.tt : theme.c1,
                backgroundColor: name === r.name ? theme.c1 : theme.pn,
                width: "180px",
                height: "60px",
                lineHeight: "60px",
                textAlign: "center",
                fontSize: "1.125rem",
                borderTopRightRadius: 10,
                borderBottomLeftRadius: 10,
                border: `1px solid ${theme.c1}`,
                boxShadow: `2px 2px 3px ${theme.c3}`,
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              {r.name}
            </div>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};
