import { Button, notification, Row, Table } from "antd";
import moment from "moment";
import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EditableList,
} from "../../../compnents/list/EditableList";

import { PrinterOutlined } from "@ant-design/icons";
import { NavBar } from "../../../compnents/nav/NavBar";
import { DateTimeFormatSimple } from "../../../models/datetime";
import { InventoryRecordsParams } from "../../../services/inventoryRecords";
import { InventoryRecordsColumns } from "./columns";
import { ThemeContext } from "../../../theme/ThemeContext";
import { getInventoryRecordsList, selectCurrent, selectInventoryRecord, selectTotal, setCurrent, getInventoryRecordsListExoprt, selectPageLoading, setPageLoading } from "./inventoryRecordSlice";
import { InventoryRecordsQuery } from "./Query";
import styles from "./invent.module.css";
import ReactToPrint from "react-to-print";
import { PrintOutlined } from "../../../compnents/icons/Icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../../app/store";

export const InventoryRecords = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const [queries, setQueries] = useState<InventoryRecordsParams>({
    upAccountTimeStart: moment().startOf("day").format(DateTimeFormatSimple),
    upAccountTimeEnd: moment().endOf("day").format(DateTimeFormatSimple),
  });

  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectInventoryRecord);

  const [size, setSize] = useState(10);

  const theme = useContext(ThemeContext);

  const ref = useRef(null);

  const [cell, setCell] = useState<any>({})   //合计栏

  const pageLoadingRX = useSelector(selectPageLoading);


  useEffect(() => {
    if (queries?.timeHead) {
      dispatch(setPageLoading(true))
      dispatch(getInventoryRecordsList({
        ...queries, current: Current, size,
        timeTail: undefined,
        timeHead: undefined,
        upAccountTimeStart:
          moment(queries.timeHead).format(
            DateTimeFormatSimple,
          ),
        upAccountTimeEnd: moment(queries.timeTail).format(
          DateTimeFormatSimple,
        )
      }
      ))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }


  }, [queries, size, Current,]);

  useEffect(() => {
    if (data.length == 0) return
    const tempArr = data.filter((item: any) => {
      return item.totalAccountBooksNumber != -1
    })
    setCell(tempArr[0])
  }, [data])

  const outCluedExport = () => {
    dispatch(getInventoryRecordsListExoprt({
      ...queries, current: Current, size,
      timeTail: undefined,
      timeHead: undefined,
      upAccountTimeStart:
        moment(queries.timeHead).format(
          DateTimeFormatSimple,
        ),
      upAccountTimeEnd: moment(queries.timeTail).format(
        DateTimeFormatSimple,
      )
    }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res;
        const blob = new Blob([content]);
        const fileName = '盘点记录查询.xlsx';
        const selfURL = window[window.webkitURL ? "webkitURL" : "URL"];
        const elink = document.createElement("a");
        if ("download" in elink) {
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = selfURL["createObjectURL"](blob);
          document.body.appendChild(elink);
          // 触发链接
          elink.click();
          selfURL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        } else {
          navigator.msSaveBlob(blob, fileName);
        }
      })
      .then(() => {
        notification.success({
          message: "导出成功",
          duration: 3,
        });
      })
      .catch((res: any) => {
        return
      });
  }
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <NavBar
        style={{ margin: '10px 20px 0px 20px' }}
        where={['库存', '盘点记录查询']}
        backtrace={{
          name: '库房工作站',
          path: '/storage/station',
          state: { station: '3' },
        }}
        buttons={
          <ReactToPrint
            trigger={() => (
              <Button
                style={{
                  border: 'none',
                  background: '#FFFFFF',
                  display: 'flex',
                  alignItems: 'center',
                }}
                size='small'
              >
                <PrintOutlined
                  style={{
                    fill: '#666',
                    width: '18px',
                    height: '18px',
                    marginRight: '8px',
                  }}
                />
                打印
              </Button>
            )}
            content={() => ref.current}
          />
        }
      />
      <div className={styles.content}>
        <InventoryRecordsQuery
          onValueChange={(v) => {
            setQueries({ ...queries, ...v })
            dispatch(setCurrent(1))
          }}
          outClued={() => outCluedExport()}
        />
        <div ref={ref} style={{ height: 'calc(100% - 92px)' }}>
          <EditableList
            style={{ height: '100%', overflowY: 'auto' }}
            loading={pageLoadingRX}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row className={styles.total}>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell className={styles.footingTitle} index={1}>
                    合计
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    align='center'
                    index={2}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={3}>
                    {cell.totalAccountBooksNumber || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={4}>
                    {cell.totalRealCount || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={5}>
                    {cell.totalCheckCount || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={6}>
                    {cell?.totalSplitAccountBooksNumber || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={7}>
                    {cell.totalSplitRealCount || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={8}>
                    {cell.totalSplitCheckCount || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='center' index={9}>
                    {cell.totalUnitPrice || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align='right' index={10}>
                    {cell.totalChangePrice || '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
            bordered
            className={styles.invent}
            rowKey={(_, i) =>
              queries
                ? (queries.size || 0) * (queries.current || 0) + (i || 0)
                : 0
            }
            onChangePage={(current, pageSize) => {
              setSize(pageSize as number)
              dispatch(setCurrent(current))
            }}
            page={{
              items: data,
              current: Current,
              size: size,
              total: total,
            }}
            columns={InventoryRecordsColumns(size * (Current - 1))}
          />
        </div>
      </div>
    </div>
  )
};
