/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-06-30 16:51:32
 * @LastEditors: K
 * @LastEditTime: 2022-07-22 11:06:10
 */
import { Button, Popconfirm, Space, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { getInvoiceStatus } from '../../../models/payment'

type Action = 'result' | 'apply' | 'detail' | 'reason' | 'again'

export const Columns = (
  startIndex: number,
  onAction: (action: Action, t: any, btnText: string) => void
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '4rem',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '班次',
      dataIndex: "shiftName",
      key: "shiftName",
      align: "center",
    },
    {
      title: '时间',
      dataIndex: "shiftEndTime",
      key: "shiftEndTime",
      align: "center",
      render: function shiftEndTime(_, t) {
        return `${t.shiftStartTime} ~ ${t.shiftEndTime}`
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '8rem',
      render: function action(_, t) {
        return (
          <Space>
            <Button
              type='primary'
              ghost
              size='small'
              onClick={() => {
                onAction(_, t, '编辑')
              }}
            >
              编辑
            </Button>
            <Button
              type='primary'
              ghost
              size='small'
              onClick={() => {

                onAction(_, t, '删除')
              }}
            >
              删除
            </Button>
          </Space>
        )
      },
    },
  ]
}

export default Columns;
