import { Button, Col, Form, Input, Row, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCategory,
  selectSetmealCurrent,
  setCategory,
  setSetmealCurrent,
} from './setmealSlice'
import { SetMealPageParams } from '../../services/setmeal'
import styles from './Setmeal.module.css'
const { Option } = Select

export const SetmealQuery = (props: {
  onValueChange: (queries: SetMealPageParams) => void
}) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const current = useSelector(selectSetmealCurrent)

  const category = useSelector(selectCategory)

 

  return (
    <Form
      form={form}
      name='control-hooks'
      autoComplete='off'
      onFieldsChange={(_, fs) => {
        dispatch(setSetmealCurrent(1))
        props.onValueChange({
          ...form.getFieldsValue(),
          category: category ? category : null,
        })
      }}
    >
      <Row gutter={20} >
        <Col span={3}>
          <Form.Item name='name'>
            <Input
              placeholder='请输入套餐名称'
              size='middle'
              allowClear
              prefix={<SearchOutlined />}
            ></Input>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='itemName'>
            <Input
              placeholder='请输入商品名称'
              size='middle'
              allowClear
              prefix={<SearchOutlined />}
            ></Input>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item
            name='state'
            style={{ marginRight: '7px' }}
            initialValue={1}
          >
            <Select placeholder='套餐状态' allowClear>
              <Option value={0}>停用</Option>
              <Option value={1}>启用</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={13}>
          <Form.Item name='category'>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(undefined))
              }}
              className={styles.queryA}
              style={{
                color: category === undefined ? '#027AFF' : '#666666',
                border: category === undefined ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === undefined ? '#eef8ff' : '#ffffff',
              }}
            >
              全部
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(0))
              }}
              className={styles.queryA}
              style={{
                color: category === 0 ? '#027AFF' : '#666666',
                border: category === 0 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 0 ? '#eef8ff' : '#ffffff',
              }}
            >
              西药套餐
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(1))
              }}
              className={styles.queryA}
              style={{
                color: category === 1 ? '#027AFF' : '#666666',
                border: category === 1 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 1 ? '#eef8ff' : '#ffffff',
              }}
            >
              中药套餐
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(2))
              }}
              className={styles.queryA}
              style={{
                color: category === 2 ? '#027AFF' : '#666666',
                border: category === 2 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 2 ? '#eef8ff' : '#ffffff',
              }}
            >
              诊疗套餐
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(3))
              }}
              className={styles.queryA}
              style={{
                color: category === 3 ? '#027AFF' : '#666666',
                border: category === 3 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 3 ? '#eef8ff' : '#ffffff',
              }}
            >
              颗粒剂套餐
            </a>
            <a
              onClick={() => {
                dispatch(setSetmealCurrent(1))
                dispatch(setCategory(4))
              }}
              className={styles.queryA}
              style={{
                color: category === 4 ? '#027AFF' : '#666666',
                border: category === 4 ? '' : '1px solid #DDDDDD',
                fontWeight: 'bold',
                backgroundColor: category === 4 ? '#eef8ff' : '#ffffff',
              }}
            >
              处置项目套餐
            </a>
          </Form.Item>
        </Col>
        <Col span={2} offset={1}>
          <Button
            type='primary'
            style={{ float: 'right' }}
            onClick={() => {
              props.onValueChange({
                ...form.getFieldsValue(),
                category: category ? category : null,
              })
            }}
          >
            查询
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
