/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-10-23 17:32:13
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-24 14:02:25
 */

import moment from 'moment'
import {
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnly,
  DateTimeFormatSimpleOnlyOne,
  DateTimeFormatSimpleText,
} from './datetime'

// 代煎处方明细
export function ReplaceMackRecipeDetailPrint(data: any) {
  const tenantName = data?.tenantName
  const dataList = data?.dataList
  const amount = data?.amount
  let dataStr = ''

  for (let j = 0; j < dataList?.length; j++) {
    dataStr +=
      '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
      '<div style="width: 8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      (Number(j) + 1) +
      '</div>' +
      '<div style="width:13%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].patientName +
      '</div>' +
      '<div style="width:16%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      (dataList[j]?.recipeTime
        ? moment(dataList[j]?.recipeTime)?.format(DateTimeFormatSimple)
        : '') +
      '</div>' +
      '<div style="width:16%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].drugName +
      '</div>' +
      '<div style="width:11%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].tip +
      '</div>' +
      '<div style="width:11%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].countPerTip +
      '</div>' +
      '<div style="width:11%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].drugCount +
      '</div>' +
      '<div style="width:14%;display: table-cell;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">' +
      dataList[j].supplierName +
      '</div>' +
      '</div>'
  }
  const contents =
    '<div >' +
    '    <h4 style="text-align: center;margin-bottom: 0px">' +
    tenantName +
    '</h4>' +
    '<h3 style="text-align: center;margin-bottom: 10px" >代煎处方明细</h3>' +
    '</div>' +
    '<div style="display: table;width: 100%;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #000000;border-left: 1px solid #000000;border-top: 1px solid #000000;border-bottom: 1px solid #000000;text-align: center;">' +
    '<div style="width:8%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">序号' +
    '</div>' +
    '<div style="width:13%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">姓名' +
    '</div>' +
    '<div style="width:16%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">开单日期' +
    '</div>' +
    '<div style="width:16%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">药品名称' +
    '</div>' +
    '<div style="width:11%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">帖数' +
    '</div>' +
    '<div style="width:11%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">每帖数量' +
    '</div>' +
    '<div style="width:11%;display: table-cell;box-sizing: border-box;border-right: solid 1px #000000;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">总量' +
    '</div>' +
    '<div style="width:14%;display: table-cell;box-sizing: border-box;font-size: 8px;font-weight: bold;word-break:break-all;vertical-align: middle;text-align: center;">代煎公司' +
    '</div>' +
    '</div>' +
    dataStr +
    '      <div style="font-size: 8px;display:table;width:100%;clear: left;border-right: 1px solid #000;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 73%;display: table-cell;vertical-align: middle;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
    '                合计：' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:11%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    amount +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:14%;display: table-cell;vertical-align: middle;text-align:center;box-sizing: border-box;">' +
    '                ' +
    '            </div>' +
    '        </div>' +
    '    <div>' +
    '<div style="text-align: left;padding-bottom: 8px;font-weight: 600;font-size:13px;margin-top:10px">' +
    '<div style="display: inline-block;width: 50%;text-align: left;">核对人:' +
    '</div>' +
    '<div style="display: inline-block;width: 50%;text-align: right;">制单日期: ' +
    moment().format(DateTimeFormatSimpleOnlyOne) +
    '</div>' +
    '</div>' +
    '</div>'
  return contents
}
