/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-08-29 17:21:08
 * @LastEditors: linxi
 * @LastEditTime: 2024-08-07 18:50:57
 */
import {
  DollarCircleFilled,
  WechatFilled,
  AlipayCircleFilled,
  CreditCardFilled,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Modal,
  notification,
  Row,
  Space,
  TableColumnType,
  Tooltip,
} from 'antd'
import React, { ReactElement, useContext, useState, useEffect } from 'react'
import { JuhePay, MembersIcon, YBIcon } from '../../../compnents/icons/Icons'
import { EditableList } from '../../../compnents/list/EditableList'
import {
  PayMethod,
  getPayMethodName,
  Payment,
  getIconImg,
} from '../../../models/payment'
import { PaymentResult } from '../../../models/paymentresult'
import { ThemeContext } from '../../../theme/ThemeContext'
import {
  copyTreatment,
  getTreatment,
} from '../../treatment/diagnosis/diagnosisSlice'
import { Recipe } from '../../../models/recipe'
import {useSelector} from "react-redux";
import {selectTenantName} from "../../../app/applicationSlice";

interface RealPayModalProps {
  refund: any
  buttonLoading?: any
  stkButtonLoading?: any
  dzpzButtonLoading?: any
  onOK: (type: string) => void
  onCancel: () => void
  resultData: any
}

export const RealPayModal = ({
  refund,
  buttonLoading,
  stkButtonLoading,
  dzpzButtonLoading,
  onOK,
  onCancel,
  resultData,
}: RealPayModalProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const [loading, setLoading] = useState(false)

  const [returnPremiumLoading, setReturnPremiumLoading] = useState(false)
  const [returnSTKPremiumLoading, setReturnSTKPremiumLoading] = useState(false)
  const [returnDZPZPremiumLoading, setReturnDZPZPremiumLoading] = useState(
    false
  )
  const tenantName = useSelector(selectTenantName)

  const [isHavingInsurance, setIsHavingInsurance] = useState(false)
  useEffect(() => {
    !!refund && setLoading(false)
  }, [refund])

  useEffect(() => {
    setIsHavingInsurance(false)
    resultData?.map((v: any) => {
      if (v.payMethod == '99') {
        setIsHavingInsurance(true)
      }
    })
  }, [resultData])

  useEffect(() => {
    const flag = !!refund
    if (flag) {
      setReturnPremiumLoading(buttonLoading)
      setReturnSTKPremiumLoading(stkButtonLoading)
      setReturnDZPZPremiumLoading(dzpzButtonLoading)
    }
  }, [buttonLoading, stkButtonLoading, dzpzButtonLoading,refund])

  return (
    <div>
      <Modal
        title='预退费'
        destroyOnClose
        visible={!!refund}
        onCancel={() => onCancel()}
        confirmLoading={loading}
        footer={null}
      >
        <Row style={{ fontSize: '1.125rem', color: theme.tc1 }}>
          <EditableList
            style={{
              marginTop: 12,
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 20,
              flex: 1,
              overflow: 'auto',
            }}
            pagination={false}
            dataSource={resultData}
            bordered
            size='small'
            columns={
              [
                {
                  title: '退费金额',
                  dataIndex: 'realPayAmount',
                  align: 'center',
                  render: (_, t) => t.realPayAmount.toFixed(2),
                },
                {
                  title: '应退费用',
                  dataIndex: 'realPayAmount',
                  align: 'center',
                  render: (_, t) => t.realPayAmount.toFixed(2),
                },
                {
                  title: '退费方式',
                  dataIndex: 'payMethod',
                  align: 'center',
                  render: function payMethod(_, t) {
                    const flag =
                      t.payMethod == 1 ||
                      t.payMethod == 2 ||
                      t.payMethod == 3 ||
                      t.payMethod == 4 ||
                      t.payMethod == 5 ||
                      t.payMethod == 6 ||t?.payMethod == 99
                    const icons = getIconImg(t?.payMethodICon)

                    return flag ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: 35,
                        }}
                      >
                        {[
                          {
                            icon: DollarCircleFilled,
                            color: '#69A6FD',
                            method: PayMethod.Cash,
                            type: 1,
                          },
                          {
                            icon: DollarCircleFilled,
                            color: '#69A6FD',
                            method: PayMethod.YB,
                            type: 1,
                          },
                          {
                            icon: WechatFilled,
                            color: '#00BC00',
                            method: PayMethod.Weipay,
                            type: 1,
                          },
                          {
                            icon: AlipayCircleFilled,
                            color: '#00A9F2',
                            method: PayMethod.Alipay,
                            type: 1,
                          },
                          {
                            icon: CreditCardFilled,
                            color: '#107C84',
                            method: PayMethod.UnionPay,
                            type: 1,
                          },
                          {
                            icon: DollarCircleFilled,
                            color: '#69A6FD',
                            method: PayMethod.Juhe,
                            type: 0,
                          },
                          {
                            icon: DollarCircleFilled,
                            color: '#69A6FD',
                            method: PayMethod.HY,
                            type: 2,
                          },
                        ].map(({ icon, color, method, type }) => {
                          const Icon = icon
                          return (
                            t.payMethod == method && (
                              <Tooltip
                                key={method}
                                overlay={getPayMethodName(method)}
                              >
                                {t.payMethod == 99 ? (
                                  <YBIcon
                                    style={{
                                      position: 'relative',
                                      top: '6px',
                                      fontSize: '1.5rem',
                                      height: '24px',
                                      width: '24px',
                                      padding: '4px',
                                      color,
                                      borderRadius: '50%',
                                      display: type == 1 ? 'block' : 'none',
                                      background: '#088f52',
                                    }}
                                  />
                                ) : (
                                  <Icon
                                    style={{
                                      position: 'relative',
                                      top: '6px',
                                      fontSize: '1.5rem',
                                      color,
                                      borderRadius: 2,
                                      display: type == 1 ? 'block' : 'none',
                                    }}
                                  />
                                )}

                                <div>
                                  <JuhePay
                                    mode='small'
                                    style={{
                                      display: type == 0 ? 'block' : 'none',
                                      borderRadius: 2,
                                      padding: '4px',
                                      width: '40px',
                                      height: '40px',
                                      fontSize: '30px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </div>
                                <div>
                                  <MembersIcon
                                    mode='small'
                                    style={{
                                      display: type == 2 ? 'block' : 'none',
                                      borderRadius: 2,
                                      padding: '4px',
                                      width: '35px',
                                      height: '35px',
                                      fontSize: '30px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            )
                          )
                        })}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          height: 35,
                        }}
                      >
                        <Tooltip key={t?.id} overlay={t?.payMethodName}>
                          <img
                            src={icons}
                            style={{
                              borderRadius: 2,
                              padding: '4px',
                              width: '32px',
                              height: '32px',
                              fontSize: '30px',
                              cursor: 'pointer',
                            }}
                          />
                        </Tooltip>
                      </div>
                    )
                  },
                },
              ] as TableColumnType<PaymentResult>[]
            }
          />
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            style={{
              marginRight: '14px',
            }}
            onClick={() => {
              onCancel()
            }}
          >
            取消
          </Button>
          {isHavingInsurance ? (
            <>
              <Button
                loading={returnSTKPremiumLoading}
                style={{
                  marginRight: '14px',
                }}
                type='primary'
                onClick={() => {
                  setLoading(true)
                  onOK('1')
                }}
              >
                实体卡退费
              </Button>
              {tenantName?.indexOf('测试') >= 0 && (
                  <Button
                      loading={returnPremiumLoading}
                      style={{
                        marginRight: '14px',
                      }}
                      type='primary'
                      onClick={() => {
                        setLoading(true)
                        onOK('3')
                      }}
                  >
                    身份证退费
                  </Button>
              )}
              <Button
                loading={returnDZPZPremiumLoading}
                style={{
                  marginRight: '14px',
                }}
                type='primary'
                onClick={() => {
                  setLoading(true)
                  onOK('2')
                }}
              >
                电子凭证退费
              </Button>
            </>
          ) : (
            <>
              <Button
                loading={returnPremiumLoading}
                style={{
                  marginRight: '14px',
                }}
                type='primary'
                onClick={() => {
                  setLoading(true)
                  setReturnPremiumLoading(true)
                  onOK('0')
                }}
              >
                退费
              </Button>
            </>
          )}
        </Row>
      </Modal>
    </div>
  )
}
