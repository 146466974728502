/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2022-01-04 13:58:27
 * @LastEditors: linxi
 */
import { EditOutlined,  } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Modal,
  ModalProps,
  Steps,
  Cascader as Cascaded,
  message,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import {
  getPreferencesUpgradeAsync,
  getTenantPreferencesListAsync,
} from '../recipeEditorSlice'
import styles from './RecipeItemModal.module.css'
interface ReplaceMakeSettingModalProps {
  recipeId?: number
  patientId?: number
  recipeCategory?: number
  isSetting?: boolean
  isOpenReplaceMake?: any
  onOk?: () => void
  onCancel?: () => void
  addPersionalMessage?: (potionType?: any, supplierId?: any, id?: any) => void
  visible?: boolean
}

export const AddOrEditDecoctingPersionalSettingModal = ({
  recipeId,
  patientId,
  recipeCategory,
  isSetting,
  isOpenReplaceMake,
  onOk,
  onCancel,
  addPersionalMessage,
  visible,
}: ReplaceMakeSettingModalProps & ModalProps): ReactElement => {
  const { Step } = Steps

  const dispatch = useDispatch<RootDispatch>()

  const [messageApi, contextHolder] = message.useMessage()

  const [selectId, setSelectId] = useState<any>()

  const [selectPotionType, setSelectPotionType] = useState<any>()

  const [dataList, setDataList] = useState<any>()

  useEffect(() => {
    if (visible) {
      localStorage.setItem('notUseGlobalKeyboardAction', 'true')
      // 获取机构支持的代煎方式
      dispatch(getTenantPreferencesListAsync())
        .then(unwrapResult)
        .then((v) => {
          setDataList(v)
          const defaultSelectedData = v?.filter((item: any) => {
            return item?.defaultSelected == 1
          })
          setSelectId(defaultSelectedData?.[0]?.id)
          setSelectPotionType(defaultSelectedData?.[0]?.potionType)
        })
    }
  }, [visible])

  const reset = () => {
    localStorage.setItem('notUseGlobalKeyboardAction', 'false')
    setSelectId('')
  }

  return (
    <>
      {contextHolder}
      <Modal
        visible={visible}
        title='代煎偏好设置'
        width={400}
        maskClosable={false}
        footer={null}
        className={styles.persionalSetting}
        destroyOnClose
        style={{ overflow: 'hidden', padding: '0' }}
        bodyStyle={{
          padding: '30px 15px 0 15px',
          height: 580,
          overflow: 'auto',
        }}
        onCancel={(e) => {
          onCancel && onCancel(e)
          reset()
        }}
        onOk={(e) => {
          onOk && onOk()
          reset()
        }}
      >
        <div className={styles.mainContent}>
          <div className={styles.mainTopContent}>
            {dataList?.length ? (
              dataList?.map((v: any, index: any) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <div
                        key={index}
                        className={
                          selectId == v?.id
                            ? styles.selectBackContent
                            : styles.backContent
                        }
                        onClick={() => {
                          setSelectId(v?.id)
                          setSelectPotionType(v?.potionType)
                          dispatch(getPreferencesUpgradeAsync(v?.id))
                            .then(unwrapResult)
                            .then((val) => {
                              messageApi.open({
                                type: 'success',
                                content:
                                  '已设置' +
                                  (v?.potionType == 2 || v?.potionType == 0
                                    ? v?.potionTypeName
                                    : v?.supplierName) +
                                  '为默认代煎方式',
                                className: 'custom-class',
                              })
                            })
                        }}
                      >
                        {v?.potionType == 2 || v?.potionType == 0
                          ? v?.potionTypeName
                          : v?.supplierName}
                      </div>
                      <div
                        className={styles.iconBackContent}
                        onClick={() => {
                          setSelectPotionType(v?.potionType)
                          localStorage.setItem(
                            'notUseGlobalKeyboardAction',
                            'false'
                          )
                          addPersionalMessage &&
                            addPersionalMessage(
                              v?.potionType,
                              v?.supplierId,
                              v?.id
                            )
                        }}
                      >
                        <EditOutlined />
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div style={{ marginTop: '150px', color: '#B2B2B2' }}>
                尚未添加偏好设置
              </div>
            )}
          </div>
          {/* <div className={styles.bottonButton}>
            <Button
              style={{ height: '40px' }}
              className={styles.cancelBackContent}
              onClick={() => {
                onCancel && onCancel()
                reset()
              }}
            >
              取消
            </Button>
            <Button
              ghost
              type='primary'
              style={{ height: '40px' }}
              disabled={!isOpenReplaceMake ? true : false}
              className={styles.addBackContent}
              onClick={() => {
                addPersionalMessage && addPersionalMessage(selectPotionType, '')
                localStorage.setItem('notUseGlobalKeyboardAction', 'false')
              }}
            >
              <PlusOutlined />
              添加偏好
            </Button>
          </div> */}
        </div>
      </Modal>
    </>
  )
}
