/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-03 20:22:17
 * @LastEditors: linxi
 * @LastEditTime: 2023-11-20 15:11:55
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import * as service from '../../../services/drugStatic'
import { drugStaticParams } from '../../../services/drugStatic'

interface DrugStatic {
  DrugStaticData: []
  loading?: false
  Total: 0
  Current: 1
}

const initialState = {
  DrugStaticData: [],
  loading: false,
  Total: 0,
  Current: 1,
} as DrugStatic

const drugStaticSlice = createSlice({
  name: 'drugStaticSlice',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setData(state, action) {
      state.DrugStaticData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
  },
})

export const { setData, setTotal, setCurrent, setLoading } = drugStaticSlice.actions

export const selectDrugStaticData = (state: RootState) =>
  state.drugStatic.DrugStaticData

export const selectTotal = (state: RootState) => state.drugStatic.Total

export const selectCurrent = (state: RootState) => state.drugStatic.Current

export const selectLoading = (state: RootState) => state.drugStatic.loading


export function getDrugStaticList(params: drugStaticParams): RootThunk {
  return api(service.getDrugStaticList(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setLoading(false))
  })
}

//导出
export const getDrugStaticExport = createAsyncThunk<
  void,
  drugStaticParams,
  RootThunkApi
>('doctorAudit/getDrugStaticExport ', async (params, api) => {
  return sendAsync(service.drugStaticExport(params), api)
})

export default drugStaticSlice.reducer
