/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-27 15:08:32
 * @LastEditors: sj
 * @LastEditTime: 2022-12-01 20:22:55
 */

import { CaretDownFilled } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Upload,
} from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { DateRangeType, DateSelect } from '../../compnents/form/DateSelect'
import { User } from '../../models/user'
import { ThemeContext } from '../../theme/ThemeContext'
import { DataType } from '../putstorage/putstorage'
import { getFullDoctorList } from '../registration/list/registrationSlice'
import styles from './inspectList.module.css'
import { InspectListModal } from './modal'

export const InspectList = () => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [value, setValue] = useState<any>(Boolean)
  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [type, setType] = useState<any>(false)

  const [doctors, setDoctors] = useState<User[]>([])

  const [selectionType] = useState<'checkbox' | 'radio'>('checkbox')

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      return
    },
  }

  const data =
    value === true
      ? [
          {
            time: '2021/11/29',
            name: '张三',
            project: '血常规(五分类)',
            schedule: '检验中',
            doctorName: '王主任',
            report: '-',
            printState: '未打印',
            state: 0,
          },
        ]
      : [
          {
            time: '2021/11/29',
            name: '张三',
            project: '血常规(五分类)',
            schedule: '检验中',
            doctorName: '王主任',
            report: '-',
            printState: '未打印',
            state: 0,
          },
          {
            time: '2021/11/27',
            name: '李四',
            project: '血型抗体效价测定',
            schedule: '检验完成',
            doctorName: '李医生',
            report: '报告单1',
            printState: '已打印',
            state: 1,
          },
        ]

  useEffect(() => {
    setPageLoading(true)
    dispatch(getFullDoctorList())
      .then(unwrapResult)
      .then((ds) => setDoctors(ds))
      .finally(() => {
        setPageLoading(false)
      })
  }, [])

  return (
    <>
      <div className={styles.main}>
        <div style={{ marginTop: '10px' }}>
          <Form form={form} autoComplete='off' name='control-hooks'>
            <Row>
              <Space style={{ flex: 1 }}>
                <Form.Item name='treatmentDoctorId' noStyle>
                  <Select
                    allowClear
                    style={{ width: 180 }}
                    placeholder='医生'
                    onChange={form.submit}
                    // suffixIcon={
                    //   <CaretDownFilled style={{ color: theme.tc3 }} />
                    // }
                  >
                    {doctors.map((d) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name='patient' noStyle>
                  <Input
                    style={{ width: 160 }}
                    placeholder='请输入患者'
                    allowClear
                    onChange={form.submit}
                  ></Input>
                </Form.Item>
                <DateSelect
                  placeholder='报告日期'
                  labelPrefix=''
                  namePrefix='time'
                  style={{ width: '8.5rem' }}
                  onChange={form.submit}
                />
                {/* <Form.Item noStyle>
                  <Checkbox
                    onChange={(e) => {
                      setValue(e.target.checked);
                    }}
                  >
                    只显示未打印
                  </Checkbox>
                </Form.Item>
                <Form.Item noStyle>
                  <Button type="primary" ghost>
                    打印设置
                  </Button>
                </Form.Item>
                <Form.Item noStyle>
                  <Button type="primary">打印</Button>
                </Form.Item> */}
                <Button type='primary'>查询</Button>
              </Space>
              <Space>
                <Button type='primary'>检验申请</Button>
              </Space>
            </Row>
          </Form>
          <div className={styles.table}>
            <Table
              pagination={false}
              loading={pageLoading}
              rowSelection={
                {
                  type: selectionType,
                  ...rowSelection,
                } as TableRowSelection<any>
              }
              columns={[
                {
                  title: '序号',
                  key: 'no',
                  align: 'center',
                  render: (_1, _2, index) => `${index + 1}`,
                },
                {
                  title: '检验日期',
                  dataIndex: 'time',
                  key: 'time',
                  align: 'center',
                },
                {
                  title: '患者姓名',
                  dataIndex: 'name',
                  key: 'name',
                  align: 'center',
                },
                {
                  title: '检验项目',
                  dataIndex: 'project',
                  key: 'project',
                  align: 'center',
                },
                {
                  title: '检验进度',
                  dataIndex: 'schedule',
                  key: 'schedule',
                  align: 'center',
                },
                {
                  title: '医生姓名',
                  dataIndex: 'doctorName',
                  key: 'doctorName',
                  align: 'center',
                },
                {
                  title: '报告单',
                  dataIndex: 'report',
                  key: 'report',
                  align: 'center',
                },
                {
                  title: '打印状态',
                  dataIndex: 'printState',
                  key: 'printState',
                  align: 'center',
                  render: function ShowActions(_, t: any) {
                    return t.printState === '未打印' ? (
                      <p style={{ color: 'red' }}> {t.printState} </p>
                    ) : (
                      <p>{t.printState}</p>
                    )
                  },
                },
                {
                  title: '操作',
                  dataIndex: '',
                  align: 'center',
                  width: '20%',
                  render: function Element(_, t) {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {t.state === 0 ? (
                          <>
                            <Upload>
                              <Button
                                size='small'
                                type='primary'
                                style={{
                                  marginLeft: '10px',
                                }}
                              >
                                添加结果数据
                              </Button>
                            </Upload>
                          </>
                        ) : (
                          <>
                            <Button
                              type='primary'
                              size='small'
                              style={{
                                marginLeft: '10px',
                              }}
                              onClick={() => {
                                setIsModalVisible(true)
                              }}
                            >
                              查看结果
                            </Button>
                            <Button
                              type='primary'
                              size='small'
                              style={{
                                marginLeft: '10px',
                              }}
                            >
                              打印报告
                            </Button>
                          </>
                        )}
                      </div>
                    )
                  },
                },
              ]}
              dataSource={data}
              style={{ paddingTop: '10px' }}
            />
          </div>
          <Pagination
            total={2}
            current={1}
            pageSize={10}
            showSizeChanger
            pageSizeOptions={['10', '20', '50', '100', '500']}
            showQuickJumper
            style={{
              position: 'absolute',
              bottom: '40px',
              right: '40px',
            }}
            showTotal={(total) => `共 ${total} 条`}
          />
        </div>
        <InspectListModal
          visible={isModalVisible}
          onOk={() => {
            setIsModalVisible(false)
          }}
          onCancel={() => {
            setIsModalVisible(false)
          }}
        />
      </div>
    </>
  )
}
