import { PlusOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Col, Form, Modal } from "antd";
import moment from "moment";
import React, { ReactElement, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectEdition } from "../../../app/applicationSlice";
import { RootDispatch } from "../../../app/store";
import { EditableList } from "../../../compnents/list/EditableList";
import { traceRoute } from "../../../layouts/layoutSlice";
import { DateTimeFormatSimpleOnly } from "../../../models/datetime";
import { CompletedOperationParams } from "../../../services/completedOperation";
import { ThemeContext } from "../../../theme/ThemeContext";
import { OperationDetail } from "../OperationDetail";
import { CompletedOperationColumns, ToothCompletedOperationColumns } from "./column";
import { getCompletedOperationList } from "./completedOperationSlice";
import { CompletedOperationQuery } from "./Query";

export const CompletedOperation = (props: {
  patientId?: string
  toothPatient?: boolean
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const theme = useContext(ThemeContext)

  const history = useHistory()

  const [total, setTotal] = useState<any>()

  const [data, setData] = useState<any[]>([])

  const [id, setId] = useState<any>()

  const [queries, setQueries] = useState<CompletedOperationParams>({
    current: 0,
    size: 10,
    flag: 3,
    startTime: moment().format(DateTimeFormatSimpleOnly),
    endTime: moment().format(DateTimeFormatSimpleOnly),
  })

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  const [detailVisible, setDetailVisible] = useState(false)

  useEffect(() => {
    if (!queries.current) return
    setPageLoading(true)
    dispatch(
      getCompletedOperationList({ ...queries, patientId: props.patientId })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setData(res.records)
        setTotal(res.total)
      })
      .finally(() => setPageLoading(false))
    !props.patientId &&
      sessionStorage.setItem(
        '/operationStation_CompletedOperation',
        JSON.stringify(queries)
      )
  }, [dispatch, queries, props.patientId])

    const columns =
      hisVersion == 1
        ? ToothCompletedOperationColumns
        : CompletedOperationColumns
  return (
    <Col
      style={{
        height: `calc(100% - ${props.patientId ? '0px' : '30px'})`,
        overflow: 'hidden',
        position: 'relative',
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!props.patientId && (
        <Button
          icon={<PlusOutlined />}
          type='primary'
          size='small'
          style={{
            position: 'fixed',
            top: 68,
            right: 45,
          }}
          onClick={() => {
            history.push({
              pathname: '/applyForOperation',
              state: {
                tag: 2,
              },
            })
          }}
        >
          手术申请
        </Button>
      )}
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '0 10px',
          paddingTop: props.patientId ? 0 : 10,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CompletedOperationQuery
          onValueChange={(v) => {
            setQueries(v)
          }}
          patientId={props.patientId}
        />

        <EditableList
          bordered={props?.toothPatient ? true : false}
          loading={pageLoading}
          page={{
            items: data,
            current: queries.current || 1,
            size: queries.size || 10,
            total: total,
          }}
          columns={columns(() => {
            setId(id)
          }, (queries.size || 10) * ((queries.current || 1) - 1)).filter(
            (v: any) => (props.toothPatient ? true : v.title != '术后诊断')
          )}
          onChangePage={(current: any, size: any) => {
            setQueries({ ...queries, current, size })
          }}
          onRow={(r) => ({
            onDoubleClick: () => {
              if (props.toothPatient) {
                setDetailVisible(true)
              } else {
                dispatch(
                  traceRoute({
                    name: '手术详情',
                    path: '/operationDetail',
                    state: {
                      id: r.id,
                      tag: 2,
                    },
                  })
                )
              }
              setId(r.id)
            },
          })}
        />
      </Col>
      <Modal
        visible={detailVisible}
        title='手术详情'
        footer={null}
        width={1200}
        onCancel={() => {
          setDetailVisible(false)
        }}
        bodyStyle={{
          height: 700,
          overflow: 'auto',
        }}
      >
        <OperationDetail
          patientId={id}
          toothPatient={props.toothPatient}
          Ctag={2}
        />
      </Modal>
    </Col>
  )
};
