import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { api ,sendAsync} from "../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../app/store";
import * as service from "../../../services/statement";
import { statementParams, GetDoctorSaleEntriesParams } from "../../../services/statement";
interface Statement {
  Statement: [],
  Total: 0,
  Current: 1,
  states: [];
  pageLoading: false

}

const initialState: Statement = {
  Statement: [],
  Total: 0,
  Current: 1,
  states:[],
  pageLoading: false
};

const statementSlice = createSlice({
  name: "statementSlice",
  initialState,
  reducers: {
    setData(state, action) {
      state.Statement = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
  

    setState: (state, action) => {
      state.states= action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
  },
});

export const {setData, 
  setTotal,
  setCurrent, setState, setPageLoading } = statementSlice.actions;


  export const selectStatement = (state: RootState) => state.statement.Statement

  export const selectTotal = (state: RootState) => state.statement.Total
  
  export const selectCurrent = (state: RootState) => state.statement.Current
  

export const selectState = (state: RootState) => state.statement.states;

export const selectPageLoading = (state: RootState) => state.statement.pageLoading;


export function getStatementList(params: statementParams): RootThunk {
  return api(service.getStatementList(params), (data: any, dispatch) => {
    dispatch(setData(data.page?.records))
      dispatch(setTotal(data.page?.total))
    dispatch(setPageLoading(false))
   
  });
}

export const getStatementLists = createAsyncThunk<void, statementParams, RootThunkApi>(
  'doctorAudit/getStatementLists ',
  async (params, api) => {
    return sendAsync(service.getStatementList(params), api)
  }
)


export const selectGetDoctorSaleEntriesParams = (
  state: RootState
): GetDoctorSaleEntriesParams => state.doctorSale.params;

// 导出
export const getStatementExoprt = createAsyncThunk<
  void,
  statementParams,
  RootThunkApi
>("doctorAudit/getStatementExoprt ", async (params, api) => {
  return sendAsync(service.getDoctorAuditExport(params), api);
});

// export function getStatementStates(timeHead:string,timeTail:string,): RootThunk {
//   return(dispatch,getState,args)=>{
//     const state = getState().statement.Statement;
//    api(service.getStatementState(timeHead,timeTail), (data: any, dispatch) => {
//     const list = data
//     dispatch(setState(list));
    
//   })(dispatch,getState,args);
// };
// }

export const getStatementStates = createAsyncThunk<
  void,
  service.TimeParams,
  RootThunkApi
>("completion/getDiseaseCompletionsStatus", async (params, api) => {
  return sendAsync(service.getStatementState(params), api)
    
});



export default statementSlice.reducer;
