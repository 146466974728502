/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-27 10:51:57
 */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import { LoadingState } from '../../../models/common'
import { emptyOf, Pagination } from '../../../models/pagination'
import { fromJson, changeWater } from '../../../models/changeWater'
import * as service from '../../../services/changewater'
import { changeWaterParams } from '../../../services/changewater'
interface ChangeWaterState {
  ChargeWatertData: []
  SummaryData: any
  Total: 0
  Current: 1
  detail: changeWater[]
  pageLoading: false
}

const initialState: ChangeWaterState = {
  ChargeWatertData: [],
  SummaryData: {},
  Total: 0,
  Current: 1,
  detail: [],
  pageLoading: false,
}

const changeWaterSlice = createSlice({
  name: 'changeWaterSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.ChargeWatertData = action.payload
    },
    setSummaryData(state, action) {
      state.SummaryData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setDetail: (state, action) => {
      state.detail = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

// 导出
export const getChangeWaterExoprt = createAsyncThunk<
  void,
  changeWaterParams,
  RootThunkApi
>('doctorAudit/getChangeWaterExoprt ', async (params, api) => {
  return sendAsync(service.getDoctorAuditExport(params), api)
})

export const {
  setData,
  setSummaryData,
  setTotal,
  setCurrent,
  setDetail,
  setPageLoading,
} = changeWaterSlice.actions

export const selectChargeWatertData = (state: RootState) =>
  state.changeWater.ChargeWatertData

  export const selectChargeWatertSummaryData = (state: RootState) =>
    state.changeWater.SummaryData

export const selectTotal = (state: RootState) => state.changeWater.Total

export const selectCurrent = (state: RootState) => state.changeWater.Current

export const selectPageLoading = (state: RootState) =>
  state.changeWater.pageLoading

export const selectDetail = (state: RootState) => state.changeWater.detail

export function getChangeWaterList(params: changeWaterParams): RootThunk {
  return api(service.getChangeWaterList(params), (data: any, dispatch) => {
    dispatch(setData(data?.paymentHistoryVOPage?.records || []))
    dispatch(setTotal(data?.paymentHistoryVOPage?.total))
    dispatch(setSummaryData(data))
    dispatch(setPageLoading(false))
  })
}
export function getChangeWaterDetail(id?: number): RootThunk {
  return api(service.getChangeWaterDetail(id), (data: any, dispatch) => {
    const list = data
    dispatch(setDetail(list))
  })
}

export default changeWaterSlice.reducer
