import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunkApi } from '../../app/store'
import * as DT from '../../services/coupons'


// 优惠券设置列表
export const getCouponsSettingList = createAsyncThunk<
    void,
    DT.CouponsSettingListPrams,
    RootThunkApi<void>
>('clinic/getInsuranceSettlementRecordList', async (params, api) => {
    return sendAsync(DT.couponsSettingList(params), api)
})

//优惠券新建与修改
export const getCouponsSettingSubmit = createAsyncThunk<
    void,
    DT.CouponsSettingSubmitParams,
    RootThunkApi<void>
>('clinic/getDoctorManagementEditSubmit', async (params, api) => {
    return sendAsync(DT.couponsSettingSubmit(params), api)
})

//生成券
export const getCouponsSettingGenerate = createAsyncThunk<
    void,
    DT.CouponsSettingGenerateParams,
    RootThunkApi<void>
>('clinic/getDoctorManagementEditSubmit', async (params, api) => {
    return sendAsync(DT.couponsSettingGenerate(params), api)
})

// 活动设置列表
export const getActiveSettingList = createAsyncThunk<
    void,
    DT.ActiveSettingListPrams,
    RootThunkApi<void>
>('clinic/getInsuranceSettlementRecordList', async (params, api) => {
    return sendAsync(DT.activeSettingList(params), api)
})

// 活动设置新增
export const getActiveSettingSubmit = createAsyncThunk<
    void,
    DT.ActiveSettingSubmitPrams,
    RootThunkApi<void>
>('clinic/getInsuranceSettlementRecordList', async (params, api) => {
    return sendAsync(DT.activeSettingSubmit(params), api)
})

// 活动设置详情
export const getActiveSettingDetail = createAsyncThunk<
    void,
    DT.ActiveSettingDetailPrams,
    RootThunkApi<void>
>('clinic/getInsuranceSettlementRecordList', async (params, api) => {
    return sendAsync(DT.activeSettingDetail(params), api)
})

//优惠券发放
export const getCouponsIssueSubmit = createAsyncThunk<
    void,
    DT.CouponsIssuePrams,
    RootThunkApi<void>
>('clinic/getInsuranceSettlementRecordList', async (params, api) => {
    return sendAsync(DT.couponsIssueSubmit(params), api)
})

//优惠券领取列表
export const getCouponsRecordList = createAsyncThunk<
    void,
    DT.CouponsRecordPrams,
    RootThunkApi<void>
>('clinic/getInsuranceSettlementRecordList', async (params, api) => {
    return sendAsync(DT.couponsRecordList(params), api)
})

//使用失效
export const getCouponsRecordupdateStatus = createAsyncThunk<
    void,
    DT.CouponsRecordUpdateStatusPrams,
    RootThunkApi<void>
>('clinic/getInsuranceSettlementRecordList', async (params, api) => {
    return sendAsync(DT.couponsRecordUpdateStatus(params), api)
})