/* eslint-disable react/display-name */
import { Avatar, Badge, Button, Space, TableColumnType, Tooltip } from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { BillingCategoryName } from '../../../models/billingCategory'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import { Dimen } from '../../../models/dimen'
import { getPayFlagName } from '../../../models/payment'
import { getRecipeStatusName } from '../../../models/recipe'
import { getTransactionQueryName } from '../../../models/TransactionQuery'
import { getCardType } from '../../payment/JuhePayPage/columns'

export const Columns = (startIndex?: any): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },
    {
      title: '结算编号',
      key: 'paymentId',
      align: 'center',
      width: '10rem',
      render: (_, r) => (r?.paymentId ? r?.paymentId : '-'),
      // ellipsis:true,
    },
    {
      title: '患者姓名',
      key: 'patientName',
      align: 'center',
      width: '10rem',
      render: (_, r) => (r?.patientName ? r?.patientName : '-'),
      // ellipsis:true,
    },
    {
      title: '病历号',
      key: 'patientId',
      align: 'center',
      width: '8em',
      dataIndex: 'patientId',
      render: (_, r) => (r?.patientId > -1 ? r?.patientId : '-'),
    },
    {
      title: '手机号',
      key: 'patientPhone',
      align: 'center',
      width: '8em',
      dataIndex: 'patientPhone',
      render: (_, r) => (r?.patientPhone ? r?.patientPhone : '-'),
    },
    {
      title: '结算险种',
      dataIndex: 'insuranceCode',
      width: '8em',
      align: 'center',
      render: (_, r) => {
        return r?.insuranceCode === 'ZIFEI' || !r?.insuranceCode
          ? '自费'
          : '智慧医保'
      },
    },
    {
      title: '开票项目',
      width: '8em',
      align: 'center',
      dataIndex: 'billingCategory',
      render: (_, t) => {
        return BillingCategoryName(t?.billingCategory)
      },
    },
    {
      title: '药品名称',
      key: 'drugName',
      width: '14rem',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r?.drugName ? r?.drugName : '-'
      },
    },
    {
      title: '单价',
      dataIndex: 'retailPrice',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return Number(r?.retailPrice) > -1 ? r?.retailPrice.toFixed(2) : '-'
      },
    },
    {
      title: '数量',
      dataIndex: 'drugCount',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return Number(r?.drugCount) > -1 ? r?.drugCount : '-'
      },
    },
    {
      title: '金额',
      dataIndex: 'drugAmount',
      align: 'right',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return Number(r?.drugAmount) > -1 ? r?.drugAmount.toFixed(2) : '-'
      },
    },
    {
      title: '批号',
      dataIndex: 'batchNo',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.batchNo ? r?.batchNo : '-'
      },
    },
    {
      title: '有效期',
      dataIndex: 'deadline',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.deadline
          ? moment(r.deadline).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '供应商',
      dataIndex: 'gysName',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.gysName ? r?.gysName : '-'
      },
    },
    {
      title: '接诊医生',
      key: 'treatmentDoctorName',
      align: 'center',
      width: Dimen.Doctor,
      render: (_, r) => (r?.treatmentDoctorName ? r?.treatmentDoctorName : '-'),
    },
    {
      title: '收费时间',
      key: 'chargeOrRefundTime',
      align: 'center',
      width: '10em',
      render: (_, r) => {
        return r?.chargeOrRefundTime
          ? moment(r?.chargeOrRefundTime)?.format(DateTimeFormat)
          : '-'
      },
    },
    {
      title: '结算状态',
      align: 'center',
      key: 'state',
      width: Dimen.Time,
      render: (_, t) =>
        t?.state ? getTransactionQueryName(Number(t?.state)) : '-',
    },
  ]
}
