/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-24 14:27:54
 * @LastEditors: linxi
 * @LastEditTime: 2021-04-24 14:34:56
 */
// 0总药房 1西药房: 2中药房
export enum DurgSystemCategory {
    ZYF = 0,
    XY = 1,
    ZY = 2,
}

export const durgSystemCategories = [
    DurgSystemCategory.ZYF,
    DurgSystemCategory.ZY,
    DurgSystemCategory.XY
]

export function durgSystemCategoryName(durgSystemCategory: DurgSystemCategory) {
    switch (durgSystemCategory) {
        case 0:
            return "总药房"
        case 1:
            return "西药房"
        case 2:
            return "中药房"
    }
}