/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2024-01-18 16:32:09
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-04 19:07:55
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../../app/applicationSlice";
import { RootThunkApi } from "../../../app/store";
import { fromMaterialJson, Material } from "../../../models/material";
import { fromJson, Recipe } from "../../../models/recipe";
import * as S from "../../../services/recipe";

export const getRecipeList = createAsyncThunk<
  { recipes: Recipe[]; materials: Record<string, Material[]> },
  string,
  RootThunkApi
>("recipeListModal/getRecipeList", async (params, api) => {
  return sendAsync(S.getRecipeList(params), api).then((data) => ({
    recipes: _.chain(data.records)
      .map((r) => fromJson(r))
      .value(),
    materials:
      _.chain(data.records)
        .map((r) => ({
          [r.id]: _.chain(r.itemList)
            .map((m) => fromMaterialJson(m))
            .sort((a, b) => {
              if (a.groupNumber < b.groupNumber) {
                return -1
              }
              if (a.groupNumber > b.groupNumber) {
                return 1
              }
              return a.sort - b.sort
            })
            .value(),
        }))
        .reduce(
          (prev, curr) => ({ ...prev, ...curr } as Record<string, Material[]>)
        )
        .value() || {},
  }))
});



export const getRecipeSimpleList = createAsyncThunk<
  { recipes: Recipe[]; materials: Record<string, Material[]> },
  S.RecipeSimpleListParams,
  RootThunkApi
>('recipeListModal/getRecipeList', async (params, api) => {
  return sendAsync(S.getRecipeSimpleList(params), api).then((data) => ({
    recipes: _.chain(data)
      .map((r) => fromJson(r))
      .value(),
    materials:
      _.chain(data)
        .map((r) => ({
          [r.id]: _.chain(r.itemList)
            .map((m) => fromMaterialJson(m))
            .sort((a, b) => {
              if (a.groupNumber < b.groupNumber) {
                return -1
              }
              if (a.groupNumber > b.groupNumber) {
                return 1
              }
              return a.sort - b.sort
            })
            .value(),
        }))
        .reduce(
          (prev, curr) => ({ ...prev, ...curr } as Record<string, Material[]>)
        )
        .value() || {},
  }))
})
