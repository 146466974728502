/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-17 13:37:26
 * @LastEditors: sj
 * @LastEditTime: 2022-11-30 17:15:33
 */
export const expressList = [
  { name: '韵达快递', value: '韵达快递' },
  { name: '天天快递', value: '天天快递' },
  { name: '申通快递', value: '申通快递' },
  { name: '圆通快递', value: '圆通快递' },
  { name: '德邦物流', value: '德邦物流' },
  { name: '百世汇通', value: '百世汇通' },
  { name: '顺丰速运', value: '顺丰速运' },
  { name: '京东物流', value: '京东物流' },
  { name: '中通速递', value: '中通速递' },
  { name: '国通快递', value: '国通快递' },
  { name: '中国邮政EMS', value: '中国邮政EMS' },
  { name: '其他', value: '其他' },
]

export const colorList = [
  { name: 'A1', value: 'A1' },
  { name: 'B1', value: 'B1' },
  { name: 'B3', value: 'B3' },
  { name: 'B4', value: 'B4' },
  { name: 'C1', value: 'C1' },
  { name: 'C2', value: 'C2' },
  { name: 'C3', value: 'C3' },
  { name: 'C4', value: 'C4' },
  { name: 'D2', value: 'D2' },
  { name: 'D3', value: 'D3' },
  { name: 'D4', value: 'D4' },
  { name: 'A2', value: 'A2' },
  { name: 'A3.5', value: 'A3.5' },
  { name: 'A4', value: 'A4' },
  { name: 'B2', value: 'B2' },
  { name: 'OM1', value: 'OM1' },
  { name: 'OM2', value: 'OM2' },
  { name: 'OM3', value: 'OM3' },
  { name: '1M1', value: '1M1' },
  { name: '1M2', value: '1M2' },
  { name: '2L1.5', value: '2L1.5' },
  { name: '2L2.5', value: '2L2.5' },
  { name: '2M1', value: '2M1' },
  { name: '2M2', value: '2M2' },
  { name: '2M3', value: '2M3' },
  { name: '2R1.5', value: '2R1.5' },
  { name: '2R2.5', value: '2R2.5' },
  { name: '3L1.5', value: '3L1.5' },
  { name: '3L2.5', value: '3L2.5' },
  { name: '3M1', value: '3M1' },
  { name: '3M2', value: '3M2' },
  { name: '3M3', value: '3M3' },
  { name: '3R1.5', value: '3R1.5' },
  { name: '3R2.5', value: '3R2.5' },
  { name: '4L1.5', value: '4L1.5' },
  { name: '4L2.5', value: '4L2.5' },
  { name: '4M1', value: '4M1' },
  { name: '4M2', value: '4M2' },
  { name: '4M3', value: '4M3' },
  { name: '4R1.5', value: '4R1.5' },
  { name: '4R2.5', value: '4R2.5' },
  { name: '5M1', value: '5M1' },
  { name: '5M2', value: '5M2' },
  { name: '5M3', value: '5M3' },
  { name: '1/3', value: '1/3' },
  { name: '2/3', value: '2/3' },
  { name: '切端透明瓷', value: '切端透明瓷' },
  { name: '中1/3', value: '中1/3' },
  { name: '颈 1/3', value: '颈 1/3' },
  { name: '颈 2/3', value: '颈 2/3' },
  { name: '切 1/3', value: '切 1/3' },
  { name: '切 2/3', value: '切 2/3' },
]

export const processContentList = [

  { name: '氧化锆桩', value: '氧化锆桩' },
  { name: '全瓷（爱尔创）', value: '全瓷（爱尔创）' },
  { name: '全瓷（二氧化锆）', value: '全瓷（二氧化锆）' },
  { name: '金属烤瓷（钴铬）', value: '金属烤瓷（钴铬）' },
  { name: '金属烤瓷（低金）', value: '金属烤瓷（低金）' },
  { name: '全瓷（铸瓷）', value: '全瓷（铸瓷）' },
  { name: '全瓷（氧化铝）', value: '全瓷（氧化铝）' },
  { name: '全瓷（3M Lava 二氧化锆）', value: '全瓷（3M Lava 二氧化锆）' },
  { name: '+瓷肩台', value: '+瓷肩台' },
  { name: '铸造桩核（镍铬）', value: '铸造桩核（镍铬）' },
  { name: '普通支架（小）', value: '普通支架（小）' },
  { name: '普通支架（大）', value: '普通支架（大）' },
  { name: '钛合金支架（中）', value: '钛合金支架（中）' },
  { name: 'NP钢牙', value: 'NP钢牙' },
  { name: '套筒冠（内冠）', value: '套筒冠（内冠）' },
  { name: '金属烤瓷（镍铬）', value: '金属烤瓷（镍铬）' },
  { name: '金属烤瓷（钛合金）', value: '金属烤瓷（钛合金）' },
  { name: '金属烤瓷（高金）', value: '金属烤瓷（高金）' },
  { name: '全瓷（维他）', value: '全瓷（维他）' },
  { name: '全瓷（紫晶二氧化锆）', value: '全瓷（紫晶二氧化锆）' },
  { name: '全瓷贴面（铸瓷）', value: '全瓷贴面（铸瓷）' },
  { name: '+牙龈瓷', value: '+牙龈瓷' },
  { name: '铸造桩核（钴铬）', value: '铸造桩核（钴铬）' },
  { name: '普通支架（中）', value: '普通支架（中）' },
  { name: '单冠', value: '单冠' },
  { name: '纯钛支架', value: '纯钛支架' },
  { name: '日本钢牙', value: '日本钢牙' },
  { name: '套筒冠（外套）', value: '套筒冠（外套）' },
  { name: '返工', value: '返工' },
  { name: '白胶钩', value: '白胶钩' },
  { name: '分体桩（2个）', value: '分体桩（2个）' },
  { name: '栓道桩（2个）', value: '栓道桩（2个）' },
  { name: '隐形义齿', value: '隐形义齿' },
  { name: '联冠', value: '联冠' },
  { name: '桥', value: '桥' },
  { name: '上颌', value: '上颌' },
  { name: '下颌', value: '下颌' },
  { name: '排牙+充胶', value: '排牙+充胶' },
  { name: '充胶', value: '充胶' },
  { name: '钴铬铸造冠', value: '钴铬铸造冠' },
]

// export const outsideStatus = [
//   { value: 0, name: '未完工' },
//   { value: 1, name: '已取件' },
//   { value: 2, name: '已完工' },
//   { value: 3, name: '试戴' },
//   { value: 4, name: '已戴走' },
//   { value: 5, name: '返工' },
// ]

export enum outsideStatus {
  WWG = 0,
  YQJ = 1,
  YWG = 2,
  SD = 3,
  YDZ = 4,
  FG = 5
}

export const outsideStatusOptions = [
  outsideStatus.WWG,
  outsideStatus.YQJ,
  outsideStatus.YWG,
  outsideStatus.SD,
  outsideStatus.YDZ,
  outsideStatus.FG,
]

export const getOutsideStatus =(key:outsideStatus):string =>{
  switch (key) {
    case outsideStatus.WWG:
      return '未完工'
      case outsideStatus.YQJ:
        return '已取件'
        case outsideStatus.YWG:
          return '已完工'
          case outsideStatus.SD:
            return '试戴'
            case outsideStatus.YDZ:
              return '已戴走'
              case outsideStatus.FG:
                return '返工'

    default:
      return ''
  }
}