import {Category} from "../models/category";
import {Request} from "./request";
import {API_PREFIX} from "./constant";
import {SupplierDealingParams} from "./supplierDealing";

export interface PurchaseControlParams {
    id?: number
    current?: number
    size?: number
    businessType?: Category
    timeHead?: string
    timeTail?: string
    putNumber?: string
    shouldPayMoney?: number
    status?: string
    supplierId?: string
    supplierName?: string
    materialsCategory?: number
    category?: string
    supplierParam?: string
    goodsParam?: string
}

export function getPurchaseControl(params: PurchaseControlParams): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/purchaseControl`,
        method: "GET",
        params,
    };
}


export function exportPurchaseControl(params: PurchaseControlParams): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/purchaseControl/export`,
        method: "GET",
        responseType: 'blob',
        params,
    };
}



export function getPurchaseControlDetail(params: PurchaseControlParams): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/purchaseControlDetail`,
        method: "GET",
        params,
    };
}

export function exportPurchaseControlDetail(params: PurchaseControlParams): Request {
    return {
        url: `${API_PREFIX}/blade-store/inventoryreceiptsitem/purchaseControlDetail/export`,
        method: "GET",
        responseType: 'blob',
        params,
    };
}