/* eslint-disable react/display-name */
import { Avatar, Badge, Button, Space, TableColumnType, Tooltip } from 'antd'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { Dimen } from '../../models/dimen'
import { Payment } from '../../models/payment'

type Action =
  | 'reconciliationStatement'
  | 'settlementInquiry'
  | 'upload'
  | 'withdrawalOfdeclaration'
  | 'settlementExpenseReport'

export const getTypeName = (cardType: string) => {
  switch (cardType) {
    case '-1':
      return '未对账'
    case '0':
      return '对账失败'
    case '1':
      return '已对账未申报'
    case '2':
      return '已申报'
    case '3':
      return '申报撤销'
    default:
      return ''
  }
}
export const Columns = (
  startIndex: number,
  disabled: boolean,
  onAction: (action: Action, t: any) => void
): TableColumnType<any>[] => {
  const colums: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      fixed: 'left',
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },

    {
      title: '月度',
      dataIndex: 'setlMon',
      align: 'center',
      key: 'setlMon',
      render: (_, t) => {
        return t.setlMon ? moment(t.setlMon).format('YYYY.MM') : '-'
      },
    },
    {
      title: '月结状态',
      dataIndex: 'flag',
      align: 'center',
      key: 'flag',
      render: (_, t: any) => {
        return t?.status?.toString() ? getTypeName(t?.status?.toString()) : '-'
      },
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      fixed: 'right',
      render: function ShowAction(_, t: any): ReactElement {
        return (
          <Space>
            {(t?.status == 0 || t?.status == -1) && (
              <Button
                type='link'
                disabled={disabled}
                onClick={() => onAction('reconciliationStatement', t)}
              >
                对账
              </Button>
            )}
            {t?.status == 1 && (
              <Button
                type='link'
                disabled={disabled}
                onClick={() => onAction('settlementExpenseReport', t)}
              >
                申报
              </Button>
            )}
            {t?.status == 2 && (
              <>
                <Button
                  type='link'
                  disabled={disabled}
                  onClick={() => onAction('withdrawalOfdeclaration', t)}
                >
                  申报撤销
                </Button>
                <Button
                  type='link'
                  disabled={disabled}
                  onClick={() => onAction('settlementInquiry', t)}
                >
                  结付查询
                </Button>
              </>
            )}
          </Space>
        )
      },
    },
  ]
  return colums
}

export const SettlementInquiryColumns = (
  startIndex: number,
): TableColumnType<Payment>[] => {
  const colums: TableColumnType<Payment>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, i) => `${startIndex + i + 1}`,
    },

    {
      title: '医药机构医保区划',
      dataIndex: 'fix_blng_admdvs',
      align: 'center',
      key: 'fix_blng_admdvs',
      width: '10rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.fix_blng_admdvs
      },
    },
    {
      title: '参保地医保区划',
      dataIndex: 'insu_admdvs',
      align: 'center',
      key: 'insu_admdvs',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.insu_admdvs
      },
    },
    {
      title: '结算费用类型',
      dataIndex: 'setl_fee_type',
      align: 'center',
      key: 'setl_fee_type',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.setl_fee_type
      },
    },
    {
      title: '结算方案',
      dataIndex: 'setl_schn',
      align: 'center',
      key: 'setl_schn',
      width: '6rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.setl_schn
      },
    },
    {
      title: '结算月份',
      dataIndex: 'setl_mon',
      align: 'center',
      key: 'setl_mon',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.setl_mon
      },
    },
    {
      title: '费用编码',
      dataIndex: 'fund_code',
      align: 'center',
      key: 'fund_code',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.fund_code
      },
    },
    {
      title: '费用名称',
      dataIndex: 'fund_name',
      align: 'center',
      key: 'fund_name',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.fund_name
      },
    },
    {
      title: '费用金额',
      dataIndex: 'fund_amt',
      align: 'center',
      key: 'fund_amt',
      width: '8rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.fund_amt
      },
    },
    {
      title: '备注',
      dataIndex: 'memo',
      align: 'center',
      key: 'memo',
      width: '6rem',
      ellipsis: true,
      render: (_, t: any) => {
        return t.memo
      },
    },
  ]
  return colums
}
