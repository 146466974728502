/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-19 15:43:32
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-19 15:53:56
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/dynamicGrossProfitStatement'

// 列表
export const getDynamicGrossProfitStatementList = createAsyncThunk<
  void,
  DT.DynamicGrossProfitStatementParams,
  RootThunkApi<void>
>('clinic/getOutPatientRegistrationReportList', async (params, api) => {
  return sendAsync(DT.dynamicGrossProfitStatementPage(params), api)
})
