export interface Fee {
  id: string
  name: string
  unitPrice: number
  units: string
  accountUnit: string
  hilistCode?: string
}

export function fromJson(json: { id?: number } & Record<string, never>): Fee {
  return {
    id: json.id?.toString() || '',
    name: json.name,
    unitPrice: json.retailPrice,
    units: json.units,
    accountUnit: json.accountUnit,
    hilistCode: json.hilistCode,
  }
}
