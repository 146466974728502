/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-03 20:22:17
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-03 15:48:50
 */
import { Col, Tabs } from "antd";

import React, { ReactElement } from "react";
import {} from "antd";
import { TabBar } from "../../compnents/widgets/TabBar";
import styles from "./personalManagement.module.css";
import { PersonalDetail } from "./personalDetail/personalDetail";
import { PersonalSetting } from "./personalSetting/personalSetting";
import { useSelector } from "react-redux";
import { selectTwoMenus } from "../../layouts/layoutSlice";
const { TabPane } = Tabs;
export const PersonalManagement = (): ReactElement => {

    const twoMenus = useSelector(selectTwoMenus)

    const menuList = twoMenus?.filter((v) => {
      return v?.name == '个人管理'
    })?.[0]

    const getKeys = (i: string) => {
      switch (i) {
        case '个人详情':
          return '0'
        case '个人设置':
          return '1'
        default:
          return '-'
      }
    }
  return (
    <>
      <Col className={styles.container}>
        <TabBar style={{ marginBottom: '20px' }}>
          {menuList?.subMenus?.map((v) => {
            return (
              <>
                <TabPane key={getKeys(v?.name)} tab={v?.name}>
                  {v?.name == '个人详情' ? (
                    <PersonalDetail />
                  ) : v?.name == '个人设置' ? (
                    <PersonalSetting />
                  ) : (
                    <></>
                  )}
                </TabPane>
              </>
            )
          })}
          {/* <TabPane key="0" tab="个人详情">
            <PersonalDetail />
          </TabPane>
          <TabPane key="1" tab="个人设置">
            <PersonalSetting />
          </TabPane> */}
        </TabBar>
      </Col>
    </>
  )
};
