import { CaretDownFilled } from '@ant-design/icons'
import { Button, Form, Row, Select, Space } from 'antd'
import 'moment/locale/zh-cn'
import React, { ReactElement, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../app/applicationSlice'
import {
  DateRangeDrugStaticTypes,
  DateRangeType,
  DateSelect,
  getDateRange,
  TJFXTime,
} from '../../../compnents/form/DateSelect'
import { PrintOutlined } from '../../../compnents/icons/Icons'
import { revenueReport } from '../../../models/revenueReport'

export const RevenueReportQuery = (props: {
  onValueChange: (queries: revenueReport) => void
  outClued: () => void
  print: () => void
}): ReactElement => {
  const [form] = Form.useForm()

  const insuranceArray = useSelector(selectInsuranceArray)

  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
    })
    form.submit()
  }, [])

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <DateSelect
              style={{ width: '8.5rem' }}
              labelPrefix=''
              allowClear={false}
              options={DateRangeDrugStaticTypes}
              namePrefix='time'
              placeholder='日期范围-时间'
              initialValue={DateRangeType.Today}
              onChange={() => form.submit()}
            />
            {insuranceArray && insuranceArray.length !== 0 && (
              <Form.Item name='insuranceCode' noStyle>
                <Select
                  placeholder='险种类型'
                  style={{ width: '8.25rem' }}
                  allowClear
                  // suffixIcon={<CaretDownFilled />}
                  onChange={form.submit}
                >
                  <Select.Option value={''}>全部</Select.Option>
                  <Select.Option value={'ZIFEI'}>自费</Select.Option>
                  {insuranceArray.map((v) => (
                    <Select.Option value={v.insuranceCode} key={v.id}>
                      {v.insuranceName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type='text'
              style={{
                color: '#666',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                props.print()
              }}
            >
              <PrintOutlined
                style={{
                  fill: '#666',
                  width: '18px',
                  height: '18px',
                  marginRight: '4px',
                }}
              />
              打印
            </Button>
            <Button type='primary' onClick={() => props.outClued()}>
              导出
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
