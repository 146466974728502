/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-29 19:58:43
 * @LastEditors: linxi
 * @LastEditTime: 2021-06-23 11:16:09
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store";
import { getQingdoulist, QingdouParams, submitbatch } from "../../services/qingdou";

export const initialState = {
    QingdouData: [],
    Total: 0,
    Current: 1,
    QingdouFlag: 1,
    tenantInventoryReceiptsId: 0
}

export const QingdouSlice = createSlice({
    name: "Qingdou",
    initialState,
    reducers: {
        setQingdouData(state, action) {
            state.QingdouData = action.payload
        },
        setQingdouTotal(state, action) {
            state.Total = action.payload
        },
        setQingdouCurrent(state, action) {
            state.Current = action.payload
        },
        setQingdouFlag(state, action) {
            state.QingdouFlag = action.payload
        },
        changeRow(state, action) {
            state.QingdouData = action.payload
        },
        settenantInventoryReceiptsId(state, action) {
            state.tenantInventoryReceiptsId = action.payload
        }
    }
})


export const saveQingdouAsync = createAsyncThunk<
    void, Array<any>, RootThunkApi<void>
>("Qingdou/list", async (Ids, api) => {
    return sendAsync(submitbatch(Ids), api, true)
})

export function getQingdou(
    params: QingdouParams
): RootThunk {
    return api(getQingdoulist(params), (data: any, dispatch) => {
        dispatch(setQingdouData(data.records))
        dispatch(setQingdouTotal(data.total))
    })
}

// export function getQingdouMothodlist

export const { setQingdouData, setQingdouCurrent, setQingdouTotal, settenantInventoryReceiptsId, setQingdouFlag, changeRow } = QingdouSlice.actions

export const selectQingdouFlag = (state: RootState) => state.Qingdou.QingdouFlag

export const selectQingdouTotal = (state: RootState) => state.Qingdou.Total

export const selectQingdouCurrent = (state: RootState) => state.Qingdou.Current

export const selectQingdouData = (state: RootState) => state.Qingdou.QingdouData

export const ReceiptsId = (state: RootState) => state.Qingdou.tenantInventoryReceiptsId

export default QingdouSlice.reducer