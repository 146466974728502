import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ThemeContext } from "../../theme/ThemeContext";
import {
  DateRangeFieldName,
  DateSelect,
} from "../../compnents/form/DateSelect";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getFullDoctorList,
  getOutpatientDepartment,
  getRegistrationList,
  selectDepartments,
  selectDoctors,
  selectRegistrationPage,
} from "../registration/list/registrationSlice";
import { getRegistrationStateName, Registration, RegistrationStates } from "../../models/registration";
import {
  selectInsuranceArray,
  selectQueryLoadingTag,
  setQueryLoadingTag,
} from "../../app/applicationSlice";
import { EditableList } from "../../compnents/list/EditableList";
import { PaymentColumns } from "./columns";
import { RootDispatch } from "../../app/store";
import moment from "moment";
import { allRegistiongAndTreatmentOperatioEditLogPage, updataTreatmentApi } from "./treament";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  DateTimeFormat,
  DateTimeFormatSimpleOnlyOne,
} from "../../models/datetime";
import { CommodityLastEditModal } from "../commodity/CommodityLastEditModal";

export const PaymentList = (): ReactElement => {
  const [form] = Form.useForm();

  const [formModal] = Form.useForm();

  const inputRef = useRef<any>(null);

  const dispatch = useDispatch<RootDispatch>();

  const insuranceArray = useSelector(selectInsuranceArray);

  const theme = useContext(ThemeContext);

  const departments = useSelector(selectDepartments);

  const doctors = useSelector(selectDoctors);

  const page = useSelector(selectRegistrationPage);

  const [visible, setVisible] = useState(false);

  const [patientDetail, setPatientDetail] = useState<any>();

  const queryLoading = useSelector(selectQueryLoadingTag);

  const [loading, setLoading] = useState<boolean>(false);

  const [params, setParams] = useState<any>({
    current: 1,
  });

  const [lastEditVisable, setLastEditVisable] = useState(false);

  const [lastEditId, setLastEditId] = useState<any>();

  const [editVisible, setEditVisible] = useState(false);

  const [queries, setQueries] = useState<any>({
    current: 1,
    size: 10,
  });

  const [modalPage, setModalPage] = useState<any>({
    total: 0,
    items: []
  })

  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    //搜索框获取焦点
    inputRef.current!.focus({
      cursor: "start",
    });
  }, []);

  useEffect(() => {
    dispatch(getOutpatientDepartment());
    dispatch(getFullDoctorList());
  }, []);

  useEffect(() => {
    dispatch(setQueryLoadingTag(true));
    dispatch(getRegistrationList(params)).finally(() => {
      dispatch(setQueryLoadingTag(false));
    });
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(allRegistiongAndTreatmentOperatioEditLogPage(queries))
      .then(unwrapResult)
      .then((res: any) => {
        setModalPage({
          items: res?.records,
          total: res?.total,
        });
      });
  }, [queries]);

  return (
    <Col
      style={{
        margin: "10px 0px",
        height: "calc(100% - 30px)",
        padding: "0px 5px",
        borderRadius: theme.br,
        backgroundColor: theme.pn,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={(values) => {
          setParams({
            ...params,
            ...values,
            current: 1,
            [DateRangeFieldName]: undefined,
          });
        }}
      >
        <Row justify="space-between" style={{ marginBottom: 20 }}>
          <Space>
            <Form.Item name="param" noStyle>
              <Input
                ref={inputRef}
                prefix={<SearchOutlined style={{ color: theme.tc3 }} />}
                placeholder="患者姓名/手机号/助记码/病历号"
                style={{ width: "17rem" }}
                allowClear
                onChange={form.submit}
              />
            </Form.Item>
            <Form.Item name="treatmentDepartmentId" noStyle>
              <Select
                style={{ width: "12.5rem" }}
                placeholder="查看范围-科室列表"
                allowClear
                // suffixIcon={<CaretDownFilled />}
                onChange={form.submit}
              >
                {departments.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="registrationState" noStyle>
              <Select
                style={{ width: "11.25rem" }}
                placeholder="是否就诊-全部"
                // suffixIcon={<CaretDownFilled />}
                allowClear
                onChange={form.submit}
              >
                {RegistrationStates.map((s) => (
                  <Select.Option key={s} value={s}>
                    {getRegistrationStateName(s)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="treatmentDoctorId" noStyle>
              <Select
                style={{ width: "8.25rem" }}
                placeholder="医生列表"
                // suffixIcon={<CaretDownFilled />}
                allowClear
                onChange={form.submit}
              >
                {doctors.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {insuranceArray && insuranceArray.length !== 0 && (
              <Form.Item name="insuranceCode" noStyle>
                <Select
                  placeholder="险种类型"
                  style={{ width: "8.25rem" }}
                  // suffixIcon={<CaretDownFilled />}
                  allowClear
                  onChange={form.submit}
                >
                  <Select.Option value={""}>全部</Select.Option>
                  <Select.Option value={"ZIFEI"}>自费</Select.Option>
                  {insuranceArray.map((v) => (
                    <Select.Option value={v.insuranceCode} key={v.id}>
                      {v.insuranceName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <DateSelect
              style={{ width: "8.5rem" }}
              labelPrefix=""
              namePrefix="registrationTime"
              placeholder="挂号时间"
              onChange={form.submit}
            />
            <Button
              type="primary"
              onClick={() => {
                form.submit(), dispatch(setQueryLoadingTag(true));
              }}
            >
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                dispatch(allRegistiongAndTreatmentOperatioEditLogPage(queries))
                  .then(unwrapResult)
                  .then((res: any) => {
                    setModalPage({
                      items: res?.records,
                      total: res?.total,
                    });
                    setEditVisible(true);
                  });
              }}
            >
              修改记录
            </Button>
          </Space>
        </Row>
      </Form>
      <EditableList<Registration>
        loading={queryLoading}
        page={page}
        columns={PaymentColumns((a, r) => {
          switch (a) {
            case "cancel":
              setVisible(true);
              setPatientDetail(r);
              formModal.setFieldsValue({
                patientName: r?.patientName,
                registrationTime: moment(r?.registrationTime),
                treatmentTime: r?.treatmentTime ? moment(r?.treatmentTime) : "",
              });
              break;
            case "detail":
              setLastEditVisable(true);
              setLastEditId(r?.id);
              break;
            default:
              return "";
          }
        }, (page.current - 1) * page.size)}
        onChangePage={(current, size) =>
          setParams({ ...params, current, size })
        }
      />

      <Modal
        title="挂号时间修改"
        width="500px"
        okText="确定"
        cancelText="取消"
        visible={visible}
        confirmLoading={loading}
        destroyOnClose
        onOk={(e) => {
          formModal
            .validateFields()
            .then((values) => {
              setLoading(true);
              dispatch(
                updataTreatmentApi({
                  registrationId: patientDetail?.id,
                  treatmentId: values.treatmentUpdateTime
                    ? patientDetail?.treatmentId
                    : "",
                  registrationUpdateTime: values?.registrationUpdateTime
                    ? moment(values?.registrationUpdateTime).format(
                        DateTimeFormat
                      )
                    : "",
                  treatmentUpdateTime: values.treatmentUpdateTime
                    ? moment(values?.treatmentUpdateTime).format(DateTimeFormat)
                    : "",
                })
              )
                .then(unwrapResult)
                .then(() => {
                  dispatch(setQueryLoadingTag(true));
                  dispatch(getRegistrationList(params)).finally(() => {
                    dispatch(setQueryLoadingTag(false));
                  });
                  formModal.resetFields();
                  setVisible(false);
                  setLoading(false);
                })
                .finally(() => {
                  setLoading(false);
                });
            })
            .catch(() => {
              // Do nothing.
            });
        }}
        onCancel={() => {
          formModal.resetFields();
          setVisible(false);
        }}
      >
        <Form
          form={formModal}
          autoComplete="off"
          labelAlign="right"
          labelCol={{ span: 6 }}
        >
          <Form.Item name="patientName" label="姓名">
            <Input style={{ width: "200px" }} disabled />
          </Form.Item>
          <Form.Item name="registrationTime" label="挂号时间">
            <DatePicker
              placeholder="请选择"
              format="YYYY/MM/DD HH:mm:ss"
              disabled
            />
          </Form.Item>
          <Form.Item name="registrationUpdateTime" label="修改挂号时间">
            <DatePicker
              showTime
              placeholder="请选择"
              format="YYYY-MM-DD hh:mm:ss"
            />
          </Form.Item>
          {patientDetail?.treatmentTime ? (
            <>
              <Form.Item name="treatmentTime" label="就诊时间">
                <DatePicker
                  format="YYYY/MM/DD HH:mm:ss"
                  placeholder="请选择"
                  disabled
                />
              </Form.Item>
              <Form.Item name="treatmentUpdateTime" label="修改就诊时间">
                <DatePicker
                  showTime
                  placeholder="请选择"
                  format="YYYY-MM-DD hh:mm:ss"
                />
              </Form.Item>
            </>
          ) : (
            <></>
          )}
        </Form>
      </Modal>

      <CommodityLastEditModal
        visible={lastEditVisable}
        title={"修改记录"}
        id={lastEditId}
        onOk={() => {
          setLastEditVisable(false);
          setLastEditId("");
        }}
        onCancel={() => {
          setLastEditVisable(false);
          setLastEditId("");
        }}
        operateType={3}
      />

      <Modal
        title="修改记录"
        width={"80vw"}
        okText="确定"
        cancelText="取消"
        visible={editVisible}
        destroyOnClose
        onCancel={() => {
          setEditVisible(false);
        }}
        onOk={() => {
          setEditVisible(false);
        }}
        footer={null}
      >
        <EditableList<Registration>
          page={{ ...modalPage, ...queries }}
          loading={pageLoading}
          columns={[
            {
              title: "序号",
              key: "no",
              align: "center",
              fixed: "left",
              width: "4em",
              render: (_1, _2, i) =>
                `${(queries.current - 1) * queries.size + i + 1}`,
            },
            {
              title: "患者姓名",
              dataIndex: "operateItem",
              width: "10em",
              align: "center",
            },
            {
              title: "修改内容",
              dataIndex: "patientName",
              width: "30em",
              align: "center",
              render: function Element(_, t: any) {
                return (
                  <span>
                    {t?.operateAttr} 由 {t?.beforeValue || "空"} 修改为{" "}
                    {t?.afterValue || "空"}
                  </span>
                );
              },
            },
            {
              title: "操作员",
              dataIndex: "operateName",
              width: "10em",
              align: "center",
              // render: (_, t) => t.operateName,
            },
            {
              title: "修改时间",
              dataIndex: "operateTime",
              width: "20em",
              align: "center",
            },
          ]}
          onChangePage={(current, size) => {
            setQueries({ ...queries, current, size });
          }}
          style={{maxHeight: '440px'}}
        />
        <Row justify="end">
          <Col>
            <Button type="primary" onClick={() => setEditVisible(false)}>
              确定
            </Button>
          </Col>
        </Row>
      </Modal>
    </Col>
  );
};
