/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-17 13:55:08
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-03 11:41:30
 */
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Dropdown, Form, Input, Menu, Modal,AutoComplete, notification, Row, Select, Space, Tooltip } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { colorList, processContentList, expressList, getOutsideStatus, outsideStatusOptions } from '../../../../../models/toothOutside';
import { RelevanceDisposeModal } from './relevanceDisposeModal';
import styles from './outside.module.css'
import { ToothComponents } from '../../../../treatment/editor/modals/toothComponents';
import { number2Pattern, numberPattern, phonePattern } from '../../../../../utils/RegExp';
import { stringTrim } from '../../../../../utils/StringUtils';
import { useDispatch, useSelector } from 'react-redux';
import { externalprocessAddAsync, externalprocessDetailAsync, selectHistoryProvider } from '../../patientSlice';
import { RootDispatch } from '../../../../../app/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { getFollowUpPeopleList } from '../../../../followUp/followUpPageSlice';
import moment from 'moment';

interface AddOutSideModalProps {
  id?: string
  visible: boolean
  onCancel: () => void
  onOk: () => void
  patientId?: string
}

const { Option } = Select;

export const AddOutSideModal = ({
  id,
  visible,
  onCancel,
  onOk,
  patientId
}: AddOutSideModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [RelevanceDisposevisible, setRelevanceDisposevisible] = useState(false)

  const [doctorList, setDoctorList] = useState<any>([])

  const [allDoctorList, setAllDoctorList] = useState<any>([])

  const [toothData, setToothData] = useState<any[]>([])

  const [disposeList, setDisposeList] = useState<any[]>([])

  const [selectIDs, setSelectIDs] = useState<any[]>([])

  const [disposeInfo, setDisposeInfo] = useState('')

  const historyProviderOptions = useSelector(selectHistoryProvider)

  const [isPatientOverlayVisible, setIsPatientOverlayVisible] = useState(false) //患者姓名输入框下拉查询弹窗

  const [keyword, setKeyword] = useState('')  

  const [loading, setLoading] = useState(false)

  const [contentList, setContentList] = useState(processContentList)

  const [colorNumList, setColorNumList] = useState(colorList)

  useEffect(() => {
    if (disposeList.length) {
      setDisposeInfo(disposeList.map((v: any) => v.name + ': ' + v.bcglsl + ' 颗').join(' ,'))
    }
  }, [disposeList])

  useEffect(() => {
    dispatch(
      getFollowUpPeopleList({
        current: 1,
        size: 1000
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setAllDoctorList(res.records)
      })
    dispatch(
      getFollowUpPeopleList({
        current: 1,
        size: 1000,
        stationCategory: 1
      })
    )
      .then(unwrapResult)
      .then((res) => {
        setDoctorList(res.records)
      })
  }, [])

  const initData = {
    processItemTooth: ['', '', '', ''],
  }

  useEffect(() => {
    if (visible && !id) {
      form.setFieldsValue({
        externalProcessItemList: [{ processItemTooth: ['', '', '', ''] }]
      })
      setToothData([initData])
    }
    if (visible && id) {
      dispatch(externalprocessDetailAsync(id)).then(unwrapResult).then((res: any) => {
        setSelectIDs(res.externalProcessRecipeList.map((v: any) => v.recipeItemId.toString()))
        setDisposeList(res.externalProcessRecipeList)
        setToothData(res.externalProcessItemList.map((v: any) => ({ ...v, processItemTooth: v.processItemTooth.split('@') })))
        let price = 0
        let num = 0
        res.externalProcessItemList.forEach((el: any) => {
          el.processItemTooth.split('@').forEach((m: any) => {
            if (m && m != '--') {
              num += m.split(',').length
            }
          })
          price += Number(el.processItemPrice || 0)
        })
        setKeyword(res.processProvider)
        form.setFieldsValue({
          ...res,
          addressee: res.addresseeName && res.addresseeName > 0 ? Number(res.addresseeName) : '',
          doctorId: res.doctorId && res.doctorId > 0 ? Number(res.doctorId) : '',
          processNumber: num,
          factoryPrice: price,
          patientPrice: res.patientPrice > 0 ? res.patientPrice : '',
          processStartTime: res.processStartTime ? moment(res.processStartTime) : '',
          processEndTime: res.processStartTime ? moment(res.processEndTime) : '',
        })
      })
    }
    if (!visible) {
      setToothData([])
      setLoading(false)
    }
  }, [visible, id])

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
  }

  useEffect(() => {
    form.setFieldsValue({
      processProvider: keyword
    })
  }, [keyword])

  const close = () => {
    onCancel()
    setSelectIDs([])
    setDisposeList([])
    setDisposeInfo('')
    form.resetFields()
    setKeyword('')
  }

  const toothChange = (action: string, index: number, value?: any) => {
    let list: any = []
    if (action == 'add') {
      list = [...toothData, initData]
    } else if (action == 'remove') {
      list = toothData.filter((v: any, i: any) => i != index)
    } else if (action == 'processItemTooth') {
      list = toothData.map((v: any, i: number) => {
        return i == index ? {
          ...v,
          processItemTooth: value
        } : v
      })
      let num = 0
      list.forEach((el: any) => {
        el.processItemTooth.forEach((m: any) => {
          if (m) {
            num += m.split(',').length
          }
        })
      })
      form.setFieldsValue({
        processNumber: num,
      })
    } else if (action == 'processItemDisease') {
      list = toothData.map((v: any, i: number) => {
        return i == index ? {
          ...v,
          processItemDisease: value
        } : v
      })
    } else if (action == 'processItemColorNo') {
      list = toothData.map((v: any, i: number) => {
        return i == index ? {
          ...v,
          processItemColorNo: value
        } : v
      })
    } else if (action == 'processItemPrice') {
      list = toothData.map((v: any, i: number) => {
        return i == index ? {
          ...v,
          processItemPrice: value
        } : v
      })
      let price = 0
      list.forEach((v: any) => {
        price += Number(v.processItemPrice || 0)
      })
      form.setFieldsValue({
        factoryPrice: price
      })
    } else {
      list = toothData
    }
    setToothData(list)
  }

  const patientOverlay = (   //患者姓名 and 介绍人 查询内容
    <Menu>
      {historyProviderOptions.map((p, i: number) => (
        <Menu.Item
          key={i}
          onClick={() => {
            form.setFieldsValue({
              name: p
            })
            setIsPatientOverlayVisible(true)
            setKeyword(p)
          }}
        >
          <Space>
            <span>{p}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <>
      <Modal
        visible={visible}
        title={id ? '编辑外加工' : '新增外加工'}
        onCancel={close}
        maskClosable={false}
        width={1200}
        bodyStyle={{
          padding: '10px 30px',
          height: 650,
          overflow: 'hidden'
        }}
        footer={null}
        destroyOnClose
      >
        <div className={styles.outsideContainer}>
        <Row style={{
          marginBottom: 10
        }}>
            <Col flex='8%' offset={3}> <span style={{ color: 'red' }}>*</span> 牙位</Col>
            <Col flex='23%'> <span style={{ color: 'red' }}>*</span> 加工内容</Col>
            <Col flex='23%'> <span style={{ color: 'red' }}>*</span> 色号</Col>
            <Col flex='20%'> <span style={{ color: 'red' }}>*</span> 价格</Col>
        </Row>
        <Form form={form}
          colon={false}
          className={styles.addOutside}
            {...layout}
          onFinish={(vs) => {
            if (toothData.map((v: any) => v.processItemTooth.join('')).some((el: any) => !el)) {
              notification.info({
                message: '请补全牙位信息'
              })
              return
            }
            setLoading(true)
            const data = {
              ...vs,
              processStartTime: vs.processStartTime ? moment(vs.processStartTime).format('YYYY-MM-DD') : undefined,
              processEndTime: vs.processEndTime ? moment(vs.processEndTime).format('YYYY-MM-DD') : undefined,
              externalProcessItemList: toothData.map((v: any) => ({
                ...v,
                processItemTooth: v.processItemTooth.map((v: any) => v || '--').join('@')
              })),
              externalProcessRecipeList: disposeList.length ? disposeList.map((v: any) => ({
                associatedCount: v.bcglsl,
                recipeItemId: v.recipeItemId,
                externalProcessId: id
              })) : undefined,
              doctorName: vs.doctorId ? doctorList.find((v: any) => v.id == vs.doctorId)?.realname : '',
              addresseeName: vs?.addressee,
              addressee:undefined,
              patientId,
              id,
            }
            dispatch(externalprocessAddAsync(data)).then(unwrapResult).then(() => {
              notification.success({
                message: '操作成功'
              })
              form.resetFields()
              setKeyword('')
              onOk()
            }).finally(() => {
              setLoading(false)
            })
          }}>
            <Form.List name="externalProcessItemList">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Space key={field.key} align="baseline" >
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                      }
                      style={{
                        width: 230
                      }}
                    >
                      {() => (
                        <Form.Item label={'加工项目' + (index + 1)} labelCol={{ span: 10 }} wrapperCol={{ span: 12 }}>
                          <ToothComponents
                            isShowSave={false}
                            data={toothData[index]?.processItemTooth.map((v: string) => v ? v : '--').join('@')}
                            showSubSelect={false}
                            hiddenSelect={(v) => {
                              toothChange('processItemTooth', index, v)
                            }}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'processItemDisease']}
                      rules={[{ required: true, message: '请选择加工内容' }]}
                    >
                      <AutoComplete
                        options={contentList}
                        style={{ width: 200,marginLeft:20 }}
                        placeholder={'加工内容'}
                        onSearch={(searchText:string) => {
                          setContentList(processContentList.filter(item => item.name.includes(searchText)))
                        }}
                        onChange={(v) => {
                          toothChange('processItemDisease', index, v)
                        }}
                      >
                      </AutoComplete>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'processItemColorNo']}
                      rules={[{ required: true, message: '请选择色号' }]}
                    >

                      <AutoComplete
                        options={colorNumList}
                        style={{ width: 250 }}
                        placeholder={'色号'}
                        onSearch={(searchText:string) => {
                          setColorNumList(colorList.filter(item => item.name.includes(searchText)))
                        }}
                        onChange={(v) => {
                          toothChange('processItemColorNo', index, v)
                        }}
                      >
                      </AutoComplete>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'processItemPrice']}
                      rules={[{ ...number2Pattern }, { required: true, message: '请输入价格' }]}
                    >
                      <Input value={toothData[index]?.processItemPrice} placeholder='价格' style={{
                        width: 250
                      }} onChange={(e) => {
                        form.validateFields(['processItemPrice']).then(() => {
                          const reg = /^([0-9]+(\.[0-9]{1,2})?)$/
                          if (!reg.test(e.target.value)) return
                          toothChange('processItemPrice', index, e.target.value)
                        })
                      }} />

                    </Form.Item>
                    {
                      index == 0 ? (
                        <PlusCircleOutlined onClick={() => {
                          if (fields.length < 12) {
                            toothChange('add', index)
                            add()
                          } else {
                            notification.info({
                              message: '最多添加12条'
                            })
                          }
                        }} />
                      ) : (
                        <MinusCircleOutlined
                          onClick={() => {
                              toothChange('remove', index)
                              remove(field.name)
                          }} />
                      )
                    }
                  </Space>
                ))}
              </>
            )}
          </Form.List>
            <Row gutter={[10, 5]}>
              <Col span={8}>
                <Form.Item {...layout} label='加工单位' name='processProvider' rules={[{ required: true }]}>
                  <Dropdown
                    overlay={patientOverlay}
                    visible={isPatientOverlayVisible}
                  >
                    <Input
                      allowClear
                      value={keyword}
                      onFocus={() => {
                        setIsPatientOverlayVisible(true)
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setIsPatientOverlayVisible(false)
                        }, 300)
                      }}
                      onClick={() => {
                        setIsPatientOverlayVisible(true)
                      }}
                      onChange={(e) => {
                        setIsPatientOverlayVisible(true)
                        setKeyword(e.target.value)
                      }}
                    />
                  </Dropdown>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='取件人' name='addressee' rules={[{ required: true }]}>
                <Input />
                  {/* <Select
                    allowClear
                    showSearch
                    filterOption={(input, option: any) =>
                      (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {allDoctorList.map((d: any) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.realname}
                      </Select.Option>
                    ))}
                  </Select> */}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} rules={[{ ...phonePattern }]} label='取件人电话' name='addresseePhone'><Input /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='加工天数' name='processCycle'
                  rules={[{ ...numberPattern }]}><Input /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='加工单号' name='orderNo' getValueFromEvent={stringTrim}><Input /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='加工颗数' name='processNumber'><Input disabled /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='医生' name='doctorId' >
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option: any) =>
                      (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {doctorList.map((d: any) => (
                      <Select.Option key={d.id} value={d.id}>
                        {d.realname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='送件日期' name='processStartTime'><DatePicker format={'YYYY-MM-DD'} /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='回件日期' name='processEndTime'><DatePicker format={'YYYY-MM-DD'} /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='状态' name='status' >
                  <Select
                    allowClear
                  >
                    {outsideStatusOptions.map((v) => (
                      <Option key={v} value={v}>
                        {getOutsideStatus(v)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='加工厂价' name='factoryPrice'><Input disabled /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} rules={[{ ...number2Pattern }]} label='对患者价' name='patientPrice'><Input /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='快递公司' name='logisticsOmpany'>
                  <Select
                    allowClear
                  >
                    {
                      expressList.map(item => (
                        <Option key={item.value} value={item.value}>
                          {item.name}
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='快递单号' name='trackingNo'><Input /></Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item {...layout} label='备注' name='remark' getValueFromEvent={stringTrim}><Input /></Form.Item>
              </Col>
            </Row>
        </Form>
        </div>
        <Row justify='space-between'>
          <div style={{ width: '70%', display: 'flex' }}>
            <Space>
            <Button type='primary' onClick={() => {
              setRelevanceDisposevisible(true)
            }}>关联处置</Button>
          </Space>
            {
              disposeInfo && (
                <Tooltip title={disposeInfo} placement="topLeft">
                  <div className={styles.footer}>
                    {disposeInfo}
                  </div>
                </Tooltip>
              )
            }
          </div>
          <Space>
            <Button onClick={close}>取消</Button>
            <Button type='primary' loading={loading} onClick={form.submit}>确认</Button>
          </Space>
        </Row>
      </Modal>
      <RelevanceDisposeModal
        visible={RelevanceDisposevisible}
        patientId={patientId}
        selectIDs={selectIDs}
        id={id}
        currentList={disposeList}
        onCancel={() => {
        setRelevanceDisposevisible(false)
        }}
        onOK={(v) => {
          setDisposeList(v)
          setRelevanceDisposevisible(false)
        }}
      />
    </>
  );
}
