export function getOrderCategoryName(orderCategory: any) {
  switch (orderCategory) {
    case "1":
      return "甲";
    case "2":
      return "已";
    case "3":
      return "丙";
    case "4":
      return "混";
  }
}
