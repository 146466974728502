import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendAsync } from '../../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../../app/store'
import * as S from '../../../services/settlement'
import {queryElecSetlCertInfoUpldRslt, upldElecSetlCertBody} from "../../../services/settlement";

// 清算申请
export const settlementApply = createAsyncThunk<
    any,
  S.ApplyBody,
  RootThunkApi
>('/settlement/settlementApply', async (body, api) => {
  return sendAsync(S.settlementApply(body), api)
})

// 清算申请撤销
export const settlementApplyRepeal = createAsyncThunk<
    any,
  S.ApplyRepealBody,
  RootThunkApi
>('/settlement/settlementApplyRepeal', async (body, api) => {
  return sendAsync(S.settlementApplyRepeal(body), api)
})

//  清单上传 4101A
export const settlementListUpload = createAsyncThunk<
    any,
  S.ListUploadBody,
  RootThunkApi
>('/settlement/settlementListUpload', async (body, api) => {
  return sendAsync(S.settlementListUpload(body), api)
})

// 清单修改 4102
export const settlementListChange = createAsyncThunk<
    any,
  S.ListChangeBody,
  RootThunkApi
>('/settlement/settlementListChange', async (body, api) => {
  return sendAsync(S.settlementListChange(body), api)
})

// 清单查询 4103
export const settlementListSearch = createAsyncThunk<
    any,
    S.ListChangeBody,
    RootThunkApi
    >('/settlement/settlementListSearch', async (body, api) => {
  return sendAsync(S.settlementListSearch(body), api)
})

// 4906 结算凭证上传
export const upldElecSetlCert = createAsyncThunk<
    any,
    S.upldElecSetlCertBody,
    RootThunkApi
    >('/settlement/upldElecSetlCertAsync', async (body, api) => {
  return sendAsync(S.upldElecSetlCert(body), api)
})

// 4907 结算凭证上传
export const queryElecSetlCertInfoUpldRsltAsync = createAsyncThunk<
    any,
    S.upldElecSetlCertBody,
    RootThunkApi
    >('/settlement/queryElecSetlCertInfoUpldRsltAsync', async (body, api) => {
  return sendAsync(S.queryElecSetlCertInfoUpldRslt(body), api)
})
