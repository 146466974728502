/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-12-07 15:09:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-20 19:04:41
 */
import React from 'react'
import { createAsyncThunk,  } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootThunkApi } from '../../app/store'
import { electronicTicketUploadList, ElectronicTicketUploadParams, medicalInformationUploadModalDetail, MedicalInformationUploadModalDetailParams, selfMedicalInformationUpload, selfMedicalInformationUploadBody, selfMedicalInformationUploadElecSetlCert, selfMedicalInformationUploadElecSetlCertBody, selfQueryElecSetlCertInfoUpldRslt, selfQueryElecSetlCertInfoUpldRsltBody, selfQuerySetlCertStas, selfQuerySetlCertStasBody } from '../../services/electronicTicketUpload'

export const getElectronicTicketUploadListAsync = createAsyncThunk<
  void,
  ElectronicTicketUploadParams,
  RootThunkApi
>('payment/getElectronicTicketUploadListAsync', async (params, api) => {
  return sendAsync(electronicTicketUploadList(params), api)
})


// 获取详情
export const getMedicalInformationUploadModalDetailAsync =
  createAsyncThunk<
    void,
    MedicalInformationUploadModalDetailParams,
    RootThunkApi
  >(
    'payment/getMedicalInformationUploadModalDetailAsync',
    async (params, api) => {
      return sendAsync(medicalInformationUploadModalDetail(params), api)
    }
  ) 

  // 自费保存
export const selfMedicalInformationUploadAsync = createAsyncThunk<
  void,
  selfMedicalInformationUploadBody,
  RootThunkApi
>('confirm/selfMedicalInformationUploadAsync', async (body, api) => {
  return sendAsync(selfMedicalInformationUpload(body), api)
})


// 医疗机构上传电子结算凭证信息结果查询
export const selfMedicalInformationUploadElecSetlCertAsync = createAsyncThunk<
  void,
  selfMedicalInformationUploadElecSetlCertBody,
  RootThunkApi
>(
  'confirm/selfMedicalInformationUploadElecSetlCertAsync',
  async (body, api) => {
    return sendAsync(selfMedicalInformationUploadElecSetlCert(body), api)
  }
)

// 医疗机构上传电子结算凭证信息结果查询
export const selfQueryElecSetlCertInfoUpldRsltAsync = createAsyncThunk<
  void,
  selfQueryElecSetlCertInfoUpldRsltBody,
  RootThunkApi
>('confirm/selfQueryElecSetlCertInfoUpldRsltAsync', async (body, api) => {
  return sendAsync(selfQueryElecSetlCertInfoUpldRslt(body), api)
})



// 查询电子结算凭证状态
export const selfQuerySetlCertStasAsync = createAsyncThunk<
  void,
  selfQuerySetlCertStasBody,
  RootThunkApi
>('confirm/selfQuerySetlCertStasAsync', async (body, api) => {
  return sendAsync(selfQuerySetlCertStas(body), api)
})




