/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-29 19:45:19
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-08 09:18:41
 */
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./additionDrug.module.css";
import {
  getMintenanceResultName,
  getMintenanceStepName,
  getQualityOfCommunityName,
  MintenanceResults,
  MintenanceStepS,
  QualityOfCommunities,
} from "../../../../models/maintenance";
import { MaintenanceDurg } from "./column";
import { useDispatch, useSelector } from "react-redux";
import {
  getModalDurg,
  SaveAllDurgAsync,
  selectModalCurrent,
  selectModalData,
  selectModalTotal,
  setModalCurrent,
  submitDurgAsync,
} from "./addtionDrugSlice";
import { ModalDurgParams } from "../../../../services/addtionDrug";
import {
  getMaterialsCategoryName,
  MaterialsCategories,
} from "../../../../models/commodity";
import { DataType } from "../../../putstorage/putstorage";
import { useLocation } from "react-router";
import { RootDispatch } from "../../../../app/store";
import { unwrapResult } from "@reduxjs/toolkit";
const { Option } = Select;

export const MintenanceModule = (props: {
  tenantMaintainId: number;
  maintainCategory: number;
  storehouseId: number;
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}) => {
  const location: any = useLocation();

  const dispatch = useDispatch<RootDispatch>();

  const [formMaintenance] = Form.useForm();

  const [form] = Form.useForm();

  const data = useSelector(selectModalData);

  const current = useSelector(selectModalCurrent);

  const total = useSelector(selectModalTotal);

  const [size, setSize] = useState(10);

  const [queries, setQueries] = useState<ModalDurgParams>();

  const [selectRow, setSelectRow] = useState<any>([]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectRow(
        selectedRows.map((v) => {
          return {
            ...v,
            ...formMaintenance.getFieldsValue(),
            maintainMeasure: JSON.stringify(
              formMaintenance.getFieldsValue().maintainMeasure
            ),
            tenantMaintainId: props.tenantMaintainId,
          };
        })
      );
    },
  };

  useEffect(() => {
    formMaintenance.setFieldsValue({
      maintainMeasure: [0],
      maintainQuality: 0,
      maintainResult: 0,
    });
  }, []);

  useEffect(() => {
    if (props.storehouseId || props.maintainCategory) {
      dispatch(
        getModalDurg({
          ...queries,
          size,
          current,
          storehouseId: props.storehouseId,
          maintainCategory: props.maintainCategory,
        })
      );
    }
  }, [queries, size, current, props.storehouseId, props.maintainCategory]);

  return (
    <Modal
      visible={props.visible}
      title="添加养护药品"
      okText="确认"
      cancelText="取消"
      className={styles.Modal}
      bodyStyle={{ padding: "10px 20px" }}
      onOk={() => {
        props.onOk();
      }}
      onCancel={() => {
        props.onCancel();
      }}
      footer={null}
    >
      {/* <Row gutter={10} style={{ marginBottom: "10px" }}>
        <Col span={3}>
          <Button type="primary" style={{ width: "100%" }}>
            添加勾选药品
          </Button>
        </Col>
        <Col span={3}>
          <Button type="primary" style={{ width: "100%" }}>
            添加全部药品
          </Button>
        </Col>
      </Row> */}
      <Form
        form={formMaintenance}
        onFieldsChange={() => {
          setSelectRow(
            selectRow.map((v: any) => {
              return {
                ...v,
                ...formMaintenance.getFieldsValue(),
                maintainMeasure: JSON.stringify(
                  formMaintenance.getFieldsValue().maintainMeasure
                ),
                tenantMaintainId: props.tenantMaintainId,
              };
            })
          );
        }}
      >
        <Row gutter={40} style={{ height: "46px" }}>
          <Col span={8}>
            <Form.Item name="maintainMeasure" label="养护措施">
              <Select
                mode="multiple"
                className={styles.multiple}
                allowClear
                // suffixIcon={<CaretDownOutlined />}
              >
                {MintenanceStepS.map((v) => (
                  <Option key={v} value={v}>
                    {getMintenanceStepName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="maintainQuality" label="质量状况">
              <Select>
                {QualityOfCommunities.map((v) => (
                  <Option key={v} value={v}>
                    {getQualityOfCommunityName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="maintainResult" label="养护结果">
              <Select>
                {MintenanceResults.map((v) => (
                  <Option key={v} value={v}>
                    {getMintenanceResultName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Form
        form={form}
        onFieldsChange={() => {
          setQueries({...form.getFieldsValue()});
          dispatch(setModalCurrent(1))
        }}
      >
        <Row
          gutter={11}
          style={{ height: "42px", position: "relative", zIndex: 999 }}
          wrap={false}
        >
          <Col span={6}>
            <Form.Item name="name">
              <Input
                placeholder="请输入药品名称或助记码"
                allowClear
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="materialsCategory">
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="药品类别"
                allowClear
              >
                {MaterialsCategories.map((v) => (
                  <Option value={v} key={v}>
                    {getMaterialsCategoryName(v)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              htmlType="submit"
              type="primary"
              style={{ width: "100%", float: "right" }}
              onClick={() => {
                setQueries(form.getFieldsValue());
              }}
            >
              查询
            </Button>
          </Col>
          <Col span={3} offset={6}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => {
                dispatch(submitDurgAsync(selectRow))
                  .then(unwrapResult)
                  .then(() => {
                    props.onOk();
                  });
              }}
            >
              添加勾选药品
            </Button>
          </Col>
          <Col span={3}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => {
                dispatch(
                  SaveAllDurgAsync({
                    ...formMaintenance.getFieldsValue(),
                    ...form.getFieldsValue(),
                    maintainMeasure: JSON.stringify(
                      formMaintenance.getFieldsValue().maintainMeasure
                    ),
                    storehouseId: props.storehouseId,
                    maintainCategory: props.maintainCategory,
                    tenantMaintainId: props.tenantMaintainId,
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    props.onOk();
                  });
              }}
            >
              添加全部药品
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        className={styles.supper}
        rowKey={(record: any) => record.tenantInventoryLedgerId}
        columns={MaintenanceDurg(size * (current - 1))}
        dataSource={data}
        pagination={false}
        rowSelection={{
          // type: selectionType,
          ...rowSelection,
        }}
      ></Table>
      <Row style={{ justifyContent: "flex-end" }}>
        <Pagination
          total={total}
          current={current}
          pageSize={size}
          showSizeChanger
          pageSizeOptions={['10', '20', '50', '100', '500']}
          showQuickJumper
          style={{
            marginTop: "10px",
          }}
          onChange={(current, size) => {
            setSize(size as number);
            dispatch(setModalCurrent(current));
          }}
        />
      </Row>
    </Modal>
  );
};
