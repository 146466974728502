import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Select, Space } from "antd";
import { use } from "echarts";
import "moment/locale/zh-cn";
import React, { ReactElement, useEffect, useRef } from "react";
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from "../../../../compnents/form/DateSelect";

import { NoFormulaParmas } from "../../../../services/formula";

export const NurseQuerys = (props: {
  isModalVisible: boolean
  onValueChange: (queries: NoFormulaParmas) => void;
}): ReactElement => {

  const [form] = Form.useForm();

  const inputRef = useRef<any>(null)
   
 
  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today);
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
      range: '1',
    });
    inputRef.current!.focus({ //搜索框获取焦点
      cursor: 'start',
    });

    form.submit();
  }, []);

  useEffect(() => {
    if (!props.isModalVisible) {
      form.setFieldsValue({
        param: ''
      });
      form.submit();
    }
  }, [props.isModalVisible])
  return (
    <Form
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      form={form}
      onFinish={(vs) => props.onValueChange(vs)}
    >
      <Row
        style={{ marginBottom: 10, width: '100%' }}
        align='middle'
        justify='space-between'
      >
        <Space>
          <Form.Item name='param' noStyle>
            <Input
              ref={inputRef}
              autoComplete='off'
              placeholder='请输入姓名/手机号/病历号'
              style={{ width: 250 }}
              onChange={() => form.submit()}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item name='range' noStyle>
            <Select
              showSearch
              style={{ width: '200px' }}
              placeholder='查看范围'
              optionFilterProp='children'
              onChange={() => form.submit()}
            >
              <Select.Option value='1'>查看范围-以病人查询</Select.Option>
              <Select.Option value='2'>查看范围-以处方查询</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='useMethod' noStyle>
            <Select
              showSearch
              style={{ width: '200px' }}
              placeholder='药品用法-全部'
              optionFilterProp='children'
              allowClear
              onChange={() => form.submit()}
            >
              <Select.Option value='静滴'>药品用法-静滴</Select.Option>
              <Select.Option value='肌注'>药品用法-肌注</Select.Option>
              <Select.Option value='雾化'>药品用法-雾化</Select.Option>
              <Select.Option value='静推'>药品用法-静推</Select.Option>
              <Select.Option value='静注'>药品用法-静注</Select.Option>
              <Select.Option value='穴注'>药品用法-穴注</Select.Option>
              <Select.Option value='皮下'>药品用法-皮下</Select.Option>

            </Select>
          </Form.Item>
          <DateSelect
            style={{ width: '10rem' }}
            labelPrefix=''
            namePrefix='time'
            placeholder='处方执行-时间'
            initialValue={DateRangeType.Today}
            onChange={() => form.submit()}
            allowClear={false}
          />
          <Button
            type='primary'
            onClick={() => props.onValueChange(form.getFieldsValue())}
          >
            查询
          </Button>
        </Space>
      </Row>
    </Form>
  )
};
