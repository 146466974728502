import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Select, Space } from "antd";
import { use } from "echarts";
import "moment/locale/zh-cn";
import React, { ReactElement, useEffect, useRef } from "react";
import {
    DateRangeType,
    DateSelect,
    getDateRange,
} from "../../../compnents/form/DateSelect";

import { BeforeOperatingParmas } from "../../../services/beforeOperatingManagePage";

export const NurseQuerys = (props: {
    isModalVisible: boolean
    onValueChange: (queries: BeforeOperatingParmas) => void;
}): ReactElement => {

    const [form] = Form.useForm();

    const inputRef = useRef<any>(null)


    useEffect(() => {
        const [head, tail] = getDateRange(DateRangeType.Today);
        form.setFieldsValue({
            timeHead: head,
            timeTail: tail,
            range: '1',
        });
        inputRef.current!.focus({ //搜索框获取焦点
            cursor: 'start',
        });

        form.submit();
    }, []);

    useEffect(() => {
        if (!props.isModalVisible) {
            form.setFieldsValue({
                param: ''
            });
            form.submit();
        }
    }, [props.isModalVisible])
    return (
        <Form
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
            form={form}
            onFinish={(vs) => props.onValueChange(vs)}
        >
            <Row
                style={{ marginBottom: 10, width: '100%' }}
                align='middle'
                justify='space-between'
            >
                <Space>
                    <Form.Item name='name' noStyle>
                        <Input
                            ref={inputRef}
                            autoComplete='off'
                            placeholder='消费者名称'
                            style={{ width: 200 }}
                            onChange={() => form.submit()}
                            prefix={<SearchOutlined />}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item name='surgicalItems' noStyle>
                        <Input
                            autoComplete='off'
                            placeholder='手术名称'
                            style={{ width: 200 }}
                            onChange={() => form.submit()}
                            prefix={<SearchOutlined />}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item name='opdoctor' noStyle>
                        <Input
                            autoComplete='off'
                            placeholder='医生名称'
                            style={{ width: 200 }}
                            onChange={() => form.submit()}
                            prefix={<SearchOutlined />}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item name='certifyingStatus' noStyle>
                        <Select
                            showSearch
                            style={{ width: '160px' }}
                            placeholder='状态'
                            optionFilterProp='children'
                            allowClear
                            onChange={() => form.submit()}
                        >
                            <Select.Option value='0'>已取消</Select.Option>
                            <Select.Option value='1'>已认证</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name='surgicalDate' noStyle>
                    <DateSelect
                        style={{ width: '10rem' }}
                        labelPrefix=''
                        namePrefix='surgicalDate'
                        placeholder='手术时间'
                        onChange={() => form.submit()}
                        allowClear
                    />
                    </Form.Item>
                    <Button
                        type='primary'
                        onClick={() => props.onValueChange(form.getFieldsValue())}
                    >
                        查询
                    </Button>
                </Space>
            </Row>
        </Form>
    )
};
