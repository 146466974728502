/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-23 09:32:57
 * @LastEditors: sj
 * @LastEditTime: 2022-09-07 10:06:16
 */
import { API_PREFIX, Request } from "./request"

export interface TriageListParams {
  current?: number
  endTime?: string
  patientName?: string
  size?: number
  startTime?: string
  state?: number
}

export interface TriageListParams {
  current?: number
  endTime?: string
  patientName?: string
  size?: number
  startTime?: string
  state?: number
}

export interface submitTriageBody {
  acceptDepartment?: string
  acceptName?: string
  acceptPhone?: string
  address?: string
  age?: number
  animalHeat?: string
  coldChain?: number
  fever?: number
  followUp?: string
  healthCodeColor?: number
  highRisk?: number
  id?: any
  identityCard?: string
  inbound?: number
  mask?: number
  messageSend?: number
  patientFlow?: number
  patientName?: string
  phone?: string
  sex?: number
  symptom?: string
  touch?: number
  describes?: string
  occupation?: string
  purpose?: string
  tourCode?: number
  reportDepartment?: number
  riskAddress?: string
}
export interface UserListParams {
  current?: number
  keyword?: string
  size?: number
  status?: number
}
export interface userItemBody {
  address?: string
  email?: string
  id?: number
  payMethod?: number
  phone?: string
  realname?: string
  sex?: number
  tenantId?: number
  username?: string
}

export interface changePasswordBody {
  oldPassword: string
  password: string
}

export interface RiskAreaParams {
  grade?: number //等级:0低风险;1中风险;2高风险,3中风险+高风险,参数不传返回全部数据
}

//预检分诊列表
export function getTriageList(params: TriageListParams): Request {
  return {
    url: `${API_PREFIX}/blade-precheck/api/precheck/list`,
    method: "GET",
    params,
  }
}

// 预检分诊详情
export function getTriageItem(id?: number): Request {
  return {
    url: `${API_PREFIX}/blade-precheck/api/precheck/detail`,
    method: "GET",
    params: {
      id,
    },
  }
}

// 获取H5所需信息
export function getTenantParams(): Request {
  return {
    url: `${API_PREFIX}/blade-precheck/api/precheck/getparams`,
    method: "POST",
  }
}

// 删除分诊
export function removeTriageItem(ids: string): Request {
  return {
    url: `${API_PREFIX}/blade-precheck/api/precheck/remove`,
    method: "POST",
    params: {
      ids,
    },
  }
}

// 提交分诊
export function submitTriage(body: submitTriageBody): Request {
  return {
    url: `${API_PREFIX}/blade-precheck/api/precheck/submit`,
    method: "POST",
    body,
  }
}

export function getRiskArea(params: RiskAreaParams): Request {
  return {
    url: `${API_PREFIX}/blade-precheck/epidemic/list`,
    method: "GET",
    params,
  }
}

// 删除预检分诊登记记录
export function removeSingle(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-precheck/api/precheck/removeSingle`,
    method: "POST",
    body:{
      id
    },
  }
}