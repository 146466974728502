import { createAsyncThunk } from '@reduxjs/toolkit'
import { sendAsync } from '../../../app/applicationSlice'
import { RootThunkApi } from '../../../app/store'
import * as S from '../../../services/invoiceDetail'
import { checkFlushParams } from '../../../services/invoiceDetail'

/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-09-08 15:00:18
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-08 14:35:58
 */

// 删除发票明细
export const invoiceDetailDeleteAsync = createAsyncThunk<
  void,
  string,
  RootThunkApi
>('invoice/invoiceDetailDeleteAsync', async (id, api) => {
  return sendAsync(S.invoiceDetailDelete(id), api)
})

// 发票明细改状态
export const invoiceDetailStatusAsync = createAsyncThunk<
  void,
  S.invoiceDetailStatusParams,
  RootThunkApi
>('invoice/invoiceDetailDeleteAsync', async (body, api) => {
  return sendAsync(S.invoiceDetailStatus(body), api)
})
// 数电票 查询红冲结果
export const checkFlushAsync = createAsyncThunk<
  void,
  S.checkFlushParams,
  RootThunkApi
>('invoice/checkFlushAsync', async (body, api) => {
  return sendAsync(S.checkFlush(body), api)
})
// 数电票 查询红冲结果(前置机模式)
export const checkFlushForMbfeAsync = createAsyncThunk<
  any,
  S.checkFlushParams,
  RootThunkApi
  >('invoice/checkFlushForMbfeAsync', async (body, api) => {
  return sendAsync(S.checkFlushForMbfe(body), api)
})

//下载
export const getDzfpExportAsync = createAsyncThunk<void, any, RootThunkApi>(
  'invoice/getDzfpExportAsync',
  async (params, api) => {
    return sendAsync(S.dfpExportAsync(params), api)
  }
)
