/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2024-07-22 14:02:18
 */
import { RecipeStatus } from './recipe'

export interface Retail {
  id: string
  recipeState: RecipeStatus
  amount: string
  patientId: string
  patientName: string
  patientSex: number
  operateUser: string
  operateTime: string
  paymentId: string
  payStatus: string
  sta: boolean
  sellingPrice: string
  rid: string
  insuranceCode?: string
  ageYear?: any
  ageMonth?: any
  birthday?: any
  phone?: any
  medicalRecord?: any
  ybPersonalNo?: any
  dnzhye?: any
  lnzhye?: any
  companyName?: any
  memberTypeStr?: any
  insuranceRegion?:any
}

export function fromJson(
  json: { id?: number; patientId?: number; paymentId?: number } & Record<
    string,
    never
  >
): Retail {
  return {
    id: json.id?.toString() || '',
    recipeState: json.recipeState,
    amount: json.amount,
    patientId: json.patientId?.toString() || '',
    patientName: json.patientName,
    patientSex: json.patientSex,
    operateUser: json.operateUser,
    operateTime: json.operateTime,
    paymentId: json.paymentId?.toString() || '',
    payStatus: json.payStatus,
    sta: json.sta,
    sellingPrice: json.sellingPrice,
    rid: json.rid,
    insuranceCode: json.insuranceCode,
    ageYear: json.ageYear,
    ageMonth: json.ageMonth,
    birthday: json.birthday,
    phone: json.phone,
    medicalRecord: json.medicalRecord,
    ybPersonalNo: json.ybPersonalNo,
    dnzhye: json.dnzhye,
    lnzhye: json.lnzhye,
    companyName: json.companyName,
    memberTypeStr: json.memberTypeStr,
    insuranceRegion:json?.insuranceRegion
  }
}
