/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-16 17:01:17
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-17 17:01:17
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import {
  batchGenerateAcceptanceInfo,
  generateAcceptanceInfo,
  generateAcceptancePrintInfo,
  purchaseAcceptanceDetail,
  purchaseAcceptanceExport,
  purchaseAcceptanceList,
  purchaseAcceptanceParams,
} from '../../services/PurchaseAcceptance'

// 列表数据
export const purchaseAcceptanceListAsync = createAsyncThunk<
  void,
  purchaseAcceptanceParams,
  RootThunkApi<void>
>('purchaseAcceptance/purchaseAcceptanceListAsync', async (params, api) => {
  return sendAsync(purchaseAcceptanceList(params), api, true)
})

// 详情
export const purchaseAcceptanceDetailAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('purchaseAcceptance/purchaseAcceptanceDetailAsync', async (params, api) => {
  return sendAsync(purchaseAcceptanceDetail(params), api, true)
})

// 生成验收信息
export const generateAcceptanceInfoAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('purchaseAcceptance/generateAcceptanceInfoAsync', async (params, api) => {
  return sendAsync(generateAcceptanceInfo(params), api, true)
})

//批量生成验收信息
export const batchGenerateAcceptanceInfoAsync = createAsyncThunk<
    void,
    any,
    RootThunkApi<void>
>('purchaseAcceptance/batchGenerateAcceptanceInfoAsync', async (params, api) => {
  return sendAsync(batchGenerateAcceptanceInfo(params), api, true)
})

// 入库单打印验收单数据
export const generateAcceptancePrintInfoAsync = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('purchaseAcceptance/generateAcceptancePrintInfo', async (params, api) => {
  return sendAsync(generateAcceptancePrintInfo(params), api, true)
})

// 导出
export const getPurchaseAcceptanceExport = createAsyncThunk<
  void,
  purchaseAcceptanceParams,
  RootThunkApi
>('purchaseAcceptance/getPurchaseAcceptanceExport ', async (params, api) => {
  return sendAsync(purchaseAcceptanceExport(params), api)
})
