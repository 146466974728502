import { EditableList } from '../../../../compnents/list/EditableList'
import React, { ReactElement, useEffect, useState } from 'react'
import { NurseQuery } from '../../formula/formulas/Query'
import { useDispatch, useSelector } from 'react-redux'
import { Columns } from '../../formula/formulas/columns'
import {
  getformulaList,
  getformula,
  selectTotal,
  selectCurrent,
  selectFormulaData,
  setCurrent,
  selectPageLoading,
  setPageLoading,
} from '../formulas/formulaSlice'
import { NoFormulaParmas } from '../../../../services/formula'
import styles from './formula.module.css'
import { ModelList } from './Modallists'
import { recipeColumn } from './column'

export const Formula = (props: { id?: number }): ReactElement => {
  const dispatch = useDispatch()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [isTreatmentId, setIsTreatmentId] = useState('')

  const [SelectId, setSelectId] = useState(0)

  const Current = useSelector(selectCurrent)

  const total = useSelector(selectTotal)

  const data = useSelector(selectFormulaData)

  const [size, setSize] = useState(10)

  const CREDENTIAL_KEY = 'states'

  const [queries, setQueries] = useState<NoFormulaParmas>()

  const pageLoadingRX = useSelector(selectPageLoading)

  const range: any = sessionStorage.getItem(CREDENTIAL_KEY)

  useEffect(() => {
    if (queries?.timeHead) {
      dispatch(setPageLoading(true))
      dispatch(getformulaList({ ...queries, current: Current, state: 1, size }))
      setTimeout(() => {
        dispatch(setPageLoading(false))
      }, 3000)
    }
  }, [queries, size, Current, props.id])

  useEffect(() => {
    if (queries?.param?.length == 9 && Number(data.length) == 1) {
      dispatch(
        getformula({
          registrationId: data[0]?.registrationId,
          state: 1,
          normalUsemethod: data[0]?.normalUsemethod,
        })
      )
      setIsModalVisible(true)
      setIsTreatmentId(data[0]?.treatmentId)
    }
  }, [data])

  return (
    <>
      <div className={styles.contenter}>
        <NurseQuery
          isModalVisible={isModalVisible}
          onValueChange={(v) => {
            setQueries({ ...queries, ...v })
            dispatch(setCurrent(1))
            const state: any = v.range
            sessionStorage.setItem(CREDENTIAL_KEY, state)
          }}
        />
        <EditableList
          rowKey={(record, i) => record.id + i + 100}
          loading={pageLoadingRX}
          className={styles.formula}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number)
            dispatch(setCurrent(current))
          }}
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          columns={
            range === '1'
              ? Columns(size * (Current - 1))
              : recipeColumn(size * (Current - 1))
          }
          onRow={(d: any) => ({
            onDoubleClick: () => {
              dispatch(
                getformula({
                  registrationId: d.registrationId,
                  state: 1,
                  normalUsemethod: d.normalUsemethod,
                })
              )
              setIsModalVisible(true)
              setIsTreatmentId(d.treatmentId)
            },
          })}
        />
      </div>
      <ModelList
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false)
          setSelectId(0)
        }}
        treatmentId={isTreatmentId}
        onCancel={() => {
          setIsModalVisible(false)
          setSelectId(0)
        }}
      />
    </>
  )
}
