/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-07 13:53:11
 * @LastEditors: sj
 * @LastEditTime: 2022-06-07 13:59:19
 */
export enum selfPayUploadType {
  costInfo = 1,
  clinicInfo = 2,
  diagnoseInfo = 3,
}

export const getSelfPayUploadName = (type: selfPayUploadType): string => {
  switch (type) {
    case selfPayUploadType.costInfo:
      return '自费病人就医费用明细查询'
    case selfPayUploadType.clinicInfo:
      return '自费病人就医就诊信息查询'
    case selfPayUploadType.diagnoseInfo:
      return '自费病人就医诊断信息查询'
    default:
      return ''
  }
}
