
import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Space, Modal, Col, Radio, notification } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import {
    DateRangeType,
    DateSelect,
    getDateRange,
} from '../../compnents/form/DateSelect'
import { EditableList } from '../../compnents/list/EditableList'
import styles from './coupons.module.css'
import { ActiveSettingColumns } from './columns'
import { RootDispatch } from '../../app/store'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { ActiveSettiongQuery } from './query'
import { CheckOutlined } from '@ant-design/icons'
import { getActiveSettingList, getActiveSettingSubmit, getActiveSettingDetail, getCouponsSettingList } from './coupons'
import { CouponsItems } from './couponsItems'

const { Option } = Select

export const ActiveSetting = (props: { selectKey: string }) => {
    const dispatch = useDispatch<RootDispatch>()

    const [params, setParams] = useState<any>({
        current: 1,
        size: 10,
    })

    const [page, setPage] = useState<any>({
        total: 0,
        items: [],
    })

    const [form] = Form.useForm()

    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 20 },
    }

    const [title, setTitle] = useState('')

    const [editId, setEditId] = useState<any>()

    const [pageLoading, setPageLoading] = useState(false)

    const [visable, setVisable] = useState(false)

    const [couponsList, setCouponsList] = useState<any>([])

    const [couponsIds, setCouponsIds] = useState<any>([])

    const getPage = () => {
        setPageLoading(true)
        dispatch(getActiveSettingList(params))
            .then(unwrapResult)
            .then((res: any) => {
                setPage({
                    items: res.records,
                    total: res.total,
                })
            })
            .finally(() => {
                setPageLoading(false)
            })
    }

    const getCouponsList = () => {
        dispatch(getCouponsSettingList({
            current: 1,
            size: 1000,
            status: 0
        }))
            .then(unwrapResult)
            .then((res: any) => {
                setCouponsList(res.records?.map((item: any) => {
                    return {
                        ...item,
                        isActive: false
                    }
                }))
            })
    }

    useEffect(() => {
        getCouponsList()
    }, [props.selectKey])

    useEffect(() => {
        getPage()
    }, [params])

    return (
        <div className={styles.wrap}>
            {/* 查询区域 */}
            <ActiveSettiongQuery
                onAdd={() => {
                    setCouponsList(couponsList.map((item: any) => {
                        return {
                            ...item,
                            isActive: false
                        }
                    }))
                    setVisable(true)
                    setTitle('新增活动')
                    setEditId('')
                    setCouponsIds([])
                }}
                onValueChange={(v) => {
                    setParams({
                        ...params,
                        ...v,
                        current: 1,
                    })
                }} />

            {/* 表格区域 */}
            <EditableList
                style={{ overflow: 'hidden' }}
                scroll={{ y: '620px' }}
                loading={pageLoading}
                page={{
                    current: params.current,
                    size: params.size,
                    total: page.total,
                    items: page.items,
                }}
                columns={ActiveSettingColumns((text, id: number, item) => {
                    if (text === 'upload') {
                        dispatch(getActiveSettingDetail({ id }))
                            .then(unwrapResult)
                            .then((res: any) => {
                                setCouponsList(couponsList.map((item: any) => {
                                    if (res.couponsIds.indexOf(item.id) !== -1) {
                                        return { ...item, isActive: true }
                                    }
                                    return { ...item, isActive: false }
                                }))
                                form.setFieldsValue({
                                    name: item.name,
                                    effectiveDays: item.effectiveDays,
                                    couponsName: item.couponsName
                                })
                                setCouponsIds(res.couponsIds)
                                setVisable(true)
                                setTitle('编辑活动')
                                setEditId(id)
                            })
                    } else if (text === 'change') {
                        dispatch(getActiveSettingDetail({ id }))
                        .then(unwrapResult)
                        .then((res: any) => {
                            dispatch(getActiveSettingSubmit({ ...res, status: item.status === 1 ? 0 : 1 }))
                                .then(unwrapResult)
                                .then(() => {
                                    notification.success({
                                        message: '操作成功',
                                        duration: 3,
                                    })
                                    form.resetFields()
                                    getPage()
                                })
                        })
                    }
                }, params.size * (params.current - 1))}
                onChangePage={(current, size) =>
                    setParams({
                        ...params,
                        current,
                        size: size || 10,
                    })
                }
                onRow={() => ({
                    onDoubleClick: () => {
                        return
                    },
                })}
            />
            <Modal
                width={800}
                title={title}
                okText='提交'
                footer={null}
                centered
                onOk={() => {
                    setVisable(false)
                }}
                onCancel={() => {
                    form.resetFields()
                    setVisable(false)
                }}
                visible={visable}
                destroyOnClose
            >
                <Form
                    form={form}
                    name='basic'
                    {...layout}
                    autoComplete='off'
                    onFinish={(v) => {
                        dispatch(getActiveSettingSubmit({ ...v, id: editId ? editId : undefined, couponsIds }))
                            .then(unwrapResult)
                            .then(() => {
                                notification.success({
                                    message: '操作成功',
                                    duration: 3,
                                })
                                form.resetFields()
                                getPage()
                                setCouponsIds([])
                            }).finally(() => {
                                setVisable(false)
                            })
                    }}
                >
                    <Form.Item label='活动名称' name='name' rules={[{ required: true, message: '请输入活动名称' }]}>
                        <Input
                            placeholder='请输入活动名称'
                            allowClear
                            size='middle'
                        />
                    </Form.Item>
                    <Form.Item label='券有效天数' name='effectiveDays' rules={[{ required: true, message: '请选择优惠券领取后有效天数' }]}>
                        <Input
                            placeholder='请选择优惠券领取后有效天数'
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item label='优惠项目' name='couponsName' rules={[{ required: true, message: '请选择优惠项目' }]}>
                        {/* <Row gutter={10}>
                            {couponsList.map((item:any) => {
                                return (<Col span={6} key={item.id} style={{marginBottom: '10px'}}>
                                    <div className={styles.coupons} onClick={() => {
                                        const ids = [...couponsIds]
                                        const ele = ids.indexOf(item.id)
                                        if(ele !== -1) {
                                            ids.splice(ele, 1)
                                        } else {
                                            ids.push(item.id)
                                        }
                                        setCouponsIds(ids)
                                        const newIds = couponsList.map((v: any) => {
                                            if (v.id === item.id) {
                                                return { ...v, isActive: !v.isActive }
                                            } else {
                                                return v
                                            }
                                        })
                                        const names = newIds.filter((v:any) => v.isActive)
                                        const name = names.map((v:any) => v.name).join(',')
                                        setCouponsList(newIds)
                                        form.setFieldsValue({ couponsName: name})
                                    }}>
                                        <div className={styles.coupons_title}>
                                            {item.name}
                                            <span className={styles.coupons_title_icon} style={{ backgroundColor: !item.isActive ? '#fff' : '#0076FF' }}>
                                                <CheckOutlined style={{ color: '#fff' }} />
                                            </span>
                                        </div>
                                        <div className={styles.coupons_content}>
                                            <span>免费</span>
                                            <span style={{ color: 'rgba(42, 124, 246, 1)' }}>体验券</span>
                                        </div>
                                    </div>
                                </Col>)
                            })}
                        </Row> */}
                        <CouponsItems
                            couponsList={couponsList}
                            couponsIds={couponsIds}
                            onClick={(v, n, i) => {
                                setCouponsIds(i)
                                setCouponsList(v)
                                form.setFieldsValue({ couponsName: n })
                            }}
                        />
                    </Form.Item>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ float: "right", marginLeft: '10px' }}
                            >
                                确定
                            </Button>
                            <Button
                                style={{ float: "right" }}
                                onClick={() => {
                                    form.resetFields()
                                    setVisable(false)
                                }}
                            >
                                取消
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}
