import { Button, Popconfirm, TableColumnType } from "antd";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { DateTimeFormatSimple } from "../../models/datetime";
import {
  AccountOutlined,
  DelectOutlined,
  ReverseOutlined,
} from "../../compnents/icons/Icons";

export const InventoryColumns = (
  onClick: (id: number, text: string) => void,
  startIndex: number,
  YBUploadcategory?: string
): TableColumnType<any>[] => {
  const layout = {
    width: "14px",
    height: "14px",
    marginRight: "4px",
  };
  const center = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const centers = {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  };
  return [
    {
      title: "序号",
      align: "center",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "库房名称",
      align: "center",
      dataIndex: "storehouseName",
      key: "storehouseName",
      sorter: (a, b) => a.storehouseName.localeCompare(b.storehouseName),
    },
    {
      title: "业务单号",
      align: "center",
      dataIndex: "putNumber",
      key: "putNumber",
    },
    // {
    //   title: "对方单位",
    //   align: "center",
    //   dataIndex: "supplierName",
    //   key: "supplierName",
    // },
    {
      title: "业务部门",
      align: "center",
      dataIndex: "departmentName",
      key: "departmentName",
      sorter: (a, b) => a.departmentName.localeCompare(b.departmentName),
    },
    {
      title: "业务人员",
      align: "center",
      dataIndex: "userName",
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      key: "userName",
    },
    {
      title: "是否登账",
      align: "center",
      dataIndex: "storehouseTag",
      key: "storehouseTag",
      sorter: (a: any, b: any) => a.storehouseTag - b.storehouseTag,
      render: (storehouseTag: number) => {
        switch (storehouseTag) {
          case 0:
            return "否";
          case 1:
            return <span style={{ color: "#1CD0BD" }}>是</span>;
          case 2:
            return "已作废";
          case 3:
            return "已冲正";
          default:
            break;
        }
      },
    },
    {
      title: "创建时间",
      align: "center",
      dataIndex: "createTime",
      key: "createTime",
      sorter: (a: any, b: any) =>
        moment(a.createTime).unix() - moment(b.createTime).unix(),
      render: (createTime) => {
        return createTime
          ? moment(createTime).format(DateTimeFormatSimple)
          : "";
      },
    },
    {
      title: "登账时间",
      align: "center",
      dataIndex: "upAccountTime",
      key: "recepitsDate",
      sorter: (a: any, b: any) =>
        moment(a.upAccountTime || moment()).unix() -
        moment(b.upAccountTime || moment()).unix(),
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
          case 3:
            return moment(t.upAccountTime).format(DateTimeFormatSimple);
          default:
            return "";
        }
      },
    },
    {
      title: "盈亏金额（进价）",
      align: "right",
      dataIndex: "totalUnitPrice",
      key: "totalUnitPrice",
      render: (_, t) => {
        return t.totalUnitPrice !== -1
          ? parseFloat(t.totalUnitPrice).toFixed(2)
          : "0.00";
      },
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "storehouseTag",
      key: "storehouseTag",
      render: (storehouseTag, data) => {
        if (YBUploadcategory) {
          return (
            data.ybUploadFlag === '2' ?
              '已上传'
              : (
                <Popconfirm
                  title="确认执行此操作？"
                  onConfirm={() => {
                    onClick(data.id, storehouseTag === 1 ? '盘点单上传' : '盘点单冲正上传');
                  }}
                  okText="确认"
                  cancelText="取消"
                >
                  <Button
                    type='primary'
                    ghost
                    size='small'

                  >
                    {storehouseTag === 1 ? '上传' : '冲正上传'}
                  </Button>
                </Popconfirm>)
          )
        } else {
          switch (storehouseTag) {
            case 0:
              return (
                <div style={{ ...center }}>
                  <Button
                    type="text"
                    size="small"
                    style={{ ...centers }}
                    icon={
                      <AccountOutlined style={{ fill: "#1CD0BD", ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, "登账");
                    }}
                  >
                    登账
                  </Button>
                  <Button
                    type="text"
                    size="small"
                    style={{ ...centers }}
                    icon={
                      <DelectOutlined style={{ fill: "#FF5D5D", ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, "作废");
                    }}
                  >
                    作废
                  </Button>
                  <Button
                    type="text"
                    size="small"
                    style={{ color: "#027AFF" }}
                    onClick={(t: any) => {
                      onClick(data.id, "查看详情");
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              );
            case 1:
              return (
                <div style={{ ...center }}>
                  <Button
                    type="text"
                    size="small"
                    style={{ ...centers }}
                    icon={
                      <ReverseOutlined style={{ fill: "#F5BB4A", ...layout }} />
                    }
                    onClick={(t: any) => {
                      onClick(data.id, "冲正");
                    }}
                  >
                    冲正
                  </Button>
                  <Button
                    type="text"
                    size="small"
                    style={{ color: "#027AFF" }}
                    onClick={(t: any) => {
                      onClick(data.id, "查看详情");
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              );
            case 2:
            case 3:
              return (
                <div>
                  <Button
                    type="text"
                    size="small"
                    style={{ color: "#027AFF" }}
                    onClick={(t: any) => {
                      onClick(data.id, "查看详情");
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              );
            default:
              break;
          }
        }
      },
    },
  ];
};
export const PrintInventoryColumns = (
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      render: (_1, _2, index) => {
        if (_2.id === 0) {
          return "合计";
        } else {
          return `${startIndex + index + 1}`;
        }
      },
    },
    {
      title: "库房名称",
      align: "center",
      dataIndex: "storehouseName",
      key: "storehouseName",
    },
    {
      title: "业务单号",
      align: "center",
      dataIndex: "putNumber",
      key: "putNumber",
    },
    {
      title: "业务部门",
      align: "center",
      dataIndex: "departmentName",
      key: "departmentName",
    },
    {
      title: "业务人员",
      align: "center",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "创建时间",
      align: "center",
      dataIndex: "createTime",
      key: "createTime",
      render: (createTime) => {
        return createTime
          ? moment(createTime).format(DateTimeFormatSimple)
          : "";
      },
    },
    {
      title: "登账时间",
      align: "center",
      dataIndex: "upAccountTime",
      key: "recepitsDate",
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
            return moment(t.recepitsDate).format(DateTimeFormatSimple);
          default:
            return "";
        }
      },
    },
    {
      title: "盈亏金额（进价）",
      align: "right",
      dataIndex: "totalUnitPrice",
      key: "totalUnitPrice",
      render: (_, t) => {
        return t.totalUnitPrice !== -1
          ? parseFloat(t.totalUnitPrice).toFixed(2)
          : "0.00";
      },
    },
  ];
};

export const QingDouColumns = (
  onClick: (id: number, text: string) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const layout = {
    width: "14px",
    height: "14px",
    marginRight: "4px",
  };
  const center = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const centers = {
    display: "flex",
    alignItems: "center",
  };
  return [
    {
      title: "序号",
      align: "center",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "库房名称",
      align: "center",
      dataIndex: "storehouseName",
      key: "storehouseName",
      sorter: (a, b) => a.storehouseName.localeCompare(b.storehouseName),
    },
    {
      title: "业务单号",
      align: "center",
      dataIndex: "putNumber",
      key: "putNumber",
    },
    {
      title: "业务部门",
      align: "center",
      dataIndex: "departmentName",
      key: "departmentName",
      sorter: (a, b) => a.departmentName.localeCompare(b.departmentName),
    },
    {
      title: "业务人员",
      align: "center",
      dataIndex: "userName",
      key: "userName",
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: "是否登账",
      align: "center",
      dataIndex: "storehouseTag",
      key: "storehouseTag",
      sorter: (a: any, b: any) => a.storehouseTag - b.storehouseTag,
      render: (storehouseTag: number) => {
        switch (storehouseTag) {
          case 0:
            return "否";
          case 1:
            return <span style={{ color: "#1CD0BD" }}>是</span>;
          case 2:
            return "已作废";
          case 3:
            return "已冲正";
          default:
            break;
        }
      },
    },
    {
      title: "创建时间",
      align: "center",
      dataIndex: "createTime",
      key: "createTime",
      sorter: (a: any, b: any) =>
        moment(a.createTime).unix() - moment(b.createTime).unix(),
      render: (createTime) => {
        return createTime
          ? moment(createTime).format(DateTimeFormatSimple)
          : "";
      },
    },
    {
      title: "登账时间",
      align: "center",
      sorter: (a: any, b: any) =>
        moment(a.upAccountTime || moment()).unix() -
        moment(b.upAccountTime || moment()).unix(),
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
            return moment(t.recepitsDate).format(DateTimeFormatSimple);
          default:
            return "";
        }
      },
    },
    {
      title: "操作",
      align: "center",
      dataIndex: "storehouseTag",
      key: "storehouseTag",
      render: (storehouseTag, data) => {
        switch (storehouseTag) {
          case 0:
            return (
              <div style={{ ...center }}>
                <Button
                  type="text"
                  size="small"
                  style={{ ...centers }}
                  icon={
                    <AccountOutlined style={{ fill: "#1CD0BD", ...layout }} />
                  }
                  onClick={(t: any) => {
                    onClick(data.id, "登账");
                  }}
                >
                  登账
                </Button>
                <Button
                  type="text"
                  size="small"
                  style={{ ...centers }}
                  icon={
                    <DelectOutlined style={{ fill: "#FF5D5D", ...layout }} />
                  }
                  onClick={(t: any) => {
                    onClick(data.id, "作废");
                  }}
                >
                  作废
                </Button>
                <Button
                  type="text"
                  size="small"
                  style={{ color: "#027AFF" }}
                  onClick={(t: any) => {
                    onClick(data.id, "查看详情");
                  }}
                >
                  查看详情
                </Button>
              </div>
            );
          case 1:
            return (
              <div style={{ ...center }}>
                <Button
                  type="text"
                  size="small"
                  style={{ ...centers }}
                  icon={
                    <ReverseOutlined style={{ fill: "#F5BB4A", ...layout }} />
                  }
                  onClick={(t: any) => {
                    onClick(data.id, "冲正");
                  }}
                >
                  冲正
                </Button>
                <Button
                  type="text"
                  size="small"
                  style={{ color: "#027AFF" }}
                  onClick={(t: any) => {
                    onClick(data.id, "查看详情");
                  }}
                >
                  查看详情
                </Button>
              </div>
            );
          case 2:
          case 3:
            return (
              <div>
                <Button
                  type="text"
                  size="small"
                  style={{ color: "#027AFF" }}
                  onClick={(t: any) => {
                    onClick(data.id, "查看详情");
                  }}
                >
                  查看详情
                </Button>
              </div>
            );
          default:
            break;
        }
      },
    },
  ];
};

export const PrintQingDouColumns = (
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: "序号",
      align: "center",
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: "库房名称",
      align: "center",
      dataIndex: "storehouseName",
      key: "storehouseName",
    },
    {
      title: "业务单号",
      align: "center",
      dataIndex: "putNumber",
      key: "putNumber",
    },
    {
      title: "业务部门",
      align: "center",
      dataIndex: "departmentName",
      key: "departmentName",
    },
    {
      title: "业务人员",
      align: "center",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "创建时间",
      align: "center",
      dataIndex: "createTime",
      key: "createTime",
      render: (createTime) => {
        return createTime
          ? moment(createTime).format(DateTimeFormatSimple)
          : "";
      },
    },
    {
      title: "登账时间",
      align: "center",
      render: (_, t) => {
        switch (t.storehouseTag) {
          case 1:
            return moment(t.recepitsDate).format(DateTimeFormatSimple);
          default:
            return "";
        }
      },
    },
  ];
};
