/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-10 09:21:12
 * @LastEditors: sj
 * @LastEditTime: 2022-08-19 15:02:18
 */
import { Col } from "antd"
import React from "react"
import { TabBar, TabPane } from "../../compnents/widgets/TabBar"
import styles from "./outRecipe.module.css"
import OutRecipe from "./outRecipe"

export const InsuranceOutRecipeTabbar = () => {
  return (
    <Col className={styles.container}>
      <TabBar
        defaultActiveKey={"1"}
      >
        <TabPane key='1' tab='外配处方'>
          <OutRecipe />
        </TabPane>
      </TabBar>
    </Col>
  );
}
