/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-19 15:45:09
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-19 15:52:58
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface DynamicGrossProfitStatementParams {
  billingCategory?: string
  startTime?: string
  endTime?: string
  materialsCategory?: string
}

export function dynamicGrossProfitStatementPage(
  body: DynamicGrossProfitStatementParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/GrossProfitChart`,
    method: 'POST',
    body,
  }
}
