import { CaretDownFilled, CaretLeftFilled } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Modal, ModalProps, Row, Space } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { Material } from '../../../models/material'
import { PrintTemplateCategory } from '../../../models/print'
import {
  getRecipeCategoryName,
  Recipe,
  RecipeCategory,
} from '../../../models/recipe'
import { ThemeContext } from '../../../theme/ThemeContext'
import { PrintTemplateModal } from '../../print/PrintTemplateModal'
import {
  MedicineMaterialColumns,
  TcmColumns,
} from '../../treatment/editor/columns'
import { getRecipeList, getRecipeSimpleList } from './recipeListModalSlice'

interface RecipeListModalProps {
  treatmentId?: string
  patientId?: any
  fyState?: any
  shipmentsState?: any
}

export const RecipeListModal = ({
  treatmentId,
  patientId,
  fyState,
  shipmentsState,
  visible,
  onCancel,
}: RecipeListModalProps & ModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [recpies, setRecipes] = useState<Recipe[]>([])

  const [materials, setMaterials] = useState<Record<string, Material[]>>({})

  const [show, setShow] = useState<Record<string, boolean>>({})

  const [recipeId, setRecipeId] = useState('')

  const [printTemplateCategory, setPrintTemplateCategory] = useState<
    PrintTemplateCategory | undefined
  >()

  useEffect(() => {
    if (treatmentId && visible) {
      if (fyState) {
        dispatch(
          getRecipeSimpleList({
            treatmentId: treatmentId,
            state: 3,
            shipmentsState: shipmentsState,
          })
        )
          .then(unwrapResult)
          .then((data) => {
            setRecipes(data.recipes)
            setMaterials(data.materials)
            if (data.recipes.length > 0) {
              // setShow({ [data.recipes[0].id]: true })
            }
          })
          .catch(() => {
            // do nothing.
          })
      } else {
        dispatch(getRecipeList(treatmentId))
          .then(unwrapResult)
          .then((data) => {
            setRecipes(data.recipes)
            setMaterials(data.materials)
            if (data.recipes.length > 0) {
              // setShow({ [data.recipes[0].id]: true })
            }
          })
          .catch(() => {
            // do nothing.
          })
      }
    }
  }, [treatmentId, visible, fyState])

  return (
    <>
      <Modal
        title='处方打印'
        width={1200}
        footer={null}
        visible={visible}
        onCancel={(e) => {
          setRecipes([])
          setMaterials({})
          setShow({})
          onCancel && onCancel(e)
        }}
      >
        <Col>
          {recpies.map((r, i) => {
            const isHidden = !show[r.id]
            const ms = materials[r.id]
            return (
              <>
                <Row
                  key={r.id}
                  style={{
                    height: '3rem',
                    borderRadius: 2,
                    border: `1px solid ${theme.wbd}`,
                    paddingLeft: 20,
                    marginTop: i === 0 ? 0 : 10,
                  }}
                  align='middle'
                >
                  <Col
                    style={{
                      fontSize: '1.25rem',
                      lineHeight: '1.25rem',
                      color: theme.tc1,
                      fontWeight: 'bold',
                    }}
                  >
                    {getRecipeCategoryName(r.category)}
                  </Col>
                  <Col
                    style={{
                      fontSize: '1.125rem',
                      lineHeight: '1.125rem',
                      marginLeft: 20,
                      color: theme.tc1,
                    }}
                  >
                    编号：{r.id}
                  </Col>
                  <Col flex='1' />
                  {r.category === RecipeCategory.Tcm ||
                  r.category === RecipeCategory.TcmGranules ? (
                    <Button
                      type='primary'
                      onClick={() => {
                        setPrintTemplateCategory(
                          r.category === RecipeCategory.Tcm
                            ? PrintTemplateCategory.Zcy
                            : PrintTemplateCategory.Klj
                        )
                        setRecipeId(r.id)
                      }}
                    >
                      打印处方
                    </Button>
                  ) : (
                    <Space>
                      <Button
                        type='primary'
                        onClick={() => {
                          setPrintTemplateCategory(PrintTemplateCategory.Xbcfd)
                          setRecipeId(r.id)
                        }}
                      >
                        新版处方单(适用打印偏好设置)
                      </Button>
                      <Button
                        type='primary'
                        onClick={() => {
                          setPrintTemplateCategory(PrintTemplateCategory.Xyy)
                          setRecipeId(r.id)
                        }}
                      >
                        打印有诊疗
                      </Button>
                      <Button
                        style={{ color: theme.c1 }}
                        onClick={() => {
                          setPrintTemplateCategory(PrintTemplateCategory.Xyw)
                          setRecipeId(r.id)
                        }}
                      >
                        打印无诊疗
                      </Button>
                    </Space>
                  )}
                  <Col
                    style={{ cursor: 'pointer' }}
                    onMouseDown={(e) => {
                      e.preventDefault()
                    }}
                    onClick={() => {
                      setShow({ ...show, [r.id]: !show[r.id] })
                    }}
                  >
                    {isHidden ? (
                      <CaretLeftFilled
                        style={{ width: '3rem', lineHeight: '3rem' }}
                      />
                    ) : (
                      <CaretDownFilled
                        style={{ width: '3rem', lineHeight: '3rem' }}
                      />
                    )}
                  </Col>
                </Row>
                {ms && !isHidden && (
                  <EditableList
                    bordered
                    key={`m${r.id}`}
                    dataSource={ms}
                    columns={
                      r.category === RecipeCategory.Tcm ||
                      r.category === RecipeCategory.TcmGranules
                        ? TcmColumns(theme)
                        : MedicineMaterialColumns(theme, ms)
                    }
                  />
                )}
              </>
            )
          })}
        </Col>
      </Modal>
      <PrintTemplateModal
        patientId={patientId}
        templateCategory={printTemplateCategory}
        recipeId={recipeId}
        treatmentId={treatmentId}
        visible={!!recipeId}
        onCancel={() => setRecipeId('')}
      />
    </>
  )
}
