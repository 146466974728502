/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-15 10:06:23
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-08 15:56:36
 */
import { EditOutlined } from '@ant-design/icons'
import { TableColumnType } from 'antd'
import _ from 'lodash'
import React from 'react'
import { EditsOutlined } from '../../compnents/icons/Icons'
import { EditableColumnType } from '../../compnents/list/EditableList'
import { BillingCategoryName } from '../../models/billingCategory'
import { Material } from '../../models/material'

export const Columns = (
  materials: Material[],
  onClick: (id: number, source: number) => void,
  onSortClick?: (text: any, v: any, id?: any) => void
): EditableColumnType<any>[] => {
  const groups = _.chain(materials)
    .groupBy((m) => m.groupNumber)
    .mapValues((ms) => ms.map((m) => m.id))
    .value()
  return [
    {
      title: '组号',
      width: '3rem',
      dataIndex: 'groupNumber',
      align: 'center',
      render: (text, m) => {
        const obj = {
          children: text,
          props: {
            rowSpan: 1,
          },
        }
        const members = groups[m.groupNumber]
        if (members && members.indexOf(m.id) === 0) {
          obj.props.rowSpan = members.length
        } else if (members) {
          obj.props.rowSpan = 0
        }
        return obj
      },
    },
    {
      title: '开票项目',
      align: 'center',
      dataIndex: 'billingCategory',
      render: (_, t) => {
        return  BillingCategoryName(t.billingCategory)
      },
    },
    {
      title: '物资名称',
      align: 'left',
      dataIndex: '',
      render: function Element(_, t) {
        return (
          <>
            <div style={{ height: '55px', textAlign: 'left' }}>
              <p
                style={{
                  height: '28px',
                  lineHeight: '36px',
                  padding: '0',
                  margin: '0',
                  fontSize: '16px',
                  fontWeight: 'bolder',
                }}
              >
                {t.name}
              </p>
              <p
                style={{
                  height: '28px',
                  lineHeight: '24px',
                  padding: '0',
                  margin: '0',
                  color: '#999999',
                  fontSize: '14px',
                }}
              >
                {t.packSpec || '暂无'}/{t.tenantSupplierName}
              </p>
            </div>
          </>
        )
      },
    },
    {
      title: '用法',
      align: 'center',
      dataIndex: 'normalUsemethod',
    },
    {
      title: '频次',
      align: 'center',
      dataIndex: 'normalFrequency',
    },
    {
      title: '数量',
      align: 'center',
      dataIndex: 'count',
    },
    {
      title: '单价',
      align: 'right',
      dataIndex: 'retailPrice',
      render: (_, t) => {
        return t.retailPrice ? parseFloat(t.retailPrice).toFixed(5) : ''
      },
    },
    {
      title: '排序',
      align: 'center',
      dataIndex: 'sort',
      width: '6rem',
      editable: true,
      render: (_, t) => {
        return t.sort > 0 ? t.sort : ''
      },
      onSave: (prev, next) => {
        onSortClick && onSortClick('edit', { prev, next })
      },
    },
    {
      title: '操作',
      align: 'center',
      dataIndex: '',
      render: function Element(_, t) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EditsOutlined
              style={{
                fill: '#027AFF',
                width: '16px',
                height: '16px',
                marginRight: '9px',
              }}
            />
            <a
              onClick={() => {
                onClick(t.id, t.source)
              }}
            >
              修改
            </a>
          </div>
        )
      },
    },
  ]
}
