//变动流水打印单
export function changeWaterPrintBody(data: any) {
  const tenantName = data?.tenantName
  const totalPageList = data?.totalPageList
  const summaryData = data?.summaryData
  const datas = data?.dataList?.map((v: any) => {
    return {
      ...v,
      customizePay: v?.customizePay
        ?.map((k: any) => {
          return { ...k }
        })
        ?.sort((a: any, b: any) =>
          a.payMethodName.localeCompare(b.payMethodName)
        ),
    }
  })

  const titleList = data?.titleList?.sort((a: any, b: any) =>
    a.payMethodName.localeCompare(b.payMethodName)
  )

  let sjDatas = ''

  for (let i = 0; i < datas?.length; i++) {
    sjDatas +=
      ' <div style="font: 8px \'宋体\';display:flex;clear: left;border-left: 1px solid #000;border-bottom: 1px solid #000">' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      (Number(i) + 1) +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].outpatientNo +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].patientName +
      '            </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].registrationCategory +
      '           </div>' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '                ' +
      datas[i].orderSource +
      '            </div>' 
      const payMethods = datas[i]?.customizePay
      if (payMethods?.length > 0) {
        for (let i = 0; i < payMethods.length; i++) {
          sjDatas +=
            '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
            '                ' +
            Number(payMethods?.[i].payMethodAmount)?.toFixed(2) +
            '            </div>' 
        }
      }
      sjDatas +=
        '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                ' +
        datas[i].totalPayAmount +
        '            </div>' +
        // '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        // '                ' +
        // datas[i].receivePayAmount +
        // '            </div>' +
        '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                ' +
        datas[i].shouldPayAmount +
        '            </div>' +
        '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:12%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                ' +
        datas[i].realPayAmount +
        '            </div>' +
        '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                ' +
        datas[i].operateUserName +
        '            </div>' +
        '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:14%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        '                ' +
        datas[i].createTime +
        '            </div>' +
        '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:12%;text-align:center;box-sizing: border-box;border-right: 1px solid #000;">' +
        '                ' +
        datas[i]?.paymentId +
        '            </div>' +
        '    </div>'
  }
  let content =
    '<div style="margin-left: 10px;margin-right: 10px">' +
    '    <h5 style="text-align: center;margin-bottom: 5px;">' +
    tenantName +
    '</h5>' +
    '    <h6 style="text-align: center;margin-top: 5px;margin-bottom: 5px;">收&nbsp;&nbsp;费&nbsp;&nbsp;流&nbsp;&nbsp;水</h6>' +
    '   <div style="font-size:8px;display:flex;clear: left;border: 1px solid #000;border-right:none">' +
    '       <div style="word-break:break-all;width:6%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '          序号' +
    '       </div>' +
    '       <div style="word-break:break-all;width:8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           就诊序号' +
    '       </div>' +
    '       <div style="word-break:break-all;width:8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            姓名' +
    '       </div>' +
    '       <div style="word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            险种代码' +
    '        </div>' +
    '        <div style="word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '           就诊类别' +
    '        </div>' 
    let plusContent: any = ''
    titleList?.map((k: any, index: any) => {
      plusContent +=
        '        <div style="word-break:break-all;width:8%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
        k?.payMethodName +
        '        </div>'
    })
    content +=
      plusContent +
      '        <div style="word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '            总金额' +
      '        </div>' +
      // '        <div style="word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      // '            现金金额' +
      // '        </div>' +
      '        <div style="word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '            应收现金' +
      '        </div>' +
      '        <div style="word-break:break-all;width: 12%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '            实收金额' +
      '        </div>' +
      '        <div style="word-break:break-all;width: 10.15%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '            发生人员' +
      '        </div>' +
      '        <div style="word-break:break-all;width: 13.2%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
      '            操作时间' +
      '        </div>' +
      '        <div style="word-break:break-all;width: 12%;text-align:center;box-sizing: border-box;border-right: 1px solid #000"">' +
      '            结算编号' +
      '        </div>' +
      '    </div>' +
      sjDatas +
      '   <div style="font-size:8px;display:flex;clear: left;border: 1px solid #000;border-top:none">' +
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width: 46%;text-align:right;box-sizing: border-box;border-right: 1px solid #000">' +
      '                合计：' +
      '            </div>'
  let totalPageContent: any = ''
  totalPageList?.map((v: any, index: any) => {
    totalPageContent +=
      '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:8%;text-align:center;box-sizing: border-box;;border-right: 1px solid #000">' +
      '                ' +
      Number(v?.totalValue)?.toFixed(2) +
      '            </div>'
  })
  content +=
    totalPageContent +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    Number(summaryData?.totalPayAmountSUM)?.toFixed(2) +
    '            </div>' +
    // '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    // '                ' +
    // Number(summaryData?.receivePayAmountSUM)?.toFixed(2) +
    // '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    Number(summaryData?.shouldPayAmountSUM)?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:12%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '                ' +
    Number(summaryData?.realPayAmountSUM)?.toFixed(2) +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:10%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:14%;text-align:center;box-sizing: border-box;border-right: 1px solid #000">' +
    '            </div>' +
    '            <div style="padding-left:2px;padding-right:2px;word-break:break-all;width:12%;text-align:center;box-sizing: border-box;">' +
    '            </div>'
  '        </div>' + '    <div>' + '    </div>' + '</div>'

  return content
}
