import {
  CaretDownFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
  Upload,
} from "antd";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch } from "../../app/store";
import {
  DateRangeFieldName,
  DateRangeType,
  DateSelect,
} from "../../compnents/form/DateSelect";
import { EditableList } from "../../compnents/list/EditableList";
import { NavBar } from "../../compnents/nav/NavBar";
import {
  getTenantCategoryName,
  Tenant,
  TenantCategories,
  TenantCategory,
} from "../../models/tenant";
import { TenantQueryParams } from "../../services/tenant";
import { ThemeContext } from "../../theme/ThemeContext";
import { columns } from "./columns";
import { ReplaceMakeModal } from './replaceMakeModal'
import { SettingsModal } from './SettingsModal'
import { TenantModal } from './TenantModal'
import styles from './Tenants.module.css'
import {
  getAllList,
  getExportExcelAsync,
  getImportExcelAsync,
  getTenantList,
  selectTenantPage,
  toggleTenantState,
} from './tenantSlice'

export const Tenants = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const page = useSelector(selectTenantPage)

  const [params, setParams] = useState<TenantQueryParams>({
    current: 1,
  })

  const [tenantId, setTenantId] = useState('')

  const [replaceMakeId, setReplaceMakeId] = useState('')

  const [replaceMakeStatus, setReplaceMakeStatus] = useState(0)

  const [tenant, setTenant] = useState<Tenant | undefined>()

  const [allList, setAllList] = useState<any[]>()

  const [isTenantModalVisible, setIsTenantModalVisible] = useState(false)
  // 代煎设置
  const [isReplaceMakeModalVisible, setIsReplaceMakeModalVisible] = useState(
    false
  )

  const replaceMakeList = [
    {
      value: 0,
      label: '全部',
    },
    {
      value: 1,
      label: '未开通',
    },
    {
      value: 2,
      label: '已开通',
    },
  ]

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
  }

  const outClued = () => {
    dispatch(
      getExportExcelAsync({
        ...params,
        area: undefined,
        provinceCode: params?.area?.slice(0, 1)[0],
        cityCode: params?.area?.slice(1, 2)[0],
        countyCode: params?.area?.slice(2, 3)[0],
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '省市区导出数据.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        return
      })
  }

  useEffect(() => {
    dispatch(
      getTenantList({
        ...params,
        area: undefined,
        provinceCode: params?.area?.slice(0, 1)[0],
        cityCode: params?.area?.slice(1, 2)[0],
        countyCode: params?.area?.slice(2, 3)[0],

        // area: params.current ? undefined : undefined,
        // provinceCode: params.current
        //   ? params?.area?.slice(0, 1)[0]
        //   : params?.area?.slice(0, 1)[0],
        // cityCode: params.current
        //   ? params?.area?.slice(1, 2)[0]
        //   : params?.area?.slice(1, 2)[0],
        // countyCode: params.current
        //   ? params?.area?.slice(2, 3)[0]
        //   : params?.area?.slice(2, 3)[0],
      })
    )
  }, [params])

  useEffect(() => {
    dispatch(getAllList())
      .then(unwrapResult)
      .then((t: any) => {
        setAllList(t)
      })
  }, [])

  return (
    <Col
      style={{
        height: 'calc(100% - 20px) ',
        overflow: 'hidden',
        margin: '10px 30px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <NavBar
        where={['机构管理', '机构列表']}
        buttons={
          <Space>
            <Upload
              customRequest={async ({ file, onSuccess }) => {
                if (file instanceof File) {
                  await dispatch(getImportExcelAsync({ file })).then(() => {
                    notification.success({
                      message: '导入成功',
                      duration: 3,
                    })
                    dispatch(
                      getTenantList({
                        ...params,
                        area: undefined,
                        provinceCode: params?.area?.slice(0, 1)[0],
                        cityCode: params?.area?.slice(1, 2)[0],
                        countyCode: params?.area?.slice(2, 3)[0],
                      })
                    )
                  })
                  onSuccess &&
                    onSuccess(null, (null as unknown) as XMLHttpRequest)
                }
              }}
              showUploadList={false}
            >
              <Button type='primary' className={styles.button}>
                导入
              </Button>
            </Upload>
            <Button type='primary' onClick={outClued}>
              导出
            </Button>

            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => setIsTenantModalVisible(true)}
            >
              机构注册
            </Button>
          </Space>
        }
      />
      <Col
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: theme.pn,
          marginTop: 10,
          borderRadius: 10,
          padding: '10px 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          initialValues={{
            tenantCategory: TenantCategory.All,
            replaceMakeStatus: 0,
          }}
          onFinish={(values) =>
            setParams({
              ...params,
              ...values,
              [DateRangeFieldName]: undefined,
              tenantCategory:
                values.tenantCategory === TenantCategory.All
                  ? undefined
                  : values.tenantCategory,
            })
          }
        >
          <Row
            justify='space-between'
            align='middle'
            style={{ marginTop: 10, marginBottom: 20, width: '100%' }}
          >
            <Space>
              <Form.Item name='name' noStyle>
                <Input
                  allowClear
                  placeholder='请输入机构名称'
                  prefix={<SearchOutlined />}
                  style={{ width: '315px' }}
                  onChange={form.submit}
                />
              </Form.Item>
              <Form.Item name='tenantCategory' noStyle>
                <Select
                  // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                  placeholder='请选择机构类别'
                  onChange={form.submit}
                  style={{ width: '200px' }}
                >
                  {TenantCategories.map((c) => (
                    <Select.Option key={c} value={c}>
                      机构类别-{getTenantCategoryName(c)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name='area' noStyle>
                <Cascaded
                  loadData={loadData}
                  changeOnSelect
                  // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                  style={{ width: '240px' }}
                  options={allList}
                  onChange={form.submit}
                  placeholder='请选择省市区'
                />
              </Form.Item>

              <DateSelect
                options={[
                  DateRangeType.Today,
                  DateRangeType.Yesterday,
                  DateRangeType.ThisWeek,
                  DateRangeType.ThisMonth,
                  DateRangeType.NextMonth,
                  DateRangeType.ThisYear,
                  DateRangeType.Range,
                ]}
                style={{ width: '200px' }}
                labelPrefix=''
                placeholder='机构终止日期'
                namePrefix='endTime'
                onChange={form.submit}
              />

              <Form.Item name='replaceMakeStatus' noStyle>
                <Select
                  // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
                  placeholder='请选择代煎状态'
                  onChange={form.submit}
                  style={{ width: '200px' }}
                >
                  {replaceMakeList.map((c) => (
                    <Select.Option key={c.value} value={c.value}>
                      代煎状态-{c.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Space>
            <Button type='primary' onClick={form.submit}>
              查询
            </Button>
          </Row>
        </Form>
        <EditableList
          page={page}
          style={{ color: theme.tc3 }}
          className={styles.table}
          columns={columns(
            (action, t) => {
              switch (action) {
                case 'settings':
                  setTenant(t)
                  break
                case 'toggle':
                  dispatch(toggleTenantState(t.id)).then(() => {
                    dispatch(
                      getTenantList({
                        ...params,
                        area: undefined,
                        provinceCode: params?.area?.slice(0, 1)[0],
                        cityCode: params?.area?.slice(1, 2)[0],
                        countyCode: params?.area?.slice(2, 3)[0],
                      })
                    )
                  })
                  break
                case 'detail':
                  setTenantId(t.id)
                  setIsTenantModalVisible(true)
                  break
                case 'openReplaceMake':
                  setReplaceMakeId(t.id)
                  setReplaceMakeStatus(t.status)
                  setIsReplaceMakeModalVisible(true)

                  break
              }
            },
            page.size * (page.current - 1),
            theme
          )}
          onChangePage={(current: number, size?: number) =>
            dispatch(
              getTenantList({
                ...params,
                area: undefined,
                provinceCode: params?.area?.slice(0, 1)[0],
                cityCode: params?.area?.slice(1, 2)[0],
                countyCode: params?.area?.slice(2, 3)[0],
                current,
                size,
              })
            )
          }
          onRow={(t) => ({
            onDoubleClick: () => {
              setTenantId(t.id)
              setIsTenantModalVisible(true)
            },
          })}
        />
      </Col>
      <TenantModal
        tenantId={tenantId}
        visible={isTenantModalVisible}
        onCancel={() => {
          setTenantId('')
          setIsTenantModalVisible(false)
        }}
        onOk={() => {
          setTenantId('')
          setIsTenantModalVisible(false)
          getTenantList({
            ...params,
            area: undefined,
            provinceCode: params?.area?.slice(0, 1)[0],
            cityCode: params?.area?.slice(1, 2)[0],
            countyCode: params?.area?.slice(2, 3)[0],
          })
        }}
      />
      <SettingsModal
        visible={!!tenant}
        onCancel={() => {
          setTenant(undefined)
        }}
        tenantId={tenant?.id}
        tenantName={tenant?.name}
      />
      {/* 代煎设置弹框 */}
      <ReplaceMakeModal
        replaceMakeId={replaceMakeId}
        replaceMakeStatus={replaceMakeStatus}
        visible={isReplaceMakeModalVisible}
        onOk={() => {
          setIsReplaceMakeModalVisible(false)
          setReplaceMakeStatus(0)
        }}
        onCancel={() => {
          setIsReplaceMakeModalVisible(false)
          setReplaceMakeStatus(0)
        }}
      />
    </Col>
  )
}
