/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-15 10:06:02
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-20 16:37:12
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../app/store"
import { pageOf } from "../../models/page";
import { permissionsEdit, permissionsPage, permissionsParams } from "../../services/permissions";

const initialState = {
    permissionsfromData: [],
    permissionsfromTotal: 0,
    permissionsfromCurrent: 1,
}

export const permissionsfromSlice = createSlice({
    name: "permissionfrom",
    initialState,
    reducers: {
        setPermissionsData(state, action) {
            state.permissionsfromData = action.payload
        },
        setPermissionsTotal(state, action) {
            state.permissionsfromTotal = action.payload
        },
        setPermissionsCurrent(state, action) {
            state.permissionsfromTotal = action.payload
        }
    }
})

// export const pagePermissionsAsync = createAsyncThunk<
//     void,
//     permissionsParams,
//     RootThunkApi<void>
// >("Permissions/getPage", async (params, api) => {
//     return sendAsync(permissionsPage(params), api, true).then((data: any) => {
//         pageOf(data,_.chain(data,records).map)
//     })
// })

export function pagePermissions(
    params: permissionsParams
): RootThunk {
    return api(permissionsPage(params), (data: any, dispatch) => {

        dispatch(setPermissionsData(data))
        dispatch(setPermissionsTotal(data.length))
    })
}

export function editPermissions(
  storehouseId: number,
  ids: Array<any>
): RootThunk {
  return api(permissionsEdit(storehouseId, ids), (data, dispatch) => {
    // dispatch(pagePermissions({}))
  })
}

export const { setPermissionsData, setPermissionsCurrent, setPermissionsTotal } = permissionsfromSlice.actions

export const selectpermissionsfromData = (state: RootState) => state.permissions.permissionsfromData
export const selectpermissionsfromTotal = (state: RootState) => state.permissions.permissionsfromTotal

export default permissionsfromSlice.reducer