/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-12 10:32:09
 * @LastEditors: linxi
 * @LastEditTime: 2024-08-06 16:24:57
 */
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Col, Input, Modal, Row, Space, Switch, Table, TableColumnType } from 'antd';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch } from '../../../../app/store';
import { EditableList } from '../../../../compnents/list/EditableList';
import { BillingCategoryName } from '../../../../models/billingCategory';
import { ThemeContext } from '../../../../theme/ThemeContext'
import { batchAddPlanAsync, searchCategory } from '../../../commodity/commoditySlice';
import { batchCailiaoAsync } from '../../../completion/completionSlice';
import { pageExamineAsync } from '../../../examinetreat/examinetreatSlice';
import { saveRecipeMaterials, selectBatchAddVisibel, setBatchAddVisibel } from '../recipeEditorSlice';
import { selectPatientInfo } from '../recipeListEditorSlice';
import styles from "./RecipeItemModal.module.css";


interface BatchAddModalProps {
  insuranceCode?: string
  recipeId?: string
  treatmentId?: string
  onOk?: (v?: any) => void;
}

export const BatchAddModal = ({
  onOk,
  recipeId = '',
  treatmentId = '',
  insuranceCode
}: BatchAddModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const batchAddVisibel = useSelector(selectBatchAddVisibel)

  const [activeBgc, setActiveBgc] = useState(-1)

  const [query, setQuery] = useState('')

  const patientInfo = useSelector(selectPatientInfo)

  const [selectedKeys, setSelectedKeys] = useState<number[]>([])

  const [saveData, setSaveData] = useState<any>([])

  const [isSplitModalVisible, setIsSplitModalVisible] = useState(false)

  const [categoryList, setCategoryList] = useState<{ name: string, id: number }[]>([])

  const [ignoreStorage, setIgnoreStorage] = useState(false)

  const [merge, setMerge] = useState(false)

  const [material, setMaterial] = useState<any>()

  const [loading, setLoading] = useState(false)

  const [params, setParams] = useState({
    size: 10,
    current: 1
  })

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  useEffect(() => {
    setParams({
      size: 10,
      current: 1
    })
    setQuery('')
  }, [activeBgc])


  useEffect(() => {
    if (!batchAddVisibel) return
    const currentData = page?.items?.filter((v: any) => {
      return selectedKeys.includes(v.id)
    })
    const filterData = selectedKeys.map((v: number) => { return [...saveData, ...currentData].find(el => el.id == v) })
    setSaveData(filterData)
  }, [selectedKeys])


  useEffect(() => {
    if (!batchAddVisibel) return
    if (activeBgc != -2) {
      let treatmentCategory;
      if (activeBgc != -1) {
        treatmentCategory = categoryList.find(v => v.id == activeBgc)?.name
      }
      const data = {
        billingCategory: '-1',
        state: 1,
        size: params.size,
        current: params.current,
        name: query,
        insuranceCode,
        treatmentCategory,
        searchType:1
      }
      dispatch(pageExamineAsync(data)).then(unwrapResult).then((res: any) => {
        const data = {
          total: res.total,
          items: res.records.map((v: any) => ({
            ...v,
            treatmentServiceId: v.id,
            splitTag: 0
          })),
        }
        setPage(data)
      })
    } else {
      if (ignoreStorage) {
        const data = {
          allStr: query,
          materialsCategory: -1,
          allMaterials: -1,
          tenantSupplierId: -1,
          state: 1,
          otc: -1,
          current: params.current,
          size: params.size,
          flag: 5,
          insuranceCode,
          // 审批标志,写死查询审批已通过
          approveFlg: insuranceCode && insuranceCode != 'ZIFEI' ? null : 1,
        }
        dispatch(batchAddPlanAsync(data)).then((res: any) => {
          const data = {
            total: res.payload.total,
            items: res.payload.records.map((v: any) => ({
              ...v,
              materialsCatalogId: v.id,
            })),
          }
          setPage(data)
        })
      } else {
        const data = {
          keyword: query,
          current: params.current,
          size: params.size,
          merge,
          insuranceCode: insuranceCode,
        }
        dispatch(batchCailiaoAsync(data)).then(unwrapResult).then((res: any) => {
          const data = {
            total: res.total,
            items: res.records.map((v: any) => ({
              ...v,
              inventoryLedgerId: merge ? undefined : v.id,
              materialsCatalogId: merge ? v.id : undefined,
            })),
          }
          setPage(data)

        })
      }

    }

  }, [query, params, batchAddVisibel, merge, ignoreStorage])

  useEffect(() => {
    if (batchAddVisibel) {
      dispatch(searchCategory()).then(unwrapResult).then(res => {
        setCategoryList([{ name: '诊疗项目', id: -1 }, { name: '材料', id: -2 }, ...res])
      })
    }
  }, [batchAddVisibel])

  const sunmit = () => {
    setLoading(true)
    const List = saveData.map((v: any) => ({
      ...v,
      id: '',
      drugCount: 1,
      groupNumber: 1,
      sort:
        saveData.length === 0
          ? 1
          : saveData
            .map((m: any[]) => m.sort)
            .reduce((p: number, c: number) => Math.max(p, c)) + 1,
    }))
    const params = {
      materials: List,
      treatmentId,
      recipeId,
      validateInventory: ignoreStorage
    }
    dispatch(saveRecipeMaterials(params)).then(unwrapResult).then(() => {
      onOk && onOk()
      close()
    }).finally(() => {
      setLoading(false)
    })
  }

  // 检查是否需要拆零
  const findSplitTag = (id: number) => {
    const item = page.items.find((v: any) => v.id == id)
    if (item && item.splitTag == 1) {
      setMaterial(item)
      setIsSplitModalVisible(true)
    }
  }

  const changeSplitTag = (id: number, type: boolean) => {
    const tag = saveData.map((v: any) => {
      if (v.id == id) {
        if (type) {
          return {
            ...v,
            splitTag: v.splitTag == 1 ? 1 : 0
          }
        } else {
          return {
            ...v,
            splitTag: 0
          }
        }
      } else {
        return {
          ...v,
          splitTag: 0
        }
      }
    })
    setSaveData(tag)
  }

  // useEffect(() => {
  //   if (batchAddVisibel) {
  //     getPage()
  //   }
  // }, [params, batchAddVisibel])

  const Columns: TableColumnType<any>[] = [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '4rem',
      render: (_1, _2, i) => `${params.size * (params.current - 1) + i + 1}`,
    },

    {
      title: '名称',
      dataIndex: 'name',
      align: 'center',
      width: '10rem',
      ellipsis: true
    },
    {
      title: '类型',
      dataIndex: 'billingCategory',
      align: 'center',
      render: (_, m) => BillingCategoryName(m.billingCategory),
    },
    {
      title: '规格',
      dataIndex: 'spec',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, m) => m.spec || '-',
    },
    {
      title: '单位',
      dataIndex: 'accountUnit',
      align: 'center',
      render: (_, m) => (m.splitTag ? m.splitUnit : m.accountUnit),
    },
    {
      title: '单价',
      dataIndex: 'retailPrice',
      align: 'center',
      render: (_, m) => m.retailPrice?.toFixed(5),
    },
    {
      title: '库存',
      dataIndex: 'count',
      align: 'center',
      render: (_, m) => m.count || '-',
    },
    {
      title: '厂家',
      dataIndex: 'tenantSupplierName',
      align: 'center',
      ellipsis: true,
      render: (_, m) => m.tenantSupplierName || '-',
    },
  ]

  const close = () => {
    setActiveBgc(-1)
    setQuery('')
    setSaveData([])
    setSelectedKeys([])
    dispatch(setBatchAddVisibel(false))
  }
  return (
    <Modal
      title={`${patientInfo?.patientName} 批量添加处置项目`}
      width={1200}
      visible={batchAddVisibel}
      onCancel={() => {
        close()
      }}
      onOk={() => {
        onOk && onOk('');
      }}
      footer={null}
      className={styles.batchModal}
      destroyOnClose
    >
      <Row gutter={12}>
        <Col span={6}>
          <EditableList
            className={styles.storetable}
            bordered
            dataSource={categoryList}
            pagination={false}
            rowKey={(record: any, i: any) => `${i}`}
            columns={
              [{
                title: "项目类别",
                dataIndex: "name",
                align: "center",
              }]
            }
            sticky
            onRow={(d: any) => {
              return {
                onClick: () => {
                  setActiveBgc(d.id)
                }
              }
            }}
            rowClassName={(v) => {
              return v.id == activeBgc ? 'activeBgc' : ''
            }}
          />
        </Col>
        <Col span={18}>
          <div className={styles.query}>
            <div>
            <Input placeholder='请输入项目名称/助记码' value={query} onChange={(e) => {
              setQuery(e.target.value)
              }} style={{ width: '250px', marginRight: 10 }} />
              <Button type='primary'>查询</Button>
            </div>
            {
              activeBgc == -2 && (

                <div className={styles.queryItem}>
                  是否指定批次 <Switch checked={merge} onChange={(v) => {
                    if (v && ignoreStorage) {
                      setIgnoreStorage(!v)
                    }
                    setMerge(v)
                  }} />
                  &emsp;&emsp;
                  是否忽略库存 <Switch checked={ignoreStorage} onChange={(v) => {
                    setIgnoreStorage(v)
                    if (v && merge) {
                      setMerge(!v)
                    }
                    setIgnoreStorage(v)
                  }} />
                </div>
              )
            }
          </div>


          <EditableList
            className={styles.listTable}
            page={{
              current: params.current,
              size: params.size,
              total: page.total,
              items: page.items,
            }}
            columns={Columns}
            onChangePage={(current, size) =>
              setParams({
                ...params,
                current,
                size: size || 10,
              })
            }
            rowKey={(m) => m.id}
            onRow={(record) => ({
              onClick: () => {
                let data = []
                if (!selectedKeys.includes(record.id)) {
                  findSplitTag(record.id)
                  data = [record.id, ...selectedKeys]
                } else {
                  data = selectedKeys.filter(v => v != record.id)
                }
                setSelectedKeys(data)
              }
            })}
            rowSelection={{
              selectedRowKeys: selectedKeys,
              onSelect: (record, selected, selectedRows) => {
                let data = []
                if (selected) {
                  findSplitTag(record.id)
                  data = [record.id, ...selectedKeys]
                } else {
                  data = selectedKeys.filter(v => v != record.id)
                }
                setSelectedKeys(data)
              },
              // onSelectAll: (selected, selectedRows, changeRows) => {
              //   const changeIds = changeRows.map(v => v.id)
              //   let data: number[] = []
              //   if (selected) {
              //     data = [...changeIds, ...selectedKeys]
              //   } else {
              //     selectedKeys.forEach((v: number) => {
              //       if (!changeIds.includes(v)) {
              //         data.push(v)
              //       }
              //     })
              //   }
              //   setSelectedKeys(data)
              // }
            }}
          />
        </Col>
      </Row>
      <Row justify='end'>
        <Space>
          <Button onClick={close}>返回</Button>
          <Button type='primary' loading={loading} disabled={selectedKeys.length == 0} onClick={sunmit}>添加{selectedKeys.length}个项目</Button>
        </Space>
      </Row>
      <Modal
        title='可选单位'
        className={styles.split}
        visible={isSplitModalVisible}
        onCancel={() => {
          setIsSplitModalVisible(false)
        }}
        footer={null}
      >
        <Space direction='vertical'>
          <Button
            type='primary'
            onClick={() => {
              setIsSplitModalVisible(false)
              changeSplitTag(material.id, true)
            }}
          >
            <span
              style={{
                backgroundColor: theme.pn,
                borderRadius: 999,
                color: theme.c1,
                lineHeight: '1rem',
                marginRight: 8,
                textAlign: 'center',
                width: '1rem',
              }}
            >
              1
            </span>
            {`${material?.splitPrice || ''}元/${material?.splitUnit || ''}`}
          </Button>
          <Button
            type='primary'
            onClick={() => {
              setIsSplitModalVisible(false)
              changeSplitTag(material.id, false)
            }}
          >
            <span
              style={{
                backgroundColor: theme.pn,
                borderRadius: 999,
                color: theme.c1,
                lineHeight: '1rem',
                marginRight: 8,
                textAlign: 'center',
                width: '1rem',
              }}
            >
              2
            </span>
            {`${material?.retailPrice || ''}元/${material?.accountUnit || ''}`}
          </Button>
        </Space>
      </Modal>
    </Modal>
  );
}

