import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Input, Modal, notification, Popconfirm, Row } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { deleteCategory } from '../commodity/commoditySlice'
import { data } from '../composing/modal'
import styles from './Examinetreat.module.css'
import {
  PlusOutlined,
  MinusOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons'

export const SetMenuModal = (props: {
  visible: boolean
  categoryList: any
  onOk: (data: any) => void
  onCancel: () => void
  onDelete: (id: number) => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()
  const [classificationList, setClassificationList] = useState<any>()

  const [popVisible, setPopVisible] = useState(false) // 删除确认框是否显示

  const text = '删除分类后，该分类下的项目将归纳到（全部）分类中，请确认！'

  useEffect(() => {
    if (!props.visible) return
    props.categoryList &&
      props.categoryList.push({
        name: '',
        id: '',
      })
    setClassificationList(
      props.categoryList.filter((item: any) => item.id != '1234567894562')
    )
  }, [props.visible])

  const moveItemUp = (index: number) => {
    const newClassificationList = [...classificationList];
    const temp = newClassificationList[index];
    newClassificationList[index] = newClassificationList[index - 1];
    newClassificationList[index - 1] = temp;
    setClassificationList(newClassificationList);
  };
  
  const moveItemDown = (index: number) => {
    const newClassificationList = [...classificationList];
    const temp = newClassificationList[index];
    newClassificationList[index] = newClassificationList[index + 1];
    newClassificationList[index + 1] = temp;
    setClassificationList(newClassificationList);
  };

  return (
    <div>
      <Modal
        width={640}
        wrapClassName={styles.modalWrap}
        title='项目分类设置'
        visible={props.visible}
        okText='保存'
        onOk={() => {
          if (classificationList.some((v: any) => v.name == '全部')) {
            notification.info({ message: '项目分类名称不能设置成全部' })
            return
          }
          const tempArr = classificationList.filter((item: any) => {
            return item.name != ''
          })
          props.onOk(tempArr)
        }}
        onCancel={() => props.onCancel()}
      >
        {classificationList &&
          classificationList.map((item: any, index: number) => {
            return (
              <Row key={index}>
                <Col
                  span={18}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <Input
                    value={item.name}
                    maxLength={6}
                    placeholder='请输入项目分类名称，最大支持6个字符'
                    onChange={(e) => {
                      const values = e.target.value
                      const data = classificationList.map(
                        (v: any, num: number) => {
                          if (v.id == item.id && num == index) {
                            return {
                              ...v,
                              name: values,
                            }
                          } else {
                            return v
                          }
                        }
                      )

                      if (data[data.length - 1].name != '') {
                        //判断是否新增一行
                        data.push({
                          name: '',
                          id: '',
                        })
                      }
                      setClassificationList(data)
                    }}
                  />
                  {/*  visible={popVisible} */}
                  <Popconfirm
                    placement='top'
                    title={text}
                    onConfirm={() => {
                      let data
                      if (item.id == '') {
                        data = classificationList.filter(
                          (v: any, num: number) => {
                            return num !== index
                          }
                        )
                        notification.success({ message: '删除成功' })
                        setClassificationList(data)
                      } else {
                        dispatch(deleteCategory({ id: item.id }))
                          .then(unwrapResult)
                          .then((res) => {
                            data = classificationList.filter(
                              (v: any, num: number) => {
                                return item.id !== v.id
                              }
                            )
                            notification.success({ message: '删除成功' })
                            setClassificationList(data)
                          })
                      }
                    }}
                    okText='确认'
                    cancelText='取消'
                  >
                    <div className={styles.iptClose}>
                      <MinusOutlined
                        style={{ marginLeft: '10px', color: '#101010' }}
                      />
                    </div>
                  </Popconfirm>
                </Col>
                {item.name && (
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '-10px',
                    }}
                  >
                    {index != 0 ? (
                      <Button
                        type='primary'
                        size='small'
                        style={{ width: '66px', marginLeft: '10px' }}
                        onClick={() => moveItemUp(index)}
                      >
                        <ArrowUpOutlined style={{ color: '#ffffff' }} />
                        上移
                      </Button>
                    ) : (
                      <div style={{ width: '66px', marginLeft: '10px' }}></div>
                    )}
                    {classificationList?.filter((v: any) => v?.name)?.length -
                      1 ==
                    index ? (
                      <div style={{ width: '66px', marginLeft: '10px' }}></div>
                    ) : (
                      <Button
                        type='primary'
                        size='small'
                        style={{ width: '66px', marginLeft: '10px' }}
                        onClick={() => moveItemDown(index)} 
                      >
                        <ArrowDownOutlined style={{ color: '#ffffff' }} />
                        下移
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            )
          })}
      </Modal>
    </div>
  )
}
