/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-02 17:50:47
 * @LastEditors: sj
 * @LastEditTime: 2022-05-16 19:46:29
 */
export interface VasContentValue {
  appKey: string
  nationUniqueCode: string
  relevanceTenantName: string
  sign: string
  timestamp: string
  url: string
}

export interface ShopperTokenValue {
  token: string
  type: string
}

export const getXzName = (XzName: string) => {
  switch (XzName) {
    case '310':
      return '职工医疗报销'
    case '390':
      return '居民医疗保险'
    case '340':
      return '离休人员医疗保险'
    case '510':
      return '职工生育报销'
    default:
      return '职工医疗报销'
  }
}

export const getQsName = (QsName: string) => {
  switch (QsName) {
    case '21':
      return '住院'
    case '11':
      return '门诊'
    case '41':
      return '药店购药'
    case '1401':
      return '门诊大病'
    case '1402':
      return '门诊特病'
    default:
      return QsName
  }
}
