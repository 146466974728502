/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-27 13:34:47
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-05 18:10:30
 */

import { Button, Col, Form, Row, Select } from 'antd'
import React, { useEffect } from 'react'
import styles from '../Putstorelist.module.css'
import { Category, CategoryName } from '../../../models/category'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../../models/datetime'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserName, sleectUserData } from '../putstorelistSlice'
import {
  DepartmentDate,
  savePutstorage,
  selectDepartData,
} from '../../putstorage/putstorageSlice'
import { Department } from '../../../models/department'
import { User } from '../../../models/user'
const { Option } = Select

export const AllocationQuery = (props: { category: Category; Detail: any }) => {
  const history = useHistory()

  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const departmentData = useSelector(selectDepartData)

  const userData = useSelector(sleectUserData)

  useEffect(() => {
    dispatch(DepartmentDate({ current: 1, size: 1000 }))
  }, [])

  return (
    <>
      <Row
        className={styles.titletext}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Col
          span={24}
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            paddingLeft: '10px',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex' }}>
            <span
              style={{
                width: '2px',
                height: '16px',
                background: ' #0052D9',
                position: 'relative',
                top: '6px',
                marginRight: '10px',
              }}
            ></span>
            {CategoryName(props.category)}业务单据
          </div>
          {props.category === '3' ? (
            <Button
              type='primary'
              style={{ float: 'right', position: 'relative', top: '-28px' }}
              disabled={props.Detail.storehouseTag !== 0 ? true : false}
              onClick={() => {
                switch (props.category) {
                  case '1':
                  case '2':
                    history.push({
                      pathname: '/updatabus',
                      state: { category: props.category },
                    })
                    break
                  case '3':
                    history.push({
                      pathname: '/transferOut',
                      state: { category: props.category, detail: props.Detail },
                    })
                    break
                  default:
                    break
                }
              }}
            >
              修改业务单据
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row
      className={styles.contentTitle}
        style={{
          marginTop: '10px',
          lineHeight: '40px',
          paddingLeft: '20px',
          marginBottom: '10px',
          fontSize: '18px',
        }}
      >
        {props.category === '3' ? (
          <></>
        ) : (
          <Col span={6}>入库单号：{props.Detail.putNumber}</Col>
        )}
        <Col span={6}>库房名称：{props.Detail.storehouseName}</Col>
        {props.category !== '3' ? (
          <Col span={6}>
            {props.Detail.supplierName ? '供应商' : '接收库房'}：
            {props.Detail.supplierName
              ? props.Detail.supplierName
              : props.Detail.acceptStorehouseName}
          </Col>
        ) : (
          <></>
        )}
        <Col span={6}>业务部门：{props.Detail.departmentName}</Col>
        <Col span={6}>业务人员：{props.Detail.userName}</Col>

        {props.category === '3' ? (
          <></>
        ) : (
          <Col span={6}>出库单号：{props.Detail.acceptPutNumber}</Col>
        )}

        {props.category === '3' ? (
          <Col span={6}>
            业务日期：
            {moment(props.Detail.recepitsDate).format(DateTimeFormatSimple)}
          </Col>
        ) : (
          <></>
        )}
        {props.category === '3' ? (
          <></>
        ) : (
          <Col span={24} style={{ marginTop: '8px' }}>
            <Form form={form} name='control-hooks'>
              <Row>
                <Col span={5} className={styles.label}>
                  <Form.Item name='departmentId' label='操作科室'>
                    <Select
                      placeholder='操作科室'
                      allowClear
                      onSelect={(key: any) => {
                        form.setFieldsValue({
                          userId: undefined,
                        })
                        dispatch(getUserName(key))
                      }}
                    >
                      {departmentData.map((v: Department) => {
                        return (
                          <Option value={v.id} key={v.id}>
                            {v.name}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ marginLeft: '70px' }}>
                  <Form.Item name='userId' label='操作人员'>
                    <Select
                      showSearch
                      placeholder='操作人员'
                      allowClear
                      onSelect={() => {
                        dispatch(
                          savePutstorage({
                            ...form.getFieldsValue,
                            ...props.Detail,
                          })
                        )
                      }}
                    >
                      {userData.map((v: User) => {
                        return (
                          <Option value={v.id} key={v.id}>
                            {v.name}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        )}
        {/* 显示入库单号、来源库房、来源科室、来源操作人员、对应出库单号 */}
        <Col span={24}></Col>
      </Row>
    </>
  )
}
