/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-05-08 16:29:08
 * @LastEditors: linxi
 * @LastEditTime: 2024-03-26 14:46:31
 */
import {notification, Popover} from "antd";
import moment from 'moment'
import { duration } from "moment";
import React from "react";
import { EditableColumnType } from "../../compnents/list/EditableList";
import { Dimen } from "../../models/dimen";
import {InfoCircleTwoTone} from "@ant-design/icons";

export const InventoryCloumn = (
  storehouseTag: number,
  onclick: (text: string, t: any) => void,
  startIndex: number
): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      width: Dimen.Num,
      align: 'center',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '产品信息',
      dataIndex: 'productInfo',

      render: function Element(_, record) {
        return (
          <Popover
            content={
              <div style={{ maxWidth: '400px' }}>
                <p
                  style={{
                    height: '16px',
                    marginBottom: '10px',
                    fontSize: '16px',
                    fontWeight: 'bolder',
                  }}
                >
                  {record.name}
                </p>
                <p
                  style={{
                    color: '#999999',
                    fontSize: '14px',
                    maxWidth: '400px',
                  }}
                >
                  {record.tenantSupplierName} /{' '}
                  {moment(record.deadline).format('YYYY-MM-DD')}/ {record.spec}{' '}
                  / {record.packSpec} / {record.doseCategory}
                </p>
              </div>
            }
            placement='bottom'
          >
            <div style={{ height: '68px', maxWidth: '500px' }}>
              <p
                style={{
                  height: '16px',
                  marginBottom: '10px',
                  fontSize: '16px',
                  fontWeight: 'bolder',
                }}
              >
                {record.name}
              </p>
              <p
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  height: '24px',
                  color: '#999999',
                  fontSize: '14px',
                  maxWidth: '500px',
                }}
              >
                {record.tenantSupplierName} /{' '}
                {moment(record.deadline).format('YYYY-MM-DD')}/ {record.spec} /{' '}
                {record.packSpec} / {record.doseCategory}
              </p>
            </div>
          </Popover>
        )
      },
    },
    {
      title: '批次号',
      align: 'center',
      width: Dimen.BatchNo,
      dataIndex: 'batchNo',
    },
    {
      title: '柜号',
      align: 'center',
      width: '8rem',
      dataIndex: 'cabineNo',
    },
    {
      title: '拆零比例',
      align: 'center',
      width: Dimen.SplitScale,
      dataIndex: 'splitScale',
    },
    {
      title: '拆零数',
      align: 'center',
      width: Dimen.Count,
      dataIndex: 'splitCount',
      render: function Element(_, t) {
        return (
          <div style={{ color: '#999999' }}>
            {t.splitCount}
          </div>
        )
      },
    },
    {
      title: '实际拆零数',
      align: 'center',
      width: Dimen.Count,
      dataIndex: 'realSplitCount',
      nextDataIndex: 'realCount',
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,2})?$/),
          message: '请输入数字',
        },
      ],
      render: function Element(_, t) {
        let color = '#999999'
        if (t.tenantInventoryReceiptItemId > 0) {
          color = t.realSplitCount !== t.splitCount ? '#FA8714' : '#027AFF'
        }
        return (
          <div style={{ color: color}}>
            {t.realSplitCount ? t.realSplitCount : t.realSplitCount === 0 ? 0 : t.splitCount}
          </div>
        )
      },
      onSave: (oldrow, next) => {
        const prev = {
          ...oldrow,
          productInfo: `${oldrow.name} /${oldrow.tenantSupplierName} /${oldrow.spec} /${oldrow.doseCategory}`,
        }
        if (prev.splitTag === 1) {
          onclick('submit', { prev, next })
        } else {
          notification.error({
            message: '该药品不支持拆零',
            duration: 3,
          })
        }
      },
    },
    {
      title: '账簿数',
      align: 'center',
      width: Dimen.Count,
      dataIndex: 'count',
      render: function Element(_, t) {
        return (
          <div style={{ color: '#999999' }}>
            {t.count}
          </div>
        )
      },
    },
    {
      title: '实际数',
      align: 'center',
      width: Dimen.Count,
      dataIndex: 'realCount',
      editable: storehouseTag > 0 ? false : true,
      rules: [
        {
          // pattern: new RegExp(/^[0-9]\d*$/),
          pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,2})?$/),
          message: '请输入数字',
        },
      ],
      render: function Element(_, t) {

        let color = '#999999'
        if (t.tenantInventoryReceiptItemId > 0) {
          color = t.realCount !== t.count ? '#FA8714' : '#027AFF'
        }
        return (
          <div style={{ color: color }}>
            {t.realCount ? t.realCount : t.realCount === 0 ? 0 : t.count}
          </div>
        )
      },
      onSave: (oldrow, next) => {
        const prev = {
          ...oldrow,
          productInfo: `${oldrow.tenantSupplierName} /${oldrow.packSpec} /${oldrow.doseCategory}`,
        }
        if (next.realCount) {
          onclick('submit', { prev, next })
        }
      },
    },
    {
      title: '盘盈盘亏数',
      align: 'center',
      width: Dimen.Count,
      dataIndex: '',
      render: (_, t) => {
        return isNaN(t.realCount - t.count)
          ? ''
          : (t.realCount - t.count).toFixed(2)
      },
    },
    {
      title: '盘盈盘亏数(拆零)',
      align: 'center',
      width: Dimen.Count,
      dataIndex: '',
      render: (_, t) => {
        return isNaN(t.realSplitCount - t.splitCount)
          ? 0
          : (t.realSplitCount - t.splitCount).toFixed(2)
      },
    },
    {
      title: '变动金额(进价)',
      align: 'right',
      width: Dimen.Count,
      dataIndex: 'totalPrice',
      render: (_, t) => {
        return isNaN(
          (t.realSplitCount - t.splitCount) * t.splitPrice +
            (t.realCount - t.count) * t.unitPrice
        )
          ? ''
          : (
              (t.realSplitCount - t.splitCount) * t.splitPrice +
              (t.realCount - t.count) * t.unitPrice
            ).toFixed(2)
      },
    },
  ]
};
