/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-11-16 18:06:31
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-15 15:46:37
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, message, Modal, notification, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import {
  getReconciliationAsync,
  PaymentInformationQueryAsync,
  SettlementChargeReversalAsync,
  SettlementExpenseReportAsync,
} from './ReconciliationSlice'
import { Columns, SettlementInquiryColumns } from './columns'
import { RootDispatch } from '../../app/store'
import styles from './Reconciliation.module.css'
import { traceRoute } from '../../layouts/layoutSlice'
import { medicalSendHttp, strParse } from '../../utils/MedicalUtils'
import {
  selectInsuranceArray,
  selectUserName,
  selectUserId,
  selectTenantAddress,
  selectTenantAreaCd,
  selectTenantName,
  selectTenantCategory,
} from '../../app/applicationSlice'
import { selectInsuranceItem, setInsurance } from '../../layouts/insuranceSlice'
import { DateTimeFormatSimpleOnlyOne } from '../../models/datetime'
import { serverType } from '../../services/commodity'

export interface ReconciliationsRef {
  reload: () => void
}

interface ReconciliationsProps {
  selectedTab?: any
}

const RealReconciliations = (
  { selectedTab }: ReconciliationsProps,
  ref: React.Ref<ReconciliationsRef>
): ReactElement => {
  const theme = useContext(ThemeContext)

  const insuranceArray = useSelector(selectInsuranceArray)

  const userName = useSelector(selectUserName)

  const userId = useSelector(selectUserId)

  const tenantAddress = useSelector(selectTenantAddress)

  const tenantAreaCd = useSelector(selectTenantAreaCd)

  const tenantName = useSelector(selectTenantName)

  const baseData = useSelector(selectInsuranceItem)

  const cycleCode = sessionStorage.getItem('cycleCode')

  const tenantCategory = useSelector(selectTenantCategory)

  const [isSettlementInquiryVisible, setIsSettlementInquiryVisible] = useState(
    false
  )

  const [setlMon, setSetlMon] = useState<any>()

  const [insuranceItem, setInsuranceItem] = useState<any>()

  const [disabled, setDisabled] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const [params, setParams] = useState<any>({ current: 1, size: 10 })

  const [page, setPage] = useState<any>({
    items: [],
    total: 0,
  })
  const [pages, setPages] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false)

  const [pageModalLoading, setPageModalLoading] = useState(false)
  useEffect(() => {
    getPageList()
  }, [params])

  const getPageList = () => {
    setPageLoading(true)
    dispatch(
      getReconciliationAsync({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (insuranceArray.length !== 0) {
      setInsuranceItem(insuranceArray[0])
    }
  }, [insuranceArray])

  // 保存医保结构体
  useEffect(() => {
    if (insuranceItem && JSON.stringify(insuranceItem) !== '{}') {
      dispatch(
        setInsurance({
          ...insuranceItem,
          userName,
          userId,
          cycleCode,
          tenantCategory,
          tenantAreaCd,
          name: tenantName,
          address: tenantAddress,
          areaCode: tenantAreaCd,
          endTime: moment(insuranceItem.endTime).format(
            DateTimeFormatSimpleOnlyOne
          ),
        })
      )
    }
    return () => {
      dispatch(setInsurance({}))
    }
  }, [insuranceItem])

  useEffect(() => {
    if (isSettlementInquiryVisible) {
      setPageModalLoading(true)
      selfPaymentInformationQuery('requestString', setlMon)
    }
  }, [isSettlementInquiryVisible, setlMon])

  // 申报
  const selfSettlementExpenseReportData = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    setDisabled(true)
    // setPageLoading(true)
    dispatch(
      SettlementExpenseReportAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '申报',
            },
            (call: any, err) => {
              !err &&
                selfSettlementExpenseReportData(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          // setPageLoading(false)
          setDisabled(false)
          getPageList()
        }
      })
      .catch(() => {
        // setPageLoading(false)
        getPageList()
        setDisabled(false)
      })
  }

  // 申报撤销
  const selfSettlementChargeReversal = (
    serverType: serverType,
    accountDate: string,
    upldBtchNo: any,
    responseString?: string
  ) => {
    setDisabled(true)
    // setPageLoading(true)
    dispatch(
      SettlementChargeReversalAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
          upldBtchNo,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: '申报撤销',
            },
            (call: any, err) => {
              !err &&
                selfSettlementChargeReversal(
                  'response',
                  accountDate,
                  upldBtchNo,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setDisabled(false)
          // setPageLoading(false)
          getPageList()
        }
      })
      .catch(() => {
        setDisabled(false)
        // setPageLoading(false)
        getPageList()
      })
  }

  // 结付信息查询
  const selfPaymentInformationQuery = (
    serverType: serverType,
    accountDate: string,
    responseString?: string
  ) => {
    dispatch(
      PaymentInformationQueryAsync({
        serverType,
        body: {
          baseData: {
            ...baseData,
            responseString,
          },
          accountDate,
        },
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        if (serverType === 'requestString') {
          const data = strParse(res)
          medicalSendHttp(
            {
              trade_args: [
                data.infno,
                data.url,
                data,
                baseData?.countryHospitalNb,
                baseData?.secretToken,
              ],
              message: ' 结付信息',
            },
            (call: any, err) => {
              !err &&
                selfPaymentInformationQuery(
                  'response',
                  accountDate,
                  JSON.stringify(call.netmessage)
                )
            }
          )
        } else {
          setPages(res?.res)
          setPageModalLoading(false)
        }
      })
      .catch(() => {
        setPageModalLoading(false)
      })
  }

  return (
    <>
      <TabBar style={{ margin: '10px 20px' }}>
        <TabPane key='0' tab='医保对账'>
          <Col
            style={{
              backgroundColor: theme.pn,
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100% - 30px)',
              marginTop: '10px',
              overflow: 'hidden',
              padding: '10px 15px 0 15px',
            }}
          >
            <EditableList
              // style={{ overflow: 'hidden' }}
              style={{ flex: 1 }}
              scroll={{ x: '30rem' }}
              loading={pageLoading}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              rowKey={(m: any) => m.paymentId}
              columns={Columns(
                params.size * (params.current - 1),
                disabled,
                (action, t) => {
                  switch (action) {
                    // 对账
                    case 'reconciliationStatement':
                      dispatch(
                        traceRoute({
                          name: '对账明细',
                          path: '/reconciliationMessage',
                          query: `accountDate=${t.setlMon}`,
                        })
                      )
                      break
                    // 结付查询
                    case 'settlementInquiry':
                      setIsSettlementInquiryVisible(true)
                      setSetlMon(t?.setlMon)
                      break
                    // 申报
                    case 'settlementExpenseReport':
                      selfSettlementExpenseReportData(
                        'requestString',
                        t?.setlMon
                      )
                      break
                    // 申报撤销
                    case 'withdrawalOfdeclaration':
                      Modal.confirm({
                        centered: true,
                        title: '是否确认申报撤销？',
                        onOk: () => {
                          selfSettlementChargeReversal(
                            'requestString',
                            t?.setlMon,
                            t?.upldBtchNo
                          )
                        },
                      })
                      break
                  }
                }
              )}
              onChangePage={(current, size) =>
                setParams({ ...params, current, size: size || 10 })
              }
              sticky
            />
          </Col>
        </TabPane>
      </TabBar>
      <Modal
        width={1300}
        className={styles.settlementInquiryModal}
        footer={null}
        visible={isSettlementInquiryVisible}
        title='结付查询'
        onOk={() => {
          setIsSettlementInquiryVisible(false)
          getPageList()
        }}
        onCancel={() => {
          setIsSettlementInquiryVisible(false)
          getPageList()
        }}
      >
        <EditableList
          pagination={false}
          scroll={{ y: '34rem' }}
          loading={pageModalLoading}
          page={{
            items: pages,
            current: 1,
            size: 10,
            total: pages?.length,
          }}
          rowKey={(v, i) => `${i}`}
          columns={SettlementInquiryColumns(params.size * (params.current - 1))}
          onChangePage={(current, size) =>
            setParams({ ...params, current, size: size || 10 })
          }
          sticky
        />
      </Modal>
    </>
  )
}

export const Reconciliations = forwardRef(RealReconciliations)
