/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: sj
 * @LastEditTime: 2021-12-15 13:54:55
 */
import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { Request } from './request'

export function createAdminToken(
  username: string,
  password: string,
  smsCode?: string
): Request {
  return {
    url: `${ADMIN_API_PREFIX}/auth/token`,
    method: 'POST',
    params: {
      phone: username,
      password,
      smsCode,
    },
  }
}
export interface CreateDirectLoginTokenParams {
  tenantId: string
  phone: string
}

export function createDirectLoginToken(
  params: CreateDirectLoginTokenParams
): Request {
  return {
    url: `${ADMIN_API_PREFIX}/auth/oneKeyLogin`,
    method: 'POST',
    params,
  }
}

export function getSmsCode(phone: string): Request {
  return {
    url: `${ADMIN_API_PREFIX}/auth/sms`,
    method: 'POST',
    params: {
      phone,
    },
  }
}

// 人脸登录
export function faceLogin(imageBast: string): Request {
  return {
    url: `${API_PREFIX}/blade-user/auth/face/login`,
    method: 'POST',
    body: {
      imageBast,
    },
  }
}

export function temporaryToken(): Request {
  return {
    url: `${API_PREFIX}/blade-user/auth/temporaryToken`,
    method: 'GET',
  }
}
