import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Table } from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'

import { ThemeContext } from '../../../theme/ThemeContext'
import { Columns } from './Columuns'
import { Query } from './Query'
import styles from './order.module.css'
import {
  getreplaceMakeDetail,
  getreplaceMakeDetailAmount,
  getreplaceMakeOrderList,
} from '../OReplaceMakeSlice'

// 代煎明细详情
export const ReplaceMakeDetail = (): ReactElement => {
  const theme = useContext(ThemeContext)

  const dispatch = useDispatch<RootDispatch>()

  const [data, setData] = useState<any>([])

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [total, setTotal] = useState(0)

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [cellData, setCellData] = useState<any>({})

  useEffect(() => {
    setPageLoading(true)
    dispatch(
      getreplaceMakeDetail({
        ...params,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setTotal(res.total)
        setData(res.records)
      })
      .finally(() => {
        setPageLoading(false)
      })

    dispatch(getreplaceMakeDetailAmount({ ...params }))
      .then(unwrapResult)
      .then((res: any) => {
        setCellData({
          sellNumber: res.sellNumber,
          totalCostFormat: res.totalCostFormat,
          sellAmountFormat: res.sellAmountFormat,
        })
      })
  }, [params])

  // 代煎订单导出
  const onExport = () => {
    const param = {
      ...params,
      current: 1,
      size: 1000,
    }
    const theader = [
      '药品名称',
      '销售数量',
      '进价',
      '总成本',
      '销售单价',
      '销售总金额',
    ]
    const filterVal = [
      'materialsCatalogName',
      'sellNumber',
      'stockPrice',
      'totalCost',
      'unitPrice',
      'sellAmount',
    ]
    dispatch(getreplaceMakeDetail({ ...param }))
      .then(unwrapResult)
      .then((res: any) => {
        const data = res.records
          .map((v: any) => ({
            ...v,
            unitPrice: v.unitPrice.toFixed(5),
            stockPrice: v.stockPrice.toFixed(5),
          }))
          ?.map((s: any) => filterVal?.map((j: any) => s[j]))
        import('../../../ExportXLSX/Export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: theader,
            data,
            filename: '代煎明细',
            autoWidth: true,
          })
        })
      })
  }

  return (
    <Col
      style={{
        height: 'calc(100% - 20px) ',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: theme.pn,
          marginTop: 10,
          borderRadius: 10,
          padding: '10px 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Query
          onValueChange={(v) => {
            setParams({
              ...params,
              ...v,
              current: 1,
            })
          }}
          onExport={onExport}
        />
        <EditableList
          page={{
            items: data,
            current: params.current || 1,
            size: params.size || 10,
            total,
          }}
          loading={pageLoading}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.total}>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell className={styles.footingTitle} index={1}>
                  合计
                </Table.Summary.Cell>
                <Table.Summary.Cell align='center' index={2}>
                  {cellData?.sellNumber}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell align='center' index={4}>
                  {cellData?.totalCostFormat}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={6} align='center'>
                  {cellData?.sellAmountFormat}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          style={{ color: theme.tc3 }}
          className={styles.table}
          columns={Columns((params.size || 10) * ((params.current || 1) - 1))}
          onChangePage={(current: number, size?: number) => {
            setParams({
              ...params,
              current: current,
              size: size || 10,
            })
          }}
        />
      </Col>
    </Col>
  )
}
