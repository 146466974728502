/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-02 14:30:29
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../../app/store'
import { sendAsync } from '../../../../app/applicationSlice'
import * as DT from '../../../../services/outPatientCostStatistics'

// 列表
export const getOutPatientCostStatisticsList = createAsyncThunk<
  void,
  DT.OutPatientCostStatisticsPrams,
  RootThunkApi<void>
>('clinic/getOutPatientCostStatisticsList', async (params, api) => {
  return sendAsync(DT.outPatientCostStatisticsList(params), api)
})


// 导出
export const getOutPatientCostStatisticsExport = createAsyncThunk<
  void,
  DT.OutPatientCostStatisticsPrams,
  RootThunkApi
>('stand/getUseDetailExport', async (params, api) => {
  return sendAsync(DT.getExportFn(params), api)
})