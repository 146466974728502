/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-03-29 13:33:08
 * @LastEditors: linxi
 * @LastEditTime: 2021-04-20 15:55:01
 */
import {
  MinusOutlined,
  PlusOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React, { ReactElement } from "react";
import styles from "./ActionBar.module.css";

export type ActionType =
  | "add"
  | "delete"
  | "edit"
  | "enable"
  | "addstore"
  | "entry"
  | "putlist";

interface ActionBarProps {
  actions: ActionType[];
  onAction: (_: ActionType) => void;
  className?: string;
}

export const ActionBar = (props: ActionBarProps): ReactElement => {
  return (
    <div className={`${styles.bar} ${props.className}`}>
      <div></div>
      <div>
        {props.actions.map((btn) => {
          let icon = <PlusOutlined />;
          let label = "新增";
          switch (btn) {
            case "delete":
              icon = <MinusOutlined />;
              label = "删除";
              break;
            case "enable":
              icon = <PoweroffOutlined />;
              label = "停用";
              break;
            case "addstore":
              icon = <PlusOutlined />;
              label = "新增药房";
              break;
            case "entry":
              label = "登账";
              break;
            case "putlist":
              icon = <PlusOutlined />;
              label = "新增";
              break;
          }
          return (
            <Button
              type="primary"
              key={btn}
              icon={icon}
              className={styles.button}
              onClick={() => props.onAction(btn)}
            >
              {label}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
