import {
    EditableList,
} from "../../../compnents/list/EditableList";
import React, { ReactElement, useEffect, useState } from "react";
import { notification } from "antd";
import { NurseQuerys } from "./Query";
import { useDispatch, useSelector } from "react-redux";
import { appealcolumn } from "./columns";
import { BeforeOperatingParmas } from "../../../services/beforeOperatingManagePage";
import { ModelList } from "./ModelList";
import styles from "../../nurse/formula/formulas/formula.module.css";
import { recipeColumns } from "../../nurse/formula/noformula/column";
import { RootDispatch } from "../../../app/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAppealListAsync, getAppealDetailAsync } from "./beforeOperatingManagePageSlice";
import { useLocation } from 'react-router-dom'

export const BeforeOperatingManagePage = (props: {
    value?: any
}): ReactElement => {
    const dispatch = useDispatch<RootDispatch>();

    const CREDENTIAL_KEY = "states";

    const [queries, setQueries] = useState<BeforeOperatingParmas>({
        current: 1, size: 10
    });

    const location: any = useLocation()

    const [isTreatmentId, setIsTreatmentId] = useState('');

    const [dataList, setDataList] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [isModalDetailsVisible, setIsModalDetailsVisible] = useState(false);

    const [SelectId, setSelectId] = useState(0);

    const [total, setTotal] = useState(0);

    const [detail, setDetail] = useState();


    const getList = () => {
        dispatch(getAppealListAsync({
            ...queries,
        }))
            .then(unwrapResult)
            .then((v: any) => {
                setDataList(v?.records)
                setTotal(v?.total)
            })
    }

    useEffect(() => {
        if (queries) {
            getList()
        }

    }, [queries]);

    useEffect(() => {
        if (location.state?.toUseAppeal) {
            const id = location.state?.id
            const operationId = location.state?.operationId
                setIsModalVisible(true);
                setIsTreatmentId(operationId)
                dispatch(getAppealDetailAsync(id))
                    .then(unwrapResult)
                    .then((v: any) => {
                        setDetail(v)
            })
        }
    }, [location.state?.toUseAppeal])

    useEffect(() => {
        // if (queries?.param?.length == 9 && Number(data.length) == 1) {
        //     dispatch(getNoformula({ registrationId: data[0]?.registrationId, state: 0, normalUsemethod: data[0]?.normalUsemethod }));
        //     setIsModalVisible(true);
        //     setIsTreatmentId(data[0]?.treatmentId)
        // }
    }, [])

    const range: any = sessionStorage.getItem(CREDENTIAL_KEY);
    return (
        <>
            <div className={styles.contenter}>
                <NurseQuerys
                    isModalVisible={isModalVisible}
                    onValueChange={(v) => {
                        setQueries({ ...queries, ...v, current: 1, size: 10 });
                        // dispatch(setCurrent(1));
                        // const state: any = v.range
                        // sessionStorage.setItem(CREDENTIAL_KEY, state);
                    }}
                />
                <EditableList
                    // rowKey={(_, i) => queries ? (queries.size || 0) * (queries.current || 0) + (i || 0) : 0}
                    className={styles.noformula}
                    page={{
                        items: dataList,
                        current: queries?.current,
                        size: queries?.size,
                        total: total
                    }}

                    onChangePage={(current, pageSize) => {
                        setQueries({ current, size: pageSize })
                        dispatch(getAppealListAsync({
                            ...queries
                        }))
                            .then(unwrapResult)
                            .then((v: any) => {
                                setDataList(v?.records)
                                setTotal(v?.total)
                            })
                        // setSize(pageSize as number);
                        // dispatch(setCurrent(current));
                    }}
                    columns={
                        appealcolumn((id, operationId) => {
                            setIsModalVisible(true);
                            setIsTreatmentId(operationId)
                            dispatch(getAppealDetailAsync(id))
                                .then(unwrapResult)
                                .then((v: any) => {
                                    setDetail(v)
                                })
                        },
                        () => {
                            setIsModalDetailsVisible(true);
                        }, queries?.current * (queries?.size - 1))
                    }
                />
                <ModelList
                    detail={detail}
                    visible={isModalVisible}
                    treatmentId={isTreatmentId}
                    onOk={() => {
                        setIsModalVisible(false);
                        getList()
                    }}
                    onCancel={() => {
                        setIsModalVisible(false);
                        getList()
                    }}
                />
                {/* <ModelDetails
                    visible={isModalDetailsVisible}
                    onOk={() => {
                        setIsModalDetailsVisible(false);
                        getList()
                    }}
                    onCancel={() => {
                        setIsModalDetailsVisible(false);
                        getList()
                    }}
                /> */}
                
            </div>
        </>
    );
};
