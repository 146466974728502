/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2024-06-11 11:33:57
 */
import { Button, Popover, Row, Spin, Tabs } from 'antd'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectEdition,
  selectQueryLoadingTag, selectTenantName,
} from '../../app/applicationSlice'
import Qrcode from 'qrcode.react'
import { TabBar } from '../../compnents/widgets/TabBar'
import { Registrations, RegistrationsRef } from './list/Registrations'
import { Register } from './register/Register'
import styles from './Registration.module.css'
import { ScanOutlined, SettingOutlined } from '@ant-design/icons'
import { PreferencesSettingModal } from './preferencesSettingModal'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../app/store'
import { getWXCode } from '../../layouts/DefaultLayout'
import { getMobileTodayRegisterCode } from './register/registerSlice'

const { TabPane } = Tabs

export const Registration = (): ReactElement => {
  const ref = useRef<RegistrationsRef>(null)

  const dispatch = useDispatch<RootDispatch>()

  const [selectKey, setSelectKey] = useState('0')

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const [loading, setLoading] = useState(false)

  const [qrCodeText, setqrCodeText] = useState('二维码正在加载中') //微诊所微信二维码

  const [registerUrl, setRegisterUrl] = useState('') //微诊所微信二维码

  const location: any = useLocation()

  const tenantName = useSelector(selectTenantName)

  const getWXCodeUrl = () => {
    const host = window.location.host
    setLoading(true)
    dispatch(getMobileTodayRegisterCode())
      .then(unwrapResult)
      .then((res: any) => {
        // setIsWXCode(c)
        setRegisterUrl(
          `http://${host}/h5/triage/index.html#/mobileTodayRegister?tenantCode=${
            res?.tenantCode
          }&tenantName=${encodeURIComponent(res?.tenantName)}&provinceCode=${
            res?.province
          }&cityCode=${res?.city}&contryCode=${res?.region}&hisRegister=true`
        )
      })
      .catch((err) => {
        setqrCodeText('二维码加载失败，点击重试')
      })
      .finally(() => setLoading(false))
  }

  const [
    isPreferencesSettingVisible,
    setIsPreferencesSettingVisible,
  ] = useState(false)

  const changeCanvasToPic = (id: string, linkId: string, title: string) => {
    const canvasImg: any = document.getElementById(id) // 获取canvas类型的二维码
    const img = new Image()
    img.src = canvasImg.toDataURL('image/png') // 将canvas对象转换为图片的data url
    const downLink: any = document.getElementById(linkId)
    downLink.href = img.src
    downLink.download = title // 图片name
  }
  const xcxModal = (
    <div style={{ width: 200, height: 290 }}>
      <Spin spinning={loading}>
        {registerUrl != '' ? (
          <div>
            <div>
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}
              >
                扫描挂号二维码：
              </span>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '16px',
              }}
            >
              <div
                style={{
                  width: '150px',
                  height: '150px',
                  border: '1px solid rgba(238, 238, 238, 1)',
                }}
              >
                <Qrcode
                  id='qrcode1'
                  value={registerUrl}
                  size={512}
                  style={{
                    width: 150,
                    height: 150,
                  }}
                />
              </div>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                margin: '16px 0 10px 0',
              }}
            >
              <a
                style={{
                  width: '80px',
                  height: '30px',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(45, 123, 255, 1)',
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                href='/#'
                id='link1'
                onClick={() => {
                  changeCanvasToPic('qrcode1', 'link1', '挂号二维码')
                }}
              >
                下载
              </a>
              {/* </div> */}
            </div>
            <div className={styles.text}>
              患者扫描二维码，即可在手机号快速挂号
            </div>
          </div>
        ) : (
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              cursor: 'pointer',
            }}
            onClick={() => {
              getWXCodeUrl()
            }}
          >{`${qrCodeText} `}</p>
        )}
      </Spin>
    </div>
  )
  return (
    <>
      <TabBar
        style={{
          position: 'relative',
        }}
        className={styles.tabs}
        defaultActiveKey={location.state ? location.state.selectKey : '0'}
        destroyInactiveTabPane
        onChange={(v) => {
          setSelectKey(v)
        }}
      >
        <TabPane tab='新增挂号' key='0'>
          <Register
            onSuccess={() => ref.current?.refresh()}
            isPreferencesSettingVisible={isPreferencesSettingVisible}
          />
        </TabPane>
        <TabPane tab='挂号列表' key='1'>
          <Registrations ref={ref} />
        </TabPane>
      </TabBar>

      {hisVersion == 0 && (tenantName?.indexOf('余姚健齿口腔')  < 0 && tenantName?.indexOf('余姚康民内科诊所')  < 0 ) ? (
        <>
          <Popover placement='bottom' content={xcxModal} trigger='click'>
            <Row
              style={{
                position: 'absolute',
                height: '2.5rem',
                right: 140,
                top: 60,
                fontSize: '1rem',
                padding: '0 20px',
              }}
              align='middle'
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#027AFF',
                }}
                onClick={() => {
                  getWXCodeUrl()
                }}
              >
                <ScanOutlined
                  style={{
                    fontSize: '20px',
                    color: '#027AFF',
                  }}
                />
                <a
                  type='text'
                  style={{
                    marginLeft: '6px',
                    fontSize: '16px',
                    color: '#027AFF',
                  }}
                >
                  扫码挂号
                </a>
              </div>
            </Row>
          </Popover>
          <Row
            style={{
              position: 'absolute',
              height: '2.5rem',
              right: 20,
              top: 60,
              fontSize: '1rem',
              padding: '0 20px',
            }}
            align='middle'
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#027AFF',
              }}
            >
              <SettingOutlined
                style={{
                  fontSize: '20px',
                  color: '#027AFF',
                }}
              />
              <a
                type='text'
                style={{
                  marginLeft: '6px',
                  fontSize: '16px',
                  color: '#027AFF',
                }}
                onClick={() => {
                  setIsPreferencesSettingVisible(true)
                }}
              >
                偏好设置
              </a>
            </div>
          </Row>
        </>
      ) : (
        <></>
      )}

      <PreferencesSettingModal
        visible={isPreferencesSettingVisible}
        onOk={() => {
          setIsPreferencesSettingVisible(false)
        }}
        onCancel={() => {
          setIsPreferencesSettingVisible(false)
        }}
      />
      <></>
    </>
  )
}
