import React, { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditableList } from "../../../../compnents/list/EditableList";
import { NavBar } from "../../../../compnents/nav/NavBar";
import { MaintenanceDrugsColumns } from "./column";
import { MaintenanceDrugsQuery } from "./query";
import {
  selectCurrent,
  selectMaintenanceDrugData,
  selectTotal,
  setCurrent,
} from "./maintenanceDrugSlice";
import styles from "./maintenanceDrug.module.css";
import { DateTimeFormat } from "../../../../models/datetime";
import moment from "moment";
import { Button } from "antd";
import { traceRoute } from "../../../../layouts/layoutSlice";
import { DataType } from "../../../putstorage/putstorage";
import { MaintenanceDrugParams } from "../../../../services/maintenanceDrug";
import { useHistory } from "react-router";
export const MaintenanceDrugs = (): ReactElement => {
  const dispatch = useDispatch();
  const [queries, setQueries] = useState<MaintenanceDrugParams>({
    timeHead: moment().startOf("month").format(DateTimeFormat),
    timeTail: moment().endOf("month").format(DateTimeFormat),
  });

  const Current = useSelector(selectCurrent);

  const total = useSelector(selectTotal);

  const data = useSelector(selectMaintenanceDrugData);

  const [size, setSize] = useState(10);

  const history = useHistory();

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      return
    },
  };

  return (
    <>
      <NavBar
        style={{ margin: "10px 20px 0px 20px" }}
        where={["库存管理", "库存养护"]}
        backtrace={{
          name: "养护列表",
          path: "/AdditionDrugs/additionDrugs",
          state: {
            station: "2",
          },
        }}
        buttons={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" style={{ marginRight: "10px" }}>
              删除
            </Button>
            <Button
              type="primary"
              onClick={() => history.push("/additionDrug")}
            >
              添加养护药品
            </Button>
          </div>
        }
      />
      <div className={styles.content}>
        <MaintenanceDrugsQuery
          onValueChange={(v: any) => {
            setQueries({ ...queries, ...v });
          }}
        />
        <EditableList
          className={styles.supper}
          rowKey={(t: any) => t.id}
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number);
            dispatch(setCurrent(current));
          }}
          columns={MaintenanceDrugsColumns()}
          rowSelection={{
            // type: selectionType,
            ...rowSelection,
          }}
        />
      </div>
    </>
  );
};
