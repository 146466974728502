import { CaretDownFilled, SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  Cascader as Cascaded,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInsuranceArray } from '../../../app/applicationSlice'
import { RootDispatch } from '../../../app/store'
import {
  DateRangeChargeTypes,
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import { InvoicePageParams, PaymentListParams } from '../../../services/payment'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getAllDepartmentUser, selectDepartments } from '../list/paymentSlice'

export const Query = (props: {
  onValueChange: (params: any) => void
  status: number
  type: number
  YBZFUploadflag?: boolean
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const { Option } = Select

  const departments = useSelector(selectDepartments)

  const insuranceArray = useSelector(selectInsuranceArray)

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  const [allList, setAllList] = useState<any>([])

  const getAllDepartment = () => {
    dispatch(getAllDepartmentUser())
      .then(unwrapResult)
      .then((res: any) => {
        setAllList(
          res.map((v: any) => {
            return {
              label: v.name,
              value: v.id,
              children: v.children.map((i: any) => {
                return {
                  label: i.name,
                  value: i.id,
                }
              }),
            }
          })
        )
      })
  }

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
  }

  useEffect(() => {
    getAllDepartment()
    if (
      Object.keys(storageParams).length &&
      props.status == storageParams.state
    ) {
      form.setFieldsValue({ ...storageParams })
      if (
        storageParams.treatmentDepartmentId &&
        storageParams.treatmentDoctorId
      ) {
        form.setFieldsValue({
          allData: [
            storageParams.treatmentDepartmentId,
            storageParams.treatmentDoctorId,
          ],
        })
      } else if (storageParams.treatmentDepartmentId) {
        form.setFieldsValue({
          allData: [storageParams.treatmentDepartmentId],
        })
      }
    } else {
      const [head, tail] = getDateRange(DateRangeType.Today)
      form.setFieldsValue({
        actionTimeHead: head,
        actionTimeTail: tail,
      })
    }

    form.submit()
  }, [])

  return (
    <Form
      form={form}
      onFinish={(values) => {
        const tempArr = values.allData
        props.onValueChange({
          ...values,
          treatmentDepartmentId: tempArr?.length ? tempArr[0] : undefined,
          treatmentDoctorId: tempArr?.length == 2 ? tempArr[1] : undefined,
          treatmentDepartmentName: tempArr?.length
            ? allList.filter((v: any) => v.value == tempArr[0])[0].label
            : undefined,
          treatmentDoctorName:
            tempArr?.length == 2
              ? allList
                  .filter((v: any) => v.value == tempArr[0])[0]
                  .children.filter((i: any) => i.value == tempArr[1])[0].label
              : undefined,
          allData: undefined,
        })
      }}
    >
      <Row style={{ marginTop: 10, marginBottom: 20 }} justify='space-between'>
        <Space>
          {props.type && props.type != 1 ? (
            <Form.Item noStyle name='param'>
              <Input
                autoComplete='off'
                prefix={<SearchOutlined />}
                placeholder='患者姓名/手机号/病历号'
                style={{ width: '16rem' }}
                allowClear
                onChange={() => form.submit()}
              />
            </Form.Item>
          ) : (
            ''
          )}
          <Form.Item noStyle name='allData'>
            <Cascaded
              loadData={loadData}
              changeOnSelect
              // suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              style={{ width: '240px' }}
              options={allList}
              onChange={() => form.submit()}
              placeholder='查看范围-科室列表'
            />
            {/* <Select
              style={{ width: '12.5rem' }}
              placeholder='查看范围-科室列表'
              allowClear
              onChange={() => form.submit()}
            >
              {departments.map((d) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))}
            </Select> */}
          </Form.Item>
          {insuranceArray &&
          insuranceArray.length !== 0 &&
          !props.YBZFUploadflag ? (
            <Form.Item name='insuranceCode' noStyle>
              <Select
                placeholder='险种类型'
                style={{ width: '8.25rem' }}
                allowClear
                // suffixIcon={<CaretDownFilled />}
                onChange={form.submit}
              >
                <Select.Option value={''}>全部</Select.Option>
                <Select.Option value={'ZIFEI'}>自费</Select.Option>
                {insuranceArray.map((v) => (
                  <Select.Option value={v.insuranceCode} key={v.id}>
                    {v.insuranceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            ''
          )}
          {/* <Form.Item name="invoiceType" noStyle>
                <Select
              placeholder="票据类型"
                  allowClear
                  suffixIcon={<CaretDownFilled />}
                  style={{ width: '8.25rem' }}
                  onChange={form.submit}
                >
              <Option value={0}>纸质发票</Option>
              <Option value={1}>电子发票</Option>
                </Select>
          </Form.Item> */}
          <DateSelect
            allowClear={false}
            style={{ width: '9.5rem' }}
            labelPrefix=''
            namePrefix='actionTime'
            placeholder='时间范围-时间'
            initialValue={DateRangeType.Today}
            options={DateRangeChargeTypes}
            onChange={() => form.submit()}
          />
          <Button type='primary' htmlType='submit'>
            查询
          </Button>
        </Space>
      </Row>
    </Form>
  )
}
