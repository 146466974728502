/*
 * @Descripttion:
 * @version:
 * @Author: suiyue
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 12:05:04
 * @LastEditors: sj
 * @LastEditTime: 2022-06-20 16:43:21
 */
import { Col, Tabs } from "antd"
import React, { useRef } from "react"
import { Stacked } from "../components/Stacked";
import styles from "../insuranceContorl.module.css"

export const MedicineTabBar = () => {
  const { TabPane } = Tabs;

  return (
    <Col className={styles.containerItem}>
      <Tabs
        defaultActiveKey={"1"}
      >
        <TabPane key='1' tab='医生中草药均帖'>
          <Stacked queryType={1} />
        </TabPane>
        <TabPane key='2' tab='机构中草药均帖'>
          <Stacked queryType={2} />
        </TabPane>
      </Tabs>
    </Col>
  )
}
