import { API_PREFIX } from './constant'
import { Request } from './request'

export interface TransactionQueryListParams {
  current?: number
  size?: number
  insuranceCode?: any
  operateUser?: any
  orderSource?: any
  param?: any
  registrationTimeHead?: string
  registrationTimeTail?: string
  state?: any
  treatmentDoctorId?: number
}

export function transactionQueryList(
  params: TransactionQueryListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/transactionInfo`,
    method: 'GET',
    params,
  }
}

export interface TransactionQueryDetailListParams {
  current?: number
  size?: number
  billingCategory?: any
  registrationTimeHead?: string
  registrationTimeTail?: string
  param?: number
  state?: any
  treatmentDoctorId?: any
}

export function transactionDetailQueryList(
  params: TransactionQueryListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/transactionDetailsInfo`,
    method: 'GET',
    params,
  }
}

// 导出
export function transactionDetailQueryExport(
  params: TransactionQueryDetailListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/transactionDetailsInfo/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}

// 导出
export function transactionQueryExport(params: TransactionQueryListParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/treatment/transactionInfo/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}


