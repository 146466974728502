/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-03 20:22:17
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-09 16:37:17
 */
export function getPatientFlowName(patientFlow: number): string {
  switch (patientFlow) {
    case 0:
      return '机构内就诊'
    case 1:
      return '引导到发热门诊就诊(非公共交通)'
    case 2:
      return '留观并转运'
    case 3:
      return '非患者,系陪同人员'
    case 4:
      return '机构工作人员自查'
    case 5:
      return '咨询'
    case 6:
      return '代注'
    case 7:
      return '简单换药'
  }
  return '-'
}
