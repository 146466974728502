import { Tabs, TabsProps } from "antd";
import React, { ReactElement } from "react";
import styles from "./TabBar.module.css";

export const TabPane = Tabs.TabPane;

export const TabBar = (props: TabsProps): ReactElement => {
  return (
    <Tabs
      {...props}
      className={`${styles.tabs} ${props.className || ""}`}
      type="card"
    >
      {props.children}
    </Tabs>
  );
};
