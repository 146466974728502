/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-19 17:22:39
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-17 15:26:19
 */
import React from 'react'
import { Button } from 'antd'
import { EditableColumnType } from '../../compnents/list/EditableList'
import moment from 'moment'
import { ItemCompletionType } from '../completion/completionSlice'
import { DateTimeFormatSimple } from '../../models/datetime'
import { Dimen } from '../../models/dimen'

export const Columns = (startIndex: number): EditableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, i) => `${1 + i + startIndex}`,
    },
    {
      title: '到货单号',
      align: 'center',
      dataIndex: 'arrivalOrderNumber',
      width: '8rem',
      ellipsis: true,
      key: 'arrivalOrderNumber',
      render: (_, t) => {
        return t.arrivalOrderNumber ? t.arrivalOrderNumber : '-'
      },
    },
    {
      title: '商品编码',
      align: 'center',
      dataIndex: 'no',
      key: 'no',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => {
        return t.no ? t.no : '-'
      },
    },
    {
      // 商品名称/生产厂家/规格/剂型
      title: '产品信息',
      align: 'left',
      width: '16rem',
      ellipsis: true,
      render: function Element(_, t) {
        return (
          <div style={{ height: '55px' }}>
            <p
              style={{
                height: '28px',
                lineHeight: '36px',
                padding: '0',
                margin: '0',
                fontSize: '16px',
                fontWeight: 'bolder',
              }}
            >
              {t?.name}
            </p>
            <p
              style={{
                height: '28px',
                lineHeight: '24px',
                padding: '0',
                margin: '0',
                color: '#999999',
                fontSize: '14px',
              }}
            >
              {t.productInfo}
            </p>
          </div>
        )
      },
    },
    {
      title: '供应商',
      align: 'center',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: '10rem',
      ellipsis: true,
      render: (_, t) => {
        return t.supplierName ? t.supplierName : '-'
      },
    },
    {
      title: '批号',
      align: 'center',
      dataIndex: 'batchNo',
      key: 'batchNo',
      width: '8rem',
      ellipsis: true,
      render: (_, t) => {
        return t.batchNo ? t.batchNo : '-'
      },
    },
    {
      title: '生产日期',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      dataIndex: 'productDate',
      key: 'productDate',
      render: (_, t) => {
        return t.productDate
          ? moment(t.productDate).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '有效期至',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      dataIndex: 'deadline',
      key: 'deadline',
      render: (_, t) => {
        return t.deadline
          ? moment(t.deadline).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '到货数量',
      align: 'center',
      dataIndex: 'totalQuantity',
      key: 'totalQuantity',
      width: '6rem',
      ellipsis: true,
      render: (_, t) => {
        return t.totalQuantity ? t.totalQuantity : '-'
      },
    },
    {
      title: '验收合格数量',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'qualifiedQuantity',
      key: 'qualifiedQuantity',
      render: (_, t) => {
        return t.qualifiedQuantity ? t.qualifiedQuantity : '-'
      },
    },
    {
      title: '验收不合格数量',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      dataIndex: 'defectiveQuantity	',
      key: 'defectiveQuantity	',
      render: (_, t) => {
        return t.defectiveQuantity ? t.defectiveQuantity : '-'
      },
    },
    {
      title: '验收人',
      align: 'center',
      dataIndex: 'createUser',
      width: '6rem',
      ellipsis: true,
      key: 'createUser',
      render: (_, t) => {
        return t.createUser ? t.createUser : '-'
      },
    },
    {
      title: '验收日期',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      dataIndex: 'creatTime',
      key: 'creatTime',
      render: (_, t) => {
        return t.creatTime
          ? moment(t.creatTime).format(DateTimeFormatSimple)
          : '-'
      },
    },
  ]
}
