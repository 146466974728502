/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-20 11:22:32
 * @LastEditors: sj
 * @LastEditTime: 2022-06-29 14:38:10
 */
export const insuranceItem = [
  {
    value: '310',
    label: '职工医疗保险',
  },
  {
    value: '390',
    label: '居民医疗保险',
  },
  {
    value: '340',
    label: '离休人员医疗保障',
  },
  {
    value: '510',
    label: '职工生育保险',
  },
]

export const yidibzItem = [
  {
    value: 0,
    label: '本地',
  },
  {
    value: 1,
    label: '省内异地',
  },
  {
    value: 2,
    label: '省外异地',
  },
  {
    value: 3,
    label: '省医保',
  },
]

export const timeFlagOptions = [
  {
    value: '1',
    label: '本周',
  },
  {
    value: '2',
    label: '本月',
  },
  {
    value: '3',
    label: '本季',
  },
  {
    value: '4',
    label: '本年',
  },
  {
    value: '5',
    label: '全部',
  },
 
]

export const timeFlagOptionsZDY = [
  {
    value: '1',
    label: '本周',
  },
  {
    value: '2',
    label: '本月',
  },
  {
    value: '3',
    label: '本季',
  },
  {
    value: '4',
    label: '本年',
  },
  {
    value: '5',
    label: '全部',
  },
  {
    value: '6',
    label: '自定义',
  },
]


export const costFlagOptions = [
  {
    value: '1',
    label: '医疗费用总额',
  },
  {
    value: '2',
    label: '医保报销',
  },
  {
    value: '3',
    label: '个人账户',
  },
  {
    value: '4',
    label: '统筹基金',
  },
  {
    value: '5',
    label: '个人现金',
  },
]

export const getEchartTitle = (type: number):string => {
  if (type === 1 || type === 2) {
    return '中草药帖均（元）'
  } else if (type === 3) {
    return '百分比（%）'
  } else if (type === 4 || type === 5) {
    return '费用（元）'
  } else if (type === 6) {
    return '人次人头比（%）'
  } else if (type === 7) {
    return '人数（个）'
  } else if (type === 8) {
    return '支付费用（元）'
  }else {
    return '-'
  }
}

export const getLineColor = (value = '') => {
  if (value === 'averageTip' || value === 'field0' || value === 'medicalList' || value === 'fundQuota' || value === 'patientPercentage') { //预警线
    return '#F72C5B'
  }else if(value ==='realAverageTip'||value === 'percentage'||value ==='realFundQuota'||value === 'realMedicalList'){ //每月折线
    return '#9cbcdd'
  }else if (value === 'dynameicAverageTip' || value === 'dynameicPercentage'||value==='dynameicFundQuota'||value === 'dynameicMedicalList'){ //动态线
    return '#FEB54E'
  }else {
    return ''
  }
}


export const getTips = (type = 0): string => {
  if (type === 4) {
    return '次均费用 = 列支医疗费 / 就诊人次数'
  } else if (type === 5) {
    return '人均费用 = 次均费用 * 人次人头比'
  } else if (type ===6) {
    return '人次人头比 = 就诊人次数 / 就诊人头'
  } else if (type === 9) {
    return '医保列支医疗费支付限额 = 总医疗费用 - 自费费用 - 乙类先行自付费用'
  }  else {
    return ''
  }
}