/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-17 15:39:14
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-21 09:26:47
 */
import {Form, Input, Button, Row, Col, Select} from "antd";
import React, { useEffect } from "react";
import { UserOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { supplierParams } from "../../services/supplier";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { traceRoute } from "../../layouts/layoutSlice";
import { setCurrent } from "../wms/supplierSlice";
const { Option } = Select;

export const SupplierQuery = (props: {
  supplierSave: () => void;
  onValueChange: (queries: supplierParams) => void;
  supplierCategory:number
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const history = useHistory();

  return (
    <>
      <p>
        <a
          onClick={() => {
            props.supplierSave();
          }}
        >
          <PlusOutlined />
          新增
        </a>
      </p>
      <Form
        form={form}
        name="control-hooks"
        onFinish={(vs) => {
          dispatch(setCurrent(1));
          props.onValueChange({ ...vs });
        }}
      >
        <Row gutter={11}>
          <Col span={6}>
            <Form.Item name="name">
              <Input
                placeholder={"请输入"+(props.supplierCategory?'生产厂商':'供应商')+"名称或拼音首字母"}
                allowClear
                prefix={<UserOutlined />}
                onInput={() => {
                  form.submit();
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
              <Form.Item name="flag" initialValue={0}>

                  <Select
                      placeholder="查询范围"
                      // suffixIcon={<CaretDownFilled />}
                      onChange={() =>{
                          form.submit();
                      }}
                  >
                      <Option value={0}>{"仅查询本机构的"+(props.supplierCategory?'生产厂商':'供应商')}</Option>
                      <Option value={1}>{"包含系统自带的默认"+(props.supplierCategory?'生产厂商':'供应商')}</Option>
                  </Select>
              </Form.Item>
          </Col>
          <Col span={2} >
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={() => {
                form.submit();
              }}
            >
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
