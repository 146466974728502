import {
    EditableList,
} from "../../../compnents/list/EditableList";
import React, { ReactElement, useEffect, useState } from "react";
import { notification, Modal } from "antd";
import { NurseQuerys } from "./Query";
import { useDispatch, useSelector } from "react-redux";
import { appealcolumn } from "./columns";
import { BeforeOperatingParmas } from "../../../services/beforeOperatingManagePage";
import { ModelList } from "./ModelList";
import styles from "../../nurse/formula/formulas/formula.module.css";
import { recipeColumns } from "../../nurse/formula/noformula/column";
import { RootDispatch } from "../../../app/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { getOperatingListAsync, getOperatingDetailAsync, getOperatingStatusAsync } from "./operatingManagePageSlice";

export const OperatingManagePage = (props: {
    value?: any
}): ReactElement => {
    const dispatch = useDispatch<RootDispatch>();

    const CREDENTIAL_KEY = "states";

    const [queries, setQueries] = useState<BeforeOperatingParmas>({
        current: 1, size: 10
    });

    const [isTreatmentId, setIsTreatmentId] = useState('');

    const [videoPath, setVideoPath] = useState('');

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [videoModel, setVideoModel] = useState(false);

    const [SelectId, setSelectId] = useState(0);

    const [data, setData] = useState([])

    const [detail, setDetail] = useState({})

    const [size, setSize] = useState(10);

    const [total, setTotal] = useState(0);

    const [status, setStatus] = useState('');

    const [isOperatingModel, setIsOperatingModel] = useState(false);

    const getList = () => {
        dispatch(getOperatingListAsync({
            ...queries,
        }))
            .then(unwrapResult)
            .then((v: any) => {
                setData(v?.records)
                setTotal(v?.total)
            })
    }

    useEffect(() => {
        getList()

    }, [queries, props?.value]);

    useEffect(() => {
        // if (queries?.param?.length == 9 && Number(data.length) == 1) {
        //     dispatch(getNoformula({ registrationId: data[0]?.registrationId, state: 0, normalUsemethod: data[0]?.normalUsemethod }));
        //     setIsModalVisible(true);
        //     setIsTreatmentId(data[0]?.treatmentId)
        // }
    }, [data])

    const range: any = sessionStorage.getItem(CREDENTIAL_KEY);
    return (
        <>
            <div className={styles.contenter}>
                <NurseQuerys
                    isModalVisible={isModalVisible}
                    onValueChange={(v) => {
                        // setIsModalVisible(true);
                        setQueries({ ...queries, ...v, current: 1, size: 10 });
                        // const state: any = v.range
                        // sessionStorage.setItem(CREDENTIAL_KEY, state);
                    }}
                />
                <EditableList
                    rowKey={(_, i) => queries ? (queries.size || 0) * (queries.current || 0) + (i || 0) : 0}
                    className={styles.noformula}
                    page={{
                        items: data,
                        current: queries?.current,
                        size: queries?.size,
                        total: total,
                    }}

                    onChangePage={(current, pageSize) => {
                        setQueries({ current, size: pageSize })
                        dispatch(getOperatingListAsync({
                            ...queries
                        }))
                            .then(unwrapResult)
                            .then((v: any) => {
                                setData(v?.records)
                                setTotal(v?.total)
                            })
                        // setSize(pageSize as number);
                        // dispatch(setCurrent(current));
                    }}
                    columns={
                        appealcolumn((id) => {
                            setIsOperatingModel(true)
                            setStatus(id)
                        },
                            (id) => {
                                dispatch(getOperatingDetailAsync(id))
                                    .then(unwrapResult)
                                    .then((v: any) => {
                                        setDetail(v)
                                    })
                                setIsModalVisible(true);
                            }, (videopath) => {
                                setVideoPath(videopath)
                                setVideoModel(true)
                            },
                            queries?.size * (queries?.current - 1))
                    }
                // onRow={(d: any) => ({
                //     onDoubleClick: () => {
                //         // dispatch(getNoformula({ registrationId: d.registrationId, state: 0, normalUsemethod: d.normalUsemethod }));
                //         setIsModalVisible(true);
                //         setIsTreatmentId(d.treatmentId)
                //     },
                // })}
                />
                <ModelList
                    detail={detail}
                    visible={isModalVisible}
                    onOk={() => {
                        setIsModalVisible(false);
                        // dispatch(getNoformulaList({
                        //     // ...queries, current: Current, size, state: 0, range: range
                        // }));

                        setSelectId(0);
                    }}
                    onCancel={() => {
                        setIsModalVisible(false);

                        // dispatch(getNoformulaList({
                        //     // ...queries, current: Current, size, state: 0, range: range
                        // }));
                        setSelectId(0);
                    }}
                />
                <Modal
                    title='取消'
                    width='500px'
                    centered
                    visible={isOperatingModel}
                    onOk={() => {
                        dispatch(getOperatingStatusAsync(status))
                            .then(unwrapResult)
                            .then(() => {
                                getList()
                                setIsOperatingModel(false)
                            })
                    }
                    }
                    onCancel={() => setIsOperatingModel(false)}
                >
                    <div style={{ height: '60px', textAlign: 'center', lineHeight: '60px', fontSize: '20px' }}>请问是否确认取消？</div>
                </Modal>
                <Modal
                    title='见证视频'
                    width='600px'
                    centered
                    visible={videoModel}
                    onOk={() => {
                        setVideoModel(false)
                        setVideoPath('')
                    }
                    }
                    onCancel={() => {
                        setVideoModel(false)
                        setVideoPath('')
                    }}
                >
                    <div>
                        <video src={videoPath} controls style={{ width: '100%' }}></video>
                    </div>
                </Modal>
            </div>
        </>
    );
};
