/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: sj
 * @LastEditTime: 2022-08-26 10:04:08
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface GetCarryOverListParams {
  current?: number
  size?: number
  timeHead?: string
  timeTail?: string
  storehouseId?: number
}

export function getCarryOverList(params: GetCarryOverListParams): Request {
  return {
    url: `${API_PREFIX}/blade-store/carryover/list`,
    method: 'GET',
    params: {
      current: params.current,
      size: params.size,
      startTime: params.timeHead,
      endTime: params.timeTail,
      storehouseId: params.storehouseId,
    },
  }
}

export function removeCarryOver(id: string): Request {
  return {
    url: `${API_PREFIX}/blade-store/carryover/remove`,
    method: 'POST',
    params: {
      ids: id,
    },
  }
}

export interface CreateCarryOverParam {
  startTime?: string
  endTime?: string
  storehouseId?: number
  storehouseName?: string
}

export function createCaryOver(params: CreateCarryOverParam): Request {
  return {
    url: `${API_PREFIX}/blade-store/carryover/carryoverexecute`,
    method: 'POST',
    body: params,
  }
}

export interface GetCarryOverDetailEntriesParams {
  current?: number
  size?: number
  flag?: number
  name?: string
  tenantCarryOverId?: number
}

export function getCarryOverDetailEntries(
  params: GetCarryOverDetailEntriesParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/carryoveritem/page`,
    method: 'GET',
    params,
  }
}

export interface GetCarryOverItemListParams {
  tenantCarryOverId: number
}

export function getCarryOverItemList(
  params: GetCarryOverItemListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/carryoverstatistics/list`,
    method: 'GET',
    params,
  }
}

// 导出
export function getCarryOverListExoprtFn(
  params: GetCarryOverListParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/carryover/list/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params: {
      current: params.current,
      size: params.size,
      startTime: params.timeHead,
      endTime: params.timeTail,
      storehouseId: params.storehouseId,
    },
  }
}

// 详情导出
export function getCarryOverDetailExoprtFn(
  params: GetCarryOverDetailEntriesParams
): Request {
  return {
    url: `${API_PREFIX}/blade-store/carryoveritem/page/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params
  }
}
