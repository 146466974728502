import { API_PREFIX } from './constant'
import { Request } from './request'

/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-05-19 14:44:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-19 14:47:56
 */
export interface failureDirectoryParams {
  current: number
  size: number
  hilistCode?: string
  localItemName?: string
}

// 列表
export function getFailureDirectory(params: failureDirectoryParams): Request {
  return {
    url: `${API_PREFIX}/blade-insurance/insuranceCatalog/checkCatMapping`,
    method: 'GET',
    params,
  }
}
