/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-27 15:08:32
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-18 17:01:40
 */
import { DeleteOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  TableColumnType,
} from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { Retail } from '../../models/retail'
import { AutomaticSchedulingModal } from './AutomaticSchedulingModal'
import styles from './composing.module.css'
import {
  addArrangeListPage,
  deleteDoctorInfo,
  getArrangeListPage,
  getDoctorInfo,
  getRankingListPage,
  resetArrangeListPage,
} from './composingSlice'
import { ComposingModal } from './modal'

export const MonthTab = (props: { selectKey: any }) => {
  const tableRef = useRef<any>(null)
  const btnRef = useRef<any>(null)
  const tableWrapRef = useRef<any>(null)
  const { Option } = Select

  const dispatch = useDispatch<RootDispatch>()

  const [selectValue, setSelectValue] = useState(1) //医生 or 护士

  const [isModalVisible, setIsModalVisible] = useState(false) //重置排班弹窗是否显示

  const [title, setTitle] = useState('') //重置排班弹窗标题

  const [monthsTime, setMonthsTime] = useState(0) //月 日期时间控制

  const [columns, setColumns] = useState<TableColumnType<any>[]>([])

  const [tableLoading, setTableLoading] = useState(false) // 表格loading控制

  const [btnLoading, setBtnLoading] = useState(false) // 按钮loading控制

  const [spinLoading, setSpinLoading] = useState(false) // 弹窗loading控制

  const [
    isAutomaticSchedulingModalVisible,
    setIsAutomaticSchedulingModalVisible,
  ] = useState(false) //自动排班弹窗

  const [params, setParams] = useState<any>()

  const [page, setPage] = useState<any>({
    total: 0,
    items: [],
  })

  const [btnDisabled, setBtnDisabled] = useState(false)

  const [rankingList, setRankingList] = useState<any>() //医生排班班次列表

  const [radioValue, setRadioValue] = useState(0) //班次单选

  const [rankingData, setRankingData] = useState<any>() //新增编辑班次信息

  const [tableScrollY, setTableScrollY] = useState<any>('')
  useEffect(() => {
    //固定表头
    const y = tableWrapRef.current.clientHeight - 62 + 'px'
    setTableScrollY(y)
  }, [])

  useEffect(() => {
    params != undefined && getPage()
  }, [params])

  useEffect(() => {
    const monthyDate = moment().subtract(monthsTime, 'months').format('YYYY-MM')
    // 获取每个月天数
    const monthsDay = new Date(
      parseInt(monthyDate.split('-')[0]),
      parseInt(monthyDate.split('-')[1]),
      0
    ).getDate()

    const data: TableColumnType<any>[] = [
      //表格表头
      {
        title: selectValue == 1 ? '医生' : '护士',
        dataIndex: 'doctorName',
        key: 'doctorName',
        align: 'center',
        fixed: 'left',
        width: '16rem',
        ellipsis: true,
        render: function doctorName(_, t) {
          return (
            <div style={{ overflow: 'hidden' }}>
              {`${t.doctorName}(${t.departmentName})`}
            </div>
          )
        },
      },
    ]
    const paramsData = []
    for (let i = 0; i < monthsDay; i++) {
      paramsData.push(`${monthyDate}-${i + 1 <= 9 ? '0' + (i + 1) : i + 1}`) //获取整月日期
      data.push({
        title: `${parseInt(monthyDate.split('-')[1])}.${i + 1}`,
        align: 'center',
        width: '8rem',
        render: function shiftName(_, t) {
          return columnsRenderFn(
            t,
            `${monthyDate}-${i + 1 <= 9 ? '0' + (i + 1) : i + 1}`,
            i
          )
        },
      })
    }
    setParams({
      //获取列表参数
      dateTime: paramsData,
      stationCategory: selectValue,
    })
    setColumns(data)
  }, [monthsTime, selectValue])

  const getPage = () => {
    //获取表格列表
    setTableLoading(true)
    tableRef.current.style.display = 'none' //关闭表格单元格弹窗
    dispatch(getArrangeListPage(params))
      .then(unwrapResult)
      .then((res) => {
        setTableLoading(false)
        if (res && !Object.keys(res).length) {
          setPage({
            total: 0,
            items: [],
          })
          return
        }
        const data = {
          total: res.total,
          items: res,
        }
        setPage(data)
      })
      .catch(() => {
        setTableLoading(false)
      })
  }

  const getDoctorInfor = (t: any, date: string, num: number) => {
    //获取医生排班信息
    dispatch(getRankingListPage(params))
      .then(unwrapResult)
      .then((res) => {
        //获取班次列表
        setRankingList(res)
        setSpinLoading(false)
      })
    dispatch(
      getDoctorInfo({
        id: t.map[date]?.scheduleId == -1 ? null : t.map[date]?.scheduleId,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (res.shiftId > 0) {
          setRadioValue(res.shiftId)
          setSpinLoading(false)
        }
      })
  }

  const setRanking = (obj: any, num: number) => {
    //设置排班
    const data = {
      doctorId: obj.doctorId,
      id:
        obj.map[params.dateTime[num]]?.scheduleId < 0
          ? null
          : obj.map[params.dateTime[num]]?.scheduleId,
      scheduleDate: params.dateTime[num],
      shiftId: radioValue,
    }
    dispatch(addArrangeListPage(data))
      .then(unwrapResult)
      .then((res) => {
        getPage()
        setBtnLoading(false)
        notification.success({
          message: data.id == null ? '新增成功' : '编辑成功',
        })
      })
      .catch((err) => {
        setBtnLoading(false)
      })
  }

  const tableCellMouseEnter = (
    t: any,
    e: any,
    index: number,
    date: string,
    str: string
  ) => {
    //columns render事件
    if (str == '删除') {
      setTableLoading(true)
      dispatch(
        deleteDoctorInfo({
          //删除医生排班信息
          id: t.map[date]?.scheduleId,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          setParams({
            //获取列表参数
            dateTime: params.dateTime,
            stationCategory: selectValue,
          })
          notification.success({ message: '删除成功' })
        })
        .catch((err) => {
          setTableLoading(false)
        })
      return
    }
    setSpinLoading(true)
    const currentRef = tableRef.current
    const currentBtnRef = btnRef.current
    currentRef!.style.display = 'block' //设置/编辑白班弹窗显示
    setRankingData([t, index]) //新增/编辑医生班次信息
    getDoctorInfor(t, date, index) //获取医生排班信息
    setRadioValue(0) //设置/编辑白班弹窗默认不选
    // 设置弹窗位置
    currentRef.style.top = ''
    currentRef.style.bottom = ''
    currentRef.style.left = ''

    // 弹窗内按钮位置
    currentBtnRef.style.top = ''
    currentBtnRef.style.bottom = ''
    currentBtnRef.style.left = ''
    if (document.body.clientHeight - e.clientY - 300 < 0) {
      currentRef.style.top = e.clientY - 350 + 'px'
      currentRef.style.left = e.clientX - 350 + 'px'
      currentBtnRef.style.top = e.clientY - 40 + 'px' //按钮位置
      currentBtnRef.style.left = e.clientX - 270 + 'px'
    } else {
      currentRef.style.top = e.clientY - 35 + 'px'
      currentRef.style.left = e.clientX - 210 + 'px'
      currentBtnRef.style.top = e.clientY + 276 + 'px' //按钮位置
      currentBtnRef.style.left = e.clientX - 130 + 'px'
    }
    if (document.body.clientWidth - (e.clientX - 120) - 220 < 0) {
      currentRef.style.left = e.clientX - 150 - 220 + 'px'
      currentRef.style.top = e.clientY - 35 + 'px'
    }
  }

  const columnsRenderFn = (t: any, date: string, index: number) => {
    // columns render
    const temp = date.split('-') //判断只能编辑 修改 删除当前及以后的日期
    const current = moment()
      .subtract(0, 'months')
      .format('YYYY-MM-DD')
      .split('-')
    let forbid = false //以前日期是否置灰

    if (
      moment(date) > moment().add(3, 'year') ||
      moment(date) < moment().startOf('day')
      // parseInt(temp[0]) < parseInt(current[0]) ||
      // parseInt(temp[1]) < parseInt(current[1]) ||
      // (parseInt(temp[1]) == parseInt(current[1]) &&
      //   parseInt(temp[2]) < parseInt(current[2]))
    ) {
      forbid = true
    }
    return t.map[date]?.shiftName ? (
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <div
          key={index}
          className={
            forbid ? styles.tableCellTextForbidColor : styles.tableCellText
          }
        >
          {t.map[date]?.shiftName}
        </div>
        <div className={forbid ? styles.tableCellTextForbid : styles.editText}>
          {forbid ? (
            ''
          ) : (
            <>
              <div
                className={styles.tableCellIcon}
                onClick={(e) => tableCellMouseEnter(t, e, index, date, '删除')}
              >
                <DeleteOutlined />
              </div>
              <span
                className={styles.tableCellWrapText}
                onClick={(e) => tableCellMouseEnter(t, e, index, date, '修改')}
              >
                点击修改排班
              </span>
            </>
          )}
        </div>
      </div>
    ) : forbid ? (
      <div className={styles.tableCellTextForbid}></div>
    ) : (
      <div
        className={styles.setText}
        onClick={(e) => tableCellMouseEnter(t, e, index, date, '设置')}
      >
        <span>点击设置排班</span>
      </div>
    )
  }

  // 注意TdCell要提到DataTable作用域外声明
  const TdCell = (props: any) => {
    // onMouseEnter, onMouseLeave在数据量多的时候，会严重阻塞表格单元格渲染，严重影响性能
    const { rowSpan, colSpan, onMouseEnter, onMouseLeave, ...restProps } = props

    return <td {...restProps} />
  }

  useEffect(() => {
    if (props?.selectKey == 2) {
      if (monthsTime <= 0) {
        setBtnDisabled(false)
      } else {
        setBtnDisabled(true)
      }
    }
  }, [monthsTime, props?.selectKey])

  return (
    <>
      <div className={styles.main}>
        {/* 新增/编辑班次 */}
        <div ref={tableRef} className={styles.raidoWrap}>
          <Spin spinning={spinLoading}>
            <Row>
              <Radio.Group
                onChange={(e) => setRadioValue(e.target.value)}
                value={radioValue}
              >
                <Space direction='vertical'>
                  {rankingList &&
                    rankingList.map((item: any) => {
                      return (
                        <Radio
                          key={item.id}
                          value={item.id}
                        >{`${item.shiftName}  ${item.shiftStartTime} ~ ${item.shiftEndTime}`}</Radio>
                      )
                    })}
                </Space>
              </Radio.Group>
            </Row>
            <Row
              ref={btnRef}
              style={{
                position: 'fixed',
                paddingLeft: '45px',
                width: '210px',
                height: '50px',
                background: '#fff',
              }}
            >
              <Space>
                <Col>
                  <Button
                    onClick={() => (tableRef!.current!.style.display = 'none')}
                  >
                    取消
                  </Button>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    loading={btnLoading}
                    onClick={() => {
                      setBtnLoading(true)
                      setRanking(rankingData[0], rankingData[1])
                    }}
                  >
                    确认
                  </Button>
                </Col>
              </Space>
            </Row>
          </Spin>
        </div>
        <div style={{ marginTop: '10px' }}>
          <Row>
            <Space style={{ flex: 1 }}>
              <Button
                type='primary'
                onClick={() => {
                  setMonthsTime(0)
                }}
              >
                回到本月
              </Button>
              <div className='input-group' style={{ display: 'inline-flex' }}>
                {/* 左按钮 */}
                <Button
                  icon={<LeftOutlined />}
                  onClick={() => {
                    setMonthsTime(monthsTime + 1)
                  }}
                />
                {/* 日期显示 */}
                <div className={styles.weekTimeWrap}>
                  {moment().subtract(monthsTime, 'months').format('YYYY-MM')}
                </div>
                {/* 右按钮 */}
                <Button
                  icon={<RightOutlined />}
                  onClick={() => {
                    setMonthsTime(monthsTime - 1)
                  }}
                />
              </div>
              <Button
                type='primary'
                disabled={btnDisabled}
                onClick={() => {
                  setTitle('重置排班')
                  setIsModalVisible(true)
                }}
              >
                重置排班
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  setIsAutomaticSchedulingModalVisible(true)
                }}
              >
                自动排班设置
              </Button>
            </Space>
            <Space>
              <Select
                style={{ width: 160 }}
                value={selectValue}
                allowClear
                onChange={(value: number) => {
                  setSelectValue(value)
                }}
              >
                <Option value={1}>医生</Option>
                <Option value={0}>护士</Option>
              </Select>
            </Space>
          </Row>
        </div>
        <div className={styles.table} ref={tableWrapRef}>
          <EditableList
            className={styles.tableGroup}
            scroll={{ y: tableScrollY }}
            style={{ height: '100%' }}
            loading={tableLoading}
            rowKey={(registration: any) => registration.tenantId}
            components={{
              body: { cell: TdCell },
            }}
            pagination={false}
            page={{
              current: 0,
              size: 10,
              total: 0,
              items: page.items,
            }}
            bordered
            columns={columns}
          />
        </div>
        <ComposingModal
          title={title}
          visible={isModalVisible}
          onOk={(title: string) => {
            if (title == '重置排班') {
              const data = {
                startTime: params.dateTime[0],
                endTime: params.dateTime[params.dateTime.length - 1],
              }
              dispatch(resetArrangeListPage(data))
                .then(unwrapResult)
                .then((res) => {
                  getPage()
                  notification.success({ message: '重置成功' })
                })
            }
            setIsModalVisible(false)
          }}
          onCancel={() => {
            setIsModalVisible(false)
          }}
        />
        <AutomaticSchedulingModal
          visible={isAutomaticSchedulingModalVisible}
          onOk={() => {
            setIsAutomaticSchedulingModalVisible(false)
            getPage()
          }}
          onCancel={() => {
            setIsAutomaticSchedulingModalVisible(false)
            getPage()
          }}
        ></AutomaticSchedulingModal>
      </div>
    </>
  )
}
