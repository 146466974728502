import _ from 'lodash'
import moment from 'moment'
import { getAge } from '../utils/StringUtils'
import { DateTimeFormat } from './datetime'
import { getOrderCategoryName, getOtcCategoryName } from './material'

export function subPrintInvoiceFeeBillForWeb(data: any) {
    console.log(data);
    
  const allPage = data?.allPage
  const chunkedArray = data?.itemList?.materials

  let subContent = ''

  for (let i = 0; i < 1; i++) {
    const dataMian = { ...data, dataList: chunkedArray, indexs: i }
    subContent += SinglePrintInvoiceFeeBillForWeb(dataMian, i)
  }

  return subContent
}

export function SinglePrintInvoiceFeeBillForWeb(data: any, indexs: any) {
  // 拆分后的数组
  const chunkedArray = data?.chunkedArray
  const allPage = data?.allPage
  const tenantName = data?.tenantName
  const userName = data?.userName
  const recipeId = data?.recipeId
  const dataListMain = data?.dataList
  console.log(dataListMain);
  
  const patientMessage = data?.patientMessage
  const detailMessage = data?.detailMessage
  const payment = data?.payment
  const paymentInfo = data?.paymentInfo
  const paymentWay = data?.paymentInfo?.paymentDetailRecordVOS?.map(
    (item: any) => item?.payMethodName
  ).join(',')
  const doctorMessage = data?.doctorMessage
  const zlCount = data?.zlCount
  const ypCount = data?.ypCount
  const SignatureSrc = data?.doctorMessage?.userSign
    ? data?.doctorMessage?.userSign
    : ''
  const YSSignatureSrc = data?.doctorMessage?.physicianSign
    ? data?.doctorMessage?.physicianSign
    : ''
  const PYYSSignatureSrc = data?.doctorMessage?.pharmacistSign
    ? data?.doctorMessage?.pharmacistSign
    : ''
  const FHYSSignatureSrc = data?.doctorMessage?.reviewerPharmacistSign
    ? data?.doctorMessage?.reviewerPharmacistSign
    : ''
  const showFlagList = data?.showFlag
  // getUrl()
  

  const getNum = (key: any) => {
    switch (key) {
      case 0:
        return '1'
      case 1:
        return '2'
      case 2:
        return '4'
      case 3:
        return '3'
      default:
        return '1'
    }
  }

  // 电子签名
  let dzqm = ''
  if (SignatureSrc) {
    dzqm = '<img src=' + SignatureSrc + ' style="width:70px;height:30px;" />'
  }

  // 医师电子签名
  let ysdzqm = ''
  if (YSSignatureSrc) {
    ysdzqm =
      '<img src=' + YSSignatureSrc + ' style="width:50px;height:18px;" />'
  }

  // 配药药师电子签名
  let pyysdzqm = ''
  if (PYYSSignatureSrc) {
    pyysdzqm =
      '<img src=' + PYYSSignatureSrc + ' style="width:50px;height:18px;" />'
  }

  // 复核药师电子签名
  let fhysdzqm = ''
  if (FHYSSignatureSrc) {
    fhysdzqm =
      '<img src=' + FHYSSignatureSrc + ' style="width:50px;height:18px;" />'
  }

  // 病历号一维码
  let blhImg = ''
  if (showFlagList?.blhFlag == 'true') {
    if (detailMessage?.codeImg) {
      blhImg =
        '<img src=' +
        detailMessage?.codeImg +
        ' style="width:136px;height:48px;font-weight:bold;" />'
    }
  } else {
    blhImg = ''
  }

  let xyMain = `
    <div style="text-align: left;font-size: 16px;width:380px;float:left;line-height: 24px;border-bottom: 1px solid #000;margin-bottom: 6px;padding-bottom: 6px;">
        <div style="display:flex;flex-wrap: wrap;justify-content: space-between;width:380px;float:left;">
            <div style="display:flex;width:280px;">项目名称</div>
            <div style="display:flex;width:50px;">数量</div>
            <div style="display:flex;width:50px;">金额</div>
            <div style="display:flex;width:320px;">国家医保编码</div>
        </div>
    </div>`
  const getNewList = (dataList?: any) => {
    for (let i = 0; i < dataList?.length; i++) {
      for (let j = 0; j < dataList?.[i]?.length; j++) {
        dataList[i][j].sortStr =
          dataList[i][j]?.normalUsemethod +
          ',' +
          dataList[i][j]?.normalDose +
          ',' +
          dataList[i][j]?.doseUnit +
          ',' +
          dataList[i][j]?.normalFrequency
      }
      dataList[i]
      // dataList[i] = dataList[i].sort(
      //   (a: any, b: any) => a?.sortStr?.length - b?.sortStr.length
      // )
    }
    return dataList
  }

  for (let i = 0; i < dataListMain?.length; i++) {
    const dataMainList: any = dataListMain?.[i]?.itemList
    for (let j = 0; j < dataMainList?.length; j++) {
        xyMain += `
            <div style="text-align: left;font-size: 16px;width:500px;float:left;line-height: 24px;border-bottom: 1px dashed #000;padding-bottom: 6px;">
                <div style="display:flex;flex-wrap: wrap;justify-content: space-between;width:380px;float:left;">
                    <div style="display:flex;width:280px;">[${dataMainList[j]?.itemPayType ? getOrderCategoryName(dataMainList[j]?.itemPayType) : '自费'}]${dataMainList[j]?.name}</div>
                    <div style="display:flex;width:50px;">${dataMainList[j]?.drugCount}${dataMainList[j]?.splitTag ? dataMainList[j]?.splitUnit : dataMainList[j]?.accountUnit}</div>
                    <div style="display:flex;width:50px;">${dataMainList[j]?.drugAmount?.toFixed(2)}</div>
                    <div style="display:flex;width320px;font-size: 14px">${dataMainList[j]?.hilistCode}</div>
                </div>
            </div>
        `
    }
      // const a =
      //   ' <div style="text-align: left;font-size: 16px;width:500px;float:left;">' +
      //   '   <div style="display:flex;justify-content: space-between;width:380px;float:left;">' +
      //   '   <div style="display:flex;width:300px;">' +
      //   '<span style="font-weight:bold"> ' +
      //   (j == 0 ? Number(dataMainList[j]?.groupNumber) + '.  ' : '') +
      //   '</span>' +
      //   (j == 0
      //     ? '<span style="font-weight:bold"> ' +
      //       dataMainList[j]?.name +
      //       '</span>' +
      //       '<span style="width:6px"> ' +
      //       '</span>' +
      //       '<span > ' +
      //       dataMainList[j]?.packSpec +
      //       '</span>' +
      //       '<span style="width:6px"> ' +
      //       '</span>' +
      //       '<span style="marginLeft:10px"> ' +
      //       (dataMainList[j]?.skinResultinfo
      //         ? dataMainList[j]?.skinResultinfo
      //         : '') +
      //       '</span>' +
      //       '<span style="width:6px"> ' +
      //       '</span>' +
      //       (showFlagList?.zlFlag == 'true'
      //         ? '<span > ' +
      //           (dataMainList[j]?.cabineNo ? dataMainList[j]?.cabineNo : '') +
      //           '</span>'
      //         : '')
      //     : '<span style="font-weight:bold;margin-left:7px"> ' +
      //       dataMainList[j]?.name +
      //       '</span>' +
      //       '<span style="width:6px"> ' +
      //       '</span>' +
      //       '<span> ' +
      //       dataMainList[j]?.packSpec +
      //       '</span>' +
      //       '<span style="width:6px"> ' +
      //       '</span>' +
      //       '<span style="marginLeft:10px"> ' +
      //       (dataMainList[j]?.skinResultinfo
      //         ? dataMainList[j]?.skinResultinfo
      //         : '') +
      //       '</span>' +
      //       '<span style="width:6px"> ' +
      //       '</span>' +
      //       (showFlagList?.ghFlag == 'true'
      //         ? '<span > ' +
      //           (dataMainList[j]?.cabineNo ? dataMainList[j]?.cabineNo : '') +
      //           '</span>'
      //         : '')) +
      //   '</div>' + 
      //   (showFlagList?.bzFlag == 'true' && dataMainList[j]?.billingCategory != '01'
      //         ? '<span> ' + otcRemark + '</span>'
      //         : '') +
      //   '<span style="font-weight:bold"> ' +
      //   (showFlagList?.daysFlag == 'false'
      //     ? dataMainList[j]?.drugCount +
      //       (dataMainList[j]?.splitTag == 1
      //         ? dataMainList[j]?.splitUnit
      //         : dataMainList[j]?.accountUnit)
      //     : dataMainList[j]?.useDays +
      //       '天' +
      //       '/' +
      //       dataMainList[j]?.drugCount +
      //       (dataMainList[j]?.splitTag == 1
      //         ? dataMainList[j]?.splitUnit
      //         : dataMainList[j]?.accountUnit)) +
      //   '</span>' +
      //   '</div>' +
      //   (dataMainList[j]?.sortStr == dataMainList[j + 1]?.sortStr &&
      //   dataMainList[j]?.sortStr
      //     ? ''
      //     : dataMainList[j]?.drugType != 1
      //     ? '   <div style="width:380px;margin-left:8px">' +
      //       '   <div style="display:flex;width:380px;float:left;">用法：' +
      //       '<span style="width:100px"> ' +
      //       dataMainList[j]?.normalUsemethod +
      //       '</span>' +
      //       '<span style="width:100px"> ' +
      //       '每次' +
      //       dataMainList[j]?.normalDose +
      //       dataMainList[j]?.doseUnit +
      //       '</span>' +
      //       '<span style="width:100px"> ' +
      //       dataMainList[j]?.normalFrequency +
      //       '</span>' +
      //       (showFlagList?.bzFlag == 'true'
      //         ? '<span style="width:100px"> ' + otcRemark + '</span>'
      //         : '') +
      //       '</div>' +
      //       (showFlagList?.cjFlag == 'true'
      //         ? '   <div style="display:flex;width:380px;float:left;">' +
      //           '<span style="width:236px">厂家： ' +
      //           dataMainList[j]?.tenantSupplierName +
      //           '</span>' +
      //           '<span style="width:100px">批号： ' +
      //           dataMainList[j]?.batchNo +
      //           '</span>' +
      //           '</div>'
      //         : '') +
      //       '</div>'
      //     : '') +
      //   '</div>'
    }
  // }
  // xyMain +=
  // ' <div style="font-size: 16px;width:380px;margin:10px 0;float:left;border-bottom:1px dashed #777777;">' +
  // ' </div>'

  const content =
    '<div style="width:424px;page-break-after:always;page-break-inside:always;page-break-before:always;">' +
    '   <div style="margin: 5px 0">' +
    '   <div style="display:flex ;position:relative">' +
    // '   <div style="display: flex;align-items: flex-end;width:110px;position:absolute;bottom:-6px">' +
    // blhImg +
    // ' </div>' +
    ' <div style="display: flex;flex-direction: column">' +
    ' <div style="text-align: center;float:left;width:380px;margin:0 10px;">' +
    '   <div style="padding: 10px 0 4px;font-size: 24px;"><span style="border-bottom:1px solid #000;">' +
    tenantName +
    '</span></div>' +
    ' </div>' +
    ' <div style="text-align: center;float:left;width:380px;margin:0 10px;">' +
    '   <div style="font-size: 24px;width:380px;text-align: center;"><span>门诊结算单</span></div>' +
    `<div style="display: flex;margin-left: 110px;font-size: 16px;padding-top: 4px"><span>${paymentInfo?.payment?.insuranceCodemc}</span><span style="padding-left: 10px">居民（${getAge(patientMessage?.ageYear, patientMessage?.ageMonth, '岁')}）</span></div>` +
    ' </div>' +
    ' </div>' +
    ' </div>' +
    // ' <div style="text-align: left;font-size: 16px;width:380px;margin:4px 20px  0 24px;float:left;"><span>NO : </span>' +
    // recipeId +
    // '</div>' +

    `   
        <div style="display:flex;font-size: 16px;width:380px;margin:8px 10px 0;
        float:left;border-top:1px solid #000;padding-top: 8px">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>病</span><span>历</span><span>号</span>
                    </div>：
                    <span>${detailMessage?.patientId}</span>
                </div>
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>患</span><span>友</span><span>姓</span><span>名</span>
                    </div>：
                    <span>${detailMessage?.patientName}</span>
                </div>
            </div>
            <div style="flex: 1;">
                <div style="display: flex;align-items: flex-end;width:136px;height: 48px">
                    ${blhImg}
                </div>
            </div>
        </div>
        <div style="display:flex;font-size: 16px;width:380px;margin:0 10px;float:left;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>就</span><span>诊</span><span>日</span><span>期</span>
                    </div>：
                    <span>${detailMessage?.createTime}</span>
                </div>
            </div>
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>科</span><span>室</span><span>名</span><span>称</span>
                    </div>：
                    <span>${detailMessage?.treatmentDepartmentName}</span>
                </div>
            </div>
        </div>` + (paymentInfo?.payment?.insuranceCode != 'ZIFEI' ? `
        <div style="display:flex;font-size: 16px;width:380px;margin:0 10px;float:left;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>医</span><span>生</span><span>姓</span><span>名</span>
                    </div>：
                    <span>${detailMessage?.treatmentDoctorName}</span>
                </div>
            </div>
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>总</span><span>金</span><span>额</span>
                    </div>：
                    <span>${Number(payment?.totalPayAmount || 0).toFixed(2)}</span>
                </div>
            </div>
        </div>
        <div style="display:flex;font-size: 16px;width:380px;margin:0 10px;float:left;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>账</span><span>户</span><span>支</span><span>付</span>
                    </div>：
                    <span>${Number(paymentInfo?.insuranceTrade?.gerenzhzc || 0).toFixed(2)}</span>
                </div>
            </div>
        </div>
        <div style="display:flex;font-size: 16px;width:380px;margin:0 10px;float:left;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>本</span><span>年</span><span>余</span><span>额</span>
                    </div>：
                    <span>${Number(paymentInfo?.insuranceTrade.dangnianye || 0).toFixed(2)}</span>
                </div>
            </div>
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>历</span><span>年</span><span>余</span><span>额</span>
                    </div>：
                    <span>${Number(paymentInfo?.insuranceTrade.linianye || 0).toFixed(2)}</span>
                </div>
            </div>
        </div>
        <div style="display:flex;font-size: 16px;width:380px;margin:0 10px;float:left;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>医</span><span>保</span><span>基</span><span>金</span>
                    </div>：
                    <span>${Number(paymentInfo?.insuranceTrade.jijinzfze || 0).toFixed(2)}</span>
                </div>
            </div>
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>个</span><span>人</span><span>现</span><span>金</span>
                    </div>：
                    <span>${Number(paymentInfo?.insuranceTrade.gerenxjzc || 0).toFixed(2)}</span>
                </div>
            </div>
        </div>
        <div style="display:flex;font-size: 16px;width:380px;margin:0 10px;float:left;border-bottom:1px solid #000;margin-bottom: 6px;padding-bottom: 6px;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:75px;display: flex;justify-content: space-between;">
                        <span>本</span><span>次</span><span>起</span><span>付</span><span>线</span>
                    </div>&nbsp;：
                    <span>${Number(paymentInfo?.insuranceTrade.benciqfx || 0).toFixed(2)}</span>
                </div>
            </div>
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width: 105px;display: flex;justify-content: space-between;margin-left: -30px;">
                        <span>此</span><span>前</span><span>起</span><span>付</span><span>线</span>
                        <span>累</span><span>计</span>
                    </div>&nbsp;：
                    <span>${Number(paymentInfo?.insuranceTrade.dnqfxljPt || 0).toFixed(2)}</span>
                </div>
            </div>
        </div>` : `
        <div style="display:flex;font-size: 16px;width:380px;margin:0 10px;float:left;border-bottom:1px solid #000;margin-bottom: 6px;padding-bottom: 6px;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>医</span><span>生</span><span>姓</span><span>名</span>
                    </div>：
                    <span>${detailMessage?.treatmentDoctorName}</span>
                </div>
            </div>
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>总</span><span>金</span><span>额</span>
                    </div>：
                    <span>${Number(payment?.totalPayAmount || 0).toFixed(2)}</span>
                </div>
            </div>
        </div>
        `) + `
        <div style="margin: 10px;font-size: 16px;width:380px;">
            <div>实收现金：${payment?.realPayAmount?.toFixed(2)}</div>
            ` + (paymentInfo?.payment?.insuranceCode != 'ZIFEI' ? `
            <div>本次起付线：${Number(paymentInfo?.insuranceTrade.shijizfqfx || 0).toFixed(2)}</div>
            <div>乙类先行自付：${Number(paymentInfo?.insuranceTrade?.xianxingzfje || 0).toFixed(2)}</div>
            <div>超限先行自付：${Number(paymentInfo?.insuranceTrade?.chaoxianjzffy || 0).toFixed(2)}</div>
            <div>
                <span>自付：0.00</span><span style="padding-left: 20px">自费：${Number(paymentInfo?.insuranceTrade?.cashAmount || 0).toFixed(2)}</span>
            </div>
            <div>个人账户支付：${Number(paymentInfo?.insuranceTrade?.gerenzhzc || 0).toFixed(2)}</div>
            <div>近亲属账户支付：${Number(paymentInfo?.insuranceTrade.gerenzhgjzfje || 0).toFixed(2)}</div>
            <div>统筹基金：${Number(paymentInfo?.insuranceTrade.jibenylbxtcjjzc || 0).toFixed(2)}</div>
            <div>大病保险基金：${Number(paymentInfo?.insuranceTrade.jumindbbxjjzc || 0).toFixed(2)}</div>
            <div>生育基金：0.00</div>
            <div>长保险基金：0.00</div>
            <div>离休（保健）基金：0.00</div>
            <div>商保支付：0.00</div>
            <div>其他补：${Number(paymentInfo?.insuranceTrade.qitazc || 0).toFixed(2)}</div>
            ` : '') + `
        </div>
        <div style="margin: 6px 10px;width:380px;border-bottom: 1px solid #000"></div>
    `
    +
    // ' <div style="font-size: 16px;width:380px;height:6px;float:left;margin:0 10px;border-bottom:1px dashed #777777;">' +
    // ' </div>' +
    // ' <div style="text-align: left;font-size: 14px;width:380px;margin:0px 20px 0px 24px;font-weight:bold;float:left;">Rp.</div>' +
    ' <div style="width:380px;min-height:400px;margin:0 10px;float:left;">' +
    ' <div style="text-align: left;float:left;width:380px;display:flex;flex-wrap:wrap;flex-basic:230px;flex-shrink: 0">' +
    xyMain +
    '</div>' +
    `
        <div style="display:flex;font-size: 16px;width:380px;">
            <div style="flex: 1;border-top: 1px solid #000;padding-top: 6px">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>支</span><span>付</span><span>类</span><span>型</span>
                    </div>：
                    <span>${paymentWay}</span>
                </div>
            </div>
        </div>
        <div style="display:flex;font-size: 16px;width:380px;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>发</span><span>卡</span><span>银</span><span>行</span>
                    </div>：
                    <span></span>
                </div>
            </div>
        </div>
        <div style="display:flex;font-size: 16px;width:380px;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>支</span><span>付</span><span>码</span>
                    </div>：
                    <span></span>
                </div>
            </div>
        </div>
        <div style="display:flex;font-size: 16px;width:380px;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>批</span><span>次</span><span>号</span>
                    </div>：
                    <span></span>
                </div>
            </div>
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>参</span><span>考</span><span>号</span>
                    </div>：
                    <span></span>
                </div>
            </div>
        </div>
        <div style="display:flex;font-size: 16px;width:380px;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>日</span><span>期</span><span>时</span><span>间</span>
                    </div>：
                    <span>${detailMessage?.createTime}</span>
                </div>
            </div>
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>打</span><span>印</span><span>操</span><span>作</span>
                    </div>：
                    <span>${userName}</span>
                </div>
            </div>
        </div>
        <div style="display:flex;font-size: 16px;width:380px;">
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>支</span><span>付</span><span>金</span><span>额</span>
                    </div>：
                    <span>RMB</span>
                    <span style="padding-left: 10px">${payment?.realPayAmount?.toFixed(2)}</span>
                </div>
            </div>
            <div style="flex: 1;">
                <div style="display: flex;line-height: 24px;">
                    <div style="width:70px;display: flex;justify-content: space-between;">
                        <span>收</span><span>费</span><span>员</span>
                    </div>：
                    <span>${detailMessage?.treatmentDoctorName}</span>
                </div>
            </div>
        </div>
    `
    +
    (data.countyCode == '330182' ? 
      `<div style="font-size: 16px;width:380px;border-top: 1px dashed #000;padding: 8px 0;margin: 6px 0 8px;padding-left: 20px">
            <div>1.“浙里办”APP，搜索“浙里办票”查看及打印</div>
            <div>2.支付宝或微信，搜索“浙江票据”-浙里办票</div>
            <div>3.通过浙江省财政电子票据查验网站:</div>
            <div>https://dzpj.zjzwfw.gov.cn 进行查验</div>
            ${data?.codeImg}
        </div>` : ''
    )
//   content +=
//     Number(indexs) + 1 == allPage
//       ? '   <div style="font-size: 10px;width:380px;display:flex;justify-content:center;float:left;margin-top:5px">----- 以下处方完毕-----</div>' +
//         '</div>'
//       : '   <div style="font-size: 10px;width:380px;display:flex;justify-content:center;float:left;margin-top:5px">-----接下页-----</div>' +
//         '</div>'
//   content +=
//     showFlagList?.jeFlag == 'true'
//       ? ' <div style="text-align: left;font-size: 16px;width:380px;margin:20px 20px 0 24px;float:left">' +
//         '药费：' +
//         ('¥ ' + ypCount) +
//         ' , ' +
//         '诊疗费：' +
//         ('¥ ' + zlCount) +
//         ' </div>'
//       : ' <div style="text-align: left;font-size: 16px;width:380px;margin:20px 20px 0 24px;display:flex">' +
//         ' <div style="font-size: 16px;height:12px;">' +
//         '药费：' +
//         ' </div>' +
//         ' <div style="font-size: 16px;width:70px;height:12px;margin:0 10px;border-bottom:1px solid #333;">' +
//         ' </div>' +
//         ' , ' +
//         ' <div style="font-size: 16px;height:12px;">' +
//         '诊疗费：' +
//         ' </div>' +
//         ' <div style="font-size: 16px;width:70px;height:12px;margin:0 10px;border-bottom:1px solid #333;">' +
//         ' </div>' +
//         ' </div>'
//   content +=
//     ' <div style="font-size: 16px;margin: 0 24px;float:left;width:380px;height:6px;border-bottom:1px dashed #777777;">' +
//     ' </div>' +
//     ' <div style="text-align: left;float:left;font-size: 16px;width:380px;margin:10px 20px 0 24px;display:flex;">' +
//     ' <div style="display: flex;font-size: 16px;width:110px;margin-right:5px">' +
//     '   <div style="width:40px;">医师：</div>' +
//     (doctorMessage?.physicianType != 2
//       ? '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
//         (doctorMessage?.physicianSignType == 1
//           ? ysdzqm
//           : doctorMessage?.physicianName) +
//         '</div>'
//       : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
//         '</div>') +
//     '</div>' +
//     ' <div style="display: flex;font-size: 16px;width:110px;margin-right:5px">' +
//     '   <div style="width:70px;">配药药师：</div>' +
//     (doctorMessage?.pharmacistType != 2
//       ? '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
//         (doctorMessage?.pharmacistSignType == 1
//           ? pyysdzqm
//           : doctorMessage?.pharmacistName) +
//         '</div>'
//       : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
//         '</div>') +
//     '</div>' +
//     ' <div style="display: flex;font-size: 16px;width:110px;margin-right:5px">' +
//     '   <div style="width:70px;">复核药师：</div>' +
//     (doctorMessage?.reviewerPharmacistType != 2
//       ? '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
//         (doctorMessage?.reviewerPharmacistSignType == 1
//           ? fhysdzqm
//           : doctorMessage?.reviewerPharmacistName) +
//         '</div>'
//       : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
//         '</div>') +
//     '</div>' +
//     ' <div style="display: flex;font-size: 16px;width:110px;margin-right:5px">' +
//     '   <div style="width:40px;">签名：</div>' +
//     (dzqm && doctorMessage?.signatureType != 2
//       ? '   <div style="flex:1;height:16px;">' + dzqm + '</div>'
//       : '   <div style="flex:1;border-bottom:1px solid #000;height:16px;">' +
//         '</div>') +
//     '</div>' +
//     ' </div>' +
//     (dzqm && doctorMessage?.signatureType != 2
//       ? ' <div style="text-align: left;float:left;font-size: 16px;width:380px;margin:20px 20px 0 20px">'
//       : ' <div style="text-align: left;float:left;font-size: 16px;width:380px;margin:6px 20px 0 20px">') +
//     '   <div style="width:380px;color:#777777">666除主诊医师特别注明外,处方仅当日有效。按卫生部规定,药房药品一经发出,不得退换。</div>' +
//     ' </div>' +
    // ' <div style="text-align: center;font-size: 14px;width:380px;margin:0 10px;float:left;">' +
    // '   <div style="padding: 10px 0;font-size: 14px;"><span>第' +
    // (Number(pageSize) + 1) +
    // '页/共' +
    // allPage +
    // '页</span></div>' +
    // ' </div>' +
    // '</div>' +
    // '</div>'

  return content
}