/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-29 11:11:19
 * @LastEditors: linxi
 * @LastEditTime: 2023-12-05 10:16:02
 */
import { Button, Checkbox, Col, Modal, notification, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import styles from './appointment.module.css'
import { AppointmentLists } from './list/appointmentList'
import { AppointmentDay } from './day'
import { AppointmentMonth } from './month'
import { PlusOutlined, SettingOutlined } from '@ant-design/icons'
import { SettingAppointment } from './settingAppointment'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAppointmentTabBar,
  selectSettingVisibile,
  setAppointmentTabBar,
  setSettingVisibile,
} from './appointmentSelice'
import { RootDispatch } from '../../app/store'
import { traceRoute } from '../../layouts/layoutSlice'
import { AppointmentWeek } from './week'
import { useHistory, useLocation } from 'react-router-dom'
import {
  selectEdition,
  selectStationCategory,
} from '../../app/applicationSlice'
import { AppointmentRegister } from '../registration/register/appointmentRegister'
import { AddPatientModal } from '../A-toothModule/toothHome/modal/addPatientModal'
import { SubscribeModal } from '../A-toothModule/toothHome/modal/subscribeModal'
import { RegisteredModal } from '../A-toothModule/toothHome/modal/registeredModal'

export const AppointmentTabBar = () => {
  const location: any = useLocation()

  const history = useHistory()

  const dispatch = useDispatch<RootDispatch>()

  const visibility = useSelector(selectSettingVisibile)

  // const [activeId, setActiveId] = useState('1')

  const stationCategory = useSelector(selectStationCategory)

  const [onlyThisAccount, setOnlyThisAccount] = useState(false)

  const activeId = useSelector(selectAppointmentTabBar)

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const [toothEditAppointmentMoal, setToothEditAppointmentMoal] = useState(
    false
  ) //  修改预约弹窗

  const [addPatientModal, setAddPatientModal] = useState(false) // 新增患者弹窗

  const [subscribeModal, setSubscribeModal] = useState(false) // 工作站-预约弹窗

  const [registeredModal, setRegisteredModal] = useState(false) //工作站-转诊弹窗

  const [modalPatientID, setModalPatientID] = useState('') //弹窗 患者ID

  const storageParams = JSON.parse(
    sessionStorage.getItem(location.pathname) || '{}'
  )

  const flag: any =
    localStorage.getItem('onlyThisAccount') == 'true' ? true : false

  useEffect(() => {
    setOnlyThisAccount(flag)
  }, [flag])

  useEffect(() => {
    dispatch(
      setAppointmentTabBar(
        location?.state?.activeId ||
          location?.state?.selectKey ||
          storageParams.tabBar ||
          '1'
      )
    )
  }, [location?.state?.selectKey])

  return (
    <Col className={styles.container}>
      <TabBar
        activeKey={activeId}
        onChange={(v) => {
          dispatch(setAppointmentTabBar(v))
        }}
        // destroyInactiveTabPane
      >
        <TabPane key='1' tab='日 ' style={{ marginTop: 4 }}>
          <AppointmentDay
            activeKey={activeId}
            onlyThisAccount={onlyThisAccount}
          />
        </TabPane>
        <TabPane key='2' tab='周 ' style={{ marginTop: 10 }}>
          <AppointmentWeek
            activeKey={activeId}
            onlyThisAccount={onlyThisAccount || false}
          />
        </TabPane>
        <TabPane key='3' tab='月' style={{ marginTop: 10 }}>
          <AppointmentMonth
            activeKey={activeId}
            onlyThisAccount={onlyThisAccount || false}
          />
        </TabPane>
        <TabPane key='4' tab='列表'>
          <AppointmentLists
            activeKey={activeId}
            // onlyThisAccount={onlyThisAccount || false}
          />
        </TabPane>
      </TabBar>
      <Space className={styles.setting}>
        {(stationCategory == 1 && activeId != '4' )&& (
          <Checkbox
            checked={onlyThisAccount}
            onChange={(e) => {
              setOnlyThisAccount(e.target.checked)
              localStorage.setItem(
                'onlyThisAccount',
                JSON.stringify(e.target.checked)
              )
            }}
          >
            只显示本账号
          </Checkbox>
        )}
        <Button
          icon={<SettingOutlined />}
          type='primary'
          ghost
          onClick={() => {
            dispatch(setSettingVisibile(true))
          }}
        >
          设置
        </Button>
        <Button
          icon={<PlusOutlined />}
          type='primary'
          onClick={() => {
            // dispatch(
            //   traceRoute({
            //     name: "新增预约",
            //     path: "/addEditAppointment",
            //     state: {
            //       id: 0
            //     },
            //   })
            // )
            if (hisVersion == 1) {
              setToothEditAppointmentMoal(true)
            } else {
              history.push({
                pathname: '/addEditAppointment',
                state: {
                  id: 0,
                  activeKey: activeId,
                },
              })
            }
          }}
        >
          新增预约
        </Button>
      </Space>
      <SettingAppointment
        onOk={() => {
          dispatch(setSettingVisibile(false))
        }}
        visible={visibility}
        onCancel={() => {
          dispatch(setSettingVisibile(false))
        }}
      />

      {/* his 口腔版修改预约 */}
      <Modal
        title='新增预约'
        visible={toothEditAppointmentMoal}
        onCancel={() => {
          setToothEditAppointmentMoal(false)
        }}
        width={1200}
        bodyStyle={{
          height: 650,
          overflow: 'auto',
        }}
        footer={null}
        destroyOnClose
      >
        <div style={{ height: 600 }}>
          <AppointmentRegister
            status={1}
            onsubmit={() => {
              setToothEditAppointmentMoal(false)
            }}
            onCancel={() => {
              setToothEditAppointmentMoal(false)
            }}
            addPatient={() => {
              setToothEditAppointmentMoal(false)
              setAddPatientModal(true)
            }}
          />
        </div>
      </Modal>
      {/* 新增患者弹窗 */}
      <AddPatientModal
        visible={addPatientModal}
        onOk={(num, patientId) => {
          setModalPatientID(patientId || '')
          if (num == 2) {
            setRegisteredModal(true)
          } else if (num == 3) {
            setSubscribeModal(true)
          }
          setAddPatientModal(false)
        }}
        onCancel={() => {
          setAddPatientModal(false)
        }}
      />
      {/* 工作站-预约 */}
      <SubscribeModal
        visible={subscribeModal}
        patientId={modalPatientID} //患者 ID
        treatmentDoctorId={''} //就诊医生 ID
        onOk={() => {
          // notification.success({
          //   message: '新增成功，请刷新页面'
          // })
          setSubscribeModal(false)
        }}
        onCancel={() => setSubscribeModal(false)}
      />

      {/* 工作站-挂号 */}
      <RegisteredModal
        title={0} // 0 挂号1 转诊
        patientId={modalPatientID} //新增患者-保存并挂号
        visible={registeredModal}
        onOk={() => {
          setRegisteredModal(false)
        }}
        onCancel={() => setRegisteredModal(false)}
      />
    </Col>
  )
}
