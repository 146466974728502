/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-03 20:22:17
 * @LastEditors: linxi
 * @LastEditTime: 2023-09-13 16:57:29
 */
import { Modal } from 'antd';
import React from 'react';
import { PaymentResult } from '../../../payment/result/PaymentResult';
import styles from './modal.module.css'


interface SubscribeModalProps {
  tablePaymentId: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
}
export const ChargeDetailModal = ({
  tablePaymentId,
  visible,
  onOk,
  onCancel,
}: SubscribeModalProps) => {
  return <Modal
    title='详情'
    width={1200}
    visible={visible}
    onCancel={onCancel}
    onOk={onOk}
    footer={null}
    className={styles.detailModal}
  >
    <PaymentResult
      proPaymentId={tablePaymentId}
      proStatus={8}
      onCancel={onCancel}
    />

  </Modal>

};

