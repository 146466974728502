/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-29 11:21:05
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-31 14:50:58
 */
import { SearchOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Col, Dropdown, Input, Menu, Modal, notification, Radio, Row, Select, Space } from 'antd';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch } from '../../../app/store';
import { WarningIcon } from '../../../compnents/icons/Icons';
import { Label } from '../../../compnents/widgets/Label';
import { getMaritalStatusName, gitTitleList } from '../../../models/patient';
import { getSourceTypeName } from '../../../models/registration';
import { getGenderName } from '../../../models/user';
import { ThemeContext } from '../../../theme/ThemeContext';
import { getTriageHistorylist } from '../../commonTriageDetail/commonTriageDetailSlice';
import { getPatientList, getPatientPageInfo, selectPatients } from '../../registration/register/registerSlice';
import styles from './mergePatientModal.module.css'
import { mergePatientAsync } from './patientListSlice';

interface MergePatientModalProps {
  visible?: boolean
  patientId: string
  onOk?: () => void
  onCancel?: () => void
  type: number // 1通用版 2 口腔专业版
}

export const MergePatientModal = ({
  onOk,
  onCancel,
  patientId,
  visible,
  type
}: MergePatientModalProps): ReactElement => {
  const theme = useContext(ThemeContext)

  const { Group } = Radio

  const dispatch = useDispatch<RootDispatch>()

  const patients = useSelector(selectPatients)

  const { Option } = Select

  const [currentPtient, setCurrentPtient] = useState<any>({})

  const [targetPtient, setTargetPtient] = useState<any>({})

  const [selectPatient, setSelectPatient] = useState<any>({})

  const [oldId, setOldId] = useState('')

  const [loading, setLoading] = useState(false)

  const titleList = useMemo(() => {
    return gitTitleList(type)
  }, [type]) 

  const getCurrentPtientName = (v: string) => {
    if (v == 'sex') {
      return getGenderName(currentPtient[v])
    } else if (v == 'maritalStatus') {
      return getMaritalStatusName(currentPtient[v])
    } else if (v == 'source') {
      return getSourceTypeName(currentPtient[v])
    } else {
      return currentPtient[v]
    }
  }

  const getTargetPtientName = (v: string) => {
    if (v == 'sex') {
      return getGenderName(targetPtient[v])
    } else if (v == 'maritalStatus') {
      return getMaritalStatusName(targetPtient[v])
    } else if (v == 'source') {
      return getSourceTypeName(targetPtient[v])
    } else {
      return targetPtient[v]
    }
  }

  useEffect(() => {
    if (visible && patientId) {
      dispatch(getPatientPageInfo(patientId)).then(unwrapResult).then((res: any) => {
        setCurrentPtient(res)
        setSelectPatient(res)
      })
    }
  }, [visible, patientId])

  const close = () => {
    setLoading(false)
    setOldId('')
    setSelectPatient({})
    setTargetPtient({})
    onCancel && onCancel()
  }

  return (
    <Modal
      visible={visible}
      title='合并患者'
      width={1000}
      destroyOnClose
      okText='确认合并'
      style={{ overflow: 'hidden', padding: '0' }}
      bodyStyle={{ padding: '10px 15px 0 15px', maxHeight: 720, overflow: 'auto' }}
      onCancel={close}
      confirmLoading={loading}
      onOk={() => {
        setLoading(true)
        dispatch(mergePatientAsync({
          ...selectPatient,
          id: patientId,
          oldId
        })).then(unwrapResult).then(() => {
          notification.success({
            message: "合并成功"
          })

          close()
          onOk && onOk()
        })
          .finally(() => setLoading(false))
      }}
      className={styles.settingModal}
    >
      <Row
        style={{
          height: '3rem',
          borderRadius: theme.br,
          backgroundColor: theme.pn,
          padding: '0 5px',
        }}
        align='middle'
        wrap={false}
        className={styles.bar}
      >
        <Col span={6} className={styles.title}>
          要合并的患者姓名
        </Col>
        <Col span={9} className={styles.item}>
          {currentPtient.name}
        </Col>
        <Col span={9}>
          <Select
            showSearch
            placeholder="请选择患者"
            optionFilterProp="children"
            style={{ width: '100%' }}
            onChange={(v) => {
              setOldId(v)
              dispatch(getPatientPageInfo(v)).then(unwrapResult).then((res: any) => {
                setTargetPtient(res)
                const obj: any = {}
                for (const key in selectPatient) {
                  if (selectPatient[key] && res[key]) {
                    obj[key] = selectPatient[key]
                  } else if (!selectPatient[key] && !res[key]) {
                    obj[key] = selectPatient[key]
                  } else {
                    obj[key] = selectPatient[key] || res[key]
                  }
                }
                setSelectPatient(obj)
              })
            }}
            onSearch={(v) => {
              dispatch(getPatientList(v))

            }}
            filterOption={false}
          >
            {
              patients.length && patients.filter((v) => v.id != currentPtient.id)?.map((p: any) => (
                <Option key={p.id} value={p.id}>
                  <Space>
                    <span>{p.name}</span>
                    <span>{getGenderName(p.sex)}</span>
                    <span>{p.phone}</span>
                    <span>{p.id}</span>
                  </Space>
                </Option>

              ))
            }
          </Select>
        </Col>
      </Row>
      <div className={styles.container}>
        {
          titleList.map((v: any) => (
            <Row key={v.value} className={styles.row}>
              <Col span={6} className={styles.subTitle}>{v.label}</Col>
              <Col span={18} className={styles.subItem}>
                <Group name={v.value} style={{ width: '100%' }} onChange={(el: any) => {
                  setSelectPatient({ ...selectPatient, [el.target.name]: el.target.value })
                  // setSelectPatient({ ...selectPatient, el.taeget.name:el.value})
                }} value={selectPatient[v.value]}>
                  {/* 字段不显示的原因是group的fontSize为0 */}
                  <Row>
                    <Col span={12} className={styles.item}>
                      <Radio style={{ width: '100%', padding: '5px 0' }} value={currentPtient[v.value]}>
                        {getCurrentPtientName(v.value) || '-'}</Radio>
                    </Col>
                    {
                      !!Object.keys(targetPtient).length && (
                        targetPtient[v.value] == currentPtient[v.value] ? (
                          <Col span={12} className={styles.item}>
                            {/* <Radio style={{ width: '100%', padding: '5px 0' }} value={targetPtient[v.value]}> */}
                            {getTargetPtientName(v.value) || '-'}
                            {/* </Radio> */}
                          </Col>
                        ) : (

                          <Col span={12} className={styles.item}>
                            <Radio style={{ width: '100%', padding: '5px 0' }} value={targetPtient[v.value]}>
                            {getTargetPtientName(v.value) || '-'}</Radio>
                        </Col>
                          )
                      )
                    }
                  </Row>
                </Group>
              </Col>
            </Row>
          ))
        }
        <Row style={{
          marginTop: 10,
          color: '#F93535 '
        }}>
          <WarningIcon style={{ width: 18, height: 18 }} /> &ensp;
          {
            type == 1 ? (
              <> 合并后，患者的病历信息、报告信息、消费信息、预约信息、随访信息、手术记录将被按时间顺序整体合并。</>

            ) : (
              <> 合并后，患者的就诊信息、处置记录、收费信息、影像信息、病例信息、回访信息、咨询沟通、外加工、治疗计划将
                被按时间顺序整体合并；亲属关系整体合并。</>

            )
          }
        </Row>
        {/* <Row justify='end' style={{ padding: '10px  0 30px' }}>
          <Space>
            <Button onClick={close}>取消</Button>
            <Button type='primary' onClick={() => {
              setLoading(true)
              dispatch(mergePatientAsync({
                ...selectPatient,
                id: patientId,
                oldId
              })).then(unwrapResult).then(() => {
                notification.success({
                  message: "合并成功"
                })

                close()
                onOk && onOk()
              })
                .finally(() => setLoading(false))
            }} loading={loading} disabled={!oldId}>确认合并</Button>
          </Space>
        </Row> */}
      </div>
    </Modal>
  );
}
