/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
} from 'antd'
import cnchar from 'cnchar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserId } from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import { Department } from '../../models/department'
import { SetmealCategoris, SetmealCategoryName } from '../../models/setmeal'
import { User } from '../../models/user'
import { getStorage } from '../durgsystem/durgsystemSlice'
import { DepartmentDate, selectDepartData } from '../putstorage/putstorageSlice'
import {
  getAllUserName,
  getUserName,
  sleectUserData,
} from '../putstorelist/putstorelistSlice'
import styles from './Setmeal.module.css'
import {
  selectSetmealSelectId,
  selectSetmealDetail,
  submitsetmealAsync,
} from './setmealSlice'
import { pinyin } from 'pinyin-pro'
const { Option } = Select
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}

export const SetmealModal = (props: {
  isModalVisible: boolean
  onOk: () => void
  onCancel: () => void
  recipeId?: any
}) => {
  const [setmealTitle, setSetmealTitle] = useState('新增')

  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const SelectId = useSelector(selectSetmealSelectId)

  const [selectuseArea, setSelectuseArea] = useState(2)

  const setmealDetail: any = useSelector(selectSetmealDetail)

  const userId = useSelector(selectUserId)

  useEffect(() => {
    if (JSON.stringify(setmealDetail) !== '{}') {
      setSetmealTitle('修改')
      setSelectuseArea(setmealDetail.useArea)
      dispatch(DepartmentDate({ current: 1, size: 1000, status: 1 }))
      dispatch(getUserName(setmealDetail.useDepartmentId))
      form.setFieldsValue({
        name: setmealDetail.name,
        mnemonicCode: setmealDetail.mnemonicCode,
        category: setmealDetail.category,
        useArea: setmealDetail.useArea,
        useDepartmentId: setmealDetail.useDepartmentId,
        useDepartmentName: setmealDetail.useDepartmentName,
        useUserId: setmealDetail.useUserId?.toString(),
        useUserName: setmealDetail.useUserName,
        flag: setmealDetail.flag,
        sort: setmealDetail.sort > 0 ? setmealDetail.sort : '',
      })
    } else {
      setSetmealTitle('新增')
      form.setFieldsValue({
        flag: 0,
      })
    }
  }, [setmealDetail])

  useEffect(() => {
    dispatch(getStorage({ current: 1 }))
    dispatch(getAllUserName({ current: 1, size: 1000 }))
  }, [])

  const departmentData = useSelector(selectDepartData)

  const userData = useSelector(sleectUserData)

  return (
    <Modal
      title={props?.recipeId ? '设置为套餐' : setmealTitle + '套餐'}
      visible={props.isModalVisible}
      className={styles.setmealform}
      onOk={() => {
        props.onOk()
      }}
      onCancel={() => {
        form.resetFields()
        props.onCancel()
        setSelectuseArea(2)
      }}
      footer={null}
    >
      <Form
        autoComplete='off'
        {...layout}
        form={form}
        name='control-hooks'
        onFinish={(vs) => {
          const useDepartmentName =
            (departmentData.find(
              (d: any) => d.id === vs.useDepartmentId
            ) as any)?.name || ''
          const useUserName =
            (userData.find((u: any) => u.id === vs.useUserId) as any)?.name ||
            ''
          if (SelectId) {
            dispatch(
              submitsetmealAsync({
                ...vs,
                useDepartmentName,
                useUserName,
                id: SelectId,
                recipeId: props?.recipeId ? props?.recipeId : null,
              })
            )
              .then(unwrapResult)
              .then(() => {
                form.resetFields()
                props.onOk()
                setSelectuseArea(2)
                if (props?.recipeId) {
                  notification.success({
                    message: '设置成功',
                  })
                }
              })
          } else {
            dispatch(
              submitsetmealAsync({
                ...vs,
                useDepartmentName,
                useUserName,
                recipeId: props?.recipeId ? props?.recipeId : null,
              })
            )
              .then(unwrapResult)
              .then(() => {
                form.resetFields()
                props.onOk()
                setSelectuseArea(2)
                if (props?.recipeId) {
                  notification.success({
                    message: '设置成功',
                  })
                }
              })
          }
        }}
      >
        <Form.Item
          name='name'
          label='套餐名称'
          rules={[
            {
              required: true,
            },
            {
              max: 40,
              message: '最长40位!',
            },
          ]}
        >
          <Input
            autoFocus
            onInput={(e: any) => {
              form.setFieldsValue({
                mnemonicCode: pinyin(e.target.value, {
                  pattern: 'first',
                  toneType: 'none',
                  separator: '',
                }).toUpperCase(),
              })
            }}
          />
        </Form.Item>

        <Form.Item
          name='mnemonicCode'
          label='助记码'
          rules={[
            {
              required: true,
            },
            {
              max: 40,
              message: '最长40位!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='category'
          label='套餐类别'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled={Boolean(
              SelectId && JSON.stringify(setmealDetail) !== '{}'
            )}
          >
            {SetmealCategoris.map((v) => {
              return (
                <Option value={v} key={v}>
                  {SetmealCategoryName(v)}
                </Option>
              )
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name='useArea'
          label='使用范围'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            onSelect={(values: number) => {
              if (values === 1) {
                dispatch(DepartmentDate({ current: 1, size: 1000, status: 1 }))
                form.setFieldsValue({
                  useDepartmentId:
                    setmealDetail.useDepartmentId > 0
                      ? setmealDetail.useDepartmentId
                      : undefined,
                })
              }
              if (values === 0) {
                dispatch(getAllUserName({ current: 1, size: 1000 }))
                form.setFieldsValue({
                  useUserId: userId,
                })
              }
              setSelectuseArea(values as number)
            }}
          >
            <Option value={0}>个人</Option>
            <Option value={1}>部门</Option>
            <Option value={2}>全院</Option>
          </Select>
        </Form.Item>
        {selectuseArea === 1 ? (
          <Form.Item
            name='useDepartmentId'
            label='使用科室'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              allowClear
              onSelect={(key: any) => {
                form.setFieldsValue({
                  useUserId: undefined,
                })
              }}
            >
              {departmentData.map((v: Department) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        ) : (
          <></>
        )}

        {selectuseArea === 0 ? (
          <Form.Item
            name='useUserId'
            label='使用人员'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select allowClear disabled>
              {userData.map((v: User) => {
                return (
                  <Option value={v.id} key={v.id}>
                    {v.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        ) : (
          <></>
        )}

        <Form.Item name='flag' label='允许他人修改'>
          <Select defaultValue={0}>
            <Option value={0}>否</Option>
            <Option value={1}>是</Option>
          </Select>
        </Form.Item>
        <Form.Item name='sort' label='排序'>
          <InputNumber max={99999} min={1} style={{ width: '426px' }} />
        </Form.Item>
        <Row gutter={20}>
          <Col span={12}>
            <Button
              onClick={() => {
                form.resetFields()
                setSelectuseArea(2)
                props.onCancel()
              }}
              style={{ float: 'right' }}
            >
              取消
            </Button>
          </Col>
          <Col span={12}>
            <Button type='primary' htmlType='submit' style={{ float: 'left' }}>
              保存
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
