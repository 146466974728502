/*
 * @Descripttion: 
 * @version: 
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: K
 * @LastEditTime: 2022-07-18 15:53:20
 */
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export function confirmPayment(ids: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/prePay`,
    method: "POST",
    params: {
      ids,
    },
  };
}
export function getDrugListFn(paymentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/payment/getResubmissionsDetails`,
    method: 'GET',
    params: {
      paymentId,
    },
  }
}
