import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { Label } from '../../../compnents/widgets/Label'
import {
  getMaritalStatusName,
  MaritalStatus,
  Patient,
} from '../../../models/patient'
import { getSourceTypeName } from '../../../models/registration'
import { getGenderName } from '../../../models/user'
import { ThemeContext } from '../../../theme/ThemeContext'
import { getAge } from '../../../utils/StringUtils'
import { searchMedicalAsync } from '../../registration/register/registerSlice'
import { PatientForm } from '../modals/PatientForm'
import styles from '../modals/PatientForm.module.css'
import { savePatient } from '../patientSlice'

interface PatientDetailBasicProps {
  patient?: Patient
  onSave?: () => void
  style?: React.CSSProperties
}

export const PatientDetailBasic = ({
  patient,
  onSave,
  style,
}: PatientDetailBasicProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [basicForm] = Form.useForm()

  const [extraForm] = Form.useForm()

  const [baseInfoEdit, setBaseInfoEdit] = useState(false)

  useEffect(() => {
    if (patient) {
      basicForm.setFieldsValue({
        ...patient,
        area: [
          Number(patient?.provinceCode),
          Number(patient?.cityCode),
          Number(patient?.contryCode),
        ],
        ageMonth: (patient?.ageMonth || -1) < 0 ? 0 : patient?.ageMonth,
      })
      extraForm.setFieldsValue(patient)
    } else {
      basicForm.resetFields()
      extraForm.resetFields()
    }
  }, [patient])

  return (
    <Col
      style={{
        height: '100%',
        overflow: 'auto',
        padding: '10px 0',
        backgroundColor: theme.pn,
        borderRadius: `0 0 ${theme.br}px ${theme.br}px`,
        ...style,
      }}
    >
      <Label label='患者健康信息' style={{ marginLeft: 20 }} />

      <Divider style={{ margin: 0, marginBottom: 20, borderColor: theme.c3 }} />
      {baseInfoEdit ? (
        <PatientForm style={{ padding: '0px 20px' }} form={basicForm} />
      ) : (
        <Row className={styles.infoContainer}>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>患者姓名：</Col>
              <Col flex={1}>{patient?.name}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>性别：</Col>
              <Col flex={1}>{getGenderName(patient?.sex || 0)}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>年龄：</Col>
              <Col flex={1}>{getAge(patient?.ageYear, patient?.ageMonth)}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>身高：</Col>
              <Col flex={1}>{patient?.height}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>体重：</Col>
              <Col flex={1}>{patient?.weight}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>民族：</Col>
              <Col flex={1}>{patient?.nation}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>婚姻状况：</Col>
              <Col flex={1}>
                {getMaritalStatusName(patient?.maritalStatus || 0)}
              </Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>邮箱地址：</Col>
              <Col flex={1}>{patient?.emailAddress}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>微信号：</Col>
              <Col flex={1}>{patient?.wechat}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>联系方式：</Col>
              <Col flex={1}>{patient?.phone}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>身份证号：</Col>
              <Col flex={1}>{patient?.idCardNumber}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>工作单位：</Col>
              <Col flex={1}>{patient?.companyName}</Col>
            </Row>
          </Col>
          <Col span={24} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>地址：</Col>
              <Col flex={1}>
                {patient?.provinceName +
                  patient?.cityName +
                  patient?.contryName +
                  patient?.address}
              </Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>紧急联系人：</Col>
              <Col flex={1}>{patient?.emergencyContactName}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>紧急联系人电话：</Col>
              <Col flex={1}>{patient?.emergencyContactPhone}</Col>
            </Row>
          </Col>
          <Col span={8} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>患者来源：</Col>
              <Col flex={1}>
                {getSourceTypeName(Number(patient?.source) || 0)}
              </Col>
            </Row>
          </Col>
          {patient?.specialDiseaseInformation && (
            <Col span={24} className={styles.infoCell}>
              <Row
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <Col className={styles.infoItem}>备案特病信息：</Col>
                <Col
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {patient?.specialDiseaseInformation}
                </Col>
              </Row>
            </Col>
          )}
          <Col span={24} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>备注：</Col>
              <Col style={{ wordWrap: 'break-word', width: 'calc(100% - 150px)' }}>{patient?.remark}</Col>
            </Row>
          </Col>
        
        </Row>
      )}
      <Label label='扩展信息' style={{ marginLeft: 20 }} />
      <Divider style={{ margin: 0, marginBottom: 20, borderColor: theme.c3 }} />
      {baseInfoEdit ? (
        <Form className={styles.form} colon={false} form={extraForm}>
          <Row style={{ marginTop: 10, marginLeft: 20, marginRight: 20 }}>
            <Col span={24} className={styles.fst}>
              <Form.Item
                label='个人史'
                name='personalHistory'
                rules={[{ max: 256, message: '最大256位!' }]}
              >
                <Input maxLength={256} />
              </Form.Item>
            </Col>
            <Col span={24} className={styles.fst}>
              <Form.Item
                label='家族史'
                name='familyHistory'
                rules={[{ max: 256, message: '最大256位!' }]}
              >
                <Input maxLength={256} />
              </Form.Item>
            </Col>
            <Col span={24} className={styles.fst}>
              <Form.Item
                label='疫苗接种史'
                name='vaccineHistory'
                rules={[{ max: 256, message: '最大256位!' }]}
              >
                <Input maxLength={256} />
              </Form.Item>
            </Col>
            {patient?.sex !== 1 && (
              <>
                <Col span={24} className={styles.fst}>
                  <Form.Item
                    label='月经史'
                    name='menstrualHistory'
                    rules={[{ max: 256, message: '最大256位!' }]}
                  >
                    <Input maxLength={256} />
                  </Form.Item>
                </Col>
                <Col span={24} className={styles.fst}>
                  <Form.Item
                    label='婚育史'
                    name='maritalHistory'
                    rules={[{ max: 256, message: '最大256位!' }]}
                  >
                    <Input maxLength={256} />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      ) : (
        <Row className={styles.infoContainer}>
          <Col span={24} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>个人史：</Col>
              <Col flex={1}>{patient?.personalHistory}</Col>
            </Row>
          </Col>
          <Col span={24} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>家族史：</Col>
              <Col flex={1}>{patient?.familyHistory}</Col>
            </Row>
          </Col>
          <Col span={24} className={styles.infoCell}>
            <Row>
              <Col className={styles.infoItem}>疫苗接种史：</Col>
              <Col flex={1}>{patient?.vaccineHistory}</Col>
            </Row>
          </Col>
          {patient?.sex !== 1 && (
            <>
              <Col span={24} className={styles.infoCell}>
                <Row>
                  <Col className={styles.infoItem}>月经史：</Col>
                  <Col flex={1}>{patient?.menstrualHistory}</Col>
                </Row>
              </Col>
              <Col span={24} className={styles.infoCell}>
                <Row>
                  <Col className={styles.infoItem}>婚育史：</Col>
                  <Col flex={1}>{patient?.maritalHistory}</Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      )}
      {/* <Row align="middle" justify="center">
        <Button
          type="primary"
          htmlType="submit"
          onClick={async () => {
            const basic = await basicForm.validateFields();
            const extra = await extraForm.validateFields();
            await dispatch(
              savePatient({
                patient: { ...basic, ...extra, id: patient?.id },
              })
            )
              .then(unwrapResult)
              .then(() => {
                notification.success({
                  message: "保存成功",
                  duration: 3,
                });

              });

            onSave && onSave();
          }}
        >
          保存
        </Button>
      </Row> */}
      <Row justify='center' style={{ paddingRight: 40 }}>
        {baseInfoEdit ? (
          <Space>
            <Button onClick={() => setBaseInfoEdit(false)}>取消</Button>
            <Button
              type='primary'
              htmlType='submit'
              onClick={async () => {
                const basic = await basicForm.validateFields()
                const extra = await extraForm.validateFields()
                await dispatch(
                  savePatient({
                    patient: {
                      ...basic,
                      ...extra,
                      id: patient?.id,
                      area: undefined,
                      provinceCode: basic?.area?.[0],
                      cityCode: basic?.area?.[1],
                      contryCode: basic?.area?.[2],
                    },
                  })
                )
                  .then(unwrapResult)
                  .then(() => {
                    notification.success({
                      message: '保存成功',
                      duration: 3,
                    })
                  })
                setBaseInfoEdit(false)
                onSave && onSave()
              }}
            >
              保存
            </Button>
          </Space>
        ) : (
          <Space>
            <Button type='primary' onClick={() => setBaseInfoEdit(true)}>
              修改
            </Button>
          </Space>
        )}
      </Row>
    </Col>
  )
}
