import { unwrapResult } from '@reduxjs/toolkit'
import { Checkbox, Form, Input, Modal, ModalProps, notification, Radio, Select } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { RootDispatch } from '../../app/store'
import {
  DepartmentCategories,
  DepartmentCategory,
  DepartmentSubCategories,
  getDepartmentCategoryName,
  getDepartmentSubCategories,
  getDepartmentSubCategoryName,
  getYBKBName,
  YBKBTYPE,
} from '../../models/department'
import { isValidMobileNumber } from '../../utils/StringUtils'
import { getFullDeparmentList } from '../completion/completionSlice'
import { setId } from '../putstorage/putstorageSlice'
import {
  createOrUpdateDepartment,
  getDepartmentDetail,
} from './departmentSlice'
import styles from './Modal.module.css'

interface DepartmentModalProps {
  departmentId?: string
}
export const DepartmentModal = ({
  departmentId,
  onCancel,
  onOk,
  ...others
}: DepartmentModalProps & ModalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const { tenantId } = useParams<{ tenantId: string | undefined }>()

  const [form] = Form.useForm()

  const [isSubcategoriesEditable, setIsSubCategoriesEditable] = useState(true)

  const [isLoading, setIsLoading] = useState(false)

  const [editData, setEditData] = useState<any>()

  useEffect(() => {
    if (departmentId) {
      dispatch(getDepartmentDetail({ departmentId, tenantId }))
        .then(unwrapResult)
        .then((d) => {
          setEditData(d)
          form.setFieldsValue(d)
          setIsSubCategoriesEditable(
            getDepartmentSubCategories(d.category).editable
          )
        })
    }
  }, [dispatch, departmentId])

  useEffect(() => {
    !others.visible && form.resetFields()
  }, [others.visible])

  return (
    <Modal
      {...others}
      title={`${departmentId ? '编辑' : '创建'}部门科室`}
      okText='确定'
      cancelText='取消'
      confirmLoading={isLoading}
      onCancel={(e) => {
        if (departmentId) {
          form.resetFields()
          setIsSubCategoriesEditable(true)
        }
        onCancel && onCancel(e)
      }}
      onOk={(e) => {
        form
          .validateFields()
          .then((values) => {
            dispatch(
              createOrUpdateDepartment({
                department: { ...values, id: departmentId },
                tenantId,
              })
            )
              .then(unwrapResult)
              .then((res: any) => {
                setIsLoading(false)
                form.resetFields()
                setIsSubCategoriesEditable(true)
                onOk && onOk(e)
              })
              .catch(() => {
                setIsLoading(false)
              })
          })
          .catch(() => {
            // do nothing
          })
      }}
    >
      <Form
        form={form}
        colon={false}
        autoComplete='off'
        initialValues={{
          category: DepartmentCategory.General,
          subCategory: getDepartmentSubCategories(DepartmentCategory.General)
            .categories,
        }}
      >
        <Form.Item
          name='code'
          label='科室代码'
          rules={[
            { required: true, message: '科室代码不能为空' },
            { max: 40, message: '最长40位!' },
          ]}
        >
          <Input autoFocus/>
        </Form.Item>
        <Form.Item
          name='ybKebie'
          label='医保科别'
          rules={[
            { required: true, message: '医保科别不能为空' },
            { max: 60, message: '最长60位!' },
          ]}
        >
          <Select
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {YBKBTYPE.map((v) => (
              <Select.Option key={v} value={v}>
                {getYBKBName(v)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='name'
          label='科室名称'
          rules={[
            { required: true, message: '科室名称不能为空' },
            { max: 60, message: '最长60位!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='resperName'
          label='科室负责人'
          rules={[{ required: true, message: '科室负责人不能为空' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='resperTel'
          label='负责人电话'
          rules={[
            { required: true, message: '负责人电话不能为空' },
            {
              validator: (_, value) => {
                if (value && !isValidMobileNumber(value)) {
                  return Promise.reject(new Error('请输入正确的手机号'))
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label='科室类型' className={styles.categories}>
          <Form.Item name='category'>
            <Radio.Group
              className={styles.column}
              onChange={(e) => {
                const subs = getDepartmentSubCategories(e.target.value)
                setIsSubCategoriesEditable(subs.editable)
                form.setFieldsValue({ subCategory: subs.categories })
              }}
            >
              {DepartmentCategories.map((c) => {
                const name = getDepartmentCategoryName(c)
                return (
                  <Radio key={name} value={c}>
                    {name}
                  </Radio>
                )
              })}
            </Radio.Group>
          </Form.Item>
          <Form.Item name='subCategory'>
            <Checkbox.Group
              className={styles.column}
              disabled={!isSubcategoriesEditable}
            >
              {DepartmentSubCategories.map((c) => {
                const name = getDepartmentSubCategoryName(c)
                return (
                  <Checkbox key={c} value={c}>
                    {name}
                  </Checkbox>
                )
              })}
            </Checkbox.Group>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  )
}
