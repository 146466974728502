/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-06-30 11:14:14
 * @LastEditors: linxi
 * @LastEditTime: 2023-10-18 10:02:04
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Cascader,
  Checkbox,
  Form,
  Input,
  Modal,
  ModalProps,
  notification,
  Radio,
  Select,
  TimePicker,
  Tree,
} from 'antd'
import { DataNode } from 'antd/lib/tree'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import styles from "./appointment.module.css"
import { appointmentSettingAsync, appointmentSettingDetailAsync, appointmentSettingDoctorsAsync, setIsUpdate } from './appointmentSelice'
interface ReplaceMakeSettingModalProps {
  visible?: boolean
  onOk?: () => void
  onCancel?: () => void
}
export const SettingAppointment = ({
  onOk,
  onCancel,
  visible,
}: ReplaceMakeSettingModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const { Option } = Select

  const [moretime, setMoretime] = useState(false)
  const [timeoutMin, setTimeoutMin] = useState('')

  const [workTime, setWorkTime] = useState<any>([moment('8:00', 'HH:mm'), moment('20:00', 'HH:mm')])

  const [settingID, setSettingID] = useState('')

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }

  const Rules = [
    {
      required: true,
    },
  ]
  interface Option {
    value: string | number;
    label?: React.ReactNode;
    disabled?: boolean;
    children?: Option[];
    // 标记是否为叶子节点，设置了 `loadData` 时有效
    // 设为 `false` 时会强制标记为父节点，即使当前节点没有 children，也会显示展开图标
    isLeaf?: boolean;
  }

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(['0-0']);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  // const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [treeData, setTreeData] = useState<any>([])

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue: any, e: any) => {
    setCheckedKeys(checkedKeysValue);
    // setSelectedKeys(e.checkedNodes)
  };

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    if (info.node.isLeaf) {
      let checked: any = []
      if (checkedKeys.includes(info.node.key)) {
        checked = checkedKeys.filter(v => v != info.node.key)
      } else {
        checked = Array.from(new Set([...checkedKeys, info.node.key]))
      }
      setCheckedKeys(checked)
    } else {
      let expanded: any = []
      if (expandedKeys.includes(info.node.key)) {
        expanded = expandedKeys.filter(v => v != info.node.key)
      } else {
        expanded = Array.from(new Set([...expandedKeys, info.node.key]))
      }
      setExpandedKeys(expanded);
    }
  };

  useEffect(() => {
    if (!visible) return
    dispatch(appointmentSettingDoctorsAsync()).then(unwrapResult).then((res: any) => {
      if (res) {
        // 树状图数据
        const tree = res.map((el: any) => ({
          title: el.departmentName,
          key: el.departmentName,
          children: el?.list?.map((v: any) => ({
            ...v,
            title: v.doctorName,
            key: v.doctorId,
            isLeaf: true
          }))
        }))
        const data = [
          {
            title: '全院',
            ket: '0-0',
            children: tree
          }
        ]
        // 默认选中
        const defaultSelectList: any = []
        res.forEach((el: any) => {
          el?.list?.forEach((v: any) => {
            if (v.status == 1) {
              defaultSelectList.push(v)
            }
          })
        })
        setCheckedKeys(defaultSelectList.map((v: any) => v.doctorId))
        // setSelectedKeys(defaultSelectList)
        setTreeData(data)
      }
    })
    dispatch(appointmentSettingDetailAsync()).then(unwrapResult).then((res: any) => {
      if (res && Object.keys(res).length) {
        setWorkTime([moment(res.businessStartTime, 'HH:mm'), moment(res.businessEndTime, 'HH:mm')])
        setSettingID(res.id)
        form.setFieldsValue({
          ...res,
          hiddenUnscheduledFlg: !!res.hiddenUnscheduledFlg,
          workTime: [moment(res.businessStartTime, 'HH:mm'), moment(res.businessEndTime, 'HH:mm')]
        })
        setMoretime(!!res.timeoutMin)
        setTimeoutMin(res.timeoutMin)
      }
    })
  }, [visible])

  return (
    // 代煎订单详情，只能修改地址
    <Modal
      visible={visible}
      title='预约设置'
      width={700}
      maskClosable={false}
      destroyOnClose
      style={{ overflow: 'hidden', padding: '0' }}
      bodyStyle={{ padding: '10px 15px 0 15px', minHeight: 500 }}
      onCancel={() => {
        onCancel && onCancel()
      }}
      onOk={() => {
        form.submit()
      }}
      className={styles.settingModal}
    >
      <Form
        form={form}
        {...layout}
        onFinish={(values) => {
          if (moretime && !timeoutMin) {
            notification.error({
              message: '请输入超时提示时间',
            })
            return
          }
          const allData = treeData[0].children
            .map((el: any) => el.children)
            .flat(Infinity)

          const doctorList = allData
            .filter((v: any) => checkedKeys.includes(v.doctorId))
            .map((el: any) => ({
              doctorAppoNum: el.doctorAppoNum,
              doctorId: el.doctorId,
            }))

          const data = {
            ...values,
            workTime: undefined,
            id: settingID || undefined,
            businessStartTime: moment(workTime[0]).format('HH:mm'),
            businessEndTime: moment(workTime[1]).format('HH:mm'),
            hiddenUnscheduledFlg: values.hiddenUnscheduledFlg ? 1 : 0,
            timeoutMin: timeoutMin || 0,
            list: doctorList,
          }
          dispatch(appointmentSettingAsync(data))
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: '设置成功',
              })
              dispatch(setIsUpdate(true))
              onCancel && onCancel()
            })
        }}
        initialValues={{
          workTime,
          hiddenUnscheduledFlg: true,
          maxAppoNum: '1',
          timeScale: '30',
        }}
      >
        <Form.Item name='workTime' label='营业时间' rules={Rules}>
          <TimePicker.RangePicker
            value={workTime}
            minuteStep={30}
            onChange={(v) => {
              if (!v) return
              setWorkTime(v)
            }}
            format='HH:mm'
          />
        </Form.Item>

        <Form.Item
          name='timeScale'
          label='时间刻度'
          rules={[
            {
              required: true,
              message: '请选择时间刻度',
            },
          ]}
        >
          <Radio.Group>
            <Radio value={15}> 15分钟 </Radio>
            <Radio value={30}> 30分钟 </Radio>
            {/* <Radio value="60"> 60分钟 </Radio> */}
          </Radio.Group>
        </Form.Item>
        <Form.Item name='maxAppoNum' label='人数限制' rules={Rules}>
          <span className={styles.limitPreson}>
            每个医生一个时段最多可预约
            {/* <Input addonAfter='人' /> */}
            <Form.Item
              name='maxAppoNum'
              noStyle
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: '请输入正确数字',
                },
              ]}
            >
              <Input addonAfter='人' />
            </Form.Item>
          </span>
        </Form.Item>
        <Form.Item
          name='hiddenUnscheduledFlg'
          label='显示设置'
          valuePropName='checked'
        >
          <Checkbox>不显示未排班医生</Checkbox>
        </Form.Item>
        <Form.Item name='' label='超时提示'>
          <div className={styles.moreTime}>
            <Checkbox
              checked={moretime}
              onChange={() => {
                setTimeoutMin('')
                setMoretime(!moretime)
              }}
            />
            {moretime && (
              <>
                <Input
                  value={timeoutMin}
                  width={40}
                  onChange={(e) => {
                    // const reg = /^99$|^(\d|[1-9]\d)$/
                    const reg = /^\d\.([1-9]{1,2}|[0-9][1-9])$|^[1-9]\d{0,1}(\.\d{1,2}){0,1}$|^99$/
                    if (!reg.test(e.target.value) && e.target.value) {
                      notification.error({
                        // message: '请输入数字'
                        message: '最大输入99分钟',
                      })
                      return
                    }
                    setTimeoutMin(e.target.value)
                  }}
                />
                分钟未到的超时提示
              </>
            )}
          </div>
        </Form.Item>
        <Form.Item
          label='预约咨询电话'
          name='adviceTel'
          rules={[
            {
              // pattern: new RegExp(
              // /^\d{3}-\d{8}|\d{4}-\d{7}|^(1[3,4,5,6,7,8,9][0-9]{9})$/
              // ),
              pattern: /^(?=[0-9-]{5,20})[0-9]*-?[0-9]*-?[0-9]*$/,
              message: '请输入正确的手机号',
            },
          ]}
        >
          <Input maxLength={13} style={{ width: 270 }} />
        </Form.Item>
        <Form.Item name='openDoctor' label='开放预约医生'>
          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            // selectedKeys={selectedKeys}
            treeData={treeData}
            titleRender={(e: any) => {
              return e.isLeaf ? (
                <div className={styles.treeInp}>
                  {e.title + '，号源数'}{' '}
                  <Input
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    value={e.doctorAppoNum}
                    placeholder='不限制'
                    onChange={(el) => {
                      // return
                      const reg = /^[1-9]\d*$/
                      if (!reg.test(el.target.value) && el.target.value) {
                        notification.error({
                          message: '请输入数字',
                        })
                        return
                      }
                      const tree = treeData[0]?.children?.map((v: any) => {
                        return {
                          ...v,
                          children: v?.children?.map((ey: any) => {
                            if (ey.doctorId === e.doctorId) {
                              return {
                                ...ey,
                                doctorAppoNum: el.target.value,
                              }
                            } else {
                              return ey
                            }
                          }),
                        }
                      })
                      const data = [
                        {
                          title: '全院',
                          ket: '0-0',
                          children: tree,
                        },
                      ]
                      setTreeData(data)
                    }}
                  />
                </div>
              ) : (
                <span>{e.title}</span>
              )
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}