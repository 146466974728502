import { API_PREFIX } from "./constant"
import { Request } from "./request"

export interface OpRecord {
  id: number
  mnemonicCode: string
  name: string
  state: number
  status: number
  tenantId: number
}

export interface OperatingRoomValue {
  current: number
  hitCount: boolean
  pages: number
  records: OpRecord[]
  searchCount: true
  size: number
  total: number
}
export interface OperatingRoomParams {
  current?: number
  size?: number
  name?: string
}

export function OperaingRoomList(params: OperatingRoomParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/operatingroom/page`,
    method: "GET",
    params: {
      ...params,
    },
  }
}

export interface ToggleOreratingRoomStateParams {
  id: string
}

export function ToggleOreratingRoomState(
  body: ToggleOreratingRoomStateParams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/operatingroom/logoutorenable`,
    method: "POST",
    body,
  }
}

export interface OperatingRoomSubmitParams {
  id: string
  name?: string
}

export function OperatingRoomSubmit(body: OperatingRoomSubmitParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/operatingroom/submit    `,
    method: "POST",
    body,
  }
}

export interface OperatingRoomDetailParams {
  id: string
}

export function OperatingRoomDetail(
  params: OperatingRoomDetailParams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/operatingroom/detail`,
    method: "GET",
    params,
  }
}
