import { PayMethod } from './payment'

export interface Refund {
  id: string
  treatmentId: string
  needPayAmount: number
  patientId: string
  patientName: string
  payMethod: any
  realPayAmount: number
  receivePayAmount: number
  refundTime: string
  returnPayAmount: number
  shouldPayAmount: number
  insuranceCode: string
  insurancePayAmount: number
  totalPayAmount: number
  paymentDetailRecordVOS?: any[]
}

export function fromJson(
  json: {
    id?: number
    treatmentId?: number
    patientId?: number
    paymentId?: number
  } & Record<string, never>
): Refund {
  return {
    // ...fromJson,
    id: json.id?.toString() || '',
    treatmentId: json.treatmentId?.toString() || '',
    needPayAmount: json.needPayAmount,
    patientId: json.patientId?.toString() || '',
    patientName: json.patientName,
    payMethod: json.payMethod || PayMethod.Cash,
    realPayAmount: json.realPayAmount,
    receivePayAmount: json.receivePayAmount,
    refundTime: json.refundTime,
    returnPayAmount: json.returnPayAmount,
    shouldPayAmount: json.shouldPayAmount,
    insuranceCode: json.insuranceCode,
    insurancePayAmount: json.insurancePayAmount,
    totalPayAmount: json.totalPayAmount,
    paymentDetailRecordVOS: json.paymentDetailRecordVOS,
  }
}
