/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-09-06 10:58:34
 */
export interface Disease {
  id: string
  name: string

  // 助记码
  mnemonicCode: string

  // icds
  icds: string

  // 空或者0:普病；1:特病；2:慢病
  category: number
  //1西医，2中医，3中医证候
  diagType: number

  tenantId: number

  source: number
}

export function fromJson(
  json: { id?: number } & Record<string, never>
): Disease {
  return {
    id: json.id?.toString() || '',
    name: json.name,
    mnemonicCode: json.mnemonicCode,
    icds: json.icds,
    category: json.category,
    diagType: json.diagType,
    tenantId: json.tenantId,
    source: json.source,
  }
}
