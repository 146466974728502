/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-06-01 14:21:05
 * @LastEditors: linxi
 * @LastEditTime: 2021-06-02 11:33:03
 */
export enum MintenanceStep {
    WGJC = 0,
    JS = 1,
    FS = 2,
    TF = 3,
    ZW = 4,
    JW = 5,
    JY = 6,
    SZ = 7,
    SX = 8,
    GZ = 9,
    LC = 10,
    CS = 11,
    BG = 12,
    FD = 13,
}

export const MintenanceStepS = [
    MintenanceStep.WGJC,
    MintenanceStep.JS,
    MintenanceStep.FS,
    MintenanceStep.TF,
    MintenanceStep.ZW,
    MintenanceStep.JW,
    MintenanceStep.JY,
    MintenanceStep.SZ,
    MintenanceStep.SX,
    MintenanceStep.GZ,
    MintenanceStep.LC,
    MintenanceStep.CS,
    MintenanceStep.BG,
    MintenanceStep.FD,
]

// 有外观检查 :1、加湿:2、翻晒:3、通风:4、增温:5、降温:6、降氧:7、熏蒸:8、筛选:9、干燥:10、冷藏:11、除湿:12、闭光:13、翻垛:14
export function getMintenanceStepName(mintenanceStep: MintenanceStep) {
    switch (mintenanceStep) {
        case 0:
            return "外观检查"
        case 1:
            return "加湿"
        case 2:
            return "翻晒"
        case 3:
            return "通风"
        case 4:
            return "增温"
        case 5:
            return "降温"
        case 6:
            return "降氧"
        case 7:
            return "熏蒸"
        case 8:
            return "筛选"
        case 9:
            return "干燥"
        case 10:
            return "冷藏"
        case 11:
            return "除湿"
        case 12:
            return "闭光"
        case 13:
            return "翻垛"
        default:
            break;
    }
}

// 质量状况(无异常:0、变质:1、过期:2、损坏:3、合格:4)
export enum QualityOfCommunity {
    WYC = 0,
    BZ = 1,
    GQ = 2,
    SH = 3,
    HG = 4,
}

export const QualityOfCommunities = [
    QualityOfCommunity.WYC,
    QualityOfCommunity.BZ,
    QualityOfCommunity.GQ,
    QualityOfCommunity.SH,
    QualityOfCommunity.HG,
]

export function getQualityOfCommunityName(qualityOfCommunity: QualityOfCommunity) {
    switch (qualityOfCommunity) {
        case 0:
            return "无异常"
        case 1:
            return "变质"
        case 2:
            return "过期"
        case 3:
            return "损坏"
        case 4:
            return "合格"
        default:
            break;
    }
}

// 可选项有正常销售:1、报损:2、停用:3
export enum MintenanceResult {
    ZC = 0,
    BS = 1,
    TY = 2,
}

export const MintenanceResults = [
    MintenanceResult.ZC,
    MintenanceResult.BS,
    MintenanceResult.TY,
]

export function getMintenanceResultName(mintenanceResult: MintenanceResult) {
    switch (mintenanceResult) {
        case 0:
            return "正常销售"
        case 1:
            return "报损"
        case 2:
            return "停用"
        default:
            break;
    }
}