import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    notification,
    Pagination,
    Row,
    Select,
    Space,
    Table,
} from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { DataType } from "../putstorage/putstorage";
import styles from "./Commodity.module.css";
import { unwrapResult } from "@reduxjs/toolkit";
import { RootDispatch } from "../../app/store";
import moment from "moment";
import {lastEditMaterialsOperationAsync, lastEditTreatmentOperationAsync, lastPaymentOperatioEditLog, lastRegistiongOrTreatmentOperatioEditLog} from "./commoditySlice";

export const CommodityLastEditModal = (props: {
    onOk: () => void;
    onCancel: () => void;
    visible: boolean;
    title: string;
    id: number;
    operateType:number;
}): ReactElement => {
    const [form] = Form.useForm();

    const dispatch = useDispatch<RootDispatch>();

    const [size, setSize] = useState(10);

    const [queries, setQueries] = useState<any>();

    const [data, setData] = useState<any>([])

    useEffect(() => {
        if (props.visible) {
            if (props.operateType === 2) {
                dispatch(lastEditTreatmentOperationAsync({ id: props?.id }))
                  .then(unwrapResult)
                  .then((res) => {
                      setData(res)
                  })
            } else if (props.operateType === 3) {
              dispatch(
                lastRegistiongOrTreatmentOperatioEditLog({ id: props?.id })
              )
                .then(unwrapResult)
                .then((res) => {
                  setData(res);
                });
            } else if (props.operateType === 4) {
              dispatch(lastPaymentOperatioEditLog({ id: props?.id }))
                .then(unwrapResult)
                .then((res) => {
                  setData(res);
                });
            } else {
              dispatch(lastEditMaterialsOperationAsync({ id: props?.id }))
                .then(unwrapResult)
                .then((res) => {
                  setData(res);
                });
            }
        }
    }, [props?.id, props.visible]);
    return (
        <Modal
            visible={props.visible}
            title={props.title}
            width={800}
            bodyStyle={{ padding: "10px 20px" }}
            onOk={() => {
                setData([])
                props.onOk()
            }}
            onCancel={() => {
                setData([])
                props.onOk()
            }}
            footer={null}
        >
            <div style={{ padding: "20px 0" }}>
                {
                    data?.map((v: any) => {
                        return (
                            <div className={styles.timeline} key={v?.id}>
                                <div className={styles.time}>
                                    <span>{v?.createTime}</span>
                                </div>
                                <div>
                                    {
                                        v?.operateAttr === 'CREATE' ? (
                                            <span>{v?.operateName} 于 {v?.createTime} 创建了目录。</span>
                                        ) : (
                                            <span>
                                                {v?.operateName} 于 {v?.createTime} 将 {v?.operateAttr} 由 {v?.beforeValue || '空'} 修改为 {v?.afterValue || '空'}。
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Row justify='end'>
                <Space>
                    <Button type='primary' onClick={() => {
                        setData([])
                        props.onOk()
                    }}>
                        确认
                    </Button>
                </Space>
            </Row>
        </Modal>
    );
};
