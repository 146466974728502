import { ThemeData } from "./ThemeContext";

export const DefaultTheme = {
  c1: "#027aff",
  c2: "#0238ff",
  c3: "#eef8ff",

  tc1: "#333333",
  tc2: "#666666",
  tc3: "#999999",

  tt: "#ffffff",
  bg: "#f4f8fb",
  pn: "#ffffff",
  wbd: "#eeeeee",
  th: "#f4f8fb",
  ds: "#f4f4f4",
  hic: "#182e4e",

  wsu: "#dddddd",

  s: "#1cd0bd",
  w: "#f5bb4a",
  e: "#ff5d5d",
  sl: "#d9f2ff",

  br: 10,
} as ThemeData;
