/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-11-15 16:01:42
 * @LastEditors: linxi
 * @LastEditTime: 2024-04-02 15:30:11
 */
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Col, Form, Input, Modal, notification, Row, Select, Space } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch } from '../../../../../app/store';
import { getGenderName } from '../../../../../models/user';
import { getPatientList, selectPatients, setPatients } from '../../../../registration/register/registerSlice';
import { addPatientrelationshipAsync } from '../../patientSlice';

interface EditRelationProps {
  visible: boolean
  patientId: string
  data: any[]
  onCancel: () => void
  onOk: () => void
}

const { Option } = Select;

export const EditRelation = ({
  visible,
  data,
  patientId,
  onCancel,
  onOk
}: EditRelationProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const patients = useSelector(selectPatients)

  const [form] = Form.useForm()

  const sights = ['本人', '配偶', '父子', '父女', '母子', '母女', '兄弟姐妹', '朋友', '其他'];

  useEffect(() => {
    if (!visible) return
    const list = data.length ? data.map(v => ({
      relationshipId: v.relationshipId,
      relationshipName: v.relationshipName,
      relationship: v.relationship,
      remark: v.remark,
    })) : [{
      relationshipId: '',
      relationshipName: '',
      relationship: '',
      remark: '',
    }]
    if (data.length) {
      const ids = Array.from(new Set(data.map(v => v.relationshipId)))
      const res = ids.map(v => data.find(el => el.relationshipId == v))
      const options: any = res.map((v: any) => {
        return {
          id: v.relationshipId,
          name: v.relationshipName,
          status: 0,
          sex: 0,
          phone: '',
          address: '',
          ageMonth: 0,
          ageYear: 0,
          birthday: '',
          companyName: '',
          idCardNumber: '',
          emergencyContactName: '',
          emergencyContactPhone: '',
          tenantPatientCardId: '',
          labelName: '',
          maritalStatus: 0,
          nation: '',
          emailAddress: '',
          remark: '',
        }
      })
      dispatch(setPatients(options))
    }
    form.setFieldsValue({
      friends: list
    })
  }, [data, visible])

  return (
    <Modal
      visible={visible}
      title='修改亲友关系'
      maskClosable={false}
      onCancel={() => {
        onCancel()
      }}
      width={1000}
      onOk={form.submit}
      destroyOnClose
    >
      <Row
        style={{
          marginBottom: 10,
        }}
      >
        <Col span={7} offset={2}>
          {' '}
          <span style={{ color: '#f00' }}>*</span> 患者姓名
        </Col>
        <Col span={4}>关系</Col>
        <Col span={10}>备注</Col>
      </Row>
      <Form
        form={form}
        requiredMark={false}
        onFinish={(vs) => {
          dispatch(
            addPatientrelationshipAsync({
              patientId,
              patientRelationships: vs.friends,
            })
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: '操作成功',
              })
              onOk()
            })
        }}
      >
        <Form.List name='friends'>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Space key={field.key} align='baseline'>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        label={'亲友' + (index + 1)}
                        name={[field.name, 'relationshipId']}
                        // rules={[{ required: true, message: '请选择患者' }]}
                      >
                        <Select
                          style={{
                            width: 260,
                          }}
                          showSearch
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          onSearch={(v) => {
                            dispatch(getPatientList(v))
                          }}
                          allowClear
                          onChange={(v) => {
                            const data = form.getFieldsValue().friends
                            const item = patients.find((el) => el.id == v)
                            form.setFieldsValue({
                              friends: data.map((el: any, i: number) =>
                                i == index
                                  ? {
                                      ...el,
                                      relationshipName: item?.name,
                                    }
                                  : el
                              ),
                            })
                          }}
                          onFocus={() => {
                            dispatch(getPatientList(''))
                          }}
                        >
                          {patients.length != 0 &&
                            patients.map((v) => (
                              <Select.Option key={v.id} value={v.id}>
                                <Space>
                                  <span>{v.name}</span>
                                  {/* <span>{getGenderName(v.sex)}</span>
                                  <span>{v.phone}</span>
                                  <span>{v.tenantPatientCardId}</span> */}
                                </Space>
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item {...field} name={[field.name, 'relationship']}>
                    <Select
                      allowClear
                      style={{
                        width: 140,
                      }}
                    >
                      {sights.map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item {...field} name={[field.name, 'remark']}>
                    <Input
                      style={{
                        width: 360,
                      }}
                    />
                  </Form.Item>
                  <Form.Item {...field} name={[field.name, 'relationshipName']}>
                    <Input type='hidden' />
                  </Form.Item>
                  {index == 0 ? (
                    <PlusCircleOutlined
                      onClick={() => {
                        if (fields.length < 10) {
                          add()
                        } else {
                          notification.info({
                            message: '最多添加10条',
                          })
                        }
                      }}
                    />
                  ) : (
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  )}
                </Space>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  )
}