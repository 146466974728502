/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-12-19 13:35:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-18 09:56:38
 */
import { Col, Tabs } from 'antd'
import React, { ReactElement, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TabBar } from '../../../compnents/widgets/TabBar'
import styles from './allReport.module.css'
import { WarehouseChangeReportLists } from './warehouseChangeReport/warehouseChangeReport'

export const AllReport = (props: { oneTab?: any }): ReactElement => {
  const [currentTab, setCurrentTab] = useState('0')

  const location: any = useLocation()

  const { TabPane } = Tabs

  return (
    // <Col className={styles.main}>
      <TabBar
        className={styles.tabs}
        activeKey={currentTab}
        onChange={(t) => setCurrentTab(t)}
      >
        <TabPane tab='库房变动统计' key='0'>
          <WarehouseChangeReportLists
            selectTabKey={currentTab}
            oneTabKey={props?.oneTab}
          ></WarehouseChangeReportLists>
        </TabPane>
      </TabBar>
    // </Col>
  )
}
