import { PlusOutlined } from "@ant-design/icons"
import { unwrapResult } from "@reduxjs/toolkit"
import { Button, Col, Form, Modal, notification } from "antd"
import moment from "moment"
import React, { ReactElement, useContext, useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { RootDispatch } from "../../../app/store"
import { EditableList } from "../../../compnents/list/EditableList"
import { traceRoute } from "../../../layouts/layoutSlice"
import { DateTimeFormatSimpleOnly } from "../../../models/datetime"
import { surgicalBody } from "../../../services/surgical"
import { ToBeCompletedOperationParams } from "../../../services/toBeCompletedOperation"
import { ThemeContext } from "../../../theme/ThemeContext"
import SurgicalFrom from "../../treatment/surgical/SurgicalFrom"
import { surgicalApply } from "../../treatment/surgical/surgicalSlice"
import { OperationDetail } from "../OperationDetail"
import { CancelOperationModal } from "./cancelOperationModal"
import {
  ToBeCompletedOperationColumns,
  ToothToBeCompletedOperationColumns,
} from './column'
import { ExcuteDetailModal } from "./excuteDetailModal"
import { ToBeCompletedOperationQuery } from "./Query"
import { getToBeCompletedOperationList } from "./toBeCompletedOperationSlice"
import styles from './excuteDetailModal.module.css'
import { selectEdition } from "../../../app/applicationSlice"

export const ToBeCompletedOperation = (props: {
  patientId?: string
  toothPatient?: boolean
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const hisVersion = useSelector(selectEdition) //his版本 0通用 1口腔

  const theme = useContext(ThemeContext)

  const history = useHistory()

  const ref = useRef<any>(null)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [
    isCancelOperationModalVisible,
    setIsCancelOperationModalVisible,
  ] = useState(false)

  const [total, setTotal] = useState<any>()

  const [data, setData] = useState<any[]>([])

  const [id, setId] = useState<any>()

  const [mainDoctorId, setMainDoctorId] = useState<any>()

  const [registrationId, setRegistrationId] = useState<any>()

  const [treatmentNo, setTreatmentNo] = useState<any>()

  const [queries, setQueries] = useState<ToBeCompletedOperationParams>({
    current: 0,
    size: 10,
    flag: 0,
    startTime: null,
    endTime: null,
  })

  const [pageLoading, setPageLoading] = useState(false) // 表格 loading

  const [detailVisible, setDetailVisible] = useState(false)

  const [addVisible, setAddVisible] = useState(false)

  useEffect(() => {
    if (!queries.current) return
    getPageData()
    !props.patientId &&
      sessionStorage.setItem(location.pathname, JSON.stringify(queries))
  }, [dispatch, queries, props.patientId])

  const getPageData = () => {
    setPageLoading(true)
    dispatch(
      getToBeCompletedOperationList({
        ...queries,
        patientId: props.patientId || undefined,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setData(res.records)
        setTotal(res.total)
      })
      .finally(() => setPageLoading(false))
  }

  const columns =
    hisVersion == 1
      ? ToothToBeCompletedOperationColumns
      : ToBeCompletedOperationColumns
  return (
    <Col
      style={{
        height: `calc(100% - ${props.patientId ? '0px' : '30px'})`,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {props.patientId && props.toothPatient ? (
        <Button
          icon={<PlusOutlined />}
          type='primary'
          style={{
            position: 'absolute',
            bottom: 0,
            left: 10,
            zIndex: 9,
          }}
          onClick={() => {
            setAddVisible(true)
          }}
        >
          新增手术
        </Button>
      ) : (
        <Button
          icon={<PlusOutlined />}
          type='primary'
          size='small'
          style={{
            position: 'fixed',
            top: 68,
            right: 45,
          }}
          onClick={() => {
            history.push({
              pathname: '/applyForOperation',
              state: {
                tag: 1,
              },
            })
          }}
        >
          手术申请
        </Button>
      )}

      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '0 10px',
          paddingTop: props.patientId ? 0 : 10,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ToBeCompletedOperationQuery
          onValueChange={(v) => {
            setQueries(v)
          }}
          patientId={props.patientId}
        />

        <EditableList
          bordered={props?.toothPatient ? true : false}
          loading={pageLoading}
          page={{
            items: data,
            current: queries.current || 1,
            size: queries.size || 10,
            total: total,
          }}
          columns={columns(
            (text, id, mainDoctorId, registrationId, treatmentNo) => {
              if (text === 'execute') {
                setId(id)
                setMainDoctorId(mainDoctorId)
                setRegistrationId(registrationId)
                setTreatmentNo(treatmentNo)
                setIsModalVisible(true)
              } else if (text === 'cancel') {
                setId(id)
                setIsCancelOperationModalVisible(true)
              }
            },
            (queries.size || 10) * ((queries.current || 1) - 1)
          )}
          onChangePage={(current: any, size: any) => {
            setQueries({ ...queries, current, size })
          }}
          onRow={(r) => ({
            onDoubleClick: () => {
              if (props.toothPatient) {
                setDetailVisible(true)
              } else {
                dispatch(
                  traceRoute({
                    name: '手术详情',
                    path: '/operationDetail',
                    state: {
                      id: r.id,
                      tag: 1,
                    },
                  })
                )
              }

              setId(r.id)
            },
          })}
        />
      </Col>
      <ExcuteDetailModal
        isModalVisible={isModalVisible}
        id={id}
        toothPatient={props.toothPatient}
        mainDoctorId={mainDoctorId}
        registrationId={registrationId}
        treatmentNo={treatmentNo}
        patientId={props.patientId}
        onCancel={() => {
          setIsModalVisible(false)
          setId('')
          getPageData()
        }}
        onOk={() => {
          setIsModalVisible(false)
          setId('')
          getPageData()
        }}
      />
      <CancelOperationModal
        isModalVisible={isCancelOperationModalVisible}
        id={id}
        onCancel={() => {
          setIsCancelOperationModalVisible(false)
          setId('')
          getPageData()
        }}
        onOk={() => {
          setIsCancelOperationModalVisible(false)
          setId('')
          getPageData()
        }}
      />
      {/* 新增 */}
      <Modal
        className={styles.addModal}
        visible={addVisible}
        title=''
        width={1200}
        bodyStyle={{
          height: 720,
          overflow: 'auto',
        }}
        maskClosable={false}
        destroyOnClose
        onCancel={() => {
          setAddVisible(false)
        }}
        onOk={() => {
          ref.current?.subSurgical()
        }}
      >
        <SurgicalFrom
          tag='operation'
          toothPatient={props.toothPatient}
          ref={ref}
          subForm={(v: surgicalBody) => {
            dispatch(surgicalApply(v))
              .then(unwrapResult)
              .then(async () => {
                await notification.success({
                  message: '手术申请提交成功',
                })
                setAddVisible(false)
                getPageData()
              })
          }}
          patientId={props.patientId}
        />
      </Modal>
      {/* 详情 */}
      <Modal
        visible={detailVisible}
        title='手术详情'
        footer={null}
        width={1200}
        onCancel={() => {
          setDetailVisible(false)
        }}
        bodyStyle={{
          height: 700,
          overflow: 'auto',
        }}
      >
        <OperationDetail
          patientId={id}
          toothPatient={props.toothPatient}
          Ctag={1}
        />
      </Modal>
    </Col>
  )
}
