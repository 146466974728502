import {Button, TableColumnType} from "antd";
import React from "react";
import {useHistory} from "react-router-dom";


export const PurchaseControlColumns = (
    onclick: (supplierId: any) => void,
    startIndex: number
): TableColumnType<any>[] => {

    const history = useHistory()

    return [
        {
            title: "序号",
            align: "center",
            width: '8rem',
            key: "no",
            render: (_1, _2, index) => `${startIndex + index + 1}`,
        },
        {
            title: "供应商名称",
            align: "center",
            dataIndex: "supplierName",
            width: '60rem',
            key: "supplierName",
        },
        {
            title: "进价金额",
            align: "center",
            dataIndex: "totalPurchasePrice",
            width: '16rem',
            key: "totalPurchasePrice",
            sorter: (a: any, b: any) => a.totalPurchasePrice - b.totalPurchasePrice,
            render: (_: any, t: any) => {
                return t.totalPurchasePrice
                    ? parseFloat((t.totalPurchasePrice as unknown) as string).toFixed(2)
                    : 0;
            },
        },
        {
            title: "售价金额",
            align: "center",
            dataIndex: "totalSellingPrice",
            width: '16rem',
            key: "totalSellingPrice",
            sorter: (a: any, b: any) => a.totalSellingPrice - b.totalSellingPrice,
            render: (_: any, t: any) => {
                return t.totalSellingPrice
                    ? parseFloat((t.totalSellingPrice as unknown) as string).toFixed(2)
                    : 0;
            },
        },
        {
            title: "品种",
            align: "center",
            dataIndex: "varieties",
            width: '16rem',
            key: "varieties",
            sorter: (a: any, b: any) => a.varieties - b.varieties,
        },
        {
            title: "数量",
            align: "center",
            dataIndex: "count",
            width: '16rem',
            key: "count",
            sorter: (a: any, b: any) => a.count - b.count,
        },
        {
            title: "操作",
            dataIndex: '',
            align: 'center',
            render : function Element(_, t) {
                return (
                    <div>
                        <Button
                            type='link'
                            style={{ fontSize: '14px' }}
                            onClick={() => {
                                // history.push({
                                //     pathname: '/',
                                //     state: {
                                //
                                //     },
                                // })
                                onclick(t.supplierId)
                                console.log(t.supplierId)
                            }}
                        >
                            进货管理详情
                        </Button>
                    </div>
                )
            }

        },
    ]

}

