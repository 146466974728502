/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-30 16:48:19
 * @LastEditors: linxi
 * @LastEditTime: 2021-08-31 17:14:01
 */
export const ruleSort = (arr: any[]) => {
  const obj = { a: 0, B: 1, F: 2, M: 3, O: 4, I: 5, D: 6, L: 7, P: 8 } as any;
  const obj1 = {
    0: "a",
    1: "B",
    2: "F",
    3: "M",
    4: "O",
    5: "I",
    6: "D",
    7: "L",
    8: "P",
  } as any;
  for (let index = 0; index < arr.length; index++) {
    const word1 = arr[index];
    let word3 = "";
    for (let i = 0; i < word1.length; i++) {
      word3 += obj[word1[i]];
    }
    arr[index] = word3;
  }
  arr.sort();
  for (const index in arr) {
    const word1 = arr[index];
    let word3 = "";
    for (let i = 0; i < word1.length; i++) {
      word3 += obj1[word1[i]];
    }
    arr[index] = word3;
  }
  return arr;
};
