import {
    ExclamationCircleFilled,
    PlusOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    notification,
    Row,
    Select,
    Space,
} from 'antd'
import React, {
    forwardRef,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { RootDispatch } from '../../../../app/store'
import { EditableList } from '../../../../compnents/list/EditableList'
import { getUrlManagementList } from './urlManagementSlice'
import { duration } from 'moment'
import leftImg from './images/leftImg.png'
import rightImg from './images/rightImg.png'
export interface UrlManagementRef {
    refresh?: () => void
    tabKey?: any
}

interface UrlManagementProps {
    tabKey?: any
}
export const UrlManagement = (
    { tabKey }: UrlManagementProps,
    ref: React.Ref<UrlManagementRef>
): ReactElement => {

    // const message = useMessage()

    const dispatch = useDispatch<RootDispatch>()

    const theme = useContext(ThemeContext)


    const [pageLoading, setPageLoading] = useState(false) //表格 loading

    const [params, setParams] = useState<any>({
        current: 1,
        size: 10,
    })

    const [page, setPage] = useState({ items: [], total: 0, })


    const copyToClipboard = async (text: string) => {
        try {
            await navigator?.clipboard?.writeText(text);
            notification.success({
                message: '复制成功',
                duration: 3,
            })
        } catch (err) {
            const el = document.createElement('textarea');
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    };

    const getPage = () => {
        dispatch(getUrlManagementList())
            .then(unwrapResult)
            .then((res: any) => {
                setPage({
                    items: res,
                    total: 0,
                })
            })
    }

    useEffect(() => {
        if (tabKey == 3) {
            getPage()
        }
    }, [tabKey])

    return (
        <Col
            style={{
                height: 'calc(100% - 30px)',
                overflow: 'scroll',
            }}
        >
            <EditableList
                scroll={{ y: 400 }}
                style={{ maxHeight: '440px', overflow: 'hidden' }}
                page={{
                    items: page.items,
                    current: params.current,
                    size: params.size,
                    total: page.total,
                }}
                loading={pageLoading}
                pagination={false}
                columns={[
                    {
                        title: '序号',
                        key: 'order',
                        align: 'center',
                        width: '10rem',
                        render: (_1, _2, index) => `${params.size * (params.current - 1) + index + 1}`,
                    },
                    {
                        title: '页面名称',
                        dataIndex: 'urlName',
                        align: 'center',
                        ellipsis: true,
                        width: '16rem',
                    },
                    {
                        title: '地址',
                        dataIndex: 'url',
                        align: 'center',
                        width: '25rem',
                        ellipsis: true
                    },
                    {
                        title: '操作',
                        key: 'actions',
                        align: 'center',
                        width: '16rem',
                        render: function UserSettings(_, u: any): ReactElement {
                            return (
                                <>
                                    <a
                                        onClick={() => {
                                            copyToClipboard(u.url)
                                        }}
                                        style={{ marginRight: '4px' }}
                                    >
                                        复制
                                    </a>
                                </>
                            )
                        },
                    },
                ]}
                onChangePage={(current, size) => {
                    setParams({ ...params, current, size: size || 10 })
                }}
            />

            <Row style={{ fontWeight: 'bold', color: '#101010', marginTop: '20px' }}>公众号菜单设置步骤：</Row>
            <Row>1.打开微信公众号后台，扫码登录，登录链接：<a href="https://mp.weixin.qq.com/">https://mp.weixin.qq.com/</a></Row>
            <Row>2.点击左侧菜单栏“内容与互动—自定义菜单“页面（图一），复制本页面上方的地址去设置公众号菜单，设置后的样式（图二）。</Row>
            <Row style={{ textAlign: 'center', marginTop: '20px' }}>
                <Col>
                    <img src={leftImg} style={{ width: '750px' }} alt="" />
                    <div>（图一）</div>
                </Col>
                <Col style={{ marginLeft: '40px' }}>
                    <img src={rightImg} style={{ width: '245px' }} alt="" />
                    <div>（图二）</div>
                </Col>
            </Row>
        </Col>
    )
}