/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-07-01 13:33:41
 * @LastEditors: sj
 * @LastEditTime: 2022-11-18 15:42:43
 */
import React, { ReactElement, useRef } from "react";
import { useLocation } from "react-router-dom";
import { TabBar, TabPane } from "../../compnents/widgets/TabBar";
import { CompletedOperation } from "./completedOperation/completedOperation";
import { ToBeCompletedOperation } from "./toBeCompletedOperation/toBeCompletedOperation";
import styles from './operationDetail.module.css'
import { Tabs } from "antd";
export const OperationStation = (props: {
  patientId?: string, // 患者管理进入标识
  toothPatient?: boolean //口腔专业版
}): ReactElement => {
  const location: any = useLocation();

  const UseTabs = props.patientId ? Tabs : TabBar
  const UseTabPane = props.patientId ? Tabs.TabPane : TabPane

  return (
    <div style={{
      height: '100%'
    }}>
      <UseTabs
        style={{ height: '100%' }}
        className={props.patientId ? styles.containerP : styles.container}
        defaultActiveKey={location.state ? location.state.selectKey : "0"}
      >
        <UseTabPane key="0" tab="待完成手术">
          <ToBeCompletedOperation patientId={props.patientId} toothPatient={props.toothPatient} />
        </UseTabPane>
        <UseTabPane key="3" tab="已结束手术">
          <CompletedOperation patientId={props.patientId} toothPatient={props.toothPatient} />
        </UseTabPane>
      </UseTabs>
    </div>
  );
};
