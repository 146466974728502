import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { api, sendAsync } from "../../../app/applicationSlice";
import { RootState, RootThunk, RootThunkApi } from "../../../app/store";
import * as D from "../../../models/department";
import { Department } from "../../../models/department";
import { Page, pageOf, startPageOf } from "../../../models/page";
import { fromJson, Registration } from "../../../models/registration";
import * as U from "../../../models/user";
import { User } from "../../../models/user";
import { getOutpatientDepartment as apiGetOutpatientDepartment } from "../../../services/department";
import {
  cancelRegistration as apiCancelRegistration,
  cancelCheckRegistration as apiCancelCheckRegistration,
  getRegistrationList as apiGetRegistrationList,
  RegistrationQueryParams,
  startTreatment,
} from "../../../services/registration";
import { getUserList } from "../../../services/user";

interface RegistrationState {
  departments: Department[];
  doctors: User[];
  page: Page<Registration>;
}

const initialState: RegistrationState = {
  departments: [],
  doctors: [],
  page: startPageOf(),
};

export const getRegistrationList = createAsyncThunk<
  Page<Registration>,
  RegistrationQueryParams,
  RootThunkApi
>("registration/getRegistrationListStatus", async (params, api) => {
  return sendAsync(apiGetRegistrationList(params), api).then((data) =>
    pageOf(
      data,
      _.chain(data.records)
        .map((r) => fromJson(r))
        .value()
    )
  );
});

export const cancelRegistration = createAsyncThunk<void, string, RootThunkApi>(
  "registration/cancleRegistrationStatus",
  async (registrationId, api) => {
    return sendAsync(apiCancelRegistration(registrationId), api);
  }
);

export const cancelCheckRegistration = createAsyncThunk<void, string, RootThunkApi>(
  "registration/cancelCheck",
  async (registrationId, api) => {
    return sendAsync(apiCancelCheckRegistration(registrationId), api);
  }
);


export const getStartTreatment = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('diagnosis/copyTreatmentStatus', async (params, api) => {
  return sendAsync(startTreatment(params), api).then(() => {
    // do nothing.
  })
})


export const getFullDoctorList = createAsyncThunk<User[], void, RootThunkApi>(
  "registration/getFullDoctorList",
  async (_1, api) => {
    return sendAsync(
      getUserList({
        stationCategory: U.StationCategory.DoctorOrPharmacist,
        current: 1,
        size: 1000,
      }),
      api
    ).then((data) =>
      _.chain(data.records)
        .map((u) => U.fromJson(u))
        .value()
    );
  }
);

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setDepartments: (state, action: PayloadAction<Department[]>) => {
      state.departments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRegistrationList.fulfilled, (state, action) => {
      state.page = action.payload
    });
    builder.addCase(getFullDoctorList.fulfilled, (state, action) => {
      state.doctors = action.payload;
    });
  },
});

const { setDepartments } = registrationSlice.actions;

export function getOutpatientDepartment(): RootThunk {
  return api(
    apiGetOutpatientDepartment(),
    (data: Record<string, never>, dispatch) => {
      const departments = _.chain(data.records)
        .map((d) => D.fromJson(d))
        .value();
      dispatch(setDepartments(departments));
    }
  );
}

export const selectDepartments = (state: RootState): Department[] =>
  state.registration.departments;

export const selectDoctors = (state: RootState): User[] =>
  state.registration.doctors;

export const selectRegistrationPage = (state: RootState): Page<Registration> =>
  state.registration.page;

export default registrationSlice.reducer;
