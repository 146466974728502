/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-08-31 10:01:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-08-31 10:07:11
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface sellingExpenseSummaryParams {
  insuranceCode?: any
  current?: any
  size?: any
  timeHead?: any
  timeTail?: any
  timeType?:any
}

export function sellingExpenseSummaryList(
  params: sellingExpenseSummaryParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/totalRevenue`,
    method: 'GET',
    params,
  }
}

export function sellingExpenseSummaryExport(
  params: sellingExpenseSummaryParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/statistics/treatmentServiceSaleList/exportexcel`,
    method: 'GET',
    responseType: 'blob',
    params,
  }
}
